import {
    Component
} from "@angular/core";
import { fuseAnimations } from "src/@fuse/animations";

@Component({
    selector: "app-bank-feed",
    templateUrl: "./bank-feed.component.html",
    styleUrls: ["./bank-feed.component.scss"],
    animations: fuseAnimations,
})
export class BankFeedComponent  {
    showFiller = true;

}
