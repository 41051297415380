<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_allowance">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="AllowanceForm" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SETTLEMENTTYPE ,'has-warning':errorWarningData.Warning?.SETTLEMENTTYPE }">
                        <mat-label>Allowance type</mat-label>
                        <mat-select (selectionChange)="allowanceChange($event.value)" formControlName="SETTLEMENTTYPE">
                            <mat-option *ngFor="let val of AlowanceType" [value]="val.ALLOWANCEDESC">
                                {{val.ALLOWANCEDESC}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SETTLEMENTTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" *ngIf="selectedAllowance?.SHOWAMOUNTPERUNIT" [ngClass]="{'has-error':errorWarningData.Error?.AMOUNTPERUNIT ,'has-warning':errorWarningData.Warning?.AMOUNTPERUNIT }">
                        <mat-label>{{selectedAllowance?.AMOUNTPERUNITDESC}}</mat-label>
                            <!-- <input style="text-align: right;" (click)="$event.target.select()" matInput formControlName="AMOUNTPERUNIT" (blur)="amountChange($event, 'AMOUNTPERUNIT')" (focusout)="CalcSettlementAllowance()"  matTooltip="{{(toolTipList)?toolTipList.SHOWAMOUNTPERUNIT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" appNumericdirective> -->
                            <!-- (change)="amountChange($event, 'AMOUNTPERUNIT')" -->
                            <!-- [value]="AllowanceForm.get('AMOUNTPERUNIT').value | number: '1.4-4'" -->
                            <!-- type="float" (blur)="amountChange($event, 'AMOUNTPERUNIT')" -->
                        <input style="text-align: right;" [value]="AllowanceForm.get('AMOUNTPERUNIT').value | number: '1.4-4'" type="text" mask="separator.4" thousandSeparator="," decimalMarker="." [leadZero]="true" matInput formControlName="AMOUNTPERUNIT" (focusout)="CalcSettlementAllowance()" matTooltip="{{(toolTipList)?toolTipList.SHOWAMOUNTPERUNIT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTPERUNIT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNTPERUNIT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" *ngIf="selectedAllowance?.SHOWCOUNT" [ngClass]="{'has-error':errorWarningData.Error?.COUNT ,'has-warning':errorWarningData.Warning?.COUNT }">
                        <mat-label>Number of Allowances</mat-label>
                        <input matInput type="number" formControlName="COUNT" (focusout)="CalcSettlementAllowance()"  matTooltip="{{(toolTipList)?toolTipList.SHOWCOUNT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHOWCOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SHOWCOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" *ngIf="selectedAllowance?.SHOWUNITSIZE" [ngClass]="{'has-error':errorWarningData.Error?.UNITSIZE ,'has-warning':errorWarningData.Warning?.UNITSIZE }">
                        <mat-label>{{selectedAllowance?.UNITSIZEDESC}}</mat-label>
                        <!-- (change)="amountChange($event, 'UNITSIZE')" -->
                        <!-- [value]="AllowanceForm.get('UNITSIZE').value | number: '1.4-4'" -->
                        <!-- (blur)="amountChange($event, 'UNITSIZE')" -->
                        <input matInput style="text-align: right;" [value]="AllowanceForm.get('UNITSIZE').value | number: '1.4-4'" type="text" mask="separator.4" thousandSeparator="," decimalMarker="." [leadZero]="true" formControlName="UNITSIZE" (focusout)="CalcSettlementAllowance()" matTooltip="{{(toolTipList)?toolTipList.SHOWUNITSIZE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHOWUNITSIZE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SHOWUNITSIZE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field *ngIf="selectedAllowance?.SHOWSTATUS" appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STATUS ,'has-warning':errorWarningData.Warning?.STATUS }">
                        <mat-label>{{selectedAllowance?.STATUSDESC}}</mat-label>
                        <mat-select formControlName="STATUS" (selectionChange)="CalcSettlementAllowance()">
                            <mat-option *ngFor="let val of StatusOption" value="{{val}}">{{val}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STATUS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.STATUS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline" *ngIf="selectedAllowance?.SHOWDATEFROM" [ngClass]="{'has-error':errorWarningData.Error?.DATEFROM ,'has-warning':errorWarningData.Warning?.DATEFROM}">
                        <mat-label>Date Range List</mat-label>
                        <mat-select formControlName="DATERANGE" #values name="DATERANGE" (selectionChange)="hideshowdatepicker($event.value)">
                            <mat-option value="Last Financial Year">Last Financial Year</mat-option>
                            <mat-option value="Current Financial Year">Current Financial Year</mat-option>
                            <mat-option value="First Half of Current Financial Year">First Half of Current Financial Year
                            </mat-option>
                            <mat-option value="Second Half of Current Financial Year">Second Half of Current Financial Year
                            </mat-option>
                            <mat-option value="Last Calendar Year">Last Calendar Year</mat-option>
                            <mat-option value="This Calendar Year">This Calendar Year</mat-option>
                            <mat-option value="First Half of Current Calendar Year" selected=''>First Half of Current Calendar Year
                            </mat-option>
                            <mat-option value="Second Half of Current Calendar Year">Second Half of Current Calendar Year
                            </mat-option>
                            <mat-option value="1st Quarter (1 Jan – 31 March)">1st Quarter (1 Jan – 31 March)
                            </mat-option>
                            <mat-option value="2nd Quarter ( 1 April – 30 June)">2nd Quarter ( 1 April – 30 June)
                            </mat-option>
                            <mat-option value="3rd Quarter  (1 July – 30 Sept)">3rd Quarter (1 July – 30 Sept)
                            </mat-option>
                            <mat-option value="4th Quarter  (1 Oct – 31 Dec)">4th Quarter (1 Oct – 31 Dec)</mat-option>
                            <mat-option value="Date Range">Date Range</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHOWDATEFROM?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SHOWDATEFROM?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field *ngIf="selectedAllowance?.SHOWDATEFROM" [ngClass]="{'has-error':errorWarningData.Error?.DATEFROM ,'has-warning':errorWarningData.Warning?.DATEFROM}" appearance="outline" class="example" fxFlex="50" class="pr-4">
                        <mat-label>Date From</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="DATEFROMTEXT" (dateChange)="choosedFromDate('change', $event)"  matTooltip="{{(toolTipList)?toolTipList.DATEFROM?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEFROM?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATEFROM?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field *ngIf="selectedAllowance?.SHOWTODATE" [ngClass]="{'has-error':errorWarningData.Error?.TODATE,'has-warning':errorWarningData.Warning?.TODATE}" appearance="outline" class="example" fxFlex="50" class="pr-4">
                        <mat-label>Date To</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="TODATETEXT" (dateChange)="choosedToDate('change', $event)"  matTooltip="{{(toolTipList)?toolTipList.TODATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TODATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TODATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}">
                        <mat-label>COMMENT</mat-label>
                        <textarea matInput formControlName="COMMENT"  matTooltip="{{(toolTipList)?toolTipList.COMMENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.PAYABLEBYPURCHASER,'has-warning':errorWarningData.Warning?.PAYABLEBYPURCHASER}" class="example gray_out_for_poup pr-4" appearance="outline" fxFlex="33">
                        <mat-label>Payable By Purchaser</mat-label>
                        <input formControlName="PAYABLEBYPURCHASER" matInput readonly currencyMask  matTooltip="{{(toolTipList)?toolTipList.PAYABLEBYPURCHASER?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYABLEBYPURCHASER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYABLEBYPURCHASER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.PAYABLEBYVENDER,'has-warning':errorWarningData.Warning?.PAYABLEBYVENDER}" class="example gray_out_for_poup pr-4" appearance="outline" fxFlex="33">
                        <mat-label>Payable By Vendor</mat-label>
                        <input formControlName="PAYABLEBYVENDER" matInput readonly currencyMask  matTooltip="{{(toolTipList)?toolTipList.PAYABLEBYVENDER?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYABLEBYVENDER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYABLEBYVENDER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field class="example" fxFlex="34" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.APPLICABLETOWHO ,'has-warning':errorWarningData.Warning?.APPLICABLETOWHO}">
                        <mat-label>Applicable to who?</mat-label>
                        <mat-select formControlName="APPLICABLETOWHO" name="APPLICABLETOWHO">
                            <mat-option value="All">All</mat-option>
                            <mat-option value="Vendor Only">Vendor Only</mat-option>
                            <mat-option value="Purchaser Only">Purchaser Only</mat-option>
                            <mat-option value="Hidden">Hidden</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPLICABLETOWHO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.APPLICABLETOWHO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" [disabled]="isspiner" (click)="saveAllowance()">
            <mat-spinner *ngIf="isspiner"></mat-spinner>{{btnTex}}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>