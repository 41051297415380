<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="one" [formGroup]="AccountForm" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="95" class="pr-4">
            <mat-label>Select Xero Account and then link</mat-label>
            <mat-select formControlName="xeroaccountClsss" (selectionChange)="GetAccountDetails($event.value)">
                <mat-option *ngFor="let item of accountData;let i=index" [value]="i">
                    {{item?.CODE}} - {{item?.NAME }} - {{item?.DESCRIPTION }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div fxFlex="5">
            <mat-spinner *ngIf="isLoadingResults" [style.zoom]="0.8"></mat-spinner>
            <mat-icon class="nav-link-icon" *ngIf="refreshbutton" style="cursor: pointer;" (click)="LoadAccount()">
                refresh
            </mat-icon>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <button mat-button fxFlex="25" (click)="LoadAccount()">
            <section class="large-button-container">
                <mat-icon class="main-icon">
                    <img src="assets/icons/web_app/xero.ico">
                </mat-icon>
                <div class="main-text">
                    <span>Load Xero<br>Accounts</span>
                </div>
            </section>
        </button>
        <button mat-raised-button color="accent" class="mat-accent pr-4" (click)="linkAccount()" fxFlex="50"
            [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Link to this Xero account.
        </button>
        <button mat-button class="mrl-15" (click)="removeAccount()">
            <mat-icon class="main-icon">
                <img src="assets/icons/web_app/icon_close_d.ico">
            </mat-icon>
        </button>
    </div>
    <div fxLayout="row wrap" class=" mt-1-5" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4 readonly_apply">
            <mat-label>Xero Code</mat-label>
            <input matInput formControlName="XEROACCOUNTCODE" readonly>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4 readonly_apply">
            <mat-label>Xero Guid</mat-label>
            <input matInput formControlName="XEROGUID" readonly>
        </mat-form-field>
    </div>
</div>