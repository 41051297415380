import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { fuseAnimations } from 'src/@fuse/animations';
// import { MatterPopupComponent } from 'src/app/main/pages/matters/matter-popup/matter-popup.component';
// import { CorrespondDailogComponent } from 'src/app/main/pages/matters/correspond-dailog/correspond-dailog.component';
import { MatterPopupComponent } from '@pages/matters/matter-popup/matter-popup.component';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription, forkJoin, tap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';


@Component({
    selector: 'app-contact-matters',
    templateUrl: './matters.component.html',
    styleUrls: ['./matters.component.scss'],
    animations: fuseAnimations
})
export class MattersComponent implements OnInit, OnDestroy {
    theme_type = localStorage.getItem('theme_type');
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    @Input() errorWarningData: any;
    @Input() contactForm: FormGroup;
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    barristerTitle: any = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Matter Num' : 'Short Name';
    // for client data
    displayedColumnsClient: string[] = ['SHORTNAME', 'MATTER'];
    isDisplay: boolean = false;
    dataSourceClient: any = [];
    currentClientData: any;
    highlightedRowsClient: any;
    @ViewChild('clientPaginator', { static: true }) clientPaginator: MatPaginator;
    @ViewChild('ClientSort', { read: MatSort, static: true }) clientSort: MatSort;
    // for corr data
    displayedColumnsCorres: string[] = ['SHORTNAME', 'MATTER'];
    isDisplay1: boolean = false;
    dataSourceCorrs: any = [];
    currentCorssData: any;
    highlightedRowsCorss: any;
    isLoadingResults: boolean = false;
    sub1: Subscription;
    sub2: Subscription;
    @ViewChild('CorrsPaginator', { static: true }) corrsPaginator: MatPaginator;
    @ViewChild('CorrsSort', { read: MatSort, static: true }) corrsSort: MatSort;
    // hyperLinkInfo: any = [];
    hyperLinkInfo: any = {};


    constructor(
        public _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        public dialog: MatDialog,
        private TableColumnsService: TableColumnsService,
        private behaviorService: BehaviorService,
        private _hyperlink: HyperlinkNavigationService
    ) {
        localStorage.setItem("collectedSelectedMatter", "[]");
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        if (this.action == "edit") {
            this.isLoadingResults = true;
            let payloadClientData = {
                "Action": "GetData",
                "Filters": {
                    CONTACTGUID: localStorage.getItem('contactGuid')
                }
            };

            let payloadContectData = {
                "Action": "GetData",
                "Filters": {
                    MATTERCONTACTGUID: localStorage.getItem('contactGuid')
                }
            };

            // this.loadClientData(payloadClientData);
            // this.loadContectData(payloadContectData);
            this.sub1 = forkJoin([
                this._mainAPiServiceService.getSetData(payloadClientData, 'matter'),
                this._mainAPiServiceService.getSetData(payloadContectData, 'matter')
            ]).pipe(
                tap(([response, response2]) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.dataSourceClient = new MatTableDataSource(response.DATA.RECORDS);
                        this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(response.DATA.RECORDS);
                        this.dataSourceClient.paginator = this.clientPaginator;
                        this.dataSourceClient.sort = this.clientSort;
                        if (response.DATA.RECORDS[0]) {
                            this.isDisplay = false;
                            this.currentClientData = response.DATA.RECORDS[0];
                            this.highlightedRowsClient = response.DATA.RECORDS[0].MATTERGUID;
                        } else {
                            this.isDisplay = true;
                        }
                        this.isLoadingResults = false;
                    }
                    this.behaviorService.Hyperlink$.next(this.hyperLinkInfo)

                    if (response2.CODE == 200 && response2.STATUS == "success") {
                        this.dataSourceCorrs = new MatTableDataSource(response2.DATA.RECORDS);
                        this.dataSourceCorrs.paginator = this.corrsPaginator;
                        this.dataSourceCorrs.sort = this.corrsSort;
                        if (response2.DATA.RECORDS[0]) {
                            this.currentCorssData = response2.DATA.RECORDS[0];
                            this.highlightedRowsCorss = response2.DATA.RECORDS[0].MATTERGUID;
                            this.isDisplay1 = false;
                        } else {
                            this.isDisplay1 = true;
                        }
                        this.isLoadingResults = false;
                    }
                })
            ).subscribe(response => {
                }, err => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            )
        }
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
    }

    get f() {
        return this.contactForm.controls;
    }

    /**
     * This function is used to load the client data
     * @param postData 
     */
    loadClientData(postData) {
        this.isLoadingResults = true;
        this.sub1 = this._mainAPiServiceService.getSetData(postData, 'matter').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.dataSourceClient = new MatTableDataSource(response.DATA.RECORDS);
                this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(response.DATA.RECORDS);
                this.dataSourceClient.paginator = this.clientPaginator;
                this.dataSourceClient.sort = this.clientSort;
                if (response.DATA.RECORDS[0]) {
                    this.isDisplay = false;
                    this.currentClientData = response.DATA.RECORDS[0];
                    this.highlightedRowsClient = response.DATA.RECORDS[0].MATTERGUID;
                } else {
                    this.isDisplay = true;
                }
                this.isLoadingResults = false;
            }

            this.behaviorService.Hyperlink$.next(this.hyperLinkInfo)
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }
    /**
     * This function is used to 
     * @param postData -post data value
     */
    loadContectData(postData) {
        this.isLoadingResults = true;
        this.sub2 = this._mainAPiServiceService.getSetData(postData, 'matter').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.dataSourceCorrs = new MatTableDataSource(response.DATA.RECORDS);
                this.dataSourceCorrs.paginator = this.corrsPaginator;
                this.dataSourceCorrs.sort = this.corrsSort;
                if (response.DATA.RECORDS[0]) {
                    this.currentCorssData = response.DATA.RECORDS[0];
                    this.highlightedRowsCorss = response.DATA.RECORDS[0].MATTERGUID;
                    this.isDisplay1 = false;
                } else {
                    this.isDisplay1 = true;
                }
                this.isLoadingResults = false;
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to Edit the Matter
     * @param Data -Data value
     */
    EditMatter(Data) {
        const dialogRef = this.dialog.open(MatterPopupComponent, {
            width: '100%', disableClose: true, data: { action: 'edit', 'matterGuid': Data }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadClientData({ CONTACTGUID: localStorage.getItem('contactGuid') });
            }
        });
    }

    /**
     * This function is used to edit the elememt
     * @param editElement 
     */
    editElement(editElement) {
        const dialogRef = this.dialog.open(MatterPopupComponent, {
            width: '100%', disableClose: true, data: { action: 'edit', 'matterGuid': editElement.MATTERGUID }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadClientData({ CONTACTGUID: localStorage.getItem('contactGuid') });
            }
        });
        // const dialogRef = this.dialog.open(CorrespondDailogComponent, {
        //   width: '100%', disableClose: true, data: { EditData: editElement, type: 'edit', 'FIRMGUID': editElement.FIRMGUID }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   this.loadContectData({ MATTERCONTACTGUID: localStorage.getItem('contactGuid') });
        // });
    };

    /**
     * This fnction is used to 
     * @param row 
     * @param item 
     * @param hyperLinkInfo 
     */
    selectHyperLink(row, item, hyperLinkInfo: any) {
        let obj = {
            LINKFIELD: "MATTERGUID",
            LINKTYPE: "MATTER",
            LINKURL: "/matter-details",
        };

        this._hyperlink.selectHyperLink(row, '', obj)
    }
}
