<div id="pack"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
    [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'solicitor-container':'barrister-container'">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-24 more_tbl_clmn list_tbl_main"
            fusePerfectScrollbar>
            <div
                class="matter-serach-div sticky_search_div responsive_auto matter-side-div">

                <form
                    class="align-center display-flex filter-menuwrap chart-acc-menu-wrap form-div-side">
                    <button mat-button [matMenuTriggerFor]="dummyButton"
                        [disabled]="true" style="display: none;">
                        Filters</button>
                    <mat-menu #dummyButton="matMenu">
                    </mat-menu>
                    <div
                        class="align-center display-flex flex-wrap filter-menublock">

                        <mat-icon class="icon-btn"
                            (click)="refreshChartACCTab()">refresh</mat-icon>
                        <div class="position-relative">
                            <mat-icon class="badge-ic">add_circle</mat-icon>
                            <button mat-button
                                class="buttonwith-text dotted-button"
                                [matMenuTriggerFor]="activeMenucard">

                                Status<span
                                    class="filter-applytext display-flex align-center">{{ChartData.AccountClass=='All'?'All Accounts':ChartData.AccountClass}}<mat-icon
                                        class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon></span>
                            </button>

                            <mat-menu #activeMenucard="matMenu" #menu>
                                <mat-radio-group
                                    *ngIf="accountTypeData.ClickType=='WithoutTrust'"
                                    name="AccountClass"
                                    [(ngModel)]="ChartData.AccountClass"
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group">
                                    <mat-radio-button
                                        (change)="AccountClass($event.value)"
                                        class="filter-radio-button"
                                        disableRipple="true"
                                        *ngFor="let letter of ListofWithoutTrust"
                                        [value]="letter?.id">
                                        {{letter?.data}}
                                    </mat-radio-button>
                                </mat-radio-group>

                                <mat-radio-group
                                    *ngIf="accountTypeData.ClickType=='WithTrust'"
                                    name="AccountClass"
                                    [(ngModel)]="ChartData.AccountClass"
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group">
                                    <mat-radio-button
                                        (change)="AccountClass($event.value)"
                                        class="filter-radio-button"
                                        disableRipple="true"
                                        *ngFor="let letter of ListofWithTrust"
                                        [value]="letter?.id">
                                        {{letter?.data}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                        </div>

                        <span class="show-inactive">
                            <!-- <mat-checkbox class="example-margin inactive-checkbox" (change)="onChecked(showActive)" name="showActive" [(ngModel)]="showActive"></mat-checkbox> -->
                            <mat-slide-toggle
                                [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'togglechecksol':'togglecheckbar'"
                                class="example-margin inactive-checkbox toggleSize"
                                (change)="onChecked(showActive)"
                                name="showActive" [(ngModel)]="showActive"></mat-slide-toggle>
                            <mat-label>Show Inactive</mat-label>
                        </span>

                        <div class="position-relative">
                            <!-- search  -->
                            <mat-icon
                                class="badge-ic"
                                (click)="onSearch('removeSearchString')">
                                {{
                                ChartData.Search != ''
                                ? "cancel_circle"
                                : ""
                                }}</mat-icon>

                            <button
                                mat-button
                                class="buttonwith-text"
                                style="padding: 1px 5px">
                                Search<span
                                    class="filter-applytext display-flex align-center">
                                    <input
                                        matInput
                                        [(ngModel)]="ChartData.Search"
                                        name="search"
                                        (keyup)="
                                            onSearch($event)"
                                        #mainSafesearchField
                                        style="
                                            margin-top: 4px;
                                            text-align: left;
                                        "
                                        tabindex="6" />
                                    <mat-icon matSuffix
                                        (click)="onSearch('Enter')">search</mat-icon>
                                </span>
                            </button>

                        </div>
                    </div>

                </form>
                <div class="bg-dark rigth-div" id="appCues_matter-invoices"></div>

                <!-- <form>

                    <mat-form-field class="example" appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select *ngIf="accountTypeData.ClickType=='WithoutTrust'" name="AccountClass" [(ngModel)]="ChartData.AccountClass" (selectionChange)="AccountClass($event.value)">
                            <mat-option value="All">All Accounts</mat-option>
                            <mat-option value="Assets">Assets</mat-option>
                            <mat-option value="Liabilities">Liabilities</mat-option>
                            <mat-option value="Equity">Equity</mat-option>
                            <mat-option value="Income">Income</mat-option>
                            <mat-option value="Expenses">Expenses</mat-option>
                        </mat-select>

                        <mat-select *ngIf="accountTypeData.ClickType=='WithTrust'" name="AccountClass" [(ngModel)]="ChartData.AccountClass" (selectionChange)="AccountClass($event.value)">
                            <mat-option value="All">All Accounts</mat-option>
                            <mat-option value="Trust Account">Trust Account</mat-option>
                            <mat-option value="Matter Ledger">Matter Ledger</mat-option>
                            <mat-option value="Unknown Deposit">Unknown Deposit accounts</mat-option>
                            <mat-option value="Controlled Money Ledger">Controlled Money Ledger </mat-option>
                            <mat-option value="Controlled Money Account">Controlled Money Account </mat-option>
                        </mat-select>

                    </mat-form-field>
                    <span class="show-inactive">
                        <mat-checkbox class="example-margin inactive-checkbox" (change)="onChecked(showActive)" name="showActive" [(ngModel)]="showActive"></mat-checkbox>
                        <mat-label>Show Inactive</mat-label>
                    </span>
                    <mat-form-field class="search" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input name="Search" [(ngModel)]="ChartData.Search" matInput placeholder="search" (keydown)="onSearch($event)">
                        <mat-icon matSuffix *ngIf="!hasSearchVal">search</mat-icon>
                        <mat-icon matSuffix (click)="onSearch('removeSearchString')" *ngIf="hasSearchVal" class="icon-color-red">highlight_off</mat-icon>
                    </mat-form-field>
                    <span class="appCues-blankSpace">
                        <a href="javascript:void(0);">Link</a>
                    </span>
                </form> -->
            </div>
            <button mat-raised-button color="primary" type="button"
                id="refreshChartACCTab" (click)="refreshChartACCTab();">Refresh</button>
            <div class="content">
                <div class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <div
                                    class="btn top_right_action_billing_position dot_controller">
                                    <button mat-icon-button
                                        [matMenuTriggerFor]="contextMenu"
                                        aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template matMenuContent
                                            let-item="item">
                                            <button mat-menu-item
                                                (click)="refreshChartACCTab()">
                                                Reload Data</button>
                                            <button mat-menu-item
                                                (click)="downloadFile()">
                                                <img
                                                    src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="height: 20px !important;margin-top: 10px;">
                                                Download XLSX
                                            </button>
                                        </ng-template>
                                    </mat-menu>
                                </div>
                                <div class="example-containerdata">
                                    <div class="example-loading-shade"
                                        *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5"
                                            *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <!-- wrapMode='both' allowResizing='false' -->
                                    <ejs-treegrid *ngIf="isShowTable"
                                        class="{{is_account}}" #charttreegrid
                                        [allowExcelExport]='true'
                                        [pageSettings]='pager'
                                        childMapping='subtasks'
                                        [dataSource]="chartdataTreeTable"
                                        allowSelection='true'
                                        childMapping='SUBACCOUNTS'
                                        [treeColumnIndex]='0'
                                        [selectedRowIndex]="0"
                                        [allowPaging]='false'
                                        allowResizing='true'
                                        [allowSorting]='true'
                                        (rowSelected)='rowSelected()'
                                        rowHeight='35'
                                        (dataBound)="dataBound()"
                                        [allowFrozenColumns]="true"
                                        >
                                        <e-columns>
                                            <e-column headerText='Account Name'
                                                field="ACCOUNTNAME"
                                                [valueAccessor]='setCustomFormater'
                                                width='200' textAlign='Left'>
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">
                                                        {{data.ACCOUNTCLASS}} - {{data.ACCOUNTNUMBER}}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {{data.ACCOUNTNAME}}&nbsp;
                                                        {{data.ACTIVE == 1 ? '' : '(inactive)'}}
                                                    </label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Balance'
                                                width='80'
                                                field="ACCOUNTBALANCE"
                                                [valueAccessor]='setCustomFormater'
                                                
                                                *ngIf="appPermissions[19]['View Balances'] == '1'">
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.ACCOUNTBALANCE | toFixedAmount:true}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Type'
                                                width='80'
                                                field="ACCOUNTTYPENAME">
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.ACCOUNTTYPENAME}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column
                                                headerText='Last Statement Date'
                                                width='120'
                                                *ngIf="isTrustColumm"
                                                field="LASTSTATEMENTDATE">
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.LASTSTATEMENTDATE}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column
                                                headerText="{{barristerTitle}}"
                                                width='80' *ngIf="isTrustColumm"
                                                field="SHORTNAME">
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.SHORTNAME}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Matter'
                                                width='80' *ngIf="isTrustColumm"
                                                field="MATTER">
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.MATTER}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Contact'
                                                width='80' *ngIf="isTrustColumm"
                                                field="CONTACTNAME">
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.CONTACTNAME}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Xero Code'
                                                width='80'
                                                *ngIf="!isTrustColumm"
                                                field="XEROACCOUNTCODE"
                                                [valueAccessor]='setCustomFormater'>
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.EXPORTINFO?.XEROACCOUNTCODE}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='MYOB Account'
                                                width='80'
                                                *ngIf="!isTrustColumm"
                                                field="MYOBEXPORTACCOUNT"
                                                [valueAccessor]='setCustomFormater'>
                                                <ng-template #template let-data>
                                                    <label [ngStyle]="{'color': '#' + data?.ROWCOLOUR}">{{data.EXPORTINFO?.MYOBEXPORTACCOUNT}}</label>
                                                </ng-template>
                                            </e-column>
                                        </e-columns>
                                    </ejs-treegrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
