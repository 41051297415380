<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<!-- <button mat-raised-button color="primary" type="button" id="refreshKitItemTab" (click)="refreshKitItemTab();">Refresh</button> -->
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>

    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.NAME,'has-warning':errorWarningData.Warning?.NAME}">
                        <mat-label>Pack Name</mat-label>
                        <input [(ngModel)]="MainKitData.KITNAME" name="KITNAME" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.CONTEXT,'has-warning':errorWarningData.Warning?.CONTEXT}">
                        <mat-label>Context</mat-label>
                        <mat-select [(ngModel)]="MainKitData.CONTEXT" name="CONTEXT" #value name="selected">
                            <mat-option value="Matter">Matter</mat-option>
                            <mat-option value="Invoice">Invoice</mat-option>
                            <mat-option value="Receipt">Receipt</mat-option>
                            <mat-option value="Firm">Firm</mat-option>
                            <mat-option value="Contact">Contact</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>

        <div class="example-containerdata">
            <table class="simple" [@animateStagger]="{value:'50'}">
                <tr>
                    <th>Order</th>
                    <th>Template File</th>
                    <th>Type</th>

                </tr>
                <tr *ngFor="let item of getDataForTable; let index = index"
                    (click)="rowdata(item ,index);highlightedRows=index"
                    [style.background]="highlightedRows == index ? selectedColore : ''"
                    [ngClass]="highlightedRows == index ? 'row-text-colore' : ''" matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
                    <td>{{item.ORDER}}</td>
                    <td>{{item.TEMPLATEFILE}}</td>
                    <td>{{item.TEMPLATETYPEDESC}}</td>
                </tr>
            </table>
        </div>
        <mat-dialog-actions align="end" class="popup_ftr">
            <button mat-raised-button color="accent" class="mat-accent" (click)="AddPack()">Add Pack Item</button>
            <button mat-raised-button color="accent" class="mat-accent" (click)="EditPack()"
                [disabled]="!currentData">Edit
                Pack Item</button>
            <button mat-raised-button color="accent" (click)="DeletePack()" [disabled]="!currentData">Delete Pack
                Item</button>
        </mat-dialog-actions>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="PackSave()" [disabled]="isspiner"
            *ngIf="action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="PackSave()" [disabled]="isspiner"
            *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>