<div class="one" fxLayout="column" [formGroup]="matterLeasingForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <mat-checkbox fxFlex="100" class="pr-4" formControlName="LEASERECEIVED">Lease Received</mat-checkbox>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Date Executed </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'DATEEXECUTED')" formControlName="DATEEXECUTEDTEXT" (dateChange)="CommanDatePickerClick('input', $event,'DATEEXECUTED')" matInput name="DateExecutede" [matDatepicker]="DateExecutede" matTooltip="{{(toolTipList)?toolTipList.DATEEXECUTED?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DateExecutede"></mat-datepicker-toggle>
            <mat-datepicker #DateExecutede></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEEXECUTED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEEXECUTED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEEXECUTED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Valid Until </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'VALIDUNTIL')" formControlName="VALIDUNTILTEXT" (dateChange)="CommanDatePickerClick('input', $event,'VALIDUNTIL')" matInput matTooltip="{{(toolTipList)?toolTipList.VALIDUNTIL?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="ValidUntil" [matDatepicker]="ValidUntil">
            <mat-datepicker-toggle matSuffix [for]="ValidUntil"></mat-datepicker-toggle>
            <mat-datepicker #ValidUntil></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.VALIDUNTIL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VALIDUNTIL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VALIDUNTIL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Executed By Lessors </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'EXECUTIONDATEBYLESSOR')" formControlName="EXECUTIONDATEBYLESSORTEXT" (dateChange)="CommanDatePickerClick('input', $event,'EXECUTIONDATEBYLESSOR')" matInput matTooltip="{{(toolTipList)?toolTipList.EXECUTIONDATEBYLESSOR?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="ExecutedByLessors" [matDatepicker]="ExecutedByLessors">
            <mat-datepicker-toggle matSuffix [for]="ExecutedByLessors"></mat-datepicker-toggle>
            <mat-datepicker #ExecutedByLessors></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.EXECUTIONDATEBYLESSOR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXECUTIONDATEBYLESSOR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXECUTIONDATEBYLESSOR?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Executed By Lessee </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'EXECUTIONDATEBYLESSEE')" formControlName="EXECUTIONDATEBYLESSEETEXT" (dateChange)="CommanDatePickerClick('input', $event,'EXECUTIONDATEBYLESSEE')" matInput matTooltip="{{(toolTipList)?toolTipList.EXECUTIONDATEBYLESSEE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="ExecutedByLessee" [matDatepicker]="ExecutedByLessee">
            <mat-datepicker-toggle matSuffix [for]="ExecutedByLessee"></mat-datepicker-toggle>
            <mat-datepicker #ExecutedByLessee></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.EXECUTIONDATEBYLESSEE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXECUTIONDATEBYLESSEE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXECUTIONDATEBYLESSEE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Lease Start Date </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'LEASECOMMENCEMENTDATE')" formControlName="LEASECOMMENCEMENTDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'LEASECOMMENCEMENTDATE')" matInput matTooltip="{{(toolTipList)?toolTipList.LEASECOMMENCEMENTDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="LeaseStartDate" [matDatepicker]="LeaseStartDate">
            <mat-datepicker-toggle matSuffix [for]="LeaseStartDate"></mat-datepicker-toggle>
            <mat-datepicker #LeaseStartDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.LEASECOMMENCEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LEASECOMMENCEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LEASECOMMENCEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Lease End Date </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'LEASETERMINATIONDATE')" formControlName="LEASETERMINATIONDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'LEASETERMINATIONDATE')" matInput matTooltip="{{(toolTipList)?toolTipList.LEASETERMINATIONDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="LeaseEndDate" [matDatepicker]="LeaseEndDate">
            <mat-datepicker-toggle matSuffix [for]="LeaseEndDate"></mat-datepicker-toggle>
            <mat-datepicker #LeaseEndDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.LEASETERMINATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LEASETERMINATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LEASETERMINATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Term Of Lease </mat-label>
            <textarea matInput matTooltip="{{(toolTipList)?toolTipList.TERMOFLEASE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="TERMOFLEASE"></textarea>
             <div  *ngIf="testBool && toolTipList.TERMOFLEASE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TERMOFLEASE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TERMOFLEASE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Permitted Use </mat-label>
            <textarea matInput matTooltip="{{(toolTipList)?toolTipList.PERMITTEDUSE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PERMITTEDUSE"></textarea>
             <div  *ngIf="testBool && toolTipList.PERMITTEDUSE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PERMITTEDUSE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PERMITTEDUSE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Lettable SqM </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.LETTABLEAREASQM?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="LETTABLEAREASQM">
             <div  *ngIf="testBool && toolTipList.LETTABLEAREASQM" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LETTABLEAREASQM.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LETTABLEAREASQM?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-checkbox fxFlex="50" class="pr-4" formControlName="RELATESTORETIREMENTVILLAGE">Relates To Retirement Village</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Disclosure Date </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'DISCLOSUREDATE')" formControlName="DISCLOSUREDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'DISCLOSUREDATE')" matInput matTooltip="{{(toolTipList)?toolTipList.DISCLOSUREDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="DisclosureDate" [matDatepicker]="DisclosureDate">
            <mat-datepicker-toggle matSuffix [for]="DisclosureDate"></mat-datepicker-toggle>
            <mat-datepicker #DisclosureDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DISCLOSUREDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DISCLOSUREDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DISCLOSUREDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-checkbox fxFlex="50" class="pr-4" formControlName="RETAILSHOPLEASE">Retail Shop Leases</mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Registration Date </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'REGISTRATIONDATE')" formControlName="REGISTRATIONDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'REGISTRATIONDATE')" matInput matTooltip="{{(toolTipList)?toolTipList.REGISTRATIONDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="RegistrationDate" [matDatepicker]="RegistrationDate">
            <mat-datepicker-toggle matSuffix [for]="RegistrationDate"></mat-datepicker-toggle>
            <mat-datepicker #RegistrationDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.REGISTRATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REGISTRATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REGISTRATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Registered Number </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.LEASINGREGISTEREDNUMBER?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="LEASINGREGISTEREDNUMBER">
             <div  *ngIf="testBool && toolTipList.LEASINGREGISTEREDNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LEASINGREGISTEREDNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LEASINGREGISTEREDNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Access Outside Trading Hours </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.ACCESSOUTSIDETRADINGHOURS?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="ACCESSOUTSIDETRADINGHOURS">
             <div  *ngIf="testBool && toolTipList.ACCESSOUTSIDETRADINGHOURS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCESSOUTSIDETRADINGHOURS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCESSOUTSIDETRADINGHOURS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Option Date </mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'OPTIONDATE')" formControlName="OPTIONDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'OPTIONDATE')" matInput matTooltip="{{(toolTipList)?toolTipList.OPTIONDATE:''}}" [matTooltipPosition]="toolTipPostion" name="OptionDate" [matDatepicker]="OptionDate">
            <mat-datepicker-toggle matSuffix [for]="OptionDate"></mat-datepicker-toggle>
            <mat-datepicker #OptionDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.OPTIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OPTIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OPTIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Option Period </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.OPTIONPERIOD?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="OPTIONPERIOD">
             <div  *ngIf="testBool && toolTipList.OPTIONPERIOD" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OPTIONPERIOD.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OPTIONPERIOD?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Option Desc. </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.OPTIONDESCRIPTION?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="OPTIONDESCRIPTION">
             <div  *ngIf="testBool && toolTipList.OPTIONDESCRIPTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OPTIONDESCRIPTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OPTIONDESCRIPTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Reimb. For GST By Tenant </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.REIMBURSEMENTFORGSTBYTENANT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="REIMBURSEMENTFORGSTBYTENANT">
             <div  *ngIf="testBool && toolTipList.REIMBURSEMENTFORGSTBYTENANT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REIMBURSEMENTFORGSTBYTENANT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REIMBURSEMENTFORGSTBYTENANT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Stamp Duty Document </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.STAMPDUTYDOCUMENTS?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="STAMPDUTYDOCUMENTS">
             <div  *ngIf="testBool && toolTipList.STAMPDUTYDOCUMENTS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STAMPDUTYDOCUMENTS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STAMPDUTYDOCUMENTS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>