<div class=" one " fxLayout="column" [formGroup]="MatterPropertyDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="75" class="pr-4">
            <mat-label>Title Ref(s) - Separate with commas </mat-label>
            <input matInput formControlName="LOTSTITLE" matTooltip="{{(toolTipList)?toolTipList.LOTSTITLE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.LOTSTITLE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOTSTITLE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOTSTITLE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <div fxFlex="25" class="Split-Title">
            <button mat-raised-button color="accent" class="mat-accent" (click)="SplitTitleRefs()" [disabled]="isspiner">
                <mat-spinner *ngIf="isspiner"></mat-spinner>Split Title Refs
            </button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <div fxFlex="20">
            <mat-label>Volume(s)</mat-label>
        </div>
        <div fxFlex="20">
            <mat-label>Folio Id(s)</mat-label>
        </div>
        <div fxFlex="20">
            <mat-label>Lot(s)</mat-label>
        </div>
        <div fxFlex="20">
            <mat-label>Plan Type (s)</mat-label>
        </div>
        <div fxFlex="20">
            <mat-label>Plan No(s)</mat-label>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VOLUME,'has-warning':errorWarningData.Warning?.VOLUME}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.VOLUME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VOLUME">
             <div  *ngIf="testBool && toolTipList.VOLUME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VOLUME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VOLUME?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOID,'has-warning':errorWarningData.Warning?.FOLIOID}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.FOLIOID?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FOLIOID">
             <div  *ngIf="testBool && toolTipList.FOLIOID" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FOLIOID.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOLIOID?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LOTS,'has-warning':errorWarningData.Warning?.LOTS}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.LOTS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LOTS">
             <div  *ngIf="testBool && toolTipList.LOTS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOTS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOTS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE,'has-warning':errorWarningData.Warning?.PLANTYPE}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANTYPE">
             <div  *ngIf="testBool && toolTipList.PLANTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER,'has-warning':errorWarningData.Warning?.PLANNUMBER}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANNUMBER">
             <div  *ngIf="testBool && toolTipList.PLANNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VOLUME2,'has-warning':errorWarningData.Warning?.VOLUME2}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.VOLUME2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VOLUME2">
             <div  *ngIf="testBool && toolTipList.VOLUME2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VOLUME2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VOLUME2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOID2,'has-warning':errorWarningData.Warning?.FOLIOID2}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.FOLIOID2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FOLIOID2">
             <div  *ngIf="testBool && toolTipList.FOLIOID2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FOLIOID2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOLIOID2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LOT2,'has-warning':errorWarningData.Warning?.LOT2}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.LOT2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LOT2">
             <div  *ngIf="testBool && toolTipList.LOT2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOT2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOT2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE2,'has-warning':errorWarningData.Warning?.PLANTYPE2}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANTYPE2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANTYPE2">
             <div  *ngIf="testBool && toolTipList.PLANTYPE2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANTYPE2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANTYPE2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER2,'has-warning':errorWarningData.Warning?.PLANNUMBER2}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANNUMBER2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANNUMBER2">
             <div  *ngIf="testBool && toolTipList.PLANNUMBER2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANNUMBER2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANNUMBER2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VOLUME3,'has-warning':errorWarningData.Warning?.VOLUME3}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.VOLUME3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VOLUME3">
             <div  *ngIf="testBool && toolTipList.VOLUME3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VOLUME3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VOLUME3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOID3,'has-warning':errorWarningData.Warning?.FOLIOID3}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.FOLIOID3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FOLIOID3">
             <div  *ngIf="testBool && toolTipList.FOLIOID3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FOLIOID3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOLIOID3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LOT3,'has-warning':errorWarningData.Warning?.LOT3}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.LOT3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LOT3">
             <div  *ngIf="testBool && toolTipList.LOT3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOT3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOT3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE3,'has-warning':errorWarningData.Warning?.PLANTYPE3}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANTYPE3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANTYPE3">
             <div  *ngIf="testBool && toolTipList.PLANTYPE3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANTYPE3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANTYPE3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER3,'has-warning':errorWarningData.Warning?.PLANNUMBER3}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANNUMBER3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANNUMBER3">
             <div  *ngIf="testBool && toolTipList.PLANNUMBER3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANNUMBER3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANNUMBER3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VOLUME4,'has-warning':errorWarningData.Warning?.VOLUME4}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.VOLUME4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VOLUME4">
             <div  *ngIf="testBool && toolTipList.VOLUME4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VOLUME4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VOLUME4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOID4,'has-warning':errorWarningData.Warning?.FOLIOID4}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.FOLIOID4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FOLIOID4">
             <div  *ngIf="testBool && toolTipList.FOLIOID4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FOLIOID4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOLIOID4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LOT4,'has-warning':errorWarningData.Warning?.LOT4}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.LOT4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LOT4">
             <div  *ngIf="testBool && toolTipList.LOT4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOT4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOT4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE4,'has-warning':errorWarningData.Warning?.PLANTYPE4}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANTYPE4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANTYPE4">
             <div  *ngIf="testBool && toolTipList.PLANTYPE4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANTYPE4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANTYPE4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER4,'has-warning':errorWarningData.Warning?.PLANNUMBER4}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANNUMBER4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANNUMBER4">
             <div  *ngIf="testBool && toolTipList.PLANNUMBER4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANNUMBER4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANNUMBER4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VOLUME5,'has-warning':errorWarningData.Warning?.VOLUME5}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.VOLUME5?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VOLUME5">
             <div  *ngIf="testBool && toolTipList.VOLUME5" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VOLUME5.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VOLUME5?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOID5,'has-warning':errorWarningData.Warning?.FOLIOID5}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.FOLIOID5?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FOLIOID5">
             <div  *ngIf="testBool && toolTipList.FOLIOID5" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FOLIOID5.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOLIOID5?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LOT5,'has-warning':errorWarningData.Warning?.LOT5}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.LOT5?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LOT5">
             <div  *ngIf="testBool && toolTipList.LOT5" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOT5.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOT5?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE5,'has-warning':errorWarningData.Warning?.PLANTYPE5}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANTYPE5?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANTYPE5">
             <div  *ngIf="testBool && toolTipList.PLANTYPE5" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANTYPE5.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANTYPE5?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER5,'has-warning':errorWarningData.Warning?.PLANNUMBER5}">
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.PLANNUMBER5?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLANNUMBER5">
             <div  *ngIf="testBool && toolTipList.PLANNUMBER5" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLANNUMBER5.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLANNUMBER5?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LOTSECTION,'has-warning':errorWarningData.Warning?.LOTSECTION}">
            <mat-label>Section</mat-label>
            <input matInput name="Section"  matTooltip="{{(toolTipList)?toolTipList.LOTSECTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LOTSECTION">
             <div  *ngIf="testBool && toolTipList.LOTSECTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LOTSECTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOTSECTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BLOCK,'has-warning':errorWarningData.Warning?.BLOCK}">
            <mat-label>Block</mat-label>
            <input matInput name="Block"  matTooltip="{{(toolTipList)?toolTipList.BLOCK?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BLOCK">
             <div  *ngIf="testBool && toolTipList.BLOCK" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BLOCK.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BLOCK?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STAGENUMBER,'has-warning':errorWarningData.Warning?.STAGENUMBER}">
            <mat-label>Stage No</mat-label>
            <input matInput name="StageNo"  matTooltip="{{(toolTipList)?toolTipList.STAGENUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STAGENUMBER">
             <div  *ngIf="testBool && toolTipList.STAGENUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STAGENUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STAGENUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.REDEVELOPMENTNUMBER,'has-warning':errorWarningData.Warning?.REDEVELOPMENTNUMBER}">
            <mat-label>Redevelopment No</mat-label>
            <input name="RedevelopmentNo" matInput  matTooltip="{{(toolTipList)?toolTipList.REDEVELOPMENTNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="REDEVELOPMENTNUMBER">
             <div  *ngIf="testBool && toolTipList.REDEVELOPMENTNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REDEVELOPMENTNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REDEVELOPMENTNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>