export const environment = {
    production: true,
    serviceWorkerEnabled: true,
    hmr: false,
    APIEndpointDeliverable: "https://apitest.silq.online/",
    APIEndpoint: 'https://api10test.silq.com.au/',
    WSUrl: 'wss://api10test.silq.com.au/',

    ReportUrl: 'https://reports.api10test.silq.com.au/',
    // MainSocketUrl:'wss://wk7jsdg3k6.execute-api.ap-southeast-2.amazonaws.com/staging/',
    MainSocketUrl:'wss://sockets.api10test.silq.com.au/',
    MainSocketMatterUrl:'wss://wk7jsdg3k6.execute-api.ap-southeast-2.amazonaws.com/staging/',
    
    // WSReportUrl: 'wss://reports.api10test.silq.com.au/',
    ReportAPIEndpoint: 'https://reports.api10test.silq.com.au/',

    // WSLicenceUrl: 'wss://licence.api10test.silq.com.au/',

    // WSDocumentsUrl: 'wss://documents.api10test.silq.com.au/',
    DocumentsUrl: 'https://documents.api10test.silq.com.au/',
    DocumentsAPIEndpoint: 'https://documents.api10test.silq.com.au/',

    // WSDatabaseUrl: 'wss://database.api10test.silq.com.au/',
    DatabaseAPIEndpoint: 'https://database.api10test.silq.com.au/',

    // REDIRECTURL:'https://online.silq.com.au/v10/',
      REDIRECTURL:'https://beta10.moontechnolabs.com/silqWebApp/',
      REDIRECTNEWUIURL:'https://beta10.moontechnolabs.com/silqWebAppV10/', //FOR V11 INTO V10

    // Microsoft Login Url
    MSLOGINURL:"https://api10test.silq.com.au/OAuth2Start?AuthType=MSSSO",

    APIKEY: 'SNGMTUEEB2AJBFC9',
    VERSION: '11.5.50',
    WORDLIMIT: 40,
    EMAILPAGESIZE: 20,
    ISPRO: false,
    // URLPATH:'silqWebAppV10' // path of rout folder
    URLPATH:'' // path of rout folder in beta server root silqWebAppV11
};
