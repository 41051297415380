<div class="notifications-panel main_setting_div" fusePerfectScrollbar
    id="app_preferences">
    <div class="header" fxLayoutAlign="flex-end">
        <button mat-icon-button class="close-button"
            (click)="toggleSidebarOpen('notifications')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="header">
        <span class="title">Notifications</span>
    </div>
    <mat-divider cdkFocusRegionEnd></mat-divider>

    <mat-card class="example-card notification-card mt-12"
        *ngFor="let notification of notificationList;">
        <mat-card-title class="font-weight-700">
            <mat-icon class="float-right">close</mat-icon>
            <span class="title">{{notification.HEADING}}</span>
        </mat-card-title>
        <mat-card-content>
            <div class="content mb-12">{{notification.DESCRIPTION}} </div>
            <mat-chip-list>
                <mat-chip>
                    <mat-icon class="content-icon">access_time</mat-icon>
                    {{notification.DATEANDTIME}}</mat-chip>
            </mat-chip-list>
        </mat-card-content>
    </mat-card>
</div>
