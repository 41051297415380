import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
} from "@angular/core";
import { merge, Subject } from "rxjs";

import { FuseNavigationService } from "src/@fuse/components/navigation/navigation.service";
import { Pipe, PipeTransform } from "@angular/core";
import { ToastrService } from "ngx-toastr";
// import { ViewRef_ } from '@angular/core/src/view';
import * as $ from "jquery";
import { GetFavouriteService } from "@_services/get-favourite.service";
import { BehaviorService } from "@_services/Behavior.service";
declare var $: any;

@Component({
    selector: "fuse-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FuseNavigationComponent implements OnInit, OnDestroy {
    @Input()
    layout = "vertical";
    target: any;
    EDITFavourite: any;
    testBoolFavourite: boolean = JSON.parse(
        localStorage.getItem("testBoolFavourite")
    );
    @Input()
    navigation: any;
    filterName: string;
    searchpage: string = "";
    //All pages array
    user: any;
    page: any = [];
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    favouriteMenuList: any = [];

    isMenuFolded: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        public getFavouriteService: GetFavouriteService,
        private toastr: ToastrService,
        public behaviorService: BehaviorService
    ) {
        this.behaviorService.isMenuFolded$
            .subscribe((data) => {
                this.isMenuFolded = data;
            });

        this.behaviorService.toolBaarSetting$
            .subscribe((result) => {
                this.target =
                    result && result.OPENINNEWTABWINDOWS
                        ? result.OPENINNEWTABWINDOWS
                        : {};
                this.onSaveData();
            });
        this.behaviorService.appPermissionsSetting$
            .subscribe((result) => {
                let paresRes: any = result;
                let currentUser = JSON.parse(
                    localStorage.getItem("currentUser")
                );
                if (paresRes == null) {
                    paresRes = [];
                    this.page = this.page.filter((e) => e.ID != "dashboard");
                } else {
                    if (currentUser.EmailSystem == "SMTP") {
                        this.page = this.page.filter((e) => e.ID != "inbox");
                    } else if (paresRes[27]["Snap Shot"] == 0) {
                        this.page = this.page.filter(
                            (e) => e.ID != "dashboard"
                        );
                    } else {
                        let teTabID = {
                            matters: "MATTERS",
                            contact: "CONTACTS",
                            invoice: "INVOICES",
                            time_entries: "TIMEENTRIES",
                            diary: "DIARY",
                            "spend-money": "SPENDMONEY",
                            authorities: "AUTHORITIES",
                            searching: "SEARCHING",
                            "full-Safe-Custody": "SAFECUSTODY",
                            "Safe-Custody": "SAFECUSTODY",
                            "trust-money": "TRUSTMONEY",
                            "trust-end-month": "TRUSTENDOFMONTH",
                        };
                        this.page = [];
                        this.favouriteMenuList.forEach((items) => {
                            let tempTarget: any =
                                typeof this.target[teTabID[items.ID]] ==
                                    "undefined"
                                    ? 0
                                    : this.target[teTabID[items.ID]];
                            // this.page.push({ openInNewTab: tempTarget, "ID": items.ID, "TITLE": items.TITLE, "URL": items.URL, "STAR": ((this.testBoolFavourite == true) ? items.STAR : ''), "POSITION": items.POSITION, "type": "item", "icon": "icon_matter_d.ico" });
                            this.page.push({
                                openInNewTab: tempTarget,
                                ID: items.ID,
                                TITLE: items.TITLE,
                                URL: items.URL,
                                STAR: items.STAR,
                                POSITION: items.POSITION,
                                type: "item",
                                icon: "icon_matter_d.ico",
                            });
                        });
                    }
                }

                document.addEventListener("visibilitychange", (event) => {
                    let guid = JSON.parse(localStorage.getItem("currentUser"));
                    if(guid){
                        if (document.visibilityState == "visible") {
                            let fevIconList = JSON.parse(
                                localStorage.getItem("usersFavouritelist")
                            );
                            if (guid.EmailSystem == "SMTP") {
                                fevIconList.DATA.RECORDS = fevIconList.DATA.RECORDS.filter(
                                    (e) => e.ID != "inbox"
                                );
                            }
                            if (fevIconList) {
                                fevIconList.DATA.RECORDS.forEach((fevdata, i) => {
                                    if (this.page && this.page.length > 0) {
                                        if (fevdata.ID == this.page[i].ID) {
                                            this.page[i].STAR = fevdata.STAR;
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
            });

        // Set the private defaults
        let guid = JSON.parse(localStorage.getItem("currentUser"));
        if (guid) {
            let Filters = {
                Action: "GetData",
                Filters: { USERGUID: guid.UserGuid },
            };
            let teTabID = {
                matters: "MATTERS",
                contact: "CONTACTS",
                invoice: "INVOICES",
                time_entries: "TIMEENTRIES",
                diary: "DIARY",
                "spend-money": "SPENDMONEY",
                authorities: "AUTHORITIES",
                searching: "SEARCHING",
                "full-Safe-Custody": "SAFECUSTODY",
                "Safe-Custody": "SAFECUSTODY",
                "trust-money": "TRUSTMONEY",
                "trust-end-month": "TRUSTENDOFMONTH",
            };
            let Favouritelist = [
                { ID: "matters", TITLE: "Matters", URL: "matters", STAR: "" },
                { ID: "contact", TITLE: "Contacts", URL: "contact", STAR: "" },
                {
                    ID: "time_entries",
                    TITLE: "Time entries",
                    URL: "time-entries",
                    STAR: "",
                },
                { ID: "diary", TITLE: "Diary", URL: "diary", STAR: "" },
                { ID: "invoice", TITLE: "Invoices", URL: "invoice", STAR: "" },
                {
                    ID: "spend-money",
                    TITLE: "Spend money",
                    URL: "spend-money",
                    STAR: "",
                },
                {
                    ID: "authorities",
                    TITLE: "Authorities",
                    URL: "authorities",
                    STAR: "",
                },
                {
                    ID: "searching",
                    TITLE: "Searching",
                    URL: "searching",
                    STAR: "",
                },
                {
                    ID: "full-Safe-Custody",
                    TITLE: "Safe Custody",
                    URL: "full-Safe-Custody",
                    STAR: "",
                },
                { ID: "task", TITLE: "Task", URL: "task", STAR: "" },
                {
                    ID: "trust-money",
                    TITLE: "Trust Money",
                    URL: "trust-money",
                    STAR: "",
                },
                {
                    ID: "trust-end-month",
                    TITLE: "Trust End Of Month",
                    URL: "trust-end-month",
                    STAR: "",
                },
                {
                    ID: "receive-money",
                    TITLE: "Receive money",
                    URL: "receive-money",
                    STAR: "",
                },
                {
                    ID: "conflict-check",
                    TITLE: "Conflict Check",
                    URL: "conflict-check",
                    STAR: "",
                },
                { ID: "inbox", TITLE: "Inbox", URL: "email", STAR: "" },
            ];
            if (guid.PRODUCTTYPE != "Barrister") {
                Favouritelist.push({
                    ID: "trust-money",
                    TITLE: "Trust Money",
                    URL: "trust-money",
                    STAR: "",
                });
                Favouritelist.push({
                    ID: "trust-end-month",
                    TITLE: "Trust End Of Month",
                    URL: "trust-end-month",
                    STAR: "",
                });
            }

            let response = JSON.parse(
                localStorage.getItem("usersFavouritelist")
            );

            if (response && response != null) {
                if (response.DATA && response.DATA.RECORDS == "") {
                    this.getFavouriteService
                        .setFavourite({ RECORDS: Favouritelist })
                        .subscribe((responses) => {
                            if (
                                responses.CODE == 200 &&
                                responses.STATUS == "success"
                            ) {
                                Favouritelist.forEach((items) => {
                                    let tempTarget: any =
                                        typeof this.target[teTabID[items.ID]] ==
                                            "undefined"
                                            ? 0
                                            : this.target[teTabID[items.ID]];
                                    // this.page.push({ openInNewTab: tempTarget, "ID": items.ID, "TITLE": items.TITLE, "URL": items.URL, "STAR": ((this.testBoolFavourite == true) ? items.STAR : ''), "type": "item", "icon": "icon_matter_d.ico" });
                                    this.page.push({
                                        openInNewTab: tempTarget,
                                        ID: items.ID,
                                        TITLE: items.TITLE,
                                        URL: items.URL,
                                        STAR: items.STAR,
                                        type: "item",
                                        icon: "icon_matter_d.ico",
                                    });
                                });
                            }
                        });
                } else {
                    this.favouriteMenuList = response.DATA.RECORDS;
                    this.favouriteMenuList.forEach((items) => {
                        let tempTarget: any =
                            typeof this.target[teTabID[items.ID]] == "undefined"
                                ? 0
                                : this.target[teTabID[items.ID]];
                        // this.page.push({ openInNewTab: tempTarget, "ID": items.ID, "TITLE": items.TITLE, "URL": items.URL, "STAR": ((this.testBoolFavourite == true) ? items.STAR : ''), "POSITION": items.POSITION, "type": "item", "icon": "icon_matter_d.ico" });
                        this.page.push({
                            openInNewTab: tempTarget,
                            ID: items.ID,
                            TITLE: items.TITLE,
                            URL: items.URL,
                            STAR: items.STAR,
                            POSITION: items.POSITION,
                            type: "item",
                            icon: "icon_matter_d.ico",
                        });
                    });
                    if (
                        this.appPermissions[27] &&
                        this.appPermissions[27]["Snap Shot"] == 0
                    ) {
                        this.page = this.page.filter(
                            (e) => e.ID != "dashboard"
                        );
                    }
                    if (guid.EmailSystem == "SMTP") {
                        this.page = this.page.filter((e) => e.ID != "inbox");
                    }
                }
            } else {
                this.getFavouriteService
                    .GetFavourite(Filters)
                    .subscribe(
                        (response) => {
                            if (
                                response.CODE == 200 &&
                                response.STATUS == "success"
                            ) {
                                localStorage.setItem(
                                    "usersFavouritelist",
                                    JSON.stringify(response)
                                );
                                if (response.DATA.RECORDS == "") {
                                    this.getFavouriteService
                                        .setFavourite({
                                            RECORDS: Favouritelist,
                                        })
                                        .subscribe((responses) => {
                                            if (
                                                responses.CODE == 200 &&
                                                responses.STATUS == "success"
                                            ) {
                                                Favouritelist.forEach(
                                                    (items) => {
                                                        let tempTarget: any =
                                                            typeof this.target[
                                                                teTabID[
                                                                items.ID
                                                                ]
                                                            ] == "undefined"
                                                                ? 0
                                                                : this.target[
                                                                teTabID[
                                                                items
                                                                    .ID
                                                                ]
                                                                ];
                                                        // this.page.push({ openInNewTab: tempTarget, "ID": items.ID, "TITLE": items.TITLE, "URL": items.URL, "STAR": ((this.testBoolFavourite == true) ? items.STAR : ''), "type": "item", "icon": "icon_matter_d.ico" });
                                                        this.page.push({
                                                            openInNewTab:
                                                                tempTarget,
                                                            ID: items.ID,
                                                            TITLE: items.TITLE,
                                                            URL: items.URL,
                                                            STAR: items.STAR,
                                                            type: "item",
                                                            icon: "icon_matter_d.ico",
                                                        });
                                                    }
                                                );
                                            }
                                        });
                                } else {
                                    this.favouriteMenuList =
                                        response.DATA.RECORDS;
                                    this.favouriteMenuList.forEach((items) => {
                                        let tempTarget: any =
                                            typeof this.target[
                                                teTabID[items.ID]
                                            ] == "undefined"
                                                ? 0
                                                : this.target[
                                                teTabID[items.ID]
                                                ];
                                        // this.page.push({ openInNewTab: tempTarget, "ID": items.ID, "TITLE": items.TITLE, "URL": items.URL, "STAR": ((this.testBoolFavourite == true) ? items.STAR : ''), "POSITION": items.POSITION, "type": "item", "icon": "icon_matter_d.ico" });
                                        this.page.push({
                                            openInNewTab: tempTarget,
                                            ID: items.ID,
                                            TITLE: items.TITLE,
                                            URL: items.URL,
                                            STAR: items.STAR,
                                            POSITION: items.POSITION,
                                            type: "item",
                                            icon: "icon_matter_d.ico",
                                        });
                                    });
                                    if (
                                        this.appPermissions[27] &&
                                        this.appPermissions[27]["Snap Shot"] ==
                                        0
                                    ) {
                                        this.page = this.page.filter(
                                            (e) => e.ID != "dashboard"
                                        );
                                    }
                                    if (guid.EmailSystem == "SMTP") {
                                        this.page = this.page.filter(
                                            (e) => e.ID != "inbox"
                                        );
                                    }
                                }
                            }
                        },
                        (error) => {
                            this.toastr.error(error);
                        }
                    );
            }
        }
    }
    changeDetection() {
        setTimeout(() => {
            if (!this._changeDetectorRef["destroyed"]) {
                this._changeDetectorRef.detectChanges();
            }
        }, 250);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * It runs once after the component's view has been fully initialized.
     */
    ngOnInit(): void {
        this.behaviorService.ToggleForUpadteFavourite$.subscribe((res) => {
            this.testBoolFavourite = res;
        });
        this.EDITFavourite = this.testBoolFavourite;
        this.navigation =
            this.navigation ||
            this._fuseNavigationService.getCurrentNavigation();
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .subscribe(() => {
                // Load the navigation
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        //Localstorage Data Get and start Display
        // this.user = JSON.parse(localStorage.getItem('names'));
        // if (this.user) {
        //   this.user.forEach(items => {
        //     this.onChange(items.TITLE);
        //   });
        // }
        this.changeDetection();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    onSaveData() {
        let tempPage = this.page;
        this.page = [];
        let teTabID = {
            matters: "MATTERS",
            contact: "CONTACTS",
            invoice: "INVOICES",
            time_entries: "TIMEENTRIES",
            diary: "DIARY",
            "spend-money": "SPENDMONEY",
            authorities: "AUTHORITIES",
            searching: "SEARCHING",
            "full-Safe-Custody": "SAFECUSTODY",
            "Safe-Custody": "SAFECUSTODY",
            "trust-money": "TRUSTMONEY",
            "trust-end-month": "TRUSTENDOFMONTH",
        };
        tempPage.forEach((items) => {
            let tempTarget: any =
                typeof this.target[teTabID[items.ID]] == "undefined"
                    ? 0
                    : this.target[teTabID[items.ID]];
            this.page.push({
                openInNewTab: tempTarget,
                ID: items.ID,
                TITLE: items.TITLE,
                URL: items.URL,
                STAR: items.STAR,
                POSITION: items.POSITION,
                type: "item",
                icon: "icon_matter_d.ico",
            });

            // this.page.push({ openInNewTab: tempTarget, "ID": items.ID, "TITLE": items.TITLE, "URL": items.URL, "STAR": ((this.testBoolFavourite == true) ? items.STAR : ''), "POSITION": items.POSITION, "type": "item", "icon": "icon_matter_d.ico" });
        });
    }

    //For click
    onChange(data) {
        const values = data["values"];
        const index = data["index"];

        if (this.page[index].STAR == "") {
            this.page[index].STAR = "star";
        } else {
            this.page[index].STAR = "";
        }

        // this.page.forEach(items => {
        //   if (items.STAR == '') {
        //     if (items.TITLE == values) {
        //       items.STAR = 'star';
        //     }
        //   } else {
        //     if (items.TITLE == values) {
        //       items.STAR = '';
        //     }
        //   }
        // });

        // this.page.forEach(items => {
        //   if (items.STAR == '') {
        //     if (items.TITLE == values) {
        //       items.STAR = 'star';
        //     } else {
        //       items.STAR = '';
        //     }
        //   } else {
        //     if (items.TITLE == values) {
        //       items.STAR = '';
        //     }
        //   }
        // });

        let guid = JSON.parse(localStorage.getItem("currentUser"));
        if (guid) {
            let favouritedatas = { RECORDS: this.page };
            this.getFavouriteService
                .setFavourite(favouritedatas)
                .subscribe(
                    (response) => {
                        if (
                            response.CODE == 200 &&
                            response.STATUS == "success"
                        ) {
                            let stordData = JSON.parse(
                                localStorage.getItem("usersFavouritelist")
                            );
                            if (stordData) {
                                let convertFlages = Object.assign(
                                    stordData.DATA.RECORDS
                                );

                                convertFlages.forEach(async (iconsData) => {
                                    if (iconsData.TITLE == values) {
                                        if (iconsData.STAR == "") {
                                            iconsData.STAR = "star";
                                        } else {
                                            iconsData.STAR = "";
                                        }
                                    }
                                });

                                stordData.DATA.RECORDS = convertFlages;
                                localStorage.setItem(
                                    "usersFavouritelist",
                                    JSON.stringify(stordData)
                                );
                            }
                            // this.toastr.error(error);
                        }
                    },
                    (error) => {
                        this.toastr.error(error);
                    }
                );
        }
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        // setTimeout(() => {

        // }, 1000);
        this.changeIcon();
        const vph = $(window).height();
        $(".sidebar_btm_div").css({ height: vph - 50 + "px" });

        $(window).resize(function () {
            const vph = $(window).height();
            $(".sidebar_btm_div").css({ height: vph - 50 + "px" });
        });
    }
    //clear textbox
    clearSearch() {
        this.searchpage = "";
    }
    //change icon
    icon = "search";
    changeIcon() {
        let fevIconList = JSON.parse(
            localStorage.getItem("usersFavouritelist")
        );
        let guid = JSON.parse(localStorage.getItem("currentUser"));
        if (fevIconList) {
            if (guid.EmailSystem == "SMTP") {
                fevIconList.DATA.RECORDS = fevIconList.DATA.RECORDS.filter(
                    (e) => e.ID != "inbox"
                );
            } setTimeout(() => {
                fevIconList.DATA.RECORDS.forEach((fevdata, i) => {
                    if (fevdata.ID == this.page[i].ID) {
                        this.page[i].STAR = fevdata.STAR;
                    }
                });
            }, 1000);
        }

        if (this.icon === "search") {
            this.icon = "close";
            this._changeDetectorRef.detectChanges();
        } else {
            this.icon = "search";
        }
    }
    ToggleForUpadteFavourite(data) {
        this.testBoolFavourite = !this.testBoolFavourite;
        // JSON.parse(localStorage.getItem("testBoolFavourite"))
        // this.EDITFavourite = this.testBoolFavourite
        this.behaviorService.ToggleForUpadteFavouriteData(
            this.testBoolFavourite
        );
        localStorage.setItem(
            "testBoolFavourite",
            JSON.stringify(this.testBoolFavourite)
        );
    }
}
@Pipe({ name: "filterByName" })
export class filterNames implements PipeTransform {
    transform(page: any[], nameToFilter: string): any[] {
        if (!page) return [];
        if (!nameToFilter) return [];
        nameToFilter = nameToFilter.toLowerCase();
        return page.filter((it) => {
            return it.TITLE.toLowerCase().includes(nameToFilter);
        });
    }
}
