<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>Browse Folder
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="one select_matter_main_div">
            <div class="example-containerdata">
                <div class="example-loading-shade" *ngIf="isLoadingResults">
                    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                </div>
                <div class="example-containerdata resizable-table">
                    <mat-table [dataSource]="folderData" matSortActive="{{sortactive}}"
                        matSortDirection="{{sortDirection}}" matSortDisableClear [@animateStagger]="{value:'50'}"
                        matSort class="">
                        <ng-container matColumnDef="TEMPLATETYPE">
                            <mat-header-cell *matHeaderCellDef mwlResizable 
                                (resizeEnd)="onResizeEnd($event, 'TEMPLATETYPE')"
                                mwlResizeHandle
                            [resizeEdges]="{ right: true }">
                                <div class="resize-handle-right" mwlResizeHandle
                            [resizeEdges]="{ right: true }"></div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <img *ngIf="row.TEMPLATETYPE =='Folder'" src="assets/icons/web_app/folder.ico">
                                <img *ngIf="row.TEMPLATETYPE =='Go_Back'" src="assets/icons/web_app/folderup.ico">
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="FOLDERNAME">
                            <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable 
                                (resizeEnd)="onResizeEnd($event, 'FOLDERNAME')"
                                mwlResizeHandle
                            [resizeEdges]="{ right: true }">
                                <div class="resize-handle-right" mwlResizeHandle
                            [resizeEdges]="{ right: true }"></div>
                                Folder Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.FOLDERNAME }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="FULLPATH">
                            <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable 
                                (resizeEnd)="onResizeEnd($event, 'FULLPATH')"
                                mwlResizeHandle
                            [resizeEdges]="{ right: true }">
                                <div class="resize-handle-right" mwlResizeHandle
                            [resizeEdges]="{ right: true }"></div>
                                Full Path
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.FULLPATH }} </mat-cell>
                        </ng-container>
                        <mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true">
                        </mat-header-row>
                        <mat-row [style.background]="highlightedRows == row.FOLDERNAME ? selectedColore : ''"
                            [ngClass]="highlightedRows == row.FOLDERNAME ? 'row-text-colore' : ''"
                            highlightedRows=row.FOLDERNAME *matRowDef="let row; columns: displayedColumns;"
                            (click)="clickRow(row);highlightedRows = row.FOLDERNAME " (dblclick)='doubleClick(row)'
                            matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                        </mat-row>
                    </mat-table>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" [pageSize]="pageSize?.folder_page"
                    (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <button mat-raised-button [mat-dialog-close]="currentMatterData" color="accent"> Select </button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
        </div>
    </mat-dialog-actions>
</div>