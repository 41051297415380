<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_general_receipt_main" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">
        General Receipt
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="generalReceiptForm" id="generalReceiptForm" class="generalReceiptForm"
                [formGroup]="generalReceiptForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMEDATE,'has-warning':errorWarningData.Warning?.INCOMEDATE}">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                            (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)"
                            formControlName="INCOMEDATETEXT"  matTooltip="{{(toolTipList)?toolTipList.INCOMEDATE?.HOVER:''}}">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMECLASS,'has-warning':errorWarningData.Warning?.INCOMECLASS}">
                        <mat-label>Class</mat-label>
                        <mat-select formControlName="INCOMECLASS" (selectionChange)="classChange($event.value)">
                            <mat-option value="Income">Income</mat-option>
                            <mat-option value="Capital">Capital</mat-option>
                            <!-- <mat-option value="Receipt">Receipt</mat-option> -->
                            <mat-option value="GST Refund"> GST Refund </mat-option>
                            <mat-option value="Tax Refund">Tax Refund</mat-option>
                            <mat-option value="Personal">Personal</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMECLASS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMECLASS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMETYPE,'has-warning':errorWarningData.Warning?.INCOMETYPE}">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="INCOMETYPE" matTooltip="{{(toolTipList)?toolTipList.INCOMETYPE?.HOVER:''}}">
                            <mat-option value="Cash">Cash</mat-option>
                            <mat-option value="Cheque">Cheque</mat-option>
                            <mat-option value="Credit Card">Credit Card</mat-option>
                            <mat-option value="Direct Deposit">Direct Deposit</mat-option>
                            <mat-option value="Direct Withdrawal">Direct Withdrawal</mat-option>
                            <mat-option value="Money Order">Money Order</mat-option>
                            <mat-option value="Transfer">Transfer</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMETYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMETYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PAYEE,'has-warning':errorWarningData.Warning?.PAYEE}">
                        <mat-label>Payor</mat-label>
                        <!-- <input matInput formControlName="PAYEE" [matAutocomplete]="payeeText" matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}">
                        <mat-autocomplete #payeeText="matAutocomplete" [panelWidth]="200"
                            (optionSelected)="PayeeTypeChange($event.option.value)">
                            <mat-option *ngFor="let val of filteredOptions | async" [value]="val.CONTACTNAME">
                                {{val.CONTACTNAME}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="selectClient('payee')">
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip=""
                                matTooltipPosition="above">
                        </mat-icon> -->
                        <mat-chip-list #chipList aria-label="Matter selection">
                            <mat-chip *ngFor="let Contactdata of Contactname" (removed)="removeContact(Contactdata)" class="mat-chips-selected">
                                {{Contactdata| slice:0:40}}<ng-container *ngIf="Contactdata.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input  matInput
                                (keydown.Tab)="Contactdatashow($event)"  matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}" 
                                formControlName="PAYEE" #MatterInput2 [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUp($event)" [ngClass]="Contactname.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1" *ngFor="let ContactData of Contactlist" (click) ="selectedContact(ContactData)" >
                                <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>{{ContactData.CONTACTNAME}}<span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="(this.MatterInputContact && this.MatterInputContact.nativeElement.value =='')? selectClient('payee'):null"   [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example pr-4" fxFlex="20"
                        [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT}">
                        <mat-label>Amount</mat-label>
                        <!-- currencyMask -->
                        <!-- (change)="amountChange($event, 'AMOUNT')" -->
                        <input type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true" style="text-align: right;" matInput class="sideText" formControlName="AMOUNT" [(ngModel)]="AMOUNT" (focusout)="amountVal()"  matTooltip="{{(toolTipList)?toolTipList.AMOUNT?.HOVER:''}}">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                        </mat-form-field>
                    <mat-form-field appearance="outline" class="example pr-4" fxFlex="20">
                        <mat-label>GST Type</mat-label>
                        <mat-select (selectionChange)="gstTypeChange($event.value)" formControlName="gsttype" id="gsttypeselect" [disabled]="isHide">
                            <mat-option *ngFor="let val of gsttypeData" [value]="val.id">{{val.text}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GSTTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GSTTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example pr-4" fxFlex="{{flaxValue}}"
                        *ngIf="flaxValue==20"
                        [ngClass]="{'has-error':errorWarningData.Error?.GST,'has-warning':errorWarningData.Warning?.GST}">
                        <mat-label>GST</mat-label>
                        <!-- currencyMask -->
                        <input matInput type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." class="sideText" formControlName="GST" [readonly]="isGstReadonly" [(ngModel)]="GST" (focusout)="gstVal()"  matTooltip="{{(toolTipList)?toolTipList.GST?.HOVER:''}}" [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                        </mat-form-field>
                    <mat-form-field appearance="outline" class="example pr-4" fxFlex="{{flaxValue}}"
                        [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTGUID,'has-warning':errorWarningData.Warning?.BANKACCOUNTGUID}">
                        <mat-label>Bank A/C</mat-label>
                        <input matInput formControlName="BANKACCOUNTGUIDTEXT" readonly>
                        <mat-icon matSuffix>
                            <button id="bank" class="matternews" (click)="BankingDialogOpen('BANK ACCOUNT')"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"></button>
                        </mat-icon>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKACCOUNTGUID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKACCOUNTGUID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example pr-4" fxFlex="{{flaxValue}}"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMEACCOUNTGUID,'has-warning':errorWarningData.Warning?.INCOMEACCOUNTGUID}">
                        <mat-label>{{classText}}</mat-label>
                        <input matInput formControlName="INCOMEACCOUNTGUIDTEXT"  matTooltip="{{(toolTipList)?toolTipList.INCOMEACCOUNTGUIDTEXT?.HOVER:''}}">
                        <mat-icon matSuffix>
                            <button id="bank" class="matternews" (click)="BankingDialogOpen(classType)"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"></button>
                        </mat-icon>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMEACCOUNTGUID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMEACCOUNTGUID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <mat-form-field appearance="outline" class="example pr-4" fxFlex="100"
                    [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}">
                    <mat-label>Note</mat-label>
                    <textarea matInput formControlName="NOTE" matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}"></textarea>
               <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NOTE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                </mat-form-field>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button *ngIf="action=='edit'" color="accent" (click)="SaveGeneralReceipt()"
            [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button *ngIf="action!='edit'" color="accent" (click)="SaveGeneralReceipt()"
            [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>