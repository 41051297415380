<button
    mat-raised-button
    color="primary"
    id="refreshTimeEntryTab"
    (click)="refreshTab(false)"
>
    Refresh
</button>
<div
    id="time_entries"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
    [ngClass]="COLOR"
>
    <div class="content" fxLayoutAlign="center">
        <div
            class="center table-div more_tbl_clmn list_tbl_main nospace"
            fusePerfectScrollbar
        >
            <div class="matter-serach-div sticky_search_div scroll-issue">
                <!-- new filter -->

                <form
                    [formGroup]="TimeEnrtyForm"
                    class="align-center display-flex filter-menuwrap"
                    *ngIf="ActiveTab == 'full-time-entries'"
                    id="filter-form-menu-wrap"
                >
                    <div
                        class="align-center display-flex flex-wrap filter-menublock"
                    >
                        <div class="add-filterwrap">
                            <!-- button -->
                            <button
                                mat-button
                                [matMenuTriggerFor]="dummyButton"
                                [disabled]="true"
                                style="display: none"
                            >
                                Filters
                            </button>
                            <mat-menu #dummyButton="matMenu"> </mat-menu>
                            <!-- ends here ~ button -->
                            <button
                                mat-button
                                [matMenuTriggerFor]="menuFilter"
                                class="filter-button"
                                [disabled]="
                                    optionalFilterList
                                        | enableDisableFilter
                                            : TimeEnrtyForm.value
                                "
                                (click)="
                                    backToFilter('optional-Discount');
                                    showFiltersList = true
                                "
                            >
                                <img
                                    [src]="
                                        currentUserData.PRODUCTTYPE ==
                                        'Solicitor'
                                            ? 'assets/icons/filter-icon.png'
                                            : 'assets/icons/filter-icon-barrister.png'
                                    "
                                    alt="Filter"
                                    class="filter-icon"
                                />
                                <span
                                    [ngClass]="
                                        (optionalFilterList
                                        | enableDisableFilter
                                            : TimeEnrtyForm.value)
                                            ? 'disabled-filter-text'
                                            : ''
                                    "
                                    >Filters</span
                                >
                            </button>

                            <mat-icon
                                class="icon-btn close-btn"
                                (click)="resetFilter()"
                                *ngIf="optionalFilters?.length > 0"
                                >close</mat-icon
                            >
                            <mat-icon
                                class="icon-btn"
                                (click)="refreshTab(false)"
                                >refresh</mat-icon
                            >

                            <mat-menu
                                #menuFilter="matMenu"
                                (click)="preventDefault($event)"
                            >
                                <div class="filterscroll" [ngClass]="COLOR">
                                    <ng-container
                                        *ngFor="
                                            let optionalFilterData of optionalFilterList
                                        "
                                    >
                                        <div
                                            *ngIf="
                                                optionalFilterData.FILTERTYPE !=
                                                'HIDDEN'
                                            "
                                        >
                                            <button
                                                class="addbutton"
                                                [ngClass]="COLOR"
                                                *ngIf="
                                                    !optionalFilterData.FILTERAPPLY &&
                                                    showFiltersList
                                                "
                                                (click)="
                                                    preventDefault($event);
                                                    activeCurrentFilter(
                                                        'optional-Discount',
                                                        optionalFilterData
                                                    );
                                                    addNewFilter(
                                                        activeOptionalFilter
                                                    );
                                                    showFiltersList = false
                                                "
                                            >
                                                <mat-icon>add_circle</mat-icon
                                                >{{
                                                    optionalFilterData.DESCRIPTION
                                                }}
                                            </button>
                                            <div
                                                class="menu-submenu"
                                                id="optional-Discount"
                                            >
                                                <h5
                                                    class="filter-title"
                                                    *ngIf="activeOptionalFilter"
                                                >
                                                    <mat-icon
                                                        class="back-icon"
                                                        (click)="
                                                            backToFilter(
                                                                'optional-Discount'
                                                            );
                                                            preventDefault(
                                                                $event
                                                            );
                                                            showFiltersList = true
                                                        "
                                                        >arrow_back_ios </mat-icon
                                                    >Filter by
                                                    {{
                                                        activeOptionalFilter.DESCRIPTION
                                                    }}
                                                </h5>

                                                <div
                                                    *ngIf="activeOptionalFilter"
                                                >
                                                    <ng-container
                                                        formArrayName="FILTERSLIST"
                                                    >
                                                        <div
                                                            *ngFor="
                                                                let filter of _filterList;
                                                                let j = index
                                                            "
                                                            class="position-relative"
                                                        >
                                                            <ng-container
                                                                [formGroupName]="
                                                                    j
                                                                "
                                                            >
                                                                <mat-form-field
                                                                    appearance="outline"
                                                                    class="width-full"
                                                                    (click)="
                                                                        preventDefault(
                                                                            $event
                                                                        )
                                                                    "
                                                                >
                                                                    <mat-select
                                                                        (selectionChange)="
                                                                            optionalDateFilter(
                                                                                $event,
                                                                                filter,
                                                                                'default',
                                                                                -1
                                                                            )
                                                                        "
                                                                        name="OPERATION"
                                                                        [(ngModel)]="
                                                                            addFilter.OPERATION
                                                                        "
                                                                        formControlName="OPERATION"
                                                                        #operationFilter
                                                                    >
                                                                        <mat-option
                                                                            *ngFor="
                                                                                let d of filter.DATEFILTERLIST
                                                                            "
                                                                            [value]="
                                                                                d.value
                                                                            "
                                                                            >{{
                                                                                d.description
                                                                            }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.selectedFilter !==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE ==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE ==
                                                                                'CURRENCY')
                                                                    "
                                                                >
                                                                    <span
                                                                        class="back-tick"
                                                                    >
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow"
                                                                        />
                                                                    </span>
                                                                    <mat-form-field
                                                                        class="width-full"
                                                                        appearance="outline"
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'NUMBER'
                                                                        "
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                    >
                                                                        <input
                                                                            matInput
                                                                            placeholder=""
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            appNumberMask
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                        />
                                                                    </mat-form-field>

                                                                    <mat-form-field
                                                                        class="width-full"
                                                                        appearance="outline"
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'CURRENCY'
                                                                        "
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                    >
                                                                        <input
                                                                            matInput
                                                                            placeholder=""
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            currencyMask
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                        />
                                                                    </mat-form-field>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.FILTERTYPE !==
                                                                            'NUMBER' &&
                                                                        filter.FILTERTYPE !==
                                                                            'CURRENCY' &&
                                                                        filter.FILTERTYPE !==
                                                                            'DATE'
                                                                    "
                                                                >
                                                                    <span
                                                                        class="back-tick"
                                                                    >
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow"
                                                                        />
                                                                    </span>
                                                                    <mat-form-field
                                                                        class="width-full"
                                                                        appearance="outline"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                    >
                                                                        <input
                                                                            matInput
                                                                            placeholder=""
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                        />
                                                                    </mat-form-field>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.selectedFilter ==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE ==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE ==
                                                                                'CURRENCY')
                                                                    "
                                                                >
                                                                    <span
                                                                        class="back-tick"
                                                                    >
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow"
                                                                        />
                                                                    </span>
                                                                    <div
                                                                        class="display-flex align-center"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'NUMBER'
                                                                        "
                                                                    >
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline"
                                                                        >
                                                                            <input
                                                                                matInput
                                                                                placeholder=""
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                                appNumberMask
                                                                            />
                                                                        </mat-form-field>
                                                                        <span
                                                                            class="middle-caption"
                                                                            >and</span
                                                                        >
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline"
                                                                        >
                                                                            <input
                                                                                matInput
                                                                                placeholder=""
                                                                                name="VALUE2"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                required
                                                                                appNumberMask
                                                                            />
                                                                        </mat-form-field>
                                                                    </div>

                                                                    <div
                                                                        class="display-flex align-center"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'CURRENCY'
                                                                        "
                                                                    >
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline"
                                                                        >
                                                                            <input
                                                                                matInput
                                                                                placeholder=""
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                                currencyMask
                                                                            />
                                                                        </mat-form-field>
                                                                        <span
                                                                            class="middle-caption"
                                                                            >and</span
                                                                        >
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline"
                                                                        >
                                                                            <input
                                                                                matInput
                                                                                placeholder=""
                                                                                name="VALUE2"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                required
                                                                                currencyMask
                                                                            />
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.FILTERTYPE ==
                                                                            'DATE' &&
                                                                        filter.selectedFilter &&
                                                                        filter.selectedFilter !==
                                                                            'is in the last' &&
                                                                            filter.selectedFilter !==
                                                                                'is between' &&
                                                                        (filter.FILTERTYPE !==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE !==
                                                                                'CURRENCY')
                                                                    "
                                                                >
                                                                    <span
                                                                        class="back-tick"
                                                                    >
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow"
                                                                        />
                                                                    </span>
                                                                    <div
                                                                        class="display-flex align-center"
                                                                    >
                                                                        <mat-form-field
                                                                            appearance="outline"
                                                                            class="set-date"
                                                                            (click)="
                                                                                preventDefault(
                                                                                    $event
                                                                                )
                                                                            "
                                                                        >
                                                                            <input
                                                                                matInput
                                                                                [matDatepicker]="
                                                                                    picker6
                                                                                "
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                            />
                                                                            <mat-datepicker-toggle
                                                                                matSuffix
                                                                                [for]="
                                                                                    picker6
                                                                                "
                                                                            >
                                                                            </mat-datepicker-toggle>
                                                                            <mat-datepicker
                                                                                #picker6
                                                                            ></mat-datepicker>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        (filter.FILTERTYPE ==
                                                                            'DATE' &&
                                                                            !filter.selectedFilter) ||
                                                                        (filter.selectedFilter ==
                                                                            'is in the last' &&
                                                                            (filter.FILTERTYPE !==
                                                                                'NUMBER' ||
                                                                                filter.FILTERTYPE !==
                                                                                    'CURRENCY'))
                                                                    "
                                                                >
                                                                    <span
                                                                        class="back-tick"
                                                                    >
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow"
                                                                        />
                                                                    </span>
                                                                    <div
                                                                        class="display-flex align-center field-box"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                    >
                                                                        <mat-form-field
                                                                            class="set-date date-filter-field"
                                                                            appearance="outline"
                                                                        >
                                                                            <input
                                                                                matInput
                                                                                placeholder=""
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                                appNumberMask
                                                                            />
                                                                        </mat-form-field>
                                                                        <mat-form-field
                                                                            appearance="outline"
                                                                            class="set-date date-dropdown-selector"
                                                                        >
                                                                            <mat-select
                                                                                [value]="
                                                                                    'days'
                                                                                "
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                required
                                                                            >
                                                                                <mat-option
                                                                                    value="days"
                                                                                    >Days
                                                                                </mat-option>
                                                                                <mat-option
                                                                                    value="month"
                                                                                    >Month</mat-option
                                                                                >
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.FILTERTYPE ==
                                                                            'DATE' &&
                                                                        filter.selectedFilter ==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE !==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE !==
                                                                                'CURRENCY')
                                                                    "
                                                                >
                                                                    <mat-form-field
                                                                        appearance="outline"
                                                                        class="set-date"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                    >
                                                                        <input
                                                                            matInput
                                                                            [matDatepicker]="
                                                                                picker
                                                                            "
                                                                            #StartDate
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                        />
                                                                        <mat-datepicker-toggle
                                                                            matSuffix
                                                                            [for]="
                                                                                picker
                                                                            "
                                                                        >
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker
                                                                            #picker
                                                                        ></mat-datepicker>
                                                                    </mat-form-field>
                                                                    <span
                                                                        class="middle-caption"
                                                                        >and</span
                                                                    >
                                                                    <mat-form-field
                                                                        appearance="outline"
                                                                        class="set-date"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                    >
                                                                        <input
                                                                            matInput
                                                                            [matDatepicker]="
                                                                                picker2
                                                                            "
                                                                            #EndDate
                                                                            name="VALUE2"
                                                                            formControlName="VALUE2"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE2
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE2
                                                                            "
                                                                            required
                                                                        />
                                                                        <mat-datepicker-toggle
                                                                            matSuffix
                                                                            [for]="
                                                                                picker2
                                                                            "
                                                                        >
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker
                                                                            #picker2
                                                                        ></mat-datepicker>
                                                                    </mat-form-field>
                                                                </div>

                                                                <button
                                                                    mat-raised-button
                                                                    color="primary"
                                                                    class="width-full accent"
                                                                    (click)="
                                                                        addfilter(
                                                                            filter,
                                                                            operationFilter,
                                                                            $event
                                                                        )
                                                                    "
                                                                >
                                                                    Apply
                                                                </button>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-menu>
                        </div>

                        <!-- filter for fee Earner -->
                        <div class="position-relative" *ngIf="'' | productType">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters && activeFilters.FEEEARNER
                                        ? clearFilter('feeEarner', 'fee_earner')
                                        : false
                                "
                            >
                                {{
                                    activeFilters && activeFilters.FEEEARNER
                                        ? "cancel_circle"
                                        : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="activeMenucard"
                                class="buttonwith-text dotted-button"
                            >
                                Fee Earner<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        activeFilters &&
                                        activeFilters.feeEarnerDesc
                                            ? activeFilters.feeEarnerDesc
                                            : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon
                                    ></span
                                >
                            </button>
                            <mat-menu #activeMenucard="matMenu" #menu>
                                <mat-radio-group
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group"
                                    formControlName="dlpdrop"
                                >
                                    <mat-radio-button
                                        value="all"
                                        (click)="
                                            Filterconfig(
                                                {
                                                    USERID: 'all',
                                                    description: 'All'
                                                },
                                                'fee_earner'
                                            );
                                            FeeEarnerFilter(
                                                activeFilters.FEEEARNER
                                            )
                                        "
                                        >All</mat-radio-button
                                    >
                                    <mat-radio-button
                                        value=""
                                        (click)="
                                            Filterconfig(
                                                {
                                                    USERID: '',
                                                    description: 'None'
                                                },
                                                'fee_earner'
                                            );
                                            FeeEarnerFilter(
                                                activeFilters.FEEEARNER
                                            )
                                        "
                                        >None</mat-radio-button
                                    >
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        *ngFor="let TimerDrop of TimerDropData"
                                        value="{{ TimerDrop.USERID }}"
                                        (click)="
                                            Filterconfig(
                                                TimerDrop,
                                                'fee_earner'
                                            );
                                            FeeEarnerFilter(
                                                activeFilters.FEEEARNER
                                            )
                                        "
                                    >
                                        {{ TimerDrop.USERID }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                        </div>
                        <!-- ends here ~ filter for fee Earner -->

                        <!--  filter for uninvoiced work -->
                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters &&
                                    activeFilters.uninvoicedWork
                                        ? clearFilter(
                                              'uninvoicedWork',
                                              'uninvoiced_work'
                                          )
                                        : false
                                "
                            >
                                {{
                                    activeFilters &&
                                    activeFilters.uninvoicedWork
                                        ? "cancel_circle"
                                        : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="menucard"
                                class="buttonwith-text dotted-button"
                            >
                                Uninvoiced Work<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        activeFilters &&
                                        activeFilters.uninvoicedWorkDesc
                                            ? activeFilters.uninvoicedWorkDesc
                                            : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon
                                    ></span
                                >
                            </button>

                            <mat-menu #menucard="matMenu">
                                <mat-radio-group
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group"
                                    formControlName="uninvoicedWork"
                                >
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        *ngFor="
                                            let uninvoiceWork of uninvoiceWorkOptions
                                        "
                                        value="{{ uninvoiceWork.value }}"
                                        (click)="
                                            Filterconfig(
                                                uninvoiceWork,
                                                'uninvoiced_work'
                                            );
                                            UninvoicedWorkFilter(
                                                activeFilters.uninvoicedWork
                                            )
                                        "
                                    >
                                        {{ uninvoiceWork.description }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                        </div>
                        <!-- ends here ~ filter for uninvoiced work  -->
                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters &&
                                    activeFilters.typeDescription
                                        ? clearFilter(
                                              'type',
                                              'type'
                                          )
                                        : false
                                "
                            >
                                {{
                                    activeFilters &&
                                    activeFilters.typeDescription
                                        ? "cancel_circle"
                                        : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="menucard22"
                                class="buttonwith-text dotted-button"
                            >
                                Type<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        activeFilters &&
                                        activeFilters.typeDescription
                                            ? activeFilters.typeDescription
                                            : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon
                                    ></span
                                >
                            </button>

                            <mat-menu #menucard22="matMenu">
                                <mat-radio-group
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group"
                                    formControlName="type"
                                >
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        *ngFor="let type of TimeEntryiesTypes"
                                        value="{{ type.value }}"
                                        (click)="
                                            Filterconfig(
                                                type,
                                                'type'
                                            );getType(type);
                                        "
                                    >
                                        {{ type.description }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                        </div>
                        <!-- filter for day range -->
                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters && activeFilters.dayRange
                                        ? clearFilter('dayRange', 'day_range')
                                        : false
                                "
                            >
                                {{
                                    activeFilters && activeFilters.dayRange
                                        ? "cancel_circle"
                                        : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="menuMatterClass"
                                class="buttonwith-text dotted-button"
                            >
                                Date Range<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        activeFilters && activeFilters.dayRange
                                            ? activeFilters.dayRange
                                            : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon
                                    ></span
                                >
                            </button>
                            <mat-menu #menuMatterClass="matMenu">
                                <h5 class="filter-title">
                                    Filter by Date Range
                                </h5>
                                <mat-radio-group
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group"
                                    formControlName="DayRange"
                                >
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        *ngFor="let dayRange of dayRangeOptions"
                                        value="{{ dayRange.value }}"
                                        (click)="
                                            Filterconfig(dayRange, 'day_range');
                                            SelectDayRangeFilter(
                                                activeFilters.dayRange
                                            )
                                        "
                                    >
                                        {{ dayRange.description }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                        </div>
                        <!-- ends here ~ filter for day range -->

                        <!-- filter for date range -->
                        <div
                            class="position-relative"
                            *ngIf="forHideShowDateRangePicker"
                        >
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters && activeFilters.DateRange
                                        ? clearFilter('DateRange', 'date_range')
                                        : false
                                "
                            >
                                {{
                                    activeFilters && activeFilters.DateRange
                                        ? "cancel_circle"
                                        : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="menuFeeEarner"
                                class="buttonwith-text dotted-button"
                            >
                                Date Range<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        activeFilters && activeFilters.DateRange
                                            ? activeFilters.DateRange
                                            : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon
                                    ></span
                                >
                            </button>
                            <mat-menu #menuFeeEarner="matMenu">
                                <h5 class="filter-title">
                                    Filter by Date Range
                                </h5>
                                <mat-form-field
                                    appearance="outline"
                                    class="example dateinput"
                                >
                                    <input
                                        matInput
                                        [satDatepicker]="picker2"
                                        formControlName="date"
                                        (dateChange)="
                                            Filterconfig($event, 'date_range')
                                        "
                                    />
                                    <sat-datepicker #picker2 [rangeMode]="true">
                                    </sat-datepicker>
                                    <sat-datepicker-toggle
                                        matSuffix
                                        [for]="picker2"
                                    ></sat-datepicker-toggle>
                                </mat-form-field>

                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="width-full accent"
                                    (click)="
                                        DateRangeFilter(
                                            'change',
                                            activeFilters.DateRange
                                        )
                                    "
                                >
                                    Apply
                                </button>
                            </mat-menu>
                        </div>

                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    onFilterSearch('removeSearchString');
                                    clearFilter('SearchString', 'SearchString')
                                "
                                *ngIf="hasSearchVal"
                            >
                                {{
                                    hasSearchVal ? "cancel_circle" : ""
                                }}</mat-icon
                            >

                            <button
                                mat-button
                                class="buttonwith-text"
                                style="padding: 1px 5px"
                            >
                                Search<span
                                    class="filter-applytext display-flex align-center"
                                >
                                    <input
                                        matInput
                                        #searchField
                                        (keyup)="
                                            disabledMainMenuTrigger = true;
                                            onSearchChange($event)
                                        "
                                        formControlName="searchFilter"
                                        style="
                                            margin-top: 4px;
                                            text-align: left;
                                        "
                                        tabindex="6"
                                    />
                                    <mat-icon
                                        matSuffix
                                        (click)="
                                            onFilterSearch('Enter');
                                            preventDefault($event)
                                        "
                                        >search</mat-icon
                                    >
                                </span>
                            </button>
                        </div>

                        <!-- ends here ~ filter for date range -->

                        <!-- disabled field -->

                        <div class="position-relative">
                            <button
                                mat-button
                                class="buttonwith-text dotted-button no-icon"
                                *ngIf="
                                    appPermissions[2]['View Balances'] == '1'
                                "
                            >
                                Total Ex GST<span
                                    class="filter-applytext display-flex align-center"
                                    >${{
                                        TimeEnrtyForm.controls["ExGST"]?.value
                                    }}</span
                                >
                            </button>
                        </div>
                        <div class="position-relative">
                            <button
                                mat-button
                                class="buttonwith-text dotted-button no-icon"
                                *ngIf="
                                    appPermissions[2]['View Balances'] == '1'
                                "
                            >
                                Total Inc GST<span
                                    class="filter-applytext display-flex align-center"
                                    >${{
                                        TimeEnrtyForm.controls["IncGST"]?.value
                                    }}</span
                                >
                            </button>
                        </div>

                        <div class="position-relative">
                            <button
                                mat-button
                                class="buttonwith-text dotted-button no-icon"
                            >
                                {{ QuantityLable
                                }}<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        TimeEnrtyForm.controls["Units"]?.value
                                    }}</span
                                >
                            </button>
                        </div>

                        <div class="position-relative">
                            <button
                                mat-button
                                class="buttonwith-text dotted-button no-icon"
                            >
                                Days<span
                                    class="filter-applytext display-flex align-center"
                                    >{{
                                        TimeEnrtyForm.controls["Days"]?.value
                                    }}</span
                                >
                            </button>
                        </div>

                        <div class="position-relative">
                            <button
                                mat-button
                                class="buttonwith-text dotted-button no-icon"
                                *ngIf="
                                    appPermissions[2]['View Balances'] == '1'
                                "
                            >
                                Budget<span
                                    class="filter-applytext display-flex align-center"
                                    >${{
                                        TimeEnrtyForm.controls["Budget"]?.value
                                    }}</span
                                >
                            </button>
                        </div>

                        <!-- ends here ~ disabled field -->

                        <!-- ADD Optional Filter -->
                        <ng-container formArrayName="FILTERS">
                            <div
                                *ngFor="
                                    let filter of optionalFilters;
                                    let i = index
                                "
                                class="position-relative"
                            >
                                <ng-container [formGroupName]="i">
                                    <mat-icon
                                        class="badge-ic"
                                        (click)="
                                            resetOptionalFilter(
                                                filter,
                                                filter.FILTERTYPE,
                                                i
                                            )
                                        "
                                        >cancel_circle
                                    </mat-icon>
                                    <button
                                        mat-button
                                        [matMenuTriggerFor]="menuCreatedDate_i"
                                        class="buttonwith-text"
                                    >
                                        {{ filter.DESCRIPTION
                                        }}<span
                                            class="filter-applytext display-flex align-center"
                                            >{{
                                                optionalDateFilterPayload[i] &&
                                                optionalDateFilterPayload[i][
                                                    "value"
                                                ] !== null
                                                    ? optionalDateFilterPayload[
                                                          i
                                                      ]["value"].VALUE
                                                    : ""
                                            }}
                                            {{
                                                optionalDateFilterPayload[i] &&
                                                optionalDateFilterPayload[i][
                                                    "value"
                                                ] !== null &&
                                                optionalDateFilterPayload[i][
                                                    "value"
                                                ].VALUE2 !== "" &&
                                                optionalDateFilterPayload[i][
                                                    "value"
                                                ].VALUE2 !== null
                                                    ? " - " +
                                                      optionalDateFilterPayload[
                                                          i
                                                      ]["value"].VALUE2
                                                    : ""
                                            }}<mat-icon class="rightdownarrow">
                                                keyboard_arrow_down</mat-icon
                                            ></span
                                        >
                                    </button>
                                    <mat-menu #menuCreatedDate_i="matMenu">
                                        <h5 class="filter-title">
                                            Filter by {{ filter.DESCRIPTION }}
                                        </h5>
                                        <mat-form-field
                                            appearance="outline"
                                            class="width-full"
                                            (click)="preventDefault($event)"
                                        >
                                            <mat-select
                                                (selectionChange)="
                                                    optionalDateFilter(
                                                        $event,
                                                        filter,
                                                        'active',
                                                        i
                                                    )
                                                "
                                                name="OPERATION"
                                                formControlName="OPERATION"
                                                #Datefilter
                                            >
                                                <mat-option
                                                    *ngFor="
                                                        let d of filter.DATEFILTERLIST
                                                    "
                                                    [value]="d.value"
                                                    >{{ d.description }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.selectedFilter !==
                                                    'is between' &&
                                                (filter.FILTERTYPE ==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE ==
                                                        'CURRENCY')
                                            "
                                        >
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow"
                                                />
                                            </span>
                                            <mat-form-field
                                                class="width-full"
                                                appearance="outline"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'NUMBER'
                                                "
                                                (click)="preventDefault($event)"
                                            >
                                                <input
                                                    matInput
                                                    placeholder=""
                                                    value=""
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    appNumberMask
                                                    required
                                                />
                                            </mat-form-field>

                                            <mat-form-field
                                                class="width-full"
                                                appearance="outline"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'CURRENCY'
                                                "
                                                (click)="preventDefault($event)"
                                            >
                                                <input
                                                    matInput
                                                    placeholder=""
                                                    value=""
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    currencyMask
                                                    required
                                                />
                                            </mat-form-field>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.FILTERTYPE !==
                                                    'NUMBER' &&
                                                filter.FILTERTYPE !==
                                                    'CURRENCY' &&
                                                filter.FILTERTYPE !== 'DATE'
                                            "
                                        >
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow"
                                                />
                                            </span>
                                            <mat-form-field
                                                class="width-full"
                                                appearance="outline"
                                                (click)="preventDefault($event)"
                                            >
                                                <input
                                                    matInput
                                                    placeholder=""
                                                    value=""
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    required
                                                />
                                            </mat-form-field>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.selectedFilter ==
                                                    'is between' &&
                                                (filter.FILTERTYPE ==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE ==
                                                        'CURRENCY')
                                            "
                                        >
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow"
                                                />
                                            </span>
                                            <div
                                                class="display-flex align-center"
                                                (click)="preventDefault($event)"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'NUMBER'
                                                "
                                            >
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline"
                                                >
                                                    <input
                                                        matInput
                                                        placeholder=""
                                                        value=""
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        appNumberMask
                                                        required
                                                    />
                                                </mat-form-field>
                                                <span class="middle-caption"
                                                    >and</span
                                                >
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline"
                                                >
                                                    <input
                                                        matInput
                                                        placeholder=""
                                                        value=""
                                                        name="VALUE2"
                                                        formControlName="VALUE2"
                                                        appNumberMask
                                                        required
                                                    />
                                                </mat-form-field>
                                            </div>

                                            <div
                                                class="display-flex align-center"
                                                (click)="preventDefault($event)"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'CURRENCY'
                                                "
                                            >
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline"
                                                >
                                                    <input
                                                        matInput
                                                        placeholder=""
                                                        value=""
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        currencyMask
                                                        required
                                                    />
                                                </mat-form-field>
                                                <span class="middle-caption"
                                                    >and</span
                                                >
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline"
                                                >
                                                    <input
                                                        matInput
                                                        placeholder=""
                                                        value=""
                                                        name="VALUE2"
                                                        formControlName="VALUE2"
                                                        currencyMask
                                                        required
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.FILTERTYPE == 'DATE' &&
                                                filter.selectedFilter &&
                                                filter.selectedFilter !==
                                                    'is in the last' &&
                                                    filter.selectedFilter !==
                                                        'is between' &&
                                                (filter.FILTERTYPE !==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE !==
                                                        'CURRENCY')
                                            "
                                        >
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow"
                                                />
                                            </span>
                                            <div
                                                class="display-flex align-center"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    class="set-date"
                                                    (click)="
                                                        preventDefault($event)
                                                    "
                                                >
                                                    <input
                                                        matInput
                                                        [matDatepicker]="
                                                            picker6
                                                        "
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        required
                                                    />
                                                    <mat-datepicker-toggle
                                                        matSuffix
                                                        [for]="picker6"
                                                    ></mat-datepicker-toggle>
                                                    <mat-datepicker
                                                        #picker6
                                                    ></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                (filter.FILTERTYPE == 'DATE' &&
                                                    !filter.selectedFilter) ||
                                                (filter.selectedFilter ==
                                                    'is in the last' &&
                                                    (filter.FILTERTYPE !==
                                                        'NUMBER' ||
                                                        filter.FILTERTYPE !==
                                                            'CURRENCY'))
                                            "
                                        >
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow"
                                                />
                                            </span>
                                            <div
                                                class="display-flex align-center field-box"
                                                (click)="preventDefault($event)"
                                            >
                                                <mat-form-field
                                                    class="set-date date-input-field"
                                                    appearance="outline"
                                                >
                                                    <input
                                                        matInput
                                                        placeholder=""
                                                        value=""
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        appNumberMask
                                                        required
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    class="set-date date-dropdown-sel"
                                                >
                                                    <mat-select
                                                        [value]="'days'"
                                                        formControlName="VALUE2"
                                                        required
                                                    >
                                                        <mat-option value="days"
                                                            >Days</mat-option
                                                        >
                                                        <mat-option
                                                            value="month"
                                                            >Month</mat-option
                                                        >
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.FILTERTYPE == 'DATE' &&
                                                filter.selectedFilter ==
                                                    'is between' &&
                                                (filter.FILTERTYPE !==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE !==
                                                        'CURRENCY')
                                            "
                                        >
                                            <mat-form-field
                                                appearance="outline"
                                                class="set-date"
                                                (click)="preventDefault($event)"
                                            >
                                                <input
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    #StartDate
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    required
                                                />
                                                <mat-datepicker-toggle
                                                    matSuffix
                                                    [for]="picker"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker
                                                ></mat-datepicker>
                                            </mat-form-field>
                                            <span class="middle-caption"
                                                >and</span
                                            >
                                            <mat-form-field
                                                appearance="outline"
                                                class="set-date"
                                                (click)="preventDefault($event)"
                                            >
                                                <input
                                                    matInput
                                                    [matDatepicker]="picker2"
                                                    #EndDate
                                                    name="VALUE2"
                                                    formControlName="VALUE2"
                                                    required
                                                />
                                                <mat-datepicker-toggle
                                                    matSuffix
                                                    [for]="picker2"
                                                ></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker2
                                                ></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <button
                                            mat-raised-button
                                            color="primary"
                                            class="width-full accent"
                                            (click)="
                                                applayOptionalfilter(
                                                    optionalFilters[i],
                                                    i,
                                                    Datefilter,
                                                    $event
                                                )
                                            "
                                        >
                                            Apply
                                        </button>
                                    </mat-menu>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <span
                        class="appCues-blankSpace"
                        style="z-index: 9999"
                        id="appCues-time-entry"
                    >
                        <a href="javascript:void(0);">Link</a>
                    </span>
                </form>
                <form
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    name="quickTimeEntriesForm"
                    id="quickTimeEntriesForm"
                    [formGroup]="quickTimeEntriesForm"
                    *ngIf="ActiveTab == 'quick-time-entries'"
                >
                    <div class="one mb-24" fxLayout="column">
                        <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                        >
                            <div
                                class="another-loading"
                                *ngIf="isLoading_another"
                            >
                                <mat-spinner
                                    [style.zoom]="0.5"
                                    *ngIf="isLoading_another"
                                ></mat-spinner>
                            </div>
                            <mat-form-field
                                appearance="outline"
                                fxFlex="20"
                                class="pr-4"
                            >
                                <mat-label>Matters</mat-label>
                                <mat-chip-list
                                    #chipList
                                    aria-label="Matter selection"
                                >
                                    <mat-chip
                                        *ngFor="let matterdata of mattername"
                                        (removed)="remove(matterdata)"
                                        class="mat-chips-selected"
                                    >
                                        {{ matterdata }}
                                        <button
                                            matChipRemove
                                            class="canclebutton"
                                        >
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input
                                        (keyup)="onKeyUp($event)"
                                        (keydown.Tab)="datashow($event)"
                                        formControlName="matterautoVal"
                                        #MatterInput
                                        [matAutocomplete]="autoMatter"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="
                                            separatorKeysCodes
                                        "
                                        (matChipInputTokenEnd)="add($event)"
                                    />
                                </mat-chip-list>
                                <mat-autocomplete
                                    #autoMatter="matAutocomplete"
                                    (optionSelected)="selectedValue($event)"
                                >
                                    <mat-option
                                        class="mat-option_1"
                                        #getValueOfSelectedMatter
                                        *ngFor="let data of matterlist"
                                        (click)="selectedmatterguid(data)"
                                        value="{{ data.MATTERGUID }}"
                                    >
                                        {{ data.SHORTNAME }}--{{ data.MATTER }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon
                                    class="extra-matter_icon"
                                    matSuffix
                                    (click)="selectMatter()"
                                >
                                    <img
                                        src="assets/icons/web_app/icon_matter_d.ico"
                                        matTooltip=""
                                        matTooltipPosition="above"
                                    />
                                </mat-icon>
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                fxFlex="10"
                                class="pr-4"
                                appearance="outline"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.ITEMDATE,
                                    'has-warning':
                                        errorWarningData.Warning?.ITEMDATE
                                }"
                            >
                                <mat-label>Date</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    placeholder="Choose a date"
                                    (dateInput)="QuickDate('input', $event)"
                                    formControlName="ITEMDATE"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                fxFlex="10"
                                class="pr-4"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.ITEMTIME,
                                    'has-warning':
                                        errorWarningData.Warning?.ITEMTIME
                                }"
                            >
                                <mat-label>Time of day</mat-label>
                                <mat-select formControlName="ITEMTIME">
                                    <mat-option
                                        *ngFor="let data of timeStops"
                                        value="{{ data }}"
                                        >{{ data }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field
                                fxFlex="12"
                                class="pr-4"
                                appearance="outline"
                                *ngIf="'' | productType"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.FEEEARNER,
                                    'has-warning':
                                        errorWarningData.Warning?.FEEEARNER
                                }"
                            >
                                <mat-label>Fee Earner</mat-label>
                                <mat-select
                                    formControlName="FEEEARNER"
                                    (selectionChange)="
                                        matterChange('FeeEarner', $event.value);
                                        dlpChange($event.value)
                                    "
                                    [disabled]="
                                        ('Create WIP for Other Fee Earners'
                                            | appPermissions
                                                : 'Create WIP for Other Fee Earners') ==
                                        0
                                            ? true
                                            : false
                                    "
                                >
                                    <mat-option
                                        *ngFor="let user of userList"
                                        value="{{ user.USERID }}"
                                    >
                                        {{ user.FULLNAME }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                fxFlex="12"
                                class="pr-4"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.QUANTITY,
                                    'has-warning':
                                        errorWarningData.Warning?.QUANTITY
                                }"
                            >
                                <mat-label>Duration</mat-label>
                                <!-- <input  matInput formControlName="QUANTITY" (focusout)="matterChange('Quantity',$event.value)"> -->
                                <input
                                    matInput
                                    formControlName="QUANTITY"
                                    (focusout)="
                                        matterChange('Quantity', $event)
                                    "
                                />
                                <mat-icon matSuffix class="secondary-text"
                                    >quantity</mat-icon
                                >
                            </mat-form-field>
                            <mat-form-field
                                fxFlex="8"
                                class="pr-4"
                                appearance="outline"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.QUANTITYTYPE,
                                    'has-warning':
                                        errorWarningData.Warning?.QUANTITYTYPE
                                }"
                            >
                                <mat-label>Type</mat-label>
                                <mat-select
                                    formControlName="QUANTITYTYPE"
                                    (selectionChange)="
                                        quntityTypeChange(
                                            'QuantityType',
                                            $event.value
                                        )
                                    "
                                >
                                    <mat-option
                                        *ngFor="let Activity of ActivityList"
                                        value="{{ Activity.ACTIVITYID }}"
                                        >{{ Activity.DESCRIPTION }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                fxFlex="8"
                                class="pr-4"
                                [ngClass]="{
                                    'has-error': errorWarningData.Error?.RATEID,
                                    'has-warning':
                                        errorWarningData.Warning?.RATEID
                                }"
                            >
                                <mat-label>Rate ID</mat-label>
                                <!-- [disabled] = " _data.edit == 'Add'" -->
                                <mat-select
                                    formControlName="RATEID"
                                    (selectionChange)="reateId($event.value)"
                                >
                                    <mat-option
                                        *ngFor="let rateids of rateid"
                                        [value]="rateids.LOOKUPGUID"
                                    >
                                        {{
                                            rateids.LOOKUPFULLVALUE
                                        }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>

                            <span class="appCues-blankSpace">
                                <a href="javascript:void(0);">Link</a>
                            </span>

                            <!-- quick time entry summary -->
                            <div class="align-center display-flex">
                                <div class="time-entries-button">
                                    <button
                                        class="buttonwith-text dotted-button mb-5"
                                    >
                                        Total Ex GST<span
                                            class="filter-applytext align-center"
                                            >${{
                                                quickTimeEntrySummary.EXGSTTOTAL
                                            }}</span
                                        >
                                    </button>
                                    <button
                                        class="buttonwith-text dotted-button"
                                    >
                                        Units<span
                                            class="filter-applytext align-center"
                                            >{{
                                                quickTimeEntrySummary.UNITSTOTAL
                                            }}</span
                                        >
                                    </button>
                                </div>

                                <div class="time-entries-button">
                                    <button
                                        class="buttonwith-text dotted-button mb-5"
                                    >
                                        Total Inc GST<span
                                            class="filter-applytext align-center"
                                            >${{
                                                quickTimeEntrySummary.INCGSTTOTAL
                                            }}</span
                                        >
                                    </button>
                                    <button
                                        class="buttonwith-text dotted-button"
                                    >
                                        Days<span
                                            class="filter-applytext align-center"
                                            >{{
                                                quickTimeEntrySummary.DAYSTOTAL
                                            }}</span
                                        >
                                    </button>
                                </div>
                            </div>
                            <!-- ends here ~ quick time entry summary -->
                        </div>
                        <div
                            class="secondcls"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                        >
                            <mat-form-field
                                appearance="outline"
                                fxFlex="10"
                                class="pr-4"
                                [ngClass]="{
                                    'has-error': errorWarningData.Error?.PRICE,
                                    'has-warning':
                                        errorWarningData.Warning?.PRICE
                                }"
                                *ngIf="
                                    appPermissions[2]['View Balances'] == '1'
                                "
                            >
                                <mat-label>Price (Ex GST)</mat-label>
                                <!-- currencyMask -->
                                <input
                                    matInput
                                    style="text-align: right;"
                                    [value]="quickTimeEntriesForm.get('PRICE').value | number: '1.2-2'" [allowNegativeNumbers]="true" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." 
                                    [leadZero]="true"
                                    formControlName="PRICE"
                                    (blur)="calcPE()"
                                />
                            </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                fxFlex="10"
                                class="pr-4"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.PRICEINCGST,
                                    'has-warning':
                                        errorWarningData.Warning?.PRICEINCGST
                                }"
                                *ngIf="
                                    appPermissions[2]['View Balances'] == '1'
                                "
                            >
                                <mat-label>Price (Inc GST)</mat-label>
                                <input
                                    matInput
                                    style="text-align: right;"
                                    [value]="quickTimeEntriesForm.get('PRICEINCGST').value | number: '1.2-2'" [allowNegativeNumbers]="true" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." 
                                    [leadZero]="true"
                                    formControlName="PRICEINCGST"
                                    (blur)="calcPI()"
                                />
                            </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                fxFlex="20"
                                class="pr-4"
                                *ngIf="
                                    DerivableDropShow == false &&
                                    currentUser?.PRODUCTTYPE != 'Barrister' &&
                                    (
                                        'Matter Deliverables'
                                        | tierPermission : 'Matter Deliverables'
                                    ).ALLOWED == 1
                                "
                            >
                                <mat-label>Deliverable</mat-label>
                                <mat-select
                                    formControlName="MATTERDELIVERABLEGUID"
                                    (selectionChange)="
                                        radioChange($event.value)
                                    "
                                >
                                    <mat-option
                                        [ngClass]="
                                            data.DELIVERABLEDESCRIPTION
                                                .length >= 50
                                                ? 'my-multi-lines-item my-multi-lines-text'
                                                : ''
                                        "
                                        *ngFor="let data of Deliverable"
                                        [value]="data.MATTERDELIVERABLEGUID"
                                        >{{
                                            data.DELIVERABLEDESCRIPTION
                                        }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field
                                fxFlex="40"
                                class="pr-4"
                                appearance="outline"
                                [ngClass]="{
                                    'has-error':
                                        errorWarningData.Error?.ADDITIONALTEXT,
                                    'has-warning':
                                        errorWarningData.Warning?.ADDITIONALTEXT
                                }"
                            >
                                <mat-label>Invoice Text</mat-label>
                                <textarea
                                    type="text"
                                    placeholder="Invoice Text"
                                    aria-label="Number"
                                    matInput
                                    formControlName="ADDITIONALTEXT"
                                    [matAutocomplete]="auto"
                                ></textarea>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option
                                        *ngFor="
                                            let Lookups of filteredOptions
                                                | async
                                        "
                                        [value]="Lookups.LOOKUPFULLVALUE"
                                        >{{
                                            Lookups.LOOKUPFULLVALUE
                                        }}</mat-option
                                    >
                                </mat-autocomplete>
                            </mat-form-field>

                            <button
                                mat-raised-button
                                fxFlex="20"
                                color="accent"
                                (click)="SaveQuickTimeEntry()"
                                [disabled]="isspiner || isLoading_another"
                                [ngClass]="
                                    appPermissions[2] &&
                                    appPermissions[2]['Create WIP'] == 0
                                        ? 'disabled-click-cursor ribbon-section'
                                        : 'ribbon-section'
                                "
                            >
                                <mat-spinner *ngIf="isspiner"></mat-spinner>Add
                                Time
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </form>
            </div>
            <div class="content fix_div_padding">
                <div
                    class="btn top_right_action_billing_one one dot_controller"
                >
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="contextMenu"
                        aria-label="More"
                    >
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>
                    <mat-menu #contextMenu="matMenu">
                        <ng-template matMenuContent let-item="item">
                            <button mat-menu-item (click)="openDialog()">
                                Field Configuration
                            </button>
                            <!-- <button mat-menu-item (click)="refreshTab(false)">
                                Reload Data
                            </button>
                            <button mat-menu-item (click)="refreshTab(true)">
                                Request All Data
                            </button> -->

                            <!-- <button
                                mat-menu-item
                                (click)="refreshTab(true, true)"
                            >
                                <img
                                    src="assets/icons/web_app/EXP_CSV.ico"
                                    style="
                                        height: 20px !important;
                                        margin-top: 10px;
                                    "
                                />
                                Export to CSV - retrieve all data first
                            </button>
                            <button
                                mat-menu-item
                                (click)="downloadFile(allData)"
                            >
                                <img
                                    src="assets/icons/web_app/EXP_CSV.ico"
                                    style="
                                        height: 20px !important;
                                        margin-top: 10px;
                                    "
                                />
                                Export to CSV - current data
                            </button> -->

                            <button
                                mat-menu-item
                                (click)="getNewExportData()"
                            >
                                <img
                                    src="assets/icons/web_app/EXP_CSV.ico"
                                    style="
                                        height: 20px !important;
                                        margin-top: 10px;
                                    "
                                />
                                Export to CSV
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
                <div class="content">
                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                        <mat-spinner
                            [style.zoom]="0.5"
                            *ngIf="isLoadingResults"
                        ></mat-spinner>
                    </div>
                    <div
                        class="resizable-table timeentry-wrap timeentry-block"
                        [ngClass]="
                            class == true ? 'Table-height' : 'Table-height-2'
                        "
                    >
                        <div class="grid-overlay" *ngIf="isDisplay">
                            <div class="grid-overlay-msg">
                                There is no data to display.
                            </div>
                        </div>
                        <mat-table
                            class="modern"
                            #table
                            (matSortChange)="
                                sortData($event); sortingCLM($event)
                            "
                            [matSortActive]="sortactive"
                            [matSortDirection]="sortDirection"
                            matSortDisableClear
                            [dataSource]="TimerData"
                            matSort
                            [@animateStagger]="{ value: '50' }"
                        >
                            <ng-container
                                *ngFor="
                                    let item of displayedColumns;
                                    let i = index
                                "
                                matColumnDef="{{ item }}"
                            >
                                <!-- Multiple Select -->
                                <span *ngIf="item == 'select'">
                                    <mat-header-cell
                                        class="checkbox_width"
                                        *matHeaderCellDef="let i = index"
                                    >
                                        <mat-checkbox
                                            (change)="
                                                $event ? masterToggle() : null
                                            "
                                            [checked]="
                                                selection.hasValue() &&
                                                isAllSelected()
                                            "
                                            [indeterminate]="
                                                selection.hasValue() &&
                                                !isAllSelected()
                                            "
                                            [aria-label]="checkboxLabel()"
                                        >
                                        </mat-checkbox>
                                    </mat-header-cell>
                                    <mat-cell
                                        class="checkbox_width"
                                        *matCellDef="let row"
                                    >
                                        <mat-checkbox
                                            (change)="
                                                $event
                                                    ? selection.toggle(row)
                                                    : null;
                                                collectSelectedVal()
                                            "
                                            [(ngModel)]="row.TAGGED"
                                            [checked]="
                                                selection.isSelected(row)
                                            "
                                            [aria-label]="checkboxLabel(row)"
                                        >
                                        </mat-checkbox>
                                    </mat-cell>
                                </span>

                                <span *ngIf="item != 'select'">
                                    <mat-header-cell
                                        *matHeaderCellDef="let i = index"
                                        mat-sort-header
                                        class="header_title"
                                        mwlResizable
                                        enableGhostResize="true"
                                        (resizing)="onResizing($event, item)"
                                        (resizeEnd)="onResizeEnd($event, item)"
                                        [resizeEdges]="{
                                            bottom: false,
                                            right: true,
                                            top: false,
                                            left: true
                                        }"
                                    >
                                        <div
                                            class="desc_cell resize-handle-right"
                                            mwlResizeHandle
                                            [resizeEdges]="{ right: true }"
                                        ></div>
                                        {{ tempColobj[item].DESCRIPTION }}
                                    </mat-header-cell>
                                </span>

                                <mat-cell
                                    *matCellDef="let row"
                                    class="text-{{
                                        tempColobj[item].JUSTIFICATION
                                    }}-align mat-column-{{ item }}"
                                    [ngClass]="{
                                        'word-wrap-class':
                                            tempColobj[item].WIDEDATA
                                    }"
                                    [ngStyle]="{
                                        color: '#' + row['ROWCOLOUR']
                                    }"
                                    [matTooltip]="getToolTipData(row)"
                                    matTooltipPosition="below"
                                    matTooltipClass="example-tooltip-font"
                                >
                                    <span
                                        *ngIf="
                                            row[item] !== undefined &&
                                            (Object.keys(hyperLinkInfo).indexOf(
                                                item
                                            ) == -1 ||
                                                hyperLinkInfo[item].LINKFIELD ==
                                                    '' ||
                                                row[
                                                    hyperLinkInfo[item]
                                                        .LINKFIELD
                                                ] == undefined ||
                                                row[
                                                    hyperLinkInfo[item]
                                                        .LINKFIELD
                                                ] == '')
                                        "
                                        >{{
                                            row[item]
                                                | toFixedAmount
                                                    : tempColobj[item].CURRENCY
                                                | wordwrap
                                                    : tempColobj[item].WIDEDATA
                                        }}</span
                                    >
                                    <span
                                        *ngIf="
                                            row[item] &&
                                            Object.keys(hyperLinkInfo).indexOf(
                                                item
                                            ) >= 0 &&
                                                hyperLinkInfo[item].LINKFIELD !=
                                                    '' &&
                                                row[
                                                    hyperLinkInfo[item]
                                                        .LINKFIELD
                                                ] != undefined &&
                                                row[
                                                    hyperLinkInfo[item]
                                                        .LINKFIELD
                                                ] != ''
                                        "
                                        (click)="
                                            selectHyperLink(
                                                row,
                                                item,
                                                hyperLinkInfo[item]
                                            )
                                        "
                                        [ngClass]="HyperlinkColor"
                                        [style]="
                                            highlightedRows ==
                                                row.WORKITEMGUID &&
                                            currentUser.PRODUCTTYPE ==
                                                'Solicitor'
                                                ? 'color: #43a047 !important'
                                                : highlightedRows ==
                                                      row.WORKITEMGUID &&
                                                  currentUser.PRODUCTTYPE ==
                                                      'Barrister'
                                                ? 'color: #4E2083 !important'
                                                : ''
                                        "
                                    >
                                        {{ row[item] }}
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row
                                *matHeaderRowDef="
                                    displayedColumns;
                                    sticky: true
                                "
                            ></mat-header-row>
                            <mat-row
                                *matRowDef="let row; columns: displayedColumns"
                                (click)="
                                    editTimeEntry(row);
                                    highlightedRows = row.WORKITEMGUID
                                "
                                [style.background]="
                                    highlightedRows == row.WORKITEMGUID
                                        ? selectedColore
                                        : ''
                                "
                                [ngClass]="{
                                    'row-text-colore':
                                        highlightedRows == row.WORKITEMGUID &&
                                        !row['ROWCOLOUR']
                                }"
                                (dblclick)="doubleClick()"
                                matRipple
                                [@animate]="{
                                    value: '*',
                                    params: { y: '100%' }
                                }"
                            >
                            </mat-row>
                            <!-- 'has-orange-colore':row.INVOICEGUID == -1 ,'has-light-blue-colore':(row.INVOICEGUID && row.INVOICEGUID != -1) -->
                            <ng-container matColumnDef="groupHeader">
                                <th
                                    class="special-group-header"
                                    colspan="999"
                                    *matCellDef="let group"
                                    style="
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                    "
                                >
                                    <!-- <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                                    <mat-icon *ngIf="!group.expanded">expand_more</mat-icon> -->
                                    <strong style="margin-left: 25px"
                                        >Item Type:
                                        {{
                                            group?.ITEMTYPEDESC
                                        }}
                                        &nbsp;&nbsp;&nbsp;&nbsp; Price(Ex):
                                        {{
                                            tempTotalData[group.ITEMTYPEDESC]
                                                ?.PRICE | currency
                                        }}
                                        &nbsp;&nbsp;&nbsp;&nbsp; Price:
                                        {{
                                            tempTotalData[group.ITEMTYPEDESC]
                                                ?.PRICEINCGST | currency
                                        }}</strong
                                    >
                                </th>
                            </ng-container>
                            <mat-row
                                *matRowDef="
                                    let row;
                                    columns: ['groupHeader'];
                                    when: isGroup
                                "
                            >
                            </mat-row>
                        </mat-table>
                    </div>
                    <mat-paginator
                        [pageSize]="pageSize?.time_entries_page"
                        (page)="onPaginateChange($event)"
                        [pageSizeOptions]="[10, 20, 50, 100]"
                        showFirstLastButtons
                    ></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
