import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { environment } from "src/environments/environment";
// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
// import 'rxjs/add/operator/filter';
import { DropDownTreeComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { TimeEntryDialogComponent } from "../../time-entries/time-entry-dialog/time-entry-dialog.component";
import * as $ from 'jquery';
import { ConfirmPopupComponent } from "src/app/main/confirm-popup/confirm-popup.component";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap, takeUntil } from "rxjs";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { CurrentUser } from "src/app/_models/CurrentUser";
@Component({
    selector: 'app-rename-email',
    templateUrl: './rename-email.component.html',
    styleUrls: ['./rename-email.component.scss']
})
export class RenameEmailComponent implements OnInit, OnDestroy {
    @ViewChild("sample", { static: false }) dropdownTree: DropDownTreeComponent;
    @ViewChild('folderlistfilename', { static: false }) public ddlelem: DropDownTreeComponent;
    id = "";
    attechmentkey = "";
    isspiner: boolean = false;
    errorWarningData: any = {};
    confirmDialogRef: any;
    MatterContactData: any = [];
    currentFolder: any = { 'id': '' };
    Attachments: any = [];
    matterData: any = {};
    mail: any;
    isLoadb1text: any = "Save to Register..";
    isLoadb2text: any = "Save to Register.";
    SaveEmailRegister: any = true;
    isLoadF: any;
    refreshbutton: boolean = false;
    isFILENAME: any;
    isLoadingResults: boolean = false;
    isConfirmBoxTitle: string = "Save Email and Attachments";
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    FolderData: any = [];
    fields: any = {};
    documentExt: any = ['docx', 'doc', 'dotx', 'dot', 'docm', 'dotm', 'pdf', 'csv', 'xslx', 'GIF', 'gif', 'PNG', 'png', 'jpeg', 'JPEG', 'JPG', 'jpg'];
    lastSubFolder: any;
    reuestData2: any = {};
    OverwritingData: any = [];
    FolderListArray: any = [];
    expandexNodelist: any = [];
    public treeSettings: any = { loadOnDemand: true };
    attachdata: any;
    EmailId: any;
    Filename: any;
    mattername: any = [];
    searchMatterValue: any = '';
    selectedData: any = [];
    private searchTerms = new Subject<string>();
    exportdatavalue: any;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    matterlist: Observable<string[]>;
    searchMatter: Subscription;
    searchData: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;
    sub4: Subscription;
    subscription: Subscription;
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    Apicount = 0
    countdata = 0;
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public confirmDialogRef1: MatDialogRef<RenameEmailComponent>
    ) {
        if (_data && _data != "") {
            this.Attachments = _data.attachments;


            this.Attachments.forEach((element, index) => {
                if (element.NAME) {
                    element.ejsId = 'ejsId' + index;
                }
            });
            this.mail = _data.mail;


            if (_data.isMultiEmails) {
                this.mail.forEach((element, index) => {
                    if (element.FILENAME) {
                        element.ejsId = 'ejsIdEM' + index;
                    }
                });
            }
        }
        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.matterData = result;
                this.mattername = [this.matterData.SELECTEDDESCRIPTION]
            }
        });
        this.behaviorService.EjsDropDownTreeData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.mail.forEach((element, i) => {
                    if (element.ID == result.Email.ID) {
                        element['foldeId'] = result.event.itemData
                    } else {
                        element.attachments.forEach(value => {
                            if (value.ID == result.Email.ID) {
                                value['foldeId'] = result.event.itemData
                            }
                        });
                    }
                });


                // if (_data.isMultiEmails) {
                //   this.mail[result.key].foldeId = result.event.itemData;
                // } else {
                //   this.Attachments[result.key].foldeId = result.event.itemData;
                // }
            }
        });
        this.getAttchmentData();
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
        if (this._data.isGetAttechment) {
            this.mail.FILENAME = (this.mail.FILENAME).replace(/(.*)\.(.*?)$/, "$1");
            this.getAttchmentData();
        }

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
                this.searchMatterValue = ''
            }
            // this.searchResult = response.RESPONSE.results;
          });

    }

    /**
     * This function is used to destroy the component sessition
     */
    ngOnDestroy(): void {
        this.searchMatter?.unsubscribe();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
        this.sub4?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    /**
     * This function is used to add the new time entry data value
     */
    newTimeEntry() {
        const dialogRef4 = this.MatDialog.open(TimeEntryDialogComponent, { width: '100%', disableClose: true, data: { 'edit': 'Add', 'matterData': '', isReadOnly: '', TABLEGUID: "" } });
        dialogRef4.afterClosed().subscribe(result => {
            if (result) {
                $('#refreshTimeEntryTab').click();
                $('#refreshWorkInprogress').click();
            }
        });
    }

    /**
     * This function is used to getAttchmentData value
     */
    async getAttchmentData() {
        if (this.mail.length == 1) {
            this.Filename = this.mail[0].FILENAME
        }
        this.Attachments = []
        for (let i = 0; i < this.mail.length; i++) {
            this.isLoadingResults = true;
            await new Promise<void>(async (next, reject) => {
               this.sub1 = this._mainAPiServiceService.getSetData({ "Action": "NetEmailGetEmailAttachments", "Data": { EMAILID: this.mail[i].ID } }, 'cloud-email').subscribe(response => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.Attachments = []
                        let tempAttechemnt: any = response.DATA.ATTACHMENTS;
                        this.mail[i]["attachments"] = this.Attachments;
                        this.mail[i]["attachments"]['SENTDATETIME'] = this.mail[i].SENTDATETIME
                        tempAttechemnt.forEach((element, index) => {
                            if (element.NAME) {
                                let re: any = /(?:\.([^.]+))?$/;
                                element.type = re.exec(element.NAME)[1];
                                this.Attachments.push(element);
                                element.ejsId = 'ejsId' + index;
                            }
                        });
                        this.refreshbutton = false;
                        this.isLoadingResults = false;
                        next();
                    } else if (response.CODE == "428" && response.STATUS == "error") {
                        this.refreshbutton = true;
                        this.isLoadingResults = false;
                        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
                        window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, 'targetWindow',
                            `toolbar=no,
             location=no,
             status=no,
             menubar=no,
             scrollbars=yes,
             resizable=yes,
             width=800,
             height=600`);
                        next();
                    }
                }, error => {
                    this.toastr.error(error);
                });
            });
        }


    }

    /**
     * This function is used to set the folder data value 
     */
    getFolder(postData, level):void {
        this.isLoadingResults = true;
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        postData.MATTERGUID = this.matterData.MATTERGUID;
        let Data=postData;
        this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isLoadingResults = false;
                this.FolderData = res.DATA.FOLDERS;
                if (level == 'rootLevel') {
                    this.FolderData.forEach(e => {
                        e.hasChild = true;
                        e.expanded = false;
                        // let tempData = e;
                        this.FolderListArray.push(e);
                        // tempData.FOLDERS.forEach(element => {
                        //   element.hasChild = true;
                        //   element.expanded = false;
                        //   element.pid = tempData.FULLPATH;
                        //   this.FolderListArray.push(element);
                        // });
                    });

                    this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: 'pid', hasChildren: 'hasChild' };
                } else {
                    this.FolderData.forEach(element => {
                        element.hasChild = true;
                        element.expanded = false;
                        element.pid = postData.FOLDERNAME;
                        this.FolderListArray.push(element);
                    });

                    this.expandexNodelist = this.expandexNodelist.filter((element, i) => i === this.expandexNodelist.indexOf(element));
                    this.FolderListArray.forEach(item => {
                        if (this.expandexNodelist.includes(item.FULLPATH) || item.FULLPATH == "<MatterFolder>") {
                            item.expanded = true;
                            item.hasChild = true;
                        } else {
                            item.hasChild = true;
                            item.expanded = false;
                        }
                    });
                    this.ddlelem.refresh();
                    (this.ddlelem as any).treeObj.nodeExpanding = this.triggerGetFolder.bind(this);

                    this.ddlelem.showPopup();
                    this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: 'pid', hasChildren: 'hasChild' };
                }
                this.isLoadingResults = false;
                // this.fields = { dataSource: this.FolderData, value: 'FULLPATH', text: 'FOLDERNAME', child: 'FOLDERS' };
            } else if (res.CODE == "432") {
                this.isLoadingResults = false;
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else {
                this.isLoadingResults = false;
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to create the folder data value.
     */
    created(event) {
        if (this.ddlelem && (this.ddlelem as any).treeObj) {
            (this.ddlelem as any).treeObj.nodeExpanding = this.triggerGetFolder.bind(this);
        }
    }

    /**
     * This function is used to get the Folder data value
     */
    triggerGetFolder(arges) {
        if (!this.expandexNodelist.includes(arges.nodeData.id) && arges.nodeData.id != "<MatterFolder>") {
            this.expandexNodelist.push(arges.nodeData.id);
            this.getFolder({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: arges.nodeData.id }, 'subFolderLevel');
        }
    }

    /**
     * This function is used to changes the folder data value
     */
    FloderChnage($event: SelectEventArgs, key: any = '', emailData: any) {
        this.mail.forEach((element, i) => {
            if (element.ID == emailData.ID) {
                element['foldeId'] = $event.itemData
            } else {
                element.attachments.forEach(value => {
                    if (value.ID == emailData.ID) {
                        value['foldeId'] = $event.itemData
                    }
                });
            }
        });
        // oldest
        // if (this.Attachments[key]) {
        //   this.Attachments[key].foldeId = $event.itemData;
        // } else {
        //   this.currentFolder = $event.itemData;
        // }
    }

/**
 * This function is used to view the Document.
 */
    viewDocument(row: any, rowEmail?) {
        if (rowEmail) {
            this.EmailId = rowEmail.value.ID
        } else {
            this.EmailId = this.mail[0].ID
        }

        this.isLoadF = row;
        this._mainAPiServiceService.getSetData({ "Action": "NetEmailGetEmailAttachments", "Data": { EMAILID: this.EmailId, ATTACHMENTID: row.ID } }, 'cloud-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.isLoadF = "";
                if (response.DATA.ATTACHMENTS && response.DATA.ATTACHMENTS[0]) {
                    let base_url = environment.ReportUrl;
                    window.open(base_url + response.DATA.ATTACHMENTS[0]['FILENAME'], '_blank');
                }
            } else if (response.CODE == "428" && response.STATUS == "error") {
                this.isLoadF = "";
                let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
                window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`);
            }
        }, error => {
            this.isLoadF = "";
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to select the matter for Email data value
     */
    selectMatterForEmail():void {
        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(async result => {

            if (result) {
                this.mattername = [result.SELECTEDDESCRIPTION];
                this.searchMatterValue = result.MATTERGUID;
                this.matterData = result;

                await this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                this.expandexNodelist = [];
                this.FolderListArray = [];
                this.ddlelem.refresh();

                (this.ddlelem as any).treeObj.nodeExpanding = this.triggerGetFolder.bind(this);
            } else {
                this.isspiner = false;
            }
        });
    }

    /**
     * This function is used to Save Email to register
     */
    async SaveEmailtoRegister(isoverRite: any = false) {
        this.OverwritingData = [];
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        this.mail.forEach(async (value, i) => {
            this.OverwritingData = [];
            // if imail is unchecked but its email under document checked.
            if (value.checked) {
                this.reuestData2 = { OVERWRITE: isoverRite, MATTERGUID: this.matterData.MATTERGUID, EMAILID: value.ID, FILENAME: value.FILENAME + '.eml', FOLDERNAME: (value.foldeId) ? value.foldeId.id : '' };
                this.OverwritingData.push(this.reuestData2);
                await this.FinalsaveAttachmentsRegister(this.OverwritingData);
                this.countdata = this.countdata + 1
                if (value.attachments.length != 0) {
                    await value.attachments.forEach(async (element) => {
                        this.OverwritingData = [];
                        await new Promise<void>(async (next, reject) => {
                            if (element.checked) {
                                let re: any = /(?:\.([^.]+))?$/;
                                let demotype = re.exec(element.NAME)[1];
                                let fileNane: any = "";
                                if (this.documentExt.includes(demotype)) {
                                    fileNane = element.NAME;
                                } else {
                                    fileNane = element.NAME + '.' + element.type;
                                }
                                if (element.foldeId == undefined) {
                                    element.foldeId = { id: '' };
                                }
                                this.reuestData2 = { OVERWRITE: isoverRite, MATTERGUID: this.matterData.MATTERGUID, ATTACHMENTID: element.ID, EMAILID: value.ID, FILENAME: fileNane, FOLDERNAME: (element.foldeId) ? element.foldeId.id : '' };
                                this.OverwritingData.push(this.reuestData2);
                                this.countdata = this.countdata + 1
                            }
                            await this.FinalsaveAttachmentsRegister(this.OverwritingData);
                            next()
                        });
                    })
                }
            } else {
                if (value.attachments.length != 0) {
                    await value.attachments.forEach(async (element) => {
                        this.OverwritingData = [];
                        if (element.checked) {
                            let re: any = /(?:\.([^.]+))?$/;
                            let demotype = re.exec(element.NAME)[1];
                            let fileNane: any = "";
                            if (this.documentExt.includes(demotype)) {
                                fileNane = element.NAME;
                            } else {
                                fileNane = element.NAME + '.' + element.type;
                            }
                            if (element.foldeId == undefined) {
                                element.foldeId = { id: '' };
                            }
                            this.reuestData2 = { OVERWRITE: isoverRite, MATTERGUID: this.matterData.MATTERGUID, ATTACHMENTID: element.ID, EMAILID: value.ID, FILENAME: fileNane, FOLDERNAME: (element.foldeId) ? element.foldeId.id : '' };
                            this.OverwritingData.push(this.reuestData2);
                            this.countdata = this.countdata + 1
                        }
                        await this.FinalsaveAttachmentsRegister(this.OverwritingData);
                    });
                } else {
                }
            }
        });
    }

    /**
     * This function is used to save Attachment Register data value.
     */
    saveAttachmentsRegister(isoverRite: any = false) {
        this.OverwritingData = [];
        let count: number = 0;
        this.Attachments.forEach(value => {
            if (value.checked) {
                count = count + 1;
            }
        });
        if (count == 0 && !this.SaveEmailRegister) {
            this.toastr.error("Please select at least one document");
            this.isspiner = false;
            return false;
        }
        this.Attachments.forEach(value => {
            if (value.checked) {
                // this.isspiner = true;
                let re: any = /(?:\.([^.]+))?$/;
                let demotype = re.exec(value.NAME)[1];
                let fileNane: any = "";
                if (this.documentExt.includes(demotype)) {
                    fileNane = value.NAME;
                } else {
                    fileNane = value.NAME + '.' + value.type;
                }
                if (value.foldeId == undefined) {
                    value.foldeId = { id: '' };
                }
                let reuestData = { OVERWRITE: isoverRite, MATTERGUID: this.matterData.MATTERGUID, EMAILID: this.mail.ID, ATTACHMENTID: value.ID, FOLDERNAME: value.foldeId.id, FILENAME: fileNane };

                this.OverwritingData.push(reuestData);
                // this._mainAPiServiceService.getSetData(reuestData, 'NetEmailSaveEmailAttachment').subscribe(response => {
                //   if (response.CODE == 200 && response.STATUS == "success") {
                //     this.isspiner = false;
                //     this.isLoadb1text = "Save to Register";
                //     this.toastr.success("Attachments Register Saved Successfully");
                //     this.confirmDialogRef1.close(false);
                //   } else if (response.CODE == "428" && response.STATUS == "error") {
                //     this.isLoadb1text = "Click Here to Login"
                //     this.isspiner = false;
                //     window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, '_blank');
                //   } else if (response.CODE == "432" && response.STATUS == "error") {
                //     this.isLoadb1text = "Click Here to Login"
                //     this.isspiner = false;
                //     window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, '_blank');
                //   }
                // }, error => {
                //   this.isspiner = false;
                //   this.toastr.error(error);
                // });
            }
        });
        if (this.SaveEmailRegister) {
            this.reuestData2 = { OVERWRITE: isoverRite, MATTERGUID: this.matterData.MATTERGUID, EMAILID: this.mail.ID, FILENAME: this.mail.FILENAME + '.eml', FOLDERNAME: this.currentFolder.id };
            this.OverwritingData.push(this.reuestData2);
            // this._mainAPiServiceService.getSetData(this.reuestData2, 'NetEmailSaveEmailAttachment').subscribe(response => {
            //   if (response.CODE == 200 && response.STATUS == "success") {
            //     this.isspiner = false;
            //     this.toastr.success("Email Register  Saved Successfully");
            //     this.confirmDialogRef1.close(false);
            //   } else if (response.CODE == "428" && response.STATUS == "error") {
            //     this.isspiner = false;
            //     window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, '_blank');
            //   } else if (response.CODE == "432" && response.STATUS == "error") {
            //     this.isspiner = false;
            //     window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, '_blank');
            //   } else {
            //     this.isspiner = false;
            //   }
            // }, error => {
            //   this.isspiner = false;
            //   this.toastr.error(error);
            // });s
        }
        this.FinalsaveAttachmentsRegister(this.OverwritingData);
    }

/**
 * This function is used to Final Attachment save data value.
 */
    async FinalsaveAttachmentsRegister(data) {
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        for (var i = 0; i < data.length; i++) {
            this.isspiner = true;
            await new Promise<void>(async (next, reject) => {
               this.sub4 = this._mainAPiServiceService.getSetData(data[i], 'NetEmailSaveEmailAttachment').subscribe(response => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.Apicount = this.Apicount + 1
                        this.isspiner = false;
                        if (data[i].ATTACHMENTID) {
                            this.toastr.success("Attachment File Register Saved Successfully");
                        } else {
                            this.toastr.success("Email Register Saved Successfully");
                        }
                        next();
                    } else if (response.CODE == "428" && response.STATUS == "error") {
                        this.Apicount = this.Apicount + 1
                        this.isspiner = false;
                        window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, 'targetWindow',
                            `toolbar=no,
             location=no,
             status=no,
             menubar=no,
             scrollbars=yes,
             resizable=yes,
             width=800,
             height=600`);
                        next()
                    } else if (response.CODE == "432" && response.STATUS == "error") {
                        this.Apicount = this.Apicount + 1
                        this.isspiner = false;
                        window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                            `toolbar=no,
             location=no,
             status=no,
             menubar=no,
             scrollbars=yes,
             resizable=yes,
             width=800,
             height=600`);
                        next()
                    } else if (response.CODE == "435" && response.STATUS == "error") {
                        this.Apicount = this.Apicount + 1
                        this.confirmDialogRef = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: "100%", data: { is_fileoverwrite: true } });
                        this.confirmDialogRef.componentInstance.confirmMessage = data[i].FILENAME + " already exists. you need to change the name of the file or overwrite it.";
                        this.confirmDialogRef.componentInstance.confirmTitle = 'Overwrite';
                        this.confirmDialogRef.componentInstance.confirm1 = 'Overwrite';
                        this.confirmDialogRef.componentInstance.confirm2 = 'Rename';
                        this.confirmDialogRef.componentInstance.confirm3 = "Close";
                        this.confirmDialogRef.afterClosed().subscribe((result) => {
                            if (result == 'overwrite') {
                                data[i].OVERWRITE = true;
                                this.FinalsaveAttachmentsRegister(data);
                                this.isspiner = true;
                            } else if (result == 'rename') {
                                // next();
                                this.isspiner = false;
                            } else {
                                next();
                            }

                            this.confirmDialogRef = null;
                        });
                    } else {
                        this.Apicount = this.Apicount + 1
                        this.isspiner = false;
                        next()
                    }
                }, error => {
                    this.Apicount = this.Apicount + 1
                    this.isspiner = false;
                    this.toastr.error(error);
                });
            });
        }
        // this.isspiner = true;

        // if(this.countdata==this.Apicount){
        //   setTimeout(() => {
        //     this.isspiner = false;
        //   this.confirmDialogRef1.close(false);
        //   }, 2000);

        // }



        // this.confirmDialogRef1.close(false);
    }

    /**
     * This function is used to show the data value
     */
    datashow(event):void {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.matterData = data
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.searchMatterValue = data.MATTERGUID
                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }


/**
 * This function is used to remove the selected matter data.
 */
    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
        this.searchMatterValue = '';
    };

    /**
     * rm the data value
     */
    rm(sortname):void {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    /**
     * This function is used to Add the Matter data value. 
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
    };

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

/**
 * This function is used to select the matter data value 
 */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            "Action": "GetData",
            "Filters": {
                FastSearch: 'True', SearchString: event
            }
        };
        return this._mainAPiServiceService.getSetData(searchobj, 'matter')
    }

    /**
     * This function is used to select the value
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {


        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
        }
    }

    /**
     * This function is used to Open the MAtter
     */
    openMatterSetTimer():void {
        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: {} });
        dialogRef.afterClosed().subscribe(result => {
            this.mattername = [result.SELECTEDDESCRIPTION];
            this.searchMatterValue = result.MATTERGUID;
            // if (result) {
            //     localStorage.setItem('set_active_matters', JSON.stringify(result));
            //     this.TimersServiceI.addTimeEnrtS('');
            // }
            // this.searchData = [];
            // this.matterlist = this.searchData;
        });
    }

    /**
     * This function is used to select the Matter Guid.
     */
    selectedmatterguid(data: any):void {
        this.matterData = data
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }

    }


}
