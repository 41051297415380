<div
    id="account"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
    [ngClass]="COLOR">
    <div class="content" fxLayoutAlign="center">
        <div
            class="center p-24 more_tbl_clmn list_tbl_main"
            fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto">
                <!-- <form [formGroup]="MainSafeCustody">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field fxFlex="20" class="example" appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select (selectionChange)="selectStatus($event.value)" formControlName="STATUS">
                                <mat-option value="All">All</mat-option>
                                <mat-option value="Review Soon ">Review Soon </mat-option>
                                <mat-option value="Checked In ">Checked In </mat-option>
                                <mat-option value="Checked In/Returned">Checked In/Returned</mat-option>
                                <mat-option value="Borrowed">Borrowed</mat-option>
                                <mat-option value="Returned">Returned</mat-option>
                                <mat-option value="Checked Out ">Checked Out </mat-option>
                                <mat-option value="Not Checked Out">Not Checked Out</mat-option>
                                <mat-option value="Review Soon ">Review Soon </mat-option>
                                <mat-option value="Archived">Archived</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox class="check_top" (change)="MatterChecxed()" formControlName="MATTERCHECK">All
                            Matters </mat-checkbox>
                        <mat-form-field class="example align_center" fxFlex="20" appearance="outline">
                            <mat-label>Matters</mat-label>
                            <mat-chip-list #chipList aria-label="Matter selection">
                                <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)"
                                    class="mat-chips-selected">
                                    {{matterdata}}
                                    <button matChipRemove class="canclebutton">
                                        <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input matInput (keydown.Tab)="datashow($event)" formControlName="MATTER" #MatterInput1
                                    [matAutocomplete]="autoMatter" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)"
                                    (keyup)='selectMatterFormSearch(MatterInput1?.value)'
                                    [ngClass]="mattername.length > 0?'hideInput':'showInput'" [disabled]="hide">
                            </mat-chip-list>
                            <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)">
                                <mat-option class="mat-option_1" *ngFor="let data of matterlist"
                                    (click)="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                    {{data.SHORTNAME}}--{{data.MATTER}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon class="" matSuffix (click)='SelectMatter()'>
                                <img src="assets/icons/web_app/icon_matter_d.ico">
                            </mat-icon>
                        </mat-form-field>

                        <mat-checkbox class="check_top" (change)="ContactChecxed()" formControlName="CLIENTCHECK">All
                            Contacts </mat-checkbox>
                        <mat-form-field class="example align_center" fxFlex="20" appearance="outline">
                            <mat-label>Client</mat-label>
                            <input readonly matInput formControlName="CLIENT">
                            <mat-icon class="set_icon {{ImgDisAb}}" matSuffix (click)='SelectContact()'>
                                <img src="assets/icons/web_app/icon_contact_d.ico">
                            </mat-icon>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" appearance="outline" class="example">
                            <mat-label>Filter</mat-label>
                            <input matInput formControlName="SEARCH" (keydown)="onSearch($event)">
                            <mat-icon matSuffix (click)="onSearch('Enter')" *ngIf="isSearchStringFilter">search
                            </mat-icon>
                            <mat-icon matSuffix class="icon-color-red" (click)="onSearch('RemoveSearchString')"
                                *ngIf="!isSearchStringFilter">
                                highlight_off
                            </mat-icon>
                        </mat-form-field>
                        <span class="appCues-blankSpace">
                            <a href="javascript:void(0);">Link</a>
                        </span>
                    </div>
                </form> -->

                <form
                    [formGroup]="MainSafeCustody"
                    class="align-center display-inline filter-menuwrap flex-column flex-wrap"
                    id="filter-form-menu-wrap">
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="1 0 auto"
                        class="align-center display-inlineflex flex-wrap filter-menublock">
                        <div class="add-filterwrap">
                            <!-- button -->
                            <button
                                mat-button
                                [matMenuTriggerFor]="dummyButton"
                                [disabled]="true"
                                style="display: none">
                                Filters
                            </button>
                            <mat-menu #dummyButton="matMenu"> </mat-menu>
                            <!-- ends here ~ button -->

                            <button
                                mat-button
                                [matMenuTriggerFor]="menuFilter"
                                class="filter-button"
                                (click)="
                                    backToFilter('optional-Discount');
                                    showFiltersList = true
                                ">
                                <img
                                    [src]="
                                        currentUserData?.PRODUCTTYPE ==
                                        'Solicitor'
                                            ? 'assets/icons/filter-icon.png'
                                            : 'assets/icons/filter-icon-barrister.png'
                                    "
                                    alt="Filter"
                                    class="filter-icon" />
                                <span> Filters</span>
                            </button>
                            <mat-icon
                                class="icon-btn close-btn"
                                (click)="clearAllFilter()"
                                *ngIf="optionalFilters?.length > 0">close</mat-icon>
                            <mat-icon
                                class="icon-btn"
                                (click)="refreshmainsafecusday()">refresh</mat-icon>
                            <mat-menu #menuFilter="matMenu">
                                <div class="filterscroll">
                                    <ng-container
                                        *ngFor="
                                            let optionalFilterData of optionalFilterList
                                        ">
                                        <div
                                            *ngIf="
                                                optionalFilterData.FILTERTYPE !=
                                                'HIDDEN'
                                            ">
                                            <button
                                                class="addbutton"
                                                [ngClass]="COLOR"
                                                *ngIf="
                                                    !optionalFilterData.FILTERAPPLY &&
                                                    showFiltersList
                                                "
                                                (click)="
                                                    preventDefault($event);
                                                    activeCurrentFilter(
                                                        'optional-Discount',
                                                        optionalFilterData
                                                    );
                                                    addNewFilter(
                                                        activeOptionalFilter
                                                    );
                                                    showFiltersList = false
                                                ">
                                                <mat-icon>add_circle</mat-icon>
                                                {{
                                                optionalFilterData.DESCRIPTION
                                                }}
                                            </button>
                                            <div
                                                class="menu-submenu"
                                                id="optional-Discount">
                                                <h5
                                                    class="filter-title"
                                                    *ngIf="activeOptionalFilter">
                                                    <mat-icon
                                                        class="back-icon"
                                                        (click)="
                                                            backToFilter(
                                                                'optional-Discount'
                                                            );
                                                            preventDefault(
                                                                $event
                                                            );
                                                            showFiltersList = true
                                                        ">
                                                        arrow_back_ios
                                                    </mat-icon>Filter by
                                                    {{
                                                    activeOptionalFilter.DESCRIPTION
                                                    }}
                                                </h5>

                                                <div
                                                    *ngIf="activeOptionalFilter">
                                                    <!-- Filter List Need to Apply -->
                                                    <ng-container
                                                        formArrayName="FILTERSLIST">
                                                        <div
                                                            *ngFor="
                                                                let filter of _filterList;
                                                                let j = index
                                                            "
                                                            class="position-relative">
                                                            <ng-container
                                                                [formGroupName]="
                                                                    j
                                                                ">
                                                                <!-- <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5> -->
                                                                <mat-form-field
                                                                    appearance="outline"
                                                                    class="width-full"
                                                                    (click)="
                                                                        preventDefault(
                                                                            $event
                                                                        )
                                                                    ">
                                                                    <mat-select
                                                                        (selectionChange)="
                                                                            optionalDateFilter(
                                                                                $event,
                                                                                filter,
                                                                                'default',
                                                                                -1
                                                                            )
                                                                        "
                                                                        name="OPERATION"
                                                                        [(ngModel)]="
                                                                            addFilter.OPERATION
                                                                        "
                                                                        formControlName="OPERATION"
                                                                        #operationFilter>
                                                                        <mat-option
                                                                            *ngFor="
                                                                                let d of filter.DATEFILTERLIST
                                                                            "
                                                                            [value]="
                                                                                d.value
                                                                            ">{{
                                                                            d.description
                                                                            }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.selectedFilter !==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE ==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE ==
                                                                                'CURRENCY')
                                                                    ">
                                                                    <span
                                                                        class="back-tick">
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow" />
                                                                    </span>
                                                                    <mat-form-field
                                                                        class="width-full"
                                                                        appearance="outline"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'NUMBER'
                                                                        ">
                                                                        <input
                                                                            matInput
                                                                            placeholder
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                            appNumberMask />
                                                                    </mat-form-field>

                                                                    <mat-form-field
                                                                        class="width-full"
                                                                        appearance="outline"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'CURRENCY'
                                                                        ">
                                                                        <input
                                                                            matInput
                                                                            placeholder
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                            currencyMask />
                                                                    </mat-form-field>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.FILTERTYPE !==
                                                                            'NUMBER' &&
                                                                        filter.FILTERTYPE !==
                                                                            'CURRENCY' &&
                                                                        filter.FILTERTYPE !==
                                                                            'DATE'
                                                                    ">
                                                                    <span
                                                                        class="back-tick">
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow" />
                                                                    </span>
                                                                    <mat-form-field
                                                                        class="width-full"
                                                                        appearance="outline"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        ">
                                                                        <input
                                                                            matInput
                                                                            placeholder
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required />
                                                                    </mat-form-field>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.selectedFilter ==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE ==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE ==
                                                                                'CURRENCY')
                                                                    ">
                                                                    <span
                                                                        class="back-tick">
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow" />
                                                                    </span>
                                                                    <div
                                                                        class="display-flex align-center"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'NUMBER'
                                                                        ">
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline">
                                                                            <input
                                                                                matInput
                                                                                placeholder
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                                appNumberMask />
                                                                        </mat-form-field>
                                                                        <span
                                                                            class="middle-caption">and</span>
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline">
                                                                            <input
                                                                                matInput
                                                                                placeholder
                                                                                name="VALUE2"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                required
                                                                                appNumberMask />
                                                                        </mat-form-field>
                                                                    </div>

                                                                    <div
                                                                        class="display-flex align-center"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        "
                                                                        *ngIf="
                                                                            filter.FILTERTYPE ==
                                                                            'CURRENCY'
                                                                        ">
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline">
                                                                            <input
                                                                                matInput
                                                                                placeholder
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                                currencyMask />
                                                                        </mat-form-field>
                                                                        <span
                                                                            class="middle-caption">and</span>
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline">
                                                                            <input
                                                                                matInput
                                                                                placeholder
                                                                                name="VALUE2"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                required
                                                                                currencyMask />
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.FILTERTYPE ==
                                                                            'DATE' &&
                                                                        filter.selectedFilter &&
                                                                        filter.selectedFilter !==
                                                                            'is in the last' &&
                                                                        filter.selectedFilter !==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE !==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE !==
                                                                                'CURRENCY')
                                                                    ">
                                                                    <span
                                                                        class="back-tick">
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow" />
                                                                    </span>
                                                                    <div
                                                                        class="display-flex align-center">
                                                                        <mat-form-field
                                                                            appearance="outline"
                                                                            class="set-date"
                                                                            (click)="
                                                                                preventDefault(
                                                                                    $event
                                                                                )
                                                                            ">
                                                                            <input
                                                                                matInput
                                                                                [matDatepicker]="
                                                                                    picker6
                                                                                "
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required />
                                                                            <mat-datepicker-toggle
                                                                                matSuffix
                                                                                [for]="
                                                                                    picker6
                                                                                ">
                                                                            </mat-datepicker-toggle>
                                                                            <mat-datepicker
                                                                                #picker6>
                                                                            </mat-datepicker>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        (filter.FILTERTYPE ==
                                                                            'DATE' &&
                                                                            !filter.selectedFilter) ||
                                                                        (filter.selectedFilter ==
                                                                            'is in the last' &&
                                                                            (filter.FILTERTYPE !==
                                                                                'NUMBER' ||
                                                                                filter.FILTERTYPE !==
                                                                                    'CURRENCY'))
                                                                    ">
                                                                    <span
                                                                        class="back-tick">
                                                                        <img
                                                                            src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow" />
                                                                    </span>
                                                                    <div
                                                                        class="display-flex align-center field-box"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        ">
                                                                        <mat-form-field
                                                                            class="set-date"
                                                                            appearance="outline">
                                                                            <input
                                                                                matInput
                                                                                placeholder
                                                                                name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                [(value)]="
                                                                                    addFilter.VALUE
                                                                                "
                                                                                required
                                                                                appNumberMask />
                                                                        </mat-form-field>
                                                                        <mat-form-field
                                                                            appearance="outline"
                                                                            class="set-date">
                                                                            <mat-select
                                                                                formControlName="VALUE2"
                                                                                [(value)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                [(ngModel)]="
                                                                                    addFilter.VALUE2
                                                                                "
                                                                                required>
                                                                                <mat-option
                                                                                    value="days">Days
                                                                                </mat-option>
                                                                                <mat-option
                                                                                    value="month">Month
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="display-flex align-center"
                                                                    *ngIf="
                                                                        filter.FILTERTYPE ==
                                                                            'DATE' &&
                                                                        filter.selectedFilter ==
                                                                            'is between' &&
                                                                        (filter.FILTERTYPE !==
                                                                            'NUMBER' ||
                                                                            filter.FILTERTYPE !==
                                                                                'CURRENCY')
                                                                    ">
                                                                    <mat-form-field
                                                                        appearance="outline"
                                                                        class="set-date"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        ">
                                                                        <input
                                                                            matInput
                                                                            [matDatepicker]="
                                                                                picker
                                                                            "
                                                                            #StartDate
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE
                                                                            "
                                                                            required
                                                                            (keydown)="
                                                                                preventDefault(
                                                                                    $event
                                                                                )
                                                                            " />
                                                                        <mat-datepicker-toggle
                                                                            matSuffix
                                                                            [for]="
                                                                                picker
                                                                            ">
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker
                                                                            #picker>
                                                                        </mat-datepicker>
                                                                    </mat-form-field>
                                                                    <span
                                                                        class="middle-caption">and</span>
                                                                    <mat-form-field
                                                                        appearance="outline"
                                                                        class="set-date"
                                                                        (click)="
                                                                            preventDefault(
                                                                                $event
                                                                            )
                                                                        ">
                                                                        <input
                                                                            matInput
                                                                            [matDatepicker]="
                                                                                picker2
                                                                            "
                                                                            #EndDate
                                                                            name="VALUE2"
                                                                            formControlName="VALUE2"
                                                                            [(ngModel)]="
                                                                                addFilter.VALUE2
                                                                            "
                                                                            [(value)]="
                                                                                addFilter.VALUE2
                                                                            "
                                                                            required
                                                                            (keydown)="
                                                                                preventDefault(
                                                                                    $event
                                                                                )
                                                                            " />
                                                                        <mat-datepicker-toggle
                                                                            matSuffix
                                                                            [for]="
                                                                                picker2
                                                                            ">
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker
                                                                            #picker2>
                                                                        </mat-datepicker>
                                                                    </mat-form-field>
                                                                </div>

                                                                <button
                                                                    mat-raised-button
                                                                    color="primary"
                                                                    class="width-full accent"
                                                                    (click)="
                                                                        addfilter(
                                                                            filter,
                                                                            operationFilter,
                                                                            $event
                                                                        )
                                                                    ">
                                                                    Apply
                                                                </button>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-menu>
                        </div>
                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters && activeFilters.status
                                        ? clearFilter('status', 'status')
                                        : false
                                ">
                                {{
                                activeFilters && activeFilters.status
                                ? "cancel_circle"
                                : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="mainsafeStatus"
                                class="buttonwith-text dotted-button">
                                Status
                                <span
                                    class="filter-applytext display-flex align-center">{{
                                    activeFilters && activeFilters.status
                                    ? activeFilters.status
                                    : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon></span>
                            </button>

                            <mat-menu #mainsafeStatus="matMenu">
                                <mat-radio-group
                                    aria-labelledby="filter-radio-group-label"
                                    class="filter-radio-group"
                                    formControlName="STATUS"
                                    (change)="
                                        Filterconfig($event, 'status');
                                        selectStatus($event.value)
                                    ">
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="All">All</mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Checked In ">Checked In
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Checked In/Returned">Checked
                                        In/Returned</mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Borrowed">Borrowed</mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Returned">Returned</mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Checked Out ">Checked Out
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Not Checked Out">Not Checked Out</mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Review Soon ">Review Soon
                                    </mat-radio-button>
                                    <mat-radio-button
                                        class="filter-radio-button"
                                        value="Archived">Archived</mat-radio-button>
                                </mat-radio-group>
                            </mat-menu>
                        </div>

                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters && activeFilters.dayRange
                                        ? clearFilter('dayRange', 'dayRange')
                                        : false
                                ">
                                {{
                                activeFilters && activeFilters.dayRange
                                ? "cancel_circle"
                                : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="menuMatterClass"
                                class="buttonwith-text dotted-button">
                                Day Range<span
                                    class="filter-applytext display-flex align-center">{{
                                    activeFilters && activeFilters.dayRange
                                    ? activeFilters.dayRange
                                    : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon></span>
                            </button>
                            <mat-menu
                                #menuMatterClass="matMenu"
                                backdropClass="searchradio">
                                <div class="filterscroll">
                                    <mat-radio-group
                                        aria-labelledby="filter-radio-group-label"
                                        class="filter-radio-group"
                                        formControlName="DayRange">
                                        <mat-radio-button
                                            class="filter-radio-button"
                                            *ngFor="
                                                let dayRange of dayRangeOptions
                                            "
                                            value="{{ dayRange.value }}"
                                            (click)="
                                                Filterconfig(
                                                    dayRange,
                                                    'dayRange'
                                                );
                                                SelectDayRangeFilter(
                                                    activeFilters.dayRange
                                                )
                                            ">
                                            {{ dayRange.description }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </mat-menu>
                        </div>

                        <div
                            class="position-relative"
                            *ngIf="forHideShowDateRangePicker">
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    activeFilters && activeFilters.daterange
                                        ? clearFilter('daterange', 'Date Range')
                                        : false
                                ">
                                {{
                                activeFilters && activeFilters.daterange
                                ? "cancel_circle"
                                : "add_circle"
                                }}
                            </mat-icon>
                            <button
                                mat-button
                                [matMenuTriggerFor]="menucard"
                                class="buttonwith-text dotted-button">
                                Date Range<span
                                    class="filter-applytext display-flex align-center">{{
                                    activeFilters && activeFilters.daterange
                                    ? activeFilters.daterange
                                    : ""
                                    }}<mat-icon class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon></span>
                            </button>
                            <mat-menu #menucard="matMenu" #menu>
                                <h5 class="filter-title">
                                    Filter by Date Range
                                </h5>
                                <div class="display-flex align-center">
                                    <span class="back-tick">
                                        <img
                                            src="assets/icons/lefticon.png"
                                            alt="Left Arrow" />
                                    </span>
                                    <div
                                        class="display-flex align-center"
                                        (click)="preventDefault($event)">
                                        <mat-form-field
                                            appearance="outline"
                                            class="example dateinput">
                                            <input
                                                matInput
                                                [satDatepicker]="picker2"
                                                formControlName="dateRang"
                                                (dateChange)="
                                                    Filterconfig(
                                                        $event,
                                                        'DATERANGE'
                                                    )
                                                " />
                                            <sat-datepicker
                                                #picker2
                                                [rangeMode]="true">
                                            </sat-datepicker>
                                            <sat-datepicker-toggle
                                                matSuffix
                                                [for]="picker2"></sat-datepicker-toggle>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="width-full accent"
                                    (click)="applayDateRangeDate()">
                                    Apply
                                </button>
                            </mat-menu>
                        </div>
                        <!-- <div class="position-relative">
                            <mat-checkbox class="check_top" (change)="MatterChecxed()" formControlName="MATTERCHECK">All
                                Matters </mat-checkbox>
                        </div> -->
                        <div class="position-relative">
                            <mat-icon
                                class="badge-ic"
                                (click)="clearFilter('MATTER', 'MATTER')"
                                *ngIf="f.MATTER.value != ''">cancel_circle</mat-icon>
                            <button
                                mat-button
                                class="buttonwith-text"
                                style="padding: 1px 5px !important">
                                Matters<span
                                    class="filter-applytext display-flex align-center">
                                    <input
                                        matInput
                                        (keyup)="clickMatterserch_Fast($event)"
                                        [matAutocomplete]="invoiceText"
                                        formControlName="MATTER"
                                        style="margin-top: 4px" />
                                    <mat-autocomplete
                                        #invoiceText="matAutocomplete"
                                        [panelWidth]="300"
                                        (optionSelected)="MatterSelect($event.option.value)"
                                        class="matter-searchlisting mt-10">
                                        <mat-option
                                            *ngFor="let Lookups of exportdatavalue "
                                            [value]="Lookups">
                                            {{Lookups.SELECTEDDESCRIPTION}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- <mat-chip-list #chipList aria-label="Matter selection">
                                <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)"
                                    class="mat-chips-selected">
                                    {{matterdata}}
                                    <button matChipRemove class="canclebutton">
                                        <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input matInput (keydown.Tab)="datashow($event)" formControlName="MATTER" #MatterInput1 style="margin-top: 4px;"
                                    [matAutocomplete]="autoMatter" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)"
                                    (keyup)='selectMatterFormSearch(MatterInput1?.value)'
                                    [ngClass]="mattername.length > 0?'hideInput':'showInput'" [disabled]="hide" >
                            </mat-chip-list>
                            <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)">
                                <mat-option class="mat-option_1" *ngFor="let data of matterlist"
                                    (click)="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                    {{data.SHORTNAME}}--{{data.MATTER}}
                                </mat-option>
                            </mat-autocomplete> -->
                                    <!-- class="set_icon {{ ImgDisAb }}" -->
                                    <mat-icon
                                        matSuffix
                                        class="set_icon"
                                        (click)="SelectMatter()">
                                        <img
                                            src="assets/icons/web_app/icon_matter_d.ico" />
                                    </mat-icon>
                                </span>
                            </button>
                        </div>
                        <!-- <div class="position-relative">
                            <mat-checkbox class="check_top" (change)="ContactChecxed()" formControlName="CLIENTCHECK">All
                                Contacts </mat-checkbox>
                        </div> -->
                        <div class="position-relative">
                            <!-- <mat-icon
                                class="badge-ic"
                                (click)="clearFilter('CLIENT', 'CLIENT')"
                                *ngIf="activeFilters.CLIENT"
                            >
                                {{
                                    activeFilters && activeFilters.CLIENT
                                        ? "cancel_circle"
                                        : ""
                                }}</mat-icon
                            > -->
                            <mat-icon
                                class="badge-ic"
                                (click)="clearFilter('CLIENT', 'CLIENT')"
                                *ngIf="f.CLIENT.value != ''">cancel_circle</mat-icon>
                            <button
                                mat-button
                                class="buttonwith-text"
                                style="padding: 1px 5px">
                                Client<span
                                    class="filter-applytext display-flex align-center">
                                    <input
                                        (keyup)="clickClientserch_Fast($event)"
                                        matInput
                                        [matAutocomplete]="clinettext"
                                        formControlName="CLIENT"
                                        style="margin-top: 4px" />
                                    <mat-autocomplete
                                        #clinettext="matAutocomplete"
                                        [panelWidth]="200"
                                        (optionSelected)="ClientSelect($event.option.value)"
                                        class="matter-searchlisting mt-10">
                                        <mat-option
                                            *ngFor="let Lookups of exportdatavalue "
                                            [value]="Lookups">
                                            {{Lookups.CONTACTNAME}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- class="set_icon {{ ImgDisAb }}" -->
                                    <mat-icon
                                        class="set_icon"
                                        matSuffix
                                        (click)="SelectContact()">
                                        <img
                                            src="assets/icons/web_app/icon_contact_d.ico" />
                                    </mat-icon>
                                </span>
                            </button>
                        </div>

                        <div class="position-relative">
                            <!-- search  -->
                            <mat-icon
                                class="badge-ic"
                                (click)="
                                    clearFilter('SearchString', 'SearchString')
                                "
                               >
                                {{
                                activeFilters && activeFilters.SearchString
                                ? "cancel_circle"
                                : "add_circle"
                                }}</mat-icon>

                            <button
                                mat-button
                                class="buttonwith-text"
                                style="padding: 1px 5px">
                                Search<span
                                    class="filter-applytext display-flex align-center">
                                    <input
                                        matInput
                                        formControlName="SEARCH"
                                        name="search"
                                        [(ngModel)]="activeFilters.SearchString"
                                        (keyup)="
                                            onSearch($event);
                                            Filterconfig($event, 'SearchString')
                                        "
                                        #mainSafesearchField
                                        style="
                                            margin-top: 4px;
                                            text-align: left;
                                        "
                                        tabindex="6" />
                                    <mat-icon matSuffix (click)="appyFilters()">search</mat-icon>
                                </span>
                            </button>
                            <!-- ends here ~ search  -->

                            <!-- OLD SEARCH -->
                            <!-- <mat-icon class="badge-ic"
                                (click)="(activeFilters && activeFilters.SearchString)?clearFilter('SearchString' , 'SearchString'):false">
                                {{(activeFilters && activeFilters.SearchString)?'cancel_circle' : 'add_circle'}}
                            </mat-icon>
                            <button mat-button [matMenuTriggerFor]="mainsafeSearch" class="buttonwith-text"
                                (click)="setFocus('searchField')">
                                Search<span class="filter-applytext display-flex align-center">{{(activeFilters
                                    &&
                                    activeFilters.SearchString)?activeFilters.SearchString : ''}}<mat-icon
                                        class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon></span>
                            </button>
                            <mat-menu #mainsafeSearch="matMenu">
                                <div class="filter-emailbox display-flex align-center">
                                    <mat-form-field class="example" appearance="outline"
                                        (click)="preventDefault($event)">
                                        <input matInput placeholder="Search" formControlName="SEARCH"
                                            name="search" [(ngModel)]="activeFilters.SearchString"
                                            (keyup)="onSearch($event);Filterconfig($event,'SearchString')"
                                            #mainSafesearchField>
                                        <mat-icon matSuffix (click)="appyFilters()">search
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <button mat-raised-button color="primary" class="width-full accent"
                                    (click)="appyFilters()">Apply</button>
                            </mat-menu> -->
                            <!-- ends here ~ OLD SEARCH -->
                        </div>

                        <!-- ADD Optional Filter -->
                        <ng-container formArrayName="FILTERS">
                            <div
                                *ngFor="
                                    let filter of optionalFilters;
                                    let i = index
                                "
                                class="position-relative">
                                <ng-container [formGroupName]="i">
                                    <mat-icon
                                        class="badge-ic"
                                        (click)="
                                            resetOptionalFilter(
                                                filter,
                                                filter.FILTERTYPE,
                                                i
                                            )
                                        ">cancel_circle
                                    </mat-icon>
                                    <button
                                        mat-button
                                        [matMenuTriggerFor]="menuCreatedDate_i"
                                        class="buttonwith-text">
                                        {{ filter.DESCRIPTION
                                        }}<span
                                            class="filter-applytext display-flex align-center">{{
                                            optionalDateFilterPayload[i] &&
                                            optionalDateFilterPayload[i][
                                            "value"
                                            ] !== null
                                            ? optionalDateFilterPayload[
                                            i
                                            ]["value"].VALUE
                                            : ""
                                            }}
                                            {{
                                            optionalDateFilterPayload[i] &&
                                            optionalDateFilterPayload[i][
                                            "value"
                                            ] !== null &&
                                            optionalDateFilterPayload[i][
                                            "value"
                                            ].VALUE2 !== "" &&
                                            optionalDateFilterPayload[i][
                                            "value"
                                            ].VALUE2 !== null
                                            ? " - " +
                                            optionalDateFilterPayload[
                                            i
                                            ]["value"].VALUE2
                                            : ""
                                            }}<mat-icon class="rightdownarrow">
                                                keyboard_arrow_down</mat-icon></span>
                                    </button>
                                    <mat-menu #menuCreatedDate_i="matMenu">
                                        <h5 class="filter-title">
                                            Filter by {{ filter.DESCRIPTION }}
                                        </h5>
                                        <mat-form-field
                                            appearance="outline"
                                            class="width-full"
                                            (click)="preventDefault($event)">
                                            <mat-select
                                                (selectionChange)="
                                                    optionalDateFilter(
                                                        $event,
                                                        filter,
                                                        'active',
                                                        i
                                                    )
                                                "
                                                name="OPERATION"
                                                formControlName="OPERATION"
                                                #Datefilter>
                                                <mat-option
                                                    *ngFor="
                                                        let d of filter.DATEFILTERLIST
                                                    "
                                                    [value]="d.value">{{ d.value
                                                    }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.selectedFilter !==
                                                    'is between' &&
                                                (filter.FILTERTYPE ==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE ==
                                                        'CURRENCY')
                                            ">
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow" />
                                            </span>
                                            <mat-form-field
                                                class="width-full"
                                                appearance="outline"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'NUMBER'
                                                "
                                                appNumberMask
                                                (click)="preventDefault($event)">
                                                <input
                                                    matInput
                                                    placeholder
                                                    value
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    required />
                                            </mat-form-field>

                                            <mat-form-field
                                                class="width-full"
                                                appearance="outline"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'CURRENCY'
                                                "
                                                currencyMask
                                                (click)="preventDefault($event)">
                                                <input
                                                    matInput
                                                    placeholder
                                                    value
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    required />
                                            </mat-form-field>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.FILTERTYPE !==
                                                    'NUMBER' &&
                                                filter.FILTERTYPE !==
                                                    'CURRENCY' &&
                                                filter.FILTERTYPE !== 'DATE'
                                            ">
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow" />
                                            </span>
                                            <mat-form-field
                                                class="width-full"
                                                appearance="outline"
                                                (click)="preventDefault($event)">
                                                <input
                                                    matInput
                                                    placeholder
                                                    value
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    required />
                                            </mat-form-field>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.selectedFilter ==
                                                    'is between' &&
                                                (filter.FILTERTYPE ==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE ==
                                                        'CURRENCY')
                                            ">
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow" />
                                            </span>
                                            <div
                                                class="display-flex align-center"
                                                (click)="preventDefault($event)"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'NUMBER'
                                                ">
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline">
                                                    <input
                                                        matInput
                                                        placeholder
                                                        value
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        appNumberMask
                                                        required />
                                                </mat-form-field>
                                                <span class="middle-caption">and</span>
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline">
                                                    <input
                                                        matInput
                                                        placeholder
                                                        value
                                                        name="VALUE2"
                                                        formControlName="VALUE2"
                                                        appNumberMask
                                                        required />
                                                </mat-form-field>
                                            </div>

                                            <div
                                                class="display-flex align-center"
                                                (click)="preventDefault($event)"
                                                *ngIf="
                                                    filter.FILTERTYPE ==
                                                    'CURRENCY'
                                                ">
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline">
                                                    <input
                                                        matInput
                                                        placeholder
                                                        value
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        currencyMask
                                                        required />
                                                </mat-form-field>
                                                <span class="middle-caption">and</span>
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline">
                                                    <input
                                                        matInput
                                                        placeholder
                                                        value
                                                        name="VALUE2"
                                                        formControlName="VALUE2"
                                                        currencyMask
                                                        required />
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.FILTERTYPE == 'DATE' &&
                                                filter.selectedFilter &&
                                                filter.selectedFilter !==
                                                    'is in the last' &&
                                                filter.selectedFilter !==
                                                    'is between' &&
                                                (filter.FILTERTYPE !==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE !==
                                                        'CURRENCY')
                                            ">
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow" />
                                            </span>
                                            <div
                                                class="display-flex align-center">
                                                <mat-form-field
                                                    appearance="outline"
                                                    class="set-date"
                                                    (click)="
                                                        preventDefault($event)
                                                    ">
                                                    <input
                                                        matInput
                                                        [matDatepicker]="
                                                            picker6
                                                        "
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        required />
                                                    <mat-datepicker-toggle
                                                        matSuffix
                                                        [for]="picker6"></mat-datepicker-toggle>
                                                    <mat-datepicker
                                                        #picker6></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                (filter.FILTERTYPE == 'DATE' &&
                                                    !filter.selectedFilter) ||
                                                (filter.selectedFilter ==
                                                    'is in the last' &&
                                                    (filter.FILTERTYPE !==
                                                        'NUMBER' ||
                                                        filter.FILTERTYPE !==
                                                            'CURRENCY'))
                                            ">
                                            <span class="back-tick">
                                                <img
                                                    src="assets/icons/lefticon.png"
                                                    alt="Left Arrow" />
                                            </span>
                                            <div
                                                class="display-flex align-center field-box"
                                                (click)="preventDefault($event)">
                                                <mat-form-field
                                                    class="set-date"
                                                    appearance="outline">
                                                    <input
                                                        matInput
                                                        placeholder
                                                        value
                                                        name="VALUE"
                                                        formControlName="VALUE"
                                                        required
                                                        appNumberMask />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    class="set-date">
                                                    <mat-select
                                                        [value]="'days'"
                                                        formControlName="VALUE2"
                                                        required>
                                                        <mat-option value="days">Days</mat-option>
                                                        <mat-option
                                                            value="month">Month
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div
                                            class="display-flex align-center"
                                            *ngIf="
                                                filter.FILTERTYPE == 'DATE' &&
                                                filter.selectedFilter ==
                                                    'is between' &&
                                                (filter.FILTERTYPE !==
                                                    'NUMBER' ||
                                                    filter.FILTERTYPE !==
                                                        'CURRENCY')
                                            ">
                                            <mat-form-field
                                                appearance="outline"
                                                class="set-date"
                                                (click)="preventDefault($event)">
                                                <input
                                                    matInput
                                                    [matDatepicker]="picker"
                                                    #StartDate
                                                    name="VALUE"
                                                    formControlName="VALUE"
                                                    required />
                                                <mat-datepicker-toggle
                                                    matSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker></mat-datepicker>
                                            </mat-form-field>
                                            <span class="middle-caption">and</span>
                                            <mat-form-field
                                                appearance="outline"
                                                class="set-date"
                                                (click)="preventDefault($event)">
                                                <input
                                                    matInput
                                                    [matDatepicker]="picker2"
                                                    #EndDate
                                                    name="VALUE2"
                                                    formControlName="VALUE2"
                                                    required />
                                                <mat-datepicker-toggle
                                                    matSuffix
                                                    [for]="picker2"></mat-datepicker-toggle>
                                                <mat-datepicker
                                                    #picker2></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <button
                                            mat-raised-button
                                            color="primary"
                                            class="width-full accent"
                                            (click)="
                                                applayOptionalfilter(
                                                    optionalFilters[i],
                                                    i,
                                                    Datefilter,
                                                    $event
                                                )
                                            ">
                                            Apply
                                        </button>
                                    </mat-menu>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <div
                        class="bg-dark rigth-div"
                        id="appCues_main_safe_custody"
                    ></div> -->
                </form>
                <!-- <div class="bg-dark rigth-div" id="appCues_file-notes"></div> -->
            </div>
            <div class="content">
                <div class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <div
                                    class="btn top_right_action_billing_position dot_controller">
                                    <button
                                        mat-icon-button
                                        [matMenuTriggerFor]="contextMenu"
                                        aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template
                                            matMenuContent
                                            let-item="item">
                                            <button
                                                mat-menu-item
                                                (click)="openDialog()">
                                                Field Configuration
                                            </button>
                                            <!-- <button
                                                mat-menu-item
                                                (click)="
                                                    refreshmainsafecusday()
                                                ">
                                                Reload Data
                                            </button> -->
                                            <!-- <button
                                                mat-menu-item
                                                (click)="printPage()">
                                                Create Report
                                            </button> -->
                                            <!-- <button mat-menu-item (click)="downloadFile(MainSafeCustodyData.data)">
                                                <img src="assets/icons/web_app/EXP_CSV.ico" style="height: 20px !important;margin-top: 10px;">
                                                Download CSV
                                            </button> -->

                                            <!-- <button
                                                mat-menu-item
                                                (click)="DownloadAllData()">
                                                <img
                                                    src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="
                                                        height: 20px !important;
                                                        margin-top: 10px;
                                                    " />
                                                Export to CSV - retrieve all
                                                data first
                                            </button>
                                            <button
                                                mat-menu-item
                                                (click)="
                                                    downloadFile(
                                                        MainSafeCustodyData.data
                                                    )
                                                ">
                                                <img
                                                    src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="
                                                        height: 20px !important;
                                                        margin-top: 10px;
                                                    " />
                                                Export to CSV - current data
                                            </button> -->

                                            <button
                                                mat-menu-item
                                                (click)='getNewExportData()'
                                                   >
                                                <img
                                                    src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="
                                                        height: 20px !important;
                                                        margin-top: 10px;
                                                    " />
                                                Export to CSV
                                            </button>
                                        </ng-template>
                                    </mat-menu>
                                    <button
                                        mat-icon-button
                                        (click)="
                                            IsSafeCustodyList =
                                                !IsSafeCustodyList
                                        "
                                        aria-label="More">
                                        <mat-icon class="secondary-text">menu</mat-icon>
                                    </button>
                                </div>
                                <div class="content">
                                    <div
                                        class="example-loading-shade"
                                        *ngIf="isLoadingResults">
                                        <mat-spinner
                                            [style.zoom]="0.5"
                                            *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div
                                        class="resizable-table"
                                        [ngClass]="
                                            class == true
                                                ? 'Table-height-msc'
                                                : 'Table-height-msc-2'
                                        ">
                                        <div
                                            class="grid-overlay"
                                            *ngIf="isDisplay">
                                            <div class="grid-overlay-msg">
                                                There is no data to display.
                                            </div>
                                        </div>
                                        <mat-table
                                            #table
                                            [dataSource]="MainSafeCustodyData"
                                            (matSortChange)="sortData($event)"
                                            matSortActive="{{ sortactive }}"
                                            matSortDirection="{{
                                                sortDirection
                                            }}"
                                            matSortDisableClear
                                            matSort
                                            [@animateStagger]="{ value: '50' }">
                                            <ng-container
                                                *ngFor="
                                                    let item of displayedColumns;
                                                    let i = index
                                                "
                                                matColumnDef="{{ item }}">
                                                <mat-header-cell
                                                    *matHeaderCellDef="
                                                        let i = index
                                                    "
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="
                                                        onResizing($event, item)
                                                    "
                                                    (resizeEnd)="
                                                        onResizeEnd(
                                                            $event,
                                                            item
                                                        )
                                                    "
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right"
                                                        mwlResizeHandle
                                                        [resizeEdges]="{
                                                            right: true
                                                        }"></div>
                                                    {{
                                                    tempColobj[item]
                                                    .DESCRIPTION
                                                    }}
                                                </mat-header-cell>
                                                <mat-cell
                                                    *matCellDef="let row"
                                                    class="text-{{
                                                        tempColobj[item]
                                                            .JUSTIFICATION
                                                    }}-align mat-column-{{
                                                        item
                                                    }}"
                                                    [ngClass]="{
                                                        'word-wrap-class':
                                                            tempColobj[item]
                                                                .WIDEDATA
                                                    }"
                                                    (click)="selectRow(row)">
                                                    <span
                                                        *ngIf="
                                                            row[item] !==
                                                                undefined &&
                                                            (Object.keys(
                                                                hyperLinkInfo
                                                            ).indexOf(item) ==
                                                                -1 ||
                                                                hyperLinkInfo[
                                                                    item
                                                                ].LINKFIELD ==
                                                                    '' ||
                                                                row[
                                                                    hyperLinkInfo[
                                                                        item
                                                                    ].LINKFIELD
                                                                ] ==
                                                                    undefined ||
                                                                row[
                                                                    hyperLinkInfo[
                                                                        item
                                                                    ].LINKFIELD
                                                                ] == '')
                                                        ">{{
                                                        row[item]
                                                        | toFixedAmount
                                                        : tempColobj[
                                                        item
                                                        ].CURRENCY
                                                        | wordwrap
                                                        : tempColobj[
                                                        item
                                                        ].WIDEDATA
                                                        }}</span>
                                                    <span
                                                        *ngIf="
                                                            row[item] &&
                                                            Object.keys(
                                                                hyperLinkInfo
                                                            ).indexOf(item) >=
                                                                0 &&
                                                            hyperLinkInfo[item]
                                                                .LINKFIELD !=
                                                                '' &&
                                                            row[
                                                                hyperLinkInfo[
                                                                    item
                                                                ].LINKFIELD
                                                            ] != undefined &&
                                                            row[
                                                                hyperLinkInfo[
                                                                    item
                                                                ].LINKFIELD
                                                            ] != ''
                                                        "
                                                        (click)="
                                                            selectHyperLink(
                                                                row,
                                                                item,
                                                                hyperLinkInfo[
                                                                    item
                                                                ]
                                                            )
                                                        "
                                                        [ngClass]="
                                                            HyperlinkColor
                                                        "
                                                        [style]="
                                                            highlightedRows ==
                                                                row.SAFECUSTODYGUID &&
                                                            currentuserdata?.PRODUCTTYPE ==
                                                                'Solicitor'
                                                                ? 'color: #43a047 !important'
                                                                : highlightedRows ==
                                                                      row.SAFECUSTODYGUID &&
                                                                  currentuserdata?.PRODUCTTYPE ==
                                                                      'Barrister'
                                                                ? 'color: #4E2083 !important'
                                                                : ''
                                                        ">
                                                        {{ row[item] }}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="
                                                    displayedColumns;
                                                    sticky: true
                                                ">
                                            </mat-header-row>
                                            <mat-row
                                                [style.background]="
                                                    highlightedRows ==
                                                    row.SAFECUSTODYGUID
                                                        ? selectedColore
                                                        : ''
                                                "
                                                *matRowDef="
                                                    let row;
                                                    columns: displayedColumns
                                                "
                                                [ngClass]="
                                                    highlightedRows ==
                                                    row.SAFECUSTODYGUID
                                                        ? 'row-text-colore'
                                                        : ''
                                                "
                                                class="contact"
                                                (click)="
                                                    editsafecustody(row);
                                                    highlightedRows =
                                                        row.SAFECUSTODYGUID
                                                "
                                                matRipple
                                                [@animate]="{
                                                    value: '*',
                                                    params: { y: '100%' }
                                                }">
                                            </mat-row>
                                        </mat-table>
                                    </div>
                                    <mat-paginator
                                        [pageSizeOptions]="[10, 20, 50, 100]"
                                        [pageSize]="
                                            pageSize?.main_safe_custoday
                                        "
                                        (page)="onPaginateChange($event)"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <fuse-sidebar
            *ngIf="IsSafeCustodyList"
            [ngStyle]="{
                height: 'auto',
                'margin-right': '10px',
                'margin-bottom': '23px'
            }"
            id="1"
            id="list_sidebar_right_child_detail"
            class="main-sidebar sidebar final-sidebar list_sidebar_right_child mt-75"
            name="final-sidebar"
            position="right"
            lockedOpen="gt-md">
            <app-safe-custody-details
                class="list_sidebar_padding"></app-safe-custody-details>
        </fuse-sidebar>
    </div>
</div>
<button
    mat-raised-button
    type="button"
    color="primary"
    id="mainsafecusday"
    (click)="refreshmainsafecusday()">
    Refresh
</button>
