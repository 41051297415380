<mat-drawer-container class="matter-details-sidebar" autosize
    [ngClass]="{ 'container-closed': !showFiller }">
    <mat-drawer #drawer
        class="example-sidenav matter-details-side-nav position-relative"
        mode="side" disableClose="true"
        opened="true">

        <mat-icon role="img" style="margin-left:8px;"
            class="mat-icon notranslate secondary-text material-icons mat-ligature-font mat-icon-no-color cursor-pointer"
            aria-hidden="true" data-mat-icon-type="font"
            (click)="showFiller = !showFiller"
            [ngClass]="(showFiller)?'legal-detail-icon':'legal-detail-icon-close'">menu</mat-icon>

        <app-bank-feed-sidebar [showFiller]="showFiller"
            class="sidebar-container"></app-bank-feed-sidebar>
    </mat-drawer>
        <router-outlet></router-outlet>
        <!-- </div> -->
   
</mat-drawer-container>