<div class=" one " fxLayout="column" [formGroup]="WillEstateForm">

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFWILL,'has-warning':errorWarningData.Warning?.DATEOFWILL}">
            <mat-label>Date of Will</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'DATEOFWILL')" formControlName="DATEOFWILLTEXT" (dateChange)="CommanDatePickerEvent('change', $event,'DATEOFWILL')" matInput name="DateofWill" [matDatepicker]="DateofWill" matTooltip="{{(toolTipList)?toolTipList.DATEOFWILL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DateofWill"></mat-datepicker-toggle>
            <mat-datepicker #DateofWill></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEOFWILL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFWILL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFWILL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PAGESINWILL,'has-warning':errorWarningData.Warning?.PAGESINWILL}">
            <mat-label>Pages in Will</mat-label>
            <input matInput formControlName="PAGESINWILL" matTooltip="{{(toolTipList)?toolTipList.PAGESINWILL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.PAGESINWILL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PAGESINWILL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAGESINWILL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NAMEINWILL,'has-warning':errorWarningData.Warning?.NAMEINWILL}">
            <mat-label>Name in Will </mat-label>
            <input matInput formControlName="NAMEINWILL"  matTooltip="{{(toolTipList)?toolTipList.NAMEINWILL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.NAMEINWILL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NAMEINWILL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NAMEINWILL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-checkbox fxFlex="100" formControlName="MARRIEDAFTERWILL">Married after Will? </mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MARRIEDAFTERWILLTOWHOM,'has-warning':errorWarningData.Warning?.MARRIEDAFTERWILLTOWHOM}">
            <mat-label>To Whom</mat-label>
            <input matInput formControlName="MARRIEDAFTERWILLTOWHOM" matTooltip="{{(toolTipList)?toolTipList.MARRIEDAFTERWILLTOWHOM?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.MARRIEDAFTERWILLTOWHOM" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MARRIEDAFTERWILLTOWHOM.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MARRIEDAFTERWILLTOWHOM?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFMARRIAGEAFTERWILL,'has-warning':errorWarningData.Warning?.DATEOFMARRIAGEAFTERWILL}">
            <mat-label>Date Of Marriage</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'DATEOFMARRIAGEAFTERWILL')" formControlName="DATEOFMARRIAGEAFTERWILLTEXT" (dateChange)="CommanDatePickerEvent('change', $event,'DATEOFMARRIAGEAFTERWILL')" matInput name="DateOfMarriage" [matDatepicker]="DateOfMarriage" matTooltip="{{(toolTipList)?toolTipList.DATEOFMARRIAGEAFTERWILL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DateOfMarriage"></mat-datepicker-toggle>
            <mat-datepicker #DateOfMarriage></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEOFMARRIAGEAFTERWILL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFMARRIAGEAFTERWILL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFMARRIAGEAFTERWILL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NUMBEROFCODICILS,'has-warning':errorWarningData.Warning?.NUMBEROFCODICILS}">
            <mat-label># of Codicils</mat-label>
            <input matInput formControlName="NUMBEROFCODICILS" matTooltip="{{(toolTipList)?toolTipList.NUMBEROFCODICILS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.NUMBEROFCODICILS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NUMBEROFCODICILS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NUMBEROFCODICILS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFCODICILS,'has-warning':errorWarningData.Warning?.DATEOFCODICILS}">
            <mat-label>Date/s of Codicil/s</mat-label>
            <input matInput formControlName="DATEOFCODICILS" matTooltip="{{(toolTipList)?toolTipList.DATEOFCODICILS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.DATEOFCODICILS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFCODICILS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFCODICILS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.WITNESS1TOCODICIL,'has-warning':errorWarningData.Warning?.WITNESS1TOCODICIL}">
            <mat-label>Witness 1 </mat-label>
            <input matInput formControlName="WITNESS1TOCODICIL" matTooltip="{{(toolTipList)?toolTipList.WITNESS1TOCODICIL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.WITNESS1TOCODICIL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.WITNESS1TOCODICIL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WITNESS1TOCODICIL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.WITNESS2TOCODICIL,'has-warning':errorWarningData.Warning?.WITNESS2TOCODICIL}">
            <mat-label>Witness 2</mat-label>
            <input matInput formControlName="WITNESS2TOCODICIL" matTooltip="{{(toolTipList)?toolTipList.WITNESS2TOCODICIL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.WITNESS2TOCODICIL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.WITNESS2TOCODICIL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WITNESS2TOCODICIL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>