<div class="nav"
    [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">
        <!-- <p class="name">Favourite </p> -->
        <ng-container *ngFor="let favopages of page; let i=index;">
            <!-- <span *ngIf="favopages.STAR=='star'"> -->
            <span>
                <fuse-nav-vertical-item *ngIf="favopages.type=='item'"
                    (onChangeVal)="onChange($event)"
                    [item]="favopages" [itemIndex]="i"></fuse-nav-vertical-item>
            </span>
        </ng-container>
        <div class="asidebottom-block">
            <mat-slide-toggle [(ngModel)]='EDITFavourite' name="EDITFavourite"
                (change)="ToggleForUpadteFavourite('')">Edit Favourites
            </mat-slide-toggle>
        </div>
        <!-- <form class="example-form favourite_form_div" id="fevrite_form" autocomplete="off">
            <div class="info">Search To Add Favourite</div>
            <div>
                <mat-form-field class="example-full-width">
                    <input matInput id="Searchpagehere" placeholder="Search page here" (click)="changeIcon()" [(ngModel)]="searchpage" name="searchpage">
                    <mat-icon matSuffix class="disabled-text" (click)="clearSearch()">{{icon}}</mat-icon>
                </mat-form-field>
            </div>
            <div>{{searchpage}}</div>
            <div class="listdata" *ngIf="( page | filterByName:searchpage ) as result">
                <mat-list role="list" *ngFor="let pages of result">
                    <mat-list-item role="listitem" (click)="onChange(pages.TITLE)">
                        <mat-icon>
                            <img *ngIf="pages.TITLE=='Matters'" src="assets/icons/web_app/icon_matter_d.ico">
                            <img *ngIf="pages.TITLE=='Contacts'" src="assets/icons/web_app/icon_contact_d.ico">
                            <img *ngIf="pages.TITLE=='Time entries'" src="assets/icons/web_app/icon_time_cost_d.ico">
                            <img *ngIf="pages.TITLE=='Diary'" src="assets/icons/web_app/icon_diary_d.ico">
                            <img *ngIf="pages.TITLE=='Invoices'" src="assets/icons/web_app/icon_invoice_d.ico">
                            <img *ngIf="pages.TITLE=='Spend money'" src="assets/icons/web_app/icon_expenses_d.ico">
                            <img *ngIf="pages.TITLE=='Receive money'" src="assets/icons/web_app/icon_receipt_small_d.ico">
                            <img *ngIf="pages.TITLE=='Generate document'" src="assets/icons/web_app/icon_letters_d.ico">
                            <img *ngIf="pages.TITLE=='Authorities'" src="assets/icons/web_app/icon_authorities_d.ico">
                            <img *ngIf="pages.TITLE=='Searching'" src="assets/icons/web_app/Search.ico">
                            <img *ngIf="pages.TITLE=='Safe Custody'" src="assets/icons/web_app/icon_safecustody_d.ico">
                            <img *ngIf="pages.TITLE=='Trust Money'" src="assets/icons/web_app/icon_trust_d.ico">
                            <img *ngIf="pages.TITLE=='Trust End Of Month'" src="assets/icons/web_app/icon_trust_d.ico">
                            <img *ngIf="pages.TITLE=='Trust Banking'" src="assets/icons/web_app/icon_trust_d.ico">
                            <img *ngIf="pages.TITLE=='Task'" src="assets/icons/web_app/icon_tasks_d.ico">
                            <img *ngIf="pages.TITLE=='Conflict Check'" src="assets/icons/web_app/Select.ico">
                            <img *ngIf="pages.TITLE=='Inbox'" src="assets/icons/web_app/icon_email_u.ico">
                            <img *ngIf="pages.TITLE=='Dashboard'" src="assets/icons/web_app/icon_reports_d.ico">
                        </mat-icon>
                        <div class="side_bar_title">{{pages.TITLE}}</div>
                        <mat-icon class="star">{{pages.STAR}}</mat-icon>
                    </mat-list-item>
                </mat-list>
            </div>
        </form> -->
    </ng-container>
</div>
