import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../matter-popup/matter-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, map } from 'rxjs';
import { ContactDialogComponent } from '../../contact/contact-dialog/contact-dialog.component';
import * as $ from 'jquery';
import { CorrespondDailogComponent } from '../correspond-dailog/correspond-dailog.component';
import { FormGroup } from '@angular/forms';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { TimersService } from '@_services/timers.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-matters-sort-detail',
    templateUrl: './matters-sort-detail.component.html',
    styleUrls: ['./matters-sort-detail.component.scss'],
    animations: fuseAnimations
})
export class MattersSortDetailComponent implements OnInit {
    protected Hyperlinkservice=inject(HyperlinkNavigationService);
    protected TimersServiceI=inject(TimersService);
    protected dialog=inject(MatDialog);
    protected behaviorService=inject(BehaviorService);
    protected _mainAPiServiceService=inject(MainAPiServiceService);
    protected toastr=inject(ToastrService);
    protected _hyperlink=inject(HyperlinkNavigationService);
    detailRecivedSubscription: Subscription;
    mattersDetailData: any;
    mattersConTactmData: any = [];
    @Output() matterCloseD: EventEmitter<any> = new EventEmitter<any>();
    @Input() matterdetailForm: FormGroup;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    timerId: any = 'timer_' + this.currentUser.UserGuid;
    theme_type = localStorage.getItem('theme_type');
    contectTitle = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
    currentuser = JSON.parse(localStorage.getItem('currentUser'));
    barristerTitle: any = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Matter Num' : 'Short Name';
    prevMatterArray: any[] = [];
    isLoadingResults: boolean = false;
    storeData: any = JSON.parse(localStorage.getItem('stored_matter_details'));
    showPushArray: any = [];
    windowNameId: any;
    title = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Client Details' : 'Solicitor Details';
    appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
    oldIsTrackId: any;
    Matterdata: any;
    HyperlinkInformation: any;
    /**
     * Used To get the COLOR Class
     */
    colorClass$= this.behaviorService.ColorSystemSettingHyperLink$.asObservable().pipe(map(colorclass=>({CLASS:colorclass})));
   
    /**
    * Used to get the Matter Data.
    */
    public MatterData$=this.behaviorService.MatterData$.asObservable().pipe(map(res=>{this.Matterdata = res}))
   
    /**
     * Used to get the Hyperlink data.
     */
    public Hyperlink$=this.behaviorService.Hyperlink$.asObservable().pipe(map(hyperlinkInfo=> this.HyperlinkInformation = hyperlinkInfo))
 
     /**
     * Used to get the Matters Data.
     */
    public MD$= this.behaviorService.MatterData$.asObservable().pipe(map(result=>{
    if (result) {
                    this.showPushArray = [];
                    this.storeData.forEach(element => {
                        if (element.MATTERGUID == result.MATTERGUID) {
                            this.showPushArray.push(element);
                        }
                    });
                    setTimeout(() => {
                        this.mattersDetailData = result;
                    }, 1000);
        
                    this.mattersConTactmData = this.showPushArray;
                } else {
                    setTimeout(() => {
                        this.mattersDetailData = null;
                    }, 1000);
        
                    this.mattersConTactmData = null;
                }
   }))

   /**
    * Used to reduse the Matter API CALLS. 
    */
   public ReduceMatterCall$= this.behaviorService.reduceMatterContactCall$.asObservable().pipe(map(result=>{
    if (result) {
        this.mattersConTactmData = [];
        setTimeout(() => {
            this.mattersDetailData = result;
        }, 1000);
        this.isLoadingResults = true;

        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MatterGuid: result.MATTERGUID } }, 'matter-contact').subscribe(res => {
            if (res.CODE == 200) {
                this.storeData = [];
                this.mattersConTactmData = (res.DATA?.MATTERCONTACTS) ? res.DATA.MATTERCONTACTS : res.DATA.RECORDS;
                this.mattersConTactmData.forEach(element => {
                    this.storeData.push(element);
                });
                localStorage.setItem('stored_matter_details', JSON.stringify(this.storeData));
                this.isLoadingResults = false;
            } else {
                this.isLoadingResults = false;
            }

        });
    }
}))

    ngOnInit(): void {
        this.storeData = (this.storeData && this.storeData != null) ? this.storeData : [];
        this._hyperlink.setColorSettingWithHyperlink();
    }

/**
 * Close the Matter details
 * @param event -Matter Info
 */
    closeDetail(event):void {
        this.matterCloseD.emit(event);
    }

    /**
     * Function is used to Addtimeentry.
     */
    addTimeEnrt():void {
        this.TimersServiceI.addTimeEnrtS('');
    }

    /**
     * Used to Add New matter
     */
    AddNewmatterpopup():void {
        let mattersData = JSON.parse(localStorage.getItem('set_active_matters'));
        const dialogRef = this.dialog.open(MatterPopupComponent, {
            width: '100%',
            disableClose: true,
            data: { action: 'edit', 'matterGuid': mattersData.MATTERGUID }
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    /**
     * Used to open the Contact dialog.
     * @param data -contact data.
     */
    ContactsDialog(data?):void {
        this.SetOldistrackid('open');
        let contactPopupData = {};
        if (data && data != undefined && data != null && data != "undefined") {
            contactPopupData = { action: 'edit', CONTACTGUID: data.PERSONGUID }
        } else {
            contactPopupData = { action: 'edit' }
        }

        const dialogRef = this.dialog.open(ContactDialogComponent, {
            disableClose: true, width: '100%', data: contactPopupData
        });
        dialogRef.afterClosed().subscribe(result => {
            this.SetOldistrackid('close');
            if (result)
                $('#refreshContactTab').click();
            $('#refreshMatterTab').click()
        });
    }

    /**
     * Use to set the id
     * @param type -OLD track id
     */
    SetOldistrackid(type):void {
        if (type === 'open') {
            this.oldIsTrackId = localStorage.getItem('istrackid');
        } else {
            localStorage.setItem('istrackid', this.oldIsTrackId);
        }
    }

    get f() {
        return this.matterdetailForm.controls;
    }

    /**
     * Edit Correspondant data.
     * @param editElement -Corraspondant data.
     */
    editElement(editElement) {
        let Matterdata
        this.behaviorService.MatterData$.subscribe(res => {
            Matterdata = res
            this.Matterdata = res;
        });


        const dialogRef = this.dialog.open(CorrespondDailogComponent, {
            width: '100%', disableClose: true, data: { EditData: editElement, type: 'edit', 'FIRMGUID': Matterdata.FIRMGUID }
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            this.behaviorService.reduceMatterContactCall(Matterdata);
            // if (result) {
            //   if (this.isEditMatter != undefined) {
            //     this.loadData(this.isEditMatter,'');
            //   } else {
            //     result.saveData['action'] = 'edit';
            //     result.saveData.index = this.correspondListIndex;
            //     this.Correspond[this.correspondListIndex] = result.showData;
            //     const saveDataRole = []
            //     this.Correspond.forEach(element => {
            //       element.ItemData.TYPE ? saveDataRole.push({ role: element.ItemData.TYPE, ref: element.ItemData.REFERENCE }) : ''
            //     });
            //     result.saveData.CorrespondRoleAndReference = saveDataRole;
            //     this.CorrespondDetail.emit(result.saveData);
            //   }
            // } else {
            //   this.loadData('','');
            // }
        });
    }

/**
 * USed to open the mail box
 * @param emailAddress -emailAddress
 * @returns -return mailAddress.
 */
    mailto(emailAddress: string):string {
        return "mailto:" + emailAddress + "?subject=" + this.Matterdata?.SELECTEDDESCRIPTION
    }

    /**
     * Redirect to matter-details widnow of particular matter. If open in new tab is true then it will open in new tab of browser.
    */
    hyperlinkData():void {
        this.Hyperlinkservice.selectHyperLink(this.Matterdata, '', this.HyperlinkInformation?.SHORTNAME);
    }

    /**
     *  Using this function go to the approximate users address.
     * @param Address -for getting users Address.
     *  */
    showMap(Address: string): void {
        var url = "https://maps.google.com/?q=" + Address;
        window.open(url);
    }

}
