<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="main-list">
  <div class="d-flex bankfeed-block flex-wrap">
    <mat-card *ngFor="let data of AccountList ; let i = index">
      <mat-card-header class="d-flex bankfeed-wrap">
        <mat-card-title>
            <form [formGroup]="authAccountName" fxLayout="row" *ngIf="data?.ShowAccountNameTextField">
                <mat-form-field appearance="outline" class="pr-4 name-input-5">
                  <mat-label>AccountName</mat-label>
                  <input matInput placeholder="Note" formControlName="name">
                </mat-form-field>
              
                <div class="main-icon-div">
                  <mat-icon class="done-icon" (click)="getAccountData(data,'updateaccountname')">done</mat-icon>
                  <mat-icon class="close-icon" (click)="closeAccountName(data)">close</mat-icon>
                </div>
              </form>
            <h1 class="accountName" *ngIf="!data?.ShowAccountNameTextField"><p [ngClass]="(COLOR=='custom-link-hyperlink') || (COLOR=='custom-link-hyperlink-withdarkmode') ? 'custom-link-hyperlink11' : 'COLOR'">{{(data?.ACCOUNTNAME && data?.ACCOUNTNAME !='') ? data?.ACCOUNTNAME : '-'}}</p> </h1>
        </mat-card-title>
              <mat-card-subtitle>
                <p class="mb-0">{{data?.BSB}} - {{data?.ACCOUNTNUMBER}}</p>
                <p>{{data?.ACCOUNTHOLDER}}</p>
                <p class="size" *ngIf="data?.LINKEDACCOUNTNUMBER && data?.LINKEDACCOUNTNAME">Linked to: {{data?.LINKEDACCOUNTCLASS}} - {{data?.LINKEDACCOUNTNUMBER}} {{data?.LINKEDACCOUNTNAME}}</p>
                <!-- <p *ngIf="data?.LINKEDACCOUNTNUMBER == '' || data?.LINKEDACCOUNTNUMBER == undefined || data?.LINKEDACCOUNTNUMBER == null">Linked to: <a  style="cursor: pointer;" [routerLink]="'/chart-account'">Link Account</a></p>  -->
              
                <div fxLayout="row" class="auth-btn form_field_div margin" *ngIf="data?.LINKEDACCOUNTNUMBER == '' || data?.LINKEDACCOUNTNUMBER == undefined || data?.LINKEDACCOUNTNUMBER == null">
                  <div >
                    <div fxFlex="35" style="margin-top: 12px;">Linked to: </div>        
                      <mat-form-field fxFlex="65" appearance="outline" class="pr-4 bank-input">
                          <input matInput [(ngModel)]="data.ShowAccountName">
                          <mat-icon matSuffix>
                              <button id="bank" class="matternews menu-disabled" class="matternews"
                              (click)="BankingDialogOpen('BANK ACCOUNT',data)">
                                  <img class="ipad-icon" src="assets/icons/web_app/icon_accounts_small_d.ico">
                              </button>
                          </mat-icon>
                      </mat-form-field>
                    </div>
                    <div>
                        <div>
                            <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="linktAccount(data)">Link Account</button>
                        </div>
                        
                    </div>
                </div>
              </mat-card-subtitle>
          <div class="d-flex">
              <div class="bank-logo">
                  <!-- <img class="verified-logo" src="assets/icons/web_app/microsoft.png"> -->
                <!-- <mat-icon> -->
                    <img src="assets/icons/bank-feed-images/{{data?.BANKIMAGE}}">
                <!-- </mat-icon> -->
                
              </div>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="getAccountData(data,'disconnect')">
                    <span>Unlink account</span>
                  </button>
                  <button mat-menu-item (click)="getAccountData(data,'deleteaccount')">
                    <span>Delete account</span>
                  </button>
                  <button mat-menu-item (click)="getAccountName(data)">
                    <span>Rename account</span>
                  </button>
                  <button mat-menu-item (click)="gotoBankRecosilationwindow(data)">
                      <span>Bank Reconciliation</span>
                    </button>
                     <button mat-menu-item (click)="connectAnatherAccount('addbankaccount',data)">
                        <span>Connect another account for this bank</span>
                      </button>
                </mat-menu>
          </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
          <br>
          <div fxLayout="row" style="height: 40px;">
              <button *ngIf="data?.unprocessedtransactioncountCount != 0" fxFlex="40" class="mat-button mat-raised-button mat-primary mat-accent" mat-button (click)="gotoAccount(data)">Reconcile {{data?.unprocessedtransactioncountCount}} items</button>
              <p style="margin-top: 5px;" *ngIf="data?.unprocessedtransactioncountCount == 0" fxFlex="80" [ngClass]="(COLOR=='custom-link-hyperlink') || (COLOR=='custom-link-hyperlink-withdarkmode') ? 'custom-link-hyperlink11' : 'COLOR'"> All items have been processed </p>
              <!-- <p fxFlex="40" style="margin-left: 20px;">Bank Balance </p>
              <p fxFlex="20" style="margin-left: 10px;" class="balance">{{data?.BALANCE}}</p> -->
          </div>
        <mat-list>
          <mat-list-item>
            <br>
            <div style="margin-left: 15px;">
              <mat-card-header>
                <mat-card-title>Bank Balance</mat-card-title>
              </mat-card-header>
              <div class="d-flex gap-5">
                <mat-card-content>
                  <h1 mat-headline class="number ">{{data?.BALANCE}}</h1>
                </mat-card-content>
                <mat-card-content>
                  <p class="mb-0 line-height-1">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" fit="" height="12" width="17" preserveAspectRatio="xMidYMid meet" focusable="false" fill="#ff0000">
                      <path fill-rule="evenodd" d="M1.22 5.222a.75.75 0 011.06 0L7 9.942l3.768-3.769a.75.75 0 011.113.058 20.908 20.908 0 013.813 7.254l1.574-2.727a.75.75 0 011.3.75l-2.475 4.286a.75.75 0 01-1.025.275l-4.287-2.475a.75.75 0 01.75-1.3l2.71 1.565a19.422 19.422 0 00-3.013-6.024L7.53 11.533a.75.75 0 01-1.06 0l-5.25-5.25a.75.75 0 010-1.06z" clip-rule="evenodd"></path>
                  </svg> -->
                  <svg *ngIf="data?.PERCENTCHANGE <0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit="" height="20" width="20" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path style="color: red;" fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-.53 14.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V8.25a.75.75 0 00-1.5 0v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clip-rule="evenodd"></path>
                  </svg>
                  <svg *ngIf="data?.PERCENTCHANGE >0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit="" height="20" width="20" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path style="color: #43a047;" fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd"></path>
                  </svg>
                  <svg *ngIf="data?.PERCENTCHANGE == 0"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit="" height="20" width="20" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path style="color: #43a047;display:none" fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd"></path>
                  </svg> 
                </p>
                <span class="mt-2" [ngStyle]="{'color': data?.PERCENTCHANGE > 0 ? 'green' : (data?.PERCENTCHANGE === 0) ? '' : 'red'}">
                  {{ data?.PERCENTCHANGE }}%  
                  <span>
                    {{ (data?.PERCENTCHANGE > 0) ? 'increase' : (data?.PERCENTCHANGE === 0) ? 'no change' : 'decrease' }}
                  </span>
                </span>
                </mat-card-content>
              </div>
            </div>
            <div style="height: 230px;">
              <ng-container *ngIf="(data && data?.series[0] && data?.series[0].data[0] && data?.series[0].data[0].length != 0)">
                <apx-chart
                  [series]="data?.series"
                  [chart]="data?.chart"
                  [xaxis]="data?.xaxis"
                  [stroke]="data?.stroke"
                  [tooltip]="data?.tooltip"
                  [dataLabels]="data?.dataLabels"
                  [fill]="data?.fill"
                  [yaxis]="data?.yaxis"
                  [title]="data?.title"
                  [ngClass]="{ 'hide-toolbar': hideToolbar }"
                ></apx-chart>
                <svg id="chart-text">
                  <text x="50%" y="11" [ngClass]="(currentUser && currentUser?.THEMECOLOURToggle == true) ? 'whitecolorrr' : 'darkcolorrr'" >Last 30 days</text>
                </svg>
              </ng-container>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="AccountList.length == 0 && !isLoadingResults" class="center-div">
    <div class="align-center">
      <h1>Add Bank Feed</h1><br>
      <h1>You have not yet added a bank feed to the system. Click the "Add Bank Feed" button and follow the instructions to authorised a bank for bank feeds.</h1><br>
      <h1>If you have recently added a bank feed that is not appearing yet, please wait 15 minutes and then refresh the page.</h1>
    </div>
  </div>
</div>