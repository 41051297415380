<div cdkDrag class="popup_main_div recipt_doc_main">
    <h2 cdkDragHandle mat-dialog-title>{{title}}
        <button class="btn" mat-icon-button [mat-dialog-close]="true" aria-label="Close dialog" *ngIf="dialogName == 'AddTrustWithdrawal';">
            <mat-icon>close</mat-icon>
        </button>

        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog" *ngIf="dialogName == '';">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <div fxLayout="column" fxFlex="1 0 auto" name="form" class="TemplatePopup">
                <div class="pr-4" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div fxFlex="70" class="middle" fxLayoutAlign="start start"><span>The {{title}} has been produced.</span></div>
                    <!-- <div fxFlex="70" class="middle" fxLayoutAlign="start start"><span>The Trust to Office <b>{{sendToAPI}}</b> has been produced</span></div> -->
                    <a fxFlex="30" href="{{base_url}}{{PDFURL}}" target="_blank" [mat-dialog-close]="true">
                        <button mat-raised-button color="accent" class="mat-accent" [disabled]="(!PDFURL)?true:false">Click here to view it</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial *ngIf="dialogName == 'AddTrustWithdrawal';">Cancel</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial *ngIf="dialogName == '';">Cancel</button>
    </mat-dialog-actions>
</div>