import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private broadcastChannel: BroadcastChannel;
  private eventSubject = new Subject<any>();

  constructor() {

    this.broadcastChannel = new BroadcastChannel('websocket_channel');

    this.broadcastChannel.onmessage = (message) => {
      this.eventSubject.next(message.data);
    };

    window.addEventListener('storage', (event) => {
      if (event.key && event.key.startsWith('websocket_event_')) {
        const data = event.newValue ? JSON.parse(event.newValue) : null;
        if(data) {
          // const details = JSON.parse(data);
          this.eventSubject.next(data);
          this.broadcastChannel.postMessage(data);
        }
      }
    });
  }

  /**
   * This function is used to get the event subject data value.
   */
  getEventSubject() {
    return this.eventSubject.asObservable();
  }
}
