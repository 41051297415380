<mat-toolbar>
    <div class="footer_text">©{{currentYear}} Silqware Pty Ltd. All Rights
        Reserved</div>
    <a class="footer_text_version-P" [routerLink]="'privacy-policy'"
        target="_blank">Privacy Policy</a>
    <a class="footer_text_version-E"
        [routerLink]="'/end-user-licence-agreement'" target="_blank">End User
        Licence Agreement</a>
    <div class="footer_text_version"> Version {{VERSION}}</div>
</mat-toolbar>
