import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { distinctUntilChanged, pairwise } from "rxjs/operators";
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-estimate-dilog',
  templateUrl: './estimate-dilog.component.html',
  styleUrls: ['./estimate-dilog.component.scss']
})
export class EstimateDilogComponent implements OnInit, OnDestroy {
  errorWarningData: any = { "Error": [], 'Warning': [] };
  estimateform: FormGroup;
  isLoadingResults: boolean = false;
  dialogTitle: string;
  action: string;
  cuurentmatter: any;
  isspiner: boolean = false;
  isreadonly: boolean = false;
  FormAction: string;
  EstimateGuid: any;
  MatterGuid: string;
  userList: any;
  public PRICEINCGSTVAL: any;
  public PRICEVAL: any;
  ActivityList: any = [];
  EstimateData: any = [];
  filteredOptions: Observable<string[]>;
  Serivcelist: any;
  selectedITEMTYPE:any = 'Time Entry'
  lookuptype:any;
  filteredOptionsActivity : Observable<string[]>;
  LookupsListActivity: any;
  RATEIDValue:any
  RATEIData:any
  optionList: any = [
    { 'ACTIVITYID': 'X', 'DESCRIPTION': 'hh:mm' },
    { 'ACTIVITYID': 'H', 'DESCRIPTION': 'Hours' },
    { 'ACTIVITYID': 'M', 'DESCRIPTION': 'Minutes' },
    { 'ACTIVITYID': 'D', 'DESCRIPTION': 'Days' },
    { 'ACTIVITYID': 'U', 'DESCRIPTION': 'Units' },
    { 'ACTIVITYID': 'F', 'DESCRIPTION': 'Fixed' }
  ];
  calculateData: any = {
    QuantityType: '', Quantity: '', FeeEarner: ''
  };
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  AllowEstimateRange: boolean;
  MAXPRICEINCGSTVAL: any;
  MAXPRICEVAL: any;
  windowNameId:any;
  ITEMTYPEValue:any;
  isdisable: boolean =true
  defaulMinMaxtvalue: any;
  previousMinQty:any='X';
  previousMaxQty:any='X';
  currentLoginData: any;
  chnageType: string = '';
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub: Subscription;
  sub1: Subscription;
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<EstimateDilogComponent>,
    private _formBuilder: FormBuilder,
    private behaviorService: BehaviorService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public _matDialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    private Timersservice: TimersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService
  ) {
    localStorage.setItem('istrackid', 'TimeBillingEstimateDialogComponent');
    this.AllowEstimateRange = JSON.parse(localStorage.getItem('currentUser')).ALLOWESTIMATERANGE;
    this.action = data.action;
    if (this.action === 'new') {
      this.dialogTitle = 'New Estimate';
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Estimate';
    } else {
      this.dialogTitle = 'Duplicate Estimate';
    }
    this.behaviorService.estimatelegalData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.EstimateData = result;
      }
    });
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.cuurentmatter = result;
    //   }
    // });
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
       
       this.cuurentmatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
       if (this.cuurentmatter) {
       } else {
         if (result) {
           this.cuurentmatter = result;
           localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.cuurentmatter))
         }
       }
     });
     
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'TimeBillingEstimateDialogComponent' && result['click'] && (result['data']['matter-estimate-item'] || result['data']['lookup'])) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'TimeBillingEstimateDialogComponent' && result['click'] && result['data']['matter-estimate-item']) {
        this.EstimateSave();
      }

    });

    this.behaviorService.defultPreferencesData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if(this.action === 'new'){
      if (result!=null) {
        this.defaulMinMaxtvalue=result.DefaultQuantityType
        this.previousMinQty = this.defaulMinMaxtvalue;
        this.previousMaxQty = this.defaulMinMaxtvalue;
       // this.currentLoginData = JSON.parse(localStorage.getItem('currentUser')); 
      }else{
        this.currentLoginData = JSON.parse(localStorage.getItem('currentUser'));
        this.defaulMinMaxtvalue=this.currentLoginData.DefaultQuantityType
        this.previousMinQty = this.defaulMinMaxtvalue;
        this.previousMaxQty = this.defaulMinMaxtvalue; 
      }
    }
    });

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.estimateform = this._formBuilder.group({
      FEEEARNER: [''],
      SERVICE: [''],
      MINQUATITY: [''],
      MINTYPE: [this.defaulMinMaxtvalue],
      MINPRICE: ['', {updateOn: 'blur'}],
      MININCGST: ['', {updateOn: 'blur'}],
      MAXQUATITY: [''],
      MAXTYPE: [this.defaulMinMaxtvalue],
      MAXPRICE: ['', {updateOn: 'blur'}],
      MAXINCGST: ['', {updateOn: 'blur'}],
      ITEMTYPE:[''],
      QUANTITYTYPE:[''],
      FEETYPE:[''],
      RATEID:['']
    });

    this.sub = this.estimateform.controls.MAXTYPE.valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      pairwise() // gets a pair of old and new value
    ).subscribe(([oldValue, newValue])=>{
      if(oldValue == null) {
        this.previousMaxQty = this.defaulMinMaxtvalue;
      } else {
        this.previousMaxQty = oldValue;
      }
    })

    this.sub1 = this.estimateform.controls.MINTYPE.valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      pairwise() // gets a pair of old and new value
    ).subscribe(([oldValue, newValue])=>{
      if(oldValue == null) {
        this.previousMinQty = this.defaulMinMaxtvalue;
      } else {
        this.previousMinQty = oldValue;
      }
    })

    if (this.action == 'edit' || this.action == 'duplicate') {
      this.EditPopUpOPen();
    }
    this.ActivityList = this.optionList;
    let userType = JSON.parse(localStorage.getItem('currentUser'));
    this.estimateform.controls['ITEMTYPE'].setValue("Time Entry");
    if(this.estimateform.controls['ITEMTYPE'].value == 'Time Entry'){
      
      this.TypeChange(this.estimateform.controls['ITEMTYPE'],'start')
    }
    if (userType) {
      this.estimateform.controls['FEEEARNER'].setValue((userType.WORKINGONBEHALFOFGUID) ? userType.WORKINGONBEHALFOFGUID : userType.UserId);
    }
    this.estimateform.controls['MINQUATITY'].setValue(0);
    this.estimateform.controls['MAXQUATITY'].setValue(0);
    let LookupsList = JSON.parse(localStorage.getItem('lookups_list'));
    if (LookupsList && LookupsList != null) {
      this.Serivcelist = LookupsList;
      this.filteredOptions = this.f.SERVICE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.isLoadingResults = false;
    } else {
      this.isLoadingResults = true;
      this.Timersservice.GetLookupsData({Action:'getdata',Filters:{ LookupType: 'Time Entry' }}).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          this.Serivcelist = res.DATA.LOOKUPS;
          localStorage.setItem('lookups_list', JSON.stringify(res.DATA.LOOKUPS));
          this.filteredOptions = this.f.SERVICE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        } else if (res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        } else {
          this.Serivcelist = [];
        }
        this.isLoadingResults = false;
      }, err => {
        this.toastr.error(err);
      });
    }
    this.calculateData.QuantityType = 'H';
    this.isLoadingResults = true;
    let userList = JSON.parse(localStorage.getItem('users_list'));
    if (userList && userList != null) {
      this.userList = this.Timersservice.removeUSerWithoutId(userList);
      this.isLoadingResults = false;
    } else {
      this.Timersservice.GetUsers({}).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          this.userList = this.Timersservice.removeUSerWithoutId(res.DATA.USERS);
          localStorage.setItem('users_list', JSON.stringify(res.DATA.USERS));
        } else if (res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        } else {
          this.userList = [];
        }
        this.isLoadingResults = false;
      }, err => {
        this.toastr.error(err);
      });
    }
    if (this.action === 'new') {
    this.getDefaultData();
    }
  }

  /**
   * This function is used to destroy the session data
   */
  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }


  /**
   * This function is used to filter the data
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.Serivcelist.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)));
  }

  /**
   * this function is used to change the amount
   */
  amountChange(element, field){
    const numbers = this.estimateform.controls[field].value;
    if(numbers) {
      const numericValue = parseFloat(numbers.replace(/,/g, '')); // Remove commas and convert to a number
      const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
      element.target.value = formattedNumber;
    }
  }

  // onInputKeyDown(event: KeyboardEvent) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //   }
  // }

  /**
   * This function is used to calculate the PE
   */
  calcPE(chnageType: any = '') {
    if (chnageType == 'max') {
      this.MAXPRICEINCGSTVAL = Number(this.f.MAXPRICE.value * 1.1).toFixed(2);
    } else {
      this.PRICEINCGSTVAL = Number(this.f.MINPRICE.value * 1.1).toFixed(2);
    }
  }

  /**
   * This function is used to calculate the PI
   */
  calcPI(chnageType: any = '') {
    if (chnageType == 'max') {
      this.MAXPRICEVAL = Number(this.f.MAXINCGST.value / 1.1).toFixed(2);

    } else {
      this.PRICEVAL = Number(this.f.MININCGST.value / 1.1).toFixed(2);
    }
  }
  //Return All Form Control
  get f() {
    return this.estimateform.controls;
  }
  //FEEEARNER

  /**
   * This function is used to change the matter
   */
  matterChange(key: any, event: any, chnageType: any = '') {
    this.calculateData.FeeEarner = this.f.FEEEARNER.value;
    this.chnageType = chnageType;
    if (chnageType == 'max') {
      this.calculateData.Quantity = this.f.MAXQUATITY.value;
      event = this.f.MAXTYPE.value;
    } else {
      this.calculateData.Quantity = this.f.MINQUATITY.value;
      event = this.f.MINTYPE.value;
    }
    // if (key === 'QuantityType') {
    switch (event) {
      case 'X': {
        this.calculateData.QuantityType = 'X';
        break;
      }
      case 'H': {
        this.calculateData.QuantityType = 'H';
        break;
      }
      case 'M': {
        this.calculateData.QuantityType = 'M';
        break;
      }
      case 'D': {
        this.calculateData.QuantityType = 'D';
        break;
      }
      case 'U': {
        this.calculateData.QuantityType = 'U';
        break;
      }
      case 'F': {
        this.calculateData.QuantityType = 'F';
        break;
      }
      default: {
        this.calculateData.QuantityType = 'F';
        break;
      }
    }
    // }
    this.calculateData.MATTERGUID = this.cuurentmatter.MATTERGUID;
    this.calculateData.rateid = this.estimateform.controls['RATEID'].value
    if (this.calculateData.Quantity != '' && this.calculateData.QuantityType != '') {
      this.isLoadingResults = true;
      this.Timersservice.CalcWorkItemCharge(this.calculateData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let CalcWorkItemCharge = response.DATA;
          if (chnageType == 'max') {
            this.estimateform.controls['MAXPRICE'].setValue(CalcWorkItemCharge.PRICE);
            this.estimateform.controls['MAXINCGST'].setValue(CalcWorkItemCharge.PRICEINCGST);
          } else {
            this.estimateform.controls['MINPRICE'].setValue(CalcWorkItemCharge.PRICE);
            this.estimateform.controls['MININCGST'].setValue(CalcWorkItemCharge.PRICEINCGST);
          }

          this.isLoadingResults = false;
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }, err => {
        this.isLoadingResults = false;
        this.toastr.error(err);
      });
    }
  }

/**
 * This function is used to open the edit popup
 */
  EditPopUpOPen() {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({"ACTION": "GetData",FILTERS:{ESTIMATEITEMGUID: this.EstimateData.ESTIMATEITEMGUID }  }, 'matter-estimate-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result.CODE == 200 && result.STATUS == "success") {
        this.estimateform.controls['ITEMTYPE'].setValue(result.DATA.RECORDS[0].ITEMTYPE);
        this.estimateform.controls['RATEID'].setValue((result.DATA.RECORDS[0].RATEID).toString());
         this.TypeChange(result.DATA.RECORDS[0],'')
        this.estimateform.controls['FEEEARNER'].setValue(result.DATA.RECORDS[0].FEEEARNER);
        this.estimateform.controls['FEETYPE'].setValue(result.DATA.RECORDS[0].FEETYPE);
        this.estimateform.controls['SERVICE'].setValue(result.DATA.RECORDS[0].SERVICE);
        this.estimateform.controls['MINQUATITY'].setValue(result.DATA.RECORDS[0].QUANTITYFROM);
        this.estimateform.controls['MINTYPE'].setValue(result.DATA.RECORDS[0].QUANTITYTYPEFROM);
        this.estimateform.controls['MINPRICE'].setValue(result.DATA.RECORDS[0].PRICEFROM);
        this.estimateform.controls['MININCGST'].setValue(result.DATA.RECORDS[0].PRICEINCGSTFROM);

        this.estimateform.controls['MAXQUATITY'].setValue(result.DATA.RECORDS[0].QUANTITYTO);
        this.estimateform.controls['MAXTYPE'].setValue(result.DATA.RECORDS[0].QUANTITYTYPETO);
        this.estimateform.controls['MAXPRICE'].setValue(result.DATA.RECORDS[0].PRICETO);
        this.estimateform.controls['MAXINCGST'].setValue(result.DATA.RECORDS[0].PRICEINCGSTTO);

        this.isLoadingResults = false;
      }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }
  
  /**
   * This function is used to change the service
   */
  ServiceChnage(value: any) {
    this.estimateform.controls['SERVICE'].setValue(value);
  }

  /**
   * This function is used to Estimate save
   */
  EstimateSave() {
    if (this.action === 'new' || this.action === 'duplicate') {
      this.FormAction = 'insert';
      this.EstimateGuid = "";
    } else {
      this.FormAction = 'update';
      this.EstimateGuid = this.EstimateData.ESTIMATEITEMGUID
    }
    let data = {
      ESTIMATEITEMGUID: this.EstimateGuid,
      MATTERGUID: this.cuurentmatter.MATTERGUID,
      FEEEARNER: this.f.FEEEARNER.value,
      SERVICE: this.f.SERVICE.value,
      QUANTITYFROM: this.f.MINQUATITY.value,
      QUANTITYTYPEFROM: this.f.MINTYPE.value,
      PRICEFROM: this.f.MINPRICE.value,
      PRICEINCGSTFROM: Number(this.f.MININCGST.value),
      QUANTITYTO: this.f.MAXQUATITY.value,
      QUANTITYTYPETO: this.f.MAXTYPE.value,
      PRICETO: this.f.MAXPRICE.value,
      PRICEINCGSTTO: this.f.MAXINCGST.value,
      ITEMTYPE:this.f.ITEMTYPE.value,
      FEETYPE:this.f.QUANTITYTYPE.value,
      RATEID:this.f.RATEID.value
    }
    this.isspiner = true;
    let finalData = { DATA: data, FormAction: this.FormAction, VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'matter-estimate-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });

  }

  /**
   * This function is used to get the default data
   */
getDefaultData(){
  let data = {
    ESTIMATEITEMGUID:'' ,
    MATTERGUID:'' ,
    FEEEARNER:'',
    SERVICE: '',
    QUANTITYFROM:'' ,
    QUANTITYTYPEFROM:'' ,
    PRICEFROM:'' ,
    PRICEINCGSTFROM: '',
    QUANTITYTO:'' ,
    QUANTITYTYPETO:'' ,
    PRICETO:'' ,
    PRICEINCGSTTO:'' ,
    ITEMTYPE:'',
    FEETYPE:'',
    RATEID:''
  }
  let finalData = { DATA: data,  Action:'Default', VALIDATEONLY: true }
  this._mainAPiServiceService.getSetData(finalData, 'matter-estimate-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response =>   {
    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
     let defaultValues=response.DATA.DEFAULTVALUES
     if(defaultValues){
this.estimateform.controls['ITEMTYPE'].setValue(defaultValues.ITEMTYPE);
     this.estimateform.controls['RATEID'].setValue((defaultValues.RATEID).toString());
     // this.TypeChange(result.DATA.ESTIMATEITEMS[0],'')
     this.estimateform.controls['FEEEARNER'].setValue(defaultValues.FEEEARNER);
     this.estimateform.controls['FEETYPE'].setValue(defaultValues.FEETYPE);
     this.estimateform.controls['SERVICE'].setValue(defaultValues.SERVICE);
     this.estimateform.controls['MINQUATITY'].setValue(defaultValues.QUANTITYFROM);
     this.estimateform.controls['MINTYPE'].setValue(defaultValues.QUANTITYTYPEFROM);
     this.estimateform.controls['MINPRICE'].setValue(defaultValues.PRICEFROM);
     this.estimateform.controls['MININCGST'].setValue(defaultValues.PRICEINCGSTFROM);
     this.estimateform.controls['MAXQUATITY'].setValue(defaultValues.QUANTITYTO);
     this.estimateform.controls['MAXTYPE'].setValue(defaultValues.QUANTITYTYPETO);
     this.estimateform.controls['MAXPRICE'].setValue(defaultValues.PRICETO);
     this.estimateform.controls['MAXINCGST'].setValue(defaultValues.PRICEINCGSTTO);
     }
    }
  }, err => {
    this.isspiner = false;
    this.toastr.error(err);
  });
}

/**
 * This function is used to check the validation data value
 */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }

    // });
    // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.EstimateSaveData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.EstimateSaveData(details);

    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details).pipe(takeUntil(this._unsubscribeAll$))
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.EstimateSaveData(details);
        }
      }
    });
  }

  /**
   * This function is used to save the estimate data
   */
  EstimateSaveData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'matter-estimate-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        $('#Legal_Details').click()
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }
  

  /**
   * This function is used to change the type
   */
  TypeChange(EventVal: any,data:any) {
    
    if(EventVal.ITEMTYPE){
      EventVal.value = EventVal.ITEMTYPE
    }else{
    }
      if(EventVal.value == 'Time Entry'|| EventVal.value == 'Matter Expense' || EventVal.value == 'Search'){
              if((EventVal.value == 'Time Entry' && data) ||  EventVal.value == 'Time Entry'){
                    this.RATEIDValue = true
                    this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'Rates' }}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
                      if (responses.CODE === 200 && responses.STATUS === 'success') {
                        localStorage.setItem('Rates_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                        this.RATEIData = responses.DATA.LOOKUPS;  
                      }
                    }); 
                }
        this.ITEMTYPEValue = false
      }else if(EventVal.value == 'Activity' || EventVal.value == 'Sundry' ){
        this.ITEMTYPEValue = true
        this.RATEIDValue = false
      };

      if( EventVal.value == 'Matter Expense' || EventVal.value == 'Search'){
        this.estimateform.controls['MINQUATITY'].setValue(0);
        this.estimateform.controls['MAXQUATITY'].setValue(0);
        this.estimateform.controls['MINPRICE'].setValue(0);
        this.estimateform.controls['MININCGST'].setValue(0);
        this.estimateform.controls['MAXPRICE'].setValue(0);
        this.estimateform.controls['MAXINCGST'].setValue(0)
        ;
        this.estimateform.controls['MINQUATITY'].disable()
        this.estimateform.controls['MAXQUATITY'].disable()
        this.estimateform.controls['MINTYPE'].disable()
        this.estimateform.controls['MAXTYPE'].disable()

      }else if(EventVal.value == 'Activity' ||EventVal.value == 'Sundry'  ){
        this.estimateform.controls['MINTYPE'].disable()
        this.estimateform.controls['MAXTYPE'].disable()
        this.estimateform.controls['MINQUATITY'].enable()
        this.estimateform.controls['MAXQUATITY'].enable()
      }else{
        this.estimateform.controls['MINQUATITY'].enable()
        this.estimateform.controls['MAXQUATITY'].enable()
        this.estimateform.controls['MINTYPE'].enable()
        this.estimateform.controls['MAXTYPE'].enable()
      };
      this.estimateform.controls['ITEMTYPE'].setValue(EventVal.value) 
      
    if(EventVal.value == 'Activity' ||EventVal.value == 'Sundry'  ){
      if(EventVal.value == 'Activity'){
        this.lookuptype = 'Activities'
        this.estimateform.controls['QUANTITYTYPE'].setValue('') 
      }else if(EventVal.value == 'Sundry'){
        this.lookuptype = 'Sundries'
        this.estimateform.controls['QUANTITYTYPE'].setValue('') 
      }
    
        if(EventVal.ITEMTYPE){
          this.estimateform.controls['QUANTITYTYPE'].setValue(EventVal.FEETYPE);
         let postData:any = { "Action": "GetData",
          "Filters": {
            "ACTIVITYTYPE": this.lookuptype?this.lookuptype:''}
          }
          this.Timersservice.GetActivity(postData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
              this.LookupsListActivity = res.DATA.ACTIVITIES;
              this.filteredOptionsActivity = this.f.QUANTITYTYPE.valueChanges.pipe(startWith(''), map(value => this._filter_data(value)));
            } else if (res.MESSAGE == 'Not logged in') {
              this.dialogRef.close(false);
            } else {
              this.LookupsListActivity = [];
            }
            this.isLoadingResults = false;
          }, err => {
            this.toastr.error(err);
          })
        }else{
          let postData:any = { "Action": "GetData",
          "Filters": {
            "ACTIVITYTYPE": this.lookuptype?this.lookuptype:''}
          }
        this.Timersservice.GetActivity(postData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
          if (res.CODE == 200 && res.STATUS == "success") {
            this.LookupsListActivity = res.DATA.ACTIVITIES;
            this.filteredOptionsActivity = this.f.QUANTITYTYPE.valueChanges.pipe(startWith(''), map(value => this._filter_data(value)));
          } else if (res.MESSAGE == 'Not logged in') {
            this.dialogRef.close(false);
          } else {
            this.LookupsListActivity = [];
          }
          this.isLoadingResults = false;
        }, err => {
          this.toastr.error(err);
        })
        }
    };
  };

  /**
   * This function is used to activity change
   */
  activityChange() {
    const indexData = this.LookupsListActivity.findIndex(activity => (activity.ACTIVITYID).toUpperCase() == (this.f.QUANTITYTYPE.value).toUpperCase());
    if (this.LookupsListActivity[indexData]) {
      this.estimateform.controls['QUANTITYTYPE'].setValue(this.LookupsListActivity[indexData].ACTIVITYID);
      this.calculateData.FeeType = this.estimateform.controls['QUANTITYTYPE']
      this.calculateData.rateid = this.estimateform.controls['RATEID'].value
    }
  };

/**
 * This function is used to change the activity
 */
  ActivityChnage(value: any) {    
    this.estimateform.controls['FEETYPE'].setValue(value);
  };

  /**
   * This function is used to filter the data
   */
  private _filter_data(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.LookupsListActivity.filter(option => (option.ACTIVITYID.toLowerCase().includes(filterValue) || option.DESCRIPTION.toLowerCase().includes(filterValue)));
  };

  /**\
   * This function is used to set the ret id
   */
  reateId(event){
    this.estimateform.controls['RATEID'].setValue(this.f.RATEID.value.toString());
    this.calculateData.rateid = this.estimateform.controls['RATEID'].value;
    if (this.calculateData.Quantity != '' && this.calculateData.QuantityType != '' && this.chnageType != '') {
      this.isLoadingResults = true;
      this.Timersservice.CalcWorkItemCharge(this.calculateData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let CalcWorkItemCharge = response.DATA;
          if (this.chnageType == 'max') {
            this.estimateform.controls['MAXPRICE'].setValue(CalcWorkItemCharge.PRICE);
            this.estimateform.controls['MAXINCGST'].setValue(CalcWorkItemCharge.PRICEINCGST);
          } else {
            this.estimateform.controls['MINPRICE'].setValue(CalcWorkItemCharge.PRICE);
            this.estimateform.controls['MININCGST'].setValue(CalcWorkItemCharge.PRICEINCGST);
          }

          this.isLoadingResults = false;
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }, err => {
        this.isLoadingResults = false;
        this.toastr.error(err);
      });
    }
  }

  /**
   * This function is used to change the quantity type
   */
  quantityTypeChange(key: any, event: any, changeType: any = '') {
    let sendData = {}

      if(changeType == "max") {
        sendData = {
          FromQuantityType: this.previousMaxQty,
          toQuantityType: event,
          Quantity: this.f.MAXQUATITY.value,
          rateid: this.f.RATEID.value
        }
      } else {
        sendData = {
          FromQuantityType: this.previousMinQty,
          toQuantityType: event,
          Quantity: this.f.MINQUATITY.value,
          rateid: this.f.RATEID.value
        }
      }

      
      this.Timersservice.ChangeQuantityType(sendData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          if(changeType == "max") {
            this.estimateform.controls.MAXQUATITY.setValue(response.DATA.QUANTITY)
            this.matterChange('Quantity', event, "max");
          } else {
            this.estimateform.controls.MINQUATITY.setValue(response.DATA.QUANTITY)
            this.matterChange('Quantity', event);
          }
        } 
      }, err => {
        this.isLoadingResults = false;
      });


  }

}

