<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div configuer_field_main" cdkDrag>
    <div class="popup_title_div">
        <h2 cdkDragHandle mat-dialog-title>
            Configure Fields
            <mat-icon (click)="ondialogcloseClick()">close</mat-icon>
        </h2>
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="popup_body">
            <form class="p-24 mr-24 remove_left_right_padding" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                name="form">
                <div>
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Search</mat-label>
                        <!-- <input matInput formControlName="search"  placeholder="search poroperty" [(ngModel)]="filterName"> -->
                        <input [(ngModel)]="searchporoperty" matInput placeholder="search Property"
                            name="searchporoperty">
                        <mat-icon matSuffix class="disabled-text">search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="information">
                    <mat-label>Contact Information</mat-label>
                    <div class="checkbox">
                        <div class="add_popup_padding" *ngFor="let name of property | filterByName:searchporoperty">
                            <mat-checkbox value="{{ name.HIDDEN }}" [checked]="!name.HIDDEN" name='name.DESCRIPTION'
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="!name.HIDDEN" class="example-margin"
                                (change)="onChange($event,name.DESCRIPTION)"><span
                                    class="font_class">{{name.DESCRIPTION }}</span> </mat-checkbox>
                                    <!-- | lowercase -->
                        </div>
                    </div>
                </div>
            </form>
            <mat-divider vertical></mat-divider>
            <div class="form-errors-model p-24">
                <div class="example-container">
                    <h2>Selected Columns ({{checkboxdata }})</h2>
                    <div id="all" cdkDropList [cdkDropListData]="property" cdkDropListConnectedTo="even"
                        class="example-list popup_scroll_div" (cdkDropListDropped)="drop($event)"
                        [cdkDropListEnterPredicate]="noReturnPredicate">
                        <span *ngFor="let number of property">
                            <div class="example-box" *ngIf="!number.HIDDEN" [cdkDragData]="number" cdkDrag>
                                <mat-icon class="detail">more_vert</mat-icon>
                                <span class="font_class">
                                    <!-- | lowercase -->
                                    {{number.DESCRIPTION }}
                                </span>
                                <mat-icon class="close" (click)="remove(number.DESCRIPTION,$event)">close</mat-icon>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="clear left_popup_btn">
            <button class="cancel" mat-button matBadgeColor="accent" (click)="deleteall()">{{title}} </button>
        </div>
        <div class="right_popup_btn">
            <div class="right_btn_child">
                <button mat-raised-button color="accent" class="mat-accent" mat-button  cdkFocusInitial
                    (click)="ondialogSaveClick()">Save</button>
            </div>
            <div class="right_btn_child">
                <button class="cancel" mat-button [mat-dialog-close]="true" matBadgeColor="accent"
                    (click)="ondialogcloseClick()">Cancel</button>
            </div>
        </div>
    </mat-dialog-actions>
</div>