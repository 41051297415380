<div class=" one " fxLayout="column" [formGroup]="matterAddressForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Title Ref(s) - Separate with commas </mat-label>
            <input matInput formControlName="LOTSTITLE">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Lots(s)</mat-label>
        </div>
        <div fxFlex="25">
            <mat-label>Plan Type (s)</mat-label>
        </div>
        <div fxFlex="25">
            <mat-label>Plan No(s)</mat-label>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LOTS,'has-warning':errorWarningData.Warning?.LOTS}">
            <input matInput name="Lots" formControlName="LOTS">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE,'has-warning':errorWarningData.Warning?.PLANTYPE}">
            <input matInput name="PlanType" formControlName="PLANTYPE">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER,'has-warning':errorWarningData.Warning?.PLANNUMBER}">
            <input matInput name="PlanNo" formControlName="PLANNUMBER">
        </mat-form-field>
        <div fxFlex="25" class="Split-Title">
            <button mat-raised-button color="accent" class="mat-accent" (click)="SplitTitleRefs()"
                [disabled]="isspiner">
                <mat-spinner *ngIf="isspiner"></mat-spinner>Split Title Refs
            </button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LOT2,'has-warning':errorWarningData.Warning?.LOT2}">
            <input matInput name="Lots" formControlName="LOT2">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE2,'has-warning':errorWarningData.Warning?.PLANTYPE2}">
            <input matInput name="PlanType" formControlName="PLANTYPE2">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER2,'has-warning':errorWarningData.Warning?.PLANNUMBER2}">
            <input matInput name="PlanNo" formControlName="PLANNUMBER2">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LOT3,'has-warning':errorWarningData.Warning?.LOT3}">
            <input matInput name="Lots" formControlName="LOT3">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE3,'has-warning':errorWarningData.Warning?.PLANTYPE3}">
            <input matInput name="PlanType" formControlName="PLANTYPE3">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER3,'has-warning':errorWarningData.Warning?.PLANNUMBER3}">
            <input matInput name="PlanNo" formControlName="PLANNUMBER3">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LOT4,'has-warning':errorWarningData.Warning?.LOT4}">
            <input matInput name="Lots" formControlName="LOT4">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE4,'has-warning':errorWarningData.Warning?.PLANTYPE4}">
            <input matInput name="PlanType" formControlName="PLANTYPE4">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER4,'has-warning':errorWarningData.Warning?.PLANNUMBER4}">
            <input matInput name="PlanNo" formControlName="PLANNUMBER4">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LOT5,'has-warning':errorWarningData.Warning?.LOT5}">
            <input matInput name="Lots" formControlName="LOT5">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANTYPE5,'has-warning':errorWarningData.Warning?.PLANTYPE5}">
            <input matInput name="PlanType" formControlName="PLANTYPE5">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PLANNUMBER5,'has-warning':errorWarningData.Warning?.PLANNUMBER5}">
            <input matInput name="PlanNo" formControlName="PLANNUMBER5">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.LOTSECTION,'has-warning':errorWarningData.Warning?.LOTSECTION}">
            <mat-label>Section</mat-label>
            <input matInput name="Section" formControlName="LOTSECTION">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.BLOCK,'has-warning':errorWarningData.Warning?.BLOCK}">
            <mat-label>Block</mat-label>
            <input matInput name="Block" formControlName="BLOCK">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.STAGENUMBER,'has-warning':errorWarningData.Warning?.STAGENUMBER}">
            <mat-label>Stage No</mat-label>
            <input matInput name="StageNo" formControlName="STAGENUMBER">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.REDEVELOPMENTNUMBER,'has-warning':errorWarningData.Warning?.REDEVELOPMENTNUMBER}">
            <mat-label>Redevelopment No</mat-label>
            <input name="RedevelopmentNo" matInput formControlName="REDEVELOPMENTNUMBER">
        </mat-form-field>
    </div>
</div>