import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Output,
} from "@angular/core";

@Directive({
    selector: "[appFileDragNDrop]",
})
export class FileDragNDropDirective {
    //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
    @Output() private filesChangeEmiter: EventEmitter<File[]> =
        new EventEmitter();
    //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
    @HostBinding("style.background") private background = "#fff";
    @HostBinding("style.border") private borderStyle = "none";
    @HostBinding("style.border-color") private borderColor = "none";
    @HostBinding("style.border-radius") private borderRadius = "8px";

    constructor() { }

    @HostListener("dragover", ["$event"]) public onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "lightgray";
        this.borderColor = "#43a047";
        //this.borderColor = '#4d2184'; for purple
        this.borderStyle = "2px solid";
    }

    @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#fff";
        this.borderColor = "none";
        this.borderStyle = "none";
    }

    //@HostListener('dragstart', ['$event'])public onDragStart(evt){    evt.preventDefault();  }
    @HostListener("drop", ["$event"]) public onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#fff";
        this.borderColor = "none";
        this.borderStyle = "none";
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            Array.from(files).forEach((file: any) => {
                // let ext = file.name.split('.')[file.name.split('.').length - 1];
                // file.ext = ext;

                this.filesChangeEmiter.emit(file);
            });
        }
    }
}
