import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trust-money-recipt',
  templateUrl: './trust-money-recipt.component.html',
  styleUrls: ['./trust-money-recipt.component.scss']
})
export class TrustMoneyReciptComponent implements OnInit {
  sendToAPI: string;
  PDFURL: any;
  base_url: string;
  title: any;
  dialogName: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this.base_url = environment.ReportUrl;
    this.sendToAPI = _data.action;
    this.PDFURL = _data.PDFURL;
    this.title = _data.reportTitle;
    if(_data.dialogName) {
      this.dialogName = _data.dialogName;
    }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
