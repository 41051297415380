<div class=" one " fxLayout="column" [formGroup]="WillEstateForm">

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-checkbox fxFlex="60" formControlName="LETTERSOFENTITLEMENT" matTooltip="{{(toolTipList)?toolTipList.LETTERSOFENTITLEMENT?.HOVER:''}}"
        [matTooltipPosition]="toolTipPostion">Letters Of Administration?</mat-checkbox>   
        <div fxFlex="40"  *ngIf="testBool && toolTipList.LETTERSOFENTITLEMENT" class="tooltip-wrap tooltip-wrap-single" ><input id="myInput" style="font-size: 12px;" readonly disabled value="{{(toolTipList)?toolTipList.LETTERSOFENTITLEMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LETTERSOFENTITLEMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.AGEATDEATH,'has-warning':errorWarningData.Warning?.AGEATDEATH}">
            <mat-label>Age At Death</mat-label>
            <input matInput formControlName="AGEATDEATH" matTooltip="{{(toolTipList)?toolTipList.AGEATDEATH?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.AGEATDEATH" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.AGEATDEATH.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AGEATDEATH?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFDEATH,'has-warning':errorWarningData.Warning?.DATEOFDEATH}">
            <mat-label>Date of Death</mat-label>
            <input (dateInput)="DateofDeathPickerEvent('input', $event,'DATEOFDEATH')" formControlName="DATEOFDEATHTEXT" (dateChange)="CommanDatePickerEvent('change', $event,'DATEOFDEATH')" matInput name="DateofDeath" [matDatepicker]="DateofDeath"  matTooltip="{{(toolTipList)?toolTipList.DATEOFDEATH?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DateofDeath"></mat-datepicker-toggle>
            <mat-datepicker #DateofDeath></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEOFDEATH" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFDEATH.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFDEATH?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> 
        <mat-form-field appearance="outline" fxFlex="65" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NAMEONDEATHCERTIFICATE,'has-warning':errorWarningData.Warning?.NAMEONDEATHCERTIFICATE}">
            <mat-label>Name On Death Certificate</mat-label>
            <input matInput formControlName="NAMEONDEATHCERTIFICATE"  matTooltip="{{(toolTipList)?toolTipList.NAMEONDEATHCERTIFICATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.NAMEONDEATHCERTIFICATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NAMEONDEATHCERTIFICATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NAMEONDEATHCERTIFICATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLACEOFDEATH ,'has-warning':errorWarningData.Warning?.PLACEOFDEATH }">
            <mat-label>Place Of Death</mat-label>
            <textarea matInput formControlName="PLACEOFDEATH"  matTooltip="{{(toolTipList)?toolTipList.PLACEOFDEATH?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"></textarea>
             <div  *ngIf="testBool && toolTipList.PLACEOFDEATH" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLACEOFDEATH.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLACEOFDEATH?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div> 

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ADDRESSOFDECEASED,'has-warning':errorWarningData.Warning?.ADDRESSOFDECEASED}">
            <mat-label>Address Of Deceased (if different to Will)</mat-label>
            <textarea matInput formControlName="ADDRESSOFDECEASED"  matTooltip="{{(toolTipList)?toolTipList.ADDRESSOFDECEASED?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"></textarea>
             <div  *ngIf="testBool && toolTipList.ADDRESSOFDECEASED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ADDRESSOFDECEASED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDRESSOFDECEASED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"   [ngClass]="{'has-error':errorWarningData.Error?.TOTALASSETS,'has-warning':errorWarningData.Warning?.TOTALASSETS, 'readonly_apply': islegalEstate}">
            <mat-label>Total Assets</mat-label>
            <!-- currencyMask -->
            <input [value]="WillEstateForm.get('TOTALASSETS').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." style="text-align: right;" matInput formControlName="TOTALASSETS" [readonly]="islegalEstate" matTooltip="{{(toolTipList)?toolTipList.TOTALASSETS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" (focusout)="focusOutAssets()" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.TOTALASSETS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TOTALASSETS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALASSETS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"   [ngClass]="{'has-error':errorWarningData.Error?.TOTALLIABILITIES,'has-warning':errorWarningData.Warning?.TOTALLIABILITIES, 'readonly_apply': islegalEstate}">
            <mat-label>Total Liabilities</mat-label>
            <input [value]="WillEstateForm.get('TOTALLIABILITIES').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." style="text-align: right;" matInput formControlName="TOTALLIABILITIES" [readonly]="islegalEstate" matTooltip="{{(toolTipList)?toolTipList.TOTALLIABILITIES?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" (focusout)="focusOutAssets()" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.TOTALLIABILITIES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TOTALLIABILITIES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALLIABILITIES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTATENETVALUE,'has-warning':errorWarningData.Warning?.ESTATENETVALUE, 'readonly_apply': islegalEstate}">
            <mat-label>Net Value</mat-label>
            <input [value]="WillEstateForm.get('ESTATENETVALUE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" matInput formControlName="ESTATENETVALUE" [readonly]="islegalEstate"  matTooltip="{{(toolTipList)?toolTipList.ESTATENETVALUE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.ESTATENETVALUE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTATENETVALUE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTATENETVALUE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PROBATEGRANTNO,'has-warning':errorWarningData.Warning?.PROBATEGRANTNO}">
            <mat-label>Probate Grant No</mat-label>
            <input matInput formControlName="PROBATEGRANTNO"  matTooltip="{{(toolTipList)?toolTipList.PROBATEGRANTNO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.PROBATEGRANTNO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PROBATEGRANTNO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PROBATEGRANTNO?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="67" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PROBATEGRANTEDTO,'has-warning':errorWarningData.Warning?.PROBATEGRANTEDTO}">
            <mat-label> Probate Granted To</mat-label>
            <input matInput formControlName="PROBATEGRANTEDTO" matTooltip="{{(toolTipList)?toolTipList.PROBATEGRANTEDTO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.PROBATEGRANTEDTO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PROBATEGRANTEDTO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PROBATEGRANTEDTO?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFGRANTOFREP,'has-warning':errorWarningData.Warning?.DATEOFGRANTOFREP}">
            <mat-label>Date of Grant</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'DATEOFGRANTOFREP')" formControlName="DATEOFGRANTOFREPTEXT" (dateChange)="CommanDatePickerEvent('change', $event,'DATEOFGRANTOFREP')" matInput name="DateofGrant" [matDatepicker]="DateofGrant"  matTooltip="{{(toolTipList)?toolTipList.DATEOFGRANTOFREP?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DateofGrant"></mat-datepicker-toggle>
            <mat-datepicker #DateofGrant></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEOFGRANTOFREP" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFGRANTOFREP.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFGRANTOFREP?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="67" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BASISOFGRANT,'has-warning':errorWarningData.Warning?.BASISOFGRANT}">
            <mat-label>Basis Of Grant</mat-label>
            <input matInput formControlName="BASISOFGRANT"  matTooltip="{{(toolTipList)?toolTipList.BASISOFGRANT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.BASISOFGRANT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BASISOFGRANT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BASISOFGRANT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PUBLISHEDIN,'has-warning':errorWarningData.Warning?.PUBLISHEDIN}">
            <mat-label>Published In</mat-label>
            <input matInput formControlName="PUBLISHEDIN"  matTooltip="{{(toolTipList)?toolTipList.PUBLISHEDIN?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
             <div  *ngIf="testBool && toolTipList.PUBLISHEDIN" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLISHEDIN.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLISHEDIN?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PUBLISHEDDATE,'has-warning':errorWarningData.Warning?.PUBLISHEDDATE}">
            <mat-label>Published Date</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'PUBLISHEDDATE')" formControlName="PUBLISHEDDATETEXT" (dateChange)="CommanDatePickerEvent('change', $event,'PUBLISHEDDATE')" matInput name="PublishedDate" [matDatepicker]="PublishedDate" matTooltip="{{(toolTipList)?toolTipList.PUBLISHEDDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="PublishedDate"></mat-datepicker-toggle>
            <mat-datepicker #PublishedDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.PUBLISHEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLISHEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLISHEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFDISTRIBUTIONNOTICE,'has-warning':errorWarningData.Warning?.DATEOFDISTRIBUTIONNOTICE}">
            <mat-label> Date Of Distribution Notice</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'DATEOFDISTRIBUTIONNOTICE')" formControlName="DATEOFDISTRIBUTIONNOTICETEXT" (dateChange)="CommanDatePickerEvent('change', $event,'DATEOFDISTRIBUTIONNOTICE')" matInput name="DateOfDistribution"
                [matDatepicker]="DateOfDistribution"  matTooltip="{{(toolTipList)?toolTipList.DATEOFDISTRIBUTIONNOTICE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DateOfDistribution"></mat-datepicker-toggle>
            <mat-datepicker #DateOfDistribution></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEOFDISTRIBUTIONNOTICE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFDISTRIBUTIONNOTICE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFDISTRIBUTIONNOTICE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>




    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFESTATETAXRETURN,'has-warning':errorWarningData.Warning?.DATEOFESTATETAXRETURN}">
            <mat-label>Date of estate tax return</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'DATEOFESTATETAXRETURN')" formControlName="DATEOFESTATETAXRETURNTEXT" (dateChange)="CommanDatePickerEvent('change', $event,'DATEOFESTATETAXRETURN')" matInput name="PublishedDate1" [matDatepicker]="PublishedDate1" matTooltip="{{(toolTipList)?toolTipList.DATEOFESTATETAXRETURN?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="PublishedDate1"></mat-datepicker-toggle>
            <mat-datepicker #PublishedDate1></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATEOFESTATETAXRETURN" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFESTATETAXRETURN.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFESTATETAXRETURN?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTATETAXRETURNCOMPLETEDDATE,'has-warning':errorWarningData.Warning?.ESTATETAXRETURNCOMPLETEDDATE}">
            <mat-label>Estate tax return completed date</mat-label>
            <input (dateInput)="CommanDatePickerEvent('input', $event,'ESTATETAXRETURNCOMPLETEDDATE')" formControlName="ESTATETAXRETURNCOMPLETEDDATETEXT" (dateChange)="CommanDatePickerEvent('change', $event,'ESTATETAXRETURNCOMPLETEDDATE')" matInput name="PublishedDate2" [matDatepicker]="PublishedDate2" matTooltip="{{(toolTipList)?toolTipList.ESTATETAXRETURNCOMPLETEDDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="PublishedDate2"></mat-datepicker-toggle>
            <mat-datepicker #PublishedDate2></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ESTATETAXRETURNCOMPLETEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTATETAXRETURNCOMPLETEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTATETAXRETURNCOMPLETEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="67" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTATETAXRETURNSTATUS,'has-warning':errorWarningData.Warning?.ESTATETAXRETURNSTATUS}">
            <mat-label> Estate tax return status</mat-label>
            <input matInput formControlName="ESTATETAXRETURNSTATUS">
             <div  *ngIf="testBool && toolTipList.ESTATETAXRETURNSTATUS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTATETAXRETURNSTATUS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTATETAXRETURNSTATUS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>