import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DeliverableDailogComponent } from './deliverable-dailog/deliverable-dailog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MoveEntryComponent } from './move-entry/move-entry.component';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { NumberSeparatorModule } from '@directives/numberseparator/number-separator.module';
import { NumericDirective } from './time-entry-dialog/numericValidation.component';
import { PipesModule } from '@_pipes/pipes.module';
import { ResizableModule } from 'angular-resizable-element';
import { ResumeTimerComponent } from './resume-timer/resume-timer.component';
import { RouterModule, Routes } from '@angular/router';
import { TimeEntriesComponent } from './../time-entries/time-entries.component';
import { TimeEntryDialogComponent } from './time-entry-dialog/time-entry-dialog.component';
import { WriteOffTimeEntryComponent } from './write-off-time-entry/write-off-time-entry.component';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    // { path: '', component: TimeEntriesComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo: '/time-entries/full-time-entries', pathMatch: 'full' },
    {
        path: 'time-entries/full-time-entries', component: TimeEntriesComponent, data: {
            title: 'Time Entries'
        }, canActivate: [AuthGuard]
    },
    {
        path: 'time-entries/quick-time-entries', component: TimeEntriesComponent, data: {
            title: 'Quick Time Entries'
        }, canActivate: [AuthGuard]
    },
];
// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "right",
//   allowNegative: true,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$",
//   suffix: "",
//   thousands: ",",
//   nullable: false,
//   inputMode: CurrencyMaskInputMode.NATURAL,
// };

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        TimeEntriesComponent,
        TimeEntryDialogComponent,
        NumericDirective,
        ResumeTimerComponent,
        WriteOffTimeEntryComponent,
        MoveEntryComponent,
        DeliverableDailogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        //mat import
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatSortModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSelectModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatDividerModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        SatDatepickerModule, SatNativeDateModule,

        DragDropModule,
        ApplicationPipesModule,
        ResizableModule,
        NgxCurrencyModule,
        PipesModule,
        MatChipsModule,
        NumberMaskModule,
        MatRadioModule,
        NgxMaskModule.forRoot(maskConfig),
        NumberSeparatorModule
    ],
    // providers: [
    //   // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    //   // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    // ],
    providers: [
        DecimalPipe,
        DatePipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        //  { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
    entryComponents: [
        TimeEntryDialogComponent,
        ResumeTimerComponent,
        WriteOffTimeEntryComponent,
        MoveEntryComponent,
        DeliverableDailogComponent
    ],
    exports: [
        TimeEntriesComponent
    ],
})
export class TimeEntriesModule { }
