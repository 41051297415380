import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as $ from 'jquery';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { MatterDialogComponentForTemplate } from '../matter-dialog/matter-dialog.component';
import { Router } from '@angular/router';
import { ResizeEvent } from 'angular-resizable-element';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subject, Subscription, takeUntil } from 'rxjs';



@Component({
  selector: 'app-email-templete',
  templateUrl: './email-templete.component.html',
  styleUrls: ['./email-templete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EmailTempleteComponent implements OnInit, OnDestroy {
  @Input() popupurl
  @Input() inputShowLocalFilter
  EmailAllData: FormGroup;
  tempColobj: any;
  ColumnsObj: any;
  EmailtemplateData: any = [];
  TemplateEmaildata: any = [];
  isLoadingResults: boolean = false;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  Title = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  displayedColumns: any = ['TYPEICON', 'NAME'];
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  highlightedRows: any;
  pageSize: any;
  SafeCustodyData: any;
  showLocalFilters: boolean = false;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub2: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    private router: Router,
    private behaviorService: BehaviorService,
    private SortingbehaviorService: SortingBehaviourService
  ) {
    localStorage.setItem('istrackid', 'EmailTempleteComponent');
    this.SortingbehaviorService.EmailSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      this.sortingDefaultState = result
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'EmailTempleteComponent' && result['click'] && result['data']['template-email']) {
        this.LoadData({ACTION: "GetData",FILTERS:{}});;
      }
    });

     // matter Template popup Search control.
     this.behaviorService.MatterTemplatePopupSearch$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((res)=>{
      if(res){
        if(res.type == 'Emails'){
          this.LoadData({ACTION: "GetData",FILTERS:{ SEARCH: res}});
        }
      }
    });

    this.sub2 = this.behaviorService.MatterTemplatePopupReload$.subscribe((res)=>{
      if(res){
        if(res.type == 'Emails'){
          this.refreshEmailTab()
        }
      }
    });


    // generate invoice Search control.
    this.behaviorService.generateInvoiceSearch$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((res)=>{
      if(res != null){
          this.LoadData({ACTION: "GetData",FILTERS:{ SEARCH: res}}); 
      }
    });
  }

  ngAfterViewChecked() {
    // alert(this.inputShowLocalFilter)
    if(this.inputShowLocalFilter == false) {
      this.showLocalFilters = this.inputShowLocalFilter;
    } else {
      this.showLocalFilters = true;
    }
  }


   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if (this.sortingDefaultState) {
      this.sortactive = this.sortingDefaultState.active;
      this.sortDirection = this.sortingDefaultState.direction;
    } else {
      this.sortactive = "";
      this.sortDirection = "";
    }

    this.behaviorService.resizeTableForAllView();
    const behaviorService = this.behaviorService;
    $(window).resize(function () {
      behaviorService.resizeTableForAllView();
    });
    this.EmailAllData = this._formBuilder.group({
      Filter: [''],
      search: ['']
    });

    this.LoadData({ACTION: "GetData",FILTERS:{}});

    this.behaviorService.SafeCustody$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
          this.SafeCustodyData = result;
      }
  });

    

  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to load the data value
   */
  LoadData(passdata) {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData(passdata, 'template-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.TemplateEmaildata = new MatTableDataSource(response.DATA.RECORDS);
        // this.editContact(response.DATA.TEMPLATES[0]);
        this.TemplateEmaildata.paginator = this.paginator;
        this.TemplateEmaildata.sort = this.sort;
        if (response.DATA.RECORDS[0]) {
          this.behaviorService.EmailGenerateData(response.DATA.RECORDS[0]);
          this.highlightedRows = response.DATA.RECORDS[0].EMAILGUID;
        }
        this.isLoadingResults = false;
      }
    }, err => {

      this.toastr.error(err);
      this.isLoadingResults = false;
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }
  get f() {
    return this.EmailAllData.controls;
  }

  /**
   * This function is used to sort the data value
   */
  sortData(val) {
    this.SortingbehaviorService.EmailSorting(val);
  }

  /**
   * This function is used to search the the filter data
   */
  FilterSearch(searchFilter: any) {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
      this.LoadData({ACTION: "GetData",FILTERS:{ SEARCH: this.f.search.value}}); 
    }
  }

/**
 * This function is used to click on the title
 */
  clicktitle(row) {
    this.behaviorService.EmailGenerateData(row);
  }
  
  /**
   * This function is used to open the dialog
   */
  EmailDialog() {

  }

  /**
   * This function is used to on paginate change
   */
  onPaginateChange(event) {
    this.pageSize.email_templete = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

  /**
   * This function is used to dbl click on the mail
   */
  dblclickEmail(row) {
    this.behaviorService.EmailGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.EmailtemplateData = result;
      }
    });

    
    if (this.router.url == "/create-document/email-invoice-template" || this.popupurl == "/create-document/email-invoice-template") {
      let invoiceGUid = localStorage.getItem('edit_invoice_id');
      let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": 'Email', "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
      this.ForEmailDialogOpen(passdata);
    } else if (this.router.url == "/create-document/email-matter-template" || this.popupurl == "/create-document/email-matter-template") {
      let matterData = JSON.parse(localStorage.getItem('set_active_matters'));
      let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": 'Email', "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
      this.ForEmailDialogOpen(passdata);
    } else if (this.router.url == "/create-document/email-receive-money-template"  ||this.popupurl == "/create-document/email-receive-money-template" ) {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": 'Email', "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
      this.ForEmailDialogOpen(passdata);
    } else if (this.router.url == "/create-document/email-contact-template" ||this.popupurl =="/create-document/email-contact-template"  ) {
      let ContactGuID = localStorage.getItem('contactGuid');
      let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": 'Email', "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
      this.ForEmailDialogOpen(passdata);
    }else if (this.router.url == "/full-Safe-Custody/Safe-Custody"  || this.popupurl ==  "/full-Safe-Custody/Safe-Custody"  ) {
      let ContactGuID = localStorage.getItem('contactGuid');
      let passdata = { 'Context': "Contact", 'ContextGuid': this.SafeCustodyData.SAFECUSTODYGUID, "knownby": 'Email', "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
      this.ForEmailDialogOpen(passdata);
    }

  }

  /**
   * This function is used to open the email dialog data
   */
  ForEmailDialogOpen(passdata) {
    const dialogRef = this._matDialog.open(MatterDialogComponentForTemplate, {
      width: '100%',
      disableClose: true,
      data: passdata
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  /**
   * This function is used to refresh the Email tab
   */
  refreshEmailTab() {
    this.LoadData({ACTION: "GetData",FILTERS:{}});
  }

  /**
   * This function is used to get the resize end data
   */
  onResizeEnd(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
    }
  }
}

