<div
    id="matters"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
    [ngClass]="COLOR">
    <!-- Adding a filter option to the matters screen -->
    <form
        [formGroup]="matterFilterForm"
        class="align-center display-flex filter-menuwrap p-24 pt-0 pb-0"
        id="filter-form-menu-wrap">
        <div class="align-center display-flex flex-wrap filter-menublock">
            <div class="add-filterwrap">
                <!-- button -->
                <button
                    mat-button
                    [matMenuTriggerFor]="dummyButton"
                    [disabled]="true"
                    style="display: none">
                    Filters
                </button>
                <mat-menu #dummyButton="matMenu"> </mat-menu>
                <!-- ends here ~ button -->
                <button
                    mat-button
                    [matMenuTriggerFor]="menuFilter"
                    class="filter-button"
                    [ngClass]="COLOR"
                    [disabled]="
                        optionalFilterList
                            | enableDisableFilter : matterFilterForm.value
                    "
                    (click)="
                        backToFilter('optional-Discount');
                        showFiltersList = true
                    ">
                    <img
                        [src]="
                            currentUserData.PRODUCTTYPE == 'Solicitor'
                                ? 'assets/icons/filter-icon.png'
                                : 'assets/icons/filter-icon-barrister.png'
                        "
                        alt="Filter"
                        class="filter-icon" />
                    <span
                        [ngClass]="
                            (optionalFilterList
                            | enableDisableFilter : matterFilterForm.value)
                                ? 'disabled-filter-text'
                                : ''
                        ">
                        Filters</span>
                </button>
                <mat-icon
                    class="icon-btn close-btn"
                    (click)="resetFilter()"
                    *ngIf="optionalFilters?.length > 0">close</mat-icon>
                <mat-icon class="icon-btn" (click)="reloadData(false)">refresh</mat-icon>
                <mat-menu #menuFilter="matMenu">
                    <div class="filterscroll" [ngClass]="COLOR">
                        <ng-container
                            *ngFor="
                                let optionalFilterData of optionalFilterList
                            ">
                            <div
                                *ngIf="
                                    optionalFilterData.FILTERTYPE != 'HIDDEN'
                                ">
                                <button
                                    class="addbutton"
                                    [ngClass]="COLOR"
                                    *ngIf="
                                        !optionalFilterData.FILTERAPPLY &&
                                        showFiltersList
                                    "
                                    (click)="
                                        preventDefault($event);
                                        activeCurrentFilter(
                                            'optional-Discount',
                                            optionalFilterData
                                        );
                                        addNewFilter(activeOptionalFilter);
                                        showFiltersList = false
                                    ">
                                    <mat-icon>add_circle</mat-icon>{{
                                    optionalFilterData.DESCRIPTION }}
                                </button>
                                <div
                                    class="menu-submenu"
                                    id="optional-Discount">
                                    <h5
                                        class="filter-title"
                                        *ngIf="activeOptionalFilter">
                                        <mat-icon
                                            class="back-icon"
                                            (click)="
                                                backToFilter(
                                                    'optional-Discount'
                                                );
                                                preventDefault($event);
                                                showFiltersList = true
                                            ">arrow_back_ios </mat-icon>Filter
                                        by
                                        {{ activeOptionalFilter.DESCRIPTION }}
                                    </h5>

                                    <div *ngIf="activeOptionalFilter">
                                        <ng-container
                                            formArrayName="FILTERSLIST">
                                            <div
                                                *ngFor="
                                                    let filter of _filterList;
                                                    let j = index
                                                "
                                                class="position-relative">
                                                <ng-container
                                                    [formGroupName]="j">
                                                    <mat-form-field
                                                        appearance="outline"
                                                        class="width-full"
                                                        (click)="
                                                            preventDefault(
                                                                $event
                                                            )
                                                        ">
                                                        <mat-select
                                                            (selectionChange)="
                                                                optionalDateFilter(
                                                                    $event,
                                                                    filter,
                                                                    'default',
                                                                    -1
                                                                )
                                                            "
                                                            name="OPERATION"
                                                            [(ngModel)]="
                                                                addFilter.OPERATION
                                                            "
                                                            formControlName="OPERATION"
                                                            #operationFilter>
                                                            <mat-option
                                                                *ngFor="
                                                                    let d of filter.DATEFILTERLIST
                                                                "
                                                                [value]="
                                                                    d.value
                                                                ">{{
                                                                d.description
                                                                }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>

                                                    <div
                                                        class="display-flex align-center"
                                                        *ngIf="
                                                            filter.selectedFilter !==
                                                                'is between' &&
                                                            (filter.FILTERTYPE ==
                                                                'NUMBER' ||
                                                                filter.FILTERTYPE ==
                                                                    'CURRENCY')
                                                        ">
                                                        <span class="back-tick">
                                                            <img
                                                                src="assets/icons/lefticon.png"
                                                                alt="Left Arrow" />
                                                        </span>
                                                        <mat-form-field
                                                            class="width-full"
                                                            appearance="outline"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            "
                                                            *ngIf="
                                                                filter.FILTERTYPE ==
                                                                'NUMBER'
                                                            ">
                                                            <input
                                                                matInput
                                                                placeholder
                                                                name="VALUE"
                                                                formControlName="VALUE"
                                                                [(ngModel)]="
                                                                    addFilter.VALUE
                                                                "
                                                                [(value)]="
                                                                    addFilter.VALUE
                                                                "
                                                                required
                                                                appNumberMask />
                                                        </mat-form-field>

                                                        <mat-form-field
                                                            class="width-full"
                                                            appearance="outline"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            "
                                                            *ngIf="
                                                                filter.FILTERTYPE ==
                                                                'CURRENCY'
                                                            ">
                                                            <input
                                                                matInput
                                                                placeholder
                                                                name="VALUE"
                                                                formControlName="VALUE"
                                                                [(ngModel)]="
                                                                    addFilter.VALUE
                                                                "
                                                                [(value)]="
                                                                    addFilter.VALUE
                                                                "
                                                                required
                                                                currencyMask />
                                                        </mat-form-field>
                                                    </div>

                                                    <div
                                                        class="display-flex align-center"
                                                        *ngIf="
                                                            filter.FILTERTYPE !==
                                                                'NUMBER' &&
                                                            filter.FILTERTYPE !==
                                                                'CURRENCY' &&
                                                            filter.FILTERTYPE !==
                                                                'DATE'
                                                        ">
                                                        <span class="back-tick">
                                                            <img
                                                                src="assets/icons/lefticon.png"
                                                                alt="Left Arrow" />
                                                        </span>
                                                        <mat-form-field
                                                            class="width-full"
                                                            appearance="outline"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            ">
                                                            <input
                                                                matInput
                                                                placeholder
                                                                name="VALUE"
                                                                formControlName="VALUE"
                                                                [(ngModel)]="
                                                                    addFilter.VALUE
                                                                "
                                                                [(value)]="
                                                                    addFilter.VALUE
                                                                "
                                                                required />
                                                        </mat-form-field>
                                                    </div>

                                                    <div
                                                        class="display-flex align-center"
                                                        *ngIf="
                                                            filter.selectedFilter ==
                                                                'is between' &&
                                                            (filter.FILTERTYPE ==
                                                                'NUMBER' ||
                                                                filter.FILTERTYPE ==
                                                                    'CURRENCY')
                                                        ">
                                                        <span class="back-tick">
                                                            <img
                                                                src="assets/icons/lefticon.png"
                                                                alt="Left Arrow" />
                                                        </span>
                                                        <div
                                                            class="display-flex align-center"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            "
                                                            *ngIf="
                                                                filter.FILTERTYPE ==
                                                                'NUMBER'
                                                            ">
                                                            <mat-form-field
                                                                class="set-date"
                                                                appearance="outline">
                                                                <input
                                                                    matInput
                                                                    placeholder
                                                                    name="VALUE"
                                                                    formControlName="VALUE"
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    required
                                                                    appNumberMask />
                                                            </mat-form-field>
                                                            <span
                                                                class="middle-caption">and</span>
                                                            <mat-form-field
                                                                class="set-date"
                                                                appearance="outline">
                                                                <input
                                                                    matInput
                                                                    placeholder
                                                                    name="VALUE2"
                                                                    formControlName="VALUE2"
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE2
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE2
                                                                    "
                                                                    required
                                                                    appNumberMask />
                                                            </mat-form-field>
                                                        </div>

                                                        <div
                                                            class="display-flex align-center"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            "
                                                            *ngIf="
                                                                filter.FILTERTYPE ==
                                                                'CURRENCY'
                                                            ">
                                                            <mat-form-field
                                                                class="set-date"
                                                                appearance="outline">
                                                                <input
                                                                    matInput
                                                                    placeholder
                                                                    name="VALUE"
                                                                    formControlName="VALUE"
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    required
                                                                    currencyMask />
                                                            </mat-form-field>
                                                            <span
                                                                class="middle-caption">and</span>
                                                            <mat-form-field
                                                                class="set-date"
                                                                appearance="outline">
                                                                <input
                                                                    matInput
                                                                    placeholder
                                                                    name="VALUE2"
                                                                    formControlName="VALUE2"
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE2
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE2
                                                                    "
                                                                    required
                                                                    currencyMask />
                                                            </mat-form-field>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="display-flex align-center"
                                                        *ngIf="
                                                            filter.FILTERTYPE ==
                                                                'DATE' &&
                                                            filter.selectedFilter &&
                                                            filter.selectedFilter !==
                                                                'is in the last' &&
                                                                filter.selectedFilter !==
                                                                    'is between' &&
                                                            (filter.FILTERTYPE !==
                                                                'NUMBER' ||
                                                                filter.FILTERTYPE !==
                                                                    'CURRENCY')
                                                        ">
                                                        <span class="back-tick">
                                                            <img
                                                                src="assets/icons/lefticon.png"
                                                                alt="Left Arrow" />
                                                        </span>
                                                        <div
                                                            class="display-flex align-center">
                                                            <mat-form-field
                                                                appearance="outline"
                                                                class="set-date"
                                                                (click)="
                                                                    preventDefault(
                                                                        $event
                                                                    )
                                                                ">
                                                                <input
                                                                    matInput
                                                                    [matDatepicker]="
                                                                        picker6
                                                                    "
                                                                    name="VALUE"
                                                                    formControlName="VALUE"
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    required />
                                                                <mat-datepicker-toggle
                                                                    matSuffix
                                                                    [for]="
                                                                        picker6
                                                                    ">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker
                                                                    #picker6></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="display-flex align-center"
                                                        *ngIf="
                                                            (filter.FILTERTYPE ==
                                                                'DATE' &&
                                                                !filter.selectedFilter) ||
                                                            (filter.selectedFilter ==
                                                                'is in the last' &&
                                                                (filter.FILTERTYPE !==
                                                                    'NUMBER' ||
                                                                    filter.FILTERTYPE !==
                                                                        'CURRENCY'))
                                                        ">
                                                        <span class="back-tick">
                                                            <img
                                                                src="assets/icons/lefticon.png"
                                                                alt="Left Arrow" />
                                                        </span>
                                                        <div
                                                            class="display-flex align-center field-box"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            ">
                                                            <mat-form-field
                                                                class="set-date date-input-field"
                                                                appearance="outline">
                                                                <input
                                                                    matInput
                                                                    placeholder
                                                                    name="VALUE"
                                                                    formControlName="VALUE"
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    [(value)]="
                                                                        addFilter.VALUE
                                                                    "
                                                                    required
                                                                    appNumberMask />
                                                            </mat-form-field>
                                                            <mat-form-field
                                                                appearance="outline"
                                                                class="set-date date-dropdown-sel">
                                                                <mat-select
                                                                    formControlName="VALUE2"
                                                                    [(value)]="
                                                                        addFilter.VALUE2
                                                                    "
                                                                    [(ngModel)]="
                                                                        addFilter.VALUE2
                                                                    "
                                                                    required>
                                                                    <mat-option
                                                                        value="days">Days</mat-option>
                                                                    <mat-option
                                                                        value="month">Month
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="display-flex align-center"
                                                        *ngIf="
                                                            filter.FILTERTYPE ==
                                                                'DATE' &&
                                                            filter.selectedFilter ==
                                                                'is between' &&
                                                            (filter.FILTERTYPE !==
                                                                'NUMBER' ||
                                                                filter.FILTERTYPE !==
                                                                    'CURRENCY')
                                                        ">
                                                        <mat-form-field
                                                            appearance="outline"
                                                            class="set-date"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            ">
                                                            <input
                                                                matInput
                                                                [matDatepicker]="
                                                                    picker
                                                                "
                                                                #StartDate
                                                                name="VALUE"
                                                                formControlName="VALUE"
                                                                [(ngModel)]="
                                                                    addFilter.VALUE
                                                                "
                                                                [(value)]="
                                                                    addFilter.VALUE
                                                                "
                                                                required
                                                                (keydown)="
                                                                    preventDefaultTab(
                                                                        $event
                                                                    )
                                                                " />
                                                            <mat-datepicker-toggle
                                                                matSuffix
                                                                [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker
                                                                #picker></mat-datepicker>
                                                        </mat-form-field>
                                                        <span
                                                            class="middle-caption">and</span>
                                                        <mat-form-field
                                                            appearance="outline"
                                                            class="set-date"
                                                            (click)="
                                                                preventDefault(
                                                                    $event
                                                                )
                                                            ">
                                                            <input
                                                                matInput
                                                                [matDatepicker]="
                                                                    picker2
                                                                "
                                                                #EndDate
                                                                name="VALUE2"
                                                                formControlName="VALUE2"
                                                                [(ngModel)]="
                                                                    addFilter.VALUE2
                                                                "
                                                                [(value)]="
                                                                    addFilter.VALUE2
                                                                "
                                                                required
                                                                (keydown)="
                                                                    preventDefaultTab(
                                                                        $event
                                                                    )
                                                                " />
                                                            <mat-datepicker-toggle
                                                                matSuffix
                                                                [for]="picker2">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker
                                                                #picker2></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>

                                                    <button
                                                        mat-raised-button
                                                        color="primary"
                                                        class="width-full accent"
                                                        (click)="
                                                            addfilter(
                                                                filter,
                                                                operationFilter,
                                                                $event
                                                            )
                                                        ">
                                                        Apply
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>

            <div class="position-relative">
                <mat-icon
                    class="badge-ic"
                    (click)="
                        activeFilters && activeFilters.active
                            ? clearFilter('active', 'Active')
                            : false
                    ">
                    {{
                    activeFilters && activeFilters.active
                    ? "cancel_circle"
                    : "add_circle"
                    }}
                </mat-icon>
                <button
                    mat-button
                    [matMenuTriggerFor]="activeMenucard"
                    class="buttonwith-text dotted-button">
                    Active<span
                        class="filter-applytext display-flex align-center">{{
                        activeFilters && activeFilters.active
                        ? activeFilters.activeDesc
                        : activeFilters?.activeDesc
                        }}<mat-icon class="rightdownarrow">
                            keyboard_arrow_down</mat-icon></span>
                </button>
                <mat-menu #activeMenucard="matMenu" #menu>
                    <mat-radio-group
                        aria-labelledby="filter-radio-group-label"
                        class="filter-radio-group"
                        formControlName="MatterFilter">
                        <mat-radio-button
                            class="filter-radio-button"
                            *ngFor="let activeData of activeFilter"
                            value="{{ activeData.value }}"
                            (click)="
                                Filterconfig(activeData, 'Active');
                                MatterChange(activeFilters.active)
                            ">
                            {{ activeData.description }}
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-menu>
            </div>

            <div class="position-relative">
                <mat-icon
                    class="badge-ic"
                    (click)="
                        activeFilters && activeFilters.unbilledWorkDesc
                            ? clearFilter('unbilledWork', 'Unbilled Work')
                            : false
                    ">
                    {{
                    activeFilters && activeFilters.unbilledWorkDesc
                    ? "cancel_circle"
                    : "add_circle"
                    }}
                </mat-icon>
                <button
                    mat-button
                    [matMenuTriggerFor]="menucard"
                    class="buttonwith-text dotted-button">
                    Unbilled Work<span
                        class="filter-applytext display-flex align-center">{{
                        activeFilters && activeFilters.unbilledWorkDesc
                        ? activeFilters.unbilledWorkDesc
                        : ""
                        }}<mat-icon class="rightdownarrow">
                            keyboard_arrow_down</mat-icon></span>
                </button>
                <mat-menu #menucard="matMenu">
                    <mat-radio-group
                        aria-labelledby="filter-radio-group-label"
                        class="filter-radio-group"
                        formControlName="InvoiceFilter"
                        [value]="
                            lastFilter && lastFilter.UninvoicedWork == ''
                                ? lastFilter.UninvoicedWork
                                : 'All'
                        ">
                        <mat-radio-button
                            class="filter-radio-button"
                            *ngFor="let unbuildData of unbuildWorkFilter"
                            value="{{ unbuildData.value }}"
                            (change)="
                                Filterconfig(unbuildData, 'Unbilled Work');
                                MatterInvoiceChange(activeFilters.unbilledWork)
                            ">
                            {{ unbuildData.description }}
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-menu>
            </div>

            <div class="position-relative">
                <mat-icon
                    class="badge-ic"
                    (click)="
                        activeFilters && activeFilters.matterClassDesc
                            ? clearFilter('matterClass', 'Matter Class')
                            : false
                    ">
                    {{
                    activeFilters && activeFilters.matterClassDesc
                    ? "cancel_circle"
                    : "add_circle"
                    }}
                </mat-icon>
                <button
                    mat-button
                    [matMenuTriggerFor]="menuMatterClass"
                    class="buttonwith-text dotted-button">
                    Matter Class<span
                        class="filter-applytext display-flex align-center">{{
                        activeFilters && activeFilters.matterClassDesc
                        ? activeFilters.matterClassDesc
                        : ""
                        }}<mat-icon class="rightdownarrow">
                            keyboard_arrow_down</mat-icon></span>
                </button>
                <mat-menu #menuMatterClass="matMenu">
                    <mat-radio-group
                        aria-labelledby="filter-radio-group-label"
                        class="filter-radio-group"
                        formControlName="MatterClassType"
                        (change)="
                            Filterconfig($event, 'Matter Class');
                            MatterClassType(activeFilters.matterClass)
                        ">
                        <mat-radio-button
                            class="filter-radio-button"
                            value="all">All</mat-radio-button>
                        <mat-radio-button
                            class="filter-radio-button"
                            *ngFor="let Classoption of Classdata"
                            value="{{ Classoption.LOOKUPFULLVALUE }}">
                            {{ Classoption.LOOKUPFULLVALUE }}
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-menu>
            </div>

            <div class="position-relative" *ngIf="isShowDrop">
                <mat-icon
                    class="badge-ic"
                    (click)="
                        activeFilters && activeFilters.FeeEarnerDesc
                            ? clearFilter('FeeEarner', 'Fee Earner')
                            : false
                    ">
                    {{
                    activeFilters && activeFilters.FeeEarnerDesc
                    ? "cancel_circle"
                    : "add_circle"
                    }}
                </mat-icon>
                <button
                    mat-button
                    [matMenuTriggerFor]="menuFeeEarner"
                    class="buttonwith-text dotted-button">
                    Fee Earner/Owner<span
                        class="filter-applytext display-flex align-center">{{
                        activeFilters && activeFilters.FeeEarnerDesc
                        ? activeFilters.FeeEarnerDesc
                        : activeFilters?.FeeEarnerDesc
                        }}<mat-icon class="rightdownarrow">
                            keyboard_arrow_down</mat-icon></span>
                </button>
                <mat-menu #menuFeeEarner="matMenu">
                    <mat-radio-group
                        aria-labelledby="filter-radio-group-label"
                        class="filter-radio-group"
                        formControlName="UserFilter"
                        value="{{
                            lastFilter && lastFilter.FeeEarner
                                ? lastFilter.FeeEarner
                                : 'all'
                        }}"
                        (change)="
                            Filterconfig($event, 'Fee Earner');
                            MatterUserChange(activeFilters.FeeEarner)
                        ">
                        <mat-radio-button
                            class="filter-radio-button"
                            value="all">All</mat-radio-button>
                        <mat-radio-button class="filter-radio-button"
                            value=" ">None</mat-radio-button>
                        <mat-radio-button
                            class="filter-radio-button"
                            *ngFor="let MatterDrop of MatterDropData"
                            value="{{ MatterDrop.USERID }}">
                            {{ MatterDrop.USERID }}
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-menu>
            </div>

            <div class="position-relative">
                <!-- search  -->
                <mat-icon
                    class="badge-ic"
                    (click)="
                        onFilterSearch('removeSearchString');
                        clearFilter('SearchString', 'SearchString')
                    "
                    *ngIf="hasSearchVal">
                    {{ hasSearchVal ? "cancel_circle" : "" }}</mat-icon>

                <button
                    mat-button
                    class="buttonwith-text"
                    style="padding: 1px 5px">
                    Search<span
                        class="filter-applytext display-flex align-center">
                        <input
                            matInput
                            #searchField
                            (keyup)="
                                disabledMainMenuTrigger = true;
                                onSearchChange($event);
                                Filterconfig($event, 'SearchString')
                            "
                            formControlName="searchFilter"
                            style="margin-top: 4px; text-align: left"
                            tabindex="6" />
                        <mat-icon
                            matSuffix
                            (click)="
                                onFilterSearch('Enter'); preventDefault($event)
                            ">search</mat-icon>
                    </span>
                </button>
            </div>

            <ng-container formArrayName="FILTERS">
                <div
                    *ngFor="let filter of optionalFilters; let i = index"
                    class="position-relative">
                    <ng-container [formGroupName]="i">
                        <mat-icon
                            class="badge-ic"
                            (click)="
                                resetOptionalFilter(
                                    filter,
                                    filter.FILTERTYPE,
                                    i
                                )
                            ">
                            cancel_circle
                        </mat-icon>
                        <button
                            mat-button
                            [matMenuTriggerFor]="menuCreatedDate_i"
                            class="buttonwith-text"
                            (click)="setFilterUi(filter, i)">
                            {{ filter.DESCRIPTION
                            }}<span
                                class="filter-applytext display-flex align-center">{{
                                optionalDateFilterPayload[i] &&
                                optionalDateFilterPayload[i]["value"] !==
                                null
                                ? optionalDateFilterPayload[i]["value"]
                                .VALUE
                                : ""
                                }}
                                {{
                                optionalDateFilterPayload[i] &&
                                optionalDateFilterPayload[i]["value"] !==
                                null &&
                                optionalDateFilterPayload[i]["value"]
                                .VALUE2 !== "" &&
                                optionalDateFilterPayload[i]["value"]
                                .VALUE2 !== null
                                ? " - " +
                                optionalDateFilterPayload[i]["value"]
                                .VALUE2
                                : ""
                                }}<mat-icon class="rightdownarrow">
                                    keyboard_arrow_down</mat-icon></span>
                        </button>
                        <mat-menu #menuCreatedDate_i="matMenu">
                            <h5 class="filter-title">
                                Filter by {{ filter.DESCRIPTION }}
                            </h5>
                            <mat-form-field
                                appearance="outline"
                                class="width-full"
                                (click)="preventDefault($event)">
                                <mat-select
                                    (selectionChange)="
                                        optionalDateFilter(
                                            $event,
                                            filter,
                                            'active',
                                            i
                                        )
                                    "
                                    name="OPERATION"
                                    formControlName="OPERATION"
                                    #Datefilter>
                                    <mat-option
                                        *ngFor="let d of filter.DATEFILTERLIST"
                                        [value]="d.value">{{ d.description }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div
                                class="display-flex align-center"
                                *ngIf="
                                    selectedFilterIs !== 'is between' &&
                                    (filter.FILTERTYPE == 'NUMBER' ||
                                        filter.FILTERTYPE == 'CURRENCY')
                                ">
                                <span class="back-tick">
                                    <img
                                        src="assets/icons/lefticon.png"
                                        alt="Left Arrow" />
                                </span>
                                <mat-form-field
                                    class="width-full"
                                    appearance="outline"
                                    *ngIf="filter.FILTERTYPE == 'NUMBER'"
                                    (click)="preventDefault($event)">
                                    <input
                                        matInput
                                        placeholder
                                        name="VALUE"
                                        formControlName="VALUE"
                                        required
                                        appNumberMask />
                                </mat-form-field>

                                <mat-form-field
                                    class="width-full"
                                    appearance="outline"
                                    *ngIf="filter.FILTERTYPE == 'CURRENCY'"
                                    (click)="preventDefault($event)">
                                    <input
                                        matInput
                                        placeholder
                                        name="VALUE"
                                        formControlName="VALUE"
                                        required
                                        currencyMask />
                                </mat-form-field>
                            </div>

                            <div
                                class="display-flex align-center"
                                *ngIf="
                                    filter.FILTERTYPE !== 'NUMBER' &&
                                    filter.FILTERTYPE !== 'CURRENCY' &&
                                    filter.FILTERTYPE !== 'DATE'
                                ">
                                <span class="back-tick">
                                    <img
                                        src="assets/icons/lefticon.png"
                                        alt="Left Arrow" />
                                </span>
                                <mat-form-field
                                    class="width-full"
                                    appearance="outline"
                                    (click)="preventDefault($event)">
                                    <input
                                        matInput
                                        placeholder
                                        name="VALUE"
                                        formControlName="VALUE"
                                        required />
                                </mat-form-field>
                            </div>

                            <div
                                class="display-flex align-center"
                                *ngIf="
                                    selectedFilterIs == 'is between' &&
                                    (filter.FILTERTYPE == 'NUMBER' ||
                                        filter.FILTERTYPE == 'CURRENCY')
                                ">
                                <span class="back-tick">
                                    <img
                                        src="assets/icons/lefticon.png"
                                        alt="Left Arrow" />
                                </span>
                                <div
                                    class="display-flex align-center"
                                    (click)="preventDefault($event)"
                                    *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                    <mat-form-field
                                        class="set-date"
                                        appearance="outline">
                                        <input
                                            matInput
                                            placeholder
                                            name="VALUE"
                                            formControlName="VALUE"
                                            required
                                            appNumberMask />
                                    </mat-form-field>
                                    <span class="middle-caption">and</span>
                                    <mat-form-field
                                        class="set-date"
                                        appearance="outline">
                                        <input
                                            matInput
                                            placeholder
                                            name="VALUE2"
                                            formControlName="VALUE2"
                                            required
                                            appNumberMask />
                                    </mat-form-field>
                                </div>

                                <div
                                    class="display-flex align-center"
                                    (click)="preventDefault($event)"
                                    *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                    <mat-form-field
                                        class="set-date"
                                        appearance="outline">
                                        <input
                                            matInput
                                            placeholder
                                            name="VALUE"
                                            formControlName="VALUE"
                                            required
                                            currencyMask />
                                    </mat-form-field>
                                    <span class="middle-caption">and</span>
                                    <mat-form-field
                                        class="set-date"
                                        appearance="outline">
                                        <input
                                            matInput
                                            placeholder
                                            name="VALUE2"
                                            formControlName="VALUE2"
                                            required
                                            currencyMask />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div
                                class="display-flex align-center"
                                *ngIf="
                                    filter.FILTERTYPE == 'DATE' &&
                                    selectedFilterIs &&
                                    selectedFilterIs !== 'is in the last' &&
                                        selectedFilterIs !== 'is between' &&
                                    (filter.FILTERTYPE !== 'NUMBER' ||
                                        filter.FILTERTYPE !== 'CURRENCY')
                                ">
                                <span class="back-tick">
                                    <img
                                        src="assets/icons/lefticon.png"
                                        alt="Left Arrow" />
                                </span>
                                <div class="display-flex align-center">
                                    <mat-form-field
                                        appearance="outline"
                                        class="set-date"
                                        (click)="preventDefault($event)">
                                        <input
                                            matInput
                                            [matDatepicker]="picker6"
                                            name="VALUE"
                                            formControlName="VALUE"
                                            required />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker6"></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker6></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div
                                class="display-flex align-center"
                                *ngIf="
                                    (filter.FILTERTYPE == 'DATE' &&
                                        !selectedFilterIs) ||
                                    (selectedFilterIs == 'is in the last' &&
                                        (filter.FILTERTYPE !== 'NUMBER' ||
                                            filter.FILTERTYPE !== 'CURRENCY'))
                                ">
                                <span class="back-tick">
                                    <img
                                        src="assets/icons/lefticon.png"
                                        alt="Left Arrow" />
                                </span>
                                <div
                                    class="display-flex align-center field-box"
                                    (click)="preventDefault($event)">
                                    <mat-form-field
                                        class="set-date"
                                        appearance="outline">
                                        <input
                                            matInput
                                            placeholder
                                            name="VALUE"
                                            formControlName="VALUE"
                                            required
                                            appNumberMask />
                                    </mat-form-field>
                                    <mat-form-field
                                        appearance="outline"
                                        class="set-date">
                                        <mat-select
                                            [value]="'days'"
                                            formControlName="VALUE2"
                                            required>
                                            <mat-option value="days">Days</mat-option>
                                            <mat-option value="month">Month
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div
                                class="display-flex align-center"
                                *ngIf="
                                    filter.FILTERTYPE == 'DATE' &&
                                    selectedFilterIs == 'is between' &&
                                    (filter.FILTERTYPE !== 'NUMBER' ||
                                        filter.FILTERTYPE !== 'CURRENCY')
                                ">
                                <mat-form-field
                                    appearance="outline"
                                    class="set-date"
                                    (click)="preventDefault($event)">
                                    <input
                                        matInput
                                        [matDatepicker]="picker"
                                        #StartDate
                                        name="VALUE"
                                        formControlName="VALUE"
                                        required />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <span class="middle-caption">and</span>
                                <mat-form-field
                                    appearance="outline"
                                    class="set-date"
                                    (click)="preventDefault($event)">
                                    <input
                                        matInput
                                        [matDatepicker]="picker2"
                                        #EndDate
                                        name="VALUE2"
                                        formControlName="VALUE2"
                                        required />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <button
                                mat-raised-button
                                color="primary"
                                class="width-full accent"
                                (click)="
                                    applayOptionalfilter(
                                        optionalFilters[i],
                                        i,
                                        Datefilter,
                                        $event
                                    )
                                ">
                                Apply
                            </button>
                        </mat-menu>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="clear-filterwrap" style="cursor: pointer"></div>
        <div class="bg-dark rigth-div" id="appCues_Matter"></div>
    </form>
    <!-- OLD FILTER -->
    <div class="content">
        <!-- CENTER -->
        <div class="center p-24 list_tbl_main more_tbl_clmn">
            <!-- CONTENT -->
            <div class="content fix_div_padding set-inline-time footer_change">
                <app-matters-list
                    (matterDetail)="matterBack($event)"
                    [optionalDateFilterPayload]="optionalDateFilterPayload"
                    [advancedFiltersDynamic]="advancedFiltersDynamic"></app-matters-list>
            </div>
            <!-- / CONTENT -->
        </div>
        <fuse-sidebar
            *ngIf="mattersDetail"
            [ngStyle]="{
                'margin-bottom': '20px',
                height: 'auto',
                'margin-right': '10px',
                border: '10px'
            }"
            id="1"
            id="list_sidebar_right_child_detail"
            class="sidebar final-sidebar list_sidebar_right_child main-sidebar"
            name="final-sidebar"
            position="right"
            lockedOpen="gt-md">
            <app-matters-sort-detail
                class="list_sidebar_padding"
                (matterCloseD)="matterClose($event)">
            </app-matters-sort-detail>
        </fuse-sidebar>
        <!-- / CENTER -->
    </div>
    <!-- / CONTENT-->
</div>
