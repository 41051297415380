import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bank-feed-instructions',
  templateUrl: './bank-feed-instructions.component.html',
  styleUrls: ['./bank-feed-instructions.component.scss']
})
export class BankFeedInstructionsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BankFeedInstructionsComponent>) { }

  ngOnInit(): void {
  }

  closePopup(){
    this.dialogRef.close(true);
  }

}
