import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as $ from 'jquery';
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Injectable({
    providedIn: 'root'
})
export class BehaviorService {

    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    timerId: any = '';
    public applicationVersion$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('applicationVersion')));
    public activeTab$: BehaviorSubject<any> = new BehaviorSubject(1);
    public activeTabName$: BehaviorSubject<any> = new BehaviorSubject('');
    public serachingData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterInvoice$: BehaviorSubject<any> = new BehaviorSubject(null);
    public CheckForCredits$: BehaviorSubject<any> = new BehaviorSubject(null);
    public workInProgress$: BehaviorSubject<any> = new BehaviorSubject(null);
    public userPermission$: BehaviorSubject<any> = new BehaviorSubject(null);
    public generateDocumentPath$: BehaviorSubject<any> = new BehaviorSubject(null);
    public calanderViewType$: BehaviorSubject<any> = new BehaviorSubject('Month');
    public TimeScale$: BehaviorSubject<any> = new BehaviorSubject(1);
    public UpdateMartterData$: BehaviorSubject<any> = new BehaviorSubject(false);
    public UpdateMartterFlag$: BehaviorSubject<any> = new BehaviorSubject(false);
    public LogOutNowFromLoggendOnUser$: BehaviorSubject<any> = new BehaviorSubject(false);

    //for packs
    public packs$: BehaviorSubject<any> = new BehaviorSubject(null);
    public EmailGenerateData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TemplateGenerateData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TemplateGenerateDataHF$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setDocumentPath$: BehaviorSubject<any> = new BehaviorSubject(null);
    //for packs

    public DocumentRegisterData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public SpendMoneyData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ChartAccountData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('ChartAccountData')));
    public AccountTypeData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('ChartAccountTypeData')));
    public ChartAccountDataEdit$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('setChartAccountDataEdit')));

    public MatterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('set_active_matters')));
    public TaskData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public DELIVERABLES$: BehaviorSubject<any> = new BehaviorSubject((localStorage.getItem('set_active_DELIVERABLES')) ? localStorage.getItem('set_active_DELIVERABLES') : (this.currentUser && this.currentUser.DELIVERABLES) ? this.currentUser.DELIVERABLES : '');
    public SysytemAccountData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MatterPopupStatus$: BehaviorSubject<any> = new BehaviorSubject((localStorage.getItem('MatterWindow_Open_Status')) ? localStorage.getItem('MatterWindow_Open_Status') : (this.currentUser && this.currentUser.POPUPSELECTMATTERFIRST) ? this.currentUser.POPUPSELECTMATTERFIRST : '')
    public SysytemAccountDIalogData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public UserDropDownData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public FileNotesData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ActiveSubMenu$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ConflictMainData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ConflictDataList$: BehaviorSubject<any> = new BehaviorSubject(null);
    public LegalChronologyData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MainTimeEntryData$: BehaviorSubject<any> = new BehaviorSubject(null);

    public matterClassData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterClassAPICallData$: ReplaySubject<any> = new ReplaySubject<any>(1);

    public MainAuthorityData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public LegalAuthorityData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public LegalAuthorityToolbar$: BehaviorSubject<any> = new BehaviorSubject(null);
    public LegalAuthorityForSubAuthToolbar$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MainTopicData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public RecouncileItemSendSetData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MatterEditData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public estimatelegalData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public deliverableData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterApplicationData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public AWAITING2FA$: BehaviorSubject<any> = new BehaviorSubject(localStorage.getItem('AWAITING2FA'));
    public ActivityData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public BankChartAccountStore$: BehaviorSubject<any> = new BehaviorSubject(null);
    public GeneralData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public SafeCustody$: BehaviorSubject<any> = new BehaviorSubject(null);
    public forUserActivity$: BehaviorSubject<any> = new BehaviorSubject(null);
    public forDiaryRefersh$: BehaviorSubject<any> = new BehaviorSubject(null);
    public forDiaryRefersh2$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ReceiptData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public Packets$: BehaviorSubject<any> = new BehaviorSubject(null);
    public dialogClose$: BehaviorSubject<any> = new BehaviorSubject(null);
    public currentUser$: BehaviorSubject<any> = new BehaviorSubject(null);
    public Hyperlink$: BehaviorSubject<any> = new BehaviorSubject(null);
    public CheckBulkInvoiceData$: BehaviorSubject<any> = new BehaviorSubject([]);
    public ONCHARGEDISBURSEMENTGST$: BehaviorSubject<any> = new BehaviorSubject(0);
    public showMails$: BehaviorSubject<any> = new BehaviorSubject(false);
    public authorisedaccounts$: BehaviorSubject<any> = new BehaviorSubject([]);
    public moveDataEntry$: BehaviorSubject<any> = new BehaviorSubject(null);



    public UseCalanderViewType$: BehaviorSubject<any> = new BehaviorSubject(null);

    public TrustDuplicateModuleHandling$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('ChartURL')));
    public TrustBankingDuplicate$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('ChartURLForBanking')));

    public MatterNum$: BehaviorSubject<any> = new BehaviorSubject(null);
    public contactData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public auditLogs$: BehaviorSubject<any> = new BehaviorSubject(null);

    public activitiesData$: BehaviorSubject<any> = new BehaviorSubject(null);

    public totalDashboard$: BehaviorSubject<any> = new BehaviorSubject(null);

    public CommonToolbarHS$: BehaviorSubject<any> = new BehaviorSubject(null);

    public UserData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public DiaryData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public DelGloballypopupHS$: BehaviorSubject<any> = new BehaviorSubject(null);
    public RecouncileConstName$: BehaviorSubject<any> = new BehaviorSubject(localStorage.getItem('RecouncileConstName'));

    public loadingSystemSetting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public loadingAccountMNG$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TrustMoneyList$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ProtectedTrustList$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ReceiveMoneyList$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TrustEndofMonth$: BehaviorSubject<any> = new BehaviorSubject(null);

    public templateSubFolder$: BehaviorSubject<any> = new BehaviorSubject(null);
    public templateSubFolderHF$: BehaviorSubject<any> = new BehaviorSubject(null);
    public SubFolder$: BehaviorSubject<any> = new BehaviorSubject(null);

    public permissionCheck$: BehaviorSubject<any> = new BehaviorSubject(false);
    public permissionCheckforHeight$: BehaviorSubject<any> = new BehaviorSubject(false);


    public reduceMatterContactCall$: BehaviorSubject<any> = new BehaviorSubject(null);
    public reduceReceiveMoneyCall$: BehaviorSubject<any> = new BehaviorSubject(null);
    public reduceSafeCustodyCall$: BehaviorSubject<any> = new BehaviorSubject(null);

    public PastRecouncileData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public isAccountMulti$: BehaviorSubject<any> = new BehaviorSubject(null);
    public multiAccountStore$: BehaviorSubject<any> = new BehaviorSubject(null);
    public isbankStatmentDate$: BehaviorSubject<any> = new BehaviorSubject(null);
    public courtFormData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public precedentsStateData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterSerachFromQuickTimeEntry$: BehaviorSubject<any> = new BehaviorSubject(null);
    public mtshareData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public chequeStatment$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('cheque_statment')));
    public allowancesData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('allowances_data')));
    public toolBaarSetting$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('tool_baar_settings')));
    public APIretryCall$: BehaviorSubject<any> = new BehaviorSubject({ 'click': false, 'data': {} });
    public EndOfMonth$: BehaviorSubject<any> = new BehaviorSubject(null);
    public EndOfMonthData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('end_of_month_data')));
    public EndOfMonthDataSelected$: BehaviorSubject<any> = new BehaviorSubject([]);
    public defultFeeErnerData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public defultPreferencesData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public statementClosingBalance$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TimerCountData$: BehaviorSubject<any> = new BehaviorSubject(0);
    public collectSelectedInvoice$: BehaviorSubject<any> = new BehaviorSubject([]);
    public collectSelectedRecovery$: BehaviorSubject<any> = new BehaviorSubject([]);
    public timerRefreshObject$: BehaviorSubject<any> = new BehaviorSubject(true);
    public EjsDropDownTreeData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public appPermissionsSetting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public accessTierPermission$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem("set_tier_permission")));
    public recounciliationItem$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MatterLoding$: BehaviorSubject<any> = new BehaviorSubject(false);
    public ChildTableData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ChildContactTableData$: BehaviorSubject<any> = new BehaviorSubject(false);
    public DefultMatterData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public stopTimeInterval$: BehaviorSubject<any> = new BehaviorSubject(false);
    public startTimeInterval$: BehaviorSubject<any> = new BehaviorSubject(true);
    public DocumentRegisterFolderClickData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public preserveMatterSidePenalAction$: BehaviorSubject<any> = new BehaviorSubject(null);
    public NotificationCall$: BehaviorSubject<any> = new BehaviorSubject(false);
    public SelectedEmailList$: BehaviorSubject<any> = new BehaviorSubject([]);
    public selectCustomeEmailList$: BehaviorSubject<any> = new BehaviorSubject(null);
    public nextReCalcTimeEntrie$: BehaviorSubject<any> = new BehaviorSubject(null);
    public PrintBankingSlip$: BehaviorSubject<any> = new BehaviorSubject(null);
    public estateAssets$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('estat_asset')));
    public estateBeneficiary$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('estate_beneficiary')));
    public estateliability$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('estate_liability')));
    public clossAllBrowser$: BehaviorSubject<any> = new BehaviorSubject(false);
    public timeEntryes$: BehaviorSubject<any> = new BehaviorSubject(null);
    public refreshSearch$: BehaviorSubject<any> = new BehaviorSubject(false);
    public newContactData$: BehaviorSubject<any> = new BehaviorSubject(false);
    public onChange$: EventEmitter<any> = new EventEmitter<any>(false);

    public browserLogout$: BehaviorSubject<any> = new BehaviorSubject(false);
    public selectedMatters$: BehaviorSubject<any> = new BehaviorSubject([]);
    public selectedBankFeeds$: BehaviorSubject<any> = new BehaviorSubject([]);
    public selectedWorkinProgressMatter$: BehaviorSubject<any> = new BehaviorSubject([]);
    public selectedtimeEntriesChckbox$: BehaviorSubject<any> = new BehaviorSubject([]);
    public selectedMattersClear$: BehaviorSubject<any> = new BehaviorSubject(false);

    public MatterTemplatePopupSearch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MatterTemplatePopupReload$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MatterpopupContactData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public FirmDirecotryData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public GetPreferences$: BehaviorSubject<any> = new BehaviorSubject(null);
    public generateInvoiceSearch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public refreshTemplateOptionsList: BehaviorSubject<any> = new BehaviorSubject(null);

    public additionalFieldsValues$: BehaviorSubject<any> = new BehaviorSubject(null);
    // public matterDataChangeValues$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterDataChangeValuesSource = new BehaviorSubject<any>(null);
    public matterDataChangeValues$ = this.matterDataChangeValuesSource.asObservable();

    public refreshTaggedList: BehaviorSubject<any> = new BehaviorSubject(null);
    public refreshinvoiceWorkItem: BehaviorSubject<any> = new BehaviorSubject(null);
    public ToggleForUpadteFavourite$: BehaviorSubject<any> = new BehaviorSubject((localStorage.getItem('testBoolFavourite')))
    public favouriteItems$: BehaviorSubject<any> = new BehaviorSubject(null);
    public isMenuFolded$: BehaviorSubject<any> = new BehaviorSubject(null);
    public usersMenu$: BehaviorSubject<any> = new BehaviorSubject(null);
    public loadingUserMenuResult$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterDetailsWindow$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setSafeCstodyPacketGuid$: BehaviorSubject<any> = new BehaviorSubject(null);
    public CallProtectedTrustApi$: BehaviorSubject<any> = new BehaviorSubject(false);
    public openDifferentTab$: BehaviorSubject<any> = new BehaviorSubject(null);
    public appointmentRowData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public refreshDiaryData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public chartupdate$: BehaviorSubject<any> = new BehaviorSubject(null);
    public editDashboardMode$: BehaviorSubject<any> = new BehaviorSubject(null);
    public editIndividualDashboardMode$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ToggleFirmDashboardData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('EditFirmDashBoard')));
    public UpdateMatterDetailFev$: BehaviorSubject<any> = new BehaviorSubject(null);
    public UpdateIndividualDashboardFev$: BehaviorSubject<any> = new BehaviorSubject(null);
    public RightClick$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('set_active_matters')));
    // public RightClick$:BehaviorSubject<any> = new BehaviorSubject(null);
    public matterDetailsTabs$: BehaviorSubject<any> = new BehaviorSubject(null);
    public SystemSettingUserTab$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ColorSystemSetting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public UpdatedColorSystemSetting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ColorSystemSettingHyperLink$: BehaviorSubject<any> = new BehaviorSubject(null);

    public SaveFamilyLawDetailFlag$: BehaviorSubject<any> = new BehaviorSubject(false);
    public SaveCriminalDetailFlag$: BehaviorSubject<any> = new BehaviorSubject(false);
    public clickMaterFromTimeLine$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(sessionStorage.getItem('SetclickMaterFromTimeLine')));

    public setDocumentEnvUrl$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setDocumentEnvUrlText$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setSessionToken$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setApiKey$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setDocumentFolder$: BehaviorSubject<any> = new BehaviorSubject(null);
    public setDocumentFileName$: BehaviorSubject<any> = new BehaviorSubject(null);
    public sendurlpath$: BehaviorSubject<any> = new BehaviorSubject(null);
    public bulkFlag$: BehaviorSubject<any> = new BehaviorSubject('');
    public bulkInvoiceWorkItemslist$: BehaviorSubject<any> = new BehaviorSubject(null);
    public SingleInvoiceData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public PassDataDashboardToSidebar$: BehaviorSubject<any> = new BehaviorSubject(null);
    public loaderDATA$: BehaviorSubject<any> = new BehaviorSubject(false);
    public InvoiceCreationStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    public CURRENCYSYMBOL$: BehaviorSubject<any> = new BehaviorSubject(localStorage.getItem('set_active_CURRENCYSYMBOL') !== undefined || 'undefined' ? JSON.parse(localStorage.getItem('set_active_CURRENCYSYMBOL')) : '');
    constructor(private http: HttpClient) { }

    /**
     * This function is used to set the firmDirectory data
     * @param firmData -firm directory data
     */
    openFirmDirecotry(firmData) {
        this.FirmDirecotryData$.next(firmData);
    }

    /**
     * This function is used to set the stopTimeInterval data
     * @param allow 
     */
    stopTimeInterval(allow: boolean) {
        this.stopTimeInterval$.next(allow);
    }

    /**
     * This function is used to set the startTimeInterval data
     * @param allow 
     */
    startInterval(allow: boolean) {
        this.startTimeInterval$.next(allow);
    }

    /**
     * This function is used to set the toolBaarSetting data
     * @param setting 
     */
    setToolBaarSetting(setting: any) {
        localStorage.setItem('tool_baar_settings', JSON.stringify(setting));
        this.toolBaarSetting$.next(setting);
    }

    /**
     * This function is used to set the CURRENCYSYMBOL data
     * @param setting 
     */
    setCURRENCYSYMBOL(setting: any) {
        this.CURRENCYSYMBOL$.next(setting);
    }

    /**
     * This function is used to set the permissionCheck data
     * @param permission 
     */
    setPermission(permission: any) {
        this.permissionCheck$.next(permission);
    }

    /**
     * This function is used to set the allowances data
     * @param allowancesData 
     */
    setAllowancesData(allowancesData: any) {
        localStorage.setItem('allowances_data', JSON.stringify(allowancesData));
        this.allowancesData$.next(allowancesData);
    }

    /**
     * This function is used to set the chequeStatment data
     * @param chequeStatment 
     */
    chequeStatment(chequeStatment: any) {
        localStorage.setItem('cheque_statment', JSON.stringify(chequeStatment));
        this.chequeStatment$.next(chequeStatment);
    }

    /**
     * This function is used to set the mtshare data
     * @param mtshareData 
     */
    mtshareData(mtshareData: any) {
        this.mtshareData$.next(mtshareData);
    }
    

    /**
     * This function is used to set the matterSerachFromQuickTimeEntry data
     * @param matterSerachFromQuickTimeEntry 
     */
    matterSerachFromQuickTimeEntry(matterSerachFromQuickTimeEntry: any) {
        this.matterSerachFromQuickTimeEntry$.next(matterSerachFromQuickTimeEntry);
    }

    /**
     * This function is used to set the courtForm data
     * @param courtFormData 
     */
    courtFormData(courtFormData: any) {
        this.courtFormData$.next(courtFormData);
    }

    precedentsStateData(precedentsStateData: any) {
        this.precedentsStateData$.next(precedentsStateData);
    }

    /**
     * This function is used to set the isbankStatment data
     * @param isbankStatmentDate 
     */
    isbankStatmentDate(isbankStatmentDate: any) {
        this.isbankStatmentDate$.next(isbankStatmentDate);
    }

    /**
     * This function is used to set the multiAccountStore data
     * @param multiAccountStore 
     */
    multiAccountStore(multiAccountStore: any) {
        this.multiAccountStore$.next(multiAccountStore);
    }

    /**
     * This function is used to set the isAccountMulti data
     * @param isAccountMulti 
     */
    isAccountMulti(isAccountMulti: any) {
        this.isAccountMulti$.next(isAccountMulti);
    }
    
    /**
     * This function is used to set the PastRecouncile data
     * @param PastRecouncileData 
     */
    PastRecouncileData(PastRecouncileData: any) {
        this.PastRecouncileData$.next(PastRecouncileData);
    }

    /**
     * This function is used to set the applicationVersion data
     * @param Version 
     */
    setApplicationVersion(Version: any) {
        this.applicationVersion$.next(Version);
    }

    /**
     * This function is used to set the reduceMatterContactCall data
     * @param reduceMatterContactCall 
     */
    reduceMatterContactCall(reduceMatterContactCall: any) {
        this.reduceMatterContactCall$.next(reduceMatterContactCall);
    }

    /**
     * This function is used to set the reduceReceiveMoneyCall data
     * @param ReceiveMoney 
     */
    reduceReceiveMoneyCall(ReceiveMoney: any) {
        this.reduceReceiveMoneyCall$.next(ReceiveMoney);
    }

    /**
     * This function is used to set the reduceReceiveMoneyCall data
     * @param SafeCustody 
     */
    reduceSafeCustodyCall(SafeCustody: any) {
        this.reduceReceiveMoneyCall$.next(SafeCustody);
    }

    /**
     * This function is used to set the templateSubFolder data
     * @param templateSubFolder 
     */
    templateSubFolder(templateSubFolder: any) {
        this.templateSubFolder$.next(templateSubFolder);
    }

    templateSubFolderHF(templateSubFolder: any) {
        this.templateSubFolderHF$.next(templateSubFolder);
    }

    /**
     * This function is used to set the SubFolder data
     * @param SubFolder 
     */
    folderSubFolder(SubFolder: any) {
        this.SubFolder$.next(SubFolder);
    }

    /**
     * This function is used to set the matterInvoice data
     * @param matterInvoice 
     */
    matterInvoiceData(matterInvoice: any) {
        this.matterInvoice$.next(matterInvoice);
    }

    /**
     * This function is used to set the CheckForCredits data
     * @param CheckForCredits 
     */
    isforCredit(CheckForCredits: any) {
        this.CheckForCredits$.next(CheckForCredits);
    }

    /**
     * This function is used to set the TrustBankingDuplicate data
     * @param TrustBankingDuplicate 
     */
    TrustBankingDuplicate(TrustBankingDuplicate: any) {
        this.TrustBankingDuplicate$.next(TrustBankingDuplicate);
    }

    /**
     * This function is used to set the loadingSystemSetting data
     * @param loadingSystemSetting 
     */
    loadingSystemSetting(loadingSystemSetting: any) {
        this.loadingSystemSetting$.next(loadingSystemSetting);
    }

    /**
     * This function is used to set the loadingAccountMNG data
     * @param loadingAccountMNG 
     */
    loadingAccountMNG(loadingAccountMNG: any) {
        this.loadingAccountMNG$.next(loadingAccountMNG);
    }

    /**
     * This function is used to set the activities data
     * @param activitiesData 
     */
    activitiesData(activitiesData: any) {
        this.activitiesData$.next(activitiesData);
    }

    /**
     * This function is used to set the Diary data
     * @param DiaryData 
     */
    DiaryData(DiaryData: any) {
        this.DiaryData$.next(DiaryData);
    }

    /**
     * This function is used to set the User data
     * @param UserData 
     */
    UserData(UserData: any) {
        this.UserData$.next(UserData);
    }

    /**
     * This function is used to set the contactData data
     * @param contactData 
     */
    contactData(contactData: any) {
        this.contactData$.next(contactData);
    }

    /**
     * This function is used to set the auditLogs data
     * @param auditLogs 
     */
    auditLogs(auditLogs: any) {
        this.auditLogs$.next(auditLogs);
    }

    /**
     * This function is used to set the CommonToolbarHS data
     * @param CommonToolbarHS 
     */
    CommonToolbarHS(CommonToolbarHS: any) {
        this.CommonToolbarHS$.next(CommonToolbarHS);
    }

    /**
     * This function is used to set the totalDashboard data
     * @param totalDashboard 
     */
    totalDashboard(totalDashboard: any) {
        this.totalDashboard$.next(totalDashboard);
    }

    /**
     * This function is used to set the TrustDuplicateModuleHandling data
     * @param TrustDuplicateModuleHandling 
     */
    TrustDuplicateModuleHandling(TrustDuplicateModuleHandling: any) {
        this.TrustDuplicateModuleHandling$.next(TrustDuplicateModuleHandling);
    }

    /**
     * This function is used to set the MatterNum data
     * @param MatterNum 
     */
    MatterNum(MatterNum: any) {
        this.MatterNum$.next(MatterNum);
    }

    /**
     * This function is used to set the dialogClose data
     * @param dialogClose 
     */
    dialogClose(dialogClose: any) {
        this.dialogClose$.next(dialogClose);
    }

    /**
     * This function is used to set the currentUser data
     * @param user 
     */
    setCuurentUser(user) {
        this.currentUser$.next(true);
    }

    /**
     * This function is used to set the ReceiptData data
     * @param ReceiptData 
     */
    ReceiptData(ReceiptData: any) {
        this.ReceiptData$.next(ReceiptData);
    }

    /**
     * This function is used to set the forUserActivity data
     * @param forUserActivity 
     */
    forUserActivity(forUserActivity: any) {
        this.forUserActivity$.next(forUserActivity);
    }

    /**
     * This function is used to set the forDiaryRefersh2 data
     * @param forDiaryRefersh2 
     */
    forDiaryRefersh2(forDiaryRefersh2: any) {
        this.forDiaryRefersh2$.next(forDiaryRefersh2);
    }

    /**
     * This function is used to set the forDiaryRefersh data
     * @param forDiaryRefersh 
     */
    forDiaryRefersh(forDiaryRefersh: any) {
        this.forDiaryRefersh$.next(forDiaryRefersh);
    }

    /**
     * This function is used to set the BankChartAccountStore data
     * @param BankChartAccountStore 
     */
    BankChartAccountStore(BankChartAccountStore: any) {
        this.BankChartAccountStore$.next(BankChartAccountStore);
    }

    /**
     * This function is used to set the RecouncileItemSendSetData data
     * @param RecouncileItemSendSetData 
     */
    RecouncileItemSendSetData(RecouncileItemSendSetData: any) {
        this.RecouncileItemSendSetData$.next(RecouncileItemSendSetData);
    }

    /**
     * This function is used to set the LegalAuthorityToolbar data
     * @param LegalAuthorityToolbar 
     */
    LegalAuthorityToolbar(LegalAuthorityToolbar: any) {
        this.LegalAuthorityToolbar$.next(LegalAuthorityToolbar);
    }

    /**
     * This function is used to set the LegalAuthorityForSubAuthToolbar data
     * @param LegalAuthorityForSubAuthToolbar 
     */
    LegalAuthorityForSubAuthToolbar(LegalAuthorityForSubAuthToolbar: any) {
        this.LegalAuthorityForSubAuthToolbar$.next(LegalAuthorityForSubAuthToolbar);
    }

    /**
     * This function is used to set the LegalAuthorityData data
     * @param LegalAuthorityData 
     */
    LegalAuthorityData(LegalAuthorityData: any) {
        this.LegalAuthorityData$.next(LegalAuthorityData);
    }

    /**
     * This function is used to set the MainTopicData data
     * @param MainTopicData 
     */
    MainTopicData(MainTopicData: any) {
        this.MainTopicData$.next(MainTopicData);
    }


    /**
     * This function is used to set the preserveMatterSidePenalAction data
     * @param val 
     */
    SetpreserveMatterSidePenalAction(val: any) {
        this.preserveMatterSidePenalAction$.next(val);
    }

    /**
     * This function is used to set the matterClassData data
     * @param matterClassData 
     */
    matterClassData(matterClassData: any) {
        this.matterClassData$.next(matterClassData);
    }

    /**
     * This function is used to set the MainAuthorityData data
     * @param MainAuthorityData 
     */
    MainAuthorityData(MainAuthorityData: any) {
        this.MainAuthorityData$.next(MainAuthorityData);
    }

    /**
     * This function is used to set the MainTimeEntryData data
     * @param MainTimeEntryData 
     */
    MainTimeEntryData(MainTimeEntryData: any) {
        this.MainTimeEntryData$.next(MainTimeEntryData);
    }

    /**
     * This function is used to set the ConflictDataList data
     * @param ConflictDataList 
     */
    ConflictDataList(ConflictDataList: any) {
        this.ConflictDataList$.next(ConflictDataList);
    }

    /**
     * This function is used to set the LegalChronologyData data
     * @param LegalChronologyData 
     */
    LegalChronologyData(LegalChronologyData: any) {
        this.LegalChronologyData$.next(LegalChronologyData);
    }

    /**
     * This function is used to set the ConflictMainData data
     * @param ConflictMainData 
     */
    ConflictMainData(ConflictMainData: any) {
        this.ConflictMainData$.next(ConflictMainData);
    }

    /**
     * This function is used to set the ActiveSubMenu data
     * @param activeSubMenu 
     */
    SetActiveSubMenu(activeSubMenu: any) {
        this.ActiveSubMenu$.next(activeSubMenu);
    }

    /**
     * This function is used to set the FileNotesData data
     * @param FileNotesData 
     */
    FileNotesData(FileNotesData: any) {
        this.FileNotesData$.next(FileNotesData);
    }

    /**
     * This function is used to set the UserDropDownData data
     * @param UserDropDownData 
     */
    UserDropDownData(UserDropDownData: any) {
        this.UserDropDownData$.next(UserDropDownData);
    }

    /**
     * This function is used to set the SysytemAccountData data
     * @param SysytemAccountData 
     */
    SysytemAccountData(SysytemAccountData: any) {
        this.SysytemAccountData$.next(SysytemAccountData);
    }

    /**
     * This function is used to set the SysytemAccountDIalogData data 
     * @param SysytemAccountDIalogData 
     */
    SysytemAccountDIalogData(SysytemAccountDIalogData: any) {
        this.SysytemAccountDIalogData$.next(SysytemAccountDIalogData);
    }

    /**
     * This function is used to set the workInProgress data
     * @param workInProgressData 
     */
    setworkInProgressData(workInProgressData: any) {
        this.workInProgress$.next(workInProgressData);
    }

    /**
     * This function is used to set the packs data
     * @param d 
     */
    packsitems(d) {
        this.packs$.next(d);
    }

    /**
     * This function is used to set the EmailGenerateData data
     * @param d 
     */
    EmailGenerateData(d) {
        this.EmailGenerateData$.next(d);
    }

    /**
     * This function is used to set the TemplateGenerateData data
     * @param d 
     */
    TemplateGenerateData(d) {
        this.TemplateGenerateData$.next(d);
    }

    TemplateGenerateDataHF(d) {
        this.TemplateGenerateDataHF$.next(d);
    }

    /**
     * This function is used to set the setDocumentPath data
     * @param d 
     */
    setDocumentPathData(d) {
        this.setDocumentPath$.next(d);
    }

    /**
     * This function is used to set the setDocumentEnvUrl data
     * @param d 
     */
    setDocumentEnvUrl(d) {
        this.setDocumentEnvUrl$.next(d);
    }

    /**
     * This function is used to set the setDocumentEnvUrlText data
     * @param d 
     */
    setDocumentEnvUrlText(d) {
        this.setDocumentEnvUrlText$.next(d);
    }

    /**
     * This function is used to set the setSessionToken data
     * @param d 
     */
    setSessionToken(d) {
        this.setSessionToken$.next(d);
    }

    /**
     * This function is used to set the setApiKey data
     * @param d 
     */
    setApiKey(d) {
        this.setApiKey$.next(d);
    }

    /**
     * This function is used to set the setDocumentFolder data
     * @param d 
     */
    setDocumentPathFolder(d) {
        this.setDocumentFolder$.next(d);
    }

    /**
     * This function is used to set the setDocumentFileName data
     * @param d 
     */
    setDocumentPathFilaname(d) {
        this.setDocumentFileName$.next(d);
    }


    /**
     * This function is used to set the DocumentRegisterData data
     * @param d 
     */
    DocumentRegisterData(d) {
        this.DocumentRegisterData$.next(d);
    }

    /**
     * This function is used to set the SpendMoneyData data
     * @param d 
     */
    SpendMoneyData(d) {
        this.SpendMoneyData$.next(d);
    }

    /**
     * This function is used to set the ChartAccountData data
     * @param d 
     */
    ChartAccountData(d) {
        let BankingData=JSON.parse(localStorage.getItem('BankingDialogDefaultData'))
        this.ChartAccountData$.next((d && d != undefined && d != null) ? d :(BankingData && BankingData !=undefined && BankingData !=null) ? BankingData : "");
    }

    /**
     * This function is used to set the AccountTypeData data
     * @param d 
     */
    AccountTypeData(d) {
        localStorage.setItem('ChartAccountTypeData',JSON.stringify(d));
        this.AccountTypeData$.next(d);
    }

    /**
     * This function is used to set the ChartAccountDataEdit data
     * @param d 
     */
    setChartAccountDataEdit(d) {
        localStorage.setItem('setChartAccountDataEdit', JSON.stringify(d));
        this.ChartAccountDataEdit$.next(d);
    }

    /**
     * This function is used to set the MatterData data
     * @param d 
     */
    MatterData(d) {
        this.MatterData$.next(d);
    }

    /**
     * This function is used to set the ToggleForUpadteFavourite data
     * @param d 
     */
    ToggleForUpadteFavouriteData(d) {
        localStorage.setItem('testBoolFavourite', JSON.stringify(d));
        this.ToggleForUpadteFavourite$.next(d)
    }

    /**
     * This function is used to set the SetToggleFirmDashboardData data
     * @param d 
     */
    SetToggleFirmDashboardData(d) {
        localStorage.setItem('EditFirmDashBoard', JSON.stringify(d));
        this.ToggleFirmDashboardData$.next(d)
    }

    /**
     * This function is used to set the TaskData data
     * @param d 
     */
    TaskData(d) {
        this.TaskData$.next(d);
    }

    /**
     * This function is used to set the calanderViewType data
     * @param d 
     */
    setCalanderViewType(d) {
        this.calanderViewType$.next(d);
    }

    /**
     * This function is used to set the UseCalanderViewType data
     * @param d 
     */
    UseCalanderViewType(d) {
        this.UseCalanderViewType$.next(d);
    }

    /**
     * This function is used to set the TimeScale data
     * @param d 
     */
    setTimeScale(d) {
        this.TimeScale$.next(d);
    }

    /**
     * This function is used to set the MatterEditData data
     * @param d 
     */
    setMatterEditData(d) {
        this.MatterEditData$.next(d);
    }

    /**
     * This function is used to set the estimatelegalData data
     * @param estimatelegalData 
     */
    estimatelegalData(estimatelegalData: any) {
        this.estimatelegalData$.next(estimatelegalData);
    }

    /**
     * This function is used to set the deliverableData data
     * @param deliverableData 
     */
    deliverableData(deliverableData: any) {
        this.deliverableData$.next(deliverableData);
    }

    /**
     * This function is used to set the matterApplicationData data
     * @param matterappointmentData 
     */
    setMatterApplicationData(matterappointmentData: any) {
        this.matterApplicationData$.next(matterappointmentData);
    }

    /**
     * This function is used to set the AWAITING2FA data
     * @param AWAITING2FA 
     */
    setAWAITING2FA(AWAITING2FA: any) {
        localStorage.setItem('AWAITING2FA', AWAITING2FA)
        this.AWAITING2FA$.next(AWAITING2FA);
    }

    /**
     * This function is used to set the ActivityData data
     * @param ActivityData 
     */
    ActivityData(ActivityData: any) {
        this.ActivityData$.next(ActivityData);
    }

    /**
     * This function is used to set the GeneralData data
     * @param GeneralData 
     */
    setGeneralData(GeneralData: any) {
        this.GeneralData$.next(GeneralData);
    }

    /**
     * This function is used to set the SafeCustody data
     * @param SafeCustody 
     */
    SafeCustody(SafeCustody: any) {
        this.SafeCustody$.next(SafeCustody);
    }

    /**
     * This function is used to set the Packets data
     * @param Packets 
     */
    Packets(Packets: any) {
        this.Packets$.next(Packets);
    }

    /**
     * This function is used to set the RecouncileConstName data
     * @param RecouncileConstName 
     */
    RecouncileConstName(RecouncileConstName: any) {
        localStorage.setItem('RecouncileConstName', RecouncileConstName);
        this.RecouncileConstName$.next(RecouncileConstName);
    }

    /**
     * This function is used to set the resizeTableForAllView data
     */
    resizeTableForAllView() {
        //default 130 px
        let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
        $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 100)) + 'px');
    }

    /**
     * This function is used to set the resizeTableForAllViewForSub data
     */
    resizeTableForAllViewForSub() {
        $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 250)) + 'px');
    }

    /**
     * This function is used to set the DelGloballypopupHS data
     * @param DelGloballypopupHS 
     */
    DelGloballypopupHS(DelGloballypopupHS: any) {
        this.DelGloballypopupHS$.next(DelGloballypopupHS);
    }

    /**
     * This function is used to set the TrustMoneyList data
     * @param Row 
     */
    setTrustMoneyList(Row: any) {
        this.TrustMoneyList$.next(Row);
    }

    /**
     * This function is used to set the ProtectedTrustList data
     * @param Row 
     */
    setProtectedTrustList(Row: any) {
        this.ProtectedTrustList$.next(Row);
    }

    /**
     * This function is used to set the ReceiveMoneyList data
     * @param Row 
     */
    setReceiveMoneyList(Row: any) {
        this.ReceiveMoneyList$.next(Row);
    }

    /**
     * This function is used to set the TrustEndofMonth data
     * @param TrustEnd 
     */
    setTrustEndofMonth(TrustEnd: any) {
        this.TrustEndofMonth$.next(TrustEnd);
    }

    /**
     * This function is used to set the activeTab data
     * @param tabId 
     */
    setactiveTab(tabId: any) {
        this.activeTab$.next(tabId);
    }

    /**
     * This function is used to set the activeTabName data
     * @param tabName 
     */
    setactiveTabName(tabName: any) {
        this.activeTabName$.next(tabName);
    }

    /**
     * This function is used to set the serachingData data
     * @param row 
     */
    setSerachingData(row: any) {
        this.serachingData$.next(row);
    }

    /**
     * This function is used to set the APIretryCall data
     * @param data 
     */
    setAPIretryCall(data: any) {
        this.APIretryCall$.next(data);
    }

    /**
     * This function is used to set the EndOfMonth data
     * @param data 
     */
    setEndOfMonth(data: any) {
        this.EndOfMonth$.next(data);
    }

    /**
     * This function is used to set the EndOfMonthData data
     * @param data 
     */
    setEndOfMonthData(data: any) {
        localStorage.setItem('end_of_month_data', JSON.stringify(data));
        this.EndOfMonthData$.next(data);
    }

    /**
     * This function is used to set the EndOfMonthDataSelected data
     * @param data 
     */
    setEndOfMonthDataSelected(data: any) {
        this.EndOfMonthDataSelected$.next(data);
    }

    /**
     * This function is used to set the statementClosingBalance data
     * @param data 
     */
    SetstatementClosingBalance(data: any) {
        this.statementClosingBalance$.next(data);
    }

    /**
     * This function is used to set the collectSelectedInvoice data
     * @param data 
     */
    collectSelectedInvoice(data: any) {
        this.collectSelectedInvoice$.next(data);
    }


    /**
     * This function is used to set the collectSelectedRecovery data
     * @param data 
     */
    collectSelectedRecovery(data: any) {
        this.collectSelectedRecovery$.next(data);
    }

    /**
     * This function is used to set the EjsDropDownTreeData data
     * @param data 
     */
    setEjsDropDownTreeData(data: any) {
        this.EjsDropDownTreeData$.next(data);
    }

    /**
     * This function is used to set the appPermissionsSetting data
     * @param Permissions 
     * 
     */
    setAppPermissionsSetting(Permissions: any) {
        this.appPermissionsSetting$.next(Permissions);
    }

    /**
     * This function is used to set the accessTierPermission data
     * @param Permissions 
     */
    setaccessTierPermission(Permissions: any) {
        this.accessTierPermission$.next(Permissions);
    }

    /**
     * This function is used to set the recounciliationItem data
     * @param data 
     */
    setRecounciliationItem(data: any) {
        this.recounciliationItem$.next(data);
    }

    /**
     * This function is used to set the ChildTableData data
     * @param data 
     */
    setChildTableData(data: any) {
        this.ChildTableData$.next(data);
    }

    /**
     * This function is used to set the ChildContactTableData data
     * @param data 
     */
    setChildContactTableData(data: any) {
        this.ChildContactTableData$.next(data);
    }

    /**
     * This function is used to set the MatterLoding data
     * @param data 
     */
    setMatterLoding(data: any) {
        this.MatterLoding$.next(data);
    }

    /**
     * This function is used to set the DefultMatterData data
     * @param Matterdata 
     */
    SetDefultMatterData(Matterdata: any) {
        this.DefultMatterData$.next(Matterdata);
    }

    /**
     * This function is used to set the DocumentRegisterFolderClickData data
     * @param FolderDataArray 
     */
    SetDocumentRegisterFolderClickData(FolderDataArray: any) {
        this.DocumentRegisterFolderClickData$.next(FolderDataArray);
    }

    /**
     * This function is used to set the NotificationCall data
     * @param data 
     */
    SetNotificationCall(data: boolean) {
        this.NotificationCall$.next(data);
    }

    /**
     * This function is used to set the defultPreferencesData data
     * @param data 
     */
    setDefultPreferencesData(data: any) {
        this.defultPreferencesData$.next(data)
    }

    /**
     * This function is used to set the SelectedEmailList data
     * @param selected 
     */
    setSelectedEmailList(selected: any[]) {
        this.SelectedEmailList$.next(selected);
    }

    /**
     * This function is used to set the selectCustomeEmailList data
     * @param val 
     */
    setSelectCustomeEmailList(val: any) {
        this.selectCustomeEmailList$.next(val);
    }

    /**
     * This function is used to set the nextReCalcTimeEntrie data
     * @param val 
     */
    setnextReCalcTimeEntrie(val) {
        this.nextReCalcTimeEntrie$.next(val);
    }

    /**
     * This function is used to set the PrintBankingSlip data
     * @param val 
     */
    setPrintBankingSlip(val: any) {
        this.PrintBankingSlip$.next(val);
    }

    /**
     * This function is used to set the estateAssets data
     * @param estateAssets 
     */
    setEstateAssets(estateAssets: any) {
        localStorage.setItem('estate_assets', JSON.stringify(estateAssets));
        this.estateAssets$.next(estateAssets);
    }

    /**
     * This function is used to set the estateBeneficiary data
     * @param beneficiary 
     */
    setEstateBeneficiary(beneficiary: any) {
        localStorage.setItem('estate_beneficiary', JSON.stringify(beneficiary));
        this.estateBeneficiary$.next(beneficiary);
    }

    /**
     * This function is used to set the setEstateLiability data
     * @param estateliability 
     */
    setEstateLiability(estateliability: any) {
        localStorage.setItem('estate_liability', JSON.stringify(estateliability));
        this.estateliability$.next(estateliability);
    }

    /**
     * This function is used to set the generateInvoiceSearch data
     * @param searchInput 
     */
    generateInvoiceSearch(searchInput: string) {
        this.generateInvoiceSearch$.next(searchInput);
    }

    /**
     * This function is used to set the additionalFieldsValues data
     * @param data 
     */
    additionalFieldsValues(data: any) {
        this.additionalFieldsValues$.next(data)
    }

    /**
     * This function is used to set the matterChangeValues data
     * @param data 
     */
    matterChangeValues(data: any) {
        this.matterDataChangeValuesSource.next(data)
    }

    /**
     * This function is used to set the setFavouriteItem data
     * @param favouriteItems 
     */
    setFavouriteItem(favouriteItems) {
        this.favouriteItems$.next(favouriteItems);
    }

    /**
     * This function is used to set the setMenuFoldUnfold data
     * @param isFold 
     */
    setMenuFoldUnfold(isFold) {
        if (isFold != null) {
            this.isMenuFolded$.next(isFold);
        }
    }

    /**
     * This function is used to set the SetclossAllBrowser data
     * @param data 
     */
    SetclickMaterFromTimeLine(data: any) {
       // localStorage.setItem('SetclickMaterFromTimeLine', JSON.stringify(data));
        this.clickMaterFromTimeLine$.next(data)
    }

    /**
     * This function is used to set the SetclossAllBrowser
     * @param isClose 
     */
    SetclossAllBrowser(isClose) {
        this.clossAllBrowser$.next(isClose);
    }

    /**
     * This function is used to set the setTimerRefreshObject data
     * @param isnew 
     */
    setTimerRefreshObject(isnew: boolean) {
        this.timerRefreshObject$.next(isnew);
    }

    /**
     * This function is used to set the setDefultFeeErnery data
     * @param defultfree 
     */
    setDefultFeeErnerData(defultfree: any) {
        this.defultFeeErnerData$.next(defultfree);
    }

    /**
     * This function is used to set setTimerCount Data
     */
    setTimerCountData() {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.timerId = 'timer_' + currentUser.UserGuid;
        }
        let prevMatterArray = JSON.parse(localStorage.getItem(this.timerId));
        prevMatterArray = (prevMatterArray) ? prevMatterArray.length : 0;
        this.TimerCountData$.next(prevMatterArray);
    }

    /**
     * This function is used to set the usersMenu data
     * @param menuName 
     */
    setUserMenuName(menuName) {
        this.usersMenu$.next(menuName);
    }

    /**
     * This function is used to set the loadingUserMenuResult data
     * @param data 
     */
    loadingUserMenuResult(data) {
        this.loadingUserMenuResult$.next(data)
    }

    /**
     * This function is used to set the openDifferentTab data
     * @param data 
     */
    openDifferentTab(data) {
        this.openDifferentTab$.next(data)
    }

    /**
     * This function is used to set the appointmentRow data
     * @param data 
     */

    appointmentRowData(data) {
        this.appointmentRowData$.next(data)
    }

    /**
     * This function is used to set the refreshDiary data
     * @param data 
     */
    refreshDiaryData(data) {
        this.refreshDiaryData$.next(data);
    }

    /**
     * This function is used to set the chartupdate data
     * @param data 
     */
    setchartupdate(data) {
        this.chartupdate$.next(data);
    }

    /**
     * This function is used to set the setRightClick data
     * @param data 
     */
    setRightClick(data) {
        this.RightClick$.next(data)
    }

}
