<div class=" one " fxLayout="column" [formGroup]="MatterConveyancingDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PURCHASEPRICE,'has-warning':errorWarningData.Warning?.PURCHASEPRICE}">
            <mat-label>Purchase Price</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('PURCHASEPRICE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.PURCHASEPRICE.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PURCHASEPRICE" (focusout)="calcBalance()" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.PURCHASEPRICE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PURCHASEPRICE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PURCHASEPRICE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DEPOSITTYPE,'has-warning':errorWarningData.Warning?.DEPOSITTYPE}">
            <mat-label>Deposit Type</mat-label>
            <mat-select matTooltip="{{(toolTipList)?toolTipList.DEPOSITTYPE.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DEPOSITTYPE">
                <mat-option *ngFor="let optionval of DepositType" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
             <div  *ngIf="testBool && toolTipList.DEPOSITTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DEPOSITTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEPOSITTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.INITIALDEPOSIT,'has-warning':errorWarningData.Warning?.INITIALDEPOSIT}">
            <mat-label>Initial Deposit</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('INITIALDEPOSIT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.INITIALDEPOSIT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="INITIALDEPOSIT" (focusout)="calcBalance()" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.INITIALDEPOSIT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INITIALDEPOSIT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INITIALDEPOSIT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BALANCEDEPOSIT,'has-warning':errorWarningData.Warning?.BALANCEDEPOSIT}">
            <mat-label>Balance Deposit</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('BALANCEDEPOSIT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.BALANCEDEPOSIT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BALANCEDEPOSIT" (focusout)="calcBalance()" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.BALANCEDEPOSIT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BALANCEDEPOSIT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BALANCEDEPOSIT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BALANCEDEPOSITDATE,'has-warning':errorWarningData.Warning?.BALANCEDEPOSITDATE}">
            <mat-label>Balance of Deposit Due</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'BALANCEDEPOSITDATE')" matTooltip="{{(toolTipList)?toolTipList.BALANCEDEPOSITDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BALANCEDEPOSITDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'BALANCEDEPOSITDATE')" matInput name="BalanceofDepositDue" [matDatepicker]="BalanceofDepositDue">
            <mat-datepicker-toggle matSuffix [for]="BalanceofDepositDue"></mat-datepicker-toggle>
            <mat-datepicker #BalanceofDepositDue></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.BALANCEDEPOSITDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BALANCEDEPOSITDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BALANCEDEPOSITDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DEPOSITBONDAMOUNT,'has-warning':errorWarningData.Warning?.DEPOSITBONDAMOUNT}">
            <mat-label>Deposit Bond</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('DEPOSITBONDAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.DEPOSITBONDAMOUNT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DEPOSITBONDAMOUNT" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.DEPOSITBONDAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DEPOSITBONDEXPIRYDATE,'has-warning':errorWarningData.Warning?.DEPOSITBONDEXPIRYDATE}">
            <mat-label>Deposit Bond Expiry Date</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'DEPOSITBONDEXPIRYDATE')" matTooltip="{{(toolTipList)?toolTipList.DEPOSITBONDEXPIRYDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DEPOSITBONDEXPIRYDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'DEPOSITBONDEXPIRYDATE')" matInput name="DepositBondExpiryDate" [matDatepicker]="DepositBondExpiryDate">
            <mat-datepicker-toggle matSuffix [for]="DepositBondExpiryDate"></mat-datepicker-toggle>
            <mat-datepicker #DepositBondExpiryDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DEPOSITBONDEXPIRYDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DEPOSITBONDEXPIRYDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEPOSITBONDEXPIRYDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DEPOSITAMOUNT,'has-warning':errorWarningData.Warning?.DEPOSITAMOUNT}">
            <mat-label>Deposit Amount</mat-label>
            <input matInput  style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('DEPOSITAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.DEPOSITAMOUNT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DEPOSITAMOUNT" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.DEPOSITAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DEPOSITAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEPOSITAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BALANCEVAL,'has-warning':errorWarningData.Warning?.BALANCEVAL}">
            <mat-label>Balance</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('BALANCEVAL').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" matTooltip="{{(toolTipList)?toolTipList.BALANCEVAL?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BALANCEVAL" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.BALANCEVAL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BALANCEVAL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BALANCEVAL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.GST,'has-warning':errorWarningData.Warning?.GST}">
            <mat-label>GST</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('GST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.GST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="GST" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.GST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.GST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OTHERCONSIDERATION,'has-warning':errorWarningData.Warning?.OTHERCONSIDERATION}">
            <mat-label>Other Consideration</mat-label>
            <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('OTHERCONSIDERATION').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.OTHERCONSIDERATION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="OTHERCONSIDERATION" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.OTHERCONSIDERATION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OTHERCONSIDERATION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERCONSIDERATION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>