import { Component, OnInit, Input, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { round } from 'lodash';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSelectPopupComponent } from '../../user-select-popup/user-select-popup.component';
import { MatterPopupComponent } from '../matter-popup.component';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { Subject, Subscription } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() matterdetailForm: FormGroup;
    //matterdetailForm: FormGroup;
    @Input() userType: any;
    @Input() errorWarningData: any;
    @Input() toolTipList: any;
    @Input() testBool;
    PRICEVAL: any;
    PRICEVALGST: any;
    MAXPRICEVALGST: any;
    MAXPRICEVAL: any;
    isDefultMatter: boolean;
    AllowEstimateRange: boolean;
    MatterStatusLookupsData: any[];
    MatterDropData: any[] = [];
    SECURITYOPTION;
    isNoneSelect: boolean = false
    //use for tier permission
    accessTierPermission: any = JSON.parse(localStorage.getItem('set_tier_permission'));
    matterAccessTier: any;
    openWebsite: any;
    toolTipPostion = "above";
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;


    constructor(
        public datepipe: DatePipe,
        public MatDialog: MatDialog,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private Timersservice: TimersService,
        private sanitizer: DomSanitizer,
        public tooltipService: TooltipService
    ) {
        this.matterAccessTier = this.getPermission('Matter Access');
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        this.sub1 = this.matterdetailForm.valueChanges.subscribe(() => {
            let doc = document.getElementById("notesData");
            if (doc) {
                doc.style.height = 'auto';
                doc.style.height = doc.scrollHeight + 'px';
            }
        })

    }
    CommencementDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['DATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    DueDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['DUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    CostsAgreementDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['FEEAGREEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    CompletedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['COMPLETEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        let MatterStatusLookups = JSON.parse(localStorage.getItem('matter_status_lookups'));
        if (MatterStatusLookups && MatterStatusLookups != null) {
            this.MatterStatusLookupsData = MatterStatusLookups;
            // this.isLoadingResults = false;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Matter Status' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.MatterStatusLookupsData = responses.DATA.LOOKUPS;
                    localStorage.setItem('matter_status_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                } else if (responses.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
                // this.isLoadingResults = false;
            });
        }
        this.AllowEstimateRange = JSON.parse(localStorage.getItem('currentUser')).ALLOWESTIMATERANGE;
        this.getDropValue();
    }
    selectMatter() {
        const dialogRef = this.MatDialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                this.matterdetailForm.controls['OWNERGUID'].setValue(result.USERGUID);
                this.matterdetailForm.controls['OWNERNAME'].setValue(result.FULLNAME);
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.CoommonMatterNum();
                }
            }
        });
    }
    getDropValue() {
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.MatterDropData = this.Timersservice.removeUSerWithoutId(userList);
        } else {
            let d = {};
            this.Timersservice.GetUsers(d).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.MatterDropData = this.Timersservice.removeUSerWithoutId(response.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(response.DATA.USERS));
                }
            }, err => {
                console.log(err);
            });
        }
    }
    get f() {
        return this.matterdetailForm.controls;
    }

    //MATTER for Matter Description textarea
    clientReferenceFunction() {
        var copyText: any = document.getElementById("clientReferenceInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    otherRefFunction() {
        var copyText: any = document.getElementById("otherRefInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    matterStatusFunction() {
        var copyText: any = document.getElementById("matterStatusInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    matterOwnerFunction() {
        var copyText: any = document.getElementById("matterOwnerInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    estimateMaximumExGSTFunction() {
        var copyText: any = document.getElementById("estimateMaximumExGSTInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    estimateMinimumIncGSTFunction() {
        var copyText: any = document.getElementById("estimateMinimumIncGSTInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    estimateMinimumExGSTFunction() {
        var copyText: any = document.getElementById("estimateMinimumExGSTInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    EstimateMaximumIncGSTFunction() {
        var copyText: any = document.getElementById("EstimateMaximumIncGSTInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    notesFunction() {
        var copyText: any = document.getElementById("notesInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    tickUsersFunction() {
        var copyText: any = document.getElementById("tickUsersInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    personAssistingFunction() {
        var copyText: any = document.getElementById("personAssistingInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    primaryFeeEarnerFunction() {
        var copyText: any = document.getElementById("primaryFeeEarnerInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    lastReviewDateFunction() {
        var copyText: any = document.getElementById("lastReviewDateInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    costAgrRecdFunction() {
        var copyText: any = document.getElementById("costAgrRecdInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    costsAgreementDateFunction() {
        var copyText: any = document.getElementById("costsAgreementDateInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    completedDateFunction() {
        var copyText: any = document.getElementById("completedDateInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    dueDateFunction() {
        var copyText: any = document.getElementById("dueDateInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    commencementDateFunction() {
        var copyText: any = document.getElementById("commencementDateInput");
        copyText.select();
        copyText.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(copyText.value);
    }

    selectFeeEarner() {
        const dialogRef = this.MatDialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].setValue(result.USERGUID);
                this.matterdetailForm.controls['PRIMARYFEEEARNERNAME'].setValue(result.FULLNAME);
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.CoommonMatterNum();
                }
            }
        });
    }
    MatterUserChange(value) {
        if (value && value.includes("")) {
            if (value.length > 1) {
                if (this.isNoneSelect) {
                    const none = ['']
                    this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(none)
                    this.isNoneSelect = false;
                } else {
                    if (value.includes("all")) {
                        const array = []
                        this.MatterDropData.forEach((user: any) => {
                            array.push(user.USERGUID)
                        })
                        array.push("all");
                        this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(array);
                        this.isNoneSelect = true;
                    } else {
                        var filteredAry = value.filter(e => e !== '');
                        this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(filteredAry);
                        this.isNoneSelect = true;
                    }
                }
            } else {
                const none = ['']
                this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(none)
                this.isNoneSelect = true;
            }
        } else {
            if (value.includes("all")) {
                const array = []
                this.MatterDropData.forEach((user: any) => {
                    array.push(user.USERGUID)
                })
                array.push("all");
                this.matterdetailForm.controls['MATTERACCESSLIST'].setValue(array);
                this.isNoneSelect = true;
            }
        }
    }
    selectPersonAssist() {
        const dialogRef = this.MatDialog.open(UserSelectPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                this.matterdetailForm.controls['ASSISTANTGUID'].setValue(result.USERGUID);
                this.matterdetailForm.controls['ASSISTANTNAME'].setValue(result.FULLNAME);
            }
        });
    }
    calcPE(type) {
        if (type == 'min') {
            this.PRICEVALGST = this.f.ESTIMATEFROMTOTALEXGST.value * 1.1;
        } else {
            this.MAXPRICEVALGST = this.f.MAXESTIMATEFROMTOTALEXGST.value * 1.1;
        }
    }
    calcPI(type) {
        if (type == 'min') {
            this.PRICEVAL = this.f.ESTIMATEFROMTOTALINCGST.value / 1.1;
        } else {
            this.MAXPRICEVAL = this.f.MAXESTIMATEFROMTOTALINCGST.value / 1.1;
        }
    }

    Costagrpicker(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['FEEAGREEMENTRECEIVEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    LastReviewpicker(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['LASTREVIEWDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    CoommonMatterNum() {
        let ownerGuid = this.matterdetailForm.controls['OWNERGUID'].value;
        let FeeEanerGuid = this.matterdetailForm.controls['PRIMARYFEEEARNERGUID'].value;
        let FirmGuid = this.matterdetailForm.controls['FIRMGUID'].value;
        this._mainAPiServiceService.getSetData({ Action: 'default', VALIDATEONLY: true, DATA: { FIRMGUID: FirmGuid, OWNERGUID: ownerGuid, PRIMARYFEEEARNERGUID: FeeEanerGuid } }, 'matter').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                const Matterdata = res.DATA.DEFAULTVALUES;
                if (this._data.action != 'duplicate') {
                    this.matterdetailForm.patchValue({
                        SHORTNAME: !this.matterdetailForm.controls['SHORTNAME'].value ? Matterdata['SHORTNAME'] : this.matterdetailForm.controls['SHORTNAME'].value,
                        GSTTYPE: Matterdata.BILLINGGROUP['GSTTYPE'],
                        BILLINGMETHOD: Matterdata.BILLINGGROUP['BILLINGMETHOD'],
                        ONCHARGEDISBURSEMENTGST: Matterdata.BILLINGGROUP['ONCHARGEDISBURSEMENTGST'],
                        RATEPERHOUR: round(Matterdata.BILLINGGROUP['RATEPERHOUR']).toFixed(2),
                        RATEPERDAY: round(Matterdata.BILLINGGROUP['RATEPERDAY']).toFixed(2)
                    });
                } else {
                    this.matterdetailForm.patchValue({
                        SHORTNAME: !this.matterdetailForm.controls['SHORTNAME'].value ? Matterdata['SHORTNAME'] : this.matterdetailForm.controls['SHORTNAME'].value,
                    });
                }
            } else if (res.MESSAGE === 'Not logged in') {
                this.dialogRef.close(false);
            } else {
                this.matterdetailForm.controls['SHORTNAME'].setValue(res.DATA.DEFAULTVALUES['SHORTNAME']);
            }
        }, error => { this.toastr.error(error); });
    }

    // get permission.
    getPermission(permission) {
        return this.accessTierPermission.filter(e => e.FEATURENAME == permission)[0];
    }
    goTo(url) {
        if (url) {
            this.openWebsite = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('http://', 'https://'))
        } else {
            this.openWebsite = undefined
        }

        if (this.openWebsite) {
            window.open(this.openWebsite.changingThisBreaksApplicationSecurity, "_blank");
        }
    }

    readOnlyCheckbox() {
        return false;
    }

    onKeyPressTextMessage(event) {
        var textArea = event.currentTarget;
        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    };
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        //  navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);

        navigator.clipboard.writeText(event)
    }
}
