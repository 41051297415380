import { BehaviorService } from '@_services/Behavior.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { isEqual } from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-multi-invoice-popup-status',
  templateUrl: './multi-invoice-popup-status.component.html',
  styleUrls: ['./multi-invoice-popup-status.component.scss'],
})
export class MultiInvoicePopupStatusComponent implements OnInit {
  multuyInvoiceData: any=[];
  count=0;
  private unsubscribe$ = new Subject<void>();
  public dialogRef: MatDialogRef<MultiInvoicePopupStatusComponent>
  constructor(private behaviourService:BehaviorService) {
    
    // this.behaviourService.InvoiceCreationStatus$.subscribe(data=>{
    //   if(data != null){
    //     this.multuyInvoiceData=data;
    //     console.log(data,data.length);
    //     data.forEach((element,index) => {
    //       if(element.INVOICECODE !=null){
    //       this.count++
    //       }
    //     });

    //   }else{
    //     this.multuyInvoiceData=[];
    //   }
    // });

    this.behaviourService.InvoiceCreationStatus$
    .pipe(
      tap(data => console.log('Received data from Observable:', data)), // Check data received
      takeUntil(this.unsubscribe$)
    )
    .subscribe(data => {
      if (data != null) {
        this.multuyInvoiceData = data;
        this.count = 0; // Reset count before calculation
        data.forEach((element) => {
          if (element.INVOICECODE != null) {
            this.count += 1;
          }
        });
      } else {
        this.multuyInvoiceData = [];
      }
    });

   }

  ngOnInit(): void {
  }

  /**
   * This function is used to Destroy the session
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
