<div class="one rate_tab_main" fxLayout="column">
    <form name="matterLeasingForm" [formGroup]="matterLeasingForm" id="matterLeasingForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
        <mat-accordion class="faqs-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>General</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-general-leasing [testBool]='testBool' [matterLeasingForm]="matterLeasingForm" [toolTipList]="toolTipList" ></app-general-leasing>

            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Rent</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-rent-leasing [testBool]='testBool' [matterLeasingForm]="matterLeasingForm" [toolTipList]="toolTipList"></app-rent-leasing>


            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Future Years</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-future-years-leasing [testBool]='testBool'  [toolTipList]="toolTipList" [matterLeasingForm]="matterLeasingForm"></app-future-years-leasing>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Rent Review</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-rent-review-leasing [testBool]='testBool' [toolTipList]="toolTipList" [matterLeasingForm]="matterLeasingForm"></app-rent-review-leasing>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>