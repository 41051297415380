<div class="popup_main_div licence_aggreement_main add_report_main">
    <div class="popup_title_div popup_title_divwrap display-flex" mat-dialog-title>
        <h2  *ngIf="isLicence=='LI'">
            SILQ End User Licence Agreement
        </h2>
        <h2 *ngIf="isLicence=='AU'">
            About SILQ
            <!-- <mat-icon>close</mat-icon> -->
        </h2>
        <h2  *ngIf="isLicence=='MD'">
            SILQ Mobile Application
        </h2>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
        <!-- <mat-icon>close</mat-icon> -->
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="popup_body licence_content" *ngIf="isLicence=='LI'">
            <p class="center_bold">END USER LICENCE AGREEMENT<br />FOR SILQ SOFTWARE</p>
            <p class="bold_font">IMPORTANT-READ CAREFULLY:</p>
            <p>This End-User License Agreement (&ldquo;EULA&rdquo;) is a legal agreement between you (either an individual person or a single legal entity, who will be referred to in this EULA as &ldquo;You&rdquo;) and the Licensor for the SILQ software
                technology that displays this EULA, including any associated media, manual, printed materials and electronic documentation (the &ldquo;Software&rdquo;).</p>
            <p>For purposes of this EULA, the term &ldquo;Licensor&rdquo; refers to Silqware Pty Limited.</p>
            <p>For purposes of this EULA, the &ldquo;Software&rdquo; also includes any software patches, updates, fixes, add-on components, web services and/or supplements that the Licensor may choose to provide to You or make available to You after the
                date You obtain Your initial copy of the Software to the extent that such items are not accompanied by a separate licence agreement or terms of use.</p>
            <p>By installing, copying, downloading, accessing or otherwise using the Software, You agree to be bound by the terms of this EULA. If You do not agree to the terms of this EULA, Licensor is unwilling to license the Software. In such event, You
                may not install, copy, download or otherwise use the Software.</p>
            <p class="bold_font">SOFTWARE LICENCE</p>
            <p>The Software is protected by intellectual property laws and treaties. The Software is licensed, not sold.</p>
            <p><b>1. GRANT OF LICENCE.</b> This EULA grants You the following rights:<br /><b>(a) During the 14 Day Free Trial.</b> You may install and use one copy of the Software for a free trial for a one-off period of 14 days only, subject to the terms
                of this EULA. Your right to install and use one copy of the Software for the free trial is non-exclusive. After the one-off 14 day free trial, You may not use or deal with the Software in any way unless You have paid the required fee to
                the Licensor.<br><b>(b) Purchase of a Licence for the Software.</b><br /><b> &bull; Installation and Use.</b> Once you have paid the required fees for one user licence to the Licensor, You may install and use one copy of the Software,
                subject to the terms of this EULA.
                <br />&bull; If You, in addition to having paid the required fee for one user licence (the &ldquo;primary user&rdquo;), have also paid the required fees for additional-seat user licences to the Licensor, You may concurrently use the number
                of copies of the Software that corresponds to those additional licences, subject to the terms of this EULA. The additional-seat user/s may only use the Software in relation to the same data file as that used by the primary user.<br />&bull;
                <b>Rights are non-exclusive.</b> Your right to install and use the Software granted by this EULA is non-exclusive, non-assignable and non-sublicensable.</p>
            <p><b>2. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS.</b><br /><b> &bull; Limitations on Reverse Engineering, Decompilation, and Disassembly.</b> You<br />may not reverse engineer, decompile, or disassemble the Software, except and<br />only
                to the extent that such activity is expressly permitted by applicable law<br />notwithstanding this limitation.<br /><b> &bull; Trademarks.</b> This EULA does not grant You any rights in connection with any<br />trademarks or service marks
                of Licensor or its suppliers.<br /><b> &bull; No rental, leasing or commercial hosting.</b> You may not rent, lease, lend or<br />provide commercial hosting services to third parties with the Software.<br /><b> &bull; Support Services.</b>                Licensor may provide You with support or other types of services related to the Software (&ldquo;Support Services&rdquo;). Whether the Licensor is obliged to provide Support Services to You is governed by other materials provided by the
                Licensor to You. Any supplemental software code provided to You as part of any Support Services are considered part of the Software and subject to the terms and conditions of this EULA. You acknowledge and agree that Licensor may use technical
                information You provide to Licensor as part of the Support Services for its business purposes, including for product support and development. Licensor will not utilize such technical information in a form that personally identifies You.
                <br /><b>&bull; Unlicensed or
                illegal use.</b> The Software contains technological measures (including use of the Internet) that are designed to prevent unlicensed or illegal use of the Software and to inform you of any updates to the Software. You consent to the Licensor
                using these measures for the purpose of preventing unlicensed or illegal use of the Software and to inform you of any updates to the Software.<br /><b> &bull; Termination.</b> Without prejudice to any other rights, Licensor may terminate
                this EULA if You fail to comply with the terms and conditions of this EULA. In such event, You must cease using the Software and destroy all copies of the Software and all of its component parts.</p>
            <p><b>3. INTELLECTUAL PROPERTY RIGHTS.</b> You acknowledge and agrees that all intellectual property in and to the Software and any other information or material (including confidential information) relating to the Software remains the property
                of the Licensor, and that You acquire no right, title or interest in or to that intellectual property by virtue of this EULA other than as expressly stated in this EULA.&nbsp; All rights not specifically granted under this EULA are reserved
                by Licensor and its suppliers.</p>
            <p><b>4. APPLICABLE LAW.</b> This EULA is governed by the laws of New South Wales.</p>
            <p><b>5. WARRANTIES</b></p>
            <p>5.1 You acknowledge that:<br />a) the Software and the Support Services cannot be and are not guaranteed to be error free and further acknowledge that the existence of any such errors shall not constitute a breach of this agreement;<br />b)
                prior to entering into this agreement you have been given a reasonable opportunity to examine and satisfy yourself regarding the features and operation of the Software (including the opportunity to use the Software during a free trial
                period) and that prior to entering this agreement You have availed yourself of that opportunity;<br />c) You have made the evaluation or judgement that You wish to use the Software and that the Licensor does not warrant that the Software
                will be suitable for Your particular purposes and you further acknowledge that at no time prior to entering into this EULA have You relied on the skill or judgment of the Licensor and that it would be unreasonable for You to rely on any
                such skill or judgment.</p>
            <p>5.2 The Licensor provides the following warranties, in relation to the Software and the Support Services if You are a Consumer: <br />(a) that the Software is of acceptable quality, as that term is defined in the Australian Consumer Law;<br
                />(b) that the Software is reasonably fit for the purpose of time management software for legal practitioners in small practice;<br />(c) that the Support Services will be rendered with due care and skill;<br />(d) that the Support Services
                will be provided either:<br />(i) at a time determined in a manner agreed between the Licensor and You; or<br />(ii) if there is no such agreement, within a reasonable time.</p>
            <p><br />5.3 Where You are a Consumer under the Australian Consumer Law, the Licensor is required to provide the following mandatory statement to you: &ldquo;Our goods come with guarantees that cannot be excluded under the Australian Consumer
                Law. You are entitled to a replacement or refund for a major failure and for compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the goods repaired or replaced if the goods fail to be of acceptable
                quality and the failure does not amount to a major failure.&rdquo; The Software and the Support Services are also subject to guarantees under the Australian Consumer Law other than those in cl 5.2 if you are a Consumer. </p>
            <p>5.4 Should You wish to make a claim under clause 5.2 or by reason of any statutory guarantee, you must provide the Licensor with written notice along with documentary evidence substantiating the claim.</p>
            <p><b>6. LIMITATIONS</b></p>
            <p>6.1 Nothing in this agreement excludes, restricts or modifies the application of any legislation which by the law of any jurisdiction cannot be excluded, restricted or modified. If You are a Consumer you have the benefit of certain non-excludable
                rights and remedies in respect of the goods or services and nothing in these terms and conditions excludes or restricts or modifies any condition, warranty, guarantee, right or remedy under the which is so conferred, other than as set
                out below.</p>
            <p>6.2 The parties agree that the Licensor&rsquo;s liability for breach of:<br />(a) the warranties contained in clause 5.2; <br />(b) the non-excludable rights and remedies referred to in clause 6.1 (other than the guarantees implied by sections
                51, 52 or 53 of the Australian Consumer Law); and<br />(c) any other liability of the Licensor howsoever arising,<br />is limited as set out in clauses 6.3 &ndash; 6.5.</p>
            <p>6.3 In relation to the Software, the Licensor&rsquo;s liability is limited to (a) repair of the Software or<br />(b) a refund of the fees paid for the Software (where the choice of remedy is at the option of the Licensor in its absolute discretion).</p>
            <p>6.4 In relation to the Support Services, the Licensor&rsquo;s liability is limited to supplying of the Support Services again.</p>
            <p>6.5 You agree that:<br />(a) to the extent such limitation is permitted by law (including the Australian Consumer Law) the Licensor is not liable for any special, indirect or consequential damage (including loss of profit, loss of business
                opportunity or loss of data) arising out of a breach of this EULA or otherwise arising out of the supply or use of the Software or the Support Services (whether under statute, at common law, in negligence, under contract or otherwise),
                even if the Licensor has been advised of the possibility of such damages;<br />(b) to the extent such limitation is permitted by law (including the Australian Consumer Law), the Licensor&rsquo;s total liability to you in relation to this
                EULA or otherwise in relation to the supply or use of the Software or the Support Services, (whether arising under statute, at common law, in negligence, under contract or otherwise), will not exceed in aggregate the total amount of fees
                you have paid to the Licensor in relation to the Software and the Support Services.</p>
            <p><b>7. ENTIRE AGREEMENT.</b> This EULA is the entire agreement between You and<br />Licensor relating to the Software and supersedes all prior or contemporaneous oral or written communications, marketing materials, proposals and representations
                with respect to the Software or any other subject matter covered by this EULA. To the extent the terms of any Licensor agreement, policy or program for Support Services or any other document conflict with the terms of this EULA, the terms
                of this EULA shall control.</p>
            <p><br /><b>In this EULA:</b></p>
            <p><b>Australian Consumer Law</b> means Schedule 2 of the Competition and Consumer Act 2010 (Cth) as amended from time to time, or any later equivalent legislation.</p>
            <p><b>Consumer</b> has the meaning given under the Australian Consumer Law.</p>
        </div>
        <div class="popup_body about_silq_main" *ngIf="isLicence=='AU'">
            <p class="center_bold">SILQ {{ProductType}}</p>
            <p> Copyright 2002-{{currentYear}} Silqware Pty. Limited </p>
            <!-- <img *ngIf="defultTheme" class="logo-icon unfold_sidebar_logo" src="assets/images/logos/silq-logo-user2.png">
            <img *ngIf="!defultTheme" class="logo-icon unfold_sidebar_logo" src="assets/images/logos/silq-logo.png"> -->
            <br>
            <p class="center_bold"> The business of being a {{ProductType}}</p><br>
            <p class="border_div">For support details go to <a class="silw_site_link" target="_blanck" href="https://www.silq.com.au/">www.silq.com.au</a></p>
        </div>
        <div class="popup_body about_silq_main" *ngIf="isLicence=='MD'">
            <p class="center_bold">SILQ Application</p>
            <br>
            <p class="center_link">
                <a mat-list-item target="_blanck" href="#">
                    <img src="assets/icons/web_app/Google_play.png"  >
                </a>
                <a mat-list-item target="_blanck" href="#">
                    <img src="assets/icons/web_app/appstore.png"  >
                </a>
            </p>
            <br>
            <p class="border_div">For support details go to <a class="silw_site_link" target="_blanck" href="https://www.silq.com.au/">www.silq.com.au</a></p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="popup_ftr combined_popup_ftr" *ngIf="isLicence!='MD'">
        <div class="right_popup_btn">
            <div class="right_btn_child">
                <button class="cancel" mat-button [mat-dialog-close]="true" matBadgeColor="accent">Close</button>
            </div>
        </div>
    </mat-dialog-actions>
</div>