import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { ContactDialogComponent } from '../contact-dialog.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactSelectDialogComponent } from '../../contact-select-dialog/contact-select-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss'],
  animations: fuseAnimations
})
export class AssociationsComponent implements OnInit, OnDestroy {

  appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
  @Input() action: any;
  isspiner: boolean = false;
  isspiner2: boolean = false;
  isDisplay: boolean = false;
  associationsData: any = [];
  ContactData: any = {};
  displayedColumns: string[] = ['CONTACTNAME', 'PHONE', 'MOBILE', 'EMAIL'];
  dataSource: any = [];
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean = false;
  highlightedRows: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  currentContactData: any = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Output() contactDetail: EventEmitter<any> = new EventEmitter<any>();
  parentContactGuid: any = '';
  ChildContactTableDataSubcription: Subscription;
  itemRowIndex:number = 0;
  pageIndex:number;
  pageSize:number;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    public _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.ChildContactTableDataSubcription = this.behaviorService.ChildContactTableData$.subscribe(result => {
      if (result) {
        this.ContactData = result;
        // this.MatterCriminalForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
        if (result.contactType == "Person" && result.action == 'setData') {
          this.parentContactGuid = result.CONTACTGUID;
          this.saveAssociation();
        } else if (result.contactType == "Person" && result.action == 'deleteData') {
          this.parentContactGuid = result.CONTACTGUID;
          this.DeleteContactParent(); //Without confirmation boxx
        }
      }
    });
    if (this.appPermissions == null) {
      this.appPermissions = [];
    }
    if (this.action == "edit") {
      this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
    }
  }

  /**
   * This function is used to edit the contact
   * @param Row -Row data
   */
  editContact(Row: any) {
    this.currentContactData = Row;
  }

  /**
   * This function is used to load the Contect data
   * @param postData --Post data value
   */
  loadContectData(postData) {
    this.isLoadingResults = true;
    this.currentContactData='';
    this._mainAPiServiceService.getSetData(postData, 'contact-parent').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.dataSource = new MatTableDataSource(response.DATA.RECORDS);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (response.DATA.RECORDS[0]) {
          this.isDisplay = false;
          this.currentContactData = response.DATA.RECORDS[0];
          this.highlightedRows = response.DATA.RECORDS[0].CONTACTPARENTGUID;
        } else {
          this.isDisplay = true;
        }
        this.isLoadingResults = false;
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to AddContact 
   */
  AddContactsDialog() {
    const dialogRef = this.dialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { defult_type: "Company" } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.action == "new" || this.action == "duplicate") {
          // this.contactDetail.emit(result);
          this.associationsData.push(result);
          this.dataSource = new MatTableDataSource(this.associationsData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.isspiner = true;
          let details = { Action: 'Insert', VALIDATEONLY: false, Data: { PARENTGUID: localStorage.getItem('contactGuid'), CONTACTGUID: result.CONTACTGUID } };
          this._mainAPiServiceService.getSetData(details, 'contact-parent').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
              this.toastr.success('Contact Link successfully');
              this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
              this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
              this.toastr.error(response.MESSAGE);
            }
            this.isspiner = false;
          }, error => {
            this.toastr.error(error);
            this.isspiner = false;
          });
        }
      }
    });
  }

  /**
   * This function is used to change the pagination
   * @param event -event value
   */
  onPaginateChange(event){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize
  }

  /**
   * 
   * @param index 
   */
  itemRowClick(index:number){
    this.itemRowIndex = index;    
  }

  /**
   * This function is used to Save the Assosiations
   */
  saveAssociation() {
    this.associationsData.forEach(item => {
      this.isspiner = true;
      let details = { Action: 'Insert', VALIDATEONLY: false, Data: { PARENTGUID: this.parentContactGuid, CONTACTGUID: item.CONTACTGUID } };
      this._mainAPiServiceService.getSetData(details, 'contact-parent').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.toastr.success('Company Linked successfully');
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.toastr.warning(response.MESSAGE);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.toastr.error(response.MESSAGE);
        }
        this.isspiner = false;
      }, error => {
        this.toastr.error(error);
        this.isspiner = false;
      });
    });
  }

  /**
   * This function is used to delete the contact
   */
  deleteContact(): void {
    if (this.action == "new") {
      let index;
      if (this.pageIndex) {
        index = this.pageIndex * this.pageSize + this.itemRowIndex
        this.currentContactData = ''
      } else {
        index = this.itemRowIndex
      }
      this.associationsData.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.associationsData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%' });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Unlink?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner2 = true;
  
          let details = { Action: "Delete", Data: { CONTACTPARENTGUID: this.highlightedRows } };
          this._mainAPiServiceService.getSetData(details, 'contact-parent').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
              this.toastr.success('Contact UnLink successfully');
              this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
              this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
              this.toastr.error(response.MESSAGE);
            }
            this.isspiner2 = false;
          }, error => {
            this.toastr.error(error);
            this.isspiner2 = false;
          });
        }
        this.confirmDialogRef = null;
      });
    }
  }
 
  /**
   * THis function is used to edit the contact
   * @param CONTACTGUID -Contactguid
   */
  EditContactsDialog(CONTACTGUID: any) {
    if (!this.currentContactData) {
      this.toastr.error("Please Select Company");
    } else {
      const dialogRef = this.dialog.open(ContactDialogComponent, { disableClose: true, data: { action: 'edit', 'CONTACTGUID': CONTACTGUID } });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          
          // if (this.action == "new") {
            // this.associationsData[this.itemRowIndex] =  result;
            // this.dataSource = new MatTableDataSource(this.associationsData);
            // this.dataSource.paginator = this.paginator;
            // this.dataSource.sort = this.sort;
          // } else {
          //   this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
          // }
        }
      });
    }
  }
  
  /**\
   * This function is used to delete the Contact parent
   */
  DeleteContactParent() {
    this.isspiner2 = true;
    let details = { Action: "Delete", Data: { PARENTGUID: this.parentContactGuid } };
    this._mainAPiServiceService.getSetData(details, 'contact-parent').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success('Contact UnLink successfully');
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
      }
      this.isspiner2 = false;
    }, error => {
      this.toastr.error(error);
      this.isspiner2 = false;
    });
  }
  ngOnDestroy(): void {
    this.ChildContactTableDataSubcription?.unsubscribe();
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }
}