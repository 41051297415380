<div class=" one " fxLayout="column" [formGroup]="matterFamliydetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COHABITATIONDATE,'has-warning':errorWarningData.Warning?.COHABITATIONDATE}">
            <mat-label>Cohabitation Date</mat-label>
            <input (dateInput)="CohabitationDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.COHABITATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COHABITATIONDATETEXT" (dateChange)="CohabitationDateClick('change', $event)" matInput [matDatepicker]="CohabitationDate">
            <mat-datepicker-toggle matSuffix [for]="CohabitationDate"></mat-datepicker-toggle>
            <mat-datepicker #CohabitationDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.COHABITATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COHABITATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COHABITATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MARRIAGEDATE,'has-warning':errorWarningData.Warning?.MARRIAGEDATE}">
            <mat-label>Marriage Date</mat-label>
            <input (dateInput)="MarriageDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.MARRIAGEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MARRIAGEDATETEXT" (dateChange)="MarriageDateClick('change', $event)" matInput [matDatepicker]="MarriageDate">
            <mat-datepicker-toggle matSuffix [for]="MarriageDate"></mat-datepicker-toggle>
            <mat-datepicker #MarriageDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.MARRIAGEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MARRIAGEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MARRIAGEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MARRIAGEPLACE,'has-warning':errorWarningData.Warning?.MARRIAGEPLACE}">
            <mat-label>Marriage Place</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.MARRIAGEPLACE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MARRIAGEPLACE">
         <div  *ngIf="testBool && toolTipList.MARRIAGEPLACE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MARRIAGEPLACE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MARRIAGEPLACE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MARRIAGECOUNTRY,'has-warning':errorWarningData.Warning?.MARRIAGECOUNTRY}">
            <mat-label>Marriage Country</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.MARRIAGECOUNTRY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MARRIAGECOUNTRY">
         <div  *ngIf="testBool && toolTipList.MARRIAGECOUNTRY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MARRIAGECOUNTRY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MARRIAGECOUNTRY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>


        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SEPARATIONDATE,'has-warning':errorWarningData.Warning?.SEPARATIONDATE}">
            <mat-label>Separation Date</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.SEPARATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SEPARATIONDATE">
            <!-- <input (dateInput)="SeparationDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.SEPARATIONDATETEXT:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SEPARATIONDATETEXT" (dateChange)="SeparationDateClick('change', $event)" matInput [matDatepicker]="separationdate"> -->
            <!-- <mat-datepicker-toggle matSuffix [for]="separationdate"></mat-datepicker-toggle>
            <mat-datepicker #separationdate></mat-datepicker> -->
         <div  *ngIf="testBool && toolTipList.SEPARATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SEPARATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SEPARATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" fxFlex="34" class="pr-4">
            <mat-label>Date Filed For Divorce</mat-label>
            <input matInput type="number" matTooltip="{{(toolTipList)?toolTipList.COURT:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DATEFILEDFORDIVORCE">
         <div  *ngIf="testBool && toolTipList.LEGALAIDREFERENCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LEGALAIDREFERENCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LEGALAIDREFERENCE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->


        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEFILEDFORDIVORCE,'has-warning':errorWarningData.Warning?.DATEFILEDFORDIVORCE}">
            <mat-label>Date Filed For Divorce</mat-label>
            <input (dateInput)="DateFiledForDivorceDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.DATEFILEDFORDIVORCE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DATEFILEDFORDIVORCETEXT" (dateChange)="DateFiledForDivorceDateClick('change', $event)" matInput [matDatepicker]="datefiledfordivore">
            <mat-datepicker-toggle matSuffix [for]="datefiledfordivore"></mat-datepicker-toggle>
            <mat-datepicker #datefiledfordivore></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.DATEFILEDFORDIVORCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEFILEDFORDIVORCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEFILEDFORDIVORCE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DIVORCEDATE,'has-warning':errorWarningData.Warning?.DIVORCEDATE}">
            <mat-label>Divorce Date</mat-label>
            <input (dateInput)="DivorceDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.DIVORCEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DIVORCEDATETEXT" (dateChange)="DivorceDateClick('change', $event)" matInput [matDatepicker]="DivorceDate">
            <mat-datepicker-toggle matSuffix [for]="DivorceDate"></mat-datepicker-toggle>
            <mat-datepicker #DivorceDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.DIVORCEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DIVORCEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DIVORCEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DIVORCEPLACE,'has-warning':errorWarningData.Warning?.DIVORCEPLACE}">
            <mat-label>Divorce Place</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.DIVORCEPLACE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DIVORCEPLACE">
         <div  *ngIf="testBool && toolTipList.DIVORCEPLACE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DIVORCEPLACE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DIVORCEPLACE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DIVORCECOUNTRY,'has-warning':errorWarningData.Warning?.DIVORCECOUNTRY}">
            <mat-label>Divorce Country</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.DIVORCECOUNTRY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DIVORCECOUNTRY">
         <div  *ngIf="testBool && toolTipList.DIVORCECOUNTRY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DIVORCECOUNTRY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DIVORCECOUNTRY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NUMDEPENDANTS,'has-warning':errorWarningData.Warning?.NUMDEPENDANTS}">
            <mat-label>Num Dependants</mat-label>
            <input matInput type="number" matTooltip="{{(toolTipList)?toolTipList.NUMDEPENDANTS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="NUMDEPENDANTS">
         <div  *ngIf="testBool && toolTipList.NUMDEPENDANTS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NUMDEPENDANTS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NUMDEPENDANTS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <!-- 
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MatterNo,'has-warning':errorWarningData.Warning?.MatterNo}">
            <mat-label>Court Matter #</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.COURT:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MatterNo">
         <div  *ngIf="testBool && toolTipList.LEGALAIDREFERENCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LEGALAIDREFERENCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LEGALAIDREFERENCE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAMILYCOURTCLIENTID,'has-warning':errorWarningData.Warning?.FAMILYCOURTCLIENTID}">
            <mat-label>Family Court Client ID</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.FAMILYCOURTCLIENTID?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FAMILYCOURTCLIENTID">
         <div  *ngIf="testBool && toolTipList.FAMILYCOURTCLIENTID" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FAMILYCOURTCLIENTID.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAMILYCOURTCLIENTID?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ExpirationDate,'has-warning':errorWarningData.Warning?.ExpirationDate}">
            <mat-label>Expiry Date</mat-label>
            <input (dateInput)="ExpiryDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.EXPIRATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ExpirationDatetextF" (dateChange)="ExpiryDateClick('change', $event)" matInput name="ExpiryDate" [matDatepicker]="ExpiryDate">
            <mat-datepicker-toggle matSuffix [for]="ExpiryDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpiryDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.EXPIRATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXPIRATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPIRATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.EXPERTHEARINGDATE,'has-warning':errorWarningData.Warning?.EXPERTHEARINGDATE}">
            <mat-label>Hearing Date</mat-label>
            <input (dateInput)="HearingDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.COURT:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="EXPERTHEARINGDATE" (dateChange)="HearingDateClick('change', $event)" matInput [matDatepicker]="HearingDate">
            <mat-datepicker-toggle matSuffix [for]="HearingDate"></mat-datepicker-toggle>
            <mat-datepicker #HearingDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.COURT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COURT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COURT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
    </div>
</div>