import { TooltipService } from '@_services/tooltip.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bankdetails',
  templateUrl: './bankdetails.component.html',
  styleUrls: ['./bankdetails.component.scss']
})
export class BankdetailsComponent implements OnInit {
  @Input() allToolTip:any = [];
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any;
  constructor(public tooltipService: TooltipService) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
       
  }

}
