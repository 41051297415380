<div class=" one " fxLayout="column" [formGroup]="MediationForm" >
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"> -->
        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4"  [ngClass]="{'has-error':errorWarningData.Error?.FIELD,'has-warning':errorWarningData.Warning?.FIELD}">
            <mat-label>On screen prompt</mat-label>
            <input matInput  formControlName="FIELD"  
             >
        </mat-form-field> -->

        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MEDIATIONDATE,'has-warning':errorWarningData.Warning?.MEDIATIONDATE}">
            <mat-label>Date of Mediation</mat-label>
            <input  formControlName="MEDIATIONDATE" (dateInput)="CommencementDateClick('input', $event)"
               matInput [matDatepicker]="CommencementDatedata" (dateChange)="CommencementDateClick('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="CommencementDatedata"></mat-datepicker-toggle>
            <mat-datepicker #CommencementDatedata></mat-datepicker>
        </mat-form-field> -->

        <!-- <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOIDENTIFIER,'has-warning':errorWarningData.Warning?.FOLIOIDENTIFIER}">
            <mat-label>Folio Identifier</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.COURT:''}}"
             formControlName="FOLIOIDENTIFIER">
              <div  *ngIf="testBool && toolTipList.INTERESTRATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELFLAG.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELFLAG?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
    <!-- </div> -->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MEDIATIONNUMDAYS,'has-warning':errorWarningData.Warning?.MEDIATIONNUMDAYS}" >
            <mat-label>Number of Days </mat-label>
            <input matInput  type="number" formControlName="MEDIATIONNUMDAYS" matTooltip="{{(toolTipList)?toolTipList.MEDIATIONNUMDAYS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"
            >
            <div  *ngIf="testBool && toolTipList.MEDIATIONNUMDAYS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MEDIATIONNUMDAYS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MEDIATIONNUMDAYS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MEDIATIONDATE,'has-warning':errorWarningData.Warning?.MEDIATIONDATE}">
            <mat-label>Date of Mediation</mat-label>
            <input (dateInput)="CommencementDateClick('input', $event)" 
             formControlName="MEDIATIONDATETEXT" (dateChange)="CommencementDateClick('change', $event)" matInput [matDatepicker]="CommencementDate"
             matTooltip="{{(toolTipList)?toolTipList.MEDIATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"
             >
            <mat-datepicker-toggle matSuffix [for]="CommencementDate"></mat-datepicker-toggle>
            <mat-datepicker #CommencementDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.MEDIATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MEDIATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MEDIATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
 
        </mat-form-field>


        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MEDIATIONAGREEMENTSIGNED,'has-warning':errorWarningData.Warning?.MEDIATIONAGREEMENTSIGNED}">
            <mat-label>Date Agreement Signed</mat-label>
            <input  formControlName="MEDIATIONAGREEMENTSIGNEDTEXT"  (dateInput)="ExpirtyDateClick('input', $event)"  (dateChange)="ExpirtyDateClick('change', $event)"
              matInput [matDatepicker]="ExpirtyDate"
              matTooltip="{{(toolTipList)?toolTipList.MEDIATIONAGREEMENTSIGNED?.HOVER:''}}"
              [matTooltipPosition]="toolTipPostion"
              >
            <mat-datepicker-toggle matSuffix [for]="ExpirtyDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpirtyDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.MEDIATIONAGREEMENTSIGNED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MEDIATIONAGREEMENTSIGNED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MEDIATIONAGREEMENTSIGNED?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MEDIATIONNOTES,'has-warning':errorWarningData.Warning?.MEDIATIONNOTES}">
            <mat-label>Notes</mat-label>
            <textarea name="MEDIATIONNOTES" formControlName="MEDIATIONNOTES" 
              matInput
              matTooltip="{{(toolTipList)?toolTipList.MEDIATIONNOTES?.HOVER:''}}"
              [matTooltipPosition]="toolTipPostion"
              ></textarea>
              <div  *ngIf="testBool && toolTipList.MEDIATIONNOTES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MEDIATIONNOTES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MEDIATIONNOTES?.COPYVALUE:'')">file_copy</mat-icon></div>
 
        </mat-form-field>
    </div>
</div>