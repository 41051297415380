<ng-container *ngIf="matterClassData$ | async "></ng-container>
<div class=" one " fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FIRMROLE,'has-warning':errorWarningData.Warning?.FIRMROLE}">
            <mat-label>Acting on behalf of</mat-label>
            <mat-select name="FIRMROLE" formControlName="FIRMROLE" matTooltip="{{(toolTipList)?toolTipList.FIRMROLE?.HOVER:''}}">
                <mat-option *ngFor="let roleoption of ContactRoleData" value="{{roleoption.LOOKUPFULLVALUE}}">
                    {{roleoption.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
            <div  *ngIf="testBool && toolTipList.FIRMROLE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FIRMROLE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIRMROLE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field class="example pr-4" fxFlex="50" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.OTHERSIDEROLE,'has-warning':errorWarningData.Warning?.OTHERSIDEROLE}">
            <mat-label>Acting against</mat-label>
            <mat-select name="OTHERSIDEROLE" formControlName="OTHERSIDEROLE" matTooltip="{{(toolTipList)?toolTipList.OTHERSIDEROLE?.HOVER:''}}">
                <mat-option *ngFor="let roleoption of ContactRoleData" value="{{roleoption.LOOKUPFULLVALUE}}">
                    {{roleoption.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
            <div  *ngIf="testBool && toolTipList.OTHERSIDEROLE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OTHERSIDEROLE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERSIDEROLE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
        <mat-checkbox class="example pr-4  pl-4" formControlName="FIRMDONTSHOWADDRESS" fxFlex="100">
            Don't Use Client Address
        </mat-checkbox>
        <mat-icon class="mr-8 cursor-pointer" (click)="tooltipOpen('clinet address')">help_outline</mat-icon>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.RELINECLIENT,'has-warning':errorWarningData.Warning?.RELINECLIENT}">
            <mat-label>"Re" Line when writing to client</mat-label>
            <textarea matInput formControlName="RELINECLIENT" matTooltip="{{(toolTipList)?toolTipList.RELINECLIENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
            <div  *ngIf="testBool && toolTipList?.RELINECLIENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RELINECLIENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RELINECLIENT.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINECLIENTCLIENTASPREFIX" fxFlex="50">
            Add Client As Prefix
        </mat-checkbox>
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINECLIENTOTHERSIDEASSUFFIX" fxFlex="50">
            Add Other Side As Suffix
        </mat-checkbox>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.RELINEOTHERPARTIES,'has-warning':errorWarningData.Warning?.RELINEOTHERPARTIES}">
            <mat-label>"Re" when writing to other parties</mat-label>
            <textarea matInput formControlName="RELINEOTHERPARTIES" matTooltip="{{(toolTipList)?toolTipList.RELINEOTHERPARTIES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
            <div  *ngIf="testBool && toolTipList.RELINEOTHERPARTIES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RELINEOTHERPARTIES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RELINEOTHERPARTIES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINEOTHERPARTIESCLIENTASPREFIX" fxFlex="50">
            Add Client As Prefix
        </mat-checkbox>
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINEOTHERPARTIESOTHERSIDEASSUFFIX" fxFlex="50">
            Add Other Side As Suffix
        </mat-checkbox>
    </div>
</div>