<div cdkDrag class="popup_main_div delete_main_popup confirm_popup" style="width:400px">
    <h1 cdkDragHandle matDialogTitle>
        {{titleData}}
        <button class="btn close-button-icon" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h1>
    <div mat-dialog-content>
        <h3 [innerHtml]="confirmMessage"></h3>
        <div *ngFor="let row of loginUserData">
            {{row.LOGONNAME}}
        </div>
    </div>
    <div mat-dialog-actions class="popup_ftr" *ngIf="!cancleBtn">
        <div class="right_btn_child" *ngIf="!isTomany">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" [mat-dialog-close]="false" type="button" (click)="refreshIt()"> {{confirm1}} </button>
        </div>
        <div class="right_btn_child" *ngIf="isTomany">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" [mat-dialog-close]="true" type="button"> {{confirm1}} </button>
        </div>
    </div>

    <div mat-dialog-actions class="popup_ftr" *ngIf="cancleBtn">
        <div class="right_btn_child" *ngIf="cancleBtn">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" [mat-dialog-close]="false" type="button" (click)="closeDilog()"> {{confirm2}} </button>
        </div>
        
        <div class="right_btn_child" *ngIf="!isTomany">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" [mat-dialog-close]="true" type="button" (click)="redirectIntoNewUI()"> {{confirm1}} </button>
        </div>
    </div>
</div>