import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener, ElementRef, Renderer2, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { ResizeEvent } from 'angular-resizable-element';
import { SortingDialogComponent } from 'src/app/main/sorting-dialog/sorting-dialog.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { FilterSearchOptions } from 'src/app/_constant/dynamic-search-option.const';
import { DatePipe } from '@angular/common';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-packets',
  templateUrl: './packets.component.html',
  styleUrls: ['./packets.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PacketsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
  currentUserData:any
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  highlightedRows: any;
  isLoadingResults: boolean = false;
  displayedColumns: any;
  ColumnsObj = [];
  tempColobj: any;
  MainPacketsData: any = [];
  isDisplay: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  PacketsForm: FormGroup;
  isSearchStringFilter: boolean;
  filterData: { 'Search': string };
  COLOR: any;
  resizableMousemove: () => void;
  resizableMouseup: () => void;
  showFiltersList: boolean = true;
  optionalFilterList = [];
  totalApplicableFilterCount: number = 0;
  HyperlinkColor: any;
  activeOptionalFilter: any;
  addFilter: any;
  activeFilters: any;
  FILTERSLIST: FormArray;
  activeFilter = FilterSearchOptions.activeFilter
  unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter
  dateFilter = FilterSearchOptions.dateFilter
  currencyFilter = FilterSearchOptions.currencyFilter
  manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;
  dayRangeOptions = FilterSearchOptions.dayRangeOptions;
  _filterList = [];
  FILTERS: FormArray;
  optionalFilters = [];
  saveAdvanceFilter: any = [];
  optionalDateFilterPayload = [];
  forHideShowDateRangePicker: boolean;
  private _unsubscribeAll$: Subject<void> = new Subject();
  gloableAdvancedFilters: any;
  GloableadvanceFilterPayload: any;

  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    private dialog: MatDialog,
    private TableColumnsService: TableColumnsService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private renderer: Renderer2,public datepipe: DatePipe,
    public behaviorService: BehaviorService,private _hyperlink:HyperlinkNavigationService,private cd: ChangeDetectorRef,) {
      this.currentUserData = JSON.parse(window.localStorage.getItem("currentUser"));
    localStorage.setItem('istrackid', 'MianSafeCustodyPacketsComponent');
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'MianSafeCustodyPacketsComponent' && result['click'] && result['data']['safe-custody-packet']) {
        if (!localStorage.getItem('packets_list_columns')) {
          this.getTableFilter();
        }
        this.LoadData(this.filterData);
      }
    });

    this._hyperlink.setColorSetting();
    this.behaviorService.ColorSystemSetting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
        this.COLOR=data
    })
    this._hyperlink.setColorSettingWithHyperlink()
    this.behaviorService.ColorSystemSettingHyperLink$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
      this.HyperlinkColor=data
    })

    
    this.behaviorService.loaderDATA$.subscribe(data=>{
      this.isLoadingResults=data;
  })

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.activeFilters = {};
    this.addFilter = {
      OPERATION: '',
      VALUE: '',
      VALUE2: '',
      FILTERTYPE: ''
    };

    $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 70)) + 'px');
    this.PacketsForm = this._formBuilder.group({
      SEARCH: [''],
      MAINFILTER: [''],
      FILTERSLIST: new FormArray([]),
      FILTERS: new FormArray([]),

    });
    this.filterData = { 'Search': '' }
    if (!localStorage.getItem("packets_filter")) {
      localStorage.setItem('packets_filter', JSON.stringify(this.filterData));
    } else {
      this.filterData = JSON.parse(localStorage.getItem("packets_filter"));
    }
    this.isSearchStringFilter = this.filterData.Search ? false : true;
    this.PacketsForm.controls['SEARCH'].setValue(this.filterData.Search);

    this.getTableFilter();
    this.LoadData(this.filterData);
    this.getFilter();
    this.setDefaultAdvanceFilter();
    //this.forHideShowDateRangePicker = false;
  }


  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit(): void {
    this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }
 
  /**
   * This function is used to get the table filter data value
   */
  getTableFilter() {
    let packetsListColumns: any = JSON.parse(localStorage.getItem('packets_list_columns'));
    if (packetsListColumns && packetsListColumns != null) {
      let data = this.TableColumnsService.filtertableColum(packetsListColumns.ColumnsObj);
      this.displayedColumns = packetsListColumns.displayedColumns;
      this.ColumnsObj = packetsListColumns.ColumnsObj;
      this.tempColobj = data.tempColobj;
    } else {
      this.TableColumnsService.getTableFilter('Safe Custody', 'Packets').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let data = this.TableColumnsService.filtertableColum(response.DATA.RECORDS);
          this.displayedColumns = data.showcol;
          this.ColumnsObj = data.colobj;
          this.tempColobj = data.tempColobj;
          localStorage.setItem('packets_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
        }
      }, error => {
        this.toastr.error(error);
      });
    }
  }

  /**
   * This function is used to onResizing the data value 
   */
  onResizing(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      const indexData = this.ColumnsObj.findIndex(col => col.COLUMNID === columnName);
      this.ColumnsObj[indexData]['WIDTH'] = event.rectangle.width;
      localStorage.setItem('packets_list_columns', JSON.stringify({ "ColumnsObj": this.ColumnsObj, 'displayedColumns': this.displayedColumns }));
      
    }
  }

  /**
   * This functio  is used to On Resize end
   */
  onResizeEnd(event: ResizeEvent, columnName): void {
    this.TableColumnsService.SaveWidthData(this.ColumnsObj, 'Safe Custody', 'Packets');
  }
//   // Resize table column. also make some change in html side put below function with 'mwlResizable'.
//   onResizeColumnSetting(event , index){
//     //event : selected column.
//     //index : selected column index.
//     //renderer : dont make this comman it's give issue import in your component.
//     //displayedColumns : displayedColumns name's (Table Header names).
//     //matTableRef : most importent please import in component wise don't make it comman.
//     // last field is localstorage name which use to store columnObj

//     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'packets_list_columns');
//   };

  //For resize this fuction is most impotent don't make it comman it's give issue in future.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
     this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  /**
   * this function is used to set the default data value
   */
  setDefaultWidth(displayedColumns, timeout) {
    setTimeout(() => {
      displayedColumns.forEach(element => {
        let temWidth = this.tempColobj[element]['WIDTH'];
        const cssValue = temWidth + 'px';
        const columnElts = document.getElementsByClassName('mat-column-' + element);
        for (let i = 0; i < columnElts.length; i++) {
          const currentEl = columnElts[i] as HTMLDivElement;
          currentEl.style.visibility = 'inherit';
          currentEl.style.width = cssValue;
        }
      });
    }, timeout);
  }

  /**
   * This function is used to download the file
   */
  downloadFile(data) {
    const sortedData = this.MainPacketsData.sortData(this.MainPacketsData.filteredData,this.MainPacketsData.sort);
    this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, sortedData, "Packets_data_", this.tempColobj);
    // this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, data, "Packets_data_", this.tempColobj);
  }

  /**
   * This function is used to print the page
   */
  printPage() {
    window.print();
  }

  /**
   * This function is used load data
   */
  LoadData(data:any={}) {

    const ArrayResetOptional = JSON.parse(JSON.stringify(this.PacketsForm.value.FILTERS));
    const ArrayResetOptionalNew = ArrayResetOptional.map((data1, index) => {
      if (this.optionalFilters[index]) {
        data1.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
      }
      return data1;
    })

    const finalPayload = ArrayResetOptionalNew.filter((d) => d['VALUE'] !== null && d['VALUE'] !== '').map((e) => {
      if (e['VALUE'] && e['VALUE'] !== '') {
        delete e.COLUMNID;
        if (e.VALUE2 == null) {
          e.VALUE2 = "";
        }
        return e;
      }
    });

    // resetFilter.FILTERS = finalPayload;
    data.AdvancedFilters = finalPayload;

    //Advance Filter Payload Logic.
    const advanceFilterPayload = this.TableColumnsService.loadAdvanceFilterPayload('Packets');
    data.AdvancedFilters = (advanceFilterPayload && advanceFilterPayload.length !== 0) ? advanceFilterPayload : [];

    if (data.AdvancedFilters.length == 0) {
      delete data.AdvancedFilters;
      localStorage.removeItem('Packets_Advance_Filter');
    }
    // ends here ~ for optional filter

    const Filters = JSON.parse(JSON.stringify(data));
    delete Filters.Contact;
    delete Filters.Contactguid;
    delete Filters.Matter;
    delete Filters.MATTERGUID;


    this.isLoadingResults = true;
    const AdvancedFilters = Filters.AdvancedFilters ? Filters.AdvancedFilters : []; 
    let Payload : any= {
      "Action": "GetData",
       Filters: Filters,
       AdvancedFilters: AdvancedFilters
    };

    this.gloableAdvancedFilters=Filters,
    this.GloableadvanceFilterPayload=AdvancedFilters
    if(AdvancedFilters.length == 0){
      delete Payload.AdvancedFilters
    }
    delete Payload.Filters.AdvancedFilters;
    this._mainAPiServiceService.getSetData(Payload, 'safe-custody-packet').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.MainPacketsData = new MatTableDataSource(res.DATA.RECORDS);
        this.MainPacketsData.sort = this.sort;
        this.MainPacketsData.paginator = this.paginator;
        this.behaviorService.Packets(null);
        if (res.DATA.RECORDS[0]) {
          this.isDisplay = false;
          this.EditPackets(res.DATA.RECORDS[0]);
          this.highlightedRows = res.DATA.RECORDS[0].SAFECUSTODYPACKETGUID;
        } else {
          this.isDisplay = true;
        }
        this.isLoadingResults = false;
        this.setDefaultWidth(this.displayedColumns, 500);
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
  }
  get f() {
    return this.PacketsForm.controls;
  }

  /**
   * This function is used to on search the data
   */
  onSearch(searchFilter: any) {
    this.filterData = JSON.parse(localStorage.getItem("packets_filter"));
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter' || searchFilter == "RemoveSearchString") {
      if (searchFilter == "RemoveSearchString") {
        this.PacketsForm.controls['SEARCH'].setValue('');
      }
      this.filterData.Search = this.f.SEARCH.value;
      localStorage.setItem('packets_filter', JSON.stringify(this.filterData));
      this.isSearchStringFilter = this.filterData.Search ? false : true;
      
      this.LoadData(this.filterData);
    }
  }

  /**
   * This function is used to open the Dialog
   */
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '100%';
    dialogConfig.disableClose = true;
    dialogConfig.data = { 'data': this.ColumnsObj, 'type': 'safe custody', 'list': 'packets' };
    //open pop-up
    const dialogRef = this.dialog.open(SortingDialogComponent, dialogConfig);
    //Save button click
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayedColumns = result.columObj;
        this.tempColobj = result.tempColobj;
        this.ColumnsObj = result.columnameObj;
        localStorage.setItem('packets_list_columns', JSON.stringify({ 'displayedColumns': result.columObj, "ColumnsObj": result.columnameObj }));
        if (!result.columObj) {
          this.MainPacketsData = new MatTableDataSource([]);
          this.isDisplay = true;
        } else {
          this.LoadData(this.filterData);
        }
      }
    });
  }
  //EditPackets

  /**
   * This function is used to edit the packets
   */
  EditPackets(row) {
    this.behaviorService.Packets(row);
  }

  /**
   * This function is used to Refresh the Packet data
   */
  RefreshPacketsData() {
    this.LoadData(this.filterData);
  }

  /**
   * This function is used to prevent the data
   */
  preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * This function is used to clear the filter
   */
  clearFilter(Filterdata:any){
 
  let filterVal :any= {}
    if(Filterdata == 'SearchString'){
      this.PacketsForm.controls['SEARCH'].setValue('');
      filterVal.Search = ''
      this.filterData.Search =''
      localStorage.setItem('packets_filter', JSON.stringify(this.filterData));
  this.LoadData(filterVal)
  
    }
  
  }

  /**
   * This function is used to back to filter
   */
  backToFilter(id) {
    $('#' + id).removeClass('active');
    setTimeout(() => {
      $('.mat-menu-content').removeClass('active');
      $('.mat-menu-content').removeClass('mat-menu-content_height');
    }, 50);
  }

  /**
   * This function is used to get the filter
   */
  getFilter() {
    let opFilterList = JSON.parse(localStorage.getItem('packets_list_columns'));
    const filterData = this.TableColumnsService.getDynamicFilter(opFilterList, this.optionalFilterList, 'packets_list_columns');
    this.refreshFilterList()
    //this.defaultFilter()
  };

  /**
   * This function is used to refresh the filter
   */
  refreshFilterList(){
    //Advance Filter Refresh
    this.totalApplicableFilterCount = 0;

    const advDynamicFilter = JSON.parse(localStorage.getItem('Packets_Advance_Filter'));
    this.optionalFilterList.map((x)=>{
        
      if (x.FILTERTYPE != "HIDDEN") {
          this.totalApplicableFilterCount += 1;
      }

      if(advDynamicFilter && advDynamicFilter.findIndex((e)=> e.COLUMNID == x.COLUMNID) !== -1){
        x.FILTERAPPLY = true;
      };
    });
  }

  /**
   * This function is used to active the current filter 
   */
  activeCurrentFilter(id, data?: any) {
    $('#' + id).addClass('active');
    setTimeout(() => {
      $('.mat-menu-content').addClass('active');
      $('.mat-menu-content').addClass('mat-menu-content_height');
    }, 200);
    this.activeOptionalFilter = data;
    this.PacketsForm.controls['MAINFILTER'].reset();
  }

  @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;

  addNewFilter(event) {

    setTimeout(() => {
      this.operationFilter.options.first.select();
    }, 200)

    this.addFilter.VALUE = "";
    this.addFilter.VALUE2 = "";

    if (this.FILTERSLIST) {
      this.FILTERSLIST.controls.splice(0, 1);
      //this.FILTERSLIST.reset();
    };

    if (event.FILTERTYPE == 'DATE') {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [{
        id: 1,
        value: 'days'
      }, {
        id: 2,
        value: 'months'
      }]
    };

    if (event.FILTERTYPE !== 'DATE' && event.FILTERTYPE !== 'CURRENCY' && event.FILTERTYPE !== 'NUMBER') {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
    }

    if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(event.FILTERTYPE)
    }

    this._filterList = [event];
    this.addFilter.FILTERTYPE = event.FILTERTYPE;
    this.addFilter.VALUE = "";
    this.addFilter.VALUE2 = "";


    // OPTIONAL FILTER
    this.FILTERSLIST = this.PacketsForm.get('FILTERSLIST') as FormArray;
    this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

    this.addFilter.OPERATION = (event.FILTERTYPE == "DATE") ? 'is in the last' : (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') ? 'is equal to' : 'contains'
    this.cd.detectChanges()

  };
  
 
/**
 * This function is used to set the optional date filter
 */
  optionalDateFilter(event, filterIs, val, index) {
    filterIs.selectedFilter = event.value;

    if (index != -1) {
      this.PacketsForm.controls.FILTERS["controls"][index].controls["VALUE"].reset();
      this.PacketsForm.controls.FILTERS["controls"][index].controls["VALUE2"].reset();
    }

    this.addFilter["VALUE"] = "";
    this.addFilter["VALUE2"] = "";

  };

  /**
   * This function is used to add the filter
   */
  async addfilter(IsfilterData, oprationalFilter, evt) {
    // alert(" >> value changed >> ")

    if (!this.TableColumnsService.checkValidation(this.addFilter.VALUE, this.addFilter.VALUE2, this.addFilter.OPERATION, IsfilterData.FILTERTYPE)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }
    await this.addDynamicFilter(IsfilterData);

    let sIndex = this.optionalFilters.length - 1;
    this.cd.detectChanges();
    setTimeout(() => {
      this.PacketsForm.controls.FILTERS["controls"][sIndex].controls["OPERATION"].setValue(this.addFilter.OPERATION);
    this.PacketsForm.controls.FILTERS["controls"][sIndex].controls["VALUE"].setValue(this.addFilter.VALUE);
    this.PacketsForm.controls.FILTERS["controls"][sIndex].controls["VALUE2"].setValue(this.addFilter.VALUE2);
    this.PacketsForm.controls.FILTERS["controls"][sIndex].controls["FILTERTYPE"].setValue(IsfilterData.FILTERTYPE);
    }, 500);
    this.PacketsForm.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
    this.PacketsForm.value.FILTERS[sIndex].VALUE2 = this.addFilter.VALUE2;
    this.PacketsForm.value.FILTERS[sIndex].OPERATION = this.addFilter.OPERATION;
    this.PacketsForm.value.FILTERS[sIndex].FILTERTYPE = IsfilterData.FILTERTYPE;


    // Advance Filter.
    IsfilterData.AdvancedFilter = this.PacketsForm.value.FILTERS[sIndex];
    let advFilters = JSON.parse(localStorage.getItem('Packets_Advance_Filter'));
    if (advFilters) {
      this.saveAdvanceFilter = advFilters;
    };

    this.saveAdvanceFilter.push(IsfilterData);
    localStorage.setItem("Packets_Advance_Filter", JSON.stringify(this.saveAdvanceFilter));

    if (this.FILTERS.length == 1) {
      this.PacketsForm.patchValue({
        FILTERS: [{
          OPERATION: this.addFilter.OPERATION,
          VALUE: this.addFilter.VALUE,
          VALUE2: this.addFilter.VALUE2,
          FILTERTYPE: IsfilterData.FILTERTYPE
        }]
      });
    } else {
      this.FILTERS.value.forEach((filterData, index) => {
        if (sIndex == index) {
          filterData = {
            OPERATION: this.addFilter.OPERATION,
            VALUE: this.addFilter.VALUE,
            VALUE2: this.addFilter.VALUE2,
            FILTERTYPE: IsfilterData.FILTERTYPE
          }
        }
      });

      this.PacketsForm.patchValue({
        FILTERS: this.FILTERS.value
      });

    }

    //After getting data appy filter.
    this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

    $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 260)) + 'px');
  };

  /**
   * This function is used to add the Dynamic filer
   */
  addDynamicFilter(event , type?) {
    if (event.FILTERTYPE == 'DATE') {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [{
        id: 1,
        value: 'days'
      }, {
        id: 2,
        value: 'months'
      }]
    };


    if (event.FILTERTYPE !== 'DATE' && event.FILTERTYPE !== 'CURRENCY' && event.FILTERTYPE !== 'NUMBER') {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
    }

    if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(event.FILTERTYPE)
    }

    event.FILTERAPPLY = true; //for filter hide/show from optional filter list.

    if(!type){
        this.optionalFilters.push(event);
    }
    // OPTIONAL FILTER
    this.FILTERS = this.PacketsForm.get('FILTERS') as FormArray;
    this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));

  };

  /**
   * This function is used to create the op filer
   */
  createOPFilter(type): FormGroup {
    return this._formBuilder.group({
      OPERATION: (type == "DATE") ? 'is in the last' : (type == 'CURRENCY' || type == 'NUMBER') ? 'is equal to' : 'contains',
      VALUE: '',
      VALUE2: '',
      FILTERTYPE: type
    });
  };

  /**
   * This function is used to apply the filter
   */
  applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any) {

    // new code for validation
    let saveAdvancefilterData = JSON.parse(localStorage.getItem("Packets_Advance_Filter"));
    const field1Val = this.PacketsForm.controls.FILTERS["controls"][isIndex].controls["VALUE"].value
    const field2Val = this.PacketsForm.controls.FILTERS["controls"][isIndex].controls["VALUE2"].value
    const operationVal = this.PacketsForm.controls.FILTERS["controls"][isIndex].controls["OPERATION"].value
    const filterTypeVal = this.PacketsForm.controls.FILTERS["controls"][isIndex].controls["FILTERTYPE"].value

    if (!this.TableColumnsService.checkValidation(field1Val, field2Val, operationVal, filterTypeVal)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }

    // ends here ~ new code for validation

    //addData.selectedFilter = OpretionIs.triggerValue;
    let addData = JSON.parse(JSON.stringify(FilterData));
    addData.selectedFilter = OpretionIs.triggerValue
    const applyFilterData = this.TableColumnsService.Optionalfilter(addData, isIndex, this.optionalDateFilterPayload, this.PacketsForm);
    this.optionalDateFilterPayload = applyFilterData;

    // Advance Dynamic filter when it's change any data.
    this.TableColumnsService.setAdvanceFilter(saveAdvancefilterData, this.saveAdvanceFilter, isIndex, FilterData, field1Val, field2Val, operationVal, filterTypeVal, applyFilterData, 'Packets');
    this.doFilter(this.optionalDateFilterPayload);
  };

  /**
   * This function is used to do the filter
   */
  doFilter(mainFilter: any) {
    let payload = JSON.parse(JSON.stringify(this.filterData));
    // payload.FILTERS = this.TableColumnsService.optionalDOFilters(mainFilter , this.activeFilters);
    payload.AdvancedFilters = this.TableColumnsService.optionalDOFilters(mainFilter, this.activeFilters);
    this.LoadData(payload);
  };

  /**
   * This function is used to set the default data
   */
  async setDefaultAdvanceFilter() {
    // Advance Filter If Already have.
    let advanceFiltes = JSON.parse(localStorage.getItem('Packets_Advance_Filter'));

    if (advanceFiltes) {
      this.optionalDateFilterPayload = [];
      this.optionalFilters = advanceFiltes;
      await advanceFiltes.forEach(async (filterData, index) => {
        await this.addDynamicFilter(filterData.AdvancedFilter, 'default');

        this.FILTERS.value[index].OPERATION = await filterData.AdvancedFilter.OPERATION;
        this.FILTERS.value[index].FILTERTYPE = await filterData.AdvancedFilter.FILTERTYPE;
        this.FILTERS.value[index].VALUE = await filterData.AdvancedFilter.VALUE;
        this.FILTERS.value[index].VALUE2 = await filterData.AdvancedFilter.VALUE2;
        await this.optionalDateFilterPayload.push(filterData.FilterHeadings);

        await this.PacketsForm.patchValue({
          FILTERS: this.FILTERS.value
        });
      });
    };
  }

  /**
   * This function is used to clear the All filter
   */
  clearAllFilter() {

    // reset FILTERAPPLY value
    this.optionalFilterList.forEach((filter, index) => {
      filter.FILTERAPPLY = false;
    })
    // ends here ~ reset FILTERAPPLY value

    let resetFilter = JSON.parse(localStorage.getItem("packets_filter"));
    this.optionalDateFilterPayload = [];
    this.optionalFilters = [];

    if (this.FILTERS) {
      this.FILTERS.reset();
      this.saveAdvanceFilter = [];
    };

    localStorage.removeItem('Packets_Advance_Filter');
    setTimeout(() => {
      // resetFilter.ITEMDATESTART = "";
      // resetFilter.ITEMDATEEND = "";

      // let currentDate = new Date()
      // let updatecurrentDate = new Date();
      // updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
      this.forHideShowDateRangePicker = false;
      ////this.PacketsForm.controls['dateRang'].setValue({ begin: updatecurrentDate, end: currentDate });
  
      // let begin = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      // let end = this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      // this.activeFilters.daterange = begin + '-' + end;
      // resetFilter.ITEMDATESTART = begin;
      // resetFilter.ITEMDATEEND = end;
      // resetFilter.STATUS = "";
      resetFilter.Search = "";
      localStorage.setItem('packets_filter', JSON.stringify(resetFilter));
      ////this.PacketsForm.controls['DayRange'].setValue('Last 90 days');
      this.forHideShowDateRangePicker = false;
      ////this.activeFilters.dayRange = "Last 90 days";

      delete resetFilter.FILTERS;
      delete resetFilter.AdvancedFilters;


      // this.DateType = 'Incurred Date';
      ////this.PacketsForm.controls['STATUS'].setValue('');
      this.PacketsForm.controls['SEARCH'].setValue("");
      // this.MainSafeCustody.controls['DateRange'].reset();
      // this.MainSafeCustody.controls['searchFilter'].reset();

      delete resetFilter.DateType;
      this.LoadData(resetFilter)
    }, 200);
  };

  /**
   * This functio is used to refresh the Packets
   */
  refreshPackets(){
   // let resetFilter = JSON.parse(localStorage.getItem("packets_filter"));
   this.doFilter(this.optionalDateFilterPayload);
  }

  /**
   * This function is used to reset the optional filter
   */
  resetOptionalFilter(event, type, isindex) {
    let index = this.optionalFilters.findIndex((e) => e.COLUMNID == event.COLUMNID);
    setTimeout(() => {
      this.optionalFilterList.map((x)=>{
        if(this.optionalFilters.findIndex((e)=> x.COLUMNID == event.COLUMNID) !== -1){
          x.FILTERAPPLY = false;
        };
      });
    }, 100);
    event.FILTERAPPLY = false; //for filter hide/show from optional filter list.

    this.optionalFilters.splice(index, 1);
    this.optionalDateFilterPayload.splice(isindex, 1);
    this.FILTERS.controls.splice(isindex, 1);
    this.PacketsForm.value.FILTERS.splice(isindex, 1);
    this.saveAdvanceFilter.splice(isindex, 1);

    //Advance Filter.
    this.TableColumnsService.refrshAdvanceFilter(event, 'Packets');

    //Delete Filters
    //const resetFilter = JSON.parse(localStorage.getItem('matter_filter'));
    const resetFilter = JSON.parse(JSON.stringify(this.filterData));
    const ArrayResetOptional = JSON.parse(JSON.stringify(this.PacketsForm.value.FILTERS));
    const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
      if (this.optionalFilters[index]) {
        data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
      }
      return data;
    })

    const finalPayload = ArrayResetOptionalNew.filter((d) => d['VALUE'] !== null && d['VALUE'] !== '').map((e) => {
      if (e['VALUE'] && e['VALUE'] !== '') {
        delete e.COLUMNID;

        if (e.VALUE2 == null) {
          e.VALUE2 = "";
        }
        //e.COLUMNNAME = event.COLUMNNAME;
        return e;
      }
    });

    // resetFilter.FILTERS = finalPayload;
    resetFilter.AdvancedFilters = finalPayload;
    if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
      // delete resetFilter.FILTERS;
      delete resetFilter.AdvancedFilters;
    };

    this.LoadData(resetFilter);
  }

          /**
 * This function is used to getNewExportData;
 */
          getNewExportData(){
            this.behaviorService.loaderDATA$.next(true);
            this.TableColumnsService.getNewExportData("Safe Custody","Packets","safe-custody-packet",this.gloableAdvancedFilters,this.GloableadvanceFilterPayload) 
          }
}
