<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div *ngIf="showMainPopUp =='Yes'" cdkDrag
    class="popup_main_div add_invoice_div_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">
        {{invoiceTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main less_space_field">
        <div class="one" fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <form name="addInvoiceForm" id="addInvoiceForm"
                [formGroup]="addInvoiceForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div class="fixed_uppar_fields" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto">
                    <div fxLayout="row" fxLayoutAlign="start center"
                        fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100"
                            class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.MATTER,'has-warning':errorWarningData.Warning?.MATTER}">
                            <mat-label>Matter</mat-label>
                            <input matInput readonly placeholder="Matter"
                                [matTooltip]="(tootipData && tootipData.MATTER)?tootipData.MATTER : ''"
                                formControlName="MATTER">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center"
                        fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="25"
                            class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.InvoiceCode,'has-warning':errorWarningData.Warning?.InvoiceCode}">
                            <mat-label>Invoice No</mat-label>
                            <input matInput placeholder="Invoice No"
                                [disabled]="isspiner"
                                [matTooltip]="(tootipData && tootipData.INVOICECODE)?tootipData.INVOICECODE : ''"
                                formControlName="INVOICECODE">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="25"
                            class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.INVOICEDATE,'has-warning':errorWarningData.Warning?.INVOICEDATE}">
                            <mat-label>Invoice Date</mat-label>
                            <input [disabled]="isspiner"
                                (dateInput)="invoiceDateChange('input', $event)"
                                [matTooltip]="(tootipData && tootipData.INVOICEDATETEXT)?tootipData.INVOICEDATETEXT : ''"
                                formControlName="INVOICEDATETEXT"
                                (dateChange)="invoiceDateChange('change', $event)"
                                matInput [matDatepicker]="picker3">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="25"
                            class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.DUEDATE,'has-warning':errorWarningData.Warning?.DUEDATE}">
                            <mat-label>Due Date</mat-label>
                            <input (dateInput)="dueDateChange('input', $event)"
                                [disabled]="isspiner"
                                [matTooltip]="(tootipData && tootipData.DUEDATETEXT)?tootipData.DUEDATETEXT : ''"
                                formControlName="DUEDATETEXT"
                                (dateChange)="dueDateChange('change', $event)"
                                matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="pr-4"
                            fxFlex="25" *ngIf="(('Foreign Currency Invoices' | tierPermission : 'Foreign Currency Invoices') && ('Foreign Currency Invoices' | tierPermission : 'Foreign Currency Invoices').ALLOWED ==1)">
                            <mat-label>Currency</mat-label>
                            <mat-select
                                (selectionChange)="currencyChange($event.value)"
                                [matTooltip]="(tootipData && tootipData.currencyType)?tootipData.currencyType : ''"
                                formControlName="currencyType">
                                <mat-option value="Default">Default</mat-option>
                                <mat-option value="USD">US Dollar</mat-option>
                                <mat-option value="UKP">UK Pound</mat-option>
                                <mat-option value="Euro">Euro</mat-option>
                                <mat-option value="GBP ">Great British Pound</mat-option>
                                <mat-option value="NZD">New Zealand Dollar</mat-option>
                                <mat-option value="CAD">Canadian Dollar</mat-option>
                                <mat-option value="JPY">Japanese Yen</mat-option>
                                <mat-option value="CNY">Chinese Yuan</mat-option>
                                <mat-option value="VND">Vietnamese Dong</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- tab detail start -->

                <div class="send_mail_body_div send_mail_body_divwrap">
                    <div class="extra_scroll" fxLayout="row"
                        fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <div class="invoice_body_scroll">
                            <mat-tab-group [selectedIndex]="activeTab"
                                class="add-invoice-tab" mat-align-tabs="start">
                                <mat-tab label="Details">
                                    <app-details
                                        class="invoice_add_dialoug_form"
                                        [addInvoiceForm]="addInvoiceForm"
                                        (totalDataOut)=changeTotalDataOut($event)>
                                    </app-details>
                                </mat-tab>
                                <mat-tab label="Summary"
                                    *ngIf="invoiceType!='preview'">
                                    <app-summary
                                        [addInvoiceForm]="addInvoiceForm"
                                        [isMax]="isMax" [isMin]="isMin"
                                        [isFixPrice]="isFixPrice">
                                    </app-summary>
                                </mat-tab>
                                <mat-tab label="Comment"
                                    *ngIf="invoiceType!='preview'">
                                    <div fxLayout="row wrap"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto">
                                        <mat-form-field appearance="outline"
                                            fxFlex="100"
                                            class="third_textarea_margin">
                                            <mat-label>Comment</mat-label>
                                            <textarea matInput
                                                [matTooltip]="(tootipData && tootipData.COMMENT)?tootipData.COMMENT : ''"
                                                formControlName="COMMENT"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <mat-divider></mat-divider>
                                </mat-tab>
                                <mat-tab label="Discount/Increase"
                                    *ngIf="invoiceType!='preview' && (('Invoice Discounting' | tierPermission : 'Invoice Discounting') && ('Invoice Discounting' | tierPermission : 'Invoice Discounting').ALLOWED ==1)" >
                                    <app-discount-increase
                                        [addInvoiceForm]="addInvoiceForm"
                                        [allItemsTotalObj]="allItemsTotalObj"
                                        (discountAmount)=changeDiscountAmount($event)></app-discount-increase>
                                </mat-tab>
                                <mat-tab
                                    *ngIf="this.f.currencyType.value !='Default' &&  (('Foreign Currency Invoices' | tierPermission : 'Foreign Currency Invoices') && ('Foreign Currency Invoices' | tierPermission : 'Foreign Currency Invoices').ALLOWED ==1)"
                                    label="Foreign Currency">
                                    <app-foreign-currency
                                        [addInvoiceForm]="addInvoiceForm">
                                    </app-foreign-currency>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                    <div class="btm_invoice_cmn" *ngIf="!isDisable">
                        <div class="value_label_parent">
                            <div fxFlex="36" class="label_width">
                                Original Total
                            </div>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="value_width extra_pad">
                                <input class="sideText" currencyMask matInput
                                    readonly placeholder="Date Received"
                                    [(ngModel)]="ORIEXTOTAL"
                                    [matTooltip]="(tootipData && tootipData.ORIEXTOTAL)?tootipData.ORIEXTOTAL : ''"
                                    formControlName="ORIEXTOTAL">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="value_width extra_pad">
                                <input class="sideText" matInput currencyMask
                                    readonly placeholder="Date Received"
                                    [(ngModel)]="ORIGSTTOTAL"
                                    [matTooltip]="(tootipData && tootipData.ORIGSTTOTAL)?tootipData.ORIGSTTOTAL : ''"
                                    formControlName="ORIGSTTOTAL">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="value_width extra_pad">
                                <input class="sideText" matInput currencyMask
                                    readonly placeholder="Date Received"
                                    [(ngModel)]="ORIINTOTAL"
                                    [matTooltip]="(tootipData && tootipData.ORIINTOTAL)?tootipData.ORIINTOTAL : ''"
                                    formControlName="ORIINTOTAL">
                            </mat-form-field>
                        </div>
                        <div class="value_label_parent">
                            <div fxFlex="36" class="label_width">
                                Discount
                            </div>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="value_width extra_pad">
                                <input class="sideText" currencyMask matInput
                                    readonly
                                    [matTooltip]="(tootipData && tootipData.DISEXAMOUNT)?tootipData.DISEXAMOUNT : ''"
                                    formControlName="DISEXAMOUNT"
                                    [(ngModel)]="DISEXAMOUNT">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="value_width extra_pad">
                                <input class="sideText" matInput currencyMask
                                    readonly
                                    [matTooltip]="(tootipData && tootipData.DISGSTAMOUNT)?tootipData.DISGSTAMOUNT : ''"
                                    formControlName="DISGSTAMOUNT"
                                    [(ngModel)]="DISGSTAMOUNT">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="value_width extra_pad">
                                <input class="sideText" currencyMask matInput
                                    readonly
                                    [matTooltip]="(tootipData && tootipData.DISUINAMOUNT)?tootipData.DISUINAMOUNT : ''"
                                    formControlName="DISUINAMOUNT"
                                    [(ngModel)]="DISUINAMOUNT">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- tab detail end -->
                <div class="fixed_lower_fields">
                    <mat-divider></mat-divider>
                    <div class="value_label_parent">
                        <div fxFlex="36" class="label_width">
                            {{TotalTitle}}
                        </div>
                        <mat-form-field appearance="outline" fxFlex="20"
                            class="value_width">
                            <!-- currencyMask -->
                            <input class="sideText" [value]="addInvoiceForm.get('OVEEXTOTAL').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." style="text-align: right;" matInput
                                [disabled]="isspiner" placeholder
                                (focusout)="calculateOverrideTotal('ex')"
                                [(ngModel)]="OVEEXTOTAL"
                                [matTooltip]="(tootipData && tootipData.OVEEXTOTAL)?tootipData.OVEEXTOTAL : ''"
                                formControlName="OVEEXTOTAL" [leadZero]="true">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="20"
                            class="value_width">
                            <input class="sideText" currencyMask matInput
                                readonly placeholder [(ngModel)]="OVEGSTTOTAL"
                                [matTooltip]="(tootipData && tootipData.OVEGSTTOTAL)?tootipData.OVEGSTTOTAL : ''"
                                formControlName="OVEGSTTOTAL">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="20"
                            class="value_width">
                            <input class="sideText" [value]="addInvoiceForm.get('OVEINTOTAL').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." style="text-align: right;" matInput
                                [disabled]="isspiner" placeholder
                                (focusout)="calculateOverrideTotal('in')"
                                [(ngModel)]="OVEINTOTAL"
                                [matTooltip]="(tootipData && tootipData.OVEINTOTAL)?tootipData.OVEINTOTAL : ''"
                                formControlName="OVEINTOTAL" [leadZero]="true">
                        </mat-form-field>
                    </div>
                </div>

                <!-- *ngIf="('Protected Trust' | tierPermission : 'Protected Trust').ALLOWED ==1" -->
                <div>
                <div
                    *ngIf="appPermissions[31].Create == 1 && currentUser?.PRODUCTTYPE =='Solicitor'">
                    <!-- As per the client New Requirement we show it in the All Cases -->
                    <!--"appPermissions[31].Access == 1 && currentUser?.PRODUCTTYPE =='Solicitor' && invoiceType == 'Invoice All WIP'"  -->
                    <mat-divider></mat-divider>
                    <div class="extra_pad Request">
                        <mat-slide-toggle
                            [checked]="RequestProtectedTrustAmount"
                            (change)="RequestProtectedTrustAmountToggle()">Request
                            Protected Trust
                            Amount:</mat-slide-toggle>

                        <mat-form-field appearance="outline" fxFlex="20"
                            class="value_width extra_pad ">
                            <mat-label>Ledger Balance</mat-label>
                            <input class="sideText" currencyMask matInput
                                formControlName="TRUSTBALANCE"
                                placeholder="Matter Ledger Balance"
                                [(ngModel)]="matterDetail.TRUSTBALANCE">
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="20"
                            class="value_width extra_pad">
                            <input class="sideText" [value]="addInvoiceForm.get('PROTECTEDTRUSTAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." style="text-align: right;" matInput
                                formControlName="PROTECTEDTRUSTAMOUNT" [leadZero]="true">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <mat-form-field appearance="fill" class="generate-invoice mr-20 pr-invoice" *ngIf="invoiceType == 'preview'">
            <mat-label>Invoice Template</mat-label>
            <mat-select  (selectionChange)="selectTamplate($event)" required  name="defaultLookupValue" [(value)]="defaultLookupValue" >
              <!-- <mat-option>--</mat-option> -->
              <mat-option *ngFor="let val of InvoiceTamplateList" [value]="val.LOOKUPGUID"> {{val.LOOKUPFULLVALUE}}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
            <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
          </mat-form-field>

        <button mat-raised-button color="accent" class="mat-accent"
            *ngIf="!isDisable" (click)="SaveInvoice()"
            [disabled]="isLoadingResults">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent"
            *ngIf="isDisable" (click)="defultInvoiceFunction(false)"
            [disabled]="isLoadingResults">
            <mat-spinner style="zoom: 0.2;position: absolute;right: 0;left: 0;
        margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Preview
        </button>
        <button mat-raised-button color="primary" *ngIf="!isspiner"
            [mat-dialog-close]="false" cdkFocusInitial>Don`t
            Save</button>
        <button mat-raised-button color="primary" *ngIf="isspiner"
            [mat-dialog-close]="false" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
</div>
<!-- </form> -->

<div *ngIf="showMainPopUp =='No'" cdkDrag
    class="popup_main_div add_invoice_div_main">
    <h2 cdkDragHandle mat-dialog-title>Prepare Invoive
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <div fxLayout="row" fxFlex="1 0 auto">
                <div class="pr-4" fxLayout="column" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <div fxFlex="70" fxLayoutAlign="start start">
                        <p>The invoice total must be larger than or equal to
                            zero and have at least one item. Please select at
                            least one item. </p>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent"
            (click)="showMainPopUp='Yes';DisabledRequestProtectedTrustAmount()"
            cdkFocusInitial>Ok</button>
        <!-- <button mat-raised-button color="primary" (click)="closeAllDialoge()" [mat-dialog-close]="false" cdkFocusInitial>No</button> -->
    </mat-dialog-actions>
</div>
