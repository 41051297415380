<!-- <router-outlet></router-outlet> -->  
<div class="one">
    <!-- <router-outlet></router-outlet> -->
</div>
  <mat-drawer-container class="matter-details-sidebar" autosize [ngClass]="{ 'container-closed': !showFiller }">
    <mat-drawer #drawer class="sidebar-container example-sidenav matter-details-side-nav position-relative" mode="side" disableClose="true" opened="true">
        <!-- <app-matter-details-sidebar [showFiller]="showFiller" class="sidebar-container"></app-matter-details-sidebar> -->
        <ng-container>
            <div class="menu-item">
                <mat-nav-list class="nav-item cursor-pointer" routerLinkActive="" [routerLinkActiveOptions]="{exact: true}">
                    <a mat-list-item
                    class="mat-nav-link"
                    [ngClass]="activeSubMenu =='reconciliation-item'  ? 'cmn_active_tab':''"
                        [routerLink]="'/account-reconciliation/reconciliation-item'">
                        <mat-icon>
                            <img src="assets/icons/web_app/icon_tagitem_d.ico">
                        </mat-icon>
                        <span class="matter-detail-text">Reconciliation Items</span>
                    </a>
                </mat-nav-list>

                <!-- <mat-nav-list *ngIf="chartAccountDetail && (chartAccountDetail.Lable == 'Select Account' || chartAccountDetail.Lable == 'CHART OF ACCOUNTS')" class="nav-item" routerLinkActive="" [routerLinkActiveOptions]="{exact: true}">
                    <a mat-list-item
                    class="mat-nav-link"
                    [ngClass]="activeSubMenu == 'bank-feed' || activeSubMenu == 'bank-feed#connect'  ? 'cmn_active_tab':''"
                    [routerLink]="'/account-reconciliation/bank-feed'">
                        <mat-icon>
                            <img src="assets/icons/web_app/icon_tagitem_d.ico">
                        </mat-icon>
                        <span class="matter-detail-text">Bank Feed</span>
                    </a>
                </mat-nav-list> -->

                <mat-nav-list class="nav-item"  routerLinkActive="" [routerLinkActiveOptions]="{exact: true}">
                    <a mat-list-item class="mat-nav-link" [routerLink]="'/account-reconciliation/past-reconciliation'"
                    [ngClass]="activeSubMenu =='past-reconciliation'  ? 'cmn_active_tab':''">
                        <mat-icon>
                            <img src="assets/icons/web_app/icon_tagitem_d.ico">
                        </mat-icon>
                        <span class="matter-detail-text">Past Reconciliations</span>
                    </a>
                </mat-nav-list>

                <mat-nav-list class="nav-item" routerLinkActive="" [routerLinkActiveOptions]="{exact: true}">
                    <a mat-list-item class="mat-nav-link" [routerLink]="'/account-reconciliation/past-banking'" [ngClass]="activeSubMenu =='past-banking'  ? 'cmn_active_tab':''">
                        <mat-icon>
                            <img src="assets/icons/web_app/icon_receipt_d.ico">
                        </mat-icon>
                        <span class="matter-detail-text">Past Bankings</span>
                    </a>
                </mat-nav-list>
            </div>
        </ng-container>
    </mat-drawer>
    <div class="example-sidenav-content legal-detail-overflow position-relative">
        <!-- <div class="miscallaneous-class"> -->
          <!-- matter info -->
          <!-- ends here ~ matter info -->
            <router-outlet></router-outlet>
        <!-- </div> -->
    </div>
  </mat-drawer-container>