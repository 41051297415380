import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { QuickPanelModule } from 'src/app/layout/components/quick-panel/quick-panel.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { ToolbarComponent } from 'src/app/layout/components/toolbar/toolbar.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { NotificationsModule } from '../notifications/notifications.module';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { LoggedonUserComponent } from 'src/app/main/pages/account-managment/loggedon-user/loggedon-user.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/Shared/shared.module';
import { LoggedonUserComponent } from '@pages/account-managment/loggedon-user/loggedon-user.component';
import { PipesModule } from '@_pipes/pipes.module';


@NgModule({
    declarations: [
        ToolbarComponent,

    ],
    imports: [
        FormsModule,
        MatSlideToggleModule,
        ScrollingModule,
        RouterModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatTabsModule,
        MatListModule,
        MatCardModule,
        MatBadgeModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseSidebarModule,
        FuseShortcutsModule,
        QuickPanelModule,
        NotificationsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        PipesModule,
        SharedModule
    ],
    exports: [
        ToolbarComponent,
    ],
    entryComponents: [
        LoggedonUserComponent
    ]
})
export class ToolbarModule {
}
