// import { Component, OnInit, Inject } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';
// import { environment } from 'src/environments/environment';
// import { MAT_DIALOG_DATA } from '@angular/material';
// import * as $ from 'jquery';

// @Component({
//   selector: 'app-end-of-month-reports',
//   templateUrl: './end-of-month-reports.component.html',
//   styleUrls: ['./end-of-month-reports.component.scss']
// })
// export class EndOfMonthReportsComponent implements OnInit {
//   isGenrateReport: boolean = false;
//   isLoadingResults: boolean = false;
//   currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
//   btnText = '';
//   title = '';
//   progress: any = 5;
//   webSocket = new WebSocket(environment.WSReportUrl);
//   reportList: any = [
//     { 'ID': 'trustreportscashbook', "TITILE": 'Trust Cashbook', 'isGeenrate': false, 'URL': '', isButtonEnable: false },
//     { 'ID': 'trusttrialbalance', "TITILE": 'Trial Balance', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//     { 'ID': 'trustcontrolaccount', "TITILE": 'Control Account', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//     { 'ID': 'trusttransferjournal', "TITILE": 'Transfer Journal', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//     { 'ID': 'trustdebitbalance', "TITILE": 'Debit Balance', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//     { 'ID': 'filemaintenanceaudit', "TITILE": 'Audit Log', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//     { 'ID': 'trustreportcontrolledaccountlisting', "TITILE": 'Controlled Listing', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//     { 'ID': 'trustreportcontrolledcashbook', "TITILE": 'Controlled Cashbook', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
//   ];
//   constructor(
//     private _mainAPiServiceService: MainAPiServiceService,
//     private toastr: ToastrService,
//     @Inject(MAT_DIALOG_DATA) public reportDataDefult: any,
//     private behaviorService: BehaviorService
//   ) { }

//   ngOnInit() {
//     this.openWSConnection(this.currentUser.SessionToken);
//     let firstReport = this.reportList[0];
//     this.ReportGenerate(firstReport.ID, this.reportDataDefult.ENDOFMONTHGUID, 0);
//     // this.title = firstReport.TITILE;
//     // this.reportList.forEach((element, index) => {
//     // this.ReportGenerate(element.ID, this.reportDataDefult.ENDOFMONTHGUID, index);
//     // });
//   }
//   ReportGenerate(ReportID, endofMonthId, index) {
//     this.title = this.reportList[index]['TITILE'];
//     this.isGenrateReport = this.isLoadingResults = true;
//     let ReportData: any = {
//       REPORTID: ReportID,
//       ENDOFMONTHGUID: endofMonthId
//     };
//     //Api Report Generate
//     this._mainAPiServiceService.SetReportGenerate(ReportData, 'ReportGenerate').subscribe(result => {
//       if (result.PDF_Generation.CODE == 200 && result.PDF_Generation.STATUS == "success") {
//         this.behaviorService.setEndOfMonthDataSelected({isClear:true});
//         this.reportList[index]['isGeenrate'] = true;
//         if (this.reportList[index + 1]) {
//           this.reportList[index + 1]['isButtonEnable'] = false;
//         }
//         this.reportList[index]['URL'] = environment.ReportUrl + result.PDF_Generation.DATA.PDFFILENAME;
//         if (this.reportList[index + 1]) {
//           setTimeout(() => {
//             this.ReportGenerate(this.reportList[index + 1].ID, this.reportDataDefult.ENDOFMONTHGUID, index + 1);
//           }, 600);
//         }
//         if (ReportID == "trustreportcontrolledcashbook") {
//           this.toastr.success('Report generated successfully');
//           $('#setendofMonth').click();
//           this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " ReportGenerateStatus");
//           this.webSocket.close();
//           this.isGenrateReport = false;
//         }
//       } else if (result.PDF_Generation.CODE == 415 && result.PDF_Generation.STATUS == 'error') {
//         this.toastr.error(result.PDF_Generation.MESSAGE);
//         this.isGenrateReport = false;
//       }
//       this.isLoadingResults = false;
//     }, error => {
//       this.toastr.error(error);
//       this.isGenrateReport = this.isLoadingResults = false;
//     });
//   }
//   openWSConnection(SessionToken: any) {
//     try {
//       let demoSoket = this.webSocket;
//       demoSoket.onopen = (openEvent) => {
//         demoSoket.send("watch channel " + SessionToken + " ReportGenerateStatus");
//       };
//       demoSoket.onclose = (closeEvent) => {
//         console.log(closeEvent);
//       };

//       demoSoket.onerror = (errorEvent) => {
//         console.log(errorEvent);
//       };

//       demoSoket.onmessage = (messageEvent) => {
//         let data = JSON.parse(messageEvent.data);
//         let details = JSON.parse(data.value);
//         this.progress = details.PROGRESS;
//         this.btnText = details.DESCRIPTION;
//       };
//     } catch (exception) {
//       console.error(exception);
//     }
//   }

// }

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';
import { SelectEmailPopupComponent } from '@pages/template/packs/select-email-popup/select-email-popup.component';
import { MatterDialogComponentForTemplate } from '@pages/template/matter-dialog/matter-dialog.component';
import { SocketService } from '@_services/socket.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
// import { SelectEmailPopupComponent } from '../pages/template/packs/select-email-popup/select-email-popup.component';
// import { MatterDialogComponentForTemplate } from '../pages/template/matter-dialog/matter-dialog.component';

@Component({
    selector: 'app-end-of-month-reports',
    templateUrl: './end-of-month-reports.component.html',
    styleUrls: ['./end-of-month-reports.component.scss']
})
export class EndOfMonthReportsComponent implements OnInit, OnDestroy {
    isGenrateReport: boolean = false;
    isLoadingResults: boolean = false;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    btnText = '';
    title = '';
    progress: any = 5;
    isEndOfMonthData: boolean = false;
    sub1: Subscription;
    // webSocket = new WebSocket(environment.WSReportUrl);
    // webSocket = new WebSocket(environment.MainSocketUrl);
    reportList: any = [
        { 'ID': 'trustreportscashbook', "TITILE": 'Trust Cashbook', 'isGeenrate': false, 'URL': '', isButtonEnable: false },
        { 'ID': 'trusttrialbalance', "TITILE": 'Trial Balance', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
        { 'ID': 'trustcontrolaccount', "TITILE": 'Control Account', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
        { 'ID': 'trusttransferjournal', "TITILE": 'Transfer Journal', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
        { 'ID': 'trustdebitbalance', "TITILE": 'Debit Balance', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
        { 'ID': 'filemaintenanceaudit', "TITILE": 'Audit Log', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
        { 'ID': 'trustreportcontrolledaccountlisting', "TITILE": 'Controlled Listing', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
        { 'ID': 'trustreportcontrolledcashbook', "TITILE": 'Controlled Cashbook', 'isGeenrate': false, 'URL': '', isButtonEnable: true },
    ];
    processEndOfMonthData: any = [];
    reportData: any;
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public reportDataDefult: any,
        private socketService: SocketService
    ) { }

       /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.reportData = [];
        this.sub1 = this.socketService.getWebSocketReportGenerationSubject().subscribe((details) => {
            if (details) {
                this.progress = details.PROGRESS;
                this.btnText = details.DESCRIPTION;
            }
        })
        // this.sub1 = this.socketService.openWSConnection(currentUser.SessionToken, 'ReportGenerateStatus')
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        // this.openWSConnection(this.currentUser.SessionToken);
        let firstReport = this.reportList[0];
        if (this.reportDataDefult.isEndOfMonthData) {
            this.reportDataDefult.processEndOfMonthData.forEach(element => {
                element.isButtonEnable = true;
                element.isGeenrate = false;
            });
            this.processEndOfMonthData = this.reportDataDefult.processEndOfMonthData
            this.isEndOfMonthData = this.reportDataDefult.isEndOfMonthData;
            this.processEndOfMonthData[0]['isButtonEnable'] = false;
            this.processEndOfMonthReport(this.reportDataDefult.processEndOfMonthData);
        } else {
            this.ReportGenerate(firstReport.ID, this.reportDataDefult.ENDOFMONTHGUID, 0);
        }

        // this.title = firstReport.TITILE;
        // this.reportList.forEach((element, index) => {
        // this.ReportGenerate(element.ID, this.reportDataDefult.ENDOFMONTHGUID, index);
        // });
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
    }

    /**
     * This function is used to processEndOfMonthReport
     * @param processEndOfMonthData -data value
     */
    async processEndOfMonthReport(processEndOfMonthData) {
        this.isGenrateReport = true;
        let numberOfData = processEndOfMonthData.length - 1;
        for (let i = 0; i < processEndOfMonthData.length; i++) {
            await new Promise<void>(async (next, reject) => {
                let ReportData: any = {};
                const uniqueId = this.socketService.generateUniqueId();
                this.socketService.openSocketReportGeneration(uniqueId);
                this.title = this.processEndOfMonthData[i]['SHORTDESC'];
                if (processEndOfMonthData[i].ITEMTYPE == '4. Unprinted Transactions') {
                    ReportData.REPORTID = "reporttrustrequest";
                    ReportData.TrustTransactionGuid = processEndOfMonthData[i].ITEMGUID;
                    if (this.reportDataDefult && this.reportDataDefult.ENDOFMONTHDATE) {
                        ReportData.ENDOFMONTHDATE = this.reportDataDefult.ENDOFMONTHDATE;
                    }
                } else if (processEndOfMonthData[i].ITEMTYPE == '5. Trust Account Statements') {
                    ReportData.REPORTID = "TrustSingleTrustAccountStatement";
                    ReportData.MATTERGUID = processEndOfMonthData[i].ITEMGUID;
                    if (this.reportDataDefult && this.reportDataDefult.ENDOFMONTHDATE) {
                        ReportData.ENDOFMONTHDATE = this.reportDataDefult.ENDOFMONTHDATE;
                    }
                } else if (processEndOfMonthData[i].ITEMTYPE == '6. Controlled Money Statements') {
                    ReportData.REPORTID = "TrustSingleControlledAccountStatement";
                    ReportData.ACCOUNTGUID = processEndOfMonthData[i].ITEMGUID;
                    if (this.reportDataDefult && this.reportDataDefult.ENDOFMONTHDATE) {
                        ReportData.ENDOFMONTHDATE = this.reportDataDefult.ENDOFMONTHDATE;
                    }
                }
                //Api Report Generate
                ReportData.TrackingGuid = uniqueId;
                this._mainAPiServiceService.SetReportGenerate(ReportData, 'ReportGenerate').subscribe(reportgenerateData => {
                    if (reportgenerateData.PDF_Generation.CODE == 200 && reportgenerateData.PDF_Generation.STATUS == "success") {
                        this.behaviorService.setEndOfMonthDataSelected({ isClear: true });
                        this.processEndOfMonthData[i]['isGeenrate'] = true;
                        this.reportData.push(reportgenerateData);
                        if (this.processEndOfMonthData[i + 1]) {
                            this.processEndOfMonthData[i + 1]['isButtonEnable'] = false;
                        }
                        this.processEndOfMonthData[i]['URL'] = environment.ReportUrl + reportgenerateData.PDF_Generation.DATA.PDFFILENAME;
                    } else if (reportgenerateData.PDF_Generation.CODE == 415 && reportgenerateData.PDF_Generation.STATUS == 'error') {
                        this.toastr.error(reportgenerateData.PDF_Generation.MESSAGE);
                    }
                    if (numberOfData == i) {
                        $('#setendofMonth').click();
                        this.toastr.success('Report generated successfully');
                        // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " ReportGenerateStatus");
                        // this.webSocket.close();
                        this.isGenrateReport = this.isLoadingResults = false;
                    }
                    next();
                    // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
                    this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                }, error => {
                    this.toastr.error(error);
                    this.isGenrateReport = this.isLoadingResults = false;
                    // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
                    this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                });
            });
        }
    }

    /**
     * This function is used to generate the Reports
     * @param ReportID -Report ID
     * @param endofMonthId -EndOfMonthId
     * @param index -index id
     */
    async ReportGenerate(ReportID, endofMonthId, index) {
        const uniqueId = this.socketService.generateUniqueId();
        this.socketService.openSocketReportGeneration(uniqueId);
        this.title = this.reportList[index]['TITILE'];
        this.isGenrateReport = this.isLoadingResults = true;
        let ReportData: any = {
            REPORTID: ReportID,
            ENDOFMONTHGUID: endofMonthId
        };
        ReportData.TrackingGuid = uniqueId;
        //Api Report Generate
        this._mainAPiServiceService.SetReportGenerate(ReportData, 'ReportGenerate').subscribe(result => {
            if (result.PDF_Generation.CODE == 200 && result.PDF_Generation.STATUS == "success") {
                this.behaviorService.setEndOfMonthDataSelected({ isClear: true });
                this.reportData.push(result);
                this.reportList[index]['isGeenrate'] = true;
                if (this.reportList[index + 1]) {
                    this.reportList[index + 1]['isButtonEnable'] = false;
                }
                this.reportList[index]['URL'] = environment.ReportUrl + result.PDF_Generation.DATA.PDFFILENAME;
                if (this.reportList[index + 1]) {
                    setTimeout(() => {
                        this.ReportGenerate(this.reportList[index + 1].ID, this.reportDataDefult.ENDOFMONTHGUID, index + 1);
                    }, 600);
                }
                if (ReportID == "trustreportcontrolledcashbook") {
                    this.toastr.success('Report generated successfully');
                    $('#setendofMonth').click();
                    // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " ReportGenerateStatus");
                    // this.webSocket.close();
                    this.isGenrateReport = false;
                }
            } else if (result.PDF_Generation.CODE == 415 && result.PDF_Generation.STATUS == 'error') {
                this.toastr.error(result.PDF_Generation.MESSAGE);
                this.isGenrateReport = false;
            }
            this.isLoadingResults = false;
            // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
            this.socketService.disconnectWebSocketReportGeneration(uniqueId);
        }, error => {
            this.toastr.error(error);
            this.isGenrateReport = this.isLoadingResults = false;
            // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
            this.socketService.disconnectWebSocketReportGeneration(uniqueId);
        });
    }

    /**
     * This function is used to Generate the Email
     * @param data -data Value
     */
    GenerateEmail(data: any):void {
        let index = this.reportData.findIndex((e) => e.PDF_Generation.DATA.MATTERGUID == data.ITEMGUID);
        let selectedItem;

        if (index !== -1) {
            selectedItem = this.reportData[index];
        } else {
            selectedItem = this.reportData[0];
        }

        selectedItem = (selectedItem && selectedItem.PDF_Generation) ? selectedItem.PDF_Generation : selectedItem;
        let DocumentData: any = { DOCUMENTGUID: (selectedItem.DATA) ? selectedItem.DATA.DOCUMENTGUID : '' };
        let DocumentName: any = { name: (selectedItem && selectedItem.DATA && selectedItem.DATA.DOCUMENTNAME) ? selectedItem.DATA.DOCUMENTNAME : '', index: 9999, };
        const dialogRef = this.dialog.open(SelectEmailPopupComponent, { width: '100%', disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let EmailtemplateData: any = '';
                this.behaviorService.EmailGenerateData$.subscribe(result => {
                    if (result) {
                        EmailtemplateData = result;
                    }
                });

                let matterData = (selectedItem && selectedItem.DATA && selectedItem.DATA.MATTERGUID) ? selectedItem.DATA : JSON.parse(localStorage.getItem('set_active_matters'));
                this.behaviorService.MatterData(null);
                let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, DocumentData: DocumentData, DocumentName: DocumentName, fromType: 'matter' }
                this.ForEmailDialogOpen(passdata);

            }
        });
    }

    /**
     * This function is used to ForEmailDialog open
     * @param passdata -Passdata
     */
    ForEmailDialogOpen(passdata):void {
        const dialogRef = this.dialog.open(MatterDialogComponentForTemplate, { width: '100%', disableClose: true, data: passdata });
        dialogRef.afterClosed().subscribe(result => { });
    }
    // openWSConnection(SessionToken: any) {
    //     try {
    //       let demoSoket = this.webSocket;
    //       demoSoket.onopen = (openEvent) => {
    //         demoSoket.send("watch channel " + SessionToken + " ReportGenerateStatus");
    //       };
    //       demoSoket.onclose = (closeEvent) => {
    //         console.log(closeEvent);
    //       };

    //       demoSoket.onerror = (errorEvent) => {
    //         console.log(errorEvent);
    //       };

    //       demoSoket.onmessage = (messageEvent) => {
    //         let data = JSON.parse(messageEvent.data);
    //         let details = JSON.parse(data.value);
    //         this.progress = details.PROGRESS;
    //         this.btnText = details.DESCRIPTION;
    //       };
    //     } catch (exception) {
    //       console.error(exception);
    //     }
    // }



}
