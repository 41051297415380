import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-criminal',
    templateUrl: './criminal.component.html',
    styleUrls: ['./criminal.component.scss']
})
export class CriminalComponent implements OnInit {
    CourtData: any;
    DivisionData: any;
    RegistryData: any;
    ListData: any;
    MatterCriminalForm: FormGroup;
    errorWarningData: any = {};
    @Input() classType: any;
    MatterData: any = {};
    @Input() action: any;
    @Input() testBool;
    @Input() toolTipList: any;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    toolTipPostion = "above";
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData()
        this.behaviorService.SaveCriminalDetailFlag$.subscribe((data) => {
            if (data) {
                this.SaveCriminal();
            }
        });
    }

    // @Input() matterdetailForm: FormGroup;
    // @Input() errorWarningData: any;
    ngOnInit() {
        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.MatterCriminalForm.controls['MATTERGUID'].setValue('');
                this.MatterCriminalForm.controls['MATTERCRIMINALGUID'].setValue('');
                this.MatterCriminalForm.controls['BRIEFSERVICEDATE'].setValue('');
                this.MatterCriminalForm.controls['BRIEFSERVICEDATETEXT'].setValue('');
                this.MatterCriminalForm.controls['COMMITTALDATE'].setValue('');
                this.MatterCriminalForm.controls['COMMITTALDATETEXT'].setValue('');
                this.MatterCriminalForm.controls['REPLYDATE'].setValue('');
                this.MatterCriminalForm.controls['REPLYDATETEXT'].setValue('');
                this.MatterCriminalForm.controls['JUVENILE'].setValue('');
                this.MatterCriminalForm.controls['WAIVEROFCOMMITTAL'].setValue('');
                this.MatterCriminalForm.controls['BAILDATE'].setValue('');
                this.MatterCriminalForm.controls['BAILDATETEXT'].setValue('');
                this.MatterCriminalForm.controls['BAILRESTRICTIONS'].setValue('');
                this.MatterCriminalForm.controls['OUTCOME'].setValue('');
                this.MatterCriminalForm.controls['SENTENCINGDATE'].setValue('');
                this.MatterCriminalForm.controls['SENTENCINGDATETEXT'].setValue('');
                this.MatterCriminalForm.controls['SENTENCE'].setValue('');
                this.MatterCriminalForm.controls['S91APPLICATION'].setValue('');
                this.MatterCriminalForm.controls['S93APPLICATION'].setValue('');
                this.MatterCriminalForm.controls['CUSTODYNUMBER'].setValue('');
                this.MatterCriminalForm.controls['CHARGENUMBER'].setValue('');
                this.MatterCriminalForm.controls['CRIMINALNAMEINDEX'].setValue('');
                this.MatterCriminalForm.controls['LEGALAIDREFERENCE'].setValue('');
                this.MatterCriminalForm.controls['OFFENCEDATE'].setValue('');
                this.MatterCriminalForm.controls['OFFENCEDATETEXT'].setValue('');

            }
        })

        // let courtLookups = JSON.parse(localStorage.getItem('court_lookups'));
        // if (courtLookups && courtLookups != null) {
        //   this.CourtData = courtLookups;
        // } else {
        //   this._mainAPiServiceService.getSetData({ 'LookupType': 'court' }, 'GetLookups').subscribe(responses => {
        //     if (responses.CODE === 200 && responses.STATUS === 'success') {
        //       this.CourtData = responses.DATA.LOOKUPS;
        //       localStorage.setItem('court_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        //     }
        //   });
        // }
        // let divisionLookups = JSON.parse(localStorage.getItem('division_lookups'));
        // if (divisionLookups && divisionLookups != null) {
        //   this.DivisionData = divisionLookups;
        // } else {
        //   this._mainAPiServiceService.getSetData({ 'LookupType': 'division' }, 'GetLookups').subscribe(responses => {
        //     if (responses.CODE === 200 && responses.STATUS === 'success') {
        //       this.DivisionData = responses.DATA.LOOKUPS;
        //       localStorage.setItem('division_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        //     }
        //   });
        // }
        // let registryLookups = JSON.parse(localStorage.getItem('registry_lookups'));
        // if (registryLookups && registryLookups != null) {
        //   this.RegistryData = registryLookups;
        // } else {
        //   this._mainAPiServiceService.getSetData({ 'LookupType': 'registry' }, 'GetLookups').subscribe(responses => {
        //     if (responses.CODE === 200 && responses.STATUS === 'success') {
        //       this.RegistryData = responses.DATA.LOOKUPS;
        //       localStorage.setItem('registry_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        //     }
        //   });
        // }
        // let CourtListLookups = JSON.parse(localStorage.getItem('court_list_lookups'));
        // if (CourtListLookups && CourtListLookups != null) {
        //   this.ListData = CourtListLookups;
        // } else {
        //   this._mainAPiServiceService.getSetData({ 'LookupType': 'Court List' }, 'GetLookups').subscribe(responses => {
        //     if (responses.CODE === 200 && responses.STATUS === 'success') {
        //       this.ListData = responses.DATA.LOOKUPS;
        //       localStorage.setItem('court_list_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        //     }
        //   });
        // }

        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MatterCriminalForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if ((result.classType == "Criminal" || result.classType == "Domestic Violence") && result.action == 'setData') {
                    this.SaveCriminal();
                } else if ((result.classType == "Criminal" || result.classType == "Domestic Violence") && result.action == 'deleteData') {
                    this.DeleteCriminal();
                }
            }
        });
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-criminal').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterCriminalData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterCriminalData) {
                        this.MatterCriminalForm.patchValue({
                            MATTERGUID: MatterCriminalData.MATTERGUID,
                            MATTERCRIMINALGUID: MatterCriminalData.MATTERCRIMINALGUID,
                            BRIEFSERVICEDATE: MatterCriminalData.BRIEFSERVICEDATE,
                            COMMITTALDATE: MatterCriminalData.COMMITTALDATE,
                            REPLYDATE: MatterCriminalData.REPLYDATE,
                            JUVENILE: (MatterCriminalData['JUVENILE'] == 0) ? false : true,
                            WAIVEROFCOMMITTAL: (MatterCriminalData['WAIVEROFCOMMITTAL'] == 0) ? false : true,
                            BAILDATE: MatterCriminalData.BAILDATE,
                            BAILRESTRICTIONS: MatterCriminalData.BAILRESTRICTIONS,
                            OUTCOME: MatterCriminalData.OUTCOME,
                            SENTENCINGDATE: MatterCriminalData.SENTENCINGDATE,
                            SENTENCE: MatterCriminalData.SENTENCE,
                            S91APPLICATION: (MatterCriminalData['S91APPLICATION'] == 0) ? false : true,
                            S93APPLICATION: (MatterCriminalData['S93APPLICATION'] == 0) ? false : true,
                            CUSTODYNUMBER: MatterCriminalData.CUSTODYNUMBER,
                            CHARGENUMBER: MatterCriminalData.CHARGENUMBER,
                            CRIMINALNAMEINDEX: MatterCriminalData.CRIMINALNAMEINDEX,
                            LEGALAIDREFERENCE: MatterCriminalData.LEGALAIDREFERENCE,
                        });
                        if (MatterCriminalData.BRIEFSERVICEDATE) {
                            this.CommanDateSetter(MatterCriminalData.BRIEFSERVICEDATE, "BRIEFSERVICEDATE")
                        }
                        if (MatterCriminalData.COMMITTALDATE) {
                            this.CommanDateSetter(MatterCriminalData.COMMITTALDATE, "COMMITTALDATE")
                        }
                        if (MatterCriminalData.REPLYDATE) {
                            this.CommanDateSetter(MatterCriminalData.REPLYDATE, "REPLYDATE")
                        }
                        if (MatterCriminalData.BAILDATE) {
                            this.CommanDateSetter(MatterCriminalData.BAILDATE, "BAILDATE")
                        }
                        if (MatterCriminalData.SENTENCINGDATE) {
                            this.CommanDateSetter(MatterCriminalData.SENTENCINGDATE, "SENTENCINGDATE")
                        }
                        if (MatterCriminalData.OFFENCEDATE) {
                            this.CommanDateSetter(MatterCriminalData.OFFENCEDATE, "OFFENCEDATE")
                        }
                    }
                }
            });
        }
    }
    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }
    initFormData() {
        this.MatterCriminalForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCRIMINALGUID: [''],
            BRIEFSERVICEDATE: [''],
            BRIEFSERVICEDATETEXT: [''],
            COMMITTALDATE: [''],
            COMMITTALDATETEXT: [''],
            REPLYDATE: [''],
            REPLYDATETEXT: [''],
            JUVENILE: [''],
            WAIVEROFCOMMITTAL: [''],
            BAILDATE: [''],
            BAILDATETEXT: [''],
            BAILRESTRICTIONS: [''],
            OUTCOME: [''],
            SENTENCINGDATE: [''],
            SENTENCINGDATETEXT: [''],
            SENTENCE: [''],
            S91APPLICATION: [''],
            S93APPLICATION: [''],
            CUSTODYNUMBER: [''],
            CHARGENUMBER: [''],
            CRIMINALNAMEINDEX: [''],
            LEGALAIDREFERENCE: [''],
            OFFENCEDATE: [''],
            OFFENCEDATETEXT: ['']
        });
    }

    DeleteCriminal() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-criminal').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                this.toolTipList = response.DATA.FIELDTIPS;
                let MatterCriminalData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERCRIMINALGUID: MatterCriminalData.MATTERCRIMINALGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-criminal').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    SaveCriminal() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MatterCriminalForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERCRIMINALGUID;
        }
        delete formData.BRIEFSERVICEDATETEXT;
        delete formData.COMMITTALDATETEXT;
        delete formData.REPLYDATETEXT;
        delete formData.BAILDATETEXT;
        delete formData.SENTENCINGDATETEXT;
        delete formData.OFFENCEDATETEXT

        const formAction = (formData.MATTERCRIMINALGUID) ? 'Update' : 'Insert';
        const CriminalData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(CriminalData, 'matter-criminal').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, CriminalData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, CriminalData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, CriminalData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveCriminalAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveCriminalAfterVAlidation(details);

        }
    }
    SaveCriminalAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-criminal').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    CommanDateSetter(val, Field) {
        let SplitedDate = val.split("/");
        this.MatterCriminalForm.controls[Field + "TEXT"].setValue(new Date(SplitedDate[1] + '/' + SplitedDate[0] + '/' + SplitedDate[2]));
    }
    BriefServiceDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MatterCriminalForm.controls['BRIEFSERVICEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    CommittalDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MatterCriminalForm.controls['COMMITTALDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    ReplyDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MatterCriminalForm.controls['REPLYDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    BailDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MatterCriminalForm.controls['BAILDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    SentencingDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MatterCriminalForm.controls['SENTENCINGDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    OffenceDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MatterCriminalForm.controls['OFFENCEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
}
