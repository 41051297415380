import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-user-budget-dialog',
  templateUrl: './user-budget-dialog.component.html',
  styleUrls: ['./user-budget-dialog.component.scss']
})
export class UserBudgetDialogComponent implements OnInit,OnDestroy {
  Months: any = [];
  budgetsData: any = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  action: any;
  errorWarningData: any = {};
  isspiner: boolean = false;
  dialogTitle: any;
  successMsg: string;
  userBudget: FormGroup;
  USERGUID: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  rateid: any;

  constructor(
    public dialogRef: MatDialogRef<UserBudgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public MatDialog: MatDialog,
    public behaviorService: BehaviorService,
    private toastr: ToastrService,
    private _mainAPiServiceService: MainAPiServiceService
  ) {
    this.action = data.action;
    this.USERGUID = data.USERGUID;
    localStorage.setItem('istrackid', 'UserBudgetDialogComponent');
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$))
    .subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'UserBudgetDialogComponent' && result['click'] && result['data']['user-budget']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'UserBudgetDialogComponent' && result['click'] && result['data']['user-budget']) {
        this.saveBudget();
      }
    });

    /**
     * Get Users list
     */
    this.getUserRates()
  }

  /**
   * This function is used to unsubscribe the observable after destroyed the component
   */
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
  
    }

 /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.userBudget = this._formBuilder.group({
      PERIODSTARTTEXT: [new Date()],
      PERIODSTART: [''],
      USERGUID: [this.USERGUID],
      USERBUDGETGUID: [],
      TOTALBUDGETHOURS: [],
      TOTALBUDGETDOLLARS: ['',{updateOn: 'blur'}],
      ratehr: [this.data.RATEPERHOUR],
      MONTHBUDGETHOURS_1: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_2: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_3: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_4: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_5: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_6: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_7: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_8: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_9: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_10: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_11: ['',{updateOn: 'blur'}],
      MONTHBUDGETHOURS_12: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_1: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_2: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_3: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_4: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_5: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_6: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_7: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_8: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_9: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_10: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_11: ['',{updateOn: 'blur'}],
      MONTHBUDGETDOLLARS_12: ['',{updateOn: 'blur'}]
    });
    if (this.action == 'edit') {
      this.dialogTitle = 'Edit Budget';
      this.budgetsData = JSON.parse(localStorage.getItem('current_budgets'));
      this.NowDate(new Date());
      this.userBudget.controls['USERBUDGETGUID'].setValue(this.budgetsData.USERBUDGETGUID);
      this.userBudget.controls['TOTALBUDGETHOURS'].setValue(parseFloat(this.budgetsData.TOTALBUDGETHOURS).toFixed(2));
      this.userBudget.controls['TOTALBUDGETDOLLARS'].setValue(parseFloat(this.budgetsData.TOTALBUDGETDOLLARS).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_1'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_1']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_2'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_2']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_3'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_3']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_4'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_4']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_5'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_5']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_6'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_6']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_7'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_7']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_8'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_8']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_9'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_9']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_10'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_10']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_11'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_11']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETHOURS_12'].setValue(parseFloat(this.budgetsData.MONTHBUDGETHOURS_GROUP['MONTHBUDGETHOURS_12']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_1'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_1']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_2'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_2']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_3'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_3']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_4'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_4']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_5'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_5']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_6'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_6']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_7'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_7']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_8'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_8']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_9'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_9']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_10'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_10']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_11'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_11']).toFixed(2));
      this.userBudget.controls['MONTHBUDGETDOLLARS_12'].setValue(parseFloat(this.budgetsData.MONTHBUDGETDOLLARS_GROUP['MONTHBUDGETDOLLARS_12']).toFixed(2));
    } else {
      this.dialogTitle = 'New Budget';
      this.NowDate(new Date());
      let PERIODSTARTtemp = (new Date());
      PERIODSTARTtemp = new Date(PERIODSTARTtemp.getFullYear(), PERIODSTARTtemp.getMonth(), 1);
      this.userBudget.controls['PERIODSTART'].setValue(this.datepipe.transform(PERIODSTARTtemp, 'dd/MM/yyyy'));
    }

  }

  /**
   * This function is used to get the monthly value.
   * @param i --value of the month
   * @returns -it's return the Monthly value
   */
  getMonthlyVal(i: number){
    const value = this.userBudget.get(`MONTHBUDGETHOURS_${i + 1}`).value
    return value;
  }

  /**
   * This function is used to get the yearly value
   * @param i -value of the year
   * @returns -return the yearly value
   */
  getYearlyVal(i: number){
    const value = this.userBudget.get(`MONTHBUDGETDOLLARS_${i + 1}`).value;
    return value;
  }

  /**
   * This function is used to make the new date
   * @param value -date value
   */
  NowDate(value):void {
    let PERIODSTARTtemp = (new Date(value));
    PERIODSTARTtemp = new Date(PERIODSTARTtemp.getFullYear(), PERIODSTARTtemp.getMonth(), 1);
    this.userBudget.controls['PERIODSTART'].setValue(this.datepipe.transform(PERIODSTARTtemp, 'dd/MM/yyyy'));
    this.Months = [];
    for (let i = 0; i < 12; i++) {
      let nowdate = (new Date(value));
      nowdate = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
      nowdate.setMonth(nowdate.getMonth() + i);
      this.Months.push(nowdate);
    }
  }
  get f() {
    return this.userBudget.controls;
  }
  MONTHBUDGETHOURS_GROUP() {
    let Obj = this.userBudget.value;
    var O = {};
    for (let i = 1; i <= 12; i++) {
      let key = 'MONTHBUDGETHOURS_' + i;
      O[key] = Obj[key];
    }
    return O;
  }

  /**
   * This function is used to add the MONTHBUDGETDOLLARS_
   * @returns -
   */
  MONTHBUDGETDOLLARS_GROUP() {
    let Obj = this.userBudget.value;
    var O = {};
    for (let i = 1; i <= 12; i++) {
      let key = 'MONTHBUDGETDOLLARS_' + i;
      O[key] = Obj[key];
    }
    return O;
  }

  /**
   * This function is used to change the value of currancy
   */
  dollaresOnCahneg():void {
    let TOTALBUDGETDOLLARS_TEMP: any = this.f.TOTALBUDGETDOLLARS.value / 12;
    this.userBudget.controls['MONTHBUDGETDOLLARS_1'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_2'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_3'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_4'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_5'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_6'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_7'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_8'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_9'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_10'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_11'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETDOLLARS_12'].setValue(parseFloat(TOTALBUDGETDOLLARS_TEMP).toFixed(2));
  }

  /**
   * This function is used to calculate the Hours
   */
  hoursOnChange():void {
    let TOTALBUDGETHOURS_TEMP: any = this.f.TOTALBUDGETHOURS.value / 12;
    this.userBudget.controls['MONTHBUDGETHOURS_1'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_2'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_3'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_4'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_5'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_6'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_7'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_8'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_9'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_10'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_11'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['MONTHBUDGETHOURS_12'].setValue(parseFloat(TOTALBUDGETHOURS_TEMP).toFixed(2));
    this.userBudget.controls['TOTALBUDGETDOLLARS'].setValue((parseFloat(this.f.ratehr.value) * parseFloat(this.f.TOTALBUDGETHOURS.value)).toFixed(2));
    this.dollaresOnCahneg();
  }

  /**
   * This function is used to save the Budget data
   */
  saveBudget():void {
    this.isspiner = true;
    let PostData: any = {
      "USERGUID": this.f.USERGUID.value,
      "PERIODSTART": this.f.PERIODSTART.value,
      "TOTALBUDGETHOURS": this.f.TOTALBUDGETHOURS.value,
      "TOTALBUDGETDOLLARS": this.f.TOTALBUDGETDOLLARS.value,
      // "ratehr": this.f.ratehr.value,
      "MONTHBUDGETHOURS_GROUP": this.MONTHBUDGETHOURS_GROUP(),
      "MONTHBUDGETDOLLARS_GROUP": this.MONTHBUDGETDOLLARS_GROUP(),
    }
    if (this.USERGUID == "") {
      PostData.USERBUDGETGUID = this.data.USERBUDGETGUIDIndex;
      this.dialogRef.close(PostData);
    } else {
      this.successMsg = 'Save successfully';
      let FormAction = this.action == 'edit' ? 'update' : 'insert';
      if (this.action == 'edit') {
        PostData.USERBUDGETGUID = this.f.USERBUDGETGUID.value;
        this.budgetsData = JSON.parse(localStorage.getItem('current_budgets'));
        this.successMsg = 'Update successfully';
      }
      let PostBudgetData: any = { Action: FormAction, VALIDATEONLY: true, Data: PostData };
      this._mainAPiServiceService.getSetData(PostBudgetData, 'user-budget').pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          this.checkValidation(res.DATA.VALIDATIONS, PostBudgetData);
        } else if (res.CODE == 451 && res.STATUS == 'warning') {
          this.checkValidation(res.DATA.VALIDATIONS, PostBudgetData);
        } else if (res.CODE == 450 && res.STATUS == 'error') {
          this.checkValidation(res.DATA.VALIDATIONS, PostBudgetData);
        } else if (res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }

      }, err => {
        this.isspiner = false;
        this.toastr.error(err);
      });
    }
  }

  /**
   * This function is used to check the api validation 
   * @param bodyData -formvalue
   * @param PostBudgetData -budget data value
   */
  checkValidation(bodyData: any, PostBudgetData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true, width: '100%', data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveBudgetData(PostBudgetData);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveBudgetData(PostBudgetData);

    }
  }

  /**
   * This function is used to save the budget data.
   * @param PostBudgetData -Budget data value
   */
  saveBudgetData(PostBudgetData: any):void {
    PostBudgetData.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(PostBudgetData, 'user-budget').pipe(takeUntil(this._unsubscribeAll$))
    .subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success(this.successMsg);
        this.dialogRef.close(true);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(this.successMsg);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.error(res.STATUS);
        this.isspiner = false;
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

getUserRates(){
  this._mainAPiServiceService
            .getSetData(
                { Action: "getdata", Filters: { LookupType: "Rates" } },
                "lookup"
            ).pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((responses) => {
                if (responses.CODE === 200 && responses.STATUS === "success") {
                    localStorage.setItem(
                        "Rates_lookups",
                        JSON.stringify(responses.DATA.LOOKUPS)
                    );
                    this.rateid = responses.DATA.LOOKUPS;
                    //this.hasSearchVal = false;
                }
            });
}

}
