import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactSelectDialogComponent } from '../../contact/contact-select-dialog/contact-select-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactDialogComponent } from '../../contact/contact-dialog/contact-dialog.component';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { debounceTime, distinctUntilChanged, from, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { TooltipService } from '@_services/tooltip.service';

@Component({ 
    selector: 'app-correspond-dailog',
    templateUrl: './correspond-dailog.component.html',
    styleUrls: ['./correspond-dailog.component.scss']
})
export class CorrespondDailogComponent implements OnInit, OnDestroy {
    selectSolicitorData: any;
    Roles: any[] = [];
    correspondRoleCounter: any[] = [0, 1, 2, 3, 4]
    DestryedContactdata: Subscription;
    CallHostlistner: boolean = false;
    searchContact: Subscription;
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    solicitor:Observable<any>;
    OpenPopup: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    currentsolicitorEvent: string;
    solicitorData: any;
    CallHostlistnersolicitor: boolean;
    constructor(
        private _formBuilder: FormBuilder,
        public MatDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        public dialogRef: MatDialogRef<CorrespondDailogComponent>,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        private cdf: ChangeDetectorRef, public toolbarServiceService: ToolbarServiceService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public tooltipService : TooltipService

    ) {
        localStorage.setItem('istrackid', 'MatterCorrespondPopupComponent');
        this.sub = this.behaviorService.matterClassData$.subscribe(result => {
            if (result) {
                this.MatterClassData = result.LOOKUPFULLVALUE;
            }
        });

        this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'MatterCorrespondPopupComponent' && result['click'] && (result['data']['contact'] || result['data']['lookup'])) {
                this.ngOnInit();
            }
        });
    }

    removable = true;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    correspondForm: FormGroup;
    MatterClassData: any = [];
    errorWarningData: any = {};
    CorrspondClassData: any = [];
    isLoadingResults: boolean = false;
    isBanfi: any = "";
    isspiner: boolean = false;
    isEdit: boolean = false;
    matterMode: string = "new";
    matterData: any;
    selectedContactData: any;
    contactDetails = {};
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    contactList: any;
    solicitorList:any;
    SolicitorData: any = [];
    searchData: any;
    contactData: any = [];
    selContactName: string = "";
    private searchTerms = new Subject<string>();
    private subscription: Subscription;
    currentEvent: any;
    @ViewChild('contactElem', { static: false }) contactElemdata: ElementRef<HTMLInputElement>;
    @ViewChild('solicitorElem', { static: false }) solicitorElemdata: ElementRef<HTMLInputElement>;
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.sub2 = this.behaviorService.MatterEditData$.subscribe(result => {
            this.matterData = result;
        });

        this.correspondForm = this._formBuilder.group({
            TYPE: ['General'],
            PERSONGUID: [''],
            PERSONGUIDTEXT: [''],
            SOLICITORGUID: [''],
            SOLICITORGUIDTEXT: [''],
            REFERENCE: [''],
            MATTERGUID: [''],
            MATTERCONTACTGUID: [''],
            RELATIONSHIP: [''],
            SHAREOFESTATE: [''],
            TYPE0: [''],
            TYPE1: [''],
            TYPE2: [''],
            TYPE3: [''],
            TYPE4: [''],
            REFERENCE0: [''],
            REFERENCE1: [''],
            REFERENCE2: [''],
            REFERENCE3: [''],
            REFERENCE4: [''],
        });
        this.isLoadingResults = true;
        let contactRoleLookups = JSON.parse(localStorage.getItem('contact_role_lookups_' + this.MatterClassData));
        if (contactRoleLookups && contactRoleLookups != null) {
            this.CorrspondClassData = contactRoleLookups;
            this.isLoadingResults = false;
        } else {
           this.sub3 = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'contact role', 'MatterClass': this.MatterClassData } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.CorrspondClassData = responses.DATA.LOOKUPS;
                    localStorage.setItem('contact_role_lookups_' + this.MatterClassData, JSON.stringify(responses.DATA.LOOKUPS));
                    this.isLoadingResults = false;
                }
            });
        }
       this.sub4 = this._mainAPiServiceService.getSetData({ "ACTION": "GetData", "FILTERS": { 'CONTACTGUID': this._data.FIRMGUID } }, 'contact').subscribe(responses => {
            if (responses.CODE === 200 && responses.STATUS === 'success') {
                if(responses.DATA.RECORDS.length < 2) {
                    this._data.CONTACTTYPE = this._data.CONTACTTYPE ? this._data.CONTACTTYPE : responses.DATA.RECORDS[0]['CONTACTTYPE'];
                }
                if(responses.DATA.RECORDS[0] && this._data.type == "edit"){
                    this.contactData = [responses.DATA.RECORDS[0]['CONTACTNAME']];
                    this.correspondForm.controls['PERSONGUIDTEXT'].setValue(responses.DATA.RECORDS[0]['CONTACTNAME']);
                }
                // responses.DATA.RECORDS[0]['CONTACTTYPE'] == "Multi Person"
                if (responses.DATA.RECORDS[0] && responses.DATA.RECORDS[0]['CONTACTTYPE'] == "Company") {
                    this.contactDetails = responses.DATA.RECORDS;
                }
            }
        });

        this.matterMode = localStorage.getItem("matterMode")
        this.isEdit = this._data.type == "edit" ? true : false;
        if (this._data.type == "edit") {
            this.correspondRoleCounter = [0]
            let editData = this._data.EditData;
            this.correspondForm.controls['SOLICITORGUID'].setValue(editData.SOLICITORGUID);
            this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(editData.SOLICITORNAME);
            this.correspondForm.controls['PERSONGUID'].setValue(editData.PERSONGUID);
            this.correspondForm.controls['PERSONGUIDTEXT'].setValue(editData.CONTACTNAME);
            this.correspondForm.controls['REFERENCE0'].setValue(editData.REFERENCE);
            this.correspondForm.controls['MATTERGUID'].setValue(editData.MATTERGUID);
            this.correspondForm.controls['TYPE0'].setValue(editData.TYPENAME);
            this.correspondForm.controls['TYPE'].setValue(editData.TYPENAME);
            this.correspondForm.controls['MATTERCONTACTGUID'].setValue(editData.MATTERCONTACTGUID);
            this.correspondForm.controls['RELATIONSHIP'].setValue(editData.RELATIONSHIP);
            this.correspondForm.controls['SHAREOFESTATE'].setValue(editData.SHAREOFESTATE);
            this.isBanfi = editData.TYPENAME;
            this.Roles.push(editData.TYPENAME)
            this.contactData = [editData.CONTACTNAME];
        }
        this.getContactDataUsingBehaviour();
        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.fastSearchContact(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.searchData = response.DATA.RECORDS;
                if (response.DATA.RECORDS.length == 0) {
                    this.contactList.push({ 'CONTACTNAME': this.currentEvent });
                } else {
                    this.contactList = this.searchData;
                }
            }
        });

        /**
         * This is for fast solicitor search.
         */
        this.solicitor=this.correspondForm.controls['SOLICITORGUIDTEXT'].valueChanges.pipe(
             debounceTime(300),
             distinctUntilChanged(),
             switchMap(data=> this.fastSolicitorSearch(data))
        );


        this.solicitor.subscribe(data => {
            this.solicitorList = data;
          });

    }

    getContactDataUsingBehaviour() {
        this.DestryedContactdata = this.behaviorService.newContactData$.subscribe(result => {
            if (result) {
                if(result?.SOLI == undefined && result?.SOLI != 'SOLICITOR'){
                this.selectedContactData = result;
                this.f.MATTERGUID.value != undefined ? this.behaviorService.MatterNum('defaults') : '';
                this.f.MATTERGUID.value ? '' : this.correspondForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.correspondForm.controls['PERSONGUID'].setValue(result.CONTACTGUID);
                this.correspondForm.controls['PERSONGUIDTEXT'].setValue(result.CONTACTNAME);
                this.contactData = [result.CONTACTNAME]
                }

                if(result?.SOLI != undefined && result?.SOLI != null && result?.SOLI == 'SOLICITOR'){
                    this.selectSolicitorData = result;
                    this.f.MATTERGUID.value != undefined ? this.behaviorService.MatterNum('defaults') : '';
                    this.f.MATTERGUID.value ? '' : this.correspondForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                    this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(result.CONTACTNAME);
                    this.correspondForm.controls['SOLICITORGUID'].setValue(result.CONTACTGUID);
                    this.SolicitorData = [result.CONTACTNAME];
                }
            }
        })
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
        let result = this.contactElemdata.nativeElement.value;
        if (result == '') {
            this.OpenPopup = true
        }
        if (result && result != null && result != undefined && this.CallHostlistner == false) {
            this.selectContactTab(this.contactElemdata.nativeElement);
            this.contactElemdata.nativeElement.value = '';
        }

        let solicitorresult = this.solicitorElemdata?.nativeElement.value;
       
        if (solicitorresult && solicitorresult != null && solicitorresult != undefined && this.CallHostlistnersolicitor == false) {
            this.selectSolicitorTab();
            this.solicitorElemdata.nativeElement.value = '';
        }
    }



    get f() {
        return this.correspondForm.controls;
    }
    selectContact() {
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: this.f.TYPE.value, 'contect_details': this.contactDetails, 'FIRMGUID': this._data.FIRMGUID, 'CONTACTTYPE': this._data.CONTACTTYPE } });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterCorrespondPopupComponent');
            if (result) {
                this.selectedContactData = result;
                this.f.MATTERGUID.value != undefined ? this.behaviorService.MatterNum('defaults') : '';
                this.f.MATTERGUID.value ? '' : this.correspondForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.correspondForm.controls['PERSONGUID'].setValue(result.CONTACTGUID);
                this.correspondForm.controls['PERSONGUIDTEXT'].setValue(result.CONTACTNAME);
                this.contactData = [result.CONTACTNAME]
            }
        });
    }
    selectSolicitor() {
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: this.f.TYPE.value, 'contect_details': this.contactDetails } });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterCorrespondPopupComponent');
            if (result) {
                this.selectSolicitorData = result;
                this.behaviorService.MatterNum('defaults');
                this.correspondForm.controls['SOLICITORGUID'].setValue(result.CONTACTGUID);
                this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(result.CONTACTNAME);
            }
        });
    }

    remove(fruit: any): void {
        this.correspondForm.controls['SOLICITORGUID'].setValue('');
        this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue('');


    }
    async saveCorrespomndDetail() {
        this.isspiner = true;
        let details: any = {
            TYPE: this.f.REFERENCE.value,
            SOLICITORGUID: this.f.SOLICITORGUID.value,
            REFERENCE: this.f.REFERENCE.value,
            PERSONGUID: this.f.PERSONGUID.value,
            MATTERGUID: this.f.MATTERGUID.value,
            RELATIONSHIP: this.f.RELATIONSHIP.value,
            SHAREOFESTATE: this.f.SHAREOFESTATE.value,
            CorrespondRoleAndReference: this.Roles
        }

        // if (this.matterData && this.isEdit) {
        if (this.matterData && this._data.action != 'duplicate') {

            details.MATTERGUID = this.matterData.MATTERGUID;
            let correspondingDetailsRole = this.Roles;
            if (this.Roles && this.Roles.length == 0) {
                this.toastr.info('Please select a minimum of one role');
                // this.dialogRef.close(true);
                this.isspiner = await false;
            }
            for (let index = 0; index < correspondingDetailsRole.length; index++) {
                await new Promise<void>(async (next, reject) => {
                    const element = correspondingDetailsRole[index];
                    details.TYPE = await element.role;
                    details.REFERENCE = await element.ref;
                    const correspondingDetailsRoleIndexLength = { index: index, length: correspondingDetailsRole.length }
                    let PostTimeEntryData: any = await { FORMACTION: 'insert', VALIDATEONLY: true, DATA: details };
                    await this._mainAPiServiceService.getSetData(PostTimeEntryData, 'matter-contact')
                        .subscribe(async response => {
                            if (response.CODE == 200 && response.STATUS == "success") {
                                await this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData, correspondingDetailsRoleIndexLength);
                            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                await this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
                            } else if (response.CODE == 450 && response.STATUS == 'error') {
                                await this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
                            } else if (response.MESSAGE == 'Not logged in') {
                                this.isspiner = await false;
                                await this.dialogRef.close(false);
                            }
                        }, (error: any) => {
                            console.log(error);
                        }, next);
                })
            };
        } else {
            // alert("inside else condition");
            if (!this.f.PERSONGUID.value) {
                this.isspiner = false;
                let errorData: any = [];
                let tempError: any = [];
                let tempWarning: any = [];
                errorData.push('');
                tempError['PERSONGUID'] = 'value';
                this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
                return this.toastr.error('You must select person');

            }
            let parsentage = (this.isBanfi == "Beneficiary") ? ' - ' + this.f.SHAREOFESTATE.value + '%' : '';
            let itemdata: any = details;
            itemdata.TYPENAME = this.correspondForm.get('TYPE').value;
            if (this.selectedContactData) {
                itemdata.CONTACTNAME = this.selectedContactData.CONTACTNAME;
            }
            if (this.selectSolicitorData) {
                itemdata.SOLICITORNAME = this.selectSolicitorData.CONTACTNAME;
            }
            let data = { 'showData': { 'type': this.f.TYPE.value, 'Text': this.f.PERSONGUIDTEXT.value, 'ItemData': itemdata, 'SOLICITORNAME': this.f.SOLICITORGUIDTEXT.value }, 'saveData': details };
            this.dialogRef.close(data);
        }
    }
    async checkValidation(bodyData: any, PostTimeEntryData: any, correspondingDetailsRoleIndexLength?) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        // errorData
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'NO' || value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'WARNING') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.isspiner = false;
        } else if (Object.keys(warningData).length != 0) {
            // this.toastr.warning(warningData);
            this.isspiner = false;
            this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(async result => {
                if (result) {
                    this.isspiner = true;
                    await this.saveCorrespomnd(PostTimeEntryData);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            await this.saveCorrespomnd(PostTimeEntryData, correspondingDetailsRoleIndexLength);
        }
    }
    async saveCorrespomnd(PostTimeEntryData: any, correspondingDetailsRoleIndexLength?) {

        PostTimeEntryData.VALIDATEONLY = false;
        await this._mainAPiServiceService.getSetData(PostTimeEntryData, 'matter-contact').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.success('Matter Contact save successfully');
                if (correspondingDetailsRoleIndexLength) {
                    const element = correspondingDetailsRoleIndexLength
                    if ((element.index + 1) === element.length) {
                        this.isspiner = false;
                        this.dialogRef.close(true);
                    }
                } else {
                    this.isspiner = false;
                    this.dialogRef.close(true);
                }

            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.isspiner = false;
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.isspiner = false;
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == 'Not logged in') {
                this.isspiner = false;
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }
    async updateCorrespomndDetail() {

        this.isspiner = true;
        let details: any = {
            TYPE: this.correspondForm.get('TYPE0').value,
            SOLICITORGUID: this.f.SOLICITORGUID.value,
            REFERENCE: this.correspondForm.get('REFERENCE0').value,
            PERSONGUID: this.f.PERSONGUID.value,
            MATTERGUID: this.f.MATTERGUID.value,
            MATTERCONTACTGUID: this.f.MATTERCONTACTGUID.value,
            RELATIONSHIP: this.f.RELATIONSHIP.value,
            SHAREOFESTATE: this.f.SHAREOFESTATE.value,
            CONTACTNAME: this.f.PERSONGUIDTEXT.value,
            SOLICITORNAME: this.f.SOLICITORGUIDTEXT.value,
        };

        if (this.f.MATTERGUID.value && this.f.MATTERGUID.value !='' && this.f.MATTERGUID.value != undefined) {
            for (let index = 0; index < this.Roles.length; index++) {
                await new Promise<void>(async (next, reject) => {
                    const element = this.Roles[index];
                    details.TYPE = element.role;
                    details.REFERENCE = element.ref;
                    let PostTimeEntryData = { FORMACTION: 'update', VALIDATEONLY: true, DATA: details };
                    this._mainAPiServiceService.getSetData(PostTimeEntryData, 'matter-contact')
                        .subscribe(response => {
                            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
                            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
                            } else if (response.CODE == 450 && response.STATUS == 'error') {
                                this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
                            } else if (response.MESSAGE == 'Not logged in') {
                                this.isspiner = false;
                                this.dialogRef.close(false);
                            }
                        }, (error: any) => {
                        }, next);
                });
            };
        } else {
            let parsentage = (this.isBanfi == "Beneficiary") ? ' - ' + this.f.SHAREOFESTATE.value + '%' : '';
            let itemdata: any = details;
            itemdata.TYPENAME = details.TYPE;
            // itemdata.CorrespondRoleAndReference = this.Roles
            if (this.selectedContactData) {
                itemdata.CONTACTNAME = this.selectedContactData.CONTACTNAME;
            }
            if (this.selectSolicitorData) {
                itemdata.SOLICITORNAME = this.selectSolicitorData.CONTACTNAME;
            }

            let data = { 'showData': { 'type': details.TYPE, 'Text': this.f.PERSONGUIDTEXT.value, 'ItemData': itemdata, 'SOLICITORNAME': this.f.SOLICITORGUIDTEXT.value }, 'saveData': details };
            this.dialogRef.close(data);
        }
    }
    changeType(val, i?) {
        let isRole = false
        for (let index = 0; index < this.Roles.length; index++) {
            const element = this.Roles[index];
            if (element.role === val) {
                isRole = true;
                break;
            }
        }
        if (isRole) {
            this.correspondForm.controls[`TYPE${i}`].setValue([''])
            return this.toastr.error('Already Selected')
        } else {
            this.changeRef();
        }
        this.isBanfi = val;
        this.AfterViewInitnClient();
    }
    changeRef() {
        const selectedRoles: any[] = []
        for (let index = 0; index < this.correspondRoleCounter.length; index++) {
            const role = this.correspondForm.get(`TYPE${index}`).value;
            const ref = this.correspondForm.get(`REFERENCE${index}`).value;
            if (role) {
                if (ref) {
                    selectedRoles.push({ role: role, ref: ref });
                } else {
                    selectedRoles.push({ role: role });
                }
            }
        }
        this.Roles = selectedRoles
    }

    AfterViewInitnClient() {
        let tempError: any = [];
        let tempWarning: any = [];
        tempWarning['SHAREOFESTATE'] = { 'SHAREOFESTATE': '' };
        tempWarning['RELATIONSHIP'] = { 'RELATIONSHIP': '' };
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    }

    getContactNewId() {
        let contecttId = '';
        let postData = {
            "Action": "GetContactId",
            "VALIDATEONLY": true,
            "Data": {},
        };

        return new Promise((response, reject) => {
           this.sub5 = this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
                if (res.CODE == 200) {
                    contecttId = res.DATA.CONTACTID;
                    response(contecttId);
                } else {
                    response(null);
                }
            });
        })
    };

    async selectNewContect(type?: any) {
        const dialogRef = await this.MatDialog.open(ContactDialogComponent, {
            disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true, contect_details: '', flagNewConactMatter: true }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                if (result.CONTACTGUID) {
                    let contectIdIs = await this.getContactNewId();
                    this.behaviorService.MatterpopupContactData$.next(contectIdIs);
                    if (type == 'Contact Name') {
                        this.correspondForm.controls['PERSONGUID'].setValue(result.CONTACTGUID);
                        this.correspondForm.controls['PERSONGUIDTEXT'].setValue(result.CONTACTNAME);
                        this.contactData = [result.CONTACTNAME]
                    };

                    if (type == 'Solicitor Name') {
                        this.correspondForm.controls['SOLICITORGUID'].setValue(result.CONTACTGUID);
                        this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(result.CONTACTNAME);
                    };

                } else {
                    this.behaviorService.MatterpopupContactData$.next(null)
                }
            }
        });
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }
    
    // fast search for payor field
    fastSearchContact(event) {
        this.contactList = [];
        this.currentEvent = '';
        if (event.length == 0) {
            this.contactList = [];
        }
        // let searchobj = { FastSearch:'True', Search: event }
        let payload = {
            "ACTION": "GetData",
            "FILTERS": {
                "FASTSEARCH": 1,
                "SEARCH": event
            },
        }
        this.currentEvent = event;
        return this._mainAPiServiceService.getSetData(payload, 'contact')

    }

    fastSolicitorSearch(event: string): Observable<any> {
        this.solicitorList = [];
        this.currentsolicitorEvent = '';
        if(event == undefined){
            this.solicitorList = [];
            return of([]); // Return an empty Observable
        }
        if (event.length === 0) {
          this.solicitorList = [];
          return of([]); // Return an empty Observable
        }
      
        let payload = {
          "ACTION": "GetData",
          "FILTERS": {
            "FASTSEARCH": 1,
            "SEARCH": event
          }
        };
      
        this.currentsolicitorEvent = event;
      
        // Use `switchMap` to return an Observable from the function
        return this._mainAPiServiceService.getSetData(payload, 'contact').pipe(
          map(response => {
            if (response.CODE === 200 && response.STATUS === 'success') {
              this.searchData = response.DATA.RECORDS;
              if (response.DATA.RECORDS.length === 0) {
                this.solicitorList.push({ 'CONTACTNAME': this.currentsolicitorEvent });
                return this.solicitorList;
              } else {
                this.solicitorList = this.searchData;
                return this.solicitorList;
              }
            } else {
              return [];
            }
          })
        );
      }

    // ends here ~ fast search for payor field

    // select contact from fast search result
    selectContactData(result) {
        this.contactElemdata.nativeElement.value = '';
        if (Object.keys(result).length == 1) {
            this.toolbarServiceService.ContactsDialog('new', result.CONTACTNAME,'Correspondent');
            this.contactData = [];
            this.contactList = [];
        } else {
            if (result) {
                this.correspondForm.controls['PERSONGUIDTEXT'].setValue(result.CONTACTNAME);
                this.correspondForm.controls['PERSONGUID'].setValue(result.CONTACTGUID);
                this.contactData = [result.CONTACTNAME]
            };
        }

    }


     // select contact from fast search result
     selectSolicitorDataVAL(result) {
        this.solicitorElemdata.nativeElement.value = '';
        if (Object.keys(result).length == 1) {
            this.toolbarServiceService.ContactsDialog('new', result.CONTACTNAME,'Correspondent','SOLICITOR');
            this.SolicitorData = [];
            this.solicitorList = [];
        } else {
            if (result) {
                this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(result.CONTACTNAME);
                this.correspondForm.controls['SOLICITORGUID'].setValue(result.CONTACTGUID);
                this.SolicitorData = [result.CONTACTNAME];
            };
        }

    }

    ngOnDestroy(): void {
        this.DestryedContactdata?.unsubscribe();
        this.behaviorService.newContactData$.next(null);
        this.searchContact?.unsubscribe();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.subscription?.unsubscribe();
       // this.solicitor?.unsubscribe();
    }

    selectContactTab(evt) {
        this.contactData = [];
        this.CallHostlistner = true;
        if (Object.keys(this.contactList[0]).length > 1) {
            this.selectContactData(this.contactList[0]);
            this.contactData = [this.contactList[0].CONTACTNAME]
        } else {
            this.toolbarServiceService.ContactsDialog('new', this.contactElemdata.nativeElement.value,'Correspondent');
            this.contactData = [];
            this.contactList = [];
            this.contactElemdata.nativeElement.value = '';
        }
        this.CallHostlistner = false;
    }

    selectSolicitorTab(){
        this.SolicitorData = [];
        this.CallHostlistnersolicitor = true;
        console.log(this.solicitorList[0]);
        if (this.solicitorList[0] !=undefined && Object.keys(this.solicitorList[0]).length == 1) {
            console.log(this.solicitorList[0].CONTACTNAME);
            this.toolbarServiceService.ContactsDialog('new', this.solicitorList[0].CONTACTNAME,'Correspondent','SOLICITOR');
            this.SolicitorData = [];
            this.solicitorList = [];
        } else {
            if (this.solicitorList[0] && this.solicitorList[0] !=undefined) {
                this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(this.solicitorList[0].CONTACTNAME);
                this.correspondForm.controls['SOLICITORGUID'].setValue(this.solicitorList[0].CONTACTGUID);
                this.SolicitorData = [this.solicitorList[0].CONTACTNAME];
                this.solicitorElemdata.nativeElement.value = '';

            };
        }
        this.CallHostlistnersolicitor = false;
    }
    // ends here ~ select contact from fast search result

    // remove contact
    removeContact(contact) {
        this.contactData = [];
        this.correspondForm.controls['PERSONGUIDTEXT'].setValue('');
        this.correspondForm.controls['PERSONGUID'].setValue('');
        this.contactElemdata.nativeElement.value = "";
    }

    removeSolicitor(){
        this.SolicitorData = [];
        this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue('');
        this.correspondForm.controls['SOLICITORGUID'].setValue('');
        this.solicitorElemdata.nativeElement.value = "";
    }
    // ends here ~ remove contact
}
