import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { of, Subject, combineLatest, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fuseAnimations } from "src/@fuse/animations";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import * as $ from "jquery";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { ConnectBankFeedComponent } from "@pages/chart-account/connect-bank-feed/connect-bank-feed.component";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { TrustMoneyReciptComponent } from "@pages/Trust Accounts/trust-money/trust-money-recipt/trust-money-recipt.component";
import { DatePipe } from "@angular/common";
import { ReceiptDilogComponent } from "@pages/invoice/receipt-dilog/receipt-dilog.component";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { BankingDialogComponent } from "@pages/banking/banking-dialog.component";
import { ContactSelectDialogComponent } from "@pages/contact/contact-select-dialog/contact-select-dialog.component";
import { GeneralReceiptDilogComponent } from "@pages/receive-money/general-receipt-dilog/general-receipt-dilog.component";
@Component({
  selector: 'app-bank-feed-list',
  templateUrl: './bank-feed-list.component.html',
  styleUrls: ['./bank-feed-list.component.scss'],
  animations: fuseAnimations,
})
export class BankFeedListComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedColore: string = 'rgb(217, 217, 217)';
  selectedDataSM: any = [];
  disableAddButtonFlag: boolean = false;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isspinerRF: boolean = false;
  isGstReadonly: boolean = true;
  selectedDataRC: any = [];
  PrepareReceiptData: any;
  AllocationBtn: string;
  INDEX: number;
  currentInvoiceData: any;
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  SelectedInvoiceIndex: number = -1;
  AutoAllocationClick: boolean = true;
  selectedInvoiceData: any;
  TotalInvoice: any;
  chartAccountDetail: any;
  windowNameId: any;
  bankFeedList: any[] = [];
  isLoadingResults: boolean = false;
  isError: boolean = false;
  MESSAGE: string = '';
  CODE: any;
  defaultValuesRM: any;
  selectedIndex: number = -1;
  selectedTable: number = -1;
  Contactname: any = [];
  ContactnamePayee: any = [];
  isDisplay: boolean = false;
  isDisplayRM: boolean = false;
  isDisplaySM: boolean = false;
  gsttypeData: any = [
      { id: 1, text: "10% GST" },
      { id: 2, text: "No GST" },
      { id: 3, text: "< 10% GST" },
  ];
  @ViewChild("MatterInput2", { static: false })
  MatterInputContact: ElementRef<HTMLInputElement>;
  @ViewChild("MatterInput3", { static: false })
  MatterInputContactPayee: ElementRef<HTMLInputElement>;
  @ViewChild("MatterInputME", { static: false })
  MatterInputMultiExp: ElementRef<HTMLInputElement>;
  @ViewChild("MatterInputRM", { static: false })
  MatterInputRM: ElementRef<HTMLInputElement>;
  @ViewChild("MatterInputRC", { static: false })
  MatterInputRC: ElementRef<HTMLInputElement>;
  @ViewChild("searchInvoice", { static: false })
  searchInvoice: ElementRef<HTMLInputElement>;
  selectedContctData: any = [];
  selectedContctDataPayee: any = [];
  CallHostlistner: boolean = false;
  Contactlist: any[] = [];
  ContactlistPayee: any[] = [];
  exportdatavalueOFContact: any;
  exportdatavalueOFContactPayee: any;
  searchContactData;
  searchContactDataPayee;
  result: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  // displayedColumns2: string[];
  displayedColumns2: any[] = [
      "select",
      "RECEIVEDDATE",
      "DATE",
      "AMOUNT",
      "SHORTNAME",
      "CONTACTNAME",
      "REFERENCE",
      "PAYEE",
  ];
  displayedColumns: any[] = [
      "select",
      "INCOMEDATE",
      "AMOUNT",
      "SHORTNAME",
      "OWNERNAME",
      "INCOMECODE",
      "PAYEE",
  ];
  showeddisplayedColumns: any[] = [
      "select",
      "INCOMEDATE",
      "OWNERNAME",
      "INCOMECODE",
  ];
  showeddisplayedColumns2: any[] = [
      "select",
      "RECEIVEDDATE",
      "DATE",
      "REFERENCE",
      "CONTACTNAME",
  ];
  displayedColumnsMR: string[] = [
      "INVOICECODE",
      "INVOICETOTAL",
      "AMOUNTOUTSTANDINGINCGST",
      "ALLOCATED",
      "MATTERGUID",
  ];
  sortingDefaultState: any;
  sortingDefaultState2: any;
  sortactive: any;
  sortDirection: any;
  sortspentactive: any;
  sortspentDirection: any;
  pageSize: any;
  pageSizeSpent: any;
  filterData: any;
  amountFilter: any;
  // @ViewChild("searchField", { static: true }) input: ElementRef;
  expenseData: any[] = [];
  incomeData: any[] = [];
  SendMoney_data: any;
  appPermissions = JSON.parse(localStorage.getItem("app_permissions"));
  receiveMoneydata: any;
  spentMoneydata: any;
  hyperLinkInfo: any;
  hyperLinkInfoSpent: any;
  tempColobj: any;
  ColumnsObj: any;
  tempColobj2: any;
  ColumnsObj2: any;
  dateColFilter = [];
  dateColFilter2 = [];
  Object: any;
  selection = new SelectionModel<any>(true, []);
  selection2 = new SelectionModel<any>(true, []);
  SelectedWIPCheckBox: any[] = [];
  SelectedWIPCheckBoxSM: any[] = [];
  receiveMoneySelecteddata: any;
  spentMoneySelecteddata: any;
  totalAmount: number;
  finalAmount: number;
  discussText: string;
  FAmount: any = [];
  FGst: any = [];
  sub1: Subscription;
  // @ViewChild("searchName", { static: false }) searchName: ElementRef;
  // @ViewChild("searchAmount", { static: false }) searchAmount: ElementRef;
  // @ViewChild("discussTextRef", { static: false }) discussTextRef: ElementRef;
  panelOpenState;
  matterlist: any[] = [];
  matterlistRM: any[] = [];
  classtype: any;
  mattername: any = [];
  matternameRM: any = [];
  exportdatavalue: any;
  exportdatavalueRM: any;
  searchData: any;
  searchDataRM: any;
  GstTypeDiff: any;
  AMOUNT: any = 0;
  GloballyUnallocatedVAl: any;
  getDataForTable: any = [];
  maxVal: any = "";
  GSTValForExGst: any;
  GSTValAfterCal: any;
  selectedData: any = [];
  selectedDataRM: any = [];
  getPayourarray: any[] = [];
  ShowData: any = [];
  matterData: any;
  isspiner: boolean = false;
  isspinerDF: boolean = false;
  highlightedRows: any;
  highlightedRowsPRD: any;
  AllocationData: any = [];
  AllocationAmout: any = 0;
  private _unsubscribeAll$: Subject<void> = new Subject();
  matternameRC: any[] = [];
  matterlistRC: any[] = [];
  searchDataRC: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  exportdatavalueRC: any;
  resultRC: any;
  contactData: any;
  bankFeedForm: FormGroup;
  flaxValue: any = 20;
  lengthsize: any;
  ExpandDivFlag: boolean=false;
  ChartHandlingData: { ClickType: string; UseTrust: string; PopUp: string; Lable: string; };
  mainIndex: any=0;
  Ftotalval:any=0;
  FTotalGST:any=0;
  SearchForm: FormGroup=this._formBuilder.group({
    date: [''],
    search: [''],
    DayRange:['All time']
});;
  begin: any;
  end: any;
  dayRangeOptions: any[] = [];
  private searchTerms = new Subject<string>();
  private searchTermsRM = new Subject<string>();
  private searchTermsSM = new Subject<string>();
  private searchTermsRMPayor = new Subject<string>();
  private searchTermsRMMatter = new Subject<string>();
  // private searchInvoice = new Subject<string>();
  MainBankIndex: any;
  sendItem: any = [];
  FinalExGSTAmount: any;
  setMainAmount: number;
  setMainGST: number;
  FinalTotal: any;
  FinalTotalGST: any;
  OpenLoader: any[];
  showgrayicon: boolean;
  shownormalicon: boolean;
  confirmDialogRef1: MatDialogRef<ReceiptDilogComponent>;
  confirmDialog: MatDialogRef<ReceiptDilogComponent>;
  public dialogRef: MatDialogRef<ReceiptDilogComponent>;
  filterVal:any;
  Daterangedata: string;
  forHideShowDateRangePicker: boolean = false;
  activeFilters: any = {dayRangeDesc:'All time'};
  StartDate:any = '';
  EndDate: any = '';
  sub4: Subscription;
  subscription: Subscription;
  constructor(
      private _mainAPiServiceService: MainAPiServiceService,
      private toastr: ToastrService,
      public behaviorService: BehaviorService,
      public MatDialog: MatDialog,
      public toolbarServiceService: ToolbarServiceService,
      private SortingbehaviorService: SortingBehaviourService,
      private TableColumnsService: TableColumnsService,
      private _formBuilder: FormBuilder,
      public globalFunService: GlobalFunctionsService,
      public _matDialog: MatDialog,
      public datepipe: DatePipe,

  ) {
      if(this.currentUser.THEMECOLOUR=="theme-blue-gray-dark" || this.currentUser.THEMECOLOUR=="theme-pink-dark"){
          this.showgrayicon=true;
      }else{
          this.showgrayicon=false;
      }
      this.bankFeedForm = this._formBuilder.group({
          bankAccounts: this._formBuilder.array([])
      })
      this.Object = Object;
      this.SortingbehaviorService.bankAccountSorting$
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((result) => {
              if (result) {
                  this.sortingDefaultState = result;
                  localStorage.setItem(
                      "bank_account_screen_sorting",
                      JSON.stringify(result)
                  );
              } else {
                  this.sortingDefaultState = JSON.parse(
                      localStorage.getItem("bank_account_screen_sorting")
                  );
              }
          });
      this.SortingbehaviorService.bankAccountSpentSorting$
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((result) => {
              if (result) {
                  this.sortingDefaultState2 = result;
                  localStorage.setItem(
                      "bank_account_spent_screen_sorting",
                      JSON.stringify(result)
                  );
              } else {
                  this.sortingDefaultState2 = JSON.parse(
                      localStorage.getItem(
                          "bank_account_spent_screen_sorting"
                      )
                  );
              }
          });
      this.behaviorService.selectedMattersClear$
          .subscribe((res) => {
              if (res) {
                  // this.selection.clear();
                  // this.lastFilter = JSON.parse(localStorage.getItem('matter_filter'));
                  //this.lastFilter = JSON.parse(localStorage.getItem('matterFilter_config'));
                  // this.getMatterList(this.lastFilter);
              }
          });
          this.searchTerms.pipe(
              debounceTime(500), // wait for 300ms pause in events
              distinctUntilChanged(), // ignore if next search term is same as previous
            ).subscribe(response => {
              // handle the API response here
              this.selectContactPayeeFormSearch(response);
            });

            this.searchTermsRM.pipe(
              debounceTime(500), // wait for 300ms pause in events
              distinctUntilChanged(), // ignore if next search term is same as previous
            ).subscribe(response => {
              // handle the API response here
              this.selectContactFormSearch(response);
            });

            this.searchTermsSM.pipe(
              debounceTime(500), // wait for 300ms pause in events
              distinctUntilChanged(), // ignore if next search term is same as previous
            ).subscribe(response => {
              // handle the API response here
              this.selectMatterFormSearch(response);
            });

            this.searchTermsRMPayor.pipe(
              debounceTime(500), // wait for 300ms pause in events
              distinctUntilChanged(), // ignore if next search term is same as previous
            ).subscribe(response => {
              // handle the API response here
              this.onChangePayor(response);
            });

            this.searchTermsRMMatter.pipe(
              debounceTime(500), // wait for 300ms pause in events
              distinctUntilChanged(), // ignore if next search term is same as previous
            ).subscribe(response => {
              // handle the API response here
              this.selectMatterFormSearchRM(response);
            });
            this.setDaysRange()
  }

  /**
   * This function is used to onSpend changes.s
   */
  onSpendChange(form: FormGroup) {
      this.searchTerms.next((<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].value);
  }

  /**
   * This function is used to get the receive mony changes
   */
  onReceiveChange(form: FormGroup) {
      this.searchTermsRM.next((<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].value);
  }

  /**
   * This function is used to change the Spent matter
   */
  onSpentMatterChange(form: FormGroup) {
      this.searchTermsSM.next((<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].value);
  }

  /**
   * This function is used to onRMPaayor change.
   */
  onRMPayorChange(form: FormGroup) {
      this.searchTermsRMPayor.next((<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].value);
  }

  /**
   * This function is used to remove the matter changes
   */
  onRMMatterChange(form: FormGroup) {
      this.searchTermsRMMatter.next((<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].value);
  }

  /**
   * This function is used to get the number only
   */
  numberOnly(event: KeyboardEvent) {
      const allowedCharacters = /[0-9.]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!allowedCharacters.test(inputChar)) {
        event.preventDefault();
      }
  }

  /**
   * This function is used to get the list data value.
   */
  getList(data?) {
      this.isLoadingResults = true;
      this.bankFeedList = [];
      this.bankAccountsList().clear();
      this.bankFeedForm.reset();
      // this.chartAccountDetail.ACCOUNTGUID
      // "ACCAAAAAAAAAAA44"
      // ACCAAAAAAAAAAAA2
      this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
      this.pageSizeSpent = JSON.parse(
          localStorage.getItem("lastPageSizeSpent")
      );

      this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM:'', DATETO:'' }}
      // let postData = {
      //     Action: "accounttransactions",
      //     Data: { ACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID },
      // };
      let request=(data ? data : this.filterVal)
      this._mainAPiServiceService.getSetData({ FormAction: 'default', VALIDATEONLY: false, Data: {} }, 'SetIncome').pipe(
          concatMap((response) => combineLatest([of(response), this._mainAPiServiceService.getSetData(request, "bank-feed")]))
      ).subscribe(([response, response2]) => {
          if (response.CODE == 200 && response.STATUS == "success") {
              //   this.toolTipList = response.DATA.FIELDTIPS;
              this.defaultValuesRM = response.DATA.DEFAULTVALUES;
              this.isLoadingResults = false;
          }
          if (response2.CODE == 200 && response2.STATUS == "success") {
              this.isError = false;
              this.MESSAGE = '';
              this.bankFeedList = response2.DATA.BANKFEEDTRANSACTIONS;
              this.bankFeedList.map((element, i) => {
                  element["SELECTEDINDEX"] = false;
                  element["SELECTEDSPENDINDEX"] = false;
                  this.addBankAccount(element,this.defaultValuesRM);
              });
              this.isLoadingResults = false;
              // this.loadExpenseData();
              this.defaultCallME();
              // this.loadIncomeData();
              this.getTableFilter();
              this.getSpentTableFilter();
          } else if(response2.CODE == 469 && response2.STATUS == "error"){
              this.CODE = response2.CODE;
              this.isLoadingResults = false;
              this.bankFeedList = [];
              this.isError = true;
              this.MESSAGE = 'You have not setup a bank feed for this account. Please click on "Connect Bank Feed" in order to do so.';
              // this.toastr.error(res.MESSAGE);
          } else {
              this.isLoadingResults = false;
              this.bankFeedList = [];
              this.isError = true;
              this.MESSAGE = `An error occurred while loading the bank feed transactions - ${response2.MESSAGE}`;
              // this.toastr.error(res.MESSAGE);
          }
      },(error) => {
          this.isLoadingResults = false;
          this.toastr.error(error);
      })

  }

  /**
   * This function is used to get the bank account list.
   */
  bankAccountsList(): FormArray {
      return this.bankFeedForm.get('bankAccounts') as FormArray;
  }

  /**
   * This function is used to add the Bank Account.
   */
  addBankAccount(data,InvoiceDATA) {
      const bnkGroup = this._formBuilder.group({
          SELECTEDSPENDINDEX: [false],
          SELECTEDINDEX: [true],
          MultiSpend:[false],
          MultiSpendTable:[false],
          searchName: [''],
          searchAmount: [''],
          ACCOUNTGUID: [data.ACCOUNTGUID],
          AMOUNT: [data.AMOUNT],
          BALANCE: [data.BALANCE],
          BANKFEEDACCOUNTGUID: [data.BANKFEEDACCOUNTGUID],
          BANKFEEDTRANSACTIONGUID: [data.BANKFEEDTRANSACTIONGUID],
          CATEGORY: [data.CATEGORY],
          COMMENT: [data.COMMENT],
          DETAILS: [data.DETAILS],
          LINKED: [data.LINKED],
          TRANSACTIONDATE: [data.TRANSACTIONDATE],
          TRANSACTIONTYPE: [data.TRANSACTIONTYPE],
          generalReceiptForm: this._formBuilder.group({
              INCOMEGUID: [''],
              INCOMECLASS: [''],
              INCOMEDATETEXT: [''],
              INCOMEDATE: [''],
              INCOMETYPE: [''],
              PAYEE: [''],
              PAYEEGUID: [''],
              AMOUNT: [''],
              gsttype: [''],
              GST: [''],
              BANKACCOUNTGUID: [''],
              BANKACCOUNTGUIDTEXT: [''],
              INCOMEACCOUNTGUID: [''],
              INCOMEACCOUNTGUIDTEXT: [''],
              NOTE: [data.DETAILS],
              classText: ['Income A/C'],
              classType: ['INCOME'],
              isGstReadonly: [true],
              isHide: [false],
              flaxValue: [20]
          }),
          PrepareReceiptForm: this._formBuilder.group({
              INCOMECODE: [InvoiceDATA?.INCOMECODE.toString().padStart(8, "0")],
              INCOMECLASS: ['Receipt'],
              INCOMETYPE: [InvoiceDATA?.INCOMETYPE],
              PAYEE: [''],
              INCOMEDATE: [InvoiceDATA?.INCOMEDATE],
              INCOMEDATETEXT: [],
              AMOUNT: [data.AMOUNT],
              GST: [''],
              BANKACCOUNTGUID: [InvoiceDATA?.BANKACCOUNTGUID],
              BANKACCOUNTGUIDTEXT: [InvoiceDATA?.BANKACCOUNTNUMBER],
              NOTE: [data.DETAILS],
              INCOMEACCOUNTGUID: [''],
              INCOMEACCOUNTGUIDTEXT: [''],
              FIRMGUID: [''],
              FIRMGUIDTEXT: [''],
              Amount: [''],
              RECEIPTAMOUNTEXGST: [''],
              SHOW: [4],
              searchInvoice: [''],
              Unallocated: [''],
              allocatedSelected: [''],
              MatterGUID: [''],
              Matter: [''],
              Clientmattertext: [""],
              Drop1:[''],
              Drop2:[''],
              Drop3:[''],
              PrepareReceiptData:[''],
              highlightedRowsPRD: [''],
              currentInvoiceData: [],
              selectedInvoiceData: []
          }),
          spendmoneyForm: this._formBuilder.group({
              GstTotal:["0.00"],
              AmountTotal:["0.00"],
              OutofBalance:["0.00"],
              DateIncurred: [""],
              Paid: [""],
              DatePaid: [""],
              Amount: [""],
              GST: [""],
              Bankac: [""],
              Notes: [data.DETAILS],
              Type: [""],
              ChequeNo: [""],
              Payee: [""],
              Invoice: [""],
              MultiLineExpense: [""],
              Class: [""],
              Matter: [""],
              AmountIncGST: [""],
              GSTType: [""],
              GST1: [""],
              BankacGUID: [""],
              AmountExGST: [""],
              Expenseac: [""], //add the riquird field
              Note: [""],
              Assetacs: [""],
              Gstac: [""],
              taxac: [""],
              Equityac: [""],
              DatePaidForSend: [""],
              DateIncurredForSend: [""],
              MatterGUID: [""],
              ExpenseacGUID: [""],
              EXPENSEACCOUNTNUMBER: [""],
              BANKACCOUNTNUMBER: [""],
              hide: [true],
              expac: [false]
          })
      });
      this.bankAccountsList().push(bnkGroup);
      if(this.checkNum(bnkGroup.get('AMOUNT').value) == -1){
          // this.defaultCallME(bnkGroup);
          this.forAddshowpopupDataForm(bnkGroup);
      }
      if(this.checkNum(bnkGroup.get('AMOUNT').value) == 1){
          this.setFormValues(bnkGroup);
      }
  }


  /* Clear the value of search filed when click on this function */
  clearSearch(form: FormGroup) {
      this.selection.clear();
      this.selection2.clear();
      this.selectedTable = -1;
      // this.spentMoneydata = null;
      this.spentMoneydata = null;
      this.receiveMoneydata = null;
      this.receiveMoneySelecteddata = null;
      this.spentMoneySelecteddata = null;
      this.totalAmount = 0;
      this.finalAm(0, 0);
      form.controls['searchAmount'].reset();
      form.controls['searchName'].reset();
  }

  /**
   * This function is used to show the data value.
   */
  datashow(form: FormGroup, event) {
      this.exportdatavalue.RECORDS.forEach((element, i) => {
          if (i == 0) {
              let data = element
              this.mattername = [data.SELECTEDDESCRIPTION];
              (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue(null);

              if (event.input) {
                  event.input.value = '';
              } else {
                  // this.MatterInput.nativeElement.value = '';
                  (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
              }
              (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue(data.SHORTNAME);
              this.mattername = [data.SELECTEDDESCRIPTION];
              (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue(data.MATTERGUID);
              // this.matterChange('MatterGuid', data.MATTERGUID);
              if (this.selectedDataSM.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedDataSM.length == 0) {
                  this.selectedDataSM = [data];
              }
          }
      });
  }

  /**
   * This function is used to select the matter for the search.
   */
  selectMatterFormSearchRCForm(form: FormGroup, event: string) {
      this.matterlistRC = [];
      if (event.length == 0) {
          this.matterlistRC = [];
      }
      // let searchobj = { FastSearch: 'True', Search: event }
      let payload = {
          ACTION: "GetData",
          FILTERS: {
              FASTSEARCH: 1,
              SEARCH: event,
          },
      };
      this._mainAPiServiceService
          .getSetData(payload, "contact")
          .subscribe((response) => {
              if (response.CODE == 200 && response.STATUS == "success") {
                  this.exportdatavalueRC = response.DATA;
                  this.searchDataRC = response.DATA.RECORDS;
                  if (response.DATA.RECORDS.length == 0) {
                     // this.matterlistRC.push({ CONTACTNAME: event });
                  } else {
                      this.matterlistRC = this.searchDataRC;
                  }
              } else if (response.CODE == 406 && response.STATUS == "error") {
                  this.toastr.error(response.MESSAGE);
              }
          });
  }

  /**
   * This function is used to select the Receive matter form
   */
  selectRecieveMatterForm(form: FormGroup) {
      this.ShowData = [];
      const dialogRef = this.MatDialog.open(MatterDialogComponent, {
          width: "100%",
          disableClose: true,
          data: null,
      });
      dialogRef.afterClosed().subscribe((result) => {
          if (result) {
              this.contactData = "";
              this.matterData = result;
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue(result.SHORTNAME);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['MatterGUID'].setValue(result.MATTERGUID);
              this.matternameRM = [result.SELECTEDDESCRIPTION];
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue(this.matterData.FIRMGUID);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue(this.matterData.CONTACTNAME);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['SHOW'].setValue(1);    
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].setValue("Show unpaid invoices for matter : " +
              this.matterData.SHORTNAME); 
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue("Show unpaid invoices for client : " +
              this.matterData.CONTACTNAME); 
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop3'].setValue("Show all unpaid invoices");           
              // this.ShowData.push({
              //     id: 1,
              //     text:
              //         "Show unpaid invoices for matter : " +
              //         this.matterData.SHORTNAME,
              // });
              // this.ShowData.push({
              //     id: 2,
              //     text:
              //         "Show unpaid invoices for client : " +
              //         this.matterData.CONTACTNAME,
              // });
              // this.ShowData.push({ id: 3, text: "Show all unpaid invoices" });
              this.GetInvoiceForReceiptForm(form,{
                  MATTERGUID: this.matterData.MATTERGUID,
                  Outstanding: "Yes",
              });
              // localStorage.setItem('set_active_matters', JSON.stringify(result));
          }
      });
  }

  /**
   * This function is used to get tge invoice For RECeipt form.
   */
  GetInvoiceForReceiptForm(form: FormGroup, data) {
     // this.PrepareReceiptData = [];
      this.isLoadingResults = true;
      this._mainAPiServiceService
          .getSetData({ Action: "GetData", Filters: data }, "invoice")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (
                      response.CODE === 200 &&
                      (response.STATUS === "OK" ||
                          response.STATUS === "success")
                  ) {
                      this.TotalInvoice = response.DATA.TOTALOUTSTANDING;
                      this.PrepareReceiptData = new MatTableDataSource(
                          response.DATA.RECORDS
                      );
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].setValue(this.PrepareReceiptData);
                        const formGroup = (<FormGroup>form.controls['PrepareReceiptForm']);
                        const dataSource = formGroup.controls['PrepareReceiptData'].value as MatTableDataSource<any>;

                        if (dataSource && this.paginator) {
                            dataSource.paginator = this.paginator;
                        }
                    //   this.PrepareReceiptData.paginator = this.paginator;
                    //   this.PrepareReceiptData.sort = this.sort;
                      if (response.DATA.RECORDS[0]) {
                          this.isDisplay = false;
                        //   this.highlightedRowsPRD = response.DATA.RECORDS[0]["INVOICEGUID"];
                          (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(response.DATA.RECORDS[0]["INVOICEGUID"]);
                          this.clickRowForm(form,response.DATA.RECORDS[0]);
                        //   this.currentInvoiceData = response.DATA.RECORDS[0];
                          (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue(response.DATA.RECORDS[0]);
                      } else {
                          this.isDisplay = true;
                      }
                      if (this.currentUser.DontAutoAllocateReceipt) {
                        const selectedInvoiceData = (<FormGroup>form.controls['PrepareReceiptForm']).controls['selectedInvoiceData'].value;
                          this.SelectedInvoiceIndex =
                              response.DATA.RECORDS.map(
                                  (el) => el.INVOICEGUID
                              ).indexOf(
                                selectedInvoiceData?.INVOICEGUID
                              );
                          if (this.SelectedInvoiceIndex > -1) {
                            (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(response.DATA.RECORDS[this.SelectedInvoiceIndex]["INVOICEGUID"]);
                            //   this.highlightedRowsPRD =
                            //       response.DATA.RECORDS[
                            //           this.SelectedInvoiceIndex
                            //       ]["INVOICEGUID"];
                              this.clickRowForm(form,
                                  response.DATA.RECORDS[
                                      this.SelectedInvoiceIndex
                                  ]
                              );
                            //   this.currentInvoiceData = response.DATA.RECORDS[this.SelectedInvoiceIndex];
                              (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue(response.DATA.RECORDS[this.SelectedInvoiceIndex]);
                          }
                          this.AutoAllocationClick = false;
                      }
                      this.isLoadingResults = false;
                      if ((<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value > 0) {
                          this.clickAutoAllocationForm(form);
                      }
                      this.revivedAmountForm(form);
                  } else if (response.MESSAGE == "Not logged in") {
                      this.isLoadingResults = false;
                      // this.dialogRef.close(false);
                  }
              },
              (error) => {
                  this.isLoadingResults = false;
                  this.toastr.error(error);
              }
          );
  }

  /**
   * This function is used to revivedAmountform
   */
  revivedAmountForm(form: FormGroup):void {
      if ((<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value > this.TotalInvoice) {
          let val = (<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value - this.TotalInvoice;
          // this.PrepareReceiptForm.controls["Unallocated"].setValue(
          //     val.toFixed(2)
          // );
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].setValue(val.toFixed(2));
          this.GloballyUnallocatedVAl = (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].value;
      } else {
          if (this.AllocationBtn == "clear") {
              // this.PrepareReceiptForm.controls["Unallocated"].setValue(
              //     this.f1.AMOUNT.value
              // );
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].setValue((<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value);
              this.GloballyUnallocatedVAl = (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].value;
          } else {
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].setValue(0);
              this.GloballyUnallocatedVAl = (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].value;
          }
      }
      this.AMOUNT = parseFloat((<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value).toFixed(2);
      let clickedrowIndex = 0;
      if (this.SelectedInvoiceIndex > -1) {
          clickedrowIndex = this.SelectedInvoiceIndex;
      }
      const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
      this.clickRowForm(form,dataSource.data[clickedrowIndex]);
      this.checkCalForm(
          form,
          dataSource.data,
          "autoAllocation",
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value
      );
  }

  /**
   * This function is used to click Row form.
   */
  clickRowForm(form: FormGroup,row: any):void {
    (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(row?.INVOICEGUID);
    const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
    //   this.currentInvoiceData = row;
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue(row);
    //   this.selectedInvoiceData = row;
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['selectedInvoiceData'].setValue(row);
      if (dataSource.data) {
          const indexData = dataSource.data.findIndex(
              (invoice) => invoice.INVOICEGUID === row.INVOICEGUID
          );
          this.INDEX = indexData;
          this.SelectedInvoiceIndex = this.INDEX;
      }
      if (row) {
          // this.PrepareReceiptForm.controls["allocatedSelected"].setValue(
          //     row.ALLOCATED
          // );
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].setValue(row.ALLOCATED);
      }
  }

  /**
   * This function is used to click the clear Allowcation form.
   */
  clickClearAllocationForm(form: FormGroup) {
      // if (this.InvoiceTypeCheck != 3) {
      // this.PrepareReceiptForm.controls["Unallocated"].setValue(0);
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].setValue(0);
      this.GloballyUnallocatedVAl = (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].value;
      this.AllocationBtn = "clear";
      const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
      this.checkCalForm(
          form,
          dataSource.data,
          "clearAllocation",
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value
      );
      if (this.currentUser.DontAutoAllocateReceipt) {
        (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(dataSource.data[this.SelectedInvoiceIndex].INVOICEGUID);
        //   this.highlightedRowsPRD =
        //       dataSource.data[
        //           this.SelectedInvoiceIndex
        //       ].INVOICEGUID;
      }
      // }
  }

  /**
   * This function is used to click Auto Allocation.
   */
  clickAutoAllocationForm(form: FormGroup, click: any = "") {
      // if (this.InvoiceTypeCheck != 3) {
        const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
        const invoiceData = (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].value;
      this.AllocationBtn = "auto";
      if (click == "AutoAllocation") {
          this.AutoAllocationClick = true;
          const selInvoiceIndex = dataSource.data.findIndex(
              (data) =>
                  data.INVOICEGUID == invoiceData.INVOICEGUID
          );
          this.SelectedInvoiceIndex =
              selInvoiceIndex !== -1 ? selInvoiceIndex : -1;
      }
      this.checkCalForm(
          form,
          dataSource.data,
          "autoAllocation",
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value
      );
      if (this.currentUser.DontAutoAllocateReceipt) {
        (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(dataSource.data[this.SelectedInvoiceIndex].INVOICEGUID);
        //   this.highlightedRowsPRD = dataSource.data[this.SelectedInvoiceIndex].INVOICEGUID;
      }
      // }
  }

  /**
   * This function is used to Bankig Dialog Open Form
   */
  BankingDialogOpenForm(form: FormGroup) {
      // for by default bank account select not trust account
      // this.ChartHandlingData = { ClickType: 'WithoutTrust', UseTrust: 'No', PopUp: '', Lable: "Select Account", }
      // localStorage.setItem('ChartURL', JSON.stringify(this.ChartHandlingData));
      // this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);
      const value = (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].value;
      const dialogRef = this.MatDialog.open(BankingDialogComponent, {
          disableClose: true,
          width: "100%",
          data: { AccountType: value, FromWhere: "generalReceiptIncome", RoterPath: 'bank-feed' },
      });
      dialogRef.afterClosed().subscribe((result) => {
          // localStorage.setItem('istrackid', 'GeneralReceiptDilogComponent');
          if (result) {
              // this.generalReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(result.ACCOUNTCLASS + ' - ' + result.ACCOUNTNUMBER);
              // this.generalReceiptForm.controls['BANKACCOUNTGUID'].setValue(result.ACCOUNTGUID);
              (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUIDTEXT'].setValue(result.ACCOUNTCLASS + ' - ' + result.ACCOUNTNUMBER);
              (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUID'].setValue(result.ACCOUNTGUID);
          }
      });
  }

  /**
   * This function is used to check the cal form
   */
  checkCalForm(form: FormGroup,data, checkval, ValEnterByUser) {
    const selectedInvoiceData = (<FormGroup>form.controls['PrepareReceiptForm']).controls['selectedInvoiceData'].value;
      if (typeof data != "undefined") {
          let enteredval = 0;
          let i = 0;
          data.forEach((element) => {
              element.ALLOCATED = 0;
          });
          if (checkval == "autoAllocation") {
              enteredval = Number(ValEnterByUser);
              if (
                  Number(enteredval) > 0 &&
                  this.SelectedInvoiceIndex > -1 &&
                  !this.currentUser.DontAutoAllocateReceipt &&
                  !this.AutoAllocationClick
              ) {
                  if (
                      Number(
                          data[this.SelectedInvoiceIndex]
                              .AMOUNTOUTSTANDINGINCGST
                      ) <= Number(enteredval)
                  ) {
                      data[this.SelectedInvoiceIndex].ALLOCATED = Number(
                          data[this.SelectedInvoiceIndex]
                              .AMOUNTOUTSTANDINGINCGST
                      ).toFixed(2);
                      enteredval =
                          enteredval -
                          data[this.SelectedInvoiceIndex]
                              .AMOUNTOUTSTANDINGINCGST;
                  } else {
                      data[this.SelectedInvoiceIndex].ALLOCATED =
                          Number(enteredval).toFixed(2);
                      enteredval =
                          enteredval -
                          data[this.SelectedInvoiceIndex]
                              .AMOUNTOUTSTANDINGINCGST;
                  }
              }

              for (i = 0; data.length > i; i++) {
                  if (
                      Number(enteredval) > 0 &&
                      data[i] &&
                      (!this.currentUser.DontAutoAllocateReceipt ||
                          this.AutoAllocationClick)
                  ) {
                      if (
                          Number(data[i].AMOUNTOUTSTANDINGINCGST) <=
                          Number(enteredval)
                      ) {
                          data[i].ALLOCATED = Number(
                              data[i].AMOUNTOUTSTANDINGINCGST
                          ).toFixed(2);
                          enteredval =
                              enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                      } else {
                          data[i].ALLOCATED = Number(enteredval).toFixed(2);
                          enteredval =
                              enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                      }
                  } else if (
                      Number(enteredval) > 0 &&
                      data[i] &&
                      this.currentUser.DontAutoAllocateReceipt &&
                      data[i].INVOICEGUID == selectedInvoiceData.INVOICEGUID && !this.AutoAllocationClick
                  ) {
                      ///dont Auto Allocation
                      if (
                          Number(data[i].AMOUNTOUTSTANDINGINCGST) <=
                          Number(enteredval)
                      ) {
                          data[i].ALLOCATED = Number(
                              data[i].AMOUNTOUTSTANDINGINCGST
                          ).toFixed(2);
                          enteredval =
                              enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                      } else {
                          data[i].ALLOCATED = Number(enteredval).toFixed(2);
                          enteredval =
                              enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                      }
                  } else if (
                      Number(enteredval) > 0 &&
                      data[i] &&
                      !this.AutoAllocationClick
                  ) {
                      if (
                          Number(data[i].AMOUNTOUTSTANDINGINCGST) <=
                          Number(enteredval)
                      ) {
                          data[i].ALLOCATED = Number(
                              data[i].AMOUNTOUTSTANDINGINCGST
                          ).toFixed(2);
                          enteredval =
                              enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                      } else {
                          data[i].ALLOCATED = Number(enteredval).toFixed(2);
                          enteredval =
                              enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                      }
                  }
                  // if (data[i] && data[i].INVOICEGUID != this.selectedInvoiceData.INVOICEGUID){
                  //   data[i].ALLOCATED = 0;
                  // }
                  // if ((data[i] && data[i].INVOICEGUID == this.selectedInvoiceData.INVOICEGUID) && this.AllocationBtn != 'auto') {
                  //   data[i].ALLOCATED = (Number(ValEnterByUser)).toFixed(2);
                  // }
              }
          }
          let clickedrowIndex = 0;
          if (this.SelectedInvoiceIndex > -1) {
              clickedrowIndex = this.SelectedInvoiceIndex;
          }
          const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
          this.clickRowForm(form,dataSource.data[clickedrowIndex]);
          this.chekUnallocatedAmountForm(form);
      }
  }

  /**
   * This function is used to check the unallocated Amount data value.
   */
  chekUnallocatedAmountForm(form: FormGroup):void {
      let allotedValue: number = 0;
      const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
      dataSource.data.forEach((element) => {
          allotedValue = Number(allotedValue) + Number(element.ALLOCATED);
      });
      let FinalAmount =
          Number((<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value) - Number(allotedValue.toFixed(2));
      // this.PrepareReceiptForm.controls["Unallocated"].setValue(FinalAmount);
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].setValue(FinalAmount);
  }

  /**
   * This function is used to apply the Receipt form data value
   */
  ApplyReceiptForm(form: FormGroup) {
      this.SingalrowAllocationForm(form);
  }

  /**
   * This function is used to Single row Allocation form data value.
   */
  SingalrowAllocationForm(form: FormGroup) {
    const invoiceData = (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].value;
    const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMEACCOUNTGUID'].setValue(null);
      if (
          Number((<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].value) <=
          Number(invoiceData.AMOUNTOUTSTANDINGINCGST)
      ) {
          dataSource.data[this.INDEX].ALLOCATED = Number(
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].value
          );
        //   this.highlightedRowsPRD = dataSource.data[this.INDEX]["INVOICEGUID"];
            (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(dataSource.data[this.INDEX]["INVOICEGUID"]);
      } else {
          this.toastr.error(
              "You are trying to allocate an amount that is more than the amount outstanding."
          );
          // this.PrepareReceiptForm.controls["allocatedSelected"].setValue(0);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].setValue(0);
          dataSource.data[this.INDEX].ALLOCATED = 0;
      }
      this.chekUnallocatedAmountForm(form);
  }

  /**
   * This function is used to Save the Receipt Form
   */
  SaveReceiptForm(form: FormGroup):void {
      this.AllocationData = [];
      const selectedMatter = (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].value;
      const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
      if(dataSource) {
          dataSource.data.forEach((element) => {
              this.AllocationData.push({
                  INVOICEGUID: element.INVOICEGUID,
                  AMOUNTAPPLIED: element.ALLOCATED,
              });
          });
          this.isspinerRF = true;
          // this.AllocationAmout = Number(this.f1.AMOUNT.value);
          let AllocationDataInsert: any = {
              BANKFEEDTRANSACTIONGUID: form.controls['BANKFEEDTRANSACTIONGUID'].value,
              INCOMECODE: (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMECODE'].value,
              INCOMECLASS: (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMECLASS'].value
                  ? (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMECLASS'].value
                  : "Receipt",
              INCOMETYPE: (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMETYPE'].value,
              // INCOMETYPE:'Write Off',
              FIRMGUID: (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].value,
              INCOMEDATE: (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMEDATE'].value,
              PAYEE: (<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].value,
              AMOUNT: (<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value,
              GST: (<FormGroup>form.controls['PrepareReceiptForm']).controls['GST'].value ? (<FormGroup>form.controls['PrepareReceiptForm']).controls['GST'].value : 0,
              BANKACCOUNTGUID: (<FormGroup>form.controls['PrepareReceiptForm']).controls['BANKACCOUNTGUID'].value,
              INCOMEACCOUNTGUID: "ACCAAAAAAAAAAAA9",
              NOTE: (<FormGroup>form.controls['PrepareReceiptForm']).controls['NOTE'].value,
              MATTERGUID: selectedMatter.MATTERGUID,
              // CLERKFEE: "",
              ALLOCATIONS: this.AllocationData,
          };

          let setReceiptPostData: any = {
              FormAction: "insert",
              VALIDATEONLY: true,
              DATA: AllocationDataInsert,
          };
          this._mainAPiServiceService
              .getSetData(setReceiptPostData, "SetIncome")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe(
                  (response) => {
                  if (
                      response.DATA.INCOMECODE &&
                      response.DATA.INCOMECODE != ""
                  ) {
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMECODE'].setValue(response.DATA.INCOMECODE.toString().padStart(8, "0"));
                      // this.PrepareReceiptForm.controls["INCOMECODE"].setValue(
                      //     response.DATA.INCOMECODE.toString().padStart(8, "0")
                      // );
                      AllocationDataInsert.INCOMECODE =
                          response.DATA.INCOMECODE;
                  } else {
                      AllocationDataInsert.INCOMECODE =
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMECODE'].value;
                  }
                  setReceiptPostData = {
                      FormAction: "insert",
                      VALIDATEONLY: true,
                      DATA: AllocationDataInsert,
                  };
                  if (
                      response.CODE == 200 &&
                      (response.STATUS == "OK" ||
                          response.STATUS == "success")
                  ) {
                      // data.SELECTEDINDEX = false;
                      // form.get('SELECTEDINDEX').setValue(false);
                      this.checkValidationForm(
                          response.DATA.VALIDATIONS,
                          setReceiptPostData,
                          form
                      );
                     // this.isspinerRF = false;
                  } else if (
                      response.CODE == 451 &&
                      response.STATUS == "warning"
                  ) {
                      // data.SELECTEDINDEX = false;
                      // form.get('SELECTEDINDEX').setValue(false);
                      this.checkValidationForm(
                          response.DATA.VALIDATIONS,
                          setReceiptPostData,
                          form
                      );
                      this.isspinerRF = false;
                  } else if (
                      response.CODE == 450 &&
                      response.STATUS == "error"
                  ) {
                      // data.SELECTEDINDEX = false;
                      // form.get('SELECTEDINDEX').setValue(false);
                      this.checkValidationForm(
                          response.DATA.VALIDATIONS,
                          setReceiptPostData,
                          form
                      );
                      this.isspinerRF = false;
                  } else if (response.MESSAGE == "Not logged in") {
                      // data.SELECTEDINDEX = false;
                      form.get('SELECTEDINDEX').setValue(false);
                      form.get('SELECTEDSPENDINDEX').setValue(false);
                      
                      // this.dialogRef.close(false);
                  }
              },
              (error) => {
                  this.isspinerRF = false;
                  this.toastr.error(error);
              }
          );
      }
  }

  /**
   * This function is used to check the validation form
   */
  async checkValidationForm(bodyData: any, details: any, form: FormGroup) {
      await this.globalFunService
          .checkValidation(bodyData, details)
          .subscribe((data) => {
              if (data) {
                  // this.errorWarningData = data.errorWarningData;
                  // this.errorWarningDataArray = data.errorWarningData;
                  if (data.callback) {
                   // this.isspinerRF = true
                      this.SaveReceiptAfterVAlidation1Form(details, form);
                  } else {
                      this.isspinerRF = false;
                  }
              }
          });
  }

  /**
   * This function is used to Save the Receipt After Validation1 Form
   */
  SaveReceiptAfterVAlidation1Form(data: any, form: FormGroup):void {
    this.isspinerRF = true;
      data.VALIDATEONLY = false;
      this._mainAPiServiceService
          .getSetData(data, "SetIncome")
          .subscribe(
              (response) => {
                  if (
                      response.CODE == 200 &&
                      (response.STATUS == "OK" ||
                          response.STATUS == "success")
                  ) {
                    this.isspinerRF = false;
                      // $('#refreshReceiceMoany').click();
                      // $('#refresheReceiptsTab').click();
                      // $('#refreshMatterInvoice').click();
                      // $('#getmatterinvoice').click();
                      // $('#refreshInvoiceTab').click()
                      this.toastr.success("Receipt save successfully");
                      // form.get('SELECTEDINDEX').setValue(false);
                      // (<FormGroup>form.controls['PrepareReceiptForm']).reset();
                      this.bankAccountsList().clear();
                      this.bankFeedForm.reset();
                      setTimeout(() => {
                          this.getList();
                      }, 2000);
                      this.PrepareReceiptData = null;
                      // this.dialogRef.close(false);
                        let DONTGENERATERECEIPT=JSON.parse(localStorage.getItem('currentUser'));
                        if((DONTGENERATERECEIPT?.DONTGENERATERECEIPT == 0 || DONTGENERATERECEIPT?.DONTGENERATERECEIPT == false)){
                         //  this.ganrateDoc(response.DATA.INCOMEGUID);
                         this.ganrateDoc(response.DATA.INCOMEGUID,form);

                        }


                       this.isspinerRF = false;
                  } else if (response.MESSAGE == "Not logged in") {
                      // this.dialogRef.close(false);
                      this.isspinerRF = false;
                  } else {
                      this.isspinerRF = false;
                  }
                  this.isspinerRF = false;
              },
              (error) => {
                  this.isspinerRF = false;
                  this.toastr.error(error);
              }
          );
  }

  /**
   * This function is used to Save the Receipt After Validation1
   */
  SaveReceiptAfterVAlidation1(data: any) {
      data.VALIDATEONLY = false;
      this._mainAPiServiceService
          .getSetData(data, "SetIncome")
          .subscribe(
              (response) => {
                  if (
                      response.CODE == 200 &&
                      (response.STATUS == "OK" ||
                          response.STATUS == "success")
                  ) {
                      // $('#refreshReceiceMoany').click();
                      // $('#refresheReceiptsTab').click();
                      // $('#refreshMatterInvoice').click();
                      // $('#getmatterinvoice').click();
                      // $('#refreshInvoiceTab').click()
                      this.toastr.success("Receipt save successfully");
                      this.PrepareReceiptData = null;
                      // this.dialogRef.close(false);
                      // this.ganrateDoc(response.DATA.INCOMEGUID);
                  } else if (response.MESSAGE == "Not logged in") {
                      // this.dialogRef.close(false);
                  } else {
                      this.isspiner = false;
                  }
                  this.isspiner = false;
              },
              (error) => {
                  this.isspiner = false;
                  this.toastr.error(error);
              }
          );
  }

/**
 * This function is used to ganrate Doc
 */
  ganrateDoc(INCOMEGUID,form) {
      localStorage.setItem('INCOMEGUID_re', INCOMEGUID);
      let warnshow: any = [];
    //   if ((<FormGroup>form.controls['PrepareReceiptForm']).controls['Unallocated'].value == 0) {
          this.isspiner = true;
          this.confirmDialog = this._matDialog.open(ReceiptDilogComponent, {
              disableClose: true, width: '100%', data: { ForWahat: 'genDoc', wichDocGen: "matter", matterData: this.matterData }
          });
          this.confirmDialog.afterClosed().subscribe(result => {
              this.isspiner = false;
              if (result) {
                  this.isspiner = true;
                  this.dialogRef.close(true);
              }
              this.confirmDialog = null;
          });
    //   } else {
    //       warnshow.push('SILQ will save it as a credit which you will be able to apply against your next invoice.' + '\n',
    //           'Do you want to save this credit for the matter or for the firm?' + '\n');
    //       this.confirmDialogRef1 = this._matDialog.open(ReceiptDilogComponent, {
    //           disableClose: true, width: '100%', data: { WorningShow: warnshow, ForWahat: 'warningShow', matterData: this.matterData, INCOMEGUID: INCOMEGUID }
    //       });
    //       this.confirmDialogRef1.afterClosed().subscribe(result => {
    //           this.isspiner = false;
    //           if (result) {
    //               this.isspiner = true;
    //               this.confirmDialog = this._matDialog.open(ReceiptDilogComponent, {
    //                   disableClose: true, width: '100%', data: { ForWahat: 'genDoc', wichDocGen: result, matterData: this.matterData }
    //               });
    //               this.confirmDialog.afterClosed().subscribe(result => {
    //                   this.isspiner = false;
    //                   if (result) {
    //                       this.isspiner = true;
    //                       this.dialogRef.close(true);
    //                   }
    //                   this.confirmDialog = null;
    //               });
    //           }
    //           this.confirmDialog = null;
    //       });
    //   }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
      this.AllocationBtn = "auto";
      window.localStorage.setItem("collectedSelectedBankFeed", "[]");
      this.filterData = {
          Search: "",
      };
      this.amountFilter = {
          AMOUNTSEARCH: "",
      };
      if (this.sortingDefaultState) {
          this.sortactive = this.sortingDefaultState.active;
          this.sortDirection = this.sortingDefaultState.direction;
      } else {
          this.sortactive = "";
          this.sortDirection = "";
      }
      if (this.sortingDefaultState2) {
          this.sortspentactive = this.sortingDefaultState2.active;
          this.sortspentDirection = this.sortingDefaultState2.direction;
      } else {
          this.sortactive = "";
          this.sortDirection = "";
      }
      const userdata = JSON.parse(localStorage.getItem("currentUser"));
      this.subscription =this.behaviorService.ChartAccountData$.subscribe((result1) => {
          const materIDofTab = window.name.split("_");
          this.windowNameId =
              materIDofTab && materIDofTab[1] ? materIDofTab[1] : undefined;
          if (this.windowNameId && userdata.OPENINNEWTAB) {
              this.chartAccountDetail = JSON.parse(
                  localStorage.getItem(
                      this.windowNameId ? this.windowNameId : result1
                  )
              );
              
              // if(this.chartAccountDetail && this.windowNameId == result1.ACCOUNTGUID && !this.chartAccountDetail.NEXTRECONCILEDDATE){
              //     this.chartAccountDetail.NEXTRECONCILEDDATE = (result1.NEXTRECONCILEDDATE)?result1.NEXTRECONCILEDDATE:'';
              //     localStorage.setItem(this.windowNameId, JSON.stringify(this.chartAccountDetail))
              // };

              if (this.chartAccountDetail) {
              } else {
                  if (result1) {
                      this.chartAccountDetail = result1;
                      // localStorage.setItem(this.windowNameId, JSON.stringify(this.chartAccountDetail))
                  }
              }
          } else {
              if (result1) {
                  this.chartAccountDetail = result1;
                  this.getList();
              }
          }
      });
      this.behaviorService.RecouncileConstName$.subscribe((result) => {
          this.windowNameId && userdata.OPENINNEWTAB
              ? (this.chartAccountDetail.ACCOUNTGUID = JSON.parse(
                    localStorage.getItem(this.windowNameId)
                ))
              : null;
      });

  }

  /**
   * This function is used to change the payor
   * @param value -value data
   */
  onChangePayor(value) {
      this.getPayourarray = [];
      if (value.length == 0) {
          this.getPayourarray = [];
      }
      let payload = {
          ACTION: "GetData",
          FILTERS: {
              FASTSEARCH: 1,
              SEARCH: value,
          },
      };
      this._mainAPiServiceService
          .getSetData(payload, "contact")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      if (response.DATA.RECORDS.length == 0) {
                          this.getPayourarray.push({ CONTACTNAME: value });
                      } else {
                          this.getPayourarray = response.DATA.RECORDS;
                      }
                  }
              },
              (err) => {
                  this.toastr.error(err);
              }
          );
  }

  /**
   * This function is used to selpayor Press Tab
   * @param form -form data value
   * @param evt -evt data value
   */
  selPayorOnPressTabForm(form: FormGroup, evt) {
      if (Object.keys(this.getPayourarray[0]).length == 1) {
          this.toolbarServiceService.ContactsDialog(
              "new",
              this.getPayourarray[0]["CONTACTNAME"]
          );
          // this.PrepareReceiptForm.controls["PAYEE"].setValue(null);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].setValue(null);
          this.getPayourarray = [];
      } else {
          const contactName = this.getPayourarray[0]["CONTACTNAME"];
          // this.PrepareReceiptForm.controls["PAYEE"].setValue(contactName);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].setValue(contactName);
      }
  }

  /**
   * This function is used to payeeType change
   * @param form -form data value.
   * @param value -value data
   */
  PayeeTypeChangeForm(form: FormGroup,value) {
      let avaiability = this.getPayourarray.filter(
          (data) => data.CONTACTNAME === value
      );
      if (Object.keys(avaiability[0]).length == 1) {
          this.toolbarServiceService.ContactsDialog("new", value);
          // this.PrepareReceiptForm.controls["PAYEE"].setValue(null);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].setValue(null);
          this.getPayourarray = [];
      } else {
          // this.PrepareReceiptForm.controls["PAYEE"].setValue(value);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].setValue(value);
      }
  }

  /**
   * This function is used to select the client ME Form 
   * @param form -form value
   * @param val -value data
   */
  selectClientMEForm(form: FormGroup, val) {
      this.getPayourarray = [];
      const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
          width: "100%",
          disableClose: true,
          data: { type: "" },
      });
      dialogRef.afterClosed().subscribe((result) => {
          localStorage.setItem("istrackid", "ReceiptDilogComponent");
          if (result) {
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['PAYEE'].setValue(result.CONTACTNAME);
              // this.PrepareReceiptForm.controls["PAYEE"].setValue(
              //     result.CONTACTNAME
              // );
          }
      });
  }

  /**
   * This function is used to master toggle data.
   */
  masterToggle() {
      this.isAllSelected()
          ? this.selection.clear()
          : this.receiveMoneydata.data.forEach((row) =>
                this.selection.select(row)
            );
      this.collectSelectedVal();
  }

  /**
   * This function is used to master toggle 2
   */
  masterToggle2() {
      this.isAllSelectedSpent()
          ? this.selection2.clear()
          : this.spentMoneydata.data.forEach((row) =>
                this.selection2.select(row)
            );
      this.collectSelectedVal2();
  }

  /**
   * This function is used to isAll selected 
   * @returns data value
   */
  isAllSelected() {
      let numSelected: any = 0;
      let numRows: any = 0;
      if (this.selection.selected) {
          numSelected = this.selection.selected.length;
      }
      if (this.receiveMoneydata.data) {
          numRows = this.receiveMoneydata.data.length;
      }
      return numSelected === numRows;
  }

  /**
   * This function is used to si AllSelected Spent
   * @returns -data value
   */
  isAllSelectedSpent() {
      let numSelected: any = 0;
      let numRows: any = 0;
      if (this.selection2.selected) {
          numSelected = this.selection2.selected.length;
      }
      if (this.spentMoneydata.data) {
          numRows = this.spentMoneydata.data.length;
      }
      return numSelected === numRows;
  }

  /**
   * This function is used to check box label
   * @param row -row data value
   * @returns data value
   */
  checkboxLabel(row?: any): string {
      if (!row) {
          return `${this.isAllSelected() ? "select" : "deselect"} all`;
      }
      return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
          row.position + 1
      }`;
  }

  /* Getting the selelcted transactions list when transaction has receive amount */
  collectSelectedVal() {
      this.SelectedWIPCheckBox = [];
      this.receiveMoneySelecteddata = null;
      this.spentMoneySelecteddata = null;
      if (this.selection.selected.length > 0) {
          this.selection.selected.forEach((element) => {
              element.TAGGED = 1;
              this.SelectedWIPCheckBox.push(element);
          });
      } else {
          this.SelectedWIPCheckBox = [];
      }
      window.localStorage.setItem(
          "collectedSelectedBankFeed",
          JSON.stringify(this.SelectedWIPCheckBox)
      );
      this.receiveMoneySelecteddata = new MatTableDataSource(
          this.SelectedWIPCheckBox
      );
      const initialValue = 0;
      const totalAmount = this.SelectedWIPCheckBox.reduce(
          (accumulator, currentValue) =>
              accumulator.AMOUNT
                  ? accumulator.AMOUNT
                  : accumulator + currentValue.AMOUNT,
          initialValue
      );
      this.totalAmount = totalAmount.toFixed(2);
      if (this.SelectedWIPCheckBox.length == 0) {
          this.totalAmount = 0;
          this.finalAmount = 0;
      }
      this.behaviorService.selectedBankFeeds$.next(this.SelectedWIPCheckBox);
      // localStorage.setItem('SelectedMatterCheckbox', JSON.stringify(this.SelectedWIPCheckBox));
  }

  /* Calculate the difference between total amount and selected transactions amount */
  finalAm(amount, totalAmount): Number {
      const calAmount =
          this.checkNum(amount) === -1 ? Math.abs(amount) : amount;
      if (totalAmount) {
          const num = (calAmount - totalAmount).toFixed(2);
          return Number(num);
      }
      return 0;
  }
/**
* This function is used to select the all check box data
* @param row -row data value
* @returns data value
*/
  checkboxLabel2(row?: any): string {
      if (!row) {
          return `${this.isAllSelectedSpent() ? "select" : "deselect"} all`;
      }
      return `${
          this.selection2.isSelected(row) ? "deselect" : "select"
      } row ${row.position + 1}`;
  }

  /* Getting the selelcted transactions list when transaction has spent amount */
  collectSelectedVal2() {
      this.spentMoneySelecteddata = null;
      this.receiveMoneySelecteddata = null;
      this.SelectedWIPCheckBoxSM = [];
      if (this.selection2.selected.length > 0) {
          this.selection2.selected.forEach((element) => {
              element.TAGGED = 1;
              this.SelectedWIPCheckBoxSM.push(element);
          });
      } else {
          this.SelectedWIPCheckBoxSM = [];
      }

      window.localStorage.setItem(
          "collectedSelectedBankFeed",
          JSON.stringify(this.SelectedWIPCheckBoxSM)
      );
      this.spentMoneySelecteddata = new MatTableDataSource(
          this.SelectedWIPCheckBoxSM
      );
      const initialValue = 0;
      const totalAmount = this.SelectedWIPCheckBoxSM.reduce(
          (accumulator, currentValue) =>
              accumulator.AMOUNT
                  ? accumulator.AMOUNT
                  : accumulator + currentValue.AMOUNT,
          initialValue
      );
      this.totalAmount = totalAmount.toFixed(2);
      this.behaviorService.selectedBankFeeds$.next(
          this.SelectedWIPCheckBoxSM
      );
      // localStorage.setItem('SelectedMatterCheckbox', JSON.stringify(this.SelectedWIPCheckBoxSM));
  }

  /**
   * This function is used to get the table filter
   */
  getTableFilter():void {
      let recreivemoneyListColumns: any = JSON.parse(
          localStorage.getItem("receivemoney_list_columns")
      );
      if (recreivemoneyListColumns && recreivemoneyListColumns != null) {
          let data = this.TableColumnsService.filtertableColum(
              recreivemoneyListColumns.ColumnsObj
          );
          this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(
              recreivemoneyListColumns.ColumnsObj
          );
          this.behaviorService.Hyperlink$.next(this.hyperLinkInfo);
          this.tempColobj = data.tempColobj;
          // this.displayedColumns = recreivemoneyListColumns.displayedColumns;
          // INCOMECODE, PAYEE, AMOUNT, DATE, SHORTNAME, CONTACTNAME
          this.ColumnsObj = recreivemoneyListColumns.ColumnsObj;
          this.dateColFilter = data.dateCol;
      } else {
          this.TableColumnsService.getTableFilter("receive money", "")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe(
                  (response) => {
                      if (
                          response.CODE == 200 &&
                          response.STATUS == "success"
                      ) {
                          let data =
                              this.TableColumnsService.filtertableColum(
                                  response.DATA.RECORDS
                              );
                          this.hyperLinkInfo =
                              this.TableColumnsService.hyperLinkInfo(
                                  response.DATA.RECORDS
                              );
                          this.behaviorService.Hyperlink$.next(
                              this.hyperLinkInfo
                          );
                          this.tempColobj = data.tempColobj;
                          // this.displayedColumns = data.showcol;
                          this.ColumnsObj = data.colobj;
                          this.dateColFilter = data.dateCol;
                          // localStorage.setItem('receivemoney_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
                      }
                  },
                  (error) => {
                      this.toastr.error(error);
                  }
              );
      }
  }

  /**
   * This function is used to getSpentTable filter data 
   */
  getSpentTableFilter() {
      let spendmoneyListColumns: any = JSON.parse(
          localStorage.getItem("spendmoney_list_columns")
      );
      if (spendmoneyListColumns && spendmoneyListColumns != null) {
          this.hyperLinkInfoSpent = this.TableColumnsService.hyperLinkInfo(
              spendmoneyListColumns.ColumnsObj
          );
          this.behaviorService.Hyperlink$.next(this.hyperLinkInfo);
          let data = this.TableColumnsService.filtertableColum(
              spendmoneyListColumns.ColumnsObj
          );
          // this.displayedColumns2 = spendmoneyListColumns.displayedColumns;
          this.ColumnsObj2 = spendmoneyListColumns.ColumnsObj;
          this.tempColobj2 = data.tempColobj;
          // if (!this.displayedColumns2.includes("select")) {
          //     if (this.displayedColumns2.length !== 0) {
          //         this.displayedColumns2.splice(0, 0, "select");
          //     }
          // }
          this.dateColFilter2 = data.dateCol;
      } else {
          this.TableColumnsService.getTableFilter("spend money", "")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe(
                  (response) => {
                      if (
                          response.CODE == 200 &&
                          response.STATUS == "success"
                      ) {
                          let data =
                              this.TableColumnsService.filtertableColum(
                                  response.DATA.RECORDS
                              );
                          this.hyperLinkInfoSpent =
                              this.TableColumnsService.hyperLinkInfo(
                                  response.DATA.RECORDS
                              );
                          this.behaviorService.Hyperlink$.next(
                              this.hyperLinkInfoSpent
                          );
                          // this.displayedColumns2 = data.showcol;
                          // this.displayedColumns2.splice(0, 0, "select");
                          this.ColumnsObj2 = data.colobj;
                          this.tempColobj2 = data.tempColobj;
                          this.dateColFilter2 = data.dateCol;
                          // localStorage.setItem('spendmoney_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
                      }
                  },
                  (error) => {
                      this.toastr.error(error);
                  }
              );
      }

  }

  /**
   * This function is used to set the deault width data value
   * @param displayedColumns -display column value data
   * @param timeout -timeout data value
   */
  setDefaultWidth(displayedColumns, timeout) {
      setTimeout(() => {
          displayedColumns.forEach(element => {
              if (element != "select") {
                  let temWidth = this.tempColobj2[element]['WIDTH'];
                  const cssValue = temWidth + 'px';
                  const columnElts = document.getElementsByClassName('mat-column-' + element);
                  for (let i = 0; i < columnElts.length; i++) {
                      const currentEl = columnElts[i] as HTMLDivElement;
                      currentEl.style.visibility = 'inherit';
                      currentEl.style.width = cssValue;
                  }
              }
          });
      }, timeout);
  }

  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit(): void {
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      this.getContactDataUsingBehaviour();
      this.getContactPayeeDataUsingBehaviour();
  }

  /**
   * This function is used to getContactPayeeusing behaviour
   */
  getContactPayeeDataUsingBehaviour() {
      this.behaviorService.newContactData$
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((result) => {
              // localStorage.setItem('istrackid', 'SpandMoneyDialog');
              this.ContactlistPayee = [];
              if (result) {
                  // this.spendmoneyForm.controls["Payee"].setValue(result.CONTACTNAME);
                  this.ContactnamePayee = [result.CONTACTNAME];
                  this.ContactlistPayee = [];
              }
          });
  }

  /**
   * This function is used to Contact matter form open
   * @param form -formdata value
   */
  ContactMatterForm(form: FormGroup) {
      this.searchContactDataPayee = [];
      this.ContactlistPayee = this.searchContactDataPayee;
      const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
          width: "100%",
          disableClose: true,
          data: { type: "" },
      });
      dialogRef.afterClosed().subscribe((result) => {
          localStorage.setItem("istrackid", "SpandMoneyDialog");
          if (result) {
              // this.spendmoneyForm.controls["Payee"].setValue(result.CONTACTNAME);
              (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(result.CONTACTNAME);
              // this.spentWho = result.CONTACTNAME;
              this.ContactnamePayee = [result.CONTACTNAME];
          }
      });
  }

  /**
   * This function is used to ContactMatterReceiveForm
   * @param form -form data value
   */
  ContactMatterReceiveForm(form: FormGroup) {
      this.searchDataRC = [];
      this.matterlistRC = this.searchDataRC;
      const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
          width: "100%",
          disableClose: true,
          data: { type: "", default: "Correspondents and Parties" },
      });
      dialogRef.afterClosed().subscribe((result) => {
          localStorage.setItem("istrackid", "MatterPopupComponent");
          if (result) {
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue(result.CONTACTGUID);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Clientmattertext'].setValue(result.CONTACTNAME);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue(result.CONTACTNAME);
              // this.PrepareReceiptForm.controls["FIRMGUID"].setValue(
              //     result.CONTACTGUID
              // );
              // this.PrepareReceiptForm.controls["Clientmattertext"].setValue(
              //     result.CONTACTNAME
              // );
              // this.PrepareReceiptForm.controls["FIRMGUIDTEXT"].setValue(
              //     result.CONTACTNAME
              // );
              this.matternameRC = [result.CONTACTNAME];
              this.contactData = result;
              // this.PrepareReceiptForm.controls['SHOW'].setValue(2);
              // (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].setValue("Show unpaid invoices for matter : " +
              // this.matterData.SHORTNAME); 
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue("Show unpaid invoices for client : " +
              result.CONTACTNAME);
              // this.ShowData.map((element) => {
              //     if (element.id === 2) {
              //         element.text = `Show unpaid invoices for client : ${result.CONTACTNAME}`;
              //     }
              // });
              this.GetInvoiceForReceiptForm(form,{
                  CONTACTGUID: result.CONTACTGUID,
                  Outstanding: "Yes",
              });
              // if (result.CONTACTTYPE == "Person") {
              // }
          }
      });
  }

  /**
   * This function is used to show the data for receive the form data value.
   */
  datashowReceiveForm(form: FormGroup,event):void {
      this.CallHostlistner = true;
      if (
          this.exportdatavalueRC &&
          this.exportdatavalueRC.RECORDS &&
          this.exportdatavalueRC.RECORDS.length != 0
      ) {
          let result=this.exportdatavalueRC.RECORDS[0];
          if (result) {
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue(result.CONTACTGUID);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Clientmattertext'].setValue(result.CONTACTNAME);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue(result.CONTACTNAME);
              this.matternameRC = [result.CONTACTNAME];
              this.contactData = result;
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue("Show unpaid invoices for client : " +
              result.CONTACTNAME); 
              // this.ShowData.map((element) => {
              //     if (element.id === 2) {
              //         element.text = `Show unpaid invoices for client : ' ${result.CONTACTNAME}`;
              //     }
              // });
              this.GetInvoiceForReceiptForm(form,{
                  CONTACTGUID: result.CONTACTGUID,
                  Outstanding: "Yes",
              });
              // if (result.CONTACTTYPE == "Person") {
              // }
          }
      } else {
          this.toolbarServiceService.ContactsDialog(
              "new",
              this.MatterInputRC.nativeElement.value
          );
          this.matternameRC = [];
          this.matterlistRC = [];
          this.MatterInputRC.nativeElement.value = "";
      }
      this.CallHostlistner = false;
  }

  /**
   * This function is used to remove the RCForm
   */
  removeRCForm(form: FormGroup, fruit: any):void {
      this.behaviorService.newContactData$.next(null);
      const index = this.matternameRC.indexOf(fruit);
      if (index >= 0) {
          this.matternameRC.splice(index, 1);
          // this.PrepareReceiptForm.controls["FIRMGUID"].setValue("");
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue("");
          //this.Deliverable = [];
      }
      let sortname = fruit.split("-")[0];
      this.rmRCForm(sortname);
  }

  /**
   * This function is used to rmRCForm
   */
  rmRCForm(sortname):void {
      this.selectedDataRC.forEach((element: any, index: any) => {
          if (
              element.CONTACTNAME.toString() ==
              sortname.replace(/^\s+|\s+$/gm, "")
          ) {
              this.selectedDataRC.splice(index, 1);
          }
      });
  }

  /**
   * This function is used to add the RCForm
   */
  addRCForm(form: FormGroup,event: MatChipInputEvent): void {
      const value = (event.value || "").trim();
      this.matterlistRC.forEach((element: any) => {
          if (element.CONTACTNAME == event.value) {
              // Add our fruit
              const array3 = this.matternameRC;
              if (value) {
                  if (
                      this.matternameRC.indexOf(
                          value.replace(/^\s+|\s+$/gm, "")
                      ) == -1
                  ) {
                      this.matternameRC = [value];
                  }
              }
          }
      });

      // Clear the input value
      if (event.input) {
          event.input.value = "";
      }
      // this.PrepareReceiptForm.controls["Clientmattertext"].setValue(null);
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Clientmattertext'].setValue(null);
  }

  /**
   * This function is used to select the Value From RC
   */
  selectedValueRCForm(form: FormGroup, event: MatAutocompleteSelectedEvent): void {
      if (
          this.matternameRC.indexOf(
              event.option.viewValue.replace(/^\s+|\s+$/gm, "")
          ) == -1
      ) {
          this.matternameRC = [event.option.viewValue];
          this.MatterInputRC.nativeElement.value = "";
          // this.PrepareReceiptForm.controls["Clientmattertext"].setValue(null);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Clientmattertext'].setValue(null);
      }
  }

  /**
   * This function is used to Select the Matter guid data value.
   */
  selectedmatterguidRCForm(form: FormGroup, result: any) {
      this.MatterInputRC.nativeElement.value = "";
      if (Object.keys(result).length == 1) {
          this.toolbarServiceService.ContactsDialog(
              "new",
              result.CONTACTNAME
          );
          this.matternameRC = [];
          this.matterlistRC = [];
      } else {
          if (result) {
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue(result.CONTACTGUID);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Clientmattertext'].setValue(result.CONTACTNAME);
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue(result.CONTACTNAME);
             
              this.matternameRC = [result.CONTACTNAME];
              this.contactData = result;
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue("Show unpaid invoices for client : " +
              result.CONTACTNAME); 
              // this.ShowData.map((element) => {
              //     if (element.id === 2) {
              //         element.text = `Show unpaid invoices for client : ' ${result.CONTACTNAME}`;
              //     }
              // });
              this.GetInvoiceForReceiptForm(form,{
                  CONTACTGUID: result.CONTACTGUID,
                  Outstanding: "Yes",
              });
          }
      }
  }

  /**
   * This function is used to select the PayeeFormSearch.
   */
  selectContactPayeeFormSearch(event) {
      this.ContactlistPayee = [];
      // let searchobj = { FastSearch: 'True', Search: event }
      if(event) {
          let payload = {
              ACTION: "GetData",
              FILTERS: {
                  FASTSEARCH: 1,
                  SEARCH: event,
              },
          };
          this._mainAPiServiceService
              .getSetData(payload, "contact")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe((response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      this.exportdatavalueOFContactPayee = response.DATA;
                      this.searchContactDataPayee = response.DATA.RECORDS;
                      if (response.DATA.RECORDS.length == 0) {
                          this.ContactlistPayee.push({ CONTACTNAME: event });
                      } else {
                          this.ContactlistPayee = this.searchContactDataPayee;
                      }
                  }
              });
      }
      // return this._mainAPiServiceService.getSetData(payload, "contact")
  }

  /**
   * This function is used to Contact PayeedatashowForm.
   */
  ContactPayeedatashowForm(event, form: FormGroup):void {
      this.ContactnamePayee = [];
      this.CallHostlistner = true;
      if (
          this.exportdatavalueOFContactPayee &&
          this.exportdatavalueOFContactPayee.RECORDS &&
          this.exportdatavalueOFContactPayee.RECORDS.length != 0
      ) {
          this.result = this.exportdatavalueOFContactPayee.RECORDS[0];
          // this.spendmoneyForm.controls['Payee'].setValue(null);
          (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(null);
          if (event.input) {
              event.input.value = "";
          } else {
              // event.target.value = "";
              (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue("");
              // this.MatterInputContactPayee.nativeElement.value = "";
          }
          // this.spendmoneyForm.controls["Payee"].setValue(this.result.CONTACTNAME);
          (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(this.result.CONTACTNAME);
          this.ContactnamePayee = [this.result.CONTACTNAME];
      } else {
          // this.toolbarServiceService.ContactsDialog(
          //     "new",
          //     this.MatterInputContactPayee.nativeElement.value
          // );
          // this.ContactnamePayee = [];
          // this.ContactlistPayee = [];
          // this.spendmoneyForm.controls["Payee"].setValue(this.MatterInputContactPayee.nativeElement.value);
          (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(event.target.value);
          // this.MatterInputContactPayee.nativeElement.value = "";
          this.ContactnamePayee = [event.target.value];
      }
      this.CallHostlistner = false;
  }

  /**
   * This function is used to get the FormValue.
   */
  getFormValue(form: FormGroup){
      const value = (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].value;
      return value;
  }

  /**
   * This function is used to get the RM value.
   */
  getRMValue(form: FormGroup):void{
      const value = (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].value;
      return value;
  }

  /**
   * This function is used to remove the Contact Payee Form 
   */
  removeContactPayeeForm(fruit: any, form: FormGroup) {
      const index = this.ContactnamePayee.indexOf(fruit);
      if (index >= 0) {
          this.ContactnamePayee.splice(index, 1);
          // this.contactForm.controls['COMPANYCONTACTGUID'].setValue('');
          //this.Deliverable = [];
      }
      this.searchTerms.next('');
      let sortname = fruit.split("-")[0];
      this.rmContactPayeedataForm(sortname);
      // this.MatterInputContactPayee.nativeElement.value='';
      (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue("");
      this.ContactlistPayee = [];
  }

  /**
   * This function is used to remove the Contact Payee data
   */
  rmContactPayeedataForm(sortname) {
      this.selectedContctDataPayee.forEach((element: any, index: any) => {
          if (
              element.CONTACTNAME.toString() ==
              sortname.replace(/^\s+|\s+$/gm, "")
          ) {
              this.selectedContctDataPayee.splice(index, 1);
          }
      });
  }

  /**
   * This function is used to add the contact PayeeForm
   */
  addContactPayeeForm(event: MatChipInputEvent, form: FormGroup): void {
      const value = (event.value || "").trim();
      this.ContactlistPayee.forEach((element: any) => {
          if (element.CONTACTNAME == event.value) {
              // Add our fruit
              const array3 = this.ContactnamePayee;
              if (value) {
                  if (
                      this.ContactnamePayee.indexOf(
                          value.replace(/^\s+|\s+$/gm, "")
                      ) == -1
                  ) {
                      this.ContactnamePayee = [value];
                  }
              }
          }
      });

      // Clear the input value
      if (event.input) {
          event.input.value = "";
      }
      // this.spendmoneyForm.controls["Payee"].setValue(null);
      (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(null);
  }

  /**
   * This function is used to select the Contact Payee Form data
   */
  selectedContactPayeeForm(result: any, form: FormGroup) {
      // this.MatterInputContactPayee.nativeElement.value = "";
      (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue("");
      if (Object.keys(result).length == 1) {
          // this.toolbarServiceService.ContactsDialog(
          //     "new",
          //     result.CONTACTNAME
          // );
          // this.ContactnamePayee = [];
          // this.ContactlistPayee = [];
          (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(result.CONTACTNAME);
              // this.spentWho = result.CONTACTNAME;
          this.ContactnamePayee = [result.CONTACTNAME];
      } else {
          if (result) {
              (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].setValue(result.CONTACTNAME);
              // this.spentWho = result.CONTACTNAME;
              this.ContactnamePayee = [result.CONTACTNAME];
          }
      }
  }

  /**
   * This function is used to onsearch the data value.
   */
  onSearch(formValue: FormGroup, text) {
      if(text == 'name') {
          formValue.controls['searchAmount'].setValue('');
      }
      if(text == 'amount') {
          formValue.controls['searchName'].setValue('');
      }
      // if (text == "name") {
      //     this.searchAmount.nativeElement.value = "";
      // }
      // if (text == "amount") {
      //     this.searchName.nativeElement.value = "";
      // }
      // this.searchFilter = value;
      // this.filterData.Search = this.searchFilter;
      // this.amountFilter.AMOUNTSEARCH = this.searchFilter;
      // this.loadData(this.filterData);
  }

  /**
   * This function is used to onSearchInvoice
   */
  onSearchInvoice(form: FormGroup, text, event: KeyboardEvent) {
      if (event.key === 'Enter') {
          event.stopPropagation();
          event.preventDefault();
          if(text == 'searchInvoice') {
              if((<FormGroup>form.controls['PrepareReceiptForm']).controls.searchInvoice.value) {
                  this.getInvoicesSearch(form);
              }
          }
      }
      // this.searchInvoice.nativeElement.focus();
  }

  /**
   * This function is used to get Invoice Search data value
   */
  getInvoicesSearch(form: FormGroup){
      // const formValue = (<FormGroup>form.controls['PrepareReceiptForm']).controls.searchInvoice.value;
      // const data = {
      //     ENDDATE: "",
      //     INVOICECODE: "",
      //     OUTSTANDING: "Yes",
      //     SEARCHSTRING: formValue,
      //     STARTDATE: ""
      // };
      // this.GetInvoiceForReceiptForm(form,data)
      // this.handleApiResponse(form, formValue);
      const formValue = (<FormGroup>form.controls['PrepareReceiptForm']).controls.searchInvoice.value;
      if(formValue) {
          this.sub1 = of(formValue).pipe(
              // debounceTime(500), // wait for 500ms pause in events
              // distinctUntilChanged(), 
              // take(1),
              // takeLast(1),
              switchMap(response => {
                  const data = {
                      ENDDATE: "",
                      INVOICECODE: "",
                      OUTSTANDING: "Yes",
                      SEARCHSTRING: response,
                      STARTDATE: ""
                  };
                  this.isLoadingResults = true;
                  return this._mainAPiServiceService
                      .getSetData({ Action: "GetData", Filters: data }, "invoice")
              })
            ).subscribe(
              (response) => {
                // handle the API response here
                this.handleApiResponse(form, response);
              },
              (error) => {
                this.isLoadingResults = false;
                this.toastr.error(error);
              }
          );
      }
  }

  /**
   * This function is used to handle Api Response
   */
  handleApiResponse(form: FormGroup,response: any):void {
      if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
        this.TotalInvoice = response.DATA.TOTALOUTSTANDING;
        this.PrepareReceiptData = new MatTableDataSource(response.DATA.RECORDS);
        (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].setValue(this.PrepareReceiptData);
        const formGroup = (<FormGroup>form.controls['PrepareReceiptForm']);
        const dataSource = formGroup.controls['PrepareReceiptData'].value as MatTableDataSource<any>;

        if (dataSource && this.paginator) {
            dataSource.paginator = this.paginator;
        }
        // this.PrepareReceiptData.paginator = this.paginator;
        // this.PrepareReceiptData.sort = this.sort;
        if (response.DATA.RECORDS[0]) {
          this.isDisplay = false;
        //   this.highlightedRowsPRD = response.DATA.RECORDS[0]["INVOICEGUID"];
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(response.DATA.RECORDS[0]["INVOICEGUID"]);
          this.clickRowForm(form, response.DATA.RECORDS[0]);
        //   this.currentInvoiceData = response.DATA.RECORDS[0];
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue(response.DATA.RECORDS[0]);
        } else {
          this.isDisplay = true;
        }
        if (this.currentUser.DontAutoAllocateReceipt) {
          const selectedInvoiceData = (<FormGroup>form.controls['PrepareReceiptForm']).controls['selectedInvoiceData'].value;
          this.SelectedInvoiceIndex = response.DATA.RECORDS.map(el => el.INVOICEGUID).indexOf(selectedInvoiceData?.INVOICEGUID);
          if (this.SelectedInvoiceIndex > -1) {
            // this.highlightedRowsPRD = response.DATA.RECORDS[this.SelectedInvoiceIndex]["INVOICEGUID"];
            (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue(response.DATA.RECORDS[this.SelectedInvoiceIndex]["INVOICEGUID"]);
            this.clickRowForm(form, response.DATA.RECORDS[this.SelectedInvoiceIndex]);
            (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue(response.DATA.RECORDS[this.SelectedInvoiceIndex]);
            // this.currentInvoiceData = response.DATA.RECORDS[this.SelectedInvoiceIndex];
          }
          this.AutoAllocationClick = false;
        }
        this.isLoadingResults = false;
        if ((<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].value > 0) {
          this.clickAutoAllocationForm(form);
        }
        this.revivedAmountForm(form);
      } else if (response.MESSAGE == "Not logged in") {
        this.isLoadingResults = false;
        // this.dialogRef.close(false);
      }
    }

    /**
     * This function is used to load the Expense data value.
     */
  loadExpenseData():void {
      const postDataForBanking = { AccountClass: "EXPENSE" };
      this._mainAPiServiceService
          .getSetData(postDataForBanking, "GetAccount")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      this.expenseData = response.DATA.ACCOUNTS;
                  } else if (response.MESSAGE == "Not logged in") {
                  }
              },
              (err) => {
                  this.isLoadingResults = false;
              }
          );
  }

  /**
   * This fu ction is used to load the Income Data
   */
  loadIncomeData():void {
      const postDataForBanking = { AccountClass: "INCOME" };
      this._mainAPiServiceService
          .getSetData(postDataForBanking, "GetAccount")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      this.incomeData = response.DATA.ACCOUNTS;
                  } else if (response.MESSAGE == "Not logged in") {
                  }
              },
              (err) => {
                  this.isLoadingResults = false;
              }
          );
  }

  /**
   * This fu ction is used to transform data.
   */
  transform(value: string): string {
      // Split the date string into day, month, and year
      const parts = value.split('/');
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
  
      // Create a new Date object using the year, month, and day
      const date = new Date(parseInt(year), parseInt(month) - 2, parseInt(day));
      // Use the DatePipe to format the date in the desired format
      const formattedDate = new DatePipe('en-US').transform(date, 'dd/MM/yyyy');
  
      return formattedDate || value; // Return the formatted date or the original value if formatting fails
  }

  /**
   * This function is used to load the Data
   */
  loadIData(bank: FormGroup,i) {
      this.selection.clear();
      this.selectedTable = i;
      this.spentMoneydata = null;
      this.receiveMoneydata = [];
      this.receiveMoneySelecteddata = null;
      this.spentMoneySelecteddata = null;
      this.totalAmount = 0;
      this.finalAm(0, 0);
      let payload;
      if(bank.get('searchName').value != '') {
          payload = {
              "INCOMECLASS": "",
              "ITEMSTARTDATE": this.transform(bank.get('TRANSACTIONDATE').value),
              "ITEMENDDATE": bank.get('TRANSACTIONDATE').value,
              "SEARCH": bank.get('searchName').value,
              "BANKFEEDSEARCH": true
          }
      }
      if(bank.get('searchAmount').value != '') {
          payload = {
              "INCOMECLASS": "",
              "ITEMSTARTDATE": this.transform(bank.get('TRANSACTIONDATE').value),
              "ITEMENDDATE": bank.get('TRANSACTIONDATE').value,
              "AMOUNTSEARCH": bank.get('searchAmount').value,
              "BANKFEEDSEARCH": true
          }
      }
      this._mainAPiServiceService
          .getSetData(
              { 
                  Action: "GetData", 
                  Filters: payload
              },
              "income"
          )
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      // this.totalAountData = { TOTALINCGST: response.DATA.TOTALINCGST, TOTALEXGST: response.DATA.TOTALEXGST };
                      this.receiveMoneydata = new MatTableDataSource(
                          response.DATA.RECORDS
                      );
                      this.receiveMoneydata.paginator = this.paginator;
                      if(response.DATA.RECORDS.length > 0) {
                          this.isDisplayRM = false;
                      }else {
                          this.isDisplayRM = true;
                      }
                      this.receivesortingDate();
                      this.setDefaultWidthRM(this.displayedColumns,500);
                  } else {
                      // this.totalAountData = { TOTALINCGST: response.DATA.TOTALINCGST, TOTALEXGST: response.DATA.TOTALEXGST };
                  }
              },
              (err) => {
                  this.toastr.error(err);
              }
          );
  }

  /**
   * This function is used to receive the sorting Date.
   */
  receivesortingDate():void {
      this.receiveMoneydata.sortingDataAccessor = (item, property) => {
        let FildeValue = this.dateColFilter;
        if (FildeValue.includes(property)) {
          if (item[property]) {
            let tempDate = item[property].split("/");
            let Sd = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
            let newDate = new Date(Sd);
            return newDate;
          }
          return item[property];
        } else {
          return item[property];
        }
      }
      // proper shorting for date 
      this.receiveMoneydata.sort = this.sort;
  }

/**
 * This function is used to set the default WidthRM
 */
  setDefaultWidthRM(displayedColumns, timeout) {
      setTimeout(() => {
        displayedColumns.forEach(element => {
          if(element != 'select'){
              let temWidth = this.tempColobj[element]['WIDTH'];
              const cssValue = temWidth + 'px';
              const columnElts = document.getElementsByClassName('mat-column-' + element);
              for (let i = 0; i < columnElts.length; i++) {
                  const currentEl = columnElts[i] as HTMLDivElement;
                  currentEl.style.visibility = 'inherit';
                  currentEl.style.width = cssValue;
              }
          }
        });
      }, timeout);
  }

  /**
   * This function is used to load the data
   */
  loadEData(bank: FormGroup,i) {
      this.selection2.clear();
      this.selectedTable = i;
      // this.spentMoneydata = null;
      this.spentMoneydata = [];
      this.receiveMoneydata = null;
      this.receiveMoneySelecteddata = null;
      this.spentMoneySelecteddata = null;
      this.totalAmount = 0;
      this.finalAm(0, 0);
      let payload;
      if(bank.get('searchName').value != '') {
          payload = {
              "EXPENDITURECLASS": "",
              "INCURREDSTARTDATE": this.transform(bank.get('TRANSACTIONDATE').value),
              "INCURREDENDDATE": bank.get('TRANSACTIONDATE').value,
              "PAIDSTARTDATE": "",
            "PAIDENDDATE": "",
              "Search": bank.get('searchName').value,
              "BANKFEEDSEARCH": true
          }
      }
      if(bank.get('searchAmount').value != '') {
          payload = {
              "EXPENDITURECLASS": "",
              "INCURREDSTARTDATE": this.transform(bank.get('TRANSACTIONDATE').value),
              "INCURREDENDDATE": bank.get('TRANSACTIONDATE').value,
              "PAIDSTARTDATE": "",
            "PAIDENDDATE": "",
              "AMOUNTSEARCH": bank.get('searchAmount').value,
              "BANKFEEDSEARCH": true
          }
      }
      this._mainAPiServiceService
          .getSetData(
              { Action: "Getdata", Filters: payload },
              "expenditure"
          )
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      // this.listingTotal = response.DATA;
                      this.spentMoneydata = new MatTableDataSource(
                          response.DATA.RECORDS
                      );
                      this.spentMoneydata.paginator = this.paginator;
                  } else {
                      // this.listingTotal = response.DATA;
                  }
                  this.sortingDate();
                  this.setDefaultWidth(this.displayedColumns2, 500);
                  // this.searchFilter = '';
              },
              (error) => {
                  this.toastr.error(error);
              }
          );
  }

  /**
   * This functionis used to sorting the Date
   */
  sortingDate() {
      this.spentMoneydata.sortingDataAccessor = (item, property) => {
          let FildeValue = this.dateColFilter2;
          if (FildeValue.includes(property)) {
              if (item[property]) {
                  let tempDate = item[property].split("/");
                  let Sd = new Date(
                      tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                  );
                  let newDate = new Date(Sd);
                  return newDate;
              }
              return item[property];
          } else {
              return item[property];
          }
      };
      this.spentMoneydata.sort = this.sort;
  }

  /**
   * This function is used to gloablecalculation
   */
  async globallyCalculation(form: FormGroup, index) {
      this.Ftotalval=0;
      this.FTotalGST=0;
      for (const element of this.getDataForTable) {
        if (element.INDEX == index) {
          //await this.processElementAsync(element);
          this.Ftotalval=this.Ftotalval+Number(element.AMOUNT);
          this.FTotalGST=this.FTotalGST+Number(element.GST)
        }
      }

      (<FormGroup>form.controls['spendmoneyForm']).controls['AmountTotal'].setValue(
        parseFloat((this.Ftotalval == 0.00)? 0.00 : this.Ftotalval).toFixed(2)
      );
    
      (<FormGroup>form.controls['spendmoneyForm']).controls['GstTotal'].setValue(
        parseFloat((this.FTotalGST == 0.00)? 0.00 : this.FTotalGST).toFixed(2)
      );

      let OutOFBALANCE=(this.Ftotalval == 0.00) ? 0.00 : this.Ftotalval- Math.abs(Number(form.controls['AMOUNT'].value));
      (<FormGroup>form.controls['spendmoneyForm']).controls['OutofBalance'].setValue(
          parseFloat(JSON.stringify(OutOFBALANCE)).toFixed(2)
      );

  }
    
  /**
   * This function is used to process the Element Async data value.
   */
    async processElementAsync(element): Promise<void> {
      return new Promise(resolve => {
        // Assuming some asynchronous operation (replace with actual async logic)
        setTimeout(() => {
          this.FAmount.push(Number(element.AMOUNT));
          this.FGst.push(Number(element.GST));
          resolve();
        }, 1000); // Replace 1000 with the actual delay or remove it for real asynchronous logic
      });
    }

    /**
     * This function is used to save the SpenMoneyData Form
     */
async  saveSpendMoneyDataForm(form: FormGroup,bankIndex?) {
      let MultiSpendFlag=form.get('MultiSpend').value;
      // const FinalExGSTAmount = (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].value - Number((<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value)
      const FinalExGSTAmount = Math.abs(Number(form.controls['AMOUNT'].value)) - Number((<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value)
      let Data;
      if(MultiSpendFlag ==false){
          Data = {
              EXPENDITUREGUID: " ",
              BANKFEEDTRANSACTIONGUID: form.controls['BANKFEEDTRANSACTIONGUID'].value,
              EXPENDITURETYPE: "EFT",
              BANKACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID,
              STATUS: "Paid",
              CHEQUENO: "",
              PAYEE: (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].value,
              MULTILINE: 0,
              AMOUNT: FinalExGSTAmount,
              GST: Number((<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value).toFixed(2),
              DATE: form.controls['TRANSACTIONDATE'].value,
              RECEIVEDDATE: form.controls['TRANSACTIONDATE'].value,
              USERCODE: "",
              SOURCEREFERENCE: "",
              NOTE: (<FormGroup>form.controls['spendmoneyForm']).controls['Notes'].value ? (<FormGroup>form.controls['spendmoneyForm']).controls['Notes'].value : form.controls['DETAILS'].value,
              EXPENDITUREITEMS: [
                  {
                      AMOUNTEXGST: Number((<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].value),
                      EXPENDITURECLASS: (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].value,
                      EXPENDITUREGUID: "",
                      EXPENDITUREITEMGUID: !this.SendMoney_data.EXPENDITUREITEMS ? "" : this.SendMoney_data.EXPENDITUREITEMS.length != 0 ? this.SendMoney_data.EXPENDITUREITEMS[0].EXPENDITUREITEMGUID : "",
                      EXPENSEACCOUNTGUID: (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].value,
                      GST: Number((<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value),
                      GSTTYPE: (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].value,
                      MATTERGUID: "",
                      NOTE: "",
                      SHORTNAME: "",
                      WORKITEMGUID: ""
                  },
              ],
          };
          this.Setata(Data, false ,form,bankIndex);
      }else{

          this.OpenLoader=bankIndex
          this.sendItem=[];
       let MultiSpendData= await this.getMultispendData(form,bankIndex);
       this.FinalExGSTAmount = this.Ftotalval - this.FTotalGST;
       Data = {
          EXPENDITUREGUID: " ",
          BANKFEEDTRANSACTIONGUID: form.controls['BANKFEEDTRANSACTIONGUID'].value,
          EXPENDITURETYPE: "EFT",
          BANKACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID,
          STATUS: "Paid",
          CHEQUENO: "",
          PAYEE: (<FormGroup>form.controls['spendmoneyForm']).controls['Payee'].value,
          MULTILINE: 1,
          AMOUNT: this.FinalExGSTAmount,
          GST: Number(this.FTotalGST.toFixed(2)),
          DATE: form.controls['TRANSACTIONDATE'].value,
          RECEIVEDDATE: form.controls['TRANSACTIONDATE'].value,
          USERCODE: "",
          SOURCEREFERENCE: "",
          NOTE: (<FormGroup>form.controls['spendmoneyForm']).controls['Notes'].value ? (<FormGroup>form.controls['spendmoneyForm']).controls['Notes'].value : form.controls['DETAILS'].value,
          EXPENDITUREITEMS: MultiSpendData
          
      };

          this.Setata(Data, false ,form,bankIndex);
      }
      
     
  }

  /**
   * This function is used to set the data value.
   */
  Setata(potData, isOnlyValidate, form: FormGroup,bankIndex) {
      if (Number.isNaN(potData.EXPENDITUREITEMS[0] && (Number.isNaN(potData.EXPENDITUREITEMS[0].AMOUNTEXGST) || Number.isNaN(potData.EXPENDITUREITEMS[0].GST)))) {
          let warningData: any = [];
          this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: "100%", data: { errorValidation: warningData }, });
          this.confirmDialogRef.componentInstance.confirmMessage = "Check the Validation Section for Errors/Warnings.";
          return false;

      }
      this.isspiner = true;
      let details = { FormAction: 'insert', VALIDATEONLY: true, Data: potData, };
      this._mainAPiServiceService.getSetData(details, "SetExpenditure").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
          if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
              this.checkValidationFormSpendMoney(response.DATA.VALIDATIONS, details, isOnlyValidate, form,bankIndex);
              this.isspiner = false;
          } else if (response.CODE == 451 && response.STATUS == "warning") {
              this.checkValidationFormSpendMoney(response.DATA.VALIDATIONS, details, isOnlyValidate, form,bankIndex);
              this.isspiner = false;
          } else if (response.CODE == 450 && response.STATUS == "error") {
              this.checkValidationFormSpendMoney(response.DATA.VALIDATIONS, details, isOnlyValidate, form,bankIndex);
              this.isspiner = false;
          } else if (response.MESSAGE == "Not logged in") {
              // this.dialogRef.close(false);
          }
      }, (error) => {
          this.isspiner = false;
          this.toastr.error(error);
      });
  }

  /**
   * This function is used to check the Validation Form for the SpendMoney.
   */
  async checkValidationFormSpendMoney(bodyData: any, details: any, isOnlyValidate, form: FormGroup,bankIndex) {
      this.isspiner = false;
      await this.globalFunService.checkValidation(bodyData, details)
          .subscribe(data => {
              if (data) {
                  // this.errorWarningData = data.errorWarningData;
                  // this.errorWarningDataArray = data.errorWarningData;
                  if (data.callback && !isOnlyValidate) {
                      this.saveSpendMoneyDataSingleLine(details, form,bankIndex);
                  }
              }
          });
  }

  /**
   * This function is used to save the SpendMoney Data in single line.
   */
  saveSpendMoneyDataSingleLine(data: any, form: FormGroup,bankIndex):void {
      data.VALIDATEONLY = false;
      this._mainAPiServiceService.getSetData(data, "SetExpenditure").subscribe((response) => {
          if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                   this.toastr.success("Save successfully");
                   this.getDataForTable = this.getDataForTable.filter(data => {
                      return data.INDEX !== bankIndex;
                    });
                   

                 //  this.globallyCalculation(form, bankIndex)
             
              if (response.DATA.PDFFILENAME && response.DATA.PDFFILENAME != '') {
                  let confirmDilog = this.MatDialog.open(TrustMoneyReciptComponent, {
                      width: '100%', data: { action: 'Spend Money', forPDF: 'Yes', PDFURL: response.DATA.PDFFILENAME, reportTitle: 'Spend Money' }
                  });
                  confirmDilog.afterClosed().subscribe(result => {
                      if (result) { }
                      confirmDilog = null;
                  });
              }
              this.bankAccountsList().clear();
              this.bankFeedForm.reset();
              setTimeout(() => {
                  this.getList();
              }, 1500);
              this.isspiner = false;
              // (<FormGroup>form.controls['spendmoneyForm']).reset();

              // (<FormGroup>form.controls['spendmoneyForm']).controls['AmountTotal'].setValue('0.00');
                  
              // (<FormGroup>form.controls['spendmoneyForm']).controls['GstTotal'].setValue('0.00');

              // (<FormGroup>form.controls['spendmoneyForm']).controls['OutofBalance'].setValue('0.00');
              // this.dialogRef.close(true);
          } else if (response.CODE == 451 && response.STATUS == "warning") {
              this.isspiner = false;
              this.toastr.warning(response.MESSAGE);
          } else if (response.CODE == 450 && response.STATUS == "error") {
              this.isspiner = false;
              this.toastr.error(response.MESSAGE);
          } else if (response.MESSAGE == "Not logged in") {
              // this.dialogRef.close(false);
          } else {
              this.isspiner = false;
          }
      }, (error) => {
          this.isspiner = false;
          this.toastr.error(error);
      });
  }

  /**
   * This function is used to Save the ReceiptAfter ValidationForm.
   */
  SaveReceiptAfterVAlidationForm(form: FormGroup) {
      this.isLoadingResults = true;
      let AllocationDataInsert: any = {
          BANKFEEDTRANSACTIONGUID: form.controls['BANKFEEDTRANSACTIONGUID'].value,
          INCOMECLASS: (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMECLASS'].value,
          INCOMETYPE: "Other",
          // FIRMGUID: this.f.FIRMGUID.value,
          INCOMEDATE: form.controls['TRANSACTIONDATE'].value,
          PAYEE: (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].value,
          AMOUNT: Number(form.controls['AMOUNT'].value) - Number((<FormGroup>form.controls['generalReceiptForm']).controls['GST'].value),
          GST: (<FormGroup>form.controls['generalReceiptForm']).controls['GST'].value,
          BANKACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID,
          INCOMEACCOUNTGUID: (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUID'].value,
          NOTE: (<FormGroup>form.controls['generalReceiptForm']).controls['NOTE'].value,
      };
      let setReceiptPostData = {
          FormAction: "insert",
          VALIDATEONLY: true,
          Data: AllocationDataInsert,
      };
      this._mainAPiServiceService
          .getSetData(setReceiptPostData, "SetIncome")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                      this.checkValidationgenForm(response.DATA.VALIDATIONS, setReceiptPostData, form);
                      this.isLoadingResults = false;
                    } else if (response.CODE == 450 && response.STATUS == 'error') {
                      this.checkValidationgenForm(response.DATA.VALIDATIONS, setReceiptPostData, form);
                      this.isLoadingResults = false;
                    } else if (response.CODE == 451 && response.STATUS == 'warning') {
                      this.checkValidationgenForm(response.DATA.VALIDATIONS, setReceiptPostData, form);
                      this.isLoadingResults = false;
                    } else if (response.MESSAGE == 'Not logged in') {
                      this.isLoadingResults = false;
                      // this.dialogRef.close(false);
                    }
                  // if (
                  //     response.CODE == 200 &&
                  //     (response.STATUS == "OK" ||
                  //         response.STATUS == "success")
                  // ) {
                  //     this.toastr.success("Receipt save successfully");
                  //     this.isHide = false;
                  //     this.isGstReadonly = true;
                  //     (<FormGroup>form.controls['generalReceiptForm']).reset();
                  // }
                  // if (response.CODE == 451 && response.STATUS == "warning") {
                  //     this.toastr.warning(response.MESSAGE);
                  //     this.isHide = false;
                  //     this.isGstReadonly = true;
                  // }
                  // if (response.CODE == 450 && response.STATUS == "error") {
                  //     this.toastr.error(response.MESSAGE);
                  //     this.isHide = false;
                  //     this.isGstReadonly = true;
                  // }
              },
              (error) => {
                  this.toastr.error(error);
              }
          );
  }

  /**
   * This function is used to check the validation
   */
  checkValidationgenForm(bodyData: any, details: any, form: FormGroup) {
      let errorData: any = [];
      let warningData: any = [];
      let tempError: any = [];
      let tempWarning: any = [];
      bodyData.forEach(function (value) {
        if (value.VALUEVALID == 'No') {
          errorData.push(value.ERRORDESCRIPTION);
          tempError[value.FIELDNAME] = value;
        } else if (value.VALUEVALID == 'Warning') {
          if (value.FIELDNAME != "INCOMECODE")
            warningData.push(value.ERRORDESCRIPTION);
          tempWarning[value.FIELDNAME] = value;
        }
      });
      // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
      // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
  
      if (Object.keys(errorData).length != 0) {
        this.toastr.error(errorData);
        this.isspiner = false;
      } else if (Object.keys(warningData).length != 0) {
        this.isspiner = false;
        this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
          disableClose: true,
          width: '100%',
          data: warningData
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
          //   this.isspiner = true;
            this.SaveReceiptAfterVAlidationGenForm(details, form);
          }
          this.confirmDialogRef = null;
        });
      } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        this.SaveReceiptAfterVAlidationGenForm(details, form);
      }
    }

    /**
     * This function is used to Save Receipt After Validation GenForm
     */
    SaveReceiptAfterVAlidationGenForm(data: any, form: FormGroup) {
      data.VALIDATEONLY = false;
      this._mainAPiServiceService.getSetData(data, 'SetIncome').subscribe(response => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            this.toastr.success('Receipt save successfully');
          //   (<FormGroup>form.controls['generalReceiptForm']).reset();
          this.bankAccountsList().clear();
          this.bankFeedForm.reset();
            this.Contactname = [];
            this.Contactlist = [];
          //   this.MatterInputContact.nativeElement.value = "";
            setTimeout(() => {
              this.getList();
            }, 1500);
        } else if (response.STATUS == 'error') {
          this.toastr.error(response.MESSAGE);
          this.isspiner = false;
        } else if (response.STATUS == 'warning') {
          this.toastr.warning(response.MESSAGE);
          this.isspiner = false;
        } else if (response.MESSAGE == 'Not logged in') {
        }
      }, error => {
        this.isspiner = false;
        this.toastr.error(error);
      });
    }

    /**
     * This function is used to sort the Data
     */
  sortData(s):void {
      this.SortingbehaviorService.bankAccountSorting(s);
      this.setDefaultWidthRM(this.displayedColumns, 0);
  }

  /**
   * This function is used to sort SpentData.
   */
  sortSpentData(s) {
      this.SortingbehaviorService.bankAccountSpentSorting(s);
      this.setDefaultWidth(this.displayedColumns2, 0);
  }

  /**
   * This function is used to change the Pagination data value.
   */
  onPaginateChange(event):void {
      this.setDefaultWidthRM(this.displayedColumns,0);
      this.pageSize.bank_feed = event.pageSize;
      localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
  }

  /**
   * This function is used to onSpentPaginateChange data value.
   */
  onSpentPaginateChange(event) {
      this.setDefaultWidth(this.displayedColumns2,0);
      this.pageSizeSpent.bank_feed_spent = event.pageSize;
      localStorage.setItem(
          "lastPageSizeSpent",
          JSON.stringify(this.pageSizeSpent)
      );
  }

  /**
   * This functionin is used to add the Contact Form data value.
   */
  addContactForm(event: MatChipInputEvent, form: FormGroup): void {
      const value = (event.value || "").trim();
      this.Contactlist.forEach((element: any) => {
          if (element.CONTACTNAME == event.value) {
              // Add our fruit
              const array3 = this.Contactname;
              if (value) {
                  if (
                      this.Contactname.indexOf(
                          value.replace(/^\s+|\s+$/gm, "")
                      ) == -1
                  ) {
                      this.Contactname = [value];
                  }
              }
          }
      });
      if (event.input) {
          event.input.value = "";
      }
      // form.controls['PAYEE'].setValue(null);
      (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue(null);
  }

  /**
   * This function is used to remove the Contact
   */
  removeContact(fruit: any): void {
      const index = this.Contactname.indexOf(fruit);
      if (index >= 0) {
          this.Contactname.splice(index, 1);
          // this.contactForm.controls['COMPANYCONTACTGUID'].setValue('');
          //this.Deliverable = [];
      }
      let sortname = fruit.split("-")[0];
      // this.MatterInputContact.nativeElement.value = "";
      this.rmContactdata(sortname);
  }

  /**
   * This function is used to remove the Contact GR.
   */
  removeContactGR(form: FormGroup): void {
      this.Contactlist = [];
      (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue("");
  }

  /**
   * This function is used to rmContactdata
   */
  rmContactdata(sortname) {
      this.selectedContctData.forEach((element: any, index: any) => {
          if (
              element.CONTACTNAME.toString() ==
              sortname.replace(/^\s+|\s+$/gm, "")
          ) {
              this.selectedContctData.splice(index, 1);
          }
      });
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub1?.unsubscribe();
      this.sub4?.unsubscribe();
      this.subscription?.unsubscribe();
  }

  /**
   * this function is used to open the bank popup
   */
  BankPop():void {
      let AccountPopdata = {};
      AccountPopdata = { action: "Connect Bank Feed" };
      const dialogRef = this.MatDialog.open(ConnectBankFeedComponent, {
          disableClose: true,
          panelClass: "ChartAc-dialog",
          data: AccountPopdata,
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
  }

  /**
   * This function is used to CheckNum data value.
   */
  checkNum(value) {
      return Math.sign(value);
  }

  /* Convert any negative number to poisitive number */
  convertPos(amount) {
      return Math.abs(amount);
  }

  /**
   * This function is used to open MoreText data value.
   */
  openMoreText(i):void {
      this.selectedIndex = i;
  }

  /**
   * This function is used to BankingDialog open only for Payee Form.
   */
  BankingDialogOpenPayeeForm(type: any, clickType, evt: any, form: FormGroup) {
      if (evt && evt.pointerType == "") {
          return;
      }
      if (type == "") {
          const value = (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].value;
          // if (this.classtype == "Expense" || this.classtype == "Matter Expense" || this.classtype == "Depreciation") {
          //     type = "EXPENSE";
          // } else if (this.classtype == "Capital") {
          //     type = "ASSET";
          // } else if (this.classtype == "Pay GST") {
          //     type = "LIABILITY";
          // } else if (this.classtype == "Pay Tax") {
          //     type = "LIABILITY";
          // } else if (this.classtype == "Personal") {
          //     type = "EQUITY";
          // } else if (this.classtype == "Other") {
          //     type = "All";
          // }
          if (value == "Expense" || value == "Matter Expense" || value == "Depreciation") {
              type = "EXPENSE";
          } else if (value == "Capital") {
              type = "ASSET";
          } else if (value == "Pay GST") {
              type = "LIABILITY";
          } else if (value == "Pay Tax") {
              type = "LIABILITY";
          } else if (value == "Personal") {
              type = "EQUITY";
          } else if (value == "Other") {
              type = "All";
          }
      }
     
      // for by default bank account select not trust account
      // this.ChartHandlingData = { ClickType: "WithoutTrust", UseTrust: "No", PopUp: "", Lable: "Select Account", };
      // localStorage.setItem("ChartURL", JSON.stringify(this.ChartHandlingData));
      // this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);
      const dialogRef = this.MatDialog.open(BankingDialogComponent, { disableClose: true, width: "100%", data: { AccountType: type, FromWhere: "spendMonyExpense", RoterPath: 'bank-feed' }, });
      dialogRef.afterClosed().subscribe((result) => {

          localStorage.setItem('istrackid', 'SpandMoneyDialog');
          
          if (result) {
              if (clickType == "ExpenseClick") {
                  (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER);
                  (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue(result.ACCOUNTGUID);
                  // this.spendmoneyForm.controls["Expenseac"].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER);
                  // this.spendmoneyForm.controls["ExpenseacGUID"].setValue(result.ACCOUNTGUID);
              } else {
                  (<FormGroup>form.controls['spendmoneyForm']).controls['Bankac'].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER + " " + result.ACCOUNTNAME);
                  (<FormGroup>form.controls['spendmoneyForm']).controls['BankacGUID'].setValue(result.ACCOUNTGUID);
                  // this.spendmoneyForm.controls["Bankac"].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER + " " + result.ACCOUNTNAME);
                  // this.spendmoneyForm.controls["BankacGUID"].setValue(result.ACCOUNTGUID);
              }
          }
      });
  }

  onInputChange(event: any) {}

  /**
   * This function is used to get the AccountInfoForm
   */
  getAccountInfoForm(clickType, form: FormGroup, event: KeyboardEvent) {
      if (event.key === "Enter") {
          if(clickType == 'ExpenseClick'){
              this._mainAPiServiceService.getSetData({ AccountNumber: clickType == 'ExpenseClick' ? (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].value : (<FormGroup>form.controls['spendmoneyForm']).controls['Bankac'].value }, "GetAccount").pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                  if (result.DATA.ACCOUNTS[0]) {
                      if (result.DATA.ACCOUNTS[0].ACCOUNTNUMBER != '' && result.DATA.ACCOUNTS[0].ACCOUNTCLASS != '') {
                          if (clickType == "ExpenseClick") {
                              (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue(result.DATA.ACCOUNTS[0].ACCOUNTCLASS + " - " + result.DATA.ACCOUNTS[0].ACCOUNTNUMBER);
                              (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue(result.DATA.ACCOUNTS[0].ACCOUNTGUID);
                              // this.spendmoneyForm.controls["Expenseac"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTCLASS + " - " + result.DATA.ACCOUNTS[0].ACCOUNTNUMBER);
                              // this.spendmoneyForm.controls["ExpenseacGUID"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTGUID);
                          } else {
                              (<FormGroup>form.controls['spendmoneyForm']).controls['Bankac'].setValue(result.DATA.ACCOUNTS[0].ACCOUNTCLASS + " - " + result.DATA.ACCOUNTS[0].ACCOUNTNUMBER + " " + result.DATA.ACCOUNTS[0].ACCOUNTNAME);
                              (<FormGroup>form.controls['spendmoneyForm']).controls['BankacGUID'].setValue(result.DATA.ACCOUNTS[0].ACCOUNTGUID);
                              // this.spendmoneyForm.controls["Bankac"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTCLASS + " - " + result.DATA.ACCOUNTS[0].ACCOUNTNUMBER + " " + result.DATA.ACCOUNTS[0].ACCOUNTNAME);
                              // this.spendmoneyForm.controls["BankacGUID"].setValue(result.DATA.ACCOUNTS[0].ACCOUNTGUID);
                          }
                      } else {
                          if (clickType == "ExpenseClick") {
                              (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue('');
                              (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue('');
                          } else {
                              (<FormGroup>form.controls['spendmoneyForm']).controls['Bankac'].setValue('');
                              (<FormGroup>form.controls['spendmoneyForm']).controls['BankacGUID'].setValue('');
                          }
  
  
                      }
                  } else {
                      if (clickType == "ExpenseClick") {
                          (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue('');
                          (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue('');
                      } else {
                          (<FormGroup>form.controls['spendmoneyForm']).controls['Bankac'].setValue('');
                          (<FormGroup>form.controls['spendmoneyForm']).controls['BankacGUID'].setValue('');
                      }
                  }
  
              })
          }
      }
  }

  // @HostListener('document:click', ['$event'])
  //   documentClick(event: MouseEvent) {
  //     let result=this.MatterInputContact.nativeElement.value;
  //       if(result && result!=null && result != undefined && this.CallHostlistner==false){
  //         this.Contactdatashow(this.MatterInputContact.nativeElement);
  //         this.MatterInputContact.nativeElement.value='';
  //         this.Contactlist=[]
  //       }
  // }

  /**
   * This function is used to select the ContactForm Search data value.
   */
  selectContactFormSearch(event) {
      this.Contactlist = [];
      // let searchobj = { FastSearch:'True', Search: event }
      let payload = {
          ACTION: "GetData",
          FILTERS: {
              FASTSEARCH: 1,
              SEARCH: event,
          },
      };
      this._mainAPiServiceService
          .getSetData(payload, "contact")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((response) => {
              if (response.CODE == 200 && response.STATUS == "success") {
                  this.exportdatavalueOFContact = response.DATA;
                  this.searchContactData = response.DATA.RECORDS;
                  if (response.DATA.RECORDS.length == 0) {
                      this.Contactlist.push({ CONTACTNAME: event });
                  } else {
                      this.Contactlist = this.searchContactData;
                  }
              }
          });
  }

  /**
   * This function is used to select the Contact form
   */
  selectedContactForm(result: any, form: FormGroup) {
      // this.MatterInputContact.nativeElement.value = "";
      (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue("");
      if (Object.keys(result).length == 1) {
          this.toolbarServiceService.ContactsDialog(
              "new",
              result.CONTACTNAME
          );
          this.Contactname = [];
          this.Contactlist = [];
      } else {
          if (result) {
              // this.generalReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
              (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue(result.CONTACTNAME);
              this.Contactname = [result.CONTACTNAME];
          }
      }
  }

  /**
   * This function is used to Contact data show Form
   */
  ContactdatashowForm(event, form: FormGroup) {
      this.Contactname = [];
      this.CallHostlistner = true;
      if (
          this.exportdatavalueOFContact &&
          this.exportdatavalueOFContact.RECORDS &&
          this.exportdatavalueOFContact.RECORDS.length != 0
      ) {
          this.Contactlist = [];
          const result = this.exportdatavalueOFContact.RECORDS[0];
          this.Contactlist = [result.CONTACTNAME];
          //  form.controls['PAYEE'].setValue(null);
          (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue(null);
          if (event.input) {
              event.input.value = "";
          } else {
              // this.MatterInputContact.nativeElement.value = "";
              (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue("");
          }

          if (result) {
              // form.controls['PAYEE'].setValue(result.CONTACTNAME);
              (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue(result.CONTACTNAME);
              this.Contactname = [result.CONTACTNAME];
          }
      } else {
          this.toolbarServiceService.ContactsDialog(
              "new",
              this.MatterInputContact.nativeElement.value
          );
          this.Contactname = [];
          this.Contactlist = [];
          // this.MatterInputContact.nativeElement.value = "";
          (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue("");
      }
      this.CallHostlistner = false;
  }

  /**
   * This functionis used to select the Client Form
   */
  selectClientForm(form: FormGroup):void {
      this.searchContactData = [];
      this.Contactlist = this.searchContactData;
      const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
          width: "100%",
          disableClose: true,
          data: { type: "" },
      });
      dialogRef.afterClosed().subscribe((result) => {
          localStorage.setItem("istrackid", "ReceiptDilogComponent");
          if (result) {
              this.Contactname = [result.CONTACTNAME];
              (<FormGroup>form.controls['generalReceiptForm']).controls['PAYEE'].setValue(result.CONTACTNAME);
          }
      });
  }

  /**
   * This function is used to get Contact Data Using Behaviour
   */
  getContactDataUsingBehaviour() {
      this.behaviorService.newContactData$
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((result) => {
              this.Contactlist = [];
              if (result) {
                  // this.generalReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
                  this.Contactname = [result.CONTACTNAME];
              }
          });
  }

  /*
  This function is called when user click on Match button from receive money section to match the selected transactions of bank feed. 
 */
  saveReconcileForm(data: FormGroup) {
      if (this.SelectedWIPCheckBox.length > 0) {
          const Ids = this.SelectedWIPCheckBox.map((id) => {
              return id.INCOMEGUID;
          });
          let postData = {
              Action: "match",
              Data: {
                  BANKFEEDTRANSACTIONGUID: data.get('BANKFEEDTRANSACTIONGUID').value,
                  MATCHTYPE: "Income",
                  GUIDLIST: Ids,
              },
          };
          this._mainAPiServiceService
              .getSetData(postData, "bank-feed")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe((res) => {
                  if (res.STATUS == "success") {
                      // this.searchAmount.nativeElement.value = "";
                      // this.searchName.nativeElement.value = "";
                      this.toastr.success("Record Match Successfully")
                      data.controls['searchAmount'].setValue("");
                      data.controls['searchName'].setValue("");
                      this.receiveMoneydata = null;
                      this.receiveMoneySelecteddata = null;
                      this.totalAmount = 0;
                      this.finalAm(0, 0);
                      this.bankAccountsList().clear();
                      this.bankFeedForm.reset();
                      setTimeout(() => {
                          this.getList();
                      }, 2000);
                      this.panelOpenState++;
                  } else if (res.STATUS == "error") {
                      this.toastr.error(res.MESSAGE);
                  }
              });
      }
  }

  /*
  This function is called when user click on Match button from spend money section to match the selected transactions of bank feed. 
 */
  saveReconcileSMForm(data) {
      if (this.SelectedWIPCheckBoxSM.length > 0) {
          const Ids = this.SelectedWIPCheckBoxSM.map((id) => {
              return id.EXPENDITUREGUID;
          });
          let postData = {
              Action: "match",
              Data: {
                  BANKFEEDTRANSACTIONGUID: data.get('BANKFEEDTRANSACTIONGUID').value,
                  MATCHTYPE: "Expenditure",
                  GUIDLIST: Ids,
              },
          };
          this._mainAPiServiceService
              .getSetData(postData, "bank-feed")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe((res) => {
                  if (res.STATUS == "success") {
                      // this.searchAmount.nativeElement.value = "";
                      // this.searchName.nativeElement.value = "";
                      this.toastr.success("Record Match Successfully")
                      data.controls['searchAmount'].setValue("");
                      data.controls['searchName'].setValue("");
                      this.spentMoneydata = null;
                      this.spentMoneySelecteddata = null;
                      this.totalAmount = 0;
                      this.finalAm(0, 0);
                      this.bankAccountsList().clear();
                      this.bankFeedForm.reset();
                      setTimeout(() => {
                          this.getList();
                      }, 2000);
                      this.panelOpenState++;
                  } else if (res.STATUS == "error") {
                      this.toastr.error(res.MESSAGE);
                  }
              });
      }
  }

  /**
   * This function is used to change NT
   */
  changeNT(event):void {
      if (event.value == "1") {
          this.toolbarServiceService.spendmoneypopup("new");
      }
  }

  changeRNT(event) {
      if (event.value == "0") {
          this.toolbarServiceService.createReceipt("select_matter");
      }
      if (event.value == "1") {
          this.NewGeneralReceipt("new");
      }
  }

  /**
   * This function is used to get the NewGeneral Receipt.
   */
  NewGeneralReceipt(type: any): void {
      this.toolbarServiceService.SetOldistrackid("open");
      const dialogRef = this.MatDialog.open(GeneralReceiptDilogComponent, {
          width: "100%",
          disableClose: true,
          data: { type: type, Id: "", matterIs: null },
      });
      dialogRef.afterClosed().subscribe((result) => {
          this.toolbarServiceService.SetOldistrackid("close");
          if (result) {
              $("#refreshReceiceMoany").click();
              $("#refreshRecouncilItem").click();
              $("#refreshGeneral").click();
          }
      });
  }

  /* When user click on Send button of Discuss at that time call this function */
  sendCommentForm(form: FormGroup) {
      this.isLoadingResults = true;
      let postData = {
          Action: "comment",
          Data: {
              BANKFEEDTRANSACTIONGUID: form.controls['BANKFEEDTRANSACTIONGUID'].value,
              COMMENT: form.controls['COMMENT'].value,
          },
      };
      this._mainAPiServiceService
          .getSetData(postData, "bank-feed")
          .subscribe((res) => {
              if (res.STATUS == "success" && res.CODE == 200) {
                  this.isLoadingResults = false;
                  this.toastr.success('Comment has been updated Successfully')
                  // this.discussTextRef.nativeElement.value = "";
                  // form.get('COMMENT').setValue("");
              } else {
                  this.isLoadingResults = false;
              }
          });
  }

  /* function to set the active expansion panel state */
  setStep(index, i) {
      this.selectedIndex = i;
      this.panelOpenState = index;
  }

  /* function to close for last expansion panel when user click on cancel button */
  closePanel() {
      this.panelOpenState++;
  }

  /**
   * This function is used to get the Comman Enable form data.
   */
  forCommonEnableForm(form: FormGroup) {
      const value = (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].value;
      // this.classtype
      if (value == "Matter Expense") {
          // this.spendmoneyForm.controls["Matter"].enable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].enable();
      }
      (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].enable();
      (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].enable();
      (<FormGroup>form.controls['spendmoneyForm']).controls['Note'].enable();
      (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].enable();
      (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].enable();
      // this.spendmoneyForm.controls["Class"].enable();
      // this.spendmoneyForm.controls["GSTType"].enable();
      // this.spendmoneyForm.controls["Note"].enable();
      // this.spendmoneyForm.controls["AmountIncGST"].enable();
      // this.spendmoneyForm.controls["Expenseac"].enable();
  }

  /**
   * This function is used to select the valueRMF Form
   */
  selectedValueRMForm(form: FormGroup, event: MatAutocompleteSelectedEvent): void {
      // const array3 = this.matternameRM;
      // if (array3.length <1) {
      if (
          this.matternameRM.indexOf(
              event.option.viewValue.replace(/^\s+|\s+$/gm, "")
          ) == -1
      ) {
          // this.matternameRM.push(event.option.viewValue);
          this.matternameRM = [event.option.viewValue];
          //this.MatterInputRM.nativeElement.value = "";
          // this.PrepareReceiptForm.controls["Matter"].setValue(null);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue(null);
      }
      // }
  }

  /**
   * this function is used to select the matter.
   * @param event -value of the search matter.
   */
  public selectMatterFormSearchRM(event) {
      // let searchobj = { FastSearch:'True', Search: event }
      let searchobj = {
          Action: "GetData",
          Filters: { FastSearch: "True", SearchString: event },
      };
      this._mainAPiServiceService
          .getSetData(searchobj, "matter")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe((response) => {
              if (response.CODE == 200 && response.STATUS == "success") {
                  if(response.DATA.RECORDS.length > 0){
                      this.searchDataRM = response.DATA.RECORDS;
                      this.matterlistRM = this.searchDataRM;
                  }   
                  this.exportdatavalueRM = response.DATA;
              }
          });
  }

  /**
   * This function is used to select the current Matter with Matterguid.
   * @param form -value of the current form.
   * @param data -data of the current matter.
   */
  selectedmatterguid(form: FormGroup, data: any) {
      (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue(data.MATTERGUID);
      (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue(data.SHORTNAME);
      this.mattername = [data.SELECTEDDESCRIPTION]
      if (this.selectedDataSM.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedDataSM.length == 0) {
          this.selectedDataSM = [data];
      }

  }

  /**
   * this function is used to select the Matter Form data value.
   */
  public selectMatterForm(form: FormGroup):void {
      this.searchData = [];
      this.matterlist = this.searchData;

      const dialogRef = this.MatDialog.open(MatterDialogComponent, {
          width: "100%",
          disableClose: true,
          data: null,
      });
      dialogRef.afterClosed().subscribe((result) => {
          if (result) {
              localStorage.setItem("istrackid", "SpandMoneyDialog");
              (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue(result.SHORTNAME);
              (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue(result.MATTERGUID);
              // this.spendmoneyForm.controls["Matter"].setValue(
              //     result.SHORTNAME
              // );
              // this.spendmoneyForm.controls["MatterGUID"].setValue(
              //     result.MATTERGUID
              // );
              this.mattername = [result.SELECTEDDESCRIPTION];
          }
      });
  }

  /**
   * This function is used to get the matter after search.
   * @param event -value of the matter.
   */
  public selectMatterFormSearch(event) {
      // let searchobj = { FastSearch:'True', Search: event }
      let searchobj = {
          "Action": "GetData",
          "Filters": { FastSearch: 'True', SearchString: event }
      }
      this._mainAPiServiceService.getSetData(searchobj, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
          if (response.CODE == 200 && response.STATUS == "success") {
              this.exportdatavalue = response.DATA
              this.searchData = response.DATA.RECORDS;
              this.matterlist = this.searchData;
          }
      })

  }

  /**
   * get the gst type of the selected form
   * @param val -value of the gst
   * @param form -value of the current form
   */
  GstTypeforSelectform(val, form: FormGroup) {
      // this.GstTypeDiff = val;
      if (val == "Less Than 10% GST") {
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].enable();
          // this.spendmoneyForm.controls["GST1"].enable();
      } else if (val == "Less Than 10% GST(Charge GST)") {
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].enable();
          // this.spendmoneyForm.controls["GST1"].enable();
      } else if (val == "No GST(Charge GST)") {
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue(0.0);
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].setValue("No GST(Charge GST)");

          // this.spendmoneyForm.controls["GST1"].disable();
          // this.spendmoneyForm.controls["GST1"].setValue(0.0);
          // this.spendmoneyForm.controls["GSTType"].setValue(
          //     "No GST(Charge GST)"
          // );
      } else if (val == "No GST") {
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue(0.0);
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].setValue("No GST");

          // this.spendmoneyForm.controls["GST1"].disable();
          // this.spendmoneyForm.controls["GST1"].setValue(0.0);
          // this.spendmoneyForm.controls["GSTType"].setValue("No GST");
      } else if (val == "10% GST") {
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].enable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].setValue("10% GST");

          // this.spendmoneyForm.controls["GST1"].disable();
          // this.spendmoneyForm.controls["GST1"].setValue("10");
      }
      this.amountCalForm(form);
  }

  /**
   * This function is used to calculate the amount 
   * @param form -current form.
   */
  amountCalForm(form: FormGroup) {
      // let amValue: any = Math.abs(form.controls['AMOUNT'].value)
      let amValue: any = (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].value ? (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].value : Math.abs(form.controls['AMOUNT'].value)
      this.GstTypeDiff = (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].value;
      // let amount = this.f.AmountIncGST.value;
      // let amount = (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].value;
      let amount: any = amValue ? parseFloat(amValue).toFixed(2) : 0;
      let cal: any = (amount / 1.1).toFixed(2);
      if (this.GstTypeDiff == "No GST") {
          this.maxVal = "";
          this.GSTValForExGst = amount;
          (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue(this.GSTValForExGst);
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue(0.0);
          // this.spendmoneyForm.controls["GST1"].setValue(0.0);
      } else if (this.GstTypeDiff == "10% GST") {
          this.maxVal = "";
          this.GSTValForExGst = cal;
          (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue(this.GSTValForExGst);
          // this.GSTValAfterCal = (amount - cal).toFixed(2);
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue((amount - cal).toFixed(2));
      } else if (this.GstTypeDiff == "Less Than 10% GST") {
          this.GSTValAfterCal =
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value != 0.0 ? (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value : 0.0;
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue(this.GSTValAfterCal);
          this.GSTValForExGst = (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value != 0.0 ? cal : amount;
          (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue(this.GSTValForExGst);
          if ((<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value != 0.0) {
              if (amount - cal <= (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value) {
                  this.GSTValAfterCal = (amount - cal).toFixed(2);
                  (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue(this.GSTValAfterCal);
              } else {
                  this.GSTValForExGst = (amount - (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value).toFixed(
                      2
                  );
                  (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue(this.GSTValForExGst);
              }
              this.maxVal =
              (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value != 0.0 ? (amount - cal).toFixed(2) : "";
          }
      }
  }

  /**
   * This function is used to remove the short name into the data.
   * @param form -value of the form
   * @param event - get the current value 
   */
  datashowRMForm(form:FormGroup,event) {
      this.exportdatavalueRM.RECORDS.forEach((element, i) => {
          if (i == 0) {
              if (element) {
                  this.contactData = "";
                  this.matterData = element;
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue(element.SHORTNAME);
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['MatterGUID'].setValue(element.MATTERGUID);
                  this.matternameRM = [element.SELECTEDDESCRIPTION];
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue(this.matterData.FIRMGUID);
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue(this.matterData.CONTACTNAME);
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['SHOW'].setValue(1); 

                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].setValue("Show unpaid invoices for matter : " +
                  this.matterData.SHORTNAME); 
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue("Show unpaid invoices for client : " +
                  this.matterData.CONTACTNAME); 
                  (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop3'].setValue("Show all unpaid invoices");              
                  // this.ShowData.push({
                  //     id: 1,
                  //     text:
                  //         "Show unpaid invoices for matter : " +
                  //         this.matterData.SHORTNAME,
                  // });
                  // this.ShowData.push({
                  //     id: 2,
                  //     text:
                  //         "Show unpaid invoices for client : " +
                  //         this.matterData.CONTACTNAME,
                  // });
                  // this.ShowData.push({ id: 3, text: "Show all unpaid invoices" });
                  this.GetInvoiceForReceiptForm(form,{
                      MATTERGUID: this.matterData.MATTERGUID,
                      Outstanding: "Yes",
                  });
              }
          }
      });
  }

  /**
   * This function is used to remove the Matterguid data.
   * @param form -value of the current form.
   * @param fruit -name of the matter
   */
  removeRMForm(form: FormGroup, fruit: any) {
      const index = this.matternameRM.indexOf(fruit);
      if (index >= 0) {
          this.matternameRM.splice(index, 1);
          // this.PrepareReceiptForm.controls["Matter"].setValue("");
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['MatterGUID'].setValue("");
          this.PrepareReceiptData = null;
          this.ShowData = [];
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].setValue(0);
          // this.PrepareReceiptForm.controls["MatterGUID"].setValue("");
      }
      let sortname = fruit.split("-")[0];
      this.rmReceiveForm(sortname);
  }

  /**
   * This function is used to remove the Rcc client data.
   * @param form -current form data.
   */
  removeRCClient(form: FormGroup) {
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue("");
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['MatterGUID'].setValue("");
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue("");
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Clientmattertext'].setValue("");
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue("");
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].setValue(""); 
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue(""); 
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop3'].setValue("");

      this.PrepareReceiptData = null;
      this.ShowData = [];
      this.matternameRC = [];
      this.contactData = null;
      this.matterlistRM=[];
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].setValue(0);
      this.matterlistRC = [];
  }

  /**
   * This is used for remove the shortname into the form
   * @param sortname -name of the Matter
   */
  rmReceiveForm(sortname) {
      this.selectedDataRM.forEach((element: any, index: any) => {
          if (
              element.SHORTNAME.toString() ==
              sortname.replace(/^\s+|\s+$/gm, "")
          ) {
              this.selectedDataRM.splice(index, 1);
          }
      });
  }

  /**
   * This function is used to remove the matterguid in the form
   * @param form 
   * @param data 
   */
  selectedmatterguidRMForm(form: FormGroup, data: any) {
      this.ShowData = [];
      if (data) {
          this.contactData = "";
          this.matterData = data;
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['SHOW'].setValue(1); 
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue(data.SHORTNAME);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['MatterGUID'].setValue(data.MATTERGUID);
          this.matternameRM = [data.SELECTEDDESCRIPTION];
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUID'].setValue(this.matterData.FIRMGUID);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['FIRMGUIDTEXT'].setValue(this.matterData.CONTACTNAME); 
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].setValue("Show unpaid invoices for matter : " +
          this.matterData.SHORTNAME); 
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].setValue("Show unpaid invoices for client : " +
          this.matterData.CONTACTNAME); 
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop3'].setValue("Show all unpaid invoices"); 

          setTimeout(() => {
              (<FormGroup>form.controls['PrepareReceiptForm']).controls['SHOW'].setValue(1); 
          },2000);
          // this.ShowData.push({
          //     id: 1,
          //     text:
          //         "Show unpaid invoices for matter : " +
          //         this.matterData.SHORTNAME,
          // });
          // this.ShowData.push({
          //     id: 2,
          //     text:
          //         "Show unpaid invoices for client : " +
          //         this.matterData.CONTACTNAME,
          // });
          // this.ShowData.push({ id: 3, text: "Show all unpaid invoices" });
          this.GetInvoiceForReceiptForm(form,{
              MATTERGUID: this.matterData.MATTERGUID,
              Outstanding: "Yes",
          });
      }
  }

/**
* This function is used to remove the matter value.
* @param form -value of the current form 
* @param event -event value
*/
  addRMForm(form: FormGroup, event: MatChipInputEvent): void {
      const value = (event.value || "").trim();
      this.matterlistRM.forEach((element: any) => {
          if (element.SHORTNAME == event.value) {
              // Add our fruit
              const array3 = this.matternameRM;
              if (value) {
                  if (
                      this.matternameRM.indexOf(
                          value.replace(/^\s+|\s+$/gm, "")
                      ) == -1
                  ) {
                      this.matternameRM = [value];
                  }
              }
          }
      });

      // Clear the input value
      if (event.input) {
          event.input.value = "";
      }
      //  event.chipInput!.clear();

      // this.PrepareReceiptForm.controls["Matter"].setValue(null);
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['Matter'].setValue(null);
  }

  /* Function is called when user click on class field drop down from multi-line expense fields */

  ClasstypeForm(Classvalue, form: FormGroup) {
      this.classtype = Classvalue;
      // if (this._data.flag == 'true') {
      //     this.mattername = [this.matterdata.SELECTEDDESCRIPTION]
      // } else {
          //this.mattername = []
      // };


      if (Classvalue === "Expense") {
          this.defaultMatterAccountForm(Classvalue, form);
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          this.mattername = [];
          // if (this.action == "new") {
              (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
              (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
              // $("#mattersnew").addClass("menu-disabled");
              (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
              (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].enable()
              // this.spendmoneyForm.controls["Matter"].disable();
              // this.spendmoneyForm.controls["GSTType"].enable();
          // } else if (this.action != "new") {
          //     $("#mattersnew").addClass("menu-disabled");
          //     this.spendmoneyForm.controls["Matter"].disable();
          // }
          this.forCommonEnableForm(form);
          // this.GstTypeforSelect('1.1');
          // let tempError: any = this.errorWarningData.Error;
      } else if (Classvalue === "Matter Expense") {
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(false);
          (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
          // $("#mattersnew").removeClass("menu-disabled");
          this.defaultMatterAccountForm(Classvalue, form);
          // if (this._data.FromWhere == "FromWIP") {
          //     this.spendmoneyForm.controls["Matter"].setValue(this.CurrentMatter.SHORTNAME);
          //     this.spendmoneyForm.controls["MatterGUID"].setValue(this.CurrentMatter.MATTERGUID);
          // }

          if (this.currentUser && this.currentUser.POPUPSELECTMATTERFIRST) {
              this.selectMatterForm(form);
          }
          this.forCommonEnableForm(form);
      } else if (Classvalue === "Capital") {
          this.defaultMatterAccountForm(Classvalue, form);
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
          (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
          // $("#mattersnew").addClass("menu-disabled");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          // this.spendmoneyForm.controls["Matter"].disable();
          this.mattername = [];
          this.forCommonEnableForm(form);
          // this.GstTypeforSelect('1.1');
          // this.spendmoneyForm.controls['GSTType'].disable();
          // let tempError: any = this.errorWarningData.Error;
          // if (tempError != undefined) {
          //     delete tempError["SHORTNAME"];
          // }
      } else if (Classvalue === "Pay GST") {
          this.defaultMatterAccountForm(Classvalue, form);
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
          // $("#mattersnew").addClass("menu-disabled");
          this.mattername = [];
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].disable();

          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          // this.spendmoneyForm.controls["Matter"].disable();
          // this.spendmoneyForm.controls["GSTType"].disable();
          this.GstTypeforSelectform("No GST", form);
          // let tempError: any = this.errorWarningData.Error;
          // if (tempError != undefined) {
          //     delete tempError["SHORTNAME"];
          // }
      } else if (Classvalue === "Pay Tax") {
          this.defaultMatterAccountForm(Classvalue, form);
          this.mattername = [];
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
          (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
          // $("#mattersnew").addClass("menu-disabled");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].disable();

          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          // this.spendmoneyForm.controls["Matter"].disable();
          // this.spendmoneyForm.controls["GSTType"].disable();
          this.GstTypeforSelectform("No GST", form);
          // let tempError: any = this.errorWarningData.Error;
          // if (tempError != undefined) {
          //     delete tempError["SHORTNAME"];
          // }
      } else if (Classvalue === "Personal") {
          this.defaultMatterAccountForm(Classvalue, form);
          this.mattername = [];
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
          (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
          // $("#mattersnew").addClass("menu-disabled");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].disable();

          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          // this.spendmoneyForm.controls["Matter"].disable();
          // this.spendmoneyForm.controls["GSTType"].disable();
          this.GstTypeforSelectform("No GST", form);
          // let tempError: any = this.errorWarningData.Error;
          // if (tempError != undefined) {
          //     delete tempError["SHORTNAME"];
          // }
      } else if (Classvalue === "Depreciation") {
          this.defaultMatterAccountForm(Classvalue, form);
          this.mattername = [];
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
          (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
          // $("#mattersnew").addClass("menu-disabled");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].disable();

          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          // this.spendmoneyForm.controls["Matter"].disable();
          // this.spendmoneyForm.controls["GSTType"].disable();
          this.GstTypeforSelectform("No GST", form);
          // let tempError: any = this.errorWarningData.Error;
          // if (tempError != undefined) {
          //     delete tempError["SHORTNAME"];
          // }
      } else if (Classvalue === "Other") {
          this.defaultMatterAccountForm(Classvalue, form);
          this.mattername = [];
          (<FormGroup>form.controls['spendmoneyForm']).controls['hide'].setValue(true);
          (<FormGroup>form.controls['spendmoneyForm']).controls['expac'].setValue(false);
          // $("#mattersnew").addClass("menu-disabled");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['MatterGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].disable();

          // this.spendmoneyForm.controls["Matter"].setValue("");
          // this.spendmoneyForm.controls["MatterGUID"].setValue("");
          // this.spendmoneyForm.controls["Matter"].disable();
          // this.spendmoneyForm.controls["GSTType"].disable();
          this.GstTypeforSelectform("No GST", form);
          // let tempError: any = this.errorWarningData.Error;
          // if (tempError != undefined) {
          //     delete tempError["SHORTNAME"];
          // }
      }
  }

  /* Function is called when user change the tab */
  onTabChangedForm(form: FormGroup, event, i) {
      // element.SELECTEDINDEX = false;
      form.get('SELECTEDINDEX').setValue(true);
      form.get('SELECTEDSPENDINDEX').setValue(false);
      this.selectedIndex = i;
      const tabCount = 3;
      // this.flaxValue = 20;
    //   if(this.checkNum(form.get('AMOUNT').value) == -1 && event.index === 0){
          // this.defaultCallME();
          // this.recClassType = "";
          // this.gstType = 0;
    //   }
    //   if(this.checkNum(form.get('AMOUNT').value) == 1 && event.index === 0){
          // this.defaultGeneralReceipt(form);
          // this.recClassType = "";
          // this.gstType = 0;
    //   }
      // this.isHide = false;
      // this.isGstReadonly = true;
  }

  /**
   * This function is used to get the default RecMoneyDataForm
   */
  defaultRecMoneyDataForm(form: FormGroup, i) {
      this.selectedIndex = i;
      // element.SELECTEDINDEX = ;
      form.get('SELECTEDINDEX').setValue(!form.get('SELECTEDINDEX').value);
      form.get('SELECTEDSPENDINDEX').setValue(!form.get('SELECTEDSPENDINDEX').value);
      // this.PrepareReceiptForm.controls["AMOUNT"].setValue(element.AMOUNT);
      (<FormGroup>form.controls['PrepareReceiptForm']).controls['AMOUNT'].setValue(form.get('AMOUNT').value);
      this.defaultprepareReceiptForm(form);
  }

  // element.get('SELECTEDINDEX').setValue(false);

  /**
   * This function is used to set the default general receipt.
   * @param form -value of the form
   */
  defaultGeneralReceipt(form: FormGroup){
      this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({ FormAction: 'default', VALIDATEONLY: false, Data: {} }, 'SetIncome').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
      //   this.toolTipList = response.DATA.FIELDTIPS;
      (<FormGroup>form.controls['generalReceiptForm']).controls['BANKACCOUNTGUIDTEXT'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTNUMBER);
      (<FormGroup>form.controls['generalReceiptForm']).controls['BANKACCOUNTGUID'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTGUID);
      let DatePaid = response.DATA.DEFAULTVALUES.INCOMEDATE.split("/");
      let DATE = new Date(DatePaid[1] + '/' + DatePaid[0] + '/' + DatePaid[2]);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEDATE'].setValue(response.DATA.DEFAULTVALUES.INCOMEDATE);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEDATETEXT'].setValue(DATE);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMECLASS'].setValue(response.DATA.DEFAULTVALUES.INCOMECLASS);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMETYPE'].setValue(response.DATA.DEFAULTVALUES.INCOMETYPE);
      (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(1);
      this.classChangeForm(response.DATA.DEFAULTVALUES.INCOMECLASS, form);
      // this.CheckdefultValidation(response.DATA.VALIDATIONS);
        this.isLoadingResults = false;
      }
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to set the form values.
   * @param form -value of the form.
   */
  setFormValues(form: FormGroup){
      (<FormGroup>form.controls['generalReceiptForm']).controls['BANKACCOUNTGUIDTEXT'].setValue(this.defaultValuesRM.BANKACCOUNTNUMBER);
      (<FormGroup>form.controls['generalReceiptForm']).controls['BANKACCOUNTGUID'].setValue(this.defaultValuesRM.BANKACCOUNTGUID);
      let DatePaid = this.defaultValuesRM.INCOMEDATE.split("/");
      let DATE = new Date(DatePaid[1] + '/' + DatePaid[0] + '/' + DatePaid[2]);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEDATE'].setValue(this.defaultValuesRM.INCOMEDATE);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEDATETEXT'].setValue(DATE);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMECLASS'].setValue(this.defaultValuesRM.INCOMECLASS);
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMETYPE'].setValue(this.defaultValuesRM.INCOMETYPE);
      (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(1);
      this.classChangeForm(this.defaultValuesRM.INCOMECLASS, form);
  }

  /**
   * This function is used to set the gst value to the GST.
   * @param form 
   */
  gstValForm(form: FormGroup) {
      const value = (<FormGroup>form.controls['generalReceiptForm']).controls['GST'].value;
      const GST = parseFloat(value).toFixed(2);
      // this.generalReceiptForm.controls['GST'].setValue(GST);
      (<FormGroup>form.controls['generalReceiptForm']).controls['GST'].setValue(GST);
  }

  /**
   * This function is used to check the comon value
   * @param form -value of the form
   * @param group -value of the group
   * @param field -value of the field
   * @returns -
   */
  getCommonVal(form: FormGroup, group: any, field: any){
      const val = (<FormGroup>form.controls[group]).controls[field].value;
      return val;
  }
/**
* This function is used to change the gst type.
* @param value -value of the gst
* @param form -value of the form.
*/
  gstTypeChangeForm(value, form: FormGroup) {
      this.flaxValue = value == 2 ? 30 : 20;
      (<FormGroup>form.controls['generalReceiptForm']).controls['flaxValue'].setValue(this.flaxValue);
      this.isGstReadonly = value == 3 ? false : true;
      (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(this.isGstReadonly);
      if (value == 2) {
      //   this.generalReceiptForm.controls['GST'].setValue(0);
          (<FormGroup>form.controls['generalReceiptForm']).controls['GST'].setValue(0);
      }
      // this.amountVal(form);
  }

  /**
   * This function is used to calculate the amount value of the form
   * @param form 
   */
  amountVal(form: FormGroup) {
      // this.AMOUNT = (<FormGroup>form.controls['generalReceiptForm']).controls['AMOUNT'].value ? parseFloat((<FormGroup>form.controls['generalReceiptForm']).controls['AMOUNT'].value).toFixed(2) : 0;
      this.AMOUNT = form.controls['AMOUNT'].value ? parseFloat(form.controls['AMOUNT'].value).toFixed(2) : 0;
      let temV = this.AMOUNT - this.AMOUNT / 1.1;
      const GST = temV.toFixed(2);
      (<FormGroup>form.controls['generalReceiptForm']).controls['AMOUNT'].setValue(this.AMOUNT);
      if ((<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].value == 2) {
          (<FormGroup>form.controls['generalReceiptForm']).controls['GST'].setValue(0);
      } else {
          (<FormGroup>form.controls['generalReceiptForm']).controls['GST'].setValue(GST);
      }
    }

    /**
     * This function is used to change the class
     * @param value -value of the class
     * @param form -value of the form.
     */
  classChangeForm(value, form: FormGroup) {
       if (value == 'GST Refund') {
         this.defaultMatterAccountGeneralForm(value, form);
          (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(2);
         this.gstTypeChangeForm(2, form);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].setValue("GST A/C");
         (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].setValue("Liability");
         (<FormGroup>form.controls['generalReceiptForm']).controls['isHide'].setValue(true);
       } else if (value == 'Tax Refund') {
          this.defaultMatterAccountGeneralForm(value, form);
          (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(2);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isHide'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].setValue('Tax A/C');
         (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].setValue('Liability');
       } else if (value == 'Capital') {
          (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(1);
         this.gstTypeChangeForm(3, form);
         this.amountVal(form);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isHide'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(true);
         (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].setValue('Asset A/C');
         (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].setValue('ASSET');
       } else if (value == 'Personal') {
          (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(2);
         this.gstTypeChangeForm(2, form);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isHide'].setValue(true);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].setValue('Equity A/C');
         (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].setValue('Equity');
       } else if (value == 'Other') {
          (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(1);
         this.amountVal(form)
         this.gstTypeChangeForm(3, form);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isHide'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].setValue('Income A/C');
         (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].setValue('all');
       } else {
          (<FormGroup>form.controls['generalReceiptForm']).controls['gsttype'].setValue(1);
         this.amountVal(form)
         this.gstTypeChangeForm(3, form);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isHide'].setValue(false);
         (<FormGroup>form.controls['generalReceiptForm']).controls['isGstReadonly'].setValue(true);
         (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].setValue('Income A/C');
         (<FormGroup>form.controls['generalReceiptForm']).controls['classType'].setValue('INCOME');
       }
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUIDTEXT'].setValue('');
      (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUID'].setValue('');
     }

     /**
      * This function is used to check the value.
      * @param form -value of the form
      * @param field -name of the field
      * @returns 
      */
  isValueCheck(form: FormGroup, field){
      const val = (<FormGroup>form.controls['PrepareReceiptForm']).controls[field].value;
      return val;
  }

  /**
   * This function is used to remove the form value
   * @param form - current form data.
   * @returns -it's return the form value.
   */
  formValueRM(form: FormGroup){
      const val = (<FormGroup>form.controls['generalReceiptForm']).controls['classText'].value;
      return val;
  }

  /**
   * This function is used to set the default prepare receipt form
   * @param form -value of the form
   */
  defaultprepareReceiptForm(form: FormGroup) {
      this._mainAPiServiceService
          .getSetData(
              { FormAction: "default", VALIDATEONLY: false, Data: {} },
              "SetIncome"
          )
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  if (response.CODE == 200 && response.STATUS == "success") {
                      let temincome = response.DATA.DEFAULTVALUES.INCOMECODE;
                      // this.toolTipList = response.DATA.FIELDTIPS;
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMECODE'].setValue(temincome.toString().padStart(8, "0"));
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['BANKACCOUNTGUIDTEXT'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTNUMBER);
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['BANKACCOUNTGUID'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTGUID);
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMETYPE'].setValue(response.DATA.DEFAULTVALUES.INCOMETYPE);
                      let DatePaid =
                          response.DATA.DEFAULTVALUES.INCOMEDATE.split("/");
                      let DATE = new Date(
                          DatePaid[1] + "/" + DatePaid[0] + "/" + DatePaid[2]
                      );
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMEDATE'].setValue(response.DATA.DEFAULTVALUES.INCOMEDATE);
                      (<FormGroup>form.controls['PrepareReceiptForm']).controls['INCOMEDATETEXT'].setValue(DATE);
                      // this.PrepareReceiptForm.controls["INCOMECODE"].setValue(
                          
                      // );
                      // this.PrepareReceiptForm.controls[
                      //     "BANKACCOUNTGUIDTEXT"
                      // ].setValue(
                      //     response.DATA.DEFAULTVALUES.BANKACCOUNTNUMBER
                      // );
                      // this.PrepareReceiptForm.controls[
                      //     "BANKACCOUNTGUID"
                      // ].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTGUID);
                      // this.PrepareReceiptForm.controls["INCOMETYPE"].setValue(
                      //     response.DATA.DEFAULTVALUES.INCOMETYPE
                      // );
                      // let DatePaid =
                      //     response.DATA.DEFAULTVALUES.INCOMEDATE.split("/");
                      // let DATE = new Date(
                      //     DatePaid[1] + "/" + DatePaid[0] + "/" + DatePaid[2]
                      // );
                      // this.PrepareReceiptForm.controls["INCOMEDATE"].setValue(
                      //     response.DATA.DEFAULTVALUES.INCOMEDATE
                      // );
                      // this.PrepareReceiptForm.controls[
                      //     "INCOMEDATETEXT"
                      // ].setValue(DATE);
                      // this.CheckdefultValidation(response.DATA.VALIDATIONS);
                      this.isLoadingResults = false;
                  }
              },
              (error) => {
                  this.isLoadingResults = false;
                  this.toastr.error(error);
              }
          );
  }

  /**
   * This function is used to check the form value is updated or not.
   * @param form -form value.
   * @param val -current event of the mat-select
   */
  onChangeShowForm(form: FormGroup, val) {
      let data = {};
      if (val.value == 3) {
          // this.AllocationBtn = "clear"
          data = { Outstanding: "Yes" };
          this.GetInvoiceForReceiptForm(form,data);
      } else if ((<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].value && val.value == 1) {
          data = {
              MATTERGUID: this.matterData.MATTERGUID,
              Outstanding: "Yes",
          };
          this.GetInvoiceForReceiptForm(form,data);
      } else if ((<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].value && val.value == 2) {
          data = {
              CONTACTGUID: this.contactData
                  ? this.contactData.CONTACTGUID
                  : this.matterData.CONTACTGUID,
              Outstanding: "Yes",
          };
          this.GetInvoiceForReceiptForm(form,data);
      } else if (!(<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop1'].value && val.value == 1) {
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['SHOW'].setValue(1);
          this.PrepareReceiptData = new MatTableDataSource([]);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].setValue(this.PrepareReceiptData);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue('');
          const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
          dataSource.paginator = this.paginator;
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue('');
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['selectedInvoiceData'].setValue('');
          this.INDEX = -1;
          this.SelectedInvoiceIndex = this.INDEX;
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].setValue('');
      } else if (!(<FormGroup>form.controls['PrepareReceiptForm']).controls['Drop2'].value && val.value == 2) {
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['SHOW'].setValue(2);
          this.PrepareReceiptData = new MatTableDataSource([]);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].setValue(this.PrepareReceiptData);
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].setValue('');
          const dataSource = (<FormGroup>form.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value;
          dataSource.paginator = this.paginator;
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['currentInvoiceData'].setValue('');
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['selectedInvoiceData'].setValue('');
          this.INDEX = -1;
          this.SelectedInvoiceIndex = this.INDEX;
          (<FormGroup>form.controls['PrepareReceiptForm']).controls['allocatedSelected'].setValue('');
      }

      // if (val.value == 3) {
      //     // this.AllocationBtn = "clear"
      //     data = { Outstanding: "Yes" };
      // } else if (val.value == 1) {
      //     data = {
      //         MATTERGUID: this.matterData.MATTERGUID,
      //         Outstanding: "Yes",
      //     };
      // } else if (val.value == 2) {
      //     data = {
      //         CONTACTGUID: this.contactData
      //             ? this.contactData.CONTACTGUID
      //             : this.matterData.CONTACTGUID,
      //         Outstanding: "Yes",
      //     };
      // }
      // this.GetInvoiceForReceiptForm(form,data);
  }

  /* Set default value to multi-line expense fields when user click on create tab */
  defaultCallME() {
      // form: FormGroup
      this._mainAPiServiceService
          .getSetData(
              { FormAction: "default", VALIDATEONLY: true, DATA: {} },
              "SetExpenditure"
          )
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (res) => {
                  if (res.CODE == 200) {
                      this.isLoadingResults = false;
                      const spendmoneyData = res.DATA.DEFAULTVALUES;
                      this.SendMoney_data = res.DATA.DEFAULTVALUES;
                      // this.forAddshowpopupDataForm(form);
                  }
              },
              (error) => {
                  this.isLoadingResults = false;
                  this.toastr.error(error);
              }
          );
  }

  /* When user click on cancel button from multi-line expense fileds section */
  cancelME(element) {
      element.SELECTEDINDEX = !element.SELECTEDINDEX;
      element.SELECTEDSPENDINDEX = !element.SELECTEDSPENDINDEX;
  }

  /**
   * This function is used to cancle ME Form
   */
  cancelMEForm(form: FormGroup):void {
      // element.SELECTEDINDEX = !element.SELECTEDINDEX;
      form.get('SELECTEDINDEX').setValue(!form.get('SELECTEDINDEX').value);
      form.get('SELECTEDSPENDINDEX').setValue(!form.get('SELECTEDSPENDINDEX').value);
  }

  /**
   * This function is used to add the show popup Data Form
   */
  forAddshowpopupDataForm(form: FormGroup):void {
      // this.isItemSaveClicked = "no";
      this.getDataForTable = [];
      (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue("0.00");
      (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].setValue(0.0);
      (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue(0.0);
      (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].setValue("10% GST");
      // this.GstTypeDiff = "10% GST";
      (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].disable();
      (<FormGroup>form.controls['spendmoneyForm']).controls['GST'].setValue(0.0);
      (<FormGroup>form.controls['spendmoneyForm']).controls['Amount'].setValue(0.0);
      // this.FinalTotal = 0.0;
      // this.FinalTotalGST = 0.0;
      // this.GSTValForInGst = 0.0;
      this.ClasstypeForm("Expense", form);
      (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].setValue("Expense");
      this.amountCalForm(form);
      this.isLoadingResults = false;
  }

  /**
   * This function is used to get the class Type.
   */
  getClassType(form):void{
      const value = (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].value;
      return value;
  }

  /**
   * This function is used to get the default Matter Account Form 
   */
  defaultMatterAccountForm(value, form: FormGroup) {
      let userData = JSON.parse(localStorage.getItem("currentUser"));
      if (
          value == "Matter Expense" ||
          value == "Pay GST" ||
          value == "Pay Tax"
      ) {
          this.isLoadingResults = true;
          let accountGuid;
          if (value == "Matter Expense") {
              accountGuid = userData.DISBURSEMENTACCOUNTGUID;
              const ExpenseacGUID =
                  this.SendMoney_data &&
                  this.SendMoney_data.EXPENDITUREITEMS &&
                  this.SendMoney_data.EXPENDITUREITEMS[0]
                      ? this.SendMoney_data.EXPENDITUREITEMS[0]
                            .EXPENSEACCOUNTGUID
                      : null;
              if (!ExpenseacGUID) {
                  this.getAccountForm(accountGuid, form);
              } else {
                  this.isLoadingResults = false;
                  (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue(this.SendMoney_data.EXPENDITUREITEMS[0]
                      .EXPENSEACCOUNTNUMBER);
                  (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue(this.SendMoney_data.EXPENDITUREITEMS[0]
                      .EXPENSEACCOUNTGUID);
                  // this.spendmoneyForm.controls["Expenseac"].setValue(
                  //     this.SendMoney_data.EXPENDITUREITEMS[0]
                  //         .EXPENSEACCOUNTNUMBER
                  // );
                  // this.spendmoneyForm.controls["ExpenseacGUID"].setValue(
                  //     this.SendMoney_data.EXPENDITUREITEMS[0]
                  //         .EXPENSEACCOUNTGUID
                  // );
              }
          } else {
              accountGuid = userData.TAXPAYABLEACCOUNTGUID;
              this.getAccountForm(accountGuid, form);
          }
          // accountGuid = value == 'Matter Expense' ? userData.DISBURSEMENTACCOUNTGUID : userData.TAXPAYABLEACCOUNTGUID;
      } else {
          (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue("");
          // this.spendmoneyForm.controls["Expenseac"].setValue("");
          // this.spendmoneyForm.controls["ExpenseacGUID"].setValue("");
      }
  }

  /**
   * This function is used to get the default Matter Account General Form.
   */
  defaultMatterAccountGeneralForm(value, form: FormGroup) {
      let userData = JSON.parse(localStorage.getItem("currentUser"));
      if (value) {
        this.isLoadingResults = true;
        let accountGuid = value == 'Matter Expense' ? userData.DISBURSEMENTACCOUNTGUID : userData.TAXPAYABLEACCOUNTGUID;
        this._mainAPiServiceService.getSetData({ ACCOUNTGUID: accountGuid }, "GetAccount").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
          let x = response.DATA.ACCOUNTS[0];
          (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUIDTEXT'].setValue(x.ACCOUNTCLASS + " - " + x.ACCOUNTNUMBER);
          (<FormGroup>form.controls['generalReceiptForm']).controls['INCOMEACCOUNTGUID'].setValue(x.ACCOUNTGUID);
          // this.generalReceiptForm.controls["INCOMEACCOUNTGUIDTEXT"].setValue(x.ACCOUNTCLASS + " - " + x.ACCOUNTNUMBER);
          // this.generalReceiptForm.controls["INCOMEACCOUNTGUID"].setValue(x.ACCOUNTGUID);
          this.isLoadingResults = false;
        }, (err) => {
          console.log(err);
        });
      } else {
      //   this.generalReceiptForm.controls["BANKACCOUNTGUIDTEXT"].setValue('');
      //   this.generalReceiptForm.controls["BANKACCOUNTGUID"].setValue('');
      }
  
  }

  /**
   * This function is used to get the Account Form.
   */
  getAccountForm(accountGuid, form: FormGroup) {
      this._mainAPiServiceService
          .getSetData({ ACCOUNTGUID: accountGuid }, "GetAccount")
          .pipe(takeUntil(this._unsubscribeAll$))
          .subscribe(
              (response) => {
                  let x = response.DATA.ACCOUNTS[0];
                  // this.spendmoneyForm.controls["Expenseac"].setValue(
                  //     x.ACCOUNTCLASS + " - " + x.ACCOUNTNUMBER
                  // );
                  // this.spendmoneyForm.controls["ExpenseacGUID"].setValue(
                  //     x.ACCOUNTGUID
                  // );
                  (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue(x.ACCOUNTCLASS + " - " + x.ACCOUNTNUMBER);
                  (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue(x.ACCOUNTGUID);
                  this.isLoadingResults = false;
              },
              (err) => {
                  console.log(err);
              }
          );
  }

  /**
   * function is used to push the value in table row data
   * @param form -current form
   * @param flag -Flag for maintain the div open/close condition.
   * @param index -index of the table row
   * @returns -
   */
  SaveItemDialog(form: FormGroup,flag,index){
      (<FormGroup>form.controls['spendmoneyForm']).controls.value;        
      if (form.value.spendmoneyForm.Expenseac == undefined || form.value.spendmoneyForm.Expenseac == null || form.value.spendmoneyForm.Expenseac == '' || form.value.spendmoneyForm.AmountIncGST == 0 || form.value.spendmoneyForm.AmountIncGST == undefined) {
          this.saveSpendMoneyDataForm(form,index);
          return
      }
      if (Object.keys(this.getDataForTable[0]).length == 0) {
          this.getDataForTable.splice(0, 1);
      }

      this.getDataForTable.push({
          INDEX:index,
          EXPENDITURECLASS: form.value.spendmoneyForm.Class,
          AMOUNT: form.value.spendmoneyForm.AmountIncGST,
          GST: Number((<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value),
          EXPENDITUREGUID: "",
          EXPENSEACCOUNTGUID: form.value.spendmoneyForm.ExpenseacGUID,
          EXPENSEACCOUNT: form.value.spendmoneyForm.Expenseac,
          EXPENSEACCOUNTNUMBER: form.value.spendmoneyForm.Expenseac,
          MATTERGUID: form.value.spendmoneyForm.MatterGUID,
          SHORTNAME: form.value.spendmoneyForm.Matter,
          NOTE: form.value.spendmoneyForm.Note,
          DESCRIPTION: this.mattername[0] ? this.mattername[0] : []
      });
      this.globallyCalculation(form,index);
      setTimeout(() => {
          (<FormGroup>form.controls['spendmoneyForm']).controls['Note'].reset();
          (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].reset();
          (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].reset()
      },200);
      this.ExpandDivFlag=false
      form.get('MultiSpendTable').setValue(flag);
  }

  /**
   * This Function is used to Open the MultyLine Spend Money UI.
   * @param form -current form
   * @param i -index of the table row
   * @param flag -Flag for maintain the div open/close condition.
   */
  defaultSpenMoneyData(form:FormGroup,i:any,flag:boolean):void {
      this.selectedIndex = i;
     (<FormGroup>form.controls['spendmoneyForm']).controls['Type'].disable();
     (<FormGroup>form.controls['spendmoneyForm']).controls['ChequeNo'].disable();
     (<FormGroup>form.controls['spendmoneyForm']).controls['GstTotal'].disable();
     (<FormGroup>form.controls['spendmoneyForm']).controls['AmountTotal'].disable();
     (<FormGroup>form.controls['spendmoneyForm']).controls['OutofBalance'].disable();
      form.get('MultiSpend').setValue(flag);
  }

  /**
  * This function is used to close the currntly open form in the table.
  * @param form -current form
  * @param flag -Flag for maintain the div open/close condition.
  * @param index - index of the table row
  */
  deleteElement(form,flag,index):void {
      form.get('MultiSpendTable').setValue(flag);
      this.globallyCalculation(form,index);
  }

  /**
   * Using This Function we open the form inside the table and make ability to set the default value in this form
   * @param form -Value of the current form
   * @param i -Table index number
   * @param flag- Flag for maintain the div open/close condition. 
   */
  createSpendMoney(form:FormGroup,i,flag):void {
      this.disableAddButtonFlag = true;
      this.getDataForTable.unshift({
      });
      form.get('MultiSpendTable').setValue(flag);
      this.MainBankIndex=i;
      setTimeout(() => {
          this.highlightedRows = 0;
          this.lengthsize = 0
          this.mainIndex=i
      }, 200);
      setTimeout(() => {
          (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].setValue("Expense");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
          (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].setValue("0.00");
          (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue("0.00");
          (<FormGroup>form.controls['spendmoneyForm']).controls['GSTType'].setValue("10% GST");
          (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue("");
          (<FormGroup>form.controls['spendmoneyForm']).controls['Note'].setValue("");
      }, 200);
  }

  /**
   * Used to Expand the div
   * @param index -get the current index
   * @param flag -get the current flag value
   * @param form -et the current form data.
   * @param bindex -get the current bank index.
   */
  ExpandDiv(index,flag,form,bindex):void {
      form.get('MultiSpendTable').setValue(flag);
      this.MainBankIndex=bindex
      this.lengthsize=index
      this.globallyCalculation(form,bindex);
     
  }

  /**
   * This function is used to get the perticular row data.
   * @param data -perticular row data.
   * @param form -get the current row
   */
  editRowData(data,form):void {
       (<FormGroup>form.controls['spendmoneyForm']).controls['Note'].setValue(data.NOTE);
       (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue(data.EXPENSEACCOUNT);
       (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].setValue(data.AMOUNT);
       (<FormGroup>form.controls['spendmoneyForm']).controls['Class'].setValue(data.EXPENDITURECLASS);
       (<FormGroup>form.controls['spendmoneyForm']).controls['Matter'].disable();
       (<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].setValue(data.GST);

  }

  /**
   * Used to update the SpendMoney Row Record
   * @param form -get the updated Form
   * @param index -get the current index
   * @param flag -flag is used to hide the open div.
   * @returns -null
   */
  updateSpendRowData(form:FormGroup,index,flag,bankindex){
      if (form.value.spendmoneyForm.Expenseac == undefined || form.value.spendmoneyForm.Expenseac == null || form.value.spendmoneyForm.Expenseac == '' || form.value.spendmoneyForm.AmountIncGST == 0 || form.value.spendmoneyForm.AmountIncGST == undefined) {
          this.saveSpendMoneyDataForm(form,bankindex);
          return
      }
      this.getDataForTable[index].NOTE=(<FormGroup>form.controls['spendmoneyForm']).controls['Note'].value;
      this.getDataForTable[index].EXPENSEACCOUNT=(<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].value;
      this.getDataForTable[index].AMOUNT=(<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].value;
      this.getDataForTable[index].EXPENDITURECLASS=(<FormGroup>form.controls['spendmoneyForm']).controls['Class'].value;
      this.getDataForTable[index].GST=(<FormGroup>form.controls['spendmoneyForm']).controls['GST1'].value;
      this.getDataForTable[index].EXPENSEACCOUNTNUMBER=(<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].value;
      form.get('MultiSpendTable').setValue(flag);
      this.globallyCalculation(form,bankindex);
      this.toastr.success('Record has been updated Successfully')
  }

  /**
   * Used to delete the perticular multiple data Record
   * @param form -get the selected form
   * @param index -get the current row index
   * @param flag -flag to hide the div
   */
  deleteSpendRowData(form:FormGroup,index,flag,bindex):void {
      this.getDataForTable.splice(index,1);
      (<FormGroup>form.controls['spendmoneyForm']).controls['AmountExGST'].setValue("");
      (<FormGroup>form.controls['spendmoneyForm']).controls['AmountIncGST'].setValue("");
      (<FormGroup>form.controls['spendmoneyForm']).controls['Expenseac'].setValue("");
      (<FormGroup>form.controls['spendmoneyForm']).controls['ExpenseacGUID'].setValue("");
      (<FormGroup>form.controls['spendmoneyForm']).controls['Note'].setValue("");
      form.get('MultiSpendTable').setValue(flag);
      this.globallyCalculation(form,bindex);
      this.toastr.success('Record has been deleted Successfully')
  }

  /**
   * This Function is used to get the spend money multi data
   */
  async getMultispendData(form: FormGroup, bankIndex): Promise<any> {
      this.sendItem = [];

      await this.getDataForTable.forEach(async (element) => {
          await this.sendItem.push({
          INDEX: element.INDEX,
          EXPENDITURECLASS: element.EXPENDITURECLASS,
          EXPENDITUREGUID: "",
          EXPENDITUREITEMGUID: !element.EXPENDITUREITEMGUID ? "" : element.EXPENDITUREITEMGUID,
          EXPENSEACCOUNTGUID: element.EXPENSEACCOUNTGUID,
          GST: Number(element.GST),
          MATTERGUID: element.MATTERGUID,
          NOTE: element.NOTE,
          SHORTNAME: element.SHORTNAME,
          WORKITEMGUID: "",
          AMOUNTEXGST: Number(element.AMOUNT) - Number(element.GST),
          });
      });

      const MultiSpendSameId = this.sendItem.filter(data => data.INDEX == bankIndex);

      // Call globallyCalculation asynchronously
      await this.globallyCalculation(form, bankIndex);


      // Return MultiSpendSameId after globallyCalculation is completed
      return MultiSpendSameId;
  }

  /**
   * This function is used to clear the filter data value.
   */
  clearFilter(Filterdata: any):void {
      if (Filterdata == 'SearchString') {
          this.SearchForm.controls['search'].setValue('');
          if ((this.StartDate != null && this.StartDate != undefined && this.StartDate != '') && (this.EndDate != null && this.EndDate != undefined && this.EndDate != '')) {
              this.filterVal ={Action: "accounttransactions", Data:{  BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID, 'SearchString': '', DATEFROM: this.StartDate, DATETO: this.EndDate }}
          } else {
              this.filterVal = {Action: "accounttransactions", Data:{ BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID, 'SearchString': '', DATEFROM: '', DATETO: '' }}
          }

      }
      if (Filterdata == 'DateRange') {
              this.forHideShowDateRangePicker=false
              this.SearchForm.controls['date'].setValue('');
              this.SearchForm.controls['DayRange'].setValue('All time');
              this.activeFilters.dayRangeDesc='All time',
              this.StartDate = '',
              this.EndDate = '',
              this.Daterangedata = '',
              this.filterVal = {Action: "accounttransactions", Data:{ BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID, 'SearchString': (this.f.search.value) ? this.f.search.value : '', DATEFROM: this.StartDate, DATETO: this.EndDate }}
      }

      if(Filterdata == "dayRange"){
          this.SearchForm.controls['DayRange'].setValue('All time');
          this.activeFilters.dayRangeDesc='All time',
          this.StartDate = '',
          this.EndDate = '',
          this.Daterangedata = '',
          this.filterVal = {Action: "accounttransactions", Data:{ BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID, 'SearchString': (this.f.search.value) ? this.f.search.value : '', DATEFROM: this.StartDate, DATETO: this.EndDate }}
      }
      this.bankAccountsList().clear();
      this.bankFeedForm.reset();
      this.getList(this.filterVal);
  }

  get f() {
      return this.SearchForm.controls;
  };

  /**
   * 
   */
  onSearchFilter(searchFilter: any) {
      if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
          this.filterVal = {Action: "accounttransactions", Data:{ BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID, 'SearchString': this.f.search.value }}
          if ((this.StartDate != null && this.StartDate != undefined && this.StartDate != '') && (this.EndDate != null && this.EndDate != undefined && this.EndDate != '')) {
              this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM:this.StartDate, DATETO: this.EndDate }}
          } else {
              this.filterVal = {Action: "accounttransactions", Data: { BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, "DATEFROM": "", "DATETO": "" }}
          }
          this.bankAccountsList().clear();
          this.bankFeedForm.reset();
          this.getList(this.filterVal);
      }
  }


  /**
   * This function is used to set the Days Range
   */
  setDaysRange():void {
      this.dayRangeOptions = [
          {
              "value": "Today",
              "description": "Today"
          },
          // {
          //     "value": "Tomorrow",
          //     "description": "Tomorrow"
          // },
          // {
          //     "value": "Next 7 days",
          //     "description": "Next 7 days"
          // },
          // {
          //     "value": "Next 30 days",
          //     "description": "Next 30 days"
          // },
          // {
          //     "value": "Next 90 days",
          //     "description": "Next 90 days"
          // },
          // {
          //     "value": "Next 6 Months",
          //     "description": "Next 6 Months"
          // },
          {
              "value": "Yesterday",
              "description": "Yesterday"
          },
          {
              "value": "Last 7 days",
              "description": "Last 7 days"
          },
          {
              "value": "Last 30 days",
              "description": "Last 30 days"
          },
          {
              "value": "Last 90 days",
              "description": "Last 90 days"
          },
          {
              "value": "Last 6 months",
              "description": "Last 6 months"
          },
          {
              "value": "All time",
              "description": "All time"
          },
          {
              "value": "Date Range",
              "description": "Date Range"
          }
      ]
  }

    /**
   * Set Value for filter
   * @param activefilter
   * @param filterBy
   */
    Filterconfig(activefilter, filterBy) {
      if (filterBy == 'day_range') {
          const beginDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
          const endDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
          this.activeFilters.DateRange = `${beginDate} - ${endDate}`
          this.SearchForm.controls['date'].setValue({ begin: new Date(), end: new Date() });
          this.activeFilters.dayRangeDesc = activefilter.value;
      } else if (filterBy == 'date_range') {
          const beginDate = this.datepipe.transform(activefilter.value['begin'], 'dd/MM/yyyy');
          const endDate = this.datepipe.transform(activefilter.value['end'], 'dd/MM/yyyy');
          this.activeFilters.DateRange = `${beginDate} - ${endDate}`
      }

      //localStorage.setItem('matterAppointmentFilter_config', JSON.stringify(this.activeFilters));
  }


   /**
   * filter for day range
   * @param val
   * @returns
   */
   SelectDayRangeFilter(val) {
      this.StartDate=''
      this.EndDate=''
      let currentDate = new Date()
      let updatecurrentDate = new Date();
      let begin = this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      if (val == "Tomorrow") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() + 1);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: updatecurrentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      } else if (val == "Next 7 days") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() + 7);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: currentDate, end: updatecurrentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '', DATETO: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      } else if (val == "Next 30 days") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() + 30);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: currentDate, end: updatecurrentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '', DATETO: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      } else if (val == "Next 90 days") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() + 90);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: currentDate, end: updatecurrentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '', DATETO: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      } else if (val == "Next 6 Months") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() + 180);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: currentDate, end: updatecurrentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '', DATETO: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      } else if (val == "Last 7 days") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 7);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      } else if (val == "Today") {
          // updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: currentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      } else if (val == 'Yesterday') {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 1);
          begin = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: updatecurrentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      } else if (val == "Last 30 days") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      } else if (val == "Last 90 days") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      } else if (val == "Date Range") {
          this.forHideShowDateRangePicker = true;
          return;
      } else if (val == "Last 6 months") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 180);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      } else if (val == "Last 12 months") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 365);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');

      } else if (val == "Last 2 years") {
          updatecurrentDate.setDate(updatecurrentDate.getDate() - 730);
          this.forHideShowDateRangePicker = false;
          this.SearchForm.controls['date'].setValue({ begin: updatecurrentDate, end: currentDate });
          this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: updatecurrentDate ? this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy') : '', DATETO: currentDate ? this.datepipe.transform(currentDate, 'dd/MM/yyyy') : '' }}
          this.StartDate=this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
          this.EndDate=this.datepipe.transform(currentDate, 'dd/MM/yyyy');
      } else if (val == "All time") {
          this.forHideShowDateRangePicker = false;
      }

      this.bankAccountsList().clear();
      this.bankFeedForm.reset();
      this.getList( this.filterVal);
  


  }
  /**
   * select date range
   * @param type
   * @param value
   */
  DateRangeFilter(type: string, value: string) {
      const splitDateRange = value.split(" - ");
      const startDate = splitDateRange[0];
      const endDate = splitDateRange[1];
      this.activeFilters.ITEMDATESTART = startDate;
      this.activeFilters.ITEMDATEEND = endDate;
      this.StartDate=startDate;
      this.EndDate=endDate
      this.filterVal = {Action: "accounttransactions", Data: {BANKFEEDACCOUNTGUID: this.chartAccountDetail.BANKFEEDACCOUNTGUID,'SearchString': this.f.search.value, DATEFROM: this.StartDate, DATETO: this.EndDate }}
      this.getList( this.filterVal);
  }

  /**
   * This function is used to Submit the Form data.
   */
  preventFormSubmit(event: KeyboardEvent):void {
      // Prevent default form submit action on Enter key
      event.preventDefault();
  }

  /**
   * This function is used to Dismiss the Bank Feed data value.
   * @param form -form data.
   */
  DismissFeed(form: FormGroup){
      this.isspinerDF = true;
      // this.confirmDialogRef = this._matDialog.open(
      //     FuseConfirmDialogComponent,
      //     {
      //         disableClose: true,
      //         width: "100%",
      //     }
      // );
      // this.confirmDialogRef.componentInstance.confirmMessage =
      //     "<h1>Are you sure you want to dismiss this transaction?</h1>";
      // let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      //     data: {
      //         isBankFeed: true,
      //         message: "<b>Are you sure you want to dismiss this transaction?</b><br><b>(have the date, description amount here so they can see which one they're about to remove)</b><br><b>Unmatched transactions might lead to discrepancies in your records</b>",
      //     },
      // });

      // confirmDialogRef.componentInstance.confirmMessage = "";
      // confirmDialogRef.afterClosed().subscribe((result) => {
      //     if(result) {
              let postData = {
                  "Action": "DismissTransaction",
                  "ValidateOnly": true,
                  "Data": {
                      BANKFEEDTRANSACTIONGUID: form.get('BANKFEEDTRANSACTIONGUID').value
                  },
              };
              this._mainAPiServiceService
              .getSetData(postData, "bank-feed")
              .pipe(takeUntil(this._unsubscribeAll$))
              .subscribe((res) => {
                  if (res.CODE == 200 && res.STATUS == "success") {
                      this.isspinerDF = false;
                      this.checkValidation(res.DATA.VALIDATIONS, postData);
                  } else if (res.CODE == 451 && res.STATUS == 'warning') {
                      this.isspinerDF = false;
                      this.checkValidation(res.DATA.VALIDATIONS, postData);
                  } else if (res.CODE == 450 && res.STATUS == 'error') {
                      this.isspinerDF = false;
                      this.checkValidation(res.DATA.VALIDATIONS, postData);
                  }
              });
      //     }
      // });
  }

  /**
   * This function is used to check the validation.
   */
  checkValidation(bodyData: any, postData: any) {
      let errorData: any = [];
      let warningData: any = [];
      let tempError: any = [];
      let tempWarning: any = [];
      bodyData.forEach(function (value) {
        if (value.VALUEVALID == 'No') {
          errorData.push(value.ERRORDESCRIPTION);
          tempError[value.FIELDNAME] = value;
        } else if (value.VALUEVALID == 'Warning') {
          tempWarning[value.FIELDNAME] = value;
          warningData.push(value.ERRORDESCRIPTION);
        }
      });
      // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
      if (Object.keys(errorData).length != 0) {
        this.toastr.error(errorData);
        this.isspinerDF = false;
      } else if (Object.keys(warningData).length != 0) {
        this.isspinerDF = false;
        this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
          disableClose: true, width: '100%', data: warningData
        });
        this.confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.isspinerDF = true;
            this.DismissBankFeed(postData);
          }
          this.confirmDialogRef = null;
        });
      } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
          this.isspinerDF = true;
          this.DismissBankFeed(postData);
      }
    }

/**
 * This function is used to Dismiss the Bank feed data value.
 */
    DismissBankFeed(postData):void{
      postData.ValidateOnly = false;
      this.sub4 = this._mainAPiServiceService.getSetData(postData, 'bank-feed').subscribe(res => {
          if (res.CODE == 200 && res.STATUS == "success") {
              this.toastr.success("Record Dismiss Successfully")
              this.bankAccountsList().clear();
              this.bankFeedForm.reset();
              setTimeout(() => {
                  this.getList();
              }, 2000);
              this.isspinerDF = false;
          } else if (res.CODE == 451 && res.STATUS == 'warning') {
              this.toastr.warning(res.STATUS);
              this.isspinerDF = false;
          } else if (res.CODE == 450 && res.STATUS == 'error') {
              this.toastr.error(res.STATUS);
              this.isspinerDF = false;
          }
          this.isspinerDF = false;
      }, err => {
          this.isspinerDF = false;
          this.toastr.error(err);
      });
    }

}
