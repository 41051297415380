import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatSort } from '@angular/material/sort';
import { ResizeEvent } from 'angular-resizable-element';
import { MatTableDataSource } from '@angular/material/table';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';

@Component({
  selector: 'app-browse-folder-popup',
  templateUrl: './browse-folder-popup.component.html',
  styleUrls: ['./browse-folder-popup.component.scss'],
  animations: fuseAnimations
})
export class BrowseFolderPopupComponent implements OnInit, OnDestroy {
  currentMatterData: any;
  parentMessage: any;
  pageSize: any;
  sortactive: any;
  sortDirection: any;
  displayedColumns: any = ['TEMPLATETYPE', 'FOLDERNAME', 'FULLPATH'];
  highlightedRows: any;
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean = false;
  folderData: any = [];
  storeFolder: any;
  previousFolder: any;
  goBack: any;
  pushFolder: any = [];
  previousFolder_2: any;
  storeFolder_2: any;
  SendgoBack: any;
  storeGoBack: any;
  sub: Subscription;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    private behaviorService: BehaviorService,
    public tooltipService : TooltipService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.storeFolder = '';
    this.previousFolder = '';
    this.behaviorService.folderSubFolder(this.storeFolder);
    this.LoadFolder({ 'MATTERGUID': this._data.MATTERGUID, FOLDERNAME: '' });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  LoadFolder(data: any) {
    this.isLoadingResults = true;
    // mattersData.MATTERGUID
    let Data=data;
    this.sub = this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').subscribe(res => {
      let tempFolder = [];
      if (res.CODE == 200 && res.STATUS == "success") {
        res.DATA.FOLDERS.forEach(element => {
          element.TEMPLATETYPE = 'Folder';
          tempFolder.push(element);
        });
        if (this.pushFolder.length > 0) {
          tempFolder.unshift({ FOLDERNAME: `.. Go up one level`, FULLPATH: '', TEMPLATETYPE: "Go_Back" });
        }
        // this.folderData
        this.folderData = new MatTableDataSource(tempFolder);
        this.folderData.paginator = this.paginator;
        this.folderData.sort = this.sort;
        if (res.DATA.FOLDERS[0]) {
          this.clickRow(res.DATA.FOLDERS[0]);
          this.highlightedRows = res.DATA.FOLDERS[0].FOLDERNAME;
        }
        this.isLoadingResults = false;
      }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }
  doubleClick(data) {
    if (data.TEMPLATETYPE == "Folder") {
      this.pushFolder.push(data.FULLPATH);
      for (let index = 0; index <= this.pushFolder.length - 1; index++) {
        this.storeFolder_2 = this.pushFolder[index];
        this.previousFolder_2 = this.pushFolder[index];
      }
      setTimeout(() => {
        this.LoadFolder({ 'MATTERGUID': this._data.MATTERGUID, FOLDERNAME: this.storeFolder_2 });
        localStorage.setItem("Folder", this.storeFolder_2);
        this.behaviorService.folderSubFolder(localStorage.getItem("Folder"));
        this.storeFolder_2 = '';
        this.previousFolder_2 = '';
      }, 500);

    } else if (data.TEMPLATETYPE == "Go_Back") {
      this.pushFolder.pop(data.FULLPATH);
      for (let index = 0; index <= this.pushFolder.length - 1; index++) {
        this.SendgoBack = this.pushFolder[index];
        this.storeGoBack = this.pushFolder[index];
      }
      setTimeout(() => {
        this.LoadFolder({ 'MATTERGUID': this._data.MATTERGUID, FOLDERNAME: this.SendgoBack });
        localStorage.setItem("Folder", this.SendgoBack);
        this.behaviorService.folderSubFolder(localStorage.getItem("Folder"))
        this.SendgoBack = '';
        this.storeGoBack = '';
      }, 500);
    }
  }
  onResizeEnd(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
    }
  }
  clickRow(row: any) {
    this.parentMessage = row;
    this.currentMatterData = row;
  }
  onPaginateChange(event) {
    this.pageSize.folder_page = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }
}