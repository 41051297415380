import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-matter-conveyancing-expandation',
    templateUrl: './matter-conveyancing-expandation.component.html',
    styleUrls: ['./matter-conveyancing-expandation.component.scss']
})
export class MatterConveyancingExpandationComponent implements OnInit, OnDestroy {
    MatterConveyancingDetailsForm: FormGroup;
    APIDataSubcription: Subscription
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() classType: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
    public tooltipService : TooltipService
    ) {
        this.initFormData();
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
    }

    ngOnInit() {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MatterConveyancingDetailsForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                // classType 29 & 30 for business purchase & business Sale.
                if ((result.classType == "Property Purchase" || result.classType == "Property Sale" || result.classType == "Business Sale" || result.classType == "Business Purchase" || result.classType == "Maritime") && result.action == 'setData') {
                    this.SaveMatterConveyancing();
                } else if ((result.classType == "Property Purchase4" || result.classType == "Property Sale" || result.classType == "Business Sale" || result.classType == "Business Purchase" || result.classType == "Maritime") && result.action == 'deleteData') {
                    this.DeleteMatterConveyancing();
                }
            }
        });

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data) {
                this.mapMatterConveyancingFields();
            }
        })

        if (this.action == "edit") {
            this.mapMatterConveyancingFields()
        }


    }

    /**
     * This function is used to get the mapMatter fields
     */
    mapMatterConveyancingFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {

            this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-conveyancing').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterConveyancingDetailsData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterConveyancingDetailsData) {
                        this.MatterConveyancingDetailsForm.patchValue({
                            MATTERGUID: MatterConveyancingDetailsData.MATTERGUID,
                            MATTERCONVEYANCINGGUID: MatterConveyancingDetailsData.MATTERCONVEYANCINGGUID,
                            EXCHANGEDATE: MatterConveyancingDetailsData.EXCHANGEDATE, //date
                            ADJUSTMENTDATE: MatterConveyancingDetailsData.ADJUSTMENTDATE,// date
                            SETTLEMENTDATE: MatterConveyancingDetailsData.SETTLEMENTDATE, //date
                            CLIENTSTATUS: MatterConveyancingDetailsData.CLIENTSTATUS,
                            SETTLEMENTTIME: MatterConveyancingDetailsData.SETTLEMENTTIME,
                            POOLINSPECTIONDATE: MatterConveyancingDetailsData.POOLINSPECTIONDATE,//date
                            PESTINSPECTIONDATE: MatterConveyancingDetailsData.PESTINSPECTIONDATE,//date
                            SETTLEMENTLOCATION: MatterConveyancingDetailsData.SETTLEMENTLOCATION,


                            //Price Aand  Deposite
                            PURCHASEPRICE: MatterConveyancingDetailsData.PURCHASEPRICE,
                            DEPOSITTYPE: MatterConveyancingDetailsData.DEPOSITTYPE,
                            INITIALDEPOSIT: MatterConveyancingDetailsData.INITIALDEPOSIT,
                            BALANCEDEPOSIT: MatterConveyancingDetailsData.BALANCEDEPOSIT,
                            BALANCEDEPOSITDATE: MatterConveyancingDetailsData.BALANCEDEPOSITDATE, //Date
                            DEPOSITBONDAMOUNT: MatterConveyancingDetailsData.DEPOSITBONDAMOUNT,
                            DEPOSITBONDEXPIRYDATE: MatterConveyancingDetailsData.DEPOSITBONDEXPIRYDATE, //Date
                            DEPOSITAMOUNT: MatterConveyancingDetailsData.DEPOSITAMOUNT,
                            GST: MatterConveyancingDetailsData.GST,
                            OTHERCONSIDERATION: MatterConveyancingDetailsData.OTHERCONSIDERATION,

                            // Contract

                            COOLINGOFFDATE: MatterConveyancingDetailsData.COOLINGOFFDATE, //Date
                            CONTRACTLENGTHVALUE: MatterConveyancingDetailsData.CONTRACTLENGTHVALUE,
                            CONTRACTLENGTHPERIODTYPE: MatterConveyancingDetailsData.CONTRACTLENGTHPERIODTYPE,
                            CONTRACTCOMPLETIONDATE: MatterConveyancingDetailsData.CONTRACTCOMPLETIONDATE,	//Date
                            CONTRACTCOMPLETIONOTHERDATE: MatterConveyancingDetailsData.CONTRACTCOMPLETIONOTHERDATE,	//Date
                            AUCTIONDATE: MatterConveyancingDetailsData.AUCTIONDATE, //Date
                            FINALDATEFORNOTICETOCOMPLETE: MatterConveyancingDetailsData.FINALDATEFORNOTICETOCOMPLETE,	//Date
                            TRANSFERFEESEXCLUDED: MatterConveyancingDetailsData.TRANSFERFEESEXCLUDED,
                            DUPLICATEDOCUMENTS: MatterConveyancingDetailsData.DUPLICATEDOCUMENTS,
                            DUPLICATETRANSFERS: MatterConveyancingDetailsData.DUPLICATETRANSFERS,
                            ENOSID: MatterConveyancingDetailsData.ENOSID,

                            // Concessions and Duty

                            PROPERTYTYPE: MatterConveyancingDetailsData.PROPERTYTYPE,
                            ELIGIBLEFORSTAMPDUTYREDUCTION: MatterConveyancingDetailsData.ELIGIBLEFORSTAMPDUTYREDUCTION,
                            FIRSTHOMEOWNERSGRANT: MatterConveyancingDetailsData.FIRSTHOMEOWNERSGRANT,
                            NEWHOMEGRANT: MatterConveyancingDetailsData.NEWHOMEGRANT,
                            ELIGIBLEPURCHASERPERCENTAGE: MatterConveyancingDetailsData.ELIGIBLEPURCHASERPERCENTAGE,
                            EXEMPTFROMPREMIUMPROPERTYDUTY: MatterConveyancingDetailsData.EXEMPTFROMPREMIUMPROPERTYDUTY,
                            FOREIGNPURCHASER: MatterConveyancingDetailsData.FOREIGNPURCHASER,
                            DUTIBLEAMOUNT: MatterConveyancingDetailsData.DUTIBLEAMOUNT,
                            ADDITIONALDUTY: MatterConveyancingDetailsData.ADDITIONALDUTY,
                            LODGEMENTFEE: MatterConveyancingDetailsData.LODGEMENTFEE,
                            ADVALOREMFEE: MatterConveyancingDetailsData.ADVALOREMFEE,
                            TOTALREGISTRATIONFEES: MatterConveyancingDetailsData.TOTALREGISTRATIONFEES,
                            STAMPDUTYAMOUNT: MatterConveyancingDetailsData.STAMPDUTYAMOUNT,
                            STAMPDUTYDUEDATE: MatterConveyancingDetailsData.STAMPDUTYDUEDATE, //	Date
                            STAMPDUTYPAIDDATE: MatterConveyancingDetailsData.STAMPDUTYPAIDDATE,//	Date
                            OSRRECEIPTNO: MatterConveyancingDetailsData.OSRRECEIPTNO,

                            // Settlement
                            FINANCEDATE: MatterConveyancingDetailsData.FINANCEDATE, //date
                            BANKREFERENCE: MatterConveyancingDetailsData.BANKREFERENCE,
                            BUILDINGREPORTCOMPLETED: MatterConveyancingDetailsData.BUILDINGREPORTCOMPLETED,
                            PESTREPORTCOMPLETED: MatterConveyancingDetailsData.PESTREPORTCOMPLETED,
                            SPECIALCONDITIONS: MatterConveyancingDetailsData.SPECIALCONDITIONS,
                            PAYABLEBYPURCHASER: MatterConveyancingDetailsData.PAYABLEBYPURCHASER,
                            PAYABLEBYVENDOR: MatterConveyancingDetailsData.PAYABLEBYVENDOR,
                            TOTALDUE: MatterConveyancingDetailsData.TOTALDUE,
                            CHEQUESDUE: MatterConveyancingDetailsData.CHEQUESDUE,
                            UNPAIDAMOUNT: MatterConveyancingDetailsData.UNPAIDAMOUNT,
                            VENDORPAYS: MatterConveyancingDetailsData.VENDORPAYS,
                            DATEPAID: MatterConveyancingDetailsData.DATEPAID,	//Date
                        });
                        let BALANCEVAL = MatterConveyancingDetailsData.PURCHASEPRICE - (MatterConveyancingDetailsData.INITIALDEPOSIT + MatterConveyancingDetailsData.BALANCEDEPOSIT);
                        this.MatterConveyancingDetailsForm.controls['BALANCEVAL'].setValue(BALANCEVAL);
                        if (MatterConveyancingDetailsData.BALANCEDEPOSITDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.BALANCEDEPOSITDATE, 'BALANCEDEPOSITDATE');
                        }
                        if (MatterConveyancingDetailsData.ADJUSTMENTDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.ADJUSTMENTDATE, 'ADJUSTMENTDATE');
                        }
                        if (MatterConveyancingDetailsData.SETTLEMENTDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.SETTLEMENTDATE, 'SETTLEMENTDATE');
                        }
                        if (MatterConveyancingDetailsData.EXCHANGEDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.EXCHANGEDATE, 'EXCHANGEDATE');
                        }
                        if (MatterConveyancingDetailsData.DEPOSITBONDEXPIRYDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.DEPOSITBONDEXPIRYDATE, 'DEPOSITBONDEXPIRYDATE');
                        }
                        if (MatterConveyancingDetailsData.COOLINGOFFDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.COOLINGOFFDATE, 'COOLINGOFFDATE');
                        }
                        if (MatterConveyancingDetailsData.CONTRACTCOMPLETIONDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.CONTRACTCOMPLETIONDATE, 'CONTRACTCOMPLETIONDATE');
                        }
                        if (MatterConveyancingDetailsData.CONTRACTCOMPLETIONOTHERDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.CONTRACTCOMPLETIONOTHERDATE, 'CONTRACTCOMPLETIONOTHERDATE');
                        }
                        if (MatterConveyancingDetailsData.AUCTIONDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.AUCTIONDATE, 'AUCTIONDATE');
                        }
                        if (MatterConveyancingDetailsData.FINALDATEFORNOTICETOCOMPLETE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.FINALDATEFORNOTICETOCOMPLETE, 'FINALDATEFORNOTICETOCOMPLETE');
                        }
                        if (MatterConveyancingDetailsData.STAMPDUTYDUEDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.STAMPDUTYDUEDATE, 'STAMPDUTYDUEDATE');
                        }
                        if (MatterConveyancingDetailsData.STAMPDUTYPAIDDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.STAMPDUTYPAIDDATE, 'STAMPDUTYPAIDDATE');
                        }
                        if (MatterConveyancingDetailsData.FINANCEDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.FINANCEDATE, 'FINANCEDATE');
                        }
                        if (MatterConveyancingDetailsData.DATEPAID) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.DATEPAID, 'DATEPAID');
                        }

                        if (MatterConveyancingDetailsData.POOLINSPECTIONDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.POOLINSPECTIONDATE, 'POOLINSPECTION');
                        }

                        if (MatterConveyancingDetailsData.PESTINSPECTIONDATE) {
                            this.CommanDateSetter(MatterConveyancingDetailsData.PESTINSPECTIONDATE, 'PESTINSPECTION');
                        }
                    }
                }
            });
        }
    }

    initFormData() {
        this.MatterConveyancingDetailsForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCONVEYANCINGGUID: [''],
            EXCHANGEDATE: [''], //date
            EXCHANGEDATETEXT: [''],
            ADJUSTMENTDATE: [''],// date
            ADJUSTMENTDATETEXT: [''],
            SETTLEMENTDATE: [''],
            SETTLEMENTDATETEXT: [''], //date
            CLIENTSTATUS: [''],

            // matter purchase
            SETTLEMENTTIME: [''],
            POOLINSPECTIONDATE: [''],
            POOLINSPECTIONTEXT: [''],
            PESTINSPECTIONDATE: [''],
            PESTINSPECTIONTEXT: [''],
            SETTLEMENTLOCATION: [''],

            //Price Aand  Deposite
            PURCHASEPRICE: [0, {updateOn: 'blur'}],
            DEPOSITTYPE: [''],
            INITIALDEPOSIT: [0, {updateOn: 'blur'}],
            BALANCEDEPOSIT: [0, {updateOn: 'blur'}],
            BALANCEDEPOSITDATE: [''], //Date
            BALANCEDEPOSITDATETEXT: [''],
            DEPOSITBONDAMOUNT: ['', {updateOn: 'blur'}],
            DEPOSITBONDEXPIRYDATE: [''], //Date
            DEPOSITBONDEXPIRYDATETEXT: [''],
            DEPOSITAMOUNT: ['', {updateOn: 'blur'}],
            GST: ['', {updateOn: 'blur'}],
            OTHERCONSIDERATION: ['', {updateOn: 'blur'}],
            BALANCEVAL: ['', {updateOn: 'blur'}],
            // Contract

            COOLINGOFFDATE: [''], //Date
            COOLINGOFFDATETEXT: [''],
            CONTRACTLENGTHVALUE: [''],
            CONTRACTLENGTHPERIODTYPE: [''],
            CONTRACTCOMPLETIONDATE: [''],	//Date
            CONTRACTCOMPLETIONDATETEXT: [''],
            CONTRACTCOMPLETIONOTHERDATE: [''],	//Date
            CONTRACTCOMPLETIONOTHERDATETEXT: [''],
            AUCTIONDATE: [''], //Date
            AUCTIONDATETEXT: [''],
            FINALDATEFORNOTICETOCOMPLETE: [''],	//Date
            FINALDATEFORNOTICETOCOMPLETETEXT: [''],
            TRANSFERFEESEXCLUDED: [''],
            DUPLICATEDOCUMENTS: [''],
            DUPLICATETRANSFERS: [''],
            ENOSID: [''],

            // Concessions and Duty

            PROPERTYTYPE: [''],
            ELIGIBLEFORSTAMPDUTYREDUCTION: [''],
            FIRSTHOMEOWNERSGRANT: [''],
            NEWHOMEGRANT: [''],
            ELIGIBLEPURCHASERPERCENTAGE: [''],
            EXEMPTFROMPREMIUMPROPERTYDUTY: [''],
            FOREIGNPURCHASER: [''],
            DUTIBLEAMOUNT: ['', {updateOn: 'blur'}],
            ADDITIONALDUTY: ['', {updateOn: 'blur'}],
            LODGEMENTFEE: ['', {updateOn: 'blur'}],
            ADVALOREMFEE: ['', {updateOn: 'blur'}],
            TOTALREGISTRATIONFEES: ['', {updateOn: 'blur'}],
            STAMPDUTYAMOUNT: ['', {updateOn: 'blur'}],
            STAMPDUTYDUEDATE: [''], //	Date
            STAMPDUTYDUEDATETEXT: [''],
            STAMPDUTYPAIDDATE: [''],//	Date
            STAMPDUTYPAIDDATETEXT: [''],
            OSRRECEIPTNO: [''],


            // Settlement
            FINANCEDATE: [''], //date
            FINANCEDATETEXT: [''],
            BANKREFERENCE: [''],
            BUILDINGREPORTCOMPLETED: [''],
            PESTREPORTCOMPLETED: [''],
            SPECIALCONDITIONS: [''],
            PAYABLEBYPURCHASER: ['', {updateOn: 'blur'}],
            PAYABLEBYVENDOR: ['', {updateOn: 'blur'}],
            TOTALDUE: ['', {updateOn: 'blur'}],
            CHEQUESDUE: ['', {updateOn: 'blur'}],
            UNPAIDAMOUNT: ['', {updateOn: 'blur'}],
            VENDORPAYS: [''],
            DATEPAID: [''],	//Date
            DATEPAIDTEXT: [''],



        });
    }
    CommanDatePickerClick(type: string, event: MatDatepickerInputEvent<Date>, formControlVar) {
        this.MatterConveyancingDetailsForm.controls[formControlVar].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    CommanDateSetter(val, Field) {
        let SplitedDate = val.split("/");
        this.MatterConveyancingDetailsForm.controls[Field + "TEXT"].setValue(new Date(SplitedDate[1] + '/' + SplitedDate[0] + '/' + SplitedDate[2]));
    }
    SaveMatterConveyancing() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MatterConveyancingDetailsForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERCONVEYANCINGGUID;
        }

        delete formData.EXCHANGEDATETEXT;
        delete formData.ADJUSTMENTDATETEXT;
        delete formData.SETTLEMENTDATETEXT;
        delete formData.BALANCEDEPOSITDATETEXT;
        delete formData.DEPOSITBONDEXPIRYDATETEXT;
        delete formData.COOLINGOFFDATETEXT;
        delete formData.CONTRACTCOMPLETIONDATETEXT;
        delete formData.CONTRACTCOMPLETIONOTHERDATETEXT;
        delete formData.AUCTIONDATETEXT;
        delete formData.FINALDATEFORNOTICETOCOMPLETETEXT;
        delete formData.STAMPDUTYDUEDATETEXT;
        delete formData.STAMPDUTYPAIDDATETEXT;
        delete formData.FINANCEDATETEXT;
        delete formData.DATEPAIDTEXT;
        delete formData.BALANCEVAL;

        delete formData.POOLINSPECTIONTEXT;
        delete formData.PESTINSPECTIONTEXT;

        const formAction = (formData.MATTERCONVEYANCINGGUID) ? 'Update' : 'Insert';
        const DebtRecoveryData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(DebtRecoveryData, 'matter-conveyancing').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, DebtRecoveryData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, DebtRecoveryData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, DebtRecoveryData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveeMatterConveyancingAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveeMatterConveyancingAfterVAlidation(details);

        }
    }
    SaveeMatterConveyancingAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-conveyancing').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    DeleteMatterConveyancing() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-conveyancing').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterConveyancingData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERCONVEYANCINGGUID: MatterConveyancingData.MATTERCONVEYANCINGGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-conveyancing').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }

}
