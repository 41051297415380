import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseConfigService } from 'src/@fuse/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from 'src/@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first, takeUntil } from 'rxjs/operators';
import browser from 'browser-detect';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { AuthenticationService } from '@_services/authentication.service';
import { AppPermissionsService } from '@_services/app-permissions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SocketService } from '@_services/socket.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'app-try-silq-free',
    templateUrl: './try-silq-free.component.html',
    styleUrls: ['./try-silq-free.component.scss'],
    animations: fuseAnimations
})
export class TrySilqFreeComponent implements OnInit, OnDestroy {
    trySilqFreeForm: FormGroup;
    isTry: boolean = true;
    tryLoginForm: FormGroup;
    progress: any = 2;
    btnText: any = '';
    isspiner: boolean = false;
    isCreateDb: boolean = false;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    errorWarningData: any = {};
    hide: boolean = true;
    connected: Subscription;
    isConnected = false;
    ipAddress: any;
    customerGuid: any = '';
    browserData: any = browser();
    // webSocket = new WebSocket(environment.MainSocketUrl);
    currentUser;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private router: ActivatedRoute,
        private routers: Router,
        private _formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private _AppPermissionsService: AppPermissionsService,
        public behaviorService: BehaviorService,
        private socketService: SocketService,
        private sanitizer: DomSanitizer,
        public _matDialog: MatDialog) {
        this.router.queryParams.subscribe(params => {
            this.customerGuid = params.id;
        });
        // this._fuseConfigService.config = {
        //   colorTheme: 'theme-default',
        //   layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
        // };
        this._fuseConfigService.config = {
            // colorTheme: 'theme-default',
            layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
        };
        let CurrentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (CurrentUser && CurrentUser?.THEMECOLOUR) {
            this._fuseConfigService.config.colorTheme = CurrentUser.THEMECOLOUR
        } else {
            this._fuseConfigService.config.colorTheme = 'theme-default'
        }
    }

    /**
     * This 
     */
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        this.trySilqFreeForm = this._formBuilder.group({ name: [''], email: [''], phone: [''] });
        this.tryLoginForm = this._formBuilder.group({ username: [''], password: [''] });
        this.isspiner = true;
        this._mainAPiServiceService.getSetData({ "FormAction": "complete signup", "ValidateOnly": true, "Data": { "CustomerGuid": this.customerGuid } }, 'SetDatabase').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                let newData = response.DATA;
                this.trySilqFreeForm.patchValue({ name: newData.LogonName, email: newData.EmailAddress, phone: newData.PhoneNumber });
            } else {
                this.toastr.error(response.MESSAGE);
            }
            this.isspiner = false;
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }
    get f() {
        return this.trySilqFreeForm.controls;
    }
    get lf() {
        return this.tryLoginForm.controls;
    }

    /**
     * This function is used to Save the Data into the database
     */
    async saveDataBase() {
        // this.socketService.openWSConnection(this.customerGuid, 'SetDatabaseStatus').pipe(takeUntil(this._unsubscribeAll$))
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        // await this.socketService.openSocket();
        this.isspiner = true;
        this.isCreateDb = true;
        // this.// webSocket = new WebSocket(environment.MainSocketUrl);
        // this.openWSConnection(this.customerGuid);
        this._mainAPiServiceService.SetDatabase({ "FormAction": "complete signup", "ValidateOnly": false, "Data": { "CustomerGuid": this.customerGuid } }, 'SetDatabase').pipe(takeUntil(this._unsubscribeAll$)).subscribe(async response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                let USerData = response.DATA;
                this.isTry = false;
                this.tryLoginForm.patchValue({ username: USerData.LogonName, password: USerData.Password });
                // this.webSocket.send("unwatch channel " + this.customerGuid + " SetDatabaseStatus");
                // this.webSocket.close();
                this.toastr.success(response.MESSAGE);
                this.loginUser();
                // this.socketService.closeSocket(this.customerGuid, "SetDatabaseStatus");
            } else {
                this.toastr.error(response.MESSAGE);
            }
            this.isCreateDb = false;
            this.isspiner = false;
            // this.socketService.closeSocket(this.customerGuid, "SetDatabaseStatus");

        }, async error => {
            // this.socketService.closeSocket(this.customerGuid, "SetDatabaseStatus");

            this.isCreateDb = false;
            this.isspiner = false;
            this.toastr.error(error);
        });
    }
    // openWSConnection(SessionToken: any) {
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " SetDatabaseStatus");
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //     };
    //     demoSoket.onerror = (errorEvent) => {
    //     };
    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }
    
    /**
     * This function is used to login the user
     */
    loginUser() {
        this.isspiner = true;
        this.authenticationService.login(this.lf.username.value, this.lf.password.value,
            this.ipAddress, this.browserData.name, this.browserData.os, this.browserData.os + '/' + this.browserData.version).pipe(first()).subscribe(data => {
                if (data) {
                    let pageModule: any = { 'spend_money': 100, 'contact_page': 100, 'matter_page': 100, 'receive_money': 100, 'past_banking': 100, 'past_recounciliations': 100, 'recounciliations_item': 100, 'activities': 100, 'conflict_check': 100, 'contact_corres_details': 100, 'contact_select': 100, 'create_diary': 100, 'document_register': 100, 'main_safe_custoday': 100, 'invoice_page': 100, 'select_invoice': 100, 'authorities': 100, 'chronology': 100, 'file_note': 100, 'legal_task': 100, 'safecustody': 100, 'user_select': 100, 'account_page': 100, 'generate_tempplate': 100, 'user_dialog': 100, 'user_page': 100, 'trust_money': 100, 'end_month_histroy': 100, 'matter_select': 100, 'time_entries_page': 100, 'work_in_progreess': 100, 'receipt_credit_page': 100, 'matter_trust': 100, 'matter_invoice': 100, 'estimate_page': 100, 'matter_apppintment_page': 100, 'template_list': 100, 'email_templete': 100, 'task_page': 100, 'main_search_page': 100, 'account_transactions_trust': 100, 'list_of_alloawnces_page': 100, 'list_of_cheques_page': 100, 'folder_page': 100 };
                    localStorage.setItem('lastPageSize', JSON.stringify(pageModule));
                    this.isspiner = false;
                    this._AppPermissionsService.applictionSetting(JSON.parse(localStorage.getItem('Login_response')));
                    let currentTime: any = new Date();
                    currentTime.setMinutes(currentTime.getMinutes() + 1);
                    localStorage.setItem('currentTime', currentTime.getTime());
                    setInterval(() => this.authenticationService.MaintainLicence(), 10 * 60 * 1000); // Update 3000ms into 10min , this call version API every 10 min
                    let currentUser: any = this.authenticationService.currentUserValue;
                    this.routers.navigate(this.authenticationService.getRouteModuleData(currentUser.INITIALWINDOW));
                    this.behaviorService.dialogClose(null);
                } else {
                    this.isspiner = false;
                }
            }, error => {
                this.isspiner = false;
                this.toastr.error(error);
            });
    }
}
