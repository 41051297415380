<!-- [formGroup]="matterdetailForm" -->
<form name="MatterCriminalForm" id="MatterCriminalForm" [formGroup]="MatterCriminalForm" fxLayout="column" fxLayoutAlign="start" class="one" fxFlex="1 0 auto">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BRIEFSERVICEDATE,'has-warning':errorWarningData.Warning?.BRIEFSERVICEDATE}">
            <mat-label>Brief Service Date</mat-label>
            <input (dateInput)="BriefServiceDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.BRIEFSERVICEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BRIEFSERVICEDATETEXT" (dateChange)="BriefServiceDateClick('change', $event)" matInput [matDatepicker]="BriefServiceDate">
            <mat-datepicker-toggle matSuffix [for]="BriefServiceDate"></mat-datepicker-toggle>
            <mat-datepicker #BriefServiceDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.BRIEFSERVICEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BRIEFSERVICEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BRIEFSERVICEDATE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMMITTALDATE,'has-warning':errorWarningData.Warning?.COMMITTALDATE}">
            <mat-label>Committal Date</mat-label>
            <input (dateInput)="CommittalDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.COMMITTALDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COMMITTALDATETEXT" (dateChange)="CommittalDateClick('change', $event)" matInput [matDatepicker]="CommittalDate">
            <mat-datepicker-toggle matSuffix [for]="CommittalDate"></mat-datepicker-toggle>
            <mat-datepicker #CommittalDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.COMMITTALDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COMMITTALDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMITTALDATE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.REPLYDATE,'has-warning':errorWarningData.Warning?.REPLYDATE}">
            <mat-label>Reply Date</mat-label>
            <input (dateInput)="ReplyDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.REPLYDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="REPLYDATETEXT" (dateChange)="ReplyDateClick('change', $event)" matInput name="ReplyDate" [matDatepicker]="ReplyDate">
            <mat-datepicker-toggle matSuffix [for]="ReplyDate"></mat-datepicker-toggle>
            <mat-datepicker #ReplyDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.REPLYDATE " class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REPLYDATE .COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REPLYDATE ?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" [ngClass]="{'has-error':errorWarningData.Error?.COHABITATIONDATE,'has-warning':errorWarningData.Warning?.COHABITATIONDATE}">
        <section class="example-section">
            <mat-checkbox class="example-margin"  formControlName="JUVENILE">Juvenile</mat-checkbox>
            <mat-checkbox class="example-margin"  formControlName="WAIVEROFCOMMITTAL">Waiver of Commital</mat-checkbox>
        </section>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BAILDATE,'has-warning':errorWarningData.Warning?.BAILDATE}">
            <mat-label>Bail Date</mat-label>
            <input (dateInput)="BailDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.BAILDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BAILDATETEXT" (dateChange)="BailDateClick('change', $event)" matInput [matDatepicker]="BailDate">
            <mat-datepicker-toggle matSuffix [for]="BailDate"></mat-datepicker-toggle>
            <mat-datepicker #BailDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.BAILDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BAILDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BAILDATE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SENTENCINGDATE,'has-warning':errorWarningData.Warning?.SENTENCINGDATE}">
            <mat-label>Sentencing Date</mat-label>
            <input (dateInput)="SentencingDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.SENTENCINGDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SENTENCINGDATETEXT" (dateChange)="SentencingDateClick('change', $event)" matInput name="SentencingDate" [matDatepicker]="SentencingDate">
            <mat-datepicker-toggle matSuffix [for]="SentencingDate"></mat-datepicker-toggle>
            <mat-datepicker #SentencingDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.SENTENCINGDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SENTENCINGDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SENTENCINGDATE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BAILRESTRICTIONS,'has-warning':errorWarningData.Warning?.BAILRESTRICTIONS}">
            <mat-label>Bail Restrictions</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.BAILRESTRICTIONS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BAILRESTRICTIONS">
         <div  *ngIf="testBool && toolTipList.BAILRESTRICTIONS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BAILRESTRICTIONS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BAILRESTRICTIONS?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OUTCOME,'has-warning':errorWarningData.Warning?.OUTCOME}">
            <mat-label>Outcome</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.OUTCOME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="OUTCOME">
         <div  *ngIf="testBool && toolTipList.OUTCOME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OUTCOME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OUTCOME?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SENTENCE,'has-warning':errorWarningData.Warning?.SENTENCE}">
            <mat-label>Sentence</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.SENTENCE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SENTENCE">
         <div  *ngIf="testBool && toolTipList.SENTENCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SENTENCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SENTENCE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <section class="example-section">
            <mat-checkbox class="example-margin"  formControlName="S91APPLICATION">S91 Application</mat-checkbox>
            <mat-checkbox class="example-margin"  formControlName="S93APPLICATION">S93 Application</mat-checkbox>
        </section>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OFFENCEDATE,'has-warning':errorWarningData.Warning?.OFFENCEDATE}">
            <mat-label>Offence Date</mat-label>
            <input (dateInput)="OffenceDate('input', $event)" matTooltip="{{(toolTipList)?toolTipList.OFFENCEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="OFFENCEDATETEXT" (dateChange)="OffenceDate('change', $event)" matInput [matDatepicker]="BriefServiceDate2">
            <mat-datepicker-toggle matSuffix [for]="BriefServiceDate2"></mat-datepicker-toggle>
            <mat-datepicker #BriefServiceDate2></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.OFFENCEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OFFENCEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OFFENCEDATE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CUSTODYNUMBER,'has-warning':errorWarningData.Warning?.CUSTODYNUMBER}">
            <mat-label>Custody No</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CUSTODYNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CUSTODYNUMBER">
         <div  *ngIf="testBool && toolTipList.CUSTODYNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CUSTODYNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CUSTODYNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="60" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CHARGENUMBER,'has-warning':errorWarningData.Warning?.CHARGENUMBER}">
            <mat-label>Charge No</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CHARGENUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CHARGENUMBER">
         <div  *ngIf="testBool && toolTipList.CHARGENUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CHARGENUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHARGENUMBER?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CRIMINALNAMEINDEX,'has-warning':errorWarningData.Warning?.CRIMINALNAMEINDEX}">
            <mat-label>Crim. Name Index</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CRIMINALNAMEINDEX?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CRIMINALNAMEINDEX">
         <div  *ngIf="testBool && toolTipList.CRIMINALNAMEINDEX" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CRIMINALNAMEINDEX.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CRIMINALNAMEINDEX?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LEGALAIDREFERENCE,'has-warning':errorWarningData.Warning?.LEGALAIDREFERENCE}">
            <mat-label>Legal Aid Ref</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.LEGALAIDREFERENCE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LEGALAIDREFERENCE">
         <div  *ngIf="testBool && toolTipList.LEGALAIDREFERENCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LEGALAIDREFERENCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LEGALAIDREFERENCE?.COPYVALUE:'')">file_copy</mat-icon></div> 
        </mat-form-field>
    </div>
</form>