import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-property-title',
  templateUrl: './property-title.component.html',
  styleUrls: ['./property-title.component.scss']
})
export class PropertyTitleComponent implements OnInit, OnDestroy {
  @Input() MatterPropertyDetailsForm: FormGroup;
  @Input() errorWarningData: any;
  isspiner: boolean = false;
  @Input() testBool;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  sub: Subscription;

  constructor(  public _mainAPiServiceService: MainAPiServiceService, public tooltipService: TooltipService,
    private toastr: ToastrService) { }

      /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  get f() {
    return this.MatterPropertyDetailsForm.controls;
  }

  /**
   * This function is used to Split the Title data
   */
  SplitTitleRefs() {
    this.isspiner = true;
    let Payload = {
      "Action": "SplitTitleRefs",
      "Data": {'titleReference': this.f.LOTSTITLE.value},
    }
    this.sub = this._mainAPiServiceService.getSetData(Payload, 'utility').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        let addressData = response.DATA;
        this.MatterPropertyDetailsForm.patchValue({
          LOTS: addressData['LOTS'],
          PLANTYPE: addressData['PLANTYPE'],
          PLANNUMBER: addressData['PLANNUMBER'],
          LOT2: addressData['LOT2'],
          PLANTYPE2: addressData['PLANTYPE2'],
          PLANNUMBER2: addressData['PLANNUMBER2'],
          LOT3: addressData['LOT3'],
          PLANTYPE3: addressData['PLANTYPE3'],
          PLANNUMBER3: addressData['PLANNUMBER3'],
          LOT4: addressData['LOT4'],
          PLANTYPE4: addressData['PLANTYPE4'],
          PLANNUMBER4: addressData['PLANNUMBER4'],
          LOT5: addressData['LOT5'],
          PLANTYPE5: addressData['PLANTYPE5'],
          PLANNUMBER5: addressData['PLANNUMBER5'],
        });
      }
      this.isspiner = false;
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to get the text data
   */
  myFunction(event:any) {
  
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    //  navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    
    navigator.clipboard.writeText(event)
}
}
