<div class="sort_main_sidebar">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
        <h3 class="one"><strong class="x">Trust {{TrustMoneyList?.CASHBOOK}} Details</strong></h3>
    </div>
    <!-- / SIDEBAR HEADER -->
    <!-- SIDEBAR CONTENT -->
    <div class="two">
        <div class="content" fusePerfectScrollbar>
            <div class="file-details" [@animate]="{value:'*',params:{delay:'200ms'}}">
                <span *ngIf="TrustMoneyList?.CASHBOOK=='Transfer'">
                    <span *ngFor="let item of TrustMoneyList?.TRUSTTRANSACTIONITEMS">
                        <table *ngIf="item.ITEMTYPE=='From'||item.ITEMTYPE=='To'">
                            <tr class="matter_num">
                                <th colspan="2" style="text-align: center;">{{item?.ITEMTYPE}}</th>
                            </tr>
                            <tr class="matter_num">
                                <th>{{barristerTitle}}</th>
                                <td>{{item?.SHORTNAME}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Amount</th>
                                <td>{{item?.AMOUNT | toFixedAmount:true}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Client</th>
                                <td>{{item?.CLIENTNAME }}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Reason</th>
                                <td>{{item?.PURPOSE }}</td>
                            </tr>
                        </table>
                        <mat-divider *ngIf="item.ITEMTYPE=='From'||item.ITEMTYPE=='To'"></mat-divider>
                    </span>
                </span>
                <span *ngIf="TrustMoneyList?.CASHBOOK=='Receipt'">
                    <span *ngFor="let item of TrustMoneyList?.TRUSTTRANSACTIONITEMS">
                        <table *ngIf="item.ITEMTYPE=='To'">
                            <tr class="matter_num">
                                <th>{{barristerTitle}}</th>
                                <td>{{item?.SHORTNAME}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Total Amount</th>
                                <td>{{TrustMoneyList?.AMOUNT | toFixedAmount:true}}</td>
                            </tr>
                            <!-- <tr class="unbilled">
                                <th>Amount</th>
                                <td>{{item?.AMOUNT | toFixedAmount:true}}</td>
                            </tr> -->
                            <tr class="unbilled">
                                <th>Client</th>
                                <td>{{item?.CLIENTNAME }}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Payment Type</th>
                                <td>{{TrustMoneyList?.PAYMENTTYPE }}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Reason</th>
                                <td>{{item?.PURPOSE }}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Date</th>
                                <td>{{TrustMoneyList?.TRANSACTIONDATE }}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Ledger Account</th>
                                <td>{{item?.ACCOUNTNUMBER }}</td>
                            </tr>
                        </table>
                        <mat-divider *ngIf="item.ITEMTYPE=='To'"></mat-divider>
                    </span>
                    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
                        <h3 class="one"><strong class="x">{{TrustMoneyList?.PAYMENTTYPE }} details</strong></h3>
                    </div>
                    <table *ngIf="TrustMoneyList?.PAYMENTTYPE=='Cash'">
                        <tr class="matter_num" *ngIf="TrustMoneyList?.CASHGROUP?.COINS">
                            <th>Coins</th>
                            <td>{{TrustMoneyList?.CASHGROUP?.COINS}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.CASHGROUP?.FIFTYDOLLARS">
                            <th>Fifty Dollars</th>
                            <td>{{TrustMoneyList?.CASHGROUP?.FIFTYDOLLARS}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.CASHGROUP?.FIVEDOLLARS">
                            <th>Five Dollars</th>
                            <td>{{TrustMoneyList?.CASHGROUP?.FIVEDOLLARS}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.CASHGROUP?.HUNDREDDOLLARS">
                            <th>Hundred Dollars</th>
                            <td>{{TrustMoneyList?.CASHGROUP?.HUNDREDDOLLARS}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.CASHGROUP?.TENDOLLARS">
                            <th>Ten Dollars</th>
                            <td>{{TrustMoneyList?.CASHGROUP?.TENDOLLARS}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.CASHGROUP?.TWENTYDOLLARS">
                            <th>Twenty Dollars</th>
                            <td>{{TrustMoneyList?.CASHGROUP?.TWENTYDOLLARS}}</td>
                        </tr>
                    </table>
                    <table *ngIf="TrustMoneyList?.PAYMENTTYPE=='Cheque' || TrustMoneyList?.PAYMENTTYPE=='EFT'">
                        <tr class="matter_num" *ngIf="TrustMoneyList?.BANKDETAILS?.ACCOUNTNAME">
                            <th>Account Name</th>
                            <td>{{TrustMoneyList?.BANKDETAILS?.ACCOUNTNAME}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.BANKDETAILS?.ACCOUNTNUMBER">
                            <th>Account Number</th>
                            <td>{{TrustMoneyList?.BANKDETAILS?.ACCOUNTNUMBER}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.BANKDETAILS?.BSB">
                            <th>BSB</th>
                            <td>{{TrustMoneyList?.BANKDETAILS?.BSB}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.BANKDETAILS?.CHEQUENO">
                            <th>Cheque NO</th>
                            <td>{{TrustMoneyList?.BANKDETAILS?.CHEQUENO}}</td>
                        </tr>
                        <tr class="matter_num" *ngIf="TrustMoneyList?.BANKDETAILS?.EFTREFERENCE">
                            <th>EFT Reference</th>
                            <td>{{TrustMoneyList?.BANKDETAILS?.EFTREFERENCE}}</td>
                        </tr>
                    </table>
                </span>
                <span *ngIf="TrustMoneyList?.CASHBOOK!='Transfer' && TrustMoneyList?.CASHBOOK!='Receipt'">
                    <span *ngFor="let item of TrustMoneyList?.TRUSTTRANSACTIONITEMS">
                        <table *ngIf="item.ITEMTYPE=='To'">
                            <tr class="matter_num">
                                <th>{{barristerTitle}}</th>
                                <td>{{item?.SHORTNAME}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Amount</th>
                                <td>{{item?.AMOUNT | toFixedAmount:true}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Ledger A/c</th>
                                <td>{{item?.ACCOUNTNAME}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Bank Referenc</th>
                                <td>{{item?.EFTREFERENCE}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Client</th>
                                <td>{{item?.CLIENTNAME }}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>Reason</th>
                                <td>{{item?.PURPOSE }}</td>
                            </tr>
                        </table>
                        <mat-divider *ngIf="item.ITEMTYPE=='To'"></mat-divider>
                    </span>
                </span>
            </div>
        </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>