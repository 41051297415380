import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatterDialogComponent } from '../../time-entries/matter-dialog/matter-dialog.component';
import * as moment from 'moment';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ResizeEvent } from 'angular-resizable-element';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { AppPermissionsPipe } from '@_pipes/app-permissions.pipe';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-create-diary',
    templateUrl: './create-diary.component.html',
    styleUrls: ['./create-diary.component.scss'],
    providers: [AppPermissionsPipe, TierPermissionPipe],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations

})
export class CreateDiaryComponent implements OnInit, OnDestroy {
    filteredOptions: Observable<string[]>;
    priviousQuntitychnage: any;
    priviousQuntitychnagesend: any;
    CreateTimeEnrtyForm: FormGroup;
    isLoadingResults: boolean = false;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    tempColobj: any;
    ColumnsObj = [];
    timeStops: any = [];
    userList: any;
    SendCreateTimeEntries: any = [];
    ActivityList: any;
    confirmDialogRef: any;
    LookupsList: any;
    createDiaryForm: any = {}
    CreateTimeEntriesArray: any = [];
    currenbtMatter: any;
    calculateData: any = { MatterGuid: '', QuantityType: '', Quantity: '', FeeEarner: '', FeeType: '' };
    theme_type = localStorage.getItem('theme_type');
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    // displayedColumns: string[];
    selection = new SelectionModel<any>(true, []);
    TimerDataFordiary: any = [];
    highlightedRows: any;
    displayedColumns: string[] = ['select', 'APPOINTMENTDATE', 'APPOINTMENTTIME', 'DURATION', 'MATTERSHORTNAME',
        'APPOINTMENTTYPE', 'NOTE', 'PRICE', 'PRICEINCGST', 'GST'];
    pageSize: any;
    isDisplay: boolean = false;
    CreateDiaryArray: any = [];
    INDEX: any;
    filterData: any = [];
    errorWarningData: any = { "Error": [], 'Warning': [] };
    forHideShowDateRangePicker: string;
    callApi: string;
    SendINDEX: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    accessTierPermission: any = JSON.parse(localStorage.getItem('set_tier_permission'))
    sortingDefaultState: any = {};
    sortactive: any;
    sortDirection: any;
    isLoading_another: boolean = false;
    isMatterPopupOpen: boolean = true;
    StoreExGST: any;
    StoreIncGST: any;
    hasDateRange: boolean = false;
    windowNameId: any;

    searchMatter: Subscription;
    exportdatavalue: any;
    searchData: any;
    matterlist: Observable<string[]>;
    mattername: any = [];
    selectedData: any = [];
    Deliverable: any;
    DeliverableField: any;
    DerivableDropShow: boolean = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    MATTERDELIVERABLEGUIDdarta: any;
    MATTERGUID: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private Timersservice: TimersService,
        public datepipe: DatePipe,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private behaviorService: BehaviorService,
        private TableColumnsService: TableColumnsService,
        public _matDialog: MatDialog,
        private toastr: ToastrService,
        private tierpermission: TierPermissionPipe,
        private SortingbehaviorService: SortingBehaviourService) {
        // this.getTableFilter();
        localStorage.setItem('istrackid', 'CreateDiaryComponent');
        this.SortingbehaviorService.createDiarySorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.sortingDefaultState = result;
                localStorage.setItem('create_diary_screen_sorting', JSON.stringify(result));
            } else {
                this.sortingDefaultState = JSON.parse(localStorage.getItem('create_diary_screen_sorting'))
            }
        });
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.currenbtMatter = result;
        //   }
        // });

        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            const materIDofTab = window.name.split("_");
            this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            this.currenbtMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
            if (this.currenbtMatter) {
            } else {
                if (result) {
                    this.currenbtMatter = result;
                    localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.currenbtMatter))
                }
            }
        });

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'CreateDiaryComponent' && result['click'] && result['data']['GetAppointment']) {
                this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"));
                this.LoadData(this.filterData);
            }
        });


    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.DELIVERABLES$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data => {
            this.DeliverableField = data
            if (this.DeliverableField == "No") {
                this.DerivableDropShow = true
            } else {
                this.DerivableDropShow = false
            }
        })

        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "";
            this.sortDirection = "";
        }

        this.CreateTimeEnrtyForm = this.fb.group({
            matterautoVal: [''],
            MATTERGUID: [''],
            ADDITIONALTEXT: [''],
            PRICEINCGST: [''],
            PRICE: [''],
            GST: [''],
            sendPRICE: [''],
            sendPRICEINCGST: [''],
            QUANTITYTYPE: [''],
            QUANTITY: [''],
            FEEEARNER: [''],
            ITEMTIME: [''],
            ITEMDATETEXT: [new Date()],
            ITEMDATE: [new Date()],
            sendITEMDATE: [this.datepipe.transform(new Date(), 'dd/MM/yyyy')],
            DateRange: [''],
            Date: [''],
            Item: ['Billable'],
            dlpdrop: [''],
            MATTERDELIVERABLEGUID: ['']
        });
        let currentDate = new Date();
        let updatecurrentDate = new Date();
        updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
        this.forHideShowDateRangePicker = "hide";
        let end = this.datepipe.transform(currentDate, 'dd/MM/yyyy');
        let begin = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');

        this.filterData = {
            'TYPEFILTER': "Billable", 'DATESTART': begin, 'DATEEND': end, "SHOWWHAT": 'CREATE WIP',
            'Search': '', 'USERGUID': this.currentUser.UserGuid
        }
        if (!localStorage.getItem("Create_DiaryEntries_filter")) {
            localStorage.setItem('Create_DiaryEntries_filter', JSON.stringify(this.filterData));
        } else {
            this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"))
        }
        if (this.filterData.DATESTART && this.filterData.DATEEND) {
            this.hasDateRange = true;
            let DATESTART = this.filterData.DATESTART.split("/");
            let sendDATESTART = new Date(DATESTART[1] + '/' + DATESTART[0] + '/' + DATESTART[2]);
            let DATEEND = this.filterData.DATEEND.split("/");
            let SensDATEEND = new Date(DATEEND[1] + '/' + DATEEND[0] + '/' + DATEEND[2]);
            this.CreateTimeEnrtyForm.controls['Date'].setValue({ begin: sendDATESTART, end: SensDATEEND });
            this.CreateTimeEnrtyForm.controls['Item'].setValue(this.filterData.TYPEFILTER);
            // let potData = { 'ITEMSTARTDATE': new Date() };
            const date1 = sendDATESTART;
            const date2 = SensDATEEND;
            const date3 = new Date();
            const diffTime = Math.abs(date2.getTime() - date1.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const date4 = this.datepipe.transform(date2, 'dd/MM/yyyy');
            const date5 = this.datepipe.transform(date3, 'dd/MM/yyyy');
            if (date4 === date5) {
                if (diffDays == 0) {
                    this.CreateTimeEnrtyForm.controls['DateRange'].setValue("Today");
                } else if (diffDays == 7) {
                    this.CreateTimeEnrtyForm.controls['DateRange'].setValue("Last 7 days");
                } else if (diffDays == 30) {
                    this.CreateTimeEnrtyForm.controls['DateRange'].setValue("Last 30 days");
                } else if (diffDays == 90) {
                    this.CreateTimeEnrtyForm.controls['DateRange'].setValue("Last 90 days");
                }
            } else {
                this.forHideShowDateRangePicker = "show";
                this.CreateTimeEnrtyForm.controls['DateRange'].setValue("Date Range");
            }
        }
        this.LoadData(this.filterData);
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.userList = this.Timersservice.removeUSerWithoutId(userList);
        } else {
            this.Timersservice.GetUsers({}).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.userList = this.Timersservice.removeUSerWithoutId(res.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(res.DATA.USERS));
                } else {
                    this.userList = [];
                }
            }, err => {
                this.toastr.error(err);
            });
        }
        this.commonQuntityTypesShow(this.currentUser.DefaultQuantityType);
        this.CreateTimeEnrtyForm.controls['FEEEARNER'].setValue(this.currentUser.UserId);
        this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(this.currenbtMatter.SHORTNAME + ' : ' + this.currenbtMatter.MATTER);
        this.calculateData.MatterGuid = this.currenbtMatter.MATTERGUID;
        this.calculateData.QuantityType = this.currentUser.DefaultQuantityType;
        this.ActivityList = [
            { 'ACTIVITYID': 'hh:mm', 'DESCRIPTION': 'hh:mm' }, { 'ACTIVITYID': 'Hours', 'DESCRIPTION': 'Hours' },
            { 'ACTIVITYID': 'Minutes', 'DESCRIPTION': 'Minutes' }, { 'ACTIVITYID': 'Days', 'DESCRIPTION': 'Days' },
            { 'ACTIVITYID': 'Units', 'DESCRIPTION': 'Units' }, { 'ACTIVITYID': 'Fixed', 'DESCRIPTION': 'Fixed' }
            // { 'ACTIVITYID': 'hh:mm', 'DESCRIPTION': 'hh:mm' }, { 'ACTIVITYID': 'Hours', 'DESCRIPTION': 'Hours' },
            // { 'ACTIVITYID': 'Minutes', 'DESCRIPTION': 'Minutes' }, { 'ACTIVITYID': 'Days', 'DESCRIPTION': 'Days' },
            // { 'ACTIVITYID': 'Units', 'DESCRIPTION': 'Units' }, { 'ACTIVITYID': 'Fixed', 'DESCRIPTION': 'Fixed' }
        ];
        let LookupsList = JSON.parse(localStorage.getItem('lookups_list'));
        if (LookupsList && LookupsList != null) {
            this.LookupsList = LookupsList;
            this.filteredOptions = this.qf.ADDITIONALTEXT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        } else {
            this.Timersservice.GetLookupsData({ Action: 'getdata', Filters: { 'LookupType': 'Time Entry' } }).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    localStorage.setItem('lookups_list', JSON.stringify(res.DATA.LOOKUPS));
                    this.LookupsList = res.DATA.LOOKUPS;
                    this.filteredOptions = this.qf.ADDITIONALTEXT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
                } else {
                    this.LookupsList = [];
                }
                this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
            });
        }
        // setTimeout(() => {
        //   this.getData()
        // }, 2000);

        this.timeStops = this.getTimeStops('00:00', '23:30');

    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.searchMatter?.unsubscribe();
    }

    /**
     * This function is used to get tyhe data value
     */
    getData(data?):void {
        if (data != null && data != undefined) {
            this.MATTERGUID = data
        } else {
            this.MATTERGUID = this.currenbtMatter.MATTERGUID
        }
        const pipe = this.tierpermission.transform('Matter Deliverables');
        if (pipe && pipe.ALLOWED == 1 && this.currentUser && this.currentUser.PRODUCTTYPE == 'Solicitor') {
            this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: {"INCLUDEBLANKROW": true, MATTERGUID: this.MATTERGUID }, }, 'matter-deliverable').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
                if (responses.CODE == "200" && responses.STATUS == 'success') {
                    this.isLoadingResults = false;
                    this.Deliverable = responses.DATA.RECORDS;
                } else {
                    this.isLoadingResults = false;
                }
            }, error => {
                this.isLoadingResults = false;

            });
        } else {
        }
    }


    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.LookupsList.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)));
    }

    /**
     * This function is used to get the commonQuntity data value
     */
    commonQuntityTypesShow(val) {
        switch (val) {
            case 'X': {
                this.CreateTimeEnrtyForm.controls['QUANTITYTYPE'].setValue('hh:mm')
                break;
            }
            case 'H': {
                this.CreateTimeEnrtyForm.controls['QUANTITYTYPE'].setValue('Hours')
                break;
            }
            case 'M': {
                this.CreateTimeEnrtyForm.controls['QUANTITYTYPE'].setValue('Minutes')
                break;
            }
            case 'D': {
                this.CreateTimeEnrtyForm.controls['QUANTITYTYPE'].setValue('Days')
                break;
            }
            case 'U': {
                this.CreateTimeEnrtyForm.controls['QUANTITYTYPE'].setValue('Units')
                break;
            }
            case 'F': {
                this.CreateTimeEnrtyForm.controls['QUANTITYTYPE'].setValue('Fixed')
                break;
            }
            default: {
                this.calculateData.FeeType = val;
                this.calculateData.QuantityType = 'F';
                break;
            }
        }
    }

    /**
     * This function is used to sort the data value
     */
    sortData(val):void {
        this.SortingbehaviorService.createDiarySorting(val);
    }

    /**
     * This function is used to make the empty array
     */
    helloFunction():void {
        this.SendCreateTimeEntries = [];
    }

    
    isAllSelected() {
        if (this.TimerDataFordiary.length != 0) {
            const numSelected = this.selection.selected.length;
            const numRows = this.TimerDataFordiary.data.length;
            return numSelected === numRows;
        }

    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.TimerDataFordiary.data.forEach(row => this.selection.select(row));
        this.CreateDiaryArray.push(this.selection.selected);
    }

    /**
     * This function is used to set the check box label 
     */
    checkboxLabel(row?: any): string {
        if (this.TimerDataFordiary.length != 0) {
            if (!row) {
                return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
            }
            return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
        }

    }
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }
    onResizeEnd(event: ResizeEvent, columnName): void {
        // this.TableColumnsService.SaveWidthData(this.ColumnsObj, 'trust money', 'protected');
    }


    LoadData(data) {
        this.TimerDataFordiary = [];
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData(data, 'GetAppointment').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {

            if (response.CODE == 200 && response.STATUS == "success") {
                response.DATA.APPOINTMENTS.forEach((element, index) => {
                    element.index = index
                });
                this.TimerDataFordiary = new MatTableDataSource(response.DATA.APPOINTMENTS);

                this.TimerDataFordiary.paginator = this.paginator;
                this.TimerDataFordiary.sort = this.sort;
                if (response.DATA.APPOINTMENTS[0]) {
                    this.Rowclick(this.TimerDataFordiary.data[0], 0);
                    this.highlightedRows = 0;
                    this.isDisplay = false;
                } else {
                    this.isDisplay = true;
                    this.CreateTimeEnrtyForm.reset();
                }
                try {
                    this.TimerDataFordiary = new MatTableDataSource(response.DATA.APPOINTMENTS);
                    this.TimerDataFordiary.paginator = this.paginator;
                    this.TimerDataFordiary.sort = this.sort;
                } catch (error) {
                }
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
        this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
    }

    tabMatter() {
        this.isMatterPopupOpen = false;
        this.isLoading_another = true;

        let Payload = {
            "Action": "GetData",
            "Filters": {
                SHORTNAME: this.qf.matterautoVal.value
            }
        };

        this._mainAPiServiceService.getSetData(Payload, 'matter').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (response.DATA.RECORDS.length == 0) {
                    this.behaviorService.matterSerachFromQuickTimeEntry({ SHORTNAME: this.qf.matterautoVal.value })
                    this.selectMatter();
                    this.isLoading_another = false;
                } else if (response.DATA.RECORDS.length == 1) {
                    this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(response.DATA.RECORDS[0].MATTERGUID);
                    this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(response.DATA.RECORDS[0].SHORTNAME + ' : ' + response.DATA.RECORDS[0].MATTER);
                    this.matterChange('MatterGuid', response.DATA.RECORDS[0].MATTERGUID);
                    this.isLoading_another = false;
                } else {
                    this.behaviorService.matterSerachFromQuickTimeEntry({ SHORTNAME: this.qf.matterautoVal.value })
                    this.selectMatter();
                    this.isLoading_another = false;
                }
            } else if (response.MESSAGE == 'Not logged in') {
                this.isLoading_another = false;
            }
        }, err => {
            this.toastr.error(err);
            this.isLoading_another = false;
        });
        // }

    }
    public selectMatter() {

        const dialogRef = this.dialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(result.SHORTNAME + ' : ' + result.MATTER);
                this.mattername = [result.SELECTEDDESCRIPTION];
                this.matterChange('MatterGuid', result.MATTERGUID);
                this.CommonChagesArray();
                this.getData(result.MATTERGUID);
                // this.commonSendArry();
                // this.SendCreateTimeEntries[this.INDEX].MATTERGUID = this.qf.MATTERGUID.value;
            }
        });
    }
    QuickDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CreateTimeEnrtyForm.controls['ITEMDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.CreateTimeEnrtyForm.controls['sendITEMDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.CommonChagesArray();
        // this.commonSendArry();

    }
    getTimeStops(start, end) {
        var startTime = moment(start, 'hh:mm');
        var endTime = moment(end, 'hh:mm');
        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }
        var timeStops = [];
        while (startTime <= endTime) {
            timeStops.push(moment(startTime).format('hh:mm A'));
            startTime.add(30, 'minutes');
        }
        return timeStops;
    }
    calcPE() {
        let priceTemp: any;
        if (Number(this.StoreExGST) == Number(this.qf.PRICE.value)) {
            priceTemp = this.qf.sendPRICE.value;
        } else {
            priceTemp = this.qf.PRICE.value;
        }
        if (typeof priceTemp === 'undefined') {
            priceTemp = 0;
        }
        this.CreateTimeEnrtyForm.patchValue({
            sendPRICE: Number(priceTemp).toFixed(6),
            PRICE: Number(priceTemp).toFixed(2),
        });
        priceTemp = Number(priceTemp) * 1.1;
        this.CreateTimeEnrtyForm.patchValue({
            sendPRICEINCGST: Number(priceTemp).toFixed(6),
            PRICEINCGST: Number(priceTemp).toFixed(2),
        });
        this.StoreIncGST = Number(priceTemp.toFixed(2));
        // this.CreateTimeEnrtyForm.controls['PRICEINCGST'].setValue((this.qf.PRICE.value * 1.1).toFixed(2));
        this.CommonChagesArray();
        // this.commonSendArry();
    }
    calcPI() {
        let temGst: any;
        if (Number(this.StoreIncGST) == Number(this.qf.PRICEINCGST.value)) {
            temGst = this.qf.sendPRICEINCGST.value;
        } else {
            temGst = this.qf.PRICEINCGST.value;
        }
        if (typeof temGst === 'undefined') {
            temGst = 0;
        }
        this.CreateTimeEnrtyForm.patchValue({
            sendPRICEINCGST: Number(temGst).toFixed(6),
            PRICEINCGST: Number(temGst).toFixed(2),
        });
        temGst = Number(temGst) / 1.1;
        this.CreateTimeEnrtyForm.patchValue({
            sendPRICE: Number(temGst).toFixed(6),
            PRICE: Number(temGst).toFixed(2),
        });
        this.StoreExGST = Number(temGst.toFixed(2));
        // this.CreateTimeEnrtyForm.controls['PRICE'].setValue((this.qf.PRICEINCGST.value / 1.1).toFixed(2));
        this.CommonChagesArray();
        // this.commonSendArry();
    }
    matterChange(key: any, event: any) {
        if (this.qf.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.qf.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }
        this.priviousQuntitychnagesend = this.calculateData.QuantityType;
        this.calculateData.Quantity = this.qf.QUANTITY.value;
        if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
            this.isLoading_another = true;
            this.Timersservice.calculateWorkItems(this.calculateData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let CalcWorkItemCharge: any = response.DATA;
                    this.CreateTimeEnrtyForm.patchValue({
                        sendPRICE: Number(CalcWorkItemCharge.PRICE).toFixed(6),
                        PRICE: Number(CalcWorkItemCharge.PRICE).toFixed(2),
                        PRICEINCGST: Number(CalcWorkItemCharge.PRICEINCGST).toFixed(2),
                        GST: Number(CalcWorkItemCharge.GST).toFixed(2),
                        sendPRICEINCGST: Number(CalcWorkItemCharge.PRICEINCGST).toFixed(6)
                    });
                    this.CommonChagesArray();
                    this.isLoading_another = false;
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }
    quntityTypeChange(key: any, event: any) {
        if (this.qf.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.qf.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }
        this.calculateData.Quantity = this.qf.QUANTITY.value;
        if (this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
            this.isLoading_another = true;
            let sendData = {
                FromQuantityType: this.priviousQuntitychnage,
                toQuantityType: this.calculateData.QuantityType,
                Quantity: this.TimerDataFordiary.data[this.INDEX].QUANTITY
            }
            this.Timersservice.ChangeQuantityType(sendData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.CreateTimeEnrtyForm.controls['QUANTITY'].setValue(response.DATA.QUANTITY);
                    this.matterChange('QuantityType', event);
                    this.priviousQuntitychnagesend = response.DATA.TOQUANTITYTYPE;
                    this.isLoading_another = false;
                    this.CommonChagesArray();
                } else if (response.MESSAGE == 'Not logged in') { }
            }, err => {
                this.isLoading_another = false;
                this.toastr.error(err);
            });
        }
    }
    get qf() {
        return this.CreateTimeEnrtyForm.controls;
    }
    timeChange() {
        this.CommonChagesArray();
        // this.commonSendArry();
    }
    DateRange1(type: string, event: MatDatepickerInputEvent<Date>) {
        try {
            this.hasDateRange = true
            let begin = this.datepipe.transform(event.value['begin'], 'dd/MM/yyyy');
            let end = this.datepipe.transform(event.value['end'], 'dd/MM/yyyy');
            this.CommonDatefun(end, begin);
        } catch (error) {
            this.hasDateRange = false;
            this.CommonDatefun('', '');
            this.CreateTimeEnrtyForm.controls['Date'].setValue({ begin: '', end: '' });

        }
        this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"));
        this.LoadData(this.filterData);
    }
    DateRange(a, b) {
    }
    Rowclick(val, index) {
        this.getData(val.MATTERGUID)
        this.INDEX = index;
        this.CreateTimeEnrtyForm.controls['PRICE'].setValue(val.PRICE);
        this.CreateTimeEnrtyForm.controls['PRICEINCGST'].setValue(val.PRICEINCGST);
        this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(val.MATTERSHORTNAME);
        //  this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(response.DATA.MATTERS[0].SHORTNAME + ' : ' + response.DATA.MATTERS[0].MATTER);
        this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(val.MATTERGUID);
        if (val.MATTERSHORTNAME) {
            this.mattername = [val.SELECTEDDESCRIPTION]
        } else {
            this.mattername = []
        }
        this.calculateData.MatterGuid = val.MATTERGUID;
        if (val.APPOINTMENTDATE) {
            let Date1 = val.APPOINTMENTDATE.split("/");
            let ShowDate = new Date(Date1[1] + '/' + Date1[0] + '/' + Date1[2]);
            this.CreateTimeEnrtyForm.controls['ITEMDATETEXT'].setValue(ShowDate);
            this.CreateTimeEnrtyForm.controls['ITEMDATE'].setValue(val.APPOINTMENTDATE);
        }
        let ttyData = moment(val.APPOINTMENTTIME, 'hh:mm');
        this.CreateTimeEnrtyForm.controls['ITEMTIME'].setValue(moment(ttyData).format('hh:mm A'));
        this.CreateTimeEnrtyForm.controls['FEEEARNER'].setValue(val.FEEEARNERID);
        //   let num = val.DURATION.match(/\d+/g); num[0]
        this.CreateTimeEnrtyForm.controls['QUANTITY'].setValue(val.DURATIONShow ? val.DURATIONShow : val.QUANTITY);
        this.CreateTimeEnrtyForm.controls['ADDITIONALTEXT'].setValue(val.SUBJECT ? val.SUBJECT : '');

        this.CreateTimeEnrtyForm.controls['MATTERDELIVERABLEGUID'].setValue(val.MATTERDELIVERABLEGUID);
        this.priviousQuntitychnage = val.QUANTITYTYPE;
        this.commonQuntityTypesShow(val.QUANTITYTYPESend ? val.QUANTITYTYPESend : val.QUANTITYTYPE);


    }

    CommonChagesArray() {
        if (this.TimerDataFordiary.data[this.INDEX]) {
            this.TimerDataFordiary.data[this.INDEX].PRICE = this.qf.PRICE.value;
            this.TimerDataFordiary.data[this.INDEX].PRICEINCGST = this.qf.PRICEINCGST.value;
            this.TimerDataFordiary.data[this.INDEX].GST = this.qf.GST.value;
            this.TimerDataFordiary.data[this.INDEX].APPOINTMENTDATE = this.qf.ITEMDATE.value;
            this.TimerDataFordiary.data[this.INDEX].MATTERGUID = this.qf.MATTERGUID.value;
            this.TimerDataFordiary.data[this.INDEX].MATTERSHORTNAME = this.qf.matterautoVal.value;
            this.TimerDataFordiary.data[this.INDEX].APPOINTMENTTIME = this.qf.ITEMTIME.value;
            this.TimerDataFordiary.data[this.INDEX].FEEEARNERID = this.qf.FEEEARNER.value;
            this.TimerDataFordiary.data[this.INDEX].DURATION = this.qf.QUANTITY.value;
            this.TimerDataFordiary.data[this.INDEX].DURATIONShow = this.qf.QUANTITY.value;
            this.TimerDataFordiary.data[this.INDEX].ADDITIONALTEXT = this.qf.ADDITIONALTEXT.value;
            this.TimerDataFordiary.data[this.INDEX].MATTERDELIVERABLEGUID = this.qf.MATTERDELIVERABLEGUID.value;

            // this.TimerDataFordiary.data[this.INDEX].QUANTITYTYPE = this.qf.QUANTITYTYPE.value;
            this.TimerDataFordiary.data[this.INDEX].QUANTITYTYPESend = this.priviousQuntitychnagesend;
        }
    }

    CheckboxClick() {
    }
    LookupsChange(value: any) {
        this.CommonChagesArray();
    }
    checkMatterIsvalid() {
        let isValid = true;
        this.selection.selected.forEach((element, index) => {
            if (element.MATTERGUID == '') {
                this.highlightedRows = element.index;
                this.INDEX = element.index;
                this.Rowclick(this.TimerDataFordiary.data[element.index], element.index);
                let tempError: any = this.errorWarningData.Error;
                tempError['MATTERGUID'] = {};
                this.errorWarningData.Error = tempError;
                isValid = false;
            }
        });
        return isValid;
    }
    radioChange(event) {
        this.MATTERDELIVERABLEGUIDdarta = event
        this.CreateTimeEnrtyForm.controls['MATTERDELIVERABLEGUID'].setValue(event);
    };

    saveCreateDiary() {
        if (!this.checkMatterIsvalid()) {
            this.toastr.error('Please Select Matter For Highlited Row');
        } else if (this.selection.selected.length == 0) {
            this.toastr.error('Please Select At List One Appointment For Time Entry');
        } else {
            this.SendCreateTimeEntries = [];
            this.selection.selected.forEach(element => {
                this.SendCreateTimeEntries.push({
                    ADDITIONALTEXT: element.SUBJECT,
                    PRICE: element.PRICE,
                    PRICEINCGST: element.PRICEINCGST,
                    ITEMDATE: element.APPOINTMENTDATE,
                    ITEMTIME: element.APPOINTMENTTIME,
                    MATTERGUID: element.MATTERGUID,
                    FEEEARNER: element.FEEEARNERID,
                    APPOINTMENTGUID: element.APPOINTMENTGUID,
                    QUANTITY: element.DURATION,
                    QUANTITYTYPE: element.QUANTITYTYPESend,
                    COMMENT: element.NOTE,
                    MATTERDELIVERABLEGUID: this.MATTERDELIVERABLEGUIDdarta

                });
            });

            let details = { MultiRecord: 1, FormAction: "insert", VALIDATEONLY: true, DATA: this.SendCreateTimeEntries };
            this._mainAPiServiceService.getSetData(details, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                //array empty of save item
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    this.checkValidation(response.DATA.VALIDATIONS, details);
                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                    this.checkValidation(response.DATA.VALIDATIONS, details);
                } else if (response.CODE == 450 && response.STATUS == 'error') {
                    this.checkValidation(response.DATA.VALIDATIONS, details);
                } else if (response.MESSAGE == 'Not logged in') {

                }
            }, error => {
                this.toastr.error(error);
            });
        }

    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'NO' || value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'WARNING' || value.VALUEVALID == 'Warning') {
                warningData.push(value.ERRORDESCRIPTION);
                tempWarning[value.FIELDNAME] = value;
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);

        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {

                    this.SaveCreateDiaryData(details);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveCreateDiaryData(details);

        }
    }
    SaveCreateDiaryData(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {

                this.toastr.success('save successfully');
                this.refreshCreateDiaryEntriesab();

            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == 'Not logged in') {

            }

        }, error => {
            this.toastr.error(error);
        });
    }

    onPaginateChange(event: any) {
        this.pageSize.create_diary = event.pageSize;
        localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
    }
    selectDayRange(val) {
        this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"));
        let currentDate = new Date()
        let updatecurrentDate = new Date();
        let begin = this.datepipe.transform(currentDate, 'dd/MM/yyyy');
        if (val == "Last 7 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 7);
            this.forHideShowDateRangePicker = "hide";
            this.CreateTimeEnrtyForm.controls['Date'].setValue({ begin: updatecurrentDate, end: currentDate });
        }
        else if (val == "Today") {
            // updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = "hide";
            this.CreateTimeEnrtyForm.controls['Date'].setValue({ begin: currentDate, end: currentDate });
        }
        else if (val == "Last 30 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = "hide";
            this.CreateTimeEnrtyForm.controls['Date'].setValue({ begin: updatecurrentDate, end: currentDate });
        }
        else if (val == "Last 90 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
            this.forHideShowDateRangePicker = "hide";
            this.CreateTimeEnrtyForm.controls['Date'].setValue({ begin: updatecurrentDate, end: currentDate });
        } else if (val == "Date Range") {
            this.forHideShowDateRangePicker = "show";
        }
        let end = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
        this.CommonDatefun(begin, end);
        this.LoadData(this.filterData);

    }
    CommonDatefun(begin, end) {
        this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"));

        this.filterData.DATESTART = end;
        this.filterData.DATEEND = begin;

        localStorage.setItem('Create_DiaryEntries_filter', JSON.stringify(this.filterData));
    }
    ItemChange(val) {
        this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"));
        this.filterData.TYPEFILTER = val;
        localStorage.setItem('v', JSON.stringify(this.filterData));
        this.LoadData(this.filterData);


    }
    refreshCreateDiaryEntriesab() {
        this.filterData = JSON.parse(localStorage.getItem("Create_DiaryEntries_filter"));
        this.LoadData(this.filterData);
    }
    FilterSearch(filterValue) {
        this.TimerDataFordiary.filter = filterValue;

    }

    public selectMatterFormSearch(event) {

        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            "Action": "GetData",
            "Filters": {
                FastSearch: 'True', SearchString: event
            }
        };
        this.searchMatter = this._mainAPiServiceService.getSetData(searchobj, 'matter').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
        })

    }

    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(null);

                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }

                this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
                this.getData(data.MATTERGUID)

                this.matterChange('MatterGuid', data.MATTERGUID);
                // const array3 = this.selectedData;
                // if (array3.length < 1) {
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }

    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue('');
            this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue('');
            this.Deliverable = [];
        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };

    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        //  event.chipInput!.clear();

        this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(null);
    };

    selectedValue(event: MatAutocompleteSelectedEvent): void {
        // const array3 = this.mattername;
        // if (array3.length <1) {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            // this.mattername.push(event.option.viewValue);
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.CreateTimeEnrtyForm.controls['matterautoVal'].setValue(null);
        }
        // }
    }

    selectedmatterguid(data: any) {
        this.getData(data.MATTERGUID)
        this.CreateTimeEnrtyForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
        this.matterChange('MatterGuid', data.MATTERGUID);
        // const array3 = this.selectedData;
        // if (array3.length < 1) {
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }
        // }
    }


}
