<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title  class="title-withswitch" >{{title}} Authority
        <mat-slide-toggle  (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div> -->
        <div class="one select_matter_main_div">
            <div class="center p-24 list_tbl_main more_tbl_clmn">
                <div class="matter-serach-div sticky_search_div">
                    <!-- select bar  -->
                    <form name="SelectContact" id="SelectContact" fxLayout="column" fxLayoutAlign="start"
                        fxFlex="1 0 auto" name="form">
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                            <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.TOPICGUID,'has-warning':errorWarningData.Warning?.TOPICGUID}">
                                <mat-label>Topic</mat-label>
                                <mat-select (selectionChange)="TopicClassChange($event.value)"
                                    [(ngModel)]="authorityDialoge.TOPICNAME" name="TOPICNAME" placeholder="Topic">
                                    <mat-option *ngFor="let val of MainTopicClass" [value]="val.TOPICNAME">
                                        {{val.TOPICNAME}}</mat-option>
                                    <!-- <mat-option value="Environmental">Environmental</mat-option>
                                            <mat-option value="Limitations Peeriod">Limitations</mat-option>
                                            <mat-option value="Negligence">Negligence</mat-option>
                                            <mat-option value="Litigation">Litigation</mat-option> -->

                                </mat-select>
                             <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOPICNAME?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.TOPICNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <mat-form-field
                                [ngClass]="{'has-error':errorWarningData.Error?.AUTHORITY,'has-warning':errorWarningData.Warning?.AUTHORITY}"
                                appearance="outline" fxFlex="34" class="pr-4">
                                <mat-label>Authority</mat-label>
                                <input [(ngModel)]="authorityDialoge.AUTHORITY" name="AUTHORITY" matInput matTooltip="{{(toolTipList)?toolTipList.AUTHORITY?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                                <!-- <mat-icon matSuffix class="secondary-text"></mat-icon> -->
                             <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AUTHORITY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AUTHORITY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>


                            <mat-form-field
                                [ngClass]="{'has-error':errorWarningData.Error?.CITATION,'has-warning':errorWarningData.Warning?.CITATION}"
                                appearance="outline" fxFlex="33" class="pr-4">
                                <mat-label>Citation</mat-label>
                                <input [(ngModel)]="authorityDialoge.CITATION" name="CITATION" matInput matTooltip="{{(toolTipList)?toolTipList.CITATION?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                                <!-- <mat-icon matSuffix class="secondary-text"></mat-icon> -->
                             <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CITATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CITATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="1 0 auto">
                            <mat-form-field
                                [ngClass]="{'has-error':errorWarningData.Error?.WEBADDRESS,'has-warning':errorWarningData.Warning?.WEBADDRESS}"
                                appearance="outline" fxFlex="50" class="pr-4">
                                <mat-label>Authority Website </mat-label>
                                <input type="url" pattern="https://.*" [(ngModel)]="authorityDialoge.WEBADDRESS" matTooltip="{{(toolTipList)?toolTipList.WEBADDRESS?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"
                                    name="WEBADDRESS" matInput>
                             <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WEBADDRESS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.WEBADDRESS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <!-- <mat-icon class="iconmargin" matSuffix (click)='openDocument()'>
                                        <img src="assets/icons/web_app/icon_letters_small_d.ico"  >
                                    </mat-icon> -->
                            <mat-form-field
                                [ngClass]="{'has-error':errorWarningData.Error?.REFERENCE,'has-warning':errorWarningData.Warning?.REFERENCE}"
                                appearance="outline" fxFlex="50" class="pr-4">
                                <mat-label>Reference</mat-label>
                                <input [(ngModel)]="authorityDialoge.REFERENCE" name="REFERENCE" matInput matTooltip="{{(toolTipList)?toolTipList.REFERENCE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"> 

                             <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REFERENCE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.REFERENCE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="1 0 auto">
                            <mat-form-field
                                [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}"
                                appearance="outline" fxFlex="100" class="pr-4">
                                <mat-label>Comment</mat-label>
                                <textarea [(ngModel)]="authorityDialoge.COMMENT_" name="COMMENT_" cdkTextareaAutosize
                                    cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" matInput matTooltip="{{(toolTipList)?toolTipList.COMMENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
                             <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>

                        </div>
                    </form>
                </div>
            </div>
            <!-- <div class="example-containerdata"> 

            </div> -->

        </div>
    </div>

    <mat-dialog-actions align="end" class="popup_ftr contact_select_footer">
        <button *ngIf="action !='edit'" mat-raised-button [disabled]="isspiner" (click)="SaveAuthority()"
            color="accent">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button *ngIf="action=='edit'" mat-raised-button [disabled]="isspiner" (click)="SaveAuthority()" color="accent">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>