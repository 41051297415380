<div class=" one " [formGroup]="contactForm" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMPANYCONTACTGUIDTEXT,'has-warning':errorWarningData.Warning?.COMPANYCONTACTGUIDTEXT}">
            <mat-label>Company/Client</mat-label>
            <!-- <input matInput readonly formControlName="COMPANYCONTACTGUIDTEXT"  matTooltip="{{allToolTip.COMPANYCONTACTGUIDTEXT?.HOVER}}">
            <mat-icon matSuffix (click)='SelectContactMatter()' *ngIf="isContact">
                <img src="assets/icons/web_app/icon_contact_d.ico">
            </mat-icon>
            <mat-icon matSuffix (click)='removeContactMatter()' *ngIf="!isContact">
                <i class="material-icons icon-color">delete_forever</i>
            </mat-icon> -->
            <mat-chip-list #chipList aria-label="Matter selection">
                <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                    {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>30">...</ng-container>
                    <button  matChipRemove class="canclebutton">
                        <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                    </button>
                </mat-chip>
                <input   matInput
                    (keydown.Tab)="datashow($event)"  matTooltip="{{allToolTip.COMPANYCONTACTGUIDTEXT?.HOVER}}" 
                    formControlName="COMPANYCONTACTGUIDTEXT" #MatterInput1 [matAutocomplete]="autoMatter"
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'">
            </mat-chip-list>
            <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)">
                                <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                            </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix (click)="(this.MatterInput && this.MatterInput.nativeElement.value =='')? SelectContactMatter():null"  *ngIf="isContact"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                <img src="assets/icons/web_app/icon_contact_d.ico">
            </mat-icon>
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMPANYCONTACTGUIDTEXT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMPANYCONTACTGUIDTEXT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>

        <!-- <button mat-fab color="primary" class="add-contect-btn" *ngIf="action === 'new'" (click)="selectNewContect()">
            <mat-icon class="add-icon">add</mat-icon>
        </button> -->
        <a role="button" class="iconbtn-plus mr-4 plus-middle"  *ngIf="action === 'new'" (click)="selectNewContect()">
            <span class="mat-button-wrapper">
                <mat-icon class=''>add_circle</mat-icon>
            </span>
        </a>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-checkbox [disabled]="isdisable" formControlName="USEPARENTADDRESS">Use Company/Client Address</mat-checkbox> -->
        <mat-slide-toggle class="pb-fieldspace"    [disabled]="isdisable" formControlName="USEPARENTADDRESS">Use Company/Client Address </mat-slide-toggle>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Occupation</mat-label>
            <input matInput formControlName="OCCUPATION" matTooltip="{{allToolTip.OCCUPATION?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OCCUPATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.OCCUPATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Salutation</mat-label>
            <input matInput formControlName="SALUTATION" matTooltip="{{allToolTip.SALUTATION?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SALUTATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SALUTATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TITLE,'has-warning':errorWarningData.Warning?.TITLE}">
            <mat-label>Position</mat-label>
            <input name="POSITION" formControlName="POSITION" matInput matTooltip="{{allToolTip.POSITION?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSITION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSITION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.GIVENNAMES,'has-warning':errorWarningData.Warning?.GIVENNAMES}">
            <mat-label>First Name</mat-label>
            <input name="GIVENNAMES" formControlName="GIVENNAMES" matInput matTooltip="{{allToolTip.GIVENNAMES?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GIVENNAMES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GIVENNAMES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pl-4" [ngClass]="{'has-error':errorWarningData.Error?.MIDDLENAMES,'has-warning':errorWarningData.Warning?.MIDDLENAMES}">
            <mat-label>Middle Name</mat-label>
            <input name="MIDDLENAMES" formControlName="MIDDLENAMES" matInput matTooltip="{{allToolTip.MIDDLENAMES?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MIDDLENAMES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MIDDLENAMES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAMILYNAME,'has-warning':errorWarningData.Warning?.FAMILYNAME}">
            <mat-label>Family Name</mat-label>
            <input name="FAMILYNAME" formControlName="FAMILYNAME" matInput matTooltip="{{allToolTip.FAMILYNAME?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAMILYNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAMILYNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" [ngClass]="{'has-error':errorWarningData.Error?.NAMETITLE,'has-warning':errorWarningData.Warning?.NAMETITLE}">
            <mat-label>Title</mat-label>
            <input name="NAMETITLE" matInput [matAutocomplete]="auto" formControlName="NAMETITLE" placeholder="Choose Title">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let val of filteredOptions$ | async" [value]="val.Name">
                    {{val.Name}}
                </mat-option>
            </mat-autocomplete>

            <!-- <mat-select name="NAMETITLE" formControlName="NAMETITLE">
                <mat-option [value]="val.Name" *ngFor="let val of common">{{val.Name}}</mat-option>
            </mat-select> -->

            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NAMETITLE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NAMETITLE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pl-4" [ngClass]="{'has-error':errorWarningData.Error?.NAMELETTERS,'has-warning':errorWarningData.Warning?.NAMELETTERS}">
            <mat-label>Letters</mat-label>
            <input name="NAMELETTERS" formControlName="NAMELETTERS" matInput matTooltip="{{allToolTip.NAMELETTERS?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NAMELETTERS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NAMELETTERS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-slide-toggle  class="pb-fieldspace"    formControlName="KNOWNBYOTHERNAME" (change)="triggerSomeEvent(contactForm)">Known by Other Name</mat-slide-toggle>
        <!-- <mat-checkbox class="" name="KNOWNBYOTHERNAME" formControlName="KNOWNBYOTHERNAME"  (change)="triggerSomeEvent(contactForm)">Known by Other Name</mat-checkbox> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OTHERGIVENNAMES,'has-warning':errorWarningData.Warning?.OTHERGIVENNAMES}">
            <mat-label>Given Names</mat-label>
            <input name="OTHERGIVENNAMES" formControlName="OTHERGIVENNAMES" matInput  matTooltip="{{allToolTip.OTHERGIVENNAMES?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERGIVENNAMES?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.OTHERGIVENNAMES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pl-4" [ngClass]="{'has-error':errorWarningData.Error?.OTHERFAMILYNAME,'has-warning':errorWarningData.Warning?.OTHERFAMILYNAME}">
            <mat-label>Family Name</mat-label>
            <input name="OTHERFAMILYNAME" formControlName="OTHERFAMILYNAME" matInput  matTooltip="{{allToolTip.OTHERFAMILYNAME?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERFAMILYNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.OTHERFAMILYNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pl-4" [ngClass]="{'has-error':errorWarningData.Error?.REASONFORCHANGE,'has-warning':errorWarningData.Warning?.REASONFORCHANGE}">
            <mat-label>Reason</mat-label>
            <input name="REASONFORCHANGE" formControlName="REASONFORCHANGE" matInput  matTooltip="{{allToolTip.REASONFORCHANGE?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REASONFORCHANGE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.REASONFORCHANGE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
</div>