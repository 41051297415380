import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { CurrencyPipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, Subscription } from 'rxjs';
import { PercentPipe } from '@angular/common';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';
@Component({
    selector: 'app-debt-recovery',
    templateUrl: './debt-recovery.component.html',
    styleUrls: ['./debt-recovery.component.scss']
})
export class DebtRecoveryComponent implements OnInit, OnDestroy {
    DebtRecoveryForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    ChildtableDataSubcription: Subscription;
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private percentPipe: PercentPipe,
        public tooltipService: TooltipService
    ) {
        this.initFormData();
    }
    initFormData() {
        this.DebtRecoveryForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERDEBTRECOVERYGUID: [''],
            DEBTAMOUNT: ['',{updateOn: 'blur'}],
            AMOUNTRECOVERED: ['',{updateOn: 'blur'}],
            DEBTDUEDATE: [''],
            DEBTDUEDATETEXT: [''],
            BALANCEOWING: [''],
            INTERESTRATETYPE: [''],
            DEFAULTJUDGEMENTDATE: [''],
            DEFAULTJUDGEMENTDATETEXT: [''],
            INTERESTRATE: ['%'],
            DJINTERESTRATE: [''],
            INTERESTENDDATE: [''],
            INTERESTENDDATETEXT: [''],
            FINALPAYMENTDATE: [''],
            FINALPAYMENTDATETEXT: [''],
            INTERESTTODATE: ['',{updateOn: 'blur'}],
            INTERESTSINCEDEFAULTJUDGEMENT: ['',{updateOn: 'blur'}],
            FILINGFEES: ['',{updateOn: 'blur'}],
            ADDITIONALSERVICEFEES: ['',{updateOn: 'blur'}],
            SERVICEFEES: ['',{updateOn: 'blur'}],
            GARNISHEETOTAL: ['',{updateOn: 'blur'}],
            SOLICITORSFEES: ['',{updateOn: 'blur'}],
            TOTALDEBT: ['',{updateOn: 'blur'}],
            REVIEWDATE: [''],
            REVIEWDATETEXT: [''],
            REPAYMENTDETAILS: [''],
        });
    }


    ngOnInit() {

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.DebtRecoveryForm.controls['MATTERGUID'].setValue('')
                this.DebtRecoveryForm.controls['MATTERDEBTRECOVERYGUID'].setValue('')
                this.DebtRecoveryForm.controls['DEBTAMOUNT'].setValue('')
                this.DebtRecoveryForm.controls['AMOUNTRECOVERED'].setValue('')
                this.DebtRecoveryForm.controls['DEBTDUEDATE'].setValue('')
                this.DebtRecoveryForm.controls['DEBTDUEDATETEXT'].setValue('')
                this.DebtRecoveryForm.controls['BALANCEOWING'].setValue('')
                this.DebtRecoveryForm.controls['INTERESTRATETYPE'].setValue('')
                this.DebtRecoveryForm.controls['DEFAULTJUDGEMENTDATE'].setValue('')
                this.DebtRecoveryForm.controls['DEFAULTJUDGEMENTDATETEXT'].setValue('')
                this.DebtRecoveryForm.controls['INTERESTRATE'].setValue('')
                this.DebtRecoveryForm.controls['DJINTERESTRATE'].setValue('')
                this.DebtRecoveryForm.controls['INTERESTENDDATE'].setValue('')
                this.DebtRecoveryForm.controls['INTERESTENDDATETEXT'].setValue('')
                this.DebtRecoveryForm.controls['FINALPAYMENTDATE'].setValue('')
                this.DebtRecoveryForm.controls['FINALPAYMENTDATETEXT'].setValue('')
                this.DebtRecoveryForm.controls['INTERESTTODATE'].setValue('')
                this.DebtRecoveryForm.controls['INTERESTSINCEDEFAULTJUDGEMENT'].setValue('')
                this.DebtRecoveryForm.controls['FILINGFEES'].setValue('')
                this.DebtRecoveryForm.controls['ADDITIONALSERVICEFEES'].setValue('')
                this.DebtRecoveryForm.controls['SERVICEFEES'].setValue('')
                this.DebtRecoveryForm.controls['GARNISHEETOTAL'].setValue('')
                this.DebtRecoveryForm.controls['SOLICITORSFEES'].setValue('')
                this.DebtRecoveryForm.controls['TOTALDEBT'].setValue('')
                this.DebtRecoveryForm.controls['REVIEWDATE'].setValue('')
                this.DebtRecoveryForm.controls['REVIEWDATETEXT'].setValue('')
                this.DebtRecoveryForm.controls['REPAYMENTDETAILS'].setValue('')
            }
        })


        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.DebtRecoveryForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Debt Recovery" && result.action == 'setData') {
                    this.SaveDebtRecovery();
                } else if (result.classType == "Debt Recovery" && result.action == 'deleteData') {
                    this.DeleteDebtRecovery();
                }
            }
        });
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-debt-recovery').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterDebtRecoveryData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterDebtRecoveryData) {
                        this.DebtRecoveryForm.patchValue({
                            MATTERGUID: MatterDebtRecoveryData.MATTERGUID,
                            MATTERDEBTRECOVERYGUID: MatterDebtRecoveryData.MATTERDEBTRECOVERYGUID,
                            DEBTAMOUNT: MatterDebtRecoveryData.DEBTAMOUNT,
                            AMOUNTRECOVERED: MatterDebtRecoveryData.AMOUNTRECOVERED,
                            BALANCEOWING: MatterDebtRecoveryData.BALANCEOWING,
                            INTERESTRATETYPE: MatterDebtRecoveryData.INTERESTRATETYPE,
                            INTERESTRATE: MatterDebtRecoveryData.INTERESTRATE + '%',
                            DJINTERESTRATE: MatterDebtRecoveryData.DJINTERESTRATE + '%',
                            INTERESTTODATE: MatterDebtRecoveryData.INTERESTTODATE,
                            INTERESTSINCEDEFAULTJUDGEMENT: MatterDebtRecoveryData.INTERESTSINCEDEFAULTJUDGEMENT,
                            FILINGFEES: MatterDebtRecoveryData.FILINGFEES,
                            ADDITIONALSERVICEFEES: MatterDebtRecoveryData.ADDITIONALSERVICEFEES,
                            SERVICEFEES: MatterDebtRecoveryData.SERVICEFEES,
                            GARNISHEETOTAL: MatterDebtRecoveryData.GARNISHEETOTAL,
                            SOLICITORSFEES: MatterDebtRecoveryData.SOLICITORSFEES,
                            TOTALDEBT: MatterDebtRecoveryData.TOTALDEBT,
                            REPAYMENTDETAILS: MatterDebtRecoveryData.REPAYMENTDETAILS,
                        });
                        if (MatterDebtRecoveryData.DEBTDUEDATE) {
                            let TEMPDEBTDUEDATE = MatterDebtRecoveryData.DEBTDUEDATE.split("/");
                            this.DebtRecoveryForm.patchValue({
                                DEBTDUEDATETEXT: new Date(TEMPDEBTDUEDATE[1] + '/' + TEMPDEBTDUEDATE[0] + '/' + TEMPDEBTDUEDATE[2]),
                                DEBTDUEDATE: MatterDebtRecoveryData.DEBTDUEDATE
                            });
                        }
                        if (MatterDebtRecoveryData.DEFAULTJUDGEMENTDATE) {
                            let TEMPDEFAULTJUDGEMENTDATE = MatterDebtRecoveryData.DEFAULTJUDGEMENTDATE.split("/");
                            this.DebtRecoveryForm.patchValue({
                                DEFAULTJUDGEMENTDATETEXT: new Date(TEMPDEFAULTJUDGEMENTDATE[1] + '/' + TEMPDEFAULTJUDGEMENTDATE[0] + '/' + TEMPDEFAULTJUDGEMENTDATE[2]),
                                DEFAULTJUDGEMENTDATE: MatterDebtRecoveryData.DEFAULTJUDGEMENTDATE
                            });
                        }
                        if (MatterDebtRecoveryData.INTERESTENDDATE) {
                            let TEMPINTERESTENDDATE = MatterDebtRecoveryData.INTERESTENDDATE.split("/");
                            this.DebtRecoveryForm.patchValue({
                                INTERESTENDDATETEXT: new Date(TEMPINTERESTENDDATE[1] + '/' + TEMPINTERESTENDDATE[0] + '/' + TEMPINTERESTENDDATE[2]),
                                INTERESTENDDATE: MatterDebtRecoveryData.INTERESTENDDATE
                            });
                        }
                        if (MatterDebtRecoveryData.FINALPAYMENTDATE) {
                            let TEMPFINALPAYMENTDATE = MatterDebtRecoveryData.FINALPAYMENTDATE.split("/");
                            this.DebtRecoveryForm.patchValue({
                                FINALPAYMENTDATETEXT: new Date(TEMPFINALPAYMENTDATE[1] + '/' + TEMPFINALPAYMENTDATE[0] + '/' + TEMPFINALPAYMENTDATE[2]),
                                FINALPAYMENTDATE: MatterDebtRecoveryData.FINALPAYMENTDATE,
                            });
                        }
                        if (MatterDebtRecoveryData.REVIEWDATE) {
                            let TEMPFINALPAYMENTDATE = MatterDebtRecoveryData.REVIEWDATE.split("/");
                            this.DebtRecoveryForm.patchValue({
                                REVIEWDATETEXT: new Date(TEMPFINALPAYMENTDATE[1] + '/' + TEMPFINALPAYMENTDATE[0] + '/' + TEMPFINALPAYMENTDATE[2]),
                                REVIEWDATE: MatterDebtRecoveryData.REVIEWDATE
                            });
                        }
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }

    get f() {
        return this.DebtRecoveryForm.controls;
    }
    changeValue(event, type, name?) {
        let element = event;
        type === 'number' || 'percent' ? element = event.replace(/[^\d.]/g, '') : '';
        if (type === 'percent') {
            const percent = element ? element + '%' : 0 + '%';
            this.DebtRecoveryForm.controls[name].setValue(percent)
        }
        let data: any = { ...this.f };
        for (const property in this.f) {
            const key = property;
            const value = data[property].value;
            data[key] = value;
        }
        delete data.TOTALDEBT;
        delete data.GARNISHEETOTAL;
        delete data.REVIEWDATE;
        delete data.REPAYMENTDETAILS;
        delete data.DEBTDUEDATETEXT;
        delete data.DEFAULTJUDGEMENTDATETEXT;
        delete data.INTERESTENDDATETEXT;
        delete data.FINALPAYMENTDATETEXT;
        data.INTERESTRATE = parseFloat(data.INTERESTRATE.replace(/[^\d.]/g, ''));
        data.DJINTERESTRATE = parseFloat(data.DJINTERESTRATE.replace(/[^\d.]/g, ''));
        this._mainAPiServiceService.getSetData(data, 'CalcDebtRecoveryValues ').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.DebtRecoveryForm.controls['TOTALDEBT'].setValue(parseFloat(response.DATA.TOTALDEBT).toFixed(2));
                this.DebtRecoveryForm.controls['BALANCEOWING'].setValue(parseFloat(response.DATA.BALANCEOWING).toFixed(2));
                this.DebtRecoveryForm.controls['GARNISHEETOTAL'].setValue(parseFloat(response.DATA.GARNISHEETOTAL).toFixed(2));
                this.DebtRecoveryForm.controls['INTERESTTODATE'].setValue(parseFloat(response.DATA.INTERESTTODATE).toFixed(2));
                this.DebtRecoveryForm.controls['INTERESTSINCEDEFAULTJUDGEMENT'].setValue(parseFloat(response.DATA.INTERESTSINCEDEFAULTJUDGEMENT).toFixed(2));
            }
        })
    }

    SaveDebtRecovery() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.DebtRecoveryForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERDEBTRECOVERYGUID;
            delete formData.MATTERGUID;
        }
        delete formData.REVIEWDATETEXT;
        delete formData.FINALPAYMENTDATETEXT;
        delete formData.DEFAULTJUDGEMENTDATETEXT;
        delete formData.DEBTDUEDATETEXT;
        delete formData.INTERESTENDDATETEXT;
        formData.MATTERGUID = this.MatterData.MATTERGUID;
        const formAction = (this.action == 'edit' && formData.MATTERDEBTRECOVERYGUID) ? 'Update' : 'Insert';
        const DebtRecoveryData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(DebtRecoveryData, 'matter-debt-recovery').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, DebtRecoveryData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, DebtRecoveryData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, DebtRecoveryData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveDebtRecoveryAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveDebtRecoveryAfterVAlidation(details);

        }
    }
    SaveDebtRecoveryAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-debt-recovery').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    ReviewDateSelect(type: string, event: MatDatepickerInputEvent<Date>) {
        this.DebtRecoveryForm.controls['REVIEWDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    FinalpaymentDateSelect(type: string, event: MatDatepickerInputEvent<Date>) {
        this.DebtRecoveryForm.controls['FINALPAYMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.changeValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'), 'date');
    }
    DefaultjudgementDateSelect(type: string, event: MatDatepickerInputEvent<Date>) {
        this.DebtRecoveryForm.controls['DEFAULTJUDGEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.changeValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'), 'date');
    }
    DebtdueDateSelect(type: string, event: MatDatepickerInputEvent<Date>) {
        this.DebtRecoveryForm.controls['DEBTDUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.changeValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'), 'date');
    }
    InterestndDateSelect(type: string, event: MatDatepickerInputEvent<Date>) {
        this.DebtRecoveryForm.controls['INTERESTENDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
        this.changeValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'), 'date');
    }
    DeleteDebtRecovery() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-debt-recovery').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterDebtRecoveryData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERDEBTRECOVERYGUID: MatterDebtRecoveryData.MATTERDEBTRECOVERYGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-debt-recovery').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
}
