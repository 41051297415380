<div id="create_diary" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto">

                <form [formGroup]="CreateTimeEnrtyForm" name="CreateTimeEnrtyForm">
                    <!-- <mat-form-field class="example" appearance="outline">
                        <mat-select formControlName="DateRange" (selectionChange)="selectDayRange($event.value)" placeholder="Select Date Range">
                            <mat-option value="Today">Today</mat-option>
                            <mat-option value="Last 7 days">Last 7 days</mat-option>
                            <mat-option value="Last 30 days">Last 30 days</mat-option>
                            <mat-option value="Last 90 days">Last 90 days</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field appearance="outline" class="example dateinput" fxFlex="17.33">
                        <mat-label>Date Range</mat-label>
                        <input name="Date" formControlName="Date" matInput [satDatepicker]="picker2"
                            (dateInput)="DateRange('input', $event)" (dateChange)="DateRange1('change', $event)">
                        <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                        <mat-icon matSuffix (click)="DateRange1('clearDateRange',null)" *ngIf='hasDateRange'
                            class="icon-color-red highlight_off"> highlight_off</mat-icon>
                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline">
                        <mat-select formControlName="Item" name="Item" (selectionChange)="ItemChange($event.value)"
                            placeholder="Select Date Range">
                            <mat-option value="Billable">Billable items</mat-option>
                            <mat-option value="Non Personal">None Personal Items</mat-option>
                            <mat-option value="All">All Items</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input name="Search" (keyup)='FilterSearch($event.target.value)' matInput placeholder="Search">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </form>
            </div>


            <button mat-raised-button color="primary" type="button" id="saveCreateDiary"
                (click)="saveCreateDiary();">Refresh</button>
            <div class="content fix_div_padding">
                <!-- <div class="btn top_right_action_billing_one one">
                    <button mat-icon-button (click)="openDialog()" aria-label="More">
                      <mat-icon class="secondary-text">more_vert</mat-icon>
                   </button>
                </div> -->
                <div id="matter_invoice" class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <!-- <div class="btn top_right_action_billing_position">
                                    <button mat-icon-button (click)="openDialog()" aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                </div> -->
                                <div class="content">
                                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div class="example-containerdata resizable-table">
                                        <div class="grid-overlay" *ngIf="isDisplay">
                                            <div class="grid-overlay-msg"> There is no data to display. </div>
                                        </div>
                                        <mat-table [dataSource]="TimerDataFordiary" (matSortChange)="sortData($event)"
                                            matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}"
                                            matSortDisableClear matSort class="mat-elevation-z8">

                                            <!-- Checkbox Column -->
                                            <ng-container matColumnDef="select">
                                                <mat-header-cell *matHeaderCellDef class="checkbox_width">
                                                    
                                                    <mat-checkbox
                                                        (change)="$event ? masterToggle() : null;helloFunction();"
                                                        [checked]="selection.hasValue() && isAllSelected()"
                                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                        [aria-label]="checkboxLabel()">
                                                    </mat-checkbox>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row let index = index">
                                                   <ng-container *ngIf="(row.MATTERSHORTNAME && row.MATTERSHORTNAME !='')">
                                                    <mat-checkbox (click)="$event.stopPropagation();"
                                                        (change)="$event ? selection.toggle(row) : null;helloFunction();"
                                                        [checked]="selection.isSelected(row)"
                                                        [aria-label]="checkboxLabel(row)"
                                                        (click)="CheckboxClick();highlightedRows=index"
                                                        [style.background]="highlightedRows == index ? selectedColore : ''"
                                                        [ngClass]="highlightedRows == index ? 'row-text-colore' : ''"
                                                        (click)="Rowclick(row,index);">
                                                    </mat-checkbox>
                                                </ng-container>
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="APPOINTMENTDATE">
                                                <mat-header-cell
                                                *matHeaderCellDef="let i = index"
                                                mat-sort-header
                                                class="header_title"
                                                mwlResizable
                                                enableGhostResize="true"
                                                (resizing)="onResizing($event, 'APPOINTMENTDATE')"
                                                (resizeEnd)="onResizeEnd($event, 'APPOINTMENTDATE')"
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    right: true,
                                                    top: false,
                                                    left: true
                                                }">
                                                <div
                                                    class="desc_cell resize-handle-right "
                                                    mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>

                                                    
                                                    Appointment Date </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.APPOINTMENTDATE}}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="APPOINTMENTTIME">
                                                <mat-header-cell
                                                *matHeaderCellDef="let i = index"
                                                mat-sort-header
                                                class="header_title"
                                                mwlResizable
                                                enableGhostResize="true"
                                                (resizing)="onResizing($event, 'APPOINTMENTTIME')"
                                                (resizeEnd)="onResizeEnd($event, 'APPOINTMENTTIME')"
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    right: true,
                                                    top: false,
                                                    left: true
                                                }">
                                                <div
                                                    class="desc_cell resize-handle-right "
                                                    mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>

                                                    Appointment Time </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.APPOINTMENTTIME}}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="DURATION">
                                                <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, 'DURATION')"
                                                    (resizeEnd)="onResizeEnd($event, 'DURATION')"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>

                                                    Duration </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.DURATION}} </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="MATTERSHORTNAME">
                                                <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, 'MATTERSHORTNAME')"
                                                    (resizeEnd)="onResizeEnd($event, 'MATTERSHORTNAME')"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>

                                                    Matter Num </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.MATTERSHORTNAME}}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="APPOINTMENTTYPE">
                                                <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, 'APPOINTMENTTYPE')"
                                                    (resizeEnd)="onResizeEnd($event, 'APPOINTMENTTYPE')"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>

                                                    Appointment Type </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.APPOINTMENTTYPE}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NOTE">
                                                <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, 'NOTE')"
                                                    (resizeEnd)="onResizeEnd($event, 'NOTE')"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>

                                                    Note </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.NOTE}} </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="PRICE">
                                                <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, 'PRICE')"
                                                    (resizeEnd)="onResizeEnd($event, 'PRICE')"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>

                                                    Price </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.PRICE}} </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="PRICEINCGST">
                                                <mat-header-cell
                                                *matHeaderCellDef="let i = index"
                                                mat-sort-header
                                                class="header_title"
                                                mwlResizable
                                                enableGhostResize="true"
                                                (resizing)="onResizing($event, 'PRICEINCGST')"
                                                (resizeEnd)="onResizeEnd($event, 'PRICEINCGST')"
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    right: true,
                                                    top: false,
                                                    left: true
                                                }">
                                                <div
                                                    class="desc_cell resize-handle-right "
                                                    mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>

                                                    Price Inc GST </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.PRICEINCGST}} </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GST">
                                                <mat-header-cell
                                                *matHeaderCellDef="let i = index"
                                                mat-sort-header
                                                class="header_title"
                                                mwlResizable
                                                enableGhostResize="true"
                                                (resizing)="onResizing($event, 'GST')"
                                                (resizeEnd)="onResizeEnd($event, 'GST')"
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    right: true,
                                                    top: false,
                                                    left: true
                                                }">
                                                <div
                                                    class="desc_cell resize-handle-right "
                                                    mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>

                                                    GST </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element.GST}} </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                            </mat-header-row>
                                            <mat-row *matRowDef="let row let index = index; columns: displayedColumns;"
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}"
                                                (click)="Rowclick(row,index);highlightedRows=index"
                                                [style.background]="highlightedRows == index ? selectedColore : ''"
                                                [ngClass]="highlightedRows == index ? 'row-text-colore' : ''">
                                            </mat-row>
                                        </mat-table>
                                    </div>
                                    <mat-paginator [pageSizeOptions]="[10, 20,50,100]"
                                        [pageSize]="pageSize?.create_diary" (page)="onPaginateChange($event)"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="matter-serach-div sticky_search_div responsive_auto bottom_class main_form_div_create_time">
                <div class="create_time_diary-loading" *ngIf="isLoading_another">
                    <mat-spinner [style.zoom]="0.5" *ngIf="isLoading_another"></mat-spinner>
                </div>
                <form class="form-create_time_diary" [formGroup]="CreateTimeEnrtyForm" name="CreateTimeEnrtyForm"
                    id="CreateTimeEnrtyForm">
                    <div class="matter-icon_issue">
                        <!-- <mat-form-field
                            [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}"
                            appearance="outline" class="pr-4 matter-field_diary">
                            <mat-label>Matter</mat-label>
                            <input (focusout)="tabMatter()" matInput placeholder="Matter"
                                formControlName="matterautoVal">
                        </mat-form-field>
                        <mat-icon class="matter_icon_timeDiary" matSuffix (click)='selectMatter()'>
                            <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="select matter"
                                matTooltipPosition="above">
                        </mat-icon> -->

                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}"
                        appearance="outline" class="pr-4 matter-field_diary">
                            <mat-label>Matters</mat-label>
                            <mat-chip-list #chipList aria-label="Matter selection">
                                <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                    {{matterdata}}
                                    <button  matChipRemove class="canclebutton">
                                        <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input matInput type="search"
                                    (keydown.Tab)="datashow($event)"
                                    formControlName="matterautoVal" #MatterInput1 [matAutocomplete]="autoMatter"
                                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)" (keyup)='selectMatterFormSearch(MatterInput1?.value)' [ngClass]="mattername.length > 0?'hideInput':'showInput'">
                            </mat-chip-list>
                            <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                                <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                    <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-icon class="matter_icon_timeDiary" matSuffix (click)='selectMatter()'>
                            <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="select matter"
                                matTooltipPosition="above">
                        </mat-icon>
                    </div>
                    <!-- item date -->
                    <mat-form-field appearance="outline" class="pr-4" appearance="outline">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date"
                            (dateInput)="QuickDate('input', $event)" (dateChange)="QuickDate('change', $event)"
                            formControlName="ITEMDATETEXT">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pr-4">
                        <mat-label>Time of day</mat-label>
                        <mat-select (selectionChange)="timeChange()" formControlName="ITEMTIME">
                            <mat-option *ngFor="let data of timeStops" value="{{data}}">{{data}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="pr-4"  *ngIf=" '' | productType" appearance="outline">
                        <mat-label>Fee Earner</mat-label>
                        <mat-select formControlName="FEEEARNER"
                            (selectionChange)="matterChange('FeeEarner',$event.value)">
                            <mat-option *ngFor="let user of userList" value="{{user.USERID}}">{{user.FULLNAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pr-4">
                        <mat-label>Duration</mat-label>
                        <input matInput formControlName="QUANTITY" (focusout)="matterChange('Quantity',$event.value)">
                        <mat-icon matSuffix class="secondary-text">quantity</mat-icon>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="pr-4">
                    <mat-label>Will Your Firm Use Deliverables?</mat-label>
                    <mat-select  #value>
                        *ngFor="let val of getDropDownValue.UNITSPERHOUR" value="{{val.VALUE}}">{{val.DESCRIPTION}}
                        <mat-option value="No">No</mat-option>
                        <mat-option value="Mandatory">Mandatory</mat-option>
                        <mat-option value="Optional">Optional</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <mat-form-field appearance="outline"  class="pr-4" *ngIf="DerivableDropShow==false && currentUser?.PRODUCTTYPE!='Barrister' &&  ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1" >
                    <mat-label>Deliverable</mat-label>
                    <!-- [disabled] = " _data.edit == 'Add'" -->                    
                        <mat-select formControlName="MATTERDELIVERABLEGUID"  (selectionChange)="radioChange($event.value)"  >
                            <mat-option *ngFor="let data of Deliverable" [value]="data.MATTERDELIVERABLEGUID">{{data.DELIVERABLEDESCRIPTION}}</mat-option>
                            <!-- <mat-option value="Mandatory">Mandatory</mat-option>
                            <mat-option value="Optional">Optional</mat-option> -->

                        </mat-select>               
                </mat-form-field>
                    <mat-form-field class="pr-4" appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="QUANTITYTYPE"
                            (selectionChange)="quntityTypeChange('QuantityType',$event.value)">
                            <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">
                                {{Activity.DESCRIPTION}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pr-4">
                        <mat-label>Price (Ex GST)</mat-label>
                        <input currencyMask type="text" matInput formControlName="PRICE" (blur)="calcPE()">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="pr-4">
                        <mat-label>Price (Inc GST)</mat-label>
                        <input type="text" currencyMask matInput formControlName="PRICEINCGST" (blur)="calcPI()">
                    </mat-form-field>
                    <mat-form-field class="pr-4 invoice_text-diary-create" appearance="outline">
                        <mat-label>Invoice Text</mat-label>
                        <textarea type="text" placeholder="Invoice Text" aria-label="Number" matInput
                            formControlName="ADDITIONALTEXT" [matAutocomplete]="auto"></textarea>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="LookupsChange($event.option.value)">
                            <mat-option *ngFor="let Lookups of  filteredOptions | async"
                                [value]="Lookups.LOOKUPFULLVALUE">{{Lookups.LOOKUPFULLVALUE}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button mat-raised-button color="primary" type="button" id="refreshCreateDiaryEntriesab"
                        (click)="refreshCreateDiaryEntriesab();">Refresh</button>
                </form>
            </div>
        </div>
    </div>
</div>