import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit , OnDestroy {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  BasicDetail: any = {
    LEGALENTITY: '', REGISTRATIONNAME: ' ', FIRSTNAME: ' ', MIDDLENAME: '', LASTNAME: '', ADDRESS: '', SUBURB: '', ADDRESSSTATE: '', POSTCODE: '',
    PHONE: '', MOBILE: '', EMAIL: '', ACCOUNTSEMAIL: '', MANAGEREMAIL: '', TECHNICALEMAIL: '', REGISTEREDUNTIL: '', INTROPRICEUNTIL: ''
  }
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  errorWarningData: any = { "Error": [], 'Warning': [] };
  searchAddress : Subscription;
  searchData : any = [];
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  subscription: Subscription;
  private searchTerms = new Subject<string>();
  constructor(public MatDialog: MatDialog, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService,
    private behaviorService: BehaviorService) { 
    localStorage.setItem('istrackid', 'AccMangBasicInfoComponent');
     this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
        if (localStorage.getItem('istrackid') == 'AccMangBasicInfoComponent' && result['click'] && result['data']['HOGetCustomerDetails']) {
          this.ngOnInit();
        }
      });
    }

    @ViewChild('addressInput3', { read: MatAutocompleteTrigger,static:true})
    dropremove3: MatAutocompleteTrigger;

       /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {

    setTimeout(() => {
      let content =document.querySelector('.dropdown2')
      const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
      scroll$.subscribe((res) => {
        this.dropremove3.closePanel();
    })
    }, 50);
    
    this.isLoadingResults = true;
    this.sub2 = this._mainAPiServiceService.getSetData({}, 'HOGetCustomerDetails').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.BasicDetail.REGISTRATIONNAME = response.DATA.CUSTOMERDATA.REGISTRATIONGROUP.REGISTRATIONNAME;
        this.BasicDetail.FIRSTNAME = response.DATA.CUSTOMERDATA.NAMEGROUP.FIRSTNAME;
        this.BasicDetail.MIDDLENAME = response.DATA.CUSTOMERDATA.NAMEGROUP.MIDDLENAME;
        this.BasicDetail.LASTNAME = response.DATA.CUSTOMERDATA.NAMEGROUP.LASTNAME;
        this.BasicDetail.LEGALENTITY = response.DATA.CUSTOMERDATA.LEGALENTITY;
        this.BasicDetail.ADDRESS = response.DATA.CUSTOMERDATA.ADDRESSGROUP.ADDRESS;
        this.BasicDetail.SUBURB = response.DATA.CUSTOMERDATA.ADDRESSGROUP.SUBURB;
        this.BasicDetail.ADDRESSSTATE = response.DATA.CUSTOMERDATA.ADDRESSGROUP.ADDRESSSTATE;
        this.BasicDetail.POSTCODE = response.DATA.CUSTOMERDATA.ADDRESSGROUP.POSTCODE;
        this.BasicDetail.PHONE = response.DATA.CUSTOMERDATA.PHONE;
        this.BasicDetail.MOBILE = response.DATA.CUSTOMERDATA.MOBILE;
        this.BasicDetail.EMAIL = response.DATA.CUSTOMERDATA.EMAILS.EMAIL;
        this.BasicDetail.ACCOUNTSEMAIL = response.DATA.CUSTOMERDATA.EMAILS.ACCOUNTSEMAIL;
        this.BasicDetail.MANAGEREMAIL = response.DATA.CUSTOMERDATA.EMAILS.MANAGEREMAIL;
        this.BasicDetail.TECHNICALEMAIL = response.DATA.CUSTOMERDATA.EMAILS.TECHNICALEMAIL;
        this.BasicDetail.REGISTEREDUNTIL = response.DATA.CUSTOMERDATA.REGISTRATIONGROUP.REGISTEREDUNTIL;
        this.BasicDetail.INTROPRICEUNTIL = response.DATA.CUSTOMERDATA.INTROPRICEUNTIL;
        this.BasicDetail.NAME = response.DATA.CUSTOMERDATA.NAMEGROUP.NAME;
        this.behaviorService.loadingAccountMNG('basic-info');
      } else {
        this.behaviorService.loadingAccountMNG('basic-info');
      }
    });

    this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.autoFillUp(term)) // Switch to new observable on new term
    ).subscribe(response => {
      // this.searchResult = response.RESPONSE.results;
        this.searchData = response.DATA.SUGGESTIONS;
    });
  }
  
  // This function is used to Register the changes
  RegisteredUntilChange(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.matterdetailForm.controls['EXCHANGEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  // This function is used to save the basic details information
  saveBasicDetail() {
    this.isspiner = true;
    delete this.BasicDetail.REGISTEREDUNTIL;
    delete this.BasicDetail.INTROPRICEUNTIL;
    let PostBasicDetail: any = { FormAction: 'update', VALIDATEONLY: true, Data: this.BasicDetail };
    this.sub3 = this._mainAPiServiceService.getSetData(PostBasicDetail, 'HOSetCustomerDetails').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.checkValidation(res.DATA.VALIDATIONS, PostBasicDetail);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.checkValidation(res.DATA.VALIDATIONS, PostBasicDetail);
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.checkValidation(res.DATA.VALIDATIONS, PostBasicDetail);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  // This function is used to check the validation data.
  checkValidation(bodyData: any, PostBasicDetail: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true, width: '100%', data: warningData
      });
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveBasicDetailData(PostBasicDetail);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveBasicDetailData(PostBasicDetail);
    }
  }

  /**
   * This function is used to save the basic Details data.
   * @param PostActivityData -post active data.
   */
  saveBasicDetailData(PostActivityData: any) {
    PostActivityData.VALIDATEONLY = false;
    this.sub4 = this._mainAPiServiceService.getSetData(PostActivityData, 'HOSetCustomerDetails').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success('Update successfully');
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(res.STATUS);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.error(res.STATUS);
        this.isspiner = false;
      }
      this.isspiner = false;
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  ngOnDestroy(): void {
    this.searchAddress?.unsubscribe();
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.sub3?.unsubscribe();
    this.sub4?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  onKeyUp(event: any): void {
    const searchValue = event.target.value;
    this.searchTerms.next(searchValue);
  }

/**
 * This function is used to auto fill text
 */
  autoFillUp(address){ 
    let addressData = {
      "Action": "GetAddressMatch",
      "Data": {
        "Address": address
      }
    };
    return this._mainAPiServiceService.getSetData(addressData, 'utility')
  }

  /**
   * This function is used to set the auto field.
   * @param data -data value
   * @param index -index number
   */
  setAutoField(data , index){
    let COMBINEDFIRST=this.searchData[index].COMBINED.split(",")[0];
    let BLANKDISC =data.COMBINED.split(",")[1]; 
    setTimeout(() => {
      this.BasicDetail.ADDRESS= COMBINEDFIRST
    },0 );
    this.BasicDetail.ADDRESSSTATE = this.searchData[index].STATE
    this.BasicDetail.POSTCODE = this.searchData[index].POSTALCODE
    this.BasicDetail.SUBURB = this.searchData[index].DISTRICT ||BLANKDISC
  }
}
