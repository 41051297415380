<div id="templates" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
    <div fxLayoutAlign="center" class="content">
        <div class="center p-010 more_tbl_clmn template-list list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div" id="filters">
                <form [formGroup]="TemplateForm">
                    <mat-form-field class="search" appearance="outline" fxFlex="25">
                        <mat-label>Matter</mat-label>
                        <input matInput placeholder="Matter" formControlName="matter" readonly>
                        <mat-icon matSuffix (click)='DcoumentFloder()'>
                            <img src="assets/icons/web_app/icon_matter_d.ico">
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field class="search" appearance="outline" fxFlex="25">
                        <mat-label>Client</mat-label>
                        <input matInput placeholder="Client" formControlName="Client">
                        <mat-icon matSuffix (click)='SelectClient()'  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field class="search" appearance="outline" fxFlex="25">
                        <mat-label>Search</mat-label>
                        <input matInput placeholder="search" formControlName="search" (keydown)="onSearch($event)">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <span fxFlex="25" style="padding-top: 30px;">
                        Folder: <strong>{{showFolder}}</strong> <a class="appCues-blankSpace" href="javascript:void(0);">Link</a>
                    </span>
                    <button mat-menu-item (click)="refreshTemplatelistTab()" id="refreshTemplatelistTab"> Reload Data</button>
                </form>
            </div>
            <!-- <div class="content"> -->
            <div class="content raidus-0" appFileDragNDrop (filesChangeEmiter)="onFilesChange($event)">
                <div class="example-loading-shade" *ngIf="isLoadingResults">
                    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                </div>
                <div class="example-containerdata resizable-table">
                    <!-- <div class="btn top_right_action_billing_position matter-window">
                        <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                        <mat-menu #contextMenu="matMenu">
                            <ng-template matMenuContent let-item="item">
                                <button mat-menu-item (click)="refreshTemplatelistTab()"> Reload Data</button>
                            </ng-template>
                        </mat-menu>
                    </div> -->
                    <div class="grid-overlay" *ngIf="isDisplay">
                        <div class="grid-overlay-msg nodata-table"> <h2>There is no data to display.</h2> </div>
                    </div>
                    <mat-table [dataSource]="Templatedata" (matSortChange)="sortData($event)" matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear [@animateStagger]="{value:'50'}" matSort class="mattempletelist">
                        <ng-container matColumnDef="TEMPLATETYPE">
                            <!-- <mat-header-cell *matHeaderCellDef mwlResizable  (resizeEnd)="onResizeEnd($event, 'TEMPLATETYPE')" mwlResizeHandle
                            [resizeEdges]="{ right: true }">
                                <div class="resize-handle-right" mwlResizeHandle
                                [resizeEdges]="{ right: true }"></div>
                            </mat-header-cell> -->
                            <mat-cell *matCellDef="let row">
                                <img *ngIf="row.TEMPLATETYPE =='Template'" class="img-matter-popup" src="assets/icons/web_app/icon_template_d.ico" matTooltip="" matTooltipPosition="above">
                                <img *ngIf="row.TEMPLATETYPE =='Folder'" class="img-matter-popup" src="assets/icons/web_app/folder.ico">
                                <img *ngIf="row.TEMPLATETYPE =='Go_Back'" class="img-matter-popup" src="assets/icons/web_app/folderup.ico">
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="TEMPLATENAME">
                            <!-- <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable  (resizeEnd)="onResizeEnd($event, 'TEMPLATENAME')" mwlResizeHandle
                            [resizeEdges]="{ right: true }">
                                <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                                Template name
                            </mat-header-cell> -->
                            <mat-cell *matCellDef="let row"> {{row.TEMPLATENAME }} </mat-cell>
                        </ng-container>
                        <!-- <mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true">
                        </mat-header-row> -->
                        <mat-row [style.background]="highlightedRows == row.TEMPLATENAME ? selectedColore : ''" [ngClass]="highlightedRows == row.TEMPLATENAME ? 'row-text-colore' : ''" highlightedRows=row.TEMPLATENAME *matRowDef="let row; columns: displayedColumns;" (click)="clickRow(row);highlightedRows = row.TEMPLATENAME "
                            (dblclick)="doubleClick(row,'true')" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                        </mat-row>
                    </mat-table>
                </div>
                <!-- <mat-paginator [pageSizeOptions]="[1000, 2000,3000,4000]" [pageSize]="pageSize?.template_list" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator> -->
            </div>
            <div class="upload_doscument_main" *ngIf="fileList.length>0">
                <div class="upload_doc_header">
                    <div class="left_title_div">
                        <span>{{fileList.length}} upload complete</span>
                    </div>
                    <div class="right_btn_div">
                        <button type="button" (click)="isClose = !isClose" mat-button class="upload_action_btn">
                                <mat-icon *ngIf="isClose">expand_more</mat-icon>
                                <mat-icon *ngIf="!isClose">expand_less</mat-icon>
                            </button>
                        <button type="button" (click)="closePopup();" mat-button class="upload_action_btn">
                                <mat-icon>close</mat-icon>
                            </button>
                    </div>
                </div>
                <div *ngIf="isClose">
                    <div class="upload_doc_body" *ngFor="let file of fileList">
                        <div class="left_doc_name">
                            <!-- <img src="assets/icons/web_app/img_icon.png"> -->
                            <span fileList>{{file.name}}</span>
                        </div>
                        <div class="right_icon_div">
                            <button type="button" mat-button class="upload_action_btn">
                                    <mat-icon>check_circle</mat-icon>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>