import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settlment-council',
  templateUrl: './settlment-council.component.html',
  styleUrls: ['./settlment-council.component.scss']
})
export class SettlmentCouncilComponent implements OnInit {

  @Input() matterAddressForm: FormGroup;
  @Input() errorWarningData: any;
  constructor() { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
