import { MainAPiServiceService } from '@_services/main-api-service.service';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-cutofdate',
  templateUrl: './cutofdate.component.html',
  styleUrls: ['./cutofdate.component.scss']
})
export class CutofdateComponent implements OnInit, OnDestroy {
  lastDayOfPeriod: any = '';
  isLoadingResults: boolean = false;
  dataTreeTable: any = [];
  isJournalEntry: boolean = false;
  lastDate: any;
  closePeriodItem: any = [];
  isspiner: boolean = false;
  errorWarningData: any = {};
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(public datepipe: DatePipe,
    public MatDialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    public dialogRef: MatDialogRef<CutofdateComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any, private toastr: ToastrService) { }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to choosed the Date.
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>):void {
    this.lastDayOfPeriod = this.datepipe.transform(event.value, 'dd/MM/yyyy');
   // this.loadData();
  }

  /**
   * This function is used to load the data
   */
  loadData():void {
    this.isLoadingResults = true;
    this.sub = this._mainAPiServiceService.getSetData({
      FormAction: "default",  
      VALIDATEONLY: true,
      DATA: {
        PERIODENDDATE: this.lastDayOfPeriod,
        PERIODTYPE:"WORKITEMCUTOFF",
      }
    }, 'SetClosePeriod').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.dataTreeTable = response.DATA.DEFAULTVALUES.CURRENTBALANCEITEMS;
        this.closePeriodItem = response.DATA.DEFAULTVALUES.CLOSEPERIODITEMS;
        this.lastDate = response.DATA.DEFAULTVALUES.LASTCLOSEDDATE;
      } else if (response.MESSAGE == 'Not logged in') {
      }
      this.isLoadingResults = false;
    }, err => {
      this.isLoadingResults = false;
    });
  }

  /**
   * This functionis used to get the end date value
   */
  PeriodEndDatePeriodEndDate():void {
    this.isspiner = true;

    let finalPassdata: any = { FormAction: 'insert', VALIDATEONLY: true, DATA: { PERIODENDDATE: this.lastDayOfPeriod,PERIODTYPE:"WORKITEMCUTOFF" } };
    this.sub1 = this._mainAPiServiceService.getSetData(finalPassdata, 'SetClosePeriod').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.checkValidation(res.DATA.VALIDATIONS, finalPassdata);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.checkValidation(res.DATA.VALIDATIONS, finalPassdata);
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.checkValidation(res.DATA.VALIDATIONS, finalPassdata);
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
        this.isspiner = false;
      }else{
        this.isspiner = false;
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to check the validation data value
   */
  checkValidation(bodyData: any, PostClosePeriodData: any):void {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        warningData.push(value.ERRORDESCRIPTION);
        tempWarning[value.FIELDNAME] = value;
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
    //   this.toastr.warning(warningData);
    this.isspiner = false;
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.FinalsaveClosePeriod(PostClosePeriodData);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.FinalsaveClosePeriod(PostClosePeriodData);

    }
  }

  /**
   * This function is used to save the final close period
   */
  FinalsaveClosePeriod(PostClosePeriodData: any):void {
    PostClosePeriodData.VALIDATEONLY = false;
    this.sub2 = this._mainAPiServiceService.getSetData(PostClosePeriodData, 'SetClosePeriod').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.isspiner=false;
        this.toastr.success('Cutoff date saved successfully"');
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.warning(response.MESSAGE);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }else{
        this.isspiner = false;
      }
    }, error => {
      this.isspiner=false;
      this.toastr.error(error);
    });


  }
  

}
