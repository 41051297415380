import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatterPopupComponent } from '../../matters/matter-popup/matter-popup.component';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TimersService } from '@_services/timers.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';

export const FAVORITES_FIRST = true;
export const FAVORITES_ALSO_SORTED = true;
@Component({
    selector: 'app-matter-dialog',
    templateUrl: './matter-dialog.component.html',
    styleUrls: ['./matter-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MatterDialogComponent implements OnInit {
    appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
    displayedColumns: string[] = ['Favourite','SHORTNAME', 'MATTER', 'CONTACTNAME'];
    getDataForTable: any = [];
    selection = new SelectionModel<any>(true, []);
    highlightedRows: any;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    matterFilterForm: FormGroup;
    theme_type = localStorage.getItem('theme_type');
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    isLoadingResults: boolean = false;
    pageSize: any;
    disableDrop: any = false;
    currentMatterData: any;
    MatterDropData: any;
    isSearchString: any;
    filterVal: any = { 'Active': '', 'FeeEarner': '', 'SearchString': '' };
    isAccountMulti: any;
    UserFilter: any = '';
    sortactive: any = "SHORTNAME";
    sortDirection: any = "asc";
    //use for tier permission
    accessTierPermission: any = JSON.parse(localStorage.getItem('set_tier_permission'));
    sortingDefaultState: any = JSON.parse(localStorage.getItem('select_matter_screen_sorting'));
    MattersData: any[];
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        private Timersservice: TimersService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        if (_data && _data.multi == 'Multi') {
            this.displayedColumns.unshift('select');
        }
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (_data && _data.UserFilter != '') {
            this.UserFilter = _data.UserFilter;
            this.filterVal = { 'Active': '', 'FeeEarner': this.UserFilter, 'SearchString': '' };
        }
        this.filterVal.FeeEarner = currentUser.LOADALLUSERSMATTERS ? 'all' : (currentUser.WORKINGONBEHALFOFGUID) ? currentUser.WORKINGONBEHALFOFGUID : currentUser.UserId;
        this.UserFilter = currentUser.LOADALLUSERSMATTERS ? 'all' : (currentUser.WORKINGONBEHALFOFGUID) ? currentUser.WORKINGONBEHALFOFGUID : currentUser.UserId;
        localStorage.setItem('istrackid', 'SelectMatterPopup');
        this.behaviorService.matterSerachFromQuickTimeEntry$.subscribe((result) => {
            if (result) {
                this.isSearchString = result.SHORTNAME;
            } else {
                this.isSearchString = '';
            }
        });
        this.matterFilterForm = this.fb.group({ MatterFilter: [''], UserFilter: [this.UserFilter], searchFilter: [''], InvoiceFilter: [''], });
        this.matterFilterForm.controls['MatterFilter'].setValue('active');
        this.matterFilterForm.controls['searchFilter'].setValue(this.isSearchString);
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'SelectMatterPopup' && result['click'] && result['data']['matter']) {
                this.ngOnInit();
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "SHORTNAME";
            this.sortDirection = "asc";
        }
        if (this.appPermissions == null)
            this.appPermissions = [];
        this.getDropValue();
        this.getMatterList();
    }

    /**
     * This function is used to Sort the data
     */
    sortData(val) {
        localStorage.setItem('select_matter_screen_sorting', JSON.stringify(val))
        this.sortactive = val.active;
        this.sortDirection = val.direction;
        this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
    }

    /**
     * This function is used to get the Drop value
     */
    getDropValue() {
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.MatterDropData = this.Timersservice.removeUSerWithoutId(userList);
        } else {
            let d = {};
            this.Timersservice.GetUsers(d).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.MatterDropData = this.Timersservice.removeUSerWithoutId(response.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(response.DATA.USERS));
                }
            }, err => {
                console.log(err);
            });
        }
    }

    /**
     * this function is used to select the Matter Id
     */
    selectMatterId(Row: any) {
        this.currentMatterData = Row;
        let URLAFTERSLIT: any = this.router.url.split("/");
        let UrlArray = ["", "matters", "time-billing", "legal-details", "document-register", "create-document"];
        if (!UrlArray.includes(URLAFTERSLIT[1])) {
            this.behaviorService.MatterData(this.currentMatterData);
        }
    }

    /**
     * This function is used to get the Matter list 
     */
    getMatterList() {
        this.filterVal.Active = 'active';
        this.filterVal.SearchString = this.isSearchString;
        if (this._data && this._data.isTrustTrans) {
            this.filterVal.Active = 'active';
            this.disableDrop = true;
        }
        this.getList(this.filterVal);
        this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
    }
    
    get f() {
        return this.matterFilterForm.controls;
    }

    /**
     * This function is used to Change the Matter
     */
    MatterChange(value) {
        this.filterVal.Active = value;
        this.getList(this.filterVal);
    }

    /**
     * This function is used to search the data value 
     */
    onSearch(searchFilter: any) {
        if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
            this.filterVal.SearchString = this.f.searchFilter.value;
            this.getList(this.filterVal);
        }
    }

    /**
     * This function is used to Matter User Changes data value
     */
    MatterUserChange(value) {
        this.filterVal.FeeEarner = value;
        this.getList(this.filterVal);
    }

    /**
     * This function is used to get the list of data
     */
    getList(filterVal: any) {
        this.isLoadingResults = true;
        // filterVal.GETALLFIELDS = true;

        let Payload: any = {
            "Action": "GetData",
        };

        Payload.Filters = filterVal;
        this._mainAPiServiceService.getSetData(Payload, 'matter').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.MattersData=[];
                if (response.DATA.RECORDS[0]) {
                    // this.highlightedRows = response.DATA.RECORDS[0].MATTERGUID;
                    // this.currentMatterData = response.DATA.RECORDS[0];
                    // this.selectMatterId(response.DATA.RECORDS[0]);
                } else {
                    this.currentMatterData = '';
                }
                this.MattersData=response.DATA.RECORDS
                this.getDataForTable = new MatTableDataSource(response.DATA.RECORDS);
                this.getDataForTable.paginator = this.paginator;
               // this.getDataForTable.sort = this.sort;
                this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
                this.isLoadingResults = false;
            }

        }, error => {
            this.toastr.error(error);
        });
        this.behaviorService.matterSerachFromQuickTimeEntry(null);
    }

    /**
     * This function is used to onPaginate Change
     */
    onPaginateChange(event) {
        this.pageSize.matter_select = event.pageSize;
        localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
    }
    
    /**
     * This function is used to Add the new matter popup
     */
    AddNewmatterpopup() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.dialog.open(MatterPopupComponent, { width: '100%', disableClose: true, data: { action: 'new' } });
        dialogRef.afterClosed().subscribe(result => { });
    }
    
    /**
     * This function is used to Edit the New Matter popup
     */
    EditNewmatterpopup() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.dialog.open(MatterPopupComponent, {
            width: '100%', disableClose: true, data: { action: 'edit', 'matterGuid': this.currentMatterData.MATTERGUID }
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    /**
     * This unction is used to isAll selected data value
     */
    isAllSelected() {
        const numSelected = this.selection.selected ? this.selection.selected.length : 0;
        const numRows = this.getDataForTable.data ? this.getDataForTable.data.length : 0;
        return numSelected === numRows;
    }

/**
 * This function is used to make the Master toggle data value
 */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.getDataForTable.data.forEach(row => this.selection.select(row));
    }

    /**
     * This function is used to set the check box lebal
     */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    /**
     * This function is used to get the permission
     */
    getPermission(permission) {
        return this.accessTierPermission.filter(e => e.FEATURENAME == permission)[0];
    }

    /**
     * This Function is used to get the Favourite Matters
     * @param row -matter data
     */
    getFavouritelist(row){
        this.isLoadingResults = true;   
        this._mainAPiServiceService.getSetData({Action: "setfavourite",DATA:{ MATTERGUID:row.MATTERGUID,FAVOURITE:(row.FAVOURITE == 0 )? true : false} }, "matter").subscribe(data=>{
        if(data.CODE==200){
            this.isLoadingResults = false;
         this.MattersData.forEach(element => {
            if(row.MATTERGUID == element.MATTERGUID){
              element.FAVOURITE = (element.FAVOURITE ==1) ? 0 : 1
            }
            });

        this.getDataForTable = new MatTableDataSource(this.MattersData);
        this.getDataForTable.paginator = this.paginator;
        this.onSortChanged({ active: this.sortactive, direction: this.sortDirection });
        // this.getList(this.filterVal);
        }
        })
    }

    /**
     * This function is used to OnSort change the data
     */
    onSortChanged(sort) {
        this.sort = sort;
        const matters = this.getDataForTable.data.slice();
        const column = sort.active;
        const isAsc = sort.direction === 'asc';
        //this.SortingbehaviorService.matterScreenSorting(sort);
        //this.setDefaultWidth(this.displayedColumns, 0);
        this.sortIt(column, isAsc, matters);
    }

    /**
     * This function is used to Sort the Data value
     */
    sortIt(property: string, isAsc: boolean, matters): void {
        matters.sort((a, b) => {
          if (FAVORITES_FIRST) {
            if (a.FAVOURITE && b.FAVOURITE) {
              return this.sortFavorites(property, isAsc, a, b);
            }
            // if only one favorite, the favorite is on top, no matter asc or desc
            if (a.FAVOURITE === 1) {
              return -1;
            } else if (b.FAVOURITE === 1) {
              return 1;
            }
          }
    
          return this.stdSort(property, isAsc, a, b);
        });
    
        this.getDataForTable = new MatTableDataSource(matters);
        this.getDataForTable.paginator = this.paginator;  
          
    }

    /**
     * This function is used to sort the favourite data value
     */
    private sortFavorites(
        property: string,
        isAsc: boolean,
        a,
        b
      ): number {
        if (FAVORITES_ALSO_SORTED) {
          return this.stdSort(property, isAsc, a, b);
        }
        return 0;
      }

      /**
       * This function is used to sort the data value
       */
    stdSort(property: string, isAsc: boolean, a, b) {
        switch (property) {
            case property:
                return compare(a[property], b[property], isAsc);
            default:
                return 0;
        }
    }

}

function compare(
    a: number | string,
    b: number | string,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }