import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settlment-parent-title',
  templateUrl: './settlment-parent-title.component.html',
  styleUrls: ['./settlment-parent-title.component.scss']
})
export class SettlmentParentTitleComponent implements OnInit {
  @Input() matterAddressForm: FormGroup;
  @Input() errorWarningData: any;
  constructor() { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
