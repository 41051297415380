import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
    MatterData: any = {};
    ExportProcessForm: FormGroup
    errorWarningData: any = {};
    statusData: any;
    @Input() action: any;
    @Input() classType: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    ChildtableDataSubcription: Subscription;
    TrainingForm: FormGroup
    confirmDialogRef: any;
    private _matDialog: any;
    constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService  , public datepipe: DatePipe, private behaviorService: BehaviorService, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService, private _formBuilder: FormBuilder) {
        this.initFormData()
    }

    ngOnInit(): void {
        this.ChildtableDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.TrainingForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Training" && result.action == 'setData') {
                    this.SaveMediationData();
                } else if (result.classType == "Training" && result.action == 'deleteData') {
                    this.DeleteMortageFinance();
                }
            }
        });

        if (this.action === 'edit') {
            this.mapMortgageFinDataToFields();
        }

        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == true) {
                this.mapMortgageFinDataToFields();
            }
        })

    }

    /**
     * This function is used to ngOnDestroy
     */
    ngOnDestroy() {
        this.ChildtableDataSubcription.unsubscribe();
    }

    /**
     * This function is used to init Form data value
     */
    initFormData() {
        this.TrainingForm = this._formBuilder.group({
            MATTERGUID: [''],
            TRAININGDATE: [''],
            TRAININGNUMDAYS: [''],
            TRAININGTOPIC: [''],
            TRAININGCONTRACTSIGNEDDATE: [''],
            MATTERTRAININGGUID: [''],
            TRAININGDATETEXT: [''],
            TRAININGCONTRACTSIGNEDDATETEXT: ['']
        });
    }

    /**
     * This function is used to mapMortgageFindData To the Filelds
     */
    mapMortgageFinDataToFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-training').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterMortageFinanceData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterMortageFinanceData) {
                        this.TrainingForm.patchValue({
                            MATTERGUID: MatterMortageFinanceData.MATTERGUID,
                            TRAININGDATE: MatterMortageFinanceData.TRAININGDATE,
                            TRAININGNUMDAYS: MatterMortageFinanceData.TRAININGNUMDAYS,
                            TRAININGTOPIC: MatterMortageFinanceData.TRAININGTOPIC,
                            TRAININGCONTRACTSIGNEDDATE: MatterMortageFinanceData.TRAININGCONTRACTSIGNEDDATE,
                            MATTERTRAININGGUID: MatterMortageFinanceData.MATTERTRAININGGUID,
                        });

                        if (MatterMortageFinanceData.TRAININGDATE) {
                            let TEMPMORTAGEFINANCECOMMENCEMENTDATE = MatterMortageFinanceData.TRAININGDATE.split("/");
                            this.TrainingForm.patchValue({
                                TRAININGDATETEXT: new Date(TEMPMORTAGEFINANCECOMMENCEMENTDATE[1] + '/' + TEMPMORTAGEFINANCECOMMENCEMENTDATE[0] + '/' + TEMPMORTAGEFINANCECOMMENCEMENTDATE[2]),
                                TRAININGDATE: MatterMortageFinanceData.TRAININGDATE
                            });
                        }
                        if (MatterMortageFinanceData.TRAININGCONTRACTSIGNEDDATE) {
                            let TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE = MatterMortageFinanceData.TRAININGCONTRACTSIGNEDDATE.split("/");
                            this.TrainingForm.patchValue({
                                TRAININGCONTRACTSIGNEDDATETEXT: new Date(TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[1] + '/' + TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[0] + '/' + TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[2]),
                                TRAININGCONTRACTSIGNEDDATE: MatterMortageFinanceData.TRAININGCONTRACTSIGNEDDATE
                            });
                        }

                    }
                }
            });
        }
    }



/**
 * This function is ised to save the madiation
 */
    SaveMediationData() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.TrainingForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERTRAININGGUID;
        }
        delete formData.TRAININGDATETEXT;
        delete formData.TRAININGCONTRACTSIGNEDDATETEXT;


        const formAction = (formData.MATTERTRAININGGUID) ? 'Update' : 'Insert';
        const FamilyLawData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(FamilyLawData, 'matter-training').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation
     * @param bodyData -body details data
     * @param details -Data Details
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMortageFinanceAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMortageFinanceAfterVAlidation(details);

        }
    }

    /**
     * This function is used to Save mortage Finance After validation
     * @param data -data validation
     */
    SaveMortageFinanceAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-training').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**This function is used to delete the finance */
    DeleteMortageFinance() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-training').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterMortageFinanceData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERTRAININGGUID: MatterMortageFinanceData.MATTERTRAININGGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-training').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

/**
 * This function is used to get the date
 * @param type -type of the date
 * @param event -event of the date
 */
    CommencementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.TrainingForm.controls['TRAININGDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to Get the Expirty Date 
     * @param type -type
     * @param event -evenet data
     */
    ExpirtyDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.TrainingForm.controls['TRAININGCONTRACTSIGNEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

}
