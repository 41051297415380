import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { GloballySafeCustodyComponent } from './globally-safecustody.component';
import { MainSafeCustodyComponent } from './main-safe-custody/main-safe-custody.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { PacketsComponent } from './packets/packets.component';
import { PacketsDialogComponent } from './packets/packets-dialog/packets-dialog.component';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { SafeCustodyDetailsComponent } from './main-safe-custody/safe-custody-details/safe-custody-details.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';


// const routes = [
//   { path: '', component: MainSafeCustodyComponent, canActivate: [AuthGuard] },

// ];


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
  { path: '', redirectTo: '/full-Safe-Custody/Safe-Custody', pathMatch: 'full' ,component: MainSafeCustodyComponent},
  {
    path: 'full-Safe-Custody/Safe-Custody', component: MainSafeCustodyComponent, data: {
      title: 'Safe Custody'
    }, canActivate: [AuthGuard]
  },
  {
    path: 'full-Safe-Custody/packets', component: PacketsComponent, data: {
      title: 'Packets'
    }, canActivate: [AuthGuard]
  },
];
@NgModule({
  declarations: [GloballySafeCustodyComponent, MainSafeCustodyComponent, PacketsComponent, PacketsDialogComponent, SafeCustodyDetailsComponent],
  entryComponents: [PacketsDialogComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDividerModule,

    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    SatDatepickerModule, SatNativeDateModule,

    MatCheckboxModule,
    DragDropModule,
    MatSortModule,
    ApplicationPipesModule,
    ResizableModule,
    MatChipsModule,
    MatRadioModule,
    NumberMaskModule

  ],
  exports: [
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class GloballySafeCustodyModule { }
