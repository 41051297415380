import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from 'src/@fuse/animations';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { ContactDialogComponent } from '../contact-dialog.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactSelectDialogComponent } from '../../contact-select-dialog/contact-select-dialog.component';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  animations: fuseAnimations
})
export class PeopleComponent implements OnInit {
  appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
  @Input() errorWarningData: any;
  @Input() contactForm: FormGroup;
  @Input() action: any;
  isspiner: boolean = false;
  isspiner2: boolean = false;
  isDisplay: boolean = false;
  contectData: any = [];
  displayedColumns: string[] = ['CONTACTNAME', 'PHONE', 'OCCUPATION', 'SALUTATION', 'MOBILE', 'EMAIL', 'NOTES'];
  dataSource: any = [];
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean = false;
  highlightedRows: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  currentContactData: any = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Output() contactDetail: EventEmitter<any> = new EventEmitter<any>();
  parentContactGuid: any = '';
  ChildContactTableDataSubcription: Subscription;
  ChildContactTableData: any = '';
  currentContactDataindex: any = 0;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    public _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.ChildContactTableDataSubcription = this.behaviorService.ChildContactTableData$.subscribe(result => {
      if (result) {
        this.ChildContactTableData = result;
        // this.MatterCriminalForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
        if ((result.contactType == "Company" || result.contactType == "Multi Person") && result.action == 'setData') {
          this.parentContactGuid = result.CONTACTGUID;
          this.saveAssociation();
        } else if ((result.contactType == "Company" || result.contactType == "Multi Person") && result.action == 'deleteData') {
          this.parentContactGuid = result.CONTACTGUID;
          this.DeleteContactParent(); //Without confirmation boxx
        }
      }
    });
    if (this.appPermissions == null) {
      this.appPermissions = [];
    }
    if (this.action == "edit") {
      this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
    }
    if (this.f.CONTACTTYPE.value == "Multi Person" && this.action == "new") {
      this.AddContactsDialog();
    }
  }
  get f() {
    return this.contactForm.controls;
  }

  /**
   * This function is used to edit the contact.
   * @param Row -selected contact row
   * @param index -index number 
   */
  editContact(Row: any, index) {
    this.currentContactDataindex = index
    this.currentContactData = Row;
  }

  /**
   * This function is used to load the Contect data
   * @param postData -post data value
   */
  loadContectData(postData) {
    this.isLoadingResults = true;
    this.currentContactData = '';
    this._mainAPiServiceService.getSetData(postData, 'contact-parent').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.dataSource = new MatTableDataSource(response.DATA.RECORDS);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (response.DATA.RECORDS[0]) {
          this.isDisplay = false;
          this.currentContactData = response.DATA.RECORDS[0];
          this.highlightedRows = response.DATA.RECORDS[0].CONTACTPARENTGUID;
        } else {
          this.isDisplay = true;
        }
        this.isLoadingResults = false;
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to Add contact dialog.
   */
  AddContactsDialog() {
    const dialogRef = this.dialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { default: "Person" } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.action == "new" || this.action == "duplicate") {
          this.contectData.push(result);
          this.highlightedRows = this.contectData[0].CONTACTGUID;
          this.currentContactData = this.contectData[0];
          this.dataSource = new MatTableDataSource(this.contectData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.isspiner = true;
          let details = { Action: 'Insert', VALIDATEONLY: false, Data: { PARENTGUID: localStorage.getItem('contactGuid'), CONTACTGUID: result.CONTACTGUID } };
          this._mainAPiServiceService.getSetData(details, 'contact-parent').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
              this.toastr.success('Contact Link successfully');
              this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
              this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
              this.toastr.error(response.MESSAGE);
            }
            this.isspiner = false;
          }, error => {
            this.toastr.error(error);
            this.isspiner = false;
          });
        }
      }
    });
  }

  /**
   * This function is used to Save Association
   */
  saveAssociation() {
    this.contectData.forEach(item => {
      this.isspiner = true;
      let details = { Action: 'Insert', VALIDATEONLY: false, Data: { PARENTGUID: this.parentContactGuid, CONTACTGUID: item.CONTACTGUID } };
      this._mainAPiServiceService.getSetData(details, 'contact-parent').subscribe(response => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.toastr.success('Company Linked successfully');
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.toastr.warning(response.MESSAGE);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.toastr.error(response.MESSAGE);
        }
        this.isspiner = false;
      }, error => {
        this.toastr.error(error);
        this.isspiner = false;
      });
    });
  }

/**
 * This function is used to delete Contact 
 */
  deleteContact(): void {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%' });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Unlink?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isspiner2 = true;
        if (this.action == "new" || this.action == "duplicate") {
          this.contectData.splice(this.currentContactDataindex, 1);
          this.currentContactData = '';
          if (this.contectData[0]) {
            this.highlightedRows = this.contectData[0].CONTACTGUID;
            this.currentContactData = this.contectData[0];
          }
          this.currentContactDataindex = 0;
          this.dataSource = new MatTableDataSource(this.contectData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isspiner2 = false;

        } else {
          let details = { Action: "Delete", Data: { CONTACTPARENTGUID: this.highlightedRows } };
          this._mainAPiServiceService.getSetData(details, 'contact-parent').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
              this.toastr.success('Contact UnLink successfully');
              this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
              this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
              this.toastr.error(response.MESSAGE);
            }
            this.isspiner2 = false;
          }, error => {
            this.toastr.error(error);
            this.isspiner2 = false;
          });
        }
      }
      this.confirmDialogRef = null;
    });
  }

/**
 *This function is  used to edit the Contact 
 * @param CONTACTGUID -contact Guid value
 */
  EditContactsDialog(CONTACTGUID: any) {
    if (!this.currentContactData) {
      this.toastr.error("Please Select Company");
    } else {
      const dialogRef = this.dialog.open(ContactDialogComponent, { disableClose: true, data: { action: 'edit', 'CONTACTGUID': CONTACTGUID } });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.action == "new" || this.action == "duplicate") {
            this.isLoadingResults = true;
            this._mainAPiServiceService.getSetData({ "ACTION": "GetData","FILTERS": { CONTACTGUID: CONTACTGUID } }, 'contact').subscribe(res => {
              if (res.MESSAGE == 'Not logged in') {
              } else {
                if (res.DATA.RECORDS[0]) {
                  this.contectData[this.currentContactDataindex] = res.DATA.RECORDS[0];
                  if (this.contectData[0]) {
                    this.highlightedRows = this.contectData[this.currentContactDataindex].CONTACTGUID;
                    this.currentContactData = this.contectData[this.currentContactDataindex];
                  }
                  this.dataSource = new MatTableDataSource(this.contectData);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                  this.isLoadingResults = false;

                } else {
                  this.toastr.error("Can't Find Contact Details");
                }
              }
            });

          } else {
            this.loadContectData({ Action: "GetData", Filters: { PARENTGUID: localStorage.getItem('contactGuid') } });

          }
        }
      });
    }
  }

  /**
   * This function is used to Delete the contact parent
   */
  DeleteContactParent() {
    this.isspiner2 = true;
    let details = { Action: "Delete", Data: { PARENTGUID: this.parentContactGuid } };
    this._mainAPiServiceService.getSetData(details, 'contact-parent').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success('Contact UnLink successfully');
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
      }
      this.isspiner2 = false;
    }, error => {
      this.toastr.error(error);
      this.isspiner2 = false;
    });
  }
  ngOnDestroy() {
    this.ChildContactTableDataSubcription?.unsubscribe();
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }
}