<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_report_main_eom add_top_clients">
    <h2 cdkDragHandle mat-dialog-title class="popup_title_div">
        <span *ngIf="!isGenrateReport"> Reports Generated </span>
        <span *ngIf="isGenrateReport">Generating Reports</span>
        <button class="btn" mat-icon-button aria-label="Close dialog" [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="one iner-scroll-class" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" style="width: 100%;" *ngIf="!isEndOfMonthData">
            <div fxLayout="row" *ngFor="let item of reportList; let index = index" class="popup_body document_generated_main_div" style="width: 100%;">
                <div fxFlex="65" *ngIf="item.isGeenrate" class="document_generated_main_first" fxLayoutAlign="start center">
                    <div>The {{item?.TITILE}} report has been produced.</div>
                </div>
                <div fxFlex="65" *ngIf="!item.isGeenrate" class="document_generated_main_first" class="button" fxLayoutAlign="start center">
                    <div>Generating Report {{item?.TITILE}}.</div>
                </div>
                <div fxFlex="35" *ngIf="!item.isGeenrate" class="document_generated_main_last" class="button">
                    <button mat-raised-button color="accent" [disabled]="item?.isButtonEnable" (click)="ReportGenerate(item.ID,reportDataDefult.ENDOFMONTHGUID,index)">
                        <!-- <mat-spinner [style.zoom]="0.5" *ngIf="!item.isGeenrate"></mat-spinner> -->
                        Generate {{item?.TITILE}}
                    </button>
                </div>
                <div fxFlex="35" *ngIf="item.isGeenrate" class="document_generated_main_last" fxLayoutAlign="start center" class="button">
                    <a href="{{item.URL}}" target="_blank">
                        <button mat-raised-button color="accent">Click here to view it</button>
                    </a>
                </div>
            </div>
            <div class="popup_body document_generated_main_div" *ngIf="isGenrateReport" style="width: 100%;margin-top: 37px;">
                <div style="position: relative;z-index: 99999;width: 100%;">
                    <h3><strong>{{title}}</strong> is being generated, please give it a moment. </h3><br>
                    <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
                    <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
                </div>
            </div>
            <div class="popup_body document_generated_main_div" *ngIf="isGenrateReport" style="width: 100%;">
                <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3>{{btnText}}</h3>
                </div>
            </div>
        </div>

        <div class="one iner-scroll-class" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" style="width: 100%;" *ngIf="isEndOfMonthData">
            <div class="iner-scroll-class">
                <div fxLayout="row" *ngFor="let item of processEndOfMonthData; let index = index" class="popup_body document_generated_main_div" style="width: 100%;">
                    <div fxFlex="55" *ngIf="item.isGeenrate" class="document_generated_main_first" fxLayoutAlign="start center">
                        <div>The {{item?.SHORTDESC }} report has been produced.</div>
                    </div>
                    <div fxFlex="65" *ngIf="!item.isGeenrate" class="document_generated_main_first" class="button" fxLayoutAlign="start center">
                        <div>Generating Report {{item?.SHORTDESC }}.</div>
                    </div>
                    <div fxFlex="35" *ngIf="!item.isGeenrate" class="document_generated_main_last" class="button">
                        <button mat-raised-button color="accent" [disabled]="item?.isButtonEnable">
                        <!-- <mat-spinner [style.zoom]="0.5" *ngIf="!item.isGeenrate"></mat-spinner> -->
                        Report Generating... 
                    </button>
                    </div>
                    <div fxFlex="45" *ngIf="item.isGeenrate" class="document_generated_main_last" fxLayoutAlign="start center" class="button">
                        <a href="{{item?.URL}}" target="_blank">
                            <button mat-raised-button color="accent">Click here to view it </button>
                        </a>

                        <button mat-raised-button color="accent" class="emailIt-btn"  (click)="GenerateEmail(item)">Click here to email it</button>
                    </div>
                    
                </div>
            </div>
            <div class="popup_body document_generated_main_div process_eom_data" *ngIf="isGenrateReport" style="width: 100%;margin-top: 37px;">
                <div style="position: relative;z-index: 99999;width: 100%;">
                    <h3><strong>{{title}}</strong> is being generated, please give it a moment. </h3><br>
                    <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
                    <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
                </div>
            </div>
            <div class="popup_body document_generated_main_div" *ngIf="isGenrateReport" style="width: 100%;">
                <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3>{{btnText}}</h3>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>