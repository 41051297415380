<!-- <div class="page">
    <div class="header">Angular sample</div>
    <div #viewer class="viewer"></div>
  </div> -->
  <!-- 766vh -->
<!-- <ng-container *ngIf="iframeUrl">
  <iframe [src]="iframeUrl" width="1000%" height="880vh"></iframe>
</ng-container> -->
<div class="example-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_matter_main_doc" cdkDrag>
  <h2 cdkDragHandle mat-dialog-title class="title-withswitch">Edit Template {{showFileName}}
    <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
  </h2>
  <div mat-dialog-content class="mat-typography main">
    <div class="control-section">
      <!-- <div>
        <button mat-button id='export'(click)="onClick(this)">Import</button>
      </div> -->
      <!-- <div id="default_title_bar" class="e-de-ctn-title"></div> -->
      <!-- height: 500px; -->
      <div class="col-lg-2 list-disp property-section" style="padding-right: inherit;padding-left: 5px;border-left: 1px solid rgb(238, 238, 238);border-bottom:  1px solid rgb(238, 238, 238) ;"> 
        <!-- <h3 style="display:block;padding-top: 5px; font-size: 13px !important;">Select Field to Insert</h3> -->
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="search-wrap tp">
          <mat-form-field appearance="outline" class="pr-4 inputfield">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search field here" [(ngModel)]="searchText" (keydown.enter)="onSearchChange()">
            <mat-icon matSuffix class="secondary-text"></mat-icon>
          </mat-form-field>
          <button mat-raised-button color="accent" class="mg mat-accent" (click)="onSearchChange()" [disabled]="isspinerSearch">
            <mat-spinner *ngIf="isspinerSearch"></mat-spinner>Search</button>
        </div>
        <div class="field-scroll">
          <mat-spinner *ngIf="isspinerSearch"></mat-spinner>
                    <!-- <ejs-listview #listview id='listview' [dataSource]='filteredFields' (select)="onSelect($event)" allowDragAndDrop='true' [showHeader]='true' [headerTitle]='headertitle' [fields]="fields"></ejs-listview> -->

          <ejs-listview #listview id='listview' [dataSource]='filteredFields' (select)="onSelect($event)" allowDragAndDrop='true' [showHeader]='true' [fields]="fields"></ejs-listview>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="search-wrap">
          <mat-form-field appearance="outline" class="pr-4">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search field here" [(ngModel)]="searchText" (ngModelChange)="onSearchChange()">
                <mat-icon matSuffix class="secondary-text"></mat-icon>
            </mat-form-field>
          </div> -->
      </div>
      <!-- control-section -->
      <!-- docu-disp -->
      <div class="col-lg-10 control-section-sub doc-alignment">
        <!-- <div> -->
          <ejs-documenteditorcontainer [documentEditorSettings]="settings" #documenteditor_default [enableToolbar]=true [locale]="culture" (created)="onCreate()" (documentChange)="onDocumentChange()" height="700px" [serviceUrl]="hostUrl" (destroyed)="onDestroy()" style="display:block;"></ejs-documenteditorcontainer>
        <!-- </div> -->
      </div>
      <div class="overlay" id="popup-overlay" style="display: none;"></div>
      <div id='waiting-popup' style="display: none;">
        <svg class="circular" height="40" width="40">
          <circle class="circle-path" cx="25" cy="25" r="20" fill="none" stroke-width="6" stroke-miterlimit="10" />
        </svg>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="popup_ftr">
    <button mat-raised-button color="accent" class="mat-accent" [disabled]="isspiner" (click)="saveAsBlob()">
        <mat-spinner *ngIf="isspiner"></mat-spinner>Save
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
  </mat-dialog-actions>
</div>

<!-- <div class="control-section">
  <div>
     <div id='default_title_bar' class="e-de-ctn-title"></div>
  <div class="col-lg-2 property-section" style="padding-right: inherit;padding-left: 5px;height: 600px;border-left: 1px solid rgb(238, 238, 238);border-bottom:  1px solid rgb(238, 238, 238) ;"> 
      <h5><label style="display:block;margin:1px;padding-top: 5px">Select Field to Insert</label></h5>
      <div>
        <ejs-listview id='listview' [dataSource]='listData' (select)="onSelect($event)" allowDragAndDrop='true' [fields]="fields"></ejs-listview>
      </div>
    </div>
  
     
      <div class="col-lg-10 control-section"  style='padding-left: 0px;padding-right: 0px; padding-top: 0px;' >
        <div>
          <ejs-documenteditorcontainer #documenteditor_default id="container" [enableToolbar]=true [locale]="culture" (created)="onCreate()"  (documentChange)="onDocumentChange()" (toolbarClick)="toolbarClick($event)" [serviceUrl]="hostUrl"  height="600px" style="display:block" ></ejs-documenteditorcontainer>
        </div>
      </div>
        <div class="overlay" id="popup-overlay" style="display: none;"></div>
  <div id='waiting-popup' style="display: none;">
      <svg class="circular" height="40" width="40">
          <circle class="circle-path" cx="25" cy="25" r="20" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
  </div>
      
  </div>
</div> -->