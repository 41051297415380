<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_rename_main">
    <h2 cdkDragHandle mat-dialog-title>{{titleText}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <div fxLayout="column" fxFlex="1 0 auto" name="form"
                *ngIf="type!='rename'">
                <div class="pr-4" fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto" *ngIf="(type!='rename' && !isTryagin)">
                    <h3>
                        <strong>
                            These documents have been added to the document
                            register.
                        </strong>
                    </h3>
                </div>
                <div *ngIf="isLoadingResults"
                    style="position: relative;z-index: 99999;">
                    <h3 *ngIf="filefolder_Name"><strong>{{filefolder_Name}}</strong>
                        is being generated, please give it a moment. </h3><br>
                    <mat-progress-bar style="height: 2em;" mode="determinate"
                        [value]="progress"></mat-progress-bar>
                    <span style="position: absolute; top: 2.2em;"
                        [ngStyle]="{'left': progress + '%'}"></span>
                    <!-- {{progress}}% -->
                </div>
                <div class="pr-4" fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                    *ngIf="(FileData.length<=0 && type!='rename' && !btnText)">
                    <strong> Documents Not found</strong>
                </div>
                <div class="pr-4" fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                    *ngIf="(FileData.length<=0 && type!='rename' && !isTryagin)">
                    <h3> {{btnText}} </h3>
                </div>
                <div class="pr-4" fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto"
                    *ngIf="(FileData.length<=0 && type!='rename' && isTryagin)">
                    <div fxFlex="70" class="middle" fxLayoutAlign="start">
                        <span>
                            In order to Load Documents this document, please
                            login to document storage account (i.e.
                            Dropbox,
                            OneDrive, Sharepoint or Google Drive). Once
                            completed, please close the pop-up and generate
                            the document again. (You will only need to do this
                            once during this session).
                        </span>
                    </div>
                    <div fxFlex="30">
                        <button mat-raised-button color="accent"
                            class="mat-accent" selectMatter
                            (click)="loadDocument()">
                            Click Here to try again
                        </button>
                    </div>
                </div>
                <div class="pr-4" fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto" *ngFor="let Item of FileData">
                    <div fxFlex="100" style="padding: 5px;" class="middle"
                        fxLayoutAlign="start start">
                        <span>{{Item.NAME}}</span>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="1 0 auto" name="form"
                *ngIf="type=='rename'">
                <span fxFlex="50"><strong> {{documentData.DESCRIPTION}}</strong></span>
                <br>
                <mat-form-field class="example pr-4" fxFlex="50"
                    appearance="outline" *ngIf="isPopup">
                    <mat-label>Old Filename</mat-label>
                    <input matInput readonly
                        [(ngModel)]="documentData.ORIGFILENAMEONLY">
                </mat-form-field>
                <mat-form-field class="example pr-4" fxFlex="50"
                    appearance="outline" *ngIf="!isPopup">
                    <mat-label>Old Filename</mat-label>
                    <input matInput readonly
                        [(ngModel)]="documentData.DOCUMENTNAME">
                </mat-form-field>
                <mat-form-field class="example pr-4" fxFlex="50"
                    appearance="outline">
                    <mat-label>New Filename</mat-label>
                    <input matInput [(ngModel)]="documentData.NEWFILENAME">
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <ejs-dropdowntree #renamepopupdropdowntree
                        [treeSettings]="treeSettings" (created)="created()"
                        (select)="FloderChnage($event)" showClearButton='true'
                        showDropDownIcon='false' id='renamepopupdropdowntree'
                        [fields]='fields' placeholder='Select a Folder'
                        floatLabelType='Auto'></ejs-dropdowntree>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end" class="popup_ftr">
            <button mat-raised-button color="primary" [mat-dialog-close]="false"
                cdkFocusInitial>Cancel</button>
            <button mat-raised-button color="accent" class="mat-accent"
                *ngIf="type=='rename'" cdkFocusInitial (click)="RenameFile()">Save</button>
        </mat-dialog-actions>
    </div>
