<!-- <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div> -->
<div cdkDrag class="popup_main_div add_edit_budgets_main">
    <h2 cdkDragHandle mat-dialog-title>
        {{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form id="userBudget" [formGroup]="userBudget" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PERIODSTART,'has-warning':errorWarningData.Warning?.PERIODSTART}">
                        <mat-label>Date</mat-label>
                        <input (ngModelChange)="NowDate($event)" formControlName="PERIODSTARTTEXT" matInput [matDatepicker]="picker3">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ratehr,'has-warning':errorWarningData.Warning?.ratehr}">
                        <mat-label>Rate /Hr</mat-label>
                        <input matInput currencyMask formControlName="ratehr" readonly>
                        <mat-icon matSuffix class="secondary-text">ratehr</mat-icon>
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ratehr,'has-warning':errorWarningData.Warning?.ratehr}">
                        <mat-label>Rate /Hr</mat-label>
                        <mat-select formControlName="ratehr">
                            <mat-option *ngFor="let val of rateid" [value]="val.LOOKUPGUID">{{val.LOOKUPFULLVALUE}}</mat-option>
                        
                        </mat-select>
                    </mat-form-field> -->

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TOTALBUDGETHOURS,'has-warning':errorWarningData.Warning?.TOTALBUDGETHOURS}">
                        <mat-label>Yearly Hours</mat-label>
                        <input class="sideText" (focusout)="hoursOnChange()" formControlName="TOTALBUDGETHOURS" matInput>
                        <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TOTALBUDGETDOLLARS,'has-warning':errorWarningData.Warning?.TOTALBUDGETDOLLARS}">
                        <mat-label>Yearly $(Ex-GST)</mat-label>
                        <input [value]="userBudget.get('TOTALBUDGETDOLLARS').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" class="sideText" (focusout)="dollaresOnCahneg()" formControlName="TOTALBUDGETDOLLARS" matInput>
                        <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
                    </mat-form-field>
                </div>
                <div *ngFor="let val of Months; index as i" of fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div fxFlex="33" class="pr-4">{{val | date:'MMM d , y' }}</div>
                    <mat-form-field appearance="outline" fxFlex="" class="pr-4">
                        <mat-label>{{val | date:'MMM' }} hours</mat-label>
                        <input type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" class="sideText" formControlName="MONTHBUDGETHOURS_{{i+1}}" matInput [value]="getMonthlyVal(i) | number: '1.2-2'">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="" class="pr-4">
                        <mat-label>{{val | date:'MMM' }} rate</mat-label>
                        <input [value]="getYearlyVal(i) | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" class="sideText" formControlName="MONTHBUDGETDOLLARS_{{i+1}}" matInput>
                        <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="saveBudget()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="saveBudget()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>`