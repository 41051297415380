<div class=" one " fxLayout="column" [formGroup]="MortageFinanceForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRINCIPALADVANCED,'has-warning':errorWarningData.Warning?.PRINCIPALADVANCED}">
            <mat-label>Principal Advanced</mat-label>
            <input matInput type="number" matTooltip="{{(toolTipList)?toolTipList.PRINCIPALADVANCED?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PRINCIPALADVANCED">
              <div  *ngIf="testBool && toolTipList.PRINCIPALADVANCED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PRINCIPALADVANCED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRINCIPALADVANCED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.INTERESTRATE,'has-warning':errorWarningData.Warning?.INTERESTRATE}">
            <mat-label>Interest Rate</mat-label>
            <input matInput style="text-align: right;" [value]="MortageFinanceForm.get('INTERESTRATE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.INTERESTRATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="INTERESTRATE" [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.INTERESTRATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INTERESTRATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTRATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOLIOIDENTIFIER,'has-warning':errorWarningData.Warning?.FOLIOIDENTIFIER}">
            <mat-label>Folio Identifier</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.COURT:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FOLIOIDENTIFIER">
              <div  *ngIf="testBool && toolTipList.INTERESTRATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELFLAG.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELFLAG?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMMENCEMENTDATE,'has-warning':errorWarningData.Warning?.COMMENCEMENTDATE}">
            <mat-label>Commencement Date</mat-label>
            <input (dateInput)="CommencementDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.COMMENCEMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COMMENCEMENTDATETEXTM" (dateChange)="CommencementDateClick('change', $event)" matInput [matDatepicker]="CommencementDate">
            <mat-datepicker-toggle matSuffix [for]="CommencementDate"></mat-datepicker-toggle>
            <mat-datepicker #CommencementDate></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.COMMENCEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COMMENCEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENCEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ExpirationDate,'has-warning':errorWarningData.Warning?.ExpirationDate}">
            <mat-label>Expirty Date</mat-label>
            <input (dateInput)="ExpirtyDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.EXPIRATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ExpirationDateTxtM" (dateChange)="ExpirtyDateClick('change', $event)" matInput [matDatepicker]="ExpirtyDate">
            <mat-datepicker-toggle matSuffix [for]="ExpirtyDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpirtyDate></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.EXPIRATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXPIRATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPIRATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DISCHARGEDATE,'has-warning':errorWarningData.Warning?.DISCHARGEDATE}">
            <mat-label>Disacharge Date</mat-label>
            <input (dateInput)="DisachargeDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.DISCHARGEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DISCHARGEDATETEXTM" (dateChange)="DisachargeDateClick('change', $event)" matInput [matDatepicker]="DisachargeDate">
            <mat-datepicker-toggle matSuffix [for]="DisachargeDate"></mat-datepicker-toggle>
            <mat-datepicker #DisachargeDate></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.DISCHARGEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DISCHARGEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DISCHARGEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SECURITYPROPERTY,'has-warning':errorWarningData.Warning?.SECURITYPROPERTY}">
            <mat-label>Security Property</mat-label>
            <textarea name="SecurityProperty" matTooltip="{{(toolTipList)?toolTipList.SECURITYPROPERTY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SECURITYPROPERTY" matInput></textarea>
              <div  *ngIf="testBool && toolTipList.SECURITYPROPERTY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SECURITYPROPERTY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SECURITYPROPERTY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>