import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-family',
    templateUrl: './family.component.html',
    styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit, OnDestroy {
    matterFamliydetailForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    @Input() classType: any;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        public tooltipService: TooltipService
    ) {
        this.initFormData();
        // this.behaviorService.SaveFamilyLawDetailFlag$.subscribe((data)=>{
        //   if(data) {
        //     this.SaveFamilyLaw();
        //   }
        // });
    }

    initFormData() {
        this.matterFamliydetailForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERFAMILYLAWGUID: [''],
            COHABITATIONDATE: [''],
            COHABITATIONDATETEXT: [''],
            MARRIAGEDATE: [''],
            MARRIAGEDATETEXT: [''],
            MARRIAGEPLACE: [''],
            MARRIAGECOUNTRY: [''],
            SEPARATIONDATE: [''],
            SEPARATIONDATETEXT: [''],
            DATEFILEDFORDIVORCE: [''],
            DATEFILEDFORDIVORCETEXT: [''],
            DIVORCEDATE: [''],
            DIVORCEDATETEXT: [''],
            DIVORCEPLACE: [''],
            DIVORCECOUNTRY: [''],
            NUMDEPENDANTS: [''],
            FAMILYCOURTCLIENTID: [''],
            EXPIRATIONDATE: [''],
            ExpirationDatetextF: ['']
        });
    }
    ngOnInit() {

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.matterFamliydetailForm.controls['MATTERGUID'].setValue('')
                this.matterFamliydetailForm.controls['MATTERFAMILYLAWGUID'].setValue('')
                this.matterFamliydetailForm.controls['COHABITATIONDATE'].setValue('')
                this.matterFamliydetailForm.controls['COHABITATIONDATETEXT'].setValue('')
                this.matterFamliydetailForm.controls['MARRIAGEDATE'].setValue('')
                this.matterFamliydetailForm.controls['MARRIAGEDATETEXT'].setValue('')
                this.matterFamliydetailForm.controls['MARRIAGEPLACE'].setValue('')
                this.matterFamliydetailForm.controls['MARRIAGECOUNTRY'].setValue('')
                this.matterFamliydetailForm.controls['SEPARATIONDATE'].setValue('')
                this.matterFamliydetailForm.controls['SEPARATIONDATETEXT'].setValue('')
                this.matterFamliydetailForm.controls['DATEFILEDFORDIVORCE'].setValue('')
                this.matterFamliydetailForm.controls['DATEFILEDFORDIVORCETEXT'].setValue('')
                this.matterFamliydetailForm.controls['DIVORCEDATE'].setValue('')
                this.matterFamliydetailForm.controls['DIVORCEDATETEXT'].setValue('')
                this.matterFamliydetailForm.controls['DIVORCEPLACE'].setValue('')
                this.matterFamliydetailForm.controls['DIVORCECOUNTRY'].setValue('')
                this.matterFamliydetailForm.controls['NUMDEPENDANTS'].setValue('')
                this.matterFamliydetailForm.controls['FAMILYCOURTCLIENTID'].setValue('')
                this.matterFamliydetailForm.controls['EXPIRATIONDATE'].setValue('')
                this.matterFamliydetailForm.controls['ExpirationDatetextF'].setValue('')
            }
        })

        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.matterFamliydetailForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if ((result.classType == "Family Law" || result.classType == "Domestic Violence") && result.action == 'setData') {

                    this.SaveFamilyLaw();
                } else if ((result.classType == "Family Law" || result.classType == "Domestic Violence") && result.action == 'deleteData') {
                    this.DeleteFamilyRecovery();
                }
            }
        });
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-family-law').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterFamilyLawData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterFamilyLawData) {
                        this.matterFamliydetailForm.patchValue({
                            MATTERGUID: MatterFamilyLawData.MATTERGUID,
                            MATTERFAMILYLAWGUID: MatterFamilyLawData.MATTERFAMILYLAWGUID,
                            COHABITATIONDATE: MatterFamilyLawData.COHABITATIONDATE,
                            MARRIAGEDATE: MatterFamilyLawData.MARRIAGEDATE,
                            MARRIAGEPLACE: MatterFamilyLawData.MARRIAGEPLACE,
                            MARRIAGECOUNTRY: MatterFamilyLawData.MARRIAGECOUNTRY,
                            SEPARATIONDATE: MatterFamilyLawData.SEPARATIONDATE,
                            DATEFILEDFORDIVORCE: MatterFamilyLawData.DATEFILEDFORDIVORCE,
                            DIVORCEDATE: MatterFamilyLawData.DIVORCEDATE,
                            DIVORCEPLACE: MatterFamilyLawData.DIVORCEPLACE,
                            DIVORCECOUNTRY: MatterFamilyLawData.DIVORCECOUNTRY,
                            NUMDEPENDANTS: MatterFamilyLawData.NUMDEPENDANTS,
                            FAMILYCOURTCLIENTID: MatterFamilyLawData.FAMILYCOURTCLIENTID,
                            EXPIRATIONDATE: MatterFamilyLawData.EXPIRATIONDATE
                        });

                        if (MatterFamilyLawData.COHABITATIONDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterFamilyLawData.COHABITATIONDATE.split("/");
                            this.matterFamliydetailForm.patchValue({
                                COHABITATIONDATETEXT: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                COHABITATIONDATE: MatterFamilyLawData.COHABITATIONDATE
                            });
                        }
                        if (MatterFamilyLawData.MARRIAGEDATE) {
                            let TEMPDEFAULTMARRIAGEDATE = MatterFamilyLawData.MARRIAGEDATE.split("/");
                            this.matterFamliydetailForm.patchValue({
                                MARRIAGEDATETEXT: new Date(TEMPDEFAULTMARRIAGEDATE[1] + '/' + TEMPDEFAULTMARRIAGEDATE[0] + '/' + TEMPDEFAULTMARRIAGEDATE[2]),
                                MARRIAGEDATE: MatterFamilyLawData.MARRIAGEDATE
                            });
                        }
                        // if (MatterFamilyLawData.SEPARATIONDATE) {
                        //   let TEMPSEPARATIONDATE = MatterFamilyLawData.SEPARATIONDATE.split("/");
                        //   this.matterFamliydetailForm.patchValue({
                        //     SEPARATIONDATETEXT: new Date(TEMPSEPARATIONDATE[1] + '/' + TEMPSEPARATIONDATE[0] + '/' + TEMPSEPARATIONDATE[2]),
                        //     SEPARATIONDATE: MatterFamilyLawData.SEPARATIONDATE
                        //   });
                        // }
                        if (MatterFamilyLawData.DATEFILEDFORDIVORCE) {
                            let TEMPDATEFILEDFORDIVORCE = MatterFamilyLawData.DATEFILEDFORDIVORCE.split("/");
                            this.matterFamliydetailForm.patchValue({
                                DATEFILEDFORDIVORCETEXT: new Date(TEMPDATEFILEDFORDIVORCE[1] + '/' + TEMPDATEFILEDFORDIVORCE[0] + '/' + TEMPDATEFILEDFORDIVORCE[2]),
                                DATEFILEDFORDIVORCE: MatterFamilyLawData.DATEFILEDFORDIVORCE,
                            });
                        }
                        if (MatterFamilyLawData.DIVORCEDATE) {
                            let TEMPDIVORCEDATE = MatterFamilyLawData.DIVORCEDATE.split("/");
                            this.matterFamliydetailForm.patchValue({
                                DIVORCEDATETEXT: new Date(TEMPDIVORCEDATE[1] + '/' + TEMPDIVORCEDATE[0] + '/' + TEMPDIVORCEDATE[2]),
                                DIVORCEDATE: MatterFamilyLawData.DIVORCEDATE,
                            });
                        }
                        if (MatterFamilyLawData.EXPIRATIONDATE) {
                            let TEMPEXPIRATIONDATE = MatterFamilyLawData.EXPIRATIONDATE.split("/");
                            this.matterFamliydetailForm.patchValue({
                                ExpirationDatetextF: new Date(TEMPEXPIRATIONDATE[1] + '/' + TEMPEXPIRATIONDATE[0] + '/' + TEMPEXPIRATIONDATE[2]),
                                EXPIRATIONDATE: MatterFamilyLawData.EXPIRATIONDATE
                            });
                        }
                    }
                }
            });
        }
    }
    SaveFamilyLaw() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.matterFamliydetailForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERFAMILYLAWGUID;
            // delete formData.MATTERGUID;
        }
        delete formData.SEPARATIONDATETEXT;
        delete formData.DIVORCEDATETEXT;
        delete formData.DATEFILEDFORDIVORCETEXT;
        delete formData.MARRIAGEDATETEXT;
        delete formData.COHABITATIONDATETEXT;
        delete formData.ExpirationDatetextF;

        const formAction = (formData.MATTERFAMILYLAWGUID) ? 'Update' : 'Insert';
        const FamilyLawData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(FamilyLawData, 'matter-family-law').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveFamilyLawAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveFamilyLawAfterVAlidation(details);

        }
    }
    SaveFamilyLawAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-family-law').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    CohabitationDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterFamliydetailForm.controls['COHABITATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    MarriageDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterFamliydetailForm.controls['MARRIAGEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    // SeparationDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.matterFamliydetailForm.controls['SEPARATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }
    DivorceDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterFamliydetailForm.controls['DIVORCEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    // HearingDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.matterdetailForm.controls['EXPERTHEARINGDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }
    ExpiryDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterFamliydetailForm.controls['EXPIRATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    DateFiledForDivorceDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterFamliydetailForm.controls['DATEFILEDFORDIVORCE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    DeleteFamilyRecovery() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-family-law').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterFamilyLawData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                // this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERFAMILYLAWGUID: MatterFamilyLawData.MATTERFAMILYLAWGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-family-law').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
}
