import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { BehaviorService } from "@_services/Behavior.service";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent implements OnInit, OnDestroy, OnChanges {
    private _unsubscribeAll: Subject<any>;
    @Input() notificationList: any;
    constructor(
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        private _fuseSidebarService: FuseSidebarService
    ) {
        this._unsubscribeAll = new Subject();
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() { }
    ngOnChanges() {
        // create header using child_id
        if (this.notificationList) {
            this.getNotificationList();
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * This function is used to get the notification list data value. 
     */
    getNotificationList():void {
        this.notificationList.forEach((element) => {
            if (element.LINKTYPE == 4) {
                element.ICON = "notifications";
            } else if (element.LINKTYPE == 6) {
                element.ICON = "cake";
            }
        });
    }

    /**
     * This function is used to open the side bar data value.
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
