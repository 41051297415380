import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lexon-form-agreement',
  templateUrl: './lexon-form-agreement.component.html',
  styleUrls: ['./lexon-form-agreement.component.scss']
})
export class LexonFormAgreementComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LexonFormAgreementComponent>) { }
  AGREE:any
  ngOnInit(): void {
  }

  /**
   * This function is used to get the data
   */
  getdata(data){
    setTimeout(() => {
      this.dialogRef.close(data);
    },500);
  }
}
