import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatterPopupComponent } from '../../matter-popup.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
  selector: 'app-domestic-violence',
  templateUrl: './domestic-violence.component.html',
  styleUrls: ['./domestic-violence.component.scss']
})
export class DomesticViolenceComponent implements OnInit {
    @Input() errorWarningData: any;
    ChildtableDataSubcription: Subscription;
    @Input() testBool;
    @Input() toolTipList : any;
    @Input() action: any;
    @Input() classType :any;

    toolTipPostion ="above";
    DomenesticViolenceForm:FormGroup
    MatterData: any = {};
  constructor(
    private datepipe: DatePipe,
    private _formBuilder: FormBuilder,
    private _mainAPiServiceService: MainAPiServiceService,
    public MatDialog: MatDialog,
    public _matDialog: MatDialog,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<MatterPopupComponent>,
    public behaviorService: BehaviorService,
    public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService)
    {
    // this.initFormData()
  }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
  }

}
