<div class="one" fxLayout="column" [formGroup]="matterLeasingForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Annual Rental</mat-label>
            <input matInput style="text-align: right;" [value]="matterLeasingForm.get('ANNUALRENTAL').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.ANNUALRENTAL?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="ANNUALRENTAL" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.ANNUALRENTAL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ANNUALRENTAL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ANNUALRENTAL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Annual Rental Clause</mat-label>
            <input matInput style="text-align: right;" [value]="matterLeasingForm.get('ANNUALRENTALCLAUSE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.ANNUALRENTALCLAUSE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="ANNUALRENTALCLAUSE" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.ANNUALRENTALCLAUSE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ANNUALRENTALCLAUSE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ANNUALRENTALCLAUSE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Rental Amount</mat-label>
            <input matInput style="text-align: right;" [value]="matterLeasingForm.get('RENTALAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.RENTALAMOUNT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="RENTALAMOUNT" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.RENTALAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTALAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTALAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Per</mat-label>
            <mat-select formControlName="RENTALPERIOD">
                <mat-option value="Week">Week</mat-option>
                <mat-option value="Month">Month</mat-option>
                <mat-option value="Quarter">Quarter</mat-option>
                <mat-option value="Year">Year</mat-option>
            </mat-select>
             <div  *ngIf="testBool && toolTipList.RENTALPERIOD" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTALPERIOD.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTALPERIOD?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-checkbox fxFlex="33" class="pr-4" formControlName="GSTPAYABLE">GST Payable</mat-checkbox>
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
            <mat-label>Rent Payable On What Day</mat-label>
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.RENTPAYABLEONWHATDAY?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="RENTPAYABLEONWHATDAY">
             <div  *ngIf="testBool && toolTipList.RENTPAYABLEONWHATDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTPAYABLEONWHATDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTPAYABLEONWHATDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
            <mat-label>Outgoings</mat-label>
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.OUTGOINGS?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="OUTGOINGS">
             <div  *ngIf="testBool && toolTipList.OUTGOINGS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OUTGOINGS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OUTGOINGS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Security Deposit</mat-label>
            <input matInput style="text-align: right;" [value]="matterLeasingForm.get('SECURITYDEPOSIT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.SECURITYDEPOSIT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="SECURITYDEPOSIT" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.SECURITYDEPOSIT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SECURITYDEPOSIT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SECURITYDEPOSIT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Security Deposit Type</mat-label>
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.SECURITYDEPOSITTYPE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="SECURITYDEPOSITTYPE">
             <div  *ngIf="testBool && toolTipList.SECURITYDEPOSITTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SECURITYDEPOSITTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SECURITYDEPOSITTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Overdue Rent Int %</mat-label>
            <input matInput  matTooltip="{{(toolTipList)?toolTipList.OVERDUERENTINTERESTRATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="OVERDUERENTINTERESTRATE">
             <div  *ngIf="testBool && toolTipList.OVERDUERENTINTERESTRATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OVERDUERENTINTERESTRATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OVERDUERENTINTERESTRATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <mat-label>Rent Start Date</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'RENTSTARTDATE')" formControlName="RENTSTARTDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'RENTSTARTDATE')" matInput  matTooltip="{{(toolTipList)?toolTipList.RENTSTARTDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="RentStartDate" [matDatepicker]="RentStartDate">
            <mat-datepicker-toggle matSuffix [for]="RentStartDate"></mat-datepicker-toggle>
            <mat-datepicker #RentStartDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.RENTSTARTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTSTARTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTSTARTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>


    </div>
    <div class="group one" fxLayout="column">
        <h2>Rent Free</h2>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Start Date</mat-label>
                <input (dateInput)="CommanDatePickerClick('input', $event,'RENTFREESTARTDATE')" formControlName="RENTFREESTARTDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'RENTFREESTARTDATE')" matInput  matTooltip="{{(toolTipList)?toolTipList.RENTFREESTARTDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" name="StartDate" [matDatepicker]="StartDate">
                <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                <mat-datepicker #StartDate></mat-datepicker>
                 <div  *ngIf="testBool && toolTipList.RENTFREESTARTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFREESTARTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFREESTARTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Clause</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('RENTFREECLAUSE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.RENTFREECLAUSE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="RENTFREECLAUSE" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.RENTFREECLAUSE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFREECLAUSE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFREECLAUSE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Units</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('RENTFREEUNITS').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.RENTFREEUNITS?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="RENTFREEUNITS" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.RENTFREEUNITS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFREEUNITS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFREEUNITS?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-select formControlName="RENTFREEPERIOD">
                    <mat-option value="Week">Week</mat-option>
                    <mat-option value="Month">Month</mat-option>
                    <mat-option value="Quarter">Quarter</mat-option>
                    <mat-option value="Year">Year</mat-option>
                </mat-select>
                 <div  *ngIf="testBool && toolTipList.RENTFREEPERIOD" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFREEPERIOD.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFREEPERIOD?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Amount</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('RENTFREEAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.RENTFREEAMOUNT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="RENTFREEAMOUNT" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.RENTFREEAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFREEAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFREEAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Override Amount</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('RENTFREEOVERRIDEAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.RENTFREEOVERRIDEAMOUNT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="RENTFREEOVERRIDEAMOUNT" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.RENTFREEOVERRIDEAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFREEOVERRIDEAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFREEOVERRIDEAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>
    </div>
    <div class="group one" fxLayout="column">
        <h2>Public Liability Insurance</h2>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Required Amount</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('PUBLICLIABAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.PUBLICLIABAMOUNT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PUBLICLIABAMOUNT" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.PUBLICLIABAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLICLIABAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLICLIABAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Premium</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('PUBLICLIABPREMIUM').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.PUBLICLIABPREMIUM?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PUBLICLIABPREMIUM" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.PUBLICLIABPREMIUM" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLICLIABPREMIUM.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLICLIABPREMIUM?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Clause</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('PUBLICLIABPREMIUMCLAUSE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.PUBLICLIABPREMIUMCLAUSE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PUBLICLIABPREMIUMCLAUSE" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.PUBLICLIABPREMIUMCLAUSE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLICLIABPREMIUMCLAUSE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLICLIABPREMIUMCLAUSE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
                <mat-label>Stamp Duty</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('PUBLICLIABSTAMPDUTY').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.PUBLICLIABSTAMPDUTY?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PUBLICLIABSTAMPDUTY" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.PUBLICLIABSTAMPDUTY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLICLIABSTAMPDUTY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLICLIABSTAMPDUTY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Payable When?</mat-label>
                <input matInput  matTooltip="{{(toolTipList)?toolTipList.PUBLICLIABPAYABLE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PUBLICLIABPAYABLE">
                 <div  *ngIf="testBool && toolTipList.PUBLICLIABPAYABLE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLICLIABPAYABLE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLICLIABPAYABLE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Interest Of Lessor</mat-label>
                <input matInput style="text-align: right;" [value]="matterLeasingForm.get('PUBLICLIABINTERESTOFLESSOR').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.PUBLICLIABINTERESTOFLESSOR?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PUBLICLIABINTERESTOFLESSOR" [leadZero]="true">
                 <div  *ngIf="testBool && toolTipList.PUBLICLIABINTERESTOFLESSOR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PUBLICLIABINTERESTOFLESSOR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PUBLICLIABINTERESTOFLESSOR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>
    </div>
    <!-- public liability insurance -->
</div>