import { ToFixedAmountPipe } from 'src/app/to-fixed-amount.pipe';
import { NgModule } from '@angular/core';
import { HighlightSearchPipe } from 'src/app/highlight-search.pipe';
import { WordwrapPipe } from 'src/app/wordwrap.pipe';
import { InvoiceNumberPipe } from 'src/app/invoicenumber.pipe';
import { StoragFormatPipe } from 'src/app/storag-format.pipe';
import { Convet24To12Pipe } from 'src/app/convet24-to12.pipe';
import { FixedAmountPipe } from 'src/app/fixed-amount.pipe';

@NgModule({
  imports: [
  ],
  declarations: [
    ToFixedAmountPipe,
    HighlightSearchPipe,
    WordwrapPipe,
    InvoiceNumberPipe,
    StoragFormatPipe,
    Convet24To12Pipe,
    FixedAmountPipe
  ],
  exports: [
    ToFixedAmountPipe,
    HighlightSearchPipe,
    WordwrapPipe,
    InvoiceNumberPipe,
    StoragFormatPipe,
    Convet24To12Pipe,
    FixedAmountPipe
  ]
})
export class ApplicationPipesModule {
}  
