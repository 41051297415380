import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    EventEmitter,
    Output,
} from "@angular/core";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseNavigationService } from "src/@fuse/components/navigation/navigation.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { BehaviorService } from "@_services/Behavior.service";
import { TierPermissionPipe } from "@_pipes/tier-permission.pipe";

@Component({
    selector: "fuse-nav-vertical-item",
    templateUrl: "./item.component.html",
    styleUrls: ["./item.component.scss"],
    providers: [TierPermissionPipe],
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding("class")
    classes = "nav-item";
    behforToggle: any;
    ToggleData: any = false;
    @Input() item: any;
    @Input() itemIndex: any;
    @Output() onChangeVal: EventEmitter<any> = new EventEmitter<any>();
    isCollapsed: boolean = false;

    currentUser: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    showAuth: boolean=true;
    showSearching: boolean = true;
    showDairy: boolean=true;
    showTrustData:boolean=true;
    showSafeCustodyData: boolean=true;
    showshowTaskPipeData: boolean=true;

    showInboxData: boolean = true;
    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        public behaviorService: BehaviorService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private router: Router,
        private tierpermission: TierPermissionPipe,
    ) {

        const pipeDairy = this.tierpermission.transform("Diary");
        if(pipeDairy){
            if(pipeDairy && pipeDairy.ALLOWED ==1){
                this.showDairy=true;
            }else{
                this.showDairy=false;
            }
        }


        const pipe = this.tierpermission.transform("Authorities");
        if(pipe){
            if(pipe && pipe.ALLOWED ==1){
                this.showAuth=true;
            }else{
                this.showAuth=false;
            }
        }


        const showTrustDataPipe = this.tierpermission.transform("Trust Accounts");
        if(showTrustDataPipe){
            if(showTrustDataPipe && showTrustDataPipe.ALLOWED ==1){
                this.showTrustData=true;
            }else{
                this.showTrustData=false;
            }
        }


        const showSafeCustodyPipe = this.tierpermission.transform("Safe Custody");
        if(showSafeCustodyPipe){
            if(showSafeCustodyPipe && showSafeCustodyPipe.ALLOWED ==1){
                this.showSafeCustodyData=true;
            }else{
                this.showSafeCustodyData=false;
            }
        }

        const showTaskPipe = this.tierpermission.transform("Tasks");
        if(showTaskPipe){
            if(showTaskPipe && showTaskPipe.ALLOWED ==1){
                this.showshowTaskPipeData=true;
            }else{
                this.showshowTaskPipeData=false;
            }
        }

        const showInboxPipe = this.tierpermission.transform("Inbox");
        if (showInboxPipe) {
            if (showInboxPipe && showInboxPipe.ALLOWED == 1) {
                this.showInboxData = true;
            } else {
                this.showInboxData = false;
            }
        }

        const pipeSearching = this.tierpermission.transform("Searching");
        if(pipeSearching){
            if(pipeSearching && pipeSearching.ALLOWED ==1){
                this.showSearching = true;
            }else{
                this.showSearching = false;
            }
        }
        // watch sidebar changes
        this.behaviorService.isMenuFolded$.subscribe((data) => {
            this.isCollapsed = data;
        });

        if (this.isCollapsed) {
            try {
                const isCollapsed = JSON.parse(
                    localStorage.getItem("sidebar_fold_unfold")
                );
                if (!this.isCollapsed) {
                    this.isCollapsed = isCollapsed;
                }
            } catch (err) { }
        }
        // ends here ~ watch sidebar changes

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // < ---- Tab management
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

        this.router.events.subscribe((val) => {
            if (
                this.item &&
                this.currentUser &&
                this.currentUser.OPENINNEWTAB
            ) {
                const url = this.router.url;
                const URL = url.split("/");
                this.item.exactMatch = URL[1] === this.item.URL ? true : false;
                if (URL[1] === "login") {
                    // window.open('/#/'+URL[1],'_self');
                    // window.blur();
                } else {
                }
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

     /**
     * It runs once after the component's view has been fully initialized.
     */
    ngOnInit(): void {
        this.behaviorService.ToggleForUpadteFavourite$.subscribe((res) => {
            this.ToggleData = res;
        });

        // <--- Tab managemnt
        if (this.item && this.currentUser && this.currentUser.OPENINNEWTAB) {
            const url = this.router.url;
            const URL = url.split("/");
            this.item.exactMatch = URL[1] === this.item.URL ? true : false;
        }
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        this._unsubscribeAll.next(true);
    }

    // onChange(val) {

    //     this.onChangeVal.emit(val);

    // }
    preventDefault(event: any) {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
    }

    onChange(values) {
        const favData = { title: values, index: this.itemIndex };
        this.onChangeVal.emit(favData);

        // let page = JSON.parse(localStorage.getItem('usersFavouritelist'));
        // let data =    page.DATA.FAVOURITES.find(e=>e.TITLE == values)
        // page.DATA.FAVOURITES.forEach(items => {
        //     if(items.TITLE == data.TITLE){
        //         if(items.STAR == ''){
        //                 items.STAR = 'star'
        //                 this.item.STAR = items.STAR

        //         }else if(items.STAR == 'star'){
        //             items.STAR = ''
        //             this.item.STAR = items.STAR
        //         }else{
        //             items.STAR = ''
        //              this.item.STAR = items.STAR
        //         }

        //         localStorage.setItem('usersFavouritelist',JSON.stringify(page));
        // }
        // //   if (items.STAR == '') {
        // //     if (items.TITLE == values) {
        // //       items.STAR = 'star';
        // //       this.starShow = true

        // //     } else {
        // //         this.starShow = true

        // //       items.STAR = '';
        // //     }
        // //   } else {
        // //     if (items.TITLE == values) {
        // //         this.starShow = false

        // //       items.STAR = '';
        // //     }
        // //   }
        // });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        // this.behforToggle.unsubscribe()
    }

    // <--- Tab management
    commonFocusWindow(url, target?) {
        let random = url;
        random = window.open(url, target);
        random.focus();
    }
    navigateTab(url, type, target) {
        let random;
        if (/[,\-]/.test(url)) {
            random = url.replace(/-/g, "");
        } else {
            random = url;
        }
        if (environment.ISPRO) {
            random = window.open("/#/" + url, random);
        } else {
            // random = window.open(
            //     "/" + environment.URLPATH + "/#/" + url,
            //     random
            // );
            random = window.open("/#/" + url,
                random
            );
            random.focus();
        }
    }

    showMenuItems() {
        return this.item.STAR == "star"
            ? true
            : this.ToggleData == true || this.ToggleData == "true"
                ? true
                : false;
    }
}
