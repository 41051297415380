import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';

@Component({
  selector: 'app-export-to-slq',
  templateUrl: './export-to-slq.component.html',
  styleUrls: ['./export-to-slq.component.scss']
})
export class ExportToSLQComponent implements OnInit, OnDestroy {
  isspiner: boolean = false;
   //use for tier permission
   accessTierPermission : any = JSON.parse(localStorage.getItem('set_tier_permission'));
  openWebsite: any;
  matterAccessTier: any;
  base_url: string;
  PDF_Generation : any;
  sub1: Subscription;
  sub2: Subscription;
  constructor(private behaviorService: BehaviorService, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService,private sanitizer: DomSanitizer ) {
    localStorage.setItem('istrackid', 'ExportToSLQComponent');
    this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'ExportToSLQComponent' && result['click'] && result['data']['user-activity']) {
        this.ngOnInit();
      }
    });

    this.matterAccessTier = this.getPermission('SLQ Export');
  }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.behaviorService.loadingAccountMNG('exportSLQ');
  }

  ngOnDestroy(): void {
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to export the data on silq
   */
  exportToSlq() {
    // this.isLoadingResults = true;
    this.base_url = environment.DocumentsUrl;
    this.isspiner = true;
    this.sub2 = this._mainAPiServiceService.getSetData({
      Action: "extractslq",
      Data: {}
    }, 'SetDatabase').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.PDF_Generation = response.DATA.SLQFILE;
        this.download(this.base_url + this.PDF_Generation)
        this.behaviorService.loadingAccountMNG('exportSLQ');
      } else {
        this.behaviorService.loadingAccountMNG('exportSLQ');
      }
      this.isspiner = false;
    });
  }

  /**
   * This function is used to get the permission data.
   * @param permission -permission data
   * @returns data
   */
  getPermission(permission){
    return this.accessTierPermission.filter(e=> e.FEATURENAME == permission)[0];
  }

  // This function is used to goto url
  goTo(url){
    if(url){
        this.openWebsite  = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('http://' , 'https://'))
      }else{
        this.openWebsite = undefined
      }

      if(this.openWebsite){
        window.open(this.openWebsite.changingThisBreaksApplicationSecurity, "_blank");
      }
  }

  /**
   * This function is used to download the url data.
   * @param url -url data
   */
  download(url) {
    const a = document.createElement('a')
    let urlData : any = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('http://' , 'https://'));
    a.href = urlData;
    a.target = '_blank';
    a.download = 'SQLFILE.zip'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

}
