import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from 'src/@fuse/animations';
import { Subscription } from 'rxjs';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';

@Component({
  selector: 'app-safe-custody-details',
  templateUrl: './safe-custody-details.component.html',
  styleUrls: ['./safe-custody-details.component.scss'],
  animations: fuseAnimations
})
export class SafeCustodyDetailsComponent implements OnInit, OnDestroy {
  SafeCustodyData: any;
  isLoadingResults: boolean = false;
  detailSafeCustodySubscription: Subscription;
  sub: Subscription;
  safecustodydetails: any[];
  storeData: any = JSON.parse(localStorage.getItem('stored_safe_custody_details'));
  showPushArray: any = [];
  constructor(
    public dialog: MatDialog,
    private behaviorService: BehaviorService,
    public _mainAPiServiceService: MainAPiServiceService,
  ) {
    this.storeData = (this.storeData && this.storeData != null) ? this.storeData : [];
    this.sub = this.behaviorService.SafeCustody$.subscribe(result => {
      if (result) {
        this.showPushArray = [];
        this.storeData.forEach(element => {
          if (element.SAFECUSTODYGUID == result.SAFECUSTODYGUID) {
            this.showPushArray.push(element);
          }
        });
        this.safecustodydetails = this.showPushArray;
        this.SafeCustodyData = result;
      } else {
        this.SafeCustodyData = null;
        this.safecustodydetails = null;
      }
    });
    this.detailSafeCustodySubscription = this.behaviorService.reduceSafeCustodyCall$.subscribe(result => {
      if (result) {
        this.isLoadingResults = true;
        this.safecustodydetails = [];
        this.SafeCustodyData = result;
        this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{ SAFECUSTODYGUID: result.SAFECUSTODYGUID}}, 'safe-custody-movement').subscribe(response => {
          this.isLoadingResults = false;
          this.safecustodydetails = response.DATA.RECORDS;
          response.DATA.RECORDS.forEach(element => {
            this.storeData.push(element);
          });
          localStorage.setItem('stored_safe_custody_details', JSON.stringify(this.storeData));
        }, err => {
        });
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() { }

  /**
   * This function is used to unsubscribe the observables strims
   */
  ngOnDestroy(): void {
    this.detailSafeCustodySubscription?.unsubscribe();
    this.sub?.unsubscribe();
  }


}
