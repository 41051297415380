<div class="sort_main_sidebar">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
        <h3 class="one"><strong class="x">Matter Details</strong></h3>
        <!-- <div class="button-div">
            <button class="button primary" mat-raised-button (click)="closeDetail($event)"><mat-icon>clear</mat-icon></button>
        </div> -->
    </div>
    <!-- / SIDEBAR HEADER -->

    <!-- SIDEBAR CONTENT -->
    <ng-container *ngIf="MatterData$ | async"></ng-container>
    <ng-container *ngIf="Hyperlink$ | async"></ng-container>
    <ng-container *ngIf="MD$ | async"></ng-container>
    <ng-container *ngIf="ReduceMatterCall$ | async"></ng-container>

    <div class="two">
        <!-- max_height_auto -->
        <div class="content" fusePerfectScrollbar *ngIf="colorClass$ | async as COLOR">
            <div class="file-details" [@animate]="{value:'*',params:{delay:'200ms'}}">
                <table>
                    <tr style="cursor: pointer;" class="matter_num">
                        <th>{{barristerTitle}}</th>
                        <!-- <td>{{mattersDetailData?.SHORTNAME}}</td> -->
                        <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" (click)="hyperlinkData()">{{mattersDetailData?.SHORTNAME}}</a></td>
                    </tr>
                    <tr class="matter">
                        <th>Matter</th>
                        <td>{{mattersDetailData?.MATTER}}</td>
                    </tr>
                    <tr class="contectTitle">
                        <th>Matter Notes</th>
                        <td>{{mattersDetailData?.NOTES}}</td>
                    </tr>
                </table>
                <mat-divider></mat-divider>
                <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
                    <h3 class="one"><strong class="x">{{title}}</strong></h3>
                </div>
                <table>
                    <tr style="cursor: pointer;" class="contectTitle matter-role">
                        <th>{{contectTitle}}</th>
                        <td [ngClass]="(appPermissions[3]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                            <span [ngClass]="(appPermissions[3]?.Edit==0)? 'disabled-click large-button' : 'large-button'">
                                <a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" (click)="ContactsDialog()" > {{mattersDetailData?.CONTACTNAME}}</a>
                            </span>
                        </td>
                    </tr>
                    <tr style="cursor: pointer;" class="contectTitle" *ngIf="mattersDetailData?.CONTACTADDRESS ">
                        <th>Address</th>
                        <td ><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" (click)="showMap(mattersDetailData?.CONTACTADDRESS)">
                            {{mattersDetailData?.CONTACTADDRESS }}
                        </a></td>
                    </tr>

                    <tr style="cursor: pointer;" class="contectTitle" *ngIf="mattersDetailData?.CONTACTPOSTALADDRESS !== '   ' ">
                        <th *ngIf="mattersDetailData?.CONTACTPOSTALADDRESS != undefined ">Postal Address</th>
                        <td ><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" (click)="showMap(mattersDetailData?.CONTACTPOSTALADDRESS)">
                            {{ mattersDetailData?.CONTACTPOSTALADDRESS }}
                        </a></td>
                    </tr>
                    <tr class="contectTitle" *ngIf="mattersDetailData?.CONTACTEMAIL">
                        <th>Client Email</th>
                        <td ><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;"  [href]="mailto(mattersDetailData?.CONTACTEMAIL)"> {{mattersDetailData?.CONTACTEMAIL}}</a></td>
                    </tr>
                    <tr class="matter_num" *ngIf="mattersDetailData?.CONTACTMOBILE">
                        <th>Client Mobile</th>
                        <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" href="tel:{{mattersDetailData?.CONTACTMOBILE}}">{{mattersDetailData?.CONTACTMOBILE}}</a></td>
                    </tr>
                    <tr class="matter" *ngIf="mattersDetailData?.CONTACTPHONE">
                        <th>Client Phone</th>
                        <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" href="tel:{{mattersDetailData?.CONTACTPHONE}}">{{mattersDetailData?.CONTACTPHONE }}</a></td>

                    </tr>
                </table>
                <mat-divider></mat-divider>
                <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
                    <h3 class="one"><strong class="x">Matter Contact Details</strong></h3>
                </div>
                <table *ngIf="isLoadingResults">
                    <tr>
                        <td colspan="2" align="center">
                            <mat-spinner></mat-spinner>
                        </td>
                    </tr>
                </table>
                <span *ngFor="let item of mattersConTactmData">
                    <table>
                        <tr style="cursor: pointer;" class="matter_num" *ngIf="item?.CONTACTNAME">
                            <th>Name</th>
                            <td [ngClass]="(appPermissions[3]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" >
                            <span [ngClass]="(appPermissions[3]?.Edit==0)? 'disabled-click large-button' : 'large-button'">
                                <a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" (click)="ContactsDialog(item)"> {{item?.CONTACTNAME }}</a>
                            </span>
                           </td>
                           <!-- (click)="ContactsDialog()"  -->
                            <!-- <td><mat-icon role="img" (click)="editElement(item)"
                                class="mat-icon notranslate main-icon matter-icon-img material-icons mat-ligature-font mat-icon-no-color"
                                aria-hidden="true" data-mat-icon-type="font"><img
                                    src="assets/icons/web_app/icon_contact_edit_d.ico"></mat-icon>
                             </td> -->
                        </tr>
                        <tr style="cursor: pointer" class="matter_num matter-role" *ngIf="item?.TYPENAME">
                            <th>Role.</th>
                            <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;"  (click)="editElement(item)"> {{item?.TYPENAME }}</a></td>
                            <!-- <td class="td2"><mat-icon role="img" (click)="editElement(item)"
                                class="mat-icon notranslate main-icon matter-icon-img material-icons mat-ligature-font mat-icon-no-color"
                                aria-hidden="true" data-mat-icon-type="font"><img
                                    src="assets/icons/web_app/icon_contact_edit_d.ico"></mat-icon>
                            </td> -->
                        </tr>
                        <tr class="matter_num" *ngIf="item?.PERSONEMAIL">
                            <th>Email</th>
                            <td ><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;"  [href]="mailto(item?.PERSONEMAIL)"> {{item?.PERSONEMAIL }}</a></td>
                        </tr>
                        <tr class="unbilled" *ngIf="item?.PERSONPHONE">
                            <th>Phone</th>
                            <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" href="tel:{{item?.PERSONPHONE}}">{{item?.PERSONPHONE}}</a></td>
                        </tr>
                        <tr class="unbilled" *ngIf="item?.PERSONMOBILE">
                            <th>Mobile </th>
                            <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" href="tel:{{item?.PERSONMOBILE}}">{{item?.PERSONMOBILE}}</a></td>
                        </tr>

                        <tr  style="cursor: pointer;" class="unbilled" *ngIf="item?.SOLICITORNAME">
                            <th >Sol. Name </th>
                            <td [ngClass]="(appPermissions[3]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" >
                                <span [ngClass]="(appPermissions[3]?.Edit==0)? 'disabled-click large-button' : 'large-button'">
                                    <a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" (click)="ContactsDialog()" > {{item?.SOLICITORNAME}}</a>
                                </span>
                               </td>
                            
                        </tr>

                        <tr class="unbilled" *ngIf="item?.SOLICITORPHONE">
                            <th>Sol. Phone </th>
                            <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" href="tel:{{item?.SOLICITORPHONE}}">{{item?.SOLICITORPHONE}}</a></td>
                            <!-- <td><span>{{item?.SOLICITORPHONE}}</span></td> -->
                        </tr>

                        <tr class="unbilled" *ngIf="item?.SOLICITORMOBILE">
                            <th>Sol. Mobile </th>
                            <td><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;" href="tel:{{item?.SOLICITORMOBILE}}">{{item?.SOLICITORMOBILE}}</a></td>
                            <!-- <td><span>{{item?.SOLICITORMOBILE}}</span></td> -->
                        </tr>

                        <tr class="unbilled" *ngIf="item?.SOLICITOREMAIL">
                            <th>Sol. Email </th>
                            <td ><a [ngClass]="COLOR.CLASS" style="text-decoration: underline;"  [href]="mailto(item?.SOLICITOREMAIL)"> {{item?.SOLICITOREMAIL}}</a></td>

                            <!-- <td><span>{{item?.SOLICITOREMAIL}}</span></td> -->
                        </tr>
                    </table>
                    <mat-divider></mat-divider>
                </span>
            </div>
        </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>