import { BehaviorService } from '@_services/Behavior.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChangePasswordComponent } from 'src/app/main/change-password/change-password.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit, OnDestroy {
  isTabShow: number;
  AWAITING2FA:any;
  showActive = false;
  sub: Subscription;
  constructor(public dialog: MatDialog, 
    private toastr: ToastrService, 
    private router: Router,
    private behaviorService: BehaviorService,
  ) { 
    this.sub = this.behaviorService.AWAITING2FA$.subscribe(res=>{
      this.AWAITING2FA = res;
   })
  }


    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  /**
   * This function is used to chage the password data
   */
  ChangePass():void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, { disableClose: true, panelClass: 'change-password' });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  /**
   * This function is used to target the new tab
   * @param URL -url data
   * @param modul -module data
   */
  targetNewTab(URL: any, modul: string) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.OPENINNEWTAB) {
        const url = this.router.serializeUrl(this.router.createUrlTree([URL]));
        let target;
        if (/[,\-|\/]/.test(URL)) {
            target = URL.replace(/-|\//g, "");
        } else {
            target = URL;
        }
        if (environment.ISPRO) {
            // window.open
            this.commonFocusWindow('/#' + url, target);
        } else {
            // window.open
            // this.commonFocusWindow('/' + environment.URLPATH + '/#' + url, target);
            this.commonFocusWindow('/#' + url, target);
        }
    } else {
        setTimeout(() => {
          this.router.navigate([URL]);
        }, 100);
    }
  }

  /**
   * This function is used to common focus windows
   * @param url -url data value
   * @param modul -modul data value
   */
  commonFocusWindow(url, modul):void {
    const active_matter = JSON.parse(localStorage.getItem('set_active_matters'));
    let random: any = active_matter;
    random = window.open(url, modul);
    random.focus();
  }

}
