<button mat-raised-button type="button" color="primary" id="refreshRecouncilItem" (click)="refreshRecouncilItem()">Refresh</button>
<button mat-raised-button type="button" color="primary" id="SetRecouncilItem" (click)="SetRecouncilItem()">
    Refresh</button>
<!-- <a> tag is for AppCuse -->
<div class="nameCount">
    {{ AccountConstName }}
    <a href="javascript:void(0);">Link</a>
</div>
<div id="AccountRecouncile" class="page-layout simple left-sidebar inner-sidebar inner-scroll" [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'solicitor-container':'barrister-container'">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto">

                <!-- new form -->
                <form [formGroup]="AccountRecouncile" class="filter-menuwrap marginleft-0">
                    <div class="align-center display-inlineflex flex-wrap filter-menublock filtermenublock-wrap">

                        <!-- reconciled date -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text">
                                Last Rec. Date<span class="filter-applytext display-flex align-center">
                                    {{f.LASTRECONCILEDDATE.value}}
                                        <!-- <input matInput disabled
                                            formControlName="LASTRECONCILEDDATE" class="search-input-text"> -->
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ reconciled date -->

                          <!-- Last Reconciled Balance -->
                          <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Last Rec. Bal.<span class="filter-applytext display-flex align-center">

                                        <input matInput disabled readonly
                                            formControlName="LASTRECONCILEDBALANCE" class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Last Reconciled Balance -->

                        <!-- for date -->
                        <!-- <mat-form-field class="dateinput pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Bank Statement Date</mat-label>
                            <input (dateInput)="BankchoosedDate('input', $event)" (dateChange)="BankchoosedDate('change', $event)" matInput formControlName="Bankdate" [matDatepicker]="picker" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-icon matSuffix (click)="BankchoosedDate('clearDateRange',null)" *ngIf='hasDateRange' class="icon-color-red highlight_off"> highlight_off</mat-icon>
                        </mat-form-field> -->

                        <div class="position-relative">
                            <mat-icon class="badge-ic">
                                 </mat-icon>
                            <button mat-button  class="buttonwith-text dotted-button calender-btn"  >
                                Statement Date <span class="filter-applytext display-flex align-center">
                                    <input matInput formControlName="Bankdate"  [satDatepicker]="spendpicker2"
                                                (dateChange)="BankchoosedDate('change', $event)"
                                                #dateRange>
                                            <sat-datepicker #spendpicker2 [rangeMode]="false"> </sat-datepicker>
                                            <sat-datepicker-toggle matSuffix [for]="spendpicker2" class="display-flex align-center calenderbtn-icon"></sat-datepicker-toggle>
                            </span>
                            </button>
                            <!-- <mat-menu #dayRangemenucard="matMenu">
                                <h5 class="filter-title">Filter by Statement Date</h5>
                                <div class="display-flex align-center">
                                    <span class="back-tick">
                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                    </span>
                                    <div class="display-flex align-center">
                                        <mat-form-field appearance="outline" (click)="preventDefault($event)">
                                            <input matInput formControlName="Bankdate" [satDatepicker]="spendpicker2"
                                                (dateChange)="BankchoosedDate('change', $event)"
                                                #dateRange>
                                            <sat-datepicker #spendpicker2 [rangeMode]="false"> </sat-datepicker>
                                            <sat-datepicker-toggle matSuffix [for]="spendpicker2"></sat-datepicker-toggle>

                                        </mat-form-field>
                                    </div>
                                </div>





                            </mat-menu> -->
                        </div>
                        <!-- ends here ~ for date -->

                        <!-- Statement Closing Bal -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Stmnt Closing Bal.<span class="filter-applytext display-flex align-center">
                                    <input matInput  [allowNegativeNumbers]="true" [value]="AccountRecouncile.get('statementClosingBalance').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." formControlName="statementClosingBalance" class="search-input-text"  (blur)="statmentClosingBal()" [leadZero]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Statement Closing Bal -->

                        <!-- calculating closing balance -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                Calc. Closing Balance<span class="filter-applytext display-flex align-center">

                                        <input matInput disabled readonly
                                            formControlName="calculatedClosingBalance" class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ calculating closing balance -->


                         <!-- Out of Balance -->
                         <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Out of Balance<span class="filter-applytext display-flex align-center">

                                        <input matInput  readonly
                                        formControlName="OutBal" class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Out of Balance -->



                        <!-- Unpresented Deposits -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Unpres. Deposits<span class="filter-applytext display-flex align-center">

                                        <input matInput disabled readonly
                                            formControlName="UnDeposite" class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Unpresented Deposits -->


                        <!-- Unpresented Withdrawals -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Unpres. Withdrawals<span class="filter-applytext display-flex align-center">

                                        <input currencyMask readonly matInput formControlName="UnWith"  class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Unpresented Withdrawals -->





                        <!-- Unbanked cash/Chqs -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Unbanked cash/Chqs<span class="filter-applytext display-flex align-center">

                                        <input matInput  readonly
                                        formControlName="unbanked_cash" class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Unbanked cash/Chqs -->

                         <!-- Control Account Bal -->
                         <div class="position-relative" *ngIf="isTrustWindow">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Control Account Bal<span class="filter-applytext display-flex align-center">

                                        <input matInput disabled readonly
                                            formControlName="controlled_account" class="search-input-text" [disabled]="true">
                                            <!-- {{f.controlled_account.value}} -->
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Control Account Bal -->

                        <!-- Calc Cash Book bal -->
                        <div class="position-relative" *ngIf="isTrustWindow">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;" >
                                Calc Cash Book Bal.<span class="filter-applytext display-flex align-center">

                                        <input matInput currencyMask [disabled]="true"
                                        formControlName="cal_cash_book" class="search-input-text"  (focusout)="statmentClosingBal()">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ Calc Cash Book bal -->



                        <!-- prepared by field -->
                        <div class="position-relative">
                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                Prepared by<span class="filter-applytext display-flex align-center">

                                        <input matInput disabled readonly
                                            formControlName="PreBy" class="search-input-text" [disabled]="true">
                                </span>
                            </button>

                        </div>
                        <!-- ends here ~ prepared by field -->



                    </div>
                </form>
                <!-- ends here ~ new form -->

                <!-- original form -->
                <!-- <form [formGroup]="AccountRecouncile" class="recouncile_form" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <div fxFlex="100">
                        <mat-form-field class="readonly_apply pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Last Reconciled Date</mat-label>
                            <input class="" matInput formControlName="LASTRECONCILEDDATE" readonly />
                        </mat-form-field>
                        <mat-form-field class="dateinput pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Bank Statement Date</mat-label>
                            <input (dateInput)="BankchoosedDate('input', $event)" (dateChange)="BankchoosedDate('change', $event)" matInput formControlName="Bankdate" [matDatepicker]="picker" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-icon matSuffix (click)="BankchoosedDate('clearDateRange',null)" *ngIf='hasDateRange' class="icon-color-red highlight_off"> highlight_off</mat-icon>
                        </mat-form-field>
                        <mat-form-field class="readonly_apply pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Calculated Closing Balance</mat-label>
                            <input currencyMask matInput formControlName="calculatedClosingBalance" readonly class="sideText" />
                        </mat-form-field>
                        <mat-form-field class="readonly_apply pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Prepared by</mat-label>
                            <input class="" matInput formControlName="PreBy" readonly />
                        </mat-form-field>
                        <mat-form-field *ngIf="isTrustWindow" class="readonly_apply pr-8" appearance="outline" fxFlex="16.66">
                            <mat-label>Control Account Bal</mat-label>
                            <input currencyMask readonly matInput formControlName="controlled_account" class="sideText" />
                        </mat-form-field>
                        <mat-form-field class="readonly_apply" appearance="outline" fxFlex="16.66">
                            <mat-label>Unpresented Deposits</mat-label>
                            <input currencyMask readonly matInput formControlName="UnDeposite" class="sideText" />
                        </mat-form-field>
                    </div>
                    <div fxFlex="100">
                        <mat-form-field class="readonly_apply pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Last Reconciled Balance</mat-label>
                            <input matInput currencyMask formControlName="LASTRECONCILEDBALANCE" class="sideText" readonly />
                        </mat-form-field>
                        <mat-form-field class="pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Statement Closing Bal.</mat-label>
                            <input currencyMask type="text" (focusout)="statmentClosingBal()" matInput formControlName="statementClosingBalance" class="sideText" />
                        </mat-form-field>
                        <mat-form-field class="readonly_apply pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Out of Balance</mat-label>
                            <input currencyMask readonly matInput formControlName="OutBal" class="sideText" />
                        </mat-form-field>
                        <mat-form-field class="readonly_apply pr-8" appearance="outline" fxFlex="16.67">
                            <mat-label>Unbanked cash/Chqs</mat-label>
                            <input currencyMask readonly matInput formControlName="unbanked_cash" class="sideText" />
                        </mat-form-field>
                        <mat-form-field *ngIf="isTrustWindow" class="readonly_apply pr-8" appearance="outline" fxFlex="16.66">
                            <mat-label>Calc Cash Book bal</mat-label>
                            <input currencyMask readonly matInput formControlName="cal_cash_book" class="sideText" />
                        </mat-form-field>
                        <mat-form-field class="readonly_apply" appearance="outline" fxFlex="16.66">
                            <mat-label>Unpresented Withdrawals</mat-label>
                            <input currencyMask readonly matInput formControlName="UnWith" class="sideText" />
                        </mat-form-field>
                    </div>
                </form> -->
                <!-- ends here ~ original form -->

            </div>
            <div class="content">
                <div class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <div class="btn top_right_action_billing_position">
                                    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template matMenuContent let-item="item">
                                            <!-- <button mat-menu-item (click)="openDialog()"> Field Configuration</button> -->
                                            <button mat-menu-item (click)="refreshRecouncilItem()"> Reload Data</button>
                                            <button mat-menu-item (click)="downloadFile(ReconciliationData.data)">
                                                <img src="assets/icons/web_app/EXP_CSV.ico" style="height: 20px !important;margin-top: 10px;">
                                                Download CSV
                                            </button>

                                        </ng-template>
                                    </mat-menu>
                                </div>
                                <div class="content">
                                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div class="example-containerdata resizable-table">
                                        <div class="grid-overlay" *ngIf="isDisplay">
                                            <div class="grid-overlay-msg">
                                                There is no data to display.
                                            </div>
                                        </div>
                                        <mat-table [dataSource]="ReconciliationData" (matSortChange)="sortData($event)" matSortActive="{{ sortactive }}" matSortDirection="{{ sortDirection }}" matSortDisableClear matSort [@animateStagger]="{ value: '50' }">
                                            <!-- <ng-container *ngFor="let item of displayedColumns; let i = index" matColumnDef="{{ item }}"> -->
                                                <ng-container *ngFor="let item of showedColumns; let i = index" matColumnDef="{{ item }}">
                                                <span *ngIf="item == 'select'">
                                                    <mat-header-cell *matHeaderCellDef>
                                                        <!-- <mat-checkbox
                                                            (change)="$event ? masterToggle() : null;helloFunction()"
                                                            [checked]="selection.hasValue() && isAllSelected()"
                                                            [indeterminate]="selection.hasValue() &&!isAllSelected()"
                                                            [aria-label]="checkboxLabel()">
                                                        </mat-checkbox> -->
                                                        <!-- (click)="$event.stopPropagation()" -->
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let row">
                                                        <mat-checkbox [disabled]="row.NOTTAGGABLE == 1 || row.TAGNOTCHANGEABLE==1 "
                                                            *ngIf="!row.NOTTAGGABLE"
                                                            (change)="$event ? selection.toggle(row) : null;helloFunction()"
                                                             [(ngModel)]="row.TAGGED">
                                                        </mat-checkbox>
                                                    </mat-cell>
                                                </span>
                                                <span *ngIf="item != 'select'">
                                                    <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, item)"
                                                    (resizeEnd)="onResizeEnd($event, item)"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>
                                                    {{ tempColobj[item].DESCRIPTION }}
                                                </mat-header-cell>
                                                    <mat-cell *matCellDef="let row"
                                                        [ngStyle]="{'color': '#' + row['ROWCOLOUR']}"
                                                        [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}"
                                                        class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}">
                                                        <span *ngIf="row[item] !==undefined">

                                                            {{row[item] | toFixedAmount: tempColobj[item].CURRENCY | wordwrap : tempColobj[item].WIDEDATA}}
                                                        </span>
                                                </mat-cell>
                                                </span>
                                            </ng-container>
                                            <!-- <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                            </mat-header-row>
                                            <mat-row [style.background]="highlightedRows == row.LINKGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumns;" [ngClass]="highlightedRows == row.LINKGUID ? 'row-text-colore' : ''" class="contact" (click)="rowClick(row);highlightedRows = row.LINKGUID"
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                            </mat-row> -->
                                            <mat-header-row *matHeaderRowDef="showedColumns;sticky: true">
                                            </mat-header-row>
                                            <mat-row [style.background]="highlightedRows == row.LINKGUID ? selectedColore : ''" *matRowDef="let row; columns: showedColumns;" [ngClass]="highlightedRows == row.LINKGUID ? 'row-text-colore' : ''" class="contact" (click)="rowClick(row);highlightedRows = row.LINKGUID"
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                            </mat-row>
                                        </mat-table>
                                    </div>
                                    <!-- <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize?.recounciliations_item" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
