<div class="example-loading-shade" *ngIf="isLoadingResults" style="z-index: 99999;">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div genrate-packs-main">
    <h2 cdkDragHandle mat-dialog-title>
        <span *ngIf="!isGenerating"> {{whichTypeTemplate}} Generated </span>
        <span *ngIf="isGenerating">Generating {{whichTypeTemplate}}</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main" fxLayout="column">
        <div *ngIf="isLoadingResults" style="position: relative;z-index: 99999;">
            <h3><strong>{{filefolder_Name}}</strong> is being generated, please give it a moment. </h3><br>
            <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
            <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
        </div>
        <div class="one" style="width: 100%;" fxLayout="column" *ngIf="(whichTypeTemplate =='Pack' && (IsAuth || isLoadingResults))" fxLayoutAlign="start" fxLayout.gt-md="row" fxFlex="1 0 auto">
            <div *ngIf="IsAuth">
                <div fxFlex="70" class="middle" *ngIf="!filefolderUrlDes" fxLayoutAlign="start">
                    <span>
                        Please login to your online shared drive account. Once completed, please close the pop-up and
                        click on try again
                    </span>
                </div>
                <div fxFlex="30">
                    <button mat-raised-button color="accent" class="mat-accent" selectMatter (click)="selectMatter(_data,arguParam)">
                        Click Here to Login
                    </button>
                </div>
            </div>
            <div *ngIf="isLoadingResults" style="width: 100%;">
                <div fxFlex="100" class="middle" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex="1 0 auto" name="form" *ngIf="whichTypeTemplate == 'Pack' && !IsAuth">
            <div class="pr-4" *ngFor="let item of PacksDocument; let index = index" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="margin-bottom: 10px;">
                <span fxFlex="{{xFxaxVal}}">
                    <strong *ngIf="documentDataIndex!=index">{{ item.ORIGFILENAME }} </strong>
                    <span *ngIf="documentDataIndex==index">
                        <mat-form-field class="example pr-4" fxFlex="50" appearance="outline">
                            <mat-label>Old Filename</mat-label>
                            <input matInput readonly [(ngModel)]="item.ORIGFILENAME">
                        </mat-form-field>
                        <mat-form-field class="example pr-4" fxFlex="50" appearance="outline">
                            <mat-label>New Filename</mat-label>
                            <input matInput [(ngModel)]="item.NEWFILENAME">
                        </mat-form-field>
                        <!-- Document has been produced. -->
                    </span>
                </span>
                <span fxFlex="{{yflaxCal}}">
                    <a *ngIf="(item.DOCUMENTTYPE=='Document' && documentDataIndex!=index)"
                        href="{{base_url}}{{item?.FILENAME}}" mat-raised-button color="accent" class="mat-accent"
                        style="margin-right: 4px;" target="_blank" #tooltip="matTooltip" matTooltip="View Document"
                        matTooltipClass="example-tooltip-font">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/View.png">
                        </mat-icon>
                    </a>
                    <a *ngIf="(item.DOCUMENTTYPE=='Link' && documentDataIndex!=index)" href="{{item?.FILENAME}}"
                        mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;" target="_blank">
                        <mat-icon class="main-icon" #tooltip="matTooltip" matTooltip="View Document"
                            matTooltipClass="example-tooltip-font">
                            <img src="assets/icons/web_app/View.png">
                        </mat-icon>
                    </a>
                    <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                        *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="RenameFileData(index)"
                        #tooltip="matTooltip" matTooltip="Rename/Move Document" matTooltipClass="example-tooltip-font">
                        <mat-icon class="main-icon">
                            <!-- (click)="xFxaxVal=70;yflaxCal=30;documentDataIndex=index" -->
                            <img src="assets/icons/web_app/rename.png">
                        </mat-icon>
                    </button>
                    <a href="{{base_url}}{{item?.DOWNLOAD}}" [disabled]="!item?.DOWNLOAD" mat-raised-button
                        color="accent" class="mat-accent" style="margin-right: 4px;" target="_blank"
                        *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" #tooltip="matTooltip"
                        matTooltip="Download Document" matTooltipClass="example-tooltip-font">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/download.png">
                        </mat-icon>
                    </a>
                    <a [href]="sanitize(item?.DESKTOPLINK)" [disabled]="!item?.DESKTOPLINK" mat-raised-button
                        color="accent" class="mat-accent" style="margin-right: 4px;" target="_blank"
                        *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" #tooltip="matTooltip"
                        matTooltip="Download Document" matTooltipClass="example-tooltip-font">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/Microsoft_Word_logo.png">
                        </mat-icon>
                    </a>
                    <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                        *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="convertToPDF(index,false)"
                        [disabled]="(isPDFLoadingResults || item.isPdf)" #tooltip="matTooltip"
                        matTooltip="Convert to PDF" matTooltipClass="example-tooltip-font">
                        <mat-spinner *ngIf="isPDFLoadingResults"></mat-spinner>
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/pdf.png">
                        </mat-icon>
                    </button>
                    <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                        *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="ToPDFAndEmail(index)"
                        [disabled]="(isPDFEmailLoadingResults || item.isPdf)" #tooltip="matTooltip"
                        matTooltip="Convert and email the document." matTooltipClass="example-tooltip-font">
                        <mat-spinner *ngIf="isPDFEmailLoadingResults"></mat-spinner>
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/email.png">
                        </mat-icon>
                    </button>
                    <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                        *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="ToEmailthedocument(index)"
                        #tooltip="matTooltip" matTooltip="Email the document" matTooltipClass="example-tooltip-font">
                        <mat-icon class="main-icon">
                            <img src="assets/icons/web_app/email_tem.png">
                        </mat-icon>
                    </button>
                    <button mat-raised-button color="accent" class="mat-accent"
                        style="margin-top: 10px;margin-right: 5px;" *ngIf="documentDataIndex==index"
                        (click)="RenameFile()">Save</button>
                    <button mat-raised-button color="accent" style="margin-top: 10px;" *ngIf="documentDataIndex==index"
                        (click)="documentDataIndex=99999;xFxaxVal=55;yflaxCal=45">Cancel</button>
                </span>
            </div>
            <div class="pr-4" *ngFor="let val of PacksEmail; let index = index" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxFlex="70" class="middle" fxLayoutAlign="start start">
                    <span><b>{{val.DESCRIPTION}} </b> Email has been produced.</span>
                </div>
                <a class="btn_mrg" target="_blank" fxFlex="30">
                    <button mat-raised-button (click)="SendMain(val)" color="accent">Click here to view it</button>
                </a>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="primary" [mat-dialog-close]="false" [disabled]="isLoadingResults" cdkFocusInitial>Cancel
        </button>
    </mat-dialog-actions>
</div>