import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

//https://apitest.silq.com.au/MobileService?SetAppointment
@Component({
  selector: 'app-dairy-dailog',
  templateUrl: './dairy-dailog.component.html',
  styleUrls: ['./dairy-dailog.component.scss']
})
export class DairyDailogComponent implements OnInit, OnDestroy {
  DairyAppointmentGUID :any ={}
  timeStops: any = [];
  errorWarningData: any = { "Error": [], 'Warning': [] };
  errorWarningDataArray: any = { "Error": [], 'Warning': [] };
  DairyForm: FormGroup;
  isLoadingResults: boolean = false;
  action: string;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  dialogTitle: string;
  isspiner: boolean = false;
  CheckClick: any;
  FormAction: string;
  AppointmentGuid: any;
  App_StartTime: any;
  App_EndTime: any;
  control: any = [];
  matterguid: string;
  appoitmentID: any;
  DairyData: any;
  defaultRecuurance: any;
  SelectedDiaryUser: any = {};
  toolTipList: any;
  time: string;
  time3: string;
  testBool: boolean=false;
  mattername: string[];
  diaryDialogOrigin: string = '';
  SelectedMatterData: any;
  timeDiffrence : any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub1: Subscription;
  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<DairyDailogComponent>,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private behaviorService: BehaviorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService,
    public tooltipService:TooltipService,
  ) {

    // debugger;
    localStorage.setItem('istrackid', 'DairyDailogComponent');
    this.timeStops = this.getTimeStops('01:00', '23:59');
    this.action = data.action;
    let SelectedDiaryUserArray = JSON.parse(localStorage.getItem("SelectedDiaryUsers"));
    // if(data.event.userIndex != null || data.event.userIndex != undefined){
    //   let selectedUser = SelectedDiaryUserArray[data.event.userIndex];
    //   this.SelectedDiaryUser = selectedUser;
    //   this.SelectedDiaryUser.FULLNAME = this.SelectedDiaryUser.FULLNAME;
    //   this.SelectedDiaryUser.USERGUID = this.SelectedDiaryUser.USERGUID;
      
      
    // }

    if (data.event.type == "ScheduleEditor") {
      this.SelectedDiaryUser=data.event; 
    }else if (SelectedDiaryUserArray && SelectedDiaryUserArray.length > 0) {
      // this.SelectedDiaryUser = SelectedDiaryUserArray[data.event.groupIndex];
      let selectedUser = SelectedDiaryUserArray.filter((obj)=>obj.USERGUID==data.event.USERGUID);
      this.SelectedDiaryUser = selectedUser[0];

    } else {
      this.SelectedDiaryUser = JSON.parse(localStorage.getItem('currentUser'));
      this.SelectedDiaryUser.FULLNAME = this.SelectedDiaryUser.UserName;
      this.SelectedDiaryUser.USERGUID = this.SelectedDiaryUser.UserGuid;
    }
    if (this.action === 'new') {
      this.dialogTitle = 'New Appointment';
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Appointment';
    } else {
      this.dialogTitle = 'Duplicate Appointment';
    }
    // this.behaviorService.dialogClose$.subscribe(result => {
    //   if(result != null){
    //     if(result.MESSAGE == 'Not logged in'){
    //       this.dialogRef.close(false);
    //     }
    //   }
    //  });

    // this.behaviorService.forDiaryRefersh$.subscribe(result => {
      this.DairyData = data;
    // });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'DairyDailogComponent' && result['click'] && result['data']['GetAppointment']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'DairyDailogComponent' && result['click'] && result['data']['SetAppointment']) {
        this.SaveAppointment();
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let SelectedDiaryUserArray = JSON.parse(localStorage.getItem("SelectedDiaryUsers"));
    if(this.data.event.userIndex != null && this.data.event.userIndex != undefined && SelectedDiaryUserArray){
      let selectedUser = SelectedDiaryUserArray[this.data.event.userIndex];
      this.SelectedDiaryUser = selectedUser;
      this.SelectedDiaryUser.FULLNAME = this.SelectedDiaryUser.FULLNAME;
      this.SelectedDiaryUser.USERGUID = this.SelectedDiaryUser.USERGUID;
    }

    this.diaryDialogOrigin = this.data.origin;
    this.SelectedMatterData = this.data;
    
    this.CheckClick = "No";
    this.DairyForm = this._formBuilder.group({
      APPOINTMENTGUID: [''],
      SUBJECT: [''],
      LOCATION: [''],
      MATTERGUID: [''],
      SendMATTERGUID: [''],
      APPOINTMENTDATE: [''],
      sendAPPOINTMENTDATE: [''],
      APPOINTMENTENDDATE: [''],
      DateFrom: [''],
      ALLDAYEVENT: [false],

      APPOINTMENTTIME: [''],
       TimeSlot2: [''],
      // APPOINTMENTENDTIME:[''],
      start: [''],
      Finish: [''],
      //Details Tab
      type: [''],
      Beforestart: [''],
      SendBeforestart: [''],
      Reminder: [''],
      Category: [''],
      Matter: [''],
      COMMENT: [''],
      //Recurrance-Pattern Tab
      Every: [''],
      EveryWeekly: [''],
      Senddayweek: [''],
      EveryMonthly: [''],
      EveryDay: [''],
      SendEveryDay: [''],
      countvalue: [''],
      Sendcountvalue: [''],
      DaySelect: [''],
      SendDaySelect: [''],
      EndDate: [''],
      SendEndDate: [''],
      ToSendEndDate: [''],
      RedioChnage: [''],
      RedioChnageDay: [''],
      RedioDate: [''],
      dayweek: [''],
      MONTHOPTIONS: [''],
      orders: new FormArray([])
    });
    //this.addCheckboxes();
    if (this.action == 'new') {
      this._mainAPiServiceService.getSetData({ FormAction: 'default', VALIDATEONLY: true, DATA: {} }, 'SetAppointment').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        if (res.CODE == 200) {
          this.isLoadingResults = false;
          this.toolTipList = res.DATA.FIELDTIPS;
          this.DairyForm.controls['type'].setValue('Conference');
          this.DairyForm.controls['Reminder'].setValue(true);
          this.DairyForm.controls['Beforestart'].setValue('15Minutes');
          this.DairyForm.controls['SendBeforestart'].setValue('15Minutes');


          var event1 = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
          let time2 = event1.toLocaleString();
         this.time3 = new Date(time2).getHours() + 1 + ":" + new Date(time2).getMinutes(); //+ ":" + new Date(time).getSeconds();
          this.DairyForm.controls['TimeSlot2'].setValue(this.time3);
          // this.DairyForm.controls['APPOINTMENTTIME'].setValue('09:00 AM');
          // this.DairyForm.controls['TimeSlot2'].setValue('10:00 AM');
          var event = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
          let time = event.toLocaleString();
          this.time = new Date(time).getHours() + 1 + ":" + new Date(time).getMinutes(); //+ ":" + new Date(time).getSeconds();
          // this.DairyForm.controls['TimeSlot2'].setValue('10:00 AM');
          this.DairyForm.controls['APPOINTMENTTIME'].setValue( this.time );
          if (this.DairyData.startTime) {
            this.DairyForm.controls['APPOINTMENTDATE'].setValue(this.DairyData.startTime);
            this.DairyForm.controls['sendAPPOINTMENTDATE'].setValue(this.datepipe.transform(this.DairyData.startTime, 'dd/MM/yyyy'));
          } else {
            // this.DairyForm.controls['APPOINTMENTDATE'].setValue(new Date());
            // this.DairyForm.controls['sendAPPOINTMENTDATE'].setValue(this.datepipe.transform(new Date(), 'dd/MM/yyyy'));
            // ______________________________________________we add time and today page date need ex :today 18/09/2022 i am on paGE 15/09/2022 I GET DATA IN date:15/09/2022
            this.time = moment(this.DairyData.event.startTime).format('HH:mm')
            this.DairyForm.controls['APPOINTMENTTIME'].setValue( this.time );
            this.time3 =  moment(this.DairyData.event.startTime).add(1, 'hours').format('HH:mm')//+ ":" + new Date(time).getSeconds();
            this.DairyForm.controls['TimeSlot2'].setValue(this.time3);
            // this.DairyForm.controls['APPOINTMENTENDTIME'].setValue( this.time );
            this.DairyForm.controls['APPOINTMENTDATE'].setValue(((!this.DairyData || this.DairyData.event.startTime == 'undefined' )?new Date(): this.DairyData.event.startTime));
            this.DairyForm.controls['sendAPPOINTMENTDATE'].setValue(((!this.DairyData || this.DairyData.event.startTime == 'undefined' )?new Date(): moment(this.DairyData.event.startTime).format("DD/MM/YYYY")));
          }

          let  startTime = moment(this.f.APPOINTMENTTIME.value, 'HH:mm');
          let  end = moment(this.f.TimeSlot2.value, 'HH:mm');
          this.timeDiffrence  =  moment.duration(end.diff(startTime));
          this.defaultRecuurance = '0'
          this.DairyForm.controls['RedioChnage'].setValue('0');
          this.CheckAllDays(false);
        } else if (res.MESSAGE === 'Not logged in') {
          this.dialogRef.close(false);
        }
        this.CheckdefultValidation(res.DATA.VALIDATIONS);
      }, error => {
        this.isLoadingResults = false;
        this.toastr.error(error);
      });
    } else {
      this.isLoadingResults = true;
    //  this.DairyAppointmentGUID = JSON.parse(localStorage.getItem('DairyAppointmentGUID'));
    // this.DairyAppointmentGUID = this.DairyData.DairyRowClickData
    this.DairyAppointmentGUID = this.data.event
    //  APPOINTMENTGUID: (this.DairyData.DairyRowClickData == '')? this.DairyAppointmentGUID[0].DairyRowClickData :this.DairyAppointmentGUID[0].DairyRowClickData}, 'GetAppointment').subscribe(res => {
        // 
        // this._mainAPiServiceService.getSetData({ APPOINTMENTGUID:  (this.DairyData.DairyRowClickData == '')? this.DairyAppointmentGUID[0].DairyRowClickData :this.DairyAppointmentGUID[0].DairyRowClickData}, 'GetAppointment').subscribe(res => {
      this._mainAPiServiceService.getSetData({ APPOINTMENTGUID:  this.DairyAppointmentGUID.DairyRowClickData}, 'GetAppointment').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {

        if (res.CODE == 200 && res.STATUS == "success") {
          let appointmentData: any = res.DATA.APPOINTMENTS[0];
          this.toolTipList = res.DATA.FIELDTIPS;
          let Date1 = appointmentData.APPOINTMENTDATE.split("/");
          let DDate = new Date(Date1[1] + '/' + Date1[0] + '/' + Date1[2]);
          this.DairyForm.controls['APPOINTMENTDATE'].setValue(DDate);
          this.DairyForm.controls['sendAPPOINTMENTDATE'].setValue(appointmentData.APPOINTMENTDATE);
          this.DairyForm.controls['SUBJECT'].setValue(appointmentData.SUBJECT);
          this.DairyForm.controls['LOCATION'].setValue(appointmentData.LOCATION);
          this.DairyForm.controls['ALLDAYEVENT'].setValue(Number(appointmentData.ALLDAYEVENT));
          let time = appointmentData.APPOINTMENTTIME;
    
          // this.DairyForm.controls['APPOINTMENTTIME'].setValue(this.time);
           this.DairyForm.controls['APPOINTMENTTIME'].setValue(time ? this.convert12to24Fomat(time) : this.DefaultReminderTime());
          // this.DairyForm.controls['APPOINTMENTTIME'].setValue(this.tConvert(appointmentData.APPOINTMENTTIME));
          // this.DairyForm.controls['APPOINTMENTTIME'].setValue(this.tConvert(appointmentData.APPOINTMENTTIME));
          let TimeSlotDATA = appointmentData.APPOINTMENTENDTIME;
          // this.DairyForm.controls['TimeSlot2'].setValue(this.tConvert(appointmentData.APPOINTMENTENDTIME));
          this.DairyForm.controls['TimeSlot2'].setValue((TimeSlotDATA ? this.convert12to24Fomat(TimeSlotDATA) : this.DefaultReminderTime()));
          //End time not yet 
          //Details
          this.DairyForm.controls['type'].setValue(appointmentData.APPOINTMENTTYPE);
          this.DairyForm.controls['Reminder'].setValue(Number(appointmentData.REMINDER));
          this.DairyForm.controls['Beforestart'].setValue(appointmentData.REMINDERMINUTESBEFORE + 'Minutes');
          this.DairyForm.controls['SendBeforestart'].setValue(appointmentData.REMINDERMINUTESBEFORE + 'Minutes');
          this.DairyForm.controls['Category'].setValue(appointmentData.CATEGORY);

          this.DairyForm.controls['APPOINTMENTGUID'].setValue(appointmentData.APPOINTMENTGUID);
          this.DairyForm.controls['MATTERGUID'].setValue(appointmentData.MATTERGUID);
          if(appointmentData.MATTERSHORTNAME && appointmentData.MATTERDESCRIPTION){
            // this.mattername = [appointmentData.MATTERSHORTNAME + '--' + appointmentData.MATTERDESCRIPTION]
            this.mattername = [appointmentData.SELECTEDDESCRIPTION]
           
        }else{
           this.mattername = [appointmentData.SELECTEDDESCRIPTION]
         
        }
          this.DairyForm.controls['Matter'].setValue(appointmentData.MATTERSHORTNAME);

          this.DairyForm.controls['SendMATTERGUID'].setValue(appointmentData.MATTERGUID);
          this.DairyForm.controls['COMMENT'].setValue(appointmentData.NOTE);


          //Recusion Tab
          // FREQUENCY:this.f.RedioChnage.value,
          this.DairyForm.controls['RedioChnage'].setValue(appointmentData.FREQUENCY);
          this.DairyForm.controls['Every'].setValue(appointmentData.DAYFREQUENCY);
          this.DairyForm.controls['EveryWeekly'].setValue(appointmentData.WEEKFREQUENCY);
          this.DairyForm.controls['dayweek'].setValue(appointmentData.WEEKDAYMASK);

          this.DairyForm.controls['EveryMonthly'].setValue(appointmentData.MONTHFREQUENCY);
          this.DairyForm.controls['MONTHOPTIONS'].setValue(appointmentData.MONTHOPTIONS);
          if (appointmentData.MONTHOPTIONS != '') {
            this.DairyForm.controls['countvalue'].disable();
            this.DairyForm.controls['DaySelect'].disable();
            this.DairyForm.controls['EveryDay'].disable();
          }
          this.DairyForm.controls['EveryDay'].setValue(appointmentData.MONTHOPTIONDAY);
          this.DairyForm.controls['SendEveryDay'].setValue(appointmentData.MONTHOPTIONDAY);
          this.DairyForm.controls['countvalue'].setValue(appointmentData.MONTHWHICHWEEK);
          this.DairyForm.controls['Sendcountvalue'].setValue(appointmentData.MONTHWHICHWEEK);
          this.DairyForm.controls['DaySelect'].setValue(appointmentData.MONTHWHICHDAY);
          this.DairyForm.controls['SendDaySelect'].setValue(appointmentData.MONTHWHICHDAY);

          if (appointmentData.RECURRINGUNTILDATE != '') {
            this.DairyForm.controls['EndDate'].disable();
            let Date2 = appointmentData.RECURRINGUNTILDATE.split("/");
            let DDate1 = new Date(Date2[1] + '/' + Date2[0] + '/' + Date2[2]);
            this.DairyForm.controls['EndDate'].setValue(DDate1);
            this.DairyForm.controls['ToSendEndDate'].setValue(DDate1);
          }

          let  startTime = moment(this.f.APPOINTMENTTIME.value, 'HH:mm');
          let  end = moment(this.f.TimeSlot2.value, 'HH:mm');
          this.timeDiffrence  =  moment.duration(end.diff(startTime));
        } else if (res.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
        this.isLoadingResults = false;
      }, err => {
        this.toastr.error(err);
        this.isLoadingResults = false;
      });
    }
    this.sub1 = this.DairyForm.valueChanges.subscribe((form) => {
      this.changeForControl(form)
    })
    this.setTooltipData()
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub1?.unsubscribe();
  }

  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['Appointment'] ){
      this.toolTipList  = TooltipData['Appointment'];
    }else{
      this.tooltipService.setToolTipData = ('Appointment');
     }
  }

  /**
   * This function is used to toggle the update
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  }

 /**
  * this function is used to change for control the data
  */
  changeForControl(formControl){
    if (formControl.ITEMDATETEXT) {
      formControl.ITEMDATE  = formControl.ITEMDATETEXT
    }
    const dubObj ={...this.errorWarningDataArray.Error};
    let element = this.errorWarningData.Error;
    for (const [key, value] of Object.entries(dubObj)) {
      const KEY = (key).toLocaleUpperCase();
      
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element[key];
       dubObj[key] = value;
       this.errorWarningDataArray.Error = {...dubObj}
     } else if (!formControl[KEY]) {
        element[key] = dubObj[key];
     }
    }

    const dubObj_1 ={...this.errorWarningDataArray.Warning};
    let element_1 = this.errorWarningData.Warning;
    for (const [key, value] of Object.entries(dubObj_1)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element_1[key];
       dubObj_1[key] = value;
       this.errorWarningDataArray.Warning = {...dubObj_1}
     } else if (!formControl[KEY]) {
        element_1[key] = dubObj_1[key];
     }
    }
  }

  /**
   * this function is used to check the validation
   */
  CheckdefultValidation(bodyData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
  }

  /**
   * this function is used to convert the time
   */
  tConvert(time) {
    time = time.trim().split(/(?=[A-Z]{2})/).join(" ");
    // time = time.split(':');
    // if (time.length > 0) { // If time format correct
    //   if (time[0] < 10) {
    //     time[0] = '0' + time[0];
    //   }
    //   // time.splice(2, 2);
    //   // var timeslote = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    // }
    // return time.join(':') + timeslote; // return adjusted time or original string
    return moment(time, ["h:mm A"]).format("HH:mm A");
  }
  get f() {
    return this.DairyForm.controls;
  }
  //DocumentSave
  SaveAppointment() {
    if (this.action === 'edit') {
      this.appoitmentID = this.f.APPOINTMENTGUID.value;
      this.FormAction = 'update';
    } else if (this.action === 'duplicate' || this.action === "new") {
      this.FormAction = 'insert';
      this.appoitmentID = '';
    }

    let data = {
      APPOINTMENTGUID: this.appoitmentID,
      SUBJECT: this.f.SUBJECT.value,
      LOCATION: this.f.LOCATION.value,
      ALLDAYEVENT: this.f.ALLDAYEVENT.value,
      APPOINTMENTDATE: this.f.sendAPPOINTMENTDATE.value,
      APPOINTMENTENDDATE: "",
      APPOINTMENTTIME: this.App_StartTime,
      ENDTIME: this.App_EndTime,
      APPOINTMENTTYPE: this.f.type.value,
      REMINDER: this.f.Reminder.value,
      REMINDERMINUTESBEFORE: this.f.SendBeforestart.value,
      CATEGORY: this.f.Category.value,
      MATTERGUID: this.f.SendMATTERGUID.value,
      USERGUID: this.SelectedDiaryUser.USERGUID,
      NOTE: this.f.COMMENT.value,

      FREQUENCY: this.f.RedioChnage.value,
      DAYFREQUENCY: this.f.Every.value,

      WEEKFREQUENCY: this.f.EveryWeekly.value,
      WEEKDAYMASK: this.f.Senddayweek.value,

      MONTHFREQUENCY: this.f.EveryMonthly.value,
      // MONTHOPTIONS: this.f.EveryDay.value,
      MONTHOPTIONS: this.f.MONTHOPTIONS.value,
      MONTHOPTIONDAY: this.f.SendEveryDay.value,
      MONTHWHICHWEEK: this.f.Sendcountvalue.value,
      MONTHWHICHDAY: this.f.SendDaySelect.value,

      RECURRINGUNTIL: this.f.ToSendEndDate.value,
      RECURRINGUNTILDATE: ''

      // SYNCHRONISINGINFO:{
      //   DATECREATED:"",
      //   TIMECREATED:"",
      //   DATEMODIFIED:"",
      //   TIMEMODIFIED:""
      // }
    }

    // send category billable if diary have MatterGUID
    if(data['MATTERGUID'] != '') {
      data["Category"] = "Billable";
    }
    // ends here ~ send category billable if diary have MatterGUID

    this.isspiner = true;
    let finalData = { DATA: data, FormAction: this.FormAction, VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'SetAppointment').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }

    // });
    // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };

    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.appotmentSaveData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.appotmentSaveData(details);
    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.appotmentSaveData(details);
        }
      }
    });
  }
  appotmentSaveData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'SetAppointment').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.behaviorService.forDiaryRefersh2("call");
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }
  //DateFrom
  DateFrom(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.DairyForm.controls['sendAPPOINTMENTDATE'].setValue(begin);

  }

  //CheckAllDays
  CheckAllDays(val) {
  
    if (val == true) {
      this.CheckClick = "Yes";
      this.App_StartTime = "";
      this.App_EndTime = "";
    } else {
      this.CheckClick = "No"; 
      this.App_StartTime = this.f.APPOINTMENTTIME.value;
       this.App_EndTime = this.f.TimeSlot2.value;
    }
  }
  StartTime() {
    this.App_StartTime = this.f.APPOINTMENTTIME.value;
    this.autocalculate();
  }
  EndTime() { 
    this.App_EndTime = this.f.TimeSlot2.value;
  };

  autocalculate(){
    // auto time diffrence calculated.
    if(this.timeDiffrence){
      var date = moment(this.App_StartTime , 'HH:mm').add(this.timeDiffrence['_data'].hours, 'hours').add(this.timeDiffrence['_data'].minutes, 'minutes').format('HH:mm A')
      let time : any = date.split(':');
      let minute = time[1].split(' ');
      let startDate = this.App_StartTime;

      startDate = startDate.split(':');
      if (startDate[0] =='00') time[0] = Number(time[0]) + 12;
      if (startDate[0] == '12') {
        time[0] = Number(time[0]) - 12;
        if(Number(time[0]) < 10){
          time[0] = '0'+time[0];
        }
      }

      this.App_EndTime = time[0].toString() + ':' + minute[0];
      this.DairyForm.controls['TimeSlot2'].setValue(this.App_EndTime);
    }
  };

  getTimeStops(start, end) {
    var startTime = moment(start, 'HH:mm');
    var endTime = moment(end, 'HH:mm');
    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }
    var timeStops = [];
    while (startTime <= endTime) {
      timeStops.push(moment(startTime).format('HH:mm A'));
      startTime.add(15, 'minutes');
    }
    return timeStops;
  }
  convert12to24Fomat(time: string): string {
    var [shours, sminutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    var hours = Number(shours);
    var minutes = Number(sminutes);
    var AMPM = time.slice(-2);
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  }
  //set default reminder time on edit
  DefaultReminderTime(): string {
    var event = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
    let time = event.toLocaleString();
    return new Date(time).getHours() + 1 + ":" + new Date(time).getMinutes();
  }

}
