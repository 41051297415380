<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_allowance">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.PAYEE,'has-warning':errorWarningData.Warning?.PAYEE}" class="example" appearance="outline" fxFlex="46" class="pr-4">
                        <mat-label>Payee</mat-label>
                        <input [(ngModel)]="PAYEE" name="PAYEE" (ngModelChange)="changeForControl('PAYEE', $event)" matInput  matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.PRICEFROM,'has-warning':errorWarningData.Warning?.PRICEFROM}" appearance="outline" fxFlex="17" class="pr-4">
                        <mat-label>Amount</mat-label>
                        <!-- Removed (focusout)="amoutCal()" from below input -->
                        <input style="text-align: right;" [formControl]="amountValue" [value]="amountValue.value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [(ngModel)]="AMOUNT" name="AMOUNT" (blur)="changeForControl('PRICEFROM', $event)" matInput  matTooltip="{{(toolTipList)?toolTipList.AMOUNT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field  appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PAYMENTDATE,'has-warning':errorWarningData.Warning?.PAYMENTDATE}">
                        <mat-label>Payment Date</mat-label>
                        <!-- Removed (focusout)="amoutCal()" from below input -->
                        <input matInput placeholder="Choose a To date"   [(ngModel)]="PAYMENTDATE" name="PAYMENTDATE" (ngModelChange)="getPaymentDate(PAYMENTDATE);changeForControl('PAYMENTDATE', $event)"
                        [matDatepicker]="toDate">
                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>  
                    <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.PAYMENTDATE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNT?.PAYMENTDATE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="17" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PAYMENTTYPE,'has-warning':errorWarningData.Warning?.PAYMENTTYPE}">
                        <mat-label>Payment Type</mat-label>
                        <mat-select [(ngModel)]="PAYMENTTYPE" name="PAYMENTTYPE" (ngModelChange)="getPaymentType(PAYMENTTYPE);changeForControl('PAYMENTTYPE', $event)">
                            <mat-option value="BPay">BPay</mat-option>
                            <mat-option value="EFT">EFT</mat-option>
                            <mat-option value="Foreign EFT">Foreign EFT</mat-option>
                            <mat-option value="Other">Other</mat-option>

                        </mat-select>   
                    <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>




                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="PAYMENTTYPE =='BPay'">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BPAYBILLERCODE,'has-warning':errorWarningData.Warning?.BPAYBILLERCODE}">
                            <mat-label>Biller Code</mat-label>
                            <input [(ngModel)]="BPAYBILLERCODE" (ngModelChange)="changeForControl('BPAYBILLERCODE', $event)" name="BPAYBILLERCODE"  matInput  matTooltip="{{(toolTipList)?toolTipList.BPAYBILLERCODE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                         <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->   
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.BPAYREFERENCE,'has-warning':errorWarningData.Warning?.BPAYREFERENCE}" class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Reference</mat-label>
                        <input [(ngModel)]="BPAYREFERENCE" name="BPAYREFERENCE" (ngModelChange)="changeForControl('BPAYREFERENCE', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.BPAYREFERENCE ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="PAYMENTTYPE =='EFT'">
                    <mat-form-field class="example" appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNAME,'has-warning':errorWarningData.Warning?.ACCOUNTNAME}">
                            <mat-label>Account Name</mat-label>
                            <input [(ngModel)]="ACCOUNTNAME" (ngModelChange)="changeForControl('ACCOUNTNAME', $event)" name="ACCOUNTNAME"  matInput  matTooltip="{{(toolTipList)?toolTipList.BPAYBILLERCODE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                         <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE }}"><mat-icon >file_copy</mat-icon></div> -->   
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.BSB,'has-warning':errorWarningData.Warning?.BSB}" class="example" appearance="outline" fxFlex="25" class="pr-4">
                        <mat-label>BSB</mat-label>
                        <input [(ngModel)]="BSB" name="BSB" (ngModelChange)="changeForControl('BSB', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.BSB ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BSB?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BSB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.ACCOUNTNUMBER}" class="example" appearance="outline" fxFlex="25" class="pr-4">
                        <mat-label>Account Number</mat-label>
                        <input [(ngModel)]="ACCOUNTNUMBER" name="ACCOUNTNUMBER" (ngModelChange)="changeForControl('ACCOUNTNUMBER', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.ACCOUNTNUMBER ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.EFTREFERENCE,'has-warning':errorWarningData.Warning?.EFTREFERENCE}" class="example" appearance="outline" fxFlex="25" class="pr-4">
                        <mat-label>EFT Reference</mat-label>
                        <input [(ngModel)]="EFTREFERENCE" name="EFTREFERENCE" (ngModelChange)="changeForControl('EFTREFERENCE', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.EFTREFERENCE ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EFTREFERENCE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.EFTREFERENCE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="PAYMENTTYPE =='Foreign EFT'">
                    <mat-form-field class="example" appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTSWIFTCODE ,'has-warning':errorWarningData.Warning?.FOREIGNEFTSWIFTCODE }">
                            <mat-label>Swift Code</mat-label>
                            <input [(ngModel)]="FOREIGNEFTSWIFTCODE " (ngModelChange)="changeForControl('FOREIGNEFTSWIFTCODE ', $event)" name="FOREIGNEFTSWIFTCODE "  matInput  matTooltip="{{(toolTipList)?toolTipList.FOREIGNEFTSWIFTCODE ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                         <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTSWIFTCODE ?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTSWIFTCODE ?.COPYVALUE }}"><mat-icon >file_copy</mat-icon></div> -->   
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTIBAN,'has-warning':errorWarningData.Warning?.FOREIGNEFTIBAN}" class="example" appearance="outline" fxFlex="33.3" class="pr-4">
                        <mat-label>IBAN</mat-label>
                        <input [(ngModel)]="FOREIGNEFTIBAN" name="FOREIGNEFTIBAN" (ngModelChange)="changeForControl('FOREIGNEFTIBAN', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.FOREIGNEFTIBAN ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTIBAN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTIBAN?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTBANKNAME,'has-warning':errorWarningData.Warning?.FOREIGNEFTBANKNAME}" class="example" appearance="outline" fxFlex="33.3" class="pr-4">
                        <mat-label>Bank Name</mat-label>
                        <input [(ngModel)]="FOREIGNEFTBANKNAME" name="FOREIGNEFTBANKNAME" (ngModelChange)="changeForControl('FOREIGNEFTBANKNAME', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.FOREIGNEFTBANKNAME ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTBANKNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTBANKNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="PAYMENTTYPE =='Foreign EFT'">
                    <mat-form-field class="example" appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCOUNTRY ,'has-warning':errorWarningData.Warning?.FOREIGNEFTCOUNTRY }">
                            <mat-label>Country</mat-label>
                            <input [(ngModel)]="FOREIGNEFTCOUNTRY " (ngModelChange)="changeForControl('FOREIGNEFTCOUNTRY ', $event)" name="FOREIGNEFTCOUNTRY "  matInput  matTooltip="{{(toolTipList)?toolTipList.FOREIGNEFTCOUNTRY ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                         <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTCOUNTRY ?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTCOUNTRY ?.COPYVALUE }}"><mat-icon >file_copy</mat-icon></div> -->   
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTAMOUNT,'has-warning':errorWarningData.Warning?.FOREIGNEFTAMOUNT}" class="example" appearance="outline" fxFlex="33.3" class="pr-4">
                        <mat-label>Amount </mat-label>
                        <input currencyMask [(ngModel)]="FOREIGNEFTAMOUNT" name="FOREIGNEFTAMOUNT" (ngModelChange)="changeForControl('FOREIGNEFTAMOUNT', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.FOREIGNEFTAMOUNT ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTAMOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTAMOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCONVERSIONDETAILS,'has-warning':errorWarningData.Warning?.FOREIGNEFTCONVERSIONDETAILS}" class="example" appearance="outline" fxFlex="33.3" class="pr-4">
                        <mat-label>Conversion Details</mat-label>
                        <input [(ngModel)]="FOREIGNEFTCONVERSIONDETAILS" name="FOREIGNEFTCONVERSIONDETAILS" (ngModelChange)="changeForControl('FOREIGNEFTCONVERSIONDETAILS', $event)"  matInput  matTooltip="{{(toolTipList)?toolTipList.FOREIGNEFTCONVERSIONDETAILS ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                     <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTCONVERSIONDETAILS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTCONVERSIONDETAILS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                    </mat-form-field>
                </div>

            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" (click)="saveCheque()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" (click)="saveCheque()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>