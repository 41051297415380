
<div class=" one " fxLayout="column" [formGroup]="DomenesticViolenceForm">
    <mat-accordion class="faqs-accordion">
        <mat-expansion-panel label="Details" fxLayout="column">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="80">
                        <strong>Criminal Details</strong>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
                <app-criminal  [testBool]='testBool' [action]="action" [classType]='classType' [toolTipList]="toolTipList"></app-criminal>
        </mat-expansion-panel>
        <mat-expansion-panel label="Details" fxLayout="column">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="80">
                        <strong>Family Law Details</strong>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
                <app-family [testBool]='testBool' [action]="action" [classType]='classType' [toolTipList]="toolTipList"></app-family>
        </mat-expansion-panel>
    </mat-accordion>
</div>