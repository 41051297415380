import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
    selector: "app-logout-error",
    templateUrl: "./logout-error.component.html",
    styleUrls: ["./logout-error.component.scss"],
})
export class LogoutErrorComponent implements OnInit {
    confirmMessage: any = "";
    loginUserData: any = [];
    confirm1: any = "";
    titleData: any = "";
    isTomany = false;
    confirm2: any = "";
    cancleBtn: boolean = false;
    datais: any;

    activeCloseWindow: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<LogoutErrorComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        // this.isTomany = data.CODE == 403 ? true : false;
        // if (this.isTomany) {
        //   this.confirm1 = "Ok";
        //   // this.confirm1 = "Log this session out";
        //   this.titleData = "All licences in use";
        //   this.confirmMessage = data.MESSAGE;
        //   this.loginUserData = data.DATA.LOGGEDONUSERS;
        // } else {
        //   this.confirm1 = "Ok";
        //   this.titleData = "Logged Off";
        //   this.confirmMessage = data.MESSAGE;
        // }

        this.isTomany = data.CODE == 403 ? true : false;
        if (this.isTomany) {
            this.confirm1 = "Ok";
            // this.confirm1 = "Log this session out";
            this.titleData = "All licences in use";
            this.confirmMessage = data.MESSAGE;
            this.loginUserData = data.DATA.LOGGEDONUSERS;
        } else {
            //old
            // this.confirm1 = "Ok";
            // this.titleData = "Logged Off";
            // this.confirmMessage = data.MESSAGE;

            let message = data.MESSAGE
                ? data.MESSAGE
                : "You have logout this session ";
            if (data.USEV11UI) {
                this.confirmMessage = message;
                this.titleData = "Login into New UI";
                this.confirm1 = "Yes";
                this.confirm2 = "No";
                this.cancleBtn = data.USEV11UI;
                this.datais = data;
                return;
            }

            this.confirm1 = "Refresh";
            this.confirm2 = "Log back in";
            this.titleData = "Logged Off";
            this.confirmMessage =
                message +
                " If you have already logged back in on another tab then click the Refresh button and the page will reload.";
        }
    }

    /**
     * this function is used to refresh the data value
     */
    refreshIt() {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    /**
     * This function is used to redirct to the new ui.
     */
    redirectIntoNewUI() {
        this.dialogRef.close(this.datais);
        return this.datais;
    }

    /**
     * This function is used to close the Dialog
     */
    closeDilog() {
        this.dialogRef.close(false);
        return this.datais;
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        // let data = JSON.parse(localStorage.getItem('USEV11UI'));
        // if(data){
        //   window.close();
        // }
    }
}
