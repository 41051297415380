import { Component, OnInit, OnDestroy } from '@angular/core';
import { FuseConfigService } from 'src/@fuse/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit, OnDestroy {
  timeData: any;
  sub1: Subscription;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    public behaviorService: BehaviorService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: { navbar: { hidden: false }, toolbar: { hidden: false }, footer: { hidden: false }, sidepanel: { hidden: false } }
      // layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
    };
  }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.timeData = setInterval(() => this.checkserver(), 6000);
  }
  ngOnDestroy(): void {
    clearInterval(this.timeData);
    this.sub1?.unsubscribe();  
  } 
  checkserver() {
    this.sub1 = this.http.post<any>(environment.APIEndpoint + 'login', { request: 'maintainlicence', "BACKGROUND": true }).subscribe(loginResponse => {
      this.behaviorService.dialogClose(loginResponse);
      if (loginResponse.MESSAGE == 'Not logged in' || (loginResponse.STATUS == "" && loginResponse.MESSAGE == "")) {
        this.router.navigate(['login']);
      } else if (loginResponse.STATUS == 'success') {
        let defult_routeData: any = JSON.parse(localStorage.getItem('defult_routeData')) ? JSON.parse(localStorage.getItem('defult_routeData')) : ['matters'];
        this.router.navigate(defult_routeData);
      }
    }, error => {
      this.toastr.error(error);
    });
  }
}
