import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "appPermissions",
    pure: true,
})
export class AppPermissionsPipe implements PipeTransform {
    app_permissions = JSON.parse(localStorage.getItem("app_permissions"));

    /**
     * This function is used to transform the data.
     */
    transform(value: any, ...args: any[]): any {
        // for (let key in this.app_permissions) {
        //   if(this.app_permissions[key] !== null){

        //   }
        // }

        // Please Input Permisstion name [specific Ex: 'Create WIP for Other Fee Earners'].
        const accessPermisstionIs = this.app_permissions.filter(
            (e) => e !== null && e[value]
        );
        let permission =
            accessPermisstionIs.length !== 0
                ? accessPermisstionIs[0][value]
                : 1;
        return permission;
    }
}
