import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-future-years-leasing',
  templateUrl: './future-years-leasing.component.html',
  styleUrls: ['./future-years-leasing.component.scss']
})
export class FutureYearsLeasingComponent implements OnInit {
  @Input() matterLeasingForm: FormGroup;
  @Input() testBool;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  constructor() { }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    navigator.clipboard.writeText(event)
  }
}
