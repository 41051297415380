<div class=" one  mb-24" [formGroup]="contactForm" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PHONE,'has-warning':errorWarningData.Warning?.PHONE}">
            <mat-label>Phone 1</mat-label>
            <input type="text" name="PHONE" placeholder="Phone 1" aria-label="Number" matInput formControlName="PHONE" (focusout)="numberFormate($event)" matTooltip="{{allToolTip.PHONE?.HOVER}}">
            <!-- <mat-autocomplete #PHONE="matAutocomplete">
                <mat-option class="main_mat_option" (onSelectionChange)="updateMySelection($event)"
                    *ngFor="let option of filteredOptions | async" [value]="option.dial_code">
                    <div class="country_name"> {{option.name}}</div>
                    <div class="country_code">{{option.dial_code}}</div>
                </mat-option>
            </mat-autocomplete> -->
            <mat-icon matSuffix class="secondary-text">phone</mat-icon>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PHONE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PHONE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PHONE2,'has-warning':errorWarningData.Warning?.PHONE2}">
            <mat-label>Phone 2</mat-label>
            <input type="text" name="PHONE2" placeholder="Phone 2" aria-label="Number" matInput formControlName="PHONE2" (focusout)="numberFormate($event)" matTooltip="{{allToolTip.PHONE2?.HOVER}}">
            <!-- <mat-autocomplete #PHONE2="matAutocomplete">
                <mat-option class="main_mat_option" (onSelectionChange)="updateMySelection($event)"
                    *ngFor="let option of filteredOptionsPhone_2 | async" [value]="option.dial_code">
                    <div class="country_name"> {{option.name}}</div>
                    <div class="country_code">{{option.dial_code}}</div>
                </mat-option>
            </mat-autocomplete> -->
            <mat-icon matSuffix class="secondary-text">phone</mat-icon>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PHONE2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PHONE2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAX,'has-warning':errorWarningData.Warning?.FAX}">
            <mat-label>Fax</mat-label>
            <input type="text" name="FAX" placeholder="Fax" aria-label="Number" matInput formControlName="FAX" (focusout)="numberFormate($event)" matTooltip="{{allToolTip.FAX?.HOVER}}">
            <!-- <mat-autocomplete #FAX="matAutocomplete">
                <mat-option class="main_mat_option" (onSelectionChange)="updateMySelection($event)"
                    *ngFor="let option of filteredOptionsFax_1 | async" [value]="option.dial_code">
                    <div class="country_name"> {{option.name}}</div>
                    <div class="country_code">{{option.dial_code}}</div>
                </mat-option>
            </mat-autocomplete> -->
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAX?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAX?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAX2,'has-warning':errorWarningData.Warning?.FAX2}">
            <mat-label>Fax 2</mat-label>
            <input type="text" name="FAX2" placeholder="fax 2" aria-label="Number" matInput formControlName="FAX2" (focusout)="numberFormate($event)" matTooltip="{{allToolTip.FAX2?.HOVER}}">
            <!-- <mat-autocomplete #FAX2="matAutocomplete">
                <mat-option class="main_mat_option" (onSelectionChange)="updateMySelection($event)"
                    *ngFor="let option of filteredOptionsFax_2 | async" [value]="option.dial_code">
                    <div class="country_name"> {{option.name}}</div>
                    <div class="country_code">{{option.dial_code}}</div>
                </mat-option>
            </mat-autocomplete> -->
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAX2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAX2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MOBILE,'has-warning':errorWarningData.Warning?.MOBILE}">
            <mat-label>Mobile</mat-label>
            <input type="text" name="MOBILE" placeholder="Mobile" aria-label="Number" matInput formControlName="MOBILE" (focusout)="numberFormate($event)" matTooltip="{{allToolTip.MOBILE?.HOVER}}">
            <!-- <mat-autocomplete #MOBILE="matAutocomplete">
                <mat-option class="main_mat_option" (onSelectionChange)="updateMySelection($event)"
                    *ngFor="let option of filteredOptionsMobile | async" [value]="option.dial_code">
                    <div class="country_name"> {{option.name}}</div>
                    <div class="country_code">{{option.dial_code}}</div>
                </mat-option>
            </mat-autocomplete> -->
            <mat-icon matSuffix class="secondary-text">phone</mat-icon>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MOBILE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MOBILE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.EMAIL,'has-warning':errorWarningData.Warning?.EMAIL}">
            <mat-label>Email</mat-label>
            <input name="EMAIL" [formControlName]="'EMAIL'" matInput matTooltip="{{allToolTip.EMAIL?.HOVER}}">
            <mat-icon matSuffix class="secondary-text">mail</mat-icon>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EMAIL?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.EMAIL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.EMAIL2,'has-warning':errorWarningData.Warning?.EMAIL2}">
            <mat-label>Email 2</mat-label>
            <input name="EMAIL2" [formControlName]="'EMAIL2'" matInput matTooltip="{{allToolTip.EMAIL2?.HOVER}}">
            <mat-icon matSuffix class="secondary-text">mail</mat-icon>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EMAIL2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.EMAIL2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.WEBADDRESS,'has-warning':errorWarningData.Warning?.WEBADDRESS}">
            <mat-label>Website</mat-label>
            <input name="WEBADDRESS" [formControlName]="'WEBADDRESS'" matInput matTooltip="{{allToolTip.WEBADDRESS?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WEBADDRESS?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.WEBADDRESS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SKYPEUSERNAME,'has-warning':errorWarningData.Warning?.SKYPEUSERNAME}">
            <mat-label>Skype Username</mat-label>
            <input name="SKYPEUSERNAME" [formControlName]="'SKYPEUSERNAME'" matInput matTooltip="{{allToolTip.SKYPEUSERNAME?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SKYPEUSERNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SKYPEUSERNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ELECTRONICSERVICEEMAIL,'has-warning':errorWarningData.Warning?.ELECTRONICSERVICEEMAIL}">
            <mat-label>Electronic Service Email</mat-label>
            <input name="ELECTRONICSERVICEEMAIL" [formControlName]="'ELECTRONICSERVICEEMAIL'" matInput matTooltip="{{allToolTip.ELECTRONICSERVICEEMAIL?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ELECTRONICSERVICEEMAIL?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.ELECTRONICSERVICEEMAIL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
</div>