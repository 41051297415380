import { BehaviorService } from '@_services/Behavior.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformAmount'
})
export class TransformAmountPipe implements PipeTransform {
  // transform(value: any): string {
  //   let val = JSON.parse(value);
  //   if (val % 1 === 0) {
  //     return val.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // No decimal places if it's a whole number
  //   } else {
  //     return val.toFixed(2); // Display with two decimal places
  //   }
    // }
    CurrenctSymbol: any = ''
    constructor(private behaviorService: BehaviorService,) {

        /**
         * Use this behaviour for the Currency Symbol
         */
        this.behaviorService.CURRENCYSYMBOL$.subscribe(res => {
            if (res && res !== '') {
                this.CurrenctSymbol = res
            }
        })
    }
  
     /**
     * This function is used to transform the data.
     */
    transform(value: any): string {
    let val = typeof value === 'string' ? parseFloat(value) : value;

    if (!isNaN(val)) {
        if (Number.isInteger(val)) {
          return this.CurrenctSymbol + this.addCommas(val);
      } else {
            return this.CurrenctSymbol + val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      return ''; // Handle non-numeric values
    }
  }

  /**
   * This function is used to add the comas
   */
  private addCommas(value: number): string {
      return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
