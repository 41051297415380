<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div view_invoice_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">
        View Invoice
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="invoiceDetailForm" id="invoiceDetailForm" [formGroup]="invoiceDetailForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form" class="generalReceiptForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="37" class="pr-4">
                        <mat-label>{{('' | productType)?'Client' :'  Solicitor'}}</mat-label>
                        <input matInput readonly placeholder="Client" formControlName="CLIENTNAME">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CLIENTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="37" class="pr-4">
                        <mat-label>Matter</mat-label>
                        <input matInput placeholder="Matter" readonly  formControlName="SHORTNAME">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHORTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SHORTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4">
                        <mat-label>Invoice No</mat-label>
                        <input matInput placeholder="Invoice No" readonly [matTooltip]="tootipData.INVOICECODE" formControlName="INVOICECODE">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICECODE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICECODE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="view-invoice field-wrap">
                    <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4">
                        <mat-label>Invoice Date</mat-label>
                        <input (dateInput)="choosedInvoiceDate('input', $event)"
                            (dateChange)="choosedInvoiceDate('change', $event)" matInput readonly
                            [satDatepicker]="picker2" [matTooltip]="tootipData.INVOICEDATE" formControlName="INVOICEDATETEXT">
                        <sat-datepicker #picker2> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4">
                        <mat-label>Due Date</mat-label>
                        <input (dateInput)="choosedDueDate('input', $event)"
                            (dateChange)="choosedDueDate('change', $event)" matInput readonly
                            [matTooltip]="tootipData.DUEDATETEXT" formControlName="DUEDATETEXT" [satDatepicker]="picker1">
                        <sat-datepicker #picker1> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="picker1"></sat-datepicker-toggle>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUEDATETE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DUEDATETE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field *ngIf="tabingVal =='Time Entries'" appearance="outline" fxFlex="18" class="example" class="pr-4">
                        <mat-label>Invoice Total</mat-label>
                        <input class="sideText" matInput (focusout)="totalchange()" [matTooltip]="tootipData.INVOICETOTAL" formControlName="INVOICETOTAL">
                        <span class="currency_sign" matPrefix>$&nbsp;</span>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICETOTAL?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICETOTAL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field *ngIf="tabingVal =='Time Entries'" appearance="outline" fxFlex="18" class="example" class="pr-4">
                        <mat-label>GST</mat-label>
                        <input class="sideText" matInput readonly [matTooltip]="tootipData.GST" formControlName="GST">
                        <span class="currency_sign" matPrefix>$&nbsp;</span>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field *ngIf="tabingVal =='Time Entries'" appearance="outline" fxFlex="18" class="example" class="pr-4">
                        <mat-label>Total Inc GST</mat-label>
                        <input class="sideText" matInput readonly [matTooltip]="tootipData.AMOUNTTOTAL" formControlName="AMOUNTTOTAL">
                        <span class="currency_sign" matPrefix>$&nbsp;</span>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTTOTAL?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNTTOTAL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="">
                </div>
                <div class="one">
                    <mat-tab-group (selectedTabChange)="TabingClick($event.tab.textLabel)">
                        <mat-tab label="Time Entries" class="time-entries">
                            <div class="example-containerdata example-containerdata-invoice-view">
                                <!-- <mat-table mat-table #table [dataSource]="invoiceDatasor"
                                    [@animateStagger]="{value:'50'}" matSort class="">
                                    <ng-container matColumnDef="ITEMDATE">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                                            {{row.ITEMDATE}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="FEEEARNER">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>FE</mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                                            {{row.FEEEARNER }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="ADDITIONALTEXT">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Text</mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                                            {{row.ADDITIONALTEXT }}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="PRICE">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Charge</mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                                            {{row.PRICE | toFixedAmount:true}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="GST">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>GST</mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                                            {{row.GST | toFixedAmount:true}}
                                        </mat-cell>
                                    </ng-container>
                                    <mat-header-row class="tbl_fix_header"
                                        *matHeaderRowDef="displayedColumnsTime; sticky: true"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumnsTime;let element;"
                                        class="row" (click)="RowClick(row);highlightedRows=row.WORKITEMGUID"
                                        [style.background]="highlightedRows == row.WORKITEMGUID ? selectedColore : ''"
                                        [ngClass]="highlightedRows == row.WORKITEMGUID ? 'row-text-colore' : ''"
                                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                    </mat-row>
                                    <ng-container matColumnDef="groupHeader">
                                        <th class="special-group-header" colspan="999" *matCellDef="let group">
                                            <strong style="margin-left: 25px;">Item Type: {{group?.ITEMTYPEDESC}}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                Price(Ex): {{tempTotalData[group.ITEMTYPEDESC]?.PRICE |currency}}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                Price:
                                                {{tempTotalData[group.ITEMTYPEDESC]?.PRICEINCGST |currency}}</strong>
                                        </th>
                                    </ng-container>
                                    <mat-row *matRowDef="let row; columns: ['groupHeader']; when:isGroup"></mat-row>
                                </mat-table> -->

                                <mat-table mat-table #table [dataSource]="invoiceDatasor" [@animateStagger]="{value:'50'}" (matSortChange)="sortingCLM($event)" matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear matSort >
                                 
                                <ng-container matColumnDef="ITEMDATE">
                                    <mat-header-cell class="text_with_class_date" style="width: 100px;"  *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                                    <mat-cell class="text_with_class_date" style="width: 100px;" *matCellDef="let row">
                                        {{row.ITEMDATE}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="FEEEARNER" *ngIf = "this.currentUser.PRODUCTTYPE== 'Solicitor'">
                                    <mat-header-cell class="text_with_class_date"  style="width: 100px;" *matHeaderCellDef mat-sort-header>FE</mat-header-cell>
                                    <mat-cell class="text_with_class_date"  style="width: 100px;" *matCellDef="let row">
                                        {{row.FEEEARNER }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ADDITIONALTEXT">
                                    <mat-header-cell class="text_with_class"  style="width: 130px;" *matHeaderCellDef mat-sort-header>Text</mat-header-cell>
                                    <mat-cell class="text_with_class"  style="width: 130px;" *matCellDef="let row">
                                        {{row.ADDITIONALTEXT }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="PRICE">
                                    <mat-header-cell style="width: 110px;" *matHeaderCellDef mat-sort-header>Price (Ex)</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.PRICE | toFixedAmount:true}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="GST">
                                    <mat-header-cell style="width: 100px;" *matHeaderCellDef mat-sort-header>GST</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.GST | toFixedAmount:true}}
                                    </mat-cell>
                                </ng-container>
                                  <ng-container matColumnDef="PRICEINCGST">
                                    <mat-header-cell style="width: 100px;" *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.PRICEINCGST | toFixedAmount:true }}
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row class="tbl_fix_header"
                                    *matHeaderRowDef="displayedColumnsTime; sticky: true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsTime;let element;"
                                    class="row" (click)="RowClick(row);highlightedRows=row.WORKITEMGUID"
                                    [style.background]="highlightedRows == row.WORKITEMGUID ? selectedColore : ''"
                                    [ngClass]="highlightedRows == row.WORKITEMGUID ? 'row-text-colore' : ''"
                                    matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                </mat-row>
                                <ng-container matColumnDef="groupHeader">
                                    <th class="special-group-header" colspan="999" *matCellDef="let group">
                                        <strong style="margin-left: 25px;">Item Type: {{group?.ITEMTYPEDESC}}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Price(Ex): {{tempTotalData[group.ITEMTYPEDESC]?.PRICE |currency}}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Price:
                                            {{tempTotalData[group.ITEMTYPEDESC]?.PRICEINCGST |currency}}</strong>
                                    </th>
                                </ng-container>
                                <mat-row *matRowDef="let row; columns: ['groupHeader']; when:isGroup"></mat-row>
                            </mat-table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" showFirstLastButtons></mat-paginator>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <mat-form-field appearance="outline" class="example" fxFlex="100" class="pr-4">
                                    <mat-label>Comment</mat-label>
                                    <textarea matInput [matTooltip]="tootipData.COMMENT" formControlName="COMMENT"></textarea>
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            </div>

                        </mat-tab>
                        <mat-tab label="Receipts">
                            <div class="example-containerdata example-containerdata-invoice-view">
                                <table mat-table #table [dataSource]="ReceiptsData" [@animateStagger]="{value:'50'}"
                                    matSort class="mat-elevation-z8">
                                    <ng-container matColumnDef="RECEIPTCODE">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Receipt</th>
                                        <td mat-cell *matCellDef="let row">
                                            <p class="job-title text-truncate">
                                                {{row.RECEIPTCODE}}
                                            </p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="RECEIPTDATE">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Received</th>
                                        <td mat-cell *matCellDef="let row">
                                            <p class="company text-truncate">
                                                {{row.RECEIPTDATE }}
                                            </p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Type">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                        <td mat-cell *matCellDef="let row">
                                            <p class="company text-truncate">
                                                {{row.Type }}
                                            </p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="AMOUNT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                                        <td mat-cell *matCellDef="let row">
                                            <p class="company text-truncate">
                                                {{row.AMOUNT | toFixedAmount:true}}
                                            </p>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row class="tbl_fix_header"
                                        *matHeaderRowDef="displayedColumnsRecipt; sticky: true"></tr>
                                    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumnsRecipt;" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                        <tr mat-row [style.background]="highlightedRows == row.PERSONGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumnsRecipt;" [ngClass]="highlightedRows == row.PERSONGUID ? 'row-text-colore' : ''" class="contact" (click)="editContact(row.PERSONGUID);highlightedRows = row.PERSONGUID"
                                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                    </tr> -->
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsRecipt;let element;"
                                        class="row" (click)="RowClick(row);highlightedRows=row.RECEIPTCODE"
                                        [style.background]="highlightedRows == row.RECEIPTCODE ? selectedColore : ''"
                                        [ngClass]="highlightedRows == row.RECEIPTCODE ? 'row-text-colore' : ''"
                                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                    </tr>
                                </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" showFirstLastButtons></mat-paginator>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4">
                                    <mat-label>Invoice Total</mat-label>
                                    <input class="sideText" matInput [matTooltip]="tootipData.AMOUNTTOTAL" formControlName="AMOUNTTOTAL">
                                    <span class="currency_sign" matPrefix>$&nbsp;</span>
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTTOTAL?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNTTOTAL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                                    <mat-label>Outstanding</mat-label>
                                    <input class="sideText" name="Outstanding" matInput
                                        [matTooltip]="tootipData.AMOUNTOUTSTANDINGINCGST" formControlName="AMOUNTOUTSTANDINGINCGST">
                                    <span class="currency_sign" matPrefix>$&nbsp;</span>
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTOUTSTANDINGINCGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNTOUTSTANDINGINCGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Interest Charges">
                            <div class="example-containerdata example-containerdata-invoice-view">
                                <table mat-table #table [dataSource]="IntersetChatgesData"
                                    [@animateStagger]="{value:'50'}" matSort class="mat-elevation-z8">
                                    <ng-container matColumnDef="INVOICECODE">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.INVOICECODE}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="INVOICEDATE">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.INVOICEDATE }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="INVOICETOTAL">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.INVOICETOTAL | toFixedAmount:true }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="AMOUNTOUTSTANDINGEXGST">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Outstanding</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.AMOUNTOUTSTANDINGEXGST | toFixedAmount:true }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="COMMENT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{row.COMMENT }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row class="tbl_fix_header"
                                        *matHeaderRowDef="displayedColumnsInterest; sticky: true"></tr>
                                    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumnsInterest;" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                        <tr mat-row [style.background]="highlightedRows == row.PERSONGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumnsInterest;" [ngClass]="highlightedRows == row.PERSONGUID ? 'row-text-colore' : ''" class="contact" (click)="editContact(row.PERSONGUID);highlightedRows = row.PERSONGUID"
                                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                    </tr> -->
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsInterest;let element;"
                                        class="row" (click)="RowClick(row);highlightedRows=row.INVOICECODE"
                                        [style.background]="highlightedRows == row.INVOICECODE ? selectedColore : ''"
                                        [ngClass]="highlightedRows == row.INVOICECODE ? 'row-text-colore' : ''"
                                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                    </tr>
                                </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" showFirstLastButtons></mat-paginator>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
    </div>

    <mat-dialog-actions align="end" class="popup_ftr">
        <button *ngIf="isView!='view'" mat-raised-button color="accent" (click)="updateInvoice()" [disabled]="isspiner">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>