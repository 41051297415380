import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DropDownTreeComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { SocketService } from '@_services/socket.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-matter-folder-dailog',
    templateUrl: './matter-folder-dailog.component.html',
    styleUrls: ['./matter-folder-dailog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MatterFolderDailogComponent implements OnInit, OnDestroy {
    base_url: any;
    type: any;
    isTryagin: boolean = false;
    fields: any = {};
    isPopup: boolean = false;
    titleText: any = "Documents";
    FileData: any = [];
    FolderData: any = {};
    fileExtention: any = "";
    // webSocket = new WebSocket(environment.MainSocketUrl);
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    btnText = '';
    progress: any = 5;
    isLoadingResults: boolean = false;
    treeSettings: any = { loadOnDemand: true };
    documentData: any = { DESCRIPTION: '', DOCUMENTNAME: '', NEWFILENAME: '' };
    expandexNodelist: any = [];
    FolderListArray: any = [];
    sub1: Subscription;
    sub2: Subscription;
    sub4: Subscription;
    @ViewChild("renamepopupdropdowntree", { static: false }) dropdownTree: DropDownTreeComponent;
    constructor(@Inject(MAT_DIALOG_DATA) public _data: any, private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public dialogRef: MatDialogRef<MatterFolderDailogComponent>,
        private socketService: SocketService) {
        // this.sub1 = this.socketService.openWSConnection(this.currentUser.SessionToken, 'LoadDocumentsStatus')
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })

        this.base_url = environment.ReportUrl;
        this.type = _data.type;
        if (_data.type != "rename") {
            // this.openWSConnection(this.currentUser.SessionToken);
            this.loadDocument();
        } else {
            this.isLoadingResults = true;
            this.documentData = _data.docData;
            this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
            if (this._data.isPopup) {
                this.isPopup = true;
                this.fileExtention = this.documentData.ORIGFILENAME.substr(this.documentData.ORIGFILENAME.lastIndexOf('.') + 1);
            } else {
                this.isPopup = false;
                this.fileExtention = this.documentData.DOCUMENTNAME.substr(this.documentData.DOCUMENTNAME.lastIndexOf('.') + 1);
            }

            this.titleText = "Rename Document";
            if (this._data.docData.FILENAMEONLY) {
                this.documentData.NEWFILENAME = this._data.docData.FILENAMEONLY;
            } else if (this._data.docData.ORIGFILENAMEONLY) {
                this.documentData.NEWFILENAME = this._data.docData.ORIGFILENAMEONLY;
            }
        }
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

    }

    /**
     * This function is used to destroy the component data
     */
    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub4?.unsubscribe();
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.created();
    }

    /**
     * This function is used to get the folder.
     */
    getFolder(postData, level) {
        postData.MATTERGUID = this.documentData.MATTERGUID;
        let Data=postData;
        this.sub2 = this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isLoadingResults = false;
                let fol: any = res.DATA.FOLDERS;
                if (level == 'rootLevel') {
                    fol.forEach(e => {
                        e.hasChild = true;
                        e.expanded = false;
                        // let tempData = e;
                        this.FolderListArray.push(e);
                        // tempData.FOLDERS.forEach(element => {
                        //   element.hasChild = true;
                        //   element.expanded = false;
                        //   element.pid = tempData.FULLPATH;
                        //   this.FolderListArray.push(element);
                        // });
                    });
                    this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
                    // this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
                } else {
                    fol.forEach(element => {
                        element.hasChild = true;
                        element.expanded = false;
                        element.pid = postData.FOLDERNAME;
                        this.FolderListArray.push(element);
                    });
                    this.expandexNodelist = this.expandexNodelist.filter((element, i) => i === this.expandexNodelist.indexOf(element));
                    this.FolderListArray.forEach(item => {
                        if (this.expandexNodelist.includes(item.FULLPATH) || item.FULLPATH == "<MatterFolder>") {
                            item.expanded = true;
                            item.hasChild = true;
                        } else {
                            item.hasChild = true;
                            item.expanded = false;
                        }
                    });
                    this.dropdownTree.refresh();
                    (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);
                    this.dropdownTree.showPopup();
                    this.fields = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: "pid", hasChildren: 'hasChild' };
                }
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to create the the expanding div data value.
     */
    created():void {
        (this.dropdownTree as any).treeObj.nodeExpanding = this.TriggerFolderList.bind(this);
    }

    /**
     * this function is used to Triger the folder list data. 
     */
    TriggerFolderList(arges):void {
        if (!this.expandexNodelist.includes(arges.nodeData.id) && arges.nodeData.id != "<MatterFolder>") {
            this.expandexNodelist.push(arges.nodeData.id);
            this.getFolder({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: arges.nodeData.id }, 'subFolderLevel');
        }
    }

    /**
     * This function is used to load the document data value.
     */
    async loadDocument() {
        // await this.socketService.openSocket();
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData(this._data.postData, "NetDriveLoadDocuments").subscribe(async DriveGetDocument => {
            if (DriveGetDocument.CODE == 200 && DriveGetDocument.STATUS == "success") {
                this.toastr.success('Documents have been added to document register');
                this.isLoadingResults = false;
                this.isTryagin = false;
                this.FileData = DriveGetDocument.DATA.DOCUMENTSLOADED;
                // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " LoadDocumentsStatus");
                // this.webSocket.close();
                $('#refreshDOCREGTab').click();
                // this.socketService.closeSocket(this.currentUser.SessionToken, "LoadDocumentsStatus");
            } else if (DriveGetDocument.CODE == 432) {
                this.isTryagin = true;
                this.isLoadingResults = false;
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + this.currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else {
                this.isLoadingResults = false;
                this.isTryagin = false;
            }
        }, async error => {
            this.isLoadingResults = false;
            this.isTryagin = false;
            this.toastr.error(error);
        });
    }
    // openWSConnection(SessionToken: any) {
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " LoadDocumentsStatus");
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //       console.log(closeEvent);
    //     };
    //     demoSoket.onerror = (errorEvent) => {
    //       console.log(errorEvent);
    //     };
    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }

    /**
     * This function is used to change the folder data value.
     */
    FloderChnage($event: SelectEventArgs) {
        this.FolderData = $event.itemData;
    }

    /**
     * This function is used to Rename the file.
     */
    RenameFile():void {
        this.isLoadingResults = true;
        let newName: any = this.documentData.NEWFILENAME ? this.documentData.NEWFILENAME.replace(/\.[^/.]+$/, "") : '';
        let postData: any = { DOCUMENTGUID: this.documentData.DOCUMENTGUID, NEWDOCUMENTNAME: this.documentData.NEWFILENAME ? this.documentData.NEWFILENAME : '' };
        postData.NEWFOLDERNAME = (this.FolderData && this.FolderData.id) ? this.FolderData.id : '';
        this._mainAPiServiceService.getSetData(postData, 'NetDriveRenameDocument').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (this._data.isPopup) {
                    this.dialogRef.close(response.DATA);
                } else {
                    this.dialogRef.close(true);
                }
                this.toastr.success("Renamed successfully");
            } else if (response.CODE == 432 || response.CODE == '432') {
                let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else if (response.CODE == 433 || response.CODE == '433') {
                this.isLoadingResults = false
                this.dialogRef.close();
            }
            this.isLoadingResults = false;
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
        });
    }

}
