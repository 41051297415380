import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SocketService } from '@_services/socket.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-upload-templates-popup',
    templateUrl: './upload-templates-popup.component.html',
    styleUrls: ['./upload-templates-popup.component.scss']
})
export class UploadTemplatesPopupComponent implements OnInit, OnDestroy {
    btnText = '';
    progress: any = 5;
    fileExtention: any;
    isspiner: boolean = false;
    isLoadingResults: boolean = false;
    isError: boolean = false;
    IsAuth: boolean = false;
    filefolder_Name: any;
    base_url: any;
    documentDataIndex: any = 99999;
    documentsData: any = [];
    xFxaxVal: any = 65;
    yflaxCal: any = 35;
    filefolderUrlDes: any;
    whichTypeTemplate: string;
    errorData: any = [];
    isGenerating: boolean = false;
    // webSocket = new WebSocket(environment.MainSocketUrl);
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        public dialog: MatDialog,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        public dialogRef: MatDialogRef<UploadTemplatesPopupComponent>,
        public socketService: SocketService,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.socketService.openWSConnection(currentUser.SessionToken, 'LoadTemplatesStatus').pipe(takeUntil(this._unsubscribeAll$))
        //     .subscribe(details => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     })
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        // this.openWSConnection(currentUser.SessionToken);
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result['click'] && result['data']['NetDriveLoadTemplates']) {
                this.UploadTemplates();
            }
        });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

    }

    syncToXero() {

    }

    /**
     * This function is used to Upload the Templates
     */
    UploadTemplates() {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.socketService.openSocket(currentUser.SessionToken);
        this.IsAuth = false;
        this.isLoadingResults = true;
        this._mainAPiServiceService.SetDocuments({ SENDTONETDRIVE: true }, 'NetDriveLoadTemplates').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toastr.success('Account Sync successfully');
                // this.webSocket.send("unwatch channel " + currentUser.SessionToken + " LoadTemplatesStatus");
                // this.webSocket.close();
                this.isLoadingResults = false;
                this.isGenerating = false;
                if (response.DATA['ERRORS']) {
                    this.isError = true;
                    this.errorData = response.DATA['ERRORS'];
                }
                // this.socketService.closeSocket(currentUser.SessionToken, 'LoadTemplatesStatus');
            } else if (response.CODE == 428) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetEmail&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else if (response.CODE == 432) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else if (response.CODE == 433) {
                this.isLoadingResults = false;
                this.IsAuth = true;
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetAccounts&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else if (response.MESSAGE == 'Not logged in') {
                this.isLoadingResults = false;
                this.dialogRef.close(false);
            } else {
                // this.toastr.error(response.MESSAGE);
                this.isLoadingResults = false;
                if (response.DATA['ERRORS']) {
                    this.isError = true;
                    this.errorData = response.DATA['ERRORS'];
                }
                // this.webSocket.send("unwatch channel " + currentUser.SessionToken + " LoadTemplatesStatus");
                // this.webSocket.close();
                // this.socketService.closeSocket(currentUser.SessionToken, 'LoadTemplatesStatus');

            }
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
            this.dialogRef.close();
            // this.socketService.closeSocket(currentUser.SessionToken,'LoadTemplatesStatus');
        });
    }
    // openWSConnection(SessionToken: any) {
    //   this.IsAuth = false;
    //   this.isLoadingResults = true;
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " LoadTemplatesStatus");
    //       this.UploadTemplates();
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //       console.log(closeEvent);
    //     };
    //     demoSoket.onerror = (errorEvent) => {
    //       console.log(errorEvent);
    //     };
    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }

}
