import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatterDialogComponent } from '../../time-entries/matter-dialog/matter-dialog.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as $ from 'jquery';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { AppPermissionsPipe } from '@_pipes/app-permissions.pipe';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-activity-dialog',
    templateUrl: './activity-dialog.component.html',
    styleUrls: ['./activity-dialog.component.scss'],
    providers: [AppPermissionsPipe, TierPermissionPipe],
})
export class ActivityDialogComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    activityForm: FormGroup;
    isLoadingResults: boolean = false;
    isreadonly: boolean = false;
    action: string;
    dialogTitle: string;
    isspiner: boolean = false;
    RATEPERUNIT: any;
    successMsg: any;
    ActivityModel: Date;
    errorWarningData: any = {};
    ITEMDATEVLAUE: any;
    timeStops: any = [];
    userList: any;
    filteredOptions: Observable<string[]>;
    LookupsList: any;
    lookuptype: any;
    PRICEINCGSTVAL: any;
    PRICEVAL: any;
    maaterguid: any = {};
    calculateData: any = {
        MatterGuid: '', QuantityType: '', Quantity: 1, FeeEarner: ''
    };
    getTypeDesc: any = '';
    windowNameId: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    Deliverable: any;
    DeliverableField: any;
    DerivableDropShow: boolean = false;
    toolTipList: any;
    testBool: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();
    modeIs: any;
    constructor(
        public MatDialog: MatDialog,
        public dialogRef: MatDialogRef<ActivityDialogComponent>,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        public datepipe: DatePipe,
        private Timersservice: TimersService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public globalFunService: GlobalFunctionsService,
        private tierpermission: TierPermissionPipe,
        public tooltipService: TooltipService,
    ) {
        localStorage.setItem('istrackid', 'ActivityDialogComponent');
        let temptype = data.popupname == 'Sundry' ? 'Sundries' : 'Activities';
        this.lookuptype = (data.popupname != 'Sundry' || data.popupname != 'Sundry') ? data.popupname : temptype;
        this.action = data.popupData.action;
        // this.behaviorService.MatterData$.subscribe(result => {
        //   this.maaterguid = result;
        // });
        // this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //     this.maaterguid = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        //     this.windowNameId ? this.windowNameId : 'set_active_matters';

        //     //Global matter selection.
        //     if (this.data.matteris) {
        //         this.maaterguid = this.data.matteris;
        //     };

        //     if (this.maaterguid) {
        //     } else {
        //         if (result) {
        //             this.maaterguid = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.maaterguid))
        //         }
        //     }
        // });
        /**
                 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
                 * @remarks
                 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
                 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
                 */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.maaterguid = result;
                    } else {
                        this.maaterguid = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.maaterguid));
                    }
                    //     //Global matter selection.
                        if (this.data.matteris) {
                            this.maaterguid = this.data.matteris;
                        };
                })
        )
            .subscribe();
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'ActivityDialogComponent' && result['click'] && result['data']['activity']) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'ActivityDialogComponent' && result['click'] && result['data']['activity']) {
                this.saveActivity();
            }
        });

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        let today = new Date();
        let time = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        this.timeStops = this.getTimeStops('00:00', '23:30');
        this.activityForm = this._formBuilder.group({
            matterautoVal: [''],
            MATTERGUID: ['', Validators.required],
            ITEMDATE: ['', Validators.required],
            ITEMTIME: [(this.currentUser.PREFILLTIMEOFDAY) ? moment(time, 'hh:mm A').format("HH:mm") : ''],
            FEEEARNER: [''],
            WORKITEMGUID: [''],
            QUANTITY: [''],
            ITEMTYPE: [''],
            FEETYPE: [''],
            QUANTITYTYPE: [],
            PRICE: ['',{updateOn: 'blur'}],
            PRICEINCGST: ['',{updateOn: 'blur'}],
            ADDITIONALTEXT: ['', Validators.required],
            COMMENT: [''],
            INVOICEDATE: [this.datepipe.transform(new Date(), 'dd/MM/yyyy'),
            ],
            MATTERDELIVERABLEGUID: ['']
        });
        this.isLoadingResults = true;
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.userList = this.Timersservice.removeUSerWithoutId(userList);
        } else {
            this.Timersservice.GetUsers({}).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.userList = this.Timersservice.removeUSerWithoutId(res.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(res.DATA.USERS));
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                } else {
                    this.userList = [];
                }
                this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
            });
        }
        let data: any = {
            "Action": "GetData",
            "Filters": {
                "ACTIVITYTYPE": this.lookuptype ? this.lookuptype : ''
            },
        }
        this.Timersservice.GetActivity(data).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.LookupsList = res.DATA.RECORDS;
                this.filteredOptions = this.f.QUANTITYTYPE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            } else {
                this.LookupsList = [];
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
        });
        if (this.lookuptype === 'Activities') {
            this.dialogTitle = 'New Fixed Fee';
            this.activityForm.controls['ITEMTYPE'].setValue('activity');
            this.activityForm.controls['FEETYPE'].setValue('activity');
        } else if (this.lookuptype === 'Sundries') {
            this.dialogTitle = 'New Sundry';
            this.activityForm.controls['ITEMTYPE'].setValue('sundry');
            this.activityForm.controls['FEETYPE'].setValue('sundry');
        } else {
            this.dialogTitle = 'New Fixed Fee';
            this.activityForm.controls['ITEMTYPE'].setValue('activity');
            this.activityForm.controls['FEETYPE'].setValue('activity');
        }
        if (this.action == 'new') {
            this.activityForm.controls['matterautoVal'].setValue(this.maaterguid.SHORTNAME + ' : ' + this.maaterguid.MATTER);
            this.calculateData.MatterGuid = this.maaterguid.MATTERGUID;
            this.activityForm.controls['MATTERGUID'].setValue(this.maaterguid.MATTERGUID);
            this.ActivityModel = new Date();
            this.activityForm.controls['QUANTITY'].setValue(1);
            let userType = JSON.parse(localStorage.getItem('currentUser'));
            if (userType) {
                this.activityForm.controls['FEEEARNER'].setValue((userType.WORKINGONBEHALFOFGUID) ? userType.WORKINGONBEHALFOFGUID : userType.UserId);
            }
        } else {
            this.setActivityData(this.data.popupData.WORKITEMGUID);
        }
        this.SetWorkItem();
        this.loadMatterDeliverable(this.maaterguid.MATTERGUID);
        this.setTooltipData()
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (this.lookuptype === 'Activities') {
            if (TooltipData && TooltipData['Activity']) {
                this.toolTipList = TooltipData['Activity'];
            } else {
                this.tooltipService.setToolTipData = ('Activity');
            }
        } else {
            if (TooltipData && TooltipData['Sundry']) {
                this.toolTipList = TooltipData['Sundry'];
            } else {
                this.tooltipService.setToolTipData = ('Sundry');
            }
        }
    }

    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    }


    SetWorkItem() {
        let PostActivityData: any = { FormAction: 'Defaults', data: { ITEMTYPE: "Sundry", MATTERGUID: this.maaterguid.MATTERGUID } };
        this._mainAPiServiceService.getSetData(PostActivityData, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {

        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }
    setActivityData(WORKITEMGUID) {
        let todayTime = new Date();
        let timeToday = todayTime.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        this.isLoadingResults = true;
        this.Timersservice.getTimeEnrtyData({ 'WorkItemGuid': WORKITEMGUID }).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.activityForm.controls['WORKITEMGUID'].setValue(WORKITEMGUID);
                let timeEntryData = response.DATA.WORKITEMS[0];
                this.calculateData.MatterGuid = timeEntryData.MATTERGUID;
                this.activityForm.controls['MATTERGUID'].setValue(timeEntryData.MATTERGUID);
                this.activityForm.controls['matterautoVal'].setValue(timeEntryData.SHORTNAME);
                if (timeEntryData.ITEMTIME != '') {
                    // let ttyData = moment(timeEntryData.ITEMTIME, 'hh:mm');
                    // this.activityForm.controls['ITEMTIME'].setValue(moment(ttyData).format('hh:mm A'));

                    let ttyData = this.convert12to24Fomat(timeEntryData.ITEMTIME)
                    this.activityForm.controls['ITEMTIME'].setValue(ttyData);
                } else {
                    this.activityForm.controls['ITEMTIME'].setValue((this.currentUser.PREFILLTIMEOFDAY) ? moment(timeToday, 'hh:mm A').format("HH:mm") : '');
                }
                this.activityForm.controls['QUANTITYTYPE'].setValue(timeEntryData.FEETYPE);
                this.activityForm.controls['FEEEARNER'].setValue(timeEntryData.FEEEARNER);
                this.activityForm.controls['QUANTITY'].setValue(timeEntryData.QUANTITY);
                this.activityForm.controls['PRICE'].setValue((Number(timeEntryData.PRICE)).toFixed(2));
                this.activityForm.controls['PRICEINCGST'].setValue((Number(timeEntryData.PRICEINCGST)).toFixed(2));
                this.activityForm.controls['ADDITIONALTEXT'].setValue(timeEntryData.ADDITIONALTEXT);
                this.activityForm.controls['COMMENT'].setValue(timeEntryData.COMMENT);
                this.activityForm.controls['MATTERDELIVERABLEGUID'].setValue(timeEntryData.MATTERDELIVERABLEGUID);
                this.calculateData.FeeType = timeEntryData.FEETYPE;
                let tempDate = timeEntryData.ITEMDATE.split("/");
                this.ITEMDATEVLAUE = timeEntryData.ITEMDATE;
                this.ActivityModel = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.LookupsList.filter(option => (option.ACTIVITYID.toLowerCase().includes(filterValue) || option.DESCRIPTION.toLowerCase().includes(filterValue)));
    }
    RatePerUnitVal() {
        this.RATEPERUNIT = parseFloat(this.f.RATEPERUNIT.value).toFixed(2);
    }
    get f() {
        return this.activityForm.controls;
    }
    public selectMatter() {
        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'ActivityDialogComponent');
            if (result) {
                this.activityForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.activityForm.controls['matterautoVal'].setValue(result.SHORTNAME + ' : ' + result.MATTER);
            }
        });
    }
    choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ITEMDATEVLAUE = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    }
    getTimeStops(start, end) {
        var startTime = moment(start, 'hh:mm');
        var endTime = moment(end, 'hh:mm');
        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }
        var timeStops = [];
        while (startTime <= endTime) {
            timeStops.push(moment(startTime).format('hh:mm A'));
            startTime.add(30, 'minutes');
        }
        return timeStops;
    }
    activityChange() {
        const indexData = this.LookupsList.findIndex(activity => (activity.ACTIVITYID).toUpperCase() == (this.f.QUANTITYTYPE.value).toUpperCase());
        if (this.LookupsList[indexData]) {
            this.activityForm.controls['QUANTITYTYPE'].setValue(this.LookupsList[indexData].ACTIVITYID);
            this.activityForm.controls['ADDITIONALTEXT'].setValue(this.LookupsList[indexData].DESCRIPTION);
            this.matterChange('QuantityType', this.LookupsList[indexData].ACTIVITYID);
        }
    }
    matterChange(key: any, value: any) {
        let event: any = value;
        if (key == "QuantityType") {
            const indexData = this.LookupsList.findIndex(activity => activity.ACTIVITYID === value);
            this.activityForm.controls['ADDITIONALTEXT'].setValue(this.LookupsList[indexData].DESCRIPTION);
            this.getTypeDesc = this.LookupsList[indexData].GSTTYPEDESC;
        }
        if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }

        if (key == 'Quantity') {
            if (!value) {
                this.calculateData.QuantityType = 'F';
            }
        }
        this.calculateData.FeeEarner = this.f.FEEEARNER.value;
        this.calculateData.Quantity = this.f.QUANTITY.value;
        if (this.calculateData.Quantity != '' && this.calculateData.QuantityType != '') {
            this.isLoadingResults = true;
            this.Timersservice.calculateWorkItems(this.calculateData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let CalcWorkItemCharge = response.DATA;
                    this.activityForm.controls['PRICE'].setValue(CalcWorkItemCharge.PRICE);
                    this.activityForm.controls['PRICEINCGST'].setValue(CalcWorkItemCharge.PRICEINCGST);
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }
    calcPE() {
        if (this.getTypeDesc != 'GST Free') {
            let numberdata = this.f.PRICE.value * 1.1;
            this.PRICEINCGSTVAL = numberdata.toFixed(2);
        } else {
            let numberdata = Number(this.f.PRICE.value);
            this.PRICEINCGSTVAL = numberdata.toFixed(2);
        }
    }
    calcPI() {
        if (this.getTypeDesc != 'GST Free') {
            let numberData = this.f.PRICEINCGST.value / 1.1;
            this.PRICEVAL = numberData.toFixed(2);
        } else {
            let numberdata = Number(this.f.PRICEINCGST.value);
            this.PRICEVAL = numberdata.toFixed(2);
        }
    }
    saveActivity(data?) {
        this.modeIs = data;
        if (this.ITEMDATEVLAUE == "" || this.ITEMDATEVLAUE == null || this.ITEMDATEVLAUE == undefined) {
            this.ITEMDATEVLAUE = this.f.INVOICEDATE.value;
        }
        this.isspiner = true;
        let PostData: any = {
            MATTERGUID: this.f.MATTERGUID.value,
            ITEMDATE: this.ITEMDATEVLAUE,
            ITEMTIME: this.f.ITEMTIME.value,
            FEEEARNER: this.f.FEEEARNER.value,
            QUANTITY: this.f.QUANTITY.value,
            ITEMTYPE: this.f.ITEMTYPE.value,
            FEETYPE: (this.calculateData && this.calculateData.FeeType) ? this.calculateData.FeeType : this.f.FEETYPE.value,
            PRICE: this.f.PRICE.value,
            PRICEINCGST: this.f.PRICEINCGST.value,
            ADDITIONALTEXT: this.f.ADDITIONALTEXT.value,
            COMMENT: this.f.COMMENT.value,
            MATTERDELIVERABLEGUID: this.f.MATTERDELIVERABLEGUID.value
        }
        if (this.action != 'new') {
            PostData.WORKITEMGUID = this.f.WORKITEMGUID.value;
        }
        this.successMsg = 'Save successfully';
        let PostActivityData: any = { FormAction: this.action == 'new' ? 'insert' : 'update', VALIDATEONLY: true, Data: PostData };
        this._mainAPiServiceService.getSetData(PostActivityData, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, PostActivityData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, PostActivityData);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, PostActivityData);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check validation data
     * @param bodyData -body data value
     * @param PostActivityData -post activity data
     */
    async checkValidation(bodyData: any, PostActivityData: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'Warning') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true, width: '100%', data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.saveActivityData(PostActivityData);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.saveActivityData(PostActivityData);
        // }

        await this.globalFunService.checkValidation(bodyData, PostActivityData)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    //this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.saveActivityData(PostActivityData);
                    } else {
                        this.isspiner = false;
                    }
                }
            });
    }

    /**
     * This function is used to SaveActivity data
     * @param PostActivityData -data value
     */
    saveActivityData(PostActivityData: any) {
        PostActivityData.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(PostActivityData, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.toastr.success(this.successMsg);
// START
// Add the Save&New button as per the client requirement. 
                if (this.modeIs == 'SaveWithNew') {
                   this.ngOnInit()
                    $('#refrshWorkinprogress').click();
                } else {
                    this.dialogRef.close(true);
                }
// Add the Save&New button as per the client requirement. 
// END
               // this.dialogRef.close(true);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.toastr.warning(this.successMsg);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.toastr.error(res.STATUS);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isspiner = false;
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    };

    /**
     * This function is used to convert the 12 to 24 format data
     * @param time -time data
     * @returns data
     */
    convert12to24Fomat(time: string): string {
        var [shours, sminutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        var hours = Number(shours);
        var minutes = Number(sminutes);
        var AMPM = time.slice(-2);
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes;
    }
    //set default reminder time on edit
    DefaultReminderTime(): string {
        var event = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
        let time = event.toLocaleString();
        return new Date(time).getHours() + 1 + ":" + new Date(time).getMinutes();
    }

/**
 * This function is used to  load the matter deliverable data
 * @param input_MATTERGUID -matter data 
 */
    loadMatterDeliverable(input_MATTERGUID: any) {
        const pipe = this.tierpermission.transform('Matter Deliverables');
        if (pipe && input_MATTERGUID && this.currentUser.PRODUCTTYPE != 'Barrister' && pipe.ALLOWED == 1) {
            this.isLoadingResults = true;
            this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: {"INCLUDEBLANKROW": true, MATTERGUID: input_MATTERGUID }, }, 'matter-deliverable').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
                if (responses.CODE == "200" && responses.STATUS == 'success') {
                    this.isLoadingResults = false;
                    this.Deliverable = responses.DATA.RECORDS;
                } else {
                    this.isLoadingResults = false;
                }
            }, error => {
                this.isLoadingResults = false;
            });
        }
    }
}
