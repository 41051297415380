<div cdkDrag class="popup_main_div delete_main_popup confirm_popup"
    [ngStyle]="{'width': isSearchData ? '800px' : '400px'}">
    <h1 cdkDragHandle matDialogTitle *ngIf="!isTrust">{{confirmTitle}}</h1>
    <h1 cdkDragHandle matDialogTitle *ngIf="isTrust">Trust Account</h1>
    <div mat-dialog-content>
        {{confirmMessage}}
        <span *ngIf="isSearchData">
            <br>
            <br>
            If you already have an InfoTrack account click "Configure" to enter
            the username and password that was
            provided by InfoTrack.
            <br>
            <br>
            If you are not an existing user of InfoTrack you can click on
            "Signup" and you will be directed to their
            Signup page.
        </span>
<br><br>
        <div *ngIf="isShowInputField">       
              <mat-form-field fxFlex="65" appearance="outline" class="pr-4 bank-input mll">
                  <input matInput [(ngModel)]="AccountDescription" placeholder="Description">
                  
              </mat-form-field>
          </div>

        <div class="border_div" *ngIf="confirmData">
            <img *ngIf="isErrorWarn" class="warning_img"
                src="assets/icons/web_app/Warning.png" alt>
            <ul>
                <li class="list_ui"
                    *ngFor='let item of confirmData;trackBy: trackByFn'>{{item}}</li>
            </ul>
        </div>
        <div *ngIf="isContactUs" [innerHTML]="contactUsMessage">

        </div>
        <div *ngIf="isBankFeed" [innerHTML]="contactUsMessage">

        </div>
    </div>
    <div mat-dialog-actions class="popup_ftr"
        *ngIf="!isSearchData && !isContactUs && !usecompanyaddress1">
        <div *ngIf="ConBtn =='Show'" class="right_btn_child">
            <button mat-raised-button
                class="mat-button mat-raised-button mat-primary mat-accent"
                type="button"
                (click)="dialogRef.close((isShowInputField == true) ? AccountDescription : true)">Confirm</button>
        </div>
        <div *ngIf="isBankFeed" class="right_btn_child">
            <button mat-raised-button
                class="mat-button mat-raised-button mat-primary mat-accent"
                type="button"
                (click)="dialogRef.close(true)">Confirm</button>
        </div>
        <!-- For csv popup -->
        <div *ngIf="ConBtn =='Yes'" class="right_btn_child">
            <button mat-raised-button
                class="mat-button mat-raised-button mat-primary mat-accent"
                type="button"
                (click)="dialogRef.close(true)">{{ConBtn}}</button>
        </div>
        <div *ngIf="_data && _data?.toggleAutocompleteDropdown =='toggleAutocompleteDropdown'" class="right_btn_child">
                    <button mat-raised-button
                        class="mat-button mat-raised-button mat-primary mat-accent"
                        type="button"
                        (click)="dialogRef.close(true)">Append</button>
                </div>
        <div class="right_btn_child cancel_confirm" *ngIf="!isTrust">
            <button mat-button (click)="dialogRef.close(false)"
                class="cancel mat-button" type="button"
                matbadgecolor="accent">{{btnName}}</button>
        </div>

        <div class="right_btn_child cancel_confirm"
            *ngIf="isTrust && !usecompanyaddress1">
            <button mat-button (click)="dialogRef.close(true)"
                class="cancel mat-button" type="button"
                matbadgecolor="accent">{{btnName}}</button>
        </div>

    </div>
    <div mat-dialog-actions class="popup_ftr"
        *ngIf="isSearchData && !isContactUs">
        <div class="right_btn_child">
            <button mat-raised-button
                class="mat-button mat-raised-button mat-primary" type="button"
                (click)="dialogRef.close(true)">Configure</button>
        </div>
        <div class="right_btn_child signuo_confirm">
            <a mat-button href="{{SignupUrl}}" (click)="dialogRef.close(false)"
                target="_blank"
                class="cancel mat-button" type="button" matbadgecolor="accent">Signup</a>
        </div>
        <div class="right_btn_child cancel_confirm">
            <button mat-button (click)="dialogRef.close(false)"
                class="cancel mat-button" type="button"
                matbadgecolor="accent">Cancel</button>
        </div>
    </div>

    <div mat-dialog-actions class="popup_ftr"
        *ngIf="!isSearchData && !isContactUs && usecompanyaddress1 &&! isTrust">
        <div *ngIf="ConBtn =='usecompany'" class="right_btn_child">
            <button mat-raised-button
                class="mat-button mat-raised-button mat-primary mat-accent"
                type="button"
                (click)="dialogRef.close(true)">Yes</button>
            <button mat-button (click)="dialogRef.close(false)"
                class="cancel mat-button" type="button"
                matbadgecolor="accent">{{btnName1}}</button>
        </div>
    </div>

    <div mat-dialog-actions class="popup_ftr" *ngIf="isContactUs">
        <div class="right_btn_child cancel_confirm">
            <button mat-button (click)="dialogRef.close(false)"
                class="cancel mat-button" type="button"
                matbadgecolor="accent">Cancel</button>
        </div>
    </div>
</div>
