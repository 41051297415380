import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { promise } from 'protractor';
import { resolve } from 'path';
import { Router } from '@angular/router';
import { BankingDialogComponent } from '@pages/banking/banking-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { fuseAnimations } from 'src/@fuse/animations';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  grid: ApexGrid;
};

@Component({
  selector: 'app-bank-feed-dashboard',
  templateUrl: './bank-feed-dashboard.component.html',
  styleUrls: ['./bank-feed-dashboard.component.scss'],
  animations: fuseAnimations,
})
export class BankFeedDashboardComponent implements OnInit {

  // @ViewChild("chart") chart: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;

  AccountList: any;
  isLoadingResults=true;
  LoadingResults=false;
  authAccountName:FormGroup;
  BankName:any
  COLOR: any;
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(private behaviourservice:BehaviorService,
    private _mainAPiServiceService: MainAPiServiceService,
    private toaster:ToastrService,
    private Fb:FormBuilder,
    private router:Router,
    public _matDialog: MatDialog,
    private _hyperlink:HyperlinkNavigationService,
    public toolbarServiceService: ToolbarServiceService
  ) {

    // this.chartOptions = {
    //   series: [
    //     {
    //         "name": "HIGHBALANCE",
    //         "data": [
    //             {
    //                 "x": "22/05/2024",
    //                 "y": 3782.31
    //             },
    //             {
    //                 "x": "23/05/2024",
    //                 "y": 4042.08
    //             },
    //             {
    //                 "x": "24/05/2024",
    //                 "y": 3603.06
    //             },
    //             {
    //                 "x": "25/05/2024",
    //                 "y": 3419.31
    //             },
    //             {
    //                 "x": "26/05/2024",
    //                 "y": 3777.49
    //             },
    //             {
    //                 "x": "27/05/2024",
    //                 "y": 3915.46
    //             },
    //             {
    //                 "x": "28/05/2024",
    //                 "y": 3618.98
    //             },
    //             {
    //                 "x": "29/05/2024",
    //                 "y": 3588.75
    //             },
    //             {
    //                 "x": "30/05/2024",
    //                 "y": 3862.37
    //             },
    //             {
    //                 "x": "31/05/2024",
    //                 "y": 3433.88
    //             },
    //             {
    //                 "x": "1/06/2024",
    //                 "y": 3995.7
    //             },
    //             {
    //                 "x": "2/06/2024",
    //                 "y": 4000.03
    //             },
    //             {
    //                 "x": "3/06/2024",
    //                 "y": 4008.21
    //             },
    //             {
    //                 "x": "4/06/2024",
    //                 "y": 3563.59
    //             },
    //             {
    //                 "x": "5/06/2024",
    //                 "y": 3602.55
    //             },
    //             {
    //                 "x": "6/06/2024",
    //                 "y": 3581.35
    //             },
    //             {
    //                 "x": "7/06/2024",
    //                 "y": 4278.31
    //             },
    //             {
    //                 "x": "8/06/2024",
    //                 "y": 3837.22
    //             },
    //             {
    //                 "x": "9/06/2024",
    //                 "y": 3806.92
    //             },
    //             {
    //                 "x": "10/06/2024",
    //                 "y": 3623.36
    //             },
    //             {
    //                 "x": "11/06/2024",
    //                 "y": 3904.55
    //             },
    //             {
    //                 "x": "12/06/2024",
    //                 "y": 3560.48
    //             },
    //             {
    //                 "x": "13/06/2024",
    //                 "y": 3536.65
    //             },
    //             {
    //                 "x": "14/06/2024",
    //                 "y": 3309.06
    //             },
    //             {
    //                 "x": "15/06/2024",
    //                 "y": 4243.37
    //             },
    //             {
    //                 "x": "16/06/2024",
    //                 "y": 3507.07
    //             },
    //             {
    //                 "x": "17/06/2024",
    //                 "y": 3514.31
    //             },
    //             {
    //                 "x": "18/06/2024",
    //                 "y": 3855.27
    //             },
    //             {
    //                 "x": "19/06/2024",
    //                 "y": 3810.6
    //             },
    //             {
    //                 "x": "20/06/2024",
    //                 "y": 3539.47
    //             },
    //             {
    //                 "x": "21/06/2024",
    //                 "y": 0
    //             }
    //         ]
    //     },
    //     {
    //         "name": "LOWBALANCE",
    //         "data": [
    //             {
    //                 "x": "22/05/2024",
    //                 "y": -3755.55
    //             },
    //             {
    //                 "x": "23/05/2024",
    //                 "y": -3504.52
    //             },
    //             {
    //                 "x": "24/05/2024",
    //                 "y": -3369.25
    //             },
    //             {
    //                 "x": "25/05/2024",
    //                 "y": -3248.11
    //             },
    //             {
    //                 "x": "26/05/2024",
    //                 "y": -3333.24
    //             },
    //             {
    //                 "x": "27/05/2024",
    //                 "y": -3281.6
    //             },
    //             {
    //                 "x": "28/05/2024",
    //                 "y": -3394.64
    //             },
    //             {
    //                 "x": "29/05/2024",
    //                 "y": -3364.63
    //             },
    //             {
    //                 "x": "30/05/2024",
    //                 "y": -3095.3
    //             },
    //             {
    //                 "x": "31/05/2024",
    //                 "y": -3494.52
    //             },
    //             {
    //                 "x": "1/06/2024",
    //                 "y": -3206.11
    //             },
    //             {
    //                 "x": "2/06/2024",
    //                 "y": -3192.01
    //             },
    //             {
    //                 "x": "3/06/2024",
    //                 "y": -3406.51
    //             },
    //             {
    //                 "x": "4/06/2024",
    //                 "y": -3416.56
    //             },
    //             {
    //                 "x": "5/06/2024",
    //                 "y": -3296.22
    //             },
    //             {
    //                 "x": "6/06/2024",
    //                 "y": -3185.06
    //             },
    //             {
    //                 "x": "7/06/2024",
    //                 "y": -4043.78
    //             },
    //             {
    //                 "x": "8/06/2024",
    //                 "y": -3329.29
    //             },
    //             {
    //                 "x": "9/06/2024",
    //                 "y": -3420.71
    //             },
    //             {
    //                 "x": "10/06/2024",
    //                 "y": -3408.49
    //             },
    //             {
    //                 "x": "11/06/2024",
    //                 "y": -3328.21
    //             },
    //             {
    //                 "x": "12/06/2024",
    //                 "y": -3147.25
    //             },
    //             {
    //                 "x": "13/06/2024",
    //                 "y": -3316.1
    //             },
    //             {
    //                 "x": "14/06/2024",
    //                 "y": -3748.15
    //             },
    //             {
    //                 "x": "15/06/2024",
    //                 "y": -3887.65
    //             },
    //             {
    //                 "x": "16/06/2024",
    //                 "y": -3423.02
    //             },
    //             {
    //                 "x": "17/06/2024",
    //                 "y": -3263.98
    //             },
    //             {
    //                 "x": "18/06/2024",
    //                 "y": -3493.51
    //             },
    //             {
    //                 "x": "19/06/2024",
    //                 "y": -3177.22
    //             },
    //             {
    //                 "x": "20/06/2024",
    //                 "y": -2892.14
    //             },
    //             {
    //                 "x": "21/06/2024",
    //                 "y": 0
    //             }
    //         ]
    //     }
    // ],
    //   chart: {
    //     type: "area",
    //     height: 250
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   stroke: {
    //     curve: "smooth"
    //   },
    //   xaxis: {
       
    //     axisBorder: {
    //       show: false
    //     },
    //     axisTicks: {
    //       show: false
    //     }
    //   },
    //   yaxis: {
    //     tickAmount: 4,
    //     floating: false,

    //     labels: {
    //       style: {
    //         //color: "#8e8da4"
    //       },
    //       offsetY: -7,
    //       offsetX: 0
    //     },
    //     axisBorder: {
    //       show: false
    //     },
    //     axisTicks: {
    //       show: false
    //     }
    //   },
    //   fill: {
    //     opacity: 0.5
    //   },
    //   tooltip: {
    //     x: {
    //       format: "dd/mm/yyyy"
    //     },
    //     fixed: {
    //       enabled: false,
    //       position: "topRight"
    //     }
    //   },
    //   grid: {
    //     yaxis: {
    //       lines: {
    //         offsetX: -30
    //       }
    //     },
    //     padding: {
    //       left: 20
    //     }
    //   }
    // };

    this.authAccountName=this.Fb.group({
      name:['']
    })
    this._hyperlink.setColorSettingWithHyperlink();
   }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
    this.AccountList=[];
    this.behaviourservice.authorisedaccounts$.subscribe(data=>{
      if(data && data.length !=0){
        this.AccountList=data;
        this.AccountList.forEach(element => {
          element.ShowAccountNameTextField=false;
          element.ShowAccountName;
          element.ACCOUNTGUID;
          element.series=[];
          element.chart= {
            type: "area",
            height: 230,
            redrawOnParentResize: true
          },
          element.dataLabels= {
            enabled: false
          },
          element.stroke= {
            curve: "smooth"
          },
          element.xaxis= {

            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
             labels: {
             show: false
            }
          },
          element.yaxis= {
            tickAmount: 4,
            floating: false,
    
            labels: {
              style: {
                colors:(this.COLOR=='custom-link-hyperlink') ?'black' :(this.COLOR=='custom-link-hyperlink-withdarkmode') ? 'white' :'black'
              },
              offsetY: -7,
              offsetX: 0
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          element.fill= {
            opacity: 0.5
          },
          
          element.grid= {
            yaxis: {
              lines: {
                offsetX: -30
              }
            },
            padding: {
              left: 20
            }
          }
          // element.chart= {
          //   toolbar: {
          //     show: false
          //   }
          // }
        });
        this.PrepareChartData();
      }else{
        setTimeout(() => {
          this.isLoadingResults=false;
        },2000);
      }
    })

    this.behaviourservice.ColorSystemSettingHyperLink$.subscribe((data) => {
      this.COLOR = data;
  });
  }

  /**
   * This function is used to getAccount Data Information 
   * @param AccountInformation -Account Information data value
   * @param Action -Action name
   */
  getAccountData(AccountInformation:any,Action:string){
    this.isLoadingResults=true
      let payload
    if(Action != 'updateaccountname' && Action !="deleteaccount"){
      payload={action: Action,Data:{ BANKFEEDACCOUNTGUID:AccountInformation?.BANKFEEDACCOUNTGUID}}
    }else 
    if(Action =="deleteaccount"){
    this.DeleteAccountpopup(AccountInformation?.BANKFEEDACCOUNTGUID);
    return;
    }else{
      payload={action: Action,Data:{ACCOUNTNAME:this.authAccountName.controls['name'].value, BANKFEEDACCOUNTGUID:AccountInformation?.BANKFEEDACCOUNTGUID}}
    }
    this._mainAPiServiceService.getSetData(payload, "bank-feed").subscribe(data=>{
      if(data.CODE==200  &&  data.STATUS=="success"){
        switch (Action) {
          case 'disconnect':
            this.toaster.success('Account disconnect Successfully')
            break;
          case 'deleteaccount':
            this.toaster.success('Account delete Successfully')
            break;            
        }
        $("#authorisedAccouts").click();
        this.isLoadingResults=false;
        AccountInformation.ShowAccountNameTextField=false;
      }else{
        this.isLoadingResults=false;
      }
    },error=>{
      this.isLoadingResults=false;
    })
  }

  /**
   * This function is used to get the Account Information
   * @param information -Account information data
   */
  getAccountName(information:any):void{
    this.authAccountName.controls['name'].setValue(information?.ACCOUNTNAME);
    information.ShowAccountNameTextField=true;
    this.AccountList.forEach(element => {
      if(element.BANKFEEDACCOUNTGUID != information.BANKFEEDACCOUNTGUID){
        element.ShowAccountNameTextField=false;
      }
    });
  }

  /**
   * This function is used to Close the Account Name
   * @param information -Account Information
   */
  closeAccountName(information:any):void{
    information.ShowAccountNameTextField=false;
  }


  /**
   * This function is used to get the Account daily balance data.
   */
async PrepareChartData(){
let today = new Date();

let endDate = new Date(today);
endDate.setDate(today.getDate() - 1);

let endDateFormatted = this.formatDate(endDate);

let startDate = new Date(endDate);
startDate.setMonth(endDate.getMonth() - 1);

let startDateFormatted = this.formatDate(startDate);
  this.isLoadingResults=false;
for(let i=0; i<this.AccountList.length;i++){
await new Promise(async(resolved,reject)=>{
  this.isLoadingResults=true;
  let payload={action: 'AccountDailyBalance',Data:{ BANKFEEDACCOUNTGUID:this.AccountList[i]?.BANKFEEDACCOUNTGUID
    ,STARTDATE: startDateFormatted,
     ENDDATE: endDateFormatted
  }}
await  this._mainAPiServiceService.getSetData(payload, "bank-feed").subscribe(async data=>{
    if(data && data.CODE==200  &&  data.STATUS=="success"){
      this.isLoadingResults=false;
     let unprocessedtransactioncountCount=this.getUnprocessedtransactioncount(this.AccountList[i]?.BANKFEEDACCOUNTGUID);
     unprocessedtransactioncountCount.then(data=>{
      this.AccountList[i].unprocessedtransactioncountCount=data;
     })
     this.AccountList[i].PERCENTCHANGE=data.DATA.PERCENTCHANGE;
     if(data?.DATA?.DAILYBALANCES && data?.DATA?.DAILYBALANCES.length >0){
      this.AccountList[i].series.push({name:"HIGHBALANCE",data:[]},{name:"LOWBALANCE",data:[]});
      data.DATA.DAILYBALANCES.forEach(element => {
        this.AccountList[i].series[0].data.push({x:element.DATEDESC,y:element.HIGHBALANCE});
        this.AccountList[i].series[1].data.push({x:element.DATEDESC,y:element.LOWBALANCE})
      });
     }
      resolved(true);
    }else{
      this.isLoadingResults=false;
    }
  },error=>{
    this.isLoadingResults=false;
  })
})
}

 }

/**
 * This function is used to format the date.
 */
 formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

/**
 * This function is uised to get the unprocessded transaction Accounts. 
 * @param BANKFEEDACCOUNTGUID -Bank Account Guid
 * @returns data value;
 */
 async getUnprocessedtransactioncount(BANKFEEDACCOUNTGUID){
  return new Promise((resolve,reject)=>{
    let payload={action: 'unprocessedtransactioncount ',Data:{ BANKFEEDACCOUNTGUID:BANKFEEDACCOUNTGUID}}
     this._mainAPiServiceService.getSetData(payload, "bank-feed").subscribe(data=>{
      if(data && data.CODE==200  &&  data.STATUS=="success"){
         resolve(data.DATA.UNPROCESSEDTRANSACTIONCOUNT);
      }else{

      }
    },error=>{

    }) 
  })
}

/**
 * This function is used to go to the perticular Account tab
 * @param data -data of the perticular Account.
 */
gotoAccount(data){
  let updatedData={
    ACCOUNTGUID: data.ACCOUNTGUID,
    ACCOUNTHOLDER: data.ACCOUNTHOLDER,
    ACCOUNTNAME: data.ACCOUNTNAME,
    ACCOUNTNUMBER: data.ACCOUNTNUMBER,
    ACCOUNTTYPE: data.ACCOUNTTYPE,
    BALANCE: data.BALANCE,
    BANKFEEDACCOUNTGUID: data.BANKFEEDACCOUNTGUID,
    BANKIMAGE: data.BANKIMAGE,
    BSB: data.BSB,
    CUSTOMERGUID: data.CUSTOMERGUID,
    LINKEDACCOUNTCLASS: data.LINKEDACCOUNTCLASS,
    LINKEDACCOUNTNAME: data.LINKEDACCOUNTNAME,
    LINKEDACCOUNTNUMBER: data.LINKEDACCOUNTNUMBER
  }
 this.router.navigate(["/bank-feed/bank-feed-list"]);
 this.behaviourservice.PassDataDashboardToSidebar$.next(updatedData);
}


/**
 * This function is used to goto Bank Reconsilation window
 * @param data -data value
 */
gotoBankRecosilationwindow(data){
  let updatedData={
    ACCOUNTGUID: data.ACCOUNTGUID,
    ACCOUNTHOLDER: data.ACCOUNTHOLDER,
    ACCOUNTNAME: data.ACCOUNTNAME,
    ACCOUNTNUMBER: data.ACCOUNTNUMBER,
    ACCOUNTTYPE: data.ACCOUNTTYPE,
    BALANCE: data.BALANCE,
    BANKFEEDACCOUNTGUID: data.BANKFEEDACCOUNTGUID,
    BANKIMAGE: data.BANKIMAGE,
    BSB: data.BSB,
    CUSTOMERGUID: data.CUSTOMERGUID,
    LINKEDACCOUNTCLASS: data.LINKEDACCOUNTCLASS,
    LINKEDACCOUNTNAME: data.LINKEDACCOUNTNAME,
    LINKEDACCOUNTNUMBER: data.LINKEDACCOUNTNUMBER,
    name:''
  }
  let name = updatedData.LINKEDACCOUNTCLASS + ' - ' + updatedData.LINKEDACCOUNTNUMBER + ' ' + updatedData.LINKEDACCOUNTNAME;
  updatedData.name=name
  this.behaviourservice.ChartAccountData(updatedData);
  localStorage.setItem("BankingDialogDefaultData",JSON.stringify(updatedData));
  this.router.navigate(["/account-reconciliation/reconciliation-item"]);

}

/**
 * This function is used to open the bank dialog data.
 */
BankingDialogOpen(type: any,data:any) {
//let Accountname=data.ShowAccountName;
  // for by default bank account select not trust account
  const dialogRef = this._matDialog.open(BankingDialogComponent, { disableClose: true, width: "100%", data: { AccountType: type, FromWhere: "connectBankFeed",UseTrust: "No", RoterPath: 'connect-bank-feed' } });
  dialogRef.afterClosed().subscribe((result) => {
    if(result){
      data.ShowAccountName=result.ACCOUNTNAME;
      data.ACCOUNTGUID=result.ACCOUNTGUID;
    }
  });
}

/**
 * This function is used to link the Account.
 */
linktAccount(data:any):void {
  this.isLoadingResults=true;
      let postData = {
          Action: "Connect",
          Data: {
              BANKFEEDACCOUNTGUID: data.BANKFEEDACCOUNTGUID,
              ACCOUNTGUID: data.ACCOUNTGUID
          }
      };
      this._mainAPiServiceService
          .getSetData(postData, "bank-feed")
          .subscribe((res) => {
              if (res.STATUS == "success") {
                $("#authorisedAccouts").click();
                this.isLoadingResults=false;
              }else{
                this.isLoadingResults=false;
              }
      },error=>{
        this.isLoadingResults=false;
      });
  
}


/**
 * This function is used to delete the spend MOney data value
 */
DeleteAccountpopup(id): void {
      let postData = {
          Action: "DeleteAccount",
          VALIDATEONLY: true,
          DATA: {
            BANKFEEDACCOUNTGUID:id,
          },
      };
      this._mainAPiServiceService
          .getSetData(postData, "bank-feed")
          .subscribe((res) => {
              if ((res.STATUS == "success" && res.CODE == 200) || (res.STATUS == "confirmation" && res.CODE == 470)) {
                this.isLoadingResults=false
                  this.ConformPopupAccountDelete(id,res);
              } else if (res.CODE == 451 && res.STATUS == "warning") {
                  this.checkValidation(
                      res.DATA.VALIDATIONS,
                      postData,
                      "bank-feed"
                  );
              } else if (res.CODE == 450 && res.STATUS == "error") {
                  this.checkValidation(
                      res.DATA.VALIDATIONS,
                      postData,
                      "bank-feed"
                  );
              }
              // else if (res.CODE == 462 && res.STATUS == 'error') {
              //     this.checkValidation(res.DATA.VALIDATIONS, postData, 'SetMatter');
              // }
              else if (res.MESSAGE == "Not logged in") {
                  this.dialogRef.close(false);
              }
          });
}

 /**
     * This function is used to check the validation data
     * @param bodyData -bodydata value
     * @param details -details value
     * @param ApiName -Api Name
     */
 checkValidation(bodyData: any, details: any, ApiName: any) {
  let errorData: any = [];
  let warningData: any = [];
  let tempError: any = [];
  let tempWarning: any = [];
  bodyData.forEach(function (value) {
      if (
          value.VALUEVALID == "No" ||
          value.VALUEVALID == "NO" ||
          value.VALUEVALID == "Error"
      ) {
          errorData.push(value.ERRORDESCRIPTION);
          tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == "Warning") {
          tempWarning[value.FIELDNAME] = value;
          warningData.push(value.ERRORDESCRIPTION);
      }
  });

  if (Object.keys(errorData).length != 0) {
      localStorage.setItem("confEWshow", "error");
      this.confirmDialogRef = this._matDialog.open(
          FuseConfirmDialogComponent,
          {
              disableClose: true,
              width: "100%",
              data: {
                  errorData: errorData,
              },
          }
      );
      this.confirmDialogRef.componentInstance.confirmMessage = "";
      this.confirmDialogRef.afterClosed().subscribe((result) => {
          localStorage.removeItem("confEWshow");
          if (result) {
          }

          this.confirmDialogRef = null;
      });
  } else if (Object.keys(warningData).length != 0) {
      localStorage.setItem("confEWshow", "warning");
      this.confirmDialogRef = this._matDialog.open(
          FuseConfirmDialogComponent,
          {
              disableClose: true,
              width: "100%",
              // data: {warningValidation:warningData},
              data: warningData,
          }
      );
      this.confirmDialogRef.componentInstance.confirmMessage =
          ApiName === "SetInvoice"
              ? ""
              : "Are you sure you want to delete?";
      // this.confirmDialogRef.componentInstance.confirmMessage = (ApiName === 'SetInvoice') ? '' : warningData;
      this.confirmDialogRef.afterClosed().subscribe((result) => {
          localStorage.removeItem("confEWshow");
          if (result) {
              this.toolbarServiceService.DeleteGData(details, ApiName);
          }
          this.confirmDialogRef = null;
      });
  } else if (
      Object.keys(warningData).length == 0 &&
      Object.keys(errorData).length == 0
  ) {
      this.toolbarServiceService.DeleteGData(details, ApiName);
  }
}

  /**
   * This function is used to delete the confirm popup for delete the account.
   * @param id -Account id
   * @param res -response data value.
   */
  ConformPopupAccountDelete(id,res) {
    this.confirmDialogRef = this._matDialog.open(
        FuseConfirmDialogComponent,
        {
            disableClose: true,
            width: "100%",
        }
    );
    this.confirmDialogRef.componentInstance.confirmMessage =res.DATA.CONFIRMATIONS[0].DESCRIPTION
    this.confirmDialogRef.componentInstance.confirmTitle =res.DATA.CONFIRMATIONS[0].TITLE
    this.confirmDialogRef.componentInstance.isShowInputField =true;

        // "Are you sure you want to delete?";
    this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result !=undefined) {
            let postData = {
                Action: "DeleteAccount",
                VALIDATEONLY: false,
                DATA: {
                  BANKFEEDACCOUNTGUID:id,
                  CONFIRMATIONTEXT: result
                },
            };
           
            this._mainAPiServiceService
                .getSetData(postData, "bank-feed")
                .subscribe((res) => {
                    if (res.STATUS == "success" && res.CODE == 200) {                       
                        this.toaster.success("Account deleted successfully");
                        $("#authorisedAccouts").click();
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            postData,
                            "bank-feed"
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            postData,
                            "bank-feed"
                        );
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                });
        }
        this.confirmDialogRef = null;
    });
}

/**
 * This function is used to connect the AnatherAccount
 * @param Action -Action which we need to pass to api. 
 * @param data -data value
 */
connectAnatherAccount(Action,data){
  this.isLoadingResults=true;
  let payload={
    action: Action,
    Data:{BANKFEEDACCOUNTGUID:data?.BANKFEEDACCOUNTGUID}
  }
  this._mainAPiServiceService.getSetData(payload, "bank-feed").subscribe(data=>{
    if(data.CODE==200  &&  data.STATUS=="success"){
       this.isLoadingResults=false;
       let AUTHORISEIFRAMEURL = data.DATA.REFRESHCONNECTIONLINK;
       var win = window.open(AUTHORISEIFRAMEURL, 'targetWindow',
          `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=800,
           height=600`);
       var timer = setInterval(() => {
           if (win.closed) {
               clearInterval(timer);
               $("#authorisedAccouts").click();
           }
       }, 1000);
    
    }else{
      this.isLoadingResults=false;
    }
  },error=>{
    this.isLoadingResults=false;
  })

}

}
