import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-matter-client',
  templateUrl: './matter-client.component.html',
  styleUrls: ['./matter-client.component.scss']
})
export class MatterClientComponent implements OnInit {

  @Input() matterdetailForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() toolTipList : any;
  @Input() testBool;
  toolTipPostion ="above";
  ContactRoleData: any;

  matterClassData$ = this.behaviorService.matterClassAPICallData$.pipe(
    switchMap(result => this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'Contact Role', 'MatterClass': result.LOOKUPFULLVALUE }}, 'lookup')),
    tap(responses=>{
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        this.ContactRoleData = responses.DATA.LOOKUPS;
      }
    })
  );

  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    public behaviorService: BehaviorService,
    public tooltipService: TooltipService
  ) { }


  /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // this.behaviorService.matterClassData$.subscribe(result => {
    //   if (result) {
    //     this._mainAPiServiceService.getSetData({ 'LookupType': 'Contact Role', 'MatterClass': result.LOOKUPFULLVALUE }, 'GetLookups').subscribe(responses => {
    //       if (responses.CODE === 200 && responses.STATUS === 'success') {
    //         this.ContactRoleData = responses.DATA.LOOKUPS;
    //         this.behaviorService.matterClassData$.unsubscribe();
    //       }
    //     });
    //   }
    // });
  }
  tooltipOpen(val) {

  }
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    navigator.clipboard.writeText(event)
  }
}


