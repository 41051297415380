import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatterPopupComponent } from '../../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';


@Component({
  selector: 'app-property-council',
  templateUrl: './property-council.component.html',
  styleUrls: ['./property-council.component.scss']
})
export class PropertyCouncilComponent implements OnInit {
  @Input() MatterPropertyDetailsForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() toolTipList: any;

  @Input() ParishLookupData: any;
  @Input() LocalityLookupData: any;
  @Input() CouncilLookupsData: any;
  
  
  toolTipPostion = "above";
  @Input() testBool;

  // ParishLookupData: any = [];
  // LocalityLookupData: any = [];
  // CouncilLookupsData: any = [];

  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions = [];
  LocalityFilteredOptions = [];
  CouncilFilteredOptions = [];

  constructor(private _mainAPiServiceService: MainAPiServiceService, public dialogRef: MatDialogRef<MatterPopupComponent>, public tooltipService: TooltipService) { }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {

    this.filteredOptions = this.ParishLookupData;
    this.LocalityFilteredOptions = this.LocalityLookupData;
    this.CouncilFilteredOptions = this.CouncilLookupsData;

    // OLD api CALL BY MIT
    // this._mainAPiServiceService.getSetData({ 'LookupType': 'Parish' }, 'GetLookups').subscribe(responses => {
    //   if (responses.CODE === 200 && responses.STATUS === 'success') {
    //     this.ParishLookupData = responses.DATA.LOOKUPS;
    //     this.filteredOptions = this.ParishLookupData;
    //   } else if (responses.MESSAGE == 'Not logged in') {
    //     this.dialogRef.close(false);
    //   }
    // });

    // this._mainAPiServiceService.getSetData({ 'LookupType': 'Locality' }, 'GetLookups').subscribe(responses => {
    //   if (responses.CODE === 200 && responses.STATUS === 'success') {
    //     this.LocalityLookupData = responses.DATA.LOOKUPS;
    //     this.LocalityFilteredOptions = this.LocalityLookupData;
    //   } else if (responses.MESSAGE == 'Not logged in') {
    //     this.dialogRef.close(false);
    //   }
    // });

    // this._mainAPiServiceService.getSetData({ 'LookupType': 'Council' }, 'GetLookups').subscribe(responses => {
    //   if (responses.CODE === 200 && responses.STATUS === 'success') {
    //     this.CouncilLookupsData = responses.DATA.LOOKUPS;
    //     this.CouncilFilteredOptions = this.CouncilLookupsData;
    //   } else if (responses.MESSAGE == 'Not logged in') {
    //     this.dialogRef.close(false);
    //   }
    // });
  }

  /**
   * This function is used to get the parish DetectChanges
   */
  parishDetectChanges(event: any, type) {

    if (type == 'Locality') {
      let filterValueLocality = this.MatterPropertyDetailsForm.value.LOCALITY.toLowerCase();
      this.LocalityFilteredOptions = this.LocalityLookupData.filter(o => o.LOOKUPFULLVALUE.toLowerCase().includes(filterValueLocality));
    } else if (type == 'Council') {
      let filterValueCouncil = this.MatterPropertyDetailsForm.value.COUNCIL.toLowerCase();
      this.CouncilFilteredOptions = this.CouncilLookupsData.filter(o => o.LOOKUPFULLVALUE.toLowerCase().includes(filterValueCouncil));
    } else {
      let filterValue = this.MatterPropertyDetailsForm.value.PARISH.toLowerCase();
      this.filteredOptions = this.ParishLookupData.filter(o => o.LOOKUPFULLVALUE.toLowerCase().includes(filterValue));
    }
  }

  /**
   * This function is used set the id data
   */
  setId(data, type) {
    if (type == 'Locality') {
      this.MatterPropertyDetailsForm.controls.LOCALITY.setValue(data.LOOKUPFULLVALUE)
    } else if (type == 'Council') {
      this.MatterPropertyDetailsForm.controls.COUNCIL.setValue(data.LOOKUPFULLVALUE)
    } else {
      this.MatterPropertyDetailsForm.controls.PARISH.setValue(data.LOOKUPFULLVALUE)
    }
  }

  /**
   * This function is used to get text 
   */
  myFunction(event: any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    //  navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    navigator.clipboard.writeText(event)
  }
}
