<div class=" one " fxLayout="column" [formGroup]="immigrationForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VISATYPE,'has-warning':errorWarningData.Warning?.VISATYPE}">
            <mat-label>Visa Type</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VISATYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VISATYPE">
         <div  *ngIf="testBool && toolTipList.VISATYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VISATYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VISATYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VALUEOFASSETS,'has-warning':errorWarningData.Warning?.VALUEOFASSETS}">
            <mat-label>Assets</mat-label>
            <input matInput style="text-align: right;" [value]="immigrationForm.get('VALUEOFASSETS').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matInput matTooltip="{{(toolTipList)?toolTipList.VALUEOFASSETS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VALUEOFASSETS" [leadZero]="true">
         <div  *ngIf="testBool && toolTipList.VALUEOFASSETS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VALUEOFASSETS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VALUEOFASSETS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VISASTATUS,'has-warning':errorWarningData.Warning?.VISASTATUS}">
            <mat-label>Visa Status</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VISASTATUS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VISASTATUS">
         <div  *ngIf="testBool && toolTipList.VISASTATUS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VISASTATUS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VISASTATUS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ANTICIPATEDDATEOFENTRY,'has-warning':errorWarningData.Warning?.ANTICIPATEDDATEOFENTRY}">
            <mat-label>Anticipated Entry</mat-label>
            <input (dateInput)="AnticipatedEntryClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.ANTICIPATEDDATEOFENTRY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ANTICIPATEDDATEOFENTRYTEXT" (dateChange)="AnticipatedEntryClick('change', $event)" matInput [matDatepicker]="AnticipatedEntry">
            <mat-datepicker-toggle matSuffix [for]="AnticipatedEntry"></mat-datepicker-toggle>
            <mat-datepicker #AnticipatedEntry></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.ANTICIPATEDDATEOFENTRY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ANTICIPATEDDATEOFENTRY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ANTICIPATEDDATEOFENTRY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LODGEMENTDATE,'has-warning':errorWarningData.Warning?.LODGEMENTDATE}">
            <mat-label>Lodgement Date</mat-label>
            <input (dateInput)="LodgementDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.LODGEMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LODGEMENTDATETEXT" (dateChange)="LodgementDateClick('change', $event)" matInput [matDatepicker]="LodgementDate">
            <mat-datepicker-toggle matSuffix [for]="LodgementDate"></mat-datepicker-toggle>
            <mat-datepicker #LodgementDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.LODGEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LODGEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LODGEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VISAEXPIRYDATE,'has-warning':errorWarningData.Warning?.VISAEXPIRYDATE}">
            <mat-label>Visa Expiry Date</mat-label>
            <input (dateInput)="VisaExpiryDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.VISAEXPIRYDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VISAEXPIRYDATETEXT" (dateChange)="VisaExpiryDateClick('change', $event)" matInput [matDatepicker]="VisaExpiryDate">
            <mat-datepicker-toggle matSuffix [for]="VisaExpiryDate"></mat-datepicker-toggle>
            <mat-datepicker #VisaExpiryDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.VISAEXPIRYDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VISAEXPIRYDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VISAEXPIRYDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DECISIONDUEDATE,'has-warning':errorWarningData.Warning?.DECISIONDUEDATE}">
            <mat-label>Decision Due Date</mat-label>
            <input (dateInput)="DecisionDueDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.DECISIONDUEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DECISIONDUEDATETEXT" (dateChange)="DecisionDueDateClick('change', $event)" matInput [matDatepicker]="DecisionDueDate">
            <mat-datepicker-toggle matSuffix [for]="DecisionDueDate"></mat-datepicker-toggle>
            <mat-datepicker #DecisionDueDate></mat-datepicker>
         <div  *ngIf="testBool && toolTipList.DECISIONDUEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DECISIONDUEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DECISIONDUEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>