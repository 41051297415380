import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-litigation',
    templateUrl: './litigation.component.html',
    styleUrls: ['./litigation.component.scss']
})
export class LitigationComponent implements OnInit, OnDestroy {
    CourtData: any;
    DivisionData: any;
    RegistryData: any;
    ListData: any;
    ClientTypeData: any;
    CourtBelowData: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(private datepipe: DatePipe,
        private _mainAPiServiceService: MainAPiServiceService, public tooltipService: TooltipService) { }

    @Input() matterdetailForm: FormGroup;
    @Input() errorWarningData: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";

    ngOnInit() {
        let courtLookups = JSON.parse(localStorage.getItem('court_lookups'));
        if (courtLookups && courtLookups != null) {
            this.CourtData = courtLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'court' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.CourtData = responses.DATA.LOOKUPS;
                    localStorage.setItem('court_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }

        let divisionLookups = JSON.parse(localStorage.getItem('division_lookups'));
        if (divisionLookups && divisionLookups != null) {
            this.DivisionData = divisionLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'division' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.DivisionData = responses.DATA.LOOKUPS;
                    localStorage.setItem('division_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let registryLookups = JSON.parse(localStorage.getItem('registry_lookups'));
        if (registryLookups && registryLookups != null) {
            this.RegistryData = registryLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'registry' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.RegistryData = responses.DATA.LOOKUPS;
                    localStorage.setItem('registry_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let CourtListLookups = JSON.parse(localStorage.getItem('court_list_lookups'));
        if (CourtListLookups && CourtListLookups != null) {
            this.ListData = CourtListLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Court List' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.ListData = responses.DATA.LOOKUPS;
                    localStorage.setItem('court_list_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let clientTypeLookups = JSON.parse(localStorage.getItem('client_type_lookups'));
        if (clientTypeLookups && clientTypeLookups != null) {
            this.ClientTypeData = clientTypeLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'client type' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.ClientTypeData = responses.DATA.LOOKUPS;
                    localStorage.setItem('client_type_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let courtBelowLookups = JSON.parse(localStorage.getItem('court_below_lookups'));
        if (courtBelowLookups && courtBelowLookups != null) {
            this.CourtBelowData = courtBelowLookups;
        } else {
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'court below' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.CourtBelowData = responses.DATA.LOOKUPS;
                    localStorage.setItem('court_below_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    // DateOfHearingsClick(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.matterdetailForm.controls['DATEOFHEARINGS'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }
    MaterialDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['MATERIALDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

}
