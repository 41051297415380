import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
@Component({
    selector: 'app-cheque-popup',
    templateUrl: './cheque-popup.component.html',
    styleUrls: ['./cheque-popup.component.scss']
})
export class ChequePopupComponent implements OnInit, OnDestroy {
    amountValue: FormControl;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    toolTipPostion ="above";
    previousPriceBal: any = 0;
    chequeStatmentData: any;
    dialogTitle: any;
    isLoadingResults: boolean = false;
    action: any;
    PAYEE: string;
    AMOUNT: any = 0;
    PAYMENTTYPE:any='BPay'
    PAYMENTDATE
    BPAYBILLERCODE
    BPAYREFERENCE
    ACCOUNTNAME
    ACCOUNTNUMBER
    FormAction: any;
    isspiner: boolean = false;
    confirmDialogRef: any;
    curentUser: any;
    windowNameId: any;
    testBool: boolean=false;
    toolTipList: any;
    paymentDate: string;
    Data: any;
    BSB: any;
    EFTREFERENCE: any;
    FOREIGNEFTSWIFTCODE: any;
    FOREIGNEFTAMOUNT: any;
    FOREIGNEFTBANKNAME: any;
    FOREIGNEFTCONVERSIONDETAILS: any;
    FOREIGNEFTCOUNTRY: any;
    FOREIGNEFTCURRENCY: any;
    FOREIGNEFTIBAN: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private _mainAPiServiceService: MainAPiServiceService,
        private behaviorService: BehaviorService, public _matDialog: MatDialog,
        private toastr: ToastrService, public dialogRef: MatDialogRef<ChequePopupComponent>,
        private currencyPipe: CurrencyPipe,
        public globalFunService:GlobalFunctionsService,
        public tooltipService:TooltipService,) {
        this.amountValue = new FormControl(null, { updateOn: 'blur' });
        this.action = data.action;
        this.behaviorService.chequeStatment$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.chequeStatmentData = result;
            }
        });
        // this.behaviorService.MatterData$.subscribe(result => {
        //     if (result) {
        //         this.curentUser = result;
        //     }
        // });
        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                 const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
             this.curentUser = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
             if (this.curentUser) {
             } else {
               if (result) {
                 this.curentUser = result;
                 localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.curentUser))
               }
             }
        });

        if (this.action === 'new') {
            this.dialogTitle = 'New Cheque';
            this.validationColor();
        } else if (this.action === 'edit') {
            this.dialogTitle = 'Update Cheque';
            this.LoadData();
        }
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.PAYMENTTYPE='BPay'
        this.NewCheque()
        this.setTooltipData()
        this.getPaymentType(this.PAYMENTTYPE);
        this.changeForControl('PAYMENTTYPE',this.PAYMENTTYPE)
     }

     ngOnDestroy(): void {
         this._unsubscribeAll$.next();
         this._unsubscribeAll$.complete();
     }

     async setTooltipData(){
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if(TooltipData && TooltipData['SettlementCheque'] ){
          this.toolTipList  = TooltipData['SettlementCheque'];
        }else{
          this.tooltipService.setToolTipData = ('SettlementCheque');
         }
      };
    
      /**
       * This function is used to toggle for the updates the data value.
       */
      ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
      };

      /**
       * This function is used to chenge for the control data.
       */
    changeForControl(KEY, value){     
        const dubObj ={...this.errorWarningDataArray.Error};
        let element = this.errorWarningData.Error;
        for (const [key, key_value] of Object.entries(dubObj)) {
         if (value != undefined && value != null && value) {
             delete element[KEY];
             dubObj[KEY] = key_value;
           this.errorWarningDataArray.Error = {...dubObj}
         } else if (!value) {
            element[KEY] = dubObj[KEY];
         }
        }
    }

    /**
     * This function is used to validate the color.
     */
    validationColor() {
        let tempError: any = [];
        let tempWarning: any = [];
        tempError["PAYEE"] = {};
        tempError["PRICEFROM"] = {};
        // tempError["PAYMENTTYPE"] = {};
        // tempError["PAYMENTDATE"] = {};
        // tempError["BPAYBILLERCODE"] = {};
        // tempError["BPAYREFERENCE"] = {};
        // tempError["ACCOUNTNAME"] = {};
        // tempError["BSB"] = {};
        // tempError["ACCOUNTNUMBER"] = {};
        // tempError["EFTREFERENCE"] = {};
        // tempError["FOREIGNEFTSWIFTCODE"] = {};
        // tempError["FOREIGNEFTIBAN"] = {};
        // tempError["FOREIGNEFTBANKNAME"] = {};
        // tempError["FOREIGNEFTCOUNTRY"] = {};
        // tempError["FOREIGNEFTAMOUNT"] = {};
        // tempError["FOREIGNEFTCONVERSIONDETAILS"] = {};
        
        

        this.errorWarningData = { Error: tempError, Warning: tempWarning };
        this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
    }

    /**
     * This functionis used to load the data value.
     */
    LoadData() {
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData({"ACTION": "GetData",FILTERS:{ SETTLEMENTCHEQUEGUID: this.chequeStatmentData.SETTLEMENTCHEQUEGUID }}, 'settlement-cheque').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toolTipList = response.DATA.FIELDTIPS
                this.AMOUNT = Number(response.DATA.RECORDS[0].AMOUNT).toFixed(2);
                this.PAYEE = response.DATA.RECORDS[0].PAYEE;
                this.PAYMENTTYPE=response.DATA.RECORDS[0].PAYMENTTYPE
                //BPAY
                this.BPAYBILLERCODE= response.DATA.RECORDS[0].BPAYDETAILS.BPAYBILLERCODE,
                this.BPAYREFERENCE=response.DATA.RECORDS[0].BPAYDETAILS.BPAYREFERENCE,
                //EFTDETAILS
                this.ACCOUNTNAME=response.DATA.RECORDS[0].EFTDETAILS.ACCOUNTNAME,
                this.ACCOUNTNUMBER=response.DATA.RECORDS[0].EFTDETAILS.ACCOUNTNUMBER,
                this.BSB=response.DATA.RECORDS[0].EFTDETAILS.BSB,
                this.EFTREFERENCE =response.DATA.RECORDS[0].EFTDETAILS.EFTREFERENCE,
                //FOREIGNEFT
                this.FOREIGNEFTSWIFTCODE=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTSWIFTCODE,
                this.FOREIGNEFTIBAN=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTIBAN,
                this.FOREIGNEFTAMOUNT=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTAMOUNT,
                this.FOREIGNEFTBANKNAME=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTBANKNAME
                this.FOREIGNEFTCONVERSIONDETAILS=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTCONVERSIONDETAILS,
                this.FOREIGNEFTCOUNTRY=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTCOUNTRY,
                this.FOREIGNEFTCURRENCY=response.DATA.RECORDS[0].FOREIGNEFT.FOREIGNEFTCURRENCY


                this.isLoadingResults = false;
            }
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to NewCheque data get
     */
    NewCheque(){
        if(this.action == 'new'){
            this._mainAPiServiceService.getSetData({FormAction:"default" ,DATA:{}}, 'settlement-cheque').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toolTipList = response.DATA.FIELDTIPS;
                //BPAYDETAILS
               this.BPAYBILLERCODE= response.DATA.DEFAULTVALUES.BPAYDETAILS.BPAYBILLERCODE,
               this.BPAYREFERENCE=response.DATA.DEFAULTVALUES.BPAYDETAILS.BPAYREFERENCE,
               //EFTDETAILS
               this.ACCOUNTNAME=response.DATA.DEFAULTVALUES.EFTDETAILS.ACCOUNTNAME,
               this.ACCOUNTNUMBER=response.DATA.DEFAULTVALUES.EFTDETAILS.ACCOUNTNUMBER,
               this.BSB=response.DATA.DEFAULTVALUES.EFTDETAILS.BSB,
               this.EFTREFERENCE =response.DATA.DEFAULTVALUES.EFTDETAILS.EFTREFERENCE,
               //FOREIGNEFT
               this.FOREIGNEFTSWIFTCODE=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTSWIFTCODE,
               this.FOREIGNEFTIBAN=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTIBAN,
               this.FOREIGNEFTAMOUNT=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTAMOUNT,
               this.FOREIGNEFTBANKNAME=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTBANKNAME
               this.FOREIGNEFTCONVERSIONDETAILS=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTCONVERSIONDETAILS,
               this.FOREIGNEFTCOUNTRY=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTCOUNTRY,
               this.FOREIGNEFTCURRENCY=response.DATA.DEFAULTVALUES.FOREIGNEFT.FOREIGNEFTCURRENCY
            }
            })
        }
      }

      /**
       * This function is used to save the cheque
       */
    saveCheque() {
        this.isspiner = true;
        this.FormAction = this.action === 'new' ? 'insert' : 'update';
        if(this.PAYMENTTYPE=='BPay'){
            this.Data = {
                PAYEE: this.PAYEE,
                AMOUNT: Number(this.calCloingBalSend(this.AMOUNT)),
                MATTERGUID: this.curentUser.MATTERGUID,
                PAYMENTDATE:this.paymentDate,
                PAYMENTTYPE:this.PAYMENTTYPE,
                BPAYBILLERCODE:this.BPAYBILLERCODE,
                BPAYREFERENCE:this.BPAYREFERENCE
            };
        }else if(this.PAYMENTTYPE=='EFT'){
            this.Data={
                PAYEE: this.PAYEE,
                AMOUNT: Number(this.calCloingBalSend(this.AMOUNT)),
                MATTERGUID: this.curentUser.MATTERGUID,
                PAYMENTTYPE:this.PAYMENTTYPE,
                PAYMENTDATE:this.paymentDate,
                ACCOUNTNAME:this.ACCOUNTNAME,
                ACCOUNTNUMBER:this.ACCOUNTNUMBER,
                BSB:this.BSB,
                EFTREFERENCE:this.EFTREFERENCE,
            }
           

        }else if(this.PAYMENTTYPE=='Foreign EFT'){
            this.Data={
                PAYEE: this.PAYEE,
                AMOUNT: Number(this.calCloingBalSend(this.AMOUNT)),
                MATTERGUID: this.curentUser.MATTERGUID,
                PAYMENTDATE:this.paymentDate,
                PAYMENTTYPE:this.PAYMENTTYPE,
               FOREIGNEFTSWIFTCODE:this.FOREIGNEFTSWIFTCODE,
               FOREIGNEFTIBAN:this.FOREIGNEFTIBAN,
               FOREIGNEFTAMOUNT:this.FOREIGNEFTAMOUNT,
               FOREIGNEFTBANKNAME:this.FOREIGNEFTBANKNAME,
               FOREIGNEFTCONVERSIONDETAILS:this.FOREIGNEFTCONVERSIONDETAILS,
               FOREIGNEFTCOUNTRY:this.FOREIGNEFTCOUNTRY 
            }

        }else{
            this.Data = {
                PAYEE: this.PAYEE,
                AMOUNT: Number(this.calCloingBalSend(this.AMOUNT)),
                MATTERGUID: this.curentUser.MATTERGUID,
                PAYMENTDATE:this.paymentDate,
                PAYMENTTYPE:this.PAYMENTTYPE
               
            };
        }
       
        if (this.action != 'new') {
            this.Data.SETTLEMENTCHEQUEGUID = this.chequeStatmentData.SETTLEMENTCHEQUEGUID;
        }
        let details = { FormAction: this.FormAction, VALIDATEONLY: true, Data: this.Data, };
        this._mainAPiServiceService.getSetData(details, "settlement-cheque").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
                this.checkValidation(response.DATA.VALIDATIONS, details);
            } else if (response.CODE == 451 && response.STATUS == "warning") {
                this.checkValidation(response.DATA.VALIDATIONS, details);
            } else if (response.CODE == 450 && response.STATUS == "error") {
                this.checkValidation(response.DATA.VALIDATIONS, details);
            } else if (response.MESSAGE == "Not logged in") {
                this.dialogRef.close(false);
            }
        }, (error) => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation data value. 
     */
    async checkValidation(bodyData: any, details: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //     if (value.VALUEVALID == "No") {
        //         errorData.push(value.ERRORDESCRIPTION);
        //         tempError[value.FIELDNAME] = value;
        //     } else if (value.VALUEVALID == "Warning") {
        //         tempWarning[value.FIELDNAME] = value;
        //         warningData.push(value.ERRORDESCRIPTION);
        //     }
        // });
        // this.errorWarningData = { Error: tempError, Warning: tempWarning };
        // this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };

        // if (Object.keys(errorData).length != 0) {
        //     this.toastr.error(errorData);
        //     this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //     this.isspiner = false;
        //     this.confirmDialogRef = this._matDialog.open(
        //         FuseConfirmDialogComponent,
        //         {
        //             disableClose: true,
        //             width: "100%",
        //             data: warningData,
        //         }
        //     );
        //     this.confirmDialogRef.componentInstance.confirmMessage =
        //         "Are you sure you want to Save?";
        //     this.confirmDialogRef.afterClosed().subscribe((result) => {
        //         if (result) {
        //             this.isspiner = true;
        //             this.saveChequeData(details);
        //         } else {
        //             this.isspiner = false;
        //         }
        //         this.confirmDialogRef = null;
        //     });
        // } else if (
        //     Object.keys(warningData).length == 0 &&
        //     Object.keys(errorData).length == 0
        // ) {
        //     this.saveChequeData(details);
        // }

        this.isspiner = false;
        await this.globalFunService.checkValidation(bodyData, details)
        .subscribe(data => {
          if (data) {
            this.errorWarningData = data.errorWarningData;
            this.errorWarningDataArray = data.errorWarningData;
            if (data.callback) {
              this.saveChequeData(details);
            }
          }
        });
    }

    /**
     * This function is used to save the chaque data value
     */
    saveChequeData(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, "settlement-cheque").subscribe((response) => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                if (this.action !== "edit") {
                    this.toastr.success(" save successfully");
                } else {
                    this.toastr.success(" update successfully");
                }
                $('#refreshStatment').click();
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == "warning") {
                this.isspiner = false;
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == "error") {
                this.isspiner = false;
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == "Not logged in") {
                this.dialogRef.close(false);
            }
        }, (error) => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This functionis used to calculate the amount.
     */
    amoutCal() {
        this.AMOUNT = this.calCLosingbalShow(this.AMOUNT);
    }

    /**
     * This function is used to calculate the closing balance
     */
    calCLosingbalShow(val) {
        let isNumCalClosingBal = val.toString().replace(/[$,]/g, "");
        if (!isNaN(isNumCalClosingBal)) {
            this.previousPriceBal = isNumCalClosingBal;
            let formattedAmount = this.currencyPipe.transform(isNumCalClosingBal, "$");
            return formattedAmount;
        }
    }

    /**
     * This function is used to calculate the closing balance
     */
    calCloingBalSend(val) {
        let sendBal = val.toString().replace(/[$,]/g, "");
        return Number(sendBal).toFixed(2);
    }

    getPaymentType(data){

    }

    /**
     * This function is used to get the Payment Date
     */
    getPaymentDate(data){
    let date =moment(data).format('DD/MM/YYYY');
    this.paymentDate=date
    }
}
