import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';


@Component({
  selector: 'app-activity-sundry-dialog',
  templateUrl: './activity-sundry-dialog.component.html',
  styleUrls: ['./activity-sundry-dialog.component.scss']
})
export class ActivitySundryDialogComponent implements OnInit, OnDestroy {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  activityForm: FormGroup;
  isLoadingResults: boolean = false;
  action: string;
  dialogTitle: string;
  dialogTitleBtn: any = 'Save';
  isspiner: boolean = false;
  RATEPERUNIT: any;
  successMsg: any;
  errorWarningData: any = {};
  toolTipList: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<ActivitySundryDialogComponent>,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public behaviorService: BehaviorService,
    private mainAPiServiceService: MainAPiServiceService,
    public _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService) {
    localStorage.setItem('istrackid', 'ActivitySundryDialogComponent');
    this.action = data.action;
    if (this.action == 'new') {
      this.dialogTitle = 'New Activity';
      this.dialogTitleBtn = 'Save';
    } else if (this.action == 'edit') {
      this.dialogTitle = 'Edit Activity';
      this.dialogTitleBtn = 'Update';
    } else {
      this.dialogTitle = 'Duplicate Activity';
      this.dialogTitleBtn = 'Duplicate';
    }
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'ActivitySundryDialogComponent' && result['click'] && result['data']['activity']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'ActivitySundryDialogComponent' && result['click'] && result['data']['activity']) {
        this.saveActivity();
      }
    });

  }

 /**
     * It runs once after the component's view has been fully initialized.
  */
  ngOnInit() {
    this.activityForm = this._formBuilder.group({
      ACTIVITYGUID: [''],
      ACTIVITYTYPE: [''],
      ACTIVITYID: ['', Validators.required],
      DESCRIPTION: ['', Validators.required],
      GSTTYPE: [''],
      RATEPERUNIT: ['', {updateOn: 'blur'}, Validators.required],
      UNITDESCRIPTIONPLURAL: [''],
      UNITDESCRIPTIONSINGLE: [''],
      HIGHLIGHTITEM:[false]
    });
    if (this.action == 'new'){
      this.NewActivity()
    }
    let postData:any = { "Action": "GetData",
    "Filters": {
      ACTIVITYGUID: this.data.ACTIVITYGUID }
    }
    if (this.action == 'edit' || this.action == "Duplicate") {
      this.activityForm.controls['ACTIVITYGUID'].setValue(this.data.ACTIVITYGUID);
      this.isLoadingResults = true;
      this.mainAPiServiceService.getSetData(postData, 'activity').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
          if (response.DATA.ACTIVITIES[0]) {
            let activityData = response.DATA.RECORDS[0];
            this.toolTipList = response.DATA.FIELDTIPS;
            this.activityForm.controls['ACTIVITYTYPE'].setValue(activityData.ACTIVITYTYPEDESC);
            this.activityForm.controls['ACTIVITYID'].setValue(activityData.ACTIVITYID);
            this.activityForm.controls['DESCRIPTION'].setValue(activityData.DESCRIPTION);
            this.activityForm.controls['GSTTYPE'].setValue(activityData.GSTTYPEDESC);
            this.activityForm.controls['RATEPERUNIT'].setValue(parseFloat(activityData.RATEPERUNIT).toFixed(2));
            this.activityForm.controls['UNITDESCRIPTIONPLURAL'].setValue(activityData.UNITDESCRIPTIONPLURAL);
            this.activityForm.controls['UNITDESCRIPTIONSINGLE'].setValue(activityData.UNITDESCRIPTIONSINGLE);
            this.activityForm.controls['HIGHLIGHTITEM'].setValue(activityData.HIGHLIGHTITEM);

          } else {
            this.toastr.error('No data found please try again');
            this.dialogRef.close(false);
          }
        }
        this.isLoadingResults = false;
      }, error => {
        this.toastr.error(error);
      });
    }
    this.defultValidation();
  }

  
  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }

  /**
   * This functionis used to Set the default validation data
   */
  defultValidation() {
    let tempError: any = [];
    let tempWarning: any = [];
    tempWarning['RATEPERUNIT'] = {};
    tempWarning['UNITDESCRIPTIONSINGLE'] = {};
    tempWarning['UNITDESCRIPTIONPLURAL'] = {};

    tempError['ACTIVITYID'] = {};
    tempError['DESCRIPTION'] = {};
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
  }

  /**
   * This function is used to RatePer Unit Value data
   */
  RatePerUnitVal() {
    this.RATEPERUNIT = parseFloat(this.f.RATEPERUNIT.value).toFixed(2);
  }
  get f() {
    return this.activityForm.controls;
  }

  /**
   * This function is used to New Activity save
   */
  NewActivity(){
    let postdata:any ={
      ACTION:"default" ,
      DATA:{}
    }
    this.mainAPiServiceService.getSetData(postdata, 'activity').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toolTipList = response.DATA.FIELDTIPS;
      }
    })
  }

  /**
   * This function is used to save the Activity data
   */
  saveActivity() {
    this.isspiner = true;
    
    let PostData: any = {
      "ACTIVITYID": this.f.ACTIVITYID.value,
      "ACTIVITYTYPE": this.f.ACTIVITYTYPE.value,
      "DESCRIPTION": this.f.DESCRIPTION.value,
      "GSTTYPE": this.f.GSTTYPE.value,
      "RATEPERUNIT": this.f.RATEPERUNIT.value,
      "UNITDESCRIPTIONSINGLE": this.f.UNITDESCRIPTIONSINGLE.value,
      "UNITDESCRIPTIONPLURAL": this.f.UNITDESCRIPTIONPLURAL.value,
      "HIGHLIGHTITEM": this.f.HIGHLIGHTITEM.value,
    };
    
    this.successMsg = 'Save successfully';
    let FormAction = this.action == 'edit' ? 'update' : 'insert';
    if (this.action == 'edit') {
      PostData.ACTIVITYGUID = this.f.ACTIVITYGUID.value;
      this.successMsg = 'Update successfully';
    };
    
    let PostActivityData: any = { ACTION: FormAction, VALIDATEONLY: true, Filters: PostData };
    this.mainAPiServiceService.getSetData(PostActivityData, 'activity').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.checkValidation(res.DATA.VALIDATIONS, PostActivityData);
      } else if (res.CODE == 451 && res.STATUS == "warning") {
        this.checkValidation(res.DATA.VALIDATIONS, PostActivityData);
      } else if (res.CODE == 450 && res.STATUS == "error") {
        this.checkValidation(res.DATA.VALIDATIONS, PostActivityData);
      } else if (res.MESSAGE == "Not logged in") {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to check validation data
   * @param bodyData -body data value
   * @param PostActivityData -post activity data value
   */
 async checkValidation(bodyData: any, PostActivityData: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   } else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, "Warning": tempWarning };
    // if (Object.keys(errorData).length != 0){
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    //  } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   // this.toastr.warning(warningData);
    //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.saveActivityData(PostActivityData);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // }
    // if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0)
    //   this.saveActivityData(PostActivityData);

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, PostActivityData)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.saveActivityData(PostActivityData);
        }
      }
    });
  }

  /**
   * This function is used to Save Activity data
   * @param PostActivityData -data value
   */
  saveActivityData(PostActivityData: any) {
    PostActivityData.VALIDATEONLY = false;
    this.mainAPiServiceService.getSetData(PostActivityData, 'activity').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success(this.successMsg);
        this.dialogRef.close(true);
      } else if (res.CODE == 451 && res.STATUS == "warning") {
        this.toastr.warning(this.successMsg);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == "error") {
        this.toastr.error(res.STATUS);
        this.isspiner = false;
      } else if (res.MESSAGE == "Not logged in") {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

}
