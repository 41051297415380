<div class=" one " fxLayout="column" [formGroup]="MotorVehicleAccidentForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE,'has-warning':errorWarningData.Warning?.ACCIDENTDATE}">
            <mat-label>Accident Date</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATETEXT" (dateInput)="AccidentDateClick('input', $event)" (dateChange)="AccidentDateClick('change', $event)" matInput [matDatepicker]="AccidentDate">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE2,'has-warning':errorWarningData.Warning?.ACCIDENTDATE2}">
            <mat-label>Accident Date 2</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE2?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATE2TEXT" (dateInput)="AccidentDate2Click('input', $event)" (dateChange)="AccidentDate2Click('change', $event)" matInput [matDatepicker]="AccidentDate2">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate2"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate2></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE2" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE2.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE2?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE3,'has-warning':errorWarningData.Warning?.ACCIDENTDATE3}">
            <mat-label>Accident Date 3</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE3?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATE3TEXT" (dateInput)="AccidentDate3Click('input', $event)" (dateChange)="AccidentDate3Click('change', $event)" matInput [matDatepicker]="AccidentDate3">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate3"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate3></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE3" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE3.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE3?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCIDENTDATE4,'has-warning':errorWarningData.Warning?.ACCIDENTDATE4}">
            <mat-label>Accident Date 4</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.ACCIDENTDATE4?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ACCIDENTDATE4TEXT" (dateInput)="AccidentDate4Click('input', $event)" (dateChange)="AccidentDate4Click('change', $event)" matInput [matDatepicker]="AccidentDate4">
            <mat-datepicker-toggle matSuffix [for]="AccidentDate4"></mat-datepicker-toggle>
            <mat-datepicker #AccidentDate4></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.ACCIDENTDATE4" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACCIDENTDATE4.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCIDENTDATE4?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.EXPIRATIONDATE,'has-warning':errorWarningData.Warning?.EXPIRATIONDATE}">
            <mat-label>Expiry Date</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.EXPIRATIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="EXPIRATIONDATETEXT" (dateInput)="ExpirtyDateClick('input', $event)" (dateChange)="ExpirtyDateClick('change', $event)" matInput [matDatepicker]="ExpiryDate">
            <mat-datepicker-toggle matSuffix [for]="ExpiryDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpiryDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.EXPIRATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXPIRATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPIRATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLACEOFINJURY,'has-warning':errorWarningData.Warning?.PLACEOFINJURY}">
            <mat-label>Place Of Accident</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PLACEOFINJURY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PLACEOFINJURY">
             <div  *ngIf="testBool && toolTipList.PLACEOFINJURY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PLACEOFINJURY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLACEOFINJURY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATESENTTOINSURER,'has-warning':errorWarningData.Warning?.DATESENTTOINSURER}">
            <mat-label>Date Sent To Insurer</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.DATESENTTOINSURER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DATESENTTOINSURERTEXT" (dateInput)="DateSendToInsurerPiker('input', $event)" (dateChange)="DateSendToInsurerPiker('change', $event)" matInput [matDatepicker]="DATESENTTOINSURERPiker">
            <mat-datepicker-toggle matSuffix [for]="DATESENTTOINSURERPiker"></mat-datepicker-toggle>
            <mat-datepicker #DATESENTTOINSURERPiker></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATESENTTOINSURER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATESENTTOINSURER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATESENTTOINSURER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATERECEIVEDBYINSURER,'has-warning':errorWarningData.Warning?.DATERECEIVEDBYINSURER}">
            <mat-label>Date Received By Insurer</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.DATERECEIVEDBYINSURER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DATERECEIVEDBYINSURERTEXT" (dateInput)="DateReceivedByInsurerPiker('input', $event)" (dateChange)="DateReceivedByInsurerPiker('change', $event)" matInput [matDatepicker]="DATERECEIVEDBYINSURERPiker">
            <mat-datepicker-toggle matSuffix [for]="DATERECEIVEDBYINSURERPiker"></mat-datepicker-toggle>
            <mat-datepicker #DATERECEIVEDBYINSURERPiker></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.DATERECEIVEDBYINSURER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATERECEIVEDBYINSURER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATERECEIVEDBYINSURER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEDAWARD,'has-warning':errorWarningData.Warning?.ESTIMATEDAWARD}">
            <mat-label>Est. Award</mat-label>
            <input matInput style="text-align: right;" [value]="MotorVehicleAccidentForm.get('ESTIMATEDAWARD').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.ESTIMATEDAWARD?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ESTIMATEDAWARD" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.ESTIMATEDAWARD" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEDAWARD.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEDAWARD?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CLAIMNUMBER,'has-warning':errorWarningData.Warning?.CLAIMNUMBER}">
            <mat-label>Claim Number</mat-label>
            <input name="CLAIMNUMBER" matTooltip="{{(toolTipList)?toolTipList.CLAIMNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CLAIMNUMBER" matInput>
             <div  *ngIf="testBool && toolTipList.CLAIMNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLAIMNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLAIMNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SETTLEMENTDATE,'has-warning':errorWarningData.Warning?.SETTLEMENTDATE}">
            <mat-label>Settlement Date</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.SETTLEMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SETTLEMENTDATETEXT" (dateInput)="SettlementDateClick('input', $event)" (dateChange)="SettlementDateClick('change', $event)" matInput [matDatepicker]="SettlementDate">
            <mat-datepicker-toggle matSuffix [for]="SettlementDate"></mat-datepicker-toggle>
            <mat-datepicker #SettlementDate></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.SETTLEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SETTLEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>