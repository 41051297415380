<!-- SIDE PANEL -->
<!-- && fuseConfig.layout.sidepanel.position === 'left'" -->
<ng-container
    *ngIf="(!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right' && isTaskData)">
    <fuse-sidebar name="chatPanel" position="right"
        class="chat-panel right-chat-panel" [folded]="true"
        [foldedAutoTriggerOnHover]="false" lockedOpen="gt-md">
        <app-task-reminder-details [taskData]="taskData"
            (isTaskData)="isTaskDataBack($event)">
        </app-task-reminder-details>
    </fuse-sidebar>
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
    <!-- for new v11 -->
    <!-- <div class="cookie_policy_mian" style="color:rgb(255, 255, 255);" [style.background-color]="warningColor" *ngIf="(isOpenWarning && UserData?.REGISTRATIONSTATUS=='Using New UI'|| UserData?.REGISTRATIONSTATUS=='')">
        <mat-icon style="padding: 11px 0px 0px 7px;font-size: 18px;">
            <img src="assets/icons/web_app/Warning.png">
        </mat-icon>
        <span style="margin-left: 10px;">Scheduled maintenance from 22:00 - 23:00 UTC </span>
        <mat-icon (click)="CloseWarning()" style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">
            clear</mat-icon>
    </div> -->
    <div class="cookie_policy_mian" style="color:rgb(255, 255, 255);"
        [style.background-color]="selectedColore"
        *ngIf="(isOpenNotifications && UserData?.REGISTRATIONSTATUS=='Using New UI')">
        <span>{{UserData?.REGISTRATIONMESSAGE}} </span>
        <!-- <button mat-raised-button class="subscribeBtn" [routerLink]="['system-setting/account-management/basicinfo']">Click here to subscribe to SILQ</button> -->
        <mat-icon (click)="CloseNotification()"
            style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">
            clear</mat-icon>
    </div>

    <div class="cookie_policy_mian" style="color:rgb(255, 255, 255);"
        [style.background-color]="selectedColore"
        *ngIf="(isOpenNotifications && UserData?.REGISTRATIONSTATUS=='Current Trial')">
        <!-- <span>Your registration is expiring soon, please update your payment details in Account Management or contact
            SILQ on 1300 55 66 89. You are registered until {{UserData.REGISTRATIONDATE}}</span> -->
        <span>{{UserData?.REGISTRATIONMESSAGE}} </span>
        <button mat-raised-button class="subscribeBtn"
            [routerLink]="['system-setting/account-management/basicinfo']">Click
            here to subscribe to SILQ</button>
        <mat-icon (click)="CloseNotification()"
            style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">
            clear</mat-icon>
    </div>
    <div class="cookie_policy_mian" style="background-color:#FFA500;color:#fff;"
        *ngIf="(isOpenNotifications && UserData?.REGISTRATIONSTATUS=='Trial Expires Soon')">
        <!-- OLD (isOpenNotifications && (UserData?.REGISTRATIONSTATUS=='Expires Soon' || UserData?.REGISTRATIONSTATUS=='Trial Expires Soon')) -->
        <!-- <span>Your registration is expiring soon, please update your payment details in Account Management or contact
            SILQ on 1300 55 66 89. You are registered until {{UserData.REGISTRATIONDATE}}</span> -->
        <span>{{UserData?.REGISTRATIONMESSAGE}} </span>
        <button mat-raised-button class="subscribeBtn"
            [routerLink]="['system-setting/account-management/basicinfo']">Click
            here to subscribe to SILQ</button>
        <mat-icon (click)="CloseNotification()"
            style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">
            clear</mat-icon>
    </div>
    <div class="cookie_policy_mian" style="background-color: red;color:#fff;"
        *ngIf="(isOpenNotifications && UserData?.REGISTRATIONSTATUS=='Grace Period')">
        <!-- <span>Your registration expired on the {{UserData.REGISTRATIONDATE}}. You currently have a 7 day grace period
            until you are locked out of your account. Please update your payment details in Account Management or
            contact SILQ on 1300 55 66 89.</span> -->
        <span>{{UserData?.REGISTRATIONMESSAGE}} </span>
        <button mat-raised-button class="subscribeBtn"
            [routerLink]="['system-setting/account-management/basicinfo']">Click
            here to update your payment details</button>
        <mat-icon (click)="CloseNotification()"
            style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">
            clear</mat-icon>
    </div>
    <div class="cookie_policy_mian" style="background-color: red;color:#fff;"
        *ngIf="(UserData?.REGISTRATIONSTATUS=='Trial Expired')">
        <!-- (isOpenNotificationsPermission || UserData?.REGISTRATIONSTATUS=='Trial Expired')))" -->
        <!-- <span>You registration and 7 day grace period has expired. Please update your payment details in Account
            Management or contact SILQ on 1300 55 66 89.
        </span> -->
        <span>{{UserData?.REGISTRATIONMESSAGE}} </span>
        <button mat-raised-button class="subscribeBtn"
            [routerLink]="['system-setting/account-management/basicinfo']">Click
            here to subscribe to SILQ</button>
        <!-- <mat-icon (click)="CloseNotification()" style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">clear</mat-icon> -->
    </div>

    <div class="cookie_policy_mian" style="background-color: red;color:#fff;"
        *ngIf="(isOpenNotifications && (UserData?.REGISTRATIONSTATUS=='Expired' || UserData?.REGISTRATIONSTATUS=='Expires Soon'))">
        <!-- <span>You registration and 7 day grace period has expired. Please update your payment details in Account
            Management or contact SILQ on 1300 55 66 89.
        </span> -->
        <span>{{UserData?.REGISTRATIONMESSAGE}} </span>
        <button mat-raised-button class="subscribeBtn"
            [routerLink]="['system-setting/account-management/basicinfo']">Click
            here to update your payment details</button>
        <!-- <mat-icon (click)="CloseNotification()" style="position: absolute;padding: 11px 0px 0px 10px;font-size: 18px;">clear</mat-icon> -->
    </div>

    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <div id="container-1" class="container sidebarwrap">
        <!-- NAVBAR: Left -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container folded-sidebarcontainer">

            <!-- TOOLBAR: Below fixed -->
            <ng-container
                *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'">
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
            </ng-container>
            <!-- / TOOLBAR: Below fixed -->

            <div id="container-3" class="container  main-container" fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

                <!-- TOOLBAR: Below static -->
                <ng-container
                    *ngIf="fuseConfig.layout.toolbar.position === 'below-static'">
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </ng-container>
                <!-- / TOOLBAR: Below static -->

                <!-- CONTENT -->
                <content class="inner-scroll"
                    [ngClass]="router.url =='dashboard/firm-dashboard'?'.firm-dashboard':''"></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below static -->
                <ng-container
                    *ngIf="fuseConfig.layout.footer.position === 'below-static'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below static -->

            </div>

            <!-- FOOTER: Below fixed -->
            <ng-container
                *ngIf="fuseConfig.layout.footer.position === 'below-fixed'">
                <ng-container *ngTemplateOutlet="footer"></ng-container>
            </ng-container>
            <!-- / FOOTER: Below fixed -->

        </div>

        <!-- NAVBAR: Right -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
            <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Right -->

    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->

</div>

<!-- SIDE PANEL -->
<ng-container
    *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">
    <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
        [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer *ngIf="!fuseConfig.layout.footer.hidden"
        [ngClass]="fuseConfig.layout.footer.customBackgroundColor === true ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background : fuseConfig.layout.footer.position">
    </footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar"
        lockedOpen="gt-md"
        *ngIf="!fuseConfig.layout.toolbar.hidden && !toolbarsetting?.NAVBARHIDE">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
    <fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar"
        [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-md" *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant"
            class="right-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
