import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GenerateDocumentPopupComponent } from '@pages/template/generate-document-popup/generate-document-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';
// import { GenerateDocumentPopupComponent } from 'src/app/main/pages/template/generate-document-popup/generate-document-popup.component';


@Component({
  selector: 'app-generate-template',
  templateUrl: './generate-template.component.html',
  styleUrls: ['./generate-template.component.scss'],
  animations: fuseAnimations
})
export class GenerateTemplatesDialoagComponent implements OnInit, OnDestroy {
  @Input() SettingForm: FormGroup;
  displayedColumns: any = ['TEMPLATETYPE', 'TEMPLATENAME'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean;
  Templatedata: any = [];
  getTemplateArray: any = [];
  TemplateGenerateData: any = [];
  highlightedRows: any;
  getDropDownValue: any = [];
  getRoeData: any = [];
  pageSize: any;
  isDisplay:any = false
  templetFolderPath: any = '';
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  pushFolder: any = [];
  storeFolder_2: any;
  showFolder: any = "";
  SendgoBack: any = "";
  storeGoBack: any;
  previousFolder_2: any;
  constructor(private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService,
    public dialogRef: MatDialogRef<GenerateTemplatesDialoagComponent>, public _matDialog: MatDialog, private router: Router,
    public behaviorService: BehaviorService) {
    localStorage.setItem('istrackid', 'GenerateTemplatesDialoagComponent');
    this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'GenerateTemplatesDialoagComponent' && result['click'] && result['data']['TemplateList']) {
        this.LoadData({});
      }
    });
  }

  ngOnInit() {
    this.LoadData({});
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This finction is used to load the data value
   */
  LoadData(data) {
    this.isLoadingResults = true;
    this.sub1 = this._mainAPiServiceService.getSetData(data, 'TemplateList').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        if (this.pushFolder.length > 0) {
          response.DATA.TEMPLATES.unshift({
              TEMPLATENAME: `.. Go up one level`,
              TEMPLATETYPE: "Go_Back",
          });
      }
        this.Templatedata = new MatTableDataSource(response.DATA.TEMPLATES);
        this.Templatedata.paginator = this.paginator;
        if (response.DATA.TEMPLATES[0]) {
            this.isDisplay = false
          // localStorage.setItem('contactGuid', response.DATA.CONTACTS[0].CONTACTGUID);
          this.highlightedRows = response.DATA.TEMPLATES[0].TEMPLATENAME;
          this.FirstClickTemplate(response.DATA.TEMPLATES[0]);
        }else if(!response.DATA.TEMPLATES[0]){
            this.isDisplay = true
        }
        this.isLoadingResults = false;
      }else{
        
        this.isLoadingResults = false;
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }

  /**
   * This function is used to click on template data value
   */
  FirstClickTemplate(data) {
    data.FOLDERPATH = this.templetFolderPath;
    this.getRoeData = data;
  }

  /**
   * This function is used to onPagination change
   */
  onPaginateChange(event) {
    this.pageSize.generate_tempplate = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

  /**
   * this function is used to double click on the row
   */
  dblclickrow(data) {
    if (data.TEMPLATETYPE == "Folder") {
        // this.LoadData({ "Folder": data.TEMPLATENAME });
        // this.templetFolderPath = (this.templetFolderPath) ? this.templetFolderPath + data.TEMPLATENAME+"\\" : data.TEMPLATENAME+"\\";

        this.pushFolder.push(data.TEMPLATENAME);
        for (let index = 0; index <= this.pushFolder.length - 1; index++) {
            if (index == 0) {
                this.storeFolder_2 = this.pushFolder[index];
                this.previousFolder_2 = this.pushFolder[index];
            } else {
                this.storeFolder_2 =
                    this.previousFolder_2 + "/" + this.pushFolder[index];
                this.previousFolder_2 = this.storeFolder_2;
            }
            this.showFolder = this.previousFolder_2;
        }
        this.behaviorService.generateDocumentPath$.next(this.showFolder);
        setTimeout(() => {
            this.LoadData({ Folder: this.storeFolder_2 });
            localStorage.setItem("Folder", this.storeFolder_2);
            this.behaviorService.templateSubFolder(
                localStorage.getItem("Folder")
            );
            this.storeFolder_2 = "";
            this.previousFolder_2 = "";
        }, 500);

         this.templetFolderPath = (this.templetFolderPath) ? this.templetFolderPath + data.TEMPLATENAME+"\\" : data.TEMPLATENAME+"\\";
    }else if (data.TEMPLATETYPE == "Go_Back") {
      this.templetFolderPath='';
      this.pushFolder.pop(data.TEMPLATENAME);
      for (let index = 0; index <= this.pushFolder.length - 1; index++) {
          if (index == 0) {
              this.SendgoBack = this.pushFolder[index];
              this.storeGoBack = this.pushFolder[index];
          } else {
              this.SendgoBack =
                  this.storeGoBack + "/" + this.pushFolder[index];
              this.storeGoBack = this.pushFolder[index];
          }
      }
      this.showFolder = this.SendgoBack;
      this.behaviorService.generateDocumentPath$.next(this.showFolder);
      setTimeout(() => {
          this.LoadData({ Folder: this.SendgoBack });
          localStorage.setItem("Folder", this.SendgoBack);
          this.behaviorService.templateSubFolder(
              localStorage.getItem("Folder")
          );
          this.SendgoBack = "";
          this.storeGoBack = "";
      }, 500);
  }
     else if (data.TEMPLATETYPE == "Sub Folder") {
      this.templetFolderPath = (this.templetFolderPath) ? this.templetFolderPath + data.TEMPLATENAME+"\\" : data.TEMPLATENAME+'\\';
      this.LoadData({ "Sub Folder": data.TEMPLATENAME });
    }
    else {
      this.openDilog()
    }
  }

  /**
   * This function is used to open the dialog
   */
  openDilog() {
    this.sub2 = this.behaviorService.TemplateGenerateData$.subscribe(result => {
      if (result) {
        this.TemplateGenerateData = result;
      }
    });
    if (this.router.url == "/create-document/invoice-template") {
      let invoiceGUid = localStorage.getItem('edit_invoice_id');
      let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "Type": "Template", "Folder": '', "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/create-document/matter-template") {
      let matterData = JSON.parse(localStorage.getItem('set_active_matters'));
      let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "Type": "Template", "Folder": '', "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/create-document/receive-money-template") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "Type": "Template", "Folder": '', "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/create-document/contact-template") {
      let ContactGuID = localStorage.getItem('contactGuid');
      let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "Type": "Template", "Folder": '', "Template": this.TemplateGenerateData.TEMPLATENAME }
      this.ForDocDialogOpen(passdata);
    }
  }
  //***********************************************************END Select Matter Contact*************************************************************************
  ForDocDialogOpen(passdata) {
    const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: passdata });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // localStorage.setItem('set_active_matters', JSON.stringify(result));
      }
    });
  }

  /**
   * This function is used to select the templates
   */
  selectTemplate() {
    this.dialogRef.close(this.getRoeData);
  }
}
