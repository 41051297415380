import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '@_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    SessionToken: any;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser: any = this.authenticationService.currentUserValue;
        let SessionToken: any = localStorage.getItem('session_token');
        if (currentUser && SessionToken) {
            if (request.method.toLowerCase() === 'post') {
                if (typeof request.body == 'object') {
                    if (request.body.SessionToken == '') {
                        request.body.SessionToken = localStorage.getItem('session_token');
                    } else {
                        request.body.SessionToken = localStorage.getItem('session_token');
                    }
                    request.body.SessionToken = localStorage.getItem('session_token');
                    if (environment.APIEndpointDeliverable == request.url.split('matter-deliverable')[0]) {
                        delete request.body.apikey
                        delete request.body.formatting
                    } else {
                        request.body.apikey = environment.APIKEY;
                        request.body.formatting = 'JSON';
                    }
                    request.body;

                    if (typeof request.body == 'object' && currentUser && request.body.LogOutNowFromLoggendOnUser && request.body.request == "Logout") {
                        delete request.body.SessionToken
                        let LogOutNow = request.body.LogOutNowFromLoggendOnUser
                        delete request.body.LogOutNowFromLoggendOnUser
                        request.body.SessionToken = LogOutNow
                    }
                }
            } else if (request.method.toLowerCase() === 'get') {
                if (request && request.url && request.url.indexOf('version.json') !== -1) {
                } else if (request && request.url && request.url.indexOf("community.silq.com.au&hapikey") !== -1) {

                } else {
                    request = request.clone({
                        params: request.params.set('SessionToken', localStorage.getItem('session_token'))
                    });
                }
            }
        } else {
            if (request.method.toLowerCase() === 'post') {
                if (typeof request.body == 'object') {
                    if (request.headers && request.headers.get("skip")) {
                        request.body.apikey = environment.APIKEY;
                        request.body.formatting = 'JSON';
                        request.body;

                        request = request.clone({
                            headers: request.headers.delete("skip", 'true')
                        });
                    } else {
                        // request.body.SessionToken = "";
                        request.body.apikey = environment.APIKEY;
                        request.body.formatting = 'JSON';
                        request.body;
                    }
                    // request.body.SessionToken = "";
                    // request.body.apikey = environment.APIKEY;
                    // request.body.formatting = 'JSON';
                    // request.body;
                }
            } else if (request.method.toLowerCase() === 'get') {
                request = request.clone({
                    params: request.params.set('SessionToken', "")
                });
            }
        }
        return next.handle(request);
    }
}
