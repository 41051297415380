import { fuseAnimations } from "src/@fuse/animations";
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    Injectable,
    OnDestroy,
    AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import * as $ from "jquery";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from "@angular/material/tree";
import { TreeGridComponent } from "@syncfusion/ej2-angular-treegrid";
// importP{RowSelectEventArgs} '../../../../../node_modules/@syncfusion/ej2-angular-treegrid/ej2-angular-treegrid';
import { ElementRef } from "@angular/core";
// import { Grid, Toolbar, ExcelExport, ExcelExportProperties, Page } from '@syncfusion/ej2-grids';
import { ToFixedAmountPipe } from "../../../to-fixed-amount.pipe";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { BehaviorService } from "@_services/Behavior.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
interface FoodNode {
    name: string;
    ACCOUNTGUID: string;
    ACCOUNTTYPE: string;
    ACCOUNTCLASS: any;
    ACTIVE: any;
    ACCOUNTNAME: any;
    ACCOUNTNUMBER: any;
    SUBACCOUNTS: any;
    MainList: any;
    acc: string;
    parent: string;
    index?: number;

    children?: FoodNode[];
}
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}
@Injectable()
@Component({
    selector: "app-chart-account",
    templateUrl: "./chart-account.component.html",
    styleUrls: ["./chart-account.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [ToFixedAmountPipe],
})
export class ChartAccountComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("tree", { static: false }) tree;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild("charttreegrid", { static: false })
    public charttreegrid: TreeGridComponent;
    @ViewChild("charttreegrid", { static: false })
    public treeGridObj: TreeGridComponent;
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    is_account = this.theme_type == "theme-default" ? "is_purple" : "is_green";
    divStyle: any = 1500;
    isLoadingResults: boolean = false;
    isShowTable: boolean = false;
    storeDataarray: any = [];
    accGUID: any = [];
    pageSize: any;
    acc: any;
    ACCOUNTGUIDsELECTED: any;
    arrayForIndex: any = [];
    isTrustColumm: any;
    chartdataTreeTable: any = [];
    hasSearchVal: boolean = false;
    @ViewChild("searchField", { static: false })
    searchField: ElementRef<HTMLInputElement>;
    activeFilters: any
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    private _transformer = (node: FoodNode, level: number) => {
        return {
            expandable: !!node.SUBACCOUNTS && node.SUBACCOUNTS.length > 0,
            name:
                node.ACCOUNTCLASS +
                " - " +
                node.ACCOUNTNUMBER +
                "        " +
                node.ACCOUNTNAME,
            ACTIVE: node.ACTIVE == 1 ? "" : "(inactive)",
            class: node.ACCOUNTNAME,
            ACCOUNTGUID: node.ACCOUNTGUID,
            ACCOUNTTYPE: node.ACCOUNTTYPE,
            index: node.index,
            parent: node.parent,
            level: level,
            MainList: node.MainList,
        };
    };

    treeControl: FlatTreeControl<ExampleFlatNode>;
    treeFlattener: MatTreeFlattener<FoodNode, ExampleFlatNode>;
    dataSource: MatTreeFlatDataSource<FoodNode, ExampleFlatNode>;

    highlightedRows: number;
    public ChartData = { AccountClass: "All", Search: "" };
    filterData: { SearchString: string; AccountClass: any; UseTrust: any };
    accountTypeData: any;
    textWrap: string;
    showActive: boolean;
    currentUserData: any;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        public dialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private SortingbehaviorService: SortingBehaviourService,
        private behaviorService: BehaviorService,
        private elementRef: ElementRef,
        private TableColumnsService: TableColumnsService,
        private toFixedAmount: ToFixedAmountPipe,
        private router: Router,
    ) {
        this.currentUserData = JSON.parse(
            window.localStorage.getItem("currentUser")
        );
        localStorage.setItem("istrackid", "ChartAccountScreenComponent");
        this.treeControl = new FlatTreeControl<ExampleFlatNode>(
            (node) => node.level,
            (node) => node.expandable
        );
        this.treeFlattener = new MatTreeFlattener(
            this._transformer,
            (node) => node.level,
            (node) => node.expandable,
            (node) => node.SUBACCOUNTS
        );
        this.dataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );

        this.behaviorService.TrustDuplicateModuleHandling$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result != null) {
                this.accountTypeData = result;
            }
        });

        this.SortingbehaviorService.TrustChartAccFilterData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                localStorage.setItem(
                    "TrustchartAcc_filter",
                    JSON.stringify(result)
                );
            }
        });

        this.SortingbehaviorService.ChartAccFilterData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                localStorage.setItem("chartAcc_filter", JSON.stringify(result));
            }
        });
        // localStorage.removeItem('TrustchartAcc_filter');
        if (this.accountTypeData.ClickType == "WithTrust") {
            this.filterData = {
                SearchString: "",
                AccountClass: "All",
                UseTrust: true,
            };
            if (!localStorage.getItem("TrustchartAcc_filter")) {
                localStorage.setItem(
                    "TrustchartAcc_filter",
                    JSON.stringify(this.filterData)
                );
            } else {
                this.filterData = JSON.parse(
                    localStorage.getItem("TrustchartAcc_filter")
                );
                // let changeVal =JSON.parse(localStorage.getItem("TrustchartAcc_filter"))

                // if (this.filterData.UseTrust == 'Yes' || this.filterData.UseTrust == 'No') {
                //   if (this.filterData.UseTrust == 'Yes') {
                //     this.filterData.UseTrust = true;
                //   } else {
                //     this.filterData.UseTrust = false;
                //   }
                // }
                this.filterData = {
                    SearchString: this.filterData.SearchString,
                    AccountClass: this.filterData.AccountClass,
                    UseTrust: true,
                };
                localStorage.setItem(
                    "TrustchartAcc_filter",
                    JSON.stringify(this.filterData)
                );
            }

            this.ChartData.AccountClass = this.filterData.AccountClass;
            this.ChartData.Search = this.filterData.SearchString;
            this.hasSearchVal = this.filterData.SearchString ? true : false;
            //for toolbar value hideshow
            this.behaviorService.CommonToolbarHS({
                AccountClass: this.filterData.AccountClass,
                ClickType: this.accountTypeData.ClickType,
            });
            this.isTrustColumm = true;

            this.loadData(this.filterData); // true
        } else {
            if (!localStorage.getItem("chartAcc_filter")) {
                this.filterData = {
                    SearchString: "",
                    AccountClass: "All",
                    UseTrust: false,
                };
                localStorage.setItem(
                    "chartAcc_filter",
                    JSON.stringify(this.filterData)
                );
            } else {
                this.filterData = JSON.parse(
                    localStorage.getItem("chartAcc_filter")
                );
                // if (filterData.Search) {
                //   this.filterData = { 'SearchString': '', "AccountClass": this.filterData.AccountClass, 'UseTrust': this.filterData.UseTrust }
                //   localStorage.setItem('chartAcc_filter', JSON.stringify(this.filterData));
                //   this.filterData = JSON.parse(localStorage.getItem("chartAcc_filter"));
                // }
                // if (!filterData.UseTrust) {
                //   this.filterData = { 'SearchString': '', "AccountClass": this.filterData.AccountClass, 'UseTrust': this.filterData.UseTrust }
                //   localStorage.setItem('chartAcc_filter', JSON.stringify(this.filterData));
                //   this.filterData = JSON.parse(localStorage.getItem("chartAcc_filter"));
                // }
            }
            this.ChartData.AccountClass = this.filterData.AccountClass;
            this.ChartData.Search = this.filterData.SearchString;
            this.hasSearchVal = this.filterData.SearchString ? true : false;
            //for toolbar value hideshow
            this.behaviorService.CommonToolbarHS({
                AccountClass: this.filterData.AccountClass,
                ClickType: this.accountTypeData.ClickType,
            });
            this.isTrustColumm = false;
            this.loadData(this.filterData); // falses
        }

        this.behaviorService.APIretryCall$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (
                localStorage.getItem("istrackid") ==
                "ChartAccountScreenComponent" &&
                result["click"] &&
                result["data"]["GetAccount"]
            ) {
                this.loadData(this.filterData);
            }
        });
    }

    ListofWithoutTrust = [
        {
            data: "All Accounts",
            id: "All",
        },
        {
            data: "Assets",
            id: "Assets",
        },
        {
            data: "Liabilities",
            id: "Liabilities",
        },
        {
            data: "Equity",
            id: "Equity",
        },
        {
            data: "Income",
            id: "Income",
        },
        {
            data: "Expenses",
            id: "Expenses",
        },
    ];

    ListofWithTrust = [
        {
            data: "All Accounts",
            id: "All",
        },
        {
            data: "Trust Account",
            id: "Trust Account",
        },
        {
            data: "Matter Ledger",
            id: "Matter Ledger",
        },
        {
            data: "Unknown Deposit",
            id: "Unknown Deposit",
        },
        {
            data: "Controlled Money Ledger",
            id: "Controlled Money Ledger",
        },
        {
            data: "Controlled Money Account",
            id: "Controlled Money Account",
        },
    ];

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        let Flag = JSON.parse(localStorage.getItem("Toggle_Flag"));
        this.showActive = Flag;
        $("content").addClass("inner-scroll");
        $(".example-containerdata").css(
            "height",
            $(window).height() - ($("#tool_baar_main").height() + 120) + "px"
        ); //before 140
        // $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 60)) + 'px');
        this.acc = "";

        $(window).resize(() => {
            this.resizeTableForAllView();
        });
    }

    /**
     * This function is used to prevent the default.
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * This function is used to set the custom formator data value
     * @param field -field data value
     * @param data -data value
     * @param column -column data value
     * @returns -data
     */
    setCustomFormater(field: string, data: any, column: Object): string {
        if (field === "ACCOUNTNAME") {
            // return (
            //     data.ACCOUNTCLASS +
            //     ` - ` +
            //     data.ACCOUNTNUMBER +
            //     "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
            //     data.ACCOUNTNAME +
            //     `\xa0` +
            //     (data.ACTIVE == 1 ? "" : "(inactive)")
            // );

            return (
                data.ACCOUNTCLASS +
                ` - ` +
                data.ACCOUNTNUMBER +
                ` ` +
                data.ACCOUNTNAME +
                ` ` +
                (data.ACTIVE == 1 ? "" : "(inactive)")
            );
        } else if (field === "ACCOUNTBALANCE") {
            return ToFixedAmountPipe.prototype.transform(
                data.ACCOUNTBALANCE,
                true
            );
        } else if (field === "XEROACCOUNTCODE") {
            return data.EXPORTINFO.XEROACCOUNTCODE
                ? data.EXPORTINFO.XEROACCOUNTCODE
                : "";
        } else if (field === "MYOBEXPORTACCOUNT") {
            return data.EXPORTINFO.MYOBEXPORTACCOUNT
                ? data.EXPORTINFO.MYOBEXPORTACCOUNT
                : "";
        }
    }

    
    dataBound() {
        var instance = (document.getElementsByClassName('e-treegrid') as any)[0].ej2_instances[0]
        instance.grid.enableStickyHeader = true;
    }

    /**
     * Table resize data value
     */
    resizeTableForAllView() {
        $("content").addClass("inner-scroll");
        $(".example-containerdata").css(
            "height",
            $(window).height() - ($("#tool_baar_main").height() + 120) + "px"
        ); //before 140
        // $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 60)) + 'px');
        this.acc = "";
    }

    /**
     * This function is used to load the data 
     * @param data -data value
     */
    loadData(data: any) {
        if (this.accountTypeData.ClickType == "WithTrust" || this.router.url=='/trust-chart-account') {
            data.UseTrust=true
            this.SortingbehaviorService.SetTrustChartAccFilterData(data);
        } else {
            this.SortingbehaviorService.SetChartAccFilterData(data);
        }
        this.isLoadingResults = true;
        this.isShowTable = false;

        this._mainAPiServiceService
            .getSetData(data, "GetAccount")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    this.chartdataTreeTable = response.DATA.ACCOUNTS;
                    this.isShowTable = true;
                    this.behaviorService.setChartAccountDataEdit(
                        response.DATA.ACCOUNTS[0]
                    );
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.isLoadingResults = false;
                        // this.behaviorService.setChartAccountDataEdit(null);
                        this.arrayForIndex = [];
                        if (response.DATA.ACCOUNTS[0].ACCOUNTGUID == "") {
                            // this.storeDataarray = response.DATA.ACCOUNTS[0].SUBACCOUNTS;
                            // this.ACCOUNTGUIDsELECTED = response.DATA.ACCOUNTS[0].SUBACCOUNTS;
                        } else {
                            // this.storeDataarray = response.DATA.ACCOUNTS;
                        }

                        // this.showData(this.storeDataarray, 0, null);
                        // this.dataSource.data = this.storeDataarray;
                        // this.treeControl.expandAll();
                        if (response.DATA.ACCOUNTS[0]) {
                        }
                        // this.RowClick(response.DATA.ACCOUNTS[0]);
                        // this.highlightedRows = 1;
                    } else if (response.MESSAGE == "Not logged in") {
                        // this.dialogRef.close(false);
                    }
                    this.isLoadingResults = false;
                },
                (err) => {
                    // this.toastr.error(err);
                    this.isLoadingResults = false;
                }
            );
    }

    /**
     * This function is used to Show the data value
     * @param element -element data value
     * @param level -level data
     * @param parent -parent data value
     */
    showData(element, level, parent) {
        element.forEach((x) => {
            this.arrayForIndex.push({});
            x.level = level;
            x.parent = this.acc;
            x.MainList = x;
            if (level == 0) {
                this.acc = x.ACCOUNTNAME;
                x.parent = null;
            }
            x.index = this.arrayForIndex.length;
            if (x.SUBACCOUNTS) {
                this.showData(x.SUBACCOUNTS, x.level + 1, x.ACCOUNTNAME);
            }
        });
    }

    /**
     * Chek it's child or not 
     */
    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    /**
     * This function is used to Select the row data.
     */
    rowSelected() {
        const selectedrecords = this.charttreegrid.getSelectedRecords(); // Get the selected records.
        let val: any = selectedrecords[0];
        let nameSend =
            val.ACCOUNTCLASS +
            " - " +
            val.ACCOUNTNUMBER +
            "        " +
            val.ACCOUNTNAME;
        this.behaviorService.ChartAccountData(val);
        this.behaviorService.setChartAccountDataEdit(val);
        this.behaviorService.RecouncileConstName(nameSend);
        localStorage.setItem(
            "ChartAccountData",
            JSON.stringify({
                name: nameSend,
                class: val.ACCOUNTNAME,
                ACCOUNTGUID: val.ACCOUNTGUID,
                ACCOUNTTYPE: val.ACCOUNTTYPE,
                index: val.index,
                parent: val.parentItem,
                level: val.level,
            })
        );
    }

    /**
     * This functionnis used to get the AccountClass Data
     * @param val 
     */
    AccountClass(val) {
        this.behaviorService.CommonToolbarHS({
            AccountClass: val,
            ClickType: this.accountTypeData.ClickType,
        });
        if (this.accountTypeData.ClickType == "WithTrust") {
            this.filterData = JSON.parse(
                localStorage.getItem("TrustchartAcc_filter")
            );
            this.filterData.AccountClass = val;
            localStorage.setItem(
                "TrustchartAcc_filter",
                JSON.stringify(this.filterData)
            );
            this.loadData(this.filterData);
        } else {
            this.filterData = JSON.parse(
                localStorage.getItem("chartAcc_filter")
            );
            this.filterData.AccountClass = val;
            localStorage.setItem(
                "chartAcc_filter",
                JSON.stringify(this.filterData)
            );
            this.loadData(this.filterData);
        }
    }

    /**
     * This function is used to Onsearch the data value
     * @param searchFilter 
     */
    onSearch(searchFilter: any) {
        if (
            searchFilter["key"] === "Enter" ||
            searchFilter == "Enter" ||
            searchFilter == "removeSearchString"
        ) {
            if (searchFilter == "removeSearchString") {
                this.ChartData.Search = "";
            }
            let filterVal = {
                SearchString: this.ChartData.Search,
                AccountClass: this.ChartData.AccountClass,
                UseTrust: this.filterData.UseTrust,
            };
            if (this.accountTypeData.ClickType == "WithTrust") {
                if (!localStorage.getItem("TrustchartAcc_filter")) {
                    localStorage.setItem(
                        "TrustchartAcc_filter",
                        JSON.stringify(filterVal)
                    );
                } else {
                    filterVal = JSON.parse(
                        localStorage.getItem("TrustchartAcc_filter")
                    );
                    filterVal.SearchString = this.ChartData.Search;
                    localStorage.setItem(
                        "TrustchartAcc_filter",
                        JSON.stringify(filterVal)
                    );
                }
                this.hasSearchVal = filterVal.SearchString ? true : false;
                this.loadData(filterVal);
            } else {
                if (!localStorage.getItem("chartAcc_filter")) {
                    localStorage.setItem(
                        "chartAcc_filter",
                        JSON.stringify(filterVal)
                    );
                } else {
                    filterVal = JSON.parse(
                        localStorage.getItem("chartAcc_filter")
                    );
                    filterVal.SearchString = this.ChartData.Search;
                    localStorage.setItem(
                        "chartAcc_filter",
                        JSON.stringify(filterVal)
                    );
                }
                this.hasSearchVal = filterVal.SearchString ? true : false;
                this.loadData(filterVal);
            }
        }
    }

    /**
     * This function is used to refresh the ChartACCTab
     */
    refreshChartACCTab() {
        let filterData = JSON.parse(localStorage.getItem("chartAcc_filter"));
        // filterData.SearchString = ''
        if (!filterData) {
            filterData = JSON.parse(
                localStorage.getItem("TrustchartAcc_filter")
            );
            // filterData.SearchString = ''
        }
        this.loadData(filterData);
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        $("div.e-content table").delegate("tr", "click", function () {
            alert("You clicked my <tr>!");
            //get <td> element values here!!??
        });
    }
  
    /**
     * this function is used to On destroy the data vaue
     */
    ngOnDestroy(): void {
        if (this.accountTypeData.ClickType == "WithTrust") {
            if (localStorage.getItem("TrustchartAcc_filter")) {
                this.filterData = JSON.parse(
                    localStorage.getItem("TrustchartAcc_filter")
                );
                this.filterData.SearchString = "";
                localStorage.setItem(
                    "TrustchartAcc_filter",
                    JSON.stringify(this.filterData)
                );
            }
        } else {
            if (localStorage.getItem("chartAcc_filter")) {
                this.filterData = JSON.parse(
                    localStorage.getItem("chartAcc_filter")
                );
                this.filterData.SearchString = "";
                localStorage.setItem(
                    "chartAcc_filter",
                    JSON.stringify(this.filterData)
                );
            }
        }
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function ias used to downloadfile data 
     */
    downloadFile() {
        //   let exportProperties: ExcelExportProperties = {
        //     includeHiddenColumn: true
        // };
        this.treeGridObj.excelExport();
    }

    /**
     * This function is used to onCheck data
     * @param showActive 
     */
    onChecked(showActive: any) {
        localStorage.setItem("Toggle_Flag", JSON.stringify(showActive));
        let filterVal = {
            SearchString: this.ChartData.Search,
            AccountClass: this.ChartData.AccountClass,
            UseTrust: this.filterData.UseTrust,
            SHOWINACTIVE: showActive,
        };
        this.loadData(filterVal);
    }

    /**
     * this function is used to set the focus data
     * @param fieldRefVar 
     */
    setFocus(fieldRefVar) {
        this.searchField.nativeElement.focus();
    }

    /**
     * This function is used to clear the filter
     */
    clearFilter(name, filterBy) {

    }
}
