import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ResizeEvent } from 'angular-resizable-element';
import { fuseAnimations } from 'src/@fuse/animations';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-email-popup',
  templateUrl: './select-email-popup.component.html',
  styleUrls: ['./select-email-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SelectEmailPopupComponent implements OnInit, OnDestroy {
  TemplateEmaildata: any = [];
  isLoadingResults: boolean = false;
  theme_type = localStorage.getItem('theme_type');
  selectedColore: string = 'rgb(217, 217, 217)';
  Title = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  displayedColumns: any = ['TYPEICON', 'NAME'];
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  highlightedRows: any;
  pageSize: any;


  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    private behaviorService: BehaviorService,
    private SortingbehaviorService: SortingBehaviourService
  ) {
    localStorage.setItem('istrackid', 'SelectEmailPopupComponent');
    this.sub = this.SortingbehaviorService.EmailSorting$.subscribe((result) => {
      if (result) {
        this.sortingDefaultState = result;
        localStorage.setItem('email_screen_sorting', JSON.stringify(result));
      } else {
        this.sortingDefaultState = JSON.parse(localStorage.getItem('email_screen_sorting'))
      }
    });
    this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'SelectEmailPopupComponent' && result['click'] && result['data']['template-email']) {
        this.LoadData({ACTION: "GetData",FILTERS:{}});
      }
    });
  }

 /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if (this.sortingDefaultState) {
      this.sortactive = this.sortingDefaultState.active;
      this.sortDirection = this.sortingDefaultState.direction;
    } else {
      this.sortactive = "";
      this.sortDirection = "";
    }
    this.LoadData({ACTION: "GetData",FILTERS:{}});
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

/**
 * this function is used to load the data value
 */
  LoadData(passdata) {
    this.isLoadingResults = true;
    this.sub2 = this._mainAPiServiceService.getSetData(passdata, 'template-email').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.TemplateEmaildata = new MatTableDataSource(response.DATA.RECORDS);
        this.TemplateEmaildata.paginator = this.paginator;
        this.TemplateEmaildata.sort = this.sort;
        if (response.DATA.RECORDS[0]) {
          this.clicktitle(response.DATA.RECORDS[0]);
          this.highlightedRows = response.DATA.RECORDS[0].EMAILGUID;
        }
        this.isLoadingResults = false;
      }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }

  /**
   * This function is used to sort the data value
   */
  sortData(val) {
    this.SortingbehaviorService.EmailSorting(val);
  }
  
  /**
   * This function is used to click title
   */
  clicktitle(row) {
    this.behaviorService.EmailGenerateData(row);
  }

  /**
   * This function is used to change the pagination 
   */
  onPaginateChange(event) {
    this.pageSize.email_templete = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

  /**
   * This function is used resize the end
   */
  onResizeEnd(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
    }
  }
}
