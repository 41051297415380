import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimal'
})
export class CustomDecimalPipe implements PipeTransform {

  /**
     * This function is used to transform the data.
     */
  transform(value: number | null | undefined, digitsInfo: string = '1.2-2'): string {
    // Check if the value is null or undefined
    if (value == null) {
      return '';
    }

     // Check if the value is zero
     if (value === 0) {
      return '0.00';
    }

    // Use the built-in DecimalPipe to format the value
    const formattedValue = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
    return formattedValue;
  }

}
