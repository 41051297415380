import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from 'src/@fuse/animations';
// import { DiaryService } from './diary.service';
// import { DiaryEventModel } from './event.model';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { DairyDailogComponent } from './dairy-dailog/dairy-dailog.component';
import { ToastrService } from 'ngx-toastr';
import { CellClickEventArgs, EventRenderedArgs, EventSettingsModel, GroupModel, PopupOpenEventArgs, ScheduleComponent, TimeScaleModel, View } from '@syncfusion/ej2-angular-schedule';
import { DatePipe } from '@angular/common';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';


@Component({
    selector: 'app-diary',
    templateUrl: './diary.component.html',
    styleUrls: ['./diary.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DiaryComponent implements OnInit, OnDestroy {
    private _unsubscribeAll$: Subject<void> = new Subject();
    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (targetElement.innerText == 'TODAY') {
            let date = new Date();
            let ScheduleDateRange = this.scheduleObj.getCurrentViewDates();
            this.behaviorService.UseCalanderViewType$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                if (result != null) {
                    this.CurrentDate = result
                } else {
                    this.CurrentDate = new Date();
                }
                // this.getEvents();
            });
            this.setedDiaryViewtype = 'Day';
            this.currentView = 'Day';
            //this.behaviorService.setCalanderViewType('Day');
            // this.behaviorService.UseCalanderViewType(this.CurrentDate);
        }
    }
    public selectedDate: Date = new Date();
    public group: GroupModel = {
        resources: ['Owners']
    };
    public allowMultipleOwner: Boolean = true;
    ownerDataSource: any;
    SendParam: { USERGUID: any; DATESTART: any; DATEEND: any; };
    CurrentDate: any = new Date();
    public eventSettings: EventSettingsModel;
    public eventSettings2: EventSettingsModel;
    allowMultipleCategory: any;
    tempEvent: any[] = [];
    @ViewChild('scheduleObj', { static: false }) public scheduleObj: ScheduleComponent;
    public TimeScale: TimeScaleModel;
    public currentView: View;
    public CombinedcurrentView: View;
    public rowAutoHeight: boolean = false;
    theme_type = localStorage.getItem('theme_type');
    selectedColore = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    dialogRef: MatDialogRef<DairyDailogComponent, any>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent, any>;
    DairyData: any;
    firstDay: any = new Date(this.CurrentDate.getFullYear(), this.CurrentDate.getMonth() - 1, 1);
    lastDay: any = new Date(this.CurrentDate.getFullYear(), this.CurrentDate.getMonth() + 2, 0);
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    setedDiaryViewtype: any = '';
    newday: any;
    isDiaryData: string
    constructor(
        // private _calendarService: DiaryService,
        private route: ActivatedRoute,
        private _httpClient: HttpClient,
        private behaviorService: BehaviorService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        private Timersservice: TimersService,
        public datepipe: DatePipe,
        private el: ElementRef,
        private renderer: Renderer2

    ) {
        this.behaviorService.calanderViewType$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                let combinedData: any = [];
                this.setedDiaryViewtype = result;
                if (result == "Combined") {
                    this.CombinedcurrentView = 'Month';
                    this.tempEvent.forEach(element => {
                        let Data: any = element;
                        Data.Color = '';
                        combinedData.push(Data);
                    });
                    this.eventSettings = {
                        dataSource: combinedData,
                    };
                } else {
                    this.currentView = result;
                    this.GetAppoiment({
                        DATESTART: this.datepipe.transform(new Date(this.firstDay), 'dd/MM/yyyy'),
                        DATEEND: this.datepipe.transform(new Date(this.lastDay), 'dd/MM/yyyy')
                    });
                }

                // call custom function to select first day of week or month
                if (this.scheduleObj) {
                    setTimeout(() => {
                        this.setCustomDate();
                    }, 100);
                }

            }
        });
        this.behaviorService.TimeScale$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.TimeScale = { enable: true, interval: 60, slotCount: result };
            }
        });
        this.behaviorService.forDiaryRefersh2$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.GetAppoiment({
                    DATESTART: this.datepipe.transform(new Date(this.firstDay), 'dd/MM/yyyy'),
                    DATEEND: this.datepipe.transform(new Date(this.lastDay), 'dd/MM/yyyy')
                });
                this.behaviorService.forDiaryRefersh2(null);
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to get the appointment data
     */
    async GetAppoiment(SendParam) {
        this.tempEvent = [];

        let selectedUser = JSON.parse(localStorage.getItem("SelectedDiaryUsers"));
        if (selectedUser && selectedUser.length) {
            this.ownerDataSource = selectedUser;
        } else {
            this.ownerDataSource = [{ FULLNAME: this.currentUser.UserName, USERGUID: this.currentUser.UserGuid, OwnerColor: '#43A047' }];
        }
        this.getonebyone(SendParam, this.ownerDataSource);
    }

    /**
     * this function is used to get the one by one data value.
     */
    async getonebyone(SendParam, users) {
        for (let i = 0; i < users.length; i++) {
            await new Promise<void>(async (next, reject) => {
                SendParam.USERGUID = users[i].USERGUID;
                this._mainAPiServiceService.getSetData(SendParam, 'GetAppointment').subscribe(res => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        if (res.DATA.APPOINTMENTS[0] == '' || res.DATA.APPOINTMENTS == '') {
                            this.behaviorService.DiaryData(null);
                        } else {
                            this.behaviorService.DiaryData(res.DATA.APPOINTMENTS);
                        }
                        if ($.isEmptyObject(res.DATA) == true) {
                            this.tempEvent.push({});
                        } else {
                            // this.behaviorService.DiaryData(res.DATA.APPOINTMENTS[0]);

                            res.DATA.APPOINTMENTS.forEach(itemsdata => {
                                this.tempEvent.push(
                                    {
                                        StartTime: this.dateformat(this.changeformat(itemsdata.APPOINTMENTDATE) + ' ' + this.convertTime12to24(itemsdata.APPOINTMENTTIME)),
                                        EndTime: this.dateformat(this.changeformat(itemsdata.APPOINTMENTDATE) + ' ' + this.convertTime12to24(itemsdata.APPOINTMENTENDTIME)),
                                        Subject: '(' + this.tConvert(itemsdata.APPOINTMENTTIME) + ') -' + itemsdata.SUBJECT,
                                        IsAllDay: false,
                                        DairyRowClickData: itemsdata.APPOINTMENTGUID,
                                        Id: itemsdata.APPOINTMENTGUID,
                                        OwnerId: users[i].USERGUID,
                                        USERGUID: users[i].USERGUID,
                                        FULLNAME: users[i].FULLNAME,
                                        Color: (this.setedDiaryViewtype != "Combined") ? `#${itemsdata.CATEGORYCOLOUR}` : ''
                                    }
                                );
                                localStorage.setItem('DairyAppointmentGUID', JSON.stringify(this.tempEvent));
                            });
                        }
                        this.eventSettings = {
                            dataSource: this.tempEvent,
                        };
                        this.scheduleObj.refreshEvents();
                        next();
                    }
                },
                    err => {
                        this.toastr.error(err);
                    }, reject);
            });
        }
    }

    /**
     * This function is used to apply the category color
     */
    applyCategoryColor(event: EventRenderedArgs) {
        const element = event;
        let categoryColor: string = element.data.Color as string;
        if (!element.element || !categoryColor) {
            return;
        }
        if (this.scheduleObj.currentView === 'Agenda') {
            (element.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
        } else {
            element.element.style.backgroundColor = categoryColor;
        }
    }

    /**
     * This function is used to convert the time
     */
    tConvert(timeString) {
        let hourEnd: any = timeString.indexOf(":");
        let H: any = +timeString.substr(0, hourEnd);
        // let h: any = H % 12 || 12;
        // let ampm: any = H < 12 ? "AM" : "PM"; //no need to check
        return H + timeString.substr(hourEnd, 3) + ' ' + timeString.substr(timeString.length - 2, timeString.length);
    };

    /**
     * This function is used to change the format
     */
    changeformat(dates) {
        var d = dates;
        var datess = d.split("/").reverse().join("/");
        var year = datess.substring(0, 4);
        var month = datess.substring(5, 7)
        var day = datess.substring(8, 10)
        return month + '/' + day + '/' + year;
    }
    //Iso Type Convert
    dateformat(d) {
        let date = new Date(d);
        return date.toISOString();
    }

    /**
     * This function is used to convert the time 12 to 24 format
     */
    convertTime12to24(time) {
        var hours = parseInt(time.substr(0, 2));
        if ((time.indexOf('am') != -1 || time.indexOf('AM') != -1) && hours == 12) {
            time = time.replace('12', '0');
        }
        if ((time.indexOf('pm') != -1 || time.indexOf('PM') != -1) && hours < 12) {
            time = time.replace(hours, (hours + 12));
        }
        return time.replace(/(am|pm|AM|PM)/, '');
    }

    /**
     * This function is used to open the popup 
     */
    onPopupOpen(args: PopupOpenEventArgs): void {
        if (args.type === 'Editor') {
            args.data['type'] = "ScheduleEditor"; this.editEvent('edit', args.data, '');
            args.cancel = true;
        }
        if (args.type === 'DeleteAlert') {
            this.deleteEvent(args.data);
            args.cancel = true;
        }
        if (args.type === 'QuickInfo') {
            args.data && args.data.DairyRowClickData ? this.behaviorService.forDiaryRefersh(args.data) : this.behaviorService.forDiaryRefersh(null);
            args.cancel = true;
        }
        if (args.type == 'EventContainer') {
            this.behaviorService.forDiaryRefersh(args.data.event[0]);
            // args.cancel = true;

        }
    }

    /**
     * This function is used to click on the cell
     */
    onCellClick(args: CellClickEventArgs) {
        // this.behaviorService.forDiaryRefersh(args);

        // setTimeout(()=>{
        //     this.behaviorService.forDiaryRefersh({
        //         "action": "new",
        //         "groupIndex":0,
        //         "startTime":args.startTime
        //     });

        // },100)
        // this.behaviorService.forDiaryRefersh({
        //     "action": "new",
        //     "groupIndex":0,
        //     "startTime":args.startTime
        // });
        let firstDateString = args.startTime.toString();
        firstDateString = firstDateString.replace("00:00:00", "09:00:00");
        const modifiedDate = new Date(firstDateString);

        setTimeout(() => {
            this.behaviorService.forDiaryRefersh({
                "action": "new",
                "groupIndex": 0,
                "startTime": modifiedDate,
                "userIndex": args.groupIndex
            });

        }, 100)
    }

    /**
     * This function is used to oncellDoubleClick
     */
    onCellDoubleClick(args: CellClickEventArgs) {
        this.newday = args
        this.editEvent('new', args, '');
        args.cancel = true;
    }


    /**
     * This function is used to edit the event
     */
    editEvent(action: string, event, clickDay): void {
        let DiaryPopupData = {};
        if (clickDay == 'click') {
            this.behaviorService.forDiaryRefersh(event);
        } else {
            if (action == 'edit') {
                DiaryPopupData = {
                    event: event,
                    AppoitmentGuId: event,
                    action: action
                }
            } else if (action == 'new' && (this.currentView == "Month" || this.currentView == "Week" || this.currentView == "Day") && this.setedDiaryViewtype != "Combined") {
                if (this.currentView == "Day") {
                    DiaryPopupData = {
                        event: this.newday,
                        action: action
                    }
                } else {
                    DiaryPopupData = {
                        event: event,
                        action: action
                    }
                }
            }
            if (action == 'edit' || ((this.currentView == "Month" || this.currentView == "Week" || this.currentView == "Day") && this.setedDiaryViewtype != "Combined")) {
                this.selectedColore = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
                this.behaviorService.forDiaryRefersh(event);
                this.dialogRef = this._matDialog.open(DairyDailogComponent, {
                    panelClass: 'event-form-dialog',
                    data: DiaryPopupData
                });
                this.dialogRef.afterClosed()
                    .subscribe(response => {
                    });
            }
        }
    }

    /**
     * This function is used to delete the event
     */
    deleteEvent(event): void {
        this.behaviorService.forDiaryRefersh(event);
        this.behaviorService.forDiaryRefersh$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            this.DairyData = result;

        });
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: true,
            width: '100%',
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                // this.DairyData.DairyRowClickData
                let postData = { FormAction: "delete", data: { APPOINTMENTGUID: this.DairyData.DairyRowClickData } }
                this._mainAPiServiceService.getSetData(postData, 'SetAppointment').subscribe(res => {
                    if (res.STATUS == "success") {
                        this.behaviorService.forDiaryRefersh2("call");
                        // $('#refreshLegalChronology').click();
                        this.toastr.success(res.STATUS);
                        // this.refresh.next(true);
                    } else {
                        // this.toastr.error("You Can't Delete Contact Which One Is To Related to Matters");
                    }
                });;
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to onAction complete
     */
    onActionComplete(args: any): void {
        if (args.requestType === 'dateNavigate' || args.requestType === 'viewNavigate') {
            this.setCustomDate();
            let ScheduleDateRange = this.scheduleObj.getCurrentViewDates();
            this.firstDay = ScheduleDateRange[0];
            this.lastDay = ScheduleDateRange[ScheduleDateRange.length - 1];
            this.GetAppoiment({
                DATESTART: this.datepipe.transform(new Date(this.firstDay), 'dd/MM/yyyy'),
                DATEEND: this.datepipe.transform(new Date(this.lastDay), 'dd/MM/yyyy')
            });

            this.setedDiaryViewtype = this.scheduleObj.currentView;
            this.currentView = this.scheduleObj.currentView;
            //this.behaviorService.setCalanderViewType(this.scheduleObj.currentView);
        }
    }

    /**
     * Set first day of week or month
     */
    setCustomDate(): void {
        const allDates = this.scheduleObj.getCurrentViewDates()
        let firstDate;

        if (allDates.length > 20) {
            for (let a of allDates) {
                if (a.getDate() == 1) {
                    firstDate = a;
                    break;
                }
            }
        } else {
            firstDate = allDates[0];
        }

        let firstDateString = firstDate.toString();
        firstDateString = firstDateString.replace("00:00:00", "09:00:00");
        const modifiedDate = new Date(firstDateString);
        this.behaviorService.forDiaryRefersh({
            "action": "new",
            "groupIndex": 0,
            "startTime": modifiedDate
        });
    }
}
