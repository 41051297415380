import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { BankingDialogComponent } from '../../banking/banking-dialog.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ContactSelectDialogComponent } from '../../contact/contact-select-dialog/contact-select-dialog.component';
import { round } from 'lodash';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
  selector: 'app-general-receipt-dilog',
  templateUrl: './general-receipt-dilog.component.html',
  styleUrls: ['./general-receipt-dilog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GeneralReceiptDilogComponent implements OnInit, OnDestroy {
  filteredOptions: Observable<string[]>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  AMOUNT: any;
  GST: any;
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  action: any;
  flaxValue: any = 20;
  ReceiptData: any;
  storeDataarray: any;
  ChartHandlingData: { ClickType: string; UseTrust: string; PopUp: string; Lable: string; };
  generalReceiptForm: FormGroup;
  isspiner: boolean;
  isLoadingResults: boolean;
  getPayourarray: any = [];
  classText: any = 'Income A/C';
  classType: any = 'INCOME';
  isGstReadonly: boolean = true;
  gsttypeData: any = [{ id: 1, text: "10% GST" }, { id: 2, text: "No GST" }, { id: 3, text: "< 10% GST" }];
  isHide: boolean = false;
  toolTipList: any;
  result: any;
  searchMatter: any;
  exportdatavalue: any;
  searchData: any;
  searchContactData;
  matterlist: Observable<string[]>;
  Contactlist: any=[];
  currentEvent: any;
  private searchTerms = new Subject<string>();
  private subscription: Subscription;
  mattername: any = [];
  Contactname:any=[];
  selectedData: any = [];
  selectedContctData: any = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
  @ViewChild('MatterInput2', { static: false }) MatterInputContact: ElementRef<HTMLInputElement>;
    matterdata: any;
    searchContact: any;
    exportdatavalueOFContact: any;
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
    testBool: boolean=false;
  CallHostlistner: boolean=false;
  DestryedContactdata: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub: Subscription;
  constructor(
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    public behaviorService: BehaviorService,
    public dialogRef: MatDialogRef<GeneralReceiptDilogComponent>,
    public datepipe: DatePipe,
    public MatDialog: MatDialog,
    public _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public tooltipService:TooltipService,public toolbarServiceService:ToolbarServiceService
  ) {
    this.behaviorService.ReceiptData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      this.ReceiptData = result;
    });
    localStorage.setItem('istrackid', 'GeneralReceiptDilogComponent');
    this.action = _data.type;
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'GeneralReceiptDilogComponent' && result['click'] && (result['data']['GetAccount'] || result['data']['contact'])) {
        this.ngOnInit();
      }

      if (localStorage.getItem('istrackid') == 'GeneralReceiptDilogComponent' && result['click'] && result['data']['SetIncome']) {
        this.SaveGeneralReceipt();
      }
    });

  }

  ngOnInit() {
    // this.isLoadingResults = true;
    this.generalReceiptForm = this._formBuilder.group({
      INCOMEGUID: [''],
      INCOMECLASS: [''],
      INCOMEDATETEXT: [''],
      INCOMEDATE: [''],
      INCOMETYPE: [''],
      PAYEE: [''],
      PAYEEGUID: [''],
      AMOUNT: ['',{updateOn: 'blur'}],
      gsttype: [''],
      GST: ['',{updateOn: 'blur'}],
      BANKACCOUNTGUID: [''],
      BANKACCOUNTGUIDTEXT: [''],
      INCOMEACCOUNTGUID: [''],
      INCOMEACCOUNTGUIDTEXT: [''],
      NOTE: [''],
    });
    if (this.action == "duplicate" || this.action == 'edit') {
      this.LoadData();
    } else {
      this.isLoadingResults = true;
      this._mainAPiServiceService.getSetData({ FormAction: 'default', VALIDATEONLY: false, Data: {} }, 'SetIncome').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          this.toolTipList = response.DATA.FIELDTIPS;
          this.generalReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTNUMBER);
          this.generalReceiptForm.controls['BANKACCOUNTGUID'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTGUID);
          let DatePaid = response.DATA.DEFAULTVALUES.INCOMEDATE.split("/");
          let DATE = new Date(DatePaid[1] + '/' + DatePaid[0] + '/' + DatePaid[2]);
          this.generalReceiptForm.controls['INCOMEDATE'].setValue(response.DATA.DEFAULTVALUES.INCOMEDATE);
          this.generalReceiptForm.controls['INCOMEDATETEXT'].setValue(DATE);
          this.generalReceiptForm.controls['INCOMECLASS'].setValue(response.DATA.DEFAULTVALUES.INCOMECLASS);
          this.generalReceiptForm.controls['INCOMETYPE'].setValue(response.DATA.DEFAULTVALUES.INCOMETYPE);
          this.generalReceiptForm.controls['gsttype'].setValue(1);
          this.classChange(response.DATA.DEFAULTVALUES.INCOMECLASS);
          this.CheckdefultValidation(response.DATA.VALIDATIONS);
          this.isLoadingResults = false;
        }
      }, error => {
        this.isLoadingResults = false;
        this.toastr.error(error);
      });
    }
    // this.getPayor({});
    this.sub = this.generalReceiptForm.valueChanges.subscribe((x: any) => {
      this.changeForControl(x)
    });
    this.setTooltipData()
    this.getContactDataUsingBehaviour();

    this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.selectContactFormSearch(term)) // Switch to new observable on new term
    ).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.exportdatavalueOFContact = response.DATA;
        this.searchContactData = response.DATA.RECORDS;
        if(response.DATA.RECORDS.length==0){
          this.Contactlist.push({'CONTACTNAME':this.currentEvent});
        }else{
          this.Contactlist = this.searchContactData;
        }
      }
    });
}

/**
 * This function is use to set the tool tip
 */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['Income'] ){
      this.toolTipList  = TooltipData['Income'];
    }else{
      this.tooltipService.setToolTipData = ('Income');
     }
  };

  /**
   * This function is used to change the amount.
   */
  amountChange(element, field){
    const numbers = this.generalReceiptForm.controls[field].value;
    if(numbers) {
      const numericValue = parseFloat(numbers.replace(/,/g, '')); // Remove commas and convert to a number
      const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
      element.target.value = formattedNumber;
    }
  }

  /**
   * This function is used to toggle the Update data 
   */
  ToggleForUpadte():void {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to chenge the control
   */
  changeForControl(formControl) {
    //Error
    const dubObj = { ...this.errorWarningDataArray.Error };
    let element = this.errorWarningData.Error;
    for (const [key, value] of Object.entries(dubObj)) {
      const KEY = (key).toLocaleUpperCase();

      if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
        delete element[key];
        dubObj[key] = value;
        this.errorWarningDataArray.Error = { ...dubObj }
      } else if (!formControl[KEY]) {
        element[key] = dubObj[key];
      }
    }
    //Warning
    const dubObj2 = { ...this.errorWarningDataArray.Warning };
    let element2 = this.errorWarningData.Warning;
    for (const [key, value] of Object.entries(dubObj2)) {
      const KEY = (key).toLocaleUpperCase();

      if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
        delete element2[key];
        dubObj2[key] = value;
        this.errorWarningDataArray.Warning = { ...dubObj2 }
      } else if (!formControl[KEY]) {
        element2[key] = dubObj2[key];
      }
    }
  }

  /**
   * This function is used to check the 
   * @param bodyData 
   */
  CheckdefultValidation(bodyData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };

  }

  /**
   * This function is used to load the data
   */
  LoadData() {
    this.isLoadingResults = true;
    
    this._mainAPiServiceService.getSetData({ Action: "GetData",Filters:{INCOMEGUID: this.ReceiptData.INCOMEGUID}}, 'income').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        if (response.DATA.RECORDS[0]) {
          let incomeData = response.DATA.RECORDS[0];
          this.toolTipList = response.DATA.FIELDTIPS;
          if (this.action == 'edit') {
            this.generalReceiptForm.controls['INCOMEGUID'].setValue(incomeData.INCOMEGUID);
          }
          let DatePaid = incomeData.INCOMEDATE.split("/");
          let DATE = new Date(DatePaid[1] + '/' + DatePaid[0] + '/' + DatePaid[2]);
          this.generalReceiptForm.controls['INCOMEDATE'].setValue(incomeData.INCOMEDATE);
          this.generalReceiptForm.controls['INCOMEDATETEXT'].setValue(DATE);
          this.generalReceiptForm.controls['INCOMECLASS'].setValue(incomeData.INCOMECLASS);
          this.generalReceiptForm.controls['INCOMETYPE'].setValue(incomeData.INCOMETYPE);
          this.generalReceiptForm.controls['PAYEE'].setValue(incomeData.PAYEE);
          this.Contactname=[incomeData.PAYEE];
          // const formattedNumber = new DecimalPipe('en-GB').transform(incomeData.AMOUNT,'1.2-2');
          // this.generalReceiptForm.controls['AMOUNT'].setValue(formattedNumber);
          this.generalReceiptForm.controls['AMOUNT'].setValue(incomeData.AMOUNT);
          this.generalReceiptForm.controls['GST'].setValue(incomeData.GST);
          this.generalReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(incomeData.BANKACCOUNTNUMBER);
          this.generalReceiptForm.controls['INCOMEACCOUNTGUIDTEXT'].setValue(incomeData.INCOMEACCOUNTNUMBER);
          this.generalReceiptForm.controls['INCOMEACCOUNTGUID'].setValue(incomeData.INCOMEACCOUNTGUID);
          this.generalReceiptForm.controls['BANKACCOUNTGUID'].setValue(incomeData.BANKACCOUNTGUID);
          this.generalReceiptForm.controls['NOTE'].setValue(incomeData.NOTE);

          if (Math.round(Number(incomeData.GST)) == 0) {
            this.generalReceiptForm.controls['gsttype'].setValue(2);
          } else {
            let total : any = this.AMOUNT - this.AMOUNT / 1.1;
            if (round(Number(total)) == round(Number(incomeData.GST))) {
              this.generalReceiptForm.controls['gsttype'].setValue(1);
            } else {
              this.generalReceiptForm.controls['gsttype'].setValue(3);
            }
          }


        }
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isLoadingResults = false;
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });

  }

  /**
   * This function is used to set the default Matter Account
   */
  defaultMatterAccount(value) {
    let userData = JSON.parse(localStorage.getItem("currentUser"));
    if (value) {
      this.isLoadingResults = true;
      let accountGuid = value == 'Matter Expense' ? userData.DISBURSEMENTACCOUNTGUID : userData.TAXPAYABLEACCOUNTGUID;
      this._mainAPiServiceService.getSetData({ ACCOUNTGUID: accountGuid }, "GetAccount").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
        let x = response.DATA.ACCOUNTS[0];
        this.generalReceiptForm.controls["INCOMEACCOUNTGUIDTEXT"].setValue(x.ACCOUNTCLASS + " - " + x.ACCOUNTNUMBER);
        this.generalReceiptForm.controls["INCOMEACCOUNTGUID"].setValue(x.ACCOUNTGUID);
        this.isLoadingResults = false;
      }, (err) => {
        console.log(err);
      });
    } else {
      this.generalReceiptForm.controls["BANKACCOUNTGUIDTEXT"].setValue('');
      this.generalReceiptForm.controls["BANKACCOUNTGUID"].setValue('');
    }

  }

  /**
   * This function is used to chenge the class value
   */
  classChange(value) {
    if (value == 'GST Refund') {
      this.defaultMatterAccount(value);
      this.generalReceiptForm.controls['gsttype'].setValue(2);
      this.gstTypeChange(2);
      this.isGstReadonly = false;
      this.classText = 'GST A/C';
      this.classType = 'Liability';
      this.isHide = true;
    } else if (value == 'Tax Refund') {
      this.defaultMatterAccount(value);
      this.generalReceiptForm.controls['gsttype'].setValue(2);
      this.isGstReadonly = false;
      this.isHide = false;
      this.classText = 'Tax A/C';
      this.classType = 'Liability';
    } else if (value == 'Capital') {
      // this.defaultMatterAccount(value);
      this.generalReceiptForm.controls['gsttype'].setValue(1);
      this.gstTypeChange(3);
      this.amountVal()
      this.isHide = false;
      this.isGstReadonly = true;
      this.classText = 'Asset A/C';
      this.classType = 'ASSET';
    } else if (value == 'Personal') {
      // this.defaultMatterAccount(value);
      this.generalReceiptForm.controls['gsttype'].setValue(2);
      this.gstTypeChange(2);
      this.isHide = true
      this.isGstReadonly = false;
      this.classText = 'Equity A/C';
      this.classType = 'Equity';
    } else if (value == 'Other') {
      // this.defaultMatterAccount(value);
      this.generalReceiptForm.controls['gsttype'].setValue(1);
      this.amountVal()
      this.gstTypeChange(3);
      this.isHide = false;
      this.isGstReadonly = false;
      this.classText = 'Income A/C';
      this.classType = 'all';
    } else {
      this.generalReceiptForm.controls['gsttype'].setValue(1);
      this.amountVal()
      this.gstTypeChange(3);
      this.isHide = false;
      this.isGstReadonly = true;
      this.classText = 'Income A/C';
      this.classType = 'INCOME';
    }
    this.generalReceiptForm.controls['INCOMEACCOUNTGUIDTEXT'].setValue('');
    this.generalReceiptForm.controls['INCOMEACCOUNTGUID'].setValue('');
  }

  /**
   * This function is used to change the gst type
   */
  gstTypeChange(value) {
    this.flaxValue = value == 2 ? 30 : 20;
    this.isGstReadonly = value == 3 ? false : true;
    if (value == 2) {
      this.generalReceiptForm.controls['GST'].setValue("0.00");
    }
    this.amountVal();
  }

  /**
   * This function is used to change the payee type
   */
  PayeeTypeChange(value) {
    this.generalReceiptForm.controls['PAYEE'].setValue(value);
  }

  /**
   * This function is used to get the payor
   */
  getPayor(postData) {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData(postData, 'contact').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.getPayourarray = response.DATA.RECORDS;
        this.filteredOptions = this.f.PAYEE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
        this.isLoadingResults = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      } else {
        this.isLoadingResults = false;
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to select the client
   */
  selectClient(val) {
    this.searchContactData = [];
    this.Contactlist = this.searchContactData;
    const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: '' } });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'ReceiptDilogComponent');
      if (result) {
        if (val == 'client') {
          this.generalReceiptForm.controls['FIRMGUIDTEXT'].setValue(result.CONTACTNAME);
          this.generalReceiptForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
        } else {
          this.generalReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
          this.Contactname=[result.CONTACTNAME];
        }
      }
    });
  }

  /**
   * This function is used to filtrer the data value
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.getPayourarray.filter(option => (option.CONTACTNAME.toLowerCase().includes(filterValue)));
  }
  get f() {
    return this.generalReceiptForm.controls;
  }

  /**
   * This function is used to choosed the date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.generalReceiptForm.controls['INCOMEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  /**
   * This function is used to get the amount the value
   */
  amountVal() {
    // const numericValue = this.f.AMOUNT.value ? this.f.AMOUNT.value.replace(/,/g, '') : 0;
    this.AMOUNT = this.f.AMOUNT.value ? parseFloat(this.f.AMOUNT.value).toFixed(2) : 0;
    // this.AMOUNT = numericValue ? parseFloat(numericValue).toFixed(2) : 0;
    let temV = this.AMOUNT - this.AMOUNT / 1.1;
    this.GST = temV.toFixed(2);
    // const formattedNumber = new DecimalPipe('en-GB').transform(this.AMOUNT,'1.2-2');
    this.generalReceiptForm.controls['AMOUNT'].setValue(this.AMOUNT);
    // this.generalReceiptForm.controls['AMOUNT'].setValue(formattedNumber);
    if (this.f.gsttype.value == 2) {
      this.generalReceiptForm.controls['GST'].setValue("0.00");
    } else {
      this.generalReceiptForm.controls['GST'].setValue(this.GST);
    }
  }

  /**
   * This funtion is used to get the gst 
   */
  gstVal() {
    this.GST = parseFloat(this.f.GST.value).toFixed(2);
    this.generalReceiptForm.controls['GST'].setValue(this.GST);
  }

  /**
   * this function is used to save the Receipt data value
   */
  SaveGeneralReceipt() {
    this.isspiner = true;
    // AMOUNT: Number(this.f.AMOUNT.value ? this.f.AMOUNT.value.replace(/[^0-9\.]/g, '') : 0) - Number(this.f.GST.value),
    let data: any = {
      INCOMECLASS: this.f.INCOMECLASS.value,
      INCOMETYPE: this.f.INCOMETYPE.value,
      // FIRMGUID: this.f.FIRMGUID.value,
      INCOMEDATE: this.f.INCOMEDATE.value,
      PAYEE: this.f.PAYEE.value,
      AMOUNT: Number(this.f.AMOUNT.value) - Number(this.f.GST.value),
      GST: this.f.GST.value,
      BANKACCOUNTGUID: this.f.BANKACCOUNTGUID.value,
      INCOMEACCOUNTGUID: this.f.INCOMEACCOUNTGUID.value,
      NOTE: this.f.NOTE.value,
    };
    let FormActionData = 'insert';
    if (this.action == 'edit') {
      data.INCOMEGUID = this.f.INCOMEGUID.value;
      FormActionData = 'update';
    }
    let matterPostData: any = { FormAction: FormActionData, VALIDATEONLY: true, Data: data };
    this._mainAPiServiceService.getSetData(matterPostData, 'SetIncome').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to check the validation value
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        if (value.FIELDNAME != "INCOMECODE")
          warningData.push(value.ERRORDESCRIPTION);
        tempWarning[value.FIELDNAME] = value;
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };

    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.SaveReceiptAfterVAlidation(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.SaveReceiptAfterVAlidation(details);
    }
  }

  /**
   * This function is used to save the Recept After validation 
   */
  SaveReceiptAfterVAlidation(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'SetIncome').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action == 'edit') {
          this.toastr.success('Receipt update successfully');
        } else {
          this.toastr.success('Receipt save successfully');
        }
        this.dialogRef.close(true);
      } else if (response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to open the Banking dialog data
   */
  BankingDialogOpen(type: any) {
    // for by default bank account select not trust account 
    this.ChartHandlingData = { ClickType: 'WithoutTrust', UseTrust: 'No', PopUp: '', Lable: "Select Account", }
    localStorage.setItem('ChartURL', JSON.stringify(this.ChartHandlingData));
    this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);

    const dialogRef = this.MatDialog.open(BankingDialogComponent, {
      disableClose: true, width: '100%', data: { AccountType: type, FromWhere: 'generalReceiptIncome' }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'GeneralReceiptDilogComponent');
      if (result) {
        if (result.AccountType == "BANK ACCOUNT") {
          this.generalReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(result.ACCOUNTCLASS + ' - ' + result.ACCOUNTNUMBER);
          this.generalReceiptForm.controls['BANKACCOUNTGUID'].setValue(result.ACCOUNTGUID);
        } else {
          this.generalReceiptForm.controls['INCOMEACCOUNTGUIDTEXT'].setValue(result.ACCOUNTCLASS + ' - ' + result.ACCOUNTNUMBER);
          this.generalReceiptForm.controls['INCOMEACCOUNTGUID'].setValue(result.ACCOUNTGUID);
        }
      }
    });
  }

  onKeyUp(event: any): void {
    const searchValue = event.target.value;
    this.searchTerms.next(searchValue);
  }

/**
 * This function is used to select Contact Form search
 */
  selectContactFormSearch(event){
    this.Contactlist=[];
    this.currentEvent = '';
    // let searchobj = { FastSearch:'True', Search: event }
    let payload = {
      "ACTION": "GetData",
      "FILTERS": {
        "FASTSEARCH": 1,
        "SEARCH": event
      },
    };
    this.currentEvent = event;
    return this._mainAPiServiceService.getSetData(payload, 'contact')
  }

  /**
   * This function is used to get the Contact Data Using Behaviour.
   */
  getContactDataUsingBehaviour(){
    this.behaviorService.newContactData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result=>{
        this.Contactlist=[];
        if (result) {
            this.generalReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
            this.Contactname=[result.CONTACTNAME];
        
        }
    })
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
      let result=this.MatterInputContact.nativeElement.value;
        if(result && result!=null && result != undefined && this.CallHostlistner==false){
          this.Contactdatashow(this.MatterInputContact.nativeElement);
          this.MatterInputContact.nativeElement.value='';
          this.Contactlist=[]
        }
    }

    ngOnDestroy(): void {
      this.behaviorService.newContactData$.next(null);   
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub?.unsubscribe();
      this.subscription?.unsubscribe();
    }


    /**
     * This function is used to show the contact data
     */
  Contactdatashow(event) {
    this.Contactname=[];
        this.CallHostlistner=true;
        if (this.exportdatavalueOFContact && this.exportdatavalueOFContact.RECORDS && this.exportdatavalueOFContact.RECORDS.length !=0) {
      this.result=this.exportdatavalueOFContact.RECORDS[0]
      this.Contactlist = [this.result.CONTACTNAME];
     this.generalReceiptForm.controls['PAYEE'].setValue(null);
      if (event.input) {
        event.input.value = '';
      } else {
        this.MatterInputContact.nativeElement.value = '';
      }

      if (this.result) {
        this.generalReceiptForm.controls['PAYEE'].setValue(this.result.CONTACTNAME);
        this.Contactname=[this.result.CONTACTNAME];
      }
    }
      else{
        this.toolbarServiceService.ContactsDialog('new',this.MatterInputContact.nativeElement.value);
        this.Contactname = [];
        this.Contactlist=[];
        this.MatterInputContact.nativeElement.value='';

    }  
    this.CallHostlistner=false;
  }

  /**
   * This function is used to remove the contact 
   */
  removeContact(fruit: any) {    
    const index = this.Contactname.indexOf(fruit);
    if (index >= 0) {
      this.Contactname.splice(index, 1);
     // this.contactForm.controls['COMPANYCONTACTGUID'].setValue('');
      //this.Deliverable = [];
    }
    let sortname = fruit.split('-')[0]
    this.MatterInputContact.nativeElement.value = '';
    this.rmContactdata(sortname)
  };


  /**
   * This function is used to rm the contact data
   */
  rmContactdata(sortname) {
    this.selectedContctData.forEach((element: any, index: any) => {
      if (element.CONTACTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
        this.selectedContctData.splice(index, 1);
      }
    })
  }

  /**
   * This function is used to add the contact
   */
  addContact(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    this.Contactlist.forEach((element: any) => {
      if (element.CONTACTNAME == event.value) {
        // Add our fruit
        const array3 = this.Contactname;
        if (value) {
          if (this.Contactname.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.Contactname = [value]
          }
        }
      }
    });
    if (event.input) {
      event.input.value = '';
    }
    this.generalReceiptForm.controls['PAYEE'].setValue(null);
  };

  /**
   * This function is used to get the selected contact
   */
  selectedContact(result:any){
    this.MatterInputContact.nativeElement.value=''; 
    if(Object.keys(result).length==1){
        this.toolbarServiceService.ContactsDialog('new',result.CONTACTNAME);
        this.Contactname = [];
        this.Contactlist=[];
        
      }else{
    if (result) {
      this.generalReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
      this.Contactname=[result.CONTACTNAME];
    }
  }
  }



}
