import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, catchError, combineLatest, forkJoin, of, switchMap, tap } from 'rxjs';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-first-time-login-basic-information',
    templateUrl: './first-time-login-basic-information.component.html',
    styleUrls: ['./first-time-login-basic-information.component.scss'],
})
export class FirstTimeLoginBasicInformationComponent implements OnInit, OnDestroy {
    appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
    isLoadingResults: boolean = false;
    step: number = 1;
    signupForm: FormGroup;
    isNext = 'false';
    isShownThirdDiv: boolean = false;
    isspiner: boolean = false;
    errorWarningData: any = {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    currentUSer: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    getDropDownValue: any = [];
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub5: Subscription;
    timeZoneData: any = [
        { Name: '(UTC +08:00) Perth', value: '(UTC +08:00) Perth' },
        { Name: '(UTC +09:30) Adelaide', value: '(UTC +09:30) Adelaide' },
        { Name: '(UTC +09:30) Darwin', value: '(UTC +09:30) Darwin' },
        { Name: '(UTC +10:00) Brisbane', value: '(UTC +10:00) Brisbane' },
        { Name: '(UTC +10:00) Canberra, Melbourne, Sydney', value: '(UTC +10:00) Canberra, Melbourne, Sydney' },
        { Name: '(UTC +10:00) Hobart', value: '(UTC +10:00) Hobart' },
    ];

    constructor(public DialogRef: MatDialogRef<FuseConfirmDialogComponent>, public _matDialog: MatDialog, private _formBuilder: FormBuilder, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService, private behaviorService: BehaviorService) {
        this.AfterViewInitNewMatter()

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.formBuild();
        this.getDefaultSystemData()
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub5?.unsubscribe();
    }

    /**
     * set the default values of form which is get from API
     */
    getDefaultSystemData(): void {
        this.isLoadingResults = true
        this.sub = forkJoin([
            this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { "GroupName": "BusinessSetup" } }, 'system'),
            this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { "GroupName": "OrganisationalDetails" } }, 'system'),
            this._mainAPiServiceService.getSetData({ Action: 'GetData', Filters: { "GroupName": "Integrations" } }, 'system')
        ]).pipe(
            tap(([business, orgDetails, integrations]) => {
                if (business.CODE == 200 && (business.STATUS == "OK" || business.STATUS == "success")) {
                    this.isLoadingResults = false;
                    this.getDropDownValue = business.DATA.LISTS;
                    let businessData = business.DATA.RECORDS;
                    this.signupForm.controls['GSTTYPE'].setValue(businessData.GSTTYPE.toString());
                    if (this.currentUSer.PRODUCTTYPE == 'Barrister') {
                        this.signupForm.controls['HOURLYBASERATE'].setValue(businessData.HOURLYBASERATE);
                        this.signupForm.controls['DAILYBASERATE'].setValue(businessData.DAILYBASERATE);
                        this.signupForm.controls['HOURLYBASERATE2'].setValue(businessData.HOURLYBASERATE2);
                        this.signupForm.controls['DAILYBASERATE2'].setValue(businessData.DAILYBASERATE2);
                        this.signupForm.controls['HOURLYBASERATE3'].setValue(businessData.HOURLYBASERATE3);
                        this.signupForm.controls['DAILYBASERATE3'].setValue(businessData.DAILYBASERATE3);
                        this.signupForm.controls['HOURLYBASERATE4'].setValue(businessData.HOURLYBASERATE4);
                        this.signupForm.controls['DAILYBASERATE4'].setValue(businessData.DAILYBASERATE4);
                        this.signupForm.controls['HOURLYBASERATE5'].setValue(businessData.HOURLYBASERATE5);
                        this.signupForm.controls['DAILYBASERATE5'].setValue(businessData.DAILYBASERATE5);
                        this.signupForm.controls['HOURLYBASERATE6'].setValue(businessData.HOURLYBASERATE6);
                        this.signupForm.controls['DAILYBASERATE6'].setValue(businessData.DAILYBASERATE6);
                        this.signupForm.controls['CLERKRATE'].setValue(businessData.CLERKRATE);
                    }
                    this.signupForm.controls['RATEDESCRIPTION1'].setValue(businessData.RATEDESCRIPTION1);
                    this.signupForm.controls['RATEDESCRIPTION2'].setValue(businessData.RATEDESCRIPTION2);
                    this.signupForm.controls['RATEDESCRIPTION3'].setValue(businessData.RATEDESCRIPTION3);
                    this.signupForm.controls['RATEDESCRIPTION4'].setValue(businessData.RATEDESCRIPTION4);
                    this.signupForm.controls['RATEDESCRIPTION5'].setValue(businessData.RATEDESCRIPTION5);
                    this.signupForm.controls['RATEDESCRIPTION6'].setValue(businessData.RATEDESCRIPTION6);

                    this.signupForm.controls['MATTERDELIVERABLEGUID'].setValue(businessData.DELIVERABLES);

                    // this.signupForm.controls['DELIVERABLES'].setValue(businessData.DELIVERABLES);
                    this.signupForm.controls['UNITSPERHOUR'].setValue(businessData.UNITSPERHOUR);
                    this.signupForm.controls['DUEDATEOFFSET'].setValue(businessData.DUEDATEOFFSET);
                    this.signupForm.controls['OFFICECHEQUEFORMAT'].setValue(businessData.OFFICECHEQUEFORMAT);
                    this.signupForm.controls['VENDORISLIABLEFORSETTLEMENTDATE'].setValue(businessData.VENDORISLIABLEFORSETTLEMENTDATE);
                    // this.signupForm.controls['USESINGLELINEEXPENSE'].setValue(businessData.USESINGLELINEEXPENSE);
                    this.signupForm.controls['RATEOVERRIDESEQUENCE'].setValue(businessData.RATEOVERRIDESEQUENCE);
                    this.signupForm.controls['DEFAULTTRUSTRECEIPTTYPE'].setValue(businessData.DEFAULTTRUSTRECEIPTTYPE);
                    this.signupForm.controls['DEFAULTTRUSTWITHDRAWALTYPE'].setValue(businessData.DEFAULTTRUSTWITHDRAWALTYPE);
                    this.signupForm.controls['EFTTRUSTACCOUNTNAME'].setValue(businessData.EFTTRUSTACCOUNTNAME);
                    this.signupForm.controls['EFTTRUSTACCOUNTNUMBER'].setValue(businessData.EFTTRUSTACCOUNTNUMBER);
                    this.signupForm.controls['EFTTRUSTBSB'].setValue(businessData.EFTTRUSTBSB);

                    this.signupForm.controls['TRUSTACCACCOUNTNAME'].setValue(businessData.TRUSTACCACCOUNTNAME);
                    this.signupForm.controls['TRUSTACCBSB'].setValue(businessData.TRUSTACCBSB);
                    this.signupForm.controls['TRUSTACCACCOUNTNUMBER'].setValue(businessData.TRUSTACCACCOUNTNUMBER);

                    this.signupForm.controls['TRUSTCHEQUEFORMAT'].setValue(businessData.TRUSTCHEQUEFORMAT);
                    this.signupForm.controls['TRUSTRECEIPTCOPIES'].setValue(businessData.TRUSTRECEIPTCOPIES.toString());
                    this.signupForm.controls['TRUSTSTATE'].setValue(businessData.TRUSTSTATE);
                    this.signupForm.controls['FIRSTTRUSTMONTH'].setValue(businessData.FIRSTTRUSTMONTH);

                    this.signupForm.controls['DEFAULTEXPENSETYPE'].setValue(businessData.DEFAULTEXPENSETYPE);
                    this.signupForm.controls['DEFAULTPAIDTYPE'].setValue(businessData.DEFAULTPAIDTYPE);
                    this.signupForm.controls['DEFAULTINCOMETYPE'].setValue(businessData.DEFAULTINCOMETYPE);
                    this.signupForm.controls['SHORTNAMESTRATEGY'].setValue(businessData.SHORTNAMESTRATEGY);
                    this.signupForm.controls['REQUIRECOSTAGREEMENT'].setValue(businessData.REQUIRECOSTAGREEMENT);
                    this.signupForm.controls['REQUIREMATTEROWNER'].setValue(businessData.REQUIREMATTEROWNER);
                    this.signupForm.controls['REQUIREFEEEARNER'].setValue(businessData.REQUIREFEEEARNER);
                    this.signupForm.controls['DEFAULTMATTERCLASS'].setValue(businessData.DEFAULTMATTERCLASS);
                    this.signupForm.controls['REQUIREMATTERTYPE'].setValue(businessData.REQUIREMATTERTYPE);
                    this.signupForm.controls['REQUIRECLIENTSOURCE'].setValue(businessData.REQUIRECLIENTSOURCE);
                    this.signupForm.controls['REQUIREFIELDOFLAW'].setValue(businessData.REQUIREFIELDOFLAW);
                    this.signupForm.controls['REQUIREEXPENSEPAYEE'].setValue(businessData.REQUIREEXPENSEPAYEE);
                    this.signupForm.controls['SUNDRYFEEEARNER'].setValue(businessData.SUNDRYFEEEARNER);

                    this.signupForm.controls['SHOWCONFLICTCHECKFORNEWMATTER'].setValue(businessData.SHOWCONFLICTCHECKFORNEWMATTER);
                    this.signupForm.controls['CHARGEDISBGSTONGSTFREEMATTERS'].setValue(businessData.CHARGEDISBGSTONGSTFREEMATTERS);

                    this.signupForm.controls['ALLOWESTIMATERANGE'].setValue(businessData.ALLOWESTIMATERANGE);
                    this.signupForm.controls['USEHIGHESTIMATEFORWARNING'].setValue(businessData.USEHIGHESTIMATEFORWARNING);
                    this.signupForm.controls['ESTIMATEWARNINGPERCENT'].setValue(businessData.ESTIMATEWARNINGPERCENT);
                    this.signupForm.controls['ESTIMATENEXTTHRESHOLD'].setValue(businessData.ESTIMATENEXTTHRESHOLD);
                    this.signupForm.controls['ESTIMATENEXTWARNINGPERCENT'].setValue(businessData.ESTIMATENEXTWARNINGPERCENT);

                    this.signupForm.controls['COUNTRY'].setValue(businessData.COUNTRY);
                    this.signupForm.controls['CURRENCYSYMBOL'].setValue(businessData.CURRENCYSYMBOL);
                    this.signupForm.controls['TIMEZONE'].setValue(businessData.TIMEZONE);
                    this.signupForm.controls['TAXRATE'].setValue(businessData.TAXRATE);
                    this.signupForm.controls['TAXTYPE'].setValue(businessData.TAXTYPE);
                    this.signupForm.controls['PRACACCACCOUNTNAME'].setValue(businessData.PRACACCACCOUNTNAME);
                    this.signupForm.controls['PRACACCBSB'].setValue(businessData.PRACACCBSB);
                    this.signupForm.controls['PRACACCACCOUNTNUMBER'].setValue(businessData.PRACACCACCOUNTNUMBER);
                    this.signupForm.controls['SWIFTID'].setValue(businessData.SWIFTID);
                }
                if (orgDetails.CODE == 200 && (orgDetails.STATUS == "OK" || orgDetails.STATUS == "success")) {
                    this.isLoadingResults = false;
                    let odData = orgDetails.DATA.RECORDS;
                    this.signupForm.controls['BARRISTERSNAME'].setValue(odData.BARRISTERSNAME);
                    this.signupForm.controls['ABN'].setValue(odData.ABN);
                    this.signupForm.controls['FAMILYLAWCOURTID'].setValue(odData.FAMILYLAWCOURTID);
                    this.signupForm.controls['LRSBOXNUMBER'].setValue(odData.LRSBOXNUMBER);
                    if (this.currentUSer.PRODUCTTYPE == 'Barrister') {
                        this.signupForm.controls['CHAMBERS'].setValue(odData.CHAMBERS);
                    }
                    this.signupForm.controls['ADDRESS1'].setValue(odData.ADDRESS1);
                    this.signupForm.controls['ADDRESS2'].setValue(odData.ADDRESS2);
                    this.signupForm.controls['POSTCODE'].setValue(odData.POSTCODE);
                    this.signupForm.controls['SUBURB'].setValue(odData.SUBURB);
                    this.signupForm.controls['STATE'].setValue(odData.STATE);

                    this.signupForm.controls['POSTALADDRESS1'].setValue(odData.POSTALADDRESS1);
                    this.signupForm.controls['POSTALADDRESS2'].setValue(odData.POSTALADDRESS2);
                    this.signupForm.controls['POSTALPOSTCODE'].setValue(odData.POSTALPOSTCODE);
                    this.signupForm.controls['POSTALSUBURB'].setValue(odData.POSTALSUBURB);
                    this.signupForm.controls['POSTALSTATE'].setValue(odData.POSTALSTATE);

                    this.signupForm.controls['DXNUMBER'].setValue(odData.DXNUMBER);
                    this.signupForm.controls['DXSUBURB'].setValue(odData.DXSUBURB);

                    this.signupForm.controls['FAX1'].setValue(odData.FAX1);
                    this.signupForm.controls['FAX2'].setValue(odData.FAX2);
                    this.signupForm.controls['PHONE1'].setValue(odData.PHONE1);
                    this.signupForm.controls['PHONE2'].setValue(odData.PHONE2);

                    this.signupForm.controls['EMAIL'].setValue(odData.EMAIL);
                    this.signupForm.controls['WWW'].setValue(odData.WWW);
                }
                if (integrations.CODE == 200 && (integrations.STATUS == "OK" || integrations.STATUS == "success")) {
                    this.isLoadingResults = false;
                    let tempData = integrations.DATA.RECORDS;
                    this.signupForm.controls['XEROSTARTDATE'].setValue(tempData.XEROSTARTDATE);
                    let XEROStartDateArray = tempData.XEROSTARTDATE.split("/");
                    this.signupForm.controls['XEROSTARTTEXTDATE'].setValue(new Date(XEROStartDateArray[1] + '/' + XEROStartDateArray[0] + '/' + XEROStartDateArray[2]));
                    this.signupForm.controls['EMAILSYSTEM'].setValue(tempData.EMAILSYSTEM);
                    this.signupForm.patchValue({
                        XEROINVOICEACCOUNT: tempData.XEROINVOICEACCOUNT,
                        XERODONTSYNCEXPENSES: tempData.XERODONTSYNCEXPENSES,
                        XERODONTSYNCINVOICES: tempData.XERODONTSYNCINVOICES,
                        XEROORGANISATION: tempData.XEROORGANISATION,
                        SEARCHPROVIDER: tempData.SEARCHPROVIDER,
                        SEARCHDOWNLOADUSERGUID: tempData.SEARCHDOWNLOADUSERGUID,
                        SEARCHUSERNAME: tempData.SEARCHUSERNAME,
                        SEARCHPASSWORD: tempData.SEARCHPASSWORD,
                        SEARCHGSTHANDLING: tempData.SEARCHGSTHANDLING,
                        SEARCHURL: tempData.SEARCHURL,
                        PINPAYMENTSAPIKEY: tempData.PINPAYMENTSAPIKEY,
                        PINPAYMENTSABSORBFEE: tempData.PINPAYMENTSABSORBFEE,
                        SILQPAYLINKDESC: tempData.SILQPAYLINKDESC,
                        SILQPAYSHOWINVDETAILS: tempData.SILQPAYSHOWINVDETAILS,
                        SILQPAYSHOWCHEQUEPAYMENT: tempData.SILQPAYSHOWCHEQUEPAYMENT,
                        SILQPAYSHOWDDPAYMENT: tempData.SILQPAYSHOWDDPAYMENT,
                        SILQPAYSHOWINVOICESTATEMENT1: tempData.SILQPAYSHOWINVOICESTATEMENT1,
                        SILQPAYSHOWINVOICESTATEMENT2: tempData.SILQPAYSHOWINVOICESTATEMENT2,
                        SILQPAYSHOWINVOICESTATEMENT3: tempData.SILQPAYSHOWINVOICESTATEMENT3,
                        SILQPAYSHOWFEEAGRSTATEMENT: tempData.SILQPAYSHOWFEEAGRSTATEMENT,
                        SILQPAYSHOWGLOBALFOOTER: tempData.SILQPAYSHOWGLOBALFOOTER
                    });
                }
                this.behaviorService.loadingSystemSetting('defaults');
            })
        ).subscribe();
    }

    /**
     * define the formgroup for the window
     */
    formBuild(): void {
        this.signupForm = this._formBuilder.group({
            // BARRISTERSNAME: [''],
            // GSTTYPE: [''],
            // DEFAULTMATTERCLASS: [''],
            // // DUEDATE: [''],
            // ADDRESS1: [''],
            // ADDRESS2: [''],
            // SUBURB: [''],
            // STATE: [''],
            // POSTCODE: [''],
            // PHONE1: [''],
            // EMAIL: [''],
            // ABN: [''],
            // WWW: [''],
            // HOURLYBASERATE: [''],
            // TIMEZONE: [''],
            // SEARCHUSERNAME: [''],
            // SEARCHPASSWORD: ['']
            SAMECOPYADDRESS: [''],
            CHAMBERS: [''],
            BARRISTERSNAME: [''],
            ADDRESS1: [''],
            ADDRESS2: [''],
            SUBURB: [''],
            STATE: [''],
            POSTCODE: [''],
            POSTALADDRESS1: [''],
            POSTALADDRESS2: [''],
            POSTALSUBURB: [''],
            POSTALSTATE: [''],
            POSTALPOSTCODE: [''],
            DXNUMBER: [''],
            DXSUBURB: [''],
            PHONE1: [''],
            PHONE2: [''],
            FAX1: [''],
            FAX2: [''],
            EMAIL: [''],
            WWW: [''],
            FAMILYLAWCOURTID: [''],
            LRSBOXNUMBER: [''],
            ABN: [''],
            GSTTYPE: [''],
            HOURLYBASERATE: [''],
            DAILYBASERATE: [''],
            HOURLYBASERATE2: [''],
            DAILYBASERATE2: [''],
            HOURLYBASERATE3: [''],
            DAILYBASERATE3: [''],
            HOURLYBASERATE4: [''],
            DAILYBASERATE4: [''],
            HOURLYBASERATE5: [''],
            DAILYBASERATE5: [''],
            HOURLYBASERATE6: [''],
            DAILYBASERATE6: [''],
            UNITSPERHOUR: [''],
            MATTERDELIVERABLEGUID: [''],
            DUEDATEOFFSET: [''],
            OFFICECHEQUEFORMAT: [''],
            VENDORISLIABLEFORSETTLEMENTDATE: [''],
            USESINGLELINEEXPENSE: [''],
            RATEOVERRIDESEQUENCE: [''],
            RATEDESCRIPTION1: [''],
            RATEDESCRIPTION2: [''],
            RATEDESCRIPTION3: [''],
            RATEDESCRIPTION4: [''],
            RATEDESCRIPTION5: [''],
            RATEDESCRIPTION6: [''],
            DEFAULTEXPENSETYPE: [''],
            DEFAULTPAIDTYPE: [''],
            DEFAULTINCOMETYPE: [''],
            SHORTNAMESTRATEGY: [''],
            REQUIRECOSTAGREEMENT: [''],
            DEFAULTMATTERCLASS: [''],
            REQUIREMATTEROWNER: [''],
            REQUIREFEEEARNER: [''],
            SUNDRYFEEEARNER: [''],
            SHOWCONFLICTCHECKFORNEWMATTER: [''],
            CHARGEDISBGSTONGSTFREEMATTERS: [''],
            ALLOWMOBILEACCESS: [''],
            DONTALLOWTRUSTOVERDRAWS: [''],
            REQUIREMATTERTYPE: [''],
            REQUIRECLIENTSOURCE: [''],
            REQUIREFIELDOFLAW: [''],
            REQUIREEXPENSEPAYEE: [''],
            ESTIMATEWARNINGPERCENT: [''],
            ESTIMATENEXTTHRESHOLD: [''],
            ESTIMATENEXTWARNINGPERCENT: [''],
            ALLOWESTIMATERANGE: [''],
            USEHIGHESTIMATEFORWARNING: [''],
            COUNTRY: [''],
            TAXTYPE: [''],
            TAXRATE: [''],
            CURRENCYSYMBOL: [''],
            TIMEZONE: [''],
            FIRSTTRUSTMONTH: [''],
            TRUSTSTATE: [''],
            TRUSTCHEQUEFORMAT: [''],
            TRUSTRECEIPTCOPIES: [''],
            EFTTRUSTACCOUNTNAME: [''],
            EFTTRUSTBSB: [''],
            EFTTRUSTACCOUNTNUMBER: [''],
            DEFAULTTRUSTRECEIPTTYPE: [''],
            DEFAULTTRUSTWITHDRAWALTYPE: [''],
            TRUSTACCACCOUNTNAME: [''],
            TRUSTACCBSB: [''],
            TRUSTACCACCOUNTNUMBER: [''],
            INVOICETEMPLATE: [''],
            RECEIPTTEMPLATE: [''],
            RECEIPTSAVEFOLDERLOCATION: [''],
            INVOICESAVEFOLDERPARENT: [''],
            RECEIPTSAVEFOLDERPARENT: [''],
            INVOICESAVEFOLDERLOCATION: [''],
            DONTGENERATERECEIPT: [''],
            DOCUMENTSYSTEM: [''],
            ROOTFOLDER: [''],
            SHAREPOINTDRIVE: [''],
            SHAREPOINTSUBSITE: [''],
            SHAREPOINTSITE: [''],
            DIRECTORYSAVESTRATEGY: [''],
            DOCUMENTSAVESTRATEGY: [''],
            DIRECTORYSAVESTATEGY: [''],
            DEFAULTSUBFOLDERS: [''],
            TRACKDOCUMENTS: [''],
            ONEDRIVEPRIMARYUSER: [''],
            EFFECTIVEDATE: [''],
            sendEFFECTIVEDATE: [''],
            INTERESTRATE: [''],
            INTERESTGUID: [''],
            SCALE_: [''],
            CURRENCYID: [''],
            EXCHANGERATEGUID: [''],
            RATE: [''],
            XEROINVOICEACCOUNT: [''],
            XERODONTSYNCEXPENSES: [''],
            XERODONTSYNCINVOICES: [''],
            XEROORGANISATION: [''],
            EMAILSYSTEM: [''],
            SEARCHPROVIDER: [''],
            SEARCHUSERNAME: [''],
            SEARCHPASSWORD: [''],
            SEARCHGSTHANDLING: [''],
            SEARCHURL: [''],
            SEARCHDOWNLOADUSERGUID: [''],
            PINPAYMENTSAPIKEY: [''],
            PINPAYMENTSABSORBFEE: [''],
            SILQPAYLINKDESC: [''],
            SILQPAYSHOWINVDETAILS: [''],
            SILQPAYSHOWCHEQUEPAYMENT: [''],
            SILQPAYSHOWDDPAYMENT: [''],
            SILQPAYSHOWINVOICESTATEMENT1: [''],
            SILQPAYSHOWINVOICESTATEMENT2: [''],
            SILQPAYSHOWINVOICESTATEMENT3: [''],
            SILQPAYSHOWFEEAGRSTATEMENT: [''],
            SILQPAYSHOWGLOBALFOOTER: [''],
            STATEMENT2: [''],
            STATEMENT3: [''],
            STATEMENT4: [''],
            STATEMENT5: [''],
            STATEMENT2RTFBASEB4: [''],
            STATEMENT3RTFBASEB4: [''],
            STATEMENT4RTFBASEB4: [''],
            STATEMENT2RTFFILE: [''],
            STATEMENT3RTFFILE: [''],
            STATEMENT4RTFFILE: [''],
            FEEAGREEMENTSTATEMENT: [''],
            STATEMENT2ISRTF: [''],
            STATEMENT3ISRTF: [''],
            STATEMENT4ISRTF: [''],
            XEROSTARTDATE: [''],
            XEROSTARTTEXTDATE: [''],
            PRACACCACCOUNTNAME: [''],
            PRACACCBSB: [''],
            PRACACCACCOUNTNUMBER: [''],
            SWIFTID: [''],
            CLERKRATE: [''],
        });
        this.sub5 = this.signupForm.valueChanges.subscribe(formControl => {
            this.changeForControl(formControl);
        })

    }

    get f() {
        return this.signupForm.controls;
    }

    /**
     * 
     */
    gotoNext() {
        this.step = 2;
        this.isNext = 'true';
    }

    gotoPrevious() {
        this.step = 1;
        this.isNext = 'false';
    }

    gotoThirdStep() {
        this.step = 3;
        this.isNext = 'Third';
    }

    /**
     * function is called when user click on save button to save all the information
     */
    gotoFinish1(){
        this.isLoadingResults = true;
        this.isspiner = true;
        let Data = {
            "GroupName": "BusinessSetup",
            "GSTTYPE": this.signupForm.controls.GSTTYPE.value,
            "UNITSPERHOUR": this.signupForm.controls.UNITSPERHOUR.value,
            "DELIVERABLES": this.signupForm.controls.MATTERDELIVERABLEGUID.value,
            "DUEDATEOFFSET": this.signupForm.controls.DUEDATEOFFSET.value,
            "VENDORISLIABLEFORSETTLEMENTDATE": this.signupForm.controls.VENDORISLIABLEFORSETTLEMENTDATE.value,
            'RATEOVERRIDESEQUENCE': this.signupForm.controls.RATEOVERRIDESEQUENCE.value,
            "DEFAULTEXPENSETYPE": this.signupForm.controls.DEFAULTEXPENSETYPE.value,
            "DEFAULTPAIDTYPE": this.signupForm.controls.DEFAULTPAIDTYPE.value,
            "DEFAULTINCOMETYPE": this.signupForm.controls.DEFAULTINCOMETYPE.value,
            "DEFAULTMATTERCLASS": this.signupForm.controls.DEFAULTMATTERCLASS.value,
            "SHORTNAMESTRATEGY": this.signupForm.controls.SHORTNAMESTRATEGY.value,
            "REQUIRECOSTAGREEMENT": this.signupForm.controls.REQUIRECOSTAGREEMENT.value,
            "OFFICECHEQUEFORMAT": this.signupForm.controls.OFFICECHEQUEFORMAT.value,
            "REQUIREMATTEROWNER": this.signupForm.controls.REQUIREMATTEROWNER.value,
            "REQUIREFEEEARNER": this.signupForm.controls.REQUIREFEEEARNER.value,
            "SUNDRYFEEEARNER": this.signupForm.controls.SUNDRYFEEEARNER.value,
            "REQUIREMATTERTYPE": this.signupForm.controls.REQUIREMATTERTYPE.value,
            "REQUIRECLIENTSOURCE": this.signupForm.controls.REQUIRECLIENTSOURCE.value,
            "REQUIREFIELDOFLAW": this.signupForm.controls.REQUIREFIELDOFLAW.value,
            "CHARGEDISBGSTONGSTFREEMATTERS": this.signupForm.controls.CHARGEDISBGSTONGSTFREEMATTERS.value,
            "REQUIREEXPENSEPAYEE": this.signupForm.controls.REQUIREEXPENSEPAYEE.value,
            "SHOWCONFLICTCHECKFORNEWMATTER": this.signupForm.controls.SHOWCONFLICTCHECKFORNEWMATTER.value,
            "RATEDESCRIPTION1": this.signupForm.controls.RATEDESCRIPTION1.value,
            "RATEDESCRIPTION2": this.signupForm.controls.RATEDESCRIPTION2.value,
            "RATEDESCRIPTION3": this.signupForm.controls.RATEDESCRIPTION3.value,
            "RATEDESCRIPTION4": this.signupForm.controls.RATEDESCRIPTION4.value,
            "RATEDESCRIPTION5": this.signupForm.controls.RATEDESCRIPTION5.value,
            "RATEDESCRIPTION6": this.signupForm.controls.RATEDESCRIPTION6.value,
            "ESTIMATEWARNINGPERCENT": this.signupForm.controls.ESTIMATEWARNINGPERCENT.value,
            "ALLOWESTIMATERANGE": this.signupForm.controls.ALLOWESTIMATERANGE.value,
            "USEHIGHESTIMATEFORWARNING": this.signupForm.controls.USEHIGHESTIMATEFORWARNING.value,
            "ESTIMATENEXTTHRESHOLD": this.signupForm.controls.ESTIMATENEXTTHRESHOLD.value,
            "ESTIMATENEXTWARNINGPERCENT": this.signupForm.controls.ESTIMATENEXTWARNINGPERCENT.value,
            "COUNTRY": this.signupForm.controls.COUNTRY.value,
            "CURRENCYSYMBOL": this.signupForm.controls.CURRENCYSYMBOL.value,
            "TIMEZONE": this.signupForm.controls.TIMEZONE.value,
            "TAXTYPE": this.signupForm.controls.TAXTYPE.value,
            "TAXRATE": this.signupForm.controls.TAXRATE.value,
            "FIRSTTRUSTMONTH": this.signupForm.controls.FIRSTTRUSTMONTH.value,
            "TRUSTSTATE": this.signupForm.controls.TRUSTSTATE.value,
            "DEFAULTTRUSTRECEIPTTYPE": this.signupForm.controls.DEFAULTTRUSTRECEIPTTYPE.value,
            "DEFAULTTRUSTWITHDRAWALTYPE": this.signupForm.controls.DEFAULTTRUSTWITHDRAWALTYPE.value,
            "TRUSTCHEQUEFORMAT": this.signupForm.controls.TRUSTCHEQUEFORMAT.value,
            "TRUSTRECEIPTCOPIES": this.signupForm.controls.TRUSTRECEIPTCOPIES.value,
            "EFTTRUSTACCOUNTNAME": this.signupForm.controls.EFTTRUSTACCOUNTNAME.value,
            "EFTTRUSTBSB": this.signupForm.controls.EFTTRUSTBSB.value,
            "EFTTRUSTACCOUNTNUMBER": this.signupForm.controls.EFTTRUSTACCOUNTNUMBER.value,
            "TRUSTACCACCOUNTNAME": this.signupForm.controls.TRUSTACCACCOUNTNAME.value,
            "TRUSTACCBSB": this.signupForm.controls.TRUSTACCBSB.value,
            "TRUSTACCACCOUNTNUMBER": this.signupForm.controls.TRUSTACCACCOUNTNUMBER.value,
            'PRACACCACCOUNTNAME': this.signupForm.controls.PRACACCACCOUNTNAME.value,
            'PRACACCBSB': this.signupForm.controls.PRACACCBSB.value,
            'PRACACCACCOUNTNUMBER': this.signupForm.controls.PRACACCACCOUNTNUMBER.value,
            "SWIFTID" : this.signupForm.controls.SWIFTID.value,
            'HOURLYBASERATE': this.signupForm.controls.HOURLYBASERATE.value
        }
        if (this.currentUSer.PRODUCTTYPE == 'Barrister') {
            Data['CLERKRATE'] = this.signupForm.controls.CLERKRATE.value,
            Data['DAILYBASERATE'] = this.signupForm.controls.DAILYBASERATE.value,
            Data['HOURLYBASERATE2'] = this.signupForm.controls.HOURLYBASERATE2.value,
            Data['DAILYBASERATE2'] = this.signupForm.controls.DAILYBASERATE2.value,
            Data['HOURLYBASERATE3'] = this.signupForm.controls.HOURLYBASERATE3.value,
            Data['DAILYBASERATE3'] = this.signupForm.controls.DAILYBASERATE3.value,
            Data['HOURLYBASERATE4'] = this.signupForm.controls.HOURLYBASERATE4.value,
            Data['DAILYBASERATE4'] = this.signupForm.controls.DAILYBASERATE4.value,
            Data['HOURLYBASERATE5'] = this.signupForm.controls.HOURLYBASERATE5.value,
            Data['DAILYBASERATE5'] = this.signupForm.controls.DAILYBASERATE5.value,
            Data['HOURLYBASERATE6'] = this.signupForm.controls.HOURLYBASERATE6.value,
            Data['DAILYBASERATE6'] = this.signupForm.controls.DAILYBASERATE6.value
        }
        let data1 = { Action: "Update", Data: Data }

       this.sub3 = this._mainAPiServiceService.getSetData(data1, 'system').pipe(
            switchMap((response) => combineLatest([of(response),this.gotoFinish2()])),
            switchMap((response: any, response2: any) => combineLatest([of(response), of(response2),this.gotoFinish3()]))
        ).subscribe(([response, response2, response3]) => {
            if (response3.CODE == 200 && (response3.STATUS == "OK" || response3.STATUS == "success")) {
                this.isspiner = false;
                this.isLoadingResults = false;
                this.behaviorService.DELIVERABLES$.next(localStorage.getItem('set_active_DELIVERABLES'));
                this.currentUSer['DEFAULTMATTERCLASS'] = this.f.DEFAULTMATTERCLASS.value;
                this.currentUSer.ONBOARDING.BASICINCOMPLETE = 0;
                localStorage.setItem('currentUser', JSON.stringify(this.currentUSer));
                localStorage.setItem('clerkrate',JSON.stringify(Data['CLERKRATE']));
                this.toastr.success('Update successfully');
                this.DialogRef.close();
            } else if (((response.CODE == 451 || response.CODE == 450) || (response2.CODE == 451 || response2.CODE == 450) || (response3.CODE == 451 || response3.CODE == 450)) && (response.STATUS == 'warning' || response2.STATUS == 'warning' || response3.STATUS == 'warning')) {
                this.isspiner = false;
                this.isLoadingResults = false;
                this.toastr.warning(response.MESSAGE);
            } else if (((response.CODE == 451 || response.CODE == 450) || (response2.CODE == 451 || response2.CODE == 450) || (response3.CODE == 451 || response3.CODE == 450)) && (response.STATUS == 'error' || response2.STATUS == 'error' || response3.STATUS == 'error')) {
                this.isspiner = false;
                this.isLoadingResults = false;
                this.toastr.warning(response.MESSAGE);
            } else {
                this.isspiner = false;
                this.isLoadingResults = false;
            }
        })

        // this._mainAPiServiceService.getSetData(data1, 'system').subscribe(response => {
        //     if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        //         this.gotoFinish2();
        //     } else if ((response.CODE == 451 || response.CODE == 450) && response.STATUS == 'warning') {
        //         this.isspiner = false;
        //         this.isLoadingResults = false;
        //         this.toastr.warning(response.MESSAGE);
        //     } else if ((response.CODE == 450 || response.CODE == 451) && response.STATUS == 'error') {
        //         this.isspiner = false;
        //         this.isLoadingResults = false;
        //         this.toastr.warning(response.MESSAGE);
        //     } else {
        //         this.isspiner = false;
        //         this.isLoadingResults = false;
        //     }
        // }), error => {
        //     this.isspiner = false;
        //     this.isLoadingResults = false;
        //     this.toastr.error(error);
        // };
    }

    /**
     * 
     * @returns secomnd api call after first complete for organization details of first time login popup widnow
     */
    gotoFinish2(): Observable<any> {
        let data = {
            "GroupName": "OrganisationalDetails",
            "BARRISTERSNAME": this.signupForm.controls.BARRISTERSNAME.value,
            "ABN": this.signupForm.controls.ABN.value,
            "FAMILYLAWCOURTID": this.signupForm.controls.FAMILYLAWCOURTID.value,
            "LRSBOXNUMBER": this.signupForm.controls.LRSBOXNUMBER.value,
            "ADDRESS1": this.signupForm.controls.ADDRESS1.value,
            "ADDRESS2": this.signupForm.controls.ADDRESS2.value,
            "SUBURB": this.signupForm.controls.SUBURB.value,
            "STATE": this.signupForm.controls.STATE.value,
            "POSTCODE": this.signupForm.controls.POSTCODE.value,
            "POSTALADDRESS1": this.signupForm.controls.POSTALADDRESS1.value,
            "POSTALADDRESS2": this.signupForm.controls.POSTALADDRESS2.value,
            "POSTALSUBURB": this.signupForm.controls.POSTALSUBURB.value,
            "POSTALSTATE": this.signupForm.controls.POSTALSTATE.value,
            "POSTALPOSTCODE": this.signupForm.controls.POSTALPOSTCODE.value,
            "DXNUMBER": this.signupForm.controls.DXNUMBER.value,
            "DXSUBURB": this.signupForm.controls.DXSUBURB.value,
            "PHONE1": this.signupForm.controls.PHONE1.value,
            "PHONE2": this.signupForm.controls.PHONE2.value,
            "FAX1": this.signupForm.controls.FAX1.value,
            "FAX2": this.signupForm.controls.FAX2.value,
            "EMAIL": this.signupForm.controls.EMAIL.value,
            "WWW": this.signupForm.controls.WWW.value
        };
        if (this.currentUSer.PRODUCTTYPE == 'Barrister') {
            data['CHAMBERS'] = this.signupForm.controls.CHAMBERS.value;
        }
        let data1 = { Action: "Update", Data: data }
        return this._mainAPiServiceService.getSetData(data1, 'system').pipe(
            tap(res => console.log("Second api call success")),
            catchError(err => { 
                this.isspiner = false;
                this.isLoadingResults = false;
                this.toastr.error(err);
                return err;
            }),
        );
        // this._mainAPiServiceService.getSetData(data1, 'system').subscribe(response => {
        // if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        //     this.gotoFinish3();
        // } else if ((response.CODE == 451 || response.CODE == 450) && response.STATUS == 'warning') {
        //     this.isspiner = false;
        //     this.isLoadingResults = false;
        //     this.toastr.warning(response.MESSAGE);
        // } else if ((response.CODE == 450 || response.CODE == 451) && response.STATUS == 'error') {
        //     this.isspiner = false;
        //     this.isLoadingResults = false;
        //     this.toastr.warning(response.MESSAGE);
        // } else {
        //     this.isspiner = false;
        //     this.isLoadingResults = false;
        // }
        // }), error => {
        //     this.isspiner = false;
        //     this.isLoadingResults = false;
        //     this.toastr.error(error);
        // };
    }

    /**
     * 
     * @returns third api cakll after second call for integrations data of first time login popup widnow
     */
    gotoFinish3(): Observable<any> {
        let data = {
            "GroupName": "Integrations",
            "SEARCHPROVIDER": this.signupForm.controls.SEARCHPROVIDER.value,
            "SEARCHDOWNLOADUSERGUID": this.signupForm.controls.SEARCHDOWNLOADUSERGUID.value,
            "SEARCHGSTHANDLING": this.signupForm.controls.SEARCHGSTHANDLING.value,
            "SEARCHUSERNAME": this.signupForm.controls.SEARCHUSERNAME.value,
            "SEARCHPASSWORD": this.signupForm.controls.SEARCHPASSWORD.value,
            "SEARCHURL": this.signupForm.controls.SEARCHURL.value,
            "XEROINVOICEACCOUNT": this.signupForm.controls.XEROINVOICEACCOUNT.value,
            "XEROSTARTDATE": this.signupForm.controls.XEROSTARTDATE.value,
            "XERODONTSYNCINVOICES": this.signupForm.controls.XERODONTSYNCINVOICES.value,
            "XERODONTSYNCEXPENSES": this.signupForm.controls.XERODONTSYNCEXPENSES.value,
            "XEROORGANISATION": this.signupForm.controls.XEROORGANISATION.value,
            "PINPAYMENTSAPIKEY": this.signupForm.controls.PINPAYMENTSAPIKEY.value,
            "PINPAYMENTSABSORBFEE": this.signupForm.controls.PINPAYMENTSABSORBFEE.value,
            "SILQPAYLINKDESC": this.signupForm.controls.SILQPAYLINKDESC.value,
            "SILQPAYSHOWINVDETAILS": this.signupForm.controls.SILQPAYSHOWINVDETAILS.value,
            "SILQPAYSHOWCHEQUEPAYMENT": this.signupForm.controls.SILQPAYSHOWCHEQUEPAYMENT.value,
            "SILQPAYSHOWDDPAYMENT": this.signupForm.controls.SILQPAYSHOWDDPAYMENT.value,
            "SILQPAYSHOWINVOICESTATEMENT1": this.signupForm.controls.SILQPAYSHOWINVOICESTATEMENT1.value,
            "SILQPAYSHOWINVOICESTATEMENT2": this.signupForm.controls.SILQPAYSHOWINVOICESTATEMENT2.value,
            "SILQPAYSHOWINVOICESTATEMENT3": this.signupForm.controls.SILQPAYSHOWINVOICESTATEMENT3.value,
            "SILQPAYSHOWFEEAGRSTATEMENT": this.signupForm.controls.SILQPAYSHOWFEEAGRSTATEMENT.value,
            "SILQPAYSHOWGLOBALFOOTER": this.signupForm.controls.SILQPAYSHOWGLOBALFOOTER.value,
            "EMAILSYSTEM": this.signupForm.controls.EMAILSYSTEM.value
          }
          let data1 = { Action: "Update", Data: data }
          return this._mainAPiServiceService.getSetData(data1, 'system').pipe(
            tap(res => console.log("Third api call success")),
            catchError(err => { 
                this.isspiner = false;
                this.isLoadingResults = false;
                this.toastr.error(err);
                return err
            }),
        );
        //   this._mainAPiServiceService.getSetData(data1, 'system').subscribe(response => {
        //     if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        //       this.isspiner = false;
        //       this.isLoadingResults = false;
        //       this.behaviorService.DELIVERABLES$.next(localStorage.getItem('set_active_DELIVERABLES'));
        //       this.currentUSer['DEFAULTMATTERCLASS'] = this.f.DEFAULTMATTERCLASS.value;
        //       this.currentUSer.ONBOARDING.BASICINCOMPLETE = 0;
        //       localStorage.setItem('currentUser', JSON.stringify(this.currentUSer));
        //       this.toastr.success('Update successfully');
        //       this.DialogRef.close();
        //     } else if ((response.CODE == 451 || response.CODE == 450) && response.STATUS == 'warning') {
        //       this.isspiner = false;
        //       this.isLoadingResults = false;
        //       this.toastr.warning(response.MESSAGE);
        //     } else if ((response.CODE == 450 || response.CODE == 451) && response.STATUS == 'error') {
        //       this.isspiner = false;
        //       this.isLoadingResults = false;
        //       this.toastr.warning(response.MESSAGE);
        //     } else {
        //       this.isspiner = false;
        //       this.isLoadingResults = false;
        //     }
        //   }), error => {
        //     this.isspiner = false;
        //     this.isLoadingResults = false;
        //     this.toastr.error(error);
        //   };
    }

    // goToFinish() {
    //     this.isLoadingResults = true;
    //     this.isspiner = true;
    //     const data = {
    //         BARRISTERSNAME: this.f.BARRISTERSNAME.value,
    //         BUSINESSGROUP: {
    //             GSTTYPE: this.f.GSTTYPE.value,
    //             DEFAULTMATTERCLASS: this.f.DEFAULTMATTERCLASS.value,
    //             HOURLYBASERATE: this.f.HOURLYBASERATE.value,
    //             ABN: this.f.ABN.value,
    //         },
    //         ADDRESSGROUP: {
    //             STREETADDRESSGROUP: {
    //                 ADDRESS1: this.f.ADDRESS1.value,
    //                 ADDRESS2: this.f.ADDRESS2.value,
    //                 SUBURB: this.f.SUBURB.value,
    //                 STATE_: this.f.STATE.value,
    //                 POSTCODE: this.f.POSTCODE.value,
    //             }
    //         },
    //         CONTACTGROUP: {
    //             PHONE1: this.f.PHONE1.value,
    //             EMAIL: this.f.EMAIL.value,
    //             WWW: this.f.WWW.value,
    //         },
    //         SEARCHINGGROUP: {
    //             SEARCHUSERNAME: this.f.SEARCHUSERNAME.value,
    //             SEARCHPASSWORD: this.f.SEARCHPASSWORD.value
    //         },

    //         REGIONALGROUP: {
    //             TIMEZONE: this.f.TIMEZONE.value
    //         }

    //         //DUEDATE:this.f.DUEDATE.value ,




    //     }
    //     if (this.currentUSer.PRODUCTTYPE == 'Barrister') {
    //         delete data.SEARCHINGGROUP
    //     }

    //     let data1 = { FormAction: "update", VALIDATEONLY: true, Data: data }
    //     this.sub1 = this._mainAPiServiceService.getSetData(data1, 'SetSystem').subscribe(response => {
    //         if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
    //             this.isspiner = true;
    //             this.isLoadingResults = true
    //             this.checkValidation(response.DATA.VALIDATIONS, data1);
    //             this.behaviorService.DELIVERABLES$.next(localStorage.getItem('set_active_DELIVERABLES'));
    //         } else if ((response.CODE == 451 || response.CODE == 450) && response.STATUS == 'warning') {
    //             this.isLoadingResults = true
    //             this.checkValidation(response.DATA.VALIDATIONS, data1);
    //         } else if ((response.CODE == 450 || response.CODE == 451) && response.STATUS == 'error') {
    //             this.isLoadingResults = true
    //             this.checkValidation(response.DATA.VALIDATIONS, data1);
    //         }
    //     }), error => {
    //         this.isspiner = false;
    //         this.isLoadingResults = true
    //         this.toastr.error(error);
    //     };

    // }

    /**
     * This function is used to check the validation data
     */
    checkValidation(bodyData: any, data1: any, ApiName?: any) {
        this.isspiner = true;
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No' || value.VALUEVALID == 'no') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning' || value.VALUEVALID == 'warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.isspiner = false;
            this.isLoadingResults = false
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length != 0) {
            this.isspiner = false;
            this.isLoadingResults = false
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true, width: '100%', data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isspiner = true;
                    this.isLoadingResults = false
                    // this.saveSettingData(data1);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            // this.saveSettingData(data1);

        } else if (Object.keys(warningData).length != 0) {
            localStorage.setItem('confEWshow', 'warning');
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = (ApiName === 'SetInvoice') ? '' : warningData;
            this.confirmDialogRef.afterClosed().subscribe(result => {
                localStorage.removeItem('confEWshow');
                if (result) {
                    // this.DeleteGData(data1, ApiName);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            // this.DeleteGData(data1, ApiName);
        }
    }

    // saveSettingData(data1: any) {
    //     this.isspiner = true;
    //     data1.VALIDATEONLY = false;
    //     this._mainAPiServiceService.getSetData(data1, 'SetSystem').subscribe(response => {
    //         if ((response.CODE == 200) && (response.STATUS == "OK" || response.STATUS == "success")) {
    //             this.currentUSer['DEFAULTMATTERCLASS'] = this.f.DEFAULTMATTERCLASS.value;
    //             this.currentUSer.ONBOARDING.BASICINCOMPLETE = 0;
    //             localStorage.setItem('currentUser', JSON.stringify(this.currentUSer));
    //             this.toastr.success('Update successfully');
    //             this.isspiner = false;
    //             this.isLoadingResults = false
    //             this.DialogRef.close();
    //         } else if (response.CODE == 451 && response.STATUS == 'warning') {
    //             this.isspiner = false;
    //             this.isLoadingResults = false
    //             this.toastr.warning(response.MESSAGE);
    //         } else if (response.CODE == 450 && response.STATUS == 'error') {
    //             this.isspiner = false;
    //             this.isLoadingResults = false
    //             this.toastr.error(response.MESSAGE);
    //         } else {
    //             this.isspiner = false;
    //             this.isLoadingResults = false
    //         }
    //         this.behaviorService.onChange$.emit(response);
    //     }, error => {
    //         this.isspiner = false;
    //         this.isLoadingResults = false
    //         this.toastr.error(error);
    //     });
    // }

    /**
     * This function is used to open the New Matter
     */
    AfterViewInitNewMatter() {
        let tempError: any = [];
        let tempWarning: any = [];
        //  tempWarning['FEEAGREEMENTDATE'] = {};
        // tempWarning['RATEPERHOUR'] = {};
        // tempWarning['RATEPERDAY'] = {};

        //  tempError['MATTER'] = {};
        tempError['BARRISTERSNAME'] = {};
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
    }
    errorWarningDataArray: any = {}

    /**
     * This function is used to change the form control
     */
    changeForControl(formControl) {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 }
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
        // this.errorWarningData = {};
    }

}
