<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>

<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{title}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.TOPICNAME,'has-warning':errorWarningData.Warning?.TOPICNAME}">
                        <mat-label>Topic Name</mat-label>
                        <input [(ngModel)]="TopicDialoge.TOPICNAME" name="TOPICNAME" matInput>
                        <!-- <mat-icon matSuffix class="secondary-text">account_circle</mat-icon> -->
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}"
                        appearance="outline" fxFlex="50" class=" pr-4">
                        <mat-select [(ngModel)]="TopicDialoge.selective" name="selective"
                            (selectionChange)="TopicClassChange($event.value)">
                            <mat-option value="1">is a head topic</mat-option>
                            <mat-option value="2">is sub topic of</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="ShowParent=='yes'" appearance="outline" fxFlex="50" class=" pr-4">
                        <mat-label>Parent</mat-label>
                        <mat-select
                            [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}"
                            [(ngModel)]="TopicDialoge.Parent" name="Parent"
                            (selectionChange)="TopicNameChange($event.value)">
                            <mat-option *ngFor="let val of MainTopicClass" [value]="val.TOPICNAME">{{val.TOPICNAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button *ngIf="action !='edit'" mat-raised-button color="accent" [disabled]="isspiner" (click)="saveTopic()"
            cdkFocusInitial>
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                    margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button *ngIf="action =='edit'" mat-raised-button color="accent" [disabled]="isspiner" (click)="saveTopic()"
            cdkFocusInitial>
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
        margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>