<!-- [formGroup]="matterdetailForm" -->
<ng-container *ngIf="matterClassData$ | async "></ng-container>
<div class=" one " fxLayout="column" [formGroup]="matterdetailForm">
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.FirmGuid,'has-warning':errorWarningData.Warning?.FirmGuid}">
            <mat-label>{{tabTitle}}</mat-label>
            <input matInput formControlName="Clientmattertext">
            <mat-icon matSuffix (click)='ContactMatter()'>
                <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Twitter" matTooltipPosition="above">
            </mat-icon>
        </mat-form-field>
    </div> -->
    <div fxFlex="100">
        Correspondents and Parties
        <button class="mr-4" mat-raised-button color="accent" (click)="Addcorres_party()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Add Corres./Party
        </button>
        <!-- <button *ngIf="isEdit" class="mr-4" mat-raised-button color="accent" (click)="SelectElement('Up')"
            [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Up
        </button>
        <button *ngIf="isEdit" mat-raised-button color="accent" (click)="SelectElement('Down')" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Down
        </button> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-card>
            <div class="customer mb-32" *ngIf="isEdit">
                <table class="simple font-size-12"  #table cdkDropList (cdkDropListDropped)="onDrop($event)">
                    <thead>
                        <tr class="mb-2">
                           
                            <th>Contact Name</th>
                            <th> Solicitor Name</th>
                            <th>Order No</th>
                            <th *ngIf="action!='duplicate'">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <!-- <thead>
                        <tr class="mb-2">
                            <th>Contact Type</th>
                            <th>Order No</th>
                            <th>Contact Name</th>
                            <th>
                                Solicitor Name
                            </th>
                            <th *ngIf="action!='duplicate'">
                                Action
                            </th>
                        </tr>
                    </thead> -->
                    <!-- <tbody> -->
                    <ng-container *ngFor="let item of CorrespondEdit; let i = index">
                        <ng-container *ngIf="i === 0 || CorrespondEdit[i].TYPENAME !== CorrespondEdit[i - 1].TYPENAME">
                            <tr class="tablerow-heading font-weight-700 " >
                                <div class="mb-2" >{{CorrespondEdit[i].TYPENAME == item.TYPENAME?item.TYPENAME:''}}</div>
                            </tr>
                        </ng-container>

                        <tr  cdkDrag  (cdkDragStarted)="onDragStart(item)" (click)="highlightedRows=item.MATTERCONTACTGUID"
                            [style.background]="highlightedRows == item.MATTERCONTACTGUID ? selectedColore : ''"
                            [ngClass]="highlightedRows == item.MATTERCONTACTGUID ? 'row-text-colore' : ''"
                            >
                              <td ><mat-icon class="menu-icon dragCursor" >menu</mat-icon>{{item.CONTACTNAME}}</td>
                              <td>
                                  <span *ngIf="item?.SOLICITORNAME">{{item?.SOLICITORNAME}}</span>
                              </td>
                            <td>{{item.ORDER}}</td>
                         

                            <td *ngIf="action!='duplicate'">
                                <mat-icon (click)="editElement(item)" style="cursor: pointer;" class="pr-4">edit</mat-icon>
                                <mat-icon (click)="deleteElement(item)" style="cursor: pointer;" class="pr-4">delete
                                </mat-icon>
                            </td>
                        </tr>
                    </ng-container>
                    <!-- </tbody> -->
                    <!-- <tr *ngFor="let item of CorrespondEdit" (click)="highlightedRows=item.MATTERCONTACTGUID"
                        [style.background]="highlightedRows == item.MATTERCONTACTGUID ? selectedColore : ''"
                        [ngClass]="highlightedRows == item.MATTERCONTACTGUID ? 'row-text-colore' : ''">
                        <td>{{item.TYPENAME}}</td>
                        <td>{{item.ORDER}}</td>
                        <td>{{item.CONTACTNAME}}</td>
                        <td>
                            <span *ngIf="item?.SOLICITORNAME">{{item?.SOLICITORNAME}}</span>
                        </td>

                        <td *ngIf="action!='duplicate'">
                            <mat-icon (click)="editElement(item)" style="cursor: pointer;" class="pr-4">edit</mat-icon>
                            <mat-icon (click)="deleteElement(item)" style="cursor: pointer;" class="pr-4">delete
                            </mat-icon>
                        </td>
                    </tr> -->
                </table>
            </div>
            <div class="customer mb-32" *ngIf="!isEdit">
                <table class="simple">
                    <thead>
                        <tr class="mb-2">
                            <th>Contact Type</th>
                            <th>Order No</th>
                            <th>Contact Name</th>
                            <th>
                                Solicitor Name
                            </th>
                            <!-- *ngIf="action!='duplicate'" -->
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tr *ngFor="let item of Correspond;let i = index" (click)="highlightedNewcorrespondRows=i"
                        [style.background]="highlightedNewcorrespondRows == i ? selectedColore : ''"
                        [ngClass]="highlightedNewcorrespondRows == i ? 'row-text-colore' : ''">
                        <td>{{item.type}}</td>
                        <td>{{item?.ORDER}}</td>
                        <td>{{item.Text}}</td>
                        <td>
                            <span *ngIf="item?.SOLICITORNAME">{{item?.SOLICITORNAME}}</span>
                        </td>
                        <!-- *ngIf="action !='duplicate'" -->
                        <td >
                            <mat-icon (click)="editElement(item.ItemData, i,item.type)" style="cursor: pointer;"
                                class="pr-4">edit</mat-icon>
                            <mat-icon (click)="removeNewCorrespond(i)" style="cursor: pointer;" class="pr-4">delete
                            </mat-icon>
                        </td>
                    </tr>
                    <br>
                </table>
            </div>
        </mat-card>
    </div>
</div>





<div class=" one " *ngIf="'' | productType" fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FIRMROLE,'has-warning':errorWarningData.Warning?.FIRMROLE}">
            <mat-label>Acting on behalf of</mat-label>
            <mat-select name="FIRMROLE" formControlName="FIRMROLE" matTooltip="{{(toolTipList)?toolTipList.FIRMROLE?.HOVER:''}}">
                <mat-option *ngFor="let roleoption of ContactRoleData" value="{{roleoption.LOOKUPFULLVALUE}}">
                    {{roleoption.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
            <div  *ngIf="testBool && toolTipList.FIRMROLE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIRMROLE?.COPYVALUE:'')" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FIRMROLE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field class="example pr-4" fxFlex="50" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.OTHERSIDEROLE,'has-warning':errorWarningData.Warning?.OTHERSIDEROLE}">
            <mat-label>Acting against</mat-label>
            <mat-select name="OTHERSIDEROLE" formControlName="OTHERSIDEROLE" matTooltip="{{(toolTipList)?toolTipList.OTHERSIDEROLE?.HOVER:''}}">
                <mat-option *ngFor="let roleoption of ContactRoleData" value="{{roleoption.LOOKUPFULLVALUE}}">
                    {{roleoption.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
            <div  *ngIf="testBool && toolTipList.OTHERSIDEROLE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OTHERSIDEROLE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERSIDEROLE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
        <mat-checkbox class="example pr-4  pl-4" formControlName="FIRMDONTSHOWADDRESS" fxFlex="100">
            Don't Use Client Address
        </mat-checkbox>
        <!-- <mat-icon class="mr-8 cursor-pointer" (click)="tooltipOpen('clinet address')">help_outline</mat-icon> -->
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.RELINECLIENT,'has-warning':errorWarningData.Warning?.RELINECLIENT}">
            <mat-label>"Re" Line when writing to client</mat-label>
            <textarea matInput formControlName="RELINECLIENT" matTooltip="{{(toolTipList)?toolTipList.RELINECLIENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
            <div  *ngIf="testBool && toolTipList?.RELINECLIENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RELINECLIENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RELINECLIENT.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINECLIENTCLIENTASPREFIX" fxFlex="50">
            Add Client As Prefix
        </mat-checkbox>
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINECLIENTOTHERSIDEASSUFFIX" fxFlex="50">
            Add Other Side As Suffix
        </mat-checkbox>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.RELINEOTHERPARTIES,'has-warning':errorWarningData.Warning?.RELINEOTHERPARTIES}">
            <mat-label>"Re" when writing to other parties</mat-label>
            <textarea matInput formControlName="RELINEOTHERPARTIES" matTooltip="{{(toolTipList)?toolTipList.RELINEOTHERPARTIES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
            <div  *ngIf="testBool && toolTipList.RELINEOTHERPARTIES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RELINEOTHERPARTIES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RELINEOTHERPARTIES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINEOTHERPARTIESCLIENTASPREFIX" fxFlex="50">
            Add Client As Prefix
        </mat-checkbox>
        <mat-checkbox class="example pr-4  pl-4" formControlName="RELINEOTHERPARTIESOTHERSIDEASSUFFIX" fxFlex="50">
            Add Other Side As Suffix
        </mat-checkbox>
    </div>
</div>
