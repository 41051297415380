import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { LogoutErrorComponent } from "src/app/main/logout-error/logout-error.component";
import { AuthenticationService } from "@_services/authentication.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    dialogRef: any;
    constructor(
        private authenticationService: AuthenticationService,
        public toasterService: ToastrService,
        private router: Router,
        public dialog: MatDialog
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(async (evt) => {
                if (evt instanceof HttpResponse) {
                    if (
                        evt.body.CODE == 200 &&
                        (evt.body.STATUS == "success" ||
                            evt.body.RESPONSE == "success" ||
                            evt.body.STATUS == "OK")
                    ) {
                        return true;
                    } else if (
                        evt.body.CODE == 200 &&
                        evt.body.STATUS != "success"
                    ) {
                        this.toasterService.error(evt.body.STATUS);
                    } else if (
                        evt.body.CODE == 406 &&
                        evt.body.MESSAGE == "Permission denied"
                    ) {
                        // this.behaviorService.setPermission(true);
                    } else if (
                        evt.body.CODE >= 400 &&
                        evt.body.CODE < 499 &&
                        (evt.body.STATUS == "error" ||
                            evt.body.RESPONSE == "error")
                    ) {
                        if (
                            evt.body &&
                            evt.body.MESSAGE &&
                            evt.body.MESSAGE != "" &&
                            evt.body.STATUS != "success" &&
                            evt.body.CODE != 450 &&
                            evt.body.CODE != 444 &&
                            evt.body.CODE != "450" &&
                            evt.body.CODE != 451 &&
                            evt.body.CODE != 402 &&
                            evt.body.CODE != 490 &&
                            evt.body.CODE != 403
                        ) {
                            this.toasterService.error(evt.body.MESSAGE);
                        }
                        if (evt.body.MESSAGE == "Not logged in") {
                            this.authenticationService.ForcLogout();
                        } else if (
                            evt.body.CODE == 490 ||
                            evt.body.CODE == "490" ||
                            evt.body.CODE == 403
                        ) {
                            const url = await this.router.url;
                            window.active = await true;
                            await this.authenticationService.clearLocal();
                            await localStorage.setItem(
                                "logoutmessage",
                                JSON.stringify(evt.body)
                            );
                            //this.dialog.closeAll();
                            // setTimeout(() => {
                            if (!this.dialogRef) {
                                this.dialogRef = this.dialog.open(
                                    LogoutErrorComponent,
                                    {
                                        disableClose: true,
                                        width: "100%",
                                        data: evt.body,
                                    }
                                );
                                this.dialogRef
                                    .afterClosed()
                                    .subscribe((result) => {
                                        // if (result) {
                                        //     this.dialogRef = null;
                                        // } else {
                                        //     this.dialogRef = null;
                                        //     this.authenticationService.ForcLogout();
                                        // }

                                        if (result == 0) {
                                            const currentUser = JSON.parse(
                                                localStorage.getItem(
                                                    "currentUser"
                                                )
                                            );
                                            const token =
                                                localStorage.getItem(
                                                    "session_token"
                                                );
                                            if (currentUser && token) {
                                                this.dialogRef = null;
                                                window.location.reload();
                                            } else {
                                                this.router.navigate(
                                                    ["login"],
                                                    {
                                                        queryParams: {
                                                            returnUrl: url,
                                                        },
                                                    }
                                                );
                                            }
                                        } else if (result == 1) {
                                            this.router.navigate(["login"], {
                                                queryParams: { returnUrl: url },
                                            });
                                        } else {
                                            this.dialogRef = null;
                                        }
                                    });
                            }
                            // }, 1200);
                        } else {
                            if (
                                evt.body.MESSAGE !=
                                "Check the Validation Section for Errors/Warnings." &&
                                evt.body.MESSAGE != ""
                            ) {
                                // if((evt.body.CODE == 436 || evt.body.CODE == '436') && evt.body.MESSAGE == "No Session Token"){
                                //     if (!this.dialogRef) {
                                //         const url =  await this.router.url;
                                //         window.active = await true;
                                //         await this.authenticationService.clearLocal();
                                //         await localStorage.setItem('logoutmessage',JSON.stringify(evt.body));
                                //         this.dialogRef = this.dialog.open(LogoutErrorComponent, {
                                //             disableClose: true, width: '100%', data: evt.body,
                                //         });
                                //         this.dialogRef.afterClosed().subscribe(result => {
                                //             // if (result) {
                                //             //     this.dialogRef = null;
                                //             // } else {
                                //             //     this.dialogRef = null;
                                //             //     this.authenticationService.ForcLogout();
                                //             // }
                                //             if (result == 0) {
                                //                 const currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                //                 const token = (localStorage.getItem('session_token'))
                                //                 if (currentUser && token) {
                                //                     this.dialogRef = null;
                                //                     window.location.reload();
                                //                 } else {
                                //                     this.router.navigate(['login'], { queryParams:{returnUrl:url}});
                                //                 }
                                //             } else if(result == 1){
                                //                 this.router.navigate(['login'], { queryParams:{returnUrl:url}});
                                //             } else {
                                //                 this.dialogRef = null;
                                //             }
                                //         });
                                //     }
                                // }else{
                                //this.toasterService.error(evt.body.MESSAGE);
                                // }
                            }
                        }
                    } else if (evt.body.MESSAGE == "Not logged in") {
                        this.authenticationService.ForcLogout();
                    }
                }
            }),
            catchError((err) => {
                if (err.status === 400) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    this.router.navigate(["login"]);
                }
                const error = err.error.message || err.statusText;
                // if (error == 'Unknown Error') {
                //     this.router.navigate(['server-error']);
                // }
                return throwError(
                    "Please hang on a moment, we are working on this and your session will resume in a few minutes. If it does not resume within 5 minutes, please contact SILQ on 1300 55 66 89."
                );
            })
        );
    }
}
