<div class="sort_main_sidebar">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
        <!-- Safe Custody Moves Details -> Movement details -->
        <h3 class="one"><strong class="x">Movement Details</strong></h3>
    </div>
    <!-- / SIDEBAR HEADER -->
    <!-- SIDEBAR CONTENT -->
    <div class="two">
        <div class="content" fusePerfectScrollbar>
            <div class="file-details" [@animate]="{value:'*',params:{delay:'200ms'}}">
                <table>
                    <tr *ngIf="isLoadingResults">
                        <td colspan="2" align="center">
                            <mat-spinner></mat-spinner>
                        </td>
                    </tr>
                </table>
                <span *ngFor="let item of safecustodydetails;">
                    <table>
                        <tr class="matter_num">
                            <th>Date</th>
                            <td>{{item?.MOVEMENTDATE}}</td>
                        </tr>
                        <tr class="matter_num">
                            <th>Move Type </th>
                            <td>{{item?.MOVEMENTTYPE}}</td>
                        </tr>
                        <tr class="matter_num">
                            <th>Name </th>
                            <td>{{item?.CONTACTNAME}}</td>
                        </tr>
                        
                        <tr class="matter_num">
                            <th>Reason</th>
                            <td>{{item?.REASON}}</td>
                        </tr>
                    </table>
                    <mat-divider></mat-divider>
                </span>
            </div>

        </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>