import { BehaviorService } from '@_services/Behavior.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-estate-details',
  templateUrl: './estate-details.component.html',
  styleUrls: ['./estate-details.component.scss']
})
export class EstateDetailsComponent implements OnInit, OnDestroy {
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  dialogTitle: any;
  isLoadingResults: boolean = false;
  action='edit';
  moduleType='legalEstate';
  isspiner: boolean = false;
  confirmDialogRef: any;
  curentUser: any;
  currentMatter:any;
  windowNameId:any;
  sub: Subscription;
  constructor( private behaviorService:BehaviorService ) { 
    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.currentMatter = result;
    //   } else {
    //     this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
    //   }
    // });
    
    this.sub = this.behaviorService.MatterData$.subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
      this.currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      if (this.currentMatter) {
        // localStorage.setItem('set_active_matters', JSON.stringify(this.currentMatter));
      } else {
        if (result) {
          this.currentMatter = result;
          localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        } else {
            this.currentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
            localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
        }
      }
    });

    this.behaviorService.setChildTableData({ MATTERGUID: this.currentMatter.MATTERGUID, Action: 'GetData', CONTACTGUID: this.currentMatter.CONTACTGUID });

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  /**
   * This function is used to submit the data value
   */
  OnSubmit():void{
    this.isspiner = true;
    this.behaviorService.setChildTableData({ MATTERGUID: this.currentMatter.MATTERGUID, 'classType': this.currentMatter.MATTERCLASS, action: 'setData', CONTACTGUID: this.currentMatter.CONTACTGUID });
  }

}
