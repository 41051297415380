import { ImageSettingsModel, QuickToolbarSettingsModel } from "@syncfusion/ej2-angular-richtexteditor";

// const maxLength = 2000;
export const iFRAME: object = { enable: true };

export const HEIGHT: number = 500;

export const INSERTImageSettings: ImageSettingsModel = {
    saveFormat: "Base64",
};

// const insertImageSettings: ImageSettingsModel = { allowedTypes: ['.jpeg', '.jpg', '.png'], display: 'inline', width: 'auto', height: 'auto', saveFormat: "Base64", saveUrl: null, path: null, }

 export const PASTECleanupSettings: object = {
    prompt: false,
    plainText: false,
     keepFormat: true,
     plainTextFilter: false,
    deniedTags: ['a'],
    deniedAttrs: ['class', 'title', 'id'],
    allowedStyleProps: ['color', 'margin', 'font-size']
};

export const QUICKToolbarSettings: QuickToolbarSettingsModel = {
    image: [
        'Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-',
        'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension',
        {
            tooltipText: 'Rotate Left',
            template: '<button class="e-tbar-btn e-btn" id="roatateLeft"><span class="e-btn-icon e-icons e-rotate-left"></span>'
        },
        {
            tooltipText: 'Rotate Right',
            template: '<button class="e-tbar-btn e-btn" id="roatateRight"><span class="e-btn-icon e-icons e-rotate-right"></span>'
        }
    ],
    table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
};

export const TOOLbarSettings: object = {
    items: ['Undo', 'Redo', '|', 'FileManager', 'Video', 'Audio', 'CreateTable',
        'FormatPainter', 'ClearFormat', 'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'SubScript', 'SuperScript', '|',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink',
        'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
};

export const backgroundColor:object={
 mode: 'Picker'
};

export const fontColor:object={
    mode: 'Picker'
   };

export const fontFamily: Object = {
    items: [
      { "text": "Arial", "value": "Arial" },
  { "text": "Arial Black", "value": "Arial Black" },
  { "text": "Arial MT Condensed Light", "value": "Arial MT Condensed Light" },
  { "text": "Arial Narrow", "value": "Arial Narrow" },
  { "text": "Book Antiqua", "value": "Book Antiqua" },
  { "text": "Calibri", "value": "Calibri" },
  { "text": "Cambria", "value": "Cambria" },
  { "text": "Candara", "value": "Candara" },
  { "text": "Century Gothic", "value": "Century Gothic" },
  { "text": "Comic Sans MS", "value": "Comic Sans MS" },
  { "text": "Consolas", "value": "Consolas" },
  { "text": "Constantia", "value": "Constantia" },
  { "text": "Corbel", "value": "Corbel" },
  { "text": "Courier New", "value": "Courier New" },
  { "text": "Franklin Gothic Medium", "value": "Franklin Gothic Medium" },
  { "text": "French Script MT", "value": "French Script MT" },
  { "text": "Garamond", "value": "Garamond" },
  { "text": "Haettenschweiler", "value": "Haettenschweiler" },
  { "text": "Helvetica", "value": "Helvetica" },
  { "text": "Impact", "value": "Impact" },
  { "text": "Lucida Console", "value": "Lucida Console" },
  { "text": "Lucida Grande", "value": "Lucida Grande" },
  { "text": "Lucida Sans Unicode", "value": "Lucida Sans Unicode" },
  { "text": "Palatino Linotype", "value": "Palatino Linotype" },
  { "text": "Papyrus", "value": "Papyrus" },
  { "text": "Segoe Print", "value": "Segoe Print" },
  { "text": "Segoe UI", "value": "Segoe UI" },
  { "text": "Sylfaen", "value": "Sylfaen" },
  { "text": "Termina", "value": "Termina" },
  { "text": "Tahoma", "value": "Tahoma" },
  { "text": "Times New Roman", "value": "Times New Roman" },
  { "text": "Trebuchet MS", "value": "Trebuchet MS" },
  { "text": "Verdana", "value": "Verdana" }
    ]
  };


// NOTE:::

// import { HEIGHT, PASTECleanupSettings, iFRAME, QUICKToolbarSettings, TOOLbarSettings, INSERTImageSettings } from 'src/app/_constant/rich-text-editor-toolbarFeature';

    // <ejs-richtexteditor
    //         [insertImageSettings] = 'insertImageSettings'
    //         [quickToolbarSettings] = 'quickToolbarSettings'
    //         [toolbarSettings] = 'toolbarSettings'
    //         [pasteCleanupSettings] = "pasteCleanupSettings"
    //             [(ngModel)] = "ckeditorValue"  fxFlex = "100" name = "ckeditorValue" class="ckeClass" #ckeditor_full_desc(ready) = "onReadyDefultSign($event)" >
    // </ejs-richtexteditor>

// iframe: any = iFRAME;
// height: any = HEIGHT;
// insertImageSettings: any = INSERTImageSettings;
// pasteCleanupSettings: any = PASTECleanupSettings;
// quickToolbarSettings: any = QUICKToolbarSettings;
// toolbarSettings: any = TOOLbarSettings;
