
<div class="example-loading-shade" *ngIf="isspiner">
    <mat-spinner [style.zoom]="0.5" *ngIf="isspiner"></mat-spinner>
</div>
<div class="p-20">
    <div class="cardblockwrap">
        <div class="cardblock-grid">
            <!--START Data -->
            <form class="w-50" [formGroup]="PreferenceForm">
                <mat-card>
                    <mat-card-header class="margin-x-header-0">
                        <mat-card-title>Data</mat-card-title>
                    </mat-card-header>
                    <div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Sort by Surname
                                </h6>
                                <span class="line-clamp3 d-block">Contact names will be sorted by Surname, otherwise it will sort by their full name</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SORTBYSURNAME"
                                (change)="checkboxClick('SORTBYSURNAME')"></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Sort Matter Alpha Numerically
                                </h6>
                                <span class="line-clamp3 d-block">The Matter
                                    number will be sorted using alphabetic
                                    sorting not numeric sorting</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SORTMATTERALPHANUMERICALLY"
                                (change)="
                                    checkboxClick('SORTMATTERALPHANUMERICALLY')
                                "></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Sort Packets Alpha Numerically
                                </h6>
                                <span class="line-clamp3 d-block">The Packet
                                    Number will be sorted using alphabetic
                                    sorting not numeric sorting</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SORTPACKETSALPHANUMERICALLY"
                                (change)="
                                    checkboxClick('SORTPACKETSALPHANUMERICALLY')
                                "></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Load All Users' Matters
                                </h6>
                                <span class="line-clamp3 d-block">If off, you
                                    will only see matters where you
                                    are the Primary Fee Earner
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="LOADALLUSERSMATTERS"
                                (change)="checkboxClick('LOADALLUSERSMATTERS')"></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Always Popup Select Matter window first
                                </h6>
                                <span class="line-clamp3 d-block">It will show
                                    the select matter window automatically, if
                                    it is off, you will need to type in the
                                    matter number to search</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="POPUPSELECTMATTERFIRST"
                                (change)="checkboxClick('POPUPSELECTMATTERFIRST')"></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Pre-fill time of the Day
                                </h6>
                                <span class="line-clamp3 d-block">When entering
                                    time-entries, time of day will be
                                    automatically pre-filled
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="PREFILLTIMEOFDAY"
                                (change)="checkboxClick('PREFILLTIMEOFDAY')"></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Show Disbursements automatically
                                </h6>
                                <span class="line-clamp3 d-block">In the matter
                                    details window is opened, automatically
                                    display disbursements in the Work In
                                    Progress tab
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SHOWDISBURSEMENTS"
                                (change)="checkboxClick('SHOWDISBURSEMENTS')"></mat-slide-toggle>
                        </div>

                        <div *ngIf="isSolacitor =='Solicitor'"
                        class="display-flex justify-content-between block-boxwrap">
                        <div>
                            <h6
                                class="font-size-12 font-weight-800 mt-0 mb-4">
                                Show Inactive Fee Earners in lists
                            </h6>
                            <span class="line-clamp3 d-block">When running reports, or in the matters window, include inactive users in the list
                            </span>
                        </div>
                        <mat-slide-toggle class="flex-shrink-0"
                            formControlName="SHOWINACTIVEFEEEARNERS"
                            (change)="checkboxClick('SHOWINACTIVEFEEEARNERS')"></mat-slide-toggle>
                    </div>
                    </div>
                </mat-card>
            </form>
            <!--END Data -->
            <!--START Behaviour -->
            <form class="w-50" [formGroup]="PreferenceForm">
                <mat-card>
                    <mat-card-header class="margin-x-header-0">
                        <mat-card-title>Behaviour</mat-card-title>
                    </mat-card-header>
                    <div>
                        <ng-container *ngIf="isSolacitor == 'Solicitor'">
                            <div class="display-flex justify-content-between block-boxwrap">
                                <div>
                                    <h6
                                        class="font-size-12 font-weight-800 mt-0 mb-4">Default
                                        Fee Earner</h6>
                                    <span class="line-clamp3 d-block">When entering
                                        time entries & viewing matters. Especially
                                        helpful if you are not the Fee Earner but
                                        entering time for others</span>
                                </div>
                                <mat-form-field class="example" fxFlex="100"
                                    appearance="outline"
                                    class="rightdropdown flex-shrink-0">
                                    <mat-label>Default Fee Earner</mat-label>
                                    <mat-select
                                        (selectionChange)="changeSelectWindow('WORKINGONBEHALFOFGUID')"
                                        name='WORKINGONBEHALFOFGUID'
                                        formControlName="WORKINGONBEHALFOFGUID">
                                        <!-- <mat-option value="all" checked>All</mat-option> -->
                                        <mat-option value checked>None</mat-option>
                                        <mat-option
                                            *ngFor="let MatterDrop of MatterDropData"
                                            value="{{MatterDrop.USERGUID}}">
                                            {{MatterDrop.USERID}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div
                                class="display-flex justify-content-between block-boxwrap">
                                <div>
                                    <h6
                                        class="font-size-12 font-weight-800 mt-0 mb-4">Default Matter Owner</h6>
                                    <span class="line-clamp3 d-block">Default Matter Owner when creating a new Matter</span>
                                </div>
                                <mat-form-field class="example" fxFlex="100"
                                    appearance="outline"
                                    class="rightdropdown flex-shrink-0">
                                    <mat-label>Default Matter Owner</mat-label>
                                    <mat-select
                                        (selectionChange)="changeSelectWindow('DEFAULTOWNERGUID')"
                                        name='DEFAULTOWNERGUID'
                                        formControlName="DEFAULTOWNERGUID">
                                        <!-- <mat-option value="all" checked>All</mat-option> -->
                                        <mat-option value checked>None</mat-option>
                                        <mat-option
                                            *ngFor="let MatterDrop of MatterDropData"
                                            value="{{MatterDrop.USERGUID}}">
                                            {{MatterDrop.USERID}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div
                                class="display-flex justify-content-between block-boxwrap">
                                <div>
                                    <h6
                                        class="font-size-12 font-weight-800 mt-0 mb-4">Default Primary Fee Earner</h6>
                                    <span class="line-clamp3 d-block">Default Primary Fee Earner when creating a new Matter</span>
                                </div>
                                <mat-form-field class="example" fxFlex="100"
                                    appearance="outline"
                                    class="rightdropdown flex-shrink-0">
                                    <mat-label>Default Primary Fee Earner</mat-label>
                                    <mat-select
                                        (selectionChange)="changeSelectWindow('DEFAULTPRIMARYFEEEARNERGUID')"
                                        name='DEFAULTPRIMARYFEEEARNERGUID'
                                        formControlName="DEFAULTPRIMARYFEEEARNERGUID">
                                        <!-- <mat-option value="all" checked>All</mat-option> -->
                                        <mat-option value checked>None</mat-option>
                                        <mat-option
                                            *ngFor="let MatterDrop of MatterDropData"
                                            value="{{MatterDrop.USERGUID}}">
                                            {{MatterDrop.USERID}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div
                                class="display-flex justify-content-between block-boxwrap">
                                <div>
                                    <h6
                                        class="font-size-12 font-weight-800 mt-0 mb-4">Default Person Assisting</h6>
                                    <span class="line-clamp3 d-block">Default Person Assisting when creating a new Matter </span>
                                </div>
                                <mat-form-field class="example" fxFlex="100"
                                    appearance="outline"
                                    class="rightdropdown flex-shrink-0">
                                    <mat-label>Default Person Assisting</mat-label>
                                    <mat-select
                                        (selectionChange)="changeSelectWindow('DEFAULTASSISTANTGUID')"
                                        name='DEFAULTASSISTANTGUID'
                                        formControlName="DEFAULTASSISTANTGUID">
                                        <!-- <mat-option value="all" checked>All</mat-option> -->
                                        <mat-option value checked>None</mat-option>
                                        <mat-option
                                            *ngFor="let MatterDrop of MatterDropData"
                                            value="{{MatterDrop.USERGUID}}">
                                            {{MatterDrop.USERID}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">Initial
                                    Window</h6>
                                <span class="line-clamp3 d-block">Choose which
                                    window you want to display first when you
                                    first log in</span>
                            </div>
                            <mat-form-field class="example" fxFlex="100"
                                appearance="outline"
                                class="rightdropdown flex-shrink-0">
                                <mat-label>Initial Window</mat-label>
                                <mat-select
                                    (selectionChange)="changeSelectWindow('INITIALWINDOW')"
                                    name='INITIALWINDOW'
                                    formControlName="INITIALWINDOW">
                                    <mat-option
                                        *ngFor="let Initial of InitialWindow"
                                        [value]="Initial"> {{Initial}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">Default
                                    Quantity Type</h6>
                                <span class="line-clamp3 d-block">When entering
                                    time entries E.g : Units, Hours etc.</span>
                            </div>
                            <mat-form-field class="example" fxFlex="100"
                                appearance="outline"
                                class="rightdropdown flex-shrink-0">
                                <mat-label>Default Quantity Type</mat-label>
                                <mat-select
                                    (selectionChange)="changeSelectWindow('DEFAULTQUANTITYTYPE')"
                                    name='DEFAULTQUANTITYTYPE'
                                    formControlName="DEFAULTQUANTITYTYPE">
                                    <mat-option *ngFor="let qty of quantType"
                                        [value]="qty.value">{{qty.Name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Open Windows in new Tabs
                                </h6>
                                <span class="line-clamp3 d-block">To speed up
                                    SILQ turn this on so each window Eg :
                                    invoices, matters does not need to reload
                                    the data as you move around the system
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="OPENINNEWTAB"
                                (change)="checkboxClick('OPENINNEWTAB')"></mat-slide-toggle>
                        </div>

                        <div class="block-boxwrap">
                            <div class="display-flex justify-content-between">
                                <div>
                                    <h6 class="font-size-12 font-weight-800 mt-0 mb-4">
                                        Email Editing Options
                                    </h6>
                                    <span class="line-clamp3 d-block">Show CC when sending emails</span>
                                </div>
                                <mat-slide-toggle class="flex-shrink-0" formControlName="SHOWEMAILCC" style="margin-top: 15px !important;" (change)="checkboxClick('SHOWEMAILCC')"></mat-slide-toggle>
                            </div>
                            <br>
                            <div class="display-flex justify-content-between">
                                <div>
                                    <span class="line-clamp3 d-block">Show BCC when sending emails</span>
                                </div>
                                <mat-slide-toggle class="flex-shrink-0" formControlName="SHOWEMAILBCC" style="margin-top: 0px !important;" (change)="checkboxClick('SHOWEMAILBCC')"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </form>
            <!--END Behaviour -->
            <!--START Contact Windows -->
            <form class="w-50" [formGroup]="PreferenceForm">
                <mat-card>
                    <mat-card-header class="margin-x-header-0">
                        <mat-card-title>Contact Windows</mat-card-title>
                    </mat-card-header>

                    <div
                        class="display-flex justify-content-between block-boxwrap">
                        <div>
                            <h6 class="font-size-12 font-weight-800 mt-0 mb-4">Contact
                                Type Default</h6>
                            <span class="line-clamp3 d-block">Choose which
                                contact type you want to see initially</span>
                        </div>
                        <mat-form-field class="example" fxFlex="100"
                            appearance="outline"
                            class="rightdropdown flex-shrink-0">
                            <mat-label>Contact Type Default </mat-label>
                            <mat-select
                                (selectionChange)="changeSelectWindow('CONTACTDEFAULTTYPE')"
                                name='CONTACTDEFAULTTYPE'
                                formControlName="CONTACTDEFAULTTYPE">
                                <mat-option value="all">All Contacts</mat-option>
                                <mat-option value="Person">People</mat-option>
                                <mat-option value="Company">Company</mat-option>
                                <mat-option value="Party">Party</mat-option>
                                <mat-option value="Payee">Payee</mat-option>
                                <mat-option value="Payor">Payor</mat-option>
                                <mat-option value="Payee/Payor">Payee/Payor</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div
                        class="display-flex justify-content-between block-boxwrap">
                        <div>
                            <h6 class="font-size-12 font-weight-800 mt-0 mb-4">Start
                                with Default</h6>
                            <span class="line-clamp3 d-block">When searching for
                                a contact choose which letter you want to narrow
                                down to. If you don't want to choose All</span>
                        </div>
                        <mat-form-field class="example" fxFlex="100"
                            appearance="outline"
                            class="rightdropdown flex-shrink-0">
                            <mat-label>Start with Default</mat-label>
                            <mat-select
                                (selectionChange)="changeSelectWindow('CONTACTSTARTSWITH')"
                                name='CONTACTSTARTSWITH'
                                formControlName="CONTACTSTARTSWITH">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="a">A</mat-option>
                                <mat-option value="b">B</mat-option>
                                <mat-option value="c">C</mat-option>
                                <mat-option value="d">D</mat-option>
                                <mat-option value="e">E</mat-option>
                                <mat-option value="f">F</mat-option>
                                <mat-option value="g">G</mat-option>
                                <mat-option value="h">H</mat-option>
                                <mat-option value="i">I</mat-option>
                                <mat-option value="j">J</mat-option>
                                <mat-option value="k">K</mat-option>
                                <mat-option value="l">L</mat-option>
                                <mat-option value="m">M</mat-option>
                                <mat-option value="n">N</mat-option>
                                <mat-option value="o">O</mat-option>
                                <mat-option value="p">P</mat-option>
                                <mat-option value="q">Q</mat-option>
                                <mat-option value="r">R</mat-option>
                                <mat-option value="s">S</mat-option>
                                <mat-option value="t">T</mat-option>
                                <mat-option value="u">U</mat-option>
                                <mat-option value="v">V</mat-option>
                                <mat-option value="w">W</mat-option>
                                <mat-option value="x">X</mat-option>
                                <mat-option value="y">Y</mat-option>
                                <mat-option value="z">Z</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div
                        class="display-flex justify-content-between block-boxwrap">
                        <div>
                            <h6 class="font-size-12 font-weight-800 mt-0 mb-4">Active
                                Default </h6>
                            <span class="line-clamp3 d-block">Choose whether you
                                want to search for active contacts only, all
                                contacts, etc</span>
                        </div>
                        <mat-form-field class="example" fxFlex="100"
                            appearance="outline"
                            class="rightdropdown flex-shrink-0">
                            <mat-label>Active Default </mat-label>
                            <mat-select
                                (selectionChange)="changeSelectWindow('CONTACTACTIVE')"
                                name='CONTACTACTIVE'
                                formControlName="CONTACTACTIVE">
                                <mat-option value="all">All Contacts</mat-option>
                                <mat-option value="1">Active Contacts</mat-option>
                                <mat-option value="0">Inactive Contacts</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-card>
            </form>
            <!--END  Contact Windows -->
            <!--START Productivity -->
            <form class="w-50" [formGroup]="PreferenceForm">
                <mat-card>
                    <mat-card-header class="margin-x-header-0">
                        <mat-card-title>Productivity</mat-card-title>
                    </mat-card-header>
                    <div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Save Emails To Register
                                </h6>
                                <span class="line-clamp3 d-block">When on, you
                                    will required to choose the
                                    matter number before sending an email</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SAVEEMAILSTOREGISTER"
                                (change)="checkboxClick('SAVEEMAILSTOREGISTER')"></mat-slide-toggle>
                        </div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Load previous X Days in Diary
                                </h6>
                                <span class="line-clamp3 d-block">Choose how
                                    many of the previous day you would to like
                                    to load being mindful that the more you load
                                    the slower it is.</span>
                            </div>
                            <mat-form-field class="example" fxFlex="100"
                                appearance="outline"
                                class="rightdropdown flex-shrink-0">
                                <mat-label>Load previous</mat-label>
                                <mat-select (selectionChange)="
                                        changeSelectWindow('LOADPREVIOUSXDAYS')
                                    " formControlName="LOADPREVIOUSXDAYS">
                                    <mat-option value="30">30 days</mat-option>
                                    <mat-option value="60">60 days</mat-option>
                                    <mat-option value="90">3 months</mat-option>
                                    <mat-option value="120">4 months</mat-option>
                                    <mat-option value="150">5 months</mat-option>
                                    <mat-option value="183">6 months</mat-option>
                                    <mat-option value="365">1 year</mat-option>
                                    <mat-option value="730">2 years</mat-option>
                                    <mat-option value="1095">3 years</mat-option>
                                    <mat-option value="1460">4 years</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Show Diary Reminder
                                </h6>
                                <span class="line-clamp3 d-block">If on , you
                                    will receive reminders like when
                                    appointments are due when logged into SILQ
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SHOWREMINDERS"
                                (change)="checkboxClick('SHOWREMINDERS')"></mat-slide-toggle>
                        </div>
                        <!-- <div class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6 class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Conver Document TO PDF
                                </h6>
                                <span class="line-clamp3 d-block">If on, All document will be saved as
                                    PDF's</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0" formControlName="CONVERTTOPDF"
                                (change)="checkboxClick('CONVERTTOPDF')"></mat-slide-toggle>
                        </div> -->

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Document Thumbnails
                                </h6>
                                <span class="line-clamp3 d-block">In the
                                    Document Register you choose what size your
                                    thumbnails/document previews are
                                </span>
                            </div>
                            <mat-form-field class="example" fxFlex="100"
                                appearance="outline"
                                class="rightdropdown flex-shrink-0">
                                <mat-label>Thumbnail Size</mat-label>
                                <mat-select (selectionChange)="
                                        changeSelectWindow('THUMBNAILSIZE')
                                    " formControlName="THUMBNAILSIZE">
                                    <mat-option value="small">Small</mat-option>
                                    <mat-option value="medium">Medium</mat-option>
                                    <mat-option value="large ">Large</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Save reports to the NetDrive
                                </h6>
                                <span class="line-clamp3 d-block">This will save
                                    a copy of reports to your NetDrive (Eg
                                    Dropbox, SharePoint or OneDrive)</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SAVEREPORTSTONETDRIVE"
                                (change)="
                                    checkboxClick('SAVEREPORTSTONETDRIVE')
                                "></mat-slide-toggle>
                        </div>

                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Email CSV export file
                                </h6>
                                <span class="line-clamp3 d-block">When you export data on a window you will receive a copy of the CSV export file via email</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="EMAILCSVFILE"
                                (change)="
                                    checkboxClick('EMAILCSVFILE')
                                "></mat-slide-toggle>
                        </div>
                    </div>
                </mat-card>
            </form>
            <!--END Productivity -->
            <!--START user interface -->
            <form class="w-50" [formGroup]="PreferenceForm">
                <mat-card>
                    <mat-card-header class="margin-x-header-0">
                        <mat-card-title>User Interface</mat-card-title>
                    </mat-card-header>
                    <div>
                        <!-- <div class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6 class="font-size-12 font-weight-800 mt-0 mb-4">
                                    User Early Acess Feture Interface
                                </h6>
                                <span class="line-clamp3 d-block">When on, you will User Early Acess Feture Interface</span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"  formControlName="USEV11UI"
                            (change)="themePanel('USEV11UI' , $event)"></mat-slide-toggle>
                        </div>
                         -->
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Dark Mode
                                </h6>
                                <span class="line-clamp3 d-block">If on, your
                                    Background will be black insted of white
                                </span>
                            </div>
                            <mat-slide-toggle *ngIf="isSolacitor =='Solicitor'"
                                class="flex-shrink-0"
                                formControlName="THEMECOLOURToggle"
                                (change)="themeradiobtn($event)"></mat-slide-toggle>

                            <!-- <mat-slide-toggle *ngIf="isSolacitor =='Solicitor'" class="flex-shrink-0"  value="theme-blue-gray-dark"
                               (change)="themeradiobtn($event.value)"></mat-slide-toggle>

                               <mat-slide-toggle *ngIf="isSolacitor !='Solicitor'" class="flex-shrink-0"  value="theme-default"
                               (change)="themeradiobtn($event.value)"></mat-slide-toggle>
                               -->
                            <mat-slide-toggle *ngIf="isSolacitor !='Solicitor'"
                                class="flex-shrink-0"
                                formControlName="THEMECOLOURToggle"
                                (change)="themeradiobtn($event)"></mat-slide-toggle>

                        </div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Hide Favourites
                                </h6>
                                <span class="line-clamp3 d-block">If on, your
                                    Favourite bar will be Hidden
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                name='NAVBARHIDE' formControlName="NAVBARHIDE"
                                (change)="themePanel('NAVBARHIDE')"></mat-slide-toggle>

                        </div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Folded Favourites
                                </h6>
                                <span class="line-clamp3 d-block">If on, your
                                    Favourites bar will be Folded initially
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                name='NAVBARFOLDED'
                                formControlName="NAVBARFOLDED"
                                (change)="themePanel('NAVBARFOLDED')"></mat-slide-toggle>

                        </div>
                        <div
                            class="display-flex justify-content-between block-boxwrap">
                            <div>
                                <h6
                                    class="font-size-12 font-weight-800 mt-0 mb-4">
                                    Hide Matter Side Panel
                                </h6>
                                <span class="line-clamp3 d-block">If on, your
                                    Side Panel will be Hide
                                </span>
                            </div>
                            <mat-slide-toggle class="flex-shrink-0"
                                formControlName="SIDEPANELHIDE"
                                (change)="themePanel('SIDEPANELHIDE' , $event)"></mat-slide-toggle>

                        </div>
                    </div>
                </mat-card>
            </form>
            <!--END user interface -->
            <form class="w-50" [formGroup]="PreferenceForm" #scrollToDiv>
                <mat-card>
                    <mat-card-header class="margin-x-header-0 heading">
                        <mat-card-title>SMTP Configuration</mat-card-title>
                        <div class="line-clamp3 d-block">Fill this in if you
                            use SMTP for your
                            Email
                        </div>

                    </mat-card-header>
                    <div
                        class="display-flex flex-wrap align-items-center gap-10">
                        <mat-form-field appearance="outline"
                            class="prefence-input">
                            <mat-label>Email Address</mat-label>
                            <input type="email"
                                formControlName="SMTPEMAILADDRESS"
                                placeholder="Email Address" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline"
                            class=" prefence-input">
                            <mat-label>Server </mat-label>
                            <input type="text" placeholder="Server"
                                formControlName="SMTPSERVER" matInput>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center"
                        class="display-flex flex-wrap align-items-center gap-10"
                        fxFlex="1 0 auto">
                        <mat-form-field appearance="outline"
                            class="pr-4 email-input-1">
                            <mat-label>Port </mat-label>
                            <input type="number" placeholder="Port"
                                formControlName="SMTPPORT" matInput>
                        </mat-form-field>
                        <div fxFlex="50" class="pr-4">
                            <mat-checkbox formControlName="SMTPUSESSL">Use SSL
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center"
                        class="display-flex flex-wrap align-items-center gap-10"
                        fxFlex="1 0 auto">
                        <mat-form-field appearance="outline"
                            class="prefence-input">
                            <mat-label>Username</mat-label>
                            <input type="text" placeholder="Username" matInput
                                formControlName="SMTPUSERNAME">
                        </mat-form-field>

                        <mat-form-field appearance="outline"
                            class="prefence-input">
                            <mat-label>Password </mat-label>
                            <input class="sideText" matInput
                                [type]="hide ? 'password' : 'text'"
                                formControlName="SMTPPASSWORD">
                            <mat-icon matSuffix (click)="hide = !hide">{{hide ?
                                'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                </mat-card>
            </form>
        </div>
    </div>
    <div mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" [disabled]="isspiner"
            (click)="preferenceSave()">Save
        </button>

    </div>
</div>
