import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsComponent } from './details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NumbersModule } from '@directives/allownumber/numbers.module';
import { NumberSeparatorModule } from '@directives/numberseparator/number-separator.module';
import { PipesModule } from '@_pipes/pipes.module';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { MatRadioModule } from '@angular/material/radio';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { ApplicationPipesModule } from '@pages/application-pipes.module';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ResizableModule } from 'angular-resizable-element';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [DetailsComponent],
  exports:[DetailsComponent],
  imports:[
    CommonModule,
    MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        ResizableModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSelectModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        SatDatepickerModule, SatNativeDateModule,
        DragDropModule,
        MatSortModule,
        ApplicationPipesModule,
        NgxCurrencyModule,
        MatRadioModule,
        NumberMaskModule,
        PipesModule,
        NumberSeparatorModule,
        NumbersModule,
        NgxMaskModule.forRoot(maskConfig),
        // GridModule,
        GridAllModule,
        TreeGridModule,
        // NumericTextBoxAllModule,
        // RatingAllModule,
        // DialogModule,
        // DatePickerAllModule,
        // DropDownListAllModule,
         ReactiveFormsModule,
         FormsModule,
        // CheckBoxModule,
  ]
})
export class DetailsModule { }
