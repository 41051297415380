
import { filter } from "lodash";

const activeFilter = [
    {
        id: 1,
        value: 'active',
        description: 'Active Matters'
    },
    {
        id: 2,
        value: 'inactive',
        description: 'Inactive Matters'
    }, {
        id: 3,
        value: 'recent inactive',
        description: 'Recently Inactive'
    }, {
        id: 4,
        value: 'near estimate',
        description: 'Near Estimate'
    }, {
        id: 5,
        value: 'Prospects',
        description: 'Prospects'
    }, {
        id: 6,
        value: 'Not Proceeding',
        description: 'Not Proceeding'
    }, {
        id: 7,
        value: '',
        description: 'All Matters'
    }
]

const unbuildWorkFilter = [
    {
        id: 1,
        value: 'All',
        description: 'Show all work'
    },
    {
        id: 2,
        value: 'Yes',
        description: 'Has unbilled work'
    },
    {
        id: 3,
        value: 'No',
        description: 'No unbilled work'
    }
];

const dateFilter = [{
    id: 0,
    value: 'is in the last',
    description: 'is in the last'
}, {
    id: 1,
    value: 'is equal to',
    description: 'is equal to'
}, {
    id: 2,
    value: 'is between',
    description: 'is between'
}, {
    id: 3,
    value: 'is after',
    description: 'is after'
}, {
    id: 4,
    value: 'is on or after',
    description: 'is on or after'
}, {
    id: 5,
    value: 'is before',
    description: 'is before'
}, {
    id: 6,
    value: 'is before or on',
    description: 'is before or on'
}];

const currencyFilter = [{
    id: 1,
    value: 'is equal to',
    description: 'is equal to'
}, {
    id: 2,
    value: 'is between',
    description: 'is between'
}, {
    id: 3,
    value: 'is greater than',
    description: 'is greater than'
}, {
    id: 4,
    value: 'is less than',
    description: 'is less than'
}];

const numberFilter = [{
    id: 1,
    value: 'is equal to',
    description: 'is equal to'
}, {
    id: 2,
    value: 'is between',
    description: 'is between'
}, {
    id: 3,
    value: 'is greater than',
    description: 'is greater than'
}, {
    id: 4,
    value: 'is less than',
    description: 'is less than'
}, {
    id: 5,
    value: 'is greater than or equal to',
    description: 'is greater than or equal to'
}, {
    id: 6,
    value: 'is less than or equal to',
    description: 'is less than or equal to'
}];


// const  manuallyOptionalFilter = [{
//     id: 1,
//     value: 'contains',
//     description: 'Contains'

//   }, {
//     id: 2,
//     value: 'doesntContain',
//     description: "Doesn't Contain"

//   }, {
//     id: 3,
//     value: 'startwith',
//     description: 'Starts With'

//   }, {
//     id: 4,
//     value: 'is equal to',
//     description: 'Equal to'

//   }, {
//     id: 5,
//     value: 'notEqualto',
//     description: 'Not Equal to'

//   }, {
//     id: 6,
//     value: 'largerThen',
//     description: 'Larger than'

//   }, {
//     id: 7,
//     value: 'is less then',
//     description: 'Less than'

//   }, {
//     id: 8,
//     value: 'largerThenOrEualto',
//     description: 'Larger than or equal to'

//   },
//   {
//     id: 9,
//     value: 'lessThenOrEualto',
//     description: 'Less than or equal to'

//   }];

const manuallyOptionalFilter = [{
    id: 1,
    value: 'contains',
    description: 'Contains'

}, {
    id: 2,
    value: 'starts with',
    description: 'starts with'

}, {
    id: 3,
    value: 'is equal to',
    description: 'Equal to'

}, {
    id: 4,
    value: 'ends with',
    description: 'ends with'

}];

const dayRangeOptions = [
    {
        "value": "All time",
        "description": "All time"
    },
    {
        "value": "Today",
        "description": "Today"
    },
    {
        "value": "Yesterday",
        "description": "Yesterday"
    },
    {
        "value": "Last 7 days",
        "description": "Last 7 days"
    },
    {
        "value": "Last 30 days",
        "description": "Last 30 days"
    },
    {
        "value": "Last 90 days",
        "description": "Last 90 days"
    },
    {
        "value": "Last 6 months",
        "description": "Last 6 months"
    },
    {
        "value": "Last 12 months",
        "description": "Last 12 months"
    },
    {
        "value": "Last 2 years",
        "description": "Last 2 years"
    },
    {
        "value": "Date Range",
        "description": "Date Range"
    }
]

const numberCurrencyFilter = (filterType) => {
    if (filterType == "CURRENCY") {
        return currencyFilter
    } else if (filterType == "NUMBER") {
        return numberFilter
    }
};

export const FilterSearchOptions = {
    activeFilter,
    unbuildWorkFilter,
    dateFilter,
    currencyFilter,
    manuallyOptionalFilter,
    numberCurrencyFilter,
    dayRangeOptions
}
