import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
// import { SettlementAddressPopupComponent } from 'src/app/main/pages/legal-details/settlement/settlement-address-popup/settlement-address-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';



@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit, OnDestroy {
  statusData: any;
  constructor(public MatDialog: MatDialog, private datepipe: DatePipe, private _mainAPiServiceService: MainAPiServiceService,
    public tooltipService: TooltipService) { }

  @Input() action: any;
  @Input() classType :any;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  sub: Subscription;

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let saleStatusLookups = JSON.parse(localStorage.getItem('sale_status_lookups'));
    if (saleStatusLookups && saleStatusLookups != null) {
      this.statusData = saleStatusLookups;
    } else {
     this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'sale status' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.statusData = responses.DATA.LOOKUPS;
          localStorage.setItem('sale_status_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        }
      });
    }
  }

  /**
   * This function is used to get the data value
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999);
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999);
    navigator.clipboard.writeText(event)
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

}
