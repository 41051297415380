import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BankingDialogComponent } from '../banking-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { TrustMoneyReciptComponent } from '../../Trust Accounts/trust-money/trust-money-recipt/trust-money-recipt.component';
import { Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';



@Component({
  selector: 'app-select-banking-dialog',
  templateUrl: './select-banking-dialog.component.html',
  styleUrls: ['./select-banking-dialog.component.scss'],
  animations: fuseAnimations
})
export class SelectBankingDialogComponent implements OnInit, OnDestroy {
  highlightedRows: any;
  isDisplay: boolean = false;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  contectTitle = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  displayedColumns: string[] = ['select', 'Type', 'Date', 'Amount', 'Payor'];
  selection = new SelectionModel(true, []);
  selectedAccountData: any;
  BankingData: any = [];
  accountTypeData: any;
  sendData: any;
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  errorWarningData: any = { "Error": [], 'Warning': [] };
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;


  constructor(private _mainAPiServiceService: MainAPiServiceService, private behaviorService: BehaviorService,
    public dialogRef: MatDialogRef<BankingDialogComponent>, private toastr: ToastrService, private dialog: MatDialog,
    public _matDialog: MatDialog,) {
    this.sub1 = this.behaviorService.ChartAccountData$.subscribe((result) => {
      this.selectedAccountData = result;
    })
    this.sub2 = this.behaviorService.TrustDuplicateModuleHandling$.subscribe(result => {
      if (result != null) {
        this.accountTypeData = result;
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.BankingData = new MatTableDataSource([]);
    this.loadData();
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.sub3?.unsubscribe();
    this.sub4?.unsubscribe();
    this.sub5?.unsubscribe();
  }

  /**
   * This function is used to get all selected data
   * @returns 
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.BankingData.data.length;
    return numSelected === numRows;
  }

  /**
   * This function is used to master toggle
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.BankingData.data.forEach(row => this.selection.select(row));
  }

  /**
   * This function is used to set the check box label
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  rowSelected(val) {

  }
  helloFunction() {
  }

  /**
   * This function is used to load the data value
   */
  loadData() {
    this.isLoadingResults = true;
   // let passUseTrust = this.accountTypeData.UseTrust == "No" ? false : true;
   let passUseTrust = this.accountTypeData && this.accountTypeData.UseTrust && this.accountTypeData.UseTrust == "No" ? false : true;
   this.sub3 = this._mainAPiServiceService.getSetData({ DATA: { ACCOUNTGUID: this.selectedAccountData.ACCOUNTGUID }, UseTrust: passUseTrust, VALIDATEONLY: 0, FormAction: 'default' }, 'SetBanking').subscribe(response => {
      let getData = response.DATA.DEFAULTVALUES;
      if (response.CODE == 200 && response.STATUS == "success") {
        if (getData.BANKINGITEMS[0]) {
          this.isDisplay = false
          this.BankingData = new MatTableDataSource(getData.BANKINGITEMS);
        } else {
          this.isDisplay = true
        }

      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isLoadingResults = false;
    }, err => {
      this.isLoadingResults = false;
    });
  }

  /**
   * This fnction is used to select the bank 
   */
  selectBank() {
    this.isspiner = true;
    this.selection.selected.forEach((items) => {
      items.TAGGED = 1
    })
    let passUseTrust = this.accountTypeData.UseTrust == "No" ? false : true;
    let postData = {
      Data: {
        ACCOUNTGUID: this.selectedAccountData.ACCOUNTGUID,
        BANKINGITEMS: this.selection.selected
      }, FormAction: 'insert', VALIDATEONLY: true, UseTrust: passUseTrust,
    };
    this.sub4 = this._mainAPiServiceService.getSetData(postData, 'SetBanking').subscribe((response) => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, postData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, postData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, postData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    })
  }

  /**
   * This function is used to check validation data
   * @param bodyData -body data value
   * @param details -details value
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      }
      else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.trustMoneyData(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length === 0) {
      this.trustMoneyData(details);
    }
  }

  /**
   * This function is used to get the trust money data
   * @param data -data value
   */
  trustMoneyData(data: any) {
    this.isspiner = true;
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'SetBanking').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success('save successfully');
        this.dialogRef.close(true);
        if (response.DATA.PDFFILENAME != '') {
          this.dialog.open(TrustMoneyReciptComponent, {
            width: '100%', data: { action: 'Select Banking', reportTitle :'Banking Report',forPDF: 'Yes', PDFURL: response.DATA.PDFFILENAME }
          });
        }
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }
}
