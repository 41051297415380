import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { FuseConfigService } from "src/@fuse/services/config.service";
import { FuseNavigationService } from "src/@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "src/@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "src/@fuse/services/translation-loader.service";
import { navigation } from "src/app/navigation/navigation";
import { locale as navigationEnglish } from "src/app/navigation/i18n/en";
import { DeviceDetectorService } from "ngx-device-detector";
import { LicenceAgreementComponent } from "./main/licence-agreement/licence-agreement.component";
import { MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AuthenticationService, BehaviorService } from "./_services";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { MatSnackBar } from '@angular/material';
import { ConnectionService } from "ng-connection-service";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import * as $ from 'jquery';
import { SwUpdate } from "@angular/service-worker";
import { SocketService } from "@_services/socket.service";
// import { ConnectionService } from 'ngx-connection-service';
// import { ConnectionService } from 'ngxc-connection-service';

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    deviceInfo = null;
    private _jsonURL = "assets/version.json";
    isConnected: boolean = true;
    // Private
    // private _unsubscribeAll: Subject<any>;
    isUser: any = JSON.parse(localStorage.getItem("currentUser"));
    status: string = "ONLINE";
    private _unsubscribeAll$: Subject<void> = new Subject();
    //websoket
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private deviceService: DeviceDetectorService,
        public dialog: MatDialog,
        public router: Router,
        private authenticationService: AuthenticationService,
        private behaviorService: BehaviorService,
        private http: HttpClient,
        private _snackBar: MatSnackBar,
        private connectionService: ConnectionService,
        private toastr: ToastrService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private swUpdate: SwUpdate,
        private socketService: SocketService
    ) {

        this.checkforVersionUpdate();

        setInterval(() => this.checkforVersionUpdate(), 60 * 1000);

        this.behaviorService.currentUser$
            .subscribe(() => {
                this.isUser = JSON.parse(localStorage.getItem("currentUser"));
            });
        /**
         * All tab LOgout  Code
         */
        let tabData;
        let windowNameId;
        window.addEventListener("storage", (event) => {
            if (event.storageArea == localStorage) {
                let setBackup = localStorage.getItem("setBackup");
                if (setBackup) {
                    const windowId = window.name.split("_");
                    windowNameId =
                        windowId && windowId[1] ? windowId[1] : undefined;
                    tabData = localStorage.getItem(windowNameId);
                    localStorage.setItem("setBackup", "false");
                }
                let token = localStorage.getItem("session_token");
                let currentUser = localStorage.getItem("currentUser");

                if (event.key) {
                    this.behaviorService.setTimerCountData();
                }

                if (!token || !currentUser) {
                    this.authenticationService.tabLogout();
                    if (tabData) {
                        localStorage.setItem(windowNameId, tabData);
                    }
                    // this.dialog.closeAll()
                }
            }
        });
        /**
         * All tab custome Logout  Code
         */
        // setInterval(() => {
        //     let token = localStorage.getItem('session_token');
        //     if (token == undefined || token == '') {
        //         this.router.navigate(['login']);
        //     }
        // }, 1000);
        let currentUser: any = this.authenticationService.currentUserValue;
        if (currentUser) {
            this._fuseConfigService.config = {
                colorTheme: currentUser.THEMECOLOUR,
                layout: {
                    sidepanel: { hidden: currentUser.SIDEPANELHIDE },
                    navbar: {
                        hidden: currentUser.NAVBARHIDE,
                        folded: currentUser.NAVBARFOLDED,
                    },
                },
            };
            this.behaviorService.setToolBaarSetting({
                NAVBARFOLDED: currentUser.NAVBARFOLDED,
                NAVBARHIDE: currentUser.NAVBARHIDE,
                OPENINNEWTABWINDOWS: currentUser.OPENINNEWTABWINDOWS,
            });
            router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    (<any>window).Appcues.page();
                    (<any>window).Appcues.identify(currentUser.UserGuid, {
                        createdAt: new Date().getTime(), // Unix timestamp of user signup date
                        purchasedAd: new Date().getTime(), // Unix timestamp of account purchase date (leave null if empty)
                        planTier: "Standard", // Current user’s plan tier
                        role: currentUser.PRODUCTTYPE, // Current user’s role or permissions
                        accountId: currentUser.UserId, // Current user's account ID
                        firstName: currentUser.UserName, // current user's first name
                        // additional suggestions
                    });
                    // (<any>window).Appcues.track("page change event", { url: event.url, article: "page change" });
                }
            });
            // this.connectionService.monitor().subscribe(isConnected => {
            //     this.isConnected = isConnected;
            //     if (this.isConnected) {
            //         this.toastr.success('Back Online');
            //     }
            // });

            this.connectionService.monitor().subscribe((currentState) => {
                const hasNetworkConnection = currentState.hasNetworkConnection;
                const hasInternetAccess = currentState.hasInternetAccess;
                this.isConnected = hasNetworkConnection;
                // if (this.isConnected && hasInternetAccess) {
                //     this.toastr.success('Back Online');
                // }
                if (currentState.hasNetworkConnection) {
                    if (this.status == "OFFLINE") {
                        this.toastr.success("Back Online");
                    }
                    this.status = "ONLINE";
                } else {
                    this.status = "OFFLINE";
                }
            });
        } else {
            this._fuseConfigService.config = {
                colorTheme: "theme-default",
            };
        }
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["en", "tr"]);

        // Set the default language
        this._translateService.setDefaultLang("en");

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish);

        // Use a language
        this._translateService.use("en");
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        // this._unsubscribeAll = new Subject();
        this.epicFunction();
        this.behaviorService.applicationVersion$
            .subscribe((result) => {
                const apppVersion = localStorage.getItem("applicationVersion");
                if (
                    apppVersion &&
                    apppVersion !== null &&
                    apppVersion !== "null" &&
                    apppVersion != undefined &&
                    apppVersion != "undefined" &&
                    apppVersion != ""
                ) {
                    if (apppVersion < result) {
                        this.getJSON().subscribe((versionData) => {
                            let snackBarRef = this._snackBar.open(
                                versionData.message,
                                "Refresh",
                                { duration: 200000000 }
                            );
                            snackBarRef.onAction().subscribe(() => {
                                localStorage.setItem(
                                    "applicationVersion",
                                    versionData.version
                                );
                                window.location.reload();
                            });
                        });
                    }
                } else {
                    localStorage.setItem("applicationVersion", result);
                }
            });
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let child = this.activatedRoute.firstChild;
                    while (child) {
                        if (child.firstChild) {
                            child = child.firstChild;
                        } else if (
                            child.snapshot.data &&
                            child.snapshot.data["title"]
                        ) {
                            return child.snapshot.data["title"];
                        } else {
                            return null;
                        }
                    }
                    return null;
                })
            )
            .subscribe((data: any) => {
                if (data) {
                    if (
                        data != "Deliverables" &&
                        data != "Estimate" &&
                        data != "Matter-Appointments" &&
                        data != "Receipts-Credits" &&
                        data != "Matter-Invoices" &&
                        data != "Matter-Trust" &&
                        data != "Work-In-Progress"
                    ) {
                        this.titleService.setTitle(data);
                    }
                }
            });
    }
    public getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
    }

/**
 * This function is used to get the device details
 */
    epicFunction() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();

        if (window.innerWidth < 1024) {
            if (isMobile) {
                const dialogRef = this.dialog.open(LicenceAgreementComponent, {
                    disableClose: true,
                    width: "100%",
                    data: { action: "MD" },
                });
                dialogRef.afterClosed().subscribe((result) => { });
            }
        } else {
            //
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * This function is used to handle the websocket
     */
    async handleWebSocket(){
        let token = localStorage.getItem("session_token");
        if(token) {
           await this.socketService.openSocketMatter(token);
        }
    }

  /**
   * This function is used for check the update
   */

    async checkforVersionUpdate(){
        // !this.socketService.webSocketMatter
        // console.log('this.socketService.webSocketMatter',this.socketService.webSocketMatter);
        if (this.swUpdate.isEnabled) {
            // this.swUpdate.versionUpdates.subscribe(event => {
            //     console.log('event',event)
            //   if (event.type === 'VERSION_READY') {
            //     this.promptUser();
            //   }
            // });
            const isNewVersion = await this.swUpdate.checkForUpdate();
            if (isNewVersion) {
              // Check if new version is activated
            //   const isNewVersionActivated = await this.swUpdate.activateUpdate();
    
              // Reload the application with new version if new version is activated
            //   if (isNewVersionActivated) {
                this.promptUser();
            //   }
            }

        }

        // this.swUpdate.checkForUpdate().then(() => console.log('Checked for update'));
    }

       /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.handleWebSocket();
        localStorage.setItem("isstart", "no");
        this.authenticationService.openWSConnection(
            localStorage.getItem("session_token")
        );
        // Subscribe to config changes
        this._fuseConfigService.config
            .subscribe((config) => {
                this.fuseConfig = config;
                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        // const dialogRef = this.dialog.open(TryFreeSetUpWizardComponent, { width: '100%', disableClose: true, data: { type: 'new' } });
        // dialogRef.afterClosed().subscribe(result => {

        // });

        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         console.log('event.url',event.url);
        //         if (event.url === '/account-reconciliation/bank-feed') {
        //             // $('#checkbankfeed').click();
        //         }
        //     }
        // });
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            if (event.url == '/bank-feed/bank-feed-dashboard#connect' && this.activatedRoute.snapshot.fragment === 'connect') {
                $('#checkbankfeed').click();
            } 
        });
    }

    /**
     * This function is used to promt the user 
     */
    promptUser(): void {
        let snackBarRef = this._snackBar.open("A new version of the app is available.", 'Refresh', {
          duration: 200000000,
        });
    
        snackBarRef.onAction().subscribe(() => {
        //   this.swUpdate.activateUpdate().then(() => {
        //     console.log('Service Worker activated. Reloading...');
        //     window.location.reload();
        //   }).catch(err => {
        //     console.error('Error activating update', err);
        //   })
            window.location.reload();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
        this._fuseSidebarService.sidebarMenuPopup.next(true);
    }
}
