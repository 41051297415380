<div class=" one " fxLayout="column" [formGroup]="TrainingForm" >
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TRAININGDATE,'has-warning':errorWarningData.Warning?.TRAININGDATE}">
            <mat-label>Training Date</mat-label>
            <input (dateInput)="CommencementDateClick('input', $event)" 
             formControlName="TRAININGDATETEXT" (dateChange)="CommencementDateClick('change', $event)" matInput [matDatepicker]="CommencementDate"
            matTooltip="{{(toolTipList)?toolTipList.TRAININGDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" 
            >
            <mat-datepicker-toggle matSuffix [for]="CommencementDate"></mat-datepicker-toggle>
            <mat-datepicker #CommencementDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.TRAININGDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TRAININGDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TRAININGDATE?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TRAININGNUMDAYS,'has-warning':errorWarningData.Warning?.TRAININGNUMDAYS}" >
            <mat-label>Number of Days </mat-label>
            <input matInput  type="number" formControlName="TRAININGNUMDAYS"
           
            matTooltip="{{(toolTipList)?toolTipList.TRAININGNUMDAYS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" 
            >
            <div  *ngIf="testBool && toolTipList.TRAININGNUMDAYS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TRAININGNUMDAYS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TRAININGNUMDAYS?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TRAININGTOPIC ,'has-warning':errorWarningData.Warning?.TRAININGTOPIC }">
            <mat-label>Topic</mat-label>
            <input name="TRAININGTOPIC " formControlName="TRAININGTOPIC" 
              matInput
            matTooltip="{{(toolTipList)?toolTipList.TRAININGTOPIC?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"
            >
            <div  *ngIf="testBool && toolTipList.TRAININGTOPIC" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TRAININGTOPIC.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TRAININGTOPIC?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TRAININGCONTRACTSIGNEDDATE,'has-warning':errorWarningData.Warning?.TRAININGCONTRACTSIGNEDDATE}">
            <mat-label>Contract Signed Date </mat-label>
            <input  formControlName="TRAININGCONTRACTSIGNEDDATETEXT"  (dateInput)="ExpirtyDateClick('input', $event)"  (dateChange)="ExpirtyDateClick('change', $event)"
              matInput [matDatepicker]="ExpirtyDate"
            matTooltip="{{(toolTipList)?toolTipList.TRAININGCONTRACTSIGNEDDATE?.HOVER:''}}"
              [matTooltipPosition]="toolTipPostion"
            >
            <mat-datepicker-toggle matSuffix [for]="ExpirtyDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpirtyDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.TRAININGCONTRACTSIGNEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TRAININGCONTRACTSIGNEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TRAININGCONTRACTSIGNEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>

        </mat-form-field>
    </div>
</div>