import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convet24To12' })
export class Convet24To12Pipe implements PipeTransform {

    /**
     * This function is used to transform the data.
     */
    transform(value: any, args: any): any {
        if (args && (typeof value != "undefined")) {
            return this.tConvert(value);
        } else {
            return value;
        }
    }
    tConvert(time: any) {
        const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
        return `${hours}:${minutes} ${period}`;
    }

}
