<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_user_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form #userForm="ngForm" name="userForm" id="userForm" fxLayout="column" fxLayoutAlign="start"
                fxFlex="1 0 auto" name="form" autocomplete="off">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.USERNAME,'has-warning':errorWarningData.Warning?.USERNAME}">
                        <mat-label>UserName</mat-label>
                        <input [matTooltip]="toolTipList && toolTipList.USERNAME ? toolTipList.USERNAME?.HOVER  : ''"
                            [(ngModel)]="userData.USERNAME" name="USERNAME" matInput>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.USERNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.USERNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-checkbox [matTooltip]="toolTipList && toolTipList.ISACTIVE ? toolTipList.ISACTIVE?.HOVER  : ''"
                        [(ngModel)]="userData.ISACTIVE" name="ISACTIVE">Active</mat-checkbox>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-checkbox *ngIf="('' | productType)"
                        [matTooltip]="toolTipList && toolTipList.ISPRINCIPAL ? toolTipList.ISPRINCIPAL?.HOVER  : ''"
                        [(ngModel)]="userData.ISPRINCIPAL" name="ISPRINCIPAL">Is Principal</mat-checkbox>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.FULLNAME,'has-warning':errorWarningData.Warning?.FULLNAME}">
                        <mat-label>Full Name</mat-label>
                        <input [matTooltip]="toolTipList && toolTipList.FULLNAME ? toolTipList.FULLNAME?.HOVER  : ''"
                            [(ngModel)]="userData.FULLNAME" name="FULLNAME" matInput>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FULLNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FULLNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                </div>

                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="{{ ('' | productType)? 'Fee Earner' : 'User'}}">
                            <div class="one" fxLayout="column">
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.USERID,'has-warning':errorWarningData.Warning?.USERID}">
                                        <mat-label>Fee Earner Id</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.USERID ? toolTipList.USERID?.HOVER  : ''"
                                            [(ngModel)]="userData.USERID" name="USERID">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                    <mat-form-field *ngIf="('' | productType)" class="example" appearance="outline"
                                        fxFlex="50">
                                        <mat-label>Staff Reporting to Me</mat-label>
                                        <mat-select (selectionChange)="MatterUserChange($event.value)"
                                            name="DIRECTREPORTS"
                                            [matTooltip]="toolTipList && toolTipList.DIRECTREPORTS ? toolTipList.DIRECTREPORTS?.HOVER  : ''"
                                            [(ngModel)]="userData.DIRECTREPORTS" multiple>
                                            <mat-option *ngFor="let user of UserListData" value="{{user.USERGUID}}">
                                                {{user.USERID}}</mat-option>
                                        </mat-select>
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.POSITION,'has-warning':errorWarningData.Warning?.POSITION}">
                                        <mat-label>Position</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.POSITION ? toolTipList.POSITION?.HOVER  : ''"
                                            [(ngModel)]="userData.POSITION" name="POSITION">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                    <mat-form-field fxFlex="25" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.GSTTYPE,'has-warning':errorWarningData.Warning?.GSTTYPE}">
                                        <mat-label>GST Type</mat-label>
                                        <mat-select
                                            [matTooltip]="toolTipList && toolTipList.GSTTYPE ? toolTipList.GSTTYPE?.HOVER  : ''"
                                            [(ngModel)]="userData.GSTTYPE" name="GSTTYPE">
                                            <mat-option value="GST Exclusive">GST Exclusive</mat-option>
                                            <mat-option value="GST Inclusive">GST Inclusive</mat-option>
                                            <mat-option value="GST Free">GST Free</mat-option>
                                        </mat-select>
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PHONE1,'has-warning':errorWarningData.Warning?.PHONE1}">
                                        <mat-label>Ph 1</mat-label>
                                        <input
                                            [matTooltip]="toolTipList && toolTipList.PHONE1 ? toolTipList.PHONE1?.HOVER  : ''"
                                            [(ngModel)]="userData.PHONE1" name="PHONE1" matInput>
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PHONE2,'has-warning':errorWarningData.Warning?.PHONE2}">
                                        <mat-label>Ph 2</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.PHONE2 ? toolTipList.PHONE2?.HOVER  : ''"
                                            [(ngModel)]="userData.PHONE2" name="PHONE2">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FAX1,'has-warning':errorWarningData.Warning?.FAX1}">
                                        <mat-label>Fax 1</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.FAX1 ? toolTipList.FAX1?.HOVER  : ''"
                                            [(ngModel)]="userData.FAX1" name="FAX1">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FAX2,'has-warning':errorWarningData.Warning?.FAX2}">
                                        <mat-label>Fax 2</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.FAX2 ? toolTipList.FAX2?.HOVER  : ''"
                                            [(ngModel)]="userData.FAX2" name="FAX2">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                                    *ngIf="('' | productType)">
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.OFFICELOCATION,'has-warning':errorWarningData.Warning?.OFFICELOCATION}">
                                        <mat-label>Office Location</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.OFFICELOCATION ? toolTipList.OFFICELOCATION?.HOVER  : ''"
                                            [(ngModel)]="userData.OFFICELOCATION" name="OFFICELOCATION">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.OFFICEDESCRIPTION,'has-warning':errorWarningData.Warning?.OFFICEDESCRIPTION}">
                                        <mat-label>Office Description </mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.OFFICEDESCRIPTION ? toolTipList.OFFICEDESCRIPTION?.HOVER  : ''"
                                            [(ngModel)]="userData.OFFICEDESCRIPTION" name="OFFICEDESCRIPTION">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.EMAIL,'has-warning':errorWarningData.Warning?.EMAIL}">
                                        <mat-label>Email</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.EMAIL ? toolTipList.EMAIL?.HOVER  : ''"
                                            [(ngModel)]="userData.EMAIL" name="EMAIL">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.MOBILE,'has-warning':errorWarningData.Warning?.MOBILE}">
                                        <mat-label>Mobile</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.MOBILE ? toolTipList.MOBILE?.HOVER  : ''"
                                            [(ngModel)]="userData.MOBILE" name="MOBILE">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PRACTICINGCERTIFICATENO,'has-warning':errorWarningData.Warning?.PRACTICINGCERTIFICATENO}">
                                        <mat-label>Practicing Cert. No</mat-label>
                                        <input matInput
                                            [matTooltip]="toolTipList && toolTipList.PRACTICINGCERTIFICATENO ? toolTipList.PRACTICINGCERTIFICATENO?.HOVER  : ''"
                                            [(ngModel)]="userData.PRACTICINGCERTIFICATENO"
                                            name="PRACTICINGCERTIFICATENO">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}">
                                        <mat-label>Comment</mat-label>
                                        <textarea matInput
                                            [matTooltip]="toolTipList && toolTipList.COMMENT ? toolTipList.COMMENT?.HOVER  : ''"
                                            [(ngModel)]="userData.COMMENT" name="COMMENT"></textarea>
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab label="Security">
                            <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
                                <br><br>
                                <mat-accordion class="faqs-accordion">
                                    <mat-expansion-panel *ngFor="let Permission of userinfoDatah; let i = index">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                                                {{Permission.key}}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div>
                                            <mat-checkbox name="{{Permission.key}}_{{i}}"
                                                (change)="AllCHecked($event, Permission.key)"> All
                                            </mat-checkbox>
                                        </div>
                                        <div *ngFor="let checkboxVal of Permission.val;let j = index">
                                            <mat-checkbox [(ngModel)]="userinfoDatah[i]['val'][j].VALUE"
                                                name="{{userinfoDatah[i]['val'][j].NAME}}_{{i}}_{{j}}">
                                                {{checkboxVal.NAME}}
                                            </mat-checkbox>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </mat-tab>


                        <mat-tab label="Rates" *ngIf="('' | productType)">
                            <label id="example-radio-group-label" class="rate_tab_label">GST Type</label>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 40px" class="rate_tab_value">
                                <mat-radio-group aria-label="Billing Method" class="example tab_value_child"
                                    fxFlex="100" class="pr-4"
                                    [matTooltip]="toolTipList && toolTipList.GSTTYPE ? toolTipList.GSTTYPE?.HOVER  : ''"
                                    [(ngModel)]="userData.GSTTYPE" name="GSTTYPE">
                                    <mat-radio-button value="GST Exclusive">Exclusive
                                    </mat-radio-button>
                                    <mat-radio-button value="GST Inclusive">Inclusive </mat-radio-button>
                                    <mat-radio-button value="GST Free">Free</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" class="pt-12" fxFlex="1 0 auto">
                                <div fxFlex="14">
                                </div>
                                <div fxFlex="43" class="text-center">
                                    <mat-label>$ / hr</mat-label>
                                </div>
                                <div fxFlex="43" class="text-center">
                                    <mat-label>$ / day</mat-label>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list">
                                <div fxFlex="14">
                                    <mat-label>Rate 1</mat-label>
                                </div>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_1 ? toolTipList.RATEPERHOUR_1?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERHOUR_1" name="RATEPERHOUR_1">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERDAY_1 ? toolTipList.RATEPERDAY_1?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERDAY_1" name="RATEPERDAY_1">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list">
                                <div fxFlex="14">
                                    <mat-label>Rate 2</mat-label>
                                </div>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_2 ? toolTipList.RATEPERHOUR_2?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERHOUR_2" name="RATEPERHOUR_2">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERDAY_2 ? toolTipList.RATEPERDAY_2?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERDAY_2" name="RATEPERDAY_2">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list">
                                <div fxFlex="14">
                                    <mat-label>Rate 3</mat-label>
                                </div>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_3 ? toolTipList.RATEPERHOUR_3?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERHOUR_3" name="RATEPERHOUR_3">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERDAY_3 ? toolTipList.RATEPERDAY_3?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERDAY_3" name="RATEPERDAY_3">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list">
                                <div fxFlex="14">
                                    <mat-label>Rate 4</mat-label>
                                </div>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_4 ? toolTipList.RATEPERHOUR_4?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERHOUR_4" name="RATEPERHOUR_4">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERDAY_4 ? toolTipList.RATEPERDAY_4?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERDAY_4" name="RATEPERDAY_4">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list">
                                <div fxFlex="14">
                                    <mat-label>Rate 5</mat-label>
                                </div>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_5 ? toolTipList.RATEPERHOUR_5?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERHOUR_5" name="RATEPERHOUR_5">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERDAY_5 ? toolTipList.RATEPERDAY_5?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERDAY_5" name="RATEPERDAY_5">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list">
                                <div fxFlex="14">
                                    <mat-label>Rate 6</mat-label>
                                </div>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_6 ? toolTipList.RATEPERHOUR_6?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERHOUR_6" name="RATEPERHOUR_6">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                                    <input matInput type="number"
                                        [matTooltip]="toolTipList && toolTipList.RATEPERDAY_6 ? toolTipList.RATEPERDAY_6?.HOVER  : ''"
                                        [(ngModel)]="userData.RATEPERDAY_6" name="RATEPERDAY_6">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>

                        </mat-tab>

                        <mat-tab label="InfoTrack" *ngIf="('' | productType)">
                            <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
                                <p fxFlex="100">
                                    Enter a username and password for the search provider if you want to use a different
                                    <br> login for this user.If nothing is provided here it will use the system default
                                    login.
                                </p>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                    [ngClass]="{'has-error':errorWarningData.Error?.SEARCHUSERNAME,'has-warning':errorWarningData.Warning?.SEARCHUSERNAME}">
                                    <mat-label>Username</mat-label>
                                    <input matInput
                                        [matTooltip]="toolTipList && toolTipList.SEARCHUSERNAME ? toolTipList.SEARCHUSERNAME?.HOVER  : ''"
                                        [(ngModel)]="userData.SEARCHUSERNAME" name="SEARCHUSERNAME">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                    [ngClass]="{'has-error':errorWarningData.Error?.SEARCHPASSWORD,'has-warning':errorWarningData.Warning?.SEARCHPASSWORD}">
                                    <mat-label>Password</mat-label>
                                    <input matInput
                                        [matTooltip]="toolTipList && toolTipList.SEARCHPASSWORD ? toolTipList.SEARCHPASSWORD?.HOVER  : ''"
                                        [(ngModel)]="userData.SEARCHPASSWORD" name="SEARCHPASSWORD">
                                <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Budgets" *ngIf="('' | productType)">
                            <div class="one" fxLayout="column">
                                <div class="example-containerdata">
                                    <table mat-table #table [dataSource]="userBudgets" matSort
                                        [@animateStagger]="{value:'50'}">
                                        <ng-container matColumnDef="PERIODSTART">
                                            <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                                Period Start</th>
                                            <td mat-cell *matCellDef="let row"> {{row.PERIODSTART}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="TOTALBUDGETHOURS">
                                            <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                                Total Hours</th>
                                            <td mat-cell *matCellDef="let row"> {{row.TOTALBUDGETHOURS}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="TOTALBUDGETDOLLARS">
                                            <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                                Total Dollars(Ex)</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.TOTALBUDGETDOLLARS | toFixedAmount:true}} </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row
                                            [style.background]="highlightedRows == row.USERBUDGETGUID ? selectedColore : ''"
                                            *matRowDef="let row; columns: displayedColumns;"
                                            [ngClass]="highlightedRows == row.USERBUDGETGUID ? 'row-text-colore' : ''"
                                            class="contact"
                                            (click)="setActiveBudget(row);currentBudgets = row;highlightedRows = row.USERBUDGETGUID"
                                            matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                        </tr>
                                    </table>
                                </div>
                                <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.user_dialog"
                                    (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
                            </div>
                            <div>
                                <mat-dialog-actions align="end" class="popup_ftr">
                                    <button mat-raised-button color="accent" class="mat-accent" *ngIf="isNewShow"
                                        (click)="budgetDailog('new')" cdkFocusInitial>New Budget</button>
                                    <button mat-raised-button color="accent" class="mat-accent"
                                        *ngIf="action!='new' && isEditShow" (click)="budgetDailog('edit')"
                                        cdkFocusInitial>Edit Budget</button>
                                    <button mat-raised-button color="primary" (click)="delete_budget()"
                                        cdkFocusInitial>Delete Budget</button>
                                </mat-dialog-actions>
                            </div>
                        </mat-tab>
                        <mat-tab label="Directory" *ngIf="(('' | productType) && this.action === 'edit' )">
                            <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto"
                                class="gst_charge tab_value_child">
                                <mat-checkbox [(ngModel)]="userData.DONTSHOWINDIRECTORY" name="DONTSHOWINDIRECTORY">
                                    Don't show in Directory</mat-checkbox>
                            </div>

                            <div class="one" fxLayout="column">
                                <div fxLayout="row wrap" class="pt-12" fxLayoutAlign="end" fxFlex="1 0 auto">
                                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="simple"
                                        (change)="onValChange($event.value)">
                                        <mat-button-toggle value="simple">Simple</mat-button-toggle>
                                        <mat-button-toggle value="HTML">HTML</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                                <mat-label class="font-weight-700">BIOGRAPHY</mat-label>

                                <div fxLayout="row wrap" class="pt-12 user-editorwrap" fxLayoutAlign="start center" fxFlex="1 0 auto"
                                    *ngIf="signatureType!='HTML'">
                                    <ckeditor [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc
                                        [config]="configsOption" fxFlex="100" class="ckeClass" [editor]="Editor"
                                        data="" (change)="changesDetact($event)">
                                    </ckeditor>
                                </div>

                                <div fxLayout="row wrap" class="pt-12" fxLayoutAlign="start center" fxFlex="1 0 auto"
                                    *ngIf="signatureType=='HTML'">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <textarea class="min_height_textarea" (focusout)="signaturePreview()" matInput
                                            [(ngModel)]="ckeditorValue" name="ckeditorValue"></textarea>
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                        </mat-form-field>
                                </div>
                                <mat-label class="font-weight-700" *ngIf="signatureType=='HTML'">Preview</mat-label>

                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                                    *ngIf="signatureType=='HTML'">

                                    <div fxFlex="100" class="parentHtmlCssDiv">
                                        <div [innerHTML]="HtmlCss" class="previewDiv"></div>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <div class="dropzone" appFileDragNDrop (filesChangeEmiter)="onFileChange($event)"
                                    *ngIf="finalfileToUpload == '' || (finalfileToUpload && finalfileToUpload[0]) == ''">
                                    <div class="text-wrapper">
                                        <div class="centered">
                                            <input type="file" name="file" id="file"
                                                (change)="onFileChange($event.target.files)" accept="image/*">
                                            <label for="file"><span class="textLink">
                                                    <mat-icon style="position: relative; top: 4px;right: 4px;">
                                                        cloud_upload</mat-icon>Select your file
                                                </span> or Drop it
                                                here!</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 *ngIf="finalfileToUpload == '' || (finalfileToUpload && finalfileToUpload[0]) == ''"
                                style="top: 16px;
                            position: relative;">No file Choosen</h3>
                            <div  class="itemimge" *ngIf="finalfileToUpload && finalfileToUpload[0] !== ''">
                                <img style="border-radius: 4px;width: -webkit-fill-available !important;" [src]="(finalfileToUpload && finalfileToUpload[0] !== '')?finalfileToUpload[0]:''"  alt="Preview image" />
                                <mat-icon class='cursor'  (click)="remove()">clear</mat-icon>
                            </div>

                        </mat-tab>
                    </mat-tab-group>

                </div> -->

                <mat-expansion-panel mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">{{ ('' | productType)? 'Fee Earner' :
                        'User'}}</mat-expansion-panel-header>
                    <div class="one" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.USERID,'has-warning':errorWarningData.Warning?.USERID}">
                                <mat-label>Fee Earner Id</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.USERID ? toolTipList.USERID?.HOVER  : ''"
                                    [(ngModel)]="userData.USERID" name="USERID">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.USERID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.USERID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <mat-form-field *ngIf="('' | productType)" class="example" appearance="outline" fxFlex="50">
                                <mat-label>Staff Reporting to Me</mat-label>
                                <mat-select (selectionChange)="MatterUserChange($event.value)" name="DIRECTREPORTS"
                                    [matTooltip]="toolTipList && toolTipList.DIRECTREPORTS ? toolTipList.DIRECTREPORTS?.HOVER  : ''"
                                    [(ngModel)]="userData.DIRECTREPORTS" multiple>
                                    <!-- <mat-option value="all">All</mat-option>
                                        <mat-option value="">None</mat-option> -->
                                    <mat-option *ngFor="let user of UserListData" value="{{user.USERGUID}}">
                                        {{user.USERID}}</mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DIRECTREPORTS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DIRECTREPORTS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.POSITION,'has-warning':errorWarningData.Warning?.POSITION}">
                                <mat-label>Position</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.POSITION ? toolTipList.POSITION?.HOVER  : ''"
                                    [(ngModel)]="userData.POSITION" name="POSITION">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSITION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSITION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <mat-form-field fxFlex="25" class="pr-4" appearance="outline"
                                [ngClass]="{'has-error':errorWarningData.Error?.GSTTYPE,'has-warning':errorWarningData.Warning?.GSTTYPE}">
                                <mat-label>GST Type</mat-label>
                                <mat-select
                                    [matTooltip]="toolTipList && toolTipList.GSTTYPE ? toolTipList.GSTTYPE?.HOVER  : ''"
                                    [(ngModel)]="userData.GSTTYPE" name="GSTTYPE">
                                    <mat-option value="GST Exclusive">GST Exclusive</mat-option>
                                    <mat-option value="GST Inclusive">GST Inclusive</mat-option>
                                    <mat-option value="GST Free">GST Free</mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GSTTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GSTTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.PHONE1,'has-warning':errorWarningData.Warning?.PHONE1}">
                                <mat-label>Ph 1</mat-label>
                                <input
                                    [matTooltip]="toolTipList && toolTipList.PHONE1 ? toolTipList.PHONE1?.HOVER  : ''"
                                    [(ngModel)]="userData.PHONE1" name="PHONE1" matInput>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PHONE1?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PHONE1?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.PHONE2,'has-warning':errorWarningData.Warning?.PHONE2}">
                                <mat-label>Ph 2</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.PHONE2 ? toolTipList.PHONE2?.HOVER  : ''"
                                    [(ngModel)]="userData.PHONE2" name="PHONE2">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PHONE2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PHONE2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.FAX1,'has-warning':errorWarningData.Warning?.FAX1}">
                                <mat-label>Fax 1</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.FAX1 ? toolTipList.FAX1?.HOVER  : ''"
                                    [(ngModel)]="userData.FAX1" name="FAX1">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAX1?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAX1?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.FAX2,'has-warning':errorWarningData.Warning?.FAX2}">
                                <mat-label>Fax 2</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.FAX2 ? toolTipList.FAX2?.HOVER  : ''"
                                    [(ngModel)]="userData.FAX2" name="FAX2">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAX2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAX2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="('' | productType)">
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.OFFICELOCATION,'has-warning':errorWarningData.Warning?.OFFICELOCATION}">
                                <mat-label>Office Location</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.OFFICELOCATION ? toolTipList.OFFICELOCATION?.HOVER  : ''"
                                    [(ngModel)]="userData.OFFICELOCATION" name="OFFICELOCATION">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OFFICELOCATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.OFFICELOCATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.OFFICEDESCRIPTION,'has-warning':errorWarningData.Warning?.OFFICEDESCRIPTION}">
                                <mat-label>Office Description </mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.OFFICEDESCRIPTION ? toolTipList.OFFICEDESCRIPTION?.HOVER  : ''"
                                    [(ngModel)]="userData.OFFICEDESCRIPTION" name="OFFICEDESCRIPTION">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OFFICEDESCRIPTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.OFFICEDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.EMAIL,'has-warning':errorWarningData.Warning?.EMAIL}">
                                <mat-label>Email</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.EMAIL ? toolTipList.EMAIL?.HOVER  : ''"
                                    [(ngModel)]="userData.EMAIL" name="EMAIL">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EMAIL?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.EMAIL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.MOBILE,'has-warning':errorWarningData.Warning?.MOBILE}">
                                <mat-label>Mobile</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.MOBILE ? toolTipList.MOBILE?.HOVER  : ''"
                                    [(ngModel)]="userData.MOBILE" name="MOBILE">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MOBILE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MOBILE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.PRACTICINGCERTIFICATENO,'has-warning':errorWarningData.Warning?.PRACTICINGCERTIFICATENO}">
                                <mat-label>Practicing Cert. No</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.PRACTICINGCERTIFICATENO ? toolTipList.PRACTICINGCERTIFICATENO?.HOVER  : ''"
                                    [(ngModel)]="userData.PRACTICINGCERTIFICATENO" name="PRACTICINGCERTIFICATENO">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRACTICINGCERTIFICATENO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRACTICINGCERTIFICATENO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}">
                                <mat-label>Comment</mat-label>
                                <textarea matInput
                                    [matTooltip]="toolTipList && toolTipList.COMMENT ? toolTipList.COMMENT?.HOVER  : ''"
                                    [(ngModel)]="userData.COMMENT" name="COMMENT"></textarea>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">Security</mat-expansion-panel-header>
                    <div fxLayout="row" fxLayoutAlign="start center " fxFlex="1 0 auto">
                        <br><br>
                        <mat-accordion class="faqs-accordion">
                            <mat-expansion-panel *ngFor="let Permission of userinfoDatah; let i = index">
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                                        {{Permission.key}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div>
                                    <mat-checkbox name="{{Permission.key}}_{{i}}"
                                        (change)="AllCHecked($event, Permission.key)"> All
                                    </mat-checkbox>
                                </div>
                                <div *ngFor="let checkboxVal of Permission.val;let j = index">
                                    <mat-checkbox [(ngModel)]="userinfoDatah[i]['val'][j].VALUE"
                                        name="{{userinfoDatah[i]['val'][j].NAME}}_{{i}}_{{j}}">
                                        {{checkboxVal.NAME}}
                                    </mat-checkbox>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel mat-align-tabs="start" fxLayout="column"
                    *ngIf="this.currentUser.PRODUCTTYPE=='Solicitor'">
                    <mat-expansion-panel-header class="Bold_panel_header">Rates</mat-expansion-panel-header>
                    <label id="example-radio-group-label" class="rate_tab_label">GST Type</label>
                    <div class="rate_tab_value">
                        <mat-radio-group aria-label="Billing Method" class="example tab_value_child" fxFlex="100"
                            class="pr-4"
                            [matTooltip]="toolTipList && toolTipList.GSTTYPE ? toolTipList.GSTTYPE?.HOVER  : ''"
                            [(ngModel)]="userData.GSTTYPE" name="GSTTYPE">
                            <mat-radio-button value="GST Exclusive">Exclusive
                            </mat-radio-button>
                            <mat-radio-button value="GST Inclusive">Inclusive </mat-radio-button>
                            <mat-radio-button value="GST Free">Free</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div>
                        <div fxFlex="14">
                        </div>
                        <div fxFlex="43" class="text-center">
                            <mat-label>$ / hr</mat-label>
                        </div>
                        <div fxFlex="43" class="text-center">
                            <mat-label>$ / day</mat-label>
                        </div>
                    </div>
                    <ng-container *ngIf="rateList.length > 0">
                        <div class="mat-rate-list">
                            <div fxFlex="14"> <!--class="lable-div" -->
                               <mat-label>{{rateList[0].LOOKUPFULLVALUE}}</mat-label>
                           </div>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_1 ? toolTipList.RATEPERHOUR_1?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERHOUR_1" name="RATEPERHOUR_1">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_1?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_1?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERDAY_1 ? toolTipList.RATEPERDAY_1?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERDAY_1" name="RATEPERDAY_1">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY_1?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERDAY_1?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                       </div>
                       <div class="mat-rate-list">
                           <div fxFlex="14" class="">
                               <mat-label>{{rateList[1].LOOKUPFULLVALUE}}</mat-label>
                           </div>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_2 ? toolTipList.RATEPERHOUR_2?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERHOUR_2" name="RATEPERHOUR_2">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERDAY_2 ? toolTipList.RATEPERDAY_2?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERDAY_2" name="RATEPERDAY_2">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY_2?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERDAY_2?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                       </div>
                       <div class="mat-rate-list">
                           <div fxFlex="14" class="">
                               <mat-label>{{rateList[2].LOOKUPFULLVALUE}}</mat-label>
                           </div>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_3 ? toolTipList.RATEPERHOUR_3?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERHOUR_3" name="RATEPERHOUR_3">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_3?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_3?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERDAY_3 ? toolTipList.RATEPERDAY_3?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERDAY_3" name="RATEPERDAY_3">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_3?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_3?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                       </div>
                       <div class="mat-rate-list">
                           <div fxFlex="14">
                               <mat-label>{{rateList[3].LOOKUPFULLVALUE}}</mat-label>
                           </div>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_4 ? toolTipList.RATEPERHOUR_4?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERHOUR_4" name="RATEPERHOUR_4">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_4?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_4?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERDAY_4 ? toolTipList.RATEPERDAY_4?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERDAY_4" name="RATEPERDAY_4">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_4?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_4?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                       </div>
                       <div class="mat-rate-list">
                           <div fxFlex="14">
                               <mat-label>{{rateList[4].LOOKUPFULLVALUE}}</mat-label>
                           </div>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_5 ? toolTipList.RATEPERHOUR_5?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERHOUR_5" name="RATEPERHOUR_5">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_5?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_5?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERDAY_5 ? toolTipList.RATEPERDAY_5?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERDAY_5" name="RATEPERDAY_5">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_5?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_5?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                       </div>
                       <div class="mat-rate-list">
                           <div fxFlex="14">
                               <mat-label>{{rateList[5].LOOKUPFULLVALUE}}</mat-label>
                           </div>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERHOUR_6 ? toolTipList.RATEPERHOUR_6?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERHOUR_6" name="RATEPERHOUR_6">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_6?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_6?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                           <mat-form-field appearance="outline" class="example" fxFlex="43" class="pr-4">
                               <input matInput type="number"
                                   [matTooltip]="toolTipList && toolTipList.RATEPERDAY_6 ? toolTipList.RATEPERDAY_6?.HOVER  : ''"
                                   [(ngModel)]="userData.RATEPERDAY_6" name="RATEPERDAY_6">
                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR_6?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEPERHOUR_6?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                               </mat-form-field>
                       </div>
                    </ng-container>
                </mat-expansion-panel>

                <mat-expansion-panel mat-align-tabs="start" *ngIf="('' | productType)" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">Rates Overrides</mat-expansion-panel-header>
                    <div class="one" fxLayout="column" *ngIf="sortdata">
                        <div class="example-containerdata">
                            <table mat-table #table [dataSource]="sortdata" matSort [@animateStagger]="{value:'50'}">
                                <ng-container matColumnDef="RATETYPE">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        Type</th>
                                    <td mat-cell *matCellDef="let row"> {{row.RATETYPE}} </td>
                                </ng-container>

                                <ng-container matColumnDef="LINKDESC">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        Desc</th>
                                    <td mat-cell *matCellDef="let row"> {{row.LINKDESC}} </td>
                                </ng-container>

                                <ng-container matColumnDef="hr1">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/hr1</th>
                                    <td mat-cell *matCellDef="let row"> {{row.hr1}} </td>
                                </ng-container>
                                <ng-container matColumnDef="hr2">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/hr2</th>
                                    <td mat-cell *matCellDef="let row"> {{row.hr2}} </td>
                                </ng-container>
                                <ng-container matColumnDef="hr3">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/hr3</th>
                                    <td mat-cell *matCellDef="let row"> {{row.hr3}} </td>
                                </ng-container>
                                <ng-container matColumnDef="hr4">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/hr4</th>
                                    <td mat-cell *matCellDef="let row"> {{row.hr4}} </td>
                                </ng-container>
                                <ng-container matColumnDef="hr5">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/hr5</th>
                                    <td mat-cell *matCellDef="let row"> {{row.hr5}} </td>
                                </ng-container>
                                <ng-container matColumnDef="hr6">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/hr6</th>
                                    <td mat-cell *matCellDef="let row"> {{row.hr6}} </td>
                                </ng-container>

                                <ng-container matColumnDef="day1">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/day1</th>
                                    <td mat-cell *matCellDef="let row"> {{row.day1}} </td>
                                </ng-container>
                                <ng-container matColumnDef="day2">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/day2</th>
                                    <td mat-cell *matCellDef="let row"> {{row.day2}} </td>
                                </ng-container>
                                <ng-container matColumnDef="day3">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/day3</th>
                                    <td mat-cell *matCellDef="let row"> {{row.day3}} </td>
                                </ng-container>
                                <ng-container matColumnDef="day4">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/day4</th>
                                    <td mat-cell *matCellDef="let row"> {{row.day4}} </td>
                                </ng-container>
                                <ng-container matColumnDef="day5">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/day5</th>
                                    <td mat-cell *matCellDef="let row"> {{row.day5}} </td>
                                </ng-container>
                                <ng-container matColumnDef="day6">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        $/day6</th>
                                    <td mat-cell *matCellDef="let row"> {{row.day6}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsdata; sticky: true"></tr>
                                <tr mat-row
                                    [style.background]="highlightedRowsoverride == row.USERRATEGUID ? selectedColore : ''"
                                    *matRowDef="let row; columns: displayedColumnsdata;"
                                    [ngClass]="highlightedRowsoverride == row.USERRATEGUID ? 'row-text-colore' : ''"
                                    class="contact"
                                    (click)="setActiveoverride(row);currentoverride = row;highlightedRowsoverride = row.USERRATEGUID"
                                    matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                </tr>
                            </table>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSizetates"
                            (page)="onPaginateChangeOverriderates($event)" showFirstLastButtons></mat-paginator>
                    </div>
                    <div>
                        <mat-dialog-actions align="end" class="popup_ftr">

                            <button mat-raised-button color="accent" class="mat-accent" [disabled]="!selectRow" (click)="budgetDailog('new','Edit Override Rates')" cdkFocusInitial>Edit
                                Override Rates</button>
                            <button mat-raised-button color="primary" (click)="DeleteRate()" [disabled]="!selectRow" cdkFocusInitial>Delete Override Rates</button>
                        </mat-dialog-actions>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel mat-align-tabs="start" *ngIf="('' | productType)" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">InfoTrack</mat-expansion-panel-header>
                    <div>
                        <p fxFlex="100">
                            Enter a username and password for the search provider if you want to use a different
                            <br> login for this user.If nothing is provided here it will use the system default
                            login.
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.SEARCHUSERNAME,'has-warning':errorWarningData.Warning?.SEARCHUSERNAME}">
                            <mat-label>Username</mat-label>
                            <input matInput
                                [matTooltip]="toolTipList && toolTipList.SEARCHUSERNAME ? toolTipList.SEARCHUSERNAME?.HOVER  : ''"
                                [(ngModel)]="userData.SEARCHUSERNAME" name="SEARCHUSERNAME">
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SEARCHUSERNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SEARCHUSERNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.SEARCHPASSWORD,'has-warning':errorWarningData.Warning?.SEARCHPASSWORD}">
                            <mat-label>Password</mat-label>
                            <input matInput
                                [matTooltip]="toolTipList && toolTipList.SEARCHPASSWORD ? toolTipList.SEARCHPASSWORD?.HOVER  : ''"
                                [(ngModel)]="userData.SEARCHPASSWORD" name="SEARCHPASSWORD">
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SEARCHPASSWORD?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SEARCHPASSWORD?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel mat-align-tabs="start" *ngIf="('Budgets' | tierPermission : 'Budgets').ALLOWED ==1" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">Budgets</mat-expansion-panel-header>
                    <div class="one" fxLayout="column">
                        <div class="example-containerdata">
                            <table mat-table #table [dataSource]="userBudgets" matSort [@animateStagger]="{value:'50'}">
                                <ng-container matColumnDef="PERIODSTART">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        Period Start</th>
                                    <td mat-cell *matCellDef="let row"> {{row.PERIODSTART}} </td>
                                </ng-container>

                                <ng-container matColumnDef="TOTALBUDGETHOURS">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        Total Hours</th>
                                    <td mat-cell *matCellDef="let row"> {{row.TOTALBUDGETHOURS}} </td>
                                </ng-container>

                                <ng-container matColumnDef="TOTALBUDGETDOLLARS">
                                    <th mat-header-cell *matHeaderCellDef class="header_title" mat-sort-header>
                                        Total Dollars(Ex)</th>
                                    <td mat-cell *matCellDef="let row">
                                        {{row.TOTALBUDGETDOLLARS | toFixedAmount:true}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row
                                    [style.background]="highlightedRows == row.USERBUDGETGUID ? selectedColore : ''"
                                    *matRowDef="let row; columns: displayedColumns;"
                                    [ngClass]="highlightedRows == row.USERBUDGETGUID ? 'row-text-colore' : ''"
                                    class="contact"
                                    (click)="setActiveBudget(row);currentBudgets = row;highlightedRows = row.USERBUDGETGUID"
                                    matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                </tr>
                            </table>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.user_dialog"
                            (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
                    </div>
                    <div>
                        <mat-dialog-actions align="end" class="popup_ftr">
                            <button mat-raised-button color="accent" class="mat-accent" *ngIf="isNewShow"
                                (click)="budgetDailog('new','ForBudget')" cdkFocusInitial>New Budget</button>
                            <button mat-raised-button color="accent" class="mat-accent"
                                *ngIf="action!='new' && isEditShow" (click)="budgetDailog('edit','ForBudget')" cdkFocusInitial>Edit
                                Budget</button>
                            <button mat-raised-button color="primary" (click)="delete_budget()" cdkFocusInitial>Delete
                                Budget</button>
                        </mat-dialog-actions>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel mat-align-tabs="start" *ngIf="(this.action === 'edit')"
                    fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">Biography, Photo and
                        Signature</mat-expansion-panel-header>
                    <div class="gst_charge tab_value_child">
                        <mat-checkbox [(ngModel)]="userData.DONTSHOWINDIRECTORY" name="DONTSHOWINDIRECTORY">
                            Don't show in Directory</mat-checkbox>
                    </div>

                    <div class="one" fxLayout="column">
                        <!-- <div fxLayout="row wrap" class="pt-12" fxLayoutAlign="end" fxFlex="1 0 auto">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="simple"
                                (change)="onValChange($event.value)">
                                <mat-button-toggle value="simple">Simple</mat-button-toggle>
                                <mat-button-toggle value="HTML">HTML</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div> -->
                        <mat-label class="font-weight-700">BIOGRAPHY</mat-label>

                        <div class="pt-12 user-editorwrap" >
                            <!-- <ckeditor [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc
                                [config]="configsOption" fxFlex="100" class="ckeClass" [editor]="Editor" data=""
                                (change)="changesDetact($event)">
                            </ckeditor> -->
                             <ejs-richtexteditor [fontColor]="fontColor" [backgroundColor]="backgroundColor" [insertImageSettings]='insertImageSettings' [quickToolbarSettings]='quickToolbarSettings'  [toolbarSettings]='toolbarSettings' [pasteCleanupSettings]="pasteCleanupSettings" [(ngModel)]="ckeditorValue"  (change)="changesrichtexteditor($event)"     name="ckeditorValue" class="ckeClass" #ckeditor_full_desc>
                        </ejs-richtexteditor>
                        </div>

                        <!-- <div *ngIf="signatureType=='HTML'">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <textarea class="min_height_textarea" (focusout)="signaturePreview()" matInput
                                    [(ngModel)]="ckeditorValue" name="ckeditorValue"></textarea>
                            </mat-form-field>
                        </div>
                        <mat-label class="font-weight-700" *ngIf="signatureType=='HTML'">Preview</mat-label>

                        <div *ngIf="signatureType=='HTML'">

                            <div fxFlex="100" class="parentHtmlCssDiv">
                                <div [innerHTML]="HtmlCss" class="previewDiv"></div>
                            </div>
                        </div> -->
                    </div>

                    <!-- for photo -->
                    <div class="pt-10">
                        <p class="font-weight-700 mt-10">PHOTO</p>
                        <!-- <div class="dropzone" appFileDragNDrop (filesChangeEmiter)="onFileChange($event,'photo')"
                            *ngIf="finalfileToUpload == '' || (finalfileToUpload && finalfileToUpload[0]) == ''">
                            <div class="text-wrapper">
                                <div class="centered">
                                    <input type="file"  id="file"
                                        (change)="onFileChange($event.target.files,'photo')" accept="image/*" >
                                    <label for="file"><span class="textLink">
                                            <mat-icon style="position: relative; top: 4px;right: 4px;">
                                                cloud_upload</mat-icon>Select your file
                                        </span> or Drop it
                                        here!</label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- <h3 *ngIf="finalfileToUpload == '' || (finalfileToUpload && finalfileToUpload[0]) == ''" style="top: 16px;
                            position: relative;">No file Choosen</h3>

                    <div class="itemimge" *ngIf="finalfileToUpload && finalfileToUpload[0] !== ''">
                        <img style="border-radius: 4px;width: -webkit-fill-available !important;"
                            [src]="(finalfileToUpload && finalfileToUpload[0] !== '')?finalfileToUpload[0]:''"
                            alt="photo image" />
                        <mat-icon class='cursor' (click)="remove('photo')">clear</mat-icon>
                    </div> -->  
                    <!-- ends here ~ for photo -->

                    <div class="control-section" *ngIf="finalfileToUpload">
                      <div class="e-img-editor-sample" style="width:743px;height:300px;">
                      <ejs-imageeditor #imageEditor (selectionChanging)="onSelectionChange($event)"  id="image-editor"  (created)="created()" [toolbar]="customToolbar" (toolbarItemClicked)="toolbarItemClicked($event)" (fileOpened)="openfile($event)">
                      </ejs-imageeditor>
                    </div><br>
                    <button class="e-btn e-primary" (click)="btnClick()">Remove</button>
                    </div>


                    <!-- for signature -->
                    &nbsp;
                    <div class="pt-10">
                        <p class="font-weight-700 mt-10">SIGNATURE</p>
                        <!-- <div class="dropzone" appFileDragNDrop (filesChangeEmiter)="onFileChange($event, 'signature')"
                            *ngIf="!signatureFileToUpload">
                            <div class="text-wrapper">
                                <div class="centered">
                                    <input type="file" name="file1" id="file1"
                                        (change)="onFileChange($event.target.files, 'signature')" accept="image/*">
                                    <label for="file1"><span class="textLink">
                                            <mat-icon style="position: relative; top: 4px;right: 4px;">
                                                cloud_upload</mat-icon>Select your file
                                        </span> or Drop it
                                        here!</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 *ngIf="!signatureFileToUpload"
                        style="top: 16px;
                            position: relative;">No file Choosen</h3>
                    <div class="itemimge" *ngIf="signatureFileToUpload">
                        <img style="border-radius: 4px;width: -webkit-fill-available !important;"
                            [src]="(signatureFileToUpload && signatureFileToUpload[0] !== '') ? signatureFileToUpload[0] : ' '"
                            alt="file1 image" />
                        <mat-icon class='cursor' (click)="remove('signature')">clear</mat-icon>
                    </div> -->

                    <!-- ends here ~ for signature -->

                    <!-- <app-signature [imageURl]="signatureFileToUpload" (SignaturePathData)="getSignatureData($event)"></app-signature> -->
                    <div class="control-section" *ngIf="signatureFileToUpload">
                        <div class="e-img-editor-sample" style="width:743px;height:300px;">
                        <ejs-imageeditor #imageEditor2 id="image-editor-2" (selectionChanging)="onSelectionChangeSignature($event)" (created)="uploadSignature()" [toolbar]="customToolbardata" (toolbarItemClicked)="toolbarItemClickedForImage($event)" (fileOpened)="openSignature($event)">
                        </ejs-imageeditor>
                      </div><br>
                      <button class="e-btn e-primary" (click)="btnClick2()">Remove</button>
                      </div>
                    </div>

                </mat-expansion-panel>
                <mat-expansion-panel mat-align-tabs="start" *ngIf="(this.action === 'edit')" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header">Invoicing</mat-expansion-panel-header>
                    <div class="one" fxLayout="column">
                        <mat-label class="font-weight-700">These bank details will be used in the payment section of an invoice instead of the default bank details when this user is the person responsible for the invoice. The person responsible is set for each individual Invoice Template. See more <a href="https://community.silq.com.au/en/set-up-different-bank-accounts-to-apply-on-certain-invoices" target="_blank">here</a></mat-label>
                    </div>
                    <br>
                    <div class="one" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.INVOICEBANKBSB,'has-warning':errorWarningData.Warning?.INVOICEBANKBSB}">
                                <mat-label>BSB</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.INVOICEBANKBSB ? toolTipList.INVOICEBANKBSB?.HOVER  : ''"
                                    [(ngModel)]="userData.INVOICEBANKBSB" name="INVOICEBANKBSB">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICEBANKBSB?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICEBANKBSB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.INVOICEBANKACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.INVOICEBANKACCOUNTNUMBER}">
                                <mat-label>A/C Number</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.INVOICEBANKACCOUNTNUMBER ? toolTipList.INVOICEBANKACCOUNTNUMBER?.HOVER  : ''"
                                    [(ngModel)]="userData.INVOICEBANKACCOUNTNUMBER" name="INVOICEBANKACCOUNTNUMBER">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICEBANKACCOUNTNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICEBANKACCOUNTNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.INVOICEBANKACCOUNTNAME,'has-warning':errorWarningData.Warning?.INVOICEBANKACCOUNTNAME}">
                                <mat-label>Account Name</mat-label>
                                <input matInput
                                    [matTooltip]="toolTipList && toolTipList.INVOICEBANKACCOUNTNAME ? toolTipList.INVOICEBANKACCOUNTNAME?.HOVER  : ''"
                                    [(ngModel)]="userData.INVOICEBANKACCOUNTNAME" name="INVOICEBANKACCOUNTNAME">
                                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICEBANKACCOUNTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICEBANKACCOUNTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" (click)="SaveUser()" cdkFocusInitial [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>{{dialogButton}}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
