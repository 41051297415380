import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {
  constructor() { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }
}
