<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_allowance">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form"  #estateForm="ngForm" (ngSubmit)="OnSubmit(estateForm)">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.CONTACTGUID,'has-warning':errorWarningData.Warning?.CONTACTGUID}" class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Contact Name</mat-label>

                        <mat-chip-list #chipListContact aria-label="Matter selection">
                            <mat-chip *ngFor="let Contactdata of Contactname" (removed)="removeContact(Contactdata)" class="mat-chips-selected">
                                {{Contactdata| slice:0:40}}<ng-container *ngIf="Contactdata.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input  matInput
                                (keydown.Tab)="Contactdatashow($event)"  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}"
                                [(ngModel)]="EstateAssetsForm.CONTACTNAME" #MatterInput2 [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUp($event)" [ngClass]="Contactname.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1" *ngFor="let ContactData of Contactlist" (click) ="selectedContact(ContactData)">
                                {{ContactData.CONTACTNAME}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)='ContactMatter()' [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>

                        <!-- <input matInput [(ngModel)]="EstateAssetsForm.CONTACTNAME" name="CONTACTNAME" #CONTACTNAME="ngModel" matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}"> -->
                        <input hidden matInput [(ngModel)]="EstateAssetsForm.CONTACTGUID" name="CONTACTGUID" #CONTACTGUID="ngModel">

                        <!-- <mat-icon matSuffix (click)='ContactMatter()'>
                            <img class="seticon" src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Select Contact" matTooltipPosition="above">
                        </mat-icon> -->
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTGUID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACTGUID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <!-- <button type="button" mat-fab color="primary" class="add-contect-btn" *ngIf="action === 'new'" (click)="selectNewContect()">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button> -->

                    <mat-icon class="add-btn-safe"  *ngIf="action === 'new'"  [ngClass]="appPermissions[3]?.Create==0 ? 'disabled-click large-button' : 'large-button'" (click)="selectNewContect()">add_circle</mat-icon>

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Asset Type</mat-label>
                        <mat-select [(ngModel)]="EstateAssetsForm.ASSETTYPE" name="ASSETTYPE" #ASSETTYPE="ngModel" matTooltip="{{(toolTipList)?toolTipList.ASSETTYPE?.HOVER:''}}">
                            <mat-option *ngFor="let assetType of AssetTypeData" [value]="assetType.LOOKUPFULLVALUE">{{assetType.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ASSETTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ASSETTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Description</mat-label>
                        <textarea matInput matTooltip="{{(toolTipList)?toolTipList.ASSETDESCRIPTION?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.ASSETDESCRIPTION" name="ASSETDESCRIPTION" #ASSETDESCRIPTION="ngModel"></textarea>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ASSETDESCRIPTION?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.ASSETDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="33" class="pr-4">
                        <mat-label>Value At Death</mat-label>
                        <!-- [value]="EstateAssetsForm.VALUEATDEATH | number: '1.2-2'" -->
                        <!-- mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" -->
                        <!-- matTooltip="{{(toolTipList)?toolTipList.VALUEATDEATH?.HOVER:''}}" -->
                        <!-- type="float" -->
                        <!-- (focusout)="modelChanged($event, 'VALUEATDEATH')" -->
                        <input style="text-align: right;" matInput [(ngModel)]="EstateAssetsForm.VALUEATDEATH" name="VALUEATDEATH" #VALUEATDEATH="ngModel" (blur)="calculate(EstateAssetsForm.VALUEATDEATH, EstateAssetsForm.INTERESTACCRUED ,1)" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VALUEATDEATH?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.VALUEATDEATH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="34" class="pr-4">
                        <mat-label>Interest</mat-label>
                        <!-- currencyMask -->
                        <!-- (focusout)="modelChanged($event, 'INTERESTACCRUED')" -->
                        <input style="text-align: right;" matInput matTooltip="{{(toolTipList)?toolTipList.INTERESTACCRUED?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.INTERESTACCRUED" name="INTERESTACCRUED" #INTERESTACCRUED="ngModel" (blur)="calculate(EstateAssetsForm.VALUEATDEATH, EstateAssetsForm.INTERESTACCRUED ,1)" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTACCRUED?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INTERESTACCRUED?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="33" class="pr-4">
                        <mat-label>Total</mat-label>
                        <input currencyMask matInput matTooltip="{{(toolTipList)?toolTipList.TOTALVALUE?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.TOTALVALUE" name="TOTALVALUE" #TOTALVALUE="ngModel" [disabled]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALVALUE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TOTALVALUE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" >
                    <mat-form-field class="example" appearance="outline" fxFlex="46" class="pr-4">
                        <!-- [ngClass]="{'has-error':errorWarningData.Error?.TENANCY,'has-warning':errorWarningData.Warning?.TENANCY}"  -->
                        <mat-label>Tenancy</mat-label>
                        <mat-select [(ngModel)]="EstateAssetsForm.TENANCY" name="TENANCY" #TENANCY="ngModel" (selectionChange)="selectTenancy($event.value)" matTooltip="{{(toolTipList)?toolTipList.TENANCY?.HOVER:''}}">
                            <mat-option *ngFor="let tenancy of TenancyData" [value]="tenancy.LOOKUPFULLVALUE">{{tenancy.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TENANCY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TENANCY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="22" class="pr-4">
                        <mat-label>Tenants In Common # Shares</mat-label>
                        <input type="number" matInput matTooltip="{{(toolTipList)?toolTipList.TENANTSINCOMMONSHARES?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.TENANTSINCOMMONSHARES" name="TENANTSINCOMMONSHARES" #TENANTSINCOMMONSHARES="ngModel" [disabled]="tenantsInCommon">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TENANTSINCOMMONSHARES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TENANTSINCOMMONSHARES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="32" class="pr-4">
                        <mat-label>TIC Deceased Share</mat-label>
                        <!-- currencyMask -->
                        <!-- (focusout)="modelChanged($event, 'TENANTINCOMMONDECEASEDSHARE')" -->
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.TENANTINCOMMONDECEASEDSHARE?.HOVER:''}}" style="text-align: right;" [(ngModel)]="EstateAssetsForm.TENANTINCOMMONDECEASEDSHARE" name="TENANTINCOMMONDECEASEDSHARE" #TENANTINCOMMONDECEASEDSHARE="ngModel" [disabled]="TICdeceasedShare" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TENANTINCOMMONDECEASEDSHARE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TENANTINCOMMONDECEASEDSHARE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>TIC Names and Addresses</mat-label>
                        <textarea type="number"  matInput matTooltip="{{(toolTipList)?toolTipList.JOINTTENANTSDETAILS?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.JOINTTENANTSDETAILS" name="JOINTTENANTSDETAILS" #JOINTTENANTSDETAILS="ngModel" [disabled]="TICNameAndAddress"></textarea>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.JOINTTENANTSDETAILS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.JOINTTENANTSDETAILS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Account Description:</mat-label>
                        <mat-select [(ngModel)]="EstateAssetsForm.ACCOUNTDESCRIPTION" name="ACCOUNTDESCRIPTION" #ACCOUNTDESCRIPTION="ngModel" matTooltip="{{(toolTipList)?toolTipList.ACCOUNTDESCRIPTION?.HOVER:''}}">
                            <mat-option *ngFor="let accountDesc of AssetAccountDescData" [value]="accountDesc.LOOKUPFULLVALUE">{{accountDesc.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTDESCRIPTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Account Details</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.ACCOUNTDETAILS?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.ACCOUNTDETAILS	" name="ACCOUNTDETAILS	" #ACCOUNTDETAILS	="ngModel">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTDETAILS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTDETAILS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="33" class="pr-4">
                        <mat-label>Action</mat-label>
                        <mat-select [(ngModel)]="EstateAssetsForm.ASSETACTION" name="ASSETACTION" #ASSETACTION="ngModel" matTooltip="{{(toolTipList)?toolTipList.ASSETACTION?.HOVER:''}}">
                            <mat-option *ngFor="let assetAction of AssetActionData" [value]="assetAction.LOOKUPFULLVALUE">{{assetAction.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ASSETACTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ASSETACTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="34" class="pr-4">
                        <mat-label>Transferred To
                        </mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.TRANSFERREDTO?.HOVER:''}}" [(ngModel)]="EstateAssetsForm.TRANSFERREDTO" name="TRANSFERREDTO" #TRANSFERREDTO="ngModel">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TRANSFERREDTO?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.TRANSFERREDTO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                        <mat-label>Date Incurred</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.DATETRANSFERRED?.HOVER:''}}" [matDatepicker]="picker" [(ngModel)]="EstateAssetsForm.DATETRANSFERRED" name="DATETRANSFERRED" #DATETRANSFERRED="ngModel">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATETRANSFERRED?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.DATETRANSFERRED?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button (click)="OnSubmit()" mat-raised-button color="accent" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>{{action === 'edit'? 'Update': 'Save'}}
        </button>
        <!-- <button mat-raised-button color="accent" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button> -->
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>