<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_allowance">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="liabilityForm"  #liabilityForm="ngForm" (ngSubmit)="OnSubmit(liabilityForm)">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.CONTACTGUID,'has-warning':errorWarningData.Warning?.CONTACTGUID}" class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Contact Name</mat-label>

                        <mat-chip-list #chipListContact aria-label="Matter selection">
                            <mat-chip *ngFor="let Contactdata of Contactname" (removed)="removeContact(Contactdata)" class="mat-chips-selected">
                                {{Contactdata| slice:0:40}}<ng-container *ngIf="Contactdata.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input  matInput
                                (keydown.Tab)="Contactdatashow($event)"  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}"
                                [(ngModel)]="EstateLiabilityForm.CONTACTNAME" #MatterInput2 [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUp($event)" [ngClass]="Contactname.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1" *ngFor="let ContactData of Contactlist" (click) ="selectedContact(ContactData)">
                                {{ContactData.CONTACTNAME}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)='ContactMatter()' [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>
                        
                        <!-- <input matInput [(ngModel)]="EstateLiabilityForm.CONTACTNAME" name="CONTACTNAME" #CONTACTNAME="ngModel"> -->
                        <input hidden matInput [(ngModel)]="EstateLiabilityForm.CONTACTGUID" name="CONTACTGUID" #CONTACTGUID="ngModel">
                        <!-- <mat-icon matSuffix (click)='ContactMatter()'>
                            <img class="seticon" src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Select Contact" matTooltipPosition="above">
                        </mat-icon> -->
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <button type="button" mat-fab color="primary" class="add-contect-btn" *ngIf="action === 'new'" (click)="selectNewContect()">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button>

                    <!-- <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label> Date Incurred</mat-label>
                        <input matInput [(ngModel)]="EstateLiabilityForm.DATEINCURRED" name="DATEINCURRED" #DATEINCURRED="ngModel">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                        <mat-label>Date Incurred</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="EstateLiabilityForm.DATEINCURRED" matTooltip="{{(toolTipList)?toolTipList.DATEINCURRED?.HOVER:''}}" name="DATEINCURRED" #DATEINCURRED="ngModel">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEINCURRED?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATEINCURRED?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Description</mat-label>
                        <textarea matInput [(ngModel)]="EstateLiabilityForm.LIABILITYDESCRIPTION" name="LIABILITYDESCRIPTION" matTooltip="{{(toolTipList)?toolTipList.LIABILITYDESCRIPTION?.HOVER:''}}" #LIABILITYDESCRIPTION="ngModel"></textarea>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LIABILITYDESCRIPTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.LIABILITYDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Amount</mat-label>
                        <!-- currencyMask -->
                        <input matInput style="text-align: right;" type="float" (focusout)="modelChanged($event, 'AMOUNT')" [(ngModel)]="EstateLiabilityForm.AMOUNT" name="AMOUNT" matTooltip="{{(toolTipList)?toolTipList.AMOUNT?.HOVER:''}}" #AMOUNT="ngModel">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    <mat-checkbox [(ngModel)]="EstateLiabilityForm.SECURED" name="SECURED" #SECURED="ngModel">Secured?</mat-checkbox>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button (click)="OnSubmit()" mat-raised-button color="accent" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>{{action === 'edit'? 'Update': 'Save'}}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>