<div class="main-container">
    <div class="container" [ngStyle]="{'height':isAuthAllowed? '100%': 'auto'}">
      <div *ngIf="!isAuthAllowed">
        <mat-card class="main-section-1" *ngIf="AWAITING2FA == 'false' || AWAITING2FA == 'default'">
            <div class="inner-wrap">
              <img src="assets/images/mfa/banner.png" class="banner-pc" alt="">
              <h1>Add a second layer of security</h1>
              <p>To set up multi-factor authentication, you'll an <b>anthenticator app on your phone</b> and <b>backup email address or security questions</b>.</p>
                <button matbutton class="btn-blue" (click)="openNewtab()"> Set up multi-factor authentication</button>
            </div>
          </mat-card>
          <mat-card class="main-section-1" *ngIf="AWAITING2FA == 'true'">
            <div class="inner-wrap">
              <img src="assets/images/mfa/banner.png" class="banner-pc" alt="">
              <h1>Add a second layer of security</h1>
              <p>To set up multi-factor authentication, you'll an <b>anthenticator app on your phone</b> and <b>backup email address or security questions</b>.</p>
                <button matbutton class="btn-blue" (click)="openNewtab()"> Turn Off multi-factor authentication</button>
            </div>
          </mat-card>
        
          <!--  Step 2 -->
        
        <mat-card class="main-section-2">
            <div class="inner-wrap-two">
              <div class="row">
                <div class="col-7">
                  <div class="inner-text-wrap">
                    <h3>What is MFA?</h3>
                    <p>
                      Multi Factor Authentication (MFA) is an authentication method that
                      requires two or more ways to gain access to your account. MFA adds
                      an extra layer of protection to your SILQ data. As well as knowing
                      your username and password, you will also need another factor such
                      as a code from an authenticator app on your mobile.
                    </p>
                  </div>
                  <div class="inner-text-wrap" style="margin-top: 50px;">
                    <h3>How does MFA protect you?</h3>
                    <p>
                      MFA adds another layer of protection to your account. Cybercriminals
                      have 15 billion credentials from data leaks. MFA protects you from
                      these cybercriminals because even if someone knows your username and
                      password, they won’t be able to access your SILQ data.
                    </p>
                    <p>
                      Once MFA is set up on your SILQ account you’ll need your password and
                      authenticator app, secondary email or security question to login.
                      After both forms of authentication are completed, only then will you
                      be able to login to SILQ.
                    </p>
            
                    <a href="#" class="btn-link">Learn more</a>
                  </div>
                </div>
                <div class="col-3">
                  <img src="assets/images/mfa//silk-folder.png" class="mx-210" alt="" />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-10">
                  <h3>How does MFA protect you?</h3>
                  <p>
                    MFA adds another layer of protection to your account. Cybercriminals
                    have 15 billion credentials from data leaks. MFA protects you from
                    these cybercriminals because even if someone knows your username and
                    password, they won’t be able to access your SILQ data.
                  </p>
                  <p>
                    Once MFA is set up on your SILQ account you’ll need your password and
                    authenticator app, secondary email or security question to login.
                    After both forms of authentication are completed, only then will you
                    be able to login to SILQ.
                  </p>
          
                  <a href="#" class="btn-link">Learn more</a>
                </div>
              </div> -->
            </div>
        </mat-card>
        <mat-card class="main-section-2">
            <div class="inner-wrap-two">
              <div class="row">
                <div class="col-3">
                  <img src="assets/images/mfa/silk.code.png" class="mx-210" alt="" />
                </div>
                <div class="col-7">
                  <div class="inner-text-wrap">
                    <h3>What’s needed?</h3>
                    <ol class="number-list">
                      <li>Google authenticator app installed on your mobile device</li>
                      <li>
                        A <b>backup email address</b> (different to the one used on SILQ)
                        or to choose security questions instead.
                      </li>
                    </ol>
                    <p>
                      By clicking on the link below, SILQ will step you through the
                      process of setting this up for your account.
                    </p>
                    <a href="#" class="btn-link">Learn more</a>
                  </div>
                </div>
              </div>
            </div>
        </mat-card>
        <div class="text-center mb-30 btn">
          <button *ngIf="AWAITING2FA == 'false' || AWAITING2FA == 'default'" (click)="openNewtab()" matbutton class="btn-blue"> Set up multi-factor authentication</button>
          <button *ngIf="AWAITING2FA == 'true'" matbutton class="btn-blue" (click)="openNewtab()"> Turn Off multi-factor authentication</button>
        </div>

      </div>
        
      <!--  Step 3 -->

    </div>

    </div>