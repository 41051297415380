<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="GeneralForm" id="GeneralForm" [formGroup]="GeneralForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.DATE,'has-warning':errorWarningData.Warning?.DATE}">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" formControlName="DATEGENERAL">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DESCRIPTION,'has-warning':errorWarningData.Warning?.DESCRIPTION}">
                        <mat-label>Description</mat-label>
                        <input matInput name="DESCRIPTION" value="GJ:" formControlName="DESCRIPTION">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
                    <div fxFlex="70" class="pr-4">
                        <div class="general-item-tbl">
                            <table mat-table [dataSource]="generalTempData" class="mat-elevation-z8">
                                <!-- Number Column -->
                                <ng-container matColumnDef="Number">
                                    <th mat-header-cell *matHeaderCellDef> Number </th>
                                    <td mat-cell *matCellDef="let element"> {{element.ACCOUNTNUMBER}} </td>
                                </ng-container>

                                <!-- Account Column -->
                                <ng-container matColumnDef="Account">
                                    <th mat-header-cell *matHeaderCellDef> Account </th>
                                    <td mat-cell *matCellDef="let element"> {{element.ACCOUNTNAME}} </td>
                                </ng-container>

                                <!-- Debit Column -->
                                <ng-container matColumnDef="Debit">
                                    <th mat-header-cell *matHeaderCellDef> Debit </th>
                                    <td mat-cell *matCellDef="let element"> {{element.DEBITAMOUNT}} </td>
                                </ng-container>

                                <!-- Credit Column -->
                                <ng-container matColumnDef="Credit">
                                    <th mat-header-cell *matHeaderCellDef> Credit </th>
                                    <td mat-cell *matCellDef="let element"> {{element.CREDITAMOUNT}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; let index = index; columns: displayedColumns;" [style.background]="itemRowIndex == index ? selectedColore : ''" [ngClass]="itemRowIndex ==index ? 'row-text-colore' : ''" (click)="itemRowClick(index)"></tr>
                            </table>
                        </div>

                        <!-- <table mat-table class="simple" [@animateStagger]="{value:'50'}">
                            <tr mat-header-row>
                                <th mat-header-cell>NUMBER</th>
                                <th mat-header-cell>ACCOUNT</th>
                                <th mat-header-cell>DEBIT</th>
                                <th mat-header-cell>CREDIT</th>
                            </tr>
                            <tr mat-row *ngFor="let item of CREDITDEBITDATA; let index = index" [style.background]="highlightedRows == index ? selectedColore : ''" [ngClass]="highlightedRows == index ? 'row-text-colore' : ''" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                <td mat-cell>{{item.ACCOUNTNUMBER}}</td>
                                <td mat-cell>{{item.ACCOUNTNAME}}</td>
                                <td mat-cell>{{item.DEBITAMOUNT}}</td>
                                <td mat-cell>{{item.CREDITAMOUNT}}</td>
                            </tr>
                    </table> -->
                        <div class="mt-12">
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <!-- DEBITSTOTAL -> DEBITS -->
                                <mat-form-field appearance="outline" fxFlex="50" class="pr-4 gray_out" [ngClass]="{'has-error':errorWarningData.Error?.DEBITS,'has-warning':errorWarningData.Warning?.DEBITS}">
                                    <mat-label>Debit Total</mat-label>
                                    <input currencyMask matInput name="DEBITSTOTAL" [(ngModel)]="DEBITSTOTAL" formControlName="TOTALDEBIT" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" class="pr-4 gray_out" [ngClass]="{'has-error':errorWarningData.Error?.CREDITSTOTAL,'has-warning':errorWarningData.Warning?.CREDITSTOTAL}">
                                    <mat-label>Credit Total</mat-label>
                                    <input currencyMask matInput name="CREDITSTOTAL" [(ngModel)]="CREDITSTOTAL" formControlName="TOTALCREDITS" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="30" class="pr-4">
                        <div *ngIf="btnhide === 'NewDelete'">
                            <button mat-raised-button color="accent" class="mat-accent mb-12 w-112" (click)="NewItem()">New Item</button>
                            <button mat-raised-button color="primary" class="ml-20 mb-12 w-112" (click)="DeleteItem()" *ngIf='CREDITDEBITDATA.length>0 && itemRowIndex!=undefined'>Delete Item </button>
                        </div>
                        <div *ngIf="btnhide === 'AddCancel'">
                            <button mat-raised-button color="accent" class="mat-accent mb-12 w-112" (click)="AddItem()">Add Item</button>
                            <button mat-raised-button color="primary" class="ml-20 mb-12 w-112" (click)="CancelItem()">Cancel Item</button>
                        </div>
                    </div>
                </div>


                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="35" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT}">
                        <mat-label>Amount</mat-label>
                        <!-- currencyMask -->
                        <input style="text-align: right;" [value]="GeneralForm.get('GAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [readonly]="isDisable" name="AMOUNT" matInput [(ngModel)]="craditDEbitData.AMOUNT" formControlName="GAMOUNT" [leadZero]="true">
                    </mat-form-field>
                    <mat-radio-group fxFlex="30" class="pr-4" [(ngModel)]="craditDEbitData.DRCR" name="DRCR" [disabled]="isDisable" class="pr-4" formControlName="CRDR" [ngClass]="{'has-error':errorWarningData.Error?.DRCR,'has-warning':errorWarningData.Warning?.DRCR}">
                        <mat-radio-button value="DR">DR</mat-radio-button>
                        <mat-radio-button value="CR">CR</mat-radio-button>
                    </mat-radio-group>
                    <mat-form-field appearance="outline" fxFlex="35" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTGUID,'has-warning':errorWarningData.Warning?.ACCOUNTGUID}">
                        <mat-label>Account</mat-label>
                        <input [disabled]="isDisable" matInput name="ACCOUNTGUID" [(ngModel)]="craditDEbitData.ACCOUNTGUID" formControlName="ACCOUNT" readonly>
                        <mat-icon matSuffix>
                            <button id="accountnew" class="matternews" [disabled]="isDisable" (click)='SelecteAccount()'><img src="assets/icons/web_app/icon_receipts_small_o.ico">
                            </button>
                        </mat-icon>
                    </mat-form-field>

                </div>

            </form>
        </div>

    </div>

    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <button mat-raised-button color="accent" class="mat-accent" class="mat-accent" (click)="SaveGeneral()" [disabled]="isspiner"><mat-spinner *ngIf="isspiner"></mat-spinner>{{ButtonText}}</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
        </div>
    </mat-dialog-actions>

</div>