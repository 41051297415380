<div class=" one " fxLayout="column" [formGroup]="matterTrademarkForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.APPLICATIONNO,'has-warning':errorWarningData.Warning?.APPLICATIONNO}">
            <mat-label>Application Number</mat-label>
            <input matInput formControlName="APPLICATIONNO" matTooltip="{{(toolTipList)?toolTipList.APPLICATIONNO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.APPLICATIONNO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.APPLICATIONNO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPLICATIONNO?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
        <mat-form-field *ngIf ="(this.action == 'edit' || this.action == 'duplicate') " appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.APPLICATIONDATE ,'has-warning':errorWarningData.Warning?.APPLICATIONDATE }">
            <mat-label>Application Date</mat-label>
            <input  formControlName="APPLICATIONDATE" (dateChange)="CommanDatePickerEvent('change', $event)" matInput name="APPLICATIONDATE" [matDatepicker]="picker1"  matTooltip="{{(toolTipList)?toolTipList.APPLICATIONDATE?.HOVER :''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.APPLICATIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.APPLICATIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPLICATIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field *ngIf ="(this.action == 'edit' || this.action == 'duplicate') " appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.JURISDICTION ,'has-warning':errorWarningData.Warning?.JURISDICTION }">
            <mat-label>Jurisdiction </mat-label>
            <input matInput formControlName="JURISDICTION" matTooltip="{{(toolTipList)?toolTipList.JURISDICTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  maxlength="100">
            <div  *ngIf="testBool && toolTipList.JURISDICTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.JURISDICTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.JURISDICTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field *ngIf ="(this.action == 'edit' || this.action == 'duplicate') " appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.TYPEOFTRADEMARK ,'has-warning':errorWarningData.Warning?.TYPEOFTRADEMARK }">
            <mat-label>Type of TradeMark </mat-label>
            <input matInput formControlName="TYPEOFTRADEMARK" matTooltip="{{(toolTipList)?toolTipList.TYPEOFTRADEMARK ?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  maxlength="100">
            <div  *ngIf="testBool && toolTipList.TYPEOFTRADEMARK" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TYPEOFTRADEMARK.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TYPEOFTRADEMARK?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field  *ngIf ="(this.action == 'edit' || this.action == 'duplicate') " appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.TRADEMARKCLASSES ,'has-warning':errorWarningData.Warning?.TRADEMARKCLASSES }">
            <mat-label>TradeMark Class</mat-label>
            <input matInput formControlName="TRADEMARKCLASSES" matTooltip="{{(toolTipList)?toolTipList.TRADEMARKCLASSES?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  maxlength="20">
            <div  *ngIf="testBool && toolTipList.TRADEMARKCLASSES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TRADEMARKCLASSES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TRADEMARKCLASSES ?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    <!-- </div> -->
</div>