<div class=" one " fxLayout="column" [formGroup]="MatterConveyancingDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.FINANCEDATE,'has-warning':errorWarningData.Warning?.FINANCEDATE}">
            <mat-label>Finance Date</mat-label>
            <input
                (dateInput)="CommanDatePickerClick('input', $event,'FINANCEDATE')"
                matTooltip="{{(toolTipList)?toolTipList.FINANCEDATE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="FINANCEDATETEXT"
                (dateChange)="CommanDatePickerClick('input', $event,'FINANCEDATE')"
                matInput name="FinanceDate" [matDatepicker]="FinanceDate">
            <mat-datepicker-toggle matSuffix [for]="FinanceDate"></mat-datepicker-toggle>
            <mat-datepicker #FinanceDate></mat-datepicker>
            <div *ngIf="testBool && toolTipList.FINANCEDATE"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.FINANCEDATE.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FINANCEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.BANKREFERENCE,'has-warning':errorWarningData.Warning?.BANKREFERENCE}">
            <mat-label>Bank Ref</mat-label>
            <input matInput
                matTooltip="{{(toolTipList)?toolTipList.BANKREFERENCE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="BANKREFERENCE">
            <div *ngIf="testBool && toolTipList.BANKREFERENCE"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.BANKREFERENCE.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKREFERENCE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" class="mb-16"
        fxFlex="1 0 auto">
        <mat-checkbox formControlName="BUILDINGREPORTCOMPLETED" fxFlex="50"
            class="pr-4">Building Report Completed</mat-checkbox>
        <mat-checkbox formControlName="PESTREPORTCOMPLETED" fxFlex="50"
            class="pr-4">Pest Report Completed</mat-checkbox>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SPECIALCONDITIONS,'has-warning':errorWarningData.Warning?.SPECIALCONDITIONS}">
            <mat-label> Special Conditions</mat-label>
            <textarea matInput
                matTooltip="{{(toolTipList)?toolTipList.SPECIALCONDITIONS?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="SPECIALCONDITIONS"></textarea>
            <div *ngIf="testBool && toolTipList.SPECIALCONDITIONS"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.SPECIALCONDITIONS.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SPECIALCONDITIONS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PAYABLEBYPURCHASER,'has-warning':errorWarningData.Warning?.PAYABLEBYPURCHASER}">
            <mat-label> Payable By Purchaser</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PAYABLEBYPURCHASER?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="PAYABLEBYPURCHASER" style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('PAYABLEBYPURCHASER').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
            <div *ngIf="testBool && toolTipList.PAYABLEBYPURCHASER"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.PAYABLEBYPURCHASER.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYABLEBYPURCHASER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PAYABLEBYVENDOR,'has-warning':errorWarningData.Warning?.PAYABLEBYVENDOR}">
            <mat-label> Payable By Vendor</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PAYABLEBYVENDOR?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="PAYABLEBYVENDOR" style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('PAYABLEBYVENDOR').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
            <div *ngIf="testBool && toolTipList.PAYABLEBYVENDOR"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.PAYABLEBYVENDOR.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYABLEBYVENDOR?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.TOTALDUE,'has-warning':errorWarningData.Warning?.TOTALDUE}">
            <mat-label> Total Due</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.TOTALDUE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="TOTALDUE" style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('TOTALDUE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  [leadZero]="true">
            <div *ngIf="testBool && toolTipList.TOTALDUE" class="tooltip-wrap"><input
                    id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.TOTALDUE.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALDUE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CHEQUESDUE,'has-warning':errorWarningData.Warning?.CHEQUESDUE}">
            <mat-label>Cheques Due</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CHEQUESDUE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="CHEQUESDUE" style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('CHEQUESDUE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
            <div *ngIf="testBool && toolTipList.CHEQUESDUE" class="tooltip-wrap"><input
                    id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.CHEQUESDUE.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHEQUESDUE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.UNPAIDAMOUNT,'has-warning':errorWarningData.Warning?.UNPAIDAMOUNT}">
            <mat-label>Unpaid Amount</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.UNPAIDAMOUNT?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="UNPAIDAMOUNT" style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('UNPAIDAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
            <div *ngIf="testBool && toolTipList.UNPAIDAMOUNT"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.UNPAIDAMOUNT.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.UNPAIDAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.VENDORPAYS,'has-warning':errorWarningData.Warning?.VENDORPAYS}">
            <mat-label>Who pays for settlement day?</mat-label>
            <mat-select
                matTooltip="{{(toolTipList)?toolTipList.VENDORPAYS?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="VENDORPAYS">
                <mat-option value="System Default">System Default</mat-option>
                <mat-option value="Vendor Pays">Vendor Pays</mat-option>
                <mat-option value="Purchasor Pays">Purchasor Pays</mat-option>
            </mat-select>
            <div *ngIf="testBool && toolTipList.VENDORPAYS" class="tooltip-wrap"><input
                    id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.VENDORPAYS.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VENDORPAYS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DATEPAID,'has-warning':errorWarningData.Warning?.DATEPAID}">
            <mat-label>Date Paid</mat-label>
            <input
                (dateInput)="CommanDatePickerClick('input', $event,'DATEPAID')"
                matTooltip="{{(toolTipList)?toolTipList.DATEPAID?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="DATEPAIDTEXT"
                (dateChange)="CommanDatePickerClick('input', $event,'DATEPAID')"
                matInput name="DatePaid" [matDatepicker]="DatePaid">
            <mat-datepicker-toggle matSuffix [for]="DatePaid"></mat-datepicker-toggle>
            <mat-datepicker #DatePaid></mat-datepicker>
            <div *ngIf="testBool && toolTipList.DATEPAID" class="tooltip-wrap"><input
                    id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.DATEPAID.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEPAID?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <!-- *ngIf="MatterData.classType == 4" -->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
        *ngIf="MatterData.classType == 'Property Purchase'">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label><b>Settlement time</b></mat-label>
            <input type="text" matInput aria-label="Number"
                matTooltip="{{(toolTipList)?toolTipList.SETTLEMENTTIME?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="SETTLEMENTTIME" name="SETTLEMENTTIME"
                (dateInput)="CommanDatePickerClick('input', $event,'SETTLEMENTTIME')">
            <div *ngIf="testBool && toolTipList.SETTLEMENTTIME"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.SETTLEMENTTIME.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTTIME?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Pool Inspection Date</mat-label>

            <input
                (dateInput)="CommanDatePickerClick('input', $event,'POOLINSPECTIONDATE')"
                matTooltip="{{(toolTipList)?toolTipList.POOLINSPECTIONDATE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="POOLINSPECTIONTEXT"
                (dateChange)="CommanDatePickerClick('input', $event,'POOLINSPECTIONDATE')"
                matInput name="poolInspectiondate"
                [matDatepicker]="poolInspectiondate">
            <mat-datepicker-toggle matSuffix [for]="poolInspectiondate"></mat-datepicker-toggle>
            <mat-datepicker #poolInspectiondate></mat-datepicker>
            <div *ngIf="testBool && toolTipList.POOLINSPECTIONDATE"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.POOLINSPECTIONDATE.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POOLINSPECTIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
        *ngIf="MatterData.classType == 'Property Purchase'">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Pest Inspection Date</mat-label>
            <input matInput [matDatepicker]="picker4"
                matTooltip="{{(toolTipList)?toolTipList.PESTINSPECTIONDATE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="PESTINSPECTIONTEXT"
                (dateInput)="CommanDatePickerClick('input', $event,'PESTINSPECTIONDATE')">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <div *ngIf="testBool && toolTipList.PESTINSPECTIONDATE"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.PESTINSPECTIONDATE.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PESTINSPECTIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label><b>Settlement Location</b></mat-label>
            <input matInput
                matTooltip="{{(toolTipList)?toolTipList.SETTLEMENTLOCATION?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion"
                formControlName="SETTLEMENTLOCATION" name="SETTLEMENTLOCATION">
            <div *ngIf="testBool && toolTipList.SETTLEMENTLOCATION"
                class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.SETTLEMENTLOCATION.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTLOCATION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

</div>
