<div class="one" fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.REFERENCE,'has-warning':errorWarningData.Warning?.REFERENCE}">
            <mat-label>Client Reference</mat-label>
            <input name="ClientReference" formControlName="REFERENCE" matInput matTooltip="{{(toolTipList)?toolTipList.REFERENCE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.REFERENCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REFERENCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REFERENCE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OTHERREFERENCE,'has-warning':errorWarningData.Warning?.OTHERREFERENCE}">
            <mat-label>Other Ref</mat-label>
            <input matInput formControlName="OTHERREFERENCE" matTooltip="{{(toolTipList)?toolTipList.OTHERREFERENCE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.OTHERREFERENCE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OTHERREFERENCE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERREFERENCE?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-icon matSuffix class="secondary-text"></mat-icon>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="example" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERSTATUS,'has-warning':errorWarningData.Warning?.MATTERSTATUS }">
            <mat-label>Matter Status</mat-label>
            <mat-select formControlName="MATTERSTATUS">
                <mat-option *ngFor="let MatterStatusLookup of MatterStatusLookupsData" value="{{MatterStatusLookup.LOOKUPFULLVALUE}}">
                    {{MatterStatusLookup.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
        </mat-form-field> -->
        <mat-form-field appearance="outline" class="example" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERSTATUS,'has-warning':errorWarningData.Warning?.MATTERSTATUS }">
            <mat-label>Matter Status</mat-label>
            <input type="text" placeholder="Matter Status" matInput name="MATTERSTATUS" formControlName="MATTERSTATUS"
                [matAutocomplete]="auto2"  matTooltip="{{(toolTipList)?toolTipList.MATTERSTATUS?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <div  *ngIf="testBool && toolTipList.MATTERSTATUS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTERSTATUS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERSTATUS?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let MatterStatusLookup of MatterStatusLookupsData" value="{{MatterStatusLookup.LOOKUPFULLVALUE}}">
                    {{MatterStatusLookup.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMMENCEMENTDATE,'has-warning':errorWarningData.Warning?.COMMENCEMENTDATE}">
            <mat-label>Commencement Date</mat-label>
            <input (dateInput)="CommencementDate('input', $event)" formControlName="DATETEXT" (dateChange)="CommencementDate('change', $event)" matInput [matDatepicker]="picker1" matTooltip="{{(toolTipList)?toolTipList.DATEEXECUTED?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.DATEEXECUTED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEEXECUTED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEEXECUTED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DUEDATE ,'has-warning':errorWarningData.Warning?.DUEDATE }">
            <mat-label>Due Date</mat-label>
            <input (dateInput)="DueDate('input', $event)" formControlName="DUEDATEForm"  (dateChange)="DueDate('change', $event)" matInput [matDatepicker]="picker4" matTooltip="{{(toolTipList)?toolTipList.DUEDATE?.HOVER :''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.DUEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DUEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEAGREEMENTDATE,'has-warning':errorWarningData.Warning?.FEEAGREEMENTDATE}">
            <mat-label>Costs Agreement Date</mat-label>
            <input (dateInput)="CostsAgreementDate('input', $event)" formControlName="FeeAgreementDateText" (dateChange)="CostsAgreementDate('change', $event)" matInput name="CostsAgreementDate" [matDatepicker]="picker2" matTooltip="{{(toolTipList)?toolTipList.FEEAGREEMENTDATE:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field> -->
        <mat-form-field appearance="outline" class="example" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMPLETEDDATE,'has-warning':errorWarningData.Warning?.COMPLETEDDATE}">
            <mat-label>Completed Date</mat-label>
            <input (dateInput)="CompletedDate('input', $event)" formControlName="COMPLETEDDATETEXT" (dateChange)="CompletedDate('change', $event)" matInput name="CompletedDate" [matDatepicker]="picker3" matTooltip="{{(toolTipList)?toolTipList.COMPLETEDDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.COMPLETEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COMPLETEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMPLETEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEAGREEMENTDATE,'has-warning':errorWarningData.Warning?.FEEAGREEMENTDATE}">
           <mat-label>Costs Agreement Date</mat-label>
           <input (dateInput)="CostsAgreementDate('input', $event)" formControlName="FeeAgreementDateText" (dateChange)="CostsAgreementDate('change', $event)" matInput name="CostsAgreementDate" [matDatepicker]="picker2" matTooltip="{{(toolTipList)?toolTipList.FEEAGREEMENTDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
           <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
           <mat-datepicker #picker2></mat-datepicker>
           <div  *ngIf="testBool && toolTipList.FEEAGREEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FEEAGREEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEAGREEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
       </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEAGREEMENTRECEIVEDDATE,'has-warning':errorWarningData.Warning?.FEEAGREEMENTRECEIVEDDATE}">
            <mat-label>Cost Agr. Rec'd</mat-label>
            <input (dateInput)="Costagrpicker('input', $event)" (dateChange)="Costagrpicker('change', $event)" name="costagrpicker" matInput [matDatepicker]="costagrpicker" formControlName="FEEAGREEMENTRECEIVEDDATETEXT" matTooltip="{{(toolTipList)?toolTipList.FEEAGREEMENTRECEIVEDDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="costagrpicker"></mat-datepicker-toggle>
            <mat-datepicker #costagrpicker></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.FEEAGREEMENTRECEIVEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FEEAGREEMENTRECEIVEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEAGREEMENTRECEIVEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LASTREVIEWDATE,'has-warning':errorWarningData.Warning?.LASTREVIEWDATE}">
            <mat-label>Last Review Date</mat-label>
            <input (dateInput)="LastReviewpicker('input', $event)" (dateChange)="LastReviewpicker('change', $event)" name="lastreviewpicker" matInput [matDatepicker]="lastreviewpicker" formControlName="LASTREVIEWDATETEXT" matTooltip="{{(toolTipList)?toolTipList.LASTREVIEWDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="lastreviewpicker"></mat-datepicker-toggle>
            <mat-datepicker #lastreviewpicker></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.LASTREVIEWDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LASTREVIEWDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LASTREVIEWDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="example" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERSTATUS,'has-warning':errorWarningData.Warning?.MATTERSTATUS }">
            <mat-label>Matter Status</mat-label>
            <mat-select formControlName="MATTERSTATUS">
                <mat-option *ngFor="let MatterStatusLookup of MatterStatusLookupsData" value="{{MatterStatusLookup.LOOKUPFULLVALUE}}">
                    {{MatterStatusLookup.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="userType">
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OwnerGuid,'has-warning':errorWarningData.Warning?.OwnerGuid}">
            <mat-label>Matter Owner</mat-label>
            <input matInput readonly formControlName="OWNERNAME"  matTooltip="{{(toolTipList)?toolTipList.OWNERNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.OWNERNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OWNERNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OWNERNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-icon matSuffix (click)="selectMatter();">
                <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Matter Owner" matTooltipPosition="above">
            </mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PrimaryFeeEarnerGuid,'has-warning':errorWarningData.Warning?.PrimaryFeeEarnerGuid}">
            <mat-label>Primary Fee Earner</mat-label>
            <input matInput readonly formControlName="PRIMARYFEEEARNERNAME"  matTooltip="{{(toolTipList)?toolTipList.PRIMARYFEEEARNERNAME:''}}" [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.OWNERNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OWNERNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OWNERNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-icon matSuffix (click)="selectFeeEarner();">
                <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Primary Fee Earner" matTooltipPosition="above">
            </mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ASSISTANTGUID,'has-warning':errorWarningData.Warning?.ASSISTANTGUID}">
            <mat-label>Person Assisting</mat-label>
            <input matInput readonly formControlName="ASSISTANTNAME"  matTooltip="{{(toolTipList)?toolTipList.ASSISTANTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.ASSISTANTNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ASSISTANTNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ASSISTANTNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
            <mat-icon matSuffix (click)="selectPersonAssist();">
                <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Person Assisting" matTooltipPosition="above">
            </mat-icon>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" [ngClass]="SECURITYOPTION? 'pb-4':'pb-20'">
        <mat-checkbox *ngIf="'' | productType" [(ngModel)]="SECURITYOPTION" formControlName="SECURITYOPTION" fxFlex="50" class="align-slef-baseline" (click)="(matterAccessTier.ALLOWED !==1)? goTo(matterAccessTier.URL):true" (click)="(matterAccessTier.ALLOWED !==1)? false:true" [matTooltip]="('Matter Access' | tierPermission : 'Matter Access').ALLOWED !== 1? ('Matter Access' | tierPermission : 'Matter Access').DESCRIPTION :''" matTooltipPosition="above">Restrict Access to this Matter
            <img *ngIf="('Matter Access' | tierPermission : 'Matter Access').ALLOWED !== 1" src="assets/icons/web_app/Feature_is_Locked.ico" class="feature_locked_text">
        </mat-checkbox>
        <mat-form-field class="example" appearance="outline" fxFlex="50">
            <mat-label>Tick Users that should have access</mat-label>
            <mat-select (selectionChange)="MatterUserChange($event.value)" formControlName="MATTERACCESSLIST" multiple [disabled]="!SECURITYOPTION">
                <!-- <mat-option value="all">All</mat-option>
                <mat-option value="">None</mat-option> -->
                <mat-option *ngFor="let MatterDrop of MatterDropData" value="{{MatterDrop.USERGUID}}">
                    {{MatterDrop.USERID}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEFROMTOTALEXGST,'has-warning':errorWarningData.Warning?.ESTIMATEFROMTOTALEXGST}">
            <mat-label>Estimate Minimum Ex-GST</mat-label>
            <input currencyMask class="sideText" matInput type="text" formControlName="ESTIMATEFROMTOTALEXGST" (keyup)="calcPE('min')" [(ngModel)]="PRICEVAL"
            matTooltip="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
            <div  *ngIf="testBool && toolTipList.ESTIMATEFROMTOTALEXGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEFROMTOTALEXGST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICEVALGST,'has-warning':errorWarningData.Warning?.PRICEVALGST}">
            <mat-label>Estimate Minimum Inc-GST</mat-label>
            <input currencyMask class="sideText" name="Ex-GST" matInput type="text" formControlName="ESTIMATEFROMTOTALINCGST" (keyup)="calcPI('min')" [(ngModel)]="PRICEVALGST"
            matTooltip="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALINCGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.ESTIMATEFROMTOTALINCGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALINCGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEFROMTOTALINCGST?.COPYVALUE:'')">file_copy</mat-icon></div>
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="AllowEstimateRange">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEFROMTOTALEXGST,'has-warning':errorWarningData.Warning?.ESTIMATEFROMTOTALEXGST}">
            <mat-label>Estimate Maximum Ex-GST</mat-label>
            <input class="sideText" currencyMask matInput type="text" formControlName="MAXESTIMATEFROMTOTALEXGST" (keyup)="calcPE('max')" [(ngModel)]="MAXPRICEVAL"  matTooltip="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
            <div  *ngIf="testBool && toolTipList.ESTIMATEFROMTOTALEXGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEFROMTOTALEXGST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICEVALGST,'has-warning':errorWarningData.Warning?.PRICEVALGST}">
            <mat-label>Estimate Maximum Inc-GST</mat-label>
            <input currencyMask class="sideText" name="maxEx-GST" matInput type="text" formControlName="MAXESTIMATEFROMTOTALINCGST" (keyup)="calcPI('max')" [(ngModel)]="MAXPRICEVALGST"  matTooltip="{{(toolTipList)?toolTipList.ESTIMATETOTOTALINCGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
            <div  *ngIf="testBool && toolTipList.ESTIMATETOTOTALINCGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATETOTOTALINCGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATETOTOTALINCGST.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.NOTES,'has-warning':errorWarningData.Warning?.NOTES}">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="NOTES"  matTooltip="{{(toolTipList)?toolTipList.NOTES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" (keyup)="onKeyPressTextMessage($event)" id="notesData"></textarea>
            <div  *ngIf="testBool && toolTipList.NOTES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NOTES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTES.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>