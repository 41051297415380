
<div cdkDrag class="popup_main_div delete_main_popup confirm_popup" style="width:400px">
    <h1 cdkDragHandle matDialogTitle>
        {{'Load CSV'}}
        <button class="btn close-button-icon" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h1>
    <div mat-dialog-content class="pupup-content">
        <ng-container *ngIf="_data.reasonVal !== '' && _data.amountVal != '' && _data.amountVal > 0">
            <p>The CSV File have 2 columns,with no Header</p>
            <p>
                <span>Column 1: The Matter Number</span><br/>
                <span>Column 2: The Current Trust Balance for that Matter</span>
            </p>
            <p>Each Matter must already exist in database.</p>
        </ng-container>

        <ng-container *ngIf="_data.reasonVal == ''">
            <p>You need to enter a reason before you complete the process</p>
        </ng-container>

        <ng-container *ngIf="_data.amountVal == '' ||  _data.amountVal <= 0">
            <p>Amount must be larger than 0</p>
        </ng-container>
    </div>
    <div mat-dialog-actions class="popup_ftr">
        <div class="right_btn_child" >
            <button mat-raised-button class="mat-button mat-raised-button mat-primary" [mat-dialog-close]="true" type="button"> Ok</button>
        </div>
    </div>
<div>
