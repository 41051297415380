<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="SafeCustody" id="SafeCustody" [formGroup]="SafeCustody">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SHORTNAME,'has-warning':errorWarningData.Warning?.SHORTNAME}">
                        <mat-label>{{barristerTitle}}</mat-label>
                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.SHORTNAME:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="SHORTNAME" readonly>
                    </mat-form-field> -->

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'menu-disabled': isDisabled,'has-error':errorWarningData.Error?.SHORTNAME,'has-warning':errorWarningData.Warning?.SHORTNAME}">
                        <mat-label>Matters</mat-label>
                        <mat-chip-list #chipList aria-label="Matter selection">
                            <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.SHORTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  
                                (keydown.Tab)="datashow($event)" 
                                formControlName="SHORTNAME" #MatterInput1 [matAutocomplete]="autoMatter"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'" [disabled]="hide">
                        </mat-chip-list>
                        <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                            <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                {{data.SHORTNAME}}--{{data.MATTER}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon [ngClass]="{'menu-disabled': isDisabled}" id="mattersnew" class="" matSuffix (click)='SelectMatter()'>
                            <img src="assets/icons/web_app/icon_matter_d.ico">
                        </mat-icon>
                  </mat-form-field>

                    <mat-form-field appearance="outline" class="example" fxFlex="{{(action === 'new' || action === 'new client' || action === 'new matter' || action === 'newlegal')?48:50}}"  [ngClass]="{'has-error':errorWarningData.Error?.CONTACTNAME,'has-warning':errorWarningData.Warning?.CONTACTNAME}">
                        <mat-label>Owner</mat-label>
                        <!-- <input matInput  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="CONTACTNAME" readonly matInput >
                        <mat-icon class="" matSuffix (click)='SelectContact()'>
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon> -->
                        <mat-chip-list #chipListContact aria-label="Matter selection">
                            <mat-chip *ngFor="let Contactdata of Contactname" (removed)="removeContact(Contactdata)" class="mat-chips-selected">
                                {{Contactdata| slice:0:40}}<ng-container *ngIf="Contactdata.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input  matInput
                                (keydown.Tab)="Contactdatashow($event)"  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}"
                                formControlName="CONTACTNAME" #MatterInput2 [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUpOwner($event)" [ngClass]="Contactname.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1" *ngFor="let ContactData of Contactlist" (click) ="selectedContact(ContactData)">
                                <span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>{{ContactData.CONTACTNAME}}<span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="(this.MatterInputContact && this.MatterInputContact.nativeElement.value =='') ? SelectContact():''"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>
                    </mat-form-field>

                    <!-- <button mat-fab color="primary" class="add-contect-btn" *ngIf="action === 'new' || action === 'new client' || action === 'new matter' || action === 'newlegal'" (click)="selectNewContect()">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button> -->

                   
                    <a role="button" class="iconbtn-plus mr-4 plus-middle"  *ngIf="action === 'new'" (click)="selectNewContect()">
                        <span class="mat-button-wrapper">
                            <mat-icon  *ngIf="action === 'new' || action === 'new client' || action === 'new matter' || action === 'newlegal'" [ngClass]="appPermissions[3]?.Create==0 ? 'disabled-click large-button' : 'large-button'" (click)="(this.MatterInputContact && this.MatterInputContact.nativeElement.value =='')? selectNewContect():null" >add_circle</mat-icon>
                        </span>
                    </a>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="{{flexSize}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SAFECUSTODYPACKETGUID,'has-warning':errorWarningData.Warning?.SAFECUSTODYPACKETGUID}">
                        <mat-label>Packet</mat-label>
                        <mat-select placeholder="Seperator" formControlName="SAFECUSTODYPACKETGUID">
                            <mat-option *ngFor="let pakets of packetcustody" [value]="pakets.SAFECUSTODYPACKETGUID">
                                {{pakets.PACKETNUMBER}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <button mat-fab color="primary" class="add-contect-btn" *ngIf="action === 'new' ||action === 'new client'|| action === 'edit' || action === 'new matter' || action === 'newlegal'" (click)="OpenPacket(this.action)">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button> -->

                    
                    <a role="button" class="iconbtn-plus mr-4 plus-middle">
                        <span class="mat-button-wrapper">
                            <mat-icon  *ngIf="action === 'new' ||action === 'new client'|| action === 'edit' || action === 'new matter' || action === 'newlegal'" (click)="OpenPacket(this.action)">add_circle</mat-icon>
                        </span>
                    </a>

                    <mat-form-field appearance="outline" fxFlex="{{flexSize}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTTYPE,'has-warning':errorWarningData.Warning?.DOCUMENTTYPE}">
                        <mat-label>Document Type</mat-label>

                        <input type="text"
                        placeholder="Seperator"
                        matInput  matTooltip="{{(toolTipList)?toolTipList.SAFECUSTODYPACKETGUID?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" 
                        formControlName="DOCUMENTTYPE"
                        [matAutocomplete]="auto">
                 <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let val of documnettype" [value]="val.LOOKUPFULLVALUE" class="custome-mat-option">
                        {{val.LOOKUPFULLVALUE}}</mat-option>
                 </mat-autocomplete>
                        <!-- <mat-select placeholder="Seperator" formControlName="DOCUMENTTYPE">
                            <mat-option *ngFor="let val of documnettype" [value]="val.LOOKUPFULLVALUE">
                                {{val.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select> -->
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTNAME,'has-warning':errorWarningData.Warning?.DOCUMENTNAME}">
                        <mat-label>Document\Website</mat-label>
                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.PACKETDESCRIPTION:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="DOCUMENTNAME">
                    </mat-form-field> -->


                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTNAME,'has-warning':errorWarningData.Warning?.DOCUMENTNAME}">
                        <mat-label>Document\Website</mat-label>
                        <input [(ngModel)]='SafeCustodyADD.DOCUMENTNAME' formControlName="DOCUMENTNAME" matInput  matTooltip="{{(toolTipList)?toolTipList.DOCUMENTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" >
                        <mat-icon matSuffix [matMenuTriggerFor]="menu" fxFlex="5">
                            <img class="seticon" src="assets/icons/web_app/folder.ico" alt="" matTooltip="File Upload" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
                        <button (click)="documentRegPopup()" mat-menu-item>Choose from Document Register</button>
                        <button (click)="uploader.click()" mat-menu-item>Choose from device</button>
                    </mat-menu>
                    <!-- File Upload hiddenfiled -->
                    <input type="file" hidden #uploader (change)="selectDoc($event)">

                    <mat-form-field appearance="outline" fxFlex="50" class="example pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.REMINDERDATE,'has-warning':errorWarningData.Warning?.REMINDERDATE}">
                        <mat-label>Review Date</mat-label>
                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.REMINDERDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  [matDatepicker]="pickerREMINDERDATE" placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" formControlName="REMINDERDATETEXT">
                        <mat-datepicker-toggle matSuffix [for]="pickerREMINDERDATE"></mat-datepicker-toggle>
                        <mat-datepicker #pickerREMINDERDATE></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SAFECUSTODYDESCRIPTION,'has-warning':errorWarningData.Warning?.SAFECUSTODYDESCRIPTION}">
                        <mat-label>Description</mat-label>
                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.SAFECUSTODYDESCRIPTION?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="SAFECUSTODYDESCRIPTION">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALTEXT,'has-warning':errorWarningData.Warning?.ADDITIONALTEXT}">
                        <mat-label>Text</mat-label>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" matInput  matTooltip="{{(toolTipList)?toolTipList.ADDITIONALTEXT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="ADDITIONALTEXT"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <button mat-raised-button color="accent" class="mat-accent" [disabled]="isDisableCheckBtn || (MOVEMENTTYPEData!='Checked In' && MOVEMENTTYPEData!='Return')" (click)="tabLable='Borrow';setDataRow('Borrow');">Borrow</button>
                    <button mat-raised-button color="accent" class="mat-accent" [disabled]="isDisableCheckBtn || (MOVEMENTTYPEData!='Borrowed')" style="margin-left: 8px" (click)="tabLable='Return';setDataRow('Return');">Return</button>
                    <button mat-raised-button color="accent" class="mat-accent" [disabled]="isDisableCheckBtn || (MOVEMENTTYPEData!='Checked In' && MOVEMENTTYPEData!='Return')" style="margin-left: 8px" (click)="tabLable='Check Out';setDataRow('Check Out');">Check
                        Out</button>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="{{tabLable}}">
                            <div class="Pad_tab" fxLayout="column" *ngIf="tabLable=='Borrow' || tabLable=='Check Out' || tabLable=='Return'">
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="example pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.MOVEMENTDATE,'has-warning':errorWarningData.Warning?.MOVEMENTDATE}">
                                        <mat-label>Date</mat-label>
                                        <input matInput   [matDatepicker]="picker1" placeholder="Choose a date" (dateChange)="choosedDateTab('change', $event)" formControlName="MOVEMENTDATETEXT">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CONTACTNAME,'has-warning':errorWarningData.Warning?.CONTACTNAME}">
                                        <mat-label>Contact Name</mat-label>
                                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="MOVEMENTCONTACTNAME">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.REASON,'has-warning':errorWarningData.Warning?.REASON}">
                                        <mat-label>Details</mat-label>
                                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" matInput  matTooltip="{{(toolTipList)?toolTipList.REASON?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="REASON"></textarea>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <button mat-raised-button color="accent" class="mat-accent" (click)="SaveSafeCustodyMoveMent()" [disabled]="isspiner">
                                        <mat-spinner
                                            style=" zoom: 0.2;position: absolute;right: 0;left: 0;margin: auto;top: 0;bottom: 0;"
                                            *ngIf="isspiner"></mat-spinner>Save
                                    </button>
                                    <button mat-raised-button color="primary" style="margin-left: 8px" (click)="isDisableCheckBtn=false;tabLable='History'">Cancel</button>
                                </div>
                            </div>
                            <div class="Pad_tab" fxLayout="column" *ngIf="tabLable=='Check In'">
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="example pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CHECKINDATE,'has-warning':errorWarningData.Warning?.CHECKINDATE}">
                                        <mat-label>Date</mat-label>
                                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.CHECKINDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  [matDatepicker]="picker1" placeholder="Choose a date" (dateInput)="PersonDate('input', $event)" (dateChange)="PersonDate('change', $event)" formControlName="CHECKINDATETEXT">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CHECKINCONTACTNAME,'has-warning':errorWarningData.Warning?.CHECKINCONTACTNAME}">
                                        <mat-label>Contact Name</mat-label>
                                        <input matInput  matTooltip="{{(toolTipList)?toolTipList.CHECKINCONTACTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"  formControlName="CHECKINCONTACTNAME">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="Pad_tab" fxLayout="column" *ngIf="tabLable=='History'">
                                <div class="one content">
                                    <div class="example-containerdata">
                                        <table mat-table [dataSource]="checkInData" [@animateStagger]="{value:'50'}">
                                            <ng-container matColumnDef="MOVEMENTDATE">
                                                <th mat-header-cell *matHeaderCellDef> Date </th>
                                                <td mat-cell *matCellDef="let element"> {{element.MOVEMENTDATE}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="MOVEMENTTYPE">
                                                <th mat-header-cell *matHeaderCellDef> Type </th>
                                                <td mat-cell *matCellDef="let element"> {{element.MOVEMENTTYPE}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="CONTACTNAME">
                                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                                <td mat-cell *matCellDef="let element"> {{element.CONTACTNAME}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="REASON">
                                                <th mat-header-cell *matHeaderCellDef> REASON </th>
                                                <td mat-cell *matCellDef="let element"> {{element.REASON}} </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                            <tr mat-row *matRowDef="let row;columns: displayedColumns;" (click)="editCheckinData(row);highlightedRows=row.SAFECUSTODYMOVEMENTGUID" [style.background]="highlightedRows == row.SAFECUSTODYMOVEMENTGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.SAFECUSTODYMOVEMENTGUID ? 'row-text-colore' : ''"
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveSafeCustody()" [disabled]="isspiner">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>