<div class="one" [ngClass]="class==true?'height-526 one':'height-525 one'" fxLayout="column">
    <div class="display-flex height-100">
    <div class="leftbox">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto" style="display: flex;">
            <!-- <input hidden type="file" #uploader (change)="onFileChange($event.target.files,'photo')" accept="image/*"> -->
            <button mat-icon-button class="profile-img image" >
                <img class="radius-500"
                    [src]="(finalfileToUpload && finalfileToUpload[0] !== '')?finalfileToUpload[0]:IMAGEPATH">
            </button>&nbsp;&nbsp;
            <div style="display: flex;align-items: center;">
                <b class="name-input000">{{userData?.FULLNAME}}</b>
            </div>
            
            <!-- <button (click)="uploader.click()" mat-raised-button style="font-weight: 600;" class="image-2">Upload
                Photo</button> -->
        </div><br>
        <form>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <!-- <mat-form-field class="a" appearance="outline" class="pr-4 name-input">
                    <mat-label>Name</mat-label>
                    <input name="BARRISTERSNAME" formControlName="UserName" matInput>
                    <mat-icon matSuffix class="secondary-text"></mat-icon>
                </mat-form-field> -->
                <p><b class="name-input0001">Phone:  </b>{{userData?.PHONE1}}</p>

                <!-- <mat-form-field appearance="outline" class="pr-4 name-input-3">
                    <mat-label>Phone</mat-label>
                    <input name="PHONE1" formControlName="PHONE1"  matInput>
                </mat-form-field> -->
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <!-- <mat-form-field appearance="outline" class="pr-4 name-input-4">
                    <mat-label>Email</mat-label>
                    <input name="EMAIL" formControlName="EMAIL" matInput>
                </mat-form-field> -->
                <p><b class="name-input0001">Email:  </b>{{userData?.EMAIL}}</p>
            </div>
            <br>
            <button class="signature" mat-raised-button color="accent" (click)="signaturDialog()"
            >Edit Signatures
            </button>
        </form>
    </div>
    <div class="rightbox" *ngIf="(('Timeline' | tierPermission : 'Timeline') && ('Timeline' | tierPermission : 'Timeline').ALLOWED == 1)">
        <app-timeline [currentUser]="currentUser"  ></app-timeline>
    </div>
</div>
</div>

<!-- <mat-dialog-actions align="end" class="popup_ftr">
    <button id="callApiId" class="mainbtn_size" mat-raised-button color="accent">
        Save
    </button>
</mat-dialog-actions> -->
