
<div cdkDrag class="popup_main_div generrate_document_main" >
    <h2 cdkDragHandle mat-dialog-title>
        <span >Bulk Create Invoices</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main" >
        <div class="one" style="width: 100%;" >
          <div *ngFor="let datamessage of multuyInvoiceData; let i = index">
            <div *ngIf="i==0">{{count}} of {{datamessage?.TOTALCOUNT}} invoices created.</div><br>
            <strong style="font-size: 15px;">{{datamessage.MESSAGE}}</strong> 
          </div>
        </div>
      </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="primary" [mat-dialog-close]="false"  id="canclePopup"
            cdkFocusInitial>Cancel
        </button>
    </mat-dialog-actions>
</div>