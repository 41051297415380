<div class="one" [formGroup]="contactForm" fxLayout="column">
    <div class="content">
        <div class="pt-20">
            <h3>{{('' | productType)?   "Matter As Client" :"Instructing Solicitor"}} {{dataSourceClient.length}}</h3>
            <div class="table_overflow_auto">
                <table mat-table matSort [dataSource]="dataSourceClient" class="modern" #Table>
                    <ng-container matColumnDef="SHORTNAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{barristerTitle}} </th>
                        <td mat-cell *matCellDef="let element" (click)="selectHyperLink(element,'SHORTNAME',element['SHORTNAME'])" class="custom-link-hyperlink"> <span class="custom-link-hyperlink">{{element?.SHORTNAME}}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="MATTER">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Matter </th>
                        <td mat-cell *matCellDef="let element"> {{element?.MATTER}} </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumnsClient; sticky: true">
                    </tr>
                    <tr [style.background]="highlightedRowsClient == row.MATTERGUID ? selectedColore : ''" [ngClass]="highlightedRowsClient == row.MATTERGUID ? 'row-text-colore' : ''" highlightedRowsClient=row.MATTERGUID mat-row *matRowDef="let row; columns: displayedColumnsClient;"
                        (click)="highlightedRowsClient = row.MATTERGUID;currentClientData=row" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
                <!-- <div class="grid-overlay relative_position_auto" *ngIf="isDisplay">
                    <div class="grid-overlay-msg"> There is no data to display. </div>
                </div> -->
                <mat-paginator #clientPaginator [pageSizeOptions]="[10, 20,50,100]" showFirstLastButtons>
                </mat-paginator>
            </div>
            <mat-dialog-actions align="end" class="popup_ftr ">
                <button mat-raised-button color="accent" class="mat-accent" [disabled]="!highlightedRowsClient" (click)="EditMatter(highlightedRowsClient)">
                    Edit </button>
            </mat-dialog-actions>
        </div>
        <div class="">
            <h3>Matters As Correspondent</h3>
            <div class="table_overflow_auto relative_position_auto">
                <table mat-table matSort [dataSource]="dataSourceCorrs" class="modern" #Table>
                    <ng-container matColumnDef="SHORTNAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{barristerTitle}} </th>
                        <td mat-cell *matCellDef="let element" (click)="selectHyperLink(element,'SHORTNAME',element['SHORTNAME'])" class="custom-link-hyperlink"> <span class="custom-link-hyperlink">{{element?.SHORTNAME}}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="MATTER">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Matter </th>
                        <td mat-cell *matCellDef="let element"> {{element?.MATTER}} </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumnsCorres; sticky: true">
                    </tr>
                    <tr [style.background]="highlightedRowsCorss == row.MATTERGUID ? selectedColore : ''" [ngClass]="highlightedRowsCorss == row.MATTERGUID ? 'row-text-colore' : ''" highlightedRowsCorss=row.MATTERGUID mat-row *matRowDef="let row; columns: displayedColumnsCorres;"
                        (click)="highlightedRowsCorss = row.MATTERGUID;currentCorssData=row" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
                <div class="grid-overlay" *ngIf="isDisplay1">
                    <div class="grid-overlay-msg"> There is no data to display. </div>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 20,50,100]" #CorrsPaginator showFirstLastButtons></mat-paginator>
            </div>
            <mat-dialog-actions align="end" class="popup_ftr">
                <button mat-raised-button color="accent" class="mat-accent" [disabled]="!highlightedRowsCorss" (click)="editElement(currentCorssData)"> Edit </button>
            </mat-dialog-actions>
        </div>

    </div>
</div>