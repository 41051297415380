import { BehaviorService } from '@_services/Behavior.service';
import { Directive, HostListener } from '@angular/core';


@Directive({
    selector: '[appRightclick]'
})
export class RightclickDirective {
    selectedMatter: any

    constructor(private behaviorService: BehaviorService,) {
    }
    // NOTE::::contextmenu -->use for rightclick event
    /**
     * This is used to check the right click  or not
     */
    @HostListener('contextmenu', ['$event'])
    onMouseDown(event: MouseEvent) {

        if (event.type == 'contextmenu') {
            this.selectedMatter = JSON.parse(localStorage.getItem('set_active_matters'))
            this.behaviorService.setRightClick(this.selectedMatter)
            event.preventDefault();
        }


    }
}
