import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-general-will-tab',
  templateUrl: './general-will-tab.component.html',
  styleUrls: ['./general-will-tab.component.scss']
})
export class GeneralWillTabComponent implements OnInit {
  @Input() WillEstateForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() islegalEstate: any = false;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;

  constructor(public datepipe: DatePipe,public tooltipService:TooltipService) { }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    
  }

  /**
   * This function is used to get the Comman Date picker
   */
  CommanDatePickerEvent(type: string, event: MatDatepickerInputEvent<Date>, formField: any = '') {
    this.WillEstateForm.controls[formField].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  /**
   * Thus function is used to get the Default Death date
   */
  DateofDeathPickerEvent(type: string, event: MatDatepickerInputEvent<Date>, formField: any = '') {
    this.WillEstateForm.controls[formField].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }


  /**
   * This function is used to get the text data value. 
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    navigator.clipboard.writeText(event)
  }

  /**
   * This function is used to focus out the assets
   */
  focusOutAssets(){
      const newValue = Number(this.WillEstateForm.controls['TOTALASSETS'].value) - Number(this.WillEstateForm.controls['TOTALLIABILITIES'].value);
      const finalValue = newValue.toString();
      this.WillEstateForm.controls['ESTATENETVALUE'].setValue(Number(parseFloat(finalValue).toFixed(2)));
  }
}
