<div class="example-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_user_main">
  <h2 cdkDragHandle mat-dialog-title>Currently Logged on Users
      <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
          <mat-icon>close</mat-icon>
      </button>
  </h2>
  <mat-dialog-content>
    <mat-list>
      <!-- <div mat-subheader>Currently Logged on Users</div> -->
      <mat-list-item *ngFor="let user of loggedOnUsers;let i = index">
          <!-- <mat-icon mat-list-icon> circle </mat-icon> -->
          <div class="display-flex">
              <div>
                  <div mat-line>{{user.USERNAME}}</div>
                  <div mat-line> {{user.LOGGEDINDATE}} - {{user.LOGGEDINTIME}}</div>
                </div>
                <div class="pl-80">                 
                    <button mat-raised-button color="accent" class="mat-accent" (click)="LogOutNow(user)">
                        Log Out Now
                    </button>
                </div>
</div>
    </mat-list-item>
      
    </mat-list>
  </mat-dialog-content>
</div>