<div class="one rate_tab_main" fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEAGREEMENTDATE,'has-warning':errorWarningData.Warning?.FEEAGREEMENTDATE}">
           <mat-label>Costs Agreement Date</mat-label>
           <input (dateInput)="CostsAgreementDate('input', $event)" formControlName="FeeAgreementDateText" (dateChange)="CostsAgreementDate('change', $event)" matInput name="CostsAgreementDate" [matDatepicker]="picker2" matTooltip="{{(toolTipList)?toolTipList.FEEAGREEMENTDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
           <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
           <mat-datepicker #picker2></mat-datepicker>
           <div  *ngIf="testBool && toolTipList.FEEAGREEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FEEAGREEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEAGREEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
       </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEAGREEMENTRECEIVEDDATE,'has-warning':errorWarningData.Warning?.FEEAGREEMENTRECEIVEDDATE}">
            <mat-label>Cost Agr. Rec'd</mat-label>
            <input (dateInput)="Costagrpicker('input', $event)" (dateChange)="Costagrpicker('change', $event)" name="costagrpicker" matInput [matDatepicker]="costagrpicker" formControlName="FEEAGREEMENTRECEIVEDDATETEXT" matTooltip="{{(toolTipList)?toolTipList.FEEAGREEMENTRECEIVEDDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="costagrpicker"></mat-datepicker-toggle>
            <mat-datepicker #costagrpicker></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.FEEAGREEMENTRECEIVEDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FEEAGREEMENTRECEIVEDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEAGREEMENTRECEIVEDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field class="example pr-4" fxFlex="35" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.GSTTYPE,'has-warning':errorWarningData.Warning?.GSTTYPE}">
            <mat-label>GST Type</mat-label>
            <mat-select formControlName="GSTTYPE" panelClass="myPanelClass"  disableOptionCentering (selectionChange)="radioChange($event.value)" matTooltip="{{(toolTipList)?toolTipList.GSTTYPE?.HOVER:''}}">
                <mat-option value="GST Exclusive">GST Exclusive</mat-option>
                <mat-option value="GST Inclusive">GST Inclusive</mat-option>
                <mat-option value="GST Free">GST Free</mat-option>
                <mat-option value="Export">Export</mat-option>
            </mat-select>
            <div  *ngIf="testBool && toolTipList.GSTTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.GSTTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GSTTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field class="example pr-4" fxFlex="35" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.BILLINGMETHOD,'has-warning':errorWarningData.Warning?.BILLINGMETHOD}">
            <mat-label>Billing Method</mat-label>
            <mat-select formControlName="BILLINGMETHOD"  panelClass="myPanelClass" disableOptionCentering (selectionChange)="radioChange($event.value)" matTooltip="{{(toolTipList)?toolTipList.GSTTYPE?.HOVER:''}}">
                <mat-option value="Time Costed">Time Costed</mat-option>
                <mat-option value="Fixed">Fixed</mat-option>
                <mat-option value="Pro Bono">Pro Bono</mat-option>
                <mat-option value="Speculative">Speculative</mat-option>
            </mat-select>
            <div  *ngIf="testBool && toolTipList.BILLINGMETHOD" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BILLINGMETHOD.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BILLINGMETHOD?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <div fxFlex="30">
            <mat-checkbox formControlName="ONCHARGEDISBURSEMENTGST" [disabled]="isDisabled" class="gst_charge tab_value_child">Charge Disbursement GST</mat-checkbox>
        </div>
    </div>

    <ng-container *ngIf="rateList.length > 0">
        <div fxLayout="row" *ngIf="!('' | productType)" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERHOUR_1,'has-warning':errorWarningData.Warning?.RATEPERHOUR_1}">
                <mat-label> {{rateList[0].LOOKUPFULLVALUE}} $/hr</mat-label>
                <input class="sideText" matInput [value]="matterdetailForm.get('RATEPERHOUR_1').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  [(ngModel)]="RATEPERHOUR_1" formControlName="RATEPERHOUR_1" matTooltip="{{(toolTipList)?toolTipList.RATEPERHOUR:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERHOUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERHOUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERHOUR_2,'has-warning':errorWarningData.Warning?.RATEPERHOUR_2}">
                <mat-label>{{rateList[1].LOOKUPFULLVALUE}} $/hr</mat-label>
                <input class="sideText" matInput [value]="matterdetailForm.get('RATEPERHOUR_2').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  [(ngModel)]="RATEPERHOUR_2" formControlName="RATEPERHOUR_2" matTooltip="{{(toolTipList)?toolTipList.RATEPERHOUR?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERHOUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERHOUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERHOUR_3,'has-warning':errorWarningData.Warning?.RATEPERHOUR_3}">
                <mat-label>{{rateList[2].LOOKUPFULLVALUE}} $/hr</mat-label>
                <input class="sideText" matInput [value]="matterdetailForm.get('RATEPERHOUR_3').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  [(ngModel)]="RATEPERHOUR_3" formControlName="RATEPERHOUR_3" matTooltip="{{(toolTipList)?toolTipList.RATEPERHOUR?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERHOUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERHOUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERHOUR_4,'has-warning':errorWarningData.Warning?.RATEPERHOUR_4}">
                <mat-label>{{rateList[3].LOOKUPFULLVALUE}} $/hr</mat-label>
                <input class="sideText" matInput [value]="matterdetailForm.get('RATEPERHOUR_4').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true"  [(ngModel)]="RATEPERHOUR_4" formControlName="RATEPERHOUR_4" matTooltip="{{(toolTipList)?toolTipList.RATEPERHOUR?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <div  *ngIf="testBool && toolTipList.RATEPERHOUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERHOUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERHOUR_5,'has-warning':errorWarningData.Warning?.RATEPERHOUR_5}">
                <mat-label>{{rateList[4].LOOKUPFULLVALUE}} $/hr</mat-label>
                <input class="sideText" matInput [value]="matterdetailForm.get('RATEPERHOUR_5').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" [(ngModel)]="RATEPERHOUR_5" formControlName="RATEPERHOUR_5" matTooltip="{{(toolTipList)?toolTipList.RATEPERHOUR?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <div  *ngIf="testBool && toolTipList.RATEPERHOUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERHOUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERHOUR_6,'has-warning':errorWarningData.Warning?.RATEPERHOUR_6}">
                <mat-label>{{rateList[5].LOOKUPFULLVALUE}} $/hr</mat-label>
                <input class="sideText" matInput [value]="matterdetailForm.get('RATEPERHOUR_6').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" [(ngModel)]="RATEPERHOUR_6" formControlName="RATEPERHOUR_6" matTooltip="{{(toolTipList)?toolTipList.RATEPERHOUR?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <div  *ngIf="testBool && toolTipList.RATEPERHOUR" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERHOUR.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERHOUR?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="rateList.length > 0">
        <div fxLayout="row" *ngIf="!('' | productType)" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERDAY,'has-warning':errorWarningData.Warning?.RATEPERDAY_1}">
                <mat-label>{{rateList[0].LOOKUPFULLVALUE}} $/day</mat-label>
                <input [value]="matterdetailForm.get('RATEPERDAY_1').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" name="PerDay1" [(ngModel)]="RATEPERDAY_1" matInput formControlName="RATEPERDAY_1" matTooltip="{{(toolTipList)?toolTipList.RATEPERDAY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERDAY,'has-warning':errorWarningData.Warning?.RATEPERDAY_2}">
                <mat-label>{{rateList[1].LOOKUPFULLVALUE}} $/day</mat-label>
                <input [value]="matterdetailForm.get('RATEPERDAY_2').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" name="PerDay2" [(ngModel)]="RATEPERDAY_2" matInput formControlName="RATEPERDAY_2" matTooltip="{{(toolTipList)?toolTipList.RATEPERDAY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERDAY,'has-warning':errorWarningData.Warning?.RATEPERDAY_3}">
                <mat-label>{{rateList[2].LOOKUPFULLVALUE}} $/day</mat-label>
                <input [value]="matterdetailForm.get('RATEPERDAY_3').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" name="PerDay3" [(ngModel)]="RATEPERDAY_3" matInput formControlName="RATEPERDAY_3" matTooltip="{{(toolTipList)?toolTipList.RATEPERDAY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERDAY,'has-warning':errorWarningData.Warning?.RATEPERDAY_4}">
                <mat-label>{{rateList[3].LOOKUPFULLVALUE}} $/day</mat-label>
                <input [value]="matterdetailForm.get('RATEPERDAY_4').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" name="PerDay4" [(ngModel)]="RATEPERDAY_4" matInput formControlName="RATEPERDAY_4" matTooltip="{{(toolTipList)?toolTipList.RATEPERDAY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERDAY,'has-warning':errorWarningData.Warning?.RATEPERDAY_5}">
                <mat-label>{{rateList[4].LOOKUPFULLVALUE}} $/day</mat-label>
                <input [value]="matterdetailForm.get('RATEPERDAY_5').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" name="PerDay5" [(ngModel)]="RATEPERDAY_5" matInput formControlName="RATEPERDAY_5" matTooltip="{{(toolTipList)?toolTipList.RATEPERDAY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.RATEPERDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="16.66" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEPERDAY,'has-warning':errorWarningData.Warning?.RATEPERDAY_6}">
                <mat-label>{{rateList[5].LOOKUPFULLVALUE}} $/day</mat-label>
                <input [value]="matterdetailForm.get('RATEPERDAY_6').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  class="sideText" name="PerDay6" [(ngModel)]="RATEPERDAY_6" matInput formControlName="RATEPERDAY_6" matTooltip="{{(toolTipList)?toolTipList.RATEPERDAY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList?.RATEPERDAY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RATEPERDAY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEPERDAY?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FixedRateExGST,'has-warning':errorWarningData.Warning?.FixedRateExGST}">
            <mat-label>Fixed Price (Ex-GST) </mat-label>
            <input class="sideText" matInput name="Ex-GST" [(ngModel)]="FIXEDRATEEXGST" (blur)="calcPE()" formControlName="FIXEDRATEEXGST" matTooltip="{{(toolTipList)?toolTipList.FIXEDRATEEXGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" [value]="matterdetailForm.get('FIXEDRATEEXGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
            <div  *ngIf="testBool && toolTipList.FIXEDRATEEXGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FIXEDRATEEXGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIXEDRATEEXGST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" *ngIf="(matterdetailForm.controls.GSTTYPE.value != 'GST Free' && matterdetailForm.controls.GSTTYPE.value != 'Export')" [ngClass]="{'has-error':errorWarningData.Error?.FixedRateIncGST,'has-warning':errorWarningData.Warning?.FixedRateIncGST}">
            <mat-label>Fixed Price (Inc-GST) </mat-label>
            <input class="sideText" name="Inc-GST" [(ngModel)]="FIXEDRATEINCGST" matInput formControlName="FIXEDRATEINCGST" (blur)="calcPI()" matTooltip="{{(toolTipList)?toolTipList.FIXEDRATEINCGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" [value]="matterdetailForm.get('FIXEDRATEINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
            <div  *ngIf="testBool && toolTipList.FIXEDRATEINCGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FIXEDRATEINCGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIXEDRATEINCGST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="(AllowEstimateRange)? 22:50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEFROMTOTALEXGST,'has-warning':errorWarningData.Warning?.ESTIMATEFROMTOTALEXGST}">
            <mat-label>Estimate Minimum Ex-GST</mat-label>
            <input class="sideText" matInput [value]="matterdetailForm.get('ESTIMATEFROMTOTALEXGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  formControlName="ESTIMATEFROMTOTALEXGST" (blur)="calcPE_1('min')" [(ngModel)]="PRICEVAL"
            matTooltip="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [leadZero]="true">
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
            <div  *ngIf="testBool && toolTipList.ESTIMATEFROMTOTALEXGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEFROMTOTALEXGST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline"  fxFlex="(AllowEstimateRange)? 22:50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICEVALGST,'has-warning':errorWarningData.Warning?.PRICEVALGST}">
            <mat-label>Estimate Minimum Inc-GST</mat-label>
            <input class="sideText" name="Ex-GST" matInput [value]="matterdetailForm.get('ESTIMATEFROMTOTALINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  formControlName="ESTIMATEFROMTOTALINCGST" (blur)="calcPI_1('min')" [(ngModel)]="PRICEVALGST"
            matTooltip="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALINCGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [leadZero]="true">
            <div  *ngIf="testBool && toolTipList.ESTIMATEFROMTOTALINCGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALINCGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEFROMTOTALINCGST?.COPYVALUE:'')">file_copy</mat-icon></div>
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
        </mat-form-field>
        <mat-form-field  *ngIf="AllowEstimateRange" appearance="outline" class="example"  fxFlex="22" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEFROMTOTALEXGST,'has-warning':errorWarningData.Warning?.ESTIMATEFROMTOTALEXGST}">
            <mat-label>Estimate Maximum Ex-GST</mat-label>
            <input class="sideText" matInput [value]="matterdetailForm.get('MAXESTIMATEFROMTOTALEXGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  formControlName="MAXESTIMATEFROMTOTALEXGST" (focusout)="calcPE_1('max')" [(ngModel)]="MAXPRICEVAL"  matTooltip="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [leadZero]="true">
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
            <div  *ngIf="testBool && toolTipList.ESTIMATEFROMTOTALEXGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATEFROMTOTALEXGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATEFROMTOTALEXGST?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field    *ngIf="AllowEstimateRange" appearance="outline" fxFlex="22" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICEVALGST,'has-warning':errorWarningData.Warning?.PRICEVALGST}">
            <mat-label>Estimate Maximum Inc-GST</mat-label>
            <input class="sideText" name="maxEx-GST" matInput [value]="matterdetailForm.get('MAXESTIMATEFROMTOTALINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  formControlName="MAXESTIMATEFROMTOTALINCGST" (focusout)="calcPI_1('max')" [(ngModel)]="MAXPRICEVALGST"  matTooltip="{{(toolTipList)?toolTipList.ESTIMATETOTOTALINCGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [leadZero]="true">
            <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
            <div  *ngIf="testBool && toolTipList.ESTIMATETOTOTALINCGST" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ESTIMATETOTOTALINCGST.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ESTIMATETOTOTALINCGST.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

</div>
<div class="one rate_tab_main" fxLayout="column" >
    <div fxFlex="100">
        <strong>Rate Overrides</strong>
    </div>
    <div fxFlex="100">
        <mat-accordion class="faqs-accordion">
            <mat-expansion-panel *ngIf='userType'>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                            </mat-icon>&nbsp;&nbsp;
                            <strong>Fee Earner Rates</strong>
                        </div>
                        <div fxFlex="30">
                            <button mat-raised-button color="accent" class="mat-accent" (click)="AddNewRate('new','1','')">New Fee Earner
                                Rate</button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <table>
                        <tr *ngIf="UserRateData.length == 0">
                            <td colspan="2" style="text-align: center;font-weight: bold;">
                                Data not found
                            </td>
                        </tr>
                        <tr *ngFor="let item of UserRateData; let i=index;">
                            <td> {{item.USERID}}&nbsp;&nbsp;{{item.RATEPERHOUR_1}}Ex/Hr &nbsp;&nbsp;{{item.RATEPERDAY_1}}Ex/Day </td>

                            <!-- <td *ngIf="item?.USERRATEGUID && action != 'duplicate'">
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="AddNewRate('edit','1',item.USERRATEGUID)">edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(item,1,i)">delete
                                </mat-icon>
                            </td> -->
                            <td *ngIf="action != 'duplicate'">
                                <mat-icon *ngIf="action == 'edit'" style="cursor: pointer;" class="pr-4" (click)="AddNewRate('edit','1',item.USERRATEGUID)">edit</mat-icon>
                                <mat-icon *ngIf="action == 'new'" style="cursor: pointer;" class="pr-4" (click)="AddEditRate('edit','1',item.USERRATEGUID, item, i)">edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(item,1,i)">delete
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_time_cost_d.ico" matTooltip="" matTooltipPosition="above">
                            </mat-icon>
                            &nbsp;&nbsp;
                            <strong>Activity Rates</strong>
                        </div>
                        <div fxFlex="30">
                            <button mat-raised-button color="accent" class="mat-accent" (click)="AddNewRate('new','2','')">New Activity
                                Rate</button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <table>
                        <tr *ngIf="ActivityRateData.length == 0">
                            <td colspan="2" style="text-align: center;font-weight: bold;">
                                Data not found
                            </td>
                        </tr>
                        <tr *ngFor="let Activityitem of ActivityRateData; let i=index;">
                            <td> {{Activityitem?.ACTIVITYID}} - {{Activityitem?.DESCRIPTION}}&nbsp;&nbsp;{{Activityitem?.RATEPERUNIT | toFixedAmount:true}}&nbsp;&nbsp;Ex/Unit
                            </td>
                            <td *ngIf="Activityitem?.ACTIVITYRATEGUID && action != 'duplicate'">
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="AddNewRate('edit','2',Activityitem?.ACTIVITYRATEGUID)">edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(Activityitem,2,i)">
                                    delete
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_expenses_d.ico" matTooltip="" matTooltipPosition="above">
                            </mat-icon>&nbsp;&nbsp;
                            <strong>Sundry Rates</strong>
                        </div>
                        <div fxFlex="30">
                            <button mat-raised-button color="accent" class="mat-accent" (click)="AddNewRate('new','3','')">New Sundry
                                Rate</button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <table>
                        <tr *ngIf="SundryRateData.length == 0">
                            <td colspan="2" style="text-align: center;font-weight: bold;">
                                Data not found
                            </td>
                        </tr>
                        <tr *ngFor="let Sundryitem of SundryRateData; let i=index;">
                            <td> {{Sundryitem?.ACTIVITYID}} - {{Sundryitem?.DESCRIPTION}}&nbsp;&nbsp;{{Sundryitem?.RATEPERUNIT | toFixedAmount:true}}&nbsp;&nbsp;Inc/pages
                            </td>
                            <td *ngIf="Sundryitem?.ACTIVITYRATEGUID">
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="AddNewRate('edit','3',Sundryitem?.ACTIVITYRATEGUID)" *ngIf='action != "duplicate"'>edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(Sundryitem,3, i)">
                                    delete
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
