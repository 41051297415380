import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { GenerateTemplatesDialoagComponent } from 'src/app/main/pages/system-settings/templates/gennerate-template-dialoag/generate-template.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { GenerateTemplatesDialoagComponent } from '@pages/system-settings/templates/gennerate-template-dialoag/generate-template.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-new-packs-dailog',
  templateUrl: './new-packs-dailog.component.html',
  styleUrls: ['./new-packs-dailog.component.scss']
})
export class NewPacksDailogComponent implements OnInit, OnDestroy {
  isLoadingResults: boolean = false;
  errorWarningData: any = {};
  PackTbl: FormGroup;
  confirmDialogRef: any;
  action: string;
  emailname: any = [];
  SendDataForNew: any = [];

  dialogTitle: string;
  isspiner: boolean = false;
  TemplateType: any;

  public KitItemData: any = {
    "KITGUID": "", "ORDER": 0, "TEMPLATEFILE": "", "TEMPLATETYPE": "", "KITITEMGUID": "", "COPIES": 0, "PROMT": false
  };
  formAction: string;
  kitId: any;
  kitguid: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<NewPacksDailogComponent>,
    private toastr: ToastrService,
    private _mainAPiServiceService: MainAPiServiceService,
    public _matDialog: MatDialog,
    private behaviorService: BehaviorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    localStorage.setItem('istrackid', 'NewPacksDailogComponent');
    this.action = data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Pack' : 'New Pack';
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.packs$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.kitguid = result.KITGUID;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'NewPacksDailogComponent' && result['click'] && result['data']['document-pack-item']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'NewPacksDailogComponent' && result['click'] && result['data']['template-email']) {
        this.templateFilefordoc();
      }
      if (localStorage.getItem('istrackid') == 'NewPacksDailogComponent' && result['click'] && result['data']['document-pack-item']) {
        this.PackItemSave();
      }

    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // this.KitItemData.TEMPLATETYPE='0';
    let mainaction = localStorage.getItem('packaction');
    if (mainaction == 'edit') {
      if (this.action == "edit") {
        this.isLoadingResults = true;
        let finalData = { ACTION:'GetData', Filters: { KITITEMGUID: this.data.data.KITITEMGUID } }
        this._mainAPiServiceService.getSetData(finalData, 'document-pack-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
          this.KitItemData.TEMPLATEFILE = res.DATA.RECORDS[0].TEMPLATEFILE;
          this.KitItemData.TEMPLATETYPE = res.DATA.RECORDS[0].TEMPLATETYPE.toString();
          this.TemplateType = this.KitItemData.TEMPLATETYPE;
          this.KitItemData.ORDER = res.DATA.RECORDS[0].ORDER;
          this.KitItemData.KITGUID = res.DATA.RECORDS[0].KITGUID;
          this.KitItemData.KITITEMGUID = res.DATA.RECORDS[0].KITITEMGUID;
          //  this.KitItemData.TEMPLATEFILE=res.DATA.RECORDS[0].TEMPLATEFILE;
          this.isLoadingResults = false;
        });
      }
    } else {
      if (this.action == 'edit') {
        this.KitItemData.TEMPLATEFILE = this.data.data.TEMPLATEFILE;
        this.KitItemData.TEMPLATETYPE = this.data.data.TEMPLATETYPEDESC == "Document" ? '0' : '1';
        this.TemplateType = this.KitItemData.TEMPLATETYPE;
        this.KitItemData.ORDER = this.data.data.ORDER;
      } else {
        this.KitItemData.TEMPLATETYPE = '0';
        this.TemplateType = 0;
      }
    }
  }

  /**
   * This function is used to get the template file for the document
   */
  templateFilefordoc() {
    this.emailname = [];
    this._mainAPiServiceService.getSetData({ACTION: "GetData",FILTERS:{}}, 'template-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        if (response.DATA.RECORDS[0]) {
          this.emailname = response.DATA.RECORDS
        }
      }
    }, err => {
      this.toastr.error(err);
    });
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to template file
   */
  templateFile() {
    this.emailname = [];
    this.emailname.push({ NAME: 'DEFI' });
    this.emailname.push({ NAME: 'DEFR' });
  }

  /**
   * This function is used to change the template
   */
  TempleteChnage(value) {
    this.TemplateType = value;
    if (value == 1) {
      this.templateFilefordoc();
    } else {
      this.emailname = [];
      // this.templateFile();
    }
  }

  /**
   * This function is used to template file change
   */
  TempleteFileChnage(value) {
  }

/**
 * This function is used to select the file
 */
  SelectFile() {
    const dialogRef = this._matDialog.open(GenerateTemplatesDialoagComponent, {
      disableClose: true,
      panelClass: 'contact-dialog',
      data: {
        action: 'new',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'NewPacksDailogComponent');
      // this.KitItemData.TEMPLATEFILE = result.TEMPLATENAME;
      if(result){
      this.KitItemData.TEMPLATEFILE = result.FOLDERPATH + result.TEMPLATENAME
      }
      // else
      // this.KitItemData.TEMPLATEFILE =''
    });
  }
  get f() {
    return this.PackTbl.controls;
  }

  // let data={
  //   TempleteType:this.f.TempleteType.value,
  //   TempleteFile:this.f.TempleteFile.value,
  //   Copies:this.f.Copies.value,
  //   Order:this.f.Order.value,
  //   Prom:this.f.Prompt.value

  // }

/**
 * This function is used to packitem save
 */
  PackItemSave() {
    let mainaction = localStorage.getItem('packaction');
    if (this.KitItemData.TEMPLATEFILE == '' || this.KitItemData.TEMPLATEFILE == null || this.KitItemData.TEMPLATEFILE == undefined) {
      this.toastr.error('Template File Required');
      return;
    } else if (this.KitItemData.TEMPLATETYPE == '' || this.KitItemData.TEMPLATETYPE == null || this.KitItemData.TEMPLATETYPE == undefined) {
      this.toastr.error('Template Type Required');
      return;
    }
    if (mainaction == 'edit') {
      if (this.action == 'edit') {
        this.formAction = "update";
      } else {
        this.formAction = "insert";
      }
    } else {
      //for add only
      this.SendDataForNew = {
        ORDER: this.KitItemData.ORDER,
        TEMPLATEFILE: this.KitItemData.TEMPLATEFILE,
        TEMPLATETYPEDESC: this.KitItemData.TEMPLATETYPE == 0 ? "Document" : "Email"
      }
      if (this.action != 'edit') {
        this.formAction = "insert";
        this.dialogRef.close(this.SendDataForNew);
      } else {
        this.formAction = "update";
        this.dialogRef.close(this.SendDataForNew);
      }
    }
    let SendData = {
      KITITEMGUID: this.KitItemData.KITITEMGUID,
      KITGUID: this.kitguid?this.kitguid:this.KitItemData.KITGUID,
      ORDER: this.KitItemData.ORDER,
      TEMPLATEFILE: this.KitItemData.TEMPLATEFILE,
      TEMPLATETYPE: Number(this.KitItemData.TEMPLATETYPE)
    }


    if (mainaction == 'edit') {
      this.isspiner = true;
      let finalData = { ACTION: this.formAction, DATA: SendData, VALIDATEONLY: true }
      this._mainAPiServiceService.getSetData(finalData, 'document-pack-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.checkValidation(response.DATA.VALIDATIONS, finalData);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.checkValidation(response.DATA.VALIDATIONS, finalData);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.checkValidation(response.DATA.VALIDATIONS, finalData);
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }, err => {
        this.isspiner = false;
        this.toastr.error(err);
      });

    }
    // this.dialogRef.close(data);
  }

  /**
   * This function is used to check the validation 
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      }
      else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }

    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.isspiner = false;
      this.toastr.error(errorData);
    } else if (Object.keys(warningData).length != 0) {
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.kitItemData(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.kitItemData(details);

    }
  }

  /**
   * This function is used to kit Item Data
   */
  kitItemData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'document-pack-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.dialogRef.close("EditPack");
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }

/**
 * This function is used to close the pack tab
 */
  ClosePackTabl() {
    this.dialogRef.close(false);
  }

}
