<div class=" one " fxLayout="column" [formGroup]="matterAddressForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STRATAPLANNUMBER,'has-warning':errorWarningData.Warning?.STRATAPLANNUMBER}">
            <mat-label>Strata Plan Number </mat-label>
            <input matInput formControlName="STRATAPLANNUMBER">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BYLAWTYPE,'has-warning':errorWarningData.Warning?.BYLAWTYPE}">
            <mat-label>By Law Type</mat-label>
            <input matInput formControlName="BYLAWTYPE">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BYLAWNO,'has-warning':errorWarningData.Warning?.BYLAWNO}">
            <mat-label>By Law No. </mat-label>
            <input matInput formControlName="BYLAWNO">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="pr-4" fxFlex="33" [ngClass]="{'has-error':errorWarningData.Error?.SPECIALRESOLUTIONDATE,'has-warning':errorWarningData.Warning?.SPECIALRESOLUTIONDATE}">
            <mat-label>Resolution Date </mat-label>
            <input (dateInput)="ResolutionDatepicker('input', $event)" (dateChange)="ResolutionDatepicker('change', $event)" name="resolutiondatepicker" matInput [matDatepicker]="resolutiondatepicker" formControlName="SPECIALRESOLUTIONDATETEXT">
            <mat-datepicker-toggle matSuffix [for]="resolutiondatepicker"></mat-datepicker-toggle>
            <mat-datepicker #resolutiondatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="34" [ngClass]="{'has-error':errorWarningData.Error?.AGGREGATIONOFENTITLEMENT,'has-warning':errorWarningData.Warning?.AGGREGATIONOFENTITLEMENT}">
            <mat-label>Aggr. of Entitlement </mat-label>
            <input matInput formControlName="AGGREGATIONOFENTITLEMENT">
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="33" [ngClass]="{'has-error':errorWarningData.Error?.TOTALUNITS,'has-warning':errorWarningData.Warning?.TOTALUNITS}">
            <mat-label>Total Units </mat-label>
            <input matInput formControlName="TOTALUNITS">
        </mat-form-field>
    </div>
</div>