import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-matter-mediation',
    templateUrl: './matter-mediation.component.html',
    styleUrls: ['./matter-mediation.component.scss']
})
export class MatterMediationComponent implements OnInit, OnDestroy {
    MatterData: any = {};
    ExportProcessForm: FormGroup
    errorWarningData: any = {};
    statusData: any;
    @Input() action: any;
    @Input() classType: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    MediationForm: FormGroup
    confirmDialogRef: any;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    sub1: Subscription;
    private _matDialog: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService  ,public datepipe: DatePipe, private behaviorService: BehaviorService, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService, private _formBuilder: FormBuilder) {
        this.initFormData()
    }

    initFormData() {
        this.MediationForm = this._formBuilder.group({
            MATTERGUID: [''],
            MEDIATIONDATE: [''],
            MEDIATIONNUMDAYS: [''],
            MEDIATIONAGREEMENTSIGNED: [''],
            MEDIATIONNOTES: [''],
            MATTERMEDIATIONGUID: [''],
            MEDIATIONDATETEXT: [''],
            MEDIATIONAGREEMENTSIGNEDTEXT: ['']

        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MediationForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Mediation" && result.action == 'setData') {
                    this.SaveMediationData();
                } else if (result.classType == "Mediation" && result.action == 'deleteData') {
                    this.DeleteMortageFinance();
                }
            }
        });

        if (this.action === 'edit') {
            this.mapMortgageFinDataToFields();
        }

        this.behaviorService.additionalFieldsValues$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((data) => {
            if (data == true) {
                this.mapMortgageFinDataToFields();
            }
        })

    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
    }

    mapMortgageFinDataToFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-mediation').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterMortageFinanceData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterMortageFinanceData) {
                        this.MediationForm.patchValue({
                            MATTERGUID: MatterMortageFinanceData.MATTERGUID,
                            MEDIATIONDATE: MatterMortageFinanceData.MEDIATIONDATE,
                            MEDIATIONNUMDAYS: MatterMortageFinanceData.MEDIATIONNUMDAYS,
                            MEDIATIONAGREEMENTSIGNED: MatterMortageFinanceData.MEDIATIONAGREEMENTSIGNED,
                            MEDIATIONNOTES: MatterMortageFinanceData.MEDIATIONNOTES,
                            MATTERMEDIATIONGUID: MatterMortageFinanceData.MATTERMEDIATIONGUID
                        });

                        if (MatterMortageFinanceData.MEDIATIONDATE) {
                            let TEMPMORTAGEFINANCECOMMENCEMENTDATE = MatterMortageFinanceData.MEDIATIONDATE.split("/");
                            this.MediationForm.patchValue({
                                MEDIATIONDATETEXT: new Date(TEMPMORTAGEFINANCECOMMENCEMENTDATE[1] + '/' + TEMPMORTAGEFINANCECOMMENCEMENTDATE[0] + '/' + TEMPMORTAGEFINANCECOMMENCEMENTDATE[2]),
                                MEDIATIONDATE: MatterMortageFinanceData.MEDIATIONDATE
                            });
                        }
                        if (MatterMortageFinanceData.MEDIATIONAGREEMENTSIGNED) {
                            let TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE = MatterMortageFinanceData.MEDIATIONAGREEMENTSIGNED.split("/");
                            this.MediationForm.patchValue({
                                MEDIATIONAGREEMENTSIGNEDTEXT: new Date(TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[1] + '/' + TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[0] + '/' + TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[2]),
                                MEDIATIONAGREEMENTSIGNED: MatterMortageFinanceData.MEDIATIONAGREEMENTSIGNED
                            });
                        }

                    }
                }
            });
        }
    }


    DeleteMortageFinance() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-mediation').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterMortageFinanceData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERMEDIATIONGUID: MatterMortageFinanceData.MATTERMEDIATIONGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-mediation').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }


    SaveMediationData() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MediationForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERMEDIATIONGUID;
        }
        delete formData.MEDIATIONDATETEXT;
        delete formData.MEDIATIONAGREEMENTSIGNEDTEXT;


        const formAction = (formData.MATTERMEDIATIONGUID) ? 'Update' : 'Insert';
        const FamilyLawData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(FamilyLawData, 'matter-mediation').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMortageFinanceAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMortageFinanceAfterVAlidation(details);

        }
    }

    SaveMortageFinanceAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.sub1 = this._mainAPiServiceService.getSetData(data, 'matter-mediation').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    CommencementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MediationForm.controls['MEDIATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    ExpirtyDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MediationForm.controls['MEDIATIONAGREEMENTSIGNED'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
}
