import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';

@Component({
  selector: 'app-trust-chart-of-account-dailog',
  templateUrl: './trust-chart-of-account-dailog.component.html',
  styleUrls: ['./trust-chart-of-account-dailog.component.scss']
})
export class TrustChartOfAccountDailogComponent implements OnInit {
  TrustChartAccount: FormGroup;
  isLoadingResults: boolean = false;
  action: any;
  dialogTitle:any;
  isspiner: boolean = false;
  constructor( 
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<TrustChartOfAccountDailogComponent>,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private behaviorService: BehaviorService,
    public _matDialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.action = data.action;
      if (this.action === 'new'){
        this.dialogTitle = 'New Account'
      }else if(this.action === 'edit'){
        this.dialogTitle = 'Update Account'
      }else{
        this.dialogTitle = 'Duplicate Account'
      }
    }
    get f() {
      return this.TrustChartAccount.controls;
    }
  ngOnInit() {
    /**
     * This Form is used to Set the TrustChartAccount data
     */
    this.TrustChartAccount = this._formBuilder.group({
      TRUSTACCOUNTCLASS:[''],
      TRUSTACCOUNTNAME:[''],
      TRUSTACCOUNTNUMBER:[''],
      TRUSTACTIVE:[''],
      TRUSTBANKBSB:[''],
      TRUSTBANKNAME:[''],
      TRUSTBANKACCOUNTNUMBER:[''],
      TRUSTBANKADDRESS:[''],
      TRUSTBANKTERM:[''],
      TRUSTBANKINTERESTRATE:[''],
    });
    this.TrustChartAccount.controls['TRUSTACTIVE'].setValue(true);
  }
/**
 * This function is  used to Save the trust account data
 */
  Savetrustaccount(){
    alert('Savetrustaccount work!!');
  }

}
