import { Component, OnInit } from '@angular/core';
import { FuseSidebarService } from 'src/@fuse/components/sidebar/sidebar.service';
import { tap } from 'rxjs/operators';
import { BehaviorService } from '@_services/Behavior.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-user-firm-directory-contact-detail',
    templateUrl: './user-firm-directory-contact-detail.component.html',
    styleUrls: ['./user-firm-directory-contact-detail.component.scss']
})
export class UserFirmDirectoryContactDetailComponent implements OnInit {
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'))
    // userDetail = '';
    userflag = true;

    $UserDetail = this._BehaviorService.FirmDirecotryData$.pipe(
        tap((userUpdatedData: any) => {
            if (userUpdatedData && userUpdatedData.EMAIL == '' && userUpdatedData.PHONE1 == '' && userUpdatedData.OFFICELOCATION == '' && userUpdatedData.BIOGRAPHY == '') {
                this.userflag = false;
            } else {
                this.userflag = true;
            }
        })
    );

    constructor(private _fuseSidebarService: FuseSidebarService, public _BehaviorService: BehaviorService) {
    }


 /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

    }

    /**
     * This function is used to open the toggle sidebar
     * @param key -key data value
     */
    toggleSidebarOpen(key) {
        /*Keep open timer box once timer added*/
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

}
