import {
    Component,
    OnDestroy,
    OnInit,
    DoCheck,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
} from "@angular/core";
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { ToastrService } from "ngx-toastr";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { TimersService } from "@_services/timers.service";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";

@Component({
    selector: "quick-panel",
    templateUrl: "./quick-panel.component.html",
    styleUrls: ["./quick-panel.component.scss"],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickPanelComponent implements OnInit, OnDestroy, DoCheck {
    private _unsubscribeAll: Subject<any>;
    public _eventsData: any;
    @Input() set eventsData(timer: any) {
        this._eventsData = timer;
    }
    @Input() currentTimerHMS: any;
    timerId: any = "";
    @Output() StopMatterId: EventEmitter<any> = new EventEmitter<any>();
    @Output() EndMatterId: EventEmitter<any> = new EventEmitter<any>();
    @Output() StartMatterId: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("MatterInput1", { static: false })
    MatterInput: ElementRef<HTMLInputElement>;
    searchMatter: Subscription;
    sub1: Subscription;
    exportdatavalue: any;
    searchData: any;
    matterlist: Observable<string[]>;
    mattername: any = [];
    selectedData: any = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    private searchTerms = new Subject<string>();
    subscription: Subscription;

    currentUser = JSON.parse(localStorage.getItem("currentUser"));
    matterListIs: any = [];

    searchMatterValue: any = "";

    constructor(
        private TimersServiceI: TimersService,
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        public _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private cd: ChangeDetectorRef
    ) {
        this._unsubscribeAll = new Subject();
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        // if(this.currentUser){
        //   this.getMatter();
        // }

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            // this.searchResult = response.RESPONSE.results;
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA;
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
                this.searchMatterValue = "";
            }
          });
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    ngDoCheck() {
        this.cd.markForCheck();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.searchMatter?.unsubscribe();
        this.sub1?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    /**
     * This function is used to start the timer
     */
    startTimer(uuid: any) {
        this.StartMatterId.emit(uuid);
    }

    /**
     * This function is used to stop the timer 
     */
    stopTimer(uuid: any) {
        this.StopMatterId.emit(uuid);
    }

    /**
     * This function is use to end the timer 
     */
    endTimer(uuid: any) {
        this.EndMatterId.emit(uuid);
    }

    /**
     * This function is used to Add the timer. 
     */
    AddTimer() {
        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this.MatDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                localStorage.setItem(
                    "set_active_matters",
                    JSON.stringify(result)
                );
                this.TimersServiceI.addTimeEnrtS("");
            }
            this.searchData = [];
            this.matterlist = this.searchData;
        });
    }

    /**
     * This function is used to open the Matter set Timer data value.
     */
    openMatterSetTimer():void {
        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this.MatDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.mattername = [result.SELECTEDDESCRIPTION];
                this.searchMatterValue = result.MATTERGUID;
            } else {
            }
            // if (result) {
            //     localStorage.setItem('set_active_matters', JSON.stringify(result));
            //     this.TimersServiceI.addTimeEnrtS('');
            // }
            // this.searchData = [];
            // this.matterlist = this.searchData;
        });
    }

    /**
     * This function is used to add the timer matter wise.
     */
    async addTimerMatterWise() {
        if (this.searchMatterValue !== "") {
            let selectMatter = this.matterListIs.filter(
                (e) => e.MATTERGUID == this.searchMatterValue
            );
            // if(selectMatter[0]){
            //     localStorage.setItem('set_active_matters', JSON.stringify(selectMatter[0]));
            // };
            await this.getMatter(this.searchMatterValue);
            // if(selectMatter.length==0){
            //   this.toastr.error('Matter not available in database please select another matter.');
            //   return;
            // };

            this.TimersServiceI.addTimeEnrtS("");
            this.searchData = [];
            this.matterlist = this.searchData;
            this.mattername = [];
            this.searchMatterValue = "";
        }
    }

    /**
     * This function is used to get the matter data value.
     */
    async getMatter(MatterID: any) {
        // let payload = {
        //     Active: "active",
        //     FeeEarner: "",
        //     SearchString: ""
        // }

        // let payload = {
        //   GETALLFIELDS: true,
        //   MATTERGUID: MatterID
        // };

        let payload = {
            Action: "GetData",
            Filters: {
                MATTERGUID: MatterID,
                GETALLFIELDS: true,
            },
        };

        return new Promise((next, rej) => {
            if (this.currentUser) {
                this.sub1 = this._mainAPiServiceService
                    .getSetData(payload, "matter")
                    .subscribe(
                        (response) => {
                            if (
                                response.CODE == 200 &&
                                response.STATUS == "success"
                            ) {
                                if (response.DATA.RECORDS[0]) {
                                    this.matterListIs = response.DATA.RECORDS;
                                    localStorage.setItem(
                                        "set_active_matters",
                                        JSON.stringify(this.matterListIs[0])
                                    );
                                }
                                next(this.matterListIs);
                            } else {
                                next(false);
                            }
                        },
                        (err) => {
                            rej(false);
                        }
                    );
            }
        });
    }

    /**
     * This function is used to select the matter for the search data. 
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            Action: "GetData",
            Filters: {
                FastSearch: "True",
                SearchString: event,
            },
        };
        return this._mainAPiServiceService
            .getSetData(searchobj, "matter")
    }

    /**
     * This function is used to data show
     */
    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element;
                this.mattername = [data.SELECTEDDESCRIPTION];
                this.searchMatterValue = data.MATTERGUID;
                if (event.input) {
                    event.input.value = "";
                } else {
                    this.MatterInput.nativeElement.value = "";
                }
                if (
                    this.selectedData.map((ele, index) =>
                        ele.MATTERGUID == data.MATTERGUID ? index : -1
                    )[0] == -1 ||
                    this.selectedData.length == 0
                ) {
                    this.selectedData = [data];
                }
            }
        });
    }

    /**
     * This function is used to remove the matter chip 
     */
    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
        }
        let sortname = fruit.split("-")[0];
        this.rm(sortname);
        this.searchMatterValue = "";
    }

    /**
     * remove the data list value
     * @param sortname 
     */
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (
                element.SHORTNAME.toString() ==
                sortname.replace(/^\s+|\s+$/gm, "")
            ) {
                this.selectedData.splice(index, 1);
            }
        });
    }

    /**
     * This function is used to add the matter chip data value.
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (
                        this.mattername.indexOf(
                            value.replace(/^\s+|\s+$/gm, "")
                        ) == -1
                    ) {
                        this.mattername = [value];
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }
    }

    /**
     * This function is used to get the selected value data.
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (
            this.mattername.indexOf(
                event.option.viewValue.replace(/^\s+|\s+$/gm, "")
            ) == -1
        ) {
            this.mattername = [event.option.viewValue];
            this.MatterInput.nativeElement.value = "";
        }
    }

    /**
     * This function is used to get the selected matter guid data value.
     */
    selectedmatterguid(data: any) {
        if (
            this.selectedData.map((ele, index) =>
                ele.MATTERGUID == data.MATTERGUID ? index : -1
            )[0] == -1 ||
            this.selectedData.length == 0
        ) {
            this.selectedData = [data];
        }
    }
}
