import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr'
import { CorrespondDailogComponent } from '../correspond-dailog/correspond-dailog.component';
import { MAT_DIALOG_DATA, MatDialogRef,MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactSelectDialogComponent } from '../../contact/contact-select-dialog/contact-select-dialog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-old-correspond-dailog',
  templateUrl: './old-correspond-dailog.component.html',
  styleUrls: ['./old-correspond-dailog.component.scss']
})
export class OldCorrespondDailogComponent implements OnInit {

  selectSolicitorData: any;
  private _unsubscribeAll$: Subject<void> = new Subject();

  constructor(
    private _formBuilder: FormBuilder,
    public MatDialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    public dialogRef: MatDialogRef<CorrespondDailogComponent>,
    private toastr: ToastrService,
    public behaviorService: BehaviorService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    localStorage.setItem('istrackid', 'MatterCorrespondPopupComponent');
    this.behaviorService.matterClassData$.subscribe(result => {
      if (result) {
        this.MatterClassData = result.LOOKUPFULLVALUE;
      }
    });


    this.behaviorService.APIretryCall$.subscribe((result) => {

      if (localStorage.getItem('istrackid') == 'MatterCorrespondPopupComponent' && result['click'] && (result['data']['contact'] || result['data']['GetLookups'])) {
        this.ngOnInit();
      }
    });
  }
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  correspondForm: FormGroup;
  MatterClassData: any = [];
  errorWarningData: any = {};
  CorrspondClassData: any = [];
  isLoadingResults: boolean = false;
  isBanfi: any = "";
  isspiner: boolean = false;
  isEdit: boolean = false;
  matterData: any;
  selectedContactData: any;
  contactDetails = {};

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.behaviorService.MatterEditData$.subscribe(result => {
      this.matterData = result;
    });
    this.correspondForm = this._formBuilder.group({
      TYPE: ['General'],
      PERSONGUID: [''],
      PERSONGUIDTEXT: [''],
      SOLICITORGUID: [''],
      SOLICITORGUIDTEXT: [''],
      REFERENCE: [''],
      MATTERGUID: [''],
      MATTERCONTACTGUID: [''],
      RELATIONSHIP: [''],
      SHAREOFESTATE: [''],
    });
    this.isLoadingResults = true;
    let contactRoleLookups = JSON.parse(localStorage.getItem('contact_role_lookups_' + this.MatterClassData));
    if (contactRoleLookups && contactRoleLookups != null) {
      this.CorrspondClassData = contactRoleLookups;
      this.isLoadingResults = false;
    } else {
      this._mainAPiServiceService.getSetData({ 'LookupType': 'contact role', 'MatterClass': this.MatterClassData }, 'GetLookups').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.CorrspondClassData = responses.DATA.LOOKUPS;
          localStorage.setItem('contact_role_lookups_' + this.MatterClassData, JSON.stringify(responses.DATA.LOOKUPS));
          this.isLoadingResults = false;
        }
      });
    }
    this._mainAPiServiceService.getSetData({ "ACTION": "GetData","FILTERS": { 'CONTACTGUID': this._data.FIRMGUID } }, 'contact').subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        if (responses.DATA.RECORDS[0] && responses.DATA.RECORDS[0]['CONTACTTYPE'] == "Company") {
          this.contactDetails = responses.DATA.RECORDS;
        }
      }
    });
    this.isEdit = this._data.type == "edit" ? true : false;
    if (this._data.type == "edit") {
      let editData = this._data.EditData;
      this.correspondForm.controls['SOLICITORGUID'].setValue(editData.SOLICITORGUID);
      this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(editData.SOLICITORNAME);
      this.correspondForm.controls['PERSONGUID'].setValue(editData.PERSONGUID);
      this.correspondForm.controls['PERSONGUIDTEXT'].setValue(editData.CONTACTNAME);
      this.correspondForm.controls['REFERENCE'].setValue(editData.REFERENCE);
      this.correspondForm.controls['MATTERGUID'].setValue(editData.MATTERGUID);
      this.correspondForm.controls['TYPE'].setValue(editData.TYPENAME);
      this.correspondForm.controls['MATTERCONTACTGUID'].setValue(editData.MATTERCONTACTGUID);
      this.correspondForm.controls['RELATIONSHIP'].setValue(editData.RELATIONSHIP);
      this.correspondForm.controls['SHAREOFESTATE'].setValue(editData.SHAREOFESTATE);
      this.isBanfi = editData.TYPENAME;
    }
  }
  get f() {
    return this.correspondForm.controls;
  }

  /**
   * This function is used to select the contact
   */
  selectContact() {
    const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: this.f.TYPE.value, 'contect_details': this.contactDetails } });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'MatterCorrespondPopupComponent');
      if (result) {
        this.selectedContactData = result;
        this.behaviorService.MatterNum('defaults');
        this.f.MATTERGUID.value ?'': this.correspondForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
        this.correspondForm.controls['PERSONGUID'].setValue(result.CONTACTGUID);
        this.correspondForm.controls['PERSONGUIDTEXT'].setValue(result.CONTACTNAME);
      }
    });
  }

  /**
   * This function is used to select the solicitor data
   */
  selectSolicitor() {
    const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: this.f.TYPE.value, 'contect_details': this.contactDetails } });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'MatterCorrespondPopupComponent');
      if (result) {
        this.selectSolicitorData = result;
        this.behaviorService.MatterNum('defaults');
        this.correspondForm.controls['SOLICITORGUID'].setValue(result.CONTACTGUID);
        this.correspondForm.controls['SOLICITORGUIDTEXT'].setValue(result.CONTACTNAME);
      }
    });
  }

  /**
   * This functgion is used to save the correspond data value
   * @returns value data
   */
  saveCorrespomndDetail() {
    this.isspiner = true;
    let details: any = {
      TYPE: this.f.TYPE.value,
      SOLICITORGUID: this.f.SOLICITORGUID.value,
      REFERENCE: this.f.REFERENCE.value,
      PERSONGUID: this.f.PERSONGUID.value,
      MATTERGUID: this.f.MATTERGUID.value,
      RELATIONSHIP: this.f.RELATIONSHIP.value,
      SHAREOFESTATE: this.f.SHAREOFESTATE.value,
    }
    if (this.matterData) {
      details.MATTERGUID = this.matterData.MATTERGUID;
      let PostTimeEntryData: any = { FORMACTION: 'insert', VALIDATEONLY: true, DATA: details };
      this._mainAPiServiceService.getSetData(PostTimeEntryData, 'matter-contact').subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
        } else if (response.MESSAGE == 'Not logged in') {
          this.isspiner = false;
          this.dialogRef.close(false);
        }
      }, (error: any) => {
        console.log(error);
      });
    } else {
      if (!this.f.PERSONGUID.value) {
        this.isspiner = false;
        let errorData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        errorData.push('');
        tempError['PERSONGUID'] = 'value';
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        return this.toastr.error('You must select person');

      }
      let parsentage = (this.isBanfi == "Beneficiary") ? ' - ' + this.f.SHAREOFESTATE.value + '%' : '';
      let itemdata: any = details;
      itemdata.TYPENAME = details.TYPE;
      if (this.selectedContactData) {
        itemdata.CONTACTNAME = this.selectedContactData.CONTACTNAME;
      }
      if (this.selectSolicitorData) {
        itemdata.SOLICITORNAME = this.selectSolicitorData.CONTACTNAME;
      }
      let data = { 'showData': { 'type': this.f.TYPE.value, 'Text': this.f.PERSONGUIDTEXT.value, 'ItemData': itemdata, 'SOLICITORNAME': this.f.SOLICITORGUIDTEXT.value }, 'saveData': details };
      this.dialogRef.close(data);
    }
  }

  /**
   * This function is used to check the validation data
   * @param bodyData 0body data value
   * @param PostTimeEntryData -post time entry data value
   */
  checkValidation(bodyData: any, PostTimeEntryData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    // errorData
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'NO' || value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'WARNING') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      // this.toastr.warning(warningData);
      this.isspiner = false;
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveCorrespomnd(PostTimeEntryData);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveCorrespomnd(PostTimeEntryData);
    }
  }

  /**
   * This function is used to save the Correspond data value
   * @param PostTimeEntryData 
   */
  saveCorrespomnd(PostTimeEntryData: any) {
    PostTimeEntryData.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(PostTimeEntryData, 'matter-contact').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success('Matter Contact save successfully');
        this.isspiner = false;
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.isspiner = false;
        this.toastr.warning(response.MESSAGE);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.isspiner = false;
        this.toastr.error(response.MESSAGE);
      } else if (response.MESSAGE == 'Not logged in') {
        this.isspiner = false;
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to Update the Correspond Details value
   */
  updateCorrespomndDetail() {
    this.isspiner = true;
    let details: any = {
      TYPE: this.f.TYPE.value,
      SOLICITORGUID: this.f.SOLICITORGUID.value,
      REFERENCE: this.f.REFERENCE.value,
      PERSONGUID: this.f.PERSONGUID.value,
      MATTERGUID: this.f.MATTERGUID.value,
      MATTERCONTACTGUID: this.f.MATTERCONTACTGUID.value,
      RELATIONSHIP: this.f.RELATIONSHIP.value,
      SHAREOFESTATE: this.f.SHAREOFESTATE.value,
    };
    if (this.f.MATTERGUID.value != undefined) {
      let PostTimeEntryData = { FORMACTION: 'update', VALIDATEONLY: true, DATA: details };
      this._mainAPiServiceService.getSetData(PostTimeEntryData, 'matter-contact').subscribe(response => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.checkValidation(response.DATA.VALIDATIONS, PostTimeEntryData);
        } else if (response.MESSAGE == 'Not logged in') {
          this.isspiner = false;
          this.dialogRef.close(false);
        }
      }, (error: any) => {
        console.log(error);
      });
    } else {
      let parsentage = (this.isBanfi == "Beneficiary") ? ' - ' + this.f.SHAREOFESTATE.value + '%' : '';
      let itemdata: any = details;
      itemdata.TYPENAME = details.TYPE;
      if (this.selectedContactData) {
        itemdata.CONTACTNAME = this.selectedContactData.CONTACTNAME;
      }
      if (this.selectSolicitorData) {
        itemdata.SOLICITORNAME = this.selectSolicitorData.CONTACTNAME;
      }
      let data = { 'showData': { 'type': this.f.TYPE.value, 'Text': this.f.PERSONGUIDTEXT.value, 'ItemData': itemdata, 'SOLICITORNAME': this.f.SOLICITORGUIDTEXT.value }, 'saveData': details };
      this.dialogRef.close(data);
    }
  }

  /**
   * This function is used to Change the type.
   * @param val -val data
   */
  changeType(val) {
    this.isBanfi = val;
    this.AfterViewInitnClient();
  }
  /**
   * This function is used to AterViewInitclient
   */
  AfterViewInitnClient() {
    let tempError: any = [];
    let tempWarning: any = [];
    tempWarning['SHAREOFESTATE'] = { 'SHAREOFESTATE': '' };
    tempWarning['RELATIONSHIP'] = { 'RELATIONSHIP': '' };
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
  }

}



