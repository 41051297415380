<div class=" one " fxLayout="column" [formGroup]="matterAddressForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTVOLUME,'has-warning':errorWarningData.Warning?.PARENTVOLUME}">
            <mat-label>Volume </mat-label>
            <input matInput formControlName="PARENTVOLUME">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTFOLIOID,'has-warning':errorWarningData.Warning?.PARENTFOLIOID}">
            <mat-label>Folio Id </mat-label>
            <input matInput formControlName="PARENTFOLIOID">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTLOT,'has-warning':errorWarningData.Warning?.PARENTLOT}">
            <mat-label>Lot </mat-label>
            <input matInput formControlName="PARENTLOT">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTPLANTYPE,'has-warning':errorWarningData.Warning?.PARENTPLANTYPE}">
            <mat-label>Plan Type </mat-label>
            <input matInput formControlName="PARENTPLANTYPE">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTPLANNUMBER,'has-warning':errorWarningData.Warning?.PARENTPLANNUMBER}">
            <mat-label>Plan No. </mat-label>
            <input matInput formControlName="PARENTPLANNUMBER">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNALLOTMENT,'has-warning':errorWarningData.Warning?.PARENTCROWNALLOTMENT}">
            <mat-label>Crown Allotment </mat-label>
            <input matInput formControlName="PARENTCROWNALLOTMENT">
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNBLOCK,'has-warning':errorWarningData.Warning?.PARENTCROWNBLOCK}">
            <mat-label>Crown Block </mat-label>
            <input matInput formControlName="PARENTCROWNBLOCK">
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNSECTION,'has-warning':errorWarningData.Warning?.PARENTCROWNSECTION}">
            <mat-label>Crown Section </mat-label>
            <input matInput formControlName="PARENTCROWNSECTION">
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNPORTION,'has-warning':errorWarningData.Warning?.PARENTCROWNPORTION}">
            <mat-label>Crown Portion </mat-label>
            <input matInput formControlName="PARENTCROWNPORTION">
        </mat-form-field>

    </div>

</div>