<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_settelmatter_main">
    <h2 mat-dialog-title>Matter Address
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="matterAddressForm" id="matterAddressForm" [formGroup]="matterAddressForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="Address">
                            <app-settlment-address [errorWarningData]="errorWarningData" [matterAddressForm]="matterAddressForm"></app-settlment-address>
                        </mat-tab>
                        <mat-tab label="Title">
                            <app-settlment-title [errorWarningData]="errorWarningData" [matterAddressForm]="matterAddressForm"></app-settlment-title>
                        </mat-tab>
                        <mat-tab label="Parent Title">
                            <app-settlment-parent-title [errorWarningData]="errorWarningData" [matterAddressForm]="matterAddressForm"></app-settlment-parent-title>
                        </mat-tab>
                        <mat-tab label="Strata">
                            <app-settlment-strata [errorWarningData]="errorWarningData" [matterAddressForm]="matterAddressForm"></app-settlment-strata>
                        </mat-tab>
                        <mat-tab label="Council">
                            <app-settlment-council [errorWarningData]="errorWarningData" [matterAddressForm]="matterAddressForm"></app-settlment-council>
                        </mat-tab>
                        <mat-tab label="Crown Allotment">
                            <app-settlment-crow-allotment [errorWarningData]="errorWarningData" [matterAddressForm]="matterAddressForm">
                            </app-settlment-crow-allotment>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveMatterAddress()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>