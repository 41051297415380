<div class="one product-app maxh-516" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example daterang-pik" fxFlex="40">
            <mat-label>Date Range</mat-label>
            <input matInput [satDatepicker]="picker2" readonly (dateChange)="choosedDate('change', $event)">
            <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
        </mat-form-field>
    </div>
    <!-- <mat-accordion *ngFor="let val of ReceipttData" class="padding_class">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Personal data
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <p class="pr-4" fxFlex="80">Amount Received</p>
                <p fxFlex="20" class="pr-4">{{val.AMOUNTRECEIVED}}</p>
                <hr/>
            </div>
            <div>
                <p class="pr-4" fxFlex="80">Date Received</p>
                <p fxFlex="20" class="pr-4">{{val.DATERECEIVED}}</p>
                <hr/>
            </div>
            <div>
                <p class="pr-4" fxFlex="80">ExGST Amount </p>
                <p fxFlex="20" class="pr-4">{{val.EXGSTAMOUNT}}</p>
            </div>
            <div>
                <p class="pr-4" fxFlex="80">GST
                </p>
                <p fxFlex="20" class="pr-4">{{val.GST}}</p>
            </div>
            <div>
                <p class="pr-4" fxFlex="80">Payment Type</p>
                <p fxFlex="20" class="pr-4">{{val.PAYMENTTYPE}}</p>
            </div>
            <div>
                <p class="pr-4" fxFlex="80">Receipt Code</p>
                <p fxFlex="20" class="pr-4">{{val.RECEIPTCODE}}</p>
            </div>
            <div>
            </div>
        </mat-expansion-panel>
    </mat-accordion> -->

    <!-- material list -->
    <table mat-table [dataSource]="receiptDatasource" class="mat-elevation-z8" *ngIf="ReceipttData.length > 0">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <ng-container matColumnDef="AMOUNTRECEIVED">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.AMOUNTRECEIVED}} </td>
        </ng-container>
      
        <ng-container matColumnDef="DATERECEIVED">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.DATERECEIVED}} </td>
        </ng-container>
      
        <ng-container matColumnDef="EXGSTAMOUNT">
          <th mat-header-cell *matHeaderCellDef>  Amount (Ex) </th>
          <td mat-cell *matCellDef="let element"> {{element.EXGSTAMOUNT}} </td>
        </ng-container>
      
        <ng-container matColumnDef="GST">
          <th mat-header-cell *matHeaderCellDef> GST </th>
          <td mat-cell *matCellDef="let element"> {{element.GST}} </td>
        </ng-container>

        <ng-container matColumnDef="PAYMENTTYPE">
            <th mat-header-cell *matHeaderCellDef> Payment Type </th>
            <td mat-cell *matCellDef="let element"> {{element.PAYMENTTYPE}} </td>
          </ng-container>

          <ng-container matColumnDef="RECEIPTCODE">
            <th mat-header-cell *matHeaderCellDef> Receipt Code </th>
            <td mat-cell *matCellDef="let element"> {{element.RECEIPTCODE}} </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    <!-- ends here ~ material list -->

    <h2 *ngIf="ReceipttData.length == 0">There is no data to show</h2>

</div>