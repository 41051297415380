import { Component, OnInit, Input, EventEmitter, Output, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactSelectDialogComponent } from '../../../contact/contact-select-dialog/contact-select-dialog.component';
import { FormGroup } from '@angular/forms';
import { CorrespondDailogComponent } from '../../correspond-dailog/correspond-dailog.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { round } from 'lodash';
import { switchMap, tap } from 'rxjs/operators';
// import { OldCorrespondDailogComponent } from '../../old-correspond-dailog/old-correspond-dailog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {
    Correspond = [];
    highlightedRows: any;
    theme_type = localStorage.getItem('theme_type');
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    CurrentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    UserData = JSON.parse(localStorage.getItem('currentUser')).PRODUCTTYPE;
    tabTitle = this.UserData == "Barrister" ? 'Solicitor' : 'Client';
    CorrespondEdit: any;
    name: string;
    position: number;
    weight: number;
    symbol: string;
    isspiner: boolean;
    @Output() CorrespondDetail: EventEmitter<any> = new EventEmitter<any>();
    isEdit: boolean = false;
    @Input() isEditMatter: any;
    @Input() matterdetailForm: FormGroup;
    @Input() errorWarningData: any;
    @Input() toolTipList: any;
    @Input() action: string;
    @ViewChild('table', { static: true }) table: MatTable<any>;
    toolTipPostion = "above";
    ContactRoleData: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    type: any = null;
    correspondListIndex: any;
    dragDisabled = true;
    highlightedNewcorrespondRows: any = 0;
    currentDraggedTypename: string;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    matterClassData$ = this.behaviorService.matterClassAPICallData$.pipe(
        switchMap(result => this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Contact Role', 'MatterClass': result?.LOOKUPFULLVALUE } }, 'lookup')),
        tap(responses => {
            if (responses.CODE === 200 && responses.STATUS === 'success') {
                this.ContactRoleData = responses.DATA.LOOKUPS;
            }
        })
    );
    constructor(
        private MatDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        private dialogRef: MatDialogRef<ClientComponent>,
        public tooltipService: TooltipService,
        private dialogRefDe: MatDialogRef<FuseConfirmDialogComponent>, private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {

        this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'MatterPopupComponent' && this.type != null && result['click'] && result['data']['matter-contact']) {
                this.SelectElement(this.type);
            }
        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.sub1 = this.behaviorService.MatterEditData$.subscribe(result => {
            if (result) { this.isEdit = true; } else { this.isEdit = false; }
        });
        this.loadData('', '');
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
    }

    get f() {
        return this.matterdetailForm.controls;
    }
    Addcorres_party() {
        let classTyepData = "";
        this.sub2 = this.behaviorService.matterClassData$.subscribe(result => {
            if (result) {
                classTyepData = result.LOOKUPFULLVALUE;
            }
        });
        if (this.CurrentUser.PRODUCTTYPE == 'Barrister') {
            const dialogRef = this.MatDialog.open(CorrespondDailogComponent, { width: '100%', disableClose: true, data: { type: 'new', classTyep: classTyepData, 'FIRMGUID': this.f.FIRMGUID.value } });
            dialogRef.afterClosed().subscribe(async result => {
                localStorage.setItem('istrackid', 'MatterPopupComponent');
                if (result == true) {
                    this.loadData('', '');
                } else if (result) {
                    result.saveData.action = 'add';
                    this.CorrespondDetail.emit(result.saveData);
                    const resultData = { ...result.showData }


                    for (let index = 0; index < resultData.ItemData.CorrespondRoleAndReference.length; index++) {
                        await new Promise<void>(async (next, reject) => {


                            const element = resultData.ItemData.CorrespondRoleAndReference[index];
                            resultData.type = element.role
                            resultData.ItemData.TYPE = element.role
                            resultData.ItemData.TYPENAME = element.role
                            resultData.ItemData.REFERENCE = element.ref
                            const data = JSON.parse(JSON.stringify(resultData));
                            this.Correspond.push(data);
                            next();
                        })

                    };

                }
            });
        } else {
            const dialogRef = this.MatDialog.open(CorrespondDailogComponent, { width: '100%', disableClose: true, data: {action:this._data.action, type: 'new', classTyep: classTyepData, 'FIRMGUID': this.f.FIRMGUID.value, 'CONTACTTYPE': this.f.CONTACTTYPE.value } });
            dialogRef.afterClosed().subscribe(async result => {
                localStorage.setItem('istrackid', 'MatterPopupComponent');
                if (result == true) {
                    this.loadData('', '');
                } else if (result) {
                    result.saveData.action = 'add';
                    this.CorrespondDetail.emit(result.saveData);
                    const resultData = { ...result.showData }


                    for (let index = 0; index < resultData.ItemData.CorrespondRoleAndReference.length; index++) {
                        await new Promise<void>(async (next, reject) => {


                            const element = resultData.ItemData.CorrespondRoleAndReference[index];
                            resultData.type = element.role
                            resultData.ItemData.TYPE = element.role
                            resultData.ItemData.TYPENAME = element.role
                            resultData.ItemData.REFERENCE = element.ref
                            const data = JSON.parse(JSON.stringify(resultData));
                            this.Correspond.push(data);
                            next();
                        })

                    };

                }
            });
        }
    }
    ContactMatter() {
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
            width: '100%', disableClose: true, data: { type: "", 'default': this.tabTitle }
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                if (this._data.action == "new" || this._data.action == "duplicate") {
                    this.CoommonMatterNum(result.CONTACTGUID);
                }
                this.matterdetailForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
                this.matterdetailForm.controls['Clientmattertext'].setValue(result.CONTACTNAME + ' - ' + result.SUBURB);
                if (result.CONTACTTYPE == "Person") {
                    this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
                    this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add ' + result.CONTACTNAME + ' to the Correspondent list?';
                    this.confirmDialogRef.afterClosed().subscribe(result2 => {
                        if (result2) {
                            let matterData: any = {};
                            this.behaviorService.MatterEditData$.subscribe(result => {
                                matterData = result;
                            });
                            if (this._data.action != "new") {
                                let details: any = {
                                    TYPE: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                    REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: matterData.MATTERGUID
                                };
                                this._mainAPiServiceService.getSetData({ FORMACTION: 'insert', VALIDATEONLY: false, DATA: details }, 'matter-contact').subscribe(response => {
                                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                                        this.toastr.success('Matter Contact save successfully');
                                        this.loadData('', '');
                                        this.isspiner = false;
                                    } else if (response.CODE == 451 && response.STATUS == 'warning') {
                                        this.isspiner = false;
                                        this.toastr.warning(response.MESSAGE);
                                    } else if (response.CODE == 450 && response.STATUS == 'error') {
                                        this.isspiner = false;
                                        this.toastr.error(response.MESSAGE);
                                    } else if (response.MESSAGE == 'Not logged in') {
                                        this.isspiner = false;
                                    }
                                }, (error: any) => {
                                    console.log(error);
                                });
                            } else {
                                this.CorrespondDetail.emit({
                                    TYPE: "Correspondent", SOLICITORGUID: result.CONTACTGUID,
                                    REFERENCE: "", PERSONGUID: result.CONTACTGUID, MATTERGUID: "", action: 'add'
                                });
                                let itemData: any = {
                                    SOLICITORGUID: '',
                                    REFERENCE: '',
                                    PERSONGUID: result.CONTACTGUID,
                                    MATTERGUID: undefined,
                                    RELATIONSHIP: '',
                                    SHAREOFESTATE: '',
                                    CONTACTNAME: result.CONTACTNAME,
                                    TYPENAME: 'Correspondent',
                                    SOLICITORNAME: ''
                                };
                                this.Correspond.push({ 'type': "Correspondent", 'Text': result.CONTACTNAME, ItemData: itemData });
                            }
                        }
                        this.confirmDialogRef = null;
                    });
                }
            }
        });
    }
    CoommonMatterNum(selectedFIRMGUID) {
       this.sub3 = this._mainAPiServiceService.getSetData({ Action: 'default', VALIDATEONLY: true, DATA: { FIRMGUID: selectedFIRMGUID, OWNERGUID: '', PRIMARYFEEEARNERGUID: '' } }, 'matter').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                const Matterdata = res.DATA.DEFAULTVALUES;
                if (this._data.action != 'duplicate') {
                    this.matterdetailForm.patchValue({
                        SHORTNAME: !this.matterdetailForm.controls['SHORTNAME'].value ? Matterdata['SHORTNAME'] : this.matterdetailForm.controls['SHORTNAME'].value,
                        GSTTYPE: Matterdata.BILLINGGROUP['GSTTYPE'],
                        BILLINGMETHOD: Matterdata.BILLINGGROUP['BILLINGMETHOD'],
                        ONCHARGEDISBURSEMENTGST: Matterdata.BILLINGGROUP['ONCHARGEDISBURSEMENTGST'],
                        RATEPERHOUR: round(Matterdata.BILLINGGROUP['RATEPERHOUR']).toFixed(2),
                        RATEPERDAY: round(Matterdata.BILLINGGROUP['RATEPERDAY']).toFixed(2)
                    });
                } else {
                    this.matterdetailForm.patchValue({
                        SHORTNAME: !this.matterdetailForm.controls['SHORTNAME'].value ? Matterdata['SHORTNAME'] : this.matterdetailForm.controls['SHORTNAME'].value
                    });
                }
            } else if (res.MESSAGE === 'Not logged in') {
                this.dialogRef.close(false);
            } else {
                this.matterdetailForm.controls['SHORTNAME'].setValue(res.DATA.DEFAULTVALUES['SHORTNAME']);
            }
        }, error => { this.toastr.error(error); });
    }

    editElement(editElement, index, type?) {
        this.correspondListIndex = index;
        const dialogRef = this.MatDialog.open(CorrespondDailogComponent, {
            width: '100%', disableClose: true, data: { EditData: editElement, type: 'edit', 'FIRMGUID':(this._data.action == "edit" || this._data.action == "duplicate") ?  editElement?.PERSONGUID : this.f.FIRMGUID.value }
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');

            if (result) {
                if (this.isEditMatter != undefined) {
                    this.loadData(this.isEditMatter, '');
                } else {
                    result.saveData['action'] = 'edit';
                    result.saveData.index = this.correspondListIndex;
                    this.Correspond[this.correspondListIndex] = result.showData;
                    const saveDataRole = []
                    this.Correspond.forEach(element => {
                        element.ItemData.TYPE ? saveDataRole.push({ role: element.ItemData.TYPE, ref: element.ItemData.REFERENCE }) : ''
                    });
                    result.saveData.CorrespondRoleAndReference = saveDataRole;
                    this.CorrespondDetail.emit(result.saveData);
                }
            } else {
                this.loadData('', '');
            }
        });
    }
    deleteElement(editElement) {
        this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
            disableClose: true,
            width: '100%',
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._mainAPiServiceService.getSetData({ FORMACTION: 'delete', VALIDATEONLY: false, DATA: { MATTERCONTACTGUID: editElement.MATTERCONTACTGUID } }, 'matter-contact').subscribe(response => {
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                        if (this._data.action == 'duplicate') {
                            this.loadData('', true);
                        } else {
                            this.loadData('', '');
                        }
                        this.toastr.success('Matter Contact Delete successfully');
                        this.isspiner = false;
                    }
                }, (error: any) => {
                    console.log(error);
                });
            }
            this.confirmDialogRef = null;
        });
    }
    removeNewCorrespond(index) {

        this.CorrespondDetail.emit({ action: 'remove', index: index });
        this.Correspond.splice(index, 1);

    }
    SelectElement(type) {
        this.type = type;
      this.sub4 = this._mainAPiServiceService.getSetData({ FORMACTION: 'move order', VALIDATEONLY: false, DATA: { MATTERCONTACTGUID: this.highlightedRows, Direction: type } }, 'matter-contact').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                if (this._data.action == 'duplicate') {
                    this.loadData('', true);
                } else {
                    this.loadData('', '');
                }
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isspiner = false;
        }, (error: any) => {
            console.log(error);
        });
    }
    loadData(matterguid = '', data) {
        if ((data == true && this._data.action == 'duplicate') || this._data.action == 'edit') {
            if (matterguid) {
                this.isEditMatter = matterguid;
            }
            if (this.isEditMatter != undefined) {
               this.sub5 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MatterGuid: this.isEditMatter } }, 'matter-contact').subscribe(response => {
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                        if (response.DATA.RECORDS[0]) {
                            this.highlightedRows = response.DATA.RECORDS[0].MATTERCONTACTGUID;
                        }

                        if (this.action == "duplicate") {
                            this.isEdit = false;
                            this.isEditMatter = undefined;

                            for (let i = 0; i < response.DATA.RECORDS.length; i++) {

                                const currentRespData = response.DATA.RECORDS[i];

                                let editData = {
                                    "type": currentRespData["TYPENAME"],
                                    "Text": currentRespData["CONTACTNAME"],
                                    "ItemData": {
                                        "TYPE": currentRespData["TYPENAME"],
                                        "SOLICITORGUID": currentRespData["SOLICITORGUID"],
                                        "PERSONGUID": currentRespData["PERSONGUID"],
                                        "RELATIONSHIP": currentRespData["RELATIONSHIP"],
                                        "SHAREOFESTATE": currentRespData["SHAREOFESTATE"],
                                        "CorrespondRoleAndReference": [
                                            {
                                                "role": ""
                                            }
                                        ],
                                        "TYPENAME": currentRespData["TYPENAME"],
                                        "CONTACTNAME": currentRespData["CONTACTNAME"],
                                        "SOLICITORNAME": currentRespData["SOLICITORNAME"],
                                        "action": "add"
                                    },
                                    "SOLICITORNAME": currentRespData["SOLICITORNAME"]
                                }
                                this.Correspond.push(editData)
                                this.CorrespondDetail.emit(editData)
                            }

                        } else {
                            this.CorrespondEdit = response.DATA.RECORDS;
                        }

                    } else if (response.MESSAGE == 'Not logged in') {
                        this.dialogRef.close(false);
                    }
                }, error => {
                    console.log(error);
                });
            }
        }
    }

    /**
     * Sets the current dragged typename when a drag operation starts.
     *
     * @param typename - The typename of the item being dragged.
     */
    onDragStart(ele): void {
        this.currentDraggedTypename = ele.TYPENAME;
        this.highlightedRows = ele.MATTERCONTACTGUID;
    }
    
    /**
     * Handles the drop event when an item is dropped into a new position.
     *
     * @param event - The drag-and-drop event containing information about the drop.
     */
    onDrop(event: CdkDragDrop<string[]>): void {
        // Retrieve the typename of the item being dragged and the target index
        const targetTypename = this.currentDraggedTypename;
        const targetIndex = event.currentIndex;
        // Check if the drop is allowed based on your conditions
        if (!this.isDropAllowed(targetTypename, targetIndex)) {
            // Prevent the drop if not allowed
            this.toastr.warning('Please rearrange the rows based on their Contact Type.');
            return;
        }
        // Move the item in the array to the new position
        moveItemInArray(this.CorrespondEdit, event.previousIndex, event.currentIndex);
        // this.isspiner = true;
        let newPosition;
        const filteredArray = this.CorrespondEdit.filter((item) => item.TYPENAME === targetTypename);
        filteredArray.map((cors,index) => {
            if(cors.MATTERCONTACTGUID == this.highlightedRows) {
                newPosition = index + 1;
            }
        })
        this.sub4 = this._mainAPiServiceService.getSetData({ Action: 'Reorder', DATA: { MATTERCONTACTGUID: this.highlightedRows, NEWPOSITION: newPosition } }, 'matter-contact').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.isspiner = false;
                if (this._data.action == 'duplicate') {
                    this.loadData('', true);
                } else {
                    this.loadData('', '');
                }
            } else if (response.MESSAGE == 'Not logged in') {
                this.isspiner = false;
                this.dialogRef.close(false);
            } else {
                this.isspiner = false;
            }
        }, (error: any) => {
            this.isspiner = false;
        });
    }

    /**
     * Determines whether the drop operation is allowed based on specified conditions.
     *
     * @param targetTypename - The typename of the item being dragged.
     * @param targetIndex - The target index where the item is being dropped.
     * @returns A boolean indicating whether the drop is allowed.
     */
    isDropAllowed(targetTypename: string, targetIndex: number): boolean {
        //you can check if the target index is within the same TYPENAME group
        const sameTypename = this.CorrespondEdit[targetIndex].TYPENAME === targetTypename;
        return sameTypename;
    }
}
