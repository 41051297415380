<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<ng-container *ngIf="CLICKMATTER$ | async"></ng-container>
<div class="popup_main_div timeentry_popup  select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title> {{headertext}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one mb-24" fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <form [formGroup]="ProtectedTrust" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="timeEntryForm"
                id="timeEntryForm">
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <div class="one  mb-24" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="50"
                                class="pr-4" appearance="outline"
                                [ngClass]="{'has-error':errorWarningData.Error?.DATE,'has-warning':errorWarningData.Warning?.DATE}">
                                <mat-label>Date</mat-label>
                                <input matInput formControlName="DATETEXT"
                                    (dateChange)="CommencementDate('change', $event)"
                                    [matDatepicker]="picker"
                                    placeholder="Choose a date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                                <mat-label>Matter Ledger</mat-label>
                                <input matInput formControlName="ACCOUNTNAME">
                                <mat-icon matSuffix (click)="openSelectWindow()"
                                    class="select-Matter-icon">
                                    <img
                                        src="assets/icons/web_app/icon_receipt_d.ico">
                                </mat-icon>

                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">

                            <mat-form-field fxFlex="50" class="pr-4"
                                appearance="outline">
                                <mat-label>Protection Type</mat-label>
                                <mat-select formControlName="PROTECTIONTYPE">
                                    <mat-option
                                        *ngFor="let MatterStatusLookup of MatterStatusLookupsData"
                                        value="{{MatterStatusLookup.LOOKUPFULLVALUE}}">
                                        {{MatterStatusLookup.LOOKUPFULLVALUE}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50"
                                class="pr-4">
                                <mat-label>Amount</mat-label>
                                <input class="sideText" type="text"
                                    formControlName="AMOUNT" currencyMask
                                    matInput>

                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" fxFlex="33" class="pr-4">
                                <mat-label>Amount Used</mat-label>

                                <input class="sideText" type="text" formControlName="AMOUNTUSED" currencyMask matInput >

                            </mat-form-field> -->

                        </div>

                        <div fxLayout="row wrap" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100"
                                [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}">
                                <mat-label>Reason</mat-label>
                                <textarea formControlName="NOTE" matInput></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button [disabled]="isspiner" mat-raised-button color="accent"
            class="mat-accent" (click)="getProtectedTrustData()">
            <mat-spinner *ngIf="isspiner"></mat-spinner>{{btntext}}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
