import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-rent-leasing',
  templateUrl: './rent-leasing.component.html',
  styleUrls: ['./rent-leasing.component.scss']
})
export class RentLeasingComponent implements OnInit {
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  @Input() matterLeasingForm: FormGroup;
  constructor(private datepipe: DatePipe) { }

  ngOnInit() {
  }
  CommanDatePickerClick(type: string, event: MatDatepickerInputEvent<Date>,formControlVar) {
    this.matterLeasingForm.controls[formControlVar].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    navigator.clipboard.writeText(event)
  }
}
