import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject, Subscription } from 'rxjs';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-export-process',
    templateUrl: './export-process.component.html',
    styleUrls: ['./export-process.component.scss']
})
export class ExportProcessComponent implements OnInit, OnDestroy {
    MatterData: any = {};
    ExportProcessForm: FormGroup
    errorWarningData: any = {};
    statusData: any;
    @Input() action: any;
    @Input() classType: any;
    @Input() toolTipList: any;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription

    toolTipPostion = "above";
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        public MatDialog: MatDialog,
        public _matDialog: MatDialog,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        public tooltipService: TooltipService
    ) {
        this.initFormData()
    }
    initFormData() {
        this.ExportProcessForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTEREXPERTPROCESSGUID: [''],
            EXPERTAGREEMENTDATESIGNED: [''],
            EXPERTAGREEMENTDATESIGNEDText: [''],
            EXPERTCONFIDENTIALITYDATESIGNED: [''],
            EXPERTCONFIDENTIALITYDATESIGNEDText: [''],
            EXPERTDOCSEXCHANGEDDATE: [''],
            EXPERTDOCSEXCHANGEDDATEText: [''],
            EXPERTSUBMISSIONSDATE: [''],
            EXPERTSUBMISSIONSDATEText: [''],
            EXPERTHEARINGDATE: [''],
            EXPERTHEARINGDATEText: [''],
        });
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }


    ngOnInit(): void {
        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.ExportProcessForm.controls['MATTERGUID'].setValue('');
                this.ExportProcessForm.controls['MATTEREXPERTPROCESSGUID'].setValue('');

                this.ExportProcessForm.controls['EXPERTAGREEMENTDATESIGNED'].setValue('');
                this.ExportProcessForm.controls['EXPERTAGREEMENTDATESIGNEDText'].setValue('');

                this.ExportProcessForm.controls['EXPERTCONFIDENTIALITYDATESIGNED'].setValue('');
                this.ExportProcessForm.controls['EXPERTCONFIDENTIALITYDATESIGNEDText'].setValue('');

                this.ExportProcessForm.controls['EXPERTDOCSEXCHANGEDDATE'].setValue('');
                this.ExportProcessForm.controls['EXPERTDOCSEXCHANGEDDATEText'].setValue('');

                this.ExportProcessForm.controls['EXPERTSUBMISSIONSDATE'].setValue('');
                this.ExportProcessForm.controls['EXPERTSUBMISSIONSDATEtext'].setValue('');

                this.ExportProcessForm.controls['EXPERTHEARINGDATE'].setValue('');
                this.ExportProcessForm.controls['EXPERTHEARINGDATEText'].setValue('');
            }
        });
        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.ExportProcessForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Expert Process" && result.action == 'setData') {
                    this.SaveExportProcess();
                } else if (result.classType == "Expert Process" && result.action == 'deleteData') {
                    this.DeleteExportProcess();
                }
            }
        });
        if (this.action == 'edit' || this.action == 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-expert-process').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterCommercialData = response.DATA.RECORDS[0];
                    if (MatterCommercialData) {
                        this.ExportProcessForm.patchValue({
                            MATTERGUID: MatterCommercialData.MATTERGUID,
                            MATTEREXPERTPROCESSGUID: MatterCommercialData.MATTEREXPERTPROCESSGUID,
                            EXPERTAGREEMENTDATESIGNED: MatterCommercialData.EXPERTAGREEMENTDATESIGNED,
                            EXPERTCONFIDENTIALITYDATESIGNED: MatterCommercialData.EXPERTCONFIDENTIALITYDATESIGNED,
                            EXPERTDOCSEXCHANGEDDATE: MatterCommercialData.EXPERTDOCSEXCHANGEDDATE,
                            EXPERTSUBMISSIONSDATE: MatterCommercialData.EXPERTSUBMISSIONSDATE,
                            EXPERTHEARINGDATE: MatterCommercialData.EXPERTHEARINGDATE

                        });

                        if (MatterCommercialData?.EXPERTAGREEMENTDATESIGNED) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.EXPERTAGREEMENTDATESIGNED.split("/");
                            this.ExportProcessForm.patchValue({
                                EXPERTAGREEMENTDATESIGNEDText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                EXPERTAGREEMENTDATESIGNED: MatterCommercialData.EXPERTAGREEMENTDATESIGNED
                            });
                        }
                        if (MatterCommercialData?.EXPERTCONFIDENTIALITYDATESIGNED) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.EXPERTCONFIDENTIALITYDATESIGNED.split("/");
                            this.ExportProcessForm.patchValue({
                                EXPERTCONFIDENTIALITYDATESIGNEDText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                EXPERTCONFIDENTIALITYDATESIGNED: MatterCommercialData.EXPERTCONFIDENTIALITYDATESIGNED
                            });
                        }
                        if (MatterCommercialData?.EXPERTDOCSEXCHANGEDDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.EXPERTDOCSEXCHANGEDDATE.split("/");
                            this.ExportProcessForm.patchValue({
                                EXPERTDOCSEXCHANGEDDATEText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                EXPERTDOCSEXCHANGEDDATE: MatterCommercialData.EXPERTDOCSEXCHANGEDDATE
                            });
                        }
                        if (MatterCommercialData?.EXPERTSUBMISSIONSDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.EXPERTSUBMISSIONSDATE.split("/");
                            this.ExportProcessForm.patchValue({
                                EXPERTSUBMISSIONSDATEText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                EXPERTSUBMISSIONSDATE: MatterCommercialData.EXPERTSUBMISSIONSDATE
                            });
                        }
                        if (MatterCommercialData?.EXPERTHEARINGDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.EXPERTHEARINGDATE.split("/");
                            this.ExportProcessForm.patchValue({
                                EXPERTHEARINGDATEText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                EXPERTHEARINGDATE: MatterCommercialData.EXPERTHEARINGDATE
                            });
                        }

                    }
                }
            });
        }

    }
    AgreementSignedDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ExportProcessForm.controls['EXPERTAGREEMENTDATESIGNED'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    ConfidentialitySignedDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ExportProcessForm.controls['EXPERTCONFIDENTIALITYDATESIGNED'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    DocumentsExchangedDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ExportProcessForm.controls['EXPERTDOCSEXCHANGEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    SubmissionsClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ExportProcessForm.controls['EXPERTSUBMISSIONSDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    HearingDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ExportProcessForm.controls['EXPERTHEARINGDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    };



    SaveExportProcess() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.ExportProcessForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTEREXPERTPROCESSGUID;
            // delete formData.SEPARATIONDATETEXT;
        }
        formData.MATTERGUID = this.MatterData.MATTERGUID;
        delete formData.EXPERTAGREEMENTDATESIGNEDText;
        delete formData.EXPERTCONFIDENTIALITYDATESIGNEDText;
        delete formData.EXPERTDOCSEXCHANGEDDATEText;
        delete formData.EXPERTSUBMISSIONSDATEText;
        delete formData.EXPERTHEARINGDATEText;

        const formAction = (this.action == 'edit' && formData.MATTEREXPERTPROCESSGUID) ? 'Update' : 'Insert';
        const MatterCommercialData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(MatterCommercialData, 'matter-expert-process').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveExportProcessAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveExportProcessAfterVAlidation(details);
        }
    }
    SaveExportProcessAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'matter-expert-process').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    DeleteExportProcess() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-expert-process').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterCommercialData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTEREXPERTPROCESSGUID: MatterCommercialData.MATTEREXPERTPROCESSGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-expert-process').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

}
