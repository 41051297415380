

// import { AccountManagmentComponent } from './account-managment.component';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
// import { AuthGuard } from '../../../_guards';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExportToSLQComponent } from './export-to-slq/export-to-slq.component';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { LoggedonUserComponent } from './loggedon-user/loggedon-user.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PaymentComponent } from './payment/payment.component';
import { PipesModule } from '@_pipes/pipes.module';
import { ProductsComponent } from './products/products.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { RouterModule, Routes } from '@angular/router';
import { ScheduleModule, DayService, WeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';
import { UserActivityComponent } from './user-activity/user-activity.component';
import {MatTooltipModule} from '@angular/material/tooltip';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [

  // {
  //   path: 'account-management/basicinfo', component: AccountManagmentComponent, data: {
  //     title: 'Account & Management - Basicinfo'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-management/basicinfo', component: AccountManagmentComponent, data: {
  //     title: 'Account & Management - Basicinfo'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-management/products', component: AccountManagmentComponent, data: {
  //     title: 'Account & Management - Products'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-management/receipts', component: AccountManagmentComponent, data: {
  //     title: 'Account & Management - Receipts'
  //   }, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-management/payment', component: AccountManagmentComponent, data: {
  //     title: 'Account & Management - Payment'
  //   }, canActivate: [AuthGuard]
  // }

];
@NgModule({
  declarations: [
   // AccountManagmentComponent,
    BasicInfoComponent,
    ProductsComponent,
    ReceiptsComponent,
    PaymentComponent,
    UserActivityComponent,
    LoggedonUserComponent,
    ExportToSLQComponent
  ],
  entryComponents: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,

    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    SatDatepickerModule, SatNativeDateModule,

    MatCheckboxModule,
    DragDropModule,
    MatSortModule,
    MatExpansionModule,
    ScheduleModule,
    MatListModule,
    MatTooltipModule,
    PipesModule
  ],
  exports: [
   // AccountManagmentComponent,
    UserActivityComponent,
    LoggedonUserComponent,
    BasicInfoComponent,
    ProductsComponent,
    ReceiptsComponent,
    PaymentComponent,
    LoggedonUserComponent,
    ExportToSLQComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    DayService,
    WeekService,
    MonthService,
    AgendaService,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class AccountManagmentModule { }
