import { Component, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { Subscription } from "rxjs";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
@Component({
    selector: "app-loggedon-user",
    templateUrl: "./loggedon-user.component.html",
    styleUrls: ["./loggedon-user.component.scss"],
})
export class LoggedonUserComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    isLoadingResults: boolean = false;
    currentUser = JSON.parse(localStorage.getItem("currentUser"));
    ownerDataSource: any;
    loggedOnUsers: any[] = [];
    sub1: Subscription;
    sub2: Subscription;

    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private behaviorService: BehaviorService,
        public dialogRef: MatDialogRef<LoggedonUserComponent>,
        private http: HttpClient,
        private _matDialog: MatDialog,
        private toastr: ToastrService
    ) {
        localStorage.setItem("istrackid", "AccMangProductsComponent");
        this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
            if (
                localStorage.getItem("istrackid") ==
                    "AccMangProductsComponent" &&
                result["click"] &&
                result["data"]["user-activity"]
            ) {
                this.ngOnInit();
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.getUsers();
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
    }

    /**
     * This function is used to log out now.
     * @param user -user data.
     */
    LogOutNow(user: any) {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage = `This will log them out immediately. Are you sure you want to log ${user.USERNAME} out?`;
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.http
                    .post<any>(environment.APIEndpoint + "Login", {
                        request: "Logout",
                        LogOutNowFromLoggendOnUser: user.SESSIONTOKEN,
                    })
                    .subscribe(
                        (loginResponse) => {
                            if (
                                loginResponse.CODE == 200 &&
                                loginResponse.STATUS == "success"
                            ) {
                                this.toastr.success(
                                    `${user.USERNAME} was successfully logged out`
                                );
                                this.dialogRef.close(true);
                            }
                        },
                        (error) => {
                            this.toastr.error(error);
                        }
                    );
            }
        });
    }

    /**
     * This function is used to get the user data
     */
    async getUsers() {
        this.isLoadingResults = true;
        const SendParam = {
            Action: "GetData",
            Filters: {},
        };
        this.sub2 = this._mainAPiServiceService
            .getSetData(SendParam, "user-activity")
            .subscribe(
                (res) => {
                    this.behaviorService.loadingUserMenuResult(false);
                    this.isLoadingResults = false;
                    this.behaviorService.loadingAccountMNG("loggedOnUser");
                    if (res.CODE == 200 && res.STATUS == "success") {
                        if ($.isEmptyObject(res.DATA) == true) {
                            this.loggedOnUsers.push({});
                        } else {
                            for (
                                let index = 0;
                                index < res.DATA.CURRENTLYLOGGEDONUSERS.length;
                                index++
                            ) {
                                // let UtcToLocalTime = moment.utc(res.DATA.CURRENTLYLOGGEDONUSERS[index].LOGGEDINTIME,'HH:mm').local().format("hh:mm A");
                                // res.DATA.CURRENTLYLOGGEDONUSERS[index].LOGGEDINTIME = UtcToLocalTime;
                                const element =
                                    res.DATA.CURRENTLYLOGGEDONUSERS[index];
                                this.loggedOnUsers.push(element);
                            }
                            // this.behaviorService.DiaryData(res.DATA.APPOINTMENTS[0]);
                        }
                    }
                },
                (err) => {
                    this.behaviorService.loadingUserMenuResult(false);
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }
}
