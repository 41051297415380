import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from 'src/@fuse/services/config.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private _fuseConfigService: FuseConfigService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true }
      }
    };
  }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
