<ng-container *ngIf="IsChecked$ | async as checkbox">  
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_invoice_main select_metter_main_div " cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>
      {{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <!-- [formGroup]="addInvoiceForm" -->
            <form name="Deliverableform" id="Deliverableform" [formGroup]="Deliverableform" fxLayout="column"
            fxLayoutAlign="start" fxFlex="1 0 auto">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="18" class="pr-4"
                    [ngClass]="{'has-error':!this.Deliverableform.controls['DeliverableId'].value,'has-warning':!this.Deliverableform.controls['DeliverableId'].value}">
                    <mat-label>ID</mat-label>
                    <input matInput placeholder="ID" formControlName="DeliverableId">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="71" class="pr-4"
                [ngClass]="{'has-error':!this.Deliverableform.controls['DeliverableDescription'].value,'has-warning':!this.Deliverableform.controls['DeliverableDescription'].value}">
                <mat-label>Description </mat-label>
                <input matInput placeholder="Description" formControlName="DeliverableDescription">
            </mat-form-field>
            
            <mat-slide-toggle fxFlex="11" class="pr-4" [checked]="checkbox.isChecked"  (change)="getActiveData($event)"  class="pr-4"  style="margin-bottom: 15px;" class="pb-fieldspace checktoggle-box">
                Active
            </mat-slide-toggle>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field fxFlex="18" class="example pr-4" appearance="outline"  
                    [ngClass]="{'has-error':!this.Deliverableform.controls['UserResponsibleGuid'].value,'has-warning':!this.Deliverableform.controls['UserResponsibleGuid'].value}">
                        <mat-label>User</mat-label>
                        <mat-select formControlName="UserResponsibleGuid" (selectionChange)="matterChange('FeeEarner',$event.value)">
                            <mat-option value="all">All</mat-option>
                            <mat-option value="">None</mat-option>
                            <mat-option *ngFor="let list of userList" value="{{list.USERGUID}}">{{list.FULLNAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="68" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.ContactGuid,'has-warning':errorWarningData.Warning?.ContactGuid}">
                    <mat-label>Deliverable Contact</mat-label>
                    
                    <input matInput formControlName="ContactGuid" readonly>
                    <mat-icon matSuffix (click)='ContactMatter()' [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                        <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                    </mat-icon>
                </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="14" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PERCENTAGECOMPLETE,'has-warning':errorWarningData.Warning?.PERCENTAGECOMPLETE}">
                        <mat-label>Percentage Complete</mat-label>
                        <input matInput placeholder="Percentage Complete" formControlName="PERCENTAGECOMPLETE">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.STARTDATE,'has-warning':errorWarningData.Warning?.STARTDATE}">
                        <mat-label>Start Date</mat-label>
                        <input matInput  formControlName="STARTDATE" [matDatepicker]="picker3" placeholder="Choose a date" (dateInput)="STARTDATEchoosedDate('input', $event)" (dateChange)="STARTDATEchoosedDate('change', $event)">
                        <!-- formControlName="INVOICEDATETEXT" (dateChange)="InvoiceStartDate('change', $event)" -->
                        <!--  -->
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.DUEDATE,'has-warning':errorWarningData.Warning?.DUEDATE}">
                        <mat-label>Due Date</mat-label>
                        <input matInput formControlName="DUEDATE" [matDatepicker]="picker2" placeholder="Choose a date" (dateInput)="DUEDATEchoosedDate('input', $event)" (dateChange)="DUEDATEchoosedDate('change', $event)">
                        <!--  (dateChange)="InvoiceStartDate('change', $event)" -->
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.COMPELATEDATE,'has-warning':errorWarningData.Warning?.COMPELATEDATE}">
                        <mat-label>Completed Date</mat-label>
                        <input matInput  formControlName="COMPELATEDATE" [matDatepicker]="picker1"  placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 ></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4" 
                    [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEFROMEXGST,'has-warning':errorWarningData.Warning?.ESTIMATEFROMEXGST}">
                    <mat-label>Estimate From Ex GST</mat-label>
                    <input [value]="Deliverableform.get('ESTIMATEFROMEXGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" type="text" matInput placeholder="Estimate From Ex GST" formControlName="ESTIMATEFROMEXGST" (blur)="calcPE('min')" [(ngModel)]="PRICEVAL" [leadZero]="true">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4" 
                    [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATEFROMINCGST,'has-warning':errorWarningData.Warning?.ESTIMATEFROMINCGST}">
                    <mat-label>Estimate From Inc GST</mat-label>
                    <input [value]="Deliverableform.get('ESTIMATEFROMINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" type="text" matInput placeholder="Estimate From Inc GST" formControlName="ESTIMATEFROMINCGST" (blur)="calcPI('min')" [(ngModel)]="PRICEVALGST" [leadZero]="true">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4" 
                    [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATETOTOTALEXGST,'has-warning':errorWarningData.Warning?.ESTIMATETOTOTALEXGST}">
                    <mat-label>Estimate To Ex GST</mat-label>
                    <input [value]="Deliverableform.get('ESTIMATETOTOTALEXGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" type="text" matInput placeholder="Estimate To Ex GST" formControlName="ESTIMATETOTOTALEXGST" (blur)="calcPE('max')" [(ngModel)]="MAXPRICEVAL" [leadZero]="true">                      
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="14.28" class="pr-4" 
                    [ngClass]="{'has-error':errorWarningData.Error?.ESTIMATETOTOTALINCGST,'has-warning':errorWarningData.Warning?.ESTIMATETOTOTALINCGST}">
                    <mat-label>Estimate To Inc GST</mat-label>
                    <input [value]="Deliverableform.get('ESTIMATETOTOTALINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" type="text" matInput placeholder="Estimate To Inc GST" formControlName="ESTIMATETOTOTALINCGST" (blur)="calcPI('max')" [(ngModel)]="MAXPRICEVALGST" [leadZero]="true">
                </mat-form-field>
                </div>
            
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field fxFlex="100" class="pr-4" appearance="outline" 
                        [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALNOTE,'has-warning':errorWarningData.Warning?.ADDITIONALNOTE}">
                        <mat-label>Additional Notes</mat-label>
                        <textarea type="text" matInput formControlName="ADDITIONALNOTE"></textarea>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveDeliverable(checkbox.isChecked)" [disabled]="isspiner" *ngIf="action === 'New'"><mat-spinner *ngIf="isspiner"></mat-spinner>Save</button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveDeliverable(checkbox.isChecked)" [disabled]="isspiner" *ngIf="action === 'edit'"><mat-spinner *ngIf="isspiner"></mat-spinner>Update</button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveDeliverable(checkbox.isChecked)" [disabled]="isspiner" *ngIf="action !== 'New' && action !== 'edit'"><mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
</ng-container>
<!-- </form> -->