import { Component, Inject, Injectable, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from 'src/@fuse/animations';
import { ChartAcDailogComponent } from '../chart-account/chart-ac-dailog/chart-ac-dailog.component';
import { SelectBankingDialogComponent } from './select-banking-dialog/select-banking-dialog.component';
import { Router } from '@angular/router';
import { TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { MatterDialogComponent } from '../time-entries/matter-dialog/matter-dialog.component';
import { Subscription } from 'rxjs';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';

@Injectable()

@Component({
  selector: 'app-banking-dialog',
  templateUrl: './banking-dialog.component.html',
  styleUrls: ['./banking-dialog.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,

})
export class BankingDialogComponent implements OnInit, OnDestroy {
  checked: boolean = false;
  @ViewChild('treegrid', { static: false }) public treegrid: TreeGridComponent;
  isLoadingResults: boolean = false;
  ACCOUNTGUIDsELECTED: any;
  arrayForIndex: any = [];
  accountIdArray: any = [];

  isDisabledselect: any;
  accountTypeData: any;
  title: string;
  ChartHandlingData: { ClickType: any; UseTrust: string; PopUp: string; Lable: string; };
  postDataForBanking: any = {};
  isAccountMulti: any;
  dataTreeTable: any = [];
  isAccountEditable: boolean = false;
  hasSearchVal : boolean = false;
  searchDataValue : any ="";

  public ChartData = { "AccountClass": 'All', "Search": '' }
  filterData: any = { 'SearchString': '' , 'AccountClass': '' , 'UseTrust': '' };
  showActive = false;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;

  constructor(
    public dialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    private globalFunctionService:GlobalFunctionsService,
    public dialogRef: MatDialogRef<BankingDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data: any, private router: Router,) {

    localStorage.setItem('istrackid', 'BankSelectDialog');
    this.sub1 = this.behaviorService.isAccountMulti$.subscribe((result) => {
      if (result) {
        this.isAccountMulti = result;
      }
    });
    this.sub2 = this.behaviorService.dialogClose$.subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.sub3 = this.behaviorService.TrustDuplicateModuleHandling$.subscribe(result => {
      if (result != null) {
        this.accountTypeData = result;
      }
    });
    this.title = "Select Account";
    this.sub4 = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'BankSelectDialog' && result['click'] && result['data']['GetAccount']) {
        this.postDataForBanking = { AccountClass: _data.AccountType, UseTrust: _data.UseTrust == "Yes" ? true : false };
        this.postDataForBanking.ShowInactive = false;
        this.loadData(this.postDataForBanking);
      }

    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    setTimeout(() => {
      this.postDataForBanking = { AccountClass: this._data.AccountType, UseTrust: this._data.UseTrust == "Yes" ? true : false };
      this.postDataForBanking.ShowInactive = false;
      this.loadData(this.postDataForBanking);
    }, 0);
  }

  ngOnDestroy(): void {
      this.sub1?.unsubscribe()
      this.sub2?.unsubscribe()
      this.sub3?.unsubscribe()
      this.sub4?.unsubscribe()
  }

  /**
   * This function is used to load the data value
   * @param type type of the load data value
   */
  async loadData(type: any) {
    this.isLoadingResults = true;
    await new Promise<void>(async next => {
      return await this._mainAPiServiceService.getSetData(type, 'GetAccount').subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          if (response.DATA.ACCOUNTS[0]) {
            this.behaviorService.setChartAccountDataEdit(response.DATA.ACCOUNTS[0]);
            this.dataTreeTable = response.DATA.ACCOUNTS;
            next();
          }
        } else if (response.MESSAGE == 'Not logged in') {
        }
        this.isLoadingResults = false;
      }, err => {
        this.isLoadingResults = false;
      });
    });
  }
  /**
   * This function is used to show the data value
   * @param element -element data value
   * @param level -level data value 
   * @param parent -parent data value
   */
  showData(element, level, parent) {
    element.forEach(x => {
      this.arrayForIndex.push({});
      x.level = level
      x.parent = parent
      x.MainList = x;
      x.index = this.arrayForIndex.length;
      if (x.SUBACCOUNTS) {
        this.showData(x.SUBACCOUNTS, x.level + 1, x.ACCOUNTNAME);
      }
    });
  }

  /**
   * This function is used to nodeChecked data
   * @param val -val data 
   * @param event -evebt data value
   */
  nodeChecked(val, event) {
    if (event.checked == true) {
      this.accountIdArray.push({ 'ACCOUNTGUID': val.ACCOUNTGUID });
    } else {
      const isLargeNumber = (element) => element.ACCOUNTGUID == val.ACCOUNTGUID;
      const indexArray = this.accountIdArray.findIndex(isLargeNumber)
      this.accountIdArray.splice(indexArray, 1);
    }
    this.behaviorService.multiAccountStore(this.accountIdArray);
  }

  /**
   * This function is used to Click on the row data
   * @param node 
   */
  RowClick(node) {
    if(this._data.RoterPath != 'bank-feed' && this._data.RoterPath != 'connect-bank-feed') {
      this.behaviorService.ChartAccountData(node);
      let name = node.ACCOUNTCLASS + ' - ' + node.ACCOUNTNUMBER + '        ' + node.ACCOUNTNAME;
      localStorage.setItem('ChartAccountData', JSON.stringify({ 'ACCOUNTTYPENAME': node.ACCOUNTTYPENAME, "name": name, "class": node.class, "ACCOUNTGUID": node.  ACCOUNTGUID, "ACCOUNTTYPE": node.ACCOUNTTYPE, "index": node.index, "parent": node.parent, "level": node.level , "NEXTRECONCILEDDATE" : node.NEXTRECONCILEDDATE
      }));
    }
    node.AccountType = this._data.AccountType;
    this.ACCOUNTGUIDsELECTED = node;
    this.isDisabledselect = node.ACCOUNTTYPENAME;
    this.isAccountEditable = node.EDITABLE;
  }

  /**
   * This function is used to select the row data
   */
  rowSelected() {
    const selectedrecords = this.treegrid.getSelectedRecords();  // Get the selected records.
    this.RowClick(selectedrecords[0]);
  }

  /**
   * This function is used to bound the data value.
   */
  dataBound() {
    var instance = (document.getElementsByClassName('e-treegrid') as any)[0].ej2_instances[0]
    instance.grid.enableStickyHeader = true;
  }

/**
 * This function is used to open the Account Dialog
 * @param val 
 * @param isTrust 
 * @param isDefult 
 */
  AccountDialogOpen(val: any, isTrust: any, isDefult: any) {
    if (val == 'edit') {
      this.openDilogForAccount(val, '');
    } else {
      if (isDefult == 'Controlled Money Account') {
        const dialogRef = this.dialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            localStorage.setItem('set_active_matters', JSON.stringify(result));
            this.openDilogForAccount(val, isDefult);
          }
        });
      } else {
        this.openDilogForAccount(val, isDefult);
      }
    }
  }

  /**
   * This function is used to openDialogFor Account
   * @param val 
   * @param isDefult 
   */
  openDilogForAccount(val: any, isDefult: any) {
    let isTrust;
    if (this.accountTypeData.ClickType == 'WithoutTrust') {
      isTrust = false;
      this.ChartHandlingData = { ClickType: 'WithoutTrust', UseTrust: 'No', PopUp: '', Lable: "CHART OF ACCOUNTS", }
    } else {
      isTrust = true;
      this.ChartHandlingData = { ClickType: 'WithTrust', UseTrust: 'Yes', PopUp: '', Lable: "TRUST CHART OF ACCOUNTS", }
    }
    localStorage.setItem('ChartURL', JSON.stringify(this.ChartHandlingData));
    this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);
    const dialogRef = this.dialog.open(ChartAcDailogComponent, { disableClose: true, panelClass: 'ChartAc-dialog', data: { action: val, 'UseTrust': this.ChartHandlingData.UseTrust, isDefultAccount: isDefult, matterGuid: "" } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postDataForBanking = { AccountClass: this._data.AccountType, UseTrust: isTrust };
        this.loadData(this.postDataForBanking);
      }
    });
  }

  /**
   * This function is used to select dialog open
   */
  SelectDialogOpen() {
    const dialogRef = this.dialog.open(SelectBankingDialogComponent, {});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * This function is used to select the click
   * @param val -value data
   */
  SelectClick(val) {
    if (this._data.RoterPath == 'Reconclie Practice') {
      setTimeout(() => {
        localStorage.setItem("BankingDialogDefaultData",JSON.stringify(val));
        this.behaviorService.ChartAccountData(val);
        val.name = val.ACCOUNTCLASS + ' - ' + val.ACCOUNTNUMBER + '        ' + val.ACCOUNTNAME;
        this.behaviorService.RecouncileConstName(val.name);
        localStorage.setItem('ChartAccountData', JSON.stringify({ "name": val.name, "class": val.class, "ACCOUNTGUID": val.ACCOUNTGUID, "LASTSTATEMENTDATE": val.LASTSTATEMENTDATE, "ACCOUNTTYPE": val.ACCOUNTTYPE, "index": val.index, "parent": val.parent, "level": val.level , "NEXTRECONCILEDDATE" : val.NEXTRECONCILEDDATE }));
        this.behaviorService.AccountTypeData(this.accountTypeData);
        this.behaviorService.TrustDuplicateModuleHandling(this.accountTypeData);
        // this.router.navigate(['/account-reconciliation/reconciliation-item']);
        this.globalFunctionService.goToIdTab('/account-reconciliation/reconciliation-item', `reconciliationitem_${val.ACCOUNTGUID}`)
      }, 1000);

    } if (this._data.RoterPath == 'Bank Feeds') {
      setTimeout(() => {
        localStorage.setItem("BankingDialogDefaultData",JSON.stringify(val));
        this.behaviorService.ChartAccountData(val);
        val.name = val.ACCOUNTCLASS + ' - ' + val.ACCOUNTNUMBER + '        ' + val.ACCOUNTNAME;
        this.behaviorService.RecouncileConstName(val.name);
        localStorage.setItem('ChartAccountData', JSON.stringify({ "name": val.name, "class": val.class, "ACCOUNTGUID": val.ACCOUNTGUID, "LASTSTATEMENTDATE": val.LASTSTATEMENTDATE, "ACCOUNTTYPE": val.ACCOUNTTYPE, "index": val.index, "parent": val.parent, "level": val.level , "NEXTRECONCILEDDATE" : val.NEXTRECONCILEDDATE }));
        this.behaviorService.AccountTypeData(this.accountTypeData);
        this.behaviorService.TrustDuplicateModuleHandling(this.accountTypeData);
        // this.router.navigate(['/account-reconciliation/reconciliation-item']);
        this.globalFunctionService.goToIdTab('/bank-feed', `bankfeed_${val.ACCOUNTGUID}`)
      }, 1000);

    } else if (this._data.RoterPath == "") {
      const dialogRef = this.dialog.open(SelectBankingDialogComponent, { disableClose: true, panelClass: 'ChartAc-dialog', data: null });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    }else if(this._data.RoterPath=='protected-trust'){
      val.name = val.ACCOUNTCLASS + '-' + val.ACCOUNTNUMBER +''+ val.ACCOUNTNAME;
      this.dialogRef.close({ data: val })
    }
  }
  refreshBank() {

  }

  /**
   * This function is used to onsearch data value
   * @param searchFilter -searchFilter
   */
  onSearch(searchFilter: any) {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
      this.filterData.UseTrust = this._data.UseTrust == "Yes" ? true : false;
      this.filterData.SearchString = this.searchDataValue;
      this.filterData.AccountClass =  this._data.AccountType;
      this.hasSearchVal = true;
      this.loadData(this.filterData);
    }else if(searchFilter == 'removeSearchString'){
      this.searchDataValue = "";
      this.filterData.SearchString = this.searchDataValue;
      this.loadData(this.filterData)
      this.hasSearchVal = false;
    }

  }

  /**
   * This function is used to onchecked data value 
   * @param showActive -show active data
   */
  onChecked(showActive:any){
    this.filterData.UseTrust = this._data.UseTrust == "Yes" ? true : false;
    this.filterData.AccountClass =  this._data.AccountType;
    this.filterData.ShowInactive = showActive;
    this.loadData(this.filterData)
  }
}
