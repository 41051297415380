<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>SILQ Sign-up
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="title-text">
            <h5>Step {{step}} of 2</h5>
            <h1 *ngIf="step == 1">Tell us a little bit about your firm so you can bill clients quickly and easily</h1>
            <h1 *ngIf="step == 2">Get your address added to invoices and trust receipts</h1>
            <!-- <h1 *ngIf="step == 3">DEMO</h1> -->

        </div>
        <div class="one select_matter_main_div">
            <div class="center p-24 list_tbl_main more_tbl_clmn">
                <div class="matter-serach-div sticky_search_div">
                    <form autocomplete="off" [formGroup]="signupForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center"   *ngIf="isNext=='false'">
                            <label class="label-text">What is your business name?</label>
                            <mat-form-field appearance="outline" fxFlex="97.8"  [ngClass]="{'has-error':errorWarningData.Error?.BARRISTERSNAME,'has-warning':errorWarningData.Warning?.BARRISTERSNAME}">
                                <input type="text" placeholder="Business Name" name="BARRISTERSNAME" formControlName="BARRISTERSNAME" matInput>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='false'">
                        <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="50" *ngIf="isNext=='false'">
                            <label class="label-text">What is your GST type?</label>
                            <mat-form-field appearance="outline"  class="pr-4"
                            [ngClass]="{'has-error':errorWarningData.Error?.GSTTYPE,'has-warning':errorWarningData.Warning?.GSTTYPE}">
                            <mat-select #value formControlName="GSTTYPE" placeholder="GST Type">
                            <mat-option value="GST Exclusive">GST Exclusive</mat-option>
                            <mat-option value="GST Inclusive">GST Inclusive</mat-option>
                            <mat-option value="GST Free">GST Free</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="50" *ngIf="isNext=='false'">
                            <label class="label-text">What is your ABN?</label>
                            <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ABN,'has-warning':errorWarningData.Warning?.ABN}">
                                <input type="text" placeholder="ABN" name="ABN" formControlName="ABN" matInput>
                            </mat-form-field>
                        </div>

                        </div>

                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='false'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="49.7">
                                <label class="label-text">What is your default hourly rate?</label>
                                <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.HOURLYBASERATE,'has-warning':errorWarningData.Warning?.HOURLYBASERATE}">
                                    <input type="text" placeholder="Default Hourly Rate" name="HOURLYBASERATE" formControlName="HOURLYBASERATE" matInput>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start"  fxFlex="49.7" *ngIf="isNext=='false'">
                                <label class="label-text">What type of law do you practice?</label>
                                <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.DEFAULTMATTERCLASS,'has-warning':errorWarningData.Warning?.DEFAULTMATTERCLASS}">
                                <mat-select name="DEFAULTMATTERCLASS" formControlName="DEFAULTMATTERCLASS"
                                 placeholder="Default Matter Class">
                                  <mat-option *ngFor="let val of getDropDownValue.DEFAULTMATTERCLASS" value="{{val.DESCRIPTION}}">
                                   {{val.DESCRIPTION}}
                                </mat-option>
                                 </mat-select>
                                </mat-form-field>
                            </div>
    
                            </div>

                            <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='false'">
                                <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="55" *ngIf="currentUSer?.PRODUCTTYPE != 'Barrister'">
                                    <label class="label-text">Do you use InfoTrack? If you do what are your credentials?</label>
                                    <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.SEARCHUSERNAME,'has-warning':errorWarningData.Warning?.SEARCHUSERNAME}">
                                        <input autocomplete="off" type="text" placeholder="username" name="SEARCHUSERNAME" formControlName="SEARCHUSERNAME" matInput>
                                    </mat-form-field>
                                </div>
                                <div style="margin-top: 18px;" fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="45" *ngIf="currentUSer?.PRODUCTTYPE != 'Barrister'">
                                    <!-- <label class="label-text">Time zone are you in?</label> -->
                                    <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.SEARCHPASSWORD,'has-warning':errorWarningData.Warning?.SEARCHPASSWORD}">
                                        <input autocomplete="off" type="password" placeholder="password" name="SEARCHPASSWORD" formControlName="SEARCHPASSWORD" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                       

                        
                        

                        <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='false'">
                            <label class="label-text">What is your Due Date Offset? (on an invoice - which terms do you supply?)</label>
                            <mat-form-field appearance="outline" fxFlex="99.5">
                                <mat-select formControlName="GSTTYPE" placeholder="Please choose one...">
                                    <mat-option value="Active">Active</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->

                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='true'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="49.7">
                                <label class="label-text">Street</label>
                            <mat-form-field appearance="outline"  [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS1,'has-warning':errorWarningData.Warning?.ADDRESS1}">
                                <input type="text" placeholder="Street" name="ADDRESS1" formControlName="ADDRESS1" matInput>
                            </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="49.7" >
                                <label class="label-text">Street 2</label>
                            <mat-form-field appearance="outline"  [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS2,'has-warning':errorWarningData.Warning?.ADDRESS2}">
                                <input type="text" placeholder="Street 2" name="ADDRESS2" formControlName="ADDRESS2" matInput>
                            </mat-form-field>
                            </div>
                           
                        </div>
                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='true'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="33.3" >
                                <label class="label-text">Suburb</label>
                                <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.SUBURB,'has-warning':errorWarningData.Warning?.SUBURB}">
                                    <input type="text" placeholder="Suburb" name="SUBURB" formControlName="SUBURB" matInput>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="33.3" >
                                <label class="label-text">State</label>
                                <mat-form-field appearance="outline"  class="px-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.STATE,'has-warning':errorWarningData.Warning?.STATE}">
                                <mat-label>State</mat-label>
                                <input type="text" placeholder="State"  name="STATE" formControlName="STATE" matInput >
        <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.STATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->

                                 </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="33.3">
                                <label class="label-text">Post Code</label>
                                <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.POSTCODE,'has-warning':errorWarningData.Warning?.POSTCODE}">
                                    <input type="text" placeholder="Post Code" name="POSTCODE" formControlName="POSTCODE" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='true'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">Phone</label>
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.PHONE1,'has-warning':errorWarningData.Warning?.PHONE1}">
                                    <input type="text" placeholder="Phone" name="PHONE1" formControlName="PHONE1" matInput>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">Email</label>
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.EMAIL,'has-warning':errorWarningData.Warning?.EMAIL}">
                                    <input type="text" placeholder="Email" name="EMAIL" formControlName="EMAIL" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='true'">
                            <!-- <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">ABN</label>
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ABN,'has-warning':errorWarningData.Warning?.ABN}">
                                    <input type="text" placeholder="ABN" name="ABN" formControlName="ABN" matInput>
                                </mat-form-field>
                            </div> -->
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="50">
                                <label class="label-text">Website</label>
                                <mat-form-field class="example pr-4"  class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.WWW,'has-warning':errorWarningData.Warning?.WWW}">
                                    <input type="text" placeholder="Website" name="WWW" formControlName="WWW" matInput>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="50">
                                <label class="label-text">What Time zone are you in?</label>
                                <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TIMEZONE,'has-warning':errorWarningData.Warning?.TIMEZONE}">
                                    <mat-select name="TIMEZONE" formControlName="TIMEZONE" placeholder="Unit Size">
                                        <mat-option *ngFor="let val of timeZoneData" value="{{val.Name}}">{{val.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='Third'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">What is your default hourly rate?</label>
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.HOURLYBASERATE,'has-warning':errorWarningData.Warning?.HOURLYBASERATE}">
                                    <input type="text" placeholder="Default Hourly Rate" name="HOURLYBASERATE" formControlName="HOURLYBASERATE" matInput>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">Time zone are you in?</label>
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.TIMEZONE,'has-warning':errorWarningData.Warning?.TIMEZONE}">
                                    <input type="text" placeholder="Time Zone" name="TIMEZONE" formControlName="TIMEZONE" matInput>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='Third'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">Do you use InfoTrack? If you do what are your credentials?</label>
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.SEARCHUSERNAME,'has-warning':errorWarningData.Warning?.SEARCHUSERNAME}">
                                    <input type="text" placeholder="username" name="SEARCHUSERNAME" formControlName="SEARCHUSERNAME" matInput>
                                </mat-form-field>
                            </div>
                            <div style="margin-top: 18px;" fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.SEARCHPASSWORD,'has-warning':errorWarningData.Warning?.SEARCHPASSWORD}">
                                    <input type="password" placeholder="password" name="SEARCHPASSWORD" formControlName="SEARCHPASSWORD" matInput>
                                </mat-form-field>
                            </div>
                        </div> -->
                        <!-- <div fxLayout="row wrap" class="field-layout" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isNext=='Third'">
                            <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <label class="label-text">Do you use InfoTrack? If you do what are your credentials?</label>
                                <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline">
                                    <input type="text" placeholder="username" name="username" formControlName="ABN" matInput>
                                </mat-form-field>
                                </div>
                                <div fxLayout="column" class="mat_field_btm_padding" fxLayoutAlign="start" fxFlex="1 0 auto">
                                <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline">
                                    <input type="text" placeholder="password" name="password" formControlName="ABN" matInput>
                                </mat-form-field>
                                </div>
                            </div>
                        </div> -->


                    </form>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
             <button mat-raised-button color="primary" *ngIf="step==2" (click)="gotoPrevious()">Back</button>
            <!-- <button mat-raised-button class="mat-accent" color="accent" *ngIf="step==2" (click)="gotoThirdStep()">Next</button> --> 
            <!-- section 1 -->
            <!-- //<button mat-raised-button color="primary" *ngIf="step==2" (click)="gotoNext()">Back</button> -->
            <button mat-raised-button class="mat-accent" color="accent" *ngIf="isNext=='false'" (click)="gotoNext()">Next</button>
            <!-- <button mat-raised-button class="mat-accent" color="accent" *ngIf="step==3" (click)="goToFinish()" [disabled]="isspiner">
                <mat-spinner *ngIf="isspiner"></mat-spinner>Finish</button> -->
                <button mat-raised-button color="accent" class="mat-accent" *ngIf="step==2" (click)="gotoFinish1()" [disabled]="isspiner">
                    <mat-spinner *ngIf="isspiner"></mat-spinner>Finish
                </button>
            <!-- <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button> -->
        </div>
    </mat-dialog-actions>
</div>