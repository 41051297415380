
export const MATTER_DASHBOARD_RIGHT_CLICK_DATA: any = [
    {
        "ID": "Edit Matter",
        "NAME": "Edit Matter",
        "URL": "",
        "ICON": "icon_matter_edit_d.ico",
        "POSITION": "",
        "TABID": 1
    },
    {
        "ID": "Matter Details",
        "NAME": "Matter Details",
        "URL": "",
        "ICON": "icon_authorities_d.ico",
        "POSITION": "",
        "TABID": 2
    },
    {
        "ID": "Generate Documents",
        "NAME": "Generate Documents",
        "URL": "",
        "ICON": "icon_letters_d.ico",
        "POSITION": "",
        "TABID": 3
    },
    {
        "ID": "New Time Entry",
        "NAME": "New Time Entry",
        "URL": "",
        "ICON": "icon_time_cost_d.ico",
        "POSITION": "",
        "TABID": 4
    },
    {
        "ID": "New Timer",
        "NAME": "New Timer",
        "URL": "",
        "ICON": "icon_time_cost_d.ico",
        "POSITION": "",
        "TABID": 5
    },
    {
        "ID": "New File Note",
        "NAME": "New File Note",
        "URL": "",
        "ICON": "icon_filesnotes_new_d.ico",
        "POSITION": "",
        "TABID": 6
    },
    {
        "ID": "Instant Invoice",
        "NAME": "Instant Invoice",
        "URL": "",
        "ICON": "icon_invoice_new_d.ico",
        "POSITION": "",
        "TABID": 7
    },
    {
        "ID": "Preview Invoice",
        "NAME": "Preview Invoice",
        "URL": "",
        "ICON": "icon_invoice_draft_d.ico",
        "POSITION": "",
        "TABID": 8
    },
    {
        "ID": "Invoice All WIP",
        "NAME": "Invoice All WIP",
        "URL": "",
        "ICON": "icon_invoice_new_d.ico",
        "POSITION": '',
        "TABID": 9
    },
    {
        "ID": "New Receipt",
        "NAME": "New Receipt",
        "URL": "",
        "ICON": "icon_receipt_new_d.ico",
        "POSITION": '',
        "TABID": 10
    },
    {
        "ID": "New Matter Expense",
        "NAME": "New Matter Expense",
        "URL": "",
        "ICON": "icon_expenses_new_d.ico",
        "POSITION": '',
        "TABID": 11
    },
    // {
    //     "ID": "New File Note",
    //     "NAME": "New File Note",
    //     "URL": "",
    //     "ICON": "",
    //     "POSITION":'',
    //     "TABID": 12
    // }
]


/*-------------------------------------------------------------------------------------------------HTML---------------------------------------------------------------*/
//     <div #trigger="matMenuTrigger" style = "position: fixed; visibility: hidden"
//     [matMenuTriggerFor]="contextMenu"
//     [matMenuTriggerData]="{item: row}"
//     [style.left.px]="menuTopLeftPosition.x"
//     [style.top.px]="menuTopLeftPosition.y">
// </div>
// <mat-menu #contextMenu="matMenu"  >
// <ng-template matMenuContent let-item="item">

//     <button mat-menu-item *ngFor="let data of RightClicklistMenu" (click)="RightClickAddData(data)">
//         {{data.NAME}}
//     </button>
// </ng-template>
// </mat-menu>
/*-------------------------------------------------------------------------------------------------HTML---------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------TS---------------------------------------------------------------*/
// this.behaviorService.RightClick$.subscribe(res=>{
//     if(res){
//       this.RightClickData = res
//     }
//   })
// @ViewChild('trigger') trigger: MatMenuTrigger;
// onRightClick(event: MouseEvent,item:any) {
//     if(event){
//       event.preventDefault(); // prevent the default context menu from showing
//       this.CurrentRightClickMatter = item
//       this.menuTopLeftPosition.x = event.clientX;
//       this.menuTopLeftPosition.y = event.clientY;
//       this.trigger.menuData = { 'item': item };
//     if(this.CurrentRightClickMatter?.MATTERGUID == this.RightClickData?.MATTERGUID){
//       this.trigger.openMenu();
//     }
//     }

//   }
/*-------------------------------------------------------------------------------------------------TS---------------------------------------------------------------*/
