<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div correspond_dailog_main">
    <h2 cdkDragHandle mat-dialog-title>Correspond Details
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="correspondForm" id="correspondForm" [formGroup]="correspondForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" fxFlex="33" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.TYPE,'has-Warning':errorWarningData.Warning?.TYPE}">
                        <mat-label>Contact Type</mat-label>
                        <mat-select #value (selectionChange)="changeType($event.value)" name="selected"
                            formControlName="TYPE">
                            <mat-option *ngFor="let val of CorrspondClassData" [value]="val.LOOKUPFULLVALUE">
                                {{val.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PERSONGUID,'has-Warning':errorWarningData.Warning?.PERSONGUID}">
                        <mat-label>Contact Name</mat-label>
                        <input matInput readonly formControlName="PERSONGUIDTEXT">
                        <mat-icon matSuffix (click)="selectContact();">
                            <img src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip="Twitter"
                                matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.SOLICITORGUID,'has-Warning':errorWarningData.Warning?.SOLICITORGUID}">
                        <mat-label>Solicitor Name</mat-label>
                        <input matInput readonly formControlName="SOLICITORGUIDTEXT">
                        <mat-icon matSuffix (click)="selectSolicitor();">
                            <img src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip="Twitter"
                                matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="99" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.REFERENCE,'has-Warning':errorWarningData.Warning?.REFERENCE}">
                        <mat-label>Reference</mat-label>
                        <input matInput formControlName="REFERENCE">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isBanfi=='Billing Recipient'">
                    <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.SHAREOFESTATE,'has-Warning':errorWarningData.Warning?.SHAREOFESTATE}">
                        <mat-label>Billing Percentage</mat-label>
                        <input matInput formControlName="SHAREOFESTATE" type="number">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isBanfi=='Beneficiary'">
                    <mat-form-field class="example" fxFlex="50" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.RELATIONSHIP,'has-Warning':errorWarningData.Warning?.RELATIONSHIP}">
                        <mat-label>Relationship</mat-label>
                        <input matInput formControlName="RELATIONSHIP">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.SHAREOFESTATE,'has-Warning':errorWarningData.Warning?.SHAREOFESTATE}">
                        <mat-label>Percentage </mat-label>
                        <input matInput formControlName="SHAREOFESTATE" type="number">
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" *ngIf="isEdit" [disabled]="isspiner"
            (click)="updateCorrespomndDetail();">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" *ngIf="!isEdit" [disabled]="isspiner"
            (click)="saveCorrespomndDetail();">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>
