import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-csv-popup",
    templateUrl: "./csv-popup.component.html",
    styleUrls: ["./csv-popup.component.scss"],
})
export class CSVPopupComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public _data: any) {}

       /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {}
}
