<div class=" one identi-contact" [formGroup]="contactForm" fxLayout="column" >
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-checkbox fxFlex="16" class="pr-4" formControlName="SOPHISTICATEDCLIENT">Sophisticated Client</mat-checkbox> -->
        <mat-slide-toggle class="pb-fieldspace"   formControlName="SOPHISTICATEDCLIENT" >Sophisticated Client</mat-slide-toggle>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field *ngIf="ProductType!='Solicitor'" appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRACTICINGCERTIFICATENO,'has-warning':errorWarningData.Warning?.PRACTICINGCERTIFICATENO}">
            <mat-label>Practicing Certificate No</mat-label>
            <input name="PRACTICINGCERTIFICATENO" [formControlName]="'PRACTICINGCERTIFICATENO'" matInput>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
        <!-- <mat-form-field  appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRACTICINGCERTIFICATENO,'has-warning':errorWarningData.Warning?.PRACTICINGCERTIFICATENO}">
            <mat-label>Practicing Certificate No.</mat-label>
            <input name="PRACTICINGCERTIFICATENO" [formControlName]="'PRACTICINGCERTIFICATENO'" matInput  matTooltip="{{allToolTip.PRACTICINGCERTIFICATENO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRACTICINGCERTIFICATENO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRACTICINGCERTIFICATENO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field> -->


        <mat-form-field appearance="outline" fxFlex="{{ProductType!='Solicitor'?33.3:100}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TFN,'has-warning':errorWarningData.Warning?.TFN}">
            <mat-label>Tax File No.</mat-label>
            <input name="TFN" [formControlName]="'TFN'" matInput  matTooltip="{{allToolTip.TFN?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TFN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TFN?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ABN,'has-warning':errorWarningData.Warning?.ABN}">
            <mat-label>Australian Business No.</mat-label>
            <input name="ABN" [formControlName]="'ABN'" matInput  matTooltip="{{allToolTip.ABN?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ABN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ABN?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACN,'has-warning':errorWarningData.Warning?.ACN}">
            <mat-label>Australian Company No.</mat-label>
            <input name="ACN" [formControlName]="'ACN'" matInput matTooltip="{{allToolTip.ACN?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACN?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACN,'has-warning':errorWarningData.Warning?.ACN}">
            <mat-label>Australian Company No.</mat-label>
            <input name="ACN" [formControlName]="'ACN'" matInput>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ABN,'has-warning':errorWarningData.Warning?.ABN}">
            <mat-label>Australian Business No.</mat-label>
            <input name="ABN" [formControlName]="'ABN'" matInput>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TFN,'has-warning':errorWarningData.Warning?.TFN}">
            <mat-label>Tax File No.</mat-label>
            <input name="TFN" [formControlName]="'TFN'" matInput>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <mat-form-field  appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NATIONALNAMESINDEX,'has-warning':errorWarningData.Warning?.NATIONALNAMESINDEX}">
            <mat-label>National Names Index</mat-label>
            <input name="NATIONALNAMESINDEX" [formControlName]="'NATIONALNAMESINDEX'" matInput  matTooltip="{{allToolTip.NATIONALNAMESINDEX?.HOVER}}">
            <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NATIONALNAMESINDEX?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NATIONALNAMESINDEX?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
        </mat-form-field>

        <mat-form-field  appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRACTICINGCERTIFICATENO,'has-warning':errorWarningData.Warning?.PRACTICINGCERTIFICATENO}">
            <mat-label>Practicing Certificate No.</mat-label>
            <input name="PRACTICINGCERTIFICATENO" [formControlName]="'PRACTICINGCERTIFICATENO'" matInput  matTooltip="{{allToolTip.PRACTICINGCERTIFICATENO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRACTICINGCERTIFICATENO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRACTICINGCERTIFICATENO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAMILYCOURTLAWYERNO,'has-warning':errorWarningData.Warning?.FAMILYCOURTLAWYERNO}">
            <mat-label>Family Court Lawyer No.</mat-label>
            <input name="FAMILYCOURTLAWYERNO" [formControlName]="'FAMILYCOURTLAWYERNO'" matInput matTooltip="{{allToolTip.FAMILYCOURTLAWYERNO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAMILYCOURTLAWYERNO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAMILYCOURTLAWYERNO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ABN,'has-warning':errorWarningData.Warning?.ABN}">
            <mat-label>Australian Business No.</mat-label>
            <input name="ABN" [formControlName]="'ABN'" matInput  matTooltip="{{allToolTip.ABN?.HOVER}}">
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ABN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ABN?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACN,'has-warning':errorWarningData.Warning?.ACN}">
            <mat-label>Australian Company No.</mat-label>
            <input name="ACN" [formControlName]="'ACN'" matInput matTooltip="{{allToolTip.ACN?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACN?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
        </mat-form-field> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.POWEROFATTORNEYBOOK,'has-warning':errorWarningData.Warning?.POWEROFATTORNEYBOOK}">
            <mat-label>Power of Attorney Book</mat-label>
            <input name="POWEROFATTORNEYBOOK" [formControlName]="'POWEROFATTORNEYBOOK'" matInput matTooltip="{{allToolTip.POWEROFATTORNEYBOOK?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POWEROFATTORNEYBOOK?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.POWEROFATTORNEYBOOK?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.POWEROFATTORNEYNO,'has-warning':errorWarningData.Warning?.POWEROFATTORNEYNO}">
            <mat-label>Power of Attorney No</mat-label>
            <input name="POWEROFATTORNEYNO" [formControlName]="'POWEROFATTORNEYNO'" matInput matTooltip="{{allToolTip.POWEROFATTORNEYNO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POWEROFATTORNEYNO?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.POWEROFATTORNEYNO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ASSOCIATIONNO,'has-warning':errorWarningData.Warning?.ASSOCIATIONNO}">
            <mat-label>Association No.</mat-label>
            <input name="ASSOCIATIONNO" [formControlName]="'ASSOCIATIONNO'" matInput matTooltip="{{allToolTip.ASSOCIATIONNO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ASSOCIATIONNO?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.ASSOCIATIONNO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LICENCECOUNTRY,'has-warning':errorWarningData.Warning?.LICENCECOUNTRY}">
            <mat-label>Licence State/Country</mat-label>
            <input name="LICENCECOUNTRY" [formControlName]="'LICENCECOUNTRY'" matInput matTooltip="{{allToolTip.LICENCECOUNTRY?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LICENCECOUNTRY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.LICENCECOUNTRY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LICENCENO,'has-warning':errorWarningData.Warning?.LICENCENO}">
            <mat-label>Drivers Licence No.</mat-label>
            <input name="LICENCENO" [formControlName]="'LICENCENO'" matInput matTooltip="{{allToolTip.LICENCENO?.HOVER}}"> 
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LICENCENO?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.LICENCENO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LICENCECOUNTRY,'has-warning':errorWarningData.Warning?.LICENCECOUNTRY}">
            <mat-label>Licence Scan</mat-label>
            <input matInput formControlName="LICENCESCANLOCATION" matTooltip="{{allToolTip.LICENCESCANLOCATION?.HOVER}}">
            <mat-icon matSuffix (click)="licenceUploader.click()">
                <img class="seticon" src="assets/icons/web_app/folder.ico" alt="" matTooltip="" matTooltipPosition="above">
            </mat-icon>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LICENCESCANLOCATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.LICENCESCANLOCATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field> -->
        <input type="file" hidden #licenceUploader (change)="selectImg($event, 'licenceId')">

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NATIONALIDENTITYCOUNTRY,'has-warning':errorWarningData.Warning?.NATIONALIDENTITYCOUNTRY}">
            <mat-label>National Identity Country</mat-label>
            <input name="NATIONALIDENTITYCOUNTRY" [formControlName]="'NATIONALIDENTITYCOUNTRY'" matInput matTooltip="{{allToolTip.NATIONALIDENTITYCOUNTRY?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NATIONALIDENTITYCOUNTRY?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.NATIONALIDENTITYCOUNTRY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NATIONALIDENTITYNO,'has-warning':errorWarningData.Warning?.NATIONALIDENTITYNO}">
            <mat-label>National Identity No.</mat-label>
            <input name="NATIONALIDENTITYNO" [formControlName]="'NATIONALIDENTITYNO'" matInput matTooltip="{{allToolTip.NATIONALIDENTITYNO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NATIONALIDENTITYNO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NATIONALIDENTITYNO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LICENCECOUNTRY,'has-warning':errorWarningData.Warning?.LICENCECOUNTRY}">
            <mat-label>National Id Scan</mat-label>
            <input matInput formControlName="NATIDENTSCANLOCATION" matTooltip="{{allToolTip.NATIDENTSCANLOCATION?.HOVER}}">
            <mat-icon matSuffix (click)="nationalIdUploader.click()">
                <img class="seticon" src="assets/icons/web_app/folder.ico" alt="" matTooltip="" matTooltipPosition="above">
            </mat-icon>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NATIDENTSCANLOCATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NATIDENTSCANLOCATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field> -->
        <input type="file" hidden #nationalIdUploader (change)="selectImg($event, 'nationalId')">
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <!-- <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAMILYCOURTLAWYERNO,'has-warning':errorWarningData.Warning?.FAMILYCOURTLAWYERNO}">
            <mat-label>Family Court Lawyer No.</mat-label>
            <input name="FAMILYCOURTLAWYERNO" [formControlName]="'FAMILYCOURTLAWYERNO'" matInput matTooltip="{{allToolTip.FAMILYCOURTLAWYERNO?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAMILYCOURTLAWYERNO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FAMILYCOURTLAWYERNO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.HEALTHFUNDPROVIDER,'has-warning':errorWarningData.Warning?.HEALTHFUNDPROVIDER}">
            <mat-label>Health Fund Provider</mat-label>
            <input name="HEALTHFUNDPROVIDER" [formControlName]="'HEALTHFUNDPROVIDER'" matInput matTooltip="{{allToolTip.HEALTHFUNDPROVIDER?.HOVER}}"> 
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.HEALTHFUNDPROVIDER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.HEALTHFUNDPROVIDER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.HEALTHFUNDNUMBER  ,'has-warning':errorWarningData.Warning?.HEALTHFUNDNUMBER  }">
            <mat-label>Fund No.</mat-label>
            <input name="HEALTHFUNDNUMBER" formControlName="HEALTHFUNDNUMBER" matInput matTooltip="{{allToolTip.HEALTHFUNDNUMBER?.HOVER}}">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.HEALTHFUNDNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.HEALTHFUNDNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33.3" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MEDICARENUMBER,'has-warning':errorWarningData.Warning?.MEDICARENUMBER}">
            <mat-label>Medicare No.</mat-label>
            <input name="MEDICARENUMBER" [formControlName]="'MEDICARENUMBER'" matInput matTooltip="{{allToolTip.MEDICARENUMBER?.HOVER}}"> 
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MEDICARENUMBER?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.MEDICARENUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
         <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MASTERINDEXNO,'has-warning':errorWarningData.Warning?.MASTERINDEXNO}">
            <mat-label>Master Index No</mat-label>
            <input name="MASTERINDEXNO" [formControlName]="'MASTERINDEXNO'" matInput matTooltip="{{allToolTip.MASTERINDEXNO?.HOVER}}"> 
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MASTERINDEXNO?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.MASTERINDEXNO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field> 
        <mat-form-field  appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CRIMINALRECORDDATE,'has-warning':errorWarningData.Warning?.CRIMINALRECORDDATE}">
            <mat-label>Criminal Record Date</mat-label>
            <input matInput [matDatepicker]="picker6" name="CRIMINALRECORDDATE" formControlName="CRIMINALRECORDDATE" (dateChange)="choosedDate($event)"
            required>
        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CRIMINALRECORDDATE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.CRIMINALRECORDDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field> 
        
    </div>

</div>