import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
import { ApplicationPipesModule } from '../../application-pipes.module';
import { AuthGuard } from 'src/app/_guards';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MainTrustMoneyComponent } from './main-trust-money/main-trust-money.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MultiAmount, TrustMoneyComponent } from './trust-money.component';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { ProtectedTrustComponent } from './protected-trust/protected-trust.component';
import { ProtectedTrustDialogComponent } from './protected-trust-dialog/protected-trust-dialog.component';
import { ProtectedTrustMultiDetailsComponent } from './protected-trust-multi-details/protected-trust-multi-details.component';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { TrustMoneyDialogeComponent } from './trust-money-dialoge/trust-money-dialoge.component';
import { TrustMoneyMultiDetailsComponent } from './trust-money-multi-details/trust-money-multi-details.component';
import { TrustMoneyReciptComponent } from './trust-money-recipt/trust-money-recipt.component';

/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    { path: '', redirectTo: '/trust-money/full-trust-money', pathMatch: 'full', canActivate: [AuthGuard] },
    {
        path: '', component: MainTrustMoneyComponent, children: [
            {
                path: 'full-trust-money', component: TrustMoneyComponent, data: {
                    title: 'Trust Money'
                },
            },
            {
                path: 'protected-trust', component: ProtectedTrustComponent, data: {
                    title: 'protected-trust'
                },
            },

        ], canActivate: [AuthGuard]
    }
];
//   export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//     align: "right",
//     allowNegative: true,
//     allowZero: true,
//     decimal: ".",
//     precision: 2,
//     prefix: "$",
//     suffix: "",
//     thousands: ",",
//     nullable: false,
//     inputMode: CurrencyMaskInputMode.NATURAL,
//   };

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [TrustMoneyComponent, TrustMoneyDialogeComponent, TrustMoneyReciptComponent, TrustMoneyMultiDetailsComponent, MultiAmount, ProtectedTrustComponent, MainTrustMoneyComponent, ProtectedTrustDialogComponent, ProtectedTrustMultiDetailsComponent],
    entryComponents: [TrustMoneyDialogeComponent, TrustMoneyReciptComponent],
    imports: [
        FormsModule,
        CommonModule,
        MatTreeModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseConfirmDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSelectModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        SatDatepickerModule, SatNativeDateModule,

        MatCheckboxModule,
        DragDropModule,
        MatSortModule,
        ApplicationPipesModule,
        ResizableModule,
        NgxCurrencyModule,
        MatChipsModule,
        NumberMaskModule,
        NgxMaskModule.forRoot(maskConfig)
        // FormsModule,
        // ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
    ],
    exports: [
        TrustMoneyComponent,
        ProtectedTrustComponent
    ],
    providers: [
        TrustMoneyComponent,
        CurrencyPipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        // { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
})
export class TrustMoneyModule { }
