<div class=" one " fxLayout="column" [formGroup]="MatterPropertyDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STRATAPLANNUMBER,'has-warning':errorWarningData.Warning?.STRATAPLANNUMBER}">
            <mat-label>Strata Plan Number </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.STRATAPLANNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STRATAPLANNUMBER">
             <div  *ngIf="testBool && toolTipList.STRATAPLANNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STRATAPLANNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STRATAPLANNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BYLAWTYPE,'has-warning':errorWarningData.Warning?.BYLAWTYPE}">
            <mat-label>By Law Type</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.BYLAWTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BYLAWTYPE">
             <div  *ngIf="testBool && toolTipList.BYLAWTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BYLAWTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BYLAWTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BYLAWNO,'has-warning':errorWarningData.Warning?.BYLAWNO}">
            <mat-label>By Law No. </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.BYLAWNO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BYLAWNO">
             <div  *ngIf="testBool && toolTipList.BYLAWNO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BYLAWNO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BYLAWNO?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="pr-4" fxFlex="33" [ngClass]="{'has-error':errorWarningData.Error?.SPECIALRESOLUTIONDATE,'has-warning':errorWarningData.Warning?.SPECIALRESOLUTIONDATE}">
            <mat-label>Resolution Date </mat-label>
            <input (dateInput)="ResolutionDatepicker('input', $event)" (dateChange)="ResolutionDatepicker('change', $event)" name="resolutiondatepicker" matInput [matDatepicker]="resolutiondatepicker" matTooltip="{{(toolTipList)?toolTipList.SPECIALRESOLUTIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SPECIALRESOLUTIONDATETEXT">
            <mat-datepicker-toggle matSuffix [for]="resolutiondatepicker"></mat-datepicker-toggle>
            <mat-datepicker #resolutiondatepicker></mat-datepicker>
             <div  *ngIf="testBool && toolTipList.SPECIALRESOLUTIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SPECIALRESOLUTIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SPECIALRESOLUTIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="34" [ngClass]="{'has-error':errorWarningData.Error?.AGGREGATIONOFENTITLEMENT,'has-warning':errorWarningData.Warning?.AGGREGATIONOFENTITLEMENT}">
            <mat-label>Aggr. of Entitlement </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.AGGREGATIONOFENTITLEMENT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="AGGREGATIONOFENTITLEMENT">
             <div  *ngIf="testBool && toolTipList.AGGREGATIONOFENTITLEMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.AGGREGATIONOFENTITLEMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AGGREGATIONOFENTITLEMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="33" [ngClass]="{'has-error':errorWarningData.Error?.TOTALUNITS,'has-warning':errorWarningData.Warning?.TOTALUNITS}">
            <mat-label>Total Units </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.TOTALUNITS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="TOTALUNITS">
             <div  *ngIf="testBool && toolTipList.TOTALUNITS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TOTALUNITS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALUNITS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>