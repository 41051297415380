import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseSidebarService } from 'src/@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from 'src/@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { TaskDialogeComponent } from '../task-dialoge/task-dialoge.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-task-reminder-details',
    templateUrl: './task-reminder-details.component.html',
    styleUrls: ['./task-reminder-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TaskReminderDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    sidebarFolded: boolean;
    folderHideshow: any = true
    @Input() taskData: any;
    displayedColumns: string[] = ['DESCRIPTION', 'STARTDATE'];
    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
    selectedColore: string = 'rgb(217, 217, 217)';
    highlightedRows: any;
    ActiveRow: any;
    SnoozeTime: any;
    SnoozeSpiner: boolean = false;
    dissSpiner: boolean = false;
    dissallSpiner: boolean = false;
    @Output() isTaskData: EventEmitter<any> = new EventEmitter<any>();
    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {HttpClient} _httpClient
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _httpClient: HttpClient,
        private _fuseSidebarService: FuseSidebarService,
        public dialog: MatDialog,
        public behaviorService: BehaviorService,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
    ) {
        // Set the defaults
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    /**
        * On init
        */
    ngOnInit(): void {
        // Subscribe to the foldedChanged observable
        this._fuseSidebarService.getSidebar('chatPanel').foldedChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((folded) => {
            this.sidebarFolded = folded;
        });
        if (this.taskData[0]) {
            this.behaviorService.TaskData(this.taskData[0]);
            this.ActiveRow = this.taskData[0];
            this.highlightedRows = this.taskData[0].TASKGUID;
        }
    }

    /**
     * This function is used to edit the Data
     */
    editData(row: any) {
        this.behaviorService.TaskData(row);
        const dialogRef = this.dialog.open(TaskDialogeComponent, {
            disableClose: true, panelClass: 'Task-dialog', data: { action: 'edit' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getTask();
            }
        });
    }

    /**
     * This function is used to dismiss all data
     */
    dissmissAll() {
        this.dissallSpiner = true;
        this.taskData.forEach(element => {
            let postData = { FormAction: "Dismiss", DATA: { TASKGUID: element.TASKGUID } };
            this._mainAPiServiceService.getSetData(postData, 'task').pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    this.getTask();
                    this.dissallSpiner = false;
                }
            }, error => {
                this.dissallSpiner = false;
                this.toastr.error(error);
            });
        });
    }

    /**
     * This function is used to Snooj the data
     */
    Snooze(row) {
        if (this.SnoozeTime) {
            this.SnoozeSpiner = true;
            let T = this.SnoozeTime.split(':');
            let data: any = {};
            data['TASKGUID'] = row.TASKGUID;
            data[T[0]] = T[1];
            let postData = { FormAction: "Snooze", DATA: data };
            this._mainAPiServiceService.getSetData(postData, 'task').pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    this.SnoozeSpiner = false;
                    this.getTask();
                }
            }, error => {
                this.SnoozeSpiner = false;
                this.toastr.error(error);
            });
        } else {
            this.toastr.warning("Can you please select snooze time");
        }
    }

    /**
     * This function is used to dismiss the data
     */
    dissmiss(row) {
        this.dissSpiner = true;
        let postData = { FormAction: "Dismiss", DATA: { TASKGUID: row.TASKGUID } };
        this._mainAPiServiceService.getSetData(postData, 'task').pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res.STATUS == "success" && res.CODE == 200) {
                this.dissSpiner = false;
                this.getTask();
            }
        }, error => {
            this.dissSpiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to get the task
     */
    getTask() {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        //new Payload changes see this ToDo -> New API for Tasks.
        let refectorPayload = {
            "Filters": {
                HASREMINDER: true,
                HASREMINDERMINUTES: 5,
                USERGUID: currentUser.UserGuid,
            },
            "BACKGROUND": true,
            Action: "GetData"
        }

        //old changes -> { HASREMINDER: true, HASREMINDERMINUTES: 5, USERGUID: currentUser.UserGuid, "BACKGROUND": true } on refectorPayload place

        this._mainAPiServiceService.getSetData(refectorPayload, 'task').pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.taskData = res.DATA.TASKS;
                if (!this.taskData[0]) {
                    this.isTaskData.emit(false);
                    this.behaviorService.TaskData(null);
                    this.ActiveRow = "";
                    this.highlightedRows = "";
                }
            }
        }, err => {
            console.log(err);
        });
    }
    
    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        this.unfoldSidebarTemporarily();
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
        this.folderHideshow = this._fuseSidebarService.getSidebar('chatPanel').unfoldedReminder

    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
        this.folderHideshow = this._fuseSidebarService.getSidebar('chatPanel').unfoldedReminder
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }
}
