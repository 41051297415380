import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MatterPopupComponent } from '../../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
    selector: 'app-leasing-details-popup',
    templateUrl: './leasing-details-popup.component.html',
    styleUrls: ['./leasing-details-popup.component.scss']
})
export class LeasingDetailsPopupComponent implements OnInit, OnDestroy {
    matterLeasingForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() testBool;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    @Input() toolTipList: any;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>) {

        this.initFormData();
    }

    get f() {
        return this.matterLeasingForm.controls;
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();

    }

    CommanDateSetter(paramVar, suffixText, value) {
        let DateArray = value.split("/");
        this.matterLeasingForm.controls[paramVar + suffixText].setValue(new Date(DateArray[1] + '/' + DateArray[0] + '/' + DateArray[2]));
        this.matterLeasingForm.controls[paramVar].setValue(value);
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
    initFormData() {
        this.matterLeasingForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERLEASINGGUID: [''],
            // general
            LEASERECEIVED: [''],
            DATEEXECUTED: [''],
            DATEEXECUTEDTEXT: [''],
            EXECUTIONDATEBYLESSOR: [''],
            EXECUTIONDATEBYLESSORTEXT: [''],
            EXECUTIONDATEBYLESSEE: [''],
            EXECUTIONDATEBYLESSEETEXT: [''],
            REGISTRATIONDATE: [''],
            REGISTRATIONDATETEXT: [''],
            VALIDUNTIL: [''],
            VALIDUNTILTEXT: [''],
            LEASECOMMENCEMENTDATE: [''],
            LEASECOMMENCEMENTDATETEXT: [''],
            LEASETERMINATIONDATE: [''],
            LEASETERMINATIONDATETEXT: [''],
            LEASINGREGISTEREDNUMBER: [''],
            TERMOFLEASE: [''],
            PERMITTEDUSE: [''],
            DISCLOSUREDATE: [''],
            DISCLOSUREDATETEXT: [''],
            LETTABLEAREASQM: [''],
            RELATESTORETIREMENTVILLAGE: [''],
            ACCESSOUTSIDETRADINGHOURS: [''],
            RETAILSHOPLEASE: [''],
            REIMBURSEMENTFORGSTBYTENANT: [''],
            STAMPDUTYDOCUMENTS: [''],
            OPTIONDATE: [''],
            OPTIONDATETEXT: [''],
            OPTIONPERIOD: [''],
            OPTIONDESCRIPTION: [''],
            // Rent

            ANNUALRENTAL: ['', {updateOn: 'blur'}],
            ANNUALRENTALCLAUSE: ['', {updateOn: 'blur'}],
            GSTPAYABLE: [''],
            RENTPAYABLEONWHATDAY: [''],
            RENTALAMOUNT: ['', {updateOn: 'blur'}],
            RENTALPERIOD: [''],
            OUTGOINGS: [''],
            SECURITYDEPOSIT: ['', {updateOn: 'blur'}],
            SECURITYDEPOSITTYPE: [''],
            OVERDUERENTINTERESTRATE: [''],
            RENTSTARTDATE: [''],
            RENTSTARTDATETEXT: [''],
            RENTFREESTARTDATE: [''],
            RENTFREESTARTDATETEXT: [''],
            RENTFREEUNITS: ['', {updateOn: 'blur'}],
            RENTFREECLAUSE: ['', {updateOn: 'blur'}],
            RENTFREEPERIOD: [''],
            RENTFREEAMOUNT: ['', {updateOn: 'blur'}],
            RENTFREEOVERRIDEAMOUNT: ['', {updateOn: 'blur'}],
            PUBLICLIABAMOUNT: ['', {updateOn: 'blur'}],
            PUBLICLIABPREMIUM: ['', {updateOn: 'blur'}],
            PUBLICLIABPREMIUMCLAUSE: ['', {updateOn: 'blur'}],
            PUBLICLIABSTAMPDUTY: ['', {updateOn: 'blur'}],
            PUBLICLIABPAYABLE: [''],
            PUBLICLIABINTERESTOFLESSOR: ['', {updateOn: 'blur'}],
            // Future Years

            YEAR2AMOUNT: ['', {updateOn: 'blur'}],
            YEAR3AMOUNT: ['', {updateOn: 'blur'}],
            YEAR4AMOUNT: ['', {updateOn: 'blur'}],
            YEAR5AMOUNT: ['', {updateOn: 'blur'}],
            YEAR6AMOUNT: ['', {updateOn: 'blur'}],
            YEAR7AMOUNT: ['', {updateOn: 'blur'}],
            YEAR8AMOUNT: ['', {updateOn: 'blur'}],
            YEAR9AMOUNT: ['', {updateOn: 'blur'}],
            YEAR10AMOUNT: ['', {updateOn: 'blur'}],
            YEAR2INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR3INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR4INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR5INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR6INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR7INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR8INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR9INSTALMENT: ['', {updateOn: 'blur'}],
            YEAR10INSTALMENT: ['', {updateOn: 'blur'}],

            // Rent Review

            RENTFORMULA: [''],
            RENTREVIEWFREQUENCY: [''],
            RENTREVIEWNATURE: [''],
            RENTREVIEWCLAUSE: [''],
            RENTREVIEWFIXEDAMOUNT: ['', {updateOn: 'blur'}],
            RENTREVIEWCURRENTCPI: [''],
            RENTREVIEWFIXEDPERCENT: [''],
            RENTREVIEWMARKETRENTPERCENT: ['']
        });
    }
    ngOnInit() {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.matterLeasingForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Leasing" && result.action == 'setData') {
                    this.SaveMatterLeasingDetails();
                } else if (result.classType == "Leasing" && result.action == 'deleteData') {
                    this.DeleteMatterLeasingDetails();
                }
            }
        });



        if (this.action === 'edit') {
            this.mapLeasingDataToFields();
        }

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((data) => {
            if (data == true) {
                this.mapLeasingDataToFields();
            }
        })

    }

    mapLeasingDataToFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-leasing').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterLeasingData = response.DATA['RECORDS'][0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterLeasingData) {
                        this.matterLeasingForm.patchValue({
                            MATTERGUID: MatterLeasingData.MATTERGUID,
                            MATTERLEASINGGUID: MatterLeasingData.MATTERLEASINGGUID,
                            // general
                            LEASERECEIVED: MatterLeasingData.LEASERECEIVED,
                            DATEEXECUTED: MatterLeasingData.DATEEXECUTED,
                            EXECUTIONDATEBYLESSOR: MatterLeasingData.EXECUTIONDATEBYLESSOR,
                            EXECUTIONDATEBYLESSEE: MatterLeasingData.EXECUTIONDATEBYLESSEE,
                            REGISTRATIONDATE: MatterLeasingData.REGISTRATIONDATE,
                            VALIDUNTIL: MatterLeasingData.VALIDUNTIL,
                            LEASECOMMENCEMENTDATE: MatterLeasingData.LEASECOMMENCEMENTDATE,
                            LEASETERMINATIONDATE: MatterLeasingData.LEASETERMINATIONDATE,
                            LEASINGREGISTEREDNUMBER: MatterLeasingData.LEASINGREGISTEREDNUMBER,
                            TERMOFLEASE: MatterLeasingData.TERMOFLEASE,
                            PERMITTEDUSE: MatterLeasingData.PERMITTEDUSE,
                            DISCLOSUREDATE: MatterLeasingData.DISCLOSUREDATE,
                            LETTABLEAREASQM: MatterLeasingData.LETTABLEAREASQM,
                            RELATESTORETIREMENTVILLAGE: MatterLeasingData.RELATESTORETIREMENTVILLAGE,
                            ACCESSOUTSIDETRADINGHOURS: MatterLeasingData.ACCESSOUTSIDETRADINGHOURS,
                            RETAILSHOPLEASE: MatterLeasingData.RETAILSHOPLEASE,
                            REIMBURSEMENTFORGSTBYTENANT: MatterLeasingData.REIMBURSEMENTFORGSTBYTENANT,
                            STAMPDUTYDOCUMENTS: MatterLeasingData.STAMPDUTYDOCUMENTS,
                            OPTIONDATE: MatterLeasingData.OPTIONDATE,
                            OPTIONPERIOD: MatterLeasingData.OPTIONPERIOD,
                            OPTIONDESCRIPTION: MatterLeasingData.OPTIONDESCRIPTION,
                            // Rent

                            ANNUALRENTAL: MatterLeasingData.ANNUALRENTAL,
                            ANNUALRENTALCLAUSE: MatterLeasingData.ANNUALRENTALCLAUSE,
                            GSTPAYABLE: MatterLeasingData.GSTPAYABLE,
                            RENTPAYABLEONWHATDAY: MatterLeasingData.RENTPAYABLEONWHATDAY,
                            RENTALAMOUNT: MatterLeasingData.RENTALAMOUNT,
                            RENTALPERIOD: MatterLeasingData.RENTALPERIOD,
                            OUTGOINGS: MatterLeasingData.OUTGOINGS,
                            SECURITYDEPOSIT: MatterLeasingData.SECURITYDEPOSIT,
                            SECURITYDEPOSITTYPE: MatterLeasingData.SECURITYDEPOSITTYPE,
                            OVERDUERENTINTERESTRATE: MatterLeasingData.OVERDUERENTINTERESTRATE,
                            RENTSTARTDATE: MatterLeasingData.RENTSTARTDATE,
                            RENTFREESTARTDATE: MatterLeasingData.RENTFREESTARTDATE,
                            RENTFREEUNITS: MatterLeasingData.RENTFREEUNITS,
                            RENTFREECLAUSE: MatterLeasingData.RENTFREECLAUSE,
                            RENTFREEPERIOD: MatterLeasingData.RENTFREEPERIOD,
                            RENTFREEAMOUNT: MatterLeasingData.RENTFREEAMOUNT,
                            RENTFREEOVERRIDEAMOUNT: MatterLeasingData.RENTFREEOVERRIDEAMOUNT,
                            PUBLICLIABAMOUNT: MatterLeasingData.PUBLICLIABAMOUNT,
                            PUBLICLIABPREMIUM: MatterLeasingData.PUBLICLIABPREMIUM,
                            PUBLICLIABPREMIUMCLAUSE: MatterLeasingData.PUBLICLIABPREMIUMCLAUSE,
                            PUBLICLIABSTAMPDUTY: MatterLeasingData.PUBLICLIABSTAMPDUTY,
                            PUBLICLIABPAYABLE: MatterLeasingData.PUBLICLIABPAYABLE,
                            PUBLICLIABINTERESTOFLESSOR: MatterLeasingData.PUBLICLIABINTERESTOFLESSOR,
                            // Future Years
                            YEAR2AMOUNT: MatterLeasingData.YEAR2AMOUNT,
                            YEAR3AMOUNT: MatterLeasingData.YEAR3AMOUNT,
                            YEAR4AMOUNT: MatterLeasingData.YEAR4AMOUNT,
                            YEAR5AMOUNT: MatterLeasingData.YEAR5AMOUNT,
                            YEAR6AMOUNT: MatterLeasingData.YEAR6AMOUNT,
                            YEAR7AMOUNT: MatterLeasingData.YEAR7AMOUNT,
                            YEAR8AMOUNT: MatterLeasingData.YEAR8AMOUNT,
                            YEAR9AMOUNT: MatterLeasingData.YEAR9AMOUNT,
                            YEAR10AMOUNT: MatterLeasingData.YEAR10AMOUNT,
                            YEAR2INSTALMENT: MatterLeasingData.YEAR2INSTALMENT,
                            YEAR3INSTALMENT: MatterLeasingData.YEAR3INSTALMENT,
                            YEAR4INSTALMENT: MatterLeasingData.YEAR4INSTALMENT,
                            YEAR5INSTALMENT: MatterLeasingData.YEAR5INSTALMENT,
                            YEAR6INSTALMENT: MatterLeasingData.YEAR6INSTALMENT,
                            YEAR7INSTALMENT: MatterLeasingData.YEAR7INSTALMENT,
                            YEAR8INSTALMENT: MatterLeasingData.YEAR8INSTALMENT,
                            YEAR9INSTALMENT: MatterLeasingData.YEAR9INSTALMENT,
                            YEAR10INSTALMENT: MatterLeasingData.YEAR10INSTALMENT,
                            // Rent Review
                            RENTFORMULA: MatterLeasingData.RENTFORMULA,
                            RENTREVIEWFREQUENCY: MatterLeasingData.RENTREVIEWFREQUENCY,
                            RENTREVIEWNATURE: MatterLeasingData.RENTREVIEWNATURE,
                            RENTREVIEWCLAUSE: MatterLeasingData.RENTREVIEWCLAUSE,
                            RENTREVIEWFIXEDAMOUNT: MatterLeasingData.RENTREVIEWFIXEDAMOUNT,
                            RENTREVIEWCURRENTCPI: MatterLeasingData.RENTREVIEWCURRENTCPI,
                            RENTREVIEWFIXEDPERCENT: MatterLeasingData.RENTREVIEWFIXEDPERCENT,
                            RENTREVIEWMARKETRENTPERCENT: MatterLeasingData.RENTREVIEWMARKETRENTPERCENT
                        });
                        if (MatterLeasingData.DATEEXECUTED) {
                            this.CommanDateSetter("DATEEXECUTED", "TEXT", MatterLeasingData.DATEEXECUTED);
                        }
                        if (MatterLeasingData.EXECUTIONDATEBYLESSOR) {
                            this.CommanDateSetter("EXECUTIONDATEBYLESSOR", "TEXT", MatterLeasingData.EXECUTIONDATEBYLESSOR);
                        }
                        if (MatterLeasingData.EXECUTIONDATEBYLESSEE) {
                            this.CommanDateSetter("EXECUTIONDATEBYLESSEE", "TEXT", MatterLeasingData.EXECUTIONDATEBYLESSEE);
                        }
                        if (MatterLeasingData.REGISTRATIONDATE) {
                            this.CommanDateSetter("REGISTRATIONDATE", "TEXT", MatterLeasingData.REGISTRATIONDATE);
                        }
                        if (MatterLeasingData.LEASECOMMENCEMENTDATE) {
                            this.CommanDateSetter("LEASECOMMENCEMENTDATE", "TEXT", MatterLeasingData.LEASECOMMENCEMENTDATE);
                        }
                        if (MatterLeasingData.LEASETERMINATIONDATE) {
                            this.CommanDateSetter("LEASETERMINATIONDATE", "TEXT", MatterLeasingData.LEASETERMINATIONDATE);
                        }
                        if (MatterLeasingData.DISCLOSUREDATE) {
                            this.CommanDateSetter("DISCLOSUREDATE", "TEXT", MatterLeasingData.DISCLOSUREDATE);
                        }
                        if (MatterLeasingData.OPTIONDATE) {
                            this.CommanDateSetter("OPTIONDATE", "TEXT", MatterLeasingData.OPTIONDATE);
                        }
                        if (MatterLeasingData.RENTSTARTDATE) {
                            this.CommanDateSetter("RENTSTARTDATE", "TEXT", MatterLeasingData.RENTSTARTDATE);
                        }
                    }
                }
            });
        }
    }


    DeleteMatterLeasingDetails() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-leasing').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterLeasingDetailsData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERLEASINGGUID: MatterLeasingDetailsData.MATTERLEASINGGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-leasing').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    SaveMatterLeasingDetails() {

        this.behaviorService.setMatterLoding(true);
        let details: any = {
            MATTERGUID: this.f.MATTERGUID.value,
            MATTERLEASINGGUID: this.f.MATTERLEASINGGUID.value,
            // general
            LEASERECEIVED: this.f.LEASERECEIVED.value,
            DATEEXECUTED: this.f.DATEEXECUTED.value,
            EXECUTIONDATEBYLESSOR: this.f.EXECUTIONDATEBYLESSOR.value,
            EXECUTIONDATEBYLESSEE: this.f.EXECUTIONDATEBYLESSEE.value,
            REGISTRATIONDATE: this.f.REGISTRATIONDATE.value,
            VALIDUNTIL: this.f.VALIDUNTIL.value,
            LEASECOMMENCEMENTDATE: this.f.LEASECOMMENCEMENTDATE.value,
            LEASETERMINATIONDATE: this.f.LEASETERMINATIONDATE.value,
            LEASINGREGISTEREDNUMBER: this.f.LEASINGREGISTEREDNUMBER.value,
            TERMOFLEASE: this.f.TERMOFLEASE.value,
            PERMITTEDUSE: this.f.PERMITTEDUSE.value,
            DISCLOSUREDATE: this.f.DISCLOSUREDATE.value,
            LETTABLEAREASQM: this.f.LETTABLEAREASQM.value,
            RELATESTORETIREMENTVILLAGE: this.f.RELATESTORETIREMENTVILLAGE.value,
            ACCESSOUTSIDETRADINGHOURS: this.f.ACCESSOUTSIDETRADINGHOURS.value,
            RETAILSHOPLEASE: this.f.RETAILSHOPLEASE.value,
            REIMBURSEMENTFORGSTBYTENANT: this.f.REIMBURSEMENTFORGSTBYTENANT.value,
            STAMPDUTYDOCUMENTS: this.f.STAMPDUTYDOCUMENTS.value,
            OPTIONDATE: this.f.OPTIONDATE.value,
            OPTIONPERIOD: this.f.OPTIONPERIOD.value,
            OPTIONDESCRIPTION: this.f.OPTIONDESCRIPTION.value,
            // Rent

            ANNUALRENTAL: this.f.ANNUALRENTAL.value,
            ANNUALRENTALCLAUSE: this.f.ANNUALRENTALCLAUSE.value,
            GSTPAYABLE: this.f.GSTPAYABLE.value,
            RENTPAYABLEONWHATDAY: this.f.RENTPAYABLEONWHATDAY.value,
            RENTALAMOUNT: this.f.RENTALAMOUNT.value,
            RENTALPERIOD: this.f.RENTALPERIOD.value,
            OUTGOINGS: this.f.OUTGOINGS.value,
            SECURITYDEPOSIT: this.f.SECURITYDEPOSIT.value,
            SECURITYDEPOSITTYPE: this.f.SECURITYDEPOSITTYPE.value,
            OVERDUERENTINTERESTRATE: this.f.OVERDUERENTINTERESTRATE.value,
            RENTSTARTDATE: this.f.RENTSTARTDATE.value,
            RENTFREESTARTDATE: this.f.RENTFREESTARTDATE.value,
            RENTFREEUNITS: this.f.RENTFREEUNITS.value,
            RENTFREECLAUSE: this.f.RENTFREECLAUSE.value,
            RENTFREEPERIOD: this.f.RENTFREEPERIOD.value,
            RENTFREEAMOUNT: this.f.RENTFREEAMOUNT.value,
            RENTFREEOVERRIDEAMOUNT: this.f.RENTFREEOVERRIDEAMOUNT.value,
            PUBLICLIABAMOUNT: this.f.PUBLICLIABAMOUNT.value,
            PUBLICLIABPREMIUM: this.f.PUBLICLIABPREMIUM.value,
            PUBLICLIABPREMIUMCLAUSE: this.f.PUBLICLIABPREMIUMCLAUSE.value,
            PUBLICLIABSTAMPDUTY: this.f.PUBLICLIABSTAMPDUTY.value,
            PUBLICLIABPAYABLE: this.f.PUBLICLIABPAYABLE.value,
            PUBLICLIABINTERESTOFLESSOR: this.f.PUBLICLIABINTERESTOFLESSOR.value,
            // Future Years
            YEAR2AMOUNT: this.f.YEAR2AMOUNT.value,
            YEAR3AMOUNT: this.f.YEAR3AMOUNT.value,
            YEAR4AMOUNT: this.f.YEAR4AMOUNT.value,
            YEAR5AMOUNT: this.f.YEAR5AMOUNT.value,
            YEAR6AMOUNT: this.f.YEAR6AMOUNT.value,
            YEAR7AMOUNT: this.f.YEAR7AMOUNT.value,
            YEAR8AMOUNT: this.f.YEAR8AMOUNT.value,
            YEAR9AMOUNT: this.f.YEAR9AMOUNT.value,
            YEAR10AMOUNT: this.f.YEAR10AMOUNT.value,
            YEAR2INSTALMENT: this.f.YEAR2INSTALMENT.value,
            YEAR3INSTALMENT: this.f.YEAR3INSTALMENT.value,
            YEAR4INSTALMENT: this.f.YEAR4INSTALMENT.value,
            YEAR5INSTALMENT: this.f.YEAR5INSTALMENT.value,
            YEAR6INSTALMENT: this.f.YEAR6INSTALMENT.value,
            YEAR7INSTALMENT: this.f.YEAR7INSTALMENT.value,
            YEAR8INSTALMENT: this.f.YEAR8INSTALMENT.value,
            YEAR9INSTALMENT: this.f.YEAR9INSTALMENT.value,
            YEAR10INSTALMENT: this.f.YEAR10INSTALMENT.value,
            // Rent Review
            RENTFORMULA: this.f.RENTFORMULA.value,
            RENTREVIEWFREQUENCY: this.f.RENTREVIEWFREQUENCY.value,
            RENTREVIEWNATURE: this.f.RENTREVIEWNATURE.value,
            RENTREVIEWCLAUSE: this.f.RENTREVIEWCLAUSE.value,
            RENTREVIEWFIXEDAMOUNT: this.f.RENTREVIEWFIXEDAMOUNT.value,
            RENTREVIEWCURRENTCPI: this.f.RENTREVIEWCURRENTCPI.value,
            RENTREVIEWFIXEDPERCENT: this.f.RENTREVIEWFIXEDPERCENT.value,
            RENTREVIEWMARKETRENTPERCENT: this.f.RENTREVIEWMARKETRENTPERCENT.value
        };
        if (this.action === 'duplicate') {
            delete details.MATTERLEASINGGUID;
        }
        const formAction = (details.MATTERLEASINGGUID) ? 'Update' : 'Insert';
        const MatterPropertyDetailsData: any = { Action: formAction, VALIDATEONLY: true, Data: details };
        this._mainAPiServiceService.getSetData(MatterPropertyDetailsData, 'matter-leasing').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterPropertyDetailsData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterPropertyDetailsData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterPropertyDetailsData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMatterLeasingDetailsAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMatterLeasingDetailsAfterVAlidation(details);

        }
    }
    SaveMatterLeasingDetailsAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-leasing').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
}
