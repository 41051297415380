import { Directive, ElementRef, HostListener, Input, AfterViewInit } from "@angular/core";

@Directive({
    selector: '[appTimelineIcon]'
})
export class TimelineIconDirective implements AfterViewInit {

    @Input() appTimelineIcon

    constructor(private el: ElementRef) { }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        let refOfdata = this.el.nativeElement.children;
        for (var indx = 0; indx < refOfdata.length; indx++) {
            let staper_node = refOfdata[indx].children[0];
            let inner_stapper_node = staper_node.children[2];
            let access_icon_layer = inner_stapper_node.children[0];
            let mat_icon = access_icon_layer.children[0];
            let nodeActualIcon = staper_node.children[1];

            if (mat_icon.tagName == 'MAT-ICON') {
                mat_icon.style.left = `5px`;
                mat_icon.style['z-index'] = '999';
                mat_icon.style['top'] = '0px';
                mat_icon.className = 'mat-icon-runtime';
                // mat_icon.style['height']='25px !important';
                // mat_icon.style['width']='25px !important';
                inner_stapper_node.style['margin-left'] = '12.5px';

                nodeActualIcon.outerHTML = `${mat_icon.outerHTML}`;
                mat_icon.outerHTML = ``;
            }
        }
    }
}
