<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button (click)="ClosePackTabl()" [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.TEMPLATETYPE,'has-warning':errorWarningData.Warning?.TEMPLATETYPE}">
                        <mat-label>Template Type</mat-label>
                        <mat-select required [(ngModel)]="KitItemData.TEMPLATETYPE" #value name="selected"
                            (selectionChange)="TempleteChnage($event.value)" name="TempleteType">
                            <mat-option value="0">Document</mat-option>
                            <mat-option value="1">Email</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.TEMPLATEFILE,'has-warning':errorWarningData.Warning?.TEMPLATEFILE}">
                        <mat-label>Template File</mat-label>
                        <input required [(ngModel)]="KitItemData.TEMPLATEFILE" type="text" placeholder="Template File"
                            aria-label="Number" matInput name="TempleteFile" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let val of emailname" value="{{val.NAME}}">
                                {{val.NAME}}
                            </mat-option>
                        </mat-autocomplete>
                        <!-- <mat-icon matSuffix (click)='SelectFile()' *ngIf="(TemplateType == 0 || TemplateType == '0')"> -->
                            <mat-icon matSuffix (click)='SelectFile()'>
                            <img src="assets/icons/web_app/icon_letters_d.ico">
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Copies</mat-label>
                        <input required [(ngModel)]="KitItemData.COPIES" name="Copies" type="number" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.ORDER,'has-warning':errorWarningData.Warning?.ORDER}">
                        <mat-label>Order</mat-label>
                        <input [(ngModel)]="KitItemData.ORDER" name="Order" type="number" matInput>
                    </mat-form-field>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-checkbox [(ngModel)]="KitItemData.PROMT" name="Prompt">Prompt Before Printing</mat-checkbox>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="PackItemSave()" [disabled]="isspiner"
            *ngIf="dialogTitle !== 'Edit Pack'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Add Pack
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="PackItemSave()" [disabled]="isspiner"
            *ngIf="dialogTitle == 'Edit Pack'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update Pack
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>