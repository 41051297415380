import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from 'src/@fuse/directives/directives';
import { FusePipesModule } from 'src/@fuse/pipes/pipes.module';
import { ResizeColumnDirective } from '@directives/resize-column.directive';

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule
    ],
    declarations : [ResizeColumnDirective],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
        ResizeColumnDirective
    ]
})
export class FuseSharedModule{ }
