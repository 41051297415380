<div>
    <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <div class="notifications-panel main_setting_div setupguidewrapper"
        fusePerfectScrollbar>
        <div class="headertop-box">
        <div class="header" fxLayoutAlign="flex-end">
            <span class="title">Setup Guide</span>
            <!-- <button mat-icon-button class="close-button"
                (click)="toggleSidebarOpen('setupguide')">
                <mat-icon>close</mat-icon>
            </button> -->
        </div>

        <div class="header progress-header">
            <mat-progress-bar mode="determinate" [value]=ProgressValue
                class="progressbar-wrap"></mat-progress-bar>
            <span class="task-status"><span class="task-complete">{{SetUpGuideDataTask?.COMPLETEDTASKCOUNT}} of
                {{SetUpGuideDataTask?.TOTALTASKCOUNT}}</span>&nbsp;<span>Tasks Complete</span>
            </span>
        </div>
        </div>

        <mat-divider cdkFocusRegionEnd></mat-divider>

        <div class="setup-listview setupinfo-content">
            <div *ngFor="let SetUpData of SetUpGuideDataList;let i = index"
                class=" setup-guideblock">
                <div class="display-flex align-items-center">
                    <span *ngIf="currentUser?.PRODUCTTYPE =='Solicitor'" class="flex-shrink-0 mr-8 d-block" (click)="changeSetupstatus(SetUpData)"  style="cursor: pointer;"><img
                            src="assets/images/logos/{{(SetUpData.STATUS =='Incomplete' ? 'circle-loading_16px' : 'checked_16px') + '.png'}}">
                    </span>
                    <span *ngIf="currentUser?.PRODUCTTYPE =='Barrister'" class="flex-shrink-0 mr-8 d-block" (click)="changeSetupstatus(SetUpData)"  style="cursor: pointer;"><img style="width: 16px;height: 16px;"
                        src="assets/images/logos/{{(SetUpData.STATUS =='Incomplete' ? 'circle-loading_16px' : 'success') + '.png'}}">
                </span>
                    <div
                        class="pointer-cursor display-flex align-items-center justify-content-between"
                        style="width: 100%;"
                        (click)="changeDescription(SetUpData)">
                        <p class="my-0 ">{{SetUpData.SHORTDESCRIPTION}}</p>
                        <mat-icon class="ml-auto">{{SetUpGuideDataList[i].longdes
                            ? 'keyboard_arrow_left' :'keyboard_arrow_right'}}</mat-icon>
                    </div>

                </div>

                <div class="setupinfo-slider" 
                    [ngClass]="SetUpGuideDataList[i].longdes ? 'active':''">
                    <div [ngClass]="(background) ? 'bc':'wt'">
                    <div class="display-flex setupinfo-title align-center">
                        <mat-icon (click)="changeDescription(SetUpData)" class="backbtn">arrow_back_ios</mat-icon>
                        <h3 style="font-weight: bolder;">{{SetUpData.SHORTDESCRIPTION}}</h3>
                    </div>
                     <div class="setup-button info-setupbtn">
                        <span class="flex-shrink-0 mr-8 d-block" (click)="changeSetupstatus(SetUpData)"  style="cursor: pointer;"><img
                            src="assets/images/logos/{{(SetUpData.STATUS =='Incomplete' ? 'circle-loading_16px' : 'checked_16px') + '.png'}}"> &nbsp; Mark as complete
                        </span>
                        <button mat-raised-button color="accent"  *ngIf="SetUpData.URL != '' && SetUpData.URL != null && SetUpData.URL != undefined"
                            class="mat-accent redirect-btn"
                            (click)="Router(SetUpData.URL)">Do this now!
                        </button>
                    </div>
                    <div class="setupinfo-content">
                        <div [innerHTML]="SetUpData.LONGDESCRIPTION"></div>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>
