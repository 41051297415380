import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';
// import { id } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-add-rate',
  templateUrl: './add-rate.component.html',
  styleUrls: ['./add-rate.component.scss']
 })
export class AddRateComponent implements OnInit, OnDestroy {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  isNotValid: boolean = false;
  action: any;
  dialogTitle: string;
  labelName:string
  GstName:string
  formAction: string;
  MatterRateForm: FormGroup;
  errorWarningData: any = {};
  rateUser: any = [];
  rateActivity: any = [];
  setAPiURl: string = "";
  closeApiURL: string = "";
  apiURL: string = "";
  rateList: any[] = [];
  GSTTypeData = ['GST Exclusive', 'GST Inclusive', 'GST Free'];
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    private _formBuilder: FormBuilder,
    public _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public PopupData: any,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    public behaviorService: BehaviorService,
    public dialogRefRate: MatDialogRef<AddRateComponent>,
    public tooltipService: TooltipService
  ) {

    localStorage.setItem('istrackid', 'AddRateComponent');
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'AddRateComponent' && result['click'] && (result['data']['user'] || result['data']['activity-rate'])) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'AddRateComponent' && result['click'] && (result['data']['user-rate'] || result['data']['activity-rate'])) {
        this.SaveRates();
      }
    });

  }

  ngOnInit() {
    this.isLoadingResults = true;
    this.MatterRateForm = this._formBuilder.group({
      USERRATEGUID: [''],
      ACTIVITYRATEGUID: [''],
      RATETYPE: [this.PopupData.rateType],
      LINKGUID: [this.PopupData.linkGuid],
      USERID: [''],
      GSTTYPE: ['GST Exclusive'],
      RATEPERHOUR: [''],
      RATEPERUNIT: ['',{ updateOn: 'blur' }],
      RATEPERDAY: [''],
      rateTypeVl: [1],
      userVal: [''],
      RATEPERHOUR_1: ['',{updateOn: 'blur'}],
      RATEPERHOUR_2: ['',{updateOn: 'blur'}],
      RATEPERHOUR_3: ['',{updateOn: 'blur'}],
      RATEPERHOUR_4: ['',{updateOn: 'blur'}],
      RATEPERHOUR_5: ['',{updateOn: 'blur'}],
      RATEPERHOUR_6: ['',{updateOn: 'blur'}],
      RATEPERDAY_1: ['',{updateOn: 'blur'}],
      RATEPERDAY_2: ['',{updateOn: 'blur'}],
      RATEPERDAY_3: ['',{updateOn: 'blur'}],
      RATEPERDAY_4: ['',{updateOn: 'blur'}],
      RATEPERDAY_5: ['',{updateOn: 'blur'}],
      RATEPERDAY_6: ['',{updateOn: 'blur'}]
    });
    this.getUserDrop(this.PopupData.type);
    this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'Rates' }}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        localStorage.setItem('Rates_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        this.rateList = responses.DATA.LOOKUPS;
      }
    });
  }

  /**
   * This function is used to get the Month value.
   */
  getMonthlyVal(i: number){
    const value = this.MatterRateForm.get(`RATEPERHOUR_${i + 1}`).value;
    return value;
  }

  /**
   * This functionis used to get the yearly value
   */
  getYearlyVal(i: number){
    const value = this.MatterRateForm.get(`RATEPERDAY_${i + 1}`).value;
    return value;
  }

  /**
   * This function is used to get the edit data value.
   */
  getEditData(data) {
    let type = data.type;
    let rateType = "";
    let postData: any = "";
    if (type == 1) {
      postData = { USERRATEGUID: this.PopupData.USERRATEGUID };
      rateType = 'user-rate';
    } else if (type == 2 || type == 3) {
      postData = { ACTIVITYRATEGUID: this.PopupData.USERRATEGUID };
      rateType = 'activity-rate';
    };

    let payload :any = {
      "Action": "GetData",
    };
      payload.Filters = postData;
    this._mainAPiServiceService.getSetData(payload, rateType).pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      if (responses.CODE == 200 && responses.STATUS == 'success') {
        if (rateType == 'user-rate') {
          let UserRateData: any = responses.DATA.RECORDS[0];
          const indexData = this.rateUser.findIndex(rates => rates.USERID === UserRateData.USERID);
          this.MatterRateForm.patchValue({
            USERRATEGUID: UserRateData.USERRATEGUID,
            RATETYPE: UserRateData.RATETYPE,
            MATTERGUID: UserRateData.MATTERGUID,
            USERID: UserRateData.USERID,
            userVal: indexData,
            GSTTYPE: UserRateData.GSTTYPE,
            RATEPERHOUR_1: parseFloat(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_1).toFixed(2),
            RATEPERHOUR_2: parseFloat(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_2).toFixed(2),
            RATEPERHOUR_3: parseFloat(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_3).toFixed(2),
            RATEPERHOUR_4: parseFloat(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_4).toFixed(2),
            RATEPERHOUR_5: parseFloat(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_5).toFixed(2),
            RATEPERHOUR_6: parseFloat(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_6).toFixed(2),


            RATEPERDAY_1: parseFloat(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_1).toFixed(2),
            RATEPERDAY_2: parseFloat(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_2).toFixed(2),
            RATEPERDAY_3: parseFloat(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_3).toFixed(2),
            RATEPERDAY_4: parseFloat(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_4).toFixed(2),
            RATEPERDAY_5: parseFloat(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_5).toFixed(2),
            RATEPERDAY_6: parseFloat(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_6).toFixed(2),

          });
        } else if (rateType == 'activity-rate') {
          let ActivityRateData = responses.DATA.RECORDS[0];
          const indexData = this.rateActivity.findIndex(Activity => Activity.ACTIVITYGUID === ActivityRateData.ACTIVITYGUID);
          this.MatterRateForm.patchValue({
            ACTIVITYRATEGUID: ActivityRateData.ACTIVITYRATEGUID,
            RATETYPE: ActivityRateData.RATETYPE,
            MATTERGUID: ActivityRateData.MATTERGUID,
            ACTIVITYGUID: ActivityRateData.ACTIVITYGUID,
            userVal: indexData,
            GSTTYPE: ActivityRateData.GSTTYPE,
            RATEPERUNIT: ActivityRateData.RATEPERUNIT
          });
        }
      }
      this.isLoadingResults = false;
    });
  }

  /**
   * This function is used to get the user drop down data. 
   */
  getUserDrop(type) {
    let postData: any = {};
    if (type == 1) {
      postData = { Action : 'GetData',Filters:{AllData: false, ACTIVE: true }};
      this.apiURL = 'user';
      this.setAPiURl = 'user-rate';
      this.closeApiURL = 'user-rate';
    } else if (type == 2 || type == 3) {
      postData = { "Action": "GetData",
      "Filters": {
        "ACTIVITYTYPE": ""}
      }
     
      postData.Filters.ACTIVITYTYPE = type == 2 ? 'Activity' : 'Sundry';
      this.apiURL = 'activity';
      this.setAPiURl = 'activity-rate';
      this.closeApiURL = 'activity-rate';
    }

    this._mainAPiServiceService.getSetData(postData, this.apiURL).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        if (this.apiURL == 'user') {
          this.rateUser = response.DATA.USERS;
        } else if (this.apiURL == 'activity') {
          this.rateActivity = response.DATA.RECORDS;
        }
        if (this.PopupData.formAction == "new") {
          if(type == 2){
            this.dialogTitle = "Add Activity Override Rates";
            this.formAction = "insert";
            this.isLoadingResults = false;
            this.labelName = "Activity";
          }else if(type == 1){
            this.labelName = "Fee Earner"
            this.dialogTitle = "Add Rates";
            this.formAction = "insert";
            this.isLoadingResults = false;   
          }else if(type == 3){
            this.labelName = "Sundry"
            this.dialogTitle = "Add override sundry rates.";
            this.formAction = "insert";
            this.isLoadingResults = false; 
          }
        } else {
          if(type == 2){
            this.dialogTitle = "Update Activity Override Rates";
            this.formAction = "insert";
            this.isLoadingResults = false;
            this.labelName = "Activity"
          }else if(type == 3){
            this.labelName = "Sundry"
            this.dialogTitle = "Update override sundry rates.";
            this.formAction = "insert";
            this.isLoadingResults = false; 
          }else if(type == 1){
            this.dialogTitle = "Update Rates";
            this.formAction = "update";
            this.labelName = "Fee Earner"
            if(this.PopupData.item) {
              this.isLoadingResults = false;
              const indexData = this.rateUser.findIndex(rates => rates.USERID === this.PopupData.item.USERID);
              this.MatterRateForm.patchValue({
                USERRATEGUID: this.PopupData.item.USERRATEGUID ? this.PopupData.item.USERRATEGUID : '',
                RATETYPE: this.PopupData.item.RATETYPE,
                MATTERGUID: this.PopupData.item.MATTERGUID,
                USERID: this.PopupData.item.USERID,
                userVal: indexData,
                GSTTYPE: this.PopupData.item.GSTTYPE ? this.PopupData.item.GSTTYPE : '',
                RATEPERHOUR_1: parseFloat(this.PopupData.item.RATEPERHOUR_1).toFixed(2),
                RATEPERHOUR_2: parseFloat(this.PopupData.item.RATEPERHOUR_2).toFixed(2),
                RATEPERHOUR_3: parseFloat(this.PopupData.item.RATEPERHOUR_3).toFixed(2),
                RATEPERHOUR_4: parseFloat(this.PopupData.item.RATEPERHOUR_4).toFixed(2),
                RATEPERHOUR_5: parseFloat(this.PopupData.item.RATEPERHOUR_5).toFixed(2),
                RATEPERHOUR_6: parseFloat(this.PopupData.item.RATEPERHOUR_6).toFixed(2),
                RATEPERDAY_1: parseFloat(this.PopupData.item.RATEPERDAY_1).toFixed(2),
                RATEPERDAY_2: parseFloat(this.PopupData.item.RATEPERDAY_2).toFixed(2),
                RATEPERDAY_3: parseFloat(this.PopupData.item.RATEPERDAY_3).toFixed(2),
                RATEPERDAY_4: parseFloat(this.PopupData.item.RATEPERDAY_4).toFixed(2),
                RATEPERDAY_5: parseFloat(this.PopupData.item.RATEPERDAY_5).toFixed(2),
                RATEPERDAY_6: parseFloat(this.PopupData.item.RATEPERDAY_6).toFixed(2)
              });
            } else {
              this.getEditData(this.PopupData);
            }
          }
          
        }
      }
    }, err => {
      this.isLoadingResults = false;
    });
  }
  get f() {
    return this.MatterRateForm.controls;
  }

  /**
   * This function is used to get the selected label
   */
  getSelectedLabel() {
    let index = this.f.userVal.value;
    return this.rateUser[index];
  }

  /**
   * This function is used to save the rates.
   */
  SaveRates() {
    this.isspiner = true;
    let details: any = {
      RATETYPE: this.f.RATETYPE.value,
      LINKGUID: this.f.LINKGUID.value,
      GSTTYPE: this.f.GSTTYPE.value,
    };
    if (this.PopupData.type == 1) {
      details.USERID = this.f.USERID.value;
      details.RATEPERHOUR_1 = this.f.RATEPERHOUR_1.value;
      details.RATEPERHOUR_2 = this.f.RATEPERHOUR_2.value;
      details.RATEPERHOUR_3 = this.f.RATEPERHOUR_3.value;
      details.RATEPERHOUR_4 = this.f.RATEPERHOUR_4.value;
      details.RATEPERHOUR_5 = this.f.RATEPERHOUR_5.value;
      details.RATEPERHOUR_6 = this.f.RATEPERHOUR_6.value;

      details.RATEPERDAY_1 = this.f.RATEPERDAY_1.value;
      details.RATEPERDAY_2 = this.f.RATEPERDAY_2.value;
      details.RATEPERDAY_3 = this.f.RATEPERDAY_3.value;
      details.RATEPERDAY_4 = this.f.RATEPERDAY_4.value;
      details.RATEPERDAY_5 = this.f.RATEPERDAY_5.value;
      details.RATEPERDAY_6 = this.f.RATEPERDAY_6.value;

    } else {
      details.ACTIVITYGUID = this.f.USERID.value;
      details.RATEPERUNIT = this.f.RATEPERUNIT.value;
    }
    if (this.PopupData.isEditRate) {
      details.APIUrl = this.setAPiURl;
      details.type = this.PopupData.type;
      this.dialogRefRate.close(details);
    } else {
      if (this.formAction != 'new' && this.PopupData.type == 1) {
        details.USERRATEGUID = this.f.USERRATEGUID.value;
      } else if (this.formAction != 'new' && this.PopupData.type != 1) {
        details.ACTIVITYRATEGUID = this.f.ACTIVITYRATEGUID.value;
      }

      let matterPostData: any = { ACTION: this.formAction, VALIDATEONLY: true, Data: details };

      this._mainAPiServiceService.getSetData(matterPostData, this.setAPiURl).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        matterPostData.VALIDATEONLY = true;
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRefRate.close(false);
        }
      }, error => {
        this.isspiner = false;
        this.toastr.error(error);
      });
    }
  }

  /**
   * This function is used to cbeck the validation data value
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveRateData(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveRateData(details);
    }
  }

  /**
   * This function is used to save the rates data
   */
  saveRateData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, this.setAPiURl).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.formAction != 'new') {
          this.toastr.success('Rate update successfully');
        } else {
          this.toastr.success('Rate save successfully');
        }
        this.dialogRefRate.close(this.closeApiURL);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRefRate.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
      this.dialogRefRate.close(true);

    });
  }

  /**
   * This function is used to active the class type
   */
  ClasstypeActivity(event) {
    const activityData = this.rateActivity[event];
    this.MatterRateForm.controls['USERID'].setValue(activityData.ACTIVITYGUID);
    if (activityData.GSTTYPE) {
      this.MatterRateForm.controls['GSTTYPE'].setValue(this.GSTTypeData[activityData.GSTTYPE]);
    }
    if (activityData.RATEPERUNIT) {
      this.MatterRateForm.controls['RATEPERUNIT'].setValue(activityData.RATEPERUNIT);
    }

  }

  /**
   * This function is used to get the class type data value.
   */
  Classtype(event) {
    const userDat: any = this.rateUser[event];
    if (userDat.USERID) {
      this.isNotValid = false;
      this.MatterRateForm.controls['USERID'].setValue(userDat.USERID);
      this.MatterRateForm.controls['GSTTYPE'].setValue(this.GSTTypeData[userDat.GSTTYPE]);
      if (userDat.RATEPERHOUR_GROUP && userDat.RATEPERDAY_GROUP) {
        this.MatterRateForm.patchValue({
          RATEPERHOUR_1: Number(parseFloat(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_1).toFixed(2)),
          RATEPERHOUR_2: Number(parseFloat(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_2).toFixed(2)),
          RATEPERHOUR_3: Number(parseFloat(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_3).toFixed(2)),
          RATEPERHOUR_4: Number(parseFloat(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_4).toFixed(2)),
          RATEPERHOUR_5: Number(parseFloat(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_5).toFixed(2)),
          RATEPERHOUR_6: Number(parseFloat(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_6).toFixed(2)),
          RATEPERDAY_1: Number(parseFloat(userDat.RATEPERDAY_GROUP.RATEPERDAY_1).toFixed(2)),
          RATEPERDAY_2: Number(parseFloat(userDat.RATEPERDAY_GROUP.RATEPERDAY_2).toFixed(2)),
          RATEPERDAY_3: Number(parseFloat(userDat.RATEPERDAY_GROUP.RATEPERDAY_3).toFixed(2)),
          RATEPERDAY_4: Number(parseFloat(userDat.RATEPERDAY_GROUP.RATEPERDAY_4).toFixed(2)),
          RATEPERDAY_5: Number(parseFloat(userDat.RATEPERDAY_GROUP.RATEPERDAY_5).toFixed(2)),
          RATEPERDAY_6: Number(parseFloat(userDat.RATEPERDAY_GROUP.RATEPERDAY_6).toFixed(2)),
        });
      }
    } else {
      this.isNotValid = true;
    }
  }

  /**
   * This function is used to get the selected
   */
  getSelectedActivityLabel(){
    let index = this.f.userVal.value;
    return this.rateActivity[index];
  }


  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }
}
