<div class="one" [formGroup]="DairyForm" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-radio-group
            [ngClass]="{'has-error':errorWarningData.Error?.FREQUENCY,'has-warning':errorWarningData.Warning?.FREQUENCY}"
            name="RedioChnage" [(ngModel)]="abc" formControlName="RedioChnage" aria-label="Select an option" fxFlex="20"
            class="pr-4" (ngModelChange)="radiobtnclick()">
            <mat-radio-button value="0">Once Only</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group
            [ngClass]="{'has-error':errorWarningData.Error?.FREQUENCY,'has-warning':errorWarningData.Warning?.FREQUENCY}"
            [(ngModel)]="abc" name="RedioChnage" formControlName="RedioChnage" aria-label="Select an option" fxFlex="20"
            class="pr-4" (ngModelChange)="radiobtnclick()">
            <mat-radio-button value="1">Daily</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group
            [ngClass]="{'has-error':errorWarningData.Error?.FREQUENCY,'has-warning':errorWarningData.Warning?.FREQUENCY}"
            [(ngModel)]="abc" name="RedioChnage" formControlName="RedioChnage" aria-label="Select an option" fxFlex="20"
            class="pr-4" (ngModelChange)="radiobtnclick()">
            <mat-radio-button value="2">Weekly</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group
            [ngClass]="{'has-error':errorWarningData.Error?.FREQUENCY,'has-warning':errorWarningData.Warning?.FREQUENCY}"
            [(ngModel)]="abc" name="RedioChnage" formControlName="RedioChnage" aria-label="Select an option" fxFlex="20"
            class="pr-4" (ngModelChange)="radiobtnclick()">
            <mat-radio-button value="3">Monthly</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group
            [ngClass]="{'has-error':errorWarningData.Error?.FREQUENCY,'has-warning':errorWarningData.Warning?.FREQUENCY}"
            [(ngModel)]="abc" name="RedioChnage" formControlName="RedioChnage" aria-label="Select an option" fxFlex="20"
            class="pr-4" (ngModelChange)="radiobtnclick()">
            <mat-radio-button value="4">Yearly</mat-radio-button>
        </mat-radio-group>
    </div>
    <!-- Once   class="pr-4-->
    <div class="Once" fxLayout="column" *ngIf="abc =='0'">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <p id="Once">Pattern Details<br>The appointment will occur only once</p>
        </div>
    </div>
    <!-- Daily-->
    <div class="Daily" fxLayout="column" *ngIf="abc =='1'">
        <div fxLayout="row" fxLayoutAlign="start">
            <p id="Daily">Pattern Details</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field
                [ngClass]="{'has-error':errorWarningData.Error?.DAYFREQUENCY,'has-warning':errorWarningData.Warning?.DAYFREQUENCY}"
                class="example p-4 " fxFlex="100" appearance="outline">
                <mat-label>Every</mat-label>
                <input matInput type="number" formControlName="Every">
            </mat-form-field>
        </div>
    </div>
    <!-- Weekly-->
    <div class="Weekly" fxLayout="column" *ngIf="abc =='2'">
        <div fxLayout="row" fxLayoutAlign="start">
            <p id="Weekly">Pattern Details</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field
                [ngClass]="{'has-error':errorWarningData.Error?.WEEKFREQUENCY,'has-warning':errorWarningData.Warning?.WEEKFREQUENCY}"
                class="example p-4" fxFlex="100" appearance="outline">
                <mat-label>Every</mat-label>
                <input matInput type="number" formControlName="EveryWeekly">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <div *ngFor="let data of WeekDay">
                <mat-checkbox (change)="weekmonth($event)" formControlName="dayweek" value="{{data.value}}"
                    fxFlex="14.2">{{data.day}}</mat-checkbox>
            </div>
            <!-- {{ordersData[i].name}} -->
            <!-- <label formArrayName="orders" *ngFor="let order of DairyForm.controls; let i = index">
                <mat-checkbox fxFlex="14.2"  formControlName ="i">{{order}}</mat-checkbox>
            </label> -->
        </div>
    </div>
    <!-- Monthly-->
    <div class="Monthly" fxLayout="column" *ngIf="abc =='3'">
        <div fxLayout="row" fxLayoutAlign="start">
            <p id="Monthly">Pattern Details</p>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHOPTIONS,'has-warning':errorWarningData.Warning?.MONTHOPTIONS}"
                class="example p-4" fxFlex="20" appearance="outline">
                <mat-label>Every</mat-label>
                <input matInput type="number" formControlName="EveryMonthly">
            </mat-form-field>

            <mat-radio-group
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHOPTIONS,'has-warning':errorWarningData.Warning?.MONTHOPTIONS}"
                name="MONTHOPTIONS" formControlName="MONTHOPTIONS" (ngModelChange)="radiobtnday()"
                aria-label="Select an option" fxFlex="20" class="pr-4">
                <mat-radio-button value="0">The First Day</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHOPTIONS,'has-warning':errorWarningData.Warning?.MONTHOPTIONS}"
                name="MONTHOPTIONS" formControlName="MONTHOPTIONS" (ngModelChange)="radiobtnday()"
                aria-label="Select an option" fxFlex="20" class="pr-4">
                <mat-radio-button value="1">The Last Day</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHOPTIONS,'has-warning':errorWarningData.Warning?.MONTHOPTIONS}"
                name="MONTHOPTIONS" formControlName="MONTHOPTIONS" (ngModelChange)="radiobtnday()"
                aria-label="Select an option" fxFlex="20" class="pr-4">
                <mat-radio-button value="3">The Day</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHOPTIONS,'has-warning':errorWarningData.Warning?.MONTHOPTIONS}"
                name="MONTHOPTIONS" formControlName="MONTHOPTIONS" (ngModelChange)="radiobtnday()"
                aria-label="Select an option" fxFlex="20" class="pr-4">
                <mat-radio-button value="4">The</mat-radio-button>
            </mat-radio-group>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHOPTIONDAY,'has-warning':errorWarningData.Warning?.MONTHOPTIONDAY}"
                class="example p-4" fxFlex="33" appearance="outline">
                <input (keyup)="EveryDaypress($event);" matInput type="number" [(ngModel)]="Everydayval"
                    formControlName="EveryDay">
            </mat-form-field>

            <mat-form-field
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHWHICHWEEK,'has-warning':errorWarningData.Warning?.MONTHWHICHWEEK}"
                appearance="outline" fxFlex="33" class="pr-4">
                <mat-select formControlName="countvalue" (selectionChange)="whichweek()">
                    <mat-option value="First" selected>First</mat-option>
                    <mat-option value="Second">Second</mat-option>
                    <mat-option value="Third">Third</mat-option>
                    <mat-option value="Fourth">Fourth</mat-option>
                    <mat-option value="Last">Last</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                [ngClass]="{'has-error':errorWarningData.Error?.MONTHWHICHDAY,'has-warning':errorWarningData.Warning?.MONTHWHICHDAY}"
                appearance="outline" fxFlex="34" class="pr-4">
                <mat-select formControlName="DaySelect" (selectionChange)="whichday()">
                    <mat-option value="Sun" selected>Sun</mat-option>
                    <mat-option value="Mon">Mon</mat-option>
                    <mat-option value="Tue">Tue</mat-option>
                    <mat-option value="Wed">Wed</mat-option>
                    <mat-option value="Thu">Thu</mat-option>
                    <mat-option value="Fri">Fri</mat-option>
                    <mat-option value="Sat">Sat</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- Yearly-->
    <div class="Yearly" fxLayout="column" *ngIf="abc =='4'">
        <p id="Yearly">Pattern Details <br> The recurrances wil occur on the anniversary of the appointment</p>
    </div>

    <!-- Last Section Start -->

    <div class="LastSection" fxLayout="column"
        *ngIf="RedioBtnValue ==='Daily' || RedioBtnValue ==='Weekly'|| RedioBtnValue ==='Monthly' || RedioBtnValue ==='Yearly'">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-radio-group aria-label="Select an option" fxFlex="20" class="radiobtn_size pr-4"
                (ngModelChange)="radiobtndate()" name="RedioDate" formControlName="RedioDate">
                <mat-radio-button value="End">No End Date</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group aria-label="Select an option" fxFlex="20" class="radiobtn_size pr-4"
                (ngModelChange)="radiobtndate()" name="RedioDate" formControlName="RedioDate">
                <mat-radio-button value="EndBy">End By</mat-radio-button>
            </mat-radio-group>

            <mat-form-field appearance="outline" class="example" fxFlex="60" class="pr-4">
                <mat-label>Date</mat-label>
                <input (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" matInput
                    formControlName="EndDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <!-- Last Section End -->
</div>