import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-mortgage-finance',
    templateUrl: './mortgage-finance.component.html',
    styleUrls: ['./mortgage-finance.component.scss']
})
export class MortgageFinanceComponent implements OnInit, OnDestroy {

    // @Input() matterdetailForm: FormGroup;
    // @Input() errorWarningData: any;
    MortageFinanceForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        public tooltipService: TooltipService
    ) {
        this.initFormData();
    }

    /**
     * This function is used to initiate the form data value
     */
    initFormData() {
        this.MortageFinanceForm = this._formBuilder.group({
            MATTERMORTGAGEGUID: [''],
            MATTERGUID: [''],
            MATTERPROPERTYGUID: [''],
            PRINCIPALADVANCED: [''],
            INTERESTRATE: ['', {updateOn: 'blur'}],
            COMMENCEMENTDATE: [''],
            COMMENCEMENTDATETEXTM: [''],
            DISCHARGEDATE: [''],
            DISCHARGEDATETEXTM: [''],
            SECURITYPROPERTY: [''],
            EXPIRATIONDATE: [''],
            ExpirationDateTxtM: ['']
        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MortageFinanceForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Mortgage Finance" && result.action == 'setData') {
                    this.SaveMortageFinance();
                } else if (result.classType == "Mortgage Finance" && result.action == 'deleteData') {
                    this.DeleteMortageFinance();
                }
            }
        });

        if (this.action === 'edit') {
            this.mapMortgageFinDataToFields();
        }

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == true) {
                this.mapMortgageFinDataToFields();
            }
        })



    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();

    }

    /**
     * this function is used to get the fields data values
     */
    mapMortgageFinDataToFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-mortgage').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterMortageFinanceData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterMortageFinanceData) {
                        this.MortageFinanceForm.patchValue({
                            MATTERGUID: MatterMortageFinanceData.MATTERGUID,
                            MATTERMORTGAGEGUID: MatterMortageFinanceData.MATTERMORTGAGEGUID,
                            MATTERPROPERTYGUID: MatterMortageFinanceData.MATTERPROPERTYGUID,
                            PRINCIPALADVANCED: MatterMortageFinanceData.PRINCIPALADVANCED,
                            INTERESTRATE: MatterMortageFinanceData.INTERESTRATE,
                            COMMENCEMENTDATE: MatterMortageFinanceData.COMMENCEMENTDATE,
                            DISCHARGEDATE: MatterMortageFinanceData.DISCHARGEDATE,
                            SECURITYPROPERTY: MatterMortageFinanceData.SECURITYPROPERTY,
                            EXPIRATIONDATE: MatterMortageFinanceData.EXPIRATIONDATE
                        });

                        if (MatterMortageFinanceData.COMMENCEMENTDATE) {
                            let TEMPMORTAGEFINANCECOMMENCEMENTDATE = MatterMortageFinanceData.COMMENCEMENTDATE.split("/");
                            this.MortageFinanceForm.patchValue({
                                COMMENCEMENTDATETEXTM: new Date(TEMPMORTAGEFINANCECOMMENCEMENTDATE[1] + '/' + TEMPMORTAGEFINANCECOMMENCEMENTDATE[0] + '/' + TEMPMORTAGEFINANCECOMMENCEMENTDATE[2]),
                                COMMENCEMENTDATE: MatterMortageFinanceData.COMMENCEMENTDATE
                            });
                        }
                        if (MatterMortageFinanceData.DISCHARGEDATE) {
                            let TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE = MatterMortageFinanceData.DISCHARGEDATE.split("/");
                            this.MortageFinanceForm.patchValue({
                                DISCHARGEDATETEXTM: new Date(TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[1] + '/' + TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[0] + '/' + TEMPDEFAULTMORTAGEFINANCEDISCHARGEDATE[2]),
                                DISCHARGEDATE: MatterMortageFinanceData.DISCHARGEDATE
                            });
                        }
                        if (MatterMortageFinanceData.EXPIRATIONDATE) {
                            let TEMPEXPIRATIONDATE = MatterMortageFinanceData.EXPIRATIONDATE.split("/");
                            this.MortageFinanceForm.patchValue({
                                ExpirationDateTxtM: new Date(TEMPEXPIRATIONDATE[1] + '/' + TEMPEXPIRATIONDATE[0] + '/' + TEMPEXPIRATIONDATE[2]),
                                EXPIRATIONDATE: MatterMortageFinanceData.EXPIRATIONDATE
                            });
                        }
                    }
                }
            });
        }
    }

    /**
     * This function is used to save the finance.
     */
    SaveMortageFinance() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MortageFinanceForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERMORTGAGEGUID;
            // delete formData.MATTERGUID;
        }
        delete formData.COMMENCEMENTDATETEXTM;
        delete formData.DISCHARGEDATETEXTM;
        delete formData.ExpirationDateTxtM;

        const formAction = (formData.MATTERMORTGAGEGUID) ? 'Update' : 'Insert';
        const FamilyLawData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(FamilyLawData, 'matter-mortgage').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, FamilyLawData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation data value
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMortageFinanceAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMortageFinanceAfterVAlidation(details);

        }
    }

    /**
     * This function is used to save the Finance After the validation get the data value. 
     */
    SaveMortageFinanceAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-mortgage').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * this function is used to get the Commencement Date click.
     */
    CommencementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MortageFinanceForm.controls['COMMENCEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * this function is used to get the Disacharge Date click.
     */
    DisachargeDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MortageFinanceForm.controls['DISCHARGEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * this function is used to get the Expirty Date click.
     */
    ExpirtyDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MortageFinanceForm.controls['EXPIRATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

      /**
     * this function is used to Delete Mortage Finance.
     */
    DeleteMortageFinance() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-mortgage').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterMortageFinanceData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERMORTGAGEGUID: MatterMortageFinanceData.MATTERMORTGAGEGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-mortgage').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

    /**
     * This function is used to get the text data
     */
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }

}
