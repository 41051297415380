import { BehaviorService } from '@_services/Behavior.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toFixedAmount' })
export class ToFixedAmountPipe implements PipeTransform {
    CurrenctSymbol: any = ''
    constructor(private behaviorService: BehaviorService,) {

        /**
         * Use this behaviour for the Currency Symbol
         */
        this.behaviorService.CURRENCYSYMBOL$.subscribe(res => {
            if (res && res !== '') {
                this.CurrenctSymbol = res
            }
        })
    }

    /**
     * This function is used to transform the data.
     */
    transform(value: any, args: any = false): any {
        this.CurrenctSymbol = localStorage.getItem('set_active_CURRENCYSYMBOL') !== undefined || 'undefined' ? JSON.parse(localStorage.getItem('set_active_CURRENCYSYMBOL')) : '';
        let tempVal = value;

        if ((typeof value == "string" || typeof value == "undefined") && !value) {
            tempVal = 0;
        }
        if (args) {
            if (tempVal != 0) {
                let tem: any = parseFloat(tempVal).toFixed(2);
                return this.CurrenctSymbol + this.addCommas(tem);
            } else {
                if (tempVal == 0) {
                    let tem: any = parseFloat(tempVal).toFixed(2);
                    return this.CurrenctSymbol + this.addCommas(tem);
                } else {
                    return;
                }
            }
        } else {

            return value;
        }
    }
    addCommas(nStr) {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }

}
