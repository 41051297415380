<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div write_off_time_entry_popup select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch"> Write Off Item
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="writeOffTimerForm" id="writeOffTimerForm" [formGroup]="writeOffTimerForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div class="one mb-24" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <div fxFlex="100" class="timer-time-div"> <b>{{matterShortName}}</b> </div><br>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field fxFlex="25" class="pr-4" appearance="outline">
                                <mat-label>Date</mat-label>
                                <input matInput placeholder="Choose a date" readonly formControlName="ITEMDATE" readonly>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="24" class="pr-4" appearance="outline">
                                <mat-label>Time of day</mat-label>
                                <input matInput placeholder="Time of day" readonly formControlName="ITEMTIME">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMTIME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMTIME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="24" appearance="outline" class="pr-4">
                                <mat-label>Fee Earner</mat-label>
                                <input matInput placeholder="Fee Earner" readonly formControlName="FEEEARNER">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEEARNER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FEEEARNER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="24" appearance="outline" class="pr-4">
                                <mat-label>Duration</mat-label>
                                <input matInput placeholder="Duration" readonly formControlName="QUANTITY">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field fxFlex="32" appearance="outline" class="pr-4">
                                <mat-label>Type</mat-label>
                                <input matInput placeholder="Type" readonly formControlName="QUANTITYTYPE">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITYTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITYTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="32" class="pr-4" appearance="outline">
                                <mat-label>Charge Ex-GST</mat-label>
                                <input matInput currencyMask placeholder="Charge Ex-GST" readonly formControlName="PRICE">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRICE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="33" class="pr-4" appearance="outline">
                                <mat-label>Charge In-GST</mat-label>
                                <input matInput currencyMask placeholder="Charge In-GST" readonly formControlName="PRICEINCGST">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICEINCGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRICEINCGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field fxFlex="49" appearance="outline" class="pr-4">
                                <mat-label>Invoice Text</mat-label>
                                <input matInput placeholder="Invoice Text" readonly formControlName="ADDITIONALTEXTSELECT">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALTEXTSELECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDITIONALTEXTSELECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="48" class="pr-4" appearance="outline">
                                <mat-label>Notes</mat-label>
                                <input matInput placeholder="Notes" readonly formControlName="COMMENT">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="value_label_parent">
                            <div fxFlex="30" class="label_width">
                                Amount to Write Off
                            </div>
                            <mat-form-field appearance="outline" fxFlex="20" class="value_width pr-4">
                                <!-- <mat-label>Amount to Write Off</mat-label> -->
                                <input type="text" formControlName="AmmountWirteOff" maxlength="" (focusout)="calcWriteOff('wo')" matInput placeholder="Amount to Write Off">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTTOWRITEOFF?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNTTOWRITEOFF?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <div fxFlex="5" class="label_width"> </div>
                            <mat-form-field appearance="outline" fxFlex="20" class="value_width pr-4">
                                <mat-label>Charge Ex GST</mat-label>
                                <input [disabled]="true" class="sideText" matInput readonly type="number" formControlName="WirteOffExAmount" placeholder="Amount to Write Off">
                                <span class="currency_sign" matPrefix>$&nbsp;</span>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHARGEEXGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CHARGEEXGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20" class="value_width pr-4">
                                <mat-label> Charge Inc-GST</mat-label>
                                <input [disabled]="true" class="sideText" matInput readonly type="number" formControlName="WirteOffInAmount" placeholder="Amount to Write Off">
                                <span class="currency_sign" matPrefix>$&nbsp;</span>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHARGEINCGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CHARGEINCGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div class="value_label_parent">
                            <div fxFlex="30" class="label_width">
                                Amount Remaining
                            </div>
                            <mat-form-field appearance="outline" fxFlex="20" class="value_width pr-4">
                                <!-- <mat-label>Amount Remainiiiing</mat-label> -->
                                <input type="text" (focusout)="calcWriteOff('re')" formControlName="AmmountRemaing" matInput placeholder="Amount Remaining">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTREMAINING?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.AMOUNTREMAINING?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <div fxFlex="5" class="label_width"> </div>
                            <mat-form-field appearance="outline" fxFlex="20" class="value_width pr-4">
                                <mat-label>Charge Ex GST</mat-label>
                                <input [disabled]="true" class="sideText" matInput readonly type="number" formControlName="RemainingExAmount" placeholder="Amount Remainiiiing">
                                <span class="currency_sign" matPrefix>$&nbsp;</span>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHARGEEXGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CHARGEEXGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20" class="value_width pr-4">
                                <mat-label> Charge Inc-GST</mat-label>
                                <input [disabled]="true" class="sideText" matInput readonly type="number" formControlName="RemainingInAmount" placeholder="Amount Remainiiiing">
                                <span class="currency_sign" matPrefix>$&nbsp;</span>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHARGEINCGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CHARGEINCGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" class="mat-accent" (click)="SaveClickTimeEntry()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>