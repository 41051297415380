import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ResizeEvent } from 'angular-resizable-element';
import { GenerateDocumentPopupComponent } from '../generate-document-popup/generate-document-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-court-forms',
  templateUrl: './court-forms.component.html',
  styleUrls: ['./court-forms.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CourtFormsComponent implements OnInit, OnDestroy {
  @Input() popupurl
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  Title = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;
  CourtForm: FormGroup;
  isLoadingResults: boolean = false;
  TableData: any = [];
  courtFormdata: any = [];
  displayedColumns: any = ['TYPEICON', 'DESCRIPTION'];
  mainDataArray: any = [];
  isEmpty: boolean = true;
  keyValue: any;
  stateSelect: any;
  formDataArray: any = [];
  highlightedRows: any;
  whichFormSelect: any='';
  search: any;
  currentRow: any;
  pageSize: any;
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  constructor(
    private _formBuilder: FormBuilder,
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    private router: Router,
    private behaviorService: BehaviorService,
    private SortingbehaviorService: SortingBehaviourService
  ) {
    // matter Template popup Search control.
    this.sub = this.behaviorService.MatterTemplatePopupSearch$.subscribe((res)=>{
      if(res){
        if(res.type == 'courtForms'){
          //this.LoadData({ SEARCH: res.search });
          this.LoadData({ "TemplateType": "Court Form", "AREAOFLAWID": this.whichFormSelect, 'Search': res.search });
        }
      }
    });

    this.sub2 = this.behaviorService.MatterTemplatePopupReload$.subscribe((res)=>{
      if(res){
        if(res.type == 'courtForms'){
          //this.LoadData({ SEARCH: res.search });
          this.courtFromRefresh()
        }
      }
    });

    this.behaviorService.precedentsStateData$.subscribe((result) => {
      if (result) {
        this.highlightedRows = 0;
        this.stateSelect = result;
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if (this.sortingDefaultState) {
      this.sortactive = this.sortingDefaultState.active;
      this.sortDirection = this.sortingDefaultState.direction;
    } else {
      this.sortactive = "";
      this.sortDirection = "";
    }
    this.behaviorService.resizeTableForAllView();
    const behaviorService = this.behaviorService;
    $(window).resize(function () {
      behaviorService.resizeTableForAllView();
    });
    this.CourtForm = this._formBuilder.group({
      Filter: [''],
      search: ['']
    });
    this.LoadData({ "TEMPLATETYPE": "Court Area of Law" });
    // this.LoadData({"TemplateType":"Court Form","AREAOFLAWID":"75"});

  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to courtFrom Refresh
   */
  courtFromRefresh(){
    this.LoadData({ "TEMPLATETYPE": "Court Area of Law" });
  }

  /**
   * This function is used to Load the data value
   */
  LoadData(passdata) {
    this.isLoadingResults = true;
    this.sub1 = this._mainAPiServiceService.getSetData(passdata, 'TemplateList ').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        if (response.DATA.AREASOFLAW) {
            this.mainDataArray = response.DATA.AREASOFLAW;
            const keys = Object.keys(response.DATA.AREASOFLAW);
            this.stateSelect = keys[0];
        //  this.stateSelect = 'Federal';
            this.stateClassChange(this.stateSelect);
            this.whichFormSelect = '90';
            this.LoadData({ "TemplateType": "Court Form", "AREAOFLAWID": '90' });
          this.isLoadingResults = false;
        } else {
          if (response.DATA.FORMS.length === 0) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
          this.formDataArray = response.DATA.FORMS;
          this.courtFormdata = new MatTableDataSource(this.formDataArray);
          this.highlightedRows = 0;
          this.courtFormdata.paginator = this.paginator;
          this.courtFormdata.sort = this.sort;
          this.isLoadingResults = false;
        }
        this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
      }
    }, err => {

      this.toastr.error(err);
      this.isLoadingResults = false;
    });
    // this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }

  /**
   * This function is used to click on the form
   */
  formClick(row) {
    this.whichFormSelect = row.AREAOFLAWID;
    this.LoadData({ "TemplateType": "Court Form", "AREAOFLAWID": row.AREAOFLAWID });
  }

  /**
   * This fiunction is used to row click
   */
  rowClick(row) {
    this.currentRow = row;
    this.behaviorService.courtFormData(row);
  }

  /**
   * This function is used to stateclass change data
   */
  stateClassChange(val) {
    this.highlightedRows = 0;
    //  this.courtFormdata = new MatTableDataSource(this.mainDataArray[val]);
    //    this.courtFormdata.paginator = this.paginator;
    //    this.courtFormdata.sort = this.sort;
  }
  
  FilterSearch(val) {

  }

  /**
   * This function is used to onsearch the data value
   */
  onSearch(searchFilter) {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
      this.LoadData({ "TemplateType": "Court Form", "AREAOFLAWID": this.whichFormSelect, 'Search': this.search });
    }
  }

  /**
   * THis function is used to double click on the row
   */
  doubleClick(row) {

    if (this.router.url == "/create-document/court-forms-invoice-template" ) {
      let invoiceGUid = localStorage.getItem('edit_invoice_id');
      let passdata = { 'FORMID': this.currentRow.FORMID, 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Court Form", 'nameShow': row.DESCRIPTION }
      this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/create-document/court-forms-matter-template") {
      let matterData = JSON.parse(localStorage.getItem('set_active_matters'));
      let passdata = { 'FORMID': this.currentRow.FORMID, 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": "Template", "Type": "Court Form", 'nameShow': row.DESCRIPTION }
      this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/create-document/court-forms-receive-money-template") {
      let ReceiptData = JSON.parse(localStorage.getItem('receiptData'));
      let passdata = { 'FORMID': this.currentRow.FORMID, 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": "Template", "Type": "Court Form", 'nameShow': row.DESCRIPTION }
      this.ForDocDialogOpen(passdata);
    } else if (this.router.url == "/create-document/court-forms-contact-template") {
      let ContactGuID = localStorage.getItem('contactGuid');
      let passdata = { 'FORMID': this.currentRow.FORMID, 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": "Template", "Type": "Court Form", 'nameShow': row.DESCRIPTION }
      this.ForDocDialogOpen(passdata);
    }
  }

  /**
   * This function is used to for open the doc dialog
   */
  ForDocDialogOpen(passdata) {
    const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: passdata });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // localStorage.setItem('set_active_matters', JSON.stringify(result));
      }
    });
  }

  /**
   * This function is used to On resize end
   */
  onResizeEnd(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
    }
  }

  /**
   * This function is used to change the pagination data
   */
  onPaginateChange(event) {
    this.pageSize.template_list = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }
}
