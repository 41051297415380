import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorService } from './Behavior.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Injectable({
    providedIn: 'root'
})
export class GetFavouriteService {

    constructor(private http: HttpClient, private behaviorSubject: BehaviorService) { }

    /**
     * This function is used to get the favourite list
     * @param postdata -post data value
     * @returns -data value
     */
    GetFavourite(postdata) {
        return this.http.post<any>(environment.APIEndpointDeliverable + 'favourite', postdata);
    }

    /**
     * This function is used to set the favourite
     * @param pagefavourite -favourite data
     * @returns data value
     */
    setFavourite(pagefavourite: any) {

        let fevIconList = JSON.parse(localStorage.getItem('usersFavouritelist'));
        if (fevIconList) {

            fevIconList.DATA.RECORDS = pagefavourite.RECORDS;
            const favMenuList = JSON.stringify(fevIconList);

            if (pagefavourite.FAVOURITESET !== "matter dashboard" && pagefavourite.FAVOURITESET !== "fee earner dashboard") {
                localStorage.setItem('usersFavouritelist', favMenuList)
            };

            this.behaviorSubject.setFavouriteItem(favMenuList);
            // this.cdf.detectChanges();

        }

        let guid: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        //pagefavourite.SESSIONTOKEN = guid.SESSIONTOKEN;
        //pagefavourite.USERGUID = guid.UserGuid;
        //pagefavourite.ACTION = 'replace';
        let FAVOURITES = pagefavourite.RECORDS
        let newpagefavourite = { ACTION: 'replace', SESSIONTOKEN: guid.SESSIONTOKEN, DATA: { USERGUID: guid.UserGuid, FAVOURITESET: pagefavourite.FAVOURITESET, FAVOURITES } }
        return this.http.post<any>(environment.APIEndpointDeliverable + 'favourite', newpagefavourite);
    }

    /**
     * This function is used to set the favourite firm
     * @param pagefavourite -page favourite irm dashboard
     * @returns data value
     */
    setFavouriteFirm(pagefavourite: any) {
        return this.http.post<any>(environment.APIEndpointDeliverable + 'favourite', pagefavourite);
    }

}
