import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatterDialogComponent } from './../../../time-entries/matter-dialog/matter-dialog.component';
import * as $ from 'jquery';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, tap } from 'rxjs';
import { BehaviorService } from '@_services/Behavior.service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  @Input() DairyForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() Action: any;
  @Input() mattername: any;
  @Input() origin: any;
    @Input() SelectedMatterData: any;
    activeMatter:any
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  filter = true;
  hideicon: boolean;
  searchData: any[];
  matterlist: any[];
  currentUserData : any = JSON.parse(localStorage.getItem("currentUser"));
 // mattername: string[];
  searchMatter: Subscription;
  exportdatavalue: any;
  selectedData: any = [];
  private searchTerms = new Subject<string>();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  subscription: Subscription;
  @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
  constructor(
    public MatDialog: MatDialog,public _mainAPiServiceService: MainAPiServiceService,private router: Router,
      public tooltipService: TooltipService,
      private behaviorService: BehaviorService,
  ) {
      /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */
      this.behaviorService.clickMaterFromTimeLine$
          .pipe(
              tap(result => {
                  if (result) {
                      this.activeMatter = result;
                  } else {
                      this.activeMatter = JSON.parse(localStorage.getItem('set_active_matters')) || result;

                  }
              })
          )
          .subscribe();

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    setTimeout(() => {
      if(this.Action == "new"){

      try {
        if(!this.mattername || this.mattername[0]=='') {
            this.mattername=[]
        }
      }  catch(err) {
      }


        // this.mattername=[]
      }else{
        // this.mattername=[this.Mattername]
        // this.mattername=((!this.Mattername || this.Mattername[0]=='')?[]:[this.Mattername])

        try {
          if(!this.mattername || this.mattername[0]=='') {
              this.mattername=[]
          }
        }  catch(err) {
        }

      }
    }, 2000);


    this.DairyForm.controls['Category'].setValue('Other');
    this.ChnageCategory('Other');
    if(this.origin == 'matter-details-new') {
      this.selectMatter();
    }

    this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
    ).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.exportdatavalue = response.DATA
        this.searchData = response.DATA.RECORDS;
        this.matterlist = this.searchData;
      }
    });
  }
  get f() {
    return this.DairyForm.controls;
  }
  //SelectMatter

  Beforstartchange(val) {
    if (this.f.Beforestart.value == true) {

    } else {

    }
  }

  //CheckReminder
  CheckReminder(value) {
    if (value == true) {
      this.DairyForm.controls['Beforestart'].enable();
      this.DairyForm.controls['SendBeforestart'].setValue(this.f.Beforestart.value);
    } else {
      this.DairyForm.controls['Beforestart'].disable();
      this.DairyForm.controls['SendBeforestart'].setValue('');
    }
  }

  ngOnDestroy(): void {
      this.searchMatter?.unsubscribe();
      this.subscription?.unsubscribe();
  }

  //ChnageCategory
  ChnageCategory(value) {
    if (this.f.Category.value == 'Billable' ||  this.f.Category.value == 'Non-Billable') {
      setTimeout(() => {
        $("#appoitmentnew").removeClass("menu-disabled");
        this.hideicon = false;
        this.DairyForm.controls['Matter'].enable();
        this.DairyForm.controls['SendMATTERGUID'].setValue(this.f.MATTERGUID.value);
        if(this.currentUserData && this.currentUserData.POPUPSELECTMATTERFIRST && this.Action == "new"){
          this.SelectMatter();
      } 
      },100);
    } else {
      setTimeout(() => {
        $("#appoitmentnew").addClass("menu-disabled");
        this.hideicon = true;
        this.DairyForm.controls['Matter'].disable();
        this.DairyForm.controls['SendMATTERGUID'].setValue('');
      },100);
     
    }
  }




  SelectMatter() {
    this.searchData = [];
    this.matterlist = this.searchData;
    const dialogRef = this.MatDialog.open(MatterDialogComponent, {
      width: '100%',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'DairyDailogComponent');
      if (result) {
        this.DairyForm.controls['Matter'].setValue(result.MATTER);
        this.DairyForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
        this.DairyForm.controls['SendMATTERGUID'].setValue(result.MATTERGUID);
        // this.mattername = [result.SHORTNAME + '--' + result.MATTER];
        this.mattername = [result.SELECTEDDESCRIPTION];

      }
    });
  }

  // select matter on load
  selectMatter() {

    //let activeMatter = JSON.parse(localStorage.getItem("set_active_matters"));
    if(this.SelectedMatterData?.matterIs){
      this.activeMatter = this.SelectedMatterData.matterIs;
    };

    let matterStatus:any = localStorage.getItem('MatterWindow_Open_Status');
    if(this.router.url.split("/")[1] == 'matter-details' || matterStatus == true){
        this.DairyForm.controls['Matter'].setValue(this.activeMatter.MATTER);
        this.DairyForm.controls['MATTERGUID'].setValue(this.activeMatter.MATTERGUID);
        this.DairyForm.controls['SendMATTERGUID'].setValue(this.activeMatter.MATTERGUID);

        this.matterlist = [this.activeMatter];
        this.mattername = [this.activeMatter.SELECTEDDESCRIPTION];
    }


  }
  // ends here ~ select mater on load




//   public selectMatter() {
//     this.searchData = [];
//     this.matterlist = this.searchData;

//     const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: "100%", disableClose: true, data: null, });
//     dialogRef.afterClosed().subscribe((result) => {
//         if (result) {
//             localStorage.setItem('istrackid', 'SpandMoneyDialog');
//             this.DairyForm.controls["Matter"].setValue(result.SHORTNAME);
//             this.DairyForm.controls["MatterGUID"].setValue(result.MATTERGUID);
//             this.mattername = [result.SHORTNAME + '--' + result.MATTER]

//         }
//     });
// }

onKeyUp(event: any): void {
  const searchValue = event.target.value;
  this.searchTerms.next(searchValue);
}

public selectMatterFormSearch(event) {
  // let searchobj = { FastSearch: 'True', SearchString: event };

  let searchobj = {
    "Action": "GetData",
    "Filters": {
      FastSearch: 'True', SearchString: event
    }
  };
  return this._mainAPiServiceService.getSetData(searchobj, 'matter')

}

datashow(event) {
  this.exportdatavalue.RECORDS.forEach((element, i) => {
    if (i == 0) {
      let data = element
      // this.mattername = [data.SHORTNAME + '--' + data.MATTER]
      this.mattername = [data.SELECTEDDESCRIPTION]

       this.DairyForm.controls['Matter'].setValue(null);

      if (event.input) {
        event.input.value = '';
      } else {
        this.MatterInput.nativeElement.value = '';
      }

        this.DairyForm.controls['SendMATTERGUID'].setValue(data.MATTERGUID);
 // this.matterChange('MatterGuid', data.MATTERGUID);
  if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
    this.selectedData = [data];
  }
    }
  });
}

remove(fruit: any) {
  const index = this.mattername.indexOf(fruit);
  if (index >= 0) {
    this.mattername.splice(index, 1);
    this.DairyForm.controls['Matter'].setValue('');
    this.DairyForm.controls['SendMATTERGUID'].setValue('');
  }
  let sortname = fruit[0].split('--')[0]
  this.rm(sortname)
};

rm(sortname) {
  this.selectedData.forEach((element: any, index: any) => {
    if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
      this.selectedData.splice(index, 1);
    }
  })
}

add(event: MatChipInputEvent): void {
  const value = (event.value || '').trim();
  this.matterlist.forEach((element: any) => {
    if (element.SHORTNAME == event.value) {
      // Add our fruit
      const array3 = this.mattername;
      if (value) {
        if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
          this.mattername = [value]
        }
      }
    }
  });

  // Clear the input value
  if (event.input) {
    event.input.value = '';
  }
  //  event.chipInput!.clear();

  this.DairyForm.controls['Matter'].setValue(null);
};

selectedValue(event: MatAutocompleteSelectedEvent): void {
  // const array3 = this.mattername;
  // if (array3.length <1) {
  if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
    // this.mattername.push(event.option.viewValue);
    this.mattername = [event.option.viewValue]
    this.MatterInput.nativeElement.value = '';
    this.DairyForm.controls['Matter'].setValue(null);
  }
  // }
}

selectedmatterguid(data: any) {
  this.DairyForm.controls['SendMATTERGUID'].setValue(data.MATTERGUID);
  if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
    this.selectedData = [data];
  }

}

}
