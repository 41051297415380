<div class=" one " fxLayout="column" [formGroup]="matterAddressForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PARISH,'has-warning':errorWarningData.Warning?.PARISH}">
            <mat-label>Parish </mat-label>
            <input matInput formControlName="PARISH">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="pr-4">
        <mat-form-field appearance="outline" fxFlex="100"
            [ngClass]="{'has-error':errorWarningData.Error?.LOCALITY,'has-warning':errorWarningData.Warning?.LOCALITY}">
            <mat-label>Locality </mat-label>
            <input matInput formControlName="LOCALITY">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="pr-4">
        <mat-form-field appearance="outline" fxFlex="100"
            [ngClass]="{'has-error':errorWarningData.Error?.COUNCIL,'has-warning':errorWarningData.Warning?.COUNCIL}">
            <mat-label>Council</mat-label>
            <input matInput formControlName="COUNCIL">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.COUNCILPROPERTYNUMBER,'has-warning':errorWarningData.Warning?.COUNCILPROPERTYNUMBER}">
            <mat-label>Council Property No </mat-label>
            <input matInput formControlName="COUNCILPROPERTYNUMBER">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SPI,'has-warning':errorWarningData.Warning?.SPI}">
            <mat-label>SPI </mat-label>
            <input matInput name="SPI" formControlName="SPI">
        </mat-form-field>
    </div>
</div>