 <button mat-raised-button color="primary" id="refreshEmailTab" (click)="refreshEmailTab();">Refresh</button>
<div id="email_templete" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
    <div fxLayoutAlign="center" class="content">
        <div class="center p-010 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div" id="filters" *ngIf="showLocalFilters">
                <form [formGroup]="EmailAllData">
                    <mat-form-field class="example" appearance="outline" fxFlex="50">
                        <mat-label>Filter</mat-label>
                        <input matInput placeholder="Filter" formControlName="Filter">
                    </mat-form-field>
                    <mat-form-field class="example" appearance="outline" fxFlex="47">
                        <mat-label>Search</mat-label>
                        <input matInput placeholder="search" formControlName="search" (keydown)="FilterSearch($event)">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <span class="appCues-blankSpace">
                        <a href="javascript:void(0);">Link</a>
                    </span>
                </form>
            </div>

            <div class="content raidus-0">
                <div class="content raidus-0">
                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                    </div>
                    <div class="example-containerdata resizable-table  height_scrol">
                        <!-- <div class="btn top_right_action_billing_position dot_controller emails">
                            <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <mat-menu #contextMenu="matMenu">
                                <ng-template matMenuContent let-item="item">
                                    <button mat-menu-item (click)="refreshEmailTab()"> Reload Data</button>
                                </ng-template>
                            </mat-menu>
                        </div> -->
                        <mat-table [dataSource]="TemplateEmaildata" (matSortChange)="sortData($event)"
                            matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear
                            [@animateStagger]="{value:'50'}" matSort class="">
                            <ng-container matColumnDef="TYPEICON">
                                <!-- <mat-header-cell *matHeaderCellDef mwlResizable 
                                    (resizeEnd)="onResizeEnd($event, 'TYPEICON')"
                                    mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                                    <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                                </mat-header-cell> -->
                                <mat-cell *matCellDef="let row" class="matter-popup-cell-1">
                                    <img src="assets/icons/web_app/icon_email_d.ico" matTooltip=""
                                        matTooltipPosition="above" class="img-matter-popup">
                                    <!-- <img *ngIf="row.TEMPLATETYPE!='Template'" src="assets/icons/web_app/folder.ico"  > -->
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="NAME">
                                <!-- <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable
                                     (resizeEnd)="onResizeEnd($event, 'NAME')"
                                    mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                                    <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                                    Name
                                </mat-header-cell> -->
                                <mat-cell class="matcell" *matCellDef="let row"> {{row.NAME }} </mat-cell>
                            </ng-container>
                            <!-- <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row> -->
                            <mat-row [style.background]="highlightedRows == row.EMAILGUID ? selectedColore : ''"
                                [ngClass]="highlightedRows == row.EMAILGUID ? 'row-text-colore' : ''"
                                highlightedRows=row.EMAILGUID *matRowDef="let row; columns: displayedColumns;"
                                (click)="clicktitle(row);highlightedRows = row.EMAILGUID " matRipple
                                (dblclick)='dblclickEmail(row)'>
                            </mat-row>
                        </mat-table>
                    </div>
                    <!-- <mat-paginator [pageSizeOptions]="[1000, 2000,3000,4000]" [pageSize]="pageSize?.email_templete"
                        (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator> -->
                </div>
            </div>
        </div>
    </div>
</div>