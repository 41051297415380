import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from 'src/@fuse/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-contact-corres-details',
  templateUrl: './contact-corres-details.component.html',
  styleUrls: ['./contact-corres-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ContactCorresDetailsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [];
  selection = new SelectionModel<any>(true, []);
  getMatterGuid: any;
  currentRow: any;
  highlightedRows: any;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  getin: any;
  getDataForTable: any = [];
  getContactData: any;
  isLoadingResults: boolean = false;
  pageSize: any;
  isFrom: any;
  matterTitle: string = '';
  currentMatterNum: string = '';
  currentInvoiceNum: string = "";
  sub: Subscription;
  sub1: Subscription;
  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ContactCorresDetailsComponent>,
    private toastr: ToastrService,
    public behaviorService: BehaviorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data.MatterNum && (data.WindowName == "MultiSelectMatter")) {
      this.currentMatterNum = data.MatterNum;
    }
    if(data.InvoiceCode && (data.WindowName == "MultiSelectInvoice")) {
      this.currentInvoiceNum = data.InvoiceCode;
    }

    

    localStorage.setItem('istrackid', 'ContactCorresDetailsComponent');
    if (data.window) {
      this.isFrom = data.window;
    }
    this.getMatterGuid = this.data.ContextGuid;
    // if(data.window){
    if (data.window == 'send email') {
      this.displayedColumns = ['select', 'contact', 'email', 'type'];

    } else {
      this.displayedColumns = ['contact', 'email', 'type'];

    }
    // }
    this.behaviorService.mtshareData(null);
    this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'ContactCorresDetailsComponent' && result['click'] && result['data']['matter-contact']) {
        this.ngOnInit();
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.isLoadingResults = true;
    this.getin = { MatterGuid: this.getMatterGuid };
    this._mainAPiServiceService.getSetData({Action: "GetData", Filters: { MatterGuid: this.getMatterGuid }}, 'matter-contact').subscribe(res => {
      if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      } else if (res.STATUS == "success" && res.CODE == 200) {
        if (this.data.isTemplate !== "Template") {
          if (Object.keys(res.DATA.RECORDS).length == 0) {
            this.toastr.error("Can't Find corresponding Details");
            this.dialogRef.close(false);
          } else if (Object.keys(res.DATA.RECORDS).length == 1 && this.data.callType != 'select') {
            this.dialogRef.close(false);
            this.selectButton();
          } else if (Object.keys(res.DATA.RECORDS).length != 0) {
            this.highlightedRows = res.DATA.RECORDS[0].MATTERCONTACTGUID;
            this.editContact(res.DATA.RECORDS[0])
            localStorage.setItem('contactGuid', res.DATA.RECORDS[0].PERSONGUID);
          }
        } else {
          if (res.DATA.RECORDS[0]) {
            this.highlightedRows = res.DATA.RECORDS[0].MATTERCONTACTGUID;
            this.editContact(res.DATA.RECORDS[0])
            localStorage.setItem('contactGuid', res.DATA.RECORDS[0].PERSONGUID);
          } else {
            this.dialogRef.close({"PERSONGUID":"","MATTERCONTACTGUID":""})
          }
        }
        this.getDataForTable = new MatTableDataSource(res.DATA.RECORDS);
        this.getDataForTable.paginator = this.paginator;
        this.getDataForTable.sort = this.sort;
        this.isLoadingResults = false;
        this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
      } else {
        this.toastr.error(res.MESSAGE);
        this.isLoadingResults = false;
      }
    });
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

  /**
   * This function is used to onPaginateChange
   * @param event -event data value
   */
  onPaginateChange(event) {
    this.pageSize.contact_corres_details = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

  /**
   * This functionnis used to close the dialog 
   */
  ondialogcloseClick(): void {
    this.dialogRef.close(false);
  }

  /**
   * This unction is used to edit the contact
   * @param event -event data value
   */
  editContact(event):void {
    this.currentRow = event;
    this.getMatterGuid = event.PERSONGUID;
    localStorage.setItem('contactGuid', event.PERSONGUID);
  }

  /**
   * This function is used to select the button
   */
  selectButton():void {
    const dialogRef = this.dialog.open(ContactDialogComponent, { data: { action: 'edit' } });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  /**
   * This function is used to isAllselected data value
   * @returns -data value
   */
  isAllSelected() {
    if (this.getDataForTable.length > 0) {
      const numSelected = this.selection.selected.length;
      const numRows = this.getDataForTable.data.length;
      return numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.getDataForTable.length > 0) {
      this.isAllSelected()
        ? this.selection.clear()
        : this.getDataForTable.data.forEach((row) =>
          this.selection.select(row)
        );
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
      }`;
  }
  helloFunction() {
  }

  /**
   * This function is used to select the button.
   */
  selctbtn():void {
    this.behaviorService.mtshareData(this.selection.selected);
  }
}
