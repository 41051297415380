import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appNumberMask]'
})
export class NumberMaskDirective {
    private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    /**
     * This is used to check the keydown data.
     */
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        if (!current) {
            current = "";
        }
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

}
