import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-settlment-strata',
  templateUrl: './settlment-strata.component.html',
  styleUrls: ['./settlment-strata.component.scss']
})
export class SettlmentStrataComponent implements OnInit {
  @Input() matterAddressForm: FormGroup;
  @Input() errorWarningData: any;
  constructor(public datepipe: DatePipe,) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  /**
   * This function is used to get the Resolution date picker
   */
  ResolutionDatepicker(type: string, event: MatDatepickerInputEvent<Date>) {
    this.matterAddressForm.controls['SPECIALRESOLUTIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

}
