<div id="try-silq-free" class="try_free_silq_main" fxLayout="column">
    <div id="try-silq-free-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="try-silq-free-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div class="logo">
                <img src="assets/images/logos/75.png">
            </div>
            <div class="title">Try SILQ Free</div>
            <form name="trySilqFreeForm" [formGroup]="trySilqFreeForm" novalidate *ngIf="isTry">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" readonly>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" readonly>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Phone</mat-label>
                    <input matInput formControlName="phone" readonly>
                </mat-form-field>
                <button mat-raised-button color="accent" class="submit-button mat-accent" (click)="saveDataBase()"
                    [disabled]="isspiner" aria-label="Save" [disabled]="trySilqFreeForm.invalid">
                    <mat-spinner *ngIf="isspiner"></mat-spinner>Create database
                </button>
            </form>
            <form name="tryLoginForm" [formGroup]="tryLoginForm" novalidate *ngIf="!isTry">
                <mat-form-field appearance="outline">
                    <mat-label>Username/Email</mat-label>
                    <input matInput formControlName="username">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <button mat-raised-button color="accent" class="submit-button" (click)="loginUser()"
                    [disabled]="isspiner" aria-label="Save" [disabled]="tryLoginForm.invalid">
                    <mat-spinner *ngIf="isspiner"></mat-spinner>Login
                </button>
            </form>
            <div class="title-footer" *ngIf="isCreateDb">
                <mat-progress-bar style="height: 1em;" mode="determinate" [value]="progress"></mat-progress-bar>
                <br>
                <h2 style="margin-top: 40px;"> {{btnText}} </h2>
            </div>
        </div>

    </div>
</div>