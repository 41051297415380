<div id="account" class="page-layout simple left-sidebar inner-sidebar inner-scroll packet_padding" [ngClass]="COLOR">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto filter-menuwrap">
                <form [formGroup]="PacketsForm" class="align-center display-inlineflex flex-wrap filter-menublock">
                     <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="align-center display-inlineflex flex-wrap filter-menublock" >
                        <div class="add-filterwrap" >

                            <!-- button -->
                           <button mat-button [matMenuTriggerFor]="dummyButton" [disabled]="true" style="display: none;">
                               Filters</button>
                           <mat-menu #dummyButton="matMenu">
                           </mat-menu>
                           <!-- ends here ~ button -->


                           <button mat-button [matMenuTriggerFor]="menuFilter" class="filter-button" [ngClass]="COLOR"
                           
                               (click)="backToFilter('optional-Discount');showFiltersList=true;"><img
                                   [src]="currentUserData?.PRODUCTTYPE == 'Solicitor' ? 'assets/icons/filter-icon.png':'assets/icons/filter-icon-barrister.png'"
                                   alt="Filter" class="filter-icon" />
                                   <span  >  Filters</span></button>
                           <mat-icon class="icon-btn close-btn" (click)="clearAllFilter()"
                               *ngIf="optionalFilters?.length > 0">close</mat-icon>
                           <mat-icon class="icon-btn" (click)="refreshPackets()" >refresh</mat-icon>
                           <mat-menu #menuFilter="matMenu">
                               <div class="filterscroll" [ngClass]="COLOR">
                                <ng-container *ngFor="let optionalFilterData of optionalFilterList">
                                    <div *ngIf="optionalFilterData.FILTERTYPE != 'HIDDEN'">
                                        <button class="addbutton"
                                            [ngClass]="COLOR"
                                            *ngIf="!optionalFilterData.FILTERAPPLY && showFiltersList"
                                            (click)="preventDefault($event);activeCurrentFilter('optional-Discount',optionalFilterData);addNewFilter(activeOptionalFilter);showFiltersList=false;">
                                            <mat-icon>add_circle</mat-icon>
                                            {{optionalFilterData.DESCRIPTION}}
                                        </button>
                                        <div class="menu-submenu" id="optional-Discount">
                                            <h5 class="filter-title" *ngIf="activeOptionalFilter">
                                                <mat-icon class="back-icon"
                                                    (click)="backToFilter('optional-Discount');preventDefault($event);showFiltersList=true;">
                                                    arrow_back_ios
                                                </mat-icon>Filter by {{activeOptionalFilter.DESCRIPTION}}
                                            </h5>

                                            <div *ngIf="activeOptionalFilter">
                                                <!-- Filter List Need to Apply -->
                                                <ng-container formArrayName="FILTERSLIST">
                                                    <div *ngFor="let filter of _filterList; let j =index;"
                                                        class="position-relative">
                                                        <ng-container [formGroupName]="j">
                                                            <!-- <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5> -->
                                                            <mat-form-field appearance="outline"
                                                                class="width-full"
                                                                (click)="preventDefault($event)">
                                                                <mat-select
                                                                    (selectionChange)="optionalDateFilter($event , filter , 'default' , -1)"
                                                                    name="OPERATION"
                                                                    [(ngModel)]="addFilter.OPERATION"
                                                                    formControlName="OPERATION"
                                                                    #operationFilter>
                                                                    <mat-option
                                                                        *ngFor="let d of filter.DATEFILTERLIST; "
                                                                        [value]="d.value">{{d.description}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png"
                                                                        alt="Left Arrow">
                                                                </span>
                                                                <mat-form-field class="width-full"
                                                                    appearance="outline"
                                                                    (click)="preventDefault($event)"
                                                                    *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                    <input matInput placeholder=""
                                                                        name="VALUE" formControlName="VALUE"
                                                                        [(ngModel)]="addFilter.VALUE"
                                                                        [(value)]="addFilter.VALUE" required
                                                                        appNumberMask>
                                                                </mat-form-field>

                                                                <mat-form-field class="width-full"
                                                                    appearance="outline"
                                                                    (click)="preventDefault($event)"
                                                                    *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                    <input matInput placeholder=""
                                                                        name="VALUE" formControlName="VALUE"
                                                                        [(ngModel)]="addFilter.VALUE"
                                                                        [(value)]="addFilter.VALUE" required
                                                                        currencyMask>
                                                                </mat-form-field>
                                                            </div>

                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png"
                                                                        alt="Left Arrow">
                                                                </span>
                                                                <mat-form-field class="width-full"
                                                                    appearance="outline"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput placeholder=""
                                                                        name="VALUE" formControlName="VALUE"
                                                                        [(ngModel)]="addFilter.VALUE"
                                                                        [(value)]="addFilter.VALUE"
                                                                        required>
                                                                </mat-form-field>
                                                            </div>


                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png"
                                                                        alt="Left Arrow">
                                                                </span>
                                                                <div class="display-flex align-center"
                                                                    (click)="preventDefault($event)"
                                                                    *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                    <mat-form-field class="set-date"
                                                                        appearance="outline">
                                                                        <input matInput placeholder=""
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE"
                                                                            required appNumberMask>
                                                                    </mat-form-field>
                                                                    <span class="middle-caption">and</span>
                                                                    <mat-form-field class="set-date"
                                                                        appearance="outline">
                                                                        <input matInput placeholder=""
                                                                            name="VALUE2"
                                                                            formControlName="VALUE2"
                                                                            [(ngModel)]="addFilter.VALUE2"
                                                                            [(value)]="addFilter.VALUE2"
                                                                            required appNumberMask>
                                                                    </mat-form-field>
                                                                </div>

                                                                <div class="display-flex align-center"
                                                                    (click)="preventDefault($event)"
                                                                    *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                    <mat-form-field class="set-date"
                                                                        appearance="outline">
                                                                        <input matInput placeholder=""
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE"
                                                                            required currencyMask>
                                                                    </mat-form-field>
                                                                    <span class="middle-caption">and</span>
                                                                    <mat-form-field class="set-date"
                                                                        appearance="outline">
                                                                        <input matInput placeholder=""
                                                                            name="VALUE2"
                                                                            formControlName="VALUE2"
                                                                            [(ngModel)]="addFilter.VALUE2"
                                                                            [(value)]="addFilter.VALUE2"
                                                                            required currencyMask>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>

                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png"
                                                                        alt="Left Arrow">
                                                                </span>
                                                                <div class="display-flex align-center">
                                                                    <mat-form-field appearance="outline"
                                                                        class="set-date"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput
                                                                            [matDatepicker]="picker6"
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE"
                                                                            required>
                                                                        <mat-datepicker-toggle matSuffix
                                                                            [for]="picker6">
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker #picker6>
                                                                        </mat-datepicker>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>

                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png"
                                                                        alt="Left Arrow">
                                                                </span>
                                                                <div class="display-flex align-center field-box"
                                                                    (click)="preventDefault($event)">
                                                                    <mat-form-field class="set-date"
                                                                        appearance="outline">
                                                                        <input matInput placeholder=""
                                                                            name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE"
                                                                            required appNumberMask>
                                                                    </mat-form-field>
                                                                    <mat-form-field appearance="outline"
                                                                        class="set-date">
                                                                        <mat-select formControlName="VALUE2"
                                                                            [(value)]="addFilter.VALUE2"
                                                                            [(ngModel)]="addFilter.VALUE2"
                                                                            required>
                                                                            <mat-option value="days">Days
                                                                            </mat-option>
                                                                            <mat-option value="month">Month
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>


                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                <mat-form-field appearance="outline"
                                                                    class="set-date"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput [matDatepicker]="picker"
                                                                        #StartDate name="VALUE"
                                                                        formControlName="VALUE"
                                                                        [(ngModel)]="addFilter.VALUE"
                                                                        [(value)]="addFilter.VALUE" required
                                                                        (keydown)="preventDefault($event)">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="picker">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                                <span class="middle-caption">and</span>
                                                                <mat-form-field appearance="outline"
                                                                    class="set-date"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput
                                                                        [matDatepicker]="picker2" #EndDate
                                                                        name="VALUE2"
                                                                        formControlName="VALUE2"
                                                                        [(ngModel)]="addFilter.VALUE2"
                                                                        [(value)]="addFilter.VALUE2"
                                                                        required
                                                                        (keydown)="preventDefault($event)">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="picker2">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker2>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>

                                                            <button mat-raised-button color="primary"
                                                                class="width-full accent"
                                                                (click)="addfilter(filter , operationFilter, $event)">Apply</button>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div> 

                                </ng-container> 
                               </div>
                           </mat-menu>
                       </div>
<!-- ~~~~~~~~~~~~~~~~ start filter ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
                    <div class="position-relative" >
                        
                        <!-- search  -->
                        <mat-icon class="badge-ic"
                        (click)="clearFilter('SearchString')">
                        {{(PacketsForm.get('SEARCH').value)?'cancel_circle' : ''}}</mat-icon>

                        <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                            Search<span class="filter-applytext display-flex align-center">

                                    <input matInput  #searchField
                                    (keyup)="onSearch($event)"
                                        formControlName="SEARCH" style="margin-top: 4px;text-align: left;" tabindex="6">
                                    <mat-icon matSuffix (click)="onSearch('Enter')">search</mat-icon>

                            </span>
                        </button>
                        <!-- ends here ~ search  -->                        

                        <!-- OLD SEARCH -->
                        <!-- <mat-icon class="badge-ic" (click)="(PacketsForm.get('SEARCH').value)?clearFilter('SearchString'):false">
                            {{(PacketsForm.get('SEARCH').value)?'cancel_circle' : 'add_circle'}}</mat-icon>
                        <button mat-button [matMenuTriggerFor]="menutytytttt" class="buttonwith-text">
                            Search<span [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'add-btn-solicitor':'add-btn-barrister'" class="filter-applytext display-flex align-center">{{PacketsForm.get('SEARCH').value}}<mat-icon class="rightdownarrow">
                                    keyboard_arrow_down</mat-icon></span>
                        </button>
                        <mat-menu #menutytytttt="matMenu" backdropClass="searchoverlay">
                            <h5 class="filter-title">Filter by Search</h5>
                            <div class="filter-emailbox display-flex align-center">
                                <mat-form-field class="example" class=" small_serach" appearance="outline"
                                    (click)="preventDefault($event)">
                                    <input (keydown)="onSearch($event)" matInput placeholder="search" formControlName="SEARCH">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
        
                            </div>
                            <button mat-raised-button color="primary" class="width-full accent"
                                (click)="onSearch('Enter')">Apply</button>
                        </mat-menu> -->
                        <!-- ends here ~ OLD SEARCH -->
                    </div>
<!-- ~~~~~~~~~~~~~~~~ end  filter ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
<ng-container formArrayName="FILTERS">
    <div *ngFor="let filter of optionalFilters; let i =index;" class="position-relative">
        <ng-container [formGroupName]="i">
            <mat-icon class="badge-ic" (click)="resetOptionalFilter(filter , filter.FILTERTYPE , i)">cancel_circle
            </mat-icon>
            <button mat-button [matMenuTriggerFor]="menuCreatedDate_i" class="buttonwith-text">
                {{filter.DESCRIPTION}}<span
                    class="filter-applytext display-flex align-center">{{(optionalDateFilterPayload[i] &&
                    optionalDateFilterPayload[i]['value'] !==
                    null)?optionalDateFilterPayload[i]['value'].VALUE : ''}} {{(optionalDateFilterPayload[i]
                    && optionalDateFilterPayload[i]['value'] !== null &&
                    optionalDateFilterPayload[i]['value'].VALUE2 !== '' && optionalDateFilterPayload[i]['value'].VALUE2 !== null)?' - ' +
                    optionalDateFilterPayload[i]['value'].VALUE2 : ''}}<mat-icon class="rightdownarrow">
                        keyboard_arrow_down</mat-icon></span>
            </button>
            <mat-menu #menuCreatedDate_i="matMenu">
                <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5>
                <mat-form-field appearance="outline" class="width-full" (click)="preventDefault($event)">
                    <mat-select (selectionChange)="optionalDateFilter($event , filter, 'active' , i)" name="OPERATION"
                        formControlName="OPERATION" #Datefilter>
                        <mat-option *ngFor="let d of filter.DATEFILTERLIST " [value]="d.value">{{d.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="display-flex align-center"
                    *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                    <span class="back-tick">
                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                    </span>
                    <mat-form-field class="width-full" appearance="outline" *ngIf="filter.FILTERTYPE == 'NUMBER'" appNumberMask
                        (click)="preventDefault($event)">
                        <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE"
                            required>
                    </mat-form-field>

                    <mat-form-field class="width-full" appearance="outline" *ngIf="filter.FILTERTYPE == 'CURRENCY'" currencyMask 
                        (click)="preventDefault($event)">
                        <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE"
                            required>
                    </mat-form-field>
                </div>

                <div class="display-flex align-center"
                    *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                    <span class="back-tick">
                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                    </span>
                    <mat-form-field class="width-full" appearance="outline"
                        (click)="preventDefault($event)">
                        <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE"
                            required>
                    </mat-form-field>
                </div>


                <div class="display-flex align-center"
                    *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                    <span class="back-tick">
                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                    </span>
                    <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                        <mat-form-field class="set-date" appearance="outline">
                            <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE" appNumberMask
                                required>
                        </mat-form-field>
                        <span class="middle-caption">and</span>
                        <mat-form-field class="set-date" appearance="outline">
                            <input matInput placeholder="" value="" name="VALUE2" formControlName="VALUE2" appNumberMask
                                required>
                        </mat-form-field>
                    </div>

                    <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                        <mat-form-field class="set-date" appearance="outline">
                            <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE" currencyMask
                                required>
                        </mat-form-field>
                        <span class="middle-caption">and</span>
                        <mat-form-field class="set-date" appearance="outline">
                            <input matInput placeholder="" value="" name="VALUE2" formControlName="VALUE2" currencyMask
                                required>
                        </mat-form-field>
                    </div>
                </div>

                <div class="display-flex align-center"
                    *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                    <span class="back-tick">
                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                    </span>
                    <div class="display-flex align-center">
                        <mat-form-field appearance="outline" class="set-date"
                            (click)="preventDefault($event)">
                            <input matInput [matDatepicker]="picker6" name="VALUE" formControlName="VALUE"
                                required>
                            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                            <mat-datepicker #picker6></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="display-flex align-center"
                    *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                    <span class="back-tick">
                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                    </span>
                    <div class="display-flex align-center field-box" (click)="preventDefault($event)">
                        <mat-form-field class="set-date" appearance="outline">
                            <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE"
                                required appNumberMask>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="set-date">
                            <mat-select [value]="'days'" formControlName="VALUE2" required>
                                <mat-option value="days">Days</mat-option>
                                <mat-option value="month">Month </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div class="display-flex align-center"
                    *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                    <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                        <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                            formControlName="VALUE" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <span class="middle-caption">and</span>
                    <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                        <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                            formControlName="VALUE2" required>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="primary" class="width-full accent"
                    (click)="applayOptionalfilter(optionalFilters[i] , i ,Datefilter, $event)">Apply</button>
            </mat-menu>
        </ng-container>
    </div>
</ng-container>
                </div>
                <div class="bg-dark rigth-div" id="appCues_main_safe_custody_Packets"></div>
                </form>
            </div>
            <div class="content">
                <div class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <div class="btn top_right_action_billing_position">
                                    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template matMenuContent let-item="item">
                                            <button mat-menu-item (click)="openDialog()"> Field Configuration</button>
                                            <!-- <button mat-menu-item (click)="RefreshPacketsData()"> Reload Data</button> -->
                                            <!-- <button mat-menu-item (click)="printPage()">Create Report</button> -->
                                            <!-- <button mat-menu-item (click)="downloadFile(MainPacketsData.data)">
                                                <img src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="height: 20px !important;margin-top: 10px;">
                                                Download CSV
                                            </button> -->

                                              <button mat-menu-item (click)="getNewExportData()">
                                                <img src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="height: 20px !important;margin-top: 10px;">
                                                    Export to CSV
                                            </button> 
                                        </ng-template>
                                    </mat-menu>
                                </div>
                                <div class="content">
                                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div class=" resizable-table">

                                        <div class="grid-overlay" *ngIf="isDisplay">
                                            <div class="grid-overlay-msg"> There is no data to display. </div>
                                        </div>
                                        <mat-table #table [dataSource]="MainPacketsData" matSort [@animateStagger]="{value:'50'}">
                                            <ng-container *ngFor="let item of displayedColumns; let i = index" matColumnDef="{{item}}">
                                                <mat-header-cell
                                                *matHeaderCellDef="let i = index"
                                                mat-sort-header
                                                class="header_title"
                                                mwlResizable
                                                enableGhostResize="true"
                                                (resizing)="onResizing($event, item)"
                                                (resizeEnd)="onResizeEnd($event, item)"
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    right: true,
                                                    top: false,
                                                    left: true
                                                 }">
                                                <div
                                                    class="desc_cell resize-handle-right "
                                                    mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>
                                                {{ tempColobj[item].DESCRIPTION }}
                                            </mat-header-cell>
                                                <mat-cell *matCellDef="let row" class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}" [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                                    <span *ngIf="row[item]!==undefined">{{row[item] | toFixedAmount:tempColobj[item].CURRENCY | wordwrap:tempColobj[item].WIDEDATA}}</span>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                            </mat-header-row>
                                            <mat-row [style.background]="highlightedRows == row.SAFECUSTODYPACKETGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumns;" [ngClass]="highlightedRows == row.SAFECUSTODYPACKETGUID ? 'row-text-colore' : ''" class="contact" (click)="EditPackets(row);highlightedRows = row.SAFECUSTODYPACKETGUID"
                                                matRipple>
                                            </mat-row>
                                        </mat-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<button mat-raised-button type="button" color="primary" id="refereshpacketsdata" (click)="RefreshPacketsData();">Refresh</button>