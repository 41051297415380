import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settlment-crow-allotment',
  templateUrl: './settlment-crow-allotment.component.html',
  styleUrls: ['./settlment-crow-allotment.component.scss']
})
export class SettlmentCrowAllotmentComponent implements OnInit {

  @Input() matterAddressForm: FormGroup;
  @Input() errorWarningData: any;
  constructor() { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }
}
