<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>Select Contact
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="one select_matter_main_div">
            <div class="center p-24 list_tbl_main more_tbl_clmn">
                <div class="matter-serach-div sticky_search_div">
                    <!-- select bar  -->
                    <form name="SelectContact" id="SelectContact" fxLayout="column" fxLayoutAlign="start"
                        fxFlex="1 0 auto" name="form" [formGroup]="SelectcontactForm">
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field class="example" fxFlex="30" class="pr-4" appearance="outline">
                                <mat-label>Contact Type</mat-label>
                                <mat-select #value name="selected" (selectionChange)="ContactTypeChange($event.value)"
                                    formControlName="ContactType">
                                    <mat-option value="all">All Contacts</mat-option>
                                    <mat-option value="Person">People</mat-option>
                                    <mat-option value="Company">Company</mat-option>
                                    <mat-option value="Party">Party</mat-option>
                                    <mat-option value="Payee">Payee</mat-option>
                                    <mat-option value="Payor">Payor</mat-option>
                                    <mat-option value="Payee/Payor">Payee/Payor</mat-option>
                                    <mat-option *ngFor="let val of contect_details;"
                                        [value]="'COMPANYCONTACTGUID,'+val.CONTACTGUID">
                                        Company people for {{val.CONTACTNAME}}
                                    </mat-option>
                                    <mat-option value="Company" *ngIf="_data?.CONTACTTYPE == 'Company'">Company People</mat-option>
                                    <mat-option value="Multi Person" *ngIf="_data?.CONTACTTYPE == 'Multi Person'">Multi People</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="example" fxFlex="20" class="pr-4" appearance="outline">
                                <mat-label>Starts With</mat-label>
                                <mat-select (selectionChange)="Contactvalue($event.value)" formControlName="startsWith">
                                    <mat-option value="all">All Contacts</mat-option>
                                    <mat-option value="a">A</mat-option>
                                    <mat-option value="b">B</mat-option>
                                    <mat-option value="c">C</mat-option>
                                    <mat-option value="d">D</mat-option>
                                    <mat-option value="e">E</mat-option>
                                    <mat-option value="f">F</mat-option>
                                    <mat-option value="g">G</mat-option>
                                    <mat-option value="h">H</mat-option>
                                    <mat-option value="i">I</mat-option>
                                    <mat-option value="j">J</mat-option>
                                    <mat-option value="k">K</mat-option>
                                    <mat-option value="l">L</mat-option>
                                    <mat-option value="m">M</mat-option>
                                    <mat-option value="n">N</mat-option>
                                    <mat-option value="o">O</mat-option>
                                    <mat-option value="p">P</mat-option>
                                    <mat-option value="q">Q</mat-option>
                                    <mat-option value="r">R</mat-option>
                                    <mat-option value="s">S</mat-option>
                                    <mat-option value="t">T</mat-option>
                                    <mat-option value="u">U</mat-option>
                                    <mat-option value="v">V</mat-option>
                                    <mat-option value="w">W</mat-option>
                                    <mat-option value="x">X</mat-option>
                                    <mat-option value="y">Y</mat-option>
                                    <mat-option value="z">Z</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="example pr-4" appearance="outline">
                                <mat-select (selectionChange)="ActiveContactsChange($event.value)"
                                    formControlName="ActiveContacts">
                                    <mat-option value="all">All Contacts</mat-option>
                                    <mat-option value="1">Active Contacts</mat-option>
                                    <mat-option value="0">Inactive Contacts</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="25" class="pr-4">
                                <mat-label>Filter</mat-label>
                                <input matInput formControlName="SEARCH" (keydown)="onSearch($event)">
                            </mat-form-field>
                        </div>
                        <button mat-raised-button color="primary" type="button" id="refreshContactTab"
                            (click)="refreshContactTab();">Refresh</button>
                    </form>
                </div>
            </div>
            <div class="example-containerdata">
                <table mat-table #table [dataSource]="Contactdata" [@animateStagger]="{value:'50'}" matSort class="">
                    <ng-container matColumnDef="CONTACTNAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact name</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate">
                                {{row.CONTACTNAME }}
                            </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="SUBURB">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Suburb</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="company text-truncate">
                                {{row.SUBURB}}
                            </p>
                        </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr [style.background]="highlightedRows == row.CONTACTGUID ? selectedColore : ''"
                        [ngClass]="highlightedRows == row.CONTACTGUID ? 'row-text-colore' : ''"
                        class="rowHeight"
                        highlightedRows=row.CONTACTGUID mat-row *matRowDef="let row; columns: displayedColumns;"
                        (click)="editContact(row);highlightedRows = row.CONTACTGUID " matRipple
                        [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" [pageSize]="pageSize?.contact_select"
                (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr contact_select_footer">
        <div class="left_popup_btn">
            <button mat-raised-button color="accent" class="mat-accent" (click)="AddContactsDialog()"
                [ngClass]="appPermissions[3]?.Create==0 ? 'disabled-click-cursor' : ''">New Contact</button>
            <button mat-raised-button color="accent" class="mat-accent" (click)="EditContactsDialog()"
                [ngClass]="(appPermissions[3]?.Edit==0 || !currentMatterData) ? 'disabled-click-cursor' : ''">Edit
                Contact</button>
            <button mat-raised-button color="accent" class="mat-accent" (click)="deleteContact()"
                [ngClass]="(appPermissions[3]?.Delete==0 || !currentMatterData) ? 'disabled-click-cursor' : ''">Delete
                Contact</button>
        </div>
        <button [ngClass]="(appPermissions[3]?.Access==0 || !currentMatterData) ? 'disabled-click-cursor' : ''"
            mat-raised-button [mat-dialog-close]="currentMatterData" color="accent" class="mat-accent">Select</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>