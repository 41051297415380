import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseConfigService } from 'src/@fuse/services/config.service';

@Component({
  selector: 'app-end-user-licence-agreement',
  templateUrl: './end-user-licence-agreement.component.html',
  styleUrls: ['./end-user-licence-agreement.component.scss']
})
export class EndUserLicenceAgreementComponent implements OnInit {
  isLicence: any;
  ProductType: any;
  currentYear: any;
  defultTheme: boolean = false;
  className: string;
  constructor( private _fuseConfigService: FuseConfigService,private router :Router , public dialog: MatDialog) {
    // this._fuseConfigService.config = {
    //   colorTheme: 'theme-default',
    //   layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
    // };
    this._fuseConfigService.config = {
        // colorTheme: 'theme-default',
         layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
       };
       let CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
       if(CurrentUser && CurrentUser?.THEMECOLOUR){
         this._fuseConfigService.config.colorTheme=CurrentUser.THEMECOLOUR
       }else{
         this._fuseConfigService.config.colorTheme='theme-default'
       }
    this.isLicence= 'LI'
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.ProductType = currentUser.PRODUCTTYPE;
    this.currentYear = new Date().getFullYear();
    if (localStorage.getItem('theme_type') == "theme-yellow-light")
      this.defultTheme = true;

    if(CurrentUser  && CurrentUser?.THEMECOLOUR !=null && CurrentUser?.THEMECOLOUR !=undefined){
      if(CurrentUser?.THEMECOLOUR == "theme-blue-gray-dark" || CurrentUser?.THEMECOLOUR =="theme-pink-dark"){
        this.className='darkBG'
      } else{
        this.className='faintBG'
      }
    }else{
      this.className='faintBG'
    }
   }

      /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if(this.router.url == '/end-user-licence-agreement'){
      setTimeout(() => {
        this.dialog.closeAll();
      }, 50);
    }
  }

}
