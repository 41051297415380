<div class="popup_main_div licence_aggreement_main add_report_main">
    <div class="popup_title_div">
        <h2 mat-dialog-title *ngIf="isLicence=='LI'">
            SILQ End User Licence Agreement
        </h2>
    </div>
    <!-- <div class="mat-typography">
        <div style="padding: 15px;background: white;" class="popup_body licence_content" *ngIf="isLicence=='LI'">
            <p class="center_bold">END USER LICENCE AGREEMENT<br />FOR SILQ SOFTWARE</p>
            <p class="bold_font">IMPORTANT-READ CAREFULLY:</p>
            <p>This End-User License Agreement (&ldquo;EULA&rdquo;) is a legal agreement between you (either an individual person or a single legal entity, who will be referred to in this EULA as &ldquo;You&rdquo;) and the Licensor for the SILQ software
                technology that displays this EULA, including any associated media, manual, printed materials and electronic documentation (the &ldquo;Software&rdquo;).</p>
            <p>For purposes of this EULA, the term &ldquo;Licensor&rdquo; refers to Silqware Pty Limited.</p>
            <p>For purposes of this EULA, the &ldquo;Software&rdquo; also includes any software patches, updates, fixes, add-on components, web services and/or supplements that the Licensor may choose to provide to You or make available to You after the
                date You obtain Your initial copy of the Software to the extent that such items are not accompanied by a separate licence agreement or terms of use.</p>
            <p>By installing, copying, downloading, accessing or otherwise using the Software, You agree to be bound by the terms of this EULA. If You do not agree to the terms of this EULA, Licensor is unwilling to license the Software. In such event, You
                may not install, copy, download or otherwise use the Software.</p>
            <p class="bold_font">SOFTWARE LICENCE</p>
            <p>The Software is protected by intellectual property laws and treaties. The Software is licensed, not sold.</p>
            <p><b>1. GRANT OF LICENCE.</b> This EULA grants You the following rights:<br /><b>(a) During the 14 Day Free Trial.</b> You may install and use one copy of the Software for a free trial for a one-off period of 14 days only, subject to the terms
                of this EULA. Your right to install and use one copy of the Software for the free trial is non-exclusive. After the one-off 14 day free trial, You may not use or deal with the Software in any way unless You have paid the required fee to
                the Licensor.<br><b>(b) Purchase of a Licence for the Software.</b><br /><b> &bull; Installation and Use.</b> Once you have paid the required fees for one user licence to the Licensor, You may install and use one copy of the Software,
                subject to the terms of this EULA.
                <br />&bull; If You, in addition to having paid the required fee for one user licence (the &ldquo;primary user&rdquo;), have also paid the required fees for additional-seat user licences to the Licensor, You may concurrently use the number
                of copies of the Software that corresponds to those additional licences, subject to the terms of this EULA. The additional-seat user/s may only use the Software in relation to the same data file as that used by the primary user.<br />&bull;
                <b>Rights are non-exclusive.</b> Your right to install and use the Software granted by this EULA is non-exclusive, non-assignable and non-sublicensable.</p>
            <p><b>2. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS.</b><br /><b> &bull; Limitations on Reverse Engineering, Decompilation, and Disassembly.</b> You<br />may not reverse engineer, decompile, or disassemble the Software, except and<br />only
                to the extent that such activity is expressly permitted by applicable law<br />notwithstanding this limitation.<br /><b> &bull; Trademarks.</b> This EULA does not grant You any rights in connection with any<br />trademarks or service marks
                of Licensor or its suppliers.<br /><b> &bull; No rental, leasing or commercial hosting.</b> You may not rent, lease, lend or<br />provide commercial hosting services to third parties with the Software.<br /><b> &bull; Support Services.</b>                Licensor may provide You with support or other types of services related to the Software (&ldquo;Support Services&rdquo;). Whether the Licensor is obliged to provide Support Services to You is governed by other materials provided by the
                Licensor to You. Any supplemental software code provided to You as part of any Support Services are considered part of the Software and subject to the terms and conditions of this EULA. You acknowledge and agree that Licensor may use technical
                information You provide to Licensor as part of the Support Services for its business purposes, including for product support and development. Licensor will not utilize such technical information in a form that personally identifies You.
                <br /><b>&bull; Unlicensed or
                illegal use.</b> The Software contains technological measures (including use of the Internet) that are designed to prevent unlicensed or illegal use of the Software and to inform you of any updates to the Software. You consent to the Licensor
                using these measures for the purpose of preventing unlicensed or illegal use of the Software and to inform you of any updates to the Software.<br /><b> &bull; Termination.</b> Without prejudice to any other rights, Licensor may terminate
                this EULA if You fail to comply with the terms and conditions of this EULA. In such event, You must cease using the Software and destroy all copies of the Software and all of its component parts.</p>
            <p><b>3. INTELLECTUAL PROPERTY RIGHTS.</b> You acknowledge and agrees that all intellectual property in and to the Software and any other information or material (including confidential information) relating to the Software remains the property
                of the Licensor, and that You acquire no right, title or interest in or to that intellectual property by virtue of this EULA other than as expressly stated in this EULA.&nbsp; All rights not specifically granted under this EULA are reserved
                by Licensor and its suppliers.</p>
            <p><b>4. APPLICABLE LAW.</b> This EULA is governed by the laws of New South Wales.</p>
            <p><b>5. WARRANTIES</b></p>
            <p>5.1 You acknowledge that:<br />a) the Software and the Support Services cannot be and are not guaranteed to be error free and further acknowledge that the existence of any such errors shall not constitute a breach of this agreement;<br />b)
                prior to entering into this agreement you have been given a reasonable opportunity to examine and satisfy yourself regarding the features and operation of the Software (including the opportunity to use the Software during a free trial
                period) and that prior to entering this agreement You have availed yourself of that opportunity;<br />c) You have made the evaluation or judgement that You wish to use the Software and that the Licensor does not warrant that the Software
                will be suitable for Your particular purposes and you further acknowledge that at no time prior to entering into this EULA have You relied on the skill or judgment of the Licensor and that it would be unreasonable for You to rely on any
                such skill or judgment.</p>
            <p>5.2 The Licensor provides the following warranties, in relation to the Software and the Support Services if You are a Consumer: <br />(a) that the Software is of acceptable quality, as that term is defined in the Australian Consumer Law;<br
                />(b) that the Software is reasonably fit for the purpose of time management software for legal practitioners in small practice;<br />(c) that the Support Services will be rendered with due care and skill;<br />(d) that the Support Services
                will be provided either:<br />(i) at a time determined in a manner agreed between the Licensor and You; or<br />(ii) if there is no such agreement, within a reasonable time.</p>
            <p><br />5.3 Where You are a Consumer under the Australian Consumer Law, the Licensor is required to provide the following mandatory statement to you: &ldquo;Our goods come with guarantees that cannot be excluded under the Australian Consumer
                Law. You are entitled to a replacement or refund for a major failure and for compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the goods repaired or replaced if the goods fail to be of acceptable
                quality and the failure does not amount to a major failure.&rdquo; The Software and the Support Services are also subject to guarantees under the Australian Consumer Law other than those in cl 5.2 if you are a Consumer. </p>
            <p>5.4 Should You wish to make a claim under clause 5.2 or by reason of any statutory guarantee, you must provide the Licensor with written notice along with documentary evidence substantiating the claim.</p>
            <p><b>6. LIMITATIONS</b></p>
            <p>6.1 Nothing in this agreement excludes, restricts or modifies the application of any legislation which by the law of any jurisdiction cannot be excluded, restricted or modified. If You are a Consumer you have the benefit of certain non-excludable
                rights and remedies in respect of the goods or services and nothing in these terms and conditions excludes or restricts or modifies any condition, warranty, guarantee, right or remedy under the which is so conferred, other than as set
                out below.</p>
            <p>6.2 The parties agree that the Licensor&rsquo;s liability for breach of:<br />(a) the warranties contained in clause 5.2; <br />(b) the non-excludable rights and remedies referred to in clause 6.1 (other than the guarantees implied by sections
                51, 52 or 53 of the Australian Consumer Law); and<br />(c) any other liability of the Licensor howsoever arising,<br />is limited as set out in clauses 6.3 &ndash; 6.5.</p>
            <p>6.3 In relation to the Software, the Licensor&rsquo;s liability is limited to (a) repair of the Software or<br />(b) a refund of the fees paid for the Software (where the choice of remedy is at the option of the Licensor in its absolute discretion).</p>
            <p>6.4 In relation to the Support Services, the Licensor&rsquo;s liability is limited to supplying of the Support Services again.</p>
            <p>6.5 You agree that:<br />(a) to the extent such limitation is permitted by law (including the Australian Consumer Law) the Licensor is not liable for any special, indirect or consequential damage (including loss of profit, loss of business
                opportunity or loss of data) arising out of a breach of this EULA or otherwise arising out of the supply or use of the Software or the Support Services (whether under statute, at common law, in negligence, under contract or otherwise),
                even if the Licensor has been advised of the possibility of such damages;<br />(b) to the extent such limitation is permitted by law (including the Australian Consumer Law), the Licensor&rsquo;s total liability to you in relation to this
                EULA or otherwise in relation to the supply or use of the Software or the Support Services, (whether arising under statute, at common law, in negligence, under contract or otherwise), will not exceed in aggregate the total amount of fees
                you have paid to the Licensor in relation to the Software and the Support Services.</p>
            <p><b>7. ENTIRE AGREEMENT.</b> This EULA is the entire agreement between You and<br />Licensor relating to the Software and supersedes all prior or contemporaneous oral or written communications, marketing materials, proposals and representations
                with respect to the Software or any other subject matter covered by this EULA. To the extent the terms of any Licensor agreement, policy or program for Support Services or any other document conflict with the terms of this EULA, the terms
                of this EULA shall control.</p>
            <p><br /><b>In this EULA:</b></p>
            <p><b>Australian Consumer Law</b> means Schedule 2 of the Competition and Consumer Act 2010 (Cth) as amended from time to time, or any later equivalent legislation.</p>
            <p><b>Consumer</b> has the meaning given under the Australian Consumer Law.</p>

            <p><br /><b>Offer Details</b></p>
            <p>The below information explains SILQ’s auto-renewal, cancellation, pricing and other terms of our offer.</p>

            <p><br /><b>Auto-renewal</b></p>
            <p>Your SILQ subscription will automatically renew either monthly or annually depending on the product you have chosen. Your account will be debited or your card charged automatically based on the then current fees for the SILQ pricing option you have selected on a recurring basis until you cancel your SILQ subscription.</p>

            <p><br /><b>Cancellation</b></p>
            <p>To cancel your SILQ subscription, you must provide a minimum of two (2) weeks’ notice. You can do so by emailing <a href = "mailto: support@silq.com.au">support@silq.com.au</a>. If you cancel your SILQ subscription you will still be charged for the following month if your subscription renews within the 2 week notice period.</p>

            <p><br /><b>Pricing and other terms</b></p>
            <p>Promotional pricing and free trials cannot be combined with any other offers, any promotional offers are available fir a finite period of time only. Pricing, terms, features and support are subject to change by SILQ at any time. We guarantee to keep you on the same pricing for a minimum of two (2) years before potentially imposing any increases (many clients have been on the same pricing for much longer than two years). See the EULA for additional terms. SILQ may extend, cancel or amend any offer  at any time, in its sole discretion. For new SILQ customers, any free or discounted period associated with a promotion will commence on the purchase date. </p>

            <p><br /><b>Free Trial</b></p>
            <p>SILQ gives you a free trial subscription that expires after 14 days, or earlier if you elect to buy a subscription before then. The ‘free’ aspect to the trial is limited to the licensing fees for the subscription. What you can trial but may still incur fees are features that are charged on a per usage basis. An example is SILQPay where you or your client (depending on your settings) are charged a processing fee each time an invoice is paid. Additionally, any costs associated with integrations such as Microsoft 365, DropBox, Infotrack etc are borne by the user during the free trial as these products are not owned by SILQ, SILQ only integrates with these external providers.
                To continue using SILQ after your free trial expires, you will need to purchase a subscription and enter your payment details in SILQ for processing. During the free trial, you will have access to all features in the software irrespective of the different pricing plans. Once you decide to subscribe, you can choose which product you would like to subscribe to and then your features will be limited to what is available on that plan.</p>
        </div>

    </div> -->
    <!-- <mat-dialog-actions align="end" class="popup_ftr combined_popup_ftr" *ngIf="isLicence!='MD'">
        <div class="right_popup_btn">
            <div class="right_btn_child">
                <button class="cancel" mat-button [mat-dialog-close]="true" matBadgeColor="accent">Close</button>
            </div>
        </div>
    </mat-dialog-actions> -->

    <div class="mat-typography">
        <div [ngClass]="className"
            class="popup_body licence_content" *ngIf="isLicence=='LI'">
        

            <p class="center_bold">
                <strong>END USER LICENCE AGREEMENT</strong><br>
                <strong>FOR SILQ SOFTWARE</strong>
            </p><br>
            <p><strong>IMPORTANT-READ CAREFULLY:</strong></p><br>
            <p>This End-User License Agreement <strong>(EULA)</strong> is a legal agreement
                between Silqware Pty Ltd ACN 084 453 734
                <strong>(Licensor/SILQ/we/us)</strong> and the person or entity whose details are identified in the application or request for services submitted to us, together with their users for any additional licences  <strong>(you).</strong></p><br>
            <p>For purposes of this EULA, “Software” includes the software programs made available to You by us from time to time, any associated media, manual, printed materials, electronic documentation and any software patches, updates, fixes, add-on components, web services and/or supplements that the Licensor may choose to provide to You or make available to You from time to time. </p><br>
            <p>By installing, copying, accessing or otherwise using the Software or any part of it, You agree to be bound by the terms of this EULA.  If You do not agree to the terms of this EULA, the Licensor shall not grant You a license to the Software and You may not install, copy or otherwise access or use the Software.</p><br>

            <p><strong>SOFTWARE LICENCE</strong></p><br>
            <p>The Software is protected by intellectual property laws and
                treaties. The Software is licensed, not sold.</p><br>

            <h2>1. GRANT OF LICENCE:</h2>  <p>This EULA grants You the following rights:</p> <br>

            <p><strong>(a)</strong> During the initial fourteen (14) Day Free Trial - You may
                install and use one (1) copy of that part of the <strong>Software</strong>
                we designate to you, for a free trial for a one-off period of
                fourteen (14) days only, subject to the terms of this <strong>EULA</strong>.</p>
            <br>
            <p><strong>(b)</strong> After the once-off fourteen (14) day free trial, You may not
                access, use or deal with the <strong>Software</strong> in any
                way unless You have paid the required licence fees to the
                Licensor.</p>
            <br>
            <p><strong>(c)</strong> Purchase of a Licence for the <strong>Software</strong>:</p>
            <br>
            <blockquote>
                <p><strong>(i) Installation and Use</strong> - The Licensor grants you a licence
                    to use that part of the <strong>Software</strong> for which
                    you have paid the applicable fee on the terms of this
                    <strong>EULA</strong>. Once you have paid the required fees,
                    you may install and use one copy of the <strong>Software</strong>,
                    subject to the terms of this <strong>EULA</strong>.</p>
                <br>
                <p><strong>(ii) Users</strong> - If You, in addition to having paid the required
                    fee for one user licence (the primary user), have also paid
                    the required fees for additional seat user licences to the
                    Licensor, You may concurrently use the number of copies of
                    the <strong>Software</strong> that corresponds to those
                    additional licences, subject to the terms of this <strong>EULA</strong>.
                    You must ensure that the additional seat user/s comply with
                    the terms of this <strong>EULA</strong>. Such users may only
                    use the <strong>Software</strong> in relation to the same
                    data file as that used by the primary user.</p>
                <br>
                <p><strong>(iii) Limitations</strong> - Your right to access, install use or
                    otherwise deal with the <strong>Software</strong> granted by
                    this <strong>EULA</strong> (on a trial basis or otherwise)
                    is non-exclusive, non-assignable and non-sublicensable and,
                    except where the Licensor allows you a trial period, extends
                    only for the period in which you have paid the applicable
                    fee (Term). You may only use the <strong>Software</strong>
                    for your own internal commercial purposes and not for any
                    other purpose. The Licensor reserves all rights that are not
                    expressly granted to You in this <strong>EULA</strong>.</p>
            </blockquote><br>

            <h2>2. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS</h2><br>

            <p><strong>(a) Limitations on Reverse Engineering, Decompilation,
                    and Disassembly:</strong></p>
            <p>You may not reverse engineer, decompile, or disassemble the
                Software, except and only to the extent that the right to
                perform such actions is expressly permitted by applicable law
                and cannot be lawfully excluded.</p><br>

            <p><strong>(b) Trademarks:</strong></p>
            <p>This EULA does not grant You any rights in connection with any
                trademarks or service marks of Licensor or its suppliers. You
                must not use such marks without the Licensor’s prior written
                consent and only then in accordance with any conditions the
                Licensor imposes.</p><br>

            <p><strong>(c) No rental, leasing or commercial hosting:</strong></p>
            <p>You may not rent, lease, lend or provide commercial hosting
                services to third parties with the Software.</p><br>

            <p><strong>(d) Support Services:</strong></p>
            <p>Licensor may provide You with support or other types of services
                related to the Software <strong>(Support Services).</strong> All calls made to the Licensor, in particular in relation to any Support Services, may be recorded for quality and training purposes.  You consent to all calls being recorded for these purposes.  Support Services are not included in the Licensor’s standard fees. Whether the Licensor is obliged to provide Support Services to You will depend on whether a separate agreement is in place between the Licensor and You, but this EULA will apply to delivery of such Support Services.  You acknowledge and agree that Licensor may use technical information that You provide to Licensor as part of the Support Services for its business purposes, including for product support and development.  Licensor will not utilize such technical information in a form that personally identifies You.</p><br>

            <p><strong>(e) Unlicensed or illegal use:</strong></p>
            <p>The Software contains technological measures (including use of
                the Internet) that are designed to prevent unlicensed or illegal
                use of the Software and to inform you of any updates to the
                Software. You consent to the Licensor using these measures for
                the purpose of preventing unlicensed or illegal use of the
                Software and to inform you of any updates to the Software.</p><br>

            <p><strong>(f) Termination:</strong></p>
            <p>Without prejudice to any other rights, Licensor may terminate this EULA if You fail to comply with the terms and conditions of this EULA. In such event, the licence referred to above is immediately at an end and You must cease using (and ensure any additional seat-licence users cease using) the Software and must not attempt to access the Software. Subject to Your Non-excludable Rights (defined below), You will not be entitled to a refund of any amount on expiry or termination of Your subscription. </p><br>

            <h2>3. INTELLECTUAL PROPERTY RIGHTS:</h2><p> You acknowledge and agree that all intellectual property rights in and to the Software, the source and object code underlying the Software  and all other information or material (including confidential information) in any form relating to the Software (including but not limited to user guides and other documentation) remain the property of the Licensor (and/or its licensor(s)), and that You acquire no right, title or interest of any kind in or to those intellectual property rights by virtue of this EULA, other than the limited licence for the Term granted pursuant to clause 1 above.</p><br>

            <h2>4. APPLICABLE LAW:</h2> <p>This EULA is governed by the laws of
                New South Wales.</p><br>

            <h2>5. WARRANTIES & LIMITATION OF LIABILITY:</h2><br>

            <p><strong>(a)</strong> You acknowledge that:</p><br>
            <blockquote>
                <p>
                    (i) the Software and the Support Services cannot be and are not guaranteed to be error free and further acknowledge that the existence of any such errors shall not constitute a breach of this agreement;<br>
                    (ii)prior to entering into this agreement You have been given a reasonable opportunity to examine and satisfy Yourself regarding the features and operation of the Software (including the opportunity to use the Software during a free trial period);<br>
                    (iii) You have made the evaluation or judgement that You wish to use the Software and that the Licensor does not warrant that the Software will be suitable for Your particular purposes and You further acknowledge that at no time prior to entering into this EULA have You relied on the skill or judgment of the Licensor and that it would be unreasonable for You to rely on any such skill or judgment.
                </p>
            </blockquote><br>
            <p><strong>(b)</strong> The Licensor provides the following warranties, in relation to the Software and the Support Services if You are a Consumer (as that term is defined in the Australian Consumer Law, being (being Schedule 2 of the Competition and Consumer Act 2010 (Cth) as amended from time to time (ACL)):</p><br>

            <ol style='font-size: 13px;'>
                <li>That the Software is of acceptable quality, as that term is
                    defined in the ACL</li>
                <li>That the Software is reasonably fit for the purpose of time
                    management software for legal practitioners in small
                    practice;</li>
                <li>That the Support Services will be rendered with due care and
                    skill;</li>
                <li>That the Support Services will be provided either:
                    <ul>
                        <li>(A) At a time determined in a manner agreed between
                            the Licensor and You; or</li>
                        <li>(B) If there is no such agreement, within a
                            reasonable time.</li>
                    </ul>
                </li>
            </ol><br>

            <p><strong>(c)</strong> In addition to the warranties above, You may have rights under the ACL or other rights in relation to the supply of goods and services that the Licensor cannot lawfully exclude (Non-excludable Rights).  With the exception of Non-excludable Rights, and despite any other provision of this EULA, the Licensor’s liability to You, whether arising under or in connection with this EULA or anything incidental to it, and whether by way of  indemnity, statute (to the extent that it is possible to exclude such liability), tort (for negligence or otherwise), or any other basis in law or equity, is limited or excluded as follows:</p><br>

            <ol style='font-size: 13px;'>
                <li>The Licensor has no liability whatsoever to You for loss of use, production, profit, revenue, business, data (including Practice Data, as defined below), contract or anticipated saving, any financing costs, increase in operating costs or any economic loss or for any special, indirect or consequential loss or damage; and</li>
                <li>the total aggregate liability of the Licensor is at all times limited to the amount equal to the fees that You have paid to the Licensor in the twelve (12) month period prior to the liability arising. </li>
            </ol>

            <p><strong>(d)</strong> To the maximum extent permitted by law, the
                Licensor’s liability pursuant to:</p>
            <p>any warranty, term or condition implied or expressly given in
                this EULA;</p>
            <p>and</p>
            <p>any Non-excludable Right (including, for the avoidance of doubt,
                section 274 of the ACL or any similar or successor provision),</p>
            <p>will be limited, at the Licensor’s option, to:</p>
            <p>in the case of the Software, a repair or refund of the fees You
                have paid for access to the Software; or</p>
            <p>in the case of the Support Services, supplying the Support
                Services again.</p><br>

            <p><strong>(e)</strong> The warranties given to Consumers pursuant to clause 5(b) are the only warranties, representations or promises as to the performance of the Software and the services that may be provided by the Licensor. While nothing in this EULA excludes, restricts or modifies the operation of any law that cannot be so excluded, restricted or modified, to the extent permitted by law all implied conditions, guarantees and warranties are expressly excluded. </p><br>

            <p><strong>(f)</strong> To the extent this EULA contains a warranty against defects pursuant to the ACL, the Licensor includes the following in accordance with section 102 of the ACL:</p>
            <p>Our goods and services come with guarantees that cannot be
                excluded under the ACL. For major failures with the service, you
                are entitled:</p>
            <ol style='font-size: 13px;'>

                <ul>
                    <li>(A) To cancel your service contract with us; and</li>
                    <li> (B) To a refund for the unused portion, or to
                        compensation for its reduced value.</li>

                </ul>

            </ol>

            <p>You are also entitled to choose a refund or replacement for major
                failures with goods.</p><br>

            <p><strong>(g)</strong> If a failure with the goods or a service does not amount to a major failure, You are entitled to have the failure rectified in a reasonable time. If this is not done You are entitled to a refund for the goods and to cancel the contract for the service and obtain a refund of any unused portion.</p>
                <br>
            <p><strong>(h)</strong> You are also entitled to be compensated for any other reasonably foreseeable loss or damage from a failure in the goods or service.</p>
                <br>
            <p><strong>(i)</strong> Should You wish to make a claim under this clause 5 or by reason of any statutory guarantee, You must provide the Licensor with written notice along with documentary evidence substantiating the claim.</p><br>

            <h2>6. RELEASE & INDEMNITY:</h2><br>
            <p> <strong>(a)</strong> indemnify and hold us, as well as our officers, employees, agents and contractors (the <strong>Silq Parties</strong>), harmless from all claims made against any one or more of the Silq Parties, together with any and all liabilities a Silq Party may incur (of any nature and including but not limited to debts, costs and expenses); and</p><br>

            <p><strong>(b)</strong> release the Silq Parties all liability of any nature </p><br>

            <p><strong>(c)</strong> which arises from or is in any way connected with your use of the Software (except to the extent that such claims or liabilities are caused or contributed to by us).  </p><br>

            <h2>7. THIRD PARTY SOFTWARE:</h2><br>
            <p>The Software may be compatible with certain third-party software
                or applications (<strong>Third Party Software</strong>), including, as embedded in the Software.  You acknowledge that:</p><br>

            <p><strong>(a)</strong>You may not be able to use Third Party Software, through integrating the Software or otherwise, unless you agree to be bound by additional licence terms imposed by the licensors of those products. You must satisfy Yourself that any such terms are appropriate and do not cause You to breach this EULA;</p>
                <br>
            <p><strong>(b)</strong> The integration and operation of Third Party Software with the Software relies on the data provided by those third parties;</p><br>

            <p><strong>(c)</strong> The Licensor has no control over Third Party Software and accepts no responsibility for it;</p><br>

            <p><strong>(d)</strong> The Licensor makes no warranties or representations regarding the integration or operation of the Software with Third Party Software, unless the specific Third Party Software program is authorised in writing by the Licensor and only then that one or more parts of the Software is compatible (to some extent) with one or more parts of that program;</p><br>

            <p><strong>(e)</strong> You release the SILQ Parties from any and all liability arising from Your use of any Third Party Software;</p><br>

            <p><strong>(f)</strong> No Silq Party is liable for any loss arising from any act or omission by a provider of Third Party Software, or any error, inaccuracy, incompleteness or other similar defect in the Third Party Software; </p><br>

            <p><strong>(g)</strong> Any information about Third Party Software, provided by the Licensor to You, has been given to use by the licensors of those products.  Those licensors are responsible for any representations that may be contained in that information;</p><br>

            <p><strong>(h)</strong> Subject to law, in the event of a data breach affecting a provider of Third Party Software, we will take reasonable steps to notify You if required to do so by law but no Silq Party will otherwise have any liability to You of any nature in respect of that Data Breach;</p><br>

            <p><strong>(i)</strong> The Licensor is not responsible for any thing, act or omission in connection with the Software’s integration with Third Party Software, including but not limited to any inaccuracies in data (including Practice Data as defined below) or time delays caused or contributed to by the Third Party Software.</p><br>

            <h2>8. CANCELLATIONS & RENEWALS</h2><br>
            <p><strong>(a) Auto-renewal:</strong> Your SILQ subscription will automatically renew either monthly or annually depending on the product You have chosen. Your account will be debited or Your card charged automatically based on the then current fees for the SILQ pricing option You have selected on a recurring basis until You cancel Your SILQ subscription.</p><br>

            <p><strong>(b) Cancellation:</strong> To cancel a SILQ subscription, You must login to the account management section of our website/the Software and follow the prompts. If You cancel a subscription earlier than the date on which Your subscription is due to expire, You will not receive a refund for that period. </p><br>

            <p><strong>(c) Data Export:</strong> Upon written request made by You within thirty (30) days of: </p><br>

            <p><strong>(d) </strong> the end of Your SILQ subscription having ended; </p><br>

            <p><strong>(e) </strong> the expiry of any free trial period; </p><br>

            <p><strong>(f) </strong> SILQ will make Your practice data from the SILQ subscription available to You for export or download for a reasonable fee as determined by SILQ and notified to You. You must login to the account management section of our website/the Software and follow the prompts to request an export or download. </p><br>

            <p><strong>(g) </strong> If you do not make such a request within the thirty (30) day period referred to above, subject to law SILQ may, but is not obliged to, delete or otherwise render inaccessible any of Your practice data. </p><br>

            <p><strong>(h) </strong> ‘<strong>Practice Data</strong>’  in this EULA means the data input by You into the Software or any other interface we make available to you. </p><br>

            <h2>9. PRICING</h2><br>
            <p>Promotional pricing and free trials cannot be combined with any other offers. Any promotional offers are available for a finite period of time only. Pricing terms, features and support are subject to change by SILQ at any time. We guarantee to keep You on the same pricing for a minimum of two (2) years before potentially imposing any increases SILQ may extend, cancel or amend any offer at any time, in its sole discretion. For new SILQ customers, any free or discounted period associated with a promotion will commence on the purchase date.</p><br>

            <h2>10. FREE TRIAL</h2><br>
            <p>SILQ may make available to You a free trial subscription that expires after the initial fourteen (14) days from date of You first accessing the Software, or earlier if You elect to buy a subscription before then.  You will not be required to pay fees for the subscription during the trial period, however You may still incur fees if You use Third Party Software in conjunction with the subscription as features that are charged on a per usage basis.  An example is SILQPay where You or Your client (depending on Your settings) are charged a processing fee each time an invoice is paid. Additionally, any costs associated with integrations such as Microsoft 365, DropBox, Infotrack etc are borne by You during the free trial as these products are not owned by SILQ; SILQ only integrates with these external providers.  To continue using SILQ after Your free trial expires, You will need to purchase a subscription and enter Your payment details in SILQ for processing.  During the free trial, You will have access to all features in the software irrespective of the different pricing plans.  Once You decide to subscribe, You can choose which product You would like to subscribe to and then Your features will be limited to what is available on that plan.</p><br>

            <h2>11. PRIVACY</h2><br>
            <p><strong>(a)</strong> By accepting the terms of this EULA, you also agree to be
                bound by the terms of our privacy policy (available at <a
                    href="https://silq.com.au/privacy-policy/" target="_blank">https://silq.com.au/privacy-policy/</a>);</p><br>
            <p><strong>(b)</strong>In particular, You must ensure that You have permission to input all Practice Data into the Software interface. </p><br>

            <h2>12. USING THE SOFTWARE:</h2>
            <p><strong>(a) Bank Feeds: </strong>The Software may be integrated with certain bank feeds that allow SILQ to download transactions into the Software direct from Your bank. You must comply with any terms imposed by Your bank or other third party in respect of such feeds.  SILQ does not operate nor have any control over the Software’s integration with any bank feeds and is not responsible for the security or integrity of the integration.  You release SILQ from any and all liability from Your use of any bank feeds. </p><br>

            <p><strong>(b) User Roles and Access:</strong> You are the subscriber. Subject to the fees You have paid and the licence granted above, You may be authorised to allow Your employees or contractors access to the Software. Those persons are ‘users’ and, as the subscriber, You are responsible for the conduct of all users, including ensuring that:</p>
            <ol style='font-size: 13px;'>
                <li>If you wish to allow some users access to parts of the
                    Software but not others, you are responsible for creating
                    and maintaining those access restrictions;</li>
                <li>Users do not cause you to breach this EULA;</li>
                <li>Users maintain the integrity of usernames and passwords and
                    keep them secure.</li>
            </ol>
            <p><strong>(c) Back ups:</strong> You are responsible for ensuring that all Practice Data entered into the Software is sufficiently backed up. We are not responsible, under any circumstances, for any data loss You may suffer in using the Software. </p><br>

            <p><strong>(d) Maintenance:</strong> We may need to restrict access to the Software in order to conduct maintenance. Where that maintenance is planned, we will try to give You reasonable notice; however, maintenance can be urgent and it may not be practical to give You advance notice of it. We are not responsible, under any circumstances, for any loss You may suffer as a result of downtime. </p><br>

            <p><strong>(e) Issues/Feedback:</strong> If You experience any issues in using the Software, please report them to us immediately through the account management section of the Software, or through our website. </p><br>
            <p><strong>(f) Privacy and Statistical Data:</strong></p>
            <ol style='font-size: 13px;'>
                <li>We may log and organise data generated from Your use of the Software. We do this to improve the Software and our other products, as well as create new products. The data that we log and organise will be aggregated and anonymised.</li>
                <li>By agreeing to this EULA, You also agree to our privacy policy that we update from time to time and is available from  <a href="https://www.silq.com.au/privacy-policy/"
                        target="_blank">https://www.silq.com.au/privacy-policy/</a></li>
            </ol><br>
            <h2>13. YOUR GENERAL RESPONSIBILITIES:</h2> You must: <br>
            <p><strong>(a)</strong> Only provide us with information that is complete and
                accurate;</p><br>
            <p><strong>(b)</strong> Keep secret all usernames and passwords issued to you in
                connection with your use of the Software and notify us
                immediately if any such information is compromised;</p><br>
            <p><strong>(c)</strong> Comply with all laws in your use of the Software;</p><br>
            <p><strong>(d)</strong>Not do anything to jeopardize the security measures we embed
                or implement in connection with the Software;</p><br>
            <p><strong>(e)</strong> Not introduce or upload any material that may damage the
                Software or any user’s ability to use the Software;</p><br>
            <p><strong>(f)</strong> In all dealings with the Licensor’s representatives, act with
                courtesy and in a respectful and professional manner;</p><br>
            <p><strong>(g)</strong> Immediately notify us if you change your email address or
                other contact details, by using the account management section
                of the Software.</p><br><br>

            <h2>14. GENERAL</h2><br>
            <p><strong>(a) ENTIRE AGREEMENT:</strong> This EULA is the entire agreement between You and the Licensor relating to the Software and supersedes all prior or contemporaneous oral or written communications, marketing materials, proposals and representations with respect to the Software or any other subject matter covered by this EULA. To the extent the terms of any Licensor agreement, policy or program for Support Services or any other document conflict with the terms of this EULA, the terms of this EULA shall control.</p><br>
            <p><strong>(b) NO ADVICE:</strong> You acknowledge that the Licensor does not provide professional advice and You ought to obtain your own independent advices in relation to Your rights and obligations under this EULA.  Further, You acknowledge that: </p><br>
            <ul style='font-size: 13px;'>
                <li>(i) if You hold moneys on trust You will be subject to duties at law in respect of such moneys; </li>
                <li>(ii) You are responsible for ensuring that You and Your authorised users comply with those duties; </li>
                <li>(iii) the Licensor does not provide any advice in relation to trust moneys; and </li>
                <li>(iv) the Licensor will not be responsible, under any circumstances, in relation to any breach by You or Your authorised users in relation to the handling of trust moneys.</li>
            </ul><br>
            <p><strong>(c) FORCE MAJEURE:</strong> The Licensor will not be liable for failure to perform its obligations under this EULA or otherwise in respect of the Software, to the extent that such failure is caused or contributed to by an act outside the Licensor’s reasonable control. </p><br>
            <p><strong>(d) DISPUTE RESOLUTION:</strong> If You have a dispute of any kind that in any way relates to this EULA or Your use of the Software, You must not commence proceedings in Court (except for urgent interlocutory relief) without first:</p><br>
            <ul style='font-size: 13px;'><br>
                <li>(i) Advising the Licensor of the nature of the dispute and providing all relevant information;</li>
                <li>(ii) Attending an informal discussion between You (or Your officer(s)) and the Licensor’s key personnel, to discuss a potential outcome of the dispute; and</li>
                <li>(iii) If the dispute remains unresolved, attending mediation in Sydney with a mediator agreed by the Licensor (or, if no agreement is reached, selected by the President of the Law Society of New South Wales), in which both parties must act in good faith to achieve a resolution and will each be responsible for their own costs and half of the mediator’s costs. </li>
            </ul>

        </div>

    </div>
</div>