import { Component, OnInit, Inject, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatterDialogComponent } from '../../time-entries/matter-dialog/matter-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, tap } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-file-note-dialog',
    templateUrl: './file-note-dialog.component.html',
    styleUrls: ['./file-note-dialog.component.scss']
})
export class FileNoteDialogComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    isspiner: boolean = false;
    isLoadingResults: boolean = false;
    errorWarningData: any = {};
    ShortName: any;
    action: any;
    matterGuid: any;
    FileNoteData: any = [];
    FormAction: string;
    FileGUID: string;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    public FilenotesNgData: any = {
        "USERNAME": "", "DATETEXT": new Date(), "DATE": this.datepipe.transform(new Date(), 'dd/MM/yyyy'), "TIME": "", "NOTE": "",
    };
    windowNameId: any;
    dialogTitle: any
    matterlist: any;
    mattername: any = [];
    matterautoVal: any = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchMatter: any;
    exportdatavalue: any;
    searchData: any;
    selectedData: any = [];
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    sub6: Subscription;
    sub7: Subscription;
    private searchTerms = new Subject<string>();
    private subscription: Subscription;
    constructor(
        public globalFunService: GlobalFunctionsService,
        public MatDialog: MatDialog,
        public dialogRef: MatDialogRef<FileNoteDialogComponent>,
        private _formBuilder: FormBuilder,
        public datepipe: DatePipe,
        public behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private toastr: ToastrService,
        public tooltipService : TooltipService
) {


        localStorage.setItem('istrackid', 'FileNotesDialogComponent');
        this.action = _data.action;
        this.sub = this.behaviorService.FileNotesData$.subscribe(result => {
            if (result) {
                this.FileNoteData = result;
            }
        });
        this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
            // old is GetFileNote
            if (localStorage.getItem('istrackid') == 'FileNotesDialogComponent' && result['click'] && result['data']['file-note']) {
                this.EditPopUpOPen();
            }
            // old is SetFileNote
            if (localStorage.getItem('istrackid') == 'FileNotesDialogComponent' && result['click'] && result['data']['file-note']) {
                this.SaveFileNote();
            }
        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        if (this.action === 'new') {
            this.dialogTitle = 'New File Note';
        } else if (this.action === 'edit') {
            this.dialogTitle = 'Update File Note';
        }
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.matterGuid = result;
        //   }
        // });

        // this.behaviorService.MatterData$.subscribe(result => {
        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //     this.matterGuid = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));

        //     //  for global matter selction
        //     if (this._data.globalMatter) {
        //         this.matterGuid = this._data.globalMatter;
        //     };

        //     if (this.matterGuid) {
        //     } else {
        //         if (result) {
        //             this.matterGuid = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.matterGuid))
        //         }
        //     }
        // });

/**
 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
 * @remarks
 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
 */
      this.sub2 = this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.matterGuid = result;
                    } else {
                        this.matterGuid = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterGuid));
                    }
                })
            )
            .subscribe();
        this.ShortName = this.matterGuid.SHORTNAME;
        if (this.action != 'new') {
            this.EditPopUpOPen();
        } else {
            this.setDefultAPIcall();
        }

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.sub6?.unsubscribe();
        this.sub7?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    setDefultAPIcall() {
        this.isLoadingResults = true;
        // let finalPassdata=
        let finalPassdata: any = { FormAction: 'default', DATA: {} };
        // old is SetFileNote new is  file-note
       this.sub3 = this._mainAPiServiceService.getSetData(finalPassdata, 'file-note').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
                let getperefrence = JSON.parse(localStorage.getItem("set_preferences"));
                //this.FilenotesNgData.USERNAME = currentUser.UserName;
                this.FilenotesNgData.USERNAME =(getperefrence && getperefrence.USERFULLNAME !='' && getperefrence.USERFULLNAME !=undefined && getperefrence.USERFULLNAME !=null) ? getperefrence.USERFULLNAME : '';
                this.toolTipList = res.DATA.FIELDTIPS
                let filenoteData = res.DATA.DEFAULTVALUES;
                if (filenoteData.DATE_) {
                    let DATE1 = filenoteData.DATE_.split("/");
                    this.FilenotesNgData.DATETEXT = new Date(DATE1[1] + '/' + DATE1[0] + '/' + DATE1[2]);
                    this.FilenotesNgData.DATE = filenoteData.DATE_;
                }

                this.matterautoVal = this.matterGuid.MATTERGUID;
                this.mattername = [this.matterGuid.SELECTEDDESCRIPTION];

                //  this.FilenotesNgData.TIME = this.convertTime12to24(filenoteData.TIME_);
                this.FilenotesNgData.TIME = this.convertTime12to24(filenoteData.TIME);
                this.CheckdefultValidation(res.DATA.VALIDATIONS);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }
    CheckdefultValidation(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    }
    EditPopUpOPen() {
        this.isLoadingResults = true;
        //FileNotes API new changes old is 'GetFileNote' current is file-note. old Payload is : { FILENOTEGUID: this.FileNoteData.FILENOTEGUID }
        let Payload = {
            "Action": "GetData",
            "Filters": {
                FILENOTEGUID: (this._data?.TABLEGUID) ? this._data.TABLEGUID : this.FileNoteData.FILENOTEGUID
            },
        }
       this.sub4 = this._mainAPiServiceService.getSetData(Payload, 'file-note').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                if (res.DATA && res.DATA.RECORDS) {
                    res.DATA.FILENOTES = res.DATA.RECORDS;
                };
                this.matterautoVal = this.matterGuid.MATTERGUID;
                this.mattername = [this.matterGuid.SELECTEDDESCRIPTION];
                this.toolTipList = res.DATA.FIELDTIPS
                this.isLoadingResults = false;
                let filenoteData = res.DATA.FILENOTES[0];
                this.FilenotesNgData = res.DATA.FILENOTES[0];
                this.FilenotesNgData.TIME = this.convertTime12to24(filenoteData.TIME);
                if (filenoteData.DATE) {
                    let DATE1 = filenoteData.DATE.split("/");
                    this.FilenotesNgData.DATETEXT = new Date(DATE1[1] + '/' + DATE1[0] + '/' + DATE1[2]);
                    this.FilenotesNgData.DATE = filenoteData.DATE;
                }
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        });
    }

    convertTime12to24(str) {
        str = String(str).toLowerCase().replace(/\s/g, '');
        var has_am = str.indexOf('am') >= 0;
        var has_pm = str.indexOf('pm') >= 0;
        // first strip off the am/pm, leave it either hour or hour:minute
        str = str.replace('am', '').replace('pm', '');
        // if hour, convert to hour:00
        if (str.indexOf(':') < 0) str = str + ':00';
        // now it's hour:minute
        // we add am/pm back if striped out before
        if (has_am) str += ' am';
        if (has_pm) str += ' pm';
        // now its either hour:minute, or hour:minute am/pm
        // put it in a date object, it will convert to 24 hours format for us
        var d = new Date("1/1/2011 " + str);
        // make hours and minutes double digits
        var doubleDigits = function (n) {
            return (parseInt(n) < 10) ? "0" + n : String(n);
        };
        return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
    }
    choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.FilenotesNgData.DATE = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    }
    //Save File Note
    SaveFileNote() {
        if (this.action == 'new' || this.action == "duplicate") {
            this.FormAction = "insert";
            this.dialogTitle = 'New File Note'
            this.FileGUID = ''
        } else {
            this.FormAction = "update";
            this.dialogTitle = 'Update File Note'
            this.FileGUID = this.FileNoteData.FILENOTEGUID;
        }

        let passdata = {
            FILENOTEGUID: this.FileGUID,
            MATTERGUID: this.matterautoVal,
            //   MATTERGUID: this.matterGuid.MATTERGUID,
            USERNAME: this.FilenotesNgData.USERNAME,
            DATE: this.FilenotesNgData.DATE,
            TIME: this.FilenotesNgData.TIME,
            NOTE: this.FilenotesNgData.NOTE,
        }

        this.setValue(passdata);
    }
    setValue(passdata) {
        this.isspiner = true;
        // let finalPassdata=
        let finalPassdata: any = { FormAction: this.FormAction, VALIDATEONLY: true, DATA: passdata };
        // old is SetFileNote new is file-note
       this.sub5 = this._mainAPiServiceService.getSetData(finalPassdata, 'file-note').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, finalPassdata);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, finalPassdata);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, finalPassdata);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }
    async checkValidation(bodyData: any, PostFileNoteData: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'Warning') {
        //     warningData.push(value.ERRORDESCRIPTION);
        //     tempWarning[value.FIELDNAME] = value;
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   // this.toastr.warning(warningData);
        //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.FinalsaveFileNote(PostFileNoteData);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.FinalsaveFileNote(PostFileNoteData);

        // }
        this.isspiner = false;
       this.sub6 = await this.globalFunService.checkValidation(bodyData, PostFileNoteData)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    // this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.FinalsaveFileNote(PostFileNoteData);
                    }
                }
            });
    }
    FinalsaveFileNote(PostFileNoteData: any) {
        PostFileNoteData.VALIDATEONLY = false;
        //old is SetFileNote new is file-note
        this._mainAPiServiceService.getSetData(PostFileNoteData, 'file-note').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.success('Note save successfully');
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.isspiner = false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.isspiner = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.toastr.error(error);
        });


    }


    // matter auto select.

    public selectMatter() {
        this.searchData = [];
        this.matterlist = this.searchData;

        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                localStorage.setItem('istrackid', 'timeentriesdialogcomponent');

                this.matterautoVal = result.MATTERGUID
                this.mattername = [result.SELECTEDDESCRIPTION];


            }
        });
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    public selectMatterFormSearch(event) {
        let searchobj = {
            "Action": "GetData",
            "Filters": { FastSearch: 'True', SearchString: event }
        };

      return this._mainAPiServiceService.getSetData(searchobj, 'matter')

    }

    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SELECTEDDESCRIPTION];
                this.matterautoVal = null;
                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }

                this.matterautoVal = data.MATTERGUID
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }

    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.matterautoVal = ''
        };

        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };

    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        };

        this.matterautoVal = null
    };

    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.matterautoVal = null
        }
    }

    selectedmatterguid(data: any) {
        this.matterautoVal = data.MATTERGUID
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }
    };

}
