<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_packets_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="packetsform" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PACKETNUMBER,'has-warning':errorWarningData.Warning?.PACKETNUMBER}">
                        <mat-label>Packet Num</mat-label>
                        <input formControlName="PACKETNUMBER" matInput matTooltip="{{(toolTipList)?toolTipList.PACKETNUMBER?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" >
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PACKETNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PACKETNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>

                    <mat-form-field class="pr-4" fxFlex="33" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.CONTACT,'has-warning':errorWarningData.Warning?.CONTACT}">
                        <mat-label>Contact</mat-label>
                        <input formControlName="CONTACT" readonly matInput matTooltip="{{(toolTipList)?toolTipList.CONTACT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-icon matSuffix (click)='SelectContact()' fxFlex="5" [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img class="seticon" src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.LOCATION,'has-warning':errorWarningData.Warning?.LOCATION}">
                        <mat-label>Location</mat-label>
                        <input formControlName="LOCATION" matInput matTooltip="{{(toolTipList)?toolTipList.LOCATION?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOCATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.LOCATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PACKETDESCRIPTION,'has-warning':errorWarningData.Warning?.PACKETDESCRIPTION}">
                        <mat-label>Description</mat-label>
                        <textarea matInput matTooltip="{{(toolTipList)?toolTipList.PACKETDESCRIPTION?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="PACKETDESCRIPTION"></textarea>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PACKETDESCRIPTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PACKETDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
 
                    </mat-form-field>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-checkbox formControlName="ISACTIVE">is Active</mat-checkbox>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="PacketsSave()" [disabled]="isspiner"
            *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="PacketsSave()" [disabled]="isspiner"
            *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="PacketsSave()" [disabled]="isspiner"
            *ngIf="action !== 'new' && action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>