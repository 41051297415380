import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fieldConfigColor'
})
export class FieldConfigColorPipe implements PipeTransform {

    /**
     * This function is used to transform the data.
     */
    transform(value: any, ...args: any[]): any {
        if (value == 'Matter') {
            if (args[0].INVOICEDVALUECOLOUR && args[0].INVOICEDVALUECOLOUR !== '' && (args[1] == 'Invoiced (Ex)' || args[1] == 'Invoiced (Inc)')) {
                return (args[2] == 'tooltip') ? args[0].INVOICEDVALUETIP : '#' + args[0].INVOICEDVALUECOLOUR;
            } else if (args[0].TOTALVALUECOLOUR && args[0].TOTALVALUECOLOUR !== '' && args[1] == 'Total Value (Ex)') {
                return (args[2] == 'tooltip') ? args[0].TOTALVALUETIP : '#' + args[0].TOTALVALUECOLOUR;
            } else if (args[0].NETTRUSTBALANCECOLOUR && args[0].NETTRUSTBALANCECOLOUR !== '' && args[1] == 'Exposure') {
                return (args[2] == 'tooltip') ? args[0].NETTRUSTBALANCETIP : '#' + args[0].NETTRUSTBALANCECOLOUR;
            } else {
                return '';
            }
        }

        if (value == 'receipts-credits') {
            if (args[0].ROWCOLOUR && args[0].ROWCOLOUR !== '') {
                return (args[2] == 'tooltip') ? args[0].ROWTOOLTIP : '#' + args[0].ROWCOLOUR;
            }
        }
    }

}
