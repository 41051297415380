import { BehaviorService } from '@_services/Behavior.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-account-reconciliation',
  templateUrl: './account-reconciliation.component.html',
  styleUrls: ['./account-reconciliation.component.scss']
})
export class AccountReconciliationComponent implements OnInit, OnDestroy {
  activeSubMenu: any;
  showFiller: boolean = true;
  chartAccountDetail: any;
  sub: Subscription;
  constructor(public behaviorService: BehaviorService, private router: Router) {
    this.sub = this.behaviorService.ActiveSubMenu$.subscribe(res=>{
      this.activeSubMenu = res
    })
    this.behaviorService.AccountTypeData$.subscribe((result) => {
      if (result) {
        this.chartAccountDetail = result;
      }
    });
   }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

}
