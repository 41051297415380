<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div timeentry_popup select_metter_main_div " cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch"> Timer :
        {{matterShortName}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one  mb-24" fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                name="resumeTimerForm" id="resumeTimerForm"
                [formGroup]="resumeTimerForm">
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <div class=" one  mb-24" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                                <mat-label>Matter</mat-label>
                                <input matInput readonly
                                    placeholder="Placeholder"
                                    formControlName="matterautoVal"
                                    matTooltip="{{(toolTipList)?toolTipList.MATTERGUID?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion">
                                <mat-icon matSuffix (click)='selectMatter()'>
                                    <img
                                        src="assets/icons/web_app/icon_matter_d.ico"
                                        matTooltip matTooltipPosition="above">
                                </mat-icon>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field *ngIf="'' | productType" fxFlex="20"
                                class="pr-4" appearance="outline"
                                [ngClass]="{'has-error':errorWarningData.Error?.FEEEARNER,'has-warning':errorWarningData.Warning?.FEEEARNER}">
                                <mat-label>Fee Earner</mat-label>
                                <mat-select formControlName="FEEEARNER"
                                    (selectionChange)="matterChange('FeeEarner',$event.value)"
                                    [disabled]="('Create WIP for Other Fee Earners' | appPermissions : 'Create WIP for Other Fee Earners') ==0?true:false">
                                    <mat-option *ngFor="let user of userList"
                                        value="{{user.USERID}}">{{user.FULLNAME}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEEARNER?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.FEEEARNER?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="pr-4" appearance="outline"
                                [ngClass]="{'has-error':errorWarningData.Error?.ITEMDATE,'has-warning':errorWarningData.Warning?.ITEMDATE}">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="picker"
                                    placeholder="Choose a date"
                                    (dateInput)="resumeDate('input', $event)"
                                    (dateChange)="resumeDate('change', $event)"
                                    formControlName="ITEMDATETEXT"
                                    matTooltip="{{(toolTipList)?toolTipList.ITEMDATE?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMDATE?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.ITEMDATE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                                <mat-label>Time of day</mat-label>
                                <input type="text" placeholder="Time of day" aria-label="Number" matInput (focusout)="getTime()" formControlName="ITEMTIME" [matAutocomplete]="auto1" matTooltip="{{(toolTipList)?toolTipList.ITEMTIME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-autocomplete #auto1="matAutocomplete">
                                    <mat-option *ngFor="let option of timeStops" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>

                           <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMTIME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMTIME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <div fxFlex="20" class="timer-time-div"> <b>{{timerTime}}</b> </div> -->
                            <mat-form-field appearance="outline" fxFlex="20"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                                <mat-label>Time of day</mat-label>
                                <input class="time-input" type="time"
                                    placeholder="Time of day"
                                    aria-label="Number" matInput
                                    formControlName="ITEMTIME"
                                    matTooltip="{{(toolTipList)?toolTipList.ITEMTIME?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion">
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMTIME?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.ITEMTIME?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="15"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.RATEID,'has-warning':errorWarningData.Warning?.RATEID}">
                                <mat-label>Rate ID</mat-label>
                                <mat-select formControlName="RATEID"
                                    (selectionChange)="reateId($event.value)">
                                    <mat-option *ngFor="let rateids of rateid"
                                        [value]="rateids.LOOKUPGUID">
                                        {{rateids.LOOKUPFULLVALUE}}</mat-option>
                                </mat-select>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEID?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.RATEID?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="15" class="pr-4"
                                appearance="outline"
                                [ngClass]="{'has-error':errorWarningData.Error?.QUANTITYTYPE,'has-warning':errorWarningData.Warning?.QUANTITYTYPE}">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="QUANTITYTYPE"
                                    (selectionChange)="quntityTypeChange('QuantityType',$event.value)">
                                    <mat-option
                                        *ngFor="let Activity of ActivityList"
                                        value="{{Activity.ACTIVITYID}}">
                                        {{Activity.DESCRIPTION}}</mat-option>
                                </mat-select>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITYTYPE?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.QUANTITYTYPE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="15"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.QUANTITY,'has-warning':errorWarningData.Warning?.QUANTITY}">
                                <mat-label>Duration</mat-label>
                                <input matInput formControlName="QUANTITY"
                                    (focusout)="matterChange('Quantity',$event.value)"
                                    matTooltip="{{(toolTipList)?toolTipList.QUANTITY?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion">
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITY?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.QUANTITY?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="15"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.PRICE,'has-warning':errorWarningData.Warning?.PRICE}">
                                <mat-label>Price (Ex GST)</mat-label>
                                <!-- currencyMask -->
                                <input class="sideText" matInput
                                    formControlName="PRICE"
                                    (blur)="calcPE($event)" [value]="resumeTimerForm.get('PRICE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."
                                    matTooltip="{{(toolTipList)?toolTipList.PRICE?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion" [leadZero]="true">

                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICE?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.PRICE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="15"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.PRICEINCGST,'has-warning':errorWarningData.Warning?.PRICEINCGST}">
                                <mat-label>Price (Inc GST)</mat-label>
                                <input class="sideText" matInput
                                    formControlName="PRICEINCGST"
                                    (blur)="calcPI($event)"
                                    [value]="resumeTimerForm.get('PRICEINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."
                                    [leadZero]="true"
                                    matTooltip="{{(toolTipList)?toolTipList.PRICEINCGST?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion">

                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICEINCGST?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.PRICEINCGST?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="30"
                                class="pr-4"
                                *ngIf="DerivableDropShow==false && currentUser?.PRODUCTTYPE!='Barrister' &&  ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1">
                                <mat-label>Deliverable</mat-label>
                                <!-- [disabled] = " _data.edit == 'Add'" -->
                                <mat-select
                                    formControlName="MATTERDELIVERABLEGUID"
                                    (selectionChange)="radioChange($event.value)">
                                    <mat-option
                                        [ngClass]="data.DELIVERABLEDESCRIPTION.length >= 50 ? 'my-multi-lines-item my-multi-lines-text' : ''"
                                        *ngFor="let data of Deliverable"
                                        [value]="data.MATTERDELIVERABLEGUID">{{data.DELIVERABLEDESCRIPTION}}</mat-option>
                                    <!-- <mat-option value="Mandatory">Mandatory</mat-option>
                                        <mat-option value="Optional">Optional</mat-option> -->

                                </mat-select>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERDELIVERABLEGUID?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.MATTERDELIVERABLEGUID?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <!-- <mat-form-field appearance="outline" fxFlex="100"  class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEID,'has-warning':errorWarningData.Warning?.RATEID}">
                                <mat-label>Rate ID</mat-label>
                                <input matInput formControlName="RATEID">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->

                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100"
                                class="pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALTEXT,'has-warning':errorWarningData.Warning?.ADDITIONALTEXT}">
                                <mat-label>Invoice Text</mat-label>
                                <textarea type="text" matInput
                                    formControlName="ADDITIONALTEXTSELECT"
                                    [matAutocomplete]="auto"></textarea>
                                <mat-autocomplete #auto="matAutocomplete"
                                    [panelWidth]="800"
                                    (optionSelected)="LookupsChange($event.option.value)">
                                    <mat-option
                                        *ngFor="let Lookups of filteredOptions | async"
                                        [value]="Lookups.LOOKUPFULLVALUE"
                                        matTooltip="{{(toolTipList)?toolTipList.ADDITIONALTEXT?.HOVER:''}}"
                                        [matTooltipPosition]="toolTipPostion">
                                        {{Lookups.LOOKUPFULLVALUE}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALTEXT?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.ADDITIONALTEXT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100"
                                class=" pr-4"
                                [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}">
                                <mat-label>Notes</mat-label>
                                <textarea cdkTextareaAutosize
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="5" matInput
                                    formControlName="COMMENT"
                                    matTooltip="{{(toolTipList)?toolTipList.COMMENT?.HOVER:''}}"
                                    [matTooltipPosition]="toolTipPostion"></textarea>
                                <div *ngIf="testBool && toolTipList"
                                    class="tooltip-wrap"
                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input
                                        id="myInput" readonly disabled
                                        value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent"
            (click)="startTimer()">Start</button>
        <button mat-raised-button color="accent" class="mat-accent-blue"
            [disabled]="isspiner" (click)="SaveClickTimeEntry()">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
