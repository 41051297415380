import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'wordwrap'
})
export class WordwrapPipe implements PipeTransform {

    /**
     * This function is used to transform the data.
     */
    transform(value: any, args?: any): any {
        // if (args) {
        //   return (value.length > environment.WORDLIMIT) ? value.substring(0, environment.WORDLIMIT - 1) + '...' : value;
        // } else
        return value;
    }

}
