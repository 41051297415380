import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
    name: "highlightSearch",
})
export class HighlightSearchPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    // transform(value: any, args?: any): any {
    //   if (!args || !value) {
    //     return value;
    //   }
    //   // Match in a case insensitive maneer

    //   const re = new RegExp(args, 'gi');

    //   if (value.match(re) && value) {
    //     const match = value.match(re);
    //     if (!match) {
    //       return value;
    //     }
    //     const replacedValue = value.replace(re, "<mark>" + match[0] + "</mark>")
    //     return this.sanitizer.bypassSecurityTrustHtml(replacedValue)
    //   } else {
    //     return value;
    //   }
    // }

    /**
     * This function is used to transform the data.
     */
    transform(
        data: string,
        highlightText: string,
        option: string = "Single-And-StartsWith-Match",
        caseSensitive: boolean = false,
        highlightStyleName: string = "search-highlight"
    ): SafeHtml {
        if (highlightText && data && option) {
            let regex: any = "";
            let caseFlag: string = !caseSensitive ? "i" : "";
            switch (option) {
                case "Single-Match": {
                    regex = new RegExp(highlightText, caseFlag);
                    break;
                }
                case "Single-And-StartsWith-Match": {
                    regex = new RegExp("." + highlightText, caseFlag);
                    break;
                }
                case "Multi-Match": {
                    regex = new RegExp(highlightText, "g" + caseFlag);
                    break;
                }
                default: {
                    // default will be a global case-insensitive match
                    regex = new RegExp(highlightText, "gi");
                }
            }

            //   return this.sanitizer.bypassSecurityTrustHtml(
            //     data.toString().replace(regex, (match) =>  "<mark>" + match[0] + "</mark>")
            // );

            //  data.toString().replace(regex, (match) => "<mark>" + match[0] + "</mark>");

            return this.sanitizer.bypassSecurityTrustHtml(
                "<span>" + data + "</span>"
            );
        } else {
            return data;
        }
    }
}
