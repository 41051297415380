import { Component, OnInit, Input } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormatVariableComponent } from './insert-with-formating/format-variable.component';
import { NewFieldComponent } from './new-field/new-field.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';


@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
  animations: fuseAnimations
})
export class EditTemplateComponent implements OnInit {
  @Input() SettingForm: FormGroup;
  @Input() errorWarningData: any;
 
  addData:any=[];
  btnClick: string;
  constructor(private _mainAPiServiceService:MainAPiServiceService, public _matDialog: MatDialog,
    public dialogRef: MatDialogRef<EditTemplateComponent>) { }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.btnClick='EditTemplate';
    // this._mainAPiServiceService.getSetData({}, 'GetSystem').subscribe(response=>{
    //   this.addData=response.DATA.SYSTEM.ADDRESSGROUP.POSTALADDRESSGROUP
    // })
    
  }

  /**
   * This function is used to Insert with the Form
   */
  InsertWithForm(){
      const dialogRef = this._matDialog.open(FormatVariableComponent, { width: '100%', disableClose: true, });
      dialogRef.afterClosed().subscribe(result => {
          if (result) {
              // localStorage.setItem('set_active_matters', JSON.stringify(result));
          }
      });
 
  }

  /**
   * This function is used to Edit the User
   */
  EditUser(){
  this.btnClick='EditUser';

  }

  /**
   * THis function is used to Edit the Template
   */
  EditTemplate(){
    this.btnClick='EditTemplate';
  }

  /**
   * THis function is used to New Add field
   */
  NewAddField(){
    const dialogRef = this._matDialog.open(NewFieldComponent, { width: '100%', disableClose: true, });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            // localStorage.setItem('set_active_matters', JSON.stringify(result));
        }
    });
  }

  /**
   * This function is used to close the popup
   */
  closepopup(){
    this.dialogRef.close(false);
  }



}
