<div class="example-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_matter_main" cdkDrag>
  <h2 cdkDragHandle mat-dialog-title>Setup Multi Factor Authentication
      <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
          <mat-icon>close</mat-icon>
      </button>
  </h2>
  <div mat-dialog-content class="mat-typography main">
      <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
        <div style="width: 100%;height: 100%;">
            <div class="auth-container" *ngIf="!isSecurityQuestion" >
                <div class="main-section-3"  >
                    <div align=center *ngIf="!isReady && !isSecurityQuestion">
                        <div class="text-center" style="width: 80%;">
                            <h3>Using your smart phone, please either go to the Apple or Android Store and download the Google Authenticator app.</h3>
                          </div>
                          <div class="text-center pb-15" >
                            <button matbutton class="btn-blue" (click)="QrCodeGenerator()"> Ready</button>
                            <p>
                              <!-- <a href="javascript:void(0)" (click)="getQuetionsOne();getQuetionsTwo();getQuetionsThree()" class="light-link"> -->
                                <!-- <a href="javascript:void(0)"  class="light-link">
                                    Don’t have Smartphone or Tablet? Click here      
                                </a> -->
                            </p>
                          </div>
                    </div>
                  <div *ngIf="isReady">
                    <div class="text-center" >
                      <h3>Follow these steps on your phone</h3>
                    </div>
                    <div class="steps-wrap" >
                      <div class="inner-steps">
                        <p>
                          <b>Step 1 :</b> Open Your authenticator.
                        </p>
                      </div>
                      <div class="inner-steps" *ngIf="AWAITING2FA == 'false' || AWAITING2FA == 'default'" >
                        <p>
                          <b>Step 2 :</b>
                          Add your account to the app by scanning this QR code.
                        </p>
                        <div class="text-center">
                          <qr-code *ngIf="!isSetupKeyGenerator" [value]="QrCode" size="180"></qr-code>
                          <div align=center *ngIf="isSetupKeyGenerator" >
                            <div class="text-left" style="padding: 20px 0 30px 0;">
                              <div class="row">
                                <div class="col-2"><b>Account</b> </div>
                                {{account.ACCOUNT}}
                              </div>
                              <div class="row">
                                <div class="col-2"><b>Base</b> </div>
                                {{account.BASE}}
                              </div>
                              <div class="row">
                                <div class="col-2"><b>Key</b> </div>
                                {{account.KEY}}
                              </div>
                            </div>
                          </div>
                          <!-- <mat-list *ngIf="isSetupKeyGenerator">
                            <mat-list-item>
                              <mat-label>Account</mat-label>
                              <div mat-line>{{account.ACCOUNT}}</div>
                            </mat-list-item>
                            <mat-list-item>
                              <mat-label>Base</mat-label>
                              <div mat-line>{{account.BASE}}</div>
                            </mat-list-item>
                            <mat-list-item>
                              <mat-label>Key</mat-label>
                              <div mat-line>{{account.KEY}}</div>
                            </mat-list-item>
                          </mat-list> -->
                          <div class="pb-15">
                            <a *ngIf="!isSetupKeyGenerator" href="javascript:void(0)" (click)="SetupKeyCodeGenerator();  isSetupKeyGenerator = true;" class="light-link">
                              Can't scan the QR code? Enter a setup key instead         
                            </a>
                            <a *ngIf="isSetupKeyGenerator" href="javascript:void(0)" (click)="QrCodeGenerator();  isSetupKeyGenerator = false;" class="light-link">
                              Scan the QR code!
                          </a>
                          </div>
                        </div>
                      </div>
                      <div class="inner-steps">
                        <p>
                          <b *ngIf="AWAITING2FA == 'false' || AWAITING2FA == 'default'" >Step 3 :</b>
                          <b *ngIf="AWAITING2FA == 'true'" >Step 2 :</b>
                          Enter the 6-digit code found in your authenticator app under
                          <a href="javascript:void()" class="light-link">{{this.currentUser.EXPORTACCOUNTSEMAIL}}</a>
                          <!-- <a href='mailto:support@silq.com.au' class="light-link">support@silq.com.au</a> -->
                        </p>
                        <div class="text-center pb-15" *ngIf="!isPasswordEnter">
                          <button matbutton class="btn-blue" (click)="isPasswordEnter = true"> Enter Code</button>
                        </div>
                        <mat-form-field  class="" appearance="outline" style="width: 100%;display: block" *ngIf="isPasswordEnter">
                          <mat-label>Enter Code</mat-label>
                          <input matInput [(ngModel)]="user.generatedCode">
                        </mat-form-field>
                        <div class="text-center pb-15" *ngIf="isPasswordEnter">
                          <button *ngIf="AWAITING2FA == 'false' || AWAITING2FA == 'default'" [disabled]="!user.generatedCode" matbutton class="btn-blue" (click)="CodeSubmit();"> Continue</button>
                          <button *ngIf="AWAITING2FA == 'true'" [disabled]="!user.generatedCode" matbutton class="btn-blue" (click)="CodeSubmit_disbale();"> Continue</button>
                        </div>
                      </div>
                    </div>
                    <div >
                    </div>
                  </div>
            
                </div>
            </div>
            <div class="auth-container container-2"  *ngIf="isSecurityQuestion">
                <mat-card class="security">
                    <div  >
                        <h3 class="text-center">Security Questions</h3>
                      <div fxLayout="row"  fxLayoutAlign="start center" fxFlex="1 0 auto" >
                          <mat-form-field class="left-dropdown " appearance="outline" fxFlex="100">
                              <mat-label>Backup Email</mat-label>
                              <input matInput [(ngModel)]="securityObject.backupemailaddress" required>
                          </mat-form-field>
              
                      </div>
                      <div fxLayout="row"  class="quetion-group">
                        <mat-label class="head-label">Security Question 1</mat-label>
                          <mat-form-field class="left-dropdown " appearance="outline" fxFlex="100">
                              <mat-label>Security Question 1</mat-label>
                              <mat-select [(ngModel)]="securityObject.securityquestion1" required>
                                  <mat-option *ngFor="let question of SecurityQuestionsOne" value="{{question.LOOKUPFULLVALUE}}">
                                      {{question.LOOKUPFULLVALUE}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                          <mat-form-field class="left-dropdown " appearance="outline" fxFlex="50">
                            <mat-label>Security Answer 1</mat-label>
                            <input matInput [(ngModel)]="securityObject.securityanswer1" required>
                        </mat-form-field>
                      </div>
                      <div fxLayout="row" class="quetion-group" >
                        <mat-label  class="head-label">Security Question 2</mat-label>
                        <mat-form-field class="left-dropdown " appearance="outline" fxFlex="100">
                            <mat-label>Security Question 2</mat-label>
                            <mat-select [(ngModel)]="securityObject.securityquestion2" required>
                                <mat-option *ngFor="let question of SecurityQuestionsTwo" value="{{question.LOOKUPFULLVALUE}}">
                                    {{question.LOOKUPFULLVALUE}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="left-dropdown " appearance="outline" fxFlex="50">
                          <mat-label>Security Answer 2</mat-label>
                          <input matInput [(ngModel)]="securityObject.securityanswer2" required>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" class="quetion-group" >
                        <mat-label  class="head-label">Security Question 3</mat-label>
                        <mat-form-field class="left-dropdown " appearance="outline" fxFlex="100">
                            <mat-label>Security Question 3</mat-label>
                            <mat-select [(ngModel)]="securityObject.securityquestion3" required>
                                <mat-option *ngFor="let question of SecurityQuestionsThree" value="{{question.LOOKUPFULLVALUE}}">
                                    {{question.LOOKUPFULLVALUE}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="left-dropdown " appearance="outline" fxFlex="50">
                          <mat-label>Security Answer 3</mat-label>
                          <input matInput [(ngModel)]="securityObject.securityanswer3" required>
                      </mat-form-field>
                    </div>
                    </div>
                    <div class="text-center pb-15">
                        <button matbutton class="btn-blue" (click)="onSecuritySubmit();"> Submit</button>
                      </div>
                </mat-card>
            </div>
                
            <!-- <mat-card class="password-box" *ngIf="isAuthAllowed && isPasswordEnter">
                <mat-form-field  class="" appearance="outline" style="width: 100%;display: block">
                  <mat-label>Password</mat-label>
                  <input matInput [(ngModel)]="user.password">
                </mat-form-field>
                <div class="text-center pb-15">
                  <button [disabled]="!user.password" matbutton class="btn-blue" (click)="onPasswordSubmit();"> Countinue</button>
                </div>
            </mat-card> -->
        </div>
      </div> 
  </div>
  <mat-dialog-actions align="end" class="popup_ftr">
    <!-- <button mat-raised-button color="accent" class="mat-accent" (click)="ondialogSaveClick()" [disabled]="isspiner" *ngIf="action !== 'edit'">
        <mat-spinner *ngIf="isspiner"></mat-spinner>Save
    </button>
    <button mat-raised-button color="accent" class="mat-accent" (click)="ondialogSaveClick()" [disabled]="isspiner" *ngIf="action === 'edit'">
        <mat-spinner *ngIf="isspiner"></mat-spinner>Update
    </button> -->
    <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Close</button>
</mat-dialog-actions>
</div>