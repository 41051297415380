<button
    mat-raised-button
    color="primary"
    id="refreshMatterTab"
    (click)="refreshMatterTab(false)">
    Refresh
</button>
<div class="btn top_right_action matter_right_action dot_controller">
    <button mat-icon-button [matMenuTriggerFor]="Menu" aria-label="More">
        <mat-icon class="secondary-text">more_vert</mat-icon>
    </button>
    <mat-menu #Menu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="openDialog()">
                Field Configuration
            </button>
            <!-- <button mat-menu-item (click)="refreshMatterTab(false)">
                Reload Data
            </button>
            <button mat-menu-item (click)="refreshMatterTab(true)">
                Request All Data
            </button> -->
            <!-- <button mat-menu-item (click)="printPage()">Create Report</button> -->
            <!-- <button mat-menu-item (click)="downloadFile(mattersData.data)">
                <img src="assets/icons/web_app/EXP_CSV.ico" style="height: 20px !important;margin-top: 10px;">
                Download CSV
            </button> -->
            <!-- <button mat-menu-item (click)="DownloadAllData()">
                <img src="assets/icons/web_app/EXP_CSV.ico" style="height: 20px !important;margin-top: 10px;">
                Download CSV
            </button>
            <button mat-menu-item (click)="downloadFile(mattersData.data)">
                <img src="assets/icons/web_app/EXP_CSV.ico" style="height: 20px !important;margin-top: 10px;">
                Download CSV with filter
            </button>  -->

            <!-- New Changes Start Here -->

            <!-- <button mat-menu-item (click)="DownloadAllData()">
                <img
                    src="assets/icons/web_app/EXP_CSV.ico"
                    style="height: 20px !important; margin-top: 10px" />
                Export to CSV - retrieve all data first
            </button> -->
            <!-- <button mat-menu-item (click)="downloadFile(mattersData.data)">
                <img
                    src="assets/icons/web_app/EXP_CSV.ico"
                    style="height: 20px !important; margin-top: 10px" />
                Export to CSV - current data
            </button> -->

            <!-- New Changes End Here -->
            <button mat-menu-item (click)="getNewExportData()">
                <img
                    src="assets/icons/web_app/EXP_CSV.ico"
                    style="height: 20px !important; margin-top: 10px" />
                Export to CSV 
            </button>

            <!-- <button mat-menu-item [matMenuTriggerFor]="CreateReport">Create Report</button> -->
        </ng-template>
    </mat-menu>
    <mat-menu #CreateReport="matMenu">
        <button mat-menu-item (click)="downloadAsPDF('p')">Portrait</button>
        <button mat-menu-item (click)="downloadAsPDF('l')">Landscape</button>
    </mat-menu>
    <button mat-icon-button (click)="showDetails()" aria-label="More">
        <mat-icon class="secondary-text">menu</mat-icon>
    </button>
</div>

<div class="one content">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <div
        class="resizable-table more_tbl_clmn"
        [ngClass]="class == true ? 'Table-height' : 'Table-height-2'"
        #matterTable
        id="matterTableId">
        <div class="grid-overlay" *ngIf="isDisplay">
            <div class="grid-overlay-msg">There is no data to display.</div>
        </div>
        <mat-table
            id="matterListTable"
            class="modern"
            #Table
            [dataSource]="mattersData"
            (matSortChange)="onSortChanged($event)"
            [matSortActive]="sortactive"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            matSort
            [@animateStagger]="{ value: '50' }"
            cdkDropListGroup>
            <ng-container
                *ngFor="let item of displayedColumns; let i = index"
                [matColumnDef]="item">
                <!-- Multiple Select -->
                <span *ngIf="item == 'select'">

                    <mat-header-cell *matHeaderCellDef class="checkbox_width">
                        <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="checkbox_width">
                        <mat-checkbox
                            (change)="$event ? selection.toggle(row) : null; collectSelectedVal()"
                            [(ngModel)]="row.TAGGED"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </mat-cell>
                </span>

                <span *ngIf="item == 'favourite'">

                    <mat-header-cell *matHeaderCellDef class="favourite_width">
                        <mat-icon class="starrr nav-link-title header_icon" 
                        >star</mat-icon>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="favourite_width" (click)="getFavouritelist(row)">  
                        <mat-icon class="starrr nav-link-title table_icon1" 
                        >{{(row.FAVOURITE == 1) ? 'star':'star_border'}}</mat-icon>
                    </mat-cell>
                </span>

                <span *ngIf="(item != 'select' && item != 'favourite')">
                    <mat-header-cell
                        *matHeaderCellDef="let i = index"
                        mat-sort-header
                        class="header_title"
                        mwlResizable
                        enableGhostResize="true"
                        (resizing)="onResizing($event, item)"
                        (resizeEnd)="onResizeEnd($event, item)"
                        [resizeEdges]="{
                            bottom: false,
                            right: true,
                            top: false,
                            left: true
                         }">
                        <div
                            class="desc_cell resize-handle-right "
                            mwlResizeHandle
                            [resizeEdges]="{ right: true }"></div>
                        {{ tempColobj[item].DESCRIPTION }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let row"
                        (contextmenu)="onRightClick($event, row)"
                        appRightclick
                        class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{ item }}"
                        [ngClass]="{'word-wrap-class': tempColobj[item].WIDEDATA}"
                        [ngStyle]="{
                            color:
                                'Matter'
                                | fieldConfigColor
                                    : row
                                    : tempColobj[item].DESCRIPTION
                                    : 'color'
                        }"
                        [matTooltip]="
                            'Matter'
                                | fieldConfigColor
                                    : row
                                    : tempColobj[item].DESCRIPTION
                                    : 'tooltip'
                        "
                        matTooltipClass="example-tooltip-font"
                        matTooltipPosition="below">
                        <span
                            [ngClass]="{'has-red':item == 'NETTRUSTBALANCE' && row[item] <= 0}"
                            [ngStyle]="{
                                color:
                                    'Matter'
                                    | fieldConfigColor
                                        : row
                                        : tempColobj[item].DESCRIPTION
                                        : 'color'
                            }"
                            *ngIf="row[item] !== undefined && (Object.keys(hyperLinkInfo).indexOf(item) ==-1
                                            || hyperLinkInfo[item].LINKFIELD == ''
                                            ||row[hyperLinkInfo[item].LINKFIELD] ==undefined
                                            || row[hyperLinkInfo[item].LINKFIELD] == '')
                            ">
                            {{
                            row[item]
                            | toFixedAmount : tempColobj[item].CURRENCY
                            | wordwrap : tempColobj[item].WIDEDATA
                            }}
                        </span>

                        <span
                            *ngIf="
                                row[item] &&
                                Object.keys(hyperLinkInfo).indexOf(item) >= 0 &&
                                hyperLinkInfo[item].LINKFIELD != '' &&
                                row[hyperLinkInfo[item].LINKFIELD] !=
                                    undefined &&
                                row[hyperLinkInfo[item].LINKFIELD] != ''
                            "
                            (click)="selectHyperLink(row, item, hyperLinkInfo[item])"
                            [ngClass]="COLOR"
                            [style]="
                                highlightedRows == row.MATTERGUID &&
                                currentUser.PRODUCTTYPE == 'Solicitor'
                                    ? 'color: #43a047 !important'
                                    : highlightedRows == row.MATTERGUID &&
                                      currentUser.PRODUCTTYPE == 'Barrister'
                                    ? 'color: #4E2083 !important'
                                    : ''
                                ">
                            {{
                            row[item]
                            | toFixedAmount : tempColobj[item].CURRENCY
                            | wordwrap : tempColobj[item].WIDEDATA
                            }}
                        </span>
                    </mat-cell>
                </span>
            </ng-container>
            <mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row
                *matRowDef="let matter; columns: displayedColumns; let element"
                class="matter"
                (click)="editmatter(matter); highlightedRows = matter.MATTERGUID"
                [style.background]="highlightedRows == matter.MATTERGUID ? selectedColore : ''"
                [ngClass]="highlightedRows == matter.MATTERGUID
                        ? 'row-text-colore row-text-colore-matter'
                        : ''
                "
                (dblclick)="doubleClick()"
                matRipple
                [@animate]="{ value: '*', params: { y: '100%' } }">
            </mat-row>
        </mat-table>
    </div>
    <mat-paginator
        [pageSizeOptions]="[10, 20, 50, 100]"
        [pageSize]="pageSize?.matter_page"
        (page)="onPaginateChange($event)"
        showFirstLastButtons></mat-paginator>
</div>
<!------------------------- START rightclick matmenu open =======>>>>NOTWE:::'(contextmenu)="onRightClick($event,row)"  appRightclick ' put it in talb met-cell ---------------------------->
<div
    #trigger="matMenuTrigger"
    style="position: fixed; visibility: hidden"
    [matMenuTriggerFor]="contextMenu"
    [matMenuTriggerData]="{ item: row }"
    [style.left.px]="menuTopLeftPosition.x"
    [style.top.px]="menuTopLeftPosition.y"></div>
<mat-menu #contextMenu="matMenu" class="matter-menu-matter">
    <ng-template matMenuContent let-item="item">
        <button
            mat-menu-item
            *ngFor="let data of RightClicklistMenu"
            (click)="RightClickAddData(data)"
            class="matterlist-buttons">
            <mat-icon role="img" class="matterlist-img">
                <img class="w-20" src="assets/icons/web_app/{{ data.ICON }}" />
            </mat-icon>
            <div class="fs-11">{{ data.NAME }}</div>
        </button>
    </ng-template>
</mat-menu>
<!------------------------- END rightclick matmenu open  ---------------------------->
