import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { AddRateComponent } from 'src/app/main/pages/matters/matter-popup/rates/add-rate/add-rate.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { AddRateComponent } from '@pages/matters/matter-popup/rates/add-rate/add-rate.component';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit, OnDestroy {

  constructor(
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService, public dialog: MatDialog,
    private toastr: ToastrService,
    public tooltipService:TooltipService
  ) { }
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() action: any;
  @Input() isEditRate: boolean;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  
  @Output() rateDetail: EventEmitter<any> = new EventEmitter<any>();
  contactGst: any = [];
  contactCurrency: any = [];
  UserRateData: any = [];
  ActivityRateData: any = [];
  SundryRateData: any = [];
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  ContectGuid: any = localStorage.getItem('contactGuid');

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // this.behaviorService.contactData$.subscribe(result => {
    //   this.ContectGuid = (result) ? result.CONTACTGUID : "";
    // });
    let contactGstLookups = JSON.parse(localStorage.getItem('contact_gst_lookups'));
    if (contactGstLookups && contactGstLookups != null) {
      this.contactGst = contactGstLookups;
    } else {
      this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'contact gst' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.contactGst = responses.DATA.LOOKUPS;
          localStorage.setItem('contact_gst_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        }
      });
    }
    let contactCurrencyLookups = JSON.parse(localStorage.getItem('contact_currency_lookups'));
    if (contactCurrencyLookups && contactCurrencyLookups != null) {
      this.contactCurrency = contactCurrencyLookups;
    } else {
      this.sub1 = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'contact currency' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.contactCurrency = responses.DATA.LOOKUPS;
          localStorage.setItem('contact_currency_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        }
      });
    }
    this.loadRateDate('user-rate', 1);
    this.loadRateDate('activity-rate', 2);
    this.loadRateDate('activity-rate', 3);
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to Load Rate Date
   * @param rateType -type of the rate
   * @param id -rate id value
   */
  loadRateDate(rateType, id) {
    if (this.action == "edit") {
      let filterData: any = { RATETYPE: 'CONTACT', LINKGUID: this.ContectGuid };
      if (id == 2) {
        filterData.ACTIVITYTYPE = "Activity"
      } else if (id == 3) {
        filterData.ACTIVITYTYPE = "Sundry";
      }

      let payload :any = {
        "Action": "GetData",
      };

      payload.Filters = filterData;

      this.sub2 = this._mainAPiServiceService.getSetData(payload, rateType).subscribe(responses => {
        if (responses.CODE == 200 && responses.STATUS == 'success') {
          if (rateType == 'user-rate') { 
            this.UserRateData = responses.DATA.RECORDS;
          } else if (rateType == 'activity-rate' && id == 2) {
            this.ActivityRateData = responses.DATA.RECORDS;
          } else if (rateType == 'activity-rate' && id == 3) {
            this.SundryRateData = responses.DATA.RECORDS;
          }
        }
      });
    }
  }

  /**
   * This function is used to Add the New Rates
   * @param actions -form action type
   * @param type -type of the Rate
   * @param id -id of the Rate
   */
  AddNewRate(actions, type, id) {
    const dialogRef = this.dialog.open(AddRateComponent, {
      disableClose: true, data: { formAction: actions, isEditRate: this.isEditRate, USERRATEGUID: id, rateType: "Contact", linkGuid: this.ContectGuid, type: type }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'ContactDialogComponent');
      if (result) {
        if (this.isEditRate) {
          if (result.type == 2) {
            this.ActivityRateData.push(result);
          } else if (result.type == 3) {
            this.SundryRateData.push(result);
          } else {       
            this.UserRateData.push(result);
          }
          this.rateDetail.emit(result);
        } else {
          if (result != 'user-rate') {
            this.loadRateDate(result, 2);
            this.loadRateDate(result, 3);
          } else {
            this.loadRateDate(result, 1);
          }
        }
      }
    });
  }

  /**
   * This function is used to Delete the Rate
   * @param item --Delete Rate of the Item
   * @param id -id of the Delete rate
   */
  DeleteRate(item, id) {
    let DeleteUrl = id == 1 ? "user-rate" : 'activity-rate';
    let postData = id == 1 ? { USERRATEGUID: item.USERRATEGUID } : { ACTIVITYRATEGUID: item.ACTIVITYRATEGUID };
    let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
    confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._mainAPiServiceService.getSetData({ FORMACTION: 'delete', VALIDATEONLY: false, DATA: postData }, DeleteUrl).subscribe(response => {
          if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
            this.toastr.success('Rate Deleted successfully');
            if (id == 1) {
              this.loadRateDate('user-rate', id);
            } else {
              this.loadRateDate('activity-rate', id);
            }
          }
        }, (error: any) => {
          console.log(error);
        });
      }
      confirmDialogRef = null;
    });
  }

}
