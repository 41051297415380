import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactSelectDialogComponent } from '@pages/contact/contact-select-dialog/contact-select-dialog.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-packets-dialog',
  templateUrl: './packets-dialog.component.html',
  styleUrls: ['./packets-dialog.component.scss']
})
export class PacketsDialogComponent implements OnInit, OnDestroy {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isLoadingResults: boolean = false;
  action: string;
  dialogTitle: string;
  isspiner: boolean = false;
  packetsform: FormGroup;
  active: boolean;
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  successMsg: any;
  PacketsData: any = [];
  FormAction: any;
  PacketsGuid: any;
  GetSetMixRetry: boolean = true;
  toolTipList: any;
  toolTipPostion = "above";
  testBool: boolean=false;
  private _unsubscribeAll$: Subject<void> = new Subject();
  appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
  constructor(public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<PacketsDialogComponent>,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    public behaviorService: BehaviorService,
    private _mainAPiServiceService: MainAPiServiceService,
    private globalFunService: GlobalFunctionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public tooltipService:TooltipService,
  ) {
    this.action = data.action;
    if (this.action === 'new') {
      this.dialogTitle = 'New Packets';
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Packets';
    } else {
      this.dialogTitle = 'Duplicate Packets';
    }
    // this.behaviorService.dialogClose$.subscribe(result => {
    //   if(result != null){
    //     if(result.MESSAGE == 'Not logged in'){
    //       this.dialogRef.close(false);
    //     }
    //   }
    //  });
    this.behaviorService.Packets$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.PacketsData = result;
      }
    });
    localStorage.setItem('istrackid', 'PacketsDialogComponent');
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'PacketsDialogComponent' && result['click'] && this.GetSetMixRetry && (result['data']['safe-custody-packet'] && result['data']['safe-custody-packet'])) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'PacketsDialogComponent' && result['click'] && this.GetSetMixRetry == false && result['data']['safe-custody-packet']) {
        this.PacketsSave();
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.packetsform = this._formBuilder.group({
      PACKETNUMBER: [''],
      CONTACT: [''],
      LOCATION: [''],
      PACKETDESCRIPTION: [''],
      ISACTIVE: [''],
      CONTACTGUID: [''],
      SAFECUSTODYPACKETGUID: ['']
    });
    this.packetsform.controls['ISACTIVE'].setValue(true);

    if (this.action == 'edit' || this.action == 'duplicate') {
      if (this.data && this.data.PacketsID) {
        // safeCustody-Dilog connected
        this.PacketsData = {
          SAFECUSTODYPACKETGUID: this.data.PacketsID
        }
      }
      this.EditPackets();
    } else {
      this._mainAPiServiceService.getSetData({ Action: 'default', VALIDATEONLY: false, Data: {} }, 'safe-custody-packet').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          this.toolTipList = response.DATA.FIELDTIPS
          let DefultPacketnumber = response.DATA.DEFAULTVALUES.PACKETNUMBER;
          this.packetsform.controls['PACKETNUMBER'].setValue(DefultPacketnumber);
        }
      }, error => {
        this.toastr.error(error);
      });
    }

    if (this.data.result && this.action !== 'duplicate') {
      let safeCustodayData = this.data.result.safeCustodyData;
      this.packetsform.controls['CONTACT'].setValue(safeCustodayData.result.CONTACTNAME);
      this.packetsform.controls['CONTACTGUID'].setValue(safeCustodayData.result.CONTACTGUID);
    };

 this.setTooltipData() }

 /**
  * This function is used to set the tooltip data value
  */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['SafeCustodyPacket'] ){
      this.toolTipList  = TooltipData['SafeCustodyPacket'];
    }else{
      this.tooltipService.setToolTipData = ('SafeCustodyPacket');
     }
  };

  /**
   * This function is used to toggle for update
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to set the destroy
   */
  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  get f() {
    return this.packetsform.controls;
  }

/**
 * This function is used to edit the Packets
 */
  EditPackets() {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({"Action": "GetData", "Filters": {SAFECUSTODYPACKETGUID: this.PacketsData.SAFECUSTODYPACKETGUID }}
    , 'safe-custody-packet').pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result.CODE == 200 && result.STATUS == "success") {
        let editData: any = result.DATA.RECORDS[0];
        this.toolTipList = result.DATA.FIELDTIPS
        if (this.action == "edit" || this.action == 'duplicate') {
          this.packetsform.controls['PACKETNUMBER'].setValue(editData.PACKETNUMBER);
        }
        this.packetsform.controls['SAFECUSTODYPACKETGUID'].setValue(editData.SAFECUSTODYPACKETGUID);
        this.packetsform.controls['CONTACTGUID'].setValue(editData.CONTACTGUID);
        this.packetsform.controls['CONTACT'].setValue(editData.CONTACTNAME);
        this.packetsform.controls['LOCATION'].setValue(editData.LOCATION);
        this.packetsform.controls['PACKETDESCRIPTION'].setValue(editData.PACKETDESCRIPTION);
        const value = editData.ISACTIVE == "Inactive" ? false : true;
        this.packetsform.controls['ISACTIVE'].setValue(value);
        this.isLoadingResults = false;
      }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }

  /**
   * This function is used to packet the save 
   */
  PacketsSave() {
    this.GetSetMixRetry = false;
    if (this.action === 'new' || this.action === 'duplicate') {
      this.FormAction = 'insert';
      this.PacketsGuid = "";
      this.successMsg = 'Save successfully';
    } else {
      this.FormAction = 'update';
      this.PacketsGuid = this.PacketsData.SAFECUSTODYPACKETGUID
      this.successMsg = 'Update successfully';
    }
    let PostData = {
      SAFECUSTODYPACKETGUID: this.PacketsGuid,
      CONTACTGUID: this.f.CONTACTGUID.value,
      PACKETNUMBER: this.f.PACKETNUMBER.value,
      PACKETDESCRIPTION: this.f.PACKETDESCRIPTION.value,
      LOCATION: this.f.LOCATION.value,
      ISACTIVE: this.f.ISACTIVE.value == true ? "Active" : "Inactive",
    };
    //return;
    this.isspiner = true;
    let finalData = { DATA: PostData, FormAction: this.FormAction, VALIDATEONLY: true };
    this._mainAPiServiceService.getSetData(finalData, 'safe-custody-packet').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to validation
   */
  checkValidation(bodyData: any, PostActivityData: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   } else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.isspiner = false;
    //   this.toastr.error(errorData);
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true, width: '100%', data: warningData
    //   });
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.savePacketsData(PostActivityData);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // }
    // if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.savePacketsData(PostActivityData);
    // }

    this.isspiner = false;
    this.globalFunService.checkValidation(bodyData, PostActivityData)
      .subscribe(data => {
        if (data) {
          this.errorWarningData = data.errorWarningData;
          this.errorWarningDataArray = data.errorWarningData;
          if (data.callback) {
            this.savePacketsData(PostActivityData);
          }
        }
      });
  }

  /**
   * This function is used to Save the Packets Data
   */
  savePacketsData(PostActivityData: any) {
    PostActivityData.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(PostActivityData, 'safe-custody-packet').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      this.behaviorService.setSafeCstodyPacketGuid$.next(res.DATA);
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success(this.successMsg);
        this.dialogRef.close(true);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(this.successMsg);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.error(res.STATUS);
        this.isspiner = false;
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
      this.behaviorService.setSafeCstodyPacketGuid$.next(null);
    });
  }

  /**
   * This function is used to select the contact
   */
  SelectContact() {
    const dialogRef = this._matDialog.open(ContactSelectDialogComponent, {
      width: '100%', disableClose: true,
      data: {
        type: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'PacketsDialogComponent');
      this.packetsform.controls['CONTACT'].setValue(result.CONTACTNAME);
      this.packetsform.controls['CONTACTGUID'].setValue(result.CONTACTGUID);
    });

  }
}



