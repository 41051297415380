<div class="mat-elevation-z4 primary " [ngClass]="!this.folderHideshow?'heading-task':'header'" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container>
        <div class="title ml-16" fxLayout="row" fxLayoutAlign="start center" (click)="unfoldSidebarTemporarily()">
            <mat-icon  *ngIf="this.folderHideshow"class="s-32 fuse-white-fg">alarm</mat-icon>
            <h3  *ngIf="this.folderHideshow"class="ml-12">Reminder</h3>
        </div>
    </ng-container>
    <div class="header_right_option">
        <!-- NOTE::big size up 1280  of task -->
        <!-- <button mat-icon-button class="toggle-sidebar-folded minimize_chat mr-8"
            (click)="foldSidebarTemporarily()" fxHide fxShow.gt-md>
            <mat-icon class="secondary-text s-20">remove</mat-icon>
        </button> -->
        <button *ngIf="this.folderHideshow" mat-icon-button class="toggle-sidebar-folded close_chat mr-8" (click)="foldSidebarTemporarily()" fxHide
            fxShow.gt-md>
            <mat-icon   class="secondary-text s-20">close</mat-icon>
        </button>
        <button *ngIf="!this.folderHideshow" mat-icon-button class="toggle-sidebar-folded close_chat mr-8" (click)="unfoldSidebarTemporarily()" fxHide
            fxShow.gt-md>
           <mat-icon   class="secondary-text s-20" >alarm</mat-icon>
        </button>
    </div>
   
           <!-- NOTE::Small size below 1280 of task -->
        <button *ngIf="this.folderHideshow" mat-icon-button class="toggle-sidebar-open mr-8" (click)="foldSidebarTemporarily()" fxHide.gt-md>
            <mat-icon class="secondary-text">close</mat-icon>
        </button>
        <button *ngIf="!this.folderHideshow" mat-icon-button class="toggle-sidebar-open  mr-8" (click)="unfoldSidebarTemporarily()" fxHide.gt-md>
            <mat-icon   class="secondary-text s-20" >alarm</mat-icon>
            </button>
    
</div>

<div class="content" *ngIf="this.folderHideshow">
    <div id="chat" fxLayout="column" fxFlex="1 1 auto">
        <div id="messages" class="messages" fxFlex="1 1 auto" fusePerfectScrollbar>
            <!-- <ng-container>
                <div class="no-contact-selected">
                    <div class="no-contact-icon">
                        <mat-icon class="s-128 fade-text">alarm</mat-icon>
                    </div>
                    <div class="no-contact secondary-text">
                        No Reminder Found.
                    </div>
                </div>
            </ng-container> -->
            <ng-container>
                <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="btm_margin">
                        <div class="reminder-large-icon">
                            <mat-icon class="main-icon">
                                <img src="assets/icons/web_app/icon_tasks_d.ico">
                            </mat-icon>
                        </div>
                        <div class="text-bold">
                            <p>Matter: <label>{{ActiveRow?.SHORTNAME }}</label></p>
                            <p>Due Date: <label>{{ActiveRow?.DUEDATE }}</label></p>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <label>{{ActiveRow?.DESCRIPTION }}</label></div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <div class="example-container">
                            <mat-table mat-table [dataSource]="taskData" class="mat-elevation-z8">
                                <ng-container matColumnDef="DESCRIPTION">
                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Subject </mat-header-cell>
                                    <mat-cell mat-cell *matCellDef="let element"> {{element.DESCRIPTION}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="STARTDATE">
                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Original Date </mat-header-cell>
                                    <mat-cell mat-cell *matCellDef="let element">
                                        {{element.STARTDATE}} </mat-cell>
                                </ng-container>
                                <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"
                                    (click)="ActiveRow=row;highlightedRows=row.TASKGUID"
                                    [style.background]="highlightedRows == row.TASKGUID ? selectedColore : ''"
                                    [ngClass]="highlightedRows == row.TASKGUID ? 'row-text-colore row-text-colore-reminder' : ''">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                    <div class="popup_ftr remindertask-btns">
                        <div class="left_popup_btn">
                            <button mat-raised-button color="accent" (click)="dissmissAll()" [disabled]="dissallSpiner">
                                <mat-icon> <img src="assets/icons/web_app/icon_cancel_d.ico"> </mat-icon>
                                <mat-spinner *ngIf="dissallSpiner"></mat-spinner>Dismiss All
                            </button>
                        </div>
                        <div class="right_popup_btn">
                            <button mat-raised-button color="accent" (click)="editData(ActiveRow)">
                                <mat-icon> <img src="assets/icons/web_app/icon_tasks_d.ico"> </mat-icon>
                                Open Item
                            </button>
                            <button mat-raised-button color="primary" cdkFocusInitial (click)="dissmiss(ActiveRow)"
                                [disabled]="dissSpiner">
                                <mat-icon> <img src="assets/icons/web_app/icon_cancel_d.ico"> </mat-icon>
                                <mat-spinner *ngIf="dissSpiner"></mat-spinner>Dismiss
                            </button>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="btm_margin">
                        <mat-form-field appearance="outline" fxFlex="70" class="pr-4">
                            <mat-label>Snooze</mat-label>
                            <mat-select [(ngModel)]="SnoozeTime" name="SnoozeTime">
                                <mat-option value="MINUTES:5">5 Minutes</mat-option>
                                <mat-option value="MINUTES:10">10 Minutes</mat-option>
                                <mat-option value="MINUTES:15">15 Minutes</mat-option>
                                <mat-option value="MINUTES:30">30 Minutes</mat-option>
                                <mat-option value="MINUTES:60">1 Hours</mat-option>
                                <mat-option value="MINUTES:120">2 Hours</mat-option>
                                <mat-option value="MINUTES:240">4 Hours</mat-option>
                                <mat-option value="MINUTES:480">8 Hours</mat-option>
                                <mat-option value="DAYS:1">1 Days</mat-option>
                                <mat-option value="DAYS:2">2 Days</mat-option>
                                <mat-option value="DAYS:3">3 Days</mat-option>
                                <mat-option value="DAYS:4">4 Days</mat-option>
                                <mat-option value="DAYS:7">1 Week</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="snooze-btn-margin" mat-raised-button color="primary" fxFlex="30" cdkFocusInitial
                            [disabled]="SnoozeSpiner" (click)="Snooze(ActiveRow)">
                            <mat-icon> <img src="assets/icons/web_app/icon_save_d.ico"> </mat-icon>
                            <mat-spinner *ngIf="SnoozeSpiner"></mat-spinner> Snooze
                        </button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</div>