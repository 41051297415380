import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from 'src/@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-select-popup',
  templateUrl: './user-select-popup.component.html',
  styleUrls: ['./user-select-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UserSelectPopupComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['USERID', 'USERNAME'];
  getDataForTable: any = [];
  highlightedRows: any;
  // selection = new SelectionModel<any>(true, []);
  theme_type = localStorage.getItem('theme_type');
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean = false;
  pageSize: any;
  currentUserData: any;
  sub: Subscription;
  sub1: Subscription;

  constructor(
    public MatDialog: MatDialog,
    private behaviorService: BehaviorService,
    private router:Router,
    private _mainAPiServiceService: MainAPiServiceService,
    public dialogRef: MatDialogRef<UserSelectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    if (_data && _data.multi == 'Multi') {
      this.displayedColumns.unshift('select');
    }
    localStorage.setItem('istrackid', 'UserSelectPopupComponent');
    this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'UserSelectPopupComponent' && result['click'] && result['data']['user']) {
        this.ngOnInit();
      }

      // result['click']=false;

    });
  }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.isLoadingResults = true;
    this.sub1 = this._mainAPiServiceService.getSetData({Action : 'GetData',Filters :{'Active': 'yes'}}, 'user').subscribe(response => {
      if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
        if (response.DATA.USERS[0]) {
          this.highlightedRows = response.DATA.USERS[0].USERGUID;
          this.currentUserData = response.DATA.USERS[0];
        }
        const route = (this.router.url).split("/");
       let selectedCheckbox = JSON.parse(localStorage.getItem(route[1] == "diary"?"SelectedDiaryUsers" : "SelectedUserActivityUsers"));
        if (selectedCheckbox) {
          selectedCheckbox.forEach((row) => {
            response.DATA.USERS.find(o => {
              if (o.USERGUID === row.USERGUID) {
                o.TAGGED = 1;
                this.selection.select(o);
              }
            });
          });
        }
        this.getDataForTable = new MatTableDataSource(response.DATA.USERS);
        this.getDataForTable.paginator = this.paginator;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isLoadingResults = false;
    }, error => {
      console.log(error);
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }

  /**
   * This function is used to select the User Id
   * @param Row -Row data value
   */
  selectUserId(Row: any) {
    this.currentUserData = Row;
  }

  /**
   * This function is used to onPagination change
   * @param event -event data value
   */
  onPaginateChange(event) {
    this.pageSize.user_select = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

  /**
   * This function is used to Master toggle data
   */
  masterToggle() {

    this.isAllSelected() ? this.selection.clear() : this.getDataForTable.data.forEach(row => this.selection.select(row));
  }

  /**
   * This unction is used to select the all checkbox data value
   * @returns 
   */
  isAllSelected() {
    const numSelected = this.selection.selected ? this.selection.selected.length : 0;
    const numRows = this.getDataForTable.data ? this.getDataForTable.data.length : 0;
    return numSelected === numRows;
  }

  /**
   * This function is used to set the label to the check box
   * @param row -row data value
   * @returns data
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

}
