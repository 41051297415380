<div class="one product-app" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <iframe [src]="safeSrc" width="100%" height="390px" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen *ngIf="paymentDetails.NEWTABREQUIRED == 0"></iframe>

        <button class="mainbtn_size" mat-raised-button color="accent" style="margin: 50px auto;" *ngIf="paymentDetails.NEWTABREQUIRED == 1" (click)="openStripeUrl()">Click here to open your Stripe Portal</button>

    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="padding-top: 20px;">
        <div fxFlex="92"></div>
        <div fxFlex="8">
            <button class="mainbtn_size" mat-raised-button color="accent" style="margin-right: 10px;" *ngIf="btntext=='Edit' && paymentDetails.NEWTABREQUIRED == 0" (click)="editPamentDetails()">{{btntext}}</button>
            <button class="mainbtn_size" mat-raised-button color="accent" style="margin-right: 10px;" *ngIf="btntext=='View'" (click)="ViewPamentDetails()">{{btntext}}</button>
        </div>
    </div>
</div>