import { BehaviorService } from '@_services/Behavior.service';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FuseNavigationService } from 'src/@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from 'src/@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from 'src/@fuse/services/config.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { LogoutErrorComponent } from 'src/app/main/logout-error/logout-error.component';

@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit, OnDestroy {
    isspiner: any = false
    hide: boolean = true;
    sub1: Subscription;
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    preferenceObjectData = JSON.parse(localStorage.getItem('currentUser'));
    PreferenceForm: FormGroup;
    fuseConfigrationForm: FormGroup
    isSolacitor: any = this.currentuser?.PRODUCTTYPE;
    fuseConfig: any;
    MatterDropData: any = [];
    colorThemeval: any
    dialogRef: any;
    InitialWindow: any = ['Matter', 'Trust', 'Time Entries', 'Contacts',
        'Spend Money', 'Receive Money', 'Invoices', 'Diary', 'Authorities', 'Chart of Accounts', 'Trust Chart of Accounts', 'Safe Custody',
        'DashBoard', 'Trust End of Month'];
    // quantType: any = ['Minutes', 'hh:mm', 'Hours', 'Minutes', 'Days', 'Units', 'Fixed'];
    quantType: any = [
        { Name: 'Minutes', value: 'M' },
        { Name: 'hh:mm', value: 'X' },
        { Name: 'Hours', value: 'H' },
        { Name: 'Days', value: 'D' },
        { Name: 'Units', value: 'U' },
        { Name: 'Fixed', value: 'F' },
    ];
    private _unsubscribeAll: Subject<any>;
    @ViewChild('scrollToDiv') scrollToDiv: ElementRef;
    constructor(
        private renderer: Renderer2,
        public behaviorService: BehaviorService,
        public _mainAPiServiceService: MainAPiServiceService,
        public _formBuilder: FormBuilder,
        private Timersservice: TimersService,
        private toastr: ToastrService,
        public hyperlink: HyperlinkNavigationService,
        public _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
    ) {
        this._unsubscribeAll = new Subject();
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
            if (result['click'] && result['data']['preference']) {
                this.preferenceSave();
            }
        });
        this.behaviorService.GetPreferences$.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
            if (result) {
                this.preferenceObjectData = result;
            } else {
                this.preferenceObjectData = JSON.parse(localStorage.getItem('currentUser'));
            }
            //this.ngOnInit()
        })
        this.fuseConfigration()
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {

        this.PreferenceForm = this._formBuilder.group({
            SORTBYSURNAME: new FormControl(''),
            SORTMATTERALPHANUMERICALLY: new FormControl(''),
            SORTPACKETSALPHANUMERICALLY: new FormControl(''),
            LOADALLUSERSMATTERS: new FormControl(''),
            POPUPSELECTMATTERFIRST: new FormControl(''),
            // Email
            SAVEEMAILSTOREGISTER: new FormControl(''),
            // dairy
            LOADPREVIOUSXDAYS: new FormControl(''),
            SHOWREMINDERS: new FormControl(''),
            // Document
            THUMBNAILSIZE: new FormControl(''),
            //  CONVERTTOPDF: new FormControl(''),
            // Behviour
            WORKINGONBEHALFOFGUID: new FormControl(''),
            DEFAULTOWNERGUID: new FormControl(''),
            DEFAULTPRIMARYFEEEARNERGUID: new FormControl(''),
            DEFAULTASSISTANTGUID: new FormControl(''),
            INITIALWINDOW: new FormControl(''),
            DEFAULTQUANTITYTYPE: new FormControl(''),
            // Open in new tab
            OPENINNEWTAB: new FormControl(''),
            SHOWEMAILCC: new FormControl(''),
            SHOWEMAILBCC: new FormControl(''),
            //  Show Disbursements automatically
            SHOWDISBURSEMENTS: new FormControl(''),
            SHOWINACTIVEFEEEARNERS:new FormControl(''),
            // contact window
            CONTACTDEFAULTTYPE: new FormControl(''),
            CONTACTSTARTSWITH: new FormControl(''),
            CONTACTACTIVE: new FormControl(''),
            //  Pre-fill time of the day
            PREFILLTIMEOFDAY: new FormControl(''),
            // Save reports to the NetDrive
            SAVEREPORTSTONETDRIVE: new FormControl(''),
            EMAILCSVFILE: new FormControl(''),
            // Themes Options
            THEMECOLOUR: new FormControl(''),
            THEMECOLOURToggle: new FormControl(''),
            //  Navbar
            NAVBARHIDE: new FormControl(''),
            NAVBARFOLDED: new FormControl(''),
            // SIDEPANELHIDE
            SIDEPANELHIDE: new FormControl(''),
            // User Interface
            USEV11UI: new FormControl(''),
            // extra field
            PRODUCTTYPE: new FormControl(''),
            DELIVERABLES: new FormControl(''),
            UNITSPERHOUR: new FormControl(''),
            DISBURSEMENTACCOUNTGUID: new FormControl(''),
            TAXPAYABLEACCOUNTGUID: new FormControl(''),
            DONTAUTOALLOCATERECEIPT: new FormControl(''),
            EMAILSYSTEM: new FormControl(''),
            DOCUMENTSYSTEM: new FormControl(''),
            // EMAIL SMTP
            SMTPEMAILADDRESS: [''],
            SMTPSERVER: [''],
            SMTPPORT: [],
            SMTPUSESSL: [false],
            SMTPUSERNAME: [''],
            SMTPPASSWORD: [''],

        })
        this.DefaultFeeEarner()
        this.defaultsetPrefernce()
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        if (this.scrollToDiv) {
            // this.scrollToDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
            // Scroll to the target div with a smooth behavior
            //  this.renderer.selectRootElement(this.scrollToDiv.nativeElement).scrollIntoView({ behavior: 'smooth' });
        }
    }
    // ngAfterViewInit() {
    //     // Subscribe to route fragment changes
    //     this.route.fragment.subscribe((fragment: string) => {
    //         if (fragment === 'scrollTarget' && this.scrollTarget) {
    //             // Scroll to the target element
    //             this.scrollTarget.nativeElement.scrollIntoView();
    //         }
    //     });
    // }
    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        // Remove the custom function menu
        this._fuseNavigationService.removeNavigationItem('custom-function');
    }
    get f(): any {
        return this.PreferenceForm.controls;
    };

    /**
     * This function is used to configre the fuse
     */
    fuseConfigration() {
        this._fuseConfigService.config = {
            layout: {
                sidepanel: { hidden: this.preferenceObjectData?.SIDEPANELHIDE }

            }
        };
        this.behaviorService.setToolBaarSetting({ NAVBARFOLDED: this.preferenceObjectData.NAVBARFOLDED, NAVBARHIDE: this.preferenceObjectData.NAVBARHIDE, OPENINNEWTAB: this.preferenceObjectData.OPENINNEWTAB, OPENINNEWTABWINDOWS: this.preferenceObjectData.OPENINNEWTABWINDOWS, USEV11UI: this.preferenceObjectData.USEV11UI });

        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: this.preferenceObjectData.NAVBARHIDE,
                    folded: this.preferenceObjectData.NAVBARFOLDED
                }
            }
        };

        // Build the config form
        // noinspection TypeScriptValidateTypes
        this.fuseConfigrationForm = this._formBuilder.group({

            colorTheme: new FormControl(),
            customScrollbars: new FormControl(),
            layout: this._formBuilder.group({
                style: new FormControl(),
                width: new FormControl(),
                navbar: this._formBuilder.group({
                    primaryBackground: new FormControl(),
                    secondaryBackground: new FormControl(),
                    folded: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl(),
                    variant: new FormControl()
                }),
                toolbar: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl()
                }),
                footer: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl()
                }),
                sidepanel: this._formBuilder.group({
                    hidden: new FormControl(),
                    position: new FormControl()
                })
            })
        });

        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {

            // Update the stored config
            this.fuseConfig = config;
            // Set the config form values without emitting an event
            // so that we don't end up with an infinite loop
            this.fuseConfigrationForm.setValue(config, { emitEvent: false });
        });

        // Subscribe to the specific form value changes (layout.style)
        this.fuseConfigrationForm.get('layout.style').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            // Reset the form values based on the
            // selected layout style
            // this._resetFormValues(value);
        });

        // Subscribe to the form value changes
        this.fuseConfigrationForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            // Update the config
            // this._fuseConfigService.config = config;
        });

        // Add customize nav item that opens the bar programmatically
        const customFunctionNavItem = {
            id: 'custom-function',
            title: 'Custom Function',
            type: 'group',
            icon: 'settings',
            children: [
                {
                    id: 'customize',
                    title: 'Customize',
                    type: 'item',
                    icon: 'settings',
                    function: () => {
                        this.toggleSidebarOpen('themeOptionsPanel');
                    }
                }
            ]
        };

        this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
    }

    /**
     * This function is used to toggle sidebar data 
     * @param key 
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    /**
    * Reset the form values based on the
    * selected layout style
    *
    * @param value
    * @private
    */
    private _resetFormValues(value): void {
        switch (value) {
            // Vertical Layout #1
            case 'vertical-layout-1':
                {
                    this.fuseConfigrationForm.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'below-static'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'below-static'
                            },
                            sidepanel: {
                                hidden: false,
                                position: 'right'
                            }
                        }
                    });
                    break;
                }
        }
    }

    /**
     * This unction is used to set the default theme color data
     */
    DefaultThemColor() {
        if (this.isSolacitor == 'Solicitor') {
            if (this.preferenceObjectData?.THEMECOLOUR == 'theme-yellow-light') {
                this.colorThemeval = 'theme-yellow-light'
                this.PreferenceForm.controls['THEMECOLOUR'].setValue(this.colorThemeval)
                this.PreferenceForm.controls['THEMECOLOURToggle'].setValue(false)

            } else {
                this.colorThemeval = 'theme-blue-gray-dark'
                this.PreferenceForm.controls['THEMECOLOUR'].setValue(this.colorThemeval)
                this.PreferenceForm.controls['THEMECOLOURToggle'].setValue(true)
            }
        } else {
            if (this.preferenceObjectData?.THEMECOLOUR == 'theme-default') {
                this.colorThemeval = 'theme-default'
                this.PreferenceForm.controls['THEMECOLOUR'].setValue(this.colorThemeval)
                this.PreferenceForm.controls['THEMECOLOURToggle'].setValue(false)
            } else {
                this.colorThemeval = 'theme-pink-dark'
                this.PreferenceForm.controls['THEMECOLOUR'].setValue(this.colorThemeval)
                this.PreferenceForm.controls['THEMECOLOURToggle'].setValue(true)
            }
        }
        this.preferenceObjectData['THEMECOLOUR'] = this.f['THEMECOLOUR'].value

    }

    /**
     * This function is used to set the deault preferances 
     */
    defaultsetPrefernce() {
        // Data
        this.PreferenceForm.controls['SORTBYSURNAME'].setValue(this.preferenceObjectData?.SORTBYSURNAME)
        this.PreferenceForm.controls['SORTMATTERALPHANUMERICALLY'].setValue(this.preferenceObjectData?.SORTMATTERALPHANUMERICALLY)
        this.PreferenceForm.controls['SORTPACKETSALPHANUMERICALLY'].setValue(this.preferenceObjectData?.SORTPACKETSALPHANUMERICALLY)
        this.PreferenceForm.controls['LOADALLUSERSMATTERS'].setValue(this.preferenceObjectData?.LOADALLUSERSMATTERS)
        this.PreferenceForm.controls['POPUPSELECTMATTERFIRST'].setValue(this.preferenceObjectData?.POPUPSELECTMATTERFIRST)
        // Emails
        this.PreferenceForm.controls['SAVEEMAILSTOREGISTER'].setValue(this.preferenceObjectData?.SAVEEMAILSTOREGISTER)
        // Dairy
        this.PreferenceForm.controls['LOADPREVIOUSXDAYS'].setValue(this.preferenceObjectData?.LOADPREVIOUSXDAYS.toString())
        this.PreferenceForm.controls['SHOWREMINDERS'].setValue(this.preferenceObjectData?.SHOWREMINDERS)
        // Document
        this.PreferenceForm.controls['THUMBNAILSIZE'].setValue(this.preferenceObjectData?.THUMBNAILSIZE)
        //  this.PreferenceForm.controls['CONVERTTOPDF'].setValue(this.preferenceObjectData?.CONVERTTOPDF)
        // BEhaviour
        this.PreferenceForm.controls['WORKINGONBEHALFOFGUID'].setValue(this.preferenceObjectData?.WORKINGONBEHALFOFGUID)
        this.PreferenceForm.controls['DEFAULTOWNERGUID'].setValue(this.preferenceObjectData?.DEFAULTOWNERGUID)
        this.PreferenceForm.controls['DEFAULTPRIMARYFEEEARNERGUID'].setValue(this.preferenceObjectData?.DEFAULTPRIMARYFEEEARNERGUID)
        this.PreferenceForm.controls['DEFAULTASSISTANTGUID'].setValue(this.preferenceObjectData?.DEFAULTASSISTANTGUID)

        this.PreferenceForm.controls['INITIALWINDOW'].setValue(this.preferenceObjectData?.initialwindow)
        this.PreferenceForm.controls['DEFAULTQUANTITYTYPE'].setValue(this.preferenceObjectData?.DefaultQuantityType)
        // open in new window
        this.PreferenceForm.controls['OPENINNEWTAB'].setValue(this.preferenceObjectData?.OPENINNEWTAB)
        this.PreferenceForm.controls['SHOWEMAILCC'].setValue(this.preferenceObjectData?.SHOWEMAILCC)
        this.PreferenceForm.controls['SHOWEMAILBCC'].setValue(this.preferenceObjectData?.SHOWEMAILBCC)
        // Show Disbursements automatically
        this.PreferenceForm.controls['SHOWDISBURSEMENTS'].setValue(this.preferenceObjectData?.SHOWDISBURSEMENTS),
        this.PreferenceForm.controls['SHOWINACTIVEFEEEARNERS'].setValue(this.preferenceObjectData?.SHOWINACTIVEFEEEARNERS)

        // Contact window
        this.PreferenceForm.controls['CONTACTDEFAULTTYPE'].setValue(this.preferenceObjectData?.CONTACTDEFAULTTYPE)
        this.PreferenceForm.controls['CONTACTSTARTSWITH'].setValue(this.preferenceObjectData?.CONTACTSTARTSWITH)
        this.PreferenceForm.controls['CONTACTACTIVE'].setValue(this.preferenceObjectData?.CONTACTACTIVE)
        // Pre-fill time of the day
        this.PreferenceForm.controls['PREFILLTIMEOFDAY'].setValue(this.preferenceObjectData?.PREFILLTIMEOFDAY)
        // Save reports to the NetDrive
        this.PreferenceForm.controls['SAVEREPORTSTONETDRIVE'].setValue(this.preferenceObjectData?.SAVEREPORTSTONETDRIVE)

        this.PreferenceForm.controls['EMAILCSVFILE'].setValue(this.preferenceObjectData?.EMAILCSVFILE)
        // Themes Options
        this.DefaultThemColor()
        //    this.PreferenceForm.controls['THEMECOLOUR'].setValue()
        // Navbar

        this.PreferenceForm.controls['NAVBARHIDE'].setValue(this.preferenceObjectData?.NAVBARHIDE)
        this.PreferenceForm.controls['NAVBARFOLDED'].setValue(this.preferenceObjectData?.NAVBARFOLDED)
        // SIDE PANEL
        this.PreferenceForm.controls['SIDEPANELHIDE'].setValue(this.preferenceObjectData?.SIDEPANELHIDE)
        // User Interface
        this.PreferenceForm.controls['USEV11UI'].setValue(this.preferenceObjectData?.USEV11UI)
        // extra feild
        this.PreferenceForm.controls['PRODUCTTYPE'].setValue(this.preferenceObjectData?.PRODUCTTYPE)
        this.PreferenceForm.controls['DELIVERABLES'].setValue(this.preferenceObjectData?.DELIVERABLES)
        this.PreferenceForm.controls['UNITSPERHOUR'].setValue(this.preferenceObjectData?.UNITSPERHOUR)
        this.PreferenceForm.controls['DISBURSEMENTACCOUNTGUID'].setValue(this.preferenceObjectData?.DISBURSEMENTACCOUNTGUID)
        this.PreferenceForm.controls['TAXPAYABLEACCOUNTGUID'].setValue(this.preferenceObjectData?.TAXPAYABLEACCOUNTGUID)
        this.PreferenceForm.controls['DONTAUTOALLOCATERECEIPT'].setValue(this.preferenceObjectData?.DONTAUTOALLOCATERECEIPT)
        this.PreferenceForm.controls['EMAILSYSTEM'].setValue(this.preferenceObjectData?.EMAILSYSTEM)
        this.PreferenceForm.controls['DOCUMENTSYSTEM'].setValue(this.preferenceObjectData?.DOCUMENTSYSTEM)
        // Email Confrugration
        this.getPreferences()
        // this.PreferenceForm.controls['SMTPEMAILADDRESS'].setValue(this.preferenceObjectData?.SMTPEMAILADDRESS);
        // this.PreferenceForm.controls['SMTPSERVER'].setValue(this.preferenceObjectData?.SMTPSERVER);
        // this.PreferenceForm.controls['SMTPPORT'].setValue(this.preferenceObjectData?.SMTPPORT);
        // this.PreferenceForm.controls['SMTPUSESSL'].setValue(this.preferenceObjectData?.SMTPUSESSL);
        // this.PreferenceForm.controls['SMTPPASSWORD'].setValue(this.preferenceObjectData?.SMTPPASSWORD);
        // this.PreferenceForm.controls['SMTPUSERNAME'].setValue(this.preferenceObjectData?.SMTPUSERNAME);
    }

    /**
     * This function is used to get the preerances data value.
     */
    getPreferences() {
        // this.isLoadingResults = true;
        this.sub1 = this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": {} }, 'preference').subscribe(response => {

            if (response.CODE == 200 && response.STATUS == "success") {
                let DefultVal: any = response.DATA;
                this.PreferenceForm.controls['SMTPEMAILADDRESS'].setValue(DefultVal.SMTPEMAILADDRESS);
                this.PreferenceForm.controls['SMTPSERVER'].setValue(DefultVal.SMTPSERVER);
                this.PreferenceForm.controls['SMTPPORT'].setValue(DefultVal.SMTPPORT);
                this.PreferenceForm.controls['SMTPUSESSL'].setValue(DefultVal.SMTPUSESSL);
                this.PreferenceForm.controls['SMTPPASSWORD'].setValue(DefultVal.SMTPPASSWORD);
                this.PreferenceForm.controls['SMTPUSERNAME'].setValue(DefultVal.SMTPUSERNAME);
                this.PreferenceForm.controls['PREFILLTIMEOFDAY'].setValue(DefultVal.PREFILLTIMEOFDAY);
                // New Changes
                this.PreferenceForm.controls['WORKINGONBEHALFOFGUID'].setValue(DefultVal?.WORKINGONBEHALFOFGUID);
                this.PreferenceForm.controls['DEFAULTOWNERGUID'].setValue(DefultVal?.DEFAULTOWNERGUID);
                this.PreferenceForm.controls['DEFAULTPRIMARYFEEEARNERGUID'].setValue(DefultVal?.DEFAULTPRIMARYFEEEARNERGUID);
                this.PreferenceForm.controls['DEFAULTASSISTANTGUID'].setValue(DefultVal?.DEFAULTASSISTANTGUID);
                this.PreferenceForm.controls['INITIALWINDOW'].setValue(DefultVal?.INITIALWINDOW);
                this.PreferenceForm.controls['DEFAULTQUANTITYTYPE'].setValue(DefultVal?.DEFAULTQUANTITYTYPE);
                this.PreferenceForm.controls['SHOWEMAILCC'].setValue(DefultVal?.SHOWEMAILCC);
                this.PreferenceForm.controls['SHOWEMAILBCC'].setValue(DefultVal?.SHOWEMAILBCC);
            }
            this.behaviorService.loadingSystemSetting('email-configuration');
        }, error => {
            ''
            this.behaviorService.loadingSystemSetting('email-configuration');
            this.toastr.error(error);
        });
    }

    /**
     * This unction is used to set the deault fee earner data values
     */
    DefaultFeeEarner() {
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.MatterDropData = this.Timersservice.removeUSerWithoutId(userList);
        } else {
            this.Timersservice.GetUsers({}).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.MatterDropData = this.Timersservice.removeUSerWithoutId(response.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(response.DATA.USERS));
                }
            }, err => {
            });
        }
    }

    /**
     * This unction is used to clickmon the checkbox data value
     * @param type -type of the checkbox 
     */
    checkboxClick(type: any):void {
        this.PreferenceForm.controls[type].setValue(this.f[type].value)
        if (this.preferenceObjectData) {
            this.preferenceObjectData[type] = this.f[type].value
        }
    }

    /**
     * This function is used to Change the selected window data value.
     * @param type -type of the selected window 
     */
    changeSelectWindow(type) {
        this.PreferenceForm.controls[type].setValue(this.f[type].value)
        if (this.preferenceObjectData) {
            this.preferenceObjectData[type] = this.f[type].value
        }
    }

    /**
     * This function is used to check the theme option value 
     * @param val 
     */
    themeradiobtn(val) {
        if (this.isSolacitor == 'Solicitor') {
            if (val.checked == true) {
                this.colorThemeval = 'theme-blue-gray-dark'
            } else {
                this.colorThemeval = 'theme-yellow-light'
            }
        } else {
            if (val.checked == true) {
                this.colorThemeval = 'theme-pink-dark'
            } else {
                this.colorThemeval = 'theme-default'
            }
        }
       
        // this.currentuser =
        // localStorage.setItem('currentUser', JSON.stringify(this.currentuser));
        // let themeName;
        // if (val == 'theme-yellow-light') {
        //   themeName = 'Green Light';
        // } else if (val == 'theme-blue-gray-dark') {
        //   themeName = 'Green Dark';
        // } else if (val == 'theme-default') {
        //   themeName = 'Purple Light';
        // } else if (val == 'theme-pink-dark') {
        //   themeName = 'Purple Dark';
        // }
        this.f['THEMECOLOUR'].value = this.colorThemeval;
        this.PreferenceForm.controls['THEMECOLOUR'].setValue(this.f['THEMECOLOUR'].value)
        if (this.preferenceObjectData) {
            this.preferenceObjectData['THEMECOLOUR'] = this.f['THEMECOLOUR'].value
        }


        // this._fuseConfigService.config = {
        //   colorTheme: val
        // };
    };

    /**
     * This function is used to get the theme panel data value.
     */
    themePanel(val, event?: any) {
        this.PreferenceForm.controls[val].setValue(this.f[val].value)
        if (this.preferenceObjectData) {
            this.preferenceObjectData[val] = this.f[val].value
        }
        if (val == 'USEV11UI') {
            this.dialogRef = this._matDialog.open(LogoutErrorComponent, {
                disableClose: true, width: '100%', data: {
                    "MESSAGE": "You need to logout of SILQ and close all SILQ tabs. Then log back in to access the selected UI. <br/><br/> Are you sure you want to change the UI?", "USEV11UI": true
                }
            });

            this.dialogRef.afterClosed().subscribe(result => {
                if (!result) {
                    this.preferenceObjectData.USEV11UI = !this.preferenceObjectData.USEV11UI
                    this.PreferenceForm.controls[val].setValue(this.preferenceObjectData.USEV11UI)
                    return;
                } else {
                    this.preferenceObjectData.USEV11UI = this.preferenceObjectData.USEV11UI
                    this.PreferenceForm.controls[val].setValue(this.preferenceObjectData.USEV11UI)
                }

                // this.updatePreference(val);
                // if(this.preferenceData.USEV11UI){
                // localStorage.setItem('USEV11UI' , JSON.stringify(this.preferenceData.USEV11UI));
                // this.authService.logout();
                // };


            });
        } else {
            //   this.updatePreference(val);
        }
    };

    /**
     * This function is used to Save the Preferances data value.
     */
    preferenceSave() {
        this.isspiner = true
        let details = { FormAction: 'update', VALIDATEONLY: false, Data: this.PreferenceForm.value };
        this._mainAPiServiceService.getSetData(details, 'preference').pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.UpdatedColorSystemSetting$.next(this.colorThemeval)
                for (const key in this.PreferenceForm.value) {
                    this.currentuser[key] = this.PreferenceForm.value[key];
                    localStorage.setItem('currentUser', JSON.stringify(this.currentuser))
                    localStorage.setItem('theme_type', this.PreferenceForm.controls['THEMECOLOUR'].value);
                    //  this.preferenceObjectData[key] = this.PreferenceForm.value[key];
                    // localStorage.setItem("set_preferences", JSON.stringify(this.preferenceObjectData));
                    localStorage.setItem('MatterWindow_Open_Status', JSON.stringify(this.PreferenceForm.value.POPUPSELECTMATTERFIRST));
                    this.behaviorService.MatterPopupStatus$.next(this.PreferenceForm.value.POPUPSELECTMATTERFIRST)

                    // _________________________________ old code as it is
                    if (key == 'DEFAULTQUANTITYTYPE') {
                        this.behaviorService.setDefultPreferencesData(this.preferenceObjectData);
                    }
                    if (key == 'WORKINGONBEHALFOFGUID') {
                        this.behaviorService.setDefultFeeErnerData(this.preferenceObjectData.WORKINGONBEHALFOFGUID);
                    }
                    if (key == 'DEFAULTOWNERGUID') {
                        this.behaviorService.setDefultFeeErnerData(this.preferenceObjectData.DEFAULTOWNERGUID);
                    }
                    if (key == 'DEFAULTPRIMARYFEEEARNERGUID') {
                        this.behaviorService.setDefultFeeErnerData(this.preferenceObjectData.DEFAULTPRIMARYFEEEARNERGUID);
                    }
                    if (key == 'DEFAULTASSISTANTGUID') {
                        this.behaviorService.setDefultFeeErnerData(this.preferenceObjectData.DEFAULTASSISTANTGUID);
                    }
                    if (key == 'SIDEPANELHIDE') {
                        this._fuseConfigService.config = {
                            layout: { sidepanel: { hidden: this.PreferenceForm.value[key] } }
                        };
                    }
                    if (key == 'USEV11UI') {
                        this.behaviorService.GetPreferences$.next(this.preferenceObjectData)
                        // this._fuseConfigService.config = {
                        //   layout: { matterWindow: { hidden: this.preferenceData[name] } }
                        // };
                        this.behaviorService.matterDetailsWindow$.next(this.currentuser);
                    }
                    if (key == 'THEMECOLOUR') {
                        this._fuseConfigService.config = {
                            colorTheme: this.preferenceObjectData.THEMECOLOUR
                        };
                    }
                    if (key == 'NAVBARFOLDED' || key == 'NAVBARHIDE' || key == 'OPENINNEWTAB' || key == 'OPENINNEWTABWINDOWS' || key == 'USEV11UI') {
                        this.behaviorService.setToolBaarSetting({ NAVBARFOLDED: this.currentuser.NAVBARFOLDED, NAVBARHIDE: this.currentuser.NAVBARHIDE, OPENINNEWTABWINDOWS: this.currentuser.OPENINNEWTABWINDOWS, OPENINNEWTAB: this.currentuser.OPENINNEWTAB });
                        localStorage.setItem('sidebar_fold_unfold', JSON.stringify(this.currentuser.NAVBARFOLDED));
                        this.behaviorService.GetPreferences$.next(this.preferenceObjectData)
                    }
                    //   this.fuseConfigration()
                    // this.dialogRef.close(true);
                    this.hyperlink.setColorSetting();
                    this.hyperlink.setColorSettingWithHyperlink()
                    // _________________________________ old code as it is
                }
                this.isspiner = false
                this.toastr.success(' update successfully');
                this.getPreferences()
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.isspiner = false
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.isspiner = false
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == 'Not logged in') {
                // this.dialogRef.close(false);
            }
        }, error => {
            this.isspiner = false
            this.toastr.error(error);
        });

    }

}
