import { CheckBoxModule, ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CommonModule } from '@angular/common';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DocumentEditorAllModule, DocumentEditorContainerAllModule,EditorService, SelectionService } from '@syncfusion/ej2-angular-documenteditor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropDownButtonModule, SplitButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { DropDownListModule, ComboBoxModule, DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { HttpClientModule } from '@angular/common/http';
import { ListViewAllModule, ListViewModule } from '@syncfusion/ej2-angular-lists';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SliderModule, NumericTextBoxModule, ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';
import { ToolbarModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { ToolbarService } from '@syncfusion/ej2-angular-documenteditor';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
  {
    path: '', component: TemplateViewerComponent
  }
];

@NgModule({
  declarations: [TemplateViewerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatFormFieldModule,
    // RouterModule.forChild(routes),
    MatDialogModule,
    DropDownListAllModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ColorPickerModule,
    SplitButtonModule,
    SliderModule,
    NumericTextBoxModule,
    DropDownButtonModule,
    DropDownListModule,
    ComboBoxModule,
    MultiSelectAllModule,
    DocumentEditorAllModule,
    DocumentEditorContainerAllModule,
    ListViewAllModule,
    CheckBoxModule, 
    ButtonModule,
    DialogModule,
    TooltipModule,
    GridModule,
    ToolbarModule,
    TabModule,
    ListViewModule
  ],
  exports: [
    TemplateViewerComponent, 
    DragDropModule
  ],
  entryComponents: [],
  providers: [ToolbarService, EditorService, SelectionService]
})
export class TemplateViewerModule { }
