<div class=" one " fxLayout="column" [formGroup]="matterLeasingForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year (s)</mat-label>
        </div>
        <div fxFlex="25">
            <mat-label>Yearly Amount</mat-label>
        </div>
        <div fxFlex="25">
            <mat-label>Period Instalment</mat-label>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 2</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR2AMOUNT"  matTooltip="{{(toolTipList)?toolTipList.YEAR2AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR2AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR2AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR2AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR2AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR2INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEAR2INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR2INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR2INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR2INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR2INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 3</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR3AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR3AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR3AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR3AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR3AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR3AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR3INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEAR3INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR3INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR3INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR3INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR3INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 4</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR4AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR4AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR4AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR4AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR4AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR4AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR4INSTALMENT"  matTooltip="{{(toolTipList)?toolTipList.YEAR4INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR4INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR4INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR4INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR4INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 5</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR5AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR5AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR5AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR5AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR5AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR5AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR5INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEAR5INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR5INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR5INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR5INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR5INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 6</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR6AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR6AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR6AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR6AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR6AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR6AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR6INSTALMENT"  matTooltip="{{(toolTipList)?toolTipList.YEAR6INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR6INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR6INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR6INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR6INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 7</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR7AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR7AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR7AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR7AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR7AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR7AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR7INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEAR7INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR7INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR7INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR7INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR7INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 8</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR8AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR8AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR8AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR8AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR8AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR8AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR8INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEAR8INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR8INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR8INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR8INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR8INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 9</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR9AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR9AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR9AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR9AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR9AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR9AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR9INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEARYEAR9INSTALMENT10AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR9INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR9INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR9INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR9INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxFlex="25">
            <mat-label>Year 10</mat-label>
        </div>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR10AMOUNT" matTooltip="{{(toolTipList)?toolTipList.YEAR10AMOUNT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR10AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR10AMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR10AMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR10AMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4">
            <input matInput formControlName="YEAR10INSTALMENT" matTooltip="{{(toolTipList)?toolTipList.YEAR10INSTALMENT?.HOVER:''}}"
            toolTipPostion ="above" style="text-align: right;" [value]="matterLeasingForm.get('YEAR10INSTALMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.YEAR10INSTALMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.YEAR10INSTALMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.YEAR10INSTALMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>