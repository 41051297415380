import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";

import { FuseConfigService } from "src/@fuse/services/config.service";
import { FuseNavigationService } from "src/@fuse/components/navigation/navigation.service";
import { FusePerfectScrollbarDirective } from "src/@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import * as $ from "jquery";
import { ToastrService } from "ngx-toastr";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";

@Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component
    implements OnInit, OnDestroy, AfterViewInit {
    currentUser: any;
    fuseConfig: any;
    navigation: any;
    greenTheme: boolean;
    defultTheme: boolean;
    sidebar: any;
    funcListner: any;
    getperefrence: any;
    toolbarsetting: any;
    preferenceObjectData: any;
    func(event): void {
        if (event.key == "sidebar_fold_unfold") {
            if (event.newValue == "true") {
                $("body").addClass("inbox_email_width");
                setTimeout(() => {
                    this._fuseSidebarService.getSidebar("navbar").toggleFold();
                }, 0);
            } else {
                $("body").removeClass("inbox_email_width");
                setTimeout(() => {
                    this._fuseSidebarService.getSidebar("navbar").toggleFold();
                }, 0);
            }
        }
    }

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    // accessing favicon link tag
    favIcon: HTMLLinkElement = window.document.querySelector("#appIcon");

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (localStorage.getItem("theme_type") == "theme-yellow-light") {
            this.greenTheme = true;
            this.favIcon.href = "assets/images/logos/sm-silq-small-user2.png";
        } else {
            this.defultTheme = true;
            this.favIcon.href = "assets/images/logos/sm-silq-small.png";
        }

        this.funcListner = this.func.bind(this);
        window.addEventListener("storage", this.funcListner);
        this.behaviorService.toolBaarSetting$.subscribe((result) => {
            if (result) {
                this.toolbarsetting = result;
                this.ngAfterViewInit();
            }
        });
        this.behaviorService.GetPreferences$.subscribe((result) => {
            if (result) {
                this.preferenceObjectData = result;
            } else {
                this.preferenceObjectData = JSON.parse(
                    localStorage.getItem("currentUser")
                );
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector(
                        "navbar .nav-link.active"
                    );

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop,
                            activeItemOffsetParentTop =
                                activeNavItem.offsetParent.offsetTop,
                            scrollDistance =
                                activeItemOffsetTop -
                                activeItemOffsetParentTop -
                                48 * 3 -
                                170;

                        this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        $(document).on("click", ".sidebarwrap .overlaywrap", function () {
            $(".toggle-sidebar-opened").trigger("click");
        });

        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar("navbar")) {
                    this._fuseSidebarService.getSidebar("navbar").close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();
            });
        // this.addClassinDev();

        this.getperefrence = JSON.parse(
            localStorage.getItem("set_preferences")
        );
        let resetWatch = setInterval(() => {
            this.getperefrence = JSON.parse(
                localStorage.getItem("set_preferences")
            );
            if (this.getperefrence) {
                clearTimeout(resetWatch);
            }
        }, 500);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        window.removeEventListener("storage", this.funcListner);
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        if (
            localStorage.getItem("sidebar_fold_unfold") === "true" ||
            this.preferenceObjectData?.NAVBARFOLDED
        ) {
            $("body").addClass("inbox_email_width");
            this._fuseSidebarService.getSidebar("navbar")?.fold();
            this.behaviorService.setMenuFoldUnfold(true);
            // setTimeout(() => {
            //     this._fuseSidebarService.getSidebar('navbar').toggleFold();
            //     this.behaviorService.setMenuFoldUnfold(true);

            // }, 300);
        } else {
            $("body").removeClass("inbox_email_width");
            this._fuseSidebarService.getSidebar("navbar")?.unfold();
            this.behaviorService.setMenuFoldUnfold(false);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        // this.addClassinDev();
        // this._fuseSidebarService.getSidebar('navbar').toggleOpen();
        if ($(".navbar-fuse-sidebar").hasClass("open")) {
            $(".navbar-fuse-sidebar").removeClass("open");
            $(".sidebarwrap .overlaywrap").remove();
        } else {
            $(".navbar-fuse-sidebar").addClass("open animations-enabled");
            $(".navbar-fuse-sidebar").after(
                '<div class="fuse-sidebar-overlay show overlaywrap"></div>'
            );
        }
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        //this.addClassinDev();
        if ($("body").hasClass("inbox_email_width")) {
            $("body").removeClass("inbox_email_width");
            localStorage.setItem("sidebar_fold_unfold", JSON.stringify(false)); //unfold
            this.preferenceObjectData.NAVBARFOLDED = false;
            this.behaviorService.GetPreferences$.next(
                this.preferenceObjectData
            );
            this.behaviorService.setMenuFoldUnfold(false);
        } else {
            $("body").addClass("inbox_email_width");
            localStorage.setItem("sidebar_fold_unfold", JSON.stringify(true)); //fold
            this.preferenceObjectData.NAVBARFOLDED = true;
            this.behaviorService.GetPreferences$.next(
                this.preferenceObjectData
            );
            this.behaviorService.setMenuFoldUnfold(true);
        }

       // this.preferenceSave();

        // this._fuseSidebarService.getSidebar('navbar').toggleFold();
        if ($(".navbar-fuse-sidebar").hasClass("open")) {
            $(".navbar-fuse-sidebar").removeClass("open");
            // $('.sidebarwrap .overlaywrap').remove();
        } else {
            $(".navbar-fuse-sidebar").addClass("open animations-enabled");
            // $('.navbar-fuse-sidebar').after('<div class="fuse-sidebar-overlay show overlaywrap"></div>');
        }
    }

    /**
     * This function is used to save the preferances data value.
     */
    preferenceSave() {
        let details = {
            FormAction: "update",
            VALIDATEONLY: false,
            Data: this.preferenceObjectData,
        };
        this._mainAPiServiceService.getSetData(details, "preference").subscribe(
            (response) => {
                if (
                    response.CODE == 200 &&
                    (response.STATUS == "OK" || response.STATUS == "success")
                ) {
                    localStorage.setItem(
                        "currentUser",
                        JSON.stringify(this.preferenceObjectData)
                    );

                    this.toastr.success(" update successfully");
                } else if (
                    response.CODE == 451 &&
                    response.STATUS == "warning"
                ) {
                    this.toastr.warning(response.MESSAGE);
                } else if (response.CODE == 450 && response.STATUS == "error") {
                    this.toastr.error(response.MESSAGE);
                } else if (response.MESSAGE == "Not logged in") {
                    // this.dialogRef.close(false);
                }
            },
            (error) => {
                this.toastr.error(error);
            }
        );
    }
    // addClassinDev() {
    //     if ($('.navbar-fuse-sidebar').hasClass("unfolded")) {
    //         $('div.sticky_search_div ').addClass('sticky_search_remove_padding');
    //     }
    //     if ($('.navbar-fuse-sidebar').hasClass("folded")) {
    //         $('div.sticky_search_div ').removeClass('sticky_search_remove_padding');
    //     }
    // }
}
