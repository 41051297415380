<!-- <div class=" one " fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Property Details</mat-label>
            <input matInput readonly formControlName="Address2">
        </mat-form-field>
    </div>


    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <button mat-raised-button color="accent" class="mat-accent mr-4" fxFlex="50" (click)="Matteraddress()">
            <span>Update Property Details</span>
        </button>
        <button mat-raised-button color="accent" class="mat-accent mr-4" fxFlex="50" (click)="LeasingDetails()">
            <span>Update Leasing Details</span>
        </button>
    </div>
</div> -->

<div class="one rate_tab_main" fxLayout="column">

    <mat-accordion class="faqs-accordion">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="80">
                        <strong>Leasing Details</strong>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-leasing-details-popup  [testBool]='testBool'  [toolTipList]="toolTipList" [action]="action"></app-leasing-details-popup>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="80">
                        <strong>Property Details</strong>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-property-details-expandation  [testBool]='testBool' [action]="action" [toolTipList]="toolTipList" ></app-property-details-expandation>
        </mat-expansion-panel>
    </mat-accordion>
</div>