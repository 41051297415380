<div cdkDrag class="popup_main_div add_edit_contact_main select_metter_main_div">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle  (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="DairyForm" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div class="mb-2" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <label class="font-size-16"><span class="text-bold" >User Name : </span> {{SelectedDiaryUser?.FULLNAME}}</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.SUBJECT,'has-warning':errorWarningData.Warning?.SUBJECT}" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Subject</mat-label>
                        <input formControlName="SUBJECT" matInput matTooltip="{{(toolTipList)?toolTipList.SUBJECT?.HOVER:''}}">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.LOCATION,'has-warning':errorWarningData.Warning?.LOCATION}" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Location</mat-label>
                        <input formControlName="LOCATION"  matTooltip="{{(toolTipList)?toolTipList.LOCATIONmatTooltip?.HOVER:''}}" matInput>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOCATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.LOCATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-checkbox matTooltip="{{(toolTipList)?toolTipList.ALLDAYEVENT?.HOVER:''}}" formControlName="ALLDAYEVENT" (ngModelChange)="CheckAllDays($event)">All Day Event
                    </mat-checkbox>
                    &nbsp;&nbsp;
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.APPOINTMENTDATE,'has-warning':errorWarningData.Warning?.APPOINTMENTDATE}" appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Date</mat-label>
                        <input (dateInput)="DateFrom('input', $event)" (dateChange)="DateFrom('change', $event)" matInput matTooltip="{{(toolTipList)?toolTipList.APPOINTMENTDATEmatTooltip?.HOVER:''}}" formControlName="APPOINTMENTDATE" [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.APPOINTMENTTIME,'has-warning':errorWarningData.Warning?.APPOINTMENTTIME}" appearance="outline" fxFlex="33" class="pr-4" *ngIf="CheckClick ==='No'">
                        <mat-label>Start</mat-label>
                        <input formControlName="APPOINTMENTTIME" placeholder="Reminder Time" type="time" matInput (ngModelChange)="StartTime($event)">
                        <!-- <mat-select matTooltip="{{(toolTipList)?toolTipList.APPOINTMENTTIME:''}}" formControlName="APPOINTMENTTIME" (ngModelChange)="StartTime($event)">
                            <mat-option *ngFor="let data of timeStops" value="{{data}}">{{data}}</mat-option>
                        </mat-select> -->
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTTIME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTTIME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.APPOINTMENTENDTIME,'has-warning':errorWarningData.Warning?.APPOINTMENTENDTIME}" appearance="outline" fxFlex="33" class="pr-4" *ngIf="CheckClick==='No'">
                        <mat-label>Finish</mat-label>
                        <input formControlName="TimeSlot2" placeholder="Reminder Time" type="time" matInput (ngModelChange)="EndTime($event)">
                        <!-- <mat-select matTooltip="{{(toolTipList)?toolTipList.APPOINTMENTENDDATE:''}}" formControlName="TimeSlot2" (ngModelChange)="EndTime($event)">
                            <mat-option *ngFor="let data of timeStops" value="{{data}}">{{data}}</mat-option>
                        </mat-select> -->
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="Details">
                            <app-details [testBool]='testBool' [toolTipList]="toolTipList" [errorWarningData]="errorWarningData" [DairyForm]="DairyForm" [Action]="action" [mattername]="mattername" [origin]="diaryDialogOrigin" [SelectedMatterData]="SelectedMatterData"></app-details>
                        </mat-tab>
                        <mat-tab label="Recurrance Pattern">
                            <app-recurrance-pattern [errorWarningData]="errorWarningData" [DairyForm]="DairyForm" [defaultRecuurance]="defaultRecuurance">
                            </app-recurrance-pattern>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveAppointment()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveAppointment()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveAppointment()" [disabled]="isspiner" *ngIf="action !== 'new' && action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>