import { BehaviorService } from "@_services/Behavior.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { TooltipService } from "@_services/tooltip.service";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BankingDialogComponent } from "@pages/banking/banking-dialog.component";
import { Subscription } from "rxjs";

@Component({
    selector: "app-connect-bank-feed",
    templateUrl: "./connect-bank-feed.component.html",
    styleUrls: ["./connect-bank-feed.component.scss"],
})
export class ConnectBankFeedComponent implements OnInit, OnDestroy {
    action: string;
    dialogTitle: string;
    isLoadingResults: boolean = false;
    AUTHORISEDACCOUNTS: any[] = [];
    AUTHORISEIFRAMEURL: string = "";
    AUTHORISEURL: string = "";
    url: SafeResourceUrl;
    isOpen: boolean = false;
    selectedIndex: number;
    sub: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    bankFeedForm: FormGroup;
    constructor(
        public dialogRef: MatDialogRef<ConnectBankFeedComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private behaviorService: BehaviorService,
        public _matDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private globalFunService: GlobalFunctionsService,
        public tooltipService: TooltipService,
        private domSanitizer: DomSanitizer,
        private formBuilder: FormBuilder
    ) {
        this.action = data.action;
        if (this.action) {
            this.dialogTitle = this.action;
        }
        this.bankFeedForm = this.formBuilder.group({
            bankIds: this.formBuilder.array([])
        })
    }

    /**
     * get the formarray list of current form
     */
    get bankIdRef() {
        return this.bankFeedForm.get('bankIds') as FormArray;
    }

    /**
     *  function is for insert formdata to formarray
     * @param data parameter for adding data to formarray of current form
     */
    addBankFeedControl(data) {
        const bnkGroup = this.formBuilder.group({
            bankId: [data.ACCOUNTGUID],
            Bankac: [''],
            BankAccountName: [''],
            ACCOUNTGUID: [data.ACCOUNTGUID],
            ACCOUNTNUMBER: [data.ACCOUNTNUMBER],
            ACCOUNTTYPE: [data.ACCOUNTTYPE],
            BALANCE: [data.BALANCE],
            BANKFEEDACCOUNTGUID: [data.BANKFEEDACCOUNTGUID],
            BSB: [data.BSB],
            CUSTOMERGUID: [data.CUSTOMERGUID],
            LINKEDACCOUNTNAME: [data.LINKEDACCOUNTNAME]
        });
        this.bankIdRef.push(bnkGroup);
    } 

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.getList();
    }

    /**
     * function is called for set the current element index
     * @param i parameter for set current selected index
     */
    setIndex(i) {
        this.selectedIndex = i;
    }

    /**
     * get the list for all bank feed accounts
     */
    getList() {
        this.isLoadingResults = true;
        this.AUTHORISEDACCOUNTS = [];
        this.bankFeedForm.reset();
        this.bankIdRef.clear();
        let postData = { Action: "authorise", Data: {} };
        this.sub = this._mainAPiServiceService
            .getSetData(postData, "bank-feed")
            .subscribe((res) => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    this.isLoadingResults = false;
                    this.AUTHORISEDACCOUNTS = res.DATA.AUTHORISEDACCOUNTS;
                    if(this.AUTHORISEDACCOUNTS.length > 0){
                        this.AUTHORISEDACCOUNTS.map((data) => {
                            this.addBankFeedControl(data);
                        })
                    }
                    this.AUTHORISEIFRAMEURL = res.DATA.AUTHORISEIFRAMEURL;
                    this.AUTHORISEURL = res.DATA.AUTHORISEURL;
                    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(
                        this.AUTHORISEIFRAMEURL
                    );
                    this.openURL(this.AUTHORISEIFRAMEURL);
                    this.isOpen = true;
                    // if (this.AUTHORISEDACCOUNTS.length == 0) {
                    //     this.isOpen = true;
                    // }
                } else {
                    this.isLoadingResults = false;
                }
            });
    }

    /**
     * function is called when user select any bank account from list
     * @param type type of account
     * @param evt current event of button
     * @param form form data of selected element
     * @returns 
     */
    BankingDialogOpen(type: any, evt: any, form: FormGroup) {
        if (evt && evt.pointerType == "") {
            return;
        }
        // for by default bank account select not trust account
        const dialogRef = this._matDialog.open(BankingDialogComponent, { disableClose: true, width: "100%", data: { AccountType: type, FromWhere: "connectBankFeed",UseTrust: "No", RoterPath: 'connect-bank-feed' } });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                form.controls["Bankac"].setValue(result.ACCOUNTCLASS + " - " + result.ACCOUNTNUMBER + " " + result.ACCOUNTNAME);
                form.controls["BankAccountName"].setValue(result.ACCOUNTNAME)
                form.controls['bankId'].setValue(result.ACCOUNTGUID);
            }
        });
    }

    /**
     * function is called when user clicks on authorise more account button from bank feed popup
     * @param url parameter of current url which is get from API
     */
    openURL(url: string) {
        this.isOpen = true;
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngOnDestroy(): void {
        this.isOpen = false;
        this.sub?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
    }

    /**
     * function is called for connect the bank feed account of selected bank account from bank account list
     * @param formData parameter of current form data
     */
    connectAccount(formData: FormGroup) {
        if(formData.get('BANKFEEDACCOUNTGUID').value) {
            let postData = {
                Action: "Connect",
                Data: {
                    BANKFEEDACCOUNTGUID: formData.get('BANKFEEDACCOUNTGUID').value,
                    ACCOUNTGUID: formData.get('bankId').value
                }
            };
            this.sub2 = this._mainAPiServiceService
                .getSetData(postData, "bank-feed")
                .subscribe((res) => {
                    if (res.STATUS == "success") {
                        this.bankIdRef.controls.forEach(element => {
                            if(element.get('BANKFEEDACCOUNTGUID').value == formData.get('BANKFEEDACCOUNTGUID').value) {
                                element.get('ACCOUNTGUID').setValue(formData.get('bankId').value);
                                element.get('LINKEDACCOUNTNAME').setValue(formData.get('BankAccountName').value);
                            }
                        });
                    }
            });
        }
    }

    /**
     * function is called for disconnect the bank feed account from bank account list when user click on disconnect button
     * @param formData parameter of current form data
     */
    disconnectAccount(formData: FormGroup){
        if(formData.get('BANKFEEDACCOUNTGUID').value) {
            let postData = {
                Action: "disconnect",
                Data: {
                    BANKFEEDACCOUNTGUID: formData.get('BANKFEEDACCOUNTGUID').value,
                }
            };
            this.sub3 = this._mainAPiServiceService
                .getSetData(postData, "bank-feed")
                .subscribe((res) => {
                    if (res.STATUS == "success") {
                        this.bankIdRef.controls.forEach(element => {
                            if(element.get('BANKFEEDACCOUNTGUID').value == formData.get('BANKFEEDACCOUNTGUID').value) {
                                element.get('ACCOUNTGUID').setValue('');
                                element.get('LINKEDACCOUNTNAME').setValue('');
                            }
                        });
                    }
            });
        }
    }

    /**
     * This function is used to open the bank feed data value.
     */
    backFeed(){
        this.isOpen = false;
    }
}
