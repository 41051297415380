import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit, OnDestroy {
  ReceipttData: any = [];
  isLoadingResults: boolean = false;
  displayedColumns: string[] = ['RECEIPTCODE', 'DATERECEIVED','AMOUNTRECEIVED', 'EXGSTAMOUNT', 'GST', 'PAYMENTTYPE'];
  receiptDatasource: any[] = [];
  sub1: Subscription;
  sub2: Subscription;

  constructor(private _mainAPiServiceService: MainAPiServiceService, public datepipe: DatePipe,
    private behaviorService:BehaviorService ) { 
      localStorage.setItem('istrackid', 'AccMangReceiptsComponent');
      this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
        if (localStorage.getItem('istrackid') == 'AccMangReceiptsComponent' && result['click'] && result['data']['HOGetReceipts']) {
          this.ngOnInit();
        }
      });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.loadData({});
  }

  ngOnDestroy(): void {
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to choosed the date. 
   * @param type -date type data
   * @param event -event data
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value['begin'], 'dd/MM/yyyy');
    let end = this.datepipe.transform(event.value['end'], 'dd/MM/yyyy');
    this.loadData({ ITEMDATESTART: begin, ITEMDATEEND: end });
  }

  /**
   * This function is used to load the data.
   * @param filterData -filter data
   */
  loadData(filterData: any) {
   this.sub2 = this._mainAPiServiceService.getSetData(filterData, 'HOGetReceipts').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.ReceipttData = response.DATA.RECEIPTS;
        this.receiptDatasource = this.ReceipttData;
        this.behaviorService.loadingAccountMNG('receipt');
      } else {
        this.behaviorService.loadingAccountMNG('receipt');
      }
    });
  }
}
