

<div id="myprofile" class="con page-layout simple left-sidebar inner-sidebar inner-scroll">
    <div class="content">
        <fuse-sidebar class="sidebar sidebar-wrapper" name="contacts-main-sidebar" position="left" lockedOpen="gt-sm">
            <div class="content" fusePerfectScrollbar  style="height: 100%;">
                <div class="sidebar-content">
                    <div class="card">
                        <div class="content py-16 sidebar-scrollwrap" fusePerfectScrollbar>
                            <div class="nav material2 my-profile-side-nav" routerLinkActive="" [routerLinkActiveOptions]="{exact: true}" >

                                <div class="nav-item name" aria-label="inbox">
                                    <a  [routerLink]="'/my-profile/general'"  class="nav-link" matRipple [ngClass]="{'cmn_active_tab accent':activeSubMenu =='general'}">
                                        <span class="title">General</span>
                                    </a>
                                </div>

                                <div class="nav-item name" aria-label="inbox">
                                    <a  [routerLink]="'/my-profile/security'"   [ngClass]="{'cmn_active_tab accent':activeSubMenu =='security'}" 
                                    class="mat-nav-link" class="nav-link" matRipple >
                                        <span class="title">Security</span>
                                    </a>
                                </div>

                                <div class="nav-item name" aria-label="inbox">
                                    <a  [routerLink]="'/my-profile/preferences'"  class="nav-link" matRipple [ngClass]="{'cmn_active_tab accent':activeSubMenu =='preferences'}">
                                        <span class="title">Preferences</span>
                                    </a>
                                </div>

                                <div class="nav-item name" aria-label="inbox" >
                                    <a  [routerLink]="'/my-profile/useractivity'"  class="nav-link" matRipple [ngClass]="{'cmn_active_tab accent':activeSubMenu =='useractivity'}">
                                        <span class="title">My Sessions</span>
                                    </a>
                                </div>

                                <div class="nav-item name" aria-label="inbox" >
                                    <a  [routerLink]="'/my-profile/connections'"  class="nav-link" matRipple [ngClass]="{'cmn_active_tab accent':activeSubMenu =='connections'}">
                                        <span class="title">Connections</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fuse-sidebar>

        <div class="center p-2410" fusePerfectScrollbar>
            <!-- <div class="example-containerdata"> -->
            <!-- CONTENT -->
            <div class="content position-relative div-hight">
                <router-outlet></router-outlet>
            </div>
        </div>

    </div>

   
</div>
