import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-newWriteOffInvoice',
  templateUrl: './newWriteOffInvoice.component.html',
  styleUrls: ['./newWriteOffInvoice.component.scss'],
  animations: fuseAnimations
})
export class WriteOffInvoiceComponent implements OnInit, OnDestroy {
  isLoadingResults: boolean = false;
  addData: any = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  errorWarningData: any = { "Error": [], 'Warning': [] };
  isspiner: boolean = false;
  WriteOffInvoice: any = {};
  currentMatter: any;
  isbtnDisable: any;
  windowNameId:any;
  testBool: boolean=false;
  toolTipList: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub1: Subscription;
  constructor(private _mainAPiServiceService: MainAPiServiceService,
    public globalFunService:GlobalFunctionsService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private behaviorService: BehaviorService,
    public dialogRef: MatDialogRef<WriteOffInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any, public _matDialog: MatDialog,
    public tooltipService:TooltipService,) {
    localStorage.setItem('istrackid', 'Invoice_newWriteOffInvoiceComponent');
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'Invoice_newWriteOffInvoiceComponent' && result['click'] && result['data']['invoice']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'Invoice_newWriteOffInvoiceComponent' && result['click'] && result['data']['SetInvoice']) {
        this.saveWriteOff();
      }
    });
  }

  ngOnInit() {
    this.WriteOffInvoice = {
      matter: '', client: '', INVOICECODE: '', INVOICEDATE: '', DUEDATE: '', TOTALINVOICES: '', TOTALRECEIVED: '', TOTALOUSTANDING: '', WriteOffDate: '', AMOUNT: ''
    };
    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.currentMatter = result;
    //   }
    // });
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
       this.currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
       if (this.currentMatter) {
       } else {
         if (result) {
           this.currentMatter = result;
           localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
         }
       }
     });

    this.EditPopUpOPen();
    this.setTooltipData()}

    /**
     * This function is used to set the tooltip data value
     */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['Invoice'] ){
      this.toolTipList  = TooltipData['Invoice'];
    }else{
      this.tooltipService.setToolTipData = ('Invoice');
     }
  };

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub1?.unsubscribe();
  }

  /**
   * This function is used to toggle the changes
   */
  ToggleForUpadte():void {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to Edit popup Open
   */
  EditPopUpOPen():void {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({"Action": "GetData",Filters:{ 'INVOICEGUID': this._data.INVOICEGUID }}, 'invoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if ((res.CODE == 200 || res.CODE == '200') && res.STATUS == "success") {
        let invoiceData = res.DATA.RECORDS[0];
        this.WriteOffInvoice.matter = invoiceData.SHORTNAME;
        this.WriteOffInvoice.client = invoiceData.CLIENTNAME;
        let temInvoice = invoiceData.INVOICECODE;
        this.WriteOffInvoice.INVOICECODE = temInvoice.toString().padStart(8, "0");
        let InvoiceDate = invoiceData.INVOICEDATE.split("/");
        let date = new Date(InvoiceDate[1] + '/' + InvoiceDate[0] + '/' + InvoiceDate[2]);
        this.WriteOffInvoice.INVOICEDATE = date;
        let DueDate = invoiceData.DUEDATE.split("/");
        let DUE = new Date(DueDate[1] + '/' + DueDate[0] + '/' + DueDate[2]);
        this.WriteOffInvoice.DUEDATE = DUE;
        this.WriteOffInvoice.TOTALINVOICES = res.DATA.TOTALINVOICES;
        this.WriteOffInvoice.TOTALRECEIVED = res.DATA.TOTALRECEIVED;
        this.isbtnDisable = res.DATA.TOTALOUTSTANDING == 0 ? true : false;
        this.WriteOffInvoice.TOTALOUSTANDING = res.DATA.TOTALOUTSTANDING
        ;
        this.WriteOffInvoice.WriteOffDate = new Date();
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isLoadingResults = false;
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }


  /**
   * This function is used to save the new Write offs data
   */
  saveWriteOff() {
    let data = {
      INVOICEGUID: this._data.INVOICEGUID,
      MATTERGUID: this.currentMatter.MATTERGUID,
      WRITEOFFDATE: this.datepipe.transform(this.WriteOffInvoice.WriteOffDate, 'dd/MM/yyyy'),
      WRITEOFFAMOUNT: this.WriteOffInvoice.AMOUNT
    }
    this.isspiner = true;
    let finalData = { DATA: data, FormAction: 'write off', VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'SetInvoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to check the validation
   * @param bodyData -body data value
   * @param details -details data value
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'ERROR' || value.VALUEVALID == 'No' || value.VALUEVALID == 'NO') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   } else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.InvoiceWriteoffSaveData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.InvoiceWriteoffSaveData(details);
    // }
    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        // this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.InvoiceWriteoffSaveData(details);
        }
      }
    });
  }

  /**
   * This function is used to save the Invoice Write off data
   * @param data -data value
   */
  InvoiceWriteoffSaveData(data: any) {
    data.VALIDATEONLY = false;
    this.sub1 = this._mainAPiServiceService.getSetData(data, 'SetInvoice').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success(' save successfully');
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }

}
