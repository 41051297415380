<div class="one temp-height height-525" fxLayout="row">
    <div>
        <div class="shadow1">
            <div class="header-title">Password</div><br>
            <button mat-raised-button style="font-weight: 600;" (click)="ChangePass()">Change your password</button>
        </div>
        <br>
        <div class="shadow2">
            <div class="header-title">
                Multi Factor Authentication
                <span [ngClass]="AWAITING2FA == 'true' ? 'btn-on' : 'btn-off'" > {{ AWAITING2FA == 'true' ? 'On' : 'Off' }}</span>
            </div>
            <h4 style="margin-bottom: 0px;">
                After entering your password, on login, verify your identity with an authentication method. </h4><br>
                <!-- <div>
                    <button *ngIf="AWAITING2FA == 'false' || AWAITING2FA == 'default'" mat-raised-button style="font-weight: 600;" (click)="targetNewTab('/multi-factor-authentication', 'MULTIFACTORAUTHENTICATION')">Set up multi-factor authentication</button>
                </div><br> -->
            <div class="border-line" *ngIf="AWAITING2FA == 'true'"></div><br>
            <div class="header-title-2" *ngIf="AWAITING2FA == 'true'">Primary method</div>
            <h3 style="font-weight: 600;" *ngIf="AWAITING2FA == 'true'">Use the Google Authenticator to generate a secure code</h3>
            <div class="auth-div" *ngIf="AWAITING2FA == 'true'">
                <div class="text-div">
                    <img src="assets/icons/web_app/Google Auth.webp">
                    <span>Google Authenticator</span>
                </div>
                <div class="text-div">
                    <button mat-button>Turn Off</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sec-div">
        <app-multi-factor-authentication></app-multi-factor-authentication>
    </div>
</div>