import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumberSeparatorFormat]'
})
export class NumberSeparatorFormatDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  /**
   * This is used for focusout
   */
  @HostListener('focusout') onBlur(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue: string = inputElement.value;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/[^0-9.-]/g, '');

    // Format the number with two decimal places and a thousand separator
    const formattedValue = this.formatNumber(parseFloat(inputValue));

    // Update the input value
    this.renderer.setProperty(inputElement, 'value', formattedValue);
      // this.el.nativeElement.value = formattedValue;
  }

  /**
   * This is used to keydown.
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      // event.preventDefault();
      // Your logic here
      const inputElement = this.el.nativeElement as HTMLInputElement;
      let inputValue: string = inputElement.value;

      // Remove non-numeric characters
      inputValue = inputValue.replace(/[^0-9.-]/g, '');

      // Format the number with two decimal places and a thousand separator
      const formattedValue = this.formatNumber(parseFloat(inputValue));

      // Update the input value
      this.renderer.setProperty(inputElement, 'value', formattedValue);
    }
  }

  /**
   * This is used to format the number. 
   */
  private formatNumber(value: number): string {
    if (isNaN(value)) {
      return '';
    }

    // Use Intl.NumberFormat for formatting with two decimal places and a thousand separator
    const formattedValue = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);

    return formattedValue;
  }

  // @HostListener('change', ['$event'])
  // onInput(event: any) {
    // if (this._inputEl.nativeElement.value === '-') return;
    // let commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');
    // let toInt: number;
    // let toLocale: string;
    // if (commasRemoved.split('.').length > 1) {
    //   let decimal = isNaN(parseInt(commasRemoved.split('.')[1]))
    //     ? ''
    //     : parseInt(commasRemoved.split('.')[1]);
    //   toInt = parseInt(commasRemoved);
    //   toLocale = toInt.toLocaleString('en-US') + '.' + decimal;
    // } else {
    //   toInt = parseInt(commasRemoved);
    //   toLocale = toInt.toLocaleString('en-US');
    // }
    // let val = parseFloat(event.target.value);
    // if (!isNaN(val)) {
    //   // if (Number.isInteger(val)) {
    //   //   this._inputEl.nativeElement.value = this.addCommas(val);
    //   // } else {
    //     this._inputEl.nativeElement.value = this.formatNumber(val.toFixed(4));
    //   // }
    // } else {
    //   this._inputEl.nativeElement.value = ''; // Handle non-numeric values
    // }


    // const num = parseFloat(event.target.value).toFixed(4);
    // const finalnum = this.formatNumber(num);
    // this._inputEl.nativeElement.value = finalnum;
    // }
  // }

  // private addCommas(value: number): string {
  //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  // formatNumber = (number) => {
  //   const value = number + '';
  //   const list = value.split('.');
  //   const prefix = list[0].charAt(0) === '-' ? '-' : '';
  //   let num = prefix ? list[0].slice(1) : list[0];
  //   let result = '';
  //   while (num.length > 3) {
  //     result = `,${num.slice(-3)}${result}`;
  //     num = num.slice(0, num.length - 3);
  //   }
  //   if (num) {
  //     result = num + result;
  //   }
  //   return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  // };

}
