<div id="app-collegeof-law" fxLayout="column">
    <div id="app-collegeof-law-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="app-collegeof-law-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div class="logo">
                <img src="assets/images/logos/75.png">
            </div>
            <div class="title">College of Law</div>
            <div class="title-details">Please enter the Student ID and Password and click save. You will then receive a confirmation message.</div>
            <form name="collegeofLawForm" [formGroup]="collegeofLawForm" novalidate>
                <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CUSTOMERNAME,'has-warning':errorWarningData.Warning?.CUSTOMERNAME}">
                    <mat-label>Student ID</mat-label>
                    <input matInput formControlName="CustomerName">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="collegeofLawForm.get('CustomerName').hasError('required')">
                        Student ID is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.PASSWORD,'has-warning':errorWarningData.Warning?.PASSWORD}">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="Password" required [type]="hide ? 'password' : 'text'">
                    <mat-icon matSuffix class="secondary-text" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="collegeofLawForm.get('CustomerName').hasError('required')">
                        Password is required
                    </mat-error>
                </mat-form-field>
                <button mat-raised-button color="accent" class="mat-accent" class="submit-button" (click)="ondialogSaveClick()" [disabled]="isspiner" aria-label="Save" [disabled]="collegeofLawForm.invalid">
                    <mat-spinner *ngIf="isspiner"></mat-spinner>Save
                </button>
            </form>
        </div>
    </div>
</div>