import {
    Component,
    OnInit,
    Inject,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
    ElementRef,
    ChangeDetectorRef,
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserBudgetDialogComponent } from "./user-budget-dialog/user-budget-dialog.component";
import { NgForm } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import * as ClassicEditor from "../../../../../../src/ckeditor.js";
import { RatesOverridesDialogComponent } from "./rates-overrides-dialog/rates-overrides-dialog.component";
import { fuseAnimations } from "src/@fuse/animations";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { TimersService } from "@_services/timers.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { TooltipService } from "@_services/tooltip.service";
import { Subject, Subscription, takeUntil } from "rxjs";

import { HEIGHT, PASTECleanupSettings, iFRAME, QUICKToolbarSettings, TOOLbarSettings, INSERTImageSettings, backgroundColor, fontColor } from 'src/app/_constant/rich-text-editor-toolbarFeature';
import { ImageEditorComponent } from "@syncfusion/ej2-angular-image-editor";
import { Browser, getComponent } from "@syncfusion/ej2-base";
import { TierPermissionPipe } from "@_pipes/tier-permission.pipe";
@Component({
    selector: "app-user-dialog",
    templateUrl: "./user-dialog.component.html",
    styleUrls: ["./user-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [TierPermissionPipe]
})
export class UserDialogComponent implements OnInit, OnDestroy {
    iframe: any = iFRAME;
    height: any = HEIGHT;
    insertImageSettings: any = INSERTImageSettings;
    pasteCleanupSettings: any = PASTECleanupSettings;
    quickToolbarSettings: any = QUICKToolbarSettings;
    toolbarSettings: any = TOOLbarSettings;
    backgroundColor=backgroundColor;
    fontColor=fontColor
    @ViewChild("userForm", { static: true }) ngForm: NgForm;
    private _unsubscribeAll$: Subject<void> = new Subject();

    //START budget related variable
    USERBUDGETGUIDIndex: number = 1;
    isNewShow: boolean = true;
    isEditShow: boolean = true;
    highlightedRows: any;
    isLoadingResults: boolean = false;
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    userBudgets: any = [];
    tempuserBudgets: any = [];
    currentBudgets: any;
    pageSize: any;
    fileToUpload: any = [];
    finalfileToUpload: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    GetSetMixRetry: boolean = true;
    public finetuneSetting = {
        brightness: { min: -100, max: 100, defaultValue: 10 },
        contrast: { min: -100, max: 100, defaultValue: 10 }
      }
      public customToolbar = ['Open', 'Crop', 'ZoomIn', 'ZoomOut', 'Transform'];
      public customToolbardata = ['Open', 'Crop', 'ZoomIn', 'ZoomOut', 'Transform'];
    displayedColumns: string[] = [
        "PERIODSTART",
        "TOTALBUDGETHOURS",
        "TOTALBUDGETDOLLARS",
    ];
    displayedColumnsdata: string[] = [
        "RATETYPE",
        "LINKDESC",
        "hr1",
        "hr2",
        "hr3",
        "hr4",
        "hr5",
        "hr6",
        "day1",
        "day2",
        "day3",
        "day4",
        "day5",
        "day6",
    ];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    HtmlCss: SafeHtml;
    signatureType: any = "simple";
    ckeditorValue: any = "";
    configsOption: any;
    public Editor = ClassicEditor;
    signatureFileToUpload: any = "";
    signFileToUpload: any;
    //END budget related variable
    public userData: any = {
        USERGUID: "",
        USERID: "",
        USERNAME: "",
        FULLNAME: "",
        ISACTIVE: true,
        ISPRINCIPAL: 0,
        GSTTYPE: "GST Exclusive",
        RATEPERHOUR_1: 0,
        RATEPERHOUR_2: 0,
        RATEPERHOUR_3: 0,
        RATEPERHOUR_4: 0,
        RATEPERHOUR_5: 0,
        RATEPERHOUR_6: 0,
        RATEPERDAY_1: 0,
        RATEPERDAY_2: 0,
        RATEPERDAY_3: 0,
        RATEPERDAY_4: 0,
        RATEPERDAY_5: 0,
        RATEPERDAY_6: 0,
        POSITION: "",
        PHONE1: "",
        PHONE2: "",
        FAX1: "",
        FAX2: "",
        MOBILE: "",
        EMAIL: "",
        PRACTICINGCERTIFICATENO: "",
        SEARCHUSERNAME: "",
        COMMENT: "",
        OFFICEDESCRIPTION: "",
        OFFICELOCATION: "",
        DONTSHOWINDIRECTORY: "",
        PHOTO: "",
        BIOGRAPHY: "",
        INVOICEBANKBSB: "",
        INVOICEBANKACCOUNTNUMBER: "",
        INVOICEBANKACCOUNTNAME: ""
    };

    public userRateOverride: any = {
        USERGUID: "",
        USERID: "",
        USERNAME: "",
        FULLNAME: "",
        ISACTIVE: true,
        ISPRINCIPAL: 0,
        GSTTYPE: "GST Exclusive",
        RATEPERHOUR_1: 0,
        RATEPERHOUR_2: 0,
        RATEPERHOUR_3: 0,
        RATEPERHOUR_4: 0,
        RATEPERHOUR_5: 0,
        RATEPERHOUR_6: 0,
        RATEPERDAY_1: 0,
        RATEPERDAY_2: 0,
        RATEPERDAY_3: 0,
        RATEPERDAY_4: 0,
        RATEPERDAY_5: 0,
        RATEPERDAY_6: 0,
        POSITION: "",
        PHONE1: "",
        PHONE2: "",
        FAX1: "",
        FAX2: "",
        MOBILE: "",
        EMAIL: "",
        PRACTICINGCERTIFICATENO: "",
        SEARCHUSERNAME: "",
        COMMENT: "",
        OFFICEDESCRIPTION: "",
        OFFICELOCATION: "",
        DONTSHOWINDIRECTORY: "",
        PHOTO: "",
        BIOGRAPHY: "",
        INVOICEBANKBSB: "",
        INVOICEBANKACCOUNTNUMBER: "",
        INVOICEBANKACCOUNTNAME: ""
    };

    userRateOverrideData: any = [];

    PermissionsCons = {
        "MATTER DETAILS": "1",
        "DAY BOOK / TIME ENTRIES": "2",
        CONTACTS: "3",
        ESTIMATES: "4",
        "DOCUMENT/EMAIL GENERATION": "5",
        "DOCUMENT REGISTER": "6",
        INVOICING: "7",
        "RECEIVE MONEY": "8",
        "SPEND MONEY": "9",
        CHRONOLOGY: "10",
        TOPICS: "11",
        AUTHORITIES: "12",
        "FILE NOTES": "13",
        "SAFE CUSTODY": "14",
        "SAFE CUSTODY PACKET": "15",
        SEARCHING: "16",
        DIARY: "17",
        TASKS: "18",
        "CHART OF ACCOUNTS": "19",
        "GENERAL JOURNAL": "20",
        "OTHER ACCOUNTING": "21",
        "TRUST MONEY": "22",
        "TRUST CHART OF ACCOUNTS": "23",
        "TRUST GENERAL JOURNAL": "24",
        "TRUST REPORTS": "25",
        "ACCOUNTING REPORTS": "26",
        "MANAGEMENT REPORTS": "27",
        SYSTEM: "28",
        USERS: "29",
        "ACTIVITIES/SUNDRIES": "30",
        "PROTECTED TRUST": "31",
        DELIVERABLES: "32",
        DASHBOARDS: "33",
    };
    AllUploadedFile: any = [];
    @ViewChild("uploader", { static: false }) uploader: ElementRef;
    @ViewChild('image-editor', { static: true }) imageEditor: ImageEditorComponent;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    userPermissiontemp: any = [];
    action: string;
    dialogTitle: string;
    dialogButton: string;
    isspiner = false;
    phide = true;
    public userinfoDatah: any = [];
    Permissions: any = [];
    formChangesSubscription: Subscription;
    UserListData: any;
    toolTipList: any = [];
    toolTipListUserRatesoverride: any = [];
    public files: any[] = [];
    permissionSkipArray: any = [
        "TRUST MONEY",
        "TRUST CHART OF ACCOUNTS",
        "TRUST GENERAL JOURNAL",
        "TRUST REPORTS",
        "ACCOUNTING REPORTS",
        "MANAGEMENT REPORTS",
    ];
    PermissionsConsArray: any[];
    currentUser: any;
    ratesoverrideData: any = [];
    dataForTable: any[];
    currentoverride: any;
    highlightedRowsoverride: any;
    pageSizetates: any;
    rowdata: any;
    sortdata: MatTableDataSource<any>;
    selectRow: any;
    testBool: boolean = false;
    rateList: any[] = [];
    PhotoWidth: any;
    SignatureWidth: any;
    SignatureHeight: any;
    PhotoHeight: any;

    @ViewChild('imageEditor')
    public imageEditorObj?: ImageEditorComponent;

    @ViewChild('imageEditor2')
    public imageEditorObj2?: ImageEditorComponent;
    constructor(
        public dialogRef: MatDialogRef<UserDialogComponent>,
        private sanitizer: DomSanitizer,
        public budgetDialogRef: MatDialogRef<UserBudgetDialogComponent>,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        public _matDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private Timersservice: TimersService,
        public globalFunService: GlobalFunctionsService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        public tooltipService: TooltipService,
        public cdf:ChangeDetectorRef
    ) {
        localStorage.setItem("istrackid", "UserDialogComponent");
        localStorage.setItem("istrackid", "UserDialogComponent");
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.action = data.action;
        if (this.action === "new") {
            this.dialogTitle = "New User";
            this.dialogButton = "Save";
        } else if (this.action === "edit") {
            this.dialogTitle = "Update User";
            this.dialogButton = "Update";
        } else {
            this.dialogTitle = "Duplicate User";
            this.dialogButton = "Duplicate";
        }
        this.behaviorService.dialogClose$
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((result) => {
                if (result != null) {
                    if (result.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                }
            });
        this.behaviorService.APIretryCall$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (
                localStorage.getItem("istrackid") == "UserDialogComponent" &&
                this.GetSetMixRetry &&
                result["click"] &&
                (result["data"]["user"] || result["data"]["user"])
            ) {
                this.ngOnInit();
            }
            if (
                localStorage.getItem("istrackid") == "UserDialogComponent" &&
                this.GetSetMixRetry == false &&
                result["click"] && ["user"]
            ) {
                this.SaveUser();
            }
        });
        this.configsOption = {
            fontSize: {
                options: [
                    8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                    72,
                ],
            },
            fontFamily: {
                options: [
                    "Arial",
                    "Bahnschrift",
                    "Calibri",
                    "Cambria",
                    '"Cambria Math"',
                    "Candara",
                    '"Comic Sans MS"',
                    "Consolas",
                    "Constantia",
                    "Corbel",
                    '"Courier New"',
                    "Ebrima",
                    '"Franklin Gothic"',
                    "Gabriola",
                    "Gadugi",
                    "Georgia",
                    '"Global Monospace"',
                    '"Global Sans Serif"',
                    '"Global Serif"',
                    '"Global User Interface"',
                    '"HoloLens MDL2 Assets"',
                    "Impact",
                    '"Ink Free"',
                    '"Javanese Text"',
                    '"Leelawadee UI"',
                    '"Lucida Console"',
                    '"Lucida Sans Unicode"',
                    '"Malgun Gothic"',
                    "Marlett",
                    '"Microsoft Himalaya"',
                    '"Microsoft JhengHei UI"',
                    '"Microsoft JhengHei"',
                    '"Microsoft New Tai Lue"',
                    '"Microsoft PhagsPa"',
                    '"Microsoft Sans Serif"',
                    '"Microsoft Tai Le"',
                    '"Microsoft YaHei UI"',
                    '"Microsoft Yi Baiti"',
                    "MingLiU-ExtB",
                    "MingLiU_HKSCS-ExtB",
                    '"Mongolian Baiti"',
                    '"MS Gothic"',
                    '"MS PGothic"',
                    '"MS UI Gothic"',
                    '"MV Boli"',
                    '"Myanmar Text"',
                    '"Nirmala UI"',
                    '"Palatino Linotype"',
                    "PMingLiU-ExtB",
                    '"Segoe MDL2 Assets"',
                    '"Segoe Print"',
                    '"Segoe Script"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Historic"',
                    '"Segoe UI Symbol"',
                    '"Segoe UI"',
                    "SimSun-ExtB",
                    '"Sitka Banner"',
                    '"Sitka Display"',
                    '"Sitka Heading"',
                    '"Sitka Small"',
                    '"Sitka Subheading"',
                    '"Sitka Text"',
                    "Sylfaen",
                    "Symbol",
                    "Tahoma",
                    '"Times New Roman"',
                    '"Trebuchet MS"',
                    "Verdana",
                    "Webdings",
                    "Wingdings",
                    '"Yu Gothic UI"',
                    '"Yu Gothic"',
                ],
            },
            toolbarGroups: [
                { name: "clipboard", groups: ["clipboard", "undo"] },
            ],
            extraPlugins: [],
            removePlugins: [
                "image",
                "uploadimage",
                "blockimagepaste",
                "dragdrop",
                "clipboard",
                "undo",
                "contextmenu",
                "paste",
            ],
            toolbar: {
                items: [
                    "heading",
                    "alignment",
                    "|",
                    "fontSize",
                    "fontFamily",
                    "fontColor",
                    "|",
                    "underline",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "blockQuote",
                    "mypreviousplugin",
                ],
            },
        };
    }

    ngOnDestroy() {
        this.formChangesSubscription?.unsubscribe();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.getuserList();
        this._mainAPiServiceService
            .getSetData(
                { Action: "getdata", Filters: { LookupType: "Rates" } },
                "lookup"
            )
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((responses) => {
                if (responses.CODE === 200 && responses.STATUS === "success") {
                    localStorage.setItem(
                        "Rates_lookups",
                        JSON.stringify(responses.DATA.LOOKUPS)
                    );
                    this.rateList = responses.DATA.LOOKUPS;
                }
            });
        if (this.action === "edit" || this.action === "duplicate") {
            this.isLoadingResults = true;
            this._mainAPiServiceService
                .getSetData(
                    {
                        Action: "GetData",
                        Filters: {
                            USERGUID: this.data.USERGUID,
                            AllData: true,
                        },
                    },
                    "user"
                )
                .subscribe(
                    async (response) => {
                        if (
                            response.CODE === 200 &&
                            response.STATUS === "success"
                        ) {
                            const userinfoData = response.DATA.USERS[0];
                            this.toolTipList = response.DATA.FIELDTIPS;
                            if (userinfoData.GSTTYPE == 0) {
                                userinfoData.GSTTYPE = "GST Exclusive";
                            } else if (userinfoData.GSTTYPE == 1) {
                                userinfoData.GSTTYPE = "GST Inclusive";
                            } else if (userinfoData.GSTTYPE == 2) {
                                userinfoData.GSTTYPE = "GST Free";
                            } else if (userinfoData.ISACTIVE == "Inactive") {
                                userinfoData.ISACTIVE = false;
                            }
                            this.setPermissionsCons(
                                userinfoData.PERMISSIONS,
                                "edit"
                            );
                            delete userinfoData["PERMISSIONS"];
                            this.userData = userinfoData;
                            this.ckeditorValue = this.userData.BIOGRAPHY;
                            this.finalfileToUpload = [userinfoData.PHOTO];
                            this.signatureFileToUpload = [
                                userinfoData.SIGNATURE,
                            ];
                            this.cdf.detectChanges()

                            const reportedUser = [];
                            await this.userData.DIRECTREPORTS.forEach(
                                async (userGuid) =>
                                    await reportedUser.push(userGuid.USERGUID)
                            );
                            this.userData.DIRECTREPORTS = await reportedUser;
                            this.rateDataGroupSet(
                                userinfoData.RATEPERDAY_GROUP,
                                userinfoData.RATEPERHOUR_GROUP
                            );
                            this.loadData();
                            this.getRatesOverride();
                            this.isLoadingResults = false;
                        } else if (response.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                        this.isLoadingResults = false;
                    },
                    (error) => {
                        this.toastr.error(error);
                    }
                );
            // this.loadData();
            this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
        } else {
            this.isLoadingResults = true;
            this._mainAPiServiceService
                .getSetData(
                    { Action: "default", VALIDATEONLY: true, DATA: {} },
                    "user"
                )
                .pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(
                    (res) => {
                        if (res.CODE == 200 && res.STATUS == "success") {
                            this.toolTipList = res.DATA.FIELDTIPS;
                            this.setPermissionsCons(
                                res.DATA.DEFAULTVALUES.PERMISSIONS,
                                "add"
                            );
                            this.getRatesOverride();
                        } else if (res.MESSAGE === "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    },
                    (error) => {
                        this.toastr.error(error);
                    }
                );
            setTimeout(() => {
                this.isLoadingResults = false;
            }, 2000);
        }
        this.formChangesSubscription = this.ngForm.form.valueChanges.subscribe(
            (form) => {
                this.changeForControl(form);
            }
        );
        this.setTooltipData();
    }

    /**
     * This function is used to open the photo
     */
     created = (): void => {
        setTimeout(() => {
            const imageEditor: ImageEditorComponent = getComponent(document.getElementById('image-editor'), 'image-editor');
            if (Browser.isDevice) {
                imageEditor.open(this.finalfileToUpload[0]);
            } else {
              if(this.finalfileToUpload[0] !== ""){
                imageEditor.open((this.finalfileToUpload[0].length >0) ? this.finalfileToUpload[0] : '');
              }
            }
        },0);
      }

      /**
       * This function is used to btn click
       */
    btnClick(): void {
        this.imageEditorObj.clearImage();
        this.finalfileToUpload[0] = "";
    }

    /**
     * This function is used to btn click
     */
    btnClick2(): void {
        this.imageEditorObj2.clearImage();
        this.signatureFileToUpload[0] = "";
    }


/**
 * This function is used to upload the Default signature
 */
      uploadSignature():void{
        setTimeout(() => {
            const imageEditor: ImageEditorComponent = getComponent(document.getElementById('image-editor-2'), 'image-editor');
            if (Browser.isDevice) {
                imageEditor.open(this.signatureFileToUpload[0]);
            } else {
                if(this.signatureFileToUpload[0] !== ""){
                    imageEditor.open((this.signatureFileToUpload[0] !== "" && this.signatureFileToUpload[0].length >0) ? this.signatureFileToUpload[0] : '');
                }
            }
        },0);
      }

/**
 * This function is used to open the file
 * @param data -file data
 */
      openfile(data):void{
       if(data){
        const imageEditor: any = getComponent(document.getElementById('image-editor'), 'image-editor');
           // imageEditor?.select("Square");
           if(imageEditor && imageEditor?.img){
            this.PhotoWidth=imageEditor?.img?.destWidth;
            this.PhotoHeight=imageEditor?.img?.destHeight;             
       }

           
            var imageData = imageEditor.getImageData();
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.width = imageData.width;
            canvas.height = imageData.height;
            ctx.putImageData(imageData, 0, 0);
            this.finalfileToUpload=[canvas.toDataURL()];
       }

      }

/**
 * This function is used to open the signature.
 * @param data - data value.
 */
      openSignature(data){
        if(data){
            const imageEditordata: any = getComponent(document.getElementById('image-editor-2'), 'image-editor');
           // imageEditordata?.select("16:9");
           if(imageEditordata && imageEditordata?.img){
            this.SignatureWidth=imageEditordata?.img?.destWidth;
            this.SignatureHeight=imageEditordata?.img?.destHeight;             
       }
             var imageData = imageEditordata.getImageData();
             var canvas = document.createElement('canvas');
             var ctx = canvas.getContext('2d');
             canvas.width = imageData.width;
             canvas.height = imageData.height;
             ctx.putImageData(imageData, 0, 0);
             this.signatureFileToUpload=[canvas.toDataURL()];
        }
 
      }

/**
 * This function is used to save the croped image Data.
 * @param args 
 */
      public toolbarItemClicked = (args: any): void => {
        if(args.item.tooltipText == "Apply") {
            const imageEditor: any = getComponent(document.getElementById('image-editor'), 'image-editor');
            var imageData = imageEditor.getImageData();
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.width = imageData.width;
            canvas.height = imageData.height;
            ctx.putImageData(imageData, 0, 0);
            this.finalfileToUpload=[canvas.toDataURL()];   
          // this.getImageSize(canvas.toDataURL())
        }else if(args.item.tooltipText=="Crop and Transform"){
            const imageEditor: any = getComponent(document.getElementById('image-editor'), 'image-editor');
         //   imageEditor?.select("Square");
        }else{
            const imageEditor: any = getComponent(document.getElementById('image-editor'), 'image-editor');
           // imageEditor?.select("Square");
           if(imageEditor && imageEditor?.img){
            this.PhotoWidth=imageEditor?.img?.destWidth;
            this.PhotoHeight=imageEditor?.img?.destHeight;             
       }
        }
    }


    /**
 * This function is used to save the croped image Data.
 * @param args 
 */
    public toolbarItemClickedForImage = (args: any): void => {
        if(args.item.tooltipText == "Apply") {
            const imageEditorval: any = getComponent(document.getElementById('image-editor-2'), 'image-editor');
            var imageData = imageEditorval.getImageData();
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.width = imageData.width;
            canvas.height = imageData.height;
            ctx.putImageData(imageData, 0, 0);
            this.signatureFileToUpload=[canvas.toDataURL()];   
          // this.getImageSize(canvas.toDataURL())
        }else if(args.item.tooltipText=="Crop and Transform"){
            const imageEditorval: any = getComponent(document.getElementById('image-editor-2'), 'image-editor');
           // imageEditorval?.select("16:9");
        }else{
            const imageEditordata: any = getComponent(document.getElementById('image-editor-2'), 'image-editor');
            if(imageEditordata && imageEditordata?.img){
             this.SignatureWidth=imageEditordata?.img?.destWidth;
             this.SignatureHeight=imageEditordata?.img?.destHeight;             
        }
        }
    }

/**
 * This function is used to set the tool tip data.
 */
    async setTooltipData():Promise<void> {
        let TooltipData = JSON.parse(localStorage.getItem("FieldTooltipData"));
        if (TooltipData && TooltipData["Users"]) {
            this.toolTipList = TooltipData["Users"];
        } else {
            this.tooltipService.setToolTipData = "Users";
        }
    }

    /**
     * This function is used to Toggle for update
     */
    ToggleForUpadte():void {
        this.testBool = !this.testBool;
        this.setTooltipData();
    }

    /**
     * This function is used to get the Rates Over rides.
     */
    getRatesOverride():void {
        const User = JSON.parse(localStorage.getItem("currentUser"));
        let payload = {
            Action: "GetData",
            Filters: {
                USERID: this.data.USERID,
            },
        };

        //old payload
        // { Action: 'default', VALIDATEONLY: true, USERID: User.UserId, DATA: {} }
        this._mainAPiServiceService
            .getSetData(payload, "user-rate")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((res) => {
                if (res.CODE == "200") {
                    this.dataForTable = [];
                    // this.ratesoverrideData = res.DATA.USERRATES;
                    // this.rowdata = res.DATA.USERRATES[0];

                    // this.highlightedRowsoverride = res.DATA.USERRATES[0].USERRATEGUID;
                    // this.currentoverride = res.DATA.USERRATES[0]

                    this.ratesoverrideData = res.DATA.RECORDS;
                    this.rowdata = res.DATA.RECORDS;

                    this.highlightedRowsoverride =
                        res.DATA.RECORDS.USERRATEGUID;
                    this.currentoverride = res.DATA.RECORDS;

                    this.ratesoverrideData.forEach((group) => {
                        this.dataForTable.push({
                            USERRATEGUID: group.USERRATEGUID,
                            RATETYPE: group.RATETYPE,
                            LINKDESC: group.LINKDESC,
                            day1: group.RATEPERDAY_GROUP.RATEPERDAY_1,
                            day2: group.RATEPERDAY_GROUP.RATEPERDAY_2,
                            day3: group.RATEPERDAY_GROUP.RATEPERDAY_3,
                            day4: group.RATEPERDAY_GROUP.RATEPERDAY_4,
                            day5: group.RATEPERDAY_GROUP.RATEPERDAY_5,
                            day6: group.RATEPERDAY_GROUP.RATEPERDAY_6,
                            hr1: group.RATEPERHOUR_GROUP.RATEPERHOUR_1,
                            hr2: group.RATEPERHOUR_GROUP.RATEPERHOUR_2,
                            hr3: group.RATEPERHOUR_GROUP.RATEPERHOUR_3,
                            hr4: group.RATEPERHOUR_GROUP.RATEPERHOUR_4,
                            hr5: group.RATEPERHOUR_GROUP.RATEPERHOUR_5,
                            hr6: group.RATEPERHOUR_GROUP.RATEPERHOUR_6,
                        });
                    });
                    this.sortdata = new MatTableDataSource(this.dataForTable);
                    this.sortdata.sort = this.sort;
                    this.sortdata.paginator = this.paginator;
                    this.pageSizetates = JSON.parse(
                        localStorage.getItem("Overriderates")
                    );
                }
            });
    }

    /**
     * This function is used to det the Active over rides data.
     * @param row 
     */
    setActiveoverride(row: any): void {
        this.rowdata = row;
        this.selectRow = row;
    }

    /**
     * This function is used to getting the updated controls data.
     * @param formControl 
     */
    changeForControl(formControl):void {
        //Error
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj };
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }
        //Warning
        const dubObj2 = { ...this.errorWarningDataArray.Warning };
        let element2 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj2)) {
            const KEY = key.toLocaleUpperCase();

            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element2[key];
                dubObj2[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj2 };
            } else if (!formControl[KEY]) {
                element2[key] = dubObj2[key];
            }
        }
    }

/**
 * This function is used to get the user data val.
 */
    loadData():void {
        this.isLoadingResults = true;
        let payload: any = {
            Action: "GetData",
        };

        payload.Filters = {
            USERGUID: this.userData.USERGUID,
            GETALLFIELDS: true,
        };

        this._mainAPiServiceService
            .getSetData(payload, "user-budget")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        if (
                            response.DATA.RECORDS[0] &&
                            response.DATA.RECORDS.length != 0
                        ) {
                            this.highlightedRows =
                                response.DATA.RECORDS[0].USERBUDGETGUID;
                            this.currentBudgets = response.DATA.RECORDS[0];
                            localStorage.setItem(
                                "current_budgets",
                                JSON.stringify(response.DATA.RECORDS[0])
                            );
                        } else {
                            this.isEditShow = false;
                        }
                        this.userBudgets = new MatTableDataSource(
                            response.DATA.RECORDS
                        );
                        this.userBudgets.paginator = this.paginator;
                        this.userBudgets.sort = this.sort;
                        this.isLoadingResults = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        this.budgetDialogRef.close(false);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    /**
     * This function is used to check the pagination change or not
     * @param event -pagination data.
     */
    onPaginateChange(event) {
        this.pageSize.user_dialog = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to Change the overriderates
     * @param event -page data.
     */
    onPaginateChangeOverriderates(event) {
        this.pageSizetates = event.pageSize;
        localStorage.setItem(
            "Overriderates",
            JSON.stringify(this.pageSizetates)
        );
    }

    /**
     * This function is used to delete the budget.
     */
    delete_budget(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            { disableClose: true, width: "100%" }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let budgetsData: any = JSON.parse(
                    localStorage.getItem("current_budgets")
                );
                if (this.userData.USERGUID != "") {
                    let postData = {
                        Action: "delete",
                        DATA: { USERBUDGETGUID: budgetsData.USERBUDGETGUID },
                    };
                    this._mainAPiServiceService
                        .getSetData(postData, "user-budget")
                        .pipe(takeUntil(this._unsubscribeAll$))
                        .subscribe((res) => {
                            if (res.STATUS == "success" && res.CODE == 200) {
                                this.toastr.success("Delete successfully");
                                this.loadData();
                            }
                        });
                } else {
                    for (var i = 0; i < this.tempuserBudgets.length; i++) {
                        if (
                            this.tempuserBudgets[i].USERBUDGETGUID ==
                            budgetsData.USERBUDGETGUID
                        ) {
                            this.tempuserBudgets.splice(i, 1);
                            i--;
                        }
                    }
                    this.isNewShow = true;
                    this.macktempBudgetDatatable();
                }
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to get the macktempBudgetDatatables
     */
    macktempBudgetDatatable():void {
        if (this.tempuserBudgets[0]) {
            this.highlightedRows = this.tempuserBudgets[0].USERBUDGETGUID;
            this.currentBudgets = this.tempuserBudgets[0];
            localStorage.setItem(
                "current_budgets",
                JSON.stringify(this.tempuserBudgets[0])
            );
            this.userBudgets = new MatTableDataSource(this.tempuserBudgets);
            this.userBudgets.paginator = this.paginator;
            this.userBudgets.sort = this.sort;
        } else {
            this.userBudgets = new MatTableDataSource([]);
            this.userBudgets.paginator = this.paginator;
            this.userBudgets.sort = this.sort;
        }
    }

    /**
     * This function is used to open the budget dialog data.
     * @param actionType -used to get the action type
     * @param Flag -flag of the dialog
     */
    budgetDailog(actionType, Flag) {
        if (Flag == "ForBudget") {
            const budgetDialogRef = this._matDialog.open(
                UserBudgetDialogComponent,
                {
                    disableClose: true,
                    panelClass: "UserBudget-dialog",
                    data: {
                        Flag: Flag,
                        action: actionType,
                        USERGUID: this.userData.USERGUID,
                        RATEPERHOUR: this.userData.RATEPERHOUR,
                        USERBUDGETGUIDIndex: this.USERBUDGETGUIDIndex,
                    },
                }
            );
            budgetDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    if (result != true) {
                        this.tempuserBudgets.push(result);
                        this.USERBUDGETGUIDIndex = this.USERBUDGETGUIDIndex + 1;
                        this.macktempBudgetDatatable();
                        this.isNewShow = false;
                    } else this.loadData();
                }
            });
        } else if (Flag == "Edit Override Rates") {
            const dialogRef = this.dialog.open(RatesOverridesDialogComponent, {
                disableClose: true,
                data: {
                    formAction: "new",
                    isEditRate: "",
                    rateType: "Matter",
                    linkGuid: "",
                    type: 1,
                    data: this.rowdata,
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.getRatesOverride();
                }
            });
        }
    }

    /**
     * This function is used to set the Active budget data.
     * @param val -value of the budget
     */
    setActiveBudget(val: any) {
        this.currentBudgets = val;
        localStorage.setItem("current_budgets", JSON.stringify(val));
    }
    //END buget
    /**
     * This function is used to get the rate per hour 
     */
    RatePerHourVal():void {
        this.userData.RATEPERHOUR = parseFloat(
            this.userData.RATEPERHOUR
        ).toFixed(2);
    }

    /**
     * This function is used to get the rate per day data. 
     */
    RatePerDayVal():void {
        this.userData.RATEPERDAY = parseFloat(this.userData.RATEPERDAY).toFixed(
            2
        );
    }

    /**
     * This function is used to set the permission data
     * @param tempPermission -data of the temp permission
     * @param type -type of the permission
     */
    setPermissionsCons(tempPermission, type: any) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));

        /**
         * key use for permission
         */

        this.PermissionsConsArray = [];
        for (let key in tempPermission) {
            this.PermissionsConsArray.push(key);
        }

        // const PermissionsCons = ['MATTER DETAILS', 'DAY BOOK / TIME ENTRIES', 'CONTACTS', 'ESTIMATES', 'DOCUMENT/EMAIL GENERATION', 'DOCUMENT REGISTER', 'INVOICING', 'RECEIVE MONEY', 'SPEND MONEY', 'CHRONOLOGY', 'TOPICS', 'AUTHORITIES', 'FILE NOTES', 'SAFE CUSTODY', 'SAFE CUSTODY PACKET', 'SEARCHING', 'DIARY', 'TASKS', 'CHART OF ACCOUNTS', 'GENERAL JOURNAL', 'OTHER ACCOUNTING', 'TRUST MONEY', 'TRUST CHART OF ACCOUNTS', 'TRUST GENERAL JOURNAL', 'TRUST REPORTS', 'ACCOUNTING REPORTS', 'MANAGEMENT REPORTS', 'SYSTEM', 'USERS', 'ACTIVITIES/SUNDRIES'];
        // const userPermissiontemp: any = [];
        if (tempPermission) {
            this.PermissionsConsArray.forEach((value) => {
                if (tempPermission[value]) {
                    if (currentUser.PRODUCTTYPE == "Barrister") {
                        if (this.permissionSkipArray.includes(value)) {
                        } else {
                            this.pushPermission(tempPermission, value, type);
                        }
                    } else {
                        this.pushPermission(tempPermission, value, type);
                    }
                }
            });
        }
        this.userinfoDatah = this.userPermissiontemp;
    }

    /**
     * This function is used to push the default permissions.
     * @param tempPermission -this is the temp permissions
     * @param value -value of the permissions
     * @param type -type of the permission
     */
    pushPermission(tempPermission, value, type):void {
        const subPermissions = [];
        tempPermission[value].forEach((value2) => {
            if (type == "add")
                subPermissions.push({ NAME: value2.NAME, VALUE: true });
            else
                subPermissions.push({
                    NAME: value2.NAME,
                    VALUE: value2.VALUE == "1" ? true : false,
                });
        });
        this.userPermissiontemp.push({ key: value, val: subPermissions });
    }

    /**
     * This function is used to Modified the Permissions data.
     * @param permissionsData - This is the permission data.
     * @returns -it's return the modified permissions.
     */
    getPermissionsCons(permissionsData) {
        const permissionsValue: any = {};
        permissionsData.forEach((value) => {
            const subPermissions = value.val;
            let temp = [];
            subPermissions.forEach((value2) => {
                temp.push({
                    NAME: value2.NAME,
                    VALUE: value2.VALUE == true ? "1" : "0",
                });
            });
            permissionsValue[value["key"]] = temp;
        });
        return permissionsValue;
    }

    /**
     * This function is used to save the USER data.
     */
    SaveUser():void {
        this.GetSetMixRetry = false;
        this.isspiner = true;
        this.isLoadingResults = true;
        if (this.action === "duplicate") {
            delete this.userData["USERGUID"];
            delete this.userData["STATUS"];
            delete this.userData["ALLOWMOBILEACCESS"];
            delete this.userData["SEARCHUSERPASSWORD"];
        }
        delete this.userData["STATUS"];
        delete this.userData["ALLOWMOBILEACCESS"];
        delete this.userData["SEARCHUSERPASSWORD"];

        const formAction = this.action == "edit" ? "update" : "insert";
        this.userData.PERMISSIONS = this.getPermissionsCons(this.userinfoDatah);

        this.userData.RATEPERHOUR_GROUP = {
            RATEPERHOUR_1: this.userData.RATEPERHOUR_1,
            RATEPERHOUR_2: this.userData.RATEPERHOUR_2,
            RATEPERHOUR_3: this.userData.RATEPERHOUR_3,
            RATEPERHOUR_4: this.userData.RATEPERHOUR_4,
            RATEPERHOUR_5: this.userData.RATEPERHOUR_5,
            RATEPERHOUR_6: this.userData.RATEPERHOUR_6,
        };
        delete this.userData.RATEPERHOUR_1;
        delete this.userData.RATEPERHOUR_2;
        delete this.userData.RATEPERHOUR_3;
        delete this.userData.RATEPERHOUR_4;
        delete this.userData.RATEPERHOUR_5;
        delete this.userData.RATEPERHOUR_6;

        this.userData.RATEPERDAY_GROUP = {
            RATEPERDAY_1: this.userData.RATEPERDAY_1,
            RATEPERDAY_2: this.userData.RATEPERDAY_2,
            RATEPERDAY_3: this.userData.RATEPERDAY_3,
            RATEPERDAY_4: this.userData.RATEPERDAY_4,
            RATEPERDAY_5: this.userData.RATEPERDAY_5,
            RATEPERDAY_6: this.userData.RATEPERDAY_6,
        };

        delete this.userData.RATEPERDAY_1;
        delete this.userData.RATEPERDAY_2;
        delete this.userData.RATEPERDAY_3;
        delete this.userData.RATEPERDAY_4;
        delete this.userData.RATEPERDAY_5;
        delete this.userData.RATEPERDAY_6;

        const formData = new FormData();

        this.userData.PHOTO =
            this.finalfileToUpload && this.finalfileToUpload[0]
                ? this.finalfileToUpload[0]
                : "";

        this.userData.SIGNATURE =
            this.signatureFileToUpload && this.signatureFileToUpload[0]
                ? this.signatureFileToUpload[0]
                : "";

// Add the Photo width and height
                this.userData.PHOTOWIDTH=(this.PhotoWidth && this.PhotoWidth !=undefined && this.PhotoWidth !='') ? this.PhotoWidth :'';
                this.userData.PHOTOHEIGHT=(this.PhotoHeight && this.PhotoHeight !=undefined && this.PhotoHeight !='') ? this.PhotoHeight :'';

// Add the signature width and height
                this.userData.SIGNATUREWIDTH=(this.SignatureWidth && this.SignatureWidth !=undefined && this.SignatureWidth !='') ? this.SignatureWidth :'';
                this.userData.SIGNATUREHEIGHT=(this.SignatureHeight && this.SignatureHeight !=undefined && this.SignatureHeight !='') ? this.SignatureHeight :'';


        const userPostData: any = {
            Action: formAction,
            VALIDATEONLY: true,
            DATA: this.userData,
        };
        this._mainAPiServiceService
            .getSetData(userPostData, "user")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.isspiner = false;
                        this.isLoadingResults = false;
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            userPostData
                        );
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            userPostData
                        );
                        this.isspiner = false;
                        this.isLoadingResults = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.isLoadingResults = false;
                        this.isspiner = false;
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            userPostData
                        );
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                        this.isLoadingResults = false;
                        this.isspiner = false;
                    }
                },
                (error) => {
                    this.isspiner = false;
                    this.isLoadingResults = false;
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to verify the validation data.
     * @param bodyData -This function is used to get the body data
     * @param details -validation details
     */
    async checkValidation(bodyData: any, details: any) {
        this.isspiner = false;
        this.isLoadingResults = false;
        await this.globalFunService
            .checkValidation(bodyData, details)
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((data) => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    //this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.SaveUserAfterVAlidation(details);
                    }
                }
            });
    }

    /**
     * Save user data After pass the all validations.
     * @param data -data of the user
     */
    SaveUserAfterVAlidation(data: any):void {
        data.VALIDATEONLY = false;
        this.isLoadingResults = true;
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (
            data.Action == "update" &&
            data.DATA.USERGUID == currentUser.UserGuid
        ) {
            data.Action = "update";
            this.appPermissions(data.DATA.PERMISSIONS);
        }

        // data.Action = 'update';
        delete data.FormAction;
        this._mainAPiServiceService
            .getSetData(data, "user")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        if (Object.keys(this.tempuserBudgets).length == 0) {
                            this.isLoadingResults = false;
                            this.toastr.success("User save successfully");
                            this.dialogRef.close(true);
                        } else {
                            delete this.tempuserBudgets[0]["USERBUDGETGUID"];
                            this.tempuserBudgets[0].USERGUID =
                                response.DATA.USERGUID;
                            this.saveBudgetData({
                                Action: "insert",
                                VALIDATEONLY: false,
                                Data: this.tempuserBudgets[0],
                            });
                            this.toastr.success("User save successfully");
                            this.dialogRef.close(true);
                        }
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.isLoadingResults = false;
                        this.toastr.warning(response.MESSAGE);
                        this.isspiner = false;
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                        this.isLoadingResults = false;
                        this.isspiner = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        this.isLoadingResults = false;
                        this.dialogRef.close(false);
                    }
                    this.isLoadingResults = false;
                },
                (error) => {
                    this.isLoadingResults = false;
                    this.isspiner = false;
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to save the budget data.
     * @param PostBudgetData -data of the budget
     */
    saveBudgetData(PostBudgetData: any):void {
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(PostBudgetData, "user-budget")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.isLoadingResults = false;
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

/**
 * This function is used to Checked all data as a selected
 * @param val -value of the permissions
 * @param type -type of the user permission
 */
    AllCHecked(val, type):void {
        this.userinfoDatah.forEach((element) => {
            if (element.key === type) {
                element.val.forEach((element2) => {
                    element2.VALUE = val.checked;
                });
            }
        });
    }

    /**
     * This function is used to get the app permissions
     * @param setting -list of permissions
     */
    appPermissions(setting: any):void {
        if (setting) {
            for (let i in setting) {
                this.setObj(setting[i], i);
            }
            localStorage.setItem(
                "app_permissions",
                JSON.stringify(this.Permissions)
            );
            this.behaviorService.setAppPermissionsSetting(this.Permissions);
        }
    }

    /**
     * This function is used to set the object data.
     * @param Obj -permission list array data.
     * @param type -permission type.
     */
    setObj(Obj: any, type: any) {
        let subPermissions = {};
        Obj.forEach(function (value) {
            subPermissions[value.NAME] = value.VALUE;
        });
        if (Object.keys(subPermissions).length != 0) {
            this.Permissions[this.PermissionsCons[type]] = subPermissions;
        }
    }

    /**
     * This function is used to set the rates data.
     * @param PerDayVals - values of the per day.
     * @param PerHourVals -values of the per hour.
     */
    rateDataGroupSet(PerDayVals, PerHourVals):void {
        let userDataParse: any = JSON.parse(JSON.stringify(this.userData));
        Object.keys(PerDayVals).forEach(function (key) {
            userDataParse[key] = PerDayVals[key];
        });
        Object.keys(PerHourVals).forEach(function (key) {
            userDataParse[key] = PerHourVals[key];
        });

        this.userData = userDataParse;
    }

    /**
     * This function is used to get the users list
     */
    getuserList():void {
        let userList = JSON.parse(localStorage.getItem("users_list"));
        if (userList && userList != null) {
            this.UserListData =
                this.Timersservice.removeUSerWithoutId(userList);
        } else {
            let d = {};
            this.Timersservice.GetUsers(d)
                .pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(
                    (response) => {
                        if (
                            response.CODE == 200 &&
                            response.STATUS == "success"
                        ) {
                            this.UserListData =
                                this.Timersservice.removeUSerWithoutId(
                                    response.DATA.USERS
                                );
                            localStorage.setItem(
                                "users_list",
                                JSON.stringify(response.DATA.USERS)
                            );
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
        }
    }

    MatterUserChange(event) {}

    /**
     * This function is used to get the new file changes
     * @param pFileList -file data
     * @param imageType -image type value
     */
    onFileChange(pFileList: File[], imageType: string) {
        if (
            pFileList["size"] <= 10000 ||
            (pFileList[0] && pFileList[0].size) <= 10000
        ) {
            this.selectDocUpload(pFileList, imageType);

            if (imageType == "photo") {
                this.fileToUpload = pFileList["name"]
                    ? pFileList["name"]
                    : pFileList[0]["name"];
            } else if (imageType == "signature") {
                this.signFileToUpload = pFileList["name"]
                    ? pFileList["name"]
                    : pFileList[0]["name"];
            }

            this._snackBar.open("Successfully upload!", "Close", {
                duration: 2000,
            });
        } else {
            this.toastr.error("Please Select up to 10kb Size Image");
        }
    }

   /**
    * This function is used to get the selected doc information
    * @param fileInput -file data
    * @param imageType -image tye like photo/signature
    */ 
    selectDocUpload(fileInput: any, imageType) {
        let file64bit = [];
        let filenames = [];
        let files = fileInput;
        // for (let i = 0; i < files.length; i++) {
        // filenames.push(files.name);
        var reader = new FileReader();

        reader.readAsDataURL(files[0] ? files[0] : files);
        reader.onload = function () {
            file64bit.push(reader.result);
        };
        reader.onerror = function (error) {};
        if (imageType == "photo") {
            this.finalfileToUpload = file64bit;
        } else if (imageType == "signature") {
            this.signatureFileToUpload = file64bit;
        }
    }

/**
 * This function is used to remove the image
 * @param imageType 
 */    
    remove(imageType):void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to remove this Item?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (imageType == "photo") {
                    this.fileToUpload = [];
                    this.finalfileToUpload = "";
                } else if (imageType == "signature") {
                    this.signFileToUpload = [];
                    this.signatureFileToUpload = "";
                }
            }
        });
    }

    /**
     * This function is used to detect the changes.
     * @param value -updated changes
     */
    async onValChange(value) {
        this.signatureType = value;
        let withoutImage = await this.replaceImgText(this.ckeditorValue);
        this.ckeditorValue = withoutImage;

        this.userData.BIOGRAPHY = this.ckeditorValue;
        this.HtmlCss = this.sanitizer.bypassSecurityTrustHtml(
            this.ckeditorValue
        );
    }

    /**
     * This function is used to getting the signature preview.
     */
    signaturePreview() {
        this.HtmlCss = this.sanitizer.bypassSecurityTrustHtml(
            this.ckeditorValue
        );
    }

    /**
     * This function is used to detect the new changes
     * @param event -event data.
     */
    async changesDetact(event):Promise<void> {
        let withoutImage = await this.replaceImgText(this.ckeditorValue.trim());
        this.userData.BIOGRAPHY = withoutImage;
        this.ckeditorValue = withoutImage;
    }

    /**
     * This function is used to replace the Image text
     * @param html -html data
     * @returns - it's returns the rates.
     */
    replaceImgText(html) {
        var ret = html.replace(
            /<img[^>]*src="data:image\/(bmp|dds|gif|jpg|jpeg|png|psd|pspimage|tga|thm|tif|tiff|yuv|ai|eps|ps|svg);base64,.*?"[^>]*>/gi,
            function (img) {
                return "";
            }
        );
        return ret;
    }

    /**
     * This function is used to Delete the Rates
     */
    DeleteRate():void {
        let item = this.rowdata;
        let DeleteUrl = "user-rate";
        let postData =
            item && item.USERRATEGUID
                ? { USERRATEGUID: item.USERRATEGUID }
                : { USERRATEGUID: this.currentoverride.USERRATEGUID };
        let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: true,
            width: "100%",
        });
        confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._mainAPiServiceService
                    .getSetData(
                        {
                            ACTION: "delete",
                            VALIDATEONLY: false,
                            DATA: postData,
                        },
                        DeleteUrl
                    )
                    .pipe(takeUntil(this._unsubscribeAll$))
                    .subscribe(
                        (response) => {
                            if (
                                response.CODE == 200 &&
                                (response.STATUS == "OK" ||
                                    response.STATUS == "success")
                            ) {
                                this.toastr.success(
                                    "Rate Deleted successfully"
                                );
                                this.getRatesOverride();
                            }
                        },
                        (error: any) => {
                            console.log(error);
                        }
                    );
            }
            confirmDialogRef = null;
        });
    }

    /**
     * This function is used to get the new rich text editor data.
     * @param event -updated rich text editor data.
     */
    changesrichtexteditor(event:any):void {
        this.userData.BIOGRAPHY = event.value;
        this.ckeditorValue = event.value;
    }


    /**
     * This function is used to get the Image size.
     * @param base64String -base 64 path of the image
     * @returns -its return the size of the image.
     */
    getImageSize(base64String: string): { sizeInBytes: number, sizeInKb: number, sizeInMb: number } {
        // Extract Base64 portion of the string
        const base64Data = base64String.split(',')[1];
      
        // Convert Base64 string to Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Specify image type as needed
      
        // Get size of Blob (image) in bytes
        const sizeInBytes = blob.size;
      
        // Convert bytes to kilobytes
        const sizeInKb = sizeInBytes / 1024;
      
        // Convert bytes to megabytes
        const sizeInMb = sizeInKb / 1024;
      
        // Return size in bytes, kilobytes, and megabytes
        return { sizeInBytes, sizeInKb, sizeInMb };
      }


      /**
       * This function is used to get the user New Signature from the child component.
       * @param data -signature data.
       */
      getSignatureData(data):void {
        if(data){
            this.signatureFileToUpload=data;
        }
    }

    resizeData(data){
    }

    onSelectionChange(args) {
  if (args && args.currentSelectionSettings) {
    const selectedArea = args.currentSelectionSettings;
    const croppedWidth = selectedArea?.width    
    const croppedHeight = selectedArea?.height
    this.PhotoWidth=croppedWidth;
    this.PhotoHeight=croppedHeight
  }
}

onSelectionChangeSignature(args){
    if (args && args.currentSelectionSettings) {
        const selectedArea = args.currentSelectionSettings;
        const croppedWidth = selectedArea?.width    
        const croppedHeight = selectedArea?.height
        this.SignatureWidth=croppedWidth;
        this.SignatureHeight=croppedHeight
      } 
}


}
