<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>Select Matter
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="one select_matter_main_div">
            <div class="center p-24 list_tbl_main more_tbl_clmn">
                <div class="matter-serach-div sticky_search_div">
                    <!-- select bar  -->
                    <form [formGroup]="matterFilterForm">
                        <mat-form-field class="example" appearance="outline">
                            <mat-label>Active Matters</mat-label>
                            <mat-select formControlName="MatterFilter" [disabled]="disableDrop" (selectionChange)="MatterChange($event.value)">
                                <mat-option value="active">Active Matters</mat-option>
                                <mat-option value="inactive">Inactive Matters</mat-option>
                                <mat-option value="recent inactive">Recently Inactive</mat-option>
                                <mat-option value="near estimate">Near Estimate</mat-option>
                                <mat-option value="">All Matters</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="example freeEarner" appearance="outline">
                            <mat-label>Fee Earner/Owner</mat-label>
                            <mat-select (selectionChange)="MatterUserChange($event.value)" formControlName="UserFilter">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="">None</mat-option>
                                <mat-option *ngFor="let MatterDrop of MatterDropData" value="{{MatterDrop.USERID}}">
                                    {{MatterDrop.USERID}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="search" appearance="outline">
                            <mat-label>Search</mat-label>
                            <input matInput placeholder="Search" (keydown)="onSearch($event)" formControlName="searchFilter">
                            <mat-icon matSuffix (click)="onSearch('Enter')">search</mat-icon>
                        </mat-form-field>
                    </form>
                </div>
            </div>

            <div class="example-containerdata">
                <table mat-table #table class="mat-table-custome" [dataSource]="getDataForTable" [@animateStagger]="{value:'50'}" (matSortChange)="sortData($event)" matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear matSort>
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="paddingset">
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" class="paddingset">
                            <mat-checkbox  (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Favourite">
                        <th mat-header-cell *matHeaderCellDef >
                            <mat-icon class="starr nav-link-title table_icon"  [ngClass]="(_data && _data?.multi == 'Multi') ? 'margin0' : 'margin18'"
                        >star</mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let row" (click)="getFavouritelist(row)">
                            <p>
                        <mat-icon class="starr nav-link-title btn" [ngClass]="(_data && _data?.multi == 'Multi') ? 'margin0' : 'margin18'"
                        >{{(row.FAVOURITE == 1) ? 'star':'star_border'}}
                        </mat-icon>
                            </p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="SHORTNAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matter Number</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate matter-truncate">
                                {{row.SHORTNAME }}
                            </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="MATTER">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matter</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="company text-truncate matter-truncate">
                                {{row.MATTER }}
                            </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="CONTACTNAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="company text-truncate matter-truncate">
                                {{row.CONTACTNAME }}
                            </p>
                        </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row [style.background]="highlightedRows == row.MATTERGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumns;" [ngClass]="highlightedRows == row.MATTERGUID ? 'row-text-colore' : ''" class="contact" (click)="selectMatterId(row);highlightedRows = row.MATTERGUID"
                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" [pageSize]="pageSize?.matter_select" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="left_popup_btn">
            <button [ngClass]="appPermissions[1]?.Create==0 ? 'disabled-click-cursor' : ''" mat-raised-button color="accent" (click)="AddNewmatterpopup()">New Matter</button>
            <button [ngClass]="(appPermissions[1]?.Edit==0|| (!currentMatterData||this.selection.selected.length  > 1)) ? 'disabled-click-cursor' : ''" mat-raised-button color="accent" (click)="EditNewmatterpopup()">Edit Matter</button>
        </div>
        <div class="right_popup_btn">
            <button [ngClass]="(appPermissions[1]?.Access==0|| (!currentMatterData && this.selection.selected.length <= 0)) ? 'disabled-click-cursor' : ''" mat-raised-button [mat-dialog-close]="(_data && _data.multi=='Multi') ? this.selection.selected : currentMatterData" color="accent">Select</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
        </div>
    </mat-dialog-actions>
</div>
