import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { round } from 'lodash';
import { AddRateComponent } from './add-rate/add-rate.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';
@Component({
    selector: 'app-rates',
    templateUrl: './rates.component.html',
    styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit, OnDestroy {
    @Input() matterdetailForm: FormGroup;
    @Input() errorWarningData: any;
    @Input() action: any;
    @Input() isEditRate: boolean;
    isDisabled: boolean = true;
    @Output() rateDetail: EventEmitter<any> = new EventEmitter<any>();
    @Input() toolTipList: any;
    @Input() testBool;
    toolTipPostion = "above";

    FIXEDRATEEXGST: any;
    FIXEDRATEINCGST: any;
    RATEPERHOUR: any;
    RATEPERDAY: string;
    matterGuid = "";
    UserRateData: any[] = [];
    ActivityRateData: any = [];
    SundryRateData: any = [];
    userType: any;
    windowNameId: any;
    PRICEVAL: any;
    PRICEVALGST: any;
    MAXPRICEVALGST: any;
    MAXPRICEVAL: any;
    AllowEstimateRange: boolean;
    isFirstTime: boolean = true;
    DefaultMatterData: Subscription;
    rateList: any[] = [];
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private behaviorService: BehaviorService,
        public dialog: MatDialog,
        private toastr: ToastrService,
        private currencyPipe: CurrencyPipe,
        public datepipe: DatePipe,
        public tooltipService: TooltipService,
    ) {
        this.toolTipList = this.toolTipList;
    }

    // matterdetailForm.controls.BILLINGMETHOD.value

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Rates' } }, 'lookup').subscribe(responses => {
            if (responses.CODE === 200 && responses.STATUS === 'success') {
                localStorage.setItem('Rates_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                this.rateList = responses.DATA.LOOKUPS;
            }
        });
        this.AllowEstimateRange = JSON.parse(localStorage.getItem('currentUser')).ALLOWESTIMATERANGE;
        let UserData = JSON.parse(localStorage.getItem('currentUser')).PRODUCTTYPE;
        this.userType = (UserData == 'Barrister') ? 0 : 1;
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.matterGuid = result.MATTERGUID;
        //   }
        // });

        this.behaviorService.MatterData$.subscribe(result => {
            const materIDofTab = window.name.split("_");
            this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            this.matterGuid = (JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'))).MATTERGUID;
            if (this.matterGuid) {
            } else {
                if (result) {
                    this.matterGuid = result;
                    localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterGuid))
                }
            }
        });

        this.loadRateDate('user-rate', 1, '');
        this.loadRateDate('activity-rate', 2, '');
        this.loadRateDate('activity-rate', 3, '');
        setTimeout(() => {
            this.radioChange();
        }, 2000);

    }

    /**
     * This function is used to Add the new rates
     * @param actions -to set the action like add/edit/delete
     * @param type - type of the rate
     * @param id -rate id
     */
    AddNewRate(actions, type, id) {
        const dialogRef = this.dialog.open(AddRateComponent, {
            disableClose: true, data: { formAction: actions, USERRATEGUID: id, isEditRate: this.isEditRate, rateType: "Matter", linkGuid: this.matterGuid, type: type }
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                if (this.isEditRate) {
                    if (result.type == 2) {
                        this.ActivityRateData.push(result);
                    } else if (result.type == 3) {
                        this.SundryRateData.push(result);
                    } else {
                        this.UserRateData.map((res) => {
                            if (res.USERID == result.USERID) {
                                this.toastr.error('This user already has a rate override.');
                            }
                        })
                        const value = this.UserRateData.filter((item) => item.USERID == result.USERID)
                        if (value.length == 0) {
                            this.UserRateData.push(result);
                        }
                    }
                    this.rateDetail.emit(this.UserRateData);
                    // this.rateDetail.emit(result);
                } else {
                    if (result != 'user-rate') {
                        this.loadRateDate(result, 2, '');
                        this.loadRateDate(result, 3, '');
                    } else {
                        this.loadRateDate(result, 1, '');
                    }
                }
            }
        });
    }

    /**
     * This function is used to edit the rates
     * @param actions -action which we need to perform
     * @param type -type of the rates
     * @param id -rate id
     * @param item -value of the rate
     * @param index -index of the rate
     */
    AddEditRate(actions, type, id, item, index):void {
        const dialogRef = this.dialog.open(AddRateComponent, {
            disableClose: true, data: { formAction: actions, USERRATEGUID: id ? id : '', isEditRate: this.isEditRate, rateType: "Matter", linkGuid: this.matterGuid, type: type, item: item }
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'MatterPopupComponent');
            if (result) {
                if (this.isEditRate) {
                    if (result.type == 2) {
                        this.ActivityRateData.push(result);
                    } else if (result.type == 3) {
                        this.SundryRateData.push(result);
                    } else {
                        let indexToUpdate = this.UserRateData.findIndex((item, i) => index === i);
                        this.UserRateData[indexToUpdate] = result;
                        this.UserRateData = Object.assign([], this.UserRateData);
                    }
                    this.rateDetail.emit(this.UserRateData);
                }
            }
        });
    }

    /**
     * This function is used to delete the rate.
     * @param item -item of the rate.
     * @param id -id of the rate.
     * @param index -index of the rate.
     * @returns 
     */
    DeleteRate(item, id, index) {

        if (this.action == "duplicate") {
            if (id == 1) {
                this.UserRateData.splice(index, 1);
            } else if (id == 2) {
                this.ActivityRateData.splice(index, 1);
            } else if (id == 3) {
                this.SundryRateData.splice(index, 1);
            }
            return;
        }

        if (this.action == "new") {
            if (id == 1) {
                this.UserRateData.splice(index, 1);
            }
            return;
        }

        let DeleteUrl = id == 1 ? "user-rate" : 'activity-rate';
        let postData = id == 1 ? { USERRATEGUID: item.USERRATEGUID } : { ACTIVITYRATEGUID: item.ACTIVITYRATEGUID };
        let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', });
        confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._mainAPiServiceService.getSetData({ ACTION: 'delete', VALIDATEONLY: false, DATA: postData }, DeleteUrl).subscribe(response => {
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                        this.toastr.success('Rate Deleted successfully');
                        if (id == 1) {
                            this.loadRateDate('user-rate', id, '');
                        } else {
                            this.loadRateDate('activity-rate', id, '');
                        }
                    }
                }, (error: any) => {
                    console.log(error);
                });
            }
            confirmDialogRef = null;
        });
    }

    /**
     * This function is used to load the default Rate data.
     * @param rateType -type of the rate
     * @param id -id of the rate.
     * @param actionData -action of the rate
     */
    loadRateDate(rateType, id, actionData) {

        if (this.action == "edit" || (this.action == "duplicate" && actionData == true)) {
            let filterData: any = { RATETYPE: 'MATTER', LINKGUID: this.matterGuid };
            if (id == 2) {
                filterData.ACTIVITYTYPE = "Activity"
            } else if (id == 3) {
                filterData.ACTIVITYTYPE = "Sundry";
            }

            let payload: any = {
                "Action": "GetData",
            };

            payload.Filters = filterData;

            this._mainAPiServiceService.getSetData(payload, rateType).subscribe(responses => {
                if (responses.CODE == 200 && responses.STATUS == 'success') {

                    // emit values first time on load rates component
                    if (this.isFirstTime && this.action == "duplicate") {

                        if (id == 1) {
                            // for fee Earner
                            const feeEarnerRates = responses.DATA.RECORDS;
                            feeEarnerRates.forEach((data) => {
                                const feeEarnerRateObj = {
                                    "RATETYPE": "Matter",
                                    "LINKGUID": data["MATTERGUID"],
                                    "GSTTYPE": data["GSTTYPE"],
                                    "USERID": data["USERID"],
                                    "RATEPERHOUR_1": data["RATEPERHOUR_GROUP"]["RATEPERHOUR_1"],
                                    "RATEPERHOUR_2": data["RATEPERHOUR_GROUP"]["RATEPERHOUR_2"],
                                    "RATEPERHOUR_3": data["RATEPERHOUR_GROUP"]["RATEPERHOUR_3"],
                                    "RATEPERHOUR_4": data["RATEPERHOUR_GROUP"]["RATEPERHOUR_4"],
                                    "RATEPERHOUR_5": data["RATEPERHOUR_GROUP"]["RATEPERHOUR_5"],
                                    "RATEPERHOUR_6": data["RATEPERHOUR_GROUP"]["RATEPERHOUR_6"],
                                    "RATEPERDAY_1": data["RATEPERDAY_GROUP"]["RATEPERDAY_1"],
                                    "RATEPERDAY_2": data["RATEPERDAY_GROUP"]["RATEPERDAY_2"],
                                    "RATEPERDAY_3": data["RATEPERDAY_GROUP"]["RATEPERDAY_3"],
                                    "RATEPERDAY_4": data["RATEPERDAY_GROUP"]["RATEPERDAY_4"],
                                    "RATEPERDAY_5": data["RATEPERDAY_GROUP"]["RATEPERDAY_5"],
                                    "RATEPERDAY_6": data["RATEPERDAY_GROUP"]["RATEPERDAY_6"],
                                    "APIUrl": "user-rate",
                                    "type": "1"
                                }
                                this.rateDetail.emit(feeEarnerRateObj);
                            })
                            // ends here ~ for fee Earner

                        } else if (id == 2) {
                            // for activity rate
                            const activityRates = responses.DATA.RECORDS;
                            activityRates.forEach((data) => {
                                const activityRate = {
                                    "RATETYPE": "Matter",
                                    "LINKGUID": data["MATTERGUID"],
                                    "GSTTYPE": data["GSTTYPE"],
                                    "ACTIVITYGUID": data["ACTIVITYGUID"],
                                    "RATEPERUNIT": data["RATEPERUNIT"],
                                    "APIUrl": "activity-rate",
                                    "type": "2"
                                }
                                this.rateDetail.emit(activityRate);
                            })
                            // ends here ~ for activity rate
                        } else if (id == 3) {
                            // for sundry rate
                            const activityRates = responses.DATA.RECORDS;
                            activityRates.forEach((data) => {
                                const activityRate = {
                                    "RATETYPE": "Matter",
                                    "LINKGUID": data["MATTERGUID"],
                                    "GSTTYPE": data["GSTTYPE"],
                                    "ACTIVITYGUID": data["ACTIVITYGUID"],
                                    "RATEPERUNIT": data["RATEPERUNIT"],
                                    "APIUrl": "activity-rate",
                                    "type": "3"
                                }
                                this.rateDetail.emit(activityRate);
                            })
                            // ends here ~ for sundry rate
                        }

                    }
                    // ends here ~ emit values first time on load rates component

                    if (rateType == 'user-rate') {
                        this.UserRateData = responses.DATA.RECORDS;
                        this.UserRateData.forEach(element => {
                            element.RATEPERDAY_1 = element.RATEPERDAY_GROUP.RATEPERDAY_1;
                            element.RATEPERHOUR_1 = element.RATEPERHOUR_GROUP.RATEPERHOUR_1;
                        });
                    } else if (rateType == 'activity-rate' && id == 2) {
                        this.ActivityRateData = responses.DATA.RECORDS;
                    } else if (rateType == 'activity-rate' && id == 3) {
                        this.SundryRateData = responses.DATA.RECORDS;
                    }
                }
            });
        }
    }

    /**
     * This function is used to choose the any one GST type.
     * @param event -value of the selected GST
     */
    radioChange(event?) {
        if (this.f.GSTTYPE.value == 'GST Free' || this.f.GSTTYPE.value == 'Export') {
            this.isDisabled = false;
            if ((this.f.GSTTYPE.value == 'GST Free' || this.f.GSTTYPE.value == 'Export') && this.action == 'new') {
                this.behaviorService.ONCHARGEDISBURSEMENTGST$.subscribe(data => {
                    this.matterdetailForm.controls['ONCHARGEDISBURSEMENTGST'].setValue(data);
                })
            }
        }
        else {
            this.isDisabled = true;
            this.matterdetailForm.controls['ONCHARGEDISBURSEMENTGST'].setValue(0);
        }
    }
    get f() {
        return this.matterdetailForm.controls;
    }

    /**
     * This function is used to calculate the fixed INC GST
     */
    calcPE() {
        this.FIXEDRATEINCGST = round(this.f.FIXEDRATEEXGST.value * 1.1);
    }

    /**
     * This function is used to calculate the Fixed Rate Ex gst
     */
    calcPI() {
        this.FIXEDRATEEXGST = round(this.f.FIXEDRATEINCGST.value / 1.1);
    }

    /***
     * This function is used to getting the rate per hour
     */
    RatePerHourVal(num) {
        let numToString = num.toString()
        let string = numToString.replace(/[^a-zA-Z0-9]/g, '');
        let converyt = Number(string).toLocaleString('en-GB');
        return converyt;
    }

    /**
     * This function is used to get the coppy text
     * @param event -value of the text
     */
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
    Costagrpicker(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['FEEAGREEMENTRECEIVEDDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    CostsAgreementDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['FEEAGREEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    calcPE_1(type) {
        if (type == 'min') {
            this.PRICEVALGST = this.f.ESTIMATEFROMTOTALEXGST.value * 1.1;
        } else {
            this.MAXPRICEVALGST = this.f.MAXESTIMATEFROMTOTALEXGST.value * 1.1;
        }
    }
    calcPI_1(type) {
        if (type == 'min') {
            this.PRICEVAL = this.f.ESTIMATEFROMTOTALINCGST.value / 1.1;
        } else {
            this.MAXPRICEVAL = this.f.MAXESTIMATEFROMTOTALINCGST.value / 1.1;
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

}
