import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
    Component,
    OnInit,
    Input,
    ElementRef,
    ViewChild,
    HostListener,
    OnDestroy,
} from "@angular/core";
import {
    FormGroup
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { ContactSelectDialogComponent } from "../../contact-select-dialog/contact-select-dialog.component";
import { ContactDialogComponent } from "../contact-dialog.component";
import { ToastrService } from "ngx-toastr";
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs";
import { TooltipService } from "@_services/tooltip.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";

interface ContactForm {
    Id: number,
    Name: string
}

@Component({
    selector: "app-person",
    templateUrl: "./person.component.html",
    styleUrls: ["./person.component.scss"],
})
export class PersonComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @Input() allToolTip: any = [];
    @Input() contactForm: FormGroup;
    @Input() editDataCompny: any;
    @Input() errorWarningData: any;
    @Input() msgFromParent1: any[];
    @Input() action: any;
    isdisable: boolean = true;
    isContact: boolean = false;
    common: { Id: number; Name: string }[];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchData: any;
    matterlist: any[];
    currentValue: any;
    searchContact: Subscription;
    exportdatavalue: any;
    result: any;
    selectedData: any = [];
    mattername: any = [];
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    @ViewChild("MatterInput1", { static: false })
    MatterInput: ElementRef<HTMLInputElement>;
    appPermissions = JSON.parse(localStorage.getItem("app_permissions"));
    CallHostlistner: boolean = false;
    DestryedContactdata: Subscription;
    filteredOptions$: Observable<ContactForm[]>;
    private searchTerms = new Subject<string>();
    subscription: Subscription;
    constructor(
        public MatDialog: MatDialog,
        public toolbarServiceService: ToolbarServiceService,
        private _mainAPiServiceService: MainAPiServiceService,
        private behaviorService: BehaviorService,
        private toastr: ToastrService,
        public tooltipService: TooltipService
    ) {
        this.getContactDataUsingBehaviour();
    }
   
     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        setTimeout(() => {
            if (
                this.contactForm.value.COMPANYCONTACTGUIDTEXT != "" &&
                this.contactForm.value.COMPANYCONTACTGUIDTEXT != undefined
            ) {
                this.isdisable = false;
                this.mattername = [
                    this.contactForm.value.COMPANYCONTACTGUIDTEXT,
                ];
            } else {
                this.mattername = [];
            }
        }, 3000);
        this.contactForm.controls["USEPARENTADDRESS"].setValue(false);
        this.contactForm.get("OTHERGIVENNAMES").disable();
        this.contactForm.get("OTHERFAMILYNAME").disable();
        this.contactForm.get("REASONFORCHANGE").disable();
        this.common = [
            { Id: 1, Name: "Mr" },
            { Id: 2, Name: "Mrs" },
            { Id: 3, Name: "Miss" },
            { Id: 4, Name: "Ms" },
            { Id: 5, Name: "Dr" },
        ];
        this.isContact = this.editDataCompny;
        if (this.contactForm.value.COMPANYCONTACTGUIDTEXT != "") {
            this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue("");
            this.isdisable = false;
        }
        this.filteredOptions$ = this.contactForm.controls.NAMETITLE.valueChanges.pipe(
            debounceTime(500),
            startWith(''),
            map(value => this.filterOptions(value))
        );

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA;
                this.searchData = response.DATA.RECORDS;
                if (response.DATA.RECORDS.length == 0) {
                    this.matterlist.push({ CONTACTNAME: this.currentValue });
                } else {
                    this.matterlist = this.searchData;
                }
            } else if (response.CODE == 406 && response.STATUS == "error") {
                this.toastr.error(response.MESSAGE);
            }
            // this.searchResult = response.RESPONSE.results;
        });
    }

    private filterOptions(value: string): ContactForm[] {
        const filterValue = value.toLowerCase();
        return this.common.filter(option => option.Name.toLowerCase().includes(filterValue));
    }

    /**
     * This function is used to display the data value.
     */
    displayFn(value: string): string {
        return value;
    }

    /**
     * This function is used to get the contact details information.
     */
    getContactDataUsingBehaviour() {
        this.DestryedContactdata =
            this.behaviorService.newContactData$.subscribe((result) => {
                if (result) {
                    this.isdisable = false;
                    this.confirmDialogRef = this.MatDialog.open(
                        FuseConfirmDialogComponent,
                        {
                            disableClose: true,
                            width: "100%",
                            data: { usecompanyaddress: true },
                        }
                    );
                    this.confirmDialogRef.componentInstance.confirmMessage =
                        "Do you want to use the company's address for this contact?";
                    this.confirmDialogRef.componentInstance.confirmTitle =
                        "Address";
                    this.confirmDialogRef.afterClosed().subscribe((result) => {
                        if (result == true) {
                            this.contactForm.controls[
                                "USEPARENTADDRESS"
                            ].setValue(true);
                        }
                    });
                }
                localStorage.setItem("istrackid", "ContactDialogComponent");
                if (result) {
                    this.contactForm.controls["COMPANYCONTACTGUID"].setValue(
                        result.CONTACTGUID
                    );
                    this.contactForm.controls[
                        "COMPANYCONTACTGUIDTEXT"
                    ].setValue(result.CONTACTNAME);
                    this.contactForm.controls["USEPARENTADDRESS"].setValue(
                        false
                    );
                    this.mattername = [result.CONTACTNAME];
                    // this.isContact = false;
                }
            });
    }

    /**
     * Destroy the observable
     */
    ngOnDestroy() {
        this.DestryedContactdata?.unsubscribe();
        this.searchContact?.unsubscribe();
        this.behaviorService.newContactData$.next(null);
        this.subscription?.unsubscribe();
    }

    get f() {
        return this.contactForm.controls;
    }

    /**
     * THis function is used to remove ContactMatter
     */
    removeContactMatter() {
        this.isContact = true;
        this.contactForm.controls["COMPANYCONTACTGUID"].setValue("");
        this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue("");
        this.contactForm.controls["USEPARENTADDRESS"].setValue(false);
    }

    /**
     * This function is used to Select the Contact Matter
     */
    SelectContactMatter() {
        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {
                type: "fromcontact",
                defult_type: this.f.CONTACTTYPE.value,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.isdisable = false;
                this.confirmDialogRef = this.MatDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: { usecompanyaddress: true },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    "Do you want to use the company's address for this contact?";
                this.confirmDialogRef.componentInstance.confirmTitle =
                    "Address";
                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    if (result == true) {
                        this.contactForm.controls["USEPARENTADDRESS"].setValue(
                            true
                        );
                    }
                });
            }
            localStorage.setItem("istrackid", "ContactDialogComponent");
            if (result) {
                this.contactForm.controls["COMPANYCONTACTGUID"].setValue(
                    result.CONTACTGUID
                );
                this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(
                    result.CONTACTNAME
                );
                this.contactForm.controls["USEPARENTADDRESS"].setValue(false);
                this.mattername = [result.CONTACTNAME];
                // this.isContact = false;
            }
        });
    }

    /**
     * This function ia used to triger Some moment 
     * @param f -data of the formcontrols
     */
    triggerSomeEvent(f) {
        if (f.value.KNOWNBYOTHERNAME == true) {
            this.contactForm.get("OTHERGIVENNAMES").enable();
            this.contactForm.get("OTHERFAMILYNAME").enable();
            this.contactForm.get("REASONFORCHANGE").enable();
        } else {
            this.contactForm.controls["OTHERGIVENNAMES"].setValue("");
            this.contactForm.controls["OTHERFAMILYNAME"].setValue("");
            this.contactForm.controls["REASONFORCHANGE"].setValue("");

            this.contactForm.get("OTHERGIVENNAMES").disable();
            this.contactForm.get("OTHERFAMILYNAME").disable();
            this.contactForm.get("REASONFORCHANGE").disable();
        }
    }

    /**
     * This function is used to get the Contact New ID
     * @returns - New Id
     */
    getContactNewId() {
        let contecttId = "";
        let postData = {
            Action: "GetContactId",
            VALIDATEONLY: true,
            Data: {},
        };

        return new Promise((response, reject) => {
            this._mainAPiServiceService
                .getSetData(postData, "contact")
                .subscribe((res) => {
                    if (res.CODE == 200) {
                        contecttId = res.DATA.CONTACTID;
                        response(contecttId);
                    } else {
                        response(null);
                    }
                });
        });
    }

    /**
     * This unction is used to selectNewContect
     */
    async selectNewContect() {
        this.MatterInput.nativeElement.value = "";
        this.mattername = [];
        this.matterlist = [];
        const dialogRef = await this.MatDialog.open(ContactDialogComponent, {
            disableClose: true,
            panelClass: "contact-dialog",
            data: {
                action: "new",
                isReturn: true,
                contect_details: "",
                flagNewConactMatter: true,
                selectedList: "Company",
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                if (result.CONTACTGUID) {
                    let contectIdIs = await this.getContactNewId();
                    this.behaviorService.MatterpopupContactData$.next(
                        contectIdIs
                    );
                    this.contactForm.controls["COMPANYCONTACTGUID"].setValue(
                        result.CONTACTGUID
                    );
                    this.contactForm.controls[
                        "COMPANYCONTACTGUIDTEXT"
                    ].setValue(result.CONTACTNAME);
                    this.mattername = [result.CONTACTNAME];

                    this.confirmDialogRef = this.MatDialog.open(
                        FuseConfirmDialogComponent,
                        {
                            disableClose: true,
                            width: "100%",
                            data: { usecompanyaddress: true },
                        }
                    );

                    this.confirmDialogRef.componentInstance.confirmMessage =
                        "Do you want to use the company's address for this contact?";
                    this.confirmDialogRef.componentInstance.confirmTitle =
                        "Address";
                    this.confirmDialogRef.afterClosed().subscribe((result) => {
                        if (result == true) {
                            this.contactForm.controls[
                                "USEPARENTADDRESS"
                            ].setValue(true);
                        }
                    });

                    localStorage.setItem("istrackid", "ContactDialogComponent");
                    if (result) {
                        this.contactForm.controls[
                            "COMPANYCONTACTGUID"
                        ].setValue(result.CONTACTGUID);
                        this.contactForm.controls[
                            "COMPANYCONTACTGUIDTEXT"
                        ].setValue(result.CONTACTNAME);
                        this.contactForm.controls["USEPARENTADDRESS"].setValue(
                            false
                        );
                        // this.isContact = false;
                    }
                } else {
                    this.behaviorService.MatterpopupContactData$.next(null);
                }
            }
        });
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * This function is used to select the matter search 
     * @param event 
     */
    selectMatterFormSearch(event) {
        this.matterlist = [];
        this.currentValue = '';
        if (event.length == 0) {
            this.matterlist = [];
        }
        // let searchobj = { FastSearch:'True', Search: event }
        let payload = {
            ACTION: "GetData",
            FILTERS: {
                FASTSEARCH: 1,
                SEARCH: event,
            },
        };
        this.currentValue = event;
        // if (this.searchContact) {
        //     this.searchContact.unsubscribe();
        // }
        return this._mainAPiServiceService.getSetData(payload, "contact")
    }

    /**
     * This function is used to show the data.
     * @param event 
     */
    datashow(event) {
        this.matterlist = [];
        this.CallHostlistner = true;
        if (
            this.exportdatavalue &&
            this.exportdatavalue.RECORDS &&
            this.exportdatavalue.RECORDS.length != 0
        ) {
            this.result = this.exportdatavalue.RECORDS[0];
            this.matterlist = [this.result.CONTACTNAME];
            this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(null);
            if (event.input) {
                event.input.value = "";
            } else {
                this.MatterInput.nativeElement.value = "";
            }
            if (this.result) {
                this.isdisable = false;
                this.confirmDialogRef = this.MatDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: { usecompanyaddress: true },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    "Do you want to use the company's address for this contact?";
                this.confirmDialogRef.componentInstance.confirmTitle =
                    "Address";
                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    if (result == true) {
                        this.contactForm.controls["USEPARENTADDRESS"].setValue(
                            true
                        );
                    }
                });
            }
            localStorage.setItem("istrackid", "ContactDialogComponent");
            if (this.result) {
                this.contactForm.controls["COMPANYCONTACTGUID"].setValue(
                    this.result.CONTACTGUID
                );
                this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(
                    this.result.CONTACTNAME
                );
                this.contactForm.controls["USEPARENTADDRESS"].setValue(false);
                this.mattername = [this.result.CONTACTNAME];
                // this.isContact = false;
            }
        } else {
            this.toolbarServiceService.ContactsDialog(
                "new",
                this.MatterInput.nativeElement.value
            );
            this.mattername = [];
            this.matterlist = [];
            this.MatterInput.nativeElement.value = "";
        }
        this.CallHostlistner = false;
    }

    @HostListener("document:click", ["$event"])
    /**
     * This function is used to click on the document
     */
    documentClick(event: MouseEvent) {
        let result = this.MatterInput.nativeElement.value;

        if (
            result &&
            result != null &&
            result != undefined &&
            this.CallHostlistner == false
        ) {
            this.datashow(this.MatterInput.nativeElement);
            this.MatterInput.nativeElement.value = "";
        }
    }

    /**
     * This function is used to remove the selected matter
     * @param fruit 
     */
    remove(fruit: any) {
        this.behaviorService.newContactData$.next(null);
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.contactForm.controls["COMPANYCONTACTGUID"].setValue("");
            //this.Deliverable = [];
        }
        let sortname = fruit.split("-")[0];
        this.rm(sortname);
    }

    /**
     * This function is used to rm the function
     * @param sortname -this is getting the 
     */
    rm(sortname) {
        this.behaviorService.newContactData$.next(null);
        this.MatterInput.nativeElement.value = "";
        this.selectedData.forEach((element: any, index: any) => {
            if (
                element.CONTACTNAME.toString() ==
                sortname.replace(/^\s+|\s+$/gm, "")
            ) {
                this.selectedData.splice(index, 1);
            }
        });
    }

    /**
     * This function is used to add the matters in cheap list
     * @param event 
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        this.matterlist.forEach((element: any) => {
            if (element.CONTACTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (
                        this.mattername.indexOf(
                            value.replace(/^\s+|\s+$/gm, "")
                        ) == -1
                    ) {
                        this.mattername = [value];
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }
        this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(null);
    }

    /**
     * This function is used to get the selected value
     * @param event -selected value
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (
            this.mattername.indexOf(
                event.option.viewValue.replace(/^\s+|\s+$/gm, "")
            ) == -1
        ) {
            this.mattername = [event.option.viewValue];
            this.MatterInput.nativeElement.value = "";
            this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(null);
        }
    }

    /**
     * This function is used to select the matter guid
     * @param result -selected matterguid
     */
    selectedmatterguid(result: any) {
        this.MatterInput.nativeElement.value = "";
        if (Object.keys(result).length == 1) {
            this.toolbarServiceService.ContactsDialog(
                "new",
                result.CONTACTNAME
            );
            this.mattername = [];
            this.matterlist = [];
        } else {
            if (result) {
                this.isdisable = false;
                this.confirmDialogRef = this.MatDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: { usecompanyaddress: true },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    "Do you want to use the company's address for this contact?";
                this.confirmDialogRef.componentInstance.confirmTitle =
                    "Address";
                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    if (result == true) {
                        this.contactForm.controls["USEPARENTADDRESS"].setValue(
                            true
                        );
                    }
                });
            }
            localStorage.setItem("istrackid", "ContactDialogComponent");
            if (result) {
                this.contactForm.controls["COMPANYCONTACTGUID"].setValue(
                    result.CONTACTGUID
                );
                this.contactForm.controls["COMPANYCONTACTGUIDTEXT"].setValue(
                    result.CONTACTNAME
                );
                this.contactForm.controls["USEPARENTADDRESS"].setValue(false);
                this.mattername = [result.CONTACTNAME];
                // this.isContact = false;
            }
        }
    }
}
