import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  ProductData: any = [];
  isLoadingResults: boolean = false;
  sub1: Subscription;
  sub2: Subscription;
  constructor(private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService:BehaviorService) {
      localStorage.setItem('istrackid', 'AccMangProductsComponent');
      this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
        if (localStorage.getItem('istrackid') == 'AccMangProductsComponent' && result['click'] && result['data']['HOGetPurchases']) {
          this.ngOnInit();
        }
      });
     }

      /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.sub2 = this._mainAPiServiceService.getSetData({}, 'HOGetPurchases').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.ProductData = response.DATA.PURCHASES;
        this.behaviorService.loadingAccountMNG('product');
      } else {
        this.behaviorService.loadingAccountMNG('product');
      }
    })
  }

  ngOnDestroy(): void {
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

}
