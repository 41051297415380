import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentEditorContainerComponent, DocumentEditorSettingsModel } from '@syncfusion/ej2-angular-documenteditor';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
// import { Document, Packer, Paragraph } from 'docx';
// import { ClickEventArgs } from '@syncfusion/ej2-navigations/src/toolbar/toolbar';

@Component({
  selector: 'app-template-viewer',
  templateUrl: './template-viewer.component.html',
  styleUrls: ['./template-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateViewerComponent implements OnInit, OnDestroy {
  isspiner: boolean = false;
  isspinerSearch:boolean=false;
  searchText = '';
  isLoadingResults: boolean = false;
  public settings: DocumentEditorSettingsModel = {
    optimizeSfdt: false
  };
  @ViewChild('viewer') viewer!: ElementRef;
  @ViewChild('listview') listView: ListViewComponent;
  docUrl: string;
  showFolder: string;
  showFileName: string = '';
  session_token: string;
  api_key: string;
  envUrl: string;
  envUrlText: string;
  iframeUrl: any;
  blobFile: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  base64String: string = '';
  templateFields: any[] = [];
  filteredFields: any[] = [];
  finalFields: { [key: string]: Object }[] = [];
  // public hostUrl: string = 'https://services.syncfusion.com/angular/production/api/documenteditor/';
  public hostUrl: string = 'https://word.apitest.silq.com.au/api/documenteditor/';
    @ViewChild('documenteditor_default')
    public container: DocumentEditorContainerComponent;
    public culture: string = 'en-US';
    // titleBar: TitleBar;
    base64: string = '';
    public syncDoc = 'https://documents.api10test.silq.com.au/Users/SmytheWozniakLawyers_2I0DGMNC6QU07JHZ/Templates/'
    //         {
    //             prefixIcon: 'sf-icon-InsertMergeField',
    //             tooltipText: 'Insert Field',
    //             text: this.onWrapText('Insert Field'),
    //             id: 'InsertField'
    //         },
    //         {
    //             prefixIcon: 'sf-icon-FinishMerge',
    //             tooltipText: 'Merge Document',
    //             text: this.onWrapText('Merge Document'),
    //             id: 'MergeDocument'
    //         },
    // 'New', 'Open', 'Separator', 
    // 'TrackChanges',
    public items = ['Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'Separator', 'Find', 'Separator','Comments','Separator', 'LocalClipboard', 'RestrictEditing','Separator','FormFields','UpdateFields']

      onWrapText(text: string): string {
        let content: string = '';
        let index: number = text.lastIndexOf(' ');
        content = text.slice(0, index);
        text.slice(index);
        content += '<div class="e-de-text-wrap">' + text.slice(index) + '</div>';
        return content;
      }
  @Output() coreControlsEvent:EventEmitter<string> = new EventEmitter();
  public listData: { [key: string]: Object }[] = [
    {
        text: 'ProductName',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'ShipName',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'CustomerID',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'Quantity',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'UnitPrice',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'Discount',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'ShipAddress',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'ShipCity',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'ShipCountry',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'OrderId',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    },
    {
        text: 'OrderDate',
        category: 'Drag or click the field to insert.',
        htmlAttributes: { draggable: true }
    }
  ];

  public templates = [
    {
      text: 'Activity Fields',
      name: 'Activity Fields',
      id: '1',
      child: [
        {
          text: 'The description of the activity',
          id: '100',
          name: 'ac-desc',
          FORMULA: 'ACT:Description',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The unique code for the activity',
          id: '101',
          name: 'ac-id',
          FORMULA: 'ACT:ActivityId',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The unit description for plural items',
          id: '102',
          name: 'ac-plural',
          FORMULA: 'ACT:UnitDescriptionPlural',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The rate per unit for the activity',
          id: '103',
          name: 'ac-rpu',
          FORMULA:
            'Choose(ACR:ActivityGuid = ACT:ActivityGuid,ACR:RatePerUnit,ACT:RatePerUnit)',
          DEFAULTFORMAT: 'n$-17.2',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The unit description for single items',
          id: '104',
          name: 'ac-single',
          FORMULA: 'ACT:UnitDescriptionSingle',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
      ]
    },
    {
      text: 'Appointment Fields',
      name: 'Appointment Fields',
      id: '2',
      child: [
        {
          text: 'Is the appointment an all day event',
          id: '200',
          name: 'ap-allday',
          FORMULA: 'APT:AllDayEvent',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The category of the appointment',
          id: '201',
          name: 'ap-cat',
          FORMULA: 'APT:Category',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The date of the appointment',
          id: '202',
          name: 'ap-date',
          FORMULA: 'APT:AppointmentDate',
          DEFAULTFORMAT: 'd17',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The end date of the appointment',
          id: '203',
          name: 'ap-enddate',
          FORMULA: 'APT:AppointmentEndDate',
          DEFAULTFORMAT: 'd17',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The location of the appointment',
          id: '204',
          name: 'ap-locat',
          FORMULA: 'APT:Location',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The subject of the appointment',
          id: '205',
          name: 'ap-subj',
          FORMULA: 'APT:Subject',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'The start time of the appointment',
          id: '206',
          name: 'ap-time',
          FORMULA: 'APT:AppointmentTime',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
        {
          text: 'What type of appointment is it',
          id: '207',
          name: 'ap-type',
          FORMULA: 'APT:AppointmentType',
          DEFAULTFORMAT: '',
          htmlAttributes: { draggable: true },
        },
      ]
    },
  ];
  wintemplate: any;

  constructor(private sanitizer: DomSanitizer, public behaviorService: BehaviorService,
    public MatDialog: MatDialog, private http: HttpClient, private ngZone: NgZone, private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService, public dialogRef: MatDialogRef<TemplateViewerComponent>) {
    this.behaviorService.setDocumentPath$.subscribe(data=>{
      this.docUrl = data;
    })
    this.behaviorService.setDocumentEnvUrl$.subscribe(data=>{
      this.envUrl = data;
    })
    this.behaviorService.setDocumentFolder$.subscribe(data=>{
      this.showFolder = data;
    })
    this.behaviorService.setDocumentFileName$.subscribe(data=>{
      this.showFileName = data;
    })
    this.behaviorService.setSessionToken$.subscribe(data=>{
      this.session_token = data;
    })
    this.behaviorService.setApiKey$.subscribe(data=>{
      this.api_key = data;
    })
    this.behaviorService.setDocumentEnvUrlText$.subscribe(data=>{
      this.envUrlText = data;
    })
    if(!this.docUrl) {
      this.session_token = localStorage.getItem('session_token')
      this.showFolder = localStorage.getItem('folder')
      this.showFileName = localStorage.getItem('filename')
      this.docUrl = localStorage.getItem('doc_path')
      this.envUrl = localStorage.getItem('env_url')
      this.envUrlText = localStorage.getItem('env_url_text')
      this.api_key = localStorage.getItem('api_key')
    }
    
    if(this.docUrl) {
      // this.iframeUrl = this.documentViewUrl();
    }

   }

  ngOnInit(): void {
    this.loadFields();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(window.innerWidth < 1298) {
      this.container.documentEditor.fitPage('FitPageWidth');
    }
  }

  onWindowResize= (): void => {
    //Resizes the document editor component to fit full browser window automatically whenever the browser resized.
    this.updateDocumentEditorSize();
  }

  updateDocumentEditorSize(): void {
    //Resizes the document editor component to fit full browser window.
    var windowWidth = window.innerWidth;
    //Reducing the size of title bar, to fit Document editor component in remaining height.
    // var windowHeight = window.innerHeight - this.titleBar.getHeight();
    // this.container.resize(windowWidth, windowHeight);
  }

  onDestroy(): void {
    //Removes event listener for browser window resize event.
    window.removeEventListener("resize", this.onWindowResize);
  }

  // async onClick() {
  //   const fileUrl = this.docUrl;
  //   this.downloadFileAndSend(fileUrl, this.container.serviceUrl + "Import");
  // }

  onSearchChange(){
    // this.filteredFields = this.templateFields.filter(item => 
    //   item.text.toLowerCase().includes(this.searchText.toLowerCase())
    // );

    // As per New Changes
this.isspinerSearch=true;
    let postData: any = {
      "Action": "FieldList",
      "Data": {
        "Search": this.searchText
      }
    };
    this._mainAPiServiceService.getSetData(postData, "template")
      .subscribe(
          (response) => {
              if (
                  response.CODE == 200 && response.STATUS == "success"
              ) {
                  this.templateFields = response.DATA.FIELDS;
                  this.filteredFields = this.templateFields;
                  // this.finalFields = Object.values(this.templateFields);
                  // this.finalFields.map((element) => {
                  //   element['category'] = 'Drag or click the field to insert.'
                  // })
                  this.isspinerSearch=false;
              }else{
                this.isspinerSearch=false;
              }
          },
          (error) => {
            this.isspinerSearch=false;
              // this.toastr.error(error);
          }
      );
  }

  base64toBlob(base64Data: string, contentType = ''): Blob {
    const byteString = atob(base64Data);
    const byteArrays = [];
    for (let i = 0; i < byteString.length; i++) {
      const byte = byteString.charCodeAt(i);
      byteArrays.push(byte);
    }
    return new Blob([new Uint8Array(byteArrays)], { type: contentType });
  }
  
  downloadFileAndSend(url: any, apiUrl: any) {
    this.isLoadingResults = true;
    const fileName = this.showFileName;
    // const headers = new HttpHeaders({
    //   'Cache-Control': 'no-cache',
    //   'Pragma': 'no-cache'
    // });
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache, no-store, must-revalidate')
      .set('Pragma', 'no-cache')
      .set('Expires', '0');
      var formData = new FormData();
      formData.append('file', url, fileName);
      // https://word.apitest.silq.com.au/api/documenteditor/Import
      this.http.post(apiUrl, formData).subscribe((res: any) => {
        if(res.status == 400){
          this.isLoadingResults = false;
          this.toastr.error(res.msg)
        } else {
          this.isLoadingResults = false;
          if(window.innerWidth < 1298) {
            this.container.documentEditor.fitPage('FitPageWidth');
          }
          this.container.documentEditor.enableSfdtExport = true;
          this.container.documentEditor.enableWordExport = true;
          this.container.documentEditor.open(res);
        }
      }, error => {
        this.isLoadingResults = false;
        this.toastr.error(error)
      })

      
    // this.http.get(url,{ responseType: "blob" }).subscribe((response: any) => {
    //   let blob = response;
    //   var formData = new FormData();
    //   formData.append('file', blob, fileName);
    //   this.http.post(apiUrl, formData).subscribe((res: any) => {
    //     this.isLoadingResults = false;
    //     if(window.innerWidth < 1298) {
    //       this.container.documentEditor.fitPage('FitPageWidth');
    //     }
    //     this.container.documentEditor.enableSfdtExport = true;
    //     this.container.documentEditor.enableWordExport = true;
    //     this.container.documentEditor.open(res);
    //   }, error => {
    //     this.isLoadingResults = false;
    //     console.log(error);
    //     this.toastr.error(error)
    //   })
    // }, error => {
    //   this.isLoadingResults = false;
    //   console.log(error);
    //   this.toastr.error(error)
    // });


    // var container = this.container;
    // var xhr = new XMLHttpRequest();
    // xhr.open('GET', url, true);
    // xhr.responseType = 'blob'; // Set the response type to blob
    // xhr.onload = function () {
    //     if (xhr.status === 200) {
    //         var blob = xhr.response;
    //         var formData = new FormData();
    //         formData.append('file', blob, fileName);
    //         // Now, send formData to your WebAPI
    //         var xhrUpload = new XMLHttpRequest();
    //         xhrUpload.open('POST', apiUrl, true);
    //         //xhrUpload.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    //         xhrUpload.onload = function () {
    //             if (xhrUpload.status === 200) {
    //                 if(window.innerWidth < 1298) {
    //                   container.documentEditor.fitPage('FitPageWidth');
    //                 }
    //                 container.documentEditor.open(JSON.parse(xhrUpload.responseText));
    //             } else {
    //                 console.error('Error uploading file: ', xhrUpload.statusText);
    //             }
    //         };
    //         xhrUpload.onerror = function () {
    //             console.error('Network error occurred while trying to upload file.');
    //         };
    //         xhrUpload.send(formData);
    //     } else {
    //         console.error('Error downloading file: ', xhr.statusText);
    //     }
    // };
    // xhr.onerror = function () {
    //     console.error('Network error occurred while trying to download file.');
    // };

    // xhr.send();

  }
  
  onCreate(): void {
    const fileUrl = this.docUrl;
    let toolbaritems : any = this.items;
    this.container.toolbarItems = toolbaritems;
    const blobFile = this.base64toBlob(fileUrl);
    this.downloadFileAndSend(blobFile, this.container.serviceUrl + "Import");
    // this.downloadFileAndSend(fileUrl, this.container.serviceUrl + "Import");
    document.getElementById("listview").addEventListener("dragstart", (event) => {
      let draggedData = this.listView.findItem(event.target as any);
      // event.dataTransfer.setData("Text", (event.target as any).innerText);
      event.dataTransfer.setData("Text", (draggedData as any).name);
      (event.target as any).classList.add('de-drag-target');
    });

    // Prevent default drag over for document editor element
    this.container.documentEditor.element.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    // Drop Event for document editor element
    this.container.documentEditor.element.addEventListener("drop", (e) => {
      var text = e.dataTransfer.getData('Text');//.replace(/\n/g, '').replace(/\r/g, '').replace(/\r\n/g, '');
      this.container.documentEditor.selection.select({ x: e.offsetX, y: e.offsetY, extend: false });
      //this.container.documentEditor.editor.insertText(text);
      this.insertField(text);
    });

    document.addEventListener("dragend", (event) => {
      if ((event.target as any).classList.contains('de-drag-target')) {
        (event.target as any).classList.remove('de-drag-target');
      }
    });
    // this.downloadFileAndSend(fileUrl, 'https://word.apitest.silq.com.au/api/documenteditor/Import');
    // let titleBarElement: HTMLElement = document.getElementById('default_title_bar');
    // this.titleBar = new TitleBar(titleBarElement, this.container.documentEditor, true);
    // this.container.documentEditor.open(JSON.stringify(mailmerge));
    // this.container.documentEditor.documentName = 'Test Dcoument';
    // this.titleBar.updateDocumentTitle();  

    //Opens the default template Getting Started.docx from web API.
    //Sets the language id as EN_US (1033) for spellchecker and docker image includes this language dictionary by default.
    //The spellchecker ensures the document content against this language.
    // this.container.documentEditor.spellChecker.languageID = 1033;
    // setInterval(()=>{
    //   this.updateDocumentEditorSize();
    // }, 100);
    //Adds event listener for browser window resize event.
    // window.addEventListener("resize", this.onWindowResize);
  }

  // toolbarClick = (args: ClickEventArgs): void => {
  //   switch (args.item.id) {
  //       case 'MergeDocument':
  //           this.mergeDocument();
  //           break;
  //       case 'InsertField':
  //           this.showInsertFielddialog();
  //           break;
  //   }
  // };

  showHideWaitingIndicator(show: boolean): void {
    let waitingPopUp: HTMLElement = document.getElementById('waiting-popup');
    let inActiveDiv:HTMLElement = document.getElementById('popup-overlay');
    inActiveDiv.style.display = show ? 'block' : 'none';
    waitingPopUp.style.display = show ? 'block' : 'none';
  } 

  // showInsertFielddialog(): void {
  //   let instance: any = this;
  //   if (document.getElementById('insert_merge_field') === null || document.getElementById('insert_merge_field') === undefined) {
  //       let fieldcontainer: any = document.createElement('div');
  //       fieldcontainer.id = 'insert_merge_field';
  //       document.body.appendChild(fieldcontainer);
  //       this.insertFieldDialogObj.appendTo('#insert_merge_field');
  //       fieldcontainer.parentElement.style.position = 'fixed';
  //       fieldcontainer.style.width = 'auto';
  //       fieldcontainer.style.height = 'auto';
  //   }
  //   this.insertFieldDialogObj.close = (): void => { this.container.documentEditor.focusIn(); };
  //   this.insertFieldDialogObj.beforeOpen = (): void => { this.container.documentEditor.focusIn(); };
  //   this.insertFieldDialogObj.show();
  //   let fieldNameTextBox: any = document.getElementById('field_text');
  //   fieldNameTextBox.value = '';
  // }

  // closeFieldDialog(): void {
  //     this.insertFieldDialogObj.hide();
  //     this.container.documentEditor.focusIn();
  // }

  loadFields(searchText: string = ''){
    let postData: any = {
      "Action": "FieldList",
      "Data": {
        "Search": searchText
      }
    };
    this._mainAPiServiceService.getSetData(postData, "template")
      .subscribe(
          (response) => {
              if (
                  response.CODE == 200 && response.STATUS == "success"
              ) {
                  this.templateFields = response.DATA.FIELDS;
                  this.filteredFields = this.templateFields;
                  // this.finalFields = Object.values(this.templateFields);
                  // this.finalFields.map((element) => {
                  //   element['category'] = 'Drag or click the field to insert.'
                  // })
              }
          },
          (error) => {
              // this.toastr.error(error);
          }
      );
  }

  // public fields:object = { tooltip:"category"};
  public fields: {[key: string]: string} ={ tooltip: 'text'};
  public headertitle = "Template Fields";
  
  onSelect(args): void {
    // let fieldName = args.text;
    if (args.data.id.length > 1) {
      let fieldName = args.data.name;
      this.listView.selectItem(undefined);
      this.insertField(fieldName);
    }
  }

  insertField(fieldName: any): void {
    let fileName: any = fieldName.replace(/\n/g, '').replace(/\r/g, '').replace(/\r\n/g, '');
    let fieldCode: any = 'MERGEFIELD  ' + fileName + '  \\* MERGEFORMAT ';
    // this.container.documentEditor.editor.insertField(fieldCode, '«' + fieldName + '»');
    this.container.documentEditor.editor.insertText(fieldName);
    // this.container.documentEditor.editor.insertField(fieldName);
    this.container.documentEditor.focusIn();
  }

  onDocumentChange(): void {
      // if (!isNullOrUndefined(this.titleBar)) {
          // this.titleBar.updateDocumentTitle();
      // }
      this.container.documentEditor.focusIn();
  }

  // documentViewUrl() {
  //   const url = `https://beta10.moontechnolabs.com/silqWebAppV11/editor/#/?foldername=${this.showFolder}&filename=${this.showFileName}&fileurl=${this.docUrl}&envUrl=${this.envUrl}&session_token=${this.session_token}&api_key=${this.api_key}&env_url_text=${this.envUrlText}`;
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }

  exportAsBlob(){
    // document.getElementById('export').addEventListener('click', () => {
    //   let http: XMLHttpRequest = new XMLHttpRequest();
    //   http.open('POST', 'http://localhost:5000/api/documenteditor/ExportSFDT');
    //   http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    //   http.responseType = 'json';
      //Serialize document content as SFDT.
      let sfdt: any = { content: this.container.documentEditor.serialize() };
      //Send the sfdt content to server side.
    //   http.send(JSON.stringify(sfdt));
    // })
  }

  public saveAsBlob() :void {
    // let http: XMLHttpRequest = new XMLHttpRequest();
    // http.open('POST', 'https://services.syncfusion.com/angular/production/api/documenteditor/ExportSFDT');
    // http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    // http.responseType = 'json';
    // //Serialize document content as SFDT.
    // let sfdt: any = { content: this.container.documentEditor.serialize() };
    // //Send the sfdt content to server side.
    // http.send(JSON.stringify(sfdt));
    // let http: XMLHttpRequest = new XMLHttpRequest();
    // http.open('POST', 'https://services.syncfusion.com/angular/production/api/documenteditor/ExportSFDT');
    // http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    // http.responseType = 'json';
    // //Serialize document content as SFDT.
    // let sfdt: any = { content: this.container.documentEditor.serialize() };
    // //Send the sfdt content to server side.
    // http.send(JSON.stringify(sfdt));
    // return;
    // this.container.documentEditor.save('sample', 'Docx');
    const fileFormat = this.showFileName.split('.').pop();
    const formatType = fileFormat == 'dotx' ? 'Dotx' : 'Docx';
    this.container.documentEditor.saveAsBlob(formatType).then((blob: Blob) => {
      // const file = new File([blob], this.container.documentEditor.documentName);
      // const myFile = this.blobToFile(blob, this.showFileName);
      const myFile: File = new File([blob], this.showFileName, { type: blob.type });
      // let exportedDocument: Blob = blob;
      // let formData: FormData = new FormData();
      //   formData.append('fileName', 'sample.docx');
      //   formData.append('data', exportedDocument);
      let fileReader: any = new FileReader();
          // const reader = new FileReader();
          // reader.readAsArrayBuffer(blob);
          // const base64UpdateString = base64String.split('base64,')[1]
          fileReader.onload = () => {
            const base64String = fileReader.result;
            this.saveTemplateFile(base64String);
            // let responseData: any = {
            //   documentData: base64String,
            // };
            // let baseUrl: string = 'https://ff7f-110-227-199-237.ngrok-free.app/api/documenteditor/ExportData';
            // let httpRequest: XMLHttpRequest = new XMLHttpRequest();
            // httpRequest.open('POST', baseUrl, true);
            // httpRequest.setRequestHeader(
            //   'Content-Type',
            //   'application/json;charset=UTF-8'
            // );
            // httpRequest.onreadystatechange = () => {
            //   if (httpRequest.readyState === 4) {
            //     if (httpRequest.status === 200 || httpRequest.status === 304) {
            //     } else {
            //     }
            //   }
            // };
            // httpRequest.send(JSON.stringify(<any>responseData));
          };
        fileReader.readAsDataURL(myFile);
    });
  }

  blobToFile = (theBlob: Blob, fileName:string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
      
    //Cast to a File() type
    return theBlob as File;
  }

  saveAsRtf(formData: FormData): void {
    //Send the blob object to server to process further.
    let httpRequest: XMLHttpRequest = new XMLHttpRequest();
    httpRequest.open('POST', 'https://services.syncfusion.com/angular/production/api/DocumentEditor/ExportAsRtf', true);
    httpRequest.onreadystatechange = () => {
        if (httpRequest.readyState === 4) {
            if (httpRequest.status === 200 || httpRequest.status === 304) {
                if (!(!(window.navigator as any).msSaveBlob)) {
                  (window.navigator as any).msSaveBlob(httpRequest.response, 'sample.rtf');
                } else {
                  let downloadLink: HTMLAnchorElement = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
                  this.download('sample.rtf', 'rtf', httpRequest.response, downloadLink, 'download' in downloadLink);
                }
            } else {
                console.error(httpRequest.response);
            }
        }
    }
    httpRequest.responseType = 'blob';
    httpRequest.send(formData);
  }

  download(fileName: string, extension: string, buffer: Blob, downloadLink: HTMLAnchorElement, hasDownloadAttribute: Boolean): void {
    if (hasDownloadAttribute) {
        downloadLink.download = fileName;
        let dataUrl: string = window.URL.createObjectURL(buffer);
        downloadLink.href = dataUrl;
        let event: MouseEvent = document.createEvent('MouseEvent');
        event.initEvent('click', true, true);
        downloadLink.dispatchEvent(event);
        setTimeout((): void => {
            window.URL.revokeObjectURL(dataUrl);
            dataUrl = undefined;
        });
    } else {
        if (extension !== 'docx' && extension !== 'xlsx') {
            let url: string = window.URL.createObjectURL(buffer);
            let isPopupBlocked: Window = window.open(url, '_blank');
            if (!isPopupBlocked) {
                window.location.href = url;
            }
        }
    }
  }

  // if (blob) {
  //   const reader = new FileReader();
  //   reader.readAsArrayBuffer(blob);
  //   reader.onload = () => {
  //       const arrayBuffer = reader.result as ArrayBuffer;
  //       const byteArray = new Uint8Array(arrayBuffer);
  //       const base64 = btoa(String.fromCharCode.apply(null, byteArray));
  //       let decodedString = atob(base64);
  //       this.saveTemplateFile(base64);
  //   };
  // }

  async blobToBase64(blob) {
    // const reader = new FileReader();
    // reader.readAsDataURL(blob);
    // return new Promise(resolve => {
    //   reader.onloadend = () => {
    //     resolve(reader.result);
    //   };
    // });
  };

  /**
   * This function is used to save the template file.
   * @param file -file data
   */
  saveTemplateFile(file: any) {
    // var formData = new FormData();
    // let blankDoc;
    // const fileFormat = this.showFileName.split('.').pop();
    // const formatType = fileFormat == 'dotx' ? 'Dotx' : 'Docx';

    // const doc = new Document({
    //   sections: [
    //     {
    //       properties: {},
    //       children: [
    //         new Paragraph(""),
    //       ],
    //     },
    //   ],
    // });
    // Packer.toBlob(doc).then((blobData) => {
    //   blankDoc = blobData
    //   console.log(blankDoc); // Blob can be used for further processing, like uploading or saving
    //   this.container.documentEditor.saveAsBlob(formatType).then((blob: Blob) => {
    //     var formData = new FormData();
    //     const blobFile = blob;
    //     formData.append('SourceFile', blobFile, 'SourceFile');
    //     formData.append('DestinationFile', blankDoc, 'DestinationFile');
    //     // https://word.apitest.silq.com.au/api/documenteditor/Import
    //     this.http.post('http://192.168.0.186:62870/api/DocumentEditor/HeaderMerge', formData).subscribe((res: any) => {
    //       console.log('response response',res);
    //     }, error => {
    //       this.isLoadingResults = false;
    //       this.toastr.error(error)
    //     })
    //   });
    // }).catch((error) => {
    //   console.error("Error creating Blob from DOCX:", error);
    // });

    this.isspiner = true;
    let details: any = {
        FOLDERNAME: this.showFolder ? this.showFolder : "",
        FILENAME: this.showFileName,
        FILEDATA: file,
    };
    let postData: any = {
        FORMACTION: "insert",
        VALIDATEONLY: true,
        Data: details,
    };
    this._mainAPiServiceService
      .getSetData(postData, "TemplateUpload")
      .subscribe(
          (response) => {
              postData.VALIDATEONLY = true;
              if (
                  response.CODE == 200 &&
                  (response.STATUS == "OK" ||
                      response.STATUS == "success")
              ) {
                  this.isspiner = false;
                  this.toastr.success("Document save successfully");
                  this.dialogRef.close();
              } else if (
                  response.CODE == 451 &&
                  response.STATUS == "warning"
              ) {
                this.isspiner = false;
                  this.checkValidation(
                      response.DATA.VALIDATIONS,
                      postData
                  );
              } else if (
                  response.CODE == 450 &&
                  response.STATUS == "error"
              ) {
                this.isspiner = false;
                  this.checkValidation(
                      response.DATA.VALIDATIONS,
                      postData
                  );
              }
          },
          (error) => {
            this.isspiner = false;
            this.toastr.error(error);
          }
      );
  }

  /**
   * This function is used to check the validation
   * @param bodyData -validation data
   * @param details -validation details
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value: {
        VALUEVALID: string;
        ERRORDESCRIPTION: any;
        FIELDNAME: any;
    }) {
        if (value.VALUEVALID == "No") {
            errorData.push(value.ERRORDESCRIPTION);
            tempError[value.FIELDNAME] = value;
        } else if (value.VALUEVALID == "Warning") {
            tempWarning[value.FIELDNAME] = value;
            warningData.push(value.ERRORDESCRIPTION);
        }
    });
    // this.errorWarningData = { Error: tempError, warning: tempWarning };
    if (Object.keys(errorData).length != 0) {
        this.toastr.error(errorData);
    } else if (Object.keys(warningData).length != 0) {
        this.confirmDialogRef = this.MatDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
                data: warningData,
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to Save?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveDocument(details);
            }
            this.confirmDialogRef = null;
        });
    } else if (
        Object.keys(warningData).length == 0 &&
        Object.keys(errorData).length == 0
    ) {
        this.saveDocument(details);
    }
  }

  /**
   * This function is used to Save Document.
   * @param data -Document data.
   */
  saveDocument(data: any) {
    this.isspiner = true;
    data.VALIDATEONLY = false;
    this._mainAPiServiceService
      .getSetData(data, "TemplateUpload")
      .subscribe(
        (response) => {
          if (
            response.CODE == 200 &&
            (response.STATUS == "OK" ||
                response.STATUS == "success")
          ) {
              this.isspiner = false;
              this.toastr.success("Document save successfully");
              this.dialogRef.close();
              // this.LoadData({});
          } else if (
              response.CODE == 451 &&
              response.STATUS == "warning"
          ) {
              this.isspiner = false;
              this.toastr.warning(response.MESSAGE);
          } else if (
              response.CODE == 450 &&
              response.STATUS == "error"
          ) {
            this.isspiner = false;
            this.toastr.error(response.MESSAGE);
          }
      },
      (error) => {
        this.isspiner = false;
        this.toastr.error(error);
      }
    );
  }

  ngOnDestroy(): void {
    // localStorage.removeItem('folder')
    // localStorage.removeItem('filename')
    // localStorage.removeItem('doc_path')
    // localStorage.removeItem('env_url')
    // localStorage.removeItem('env_url_text')
    // localStorage.removeItem('api_key')
  }

}
