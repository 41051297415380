<div class=" one " fxLayout="column" [formGroup]="MatterPropertyDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CROWNALLOTMENT,'has-warning':errorWarningData.Warning?.CROWNALLOTMENT}">
            <mat-label>Allotment </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CROWNALLOTMENT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CROWNALLOTMENT">
              <div  *ngIf="testBool && toolTipList.CROWNALLOTMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CROWNALLOTMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CROWNALLOTMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CROWNBLOCK,'has-warning':errorWarningData.Warning?.CROWNBLOCK}">
            <mat-label>Block </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CROWNBLOCK?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CROWNBLOCK">
              <div  *ngIf="testBool && toolTipList.CROWNBLOCK" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CROWNBLOCK.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CROWNBLOCK?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CROWNSECTION,'has-warning':errorWarningData.Warning?.CROWNSECTION}">
            <mat-label>Section </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CROWNSECTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CROWNSECTION">
              <div  *ngIf="testBool && toolTipList.CROWNSECTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CROWNSECTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CROWNSECTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CROWNPORTION,'has-warning':errorWarningData.Warning?.CROWNPORTION}">
            <mat-label>Portion </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CROWNPORTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CROWNPORTION">
              <div  *ngIf="testBool && toolTipList.CROWNPORTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CROWNPORTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CROWNPORTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CROWNSUBDIVISION,'has-warning':errorWarningData.Warning?.CROWNSUBDIVISION}">
            <mat-label>Sub Division </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CROWNSUBDIVISION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CROWNSUBDIVISION">
              <div  *ngIf="testBool && toolTipList.CROWNSUBDIVISION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CROWNSUBDIVISION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CROWNSUBDIVISION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="75" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CROWNPARISH,'has-warning':errorWarningData.Warning?.CROWNPARISH}">
            <mat-label>Parish </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CROWNPARISH?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CROWNPARISH">
              <div  *ngIf="testBool && toolTipList.CROWNPARISH" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CROWNPARISH.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CROWNPARISH?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>