import { Pipe, PipeTransform } from '@angular/core';
import { retry } from 'rxjs/operators';

@Pipe({
    name: 'enableDisableFilter',
    pure: true
})
export class EnableDisableFilterPipe implements PipeTransform {

    /**
     * This function is used to transform the data.
     */
    transform(value: any, ...args: any[]): any {
        let filterLeft = 0;
        value.forEach((obj) => {
            if (obj.FILTERTYPE != "HIDDEN" && !obj.FILTERAPPLY) {
                filterLeft += 1;
            }
        });

        if (filterLeft == 0) {
            retry(3);
            return false; //first is true but currenly we not use.
        } else {
            return false;
        }
    }

}
