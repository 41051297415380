import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-settlment-address',
  templateUrl: './settlment-address.component.html',
  styleUrls: ['./settlment-address.component.scss']
})
export class SettlmentAddressComponent implements OnInit, OnDestroy {
  @Input() matterAddressForm: FormGroup;
  @Input() errorWarningData: any;
  StreetType: any = [];
  StateData: any = [];
  sub: Subscription;
  sub1: Subscription;
  constructor(public _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService) { }

  ngOnInit() {
    let streetTypesLookups = JSON.parse(localStorage.getItem('street_types_lookups'));
    if (streetTypesLookups && streetTypesLookups != null) {
      this.StreetType = streetTypesLookups;
    } else {
      this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'street types' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.StreetType = responses.DATA.LOOKUPS;
          localStorage.setItem('street_types_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        }
      });
    }
    let statesLookups = JSON.parse(localStorage.getItem('states_lookups'));
    if (statesLookups && statesLookups != null) {
      this.StateData = statesLookups;
    } else {
      this.sub1 = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'states' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          localStorage.setItem('states_lookups', JSON.stringify(responses.DATA.LOOKUPS));
          this.StateData = responses.DATA.LOOKUPS;
        }
      });
    }
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

}
