import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { retryWhen, delay, take, retry, catchError } from 'rxjs/operators';
import { BehaviorService } from './Behavior.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { FuseSidebarService } from 'src/@fuse/components/sidebar/sidebar.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Injectable({ providedIn: 'root' })
export class TimersService {
    prevMatterArray: any[] = [];
    activeGuid: any;
    retryAllData: any = {};
    constructor(private httpClient: HttpClient, private toastr: ToastrService, private behaviorService: BehaviorService, private _snackBar: MatSnackBar, private _fuseSidebarService: FuseSidebarService,) { }
    getTimeEnrtyData(Data: any) {
        if (Data == null) {
            Data = {};
        } else {
            const AdvancedFilters = Data.AdvancedFilters ? Data.AdvancedFilters : [];
            let DataValue = {
                "Action": "GetData",
                "Filters": Data,
                AdvancedFilters: AdvancedFilters
            }
            if (AdvancedFilters.length == 0) {
                delete DataValue.AdvancedFilters
            }
            delete DataValue.Filters.AdvancedFilters;
            Data = DataValue;
        }
        this.retryAllData = {};
        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'workitem', Data).pipe(retry(0), catchError(err => {
            // this.confirmDialogRef = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: '100%' });
            // this.confirmDialogRef.componentInstance.confirmMessage = "Unable to connect to server. Click here to retry.";
            // this.confirmDialogRef.componentInstance.confirm1 = false;
            // this.confirmDialogRef.componentInstance.confirm2 = false;
            // this.confirmDialogRef.componentInstance.confirm3 = "Retry";
            // this.confirmDialogRef.afterClosed().subscribe(result => {
            //   if (result) {
            //
            //   }
            // });
            this.retryAllData['workitem'] = true;
            let snackBarRef = this._snackBar.open("Unable to connect to server. Click here to retry.", "retry", { duration: 200000000 });
            snackBarRef.onAction().subscribe(() => {

                this.behaviorService.setAPIretryCall({ 'click': true, 'data': this.retryAllData });
            });
            return of(err);
        })
        );
    }

    /**
     * This function is used to gte the users list data value.
     */
    GetUsers(Data: any)  {
        if (Data == null) {
            Data = {
                Filters: {
                    ACTIVE: "1",
                    AllData: false
                }
            };
        } else {
            Data = {
                Filters: {
                    ACTIVE: "1",
                    AllData: false
                }
            };
        }

        Data.Action = "GetData";
        //old is environment.APIEndpoint + 'GetUser'
        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'user', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    }
    /**
     * This function is used to get the activity 
     */
    GetActivity(Data: any) {
        if (Data == null) {
            Data = {};
        }
        // return this.httpClient.post<any>(environment.APIEndpoint + 'GetActivity', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));

        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'activity', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    }

    /**
     * This function is used to fetch the matter list 
     */
    matterListFetch(Data: any) {
        if (Data == null) {
            Data = {};
        } let DataValue = {
            "Action": "GetData",
            "Filters": Data
        }
        Data = DataValue;
        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'workitem', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    }

    //time entry popup data
    GetLookupsData(Data: any) {
        if (Data == null) {
            Data = {}
        }
        this.retryAllData = {};
        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'lookup', Data).pipe(retry(0), catchError(err => {
            // this.confirmDialogRef = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: '100%' });
            // this.confirmDialogRef.componentInstance.confirmMessage = "Unable to connect to server. Click here to retry.";
            // this.confirmDialogRef.componentInstance.confirm1 = false;
            // this.confirmDialogRef.componentInstance.confirm2 = false;
            // this.confirmDialogRef.componentInstance.confirm3 = "Retry";
            // this.confirmDialogRef.afterClosed().subscribe(result => {
            //   if (result) {
            //
            //   }
            // });
            this.retryAllData['lookup'] = true;
            let snackBarRef = this._snackBar.open("Unable to connect to server. Click here to retry.", "retry", { duration: 200000000 });
            snackBarRef.onAction().subscribe(() => {

                this.behaviorService.setAPIretryCall({ 'click': true, 'data': this.retryAllData });
            });
            return of(err);
        })
        );
    }

    /**
     * This function is used to calculate the wORK items
     */
    calculateWorkItems(Data: any) {
        if (Data == null) {
            Data = {};
        };

        let Payload = {
            "Action": "CalcWorkItemCharge",
            "Data": {
                ...Data
            }
        }

        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'utility', Payload).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    }

    /**
     * This function is used to change the Quantity type
     */
    ChangeQuantityType(Data: any) {
        if (Data == null) {
            Data = {};
        };

        let Payload = {
            "Action": "ChangeQuantityType",
            "Data": {
                ...Data
            }
        }

        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'utility ', Payload).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    };

    /**
     * This function is used to calculate the Work item Charge
     */
    CalcWorkItemCharge(Data: any) {
        if (Data == null) {
            Data = {};
        };

        let Payload = {
            "Action": "CalcWorkItemCharge",
            "Data": {
                ...Data
            }
        }

        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'utility', Payload).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    };

    /**
     * This function is used to set the Work Items
     */
    SetWorkItems(Data: any) {

        if (Data == null) {
            Data = {};
        };

        this.retryAllData = {};
        return this.httpClient.post<any>(environment.APIEndpointDeliverable + 'workitem', Data).pipe(retry(0), catchError(err => {
            // this.confirmDialogRef = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: '100%' });
            // this.confirmDialogRef.componentInstance.confirmMessage = "Unable to connect to server. Click here to retry.";
            // this.confirmDialogRef.componentInstance.confirm1 = false;
            // this.confirmDialogRef.componentInstance.confirm2 = false;
            // this.confirmDialogRef.componentInstance.confirm3 = "Retry";
            // this.confirmDialogRef.afterClosed().subscribe(result => {
            //   if (result) {
            //
            //   }
            // });
            this.retryAllData['workitem'] = true;
            let snackBarRef = this._snackBar.open("Unable to connect to server. Click here to retry.", "retry", { duration: 200000000 });
            snackBarRef.onAction().subscribe(() => {

                this.behaviorService.setAPIretryCall({ 'click': true, 'data': this.retryAllData });
            });
            return of(err);
        })
        );
    }

    /**
     * This function is used to Set the Journal
     */
    SetJournal(Data: any) {
        if (Data == null) {
            Data = {};
        }
        return this.httpClient.post<any>(environment.APIEndpoint + 'SetJournal ', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
    }
   
    /**
     * This function is used to add the TimeEntries data value.
     */
    addTimeEnrtS(eTimer: any) {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        let timerId: any = 'timer_' + currentUser.UserGuid;
        let activeMatters: any;
        let isRT: boolean;
        if (eTimer == '' && !eTimer) {
            isRT = false;
            activeMatters = JSON.parse(localStorage.getItem('set_active_matters'));
            if (activeMatters.ACTIVE == 0) {
                this.toastr.error("You cannot start timer for Inactive matter. Please select active matter and try again.");
                return false;
            }
        } else {
            activeMatters = eTimer;
            isRT = true;
        }
        let timerD = JSON.parse(localStorage.getItem(timerId));
        this.prevMatterArray = timerD ? JSON.parse(localStorage.getItem(timerId)) : [];
        this.addNewTimer(this.prevMatterArray, isRT, eTimer);
        this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
    }

    /**
     * This functuion is used to add the new timer
     */
    addNewTimer(prevMatterArray, isRT: any, data: any) {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        let timerId: any = 'timer_' + currentUser.UserGuid;
        let dateFo = new Date();
        let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
        let activeMatters: any = isRT ? data : JSON.parse(localStorage.getItem('set_active_matters'));
        this.activeGuid = Guid.newGuid();
        /*When add first matter in local storage. Matter array null for first time*/
        if (!localStorage.getItem(timerId)) {
            let matterArry = [];
            if (data.uuid != '' && data.uuid) {
                this.activeGuid = data.uuid;
                matterArry.push(data);
            } else {
                let temObj: any = { 'startTime': currentUTC, 'endTime': '', 'uuid': this.activeGuid, 'WORKITEMGUID': '', 'ADDITIONALTEXT': '', 'COMMENT': '', 'startTimeForDefult': '', 'matter_id': (activeMatters.SELECTEDDESCRIPTION) ? activeMatters.SELECTEDDESCRIPTION : activeMatters.SHORTNAME, 'matterguid': activeMatters.MATTERGUID, 'time': 0, 'isStart': true, 'RATEID': '' };
                if (isRT) {
                    temObj.ADDITIONALTEXT = activeMatters.ADDITIONALTEXT;
                    temObj.COMMENT = activeMatters.COMMENT;
                    temObj.RATEID = activeMatters.RATEID
                    temObj.time = activeMatters.secound;
                    temObj.startTimeForDefult = activeMatters.startTimeForDefult;
                    temObj.WORKITEMGUID = activeMatters.WORKITEMGUID;
                } else {
                    let today = new Date();
                    let time = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
                    temObj.startTimeForDefult = time;
                }
                matterArry.push(temObj);
            }
            localStorage.setItem(timerId, JSON.stringify(matterArry));
            this.toastr.success("Timer is added for selected matter");
        } else {
            let demoTimer: any[] = [];
            prevMatterArray.forEach(items => {
                let startTimer: any = localStorage.getItem('start_' + items.uuid);
                if (startTimer) {
                    let tempData = { 'startTime': items.startTime, 'endTime': currentUTC, 'uuid': items.uuid, 'WORKITEMGUID': items.WORKITEMGUID, 'RATEID': items.RATEID, 'ADDITIONALTEXT': items.ADDITIONALTEXT, 'COMMENT': items.COMMENT, 'startTimeForDefult': items.startTimeForDefult, 'matter_id': items.matter_id, 'matterguid': items.matterguid, 'time': startTimer, 'isStart': false };
                    demoTimer.push(tempData);
                    localStorage.removeItem('start_' + items.uuid);
                } else {
                    let tempData2 = { 'startTime': items.startTime, 'endTime': items.endTime, 'uuid': items.uuid, 'RATEID': items.RATEID, 'WORKITEMGUID': items.WORKITEMGUID, 'ADDITIONALTEXT': items.ADDITIONALTEXT, 'COMMENT': items.COMMENT, 'startTimeForDefult': items.startTimeForDefult, 'matter_id': items.matter_id, 'matterguid': items.matterguid, 'time': items.time, 'isStart': false };
                    demoTimer.push(tempData2);
                }
            });
            if (data.uuid != '' && data.uuid) {
                this.activeGuid = data.uuid;
                demoTimer.push(data);
            } else {
                let tempData3 = { 'startTime': currentUTC, 'endTime': '', 'uuid': this.activeGuid, 'WORKITEMGUID': '', 'ADDITIONALTEXT': '', 'RATEID': '', 'COMMENT': '', 'startTimeForDefult': '', 'matter_id': (activeMatters.SELECTEDDESCRIPTION) ? activeMatters.SELECTEDDESCRIPTION : activeMatters.SHORTNAME, 'matterguid': activeMatters.MATTERGUID, 'time': 0, 'isStart': true };
                if (isRT) {
                    let today = new Date();
                    let time = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
                    tempData3.COMMENT = activeMatters.COMMENT;
                    tempData3.RATEID = activeMatters.RATEID;
                    tempData3.ADDITIONALTEXT = activeMatters.ADDITIONALTEXT;
                    tempData3.time = activeMatters.secound;
                    tempData3.startTimeForDefult = activeMatters.startTimeForDefult;
                    tempData3.WORKITEMGUID = activeMatters.WORKITEMGUID;
                } else {
                    let today = new Date();
                    let time = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
                    tempData3.startTimeForDefult = time;
                }
                demoTimer.push(tempData3);
            }
            localStorage.setItem(timerId, JSON.stringify(demoTimer));
        }
        let timeD: any = 0;
        if (isRT)
            timeD = data.uuid ? data.time : activeMatters.secound;
        localStorage.setItem('start_' + this.activeGuid, timeD);
        this.behaviorService.setTimerCountData();
        this.behaviorService.setTimerRefreshObject(true);
    }

    /**
     * This function is used to Contains the object
     */
    containsObject(obj) {
        let isValid = true;
        this.prevMatterArray.forEach(items => {
            if (items.uuid === obj)
                isValid = false;
        });
        return isValid;
    }

    /**
     * This function is used to remove the User Without ID
     */
    removeUSerWithoutId(userList: any) {
        let templ: any = [];
        userList.forEach(element => {
            if (element.USERID != '') {
                templ.push(element);
            }
        });
        return templ;
    }
}
class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
