import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ContactSelectDialogComponent } from '../../contact/contact-select-dialog/contact-select-dialog.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorSubject, map } from 'rxjs';
@Component({
  selector: 'app-deliverable-dailog',
  templateUrl: './deliverable-dailog.component.html',
  styleUrls: ['./deliverable-dailog.component.scss']
})
export class DeliverableDailogComponent implements OnInit {
  isLoadingResults: boolean = false;
  errorWarningData: any = { "Error": [], 'Warning': [] };
  Deliverabledata: any = {}
  Deliverabledata_1: any = []
  isspiner: boolean = false;
  FormAction: string;
  DeliverableGUID: any
  Deliverableform: FormGroup;
  dialogTitle: string;
  action: string;
  windowNameId: any;
  currentMatter: any
  COMPELATEDATEValue: any
  STARTDATEValue: any
  DUEDATEValue: any
  MatterDropData: any;
  userList: any
  ContactGuid: any
  PRICEVAL: any = 0.00;
  PRICEVALGST: any = 0.00;
  MAXPRICEVALGST: any = 0.00;
  MAXPRICEVAL: any = 0.00;
  appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
 public ActiveCheckedBox$=new BehaviorSubject<boolean>(true);
  IsChecked$=this.ActiveCheckedBox$.asObservable().pipe(map((checkedValue)=>({isChecked:checkedValue})))
  constructor(
    public _matDialog: MatDialog,
    public dialogRef: MatDialogRef<DeliverableDailogComponent>,
    public datepipe: DatePipe,
    public MatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    private toastr: ToastrService,
    private titleService: Title,
    public globalFunService: GlobalFunctionsService
  ) {

    this.behaviorService.deliverableData$.subscribe(result => {
      if (result) {
        this.Deliverabledata = result;
      }
    });
    this.behaviorService.dialogClose$.subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.MatterData$.subscribe(result => {
      const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
      this.currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      if (this.currentMatter) {
      } else {
        if (result) {
          this.currentMatter = result;
          localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.currentMatter))
        }
      }
    });
    this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'TimeBillingDeliverablesComponent' && result['click'] && (result['data']['matter-deliverable'] || result['data']['lookup'])) {
        this.ngOnInit();
        if (localStorage.getItem('istrackid') == 'TimeBillingEstimateDialogComponent' && result['click'] && result['data']['matter-deliverable']) {
          this.SaveDeliverable() 
          this.DeliverableSaveData({});
        }

      }
    });

    this.action = this.data.type;
    if (this.action === 'New') {
      this.dialogTitle = 'New Deliverable';
    } else if (this.action === 'edit' || this.action === 'Duplicate') {
      this.dialogTitle = (this.action === 'edit') ? 'Update Deliverable' : 'Duplicate Deliverable';
      this.EditPopUpOPen()
    }
    // else if (this.action === 'Duplicate') {
    //   this.dialogTitle = 'Duplicate Deliverable';
    // }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if (this.action === 'New') {
    this.getDefaultData()
    }
    this.Deliverableform = this._formBuilder.group({
      DeliverableId: ['',Validators.required],
      DeliverableDescription: ['',Validators.required],
      UserResponsibleGuid: ['',Validators.required],
      ContactGuid: [''],
      PERCENTAGECOMPLETE: [''],
      STARTDATE: [''],
      DUEDATE: [''],
      COMPELATEDATE: [''],
      ESTIMATEFROMEXGST: ['0.00', {updateOn: 'blur'}],
      ESTIMATEFROMINCGST: ['0.00', {updateOn: 'blur'}],
      ESTIMATETOTOTALEXGST: ['0.00', {updateOn: 'blur'}],
      ESTIMATETOTOTALINCGST: ['0.00', {updateOn: 'blur'}],
      ADDITIONALNOTE: [''],
    })

    this.userList = JSON.parse(localStorage.getItem('users_list'));
  };

  /**
   * Thia function is used to choosed the date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.COMPELATEDATEValue = this.datepipe.transform(event.value, 'dd/MM/yyyy');
  }

  /**
   * This function is used to choosed the Due Date
   */
  DUEDATEchoosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.DUEDATEValue = this.datepipe.transform(event.value, 'dd/MM/yyyy');
  }

  /**
   * This function is used to Choosed the Start date
   */
  STARTDATEchoosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.STARTDATEValue = this.datepipe.transform(event.value, 'dd/MM/yyyy');
  }


  /**
   * This function is used to calculate the value
   */
  calcPE(type) {
    if (type == 'min') {
      this.PRICEVALGST = this.f.ESTIMATEFROMEXGST.value * 1.1;
    } else {
      this.MAXPRICEVALGST = this.f.ESTIMATETOTOTALEXGST.value * 1.1;
    }
  };

  /**
   * This function is used to calculate the PI
   */
  calcPI(type) {
    if (type == 'min') {
      this.PRICEVAL = this.f.ESTIMATEFROMINCGST.value / 1.1;
    } else {
      this.MAXPRICEVAL = this.f.ESTIMATETOTOTALINCGST.value / 1.1;
    }
  };

  
  get f() {
    return this.Deliverableform.controls;
  };

  /**
   * This function is used to matterChange data get
   */
  matterChange(key: any, event: any, chnageType: any = '') {
    this.Deliverableform.controls['UserResponsibleGuid'].setValue(this.f.UserResponsibleGuid.value)
  };

  /**
   * This function is used to Set the Contact matter data value
   */
  ContactMatter() {
    const dialogRef = this._matDialog.open(ContactSelectDialogComponent, {
      width: '100%', disableClose: true, data: { type: "", 'default': '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Deliverableform.controls['ContactGuid'].setValue(result.CONTACTNAME + "-" + result.SUBURB);
      this.ContactGuid = result.CONTACTGUID
      localStorage.setItem('istrackid', 'DeliverableDailogComponent');

      if (result) {
        if (this.data.action == "new" || this.data.action == "duplicate") {
          // this.Clientchild.CoommonMatterNum(result.CONTACTGUID);
        }
      }
    })
  };

  /**
   * This function is used to get the contact
   */
  GetContact(data:any) {
    this.isLoadingResults = true;
    new Promise((next, reject) => {
      this._mainAPiServiceService.getSetData({"Action": "GetData","FILTERS": {CONTACTGUID:data}}, 'contact').subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let contactList = response.DATA.RECORDS.filter(e => e.CONTACTGUID == this.Deliverabledata.CONTACTGUID);
          this.Deliverableform.controls['ContactGuid'].setValue((contactList.length > 0) ? contactList[0].CONTACTNAME : '');
          this.ContactGuid = (contactList.length > 0) ? contactList[0].CONTACTGUID : '';
          this.isLoadingResults = false;
          next(true);
        } else {
          this.isLoadingResults = false;
          next(true);
        }
      });
    });
  };

  /**
   * This function is used to Edit the popup Open
   */
  async EditPopUpOPen() {
    

    this.userList = JSON.parse(localStorage.getItem('users_list'));
  //  let contactusername = this.userList.filter(e => e.USERGUID == this.Deliverabledata.USERRESPONSIBLEGUID)
// "MUSTINCLUDEGUID":this.Deliverabledata.MATTERDELIVERABLEGUID
//'MATTERDELIVERABLEGUID': this.Deliverabledata.MATTERDELIVERABLEGUID
    this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { "MUSTINCLUDEGUID":this.Deliverabledata.MATTERDELIVERABLEGUID } }, 'matter-deliverable').subscribe(async result => {
      if (result.CODE == 200 && result.STATUS == "success") {

        this.Deliverableform.controls['DeliverableDescription'].setValue(result.DATA.RECORDS[0].DELIVERABLEDESCRIPTION);
        this.Deliverableform.controls['DeliverableId'].setValue(result.DATA.RECORDS[0].DELIVERABLEID);
        this.Deliverableform.controls['UserResponsibleGuid'].setValue(result.DATA.RECORDS[0].USERRESPONSIBLEGUID);

        this.Deliverableform.controls['PERCENTAGECOMPLETE'].setValue(result.DATA.RECORDS[0].PERCENTAGECOMPLETE);
        // DATE 
        this.Deliverableform.controls['STARTDATE'].setValue(moment(result.DATA.RECORDS[0].STARTDATE, 'DD/MM/YYYY'));
        this.Deliverableform.controls['DUEDATE'].setValue(moment(result.DATA.RECORDS[0].DUEDATE, 'DD/MM/YYYY'));
        this.Deliverableform.controls['COMPELATEDATE'].setValue(moment(result.DATA.RECORDS[0].COMPLETEDDATE.replace(/^\s+|\s+$/gm, ''), 'DD/MM/YYYY'));

        this.Deliverableform.controls['ESTIMATEFROMEXGST'].setValue(result.DATA.RECORDS[0].ESTIMATEFROMTOTALEXGST);
        this.Deliverableform.controls['ESTIMATEFROMINCGST'].setValue(result.DATA.RECORDS[0].ESTIMATEFROMTOTALINCGST);

        this.Deliverableform.controls['ESTIMATETOTOTALEXGST'].setValue(result.DATA.RECORDS[0].ESTIMATETOTOTALEXGST);
        this.Deliverableform.controls['ESTIMATETOTOTALINCGST'].setValue(result.DATA.RECORDS[0].ESTIMATETOTOTALINCGST);
        this.Deliverableform.controls['ADDITIONALNOTE'].setValue(result.DATA.RECORDS[0].ADDITONALNOTES);

        if(result.DATA.RECORDS[0].ISACTIVE){
          (result.DATA.RECORDS[0].ISACTIVE === 'Inactive') ? this.setToggleValue(false) : this.setToggleValue(true)
         }
        // this.Deliverableform.controls['MATTERGUID'].setValue(this.currentMatter.MATTERGUID);
        // this.Deliverableform.controls['MATTERGUID'].setValue(this.currentMatter.MATTERGUID);
        setTimeout(async () => {  
            if(result.DATA.RECORDS[0].CONTACTGUID){
                await this.GetContact(result.DATA.RECORDS[0].CONTACTGUID)
            }
        }, 500);
        this.isLoadingResults = false;
    }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }
/**
 * This function is used to Save Delivarable Values
 * @param ToggleValue 
 */
  SaveDeliverable(ToggleValue?:boolean):void {    
    if (this.action === 'New' || this.action === 'Duplicate') {
      this.FormAction = 'insert';
      this.DeliverableGUID = "";
      this.Deliverabledata.MATTERDELIVERABLEGUID = ''
    } else {
      this.FormAction = 'update';
      this.DeliverableGUID = this.Deliverabledata.MATTERDELIVERABLEGUID
    }
    if (this.STARTDATEValue == undefined || this.STARTDATEValue == null ) {
      this.STARTDATEValue = ""
    };
    if (this.DUEDATEValue == undefined || this.DUEDATEValue == null) {
      this.DUEDATEValue = ""
    };
    if (this.COMPELATEDATEValue == undefined || this.COMPELATEDATEValue == null ) {
      this.COMPELATEDATEValue = ""
    };

    
    let PostData: any = {
      MatterDeliverableGuid:this.Deliverabledata.MATTERDELIVERABLEGUID,
      MatterGuid: window.name.split('_')[1] ? window.name.split('_')[1] : this.currentMatter.MATTERGUID,
      DELIVERABLEID: this.f.DeliverableId.value,
      DELIVERABLEDESCRIPTION: this.f.DeliverableDescription.value,
      USERRESPONSIBLEGUID: this.f.UserResponsibleGuid.value,
      ContactGuid: this.ContactGuid?this.ContactGuid :'',
      PercentageComplete: this.f.PERCENTAGECOMPLETE.value,
      StartDate: this.STARTDATEValue,
      DueDate: this.DUEDATEValue,
      CompletedDate: this.COMPELATEDATEValue,
      EstimateFromTotalExGST: (Number(this.PRICEVAL)).toFixed(2),
      EstimateFromTotalIncGST: (Number(this.PRICEVALGST)).toFixed(2),
      EstimateToTotalExGST: (Number(this.MAXPRICEVAL)).toFixed(2),
      EstimateToTotalIncGST: (Number(this.MAXPRICEVALGST)).toFixed(2),
      AdditonalNotes: this.f.ADDITIONALNOTE.value,
      ISACTIVE:(ToggleValue === true) ? "Active" : "Inactive"
    };

    this.isspiner = true;
    let PostsaveData: any = { FormAction: this.FormAction, VALIDATEONLY: true, Data: PostData };
    this._mainAPiServiceService.getSetData(PostsaveData, 'matter-deliverable').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, PostsaveData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, PostsaveData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, PostsaveData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });

    // this.getMatterInitialList(PostData)

  }

  /**
   * This function is used to check Validations
   */
  async checkValidation(bodyData: any, details: any) {
    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
      .subscribe(data => {
        if (data) {
          this.errorWarningData = data.errorWarningData;
          if (data.callback) {
            this.DeliverableSaveData(details);
          }
        }
      });
  }

  /**
   * This function is used to save the Deliverable data.
   */
  DeliverableSaveData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'matter-deliverable').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to get the Default data value
   */
getDefaultData(){
  let PostData: any = {
    MatterDeliverableGuid:'',
    MatterGuid: '',
    DELIVERABLEID: '',
    DELIVERABLEDESCRIPTION: '',
    UserResponsibleGuid:'',
    ContactGuid:'',
    PercentageComplete: '',
    StartDate:'',
    DueDate: '',
    CompletedDate: '',
    EstimateFromTotalExGST: '',
    EstimateFromTotalIncGST: '',
    EstimateToTotalExGST: '',
    EstimateToTotalIncGST: '',
    AdditonalNotes: ''
  };

  this.isspiner = true;
  let PostsaveData: any = { Action: 'Default', VALIDATEONLY: true, Data: PostData };
  this._mainAPiServiceService.getSetData(PostsaveData, 'matter-deliverable').subscribe(response => {
    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {

      this.Deliverableform.controls['DeliverableDescription'].setValue(response.DATA.DEFAULTVALUES.DELIVERABLEDESCRIPTION);
        this.Deliverableform.controls['DeliverableId'].setValue(response.DATA.DEFAULTVALUES.DELIVERABLEID);
        this.Deliverableform.controls['UserResponsibleGuid'].setValue(response.DATA.DEFAULTVALUES.USERRESPONSIBLEGUID);

        this.Deliverableform.controls['PERCENTAGECOMPLETE'].setValue(response.DATA.DEFAULTVALUES.PERCENTAGECOMPLETE);
        // DATE 
        this.Deliverableform.controls['STARTDATE'].setValue(moment(response.DATA.DEFAULTVALUES.STARTDATE, 'DD/MM/YYYY'));
        this.Deliverableform.controls['DUEDATE'].setValue(moment(response.DATA.DEFAULTVALUES.DUEDATE, 'DD/MM/YYYY'));
        this.Deliverableform.controls['COMPELATEDATE'].setValue(moment(response.DATA.DEFAULTVALUES.COMPLETEDDATE.replace(/^\s+|\s+$/gm, ''), 'DD/MM/YYYY'));

        this.Deliverableform.controls['ESTIMATEFROMEXGST'].setValue(response.DATA.DEFAULTVALUES.ESTIMATEFROMTOTALEXGST);
        this.Deliverableform.controls['ESTIMATEFROMINCGST'].setValue(response.DATA.DEFAULTVALUES.ESTIMATEFROMTOTALINCGST);

        this.Deliverableform.controls['ESTIMATETOTOTALEXGST'].setValue(response.DATA.DEFAULTVALUES.ESTIMATETOTOTALEXGST);
        this.Deliverableform.controls['ESTIMATETOTOTALINCGST'].setValue(response.DATA.DEFAULTVALUES.ESTIMATETOTOTALINCGST);
        this.Deliverableform.controls['ADDITIONALNOTE'].setValue(response.DATA.DEFAULTVALUES.ADDITONALNOTES);
        if(response.DATA.DEFAULTVALUES.ISACTIVE){
         (response.DATA.DEFAULTVALUES.ISACTIVE === 'Inactive') ? this.setToggleValue(false) : this.setToggleValue(true)
        }
      this.isspiner = false; 
    } else if (response.CODE == 451 && response.STATUS == 'warning') {
      this.toastr.warning(response.MESSAGE);
      this.isspiner = false;
    } else if (response.CODE == 450 && response.STATUS == 'error') {
      this.toastr.error(response.MESSAGE);
      this.isspiner = false;
    } else if (response.MESSAGE == 'Not logged in') {
      this.dialogRef.close(false);
    }
  }, error => {
    this.isspiner = false; 
    this.toastr.error(error);
  });
}

/**
 * Used to set the value on toggle button
 */
 setToggleValue(Value:boolean):void{
  this.ActiveCheckedBox$.next(Value);
}

/**
 * This function is used to get the toggle button value.
 * @param event -Used to get the updated value of the toggle button
 */
getActiveData(event:any):void{
 this.setToggleValue(event.checked);
}

}
