import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-conveyancing-contract',
  templateUrl: './conveyancing-contract.component.html',
  styleUrls: ['./conveyancing-contract.component.scss']
})
export class ConveyancingContractComponent implements OnInit {
  errorWarningData:any={}
  @Input() MatterConveyancingDetailsForm:FormGroup
  constructor(private datepipe: DatePipe, public tooltipService: TooltipService) { }

  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  
  ngOnInit() {
  }

  /**
   * This function is used to get the commanDatePicker.
   */
  CommanDatePickerClick(type: string, event: MatDatepickerInputEvent<Date>,formControlVar) {
    this.MatterConveyancingDetailsForm.controls[formControlVar].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  /**
   * THis function is used to get the text data value
   */
  myFunction(event:any) {
    navigator.clipboard.writeText(event)
  }
   

}
