import { AuthenticationService } from '@_services/authentication.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
    name: 'tierPermission',
    pure: true
})
export class TierPermissionPipe implements PipeTransform {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }
    accessTierPermission: any = JSON.parse(localStorage.getItem('set_tier_permission'));
  
     /**
     * This function is used to transform the data.
     */
    transform(value: any, ...args: any[]): any {
        return (this.accessTierPermission !== null) ? this.accessTierPermission.filter(e => e.FEATURENAME == value)[0] : [];
        // if(this.accessTierPermission !==null){
        //   if(this.accessTierPermission.filter(e=> e.FEATURENAME == value).length !== 0){
        //     return this.accessTierPermission.filter(e=> e.FEATURENAME == value)[0];
        //   }else{
        //     this.authenticationService.ForcLogout();
        //     setTimeout(() => {
        //       location.reload()
        //     }, 1000);
        //   }
        // }else{
        //   return [];
        // }
    }

}
