import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tooltip'
})
export class TooltipPipe implements PipeTransform {
    constructor(private _mainAPiServiceService: MainAPiServiceService) { }
      
    /**
     * This function is used to transform the data.
     */
    transform(value: any, ...args: any[]): any {
        const dashboardTooltips: any = JSON.parse(localStorage.getItem('dashboard_tooltips'));
        if (dashboardTooltips && dashboardTooltips.length > 0) {
            const data = dashboardTooltips.filter(item => item.NAME == value);
            return data[0] ? data[0].TOOLTIP : ''
        } else {

            this._mainAPiServiceService.getSetData({"Action": "GetData", "Filters": { "GraphList": true } }, 'dashboard').subscribe(async res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    localStorage.setItem('dashboard_tooltips', JSON.stringify(res.DATA.DASHBOARDLIST))
                    const data = await res.DATA.DASHBOARDLIST.filter(item => item.NAME == value);
                    return data[0] ? data[0].TOOLTIP : ''
                    // this.dataFrimDashboard=res.DATA && res.DATA.DASHBOARDLIST[12]? res.DATA.DASHBOARDLIST[12] : {};
                } else {
                    return null;

                }
            })
        }
    }

}
