import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// import { SelectDocumentsComponent } from 'src/app/main/pages/document-register/select-documents/select-documents.component';
// import { colorSets } from '@swimlane/ngx-charts/release/utils';
import { SelectDocumentsComponent } from '@pages/document-register/select-documents/select-documents.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, Subscription, forkJoin, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-chron-item-dailog',
  templateUrl: './chron-item-dailog.component.html',
  styleUrls: ['./chron-item-dailog.component.scss']
})
export class ChronItemDailogComponent implements OnInit, OnDestroy {
  ChroneItem: FormGroup;
  isLoadingResults: boolean = false;
  action: string;
  confirmDialogRef: any;
  dialogTitle: string;
  ChronologyADD: any = [];
  isspiner: boolean = false;
  currentMatter: any;
  Chronology_data: any;
  ChronoGUID: any;
  Dateform: any;
  Dateto: string;
  timeform: string;
  timeto: string;
  FormAction: string;
  fileToUpload: any = [];
  errorWarningData: any = {};
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  uploadBy: string = '';
  docGUID: any = [];
  windowNameId: any;
  userList:any =[]
  eventvalue:any
  @Input() toolTipList : any;
  toolTipPostion ="above";
  testBool: boolean=false;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub1: Subscription;
  sub2: Subscription;
  
  docname;
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<ChronItemDailogComponent>,
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    public behaviorService: BehaviorService,
    public datepipe: DatePipe,
    private _mainAPiServiceService: MainAPiServiceService,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService,
    public tooltipService:TooltipService,
  ) {
    localStorage.setItem('istrackid', 'LegalDetailsChronologyDialogComponent');
    this.action = data.action;
    if (this.action === 'new') {
      this.dialogTitle = 'New Chronology';
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Chronology';
    } else {
      this.dialogTitle = 'Duplicate Chronology';
    }
    this.ChronologyADD = {
      'FORMAT': '',
      "DATEFROM": '',
      "TIMEFROM": '',
      "DATETO": '',
      "TIMETO": '',
      "REFERENCE": '',
      "BRIEFPAGENO": '',
      "WITNESSES": '',
      "COMMENT": '',
      "PRIVILEGED": '',
      "ADDITIONALTEXT": '',
      "TOPIC": '',
      "EVENTAGREED": '',
      "DOCUMENTNAME": '',
      "MATTERGUID": '',
      "CHRONOLOGYGUID": '',
      "FakeDateForm": '',
      "FakeDateTo": ''
    }
    this.behaviorService.LegalChronologyData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {

        this.Chronology_data = result;
      }
    });
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });

    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'LegalDetailsChronologyDialogComponent' && result['click'] && result['data']['chronology'] || result['data']['lookup']) {
        this.ngOnInit();
        this.ChroneItemSave();
      }
      // if (localStorage.getItem('istrackid') == 'LegalDetailsChronologyDialogComponent' && result['click'] && result['data']['chronology'] || result['data']['lookup']) {
      // }  

    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.ChroneItem = this._formBuilder.group({
      Format: [],
      dateForm: [],
      timeForm: [],
      dateto: [],
      timeto: [],
      text: [],
      topic: [],
      COMMENT: [],
      Privileged: [],
      Reference: [],
      brif: [],
      Witnesses: [],
      eventAgereed: [],
      document: []
    });
    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.currentMatter = result;
    //   }
    // });
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
       this.currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
       if (this.currentMatter) {
       } else {
         if (result) {
           this.currentMatter = result;
           localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.currentMatter))
         }
       }
    });

    if (this.action == 'edit' || this.action == 'duplicate') {
      this.EditTimeadata();
    } else if (this.action == 'new') {
      this.ChronologyADD.FORMAT = '1';
      this.ChronologyADD.MATTERGUID = this.currentMatter.MATTERGUID;
      this.FormatChange(this.ChronologyADD.FORMAT);
      this.newchron();
    }
  this.setTooltipData()
}

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
  }

  /**
   * This function is used to set the tooltip data value
   */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['Chronology'] ){
      this.toolTipList  = TooltipData['Chronology'];
    }else{
      this.tooltipService.setToolTipData = ('Chronology');
     }
  };

  /**
   * This function is used to Toggle forUpdate
   */
  ToggleForUpadte():void {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to copy tool tip text
   * @param event -event data value
   */
  copyTooltipText(event: any):void {
    navigator.clipboard.writeText(event)
  };

  /**
   * calling APIs when action is new
   */
  newchron(){
   this.sub1 = forkJoin([
      this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'event agreed' }}, 'lookup'),
      this._mainAPiServiceService.getSetData({Action:"default" ,DATA:{}}, 'chronology')
    ]).pipe(
      tap(([lookups,chronology]) => {
        if (lookups.CODE == 200 && (lookups.STATUS == "OK" || lookups.STATUS == "success")) {
          this.userList =  lookups.DATA.LOOKUPS
        }
        if (chronology.CODE == 200 && (chronology.STATUS == "OK" || chronology.STATUS == "success")) {
          this.toolTipList = chronology.DATA.FIELDTIPS;
        }
      })
    ).subscribe(
      next => console.log('next',next),
      error =>  {
        this.toastr.error(error);
        this.isLoadingResults = false;
      }
    );
    // this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'event agreed' }}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
    //   if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
    //      this.userList =  response.DATA.LOOKUPS
        
    //   }
    // })
  
    // this._mainAPiServiceService.getSetData({FormAction:"default" ,DATA:{}}, 'chronology').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
    //   if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
    //     this.toolTipList = response.DATA.FIELDTIPS;
    //   }
    // })
  }

  /**
   * This function is used to Event1 change
   */
  Event1(value :any){   
    this.eventvalue = value
  }

  /**
   * calling APIs when action is edit
   */
  EditTimeadata() {
    this.isLoadingResults = true;
    this.sub2 = forkJoin([
      this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{"LookupType": "event agreed"}}, 'lookup'),
      this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{ CHRONOLOGYGUID: this.Chronology_data.CHRONOLOGYGUID }}, 'chronology')
    ]).pipe(
      tap(([lookups, chronology]) => {
        if (lookups.CODE == 200 && (lookups.STATUS == "OK" || lookups.STATUS == "success")) {
          this.userList =  lookups.DATA.LOOKUPS
        }
        if (chronology.CODE == 200 && chronology.STATUS == "success") {
          this.toolTipList = chronology.DATA.FIELDTIPS
          let Dateform = chronology.DATA.RECORDS[0].DATEFROM.split("/");
          let DATE = new Date(Dateform[1] + '/' + Dateform[0] + '/' + Dateform[2]);
          this.ChronologyADD.DATEFROM = DATE;
          this.ChronologyADD.FakeDateForm = chronology.DATA.RECORDS[0].DATEFROM;
          let DATETO = chronology.DATA.RECORDS[0].DATETO.split("/");
          let DATE2 = new Date(DATETO[1] + '/' + DATETO[0] + '/' + DATETO[2]);
          this.ChronologyADD.DATETO = DATE2;
          this.ChronologyADD.FakeDateTo = chronology.DATA.RECORDS[0].DATETO;
          this.ChronologyADD.FORMAT = chronology.DATA.RECORDS[0].FORMAT
          this.ChronologyADD.TIMEFROM = chronology.DATA.RECORDS[0].TIMEFROM
          this.ChronologyADD.TIMETO = chronology.DATA.RECORDS[0].TIMETO
          this.ChronologyADD.REFERENCE = chronology.DATA.RECORDS[0].REFERENCE
          this.ChronologyADD.BRIEFPAGENO = chronology.DATA.RECORDS[0].BRIEFPAGENO
          this.ChronologyADD.WITNESSES = chronology.DATA.RECORDS[0].WITNESSES
          this.ChronologyADD.COMMENT = chronology.DATA.RECORDS[0].COMMENT
          this.ChronologyADD.PRIVILEGED = (chronology.DATA.RECORDS[0].PRIVILEGED == 'Yes')?chronology.DATA.RECORDS[0].PRIVILEGED:0;
          this.ChronologyADD.ADDITIONALTEXT = chronology.DATA.RECORDS[0].ADDITIONALTEXT
          this.ChronologyADD.TOPIC = chronology.DATA.RECORDS[0].TOPIC
           this.ChronologyADD.EVENTAGREED = chronology.DATA.RECORDS[0].EVENTAGREED
          //  this.ChronologyADD.EVENTAGREED = this.userList
          this.ChronologyADD.DOCUMENTNAME = chronology.DATA.RECORDS[0].DOCUMENTNAME;
          this.ChronologyADD.MATTERGUID = chronology.DATA.RECORDS[0].MATTERGUID
          this.ChronologyADD.CHRONOLOGYGUID = chronology.DATA.RECORDS[0].CHRONOLOGYGUID;
          this.FormatChange(chronology.DATA.RECORDS[0].FORMAT);
  
        } else if (chronology.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
        this.isLoadingResults = false;
      })
    ).subscribe(
      next => console.log('next',next),
      error =>  {
        this.toastr.error(error);
        this.isLoadingResults = false;
      }
    )
    // this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{"LookupType": "event agreed"}}, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
    //   if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
    //      this.userList =  response.DATA.LOOKUPS
       
    //   }
    // })
    // this._mainAPiServiceService.getSetData({Action:'GetData',Filters:{ CHRONOLOGYGUID: this.Chronology_data.CHRONOLOGYGUID }}, 'chronology').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
     
    //   if (res.CODE == 200 && res.STATUS == "success") {
    //     this.toolTipList = res.DATA.FIELDTIPS
    //     let Dateform = res.DATA.RECORDS[0].DATEFROM.split("/");
    //     let DATE = new Date(Dateform[1] + '/' + Dateform[0] + '/' + Dateform[2]);
    //     this.ChronologyADD.DATEFROM = DATE;
    //     this.ChronologyADD.FakeDateForm = res.DATA.RECORDS[0].DATEFROM;
    //     let DATETO = res.DATA.RECORDS[0].DATETO.split("/");
    //     let DATE2 = new Date(DATETO[1] + '/' + DATETO[0] + '/' + DATETO[2]);
    //     this.ChronologyADD.DATETO = DATE2;
    //     this.ChronologyADD.FakeDateTo = res.DATA.RECORDS[0].DATETO;
    //     this.ChronologyADD.FORMAT = res.DATA.RECORDS[0].FORMAT
    //     this.ChronologyADD.TIMEFROM = res.DATA.RECORDS[0].TIMEFROM
    //     this.ChronologyADD.TIMETO = res.DATA.RECORDS[0].TIMETO
    //     this.ChronologyADD.REFERENCE = res.DATA.RECORDS[0].REFERENCE
    //     this.ChronologyADD.BRIEFPAGENO = res.DATA.RECORDS[0].BRIEFPAGENO
    //     this.ChronologyADD.WITNESSES = res.DATA.RECORDS[0].WITNESSES
    //     this.ChronologyADD.COMMENT = res.DATA.RECORDS[0].COMMENT
    //     this.ChronologyADD.PRIVILEGED = (res.DATA.RECORDS[0].PRIVILEGED == 'Yes')?res.DATA.RECORDS[0].PRIVILEGED:0;
    //     this.ChronologyADD.ADDITIONALTEXT = res.DATA.RECORDS[0].ADDITIONALTEXT
    //     this.ChronologyADD.TOPIC = res.DATA.RECORDS[0].TOPIC
    //      this.ChronologyADD.EVENTAGREED = res.DATA.RECORDS[0].EVENTAGREED
    //     //  this.ChronologyADD.EVENTAGREED = this.userList
    //     this.ChronologyADD.DOCUMENTNAME = res.DATA.RECORDS[0].DOCUMENTNAME;
    //     this.ChronologyADD.MATTERGUID = res.DATA.RECORDS[0].MATTERGUID
    //     this.ChronologyADD.CHRONOLOGYGUID = res.DATA.RECORDS[0].CHRONOLOGYGUID;
    //     this.FormatChange(res.DATA.RECORDS[0].FORMAT);

    //   } else if (res.MESSAGE == 'Not logged in') {
    //     this.dialogRef.close(false);
    //   }
    //   this.isLoadingResults = false;
    // }, err => {
    //   this.toastr.error(err);
    //   this.isLoadingResults = false;
    // });
  }

  /**
   * This function is used to commonOdd get
   */
  commonOdd() {
    this.ChroneItem.controls['dateForm'].enable();
    this.ChroneItem.controls['timeForm'].disable();
    this.ChroneItem.controls['dateto'].disable();
    this.ChroneItem.controls['timeto'].disable();
  }

  /**
   * This function is used to get the Common Even values
   */
  commonEven() {
    this.ChroneItem.controls['dateForm'].enable();
    this.ChroneItem.controls['dateto'].enable();
    this.ChroneItem.controls['timeForm'].disable();
    this.ChroneItem.controls['timeto'].disable();
  }
  
  /**
   * This function is used to change the format
   */
  FormatChange(val):void {
    if (val == '1') {
      this.commonOdd();

    } else if (val == '2') {
      this.commonEven();
    } else if (val == '3') {
      this.commonOdd();
    } else if (val == '4') {
      this.commonEven();
    } else if (val == '5') {
      this.commonOdd();
    } else if (val == '6') {
      this.commonEven();
    } else if (val == '7') {
      this.ChroneItem.controls['dateForm'].enable();
      this.ChroneItem.controls['timeForm'].enable();
      this.ChroneItem.controls['dateto'].disable();
      this.ChroneItem.controls['timeto'].disable();
    } else if (val == '8') {
      this.ChroneItem.controls['dateForm'].enable();
      this.ChroneItem.controls['timeForm'].enable();
      this.ChroneItem.controls['timeto'].enable();
      this.ChroneItem.controls['dateto'].disable();
    }
  }
 
  /**
   * This function is used to choosed Date Form
   */
  choosedDateFrom() {

  }
  
  /**
   * This function is used to choosed DateForm
   */
  choosedDateForm(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.ChronologyADD.FakeDateForm = begin;
  }
  
  /**
   * This function is used to choosed the date.
   */
  choosedDateTo(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.ChronologyADD.FakeDateTo = begin;
  }
 
  /**
   * This function is used to get the date.
   */
  DateTo() {

  }
  //Event
  Event() {

  }
 
  /**
   * This function is used to select the doc
   */
  selectDoc(event) {
    this.uploadBy = "device";
    this.FileConvert64bit(event);
  }

  /**
   * This function is used to document Reg popup
   */
  documentRegPopup() {
    this.uploadBy = "document-register";
    const dialogRef = this.MatDialog.open(SelectDocumentsComponent, {
      disableClose: true,
      panelClass: "Select-Document-dialog",
      data: null,
    });
    dialogRef.afterClosed().subscribe((result) => {
      localStorage.setItem('istrackid', 'LegalDetailsChronologyDialogComponent');
      let filename = [];
      result.forEach(document => {
       // filename.push(document.DOCUMENTNAME);
        this.docGUID.push(document.DOCUMENTGUID);
        // this.docname = document.DOCUMENTNAME.split("\\");
        this.docname = document.DOCUMENTNAME
        // filename.push(this.docname[this.docname.length - 1])
        filename.push(this.docname)
      });
    //  alert(this.ChronologyADD.DOCUMENTNAME)
      this.ChronologyADD.DOCUMENTNAME = filename.join(', ');
     // this.ChronologyADD.DOCUMENTNAME = this.docname[this.docname.length - 1];
      
    });
  }
  /**
   * This functionis used to send the common odd.
   */
  commonSendOdd() {
    this.Dateform = this.ChronologyADD.FakeDateForm;
    this.Dateto = ""
    this.timeform = ""
    this.timeto = "";
  }

  /**
   * This function is used to send the Even
   */
  commonSendEven() {
    this.Dateform = this.ChronologyADD.FakeDateForm;
    this.Dateto = this.ChronologyADD.FakeDateTo;
    this.timeform = ""
    this.timeto = "";
  }
 
  /**
   * This function is used to chrone Item Save
   */
  ChroneItemSave() {
    this.isspiner = true;
    if (this.ChronologyADD.FORMAT == '1') {
      this.commonSendOdd();
    } else if (this.ChronologyADD.FORMAT == '2') {
      this.commonSendEven();
    } else if (this.ChronologyADD.FORMAT == '3') {
      this.commonSendOdd();
    } else if (this.ChronologyADD.FORMAT == '4') {
      this.commonSendEven();
    } else if (this.ChronologyADD.FORMAT == '5') {
      this.commonSendOdd();
    } else if (this.ChronologyADD.FORMAT == '6') {
      this.commonSendEven();
    } else if (this.ChronologyADD.FORMAT == '7') {
      this.Dateform = this.ChronologyADD.FakeDateForm;
      this.Dateto = ""
      this.timeform = this.ChronologyADD.TIMEFROM;
      this.timeto = "";
    } else if (this.ChronologyADD.FORMAT == '8') {
      this.Dateform = this.ChronologyADD.FakeDateForm;
      this.Dateto = ""
      this.timeform = this.ChronologyADD.TIMEFROM;
      this.timeto = this.ChronologyADD.TIMETO;
    }

    if (this.action == 'edit') {
      this.ChronoGUID = this.ChronologyADD.CHRONOLOGYGUID;
      this.ChronologyADD.EVENTAGREED = this.eventvalue
      this.FormAction = 'update';
    } else if (this.action == 'new') {
      this.ChronoGUID = '';
      this.FormAction = 'insert';
    } else if (this.action == 'duplicate') {
      this.ChronoGUID = '';
      this.FormAction = 'insert';
    }
    // this._mainAPiServiceService.getSetData({"LookupType": "eventagreed"}, 'GetLookups ').subscribe(response => {
    //   if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        
    //   }
    // })
    let Data = {
      DATEFROM: this.Dateform,
      TIMETO: this.timeto,
      FORMAT: this.ChronologyADD.FORMAT,
      TIMEFROM: this.timeform,
      DATETO: this.Dateto,
      REFERENCE: this.ChronologyADD.REFERENCE,
      BRIEFPAGENO: this.ChronologyADD.BRIEFPAGENO,
      WITNESSES: this.ChronologyADD.WITNESSES,
      COMMENT: this.ChronologyADD.COMMENT,
      PRIVILEGED: this.ChronologyADD.PRIVILEGED,
      ADDITIONALTEXT: this.ChronologyADD.ADDITIONALTEXT,
      TOPIC: this.ChronologyADD.TOPIC,
      EVENTAGREED: this.ChronologyADD.EVENTAGREED,
      // EVENTAGREED: this.userList,
      DOCUMENTNAME: this.ChronologyADD.DOCUMENTNAME,
      MATTERGUID: this.ChronologyADD.MATTERGUID,
      CHRONOLOGYGUID: this.ChronoGUID,
    }
    if (this.uploadBy == 'device') {
      Data['FILEDATA'] = this.fileToUpload[0];
    } else {
      Data['DOCUMENTGUID'] = this.docGUID;
    }
    let details = { Action: this.FormAction, VALIDATEONLY: true, Data: Data };
    this._mainAPiServiceService.getSetData(details, 'chronology').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isspiner = false;
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });

  }

  /**
   * This function is used to check the validation
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.saveChronodata(details);
    //     }
    //     this.isspiner = false;
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.saveChronodata(details);
    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.saveChronodata(details);
        }
      }
    });
  }

  /**
   * This fumnction is used to save the chronology data
   * @param data -data value
   */
  saveChronodata(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'chronology').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to File Convert 64bit 
   * @param fileInput -file input format data
   */
  FileConvert64bit(fileInput: any) {
    let file64bit = [];
    let filenames = [];
    let files = fileInput.target.files;
    for (let i = 0; i < files.length; i++) {
      filenames.push(files[i].name);
      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        file64bit.push(reader.result);
      };
      reader.onerror = function (error) {
      };
    }
    this.ChronologyADD.DOCUMENTNAME = (filenames.join(', '));
    this.fileToUpload = file64bit;
  }
}
