<div class="popup_main_div close_year_accounts" cdkDrag>
    <div class="popup_title_div">
        <h2 cdkDragHandle mat-dialog-title>
            WIP Cut Of Date
            <!-- <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button> -->
            <mat-icon [mat-dialog-close]="false" aria-label="Close dialog" class="mat-icon-close">close</mat-icon>
        </h2>
        <div class="example-loading-shade" *ngIf="isLoadingResults" style="z-index: 99999;">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
    </div>
    <mat-dialog-content class="mat-typography" style="overflow: hidden !important;">
        <div class="one iner-scroll-class" fxLayout="column" fxLayoutAlign="start">
            <div class="popup_body mt-8">
                <div align="center" class="pb-16">
                    This process will limit users entering work in progress for matters on or before the date entered.
                </div>
                <div align="center" class="pb-16">
                    This includes time entries, activities, sundries and matter expenses.                </div>
                <!-- <div align="center" class="pb-16 mt-4 text-bold">
                    please speak to your accountant for further information on this process
                </div> -->
            </div>
            <div class="popup_body">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" [ngClass]="{'has-error':errorWarningData.Error?.PERIODENDDATE,'has-warning':errorWarningData.Warning?.PERIODENDDATE}">
                        <mat-label>Last Day Of Period</mat-label>
                        <input matInput [matDatepicker]="CalculateUpto" placeholder="Last Day Of Period" name="DATE" (dateChange)="choosedDate('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="CalculateUpto"></mat-datepicker-toggle>
                        <mat-datepicker #CalculateUpto></mat-datepicker>
                    </mat-form-field>
                    <mat-label fxFlex="50" class="pl-12">Last Closed Date : {{lastDate}} </mat-label>
                </div>
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="Current Balances" *ngIf="!isJournalEntry">
                            <div class="item_pad">
                                <div class="example-containerdata">
                                    <ejs-treegrid #treegrid [dataSource]="dataTreeTable" allowSelection='true' childMapping='SUBACCOUNTS' [treeColumnIndex]='0' [selectedRowIndex]="1" [allowPaging]='false' allowResizing='true' [allowSorting]='true'>
                                        <e-columns>
                                            <e-column headerText='Account Name' width='200' textAlign='Left'>
                                                <ng-template #template let-data>
                                                    <label>{{data.ACCOUNTDESC}}  </label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Type' width='80'>
                                                <ng-template #template let-data>
                                                    <label>{{data.ACCOUNTTYPE}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Balance' width='80'>
                                                <ng-template #template let-data>
                                                    <label>{{data.DISPLAYBALANCE}}</label>
                                                </ng-template>
                                            </e-column>
                                        </e-columns>
                                    </ejs-treegrid>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Closing Journal Entry" *ngIf="isJournalEntry">
                            <div class="item_pad">
                                <div class="example-containerdata">
                                    <ejs-treegrid #treegrid2 [dataSource]="closePeriodItem" allowSelection='true' childMapping='SUBACCOUNTS' [treeColumnIndex]='0' [selectedRowIndex]="1" [allowPaging]='false' allowResizing='true' [allowSorting]='true'>
                                        <e-columns>
                                            <e-column headerText='Description' width='200' textAlign='Left'>
                                                <ng-template #template let-data>
                                                    <label>   {{data.DESCRIPTION}} </label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Credit' width='80'>
                                                <ng-template #template let-data>
                                                    <label>{{data.CREDITAMOUNT | toFixedAmount:true}}</label>
                                                </ng-template>
                                            </e-column>
                                            <e-column headerText='Debit' width='80'>
                                                <ng-template #template let-data>
                                                    <label>{{data.DEBITAMOUNT | toFixedAmount:true}} </label>
                                                </ng-template>
                                            </e-column>
                                        </e-columns>
                                    </ejs-treegrid>
                                </div>
                            </div>
                        </mat-tab>


                    </mat-tab-group>
                </div> -->
            </div>
        </div>
        <!-- <div class="popup_body document_generated_main_div" style="width: 100%;margin-top: 15px;">

            <div align="right" class="mt-4 text-bold">
                <button class="mr-4" *ngIf="!isJournalEntry" mat-raised-button mat-button color="primary" (click)="isJournalEntry=!isJournalEntry">Next <mat-icon>keyboard_arrow_right</mat-icon></button>
                <button class="mr-4" *ngIf="isJournalEntry" mat-raised-button mat-button color="primary" (click)="isJournalEntry=!isJournalEntry"><mat-icon>navigate_before</mat-icon> Previous</button>
                <button class="mr-4" *ngIf="isJournalEntry" mat-raised-button mat-button color="primary" [disabled]="isspiner" (click)="PeriodEndDatePeriodEndDate()"> <mat-spinner *ngIf="isspiner" ></mat-spinner><mat-icon>done</mat-icon> Finish</button>
            </div>
        </div> -->
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="popup_ftr">

        <div class="right_popup_btn">
            <button class="mr-4" mat-raised-button mat-button color="accent" [disabled]="isspiner" (click)="PeriodEndDatePeriodEndDate()"> <mat-spinner *ngIf="isspiner" ></mat-spinner> SAVE</button>
            <button mat-raised-button mat-button [mat-dialog-close]="true" color="primary">Cancel</button>
        </div>
    </mat-dialog-actions>
</div>