<div class=" one " fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MatterNo,'has-warning':errorWarningData.Warning?.MatterNo}">
            <mat-label>Court Matter #</mat-label>
            <input matInput formControlName="MatterNo" matTooltip="{{(toolTipList)?toolTipList.MATTERNO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"> <div  *ngIf="testBool && toolTipList.MATTERNO" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERNO?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTERNO.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COURT,'has-warning':errorWarningData.Warning?.COURT}">
            <mat-label>Court</mat-label>
            <input type="text" placeholder="Court" matInput name="COURT" formControlName="COURT" [matAutocomplete]="auto1" matTooltip="{{(toolTipList)?toolTipList.COURT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.COURT" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COURT?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COURT.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
            <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let optionval of CourtData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example pr-4" fxFlex="34" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.DIVISION,'has-warning':errorWarningData.Warning?.DIVISION}">
            <mat-label>Division</mat-label>
            <input type="text" matInput name="DIVISION" formControlName="DIVISION" [matAutocomplete]="auto2" matTooltip="{{(toolTipList)?toolTipList.DIVISION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.DIVISION" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DIVISION?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DIVISION.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let optionval of DivisionData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    </div> -->
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field class="example pr-4" fxFlex="33" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CourtList,'has-warning':errorWarningData.Warning?.CourtList}">
            <mat-label>List</mat-label>
            <input type="text" matInput name="CourtList" formControlName="CourtList" [matAutocomplete]="auto3" matTooltip="{{(toolTipList)?toolTipList.COURTLIST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.COURTLIST" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COURTLIST?.COPYVALUE:'')" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COURTLIST.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let optionval of ListData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example" fxFlex="33" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.REGISTRY,'has-warning':errorWarningData.Warning?.REGISTRY}">
            <mat-label>Registry</mat-label>
            <input type="text" matInput name="REGISTRY" formControlName="REGISTRY" [matAutocomplete]="auto4"  matTooltip="{{(toolTipList)?toolTipList.REGISTRY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.REGISTRY" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REGISTRY?.COPYVALUE:'')" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REGISTRY.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
            <mat-autocomplete #auto4="matAutocomplete">
                <mat-option *ngFor="let optionval of RegistryData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example" fxFlex="34" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ClientType,'has-warning':errorWarningData.Warning?.ClientType}">
            <mat-label>Client Type</mat-label>
            <input type="text" matInput name="ClientType" formControlName="ClientType" [matAutocomplete]="ClientType" matTooltip="{{(toolTipList)?toolTipList.CLIENTSTATUS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-autocomplete #ClientType="matAutocomplete">
                <mat-option *ngFor="let optionval of ClientTypeData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
            <div  *ngIf="testBool && toolTipList.CLIENTSTATUS" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTSTATUS?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLIENTSTATUS.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="currentUserType=='Solicitor'">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.CLIENTNAME,'has-warning':errorWarningData.Warning?.CLIENTNAME}">
            <mat-label>Client Name</mat-label>
            <input matInput formControlName="CLIENTNAME"  matTooltip="{{(toolTipList)?toolTipList.CLIENTSTATUS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.CLIENTNAME" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTNAME?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLIENTNAME.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERTITLEBELOW,'has-warning':errorWarningData.Warning?.MATTERTITLEBELOW}">
            <mat-label> Matter Title Below </mat-label>
            <input matInput formControlName="MATTERTITLEBELOW"  matTooltip="{{(toolTipList)?toolTipList.MATTERTITLEBELOW?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.MATTERTITLEBELOW" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERTITLEBELOW?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTERTITLEBELOW.COPYVALUE:''}}"><mat-icon>file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field class="example pr-4" fxFlex="33" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.COURTBELOW,'has-warning':errorWarningData.Warning?.COURTBELOW}">
            <mat-label> Court Below </mat-label>
            <input type="text" matInput name="COURTBELOW" formControlName="COURTBELOW" [matAutocomplete]="COURTBELOW" matTooltip="{{(toolTipList)?toolTipList.COURTBELOW?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-autocomplete #COURTBELOW="matAutocomplete">
                <mat-option *ngFor="let optionval of CourtBelowData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
            <div  *ngIf="testBool && toolTipList.COURTBELOW" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COURTBELOW?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COURTBELOW.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" [ngClass]="{'has-error':errorWarningData.Error?.CASENUMBERBELOW,'has-warning':errorWarningData.Warning?.CASENUMBERBELOW}">
            <mat-label> Case # Below </mat-label>
            <input matInput formControlName="CASENUMBERBELOW" matTooltip="{{(toolTipList)?toolTipList.CASENUMBERBELOW?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.CASENUMBERBELOW" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CASENUMBERBELOW?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CASENUMBERBELOW.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFHEARINGS,'has-warning':errorWarningData.Warning?.DATEOFHEARINGS}">
            <mat-label>Date Of Hearings</mat-label>
            <input formControlName="DATEOFHEARINGS" matInput matTooltip="{{(toolTipList)?toolTipList.DATEOFHEARINGS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.DATEOFHEARINGS" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFHEARINGS?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFHEARINGS.COPYVALUE:''}}"><mat-icon>file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATERIALDATE,'has-warning':errorWarningData.Warning?.MATERIALDATE}">
            <mat-label>Material Date</mat-label>
            <input (dateInput)="MaterialDateClick('input', $event)" formControlName="MATERIALDATETEXT" (dateChange)="MaterialDateClick('change', $event)" matInput [matDatepicker]="MaterialDate" matTooltip="{{(toolTipList)?toolTipList.MATERIALDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="MaterialDate"></mat-datepicker-toggle>
            <mat-datepicker #MaterialDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.MATERIALDATE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATERIALDATE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATERIALDATE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DECISION,'has-warning':errorWarningData.Warning?.DECISION}">
            <mat-label>Decision Of</mat-label>
            <input matInput formControlName="DECISION" matTooltip="{{(toolTipList)?toolTipList.DECISION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.DECISION" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DECISION?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DECISION.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COSTESTIMATEIFWINEXGST,'has-warning':errorWarningData.Warning?.COSTESTIMATEIFWINEXGST}">
            <mat-label>Cost Estimate If Successful</mat-label>
            <input style="text-align: right;" matInput [value]="matterdetailForm.get('COSTESTIMATEIFWINEXGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." formControlName="COSTESTIMATEIFWINEXGST" [(ngModel)]="COSTESTIMATEIFWINEXGST" (focusout)="calcPE('Successful')"  matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFWINEXGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" [leadZero]="true">
            <div  *ngIf="testBool && toolTipList.COSTESTIMATEIFWINEXGST" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COSTESTIMATEIFWINEXGST?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COSTESTIMATEIFWINEXGST.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CostEstimateIfWinIncGST,'has-warning':errorWarningData.Warning?.CostEstimateIfWinIncGST}">
            <mat-label>Inc-GST</mat-label>
            <input style="text-align: right;" name="Inc-GST" [value]="matterdetailForm.get('CostEstimateIfWinIncGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matInput formControlName="CostEstimateIfWinIncGST" [(ngModel)]="CostEstimateIfWinIncGST"  (focusout)="calcPI('Successful')" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFWININCGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" [leadZero]="true">
            <div  *ngIf="testBool && toolTipList.COSTESTIMATEIFWININCGST" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COSTESTIMATEIFWININCGST?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COSTESTIMATEIFWININCGST.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
            <mat-icon matSuffix class="secondary-text"></mat-icon>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CostEstimateIfFailExGST,'has-warning':errorWarningData.Warning?.CostEstimateIfFailExGST}">
            <mat-label>Cost Estimate If Unsuccessful</mat-label>
            <input style="text-align: right;" matInput [value]="matterdetailForm.get('CostEstimateIfFailExGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." formControlName="CostEstimateIfFailExGST" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFFAILEXGST?.HOVER:''}}" [leadZero]="true"
            [matTooltipPosition]="toolTipPostion" [(ngModel)]="CostEstimateIfFailExGST" (focusout)="calcPE('Unsuccessful')" >
            <div  *ngIf="testBool && toolTipList.COSTESTIMATEIFFAILEXGST" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COSTESTIMATEIFFAILEXGST?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COSTESTIMATEIFFAILEXGST.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CostEstimateIfFailIncGST,'has-warning':errorWarningData.Warning?.CostEstimateIfFailIncGST}">
            <mat-label>Inc-GST</mat-label>
            <input style="text-align: right;" name="Inc-GST2" [value]="matterdetailForm.get('CostEstimateIfFailIncGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matInput formControlName="CostEstimateIfFailIncGST" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFFAILINCGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [(ngModel)]="CostEstimateIfFailIncGST" (focusout)="calcPI('Unsuccessful')" [leadZero]="true">
            <div  *ngIf="testBool && toolTipList.COSTESTIMATEIFFAILINCGST" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COSTESTIMATEIFFAILINCGST?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COSTESTIMATEIFFAILINCGST.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
            <mat-icon matSuffix class="secondary-text"></mat-icon>
        </mat-form-field>
    </div>
</div>