import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-immigration',
    templateUrl: './immigration.component.html',
    styleUrls: ['./immigration.component.scss']
})
export class ImmigrationComponent implements OnInit, OnDestroy {
    immigrationForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData();
    }
    // @Input() matterdetailForm: FormGroup;
    // @Input() errorWarningData: any;

    initFormData() {
        this.immigrationForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERIMMIGRATIONGUID: [''],
            VISATYPE: [''],
            VALUEOFASSETS: ['', {updateOn: 'blur'}],
            VISASTATUS: [''],
            ANTICIPATEDDATEOFENTRY: [''],
            ANTICIPATEDDATEOFENTRYTEXT: [''],
            LODGEMENTDATE: [''],
            LODGEMENTDATETEXT: [''],
            VISAEXPIRYDATE: [''],
            VISAEXPIRYDATETEXT: [''],
            DECISIONDUEDATE: [''],
            DECISIONDUEDATETEXT: [''],
        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.immigrationForm.controls['MATTERGUID'].setValue('')
                this.immigrationForm.controls['MATTERIMMIGRATIONGUID'].setValue('')
                this.immigrationForm.controls['VISATYPE'].setValue('')
                this.immigrationForm.controls['VALUEOFASSETS'].setValue('')
                this.immigrationForm.controls['VISASTATUS'].setValue('')
                this.immigrationForm.controls['ANTICIPATEDDATEOFENTRY'].setValue('')
                this.immigrationForm.controls['ANTICIPATEDDATEOFENTRYTEXT'].setValue('')
                this.immigrationForm.controls['LODGEMENTDATE'].setValue('')
                this.immigrationForm.controls['LODGEMENTDATETEXT'].setValue('')
                this.immigrationForm.controls['VISAEXPIRYDATE'].setValue('')
                this.immigrationForm.controls['VISAEXPIRYDATETEXT'].setValue('')
                this.immigrationForm.controls['DECISIONDUEDATE'].setValue('')
                this.immigrationForm.controls['DECISIONDUEDATETEXT'].setValue('')
            }
        })

        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.immigrationForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Immigration" && result.action == 'setData') {
                    this.Saveimmigration();
                } else if (result.classType == "Immigration" && result.action == 'deleteData') {
                    this.Deleteimmigration();
                }
            }
        });
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-immigration').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterimmigrationData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    if (MatterimmigrationData) {
                        this.immigrationForm.patchValue({
                            MATTERGUID: MatterimmigrationData.MATTERGUID,
                            MATTERIMMIGRATIONGUID: MatterimmigrationData.MATTERIMMIGRATIONGUID,
                            VISATYPE: MatterimmigrationData.VISATYPE,
                            VALUEOFASSETS: MatterimmigrationData.VALUEOFASSETS,
                            VISASTATUS: MatterimmigrationData.VISASTATUS,
                            ANTICIPATEDDATEOFENTRY: MatterimmigrationData.ANTICIPATEDDATEOFENTRY,
                            LODGEMENTDATE: MatterimmigrationData.LODGEMENTDATE,
                            VISAEXPIRYDATE: MatterimmigrationData.VISAEXPIRYDATE,
                            DECISIONDUEDATE: MatterimmigrationData.DECISIONDUEDATE,
                        });
                        if (MatterimmigrationData.ANTICIPATEDDATEOFENTRY) {
                            let TEMPANTICIPATEDDATEOFENTRY = MatterimmigrationData.ANTICIPATEDDATEOFENTRY.split("/");
                            this.immigrationForm.patchValue({
                                ANTICIPATEDDATEOFENTRYTEXT: new Date(TEMPANTICIPATEDDATEOFENTRY[1] + '/' + TEMPANTICIPATEDDATEOFENTRY[0] + '/' + TEMPANTICIPATEDDATEOFENTRY[2]),
                                ANTICIPATEDDATEOFENTRY: MatterimmigrationData.ANTICIPATEDDATEOFENTRY
                            });
                        }
                        if (MatterimmigrationData.LODGEMENTDATE) {
                            let TEMPLODGEMENTDATE = MatterimmigrationData.LODGEMENTDATE.split("/");
                            this.immigrationForm.patchValue({
                                LODGEMENTDATETEXT: new Date(TEMPLODGEMENTDATE[1] + '/' + TEMPLODGEMENTDATE[0] + '/' + TEMPLODGEMENTDATE[2]),
                                LODGEMENTDATE: MatterimmigrationData.LODGEMENTDATE
                            });
                        }
                        if (MatterimmigrationData.VISAEXPIRYDATE) {
                            let TEMPVISAEXPIRYDATE = MatterimmigrationData.VISAEXPIRYDATE.split("/");
                            this.immigrationForm.patchValue({
                                VISAEXPIRYDATETEXT: new Date(TEMPVISAEXPIRYDATE[1] + '/' + TEMPVISAEXPIRYDATE[0] + '/' + TEMPVISAEXPIRYDATE[2]),
                                VISAEXPIRYDATE: MatterimmigrationData.VISAEXPIRYDATE
                            });
                        }
                        if (MatterimmigrationData.DECISIONDUEDATE) {
                            let TEMPDECISIONDUEDATE = MatterimmigrationData.DECISIONDUEDATE.split("/");
                            this.immigrationForm.patchValue({
                                DECISIONDUEDATETEXT: new Date(TEMPDECISIONDUEDATE[1] + '/' + TEMPDECISIONDUEDATE[0] + '/' + TEMPDECISIONDUEDATE[2]),
                                DECISIONDUEDATE: MatterimmigrationData.DECISIONDUEDATE,
                            });
                        }
                    }
                }
            });
        }
    }
    Deleteimmigration() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-immigration').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterimmigrationData = response.DATA.RECORDS[0];
                this.toolTipList = response.DATA.FIELDTIPS;
                let postData = { FormAction: "Delete", Data: { MATTERIMMIGRATIONGUID: MatterimmigrationData.MATTERIMMIGRATIONGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-immigration').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    Saveimmigration() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.immigrationForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERIMMIGRATIONGUID;
        }
        delete formData.ANTICIPATEDDATEOFENTRYTEXT;
        delete formData.LODGEMENTDATETEXT;
        delete formData.VISAEXPIRYDATETEXT;
        delete formData.DECISIONDUEDATETEXT;
        const formAction = (formData.MATTERIMMIGRATIONGUID) ? 'Update' : 'Insert';
        const immigrationData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(immigrationData, 'matter-immigration').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, immigrationData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, immigrationData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, immigrationData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveimmigrationAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveimmigrationAfterVAlidation(details);

        }
    }
    SaveimmigrationAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-immigration').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }




    AnticipatedEntryClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.immigrationForm.controls['ANTICIPATEDDATEOFENTRY'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    LodgementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.immigrationForm.controls['LODGEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    VisaExpiryDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.immigrationForm.controls['VISAEXPIRYDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    DecisionDueDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.immigrationForm.controls['DECISIONDUEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();

    }

}
