import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-email-attachment-popup',
  templateUrl: './email-attachment-popup.component.html',
  styleUrls: ['./email-attachment-popup.component.scss']
})
export class EmailAttachmentPopupComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<EmailAttachmentPopupComponent>,
  ) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
