// ej2 Calander
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from 'src/app/_guards';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { CreateDiaryComponent } from './create-diary/create-diary.component';
import { DairyDailogComponent } from './dairy-dailog/dairy-dailog.component';
import { DetailsComponent } from './dairy-dailog/details/details.component';
import { DiaryComponent } from './diary.component';
import { DiaryService } from './diary.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EventFormComponent } from './event-form/event-form.component';
import { FuseConfirmDialogModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { PipesModule } from '@_pipes/pipes.module';
import { RecurrancePatternComponent } from './dairy-dailog/recurrance-pattern/recurrance-pattern.component';
import { RouterModule, Routes } from '@angular/router';
import { ScheduleModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    {
        path: '',
        component: DiaryComponent,
        data: {
            title: 'Diary'
        },
        children: [], resolve: {
            chat: DiaryService
        }, canActivate: [AuthGuard]
    },
    {
        path: 'diary/create-diary', component: CreateDiaryComponent,
        data: {
            title: 'Create Diary'
        }, canActivate: [AuthGuard]
    }
];
// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "right",
//   allowNegative: true,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$",
//   suffix: "",
//   thousands: ",",
//   nullable: false,
//   inputMode: CurrencyMaskInputMode.NATURAL,
// };
@NgModule({
    declarations: [
        DiaryComponent,
        CreateDiaryComponent,
        EventFormComponent,
        DairyDailogComponent,

        DetailsComponent,
        RecurrancePatternComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatButtonModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatTableModule,
        SatDatepickerModule, SatNativeDateModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        DragDropModule,
        ApplicationPipesModule,
        MatAutocompleteModule,
        PipesModule,
        MatChipsModule,


        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ColorPickerModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
        MatSortModule,
        NgxCurrencyModule,

        // ej2 Calander
        ScheduleModule
    ],
    exports: [
        DiaryComponent
    ],
    providers: [
        DiaryService,
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        //{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],

    entryComponents: [
        EventFormComponent,
        DairyDailogComponent
    ],
})
export class DiaryModule { }
