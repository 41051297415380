<div class="sort_main_sidebar">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
        <h3 class="one"><strong class="x"> Document Thumbnail</strong></h3>
    </div>
    <!-- / SIDEBAR HEADER -->
    <!-- SIDEBAR CONTENT -->
    <div class="p-16">
        <div class="content" fusePerfectScrollbar>

            <div class="file-details text-center" [@animate]="{value:'*',params:{delay:'200ms'}}">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <img src="{{thumbnailData?.THUMBNAILURL}}" class="img-width doc-thumbnail" *ngIf='!isLoadingResults'>
            </div>

        </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>