<button mat-raised-button color="primary" type="button" id="trustMoneyRefersh"
    (click)="trustMoneyRefersh();">Refresh</button>
    <ng-container *ngIf="SORT$ | async"></ng-container>
    <ng-container *ngIf="TMoneyFilter$ | async"></ng-container>
    <ng-container *ngIf="APICALL$ | async"></ng-container>
    <ng-container *ngIf="COLOR$ | async "></ng-container>
    <ng-container *ngIf="HyperlinkColor$ | async "></ng-container>

<div id="TrustMoney" class="page-layout simple left-sidebar inner-sidebar inner-scroll"
    [ngClass]="COLOR">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto matter-side-div">
                <form name="form" [formGroup]="TrustMoneyForm" class="align-center display-flex filter-menuwrap form-div-side"
                    id="filter-form-menu-wrap">
                    <div class="align-center display-flex flex-wrap filter-menublock">
                        <div class="add-filterwrap">
                            <!-- button -->
                            <button mat-button [matMenuTriggerFor]="dummyButton" [disabled]="true"
                                style="display: none;">
                                Filters</button>
                            <mat-menu #dummyButton="matMenu">
                            </mat-menu>
                            <!-- ends here ~ button -->
                            <button mat-button [matMenuTriggerFor]="menuFilterdata" class="filter-button"
                            
                                (click)="backToFilter('optional-Discount');showFiltersList=true;"><img
                                    [src]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'assets/icons/filter-icon.png':'assets/icons/filter-icon-barrister.png'"
                                    alt="Filter" class="filter-icon" />
                                    <span  >Filters</span></button>

                            <mat-icon class="icon-btn close-btn" (click)="resetFilter()"
                                *ngIf="optionalFilters?.length > 0">close</mat-icon>
                            <mat-icon class="icon-btn" (click)="trustMoneyRefersh()">refresh</mat-icon>

                            <mat-menu #menuFilterdata="matMenu" (click)="preventDefault($event)">
                                <div class="filterscroll">
                                    <ng-container *ngFor="let optionalFilterData of optionalFilterList">
                                        <div *ngIf="optionalFilterData.FILTERTYPE != 'HIDDEN'">
                                            <button class="addbutton"
                                                *ngIf="!optionalFilterData.FILTERAPPLY && showFiltersList"
                                                [ngClass]="COLOR"
                                                (click)="preventDefault($event);activeCurrentFilter('optional-Discount',optionalFilterData);addNewFilter(activeOptionalFilter);showFiltersList=false;">
                                                <mat-icon>add_circle</mat-icon>{{optionalFilterData.DESCRIPTION}}
                                            </button>
                                            <div class="menu-submenu" id="optional-Discount">
                                                <h5 class="filter-title" *ngIf="activeOptionalFilter">
                                                    <mat-icon class="back-icon"
                                                        (click)="backToFilter('optional-Discount');preventDefault($event);showFiltersList=true;">arrow_back_ios
                                                    </mat-icon>Filter by {{activeOptionalFilter.DESCRIPTION}}
                                                </h5>
                                                <div *ngIf="activeOptionalFilter">
                                                    <!-- Filter List Need to Apply -->
                                                    <ng-container formArrayName="FILTERSLIST">
                                                        <div *ngFor="let filter of _filterList; let j =index;"
                                                            class="position-relative">
                                                            <ng-container [formGroupName]="j">
                                                                <!-- <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5> -->
                                                                <mat-form-field appearance="outline" class="width-full"
                                                                    (click)="preventDefault($event)">
                                                                    <mat-select
                                                                        (selectionChange)="optionalDateFilter($event , filter , 'default' , -1)"
                                                                        name="OPERATION"
                                                                        [(ngModel)]="addFilter.OPERATION"
                                                                        formControlName="OPERATION" #operationFilter>
                                                                        <mat-option
                                                                            *ngFor="let d of filter.DATEFILTERLIST "
                                                                            [value]="d.value">{{d.description}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                                <div class="display-flex align-center"
                                                                    *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                    <span class="back-tick">
                                                                        <img src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow">
                                                                    </span>
                                                                    <mat-form-field class="width-full"
                                                                        appearance="outline"
                                                                        *ngIf="filter.FILTERTYPE == 'NUMBER'"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput placeholder="" name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE" required
                                                                            appNumberMask>
                                                                    </mat-form-field>

                                                                    <mat-form-field class="width-full"
                                                                        appearance="outline"
                                                                        *ngIf="filter.FILTERTYPE == 'CURRENCY'"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput placeholder="" name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE" required
                                                                            currencyMask>
                                                                    </mat-form-field>
                                                                </div>

                                                                <div class="display-flex align-center"
                                                                    *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                                    <span class="back-tick">
                                                                        <img src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow">
                                                                    </span>
                                                                    <mat-form-field class="width-full"
                                                                        appearance="outline"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput placeholder="" name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE" required>
                                                                    </mat-form-field>
                                                                </div>


                                                                <div class="display-flex align-center"
                                                                    *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                    <span class="back-tick">
                                                                        <img src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow">
                                                                    </span>
                                                                    <div class="display-flex align-center"
                                                                        (click)="preventDefault($event)"
                                                                        *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                        <mat-form-field class="set-date"
                                                                            appearance="outline">
                                                                            <input matInput placeholder="" name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="addFilter.VALUE"
                                                                                [(value)]="addFilter.VALUE" required
                                                                                appNumberMask>
                                                                        </mat-form-field>
                                                                        <span class="middle-caption">and</span>
                                                                        <mat-form-field class="set-date"
                                                                            appearance="outline">
                                                                            <input matInput placeholder="" name="VALUE2"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="addFilter.VALUE2"
                                                                                [(value)]="addFilter.VALUE2" required
                                                                                appNumberMask>
                                                                        </mat-form-field>
                                                                    </div>

                                                                    <div class="display-flex align-center"
                                                                        (click)="preventDefault($event)"
                                                                        *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                        <mat-form-field class="set-date"
                                                                            appearance="outline">
                                                                            <input matInput placeholder="" name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="addFilter.VALUE"
                                                                                [(value)]="addFilter.VALUE" required
                                                                                currencyMask>
                                                                        </mat-form-field>
                                                                        <span class="middle-caption">and</span>
                                                                        <mat-form-field class="set-date"
                                                                            appearance="outline">
                                                                            <input matInput placeholder="" name="VALUE2"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="addFilter.VALUE2"
                                                                                [(value)]="addFilter.VALUE2" required
                                                                                currencyMask>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div class="display-flex align-center"
                                                                    *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                    <span class="back-tick">
                                                                        <img src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow">
                                                                    </span>
                                                                    <div class="display-flex align-center">
                                                                        <mat-form-field appearance="outline"
                                                                            class="set-date"
                                                                            (click)="preventDefault($event)">
                                                                            <input matInput [matDatepicker]="picker6"
                                                                                name="VALUE" formControlName="VALUE"
                                                                                [(ngModel)]="addFilter.VALUE"
                                                                                [(value)]="addFilter.VALUE" required>
                                                                            <mat-datepicker-toggle matSuffix
                                                                                [for]="picker6">
                                                                            </mat-datepicker-toggle>
                                                                            <mat-datepicker #picker6></mat-datepicker>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                                <div class="display-flex align-center"
                                                                    *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                    <span class="back-tick">
                                                                        <img src="assets/icons/lefticon.png"
                                                                            alt="Left Arrow">
                                                                    </span>
                                                                    <div class="display-flex align-center field-box"
                                                                        (click)="preventDefault($event)">
                                                                        <mat-form-field class="set-date"
                                                                            appearance="outline">
                                                                            <input matInput placeholder="" name="VALUE"
                                                                                formControlName="VALUE"
                                                                                [(ngModel)]="addFilter.VALUE"
                                                                                [(value)]="addFilter.VALUE" required
                                                                                appNumberMask>
                                                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline"
                                                                            class="set-date">
                                                                            <mat-select [value]="'days'"
                                                                                formControlName="VALUE2"
                                                                                [(ngModel)]="addFilter.VALUE2"
                                                                                [(value)]="addFilter.VALUE2" required>
                                                                                <mat-option
                                                                                    value="days">Days</mat-option>
                                                                                <mat-option value="month">Month
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>


                                                                <div class="display-flex align-center"
                                                                    *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                    <mat-form-field appearance="outline"
                                                                        class="set-date"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput [matDatepicker]="picker"
                                                                            #StartDate name="VALUE"
                                                                            formControlName="VALUE"
                                                                            [(ngModel)]="addFilter.VALUE"
                                                                            [(value)]="addFilter.VALUE" required>
                                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker #picker></mat-datepicker>
                                                                    </mat-form-field>
                                                                    <span class="middle-caption">and</span>
                                                                    <mat-form-field appearance="outline"
                                                                        class="set-date"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput [matDatepicker]="picker2"
                                                                            #EndDate name="VALUE2"
                                                                            formControlName="VALUE2"
                                                                            [(ngModel)]="addFilter.VALUE2"
                                                                            [(value)]="addFilter.VALUE2" required>
                                                                        <mat-datepicker-toggle matSuffix
                                                                            [for]="picker2">
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker #picker2></mat-datepicker>
                                                                    </mat-form-field>
                                                                </div>

                                                                <button mat-raised-button color="primary"
                                                                    class="width-full accent"
                                                                    (click)="addfilter(filter , operationFilter, $event)">Apply</button>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-menu>
                        </div>

                        <div class="position-relative">
                            <mat-icon class="badge-ic"
                                (click)="(activeFilters && activeFilters.daterange)?clearFilter('daterange' , 'Date Range'):false">
                                {{(activeFilters && activeFilters.daterange)?'cancel_circle' : 'add_circle'}}
                            </mat-icon>
                            <button mat-button [matMenuTriggerFor]="menucard" class="buttonwith-text dotted-button">

                                Date Range<span class="filter-applytext display-flex align-center">{{(activeFilters &&
                                    activeFilters.daterange)?activeFilters.daterange : ''}}<mat-icon
                                        class="rightdownarrow">
                                        keyboard_arrow_down</mat-icon></span>
                            </button>
                            <mat-menu #menucard="matMenu" #menu>
                                <h5 class="filter-title">Filter by Date Range</h5>
                                <div class="display-flex align-center">
                                    <span class="back-tick">
                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                    </span>
                                    <div class="display-flex align-center" (click)="preventDefault($event)">
                                        <mat-form-field appearance="outline" class="example dateinput">
                                            <input matInput [satDatepicker]="picker2" formControlName="DateRange"
                                                (dateChange)="Filterconfig($event,'DATERANGE')">
                                            <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
                                            <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button mat-raised-button color="primary" class="width-full accent"
                                    (click)="DateRange1()">Apply</button>
                            </mat-menu>
                        </div>
                        <div class="position-relative">
                            <button mat-button aria-readonly="true" class="buttonwith-text no-icon">
                                Trust Balance<span
                                    class="filter-applytext display-flex align-center">{{TrustMoneyForm.get('TRUSTBALANCE').value
                                    | currency}}
                                </span>
                            </button>
                        </div>
                        <div class="position-relative">
                            <button mat-button aria-readonly="true" class="buttonwith-text no-icon">
                                Last Trust Rec Date<span
                                    class="filter-applytext display-flex align-center">{{TrustMoneyForm.get('LASTRECONCILIATIONDATE').value}}
                                </span>
                            </button>
                        </div>

                        <div class="position-relative">
                            <button mat-button aria-readonly="true" class="buttonwith-text no-icon">
                                Last Trust Rec Balance<span
                                    class="filter-applytext display-flex align-center">{{TrustMoneyForm.get('LASTRECONCILIATIONBALANCE').value
                                    | currency}}
                                </span>
                            </button>
                        </div>


                        <div class="position-relative">

                            <!-- search  -->
                            <mat-icon class="badge-ic" (click)="clearFilter('SearchString' , 'SearchString')">
                                {{(activeFilters && activeFilters.SearchString)?'cancel_circle' : ''}}</mat-icon>

                            <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                Search<span class="filter-applytext display-flex align-center">

                                    <input matInput #searchField
                                        (keyup)="onSearch($event);Filterconfig($event,'SearchString')"
                                        formControlName="Search" style="margin-top: 4px;text-align: left;" tabindex="6">
                                    <mat-icon matSuffix (click)="onSearch('Enter')">search</mat-icon>

                                </span>
                            </button>
    
                        </div>
                        <ng-container formArrayName="FILTERS">
                            <div *ngFor="let filter of optionalFilters; let i =index;" class="position-relative">
                                <ng-container [formGroupName]="i">
                                    <mat-icon class="badge-ic"
                                        (click)="resetOptionalFilter(filter , filter.FILTERTYPE , i)">cancel_circle
                                    </mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menuCreatedDate_i" class="buttonwith-text"
                                        (click)="setFilterUi(filter,i)">
                                        {{filter.DESCRIPTION}}<span
                                            class="filter-applytext display-flex align-center">{{(optionalDateFilterPayload[i]
                                            &&
                                            optionalDateFilterPayload[i]['value'] !==
                                            null)?optionalDateFilterPayload[i]['value'].VALUE : ''}}
                                            {{(optionalDateFilterPayload[i]
                                            && optionalDateFilterPayload[i]['value'] !== null &&
                                            optionalDateFilterPayload[i]['value'].VALUE2 !== '')?' - ' +
                                            optionalDateFilterPayload[i]['value'].VALUE2 : ''}}<mat-icon
                                                class="rightdownarrow">
                                                keyboard_arrow_down</mat-icon></span>
                                    </button>
                                    <mat-menu #menuCreatedDate_i="matMenu">
                                        <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5>
                                        <mat-form-field appearance="outline" class="width-full"
                                            (click)="preventDefault($event)">
                                            <mat-select (selectionChange)="optionalDateFilter($event , filter)"
                                                name="OPERATION" formControlName="OPERATION" #Datefilter>
                                                <mat-option *ngFor="let d of filter.DATEFILTERLIST "
                                                    [value]="d.value">{{d.description}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <div class="display-flex align-center"
                                            *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                            <span class="back-tick">
                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                            </span>
                                            <mat-form-field class="width-full" appearance="outline"
                                                *ngIf="filter.FILTERTYPE == 'NUMBER'" (click)="preventDefault($event)">
                                                <input matInput placeholder="" value="" name="VALUE"
                                                    formControlName="VALUE" appNumberMask required>
                                            </mat-form-field>

                                            <mat-form-field class="width-full" appearance="outline"
                                                *ngIf="filter.FILTERTYPE == 'CURRENCY'"
                                                (click)="preventDefault($event)">
                                                <input matInput placeholder="" value="" name="VALUE"
                                                    formControlName="VALUE" currencyMask required>
                                            </mat-form-field>
                                        </div>

                                        <div class="display-flex align-center"
                                            *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                            <span class="back-tick">
                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                            </span>
                                            <mat-form-field class="width-full" appearance="outline"
                                                (click)="preventDefault($event)">
                                                <input matInput placeholder="" value="" name="VALUE"
                                                    formControlName="VALUE" required>
                                            </mat-form-field>
                                        </div>


                                        <div class="display-flex align-center"
                                            *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                            <span class="back-tick">
                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                            </span>
                                            <div class="display-flex align-center" (click)="preventDefault($event)"
                                                *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                <mat-form-field class="set-date" appearance="outline">
                                                    <input matInput placeholder="" value="" name="VALUE"
                                                        formControlName="VALUE" appNumberMask required>
                                                </mat-form-field>
                                                <span class="middle-caption">and</span>
                                                <mat-form-field class="set-date" appearance="outline">
                                                    <input matInput placeholder="" value="" name="VALUE2"
                                                        formControlName="VALUE2" appNumberMask required>
                                                </mat-form-field>
                                            </div>

                                            <div class="display-flex align-center" (click)="preventDefault($event)"
                                                *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                <mat-form-field class="set-date" appearance="outline">
                                                    <input matInput placeholder="" value="" name="VALUE"
                                                        formControlName="VALUE" currencyMask required>
                                                </mat-form-field>
                                                <span class="middle-caption">and</span>
                                                <mat-form-field class="set-date" appearance="outline">
                                                    <input matInput placeholder="" value="" name="VALUE2"
                                                        formControlName="VALUE2" currencyMask required>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="display-flex align-center"
                                            *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                            <span class="back-tick">
                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                            </span>
                                            <div class="display-flex align-center">
                                                <mat-form-field appearance="outline" class="set-date"
                                                    (click)="preventDefault($event)">
                                                    <input matInput [matDatepicker]="picker6" name="VALUE"
                                                        formControlName="VALUE" required>
                                                    <mat-datepicker-toggle matSuffix
                                                        [for]="picker6"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker6></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="display-flex align-center"
                                            *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                            <span class="back-tick">
                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                            </span>
                                            <div class="display-flex align-center field-box"
                                                (click)="preventDefault($event)">
                                                <mat-form-field class="set-date" appearance="outline">
                                                    <input matInput placeholder="" value="" name="VALUE"
                                                        formControlName="VALUE" appNumberMask required>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="set-date">
                                                    <mat-select [value]="'days'" formControlName="VALUE2" required>
                                                        <mat-option value="days">Days</mat-option>
                                                        <mat-option value="month">Month </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>


                                        <div class="display-flex align-center"
                                            *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                            <mat-form-field appearance="outline" class="set-date"
                                                (click)="preventDefault($event)">
                                                <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                    formControlName="VALUE" required>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                            <span class="middle-caption">and</span>
                                            <mat-form-field appearance="outline" class="set-date"
                                                (click)="preventDefault($event)">
                                                <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                    formControlName="VALUE2" required>
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="picker2"></mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                

                                        <button mat-raised-button color="primary" class="width-full accent"
                                            (click)="applayOptionalfilter(optionalFilters[i] , i ,Datefilter, $event)">Apply</button>
                                    </mat-menu>
                                </ng-container>
                            </div>
                        </ng-container>

                        
                    </div>
                  

                </form>
            
            <div class="bg-dark rigth-div" id="appCues_trust_Money_window"></div>
        </div>
            <div class="matter-serach-div sticky_search_div_new responsive_auto pb-8"
                *ngIf="(UNBANKEDNOTICEData || UNBANKEDNOTICEData!='')">
                <mat-card>{{UNBANKEDNOTICEData}}</mat-card>
            </div>
            <div class="content fix_div_padding">
                <div id="matter_invoice" class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <div class="btn top_right_action_billing_position dot_controller">
                                    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template matMenuContent let-item="item">
                                            <button mat-menu-item (click)="openDialog()"> Field Configuration</button>
                                            <!-- <button mat-menu-item (click)="trustMoneyRefersh()"> Reload Data</button>
                                          
                                            <button mat-menu-item (click)="DownloadAllData()">
                                                <img src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="height: 20px !important;margin-top: 10px;">
                                                Export to CSV - retrieve all data first
                                            </button>
                                            <button mat-menu-item (click)="downloadFile(TrustMoneyData.data)">
                                                <img src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="height: 20px !important;margin-top: 10px;">
                                                Export to CSV - current data
                                            </button> -->
                                            <button mat-menu-item (click)="getNewExportData()">
                                                <img src="assets/icons/web_app/EXP_CSV.ico"
                                                    style="height: 20px !important;margin-top: 10px;">
                                                Export to CSV
                                            </button>
                                        </ng-template>
                                    </mat-menu>
                                    <button mat-icon-button (click)="IsTrustMoneyList=!IsTrustMoneyList"
                                        aria-label="More">
                                        <mat-icon class="secondary-text">menu</mat-icon>
                                    </button>
                                </div>
                                <div class="content">
                                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div class=" resizable-table" [ngClass]="class==true?'Table-height-tm':'Table-height-tm-2'">
                                        <div class="grid-overlay" *ngIf="isDisplay">
                                            <div class="grid-overlay-msg"> There is no data to display. </div>
                                        </div>
                                        <mat-table #table matSortActive="{{sortactive}}"
                                            matSortDirection="{{sortDirection}}" (matSortChange)="sortData($event)"
                                            matSortDisableClear [dataSource]="TrustMoneyData"
                                            [@animateStagger]="{value:'50'}" matSort class="orange-table">
                                            <ng-container *ngFor="let item of displayedColumns; let i = index"
                                                matColumnDef="{{item}}">
                                                <mat-header-cell *matHeaderCellDef="let i = index"
                                                mat-sort-header
                                                class="header_title"
                                                mwlResizable
                                                enableGhostResize="true"
                                                (resizing)="onResizing($event, item)"
                                                (resizeEnd)="onResizeEnd($event, item)"
                                                [resizeEdges]="{
                                                    bottom: false,
                                                    right: true,
                                                    top: false,
                                                    left: true
                                                 }">
                                                <div
                                                    class="desc_cell resize-handle-right "
                                                    mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>
                                                {{ tempColobj[item].DESCRIPTION }}
                                            </mat-header-cell>
                                                <mat-cell *matCellDef="let row" class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-colum-{{itme}}" [ngClass]="{'word-wrap-class':(tempColobj[item].WIDEDATA)}" [ngStyle]="{'color': '#' + row['ROWCOLOUR']}" [matTooltip]="getToolTipData(row)" matTooltipClass="example-tooltip-font" matTooltipPosition="below"> <span *ngIf="row[item]!==undefined && ((Object.keys(hyperLinkInfo).indexOf(item) == -1) || hyperLinkInfo[item].LINKFIELD == '' || row[hyperLinkInfo[item].LINKFIELD] == undefined || row[hyperLinkInfo[item].LINKFIELD] == '')">
                                                    {{row[item] |
                                                        toFixedAmount:tempColobj[item].CURRENCY |
                                                        wordwrap:tempColobj[item].WIDEDATA |
                                                        multiAmount:tempColobj[item].DESCRIPTION:
                                                        row['MULTIITEM']}}</span>
                                                    <span *ngIf="row[item] && (Object.keys(hyperLinkInfo).indexOf(item) >= 0) && hyperLinkInfo[item].LINKFIELD != '' && row[hyperLinkInfo[item].LINKFIELD] != undefined && row[hyperLinkInfo[item].LINKFIELD] != ''" (click)="selectHyperLink(row,item,hyperLinkInfo[item])" 
                                                        [ngClass]="HyperlinkColor"   [style]="highlightedRows == row.TRUSTTRANSACTIONGUID && currentUserData?.PRODUCTTYPE == 'Solicitor' ? 'color: #43a047 !important' : highlightedRows == row.TRUSTTRANSACTIONGUID && currentUserData?.PRODUCTTYPE == 'Barrister' ? 'color: #4E2083 !important':'' "
                                                        >
                                                        {{row[item]}}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                            </mat-header-row>
                                            <mat-row *matRowDef="let money; columns: displayedColumns;let element;"
                                                (click)="RowClick(money);highlightedRows=money.TRUSTTRANSACTIONGUID"
                                                [style.background]="highlightedRows == money.TRUSTTRANSACTIONGUID ? selectedColore : ''"
                                                [ngClass]="{'row-text-colore':highlightedRows == money.TRUSTTRANSACTIONGUID && !money['ROWCOLOUR'],'has-orange-row': (money.TRANSACTIONTYPE === 'Reversal'),'has-Purple-row':(money.TRANSACTIONTYPE === 'Normal Item' && money.TRUSTTRANSACTIONLINKGUID)}"
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                            </mat-row>
                                            <!-- 'has-red-row':money.TRUSTTRANSACTIONLINKGUID, -->
                                        </mat-table>
                                    </div>
                                </div>
                                <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.trust_money"
                                    (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <fuse-sidebar *ngIf="IsTrustMoneyList" [ngStyle]="{'height': auto,'margin-right':'10px','margin-bottom':'0px'}"
            id="list_sidebar_right_child_detail" class="sidebar final-sidebar list_sidebar_right_child"
            name="final-sidebar" position="right" lockedOpen="gt-md">
            <app-trust-money-multi-details class="list_sidebar_padding"></app-trust-money-multi-details>
        </fuse-sidebar>
    </div>
</div>