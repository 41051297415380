<!-- <ejs-schedule [eventSettings]='eventSettings'> </ejs-schedule> -->
<span class="appCues-blankSpace" style="z-index:9999">
    <a href="javascript:void(0);">Link</a>
</span>
<div id="calendar" class="page-layout simple fullwidth">



    <!-- CONTENT -->
    <div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
        <ejs-schedule *ngIf="setedDiaryViewtype!='Combined'" #scheduleObj width="100%" height="100%" [selectedDate]="selectedDate" class="schedule-cal-custom" (actionComplete)='onActionComplete($event)' [rowAutoHeight]="rowAutoHeight" [timeScale]="TimeScale"
            [currentView]="currentView" (popupOpen)='onPopupOpen($event)' (cellDoubleClick)="onCellDoubleClick($event)" (cellClick)="onCellClick($event)" [eventSettings]="eventSettings" [group]='group' [allowDragAndDrop]="false" (eventRendered)="applyCategoryColor($event)">
            <e-resources>
                <e-resource field="OwnerId" title="Owner" name="Owners" [dataSource]="ownerDataSource" [allowMultiple]="allowMultipleOwner" textField='FULLNAME' idField='USERGUID' colorField="OwnerColor">
                </e-resource>
            </e-resources>
        </ejs-schedule>

        <ejs-schedule *ngIf="setedDiaryViewtype=='Combined'" #scheduleObj width="100%" height="100%" [selectedDate]="selectedDate" class="schedule-cal-custom" (actionComplete)='onActionComplete($event)' [rowAutoHeight]="rowAutoHeight" [timeScale]="TimeScale"
            [currentView]="CombinedcurrentView" (popupOpen)='onPopupOpen($event)' (cellDoubleClick)="onCellDoubleClick($event)" (cellClick)="onCellClick($event)" [eventSettings]="eventSettings" [allowDragAndDrop]="false" (eventRendered)="applyCategoryColor($event)">
            <e-resources>
                <e-resource field="OwnerId" title="Owner" name="Owners" [dataSource]="ownerDataSource" [allowMultiple]="allowMultipleOwner" textField='FULLNAME' idField='USERGUID' colorField="OwnerColor">
                </e-resource>
            </e-resources>
        </ejs-schedule>
        
    </div>

    <!-- / CONTENT -->
</div>