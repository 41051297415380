import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatterPopupComponent } from '../../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';

@Component({
  selector: 'app-conveyancing-concessions-duty',
  templateUrl: './conveyancing-concessions-duty.component.html',
  styleUrls: ['./conveyancing-concessions-duty.component.scss']
})
export class ConveyancingConcessionsDutyComponent implements OnInit, OnDestroy {
  errorWarningData: any = {};
  @Input() MatterConveyancingDetailsForm: FormGroup
  propertyTypeLookupsData: any[];
  sub: Subscription;

  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  
  constructor(private datepipe: DatePipe,
    private _mainAPiServiceService: MainAPiServiceService,
    public dialogRef: MatDialogRef<MatterPopupComponent>,
    public tooltipService : TooltipService
  ) { }

  ngOnInit() {
    let MatterStatusLookups = JSON.parse(localStorage.getItem('propertyType_lookups'));
    if (MatterStatusLookups && MatterStatusLookups != null) {
      this.propertyTypeLookupsData = MatterStatusLookups;
      // this.isLoadingResults = false;
    } else {
      this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',FIlters:{ 'LookupType': 'Property Type' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.propertyTypeLookupsData = responses.DATA.LOOKUPS;
          localStorage.setItem('propertyType_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        } else if (responses.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
        // this.isLoadingResults = false;
      });
    }
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  /**
   * This function is used to get the text data.
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    navigator.clipboard.writeText(event)
  }
   
  /**
   * this function is used to get the common date picker
   */
  CommanDatePickerClick(type: string, event: MatDatepickerInputEvent<Date>, formControlVar) {
    this.MatterConveyancingDetailsForm.controls[formControlVar].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }
}
