<div class="one" fxLayout="column" [formGroup]="contactForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Preferred name</mat-label>
            <input matInput name="SALUTATION" formControlName="SALUTATION">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SALUTATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SALUTATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Position</mat-label>
            <input matInput name="POSITION" formControlName="POSITION">
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSITION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.POSITION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
</div>