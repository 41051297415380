<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div correspond_dailog_main">
    <h2 cdkDragHandle mat-dialog-title>Correspondent Details
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" *ngIf="currentUser.PRODUCTTYPE == 'Solicitor'">
            <form name="correspondForm" id="correspondForm" [formGroup]="correspondForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                   
                    <mat-form-field appearance="outline" class="example" fxFlex="48" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PERSONGUID,'has-Warning':errorWarningData.Warning?.PERSONGUID}">
                        <mat-label>Contact Name</mat-label>
                        <mat-chip-list #chipListContact aria-label="Matter selection">
                            <mat-chip *ngFor="let contact of contactData" (removed)="removeContact(contact)" class="mat-chips-selected">
                                {{contact| slice:0:40}}<ng-container *ngIf="contact && contact.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <!-- [(ngModel)]="selContactName" -->
                            <input  matInput
                                (keydown.Tab)="selectContactTab($event)" 
                                formControlName="PERSONGUIDTEXT" #contactElem [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUp($event)" [ngClass]="contactData.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1" *ngFor="let data of contactList" (click) ="selectContactData(data)">
                                <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="(this.contactElemdata && this.contactElemdata.nativeElement.value =='')? selectContact():null"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>

                        <!-- ends here ~ contact mat chip -->

                    </mat-form-field>
                    <button mat-fab color="primary" class="add-contect-btn" (click)="(this.contactElemdata && this.contactElemdata.nativeElement.value =='')? selectNewContect('Contact Name'):null">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button>

                 
                    <!-- <mat-autocomplete #autoContactList="matAutocomplete">
                        <mat-option class="mat-option_1" *ngFor="let data of contactList" (click) ="selectContactData(data)" value="{{data.CONTACTGUID}}">
                           {{data.CONTACTNAME}}
                        </mat-option>
                    </mat-autocomplete>  -->

                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.SOLICITORGUID,'has-Warning':errorWarningData.Warning?.SOLICITORGUID}">
                    <mat-label>Solicitor Name</mat-label>
                    <mat-chip-list #chipListSilicitor aria-label="Matter selection">
                        <mat-chip *ngFor="let Solicitor of SolicitorData" (removed)="removeSolicitor(Solicitor)" class="mat-chips-selected">
                            {{Solicitor| slice:0:40}}<ng-container *ngIf="Solicitor && Solicitor.length>30">...</ng-container>
                            <button  matChipRemove class="canclebutton">
                                <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <!-- [(ngModel)]="selContactName" -->
                        <input  matInput 
                            (keydown.Tab)="selectSolicitorTab()" 
                            formControlName="SOLICITORGUIDTEXT" #solicitorElem [matAutocomplete]="autoSolicitor"
                            [matChipInputFor]="chipListSilicitor" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addContact($event)" [ngClass]="SolicitorData.length > 0?'hideInput':'showInput'">
                    </mat-chip-list>

                    <mat-autocomplete #autoSolicitor="matAutocomplete">
                        <mat-option class="mat-option_1" *ngFor="let data of solicitorList" (click) ="selectSolicitorDataVAL(data)">
                            <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix (click)="selectSolicitor();">
                        <img src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip=""
                            matTooltipPosition="above">
                    </mat-icon>

                    <!-- ends here ~ contact mat chip -->

                </mat-form-field>
                    
                    <button mat-fab color="primary" class="add-contect-btn" (click)="selectNewContect('Solicitor Name')">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-label class="fs-18" fxFlex="50">Role</mat-label>
                    <mat-label class="fs-18" fxFlex="50">Reference</mat-label>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngFor="let roleerf of correspondRoleCounter; let i=index">
                    <mat-form-field class="example" fxFlex="50" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.TYPE,'has-Warning':errorWarningData.Warning?.TYPE}">
                        <mat-select #value (selectionChange)="changeType($event.value, i)" name="selected" formControlName="TYPE{{i}}">
                            <mat-option *ngFor="let val of CorrspondClassData" [value]="val.LOOKUPFULLVALUE">
                                {{val.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.REFERENCE,'has-Warning':errorWarningData.Warning?.REFERENCE}">
                        <!-- <input matInput formControlName="REFERENCE"> -->
                        <input matInput  formControlName="REFERENCE{{i}}" (change)="changeRef()">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isBanfi=='Billing Recipient'">
                    <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.SHAREOFESTATE,'has-Warning':errorWarningData.Warning?.SHAREOFESTATE}">
                        <mat-label>Billing Percentage</mat-label>
                        <input matInput formControlName="SHAREOFESTATE" type="number">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isBanfi=='Beneficiary'">
                    <mat-form-field class="example" fxFlex="50" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.RELATIONSHIP,'has-Warning':errorWarningData.Warning?.RELATIONSHIP}">
                        <mat-label>Relationship</mat-label>
                        <input matInput formControlName="RELATIONSHIP">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.SHAREOFESTATE,'has-Warning':errorWarningData.Warning?.SHAREOFESTATE}">
                        <mat-label>Percentage </mat-label>
                        <input matInput formControlName="SHAREOFESTATE" type="number">
                    </mat-form-field>
                </div>
            </form>
        </div>

        <!-- This Form use when Product type is Berister -->
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" *ngIf="currentUser.PRODUCTTYPE !== 'Solicitor'">
            <form name="correspondForm" id="correspondForm" [formGroup]="correspondForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field class="example"  fxFlex="33" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.TYPE,'has-Warning':errorWarningData.Warning?.TYPE}">
                        <mat-label>Contact Type</mat-label>
                        <mat-select #value (selectionChange)="changeType($event.value, 0)" name="selected" formControlName="TYPE0">
                            <mat-option *ngFor="let val of CorrspondClassData" [value]="val.LOOKUPFULLVALUE">
                                {{val.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                   
                    <!-- <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PERSONGUID,'has-Warning':errorWarningData.Warning?.PERSONGUID}">
                        <mat-label>Contact Name</mat-label>
                        <input matInput readonly formControlName="PERSONGUIDTEXT">
                        <mat-icon matSuffix (click)="selectContact();">
                            <img src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip=""
                                matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field> -->

                    <mat-form-field appearance="outline" class="example" fxFlex="48" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.PERSONGUID,'has-Warning':errorWarningData.Warning?.PERSONGUID}">
                    <mat-label>Contact Name</mat-label>
                    <mat-chip-list #chipListContact aria-label="Matter selection">
                        <mat-chip *ngFor="let contact of contactData" (removed)="removeContact(contact)" class="mat-chips-selected">
                            {{contact| slice:0:40}}<ng-container *ngIf="contact && contact.length>30">...</ng-container>
                            <button  matChipRemove class="canclebutton">
                                <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <!-- [(ngModel)]="selContactName" -->
                        <input  matInput
                            (keydown.Tab)="selectContactTab($event)" 
                            formControlName="PERSONGUIDTEXT" #contactElem [matAutocomplete]="autoContact"
                            [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUp($event)" [ngClass]="contactData.length > 0?'hideInput':'showInput'">
                    </mat-chip-list>
                    <mat-autocomplete #autoContact="matAutocomplete">
                        <mat-option class="mat-option_1" *ngFor="let data of contactList" (click) ="selectContactData(data)">
                            <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix (click)="(this.contactElemdata && this.contactElemdata.nativeElement.value =='')? selectContact():null"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                        <img src="assets/icons/web_app/icon_contact_new_d.ico">
                    </mat-icon>

                    <!-- ends here ~ contact mat chip -->

                </mat-form-field>





                    <!-- <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.SOLICITORGUID,'has-Warning':errorWarningData.Warning?.SOLICITORGUID}">
                        <mat-label>Solicitor Name</mat-label>
                        <input matInput readonly formControlName="SOLICITORGUIDTEXT">
                        <mat-icon matSuffix (click)="selectSolicitor();">
                            <img src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip=""
                                matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field> -->
               
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.SOLICITORGUID,'has-Warning':errorWarningData.Warning?.SOLICITORGUID}">
                    <mat-label>Solicitor Name</mat-label>
                    <mat-chip-list #chipListSilicitor aria-label="Matter selection">
                        <mat-chip *ngFor="let Solicitor of SolicitorData" (removed)="removeSolicitor(Solicitor)" class="mat-chips-selected">
                            {{Solicitor| slice:0:40}}<ng-container *ngIf="Solicitor && Solicitor.length>30">...</ng-container>
                            <button  matChipRemove class="canclebutton">
                                <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <!-- [(ngModel)]="selContactName" -->
                        <input  matInput 
                            (keydown.Tab)="selectSolicitorTab()" 
                            formControlName="SOLICITORGUIDTEXT" #solicitorElem [matAutocomplete]="autoSolicitor"
                            [matChipInputFor]="chipListSilicitor" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addContact($event)" [ngClass]="SolicitorData.length > 0?'hideInput':'showInput'">
                    </mat-chip-list>

                    <mat-autocomplete #autoSolicitor="matAutocomplete">
                        <mat-option class="mat-option_1" *ngFor="let data of solicitorList" (click) ="selectSolicitorDataVAL(data)">
                            <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix (click)="selectSolicitor();">
                        <img src="assets/icons/web_app/icon_contact_new_d.ico" matTooltip=""
                            matTooltipPosition="above">
                    </mat-icon>

                    <!-- ends here ~ contact mat chip -->

                </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" class="pr-4" fxFlex="100"
                        [ngClass]="{'has-error':errorWarningData.Error?.REFERENCE,'has-Warning':errorWarningData.Warning?.REFERENCE}">
                        <mat-label>Reference</mat-label>
                        <input matInput  formControlName="REFERENCE0" (change)="changeRef()">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="isBanfi=='Billing Recipient'">
                    <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.SHAREOFESTATE,'has-Warning':errorWarningData.Warning?.SHAREOFESTATE}">
                        <mat-label>Billing Percentage</mat-label>
                        <input matInput formControlName="SHAREOFESTATE" type="number">
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" *ngIf="isEdit" [disabled]="isspiner"
            (click)="updateCorrespomndDetail();">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" *ngIf="!isEdit" [disabled]="isspiner"
            (click)="saveCorrespomndDetail();">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>