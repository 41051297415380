import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';

@Component({
  selector: 'app-trust-chart-of-account',
  templateUrl: './trust-chart-of-account.component.html',
  styleUrls: ['./trust-chart-of-account.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class TrustChartOfAccountComponent implements OnInit {
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean = false;

  constructor() { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }
  //FilterSearch
  FilterSearch() {

  }
  //AccountType
  AccountType(value) {
  }
  //Refersh
  refreshturstcAC() {

  }


}
