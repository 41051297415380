import { Component, OnInit, Input } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MainAPiServiceService } from '@_services/main-api-service.service';


@Component({
  selector: 'app-new-field',
  templateUrl: './new-field.component.html',
  styleUrls: ['./new-field.component.scss'],
  animations: fuseAnimations
})
export class NewFieldComponent implements OnInit {
  @Input() SettingForm: FormGroup;
  @Input() errorWarningData: any;
  addData: any = [];
  VarValue: string;
  constructor(private _mainAPiServiceService: MainAPiServiceService,
    public dialogRef: MatDialogRef<NewFieldComponent>) { }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // this._mainAPiServiceService.getSetData({}, 'GetSystem').subscribe(response=>{
    //   this.addData=response.DATA.SYSTEM.ADDRESSGROUP.POSTALADDRESSGROUP
    // })

  }

  /**
   * This function is used to choose the date
   */
  choosedDate() {

  }

  /**
   * This function is used to onChange the Field type
   */
  onChangeFieldType(val) {
    if (val == 'Calculated Field') {
      this.VarValue = "CalculatedF"
    }
  }

  /**
   * Thia function is used to close the popup
   */
  closepopup() {
    this.dialogRef.close(false);
  }

}
