<div class="popup_main_div try_free_set_up_wizard" cdkDrag>
    <div class="popup_title_div">
        <h2 cdkDragHandle mat-dialog-title>
            Set Up Wizard
            <mat-icon [mat-dialog-close]="true">close</mat-icon>
        </h2>
    </div>
    <mat-dialog-content class="mat-typography wizard_main_div">
        <div class="popup_body">
            <div class="popup_slider_wizard">
                <mat-horizontal-stepper linear #stepper>
                    <mat-step>
                        <form>
                            <ng-template matStepLabel><div class="slider_bullet"></div></ng-template>
                            <div class="wizard_body">
                                <mat-form-field appearance="outline" class="full_width_field">
                                    <mat-label>Test</mat-label>
                                    <input class="sideText" matInput >
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full_width_field">
                                    <mat-label>Test</mat-label>
                                    <input class="sideText" matInput >
                                </mat-form-field>
                            </div>
                            <div class="wizard_footer">
                                <button mat-button class="border_ftr_btn">Skip</button>
                                <button mat-button matStepperNext class="fill_ftr_btn">Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel><div class="slider_bullet"></div></ng-template>
                        <div class="wizard_body">
                            <mat-form-field appearance="outline" class="full_width_field">
                                <mat-label>Test</mat-label>
                                <input class="sideText" matInput >
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="full_width_field">
                                <mat-label>Test</mat-label>
                                <input class="sideText" matInput >
                            </mat-form-field>
                        </div>
                        <div class="wizard_footer">
                            <button mat-button matStepperPrevious class="no_border_btn"><mat-icon>keyboard_arrow_left</mat-icon> Back</button>
                            <button mat-button [mat-dialog-close]="true" class="fill_right_btn" (click)="stepper.reset()">Save</button>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </mat-dialog-content>
    <!-- <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <div class="right_btn_child">
                <button mat-raised-button color="primary" mat-button cdkFocusInitial>Save</button>
            </div>
            <div class="right_btn_child">
                <button class="cancel" mat-button [mat-dialog-close]="true" matBadgeColor="accent">Cancel</button>
            </div>
        </div>
    </mat-dialog-actions> -->
</div>