import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommonModule } from '@angular/common';
import { CopyTemplateComponent } from './template-list/copy-template/copy-template.component';
import { CourtFormsComponent } from './court-forms/court-forms.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import { EditTemplateComponent } from './template-list/edit-template/edit-template.component';
import { EmailAttachmentPopupComponent } from './email-templete/email-attachment-popup/email-attachment-popup.component';
import { EmailDailogComponent } from './email-templete/email-dailog/email-dailog.component';
import { EmailTempleteComponent } from './email-templete/email-templete.component';
import { FileDragNDropModule } from '@directives/file-drag-n-drop/file-drag-n-drop.module';
import { FolderCreateComponent } from './template-list/folder-create/folder-create.component';
import { FormatVariableComponent } from './template-list/edit-template/insert-with-formating/format-variable.component';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { GenerateDocumentPopupComponent } from './generate-document-popup/generate-document-popup.component';
import { GeneratePacksPopupComponent } from './packs/generate-packs-popup/generate-packs-popup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatterDialogComponentForTemplate } from './matter-dialog/matter-dialog.component';
import { MatterDocumanrPopupComponent } from './matter-documanr-popup/matter-documanr-popup.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NewFieldComponent } from './template-list/edit-template/new-field/new-field.component';
import { NewPacksDailogComponent } from './packs/new-packs-dailog/new-packs-dailog.component';
import { NgModule } from '@angular/core';
import { PacksComponent } from './packs/packs.component';
import { PacksDailogComponent } from './packs/packs-dailog/packs-dailog.component';
import { PrecedentsComponent } from './precedents/precedents.component';
import { ResizableModule } from 'angular-resizable-element';
import { RichTextEditorAllModule  } from '@syncfusion/ej2-angular-richtexteditor';
import { RouterModule, Routes } from '@angular/router';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { SelectEmailPopupComponent } from './packs/select-email-popup/select-email-popup.component';
import { SendEmailComponent } from './email-templete/send-email/send-email.component';
import { SetLetterHeadComponent } from './template-list/set-letterhead/set-letterhead.component';
import { SignaturesPopupComponent } from '../inbox/signatures-popup/signatures-popup.component';
import { TemplateComponent } from './template.component';
import { TemplateListComponent } from './template-list/template-list.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { TruncatePipe } from './truncate.pipe';
import { MultiInvoicePopupStatusComponent } from './multi-invoice-popup-status/multi-invoice-popup-status.component';
import { LexonFormsComponent } from './lexon-forms/lexon-forms.component';
import { LexonFormAgreementComponent } from './lexon-form-agreement/lexon-form-agreement.component';




/**
 * This is using for set the route JSON
 */
const routes: Routes = [
  { path: '', redirectTo: '/create-document/matter-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/invoice-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/receive-money-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/contact-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/safe-custody-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/invoice-multiple-document', pathMatch: 'full' },

  { path: '', redirectTo: '/create-document/email-matter-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/email-invoice-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/email-contact-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/email-receive-money-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/email-safe-custody-template', pathMatch: 'full' },
  { path: '', redirectTo: '/create-document/email-document-register-template', pathMatch: 'full' },

  { path: '', redirectTo: '/create-document/packs', pathMatch: 'full' },
  {
    path: '', component: TemplateComponent, children: [
      //for template
      {
        path: 'matter-template', component: TemplateListComponent, data: {
          title: 'Matter Template'
        }
      },
      {
        path: 'invoice-template', component: TemplateListComponent, data: {
          title: 'Invoice Template'
        }
      },
      {
        path: 'receive-money-template', component: TemplateListComponent, data: {
          title: 'Receive Money Template'
        }
      },
      {
        path: 'contact-template', component: TemplateListComponent, data: {
          title: 'Contact Template'
        }
      },
      {
        path: 'safe-custody-template', component: TemplateListComponent, data: {
          title: 'Safe Custody Template'
        }
      },
      {
        path: 'invoice-multiple-document', component: TemplateListComponent, data: {
          title: 'invoice-multiple-document'
        }
      },
      {
        path: 'document-register-template', component: TemplateListComponent, data: {
          title: 'Document Template'
        }
      },

      //for email template
      {
        path: 'email-matter-template', component: EmailTempleteComponent, data: {
          title: 'Matter Template'
        }
      },
      {
        path: 'email-invoice-template', component: EmailTempleteComponent, data: {
          title: 'Invoice Template'
        }
      },
      {
        path: 'email-contact-template', component: EmailTempleteComponent, data: {
          title: 'Contact Template'
        }
      },
      {
        path: 'email-receive-money-template', component: EmailTempleteComponent, data: {
          title: 'Receive Money Template'
        }
      },
      {
        path: 'email-safe-custody-template', component: EmailTempleteComponent, data: {
          title: 'Safe Custody Template'
        }
      },
      {
        path: 'email-document-register-template', component: EmailTempleteComponent, data: {
          title: 'Document Register Template'
        }
      },
      {
        path: 'email-invoice-multiple-document', component: EmailTempleteComponent, data: {
          title: 'Invoice Multiple Document'
        }
      },

      // Packs Template

      // { path: 'email-templete', component: EmailTempleteComponent },
      // { path: 'packs', component: PacksComponent },
      {
        path: 'packs-matter-template', component: PacksComponent, data: {
          title: 'Matter Template'
        }
      },
      {
        path: 'packs-invoice-template', component: PacksComponent, data: {
          title: 'Invoice Template'
        }
      },
      {
        path: 'packs-contact-template', component: PacksComponent, data: {
          title: 'Contact Template'
        }
      },
      {
        path: 'packs-receive-money-template', component: PacksComponent, data: {
          title: 'Packs Receive Money'
        }
      },
      {
        path: 'packs-safe-custody-template', component: PacksComponent, data: {
          title: 'Safe Custody Template'
        }
      },
      {
        path: 'packs-document-register-template', component: PacksComponent, data: {
          title: 'Document Register Template'
        }
      },
      {
        path: 'packs-invoice-multiple-document', component: PacksComponent, data: {
          title: 'Invoice Multiple Document'
        }
      },
      // Precedents Template

      {
        path: 'precedents-matter-template', component: PrecedentsComponent, data: {
          title: 'Matter Template'
        }
      },

      // Court Template

      {
        path: 'court-forms-matter-template', component: CourtFormsComponent, data: {
          title: 'Matter Template'
        }
      },
      {
        path: 'court-forms-invoice-template', component: CourtFormsComponent, data: {
          title: 'Invoice Template'
        }
      },
      {
        path: 'court-forms-contact-template', component: CourtFormsComponent, data: {
          title: 'Contact Template'
        }
      },
      {
        path: 'court-forms-receive-money-template', component: CourtFormsComponent, data: {
          title: 'Receive Money Template'
        }
      },
      {
        path: 'court-forms-safe-custody-template', component: CourtFormsComponent, data: {
          title: 'Safe Custody Template'
        }
      },
      {
        path: 'court-forms-document-register-template', component: CourtFormsComponent, data: {
          title: 'Document Register Template'
        }
      },

      {
        path: 'court-invoice-multiple-document', component: CourtFormsComponent, data: {
          title: 'Invoice Multiple Document'
        }
      },
      // Precedents Template
      {
        path: 'precedents-matter-template', component: PrecedentsComponent, data: {
          title: 'Matter Template'
        }
      },
      {
        path: 'precedents-invoice-template', component: PrecedentsComponent, data: {
          title: 'Invoice Template'
        }
      },
      {
        path: 'precedents-contact-template', component: PrecedentsComponent, data: {
          title: 'Contact Template'
        }
      },
      {
        path: 'precedents-receive-money-template', component: PrecedentsComponent, data: {
          title: 'Receive Money Template'
        }
      },
      {
        path: 'precedents-safe-custody-template', component: PrecedentsComponent, data: {
          title: 'Safe Custody Template'
        }
      },
      {
        path: 'precedents-document-register-template', component: PrecedentsComponent, data: {
          title: 'Document Register Template'
        }
      },
      {
        path: 'precedents-invoice-multiple-document', component: PrecedentsComponent, data: {
          title: 'invoice Multiple Document'
        }
      },
      {
        path: 'email-templete', component: EmailTempleteComponent, data: {
          title: 'Email Templete'
        }
      },
      {
        path: 'packs', component: PacksComponent, data: {
          title: 'Packs'
        }
      },
    ], canActivate: [AuthGuard]
  }
];
@NgModule({
  declarations: [
    EditTemplateComponent,
    SetLetterHeadComponent,
    TemplateComponent,
    MatterDialogComponentForTemplate,
    TemplateListComponent,
    EmailTempleteComponent,
    CopyTemplateComponent,
    EmailDailogComponent,
    PacksComponent,
    PacksDailogComponent,
    NewPacksDailogComponent,
    FormatVariableComponent,
    NewFieldComponent,
    CourtFormsComponent,
    SendEmailComponent,
    PrecedentsComponent,
    GenerateDocumentPopupComponent,
    MultiInvoicePopupStatusComponent,
    GeneratePacksPopupComponent,
    SelectEmailPopupComponent,
    FolderCreateComponent,
    EmailAttachmentPopupComponent,
    SignaturesPopupComponent,
    MatterDocumanrPopupComponent,
    TruncatePipe,
    LexonFormsComponent,
    LexonFormAgreementComponent,
    // FileDragNDropDirective,

  ],

  entryComponents: [
    MatterDialogComponentForTemplate,
    EmailDailogComponent,
    PacksDailogComponent,
    SendEmailComponent,
    NewPacksDailogComponent,
    SetLetterHeadComponent,
    EditTemplateComponent,
    CopyTemplateComponent,
    FormatVariableComponent,
    NewFieldComponent,
    GenerateDocumentPopupComponent,
    MultiInvoicePopupStatusComponent,
    GeneratePacksPopupComponent,
    SelectEmailPopupComponent,
    FolderCreateComponent,
    EmailAttachmentPopupComponent,
    SignaturesPopupComponent,
    MatterDocumanrPopupComponent
  ],
  imports: [
    // NgxEditorModule,
    CKEditorModule,
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    // MatRippleModule,
    MatProgressBarModule,
    MatTableModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    DragDropModule,
    SatDatepickerModule, SatNativeDateModule,
    MatCheckboxModule,
    MatSortModule,
    MatTreeModule,
    MatTooltipModule,
    MatChipsModule,
    ResizableModule,
    FileDragNDropModule,
    ApplicationPipesModule,
    DropDownTreeModule,
    MatDividerModule,
    MatListModule,
      MatButtonToggleModule,
      RichTextEditorAllModule
  ],
  exports: [
    TemplateComponent,
    MatTreeModule,
    MatterDocumanrPopupComponent,
    EmailTempleteComponent,
    PacksComponent
  ],
    providers: [TemplateComponent, TruncatePipe ]
})
export class TemplateModule { }
