<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_rate_main" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="MatterRateForm" id="MatterRateForm" [formGroup]="MatterRateForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <mat-error *ngIf="isNotValid">
                    Please select valid user
                </mat-error>
                <div fxLayout="row" *ngIf="apiURL=='user'" class="mat_field_btm_padding" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example pr-4" fxFlex="100" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.MATTERCLASS,'has-warning':errorWarningData.Warning?.MATTERCLASS}">
                        <mat-label>{{labelName}}</mat-label>
                        <mat-select itemTemplate #value name="selected" formControlName="userVal" (ngModelChange)="Classtype($event)" [(value)]="selected" >
                            <mat-select-trigger *ngIf="getSelectedLabel() as selectedLabel">
                                {{selectedLabel.USERID}} - {{selectedLabel.USERNAME}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let item of rateUser;let i=index" [value]="i">

                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <div fxFlex="80" class="select-option-div">
                                        {{item.USERID}} - {{item.USERNAME}}
                                    </div>
                                    <div fxFlex="20" class="select-option-div">
                                        {{item.RATEPERHOUR_GROUP.RATEPERHOUR_1 | toFixedAmount:item.RATEPERHOUR_GROUP.RATEPERHOUR_1 }}
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" *ngIf="apiURL=='activity'" class="mat_field_btm_padding" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example pr-4" fxFlex="100" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.MATTERCLASS,'has-warning':errorWarningData.Warning?.MATTERCLASS}">
                        <mat-label>{{labelName}}</mat-label>
                        <mat-select #value name="selected" formControlName="userVal" (ngModelChange)="ClasstypeActivity($event)">
                            <mat-select-trigger *ngIf="getSelectedActivityLabel() as selectedActivityLabel">
                                {{selectedActivityLabel.DESCRIPTION}} {{selectedActivityLabel.ACTIVITYID}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let item of rateActivity;let i=index" [value]="i">
                                <div fxLayout="row">
                                    <div fxFlex="80" class="select-option-div pr-4">
                                        {{item.DESCRIPTION}} {{item.ACTIVITYID}}
                                    </div>
                                    <div fxFlex="20" class="select-option-div">
                                        {{item.RATEPERUNIT | toFixedAmount:item.RATEPERUNIT }}
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label id="example-radio-group-label" class="rate_tab_label">GST Type</label>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 40px" class="rate_tab_value">
                    <mat-radio-group aria-label="Billing Method" formControlName="GSTTYPE" class="example tab_value_child" fxFlex="100" class="pr-4">
                        <mat-radio-button value="GST Exclusive" [checked]="true">GST Exclusive</mat-radio-button>
                        <mat-radio-button value="GST Inclusive">GST Inclusive </mat-radio-button>
                        <mat-radio-button value="GST Free">GST Free</mat-radio-button>
                    </mat-radio-group>
                </div>
                <!-- <div fxLayout="row" class="mat_field_btm_padding" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="apiURL=='user'">
                    <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                        <mat-label>$ / Hr</mat-label>
                        <input matInput currencyMask formControlName="RATEPERHOUR">
                    </mat-form-field>
                    <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                        <mat-label>$ / Day</mat-label>
                        <input matInput currencyMask formControlName="RATEPERDAY">
                    </mat-form-field>
                </div> -->

                <div fxLayout="row" fxLayoutAlign="start center" class="pt-12" fxFlex="1 0 auto" *ngIf="apiURL=='user'">
                    <div fxFlex="16">
                    </div>
                    <div fxFlex="42" class="text-center">
                        <mat-label>$ / hr</mat-label>
                    </div>
                    <div fxFlex="42" class="text-center">
                        <mat-label>$ / day</mat-label>
                    </div>
                </div>
                <ng-container *ngFor="let rate of rateList; let i = index">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" *ngIf="apiURL=='user'">
                        <div fxFlex="16" class="lable-div">
                            <mat-label> {{rate.LOOKUPFULLVALUE}} </mat-label>
                        </div>
                        <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                            <input matInput style="text-align: right;" [value]="getMonthlyVal(i) | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." formControlName="RATEPERHOUR_{{i+1}}" [leadZero]="true">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                            <input matInput style="text-align: right;" [value]="getYearlyVal(i) | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." formControlName="RATEPERDAY_{{i+1}}" [leadZero]="true">
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" *ngIf="apiURL=='user'">
                    <div fxFlex="16" class="lable-div">
                        <mat-label *ngIf="rateList.length > 0">{{rateList[1].LOOKUPFULLVALUE}}</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_2">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_2">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" *ngIf="apiURL=='user'">
                    <div fxFlex="16" class="lable-div">
                        <mat-label *ngIf="rateList.length > 0">{{rateList[2].LOOKUPFULLVALUE}}</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_3">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_3">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" *ngIf="apiURL=='user'">
                    <div fxFlex="16" class="lable-div">
                        <mat-label *ngIf="rateList.length > 0">{{rateList[3].LOOKUPFULLVALUE}}</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_4">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_4">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" *ngIf="apiURL=='user'">
                    <div fxFlex="16" class="lable-div">
                        <mat-label *ngIf="rateList.length > 0">{{rateList[4].LOOKUPFULLVALUE}}</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_5">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_5">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" *ngIf="apiURL=='user'">
                    <div fxFlex="16" class="lable-div">
                        <mat-label *ngIf="rateList.length > 0">{{rateList[5].LOOKUPFULLVALUE}}</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_6">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_6">
                    </mat-form-field>
                </div> -->












                <div fxLayout="row" class="mat_field_btm_padding" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="apiURL=='activity'">
                    <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                        <mat-label>Rate</mat-label>
                        <input style="text-align: right;" matInput [value]="MatterRateForm.get('RATEPERUNIT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." formControlName="RATEPERUNIT" [leadZero]="true">
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveRates()" [disabled]="isspiner||isNotValid">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>