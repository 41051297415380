import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EnableMfaComponent } from './enable-mfa/enable-mfa.component';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-multi-factor-authentication',
    templateUrl: './multi-factor-authentication.component.html',
    styleUrls: ['./multi-factor-authentication.component.scss']
})
export class MultiFactorAuthenticationComponent implements OnInit, OnDestroy {
    isAuthAllowed: boolean = false;
    isPasswordEnter: boolean = false;
    isSetupKeyGenerator: boolean = false;
    user: any = {};
    account: any = {};
    AWAITING2FA: any
    QrCode: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private globalFunction: GlobalFunctionsService,
        public dialog: MatDialog) {
        this.behaviorService.AWAITING2FA$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            this.AWAITING2FA = res
        })
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to QrCode generate
     */
    QrCodeGenerator() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const data = {
            User: currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: 'Request2FAQRCode',
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isAuthAllowed = true;
                this.QrCode = res.DATA.QRCODE

            }
        })
    }

    /**
     * This function is used to
     */
    SetupKeyCodeGenerator() {
        const data = {
            User: this.currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: 'Request2FASetupKey',
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isSetupKeyGenerator = true;
                this.account = res.DATA

            }
        })
    }

    /**
     * This function is used to Code Submit
     */
    CodeSubmit() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const data = {
            User: currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: "Enable2FA",
            User2FAToken: this.user.generatedCode,
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.toastr.success('Multi Factor Authentication Enabled Successfully')
            }
        })
    }

    /**
     * This unction is used to Open the window in new tab
     */
    openNewtab() {
        const url = '/multi-factor-authentication/enable-mfa'
        // this.globalFunction.goTotab(url, 'MFA')
        let confirmDialogRef = this.dialog.open(EnableMfaComponent, {
            disableClose: true, width: "100%", data: {}
        });
    }
}
