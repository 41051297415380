<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<ng-container>
    <div id="authorisedAccouts" (click)="getAccountList()"></div>
    <div class="menu-item" *ngIf="AccountList?.length >=0">
        <mat-nav-list class="nav-item cursor-pointer" routerLinkActive
            [routerLinkActiveOptions]="{exact: true}"
            [ngClass]="{'cmn_active_tab':activeSubMenu =='bank-feed-dashboard'}"
            >
            <a mat-list-item  [routerLink]="'/bank-feed/bank-feed-dashboard'"
                class="mat-nav-link"
                matTooltipPosition="right">
                <mat-icon>
                    <img src="assets/icons/web_app/icon_reports_d.ico">
                </mat-icon>
                <span class="matter-detail-text"> Dashboard</span>
            </a>
        </mat-nav-list>
<ng-container *ngFor="let data of AccountList">
        <mat-nav-list class="nav-item" *ngIf="data?.ACCOUNTNAME && data?.ACCOUNTNAME !==''" [ngClass]="{'cmn_active_tab':(data.BANKFEEDACCOUNTGUID== UniqueAccountNumber && activeSubMenu !='bank-feed-dashboard')}">
            <a mat-list-item [routerLink]="'/bank-feed/bank-feed-list'" (click)="setActiveClass(data)"
                class="mat-nav-link"
                matTooltipPosition="right">
                <mat-icon>
                    <img src="assets/icons/web_app/icon_estimate_d.ico">
                </mat-icon>
                <span class="matter-detail-text">{{(data?.ACCOUNTNAME && data?.ACCOUNTNAME !=='') ? data?.ACCOUNTNAME :'NO'}}</span>
            </a>
        </mat-nav-list>
</ng-container>        
    </div>
</ng-container>