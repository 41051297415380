import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-settlement-address-popup',
  templateUrl: './settlement-address-popup.component.html',
  styleUrls: ['./settlement-address-popup.component.scss']
})
export class SettlementAddressPopupComponent implements OnInit, OnDestroy {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  matterAddressForm: FormGroup;
  curentMatter: any;
  errorWarningData: any = {};
  MatterPropertyGuid: any = '';
  windowNameId:any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  
  constructor(
    private _formBuilder: FormBuilder,
    public _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public PopupData: any,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    public dialogRefRate: MatDialogRef<SettlementAddressPopupComponent>,
    private behaviorService: BehaviorService) {
    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.curentMatter = result;
    //   }
    // });
    
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
      this.curentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      if (this.curentMatter) {
        // localStorage.setItem('set_active_matters', JSON.stringify(this.curentMatter));
      } else {
        if (result) {
          this.curentMatter = result;
          localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.curentMatter))
        } else {
            this.curentMatter = JSON.parse(localStorage.getItem('set_active_matters'));
            localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.curentMatter))
        }
      }
    });

    localStorage.setItem('istrackid', 'SettlementAddressPopupComponent');
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'SettlementAddressPopupComponent' && result['click'] && result['data']['GetMatterProperty']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'SettlementAddressPopupComponent' && result['click'] && result['data']['matter-property']) {
        this.SaveMatterAddress();
      }
    });
  }

  ngOnInit() {
    this.matterAddressForm = this._formBuilder.group({
      MATTERGUID: [''],
      //Crown Allotment
      CROWNALLOTMENT: [''],
      CROWNBLOCK: [''],
      CROWNSECTION: [''],
      CROWNPORTION: [''],
      CROWNSUBDIVISION: [''],
      CROWNPARISH: [''],
      //Council
      SPI: [''],
      COUNCILPROPERTYNUMBER: [''],
      COUNCIL: [''],
      LOCALITY: [''],
      PARISH: [''],
      //Address
      BUILDINGNAME: [''],
      UNITNUMBER: [''],
      STREETNUMBER: [''],
      STREETNUMBERTO: [''],
      STREETNAME: [''],
      STREETTYPE: [''],
      SUBURB: [''],
      STATE: [''],
      POSTCODE: [''],
      NATUREOFBUILDING: [''],
      //Title
      LOTS: [''],
      PLANTYPE: [''],
      PLANNUMBER: [''],
      LOT2: [''],
      PLANTYPE2: [''],
      PLANNUMBER2: [''],
      LOT3: [''],
      PLANTYPE3: [''],
      PLANNUMBER3: [''],
      LOT4: [''],
      PLANTYPE4: [''],
      PLANNUMBER4: [''],
      LOT5: [''],
      PLANTYPE5: [''],
      PLANNUMBER5: [''],
      LOTSECTION: [''],
      BLOCK: [''],
      STAGENUMBER: [''],
      REDEVELOPMENTNUMBER: [''],
      LOTSTITLE: [''],

      // Parent Title
      PARENTVOLUME: [''],
      PARENTFOLIOID: [''],
      PARENTLOT: [''],
      PARENTPLANTYPE: [''],
      PARENTPLANNUMBER: [''],
      PARENTCROWNALLOTMENT: [''],
      PARENTCROWNBLOCK: [''],
      PARENTCROWNSECTION: [''],
      PARENTCROWNPORTION: [''],

      // Strata
      STRATAPLANNUMBER: [''],
      BYLAWTYPE: [''],
      BYLAWNO: [''],
      SPECIALRESOLUTIONDATE: [''],
      SPECIALRESOLUTIONDATETEXT: [''],
      AGGREGATIONOFENTITLEMENT: [''],
      TOTALUNITS: [''],
    });
    if (this.PopupData.type == "edit") {
      this.getEditData();
    }
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to get the edit data value.
   */
  getEditData() {
    this.isLoadingResults = true;
    let data: any = {
      FormAction: "GetData",
      Filters: {
        MATTERGUID: this.curentMatter.MATTERGUID
      },
    }
    this._mainAPiServiceService.getSetData(data, 'matter-property').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
    
      if (responses.CODE == 200 && responses.STATUS == 'success') {

       if(responses.DATA['RECORDS'][0]){
        let addressData = responses.DATA['RECORDS'][0];
        this.MatterPropertyGuid = addressData.MATTERPROPERTYGUID;
        this.matterAddressForm.patchValue({
          MATTERGUID: addressData.MATTERGUID,
          //Crown Allotment
          CROWNALLOTMENT: addressData.CROWNALLOTMENT,
          CROWNBLOCK: addressData.CROWNBLOCK,
          CROWNSECTION: addressData.CROWNSECTION,
          CROWNPORTION: addressData.CROWNPORTION,
          CROWNSUBDIVISION: addressData.CROWNSUBDIVISION,
          CROWNPARISH: addressData.CROWNPARISH,
          //Council
          SPI: addressData.SPI,
          COUNCILPROPERTYNUMBER: addressData.COUNCILPROPERTYNUMBER,
          COUNCIL: addressData.COUNCIL,
          LOCALITY: addressData.LOCALITY,
          PARISH: addressData.PARISH,
          //Address
          BUILDINGNAME: addressData.BUILDINGNAME,
          UNITNUMBER: addressData.UNITNUMBER,
          STREETNUMBER: addressData.STREETNUMBER,
          STREETNUMBERTO: addressData.STREETNUMBERTO,
          STREETNAME: addressData.STREETNAME,
          STREETTYPE: addressData.STREETTYPE,
          SUBURB: addressData.SUBURB,
          STATE: addressData.STATE,
          POSTCODE: addressData.POSTCODE,
          NATUREOFBUILDING: addressData.NATUREOFBUILDING,
          //Title
          LOTS: addressData.LOTS,
          PLANTYPE: addressData.PLANTYPE,
          PLANNUMBER: addressData.PLANNUMBER,
          LOT2: addressData.LOT2,
          PLANTYPE2: addressData.PLANTYPE2,
          PLANNUMBER2: addressData.PLANNUMBER2,
          LOT3: addressData.LOT3,
          PLANTYPE3: addressData.PLANTYPE3,
          PLANNUMBER3: addressData.PLANNUMBER3,
          LOT4: addressData.LOT4,
          PLANTYPE4: addressData.PLANTYPE4,
          PLANNUMBER4: addressData.PLANNUMBER4,
          LOT5: addressData.LOT5,
          PLANTYPE5: addressData.PLANTYPE5,
          PLANNUMBER5: addressData.PLANNUMBER5,
          LOTSECTION: addressData.LOTSECTION,
          BLOCK: addressData.BLOCK,
          STAGENUMBER: addressData.STAGENUMBER,
          REDEVELOPMENTNUMBER: addressData.REDEVELOPMENTNUMBER,
          // PARENTTITLE
          PARENTVOLUME: addressData.PARENTVOLUME,
          PARENTFOLIOID: addressData.PARENTFOLIOID,
          PARENTLOT: addressData.PARENTLOT,
          PARENTPLANTYPE: addressData.PARENTPLANTYPE,
          PARENTPLANNUMBER: addressData.PARENTPLANNUMBER,
          PARENTCROWNALLOTMENT: addressData.PARENTCROWNALLOTMENT,
          PARENTCROWNBLOCK: addressData.PARENTCROWNBLOCK,
          PARENTCROWNSECTION: addressData.PARENTCROWNSECTION,
          PARENTCROWNPORTION: addressData.PARENTCROWNPORTION,
          // Strata
          STRATAPLANNUMBER: addressData.STRATAPLANNUMBER,
          BYLAWTYPE: addressData.BYLAWTYPE,
          BYLAWNO: addressData.BYLAWNO,
         
          AGGREGATIONOFENTITLEMENT: addressData.AGGREGATIONOFENTITLEMENT,
          TOTALUNITS: addressData.TOTALUNITS,
        });
      
        if(addressData.SPECIALRESOLUTIONDATE){
          let SPECIALRESOLUTIONDate =  addressData.SPECIALRESOLUTIONDATE.split("/");
          this.matterAddressForm.controls['SPECIALRESOLUTIONDATETEXT'].setValue(new Date(SPECIALRESOLUTIONDate[1] + '/' + SPECIALRESOLUTIONDate[0] + '/' + SPECIALRESOLUTIONDate[2]));
          this.matterAddressForm.controls['SPECIALRESOLUTIONDATE'].setValue(addressData.SPECIALRESOLUTIONDATE);
        }
      }
        this.isLoadingResults = false;
      } else {
        this.isLoadingResults = false;
      }
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
  }
  get f() {
    return this.matterAddressForm.controls;
  }

  /**
   * This function is used to Save the matter Address
   */
  SaveMatterAddress() {
    this.isspiner = true;
    let details: any = {
      MATTERGUID: this.curentMatter.MATTERGUID,
      // CROWNALLOTMENTGROUP: {
        CROWNALLOTMENT: this.f.CROWNALLOTMENT.value,
        CROWNBLOCK: this.f.CROWNBLOCK.value,
        CROWNSECTION: this.f.CROWNSECTION.value,
        CROWNPORTION: this.f.CROWNPORTION.value,
        CROWNSUBDIVISION: this.f.CROWNSUBDIVISION.value,
        CROWNPARISH: this.f.CROWNPARISH.value,
      // },
      // PROPERTYADDRESSGROUP: {
        //Council
        SPI: this.f.SPI.value,
        COUNCILPROPERTYNUMBER: this.f.COUNCILPROPERTYNUMBER.value,
        COUNCIL: this.f.COUNCIL.value,
        LOCALITY: this.f.LOCALITY.value,
        PARISH: this.f.PARISH.value,
        //Address
        BUILDINGNAME: this.f.BUILDINGNAME.value,
        UNITNUMBER: this.f.UNITNUMBER.value,
        STREETNUMBER: this.f.STREETNUMBER.value,
        STREETNUMBERTO: this.f.STREETNUMBERTO.value,
        STREETNAME: this.f.STREETNAME.value,
        STREETTYPE: this.f.STREETTYPE.value,
        SUBURB: this.f.SUBURB.value,
        STATE: this.f.STATE.value,
        POSTCODE: this.f.POSTCODE.value,
        NATUREOFBUILDING: this.f.NATUREOFBUILDING.value,
      // },
      //Title
      // LOTPLAN: {
        LOTS: this.f.LOTS.value,
        PLANTYPE: this.f.PLANTYPE.value,
        PLANNUMBER: this.f.PLANNUMBER.value,
        LOT2: this.f.LOT2.value,
        PLANTYPE2: this.f.PLANTYPE2.value,
        PLANNUMBER2: this.f.PLANNUMBER2.value,
        LOT3: this.f.LOT3.value,
        PLANTYPE3: this.f.PLANTYPE3.value,
        PLANNUMBER3: this.f.PLANNUMBER3.value,
        LOT4: this.f.LOT4.value,
        PLANTYPE4: this.f.PLANTYPE4.value,
        PLANNUMBER4: this.f.PLANNUMBER4.value,
        LOT5: this.f.LOT5.value,
        PLANTYPE5: this.f.PLANTYPE5.value,
        PLANNUMBER5: this.f.PLANNUMBER5.value,
        LOTSECTION: this.f.LOTSECTION.value,
        BLOCK: this.f.BLOCK.value,
        STAGENUMBER: this.f.STAGENUMBER.value,
        REDEVELOPMENTNUMBER: this.f.REDEVELOPMENTNUMBER.value,
      // },
      // PARENTTITLE
      // PARENTTITLE: {
        PARENTVOLUME: this.f.PARENTVOLUME.value,
        PARENTFOLIOID: this.f.PARENTFOLIOID.value,
        PARENTLOT: this.f.PARENTLOT.value,
        PARENTPLANTYPE: this.f.PARENTPLANTYPE.value,
        PARENTPLANNUMBER: this.f.PARENTPLANNUMBER.value,
        PARENTCROWNALLOTMENT: this.f.PARENTCROWNALLOTMENT.value,
        PARENTCROWNBLOCK: this.f.PARENTCROWNBLOCK.value,
        PARENTCROWNSECTION: this.f.PARENTCROWNSECTION.value,
        PARENTCROWNPORTION: this.f.PARENTCROWNPORTION.value,

      // },
      // STRATA
      // STRATAGROUP: {
        STRATAPLANNUMBER: this.f.STRATAPLANNUMBER.value,
        BYLAWTYPE: this.f.BYLAWTYPE.value,
        BYLAWNO: this.f.BYLAWNO.value,
        SPECIALRESOLUTIONDATE: this.f.SPECIALRESOLUTIONDATE.value,
        AGGREGATIONOFENTITLEMENT: this.f.AGGREGATIONOFENTITLEMENT.value,
        TOTALUNITS: this.f.TOTALUNITS.value,
      // }
    };
    let formaction = "insert";
    if (this.MatterPropertyGuid != '') {
      formaction = "update";
      details.MATTERPROPERTYGUID = this.MatterPropertyGuid;
    }
    let matterAddressPostData: any = { FORMACTION: formaction, VALIDATEONLY: true, Data: details };
    if (this.PopupData.type != "edit") {
      this.isspiner = false;
      this.dialogRefRate.close(matterAddressPostData);
    } else {
      this._mainAPiServiceService.getSetData(matterAddressPostData, 'matter-property').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        matterAddressPostData.VALIDATEONLY = true;
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.checkValidation(response.DATA.VALIDATIONS, matterAddressPostData);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.checkValidation(response.DATA.VALIDATIONS, matterAddressPostData);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.checkValidation(response.DATA.VALIDATIONS, matterAddressPostData);
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRefRate.close(false);
        }
      }, error => {
        this.toastr.error(error);
        this.isspiner = false;
      });
    }
  }

  /**
   * This function is used to check the Validation
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(value => {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true, width: '100%', data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveAddressData(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveAddressData(details);
    }
  }

  /***
   * This function is used to save the address.
   */
  saveAddressData(data: any) {
    data.VALIDATEONLY = false;

    this._mainAPiServiceService.getSetData(data, 'matter-property').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toastr.success('Address update successfully');
        this.dialogRefRate.close(data.Data);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRefRate.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
      this.dialogRefRate.close(true);
    });
  }

}
