<!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
    {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
  </button> -->
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Fill out your name</ng-template>
        <!-- <mat-form-field>
          <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
        </mat-form-field> -->
        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Fill out your address</ng-template>
        <!-- <mat-form-field>
          <input matInput placeholder="Address" formControlName="secondCtrl" required>
        </mat-form-field> -->
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
            <ng-template matStepLabel>Done</ng-template>
            You are now done.
            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
            </div>
        </form>
    </mat-step>
  </mat-horizontal-stepper>