import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Table } from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { catchError, concatMap, finalize, from, Subject, takeUntil, tap } from 'rxjs';
import { fuseAnimations } from 'src/@fuse/animations';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-template-header-footer',
  templateUrl: './template-header-footer.component.html',
  styleUrls: ['./template-header-footer.component.scss'],
  animations: fuseAnimations,
})
export class TemplateHeaderFooterComponent implements OnInit, OnDestroy {
  isspiner: boolean = false;
  isLoadingResults: boolean = false;
  destinationBlobFiles: any[] = [];
  sourceFile: any;
  fileName: any;
  selectedTemplateFields: any[] = [];
  isPage: number = 0;
  Templatedata: any = [];
  TemplatedataList: any = [];
  parentMessage: any;
  storeFolder: any;
  previousFolder: any;
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;
  goBack: any;
  pushFolder: any = [];
  previousFolder_2: any;
  showFolder: any = "";
  storeFolder_2: any;
  SendgoBack: any = "";
  storeGoBack: any;
  pushFolderList: any = [];
  previousFolder_List: any;
  showFolderList: any = "";
  storeFolder_List: any;
  SendgoBackList: any = "";
  storeGoBackList: any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  selectedColore: string = "rgb(217, 217, 217)";
  highlightedRows: any;
  addListRow: any;
  highlightedRowsList: any;
  displayedColumns: any = ["TEMPLATETYPE", "TEMPLATENAME"];
  private _unsubscribeAll$: Subject<void> = new Subject();
  selectedRow: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService, private behaviorService: BehaviorService,private http: HttpClient, public MatDialog: MatDialog, private tableService: TableColumnsService, public dialogRef: MatDialogRef<TemplateHeaderFooterComponent>,) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
    this.storeFolder = "";
    this.previousFolder = "";
    this.behaviorService.templateSubFolderHF(this.storeFolder);
    this.LoadData({});
  }

  /**
   * This function is used to destroy the session
   */
  ngOnDestroy(): void {
    localStorage.setItem("FolderFH1","")
    localStorage.setItem("FolderFH","")
  }

  /**
   * This function is used to load the data
   */
  LoadData(d) {
    this.isLoadingResults = true;
    this._mainAPiServiceService
        .getSetData(d, "TemplateList")
        .pipe(takeUntil(this._unsubscribeAll$))
        .subscribe(
            (response) => {
              if (response.CODE == 200 && response.STATUS == "success") {
                // this.selectedTemplateFields = response.DATA.TEMPLATES.filter((ele) => ele.TEMPLATETYPE == "Template");
                if (this.pushFolder.length > 0 || this.pushFolderList.length > 0) {
                    response.DATA.TEMPLATES.unshift({
                        TEMPLATENAME: `.. Go up one level`,
                        TEMPLATETYPE: "Go_Back",
                    });
                }
                this.Templatedata = new MatTableDataSource(
                    response.DATA.TEMPLATES
                );
                this.TemplatedataList = new MatTableDataSource(
                    response.DATA.TEMPLATES
                );
                this.clickRow(response.DATA.TEMPLATES[0]);
                // this.Templatedata.paginator = this.paginator;
                // this.Templatedata.sort = this.sort;
                if (response.DATA.TEMPLATES[0]) {
                    this.behaviorService.TemplateGenerateDataHF(
                        response.DATA.TEMPLATES[0]
                    );
                    // localStorage.setItem('contactGuid', response.DATA.CONTACTS[0].CONTACTGUID);
                    this.highlightedRows = response.DATA.TEMPLATES[0].TEMPLATENAME;
                    this.highlightedRowsList = response.DATA.TEMPLATES[0].TEMPLATENAME;
                    // if(this.highlightedRows !=='.. Go up one level'){
                    //   this.behaviorService.generateDocumentPath$.next(this.highlightedRows);
                    // }
                    // this.isDisplay = false;
                }
                // if (response.DATA.TEMPLATES.length == 0) {
                //     this.isDisplay = true;
                // }
                this.isLoadingResults = false;
            } else if (
                response.CODE == 423 &&
                response.STATUS == "error"
            ) {
                this.isLoadingResults = false;
                this.toastr.error(response.MESSAGE);
            }
            },
            (err) => {
                this.toastr.error(err);
                this.isLoadingResults = false;
            }
        );
  }

  /**
   * This function is used to double click on the row
   */
  doubleClick(data, flag?) {
    if(this.isPage === 0) {
        if (data.TEMPLATETYPE == "Folder") {
            this.pushFolderList.push(data.TEMPLATENAME);
            for (let index = 0; index <= this.pushFolderList.length - 1; index++) {
                if (index == 0) {
                    this.storeFolder_List = this.pushFolderList[index];
                    this.previousFolder_List = this.pushFolderList[index];
                } else {
                    this.storeFolder_List =
                        this.previousFolder_List + "/" + this.pushFolderList[index];
                    this.previousFolder_List = this.storeFolder_List;
                }
                this.showFolderList = this.previousFolder_List;
            }
            // this.behaviorService.generateDocumentPath$.next(this.showFolder);
            setTimeout(() => {
                this.LoadData({ Folder: this.storeFolder_List });
                localStorage.setItem("FolderFH1", this.storeFolder_List);
                this.behaviorService.templateSubFolderHF(
                    localStorage.getItem("FolderFH1")
                );
                this.storeFolder_List = "";
                this.previousFolder_List = "";
            }, 500);
        } else if (data.TEMPLATETYPE == "Go_Back") {
            this.pushFolderList.pop(data.TEMPLATENAME);
            for (let index = 0; index <= this.pushFolderList.length - 1; index++) {
                if (index == 0) {
                    this.SendgoBackList = this.pushFolderList[index];
                    this.storeGoBackList = this.pushFolderList[index];
                } else {
                    this.SendgoBackList =
                        this.storeGoBackList + "/" + this.pushFolderList[index];
                    this.storeGoBackList = this.pushFolderList[index];
                }
            }
            this.showFolderList = this.SendgoBackList;
            // this.behaviorService.generateDocumentPath$.next(this.showFolder);
            setTimeout(() => {
                this.LoadData({ Folder: this.SendgoBackList });
                localStorage.setItem("FolderFH1", this.SendgoBackList);
                this.behaviorService.templateSubFolderHF(
                    localStorage.getItem("FolderFH1")
                );
                this.SendgoBackList = "";
                this.storeGoBackList = "";
            }, 500);
        } else if (data.TEMPLATETYPE == "Sub Folder") {
            this.LoadData({ "Sub Folder": data.TEMPLATENAME });
        } else {
            this.behaviorService.templateSubFolderHF(
                localStorage.getItem("FolderFH1")
            );
            // this.openDilog();
        }
    } else {
        if (data.TEMPLATETYPE == "Folder") {
            this.pushFolder.push(data.TEMPLATENAME);
            for (let index = 0; index <= this.pushFolder.length - 1; index++) {
                if (index == 0) {
                    this.storeFolder_2 = this.pushFolder[index];
                    this.previousFolder_2 = this.pushFolder[index];
                } else {
                    this.storeFolder_2 =
                        this.previousFolder_2 + "/" + this.pushFolder[index];
                    this.previousFolder_2 = this.storeFolder_2;
                }
                this.showFolder = this.previousFolder_2;
            }
            // this.behaviorService.generateDocumentPath$.next(this.showFolder);
            setTimeout(() => {
                this.LoadData({ Folder: this.storeFolder_2 });
                localStorage.setItem("FolderFH", this.storeFolder_2);
                this.behaviorService.templateSubFolderHF(
                    localStorage.getItem("FolderFH")
                );
                this.storeFolder_2 = "";
                this.previousFolder_2 = "";
            }, 500);
        } else if (data.TEMPLATETYPE == "Go_Back") {
            this.pushFolder.pop(data.TEMPLATENAME);
            for (let index = 0; index <= this.pushFolder.length - 1; index++) {
                if (index == 0) {
                    this.SendgoBack = this.pushFolder[index];
                    this.storeGoBack = this.pushFolder[index];
                } else {
                    this.SendgoBack =
                        this.storeGoBack + "/" + this.pushFolder[index];
                    this.storeGoBack = this.pushFolder[index];
                }
            }
            this.showFolder = this.SendgoBack;
            // this.behaviorService.generateDocumentPath$.next(this.showFolder);
            setTimeout(() => {
                this.LoadData({ Folder: this.SendgoBack });
                localStorage.setItem("FolderFH", this.SendgoBack);
                this.behaviorService.templateSubFolderHF(
                    localStorage.getItem("FolderFH")
                );
                this.SendgoBack = "";
                this.storeGoBack = "";
            }, 500);
        } else if (data.TEMPLATETYPE == "Sub Folder") {
            this.LoadData({ "Sub Folder": data.TEMPLATENAME });
        } else {
            this.behaviorService.templateSubFolderHF(
                localStorage.getItem("FolderFH")
            );
            // this.openDilog();
        }
    }
  }

  /**
   * This function is used to go to the next
   */
  gotoNext(){
    let fileName;
    let folder;
    this.behaviorService.TemplateGenerateDataHF$.subscribe(result => {
      if (result) {
        fileName = result.TEMPLATENAME;
      }
    })
    this.behaviorService.templateSubFolderHF$.subscribe((result) => {
      if (result) {
        folder = result;
      } else if (result == '') {
        folder = result;
      }
    })
    if(fileName) {
      this.DownloadTemplatesEditHF(fileName, folder)
    }
  }

  /**
   * This function is used to download the Template Edit
   */
  DownloadTemplatesEditHF(fileName: any, folder: any) {
    this.isLoadingResults = true;
    let session_token = localStorage.getItem('session_token');
    // this.SetOldistrackid('open');
    this._mainAPiServiceService.getSetData({ FILENAME: fileName, FOLDER: folder }, 'TemplateDownload').subscribe(response => {
      // this.SetOldistrackid('close');
      if (response.FileUpload_response.CODE == 200 && response.FileUpload_response.STATUS === 'success') {
        const fileExt = this.tableService.getFileExtension(fileName);
        const extension = fileExt == 'docx' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' ;
        const blobFile = this.base64toBlob(response.FileUpload_response.DATA.DOWNLOADFILEBASE64,extension);
        this.sourceFile = blobFile;
        this.fileName = fileName;
        this.isLoadingResults = false;
        this.isPage++;
        this.selectedTemplateFields = [];
        localStorage.setItem("FolderFH1","")
        localStorage.setItem("FolderFH","")
        this.LoadData({});
      } else if (response.FileUpload_response.MESSAGE == 'Not logged in') {
        this.isLoadingResults = false;
        // this.SetOldistrackid('close');
      }
    }, error => {
        this.isLoadingResults = false;
    //   this.SetOldistrackid('close');
      this.toastr.error(error);
    });
  }


  /**
   * This function is used to convert the base64 to blob
   */
  base64toBlob(base64Data: string, contentType = ''): Blob {
    // Remove the base64 header if present
    const byteString = atob(base64Data.split(',')[1] || base64Data);
    
    // Create an array of 8-bit unsigned integers
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    // Convert to a Blob
    return new Blob([byteArray], { type: contentType });
  }

  /**
   * This function is used to go to the previous
   */
  gotoPrevious(){
    this.storeFolder = "";
    this.previousFolder = "";
    this.behaviorService.templateSubFolderHF(this.storeFolder);
    this.isPage--;
    this.selectedTemplateFields = [];
    localStorage.setItem("FolderFH1","")
    localStorage.setItem("FolderFH","")
    this.LoadData({});
  }

  /**
   * This function is used to add the list
   */
  addList(row){
    this.addListRow = row;
  }

  /**
   * This function is used to add to the list
   */
  addToList(){
    const isAdd = this.selectedTemplateFields.filter((ele) => ele.TEMPLATETYPE == this.addListRow.TEMPLATETYPE && ele.TEMPLATENAME == this.addListRow.TEMPLATENAME)
    if(isAdd.length == 0 && this.addListRow.TEMPLATETYPE == "Template"){
        this.selectedTemplateFields.push({TEMPLATENAME: this.addListRow.TEMPLATENAME, TEMPLATETYPE: this.addListRow.TEMPLATETYPE, Folder: localStorage.getItem("FolderFH")});
    }
  }

  /**
   * This  function is used to add all to the list
   */
  addAllToList(){
    this.Templatedata.data.forEach(element => {
        // const isAdd = this.selectedTemplateFields.includes(element.TEMPLATENAME);
        // if(!isAdd && element.TEMPLATETYPE == "Template"){
        //     this.selectedTemplateFields.push(element.TEMPLATENAME);
        // }
        const isAdd = this.selectedTemplateFields.filter((ele) => ele.TEMPLATETYPE == element.TEMPLATETYPE && ele.TEMPLATENAME == element.TEMPLATENAME)
        if(isAdd.length == 0 && element.TEMPLATETYPE == "Template"){
            this.selectedTemplateFields.push({TEMPLATENAME: element.TEMPLATENAME, TEMPLATETYPE: element.TEMPLATETYPE, Folder: localStorage.getItem("FolderFH")});
        }
    });
  }


  /**
   * This function is used to remove from the list
   */
  removeFromList(){
    const index = this.selectedTemplateFields.indexOf(this.selectedRow);
    if (index > -1) {
        this.selectedTemplateFields.splice(index, 1);
    }
  }


  /**
   * This function is used to select the row
   */
  selectRow(row){
    this.selectedRow = row;
  }


  /**
   * This function is used to click the row
   */
  clickRow(Row: any) {
    this.behaviorService.TemplateGenerateDataHF(Row);
    this.parentMessage = Row;
    // this.currentMatterData = Row;
  }

  /**
   * This function is used to set the letter head
   */
  setLetterHead(){
    this.destinationBlobFiles = [];
    this.isLoadingResults = true;
    from(this.selectedTemplateFields).pipe(
        concatMap((ele) => 
            this._mainAPiServiceService.getSetData(
                { FILENAME: ele.TEMPLATENAME, FOLDER: ele.Folder },
                'TemplateDownload'
            ).pipe(
                tap(response => {
                    if (response.FileUpload_response.CODE == 200 && response.FileUpload_response.STATUS === 'success') {
                        const fileExt = this.tableService.getFileExtension(ele.TEMPLATENAME);
                        const extension = fileExt == 'docx' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' ;
                        const blobFile = this.base64toBlob(response.FileUpload_response.DATA.DOWNLOADFILEBASE64,extension);
                        // this.destinationBlobFiles.push(blobFile);
                        this.destinationBlobFiles.push({FILENAME: ele.TEMPLATENAME, FOLDER: ele.Folder, blob: blobFile});
                    } else if (response.FileUpload_response.MESSAGE === 'Not logged in') {
                        throw new Error('Not logged in'); // Exit early if not logged in
                    }
                }),
                catchError(error => {
                    this.toastr.error(error);
                    throw error; // rethrow to stop the sequence
                })
            )
        ),
        finalize(() => {
            this.isLoadingResults = false; // Ensure this is reset at the end
        })
    ).subscribe({
        next: () => {}, // handle successful completion if necessary
        error: () => { this.isLoadingResults = false; }, // Handle errors globally
        complete: () => {
          setTimeout(() => {
            this.letterHeadAPI(this.destinationBlobFiles);
          }, 1000);
        }
    });
  }

  /**
   * This function is used to set the letter head
   */
  letterHeadAPI(files){
    this.isLoadingResults = true;
      from(files).pipe(
        concatMap((element: any) => {
          let formData = new FormData();
          formData.append('SourceFile', this.sourceFile, this.fileName);
          formData.append('DestinationFile', element.blob, element.FILENAME);
          // https://word.apitest.silq.com.au/api/documenteditor/HeaderMerge
          return this.http.post("https://word.apitest.silq.com.au/api/documenteditor/HeaderMerge", formData).pipe(
            tap((response: any) => {
              if(response.code == 200 && response.status == "Success") {
                setTimeout(() => {
                  this.templateUpload(element.FILENAME,element.FOLDER,response.data);
                  // var a = document.createElement("a"); //Create <a>
                  // a.href = response.data; //Image Base64 Goes here
                  // a.download = element.FILENAME; //File name Here
                  // a.click(); //Downloaded file
                }, 500);
              } else if(response.code == 400){
                this.toastr.error(response.message);
              }
            }),
            catchError((error) => {
              this.toastr.error(error);
              throw error; // rethrow to stop further execution if needed
            })
          );
        }),
        finalize(() => {
          this.isLoadingResults = false;
        })
      ).subscribe({
        next: () => { /* You can add actions here if needed */ },
        error: () => { /* Handle errors globally if needed */ },
        complete: () => {
          // this.selectedTemplateFields = [];
        }
      });
  }

  /**
   * This function is used to template upload
   */
  templateUpload(template, folder, file){
    this.isLoadingResults = true;
    let details: any = {
      FOLDERNAME: folder,
      FILENAME: template,
      FILEDATA: file,
  };
  let postData: any = {
      FORMACTION: "insert",
      VALIDATEONLY: true,
      Data: details,
  };
  this._mainAPiServiceService
    .getSetData(postData, "TemplateUpload")
    .subscribe(
        (response) => {
            postData.VALIDATEONLY = true;
            if (
                response.CODE == 200 &&
                (response.STATUS == "OK" ||
                    response.STATUS == "success")
            ) {
                this.toastr.success("Document save successfully");
                this.dialogRef.close()
                this.isLoadingResults = false;
            } else if (
                response.CODE == 451 &&
                response.STATUS == "warning"
            ) {
              this.isLoadingResults = false;
                this.checkValidation(
                    response.DATA.VALIDATIONS,
                    postData
                );
            } else if (
                response.CODE == 450 &&
                response.STATUS == "error"
            ) {
              this.isLoadingResults = false;
                this.checkValidation(
                    response.DATA.VALIDATIONS,
                    postData
                );
            }
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastr.error(error);
        }
    );
  }

  /**
   * This function is used to check the validation
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value: {
        VALUEVALID: string;
        ERRORDESCRIPTION: any;
        FIELDNAME: any;
    }) {
        if (value.VALUEVALID == "No") {
            errorData.push(value.ERRORDESCRIPTION);
            tempError[value.FIELDNAME] = value;
        } else if (value.VALUEVALID == "Warning") {
            tempWarning[value.FIELDNAME] = value;
            warningData.push(value.ERRORDESCRIPTION);
        }
    });
    // this.errorWarningData = { Error: tempError, warning: tempWarning };
    if (Object.keys(errorData).length != 0) {
        this.toastr.error(errorData);
    } else if (Object.keys(warningData).length != 0) {
        this.confirmDialogRef = this.MatDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
                data: warningData,
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to Save?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveDocument(details);
            }
            this.confirmDialogRef = null;
        });
    } else if (
        Object.keys(warningData).length == 0 &&
        Object.keys(errorData).length == 0
    ) {
        this.saveDocument(details);
    }
  }

  /**
   * This function is used to save the document
   */
  saveDocument(data: any) {
    this.isLoadingResults = true;
    data.VALIDATEONLY = false;
    this._mainAPiServiceService
      .getSetData(data, "TemplateUpload")
      .subscribe(
        (response) => {
          if (
            response.CODE == 200 &&
            (response.STATUS == "OK" ||
                response.STATUS == "success")
          ) {
              this.isLoadingResults = false;
              this.toastr.success("Document save successfully");
              this.dialogRef.close()
          } else if (
              response.CODE == 451 &&
              response.STATUS == "warning"
          ) {
              this.isLoadingResults = false;
              this.toastr.warning(response.MESSAGE);
          } else if (
              response.CODE == 450 &&
              response.STATUS == "error"
          ) {
            this.isLoadingResults = false;
            this.toastr.error(response.MESSAGE);
          }
      },
      (error) => {
        this.isLoadingResults = false;
        this.toastr.error(error);
      }
    );
  }

}
