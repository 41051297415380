import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-compensation',
    templateUrl: './compensation.component.html',
    styleUrls: ['./compensation.component.scss']
})
export class CompensationComponent implements OnInit, OnDestroy {
    CompensationForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    allToolTip_Msg: any;
    @Input() action: any;
    @Input() testBool;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    APIDataSubcription: Subscription;
    APIDataSubcription_1: Subscription
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        public datepipe: DatePipe,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public _matDialog: MatDialog,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData();
    }

    initFormData() {
        this.CompensationForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCOMPENSATIONGUID: [''],
            ACCIDENTDATE: [''],
            ACCIDENTDATETEXT: [''],
            ACCIDENTDATE2: [''],
            ACCIDENTDATE2TEXT: [''],
            ACCIDENTDATE3: [''],
            ACCIDENTDATE3TEXT: [''],
            ACCIDENTDATE4: [''],
            ACCIDENTDATE4TEXT: [''],
            EXPIRATIONDATE: [''],
            EXPIRATIONDATETEXT: [''],
            DATEOFNOTICEOFINJURY: [''],
            DATEOFNOTICEOFINJURYTEXT: [''],
            PLACEOFINJURY: [''],
            INJURYDESCRIPTION: [''],
            HOWDIDINJURYOCCUR: [''],
            LITIGATIONFUNDER: [''],
            TRANSFERREDFROMOTHERSOLICITOR: [''],
            OTHERSOLICITORNAME: [''],
            ESTIMATEDAWARD: ['', {updateOn: 'blur'}],
            CLAIMNUMBER: [''],
            INVESTIGATIONDATE: [''],
            INVESTIGATIONDATETEXT: [''],
            SETTLEMENTDATE: [''],
            SETTLEMENTDATETEXT: [''],
        });
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.CompensationForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                let ClassData = ["Compensation", "Workers Compensation", "Employment", "Medical Negligence", "Working Injuries Damages", "Public Liability", "Total and Permanent Disability"];
                if (ClassData.includes(result.classType) && result.action == 'setData') {
                    this.SaveCompensation();
                } else if (ClassData.includes(result.classType) && result.action == 'deleteData') {
                    this.DeleteMatterCompensation();
                }
            }
        });
        // if (this.action === 'edit' || this.action === 'duplicate') {
        //   this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID },FIELDTIPS :this.toolTipList}, 'mattercompensation').subscribe(response => {
        //     if (response.CODE == 200 && response.STATUS == 'success') {
        //       this.toolTipList = response.DATA.FIELDTIPS
        //       let MatterCompensationData = response.DATA.RECORDS[0];
        //       if (MatterCompensationData) {
        //         this.CompensationForm.patchValue({
        //           MATTERGUID: MatterCompensationData.MATTERGUID,
        //           MATTERCOMPENSATIONGUID: MatterCompensationData.MATTERCOMPENSATIONGUID,
        //           PLACEOFINJURY: MatterCompensationData.PLACEOFINJURY,
        //           INJURYDESCRIPTION: MatterCompensationData.INJURYDESCRIPTION,
        //           HOWDIDINJURYOCCUR: MatterCompensationData.HOWDIDINJURYOCCUR,
        //           LITIGATIONFUNDER: MatterCompensationData.LITIGATIONFUNDER,
        //           TRANSFERREDFROMOTHERSOLICITOR: MatterCompensationData.TRANSFERREDFROMOTHERSOLICITOR,
        //           OTHERSOLICITORNAME: MatterCompensationData.OTHERSOLICITORNAME,
        //           ESTIMATEDAWARD: MatterCompensationData.ESTIMATEDAWARD,
        //           CLAIMNUMBER: MatterCompensationData.CLAIMNUMBER,
        //         });
        //       }
        //       if (MatterCompensationData.ACCIDENTDATE) {
        //         let TEMPACCIDENTDATE = MatterCompensationData.ACCIDENTDATE.split("/");
        //         this.CompensationForm.patchValue({
        //           ACCIDENTDATETEXT: new Date(TEMPACCIDENTDATE[1] + '/' + TEMPACCIDENTDATE[0] + '/' + TEMPACCIDENTDATE[2]),
        //           ACCIDENTDATE: MatterCompensationData.ACCIDENTDATE
        //         });
        //       }
        //       if (MatterCompensationData.EXPIRATIONDATE) {
        //         let TEMPEXPIRATIONDATE = MatterCompensationData.EXPIRATIONDATE.split("/");
        //         this.CompensationForm.patchValue({
        //           EXPIRATIONDATETEXT: new Date(TEMPEXPIRATIONDATE[1] + '/' + TEMPEXPIRATIONDATE[0] + '/' + TEMPEXPIRATIONDATE[2]),
        //           EXPIRATIONDATE: MatterCompensationData.EXPIRATIONDATE
        //         });
        //       }
        //       if (MatterCompensationData.DATEOFNOTICEOFINJURY) {
        //         let TEMPDATEOFNOTICEOFINJURY = MatterCompensationData.DATEOFNOTICEOFINJURY.split("/");
        //         this.CompensationForm.patchValue({
        //           DATEOFNOTICEOFINJURYTEXT: new Date(TEMPDATEOFNOTICEOFINJURY[1] + '/' + TEMPDATEOFNOTICEOFINJURY[0] + '/' + TEMPDATEOFNOTICEOFINJURY[2]),
        //           DATEOFNOTICEOFINJURY: MatterCompensationData.DATEOFNOTICEOFINJURY
        //         });
        //       }
        //       if (MatterCompensationData.INVESTIGATIONDATE) {
        //         let TEMPINVESTIGATIONDATE = MatterCompensationData.INVESTIGATIONDATE.split("/");
        //         this.CompensationForm.patchValue({
        //           INVESTIGATIONDATETEXT: new Date(TEMPINVESTIGATIONDATE[1] + '/' + TEMPINVESTIGATIONDATE[0] + '/' + TEMPINVESTIGATIONDATE[2]),
        //           INVESTIGATIONDATE: MatterCompensationData.INVESTIGATIONDATE
        //         });
        //       }
        //       if (MatterCompensationData.SETTLEMENTDATE) {
        //         let TEMPSETTLEMENTDATE = MatterCompensationData.SETTLEMENTDATE.split("/");
        //         this.CompensationForm.patchValue({
        //           SETTLEMENTDATETEXT: new Date(TEMPSETTLEMENTDATE[1] + '/' + TEMPSETTLEMENTDATE[0] + '/' + TEMPSETTLEMENTDATE[2]),
        //           SETTLEMENTDATE: MatterCompensationData.SETTLEMENTDATE
        //         });
        //       }
        //     }
        //   });
        // }

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        // this.behaviorService.additionalFieldsValues$.subscribe((data)=>{
        //   if(data == false) {
        //     this.CompensationForm.controls['MATTERGUID'].setValue('');
        //     this.CompensationForm.controls['MATTERCOMPENSATIONGUID'].setValue('');
        //     this.CompensationForm.controls['ACCIDENTDATE'].setValue('');
        //     this.CompensationForm.controls['ACCIDENTDATETEXT'].setValue('');
        //     this.CompensationForm.controls['EXPIRATIONDATE'].setValue('');
        //     this.CompensationForm.controls['EXPIRATIONDATETEXT'].setValue('');
        //     this.CompensationForm.controls['DATEOFNOTICEOFINJURY'].setValue('');
        //     this.CompensationForm.controls['DATEOFNOTICEOFINJURYTEXT'].setValue('');
        //     this.CompensationForm.controls['PLACEOFINJURY'].setValue('');
        //     this.CompensationForm.controls['INJURYDESCRIPTION'].setValue('');
        //     this.CompensationForm.controls['HOWDIDINJURYOCCUR'].setValue('');
        //     this.CompensationForm.controls['LITIGATIONFUNDER'].setValue('');
        //     this.CompensationForm.controls['TRANSFERREDFROMOTHERSOLICITOR'].setValue('');
        //     this.CompensationForm.controls['OTHERSOLICITORNAME'].setValue('');
        //     this.CompensationForm.controls['ESTIMATEDAWARD'].setValue('');
        //     this.CompensationForm.controls['CLAIMNUMBER'].setValue('');
        //     this.CompensationForm.controls['INVESTIGATIONDATE'].setValue('');
        //     this.CompensationForm.controls['INVESTIGATIONDATETEXT'].setValue('');
        //     this.CompensationForm.controls['SETTLEMENTDATE'].setValue('SETTLEMENTDATETEXT');
        //   }
        // })

        if (this.action === 'edit') {
            this.mapCompensationDataToFields();
        }

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.APIDataSubcription_1 = this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == true) {
                this.mapCompensationDataToFields();
            }
        })


    }

    mapCompensationDataToFields() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID }, FIELDTIPS: this.toolTipList }, 'matter-compensation').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                this.toolTipList = response.DATA.FIELDTIPS
                let MatterCompensationData = response.DATA.RECORDS[0];
                if (MatterCompensationData) {
                    this.CompensationForm.patchValue({
                        MATTERGUID: MatterCompensationData.MATTERGUID,
                        MATTERCOMPENSATIONGUID: MatterCompensationData.MATTERCOMPENSATIONGUID,
                        PLACEOFINJURY: MatterCompensationData.PLACEOFINJURY,
                        INJURYDESCRIPTION: MatterCompensationData.INJURYDESCRIPTION,
                        HOWDIDINJURYOCCUR: MatterCompensationData.HOWDIDINJURYOCCUR,
                        LITIGATIONFUNDER: MatterCompensationData.LITIGATIONFUNDER,
                        TRANSFERREDFROMOTHERSOLICITOR: MatterCompensationData.TRANSFERREDFROMOTHERSOLICITOR,
                        OTHERSOLICITORNAME: MatterCompensationData.OTHERSOLICITORNAME,
                        ESTIMATEDAWARD: MatterCompensationData.ESTIMATEDAWARD,
                        CLAIMNUMBER: MatterCompensationData.CLAIMNUMBER,
                    });
                }
                if (MatterCompensationData) {
                    if (MatterCompensationData.ACCIDENTDATE) {
                        let TEMPACCIDENTDATE = MatterCompensationData.ACCIDENTDATE.split("/");
                        this.CompensationForm.patchValue({
                            ACCIDENTDATETEXT: new Date(TEMPACCIDENTDATE[1] + '/' + TEMPACCIDENTDATE[0] + '/' + TEMPACCIDENTDATE[2]),
                            ACCIDENTDATE: MatterCompensationData.ACCIDENTDATE
                        });
                    }
                    if (MatterCompensationData.ACCIDENTDATE2) {
                        let TEMPACCIDENTDATE2 = MatterCompensationData.ACCIDENTDATE2.split("/");
                        this.CompensationForm.patchValue({
                            ACCIDENTDATE2TEXT: new Date(TEMPACCIDENTDATE2[1] + '/' + TEMPACCIDENTDATE2[0] + '/' + TEMPACCIDENTDATE2[2]),
                            ACCIDENTDATE2: MatterCompensationData.ACCIDENTDATE2
                        });
                    }
                    if (MatterCompensationData.ACCIDENTDATE3) {
                        let TEMPACCIDENTDATE3 = MatterCompensationData.ACCIDENTDATE3.split("/");
                        this.CompensationForm.patchValue({
                            ACCIDENTDATE3TEXT: new Date(TEMPACCIDENTDATE3[1] + '/' + TEMPACCIDENTDATE3[0] + '/' + TEMPACCIDENTDATE3[2]),
                            ACCIDENTDATE3: MatterCompensationData.ACCIDENTDATE3
                        });
                    }
                    if (MatterCompensationData.ACCIDENTDATE4) {
                        let TEMPACCIDENTDATE4 = MatterCompensationData.ACCIDENTDATE4.split("/");
                        this.CompensationForm.patchValue({
                            ACCIDENTDATE4TEXT: new Date(TEMPACCIDENTDATE4[1] + '/' + TEMPACCIDENTDATE4[0] + '/' + TEMPACCIDENTDATE4[2]),
                            ACCIDENTDATE4: MatterCompensationData.ACCIDENTDATE4
                        });
                    }
                    if (MatterCompensationData.EXPIRATIONDATE) {
                        let TEMPEXPIRATIONDATE = MatterCompensationData.EXPIRATIONDATE.split("/");
                        this.CompensationForm.patchValue({
                            EXPIRATIONDATETEXT: new Date(TEMPEXPIRATIONDATE[1] + '/' + TEMPEXPIRATIONDATE[0] + '/' + TEMPEXPIRATIONDATE[2]),
                            EXPIRATIONDATE: MatterCompensationData.EXPIRATIONDATE
                        });
                    }
                    if (MatterCompensationData.DATEOFNOTICEOFINJURY) {
                        let TEMPDATEOFNOTICEOFINJURY = MatterCompensationData.DATEOFNOTICEOFINJURY.split("/");
                        this.CompensationForm.patchValue({
                            DATEOFNOTICEOFINJURYTEXT: new Date(TEMPDATEOFNOTICEOFINJURY[1] + '/' + TEMPDATEOFNOTICEOFINJURY[0] + '/' + TEMPDATEOFNOTICEOFINJURY[2]),
                            DATEOFNOTICEOFINJURY: MatterCompensationData.DATEOFNOTICEOFINJURY
                        });
                    }
                    if (MatterCompensationData.INVESTIGATIONDATE) {
                        let TEMPINVESTIGATIONDATE = MatterCompensationData.INVESTIGATIONDATE.split("/");
                        this.CompensationForm.patchValue({
                            INVESTIGATIONDATETEXT: new Date(TEMPINVESTIGATIONDATE[1] + '/' + TEMPINVESTIGATIONDATE[0] + '/' + TEMPINVESTIGATIONDATE[2]),
                            INVESTIGATIONDATE: MatterCompensationData.INVESTIGATIONDATE
                        });
                    }
                    if (MatterCompensationData.SETTLEMENTDATE) {
                        let TEMPSETTLEMENTDATE = MatterCompensationData.SETTLEMENTDATE.split("/");
                        this.CompensationForm.patchValue({
                            SETTLEMENTDATETEXT: new Date(TEMPSETTLEMENTDATE[1] + '/' + TEMPSETTLEMENTDATE[0] + '/' + TEMPSETTLEMENTDATE[2]),
                            SETTLEMENTDATE: MatterCompensationData.SETTLEMENTDATE
                        });
                    }
                }

            }
        });
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }

    SaveCompensation() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.CompensationForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERCOMPENSATIONGUID;
            delete formData.MATTERGUID;
        }
        delete formData.ACCIDENTDATETEXT;
        delete formData.ACCIDENTDATE2TEXT;
        delete formData.ACCIDENTDATE3TEXT;
        delete formData.ACCIDENTDATE4TEXT;
        delete formData.EXPIRATIONDATETEXT;
        delete formData.DATEOFNOTICEOFINJURYTEXT;
        delete formData.INVESTIGATIONDATETEXT;
        delete formData.SETTLEMENTDATETEXT;

        formData.MATTERGUID = this.MatterData.MATTERGUID;
        const formAction = (this.action == 'edit' && formData.MATTERCOMPENSATIONGUID) ? 'Update' : 'Insert';
        const MatterCompensationData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(MatterCompensationData, 'matter-compensation').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCompensationData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCompensationData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCompensationData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveCompensationAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveCompensationAfterVAlidation(details);
        }
    }
    SaveCompensationAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'matter-compensation').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    DeleteMatterCompensation() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-compensation').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterCompensationData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERCOMPENSATIONGUID: MatterCompensationData.MATTERCOMPENSATIONGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-compensation').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

    AccidentDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['ACCIDENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to set the ACCIDENTDATE2 value.
     */
    AccidentDate2Click(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['ACCIDENTDATE2'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the ACCIDENTDATE3 value.
     */
    AccidentDate3Click(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['ACCIDENTDATE3'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the ACCIDENTDATE4 value.
     */
    AccidentDate4Click(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['ACCIDENTDATE4'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    ExpiryDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['EXPIRATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    InvestigationClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['INVESTIGATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    SettlementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['SETTLEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    NoticeofInjuryClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.CompensationForm.controls['DATEOFNOTICEOFINJURY'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }

}
