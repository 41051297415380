import { Directive, ElementRef, Input, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[appTreeview]",
})
export class TreeviewDirective {
    static counter = 0;

    @Input() appProgressBarColor;

    styleEl: HTMLStyleElement = document.createElement("style");

    //generate unique attribule which we will use to minimise the scope of our dynamic style
    // progressBarAttribute = `treeView-${TreeviewDirective.counter++}`;
    // bufferAttribute = `treeView-buffer-${TreeviewDirective.counter++}`;

    // constructor(private el: ElementRef) {
    //   const nativeEl: HTMLElement = this.el.nativeElement;
    //   nativeEl.setAttribute(this.progressBarAttribute,'');
    //   nativeEl.setAttribute(this.bufferAttribute,'');
    //   nativeEl.appendChild(this.styleEl);
    // }

    ngOnChanges(changes: SimpleChanges): void {
        // this.updateColor();
    }

    // updateColor(): void{

    //   this.styleEl.innerText = `
    //     [${this.progressBarAttribute}] .mat-progress-bar-fill::after {
    //       background-color: ${this.appProgressBarColor? this.appProgressBarColor : '#ffff'} !important;
    //     }
    //     [${this.progressBarAttribute}] .mat-progress-bar-buffer{
    //       background-color: #ffff !important;
    //     }
    //   `;
    // }
}
