<div cdkDrag class="popup_main_div delete_main_popup confirm_popup">

    <h1 cdkDragHandle matDialogTitle>Email Attachment</h1>
    <div mat-dialog-content>

        <div>
            SILQ can automatically convert this file to PDF when it is generated.
        </div>

        <div class="mt-12">
            Do you want to convert this to PDF once it is generated?
            <ul class="list-style">
                <li class="list_ui">Generate and attach the final DOCX document.</li>
                <li class="list_ui">Convert the document to PDF and attach the PDF file.</li>
                <li class="list_ui">If it has previously been generated use that file, otherwise generate and attach it to the email.</li>
            </ul>
        </div>
    </div>
    <div mat-dialog-actions class="popup_ftr">

        <div class="right_btn_child">
            <button mat-raised-button color="accent" class="mat-accent" type="button" (click)="dialogRef.close('dotx')">No</button>
            <button mat-raised-button color="accent" class="mat-accent" type="button" (click)="dialogRef.close('pdf')">Yes</button>
            <button mat-raised-button color="accent" class="mat-accent" type="button" (click)="dialogRef.close('orig')">Original</button>
        </div>
    </div>
</div>