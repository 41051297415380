<div class="one rate_tab_main" fxLayout="column">

    <mat-accordion class="faqs-accordion">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="80">
                        <strong>Conveyancing Details</strong>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-matter-conveyancing-expandation  [testBool]='testBool' [action]="action" [classType]='classType' [toolTipList]="toolTipList"></app-matter-conveyancing-expandation>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="80">
                        <strong>Property Details</strong>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-property-details-expandation  [testBool]='testBool' [action]="action" [toolTipList]="toolTipList"></app-property-details-expandation>
        </mat-expansion-panel>
    </mat-accordion>
</div>