<ng-container *ngIf="sub1$ | async"></ng-container>
<ng-container *ngIf="sub2$ | async"></ng-container>
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>Add {{action}} Adjustment
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="AdjustForm" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <!-- <mat-form-field appearance="outline" fxFlex="100" class=" pr-4">
                        <mat-label>Adjustment Date</mat-label>
                        <input [readonly]="!checked" formControlName="ADJUSTMENTDATE" name="adjustmentDate" matInput>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4">
                        <mat-label>Adjustment Date</mat-label>
                        <input [readonly]="!checked" [matDatepicker]="picker" matInput (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="checked"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4">
                        <mat-label>Amount</mat-label>
                        <input [readonly]="!checked" formControlName="AMOUNT" currencyMask name="amount" matInput>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <section fxFlex="100" class=" pr-4" class="example-section">
                        <mat-checkbox name="checked" formControlName="checked" class="example-margin" [(ngModel)]="checked" (ngModelChange)="ManualAdjustmentchanges($event)">Manual Adjustment</mat-checkbox>
                    </section>
                </div>
                <div *ngIf="!checked" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <p fxFlex="100" class=" pr-4">Select the transaction that you want to create an adjustment for.</p>
                </div>
                <div *ngIf="!checked" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <table fxFlex="100" class=" pr-4" mat-table [dataSource]="AdjustList" class="mat-elevation-z8">
                        <ng-container matColumnDef="Cashbook">
                            <th mat-header-cell *matHeaderCellDef> Cashbook </th>
                            <td mat-cell *matCellDef="let element"> {{element.CASHBOOK}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.CASHBOOKID}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Entered">
                            <th mat-header-cell *matHeaderCellDef> Entered </th>
                            <td mat-cell *matCellDef="let element"> {{element.ENTEREDDATE}} </td>
                        </ng-container>
                        <ng-container matColumnDef="transDate">
                            <th mat-header-cell *matHeaderCellDef> Trans Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.TRANSACTIONDATE}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Amount">
                            <th mat-header-cell *matHeaderCellDef> Amount </th>
                            <td mat-cell *matCellDef="let element"> {{element.AMOUNT}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let money; columns: displayedColumns;let element;" class="money" (click)="rowClick(money);highlightedRows=money.ITEMID" [style.background]="highlightedRows == money.ITEMID ? selectedColore : ''" [ngClass]="highlightedRows == money.TRUSTTRANSACTIONGUID ? 'row-text-colore' : ''"
                            matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                        </tr>
                    </table>
                </div>
                <br>
                <div *ngIf="checked" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <p fxFlex="100" class=" pr-4" style="width: min-content;"> A manual adjustment should only be used in rare situation, You will need to call the SILQ support desk for them to enter The password for you to be able to complete the adjustment.</p>
                </div>
                <div *ngIf="checked" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4">
                        <mat-label>Password</mat-label>
                        <input formControlName="PASSWORD" type="password" name="Password" matInput>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class=" pr-4" fxFlex="100">
                        <mat-label>Reason</mat-label>
                        <textarea formControlName="DESCRIPTION" matInput></textarea>
                    </mat-form-field>
                </div>

            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button [disabled]="(AdjustList.length == 0 || isspiner) && !isEnable" mat-raised-button color="accent" (click)="save()" cdkFocusInitial>
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>