import {
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    Output,
    EventEmitter,
    Input,
    OnDestroy,
} from "@angular/core";
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { fuseAnimations } from "src/@fuse/animations";
import * as $ from "jquery";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { ContactSelectDialogComponent } from "../../contact/contact-select-dialog/contact-select-dialog.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ResizeEvent } from "angular-resizable-element";
import { GenerateDocumentPopupComponent } from "../generate-document-popup/generate-document-popup.component";
import { MatterPopupComponent } from "../../matters/matter-popup/matter-popup.component";
import { ContactDialogComponent } from "../../contact/contact-dialog/contact-dialog.component";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { Subject, Subscription, takeUntil } from "rxjs";

@Component({
    selector: "app-template-list",
    templateUrl: "./template-list.component.html",
    styleUrls: ["./template-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class TemplateListComponent implements OnInit, OnDestroy {
    SafeCustodyData: any;
    displayedColumns: any = ["TEMPLATETYPE", "TEMPLATENAME"];
    //displayedColumns: string[];
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    highlightedRows: any;
    currentMatterData: any;
    Templatedata: any = [];
    TemplateGenerateData: any = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    isLoadingResults: boolean;
    isDisplay: boolean = false;
    pageSize: any;
    abc: number;
    isClose: any = true;
    TemplateForm: FormGroup;
    parentMessage: any;
    storeFolder: any;
    previousFolder: any;
    sortingDefaultState: any = {};
    sortactive: any;
    sortDirection: any;
    goBack: any;
    pushFolder: any = [];
    previousFolder_2: any;
    showFolder: any = "";
    storeFolder_2: any;
    SendgoBack: any = "";
    storeGoBack: any;
    @Output() matterDetail: EventEmitter<any> = new EventEmitter<any>();
    @Input() popupurl;
    fileList: any = [];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    errorWarningData: any = {};
    windowNameId: any;
    showfolder: boolean = false;
    appPermissions = JSON.parse(localStorage.getItem("app_permissions"));
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub3: Subscription;
    constructor(
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public MatDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private router: Router,
        public _matDialog: MatDialog,
        private behaviorService: BehaviorService,
        private SortingbehaviorService: SortingBehaviourService
    ) {
        const materIDofTab = window.name.split("_");
        this.windowNameId =
            materIDofTab && materIDofTab[1] ? materIDofTab[1] : undefined;
        localStorage.setItem("istrackid", "TemplateListComponent");
        this.SortingbehaviorService.templateSorting$
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((result) => {
                if (result) {
                    this.sortingDefaultState = result;
                    localStorage.setItem(
                        "template_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingDefaultState = JSON.parse(
                        localStorage.getItem("template_screen_sorting")
                    );
                }
            });
        this.TemplateForm = this._formBuilder.group({
            search: [""],
            matter: [""],
            Client: [""],
        });
        this.behaviorService.APIretryCall$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (
                localStorage.getItem("istrackid") == "TemplateListComponent" &&
                result["click"] &&
                result["data"]["TemplateList"]
            ) {
                this.LoadData({});
            }
        });

        this.behaviorService.MatterData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            const materIDofTab = window.name.split("_");
            this.windowNameId =
                materIDofTab && materIDofTab[1] ? materIDofTab[1] : undefined;
            let mattersData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            if (mattersData) {
                // localStorage.setItem('set_active_matters', JSON.stringify(mattersData));
            } else {
                if (result) {
                    mattersData = result;
                    localStorage.setItem(
                        this.windowNameId || 'set_active_matters',
                        JSON.stringify(mattersData)
                    );
                } else {
                    mattersData = JSON.parse(
                        localStorage.getItem("set_active_matters")
                    );
                    localStorage.setItem(
                        this.windowNameId || 'set_active_matters',
                        JSON.stringify(mattersData)
                    );
                }
            }
        });

        // matter Template popup Search control.
        this.behaviorService.MatterTemplatePopupSearch$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((res) => {
            if (res) {
                if (res.type == "Template") {
                    this.LoadData({
                        SEARCH: res.search,
                        Folder: this.showFolder,
                    });
                }
            }
        });

        this.sub3 = this.behaviorService.MatterTemplatePopupReload$.subscribe((res)=>{
            if(res){
              if(res.type == 'Template'){
                this.refreshTemplatelistTab();
              }
            }
          });
    }

    ngOnInit() {
        this.behaviorService.generateDocumentPath$.next(null);
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "";
            this.sortDirection = "";
        }
        this.storeFolder = "";
        this.previousFolder = "";
        this.behaviorService.templateSubFolder(this.storeFolder);

        let mattersData = JSON.parse(
            localStorage.getItem(
                this.windowNameId ? this.windowNameId : "set_active_matters"
            )
        );

        this.TemplateForm.controls["matter"].setValue(
            mattersData && mattersData.SHORTNAME ? mattersData.SHORTNAME : ""
        );
        this.TemplateForm.controls["Client"].setValue(
            mattersData && mattersData.CONTACTNAME
                ? mattersData.CONTACTNAME
                : ""
        );
        let newSeH = $(".sticky_search_div_new").is(":visible")
            ? $(".sticky_search_div_new").height()
            : 0;
        $(".example-containerdata").css(
            "height",
            $(window).height() -
                ($("#tool_baar_main").height() +
                    $(".sticky_search_div").height() +
                    newSeH +
                    45) +
                "px"
        );
        $(window).resize(() => {
            let newSeH = $(".sticky_search_div_new").is(":visible")
                ? $(".sticky_search_div_new").height()
                : 0;
            $(".example-containerdata").css(
                "height",
                $(window).height() -
                    ($("#tool_baar_main").height() +
                        $(".sticky_search_div").height() +
                        newSeH +
                        100) +
                    "px"
            );
        });
        this.LoadData({});

        this.behaviorService.SafeCustody$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                this.SafeCustodyData = result;
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub3?.unsubscribe();
    }

    /**
     * This function is used to refresh the  Template list tab
     */
    refreshTemplatelistTab() {
        this.LoadData({
            Folder: localStorage.getItem("Folder")
                ? localStorage.getItem("Folder")
                : "",
        });
    }

    /**
     * this function is used to load the data
     */
    LoadData(d) {
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(d, "TemplateList")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        if (this.pushFolder.length > 0) {
                            response.DATA.TEMPLATES.unshift({
                                TEMPLATENAME: `.. Go up one level`,
                                TEMPLATETYPE: "Go_Back",
                            });
                        }
                        this.Templatedata = new MatTableDataSource(
                            response.DATA.TEMPLATES
                        );
                        this.clickRow(response.DATA.TEMPLATES[0]);
                        this.Templatedata.paginator = this.paginator;
                        this.Templatedata.sortingDataAccessor = (
                            item,
                            property
                        ) => {
                            if (
                                "Folder" == item.TEMPLATETYPE ||
                                "Go_Back" == item.TEMPLATETYPE
                            ) {
                                if ("desc" == this.sort.direction)
                                    return "\uFFFF" + "\uFFFF" + "\uFFFF";
                                return "\u0000" + "\u0000" + "\u0000";
                            }
                            return item[property];
                        };
                        this.Templatedata.sort = this.sort;
                        if (response.DATA.TEMPLATES[0]) {
                            this.behaviorService.TemplateGenerateData(
                                response.DATA.TEMPLATES[0]
                            );
                            // localStorage.setItem('contactGuid', response.DATA.CONTACTS[0].CONTACTGUID);
                            this.highlightedRows =
                                response.DATA.TEMPLATES[0].TEMPLATENAME;
                            // if(this.highlightedRows !=='.. Go up one level'){
                            //   this.behaviorService.generateDocumentPath$.next(this.highlightedRows);
                            // }
                            this.isDisplay = false;
                        }
                        if (response.DATA.TEMPLATES.length == 0) {
                            this.isDisplay = true;
                        }
                        this.isLoadingResults = false;
                    } else if (
                        response.CODE == 423 &&
                        response.STATUS == "error"
                    ) {
                        this.isLoadingResults = false;
                        this.toastr.error(response.MESSAGE);
                    }
                },
                (err) => {
                    this.toastr.error(err);
                    this.isLoadingResults = false;
                }
            );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * 
     */
    get f() {
        return this.TemplateForm.controls;
    }

    /**
     * This function is used to sort the data
     */
    sortData(val) {
        this.SortingbehaviorService.templateSorting(val);
    }

    /**
     * This function is used to on search the data
     */
    onSearch(searchFilter: any) {
        if (searchFilter["key"] === "Enter" || searchFilter == "Enter") {
            this.LoadData({ SEARCH: this.f.search.value });
        }
    }

    /**
     * This function is used to click on the row
     */
    clickRow(Row: any) {
        // if(this.showFolder==''){
        //   setTimeout(() => {
        //     if(Row.TEMPLATETYPE=="Folder"){
        //       this.behaviorService.generateDocumentPath$.next(Row.TEMPLATENAME);
        //       }  else{
        //         if(Row.TEMPLATETYPE=='Template'){
        //           this.behaviorService.generateDocumentPath$.next(null);
        //         }
        //       }
        //    }, 1000);
        // }

        this.behaviorService.TemplateGenerateData(Row);
        this.parentMessage = Row;
        // this.matterDetail.emit(Row);
        this.currentMatterData = Row;
    }

    /**
     * This function is used to On paginate change
     */
    onPaginateChange(event) {
        this.pageSize.template_list = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to double click on the row
     */
    doubleClick(data, flag?) {
        if (data.TEMPLATETYPE == "Folder") {
            this.pushFolder.push(data.TEMPLATENAME);
            for (let index = 0; index <= this.pushFolder.length - 1; index++) {
                if (index == 0) {
                    this.storeFolder_2 = this.pushFolder[index];
                    this.previousFolder_2 = this.pushFolder[index];
                } else {
                    this.storeFolder_2 =
                        this.previousFolder_2 + "/" + this.pushFolder[index];
                    this.previousFolder_2 = this.storeFolder_2;
                }
                this.showFolder = this.previousFolder_2;
            }
            this.behaviorService.generateDocumentPath$.next(this.showFolder);
            setTimeout(() => {
                this.LoadData({ Folder: this.storeFolder_2 });
                localStorage.setItem("Folder", this.storeFolder_2);
                this.behaviorService.templateSubFolder(
                    localStorage.getItem("Folder")
                );
                this.storeFolder_2 = "";
                this.previousFolder_2 = "";
            }, 500);
        } else if (data.TEMPLATETYPE == "Go_Back") {
            this.pushFolder.pop(data.TEMPLATENAME);
            for (let index = 0; index <= this.pushFolder.length - 1; index++) {
                if (index == 0) {
                    this.SendgoBack = this.pushFolder[index];
                    this.storeGoBack = this.pushFolder[index];
                } else {
                    this.SendgoBack =
                        this.storeGoBack + "/" + this.pushFolder[index];
                    this.storeGoBack = this.pushFolder[index];
                }
            }
            this.showFolder = this.SendgoBack;
            this.behaviorService.generateDocumentPath$.next(this.showFolder);
            setTimeout(() => {
                this.LoadData({ Folder: this.SendgoBack });
                localStorage.setItem("Folder", this.SendgoBack);
                this.behaviorService.templateSubFolder(
                    localStorage.getItem("Folder")
                );
                this.SendgoBack = "";
                this.storeGoBack = "";
            }, 500);
        } else if (data.TEMPLATETYPE == "Sub Folder") {
            this.LoadData({ "Sub Folder": data.TEMPLATENAME });
        } else {
            this.behaviorService.templateSubFolder(
                localStorage.getItem("Folder")
            );
            this.openDilog();
        }
    }

    /**
     * This function is used to open the dialog
     */
    openDilog() {
        this.behaviorService.TemplateGenerateData$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                this.TemplateGenerateData = result;
            }
        });
        if (
            this.router.url == "/create-document/invoice-template" ||
            this.router.url=="/matter-details/matter-invoices" ||
            this.popupurl == "/create-document/invoice-template"
        ) {
            if (this.router.url == "/invoice/sub-invoices") {
                // code block works on double click template
                try {
                    let passdata;
                    let collectSelectedInvoice = JSON.parse(
                        localStorage.getItem("collectSelectedInvoice")
                    );

                    if (
                        collectSelectedInvoice &&
                        collectSelectedInvoice.length > 0
                    ) {
                        passdata = {
                            Context: "Invoice",
                            ContextGuid: "",
                            knownby: "Template",
                            Type: "Template",
                            Folder: this.showFolder,
                            Template: this.TemplateGenerateData.TEMPLATENAME,
                            isMultipleDocument: true,
                        };
                    } else {
                        let invoiceGUid =
                            localStorage.getItem("edit_invoice_id");
                        passdata = {
                            Context: "Invoice",
                            ContextGuid: invoiceGUid,
                            knownby: "Template",
                            Type: "Template",
                            Folder: this.showFolder,
                            Template: this.TemplateGenerateData.TEMPLATENAME,
                        };
                    }
                    //let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": "Template", "Type": "Template", "Folder": this.storeFolder, "Template": this.TemplateGenerateData.TEMPLATENAME }
                    this.ForDocDialogOpen(passdata);
                } catch (err) {
                    console.log(err);
                }
            } else {
                let invoiceGUid = localStorage.getItem("edit_invoice_id");
                let passdata = {
                    Context: "Invoice",
                    ContextGuid: invoiceGUid,
                    knownby: "Template",
                    Type: "Template",
                    Folder: this.showFolder,
                    Template: this.TemplateGenerateData.TEMPLATENAME,
                };
                // let passdata = { 'Context': "Invoice", 'ContextGuid': invoiceGUid, "knownby": 'Template', "Type": "Template", "Folder": localStorage.getItem("Folder"), "Template": this.TemplateGenerateData.TEMPLATENAME }
                this.ForDocDialogOpen(passdata);
            }
        } else if (
            this.router.url == "/create-document/matter-template" ||
            this.router.url=="/matter-details/receipts-credits" ||
            this.popupurl == "/create-document/matter-template"
        ) {
            if (this.router.url == "/matters") {
                try {
                    let passdata;
                    let collectedSelectedMatter = JSON.parse(
                        localStorage.getItem("collectedSelectedMatter")
                    );
                    if (
                        collectedSelectedMatter &&
                        collectedSelectedMatter.length > 0
                    ) {
                        passdata = {
                            Context: "Matter",
                            ContextGuid: "",
                            knownby: "Template",
                            Type: "Template",
                            Folder: this.showFolder,
                            Template: this.TemplateGenerateData.TEMPLATENAME,
                            isMultipleMatter: true,
                        };
                    } else {
                        let matterData = JSON.parse(
                            localStorage.getItem(
                                this.windowNameId
                                    ? this.windowNameId
                                    : "set_active_matters"
                            )
                        );
                        passdata = {
                            Context: "Matter",
                            ContextGuid: matterData.MATTERGUID,
                            knownby: "Template",
                            Type: "Template",
                            Folder: this.showFolder,
                            Template: this.TemplateGenerateData.TEMPLATENAME,
                            isMultipleMatter: true,
                        };
                    }
                    this.ForDocDialogOpen(passdata);
                } catch (err) {
                    console.log(err);
                }
            } else {
                let matterData = JSON.parse(
                    localStorage.getItem(
                        this.windowNameId
                            ? this.windowNameId
                            : "set_active_matters"
                    )
                );
                let passdata = {
                    Context: "Matter",
                    ContextGuid: matterData.MATTERGUID,
                    knownby: "Template",
                    Type: "Template",
                    Folder: this.showFolder,
                    Template: this.TemplateGenerateData.TEMPLATENAME,
                };
                // let passdata = { 'Context': "Matter", 'ContextGuid': matterData.MATTERGUID, "knownby": 'Template', "Type": "Template", "Folder": localStorage.getItem("Folder"), "Template": this.TemplateGenerateData.TEMPLATENAME }
                this.ForDocDialogOpen(passdata);
            }
        } else if (
            this.router.url == "/create-document/receive-money-template" ||
            this.popupurl == "/create-document/receive-money-template"
        ) {
            let ReceiptData = JSON.parse(localStorage.getItem("receiptData"));
            let passdata = {
                Context: "Income",
                ContextGuid: ReceiptData.INCOMEGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.showFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            // let passdata = { 'Context': "Income", 'ContextGuid': ReceiptData.INCOMEGUID, "knownby": 'Template', "Type": "Template", "Folder": localStorage.getItem("Folder"), "Template": this.TemplateGenerateData.TEMPLATENAME }
            this.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/contact-template" ||
            this.popupurl == "/create-document/contact-template"
        ) {
            let ContactGuID = localStorage.getItem("contactGuid");
            let passdata = {
                Context: "Contact",
                ContextGuid: ContactGuID,
                knownby: "Template",
                Type: "Template",
                Folder: this.showFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            // let passdata = { 'Context': "Contact", 'ContextGuid': ContactGuID, "knownby": 'Template', "Type": "Template", "Folder": localStorage.getItem("Folder"), "Template": this.TemplateGenerateData.TEMPLATENAME }
            this.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/full-Safe-Custody/Safe-Custody" ||
            this.popupurl == "/full-Safe-Custody/Safe-Custody"
        ) {
            let passdata = {
                Context: "Safe Custody",
                ContextGuid: this.SafeCustodyData.SAFECUSTODYGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.ForDocDialogOpen(passdata);
        }
    }
    //***********************************************************END Select Matter Contact*************************************************************************
 
 /**
  * This function is used to for doc dialog open.
  */
    ForDocDialogOpen(passdata) {
        const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
            width: "100%",
            disableClose: true,
            data: passdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // localStorage.setItem('set_active_matters', JSON.stringify(result));
            }
        });
    }

    /**
     * This function is used to OnREsize the end
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(
                "mat-column-" + columnName
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }

    /**
     * THis function is used to Contact matter
     */
    ContactMatter() {
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {
                type: "",
            },
        });
        dialogRef.afterClosed().subscribe((result) => {});
    }

/**
 * THis function is used to Document Floder
 */
    DcoumentFloder() {
        const dialogConfig = new MatDialogConfig();
        let mattersData = JSON.parse(
            localStorage.getItem(
                this.windowNameId ? this.windowNameId : "set_active_matters"
            )
        );
        const dialogRef = this.MatDialog.open(MatterPopupComponent, {
            width: "100%",
            disableClose: true,
            data: { action: "edit", matterGuid: mattersData.MATTERGUID },
        });
        dialogRef.afterClosed().subscribe((result) => {});
    }
    
    /**
     * This function is used to select the client
     */
    SelectClient() {
        if (!localStorage.getItem("contactGuid")) {
            this.toastr.error("Please Select Contact");
        } else {
            const dialogRef = this.MatDialog.open(ContactDialogComponent, {
                disableClose: true,
                data: { action: "edit" },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    $("#refreshContactTab").click();
                }
            });
        }
    }

    /**
     * This function is used to on file change
     */
    onFilesChange(file) {
        this.fileList.push(file);
        this.saveTemplateFile(file);
    }

    /**
     * This function is used to save thw template file
     */
    saveTemplateFile(file: any) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
            let details: any = {
                FOLDERNAME: this.showFolder,
                FILENAME: file.name,
                FILEDATA: event.target.result,
            };
            let postData: any = {
                FORMACTION: "insert",
                VALIDATEONLY: true,
                Data: details,
            };
            this._mainAPiServiceService
                .getSetData(postData, "TemplateUpload")
                .pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(
                    (response) => {
                        postData.VALIDATEONLY = true;
                        if (
                            response.CODE == 200 &&
                            (response.STATUS == "OK" ||
                                response.STATUS == "success")
                        ) {
                            this.toastr.success("Document save successfully");
                            this.LoadData({
                                Folder: localStorage.getItem("Folder"),
                            });
                            // this.checkValidation(response.FileUpload_response.DATA.VALIDATIONS, postData);
                        } else if (
                            response.CODE == 451 &&
                            response.STATUS == "warning"
                        ) {
                            this.checkValidation(
                                response.DATA.VALIDATIONS,
                                postData
                            );
                        } else if (
                            response.CODE == 450 &&
                            response.STATUS == "error"
                        ) {
                            this.checkValidation(
                                response.DATA.VALIDATIONS,
                                postData
                            );
                        }
                    },
                    (error) => {
                        this.toastr.error(error);
                    }
                );
        };
        reader.readAsDataURL(file);
    }


    /**
     * This function is used to check the validation
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: {
            VALUEVALID: string;
            ERRORDESCRIPTION: any;
            FIELDNAME: any;
        }) {
            if (value.VALUEVALID == "No") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { Error: tempError, warning: tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this.MatDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: warningData,
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to Save?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.saveDocument(details);
                }
                this.confirmDialogRef = null;
            });
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            this.saveDocument(details);
        }
    }

    /**
     * This function is used to save the document
     */
    saveDocument(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService
            .getSetData(data, "TemplateUpload")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        this.toastr.success("Document save successfully");
                        this.LoadData({});
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.toastr.warning(response.MESSAGE);
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to close the popup
     */
    closePopup() {
        this.fileList = [];
    }
}
