import 'hammerjs';
import { AppComponent } from 'src/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ChangePasswordComponent } from './main/change-password/change-password.component';
import { ConfirmPopupComponent } from './main/confirm-popup/confirm-popup.component';
import { Convet24To12Pipe } from './convet24-to12.pipe';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DialogBoxComponent } from './help-desk/dialog-box/dialog-box.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import { EndOfMonthReportsComponent } from './main/end-of-month-reports/end-of-month-reports.component';
import { EndUserLicenceAgreementComponent } from './main/end-user-licence-agreement/end-user-licence-agreement.component';
import { fuseConfig } from 'src/app/fuse-config';
import { FuseModule } from 'src/@fuse/fuse.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { HelpDeskComponent } from './help-desk/help-desk.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InternalErrorComponent } from './main/errors/internal-error/internal-error.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { LayoutModule } from 'src/app/layout/layout.module';
import { LicenceAgreementComponent } from './main/licence-agreement/licence-agreement.component';
import { LogoutErrorComponent } from './main/logout-error/logout-error.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatterFolderDailogComponent } from './layout/components/toolbar/matter-folder-dailog/matter-folder-dailog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './main/errors/not-found/not-found.component';
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';
import { ReportsComponent } from './main/reports/reports.component';
import { RouterModule, Routes } from '@angular/router';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ServerErrorComponent } from './main/errors/server-error/server-error.component';
import { SortingDialogComponent, filterNames } from './main/sorting-dialog/sorting-dialog.component';
import { SyncToXeroPopupComponent } from './main/sync-to-xero-popup/sync-to-xero-popup.component';
import { ToastrModule } from 'ngx-toastr';
import { ToFixedAmountPipe } from './to-fixed-amount.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TryFreeSetUpWizardComponent } from './main/try-free-set-up-wizard/try-free-set-up-wizard.component';
import { UploadTemplatesPopupComponent } from './main/upload-templates-popup/upload-templates-popup.component';
import { UserFirmDirectoryContactDetailComponent } from './user-firm-directory-contact-detail/user-firm-directory-contact-detail.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { CSVPopupComponent } from './main/csv-popup/csv-popup.component';
import { SharedModule } from './Shared/shared.module';
import { FixedAmountPipe } from './fixed-amount.pipe';
import { ApplicationPipesModule } from '@pages/application-pipes.module';
import { BindDropdownTreeComponent } from '@pages/inbox/rename-email/bind-dropdown-tree/bind-dropdown-tree.component';
import { MatterDialogComponent } from '@pages/time-entries/matter-dialog/matter-dialog.component';
import { RenameEmailComponent } from '@pages/inbox/rename-email/rename-email.component';
import { PagesModule } from '@pages/pages.module';
import { TrustChartAccount } from '@pages/trust-chart-of-account/trust-chart-of-account.module';
import { MyProfileModule } from '@pages/my-profile/my-profile.module';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { NumberSeparatorModule } from '@directives/numberseparator/number-separator.module';
import { NumbersModule } from '@directives/allownumber/numbers.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { BulkInvoicingWorkitemComponent } from '@pages/invoice/bulk-invoicing-workitem/bulk-invoicing-workitem.component';
import { DetailsModule } from '@pages/invoice/invoice-add-dailog/details/details.module';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FileSaverModule } from 'ngx-filesaver';

/**
 * This is using for set the route JSON
 */
const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    { path: 'server-error', component: ServerErrorComponent },
    { path: 'end-user-licence-agreement', component: EndUserLicenceAgreementComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },


    { path: '**', component: NotFoundComponent },
];

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        AppComponent,
        SortingDialogComponent,
        filterNames,
        NotFoundComponent,
        InternalErrorComponent,
        ReportsComponent,
        LicenceAgreementComponent,
        ChangePasswordComponent,
        MatterFolderDailogComponent,
        ServerErrorComponent,
        TryFreeSetUpWizardComponent,
        MatterDialogComponent,
        ConfirmPopupComponent,
        EndOfMonthReportsComponent,
        SyncToXeroPopupComponent,
        UploadTemplatesPopupComponent,
        RenameEmailComponent,
        LogoutErrorComponent,
        BindDropdownTreeComponent,
        HelpDeskComponent,
        BulkInvoicingWorkitemComponent,
        EndUserLicenceAgreementComponent,
        DialogBoxComponent,
        UserFirmDirectoryContactDetailComponent,
        PrivacyPolicyComponent,
        CSVPopupComponent,
      //  TimelineIconDirective,
        //  TimelineIconDirective,
    ],
    entryComponents: [
        SortingDialogComponent,
        ReportsComponent,
        LicenceAgreementComponent,
        ChangePasswordComponent,
        MatterFolderDailogComponent,
        TryFreeSetUpWizardComponent,
        MatterDialogComponent,
        ConfirmPopupComponent,
        EndOfMonthReportsComponent,
        SyncToXeroPopupComponent,
        UploadTemplatesPopupComponent,
        RenameEmailComponent,
        LogoutErrorComponent,
        DialogBoxComponent,
        UserFirmDirectoryContactDetailComponent
    ],
    imports: [
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DetailsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        TranslateModule.forRoot(),
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
        MatPaginatorModule,
        MatDividerModule,
        MatDialogModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatMenuModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatTableModule,
        MatSortModule,
        MatToolbarModule,
        MatCardModule,
        MatListModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MatSnackBarModule,
        MatTabsModule,
        //Datepicker
        SatDatepickerModule,
        SatNativeDateModule,
        MatProgressBarModule,
        // App modules
        LayoutModule,
        PagesModule,
        ToastrModule.forRoot(), // ToastrModule added
        MatProgressSpinnerModule,

        DropDownTreeModule,
        ApplicationPipesModule,
        MatOptionModule,
        TrustChartAccount,
        NumberMaskModule,
        SharedModule,
        MyProfileModule,
        NumberSeparatorModule,
        NumbersModule,
        RichTextEditorAllModule,
        FileSaverModule,
        NgxMaskModule.forRoot(maskConfig),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorkerEnabled })
    ],
    exports: [
        SortingDialogComponent,
        ChangePasswordComponent,
        DropDownTreeModule,
        RenameEmailComponent,
        LogoutErrorComponent,
        BindDropdownTreeComponent,
        DialogBoxComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DatePipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        ToFixedAmountPipe,
        Convet24To12Pipe,
        FixedAmountPipe,

    ],
})
export class AppModule {
}
