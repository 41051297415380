<div class=" one " [formGroup]="contactForm" fxLayout="column">

    <div fxLayout="row wrap" class="pt-12" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.NOTES,'has-warning':errorWarningData.Warning?.NOTES}">
            <mat-label>Notes</mat-label>
            <textarea class="min_height_textarea" name="NOTES" [formControlName]="'NOTES'" matInput matTooltip="{{allToolTip.NOTES?.HOVER}}"></textarea>
            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NOTES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
</div>