import { fuseAnimations } from "src/@fuse/animations";
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ChangeDetectorRef, Renderer2, ElementRef, HostListener, OnDestroy } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialogRef, MatDialog, MatDialogConfig, } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Subscription, Subject } from "rxjs";
import { SortingDialogComponent } from "src/app/main/sorting-dialog/sorting-dialog.component";
import { DatePipe } from "@angular/common";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { ResizeEvent } from 'angular-resizable-element';
import * as $ from 'jquery';
import { DateAdapter } from '@angular/material/core';
import { inputDateFormat } from 'src/app/date.adapter';
import { TrustMoneyReciptComponent } from "../../Trust Accounts/trust-money/trust-money-recipt/trust-money-recipt.component";
import * as moment from "moment";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
@Component({
    selector: "app-recounciliation-item",
    templateUrl: "./recounciliation-item.component.html",
    styleUrls: ["./recounciliation-item.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: inputDateFormat,
})
export class RecounciliationItemComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
    previousPriceBal: any = 0;
    chartAccountDetail: any;
    errorWarningData: any = { Error: [], Warning: [] };
    GetReconciliationItemsSubscription: Subscription;
    isLoadingResults: boolean = false;
    pageSize: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    DebitAmount: any = [];
    CraditAmount: any = [];
    dateColFilter = [];
    SendRecouncileArray: any = [];
    FirstTimeWithDrawTotal: any = [];
    FirstTimeWithDrawTotalArray: any = [];
    FirstTimeDipositeTotalArray: any = [];
    AccountRecouncile: FormGroup;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    ReconciliationData: any = [];
    selection = new SelectionModel<any>(true, []);
    displayedColumns: string[];
    showedColumns: string[];
    tempColobj: any;
    ColumnsObj = [];
    CalculatedClosingBalnce: number;
    DepositAdjustments: number = 0;
    WithdrawalAdjustments: number = 0;
    DepositBalnce: number;
    FirstTimeDipositeTotal: number;
    lastDay: any;
    changeDate: any;
    recouncileItemdata: any;
    isDisplay: boolean = false;
    selectedColore: string = 'rgb(217, 217, 217)';
    SendData: {
        ACCOUNTGUID: any;
        PERIODENDDATE: any;
        STARTINGBALANCE: number;
        DEPOSITS: any;
        WITHDRAWALS: any;
        UNPRESENTEDDEPOSITS: any;
        UNPRESENTEDWITHDRAWALS: any;
        ENDINGBALANCE: any;
        PREPAREDBY: any;
        RECONCILIATIONITEMS: any[];
    };
    AccountConstName: any;
    accountTypeData: any;
    sortingDefaultState: any = {};
    sortactive: any;
    sortDirection: any;
    previousStatmentBal: any = 0;
    isNumStoreStatmentclose: any;
    isTrustWindow: boolean;
    SelectedCheckBox: any[];
    hasDateRange: boolean = false;
    MaincalCashBook: any = 0;
    statementClosingBalance: any;
    highlightedRows: any;
    RecouncileConstNameSubscribe: Subscription;
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    sub6: Subscription;
    sub7: Subscription;
    sub8: Subscription;
    sub9: Subscription;
    sub10: Subscription;
    sub11: Subscription;
    sub12: Subscription;
    sub13: Subscription;
    windowNameId: any;
    currentUserData: any;
    activeTab: any;
    resizableMousemove: () => void;
    resizableMouseup: () => void;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private dialog: MatDialog,
        public datepipe: DatePipe,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public behaviorService: BehaviorService,
        private TableColumnsService: TableColumnsService,
        public _matDialog: MatDialog,
        private SortingbehaviorService: SortingBehaviourService,
        private adapter: DateAdapter<any>,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2
    ) {
        
        this.currentUserData = JSON.parse(window.localStorage.getItem("currentUser"))

        localStorage.setItem('istrackid', 'RecounciliationItemComponent');
        const userdata = JSON.parse(localStorage.getItem("currentUser"));
        this.sub = this.behaviorService.activeTabName$.subscribe((res) => {
            this.activeTab = res;
            this.getTableFilter();
        })
        this.sub1 = this.SortingbehaviorService.accountRecouncileSorting$.subscribe((result) => {
            if (result) {
                this.sortingDefaultState = result;
                localStorage.setItem('account_reconcile_screen_sorting', JSON.stringify(result));
            } else {
                this.sortingDefaultState = JSON.parse(localStorage.getItem('account_reconcile_screen_sorting'))
            }
        });
        this.sub2 = this.SortingbehaviorService.NoTrustRecounItemFilterData$.subscribe((result) => {
            if (result) {
                localStorage.setItem('notrustRecBankDate', JSON.stringify(result.BankStatementDate));
            }
        });
        this.sub3 = this.SortingbehaviorService.TrustRecounItemFilterData$.subscribe((result) => {
            if (result) {
                localStorage.setItem('trustRecBankDate', JSON.stringify(result.BankStatementDate));

            }
        });
        this.sub4 = this.behaviorService.statementClosingBalance$.subscribe((result) => {
            if (result) {
                this.statementClosingBalance = result;
            }
        });
        this.sub5 = this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'RecounciliationItemComponent' && result['click'] && result['data']['GetReconciliationItems']) {
                if (!localStorage.getItem('recounciliation_list_columns')) {
                    this.getTableFilter();
                }
                this.refreshRecouncilItem();
            }
        });

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.hasDateRange = true;
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "";
            this.sortDirection = "";
        }
        this.ReconciliationData = [];
        const userdata = JSON.parse(localStorage.getItem("currentUser"));
        this.AccountRecouncile = this._formBuilder.group({
            LASTRECONCILEDDATE: [""],
            LASTRECONCILEDBALANCE: [],
            statementClosingBalance: ['', {updateOn: 'blur'}],
            Bankdate: [],
            calculatedClosingBalance: [],
            OutBal: [],
            UnDeposite: [],
            PreBy: [userdata.UserName],
            UnWith: [],
            SendDeposite: [],
            SendWithdrawal: [],
            unbanked_cash: [0],
            controlled_account: [0],
            cal_cash_book: [0],
            REVERSEDDEPOSITS: [0],
            REVERSEDWITHDRAWALS: [0],
        });
       
        this.sub6 = this.behaviorService.ChartAccountData$.subscribe((result) => {
            const materIDofTab = window.name.split("_");
            this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            if (this.windowNameId && userdata.OPENINNEWTAB) {
                this.chartAccountDetail = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : result));
                if(this.chartAccountDetail && this.windowNameId == result.ACCOUNTGUID && !this.chartAccountDetail.NEXTRECONCILEDDATE){
                    this.chartAccountDetail.NEXTRECONCILEDDATE = (result.NEXTRECONCILEDDATE)?result.NEXTRECONCILEDDATE:'';
                    localStorage.setItem(this.windowNameId, JSON.stringify(this.chartAccountDetail))
                };

                if (this.chartAccountDetail) {
                } else {
                    if (result) {
                        this.chartAccountDetail = result;
                        localStorage.setItem(this.windowNameId, JSON.stringify(this.chartAccountDetail))
                    }
                }
            } else {
                if (result) {
                    this.chartAccountDetail = result;
                }
            }
        });
        this.sub7 = this.behaviorService.TrustDuplicateModuleHandling$.subscribe((result) => {
            if (this.chartAccountDetail && this.chartAccountDetail.accountTypeData) {
                this.accountTypeData = this.chartAccountDetail.accountTypeData;
            } else {
                if (result) {
                    this.accountTypeData = result;
                    this.chartAccountDetail.accountTypeData = result;
                    if (this.windowNameId && userdata.OPENINNEWTAB) { localStorage.setItem(this.windowNameId, JSON.stringify(this.chartAccountDetail)) }
                }
            }
        });

        this.isTrustWindow = (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust) ? true : false;
        if (this.chartAccountDetail && this.chartAccountDetail.NEXTRECONCILEDDATE) {
            this.lastDay = this.chartAccountDetail.NEXTRECONCILEDDATE;
        } else {

            this.sub8 = this.behaviorService.isbankStatmentDate$.subscribe((result) => {
                if (result) {
                    this.lastDay = result;
                } else {
                    this.lastDay = this.datepipe.transform(new Date(), "dd/MM/yyyy");
                }
            });

        };

        this.behaviorService.isbankStatmentDate(this.lastDay);

        const splitDate = this.lastDay.split("/");
        const showDate = new Date(splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2]);
        this.getTableFilter();
        if (this.chartAccountDetail && this.chartAccountDetail.isEOM) {
            this.getAccountInfo(this.chartAccountDetail.ACCOUNTGUID);
        };

        this.RecouncileConstNameSubscribe = this.behaviorService.RecouncileConstName$.subscribe((result) => {
            this.windowNameId && userdata.OPENINNEWTAB ? this.chartAccountDetail = JSON.parse(localStorage.getItem(this.windowNameId)) : null;
            if (this.chartAccountDetail) {
                this.AccountConstName = this.chartAccountDetail.name;
            } else {
                if (result) {
                    this.AccountConstName = result;
                }
            };
            this.LoadData({ AccountGuid: this.chartAccountDetail.ACCOUNTGUID, BankStatementDate: this.lastDay, UseTrust: (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust == true) ? true : false });
        });

        this.resizeTableForAllView();
        $(window).resize(() => {
            this.resizeTableForAllView();
        });
    };

    /**
     * This function is used to get the Account Information
     * @param ACCOUNTGUID -ACCOUNTGUID
     */
    getAccountInfo(ACCOUNTGUID) {
        this.isLoadingResults = true;
        let isTrust = this.accountTypeData.ClickType == 'WithoutTrust' ? false : true;
        this.sub9 = this._mainAPiServiceService.getSetData({ ACCOUNTGUID: ACCOUNTGUID, UseTrust: isTrust }, 'GetAccount').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                let accountData = res.DATA.ACCOUNTS[0];
                this.AccountConstName = accountData.ACCOUNTCLASS + " - " + accountData.ACCOUNTNUMBER + " " + accountData.ACCOUNTNAME;
            }
            this.isLoadingResults = false;
        }, err => {
            this.toastr.error(err);
            this.isLoadingResults = false;
        });
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
        setTimeout(() => {
            this.resizeTableForAllView();
        }, 500);
    }
    resizeTableForAllView() {
        let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
        $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 150)) + 'px'); //before 190
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.ReconciliationData.data.forEach((row) => this.selection.select(row));
        this.GloballyCal();
        this.FirstTimeCal("");
        this.SendRecouncileArray.push(this.selection.selected);
        this.commonSendData();
    }

    /**
     * This function is used to select all check box
     * @returns data
     */
    isAllSelected() {
        let numSelected: any = 0;
        let numRows: any = 0;
        if (this.selection.selected) {
            numSelected = this.selection.selected.length;
        }
        if (this.ReconciliationData.data) {
            numRows = this.ReconciliationData.data.length;
        }
        return numSelected === numRows;
    }

    /**
     * Used to send the gloable data
     */
    commonSendData() {
        this.selection.selected.forEach((element) => {
            element.TAGGED = 1;
        });
        if (this.f.UnWith.value == undefined || this.f.UnWith.value == "undefined") {
            this.AccountRecouncile.controls["UnWith"].setValue(0);
        }
        this.behaviorService.RecouncileItemSendSetData({
            ACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID,
            PERIODENDDATE: (!this.changeDate) ? moment(this.refreshDate(), 'MM/DD/YYYY').format('DD/MM/YYYY') : this.lastDay,
            STARTINGBALANCE: Number(this.calCloingBalSend(this.f.LASTRECONCILEDBALANCE.value)),
            DEPOSITS: this.f.SendDeposite.value,
            WITHDRAWALS: this.f.SendWithdrawal.value,
            UNPRESENTEDDEPOSITS: Number(this.calCloingBalSend(this.f.UnDeposite.value)),
            UNPRESENTEDWITHDRAWALS: Number(this.calCloingBalSend(this.f.UnWith.value)),
            ENDINGBALANCE: Number(this.calCloingBalSend(this.f.calculatedClosingBalance.value)),
            PREPAREDBY: this.f.PreBy.value,
            RECONCILIATIONITEMS: this.selection.selected,
        });
    }

    get f() {
        return this.AccountRecouncile.controls;
    }

    /**
     * This unction is used to make the gloabaly calculation data
     */
    GloballyCal() {
        this.DebitAmount = [];
        this.CraditAmount = [];
        this.WithdrawalAdjustments = 0;
        this.DepositAdjustments = 0;
        this.SelectedCheckBox = [];
        // let selected = JSON.parse(localStorage.getItem('SelectedCheckbox'))
        // this.selection.toggle(selected)

        if (this.selection.selected.length > 0) {
            this.selection.selected.forEach((element) => {
                this.DebitAmount.push(element.DEBITAMOUNT);
                this.CraditAmount.push(element.CREDITAMOUNT);
                element.TAGGED = 1;
                this.SelectedCheckBox.push(element);
            });
        } else {
            this.SelectedCheckBox = [];
        }
        localStorage.setItem('SelectedCheckbox', JSON.stringify(this.SelectedCheckBox));
        this.ReconciliationData.data.forEach((element) => {
            if (element.TAGGED) {
                if (element.LINKTYPE == 255) {
                    this.WithdrawalAdjustments = Number(this.WithdrawalAdjustments) + Number(element.DEBITAMOUNT);
                    this.DepositAdjustments = Number(this.DepositAdjustments) + Number(element.CREDITAMOUNT);
                }
            } else {
                if (element.LINKTYPE == 254) {
                    this.WithdrawalAdjustments = Number(this.WithdrawalAdjustments) + Number(element.CREDITAMOUNT);
                    this.DepositAdjustments = Number(this.DepositAdjustments) + Number(element.DEBITAMOUNT);
                }
            }
        });
        this.CalculatedClosingBalnce = Number(this.DebitAmount.reduce(function (a = 0, b = 0) { return a + b; }, 0));
        this.DepositBalnce = Number(this.CraditAmount.reduce(function (a = 0, b = 0) { return a + b; }, 0));
        this.AccountRecouncile.controls["SendDeposite"].setValue(this.CalculatedClosingBalnce);
        this.AccountRecouncile.controls["SendWithdrawal"].setValue(this.DepositBalnce);
        let deposit: any = Number(this.DepositBalnce).toFixed(2);

        let withdrawal: any = Number(this.CalculatedClosingBalnce).toFixed(2);
        let finalTotal = Number(this.calCloingBalSend(this.f.LASTRECONCILEDBALANCE.value)) + Number(withdrawal) - Number(deposit);
        this.AccountRecouncile.controls["calculatedClosingBalance"].setValue(this.calCLosingbalShow(finalTotal));
        // this.CalcCashBookbal();
    }

    /**
     * This function is used to get the data 
     */
    helloFunction() {
        this.GloballyCal();
        this.FirstTimeCal("");
        this.commonSendData();
        this.statmentClosingBal();
    }
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
    }

    /**
     * This function is used to CalcCashBookbal
     */
    CalcCashBookbal() {
        let calCashBook: Number = this.MaincalCashBook.toFixed(2);
        if (this.selection.selected.length > 0) {
            this.selection.selected.forEach(element => {
                calCashBook = Number(calCashBook) - element.DEBITAMOUNT + element.CREDITAMOUNT;
            });
            // calCashBook = (Number(this.f.statementClosingBalance.value) - Number(this.f.UnWith.value) + Number(this.f.UnDeposite.value) + Number(this.f.unbanked_cash.value) - Number(this.f.REVERSEDWITHDRAWALS.value) + Number(this.f.REVERSEDDEPOSITS.value) + Number(this.DepositAdjustments) - Number(this.WithdrawalAdjustments));
        }
        this.AccountRecouncile.controls["cal_cash_book"].setValue(calCashBook);
    }

    /**
     * This function is used to statementClosing Balance
     */
    statmentClosingBal() {
        if (this.f.statementClosingBalance.value != null) {
            this.isNumStoreStatmentclose = this.f.statementClosingBalance.value;
            if (!isNaN(this.isNumStoreStatmentclose)) {
                this.previousStatmentBal = this.isNumStoreStatmentclose;
                this.AccountRecouncile.controls["statementClosingBalance"].setValue(parseFloat(this.isNumStoreStatmentclose).toFixed(2));
                this.behaviorService.SetstatementClosingBalance(this.isNumStoreStatmentclose);
                let val = Number(this.isNumStoreStatmentclose) - Number(this.calCloingBalSend(this.f.calculatedClosingBalance.value));
                this.AccountRecouncile.controls["OutBal"].setValue(this.calCLosingbalShow(val));
            } else {
                this.AccountRecouncile.controls["statementClosingBalance"].setValue(parseFloat(this.previousStatmentBal).toFixed(2));
                this.behaviorService.SetstatementClosingBalance(this.previousStatmentBal);
            }
            this.CalcCashBookbal();
        }
    }

    /**
     * This function is used to get the table filter
     */
    getTableFilter() {
        let recounciliationListColumns: any = JSON.parse(localStorage.getItem('recounciliation_list_columns'));
        if (recounciliationListColumns && recounciliationListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(recounciliationListColumns.ColumnsObj);
            this.tempColobj = data.tempColobj;
            this.displayedColumns = recounciliationListColumns.displayedColumns;
            this.dateColFilter = data.dateCol;
            if (!this.displayedColumns.includes("select")) {
                this.displayedColumns.splice(0, 0, "select");
            }
            this.showedColumns = [...this.displayedColumns];
            if(this.activeTab == 'ACCOUNTS RECONCILIATION') {
                const array = ['CHEQUENO', 'CHEQUETOTAL', 'TRUSTREFERENCE'];
                for(const element of array) {
                    const index = this.showedColumns.indexOf(element);
                    if (index > -1) {
                        this.showedColumns.splice(index, 1);
                    }
                }
            }
            if(this.activeTab == 'TRUST ACCOUNTS RECONCILIATION') {
                const array = ['CHEQUENO', 'CHEQUETOTAL'];
                for(const element of array) {
                    const index = this.showedColumns.indexOf(element);
                    if (index > -1) {
                        this.showedColumns.splice(index, 1);
                    }
                }
            }
            this.ColumnsObj = recounciliationListColumns.ColumnsObj;
        } else {
            this.sub10 = this.TableColumnsService.getTableFilter("Reconciliation", "Reconciliation").subscribe((response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let data = this.TableColumnsService.filtertableColum(response.DATA.RECORDS);
                    this.tempColobj = data.tempColobj;
                    this.displayedColumns = data.showcol;
                    this.dateColFilter = data.dateCol;
                    this.displayedColumns.splice(0, 0, "select");
                    this.showedColumns = [...this.displayedColumns];
                    if(this.activeTab == 'ACCOUNTS RECONCILIATION') {
                        const array = ['CHEQUENO', 'CHEQUETOTAL', 'TRUSTREFERENCE'];
                        for(const element of array) {
                            const index = this.showedColumns.indexOf(element);
                            if (index > -1) {
                                this.showedColumns.splice(index, 1);
                            }
                        }
                    }
                    if(this.activeTab == 'TRUST ACCOUNTS RECONCILIATION') {
                        const array = ['CHEQUENO', 'CHEQUETOTAL'];
                        for(const element of array) {
                            const index = this.showedColumns.indexOf(element);
                            if (index > -1) {
                                this.showedColumns.splice(index, 1);
                            }
                        }
                    }
                    this.ColumnsObj = data.colobj;
                    localStorage.setItem('recounciliation_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
                }
            }, (error) => {
                this.toastr.error(error);
            });
        }
    }

    /**
     * This function is used to OnResizing data
     * @param event -event data
     * @param columnName -column name
     */
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(col => col.COLUMNID === columnName);
            this.ColumnsObj[indexData]['WIDTH'] = event.rectangle.width;
            localStorage.setItem('recounciliation_list_columns', JSON.stringify({ "ColumnsObj": this.ColumnsObj, 'displayedColumns': this.displayedColumns }));
            
        }
    }

    /**
     * This function is used to OnResize the End data 
     * @param event -event data
     * @param columnName -column name data
     */
    onResizeEnd(event: ResizeEvent, columnName): void{
        this.TableColumnsService.SaveWidthData(this.ColumnsObj, "Reconciliation", "Reconciliation");
      }
    
      /**
       * This function is used to set the default Width data
       * @param displayedColumns-columns data 
       * @param timeout -timeout data
       */
    setDefaultWidth(displayedColumns, timeout) {
        setTimeout(() => {
            displayedColumns.forEach(element => {
                if (element != 'select') {
                    let temWidth = this.tempColobj[element]['WIDTH'];
                    const cssValue = temWidth + 'px';
                    const columnElts = document.getElementsByClassName('mat-column-' + element);
                    for (let i = 0; i < columnElts.length; i++) {
                        const currentEl = columnElts[i] as HTMLDivElement;
                        currentEl.style.visibility = 'inherit';
                        currentEl.style.width = cssValue;
                    }
                }
            });
        }, timeout);
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.RecouncileConstNameSubscribe?.unsubscribe();
        this.GetReconciliationItemsSubscription?.unsubscribe();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.sub6?.unsubscribe();
        this.sub7?.unsubscribe();
        this.sub8?.unsubscribe();
        this.sub9?.unsubscribe();
        this.sub10?.unsubscribe();
        this.sub11?.unsubscribe();
        this.sub12?.unsubscribe();
        this.sub13?.unsubscribe();
    }

    /**
     * This function is used to onPaginate change
     * @param event -event data
     */
    onPaginateChange(event) {
        this.setDefaultWidth(this.displayedColumns, 0);
        this.setDefaultWidth(this.showedColumns, 0)
        this.pageSize.recounciliations_item = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to First time CAL
     * @param data 
     */
    FirstTimeCal(data) {
        if (data != "") {
            this.FirstTimeWithDrawTotalArray = [];
            this.FirstTimeDipositeTotalArray = [];
            data.forEach((element) => {
                this.FirstTimeWithDrawTotalArray.push(element.DEBITAMOUNT);
                this.FirstTimeWithDrawTotal = Number(this.FirstTimeWithDrawTotalArray.reduce(function (a = 0, b = 0) { return a + b; }, 0));
                this.FirstTimeDipositeTotalArray.push(element.CREDITAMOUNT);
                this.FirstTimeDipositeTotal = Number(this.FirstTimeDipositeTotalArray.reduce(function (a = 0, b = 0) { return a + b; }, 0));
            });
            this.MaincalCashBook = this.FirstTimeWithDrawTotal - this.FirstTimeDipositeTotal;
            this.AccountRecouncile.controls["cal_cash_book"].setValue((this.MaincalCashBook).toFixed(2));
        }
        let FinalValWithdrawl = (this.FirstTimeWithDrawTotal - this.CalculatedClosingBalnce).toFixed(2);
        let FinalValdeposit = (this.FirstTimeDipositeTotal - this.DepositBalnce).toFixed(2);
        this.AccountRecouncile.controls["UnWith"].setValue(this.calCLosingbalShow(FinalValdeposit));
        this.AccountRecouncile.controls["UnDeposite"].setValue(this.calCLosingbalShow(FinalValWithdrawl));
    }

    /**
     * This function is used to sorting the date
     */
    sortingDate():void {
        this.ReconciliationData.sortingDataAccessor = (item, property) => {
            let FildeValue = this.dateColFilter;
            if (FildeValue.includes(property)) {
                if (item[property]) {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]);
                    let newDate = new Date(Sd);
                    return newDate;
                }
                return item[property];
            } else {
                return item[property];
            }
        };
        // proper shorting for date
        this.ReconciliationData.sort = this.sort;
    }

    /**
     * This function is used to Sort the data
     * @param s -sort data
     */
    sortData(s) {
        this.SortingbehaviorService.accountRecouncileSorting(s);
        this.setDefaultWidth(this.displayedColumns, 0);
        this.setDefaultWidth(this.showedColumns, 0)
    }

    /**
     * This function is used to refresh the Data
     * @returns 
     */
    refreshDate(){
        let LastDayOfMonth: any = new Date();
        let nextRecDateIs = JSON.parse(localStorage.getItem((this.chartAccountDetail && this.chartAccountDetail.ACCOUNTGUID)?this.chartAccountDetail.ACCOUNTGUID:'ChartAccountData'));
  
        if(!nextRecDateIs && !this.windowNameId){
            nextRecDateIs = JSON.parse(localStorage.getItem('ChartAccountData'));
        };
      
        LastDayOfMonth = (nextRecDateIs)?(nextRecDateIs.NEXTRECONCILEDDATE !=="")?nextRecDateIs.NEXTRECONCILEDDATE:new Date():new Date();
        return  moment(LastDayOfMonth , 'DD/MM/YYYY');
    };

    /**
     * This function is used to Load the data
     * @param data -data value
     */
    LoadData(data) {
        if (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust == true) {
            this.SortingbehaviorService.SetTrustRecounItemFilterData(data);
        } else {
            this.SortingbehaviorService.SetNoTrustRecounItemFilterData(data);
        };

        this.AccountRecouncile.controls["calculatedClosingBalance"].setValue(0);
        this.AccountRecouncile.controls["statementClosingBalance"].setValue(0);
        this.AccountRecouncile.controls["OutBal"].setValue(0);
        this.AccountRecouncile.controls["UnDeposite"].setValue(0);
        this.AccountRecouncile.controls["UnWith"].setValue(0);
        this.CalculatedClosingBalnce = 0;
        this.DepositBalnce = 0;
        this.ReconciliationData = [];
        this.isLoadingResults = true;
        data.BankStatementDate = (!this.changeDate) ? moment(this.refreshDate(), 'MM/DD/YYYY').format('DD/MM/YYYY') : this.lastDay;

        this.GetReconciliationItemsSubscription = this._mainAPiServiceService.getSetData(data, "GetReconciliationItems").subscribe((response) => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.FirstTimeCal(response.DATA.RECONCILIATIONITEMS);
                this.ReconciliationData = new MatTableDataSource(response.DATA.RECONCILIATIONITEMS);
                // this.ReconciliationData.paginator = this.paginator;
                this.sortingDate();
                this.AccountRecouncile.controls["LASTRECONCILEDDATE"].setValue(response.DATA.LASTRECONCILEDDATE);
                this.AccountRecouncile.controls["unbanked_cash"].setValue(response.DATA.UNBANKEDMONEY);
                this.AccountRecouncile.controls["controlled_account"].setValue(response.DATA.CONTROLACCOUNTBALANCE);
                this.AccountRecouncile.controls["REVERSEDWITHDRAWALS"].setValue(response.DATA.REVERSEDWITHDRAWALS);
                this.AccountRecouncile.controls["REVERSEDDEPOSITS"].setValue(response.DATA.REVERSEDDEPOSITS);
                this.AccountRecouncile.controls["LASTRECONCILEDBALANCE"].setValue(this.calCLosingbalShow(response.DATA.LASTRECONCILEDBALANCE));
                this.AccountRecouncile.controls["calculatedClosingBalance"].setValue(this.calCLosingbalShow(response.DATA.LASTRECONCILEDBALANCE));
                if (this.statementClosingBalance) {
                    this.AccountRecouncile.controls["statementClosingBalance"].setValue(this.statementClosingBalance);
                }
                let showOutBal = 0.0 - Number(this.calCloingBalSend(response.DATA.LASTRECONCILEDBALANCE));
                this.AccountRecouncile.controls["OutBal"].setValue(this.calCLosingbalShow(showOutBal));

                // this.AccountRecouncile.controls['OutBal'].setValue(response.DATA.LASTRECONCILEDBALANCE);

                this.isLoadingResults = false;
                // Find Last Day of Month.
                // let LastDayOfMonth = "";
                // let dateIs = ""

                // if(this.AccountRecouncile.controls["LASTRECONCILEDDATE"].value !==""){
                //     dateIs =  moment(this.AccountRecouncile.controls["LASTRECONCILEDDATE"].value, 'DD-MM-YYYY').format('MM/DD/YYYY');
                //     if(!this.isLastDay(new Date(dateIs))){
                //         LastDayOfMonth =  moment(this.AccountRecouncile.controls["LASTRECONCILEDDATE"].value, 'DD-MM-YYYY').endOf('month').format('MM/DD/YYYY');
                //         this.AccountRecouncile.controls["Bankdate"].setValue(new Date(LastDayOfMonth));
                //     }else{
                //         let DateCurrent = moment(this.AccountRecouncile.controls["LASTRECONCILEDDATE"].value, 'DD-MM-YYYY').format('MM/DD/YYYY');
                //         let convertDate = new Date(DateCurrent)
                //         convertDate.setDate(convertDate.getDate() + 1);

                //         LastDayOfMonth =  moment(convertDate).endOf('month').format('MM/DD/YYYY');
                //         this.AccountRecouncile.controls["Bankdate"].setValue(new Date(LastDayOfMonth))
                //     }
                // }


                // if (selectAccount.length > 0) {
                // let LastDayOfMonth: any = new Date();
                // let setChatAccountData = JSON.parse(localStorage.getItem('setChartAccountDataEdit'));
                // let selectAccount = setChatAccountData.SUBACCOUNTS.filter((e) => e.ACCOUNTGUID == this.chartAccountDetail.ACCOUNTGUID);
                //     LastDayOfMonth =(selectAccount[0].NEXTRECONCILEDDATE && selectAccount[0].NEXTRECONCILEDDATE !=="")?moment(selectAccount[0].NEXTRECONCILEDDATE , 'DD/MM/YYYY').format('MM/DD/YYYY') : new Date();
                //     this.lastDay = moment(LastDayOfMonth , 'MM/DD/YYYY').format('DD/MM/YYYY');
                //     this.AccountRecouncile.controls["Bankdate"].setValue(new Date(LastDayOfMonth));
                //     this.AccountRecouncile.controls["Bankdate"].setValue(moment(data.BankStatementDate, 'DD/MM/YYYY'));
                // } else {
                //     this.AccountRecouncile.controls["Bankdate"].setValue(moment(data.BankStatementDate, 'DD/MM/YYYY'));
                // }

               // this.AccountRecouncile.controls["Bankdate"].setValue(moment(data.BankStatementDate, 'DD/MM/YYYY'));
                let INVOICEDATET = data.BankStatementDate.split("/");
                this.AccountRecouncile.controls["Bankdate"].setValue(new Date(INVOICEDATET[1] + '/' + INVOICEDATET[0] + '/' + INVOICEDATET[2]));

                if (response.DATA.RECONCILIATIONITEMS[0]) {
                    this.rowClick(response.DATA.RECONCILIATIONITEMS[0]);
                    this.highlightedRows = response.DATA.RECONCILIATIONITEMS[0].LINKGUID;
                    this.isDisplay = false;
                } else {
                    this.isDisplay = true;
                    this.AccountRecouncile.controls["UnDeposite"].setValue(0);
                    this.AccountRecouncile.controls["UnWith"].setValue(0);
                }
                this.selection.clear();
                response.DATA.RECONCILIATIONITEMS.forEach(o => {
                    if (o.TAGGED) {
                        this.selection.select(o);
                    }
                });
                let selectedCheckbox = JSON.parse(localStorage.getItem("SelectedCheckbox"));
                if (selectedCheckbox) {
                    selectedCheckbox.forEach((row) => {
                        response.DATA.RECONCILIATIONITEMS.find(o => {
                            if (o.LINKGUID === row.LINKGUID && o.NOTTAGGABLE == 0) {
                                o.TAGGED = 1;
                                this.selection.select(o);
                            }
                        });
                    });
                }
                this.helloFunction();
                this.setDefaultWidth(this.displayedColumns, 500);
                this.setDefaultWidth(this.showedColumns, 500);
            } else if (response.CODE == 406 && response.MESSAGE == "Permission denied") {
                this.toastr.error(response.MESSAGE);
                this.ReconciliationData = new MatTableDataSource([]);
                // this.ReconciliationData.paginator = this.paginator;
                this.sortingDate();
                this.isLoadingResults = false;
                this.setDefaultWidth(this.displayedColumns, 500);
                this.setDefaultWidth(this.showedColumns, 500);
            }
        }, (err) => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * This function is used to click on the row
     * @param e 
     */
    rowClick(e) {
        this.behaviorService.setRecounciliationItem(e);
    }

    /**
     * This function is used to open the dialog
     */
    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        dialogConfig.disableClose = true;
        dialogConfig.data = { data: this.ColumnsObj, type: "Reconciliation", list: "Reconciliation" };
        const dialogRef = this.dialog.open(SortingDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tempColobj = result.tempColobj;
                this.displayedColumns = result.columObj;
                this.dateColFilter = result.dateCol;
                this.displayedColumns.splice(0, 0, "select");
                this.ColumnsObj = result.columnameObj;
                this.showedColumns = [...this.displayedColumns];
                if(this.activeTab == 'ACCOUNTS RECONCILIATION') {
                    const array = ['CHEQUENO', 'CHEQUETOTAL', 'TRUSTREFERENCE'];
                    for(const element of array) {
                        const index = this.showedColumns.indexOf(element);
                        if (index > -1) {
                            this.showedColumns.splice(index, 1);
                        }
                    }
                }
                if(this.activeTab == 'TRUST ACCOUNTS RECONCILIATION') {
                    const array = ['CHEQUENO', 'CHEQUETOTAL'];
                    for(const element of array) {
                        const index = this.showedColumns.indexOf(element);
                        if (index > -1) {
                            this.showedColumns.splice(index, 1);
                        }
                    }
                }
                localStorage.setItem('recounciliation_list_columns', JSON.stringify({ 'displayedColumns': result.columObj, "ColumnsObj": result.columnameObj }));
                if (!result.columObj) {
                    this.ReconciliationData = new MatTableDataSource([]);
                    // this.ReconciliationData.paginator = this.paginator;
                    this.ReconciliationData.sort = this.sort;
                    this.isDisplay = true;
                } else {
                    this.LoadData({ AccountGuid: this.chartAccountDetail.ACCOUNTGUID, BankStatementDate: this.lastDay, UseTrust: (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust == true) ? true : false });
                }
            }
        });
    }

    /**
     * This function is used to set the Recouncil Item
     * @returns data
     */
    SetRecouncilItem() {
        this.sub11 = this.behaviorService.RecouncileItemSendSetData$.subscribe((result) => {
            this.recouncileItemdata = result;
        });
        if (Number(this.calCloingBalSend(this.f.OutBal.value)) != 0) {
            this.toastr.error('Out of Balance is not $0');
            return false;
        }

        let finalData = {
            DATA: this.recouncileItemdata,
            FormAction: "insert",
            UseTrust: (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust == true) ? true : false,
            VALIDATEONLY: true,
        };
        this.sub12 = this._mainAPiServiceService.getSetData(finalData, "SetReconciliation").subscribe((response) => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.warning(response.MESSAGE);
                this.checkValidation(response.DATA.VALIDATIONS, finalData);
            } else if (response.CODE == 451 && response.STATUS == "warning") {
                this.toastr.warning(response.MESSAGE);
                this.checkValidation(response.DATA.VALIDATIONS, finalData);
            } else if (response.CODE == 450 && response.STATUS == "error") {
                this.toastr.error(response.MESSAGE);
                this.checkValidation(response.DATA.VALIDATIONS, finalData);
            } else if (response.MESSAGE == "Not logged in") {
            }
        }, (err) => {
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check the validation data
     * @param bodyData -validation data
     * @param details -cvalidfation details
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == "No") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { Error: tempError, Warning: tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true, width: "100%", data: warningData,
            });
            this.confirmDialogRef.componentInstance.confirmMessage = "Are you sure you want to Save?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.RecouncileSaveData(details);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.RecouncileSaveData(details);
        }
    }

/**
 * This function is used to open the popup
 * @returns -data
 */
    openPopupForSaving(){
        return this.dialog.open(TrustMoneyReciptComponent, {
            width: '100%', data: { action: '', forPDF: 'Yes', PDFURL: null, reportTitle: 'Saving Reconciliation' , saveLoader:true}
        });
    };

    /**
     * This function is used to Save the recouncil data 
     * @param data data value
     */
    RecouncileSaveData(data: any) {
        data.VALIDATEONLY = false;
        let savewindow = this.openPopupForSaving();
        this.sub13 = this._mainAPiServiceService.getSetData(data, "SetReconciliation").subscribe(
            (response) => {
                savewindow.close();
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    this.toastr.success(" save successfully");
                    let confirmDilog = this.dialog.open(TrustMoneyReciptComponent, {
                        width: '100%', data: { action: 'https://reports.apitest.silq.com.au/', forPDF: 'Yes', PDFURL: response.DATA.PDFFILENAME, reportTitle: 'Reconciliation Item Report' }
                    });
                    confirmDilog.afterClosed().subscribe(result => {
                        if (result) {
                        }
                        confirmDilog = null;
                    });
                    this.refreshRecouncilItem();
                } else if (response.CODE == 451 && response.STATUS == "warning") {
                    this.toastr.warning(response.MESSAGE);
                } else if (response.CODE == 450 && response.STATUS == "error") {
                    this.toastr.error(response.MESSAGE);
                } else if (response.MESSAGE == "Not logged in") {
                }
            }, (error) => {
                this.toastr.error(error);
            });
    }

    /**
     * This function is used to refresh the RecouncilItem.
     */
    refreshRecouncilItem():void {
        this.LoadData({
            AccountGuid: this.chartAccountDetail.ACCOUNTGUID, BankStatementDate: this.lastDay, UseTrust: (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust == true) ? true : false,
        });
        // this.LoadData({ AccountGuid: "ACCAAAAAAAAAAAA4", 'BankStatementDate': "3/09/2019" });
    }

    /**
     * This function is used to choosed the Bank data
     * @param type 
     * @param event 
     */
    BankchoosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        try {
            this.hasDateRange = true;
            this.lastDay = this.datepipe.transform(event.value, "dd/MM/yyyy");
            this.changeDate = this.datepipe.transform(event.value, "dd/MM/yyyy");
            this.behaviorService.isbankStatmentDate(this.lastDay);
        } catch (error) {
            this.hasDateRange = false;
            this.lastDay = '';
            this.AccountRecouncile.controls["Bankdate"].setValue('');

        }
        this.LoadData({
            AccountGuid: this.chartAccountDetail.ACCOUNTGUID,
            BankStatementDate: this.lastDay,
            UseTrust: (this.accountTypeData && this.accountTypeData.UseTrust == "Yes" || this.accountTypeData && this.accountTypeData.UseTrust == true) ? true : false
        });
    }


    /**
     * Calculate the closing the balance data
     * @param val -value data
     * @returns closing data
     */
    calCLosingbalShow(val) {
        let isNumCalClosingBal = val.toString().replace(/[$,]/g, "");
        if (!isNaN(isNumCalClosingBal)) {
            this.previousPriceBal = isNumCalClosingBal;
            return Number(isNumCalClosingBal).toFixed(2);
        }
    }

    /**
     * This function is used to send the balance data.
     * @param val -value data
     * @returns daat
     */
    calCloingBalSend(val) {
        let sendBal = val.toString().replace(/[$,]/g, "");
        return Number(sendBal).toFixed(2);
    }

    /**
     * This function is used to download the file name
     * @param data -data name
     */
    downloadFile(data) {
        const sortedData = this.ReconciliationData.sortData(this.ReconciliationData.filteredData, this.ReconciliationData.sort);
        this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, sortedData, "ReconciliationData_data_", this.tempColobj);
        //this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, data, "ReconciliationData_data_", this.tempColobj);
    };

    /**
     * This functon is used to check the is last day or not
     * @param dt -date
     * @returns -data value
     */
    isLastDay(dt) {
        var test = new Date(dt.getTime()),
            month = test.getMonth();

        test.setDate(test.getDate() + 1);
        return test.getMonth() !== month;
    }

    /**
     * This function is used to set the prevent data.
     * @param event -event data
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * This function is used to format the date
     * @param date -date data
     * @returns data
     */
    formattedDate(date) {
        if(date) {
            return date._i;
        }
    }

    // onResizeColumnSetting(event , index){
    //     //event : selected column.
    //     //index : selected column index.
    //     //renderer : dont make this comman it's give issue import in your component.
    //     //displayedColumns : displayedColumns name's (Table Header names).
    //     //matTableRef : most importent please import in component wise don't make it comman.
    //     // last field is localstorage name which use to store columnObj
    
    //     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'recounciliation_list_columns');
    //   };
    
      //For resize this fuction is most impotent don't make it comman it's give issue in future.
      @HostListener('window:resize', ['$event'])
      onResize(event) {
         this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
      }
}
