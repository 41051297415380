import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { fuseAnimations } from 'src/@fuse/animations';
import { ContactSelectDialogComponent } from '../../contact/contact-select-dialog/contact-select-dialog.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSort } from '@angular/material/sort';
import * as $ from 'jquery';
import { BankingDialogComponent } from '../../banking/banking-dialog.component';
import { GenerateDocumentPopupComponent } from '../../template/generate-document-popup/generate-document-popup.component';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { ToolbarServiceService } from 'src/app/layout/components/toolbar/toolbar-service.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-receipt-dilog',
    templateUrl: './receipt-dilog.component.html',
    styleUrls: ['./receipt-dilog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ReceiptDilogComponent implements OnInit, OnDestroy {
    @ViewChild('payorInputField', { static: false }) payorInputField!: ElementRef;

    filteredOptions: Observable<string[]>;
    AllocationAmout: any = 0;
    AllocationData: any = [];
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    INDEX: number;
    confirmDialogRef: MatDialogRef<ReceiptDilogComponent>;
    confirmDialogRef1: MatDialogRef<ReceiptDilogComponent>;
    confirmDialogRef3: MatDialogRef<FuseConfirmDialogComponent>;
    isShowchecked: string;
    getPayourarray: any = [];
    data: { 'Outstanding': string; };
    incomeType: any;
    note: any;
    incometype: any;
    amountExGST: any;
    amountReceived: any;
    dateReceived: any;
    receiptCode: any;
    gst: any;
    receiptData: any;
    formaction: string;
    val: any;
    displayedColumns: string[] = ['INVOICECODE', 'INVOICETOTAL', 'AMOUNTOUTSTANDINGINCGST', 'ALLOCATED', 'MATTERGUID'];
    PrepareReceiptForm: FormGroup=this._formBuilder.group({
        INCOMECODE: [''],
        INCOMECLASS: ['Receipt'],
        INCOMETYPE: [''],
        PAYEE: [''],
        INCOMEDATE: [''],
        INCOMEDATETEXT: [],
        AMOUNT: ['',{ updateOn: 'blur' }],
        GST: [''],
        BANKACCOUNTGUID: [''],
        BANKACCOUNTGUIDTEXT: [''],
        NOTE: [''],
        INCOMEACCOUNTGUID: ['', Validators.required],
        INCOMEACCOUNTGUIDTEXT: [''],
        FIRMGUID: [''],
        FIRMGUIDTEXT: [''],
        Amount: [''],
        RECEIPTAMOUNTEXGST: [''],
        SHOW: [''],
        Unallocated: [''],
        allocatedSelected: ['',{ updateOn: 'blur' }],
        AMOUNTLESSCLERKFEE: ['',{ updateOn: 'blur' }],
        CLERKFEE: ['',{ updateOn: 'blur' }],
        CREDITTYPE:['']
    });;
    PrepareReceiptData: any;
    PrepareReceiptTemp: any;
    isspiner: boolean = false;
    isLoadingResults: boolean = false;
    highlightedRows: any;
    currentInvoiceData: any;
    lastFilter: any;
    theme_type = localStorage.getItem('theme_type');
    selectedInvoiceData: any;
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    ShowData: any = [];
    matterData: any;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    isEdit: boolean = false;
    AMOUNT: any = 0;
    AllocationBtn: string;
    TotalInvoice: any;
    storeDataarray: any;
    TempData: any;
    InvoiceTypeCheck: any;
    storeAllocatedVal: any;
    GloballyUnallocatedVAl: any;
    action: any;
    title: string;
    Warn: string;
    Warnpopup: string;
    warndataGet: any;
    whichTempGenerate: string;
    isProduct: any;
    GetSetMixRetry: boolean = true;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    AutoAllocationClick: boolean = true;

    testBool: boolean = false;

    ChartHandlingData: { ClickType: string; UseTrust: string; PopUp: string; Lable: string; };
    SelectedInvoiceIndex: number = -1;
    toolTipList: any;
    appPermissions = JSON.parse(localStorage.getItem('app_permissions'));
    searchContact: Subscription;
    DestryedContactdata: Subscription;
    private _unsubscribeAll$: Subject<void> = new Subject();
    CurrenctSymbol: string = '';
    sub1: Subscription;
    public data$;
    isClerkrate: string = '';
   // public data$=this.PrepareReceiptForm.controls['PAYEE'].valueChanges.pipe(debounceTime(450),distinctUntilChanged(),map(data=> this.onChangePayor(data)));
 
   constructor(
        public globalFunService: GlobalFunctionsService,
        private toastr: ToastrService,
        public behaviorService: BehaviorService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ReceiptDilogComponent>,
        public datepipe: DatePipe,
        public MatDialog: MatDialog,
        public _matDialog: MatDialog,
        public _mainAPiServiceService: MainAPiServiceService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public tooltipService: TooltipService, public toolbarServiceService: ToolbarServiceService,
        private currencyPipe: CurrencyPipe
    ) {
        this.behaviorService.CURRENCYSYMBOL$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res && res !== '') {
                this.CurrenctSymbol = res
            }
        })
        localStorage.setItem('istrackid', 'ReceiptDilogComponent');
        if (_data.ForWahat) {
            if (_data.ForWahat == "genDoc") {
                this.whichTempGenerate = _data.wichDocGen;
            } else {
                this.Warnpopup = "show";
                this.warndataGet = _data.WorningShow
            }
        } else {
            this.Warnpopup = "hide";
        }
        this.action = _data.action;
        this.receiptData = JSON.parse(localStorage.getItem('receiptData'));
        if (this.action == 'edit' || this.action == 'editForTB') {
            if (this.receiptData?.INCOMECLASS == "Write Off") {
                this.title = 'View Write off'
            } else {
                this.title = 'View Receipt';
            }
        } else {
            this.title = 'Prepare Receipt'
        }
        this.behaviorService.matterInvoice$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            this.selectedInvoiceData = result;
        });
        this.matterData = this._data.matterData;
        this.isEdit = this._data.action == 'edit' || this._data.action == 'view' ? true : false;

        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });

        this.isProduct = this.currentUser.PRODUCTTYPE == 'Solicitor' ? 'Client' : 'Firm';

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            let conditionEnable = true;
            if (localStorage.getItem('istrackid') == 'ReceiptDilogComponent' && this.GetSetMixRetry && result['click'] && (result['data']['SetIncome'] || result['data']['invoice'] || result['data']['income'] || result['data']['receipt-allocation'])) {
                this.ngOnInit();
                conditionEnable = false;
            }
            if (localStorage.getItem('istrackid') == 'ReceiptDilogComponent' && conditionEnable && result['click'] && result['data']['invoice']) {
                this.onChangeShow(this.InvoiceTypeCheck);
            }
            if (localStorage.getItem('istrackid') == 'ReceiptDilogComponent' && this.GetSetMixRetry == false && result['click'] && result['data']['SetIncome']) {
                this.SaveReceipt();
            }
        });
    }
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        // this.getPayor({});
        this.AllocationBtn = 'auto';
      
        //this.PrepareReceiptForm.controls["INCOMETYPE"].disable();
        // this.PrepareReceiptForm.controls['DatePaid'].disable();
        // let INCOMEDATEVAL = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
        // this.PrepareReceiptForm.controls['INCOMEDATE'].setValue(INCOMEDATEVAL);
        this.isShowchecked = "false";
        //for invoice
        if (this._data.action == 'editForTB' || this._data.action == 'edit' || this._data.action == 'view') {
            this.receiptData = JSON.parse(localStorage.getItem('receiptData'));
            let TBdata = JSON.parse(localStorage.getItem('TBreceiptData'));
            if (this._data.INCOMEGUID) {
                this.setInvoiceForReceipt(this._data.INCOMEGUID);
            } else if (this._data.action == 'editForTB')
                this.setInvoiceForReceipt(TBdata.INCOMEGUID);
            else if (this._data.action == 'edit' || this._data.action == 'view')
                this.setInvoiceForReceipt(this.receiptData.INCOMEGUID);
        } else if (this._data.action == 'add') {
            this.isLoadingResults = true;
            this._mainAPiServiceService.getSetData({ FormAction: 'default', VALIDATEONLY: false, Data: {} }, 'SetIncome').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let temincome = response.DATA.DEFAULTVALUES.INCOMECODE;
                    this.toolTipList = response.DATA.FIELDTIPS;
                    this.PrepareReceiptForm.controls['INCOMECODE'].setValue(temincome.toString().padStart(8, "0"));
                    this.PrepareReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTNUMBER);
                    this.PrepareReceiptForm.controls['BANKACCOUNTGUID'].setValue(response.DATA.DEFAULTVALUES.BANKACCOUNTGUID);
                    this.PrepareReceiptForm.controls['INCOMETYPE'].setValue(response.DATA.DEFAULTVALUES.INCOMETYPE);
                    let DatePaid = response.DATA.DEFAULTVALUES.INCOMEDATE.split("/");
                    let DATE = new Date(DatePaid[1] + '/' + DatePaid[0] + '/' + DatePaid[2]);
                    this.PrepareReceiptForm.controls['INCOMEDATE'].setValue(response.DATA.DEFAULTVALUES.INCOMEDATE);
                    this.PrepareReceiptForm.controls['INCOMEDATETEXT'].setValue(DATE);
                    if (this.currentUser.PRODUCTTYPE != 'Solicitor') {
                        this.isClerkrate = JSON.parse(localStorage.getItem('clerkrate'));
                        this.PrepareReceiptForm.controls['CLERKFEE'].setValue(response.DATA.DEFAULTVALUES.CLERKFEE);
                        this.UtilityAPI('Clark_Fee')
                    }
                    this.CheckdefultValidation(response.DATA.VALIDATIONS);
                    this.isLoadingResults = false;
                }
            }, error => {
                this.isLoadingResults = false;
                this.toastr.error(error);
            });
            this.PrepareReceiptForm.controls['FIRMGUID'].setValue(this.matterData.FIRMGUID);
            this.PrepareReceiptForm.controls['FIRMGUIDTEXT'].setValue(this.matterData.CONTACTNAME);
            this.PrepareReceiptForm.controls['SHOW'].setValue(1);
            this.ShowData.push({ id: 1, text: 'Show unpaid invoices for matter : ' + this.matterData.SHORTNAME });
            this.ShowData.push({ id: 2, text: 'Show unpaid invoices for client : ' + this.matterData.CONTACTNAME });
            this.ShowData.push({ id: 3, text: 'Show all unpaid invoices' });
            this.GetInvoiceForReceipt({ MATTERGUID: this.matterData.MATTERGUID, 'Outstanding': 'Yes' });
        }
        this.sub1 = this.PrepareReceiptForm.valueChanges.subscribe((x: any) => {
            this.changeForControl(x)
        });
        this.setTooltipData()
        this.getContactDataUsingBehaviour()
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(){
        if(this.payorInputField){
            this.data$=fromEvent(this.payorInputField.nativeElement, 'input').pipe(debounceTime(450),distinctUntilChanged(),map((event: any) =>this.onChangePayor(event.target.value)));
        }
    }
    
    /**
     * This function is used to get the Contact data Using Behaviour.
     */
    getContactDataUsingBehaviour() {
        this.DestryedContactdata = this.behaviorService.newContactData$.subscribe(result => {
            this.getPayourarray = [];
            if (result) {
                this.PrepareReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
            }
        })
    }

    ngOnDestroy() {
        this.DestryedContactdata?.unsubscribe();
        this.behaviorService.newContactData$.next(null);
        this.searchContact?.unsubscribe();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub1?.unsubscribe();
    }

    /**
     * This function is used to set the tooltip data.
     */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['Income']) {
            this.toolTipList = TooltipData['Income'];
        } else {
            this.tooltipService.setToolTipData = ('Income');
        }
    };

    /**
     * This function is used to update the toggle
     */
    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };

    /**
     * This function is used to change the control
     */
    changeForControl(formControl):void {
        //Error
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }
        //Warning
        const dubObj2 = { ...this.errorWarningDataArray.Warning };
        let element2 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj2)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element2[key];
                dubObj2[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj2 }
            } else if (!formControl[KEY]) {
                element2[key] = dubObj2[key];
            }
        }
    }

/**
 * This function is used to check the default validation data value.
 */
    CheckdefultValidation(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
    }

    /**
     * This function is used to set the Invoice For the receipt
     */
    setInvoiceForReceipt(INCOMEGUID) {
        this.PrepareReceiptData = [];
        this.isLoadingResults = true;
        let incomeGuid = { INCOMEGUID: INCOMEGUID };
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { INCOMEGUID: INCOMEGUID } }, 'income').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                if (response.DATA.RECORDS[0]) {
                    localStorage.setItem('receiptData', JSON.stringify(response.DATA.RECORDS[0]));
                    let data = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS;
                    this.PrepareReceiptForm.controls['INCOMECODE'].setValue(data.INCOMECODE.toString().padStart(8, "0"));
                    let FeeAgreementDate1 = data.INCOMEDATE.split("/");
                    this.PrepareReceiptForm.controls['INCOMEDATETEXT'].setValue(new Date(FeeAgreementDate1[1] + '/' + FeeAgreementDate1[0] + '/' + FeeAgreementDate1[2]));
                    this.PrepareReceiptForm.controls['INCOMEDATE'].setValue(data.INCOMEDATE);
                    this.PrepareReceiptForm.controls['AMOUNT'].setValue(data.AMOUNT);
                    this.PrepareReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(data.BANKACCOUNTNUMBER);
                    this.PrepareReceiptForm.controls['BANKACCOUNTGUID'].setValue(data.BANKACCOUNTGUID);
                    this.PrepareReceiptForm.controls['FIRMGUID'].setValue(data.FIRMGUID);
                    this.PrepareReceiptForm.controls['FIRMGUIDTEXT'].setValue(data.CONTACTNAME);

                    this.PrepareReceiptForm.controls["INCOMETYPE"].disable();
                    if (data.INCOMECLASS == "Write Off") {
                        this.PrepareReceiptForm.controls['INCOMETYPE'].setValue('Write Off');
                        this.title = 'View Write off';
                    } else {
                        this.PrepareReceiptForm.controls['INCOMETYPE'].setValue(data.INCOMETYPE);
                        this.title = 'View Receipt';
                    };

                    this.PrepareReceiptForm.controls['NOTE'].setValue(data.NOTE);
                    this.PrepareReceiptForm.controls['PAYEE'].setValue(data.PAYEE);

                    this.ShowData.push({ id: 2, text: 'Show unpaid invoices for client : ' + data.PAYEE });
                    this.ShowData.push({ id: 3, text: 'Show all unpaid invoices' });
                }
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
        this.isLoadingResults = true;

        this._mainAPiServiceService.getSetData({Action: "GetData", Filters:{ "RECEIPTGUID": INCOMEGUID }}, 'receipt-allocation').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.PrepareReceiptData = new MatTableDataSource(response.DATA.RECORDS);
                this.PrepareReceiptData.paginator = this.paginator;
                this.PrepareReceiptData.sort = this.sort;
                if (response.DATA.RECORDS[0]) {
                    this.highlightedRows = response.DATA.RECORDS[0]['INVOICEGUID'];
                    this.currentInvoiceData = response.DATA.RECORDS[0];
                    this.clickRow(response.DATA.RECORDS[0]);
                }
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });


// new IMPLIMENTAION

// this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { INCOMEGUID: INCOMEGUID } }, 'income').pipe(
//     switchMap(data=>(combineLatest([of(data),this._mainAPiServiceService.getSetData({ "RECEIPTGUID": INCOMEGUID }, 'GetReceiptAllocation')])))
// ).subscribe(([response,response2])=>{
//     if(response && response !=null && response !=undefined){

//     }
//     if(response2){
//     }
// })

    }

    /**
     * This function is used to Open the Banking Dialog
     */
    BankingDialogOpen(type: any) {
        // for by default bank account select not trust account
        this.ChartHandlingData = { ClickType: 'WithoutTrust', UseTrust: 'No', PopUp: '', Lable: "Select Account", }
        localStorage.setItem('ChartURL', JSON.stringify(this.ChartHandlingData));
        this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);
        const dialogRef = this.MatDialog.open(BankingDialogComponent, {
            disableClose: true, width: '100%', data: { AccountType: type }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                localStorage.setItem('istrackid', 'ReceiptDilogComponent');
                this.PrepareReceiptForm.controls['BANKACCOUNTGUIDTEXT'].setValue(result.ACCOUNTCLASS + ' - ' + result.ACCOUNTNUMBER + ' ' + result.ACCOUNTNAME);
                this.PrepareReceiptForm.controls['BANKACCOUNTGUID'].setValue(result.ACCOUNTGUID);
            }
        });
    }

    /**
     * This function is used to get the Invoice For Receipt.
     */
    GetInvoiceForReceipt(data) {
        this.PrepareReceiptData = [];
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData({ "Action": "GetData", Filters: data }, 'invoice').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE === 200 && (response.STATUS === "OK" || response.STATUS === "success")) {
                this.TotalInvoice = response.DATA.TOTALOUTSTANDING;
                this.PrepareReceiptData = new MatTableDataSource(response.DATA.RECORDS);
                this.PrepareReceiptData.paginator = this.paginator;
                this.PrepareReceiptData.sort = this.sort;
                if (response.DATA.RECORDS[0]) {
                    this.highlightedRows = response.DATA.RECORDS[0]['INVOICEGUID'];
                    this.clickRow(response.DATA.RECORDS[0]);
                    this.currentInvoiceData = response.DATA.RECORDS[0];
                }
                if (this.currentUser.DontAutoAllocateReceipt) {
                    this.SelectedInvoiceIndex = response.DATA.RECORDS.map((el) => el.INVOICEGUID).indexOf(this.selectedInvoiceData?.INVOICEGUID);
                    if (this.SelectedInvoiceIndex > -1) {
                        this.highlightedRows = response.DATA.RECORDS[this.SelectedInvoiceIndex]['INVOICEGUID'];
                        this.clickRow(response.DATA.RECORDS[this.SelectedInvoiceIndex]);
                        this.currentInvoiceData = response.DATA.RECORDS[this.SelectedInvoiceIndex];
                    }
                    this.AutoAllocationClick = false;
                }
                this.isLoadingResults = false;
                if (this.f.AMOUNT.value > 0) {
                    this.clickAutoAllocation();
                }
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.isLoadingResults = false;
            this.toastr.error(error);
        });
    }

    get f() {
        return this.PrepareReceiptForm.controls;
    }

    /**
     * This function is used to choosed the date.
     */
    choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.PrepareReceiptForm.controls['INCOMEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * This function is used to onChange the show.
     */
    onChangeShow(val) {
        this.GetSetMixRetry = false;
        this.InvoiceTypeCheck = val;
        let data = {};
        if (val == 3) {
            // this.AllocationBtn = "clear"
            data = { 'Outstanding': 'Yes' };
        } else if (val == 1) {
            data = { MATTERGUID: this.matterData.MATTERGUID, 'Outstanding': 'Yes' };
        } else if (val == 2) {
            data = { CONTACTGUID: this.matterData.CONTACTGUID, 'Outstanding': 'Yes' };
        }
        this.GetInvoiceForReceipt(data);
    }

    /**
     * This function is used to change the amount.
     */
    amountChange(element, field) {
        const numbers = this.PrepareReceiptForm.controls[field].value;
        // if(this.PrepareReceiptForm.controls[field].value) {
        //     const formattedAmount = this.currencyPipe.transform(this.PrepareReceiptForm.controls[field].value, this.CurrenctSymbol, 'symbol', '1.2-2');
        //     element.target.value = formattedAmount;
        // }
        if(numbers) {
            const numericValue = parseFloat(numbers.replace(/,/g, '')); // Remove commas and convert to a number
            const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.2-2'); // Use DecimalPipe
            element.target.value = formattedNumber;
          }
    }

    /**
     * This function is used to review the amount
     */
    revivedAmount() {
        if (this.f.AMOUNT.value > this.TotalInvoice) {
            let val = this.f.AMOUNT.value - this.TotalInvoice;
            this.PrepareReceiptForm.controls['Unallocated'].setValue(val.toFixed(2));
            this.GloballyUnallocatedVAl = this.f.Unallocated.value;
        } else {
            if (this.AllocationBtn == 'clear') {
                this.PrepareReceiptForm.controls['Unallocated'].setValue(this.f.AMOUNT.value);
                this.GloballyUnallocatedVAl = this.f.Unallocated.value;
            } else {
                this.PrepareReceiptForm.controls['Unallocated'].setValue(0);
                this.GloballyUnallocatedVAl = this.f.Unallocated.value;
            }
        }
        this.AMOUNT = Number(parseFloat(this.f.AMOUNT.value).toFixed(2));
        let clickedrowIndex = 0;
        if (this.SelectedInvoiceIndex > -1) {
            clickedrowIndex = this.SelectedInvoiceIndex;
        }
        this.clickRow(this.PrepareReceiptData.data[clickedrowIndex]);
        this.checkCal(this.PrepareReceiptData.data, 'autoAllocation', this.f.AMOUNT.value);
    }

    /**
     * This function is used to set the utility API.
     */
    UtilityAPI(key: any) {
        this.isspiner = true;
        let Payload = {
            "Action": "CalcClerkFee",
            "Data": {
                // "AMOUNTRECEIVED": this.f.AMOUNT.value,
                // "CLERKFEE": this.f.CLERKFEE.value ? this.f.CLERKFEE.value : '',
                // "AMOUNTLESSCLERKFEE": this.f.AMOUNTLESSCLERKFEE.value ? this.f.AMOUNTLESSCLERKFEE.value : ''
            },
        }
        switch (key) {
            case 'Amt_Less_Clark_Fee':
                Payload.Data["AMOUNTLESSCLERKFEE"] = this.f.AMOUNTLESSCLERKFEE.value
                break;
            case 'Clark_Fee':
                Payload.Data["CLERKFEE"] = this.f.CLERKFEE.value
                break;
            case 'Amount_Received':
                Payload.Data["AMOUNTRECEIVED"] = this.f.AMOUNT.value

                break;
            default:
                break;
        }
        this._mainAPiServiceService.getSetData(Payload, 'utility').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                const cf = response.DATA.CLERKFEE ? response.DATA.CLERKFEE : this.f.CLERKFEE.value;
                this.PrepareReceiptForm.controls['CLERKFEE'].setValue(Number(cf).toFixed(2));
                const acf = response.DATA.AMOUNTLESSCLERKFEE ? response.DATA.AMOUNTLESSCLERKFEE : this.f.AMOUNTLESSCLERKFEE.value;
                this.PrepareReceiptForm.controls['AMOUNTLESSCLERKFEE'].setValue(Number(acf).toFixed(2));
                const amt = response.DATA.AMOUNTRECEIVED ? response.DATA.AMOUNTRECEIVED : this.f.AMOUNT.value;
                this.PrepareReceiptForm.controls['AMOUNT'].setValue(Number(amt).toFixed(2));
            }
            this.isspiner = false;
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });

    }

    /**
     * THis function is used to check the calculation data.
     */
    checkCal(data, checkval, ValEnterByUser) {
        if (typeof data != "undefined") {
            let enteredval = 0;
            let i = 0;
            data.forEach(element => {
                element.ALLOCATED = 0;
            });
            if (checkval == 'autoAllocation') {
                enteredval = Number(ValEnterByUser);
                if (Number(enteredval) > 0 && this.SelectedInvoiceIndex > -1 && !this.currentUser.DontAutoAllocateReceipt && !this.AutoAllocationClick) {
                    if (Number(data[this.SelectedInvoiceIndex].AMOUNTOUTSTANDINGINCGST) <= Number(enteredval)) {
                        data[this.SelectedInvoiceIndex].ALLOCATED = (Number(data[this.SelectedInvoiceIndex].AMOUNTOUTSTANDINGINCGST)).toFixed(2);
                        enteredval = enteredval - data[this.SelectedInvoiceIndex].AMOUNTOUTSTANDINGINCGST;
                    } else {
                        data[this.SelectedInvoiceIndex].ALLOCATED = (Number(enteredval)).toFixed(2);
                        enteredval = enteredval - data[this.SelectedInvoiceIndex].AMOUNTOUTSTANDINGINCGST;
                    }
                }
                // let ALLOCATEDValue
                // data.find(element => {

                //     if (element.AMOUNTOUTSTANDINGINCGST == enteredval) {
                //         ALLOCATEDValue = element.ALLOCATED = enteredval;
                //     }
                // })
                const firstMatchingElement = data.find(element => element.AMOUNTOUTSTANDINGINCGST == enteredval);
                if (firstMatchingElement) {
                    firstMatchingElement.ALLOCATED = enteredval;
                }
                if (!firstMatchingElement?.ALLOCATED) {
                    for (i = 0; data.length > i; i++) {
                        if (Number(enteredval) > 0 && data[i] && (!this.currentUser.DontAutoAllocateReceipt || this.AutoAllocationClick)) {
                            if (Number(data[i].AMOUNTOUTSTANDINGINCGST) <= Number(enteredval)) {
                                data[i].ALLOCATED = (Number(data[i].AMOUNTOUTSTANDINGINCGST)).toFixed(2);
                                enteredval = enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                            } else {
                                data[i].ALLOCATED = (Number(enteredval)).toFixed(2);
                                enteredval = enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                            }
                        } else if (Number(enteredval) > 0 && data[i] && this.currentUser.DontAutoAllocateReceipt && data[i].INVOICEGUID == this.selectedInvoiceData.INVOICEGUID && !this.AutoAllocationClick) { ///dont Auto Allocation
                            if (Number(data[i].AMOUNTOUTSTANDINGINCGST) <= Number(enteredval)) {
                                data[i].ALLOCATED = (Number(data[i].AMOUNTOUTSTANDINGINCGST)).toFixed(2);
                                enteredval = enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                            } else {
                                data[i].ALLOCATED = (Number(enteredval)).toFixed(2);
                                enteredval = enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                            }
                        } else if (Number(enteredval) > 0 && data[i] && !this.AutoAllocationClick) {
                            if (Number(data[i].AMOUNTOUTSTANDINGINCGST) <= Number(enteredval)) {
                                data[i].ALLOCATED = (Number(data[i].AMOUNTOUTSTANDINGINCGST)).toFixed(2);
                                enteredval = enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                            } else {
                                data[i].ALLOCATED = (Number(enteredval)).toFixed(2);
                                enteredval = enteredval - data[i].AMOUNTOUTSTANDINGINCGST;
                            }
                        }
                        // if (data[i] && data[i].INVOICEGUID != this.selectedInvoiceData.INVOICEGUID){
                        //   data[i].ALLOCATED = 0;
                        // }
                        // if ((data[i] && data[i].INVOICEGUID == this.selectedInvoiceData.INVOICEGUID) && this.AllocationBtn != 'auto') {
                        //   data[i].ALLOCATED = (Number(ValEnterByUser)).toFixed(2);
                        // }
                    }
                }
            }
            let clickedrowIndex = 0;
            if (this.SelectedInvoiceIndex > -1) {
                clickedrowIndex = this.SelectedInvoiceIndex;
            }
            this.clickRow(this.PrepareReceiptData.data[clickedrowIndex]);
            this.chekUnallocatedAmount();
        }
    }

    /**
     * This function is used to Apply the Receipt
     */
    ApplyReceipt() {
        this.SingalrowAllocation();
    }

    /**
     * This function is used to click the row
     */
    clickRow(row: any) {
        // this.PrepareReceiptData.data
        this.currentInvoiceData = row;
        this.selectedInvoiceData = row;
        if (this.PrepareReceiptData.data) {
            const indexData = this.PrepareReceiptData.data.findIndex(invoice => invoice.INVOICEGUID === row.INVOICEGUID);
            this.INDEX = indexData;
            this.SelectedInvoiceIndex = this.INDEX;
        }
        if(row && row?.ALLOCATED) {
            this.PrepareReceiptForm.controls['allocatedSelected'].setValue(row.ALLOCATED);
        }
    }

    /**
     * This functionis used to get the single row Allowcation.
     */
    SingalrowAllocation() {
        if (Number(this.f.allocatedSelected.value) <= Number(this.currentInvoiceData.AMOUNTOUTSTANDINGINCGST)) {
            this.PrepareReceiptData.data[this.INDEX].ALLOCATED = Number(this.f.allocatedSelected.value);
            this.highlightedRows = this.PrepareReceiptData.data[this.INDEX]['INVOICEGUID'];
        } else {
            this.toastr.error('You are trying to allocate an amount that is more than the amount outstanding.');
            this.PrepareReceiptForm.controls['allocatedSelected'].setValue(0);
            this.PrepareReceiptData.data[this.INDEX].ALLOCATED = 0;
        }
        this.chekUnallocatedAmount();
    }

    /**
     * This function is used to check the Unallocated Amount.
     */
    chekUnallocatedAmount() {
        let allotedValue: number = 0;
        this.PrepareReceiptData.data.forEach(element => {
            allotedValue = Number(allotedValue) + Number(element.ALLOCATED);
        });
        let FinalAmount = Number(this.f.AMOUNT.value) - Number(allotedValue.toFixed(2));
        this.PrepareReceiptForm.controls['Unallocated'].setValue(FinalAmount);
    }

    /**
     * This function is used to click on Auto Allocation.
     */
    clickAutoAllocation(click: any = '') {
        // if (this.InvoiceTypeCheck != 3) {
        this.AllocationBtn = 'auto';
        if (click == 'AutoAllocation') {
            this.AutoAllocationClick = true;
            const selInvoiceIndex = this.PrepareReceiptData.data.findIndex((data) => data.INVOICEGUID == this.currentInvoiceData.INVOICEGUID);
            this.SelectedInvoiceIndex = selInvoiceIndex !== -1 ? selInvoiceIndex : -1;
        }
        this.checkCal(this.PrepareReceiptData.data, 'autoAllocation', this.f.AMOUNT.value);
        if (this.currentUser.DontAutoAllocateReceipt) {
            this.highlightedRows = this.PrepareReceiptData.data[this.SelectedInvoiceIndex].INVOICEGUID;
        }
        // }
    }

    /**
     * This function is used to click the clear allocation.
     */
    clickClearAllocation() {
        // if (this.InvoiceTypeCheck != 3) {
        this.PrepareReceiptForm.controls['Unallocated'].setValue(0);
        this.GloballyUnallocatedVAl = this.f.Unallocated.value;
        this.AllocationBtn = 'clear';
        this.checkCal(this.PrepareReceiptData.data, 'clearAllocation', this.f.AMOUNT.value);
        if (this.currentUser.DontAutoAllocateReceipt) {
            this.highlightedRows = this.PrepareReceiptData.data[this.SelectedInvoiceIndex].INVOICEGUID;
        }
        // }
    }

    /**
     * This function is used to save the Receipt
     */
    SaveReceipt() {
        this.GetSetMixRetry = false;
        this.AllocationData = [];
        this.PrepareReceiptData.data.forEach(element => {
            this.AllocationData.push({ INVOICEGUID: element.INVOICEGUID, AMOUNTAPPLIED: element.ALLOCATED })
        });
        this.isspiner = true;
        this.PrepareReceiptTemp = this.PrepareReceiptData.data;
        this.AllocationAmout = Number(this.f.AMOUNT.value);

        let AllocationDataInsert: any = {
            INCOMECODE: this.f.INCOMECODE.value,
            INCOMECLASS: this.f.INCOMECLASS.value,
            INCOMETYPE: this.f.INCOMETYPE.value,
            // INCOMETYPE:'Write Off',
            FIRMGUID: this.f.FIRMGUID.value,
            INCOMEDATE: this.f.INCOMEDATE.value,
            PAYEE: this.f.PAYEE.value,
            AMOUNT: this.f.AMOUNT.value,
            GST: this.f.GST.value,
            BANKACCOUNTGUID: this.f.BANKACCOUNTGUID.value,
            INCOMEACCOUNTGUID: "ACCAAAAAAAAAAAA9",
            NOTE: this.f.NOTE.value,
            MATTERGUID: this.matterData.MATTERGUID,
            CLERKFEE: this.f.CLERKFEE.value,
            AMOUNTLESSCLERKFEE: this.f.AMOUNTLESSCLERKFEE.value,
            ALLOCATIONS: this.AllocationData,
            CREDITTYPE:this.f.CREDITTYPE.value
        };
        let setReceiptPostData: any = { FormAction: 'insert', VALIDATEONLY: true, DATA: AllocationDataInsert };
        this._mainAPiServiceService.getSetData(setReceiptPostData, 'SetIncome').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.DATA.INCOMECODE && response.DATA.INCOMECODE != '') {
                this.PrepareReceiptForm.controls['INCOMECODE'].setValue(response.DATA.INCOMECODE.toString().padStart(8, "0"));
                AllocationDataInsert.INCOMECODE = response.DATA.INCOMECODE;
            } else {
                AllocationDataInsert.INCOMECODE = this.f.INCOMECODE.value;
            }
            setReceiptPostData = { FormAction: 'insert', VALIDATEONLY: true, DATA: AllocationDataInsert };
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.checkValidation(response.DATA.VALIDATIONS, setReceiptPostData);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.checkValidation(response.DATA.VALIDATIONS, setReceiptPostData);
                this.isspiner = false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.checkValidation(response.DATA.VALIDATIONS, setReceiptPostData);
                this.isspiner = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation data value.
     */
    async checkValidation(bodyData: any, details: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'Warning') {
        //     if (value.FIELDNAME != "INCOMECODE") {
        //       warningData.push(value.ERRORDESCRIPTION);
        //     }
        //     tempWarning[value.FIELDNAME] = value;
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };

        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   this.isspiner = false;
        //   // this.toastr.warning(warningData);
        //   this.confirmDialogRef3 = this.MatDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
        //   this.confirmDialogRef3.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef3.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.SaveReceiptAfterVAlidation(details);
        //     }
        //     this.confirmDialogRef3 = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.SaveReceiptAfterVAlidation(details);
        // }
        await this.globalFunService.checkValidation(bodyData, details)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.SaveReceiptAfterVAlidation(details);
                    } else {
                        this.isspiner = false;
                    }
                }
            });
    }

    /**
     * This function is used to Save the Receipt After validation data
     */
    SaveReceiptAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'SetIncome').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                $('#refreshReceiceMoany').click();
                $('#refresheReceiptsTab').click();
                $('#refreshMatterInvoice').click();
                $('#getmatterinvoice').click();
                $('#refreshInvoiceTab').click()
                $('#refreshreciptsDAta').click()
                this.toastr.success('Receipt save successfully');
                this.dialogRef.close(false);
                // As per client requirement hide the popup
                //but currently again show
                let DONTGENERATERECEIPT=JSON.parse(localStorage.getItem('currentUser'));
                 if((DONTGENERATERECEIPT?.DONTGENERATERECEIPT == 0 || DONTGENERATERECEIPT?.DONTGENERATERECEIPT == false)){
                    this.ganrateDoc(response.DATA.INCOMEGUID);
                 }
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            } else {
                this.isspiner = false;
            };
            this.isspiner = false;
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to generate the Document.
     */
    ganrateDoc(INCOMEGUID) {
        localStorage.setItem('INCOMEGUID_re', INCOMEGUID);
        let warnshow: any = [];
        // if (Number(this.f.Unallocated.value) == 0) {
            this.isspiner = true;
            this.confirmDialogRef = this._matDialog.open(ReceiptDilogComponent, {
                disableClose: true, width: '100%', data: { ForWahat: 'genDoc', wichDocGen: "matter", matterData: this.matterData }
            });
            this.confirmDialogRef.afterClosed().subscribe(result => {
                this.isspiner = false;
                if (result) {
                    this.isspiner = true;
                    this.dialogRef.close(true);
                }
                this.confirmDialogRef = null;
            });
        // } 
        // else {
        //     warnshow.push('SILQ will save it as a credit which you will be able to apply against your next invoice.' + '\n',
        //         'Do you want to save this credit for the matter or for the firm?' + '\n');
        //     this.confirmDialogRef1 = this._matDialog.open(ReceiptDilogComponent, {
        //         disableClose: true, width: '100%', data: { WorningShow: warnshow, ForWahat: 'warningShow', matterData: this.matterData, INCOMEGUID: INCOMEGUID }
        //     });
        //     this.confirmDialogRef1.afterClosed().subscribe(result => {
        //         this.isspiner = false;
        //         if (result) {
        //             this.isspiner = true;
        //             this.confirmDialogRef = this._matDialog.open(ReceiptDilogComponent, {
        //                 disableClose: true, width: '100%', data: { ForWahat: 'genDoc', wichDocGen: result, matterData: this.matterData }
        //             });
        //             this.confirmDialogRef.afterClosed().subscribe(result => {
        //                 this.isspiner = false;
        //                 if (result) {
        //                     this.isspiner = true;
        //                     this.dialogRef.close(true);
        //                 }
        //                 this.confirmDialogRef = null;
        //             });
        //         }
        //         this.confirmDialogRef = null;
        //     });
        // }
    }

    /**
     * This function is used to select the client
     */
    selectClient(val) {
        const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: '' } });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'ReceiptDilogComponent');
            if (result) {
                if (val == 'client') {
                    this.PrepareReceiptForm.controls['FIRMGUIDTEXT'].setValue(result.CONTACTNAME);
                    this.PrepareReceiptForm.controls['FIRMGUID'].setValue(result.CONTACTGUID);
                } else {
                    this.PrepareReceiptForm.controls['PAYEE'].setValue(result.CONTACTNAME);
                }
            }
        });
    }

    /**
     * This function is used to get the payor.
     */
    getPayor(postData) {
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData(postData, 'contact').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.getPayourarray = response.DATA.RECORDS;
                this.filteredOptions = this.f.PAYEE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
                this.isLoadingResults = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            } else {
                this.isLoadingResults = false;
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.getPayourarray.filter(option => (option.CONTACTNAME.toLowerCase().includes(filterValue)));
    }

    /**
     * This function is used to payee Type change
     */
    PayeeTypeChange(value) {
        let avaiability = this.getPayourarray.filter(data => data.CONTACTNAME === value);
        if (Object.keys(avaiability[0]).length == 1) {
            this.toolbarServiceService.ContactsDialog('new', value);
            // this.contactData = [];
            this.PrepareReceiptForm.controls['PAYEE'].setValue(null);
            this.getPayourarray = [];
        } else {
            this.PrepareReceiptForm.controls['PAYEE'].setValue(value);
        }
    }

    /**
     * This function is used to generate the Document.
     */
    GenerateDoc() {
        // if (this.whichTempGenerate == 'matter') {
        //   this.ContextGuid = INCOMEGUID;
        // this.ContextGuid = this.matterData.MATTERGUID
        // } else {
        //   this.ContextGuid = INCOMEGUID;
        // }
        let passdata = { Context: 'Receipt', ContextGuid: localStorage.getItem('INCOMEGUID_re'), knownby: 'Template', Type: 'Template', Template: "<DEFRECTEMP>" };
        const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
            width: '100%', disableClose: true, data: passdata
        });
        dialogRef.afterClosed().subscribe(result => {
            localStorage.setItem('istrackid', 'ReceiptDilogComponent');
        });
    }

    /**
     * This function is used to close the all dialog
     */
    closeAllDialoge() {
        this.confirmDialogRef1.close(false);
    }

    /**
     * This function is used to on change payor get
     */
    onChangePayor(value) {
        this.getPayourarray = [];
        if (value.length == 0) {
            this.getPayourarray = [];
        }
        let payload = {
            "ACTION": "GetData",
            "FILTERS": {
                "FASTSEARCH": 1,
                "SEARCH": value
            },
        };
        this.searchContact = this._mainAPiServiceService.getSetData(payload, 'contact').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {

                if (response.DATA.RECORDS.length == 0) {
                    this.getPayourarray.push({ 'CONTACTNAME': value });
                } else {
                    this.getPayourarray = response.DATA.RECORDS;
                }
            }
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });


    }

    /**
     * This function is used to set the payorOnPressTab.
     */
    selPayorOnPressTab(evt) {
        if (Object.keys(this.getPayourarray[0]).length == 1) {
            this.toolbarServiceService.ContactsDialog('new', this.getPayourarray[0]["CONTACTNAME"]);
            // this.contactData = [];
            this.PrepareReceiptForm.controls['PAYEE'].setValue(null);
            this.getPayourarray = [];
        } else {
            const contactName = this.getPayourarray[0]["CONTACTNAME"]
            this.PrepareReceiptForm.controls['PAYEE'].setValue(contactName);
        }


    }
}
