<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<!-- <div class="jurisdiction-select">
    <div class="dropDown court_form_drop">
        <mat-form-field class="select-drop">
            <mat-label>Jurisdiction</mat-label>
            <mat-select [(ngModel)]="stateSelect" (selectionChange)="stateClassChange($event.value)">
                <mat-option *ngFor="let val of mainDataArray | keyvalue" [value]="val.key">{{val.key}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div> -->
<mat-divider class="mat-divider right_divide"></mat-divider>
<div id="court-form">
    <!-- <div class="court-header" [ngClass]="{'porpule-theme-on': theme_type =='theme-default'? true:false}"> -->
        <!-- <div class="top_flex_parent"> -->
        <!-- <div class="court-name">
            <h3>Court Forms</h3>
        </div>
        <div class="search-bar half_search_div"> -->
            <!-- don't t\remove id=matter-doc-template it's use in matter popup to hide search -->
            <!-- <div class="search-input-content" fxLayout="row" fxLayoutAlign="start center" id="matter-doc-template">
                <mat-icon class="secondary-text">search</mat-icon>
                <input [(ngModel)]="search" (keydown)="onSearch($event)" placeholder="Search">
            </div>
        </div>
        <div class="table-head"></div>
    </div> -->
    <div class="court-body">
        <div class="sidebar-court">
            <div class="side-two-content">
                <span *ngFor="let val of mainDataArray | keyvalue">
                    <div class="sidebar-content">
                        <!-- <p *ngIf="stateSelect == val.key" class="key_class"><strong>{{val.key}}</strong></p> -->
                        <div class="nav-item" *ngFor="let val2 of val.value">
                            <a class="nav-link" href="#" onclick="event.preventDefault()"
                                [ngClass]="{'active accent': whichFormSelect == val2.AREAOFLAWID,'porpule-theme-active': theme_type =='theme-default' && whichFormSelect == val2.AREAOFLAWID? true:false}"
                                (click)="formClick(val2)" *ngIf="stateSelect == val.key" class="key_subClass">
                                {{val2.DESCRIPTION}}</a>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="court-listing resizable-table">
            <!-- <div class="btn top_right_action_billing_position">
                <button mat-icon-button [matMenuTriggerFor]="contextMenu"  aria-label="More">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>
                <mat-menu #contextMenu="matMenu">
                    <ng-template matMenuContent let-item="item">
                        <button mat-menu-item (click)="courtFromRefresh()"> Reload Data</button>
                    </ng-template>
                </mat-menu>
            </div> -->
            <div *ngIf="isEmpty" class="no-data_class">
                <h2>There is no data to display</h2>
            </div>

            <mat-table matSort [dataSource]="courtFormdata" [@animateStagger]="{value:'50'}" class="inner-table">
                <ng-container matColumnDef="TYPEICON">
                    <!-- <mat-header-cell *matHeaderCellDef mwlResizable
                        (resizeEnd)="onResizeEnd($event, 'TYPEICON')"
                        mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                        <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                    </mat-header-cell> -->
                    <mat-cell *matCellDef="let row">
                        <mat-icon class="main-icon_inner">
                            <img src="assets/icons/web_app/icon_courtforms_d.ico"  matTooltip=""
                                matTooltipPosition="above">
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DESCRIPTION">
                    <!-- <mat-header-cell mat-sort-header *matHeaderCellDef mwlResizable
                        (resizeEnd)="onResizeEnd($event, 'DESCRIPTION')"
                        mwlResizeHandle
                      [resizeEdges]="{ right: true }">DESCRIPTION
                    </mat-header-cell>
                    <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                    rowClick -->
                    <mat-cell *matCellDef="let row"> {{row.DESCRIPTION }} </mat-cell>
                </ng-container>
                <!-- <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row> -->
                <mat-row *matRowDef="let row let index = index;  columns: displayedColumns;"
                    [style.background]="highlightedRows == index ? selectedColore : ''"
                    [ngClass]="highlightedRows == index ? 'row-text-colore' : ''" highlightedRows=index
                    (click)="rowClick(row);highlightedRows = index" (dblclick)='doubleClick(row)' matRipple>
                </mat-row>
            </mat-table>
            <!-- <div class="footer"> -->
                <!-- <mat-paginator fixed [pageSizeOptions]="[2000, 3000, 4000, 5000]" showFirstLastButtons></mat-paginator>  -->
               <!-- </div> -->

        </div>

    </div>

</div>
