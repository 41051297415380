import { AddressComponent } from './contact-dialog/address/address.component';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AssociationsComponent } from './contact-dialog/associations/associations.component';
import { AuthGuard } from '../../../_guards';
import { BankdetailsComponent } from './contact-dialog/Bank-Details/bankdetails/bankdetails.component';
import { CommonModule } from '@angular/common';
import { CompanyComponent } from './contact-dialog/company/company.component';
import { ContactComponent } from './contact.component';
import { ContactCorresDetailsComponent } from './contact-corres-details/contact-corres-details.component';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { ContactSelectDialogComponent } from './contact-select-dialog/contact-select-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { IdComponent } from './contact-dialog/id/id.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MattersComponent } from './contact-dialog/matters/matters.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MultiPersonComponent } from './contact-dialog/multi-person/multi-person.component';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { NotesComponent } from './contact-dialog/notes/notes.component';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { OtherComponent } from './contact-dialog/other/other.component';
import { PeopleComponent } from './contact-dialog/people/people.component';
import { PersonComponent } from './contact-dialog/person/person.component';
import { PhComponent } from './contact-dialog/ph/ph.component';
import { PhoneFormatPipe } from 'src/app/phone-format.pipe';
import { PipesModule } from '@_pipes/pipes.module';
import { RatesComponent } from './contact-dialog/rates/rates.component';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
  {
    path: '', component: ContactComponent,
    data: {
      title: 'Contact'
    }, canActivate: [AuthGuard]
  }
];

@NgModule({

  declarations: [
    ContactComponent,
    ContactDialogComponent,
    PersonComponent,
    PhComponent,
    AddressComponent,
    IdComponent,
    OtherComponent,
    CompanyComponent,
    ContactCorresDetailsComponent,
    ContactSelectDialogComponent,
    RatesComponent,
    PhoneFormatPipe,
    PeopleComponent,
    MattersComponent,
    MultiPersonComponent,
    AssociationsComponent,
    NotesComponent,
    BankdetailsComponent,


  ],
  imports: [
    MatChipsModule,
    PipesModule,
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatAutocompleteModule,
    //mat import
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    ScrollingModule,
    MatTabsModule,
    MatDialogModule,
    MatExpansionModule,

    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatSortModule,
    ApplicationPipesModule,
    // Ng2TelInputModule,
    ResizableModule,
    MatRadioModule,
    NumberMaskModule,
    NgxCurrencyModule
  ],
  exports: [
    ContactComponent
  ],
  entryComponents: [ContactDialogComponent, ContactCorresDetailsComponent, ContactSelectDialogComponent]
})
export class ContactModule {

  constructor() { }
}
