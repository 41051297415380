import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@_services/authentication.service';
import { BehaviorService } from '@_services/Behavior.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  isspiner: boolean = false;
  oldhide: boolean = true;
  phide: boolean = true;
  cnhide: boolean = true;
  sub1: Subscription;
  sub2: Subscription;

  postData: any = { "Request": "ChangePassword", "user": "", "password": "", "NewPassword": "" };
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private _formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    public behaviorService: BehaviorService,
  ) {
    localStorage.setItem('istrackid', 'ChangePasswordComponent');
    this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'ChangePasswordComponent' && result['click'] && result['data']['login']) {
        this.updatePassword();
      }
    });
   }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // let UserData: any = this.authenticationService.currentUserValue;
    this.changePasswordForm = this._formBuilder.group({
      // username: [UserData.USERNAME ? UserData.USERNAME : UserData.UserName],
      oldpassword: ['', Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  get f() {
    return this.changePasswordForm.controls;
  }
  
  /**
   * This function is used to update Password data 
   * @returns new data value
   */
  updatePassword() {
    if (this.f.password.value != this.f.confirmPassword.value) {
      this.toastr.error('Passwords must match');
      return false;
    }
    this.isspiner = true;
    // this.postData.user = this.f.username.value;
    this.postData.password = this.f.oldpassword.value;
    this.postData.NewPassword = this.f.password.value;
    this.authenticationService.changePassword(this.postData).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success('Password update successfully');
        this.dialogRef.close(true);
        // this.authenticationService.ForcLogout();
        localStorage.setItem('session_token', res['DATA'].SESSIONTOKEN);
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isspiner = false;
    }, err => {
      this.isspiner = false;
    });
  }
}
