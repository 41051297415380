<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container>
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->
<button *ngIf="!fuseConfig.layout.toolbar.hidden"
    (click)="toggleSidebarOpen('themeOptionsPanelHelp')" mat-icon-button
    class="mat-elevation-z2 theme-options-button help-button"
    [ngClass]="{'right-side-panel': fuseConfig.layout.sidepanel.position === 'right',
                    'side-panel-hidden': fuseConfig.layout.sidepanel.hidden === true}">
    <mat-icon>help_outline</mat-icon> <span>Help</span>

</button>

<fuse-sidebar *ngIf="!fuseConfig.layout.toolbar.hidden" name="themeOptionsPanel"
    class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <!-- <fuse-theme-options></fuse-theme-options> -->
    <!-- <app-preferences></app-preferences> -->
</fuse-sidebar>

<fuse-sidebar *ngIf="!fuseConfig.layout.toolbar.hidden"
    name="themeOptionsPanelHelp" class="theme-options-sidebar-help-desk"
    position="right" [invisibleOverlay]="true">
    <app-help-desk></app-help-desk>
</fuse-sidebar>

<fuse-sidebar *ngIf="!fuseConfig.layout.toolbar.hidden"
    name="BulkInvoicingWorkItemPanel" class="theme-options-sidebar-bulkInvoice-data"
    position="right" [invisibleOverlay]="true">
    <app-bulk-invoicing-workitem></app-bulk-invoicing-workitem>
</fuse-sidebar>

<fuse-sidebar *ngIf="!fuseConfig.layout.toolbar.hidden"
    name="firmDirectoryContactDetail"
    class="theme-user-firm-directory-contact-detail" position="right"
    [invisibleOverlay]="true">
    <app-user-firm-directory-contact-detail></app-user-firm-directory-contact-detail>
</fuse-sidebar>

<fuse-sidebar *ngIf="!fuseConfig.layout.toolbar.hidden" name="SetUpGuideOpen"
    class="theme-user-firm-directory-contact-detail" position="right"
    [invisibleOverlay]="true">
    <app-setup-guide></app-setup-guide>
</fuse-sidebar>
