<div class="drawer-header">
    <h5 class="details-text">Contact info</h5>
    <button mat-icon-button color="primary"
        aria-label="Example icon button with a home icon"
        (click)="toggleSidebarOpen('firmDirectoryContactDetail')">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="drawer-body" *ngIf="$UserDetail |async as userDetail">
    <div class="box-topblock">
        <div class="box-imageblock">
            <img class="box-image"
                [src]="(userDetail?.PHOTO !== '')?userDetail.PHOTO:'assets/images/profile/user.png'">
        </div>
        <div class="card-maintitle">{{userDetail?.FULLNAME}}</div>
        <div class="card-subtext">{{userDetail?.POSITION}}</div>
    </div>
    <div class="drawer-detailsblock" *ngIf="userflag">
        <h5 class="details-text">Details</h5>
        <ul class="details-listwrap" *ngIf="userDetail">
            <li *ngIf="userDetail?.EMAIL">
                <span>Email</span>
                {{userDetail?.EMAIL}}
            </li>
            <li *ngIf="userDetail?.PHONE1">
                <span>Phone number</span>
                {{userDetail?.PHONE1}}
            </li>

            <!-- <li *ngIf="userDetail?.OFFICEDESCRIPTION">
                <span>Office description</span>
                {{userDetail?.OFFICEDESCRIPTION}}
            </li> -->

            <li *ngIf="userDetail?.OFFICELOCATION">
                <span>Address</span>
                {{userDetail?.OFFICELOCATION}}
            </li>

            <li *ngIf="userDetail?.BIOGRAPHY">
                <span>Biography</span>
                <div class="biography_detail" *ngIf="userDetail?.BIOGRAPHY"
                    [innerHTML]="userDetail?.BIOGRAPHY"></div>
            </li>
        </ul>

        <!-- POST PAST -->
        <!-- <div class="post-wrap" >
            <mat-card *ngIf="currentUser.UserGuid == userDetail.USERGUID">
                <h3 class="details-text mb-12">Create Post</h3>
                <div class="display-flex flex-wrap ">
                    <div class="user-image">
                        <img class="image-fit" [src]="(userDetail?.PHOTO !== '')?userDetail.PHOTO:'assets/images/profile/user.png'" alt="" />
                    </div>
                    <mat-form-field appearance="outline" class="flex-grow-1 post-field">
                        <mat-label></mat-label>
                        <textarea matInput></textarea>
                      </mat-form-field>
                </div>
                <button mat-raised-button color="primary" style="background-color:#5a9440; ">Post</button>
            </mat-card>
            <mat-card>
                <div class="display-flex flex-wrap align-center justify-between">
                    <div class="display-flex align-center">
                        <div class="postblock-img">
                            <img class="image-fit" [src]="(userDetail?.PHOTO !== '')?userDetail.PHOTO:'assets/images/profile/user.png'" alt="" />
                        </div>
                        <div class="postblock-info">
                            <h6 class="username">Caroline Lundu</h6>
                            <span>29 minutes ago</span>
                        </div>
                    </div>
                    <div>
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item>
                              <mat-icon>dialpad</mat-icon>
                              <span>Redial</span>
                            </button>
                            <button mat-menu-item disabled>
                              <mat-icon>voicemail</mat-icon>
                              <span>Check voice mail</span>
                            </button>
                            <button mat-menu-item>
                              <mat-icon>notifications_off</mat-icon>
                              <span>Disable alerts</span>
                            </button>
                          </mat-menu>
                    </div>
                </div>

                <div class="cardbody">
                    <p>Look at that sky! I so want to be there.. Can we arrange a trip? Is that a possibility? Please!!!</p>
                </div>
            </mat-card>
        </div> -->
    </div>

    <div class="drawer-detailsblock" *ngIf="!userflag">
        This user details not available.
    </div>
</div>
