<ng-container *ngIf="!item.hidden">

    <ng-container *ngIf="showMenuItems()">
        <!-- item.URL -->

        <!---------------------START currentUser.OPENINNEWTAB == 1 ---------------------------->
        <a class="nav-link"
            (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActive]="['active', 'accent']"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
            *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && (item.TITLE !='Authorities' && item.TITLE !='Searching' && item.TITLE !='Diary' && item.TITLE !='Trust Money' && item.TITLE !='Trust End Of Month' && item.TITLE !='Safe Custody' && item.TITLE !='Tasks' && item.TITLE!='Inbox')">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <a class="nav-link"
            (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActive]="['active', 'accent']"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
            *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Authorities') && showAuth)">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <a class="nav-link"
            (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActive]="['active', 'accent']"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
            *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Inbox') && showInboxData)"
            >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <a class="nav-link"
            (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActive]="['active', 'accent']"
            [ngClass]="item.exactMatch?'accent':''"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
            *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Searching') && showSearching)"
            >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <a class="nav-link"
        (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
        [ngClass]="item.exactMatch?'accent':''"
        [routerLinkActive]="['active', 'accent']"
        [ngClass]="item.exactMatch?'accent':''"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Trust Money'  && showTrustData))"
        >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <a class="nav-link"
    (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActive]="['active', 'accent']"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Trust End Of Month'  && showTrustData))"
    >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
</a>

<a class="nav-link"
    (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActive]="['active', 'accent']"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Safe Custody'  && showSafeCustodyData))"
    >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
</a>

<a class="nav-link"
    (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActive]="['active', 'accent']"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Diary'  && showDairy))"
    >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
</a>

<a class="nav-link"
    (click)="navigateTab(item.URL, item.openInNewTab ? '_blank' : '_self',item.TITLE);"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActive]="['active', 'accent']"
    [ngClass]="item.exactMatch?'accent':''"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    *ngIf="currentUser.OPENINNEWTAB == 1 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Tasks'  && showshowTaskPipeData))"

    >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
</a>

      
        <!---------------------END currentUser.OPENINNEWTAB == 1 ---------------------------->

        <!---------------------START currentUser.OPENINNEWTAB == 0 ---------------------------->
        <a class="nav-link" [ngClass]="item.classes"
            *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && (item.TITLE !='Authorities' && item.TITLE !='Searching' && item.TITLE !='Diary' && item.TITLE !='Trust Money' && item.TITLE !='Trust End Of Month' && item.TITLE !='Safe Custody' && item.TITLE !='Tasks' && item.TITLE!='Inbox')"
            [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- New changes for Authorities -->
        <a class="nav-link" [ngClass]="item.classes"
        *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Authorities') && showAuth)"
        [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- this is for trust Money -->
      <!-- New changes for Inbox -->
        <a class="nav-link" [ngClass]="item.classes"
        *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Inbox') && showInboxData)"
        [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <a class="nav-link" [ngClass]="item.classes"
            *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Searching') && showSearching)"
            [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <a class="nav-link" [ngClass]="item.classes"
        *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Trust Money'  && showTrustData))"
        [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

         <!-- this is for trust Money -->
        <a class="nav-link" [ngClass]="item.classes"
        *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Trust End Of Month'  && showTrustData))"
        [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- this is for safe custody -->
        <a class="nav-link" [ngClass]="item.classes"
        *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Safe Custody'  && showSafeCustodyData))"
        [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- this is for Dairy -->
        <a class="nav-link" [ngClass]="item.classes"
        *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Diary'  && showDairy))"
        [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

         <!-- this is for Tasks -->
         <a class="nav-link" [ngClass]="item.classes"
         *ngIf="currentUser.OPENINNEWTAB == 0 && item.URL && !item.externalURL && !item.function && ((item.TITLE =='Tasks'  && showshowTaskPipeData))"
         [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']"
         [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
         <ng-container *ngTemplateOutlet="itemContent"></ng-container>
         </a>

         <!---------------------END currentUser.OPENINNEWTAB == 0 ---------------------------->

         
        <!--Old Code uncomment below code and comment upper code in for tab management-->
        <!-- <a class="nav-link" [ngClass]="item.classes" *ngIf="item.URL && !item.externalURL && !item.function" [routerLink]="[item.URL]" [routerLinkActive]="['active', 'accent']" [routerLinkActiveOptions]="{exact: item.exactMatch || false}" [target]="item.openInNewTab ? '_blank' : '_self'">
                    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>-->

        <!-- item.externalURL -->
        <a class="nav-link" [ngClass]="item.classes"
            *ngIf="item.URL && item.externalURL && !item.function"
            [href]="item.URL" [target]="item.openInNewTab ? '_blank' : '_self'">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- item.function -->
        <span class="nav-link" [ngClass]="item.classes"
            *ngIf="!item.URL && item.function" (click)="item.function()">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </span>

        <!-- item.URL && item.function -->
        <a class="nav-link" [ngClass]="item.classes"
            *ngIf="item.URL && !item.externalURL && item.function"
            (click)="item.function()" [routerLink]="[item.URL]"
            [routerLinkActive]="['active', 'accent']"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
            [target]="item.openInNewTab ? '_blank' : '_self'">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- item.externalURL && item.function -->
        <a class="nav-link" [ngClass]="item.classes"
            *ngIf="item.URL && item.externalURL && item.function"
            (click)="item.function()" [href]="item.URL"
            [target]="item.openInNewTab ? '_blank' : '_self'">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <ng-template #itemContent>

            <ng-container>
                <!-- <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon> -->
                <mat-icon class="nav-link-icon" *ngIf="item.icon">
                    <img *ngIf="item.TITLE=='Matters'"
                        src="assets/icons/web_app/icon_matter_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Matters':''">
                    <img *ngIf="item.TITLE=='Contacts'"
                        src="assets/icons/web_app/icon_contact_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Contacts':''">
                    <img *ngIf="item.TITLE=='Time entries'"
                        src="assets/icons/web_app/icon_time_cost_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Time entries':''">
                    <img *ngIf="(item.TITLE=='Diary') && showDairy"
                        src="assets/icons/web_app/icon_diary_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Diary':''">
                    <img *ngIf="item.TITLE=='Invoices'"
                        src="assets/icons/web_app/icon_invoice_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Invoices':''">
                    <img *ngIf="item.TITLE=='Spend money'"
                        src="assets/icons/web_app/icon_expenses_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Spend money':''">
                    <img *ngIf="item.TITLE=='Receive money'"
                        src="assets/icons/web_app/icon_receipt_small_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Receive money':''">
                    <img *ngIf="item.TITLE=='Generate document'"
                        src="assets/icons/web_app/icon_letters_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Generate document':''">
                    <img *ngIf="(item.TITLE=='Authorities') && showAuth"
                        src="assets/icons/web_app/icon_authorities_small_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Authorities':''">
                    <img *ngIf="item.TITLE=='Tasks' && showshowTaskPipeData"
                        src="assets/icons/web_app/icon_tasks_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Tasks':''">
                    <img *ngIf="item.TITLE=='Searching'"
                        src="assets/icons/web_app/icon_search_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Searching':''">
                    <img *ngIf="(item.TITLE=='Safe Custody') && showSafeCustodyData"
                        src="assets/icons/web_app/icon_safecustody_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Safe Custody':''">
                    <img *ngIf="(item.TITLE=='Trust Money') && showTrustData"
                        src="assets/icons/web_app/icon_trust_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Trust Money':''">
                    <img *ngIf="(item.TITLE=='Trust End Of Month') && showTrustData"
                        src="assets/icons/web_app/icon_trust_d.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Trust End Of Month':''">
                    <img *ngIf="item.TITLE=='Conflict Check'"
                        src="assets/icons/web_app/Select.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Conflict Check':''">
                    <img *ngIf="item.TITLE=='Inbox' && showInboxData"
                        src="assets/icons/web_app/icon_email_u.ico"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Inbox':''">
                        <!-- assets/icons/web_app/icon_reports_d.ico -->
                    <img *ngIf="item.TITLE=='Dashboard'"
                        src="assets/icons/web_app/dashboard icon 22.svg"
                        data-toggle="tooltip" data-placement="top"
                        [title]="isCollapsed ? 'Dashboard':''">
                    <!-- <img *ngIf="item.TITLE=='Trust Banking'" src="assets/icons/web_app/icon_trust_d.ico"  > -->
                </mat-icon>
                <!-- {{item | json}} -->
                <span class="nav-link-title" *ngIf="(item.TITLE !='Authorities' && item.TITLE !='Diary' && item.TITLE !='Trust Money' && item.TITLE !='Trust End Of Month' && item.TITLE !='Safe Custody' && item.TITLE !='Tasks'  &&  item.TITLE !='Inbox')">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Authorities') && showAuth">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Inbox') && showInboxData">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Diary') && showDairy">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Trust Money') && showTrustData">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Trust End Of Month') && showTrustData">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Safe Custody') && showSafeCustodyData">{{item.TITLE}}</span>
                <span class="nav-link-title" *ngIf="(item.TITLE =='Tasks') && showshowTaskPipeData">{{item.TITLE}}</span>


                <span class="nav-link-badge" *ngIf="item.badge"
                    [translate]="item.badge.translate"
                    [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
                    {{item.badge.TITLE}}
                </span>
                <!-- <mat-icon  *ngIf= 'ToggleData ' (click)="onChange(item.TITLE)" class="star nav-link-title"></mat-icon> -->

                <!-- <mat-icon  *ngIf="ToggleData == true || ToggleData == 'true'" (click)="onChange(item.TITLE); preventDefault($event);" class="star nav-link-title">
                {{"item.STAR == 'star'"?item.STAR:''}}</mat-icon> -->

                <!-- favourite icon -->
                <ng-container *ngIf="ToggleData == true || ToggleData == 'true'">
                    <mat-icon *ngIf="item.STAR != 'star'"
                        (click)="onChange(item.TITLE); preventDefault($event);"
                        class="star nav-link-title">star_border</mat-icon>
                    <mat-icon *ngIf="item.STAR == 'star'"
                        (click)="onChange(item.TITLE); preventDefault($event);"
                        class="star nav-link-title">star</mat-icon>
                </ng-container>
                <!-- ends here ~ favourite icon -->

            </ng-container>
        </ng-template>
        <!-- <mat-icon class="nav-link-icon nav-link-icon-div" (click)="onChange(item.TITLE)">delete_forever</mat-icon> -->
    </ng-container>
</ng-container>
