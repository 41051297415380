<div class="container" *ngIf="!openWebsite">
    <div class="row backGround"
        [ngClass]="(currentUser && currentUser.PRODUCTTYPE && currentUser.PRODUCTTYPE == 'Barrister')?'backGround-purple':'backGround-green'">
        <div>
            <img src="assets/images/logos/img_avatar.png" alt="Girl in a jacket"
                width="80" height="80"
                class="bordar">
        </div>
        <div>
            <p class="user-name"> {{currentUser?.UserName}}</p>
            <p class="info">let's find you an answer</p>
        </div>
        <div class="close-icon">
            <mat-icon (click)="close('themeOptionsPanelHelp')">clear</mat-icon>
        </div>
    </div>

    <div>
        <button *ngIf="searchResult && searchResult.length > 0"
            style="margin-left: 16px;" mat-button class="pading_left"
            (click)="backToHelp()"><mat-icon>keyboard_arrow_left</mat-icon>
            <span style="margin-top: 3px;"> Back </span> </button>
        <div class="row search-div sticky_search_div w-auto">
            <!-- <form class="">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Telephone</mat-label>
                  <input type="input" matInput placeholder="search answare">
                  <mat-icon matSuffix>mode_search</mat-icon>
                </mat-form-field>
              </form> -->
            <!-- <mat-form-field class="example-full-width search" appearance="outline">
        <mat-label>Search</mat-label> -->
            <!-- <input matInput placeholder="Search" (keyup.enter)="onSearch();" [(ngModel)]="searchFilter" formControlName="searchFilter"> -->
            <!-- <input matInput placeholder="Search" id="searchBox" [(ngModel)]="searchDataValue"
          (keyup)="searchData(searchDataValue)" class="search_input">
        <mat-icon matSuffix>search</mat-icon> -->
            <!-- <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let searchData of searchResult" [value]="searchData.subcategory" class="innarCardClass">
            <mat-card class="example-card">
              <mat-card-title>{{searchData.title}}</mat-card-title>
              <mat-card-subtitle>{{searchData.subcategory}}</mat-card-subtitle>
              <mat-card-content>
                <p [innerHtml]="senetizeHtml(searchData.description)"></p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button style="background-color: #F7F7F7;" (click)="goTo(searchData.url)">Knowlage base information</button>
              </mat-card-actions>
            </mat-card>
          </mat-option>
        </mat-autocomplete> -->
            <!-- </mat-form-field> -->

            <mat-form-field class="search example example-full-width-1"
                appearance="outline">
                <mat-label>Search</mat-label>
                <!-- <input matInput placeholder="Search" (keyup.enter)="onSearch();" [(ngModel)]="searchFilter" formControlName="searchFilter"> -->
                <input matInput placeholder="Search"
                    [(ngModel)]="searchDataValue"
                    (keyup)="onKeyUp($event)">
            </mat-form-field>
        </div>

        <!-- search card list -->
        <div class="row flex-column custom_height"
            *ngIf="searchResult && searchResult.length > 0">
            <mat-card class="example-card  search_card "
                *ngFor="let searchData of searchResult">
                <mat-card-title [innerHtml]="senetizeHtml(searchData.title)"></mat-card-title>
                <mat-card-subtitle>{{searchData.subcategory}}</mat-card-subtitle>
                <mat-card-content>
                    <p [innerHtml]="senetizeHtml(searchData.description)"></p>
                </mat-card-content>
                <mat-card-actions class="border_bottom">
                    <button mat-stroked-button
                        style="background-color: #F7F7F7;"
                        (click)="goTo(searchData.url)">Knowledge base
                        information</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div class="row div-card"
            *ngIf="searchResult && searchResult.length == 0 || !searchResult">
            <mat-card class="example-card example-full-width">
                <mat-card-title class="heading-font">Top searches for this page</mat-card-title>
                <mat-card-content>
                    <mat-list role="list" class="dark-Mode-white"
                        *ngIf="topMostResult">
                        <mat-list-item role="listitem"
                            class="list list_top_search"
                            *ngFor="let data of topMostResult;"
                            (click)="goTo(data.url)"
                            [innerHtml]="senetizeHtml(data.title)"></mat-list-item>
                        <!-- <mat-list-item role="listitem" class="list">Choose your worldflow actions</mat-list-item>
            <mat-list-item role="listitem" class="list">Connect form to the conversations inbox</mat-list-item>
            <mat-list-item role="listitem" class="list">Get help with HubSpot</mat-list-item> -->
                    </mat-list>
                    <p *ngIf="!topMostResult || topMostResult.length == 0 ">Result
                        not found!</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="row div-card"
            *ngIf="searchResult && searchResult.length == 0 || !searchResult">
            <mat-card class="example-card example-full-width">
                <mat-card-title class="heading-font">Support</mat-card-title>
                <mat-card-content>
                    <mat-list role="list">
                        <!-- <mat-list-item role="listitem " class="list btn_clicks">
              <mat-icon matSuffix class="icon">people</mat-icon>Ask the community
            </mat-list-item>
            <mat-list-item role="listitem " class="list btn_clicks">
              <mat-icon matSuffix class="icon">mode_inbox</mat-icon>View your support inbox
            </mat-list-item> -->
                        <mat-list-item role="listitem " class="list btn_clicks"
                            (click)="openDialog()">
                            <mat-icon matSuffix class="icon">sms</mat-icon>Contact
                            support
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</div>

<div class="example-loading-shade" *ngIf="loader" style="z-index: 99999;">
    <mat-spinner [style.zoom]="0.5" *ngIf="loader"></mat-spinner>
</div>

<div class="container" id="iframe-help-desk" *ngIf="openWebsite">
    <div class="row padding_10" id="help-desk-1" style="cursor: pointer;">
        <!-- <mat-icon (click)="backToHome()">keyboard_backspace</mat-icon> -->
        <button mat-button class="pading_left" (click)="backToHome()"><mat-icon>keyboard_arrow_left</mat-icon>
            <span style="margin-top: 3px;"> Back </span> </button>
        <div class="d-flex">
            <button mat-icon-button color="primary" (click)="backToHome()"><mat-icon>remove</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="backToHome()"><mat-icon>clear</mat-icon>
            </button>
        </div>

    </div>
    <div class="row new-tab" id="help-desk-2" (click)="newTab()">
        <p style="color:#00a4bd;font-weight: bold;"> Open in new tab </p>
        <mat-icon style="color:#00a4bd">open in new tab</mat-icon>
    </div>
    <div class="row">
        <iframe [src]="openWebsite" id='MainPopupIframe' width="100%" title
            class="ifream-height">
        </iframe>
    </div>
</div>
