<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div timeentry_popup  select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch"> {{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults==true">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults==true"></mat-spinner>
        </div> -->
        <div class="one mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="timeEntryForm" id="timeEntryForm" [formGroup]="timeEntryForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div class="one  mb-24" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <!-- <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                                <mat-label>Matter</mat-label>
                                <input matInput readonly placeholder="Placeholder" formControlName="matterautoVal" matTooltip="{{(toolTipList)?toolTipList.MATTERGUID:''}}"
                                [matTooltipPosition]="toolTipPostion">
                                <mat-icon matSuffix (click)='selectMatter()'>
                                    <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                                </mat-icon>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->

                            <mat-form-field appearance="outline" fxFlex="40" class="pr-4 input_error"  [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                                <mat-label>Matter</mat-label>
                                <mat-chip-list #chipList aria-label="Matter selection">
                                    <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                        {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                                        <button  matChipRemove class="canclebutton">
                                            <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input
                                        (keydown.Tab)="datashow($event)"
                                        formControlName="matterautoVal" #MatterInput1 [matAutocomplete]="autoMatter"
                                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'" required >
                                </mat-chip-list>
                                <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                                    <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                        <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon matSuffix (click)='selectMatter()' class="select-Matter-icon">
                                    <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                                </mat-icon>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MATTER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <mat-form-field *ngIf="'' | productType" fxFlex="20" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.FEEEARNER,'has-warning':errorWarningData.Warning?.FEEEARNER}">
                                <mat-label>Fee Earner</mat-label>
                                <mat-select formControlName="FEEEARNER" (selectionChange)="matterChange('FeeEarner',$event.value)"   [disabled]="('Create WIP for Other Fee Earners' | appPermissions : 'Create WIP for Other Fee Earners') ==0?true:false">
                                    <mat-option *ngFor="let user of userList" value="{{user.USERID}}">{{user.FULLNAME}}
                                    </mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEEARNER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FEEEARNER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="20" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ITEMDATE,'has-warning':errorWarningData.Warning?.ITEMDATE}">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" [(ngModel)]="ITEMDATEModel" formControlName="ITEMDATE"  matTooltip="{{(toolTipList)?toolTipList.ITEMDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                                <mat-label>Time of day</mat-label>
                                <input type="text" placeholder="Time of day" aria-label="Number" matInput (focusout)="getTime()" formControlName="ITEMTIME" [matAutocomplete]="auto"  matTooltip="{{(toolTipList)?toolTipList.ITEMTIME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of timeStops" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->

                            <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                                <mat-label>Time of day</mat-label>
                                <input class="time-input" type="time" placeholder="Time of day" aria-label="Number" matInput  formControlName="ITEMTIME"   matTooltip="{{(toolTipList)?toolTipList.ITEMTIME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMTIME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMTIME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <!-- <mat-form-field *ngIf="action!='Edit'" fxFlex="20" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTYPE,'has-warning':errorWarningData.Warning?.ITEMTYPE}">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="ITEMTYPE" (selectionChange)="itemTypeChange($event.value);">
                                    <mat-option value="wip">Time Entry</mat-option>
                                    <mat-option value="activity">Activity</mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->
                            <!-- <mat-form-field *ngIf="action=='Edit'" appearance="outline" fxFlex="25" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ITEMDATE,'has-warning':errorWarningData.Warning?.ITEMDATE}">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" [(ngModel)]="ITEMDATEModel" formControlName="ITEMDATE"  matTooltip="{{(toolTipList)?toolTipList.ITEMDATE:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <mat-form-field *ngIf="action=='Edit'" appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                                <mat-label>Time of day</mat-label>
                                <input type="text" placeholder="Time of day" aria-label="Number" matInput formControlName="ITEMTIME" [matAutocomplete]="auto" matTooltip="{{(toolTipList)?toolTipList.ITEMTIME:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of timeStops" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->
                        </div>
                        <!-- <div fxLayout="row" *ngIf="action!='Edit'" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ITEMDATE,'has-warning':errorWarningData.Warning?.ITEMDATE}">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" [(ngModel)]="ITEMDATEModel" formControlName="ITEMDATE"  matTooltip="{{(toolTipList)?toolTipList.ITEMDATE:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                                <mat-label>Time of day</mat-label>
                                <input type="text" placeholder="Time of day" aria-label="Number" matInput (focusout)="getTime()" formControlName="ITEMTIME" [matAutocomplete]="auto"  matTooltip="{{(toolTipList)?toolTipList.ITEMTIME:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of timeStops" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>


                        </div> -->
                        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field *ngIf="'' | productType" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.FEEEARNER,'has-warning':errorWarningData.Warning?.FEEEARNER}">
                                <mat-label>Fee Earner</mat-label>
                                <mat-select formControlName="FEEEARNER" (selectionChange)="matterChange('FeeEarner',$event.value)"   [disabled]="('Create WIP for Other Fee Earners' | appPermissions : 'Create WIP for Other Fee Earners') ==0?true:false">
                                    <mat-option *ngFor="let user of userList" value="{{user.USERID}}">{{user.FULLNAME}}
                                    </mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field fxFlex="30" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.QUANTITYTYPE,'has-warning':errorWarningData.Warning?.QUANTITYTYPE}">
                                <mat-label>{{QuantityTypeLabel}}</mat-label>
                                <mat-select formControlName="QUANTITYTYPE" (selectionChange)="quntityTypeChange('QuantityType',$event.value)">
                                    <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">
                                        {{Activity.DESCRIPTION}}
                                    </mat-option>
                                </mat-select>
                                <input type="text" matInput formControlName="QUANTITYTYPE" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">
                                        {{Activity?.ACTIVITYID}} &nbsp;&nbsp;&nbsp;&nbsp; {{Activity.DESCRIPTION}}
                                    </mat-option>
                                </mat-autocomplete>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                        </div> -->
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="17"  class="pr-4"   [ngClass]="{'has-error':errorWarningData.Error?.rateid,'has-warning':errorWarningData.Warning?.rateid}">
                                <mat-label>Rate ID</mat-label>
                                <!-- [disabled] = " _data.edit == 'Add'" -->
                                <mat-select formControlName="RATEID" (selectionChange)="reateId($event.value)">
                                    <mat-option *ngFor="let rateids of rateid" [value]="rateids.LOOKUPGUID">
                                        {{rateids.LOOKUPFULLVALUE}}</mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RATEID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RATEID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <mat-form-field fxFlex="17" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.QUANTITYTYPE,'has-warning':errorWarningData.Warning?.QUANTITYTYPE}">
                                <mat-label>{{QuantityTypeLabel}}</mat-label>
                                <mat-select formControlName="QUANTITYTYPE" (selectionChange)="quntityTypeChange('QuantityType',$event.value)">
                                    <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">
                                        {{Activity.DESCRIPTION}}
                                    </mat-option>
                                </mat-select>
                                <!-- <input type="text" matInput formControlName="QUANTITYTYPE" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">
                                        {{Activity?.ACTIVITYID}} &nbsp;&nbsp;&nbsp;&nbsp; {{Activity.DESCRIPTION}}
                                    </mat-option>
                                </mat-autocomplete> -->
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITYTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITYTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="17" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.QUANTITY,'has-warning':errorWarningData.Warning?.QUANTITY}">
                                <mat-label>Quantity</mat-label>
                                <input matInput formControlName="QUANTITY" (focusout)="matterChange('Quantity',$event)"  matTooltip="{{(toolTipList)?toolTipList.QUANTITY?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                                <mat-icon matSuffix class="secondary-text">quantity</mat-icon>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <mat-form-field appearance="outline" matTooltip="{{toltipText1}}&#13; {{toltipText2}}&#13;{{toltipText3}}" matTooltipClass="custom-tooltip" matTooltipPosition="above" fxFlex="17" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICE,'has-warning':errorWarningData.Warning?.PRICE}"  *ngIf="appPermissions[2]['View Balances'] == '1'">
                                <mat-label>Price (Ex GST)</mat-label>
                                <!-- currencyMask -->
                                <!-- [(ngModel)]="PRICEVAL" -->
                                <input class="sideText" matInput formControlName="PRICE" [(ngModel)]="PRICEVAL" (blur)="calcPE()"  matTooltip="{{(toolTipList)?toolTipList.PRICE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [value]="timeEntryForm.get('PRICE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true">
                                <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
                                <!-- <mat-icon matSuffix class="secondary-text">currency</mat-icon> -->
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRICE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="17" class="pr-4" *ngIf="!isGStFree && appPermissions[2]['View Balances'] == '1'" [ngClass]="{'has-error':errorWarningData.Error?.PRICEINCGST,'has-warning':errorWarningData.Warning?.PRICEINCGST}">
                                <mat-label>Price (Inc GST)</mat-label>
                                <!-- isreadonly -->
                                <!-- [(ngModel)]="PRICEINCGSTVAL" -->
                                <input class="sideText" matInput formControlName="PRICEINCGST" [(ngModel)]="PRICEINCGSTVAL" (blur)="calcPI()" matTooltip="{{(toolTipList)?toolTipList.PRICEINCGST?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" [value]="timeEntryForm.get('PRICEINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true">
                                <!-- <span class="currency_sign" matPrefix>$&nbsp;</span> -->
                                <!-- <mat-icon matSuffix class="secondary-text">currency</mat-icon> -->
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICEINCGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRICEINCGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" fxFlex="50"  class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.RATEID,'has-warning':errorWarningData.Warning?.RATEID}">
                                <mat-label>Rate ID</mat-label>
                                <input matInput formControlName="RATEID">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->


                            <mat-form-field appearance="outline" fxFlex="17"  class="pr-4" *ngIf="DerivableDropShow==false &&  ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1">
                                <mat-label>Deliverable</mat-label>
                                <!-- [disabled] = " _data.edit == 'Add'" -->
                                    <mat-select formControlName="MATTERDELIVERABLEGUID" (selectionChange)="radioChange($event.value)">
                                        <mat-option [ngClass]="data.DELIVERABLEDESCRIPTION.length >= 50 ? 'my-multi-lines-item my-multi-lines-text' : ''"  *ngFor="let data of Deliverable" [value]="data.MATTERDELIVERABLEGUID">{{data.DELIVERABLEDESCRIPTION}}</mat-option>
                                        <!-- <mat-option value="Mandatory">Mandatory</mat-option>
                                        <mat-option value="Optional">Optional</mat-option> -->

                                    </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DELIVERABLEDESCRIPTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DELIVERABLEDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                </mat-form-field>
                        </div>

                        <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.APPOINTMENTTIME,'has-warning':errorWarningData.Warning?.APPOINTMENTTIME}" appearance="outline" fxFlex="50" class="pr-4" *ngIf="CheckClick ==='No'">
                                <mat-label>Start</mat-label>
                                <input formControlName="APPOINTMENTTIME" placeholder="Reminder Time" type="time" matInput (ngModelChange)="StartTime($event)">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                            <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.APPOINTMENTENDTIME,'has-warning':errorWarningData.Warning?.APPOINTMENTENDTIME}" appearance="outline" fxFlex="50" class="pr-4" *ngIf="CheckClick==='No'">
                                <mat-label>Finish</mat-label>
                                <input formControlName="TimeSlot2" placeholder="Reminder Time" type="time" matInput (ngModelChange)="EndTime($event)">
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div> -->
                        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field fxFlex="100" class="pr-4" appearance="outline">
                                <mat-label>Select Text</mat-label>
                                <mat-select #LookupsSelect (selectionChange)='LookupsChange(LookupsSelect.value)'
                                    formControlName="ADDITIONALTEXTSELECT">
                                    <mat-option *ngFor="let Lookups of LookupsList" [value]="Lookups.LOOKUPFULLVALUE">
                                        {{Lookups.LOOKUPFULLVALUE}}</mat-option>
                                </mat-select>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>
                        </div> -->
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALTEXT,'has-warning':errorWarningData.Warning?.ADDITIONALTEXT}">
                                <mat-label>Invoice Text</mat-label>
                                <textarea class="min_height_textarea" type="text" matInput formControlName="ADDITIONALTEXTSELECT"  [matAutocomplete] ='invoiceText' matTooltip="{{(toolTipList)?toolTipList.ADDITIONALTEXT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
                                <mat-autocomplete #invoiceText="matAutocomplete" [panelWidth]="800" (optionSelected)="LookupsChangeAuto($event.option.value)">
                                    <mat-option *ngFor="let Lookups of filteredOptionsAuto | async" [value]="Lookups.LOOKUPFULLVALUE">
                                        {{Lookups?.LOOKUPFULLVALUE}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-menu #menu="matMenu" class="custom-menu" >
                                     <ng-container *ngFor="let Lookups of filteredOptions">
                                        <button mat-menu-item (click)="LookupsChange(Lookups.LOOKUPFULLVALUE)" >
                                            {{ Lookups?.LOOKUPFULLVALUE }}
                                        </button>
                                    </ng-container>
                                </mat-menu>
                                <button mat-icon-button [matMenuTriggerFor]="menu" class="iconbtn-plus add-circle" (click)="toggleAutocompleteDropdown()" >
                                    <mat-icon class='add-circle-color'>add_circle</mat-icon>
                                </button>

                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALTEXT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDITIONALTEXT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" fxFlex="100"
                                [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALTEXT,'has-warning':errorWarningData.Warning?.ADDITIONALTEXT}">
                                <mat-label>Invoice Text</mat-label>
                                <textarea matInput formControlName="ADDITIONALTEXT"></textarea>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                            </mat-form-field> -->
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}">
                                <mat-label>Comment</mat-label>
                                <textarea matInput formControlName="COMMENT"  matTooltip="{{(toolTipList)?toolTipList.COMMENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
                            <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="startTimer(previewTimer)" *ngIf="isStartButton">
            Start
        </button>

        <button  *ngIf="this.action=='Add' || this.action == 'NewTimeEntryMatter'" mat-raised-button color="accent" class="mat-accent" (click)="SaveClickTimeEntry('SaveWithNew')" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner && modeIs == 'SaveWithNew'"></mat-spinner>Save & New
        </button>

        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveClickTimeEntry()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner && !modeIs"></mat-spinner>{{buttonText}}
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
