import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as $ from 'jquery';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-re-calc-timeEntrie-dialoge',
    templateUrl: './re-calc-timeEntrie-dialoge.component.html',
    styleUrls: ['./re-calc-timeEntrie-dialoge.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ReCalcTimeEntriesDialogeComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @Input() SettingForm: FormGroup;
    @Input() errorWarningData: any;

    addData: any = [];
    TemplateName: any;
    getSelectedWIP: any;
    getSelectedWIPNewPrice: any = [];
    IsWipData: boolean = false;
    isLoadingResults: boolean = true;
    isspiner: boolean = false;
    successMsg: any = 'Time entry update successfully';
    workItem: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    FeeErner = (this.currentUser.WORKINGONBEHALFOFGUID) ? this.currentUser.WORKINGONBEHALFOFGUID : this.currentUser.UserId
    firstCount: any = 0;
    secondCount: number = 0;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private Timersservice: TimersService,
        private toastr: ToastrService,
        public MatDialog: MatDialog,
        private cd: ChangeDetectorRef,
        public dialogRef: MatDialogRef<ReCalcTimeEntriesDialogeComponent>,
        private behaviourService: BehaviorService) {
        localStorage.setItem('istrackid', 'ReCalcTimeEntriesDialogeComponent');
        this.behaviourService.workInProgress$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.getSelectedWIP = result;
            }
        });
        this.behaviourService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'ReCalcTimeEntriesDialogeComponent' && result['click'] && result['data']['CalcWorkItemCharge']) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'ReCalcTimeEntriesDialogeComponent' && result['click'] && result['data']['workitem']) {
                this.ChangePrice();
            }
        });
        this.behaviourService.defultFeeErnerData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.FeeErner = result;
            }
        })
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        let selectFreeearnar = JSON.parse(localStorage.getItem('Work_in_progress_filter'));
        this.FeeErner = selectFreeearnar?.FEEEARNER;
        this.loadData();

        //this.isLoadingResults = true;
        // if (this.getSelectedWIP.ITEMTYPEDESC == "WIP") {
        // this.IsWipData = true;
        // let sendData = {
        //   MATTERGUID: this.getSelectedWIP.MATTERGUID,
        //   FEEEARNER: this.getSelectedWIP.FEEEARNER,
        //   FEETYPE: this.getSelectedWIP.FEETYPE,
        //   QUANTITY: this.getSelectedWIP.QUANTITY,
        //   QUANTITYTYPE: this.getSelectedWIP.QUANTITYTYPE
        // }
        // this._mainAPiServiceService.getSetData(sendData, 'CalcWorkItemCharge').subscribe(response => {
        //   if (response.CODE == 200 && response.STATUS == "success") {
        //     this.getSelectedWIPNewPrice = response.DATA;
        //     if ((this.getSelectedWIP.PRICE == this.getSelectedWIPNewPrice.PRICE)
        //       && (this.getSelectedWIP.GST == this.getSelectedWIPNewPrice.GST)
        //       && (this.getSelectedWIP.PRICEINCGST == this.getSelectedWIPNewPrice.PRICEINCGST)) {
        //       this.IsWipData = false;
        //     }
        //   }
        //   this.isLoadingResults = false;
        // });
        // } else {
        //   this.isLoadingResults = false;
        //   this.IsWipData = false;
        // }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    /**
     * This function is used to load the data value.
     */
    async loadData() {
        this.firstCount = 0;
        this.secondCount = 0;
        this.isLoadingResults = true;
        let potData = { 'MatterGuid': this.getSelectedWIP.MATTERGUID, FEEEARNER: this.FeeErner, INVOICED: "No", ITEMDATESTART: "", ITEMDATEEND: "" };
        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": potData }, 'workitem').subscribe(async res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                const length = (res.DATA.WORKITEMS).length;
                for (var i = 0; i < length; i++) {
                    if (res.DATA.WORKITEMS[i].QUANTITYTYPE != "Fixed") {
                        if (!res.DATA.WORKITEMS[i].ISGROUPBY && !res.DATA.WORKITEMS[i].INVOICEGUID) {
                            await new Promise<void>(async (next, reject) => {
                                this.firstCount = this.firstCount + 1;
                                this.isLoadingResults = true;
                                this.IsWipData = true;
                                this.workItem = res.DATA.WORKITEMS[i];
                                if(this.workItem.QUANTITYTYPE == 'hh:mm') {
                                    this.workItem.QUANTITYTYPE = 'X';
                                }
                                else if(this.workItem.QUANTITYTYPE == 'Hours'){
                                    this.workItem.QUANTITYTYPE = 'H';
                                }
                                else if(this.workItem.QUANTITYTYPE == 'Minutes') {
                                    this.workItem.QUANTITYTYPE = 'M';
                                }
                                else if(this.workItem.QUANTITYTYPE == 'Days') {
                                    this.workItem.QUANTITYTYPE = 'D';
                                }
                                else if(this.workItem.QUANTITYTYPE == 'Units'){
                                    this.workItem.QUANTITYTYPE = 'U';
                                }
                                else if(this.workItem.QUANTITYTYPE == 'Fixed'){
                                    this.workItem.QUANTITYTYPE = 'F';
                                } else {
                                    this.workItem.QUANTITYTYPE = 'F';
                                }
                                let sendData = {
                                    MATTERGUID: this.workItem.MATTERGUID,
                                    FEEEARNER: this.workItem.FEEEARNER,
                                    FEETYPE: this.workItem.FEETYPE,
                                    QUANTITY: this.workItem.QUANTITY,
                                    QUANTITYTYPE: this.workItem.QUANTITYTYPE,
                                    RATEID: this.workItem.RATEID
                                };

                                let Payload = {
                                    "Action": "CalcWorkItemCharge",
                                    "Data": {
                                        ...sendData
                                    }
                                }
                                this._mainAPiServiceService.getSetData(Payload, 'utility').subscribe(response => {
                                    if (response.CODE == 200 && response.STATUS == "success") {
                                        this.getSelectedWIPNewPrice = response.DATA;
                                        if ((this.workItem.PRICE == this.getSelectedWIPNewPrice.PRICE)
                                            && (this.workItem.GST == this.getSelectedWIPNewPrice.GST)
                                            && (this.workItem.PRICEINCGST == this.getSelectedWIPNewPrice.PRICEINCGST)) {
                                            this.IsWipData = false;
                                            this.secondCount = this.secondCount + 1;
                                            next();
                                        }
                                        this.behaviourService.nextReCalcTimeEntrie$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                                            if (res == 'saveTimeEntry') {
                                                this.behaviourService.setnextReCalcTimeEntrie(null);
                                                next();
                                            } else if (res == 'skipTimeEntry') {
                                                this.behaviourService.setnextReCalcTimeEntrie(null);
                                                next();
                                            }
                                        });
                                    }
                                    this.isLoadingResults = false;
                                });
                            });
                        }
                        if (((res.DATA.WORKITEMS).length - 1) == i) {
                            if (this.firstCount == this.secondCount) {
                                this.dialogRef.close(true);
                            } else {
                                this.dialogRef.close(false);
                            }

                            $('#refreshWorkInprogress').click();
                        }

                    }
                }
            } else {

            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });

    }


/**
 * This function is used to change the price
 */
    ChangePrice() {
        this.isspiner = true;
        this.workItem.PRICE = this.getSelectedWIPNewPrice.PRICE;
        this.workItem.GST = this.getSelectedWIPNewPrice.GST;
        this.workItem.PRICEINCGST = this.getSelectedWIPNewPrice.PRICEINCGST;
        let PostData: any = this.workItem;
        this.successMsg = 'Time entry Update successfully';
        let PostTimeEntryData: any = { FormAction: 'update', VALIDATEONLY: true, Data: PostData };
        this.Timersservice.SetWorkItems(PostTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check the validation data 
     */
    checkValidation(bodyData: any, PostTimeEntryData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        // errorData
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'NO') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'WARNING') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.isspiner = false;
        } else if (Object.keys(warningData).length != 0) {
            // this.toastr.warning(warningData);
            this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isspiner = true;
                    this.saveTimeEntry(PostTimeEntryData);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.saveTimeEntry(PostTimeEntryData);
        }
    }

    /**
     * This function is used to save the time entry data value.
     */
    saveTimeEntry(PostTimeEntryData: any) {
        PostTimeEntryData.VALIDATEONLY = false;

        this.Timersservice.SetWorkItems(PostTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.toastr.success(this.successMsg);
                this.behaviourService.setnextReCalcTimeEntrie("saveTimeEntry");

                // this.dialogRef.close(true);
                this.isspiner = false;
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.toastr.warning(res.MESSAGE);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.toastr.warning(res.MESSAGE);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to SkipTime Entry.
     */
    SkipTimeEntry() {
        this.behaviourService.setnextReCalcTimeEntrie("skipTimeEntry");
    }
}
