<div class=" one  mb-24" [formGroup]="contactForm" fxLayout="column">
<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTNAME,'has-warning':errorWarningData.Warning?.BANKACCOUNTNAME}">
        <mat-label>Account Name</mat-label>
        <input type="text" name="BANKACCOUNTNAME" placeholder="Account Name" aria-label="Number" matInput formControlName="BANKACCOUNTNAME"  matTooltip="{{allToolTip.BANKACCOUNTNAME?.HOVER}}">
      
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKACCOUNTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKACCOUNTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKADDRESS,'has-warning':errorWarningData.Warning?.BANKADDRESS}">
        <mat-label>Bank Address</mat-label>
        <input type="text" name="BANKADDRESS" placeholder="Bank Address" aria-label="Number" matInput formControlName="BANKADDRESS"  matTooltip="{{allToolTip.BANKADDRESS?.HOVER}}">
       
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKADDRESS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKADDRESS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
</div>


<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKBSB,'has-warning':errorWarningData.Warning?.BANKBSB}">
        <mat-label>BSB</mat-label>
        <input type="text" name="BANKBSB" placeholder="BSB" aria-label="Number" matInput formControlName="BANKBSB"  matTooltip="{{allToolTip.BANKBSB?.HOVER}}">
      
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKBSB?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKBSB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.BANKACCOUNTNUMBER}">
        <mat-label>Account Number</mat-label>
        <input type="text" name="BANKACCOUNTNUMBER" placeholder="Account Number" aria-label="Number" matInput formControlName="BANKACCOUNTNUMBER"  matTooltip="{{allToolTip.BANKACCOUNTNUMBER?.HOVER}}">
       
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKACCOUNTNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKACCOUNTNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
</div>
<br>
<b>International EFT Details</b>
<br>
<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTSWIFTCODE,'has-warning':errorWarningData.Warning?.FOREIGNEFTSWIFTCODE}">
        <mat-label>Swift Code</mat-label>
        <input type="text" name="FOREIGNEFTSWIFTCODE" placeholder="Swift Code" aria-label="Number" matInput formControlName="FOREIGNEFTSWIFTCODE"  matTooltip="{{allToolTip.FOREIGNEFTSWIFTCODE?.HOVER}}">
      
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTSWIFTCODE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTSWIFTCODE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTIBAN,'has-warning':errorWarningData.Warning?.FOREIGNEFTIBAN}">
        <mat-label>IBAN</mat-label>
        <input type="text" name="FOREIGNEFTIBAN" placeholder="IBAN" aria-label="Number" matInput formControlName="FOREIGNEFTIBAN"  matTooltip="{{allToolTip.FOREIGNEFTIBAN?.HOVER}}">
       
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTIBAN?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTIBAN?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTBANKNAME,'has-warning':errorWarningData.Warning?.FOREIGNEFTBANKNAME}">
        <mat-label>Bank Name</mat-label>
        <input type="text" name="FOREIGNEFTBANKNAME" placeholder="Bank Name" aria-label="Number" matInput formControlName="FOREIGNEFTBANKNAME"  matTooltip="{{allToolTip.FOREIGNEFTBANKNAME?.HOVER}}">
       
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTBANKNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTBANKNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
</div>

<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCOUNTRY,'has-warning':errorWarningData.Warning?.FOREIGNEFTCOUNTRY}">
        <mat-label>Country</mat-label>
        <input type="text" name="FOREIGNEFTCOUNTRY" placeholder="Country" aria-label="Number" matInput formControlName="FOREIGNEFTCOUNTRY"  matTooltip="{{allToolTip.FOREIGNEFTCOUNTRY?.HOVER}}">
      
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTCOUNTRY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTCOUNTRY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCURRENCY,'has-warning':errorWarningData.Warning?.FOREIGNEFTCURRENCY}">
        <mat-label>Currency Code (i.e. USD)</mat-label>
        <input type="text" name="FOREIGNEFTCURRENCY" placeholder="Currency" aria-label="Number" matInput formControlName="FOREIGNEFTCURRENCY"  matTooltip="{{allToolTip.FOREIGNEFTCURRENCY?.HOVER}}">
       
   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FOREIGNEFTCURRENCY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FOREIGNEFTCURRENCY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

    </mat-form-field>
</div>

</div>
