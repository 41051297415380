<!-- [formGroup]="matterdetailForm" -->
<form name="CommercialForm" id="CommercialForm" [formGroup]="CommercialForm" fxLayout="column" fxLayoutAlign="start"
    class="one" fxFlex="1 0 auto">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CLASSOFSHARES,'has-warning':errorWarningData.Warning?.CLASSOFSHARES}">
            <mat-label>Class of Shares</mat-label>
            <input matInput formControlName="CLASSOFSHARES" matTooltip="{{(toolTipList)?toolTipList.CLASSOFSHARES?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.CLASSOFSHARES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLASSOFSHARES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLASSOFSHARES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.NUMBEROFSHARES,'has-warning':errorWarningData.Warning?.NUMBEROFSHARES}">
            <mat-label>Number of Shares</mat-label>
            <input matInput type="number" formControlName="NUMBEROFSHARES" matTooltip="{{(toolTipList)?toolTipList.NUMBEROFSHARES?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.NUMBEROFSHARES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NUMBEROFSHARES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NUMBEROFSHARES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="pr-4">
        <mat-form-field appearance="outline" fxFlex="100"
            [ngClass]="{'has-error':errorWarningData.Error?.CONSIDERATION,'has-warning':errorWarningData.Warning?.CONSIDERATION}">
            <mat-label>Consideration</mat-label>
            <input matInput formControlName="CONSIDERATION" matTooltip="{{(toolTipList)?toolTipList.CONSIDERATION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
            <div  *ngIf="testBool && toolTipList.MATTERSTATUS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTERSTATUS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERSTATUS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</form>