<div class="one" fxLayout="column" [formGroup]="MatterPropertyDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.BUILDINGNAME,'has-warning':errorWarningData.Warning?.BUILDINGNAME}">
            <mat-label>Building Name</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.BUILDINGNAME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="BUILDINGNAME">
             <div  *ngIf="testBool && toolTipList.BUILDINGNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BUILDINGNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BUILDINGNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.UNITNUMBER,'has-warning':errorWarningData.Warning?.UNITNUMBER}">
            <mat-label>Unit No.</mat-label>
            <input matInput name="Unit No" matTooltip="{{(toolTipList)?toolTipList.UNITNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="UNITNUMBER">
             <div  *ngIf="testBool && toolTipList.UNITNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.UNITNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.UNITNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETNUMBER,'has-warning':errorWarningData.Warning?.STREETNUMBER}">
            <mat-label>Street No</mat-label>
            <input matInput name="StreetNo" matTooltip="{{(toolTipList)?toolTipList.STREETNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STREETNUMBER">
             <div  *ngIf="testBool && toolTipList.STREETNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STREETNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STREETNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="10" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETNUMBERTO,'has-warning':errorWarningData.Warning?.STREETNUMBERTO}">
            <mat-label>To</mat-label>
            <input matInput name="To" matTooltip="{{(toolTipList)?toolTipList.STREETNUMBERTO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STREETNUMBERTO">
             <div  *ngIf="testBool && toolTipList.STREETNUMBERTO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STREETNUMBERTO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STREETNUMBERTO?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETNAME,'has-warning':errorWarningData.Warning?.STREETNAME}">
            <mat-label>Street</mat-label>
            <input name="Street" matInput matTooltip="{{(toolTipList)?toolTipList.STREETNAME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STREETNAME">
             <div  *ngIf="testBool && toolTipList.STREETNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STREETNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STREETNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETTYPE,'has-warning':errorWarningData.Warning?.STREETTYPE}">
            <mat-label>Street Type</mat-label>
            <input type="text" placeholder="State" aria-label="Number" matInput name="STREETTYPE" matTooltip="{{(toolTipList)?toolTipList.STREETTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STREETTYPE" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let val of StreetType" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
             <div  *ngIf="testBool && toolTipList.STREETTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STREETTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STREETTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="30" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SUBURB,'has-warning':errorWarningData.Warning?.SUBURB}">
            <mat-label>Suburb</mat-label>
            <input matInput name="Suburb" matTooltip="{{(toolTipList)?toolTipList.SUBURB?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SUBURB">
             <div  *ngIf="testBool && toolTipList.SUBURB" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SUBURB.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBURB?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STATE,'has-warning':errorWarningData.Warning?.STATE}">
            <mat-label>State</mat-label>
            <input type="text" placeholder="State" aria-label="Number" matInput name="STATE" matTooltip="{{(toolTipList)?toolTipList.STATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STATE" [matAutocomplete]="auto3">
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let val of StateData" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
             <div  *ngIf="testBool && toolTipList.STATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STATE?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.POSTCODE,'has-warning':errorWarningData.Warning?.POSTCODE}">
            <mat-label>Post Code</mat-label>
            <input matInput name="PostCode" matTooltip="{{(toolTipList)?toolTipList.POSTCODE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="POSTCODE">
             <div  *ngIf="testBool && toolTipList.POSTCODE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.POSTCODE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.POSTCODE?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" [ngClass]="{'has-error':errorWarningData.Error?.NATUREOFBUILDING,'has-warning':errorWarningData.Warning?.NATUREOFBUILDING}">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Nature Of Building</mat-label>
            <input matInput name="NatureOfBulding" matTooltip="{{(toolTipList)?toolTipList.NATUREOFBUILDING?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="NATUREOFBUILDING">
             <div  *ngIf="testBool && toolTipList.NATUREOFBUILDING" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NATUREOFBUILDING.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NATUREOFBUILDING?.COPYVALUE:'')">file_copy</mat-icon></div>
         </mat-form-field>
    </div>
</div>