<div class="one prepare_invoice_summary" [formGroup]="addInvoiceForm" fxLayout="column">
    <div class="value_label_parent">
        <div fxFlex="60" class="label_width"> </div>
        <div fxFlex="15" class="value_width">Excluding GST</div>
        <div fxFlex="10" class="value_width">GST</div>
        <div fxFlex="15" class="value_width">Including GST</div>
    </div>
    <div class="value_label_parent">
        <div fxFlex="60" class="label_width">
            <!-- Total invoiced befor this invoice ? -->
            Total invoiced before this invoice
        </div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="INVOICEDVALUEEXGST">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="INVOICEDVALUEINCGST">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>
    <div class="value_label_parent">
        <div fxFlex="60" class="label_width">
            Total invoiced including this invoice 
        </div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="FIXEDRATEEXGSTTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="FIXEDRATEINCGSTTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>

    <div *ngIf="isFixPrice" class="value_label_parent">
        <div fxFlex="30" class="label_width_half">Fixed Price Status: </div>
        <div fxFlex="30" class="label_width_half">Exceeded By :</div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="FIXEDRATEEXGST">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="FIXEDRATEINCGST">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>
    <div *ngIf="!isFixPrice" class="value_label_parent">
        <div fxFlex="30" class="label_width_half"> Fixed Price Status: </div>
        <div fxFlex="30" class="label_width_half">Fixed Price not specified!</div>
    </div>
    <div *ngIf="isMin" class="value_label_parent">
        <div fxFlex="30" class="label_width_half">Minimum Estimate Status: </div>
        <div fxFlex="30" class="label_width_half">Exceeded By :</div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="ESTIMATETOTOTALEXGST">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input class="sideText" matInput readonly placeholder="" formControlName="ESTIMATETOTOTALINCGST">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>
    <div *ngIf="!isMin" class="value_label_parent">
        <div fxFlex="30" class="label_width_half"> Minimum Estimate Status: </div>
        <div fxFlex="30" class="label_width_half">No Minimum Estimate Provided!</div>
        <div fxFlex="15" class="value_width"></div>
        <div fxFlex="10" class="value_width"></div>
        <div fxFlex="15" class="value_width"></div>
    </div>



    <div *ngIf="isMax" class="value_label_parent">
        <div fxFlex="30" class="label_width_half">Maximum Estimate Status: </div>
        <div fxFlex="30" class="label_width_half">Exceeded By :</div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input matInput readonly placeholder="" formControlName="ESTIMATEFROMTOTALEXGST">
        </mat-form-field>
        <div fxFlex="10" class="value_width"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="pr-4" class="value_width">
            <input matInput readonly placeholder="" formControlName="ESTIMATEFROMTOTALINCGST">
        </mat-form-field>
    </div>
    <div *ngIf="!isMax" class="value_label_parent">
        <div fxFlex="30" class="label_width_half"> Maximum Estimate Status: </div>
        <div fxFlex="30" class="label_width_half">No Maximum Estimate Provided!</div>
        <div fxFlex="15" class="value_width"></div>
        <div fxFlex="10" class="value_width"></div>
        <div fxFlex="15" class="value_width"></div>

    </div>
</div>
<mat-divider></mat-divider>