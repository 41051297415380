<div class=" one " fxLayout="column" [formGroup]="MatterConveyancingDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COOLINGOFFDATE,'has-warning':errorWarningData.Warning?.COOLINGOFFDATE}">
            <mat-label>Cooling Off Date</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'COOLINGOFFDATE')" matTooltip="{{(toolTipList)?toolTipList.COOLINGOFFDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COOLINGOFFDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'COOLINGOFFDATE')" matInput name="CoolingOffDate" [matDatepicker]="CoolingOffDate">
            <mat-datepicker-toggle matSuffix [for]="CoolingOffDate"></mat-datepicker-toggle>
            <mat-datepicker #CoolingOffDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.COOLINGOFFDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COOLINGOFFDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COOLINGOFFDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CONTRACTLENGTHVALUE,'has-warning':errorWarningData.Warning?.CONTRACTLENGTHVALUE}">
            <mat-label>Contract Length</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CONTRACTLENGTHVALUE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CONTRACTLENGTHVALUE">
       <div  *ngIf="testBool && toolTipList.CONTRACTLENGTHVALUE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CONTRACTLENGTHVALUE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTRACTLENGTHVALUE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CONTRACTLENGTHPERIODTYPE,'has-warning':errorWarningData.Warning?.CONTRACTLENGTHPERIODTYPE}">
            <mat-select matTooltip="{{(toolTipList)?toolTipList.COURT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CONTRACTLENGTHPERIODTYPE">
                <mat-option value="Days">Days</mat-option>
                <mat-option value="Weeks">Weeks</mat-option>
                <mat-option value="Months">Months</mat-option>
                <mat-option value="Years">Years</mat-option>
            </mat-select>
       <div  *ngIf="testBool && toolTipList.CONTRACTLENGTHPERIODTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CONTRACTLENGTHPERIODTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTRACTLENGTHPERIODTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CONTRACTCOMPLETIONDATE,'has-warning':errorWarningData.Warning?.CONTRACTCOMPLETIONDATE}">
            <mat-label>Contract Completion Date</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'CONTRACTCOMPLETIONDATE')" matTooltip="{{(toolTipList)?toolTipList.CONTRACTCOMPLETIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CONTRACTCOMPLETIONDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'CONTRACTCOMPLETIONDATE')" matInput name="ContractCompletionDate" [matDatepicker]="ContractCompletionDate">
            <mat-datepicker-toggle matSuffix [for]="ContractCompletionDate"></mat-datepicker-toggle>
            <mat-datepicker #ContractCompletionDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.CONTRACTCOMPLETIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CONTRACTCOMPLETIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTRACTCOMPLETIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CONTRACTCOMPLETIONOTHERDATE,'has-warning':errorWarningData.Warning?.CONTRACTCOMPLETIONOTHERDATE}">
            <mat-label>Contract Completion Other Date</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'CONTRACTCOMPLETIONOTHERDATE')" matTooltip="{{(toolTipList)?toolTipList.CONTRACTCOMPLETIONOTHERDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CONTRACTCOMPLETIONOTHERDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'CONTRACTCOMPLETIONOTHERDATE')" matInput name="ContractCompletionOtherDate"
                [matDatepicker]="ContractCompletionOtherDate">
            <mat-datepicker-toggle matSuffix [for]="ContractCompletionOtherDate"></mat-datepicker-toggle>
            <mat-datepicker #ContractCompletionOtherDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.CONTRACTCOMPLETIONOTHERDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CONTRACTCOMPLETIONOTHERDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTRACTCOMPLETIONOTHERDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.AUCTIONDATE,'has-warning':errorWarningData.Warning?.AUCTIONDATE}">
            <mat-label>Auction Date</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'AUCTIONDATE')" matTooltip="{{(toolTipList)?toolTipList.AUCTIONDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="AUCTIONDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'AUCTIONDATE')" matInput name="AuctionDate" [matDatepicker]="AuctionDate">
            <mat-datepicker-toggle matSuffix [for]="AuctionDate"></mat-datepicker-toggle>
            <mat-datepicker #AuctionDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.AUCTIONDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.AUCTIONDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AUCTIONDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FINALDATEFORNOTICETOCOMPLETE,'has-warning':errorWarningData.Warning?.FINALDATEFORNOTICETOCOMPLETE}">
            <mat-label>Final Date For Notice To Complete</mat-label>
            <input (dateInput)="CommanDatePickerClick('input', $event,'FINALDATEFORNOTICETOCOMPLETE')" matTooltip="{{(toolTipList)?toolTipList.FINALDATEFORNOTICETOCOMPLETE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="FINALDATEFORNOTICETOCOMPLETETEXT" (dateChange)="CommanDatePickerClick('input', $event,'FINALDATEFORNOTICETOCOMPLETE')" matInput name="FinalDateForNoticeToComplete"
                [matDatepicker]="FinalDateForNoticeToComplete">
            <mat-datepicker-toggle matSuffix [for]="FinalDateForNoticeToComplete"></mat-datepicker-toggle>
            <mat-datepicker #FinalDateForNoticeToComplete></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.FINALDATEFORNOTICETOCOMPLETE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FINALDATEFORNOTICETOCOMPLETE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FINALDATEFORNOTICETOCOMPLETE?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-checkbox fxFlex="100" matTooltip="{{(toolTipList)?toolTipList.TRANSFERFEESEXCLUDED?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="TRANSFERFEESEXCLUDED">Transfer Fees Excluded</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DUPLICATEDOCUMENTS,'has-warning':errorWarningData.Warning?.DUPLICATEDOCUMENTS}">
            <mat-label>Duplicate Documents</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.DUPLICATEDOCUMENTS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DUPLICATEDOCUMENTS">
       <div  *ngIf="testBool && toolTipList.DUPLICATEDOCUMENTS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DUPLICATEDOCUMENTS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUPLICATEDOCUMENTS?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DUPLICATETRANSFERS,'has-warning':errorWarningData.Warning?.DUPLICATETRANSFERS}">
            <mat-label>Duplicate Transfers</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.DUPLICATETRANSFERS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DUPLICATETRANSFERS">
       <div  *ngIf="testBool && toolTipList.DUPLICATETRANSFERS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DUPLICATETRANSFERS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUPLICATETRANSFERS?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ENOSID,'has-warning':errorWarningData.Warning?.ENOSID}">
            <mat-label>eNOS Id</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.ENOSID?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ENOSID">
       <div  *ngIf="testBool && toolTipList.ENOSID" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ENOSID.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ENOSID?.COPYVALUE:'')">file_copy</mat-icon></div>
       
        </mat-form-field>
    </div>
</div>