<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>
        Select Document
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="upper_fields">
            <mat-form-field class="" appearance="outline" fxFlex="34">
                <mat-label>Search</mat-label>
                <input [(ngModel)]="search" matInput placeholder="search" (keydown)="onSearch($event)">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="one select_matter_main_div">
            
            <div class="example-containerdata resizable-table">
                <div class="grid-overlay" *ngIf="isDisplay">
                    <div class="grid-overlay-msg"> There is no data to display. </div>
                </div>
                <!-- <table mat-table [dataSource]="SelectDocumentdata" matSortDisableClear matSort [@animateStagger]="{ value: '50' }" multiTemplateDataRows> -->
                    <mat-table #table [dataSource]="SelectDocumentdata"   matSortDisableClear matSort [@animateStagger]="{value:'50'}" multiTemplateDataRows>
 
                    <ng-container matColumnDef="select" >
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox class="table_checkbx" (change)="$event ? masterToggle() : null;helloFunction();" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" >
                            <mat-checkbox *ngIf="row.DOCUMENTNAME" class="table_checkbx" (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null;helloFunction();" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="DOCUMENTNAME" >
                        <th style="width: 530px;" mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</th>
                        <td style="width: 500px" mat-cell *matCellDef="let row">
                            {{ row.DOCUMENTNAME }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="GENERATEDATE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                        <td class="date_clmn_custome" mat-cell *matCellDef="let row">
                            {{ row.GENERATEDATE }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="GENERATETIME"  >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.GENERATETIME }}
                        </td>
                    </ng-container>
                    <!-- <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true" ></tr> -->
                    <mat-header-row  *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <!-- <mat-row *matRowDef="let row; columns: displayedColumns;let element;" class="row" (click)="RowClick(row);highlightedRows=row.DOCUMENTGUID" [style.background]="highlightedRows == row.DOCUMENTGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.DOCUMENTGUID ? 'row-text-colore': ''" highlightedRows="row.DOCUMENTGUID" (click)="RowClick(row);"
                        matRipple [@animate]="{ value: '*', params: { y: '100%' } }"></mat-row> -->
                       
                       
                        <mat-row *matRowDef="let row; columns: displayedColumns;let element;"  [class.hidden]="row.FOLDERNAME" class="row" (click)="RowClick(row);highlightedRows=row.DOCUMENTGUID" [style.background]="highlightedRows == row.DOCUMENTGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.DOCUMENTGUID ? 'row-text-colore' : ''"
                         matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                        </mat-row>
                   
                   

                         <ng-container matColumnDef="groupHeader"  >
                                <th class="special-group-header" colspan="999" *matCellDef="let group">
                                    <mat-icon>
                                        <img *ngIf="group.foldertype=='folder' " src="assets/icons/web_app/folder.ico ">
                                        <img *ngIf="group.foldertype=='Go_Back' " src="assets/icons/web_app/folderup.ico ">
                                    </mat-icon>
                                    <strong>{{group.FOLDERNAME}}</strong>
                                </th>
                            </ng-container>
                            <ng-container *matRowDef="let row; columns: [ 'groupHeader']; when:isGroup" >
                            <mat-row  (dblclick)='doubleClick(row)'  [style.background]="highlightedRows==row.FOLDERNAME ? selectedColore : '' " [ngClass]="highlightedRows==row.FOLDERNAME
                                        ? 'row-text-colore' : '' " highlightedRows=row.FOLDERNAME (click)="highlightedRows=row.FOLDERNAME " >
                             </mat-row>
                            </ng-container>
                            
                    </mat-table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize?.document_register" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr contact_select_footer">
        <button mat-raised-button (click)="selectTemplate()" color="accent"> Select </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial> Cancel </button>
    </mat-dialog-actions>
</div>