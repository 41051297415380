<div class="container">
    <div class="privacy-policy-container">
        <h1 class="main-headline">SILQ Privacy Policy</h1>
        <h2 class="sub-headline">APPLICATION PRIVACY POLICY</h2>
        <h2 class="company-name">Silqware Pty Ltd</h2>

        <ol>
            <li class="main-headline-point">We respect your privacy
                <ol>
                    <li class="sub-point">Silqware Pty Ltd (we, us, our) respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) (the Act). This policy sets out how we collect and treat personal information.</li>

                    <li class="sub-point">“Personal information” is information or an opinion about an individual (whether about you and your practice, or contained in any data file including information about your clients, suppliers or other stakeholders (“Practice Data”) .</li>

                    <li class="sub-point">By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy.</li>
                </ol>
            </li>
       
            <li class="main-headline-point">Collection of personal information
            <ol>
                <li class="sub-point">
                    Silqware Pty Ltd will, from time to time, receive and store personal information you enter onto our website, provided to us directly or given to us in other forms.
                </li>
                <li class="sub-point">
                    We typically obtain personal information directly from you. That occurs, for example, where you provide us with information such as your name, phone number, address and email address to enable us to send information, provide updates and process your product or service order, or we access your Practice Data which contains personal information of others. 
                </li>
                <li class="sub-point">
                    We may collect additional information at other times, including but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and/or promotions, provide financial or credit card information, or communicate generally with us (whether electronically or in person).
                </li>
                <li class="sub-point">
                    We may also obtain personal information from publicly available sources, including search engines, websites, social media, publicly available data bases, third party sources and referees, personal contacts and industry connections. On occasion, we may also receive your personal information from third parties; for example, from people who work with you. 

                </li>
                <li class="sub-point">
                    If you provide personal information to us about someone else (such as your directors, employees, clients, customers or someone you have business dealings with) you must ensure that you are entitled to disclose that information to us and that you have fully complied with the Act (including obtaining all consents) in relation to the collection, use or disclosure of that information. We will handle that personal information in accordance with this policy and our obligations under the Act. 
                </li>
            </ol>
        </li>

            <li class="main-headline-point">Dealing with us anonymously
            <ol>
                <li class="sub-point">You may not wish to identify yourself or you may wish to use a pseudonym and this is an option provided that this does not make it impractical for us to deal with you. However, it will generally be impractical for you to deal with us anonymously or under a pseudonym, should you wish to enter into a contract with us, including to purchase goods or services from us. The reason for this is because your identity is a relevant factor in creating a legally binding arrangement. It is material to our decision of whether or not to enter into agreements with you. Also, if you do not provide us with other information that we request, we may not be able to provide you with access to or full use of our websites and/or services.</li>
            </ol>
        </li>
            <li class="main-headline-point">Use of personal information
            <ol>
                <li class="sub-point">We use personal information collected from you to provide you with information, updates and our services. We may also use personal information to: 
                <ol>
                    <li class="sub-sub-point">communicate with you;</li>
                    <li class="sub-sub-point">identify you and to assist you to use our website or any of our relevant goods or services more easily;</li>
                    <li class="sub-sub-point">perform services requested by you on your own behalf or on behalf of others, </li>
                    <li class="sub-sub-point">troubleshoot problems or develop new tools for you</li>
                    <li class="sub-sub-point">have our agents perform such services we required in order to provide our services to you (for example, office support services and IT providers);</li>
                    <li class="sub-sub-point"> monitor, identify and analyse trends, usage, and activities to improve the quality of our service;</li>
                    <li class="sub-sub-point">research and develop our goods and services;</li>
                    <li class="sub-sub-point">maintain and develop our business systems and infrastructure, including testing and upgrading of these systems;</li>
                    <li class="sub-sub-point"> inform you of matters about which we believe you may have an interest;</li>
                    <li class="sub-sub-point">notify you of our product and service offerings from time to time;</li>
                    <li class="sub-sub-point">keep appropriate records; and</li>
                    <li class="sub-sub-point">comply with legislative and regulatory requirements.</li>
                </ol>
            </li>
                <li class="sub-point">By using our website and accepting our services you expressly permit us to use your personal information (but not personal information contained in your Practice Data) for our direct marketing purposes and the purposes expressly set out in this policy. You consent to our use of your information to issue product and professional mail outs to you by email,  social media or letters and undertaking other marketing or service based activities</li>

                <li class="sub-point">If you do not wish to receive any of these materials, please click the ‘unsubscribe’ link at the bottom of relevant emails or contact our Privacy Officer, whose details are at the end of this Policy, to opt out. You may opt out of any direct marketing service at any time. <p>We will never sell, license, trade or provide personal information to another person for direct marketing purposes, except that you may receive advertising via social media platforms when using our Website or related sites on Facebook, Instagram or other such platforms.  </p> </li>

            </ol>
        </li>
            <li class="main-headline-point">Disclosure of personal information
            <ol>
                <li class="sub-point">We may disclose personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this Policy. Personal information is only supplied to a third party when it is required for the delivery of our services and we take reasonable steps to ensure that all such third parties agree to comply with this policy and have measures in place to protect the personal information they receive – see clause 6 for more details. 
                </li>
                <li class="sub-point">We may from time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request.  If we are compelled, at law, to comply with such a request we shall notify you of the requirement as soon as practical after receipt of the relevant notice, unless we are prohibited at law from doing so.</li>
                <li class="sub-point">We may also use (and in so doing, disclose) personal information to protect the intellectual property, copyright, trademarks, legal rights or property of Silqware Pty Ltd.</li>
                <li class="sub-point">Information that we collect may from time to time be stored, processed in or transferred between parties located in countries outside of Australia. We will ensure that any overseas entity to whom personal information is disclosed has agreed to comply with the Act, or are subject to laws that are at least equivalent to those imposed under the Act to protect your privacy</li>
                <li class="sub-point">If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required by any of the above circumstances.
                </li>
            </ol>
        </li>
            <li class="main-headline-point">Security of personal information
            <ol>
                <li class="sub-point">Silqware Pty Ltd is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</li>
                <li class="sub-point">We require our employees to protect the confidentiality of personal information as required by applicable law and limit access to personal information to our employees and other third parties who have a legitimate need to use it, consistent with the purposes for which it was collected.</li>
                <li class="sub-point">Although we take measures to safeguard against unauthorised disclosure, the nature of communicating electronically means that the we cannot guarantee the security of any information that you transmit to us, or receive from us</li>
                <li class="sub-point">We will comply with all of our obligations under the Act if there is a data breach and the mandatory notification provisions of that Act apply (including notifying you).
                </li>
            </ol>
        </li>
            <li class="main-headline-point">Access to personal information
            <ol>
                <li class="sub-point">You may request details of personal information that we hold about you in accordance with the Act. If you would like a copy of the information which we hold about you, or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at <a href = "mailto: support@silq.com.au">support@silq.com.au</a>.</li>
                <li class="sub-point">We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act.</li>
                <li class="sub-point">For security reasons, any request for details of personal information held by us should be made in writing. You may be asked to provide proof of identify.</li>
            </ol>
        </li>
            <li class="main-headline-point">Practice Data
            <ol>
                <li class="sub-point">In providing our services to you we may need to access personal information contained within your Practice Data (being personal information of your clients, suppliers and other stakeholders)</li>
                <li class="sub-point">You must ensure that you are entitled to disclose that information to us and that you have fully complied with the Act (including obtaining all consents) in relation to the collection, use or disclosure of that information to us. We will handle that personal information in accordance with this policy and our obligations under the Act.</li>
                <li class="sub-point">In no circumstance shall we use or disclose personal information contained in Practice Data otherwise then to provide you with our services.</li> 
                <li class="sub-point">We restrict access to your Practice Data to our employees and contractors who need that information to provide our services to you. Anyone to whom personal information is disclosed will be subject to strict contractual confidentiality obligations.
                </li> 
                <li class="sub-point">Notwithstanding clause 5.4, we currently store your data and your Practice Data in secure and protected data storage facilities in Australia with parties who are contractually and legally bound to comply with the Act and not to use or disclose personal information (whether yours or contained in the Practice Data) otherwise than to support the provision of our services to you. </li> 
                <li class="sub-point">In no circumstances shall we use or disclose personal information contained in your Practice Data for our marketing or business development purpose.</li> 
            </ol>
        </li>
            <li class="main-headline-point">Contact us about privacy
           
            <ol>
                <li class="sub-point">If you would like to discuss this privacy policy, or you have any complaints about our privacy practices, please feel free to send in details of to Suite 303, Level 3, 46 Market St, Sydney NSW 2000  or alternatively send us an email at <a href = "mailto: support@silq.com.au">support@silq.com.au</a></li>
                <li class="sub-point">
                    We take complaints very seriously. In the case of a complaint, we will usually request that you put your complaint in writing so that it may be properly considered and investigated by us. The matter will be considered by our Privacy Officer, who will respond within a reasonable time and usually within 30 days. 
                    
                    <p>You will be notified of our decision. If you remain dissatisfied with the outcome, we will also inform you of your right to take the matter to the Office of the Australian Information Commissioner (OAIC). </p>
                </li>
            </ol>
        </li>
            
            <li class="main-headline-point">Changes to Privacy Policy
            <ol>
                <li class="sub-point">. Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. We will usually let you know by direct contact, but please check back from time to time to review our Privacy Policy.</li>
            </ol>

        </li>
            <li class="main-headline-point">Website
            <ol>
                <li class="sub-point">
                    When you visit our website
                    <p>When you come to our website (www.silq.com.au) we may collect certain information such as browser type, operating system, website visited immediately before coming to our site, etc. This information is used in an aggregated manner to analyse how people use our site, such that we can improve our service.
                    </p>
                </li>

                <li class="sub-point">
                    Cookies
                    <p> We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyse website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google Adwords. These ads may appear on this website or other websites you visit.
                    </p>
                </li>

                <li class="sub-point mb-10">
                    Third party sites
                    <p> Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third party websites do not constitute sponsorship or endorsement or approval of these websites. Please be aware that Silqware Pty Ltd is not responsible for the privacy practises of other such websites. We encourage our users to be aware, when they leave our website, to read the privacy statements of each and every website that collects personal identifiable information.August 2022.
                    </p>
                </li>
            </ol></li>

            <!-- <li class="main-headline-point">Offer Details

                <ol > <li class="sub-point">The below information explains SILQ’s auto-renewal, cancellation, pricing and other terms of our offer.</li>
                </ol>
            </li>

            <li class="main-headline-point">Auto-renewal

                <ol> <li class="sub-point">Your SILQ subscription will automatically renew either monthly or annually depending on the product you have chosen. Your account will be debited or your card charged automatically based on the then current fees for the SILQ pricing option you have selected on a recurring basis until you cancel your SILQ subscription.</li>
                </ol>
            </li>

            <li class="main-headline-point">Cancellation

                <ol><li  class="sub-point">To cancel your SILQ subscription, you must provide a minimum of two (2) weeks’ notice. You can do so by emailing <a href = "mailto: support@silq.com.au">support@silq.com.au</a>. If you cancel your SILQ subscription you will still be charged for the following month if your subscription renews within the 2 week notice period.</li>
                </ol>
            </li>

            <li class="main-headline-point">Pricing and other terms

                <ol><li class="sub-point">Promotional pricing and free trials cannot be combined with any other offers, any promotional offers are available fir a finite period of time only. Pricing, terms, features and support are subject to change by SILQ at any time. We guarantee to keep you on the same pricing for a minimum of two (2) years before potentially imposing any increases (many clients have been on the same pricing for much longer than two years). See the EULA for additional terms. SILQ may extend, cancel or amend any offer  at any time, in its sole discretion. For new SILQ customers, any free or discounted period associated with a promotion will commence on the purchase date.</li> 
                </ol>
            </li>

            <li class="main-headline-point">Free Trial

                <ol>
                    <li class="sub-point">
                    SILQ gives you a free trial subscription that expires after 14 days, or earlier if you elect to buy a subscription before then. The ‘free’ aspect to the trial is limited to the licensing fees for the subscription. What you can trial but may still incur fees are features that are charged on a per usage basis. An example is SILQPay where you or your client (depending on your settings) are charged a processing fee each time an invoice is paid. Additionally, any costs associated with integrations such as Microsoft 365, DropBox, Infotrack etc are borne by the user during the free trial as these products are not owned by SILQ, SILQ only integrates with these external providers.

                </li>
                <li class="sub-point"> To continue using SILQ after your free trial expires, you will need to purchase a subscription and enter your payment details in SILQ for processing. During the free trial, you will have access to all features in the software irrespective of the different pricing plans. Once you decide to subscribe, you can choose which product you would like to subscribe to and then your features will be limited to what is available on that plan.</li>
                </ol>
            </li> -->
        </ol>
    </div>
</div>