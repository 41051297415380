<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">Write Off
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                        <mat-label > {{('' | productType)?   "Client" :"Solicitor"}}</mat-label>
                        <input [(ngModel)]="WriteOffInvoice.client" name="client" matInput readonly>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                        <mat-label>Matter</mat-label>
                        <input [(ngModel)]="WriteOffInvoice.matter" name="matter" matInput readonly>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MATTER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Invoice No</mat-label>
                        <input [(ngModel)]="WriteOffInvoice.INVOICECODE" name="INVOICECODE" matInput
                            placeholder="Invoice No" readonly>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICECODE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICECODE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Invoice Date</mat-label>
                        <input [(ngModel)]="WriteOffInvoice.INVOICEDATE" name="INVOICEDATE" matInput readonly
                            [satDatepicker]="picker2">
                        <sat-datepicker #picker2> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Due Date</mat-label>
                        <input [(ngModel)]="WriteOffInvoice.DUEDATE" name="DUEDATE" matInput readonly
                            [satDatepicker]="picker1">
                        <sat-datepicker #picker1> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="picker1"></sat-datepicker-toggle>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DUEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Invoice Total</mat-label>
                        <input currencyMask [(ngModel)]="WriteOffInvoice.TOTALINVOICES" name="TOTALINVOICES" readonly
                            matInput placeholder="Invoice Total">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALINVOICES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TOTALINVOICES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Amount Paid</mat-label>
                        <input currencyMask [(ngModel)]="WriteOffInvoice.TOTALRECEIVED" name="TOTALRECEIVED" readonly
                            matInput placeholder="Amount Paid">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALRECEIVED?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TOTALRECEIVED?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4">
                        <mat-label>Amount Outstanding</mat-label>
                        <input currencyMask [(ngModel)]="WriteOffInvoice.TOTALOUSTANDING" name="TOTALOUSTANDING"
                            readonly matInput placeholder="Amount Outstanding">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALOUSTANDING?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TOTALOUSTANDING?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.WRITEOFFDATE,'has-warning':errorWarningData.Warning?.WRITEOFFDATE}">
                        <mat-label>Write Off Date</mat-label>
                        <input [(ngModel)]="WriteOffInvoice.WriteOffDate" name="WriteOffDate" matInput readonly
                            [satDatepicker]="WriteOffDatePiker">
                        <sat-datepicker #WriteOffDatePiker> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="WriteOffDatePiker"></sat-datepicker-toggle>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WRITEOFFDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.WRITEOFFDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.WRITEOFFAMOUNT,'has-warning':errorWarningData.Warning?.WRITEOFFAMOUNT}">
                        <mat-label>Write Off Amount</mat-label>
                        <!-- currencyMask -->
                        <input style="text-align: right;" type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true" [(ngModel)]="WriteOffInvoice.AMOUNT" name="AMOUNT" matInput placeholder="Amount">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WRITEOFFAMOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.WRITEOFFAMOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                </div>
            </form>
            <!-- WriteOffDate -->
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button [disabled]='isbtnDisable || isspiner' mat-raised-button color="accent" (click)="saveWriteOff()"
            cdkFocusInitial>
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>