<div class=" one " [formGroup]="contactForm" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="49" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.GENDER,'has-warning':errorWarningData.Warning?.GENDER}">
            <mat-label>Gender</mat-label>
            <mat-select name="GENDER" [formControlName]="'GENDER'">
                <mat-option [value]="val.Name" *ngFor="let val of common">{{val.Name}}</mat-option>
            </mat-select>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GENDER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GENDER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" class="example" fxFlex="49" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFBIRTH,'has-warning':errorWarningData.Warning?.DATEOFBIRTH}">
            <mat-label>Date Of Birth</mat-label>
            <input (dateInput)="choosedDateOfBirth('input', $event)" (dateChange)="choosedDateOfBirth('change', $event)" matInput name="DATEOFBIRTH" [formControlName]="'DATEOFBIRTH'" [matDatepicker]="DATEOFBIRTH" placeholder="Date Of Birth" matTooltip="{{allToolTip.DATEOFBIRTH?.HOVER}}">
            <mat-datepicker-toggle matSuffix [for]="DATEOFBIRTH"></mat-datepicker-toggle>
            <mat-datepicker #DATEOFBIRTH></mat-datepicker>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFBIRTH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATEOFBIRTH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-checkbox class="checkbox" name="BIRTHDAYREMINDER" [formControlName]="'BIRTHDAYREMINDER'">Reminder   </mat-checkbox> -->
        <mat-slide-toggle class="pb-fieldspace"    formControlName="BIRTHDAYREMINDER">Reminder </mat-slide-toggle>
     
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TOWNOFBIRTH,'has-warning':errorWarningData.Warning?.TOWNOFBIRTH}">
            <mat-label>Town Of Birth</mat-label>
            <input name="TOWNOFBIRTH" [formControlName]="'TOWNOFBIRTH'" matInput matTooltip="{{allToolTip.TOWNOFBIRTH?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOWNOFBIRTH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TOWNOFBIRTH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COUNTRYOFBIRTH,'has-warning':errorWarningData.Warning?.COUNTRYOFBIRTH}">
            <mat-label>Country Of Birth</mat-label>
            <input name="COUNTRYOFBIRTH" [formControlName]="'COUNTRYOFBIRTH'" matInput matTooltip="{{allToolTip.COUNTRYOFBIRTH?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COUNTRYOFBIRTH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COUNTRYOFBIRTH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFDEATH,'has-warning':errorWarningData.Warning?.DATEOFDEATH}">
            <mat-label>Date Of Death</mat-label>
            <input (dateInput)="choosedDateOfDath('input', $event)" (dateChange)="choosedDateOfDath('change', $event)" matInput name="DATEOFDEATH" [formControlName]="'DATEOFDEATH'" [matDatepicker]="DATEOFDEATH" placeholder="Date Of Death" matTooltip="{{allToolTip.DATEOFDEATH?.HOVER}}">
            <mat-datepicker-toggle  matSuffix [for]="DATEOFDEATH"></mat-datepicker-toggle>
            <mat-datepicker #DATEOFDEATH></mat-datepicker>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFDEATH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATEOFDEATH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFDEATHRANGE,'has-warning':errorWarningData.Warning?.DATEOFDEATHRANGE}">
            <mat-label>Date Of Death Range</mat-label>

            <input matInput name="DATEOFDEATHRANGE" [formControlName]="'DATEOFDEATHRANGE'" [matDatepicker]="DATEOFDEATHRANGE" placeholder="Date Of Death Range" (dateInput)="choosedDateOfDathRange('input', $event)" (dateChange)="choosedDateOfDathRange('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="DATEOFDEATHRANGE"></mat-datepicker-toggle>
            <mat-date-range-picker #DATEOFDEATHRANGE></mat-date-range-picker>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->
        <mat-form-field appearance="outline" appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFDEATHRANGE,'has-warning':errorWarningData.Warning?.DATEOFDEATHRANGE}">
            <mat-label>Date Of Death Range</mat-label>
            <!-- <input [formControlName]="'DATEOFDEATHRANGE'"matInput [satDatepicker]="picker2" (dateInput)="choosedDateOfDathRange('input', $event)" (dateChange)="choosedDateOfDathRange('change', $event)"> -->
            <!-- <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle> -->
            <input (dateInput)="choosedDateOfDathRange('input', $event)" (dateChange)="choosedDateOfDathRange('change', $event)" matInput name="DATEOFDEATHRANGE" [formControlName]="'DATEOFDEATHRANGE'" [matDatepicker]="DATEOFDEATHRANGE" placeholder="Date Of Death Range" matTooltip="{{allToolTip.DATEOFDEATHRANGE?.HOVER}}">
            <mat-datepicker-toggle  matSuffix [for]="DATEOFDEATHRANGE"></mat-datepicker-toggle>
            <mat-datepicker #DATEOFDEATHRANGE></mat-datepicker>
            <!-- <mat-icon matSuffix (click)="DateRange1('clearDateRange',null)" class="icon-color-red highlight_off"> highlight_off</mat-icon> -->

       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEOFDEATHRANGE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATEOFDEATHRANGE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CAUSEOFDEATH,'has-warning':errorWarningData.Warning?.CAUSEOFDEATH}">
            <mat-label>Cause Of Death</mat-label>
            <input name="CAUSEOFDEATH" [formControlName]="'CAUSEOFDEATH'" matInput matTooltip="{{allToolTip.CAUSEOFDEATH?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CAUSEOFDEATH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CAUSEOFDEATH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PLACEOFDEATH,'has-warning':errorWarningData.Warning?.PLACEOFDEATH}">
            <mat-label>Place Of Death</mat-label>
            <input matInput name="PLACEOFDEATH" [formControlName]="'PLACEOFDEATH'" placeholder="Place Of Death" matTooltip="{{allToolTip.PLACEOFDEATH?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PLACEOFDEATH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PLACEOFDEATH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MARITALSTATUS,'has-warning':errorWarningData.Warning?.MARITALSTATUS}">
            <mat-label>Marital Status</mat-label>
            <mat-select name="MARITALSTATUS" [formControlName]="'MARITALSTATUS'">
                <mat-option [value]="val.Name" *ngFor="let val of status">{{val.Name}}</mat-option>
            </mat-select>
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MARITALSTATUS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MARITALSTATUS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SPOUSE,'has-warning':errorWarningData.Warning?.SPOUSE}">
            <mat-label>Spouse</mat-label>
            <input name="SPOUSE" [formControlName]="'SPOUSE'" matInput matTooltip="{{allToolTip.SPOUSE?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SPOUSE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SPOUSE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.NUMBEROFDEPENDANTS,'has-warning':errorWarningData.Warning?.NUMBEROFDEPENDANTS}">
            <mat-label>Number Of Dependants</mat-label>
            <input name="NUMBEROFDEPENDANTS" [formControlName]="'NUMBEROFDEPENDANTS'" min='0' max='99' type="number" matInput matTooltip="{{allToolTip.NUMBEROFDEPENDANTS?.HOVER}}">
       <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NUMBEROFDEPENDANTS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NUMBEROFDEPENDANTS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>