<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dilaogtitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <div class="popup_body document_generated_main_div" *ngIf="isLoadingResultsU" style="width: 100%;">
                <div style="position: relative;z-index: 99999;width: 100%;">
                    <h3><strong>{{title}}</strong> is being generated, please give it a moment. </h3><br>
                    <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
                    <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
                </div>
            </div>
            <div class="popup_body document_generated_main_div" *ngIf="isLoadingResultsU" style="width: 100%;">
                <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3>{{btnText}}</h3>
                </div>
            </div>
            <div class="popup_body document_generated_main_div" *ngIf="isPDFGenerated" style="width: 100%;">
                <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div class="middle">The {{title}} report has been produced.</div>
                </div>
                <div fxLayout="row" class="document_generated_main_last" fxLayoutAlign="start center" fxFlex="1 0 auto" class="button">
                    <a href="{{this.base_url}}{{PDF_Generation}}" target="_blank">
                        <button mat-raised-button mat-dialog-close color="accent">Click here to view it</button>
                    </a>
                </div>
            </div>
            <form *ngIf="!isPDFGenerated && !isLoadingResultsU" [formGroup]="FilterForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Date Range List</mat-label>
                        <mat-select formControlName="DATERANGE" (selectionChange)="dateRangeChnange($event.value)">
                            <mat-option value="Last Month">Last Month</mat-option>
                            <mat-option value="Current Month">Current Month</mat-option>
                            <mat-option value="Last Quarter">Last Quarter</mat-option>
                            <mat-option value="Current Quarter">Current Quarter</mat-option>
                            <mat-option value="Last Financial Year">Last Financial Year</mat-option>
                            <mat-option value="Current Financial Year">Current Financial Year</mat-option>
                            <mat-option value="Date Range">Data Range</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>From Date</mat-label>
                        <input matInput placeholder="Choose a date" [satDatepicker]="fromdate" formControlName="FROMDATE" (dateInput)="choosedDate()" (dateChange)="choosedDate()">
                        <sat-datepicker #fromdate> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="fromdate"></sat-datepicker-toggle>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>To Date</mat-label>
                        <input matInput placeholder="Choose a date" [satDatepicker]="todate" formControlName="TODATE" (dateInput)="choosedDate()" (dateChange)="choosedDate()">
                        <sat-datepicker #todate> </sat-datepicker>
                        <sat-datepicker-toggle matSuffix [for]="todate"></sat-datepicker-toggle>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveFilter()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>OK
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>