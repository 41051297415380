<div class=" one " fxLayout="column" [formGroup]="MatterConveyancingDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PROPERTYTYPE,'has-warning':errorWarningData.Warning?.PROPERTYTYPE}">
            <mat-label>Property Type</mat-label>
            <mat-select formControlName="PROPERTYTYPE" matTooltip="{{(toolTipList)?toolTipList.PROPERTYTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
                <mat-option *ngFor="let propertyTypetatusLookup of propertyTypeLookupsData" value="{{propertyTypetatusLookup.LOOKUPGUID}}">
                    {{propertyTypetatusLookup.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
       <div  *ngIf="testBool && toolTipList.PROPERTYTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PROPERTYTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PROPERTYTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-checkbox fxFlex="70" formControlName="ELIGIBLEFORSTAMPDUTYREDUCTION">Eligible For Stamp Duty Reduction </mat-checkbox>
    </div>
    <div fxLayout="row" class="mb-16" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-checkbox fxFlex="33" formControlName="FIRSTHOMEOWNERSGRANT">First Home Owners Grant</mat-checkbox>
        <mat-checkbox fxFlex="24" formControlName="NEWHOMEGRANT">New Home Grant</mat-checkbox>
        <mat-checkbox fxFlex="43" formControlName="EXEMPTFROMPREMIUMPROPERTYDUTY">Exempt From Premium Property Duty</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ELIGIBLEPURCHASERPERCENTAGE,'has-warning':errorWarningData.Warning?.ELIGIBLEPURCHASERPERCENTAGE}">
            <mat-label>Eligible Purchaser Percentage</mat-label>
            <input matInput formControlName="ELIGIBLEPURCHASERPERCENTAGE" matTooltip="{{(toolTipList)?toolTipList.ELIGIBLEPURCHASERPERCENTAGE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
       <div  *ngIf="testBool && toolTipList.ELIGIBLEPURCHASERPERCENTAGE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ELIGIBLEPURCHASERPERCENTAGE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ELIGIBLEPURCHASERPERCENTAGE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-checkbox fxFlex="50" formControlName="FOREIGNPURCHASER">Foreign Purchaser</mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <div fxLayout="column" fxFlex="50">
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DUTIBLEAMOUNT,'has-warning':errorWarningData.Warning?.DUTIBLEAMOUNT}">
                <mat-label> Dutible Amount</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('DUTIBLEAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.DUTIBLEAMOUNT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DUTIBLEAMOUNT" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.DUTIBLEAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DUTIBLEAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUTIBLEAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALDUTY,'has-warning':errorWarningData.Warning?.ADDITIONALDUTY}">
                <mat-label>Additional Duty</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('ADDITIONALDUTY').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.ADDITIONALDUTY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ADDITIONALDUTY" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.ADDITIONALDUTY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ADDITIONALDUTY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALDUTY?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LODGEMENTFEE,'has-warning':errorWarningData.Warning?.LODGEMENTFEE}">
                <mat-label>Lodgement Fee</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('LODGEMENTFEE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.LODGEMENTFEE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="LODGEMENTFEE" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.LODGEMENTFEE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LODGEMENTFEE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LODGEMENTFEE?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ADVALOREMFEE,'has-warning':errorWarningData.Warning?.ADVALOREMFEE}">
                <mat-label>Ad Valorem Fee</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('ADVALOREMFEE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.ADVALOREMFEE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ADVALOREMFEE" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.ADVALOREMFEE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ADVALOREMFEE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADVALOREMFEE?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TOTALREGISTRATIONFEES,'has-warning':errorWarningData.Warning?.TOTALREGISTRATIONFEES}">
                <mat-label>Total Registration Fees</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('TOTALREGISTRATIONFEES').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.TOTALREGISTRATIONFEES?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="TOTALREGISTRATIONFEES" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.TOTALREGISTRATIONFEES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TOTALREGISTRATIONFEES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALREGISTRATIONFEES?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="50" class="mt-72">
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STAMPDUTYAMOUNT,'has-warning':errorWarningData.Warning?.STAMPDUTYAMOUNT}">
                <mat-label>Stamp Duty</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('STAMPDUTYAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  matTooltip="{{(toolTipList)?toolTipList.STAMPDUTYAMOUNT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STAMPDUTYAMOUNT" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.STAMPDUTYAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STAMPDUTYAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STAMPDUTYAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STAMPDUTYDUEDATE,'has-warning':errorWarningData.Warning?.STAMPDUTYDUEDATE}">
                <mat-label>Stamp Duty Due</mat-label>
                <input (dateInput)="CommanDatePickerClick('input', $event,'STAMPDUTYDUEDATE')"  matTooltip="{{(toolTipList)?toolTipList.STAMPDUTYDUEDATETEXT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STAMPDUTYDUEDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'STAMPDUTYDUEDATE')" matInput name="StampDutyDue" [matDatepicker]="StampDutyDue">
                <mat-datepicker-toggle matSuffix [for]="StampDutyDue"></mat-datepicker-toggle>
                <mat-datepicker #StampDutyDue></mat-datepicker>
           <div  *ngIf="testBool && toolTipList.STAMPDUTYDUEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STAMPDUTYDUEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STAMPDUTYDUEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STAMPDUTYPAIDDATE,'has-warning':errorWarningData.Warning?.STAMPDUTYPAIDDATE}">
                <mat-label>Stamp Duty Paid</mat-label>
                <input (dateInput)="CommanDatePickerClick('input', $event,'STAMPDUTYPAIDDATE')"  matTooltip="{{(toolTipList)?toolTipList.STAMPDUTYPAIDDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="STAMPDUTYPAIDDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'STAMPDUTYPAIDDATE')" matInput name="StampDutyPaid" [matDatepicker]="StampDutyPaid">
                <mat-datepicker-toggle matSuffix [for]="StampDutyPaid"></mat-datepicker-toggle>
                <mat-datepicker #StampDutyPaid></mat-datepicker>
           <div  *ngIf="testBool && toolTipList.STAMPDUTYPAIDDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.STAMPDUTYPAIDDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STAMPDUTYPAIDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OSRRECEIPTNO,'has-warning':errorWarningData.Warning?.OSRRECEIPTNO}">
                <mat-label>OSR Receipt No</mat-label>
                <input matInput  matTooltip="{{(toolTipList)?toolTipList.OSRRECEIPTNO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="OSRRECEIPTNO">
        <div  *ngIf="testBool && toolTipList.OSRRECEIPTNO" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OSRRECEIPTNO.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OSRRECEIPTNO?.COPYVALUE:'')">file_copy</mat-icon></div>   
        </mat-form-field>
        </div>
    </div>
</div>