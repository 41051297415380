import { HttpClient } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap } from "rxjs";
import { MainAPiServiceService } from "@_services/main-api-service.service";

@Component({
    selector: "app-help-desk",
    templateUrl: "./help-desk.component.html",
    styleUrls: ["./help-desk.component.scss"],
})
export class HelpDeskComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    subscription1: Subscription;
    sub1: Subscription;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    private searchTerms = new Subject<string>();

    /**
     * Constructor
     * @param {FuseSidebarService} _fuseSidebarService
     */

    constructor(
        private httpClient: HttpClient,
        public dialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private sanitizer: DomSanitizer,
        private _fuseSidebarService: FuseSidebarService,
        private router: Router
    ) { }
    searchDataValue = "";
    searchResult = [];
    topMostResult = [];
    openWebsite: any;
    loader: any = false;
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.sub1 = this._fuseSidebarService.sidebarMenuPopup.subscribe(
            (res) => {
                let currentRoute = this.router.url.split("/")[1];
                this.topSearches(currentRoute);
            }
        );

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.searchData(term)) // Switch to new observable on new term
          ).subscribe(response => {
            this.searchResult = response.RESPONSE.results;
          });
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.subscription?.unsubscribe();
        this.subscription1?.unsubscribe();
    }

    /**
     * This function is used to open the new tab.
     */
    newTab() {
        if (this.openWebsite) {
            window.open(
                this.openWebsite.changingThisBreaksApplicationSecurity,
                "_blank"
            );
        }
    }

    // Push a search term into the observable stream
    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * This function is used to search the data
     */
    searchData(searchValue: any) {
        // const API = `https://api.hubapi.com/cms/v3/site-search/search?q=${searchValue}&domain=community.silq.com.au&hapikey=dfe82382-9bef-401f-811b-369b21057ee3`
        // this.httpClient.get<any>(API).subscribe((res)=>{

        // });

        // let data = {
        //     Action: "search",
        //     SearchTerm: searchValue,
        // };

        // this.subscription = this._mainAPiServiceService
        //     .getSetData(data, "knowledge-base")
        //     .subscribe((response) => {
        //         this.searchResult = response.RESPONSE.results;
        //     });
        let data = {
            Action: "search",
            SearchTerm: searchValue,
        };
      
        return this._mainAPiServiceService.getSetData(data, "knowledge-base");
    }

    /**
     * This function is used to go with securely
     */
    goTo(url) {
        // this.openWebsite = encodeURI(url);
        if (url) {
            this.openWebsite = this.sanitizer.bypassSecurityTrustResourceUrl(
                url.replace("http://", "https://")
            );
        } else {
            this.openWebsite = undefined;
        }
        this.loader = true;
        setTimeout(() => {
            this.loader = false;
        }, 2000);
    }

    /**
     * This function is used to go to the back to the home.
     */
    backToHome():void {
        this.openWebsite = undefined;
    }

    /**
     * This function is used to goto the back to help.
     */
    backToHelp():void {
        this.searchResult = [];
        this.searchDataValue = "";
    }

    /**
     * This function is used to close the data value.
     */
    close(key) {
        this._fuseSidebarService.getSidebar(key).close();
    }

    /**
     * This is used to senitize the html data value. 
     */
    senetizeHtml(description) {
        return this.sanitizer.bypassSecurityTrustHtml(description);
    }

    /**
     * This function is used to open the dialog data value.
     */
    openDialog() {
        let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: {
                isContactUs: true,
                message:
                    "<p>Call us on <a href='tel:1300556689'><b>1300 55 66 89</b></a>  or you can email us at <a href='mailto:support@silq.com.au'>support@silq.com.au</a>. Please provide as much detailed information as possible so we can assist you.<p>",
                // message:"<p>Please email us at <a href='mailto:support@silq.com.au'>support@silq.com.au</a>. Please provide as much detailed information as possible so we can assist you.</p>"
            },
        });

        confirmDialogRef.componentInstance.confirmMessage = "";
        confirmDialogRef.componentInstance.confirmTitle = "Contact support";
    }

    /**
     * This function is used to top searche the data values.
     */
    topSearches(searchValue):void {
        let data = {
            Action: "search",
            SearchTerm: searchValue,
            Limit: 5,
            MinScore: 0.04,
            PopularityBoost: 2.0,
        };

        this.subscription1 = this._mainAPiServiceService
            .getSetData(data, "knowledge-base")
            .subscribe((response) => {
                this.topMostResult = response.RESPONSE.results;
            });
    }
}
