import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { TooltipService } from '@_services/tooltip.service';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
})
export class OtherComponent implements OnInit {

  common: any;
  selectGender: any;
  status: { Id: number; Name: string; }[];
  constructor(public datepipe: DatePipe,
    public tooltipService:TooltipService) { }
  @Input() allToolTip:any ;
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;

  /**
   * This function is
   * @param type 
   * @param event 
   */
  choosedDateOfBirth(type: string, event: MatDatepickerInputEvent<Date>) {
    this.contactForm.controls['DATEOFBIRTHTEXT'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  /**
   * This function is used to choose the Date of death
   * @param type --type of date
   * @param event -event value
   */
  choosedDateOfDath(type: string, event: MatDatepickerInputEvent<Date>) {
    this.contactForm.controls['DATEOFDEATHTEXT'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  /**
   * This function is used to choose the Date of death range
   * @param type --type of date
   * @param event -event value
   */
  choosedDateOfDathRange(type: string, event: MatDatepickerInputEvent<Date>){
    const date:any = event.value
    this.contactForm.controls['DATEOFDEATHRANGETEXT'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // this.contactForm.controls['DATEOFDEATHRANGETEXT'].setValue(this.datepipe.transform(date.begin, 'dd/MM/yyyy') + " " +this.datepipe.transform(date.end, 'dd/MM/yyyy'));    
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.common = [
      { Id: 1, Name: "Male" },
      { Id: 2, Name: "Female" },
      { Id: 3, Name: "Unknown" },
    ];

    this.status = [{ Id: 1, Name: "Single" },
    { Id: 2, Name: "Married" },
    { Id: 3, Name: "Divorced" },
    { Id: 4, Name: "Separated" },
    { Id: 5, Name: "De facto" },
    ];
  }

}
