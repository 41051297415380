<div class="one prepare_invoice_summary" [formGroup]="addInvoiceForm" fxLayout="column">
<div class="value_label_parent_1">
    <mat-form-field appearance="outline" fxFlex="50" class="value_width" class="pr-4">
<mat-label>Exchange Rate</mat-label>        
<input formControlName="FOREIGNCURRENCYRATE" class="sideText" matInput readonly placeholder="Exchange Rate" >
    </mat-form-field>
    <div fxFlex="10" class="value_width"></div>
    <mat-form-field appearance="outline" fxFlex="50" class="value_width" class="pr-4">
 <mat-label>Foreign Amount (Ex-GST)</mat-label>        
<input formControlName="FOREIGNCURRENCYAMOUNT" class="sideText" matInput readonly placeholder="Foreign Amount (Ex-GST)" >
    </mat-form-field>
</div>
<div class="value_label_parent_2">
    <mat-form-field appearance="outline" fxFlex="50" class="value_width" class="pr-4">
 <mat-label>Foreign GST</mat-label>        
<input formControlName="FOREIGNCURRENCYGST" class="sideText" matInput readonly placeholder="Foreign GST">
    </mat-form-field>
    <div fxFlex="10" class="value_width"></div>
    <mat-form-field appearance="outline" fxFlex="50" class="value_width" class="pr-4">
<mat-label>Foreign Amount (Inc-GST)</mat-label>        
<input formControlName="FOREIGNCURRENCYINCGST" class="sideText" matInput readonly placeholder="Foreign Amount (Inc-GST)" >
    </mat-form-field>
</div>
</div>