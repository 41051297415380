<div class="one" fxLayout="column">
    <div class="content">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="pt-20">
            <div class="table_overflow_auto relative_position_auto">
                <table mat-table matSort [dataSource]="dataSource" class="modern" #Table>
                    <!-- Name Column -->
                    <ng-container matColumnDef="CONTACTNAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate"> {{row?.CONTACTNAME }} </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PHONE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate"> {{row?.CONTACTPHONE }} </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="MOBILE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate"> {{row?.CONTACTMOBILE }} </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="EMAIL">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate"> {{row?.CONTACTEMAIL }} </p>
                        </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr [style.background]="(highlightedRows == row.CONTACTGUID && highlightedRows) ? selectedColore : ''" [ngClass]="(highlightedRows && highlightedRows == row.CONTACTGUID) ? 'row-text-colore' : ''" highlightedRows=row.CONTACTGUID mat-row
                        *matRowDef="let row; let index = index; columns: displayedColumns;" (click)="editContact(row);highlightedRows = row.CONTACTGUID;itemRowClick(index) " matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
                <div class="grid-overlay" *ngIf="isDisplay">
                    <div class="grid-overlay-msg"> There is no data to display. </div>
                </div>
                <mat-paginator [pageSizeOptions]="[20,50,100]" showFirstLastButtons (page)="onPaginateChange($event)"></mat-paginator>
            </div>
        </div>
    </div>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" align="end" class="popup_ftr">
        <button fxFlex="29.5" class="pr-4 btn-add" mat-raised-button color="accent" cdkFocusInitial (click)="AddContactsDialog()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Add to Company
        </button>
        <button fxFlex="29.5" class="pr-4 btn-add" mat-raised-button color="accent" cdkFocusInitial (click)="EditContactsDialog(currentContactData?.CONTACTGUID)" [disabled]="!currentContactData">Edit Company 
        </button>
        <button fxFlex="29.5" class="pr-4 btn-add" mat-raised-button color="primary" cdkFocusInitial (click)="deleteContact()" [disabled]="!currentContactData || isspiner2">
            <mat-spinner *ngIf="isspiner2"></mat-spinner>Remove from Company 
        </button>
    </mat-dialog-actions>
</div>