import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { TooltipService } from '@_services/tooltip.service';
import { AllCountryCodeService } from '@_services/allCountry-code.service';
@Component({
  selector: 'app-ph',
  templateUrl: './ph.component.html',
  styleUrls: ['./ph.component.scss']
})
export class PhComponent implements OnInit, OnDestroy {
  phoneTest: any;
  CountryArray: any = [];
  filteredOptions: Observable<string[]>;
  filteredOptionsPhone_2: Observable<string[]>;
  filteredOptionsFax_1: Observable<string[]>;
  filteredOptionsFax_2: Observable<string[]>;
  filteredOptionsMobile: Observable<string[]>;
  sub: Subscription;
  options: any = [];
  singalCountryName: any;
  singalCountryCode: any;
  constructor(private allCountryService: AllCountryCodeService,
    public tooltipService:TooltipService) { }
  @Input() allToolTip:any = [];
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any;
  telOptions = { initialCountry: 'us', preferredCountries: ['us'] };

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.sub = this.allCountryService.getCountryData().subscribe((result) => {
      this.CountryArray = result;
      this.filteredOptions = this.f.PHONE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.filteredOptionsPhone_2 = this.f.PHONE2.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.filteredOptionsFax_1 = this.f.FAX.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.filteredOptionsFax_2 = this.f.FAX2.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
      this.filteredOptionsMobile = this.f.MOBILE.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
    })
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CountryArray.filter(option => (option.name.toLowerCase().includes(filterValue)) || (option.dial_code.toLowerCase().includes(filterValue)));
  }

  /**
   * This function is used to change the number format
   * @param event -number Formate value
   */
  numberFormate(event) {
    let targetName = event.target.name;
    let targetVal = event.target.value;
    if (targetVal.indexOf('+') > -1 && targetVal.indexOf('+61') != 0) {
      const phoneNumber = parsePhoneNumberFromString(targetVal)
      if (phoneNumber != undefined) {
        this.contactForm.controls[targetName].setValue(phoneNumber.formatInternational());
      }
    } else if (targetVal.indexOf('+61') == 0) {
      let len = targetVal.length;
      if (len == 11 || len == 12) {
        const phoneNumber = parsePhoneNumberFromString(targetVal);
        let phone = phoneNumber.formatNational();
        let output = [phone.slice(0, 4), ' ', phone.slice(4)].join('');
        this.contactForm.controls[targetName].setValue(output);
      } else {
        const phoneNumber = parsePhoneNumberFromString(targetVal)
        if (phoneNumber != undefined) {
          this.contactForm.controls[targetName].setValue(phoneNumber.formatNational());
        }
      }
    } else {
      let str = targetVal;
      let strLen = str.length;
      if (strLen == 8) {
        let output = [str.slice(0, 4), ' ', str.slice(4)].join('');
        this.contactForm.controls[targetName].setValue(output);
      } else {
        const phoneNumber = parsePhoneNumberFromString('+61' + targetVal)
        if (phoneNumber != undefined) {
          this.contactForm.controls[targetName].setValue(phoneNumber.formatNational());
        }
      }
    }
  }
  get f() {
    return this.contactForm.controls;
  }
  /**
   * This function is used to  update the selection value 
   * @param val -this function is used to update the selection value
   */
  updateMySelection(val) {
    this.singalCountryName = val.source.value.name;
    this.singalCountryCode = val.source.value.dial_code;
  }
}
