import { BehaviorService } from "@_services/Behavior.service";
import { map, tap } from "rxjs";
import { Component, OnInit, inject } from "@angular/core";
import { ContactDialogComponent } from "@pages/contact/contact-dialog/contact-dialog.component";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import * as $ from 'jquery';
import { GenerateDocumentPopupComponent } from "@pages/template/generate-document-popup/generate-document-popup.component";
import { MatterDialogComponentForTemplate } from "@pages/template/matter-dialog/matter-dialog.component";
import { ToolbarServiceService } from "src/app/layout/components/toolbar/toolbar-service.service";
import { ReCalcTimeEntriesDialogeComponent } from "@pages/time-billing/re-calc-timeEntrie-dialoge/re-calc-timeEntrie-dialoge.component";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { SearchandreplaceworkitemComponent } from "@pages/time-billing/searchandreplaceworkitem/searchandreplaceworkitem.component";

@Component({
    selector: "app-bulk-invoicing-workitem",
    templateUrl: "./bulk-invoicing-workitem.component.html",
    styleUrls: ["./bulk-invoicing-workitem.component.scss"],
})
export class BulkInvoicingWorkitemComponent implements OnInit {
  appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    private _fuseSidebarService = inject(FuseSidebarService);
    private behaviourService = inject(BehaviorService);
    public dialog = inject(MatDialog);
    public currentuserdata = JSON.parse(localStorage.getItem("currentUser"));
    public toaster=inject(ToastrService)
    public _mainAPiServiceService=inject(MainAPiServiceService);
    public globalFunService=inject(GlobalFunctionsService);
    public _matDialog=inject(MatDialog);
    public toolbarServiceService=inject(ToolbarServiceService);
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public bulkInvoiceWorkItems$ =this.behaviourService.bulkInvoiceWorkItemslist$.asObservable().pipe(map((data) => ({ invoiceBulkWorkItems: data })),tap((data)=>{console.log(data)}));
    public SingleMatterData$=this.behaviourService.SingleInvoiceData$.asObservable().pipe(map((data)=> ({ SingleInvoiceData:data })))
    currentMatter: any;
    isLoadingResults: boolean;
    InvoiceGUID: any[]=[];
    ContextGuidID: any=[];
    errorWarningData: any = {};
    CheckBoxBulkRecord: any[]=[];
    filterCheckBoxBulkRecord: any;
    passdata: {  };
  isWIPData: string;

   /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {

      this.behaviourService.workInProgress$
      .subscribe((result) => {
          if (result) {
              this.isWIPData = "Notempty";
          } else {
              this.isWIPData = "empty";
          }
      });
    }

    /**
     * This function is used to close the sidebar.
     * @param key -key data
     */
    close(key) {
        this._fuseSidebarService.getSidebar(key).close();
    }

    /**
     * This function is used to Open the Matter.
     */
    openMatter() {
        setTimeout(() => {
            this.behaviourService.UpdateMartterData$.next(true);
        }, 1000);
        this.behaviourService.openDifferentTab("editMatter");
    }

    /**
     * This function is used to Open the Contact Dialog.
     */
    openContactName(id) {
        let contactPopupData = { action: "edit" ,CONTACTGUID:((id && id !=null && id !=undefined && id !='') ? id : '')};
        const dialogRef = this.dialog.open(ContactDialogComponent, {
            disableClose: true,
            width: "100%",
            data: contactPopupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.data?.Data ) {
                this.currentMatter.CONTACTNAME = result.data.Data.CONTACTNAME;
                localStorage.setItem(
                    "set_active_matters",
                    JSON.stringify(this.currentMatter)
                );
            }
        });
    }

/**
 * Save the multiple invoice one by one format
 */
async SaveInvoice(data?:any,value?,SingleData?) {
    this.isLoadingResults=true
    const SingleValue=SingleData?.SingleInvoiceData;
    const matterItem = value?.invoiceBulkWorkItems;
    let PostData;
           PostData = {
            INVOICECODE: '',
            MATTERGUID: matterItem.MATTERGUID,
            INVOICEDATE: SingleValue.INVOICEDATE,
            DUEDATE: SingleValue.DUEDATE,
            PRINTEDDATE: '',
            INVOICETOTAL: matterItem.UNBILLEDVALUEEXGST,
            INVOICETOTALINCGST: matterItem.UNBILLEDVALUEINCGST,
            GST: (matterItem?.UNBILLEDVALUEINCGST - matterItem?.UNBILLEDVALUEEXGST),
            FOREIGNCURRENCYID: '',
            COMMENT: '',
            ADDITIONALTEXT: '',
            BULKINVOICING:true,
            INVOICEUPTO:SingleValue.FILLUPTO
        };
        
  
      let PostInvoiceEntryData: any = { FormAction:'invoiceall', VALIDATEONLY: true, Data: PostData };
  
      this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(async res => {
  
          PostInvoiceEntryData = { FormAction:'invoiceall', VALIDATEONLY: true, Data: PostData };
          if (res.CODE == 200 && res.STATUS == 'success') {
           await   this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData,data,SingleValue);
          } else if (res.CODE == 451 && res.STATUS == 'warning') {
            await  this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData,data,SingleValue);
              this.isLoadingResults = false;
             
          } else if ((res.CODE=="409" || res.CODE=="450" || res.CODE == 450) && res.STATUS == 'error') {
            await  this.checkValidation(res.DATA.VALIDATIONS, PostInvoiceEntryData,data,SingleValue);
              this.isLoadingResults = false;
          } else if (res.MESSAGE == 'Not logged in') {
            this.isLoadingResults = false;
          }
        
          
      }, err => {
       
          this.isLoadingResults = false;
          this.toaster.error(err);
      });
    this.isLoadingResults=false
    
  }
  
  /**
   * This function is used to check the validation.
   * @param bodyData -validation data
   * @param PostInvoiceEntryData -invoice data
   * @returns 
   */
   async checkValidation(bodyData: any, PostInvoiceEntryData: any,Type,SingleValue): Promise<void> {
      await this.globalFunService.checkValidation(bodyData, PostInvoiceEntryData)
      .subscribe(async data => {
          if (data) {
              this.errorWarningData = data.errorWarningData;
              if (data.callback) {
                this.isLoadingResults = false;
                 await this.saveInvoice(PostInvoiceEntryData,Type,SingleValue);
                 
              } else {
                  this.isLoadingResults = false;
                  
              }
          }
      });
   
  }
  
  /**
   * This function is used to save the invoice with validation false
   * @param PostInvoiceEntryData -invoice data
   * @returns 
   */
  async saveInvoice(PostInvoiceEntryData: any,Type,SingleValue):Promise<void> {
    PostInvoiceEntryData.VALIDATEONLY = false;
    this.isLoadingResults = true;
    await  this._mainAPiServiceService.getSetData(PostInvoiceEntryData, 'SetInvoice').subscribe(async res => {
        if (res.CODE == 200 && res.STATUS == "success") {
          this.InvoiceGUID.push({ContextGuid:res.DATA.INVOICEGUID})
          this.ContextGuidID.push({INVOICEGUID:res.DATA.INVOICEGUID})
          this.behaviourService.matterInvoiceData({ INVOICEGUID: res.DATA.INVOICEGUID });
            let CreditinvoiceData = PostInvoiceEntryData.Data;
            CreditinvoiceData.INVOICEGUID = res.DATA.INVOICEGUID;
            localStorage.setItem('Create_INVOICEGUID_WorkInProgress', CreditinvoiceData.INVOICEGUID)
            CreditinvoiceData.AMOUNTOUTSTANDINGINCGST = PostInvoiceEntryData.Data.INVOICETOTALINCGST;
         this.exicuteLoop(Type,SingleValue);
         this.toaster.success(res.STATUS);
        } else {
            if (res.CODE=="409" || (res.CODE == 402 && res.STATUS == 'error' && res.MESSAGE == 'Not logged in')) {
            this.exicuteLoop(Type,SingleValue);
            }
        };
        this.isLoadingResults = false;
    },async err => {
      this.isLoadingResults = false;
        this.toaster.error(err);
        this.exicuteLoop(Type,SingleValue); 
    });   
}


/**
 * This function is deside which function we need to call
 */
exicuteLoop(Type,SingleValue){
   
      if(Type=='Create Bulk Invoice'){
        $('#refreshInvoiceTab').click()
        $('#Legal_Details').click();
        this.CheckBoxBulkRecord=[];
        this.behaviourService.CheckBulkInvoiceData$.next(this.CheckBoxBulkRecord);
        this.InvoiceGUID=[];
        this.ContextGuidID=[]
        localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
      }else if(Type=='Create+Generate Bulk Invoice'){
        this.passdata = {
          Context: 'Invoice',
          knownby:'Template',
          Type:'Template',
          Template: SingleValue?.INVOICETEMPLATEE,
      } 
      if(this.InvoiceGUID?.length > 0){
        this.invoicedatawip();
      }
      }else if(Type=='Create+Email Bulk Invoice'){
          if(this.ContextGuidID?.length > 0){
            this.ForEmailDialogOpen(SingleValue);
          }
      }
  }
  
  /**
 * This funtion is used to generate the email.
 */
ForEmailDialogOpen(SingleValue) {
    localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
  let  passdata = { 'Context': "Invoice", 'ContextGuid': '', "knownby": "Email", "Type": "Email", "Folder": '', "Template": SingleValue?.Template, isMultipleDocument: true }
  
    const dialogRef = this._matDialog.open(MatterDialogComponentForTemplate, {
        width: '100%',
        disableClose: true,
        data: passdata
    });
    dialogRef.afterClosed().subscribe(result => { 
        this.close('BulkInvoicingWorkItemPanel')
          $('#refreshInvoiceTab').click()
          $('#Legal_Details').click();
          $("#BulkInvoices").click();
          this.CheckBoxBulkRecord=[];
          this.behaviourService.CheckBulkInvoiceData$.next(this.CheckBoxBulkRecord);
          this.InvoiceGUID=[];
          this.ContextGuidID=[]
          localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
    });
  }




/**
 * This function is used to generate the invoice
 */
invoicedatawip() {
    let dialogRef: any;
    dialogRef = this.dialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: { Context: 'Invoice', multiSelect:this.InvoiceGUID, multipledatainvoice: true, passdata: this.passdata } });  
     dialogRef.afterClosed().subscribe((result: any) => {
        this.close('BulkInvoicingWorkItemPanel')
           $('#refreshInvoiceTab').click()
           $('#Legal_Details').click();
           $("#BulkInvoices").click();
            this.CheckBoxBulkRecord=[];
            this.behaviourService.CheckBulkInvoiceData$.next(this.CheckBoxBulkRecord);
            this.InvoiceGUID=[];
            this.ContextGuidID=[]
            localStorage.setItem('ContextGuidID',JSON.stringify(this.ContextGuidID));
           // this.getMatterDataAPI();
           // this.selection.clear()
    });
    
    }



/**
 * This function is used to Calling the preview
 * @param value -value data
 * @param SingleData -single data value
 */
callingPreview(value?,SingleData?){
    const SingleValue=SingleData?.SingleInvoiceData;
    const matterItem = value?.invoiceBulkWorkItems;
      let passdata: any = {
          Context: "previewall",
          ContextGuid: "",
          knownby: "Template",
          Type: "Template",
          data: [matterItem],
          //Template:SingleValue?.Template,
          isMultipleDocument: true,
          isBulkPreview:true,
          INVOICEDATE: SingleValue?.INVOICEDATE,
          DUEDATE: SingleValue?.DUEDATE,
          BULKINVOICING:true,
          INVOICEUPTO:SingleValue?.INVOICEUPTO,
          Template:SingleValue?.INVOICETEMPLATEE
      };
      this.ForDocDialogOpen(passdata);  
  }
  
       /**
       * This function is used to open the dock dialog data value
       * @param passdata -value data
       */
   ForDocDialogOpen(passdata) {
    const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
        width: "100%",
        disableClose: true,
        data: passdata,
    });
    dialogRef.afterClosed().subscribe((result) => {
         // $('#refreshInvoiceTab').click()
        //  $('#Legal_Details').click();
    });
  }

 /**
     * This function is used to RecalcTimeEntry data 
     */
 ReCalcTimeEntriClick() {
  this.toolbarServiceService.SetOldistrackid("open");
  const dialogRef = this._matDialog.open(
      ReCalcTimeEntriesDialogeComponent,
      {
          width: "100%",
          disableClose: true,
          data: { action: "" },
      }
  );
  dialogRef.afterClosed().subscribe((result) => {
      this.toolbarServiceService.SetOldistrackid("close");
      if (result == true) {
          this.confirmDialogRef = this._matDialog.open(
              FuseConfirmDialogComponent,
              {
                  disableClose: true,
                  width: "100%",
                  data: {
                      errorData: ["There are no Entries to change"],
                  },
              }
          );
          this.confirmDialogRef.componentInstance.confirmMessage =
              "Recalculate Time Entries";
      } else {
      }
  });
}

/**
 * This function is used to Search and replace Workitem On click
 */
searchAndReplaceWorkItemOnClick() {
    this.toolbarServiceService.SetOldistrackid("open");
    const dialogRef = this._matDialog.open(
        SearchandreplaceworkitemComponent,
        {
            width: "100%",
            disableClose: true,
            data: { action: "" },
        }
    );
    dialogRef.afterClosed().subscribe((result) => {
        this.toolbarServiceService.SetOldistrackid("close");
        if (result) {
        }
    });
}

}