import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatterDialogComponentForTemplate } from '../../template/matter-dialog/matter-dialog.component';
import { GenerateDocumentPopupComponent } from '../../template/generate-document-popup/generate-document-popup.component';
import { GeneratePacksPopupComponent } from '../../template/packs/generate-packs-popup/generate-packs-popup.component';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription, forkJoin, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-generate-invoice',
    templateUrl: './generate-invoice.component.html',
    styleUrls: ['./generate-invoice.component.scss'],
    animations: fuseAnimations
})
export class GenerateInvoiceComponent implements OnInit, OnDestroy {

    selectedTabIndex: number = 0;
    @Input() SettingForm: FormGroup;
    isLoadingResults: boolean = false;
    @Input() errorWarningData: any;
    addData: any = [];
    theme_type = localStorage.getItem('theme_type');
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = 'rgb(217, 217, 217)';
    displayedColumns: string[] = ['Pack'];
    GenerateInvoiceData: MatTableDataSource<any>;
    highlightedRows: any;
    slectedPack: any;
    Invoicedata: any;
    passdata: any = {};
    temptype: string;
    passdataArr: any = []
    EmailtemplateData: any;
    InvoiceTamplateList: any = [];
    defaultLookupValue: any = ''
    InvoiceSelectedTamplate: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub: Subscription;
    accessTierPermission: any = JSON.parse(
        localStorage.getItem("set_tier_permission")
    );
    isShownEmailPack: boolean=false;
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService, public dialogRef: MatDialogRef<GenerateInvoiceComponent>,
        private behaviorService: BehaviorService,
        private dialog: MatDialog,
        private router: Router,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {



let data = this.accessTierPermission.filter(data=> data.FEATURENAME == 'Document Generation');
if(data.length >0){
    if(data[0].ALLOWED == 1){
        this.isShownEmailPack=true
    }else{
        this.isShownEmailPack=false
    }
}else{
    this.isShownEmailPack=false

}
        localStorage.setItem('istrackid', 'GenerateInvoicedialogComponent');
        this.behaviorService.matterInvoice$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.Invoicedata = result;
            }
        });
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'GenerateInvoicedialogComponent' && result['click'] && result['data']['document-pack']) {
                this.loadData();
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.loadData();
    };

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
    }

    /**
     * This function is used to load the invoice template
     */
    loadInvoiceTemplate():void {
        this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { LookupType: "invoice formats" } }, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.InvoiceTamplateList = res.DATA.LOOKUPS
                setTimeout(() => {
                    this.defaultLookupValue = this.InvoiceTamplateList[0].LOOKUPGUID
                }, 1000);

            };
        });
    }

    /**
     * initial load data when user first opens the generate invoice window
     */
    loadData() {
        this.isLoadingResults = true;
        let finalData = { ACTION: 'GetData', Filters: { Context: "Invoice" } }
        this.sub = forkJoin([
            this._mainAPiServiceService.getSetData(finalData, 'document-pack'),
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { LookupType: "invoice formats" } }, 'lookup')
        ]).pipe(
            tap(([documents,lookups]) => {
                if (documents.CODE == 200 && documents.STATUS == "success") {
                    // this.GenerateInvoiceData = res.DATA.RECORDS;
                    this.GenerateInvoiceData = new MatTableDataSource(documents.DATA.RECORDS);
                    if (documents.DATA.RECORDS[0]) {
                        this.highlightedRows = documents.DATA.RECORDS[0].KITGUID;
                        this.RowClick(documents.DATA.RECORDS[0]);
                    } else {
                        //
                    }
                } else if (documents.MESSAGE == 'Not logged in') {
                    this.isLoadingResults = false;
                    this.dialogRef.close(false);
                }

                if (lookups.CODE == 200 && lookups.STATUS == "success") {
                    this.InvoiceTamplateList = lookups.DATA.LOOKUPS
                    setTimeout(() => {
                        this.defaultLookupValue = this.InvoiceTamplateList[0].LOOKUPGUID
                    }, 1000);
                    this.isLoadingResults = false;
                }
            })
        ).subscribe(
            next => console.log(next),
            error => {
                this.toastr.error(error);
                this.isLoadingResults = false;
            }
        )
        // this._mainAPiServiceService.getSetData(finalData, 'document-pack').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        //     if (res.CODE == 200 && res.STATUS == "success") {
        //         // this.GenerateInvoiceData = res.DATA.RECORDS;
        //         this.GenerateInvoiceData = new MatTableDataSource(res.DATA.RECORDS);
        //         if (res.DATA.RECORDS[0]) {
        //             this.highlightedRows = res.DATA.RECORDS[0].KITGUID;
        //             this.RowClick(res.DATA.RECORDS[0]);
        //         } else {
        //             //
        //         }
        //     } else if (res.MESSAGE == 'Not logged in') {
        //         this.dialogRef.close(false);
        //     }
        //     this.isLoadingResults = false;
        // }, err => {
        //     this.toastr.error(err);
        //     this.isLoadingResults = false;
        // });
    }

    /**
     * This function is used to click on the row
     * @param val -val data
     */
    RowClick(val):void {
        this.slectedPack = val;
    }

    /**
     * This function is used to generte the Document pack
     * @param val -Val data
     * @returns -data value
     */
    GenerateDocumentPack(val):void {
        let checkType = this.slectedPack.KITNAME.split('-');
        if (checkType[1] == " Email") {
            this.temptype = "Email"
        } else {
            this.temptype = "Pack"
        }
        if (this.Invoicedata && this.Invoicedata != null || undefined || '') {
            this.passdata = {
                Context: 'Invoice',
                ContextGuid: this.Invoicedata.INVOICEGUID
            }
        }

        if (val == "Pack") {
            this.passdata.knownby = 'Pack';
            this.passdata.Type = 'Pack';
            this.passdata.Template = this.slectedPack.KITNAME;
        } else if (val == "Template") {
            this.passdata.knownby = 'Template';
            this.passdata.Type = 'Template';
            this.passdata.Template = '<DEFINVTEMP>';
            // select invoice selection changes.
            if (this.InvoiceSelectedTamplate) {
                this.passdata.Template = this.InvoiceSelectedTamplate;
            };

            if (this._data.multipledatainvoice && this._data.context == 'invoiceall') {
                this.invoicedatawip(this.passdata)
                return
            }
        } else {
            this.passdata.Template = this.slectedPack.KITNAME;
            this.passdata.knownby = this.temptype;
            this.passdata.Type = this.temptype;
        }
        let dialogRef: any;
        if (this.passdata.Type == "Template") {
            dialogRef = this.dialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: this.passdata });
        } else if (this.passdata.Type == "Pack") {
            dialogRef = this.dialog.open(GeneratePacksPopupComponent, { width: '100%', disableClose: true, data: this.passdata });
        } else {
            dialogRef = this.dialog.open(MatterDialogComponentForTemplate, { width: '100%', disableClose: true, data: this.passdata });
        }
        dialogRef.afterClosed().subscribe(result => { });
    };

    /**
     * This function is used to select the template data value
     * @param event -event data value
     */
    selectTamplate(event: any) {
        this.InvoiceSelectedTamplate = event.value;

    }

    /**
     * This function is used to get the invoiced data WIP
     * @param data -data value
     */
    invoicedatawip(data: any):void {
        this.passdataArr = []
        if (this._data.multipledatainvoice) {
            this._data.invoiceallarrData.forEach(element => {
                this.passdataArr.push({
                    ContextGuid: element.DATA.INVOICEGUID ,
                })
            });
            let dialogRef: any;
            if (this.passdata.Type == "Template")  {
                dialogRef = this.dialog.open(GenerateDocumentPopupComponent, { width: '100%', disableClose: true, data: { Context: 'Invoice', multiSelect: this.passdataArr, multipledatainvoice: true, passdata: this.passdata } });
            }

        }

    }

    /**
     * select tab index of selected item
     * @param tabIndex
     */
    selectTab(tabIndex) {
        this.selectedTabIndex = tabIndex;
    }

    /**
     * This function is used to generate the email
     * @param val -val data
     */
    GenarateEmail(val: any = ''):void {
        this.behaviorService.EmailGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.EmailtemplateData = result;
            }
        });


        let passdata
        let invoiceGUid = localStorage.getItem('edit_invoice_id');
        let collectSelectedInvoice = JSON.parse(localStorage.getItem('collectSelectedInvoice'));

        let Invoice_id = localStorage.getItem('Create_INVOICEGUID_WorkInProgress')
        if (collectSelectedInvoice && collectSelectedInvoice.length > 1 && this._data.flag == 'MultipleDocument') {
            passdata = { 'Context': "Invoice", 'ContextGuid': Invoice_id, "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME, isMultipleDocument: true }
        } else {

            passdata = { 'Context': "Invoice", 'ContextGuid': ((Invoice_id) ? Invoice_id : invoiceGUid), "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME }
        }


        //this.ForDocDialogOpen(passdata);
        this.ForEmailDialogOpen(passdata);

    }

    /**
     * this functgion is used to open the email dialog data value
     * @param passdata -passdata value 
     */
    ForEmailDialogOpen(passdata):void {

        const dialogRef = this._matDialog.open(MatterDialogComponentForTemplate, {
            width: '100%',
            disableClose: true,
            data: passdata
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    /**
     * This function is used to search the data with the filter value 
     * @param searchFilter -search filter data value 
     */
    FilterSearch(searchFilter: any):void {
        this.behaviorService.generateInvoiceSearch(searchFilter);


        searchFilter = searchFilter.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.GenerateInvoiceData.filter = searchFilter
    }

}
