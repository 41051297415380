import { fuseAnimations } from "src/@fuse/animations";
import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    OnDestroy,
    ElementRef,
    Renderer2,
} from "@angular/core";
import * as $ from "jquery";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { MattersListComponent } from "./matters-list/matters-list.component";
import { Subscription } from "rxjs";
import { FuseConfigService } from "src/@fuse/services/config.service";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { FilterSearchOptions } from "src/app/_constant/dynamic-search-option.const";
import { MatSelect } from "@angular/material/select";
import { TimersService } from "@_services/timers.service";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { HyperlinkNavigationService } from "@_services/hyperlink-navigation.service";
@Component({
    selector: "app-matters",
    templateUrl: "./matters.component.html",
    styleUrls: ["./matters.component.scss"],
    animations: fuseAnimations,
})
export class MattersComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    @ViewChild(MattersListComponent, { static: false })
    child: MattersListComponent;
    @ViewChild("menu", { static: false }) menu: MatMenuTrigger;
    @ViewChild("Datefilter", { static: false }) Datefilter: any;
    @ViewChild("searchField", { static: false })
    searchField: ElementRef<HTMLInputElement>;

    matterFilterForm: FormGroup;
    mattersDetail: any;
    detailHeight: any;
    isShowDrop: boolean = false;
    MatterDropData: any;
    lastFilter: any;
    hasSearchVal: boolean = false;
    Classdata: any = [];

    activeFilters: any;
    clearFilters = false;
    optionalFilterList = [];
    currentUser;

    optionalFilters = [];
    optionalFilterAppply = true;

    optionalDateFilterPayload = [];
    // Optional Filters
    activeFilter = FilterSearchOptions.activeFilter;
    unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter;
    activeOptionalFilter: any;
    dateFilter = FilterSearchOptions.dateFilter;
    currencyFilter = FilterSearchOptions.currencyFilter;
    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;

    FILTERS: FormArray;
    FILTERSLIST: FormArray;
    _filterList = [];

    addFilter;
    selectedFilterIs: any;
    showFiltersList: boolean = true;
    currentUserData: any;
    advancedFiltersDynamic: any = [];
    disabledMainMenuTrigger = true;
    saveAdvanceFilter: any = [];

    totalApplicableFilterCount: number = 0;
    private _unsubscribeAll$: Subject<void> = new Subject();

    detectedChangeRef = Math.random();
    COLOR: any;
    current_matter = localStorage.getItem("current_matter_class");
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    sub6: Subscription;
    sub7: Subscription;

    constructor(
        private Timersservice: TimersService,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private SortingbehaviorService: SortingBehaviourService,
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private renderer: Renderer2,
        private toasterService: ToastrService,
        private TableColumnsService: TableColumnsService,
        private hyperlink: HyperlinkNavigationService
    ) {
        this.hyperlink.setColorSetting();
        this.currentUserData = JSON.parse(
            window.localStorage.getItem("currentUser")
        );
        const path = this.router.url;
        if (path == "" || path == "/") {
            window.location.href = window.location.href + "matters";
            //  this.router.navigateByUrl('matters');
            window.name = "matters";
        }

        this.addFilter = {
            OPERATION: "",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: "",
        };

        localStorage.setItem("istrackid", "MattersSecreenComponent");
        let theme_type = localStorage.getItem("theme_type");
        // if (theme_type != "theme-default") {
        //   $('body').addClass('theme-yellow-light').removeClass("theme-default");
        // } else {
        //   $('body').addClass('theme-default').removeClass("theme-yellow-light");
        // }
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.isShowDrop = currentUser.PRODUCTTYPE == "Barrister" ? false : true;
        this.getDropValue();
        // this.lastFilter = JSON.parse(localStorage.getItem('matter_filter'));
        this.sub1 = this.SortingbehaviorService.MatterFilterData$.subscribe((result) => {
            if (result) {
                this.lastFilter = result;

            }
        });

        this.matterFilterForm = this.fb.group({
            MatterFilter: ["active"],
            UserFilter: [""],
            searchFilter: [""],
            InvoiceFilter: [""],
            MatterClassType: [""],
            FILTERS: new FormArray([]),
            FILTERSLIST: new FormArray([]),
            MAINFILTER: [""],
        });

        if (this.lastFilter) {
            this.lastFilter.SearchString = "";
            this.lastFilter.FeeEarner = currentUser.LOADALLUSERSMATTERS
                ? "all"
                : currentUser.WORKINGONBEHALFOFGUID
                    ? currentUser.WORKINGONBEHALFOFGUID
                    : currentUser.UserId;
            this.currentUser = this.lastFilter.FeeEarner;
            delete this.lastFilter.FILTERS;
            delete this.lastFilter.AdvancedFilters;
            localStorage.setItem(
                "matter_filter",
                JSON.stringify(this.lastFilter)
            );
            this.matterFilterForm.controls["MatterFilter"].setValue(
                this.lastFilter.active
                    ? this.lastFilter.active
                    : this.lastFilter.Active
            );
            this.matterFilterForm.controls["UserFilter"].setValue(
                this.lastFilter.FeeEarner
            );
            //this.matterFilterForm.controls['searchFilter'].setValue(this.lastFilter.SearchString);
            this.matterFilterForm.controls["searchFilter"].setValue(
                this.lastFilter.SearchString
            );
            this.matterFilterForm.controls["InvoiceFilter"].setValue(
                this.lastFilter.UninvoicedWork
                    ? this.lastFilter.UninvoicedWork
                    : this.lastFilter.unbilledWork
            );
            this.matterFilterForm.controls["MatterClassType"].setValue(
                this.lastFilter.MatterClass
                    ? this.lastFilter.MatterClass
                    : this.lastFilter.matterClass
            );

            this.hasSearchVal = this.lastFilter.SearchString ? true : false;
            this.getLookups();
        } else {
            // This is for Matter class
            this.getLookups();
        }

        this.sub2 = this.behaviorService.APIretryCall$.subscribe(async (result) => {
            if (
                localStorage.getItem("istrackid") ==
                "MattersSecreenComponent" &&
                result["click"] &&
                result["data"]["matter"]
            ) {
                if (!localStorage.getItem("matters_list_columns")) {
                    this.child.getTableFilter();
                    setTimeout(() => {
                        this.getFilter();
                    }, 1000);
                }
                this.child.getMatterList(
                    JSON.parse(localStorage.getItem("matter_filter"))
                );
            }
        });
        this.sub3 = this.behaviorService.defultFeeErnerData$
            .subscribe((result) => {
                if (result) {
                    let filterVal: any = JSON.parse(
                        localStorage.getItem("matter_filter")
                    );
                    delete filterVal.FILTER;
                    filterVal.FeeEarner = result;
                    this.matterFilterForm.controls["UserFilter"].setValue(
                        result
                    );
                    localStorage.setItem(
                        "matter_filter",
                        JSON.stringify(filterVal)
                    );
                }
            });
    }

    get Opfilters() {
        return this.matterFilterForm.get("FILTERS") as FormArray;
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.sub6?.unsubscribe();
        this.sub7?.unsubscribe();
    }

    /**
     * This function is used to changes the matter
     */
    MatterChange(value) {
        let filterVal: any = {
            Active: value,
            FeeEarner: "",
            UninvoicedWork: "",
            MatterClass: "",
        };
        if (!localStorage.getItem("matter_filter")) {
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        } else {
            filterVal = JSON.parse(localStorage.getItem("matter_filter"));
            filterVal.Active = value;
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        }
        // this.behaviorService.MatterData(null);
        // this.child.getMatterList(filterVal);

        this.addOptionalFilter(filterVal);
    }

    /**
     * This funcrtion is used to Change the mattr user
     * @param value -Matter User value
     */
    MatterUserChange(value) {
        let filterVal: any = {
            Active: "",
            FeeEarner: value,
            UninvoicedWork: "",
            MatterClass: "",
        };
        if (!localStorage.getItem("matter_filter")) {
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        } else {
            filterVal = JSON.parse(localStorage.getItem("matter_filter"));
            filterVal.FeeEarner = value;
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        }
        // this.behaviorService.MatterData(null);
        // this.child.getMatterList(filterVal);

        this.addOptionalFilter(filterVal);
    }

    /**
     * This function is used to change the Matter Invoice 
     * @param value -Matter Invoice value
     */
    MatterInvoiceChange(value) {
        let filterVal: any = {
            Active: "",
            FeeEarner: "",
            UninvoicedWork: value,
            MatterClass: "",
        };
        if (!localStorage.getItem("matter_filter")) {
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        } else {
            filterVal = JSON.parse(localStorage.getItem("matter_filter"));
            filterVal.UninvoicedWork = value;
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        }
        // this.behaviorService.MatterData(null);
        // this.child.getMatterList(filterVal);
        this.addOptionalFilter(filterVal);
    }
    get f() {
        return this.matterFilterForm.controls;
    }

    /**
     * This function is used to search the matter list
     * @param searchFilter -search filter value
     */
    onSearch(searchFilter: any) {
        if (
            searchFilter["key"] === "Enter" ||
            searchFilter == "Enter" ||
            searchFilter == "removeSearchString"
        ) {
            // document.getElementById("menuFilterMenuId").click();
            //able to remove search filter
            if (searchFilter == "removeSearchString") {
                this.matterFilterForm.controls["searchFilter"].setValue("");
            }
            let filterVal = {
                Active: "",
                SearchString: this.f.searchFilter.value,
                FeeEarner: "",
                UninvoicedWork: "",
                MatterClass: "",
            };
            if (!localStorage.getItem("matter_filter")) {
                localStorage.setItem(
                    "matter_filter",
                    JSON.stringify(filterVal)
                );
            } else {
                filterVal = JSON.parse(localStorage.getItem("matter_filter"));
                filterVal.SearchString = this.f.searchFilter.value;
                localStorage.setItem(
                    "matter_filter",
                    JSON.stringify(filterVal)
                );
            }
            this.hasSearchVal = filterVal.SearchString ? true : false;
            this.behaviorService.MatterData(null);
            this.child.getMatterList(filterVal);
        }
    }

    /**
     * This function is used to Get the matter class type
     * @param val -val data
     */
    MatterClassType(val) {
        let filterVal: any = {
            Active: "",
            FeeEarner: "",
            UninvoicedWork: "",
            MatterClass: val,
            IncludeAll: val == "all" ? true : false,
        };
        // let filterVal: any = { 'Active': '', 'FeeEarner': '', 'UninvoicedWork': '', 'MatterClass': (val == 'all')? null : val, IncludeAll:(val == 'all')? true : false };

        if (!localStorage.getItem("matter_filter")) {
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        } else {
            filterVal = JSON.parse(localStorage.getItem("matter_filter"));
            filterVal.MatterClass = val;
            // filterVal.MatterClass = (val == 'all')? null : val;
            filterVal.IncludeAll = val == "all" ? true : false;
            localStorage.setItem("current_matter_class", val);
            localStorage.setItem("matter_filter", JSON.stringify(filterVal));
        }
        // this.behaviorService.MatterData(null);
        // this.child.getMatterList(filterVal);
        this.addOptionalFilter(filterVal);
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.resizeTableForAllView();
        $(window).resize(() => {
            this.behaviorService.resizeTableForAllView();
        });
        let MatterClassLookups = JSON.parse(
            localStorage.getItem("matter_class_lookups")
        );
        if (MatterClassLookups && MatterClassLookups != null) {
            this.Classdata = MatterClassLookups;
        } else {
            // this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'Matter Class' }}, 'lookup').subscribe(responses => {
            //   if (responses.CODE === 200 && responses.STATUS === 'success') {
            //     this.Classdata = responses.DATA.LOOKUPS;
            //     localStorage.setItem('matter_class_lookups', JSON.stringify(responses.DATA.LOOKUPS));
            //   }
            // });
        }
        this.getFilter();
        // Add Dynamic Filter
        // this.matterFilterForm = new FormGroup({
        //   FILTERS: new FormArray([])
        // });
        this.setDefaultAdvanceFilter();
        this.cd.detectChanges();
        this.sub4 = this.behaviorService.ColorSystemSetting$.subscribe((data) => {
            this.COLOR = data;
        });
    }

    /**
     * This function is used to get the lookups data value.
     */
    getLookups() {
      this._mainAPiServiceService
            .getSetData(
                { Action: "getdata", Filters: { LookupType: "Matter Class" } },
                "lookup"
            )
            .subscribe((responses) => {
                if (responses.CODE === 200 && responses.STATUS === "success") {
                    this.Classdata = responses.DATA.LOOKUPS;
                    let Classdatavalue = responses.DATA.LOOKUPS;
                    localStorage.setItem(
                        "matter_class_lookups",
                        JSON.stringify(responses.DATA.LOOKUPS)
                    );
                    let currentUser = JSON.parse(
                        localStorage.getItem("currentUser")
                    );
                    let updatedMatterclass = Classdatavalue.filter(
                        (data) =>
                            data.LOOKUPGUID == currentUser.DEFAULTMATTERCLASS
                    );

                    this.matterFilterForm.controls["MatterClassType"].setValue(
                        "all"
                    );
                    let FeeEarnerData = currentUser.LOADALLUSERSMATTERS
                        ? "all"
                        : currentUser.WORKINGONBEHALFOFGUID
                            ? currentUser.WORKINGONBEHALFOFGUID
                            : currentUser.UserId;
                    this.currentUser = FeeEarnerData;
                    // this.getFilter();
                    // this.activeFilters.matterClassDesc = (this.f.MatterClassType.value && this.f.MatterClassType.value !='' && this.f.MatterClassType.value !=undefined && this.f.MatterClassType.value == 'all') ? 'All' : this.f.MatterClassType.value;
                    this.activeFilters.matterClassDesc =
                        this.f.MatterClassType.value &&
                            this.f.MatterClassType.value == "all"
                            ? "All"
                            : this.f.MatterClassType.value;
                    // let temData = { 'Active': 'active', 'SearchString': '', 'FeeEarner': FeeEarnerData, 'UninvoicedWork': 'All', 'MatterClass': updatedMatterclass[0].LOOKUPFULLVALUE ? updatedMatterclass[0].LOOKUPFULLVALUE : 'all' };
                    let temData = {
                        Active: "active",
                        SearchString: "",
                        FeeEarner: FeeEarnerData,
                        UninvoicedWork: "All",
                        MatterClass: "all",
                    };
                    localStorage.setItem(
                        "matter_filter",
                        JSON.stringify(temData)
                    );
                    this.child.getMatterList(
                        JSON.parse(localStorage.getItem("matter_filter"))
                    );
                    this.matterFilterForm.controls["MatterFilter"].setValue(
                        "active"
                    );
                    this.matterFilterForm.controls["InvoiceFilter"].setValue(
                        "All"
                    );

                    setTimeout(() => {
                        this.matterFilterForm.controls["UserFilter"].setValue(FeeEarnerData)
                        if(FeeEarnerData){
                            this.activeFilters.FeeEarnerDesc= (FeeEarnerData && FeeEarnerData !=null && FeeEarnerData !=undefined) ? FeeEarnerData : "all"
                        }
                    },2000);
                    this.hasSearchVal = false;
                }
            });
    }

    /**
     * This functiuon is used to create the Op filter
     * @param type -filter type
     * @returns 
     */
    createOPFilter(type): FormGroup {
        return this.fb.group({
            OPERATION:
                type == "DATE"
                    ? "is in the last"
                    : type == "CURRENCY" || type == "NUMBER"
                        ? "is equal to"
                        : "contains",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: type,
        });
    }

    /**
     * This function is used to set the default filter data value
     */
    defaultSetFilter() {
        const editFilter = JSON.parse(
            localStorage.getItem("matterFilter_config")
        );
        if (editFilter) {
            this.activeFilters = JSON.parse(
                localStorage.getItem("matterFilter_config")
            );
            this.activeFilters.FeeEarner = this.f.UserFilter.value;
            this.activeFilters.unbilledWork = this.f.InvoiceFilter.value;
            this.activeFilters.UninvoicedWork = this.f.InvoiceFilter.value;
            this.activeFilters.active = this.f.MatterFilter.value;
            this.activeFilters.Active = this.f.MatterFilter.value;
            this.activeFilters.matterClass = this.f.MatterClassType.value;
            this.activeFilters.matterClassDesc =
                this.f.MatterClassType.value == "all"
                    ? "All"
                    : this.f.MatterClassType.value;
            this.activeFilters.SearchString = this.f.searchFilter.value;
        } else {
            this.activeFilters = {};
            this.activeFilters.FeeEarner = this.f.UserFilter.value;
            this.activeFilters.active = this.f.MatterFilter.value;
            this.activeFilters.Active = this.f.MatterFilter.value;

            this.activeFilters.activeDesc = this.activeFilter.filter(
                (currentActive) =>
                    currentActive.value == this.f.MatterFilter.value
            )[0].description;
            this.activeFilters.unbilledWork = this.f.InvoiceFilter.value;
            this.activeFilters.UninvoicedWork = this.f.InvoiceFilter.value;
            let UninvoicedWork =!this.lastFilter ||!this.lastFilter.UninvoicedWork ||this.lastFilter.UninvoicedWork == ""? "All": this.lastFilter.UninvoicedWork;
            this.activeFilters.unbilledWorkDesc = this.unbuildWorkFilter.filter((activeF) => activeF.value == UninvoicedWork)[0].description;
            this.activeFilters.matterClass = this.f.MatterClassType.value;
            this.activeFilters.matterClassDesc =
                this.f.MatterClassType.value == "all"
                    ? "All"
                    : this.f.MatterClassType.value;
           (this.f.UserFilter.value && this.f.UserFilter.value !=undefined && this.f.UserFilter.value !=null && this.f.UserFilter.value !='') ? this.f.UserFilter.value : this.matterFilterForm.controls["UserFilter"].setValue("all");
            this.activeFilters.FeeEarnerDesc = (this.f.UserFilter.value && this.f.UserFilter.value !=undefined && this.f.UserFilter.value !=null && this.f.UserFilter.value !='' && this.f.UserFilter.value != 'all') ? this.f.UserFilter.value: "All";
            this.activeFilters.SearchString = this.f.searchFilter.value;
        }

        // this.Filterconfig({ value: this.activeFilters.FeeEarner }, 'Fee Earner')
        this.Filterconfig({ value: this.currentUser }, "Fee Earner");
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        // this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
        //   setTimeout(() => {
        //     this.mattersDetail = (!config.layout.sidepanel.hidden);
        //   }, 50);
        // });
        // this._fuseConfigService.config = {
        //     layout: { sidepanel: { hidden: this.currentUser.SIDEPANELHIDE} }
        //   };

        this.mattersDetail = !this.currentUserData.SIDEPANELHIDE;
       this.sub6 = this.behaviorService.preserveMatterSidePenalAction$
            .subscribe((result) => {
                if (result != null) {
                    this.mattersDetail = result;
                }
            });

        $("content").addClass("inner-scroll");
        $(".example-containerdata").css(
            "height",
            $(window).height() -
            ($("#filter-form-menu-wrap").height() + 265) +
            "px"
        );
        $(window).resize(function () {
            $(".example-containerdata").css(
                "height",
                $(window).height() -
                ($("#filter-form-menu-wrap").height() + 265) +
                "px"
            );
        });
    }

    /**
     * this function is used to get the filter data 
     */
    getFilter() {
        let opFilterList = JSON.parse(
            localStorage.getItem("matters_list_columns")
        );
        this.TableColumnsService.getDynamicFilter(
            opFilterList,
            this.optionalFilterList,
            "matters_list_columns"
        );
        this.refreshFilterList();
        this.defaultSetFilter();
        setTimeout(() => {
            this.detectedChangeRef = Math.random();
        }, 500);
    }

    /**
     * This function is used to refresh the filter list
     */
    refreshFilterList() {
        this.totalApplicableFilterCount = 0;
        //Advance Filter Refresh
        const advDynamicFilter = JSON.parse(
            localStorage.getItem("MatterWindow_Advance_Filter")
        );
        this.optionalFilterList.map((x) => {
            if (x.FILTERTYPE != "HIDDEN") {
                this.totalApplicableFilterCount += 1;
            }

            if (
                advDynamicFilter &&
                advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
                -1
            ) {
                x.FILTERAPPLY = true;
            }
        });
    }

    /**
     * This function is used to get the drop down value
     */
    getDropValue() {
        let userList = JSON.parse(localStorage.getItem("users_list"));
        if (userList && userList != null) {
            this.MatterDropData =
                this.Timersservice.removeUSerWithoutId(userList);
        } else {
            let d = {};
            this.sub7 = this.Timersservice.GetUsers(d)
                .subscribe(
                    (response) => {
                        if (
                            response.CODE == 200 &&
                            response.STATUS == "success"
                        ) {
                            this.MatterDropData =
                                this.Timersservice.removeUSerWithoutId(
                                    response.DATA.USERS
                                );
                            localStorage.setItem(
                                "users_list",
                                JSON.stringify(response.DATA.USERS)
                            );
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
        }
    }

    /**
     * This function is used to get the matter back
     * @param event 
     */
    matterBack(event: any) {
        this.mattersDetail = event;
        this.behaviorService.SetpreserveMatterSidePenalAction(
            this.mattersDetail
        );
        let windowHeight = $(window).height();
        let toolBaarMain = $("#tool_baar_main").height();
        let serchHeight = $(".sticky_search_div").height();
        this.detailHeight =
            windowHeight - (toolBaarMain + serchHeight + 52.5) + "px";
    }

    /**
     * This function is used to Close the Matter value
     * @param event -event data
     */
    matterClose(event: any) {
        this.mattersDetail = false;
    }

    /**
     * This function is used to prevent default data 
     * @param event 
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * This function is used to active the current filter data
     * @param id -id of the filter data
     * @param data -data of the filter 
     */
    activeCurrentFilter(id, data?: any) {
        $("#" + id).addClass("active");
        setTimeout(() => {
            $(".mat-menu-content").addClass("active");
            $(".mat-menu-content").addClass("mat-menu-content_height");
        }, 200);

        this.selectedFilterIs = "";
        this.matterFilterForm.controls["MAINFILTER"].reset();
        this.activeOptionalFilter = data;

        // this.addFilter.OPERATION = data["DATEFILTERLIST"][0]["value"]
    }

    /**
     * This function is used to back to filter
     * @param id -id of the filter
     */
    backToFilter(id) {
        $("#" + id).removeClass("active");
        setTimeout(() => {
            $(".mat-menu-content").removeClass("active");
            $(".mat-menu-content").removeClass("mat-menu-content_height");
        }, 50);

        if (this.FILTERS && this.FILTERSLIST) {
            this.FILTERSLIST.reset();
        }
    }

    /**
     * This unction is used to Config the filter
     * @param activefilter -active filter data
     * @param filterBy -filter by date config
     */
    Filterconfig(activefilter, filterBy) {
        if (filterBy == "Active") {
            this.activeFilters.active = activefilter.value;
            // this.activeFilters.activeDesc = activefilter.source.triggerValue;
            this.activeFilters.activeDesc = activefilter.description;
            this.activeFilters.Active = activefilter.value;
        }

        if (filterBy == "Unbilled Work") {
            this.activeFilters.unbilledWork = activefilter.value;
            // this.activeFilters.unbilledWorkDesc = activefilter.source.triggerValue
            this.activeFilters.unbilledWorkDesc = activefilter.description;
            this.activeFilters.UninvoicedWork = activefilter.value;
        }
        if (filterBy == "Matter Class") {
            this.activeFilters.matterClass = activefilter.value;
            this.activeFilters.MatterClass = activefilter.value;
            this.activeFilters.matterClassDesc =
                activefilter.value == "all" ? "All" : activefilter.value;
        }
        if (filterBy == "Fee Earner") {
            this.activeFilters.FeeEarner = activefilter.value;
            //this.activeFilters.FeeEarnerDesc = activefilter.value == "all" ? "All" : activefilter.value == "" ? this.currentUser : activefilter.value;
            this.activeFilters.FeeEarnerDesc =
                activefilter.value == "all"
                    ? "All"
                    : activefilter.value == ""
                        ? this.currentUser
                        : activefilter.value;
        }
        if (filterBy == "SearchString") {
            //this.activeFilters.SearchString = this.f.searchFilter.value;
        }
        if (filterBy != "SearchString") {
            localStorage.setItem(
                "matterFilter_config",
                JSON.stringify(this.activeFilters)
            );
        }
    }

    /**
     * This function is used to onfilter search
     * @param searchFilter 
     */
    onFilterSearch(searchFilter?: any) {
        if (
            this.matterFilterForm.value["searchFilter"] ||
            this.matterFilterForm.value["searchFilter"] == ""
        ) {
            //able to remove search filter
            if (searchFilter == "removeSearchString") {
                this.matterFilterForm.controls["searchFilter"].setValue("");
            }
            let filterVal = {
                Active: "",
                SearchString: this.f.searchFilter.value,
                FeeEarner: "",
                UninvoicedWork: "",
                MatterClass: "",
            };
            if (!localStorage.getItem("matter_filter")) {
                localStorage.setItem(
                    "matter_filter",
                    JSON.stringify(filterVal)
                );
            } else {
                filterVal = JSON.parse(localStorage.getItem("matter_filter"));
                filterVal.SearchString = this.f.searchFilter.value;
                localStorage.setItem(
                    "matter_filter",
                    JSON.stringify(filterVal)
                );
            }
            this.hasSearchVal = filterVal.SearchString ? true : false;

            // this.behaviorService.MatterData(null);
            // this.child.getMatterList(filterVal);
            this.addOptionalFilter(null); // add the optional filters.
        }
    }

    /**
     * This function is used to add the optional filter. 
     * @param prevFilter -prev filter
     */
    addOptionalFilter(prevFilter?: any) {
        //Delete Filters
        const Filter = prevFilter
            ? JSON.parse(JSON.stringify(prevFilter))
            : JSON.parse(localStorage.getItem("matter_filter"));
        delete Filter.activeDesc;
        delete Filter.unbilledWorkDesc;
        delete Filter.FILTERINDEX;
        delete Filter.FILTERAPPLAY;

        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.matterFilterForm.value.FILTERS)
        );

        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });

        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;

                if (e.FILTERTYPE == "DATE") {
                    let date1 = e.VALUE;
                    let date2 = e.VALUE2;

                    if (date1) {
                        let date1Split = date1.split("T");
                        if (date1Split.length > 1) {
                            date1 =
                                e && e.VALUE !== ""
                                    ? moment(e.VALUE).format("DD/MM/YYYY")
                                    : "";
                        }
                    }

                    if (date2) {
                        let date2Split = date2.split("T");
                        if (date2Split.length > 1) {
                            date2 =
                                e &&
                                    e.VALUE2 !== "" &&
                                    e.OPERATION == "is between"
                                    ? moment(e.VALUE2).format("DD/MM/YYYY")
                                    : "";
                        }
                    }

                    if (e.OPERATION == "is in the last") {
                        e.VALUE = e.VALUE;
                        e.VALUE2 = e.VALUE2;
                    } else {
                        e.VALUE = date1;
                        e.VALUE2 = date2;
                    }
                }

                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }

                return e;
            }
        });

        // Filter.FILTERS = finalPayload;
        Filter.AdvancedFilters = finalPayload;
        if (
            (this.FILTERS && this.FILTERS.controls.length == 0) ||
            finalPayload.length == 0
        ) {
            // delete Filter.FILTERS;
            delete Filter.AdvancedFilters;
        }

        this.advancedFiltersDynamic = Filter.AdvancedFilters;
        this.cd.detectChanges();
        this.reload(Filter);
    }

    /**
     * This function is used to clear the filter data.
     * @param name -Name o the filter
     * @param filterBy -filter by data
     */
    clearFilter(name, filterBy) {
        delete this.activeFilters[name];
        let resetFilter = JSON.parse(localStorage.getItem("matter_filter"));

        this.clearFilters = true;
        if (filterBy == "Active") {
            this.matterFilterForm.controls["MatterFilter"].setValue("active");
            resetFilter.Active = "active";
            this.activeFilters.activeDesc = "Active Matters";
        }

        if (filterBy == "Unbilled Work") {
            this.matterFilterForm.controls["InvoiceFilter"].setValue("All");
            resetFilter.UninvoicedWork = "All";
            this.activeFilters.unbilledWorkDesc = "Show All Work";
        }

        if (filterBy == "Matter Class") {
            this.matterFilterForm.controls["MatterClassType"].setValue("all");
            resetFilter.MatterClass = "all";
            this.activeFilters.matterClassDesc = "All";
            localStorage.setItem("current_matter_class", "all");
        }

        if (filterBy == "Fee Earner") {
            // this.matterFilterForm.controls['UserFilter'].setValue(this.currentUser);
            // resetFilter.FeeEarner = this.currentUser;
            // this.activeFilters.FeeEarner = this.currentUser;

            this.matterFilterForm.controls["UserFilter"].setValue("all");
            resetFilter.FeeEarner = "all";
            this.activeFilters.FeeEarner = "all";
            this.activeFilters.FeeEarnerDesc = "All";
        }

        if (filterBy == "SearchString") {
            this.matterFilterForm.controls["searchFilter"].setValue("");
            resetFilter.SearchString = "";
            this.activeFilters.SearchString = "";
        }

        delete resetFilter.activeDesc;
        delete resetFilter.unbilledWorkDesc;
        delete resetFilter.FILTERINDEX;
        delete resetFilter.FILTERAPPLAY;
        delete resetFilter.AdvancedFilters;

        // this.reload(resetFilter);
        this.addOptionalFilter(resetFilter);
        localStorage.setItem("matter_filter", JSON.stringify(resetFilter));
        localStorage.setItem(
            "matterFilter_config",
            JSON.stringify(this.activeFilters)
        );
    }

    /**
     * This unction is used to reset the filter
     */
    resetFilter() {
        // reset FILTERAPPLY value
        this.optionalFilterList.forEach((filter, index) => {
            filter.FILTERAPPLY = false;
        });
        // ends here ~ reset FILTERAPPLY value

        setTimeout(() => {
            this.detectedChangeRef = Math.random();
        }, 500);

        let resetFilter = JSON.parse(localStorage.getItem("matter_filter"));
        this.matterFilterForm.controls["MatterFilter"].setValue("active");
        this.matterFilterForm.controls["InvoiceFilter"].setValue("All");
        this.matterFilterForm.controls["MatterClassType"].setValue("all");
        this.matterFilterForm.controls["searchFilter"].setValue("");
        localStorage.setItem("current_matter_class", "all");
        this.optionalFilters = [];
        this.optionalDateFilterPayload = [];
        this.advancedFiltersDynamic = [];

        if (this.FILTERS) {
            this.FILTERS.reset();
            if (this.FILTERSLIST) {
                this.FILTERSLIST.reset();
            }
        }

        this.saveAdvanceFilter = [];
        localStorage.removeItem("MatterWindow_Advance_Filter");

        setTimeout(() => {
            if (JSON.parse(localStorage.getItem("matterFilter_config"))) {
                this.activeFilters = JSON.parse(
                    localStorage.getItem("matterFilter_config")
                );

                this.activeFilters.FeeEarner = this.f.UserFilter.value;
                this.activeFilters.unbilledWork = this.f.InvoiceFilter.value;
                this.activeFilters.unbilledWorkDesc =
                    this.unbuildWorkFilter.filter(
                        (activeF) => activeF.value == this.f.InvoiceFilter.value
                    )[0].description;
                this.activeFilters.active = this.f.MatterFilter.value;
                this.activeFilters.activeDesc = this.activeFilter.filter(
                    (currentActive) =>
                        currentActive.value == this.f.MatterFilter.value
                )[0].description;
                this.activeFilters.matterClass =
                    this.f.MatterClassType.value == "all"
                        ? "All"
                        : this.f.MatterClassType.value;
                this.activeFilters.SearchString = "";

                resetFilter.Active = this.activeFilter.filter(
                    (currentActive) => currentActive.value == "active"
                )[0].value;
                resetFilter.FeeEarner = this.currentUser;
                resetFilter.MatterClass = "all";
                localStorage.setItem("current_matter_class", "all");
                resetFilter.SearchString = "";
                resetFilter.UninvoicedWork = "";

                this.matterFilterForm.controls["UserFilter"].setValue("all");
                this.activeFilters.FeeEarnerDesc = "All";

                delete resetFilter.FILTERS;
                delete resetFilter.AdvancedFilters;
                localStorage.setItem(
                    "matter_filter",
                    JSON.stringify(resetFilter)
                );
            }
            this.behaviorService.MatterData(null);
            this.child.getMatterList(resetFilter);
        }, 500);
    }

    /**
     * This function is used to reload the filter value
     * @param filterVal 
     */
    reload(filterVal) {
        filterVal.SearchString = this.f.searchFilter.value;
        this.behaviorService.MatterData(null);
        this.child.getMatterList(filterVal);
    }

    /**
     * This function is used to  config the filterdata
     * @param event 
     */
    dateFilterconfig(event) {
        this.optionalFilterAppply = false;
    }

    /**
     * This function is used to Add the dynamic filter
     * @param event -event data
     * @param type -filter type
     */
    addDynamicFilter(event, type?) {
        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        event.FILTERAPPLY = true; //for filter hide/show from optional filter list.
        if (!type) {
            this.optionalFilters.push(event);
        }

        setTimeout(() => {
            this.detectedChangeRef = Math.random();
        }, 500);

        // OPTIONAL FILTER
        this.FILTERS = this.matterFilterForm.get("FILTERS") as FormArray;
        this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));
    }

    /**
     * This function is used to reset the optional filter data
     * @param event -event data
     * @param type -type o the filter
     * @param isindex -isindex data
     */
    resetOptionalFilter(event, type, isindex) {
        let index = this.optionalFilters.findIndex(
            (e) => e.COLUMNID == event.COLUMNID
        );

        event.FILTERAPPLY = false; //for filter hide/show from optional filter list.

        // fixed code to show filter in filter list
        setTimeout(() => {
            this.optionalFilterList.map((x) => {
                if (
                    this.optionalFilters.findIndex(
                        (e) => x.COLUMNID == event.COLUMNID
                    ) !== -1
                ) {
                    x.FILTERAPPLY = false;
                }
            });

            this.optionalFilterList = this.optionalFilterList;
        }, 100);
        // ends here ~ fixed code to show filter in filter list

        setTimeout(() => {
            this.detectedChangeRef = Math.random();
        }, 500);

        this.optionalFilters.splice(index, 1);
        this.optionalDateFilterPayload.splice(isindex, 1);
        this.FILTERS.controls.splice(isindex, 1);
        this.matterFilterForm.value.FILTERS.splice(isindex, 1);
        //Delete Filters
        const resetFilter = JSON.parse(localStorage.getItem("matter_filter"));

        delete resetFilter.activeDesc;
        delete resetFilter.unbilledWorkDesc;
        delete resetFilter.FILTERINDEX;
        delete resetFilter.FILTERAPPLAY;

        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.matterFilterForm.value.FILTERS)
        );

        //Advance Filter.
        this.saveAdvanceFilter.splice(isindex, 1);
        this.TableColumnsService.refrshAdvanceFilter(event, "MatterWindow");

        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });

        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                if (e.FILTERTYPE == "DATE") {
                    let date1 = e.VALUE;
                    let date2 = e.VALUE2;

                    if (date1) {
                        let date1Split = date1.split("T");
                        if (date1Split.length > 1) {
                            date1 =
                                e && e.VALUE !== ""
                                    ? moment(e.VALUE).format("DD/MM/YYYY")
                                    : "";
                        }
                    }

                    if (date2) {
                        let date2Split = date2.split("T");
                        if (date2Split.length > 1) {
                            date2 =
                                e &&
                                    e.VALUE2 !== "" &&
                                    e.OPERATION == "is between"
                                    ? moment(e.VALUE2).format("DD/MM/YYYY")
                                    : "";
                        }
                    }

                    if (e.OPERATION == "is in the last") {
                        e.VALUE = e.VALUE;
                        e.VALUE2 = e.VALUE2;
                    } else {
                        e.VALUE = date1;
                        e.VALUE2 = date2;
                    }
                }

                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }
                return e;
            }
        });

        // resetFilter.FILTERS = finalPayload;
        resetFilter.AdvancedFilters = finalPayload;
        this.advancedFiltersDynamic = finalPayload;
        this.cd.detectChanges();
        if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
            delete resetFilter.AdvancedFilters;
            this.advancedFiltersDynamic = [];
            this.cd.detectChanges();
        }

        this.reload(resetFilter);
    }

    /**
     * This function is used to Optional filter data
     * @param event -event data
     * @param filterIs -filter name
     * @param val -value data
     * @param index -index data
     */
    optionalDateFilter(event, filterIs, val, index):void {
        if (val && val == "active") {
            this.selectedFilterIs = event.value;
        } else {
            filterIs.selectedFilter = event.value;
            this.selectedFilterIs = event.value;
        }
        if (index != -1) {
            this.matterFilterForm.controls.FILTERS["controls"][index].controls[
                "VALUE"
            ].reset();
            this.matterFilterForm.controls.FILTERS["controls"][index].controls[
                "VALUE2"
            ].reset();
        }

        this.addFilter["VALUE"] = "";
        this.addFilter["VALUE2"] = "";
    }

    /**
     * This function is used to aply the optional ilter datas
     * @param FilterData -filter data value
     * @param isIndex -isIndex data
     * @param OpretionIs -operation is data
     * @param evt -evt data
     * @returns -optional filter data value
     */
    applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any) {
        // new code for validation
        let saveAdvancefilterData = JSON.parse(
            localStorage.getItem("MatterWindow_Advance_Filter")
        );
        const field1Val =
            this.matterFilterForm.controls.FILTERS["controls"][isIndex]
                .controls["VALUE"].value;
        const field2Val =
            this.matterFilterForm.controls.FILTERS["controls"][isIndex]
                .controls["VALUE2"].value;
        const operationVal =
            this.matterFilterForm.controls.FILTERS["controls"][isIndex]
                .controls["OPERATION"].value;
        const filterTypeVal =
            this.matterFilterForm.controls.FILTERS["controls"][isIndex]
                .controls["FILTERTYPE"].value;

        if (
            !this.TableColumnsService.checkValidation(
                field1Val,
                field2Val,
                operationVal,
                filterTypeVal
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }

        // ends here ~ new code for validation

        //addData.selectedFilter = OpretionIs.triggerValue;
        let addData = JSON.parse(JSON.stringify(FilterData));
        addData.selectedFilter = OpretionIs.triggerValue;

        const applyFilterData = this.TableColumnsService.Optionalfilter(
            addData,
            isIndex,
            this.optionalDateFilterPayload,
            this.matterFilterForm
        );
        this.optionalDateFilterPayload = applyFilterData;

        // Advance Dynamic filter when it's change any data.
        this.TableColumnsService.setAdvanceFilter(
            saveAdvancefilterData,
            this.saveAdvanceFilter,
            isIndex,
            FilterData,
            field1Val,
            field2Val,
            operationVal,
            filterTypeVal,
            applyFilterData,
            "MatterWindow"
        );
        this.doFilter(this.optionalDateFilterPayload);
    }

    /**
     * This function is used to do the filter data
     * @param mainFilter -main filter data
     */
    doFilter(mainFilter: any) {
        const payloadData = JSON.parse(JSON.stringify(mainFilter));
        const tempPayload = Object.assign(
            JSON.parse(JSON.stringify(mainFilter))
        );

        const finalPayload = payloadData
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    delete e["value"].COLUMNID;
                    return e["value"];
                }
            });

        const doFilter = JSON.parse(JSON.stringify(this.activeFilters));
        // doFilter.FILTERS = finalPayload;
        doFilter.AdvancedFilters = finalPayload;
        this.advancedFiltersDynamic = finalPayload;

        delete doFilter.activeDesc;
        delete doFilter.unbilledWorkDesc;
        delete doFilter.FILTERINDEX;
        delete doFilter.FILTERAPPLAY;
        this.reload(doFilter);

        // Store dynamic filters.
        let objForSave = Object.assign(doFilter);
        objForSave.FILTERS = tempPayload
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    // need ID so not deleted.
                    return e["value"];
                }
            });
    }

    // New Filter changes code.
    @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;

    /**
     * This function is used to Add the new filter data.
     * @param event -event data.
     */
    addNewFilter(event) {
        setTimeout(() => {
            this.operationFilter.options.first.select();
        }, 200);

        this.addFilter.VALUE = "";
        this.addFilter.VALUE2 = "";

        if (this.FILTERSLIST) {
            this.FILTERSLIST.controls.splice(0, 1);
        }

        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        this.addFilter.FILTERTYPE = event.FILTERTYPE;

        // OPTIONAL FILTER
        this.FILTERSLIST = this.matterFilterForm.get(
            "FILTERSLIST"
        ) as FormArray;
        this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));
        const localFormIndex = this.FILTERSLIST.controls.length - 1;

        this.addFilter.OPERATION =
            event.FILTERTYPE == "DATE"
                ? "is in the last"
                : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
                    ? "is equal to"
                    : "contains";

        this._filterList = [event];
        this.cd.detectChanges();
    }

    /**
     * This unction is used to add the filter
     * @param IsfilterData -this function is used to get the is filter data 
     * @param oprationalFilter -operational ilter data value
     * @param evt -evt data
     * @returns -data value
     */
    async addfilter(IsfilterData, oprationalFilter, evt) {
        if (
            !this.TableColumnsService.checkValidation(
                this.addFilter.VALUE,
                this.addFilter.VALUE2,
                this.addFilter.OPERATION,
                IsfilterData.FILTERTYPE
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
        await this.addDynamicFilter(IsfilterData);

        let sIndex = this.optionalFilters.length - 1;

        this.matterFilterForm.value.FILTERS[sIndex].VALUE =
            this.addFilter.VALUE;
        this.matterFilterForm.value.FILTERS[sIndex].VALUE2 =
            this.addFilter.VALUE2;
        this.matterFilterForm.value.FILTERS[sIndex].OPERATION =
            this.addFilter.OPERATION;
        this.matterFilterForm.value.FILTERS[sIndex].FILTERTYPE =
            IsfilterData.FILTERTYPE;

        // Advance Filter.
        IsfilterData.AdvancedFilter =
            this.matterFilterForm.value.FILTERS[sIndex];
        IsfilterData.selectedFilter = this.addFilter.OPERATION;
        let advFilters = JSON.parse(
            localStorage.getItem("MatterWindow_Advance_Filter")
        );
        if (advFilters) {
            this.saveAdvanceFilter = advFilters;
        }

        this.saveAdvanceFilter.push(IsfilterData);
        localStorage.setItem(
            "MatterWindow_Advance_Filter",
            JSON.stringify(this.saveAdvanceFilter)
        );

        if (this.FILTERS.length == 1) {
            this.matterFilterForm.patchValue({
                FILTERS: [
                    {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    },
                ],
            });
        } else {
            this.FILTERS.value.forEach((e, index) => {
                if (sIndex == index) {
                    // filterData = {
                    //   OPERATION: this.addFilter.OPERATION,
                    //   VALUE: this.addFilter.VALUE,
                    //   VALUE2: this.addFilter.VALUE2,
                    //   FILTERTYPE: IsfilterData.FILTERTYPE
                    // }

                    e.OPERATION = this.addFilter.OPERATION;
                    e.VALUE = this.addFilter.VALUE;
                    e.VALUE2 = this.addFilter.VALUE2;
                    e.FILTERTYPE = IsfilterData.FILTERTYPE;
                }
            });

            this.matterFilterForm.patchValue({
                FILTERS: this.FILTERS.value,
            });
        }

        //After getting data appy filter.
        this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

        $(".example-containerdata").css(
            "height",
            $(window).height() -
            ($("#filter-form-menu-wrap").height() + 265) +
            "px"
        );
    }

    /**
     * This function is used to set the filter UI
     * @param filter -filter data
     * @param index -index data value
     */
    setFilterUi(filter, index) {
        this.selectedFilterIs =
            this.optionalDateFilterPayload[index]["value"]["OPERATION"];
    }

    /**
     * This function is used to set the focus data
     * @param fieldRefVar -field data value
     */
    setFocus(fieldRefVar) {
        this.searchField.nativeElement.focus();
    }

    /**
     * This function is used to set the default Radio button 
     * @param event -event data value
     */
    preventDefaultRadio(event) {
        event.stopPropagation();
    }

    /**
     * This function is used to set the prevent default tab set
     * @param event -event data value
     */
    preventDefaultTab(event) {
        event.stopPropagation();
    }

    /**
     * This function is used to reload the data value
     * @param isAllData -isAll data value
     */
    reloadData(isAllData) {
        this.lastFilter = JSON.parse(localStorage.getItem("matter_filter"));
        //this.lastFilter = JSON.parse(localStorage.getItem('matterFilter_config'));
        let isGetallData = isAllData;
        this.lastFilter.GETALLDATA = isGetallData;

        let appyFilterPayload = JSON.parse(JSON.stringify(this.lastFilter));
        let daynamicFilters = [];
        this.optionalDateFilterPayload.forEach((e) => {
            daynamicFilters.push(e.value);
        });

        if (daynamicFilters.length !== 0) {
            appyFilterPayload.AdvancedFilters = daynamicFilters;
        }

        this.reload(appyFilterPayload);
        // this.reload(this.lastFilter)
    }

    /**
     * This function is used to onsearch change data
     * @param searchFilter -search filter data
     */
    onSearchChange(searchFilter: any) {
        if (
            searchFilter["key"] === "Enter" ||
            searchFilter == "Enter" ||
            searchFilter == "removeSearchString"
        ) {
            this.onFilterSearch("Enter");
        }
        this.setFocus("");
    }

    /**
     * This function is used to set the default Advance filter
     */
    async setDefaultAdvanceFilter() {
        // Advance Filter If Already have.
        let advanceFiltes = JSON.parse(
            localStorage.getItem("MatterWindow_Advance_Filter")
        );
        if (advanceFiltes) {
            this.optionalDateFilterPayload = [];
            this.optionalFilters = advanceFiltes;
            await advanceFiltes.forEach(async (filterData, index) => {
                await this.addDynamicFilter(
                    filterData.AdvancedFilter,
                    "default"
                );

                this.FILTERS.value[index].OPERATION = await filterData
                    .AdvancedFilter.OPERATION;
                this.FILTERS.value[index].FILTERTYPE = await filterData
                    .AdvancedFilter.FILTERTYPE;
                this.FILTERS.value[index].VALUE = await filterData
                    .AdvancedFilter.VALUE;
                this.FILTERS.value[index].VALUE2 = await filterData
                    .AdvancedFilter.VALUE2;
                await this.optionalDateFilterPayload.push(
                    filterData.FilterHeadings
                );
                await this.matterFilterForm.patchValue({
                    FILTERS: this.FILTERS.value,
                });
            });
        }
    }
}
