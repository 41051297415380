<div class="container">

    <!-- <div class="timeline-input-wrapper">
        <h4 class="f-30">All Activities</h4>
        <p>Application wide activities are listed here as individual items, starting with the most recent.</p>
    </div> -->

    <div class="main-block position-relative">
        <ng-container
            *ngIf="((router.url == '/dashboard/individual-dashboard' || router.url == '/matter-details/matter-detail-dashboard') && dashboardModeIs) && (  setFavriotIconStatus('timeline') || (!dashboardModeIs && setFavriotIconStatus('timeline') && setFavriotIconStatus('timeline')[0]['STAR'] == 'star'))">
            <div
                class="display-flex justify-content-between align-center w-50 margin-left-auto padding-left-10 padding-right-0">
                <p class="p-text">Show Timeline</p>
                <mat-icon class="star-block nav-link-title"
                    (click)="onEdit('timeline')">{{(setFavriotIconStatus('timeline')[0].STAR
                    == 'star')?'star':'star_border'}}</mat-icon>
            </div>
            <div class="timeline-continer display-flex flex-wrap align-center">

                <div *ngFor="let timelineMenu of timelineMenuList"
                    class="display-flex justify-content-between align-center w-50">
                    <p *ngIf="timelineMenu" class="p-text"
                        [ngStyle]="{'margin': !dashboardModeIs ? '3px 0px' : '' }">
                        {{timelineMenu?.TITLE}}</p>
                    <span
                        class="display-flex justify-content-between align-center">
                        <mat-icon *ngIf="dashboardModeIs"
                            class="star-block nav-link-title "
                            (click)="onEdit(timelineMenu.ID)">{{(setFavriotIconStatus(timelineMenu.ID)[0].STAR
                            == 'star')?'star':'star_border'}}</mat-icon>
                    </span>
                </div>
            </div>
        </ng-container>

        <ng-container
            *ngFor="let timelineData of timelineTrackdata;let i = index">
            <ng-container *ngIf="timelineData.value.length > 0">
                <div class="timel-today-btn f-14">
                    {{timelineData.key}}
                </div>
                <!-- Note if you modify html so please also you need to modify directive [appTimelineIcon] -->
                <mat-vertical-stepper class="timeline-mainwrap"
                    *ngIf="timelineData.value.length > 0" [appTimelineIcon]="i">
                    <!-- <ng-template matStepperIcon="active">
                            <mat-icon>done</mat-icon>
                        </ng-template> -->
                    <mat-step label="{{task.ORDERNUMBER}}" state="phone"
                        *ngFor="let task of timelineData.value"
                        class="icon-mat_{{i}}">
                        <form class="timeline-forms">
                            <ng-template matStepLabel>
                                <mat-icon class="main-icon  timeline-icon"
                                    *ngIf="task?.ICONNAME">
                                    <img
                                        src="assets/icons/web_app/{{task.ICONNAME}}">
                                </mat-icon>
                                <span class="font-bold">{{task.EVENTSUMMARY}}</span>
                                <a [ngClass]="COLOR"
                                    *ngIf="router.url == '/matter-details/matter-detail-dashboard'"
                                    class="font-bold  ml-10">{{task.SHORTNAME}}</a>
                                <a [ngClass]="COLOR"
                                    *ngIf="router.url != '/matter-details/matter-detail-dashboard'"
                                    style="text-decoration: underline;"
                                    class="font-bold ml-10"
                                    (click)="RedirectHyperLink(task)">{{task.SHORTNAME}}</a>
                            </ng-template>

                            <div class="ptag-form" *ngIf="task">
                                <p class="timeline-datetime"> <span
                                        *ngIf="task?.EVENTDATE !== ''">
                                        {{formatechanges(task.EVENTDATE) | date
                                        : 'mediumDate'}}

                                    </span> <span
                                        *ngIf="task?.EVENTTIME && task?.EVENTTIME !==''">,
                                        {{task?.EVENTTIME}}</span></p>
                                <p class="m-r psline display-flex align-center"
                                    *ngIf="task?.TABLENAME !== 'TrustTransaction'">
                                    <span class="dot-block">•</span> <span
                                        class="ptag-pone timeline-view-btn"
                                        (click)="EditDetail(task)">View</span></p>
                            </div>

                            <div class="timeline-content"
                                *ngIf="task && task.EVENTDESCRIPTION !==''">
                                <!-- <h5 class="f-16">{{task?.MATTER}} </h5> -->
                                <p [innerHTML]="task.EVENTDESCRIPTION"></p>
                            </div>

                        </form>
                    </mat-step>
                    <!-- <mat-step label="Step 2" state="chat">
                            <form>
                                <ng-template matStepLabel><span class="fw-700"> Leo Gill </span>added you to<span class="fw-700">
                                        Top Secret Project</span> group and assigned you as a<span class="fw-700"> Project
                                        Manager</span></ng-template>
                                <div class="ptag-form">
                                    <p>Mar 24, 12:35 PM</p>
                                    <p class="ptag-pone">• Top Secret Project</p>
                                </div>
                                <div class="timeline-content">
                                    <h5 class="f-16">Congratulations for your acceptance! </h5>
                                    <p>Hi Brian,
                                        Your submission has been accepted and you are ready to move into the next phase. Once you
                                        are ready, reach out to me and we will ...</p>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step label="Step 3">
                            <ng-template matStepLabel>You have 15 unread mails across 3 mailboxes</ng-template>
                            <div class="ptag-form">
                                <p>Mar 24, 12:35 PM</p>
                                <p class="ptag-pone">• Top Secret Project</p>
                            </div>
                            <div class="timeline-content">
                                <h5 class="f-16">Congratulations for your acceptance! </h5>
                                <p>Hi Brian,
                                    Your submission has been accepted and you are ready to move into the next phase. Once you are
                                    ready, reach out to me and we will ...</p>
                            </div>
                        </mat-step> -->
                </mat-vertical-stepper>

                <mat-vertical-stepper class="timeline-mainwrap"
                    *ngIf="timelineData.value.length == 0">
                    There is no data to display.
                </mat-vertical-stepper>
            </ng-container>
        </ng-container>

        <!-- <div class="main-block">
                <div class="timel-today-btn f-14">
                    Yesterday
                </div>

                <mat-vertical-stepper class="timeline-mainwrap">

                    <mat-step>
                        <form class="timeline-forms">
                            <ng-template matStepLabel>You have 3 new mails</ng-template>
                            <div class="ptag-form">
                                <p>Mar 24, 12:35 PM</p>
                                <p class="ptag-pone">• Mailbox</p>
                            </div>

                            <div class="timeline-content">
                                <p>1. Please review and sign the attached agreement</p>
                                <p>2. Delivery address confirmation</p>
                                <p>3. Previous clients and their invoices</p>
                            </div>

                        </form>
                    </mat-step>
                    <mat-step>
                        <form>
                            <ng-template matStepLabel><span class="fw-700"> Tina Harris </span>started a chat with
                                you</ng-template>
                            <div class="ptag-form">
                                <p>Mar 24, 12:35 PM</p>
                                <p class="ptag-pone">• Go to Chat (Tina Harris)</p>
                            </div>
                            <div class="timeline-content">
                                <h5 class="f-16">Congratulations for your acceptance! </h5>
                                <p>Hi Brian,
                                    Your submission has been accepted and you are ready to move into the next phase. Once
                                    you are ready, reach out to me and we will ...</p>
                            </div>
                        </form>
                    </mat-step>

                </mat-vertical-stepper>

                <div class="main-block">
                    <div class="timel-today-btn f-14">
                        3 Days Ago
                    </div>

                    <mat-vertical-stepper class="timeline-mainwrap">

                        <mat-step>
                            <form class="timeline-forms">
                                <ng-template matStepLabel>Your submission has been accepted and you are ready to sign-up for
                                    the final assigment which will be ready in 2 days</ng-template>
                                <div class="ptag-form">
                                    <p>Mar 24, 12:35 PM</p>
                                </div>
                            </form>
                        </mat-step>


                    </mat-vertical-stepper>

                </div>
            </div> -->
    </div>
</div>
