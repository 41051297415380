
<div class="btn DotedMenu">
    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
        <mat-icon class="secondary-text">more_vert</mat-icon>
    </button>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="openDialog()"> Field Configuration</button>
            <!-- <button mat-menu-item (click)="refreshContactTabData(false)"> Reload Data</button>
            <button mat-menu-item (click)="refreshContactTabData(true)">Request All Data</button>
            <button mat-menu-item (click)="DownloadAllData()">
                <img src="assets/icons/web_app/EXP_CSV.ico" style="height: 20px !important;margin-top: 10px;">
                Export to CSV - retrieve all data first
            </button> 
            <button mat-menu-item (click)="downloadFile(Contactdata.data)">
                <img src="assets/icons/web_app/EXP_CSV.ico" alt=""
                    style="height: 20px !important;margin-top: 10px;">
                    Export to CSV - current data
            </button> -->
            <button mat-menu-item (click)="getNewExportData()">
                <img src="assets/icons/web_app/EXP_CSV.ico" alt=""
                    style="height: 20px !important;margin-top: 10px;">
                    Export to CSV
            </button>
        </ng-template>
    </mat-menu>
</div>

<div id="refreshContactTab" class="page-layout simple left-sidebar inner-sidebar inner-scroll" [ngClass]="COLOR">
    <div fxLayoutAlign="center" class="content">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div matter-side-div">
                <form [formGroup]="contactFilter" class="align-center display-flex filter-menuwrap form-div-side" id="filter-form-menu-wrap">
                    <div class="align-center display-flex flex-wrap filter-menublock">
                        <div class="add-filterwrap" >
                            <!-- button -->
                            <button mat-button [matMenuTriggerFor]="dummyButton" [disabled]="true" style="display: none;">
                                Filters</button>
                            <mat-menu #dummyButton="matMenu">
                            </mat-menu>
                            <!-- ends here ~ button -->
                            <button mat-button [matMenuTriggerFor]="menuFilteroption" class="filter-button"
                             [disabled]='optionalFilterList | enableDisableFilter:contactFilter.value'
                            (click)="backToFilter('optional-Discount');showFiltersList=true;"><img [src]="currentUser?.PRODUCTTYPE == 'Solicitor' ? 'assets/icons/filter-icon.png':'assets/icons/filter-icon-barrister.png'"
                                alt="Filter" class="filter-icon" />
                           <span  [ngClass]='(optionalFilterList| enableDisableFilter:contactFilter.value) ? "disabled-filter-text" : ""'> Filters </span> </button>
                            
                            <mat-icon class="icon-btn close-btn" (click)="resetFilter()" *ngIf="optionalFilters?.length > 0">close</mat-icon>
                            <mat-icon class="icon-btn" id="refreshContactTa" (click)="refreshContactTabData(false)">refresh</mat-icon >
                            <mat-menu #menuFilteroption="matMenu" (click)="preventDefault($event)">
                                <div class="filterscroll" [ngClass]="COLOR">
                                <div  *ngFor="let optionalFilterData of optionalFilterList">
                                    <div  *ngIf="optionalFilterData.FILTERTYPE != 'HIDDEN'">
                                    <button class="addbutton"  *ngIf="!optionalFilterData.FILTERAPPLY  && showFiltersList"
                                        (click)="preventDefault($event);activeCurrentFilter('optional-Discount',optionalFilterData);addNewFilter(activeOptionalFilter);showFiltersList=false;">
                                        <mat-icon>add_circle</mat-icon>{{optionalFilterData.DESCRIPTION}}
                                    </button>
                                    <div class="menu-submenu" id="optional-Discount">
                                        <h5 class="filter-title" *ngIf="activeOptionalFilter">
                                            <mat-icon class="back-icon"
                                                (click)="backToFilter('optional-Discount');preventDefault($event);showFiltersList=true;">arrow_back_ios
                                            </mat-icon>Filter by {{activeOptionalFilter.DESCRIPTION}}
                                        </h5>
                                        <div *ngIf="activeOptionalFilter">
                                            <ng-container formArrayName="FILTERSLIST">
                                                <div *ngFor="let filter of _filterList; let j =index;" class="position-relative">
                                                    <ng-container [formGroupName]="j">
                                                        <mat-form-field appearance="outline" class="width-full"
                                                            (click)="preventDefault($event)">
                                                            <mat-select (selectionChange)="optionalDateFilter($event , filter , 'default' , -1)"
                                                                name="OPERATION" [(ngModel)]="addFilter.OPERATION"
                                                                formControlName="OPERATION"  #operationFilter>
                                                                <mat-option *ngFor="let d of filter.DATEFILTERLIST "
                                                                    [value]="d.value">{{d.description}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <mat-form-field class="width-full" appearance="outline"
                                                                (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                <input  matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required appNumberMask>
                                                            </mat-form-field>
                                                            <mat-form-field class="width-full" appearance="outline"
                                                                (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                <input  matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required currencyMask>
                                                            </mat-form-field>
                                                           
                                                        </div>
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <mat-form-field class="width-full" appearance="outline"
                                                                (click)="preventDefault($event)">
                                                                <input matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                            </mat-form-field>
                                                        </div>
            
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required appNumberMask>
                                                                </mat-form-field>
                                                                <span class="middle-caption">and</span>
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE2"
                                                                        formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required appNumberMask>
                                                                </mat-form-field>
                                                            </div>

                                                            <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required currencyMask>
                                                                </mat-form-field>
                                                                <span class="middle-caption">and</span>
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE2"
                                                                        formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required currencyMask>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <div class="display-flex align-center">
                                                                <mat-form-field appearance="outline" class="set-date"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput [matDatepicker]="picker6" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                    <mat-datepicker-toggle matSuffix [for]="picker6">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker6></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                            <span class="back-tick">
                                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                            </span>
                                                            <div class="display-flex align-center field-box"
                                                                (click)="preventDefault($event)">
                                                                <mat-form-field class="set-date" appearance="outline">
                                                                    <input matInput placeholder="" name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                </mat-form-field>
                                                                <mat-form-field appearance="outline" class="set-date">
                                                                    <mat-select [value]="'days'" formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required>
                                                                        <mat-option value="days">Days</mat-option>
                                                                        <mat-option value="month">Month </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
            
            
                                                        <div class="display-flex align-center"
                                                            *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                            <mat-form-field appearance="outline" class="set-date"
                                                                (click)="preventDefault($event)">
                                                                <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                                    formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                            </mat-form-field>
                                                            <span class="middle-caption">and</span>
                                                            <mat-form-field appearance="outline" class="set-date"
                                                                (click)="preventDefault($event)">
                                                                <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                                    formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required>
                                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker2></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>
            
                                                        <button mat-raised-button color="primary" class="width-full accent"
                                                            (click)="addfilter(filter , operationFilter, $event)">Apply</button>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </mat-menu>
                            </div>

                            <div class="position-relative">
                                <mat-icon class="badge-ic"
                                    (click)="(activeFilters && activeFilters.active)?clearFilter('active' , 'Active'):false">
                                    {{(activeFilters && activeFilters.active)?'cancel_circle' : 'add_circle'}} </mat-icon>
                                <button mat-button [matMenuTriggerFor]="activeMenucard" class="buttonwith-text dotted-button">
                
                                    Active<span class="filter-applytext display-flex align-center">{{(activeFilters &&
                                        activeFilters.activeDesc)?activeFilters.activeDesc : ''}}<mat-icon class="rightdownarrow">
                                            keyboard_arrow_down</mat-icon></span>
                                </button>
                                <mat-menu #activeMenucard="matMenu" #menu>
                                    <mat-radio-group formControlName="active" aria-labelledby="example-radio-group-label" class="filter-radio-group"  >
                                    <mat-radio-button (change)="Filterconfig(season,'Active');ContactChange($event.value)" class="filter-radio-button" disableRipple="true" *ngFor="let season of seasons" [value]="season.id">
                                     {{season.data}}
                                    </mat-radio-button>
                                    </mat-radio-group>
                                </mat-menu>
                            </div>

                            <div class="position-relative">
                                <mat-icon class="badge-ic"
                                    (click)="(activeFilters && activeFilters.contactType)?clearFilter('contactType' , 'ContactType'):false">
                                    {{(activeFilters && activeFilters.contactType)?'cancel_circle' : 'add_circle'}} </mat-icon>
                                <button mat-button [matMenuTriggerFor]="activeMenucardval" class="buttonwith-text dotted-button">
                
                                    Contact Type<span class="filter-applytext display-flex align-center">{{(activeFilters &&
                                        activeFilters.contactType)?activeFilters.contactType : ''}}<mat-icon class="rightdownarrow">
                                            keyboard_arrow_down</mat-icon></span>
                                </button>
                                <mat-menu #activeMenucardval="matMenu" #menu>
                                    <mat-radio-group  formControlName="ContactType" aria-labelledby="filter-radio-group-label" class="filter-radio-group"  >
                                        <mat-radio-button (change)="Filterconfig(Contact,'ContactType');ContactTypeChange()" class="filter-radio-button" disableRipple="true" *ngFor="let Contact of contact" [value]="Contact.id">
                                         {{Contact.data}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </mat-menu>
                            </div>

                            <div class="position-relative">
                                <mat-icon class="badge-ic"
                                    (click)="(activeFilters && activeFilters.firstLetter)?clearFilter('firstLetter' , 'FirstLetter'):false">
                                    {{(activeFilters && activeFilters.firstLetter)?'cancel_circle' : 'add_circle'}} </mat-icon>
                                <button mat-button [matMenuTriggerFor]="activeMenu" class="buttonwith-text dotted-button">
                
                                    Starts With<span class="filter-applytext display-flex align-center">{{(activeFilters &&
                                        activeFilters.firstLetter)?activeFilters.firstLetter : ''}}<mat-icon class="rightdownarrow">
                                            keyboard_arrow_down</mat-icon></span>
                                </button>
                                <mat-menu #activeMenu="matMenu" #menu>
                                        <mat-radio-group  formControlName="FirstLetter" aria-labelledby="filter-radio-group-label" class="filter-radio-group"  >
                                            <mat-radio-button (change)="Filterconfig(letter,'FirstLetter');Contactvalue()" class="filter-radio-button" disableRipple="true" *ngFor="let letter of ListofLetter" [value]="letter?.id">
                                             {{letter?.data}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                </mat-menu>
                            </div>

                            <div class="position-relative">
                                <!-- search -->
                                <mat-icon class="badge-ic"
                                (click)="clearFilter('Search' , 'search')" *ngIf="activeFilters.Search">
                                {{(activeFilters.Search)?'cancel_circle' : ''}}</mat-icon>

                                <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                    Search<span class="filter-applytext display-flex align-center">

                                            <input matInput  #searchField
                                            (keyup)="Filterconfig($event,'search');onSearchNew($event)"
                                                formControlName="search" style="margin-top: 4px;text-align: left;" tabindex="6">
                                            <mat-icon matSuffix (click)="onSearch()">search</mat-icon>

                                    </span>
                                </button>
                            </div>

                            <ng-container formArrayName="FILTERS">
                                <div *ngFor="let filter of optionalFilters; let i =index;" class="position-relative">
                                    <ng-container [formGroupName]="i">
                                        <mat-icon class="badge-ic" (click)="resetOptionalFilter(filter , filter.FILTERTYPE , i)">cancel_circle
                                        </mat-icon>
                                        <button mat-button [matMenuTriggerFor]="menuCreatedDate_i" class="buttonwith-text" (click)="setFilterUi(filter,i)">
                                            {{filter.DESCRIPTION}}<span
                                                class="filter-applytext display-flex align-center">{{(optionalDateFilterPayload[i] &&
                                                optionalDateFilterPayload[i]['value'] !==
                                                null)?optionalDateFilterPayload[i]['value'].VALUE : ''}} {{(optionalDateFilterPayload[i]
                                                && optionalDateFilterPayload[i]['value'] !== null &&
                                                optionalDateFilterPayload[i]['value'].VALUE2 !== '' && optionalDateFilterPayload[i]['value'].VALUE2 !== null)?' - ' +
                                                optionalDateFilterPayload[i]['value'].VALUE2 : ''}}<mat-icon class="rightdownarrow">
                                                    keyboard_arrow_down</mat-icon></span>
                                        </button>
                                        <mat-menu #menuCreatedDate_i="matMenu">
                                            <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5>
                                            <mat-form-field appearance="outline" class="width-full" (click)="preventDefault($event)">
                                                <mat-select (selectionChange)="optionalDateFilter($event , filter , 'active' , i)" name="OPERATION"
                                                    formControlName="OPERATION" #Datefilter>
                                                    <mat-option *ngFor="let d of filter.DATEFILTERLIST " [value]="d.value">{{d.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <mat-form-field class="width-full" appearance="outline"
                                                    (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                    <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                        required appNumberMask>
                                                </mat-form-field>
                                                <mat-form-field class="width-full" appearance="outline"
                                                    (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                    <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                        required currencyMask>
                                                </mat-form-field>
                                            </div>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <mat-form-field class="width-full" appearance="outline"
                                                    (click)="preventDefault($event)">
                                                    <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                        required>
                                                </mat-form-field>
                                            </div>
                
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required appNumberMask>
                                                    </mat-form-field>
                                                    <span class="middle-caption">and</span>
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE2" formControlName="VALUE2"
                                                            required appNumberMask>
                                                    </mat-form-field>
                                                </div>

                                                <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required currencyMask>
                                                    </mat-form-field >
                                                    <span class="middle-caption">and</span>
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE2" formControlName="VALUE2"
                                                            required currencyMask>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <div class="display-flex align-center">
                                                    <mat-form-field appearance="outline" class="set-date"
                                                        (click)="preventDefault($event)">
                                                        <input matInput [matDatepicker]="picker6" name="VALUE" formControlName="VALUE"
                                                            required>
                                                        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker6></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                <span class="back-tick">
                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                </span>
                                                <div class="display-flex align-center field-box" (click)="preventDefault($event)">
                                                    <mat-form-field class="set-date" appearance="outline">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="set-date">
                                                        <mat-select [value]="'days'" formControlName="VALUE2" required>
                                                            <mat-option value="days">Days</mat-option>
                                                            <mat-option value="month">Month </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                
                
                                            <div class="display-flex align-center"
                                                *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                                    <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                        formControlName="VALUE" required>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                <span class="middle-caption">and</span>
                                                <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                                    <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                        formControlName="VALUE2" required>
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <button mat-raised-button color="primary" class="width-full accent"
                                            (click)="applayOptionalfilter(optionalFilters[i] , i ,Datefilter, $event,'isDynamic')">Apply</button>
                                        </mat-menu>
                                    </ng-container>
                                </div>
                            </ng-container>

                            </div>
                </form>
                <div class="bg-dark rigth-div" id="appCues_contact"></div>
            </div>
            <div class="content">
                <div class="one content">
                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                    </div>
                    <div class="more_tbl_clmn resizable-table" [ngClass]="class==true?'Table-height':'Table-height-2'">
                        <div class="grid-overlay" *ngIf="isDisplay">
                            <div class="grid-overlay-msg"> There is no data to display. </div>
                        </div>
                        <mat-table  class="modern"
                        #Table [dataSource]="Contactdata" (matSortChange)="sortData($event)" matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSortDisableClear matSort [@animateStagger]="{value:'50'}" cdkDropListGroup>
                            <ng-container *ngFor="let item of displayedColumns; let indx = index;" matColumnDef="{{item}}">
                               
                                <mat-header-cell
                                *matHeaderCellDef="let i = index"
                                mat-sort-header
                                class="header_title"
                                mwlResizable
                                enableGhostResize="true"
                                (resizing)="onResizing($event, item)"
                                (resizeEnd)="onResizeEnd($event, item)"
                                [resizeEdges]="{
                                    bottom: false,
                                    right: true,
                                    top: false,
                                    left: true
                                 }">
                                <div
                                    class="desc_cell resize-handle-right"
                                    mwlResizeHandle
                                    [resizeEdges]="{ right: true }"></div>
                                {{ tempColobj[item].DESCRIPTION }}
                            </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}" [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                    <span *ngIf="row[item]!==undefined">{{row[item] | toFixedAmount:tempColobj[item].CURRENCY | wordwrap:tempColobj[item].WIDEDATA}}</span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                            <mat-row [style.background]="highlightedRows == row.CONTACTGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.CONTACTGUID ? 'row-text-colore' : ''" highlightedRows=row.CONTACTGUID *matRowDef="let row; columns: displayedColumns;" (click)="editContact(row.CONTACTGUID, row);highlightedRows = row.CONTACTGUID "
                                matRipple (dblclick)="ContactsDialog()" [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                        </mat-table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.contact_page" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>