import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-topic-dialog',
  templateUrl: './topic-dialog.component.html',
  styleUrls: ['./topic-dialog.component.scss'],
  animations: fuseAnimations
})
export class TopicDialogComponent implements OnInit, OnDestroy {
  errorWarningData: any = { "Error": [], 'Warning': [] };
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  addData: any = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  MainTopicClass: any = [];
  public TopicDialoge = {
    TOPICNAME: '', Parent: '', TOPICGUID: '', PARENTGUID: '', selective: ''
  }
  ShowParent: string;
  action: string;
  FormAction: string;
  TopicGuid: string;
  title: string;
  TopicClassVal: any;
  parentGuid: string;
  MainTopicData: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(private _mainAPiServiceService: MainAPiServiceService, public _matDialog: MatDialog, public behaviorService: BehaviorService,
    private toastr: ToastrService, public dialogRef: MatDialogRef<TopicDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService,) {
    this.action = data.action;
    localStorage.setItem('istrackid', 'mainAuthorityTopicPopup');
    if (data.action == 'edit') {
      this.title = 'Update';
    } else if (data.action == 'duplicate') {
      this.title = 'Duplicate';
    }
    else if (data.action == 'new') {
      this.title = 'Add';
    }
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });

    this.behaviorService.MainTopicData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.MainTopicData = result;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'mainAuthorityTopicPopup' && result['click'] && result['data']['topic']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'mainAuthorityTopicPopup' && result['click'] && result['data']['topic']) {
        this.saveTopic();
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let Payload = {
      "Action": "GetData",
      "Filters": {}
    };

    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData(Payload, 'topic').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        this.isLoadingResults = false;
        responses.DATA.RECORDS.forEach(element => {
          this.MainTopicClass.push(element);
          if (element.SUBTOPICS) {
            element.SUBTOPICS.forEach(element2 => {
              this.MainTopicClass.push(element2);
            });
          }
        });
      }
    });
    if (this.action == 'edit' || this.action == 'duplicate') {
      let Payload = {
        "Action": "GetData",
        "Filters": { TOPICGUID: this.MainTopicData }
      };

      this.isLoadingResults = true;
      this._mainAPiServiceService.getSetData( Payload, 'topic').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.TopicDialoge.selective = '1';
          this.TopicClassChange('1');
          this.TopicDialoge.TOPICNAME = responses.DATA.RECORDS[0].TOPICNAME;
          this.TopicDialoge.TOPICGUID = responses.DATA.RECORDS[0].TOPICGUID;
          let Payload2 = {
            "Action": "GetData",
            "Filters": { TOPICGUID: responses.DATA.RECORDS[0].PARENTGUID }
          };

          if (responses.DATA.RECORDS[0].PARENTGUID != "") {
            this._mainAPiServiceService.getSetData( Payload2, 'topic').subscribe(responses => {
              if (responses.CODE === 200 && responses.STATUS === 'success') {
                this.isLoadingResults = false;
                this.TopicDialoge.Parent = responses.DATA.RECORDS[0].TOPICNAME;
                this.TopicDialoge.PARENTGUID = responses.DATA.RECORDS[0].TOPICGUID;
                this.TopicDialoge.selective = '2';
                this.TopicClassChange('2');
              } else {
                this.isLoadingResults = false;
              }
            });
          } else {
            this.TopicDialoge.selective = '1';
            this.TopicClassChange('1');
            this.TopicDialoge.TOPICNAME = responses.DATA.RECORDS[0].TOPICNAME;
            this.TopicDialoge.TOPICGUID = responses.DATA.RECORDS[0].TOPICGUID;
          }
          this.isLoadingResults = false;
        } else if (responses.MESSAGE == 'Not logged in') {
          this.isLoadingResults = false;
          this.dialogRef.close(false);
        }
      });
    } else {
      this.isLoadingResults = false;
      this.TopicDialoge.selective = '1';
      this.TopicClassChange('1');
    }


  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to set the topic class
   */
  TopicClassChange(val) {
    this.TopicClassVal = val;
    if (val == '2') {
      this.ShowParent = 'yes';
    } else {
      this.ShowParent = 'no';
    }
  }

  /**
   * This function is used to set the topic name change
   */
  TopicNameChange(val) {
    let value = this.MainTopicClass.find(c => c['TOPICNAME'] == val);
    this.TopicDialoge.PARENTGUID = value.TOPICGUID;
  }

  /**
   * This function is used to save the topic data value
   */
  saveTopic() {
    this.isspiner = true;
    if (this.action == 'edit') {
      this.FormAction = 'update';
      this.TopicGuid = this.TopicDialoge.TOPICGUID
    } else if (this.action == 'new' || this.action == 'duplicate') {
      this.FormAction = 'insert';
      this.TopicGuid = ""
    }
    if (this.TopicClassVal == '2') {
      this.parentGuid = this.TopicDialoge.PARENTGUID;
    } else {
      this.parentGuid = '';
    }
    let data = {
      TOPICGUID: this.TopicGuid,
      PARENTGUID: this.parentGuid,
      TOPICNAME: this.TopicDialoge.TOPICNAME

    }
    let finalData = { DATA: data, Action: this.FormAction, VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'topic').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });

  }

  /**
   * This function is used to check the validation data value
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.isspiner = false;
    //   this.toastr.error(errorData);
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.AuthoritySaveData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.AuthoritySaveData(details);
    // } 
    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        // this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.AuthoritySaveData(details);
        }
      }
    });
  }

  /**
   * This function is used to AithoritySaveData
   */
  AuthoritySaveData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'topic').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success('save successfully');
        } else {
          this.toastr.success('update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, error => {
      this.toastr.error(error);
    });
  }

}
