<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="one prepare_invoice_summary" [formGroup]="addInvoiceForm" fxLayout="column">
    <div class="value_label_parent">
        <div fxFlex="60" class="label_width"> </div>
        <div fxFlex="15" class="value_width">Excluding GST</div>
        <div fxFlex="10" class="value_width">GST</div>
        <div fxFlex="15" class="value_width">Including GST</div>
    </div>
    <div class="value_label_parent">
        <div fxFlex="60" class="label_width">
            <!-- <mat-checkbox formControlName="Fees" name="Fees" (change)="checkBoxSelect($event)"
                formControlName="APPLYTOFEES">Apply to fees/Activities</mat-checkbox> -->

            <mat-slide-toggle formControlName="Fees" name="Fees" (change)="checkBoxSelect($event)"
                formControlName="APPLYTOFEES">
                Apply to fees/Activities
            </mat-slide-toggle>
        </div>

        <mat-form-field appearance="outline" class="pr-4" fxFlex="25"
            *ngIf="addInvoiceForm && addInvoiceForm.controls['APPLYTOFEES'].value && showApplyToWhatDropdown">
            <mat-label>Apply To What</mat-label>
            <mat-select (selectionChange)="currencyChange($event.value)" name="applyTo" [(ngModel)]="selectedOption"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="All Fees/Activities">All Fees/Activities</mat-option>
                <mat-option value="Selected Fee Earners">Selected Fee Earners</mat-option>
                <mat-option value="Selected Deliverables">Selected Deliverables</mat-option>
            </mat-select>
        </mat-form-field>
        <span class="mat-button-wrapper">
            <mat-icon  *ngIf="FEEEARNERBTN" class="add-circle-color"  (click)="addNewLine()">add_circle</mat-icon>
          
        </span>
        <!-- <button *ngIf="FEEEARNERBTN" mat-raised-button color="accent" class="mat-accent btn" (click)="addNewLine()"> + </button> -->

        <mat-form-field appearance="outline" fxFlex="15" class="value_width" class="pr-4">
            <input class="sideText" matInput readonly placeholder="Date Received" formControlName="ActivityEXTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10" class="value_width"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="value_width" class="pr-4">
            <input class="sideText" matInput readonly placeholder="Date Received" formControlName="ActivityINTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>

    <ng-container formArrayName="FEEEARNERS">
        <div class="value_label_parent" *ngFor="let UserData of FEEEARNERSLIST; let i = index">
            <ng-container [formGroupName]="i">
                <mat-form-field fxFlex="16" class="value_width" class="pr-4" appearance="outline"
                    *ngIf="selectedOption !== 'Selected Deliverables'">
                    <mat-label>Fee Earner</mat-label>
                    <mat-select formControlName="FEEEARNERID" (selectionChange)="selectFeeEarnar($event , i)" (click)="feeEarnerTagged()"
                        >
                        <mat-option *ngFor="let user of feeEarnarList;" [value]="user.USERID">{{user.USERID}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="16" class="value_width" class="pr-4" appearance="outline"
                    *ngIf="selectedOption == 'Selected Deliverables'">
                    <mat-label>Deliverables</mat-label>
                    <mat-select formControlName="FEEEARNERID" (selectionChange)="selectDeliverable($event , i)"
                        [(value)]="deliverableData">
                        <mat-option *ngFor="let user of feeEarnardeliverableList;" [value]="user.DELIVERABLEID">
                            {{user.DELIVERABLEDESCRIPTION}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="16" class="value_width" class="pr-4" appearance="outline">
                    <mat-label>Discount</mat-label>
                    <mat-select formControlName="INCREASE" (selectionChange)="DiscountTypeChangeFreeEarner(i)">
                        <mat-option value="true">Increase</mat-option>
                        <mat-option value="false">Discount</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="15" class="value_width" class="pr-4" appearance="outline">
                    <mat-label>GST Amount</mat-label>
                    <mat-select (selectionChange)="GSTTypeChangeFreeEarner($event.value , i)"
                        formControlName="CALCINCGST">
                        <mat-option value="true">Ex-GST Amount</mat-option>
                        <mat-option value="false">Inc-GST Amount</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="16" class="value_width" class="pr-4" appearance="outline">
                    <mat-label>Discount Type</mat-label>
                    <mat-select formControlName="DISCOUNTBYAMOUNT"
                        (selectionChange)="PercentageTypeChangeFreeEarner($event.value , i)">
                        <mat-option value="false">Percentage</mat-option>
                        <mat-option value="true">Amount</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="16" class="value_width" class="pr-4">
                    <mat-label>Amount</mat-label>
                    <input class="sideText" matInput type="number" min="0" max="100" placeholder="Percentage"
                        *ngIf="isAmount" (focusout)="onAmoPerChnageFeeEarnar($event , i)"
                        formControlName="DISCOUNTAMOUNT">
                    <input class="sideText" matInput placeholder="Amount" [value]="addInvoiceForm.get('amount').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." *ngIf="!isAmount"
                        (focusout)="onAmoPerChnageFeeEarnar($event , i)" formControlName="amount" [leadZero]="true">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="18" class="value_width mat-form-field-disabled"
                    class="pr-4" [ngClass]="isDisabled ? 'disabledField':''">
                    <mat-label>Discount</mat-label>
                    <input class="sideText" matInput currencyMask placeholder="Discount"
                        style="color:black;font-weight: bold;" readonly formControlName="DISCOUNTANS" [disabled]="true">
                </mat-form-field>
                <mat-icon class="removeFeeEarner" (click)="removeFeeEarner(i)">cancel_circle</mat-icon>
            </ng-container>
        </div>
    </ng-container>

    <!-- <div class="value_label_parent clickToaddBtn" *ngIf="FEEEARNERBTN">
        <button mat-raised-button color="accent" class="mat-accent" fxFlex="30" (click)="addNewLine()"> Click here to add another line </button>
    </div> -->

    <div class="value_label_parent">
        <div fxFlex="60" class="label_width">
            <!-- <mat-checkbox name="Sundries" formControlName="Sundries" (change)="checkBoxSelect($event)">Apply to sundries
                /searching</mat-checkbox> -->

            <mat-slide-toggle name="Sundries" formControlName="Sundries" (change)="checkBoxSelect($event)">
                Apply to sundries/searching
            </mat-slide-toggle>
        </div>
        <mat-form-field appearance="outline" fxFlex="15" class="value_width" class="pr-4">
            <input class="sideText" matInput readonly placeholder="Date Received" formControlName="SundryEXTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10" class="value_width"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="value_width" class="pr-4">
            <input class="sideText" matInput readonly placeholder="Date Received" formControlName="SundryINTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>
    <div class="value_label_parent">
        <div fxFlex="60" class="label_width">
            <!-- <mat-checkbox formControlName="MatterExpenses" name="Matter Expenses" (change)="checkBoxSelect($event)">
                Apply to Matter Expenses</mat-checkbox> -->

            <mat-slide-toggle formControlName="MatterExpenses" name="Matter Expenses" (change)="checkBoxSelect($event)">
                Apply to Matter Expenses
            </mat-slide-toggle>
        </div>
        <mat-form-field appearance="outline" fxFlex="15" class="value_width" class="pr-4">
            <input class="sideText" matInput readonly placeholder="Date Received" formControlName="WIPEXTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
        <div fxFlex="10" class="value_width"></div>
        <mat-form-field appearance="outline" fxFlex="15" class="value_width" class="pr-4">
            <input class="sideText" matInput readonly placeholder="Date Received" formControlName="WIPINTOTAL">
            <span class="currency_sign" matPrefix>$&nbsp;</span>
        </mat-form-field>
    </div>
    <div class="value_label_parent">
        <mat-form-field fxFlex="25" class="value_width" class="pr-4" appearance="outline">
            <mat-label>Discount</mat-label>
            <mat-select formControlName="Discount_type" (selectionChange)="DiscountTypeChange()">
                <mat-option value="Increase">Increase</mat-option>
                <mat-option value="Discount">Discount</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="25" class="value_width" class="pr-4" appearance="outline">
            <mat-label>GST Amount</mat-label>
            <mat-select (selectionChange)="GSTTypeChange($event.value)" formControlName="GST_type">
                <mat-option value="ExGstAmount">Ex-GST Amount</mat-option>
                <mat-option value="IncGstAmount">Inc-GST Amount</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="25" class="value_width" class="pr-4" appearance="outline">
            <mat-label>Discount Type</mat-label>
            <mat-select formControlName="Percentage_type" (selectionChange)="PercentageTypeChange($event.value)">
                <mat-option value="Percentage">Percentage</mat-option>
                <mat-option value="Amount">Amount</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="value_width" class="pr-4">
            <mat-label>Amount</mat-label>
            <input class="sideText" type="number" matInput min="0" max="100" placeholder="Percentage" *ngIf="isAmount"
                (focusout)="onAmoPerChnage($event)" formControlName="Percentage">
            <input class="sideText" [value]="addInvoiceForm.get('amount').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matInput placeholder="Amount" *ngIf="!isAmount" (blur)="onAmoPerChnage($event)"
                formControlName="amount" [leadZero]="true">
        </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Disc / inc Description</mat-label>
            <textarea matInput formControlName="ADDITIONALTEXT"></textarea>
        </mat-form-field>
    </div>
</div>
<mat-divider></mat-divider>