<div class=" one " fxLayout="column" [formGroup]="matterAddressForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CROWNALLOTMENT,'has-warning':errorWarningData.Warning?.CROWNALLOTMENT}">
            <mat-label>Allotment </mat-label>
            <input matInput formControlName="CROWNALLOTMENT">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CROWNBLOCK,'has-warning':errorWarningData.Warning?.CROWNBLOCK}">
            <mat-label>Block </mat-label>
            <input matInput formControlName="CROWNBLOCK">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CROWNSECTION,'has-warning':errorWarningData.Warning?.CROWNSECTION}">
            <mat-label>Section </mat-label>
            <input matInput formControlName="CROWNSECTION">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CROWNPORTION,'has-warning':errorWarningData.Warning?.CROWNPORTION}">
            <mat-label>Portion </mat-label>
            <input matInput formControlName="CROWNPORTION">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CROWNSUBDIVISION,'has-warning':errorWarningData.Warning?.CROWNSUBDIVISION}">
            <mat-label>Sub Division </mat-label>
            <input matInput formControlName="CROWNSUBDIVISION">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="75" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.CROWNPARISH,'has-warning':errorWarningData.Warning?.CROWNPARISH}">
            <mat-label>Parish </mat-label>
            <input matInput formControlName="CROWNPARISH">
        </mat-form-field>
    </div>
</div>