<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<ng-container *ngIf="data$ | async"></ng-container>
<div *ngIf="Warnpopup =='hide'" cdkDrag
    class="popup_main_div add_edit_receipt_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">
        {{title}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <form name="PrepareReceiptForm" id="PrepareReceiptForm"
                [formGroup]="PrepareReceiptForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="70"
                        class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.FIRMGUID,'has-warning':errorWarningData.Warning?.FIRMGUID}">
                        <mat-label>{{('' | productType)? "Client" :"Solicitor"}}</mat-label>
                        <input readonly matInput placeholder="Client"
                            formControlName="FIRMGUIDTEXT"
                            matTooltip="{{(toolTipList)?toolTipList.FIRMGUIDTEXT?.HOVER:''}}">
                        <mat-icon matSuffix (click)="selectClient('client')"
                            *ngIf="this._data.action !='editForTB' || this._data.action !='edit'"
                            [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIRMGUID?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.FIRMGUID?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="15"
                        class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMECODE,'has-warning':errorWarningData.Warning?.INCOMECODE}">
                        <mat-label>Receipt Code</mat-label>
                        <input matInput placeholder="Receipt Code"
                            formControlName="INCOMECODE"
                            matTooltip="{{(toolTipList)?toolTipList.INCOMECODE?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMECODE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.INCOMECODE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field *ngIf="this._data.action == 'add'|| 'edit'"
                        appearance="outline" fxFlex="15" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMEDATE,'has-warning':errorWarningData.Warning?.INCOMEDATE}">
                        <mat-label>Date Received</mat-label>
                        <input readonly
                            (dateInput)="choosedDate('input', $event)"
                            (dateChange)="choosedDate('change', $event)"
                            formControlName="INCOMEDATETEXT"
                            matTooltip="{{(toolTipList)?toolTipList.INCOMEDATE?.HOVER:''}}"
                            matInput [matDatepicker]="picker"
                            placeholder="Choose a date" [readonly]="isEdit"
                            [disabled]="isEdit">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMEDATE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.INCOMEDATE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <!-- <mat-form-field *ngIf="this._data.action != 'add' || this._data.action != 'edit'" appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.INCOMEDATE,'has-warning':errorWarningData.Warning?.INCOMEDATE}">
                        <mat-label>Date Received</mat-label>
                        <input readonly (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" formControlName="INCOMEDATETEXT" matTooltip="{{(toolTipList)?toolTipList.INCOMEDATE?.HOVER:''}}" matInput [matDatepicker]="picker" placeholder="Choose a date" [readonly]="isEdit" [disabled]="isEdit">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field> -->

                    <mat-form-field *ngIf="this._data.action == 'add'|| 'edit' "
                        appearance="outline" fxFlex="55" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.FIRMGUID,'has-warning':errorWarningData.Warning?.FIRMGUID}">
                        <mat-label>Payor</mat-label>
                        <input matInput placeholder="Payor"
                            formControlName="PAYEE"
                            [matAutocomplete]="payeeText"
                            matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}"
                            #payorInputField
                            (keydown.Tab)="selPayorOnPressTab($event)">
                        <mat-autocomplete #payeeText="matAutocomplete"
                            [panelWidth]="400"
                            (optionSelected)="PayeeTypeChange($event.option.value)">
                            <mat-option *ngFor="let val of getPayourarray"
                                [value]="val.CONTACTNAME">
                                <span
                                    *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>Add
                                    </b></span>&nbsp; <span
                                    *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>"</b></span>{{val.CONTACTNAME}}<span
                                    *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="selectClient('payee')"
                            [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico"
                                matTooltip matTooltipPosition="above">
                        </mat-icon>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field *ngIf="this._data.action == 'add' || 'edit'"
                        appearance="outline" fxFlex="15" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTGUID,'has-warning':errorWarningData.Warning?.BANKACCOUNTGUID}">
                        <mat-label>Bank Account</mat-label>
                        <input matInput placeholder="Bank Account" readonly
                            formControlName="BANKACCOUNTGUIDTEXT">
                        <mat-icon matSuffix>
                            <button id="bank" class="matternews"
                                (click)="BankingDialogOpen('Assets')"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"
                                    matTooltip
                                    matTooltipPosition="above"></button>
                        </mat-icon>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKACCOUNTGUID?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.BANKACCOUNTGUID?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field appearance="outline"
                        fxFlex="{{(this._data.action == 'add'|| 'edit')?15:33}}"
                        class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT}">
                        <mat-label>Amount Received</mat-label>
                        <input class="sideText" matInput
                            placeholder="Amount Received"
                            [value]="PrepareReceiptForm.get('AMOUNT').value | number: '1.2-2'"
                            formControlName="AMOUNT"
                            (focusout)="revivedAmount(); UtilityAPI('Amount_Received')" [(ngModel)]="AMOUNT"
                            type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true">
                            <!-- type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" -->
                            <!-- matTooltip="{{(toolTipList)?toolTipList.AMOUNT?.HOVER:''}}" -->
                            <!-- [(ngModel)]="AMOUNT" -->
                            <!-- (focusout)="revivedAmount(); UtilityAPI('Amount_Received')" -->
                            <!-- (focusout)="amountChange($event, 'AMOUNT')" -->
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field
                        *ngIf=" this.f.INCOMETYPE?.value !== 'Write Off'"
                        appearance="outline"
                        fxFlex="{{(this._data.action == 'add'|| 'edit')?15:33}}"
                        class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMETYPE,'has-warning':errorWarningData.Warning?.INCOMETYPE}">
                        <mat-label>Income Type</mat-label>
                        <mat-select formControlName="INCOMETYPE"
                            [value]="incomeType"
                            matTooltip="{{(toolTipList)?toolTipList.INCOMETYPE?.HOVER:''}}">
                            <mat-option value="Cash">Cash</mat-option>
                            <mat-option value="Cheque">Cheque</mat-option>
                            <mat-option value="Credit Card">Credit Card</mat-option>
                            <mat-option value="Direct Deposit">Direct Deposit</mat-option>
                            <mat-option value="Direct Withdrawal">Direct
                                Withdrawal</mat-option>
                            <mat-option value="Money Order">Money Order</mat-option>
                            <mat-option value="Transfer">Transfer</mat-option>
                            <mat-option value="SilqPay">SilqPay</mat-option>
                        </mat-select>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMETYPE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.INCOMETYPE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field
                        *ngIf=" this.f.INCOMETYPE?.value == 'Write Off'"
                        appearance="outline" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INCOMETYPE,'has-warning':errorWarningData.Warning?.INCOMETYPE}">
                        <mat-label>Write Off</mat-label>
                        <input matInput disabled readonly
                            placeholder="Write Off" formControlName="INCOMETYPE"
                            matTooltip="{{(toolTipList)?toolTipList.INCOMETYPE?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMETYPE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.INCOMETYPE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="this._data.action == 'add'">
                    <mat-form-field appearance="outline" fxFlex="70" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FIRMGUID,'has-warning':errorWarningData.Warning?.FIRMGUID}">
                        <mat-label>Payor</mat-label>
                        <input matInput placeholder="Payor" formControlName="PAYEE" [matAutocomplete]="payeeText" matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}" (keyup)="onChangePayor(payorInputField.value)" #payorInputField (keydown.Tab)="selPayorOnPressTab($event)">
                        <mat-autocomplete #payeeText="matAutocomplete" [panelWidth]="400" (optionSelected)="PayeeTypeChange($event.option.value)">
                            <mat-option *ngFor="let val of getPayourarray" [value]="val.CONTACTNAME">
                                <span *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>"</b></span>{{val.CONTACTNAME}}<span *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="selectClient('payee')" [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTGUID,'has-warning':errorWarningData.Warning?.BANKACCOUNTGUID}">
                        <mat-label>Bank Account</mat-label>
                        <input matInput placeholder="Bank Account" readonly formControlName="BANKACCOUNTGUIDTEXT">
                        <mat-icon matSuffix>
                            <button id="bank" class="matternews" (click)="BankingDialogOpen('Assets')"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico" matTooltip=""
                                    matTooltipPosition="above"></button>
                        </mat-icon>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKACCOUNTGUID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKACCOUNTGUID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>  -->
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <!-- [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}" -->
                    <mat-form-field appearance="outline" fxFlex="100"
                        class="pr-4">
                        <mat-label>Note</mat-label>
                        <textarea matInput formControlName="NOTE"
                            matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}"></textarea>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.NOTE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div *ngIf="action != 'edit' && action !='editForTB'"
                    fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100"
                        class="pr-4">
                        <mat-label>Show?</mat-label>
                        <mat-select formControlName="SHOW"
                            (ngModelChange)="onChangeShow($event)">
                            <mat-option *ngFor="let val of ShowData"
                                [value]="val.id">{{val.text}} </mat-option>
                        </mat-select>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHOW?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.SHOW?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>
                </div>
                <div *ngIf="action != 'edit' && action !='editForTB'"
                    fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto" class="unallocated_div">
                    <!-- Unallocated
                    <mat-form-field appearance="outline" fxFlex="60"
                        class="pr-4 readonly_apply">
                        <mat-label>Unallocated</mat-label>
                        <input currencyMask matInput placeholder="Unallocated"
                            readonly formControlName="Unallocated">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.UNALLOCATED?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.UNALLOCATED?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field> -->
                   
                    <ng-container *ngIf="currentUser.PRODUCTTYPE != 'Solicitor' && isClerkrate != 0">
                    <mat-form-field appearance="outline" fxFlex="20"
                        class="pr-4 region-input">
                        <mat-label>Amt Less Clerk Fee</mat-label>
                        <input style="text-align: right;" formControlName="AMOUNTLESSCLERKFEE"
                            [value]="PrepareReceiptForm.get('AMOUNTLESSCLERKFEE').value | number: '1.2-2'"
                            (focusout)="UtilityAPI('Amt_Less_Clark_Fee')"
                            matInput type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="20"
                        class="pr-4 region-input">
                        <mat-label>Clerk Fee</mat-label>
                        <input style="text-align: right;" type="text" formControlName="CLERKFEE"
                            [value]="PrepareReceiptForm.get('CLERKFEE').value | number: '1.2-2'"
                            (focusout)="UtilityAPI('Clark_Fee')"
                            matInput mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true">
                    </mat-form-field>
                    </ng-container>
                </div>
                <div *ngIf="action != 'edit' && action !='editForTB'"
                    fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto" class="allocate_btn_div">
                    Invoices To allocate Against
                    <!-- <button mat-raised-button color="accent"  (click)="PreviousSetLetter()" cdkFocusInitial>Previous</button> -->
                    <button mat-raised-button color="accent" class="mat-accent"
                        (click)="clickClearAllocation()">Clear
                        Allocations</button>
                    <button mat-raised-button color="accent" class="mat-accent"
                        (click)="clickAutoAllocation('AutoAllocation')">Auto
                        Allocate</button>

                        <mat-form-field appearance="outline" *ngIf="action != 'edit' && action !='editForTB'"
                        class="pr-4 readonly_apply  newClass">
                        <mat-label>Unallocated</mat-label>
                        <input currencyMask matInput placeholder="Unallocated"
                            readonly formControlName="Unallocated">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.UNALLOCATED?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.UNALLOCATED?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                        </mat-form-field>

                        <mat-form-field appearance="outline" *ngIf="PrepareReceiptForm.get('Unallocated').value > 0"
                        class="pr-4 newClass">
                        <mat-label>Credit Type</mat-label>
                        <mat-select formControlName="CREDITTYPE">
                            <mat-option value="Matter">Matter Credit</mat-option>
                            <mat-option value="Client">Client Credit</mat-option>
                        </mat-select>

                        </mat-form-field>
                </div>
                <div class="one invoice_receipt_main_div">
                    <div class="example-containerdata">
                        <table mat-table #table
                            [dataSource]="PrepareReceiptData"
                            [@animateStagger]="{value:'50'}" matSort
                            class="mat-elevation-z8">
                            <ng-container matColumnDef="INVOICECODE">
                                <th mat-header-cell *matHeaderCellDef
                                    mat-sort-header>Invoice</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="job-title text-truncate">
                                        <span>
                                            {{row.INVOICECODE}}
                                        </span>
                                    </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="INVOICETOTAL">
                                <th mat-header-cell *matHeaderCellDef
                                    mat-sort-header>Total</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="company text-truncate">
                                        <span>
                                            <!-- {{ row.INVOICETOTALINCGST | toFixedAmount:true}} -->
                                            {{ (action != 'edit' && action !='editForTB')
                                            ? row.INVOICETOTALINCGST :
                                            row.INVOICETOTAL |
                                            toFixedAmount:true}}
                                        </span>
                                    </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="AMOUNTOUTSTANDINGINCGST">
                                <th mat-header-cell *matHeaderCellDef
                                    mat-sort-header>Outstanding</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="company text-truncate">
                                        <span>

                                            <!-- {{  row.AMOUNTOUTSTANDINGINCGST  | toFixedAmount:true}} -->
                                            {{ (action != 'edit' && action !='editForTB')
                                            ? row.AMOUNTOUTSTANDINGINCGST
                                            :row.INVOICEOUTSTANDING |
                                            toFixedAmount:true}}
                                        </span>
                                    </p>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="action == 'add'"
                                matColumnDef="ALLOCATED">
                                <th mat-header-cell *matHeaderCellDef
                                    mat-sort-header>Allocated</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="company text-truncate">
                                        <span *ngIf="row.ALLOCATED">
                                            ${{row.ALLOCATED}}
                                        </span>
                                        <span *ngIf="!row.ALLOCATED">
                                            $0.00
                                        </span>
                                    </p>
                                </td>
                            </ng-container>

                            <ng-container *ngIf="action != 'add'"
                                matColumnDef="ALLOCATED">
                                <th mat-header-cell *matHeaderCellDef
                                    mat-sort-header>Allocated</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="company text-truncate">
                                        <span>
                                            ${{row.AMOUNT}}
                                        </span>
                                    </p>
                                </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="ALLOCATED">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Allocated</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="company text-truncate">
                                        <span *ngIf="row.ALLOCATED">
                                        {{ (action != 'edit' && action !='editForTB') ?  row.ALLOCATED :  row.AMOUNT  }}
                                       </span>
                                        <span *ngIf="!row.ALLOCATED && (action != 'edit' && action !='editForTB') ">
                                            0.00
                                       </span>
                                    </p>
                                </td>
                            </ng-container> -->

                            <ng-container matColumnDef="MATTERGUID">
                                <th mat-header-cell *matHeaderCellDef
                                    mat-sort-header>Matter</th>
                                <td mat-cell *matCellDef="let row">
                                    <p class="company text-truncate">
                                        <span>
                                            {{ row.SHORTNAME }}
                                        </span>
                                    </p>
                                </td>
                            </ng-container>
                            <tr mat-header-row class="tbl_fix_header"
                                *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <!-- sticky: true" -->
                            <tr mat-row
                                *matRowDef="let row; columns: displayedColumns;"
                                [style.background]="highlightedRows == row?.INVOICEGUID ? selectedColore : ''"
                                [ngClass]="highlightedRows == row?.INVOICEGUID ? 'row-text-colore' : ''"
                                class="contact"
                                (click)="clickRow(row);highlightedRows = row?.INVOICEGUID"
                                matRipple
                                [@animate]="{value:'*',params:{y:'100%'}}">
                            </tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]"
                        showFirstLastButtons></mat-paginator>
                </div>
                <div *ngIf="action != 'edit' && action !='editForTB'"
                    fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto" class="unallocated_div">
                    <p fxFlex="50"> Enter the amount to allocate to the selected
                        invoice and press tab</p>
                    <mat-form-field appearance="outline" fxFlex="35"
                        class="pr-4">
                        <input [value]="PrepareReceiptForm.get('allocatedSelected').value | number: '1.2-2'" style="text-align: right;" formControlName="allocatedSelected"
                            matInput placeholder="Unallocated" type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [leadZero]="true">
                            <!-- [value]="PrepareReceiptForm.get('allocatedSelected').value | number: '1.2-2'" -->
                        <!-- <span class="currency_sign" matPrefix>$&nbsp;</span>
                        <mat-icon matSuffix class="secondary-text">currency</mat-icon> -->
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>

                    <button fxFlex="15" class="buttonsize" mat-raised-button
                        color="accent" (click)="ApplyReceipt()">Apply</button>
                </div>

            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button *ngIf="action != 'edit' && action !='editForTB'"
            mat-raised-button color="accent" (click)="SaveReceipt()"
            [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>
<!-- </form> -->

<div *ngIf="Warnpopup =='show'" cdkDrag
    class="popup_main_div add_edit_receipt_main">
    <h2 cdkDragHandle mat-dialog-title>Prepare Receipt
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <div fxLayout="row" fxFlex="1 0 auto">
                <div class="pr-4" fxLayout="column" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <div *ngFor="let val of warndataGet" fxFlex="70"
                        fxLayoutAlign="start start">
                        <p>{{val}}</p>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent"
            [mat-dialog-close]="'matter'" cdkFocusInitial>Matter</button>
        <button mat-raised-button color="accent" class="mat-accent"
            [mat-dialog-close]="'frim'" cdkFocusInitial>{{isProduct}}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>

<div *ngIf="whichTempGenerate =='matter' || whichTempGenerate =='frim'" cdkDrag
    class="popup_main_div add_edit_receipt_main">
    <h2 cdkDragHandle mat-dialog-title>Prepare Receipt
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start"
            fxLayout.gt-md="row">
            <div fxLayout="row" fxFlex="1 0 auto">
                <div class="pr-4" fxLayout="column" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <div fxFlex="70" fxLayoutAlign="start start">
                        <p>Do you want to generate a copy of the receipt? </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent"
            (click)="GenerateDoc(INCOMEGUID)" [mat-dialog-close]="true"
            cdkFocusInitial>Yes</button>
        <button mat-raised-button color="primary" (click)="closeAllDialoge()"
            [mat-dialog-close]="false" cdkFocusInitial>No</button>
    </mat-dialog-actions>
</div>
