import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dialog-box',
    templateUrl: './dialog-box.component.html',
    styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {

    constructor() { }

       /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
    }

}
