<form name="DebtRecoveryForm" id="DebtRecoveryForm" [formGroup]="DebtRecoveryForm" fxLayout="column"
    fxLayoutAlign="start" class="one" fxFlex="1 0 auto">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DEBTAMOUNT,'has-warning':errorWarningData.Warning?.DEBTAMOUNT}">
            <mat-label>debt Amount</mat-label>
            <input style="text-align: right;" [value]="DebtRecoveryForm.get('DEBTAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." (focusout)="changeValue($event.target.value,'number')" matInput formControlName="DEBTAMOUNT" matTooltip="{{(toolTipList)?toolTipList.DEBTAMOUNT?.HOVER:''}}" [leadZero]="true"
            [matTooltipPosition]="toolTipPostion">
              <div  *ngIf="testBool && toolTipList.DEBTAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DEBTAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEBTAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.AMOUNTRECOVERED,'has-warning':errorWarningData.Warning?.AMOUNTRECOVERED}">
            <mat-label>Amount Recovered</mat-label>
            <input style="text-align: right;" [value]="DebtRecoveryForm.get('AMOUNTRECOVERED').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true" (focusout)="changeValue($event.target.value,'number')" matInput formControlName="AMOUNTRECOVERED" matTooltip="{{(toolTipList)?toolTipList.AMOUNTRECOVERED?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
              <div  *ngIf="testBool && toolTipList.AMOUNTRECOVERED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.AMOUNTRECOVERED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNTRECOVERED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DEBTDUEDATE,'has-warning':errorWarningData.Warning?.DEBTDUEDATE}">
            <mat-label>Debt Due Date</mat-label>
            <input (dateInput)="DebtdueDateSelect('input', $event)" (dateChange)="DebtdueDateSelect('change', $event)" 
                matInput [matDatepicker]="DEBTDUEDATE" formControlName="DEBTDUEDATETEXT" matTooltip="{{(toolTipList)?toolTipList.DEBTDUEDATETEXT?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DEBTDUEDATE"></mat-datepicker-toggle>
            <mat-datepicker #DEBTDUEDATE></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.DEBTDUEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DEBTDUEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEBTDUEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.BALANCEOWING,'has-warning':errorWarningData.Warning?.BALANCEOWING}">
            <mat-label>Balance Owing</mat-label>
            <input (focusout)="changeValue($event.target.value,'number')" matInput formControlName="BALANCEOWING" currencyMask readonly matTooltip="{{(toolTipList)?toolTipList.BALANCEOWING?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion">
              <div  *ngIf="testBool && toolTipList.BALANCEOWING" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.BALANCEOWING.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BALANCEOWING?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INTERESTRATETYPE,'has-warning':errorWarningData.Warning?.INTERESTRATETYPE}">
            <mat-label>Interest Type</mat-label>
            <mat-select name="INTERESTRATETYPE" formControlName="INTERESTRATETYPE" (selectionChange)="changeValue($event.value,'string')">
                <mat-option value="Compound">Compound</mat-option>
                <mat-option value="Simple">Simple</mat-option>
            </mat-select>
                  <div  *ngIf="testBool && toolTipList.INTERESTRATETYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INTERESTRATETYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTRATETYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DEFAULTJUDGEMENTDATE,'has-warning':errorWarningData.Warning?.DEFAULTJUDGEMENTDATE}">
            <mat-label>Default Judgement Date</mat-label>
            <input (dateInput)="DefaultjudgementDateSelect('input', $event)"
                (dateChange)="DefaultjudgementDateSelect('change', $event)" matInput
                [matDatepicker]="DEFAULTJUDGEMENTDATE" formControlName="DEFAULTJUDGEMENTDATETEXT" matTooltip="{{(toolTipList)?toolTipList.DEFAULTJUDGEMENTDATE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <mat-datepicker-toggle matSuffix [for]="DEFAULTJUDGEMENTDATE"></mat-datepicker-toggle>
            <mat-datepicker #DEFAULTJUDGEMENTDATE></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.DEFAULTJUDGEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DEFAULTJUDGEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEFAULTJUDGEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INTERESTRATE,'has-warning':errorWarningData.Warning?.INTERESTRATE}">
            <mat-label>Interest Rate</mat-label>
            <input (focusout)="changeValue($event.target.value,'percent', 'INTERESTRATE')" matInput formControlName="INTERESTRATE" matTooltip="{{(toolTipList)?toolTipList.INTERESTRATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" >
              <div  *ngIf="testBool && toolTipList.INTERESTRATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INTERESTRATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTRATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.DJINTERESTRATE,'has-warning':errorWarningData.Warning?.DJINTERESTRATE}">
            <mat-label>DJ Interest Rate</mat-label>
            <input (focusout)="changeValue($event.target.value,'percent', 'DJINTERESTRATE')" matInput matTooltip="{{(toolTipList)?toolTipList.INTERESTRATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="DJINTERESTRATE" >
              <div  *ngIf="testBool && toolTipList.DJINTERESTRATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DJINTERESTRATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DJINTERESTRATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INTERESTENDDATE,'has-warning':errorWarningData.Warning?.INTERESTENDDATE}">
            <mat-label>Interest End Date</mat-label>
            <input (dateInput)="InterestndDateSelect('input', $event)"
                (dateChange)="InterestndDateSelect('change', $event)" matInput [matDatepicker]="INTERESTENDDATE"
                matTooltip="{{(toolTipList)?toolTipList.INTERESTENDDATETEXT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="INTERESTENDDATETEXT">
            <mat-datepicker-toggle matSuffix [for]="INTERESTENDDATE"></mat-datepicker-toggle>
            <mat-datepicker #INTERESTENDDATE></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.INTERESTENDDATE " class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INTERESTENDDATE .COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTENDDATE ?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.FINALPAYMENTDATE,'has-warning':errorWarningData.Warning?.FINALPAYMENTDATE}">
            <mat-label>Final Payment Date</mat-label>
            <input (dateInput)="FinalpaymentDateSelect('input', $event)"
                (dateChange)="FinalpaymentDateSelect('change', $event)" matInput [matDatepicker]="FINALPAYMENTDATE"
                matTooltip="{{(toolTipList)?toolTipList.INTERESTRATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="FINALPAYMENTDATETEXT">
            <mat-datepicker-toggle matSuffix [for]="FINALPAYMENTDATE"></mat-datepicker-toggle>
            <mat-datepicker #FINALPAYMENTDATE></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.FINALPAYMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FINALPAYMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FINALPAYMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INTERESTTODATE,'has-warning':errorWarningData.Warning?.INTERESTTODATE}">
            <mat-label>Interest To Date</mat-label>
            <input (focusout)="changeValue($event.target.value,'number')" matInput matTooltip="{{(toolTipList)?toolTipList.INTERESTTODATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="INTERESTTODATE" style="text-align: right;" [value]="DebtRecoveryForm.get('INTERESTTODATE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.INTERESTTODATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INTERESTTODATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTTODATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.INTERESTSINCEDEFAULTJUDGEMENT,'has-warning':errorWarningData.Warning?.INTERESTSINCEDEFAULTJUDGEMENT}">
            <mat-label>Interest Since DJ</mat-label>
            <input  (focusout)="changeValue($event.target.value,'number')" matInput matTooltip="{{(toolTipList)?toolTipList.INTERESTSINCEDEFAULTJUDGEMENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="INTERESTSINCEDEFAULTJUDGEMENT" style="text-align: right;" [value]="DebtRecoveryForm.get('INTERESTSINCEDEFAULTJUDGEMENT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.INTERESTSINCEDEFAULTJUDGEMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INTERESTSINCEDEFAULTJUDGEMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INTERESTSINCEDEFAULTJUDGEMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.FILINGFEES,'has-warning':errorWarningData.Warning?.FILINGFEES}">
            <mat-label>Filing Fees</mat-label>
            <input (focusout)="changeValue($event.target.value,'number')" matInput matTooltip="{{(toolTipList)?toolTipList.FILINGFEES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="FILINGFEES" style="text-align: right;" [value]="DebtRecoveryForm.get('FILINGFEES').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.FILINGFEES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FILINGFEES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FILINGFEES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALSERVICEFEES,'has-warning':errorWarningData.Warning?.ADDITIONALSERVICEFEES}">
            <mat-label>Addit. Service Fees</mat-label>
            <input  (focusout)="changeValue($event.target.value,'number')" matInput matTooltip="{{(toolTipList)?toolTipList.ADDITIONALSERVICEFEES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="ADDITIONALSERVICEFEES" style="text-align: right;" [value]="DebtRecoveryForm.get('ADDITIONALSERVICEFEES').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.ADDITIONALSERVICEFEES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ADDITIONALSERVICEFEES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALSERVICEFEES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SERVICEFEES,'has-warning':errorWarningData.Warning?.SERVICEFEES}">
            <mat-label>Service Fees</mat-label>
            <input (focusout)="changeValue($event.target.value,'number')" matInput matTooltip="{{(toolTipList)?toolTipList.SERVICEFEES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="SERVICEFEES" style="text-align: right;" [value]="DebtRecoveryForm.get('SERVICEFEES').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.SERVICEFEES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SERVICEFEES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SERVICEFEES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.GARNISHEETOTAL,'has-warning':errorWarningData.Warning?.GARNISHEETOTAL}">
            <mat-label>Garnishee Total</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.GARNISHEETOTAL?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="GARNISHEETOTAL" currencyMask readonly>
              <div  *ngIf="testBool && toolTipList.GARNISHEETOTAL" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.GARNISHEETOTAL.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GARNISHEETOTAL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SOLICITORSFEES,'has-warning':errorWarningData.Warning?.SOLICITORSFEES}">
            <mat-label>Solicitors Fees</mat-label>
            <input (focusout)="changeValue($event.target.value,'number')" matInput matTooltip="{{(toolTipList)?toolTipList.SOLICITORSFEES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="SOLICITORSFEES" style="text-align: right;" [value]="DebtRecoveryForm.get('SOLICITORSFEES').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
              <div  *ngIf="testBool && toolTipList.SOLICITORSFEES" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SOLICITORSFEES.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SOLICITORSFEES?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.TOTALDEBT,'has-warning':errorWarningData.Warning?.TOTALDEBT}">
            <mat-label>Total Debt</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.TOTALDEBT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="TOTALDEBT" currencyMask readonly>
              <div  *ngIf="testBool && toolTipList.TOTALDEBT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TOTALDEBT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOTALDEBT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.REVIEWDATE,'has-warning':errorWarningData.Warning?.REVIEWDATE}">
            <mat-label>Review Date</mat-label>
            <input (dateInput)="ReviewDateSelect('input', $event)" (dateChange)="ReviewDateSelect('change', $event)"
                matInput [matDatepicker]="REVIEWDATE" matTooltip="{{(toolTipList)?toolTipList.REVIEWDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="REVIEWDATETEXT">
            <mat-datepicker-toggle matSuffix [for]="REVIEWDATE"></mat-datepicker-toggle>
            <mat-datepicker #REVIEWDATE></mat-datepicker>
              <div  *ngIf="testBool && toolTipList.REVIEWDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REVIEWDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REVIEWDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100"
            [ngClass]="{'has-error':errorWarningData.Error?.REPAYMENTDETAILS,'has-warning':errorWarningData.Warning?.REPAYMENTDETAILS}">
            <mat-label>Repayment Details</mat-label>
            <textarea matInput matTooltip="{{(toolTipList)?toolTipList.REPAYMENTDETAILS?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" formControlName="REPAYMENTDETAILS"></textarea>
              <div  *ngIf="testBool && toolTipList.REPAYMENTDETAILS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REPAYMENTDETAILS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REPAYMENTDETAILS?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</form>