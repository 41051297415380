import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-maritime',
    templateUrl: './maritime.component.html',
    styleUrls: ['./maritime.component.scss']
})
export class MaritimeComponent implements OnInit, OnDestroy {

    @Input() errorWarningData: any;
    @Input() testBool;
    @Input() toolTipList: any;
    @Input() action: any;
    @Input() classType: any;
    toolTipPostion = "above";
    MariTimeForm: FormGroup
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    MatterData: any = {};
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private datepipe: DatePipe,
        private _formBuilder: FormBuilder,
        private _mainAPiServiceService: MainAPiServiceService,
        public MatDialog: MatDialog,
        public _matDialog: MatDialog,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData()
    }
    initFormData() {
        this.MariTimeForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERVESSELGUID: [''],
            VESSELNAME: [''],
            VESSELFLAG: [''],
            VESSELTYPE: [''],
            TONNAGE: [''],
            VESSELMASTER: [''],
            VESSELLOCATION: [''],
            // PURCHASEPRICE : [''],
            INCIDENTDATE: [''],
            INCIDENTDATEText: [''],
            // EXCHANGEDATE : [''],
            // EXCHANGEDATEText:[''],
            // SETTLEMENTDATE:[''],
            // SETTLEMENTDATEText:['']
        });
    }
    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();

    }
    ngOnInit() {
        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.MariTimeForm.controls['MATTERGUID'].setValue('');
                this.MariTimeForm.controls['MATTERVESSELGUID'].setValue('');
                this.MariTimeForm.controls['VESSELNAME'].setValue('');
                this.MariTimeForm.controls['VESSELFLAG'].setValue('');
                this.MariTimeForm.controls['VESSELTYPE'].setValue('');
                this.MariTimeForm.controls['TONNAGE'].setValue('');
                this.MariTimeForm.controls['VESSELMASTER'].setValue('');
                this.MariTimeForm.controls['VESSELLOCATION'].setValue('');
                this.MariTimeForm.controls['PURCHASEPRICE'].setValue('');

                this.MariTimeForm.controls['INCIDENTDATE'].setValue('');
                this.MariTimeForm.controls['INCIDENTDATEText'].setValue('');

                this.MariTimeForm.controls['EXCHANGEDATE'].setValue('');
                this.MariTimeForm.controls['EXCHANGEDATEText'].setValue('');

                this.MariTimeForm.controls['SETTLEMENTDATE'].setValue('');
                this.MariTimeForm.controls['SETTLEMENTDATEText'].setValue('');


            }
        });

        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MariTimeForm.controls['MATTERGUID'].setValue(result.MATTERGUID);

                if (result.classType == "Maritime" && result.action == 'setData') {
                    this.SaveExportProcess();
                } else if (result.classType == "Maritime" && result.action == 'deleteData') {
                    this.DeleteExportProcess();
                }
            }
        });

        if (this.action == 'edit' || this.action == 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-vessel').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterCommercialData = response.DATA.RECORDS[0];
                    if (MatterCommercialData) {
                        this.MariTimeForm.patchValue({
                            MATTERGUID: MatterCommercialData.MATTERGUID,
                            MATTERVESSELGUID: MatterCommercialData.MATTERVESSELGUID,
                            VESSELNAME: MatterCommercialData.VESSELNAME,
                            VESSELFLAG: MatterCommercialData.VESSELFLAG,
                            VESSELTYPE: MatterCommercialData.VESSELTYPE,
                            TONNAGE: MatterCommercialData.TONNAGE,
                            VESSELMASTER: MatterCommercialData.VESSELMASTER,
                            VESSELLOCATION: MatterCommercialData.VESSELLOCATION,
                            PURCHASEPRICE: MatterCommercialData.PURCHASEPRICE,
                        });

                        if (MatterCommercialData?.INCIDENTDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.INCIDENTDATE.split("/");
                            this.MariTimeForm.patchValue({
                                INCIDENTDATEText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                INCIDENTDATE: MatterCommercialData.INCIDENTDATE
                            });
                        }
                        if (MatterCommercialData?.EXCHANGEDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.EXCHANGEDATE.split("/");
                            this.MariTimeForm.patchValue({
                                EXCHANGEDATEText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                EXCHANGEDATE: MatterCommercialData.EXCHANGEDATE
                            });
                        }
                        if (MatterCommercialData?.SETTLEMENTDATE) {
                            let TEMPFAMILYCOHABITATIONDATE = MatterCommercialData.SETTLEMENTDATE.split("/");
                            this.MariTimeForm.patchValue({
                                SETTLEMENTDATEText: new Date(TEMPFAMILYCOHABITATIONDATE[1] + '/' + TEMPFAMILYCOHABITATIONDATE[0] + '/' + TEMPFAMILYCOHABITATIONDATE[2]),
                                SETTLEMENTDATE: MatterCommercialData.SETTLEMENTDATE
                            });
                        }

                    }
                }
            });
        }
    }
    // SettlementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.MariTimeForm.controls['SETTLEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }
    // ExchangeDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.MariTimeForm.controls['EXCHANGEDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }
    IncidentDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MariTimeForm.controls['INCIDENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }

    SaveExportProcess() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MariTimeForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERVESSELGUID;
        }
        formData.MATTERGUID = this.MatterData.MATTERGUID;
        // delete formData.SETTLEMENTDATEText;
        // delete formData.EXCHANGEDATEText;
        delete formData.INCIDENTDATEText;

        const formAction = (this.action == 'edit' && formData.MATTERVESSELGUID) ? 'Update' : 'Insert';
        const MatterCommercialData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(MatterCommercialData, 'matter-vessel').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveExportProcessAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveExportProcessAfterVAlidation(details);
        }
    }
    SaveExportProcessAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'matter-vessel').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    DeleteExportProcess() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-vessel').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterCommercialData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERVESSELGUID: MatterCommercialData.MATTERVESSELGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-vessel').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

}
