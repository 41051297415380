<div class="navbar-top sidebar_main_div"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <!-- <div class="logo">
        <img class="logo-icon" src="assets/images/logos/sm-silq.png">
        <span class="logo-text secondary-text">SILQ</span>
    </div> -->
    <div class="navtop-block">
        <div class="navtopwrap">
            <div class="buttons">
                <button mat-icon-button class="toggle-sidebar-folded"
                    (click)="toggleSidebarFolded()" fxHide.lt-lg>
                    <mat-icon class="secondary-text" style="margin-left: 16px;">menu</mat-icon>
                </button>

                <button mat-icon-button class="toggle-sidebar-opened"
                    (click)="toggleSidebarOpened()" fxHide.gt-md>
                    <mat-icon class="secondary-text">arrow_back</mat-icon>
                </button>
            </div>
            <div class="logo barrister" *ngIf="defultTheme">
                <button mat-icon-button class="fold_sidebar_logo"
                    (click)="toggleSidebarFolded()" fxHide.lt-lg>
                    <mat-icon class="secondary-text" style="margin-left: 16px;">menu</mat-icon>
                </button>
                <!-- <img class="logo-icon unfold_sidebar_logo" src="assets/images/logos/silq-logo.png">
                <img class="logo-icon fold_sidebar_logo" src="assets/images/logos/sm-silq-small.png"> -->

                <div class="nav-logo logo-icon unfold_sidebar_logo"><img
                        class="logo-icon unfold_sidebar_logo"
                        src="assets/images/logos/sm-silq-small.png">
                    <!-- <span class="logo-textblock" *ngIf="currentUser">{{currentUser.UserName}}</span> -->
                    <span class="logo-textblock" *ngIf="getperefrence"> {{getperefrence.USERFULLNAME}}</span>
                </div>
                <img class="logo-icon fold_sidebar_logo"
                    src="assets/images/logos/sm-silq-small.png">

            </div>

            <!-- user 2 -->

            <div class="logo solicitor" *ngIf="greenTheme">
                <!-- this button display after menu is minimize -->
                <button mat-icon-button class="fold_sidebar_logo"
                    (click)="toggleSidebarFolded()" fxHide.lt-lg>
                    <mat-icon class="secondary-text" style="margin-left: 16px;">menu</mat-icon>
                </button>
                <div class="nav-logo logo-icon unfold_sidebar_logo"><img
                        class="logo-icon unfold_sidebar_logo"
                        src="assets/images/logos/sm-silq-small-user2.png">
                    <!-- <span class="logo-textblock" *ngIf="currentUser"> {{currentUser.UserName}}</span> -->
                    <span class="logo-textblock" *ngIf="getperefrence"> {{getperefrence.USERFULLNAME}}</span>
                </div>
                <img class="logo-icon fold_sidebar_logo"
                    src="assets/images/logos/sm-silq-small-user2.png">
            </div>
        </div>

    </div>
    <span class="nav-authemail logo-icon unfold_sidebar_logo"
        *ngIf="currentUser">{{currentUser.EXPORTACCOUNTSEMAIL}}</span>
    <span class="navcaption logo-icon unfold_sidebar_logo" *ngIf="getperefrence">{{getperefrence.BUSINESSNAME}}</span>
</div>

<div class="navbar-scroll-container sidebar_btm_div"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <!-- <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <div class="h3 username">Charlie Adams</div>
        <div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg">
        </div>
    </div> -->
    <div class="navbar-content sidebar-footer-search">
        <fuse-navigation style="padding-bottom: 20px;" class="material2"
            layout="vertical"></fuse-navigation>
    </div>

</div>
