
import { AccountManagmentModule } from '../account-managment/account-managment.module';
import { AuthGuard } from 'src/app/_guards';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommonModule } from '@angular/common';
import { ConnectionComponent } from './connection/connection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSidebarModule } from "../../../../@fuse/components/sidebar/sidebar.module";
import { GeneralComponent } from './general/general.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MyProfileComponent } from './my-profile.component';
import { NgModule } from '@angular/core';
import { PreferencesComponent } from './preferences/preferences.component';
import { RouterModule, Routes } from '@angular/router';
import { SecurityComponent } from './security/security.component';
import { SharedModule } from 'src/app/Shared/shared.module';
import { UserActivityComponent } from '../account-managment/user-activity/user-activity.component';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { PipesModule } from '@_pipes/pipes.module';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    { path: '', redirectTo: '/my-profile/general', pathMatch: 'full', canActivate: [AuthGuard] },
    {
        path: '', component: MyProfileComponent, children: [
            {
                path: 'general', component: GeneralComponent, data: {
                    title: 'My Profile - General'
                }
            },
            {
                path: 'security', component: SecurityComponent, data: {
                    title: 'My Profile - Security'
                }
            },
            {
                path: 'preferences', component: PreferencesComponent, data: {
                    title: 'My Profile - Preferences'
                }
            },
            {
                path: 'useractivity', component: UserActivityComponent, data: {
                    title: 'My Profile - UserActivity'
                }
            },
            {
                path: 'connections', component: ConnectionComponent, data: {
                    title: 'My Profile - Connections'
                }
            },
        ]
    },
]

@NgModule({
    declarations: [
        MyProfileComponent,
        PreferencesComponent,
        GeneralComponent,
        SecurityComponent,
        ConnectionComponent,

    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        FuseSidebarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSelectModule,
        MatButtonModule,
        FuseSidebarModule,
        MatButtonToggleModule,
        CKEditorModule,
        RouterModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        AccountManagmentModule,
        MatCheckboxModule,
        PipesModule
    ],
    providers: [
        TierPermissionPipe
    ]
})
export class MyProfileModule { }
