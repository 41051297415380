import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { round } from 'lodash';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-rates-overrides-dialog',
    templateUrl: './rates-overrides-dialog.component.html',
    styleUrls: ['./rates-overrides-dialog.component.scss']
})
export class RatesOverridesDialogComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    isLoadingResults: boolean = false;
    isspiner: boolean = false;
    isNotValid: boolean = false;
    action: any;
    dialogTitle: string = "Update Override Rates";
    labelName: string
    GstName: string
    formAction: string;
    MatterRateForm: FormGroup;
    errorWarningData: any = {};
    rateUser: any = [];
    rateActivity: any = [];
    setAPiURl: string = "";
    closeApiURL: string = "";
    apiURL: string = "";
    GSTTypeData = ['GST Exclusive', 'GST Inclusive', 'GST Free'];
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private _formBuilder: FormBuilder,
        public _mainAPiServiceService: MainAPiServiceService,
        @Inject(MAT_DIALOG_DATA) public PopupData: any,
        private toastr: ToastrService,
        public _matDialog: MatDialog,
        public behaviorService: BehaviorService,
        public dialogRefRate: MatDialogRef<RatesOverridesDialogComponent>,
    ) {

        localStorage.setItem('istrackid', 'AddRateComponent');
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((result) => {
                if (localStorage.getItem('istrackid') == 'AddRateComponent' && result['click'] && (result['data']['GetUsers'] || result['data']['activity-rate'])) {
                    this.ngOnInit();
                }
                if (localStorage.getItem('istrackid') == 'AddRateComponent' && result['click'] && (result['data']['user-rate'] || result['data']['activity-rate'])) {
                    this.SaveRates();
                }
            });

    }

    /**
     * This function is used to Unsubscribe the observables after the destreyd the component.
     */
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        // this.isLoadingResults = true;
        this.MatterRateForm = this._formBuilder.group({
            USERRATEGUID: [''],
            ACTIVITYRATEGUID: [''],
            RATETYPE: [this.PopupData.rateType],
            LINKGUID: [this.PopupData.data.MATTERGUID],
            LINKDESC: [this.PopupData.data.LINKDESC],
            USERID: [''],
            GSTTYPE: ['GST Exclusive'],
            RATEPERHOUR: [''],
            RATEPERUNIT: [''],
            RATEPERDAY: [''],
            rateTypeVl: [1],
            userVal: [''],
            RATEPERHOUR_1: [''],
            RATEPERHOUR_2: [''],
            RATEPERHOUR_3: [''],
            RATEPERHOUR_4: [''],
            RATEPERHOUR_5: [''],
            RATEPERHOUR_6: [''],
            RATEPERDAY_1: [''],
            RATEPERDAY_2: [''],
            RATEPERDAY_3: [''],
            RATEPERDAY_4: [''],
            RATEPERDAY_5: [''],
            RATEPERDAY_6: ['']
        });

        //this.getUserDrop(this.PopupData.type);
        this.formAction = 'update';
        this.getEditData();
        this.MatterRateForm.controls['LINKDESC'].disable();
    };

/**
 * This Function is used to edit the rates-overrides data
 */
    getEditData():void {
        let rateType = "";
        let postData: any = "";
        postData = { USERRATEGUID: this.PopupData.data.USERRATEGUID };
        rateType = 'user-rate';

        let payload: any = {
            "Action": "GetData",
        };
        payload.Filters = postData;
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData(payload, rateType).pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(responses => {
                if (responses.CODE == 200 && responses.STATUS == 'success') {
                    if (rateType == 'user-rate') {
                        let UserRateData: any = responses.DATA.RECORDS[0];
                        const indexData = this.rateUser.findIndex(rates => rates.USERID === UserRateData.USERID);
                        this.MatterRateForm.patchValue({
                            USERRATEGUID: UserRateData.USERRATEGUID,
                            RATETYPE: UserRateData.RATETYPE,
                            MATTERGUID: UserRateData.MATTERGUID,
                            USERID: UserRateData.USERID,
                            userVal: indexData,
                            GSTTYPE: UserRateData.GSTTYPE,
                            RATEPERHOUR_1: round(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_1).toFixed(2),
                            RATEPERHOUR_2: round(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_2).toFixed(2),
                            RATEPERHOUR_3: round(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_3).toFixed(2),
                            RATEPERHOUR_4: round(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_4).toFixed(2),
                            RATEPERHOUR_5: round(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_5).toFixed(2),
                            RATEPERHOUR_6: round(UserRateData.RATEPERHOUR_GROUP.RATEPERHOUR_6).toFixed(2),

                            RATEPERDAY_1: round(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_1).toFixed(2),
                            RATEPERDAY_2: round(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_2).toFixed(2),
                            RATEPERDAY_3: round(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_3).toFixed(2),
                            RATEPERDAY_4: round(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_4).toFixed(2),
                            RATEPERDAY_5: round(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_5).toFixed(2),
                            RATEPERDAY_6: round(UserRateData.RATEPERDAY_GROUP.RATEPERDAY_6).toFixed(2),
                            LINKDESC: UserRateData.LINKDESC,
                            LINKGUID: UserRateData.MATTERGUID

                        });
                    }
                    this.isLoadingResults = false;
                    this.setAPiURl = 'user-rate';
                }
                this.isLoadingResults = false;
            });
    };

    getUserDrop(type) {

    };

    get f() {
        return this.MatterRateForm.controls;
    };

    /**
     * This function is used to get the selected label
     * @returns -it's  return the selected label
     */
    getSelectedLabel() :void{
        let index = this.f.userVal.value;
        return this.rateUser[index];
    };

/**
 * This function is used to save the rates data
 */
    SaveRates():void {
        const User: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.isspiner = true;
        let details: any = {
            RATETYPE: this.f.RATETYPE.value,
            LINKGUID: this.f.LINKGUID.value,
            GSTTYPE: this.f.GSTTYPE.value,
        };



        if (this.PopupData.type == 1) {
            details.USERID = this.f.USERID.value;
            details.RATEPERHOUR_1 = this.f.RATEPERHOUR_1.value;
            details.RATEPERHOUR_2 = this.f.RATEPERHOUR_2.value;
            details.RATEPERHOUR_3 = this.f.RATEPERHOUR_3.value;
            details.RATEPERHOUR_4 = this.f.RATEPERHOUR_4.value;
            details.RATEPERHOUR_5 = this.f.RATEPERHOUR_5.value;
            details.RATEPERHOUR_6 = this.f.RATEPERHOUR_6.value;

            details.RATEPERDAY_1 = this.f.RATEPERDAY_1.value;
            details.RATEPERDAY_2 = this.f.RATEPERDAY_2.value;
            details.RATEPERDAY_3 = this.f.RATEPERDAY_3.value;
            details.RATEPERDAY_4 = this.f.RATEPERDAY_4.value;
            details.RATEPERDAY_5 = this.f.RATEPERDAY_5.value;
            details.RATEPERDAY_6 = this.f.RATEPERDAY_6.value;

        } else {
            details.ACTIVITYGUID = this.f.USERID.value;
            details.RATEPERUNIT = this.f.RATEPERUNIT.value;
        }
        if (this.PopupData.isEditRate) {
            details.APIUrl = this.setAPiURl;
            details.type = this.PopupData.type;
            this.dialogRefRate.close(details);
        } else {
            if (this.formAction != 'new' && this.PopupData.type == 1) {
                details.USERRATEGUID = this.f.USERRATEGUID.value;
            } else if (this.formAction != 'new' && this.PopupData.type != 1) {
                details.ACTIVITYRATEGUID = this.f.ACTIVITYRATEGUID.value;
            }
            let matterPostData: any = { ACTION: this.formAction, VALIDATEONLY: true, Data: details };


            matterPostData.Data.USERID = this.PopupData.data.USERID;
            // matterPostData.Data.LINKDESC = this.f.LINKDESC.value;

            this._mainAPiServiceService.getSetData(matterPostData, this.setAPiURl).pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(response => {
                    matterPostData.VALIDATEONLY = true;
                    if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                        this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
                    } else if (response.CODE == 451 && response.STATUS == 'warning') {
                        this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
                    } else if (response.CODE == 450 && response.STATUS == 'error') {
                        this.checkValidation(response.DATA.VALIDATIONS, matterPostData);
                    } else if (response.MESSAGE == 'Not logged in') {
                        this.dialogRefRate.close(false);
                    }
                }, error => {
                    this.isspiner = false;
                    this.toastr.error(error);
                });
        }
    };

/**
 * This function is used to check the validation
 * @param bodyData -form data
 * @param details -value details
 */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.isspiner = false;
        } else if (Object.keys(warningData).length != 0) {
            this.isspiner = false;
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isspiner = true;
                    this.saveRateData(details);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.saveRateData(details);
        }
    };

/**
 * This function is used to set the Rate data.
 * @param data -rate data.
 */
    saveRateData(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, this.setAPiURl).pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(response => {
                if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                    if (this.formAction != 'new') {
                        this.toastr.success('Rate update successfully');
                    } else {
                        this.toastr.success('Rate save successfully');
                    }
                    //this.dialogRefRate.close(this.closeApiURL);
                    this.dialogRefRate.close(response);
                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                    this.toastr.warning(response.MESSAGE);
                    this.isspiner = false;
                } else if (response.CODE == 450 && response.STATUS == 'error') {
                    this.toastr.error(response.MESSAGE);
                    this.isspiner = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRefRate.close(false);
                }
            }, error => {
                this.isspiner = false;
                this.toastr.error(error);
                this.dialogRefRate.close(true);

            });
    };

    /**
     * This function is used to set the class type Activity
     * @param event -class type activity data.
     */
    ClasstypeActivity(event) {
        const activityData = this.rateActivity[event];
        this.MatterRateForm.controls['USERID'].setValue(activityData.ACTIVITYGUID);
        if (activityData.GSTTYPE) {
            this.MatterRateForm.controls['GSTTYPE'].setValue(this.GSTTypeData[activityData.GSTTYPE]);
        }
        if (activityData.RATEPERUNIT) {
            this.MatterRateForm.controls['RATEPERUNIT'].setValue(activityData.RATEPERUNIT);
        }

    }

    /**
     * This function is used to set the class type data
     * @param event -class type value
     */
    Classtype(event):void {
        const userDat: any = this.rateUser[event];
        if (userDat.USERID) {
            this.isNotValid = false;
            this.MatterRateForm.controls['USERID'].setValue(userDat.USERID);
            this.MatterRateForm.controls['GSTTYPE'].setValue(this.GSTTypeData[userDat.GSTTYPE]);
            if (userDat.RATEPERHOUR_GROUP && userDat.RATEPERDAY_GROUP) {
                this.MatterRateForm.patchValue({
                    RATEPERHOUR_1: round(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_1).toFixed(2),
                    RATEPERHOUR_2: round(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_2).toFixed(2),
                    RATEPERHOUR_3: round(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_3).toFixed(2),
                    RATEPERHOUR_4: round(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_4).toFixed(2),
                    RATEPERHOUR_5: round(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_5).toFixed(2),
                    RATEPERHOUR_6: round(userDat.RATEPERHOUR_GROUP.RATEPERHOUR_6).toFixed(2),


                    RATEPERDAY_1: round(userDat.RATEPERDAY_GROUP.RATEPERDAY_1).toFixed(2),
                    RATEPERDAY_2: round(userDat.RATEPERDAY_GROUP.RATEPERDAY_2).toFixed(2),
                    RATEPERDAY_3: round(userDat.RATEPERDAY_GROUP.RATEPERDAY_3).toFixed(2),
                    RATEPERDAY_4: round(userDat.RATEPERDAY_GROUP.RATEPERDAY_4).toFixed(2),
                    RATEPERDAY_5: round(userDat.RATEPERDAY_GROUP.RATEPERDAY_5).toFixed(2),
                    RATEPERDAY_6: round(userDat.RATEPERDAY_GROUP.RATEPERDAY_6).toFixed(2),
                });
            }
        } else {
            this.isNotValid = true;
        }
    };

    /**
     * This function is used to get the selected Activity label
     * @returns it's return the label
     */
    getSelectedActivityLabel() {
        let index = this.f.userVal.value;
        return this.rateActivity[index];
    }
}
