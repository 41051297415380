import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';
@Component({
  selector: 'app-conveyancing-settlement',
  templateUrl: './conveyancing-settlement.component.html',
  styleUrls: ['./conveyancing-settlement.component.scss']
})
export class ConveyancingSettlementComponent implements OnInit, OnDestroy {
  errorWarningData:any={};
  MatterData: any = {};
  ChildtableDataSubcription: Subscription;
  @Input() MatterConveyancingDetailsForm:FormGroup
  @Input() testBool;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  constructor(private datepipe: DatePipe,
    public behaviorService: BehaviorService, public tooltipService: TooltipService) { }

  ngOnInit() {
    this.ChildtableDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
      if (result) {
        this.MatterData = result;
      }})
  }

  /**
   * This function is used to get the text data.
   */
  myFunction(event:any) {
    navigator.clipboard.writeText(event)
  }
   
  /**
   * This function is used to get the common date picker.
   */
  CommanDatePickerClick(type: string, event: MatDatepickerInputEvent<Date>,formControlVar) {
    this.MatterConveyancingDetailsForm.controls[formControlVar].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }
  
  ngOnDestroy(): void {
    this.ChildtableDataSubcription?.unsubscribe();
  }
}
