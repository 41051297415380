
<div cdkDrag class="popup_main_div generrate_document_main_data" >
    <h2 cdkDragHandle mat-dialog-title>
        <span >BANK FEED INSTRUCTIONS</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" style="width: 100%;" >
           <b>Before you connect a bank account, please ensure the following are already complete</b><br> 
            <h3>1. Ensure that you have added an ABN in system settings under organisational details.</h3>
            
           <h3> 2. Ensure the logged in user has a valid mobile number in their user settings.</h3>
            
           <h3> 3. Data sharing from your bank needs to be turned on before you start to connect a bank feed.</h3>
            <br>
           <h3>For more information go to <a href="https://community.silq.com.au/en/tips-tricks-to-know-before-connecting-or-using-a-bank-feed" target="_blank">https://community.silq.com.au/en/tips-tricks-to-know-before-connecting-or-using-a-bank-feed</a></h3>

           <h3>Be aware that it can take a number of minutes after you have successfully connected before the data will appear in SILQ. If it does not show immediately please refresh the page every few minutes until it does appear.</h3>
        </div>
      </div>
    <mat-dialog-actions fxLayout="row" class="popup_ftr" align="end">
        <button (click)="closePopup()"  mat-raised-button color="primary" [mat-dialog-close]="false"  id="canclePopup"
            cdkFocusInitial>Start
        </button>
    </mat-dialog-actions>
</div>