<!-- [formGroup]="contactForm" -->
<div class=" one " fxLayout="column" [formGroup]="contactForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline"
            [ngClass]="{'has-error':errorWarningData.Error?.DEFAULTCURRENCYID,'has-warning':errorWarningData.Warning?.DEFAULTCURRENCYID}">
            <mat-label>Default Currency</mat-label>
            <mat-select #value name="selected" formControlName="DEFAULTCURRENCYID">
                <mat-option *ngFor="let optionval of contactCurrency" value="{{optionval.LOOKUPGUID}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-select>
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DEFAULTCURRENCYID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DEFAULTCURRENCYID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline"
            [ngClass]="{'has-error':errorWarningData.Error?.GSTTYPE,'has-warning':errorWarningData.Warning?.GSTTYPE}">
            <mat-label>Default contact GST type</mat-label>
            <mat-select #value name="selected" formControlName="GSTTYPE">
                <mat-option *ngFor="let optionval of contactGst" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}</mat-option>
            </mat-select>
        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GSTTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GSTTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

        </mat-form-field>
    </div>
</div>
<div class="one rate_tab_main" fxLayout="column">
    <div fxFlex="100">
        <strong>You only need to enter entries on this tab if the rates are different for this contact/activity from the
            default values</strong>
    </div>
    <div fxFlex="100">
        <mat-accordion class="faqs-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_contact_d.ico"  matTooltip=""
                                    matTooltipPosition="above">
                            </mat-icon>&nbsp;&nbsp;
                            <strong>Fee Earner Rates</strong>
                        </div>
                        <div fxFlex="30">
                            <button mat-raised-button color="accent" class="mat-accent" (click)="AddNewRate('new','1','')">New Fee Earner
                                Rate..</button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <table>
                        <tr *ngIf="UserRateData.length == 0">
                            <td colspan="2" style="text-align: center;font-weight: bold;">
                                Data not found
                            </td>
                        </tr>
                        <tr *ngFor="let item of UserRateData">
                            <td> {{item?.USERID}}&nbsp;&nbsp;{{(item.RATEPERHOUR_GROUP)?item.RATEPERHOUR_GROUP?.RATEPERHOUR_1 : item.RATEPERHOUR_1
                                | toFixedAmount:true}}Ex/Hr
                                &nbsp;&nbsp;{{(item.RATEPERDAY_GROUP)?item?.RATEPERDAY_GROUP?.RATEPERDAY_1 : item.RATEPERDAY_1 | toFixedAmount:true}}
                                Ex/Day
                            </td>
                            <td *ngIf="item?.USERRATEGUID">
                                <mat-icon style="cursor: pointer;" class="pr-4"
                                    (click)="AddNewRate('edit','1',item?.USERRATEGUID)">edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(item,1)">delete
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_time_cost_d.ico"  matTooltip="Twiter"
                                    matTooltipPosition="above">
                            </mat-icon>
                            &nbsp;&nbsp;
                            <strong>Activity Rates</strong>
                        </div>
                        <div fxFlex="30">
                            <button mat-raised-button color="accent" class="mat-accent" (click)="AddNewRate('new','2','')">New Activity
                                Rate</button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <table>
                        <tr *ngIf="ActivityRateData.length == 0">
                            <td colspan="2" style="text-align: center;font-weight: bold;">
                                Data not found
                            </td>
                        </tr>
                        <tr *ngFor="let Activityitem of ActivityRateData">
                            <td> {{Activityitem?.ACTIVITYID}} -
                                {{Activityitem?.DESCRIPTION}}&nbsp;&nbsp;{{Activityitem?.RATEPERUNIT | toFixedAmount:true}}&nbsp;&nbsp;Ex/Unit
                            </td>
                            <td *ngIf="Activityitem?.ACTIVITYRATEGUID">
                                <mat-icon style="cursor: pointer;" class="pr-4"
                                    (click)="AddNewRate('edit','2',Activityitem?.ACTIVITYRATEGUID)">edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(Activityitem,2)">
                                    delete
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <mat-icon>
                                <img src="assets/icons/web_app/icon_expenses_d.ico"  matTooltip=""
                                    matTooltipPosition="above">
                            </mat-icon>&nbsp;&nbsp;
                            <strong>Sundry Rates</strong>
                        </div>
                        <div fxFlex="30">
                            <button mat-raised-button color="accent" class="mat-accent" (click)="AddNewRate('new','3','')">New Sundry
                                Rate</button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <table>
                        <tr *ngIf="SundryRateData.length == 0">
                            <td colspan="2" style="text-align: center;font-weight: bold;">
                                Data not found
                            </td>
                        </tr>
                        <tr *ngFor="let Sundryitem of SundryRateData">
                            <td> {{Sundryitem?.ACTIVITYID}} -
                                {{Sundryitem?.DESCRIPTION}}&nbsp;&nbsp;{{Sundryitem?.RATEPERUNIT | toFixedAmount:true}}&nbsp;&nbsp;Inc/pages
                            </td>
                            <td *ngIf="Sundryitem?.ACTIVITYRATEGUID">
                                <mat-icon style="cursor: pointer;" class="pr-4"
                                    (click)="AddNewRate('edit','3',Sundryitem?.ACTIVITYRATEGUID)">edit</mat-icon>
                                <mat-icon style="cursor: pointer;" class="pr-4" (click)="DeleteRate(Sundryitem,3)">
                                    delete
                                </mat-icon>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>