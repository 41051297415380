import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as $ from 'jquery';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-chart-ac-dailog',
    templateUrl: './chart-ac-dailog.component.html',
    styleUrls: ['./chart-ac-dailog.component.scss']
})
export class ChartAcDailogComponent implements OnInit, OnDestroy {
    successMsg: any;
    accountType: any;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    barristerTitle: any = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Matter Num' : 'Short Name';
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    isLoadingResults: boolean = false;
    action: string;
    dialogTitle: string;
    isspiner: boolean = false;
    AccountForm: FormGroup;
    AccountData: any = [];
    theCheckbox = true;
    isTrust: boolean = false;
    isTrustAccountId: any = '';
    sendtype: number;
    accountTypeData: any;
    isControlledMoneyAccount: boolean = false;
    accountTooltip: any;
    testBool: boolean = false;
    toolTipList: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub1: Subscription;
    constructor(
        public MatDialog: MatDialog,
        public dialogRef: MatDialogRef<ChartAcDailogComponent>,
        private _formBuilder: FormBuilder,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        public _matDialog: MatDialog,
        private _mainAPiServiceService: MainAPiServiceService,
        private globalFunService: GlobalFunctionsService,
        public tooltipService: TooltipService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        localStorage.setItem('istrackid', 'ChartAccountDailogComponent');
        this.action = data.action;
        this.behaviorService.ChartAccountData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.AccountData = result;
            }
        });
        this.isControlledMoneyAccount = (this.AccountData && this.AccountData.ACCOUNTCLASSNAME == 'Controlled Money Account') ? true : false;
        this.behaviorService.TrustDuplicateModuleHandling$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.accountTypeData = result;
            }
        });
        this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        if (this.accountTypeData.ClickType == 'WithoutTrust') {
            if (this.action === 'new') {
                this.dialogTitle = 'New Account';
            } else if (this.action === 'edit') {
                this.dialogTitle = 'Update Account';
            } else if (this.action === 'duplicate') {
                this.dialogTitle = 'Duplicate Account';
            }
        } else {
            if (this.action === 'new') {
                if (this.isControlledMoneyAccount)
                    this.dialogTitle = 'New Controlled Money Account';
                else
                    this.dialogTitle = 'Trust New Account';
            } else if (this.action === 'edit') {
                if (this.isControlledMoneyAccount)
                    this.dialogTitle = 'Update Controlled Money Account';
                else
                    this.dialogTitle = 'Trust Update Account';
            } else if (this.action === 'duplicate') {
                this.dialogTitle = 'Trust Duplicate Account';
            }
        }

        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'ChartAccountDailogComponent' && result['click'] && result['data']['GetAccount']) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'ChartAccountDailogComponent' && result['click'] && result['data']['SetAccount']) {
                this.SaveAccount();
            }

        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.AccountForm = this._formBuilder.group({
            ACCOUNTGUID: [''],
            MATTERGUID: [''],
            ACCOUNTCLASS: [''],
            ACCOUNTNAME: ['', Validators.required],
            //General
            ACCOUNTNUMBER: [''],
            ACCOUNTNUMBERCLSS: [''],
            ACCOUNTTYPE: [''],
            ACTIVE: [''],
            ACCOUNTTYPENAME: [''],
            //EXPORTINFO
            MYOBEXPORTACCOUNT: [''],
            //bank BANKDETAILS
            BANKNAME: [''],
            BANKADDRESS: [''],
            BANKBSB: [''],
            SForSendACCNO: [''],
            BANKACCOUNTNUMBER: [''],
            BANKTERM: [''],
            BANKINTERESTRATE: [''],
            //XERO
            XEROGUID: [''],
            XEROACCOUNTCODE: [''],
            xeroaccountClsss: [''],
        });
        if (this.action == "edit" || this.action == 'duplicate') {
            this.isLoadingResults = true;
            this.isTrust = this.accountTypeData.ClickType == 'WithoutTrust' ? false : true;
            this._mainAPiServiceService.getSetData({ ACCOUNTGUID: this.AccountData.ACCOUNTGUID, UseTrust: this.isTrust }, 'GetAccount').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.isTrustAccountId = res.DATA.ACCOUNTS[0].ACCOUNTNUMBER;
                    let accountData = res.DATA.ACCOUNTS[0];
                    if (this.action != 'duplicate') {
                        this.AccountForm.controls['ACCOUNTGUID'].setValue(accountData.ACCOUNTGUID);
                    }
                    this.AccountForm.controls['ACCOUNTCLASS'].setValue(accountData.ACCOUNTCLASSNAME);          // toString()
                    this.AccountForm.controls['ACCOUNTNAME'].setValue(accountData.ACCOUNTNAME);
                    //General
                    this.AccountForm.controls['SForSendACCNO'].setValue(accountData.ACCOUNTNUMBER);

                    this.AccountForm.controls['ACCOUNTNUMBERCLSS'].setValue(accountData.ACCOUNTCLASS + ' - ');
                    this.AccountForm.controls['ACCOUNTNUMBER'].setValue(accountData.ACCOUNTNUMBER);

                    this.accountType = accountData.ACCOUNTTYPENAME;
                    this.AccountForm.controls['ACCOUNTTYPE'].setValue(accountData.ACCOUNTTYPENAME);          // toString()

                    this.AccountForm.controls['ACTIVE'].setValue(Number(accountData.ACTIVE));
                    //EXPORTINFO
                    if (accountData.EXPORTINFO) {
                        this.AccountForm.controls['MYOBEXPORTACCOUNT'].setValue(accountData.EXPORTINFO['MYOBEXPORTACCOUNT']);
                    }

                    if (!this.isTrust && accountData.EXPORTINFO) {
                        this.AccountForm.controls['XEROGUID'].setValue(accountData.EXPORTINFO['XEROGUID']);
                        this.AccountForm.controls['XEROACCOUNTCODE'].setValue(accountData.EXPORTINFO['XEROACCOUNTCODE']);  // XERO
                    }
                    //BANKDETAILS
                    if (accountData.BANKDETAILS) {
                        this.AccountForm.controls['BANKNAME'].setValue(accountData.BANKDETAILS['BANKNAME']);
                        this.AccountForm.controls['BANKADDRESS'].setValue(accountData.BANKDETAILS['BANKADDRESS']);
                        this.AccountForm.controls['BANKBSB'].setValue(accountData.BANKDETAILS['BANKBSB']);
                        this.AccountForm.controls['BANKACCOUNTNUMBER'].setValue(accountData.BANKDETAILS['BANKACCOUNTNUMBER']);
                        this.AccountForm.controls['BANKTERM'].setValue(accountData.BANKDETAILS['BANKTERM']);
                        this.AccountForm.controls['BANKINTERESTRATE'].setValue(accountData.BANKDETAILS['BANKINTERESTRATE']);
                    }
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
                this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
                this.isLoadingResults = false;
            });
        } else {
            this.isLoadingResults = true;
            if (this.accountTypeData.ClickType == "WithTrust") {
                this.isTrust = true;
                let trustclassData = JSON.parse(localStorage.getItem("TrustchartAcc_filter"));
                if (trustclassData && trustclassData.AccountClass == 'Controlled Money Account') {
                    this.AccountForm.controls['BANKTERM'].setValue(trustclassData.AccountClass);
                }
                let ActiveMatterData = JSON.parse(localStorage.getItem('set_active_matters'));
                if (ActiveMatterData) {
                    this.AccountForm.controls['MATTERGUID'].setValue(ActiveMatterData.MATTERGUID);
                    this.AccountForm.controls['ACCOUNTNAME'].setValue(ActiveMatterData.CONTACTNAME + ' : ' + ActiveMatterData.MATTER + ' Controlled Money Account');
                }
            }
            let selectedAccount: any;
            this.behaviorService.ChartAccountData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                selectedAccount = result;
            });
            let accountClass = '1-';
            if (selectedAccount && selectedAccount.ACCOUNTCLASS) {
                accountClass = selectedAccount.ACCOUNTCLASS + '-';
                this.AccountForm.controls['ACCOUNTCLASS'].setValue(selectedAccount.ACCOUNTCLASSNAME);
            }
            this.AccountForm.controls['ACCOUNTNUMBERCLSS'].setValue(this.isControlledMoneyAccount ? 'D-' : accountClass);
            this.AccountForm.controls['ACTIVE'].setValue(true);
            this._mainAPiServiceService.getSetData({ FormAction: 'default', UseTrust: (this.accountTypeData.UseTrust == "Yes" || this.accountTypeData.UseTrust == true) ? true : false, VALIDATEONLY: true, DATA: { ACCOUNTCLASS: selectedAccount.ACCOUNTCLASSNAME } }, 'SetAccount').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                this.isLoadingResults = false;
                this.CheckdefultValidation(res.DATA.VALIDATIONS);
            });
        }
        this.sub1 = this.AccountForm.valueChanges.subscribe((form: any) => {
            this.changeForControl(form)
        });
        this.setTooltipData()

    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
    }

/**
 * This function is used to set tool tip data 
 */
    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['Account']) {
            this.toolTipList = TooltipData['Account'];
        } else {
            this.tooltipService.setToolTipData = ('Account');
        }
    }

    /**
     * This function is used to update the data.
     */
    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    }


/**
 * This function is used to changeforcontrol data
 * @param formControl 
 */
    changeForControl(formControl) {
        //Error
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }
        //Warning
        const dubObj2 = { ...this.errorWarningDataArray.Warning };
        let element2 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj2)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element2[key];
                dubObj2[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj2 }
            } else if (!formControl[KEY]) {
                element2[key] = dubObj2[key];
            }
        }
    }

    /**
     * This function is used to Check the validation data value
     * @param bodyData -body data value
     */
    CheckdefultValidation(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
    }
    get f() {
        return this.AccountForm.controls;
    }

    /**
     * This function is used to GetAccount Class data.
     * @param val 
     */
    GetAccountClass(val: any) {
        let classData: any = { 'Asset': '1-', 'Liability': '2-', 'Equity': '3-', 'Income': '4-', 'Expense': '5-' };
        this.AccountForm.controls['ACCOUNTNUMBERCLSS'].setValue(this.isControlledMoneyAccount ? 'D-' : classData[val]);
    }
    
    /**
     * This function is used to Save Account data value
     */
    SaveAccount() {
        this.isspiner = true;
        let PostData: any = {
            ACCOUNTCLASS: this.f.ACCOUNTCLASS.value,
            ACCOUNTNAME: this.f.ACCOUNTNAME.value,
            ACCOUNTNUMBER: this.f.ACCOUNTNUMBER.value,
            ACCOUNTTYPE: this.f.ACCOUNTTYPE.value,
            ACTIVE: this.f.ACTIVE.value,
            MYOBEXPORTACCOUNT: this.f.MYOBEXPORTACCOUNT.value,
            BANKDETAILS: {
                BANKNAME: this.f.BANKNAME.value,
                BANKADDRESS: this.f.BANKADDRESS.value,
                BANKBSB: this.f.BANKBSB.value,
                BANKACCOUNTNUMBER: this.f.BANKACCOUNTNUMBER.value,
                BANKTERM: this.f.BANKTERM.value,
                BANKINTERESTRATE: this.f.BANKINTERESTRATE.value,
            }
        };
        if (!this.isTrust) {
            PostData.XEROACCOUNTCODE = this.f.XEROACCOUNTCODE.value;
            PostData.XEROGUID = this.f.XEROGUID.value;
        }
        if (this.isControlledMoneyAccount) {
            PostData.MATTERGUID = this.f.MATTERGUID.value;
        }
        this.successMsg = 'Save successfully';
        let FormAction = this.action == 'edit' ? 'update' : 'insert';
        if (this.action == 'edit') {
            PostData.ACCOUNTGUID = this.f.ACCOUNTGUID.value;
            this.successMsg = 'Update successfully';
        }
        let PostAccountData: any = { FormAction: FormAction, UseTrust: (this.accountTypeData.UseTrust == "Yes" || this.accountTypeData.UseTrust == true) ? true : false, VALIDATEONLY: true, Data: PostData };
        this._mainAPiServiceService.getSetData(PostAccountData, 'SetAccount').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, PostAccountData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, PostAccountData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, PostAccountData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }

        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /** 
     * This function is used to check the validation data
    */
    checkValidation(bodyData: any, PostAccountData: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'Warning') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        // this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
       
        //   this.isspiner = false;
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true, width: '100%', data: warningData
        //   });
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.saveAccountData(PostAccountData);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.saveAccountData(PostAccountData);

        // }

        this.isspiner = false;
        this.globalFunService.checkValidation(bodyData, PostAccountData)
            .pipe(takeUntil(this._unsubscribeAll$)).subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.saveAccountData(PostAccountData);
                    }
                }
            });
    }

    /**
     * This function is used to Get the onAccountType data 
     * @param value 
     */
    onACCOUNTTYPE(value) {
        this.accountType = value;
    }

    /**
     * This function is used to Save the Account Data Value
     * @param PostAccountData -post Account data value
     */
    saveAccountData(PostAccountData: any) {
        PostAccountData.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(PostAccountData, 'SetAccount').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.toastr.success(this.successMsg);
                $('#refreshChartACCTab').click();
                this.dialogRef.close(true);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.toastr.warning(this.successMsg);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.toastr.error(res.STATUS);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isspiner = false;
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }
}
