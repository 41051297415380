import { Component, OnInit, Pipe, PipeTransform, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { TableColumnsService } from '@_services/table-columns.service';
import { Subject } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';


@Component({
    selector: 'app-sorting-dialog',
    templateUrl: './sorting-dialog.component.html',
    styleUrls: ['./sorting-dialog.component.scss']
})
export class SortingDialogComponent implements OnInit, OnDestroy {
    checkboxdata: number = 0;
    property: any[] = [];
    propertyNew: any[] = [];
    filterName: string;
    modelType: string;
    list: string;
    searchporoperty: string;
    noReturnPredicate: string;
    title: string;
    namesFiltered = [];
    even = [];
    isLoadingResults: boolean = false;
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private TableColumnsService: TableColumnsService,
        public dialogRef: MatDialogRef<SortingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        public toasterService: ToastrService,
    ) {
        this.modelType = data.type;
        this.list = data.list;
        this.propertyNew = data.data;
    }
    
     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.property = this.propertyNew.sort(function (a, b) {
            return a.HIDDEN - b.HIDDEN;
        });
        this.updateCount();
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to update the count
     */
    updateCount():void {
        this.checkboxdata = 0;
        this.property.forEach(itemsdata => {
            if (!itemsdata.HIDDEN) {
                this.checkboxdata++;
            }
        });
        this.title = this.checkboxdata == 0 ? "Select All" : "Clear";
    }

    /**
     * This function is used to check the check box value
     * @param event -event data
     * @param data -data of the check box.
     */
    onChange(event, data) {
        let tempPropertyData = [];
        this.property.forEach(itemsdata => {
            if (itemsdata.DESCRIPTION == data) {
                itemsdata.HIDDEN = event.checked ? 0 : 1;
                tempPropertyData.push(itemsdata);
            } else {
                tempPropertyData.push(itemsdata);
            }
        });
        this.property = tempPropertyData.sort(function (a, b) {
            return a.HIDDEN - b.HIDDEN;
        });
        this.updateCount();
    }

    /**
     * This function is used to drag and drop
     * @param event -event data.
     */
    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    /**
     * This function is used to remove the matter description
     * @param item -Matters data
     * @param event -event data
     */
    remove(item, event) {
        let tempPropertyData = [];
        this.property.forEach(itemsdata => {
            if (itemsdata.DESCRIPTION == item) {
                itemsdata.HIDDEN = event.checked ? 0 : 1;
                tempPropertyData.push(itemsdata);
            } else {
                tempPropertyData.push(itemsdata);
            }
        });
        this.property = tempPropertyData.sort(function (a, b) {
            return a.HIDDEN - b.HIDDEN;
        });
        this.updateCount();
    }

    /**
     * Delete all columns click after delete all data
     */
    deleteall() {
        this.title = this.checkboxdata == 0 ? "Clear" : "Select All";
        let temCheck = this.checkboxdata == 0 ? true : false;
        this.clearfilter(temCheck);
        this.updateCount();
    }

    /**
     * this function is used to clear the filter data.
     * @param isCheck -flag for manage the clear filter or not
     */
    clearfilter(isCheck: boolean) {
        let tempPropertyData = [];
        this.property.forEach(itemsdata => {
            itemsdata.HIDDEN = isCheck ? 0 : 1;
            tempPropertyData.push(itemsdata);
        });
        this.property = tempPropertyData.sort(function (a, b) {
            return a.HIDDEN - b.HIDDEN;
        });
    }

    /**
     * This function is used to close dialog content
     */
    ondialogcloseClick(): void {
        this.dialogRef.close(false);
    }
    /**
     * This function is used to save the data with content close event.
     */
    async ondialogSaveClick():Promise<void> {
        let showCol = [];
        let tempColobj = [];
        let dateCol = [];
        this.property.forEach(itemsdata => {
            tempColobj[itemsdata.COLUMNID] = itemsdata;
            if (itemsdata.HIDDEN == 0)
                showCol.push(itemsdata.COLUMNID);
            if (itemsdata.ISDATE && itemsdata.ISDATE == 1)
                dateCol.push(itemsdata.COLUMNID);
        });
        await this.saveLastFilter();
        this.dialogRef.close({ columObj: showCol, columnameObj: this.property, tempColobj: tempColobj, dateCol: dateCol });
    }

    // saveLastFilter() {
    //   let dataObj = [];
    //   let POSITIONData = 1;
    //   this.property.forEach(itemsdata => {
    //     dataObj.push({ COLUMNNAME: itemsdata.COLUMNNAME, HIDDEN: itemsdata.HIDDEN, POSITION: POSITIONData });
    //     POSITIONData++;
    //   });
    //   this.TableColumnsService.setTableFilter(this.modelType, this.list, dataObj).subscribe(response => {
    //     if (response.CODE == 200 && response.STATUS == "success") {
    //       if (response.MESSAGE && response.STATUS)
    //         this.toasterService.success(response.MESSAGE);
    //     } else {
    //       this.toasterService.error(response.STATUS);
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    // }

    /**
     * This function is used to save the Last filter data.
     * @returns 
     */
    async saveLastFilter() {
        this.isLoadingResults = true;
        let dataObj = [];
        let POSITIONData = 1;
        this.property.forEach(itemsdata => {
            dataObj.push({ COLUMNNAME: itemsdata.COLUMNNAME, HIDDEN: itemsdata.HIDDEN, POSITION: POSITIONData });
            POSITIONData++;
        });
        return new Promise((next, reject) => {
            this.TableColumnsService.setTableFilter(this.modelType, this.list, dataObj)
                .subscribe(
                    (response) => {
                        if (response.CODE == 200 && response.STATUS == "success") {
                            if (response.MESSAGE && response.STATUS)
                                this.isLoadingResults = false;
                            this.toasterService.success(response.MESSAGE);
                            next(true); // Resolve the promise on success
                        } else {
                            this.isLoadingResults = false;
                            this.toasterService.error(response.STATUS);
                            reject(); // Reject the promise on error
                        }
                    },
                    (error) => {
                        this.isLoadingResults = false;
                        reject(error); // Reject the promise on error
                    }
                );
        });
    }
}

/**
 * Make the custome pipe.
 */
@Pipe({ name: 'filterByName' })
export class filterNames implements PipeTransform {
    /**
     * This function is used to transform the data.
     */
    transform(listOfNames: any[], nameToFilter: string): any[] {
        if (!listOfNames) return [];
        if (!nameToFilter) return listOfNames;
        nameToFilter = nameToFilter.toLowerCase();
        return listOfNames.filter(it => {
            return it.DESCRIPTION.toLowerCase().includes(nameToFilter);
        });
    }
}
