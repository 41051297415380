import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subscription, map } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';

@Component({
  selector: 'app-adjustment-dialog',
  templateUrl: './adjustment-dialog.component.html',
  styleUrls: ['./adjustment-dialog.component.scss'],
  animations: fuseAnimations
})
export class AdjustmentDialogComponent implements OnInit, OnDestroy {
  isspiner: boolean = false;
  isLoadingResults: boolean = false;
  confirmDialogRef: any;
  errorWarningData: any = {};
  displayedColumns: string[] = ['Cashbook', 'Id', 'Entered', 'transDate', 'Amount'];
  checked = false;
  chartAccountDetail: any;
  AdjustList: any = [];
  AdjustForm: FormGroup;
  highlightedRows: any;
  recoundate: any;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  action: any;
  sendType: any;
  cuurrentRow: any = {};
  isEnable: boolean = false;
  adjustmentDate: any;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  sub7: Subscription;
  sub1$ = this.behaviorService.isbankStatmentDate$.pipe(map(result=> {
    if (result)
      this.recoundate = result;
  }))

  sub2$ = this.behaviorService.ChartAccountData$.pipe(map(result => {
    if (result) {
        this.chartAccountDetail = result;
    }
  }))

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<AdjustmentDialogComponent>,
    public _mainAPiServiceService: MainAPiServiceService,
    public behaviorService: BehaviorService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    public datepipe: DatePipe
  ) {
    this.action = _data.type;
    if (this.action == "Withdrawal") {
      this.sendType = 'Withdrawal';
    } else {
      this.sendType = 'Receipt';
    }

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.AdjustForm = this._formBuilder.group({
      ADJUSTMENTDATE: [''],
      DESCRIPTION: [''],
      AMOUNT: [''],
      PASSWORD: [''],
      checked: ['']
    });
      setTimeout(() => {
          this.LoadData();
      }, 2000);

   this.sub7 = this.AdjustForm.valueChanges.subscribe(x => {
      const formControl = this.f
      if (this.checked) {
        if (formControl["ADJUSTMENTDATE"].value && formControl["AMOUNT"].value && formControl["PASSWORD"].value && formControl["DESCRIPTION"].value) {
          this.isEnable = true;
        } else {
          this.isEnable = false;
        }
      } else {
        if (formControl["ADJUSTMENTDATE"].value && formControl["DESCRIPTION"].value && formControl["AMOUNT"].value && formControl["DESCRIPTION"].value) {
          this.isEnable = true;
        } else {
          this.isEnable = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.sub3?.unsubscribe();
    this.sub4?.unsubscribe();
    this.sub5?.unsubscribe();
    this.sub7?.unsubscribe();
  }

  /**
   * This function is used to MannualAdjustment changes
   * @param event 
   */
  ManualAdjustmentchanges(event){
    const formControl = this.f
    if (event && formControl["DESCRIPTION"].value) {
        this.AdjustForm.controls['DESCRIPTION'].setValue('');
    }
  }

  /**
   * Thisfunction is used to Choosed the date
   * @param type -type of the date
   * @param event -event date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.adjustmentDate = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    this.AdjustForm.controls["ADJUSTMENTDATE"].setValue(this.adjustmentDate);
  }

  /**
   * This function is used to Load the data
   */
  LoadData() {
      this.isLoadingResults = true;
    let val = {
        ACCOUNTGUID: (this.chartAccountDetail?.ACCOUNTGUID) ? this.chartAccountDetail?.ACCOUNTGUID:'' , RECONCILIATIONDATE: this.recoundate, ADJUSTMENTTYPE: this.sendType
    };
    let finalSend = { Data: val, FormAction: 'default', UseTrust: true, ValidateOnly: 1 };
    this.sub3 = this._mainAPiServiceService.getSetData(finalSend, 'SetReconciliationAdjustment').subscribe((res) => {
      if (res.DATA.DEFAULTVALUES.ADJUSTMENTITEMS[0]) {
        this.AdjustList = res.DATA.DEFAULTVALUES.ADJUSTMENTITEMS;
        this.rowClick(res.DATA.DEFAULTVALUES.ADJUSTMENTITEMS[0]);
        this.highlightedRows = res.DATA.DEFAULTVALUES.ADJUSTMENTITEMS[0].ITEMID;
      }
      this.isLoadingResults = false;
    });
  }

  /**
   * This function is used to Click on the Row
   * @param val -value data
   */
  rowClick(val) {
    this.cuurrentRow = val
    this.AdjustForm.patchValue({
      ADJUSTMENTDATE: val.TRANSACTIONDATE,
      DESCRIPTION: val.DESCRIPTION,
      AMOUNT: val.AMOUNT
    });
  }
  get f() {
    return this.AdjustForm.controls;
  }

  /**
   * Save the data
   */
  save() {
    let val = {
      ADJUSTMENTGUID: '',
      ACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID,
      ADJUSTMENTDATE: this.f.ADJUSTMENTDATE.value,
      TRUSTTRANSACTIONGUID: (this.checked) ? '' : this.cuurrentRow.TRUSTTRANSACTIONGUID,
      AMOUNT: this.f.AMOUNT.value,
      MANUAL:this.checked,
      DESCRIPTION: this.f.DESCRIPTION.value,
      PASSWORD: this.checked ? this.f.PASSWORD.value : '',
      RECONCILIATIONDATE: this.recoundate,
      ADJUSTMENTTYPE: this.sendType
    };
    this.isspiner = true;
    let details = { FormAction: 'insert', VALIDATEONLY: true, Data: val };
    this.sub4 = this._mainAPiServiceService.getSetData(details, 'SetReconciliationAdjustment').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });

  }

  /**
   * This function is used to check the validation
   * @param bodyData -bodydata
   * @param details -details of the validation
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      }
      else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveSpendMoneyData(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveSpendMoneyData(details);
    }
  }

  /**
   * This function is used to save the SpendMoney data
   * @param data - data Value
   */
  saveSpendMoneyData(data: any) {
    data.VALIDATEONLY = false;
    this.sub5 = this._mainAPiServiceService.getSetData(data, 'SetReconciliationAdjustment').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {

        this.toastr.success(' save successfully');
        this.isspiner = false;
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isspiner = false;
    }, error => {
      this.toastr.error(error);
    });
  }
}
