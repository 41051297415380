<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_rate_main" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="MatterRateForm" id="MatterRateForm" [formGroup]="MatterRateForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <mat-error *ngIf="isNotValid">
                    Please select valid user
                </mat-error>
               
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 40px" class="rate_tab_value">
                    
                    <div class="example tab_value_child" fxFlex="50" class="pr-4">
                        <!-- <label>RATETYPE</label> : <span *ngIf="PopupData">{{PopupData.data.RATETYPE}}</span> -->
                        <mat-form-field  appearance="outline" class="example" fxFlex="100" >
                            <mat-label>Link Type</mat-label>
                                <mat-select formControlName="RATETYPE"  disabled="true">
                              <mat-option value="Matter">Matter</mat-option>
                              <!-- <mat-option value="Client">Client</mat-option> -->
                              <mat-option value="Contact">Contact</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>

                    <div class="example tab_value_child" fxFlex="50" >
                        <!-- <label>LINKDESC</label> : <span *ngIf="PopupData">{{PopupData.data.LINKDESC}} </span> -->
                        <mat-form-field appearance="outline" class="example "  fxFlex="100">
                            <mat-label>Link Desc</mat-label>
                            <input type="text" matInput formControlName="LINKDESC">
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="pt-12" fxFlex="1 0 auto" >
                    <div fxFlex="16">
                    </div>
                    <div fxFlex="42" class="text-center">
                        <mat-label>$ / hr</mat-label>
                    </div>
                    <div fxFlex="42" class="text-center">
                        <mat-label>$ / day</mat-label>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" >
                    <div fxFlex="16" class="lable-div">
                        <mat-label>Rate 1</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_1">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_1">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" >
                    <div fxFlex="16" class="lable-div">
                        <mat-label>Rate 2</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_2">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_2">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" >
                    <div fxFlex="16" class="lable-div">
                        <mat-label>Rate 3</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_3">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_3">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" >
                    <div fxFlex="16" class="lable-div">
                        <mat-label>Rate 4</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_4">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_4">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" >
                    <div fxFlex="16" class="lable-div">
                        <mat-label>Rate 5</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_5">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_5">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mat-rate-list" >
                    <div fxFlex="16" class="lable-div">
                        <mat-label>Rate 6</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERHOUR_6">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="42" class="pr-4">
                        <input matInput type="number" formControlName="RATEPERDAY_6">
                    </mat-form-field>
                </div>

                <!-- <div fxLayout="row" class="mat_field_btm_padding" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="apiURL=='GetActivity'">
                    <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                        <mat-label>Rate</mat-label>
                        <input matInput currencyMask formControlName="RATEPERUNIT">
                    </mat-form-field>
                </div> -->
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveRates()" [disabled]="isspiner||isNotValid">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>