import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Pipe({
    name: 'productType'
})
export class ProductTypePipe implements PipeTransform {
    constructor(private router: Router) { }
   /**
     * This function is used to transform the data.
     */
    transform(value: any, ...args: any[]): any {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            const PRODUCTTYPE = currentUser.PRODUCTTYPE;
            return PRODUCTTYPE == 'Barrister' ? false : true;

        } else {
            // this.router.navigate(['login']);
        }
    }

}
