import { TooltipService } from '@_services/tooltip.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  constructor(public tooltipService:TooltipService) { }
  @Input() errorWarningData: any;
  @Input() contactForm: FormGroup;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
