
<div cdkDrag class="popup_main_div generrate_document_main_data" >
    <h2 cdkDragHandle mat-dialog-title>
        <span >LEXON FORM AGREEMENT</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main" >
        <div class="one" style="width: 100%;" >
           <b>Conveyancing tools</b><br> 
            <p>Lexon’s Conveyancing Protocol comprises free tools intended to assist staff in dealing with a range of issues that arise in a conveyance by picking up some major issues which are programmed into everyday practice.</p>
            
           <p> The ultimate goal is that the use of the Conveyancing Protocol will help practitioners avoid claims entirely. In the event a claim is nonetheless made, Practitioners can reduce the risk of a Deterrent Excess by using all of the Protocol documents.</p>
            
           <p> Lexon’s Risk Tools are provided as a resource to law practices insured by Lexon Insurance Pte Ltd. They do not constitute legal advice and are not intended for public circulation or for use by any nonclient third party without Lexon's prior approval. Lexon recommends practitioners exercise their experience and judgment in applying Lexon’s Risk Tools and the contents to particular matters at hand. Lexon’s Risk Tools are not all encompassing and are not an exhaustive list of every issue that may arise in relevant matters. Practitioners will need to adapt the tools to address individual clients' circumstances. Lexon's Risk Tools are not a replacement for legal training or engaging qualified legal practitioners or other professionals. These tools are designed to identify and draw attention to some key issues which may arise in matters.</p>
            
            <p>Lexon’s Risk Tools are subject to copyright vested in Lexon Insurance Pte Ltd. Practitioners insured by Lexon Insurance Pte Ltd (and no other person or entity) have a limited licence to download, display, print and reproduce the tools for use only within their law practice while insured by Lexon.</p>
            
            <b>All other tools</b><br> 
           <p> These risk tools are a free resource provided by Lexon, intended to assist staff in dealing with a range of issues that arise in a relevant matter. They do this by picking up some key issues which are programmed into everyday practice through various tools, letters, checklists etc.</p> 
            
           <p> Lexon’s Risk Tools are provided as a resource to law practices insured by Lexon Insurance Pte Ltd. They do not constitute legal advice and are not intended for public circulation or for use by any nonclient third party without Lexon's prior approval. Lexon recommends practitioners exercise their experience and judgment in applying Lexon’s Risk Tools and the contents to particular matters at hand. Lexon’s Risk Tools are not all encompassing and are not an exhaustive list of every issue that may arise in relevant matters. Practitioners will need to adapt the tools to address individual clients' circumstances. Lexon's Risk Tools are not a replacement for legal training or engaging qualified legal practitioners or other professionals. These tools are designed to identify and draw attention to some key issues which may arise in matters. </p>
            
          <p>  Lexon’s Risk Tools are subject to copyright vested in Lexon Insurance Pte Ltd. Practitioners insured by Lexon Insurance Pte Ltd (and no other person or entity) have a limited licence to download, display, print and reproduce the tools for use only within their law practice while insured by Lexon. </p>
        <!-- <mat-checkbox [(ngModel)]="AGREE" class="mt-24" (ngModelChange)="getdata($event)">
        I have read and agree to the terms of service
        </mat-checkbox> -->
        </div>
      </div>
    <mat-dialog-actions fxLayout="row" class="popup_ftr">
        <mat-checkbox fxFlex="91" fxLayoutAlign="start" [(ngModel)]="AGREE"  (ngModelChange)="getdata($event)">
            I have read and agree to the terms of service
            </mat-checkbox>
        <button fxFlex="7"  mat-raised-button color="primary" [mat-dialog-close]="false"  id="canclePopup"
            cdkFocusInitial>Cancel
        </button>
    </mat-dialog-actions>
</div>