import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { FuseSharedModule } from 'src/@fuse/shared.module';

@NgModule({
    declarations: [NotificationsComponent],
    imports: [
        CommonModule,
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        FuseSharedModule,
        MatButtonModule,
        MatIconModule,
        MatChipsModule,
        MatCardModule
    ],
    exports: [
        NotificationsComponent
    ]
})
export class NotificationsModule { }
