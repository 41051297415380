<div class="example-loading-shade" *ngIf="isLoadingResults" style="z-index: 99999;">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>

<div class="example-loading-shade" *ngIf="singleLoader" style="z-index: 99999;">
    <mat-spinner [style.zoom]="0.5" *ngIf="singleLoader"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>
        <span *ngIf="!isGenerating"> {{whichTypeTemplate}} Generated </span>
        <span *ngIf="isGenerating">Generating {{whichTypeTemplate}}</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main" fxLayout="column">
        <div *ngIf="isLoadingResults" style="position: relative;z-index: 99999;">
            <h3><strong>{{filefolder_Name}}</strong> is being generated, please give it a moment. </h3><br>
            <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
            <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
            <!-- {{progress}}% -->
        </div>
        <div class="one" style="width: 100%;" fxLayout="column"
            *ngIf="(whichTypeTemplate == 'Template' || whichTypeTemplate == 'Receipt' || whichTypeTemplate == 'Email')"
            fxLayoutAlign="start" fxLayout.gt-md="row" fxFlex="1 0 auto">
            <div *ngIf="IsAuth && whichTypeTemplate != 'Email'">
                <div fxFlex="70" class="middle" *ngIf="!filefolderUrlDes" fxLayoutAlign="start">
                    <span>Please login to your online shared drive account. Once completed, please close the pop-up and
                        click on try again</span></div>
                <div fxFlex="30"> <button mat-raised-button color="accent" class="mat-accent" selectMatter
                        (click)="selectMatter(_data,arguParam)"> Click Here to Login</button></div>

            </div>
            <div *ngIf="IsAuth && whichTypeTemplate == 'Email'">
                <div fxFlex="70" class="middle" *ngIf="!filefolderUrlDes" fxLayoutAlign="start">
                    <span>Please login to your Microsoft 365 email account. Once completed, please the pop-up and click
                        on try again</span></div>
                <div fxFlex="30"> <button mat-raised-button color="accent" class="mat-accent" selectMatter
                        (click)="selectMatter(_data,arguParam)"> Click Here to Login</button></div>

            </div>
            <div *ngIf="!IsAuth" style="width: 100%;">
                <div fxFlex="100" class="middle" *ngIf="!filefolderUrlDes && isLoadingResults" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
                <div fxFlex="100" class="middle" *ngIf="filefolderUrlDes" fxLayoutAlign="start">
                    <span>The <b>{{filefolderUrlDes}}</b> has been produced.</span>
                </div>
            </div>
        </div>
        <br>
        <div class="one" style="width: 100%;" fxLayout="column"
            *ngIf="(whichTypeTemplate == 'Template' || whichTypeTemplate == 'Receipt')" fxLayoutAlign="start"
            fxLayout.gt-md="row" fxFlex="1 0 auto">
            <div *ngIf="!IsAuth" style="width: 100%;">
                <span>
                    <div fxFlex="100" fxLayoutAlign="start" *ngIf="(filefolderUrlDes && documentType=='Document')">
                        <span fxFlex="{{xFxaxVal}}">
                            <strong *ngIf="!isRename">{{ documentData.ORIGFILENAME }}</strong>
                            <mat-form-field *ngIf="isRename" class="example pr-4" fxFlex="50" class="pr-4"
                                appearance="outline">
                                <mat-label>Old Filename</mat-label>
                                <input matInput readonly [(ngModel)]="documentData.ORIGFILENAME">
                            </mat-form-field>
                            <mat-form-field *ngIf="isRename" class="example pr-4" fxFlex="50" class="pr-4"
                                appearance="outline">
                                <mat-label>New Filename</mat-label>
                                <input matInput [(ngModel)]="documentData.NEWFILENAME">
                            </mat-form-field>
                        </span>
                        <span fxFlex="{{yflaxCal}}">
                            <a href="{{base_url}}{{filefolderUrl}}" target="_blank" mat-raised-button mat-dialog-close
                                *ngIf="!isRename" color="accent">
                                Click here to view it
                            </a>
                            <button mat-raised-button color="accent" class="mat-accent"
                                *ngIf="!isRename && documentData.DOCUMENTGUID"
                                (click)="isRename=true;xFxaxVal=70;yflaxCal=30">Rename</button>
                            <button mat-raised-button color="accent" class="mat-accent" style="margin-top: 10px;"
                                *ngIf="isRename" (click)="RenameFile()">Save</button>
                            <button mat-raised-button color="accent" style="margin-top: 10px;" *ngIf="isRename"
                                (click)="isRename=false;xFxaxVal=60;yflaxCal=40">Cancel</button>
                        </span>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="start start" *ngIf="(filefolderUrlDes && documentType=='Link')">
                        <span fxFlex="{{xFxaxVal}}">
                            <strong *ngIf="!isRename">{{ documentData.ORIGFILENAME }}</strong>
                            <mat-form-field *ngIf="isRename" class="example pr-4" fxFlex="50" class="pr-4"
                                appearance="outline">
                                <mat-label>Old Filename</mat-label>
                                <input matInput readonly [(ngModel)]="documentData.ORIGFILENAME">
                            </mat-form-field>
                            <mat-form-field *ngIf="isRename" class="example pr-4" fxFlex="50" class="pr-4"
                                appearance="outline">
                                <mat-label>New Filename</mat-label>
                                <input matInput [(ngModel)]="documentData.NEWFILENAME">
                            </mat-form-field>
                        </span>
                        <span fxFlex="{{yflaxCal}}">
                            <a mat-raised-button href="{{filefolderUrl}}" target="_blank" mat-dialog-close
                                color="accent">
                                Click here to view it
                            </a>
                            <button mat-raised-button color="accent" class="mat-accent" *ngIf="!isRename"
                                (click)="isRename=true;xFxaxVal=70;yflaxCal=30">Rename</button>
                            <button mat-raised-button color="accent" class="mat-accent" style="margin-top: 10px;"
                                *ngIf="isRename" (click)="RenameFile()">Save</button>
                            <button mat-raised-button color="accent" style="margin-top: 10px;" *ngIf="isRename"
                                (click)="isRename=false;xFxaxVal=60;yflaxCal=40">Cancel</button>
                        </span>
                    </div>
                </span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="1 0 auto" name="form" class="TemplatePopup"
            *ngIf="whichTypeTemplate == 'Email' && !IsAuth && !isgenerateForBulk">
            <div *ngFor="let val of PacksEmail; let index = index" class="pr-4" fxLayout="row"
                fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxFlex="70" class="middle" fxLayoutAlign="start start">
                    <span> The <b>{{filefolder_Name}}</b> has been produced.</span>
                </div>
                <a target="_blank">
                    <button mat-raised-button (click)="SendMain(val)" color="accent">Click here to view it</button>
                </a>
            </div>
        </div>
        <div fxLayout="column" fxFlex="1 0 auto" name="form" class="TemplatePopup"
            *ngIf="whichTypeTemplate == 'Email' && !IsAuth && isgenerateForBulk">

            <!-- filteredArray?.length == 0 &&  -->
            <div fxLayout="row" *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email'" >
                <div [fxFlex]="isShowRegenerateAll ? '68' : '73'">
                    <strong *ngIf="isShowSentEmailCount && filteredArray.length > 0 && SHOWFLAGDATA =='Invoice and email'" style="font-size: 20px;">{{SENTEMAILCOUNT}}/{{filteredArray.length}} emails sent </strong><br>
                    <strong *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email'" style="font-size: 20px;">{{COUNT}}/{{collectSelectedInvoice.length}} invoices created </strong>
                </div>
                <div fxFlex="20">
                    <div fxLayout="row">
                        <div fxFlex="50">
                            <a target="_blank" >
                                <button mat-raised-button  color="accent" (click)="getPopupData(PacksEmail,'multiple')" [disabled]="isDisableSendAll == true">Send All</button>
                            </a>
                        </div>
                        <div fxFlex="50">
                            <button style="margin-left: 30px;" *ngIf="isShowRegenerateAll" mat-raised-button color="primary" class="mat-accent sizeOne" selectMatter (click)="reGenerateDocument(PacksEmail)">
                                Re-Generate All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
           <br>

            <div *ngFor="let val of PacksEmail; let index = index" class="pr-4 pb-8" fxLayout="row"
                fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxFlex="70" class="middle" fxLayoutAlign="start start" *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email'">
                    <span> The <b>{{filefolder_Name}}</b> has been produced for matter {{val?.SHORTNAME}} - {{val?.MATTER}}
                        </span>
                </div>
                <div fxFlex="70" class="middle" fxLayoutAlign="start start" *ngIf="SHOWFLAGDATA == undefined ">
                    <span> The <b>{{filefolder_Name}}</b> has been produced for Invoice -
                        {{val.INVOICECODE | invoiceNumber }}.</span>
                </div>
                <a target="_blank" *ngIf="SHOWFLAGDATA == undefined ">
                    <button mat-raised-button (click)="SendMain(val)" color="accent">Click here to view it</button>
                </a>
                <a target="_blank" *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email' && val.SHOWINPUT == false">
                    <button mat-raised-button (click)="SendMain(val,'MultyEmails')"  color="accent" [disabled]="val.SHOWICON == true">
                        <!-- Edit -->
                        <mat-icon >edit</mat-icon>
                    </button>
                </a>
                <a style="margin-left: -5px;" target="_blank" *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email' && val.SHOWINPUT == false">
                    <button mat-raised-button (click)="getPopupData(val,'single')"  color="accent" [disabled]="val.SHOWICON == true">
                        <mat-icon >send</mat-icon>
                    </button>
                </a>&nbsp;&nbsp;
                <mat-icon class="main-icon" *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email' && val.SHOWINPUT == false && val.SHOWICON ==true">
                    <img
                        src="assets/icons/web_app/Select.ico">
                </mat-icon>
            <ng-container *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email' && val.SHOWINPUT == true">
                <mat-form-field  appearance="outline"  class="pr-4 setwidth" >
                    <mat-label>Select Contact</mat-label>
                    <mat-select name="SelectContact"placeholder="Select Contact" [(ngModel)]="val.mattercontact" (selectionChange)="onChangeMatterContact($event.value)">
                        <mat-option *ngFor="let data of val?.MATTERCONTACT"  [value]="data">{{data.CONTACTNAME}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="add-filterwrap" (click)="getRefreshData(val)">
                    <mat-icon class="icon-btn pd">refresh</mat-icon>
                </div>
            </ng-container>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button  mat-raised-button color="primary" [mat-dialog-close]="false" [disabled]="isLoadingResults" *ngIf="SHOWFLAGDATA == undefined "
            cdkFocusInitial >Cancel
        </button>
        <button  mat-raised-button color="primary" [mat-dialog-close]="false" [disabled]="isLoadingResults" *ngIf="SHOWFLAGDATA && SHOWFLAGDATA !=undefined && SHOWFLAGDATA =='Invoice and email'"
            cdkFocusInitial>Close
        </button>
    </mat-dialog-actions>
</div>