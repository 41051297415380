import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import * as $ from 'jquery';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { AuthenticationService } from '@_services/authentication.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-select-dialog',
  templateUrl: './contact-select-dialog.component.html',
  styleUrls: ['./contact-select-dialog.component.scss'],
  animations: fuseAnimations
})
export class ContactSelectDialogComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['CONTACTNAME', 'SUBURB'];
  appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  highlightedRows: any;
  newHighlightedRows: any;
  currentMatterData: any;
  Contactdata: any = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isLoadingResults: boolean;
  SelectcontactForm: FormGroup;
  pageSize: any;
  currentUser: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterVals: any = { 'active': '1', 'FirstLetter': 'a', 'ContactType': 'Company', 'SEARCH': '' };
  contect_details: any[] = [];
  sub: Subscription;
  sub1: Subscription;
  sub3: Subscription;


  constructor(
    public closeDialogRef: MatDialogRef<ContactSelectDialogComponent>,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public _matDialog: MatDialog,
    public _mainAPiServiceService: MainAPiServiceService,
    private authenticationService: AuthenticationService,
    private behaviorService: BehaviorService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    localStorage.setItem('istrackid', 'ContactSelectDialogComponent');
    this.SelectcontactForm = this.fb.group({ ContactType: ['Company'], startsWith: ['a'], ActiveContacts: ['1'], SEARCH: [''], });
    this.currentUser = this.authenticationService.currentUserValue;

    this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'ContactSelectDialogComponent' && result['click'] && result['data']['contact']) {
        this.ngOnInit();
      }
    });

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {    
    // this.contect_details = (typeof this._data.contect_details) == "undefined" ? [] : this._data.contect_details;
    if (JSON.parse(localStorage.getItem('contact_Filter_popup'))) {
      this.filterVals = JSON.parse(localStorage.getItem('contact_Filter_popup'));
      delete this.filterVals.COMPANYCONTACTGUID;
      this.SelectcontactForm.patchValue({
        ActiveContacts: this.filterVals.active == '' ? "all" : this.filterVals.active,
        ContactType: this.filterVals.ContactType == '' ? "all" : this.filterVals.ContactType,
        startsWith: this.filterVals.FirstLetter == '' ? 'all' : this.filterVals.FirstLetter
      });
    } else {
      localStorage.setItem('contact_Filter_popup', JSON.stringify(this.filterVals));
    }
    this.filterVals.SEARCH = '';
    if (this.currentUser.initialwindow == "Trust") {
      this.SelectcontactForm.patchValue({ ActiveContacts: "all", ContactType: "all", startsWith: 'all' });
      this.filterVals.active = "";
      this.filterVals.ContactType = "";
      this.filterVals.FirstLetter = "";
    }
    // if (this._data.default == "Solicitor" || this._data.defult_type == "Person") {
    //   this.filterVals.ContactType = "Company";
    //   this.SelectcontactForm.patchValue({ ContactType: "Company" });
    // }
    if (this._data.default == "Solicitor" || this._data.defult_type == "Person") {
      this.filterVals.ContactType = "Company";
      this.SelectcontactForm.patchValue({ ContactType: "Company" });
    } else if (this._data.default == "Person") {
      this.filterVals.ContactType = "Person";
      this.SelectcontactForm.patchValue({ ContactType: "Person" });
    } else {

      if(this._data && this._data.defult_type !=undefined){
        this.filterVals.ContactType = this._data.defult_type;
        this.SelectcontactForm.patchValue({ ContactType: this._data.defult_type });
      }else{
       // this.SelectcontactForm.patchValue({ ContactType: this.filterVals.ContactType });
      }
    }
    if (this._data.type == 'Beneficiary') {
      if (this.filterVals.COMPANYCONTACTGUID)
        delete this.filterVals.COMPANYCONTACTGUID;
      this.filterVals.ContactType = "";
      this.SelectcontactForm.patchValue({ ContactType: "all" }); 
    }
    if (this.contect_details[0]) {
      delete this.filterVals.ContactType;
      this.filterVals.COMPANYCONTACTGUID = this.contect_details[0]['CONTACTGUID'];
      this.SelectcontactForm.patchValue({ ContactType: "COMPANYCONTACTGUID," + this.contect_details[0]['CONTACTGUID'] });
    }
    this.loadContectData(this.filterVals);
    if (this._data.type == "fromcontact" || this._data.default == "Person") {
      this.SelectcontactForm.get('ContactType').disable();
    }
    if (this.appPermissions == null) {
      this.appPermissions = [];
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub1?.unsubscribe();
    this.sub3?.unsubscribe();
  }

  /**
   * This function is used to reresh the contact tab
   */
  refreshContactTab():void {
    this.loadContectData(this.filterVals);
  }

  /**
   * This function is used to get the contact value
   * @param value -value data
   */
  Contactvalue(value):void {
    this.filterVals.FirstLetter = value != 'all' ? value : ''; 
    this.loadContectData(this.filterVals);
  }

  /**
   * This function is used to Active Contact change
   * @param data -data value
   */
  ActiveContactsChange(data):void {
    this.filterVals.active = this.f.ActiveContacts.value == 'all' ? "" : this.f.ActiveContacts.value;
    this.loadContectData(this.filterVals);
  }

  /**
   * This function is used to change the contact type
   * @param value -value data
   */
  ContactTypeChange(value):void {
    let valueVal = value.split(",");
    if (valueVal[0] == 'COMPANYCONTACTGUID') {
      this.filterVals.COMPANYCONTACTGUID = valueVal[1];
      delete this.filterVals.ContactType;
    } else if (value == 'Company' || value == 'Multi Person') {
      this.filterVals.COMPANYCONTACTGUID = this._data.FIRMGUID;
      delete this.filterVals.ContactType;
    } else {
      delete this.filterVals.COMPANYCONTACTGUID;
      this.filterVals.ContactType = valueVal[0] == 'all' ? "" : valueVal[0];
    }
    this.loadContectData(this.filterVals);
  }
  /**
   * This function is used to search the data filter value 
   * @param searchFilter -search ilter data value
   */
  onSearch(searchFilter: any):void {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
      this.filterVals.SEARCH = this.f.SEARCH.value;
      this.loadContectData(this.filterVals); 
    }
  }

  /**
   * This unction is used to load contact data
   * @param postData -post data value
   */
  loadContectData(postData):void {
    localStorage.setItem('contact_Filter_popup', JSON.stringify(postData));
    this.isLoadingResults = true;
    let paylaod = {
      "ACTION": "GetData",
	    "FILTERS": {...postData},
    };

    this.sub1 = this._mainAPiServiceService.getSetData(paylaod, 'contact').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.Contactdata = new MatTableDataSource(response.DATA.RECORDS);
        this.Contactdata.paginator = this.paginator;
        this.Contactdata.sort = this.sort;
        if (response.DATA.RECORDS[0]) {
          this.currentMatterData = response.DATA.RECORDS[0];
          this.behaviorService.contactData(response.DATA.RECORDS[0]);
          // localStorage.setItem('contactGuid', response.DATA.RECORDS[0].CONTACTGUID);
          this.highlightedRows = response.DATA.RECORDS[0].CONTACTGUID;
          if (this.newHighlightedRows) {
            const indexData = response.DATA.RECORDS.findIndex(col => col.CONTACTGUID === this.newHighlightedRows);
            let active = response.DATA.RECORDS[indexData];
            this.currentMatterData = active;
            this.behaviorService.contactData(active);
            this.highlightedRows = this.newHighlightedRows;
          }
        } else {
          this.currentMatterData = '';
        }
        this.isLoadingResults = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.closeDialogRef.close(false);
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }
  get f() {
    return this.SelectcontactForm.controls;
  }

  /**
   * This function is used to edit the contact data value
   * @param Row 
   */
  editContact(Row: any):void {
    this.currentMatterData = Row;
    // localStorage.setItem('contactGuid', Row.CONTACTGUID);
  }

  /**
   * This function is used to onPagination change
   * @param event -event data value
   */
  onPaginateChange(event):void {
    this.pageSize.contact_select = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }
  
  /**
   * This function is used to Add contact dialog
   */
  async AddContactsDialog(){
    this.behaviorService.MatterpopupContactData$.next(null);
    const dialogRef = await this.dialog.open(ContactDialogComponent, {
      disableClose: true, panelClass: 'contact-dialog', data: { action: 'new', isReturn: true , contect_details : this._data.contect_details , flagNewConactMatter : true}
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.CONTACTGUID) {
          let contectIdIs = await this.getContactNewId();
          this.behaviorService.MatterpopupContactData$.next(contectIdIs);
          this.closeDialogRef.close(result);
          // this.newHighlightedRows = result.CONTACTGUID;
        }else{
          this.behaviorService.MatterpopupContactData$.next(null)
        }
      }
    });
  }
/**
 * This function is used to get the contact new tab
 * @returns -data value
 */
  getContactNewId(){
    let contecttId = '';
    let postData = {
      "Action": "GetContactId",
      "VALIDATEONLY": true,
      "Data": {},
    };

    return new Promise((response , reject)=>{
      this.sub3 = this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
        if(res.CODE == 200){
          contecttId = res.DATA.CONTACTID;
          response(contecttId);
        }else{
          response(null);
        }
      });
    })
  }

/**
 * This function is used to delete the contact data
 */
  deleteContact(): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: true,
      width: '100%',
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let getContactGuId = this.currentMatterData.CONTACTGUID;
        let postData = { Action: "delete", DATA: { CONTACTGUID: getContactGuId } };
        this._mainAPiServiceService.getSetData(postData, 'contact').subscribe(res => {
          if (res.STATUS == "success") {
            $('#refreshContactTab').click();
            this.toastr.success(res.STATUS);
          } else {
            this.toastr.error("You Can't Delete Contact Which One Is To Related to Matters");
          }
        });;
      }
      this.confirmDialogRef = null;
    });
  }
 
  /**
   * This function is used to open the edit contact popup.
   */
  EditContactsDialog():void {
    if (!this.currentMatterData) {
      this.toastr.error("Please Select Contact");
    } else {
      const dialogRef = this.dialog.open(ContactDialogComponent, { disableClose: true, data: { action: 'edit', CONTACTGUID: this.currentMatterData.CONTACTGUID } });
      dialogRef.afterClosed().subscribe(result => {
        if (result)
          $('#refreshContactTab').click();
      });
    }
  }
}
