<!-- [formGroup]="matterdetailForm" -->
<!-- <mat-label><strong>Description</strong>  </mat-label> -->
<div class=" one " fxLayout="column">
    <div  [formGroup]="matterCompulsoryAcqForm">
        <div>
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CLIENTVALUATION,'has-warning':errorWarningData.Warning?.CLIENTVALUATION}">
                <mat-label>Client Valuation</mat-label>
                <input style="text-align: right;" matInput [value]="matterCompulsoryAcqForm.get('CLIENTVALUATION').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.CLIENTVALUATION?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="CLIENTVALUATION" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.CLIENTVALUATION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLIENTVALUATION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTVALUATION?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.AUTHORITYVALUATION,'has-warning':errorWarningData.Warning?.AUTHORITYVALUATION}">
                <mat-label>Authority Valuation</mat-label>
                <input style="text-align: right;" matInput [value]="matterCompulsoryAcqForm.get('AUTHORITYVALUATION').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.AUTHORITYVALUATION?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="AUTHORITYVALUATION" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.AUTHORITYVALUATION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.AUTHORITYVALUATION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AUTHORITYVALUATION?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <!-- <mat-form-field appearance="outline" fxFlex="33.33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FIELDNAME,'has-warning':errorWarningData.Warning?.FIELDNAME}">
                <mat-label>Description </mat-label>
                <input matInput type="number" matTooltip="{{(toolTipList)?toolTipList.FIELDNAME?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="FIELDNAME">
                <div  *ngIf="testBool && toolTipList.FIELDNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.FIELDNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FIELDNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field> -->
        </div>
        <div >
            <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFACQUISITION,'has-warning':errorWarningData.Warning?.DATEOFACQUISITION}">
                <mat-label>Date of Acquisition</mat-label>
                <input [matDatepicker]="picker" matInput (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)"  matTooltip="{{(toolTipList)?toolTipList.DATEOFACQUISITION?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="DATEOFACQUISITION">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              
                <div  *ngIf="testBool && toolTipList.DATEOFACQUISITION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATEOFACQUISITION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AUTHORITYVALUATION?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMPENSATIONAMOUNT,'has-warning':errorWarningData.Warning?.COMPENSATIONAMOUNT}">
                <mat-label>Compensation Amount</mat-label>
                <input style="text-align: right;" matInput [value]="matterCompulsoryAcqForm.get('COMPENSATIONAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.COMPENSATIONAMOUNT?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="COMPENSATIONAMOUNT" [leadZero]="true">
                <div  *ngIf="testBool && toolTipList.COMPENSATIONAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COMPENSATIONAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTVALUATION?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATENOTICESENT,'has-warning':errorWarningData.Warning?.DATENOTICESENT}">
                <mat-label>Date Notice Sent</mat-label>
                <input [matDatepicker]="picker1" matInput (dateInput)="choosedDate1('input', $event)" (dateChange)="choosedDate1('change', $event)"  matTooltip="{{(toolTipList)?toolTipList.DATENOTICESENT?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="DATENOTICESENT">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <div  *ngIf="testBool && toolTipList.DATENOTICESENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATENOTICESENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AUTHORITYVALUATION?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
           
                <mat-checkbox class="example pr-4  pl-4" formControlName="DETERMINATIONISSUED" fxFlex="50">
                    Determination Issued
                </mat-checkbox>
          

       
    </div>
</div>
</div>

<div class="one rate_tab_main" fxLayout="column">
    <!-- <div fxFlex="100"> -->
    <form name="WillEstateForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

        <mat-accordion class="faqs-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Property Details</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-details-expandation  [testBool]='testBool' [action]="action" [toolTipList]="toolTipList"></app-property-details-expandation>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- </div> -->
    </form>
</div>