<div class=" one " fxLayout="column" [formGroup]="matterdetailForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MatterNo,'has-warning':errorWarningData.Warning?.MatterNo}">
            <mat-label>Court Matter #</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.MATTERNO?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MatterNo">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COURT,'has-warning':errorWarningData.Warning?.COURT}">
            <mat-label>Court</mat-label>
            <input type="text" placeholder="Matter Type" matInput name="COURT" matTooltip="{{(toolTipList)?toolTipList.COURT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COURT" [matAutocomplete]="auto1">
            <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let optionval of CourtData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example pr-4" fxFlex="50" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.DIVISION,'has-warning':errorWarningData.Warning?.DIVISION}">
            <mat-label>Division</mat-label>
            <input type="text" matInput name="DIVISION" matTooltip="{{(toolTipList)?toolTipList.DIVISION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DIVISION" [matAutocomplete]="auto2">
            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let optionval of DivisionData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field class="example pr-4" fxFlex="33" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.CourtList,'has-warning':errorWarningData.Warning?.CourtList}">
            <mat-label>List</mat-label>
            <input type="text" matInput name="CourtList" matTooltip="{{(toolTipList)?toolTipList.COURTLIST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CourtList" [matAutocomplete]="auto3">
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let optionval of ListData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example" fxFlex="33" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.REGISTRY,'has-warning':errorWarningData.Warning?.REGISTRY}">
            <mat-label>Registry</mat-label>
            <input type="text" matInput name="REGISTRY" matTooltip="{{(toolTipList)?toolTipList.REGISTRY?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="REGISTRY" [matAutocomplete]="auto4">
            <mat-autocomplete #auto4="matAutocomplete">
                <mat-option *ngFor="let optionval of RegistryData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example" fxFlex="34" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ClientType,'has-warning':errorWarningData.Warning?.ClientType}">
            <mat-label>Client Type</mat-label>
            <input type="text" matInput name="ClientType" matTooltip="{{(toolTipList)?toolTipList.CLIENTTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ClientType" [matAutocomplete]="ClientType">
            <mat-autocomplete #ClientType="matAutocomplete">
                <mat-option *ngFor="let optionval of RegistryData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERTITLEBELOW,'has-warning':errorWarningData.Warning?.MATTERTITLEBELOW}">
            <mat-label> Matter Title Below </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.MATTERTITLEBELOW?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MATTERTITLEBELOW">
        </mat-form-field>

        <mat-form-field class="example pr-4" fxFlex="33" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.COURTBELOW,'has-warning':errorWarningData.Warning?.COURTBELOW}">
            <mat-label> Court Below </mat-label>
            <input type="text" matInput name="COURTBELOW" matTooltip="{{(toolTipList)?toolTipList.COURTBELOW?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COURTBELOW" [matAutocomplete]="COURTBELOW">
            <mat-autocomplete #COURTBELOW="matAutocomplete">
                <mat-option *ngFor="let optionval of CourtBelowData" value="{{optionval.LOOKUPFULLVALUE}}">
                    {{optionval.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" [ngClass]="{'has-error':errorWarningData.Error?.CASENUMBERBELOW,'has-warning':errorWarningData.Warning?.CASENUMBERBELOW}">
            <mat-label> Case # Below </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.CASENUMBERBELOW?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CASENUMBERBELOW">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DATEOFHEARINGS,'has-warning':errorWarningData.Warning?.DATEOFHEARINGS}">
            <mat-label>Date Of Hearings</mat-label>
            <input matTooltip="{{(toolTipList)?toolTipList.DATEOFHEARINGS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DATEOFHEARINGS" matInput>

        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATERIALDATE,'has-warning':errorWarningData.Warning?.MATERIALDATE}">
            <mat-label>Material Date</mat-label>
            <input (dateInput)="MaterialDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.MATERIALDATETEXT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="MATERIALDATETEXT" (dateChange)="MaterialDateClick('change', $event)" matInput [matDatepicker]="MaterialDate">
            <mat-datepicker-toggle matSuffix [for]="MaterialDate"></mat-datepicker-toggle>
            <mat-datepicker #MaterialDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DECISION,'has-warning':errorWarningData.Warning?.DECISION}">
            <mat-label>Decision Of</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.DECISION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="DECISION">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COSTESTIMATEIFWINEXGST,'has-warning':errorWarningData.Warning?.COSTESTIMATEIFWINEXGST}">
            <mat-label>Cost Estimate If Successful</mat-label>
            <input matInput type="number" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFWINEXGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="COSTESTIMATEIFWINEXGST">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CostEstimateIfWinIncGST,'has-warning':errorWarningData.Warning?.CostEstimateIfWinIncGST}">
            <mat-label>Inc-GST</mat-label>
            <input name="Inc-GST" matInput type="number" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFWININCGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CostEstimateIfWinIncGST">
            <mat-icon matSuffix class="secondary-text"></mat-icon>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="example" fxFlex="59" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CostEstimateIfFailExGST,'has-warning':errorWarningData.Warning?.CostEstimateIfFailExGST}">
            <mat-label>Cost Estimate If Unsuccessful</mat-label>
            <input matInput type="number" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFFAILEXGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CostEstimateIfFailExGST">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CostEstimateIfFailIncGST,'has-warning':errorWarningData.Warning?.CostEstimateIfFailIncGST}">
            <mat-label>Inc-GST</mat-label>
            <input name="Inc-GST2" matInput type="number" matTooltip="{{(toolTipList)?toolTipList.COSTESTIMATEIFFAILINCGST?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CostEstimateIfFailIncGST">
            <mat-icon matSuffix class="secondary-text"></mat-icon>
        </mat-form-field>
    </div>
</div>