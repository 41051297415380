<div class="popup_main_div email_popup_main select_email_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>Select Email
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="one select_email_main_div">
            <div class="example-containerdata">
                <table mat-table #table [dataSource]="TemplateEmaildata" [@animateStagger]="{value:'50'}" matSort>
                    <ng-container matColumnDef="TYPEICON">
                        <mat-header-cell *matHeaderCellDef mwlResizable 
                            (resizeEnd)="onResizeEnd($event, 'TYPEICON')"
                            mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                            <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <img src="assets/icons/web_app/icon_email_d.ico" matTooltip=""
                                matTooltipPosition="above">
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="NAME">
                        <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable 
                            (resizeEnd)="onResizeEnd($event, 'NAME')"
                            mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                            <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                            Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.NAME }} </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row [style.background]="highlightedRows == row.EMAILGUID ? selectedColore : ''"
                        [ngClass]="highlightedRows == row.EMAILGUID ? 'row-text-colore' : ''"
                        highlightedRows=row.EMAILGUID *matRowDef="let row; columns: displayedColumns;"
                        (click)="clicktitle(row);highlightedRows = row.EMAILGUID " matRipple>
                    </mat-row>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.email_templete"
                (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <button mat-raised-button [mat-dialog-close]="true" color="accent">Select</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
        </div>
    </mat-dialog-actions>
</div>