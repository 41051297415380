<div class="one" fxLayout="column" [formGroup]="matterAddressForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.BUILDINGNAME,'has-warning':errorWarningData.Warning?.BUILDINGNAME}">
            <mat-label>Building Name</mat-label>
            <input matInput formControlName="BUILDINGNAME">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.UNITNUMBER,'has-warning':errorWarningData.Warning?.UNITNUMBER}">
            <mat-label>Unit No.</mat-label>
            <input matInput name="Unit No" formControlName="UNITNUMBER">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETNUMBER,'has-warning':errorWarningData.Warning?.STREETNUMBER}">
            <mat-label>Street No</mat-label>
            <input matInput name="StreetNo" formControlName="STREETNUMBER">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="10" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETNUMBERTO,'has-warning':errorWarningData.Warning?.STREETNUMBERTO}">
            <mat-label>To</mat-label>
            <input matInput name="To" formControlName="STREETNUMBERTO">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETNAME,'has-warning':errorWarningData.Warning?.STREETNAME}">
            <mat-label>Street</mat-label>
            <input name="Street" matInput formControlName="STREETNAME">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STREETTYPE,'has-warning':errorWarningData.Warning?.STREETTYPE}">
            <mat-label>Street Type</mat-label>
            <input type="text" placeholder="State" aria-label="Number" matInput name="STREETTYPE" formControlName="STREETTYPE" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let val of StreetType" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="30" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SUBURB,'has-warning':errorWarningData.Warning?.SUBURB}">
            <mat-label>Suburb</mat-label>
            <input matInput name="Suburb" formControlName="SUBURB">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.STATE,'has-warning':errorWarningData.Warning?.STATE}">
            <mat-label>State</mat-label>
            <input type="text" placeholder="State" aria-label="Number" matInput name="STATE" formControlName="STATE" [matAutocomplete]="auto3">
            <mat-autocomplete #auto3="matAutocomplete">
                <mat-option *ngFor="let val of StateData" value="{{val.LOOKUPFULLVALUE}}">
                    {{val.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.POSTCODE,'has-warning':errorWarningData.Warning?.POSTCODE}">
            <mat-label>Post Code</mat-label>
            <input matInput name="PostCode" formControlName="POSTCODE">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" [ngClass]="{'has-error':errorWarningData.Error?.NATUREOFBUILDING,'has-warning':errorWarningData.Warning?.NATUREOFBUILDING}">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Nature Of Building</mat-label>
            <input matInput name="NatureOfBulding" formControlName="NATUREOFBUILDING">
        </mat-form-field>
    </div>
</div>