import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bank-feed-sidebar',
  templateUrl: './bank-feed-sidebar.component.html',
  styleUrls: ['./bank-feed-sidebar.component.scss']
})
export class BankFeedSidebarComponent implements OnInit {
  @Input() showFiller: boolean;
  activeSubMenu: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  AccountList: any[]=[];
  isLoadingResults: boolean = false;
  UniqueAccountNumber: any;
  constructor(private behaviorService: BehaviorService,private _mainAPiServiceService: MainAPiServiceService,) {
    this.behaviorService.ActiveSubMenu$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        this.activeSubMenu = res
    });

    this.behaviorService.PassDataDashboardToSidebar$.subscribe(data=>{
      if(data && data != null){
        this.setActiveClass(data);
      }
    })
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
    this.getAccountList()
    let BankingData=JSON.parse(localStorage.getItem('BankingDialogDefaultData'));
    if(BankingData && BankingData !=null){
      this.behaviorService.ChartAccountData(BankingData);
      this.UniqueAccountNumber=BankingData.BANKFEEDACCOUNTGUID;
    }
  }

  /**
   * This function is used to get the Account list
   */
  getAccountList():void{
    this.AccountList = [];
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({action: "authorisedaccounts",Data:{}}, "bank-feed").subscribe(data=>{
      if(data.CODE==200  &&  data.STATUS=="success"){
        this.isLoadingResults = false;
        this.AccountList=data.DATA.AUTHORISEDACCOUNTS;
        this.behaviorService.authorisedaccounts$.next(data.DATA.AUTHORISEDACCOUNTS);
      } else {
        this.isLoadingResults = false;
      }
    })
  }

  /**
   * This function is used to set the Active class
   */
  setActiveClass(AccountInformation:any){
    let name = AccountInformation.LINKEDACCOUNTCLASS + ' - ' + AccountInformation.LINKEDACCOUNTNUMBER + ' ' + AccountInformation.LINKEDACCOUNTNAME;
    AccountInformation.name=name
    localStorage.setItem("BankingDialogDefaultData",JSON.stringify(AccountInformation));
    this.behaviorService.ChartAccountData(AccountInformation);
    this.UniqueAccountNumber=AccountInformation.BANKFEEDACCOUNTGUID

  }
}
