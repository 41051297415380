<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_matter_main" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="matterdetailForm" id="matterdetailForm" [formGroup]="matterdetailForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                <div fxLayout="row" class="mat_field_btm_padding" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example pr-4" fxFlex="40" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.MATTERCLASS,'has-warning':errorWarningData.Warning?.MATTERCLASS}">
                        <mat-label>Class</mat-label>
                        <mat-select  formControlName="MATTERCLASS" (ngModelChange)="Classtype($event)">
                            <mat-option *ngFor="let Classoption of Classdata" value="{{Classoption.LOOKUPFULLVALUE}}">
                                {{Classoption.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{(toolTipList)?toolTipList.MATTERCLASS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERCLASS?.COPYVALUE:'')">file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example pr-4" fxFlex="40" [ngClass]="{'has-error':errorWarningData.Error?.Shortname,'has-warning':errorWarningData.Warning?.Shortname}">
                        <mat-label>{{barristerTitle}}</mat-label>
                        <input matInput formControlName="SHORTNAME" matTooltip="{{(toolTipList && !testBool)?toolTipList.SHORTNAME.COPYVALUE:''}}"
                        [matTooltipPosition]="toolTipPostion" >
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SHORTNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHORTNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
                    </mat-form-field>
                    <!-- <mat-checkbox formControlName="ACTIVE">Active Matter</mat-checkbox> -->
                    <!-- (selectionChange)="MatterChange($event.value)" -->
                    <mat-form-field  appearance="outline"class="example pr-4" fxFlex="40">
                        <mat-label>Active</mat-label>
                        <mat-select formControlName="ACTIVE"   panelClass="myPanelClass" disableOptionCentering>
                            <mat-option value="Active">Active</mat-option>
                            <mat-option value="Inactive">Inactive</mat-option>
                            <mat-option value="Prospect">Prospect</mat-option>
                            <mat-option value="Not Proceeding">Not Proceeding</mat-option>
                        </mat-select>
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACTIVE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ACTIVE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example"  fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERSTATUS,'has-warning':errorWarningData.Warning?.MATTERSTATUS }">
                        <mat-label>Matter Status</mat-label>
                        <input type="text" placeholder="Matter Status" matInput name="MATTERSTATUS" formControlName="MATTERSTATUS"
                            [matAutocomplete]="auto2"  matTooltip="{{(toolTipList)?toolTipList.MATTERSTATUS?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion">
                            <div  *ngIf="testBool && toolTipList.MATTERSTATUS" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERSTATUS?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTERSTATUS.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                        <mat-autocomplete #auto2="matAutocomplete"  >
                            <mat-option *ngFor="let MatterStatusLookup of MatterStatusLookupsData" value="{{MatterStatusLookup.LOOKUPFULLVALUE}}">
                                {{MatterStatusLookup.LOOKUPFULLVALUE}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline"  fxFlex={{fxFlexForCol1}} class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FirmGuid,'has-warning':errorWarningData.Warning?.FirmGuid}">
                        <mat-label>{{clientField}}</mat-label>
                        <!-- <input matInput formControlName="Clientmattertext" matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME ?.HOVER:''}}"
                        [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CONTACTNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTNAME?.COPYVALUE:'')">file_copy</mat-icon></div>

                        <mat-icon matSuffix (click)='ContactMatter()'>
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon> -->
                        <mat-chip-list #chipList aria-label="Matter selection">
                            <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input  matInput
                                (keydown.Tab)="datashow($event)"  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME ?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"
                                formControlName="Clientmattertext" #MatterInput1 [matAutocomplete]="autoMatter"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                            <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)">
                                <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="(this.MatterInput && this.MatterInput.nativeElement.value =='')? ContactMatter():null"   [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>

                    <!-- <button mat-fab color="primary" class="add-contect-btn" *ngIf="this.action === 'new'" (click)="AddContactsDialog()">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button> -->
                    <a role="button" class="iconbtn-plus mr-4 plus-middle"  [ngClass]="appPermissions[3]?.Create==0 ? 'disabled-click large-button' : 'large-button'" *ngIf="action === 'new'" (click)="(this.MatterInput && this.MatterInput.nativeElement.value =='')?AddContactsDialog():null">
                        <span class="mat-button-wrapper">
                            <mat-icon class=''>add_circle</mat-icon>
                        </span>
                    </a>




                    <mat-form-field *ngIf="currentuser.PRODUCTTYPE=='Barrister'" class="pr-4" appearance="outline" fxFlex="{{barristerVersionFieldFlag.ClientNameField}}" [ngClass]="{'has-error':errorWarningData.Error?.CLIENTNAME,'has-warning':errorWarningData.Warning?.CLIENTNAME}">
                        <mat-label>Client Name</mat-label>
                        <input matInput formControlName="CLIENTNAME"  matTooltip="{{(toolTipList)?toolTipList.CLIENTSTATUS?.HOVER:''}}"
                        [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList.CLIENTNAME" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTNAME?.COPYVALUE:'')" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLIENTNAME.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="{{barristerVersionFieldFlag.ClientReferenceField}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.REFERENCE,'has-warning':errorWarningData.Warning?.REFERENCE}">
                        <mat-label>Client Reference</mat-label>
                        <input name="ClientReference" formControlName="REFERENCE" matInput matTooltip="{{(toolTipList)?toolTipList.REFERENCE?.HOVER:''}}"
                        [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList.REFERENCE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REFERENCE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REFERENCE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OTHERREFERENCE,'has-warning':errorWarningData.Warning?.OTHERREFERENCE}">
                        <mat-label>Other Ref</mat-label>
                        <input matInput formControlName="OTHERREFERENCE" matTooltip="{{(toolTipList)?toolTipList.OTHERREFERENCE?.HOVER:''}}"
                        [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList.OTHERREFERENCE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OTHERREFERENCE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OTHERREFERENCE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                        <mat-icon matSuffix class="secondary-text"></mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="99.5" [ngClass]="{'has-error':errorWarningData.Error?.Matter,'has-warning':errorWarningData.Warning?.Matter}">
                        <mat-label>Matter Description</mat-label>
                        <textarea formControlName="MATTER" matInput [matAutocomplete]="descriptionText" matTooltip="{{(toolTipList)?toolTipList.MATTER?.HOVER:''}}"
                        [matTooltipPosition]="toolTipPostion"> </textarea>
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTER?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.MATTER.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>

                        <!-- class="mat-option-text"  -->
                        <mat-autocomplete  #descriptionText="matAutocomplete" [panelWidth]="800" (optionSelected)="LookupsChange($event.option.value)">
                            <mat-option class="mat-option_1" *ngFor="let Lookups of filteredOptions | async" [value]="Lookups.LOOKUPFULLVALUE">
                                {{Lookups.LOOKUPFULLVALUE}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div  fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMMENCEMENTDATE,'has-warning':errorWarningData.Warning?.COMMENCEMENTDATE}">
                        <mat-label>Commencement Date</mat-label>
                        <input (dateInput)="CommencementDate('input', $event)" formControlName="DATETEXT" (dateChange)="CommencementDate('change', $event)" matInput [matDatepicker]="picker1" matTooltip="{{(toolTipList)?toolTipList.DATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <div  *ngIf="testBool && toolTipList.DATE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DATE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DUEDATE ,'has-warning':errorWarningData.Warning?.DUEDATE }">
                        <mat-label>Due Date</mat-label>
                        <input (dateInput)="DueDate('input', $event)" formControlName="DUEDATEForm"  (dateChange)="DueDate('change', $event)" matInput [matDatepicker]="picker4" matTooltip="{{(toolTipList)?toolTipList.DUEDATE?.HOVER :''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                        <div  *ngIf="testBool && toolTipList.DUEDATE" class="tooltip-wrap"   (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUEDATE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.DUEDATE.COPYVALUE:''}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.COMPLETEDDATE,'has-warning':errorWarningData.Warning?.COMPLETEDDATE}">
                        <mat-label>Completed Date</mat-label>
                        <input (dateInput)="CompletedDate('input', $event)" formControlName="COMPLETEDDATETEXT" (dateChange)="CompletedDate('change', $event)" matInput name="CompletedDate" [matDatepicker]="picker3" matTooltip="{{(toolTipList)?toolTipList.COMPLETEDDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                        <div  *ngIf="testBool && toolTipList.COMPLETEDDATE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMPLETEDDATE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.COMPLETEDDATE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.LASTREVIEWDATE,'has-warning':errorWarningData.Warning?.LASTREVIEWDATE}">
                        <mat-label>Last Review Date</mat-label>
                        <input (dateInput)="LastReviewpicker('input', $event)" (dateChange)="LastReviewpicker('change', $event)" name="lastreviewpicker" matInput [matDatepicker]="lastreviewpicker" formControlName="LASTREVIEWDATETEXT" matTooltip="{{(toolTipList)?toolTipList.LASTREVIEWDATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="lastreviewpicker"></mat-datepicker-toggle>
                        <mat-datepicker #lastreviewpicker></mat-datepicker>
                        <div  *ngIf="testBool && toolTipList.LASTREVIEWDATE" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LASTREVIEWDATE?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.LASTREVIEWDATE.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>

                <div *ngIf="currentuser.PRODUCTTYPE=='Solicitor'" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.OwnerGuid,'has-warning':errorWarningData.Warning?.OwnerGuid}">
                        <mat-label>Matter Owner</mat-label>
                        <input matInput readonly formControlName="OWNERNAME"  matTooltip="{{(toolTipList)?toolTipList.OWNERNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList.OWNERNAME" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.OWNERNAME?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.OWNERNAME.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                        <mat-icon matSuffix  *ngIf="matterdetailForm.controls['OWNERNAME'].value !== ''" (click)="RestFolderPath('OWNERNAME')" class="float-left reset-path-btn">close</mat-icon>
                        <mat-icon matSuffix (click)="selectMatter();">
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Matter Owner" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PrimaryFeeEarnerGuid,'has-warning':errorWarningData.Warning?.PrimaryFeeEarnerGuid}">
                        <mat-label>Primary Fee Earner</mat-label>
                        <input matInput readonly formControlName="PRIMARYFEEEARNERNAME"  matTooltip="{{(toolTipList)?toolTipList.PRIMARYFEEEARNERNAME:''}}" [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList.PRIMARYFEEEARNERNAME" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRIMARYFEEEARNERNAME?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PRIMARYFEEEARNERNAME.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                        <mat-icon matSuffix  *ngIf="matterdetailForm.controls['PRIMARYFEEEARNERNAME'].value !== ''" (click)="RestFolderPath('PRIMARYFEEEARNERNAME')" class="float-left reset-path-btn">close</mat-icon>
                        <mat-icon matSuffix (click)="selectFeeEarner();">
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Primary Fee Earner" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example" fxFlex="21" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ASSISTANTGUID,'has-warning':errorWarningData.Warning?.ASSISTANTGUID}">
                        <mat-label>Person Assisting</mat-label>
                        <input matInput readonly formControlName="ASSISTANTNAME"  matTooltip="{{(toolTipList)?toolTipList.ASSISTANTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <div  *ngIf="testBool && toolTipList.ASSISTANTNAME" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ASSISTANTNAME?.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ASSISTANTNAME.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                         <mat-icon matSuffix  *ngIf="matterdetailForm.controls['ASSISTANTNAME'].value !== ''" (click)="RestFolderPath('ASSISTANTNAME')" class="float-left reset-path-btn">close</mat-icon>
                        <mat-icon matSuffix (click)="selectPersonAssist();">
                            <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Person Assisting" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <mat-checkbox [ngClass]="SECURITYOPTION? 'pb-4':'pb-20'" *ngIf="'' | productType" [(ngModel)]="SECURITYOPTION" formControlName="SECURITYOPTION" fxFlex="15" class="align-slef-baseline" (click)="(matterAccessTier?.ALLOWED !==1)? goTo(matterAccessTier?.URL):true" (click)="(matterAccessTier?.ALLOWED !==1)? false:true" [matTooltip]="('Matter Access' | tierPermission : 'Matter Access') && ('Matter Access' | tierPermission : 'Matter Access').ALLOWED !== 1? ('Matter Access' | tierPermission : 'Matter Access').DESCRIPTION :''" matTooltipPosition="above">Restrict Access<br> to this Matter
                        <img *ngIf="(('Matter Access' | tierPermission : 'Matter Access') && ('Matter Access' | tierPermission : 'Matter Access').ALLOWED !== 1)" src="assets/icons/web_app/Feature_is_Locked.ico" class="feature_locked_text">
                    </mat-checkbox>
                    <mat-form-field [ngClass]="SECURITYOPTION? 'pb-4':'pb-20'"  class="example" appearance="outline" fxFlex="23.5">
                        <mat-label>Tick Users that should have access</mat-label>
                        <mat-select (selectionChange)="MatterUserChange($event.value)" formControlName="MATTERACCESSLIST" multiple [disabled]="!SECURITYOPTION">
                            <!-- <mat-option value="all">All</mat-option>
                            <mat-option value="">None</mat-option> -->
                            <mat-option *ngFor="let MatterDrop of MatterDropData" value="{{MatterDrop.USERGUID}}">
                                {{MatterDrop.USERID}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" [ngClass]="SECURITYOPTION? 'pb-4':'pb-20'"> -->

                <!-- </div> -->
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.NOTES,'has-warning':errorWarningData.Warning?.NOTES}">
                        <mat-label>Notes</mat-label>
                        <textarea matInput formControlName="NOTES"  matTooltip="{{(toolTipList)?toolTipList.NOTES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" (keyup)="onKeyPressTextMessage($event)" id="notesData"></textarea>
                        <div  *ngIf="testBool && toolTipList.NOTES" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTES.COPYVALUE:'')"><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.NOTES.COPYVALUE:''}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>

<!-- ________________________________________commet Whole div to-do ______"Change new/update matter window to not use tabs, but a concertina instead, like you use on the Details tab". -->
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="min_height">
                    <mat-tab-group mat-align-tabs="start" fxLayout="column">
                        <mat-tab label="General" mat-align-tabs="start" fxLayout="column">
                            <app-general [testBool]='testBool' [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData" [userType]="userType" [toolTipList]="toolTipList"></app-general>
                        </mat-tab>
                        <mat-tab label="{{tabTitle}}" >
                            <app-client [errorWarningData]="errorWarningData" [matterdetailForm]="matterdetailForm" [isEditMatter]="isEditMatter" (CorrespondDetail)="corDetailBack($event)" [toolTipList]="toolTipList"></app-client>
                        </mat-tab>
                        <mat-tab label="Client" *ngIf="'' | productType">
                            <app-matter-client [testBool]='testBool' [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData"  [toolTipList]="toolTipList"></app-matter-client>
                        </mat-tab>
                        <mat-tab label="Rates">
                            <app-rates  [testBool]='testBool' [matterdetailForm]="matterdetailForm" [isEditRate]="isEditRate" [action]="action" [errorWarningData]="errorWarningData" (rateDetail)="rateDetailBack($event)" [toolTipList]="toolTipList" ></app-rates>
                        </mat-tab>
                        <mat-tab label="Case">
                            <app-case-detail  [testBool]='testBool' [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-case-detail>
                        </mat-tab>
                        <mat-tab label="Details">
                            <div class="main-detail-tab">
                                <div *ngIf="classtype==''||classtype=='1'||classtype=='2'|| classtype=='0' || classtype=='15'|| classtype=='17'|| classtype=='255'|| classtype=='16'" style="text-align: center;padding-top: 60px;"><strong> No additional fields</strong>
                                </div>
                                <app-commercial [testBool]='testBool' *ngIf="classtype=='19'" [action]="action" [toolTipList]="toolTipList"></app-commercial>
                                <app-compensation [testBool]='testBool' *ngIf="classtype=='8' || classtype=='21' || classtype=='22' || classtype=='23' || classtype=='24' || classtype=='25' || classtype=='26'" [action]="action" [toolTipList]="toolTipList">
                                </app-compensation>
                                <app-criminal  [testBool]='testBool'*ngIf="classtype=='18'" [action]="action" [toolTipList]="toolTipList"></app-criminal>
                                <app-family [testBool]='testBool' *ngIf="classtype=='10'" [action]="action" [toolTipList]="toolTipList"></app-family>
                                <app-immigration  [testBool]='testBool' *ngIf="classtype=='6'" [action]="action" [toolTipList]="toolTipList"></app-immigration>
                                <app-maritime [testBool]='testBool' *ngIf="classtype=='20'" [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-maritime>
                                <app-mortgage-finance [testBool]='testBool' *ngIf="classtype=='12'" [action]="action" [toolTipList]="toolTipList"></app-mortgage-finance>
                                <app-motor-vehicle-accident [testBool]='testBool' *ngIf="classtype=='13'" [action]="action" [toolTipList]="toolTipList">
                                </app-motor-vehicle-accident>
                                <app-compulsory-acquisition [testBool]='testBool' *ngIf="classtype=='9'" [action]="action" [toolTipList]="toolTipList"></app-compulsory-acquisition>
                                <app-leasing [testBool]='testBool' *ngIf="classtype=='11'" [action]="action" [toolTipList]="toolTipList"></app-leasing>
                                <app-property  [testBool]='testBool'*ngIf="classtype=='3' || classtype=='29'" [action]="action" [classType]='classtype' [toolTipList]="toolTipList"></app-property>
                                <app-property-purchase [testBool]='testBool' *ngIf="classtype=='4' || classtype=='30'" [action]="action" [classType]='classtype'  [toolTipList]="toolTipList"></app-property-purchase>
                                <app-strata [testBool]='testBool' *ngIf="classtype=='5'" [action]="action" [toolTipList]="toolTipList"></app-strata>
                                <app-wills-estate [testBool]='testBool' *ngIf="classtype=='7' || classtype=='28'" [action]="action" [toolTipList]="toolTipList"></app-wills-estate>
                                <app-trademark-ip [testBool]='testBool' *ngIf="classtype=='14'" [action]="action" [toolTipList]="toolTipList"></app-trademark-ip>
                                <app-debt-recovery [testBool]='testBool' *ngIf="classtype=='27'" [action]="action" [toolTipList]="toolTipList"></app-debt-recovery>
                            </div>
                        </mat-tab>
                        <mat-tab label="Others">
                            <app-others [testBool]='testBool' [matterdetailForm]="matterdetailForm" [isEditMatter]="action" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList">
                            </app-others>
                        </mat-tab>
                    </mat-tab-group>
                </div> -->
                <!-- <mat-expansion-panel  label="General" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" class="Bold_panel_header">General</mat-expansion-panel-header >
                        <app-general [testBool]='testBool' [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData" [userType]="userType" [toolTipList]="toolTipList"></app-general>

                </mat-expansion-panel> -->

                <mat-expansion-panel label="General" mat-align-tabs="start" fxLayout="column">
                    <mat-expansion-panel-header class="Bold_panel_header" > {{(currentuser.PRODUCTTYPE=='Solicitor')?'Client , ' + tabTitle : 'Correspondents and Parties'}}</mat-expansion-panel-header >
                        <app-client [errorWarningData]="errorWarningData" [matterdetailForm]="matterdetailForm" [isEditMatter]="isEditMatter" (CorrespondDetail)="corDetailBack($event)" [toolTipList]="toolTipList" [action]="action"></app-client>

                </mat-expansion-panel>

                <!-- <mat-expansion-panel label="Client" mat-align-tabs="start" fxLayout="column" *ngIf="'' | productType">
                    <mat-expansion-panel-header class="Bold_panel_header" *ngIf="'' | productType">Client</mat-expansion-panel-header >

                        <app-matter-client [testBool]='testBool' [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData"  [toolTipList]="toolTipList"></app-matter-client>

                </mat-expansion-panel> -->

            <mat-expansion-panel label="Rates" mat-align-tabs="start" fxLayout="column">
                <mat-expansion-panel-header class="Bold_panel_header">Costs Agreement and Rates</mat-expansion-panel-header>
                    <app-rates  [testBool]='testBool' [matterdetailForm]="matterdetailForm" [isEditRate]="isEditRate" [action]="action" [errorWarningData]="errorWarningData" (rateDetail)="rateDetailBack($event)" [toolTipList]="toolTipList" ></app-rates>
            </mat-expansion-panel>

            <mat-expansion-panel label="Case" mat-align-tabs="start" fxLayout="column">
                <mat-expansion-panel-header class="Bold_panel_header">Case/Litigation Details</mat-expansion-panel-header>
                    <app-case-detail  [testBool]='testBool' [matterdetailForm]="matterdetailForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-case-detail>
            </mat-expansion-panel>

            <mat-expansion-panel label="Details" mat-align-tabs="start" fxLayout="column">
                <mat-expansion-panel-header class="Bold_panel_header">{{ClassNameForDetail}} Details</mat-expansion-panel-header>
                    <div class="main-detail-tab">
                        <div *ngIf="classtype==''||classtype=='Advice'||classtype=='Litigation'|| classtype=='General' || classtype=='Internal'" style="text-align: center;padding-top: 60px;"><strong> No additional fields</strong>
                        </div>
                        <app-commercial [testBool]='testBool' *ngIf="classtype=='Commercial'" [action]="action" [toolTipList]="toolTipList" [classType]='classtype'  ></app-commercial>
                        <app-compensation [testBool]='testBool' *ngIf="classtype=='Compensation' || classtype=='Employment' || classtype=='Medical Negligence' || classtype=='Public Liability' || classtype=='Total and Permanent Disability' || classtype=='Working Injuries Damages' || classtype=='Workers Compensation'" [action]="action" [toolTipList]="toolTipList">
                            [classType]='classtype' </app-compensation>
                        <app-criminal  [testBool]='testBool'*ngIf="classtype=='Criminal'" [action]="action" [classType]='classtype'   [toolTipList]="toolTipList"></app-criminal>
                        <app-family [testBool]='testBool' *ngIf="classtype=='Family Law'" [action]="action" [classType]='classtype'  [toolTipList]="toolTipList"></app-family>
                        <app-domestic-violence *ngIf="classtype=='Domestic Violence'" [testBool]='testBool'  [action]="action" [classType]='classtype'  [toolTipList]="toolTipList"></app-domestic-violence>
                        <app-immigration  [testBool]='testBool' *ngIf="classtype=='Immigration'" [action]="action" [classType]='classtype'  [toolTipList]="toolTipList"></app-immigration>
                        <app-maritime [testBool]='testBool' *ngIf="classtype=='Maritime'" [action]="action"  [classType]='classtype' [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-maritime>
                        <app-mortgage-finance [testBool]='testBool' *ngIf="classtype=='Mortgage Finance'" [action]="action" [toolTipList]="toolTipList"></app-mortgage-finance>
                        <app-motor-vehicle-accident [testBool]='testBool' *ngIf="classtype=='Motor Vehicle Accident'" [classType]='classtype'  [action]="action" [toolTipList]="toolTipList">
                        </app-motor-vehicle-accident>
                        <app-export-process  [testBool]='testBool' *ngIf="classtype =='Expert Process'" [action]="action" [classType]='classtype' [toolTipList]="toolTipList"></app-export-process>
                        <app-matter-mediation  [testBool]='testBool' *ngIf="classtype=='Mediation'" [action]="action" [classType]='classtype' [toolTipList]="toolTipList"></app-matter-mediation>
                        <app-training  [testBool]='testBool' *ngIf="classtype=='Training'" [action]="action" [classType]='classtype' [toolTipList]="toolTipList"></app-training>
                        <app-compulsory-acquisition [testBool]='testBool' *ngIf="classtype=='Compulsory Acquisition'" [action]="action" [toolTipList]="toolTipList"></app-compulsory-acquisition>
                        <app-leasing [testBool]='testBool' *ngIf="classtype=='Leasing'" [action]="action" [classType]='classtype'   [toolTipList]="toolTipList"></app-leasing>
                        <app-property  [testBool]='testBool'*ngIf="classtype=='Property Sale' || classtype=='Business Sale'" [action]="action" [classType]='classtype' [toolTipList]="toolTipList"></app-property>
                        <app-property-purchase [testBool]='testBool' *ngIf="classtype=='Property Purchase' || classtype=='Business Purchase'" [classType]='classtype'   [action]="action" [classType]='classtype'  [toolTipList]="toolTipList"></app-property-purchase>
                        <app-strata [testBool]='testBool' *ngIf="classtype=='Strata'" [action]="action" [classType]='classtype'  [toolTipList]="toolTipList"></app-strata>
                        <app-wills-estate [testBool]='testBool' *ngIf="classtype=='Estate' || classtype=='Wills, POA & EG' || classtype=='Wills and Estate' " [classType]='classtype'   [action]="action" [toolTipList]="EstatetoolTipList"></app-wills-estate>
                        <app-trademark-ip [testBool]='testBool' *ngIf="classtype=='Trademark/IP'" [classType]='classtype'  [action]="action" [toolTipList]="toolTipList"></app-trademark-ip>
                        <app-debt-recovery [testBool]='testBool' *ngIf="classtype=='Debt Recovery'" [classType]='classtype'  [action]="action" [toolTipList]="toolTipList"></app-debt-recovery>
                    </div>
            </mat-expansion-panel>
            <mat-expansion-panel label="Case" mat-align-tabs="start" fxLayout="column">
                <mat-expansion-panel-header class="Bold_panel_header">Other</mat-expansion-panel-header>
                    <app-others [testBool]='testBool' [matterdetailForm]="matterdetailForm" [isEditMatter]="action" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList">
                    </app-others>
            </mat-expansion-panel>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="ondialogSaveClick()" [disabled]="isspiner" *ngIf="action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="ondialogSaveClick()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>
<!-- </form> -->
