import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
// import { startOfDay, isSameDay, isSameMonth } from "date-fns";
// import {
//     CalendarEvent,
//     CalendarEventTimesChangedEvent,
//     CalendarMonthViewDay,
//     CalendarWeekViewBeforeRenderEvent,
//     CalendarEventAction,
// } from "angular-calendar";
import { fuseAnimations } from "src/@fuse/animations";
// import { DiaryService } from '..//diary.service';
// import { DiaryEventModel } from './event.model';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import * as $ from "jquery";
import {
    MatDialogRef,
    MatDialog,
    MatDialogConfig,
} from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { ToastrService } from "ngx-toastr";
import {
    CellClickEventArgs,
    EventRenderedArgs,
    EventSettingsModel,
    GroupModel,
    PopupOpenEventArgs,
    ScheduleComponent,
    TimeScaleModel,
    View,
} from "@syncfusion/ej2-angular-schedule";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { UserSelectPopupComponent } from "../../matters/user-select-popup/user-select-popup.component";
import { LoggedonUserComponent } from "../loggedon-user/loggedon-user.component";
import { BehaviorService } from "@_services/Behavior.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { TimersService } from "@_services/timers.service";
import { TierPermissionPipe } from "@_pipes/tier-permission.pipe";

@Component({
    selector: "app-user-activity",
    templateUrl: "./user-activity.component.html",
    styleUrls: ["./user-activity.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [TierPermissionPipe],
})
export class UserActivityComponent implements OnInit, OnDestroy {
    public selectedDate: Date = new Date();
    public group: GroupModel = {
        resources: ["Owners"],
    };
    class: boolean;
    public allowMultipleOwner: Boolean = true;
    ownerDataSource: any;
    SendParam: { USERGUID: any; DATESTART: any; DATEEND: any };
    CurrentDate: any = new Date();
    public eventSettings: EventSettingsModel;
    public eventSettings2: EventSettingsModel;
    allowMultipleCategory: any;
    tempEvent: any;
    @ViewChild("scheduleObj", { static: false })
    public scheduleObj: ScheduleComponent;
    public TimeScale: TimeScaleModel;
    public currentView: View;
    public rowAutoHeight: boolean = false;
    theme_type = localStorage.getItem("theme_type");
    selectedColore =
        this.theme_type == "theme-default" ? "rebeccapurple" : "#43a047";
    // dialogRef: MatDialogRef<DairyDailogComponent, any>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent, any>;
    DairyData: any;
    firstDay: any = new Date(
        this.CurrentDate.getFullYear(),
        this.CurrentDate.getMonth() - 1,
        1
    );
    lastDay: any = new Date(
        this.CurrentDate.getFullYear(),
        this.CurrentDate.getMonth() + 2,
        0
    );
    currentUser = JSON.parse(localStorage.getItem("currentUser"));
    isLoading: boolean = false;
    openWebsite: any;
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions")!);
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    sub6: Subscription;
    sub7: Subscription;
    constructor(
        private route: ActivatedRoute,
        private _httpClient: HttpClient,
        private behaviorService: BehaviorService,
        private _matDialog: MatDialog,
        private dialog: MatDialog,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        private Timersservice: TimersService,
        public datepipe: DatePipe,
        private tierpermission: TierPermissionPipe,
        private sanitizer: DomSanitizer,
        public MatDialog: MatDialog
    ) {
        localStorage.setItem("istrackid", "AccMangPaymentComponent");
        this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
            if (
                localStorage.getItem("istrackid") ==
                    "AccMangPaymentComponent" &&
                result["click"] &&
                result["data"]["HOGetPaymentDetailURL"]
            ) {
                this.ngOnInit();
            }
        });
        this.sub2 = this.behaviorService.calanderViewType$.subscribe(
            (result) => {
                if (result) {
                    this.currentView = result;
                }
            }
        );
        this.sub3 = this.behaviorService.TimeScale$.subscribe((result) => {
            if (result) {
                this.TimeScale = {
                    enable: true,
                    interval: 60,
                    slotCount: result,
                };
            }
        });
        this.sub4 = this.behaviorService.forUserActivity$.subscribe(
            (result) => {
                if (result) {
                    this.GetAppoiment();
                    this.behaviorService.forUserActivity(null);
                }
            }
        );
        this.behaviorService.setactiveTab(33);
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.sub5 = this.behaviorService.permissionCheckforHeight$.subscribe(
            (data) => {
                this.class = data;
            }
        );
        this.GetAppoiment();
        this.behaviorService.loadingSystemSetting$.next(true);
        this.behaviorService.SystemSettingUserTab$.next({
            menu: "activity",
        });
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.sub6?.unsubscribe();
        this.sub7?.unsubscribe();
    }

   /**
    * This function is used to show user logged on popup
    */
    showUserLoggedOnPopup() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        const dialogRef = this.dialog.open(LoggedonUserComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            //   const animal = result;
        });
    }

    /**
     * This function is used to get Appointment data.
     */
    async GetAppoiment() {
        this.tempEvent = [];
        let selectedUser = JSON.parse(
            localStorage.getItem("SelectedUserActivityUsers")
        );
        if (selectedUser && selectedUser.length) {
            this.ownerDataSource = selectedUser;
        } else {
            this.ownerDataSource = [
                {
                    FULLNAME: this.currentUser.UserName,
                    USERGUID: this.currentUser.UserGuid,
                },
            ];
        }
        this.getonebyone(this.ownerDataSource);
    }

    /**
     * This function is used to get the data one by one
     * @param users-users data
     */
    async getonebyone(users) {
        for (let i = 0; i < users.length; i++) {
            this.isLoading = true;
            await new Promise<void>(async (next, reject) => {
                const SendParam = {
                    Action: "GetData",
                    Filters: { UserGuid: users[i].USERGUID },
                };
                this._mainAPiServiceService
                    .getSetData(SendParam, "user-activity")
                    .subscribe(
                        (res) => {
                            this.isLoading = false;
                            this.behaviorService.loadingAccountMNG(
                                "useractivity"
                            );
                            this.behaviorService.loadingUserMenuResult(false);

                            if (res.CODE == 200 && res.STATUS == "success") {
                                if ($.isEmptyObject(res.DATA) == true) {
                                    this.tempEvent.push({});
                                } else {
                                    // this.behaviorService.DiaryData(res.DATA.APPOINTMENTS[0]);

                                    res.DATA.LOGONHISTORY.forEach(
                                        (itemsdata) => {
                                            this.tempEvent.push({
                                                StartTime: this.dateformat(
                                                    this.changeformat(
                                                        itemsdata.LOGGEDINDATE
                                                    ) +
                                                        " " +
                                                        this.convertTime12to24(
                                                            itemsdata.LOGGEDINTIME
                                                        )
                                                ),
                                                EndTime: this.dateformat(
                                                    this.changeformat(
                                                        itemsdata.LOGGEDOUTDATE
                                                    ) +
                                                        " " +
                                                        this.convertTime12to24(
                                                            itemsdata.LOGGEDOUTTIME
                                                        )
                                                ),
                                                Subject:
                                                    "(" +
                                                    this.tConvert(
                                                        itemsdata.LOGGEDINTIME
                                                    ) +
                                                    ") -" +
                                                    itemsdata.USERNAME,
                                                IsAllDay: false,
                                                // DairyRowClickData: itemsdata.APPOINTMENTGUID,
                                                // Id: itemsdata.APPOINTMENTGUID,
                                                OwnerId: users[i].USERGUID,
                                                USERGUID: users[i].USERGUID,
                                                FULLNAME: users[i].FULLNAME,
                                                // Color : `#${itemsdata.CATEGORYCOLOUR }`
                                            });
                                        }
                                    );
                                }
                                this.eventSettings = {
                                    dataSource: this.tempEvent,
                                };
                                this.scheduleObj.refreshEvents();
                                next();
                            }
                        },
                        (err) => {
                            this.isLoading = false;
                            this.toastr.error(err);
                            this.behaviorService.loadingUserMenuResult(false);
                        },
                        reject
                    );
            });
        }
    }

/**
 * This function is used to apply category color
 * @param event -event data
 * @returns category
 */
    applyCategoryColor(event: EventRenderedArgs) {
        const element = event;
        let categoryColor: string = element.data.Color as string;
        if (!element.element || !categoryColor) {
            return;
        }
        if (this.scheduleObj.currentView === "Agenda") {
            (element.element.firstChild as HTMLElement).style.borderLeftColor =
                categoryColor;
        } else {
            element.element.style.backgroundColor = categoryColor;
        }
    }

    /**
     * This function is used to convert the time data
     * @param timeString -time string
     * @returns data
     */
    tConvert(timeString) {
        let hourEnd: any = timeString.indexOf(":");
        let H: any = +timeString.substr(0, hourEnd);
        // let h: any = H % 12 || 12;
        // let ampm: any = H < 12 ? "AM" : "PM"; //no need to check
        return (
            H +
            timeString.substr(hourEnd, 3) +
            " " +
            timeString.substr(timeString.length - 2, timeString.length)
        );
    }

    /**
     * This function is used to change the format data
     * @param dates -date data
     * @returns date.
     */
    changeformat(dates) {
        var d = dates;
        var datess = d.split("/").reverse().join("/");
        var year = datess.substring(0, 4);
        var month = datess.substring(5, 7);
        var day = datess.substring(8, 10);
        return month + "/" + day + "/" + year;
    }
   
    /**
     * This function is used to format the date
     * @param d -current date
     * @returns new date
     */
    dateformat(d) {
        let date = new Date(d);
        return date.toISOString();
    }

    /**
     * This function is used to convert the time 12 to 24
     * @param time -current time data
     * @returns time
     */
    convertTime12to24(time) {
        var hours = parseInt(time.substr(0, 2));
        if (
            (time.indexOf("am") != -1 || time.indexOf("AM") != -1) &&
            hours == 12
        ) {
            time = time.replace("12", "0");
        }
        if (
            (time.indexOf("pm") != -1 || time.indexOf("PM") != -1) &&
            hours < 12
        ) {
            time = time.replace(hours, hours + 12);
        }
        return time.replace(/(am|pm|AM|PM)/, "");
    }

    /**
     * This function is used to open the popup data
     * @param args -argument data
     */
    onPopupOpen(args: PopupOpenEventArgs): void {
        if (args.type === "Editor") {
            args.data["type"] = "ScheduleEditor";
            // this.editEvent('edit', args.data, '');
            args.cancel = true;
        }
        if (args.type === "DeleteAlert") {
            // this.deleteEvent(args.data);
            args.cancel = true;
        }
        if (args.type === "QuickInfo") {
            args.cancel = true;
        }
    }

    /**
     * This function is used to click
     * @param args -args data
     */
    onCellClick(args: CellClickEventArgs) {
        this.behaviorService.forDiaryRefersh(args);
    }

    /**
     * This function is used to double click on row
     * @param args 
     */
    onCellDoubleClick(args: CellClickEventArgs) {
        // this.editEvent('new', args, '');
        args.cancel = true;
    }

    /**
     * This function is used to onActionComplete data
     * @param args 
     */
    onActionComplete(args: any): void {
        if (
            args.requestType === "dateNavigate" ||
            args.requestType === "viewNavigate"
        ) {
            let ScheduleDateRange = this.scheduleObj.getCurrentViewDates();
            this.firstDay = ScheduleDateRange[0];
            this.lastDay = ScheduleDateRange[ScheduleDateRange.length - 1];
            this.GetAppoiment();
        }
    }

    /**
     * This function is used to setView Type
     * @param params 
     */
    setViewType(params: any) {
        const pipe = this.tierpermission.transform("Combined Calendar");
        if (pipe.ALLOWED !== 1 && params == "Combined") {
            this.goTo(pipe.URL);
        } else {
            this.sub6 = this.behaviorService.UseCalanderViewType$.subscribe(
                (result) => {
                    if (result != null) {
                        this.CurrentDate = result;
                    } else {
                        this.CurrentDate = new Date();
                    }
                    // this.getEvents();
                }
            );
            this.behaviorService.setCalanderViewType(params);
            this.behaviorService.UseCalanderViewType(this.CurrentDate);
        }
    }

    /**
     * This function is used to goto url
     * @param url -url data
     */
    goTo(url) {
        if (url) {
            this.openWebsite = this.sanitizer.bypassSecurityTrustResourceUrl(
                url.replace("http://", "https://")
            );
        } else {
            this.openWebsite = undefined;
        }

        if (this.openWebsite) {
            window.open(
                this.openWebsite.changingThisBreaksApplicationSecurity,
                "_blank"
            );
        }
    }

    /**
     * This function is used to set the time scale.
     * @param params -Params data
     */
    setTimeScale(params: any):void {
        this.behaviorService.setTimeScale(params);
    }

    /**
     * This function is used to change the user of User Activity
     */
    ChangeUserOfUserActivity() {
        this.sub7 = this.behaviorService.forDiaryRefersh$.subscribe(
            (result) => {
                this.DairyData = result;
            }
        );

        const dialogRef = this.MatDialog.open(UserSelectPopupComponent, {
            width: "100%",
            disableClose: true,
            data: { multi: "Multi" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                localStorage.setItem(
                    "SelectedUserActivityUsers",
                    JSON.stringify(result)
                );
                this.behaviorService.forUserActivity("call");
            }
        });
    }
}
