<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_estimate_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="estimateform" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field  appearance="outline" fxFlex="auto" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTYPE,'has-warning':errorWarningData.Warning?.ITEMTYPE}"> 
                        <mat-label>Select an option</mat-label>
                        <mat-select  formControlName="ITEMTYPE"  #value name="selected" (selectionChange)="TypeChange($event , '')">
                          <mat-option value="Time Entry">Time Entry</mat-option>
                          <mat-option value="Activity">Activity</mat-option>
                          <mat-option value="Matter Expense">Matter Expense</mat-option>
                          <mat-option value="Sundry">Sundry </mat-option>
                          <mat-option value="Search">Search</mat-option>
                        </mat-select>
                      </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="auto" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEEARNER,'has-warning':errorWarningData.Warning?.FEEEARNER}">
                        <mat-label>Fee Earner</mat-label>
                        <mat-select #value name="selected" (selectionChange)="matterChange('FeeEarner',$event.value)" formControlName="FEEEARNER">
                            <mat-option *ngFor="let user of userList" value="{{user.USERID}}">{{user.FULLNAME}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field  *ngIf="RATEIDValue" appearance="outline" fxFlex="17"  class="pr-4"   [ngClass]="{'has-error':errorWarningData.Error?.rateid,'has-warning':errorWarningData.Warning?.rateid}">
                        <mat-label>Rate ID</mat-label>
                        <!-- [disabled] = " _data.edit == 'Add'" -->
                        <mat-select formControlName="RATEID" (selectionChange)="reateId($event.value)">
                            <mat-option *ngFor="let rateids of RATEIData" [(value)]="rateids.LOOKUPGUID">
                                {{rateids.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="ITEMTYPEValue" appearance="outline" fxFlex="auto" class="pr-4" >
                        <mat-label *ngIf="lookuptype=='Activities' || lookuptype=='Activity'">Activity</mat-label>
                        <mat-label *ngIf="lookuptype=='Sundries' || lookuptype=='Sundry'">Sundry</mat-label>
                        <input type="text" matInput formControlName="QUANTITYTYPE" [matAutocomplete]="auto" >
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="ActivityChnage($event.option.value)">
                            <mat-option *ngFor="let Activity of filteredOptionsActivity | async " [value]="Activity.ACTIVITYID">
                                {{Activity?.ACTIVITYID}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{Activity.DESCRIPTION}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SERVICE,'has-warning':errorWarningData.Warning?.SERVICE}">
                        <mat-label>Service</mat-label>
                        <textarea type="text" matInput type="text" matInput formControlName="SERVICE"  style=" min-height: 51px;" [matAutocomplete]="serviceText"></textarea>
                        <mat-autocomplete #serviceText="matAutocomplete" (optionSelected)="ServiceChnage($event.option.value)">
                            <mat-option *ngFor="let Service of filteredOptions | async" [value]="Service.LOOKUPFULLVALUE">{{Service.LOOKUPFULLVALUE}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MINQUATITY,'has-warning':errorWarningData.Warning?.MINQUATITY}">
                        <mat-label>Min. Quantity</mat-label>
                        <input formControlName="MINQUATITY" matInput [disabled]="isdisable" (focusout)="matterChange('Quantity',$event.value)">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MINTYPE,'has-warning':errorWarningData.Warning?.MINTYPE}">
                        <mat-label>Min. Type:</mat-label>
                        <mat-select #value name="selected" (selectionChange)="quantityTypeChange('QuantityType',$event.value)" formControlName="MINTYPE">
                            <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">{{Activity.DESCRIPTION}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MINPRICE,'has-warning':errorWarningData.Warning?.MINPRICE}">
                        <mat-label>Min. Price</mat-label>
                        <!-- currencyMask -->
                        <!-- numeric numericType="decimal" -->
                        <!-- type="float" (blur)="amountChange($event, 'MINPRICE')" -->
                        <input [value]="estimateform.get('MINPRICE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" formControlName="MINPRICE" matInput [readonly]="isreadonly" (blur)="calcPE()" [(ngModel)]="PRICEVAL" [allowNegativeNumbers]="true" [leadZero]="true">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MININCGST,'has-warning':errorWarningData.Warning?.MININCGST}">
                        <mat-label>Min. Inc GST</mat-label>
                        <!-- type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  -->
                        <!-- (blur)="amountChange($event, 'MINPRICE')" -->
                        <input [value]="estimateform.get('MININCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" [allowNegativeNumbers]="true" formControlName="MININCGST" matInput [readonly]="isreadonly" (blur)="calcPI()" [(ngModel)]="PRICEINCGSTVAL" [leadZero]="true">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf='AllowEstimateRange'>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MAXQUATITY,'has-warning':errorWarningData.Warning?.MAXQUATITY}">
                        <mat-label>Max. Quantity</mat-label>
                        <input formControlName="MAXQUATITY" matInput (focusout)="matterChange('Quantity',$event.value,'max')">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MAXTYPE,'has-warning':errorWarningData.Warning?.MAXTYPE}">
                        <mat-label>Max. Type:</mat-label>
                        <mat-select #value name="selected" (selectionChange)="quantityTypeChange('QuantityType',$event.value,'max')" formControlName="MAXTYPE">
                            <mat-option *ngFor="let Activity of ActivityList" value="{{Activity.ACTIVITYID}}">{{Activity.DESCRIPTION}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MAXPRICE,'has-warning':errorWarningData.Warning?.MAXPRICE}">
                        <mat-label>Max. Price</mat-label>
                        <!-- numeric numericType="decimal"  -->
                        <input [value]="estimateform.get('MAXPRICE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" [allowNegativeNumbers]="true" formControlName="MAXPRICE" matInput [readonly]="isreadonly" (blur)="calcPE('max')" [(ngModel)]="MAXPRICEVAL" [leadZero]="true">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MAXINCGST,'has-warning':errorWarningData.Warning?.MAXINCGST}">
                        <mat-label>Max. Inc GST</mat-label>
                        <input [value]="estimateform.get('MAXINCGST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  style="text-align: right;" [allowNegativeNumbers]="true" formControlName="MAXINCGST" matInput [readonly]="isreadonly" (blur)="calcPI('max')" [(ngModel)]="MAXPRICEINCGSTVAL" [leadZero]="true">
                    </mat-form-field>
                </div>

            </form>
        </div>
        <mat-dialog-actions align="end" class="popup_ftr">
            <button mat-raised-button color="accent" class="mat-accent" (click)="EstimateSave()" [disabled]="isspiner" *ngIf="action === 'new'"><mat-spinner *ngIf="isspiner"></mat-spinner>Save</button>
            <button mat-raised-button color="accent" class="mat-accent" (click)="EstimateSave()" [disabled]="isspiner" *ngIf="action === 'edit'"><mat-spinner *ngIf="isspiner"></mat-spinner>Update</button>
            <button mat-raised-button color="accent" class="mat-accent" (click)="EstimateSave()" [disabled]="isspiner" *ngIf="action !== 'new' && action !== 'edit'"><mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
        </mat-dialog-actions>
    </div>