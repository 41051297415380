import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'storagFormat' })
export class StoragFormatPipe implements PipeTransform {

    /**
     * This function is used to transform the data.
     */
    transform(value: any = 0, args: any = 2): any {
        if (value === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = args < 0 ? 0 : args;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(value) / Math.log(k));
        return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

}
