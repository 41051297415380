import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-commercial',
    templateUrl: './commercial.component.html',
    styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent implements OnInit, OnDestroy {
    CommercialForm: FormGroup;
    ChildtableDataSubcription: Subscription;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();

    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public _matDialog: MatDialog,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>, public tooltipService: TooltipService) {
        this.initFormData();
    }
    initFormData() {
        this.CommercialForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCOMMERCIALGUID: [''],
            CLASSOFSHARES: [''],
            NUMBEROFSHARES: [''],
            CONSIDERATION: [''],
        });
    }

    ngOnDestroy(): void {
        this.ChildtableDataSubcription?.unsubscribe();
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == false) {
                this.CommercialForm.controls['MATTERGUID'].setValue('');
                this.CommercialForm.controls['MATTERCOMMERCIALGUID'].setValue('');
                this.CommercialForm.controls['CLASSOFSHARES'].setValue('');
                this.CommercialForm.controls['NUMBEROFSHARES'].setValue('');
                this.CommercialForm.controls['CONSIDERATION'].setValue('');
            }
        })


        this.ChildtableDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.CommercialForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Commercial" && result.action == 'setData') {
                    this.SaveCommercial();
                } else if (result.classType == "Commercial" && result.action == 'deleteData') {
                    this.DeleteMatterCommercial();
                }
            }
        });
        if (this.action === 'edit' || this.action === 'duplicate') {
            this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-commercial').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterCommercialData = response.DATA.RECORDS[0];
                    if (MatterCommercialData) {
                        this.CommercialForm.patchValue({
                            MATTERGUID: MatterCommercialData.MATTERGUID,
                            MATTERCOMMERCIALGUID: MatterCommercialData.MATTERCOMMERCIALGUID,
                            CLASSOFSHARES: MatterCommercialData.CLASSOFSHARES,
                            NUMBEROFSHARES: MatterCommercialData.NUMBEROFSHARES,
                            CONSIDERATION: MatterCommercialData.CONSIDERATION,
                        });
                    }
                }
            });
        }
    }

    SaveCommercial() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.CommercialForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERCOMMERCIALGUID;
            delete formData.MATTERGUID;
        }
        formData.MATTERGUID = this.MatterData.MATTERGUID;
        const formAction = (this.action == 'edit' && formData.MATTERCOMMERCIALGUID) ? 'Update' : 'Insert';
        const MatterCommercialData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(MatterCommercialData, 'matter-commercial').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MatterCommercialData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveCommercialAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveCommercialAfterVAlidation(details);
        }
    }
    SaveCommercialAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'matter-commercial').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    DeleteMatterCommercial() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-commercial').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterCommercialData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERCOMMERCIALGUID: MatterCommercialData.MATTERCOMMERCIALGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-commercial').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
}
