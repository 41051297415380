import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-enable-mfa',
    templateUrl: './enable-mfa.component.html',
    styleUrls: ['./enable-mfa.component.scss']
})
export class EnableMfaComponent implements OnInit, OnDestroy {

    isPasswordEnter: boolean = false;
    isSetupKeyGenerator: boolean = false;
    user: any = {};
    account: any = {};
    securityObject: any = {};

    QrCode: any;
    isReady: boolean = false;
    isSecurityQuestion: boolean = false;
    SecurityQuestionsOne: any = [];
    SecurityQuestionsTwo: any = [];
    SecurityQuestionsThree: any = [];
    isLoadingResults: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();

    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    AWAITING2FA = localStorage.getItem('AWAITING2FA')
    constructor(private _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService, private behaviorService: BehaviorService,
        public dialog: MatDialog, private http: HttpClient) { }

          /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to generate the QR CODE
     */
    QrCodeGenerator() {

        const data = {
            User: this.currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: 'Request2FAQRCode',
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.QrCode = res.DATA.QRCODE
                this.isReady = true;
            }
        })
    }

    /**
     * This function is used to set up the key code generator.s
     */
    SetupKeyCodeGenerator() {
        const data = {
            User: this.currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: 'Request2FASetupKey',
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isSetupKeyGenerator = true;
                this.account = res.DATA
                this.isReady = true;
            }
        })
    }

    /**
     * This function is used to get the Question one
     */
    getQuetionsOne() {
        const data = {
            Action: 'getdata',
            Filters: { LookupType: "Security Question 1" }
            // "QuestionNumber": 1
        }
        this._mainAPiServiceService.getSetData(data, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.SecurityQuestionsOne = res.DATA.LOOKUPS
                this.isSecurityQuestion = true;
            }
        })
    }

    /**
     * This function is used to get the question data.
     */
    getQuetionsTwo() {
        const data = {
            Action: 'getdata',
            Filters: { LookupType: "Security Question 2" }
        }
        this._mainAPiServiceService.getSetData(data, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.SecurityQuestionsTwo = res.DATA.LOOKUPS
                this.isSecurityQuestion = true;
            }
        })
    }

    /**
     * This function is used to get the Question three
     */
    getQuetionsThree() {
        const data = {
            Action: 'getdata',
            Filters: { LookupType: "Security Question 3" }
            // "QuestionNumber": 3
        }
        this._mainAPiServiceService.getSetData(data, 'lookup').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.SecurityQuestionsThree = res.DATA.LOOKUPS
                this.isSecurityQuestion = true;
            }
        })
    }

    /**
     * This function is used to Code submit
     */
    CodeSubmit():void{
        this.isLoadingResults = true;
        const data = {
            User: this.currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: "Enable2FA",
            User2FAToken: this.user.generatedCode,
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            this.isLoadingResults = false;
            if (res.CODE == 200 && res.STATUS == "success") {
                this.AWAITING2FA = 'true'
                // localStorage.setItem('AWAITING2FA',this.AWAITING2FA)
                this.behaviorService.setAWAITING2FA(this.AWAITING2FA);
                this.toastr.success('Multi Factor Authentication Enabled Successfully');
                this.dialog.closeAll();
            } else if (res.CODE == 458 && res.STATUS == "error") {
                this.dialog.closeAll();

            }
        })
    }

    /**
     * this function is used to CodeSubmit and make it disabled
     */
    CodeSubmit_disbale() {
        this.isLoadingResults = true;
        const data = {
            User: this.currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            Request: "Disable2FA",
            User2FAToken: this.user.generatedCode,
        }
        this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            this.isLoadingResults = false;
            if (res.CODE == 200 && res.STATUS == "success") {
                if (res.DATA['2FADISABLED'] == "1")
                    this.AWAITING2FA = 'false'
                // localStorage.setItem('AWAITING2FA',this.AWAITING2FA)
                this.behaviorService.setAWAITING2FA(this.AWAITING2FA);
                this.toastr.success('Multi Factor Authentication Disabled Successfully');
                this.dialog.closeAll();
            } else if (res.CODE == 458 && res.STATUS == "error") {
                this.dialog.closeAll();

            }
        })
    }

/**
 * This function is used to onSecurity Submit
 */
    onSecuritySubmit():void {

        const data = {
            user: this.currentUser.EXPORTACCOUNTSEMAIL,
            formatting: JSON,
            request: "setsecurityqa",
            ...this.securityObject

        }

        this.http.post<any>(environment.APIEndpoint + 'login', data).pipe(takeUntil(this._unsubscribeAll$)).subscribe(loginResponse => {
                if (loginResponse.CODE == 200 && loginResponse.STATUS == "success") {
                    this.toastr.success('Multi Factor Authentication Enabled Successfully')
                }
            }, error => {
                this.toastr.error(error);
            });
        // this._mainAPiServiceService.getSetData(data, 'login').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
        //     if (res.CODE == 200 && res.STATUS == "success") {
        //         this.toastr.success('Multi Factor Authentication Enabled Successfully')
        //     }
        // })
    }

}
