import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

//Tree Table
import { FlatTreeControl } from '@angular/cdk/tree';
import { fuseAnimations } from 'src/@fuse/animations';
import * as $ from 'jquery';
import { MatterDialogComponent } from '../../time-entries/matter-dialog/matter-dialog.component';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';

interface FoodNode {
  name: string;
  KITNAME: string;
  CONTEXT: string;
  KITGUID: string;
  TEMPLATEFILE: string;
  TEMPLATETYPEDESC: string;
  index?: number;
  children?: FoodNode[];
  KITITEMS?: FoodNode[];
  MainList?: FoodNode[];
}


/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-packs',
  templateUrl: './packs.component.html',
  styleUrls: ['./packs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class PacksComponent implements OnInit, AfterViewInit, OnDestroy {
  packForm: FormGroup;
  @Input() popupurl
  @ViewChild('tree', { static: false }) tree;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  isLoadingResults: boolean = false;
  storeDataarray: any = [];
  MainKitArray: any = [];
  sendItemDataToPopup: any = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  arrayForIndex: any = [];
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.KITITEMS && node.KITITEMS.length > 0,
      name: node.KITNAME,
      kititem: node.KITITEMS,
      Context: node.CONTEXT,
      Main: node.MainList,
      child: node.TEMPLATEFILE,
      iconType: node.TEMPLATETYPEDESC,
      KitGUid: node.KITGUID,
      index: node.index,
      level: level,
    };
  }
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.KITITEMS);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  highlightedRows: number;
  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    private behaviorService: BehaviorService,
    private _mainAPiServiceService: MainAPiServiceService
  ) {
    localStorage.setItem('istrackid', 'PacksComponent');
    this.loadData({});
    this.sub = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'PacksComponent' && result['click'] && result['data']['document-pack']) {
          this.loadData({});
        }
    });

    // matter Template popup Search control.
    this.sub1 = this.behaviorService.MatterTemplatePopupSearch$.subscribe((res)=>{
      if(res){
        if(res.type == 'Packs'){
          this.loadData({ SEARCH: res.search });
        }
      }
    });

    this.sub3 = this.behaviorService.MatterTemplatePopupReload$.subscribe((res)=>{
      if(res){
        if(res.type == 'Packs'){
          this.refreshKitTab();
        }
      }
    });
  }

  /**
   * This functioon is used to show the data
   */
  showData(element, level, parent) {
    element.forEach(x => {
      // this.MainKitArray=x;
      this.arrayForIndex.push({});
      x.level = level
      x.parent = parent
      x.MainList = x;
      x.index = this.arrayForIndex.length;
      if (x.KITITEMS)
        this.showData(x.KITITEMS, x.level + 1, x.KITNAME);
    });
  }

 /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + 10)) + 'px');
    this.packForm = this._formBuilder.group({
      Filter: [],
      search: []
    });
    this.isLoadingResults = true;
    // this.behaviorService.resizeTableForAllView();
    // const behaviorService = this.behaviorService;
    // $(window).resize(function () {
    //   behaviorService.resizeTableForAllView();
    // });

    let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
    $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 60)) + 'px');
    $(window).resize(() => {
    let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
    $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 60)) + 'px');
    });
    // this.loadData();
  }

  /**
   * This function is used to destroy the component session
   */
  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
      this.sub3?.unsubscribe();
  }

  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit() {
    //this.treeControl.expandAll();
  }

  /**
   * This function is used to refresh the KIT tab
   */
  refreshKitTab() {
    this.loadData({});
  }

  /**
   * This function is used to load the data value
   */
  loadData(Data) {
    this.isLoadingResults = true;
    let finalData = { ACTION:'GetData', Filters: { INCLUDEKITITEMS: 'Yes' } }
    this.sub2 = this._mainAPiServiceService.getSetData(finalData, 'document-pack').subscribe(res => {

      if (res.CODE == 200 && res.STATUS == "success") {
        // this.dataSource.paginator = this.paginator;
        this.arrayForIndex = [];
        this.storeDataarray = res.DATA.RECORDS;
        this.showData(this.storeDataarray, 0, null);
        this.dataSource.data = this.storeDataarray;
       // this.treeControl.expandAll();
        this.RowClick(res.DATA.RECORDS[0]);
        this.highlightedRows = 1;
      }
      this.isLoadingResults = false;
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }
  get f() {
    return this.packForm.controls;
  }

  /**
   * This function is used to search the filter
   */
  FilterSearch(searchFilter: any) {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter') {
      this.loadData({ SEARCH: this.f.search.value })
    }
  }

/**
 * Thia function is used to selectbthe matter
 */
  SelectMatter() {
    const dialogRef = this._matDialog.open(MatterDialogComponent, {
      width: '100%',
      disableClose: true,
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  /**
   * This function is used to Row click 
   */
  RowClick(main) {
    $("#packsToolbarHide").click();
    this.behaviorService.packsitems(main);
    this.behaviorService.EmailGenerateData(main?.EMAILDETAILS);
    this.behaviorService.TemplateGenerateData({ 'TEMPLATENAME': main.TEMPLATEFILE });
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}
