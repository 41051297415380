import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { GenerateTemplatesDialoagComponent } from 'src/app/main/pages/system-settings/templates/gennerate-template-dialoag/generate-template.component';
import * as ClassicEditor from '../../../../../../ckeditor.js';
import Imgbase64uploderPlugin from '../../../../../helpers/CKeditor/imgbase64uploder';
import { EmailAttachmentPopupComponent } from '../email-attachment-popup/email-attachment-popup.component';
import { GenerateTemplatesDialoagComponent } from '@pages/system-settings/templates/gennerate-template-dialoag/generate-template.component';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { Subject, takeUntil } from 'rxjs';
import { HEIGHT, PASTECleanupSettings, iFRAME, QUICKToolbarSettings, TOOLbarSettings, INSERTImageSettings, fontFamily, backgroundColor, fontColor } from 'src/app/_constant/rich-text-editor-toolbarFeature';
@Component({
  selector: 'app-email-dailog',
  templateUrl: './email-dailog.component.html',
  styleUrls: ['./email-dailog.component.scss']
})
export class EmailDailogComponent implements OnInit, OnDestroy {
    iframe: any = iFRAME;
    height: any = HEIGHT;
    insertImageSettings: any = INSERTImageSettings;
    pasteCleanupSettings: any = PASTECleanupSettings;
    quickToolbarSettings: any = QUICKToolbarSettings;
    toolbarSettings: any = TOOLbarSettings;
    fontFamily: any = fontFamily;
    backgroundColor=backgroundColor;
    fontColor=fontColor
  public Editor = ClassicEditor;
  EmailTemplete: FormGroup;
  rowdata: any = [];
  highlightedRows: any;
  InnerTableData: any = [];
  confirmDialogRef: any;
  errorWarningData: any = {};
  isLoadingResults: boolean = false;
  action: string;
  EmailtemplateData: any = [];
  dialogTitle: string;
  isspiner: boolean = false;
  GetData: any = [];
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  Name = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  displayedColumns: string[] = ['NAME', 'DESCRIPTION'];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  To_options: string[] = ['<i:mc-email>', '<i:f-email>', '<i:mcs-email>', '<i:s-email>'];
  CC_options: string[] = ['<i:mc-email>', '<i:f-email>', '<i:mcs-email>', '<i:s-email>'];
  BCC_options: string[] = ['<i:mc-email>', '<i:f-email>', '<i:mcs-email>', '<i:s-email>'];
  SUb_options: string[] = ['<i:mc-email>', '<i:f-email>', '<i:mcs-email>', '<i:s-email>'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  FormAction: string;
  GUID: string;
  htmlText: any;
  configsOption: any;
  attechmentArray: any = [];
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<EmailDailogComponent>,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    private behaviorService: BehaviorService,

    private _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService
  ) {
    localStorage.setItem('istrackid', 'EmailDailogComponent');
    this.action = data.action;
    this.behaviorService.EmailGenerateData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.EmailtemplateData = result;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'EmailDailogComponent' && result['click'] && result['data']['TemplateFieldList']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'EmailDailogComponent' && result['click'] && result['data']['template-email']) {
        this.EmailSave();
      }

    });


  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.configsOption = { fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72] }, extraPlugins: [Imgbase64uploderPlugin] };
    this.EmailTemplete = this._formBuilder.group({
      name: [],
      ToEmail: [],
      CCEmail: [],
      BCCEmail: [],
      subject: [],
      attachment: [],
      text: [],

    });
    this.innerTable();
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    if (this.action === 'new') {
      this.dialogTitle = 'New Email';
      this.getData();
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Email';
      this.CommonEditDupData();
    } else {
      this.dialogTitle = 'Duplicate Document';
      this.CommonEditDupData();
    }
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to get the data
   */
  getData() {
    this._mainAPiServiceService.getSetData({"Action": "GetData","Filters": {}}, 'preference').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        let DefultVal: any = response.DATA;
        this.EmailTemplete.controls['text'].setValue(DefultVal.SIGNATUREDEFAULT);
        // this.ckeditorValue += this.DefultData + DefultVal.SIGNATUREDEFAULT;
      }
    }, error => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to comman edit duplicate data
   */
  CommonEditDupData() {
    // this.GetData=ShowData;
    this.EmailTemplete.controls['name'].setValue(this.EmailtemplateData.NAME);
    this.EmailTemplete.controls['ToEmail'].setValue(this.EmailtemplateData.TOADDRESS);
    this.attechmentArray = (this.EmailtemplateData.ATTACHMENT).split(';');
    this.EmailTemplete.controls['attachment'].setValue(this.EmailtemplateData.ATTACHMENT);
    this.EmailTemplete.controls['BCCEmail'].setValue(this.EmailtemplateData.BCCADDRESS);
    this.EmailTemplete.controls['CCEmail'].setValue(this.EmailtemplateData.CCADDRESS);
    this.EmailTemplete.controls['subject'].setValue(this.EmailtemplateData.SUBJECT);
    this.EmailTemplete.controls['text'].setValue(this.EmailtemplateData.TEXT_);
  }

  /**
   * This function is used tonget the inner table
   */
  innerTable() {
    // this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({ "TEMPLATETYPE": 'Email' }, 'TemplateFieldList').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      this.InnerTableData = new MatTableDataSource(res.DATA.FIELDS);
      this.InnerTableData.paginator = this.paginator;
      this.InnerTableData.sort = this.sort;
      this.highlightedRows = 0;
    })
  }
  
  /**
   * This function is used to get the document Folder
   */
  DcoumentFloder() {
    const dialogRef = this._matDialog.open(GenerateTemplatesDialoagComponent, {
      disableClose: true, panelClass: 'contact-dialog', data: { action: 'new' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let templateDta: any = result;
        const dialogRef1 = this.MatDialog.open(EmailAttachmentPopupComponent, { width: '100%', disableClose: true, data: '' });
        dialogRef1.afterClosed().subscribe(result => {
          localStorage.setItem('istrackid', 'EmailDailogComponent');
          if (this.f.attachment.value) {
            if (this.f.attachment.value.trim()) {
              this.attechmentArray = this.f.attachment.value.trim().split(';');
            } else {
              this.attechmentArray = [];
            }
          } else {
            this.attechmentArray = [];
          }
          this.attechmentArray.push(templateDta.TEMPLATENAME.split('.').slice(0, -1) + '.' + result)
          this.EmailTemplete.controls['attachment'].setValue(this.attechmentArray.join(';'));
        });

      }
    });
  }
  
  /**
   * This function is used to close the email
   */
  CloseEmail() {
    this.dialogRef.close(false);
  }
 
  /**
   * This function is used to click on the table
   */
  clicktable(val) {
    this.rowdata = val;
  }
  get f() {
    return this.EmailTemplete.controls;
  }

  /**
   * This function is used to save the Email
   */
  EmailSave() {
    if (this.action == 'edit') {
      this.GUID = this.EmailtemplateData.EMAILGUID;
      this.FormAction = 'update';
    } else {
      this.FormAction = 'insert';
      this.GUID = " ";
    }
    this.isspiner = true;
    let passdata = {
      EMAILGUID: this.GUID,
      ATTACHMENT: this.f.attachment.value,
      BCCADDRESS: this.f.BCCEmail.value,
      CCADDRESS: this.f.CCEmail.value,
      NAME: this.f.name.value,
      SUBJECT: this.f.subject.value,
      TEXT: this.f.text.value,
      TOADDRESS: this.f.ToEmail.value,
    };
    let details = { FormAction: this.FormAction, VALIDATEONLY: true, Data: passdata };
    this._mainAPiServiceService.getSetData(details, 'template-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });

  }

  /**
   * This function is used to check the validation 
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }

    // });
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.SetEmailData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.SetEmailData(details);

    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details).pipe(takeUntil(this._unsubscribeAll$))
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.SetEmailData(details);
        }
      }
    });
  }

  /**
   * This function is used to set the email data value
   */
  SetEmailData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'template-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to onready data
   */
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
}
