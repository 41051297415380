import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { GeneralDailogComponent } from './general-dailog/general-dailog.component';
import { GeneralJournalComponent } from './general-journal.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    {
        path: 'general-journal', component: GeneralJournalComponent, data: {
            title: 'General Journal'
        }, canActivate: [AuthGuard]
    },
    {
        path: 'trust-general-journal', component: GeneralJournalComponent, data: {
            title: 'Trust General Journal'
        }, canActivate: [AuthGuard]
    },
];

const maskConfig: Partial<IConfig> = {
    validation: false,
};
// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "right",
//   allowNegative: true,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$",
//   suffix: "",
//   thousands: ",",
//   nullable: false,
//   inputMode: CurrencyMaskInputMode.NATURAL,
// };
@NgModule({
    declarations: [
        GeneralJournalComponent,
        GeneralDailogComponent,
        ReportFilterComponent
    ],
    entryComponents: [
        GeneralDailogComponent,
        ReportFilterComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSelectModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressSpinnerModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        SatDatepickerModule, SatNativeDateModule,

        MatCheckboxModule,
        DragDropModule,
        MatExpansionModule,
        MatRadioModule,
        // N level tree checkbox
        MatTreeModule,
        CdkTableModule,
        CdkTreeModule,
        ScrollingModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatSortModule,
        ApplicationPipesModule,
        ResizableModule,
        NgxCurrencyModule,
        MatProgressBarModule,
        FormsModule,
        NgxMaskModule.forRoot(maskConfig)
    ],
    exports: [
        MatTreeModule,
        CdkTableModule,
        CdkTreeModule,
        ScrollingModule,
        MatDatepickerModule
    ],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        //{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
    bootstrap: [],
})


export class GeneralJoural { }
