<div cdkDrag class="popup_main_div send_mail_main">
    <h2 cdkDragHandle mat-dialog-title>{{isConfirmBoxTitle}}
        <button class="btn" mat-icon-button (click)="closeEmailPopup()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form id="send_email_form" [formGroup]="SendEmailForm" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div class="email_fixed_field">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="btm_email_margin">
                        <button (click)="selectMatterContact('toaddress')" fxFlex="10" class="pr-4 pad_btn" mat-stroked-button>To</button>
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.TOADDRESS,'has-warning':errorWarningData.warning?.TOADDRESS}" appearance="outline" fxFlex="80" class=" pr-4">
                            <mat-label>To</mat-label>
                            <mat-chip-list #chipList aria-label="email selection">
                                <mat-chip *ngFor="let email of emails" [selectable]="selectable" [removable]="removable" (removed)="remove(email)">
                                    {{email}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input formControlName="TOADDRESS" placeholder="Ex. ex@example.com" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                        </mat-form-field>
                        <!-- <button fxFlex="10" style="margin-right: 10px;bottom: 12px;" mat-stroked-button (click)="isCc=!isCc"
                            class="cc">Cc</button>
                        <button fxFlex="10" style="bottom: 12px;" mat-stroked-button (click)="isBcc=!isBcc"
                            class="bcc">Bcc</button> -->
                        <mat-slide-toggle formControlName="isCc" (change)="ToggleForUpadte($event, 'isCc')" fxFlex="10" style="margin-right: 10px;bottom: 12px;">Cc
                        </mat-slide-toggle>
                        <mat-slide-toggle formControlName="isBcc" (change)="ToggleForUpadte($event, 'isBcc')" fxFlex="10" style="bottom: 12px;">Bcc
                        </mat-slide-toggle>
                    </div>
                    <div *ngIf="f.isCc.value == true" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="btm_email_margin">
                        <button (click)="selectMatterContact('ccaddress')" fxFlex="10" class="pr-4 pad_btn" mat-stroked-button>Cc</button>
                        <mat-form-field appearance="outline" fxFlex="90" class=" pr-4">
                            <mat-label>Cc</mat-label>
                            <mat-chip-list #chipListCC aria-label="cc selection">
                                <mat-chip *ngFor="let Ccemail of Ccemails" [selectable]="selectable" [removable]="removable" (removed)="removeCcEmail(Ccemail)">
                                    {{Ccemail}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input formControlName="CCADDRESS" placeholder="Ex. ex@example.com" [matChipInputFor]="chipListCC" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addCcEmail($event)">
                            </mat-chip-list>
                            <!-- <input placeholder="Cc" matInput> -->
                        </mat-form-field>

                    </div>
                    <div *ngIf="f.isBcc.value == true" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="btm_email_margin">
                        <button (click)="selectMatterContact('bccaddress')" fxFlex="10" class="pr-4 pad_btn" mat-stroked-button>Bcc</button>
                        <mat-form-field appearance="outline" fxFlex="90" class=" pr-4">
                            <mat-label>Bcc</mat-label>
                            <mat-chip-list #chipListBCC aria-label="bcc selection">
                                <mat-chip *ngFor="let Bccemail of Bccemails" [selectable]="selectable" [removable]="removable" (removed)="removeBccEmail(Bccemail)">
                                    {{Bccemail}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input formControlName="BCCADDRESS" placeholder="Ex. ex@example.com" [matChipInputFor]="chipListBCC" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addBccEmail($event)">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.SUBJECT,'has-warning':errorWarningData.warning?.SUBJECT}" appearance="outline" fxFlex="100" class=" pr-4">
                            <mat-label>Subject</mat-label>
                            <input formControlName="SUBJECT" placeholder="Enter Subject" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div [ngClass]="[isCc ? 'cc_editor':'',isBcc ? 'bcc_editor':'']" class="email_editor_div">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <ckeditor [ngClass]="{'has-error':errorWarningData.Error?.CONTENT,'has-warning':errorWarningData.warning?.CONTENT}" formControlName="ckeditorName" [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc [config]="configsOption" fxFlex="100"
                            class="ckeClass email-ckeditorblock" [editor]="Editor" data="" (ready)="onReady($event)">
                        </ckeditor>
                    </div>
                </div> -->

                <div [ngClass]="[f.isCc.value == true ? 'cc_editor':'',f.isBcc.value == true ? 'bcc_editor':'']" class="email_editor_div">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="user-editorwrap">
                        <!-- <ckeditor [ngClass]="{'has-error':errorWarningData.Error?.CONTENT,'has-warning':errorWarningData.warning?.CONTENT}" formControlName="ckeditorName" [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc [config]="configsOption" fxFlex="100"
                            class="ckeClass email-ckeditorblock" [editor]="Editor" data="" (ready)="onReady($event)">
                        </ckeditor> -->
                           <ejs-richtexteditor [fontColor]="fontColor" [backgroundColor]="backgroundColor" [fontFamily]='fontFamily' [insertImageSettings]='insertImageSettings'   [quickToolbarSettings]='quickToolbarSettings'  [toolbarSettings]='toolbarSettings' [pasteCleanupSettings]="pasteCleanupSettings" [ngClass]="{'has-error':errorWarningData.Error?.CONTENT,'has-warning':errorWarningData.warning?.CONTENT}" formControlName="ckeditorName" [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc fxFlex="100"
                        class="ckeClass email-ckeditorblock"   (ready)="onReady($event)" >
                        </ejs-richtexteditor>
                    </div>
                </div>
                <!-- <div class="flex_row_attach">
                    <div *ngFor="let file of fileNameArray;let i = index;" class="parent_file_upload_show">
                        <mat-icon class="icon_inner_file">
                            <img src="assets/icons/web_app/file_upload_icon.png" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                        <span class="file_name_uploded">
                            {{file.name}}
                        </span>
                        <mat-icon class='remove_file' (click)="remove_file(i)">close</mat-icon>
                    </div>
                    <input (change)="onFileInput($event,ckeditor_full_desc)" #fileInput type="file" style="display:none;">
                </div> -->
            </form>
        </div>
    </div>
    <mat-dialog-actions class="file_arrenge">
        <div>
            <mat-form-field appearance="outline" fxFlex="45" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                <mat-label>Matters</mat-label>
                <mat-chip-list #chipList aria-label="Matter selection">
                    <mat-chip *ngFor="let matterdata of mattername" (removed)="removeData(matterdata)" class="mat-chips-selected">
                        {{matterdata}}
                        <button  matChipRemove class="canclebutton">
                            <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input
                        (keydown.Tab)="datashow($event)"
                        [(ngModel)]="matterautoVal" #MatterInput1 [matAutocomplete]="autoMatter"
                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addData($event)" (keyup)="onKeyUp($event)" name="matterautoVal" [ngClass]="mattername && mattername.length > 0?'hideInput':'showInput'">
                </mat-chip-list>
                <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                    <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                        <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)='selectMatterForEmail()' class="select-Matter-icon">
                    <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                </mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="fileNameArray && fileNameArray.length !==0" appearance="outline" fxFlex="45" style="margin-bottom: 5px;margin-top: 5px;" class="pr-4">
                <mat-label>Attacments</mat-label>
                <mat-chip-list #chipList aria-label="Matter selection">
                    <mat-chip *ngFor="let file of fileNameArray;let i = index;" (removed)="remove_file(i)" class="mat-chips-selected">
                        <mat-icon class="icon_inner_file">
                            <img src="assets/icons/web_app/file_upload_icon.png" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                        {{file.name}}
                        <button  matChipRemove class="canclebutton">
                            <mat-icon class='remove_file' (click)="remove_file(i)">close</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-list>
                <input (change)="onFileInput($event,ckeditor_full_desc)" #fileInput type="file" style="display:none;">
            </mat-form-field>

            <button class="margin_left_auto" [matMenuTriggerFor]="menu" matTooltip="file attachment" mat-button style="margin-top: 15px;"  fxLayoutAlign="end center" fxFlex="1 0 auto">
                <mat-icon class="attechment_file_send_email">attachment</mat-icon>
            </button>
        </div>
        <div>
            <mat-slide-toggle class="margin_left_auto margin-with" name="SAVETOREGISTER" [(ngModel)]="SAVETOREGISTER" fxLayoutAlign="start center" fxFlex="1 0 auto" (change)="toggle($event)">Save to Register </mat-slide-toggle>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"  *ngIf="SAVETOREGISTER">
                <mat-form-field  class="mr-4" [ngClass]="{'has-error':errorWarningData.Error?.SUBJECT,'has-warning':errorWarningData.warning?.SUBJECT}" appearance="outline" >
                    <mat-label>File</mat-label>
                    <input placeholder="Enter Subject" [(ngModel)]="fileName" name="fileName"  matInput>
                </mat-form-field>
                <!-- <mat-form-field class="mr-4" [ngClass]="{'has-error':errorWarningData.Error?.SUBJECT,'has-warning':errorWarningData.warning?.SUBJECT}" appearance="outline" >
                    <mat-label>File name</mat-label> -->
                    <div class="mat-form-field-wrapper ejs-folder-tree">
                        <ejs-dropdowntree #sample id="ejs-drop" [fields]="fieldsEjs" (select)="FloderChnage($event,attechmentkey)" placeholder='Select a Folder' [treeSettings]="treeSettings" (click)="created($event)">
                        </ejs-dropdowntree>
                    </div>
                <!-- </mat-form-field> -->
            </div>
            <div>
                <button class="margin_left_auto" mat-raised-button color="accent" cdkFocusInitial style="margin-bottom: 5px;margin-top: 5px;" [disabled]="isspiner"   (click)="sendEmail()">
                    <mat-spinner *ngIf="isspiner"></mat-spinner>
                    Send
                </button>
                <button class="margin_left_auto" mat-raised-button color="primary" (click)="closeEmailPopup()" cdkFocusInitial style="margin-bottom: 5px;margin-top: 5px;">Don't Send</button>
            </div>
        </div>

</mat-dialog-actions>
</div>
<mat-menu #menu="matMenu">
    <button (click)="documentRegPopup()" mat-menu-item>Choose from Document Register</button>
    <button (click)="UploadToRegPopup()" mat-menu-item>Upload to Register and Attach</button>
    <!-- <button (click)="fileInput.click()" mat-menu-item> Upload (doesn`t upload to register)</button> -->
</mat-menu>
