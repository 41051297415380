<div id="pack" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-010 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto" id="filters">
                <form [formGroup]="packForm">
                    <mat-form-field class="search" appearance="outline" fxFlex="50">
                        <mat-label>Filter</mat-label>
                        <input matInput placeholder="Filter" formControlName="Filter">
                    </mat-form-field>
                    <mat-form-field class="search" appearance="outline" fxFlex="47">
                        <mat-label>Search</mat-label>
                        <input matInput placeholder="search" formControlName="search" (keydown)="FilterSearch($event)">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <span class="appCues-blankSpace">
                        <a href="javascript:void(0);">Link</a>
                    </span>
                </form>
            </div>
            <button id="filters-matter-popup " mat-raised-button color="primary" type="button" id="refreshKitTab" (click)="refreshKitTab();">Refresh</button>
            <div class="content raidus-0">
                <div class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content ">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content pack raidus-0">
                                <!-- <div class="btn top_right_action_billing_position sideBarDot">
                                    <button mat-icon-button [matMenuTriggerFor]="contextMenu"  aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template matMenuContent let-item="item">
                                            <button mat-menu-item (click)="refreshKitTab()"> Reload Data</button>
                                        </ng-template>
                                    </mat-menu>
                                </div> -->
                                <div class="content">
                                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div class="example-containerdata">
                                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                                            <!-- This is the tree node template for leaf nodes -->
                                            <mat-tree-node class="border-top fontsize" *matTreeNodeDef="let node" matTreeNodePadding [style.background]="highlightedRows ==  node.index? selectedColore : ''" [ngClass]="highlightedRows == node.index ? 'row-text-colore' : ''" highlightedRows=node.index (click)="RowClick(node.Main);highlightedRows = node.index "
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                                <!-- use a disabled button to provide padding for tree leaf -->
                                                <button mat-icon-button disabled></button>
                                                <span> <mat-icon *ngIf="node.child == ''|| node.child == null ">
                                                <img src="assets/icons/web_app/icon_doc_pack_d.ico"  >
                                            </mat-icon> {{node.name}}</span>
                                                <span>
                                                    <mat-icon *ngIf="node.iconType =='Email'">
                                                            <img class="email-img" src="assets/icons/web_app/icon_email_d.ico"  >
                                                        </mat-icon>
                                                   <mat-icon matSuffix *ngIf="node.iconType =='Template'" >
                                                    <img class="document-img" src="assets/icons/web_app/icon_letters_small_d.ico"  >
                                                </mat-icon>{{node.child}}</span>

                                            </mat-tree-node>
                                            <!-- This is the tree node template for expandable nodes -->
                                            <mat-tree-node class="border-top fontsize" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [style.background]="highlightedRows ==  node.index? selectedColore : ''" [ngClass]="highlightedRows == node.index ? 'row-text-colore' : ''" highlightedRows=node.index
                                                (click)="RowClick(node.Main);highlightedRows = node.index " matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name" [style.background]="highlightedRows ==  node.index? selectedColore : ''" [ngClass]="highlightedRows == node.index ? 'row-text-colore' : ''" highlightedRows=node.index
                                                    (click)="RowClick(node.Main);highlightedRows = node.index ">
                                                <mat-icon class="mat-icon-rtl-mirror" >
                                                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                                </mat-icon>
                                              </button>
                                                <span> <mat-icon>
                                                    <img src="assets/icons/web_app/icon_doc_pack_d.ico"  >
                                                </mat-icon>
                                                  {{node.name}}</span>
                                                <!-- means child in main -->
                                                <span>{{node.child}}</span>

                                            </mat-tree-node>
                                        </mat-tree>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>