<div class="popup_main_div corres_popup_main select_metter_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>Template
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog"><mat-icon>close</mat-icon></button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="select_matter_main_div">
            <div class="align">
                <p  style="font-size: 12px;"><b>Current Folder :</b> {{showFolder}}</p>
                <!-- <b style="font-size: 12px;">{{pathOfGenerateDocument}}</b> -->
            </div>
            <div class="example-containerdata" *ngIf="Templatedata">
                <div class="grid-overlay" *ngIf="isDisplay">
                    <div class="grid-overlay-msg">There is no data to display.</div>
                </div>
                <table mat-table [dataSource]="Templatedata" [@animateStagger]="{value:'50'}">
                    <ng-container matColumnDef="TEMPLATETYPE">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <img *ngIf="row.TEMPLATETYPE ==='Template'" src="assets/icons/web_app/icon_template_d.ico"  >
                            <img *ngIf="row.TEMPLATETYPE!='Template'" src="assets/icons/web_app/folder.ico"  >
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="TEMPLATENAME">
                        <th mat-header-cell *matHeaderCellDef>Template name </th>
                        <td mat-cell *matCellDef="let row"> {{row.TEMPLATENAME }} </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style.background]="highlightedRows == row.TEMPLATENAME ? selectedColore : ''" [ngClass]="highlightedRows == row.TEMPLATENAME ? 'row-text-colore' : ''" highlightedRows=row.TEMPLATENAME (click)="FirstClickTemplate(row);highlightedRows = row.TEMPLATENAME "
                        matRipple [@animate]="{value:'*',params:{y:'100%'}}" (dblclick)='dblclickrow(row)'>
                    </tr>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" [pageSize]="pageSize?.generate_tempplate" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    
    <mat-dialog-actions align="end" class="popup_ftr contact_select_footer">
        <button mat-raised-button (click)="selectTemplate()" color="accent">Select</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>