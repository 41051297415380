<div class=" one " fxLayout="column" [formGroup]="MatterPropertyDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTVOLUME,'has-warning':errorWarningData.Warning?.PARENTVOLUME}">
            <mat-label>Volume </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTVOLUME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTVOLUME">
             <div  *ngIf="testBool && toolTipList.PARENTVOLUME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTVOLUME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTVOLUME?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTFOLIOID,'has-warning':errorWarningData.Warning?.PARENTFOLIOID}">
            <mat-label>Folio Id </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTFOLIOID?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTFOLIOID">
             <div  *ngIf="testBool && toolTipList.PARENTFOLIOID" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTFOLIOID.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTFOLIOID?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTLOT,'has-warning':errorWarningData.Warning?.PARENTLOT}">
            <mat-label>Lot </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTLOT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTLOT">
             <div  *ngIf="testBool && toolTipList.PARENTLOT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTLOT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTLOT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTPLANTYPE,'has-warning':errorWarningData.Warning?.PARENTPLANTYPE}">
            <mat-label>Plan Type </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTPLANTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTPLANTYPE">
             <div  *ngIf="testBool && toolTipList.PARENTPLANTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTPLANTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTPLANTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PARENTPLANNUMBER,'has-warning':errorWarningData.Warning?.PARENTPLANNUMBER}">
            <mat-label>Plan No. </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTPLANNUMBER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTPLANNUMBER">
             <div  *ngIf="testBool && toolTipList.PARENTPLANNUMBER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTPLANNUMBER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTPLANNUMBER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNALLOTMENT,'has-warning':errorWarningData.Warning?.PARENTCROWNALLOTMENT}">
            <mat-label>Crown Allotment </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTCROWNALLOTMENT?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTCROWNALLOTMENT">
             <div  *ngIf="testBool && toolTipList.PARENTCROWNALLOTMENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTCROWNALLOTMENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTCROWNALLOTMENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNBLOCK,'has-warning':errorWarningData.Warning?.PARENTCROWNBLOCK}">
            <mat-label>Crown Block </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTCROWNBLOCK?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTCROWNBLOCK">
             <div  *ngIf="testBool && toolTipList.PARENTCROWNBLOCK" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTCROWNBLOCK.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTCROWNBLOCK?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNSECTION,'has-warning':errorWarningData.Warning?.PARENTCROWNSECTION}">
            <mat-label>Crown Section </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTCROWNSECTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTCROWNSECTION">
             <div  *ngIf="testBool && toolTipList.PARENTCROWNSECTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTCROWNSECTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTCROWNSECTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" class="pr-4" fxFlex="25" [ngClass]="{'has-error':errorWarningData.Error?.PARENTCROWNPORTION,'has-warning':errorWarningData.Warning?.PARENTCROWNPORTION}">
            <mat-label>Crown Portion </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.PARENTCROWNPORTION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PARENTCROWNPORTION">
             <div  *ngIf="testBool && toolTipList.PARENTCROWNPORTION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PARENTCROWNPORTION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARENTCROWNPORTION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>

</div>