<button mat-raised-button type="button" color="primary" id="refreshBank"
    (click)="refreshBank();">Refresh</button>
<div
    class="popup_main_div corres_popup_main select_metter_main_div account_select_main_popup"
    cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>{{title}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div>
            <span class="show-inactive">
                <mat-checkbox class="example-margin inactive-checkbox"
                    (change)="onChecked(showActive)" [(ngModel)]="showActive"></mat-checkbox>
                <mat-label>Show Inactive</mat-label>
            </span>
            <mat-label class="filter">Filter</mat-label>
            <mat-form-field class="example" class="search small_serach"
                appearance="outline">
                <!-- <input matInput placeholder="Search" (keyup.enter)="onSearch();" [(ngModel)]="searchFilter" formControlName="searchFilter"> -->
                <input matInput placeholder="Search" (keyup)="onSearch($event)"
                    [(ngModel)]="searchDataValue">
                <mat-icon matSuffix (click)="onSearch('Enter')"
                    *ngIf="!hasSearchVal">search</mat-icon>
                <mat-icon matSuffix (click)="onSearch('removeSearchString')"
                    *ngIf="hasSearchVal" class="icon-color-red"> highlight_off</mat-icon>
            </mat-form-field>
        </div>
        <div class="example-containerdata">
            <ejs-treegrid #treegrid [dataSource]="dataTreeTable"
                allowSelection='true' (rowSelected)='rowSelected()'
                childMapping='SUBACCOUNTS' [treeColumnIndex]='0'
                [selectedRowIndex]="0" [allowPaging]='false'
                allowResizing='true' [allowSorting]='true' (dataBound)="dataBound()">
                <e-columns>
                    <e-column headerText='Account Name' width='200'
                        textAlign='Left'>
                        <ng-template #template let-data>
                            <label [ngStyle]="{'color': '#'+data.ROWCOLOUR}">
                                {{data.ACCOUNTCLASS}} - {{data.ACCOUNTNUMBER}}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{data.ACCOUNTNAME}}&nbsp;
                                {{data.ACTIVE == 1 ? '' : '(inactive)'}}
                            </label>
                        </ng-template>
                    </e-column>
                    <e-column headerText='Balance' width='80'>
                        <ng-template #template let-data>
                            <!-- <label>{{data.OPENINGBALANCE | toFixedAmount:true}}</label> -->
                            <label [ngStyle]="{'color': '#'+data.ROWCOLOUR}">{{data.ACCOUNTBALANCE | toFixedAmount:true}}</label>
                        </ng-template>
                    </e-column>
                    <e-column headerText='Type' width='80'>
                        <ng-template #template let-data>
                            <label [ngStyle]="{'color': '#'+data.ROWCOLOUR}">{{data.ACCOUNTTYPENAME}}</label>
                        </ng-template>
                    </e-column>
                    <e-column headerText='Matter Num' width='80'
                        *ngIf="_data?.RoterPath =='protected-trust'">
                        <ng-template #template let-data>
                            <label [ngStyle]="{'color': '#'+data.ROWCOLOUR}">{{data.SHORTNAME}}</label>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-treegrid>
        </div>
    </div>
    <mat-dialog-actions align="left" class="popup_ftr contact_select_footer">
        <button [disabled]="isDisabledselect =='Header'" mat-raised-button
            color="accent" (click)="SelectClick(ACCOUNTGUIDsELECTED)"
            [mat-dialog-close]="ACCOUNTGUIDsELECTED">Select</button>
        <button mat-raised-button color="accent" class="mat-accent"
            (click)="AccountDialogOpen('new','TRUE',postDataForBanking.AccountClass)">New
            Account</button>
        <button mat-raised-button color="accent"
            (click)="AccountDialogOpen('edit','FALSE','')"
            [disabled]="!isAccountEditable">Edit Account</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>
