<div class=" one " fxLayout="column" [formGroup]="MariTimeForm">
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.VESSELNAME,'has-warning':errorWarningData.Warning?.VESSELNAME}">
            <mat-label>Vessel Name</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELNAME?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VESSELNAME">
       <div  *ngIf="testBool && toolTipList.VESSELNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELFLAG,'has-warning':errorWarningData.Warning?.VESSELFLAG}">
            <mat-label> Flag </mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELFLAG?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VESSELFLAG">
       <div  *ngIf="testBool && toolTipList.VESSELFLAG" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELFLAG.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELFLAG?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELTYPE,'has-warning':errorWarningData.Warning?.VESSELTYPE}">
            <mat-label> Vessel Type</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELTYPE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VESSELTYPE">
       <div  *ngIf="testBool && toolTipList.VESSELTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TONNAGE,'has-warning':errorWarningData.Warning?.TONNAGE}">
            <mat-label> Tonnage</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.TONNAGE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="TONNAGE">
       <div  *ngIf="testBool && toolTipList.TONNAGE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TONNAGE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TONNAGE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELMASTER,'has-warning':errorWarningData.Warning?.VESSELMASTER}">
            <mat-label> Master</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELMASTER?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VESSELMASTER">
       <div  *ngIf="testBool && toolTipList.VESSELMASTER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELMASTER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELMASTER?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELLOCATION,'has-warning':errorWarningData.Warning?.VESSELLOCATION}">
            <mat-label> Location</mat-label>
            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELLOCATION?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="VESSELLOCATION">
       <div  *ngIf="testBool && toolTipList.VESSELLOCATION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELLOCATION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELLOCATION?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PURCHASEPRICE,'has-warning':errorWarningData.Warning?.PURCHASEPRICE}">
            <mat-label> Purchase Price</mat-label>
            <input matInput currencyMask matTooltip="{{(toolTipList)?toolTipList.PURCHASEPRICE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="PURCHASEPRICE">
       <div  *ngIf="testBool && toolTipList.PURCHASEPRICE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PURCHASEPRICE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PURCHASEPRICE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.INCIDENTDATE,'has-warning':errorWarningData.Warning?.INCIDENTDATE}">
            <mat-label>Incident Date</mat-label>
            <input (dateInput)="IncidentDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.INCIDENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="INCIDENTDATEText" (dateChange)="IncidentDateClick('change', $event)" matInput [matDatepicker]="IncidentDate">
            <mat-datepicker-toggle matSuffix [for]="IncidentDate"></mat-datepicker-toggle>
            <mat-datepicker #IncidentDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.INCIDENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INCIDENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCIDENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.EXCHANGEDATE,'has-warning':errorWarningData.Warning?.EXCHANGEDATE}">
            <mat-label>Exchange Date</mat-label>
            <input (dateInput)="ExchangeDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.EXCHANGEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="EXCHANGEDATEText" (dateChange)="ExchangeDateClick('change', $event)" matInput [matDatepicker]="ExchangeDate">
            <mat-datepicker-toggle matSuffix [for]="ExchangeDate"></mat-datepicker-toggle>
            <mat-datepicker #ExchangeDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.EXCHANGEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXCHANGEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXCHANGEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SETTLEMENTDATE,'has-warning':errorWarningData.Warning?.SETTLEMENTDATE}">
            <mat-label>Settlement Date</mat-label>
            <input (dateInput)="SettlementDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.SETTLEMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SETTLEMENTDATEText" (dateChange)="SettlementDateClick('change', $event)" matInput name="SettlementDate" [matDatepicker]="SettlementDate">
            <mat-datepicker-toggle matSuffix [for]="SettlementDate"></mat-datepicker-toggle>
            <mat-datepicker #SettlementDate></mat-datepicker>
       <div  *ngIf="testBool && toolTipList.SETTLEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SETTLEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div> -->

    <div class="one rate_tab_main" fxLayout="column">

        <mat-accordion class="faqs-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Maritime Details</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class=" one " fxLayout="column" [formGroup]="MariTimeForm">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.VESSELNAME,'has-warning':errorWarningData.Warning?.VESSELNAME}">
                            <mat-label>Vessel Name</mat-label>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELNAME?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="VESSELNAME">
                    <div  *ngIf="testBool && toolTipList.VESSELNAME" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELNAME.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELNAME?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELFLAG,'has-warning':errorWarningData.Warning?.VESSELFLAG}">
                            <mat-label> Flag </mat-label>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELFLAG?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="VESSELFLAG">
                    <div  *ngIf="testBool && toolTipList.VESSELFLAG" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELFLAG.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELFLAG?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                
                        <mat-form-field appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELTYPE,'has-warning':errorWarningData.Warning?.VESSELTYPE}">
                            <mat-label> Vessel Type</mat-label>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELTYPE?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="VESSELTYPE">
                    <div  *ngIf="testBool && toolTipList.VESSELTYPE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELTYPE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELTYPE?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                
                        <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.TONNAGE,'has-warning':errorWarningData.Warning?.TONNAGE}">
                            <mat-label> Tonnage</mat-label>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.TONNAGE?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="TONNAGE">
                    <div  *ngIf="testBool && toolTipList.TONNAGE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.TONNAGE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TONNAGE?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                
                        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELMASTER,'has-warning':errorWarningData.Warning?.VESSELMASTER}">
                            <mat-label> Master</mat-label>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELMASTER?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="VESSELMASTER">
                    <div  *ngIf="testBool && toolTipList.VESSELMASTER" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELMASTER.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELMASTER?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                
                        <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.VESSELLOCATION,'has-warning':errorWarningData.Warning?.VESSELLOCATION}">
                            <mat-label> Location</mat-label>
                            <input matInput matTooltip="{{(toolTipList)?toolTipList.VESSELLOCATION?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="VESSELLOCATION">
                    <div  *ngIf="testBool && toolTipList.VESSELLOCATION" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.VESSELLOCATION.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VESSELLOCATION?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                
                       
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.INCIDENTDATE,'has-warning':errorWarningData.Warning?.INCIDENTDATE}">
                            <mat-label>Incident Date</mat-label>
                            <input (dateInput)="IncidentDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.INCIDENTDATE?.HOVER:''}}"
                            [matTooltipPosition]="toolTipPostion" formControlName="INCIDENTDATEText" (dateChange)="IncidentDateClick('change', $event)" matInput [matDatepicker]="IncidentDate">
                            <mat-datepicker-toggle matSuffix [for]="IncidentDate"></mat-datepicker-toggle>
                            <mat-datepicker #IncidentDate></mat-datepicker>
                       <div  *ngIf="testBool && toolTipList.INCIDENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.INCIDENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCIDENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Sale Details</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-matter-conveyancing-expandation  [testBool]='testBool' [action]="action" [classType]='classType' [toolTipList]="toolTipList"  [testBool]='testBool'></app-matter-conveyancing-expandation>
            </mat-expansion-panel>
        </mat-accordion>
    
    </div>
</div>