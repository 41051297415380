import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TooltipService } from '@_services/tooltip.service';
// import { SettlementAddressPopupComponent } from 'src/app/main/pages/legal-details/settlement/settlement-address-popup/settlement-address-popup.component';
// import { LeasingDetailsPopupComponent } from './leasing-details-popup/leasing-details-popup.component';



@Component({
  selector: 'app-leasing',
  templateUrl: './leasing.component.html',
  styleUrls: ['./leasing.component.scss']
})
export class LeasingComponent implements OnInit {
  constructor(public MatDialog: MatDialog, private datepipe: DatePipe, public tooltipService: TooltipService
  ) { }
  @Input() action: any;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;

  ngOnInit() {
  }
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999);
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999);
    navigator.clipboard.writeText(event)
  }
}
