]
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div checkcredit_main checkforcredit_main_div " cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>
        Unused Credits
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="header-notes">
            <p>Below are the credits available to allocate to this invoice.</p>
            <p>If you want to use any of the credits, simply edit the allocated amount.</p>
        </div>
        <div fxLayout="column" class="d-block" fxLayoutAlign="start" fxLayout.gt-md="row">
            <div fxLayout="row" class="p-4" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxFlex="34" class="pr-4">
                    <mat-label class="p-4">Invoice :</mat-label> {{invoiceData.INVOICECODE}}
                </div>
                <div fxFlex="66" class="pr-4">
                    <mat-label class="p-4">Matter :</mat-label> {{matterData.MATTER}}
                </div>
            </div>
            <div class="p-4" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <div fxFlex="33" class="pr-4">
                    <mat-label class="p-4">Outstanding :</mat-label> {{invoiceData.AMOUNTOUTSTANDINGINCGST | currency}}
                </div>
                <div fxFlex="33" class="pr-4">
                    <mat-label class="p-4">Used Credit :</mat-label> {{UsedCredits | currency}}
                </div>
                <div fxFlex="33" class="pr-4">
                    <mat-label class="p-4">Total Credit :</mat-label> {{invoiceData.TotalCredits | currency}}
                </div>

            </div>
        </div>

        <div class="example-containerdata pt-4">
            
            <table mat-table [dataSource]="CreditsDataSource">

                <ng-container matColumnDef="Discription">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element"> {{element.NOTE}} </td>
                </ng-container>

                <ng-container matColumnDef="Receipt">
                    <th mat-header-cell *matHeaderCellDef> Receipt </th>
                    <td mat-cell *matCellDef="let element"> {{element.INCOMECLASS}} </td>
                </ng-container>

                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.INCOMEDATE}} </td>
                </ng-container>

               
                <ng-container matColumnDef="Aallocation">
                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                    <td mat-cell *matCellDef="let element"> {{element.ALLOCATION | currency}} </td>
                </ng-container>
                
                <ng-container matColumnDef="Allocated">
                    <th mat-header-cell *matHeaderCellDef> Allocated </th>
                    <td mat-cell *matCellDef="let element"> {{element.AMOUNTALLOCATED | currency}} </td>
                </ng-container>

                 <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true">
                </tr>
                <!-- sticky: true" -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [style.background]="highlightedRows == row?.RECEIPTALLOCATIONGUID ? selectedColore : ''"
                    [ngClass]="highlightedRows == row?.RECEIPTALLOCATIONGUID ? 'row-text-colore' : ''" class="contact"
                    (click)="clickRow(row);highlightedRows = row?.RECEIPTALLOCATIONGUID" matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
                </tr>
            </table>
            <!-- <div class="errormsg"  *ngIf="showEmptyDataMsg">
                <div > There is no data to display. </div>
            </div> -->
        </div>

        <div fxLayout="column" class="d-block pt-4 " fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" name="CheckforCreditsForm"  [formGroup]="CheckforCreditsForm" fxLayoutAlign="start" fxFlex="1 0 auto">
                <div fxLayout="row" class="p-4" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div fxFlex="66" class="pr-4" fxLayoutAlign="end center">
                        <mat-label class="p-4">Enter the amount from the selected credit and then press tab</mat-label>
                    </div>
                    <div fxFlex="34" class="pr-4">
                        <mat-form-field appearance="outline" fxFlex="100" class="pr-4 amount-mat-field">
                            <mat-label>Amount</mat-label>
                            <input currencyMask formControlName="allocatedSelected" (focusout)="SingalrowAllocation()"  matInput placeholder="Amount">
                        </mat-form-field>

                    </div>

                </div>
            </form>

        </div>


    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="left_popup_btn">
            <button mat-raised-button color="accent" class="mat-accent" (click)="CreditsAllowcation()">Auto Allocate</button>
        </div>
        <div class="right_popup_btn">
            <button mat-raised-button color="accent" class="mat-accent" (click)="SetUseCredits()" [disabled]="invoiceData.AMOUNTOUTSTANDINGINCGST==0.00 || showEmptyDataMsg==true" > Use Credit</button>
           
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Use Credit</button>
        </div>
    </mat-dialog-actions>
</div>
<!-- </form> -->