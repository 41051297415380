import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import * as ClassicEditor from "../../../../../../src/ckeditor.js";
import { MatDialogRef } from '@angular/material/dialog';
import Imgbase64uploderPlugin from '../../../../helpers/CKeditor/imgbase64uploder';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';
import { HEIGHT, PASTECleanupSettings, iFRAME, QUICKToolbarSettings, TOOLbarSettings, INSERTImageSettings, fontFamily, backgroundColor, fontColor } from 'src/app/_constant/rich-text-editor-toolbarFeature';

@Component({
  selector: 'app-signatures-popup',
  templateUrl: './signatures-popup.component.html',
  styleUrls: ['./signatures-popup.component.scss']
})
export class SignaturesPopupComponent implements OnInit, OnDestroy {
    iframe: any = iFRAME;
    height: any = HEIGHT;
    insertImageSettings: any = INSERTImageSettings;
    pasteCleanupSettings: any = PASTECleanupSettings;
    quickToolbarSettings: any = QUICKToolbarSettings;
    toolbarSettings: any = TOOLbarSettings;
    fontFamily: any = fontFamily;
    backgroundColor=backgroundColor;
    fontColor=fontColor

    showeditor:boolean=false
    clickedIndex:any
  isspiner: boolean = false;
  isLoadingResults: boolean = false;
  isConfirmBoxTitle: any = "Signatures";
  configsOption: any;
  configsOption1: any;
  ckeditorValue: any = '';
  ckeditorValue1: any = '';
  HtmlCss: SafeHtml;
  signatureType: any = "simple";
  sub: Subscription;
  sub1: Subscription;

  public Editor = ClassicEditor;
  constructor(private toastr: ToastrService,private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer, private _mainAPiServiceService: MainAPiServiceService, public confirmDialogRef1: MatDialogRef<SignaturesPopupComponent>) { }

     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.configsOption = { fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72] },  extraPlugins: [Imgbase64uploderPlugin],
    fontFamily:{ options: [
      'Arial',
      'Bahnschrift',
      'Calibri',
      'Cambria',
      '"Cambria Math"',
      'Candara',
      '"Comic Sans MS"',
      'Consolas',
      'Constantia',
      'Corbel',
      '"Courier New"',
      'Ebrima',
      '"Franklin Gothic"',
      'Gabriola',
      'Gadugi',
      'Georgia',
      '"Global Monospace"',
      '"Global Sans Serif"',
      '"Global Serif"',
      '"Global User Interface"',
      '"HoloLens MDL2 Assets"',
      'Impact',
      '"Ink Free"',
      '"Javanese Text"',
      '"Leelawadee UI"',
      '"Lucida Console"',
      '"Lucida Sans Unicode"',
      '"Malgun Gothic"',
      'Marlett',
      '"Microsoft Himalaya"',
      '"Microsoft JhengHei UI"',
      '"Microsoft JhengHei"',
      '"Microsoft New Tai Lue"',
      '"Microsoft PhagsPa"',
      '"Microsoft Sans Serif"',
      '"Microsoft Tai Le"',
      '"Microsoft YaHei UI"',
      '"Microsoft Yi Baiti"',
      'MingLiU-ExtB',
      'MingLiU_HKSCS-ExtB',
      '"Mongolian Baiti"',
      '"MS Gothic"',
      '"MS PGothic"',
      '"MS UI Gothic"',
      '"MV Boli"',
      '"Myanmar Text"',
      '"Nirmala UI"',
      '"Palatino Linotype"',
      'PMingLiU-ExtB',
      '"Segoe MDL2 Assets"',
      '"Segoe Print"',
      '"Segoe Script"',
      '"Segoe UI Emoji"',
      '"Segoe UI Historic"',
      '"Segoe UI Symbol"',
      '"Segoe UI"',
      'SimSun-ExtB',
      '"Sitka Banner"',
      '"Sitka Display"',
      '"Sitka Heading"',
      '"Sitka Small"',
      '"Sitka Subheading"',
      '"Sitka Text"',
      'Sylfaen',
      'Symbol',
      'Tahoma',
      '"Times New Roman"',
      '"Trebuchet MS"',
      'Verdana',
      'Webdings',
      'Wingdings',
      '"Yu Gothic UI"',
      '"Yu Gothic"',
        ]
      }, };
          this.configsOption1 = { fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72] },  extraPlugins: [Imgbase64uploderPlugin] ,
          fontFamily:{ options: [
            'Arial',
      'Bahnschrift',
      'Calibri',
      'Cambria',
      '"Cambria Math"',
      'Candara',
      '"Comic Sans MS"',
      'Consolas',
      'Constantia',
      'Corbel',
      '"Courier New"',
      'Ebrima',
      '"Franklin Gothic"',
      'Gabriola',
      'Gadugi',
      'Georgia',
      '"Global Monospace"',
      '"Global Sans Serif"',
      '"Global Serif"',
      '"Global User Interface"',
      '"HoloLens MDL2 Assets"',
      'Impact',
      '"Ink Free"',
      '"Javanese Text"',
      '"Leelawadee UI"',
      '"Lucida Console"',
      '"Lucida Sans Unicode"',
      '"Malgun Gothic"',
      'Marlett',
      '"Microsoft Himalaya"',
      '"Microsoft JhengHei UI"',
      '"Microsoft JhengHei"',
      '"Microsoft New Tai Lue"',
      '"Microsoft PhagsPa"',
      '"Microsoft Sans Serif"',
      '"Microsoft Tai Le"',
      '"Microsoft YaHei UI"',
      '"Microsoft Yi Baiti"',
      'MingLiU-ExtB',
      'MingLiU_HKSCS-ExtB',
      '"Mongolian Baiti"',
      '"MS Gothic"',
      '"MS PGothic"',
      '"MS UI Gothic"',
      '"MV Boli"',
      '"Myanmar Text"',
      '"Nirmala UI"',
      '"Palatino Linotype"',
      'PMingLiU-ExtB',
      '"Segoe MDL2 Assets"',
      '"Segoe Print"',
      '"Segoe Script"',
      '"Segoe UI Emoji"',
      '"Segoe UI Historic"',
      '"Segoe UI Symbol"',
      '"Segoe UI"',
      'SimSun-ExtB',
      '"Sitka Banner"',
      '"Sitka Display"',
      '"Sitka Heading"',
      '"Sitka Small"',
      '"Sitka Subheading"',
      '"Sitka Text"',
      'Sylfaen',
      'Symbol',
      'Tahoma',
      '"Times New Roman"',
      '"Trebuchet MS"',
      'Verdana',
      'Webdings',
      'Wingdings',
      '"Yu Gothic UI"',
      '"Yu Gothic"',
        ]
      },};
    this.getData();
  }


  /**
   * this function is used to destroy the session of the component
   */
  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

  /**
   * This function is used to get the data value.
   */
  getData():void {
    this.isLoadingResults = true;
    this.sub = this._mainAPiServiceService.getSetData({"Action": "GetData","Filters": {}}, 'preference').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        let DefultVal: any = response.DATA;
        this.ckeditorValue = DefultVal.SIGNATUREDEFAULT;
        this.ckeditorValue1 = DefultVal.SIGNATUREREPLYFORWARD;
        this.isLoadingResults = false;
      } else {
        this.isLoadingResults = false;
      }
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to update the signature data
   */
  updateSignatures():void {
    this.isspiner = true;
    let details = { FormAction: 'update', VALIDATEONLY: false, Data: { SIGNATUREDEFAULT: this.ckeditorValue, SIGNATUREREPLYFORWARD: this.ckeditorValue1 } };
    this.sub1 = this._mainAPiServiceService.getSetData(details, 'preference').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.isspiner = false;
        this.toastr.success("Save Sucessfully");
        this.confirmDialogRef1.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.isspiner = false;
        this.toastr.warning(response.MESSAGE);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.isspiner = false;
        this.toastr.error(response.MESSAGE);
      } else if (response.MESSAGE == 'Not logged in') {
        this.isspiner = false;
        this.confirmDialogRef1.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to Ready to makes the default sign
   */
  public onReadyDefultSign(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      editor.service.getHtml(editor.value)

    );
  }

  /**
   * This function is used to onREadyReply signature data value
   */
  public onReadyReplySign(editor):void {
     editor.ui.getEditableElement().parentElement.insertBefore(
         editor.ui.view.toolbar.element,
         editor.service.getHtml(editor.value),
      editor.ui.getEditableElement()
    );

// Manually trigger change detection
  this.cdr.detectChanges();
  };

  /**
   * This function is used to onTabChanged
   */
    onTabChanged($event) {
        this.clickedIndex = $event.index;
        if (this.clickedIndex == 1) {
            this.showeditor= true
        }
    }

    /**
     * This function is used to ValChange
     */
  async onValChange(value) {
    this.signatureType = value;
    let withoutImage = await this.replaceImgText(this.ckeditorValue);
    this.ckeditorValue = withoutImage;

    // this.userData.BIOGRAPHY = this.ckeditorValue
    this.HtmlCss = this.sanitizer.bypassSecurityTrustHtml(this.ckeditorValue);

  }

  /**
   * This function is used to signature preview data value
   */
  signaturePreview():void {
    this.HtmlCss = this.sanitizer.bypassSecurityTrustHtml(this.ckeditorValue);
  }

  /**
   * This function is used to changeDetect
   */
  async changesDetact(event){
    let withoutImage = await this.replaceImgText(this.ckeditorValue.trim());
    // this.userData.BIOGRAPHY = withoutImage;
    this.ckeditorValue = withoutImage;
  }

  /**
   * This function is used to replace Image text.
   */
  replaceImgText(html):void {
    var ret = html.replace( /<img[^>]*src="data:image\/(bmp|dds|gif|jpg|jpeg|png|psd|pspimage|tga|thm|tif|tiff|yuv|ai|eps|ps|svg);base64,.*?"[^>]*>/gi, function( img ){
      return '';
    });
    return ret;
  };
}
