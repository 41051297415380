export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'MATTERS': {
                'TITLE': 'Matters'
            },
            'CONTACT': {
                'TITLE': 'Contact'
            },
            'Dashboard': {
                'TITLE': 'Dashboard',
            },
            'Chat': {
                'TITLE': 'Chat',
            },
            'Book': {
                'TITLE': 'Book',
            },
        }
    }
};
