<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch" >{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="AccountForm" id="AccountForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTCLASS,'has-warning':errorWarningData.Warning?.ACCOUNTCLASS}">
                        <mat-label>Account Class</mat-label>
                        <mat-select (selectionChange)="GetAccountClass($event.value)" *ngIf="accountTypeData.ClickType=='WithoutTrust'" #value formControlName="ACCOUNTCLASS">
                            <mat-option value="Asset">Assets</mat-option>
                            <mat-option value="Liability">Liabilities</mat-option>
                            <mat-option value="Equity">Equity</mat-option>
                            <mat-option value="Income">Income</mat-option>
                            <mat-option value="Expense">Expenses</mat-option>
                        </mat-select>
                        <mat-select *ngIf="accountTypeData.ClickType=='WithTrust'" [disabled]="(isControlledMoneyAccount||(isTrustAccountId=='1000' && isTrust))" #value formControlName="ACCOUNTCLASS">
                            <mat-option value="Trust Account">Trust Account</mat-option>
                            <mat-option value="Matter Ledger">Matter Ledger</mat-option>
                            <mat-option value="Unknown Deposit">Unknown Deposit accounts</mat-option>
                            <mat-option value="Controlled Money Ledger ">Unknown Controlled Money Ledger </mat-option>
                            <mat-option value="Controlled Money Account">Controlled Money Account </mat-option>
                        </mat-select>
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTCLASS?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTCLASS?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTCLASS?.COPYVALUE:'')">file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNAME,'has-warning':errorWarningData.Warning?.ACCOUNTNAME}">
                        <mat-label>Account Name</mat-label>
                        <input formControlName="ACCOUNTNAME" matInput>
                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="General">
                            <br>
                            <div class="one" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="7" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.ACCOUNTNUMBER}">
                                        <input formControlName='ACCOUNTNUMBERCLSS' matInput readonly>
                                        <!-- <span matPrefix>1-&nbsp;</span> -->
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="43" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.ACCOUNTNUMBER}">
                                        <mat-label>Account Number</mat-label>
                                        <input formControlName='ACCOUNTNUMBER' matInput>
                                        <!-- <span matPrefix>1-&nbsp;</span> -->
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <mat-checkbox formControlName="ACTIVE">Active Account</mat-checkbox>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="(!isControlledMoneyAccount && isTrustAccountId!='1000' )">
                                    <mat-form-field appearance="outline" fxFlex="" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTTYPE,'has-warning':errorWarningData.Warning?.ACCOUNTTYPE}">
                                        <mat-label>Account Type</mat-label>
                                        <mat-select *ngIf="accountTypeData.ClickType=='WithoutTrust'" formControlName="ACCOUNTTYPE" (selectionChange)="onACCOUNTTYPE($event.value)">
                                            <mat-option value="Header">Header</mat-option>
                                            <mat-option value="Detail">Detail</mat-option>
                                            <mat-option value="Bank Account">Bank Account</mat-option>
                                            <mat-option value="Pass Through">Pass Through</mat-option>
                                        </mat-select>
                                        <mat-select *ngIf="accountTypeData.ClickType=='WithTrust'" formControlName="ACCOUNTTYPE" (selectionChange)="onACCOUNTTYPE($event.value)">
                                            <mat-option value="Statutory Deposit">Statutory Deposit</mat-option>
                                            <mat-option value="Matter Ledger">Matter Ledger</mat-option>
                                            <mat-option value="Controlled Money Account">Controlled Money Account
                                            </mat-option>
                                            <mat-option value="Controlled Money Ledger">Controlled Money Ledger
                                            </mat-option>
                                        </mat-select>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Bank Details" *ngIf="(isTrustAccountId=='1000' && isTrust) || isControlledMoneyAccount">
                            <br>
                            <div class="one" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKBSB ,'has-warning':errorWarningData.Warning?.BANKBSB }">
                                        <mat-label>BSB</mat-label>
                                        <input formControlName='BANKBSB' matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKBSB?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.BANKBSB?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKNAME ,'has-warning':errorWarningData.Warning?.BANKNAME }">
                                        <mat-label>Bank Name</mat-label>
                                        <input formControlName='BANKNAME' matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTNUMBER ,'has-warning':errorWarningData.Warning?.BANKACCOUNTNUMBER }">
                                        <mat-label>A/C Number</mat-label>
                                        <input formControlName='BANKACCOUNTNUMBER' matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKACCOUNTNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKACCOUNTNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKADDRESS ,'has-warning':errorWarningData.Warning?.BANKADDRESS }">
                                        <mat-label>Bank Address</mat-label>
                                        <input formControlName='BANKADDRESS' matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKADDRESS?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.BANKADDRESS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKTERM ,'has-warning':errorWarningData.Warning?.BANKTERM }">
                                        <mat-label>Term</mat-label>
                                        <input formControlName='BANKTERM' matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKTERM?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKTERM?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.BANKINTERESTRATE ,'has-warning':errorWarningData.Warning?.BANKINTERESTRATE }">
                                        <mat-label>Interest Rate</mat-label>
                                        <input formControlName='BANKINTERESTRATE' matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKINTERESTRATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKINTERESTRATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Xero" *ngIf="!isTrust">
                            <app-xero [AccountForm]="AccountForm" [action]="action" [errorWarningData]="errorWarningData"></app-xero>
                        </mat-tab>
                        <mat-tab label="MYOB" *ngIf="(isTrustAccountId!='1000' && !isTrust && !isControlledMoneyAccount)">
                            <br>
                            <span>
                                SILQ will by default export to 1-1200. You only need to enter a value for the MYOB
                                export
                                account if you want it to go<br>
                                to a different account. It is upto you to ensure that the account you are exporting to
                                matches the appropriate account.
                                <br><br></span>
                            <span>It must be in the format X-YYYY</span><br><br>
                            <div class="one" [formGroup]="AccountForm" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MYOBEXPORTACCOUNT,'has-warning':errorWarningData.Warning?.MYOBEXPORTACCOUNT}">
                                        <mat-label>MYOB Export Account</mat-label>
                                        <input placeholder="MYOB Export Account" formControlName="MYOBEXPORTACCOUNT" matInput>
                                        <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MYOBEXPORTACCOUNT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MYOBEXPORTACCOUNT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
    </div>

    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveAccount()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveAccount()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveAccount()" [disabled]="isspiner" *ngIf="action === 'duplicate'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>

</div>