<div id="SaveEstate" (click)="SaveWillEstate()"></div>
<div class="one rate_tab_main" fxLayout="column">
    <!-- <div fxFlex="100"> -->
    <form name="WillEstateForm" id="WillEstateForm" [formGroup]="WillEstateForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

        <mat-accordion class="faqs-accordion" *ngIf="moduleType!='legalEstate'">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>General</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-general-will-tab [testBool]='testBool' [WillEstateForm]="WillEstateForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-general-will-tab>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Will Details </strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-wills-details-tab [testBool]='testBool' [WillEstateForm]="WillEstateForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-wills-details-tab>
            </mat-expansion-panel>
            <!--  -->
        </mat-accordion>


        <mat-tab-group mat-align-tabs="start" *ngIf="moduleType ==='legalEstate'">
            <mat-tab label="General">
                <div class="mt-16">
                    <app-general-will-tab [testBool]='testBool' [WillEstateForm]="WillEstateForm" [errorWarningData]="errorWarningData" [islegalEstate]="moduleType ==='legalEstate'? true: false" [toolTipList]="toolTipList"></app-general-will-tab>
                </div>
            </mat-tab>
            <mat-tab label="Will Details">
                <div class="mt-16">
                    <app-wills-details-tab  [testBool]='testBool' [WillEstateForm]="WillEstateForm" [errorWarningData]="errorWarningData" [islegalEstate]="moduleType ==='legalEstate'? true: false" [toolTipList]="toolTipList"></app-wills-details-tab>
                </div>
            </mat-tab>
        </mat-tab-group>
        <!-- </div> -->
    </form>
</div>