import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-property-address',
  templateUrl: './property-address.component.html',
  styleUrls: ['./property-address.component.scss']
})
export class PropertyAddressComponent implements OnInit, OnDestroy {
  @Input() MatterPropertyDetailsForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() testBool;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  sub: Subscription;
  sub1: Subscription;
  
  StreetType: any = [];
  StateData: any = [];
  constructor(public _mainAPiServiceService: MainAPiServiceService, public tooltipService: TooltipService,
    private toastr: ToastrService) { }

      /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let streetTypesLookups = JSON.parse(localStorage.getItem('street_types_lookups'));
    if (streetTypesLookups && streetTypesLookups != null) {
      this.StreetType = streetTypesLookups;
    } else {
      this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'street types' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          this.StreetType = responses.DATA.LOOKUPS;
          localStorage.setItem('street_types_lookups', JSON.stringify(responses.DATA.LOOKUPS));
        }
      });
    }
    let statesLookups = JSON.parse(localStorage.getItem('states_lookups'));
    if (statesLookups && statesLookups != null) {
      this.StateData = statesLookups;
    } else {
      this.sub1 = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'states' }}, 'lookup').subscribe(responses => {
        if (responses.CODE === 200 && responses.STATUS === 'success') {
          localStorage.setItem('states_lookups', JSON.stringify(responses.DATA.LOOKUPS));
          this.StateData = responses.DATA.LOOKUPS;
        }
      });
    }
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

  /**
   * This function is used to get the text data.
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    //  navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    
    navigator.clipboard.writeText(event)
  }
}
