<div class="one rate_tab_main" fxLayout="column">
    <!-- <div fxFlex="100"> -->
    <form name="MatterPropertyDetailsForm" id="MatterPropertyDetailsForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="MatterPropertyDetailsForm">

        <mat-accordion class="faqs-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Address</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-address  [testBool]='testBool' [MatterPropertyDetailsForm]="MatterPropertyDetailsForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-property-address>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Title</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-title [testBool]='testBool' [MatterPropertyDetailsForm]="MatterPropertyDetailsForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-property-title>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Parent Title</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-parent-title [testBool]='testBool' [MatterPropertyDetailsForm]="MatterPropertyDetailsForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-property-parent-title>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Strata</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-strata [testBool]='testBool' [MatterPropertyDetailsForm]="MatterPropertyDetailsForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-property-strata>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Council</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-council [testBool]='testBool' [MatterPropertyDetailsForm]="MatterPropertyDetailsForm" [ParishLookupData]="ParishLookupData" [LocalityLookupData]="LocalityLookupData" [CouncilLookupsData]="CouncilLookupsData" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-property-council>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Crown Allotment</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-property-crow-allotment [testBool]='testBool' [MatterPropertyDetailsForm]="MatterPropertyDetailsForm" [errorWarningData]="errorWarningData" [toolTipList]="toolTipList"></app-property-crow-allotment>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- </div> -->
    </form>
</div>