<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_file_note_main">
    <!-- <h2 cdkDragHandle mat-dialog-title>File Note : {{ShortName}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2> -->

    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="35" class="pr-4 input_error"  [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                        <mat-label>Matter</mat-label>
                        <mat-chip-list #chipList aria-label="Matter selection">
                            <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input 
                                (keydown.Tab)="datashow($event)" [(ngModel)]="matterautoVal"
                                 #MatterInput1 [matAutocomplete]="autoMatter"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'" required >
                        </mat-chip-list>
                        <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                            <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)='selectMatter()' class="select-Matter-icon">
                            <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.DATE,'has-warning':errorWarningData.Warning?.DATE}" appearance="outline" class="example" fxFlex="25" class="pr-4">
                        <mat-label>Date</mat-label>
                        <input (dateChange)="choosedDate('change', $event)" matInput [(ngModel)]="FilenotesNgData.DATETEXT" name="DATETEXT" [matDatepicker]="picker" placeholder="Date"  matTooltip="{{(toolTipList)?toolTipList.DATE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.TIME,'has-warning':errorWarningData.Warning?.TIME}" appearance="outline" fxFlex="20" class="pr-4">
                        <mat-label>Time</mat-label>
                        <input [(ngModel)]="FilenotesNgData.TIME" name="TIME" matInput type="time"  matTooltip="{{(toolTipList)?toolTipList.TIME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    </mat-form-field>
                    <mat-form-field  *ngIf="'' | productType " [ngClass]="{'has-error':errorWarningData.Error?.USERNAME,'has-warning':errorWarningData.Warning?.USERNAME}" appearance="outline" fxFlex="20" class="pr-4">
                        <mat-label>User</mat-label>
                        <input [(ngModel)]="FilenotesNgData.USERNAME" name="USERNAME" matInput placeholder="USERNAME"  matTooltip="{{(toolTipList)?toolTipList.USERNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Comment</mat-label>
                        <textarea class="dynamic_textarea_height" matInput [(ngModel)]="FilenotesNgData.NOTE" name="NOTE"  matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
                        <!-- <textarea id="filenottext" type="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" matInput></textarea>
                         -->
                        <!-- <textarea class="dynamic_textarea_height" name="NOTE" formControlName="NOTE" id="notetextarea" matInput></textarea> -->
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveFileNote()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>