<div class="one" [formGroup]="DairyForm" fxLayout="column">
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.APPOINTMENTTYPE,'has-warning':errorWarningData.Warning?.APPOINTMENTTYPE}" appearance="outline" fxFlex="40" class="pr-4">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
                <mat-option value="Conference">Conference</mat-option>
                <mat-option value="Meeting">Meeting</mat-option>
                <mat-option value="CourtDate">Court Date</mat-option>
                <mat-option value="Preparation">Preparation</mat-option>
                <mat-option value="PhoneCall">Phone Call</mat-option>
                <mat-option value="Reminder">Reminder</mat-option>
                <mat-option value="Other">Other</mat-option>
            </mat-select>
         <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
        <mat-checkbox fxFlex="20" formControlName="Reminder" (ngModelChange)="CheckReminder($event)" [ngModel]="filter">Reminder</mat-checkbox>
        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.REMINDERMINUTESBEFORE,'has-warning':errorWarningData.Warning?.REMINDERMINUTESBEFORE}" appearance="outline" fxFlex="40" class="pr-4">
            <mat-label>Before Start</mat-label>
            <mat-select formControlName="Beforestart"  (selectionChange)="Beforstartchange($event.value)">
                <mat-option value="15Minutes">15 Minutes</mat-option>
                <mat-option value="30Minutes">30 Minutes</mat-option>
                <mat-option value="45Minutes">45 Minutes</mat-option>
                <mat-option value="1hours">1 hours</mat-option>
                <mat-option value="1/2hours">1/2 hours</mat-option>
                <mat-option value="2hours">2 hours</mat-option>
                <mat-option value="3hours">3 hours</mat-option>
                <mat-option value="4hours">4 hours</mat-option>

                <mat-option value="5hours">5 hours</mat-option>
                <mat-option value="6hours">6 hours</mat-option>
                <mat-option value="7hours">7 hours</mat-option>
                <mat-option value="8hours">8 hours</mat-option>
                <mat-option value="9hours">9 hours</mat-option>
                <mat-option value="10hours">10 hours</mat-option>
                <mat-option value="11hours">11 hours</mat-option>
                <mat-option value="0.5 days">0.5 days</mat-option>
                <mat-option value="18hours">18 hours</mat-option>

                <mat-option value="1 day">1 day</mat-option>
                <mat-option value="2 days">2 days</mat-option>
                <mat-option value="3 days">3 days</mat-option>
                <mat-option value="4 days">4 days</mat-option>
                <mat-option value="5 days">5 days</mat-option>
                <mat-option value="6 days">6 days</mat-option>

                <mat-option value="1 week">1 week</mat-option>
                <mat-option value="2 weeks">2 weeks</mat-option>
            </mat-select>
         <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.CATEGORY,'has-warning':errorWarningData.Warning?.CATEGORY}" appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Category</mat-label>
            <mat-select formControlName="Category" (ngModelChange)="ChnageCategory($event)">
                <mat-option value="Billable">Billable</mat-option>
                <mat-option value="Non-Billable">Non-Billable</mat-option>
                <mat-option value="Personal">Personal</mat-option>
                <mat-option value="Other">Other</mat-option>
            </mat-select>
         <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>
        <!-- <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}" class="example p-16" fxFlex="50" appearance="outline">
            <mat-label>Matter</mat-label>
            <input formControlName="Matter" matInput>
           
            <mat-icon matSuffix>
                <button [disabled]="hideicon"  id="appoitmentnew" class="appoitmentnews menu-disabled " (click)='SelectMatter()'><img  src="assets/icons/web_app/icon_matter_d.ico"  >
            </button>
            </mat-icon>
           
         <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field> -->

        <mat-form-field [class.disabled-inputs]="DairyForm.get('Matter').disabled"  [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}" class="example p-16" fxFlex="50" appearance="outline">
            <mat-label>Matters</mat-label>
            <mat-chip-list #chipList aria-label="Matter selection">
                <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                    {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                    <button  matChipRemove class="canclebutton">
                        <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                    </button>
                </mat-chip>
                <input formControlName="Matter" matInput
                    (keydown.Tab)="datashow($event)" 
                     #MatterInput1 [matAutocomplete]="autoMatter"
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" [ngClass]="mattername?.length > 0?'hideInput':'showInput'">
            </mat-chip-list>
            <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                    {{data.SELECTEDDESCRIPTION}}
                </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix>
                <button id="appoitmentnew" class="appoitmentnews" (click)='SelectMatter()'><img class="ipad-icon"
                        src="assets/icons/web_app/icon_matter_d.ico">
                </button>
            </mat-icon>
            <!-- <mat-icon class="matter_icon_timeDiary" matSuffix (click)='selectMatter()'>
                <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="select matter"
                    matTooltipPosition="above">
            </mat-icon> -->
     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
        </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}" appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Comment</mat-label>
            <textarea formControlName="COMMENT" matInput></textarea>
         <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.APPOINTMENTENDDATE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.APPOINTMENTENDDATE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>