import { AuthenticationService } from '@_services/authentication.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  is_count: boolean = false;
  is_exportData: boolean = false;
  is_okbutton: boolean = false;
  is_fileoverwrite = false;
  count: number = 180;
  timerInterval: any;
  public confirmMessage: string = "Are you sure you want to Save?";
  public confirmMessage1: string = "";
  public confirmTitle: string = "Confirm";
  public confirm1: string = "Yes";
  public confirm2: string = "No";
  public confirm3: string = "";
  public confirm5:string = "Cancle"
  public confirm4: string = "Export";
  constructor(@Inject(MAT_DIALOG_DATA) public _data: any, private auth: AuthenticationService,
    private _mainAPiServiceService: MainAPiServiceService,
    public dialogRef: MatDialogRef<ConfirmPopupComponent>) {
    if (_data) {
      this.is_count = _data.is_count;
      this.is_exportData = _data.is_exportData;
      this.is_fileoverwrite = _data.is_fileoverwrite;
    }
  }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if (this.is_count) {
      clearInterval(this.timerInterval);
      this.timerInterval = setInterval(() => {
        if (this.count == 0) {
          clearInterval(this.timerInterval);
          this.dialogRef.close(false);
          this.auth.logout();
        }
        this.count--;
      }, 1000);
    }
  }

  /**
   * This function is used to Close the popup
   */
  closePopup():void {
    clearInterval(this.timerInterval);
  }

  /**
   * This function is used to Convert the Seconds to Hms
   * @param d -time
   * @returns -data value
   */
  secondsToHms(d: any) {
    d = Number(d);
    var minutes = ("00" + Math.floor((d % 3600) / 60)).slice(-2);
    var seconds = ("00" + (d % 3600) % 60).slice(-2);
    return minutes + ":" + seconds;
  }

  /**
   * This function is used to export to Data Click
   */
  exportDataClick() {
    this.is_okbutton = true;
    this.confirm2 = "Ok";
    this.confirmMessage = "Please check your email in a few minutes for the export file";
    this.confirmTitle = "Inform";

    this._mainAPiServiceService.getSetData({ EXPORTSYSTEM: this._data.exportDataType }, 'NetAccountsExportData').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {

      } else {

      }
    }, error => {

    });
  }

  /**
   * This function is used to click on the overWrite click
   * @param val 
   */
  overwriteClick(val) {
    this.dialogRef.close(val);
  }

}
