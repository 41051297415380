<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button (click)="CloseEmail()" [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main inner_content_height">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form id="new_email_form" [formGroup]="EmailTemplete" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div class="email_fixed_field">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.NAME,'has-warning':errorWarningData.Warning?.NAME}" appearance="outline" fxFlex="49" class="pr-4">
                            <mat-label>Name</mat-label>
                            <input name="CONTACTNAME" formControlName="name" matInput>
                            <!-- <mat-icon matSuffix class="secondary-text">account_circle</mat-icon> -->
                        </mat-form-field>
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.TOADDRESS,'has-warning':errorWarningData.Warning?.TOADDRESS}" appearance="outline" class="example" fxFlex="49">
                            <mat-label>To</mat-label>
                            <input type="text" placeholder="Pick one" aria-label="Number" formControlName="ToEmail" matInput [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of To_options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.CCADDRESS,'has-warning':errorWarningData.Warning?.CCADDRESS}" appearance="outline" class="example" fxFlex="49" class="pr-4">
                            <mat-label>CC</mat-label>
                            <input type="text" placeholder="Pick one" aria-label="Number" formControlName="CCEmail" matInput [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of CC_options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.BCCADDRESS,'has-warning':errorWarningData.Warning?.BCCADDRESS}" appearance="outline" class="example" fxFlex="49">
                            <mat-label>BCC</mat-label>
                            <input type="text" placeholder="Pick one" aria-label="Number" formControlName="BCCEmail" matInput [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of BCC_options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.SUBJECT,'has-warning':errorWarningData.Warning?.SUBJECT}" appearance="outline" class="example" fxFlex="49" class="auto_drop_ghandel pr-4">
                            <mat-label>Subject</mat-label>
                            <input type="text" placeholder="Pick one" aria-label="Number" formControlName="subject" matInput [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of SUb_options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field [ngClass]="{'has-error':errorWarningData.Error?.ATTACHMENT,'has-warning':errorWarningData.Warning?.ATTACHMENT}" appearance="outline" class="example" fxFlex="49">
                            <mat-label>Attachment</mat-label>
                            <input formControlName="attachment" matInput>
                            <mat-icon matSuffix (click)='DcoumentFloder()'>
                                <img src="assets/icons/web_app/folder.ico">
                            </mat-icon>
                        </mat-form-field>

                    </div>
                </div>
                <!-- New SrollBar -->
                <div class="email_editor_div">
                    <div class="new_email_editor user-editorwrap" fxLayout="row" fxLayoutAlign="start start" fxFlex="1 0 auto">
                        <!-- <ckeditor formControlName="text" fxFlex="100" class=" pr-4" [editor]="Editor" data="" [config]="configsOption" (ready)="onReady($event)">
                        </ckeditor> -->
                          <ejs-richtexteditor [fontColor]="fontColor" [backgroundColor]="backgroundColor" [fontFamily]='fontFamily' [insertImageSettings]='insertImageSettings' [quickToolbarSettings]='quickToolbarSettings'  [toolbarSettings]='toolbarSettings' [pasteCleanupSettings]="pasteCleanupSettings" formControlName="text"
                         (ready)="onReady($event)" >
                        </ejs-richtexteditor>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="EmailSave()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="EmailSave()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="EmailSave()" [disabled]="isspiner" *ngIf="action !== 'new' && action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>
