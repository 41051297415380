import { Component, OnInit ,Input} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
@Component({
  selector: 'app-foreign-currency',
  templateUrl: './foreign-currency.component.html',
  styleUrls: ['./foreign-currency.component.scss']
})
export class ForeignCurrencyComponent implements OnInit {
  @Input() addInvoiceForm: FormGroup;
  constructor(private _mainAPiServiceService:MainAPiServiceService,
    private behaviorService: BehaviorService, public _matDialog: MatDialog,
    private toastr: ToastrService,public datepipe: DatePipe) { }


     /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {

  }

}
