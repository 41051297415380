import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-id',
    templateUrl: './id.component.html',
    styleUrls: ['./id.component.scss']
})
export class IdComponent implements OnInit {
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    ProductType = this.currentuser.PRODUCTTYPE;
    fileToUpload: any = [];
    constructor(public tooltipService: TooltipService, public datepipe: DatePipe) { }
    @Input() contactForm: FormGroup;
    @Input() allToolTip: any;
    @Input() errorWarningData: any;

    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
    }
    /**
     * 
     * @param event -this function is used to select the image
     * @param type -type of the image
     */
    selectImg(event, type) {
        // this.uploadBy = "device";
        this.FileConvert64bit(event, type);


    }

/**
 * This function is used to convert the filw from base64
 * @param fileInput -file input value
 * @param type -type of the file
 */
    async FileConvert64bit(fileInput: any, type: any) {
        let file64bit = [];
        let filenames = [];
        let files = fileInput.target.files;
        for (let i = 0; i < files.length; i++) {
            await new Promise<void>(async (next, reject) => {
                await filenames.push(files[i].name);
                var reader = await new FileReader();
                await reader.readAsDataURL(files[i]);
                reader.onload = await function () {
                    file64bit.push(reader.result);
                    next();

                };
                reader.onerror = await function (error) {
                    reject();
                };
            })
        }
        if (type == 'licenceId') {
            await this.contactForm.controls['LICENCESCANLOCATION'].setValue(filenames[0]);
            await this.contactForm.controls['LICENCEFILEDATA'].setValue(file64bit[0]);

        } else if (type == 'nationalId') {
            await this.contactForm.controls['NATIDENTSCANLOCATION'].setValue(filenames[0]);
            await this.contactForm.controls['NATIDENTFILEDATA'].setValue(file64bit[0]);
        }
        // this.contactForm.controls['NATIONALIDENTITYCOUNTRY'].setValue(file64bit);
        // this.fileToUpload = file64bit;
    }

    /**
     * This function is used to choosed the date
     * @param event 
     */
    choosedDate(event) {
        this.contactForm.controls['CRIMINALdate'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));

    }

}
