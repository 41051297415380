import { CurrentUser } from 'src/app/_models/CurrentUser';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseConfigService } from 'src/@fuse/services/config.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    isLicence: any;
    ProductType: any;
    currentYear: any;
    defultTheme: boolean = false;
    constructor(private _fuseConfigService: FuseConfigService, private router: Router, public dialog: MatDialog) {
        // this._fuseConfigService.config = {
        //   colorTheme: 'theme-default',
        //   layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
        // };
        this._fuseConfigService.config = {
            // colorTheme: 'theme-default',
            layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
        };
        let CurrentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (CurrentUser && CurrentUser?.THEMECOLOUR) {
            this._fuseConfigService.config.colorTheme = CurrentUser.THEMECOLOUR
        } else {
            this._fuseConfigService.config.colorTheme = 'theme-default'
        }
        this.isLicence = 'LI'
        // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.ProductType = currentUser.PRODUCTTYPE;
        this.currentYear = new Date().getFullYear();
        if (localStorage.getItem('theme_type') == "theme-yellow-light")
            this.defultTheme = true;
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        if (this.router.url == "/privacy-policy") {
            setTimeout(() => {
                this.dialog.closeAll();
            }, 50);
        };
    }

}
