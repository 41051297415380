<div class="one rate_tab_main" fxLayout="column">
    <!-- <div fxFlex="100"> -->
    <form name="MatterPropertyDetailsForm" id="MatterPropertyDetailsForm" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="MatterConveyancingDetailsForm">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.EXCHANGEDATE,'has-warning':errorWarningData.Warning?.EXCHANGEDATE}">
                <mat-label>Exchange Date</mat-label>
                <input (dateInput)="CommanDatePickerClick('input', $event,'EXCHANGEDATE')" matTooltip="{{(toolTipList)?toolTipList.EXCHANGEDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="EXCHANGEDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'EXCHANGEDATE')" matInput name="ExchangeDate" [matDatepicker]="ExchangeDate">
                <mat-datepicker-toggle matSuffix [for]="ExchangeDate"></mat-datepicker-toggle>
                <mat-datepicker #ExchangeDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.EXCHANGEDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXCHANGEDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXCHANGEDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4"  [ngClass]="{'has-error':errorWarningData.Error?.ADJUSTMENTDATE,'has-warning':errorWarningData.Warning?.ADJUSTMENTDATE}">
                <mat-label>Adjustment Date</mat-label>
                <input (dateInput)="CommanDatePickerClick('input', $event,'ADJUSTMENTDATE')" matTooltip="{{(toolTipList)?toolTipList.ADJUSTMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="ADJUSTMENTDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'ADJUSTMENTDATE')" matInput name="AdjustmentDate" [matDatepicker]="AdjustmentDate">
                <mat-datepicker-toggle matSuffix [for]="AdjustmentDate"></mat-datepicker-toggle>
                <mat-datepicker #AdjustmentDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.ADJUSTMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.ADJUSTMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADJUSTMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SETTLEMENTDATE,'has-warning':errorWarningData.Warning?.SETTLEMENTDATE}">
                <mat-label> Settlement Date</mat-label>
                <input (dateInput)="CommanDatePickerClick('input', $event,'SETTLEMENTDATE')" matTooltip="{{(toolTipList)?toolTipList.SETTLEMENTDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="SETTLEMENTDATETEXT" (dateChange)="CommanDatePickerClick('input', $event,'SETTLEMENTDATE')" matInput name="SettlementDate" [matDatepicker]="SettlementDate">
                <mat-datepicker-toggle matSuffix [for]="SettlementDate"></mat-datepicker-toggle>
                <mat-datepicker #SettlementDate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.SETTLEMENTDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.SETTLEMENTDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SETTLEMENTDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline"   fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.CLIENTSTATUS,'has-warning':errorWarningData.Warning?.CLIENTSTATUS}">
                <mat-label> Status</mat-label>
                <mat-select matTooltip="{{(toolTipList)?toolTipList.CLIENTSTATUS?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion" formControlName="CLIENTSTATUS">
                    <mat-option value="Unexchanged">Unexchanged</mat-option>
                    <mat-option value="Exchanged">Exchanged</mat-option>
                    <mat-option value="In Progress">In Progress</mat-option>
                    <mat-option value="Cooling Off">Cooling Off</mat-option>
                    <mat-option value="Complete">Complete</mat-option>
                </mat-select>
                <div  *ngIf="testBool && toolTipList.CLIENTSTATUS" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.CLIENTSTATUS.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENTSTATUS?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field *ngIf="classType != 'Maritime'" appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PURCHASEPRICE,'has-warning':errorWarningData.Warning?.PURCHASEPRICE}">
                <mat-label> Purchase Price</mat-label>
                <input matInput style="text-align: right;" [value]="MatterConveyancingDetailsForm.get('PURCHASEPRICE').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." matTooltip="{{(toolTipList)?toolTipList.PURCHASEPRICE?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion" formControlName="PURCHASEPRICE" [leadZero]="true">
        <div  *ngIf="testBool && toolTipList.PURCHASEPRICE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.PURCHASEPRICE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PURCHASEPRICE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="MatterData.classType == 4">
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label><b>Settlement time</b></mat-label>
                <input type="text" matInput aria-label="Number"  formControlName="SETTLEMENTTIME" name="SETTLEMENTTIME" (dateInput)="CommanDatePickerClick('input', $event,'SETTLEMENTTIME')" >
            <div  *ngIf="testBool && toolTipList.REVIEWDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REVIEWDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REVIEWDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label>Pool Inspection Date</mat-label>
                <input matInput [matDatepicker]="picker3" formControlName="POOLINSPECTIONDATE"
                (dateInput)="CommanDatePickerClick('input', $event,'POOLINSPECTIONDATE')">

                <input  (dateInput)="CommanDatePickerClick('input', $event,'POOLINSPECTIONDATE')" formControlName="POOLINSPECTIONTEXT" (dateChange)="CommanDatePickerClick('input', $event,'POOLINSPECTIONDATE')" matInput name="poolInspectiondate" [matDatepicker]="poolInspectiondate">
                <mat-datepicker-toggle matSuffix [for]="poolInspectiondate"></mat-datepicker-toggle>
                <mat-datepicker #poolInspectiondate></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.REVIEWDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REVIEWDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REVIEWDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field> 
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="MatterData.classType == 4">
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label>Pestinspection Date</mat-label>
                <input matInput [matDatepicker]="picker4" formControlName="PESTINSPECTIONTEXT"
                (dateInput)="CommanDatePickerClick('input', $event,'PESTINSPECTIONDATE')">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.REVIEWDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REVIEWDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REVIEWDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
    
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label><b>Settlement Location</b></mat-label>
                <input matInput formControlName="SETTLEMENTLOCATION" name="SETTLEMENTLOCATION">
            <div  *ngIf="testBool && toolTipList.REVIEWDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.REVIEWDATE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REVIEWDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
            </mat-form-field>
        </div> -->

        <mat-accordion class="faqs-accordion">
            <!-- app-conveyancing-contract -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Price And Deposit</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-conveyancing-price-deposite [testBool]='testBool' [MatterConveyancingDetailsForm]="MatterConveyancingDetailsForm" [toolTipList]="toolTipList"></app-conveyancing-price-deposite>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Contract</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-conveyancing-contract  [testBool]='testBool'[MatterConveyancingDetailsForm]="MatterConveyancingDetailsForm" [toolTipList]="toolTipList"></app-conveyancing-contract>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel *ngIf="classType!='Property Purchase'"> -->
            <mat-expansion-panel *ngIf="classType == 'Property Purchase' || classType == 'Property Sale' || classType == 'Business Sale' || classType == 'Business Purchase' || classType == 'Maritime'">
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Concessions and Duty</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-conveyancing-concessions-duty [testBool]='testBool' [MatterConveyancingDetailsForm]="MatterConveyancingDetailsForm" [toolTipList]="toolTipList"></app-conveyancing-concessions-duty>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="80">
                            <strong>Settlement</strong>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-conveyancing-settlement [testBool]='testBool' [MatterConveyancingDetailsForm]="MatterConveyancingDetailsForm" [toolTipList]="toolTipList"></app-conveyancing-settlement>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- </div> -->
    </form>
</div>