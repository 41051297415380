import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectorRef,
    ElementRef,
    destroyPlatform,
    HostListener,
    OnDestroy,
} from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { fuseAnimations } from "src/@fuse/animations";
import { SortingDialogComponent } from "../../sorting-dialog/sorting-dialog.component";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import * as $ from "jquery";
import { MatSort } from "@angular/material/sort";
import { MatterDialogComponent } from "./matter-dialog/matter-dialog.component";
import * as moment from "moment";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
import { lowerCase, round, toLower } from "lodash";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { ResizeEvent } from "angular-resizable-element";
import { SelectionModel } from "@angular/cdk/collections";
import { Router } from "@angular/router";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSelect } from "@angular/material/select";
import { COMMA, E, ENTER } from "@angular/cdk/keycodes";
import { FilterSearchOptions } from "src/app/_constant/dynamic-search-option.const";
import { TimersService } from "@_services/timers.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { HyperlinkNavigationService } from "@_services/hyperlink-navigation.service";
import { AppPermissionsPipe } from "@_pipes/app-permissions.pipe";
import { TierPermissionPipe } from "@_pipes/tier-permission.pipe";

export class Group {
    level = 0;
    expanded = false;
    totalCounts = 0;
}

@Component({
    selector: "app-time-entries",
    templateUrl: "./time-entries.component.html",
    styleUrls: ["./time-entries.component.scss"],
    providers: [AppPermissionsPipe, TierPermissionPipe],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class TimeEntriesComponent implements OnInit, AfterViewInit, OnDestroy {
    isdisable: boolean = true;
    disabledMainMenuTrigger = true;
    hasSearchVal: boolean = false;
    filteredOptions: Observable<string[]>;
    forHideShowDateRangePicker: Boolean = false;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    TimeEnrtyForm: FormGroup;
    successMsg: any;
    quickTimeEntriesForm: FormGroup;
    ActiveTab: any;
    pageSize: any;
    errorWarningData: any = {};
    isLoadingResults: boolean = false;
    displayedColumns: string[];
    ColumnsObj = [];
    timeStops: any = [];
    dateColFilter = [];
    userList: any;
    LookupsList: any;
    TimerData: any = [];
    allData: any = [];
    _allGroup: any[];
    expandedSubCar: any[] = [];
    tempTotalData = {};
    isGetallData: any = false;
    expandedCar: any[] = [];
    groupByColumns = ["ITEMTYPEDESC"];
    TimerDropData: any;
    isShowDrop: boolean;
    ActivityList: any;
    currentuser = JSON.parse(localStorage.getItem("currentUser"));
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    lastFilter: any = {};
    highlightedRows: any;
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
    selectedColore: string = "rgb(217, 217, 217)";
    tempColobj: any;
    isspiner: boolean = false;
    isDisplay: boolean = false;
    isLoading_another: boolean = false;
    isMatterPopupOpen: boolean = true;
    sortingDefaultState: any = {};
    sortactive: any;
    sortDirection: any;
    priviousQuntitychnage: any;
    PRICEINCGSTVAL: any = 0;
    PRICEVAL: any = 0;
    StoreExGST: any;
    StoreIncGST: any;
    currentLoginData = JSON.parse(localStorage.getItem("currentUser"));
    calculateData: any = {
        MatterGuid: "",
        QuantityType: "",
        Quantity: "",
        FeeEarner: "",
        FeeType: "",
    };
    hasDateRange: boolean = false;
    QuantityLable: any =
        this.currentLoginData.DefaultQuantityType == "U" ? "Units" : "Hours";
    selection = new SelectionModel<any>(true, []);
    SelectedWIPCheckBox: any[];
    rateid: any = "";
    CheckedUncheckedData = []; // uninvoiced and un write off data List for checkBox.
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    @ViewChild("MatterInput", { static: false })
    MatterInput: ElementRef<HTMLInputElement>;
    @ViewChild("getValueOfSelectedMatter", { static: false })
    getValueOfSelectedMatter: ElementRef<HTMLInputElement>;
    @ViewChild("searchField", { static: false })
    searchField: ElementRef<HTMLInputElement>;
    mattername: any = [];
    selectedData: any = [];
    searchMatter: any;
    searchData: any;
    matterlist: any[] = [];
    // matterlist: Observable<string[]>;
    sortingMatterState;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    exportdatavalue: any;
    isCallDownloadFunction: boolean = false;
    RequestGuid: string;
    DeliverableField: any;
    DerivableDropShow: boolean = false;
    Deliverable: any;
    matterDataForFree: any;
    accessTierPermission: any = JSON.parse(
        localStorage.getItem("set_tier_permission")
    );

    // for filter
    activeFilters: any = {};
    clearFilters = false;
    optionalFilterList = [];

    optionalFilters = [];
    optionalFilterAppply = true;

    filterForm: FormGroup;

    activeOptionalFilter: any;

    optionalDateFilterPayload = [];

    dateFilter = FilterSearchOptions.dateFilter;

    currencyFilter = FilterSearchOptions.currencyFilter;

    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;

    FILTERS: FormArray;
    FILTERSLIST: FormArray;
    _filterList = [];

    addFilter;
    selectedFilterIs: any;

    uninvoiceWorkOptions: any = [
        {
            value: "No",
            description: "Uninvoiced Work",
        },
        {
            value: "Yes",
            description: "Invoiced Work",
        },
        {
            value: "",
            description: "All Work",
        },
    ];

    TimeEntryiesTypes: any = [
        {
            value: "",
            description: "All",
        },
        {
            value: "WipAndActivity",
            description: "Time + Fixed",
        },
        {
            value: "WIP",
            description: "Time Entries",
        },
        {
            value: "Activity",
            description: "Fixed Fees",
        },{
            value: "Sundry",
            description: "Sundries",
        },{
            value: "Disbursement",
            description: "Matter Expenses",
        },
    ];

    dayRangeOptions: any = [
        {
            value: "Today",
            description: "Today",
        },
        {
            value: "Yesterday",
            description: "Yesterday",
        },
        {
            value: "Last 7 days",
            description: "Last 7 days",
        },
        {
            value: "Last 30 days",
            description: "Last 30 days",
        },
        {
            value: "Last 90 days",
            description: "Last 90 days",
        },
        {
            value: "Last 6 months",
            description: "Last 6 months",
        },
        {
            value: "Last 12 months",
            description: "Last 12 months",
        },
        {
            value: "Last 2 years",
            description: "Last 2 years",
        },
        {
            value: "All time",
            description: "All time",
        },
        {
            value: "Date Range",
            description: "Date Range",
        },
    ];

    currentUserData: any;

    showFiltersList: boolean = true;

    totalApplicableFilterCount: number = 0;
    detectedChangeRef = Math.random();

    // ends here ~ for filter

    @HostListener("window:resize", ["$event"])
    saveAdvanceFilter = [];
    hyperLinkInfo: any;
    Object: any;
    quickTimeEntrySummary: any = {};
    COLOR: any;
    HyperlinkColor: any;
    lastUpdatedTimeEntry: any;
    class: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    PRICEValue: any;
    PRICEINCValue: any;
    gloableAdvancedFilters: any;
    GloableadvanceFilterPayload: any;
    private searchTerms = new Subject<string>();
    subscription: Subscription;
    // unsubscribedata: Subscription;

    constructor(
        private dialog: MatDialog,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private Timersservice: TimersService,
        private datepipe: DatePipe,
        private TableColumnsService: TableColumnsService,
        private behaviorService: BehaviorService,
        public MatDialog: MatDialog,
        private SortingbehaviorService: SortingBehaviourService,
        private _mainAPiServiceService: MainAPiServiceService,
        private cd: ChangeDetectorRef,
        private globalFunService: GlobalFunctionsService,
        private router: Router,
        private tierpermission: TierPermissionPipe,
        private _hyperlink: HyperlinkNavigationService
    ) {
        this._hyperlink.setColorSetting();
        this._hyperlink.setColorSettingWithHyperlink();
        this.behaviorService.ColorSystemSetting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((data) => {
            this.COLOR = data;
        });

        this.behaviorService.ColorSystemSettingHyperLink$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((data) => {
            this.HyperlinkColor = data;
        });
        this.currentUserData = JSON.parse(
            window.localStorage.getItem("currentUser")
        );
        this.Object = Object;
        this.addFilter = {
            OPERATION: "",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: "",
        };

        localStorage.setItem("istrackid", "timeentriescomponent");
        this.SortingbehaviorService.timeEntryScreenSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (result) => {
                if (result) {
                    this.sortingDefaultState = result;
                    localStorage.setItem(
                        "time_entry_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingDefaultState = JSON.parse(
                        localStorage.getItem("time_entry_screen_sorting")
                    );
                }
            }
        );
        this.SortingbehaviorService.timeEntryFilterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.lastFilter = result;
            }
        });
        // this.lastFilter = JSON.parse(localStorage.getItem('time_entries_filter'));
        if (
            this.lastFilter &&
            (this.lastFilter.hasOwnProperty("FeeEarner") ||
                this.lastFilter.hasOwnProperty("Invoiced") ||
                this.lastFilter.hasOwnProperty("ItemDateStart") ||
                this.lastFilter.hasOwnProperty("ItemDateEnd"))
        ) {
            localStorage.removeItem("time_entries_filter");
            this.lastFilter = "";
        }
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.isShowDrop = currentUser.PRODUCTTYPE == "Barrister" ? false : true;
        this.TimeEnrtyForm = this.fb.group({
            date: [""],
            uninvoicedWork: [""],
            type: ["WipAndActivity"],
            dlpdrop: [""],
            DayRange: ["Today"],
            ExGST: [""],
            IncGST: [""],
            Units: [""],
            Days: [""],
            Budget: [""],
            FILTERS: new FormArray([]),
            FILTERSLIST: new FormArray([]),
            MAINFILTER: [""],
            searchFilter: [""],
        });

        // this.TimeEnrtyForm.controls["FILTERS"].valueChanges.subscribe(
        //     (value) => {
                // this.detectedChangeRef = Math.random()
        //     }
        // );

        if (this.lastFilter) {
            if (this.lastFilter.ITEMDATESTART && this.lastFilter.ITEMDATEEND) {
                this.hasDateRange = true;
                let tempDate = this.lastFilter.ITEMDATESTART.split("/");
                let tempDate2 = this.lastFilter.ITEMDATEEND.split("/");
                let Sd = new Date(
                    tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                );
                let ed = new Date(
                    tempDate2[1] + "/" + tempDate2[0] + "/" + tempDate2[2]
                );
                // this.TimeEnrtyForm.controls['date'].setValue({ begin: Sd, end: ed });

                const date1 = Sd;
                const date2 = ed;
                const diffTime = Math.abs(date2.getTime() - date1.getTime());
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            } else {
                this.hasDateRange = true;
                let updatecurrentDate = new Date();
                updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
                // this.TimeEnrtyForm.controls['date'].setValue({ begin: new Date(), end: new Date() });
                // this.TimeEnrtyForm.controls['date'].setValue({ begin: updatecurrentDate, end: new Date() });
                // this.lastFilter.ITEMDATESTART = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
                this.lastFilter.ITEMDATESTART = this.datepipe.transform(
                    new Date(),
                    "dd/MM/yyyy"
                );
                this.lastFilter.ITEMDATEEND = this.datepipe.transform(
                    new Date(),
                    "dd/MM/yyyy"
                );
            }
            this.lastFilter.FEEEARNER = currentUser.WORKINGONBEHALFOFGUID
                ? currentUser.WORKINGONBEHALFOFGUID
                : currentUser.UserId;
            this.TimeEnrtyForm.controls["uninvoicedWork"].setValue(
                this.lastFilter.INVOICED
            );
            this.TimeEnrtyForm.controls["dlpdrop"].setValue(
                currentUser.WORKINGONBEHALFOFGUID
                    ? currentUser.WORKINGONBEHALFOFGUID
                    : currentUser.UserId
            );
            this.hasSearchVal = this.lastFilter.SearchString ? true : false;
        } else {
            this.hasDateRange = true;
            var dt = new Date();
            dt.setMonth(dt.getMonth() + 1);
            // this.TimeEnrtyForm.controls['date'].setValue({ begin: new Date(), end: new Date() });
            this.TimeEnrtyForm.controls["dlpdrop"].setValue(
                currentUser.WORKINGONBEHALFOFGUID
                    ? currentUser.WORKINGONBEHALFOFGUID
                    : currentUser.UserId
            );
            this.lastFilter = {
                FEEEARNER: currentUser.WORKINGONBEHALFOFGUID
                    ? currentUser.WORKINGONBEHALFOFGUID
                    : currentUser.UserId,
                INVOICED: "",
                ITEMDATESTART: this.datepipe.transform(
                    new Date(),
                    "dd/MM/yyyy"
                ),
                ITEMDATEEND: this.datepipe.transform(new Date(), "dd/MM/yyyy"),
            };
        }
        localStorage.setItem(
            "time_entries_filter",
            JSON.stringify(this.lastFilter)
        );
        // localStorage.setItem('timeEntriesFilter_config', JSON.stringify(this.lastFilter));
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (
                localStorage.getItem("istrackid") == "timeentriescomponent" &&
                result["click"] &&
                result["data"]["workitem"]
            ) {
                if (!localStorage.getItem("timeentries_list_columns")) {
                    this.getTableFilter();
                    this.getFilter();
                }
                this.LoadData(this.lastFilter);
                // result['click']=false;
            }
            if (
                localStorage.getItem("istrackid") == "timeentriescomponent" &&
                result["click"] &&
                result["data"]["workitem"]
            ) {
                this.SaveQuickTimeEntry();
            }
        });
        this.behaviorService.defultPreferencesData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.currentLoginData = JSON.parse(
                    localStorage.getItem("currentUser")
                );
                this.LoadData(this.lastFilter);
            }
        });
        this.behaviorService.defultFeeErnerData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.TimeEnrtyForm.controls["dlpdrop"].setValue(result);
                this.lastFilter.FEEEARNER = result;
                this.SortingbehaviorService.setWorkInprogressFilterData(
                    this.lastFilter
                );
                this.LoadData(this.lastFilter);
            }
        });
        this.behaviorService.timeEntryes$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                setTimeout(() => {
                    this.moveToAnotherMatter(result);
                }, 3000);
            }
        });

        this.behaviorService.loaderDATA$.subscribe(data=>{
            this.isLoadingResults=data;
        })

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA;
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
                this.isLoading_another = false;
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.permissionCheckforHeight$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((data) => {
            this.class = data;
        });

        this.behaviorService.DELIVERABLES$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((data) => {
            this.DeliverableField = data;
            if (this.DeliverableField == "No") {
                this.DerivableDropShow = true;
            } else {
                this.DerivableDropShow = false;
            }
        });

        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        // getSetData({ 'LookupType': 'Rates' }
        this._mainAPiServiceService
            .getSetData(
                { Action: "getdata", Filters: { LookupType: "Rates" } },
                "lookup"
            ).pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((responses) => {
                if (responses.CODE === 200 && responses.STATUS === "success") {
                    localStorage.setItem(
                        "Rates_lookups",
                        JSON.stringify(responses.DATA.LOOKUPS)
                    );
                    this.rateid = responses.DATA.LOOKUPS;
                    this.hasSearchVal = false;
                }
            });
        if (this.currentUser && this.currentUser.SORTBYSURNAME) {
            this.sortactive = "CLIENTNAME";
            this.sortDirection = "asc";
        } else {
            this.sortactive = "SHORTNAME";
            this.sortDirection = "desc";
        }
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "ITEMDATE";
            this.sortDirection = "asc";
        }
        // 275 by edefault
        // $('content').addClass('inner-scroll');
        // $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 175)) + 'px');
        // $(window).resize(function () {
        //   $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 175)) + 'px');
        // });
        this.behaviorService.ActiveSubMenu$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            this.ActiveTab = result;
        });
        if (this.ActiveTab == "quick-time-entries") {
            this.quickTimeEntriesForm = this.fb.group({
                matterautoVal: [""],
                MATTERGUID: [""],
                ITEMDATETEXT: [""],
                ITEMDATE: [new Date()],
                sendITEMDATE: [
                    this.datepipe.transform(new Date(), "dd/MM/yyyy"),
                ],
                PRICE: ["", {updateOn: 'blur'}],
                PRICEINCGST: ["", {updateOn: 'blur'}],
                sendPRICE: [""],
                sendPRICEINCGST: [""],
                INVOICEDATE: [
                    this.datepipe.transform(new Date(), "dd/MM/yyyy"),
                ],
                ADDITIONALTEXT: [""],
                QUANTITYTYPE: ["Hours"],
                QUANTITY: [0],
                FEEEARNER: [""],
                ITEMTIME: [""],
                RATEID: [""],
                MATTERDELIVERABLEGUID: [""],
            });

            this.commonQuntityTypesShow();
            this.quickTimeEntriesForm.controls["FEEEARNER"].setValue(
                this.currentLoginData.WORKINGONBEHALFOFGUID
                    ? this.currentLoginData.WORKINGONBEHALFOFGUID
                    : this.currentLoginData.UserId
            );
            this.calculateData.Quantity = this.qf.QUANTITY.value;
            this.calculateData.QuantityType =
                this.currentLoginData.DefaultQuantityType;
            this.timeStops = this.getTimeStops("00:00", "23:30");
            let userList = JSON.parse(localStorage.getItem("users_list"));
            if (userList && userList != null) {
                this.userList =
                    this.Timersservice.removeUSerWithoutId(userList);
            } else {
                this.Timersservice.GetUsers({}).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                    (res) => {
                        if (res.CODE == 200 && res.STATUS == "success") {
                            this.userList =
                                this.Timersservice.removeUSerWithoutId(
                                    res.DATA.USERS
                                );
                            localStorage.setItem(
                                "users_list",
                                JSON.stringify(res.DATA.USERS)
                            );
                        } else {
                            this.userList = [];
                        }
                    },
                    (err) => {
                        this.toastr.error(err);
                    }
                );
            }
            this.fiilUpQuickTimeEntry();
            this.ActivityList = [
                { ACTIVITYID: "hh:mm", DESCRIPTION: "hh:mm" },
                { ACTIVITYID: "Hours", DESCRIPTION: "Hours" },
                { ACTIVITYID: "Minutes", DESCRIPTION: "Minutes" },
                { ACTIVITYID: "Days", DESCRIPTION: "Days" },
                { ACTIVITYID: "Units", DESCRIPTION: "Units" },
                { ACTIVITYID: "Fixed", DESCRIPTION: "Fixed" },
            ];
            let LookupsList = JSON.parse(localStorage.getItem("lookups_list"));
            if (LookupsList && LookupsList != null) {
                this.LookupsList = LookupsList;
                this.filteredOptions = this.qf.ADDITIONALTEXT.valueChanges.pipe(
                    startWith(""),
                    map((value) => this._filter(value))
                );
            } else {
                this.Timersservice.GetLookupsData({
                    Action: "getdata",
                    Filters: { LookupType: "Time Entry" },
                }).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                    (res) => {
                        if (res.CODE == 200 && res.STATUS == "success") {
                            this.LookupsList = res.DATA.LOOKUPS;
                            localStorage.setItem(
                                "lookups_list",
                                JSON.stringify(res.DATA.LOOKUPS)
                            );
                            this.filteredOptions =
                                this.qf.ADDITIONALTEXT.valueChanges.pipe(
                                    startWith(""),
                                    map((value) => this._filter(value))
                                );
                        } else {
                            this.LookupsList = [];
                        }
                        this.isLoadingResults = false;
                    },
                    (err) => {
                        this.toastr.error(err);
                    }
                );
            }
            if (this.appPermissions[2]) {
                if (
                    this.appPermissions[2]["Override WIP Price"] == "1" ||
                    this.appPermissions[2]["Override WIP Price"] == 1
                ) {
                    this.quickTimeEntriesForm.controls["PRICEINCGST"].enable();
                    this.quickTimeEntriesForm.controls["PRICE"].enable();
                } else {
                    this.quickTimeEntriesForm.controls["PRICEINCGST"].disable();
                    this.quickTimeEntriesForm.controls["PRICE"].disable();
                }
            }
        }
        this.getTableFilter();
        this.getFilter();
        let userList = JSON.parse(localStorage.getItem("users_list"));
        if (userList && userList != null) {
            this.TimerDropData =
                this.Timersservice.removeUSerWithoutId(userList);
        } else {
            let d = {};
            this.Timersservice.GetUsers(d).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.TimerDropData =
                            this.Timersservice.removeUSerWithoutId(
                                res.DATA.USERS
                            );
                        localStorage.setItem(
                            "users_list",
                            JSON.stringify(res.DATA.USERS)
                        );
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
        }
        // this.LoadData(this.lastFilter);

        const daterange = this.TimeEnrtyForm.get("DayRange").value;
        this.forHideShowDateRangePicker = false;

        // this.selectDayRange(daterange);
        this.SelectDayRangeFilter(daterange);

        // Quick Time entry need Matter pop up for select matter.
        if (this.router.url.split("/")[2] == "quick-time-entries") {
            if (this.calculateData.MatterGuid == "") {
                // this.selectMatter();
                this.quickTimeEntriesForm.controls["QUANTITY"].setValue(0);
            } else {
                this.matterChange("MatterGuid", this.calculateData.MatterGuid);
                this.quickTimeEntriesForm.controls["QUANTITY"].setValue(0);
            }
        }

        this.setDefaultAdvanceFilter();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.subscription?.unsubscribe();
    }

    onResized(event: Event) {}

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        //this.unsubscribedata.unsubscribe();
        this.forHideShowDateRangePicker = false;
        this.matterDataForFree = JSON.parse(
            localStorage.getItem("set_active_matters")
        );
        if (this.matterDataForFree) {
            this.getDeliverabledata(this.matterDataForFree.MATTERGUID);
        }

        // setTimeout(()=>{
        //   this.defaultSetFilter();
        // },500)

        // 275 by edefault
        $("content").addClass("inner-scroll");
        $(".example-containerdata").css(
            "height",
            $(window).height() -
                ($("#filter-form-menu-wrap").height() + 275) +
                "px"
        );
        $(window).resize(function () {
            $(".example-containerdata").css(
                "height",
                $(window).height() -
                    ($("#filter-form-menu-wrap").height() + 275) +
                    "px"
            );
        });
    }

    /**
     * function gets the data of matter deliverable
     * @param MATTERGUID matterguid for calling API
     */
    getDeliverabledata(MATTERGUID) {
        const pipe = this.tierpermission.transform("Matter Deliverables");
        if (
            pipe &&
            MATTERGUID &&
            this.currentUser.PRODUCTTYPE != "Barrister" &&
            pipe.ALLOWED == 1
        ) {
            this._mainAPiServiceService
                .getSetData(
                    { Action: "GetData", Filters: { MATTERGUID: MATTERGUID, ACTIVE:'Active' } },
                    "matter-deliverable"
                ).pipe(takeUntil(this._unsubscribeAll$))
                .subscribe(
                    (responses) => {
                        if (
                            responses.CODE == "200" &&
                            responses.STATUS == "success"
                        ) {
                            this.isLoadingResults = false;
                            this.Deliverable = responses.DATA.RECORDS;
                        } else {
                            this.isLoadingResults = false;
                        }
                    },
                    (error) => {
                        this.isLoadingResults = false;
                    }
                );
        }
    }

    /**
     * function takes string parameter and returnd array of string
     * @param value string value for filtering data from array
     * @returns  it returns arrays of string
     */
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.LookupsList.filter((option) =>
            option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)
        );
    }

    /**
     * function returns array of time with hh:mm A format based on start and end time
     * @param start start time
     * @param end end time
     * @returns it returns array of time with hh:mm A format
     */
    getTimeStops(start, end) {
        var startTime = moment(start, "hh:mm");
        var endTime = moment(end, "hh:mm");
        if (endTime.isBefore(startTime)) {
            endTime.add(1, "day");
        }
        var timeStops = [];
        while (startTime <= endTime) {
            timeStops.push(moment(startTime).format("hh:mm A"));
            startTime.add(30, "minutes");
        }
        return timeStops;
    }

    /**
     * function is called to set the value of QUANTITYTYPE field
     */
    commonQuntityTypesShow() {
        switch (this.currentLoginData.DefaultQuantityType) {
            case "X": {
                this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                    "hh:mm"
                );
                break;
            }
            case "H": {
                this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                    "Hours"
                );
                break;
            }
            case "M": {
                this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                    "Minutes"
                );
                break;
            }
            case "D": {
                this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                    "Days"
                );
                break;
            }
            case "U": {
                this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                    "Units"
                );
                break;
            }
            case "F": {
                this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                    "Fixed"
                );
                break;
            }
            default: {
                this.calculateData.FeeType = event;
                this.calculateData.QuantityType = "F";
                break;
            }
        }
    }
    get qf() {
        return this.quickTimeEntriesForm.controls;
    }

    /**
     * function is called to set the value of Price Ex GST
     */
    calcPE() {
        let priceTemp: any;
        if (Number(this.StoreExGST) == Number(this.qf.PRICE.value)) {
            priceTemp = this.qf.sendPRICE.value;
        } else {
            priceTemp = this.qf.PRICE.value;
        }
        if (typeof priceTemp === "undefined") {
            priceTemp = 0;
        }
        this.quickTimeEntriesForm.patchValue({
            sendPRICE: Number(priceTemp).toFixed(6),
            PRICE: Number(priceTemp).toFixed(2),
        });
        priceTemp = Number(priceTemp) * 1.1;
        this.quickTimeEntriesForm.patchValue({
            sendPRICEINCGST: Number(priceTemp).toFixed(6),
            PRICEINCGST: Number(priceTemp).toFixed(2),
        });
        this.StoreIncGST = Number(priceTemp.toFixed(2));
    }

    /**
     * function is called to set the value of Price Inc GST
     */
    calcPI() {
        let temGst: any;
        if (Number(this.StoreIncGST) == Number(this.qf.PRICEINCGST.value)) {
            temGst = this.qf.sendPRICEINCGST.value;
        } else {
            temGst = this.qf.PRICEINCGST.value;
        }
        if (typeof temGst === "undefined") {
            temGst = 0;
        }
        this.quickTimeEntriesForm.patchValue({
            sendPRICEINCGST: Number(temGst).toFixed(6),
            PRICEINCGST: Number(temGst).toFixed(2),
        });
        temGst = Number(temGst) / 1.1;
        this.quickTimeEntriesForm.patchValue({
            sendPRICE: Number(temGst).toFixed(6),
            PRICE: Number(temGst).toFixed(2),
        });
        this.StoreExGST = Number(temGst.toFixed(2));
    }

    /**
     * function is called to calculate work items
     * @param key key is string value of particular field
     * @param event current event value of angular form field tag like input, select etc.
     */
    matterChange(key: any, event: any) {
        if (this.qf.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.qf.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.quickTimeEntriesForm.controls["MATTERGUID"].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case "hh:mm": {
                    this.calculateData.QuantityType = "X";
                    break;
                }
                case "Hours": {
                    this.calculateData.QuantityType = "H";
                    break;
                }
                case "Minutes": {
                    this.calculateData.QuantityType = "M";
                    break;
                }
                case "Days": {
                    this.calculateData.QuantityType = "D";
                    break;
                }
                case "Units": {
                    this.calculateData.QuantityType = "U";
                    break;
                }
                case "Fixed": {
                    this.calculateData.QuantityType = "F";
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = "F";
                    break;
                }
            }
        }

        this.priviousQuntitychnage = this.calculateData.QuantityType;
        this.calculateData.Quantity = this.qf.QUANTITY.value
            ? this.qf.QUANTITY.value
            : 1;
        this.calculateData.MatterGuid = this.qf.MATTERGUID.value;
        this.calculateData.rateid =
            this.qf.RATEID.value.toString() !== ""
                ? this.qf.RATEID.value.toString()
                : "0";
        //Data.RATEID =(this.qf.RATEID.value.toString() !=='')?this.qf.RATEID.value.toString():"0";
        if (
            this.calculateData.MatterGuid != "" &&
            (this.calculateData.QuantityType != "" ||
                this.calculateData.FeeType != "")
        ) {
            this.isLoading_another = true;
            this.Timersservice.calculateWorkItems(this.calculateData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        let CalcWorkItemCharge = response.DATA;
                        this.quickTimeEntriesForm.controls["PRICE"].setValue(
                            Number(CalcWorkItemCharge.PRICE).toFixed(2)
                        );
                        this.quickTimeEntriesForm.controls[
                            "PRICEINCGST"
                        ].setValue(
                            Number(CalcWorkItemCharge.PRICEINCGST).toFixed(2)
                        );
                        this.quickTimeEntriesForm.controls[
                            "sendPRICE"
                        ].setValue(Number(CalcWorkItemCharge.PRICE).toFixed(6));
                        this.quickTimeEntriesForm.controls[
                            "sendPRICEINCGST"
                        ].setValue(
                            Number(CalcWorkItemCharge.PRICEINCGST).toFixed(6)
                        );
                        this.isLoading_another = false;
                    } else {
                        this.isLoading_another = false;
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
        }
    }

    /**
     * function is called to change quantity type
     * @param key key is string value of particular field
     * @param event current event value of angular form field tag like input, select etc.
     */
    quntityTypeChange(key: any, event: any) {
        if (this.qf.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.qf.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.quickTimeEntriesForm.controls["MATTERGUID"].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case "hh:mm": {
                    this.calculateData.QuantityType = "X";
                    break;
                }
                case "Hours": {
                    this.calculateData.QuantityType = "H";
                    break;
                }
                case "Minutes": {
                    this.calculateData.QuantityType = "M";
                    break;
                }
                case "Days": {
                    this.calculateData.QuantityType = "D";
                    break;
                }
                case "Units": {
                    this.calculateData.QuantityType = "U";
                    break;
                }
                case "Fixed": {
                    this.calculateData.QuantityType = "F";
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = "F";
                    break;
                }
            }
        }
        this.calculateData.Quantity = this.qf.QUANTITY.value;
        if (
            this.calculateData.MatterGuid != "" &&
            this.calculateData.Quantity != "" &&
            (this.calculateData.QuantityType != "" ||
                this.calculateData.FeeType != "")
        ) {
            this.isLoading_another = true;
            let sendData = {
                FromQuantityType: this.priviousQuntitychnage,
                toQuantityType: this.calculateData.QuantityType,
                Quantity: this.qf.QUANTITY.value,
            };
            this.Timersservice.ChangeQuantityType(sendData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.quickTimeEntriesForm.controls["QUANTITY"].setValue(
                            response.DATA.QUANTITY
                        );
                        this.matterChange("QuantityType", event);
                        this.priviousQuntitychnage =
                            response.DATA.TOQUANTITYTYPE;
                        this.isLoading_another = false;
                    } else if (response.MESSAGE == "Not logged in") {
                        this.isLoading_another = false;
                    } else {
                        this.isLoading_another = false;
                    }
                },
                (err) => {
                    this.isLoading_another = false;
                    this.toastr.error(err);
                }
            );
        }
    }
    // tabMatter() {
    //     this.isMatterPopupOpen = false;
    //     // if(this.isMatterPopupOpen == false){
    //     // this.isMatterPopupOpen == true;
    //     this.isLoading_another = true;

    //     let Payload = {
    //         Action: "GetData",
    //         Filters: { SHORTNAME: this.qf.matterautoVal.value },
    //     };

    //     this._mainAPiServiceService.getSetData(Payload, "matter").pipe(takeUntil(this._unsubscribeAll$)).subscribe(
    //         (response) => {
    //             if (response.CODE == 200 && response.STATUS == "success") {
    //                 if (response.DATA.RECORDS.length == 0) {
    //                     this.behaviorService.matterSerachFromQuickTimeEntry({
    //                         SHORTNAME: this.qf.matterautoVal.value,
    //                     });
    //                     this.selectMatter();
    //                     // this.isMatterPopupOpen == true;
    //                     this.isLoading_another = false;
    //                 } else if (response.DATA.RECORDS.length == 1) {
    //                     this.quickTimeEntriesForm.controls[
    //                         "MATTERGUID"
    //                     ].setValue(response.DATA.RECORDS[0].MATTERGUID);
    //                     this.quickTimeEntriesForm.controls[
    //                         "matterautoVal"
    //                     ].setValue(
    //                         response.DATA.RECORDS[0].SHORTNAME +
    //                             " : " +
    //                             response.DATA.RECORDS[0].MATTER
    //                     );
    //                     this.matterChange(
    //                         "MatterGuid",
    //                         response.DATA.RECORDS[0].MATTERGUID
    //                     );
    //                     this.isLoading_another = false;
    //                 } else {
    //                     // open popup
    //                     this.behaviorService.matterSerachFromQuickTimeEntry({
    //                         SHORTNAME: this.qf.matterautoVal.value,
    //                     });
    //                     this.selectMatter();
    //                     // this.isMatterPopupOpen == true;
    //                     this.isLoading_another = false;
    //                 }
    //             } else if (response.MESSAGE == "Not logged in") {
    //                 this.isLoading_another = false;
    //             } else {
    //                 this.isLoading_another = false;
    //             }
    //         },
    //         (err) => {
    //             this.toastr.error(err);
    //             this.isLoading_another = false;
    //         }
    //     );
    //     // }
    // }


    /**
     * function is called to select a matter from matter component popup
     */
    public selectMatter() {
        this.searchData = [];
        this.quickTimeEntriesForm.controls["MATTERDELIVERABLEGUID"].setValue(
            ""
        );
        this.matterlist = this.searchData;

        if (this.isMatterPopupOpen == true) {
            this.behaviorService.matterSerachFromQuickTimeEntry({
                SHORTNAME: "",
            });
        }
        const dialogRef = this.dialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: { UserFilter: this.qf.FEEEARNER.value },
        });
        dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem("istrackid", "timeentriescomponent");
            this.isMatterPopupOpen = true;
            this.cd.detectChanges();
            if (result) {
                this.quickTimeEntriesForm.controls["MATTERGUID"].setValue(
                    result.MATTERGUID
                );
                this.quickTimeEntriesForm.controls["matterautoVal"].setValue(
                    result.SHORTNAME + " : " + result.MATTER
                );
                this.mattername = [result.SHORTNAME + "--" + result.MATTER];
                this.matterChange("MatterGuid", result.MATTERGUID);
                this.getDeliverabledata(result.MATTERGUID);
                this.isLoading_another = false;
            }
        });
    }

    /**
     * function is called to set the date field of formgroup
     * @param type string value of particular form field
     * @param event current event of angular material date picker tag
     */
    QuickDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.quickTimeEntriesForm.controls["sendITEMDATE"].setValue(
            this.datepipe.transform(event.value, "dd/MM/yyyy")
        );
        this.LoadData({});
    }

    /**
     * function is call api for saving quick time entry
     */
    SaveQuickTimeEntry() {
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        this.isspiner = true;
        let PostData: any = {
            MATTERGUID: this.qf.MATTERGUID.value,
            ITEMDATE: this.qf.sendITEMDATE.value,
            PRICE: Number(this.qf.sendPRICE.value),
            PRICEINCGST: Number(this.qf.sendPRICEINCGST.value),
            ADDITIONALTEXT: this.qf.ADDITIONALTEXT.value,
            QUANTITYTYPE: this.qf.QUANTITYTYPE.value,
            FEEEARNER: this.qf.FEEEARNER.value,
            ITEMTYPE: 1,
            ITEMTIME: this.qf.ITEMTIME.value,
            QUANTITY: this.qf.QUANTITY.value,
            RATEID: this.qf.RATEID.value.toString(),
            MATTERDELIVERABLEGUID: this.qf.MATTERDELIVERABLEGUID.value,
        };
        this.successMsg = "Time entry added successfully";
        let FormAction = "insert";
        let PostQuickTimeEntryData: any = {
            FormAction: FormAction,
            VALIDATEONLY: true,
            RequestGuid: this.RequestGuid,
            Data: PostData,
        };
        this.Timersservice.SetWorkItems(PostQuickTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (res) => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.checkValidation(
                        res.DATA.VALIDATIONS,
                        PostQuickTimeEntryData
                    );
                } else if (res.CODE == 451 && res.STATUS == "warning") {
                    this.checkValidation(
                        res.DATA.VALIDATIONS,
                        PostQuickTimeEntryData
                    );
                } else if (res.CODE == 450 && res.STATUS == "error") {
                    this.checkValidation(
                        res.DATA.VALIDATIONS,
                        PostQuickTimeEntryData
                    );
                }
            },
            (err) => {
                this.isspiner = false;
                this.toastr.error(err);
            }
        );
    }


    /**
     * function is called to default value of quick time entry form fields
     */
    fiilUpQuickTimeEntry() {
        let previousSaveQuickTimeEntryData = JSON.parse(
            localStorage.getItem("previousSaveQuickTimeEntry")
        );
        if (previousSaveQuickTimeEntryData) {
            this.commonQuntityTypesShow();
            this.filteredOptions = this.qf.ADDITIONALTEXT.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
            );
            this.quickTimeEntriesForm.controls["ITEMDATE"].setValue(
                previousSaveQuickTimeEntryData["ITEMDATE"]
            );
            this.quickTimeEntriesForm.controls["sendITEMDATE"].setValue(
                previousSaveQuickTimeEntryData["sendITEMDATE"]
            );
            this.quickTimeEntriesForm.controls["FEEEARNER"].setValue(
                previousSaveQuickTimeEntryData["FEEEARNER"]
            );
            this.quickTimeEntriesForm.controls["QUANTITY"].setValue(0);
            this.quickTimeEntriesForm.controls["PRICE"].setValue(0);
            this.quickTimeEntriesForm.controls["PRICEINCGST"].setValue(0);
            this.quickTimeEntriesForm.controls["sendPRICE"].setValue(0);
            this.quickTimeEntriesForm.controls["sendPRICEINCGST"].setValue(0);
            this.quickTimeEntriesForm.controls["ADDITIONALTEXT"].setValue("");
            this.quickTimeEntriesForm.controls["ITEMTIME"].setValue("");
            this.quickTimeEntriesForm.controls["MATTERGUID"].setValue(
                previousSaveQuickTimeEntryData["MATTERGUID"]
            );
            this.quickTimeEntriesForm.controls["matterautoVal"].setValue(
                previousSaveQuickTimeEntryData["matterautoVal"]
            );
            this.quickTimeEntriesForm.controls["QUANTITYTYPE"].setValue(
                previousSaveQuickTimeEntryData["QUANTITYTYPE"]
            );
            this.quickTimeEntriesForm.controls["RATEID"].setValue(
                previousSaveQuickTimeEntryData["RATEID"].toString()
            );
            this.calculateData.Quantity = this.qf.QUANTITY.value;
            this.calculateData.QuantityType =
                this.currentLoginData.DefaultQuantityType;
            this.calculateData.MatterGuid =
                previousSaveQuickTimeEntryData["MATTERGUID"];
            this.quntityTypeChange(
                "QuantityType",
                previousSaveQuickTimeEntryData["QUANTITYTYPE"]
            );
        } else {
            this.quickTimeEntriesForm.controls["RATEID"].setValue('0');
        }
        // this.calculateData.Quantity = this.qf.QUANTITY.value;
        // this.calculateData.QuantityType = this.currentLoginData.DefaultQuantityType;
        // this.calculateData.MatterGuid = '';
        // this.StoreExGST = '';
        // this.StoreIncGST = '';
    }
    checkValidation(bodyData: any, PostQuickTimeEntryData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        // errorData
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == "NO") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (lowerCase(value.VALUEVALID) == "warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { Error: tempError, Warning: tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.isspiner = false;
        } else if (Object.keys(warningData).length != 0) {
            this.isspiner = false;
            this.confirmDialogRef = this.MatDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: warningData,
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to Save?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.isspiner = true;
                    this.saveQuick(PostQuickTimeEntryData);
                }
                this.confirmDialogRef = null;
            });
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            this.saveQuick(PostQuickTimeEntryData);
        }
    }


    /**
     * function is calling api for setting work items of particular time entry
     * @param PostQuickTimeEntryData payload for calling api
     */
    saveQuick(PostQuickTimeEntryData: any) {
        PostQuickTimeEntryData.VALIDATEONLY = false;
        this.Timersservice.SetWorkItems(PostQuickTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (res) => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.isspiner = false;
                    let previousSaveQuickTimeEntry: any = {};
                    Object.keys(this.quickTimeEntriesForm.controls).forEach(
                        (key) => {
                            previousSaveQuickTimeEntry[key] =
                                this.quickTimeEntriesForm.controls[key].value;
                        }
                    );
                    localStorage.setItem(
                        "previousSaveQuickTimeEntry",
                        JSON.stringify(previousSaveQuickTimeEntry)
                    );
                    this.LoadData(this.lastFilter);

                    this.fiilUpQuickTimeEntry();
                    this.toastr.success(this.successMsg);
                } else if (res.CODE == 451 && res.STATUS == "warning") {
                    this.toastr.warning(res.MESSAGE);
                    this.isspiner = false;
                } else if (res.CODE == 450 && res.STATUS == "error") {
                    this.toastr.warning(res.MESSAGE);
                    this.isspiner = false;
                }
            },
            (err) => {
                this.isspiner = false;
                this.toastr.error(err);
            }
        );
    }

    /**
     * function is called to reload all data
     * @param isAllData boolean flag pass to call the API
     * @param flag boolean flag pass to call the API
     */
    async refreshTab(isAllData: any, flag?: any) {
        this.isCallDownloadFunction = flag;
        // let temD = await JSON.parse(localStorage.getItem('time_entries_filter'));
        let temD = await JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        let filterData: any = (await temD) ? temD : {};
        if (isAllData && filterData) {
            this.isGetallData = await isAllData;
            filterData.GETALLDATA = await this.isGetallData;
        }
        // await this.LoadData(filterData);
        await this.LoadDataMod(filterData);
    }

    /**
     * function is set the header or columns for mat table list
     */
    getTableFilter() {
        let timeentriesListColumns: any = JSON.parse(
            localStorage.getItem("timeentries_list_columns")
        );
        if (timeentriesListColumns && timeentriesListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(
                timeentriesListColumns.ColumnsObj
            );
            this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(
                timeentriesListColumns.ColumnsObj
            );
            this.tempColobj = data.tempColobj;
            this.displayedColumns = timeentriesListColumns.displayedColumns;
            this.ColumnsObj = timeentriesListColumns.ColumnsObj;
            localStorage.setItem('time-entries-justification',JSON.stringify(data?.colobj));
            //For Checkbox multi select time entries
            if (!this.displayedColumns.includes("select")) {
                if (this.displayedColumns.length !== 0) {
                    this.displayedColumns.splice(0, 0, "select");
                }
            }
            this.dateColFilter = data.dateCol;
            if (this.currentuser.PRODUCTTYPE == "Barrister") {
                let index = this.ColumnsObj.findIndex(
                    (e: any) => e.COLUMNID == "CLIENTNAME"
                );
                this.ColumnsObj[index].DESCRIPTION = "Solicitor Name";
                let idnex1 = this.displayedColumns.indexOf("FEEEARNER");
                if(idnex1 != -1){
                    this.displayedColumns.splice(idnex1, 1);
                }
            }
        } else {
            this.TableColumnsService.getTableFilter(
                "time entries",
                ""
            ).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        let data = this.TableColumnsService.filtertableColum(
                            response.DATA.RECORDS
                        );
                        this.hyperLinkInfo =
                            this.TableColumnsService.hyperLinkInfo(
                                response.DATA.RECORDS
                            );
                        this.tempColobj = data.tempColobj;
                        this.displayedColumns = data.showcol;
                        this.ColumnsObj = data.colobj;
                        localStorage.setItem('time-entries-justification',JSON.stringify(data?.colobj));
                        //For Checkbox multi select time entries
                        this.displayedColumns.splice(0, 0, "select");
                        this.dateColFilter = data.dateCol;
                        localStorage.setItem(
                            "timeentries_list_columns",
                            JSON.stringify({
                                ColumnsObj: data.colobj,
                                displayedColumns: data.showcol,
                            })
                        );
                        if (this.currentuser.PRODUCTTYPE == "Barrister") {
                            let index = this.ColumnsObj.findIndex(
                                (e: any) => e.COLUMNID == "CLIENTNAME"
                            );
                            this.ColumnsObj[index].DESCRIPTION =
                                "Solicitor Name";
                            let index1 =
                                this.displayedColumns.indexOf("FEEEARNER");
                                if(index1 != -1){
                                    this.displayedColumns.splice(index1, 1);
                                }
                        }
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
        }
    }

    /**
     * function is called when user starting resizing particular column of table
     * @param event resize event of particular column of table
     * @param columnName  column name of table which needs to be resizing
     */
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(
                "mat-column-" + columnName
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(
                (col) => col.COLUMNID === columnName
            );
            if (columnName != "select") {
                this.ColumnsObj[indexData]["WIDTH"] = event.rectangle.width;
            }
            localStorage.setItem(
                "timeentries_list_columns",
                JSON.stringify({
                    ColumnsObj: this.ColumnsObj,
                    displayedColumns: this.displayedColumns,
                })
            );
        }
    }

    /**
     * function is called when user is about to end the resize column of table
     * @param event resize event of particular column of table
     * @param columnName column name of table which needs to be resizing
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        this.TableColumnsService.SaveWidthData(
            this.ColumnsObj,
            "time entries",
            ""
        );
    }

    /**
     * function is called to set the default width of particular columns of mat table
     * @param displayedColumns columns of mat table
     * @param timeout timeout seconds for settimeout function
     */
    setDefaultWidth(displayedColumns, timeout) {
        setTimeout(() => {
            if (displayedColumns) {
                displayedColumns.forEach((element) => {
                    if (element != "select") {
                        let temWidth = this.tempColobj[element]["WIDTH"];
                        const cssValue = temWidth + "px";
                        const columnElts = document.getElementsByClassName(
                            "mat-column-" + element
                        );
                        for (let i = 0; i < columnElts.length; i++) {
                            const currentEl = columnElts[i] as HTMLDivElement;
                            currentEl.style.visibility = "inherit";
                            currentEl.style.width = cssValue;
                        }
                    }
                });
            }
        }, timeout);
    }

    /**
     * function is called to sort the table data with date wise
     */
    // sortingDate() {
    //     this.TimerData.sortingDataAccessor = (item, property) => {
    //         let FildeValue = this.dateColFilter;
    //         if (FildeValue.includes(property)) {
    //             if (
    //                 item[property] &&
    //                 (property == "ITEMDATEANDTIME" ||
    //                     property == "CREATEDDATEANDTIME" ||
    //                     property == "MODIFIEDDATEANDTIME")
    //             ) {
    //                 let momentDate = moment(
    //                     moment(item[property], "DD/MM/YYYY hh:mm A")
    //                 ).format("YYYY-MM-DD HH:mm:ss");
    //                 let timestamp = Date.parse(momentDate);
    //                 return timestamp / 1000;
    //             } else if (
    //                 item[property] &&
    //                 (property != "ITEMDATEANDTIME" ||
    //                     property != "CREATEDDATEANDTIME" ||
    //                     property != "MODIFIEDDATEANDTIME")
    //             ) {
    //                 let tempDate = item[property].split("/");
    //                 let Sd = new Date(
    //                     tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
    //                 );
    //                 let newDate = new Date(Sd);
    //                 return newDate;
    //             }
    //             return item[property];
    //         } else {
    //             return item[property];
    //         }
    //     };
    //     // proper shorting for date
    //     this.TimerData.sort = this.sort;
    // }

    /**
     * function is called when user click on sort icon of particular column of mat-table
     * @param val current event of column
     */
    sortData(val) {
        this.SortingbehaviorService.timeEntryScreenSorting(val);
        this.setDefaultWidth(this.displayedColumns, 0);
    }
    get f() {
        return this.TimeEnrtyForm.controls;
    }


    /**
     * function is called when user select a particular row of mat table
     * @param Data current data of selected row
     */
    editTimeEntry(Data: any) {
        if (Data) {
            this.lastUpdatedTimeEntry = Data.WORKITEMGUID;
            this.behaviorService.MainTimeEntryData(Data);
            this.behaviorService.setworkInProgressData(Data);
            this.behaviorService.SpendMoneyData(Data);
            localStorage.setItem("edit_WORKITEMGUID", Data.WORKITEMGUID);
        } else {
            this.behaviorService.setworkInProgressData(null);
        }
    }

    /**
     * function is called when user hovers a mouse on a particular row of mat table
     * @param row current data of selected row
     * @returns retuen the string value based on condition
     */
    getToolTipData(row: any) {
        if (row.INVOICEGUID && row.INVOICEGUID != -1) {
            return "This item has already been invoiced";
        } else if (row.INVOICEGUID == -1) {
            return "This item has been written off.";
        } else {
            return "";
        }
    }

    /**
     * function is called get the all the latest data of time entries
     * @param Data payload for the API
     */
    LoadData(Data: any) {
        if (this.ActiveTab == "quick-time-entries") {
            Data.ITEMDATEEND = this.qf.sendITEMDATE.value;
            Data.ITEMDATESTART = this.qf.sendITEMDATE.value;
            Data.FEEEARNER = this.qf.FEEEARNER.value;
            let lastFilter = JSON.parse(
                localStorage.getItem("time_entries_filter")
            );
            this.SortingbehaviorService.SettimeEntryFilterData(lastFilter);
        } else {
            this.SortingbehaviorService.SettimeEntryFilterData(Data);
        }

        this.SortingbehaviorService.timeEntryScreenSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (result) => {
                if (result) {
                    this.sortingMatterState = result;
                    localStorage.setItem(
                        "time_entry_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingMatterState = JSON.parse(
                        localStorage.getItem("time_entry_screen_sorting")
                    );
                }
            }
        );

        Data.ITEMTYPE = "WipAndActivity";
        if (Data.DateRange || Data.DateRangeDesc) {
            delete Data.DateRange;
            delete Data.DateRangeDesc;
        }
        //Advance Filter Payload Logic.
        const advanceFilterPayload =
            this.TableColumnsService.loadAdvanceFilterPayload("TimeEntries");
        Data.AdvancedFilters =
            advanceFilterPayload && advanceFilterPayload.length !== 0
                ? advanceFilterPayload
                : [];

        if (Data.AdvancedFilters.length == 0) {
            delete Data.AdvancedFilters;
            localStorage.removeItem("TimeEntries_Advance_Filter");
        }

        // Data.RATEID =(this.qf.RATEID.value.toString() !=='')?this.qf.RATEID.value.toString():"0";
        this.TimerData = [];
        this.isLoadingResults = true;
        this.Timersservice.getTimeEnrtyData(Data).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.quickTimeEntrySummary = response.DATA;
                    this.behaviorService.MainTimeEntryData(null);
                    this.TimeEnrtyForm.controls["ExGST"].setValue(
                        response.DATA.EXGSTTOTAL
                    );
                    this.TimeEnrtyForm.controls["IncGST"].setValue(
                        response.DATA.INCGSTTOTAL
                    );
                    this.QuantityLable =
                        this.currentLoginData.DefaultQuantityType == "U"
                            ? "Units"
                            : "Hours";
                    let UnitsTotal =
                        this.currentLoginData.DefaultQuantityType == "U"
                            ? response.DATA.UNITSTOTAL
                            : response.DATA.HOURSTOTAL;
                    this.TimeEnrtyForm.controls["Units"].setValue(UnitsTotal);
                    this.TimeEnrtyForm.controls["Days"].setValue(
                        response.DATA.DAYSTOTAL
                    );
                    this.TimeEnrtyForm.controls["Budget"].setValue(
                        response.DATA.BUDGETTOTAL
                    );

                    this.TimeEnrtyForm.controls["ExGST"].disable();
                    this.TimeEnrtyForm.controls["IncGST"].disable();
                    this.TimeEnrtyForm.controls["Units"].disable();
                    this.TimeEnrtyForm.controls["Days"].disable();
                    this.TimeEnrtyForm.controls["Budget"].disable();
                    let tempData = [];
                    //this.CheckedUncheckedData = [];
                    response.DATA.WORKITEMS.forEach((group) => {
                        if (!group.ISGROUPBY) {
                            tempData.push(group);
                            if (!group.INVOICEGUID) {
                                // this.CheckedUncheckedData.push(group);
                            }
                        } else {
                            this.tempTotalData[group.ITEMTYPEDESC] = group;
                        }
                    });
                    this.allData = tempData;
                    this.TimerData = new MatTableDataSource();
                    this.TimerData.paginator = this.paginator;
                    this.TimerData.data = this.getGroups(
                        this.allData,
                        this.groupByColumns
                    );
                    // this.sortingDate();
                    if (this.TimerData.data[0]) {
                        this.groupHeaderClick(this.TimerData.data[0]);
                        this.isDisplay = false;
                        this.editTimeEntry(this.TimerData.data[1]);
                        this.highlightedRows = this.TimerData.data[1].WORKITEMGUID;
                        let shortingData: any = {
                            active: this.sortactive,
                            direction: this.sortDirection,
                        };
                        this.sortingCLM(shortingData);
                    } else {
                        this.editTimeEntry(null);
                        this.isDisplay = true;
                    }
                    this.isGetallData = response.DATA.TOOMANYRECORDS;
                    Data.GETALLDATA = this.isGetallData;
                    this.SortingbehaviorService.SettimeEntryFilterData(Data);
                    this.setDefaultWidth(this.displayedColumns, 500);
                    if (this.isCallDownloadFunction == true) {
                        setTimeout(() => {
                            this.downloadFile(this.allData);
                            this.isCallDownloadFunction = false;
                        }, 1000);
                    }
                }
                this.isLoadingResults = false;
            },
            (err) => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            }
        );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * function is called when user change pages of mat table
     * @param event current event of mat table data list
     */
    onPaginateChange(event) {
        this.setDefaultWidth(this.displayedColumns, 0);
        this.pageSize.time_entries_page = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }
    
    /**
     * This function is used to change the uninvoiced work
     */
    uninvoicedWorkChange(value) {
        let filterVal = !localStorage.getItem("time_entries_filter")
            ? {
                  FEEEARNER: "",
                  INVOICED: value,
                  ITEMDATESTART: "",
                  ITEMDATEEND: "",
              }
            : JSON.parse(localStorage.getItem("time_entries_filter"));
        if (!localStorage.getItem("time_entries_filter")) {
            localStorage.setItem(
                "time_entries_filter",
                JSON.stringify(filterVal)
            );
        } else {
            filterVal.INVOICED = value;
            localStorage.setItem(
                "time_entries_filter",
                JSON.stringify(filterVal)
            );
        }
        this.LoadData(filterVal);
    }

    /**
     * This function is used to change the data value.
     */
    dlpChange(value) {
        let filterVal = !localStorage.getItem("time_entries_filter")
            ? {
                  FEEEARNER: value,
                  INVOICED: "",
                  ITEMDATESTART: "",
                  ITEMDATEEND: "",
              }
            : JSON.parse(localStorage.getItem("time_entries_filter"));
        if (!localStorage.getItem("time_entries_filter")) {
            localStorage.setItem(
                "time_entries_filter",
                JSON.stringify(filterVal)
            );
        } else {
            filterVal.FEEEARNER = value;
            localStorage.setItem(
                "time_entries_filter",
                JSON.stringify(filterVal)
            );
        }
        this.LoadData(filterVal);
    }

    /**
     * This function is used to choosed the date.
     */
    choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        try {
            if (type == "clearDateRange") {
                this.hasDateRange = false;
                this.CommonDatefun("", "");
                this.TimeEnrtyForm.controls["date"].setValue({
                    begin: "",
                    end: "",
                });
            } else {
                this.hasDateRange = true;
                let begin = this.datepipe.transform(
                    event.value["begin"],
                    "dd/MM/yyyy"
                );
                let end = this.datepipe.transform(
                    event.value["end"],
                    "dd/MM/yyyy"
                );
                this.CommonDatefun(end, begin);
            }
        } catch (error) {
            this.hasDateRange = false;
            this.CommonDatefun("", "");
        }
        let filterData = JSON.parse(
            localStorage.getItem("time_entries_filter")
        );
        this.LoadData(filterData);
    }

    /**
     * This function is used to select the day range data value.
     */
    selectDayRange(val) {
        this.lastFilter = JSON.parse(
            localStorage.getItem("time_entries_filter")
        );
        let currentDate = new Date();
        let updatecurrentDate = new Date();
        let begin = this.datepipe.transform(currentDate, "dd/MM/yyyy");
        if (val == "Last 7 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 7);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Today") {
            // updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: currentDate,
                end: currentDate,
            });
        } else if (val == "Yesterday") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 1);
            begin = this.datepipe.transform(updatecurrentDate, "dd/MM/yyyy");
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: updatecurrentDate,
            });
        } else if (val == "Last 30 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Last 90 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Date Range") {
            this.forHideShowDateRangePicker = true;
            return;
        }
        let end = this.datepipe.transform(updatecurrentDate, "dd/MM/yyyy");
        this.CommonDatefun(begin, end);
        this.LoadData(this.lastFilter);
    }


    /**
     * This function is used to open the dialog data value.
     */
    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        dialogConfig.disableClose = true;
        if (this.currentuser.PRODUCTTYPE == "Barrister") {
            let index = this.ColumnsObj.findIndex(
                (e: any) => e.COLUMNID == "FEEEARNER"
            );
            this.ColumnsObj.splice(index, 1);
        }
        dialogConfig.data = {
            data: this.ColumnsObj,
            type: "time entries",
            list: "",
        };
        //open pop-up
        const dialogRef = this.dialog.open(
            SortingDialogComponent,
            dialogConfig
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.displayedColumns = result.columObj;
                if (!this.displayedColumns.includes("select")) {
                    if (this.displayedColumns.length !== 0) {
                        this.displayedColumns.splice(0, 0, "select");
                    }
                }
                this.tempColobj = result.tempColobj;
                this.ColumnsObj = result.columnameObj;
                this.dateColFilter = result.dateCol;
                localStorage.setItem('time-entries-justification',JSON.stringify(result?.columnameObj));
                localStorage.setItem(
                    "timeentries_list_columns",
                    JSON.stringify({
                        displayedColumns: result.columObj,
                        ColumnsObj: result.columnameObj,
                    })
                );
                if (!result.columObj) {
                    this.TimerData = new MatTableDataSource([]);
                    this.TimerData.paginator = this.paginator;
                    this.TimerData.sort = this.sort;
                    this.isDisplay = true;
                } else {
                    this.LoadData(this.lastFilter);
                }
            }
        });
    }

    /**
     * This functiomn is used to common Date fun gate data value.
     */
    CommonDatefun(begin, end) {
        this.lastFilter = JSON.parse(
            localStorage.getItem("time_entries_filter")
        );
        if (this.lastFilter) {
            this.lastFilter.ITEMDATESTART = end;
            this.lastFilter.ITEMDATEEND = begin;
        } else {
            this.lastFilter = {
                FEEEARNER: "",
                INVOICED: "",
                ITEMDATESTART: end,
                ITEMDATEEND: begin,
            };
        }
        localStorage.setItem(
            "time_entries_filter",
            JSON.stringify(this.lastFilter)
        );
    }
   
    /**
     * This function is used to get the groups
     */
    getGroups(data: any[], groupByColumns: string[]): any[] {
        return this.getGroupList(data, 0, groupByColumns);
    }

    /**
     * This function is used to get the grup list.
     */
    getGroupList(
        data: any[],
        level: number = 0,
        groupByColumns: string[]
    ): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        let groups = this.uniqueBy(
            data.map((row) => {
                const result = new Group();
                result.level = level + 1;
                for (let i = 0; i <= level; i++) {
                    result[groupByColumns[i]] = row[groupByColumns[i]];
                }
                return result;
            }),
            JSON.stringify
        );
        const currentColumn = groupByColumns[level];
        let subGroups = [];
        groups.forEach((group) => {
            const rowsInGroup = data.filter(
                (row) => group[currentColumn] === row[currentColumn]
            );
            group.totalCounts = rowsInGroup.length;
            this.expandedSubCar = [];
        });
        this._allGroup = groups;
        return groups;
    }

    /**
     * This function is used to add the New group.
     */
    addGroupsNew(
        allGroup: any[],
        data: any[],
        groupByColumns: string[],
        dataRow: any
    ): any[] {
        const rootGroup = new Group();
        rootGroup.expanded = true;
        return this.getSublevelNew(
            allGroup,
            data,
            0,
            groupByColumns,
            rootGroup,
            dataRow
        );
    }

    /**
     * This function is used to set the Group
     */
    isGroup(index, item): boolean {
        return item.level;
    }

    /**
     * This function is used to get the Sublevel
     */
    getSublevelNew(
        allGroup: any[],
        data: any[],
        level: number,
        groupByColumns: string[],
        parent: Group,
        dataRow: any
    ): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        const currentColumn = groupByColumns[level];
        let subGroups = [];
        allGroup.forEach((group) => {
            const rowsInGroup = data.filter(
                (row) => group[currentColumn] === row[currentColumn]
            );
            group.totalCounts = rowsInGroup.length;
            // if (group.ITEMTYPEDESC == dataRow.ITEMTYPEDESC.toString()) {
            //   group.expanded = dataRow.expanded;
            const subGroup = this.getSublevelNew(
                allGroup,
                rowsInGroup,
                level + 1,
                groupByColumns,
                group,
                group.ITEMTYPEDESC.toString()
            );
            this.expandedSubCar = subGroup;
            subGroup.unshift(group);
            subGroups = subGroups.concat(subGroup);
            // } else {
            //   subGroups = subGroups.concat(group);
            //
            // }
        });
        return subGroups;
    }

    /**
     * This function is used to get the uniqueBy
     */
    uniqueBy(a, key) {
        const seen = {};
        return a.filter((item) => {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    /**
     * This function is used to get the Group Header click.
     */
    groupHeaderClick(row) {
        if (row.expanded) {
            row.expanded = false;
            this.editTimeEntry(null);
            this.TimerData.data = this.getGroups(
                this.allData,
                this.groupByColumns
            );
        } else {
            row.expanded = true;
            this.expandedCar = row;
            this.TimerData.data = this.addGroupsNew(
                this._allGroup,
                this.allData,
                this.groupByColumns,
                row
            );
        }
    }

    /**
     * This function is used to sort the column.
     */
    sortingCLM(sort: MatSort) {
        let data = this.allData;
        let sortSubData: any[];
        const splitDate = (date) => {
            const dateValues = date.split("/");
            const day = dateValues[0];
            const month = dateValues[1];
            const year = dateValues[2];
            // let Sd = new Date(
            //     tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
            // );
            // let newDate = new Date(Sd);
            return month + "/" + (day.length < 2 ? `0${day}`: day) + "/" + year;
            // const originalDate = moment(date, 'DD/MM/YYYY');
            // const formattedDate = originalDate.format('DD/MM/YYYY');
        }
        const splitDT = (date) => {
            if(date) {
                const momentDate = moment(moment(date, "DD/MM/YYYY hh:mm A")).format("YYYY-MM-DD HH:mm:ss");
                let timestamp = Date.parse(momentDate);
                return timestamp / 1000;
            }
        }
        const index = data.findIndex((x) => x["level"] == 1);
        if (sort.active && sort.direction !== "") {
            if (index > -1) {
                data.splice(index, 1);
            }
            data = data.sort((a: any, b: any) => {
                let propertyA: number | Date | string = "";
                let propertyB: number | Date | string = "";
                let FildeValue = this.dateColFilter;
                if (sort.active) {
                    const property = sort.active;
                    const isAsc = sort.direction === 'asc';
                    if (FildeValue.includes(sort.active)) {
                        if (
                            (a[property] && b[property]) &&
                            (sort.active == "ITEMDATEANDTIME" ||
                                sort.active == "CREATEDDATEANDTIME" ||
                                sort.active == "MODIFIEDDATEANDTIME")
                        ) {
                            const AD = splitDT(a[property]);
                            const BD = splitDT(b[property]);
                            // let momentDate = moment(
                            //     moment(item[property], "DD/MM/YYYY hh:mm A")
                            // ).format("YYYY-MM-DD HH:mm:ss");
                            // let timestamp = Date.parse(momentDate);
                            // return timestamp / 1000;
                            return (new Date(AD).valueOf() < new Date(BD).valueOf() ? -1 : 1) * (isAsc ? 1 : -1);
                        } else if ((a && b) && (property != "ITEMDATEANDTIME" && property != "CREATEDDATEANDTIME" && property != "MODIFIEDDATEANDTIME")) {
                            // else if ((a && b) && (property != "ITEMDATEANDTIME" || property != "CREATEDDATEANDTIME" || property != "MODIFIEDDATEANDTIME"))
                            // let tempDate = item[property].split("/");
                            // let Sd = new Date(
                            //     tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                            // );
                            // let newDate = new Date(Sd);
                            // return newDate;
                            const A = splitDate(a[property]);
                            const B = splitDate(b[property]);
                            return (new Date(A).valueOf() < new Date(B).valueOf() ? -1 : 1) * (isAsc ? 1 : -1);
                        }
                        return (a[property] < b[property] ? -1 : 1) * (isAsc ? 1 : -1)
                        // return item[property];
                        // let tempDate = a[sort.active].split("/");
                        // let tempDateb = b[sort.active].split("/");
                        // [propertyA, propertyB] = [
                        //     new Date(
                        //         tempDate[1] +
                        //             "/" +
                        //             tempDate[0] +
                        //             "/" +
                        //             tempDate[2]
                        //     ),
                        //     new Date(
                        //         tempDateb[1] +
                        //             "/" +
                        //             tempDateb[0] +
                        //             "/" +
                        //             tempDateb[2]
                        //     ),
                        // ];
                        // const valueA = isNaN(+propertyA)
                        //     ? propertyA
                        //     : +propertyA;
                        // const valueB = isNaN(+propertyB)
                        //     ? propertyB
                        //     : +propertyB;
                        // return (
                        //     (valueA < valueB ? -1 : 1) *
                        //     (this.sort.direction === "asc" ? 1 : -1)
                        // );
                    } else if (sort.active == "ITEMTIME") {
                        let aType: any = a[sort.active]
                            .split(/(?=[A-Z]{2})/)
                            .join(" ");
                        let bType: any = b[sort.active]
                            .split(/(?=[A-Z]{2})/)
                            .join(" ");
                        [propertyA, propertyB] = [
                            new Date("1970/01/01 " + aType),
                            new Date("1970/01/01 " + bType),
                        ];
                        const valueA = isNaN(+propertyA)
                            ? propertyA
                            : +propertyA;
                        const valueB = isNaN(+propertyB)
                            ? propertyB
                            : +propertyB;
                        return (
                            (valueA < valueB ? -1 : 1) *
                            (isAsc ? 1 : -1)
                        );
                    } else {
                        [propertyA, propertyB] = [
                            a[sort.active],
                            b[sort.active],
                        ];
                        const valueA = isNaN(+propertyA)
                            ? propertyA
                            : +propertyA;
                        const valueB = isNaN(+propertyB)
                            ? propertyB
                            : +propertyB;
                        return (a[property] < b[property] ? -1 : 1) * (isAsc ? 1 : -1);
                    }
                } else {
                    return 0;
                }
            });
            sortSubData = [...data];
            sortSubData = sortSubData.slice().sort((a: any, b: any) => {
                const property = sort.active;
                const isAsc = sort.direction === 'asc';
                if (a[property] === b[property] && sort.active === property) {
                    return (a['ITEMDATESORT'] < b['ITEMDATESORT'] ? -1 : 1) * (isAsc ? 1 : -1) // ascending order for sub-sorting
                }
                return 0;
            });
        }
        this.TimerData.data = this.addGroupsNew(
            this._allGroup,
            sortSubData,
            this.groupByColumns,
            this.expandedCar
        );
        this.TimerData.paginator = this.paginator;

    }

    /**
     * This function is used to download the file data value.
     */
    downloadFile(data?) {
        // const sortedData = this.TimerData.sortData(
        //     this.TimerData.filteredData,
        //     this.TimerData.sort
        // );
        this.TableColumnsService.exportDataIntoCsv(this.displayedColumns,this.TimerData?.filteredData,"Time_entries_data_",this.tempColobj);
    }

    
    /**
     * This function is used to makes the master toggle.
     */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.TimerData.data.forEach((row) => this.selection.select(row));
        //  this.isAllSelected() ? this.selection.clear() : this.CheckedUncheckedData.forEach((row) => this.selection.select(row));
        this.collectSelectedVal();
    }

    /**
     * This function is used to select the all data value. 
     */
    isAllSelected() {
        let numSelected: any = 0;
        let numRows: any = 0;
        if (this.selection.selected) {
            numSelected = this.selection.selected.length;
        }
        // if (this.CheckedUncheckedData) {
        //   numRows = this.CheckedUncheckedData.length;
        // }
        if (this.TimerData.data) {
            numRows = this.TimerData.data.length;
        }
        return numSelected === numRows;
    }

    /**
     * This function is used to get the check box lable
     */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
            row.position + 1
        }`;
    }

    /**
     * This function is used to collect the Selected value.
     */
    collectSelectedVal() {
        this.SelectedWIPCheckBox = [];
        if (this.selection.selected.length > 0) {
            this.selection.selected.forEach((element) => {
                element.TAGGED = 1;
                this.SelectedWIPCheckBox.push(element);
            });
        } else {
            this.SelectedWIPCheckBox = [];
        }
        localStorage.setItem(
            "SelectedTimeEntriesCheckbox",
            JSON.stringify(this.SelectedWIPCheckBox)
        );
        this.behaviorService.selectedtimeEntriesChckbox$.next(this.SelectedWIPCheckBox);
    }

    /**
     * This function is used to move to another matter data value.
     */
    moveToAnotherMatter(selectedMatterData: any) {
        if (
            JSON.parse(localStorage.getItem("SelectedTimeEntriesCheckbox")) !==
            null
        ) {
            let allSelectedData = JSON.parse(
                localStorage.getItem("SelectedTimeEntriesCheckbox")
            );
            let payLoad = {};
            let data = [];

            payLoad["MultiRecord"] = true;
            payLoad["FormAction"] = "update";
            payLoad["VALIDATEONLY"] = true;

            allSelectedData.forEach((e) => {
                data.push({
                    WorkItemGuid: e.WORKITEMGUID,
                    MATTERGUID: selectedMatterData.MATTERGUID,
                });
            });

            payLoad["RequestGuid"] = this.RequestGuid;
            payLoad["Data"] = data;
            this.Timersservice.SetWorkItems(payLoad).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
                (res) => {
                    payLoad["VALIDATEONLY"] = false;
                    this.Timersservice.SetWorkItems(payLoad).subscribe(
                        (res) => {
                            this.LoadData(this.lastFilter);

                            this.selection.clear();
                        }
                    );
                },
                (err) => {
                    this.isspiner = false;
                    this.toastr.error(err);
                }
            );

            // allSelectedData.forEach(async element => {
            //   let PostData: any = {
            //     "MATTERGUID": selectedMatterData.MATTERGUID,
            //     "ITEMDATE": element.ITEMDATE,
            //     "PRICE": Number(element.PRICE),
            //     "PRICEINCGST": Number(element.PRICEINCGST),
            //     "ADDITIONALTEXT": element.ADDITIONALTEXT,
            //     "QUANTITYTYPE": element.QUANTITYTYPE,
            //     "FEEEARNER": element.FEEEARNER,
            //     "ITEMTYPE": element.ITEMTYPE,
            //     "ITEMTIME": element.ITEMTIME,
            //     "QUANTITY": element.QUANTITY,
            //     "WorkItemGuid": element.WORKITEMGUID
            //   }

            //   let FormAction = 'update';
            //   let PostQuickTimeEntryData: any = { FormAction: FormAction, VALIDATEONLY: false, Data: PostData };
            //   await this.Timersservice.SetWorkItems(PostQuickTimeEntryData).subscribe(res => {
            //     this.LoadData(this.lastFilter);
            //     this.selection.clear();
            //   }, err => {
            //     this.isspiner = false;
            //     this.toastr.error(err);
            //   });
            // });
        }
    }

    /**
     * This function is used to dbl click.
     */
    doubleClick() {
      //  this.globalFunService.editTimeEntries();
      $("#EditTimeEntryFromWIP").click();

    }

    /**
     * This function is used to get the rate id 
     */
    reateId(event) {
        this.calculateData.rateid = this.qf.RATEID.value.toString();
        this.matterChange("RateID", event);
    }

    /**
     * This function is used to remove the matter chip
     */
    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.Deliverable = [];
        }

        this.quickTimeEntriesForm.controls["MATTERDELIVERABLEGUID"].setValue(
            ""
        );
        let sortname = fruit.split("--")[0];
        this.rm(sortname);
    }

    /**
     * This function is used to remove the matter data value
     */
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (
                element.SHORTNAME.toString() ==
                sortname.replace(/^\s+|\s+$/gm, "")
            ) {
                this.selectedData.splice(index, 1);
            }
        });
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * This function is used to select the MatterForm Search
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            Action: "GetData",
            Filters: { FastSearch: "True", SearchString: event },
        };
        return this._mainAPiServiceService.getSetData(searchobj, "matter")
    }

    /**
     * This function is used to add the matter data value
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (
                        this.mattername.indexOf(
                            value.replace(/^\s+|\s+$/gm, "")
                        ) == -1
                    ) {
                        this.mattername = [value];
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }
        //  event.chipInput!.clear();

        this.quickTimeEntriesForm.controls["matterautoVal"].setValue(null);
    }

    /**
     * This function is used to select the value
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        // const array3 = this.mattername;
        // if (array3.length <1) {
        if (
            this.mattername.indexOf(
                event.option.viewValue.replace(/^\s+|\s+$/gm, "")
            ) == -1
        ) {
            // this.mattername.push(event.option.viewValue);
            this.mattername = [event.option.viewValue];
            this.MatterInput.nativeElement.value = "";
            this.quickTimeEntriesForm.controls["matterautoVal"].setValue(null);
        }
        // }
    }

    /**
     * This function is used to select the matter data.
     */
    selectedmatterguid(data: any) {
        this.quickTimeEntriesForm.controls["MATTERGUID"].setValue(
            data.MATTERGUID
        );
        this.matterChange("MatterGuid", data.MATTERGUID);
        this.getDeliverabledata(data.MATTERGUID);
        // const array3 = this.selectedData;
        // if (array3.length < 1) {
        if (
            this.selectedData.map((ele, index) =>
                ele.MATTERGUID == data.MATTERGUID ? index : -1
            )[0] == -1 ||
            this.selectedData.length == 0
        ) {
            this.selectedData = [data];
        }
        this.isLoading_another = false;
        // }
    }

    /**
     * This function is used to show the data value.
     */
    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element;
                this.mattername = [data.SHORTNAME + "--" + data.MATTER];
                this.quickTimeEntriesForm.controls["matterautoVal"].setValue(
                    null
                );

                if (event.input) {
                    event.input.value = "";
                } else {
                    this.MatterInput.nativeElement.value = "";
                }

                this.quickTimeEntriesForm.controls["MATTERGUID"].setValue(
                    data.MATTERGUID
                );
                this.matterChange("MatterGuid", data.MATTERGUID);
                this.getDeliverabledata(data.MATTERGUID);
                // const array3 = this.selectedData;
                // if (array3.length < 1) {
                if (
                    this.selectedData.map((ele, index) =>
                        ele.MATTERGUID == data.MATTERGUID ? index : -1
                    )[0] == -1 ||
                    this.selectedData.length == 0
                ) {
                    this.selectedData = [data];
                }
                this.isLoading_another = false;
            }
        });
    }

    /**
     * This function is used to Download the all Data value.
     */
    DownloadAllData() {
        // const requestdata={
        //   FEEEARNER: "all",
        //   GETALLDATA: true,
        //   INVOICED: "",
        //   ITEMDATEEND: "",
        //   ITEMDATESTART: "",
        //   ITEMTYPE: "WipAndActivity"
        // }

        const requestdata = {
            GETALLDATA: true,
        };

        this.isGetallData = true;
        this.isCallDownloadFunction = true;
        this.LoadData(requestdata);
    }

    radioChange(event) {}

    /****************** FOR FILTER ******************/

    // for filter

    /**
     * for prevent default
     * @param event
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Set Value for filter
     * @param activefilter
     * @param filterBy
     */
    Filterconfig(activefilter, filterBy) {
        if (filterBy == "uninvoiced_work") {
            this.activeFilters.uninvoicedWork = activefilter.value;
            // this.activeFilters.uninvoicedWorkDesc = activefilter.source.triggerValue;
            this.activeFilters.uninvoicedWorkDesc = activefilter.description;
        } else if (filterBy == "fee_earner") {
            this.activeFilters.FEEEARNER = activefilter.USERID;
            // this.activeFilters.feeEarnerDesc = activefilter.source.triggerValue;
            if (activefilter.description) {
                this.activeFilters.feeEarnerDesc = activefilter.description;
            } else {
                this.activeFilters.feeEarnerDesc = activefilter.USERID;
            }
        } else if (filterBy == "day_range") {
            this.activeFilters.dayRange = activefilter.value;

            const beginDate = this.datepipe.transform(new Date(), "dd/MM/yyyy");
            const endDate = this.datepipe.transform(new Date(), "dd/MM/yyyy");
            this.activeFilters.DateRange = `${beginDate} - ${endDate}`;
            this.activeFilters.DateRangeDesc = `${beginDate} - ${endDate}`;

            this.TimeEnrtyForm.controls["date"].setValue({
                begin: new Date(),
                end: new Date(),
            });

            // this.activeFilters.dayRangeDesc = activefilter.source.triggerValue;
            this.activeFilters.dayRangeDesc = activefilter.value;
        } else if (filterBy == "date_range") {
            const beginDate = this.datepipe.transform(
                activefilter.value["begin"],
                "dd/MM/yyyy"
            );
            const endDate = this.datepipe.transform(
                activefilter.value["end"],
                "dd/MM/yyyy"
            );
            this.activeFilters.DateRange = `${beginDate} - ${endDate}`;
            this.activeFilters.DateRangeDesc = `${beginDate} - ${endDate}`;
        }else if(filterBy == "type"){
            //this.activeFilters.type = activefilter.description;
            this.activeFilters.typeDescription=activefilter.description;
            this.activeFilters.type = activefilter.value;
        }

        localStorage.setItem(
            "timeEntriesFilter_config",
            JSON.stringify(this.activeFilters)
        );
    }

    /**
     * filter for uninvoiced work
     * @param value
     */
    UninvoicedWorkFilter(value) {
        let filterVal = !localStorage.getItem("timeEntriesFilter_config")
            ? {
                  FEEEARNER: "",
                  INVOICED: value,
                  ITEMDATESTART: "",
                  ITEMDATEEND: "",
              }
            : JSON.parse(localStorage.getItem("timeEntriesFilter_config"));
        if (!localStorage.getItem("timeEntriesFilter_config")) {
            localStorage.setItem(
                "timeEntriesFilter_config",
                JSON.stringify(filterVal)
            );
        } else {
            filterVal.INVOICED = value;
            localStorage.setItem(
                "timeEntriesFilter_config",
                JSON.stringify(filterVal)
            );
        }

        this.LoadDataMod(filterVal);
    }

    /**
     * filter for fee earner
     * @param value
     */
    FeeEarnerFilter(value) {
        let filterVal = !localStorage.getItem("timeEntriesFilter_config")
            ? {
                  FEEEARNER: value,
                  INVOICED: "",
                  ITEMDATESTART: "",
                  ITEMDATEEND: "",
              }
            : JSON.parse(localStorage.getItem("timeEntriesFilter_config"));
        if (!localStorage.getItem("timeEntriesFilter_config")) {
            localStorage.setItem(
                "timeEntriesFilter_config",
                JSON.stringify(filterVal)
            );
        } else {
            filterVal.FEEEARNER = value;
            localStorage.setItem(
                "timeEntriesFilter_config",
                JSON.stringify(filterVal)
            );
        }
        this.LoadDataMod(filterVal);
    }

    /**
     * This function is used to get the comman date function data value.
     */
    CommonDatefunMod(begin, end) {
        this.activeFilters = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        if (this.activeFilters) {
            this.activeFilters.ITEMDATESTART = end;
            this.activeFilters.ITEMDATEEND = begin;
        } else {
            this.activeFilters = {
                FEEEARNER: "",
                INVOICED: "",
                ITEMDATESTART: end,
                ITEMDATEEND: begin,
            };
        }
        localStorage.setItem(
            "time_entries_filter",
            JSON.stringify(this.activeFilters)
        );
    }

    /**
     * filter for day range
     * @param val
     * @returns
     */
    SelectDayRangeFilter(val) {
        this.activeFilters = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        if (this.activeFilters == null || this.activeFilters == undefined) {
            this.activeFilters = {};
        }
        this.activeFilters.dayRange = val;

        let currentDate = new Date();
        let updatecurrentDate = new Date();
        let begin = this.datepipe.transform(currentDate, "dd/MM/yyyy");
        if (val == "Last 7 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 7);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Today") {
            // updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: currentDate,
                end: currentDate,
            });
        } else if (val == "Yesterday") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 1);
            begin = this.datepipe.transform(updatecurrentDate, "dd/MM/yyyy");
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: updatecurrentDate,
            });
        } else if (val == "Last 30 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Last 90 days") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Date Range") {
            this.forHideShowDateRangePicker = true;
            return;
        } else if (val == "Last 6 months") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 180);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Last 12 months") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 365);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "Last 2 years") {
            updatecurrentDate.setDate(updatecurrentDate.getDate() - 730);
            this.forHideShowDateRangePicker = false;
            this.TimeEnrtyForm.controls["date"].setValue({
                begin: updatecurrentDate,
                end: currentDate,
            });
        } else if (val == "All time") {
            this.forHideShowDateRangePicker = false;
            this.lastFilter = JSON.parse(
                localStorage.getItem("matter_invoice_filter")
            );
            this.activeFilters.ITEMDATESTART = "";
            this.activeFilters.ITEMDATEEND = "";
        }

        if (val != "All time") {
            let end = this.datepipe.transform(updatecurrentDate, "dd/MM/yyyy");
            this.CommonDatefunMod(begin, end);
        }

        localStorage.setItem(
            "timeEntriesFilter_config",
            JSON.stringify(this.activeFilters)
        );
        this.LoadDataMod(this.activeFilters);
    }

    /**
     * select date range
     * @param type
     * @param value
     */
    DateRangeFilter(type: string, value: string) {
        const splitDateRange = value.split(" - ");
        const startDate = splitDateRange[0];
        const endDate = splitDateRange[1];
        this.activeFilters.ITEMDATESTART = startDate;
        this.activeFilters.ITEMDATEEND = endDate;
        // this.CommonDatefunMod(endDate,startDate);
        localStorage.setItem(
            "timeEntriesFilter_config",
            JSON.stringify(this.activeFilters)
        );
        let filterData = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        this.LoadDataMod(filterData);
    }

    /**
     * function for clear filter
     */
    clearFilter(name, filterBy) {
        delete this.activeFilters[name];
        let resetFilter = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        this.clearFilters = true;
        if (filterBy == "uninvoiced_work") {
            this.TimeEnrtyForm.controls["uninvoicedWork"].setValue("");
            resetFilter.UninvoicedWork = "";
            this.activeFilters.UninvoicedWork = "";
            this.activeFilters.uninvoicedWorkDesc = "All Work";
            this.UninvoicedWorkFilter("");
        } else if (filterBy == "fee_earner") {
            this.TimeEnrtyForm.controls["dlpdrop"].setValue("");
            resetFilter.FEEEARNER = "";
            this.activeFilters.FEEEARNER = "";
            resetFilter.feeEarnerDesc = "None";
            this.activeFilters.feeEarnerDesc = "None";
            this.FeeEarnerFilter("");
        } else if (filterBy == "SearchString") {
            this.TimeEnrtyForm.controls["searchFilter"].setValue("");
            resetFilter.SearchString = "";
            this.activeFilters.SearchString = "";
        } else if (filterBy == "day_range") {
            this.TimeEnrtyForm.controls["DayRange"].setValue("Today");
            resetFilter.DayRange = "";
            this.TimeEnrtyForm.controls["date"].setValue("");
            resetFilter.DateRange = "";
            this.forHideShowDateRangePicker = false;

            this.activeFilters.dayRange = "Today";
            this.activeFilters.dayRangeDesc = "Today";
            this.SelectDayRangeFilter("Today");

            this.CommonDatefun("", "");
            let filterData = JSON.parse(
                localStorage.getItem("timeEntriesFilter_config")
            );
            this.SelectDayRangeFilter("Today");
        } else if (filterBy == "date_range") {
            this.CommonDatefun("", "");
            this.TimeEnrtyForm.controls["date"].setValue("");
            let filterData = JSON.parse(
                localStorage.getItem("timeEntriesFilter_config")
            );
            this.LoadDataMod(filterData);
        }else if (filterBy == "type") {
            this.TimeEnrtyForm.controls["type"].setValue("WipAndActivity");
            let filterData = JSON.parse(
                localStorage.getItem("timeEntriesFilter_config")
            );
            this.activeFilters.type = "Time + Fixed";
            this.LoadDataMod(filterData);
        }

        localStorage.setItem(
            "timeEntriesFilter_config",
            JSON.stringify(this.activeFilters)
        );
    }

    /**
     * This function is used to get the type
     */
    getType(data){
        this.activeFilters.type = data.value;
        let filterData = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        if(filterData){
            filterData.type=data.value;
            this.LoadDataMod(filterData);
        }
    }

    /**
     * reset or clear filter
     */
    resetFilter() {
        // reset FILTERAPPLY value
        this.optionalFilterList.forEach((filter, index) => {
            filter.FILTERAPPLY = false;
        });
        // ends here ~ reset FILTERAPPLY value

        let resetFilter = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        this.TimeEnrtyForm.controls["uninvoicedWork"].setValue("");
        this.TimeEnrtyForm.controls["DayRange"].setValue("Today");
        this.TimeEnrtyForm.controls["date"].setValue("");
        this.TimeEnrtyForm.controls["dlpdrop"].setValue("");
        this.TimeEnrtyForm.controls["type"].setValue("WipAndActivity");

        this.forHideShowDateRangePicker = false;

        this.saveAdvanceFilter = [];
        localStorage.removeItem("TimeEntries_Advance_Filter");

        this.optionalFilters = [];
        this.optionalDateFilterPayload = [];
        if (this.FILTERS) {
            this.FILTERS.reset();
        }

        // setTimeout(() => {
        if (JSON.parse(localStorage.getItem("timeEntriesFilter_config"))) {
            this.activeFilters = JSON.parse(
                localStorage.getItem("timeEntriesFilter_config")
            );

            resetFilter.UninvoicedWork = "";
            resetFilter.FEEEARNER = "";
            resetFilter.feeEarnerDesc = "None";
            resetFilter.DayRange = "Today";
            resetFilter.DateRange = "";
            resetFilter.DateRangeDesc = "";
            resetFilter.type = "WipAndActivity";
            resetFilter.typeDescription = "Time + Fixed";

            delete resetFilter.FILTERS;
            delete resetFilter.AdvancedFilters;
            localStorage.setItem(
                "timeEntriesFilter_config",
                JSON.stringify(resetFilter)
            );

            setTimeout(() => {
                // this.detectedChangeRef = Math.random()
            }, 500);
        }

        // this.activeFilters.feeEarner = this.f.dlpdrop.value;
        this.activeFilters.FEEEARNER = this.f.dlpdrop.value;
        this.activeFilters.feeEarnerDesc =
            this.f.dlpdrop.value == "" ? "None" : this.f.dlpdrop.value;
        this.activeFilters.uninvoicedWork = this.f.uninvoicedWork.value;
        this.activeFilters.INVOICED = this.f.uninvoicedWork.value;
        this.activeFilters.uninvoicedWorkDesc = "All Work";
        this.activeFilters.dayRange = this.f.DayRange.value;
        this.activeFilters.DayRange = this.f.DayRange.value;
        this.activeFilters.dayRangeDesc = this.f.DayRange.value;
        this.activeFilters.DateRange = this.f.date.value;
        this.activeFilters.type = this.f.type.value;
        localStorage.setItem(
            "timeEntriesFilter_config",
            JSON.stringify(this.activeFilters)
        );

        this.activeFilters.ITEMDATESTART = this.datepipe.transform(
            new Date(),
            "dd/MM/yyyy"
        );
        this.activeFilters.ITEMDATEEND = this.datepipe.transform(
            new Date(),
            "dd/MM/yyyy"
        );
        this.LoadDataMod(this.activeFilters);

        // }, 500);
    }

    /**
     * set default filter
     */
    defaultSetFilter() {
        // debugger;
        if (JSON.parse(localStorage.getItem("timeEntriesFilter_config"))) {
            this.activeFilters = JSON.parse(
                localStorage.getItem("timeEntriesFilter_config")
            );
            /** ENDS HERE ~ BELOW CODE IS WORKING FOR DATE RANGE ~ PLEASE DO NOT DELETE IT */
            if (this.activeFilters.dayRange == "Date Range") {
                this.forHideShowDateRangePicker = false;
                this.activeFilters.dayRange = "Today";
                this.activeFilters.dayRangeDesc = "Today";
            }
            this.TimeEnrtyForm.controls["dlpdrop"].setValue(
                this.activeFilters.FEEEARNER
            );
            this.TimeEnrtyForm.controls["uninvoicedWork"].setValue(
                this.activeFilters.uninvoicedWork
            );
            this.TimeEnrtyForm.controls["DayRange"].setValue(
                this.activeFilters.dayRange
            );
            this.activeFilters.typeDescription=this.activeFilters.typeDescription
            this.TimeEnrtyForm.controls["type"].setValue(
                this.activeFilters.type
            );
            localStorage.setItem("timeEntriesFilter_config",JSON.stringify(this.activeFilters));
        } else {
            this.activeFilters = {};
            this.activeFilters.FEEEARNER = this.f.dlpdrop.value;
            this.activeFilters.feeEarnerDesc = this.f.dlpdrop.value;
            this.activeFilters.uninvoicedWork = this.f.uninvoicedWork.value;
            this.activeFilters.dayRange = "Today";
            this.activeFilters.dayRangeDesc = "Today";
            this.activeFilters.type = "WipAndActivity";
            this.activeFilters.typeDescription = "Time + Fixed";
            this.TimeEnrtyForm.controls["DayRange"].setValue(
                this.activeFilters.dayRange
            );
            this.activeFilters.DateRange = this.f.date.value;
            localStorage.setItem(
                "timeEntriesFilter_config",
                JSON.stringify(this.activeFilters)
            );
        }

        if (this.activeFilters.dayRange == "Date Range") {
            this.forHideShowDateRangePicker = true;

            if (this.activeFilters.DateRange) {
                let splitDate = this.activeFilters.DateRange.split(" - ");
                if (splitDate[0]) {
                    this.activeFilters.ITEMDATESTART = splitDate[0];
                    this.activeFilters.ITEMDATEEND = splitDate[1];
                }
            }

            this.LoadDataMod(this.activeFilters);
        } else {
            this.SelectDayRangeFilter(this.activeFilters.dayRange);
        }
    }

    /**
     * This function is used to onsearch change the data value.
     */
    onSearchChange(searchFilter: any) {
        if (
            searchFilter["key"] === "Enter" ||
            searchFilter == "Enter" ||
            searchFilter == "removeSearchString"
        ) {
            this.onFilterSearch("Enter");
        }
        this.setFocus("");
    }

    /**
     * This function is used to set the focus
     */
    setFocus(fieldRefVar) {
        this.searchField.nativeElement.focus();
    }

    /**
     * This function is used to onFilter search data. 
     */
    onFilterSearch(searchFilter?: any) {
        if (
            this.TimeEnrtyForm.value["searchFilter"] ||
            this.TimeEnrtyForm.value["searchFilter"] == ""
        ) {
            //able to remove search filter
            if (searchFilter == "removeSearchString") {
                this.TimeEnrtyForm.controls["searchFilter"].setValue("");
            }
            let filterVal = {
                FEEEARNER: "",
                INVOICED: "",
                SearchString: this.TimeEnrtyForm.value["searchFilter"],
                ITEMDATESTART: "",
                ITEMDATEEND: "",
            };
            if (!localStorage.getItem("timeEntriesFilter_config")) {
                localStorage.setItem(
                    "timeEntriesFilter_config",
                    JSON.stringify(filterVal)
                );
            } else {
                filterVal = JSON.parse(
                    localStorage.getItem("timeEntriesFilter_config")
                );
                filterVal.SearchString = this.f.searchFilter.value;
                localStorage.setItem(
                    "timeEntriesFilter_config",
                    JSON.stringify(filterVal)
                );
            }
            this.hasSearchVal = filterVal.SearchString ? true : false;
            this.LoadDataMod(filterVal);
        }
    }

    /**
     * get filter
     */
    getFilter() {
        let opFilterList = JSON.parse(
            localStorage.getItem("timeentries_list_columns")
        );
        this.TableColumnsService.getDynamicFilter(
            opFilterList,
            this.optionalFilterList,
            "timeentries_list_columns"
        );
        this.refreshFilterList();
        this.defaultSetFilter();

        setTimeout(() => {}, 500);
    }

    /**
     * This function is used to refresh the filter list
     */
    refreshFilterList() {
        //Advance Filter Refresh
        const advDynamicFilter = JSON.parse(
            localStorage.getItem("TimeEntries_Advance_Filter")
        );
        this.optionalFilterList.map((x) => {
            if (x.FILTERTYPE != "HIDDEN") {
                this.totalApplicableFilterCount += 1;
            }

            if (
                advDynamicFilter &&
                advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
                    -1
            ) {
                x.FILTERAPPLY = true;
            }
        });
    }

    /**
     * dynamic form group
     * @param type
     * @returns dynamic form group
     */
    createOPFilter(type): FormGroup {
        return this.fb.group({
            OPERATION:
                type == "DATE"
                    ? "is in the last"
                    : type == "CURRENCY" || type == "NUMBER"
                    ? "is equal to"
                    : "contains",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: type,
        });
    }

    /**
     * add dynamic filter
     * @param event
     */
    addDynamicFilter(event, type?) {
        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        event.FILTERAPPLY = true; //for filter hide/show from optional filter list.

        if (!type) {
            this.optionalFilters.push(event);
        }

        // OPTIONAL FILTER
        this.FILTERS = this.TimeEnrtyForm.get("FILTERS") as FormArray;
        this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));

        setTimeout(() => {
            // this.detectedChangeRef = Math.random()
        }, 500);
    }

    /**
     * apply optional filter and pass payload to "doFilter" method to load data
     * @param addData
     * @param isIndex
     */
    applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any) {
        // new code for validation
        let saveAdvancefilterData = JSON.parse(
            localStorage.getItem("TimeEntries_Advance_Filter")
        );
        const field1Val =
            this.TimeEnrtyForm.controls.FILTERS["controls"][isIndex].controls[
                "VALUE"
            ].value;
        const field2Val =
            this.TimeEnrtyForm.controls.FILTERS["controls"][isIndex].controls[
                "VALUE2"
            ].value;
        const operationVal =
            this.TimeEnrtyForm.controls.FILTERS["controls"][isIndex].controls[
                "OPERATION"
            ].value;
        const filterTypeVal =
            this.TimeEnrtyForm.controls.FILTERS["controls"][isIndex].controls[
                "FILTERTYPE"
            ].value;

        if (
            !this.TableColumnsService.checkValidation(
                field1Val,
                field2Val,
                operationVal,
                filterTypeVal
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }

        // ends here ~ new code for validation

        //addData.selectedFilter = OpretionIs.triggerValue;
        let addData = JSON.parse(JSON.stringify(FilterData));
        addData.selectedFilter = OpretionIs.triggerValue;

        const applyFilterData = this.TableColumnsService.Optionalfilter(
            addData,
            isIndex,
            this.optionalDateFilterPayload,
            this.TimeEnrtyForm
        );
        this.optionalDateFilterPayload = applyFilterData;

        // Advance Dynamic filter when it's change any data.
        this.TableColumnsService.setAdvanceFilter(
            saveAdvancefilterData,
            this.saveAdvanceFilter,
            isIndex,
            FilterData,
            field1Val,
            field2Val,
            operationVal,
            filterTypeVal,
            applyFilterData,
            "TimeEntries"
        );
        this.doFilter(this.optionalDateFilterPayload);

        setTimeout(() => {
            // this.detectedChangeRef = Math.random()
        }, 500);
    }

    /**
     * apply optional filter and load data from API
     * @param mainFilter
     */
    doFilter(mainFilter: any) {
        const payloadData = JSON.parse(JSON.stringify(mainFilter));
        const finalPayload = payloadData
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    delete e["value"].COLUMNID;
                    return e["value"];
                }
            });

        const doFilter = JSON.parse(JSON.stringify(this.activeFilters));

        // doFilter.FILTERS = finalPayload;
        doFilter.AdvancedFilters = finalPayload;
        delete doFilter.FILTERS;
        this.LoadDataMod(doFilter);
    }

    /**
     * reset optional filter
     */

    resetOptionalFilter(event, type, isindex) {
        let index = this.optionalFilters.findIndex(
            (e) => e.COLUMNID == event.COLUMNID
        );

        event.FILTERAPPLY = false; //for filter hide/show from optional filter list.
        setTimeout(() => {
            this.optionalFilterList.map((x) => {
                if (
                    this.optionalFilters.findIndex(
                        (e) => x.COLUMNID == event.COLUMNID
                    ) !== -1
                ) {
                    x.FILTERAPPLY = false;
                }
            });

            this.optionalFilterList = this.optionalFilterList;
        }, 100);

        this.optionalFilters.splice(index, 1);
        this.optionalDateFilterPayload.splice(isindex, 1);
        this.FILTERS.controls.splice(isindex, 1);
        this.TimeEnrtyForm.value.FILTERS.splice(isindex, 1);

        //Delete Filters
        const resetFilter = JSON.parse(
            localStorage.getItem("timeEntriesFilter_config")
        );
        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.TimeEnrtyForm.value.FILTERS)
        );

        //Advance Filter.
        this.saveAdvanceFilter.splice(isindex, 1);
        this.TableColumnsService.refrshAdvanceFilter(event, "TimeEntries");

        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });

        const finalPayload = ArrayResetOptional.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                // e.COLUMNNAME = event.COLUMNNAME;
                return e;
            }
        });

        resetFilter.AdvancedFilters = finalPayload;
        // resetFilter.FILTERS = finalPayload;
        delete resetFilter.FILTERS;

        if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
            delete resetFilter.AdvancedFilters;
        }

        this.LoadDataMod(resetFilter);

        setTimeout(() => {
            // this.detectedChangeRef = Math.random()
        }, 500);
    }

    /**
     * change date time option on optional filter
     * @param event
     * @param filterIs
     */
    optionalDateFilter(event, filterIs, val, index) {
        filterIs.selectedFilter = event.value;

        if (index != -1) {
            this.TimeEnrtyForm.controls.FILTERS["controls"][index].controls[
                "VALUE"
            ].reset();
            this.TimeEnrtyForm.controls.FILTERS["controls"][index].controls[
                "VALUE2"
            ].reset();
        }

        this.addFilter["VALUE"] = "";
        this.addFilter["VALUE2"] = "";
    }

    /**
     * move to back screen on click on "Add Filter" Option or Click on Back Arrow in "Add Filter" Option
     * @param id
     */
    backToFilter(id) {
        $("#" + id).removeClass("active");
        setTimeout(() => {
            $(".mat-menu-content").removeClass("active");
            $(".mat-menu-content").removeClass("mat-menu-content_height");
        }, 50);
    }

    /**
     * This function is used to Load the Mod data value
     */
    LoadDataMod(Data: any) {
        if (this.ActiveTab == "quick-time-entries") {
            Data.FEEEARNER = this.qf.FEEEARNER.value;

            if (this.activeFilters.dayRange != "All time") {
                Data.ITEMDATEEND = this.qf.sendITEMDATE.value;
                Data.ITEMDATESTART = this.qf.sendITEMDATE.value;
            }

            let lastFilter = JSON.parse(
                localStorage.getItem("timeEntriesFilter_config")
            );
            this.SortingbehaviorService.SettimeEntryFilterData(lastFilter);
        } else {
            this.SortingbehaviorService.SettimeEntryFilterData(Data);
        }
        this.SortingbehaviorService.timeEntryScreenSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (result) => {
                if (result) {
                    this.sortingMatterState = result;
                    localStorage.setItem(
                        "time_entry_screen_sorting",
                        JSON.stringify(result)
                    );
                } else {
                    this.sortingMatterState = JSON.parse(
                        localStorage.getItem("time_entry_screen_sorting")
                    );
                }
            }
        );

        //Data.ITEMTYPE = "WipAndActivity";
        this.TimerData = [];
        this.isLoadingResults = true;
        const modifiedData = JSON.parse(JSON.stringify(Data));

        if (this.activeFilters.dayRange != "All time") {
            if (!modifiedData.ITEMDATEEND) {
                const dayRangeDate = this.TimeEnrtyForm.value["dayRange"];
                if (dayRangeDate && dayRangeDate != "") {
                    this.SelectDayRangeFilter(dayRangeDate);
                } else {
                    this.SelectDayRangeFilter("Today");
                }
                return false;
            }
        } else {
            modifiedData.ITEMDATESTART = "";
            modifiedData.ITEMDATEEND = "";
        }

        delete modifiedData.dayRange;
        delete modifiedData.dayRangeDesc;
        delete modifiedData.feeEarnerDesc;
       // delete modifiedData.uninvoicedWork;
        delete modifiedData.uninvoicedWorkDesc;
        delete modifiedData.DateRange;
        delete modifiedData.DateRangeDesc;
        delete modifiedData.DayRange;
        delete modifiedData.typeDescription;
        modifiedData.AdvancedFilters = [];
        this.optionalDateFilterPayload.forEach((data) => {
            modifiedData.AdvancedFilters.push(data["value"]);
        });

        //Advance Filter Payload Logic.
        const advanceFilterPayload =
            this.TableColumnsService.loadAdvanceFilterPayload("TimeEntries");
        modifiedData.AdvancedFilters =
            advanceFilterPayload && advanceFilterPayload.length !== 0
                ? advanceFilterPayload
                : [];

            this.GloableadvanceFilterPayload=advanceFilterPayload;
        if (modifiedData.AdvancedFilters.length == 0) {
            delete modifiedData.AdvancedFilters;
            localStorage.removeItem("TimeEntries_Advance_Filter");
        }
        if (!modifiedData.FEEEARNER) {
            modifiedData.FEEEARNER = "";
        }
        if (!modifiedData.INVOICED) {
            modifiedData.INVOICED = "";
        }

        delete modifiedData.FILTERS;
        if(modifiedData){

            let TYPE=modifiedData.type;
            modifiedData.ITEMTYPE=(TYPE && TYPE !=='') ? TYPE : 'ALL';
            delete modifiedData.type;
        }
        modifiedData.INVOICED=modifiedData.uninvoicedWork;
        this.gloableAdvancedFilters=modifiedData;
        this.Timersservice.getTimeEnrtyData(modifiedData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(
            (response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.quickTimeEntrySummary = response.DATA;
                    this.behaviorService.MainTimeEntryData(null);
                    this.TimeEnrtyForm.controls["ExGST"].setValue(
                        response.DATA.EXGSTTOTAL
                    );
                    this.TimeEnrtyForm.controls["IncGST"].setValue(
                        response.DATA.INCGSTTOTAL
                    );
                    this.QuantityLable =
                        this.currentLoginData.DefaultQuantityType == "U"
                            ? "Units"
                            : "Hours";
                    let UnitsTotal =
                        this.currentLoginData.DefaultQuantityType == "U"
                            ? response.DATA.UNITSTOTAL
                            : response.DATA.HOURSTOTAL;
                    this.TimeEnrtyForm.controls["Units"].setValue(UnitsTotal);
                    this.TimeEnrtyForm.controls["Days"].setValue(
                        response.DATA.DAYSTOTAL
                    );
                    this.TimeEnrtyForm.controls["Budget"].setValue(
                        response.DATA.BUDGETTOTAL
                    );
                    let tempData = [];
                    //this.CheckedUncheckedData = [];
                    response.DATA.WORKITEMS.forEach((group) => {
                        if (!group.ISGROUPBY) {
                            tempData.push(group);
                            if (!group.INVOICEGUID) {
                                // this.CheckedUncheckedData.push(group);
                            }
                        } else {
                            this.tempTotalData[group.ITEMTYPEDESC] = group;
                        }
                    });
                    this.allData = tempData;
                    this.TimerData = new MatTableDataSource();
                    this.TimerData.paginator = this.paginator;
                    this.TimerData.data = this.getGroups(
                        this.allData,
                        this.groupByColumns
                    );
                    if (this.TimerData.data[0]) {
                        this.groupHeaderClick(this.TimerData.data[0]);
                        this.isDisplay = false;
                        // this.editTimeEntry(this.TimerData.data[1]);
                        this.highlightedRows =
                            this.lastUpdatedTimeEntry &&
                            this.lastUpdatedTimeEntry != undefined &&
                            this.lastUpdatedTimeEntry != null
                                ? this.lastUpdatedTimeEntry
                                : this.TimerData.data[1].WORKITEMGUID;
                        let shortingData: any = {
                            active: this.sortactive,
                            direction: this.sortDirection,
                        };
                        this.sortingCLM(shortingData);
                    } else {
                        this.editTimeEntry(null);
                        this.isDisplay = true;
                    }
                    this.isGetallData = response.DATA.TOOMANYRECORDS;
                    // this.sortingDate();
                    Data.GETALLDATA = this.isGetallData;
                    this.SortingbehaviorService.SettimeEntryFilterData(Data);

                    this.setDefaultWidth(this.displayedColumns, 500);
                    if (this.isCallDownloadFunction == true) {
                        setTimeout(() => {
                            this.downloadFile();
                            this.isCallDownloadFunction = false;
                        }, 1000);
                    }
                }
                this.isLoadingResults = false;
            },
            (err) => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            }
        );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    /**
     * This function is used to Activate the current filter
     */
    activeCurrentFilter(id, data?: any) {
        $("#" + id).addClass("active");
        setTimeout(() => {
            $(".mat-menu-content").addClass("active");
            $(".mat-menu-content").addClass("mat-menu-content_height");
        }, 200);

        // this.filterForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
        this.activeOptionalFilter = data;
        this.TimeEnrtyForm.controls["MAINFILTER"].reset();
    }

    /**
     * This unction is used to set the date filter config value
     */
    dateFilterconfig(event) {
        this.optionalFilterAppply = false;
    }

    // New Filter changes code.
    @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;
    addNewFilter(event) {
        setTimeout(() => {
            this.operationFilter.options.first.select();
        }, 200);

        this.addFilter.VALUE = "";
        this.addFilter.VALUE2 = "";
        this.cd.detectChanges();
        if (this.FILTERSLIST) {
            this.FILTERSLIST.controls.splice(0, 1);
        }

        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }

        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }

        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }

        this.addFilter.FILTERTYPE = event.FILTERTYPE;

        // OPTIONAL FILTER
        this.FILTERSLIST = this.TimeEnrtyForm.get("FILTERSLIST") as FormArray;
        this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

        this.addFilter.OPERATION =
            event.FILTERTYPE == "DATE"
                ? "is in the last"
                : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
                ? "is equal to"
                : "contains";
        this._filterList = [event];
        this.cd.detectChanges();
    }

    /**
     * This function is used to add the filter 
     */
    async addfilter(IsfilterData, oprationalFilter, evt) {
        if (
            !this.TableColumnsService.checkValidation(
                this.addFilter.VALUE,
                this.addFilter.VALUE2,
                this.addFilter.OPERATION,
                IsfilterData.FILTERTYPE
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
        await this.addDynamicFilter(IsfilterData);

        let sIndex = this.optionalFilters.length - 1;
        this.cd.detectChanges();
        setTimeout(() => {
            this.TimeEnrtyForm.controls.FILTERS["controls"][sIndex].controls[
                "OPERATION"
            ].setValue(this.addFilter.OPERATION);
            this.TimeEnrtyForm.controls.FILTERS["controls"][sIndex].controls[
                "VALUE"
            ].setValue(this.addFilter.VALUE);
            this.TimeEnrtyForm.controls.FILTERS["controls"][sIndex].controls[
                "VALUE2"
            ].setValue(this.addFilter.VALUE2);
            this.TimeEnrtyForm.controls.FILTERS["controls"][sIndex].controls[
                "FILTERTYPE"
            ].setValue(IsfilterData.FILTERTYPE);
        }, 500);
        this.cd.detectChanges();
        this.TimeEnrtyForm.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
        this.TimeEnrtyForm.value.FILTERS[sIndex].VALUE2 = this.addFilter.VALUE2;
        this.TimeEnrtyForm.value.FILTERS[sIndex].OPERATION =
            this.addFilter.OPERATION;
        this.TimeEnrtyForm.value.FILTERS[sIndex].FILTERTYPE =
            IsfilterData.FILTERTYPE;

        // Advance Filter.
        IsfilterData.AdvancedFilter = this.TimeEnrtyForm.value.FILTERS[sIndex];
        let advFilters = JSON.parse(
            localStorage.getItem("TimeEntries_Advance_Filter")
        );
        if (advFilters) {
            this.saveAdvanceFilter = advFilters;
        }

        this.saveAdvanceFilter.push(IsfilterData);
        localStorage.setItem(
            "TimeEntries_Advance_Filter",
            JSON.stringify(this.saveAdvanceFilter)
        );

        if (this.FILTERS.length == 1) {
            this.TimeEnrtyForm.patchValue({
                FILTERS: [
                    {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    },
                ],
            });
        } else {
            this.FILTERS.value.forEach((filterData, index) => {
                if (sIndex == index) {
                    filterData = {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    };
                }
            });

            this.TimeEnrtyForm.patchValue({
                FILTERS: this.FILTERS.value,
            });
        }

        //After getting data appy filter.
        this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

        $(".example-containerdata").css(
            "height",
            $(window).height() -
                ($("#filter-form-menu-wrap").height() + 275) +
                "px"
        );
    }

    /**
     * This function is used to set the ilter UI
     */
    setFilterUi(filter, index) {
        this.selectedFilterIs =
            this.optionalDateFilterPayload[index]["value"]["OPERATION"];
    }
   
/**
 * this function is used to set the default Advanced filter
 */
    async setDefaultAdvanceFilter() {
        let advanceFiltes = JSON.parse(
            localStorage.getItem("TimeEntries_Advance_Filter")
        );

        if (advanceFiltes) {
            this.optionalDateFilterPayload = [];
            this.optionalFilters = advanceFiltes;
            await advanceFiltes.forEach(async (filterData, index) => {
                await this.addDynamicFilter(
                    filterData.AdvancedFilter,
                    "default"
                );

                this.FILTERS.value[index].OPERATION = await filterData
                    .AdvancedFilter.OPERATION;
                this.FILTERS.value[index].FILTERTYPE = await filterData
                    .AdvancedFilter.FILTERTYPE;
                this.FILTERS.value[index].VALUE = await filterData
                    .AdvancedFilter.VALUE;
                this.FILTERS.value[index].VALUE2 = await filterData
                    .AdvancedFilter.VALUE2;
                await this.optionalDateFilterPayload.push(
                    filterData.FilterHeadings
                );

                await this.TimeEnrtyForm.patchValue({
                    FILTERS: this.FILTERS.value,
                });
            });
        }
    }
/**
 * This function is used to select the hyper link data 
 * @param row -row data value
 * @param item -item data value
 * @param hyperLinkInfo -hyperlink information data
 */
    selectHyperLink(row, item, hyperLinkInfo: any) {
        this._hyperlink.selectHyperLink(row, item, hyperLinkInfo);
    }

    /**
 * This function is used to getNewExportData;
 */
    getNewExportData(){
        let active;
        let direction
        let matterjustification=JSON.parse(localStorage.getItem('time-entries-justification'));
        if(this.sortingDefaultState == null){
          active=this.sortactive;
          direction=this.sortDirection;
        }else{
            active=this.sortingDefaultState.active;
            direction=this.sortingDefaultState?.directionl;
        }

        let columnname=[];
        if( active !== undefined && direction !==undefined &&  active !== '' && direction !==''){
            columnname=matterjustification.filter(data=>data.COLUMNID == active);
        }else{
            columnname.push({COLUMNNAME:''});
            direction='';
        }
       
    this.behaviorService.loaderDATA$.next(true);
    this.TableColumnsService.getNewExportData("time entries","","workitem",this.gloableAdvancedFilters,this.GloableadvanceFilterPayload,columnname[0].COLUMNNAME,direction) 
    }
}
