import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    animations: fuseAnimations
})
export class SidePanelComponent implements OnInit, OnDestroy {
    RegisterDocumentData: any;
    thumbnailData: any;
    isLoadingResults: boolean = true;
    RegisterDocumentDataSubscription: Subscription;
    constructor(
        public dialog: MatDialog, private behaviorService: BehaviorService, public _mainAPiServiceService: MainAPiServiceService, private toastr: ToastrService,
    ) {
        this.RegisterDocumentDataSubscription = this.behaviorService.DocumentRegisterData$.subscribe(result => {
            if (result) {
                this.thumbnailData = [];
                this.RegisterDocumentData = result;
                this.getDocumentthumbnail();
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
    }

    /**
     * This function is used to get the Document thumbnail
     */
    getDocumentthumbnail() {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.isLoadingResults = true;

        this._mainAPiServiceService.getSetData({ DOCUMENTNAME: this.RegisterDocumentData.DOCUMENTNAME, MATTERGUID: this.RegisterDocumentData.MATTERGUID }, 'NetDriveGetDocumentThumbnail').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.isLoadingResults = false;
                this.thumbnailData = response.DATA.DOCUMENTS[0];
                $('.img-width').css('width', this.thumbnailData.THUMBNAILWIDTH + 'px');
            } else if (response.CODE == 432) {
                this.isLoadingResults = false;
                window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
                    `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=800,
         height=600`);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
            }
            this.isLoadingResults = false;
            // this.isLoadingResults = false;
            // this.receiptallocation = response.DATA.RECEIPTALLOCATIONS;
        }, err => {
            this.isLoadingResults = false;
        });
        // throw new Error('Method not implemented.');
    }

    ngOnDestroy(): void {
        this.RegisterDocumentDataSubscription?.unsubscribe();
    }



}
