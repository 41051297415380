import { TooltipService } from '@_services/tooltip.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-multi-person',
  templateUrl: './multi-person.component.html',
  styleUrls: ['./multi-person.component.scss']
})
export class MultiPersonComponent implements OnInit {
  @Input() allToolTip:any;
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any = {};
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  
  constructor(public tooltipService:TooltipService) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {}

}
