import { BehaviorService } from '@_services/Behavior.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from 'src/@fuse/animations';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-trust-money-multi-details',
    templateUrl: './trust-money-multi-details.component.html',
    styleUrls: ['./trust-money-multi-details.component.scss'],
    animations: fuseAnimations
})
export class TrustMoneyMultiDetailsComponent implements OnInit, OnDestroy {
    TrustMoneyList: any;
    private _unsubscribeAll$: Subject<void> = new Subject();

    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    barristerTitle: any = this.currentuser.PRODUCTTYPE == "Solicitor" ? 'Matter Num' : 'Short Name';
    constructor(
        public dialog: MatDialog, private behaviorService: BehaviorService
    ) {
        this.behaviorService.TrustMoneyList$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.TrustMoneyList = result;
            }
        });
    }

    ngOnInit() {
    }
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

}
