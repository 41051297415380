<div class="example-loading-shade" *ngIf="isLoadingResults" style="z-index: 99999;">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div" [ngClass]="(_data.Context == 'previewall' && router.url =='/invoice/bulk-invoices') ? 'generrate_document_main_preview' : 'generrate_document_main'">
    <h2 cdkDragHandle mat-dialog-title *ngIf='header1'>
        <span *ngIf="!isGenerating"> Converted to PDF </span>
        <span *ngIf="isGenerating">Converting to PDF</span>

        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <h2 cdkDragHandle mat-dialog-title *ngIf='header2'>
        <span *ngIf="!isGenerating"> {{whichTypeTemplate}} Generated </span>
        <span *ngIf="isGenerating">Generating {{whichTypeTemplate}}</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main" fxLayout="column">
        <div  fxLayout="row" *ngIf='showmattercontact== true'>
            <div fxFlex="60">
                <strong style="font-size: 20px;">
                    {{SINGLECOUNT}} / {{Totallength}} documents generated.
                </strong>
            </div>
            <div fxFlex="40">
                <button  mat-raised-button color="accent" class="mat-accent sizeOne" selectMatter (click)="viewAllDocument(documentsData)">
                    View All
                </button>
                    &nbsp;
                <button mat-raised-button color="primary" class="mat-accent sizeOne" selectMatter (click)="downloadAllDocument(documentsData)">
                    Download All
                </button>
                   &nbsp;
                   
                <button *ngIf="isShowRegenerateAll" mat-raised-button color="primary" class="mat-accent sizeOne" selectMatter (click)="reGenerateDocument(documentsData)">
                    Re-Generate All
                </button>
            </div>
            
    
        </div>
        
<!-- As per client requirement we set it at the bottom -->

<!-- 
        <div *ngIf="isLoadingResults && _data.Context !== 'previewall'" style="position: relative;z-index: 99999;">
            <h3 *ngIf="filefolder_Name"><strong>{{filefolder_Name}}</strong> is being generated, please give it a
                moment. </h3><br>
            <h3 *ngIf="PdfName"><strong>{{PdfName}}</strong> is being converted to PDF, please give it a moment. </h3>
            <br>
            <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
            <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
        </div> -->
        
        <div class="one" style="width: 100%;" fxLayout="column"
            *ngIf="(whichTypeTemplate == 'Template' || whichTypeTemplate == 'Receipt') && (_data.Context !== 'previewall')" fxLayoutAlign="start"
            fxLayout.gt-md="row" fxFlex="1 0 auto">
            <div *ngIf="IsAuth">
                <div fxFlex="70" class="middle" *ngIf="!filefolderUrlDes" fxLayoutAlign="start">
                    <span>
                        <!-- Please login to your online shared drive account. Once completed, please close the pop-up and
                        click on try again -->
                        In order to generate this document, please login to document storage account (i.e. Dropbox,
                        OneDrive, Sharepoint or Google Drive). Once completed, please close the pop-up and generate the
                        document again. (You will only need to do this once during this session).
                    </span>
                </div>
                <div fxFlex="30">
                    <button mat-raised-button color="accent" class="mat-accent" selectMatter
                        (click)="selectMatter(_data,arguParam)">
                        Click Here to Login
                    </button>
                </div>
            </div>
            
        
            <!-- <div *ngIf="!IsAuth" style="width: 100%;">
                <div class="middle" *ngIf="!filefolderUrlDes && notShow==false && isLoadingResults" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
                <div class="middle" *ngIf="filefolderUrlDes && isAllClose" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
                <div class="middle" *ngIf="(filefolderUrlDes || PDffilerUrlDes)" fxLayoutAlign="start">
                    <span *ngIf="filefolderUrlDes"> The <b>{{filefolderUrlDes}}</b> has been
                        produced.</span>
                    <span *ngIf="PDffilerUrlDes"> The <b>{{PdfName}}</b> has been converted to
                        PDF.</span>
                </div>
            </div> -->
      
      
            <div *ngIf="!IsAuth" style="width: 100%;">
                <!-- <div class="middle" *ngIf="!filefolderUrlDes && notShow==false && isLoadingResults" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
                <div class="middle" *ngIf="filefolderUrlDes && isAllClose" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div> -->
                <div class="middle" *ngIf="(filefolderUrlDes || PDffilerUrlDes)" fxLayoutAlign="start">
                    <span *ngIf="filefolderUrlDes"> The <b>{{filefolderUrlDes}}</b> has been
                        produced.</span>
                    <span *ngIf="PDffilerUrlDes"> The <b>{{PdfName}}</b> has been converted to
                        PDF.</span>
                </div>
            </div>
        </div>
        
        <div *ngIf="!IsAuth && SHOWVIEWALL"  fxLayout="row">
            <div fxFlex="60" class="middle" *ngIf="(filefolderUrlDes || PDffilerUrlDes)" fxLayoutAlign="start">
                <span *ngIf="filefolderUrlDes"> The <b>{{filefolderUrlDes}}</b> has been
                    produced</span>
            </div>
            <div fxFlex="40" *ngIf="(filefolderUrlDes || PDffilerUrlDes)" style="text-align: center;margin-right: 2px;">
              <button  mat-raised-button color="accent" class="mat-accent size" selectMatter (click)="ViewallInvoices()">
               View All
              </button>
               &nbsp;&nbsp;
              <button mat-raised-button color="primary" class="mat-accent size" selectMatter (click)="DownloadallInvoices()">
                Download All
              </button>
            </div>
        </div>
        <br>
        <div class="one" style="width: 100%;" fxLayout="column"
            *ngIf="(whichTypeTemplate == 'Template' || whichTypeTemplate == 'Receipt')" fxLayoutAlign="start"
            fxLayout.gt-md="row" fxFlex="1 0 auto">
            <div *ngIf="!IsAuth" style="width: 100%;">
                <span *ngFor="let item of documentsData; let index = index" class="document-section">
                    <strong style="font: bold;color: red;" *ngIf="item?.MESSAGE">{{item?.SHORTNAME}} - {{item?.MESSAGE}}</strong>
                    <div fxFlex="100" fxLayoutAlign="start"
                        *ngIf="(item?.DESCRIPTION && item?.DOCUMENTTYPE=='Document')">
                        <span fxFlex="{{xFxaxVal}}">
                            <strong *ngIf="documentDataIndex!=index">{{ item.ORIGFILENAME }}</strong>
                            <span *ngIf="documentDataIndex==index">
                                <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                                    <mat-label>Old Filename</mat-label>
                                    <input matInput readonly [(ngModel)]="item.ORIGFILENAME">
                                </mat-form-field>
                                <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                                    <mat-label>New Filename</mat-label>
                                    <input matInput [(ngModel)]="item.NEWFILENAME">
                                </mat-form-field>
                            </span>
                        </span>
                        <span fxFlex="{{yflaxCal}}" *ngIf="_data.Context == 'previewall'" style="text-align: center;">
                            <!-- <a href="{{base_url}}{{item?.FILENAME}}" target="_blank"
                                mat-raised-button *ngIf="!isRename" color="accent">
                                Click here to view it
                            </a> -->

                            <a mat-raised-button color="accent" class="mat-accent" (click)="getSingleView(item?.FILENAME)"
                                #tooltip="matTooltip" matTooltip="View Document" matTooltipClass="example-tooltip-font"
                                style="margin-right: 4px;"  *ngIf="documentDataIndex!=index">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/View.png">
                                </mat-icon>
                            </a>
                            <!-- href="{{base_url}}{{item?.DOWNLOAD}}"  -->
                            <a (click)="getSingleDowanload(item?.FILENAME)" mat-raised-button [disabled]="!item?.DOWNLOAD"
                                color="accent" class="mat-accent" style="margin-right: 4px;" target="_blank"
                                *ngIf="documentDataIndex!=index" #tooltip="matTooltip" matTooltip="Download Document"
                                matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/download.png">
                                </mat-icon>
                            </a>

                        </span>
                        <span fxFlex="{{yflaxCal}}" *ngIf="_data.Context !== 'previewall'">
                            <a href="{{base_url}}{{item?.FILENAME}}" mat-raised-button color="accent" class="mat-accent"
                                #tooltip="matTooltip" matTooltip="View Document" matTooltipClass="example-tooltip-font"
                                style="margin-right: 4px;" target="_blank" *ngIf="documentDataIndex!=index">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/View.png">
                                </mat-icon>
                            </a>
                            <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="RenameFileData(index)"
                                #tooltip="matTooltip" matTooltip="Rename/Move Document"
                                matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/rename.png">
                                    <!-- (click)="xFxaxVal=70;yflaxCal=30;documentDataIndex=index  documentDataIndex=index;" #tooltip="matTooltip" -->
                                </mat-icon>
                            </button>
                            <a href="{{base_url}}{{item?.DOWNLOAD}}" mat-raised-button [disabled]="!item?.DOWNLOAD"
                                color="accent" class="mat-accent" style="margin-right: 4px;" target="_blank"
                                *ngIf="documentDataIndex!=index" #tooltip="matTooltip" matTooltip="Download Document"
                                matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/download.png">
                                </mat-icon>
                            </a>
                            <a [href]="sanitize(item?.DESKTOPLINK)" mat-raised-button [disabled]="!item?.DESKTOPLINKs"
                                color="accent" class="mat-accent" style="margin-right: 4px;" target="_blank"
                                *ngIf="documentDataIndex!=index" #tooltip="matTooltip"
                                matTooltip="Open in  Desktop Word" matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/Microsoft_Word_logo.png">
                                </mat-icon>
                            </a>
                            <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index"
                                (click)="convertToPDF(index,false)" [disabled]="(isPDFLoadingResults || item.isPdf)"
                                #tooltip="matTooltip" matTooltip="Convert to PDF"
                                matTooltipClass="example-tooltip-font">
                                <mat-spinner *ngIf="isPDFLoadingResults"></mat-spinner>
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/pdf.png">
                                </mat-icon>
                            </button>
                            <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="ToPDFAndEmail(index)"
                                [disabled]="(isPDFEmailLoadingResults || item.isPdf)" #tooltip="matTooltip"
                                matTooltip="Convert and email the document." matTooltipClass="example-tooltip-font">
                                <mat-spinner *ngIf="isPDFEmailLoadingResults"></mat-spinner>
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/email.png">
                                </mat-icon>
                            </button>
                            <button mat-raised-button color="accent" class="mat-accent" style="margin-right: 4px;"
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index"
                                (click)="ToEmailthedocument(index)" #tooltip="matTooltip"
                                matTooltip="Email the document" matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/email_tem.png">
                                </mat-icon>
                            </button>
                            <button mat-raised-button color="accent" class="mat-accent"
                                style="margin-top: 10px;margin-right: 5px;" *ngIf="documentDataIndex==index"
                                (click)="RenameFile()">Save</button>
                            <button mat-raised-button color="accent" style="margin-top: 10px;"
                                *ngIf="documentDataIndex==index"
                                (click)="documentDataIndex=99999;xFxaxVal=55;yflaxCal=45">Cancel</button>
                        </span>
                    </div>
                    <div fxFlex="100" fxLayoutAlign="start" *ngIf="(item?.DESCRIPTION && item?.DOCUMENTTYPE=='Link')">
                        <span fxFlex="{{xFxaxVal}}">
                            <b style="font-size: 15px;" *ngIf="_data.showmattercontact==true">{{item.SHORTNAME}} - {{item.MATTER | truncate:30}} - Inv No : {{item.INVOICECODE}}
                            </b><br>
                            <strong *ngIf="documentDataIndex!=index">{{ item.ORIGFILENAME }} </strong>
                            <span *ngIf="documentDataIndex==index">
                                <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                                    <mat-label>Old Filename</mat-label>
                                    <input matInput readonly [(ngModel)]="item.ORIGFILENAME">
                                </mat-form-field>
                                <mat-form-field class="example pr-4" fxFlex="50" class="pr-4" appearance="outline">
                                    <mat-label>New Filename</mat-label>
                                    <input matInput [(ngModel)]="item.NEWFILENAME">
                                </mat-form-field>
                            </span>
                        </span>
                        <span fxFlex="{{yflaxCal}}" *ngIf="item?.MATTERCONTACT?.length <= 0">
                            <a href="{{(item?.FILENAME)?item?.FILENAME:base_url+item?.DOWNLOAD}}" target="_blank"
                                *ngIf="documentDataIndex!=index" mat-raised-button color="accent" class="mat-accent"
                                style="margin-right: 4px;" #tooltip="matTooltip" matTooltip="View Document"
                                matTooltipClass="example-tooltip-font">
                                <!-- Click here to view it -->
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/View.png">
                                </mat-icon>
                            </a>
                           
                            <a href="{{base_url}}{{item?.DOWNLOAD}}" mat-raised-button color="accent"
                                [disabled]="!item?.DOWNLOAD" class="mat-accent" style="margin-right: 4px;"
                                target="_blank" *ngIf="documentDataIndex!=index" #tooltip="matTooltip"
                                matTooltip="Download Document" matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/download.png">
                                </mat-icon>
                            </a>
                            <a [href]="sanitize(item?.DESKTOPLINK)" mat-raised-button color="accent"
                                [disabled]="!item?.DESKTOPLINK" class="mat-accent" style="margin-right: 4px;"
                                target="_blank" *ngIf="documentDataIndex!=index && item.isPdf == false" #tooltip="matTooltip"
                                matTooltip="Open in Desktop Word" matTooltipClass="example-tooltip-font">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/Microsoft_Word_logo.png">
                                </mat-icon>
                            </a>

                            <!-- New changes -->

                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">

                                <button mat-menu-item
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index" (click)="RenameFileData(index)"
                                >
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/rename.png">
                                </mat-icon> Rename File
                              </button>


                            <button mat-menu-item  
                                [disabled]="(isPDFLoadingResults || item.isPdf )"
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index && showPDFButton && item.isPdf ==false"
                                (click)="convertToPDF(index,false)">
                                <mat-spinner *ngIf="isPDFLoadingResults && documentDataIndex == index"></mat-spinner>
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/pdf.png">
                                </mat-icon>Convert to PDF
                            </button>
                            <button mat-menu-item 
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index && showPDFButton && item.isPdf ==false"
                                (click)="ToPDFAndEmail(index)" [disabled]="(isPDFEmailLoadingResults || item.isPdf)"
                                >

                                <mat-spinner *ngIf="isPDFEmailLoadingResults"></mat-spinner>
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/email.png">
                                </mat-icon> Convert to PDF + Email
                            </button>
                            <button mat-menu-item
                                *ngIf="item.DOCUMENTGUID && documentDataIndex!=index"
                                (click)="ToEmailthedocument(index)">
                                <mat-icon class="main-icon">
                                    <img src="assets/icons/web_app/email_tem.png">
                                </mat-icon> Email File
                            </button>
                            </mat-menu>
                            <button mat-raised-button color="accent" class="mat-accent"
                                style="margin-top: 10px;margin-right: 5px;" *ngIf="documentDataIndex==index"
                                (click)="RenameFile()">Save</button>
                            <button mat-raised-button color="accent" style="margin-top: 10px;"
                                *ngIf="documentDataIndex==index"
                                (click)="documentDataIndex=99999;xFxaxVal=55;yflaxCal=45">Cancel</button>
                        </span>
                    </div>
                    <ng-container *ngIf="item?.MATTERCONTACT?.length > 0">
                        <div fxFlex="100">
                            <span fxFlex="60">
                                <b style="font-size: 15px;">{{item.SHORTNAME}} - {{item.MATTER | truncate:30}} - Inv No : {{item.INVOICECODE}}
                                </b><br>
                                <strong>The invoice was created but SILQ needs to know who to send it to. Please select the contact you want to send the invoice to and re-generate it.</strong>
                            </span>
                            <span fxFlex="40">
                                <div fxFlex="100">
                                    <mat-form-field fxFlex="54"  appearance="outline"  class="pr-4 setwidth" >
                                        <mat-label>Select Contact</mat-label>
                                        <mat-select name="SelectContact"placeholder="Select Contact" [(ngModel)]="item.mattercontact" (selectionChange)="onChangeMatterContact($event.value)">
                                            <mat-option *ngFor="let data of item?.MATTERCONTACT"  [value]="data">{{data.CONTACTNAME}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
    
                                    <div fxFlex="20" class="add-filterwrap" (click)="getRefreshData(item)">
                                        <mat-icon class="icon-btn pd">refresh</mat-icon>
                                    </div>
                                </div>
                               
                            </span>
                        </div>
                        
                    </ng-container>
                </span>               
            </div>
        </div>


        <div *ngIf="isLoadingResults && _data.Context !== 'previewall'" style="position: relative;z-index: 99999;">
            <h3 *ngIf="filefolder_Name"><strong>{{filefolder_Name}}</strong> is being generated, please give it a
                moment. </h3><br>
            <h3 *ngIf="PdfName"><strong>{{PdfName}}</strong> is being converted to PDF, please give it a moment. </h3>
            <br>
            <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
            <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
            <!-- {{progress}}% -->
        </div>

        <div *ngIf="!IsAuth && _data.Context != 'previewall'" style="width: 100%;">
            <div class="middle" *ngIf="((!filefolderUrlDes) && (notShow==false) && (isLoadingResults == true))" fxLayoutAlign="start">
                <h3> {{btnText}} </h3>
            </div>
            <div class="middle" *ngIf="filefolderUrlDes && isAllClose && (isLoadingResults == true)" fxLayoutAlign="start">
                <h3> {{btnText}} </h3>
            </div>
            <!-- <div class="middle" *ngIf="(filefolderUrlDes || PDffilerUrlDes)" fxLayoutAlign="start">
                <span *ngIf="filefolderUrlDes"> The <b>{{filefolderUrlDes}}</b> has been
                    produced.</span>
                <span *ngIf="PDffilerUrlDes"> The <b>{{PdfName}}</b> has been converted to
                    PDF.</span>
            </div> -->
        </div>

        <div *ngIf="isLoadingResults && _data.Context == 'previewall'" style="position: relative;z-index: 99999;">
            <h3 *ngIf="filefolder_Name"><strong>{{filefolder_Name}}</strong> is being generated, please give it a
                moment. </h3><br>
            <h3 *ngIf="PdfName"><strong>{{PdfName}}</strong> is being Converting to PDF, please give it a moment. </h3>
            <br>
            <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
            <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
            <!-- {{progress}}% -->
        </div>
        <div class="one" style="width: 100%;" fxLayout="column"
            *ngIf="(whichTypeTemplate == 'Template' || whichTypeTemplate == 'Receipt') && (_data.Context == 'previewall')" fxLayoutAlign="start"
            fxLayout.gt-md="row" fxFlex="1 0 auto">
            <div *ngIf="IsAuth">
                <div fxFlex="70" class="middle" *ngIf="!filefolderUrlDes" fxLayoutAlign="start">
                    <span>
                        <!-- Please login to your online shared drive account. Once completed, please close the pop-up and
                        click on try again -->
                        In order to generate this document, please login to document storage account (i.e. Dropbox,
                        OneDrive, Sharepoint or Google Drive). Once completed, please close the pop-up and generate the
                        document again. (You will only need to do this once during this session).
                    </span>
                </div>
                <div fxFlex="30">
                    <button mat-raised-button color="accent" class="mat-accent" selectMatter
                        (click)="selectMatter(_data,arguParam)">
                        Click Here to Login
                    </button>
                </div>
            </div>
            <div *ngIf="!IsAuth" style="width: 100%;">
                <div class="middle" *ngIf="!filefolderUrlDes" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
                <div class="middle" *ngIf="filefolderUrlDes && isAllClose" fxLayoutAlign="start">
                    <h3> {{btnText}} </h3>
                </div>
                <div class="middle" *ngIf="(filefolderUrlDes || PDffilerUrlDes)" fxLayoutAlign="start">
                    <!-- <span *ngIf="filefolderUrlDes"> The <b>{{filefolderUrlDes}}</b> has been
                        produced..</span> -->
                    <span *ngIf="PDffilerUrlDes"> The <b>{{PdfName}}</b> has been converted to
                        PDF.</span>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="primary" [mat-dialog-close]="false" [disabled]="isLoadingResults" id="canclePopup"
            cdkFocusInitial>Cancel
        </button>
    </mat-dialog-actions>
</div>