<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_invoice_main select_metter_main_div " cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">
        Instant Invoice
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="addInvoiceForm" id="addInvoiceForm" [formGroup]="addInvoiceForm" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>{{('' | productType)?'Client' :'  Solicitor'}}</mat-label>
                        <input matInput readonly placeholder="Client" [matTooltip]="tootipData.client" formControlName="client">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CLIENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CLIENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Matter</mat-label>
                        <input matInput readonly placeholder="Matter" [matTooltip]="tootipData.matter" formControlName="matter">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MATTER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.InvoiceCode,'has-warning':errorWarningData.Warning?.InvoiceCode}">
                        <mat-label>Invoice No</mat-label>
                        <input matInput placeholder="Invoive No" [matTooltip]="tootipData.Invoiceno" formControlName="Invoiceno">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICECODE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICECODE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.INVOICEDATE,'has-warning':errorWarningData.Warning?.INVOICEDATE}">
                        <mat-label>Invoice Date</mat-label>
                        <input [matTooltip]="tootipData.INVOICEDATE" formControlName="INVOICEDATETEXT" (dateChange)="InvoiceStartDate('change', $event)"
                            matInput [matDatepicker]="picker3">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.DUEDATE,'has-warning':errorWarningData.Warning?.DUEDATE}">
                        <mat-label>Due Date</mat-label>
                        <input [matTooltip]="tootipData.DUEDATETEXT" formControlName="DUEDATETEXT" (dateChange)="InvoiceDueDate('change', $event)" matInput
                            [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DUEDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DUEDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="30" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.QUANTITY,'has-warning':errorWarningData.Warning?.QUANTITY}">
                        <mat-label>Duration</mat-label>
                        <input matInput placeholder="Duration" [matTooltip]="tootipData.QUANTITY" formControlName="QUANTITY"
                            (focusout)="matterChange('',$event.value)">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITY?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="30" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.QUANTITYTYPE,'has-warning':errorWarningData.Warning?.QUANTITYTYPE}">
                        <mat-label>Type</mat-label>
                        <mat-select [matTooltip]="tootipData.QUANTITYTYPE" formControlName="QUANTITYTYPE"
                            (selectionChange)="quntityTypeChange('QuantityType',$event.value)">
                            <mat-option *ngFor="let option of optionList" value="{{option.ACTIVITYID}}">
                                {{option.DESCRIPTION}}</mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITYTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITYTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field *ngIf="('' | productType)" fxFlex="40" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.FEEEARNER,'has-warning':errorWarningData.Warning?.FEEEARNER}">
                        <mat-label>Fee Earner</mat-label>
                        <mat-select [matTooltip]="tootipData.FEEEARNER" formControlName="FEEEARNER"
                            (selectionChange)="matterChange('FeeEarner',$event.value)">
                            <mat-option *ngFor="let user of userList" value="{{user.USERID}}">{{user.FULLNAME}}
                            </mat-option>
                        </mat-select>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEEARNER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FEEEARNER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field fxFlex="100" class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALTEXT,'has-warning':errorWarningData.Warning?.ADDITIONALTEXT}">
                        <mat-label>Text</mat-label>
                        <textarea type="text" matInput [matTooltip]="tootipData.ADDITIONALTEXT" formControlName="ADDITIONALTEXT"
                            [matAutocomplete]="invoiceText"></textarea>
                        <mat-autocomplete #invoiceText="matAutocomplete" [panelWidth]="800"
                            (optionSelected)="LookupsChange($event.option.value)">
                            <mat-option *ngFor="let Lookups of filteredOptions | async"
                                [value]="Lookups.LOOKUPFULLVALUE">
                                {{Lookups.LOOKUPFULLVALUE}}
                            </mat-option>
                        </mat-autocomplete>
                        <!-- <mat-select [matTooltip]="tootipData.ADDITIONALTEXT" formControlName="ADDITIONALTEXT">
                            <mat-option *ngFor="let Lookups of LookupsList" value="{{Lookups.LOOKUPFULLVALUE}}">
                                {{Lookups.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select> -->
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALTEXT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDITIONALTEXT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100"
                        [ngClass]="{'has-error':errorWarningData.Error?.INVOICECOMMENT,'has-warning':errorWarningData.Warning?.INVOICECOMMENT}">
                        <mat-label>Invoice Comment</mat-label>
                        <textarea matInput [matTooltip]="tootipData.INVOICECOMMENT" formControlName="INVOICECOMMENT"></textarea>
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICECOMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICECOMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4">
                        <mat-label>Total (Ex-GST)</mat-label>
                        <!-- currencyMask -->
                        <input [value]="addInvoiceForm.get('Totalexgst').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [(ngModel)]="TotalExGst" (focusout)="calcTotalEXGST()" matInput
                            placeholder="Total (Ex-GST)" [matTooltip]="tootipData.INVOICETOTAL" formControlName="Totalexgst" style="text-align: right;" [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.GST,'has-warning':errorWarningData.Warning?.GST}">
                        <mat-label>GST</mat-label>
                        <input [(ngModel)]="GstVal" matInput [readonly] ="fixedandGSTfree" placeholder="GST"
                            [matTooltip]="tootipData.GST" formControlName="GST" style="text-align: right;" [value]="addInvoiceForm.get('GST').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4">
                        <mat-label>Total (Inc-GST)</mat-label>
                        <input [(ngModel)]="TotalIncGst" (focusout)="calcTotalINGST()" matInput [value]="addInvoiceForm.get('Totalincgst').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."
                            placeholder="Total (Inc-GST)" [matTooltip]="tootipData.INVOICETOTAL" formControlName="Totalincgst" style="text-align: right;" [leadZero]="true">
                   <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INVOICETOTAL?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INVOICETOTAL?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="SaveInstaceInvoice()"
            [disabled]="isspiner">
            <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don`t Save</button>
    </mat-dialog-actions>
</div>
<!-- </form> -->