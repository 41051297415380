import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { MatDialogRef } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { Router } from "@angular/router";
import { fuseAnimations } from "src/@fuse/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: "app-select-documents",
    templateUrl: "./select-documents.component.html",
    styleUrls: ["./select-documents.component.scss"],
    animations: fuseAnimations,
})
export class SelectDocumentsComponent implements OnInit, OnDestroy {
    sendDocIdToemail: any = [];
    selection = new SelectionModel<any>(true, []);
    displayedColumns: any = ["select", "DOCUMENTNAME", "GENERATEDATE", "GENERATETIME"];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    theme_type = localStorage.getItem("theme_type");
    // selectedColore: string =
    //     this.theme_type == "theme-default" ? "rebeccapurple" : "#43a047";
    selectedColore: string = 'rgb(67, 160, 71)';
    isLoadingResults: boolean = false;
    highlightedRows: any;
    SelectDocumentdata: any = [];
    pageSize: any;
    search: string = "";
    windowNameId  = window.name.split("_").pop();
    FolderDocumentList: any = [];
    lastNetDriveFolderFilter: any;
    FolderData: any = [];
    pushFolder: any = [];
    DocRegData: any = [];
    FolderDataArray: any = [];
    sendSaveFolder: string;
    lastSubFolder: any = "All";
    LastFolderFullPath: string;
    documentform: FormGroup;
    isDisplay: boolean = false;
    private _unsubscribeAll$: Subject<void> = new Subject();

    matter = JSON.parse(localStorage.getItem(this.windowNameId? this.windowNameId :"set_active_matters"));
    constructor(
      private _formBuilder: FormBuilder,
        private SortingbehaviorService: SortingBehaviourService,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<SelectDocumentsComponent>,
        public _matDialog: MatDialog,
        private router: Router,
        public behaviorService: BehaviorService
    ) {


        this.behaviorService.DocumentRegisterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result != null) {
            //   this.isOpenDesktopWord = false;
            //   this.isDocRegData = 'Notempty';
              this.DocRegData = result;
              if (this.DocRegData.type == 'docx' || this.DocRegData.type == 'doc'
                || this.DocRegData.type == 'dotx' || this.DocRegData.type == 'dot'
                || this.DocRegData.type == 'docm' || this.DocRegData.type == 'dotm') {
                // this.isOpenDesktopWord = true;
              }
            } else {
            //   this.isDocRegData = 'empty';
            }
          });




        localStorage.setItem('istrackid', 'SelectDocumentsComponent');
        this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'SelectDocumentsComponent' && result['click'] && result['data']['document']) {
                this.ngOnInit();
            }
        });
    }

    // ngOnInit() {
    //     this.LoadData({
    //         MATTERGUID: this.matter.MATTERGUID,
    //         SearchString: this.search,
    //     });
    // }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
      this.documentform = this._formBuilder.group({
        matter: [''],
        Client: [''],
        search: [''],
        foldervalue: [''],
        showfolder: ['']
      });
        if(!this.matter){
           this.matter = JSON.parse(localStorage.getItem("set_active_matters"))
        }
        // this.LoadData({
        //     MATTERGUID: this.matter.MATTERGUID,
        //     SearchString: this.search,
        // });
        this.LoadData2({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' });

        this.SortingbehaviorService.DocumentRegisterFilterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
             
           
              if (result.id != 'All') {
                this.lastSubFolder.id = "";
                this.lastSubFolder.text = "";
                this.lastSubFolder = result.lastSubFolder;
              } else {
                this.lastSubFolder.id = result.id;
                this.lastSubFolder.text = result.text;
              }
            }
          });
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        let numSelected: any = 0;
        let numRows: any = 0;
        if (this.selection.selected) {
            numSelected = this.selection.selected.length;
        }
        if (this.SelectDocumentdata.data) {
            numRows = this.SelectDocumentdata.data.length;
        }
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {

        if (this.SelectDocumentdata.data.length > 0) {
            this.isAllSelected()
                ? this.selection.clear()
                : this.SelectDocumentdata.data.forEach((row) =>
                    this.selection.select(row)
                );
        }
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {

        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }


    /**
     * This function is used to makes the array
     */
    helloFunction() {
        this.sendDocIdToemail = [];
        this.selection.selected.forEach((element) => {
            this.sendDocIdToemail.push({
                DOCUMENTGUID: element.DOCUMENTGUID,
                DOCUMENTNAME: element.DOCUMENTNAME,
            });
        });
    }

/**
 * This function is used to load the data
 */
    LoadData2(postData) {
        this.isLoadingResults = true;
        this.FolderDocumentList = [];
        this.lastNetDriveFolderFilter = postData;
        postData.MATTERGUID = this.matter.MATTERGUID;
        postData.DOCUMENTGUID = this.DocRegData.DOCUMENTGUID
        let Data=postData;
        this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
          if (res.CODE == 200 && res.STATUS == "success") {
            this.FolderData = res.DATA.FOLDERS;
            if (Object.keys(this.pushFolder).length > 0) {
              this.FolderDocumentList.unshift({ FOLDERNAME: `.. Go up one level`, foldertype: "Go_Back", is_folder: true });
            }
            res.DATA.FOLDERS.forEach(e => {
              e.is_folder = true;
              e.foldertype = 'folder';
              delete e.FOLDERS;
              this.FolderDocumentList.push(e);
            });
            this.isLoadingResults = false;
          } else {
            this.isLoadingResults = false;
          }
          this.LoadData({
            MATTERGUID: this.matter.MATTERGUID,
            SearchString: this.search,
        });
        }, err => {
          this.isLoadingResults = false;
          this.toastr.error(err);
        });
      }

      /**
       * This function is used to makes the groups
       */
      isGroup(index, item): boolean {
        return item.is_folder;
      }

      /**
       * This function is used to load the data value.
       */
 LoadData(data?) {
    let filterVal: any = {};
    if (this.lastSubFolder == "All") {
      filterVal = { 'SearchString': this.search, 'MATTERGUID': this.matter.MATTERGUID }
      let ForDataFilter = { 'SearchString': this.search, 'matterData': this.matter, 'lastSubFolder': 'All' }
      this.SortingbehaviorService.SetTransactionFilterData(ForDataFilter);

    } else {
      filterVal = { 'SearchString': this.search, 'MATTERGUID': this.matter.MATTERGUID, FULLPATH: this.LastFolderFullPath, FOLDERNAME: this.lastSubFolder };
      let ForDataFilter = { 'SearchString':this.search, 'matterData': this.matter, 'lastSubFolder': '', 'id': this.LastFolderFullPath, 'text': this.lastSubFolder };
      this.SortingbehaviorService.SetTransactionFilterData(ForDataFilter);
    }
        this.isLoadingResults = true;
        this._mainAPiServiceService.getSetData({Action:'GetData',Filters:filterVal}, "document").pipe(takeUntil(this._unsubscribeAll$)).subscribe((response) => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.behaviorService.DocumentRegisterData(null);
                if (response.DATA.RECORDS[0]) {
                    this.SelectDocumentdata.paginator = this.paginator;
                   
                    // this.highlightedRows = response.DATA.DOCUMENTS[0].DOCUMENTGUID;

                    response.DATA.RECORDS.forEach(element => {
                        if (element.DOCUMENTNAME) {
                          let docType = element.DOCUMENTNAME.split('.');
                          element.type = docType[1];
                        }
                        this.FolderDocumentList.push(element);
                      });
                      this.RowClick(response.DATA.RECORDS[0]);
                      this.highlightedRows = response.DATA.RECORDS[0].DOCUMENTGUID;
                      this.SelectDocumentdata = new MatTableDataSource(this.FolderDocumentList);
                     
                      this.sortingDate();
                      this.isLoadingResults = false;
                      this.isDisplay = false;
                      // this.DocumentAllData.sort = this.sort;
                }else {
                    this.SelectDocumentdata = new MatTableDataSource(this.FolderDocumentList);
                    this.isLoadingResults=false
                    this.isDisplay = false;
                  }
               
            }
        }, (err) => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        }
        );
        this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    }

    // LoadData(data?) {
    //     this.isLoadingResults = true;
    //     this._mainAPiServiceService.getSetData(data, "GetDocument").subscribe((response) => {
    //         if (response.CODE == 200 && response.STATUS == "success") {

    //             this.SelectDocumentdata = new MatTableDataSource(response.DATA.DOCUMENTS);
    //             this.SelectDocumentdata.paginator = this.paginator;
    //             this.isLoadingResults = false;
    //             if (response.DATA.DOCUMENTS[0]) {
    //                 this.highlightedRows = response.DATA.DOCUMENTS[0].DOCUMENTGUID;
    //             }
    //             this.sortingDate();
    //         }
    //     }, (err) => {
    //         this.isLoadingResults = false;
    //         this.toastr.error(err);
    //     }
    //     );
    //     this.pageSize = JSON.parse(localStorage.getItem("lastPageSize"));
    // }

    /**
     * This function is used to sorting the Date
     */
    sortingDate() {
        this.SelectDocumentdata.sortingDataAccessor = (item, property) => {
            if (property == "GENERATEDATE") {
                if (item[property]) {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
                    let newDate = new Date(Sd);
                    return newDate;
                }
                return item[property];
            } else {
                return item[property];
            }
        }
        // proper shorting for date 
        this.SelectDocumentdata.sort = this.sort;
    }

    /**
     * This function is used to change the pagination
     */
    onPaginateChange(event) {
        this.pageSize.document_register = event.pageSize;
        localStorage.setItem("lastPageSize", JSON.stringify(this.pageSize));
    }

    /**
     * This function is used to Row click the data
     */
    RowClick(row) {
       
        this.behaviorService.DocumentRegisterData(row);
        this.sendDocIdToemail.push({
          DOCUMENTGUID: row.DOCUMENTGUID,
          DOCUMENTNAME: row.DOCUMENTNAME,
      });
        if (this.sendDocIdToemail.length > 1) {
          let updatedArray = this.sendDocIdToemail.slice(-1)
          this.sendDocIdToemail = updatedArray
        }
     }

     /**
      * This function is used to select the template data value
      */
    selectTemplate() {
        this.dialogRef.close(this.sendDocIdToemail);
        // this.behaviorService.sendDocIdToEmailService(this.sendDocIdToemail);
    }

    /**
     * This fuction is used to On search the data value
     */
    onSearch(searchFilter: any) {
        if (searchFilter["key"] === "Enter" || searchFilter == "Enter") {
            //    let filterVal = { 'SearchString': this.f.search.value, 'MATTERGUID': this.activeMATTERGUID };
          // this.FolderDocumentList = [];
            // this.LoadData({
            //     MATTERGUID: this.matter.MATTERGUID,
            //     SearchString: this.search,
            // });
            this.LoadData2({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' });
        }
    }

/**
 * This function is used to double click on the row.
 */
    doubleClick(row) {
        if (row.foldertype == "folder") {
          this.FolderDataArray.push(row);
          this.behaviorService.SetDocumentRegisterFolderClickData(this.FolderDataArray);
          this.sendSaveFolder = row.FULLPATH;
          localStorage.setItem('sendSaveFolder', row.FULLPATH);
          this.pushFolder[row.FULLPATH] = row.FOLDERNAME;
          this.LastFolderFullPath = Object.keys(this.pushFolder)[Object.keys(this.pushFolder).length - 1];
    
          this.lastSubFolder = this.pushFolder[this.LastFolderFullPath];
          setTimeout(() => {
            this.LoadData2({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: this.LastFolderFullPath });
          }, 500);
        } else if (row.foldertype == "Go_Back") {
          this.FolderDataArray.pop();
          this.behaviorService.SetDocumentRegisterFolderClickData(this.FolderDataArray);
          delete this.pushFolder[this.LastFolderFullPath];
          setTimeout(() => {
            if (Object.keys(this.pushFolder).length > 0) {
              this.LastFolderFullPath = Object.keys(this.pushFolder)[Object.keys(this.pushFolder).length - 1];
              this.lastSubFolder = this.pushFolder[this.LastFolderFullPath];
              this.LoadData2({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: this.LastFolderFullPath });
            } else {
              this.lastSubFolder = "All";
              this.LoadData2({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' });
            }
          }, 500);
        }
      }


}
