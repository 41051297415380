import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';

import { ContentModule } from 'src/app/layout/components/content/content.module';
import { FooterModule } from 'src/app/layout/components/footer/footer.module';
import { NavbarModule } from 'src/app/layout/components/navbar/navbar.module';
import { ToolbarModule } from 'src/app/layout/components/toolbar/toolbar.module';

import { VerticalLayout1Component } from 'src/app/layout/vertical/layout-1/layout-1.component';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TaskReminderDetailsComponent } from '@pages/Task/task-reminder-details/task-reminder-details.component';

// import { TaskReminderDetailsComponent } from 'src/app/main/pages/Task/task-reminder-details/task-reminder-details.component';


@NgModule({

    declarations: [
        VerticalLayout1Component,
        TaskReminderDetailsComponent
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        FuseSidebarModule,
        ContentModule,
        FooterModule,
        NavbarModule,
        ToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatMenuModule,
        MatSelectModule,
        MatButtonModule,
        MatTableModule,
        MatProgressSpinnerModule
    ],
    exports: [
        VerticalLayout1Component
    ]
})
export class VerticalLayout1Module {
}
