import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number = 30, ellipsis: boolean = true): string {
    if (!value) {
      return '';
    }

    if (value.length > limit) {
      return value.substring(0, limit) + (ellipsis ? '...' : '');
    }

    return value;
  }

}
