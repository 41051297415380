<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div rename_mail_main">
    <h2 cdkDragHandle mat-dialog-title>{{isConfirmBoxTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form id="rename_email_form" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div class="flex_row_attach">
                    <div class="ftr_left_auto">
                        <!-- <span *ngIf="matterData?.MATTERGUID">{{matterData?.MATTER}}({{matterData?.SHORTNAME}})</span>
                        <span *ngIf="!matterData?.MATTERGUID" style="color: red;">Select Matter</span>
                        <img src="assets/icons/web_app/icon_matter_d.ico" style="cursor: pointer;" (click)="selectMatterForEmail()"> -->

                        <mat-form-field class="pr-4" appearance="outline" fxFlex="70" class="searchMatter-input"  [ngClass]="{'has-error':!searchMatterValue}">
                            <mat-label>Matters</mat-label>
                            <mat-chip-list #chipList aria-label="Matter selection" id="chiplist">
                                <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                    {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                                    <button  matChipRemove class="canclebutton">
                                        <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input matInput type="search" class="form-control"  name="Matter" [(ngModel)]="searchMatterValue"
                                    (keydown.Tab)="datashow($event)" 
                                    #MatterInput1 [matAutocomplete]="autoMatter"
                                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)" (keyup)="onKeyUp($event)" [ngClass]="mattername.length > 0?'hideInput':'showInput'">
                            </mat-chip-list>
                            <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValue($event)" >
                                <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                    <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                                </mat-option>
                            </mat-autocomplete>
                            
                            <mat-icon class="matter_icon_timeDiary" matSuffix (click)='selectMatterForEmail()'>
                                <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="select matter"
                                    matTooltipPosition="above">
                            </mat-icon>
                    </mat-form-field>
                    </div>
                   
                </div>
                <div class="attachment-list" fxLayout="row wrap">
                    <div class="rename-attachment" fxLayout="column" *ngIf="!_data.isMultiEmails">
                        <ng-container *ngFor="let item of mail | keyvalue">
                        <div class="inner_flex_div rename_flex_div" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <div fxFlex="5" class="pl-4">
                                <mat-checkbox [(ngModel)]="item.value.checked" name="item.value.checked"> </mat-checkbox>
                            </div>
                            <mat-icon class="preview" fxFlex="20">
                                <img mattooltip="" mattooltipposition="above" src="assets/icons/web_app/icon_email_u.ico" class="ng-tns-c35-27">
                            </mat-icon>
                            <mat-form-field appearance="outline" fxFlex="37.5" class=" pr-4">
                                <mat-label>Email Name</mat-label>
                                <!-- <input placeholder="Enter Email Name" matInput [(ngModel)]="mail.FILENAME" name="emailFILENAME"> -->
                                 <input placeholder="Enter Email Name" matInput [(ngModel)]="item.value.FILENAME" name="Filename">
                            </mat-form-field>
                            <div fxFlex="37.5">
                                <ejs-dropdowntree showClearButton='false' #folderlistfilename (select)="FloderChnage($event,'',item.value)" [treeSettings]="treeSettings" showDropDownIcon='false' id='folderlistfilename' [fields]='fields' (created)='created($event)' placeholder='Select a Folder'
                                    floatLabelType='Auto'>
                                </ejs-dropdowntree>
                            </div>
                        </div>
                        
                        <!-- *ngFor="let mailitem of Attachments| keyvalue  " -->
                        <ng-container *ngFor="let mailitem of item.value.attachments| keyvalue  ">
                            <!-- *ngIf="item.value.SENTDATETIME==mailitem.SENTDATETIME" -->
                            <div class="rename-attachment" fxLayout="column" *ngIf="mailitem.value?.SIZE !=null" >
                                <div class="inner_flex_div rename_flex_div" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <div fxFlex="5" class="pl-4">
                                        <mat-checkbox [(ngModel)]="mailitem.value.checked" name="checked"></mat-checkbox>
                                    </div>
                                    <div fxFlex="20" fxLayoutAlign="start">
                                        <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf="(mailitem.value.type == 'docx' || mailitem.value.type == 'doc' || mailitem.value.type == 'dotx' || mailitem.value.type == 'dot' || mailitem.value.type == 'docm' || mailitem.value.type == 'dotm ')">
                                            <img src="assets/icons/web_app/EXP_RTF.ico" matTooltip="" matTooltipPosition="above">
                                        </mat-icon>
                                        <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf=" (mailitem.value.type == 'pdf')">
                                            <img src="assets/icons/web_app/EXP_PDF.ico" matTooltip="" matTooltipPosition="above">
                                        </mat-icon>
                                        <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf="(mailitem.value.type == 'xslx' || mailitem.value.type == 'csv')">
                                            <img src="assets/icons/web_app/EXP_XLS.ico" matTooltip="" matTooltipPosition="above">
                                        </mat-icon>
                                        <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf="(mailitem.value.type == 'GIF'|| mailitem.value.type == 'gif' || mailitem.value.type == 'PNG'|| mailitem.value.type == 'png'|| mailitem.value.type =='jpeg'|| mailitem.value.type =='JPEG' || mailitem.value.type =='JPG'|| mailitem.value.type =='jpg' )">
                                            <img mattooltip="" mattooltipposition="above" src="assets/icons/web_app/IMAGE.ICO" class="ng-tns-c35-27">
                                        </mat-icon>
                                        <div fxLayout="column">
                                            <a href="#" onclick="event.preventDefault()" (click)="viewDocument(mailitem.value,item)">
                                                <mat-spinner *ngIf="mailitem.value?.ID==isLoadF?.ID" [style.zoom]="0.8">
                                                </mat-spinner>View..
                                            </a>
                                            <div class="size">({{mailitem.value?.SIZE | storagFormat}})</div>
                                        </div>
                                    </div>
                                    <div fxFlex="75">
                                        <mat-form-field appearance="outline" class="pr-4" fxFlex="50">
                                            <mat-label>File Name</mat-label>
                                            <input placeholder="Enter Email Name" matInput [(ngModel)]="mailitem.value.NAME" name="docFILENAME_{{mailitem.value.NAME}}">
                                        </mat-form-field>
                                        <div fxFlex="50">
    
                                            <!-- <app-bind-dropdown-tree id="{{mailitem.value.ejsId}}" (select)="FloderChnage($event,'',item)" attechmentkey="{{mailitem.key}}">
                                            </app-bind-dropdown-tree> -->

                                            <ejs-dropdowntree showClearButton='false' #folderlistfilename (select)="FloderChnage($event,'',mailitem.value)" [treeSettings]="treeSettings" showDropDownIcon='false' id='folderlistfilename' [fields]='fields' (created)='created($event)' placeholder='Select a Folder'
                                            floatLabelType='Auto'>
                                        </ejs-dropdowntree>
                                            <!-- <ejs-dropdowntree showClearButton='false' #folderList_{{item.key}} (select)="FloderChnage($event,item.key)" showDropDownIcon='false' [id]="'folderList_'+item.key" [fields]='fields' placeholder='Select a Folder' floatLabelType='Auto'>
                                            </ejs-dropdowntree> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    </div>
                    <div *ngIf="_data.isMultiEmails" class="rename-attachment" fxLayout="column">
                        <ng-container *ngFor="let item of mail | keyvalue">
                            <div class="rename-attachment" fxLayout="column">
                                <div class="inner_flex_div rename_flex_div" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <div fxFlex="5" class="pl-4">
                                        <mat-checkbox [(ngModel)]="item.value.checked" name="checked"></mat-checkbox>
                                    </div>
                                    <mat-icon class="preview" fxFlex="20">
                                        <img mattooltip="" mattooltipposition="above" src="assets/icons/web_app/icon_email_u.ico" class="ng-tns-c35-27">
                                    </mat-icon>
                                    <mat-form-field appearance="outline" fxFlex="37.5" class=" pr-4">
                                        <mat-label>Email Name..</mat-label>
                                        <input placeholder="Enter Email Name" matInput [(ngModel)]="item.value.FILENAME" name="emailFILENAME_{{item.key}}">
                                    </mat-form-field>
                                    <div fxFlex="37.5">
                                        <app-bind-dropdown-tree id="{{item.value.ejsId}}" attechmentkey="{{item.key}}" [Email]="item.value">
                                        </app-bind-dropdown-tree>
                                        
                                    </div>
                                </div>
                            </div>
                          
                            <ng-container *ngFor="let mailitem of item.value.attachments| keyvalue  ">
                                <!-- *ngIf="item.value.SENTDATETIME==mailitem.SENTDATETIME" -->
                                <div class="rename-attachment" fxLayout="column" *ngIf="item.value.SENTDATETIME === item.value.attachments.SENTDATETIME && mailitem.value?.SIZE !=null">
                                    <div class="inner_flex_div rename_flex_div" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                        <div fxFlex="5" class="pl-4">
                                            <mat-checkbox [(ngModel)]="mailitem.value.checked" name="checked"></mat-checkbox>
                                        </div>
                                        <div fxFlex="20" fxLayoutAlign="start">
                                            <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf="(mailitem.value.type == 'docx' || mailitem.value.type == 'doc' || mailitem.value.type == 'dotx' || mailitem.value.type == 'dot' || mailitem.value.type == 'docm' || mailitem.value.type == 'dotm ')">
                                                <img src="assets/icons/web_app/EXP_RTF.ico" matTooltip="" matTooltipPosition="above">
                                            </mat-icon>
                                            <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf=" (mailitem.value.type == 'pdf')">
                                                <img src="assets/icons/web_app/EXP_PDF.ico" matTooltip="" matTooltipPosition="above">
                                            </mat-icon>
                                            <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf="(mailitem.value.type == 'xslx' || mailitem.value.type == 'csv')">
                                                <img src="assets/icons/web_app/EXP_XLS.ico" matTooltip="" matTooltipPosition="above">
                                            </mat-icon>
                                            <mat-icon class="preview" (click)="viewDocument(mailitem.value,item)" *ngIf="(mailitem.value.type == 'GIF'|| mailitem.value.type == 'gif' || mailitem.value.type == 'PNG'|| mailitem.value.type == 'png'|| mailitem.value.type =='jpeg'|| mailitem.value.type =='JPEG' || mailitem.value.type =='JPG'|| mailitem.value.type =='jpg' )">
                                                <img mattooltip="" mattooltipposition="above" src="assets/icons/web_app/IMAGE.ICO" class="ng-tns-c35-27">
                                            </mat-icon>
                                            <div fxLayout="column">
                                                <a href="#" onclick="event.preventDefault()" (click)="viewDocument(mailitem.value,item)">
                                                    <mat-spinner *ngIf="mailitem.value?.ID==isLoadF?.ID" [style.zoom]="0.8">
                                                    </mat-spinner>View..
                                                </a>
                                                <div class="size">({{mailitem.value?.SIZE | storagFormat}})</div>
                                            </div>
                                        </div>
                                        <div fxFlex="75">
                                            <mat-form-field appearance="outline" class="pr-4" fxFlex="50">
                                                <mat-label>File Name</mat-label>
                                                <input placeholder="Enter Email Name" matInput [(ngModel)]="mailitem.value.NAME" name="docFILENAME_{{mailitem.value.NAME}}">
                                            </mat-form-field>
                                            <div fxFlex="50">
        
                                                <app-bind-dropdown-tree id="{{mailitem.value.ejsId}}" attechmentkey="{{mailitem.key}}" [Email]="mailitem.value">
                                                </app-bind-dropdown-tree>
                                                
                                                <!-- <ejs-dropdowntree showClearButton='false' #folderList_{{item.key}} (select)="FloderChnage($event,item.key)" showDropDownIcon='false' [id]="'folderList_'+item.key" [fields]='fields' placeholder='Select a Folder' floatLabelType='Auto'>
                                                </ejs-dropdowntree> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <!-- <ng-container *ngFor="let item of Attachments | keyvalue">
                        <div class="rename-attachment" fxLayout="column">
                            <div class="inner_flex_div rename_flex_div" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <div fxFlex="5" class="pl-4">
                                    <mat-checkbox [(ngModel)]="item.value.checked" name="checked"></mat-checkbox>
                                </div>
                                <div fxFlex="20" fxLayoutAlign="start">
                                    <mat-icon class="preview" (click)="viewDocument(item.value)" *ngIf="(item.value.type == 'docx' || item.value.type == 'doc' || item.value.type == 'dotx' || item.value.type == 'dot' || item.value.type == 'docm' || item.value.type == 'dotm ')">
                                        <img src="assets/icons/web_app/EXP_RTF.ico" matTooltip="" matTooltipPosition="above">
                                    </mat-icon>
                                    <mat-icon class="preview" (click)="viewDocument(item.value)" *ngIf=" (item.value.type == 'pdf')">
                                        <img src="assets/icons/web_app/EXP_PDF.ico" matTooltip="" matTooltipPosition="above">
                                    </mat-icon>
                                    <mat-icon class="preview" (click)="viewDocument(item.value)" *ngIf="(item.value.type == 'xslx' || item.value.type == 'csv')">
                                        <img src="assets/icons/web_app/EXP_XLS.ico" matTooltip="" matTooltipPosition="above">
                                    </mat-icon>
                                    <mat-icon class="preview" (click)="viewDocument(item)" *ngIf="(item.value.type == 'GIF'|| item.value.type == 'gif' || item.value.type == 'PNG'|| item.value.type == 'png'|| item.value.type =='jpeg'|| item.value.type =='JPEG' || item.value.type =='JPG'|| item.value.type =='jpg' )">
                                        <img mattooltip="" mattooltipposition="above" src="assets/icons/web_app/IMAGE.ICO" class="ng-tns-c35-27">
                                    </mat-icon>
                                    <div fxLayout="column">
                                        <a href="#" onclick="event.preventDefault()" (click)="viewDocument(item.value)">
                                            <mat-spinner *ngIf="item.value?.ID==isLoadF?.ID" [style.zoom]="0.8">
                                            </mat-spinner>View
                                        </a>
                                        <div class="size">({{item.value?.SIZE | storagFormat}})</div>
                                    </div>
                                </div>
                                <div fxFlex="75">
                                    <mat-form-field appearance="outline" class="pr-4" fxFlex="50">
                                        <mat-label>File Name</mat-label>
                                        <input placeholder="Enter Email Name" matInput [(ngModel)]="item.value.NAME" name="docFILENAME_{{item.key}}">
                                    </mat-form-field>
                                    <div fxFlex="50">

                                        <app-bind-dropdown-tree id="{{item.value.ejsId}}" attechmentkey="{{item.key}}">
                                        </app-bind-dropdown-tree>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions class="file_arrenge">
        <button class="margin_left_auto" mat-raised-button color="accent" cdkFocusInitial *ngIf="mail && !mail?.HASATTACHMENTS" style="margin-bottom: 5px;margin-top: 5px;" [disabled]="isspiner || (!matterData?.MATTERGUID)" (click)="SaveEmailtoRegister()">
            <mat-spinner *ngIf="isspiner"></mat-spinner>
            {{isLoadb1text}}
        </button>
        <button class="margin_left_auto" mat-raised-button color="accent" cdkFocusInitial *ngIf="mail?.HASATTACHMENTS" style="margin-bottom: 5px;margin-top: 5px;" [disabled]="isspiner || (!matterData?.MATTERGUID)" (click)="SaveEmailtoRegister()">
            <!-- (click)="saveAttachmentsRegister() -->
            <mat-spinner *ngIf="isspiner"></mat-spinner>
            {{isLoadb2text}}
        </button>
        <button class="margin_left_auto" mat-raised-button color="accent" cdkFocusInitial *ngIf="_data?.isGetAttechment" style="margin-bottom: 5px;margin-top: 5px;" (click)="newTimeEntry()">
            New Time Entry
        </button>
        <button class="margin_left_auto" mat-raised-button color="accent" cdkFocusInitial *ngIf="refreshbutton" style="margin-bottom: 5px;margin-top: 5px;" (click)="getAttchmentData()">
            Get Attachment
        </button>
        <button class="margin_left_auto" mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial style="margin-bottom: 5px;margin-top: 5px;">Don't Save</button>
    </mat-dialog-actions>
</div>