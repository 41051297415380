import { BehaviorService } from "@_services/Behavior.service";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class RouterPermisstionGuard implements CanActivate {
    matterDetailsFlag: boolean = false;
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    constructor(
        public behaviorService: BehaviorService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: any
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.behaviorService.matterDetailsWindow$.subscribe((res) => {
            if (res) {
                this.matterDetailsFlag = res.USEV11UI;
            } else {
                this.matterDetailsFlag = this.currentUser?.USEV11UI
                    ? this.currentUser.USEV11UI
                    : false;
            }
        });

        if (this.matterDetailsFlag) {
            this.router.navigate(["/matter-details/chronology"]);
            return false;
        } else {
            if (state.url.split("/")[1] == "matter-details") {
                this.router.navigate(["/legal-details/chronology"]);
                return true;
            }
            return true;
        }
    }
}
