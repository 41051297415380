import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class AllCountryCodeService {

    constructor(private httpClient: HttpClient) { }

    /**
     * This function is used to get the country code
     * @returns 
     */
    getCountryData() {
        return this.httpClient.get<any>('./assets/country-code.json')
    }


}
