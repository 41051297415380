import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-trust-money',
  templateUrl: './main-trust-money.component.html',
  styleUrls: ['./main-trust-money.component.scss']
})
export class MainTrustMoneyComponent implements OnInit {

  constructor() { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit(): void {
  
  }

}
