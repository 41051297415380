import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatterPopupComponent } from '../../matter-popup.component';
import { Subject, Subscription } from 'rxjs';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-motor-vehicle-accident',
    templateUrl: './motor-vehicle-accident.component.html',
    styleUrls: ['./motor-vehicle-accident.component.scss']
})
export class MotorVehicleAccidentComponent implements OnInit, OnDestroy {
    MotorVehicleAccidentForm: FormGroup;
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        public tooltipService: TooltipService) {
        this.initFormData();
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.MotorVehicleAccidentForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Motor Vehicle Accident" && result.action == 'setData') {
                    this.SaveMotorVehicleAccident();
                } else if (result.classType == "Motor Vehicle Accident" && result.action == 'deleteData') {
                    this.DeleteMotorVehicleAccident();
                }
            }
        });

        if (this.action === 'edit') {
            this.mapMotorVehicleAccidentDataToFields();
        }

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == true) {
                this.mapMotorVehicleAccidentDataToFields();
            }
        })



    }


    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }

    /**
     * this function is used to get the Accedental Date data
     */
    mapMotorVehicleAccidentDataToFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID }, FIELDTIPS: this.toolTipList }, 'matter-compensation').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterMotorVehicleAccidentData = response.DATA.RECORDS[0];
                    this.toolTipList = response.DATA.FIELDTIPS
                    if (MatterMotorVehicleAccidentData) {
                        this.MotorVehicleAccidentForm.patchValue({
                            MATTERGUID: MatterMotorVehicleAccidentData.MATTERGUID,
                            MATTERCOMPENSATIONGUID: MatterMotorVehicleAccidentData.MATTERCOMPENSATIONGUID,
                            DATERECEIVEDBYINSURER: MatterMotorVehicleAccidentData.DATERECEIVEDBYINSURER,
                            DATESENTTOINSURER: MatterMotorVehicleAccidentData.DATESENTTOINSURER,
                            ACCIDENTDATE: MatterMotorVehicleAccidentData.ACCIDENTDATE,
                            ACCIDENTDATE2: MatterMotorVehicleAccidentData.ACCIDENTDATE2,
                            ACCIDENTDATE3: MatterMotorVehicleAccidentData.ACCIDENTDATE3,
                            ACCIDENTDATE4: MatterMotorVehicleAccidentData.ACCIDENTDATE4,
                            PLACEOFINJURY: MatterMotorVehicleAccidentData.PLACEOFINJURY,
                            ESTIMATEDAWARD: MatterMotorVehicleAccidentData.ESTIMATEDAWARD,
                            CLAIMNUMBER: MatterMotorVehicleAccidentData.CLAIMNUMBER,
                            SETTLEMENTDATE: MatterMotorVehicleAccidentData.SETTLEMENTDATE,
                            EXPIRATIONDATE:MatterMotorVehicleAccidentData.EXPIRATIONDATE
                        });
                        if (MatterMotorVehicleAccidentData.ACCIDENTDATE) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.ACCIDENTDATE, "ACCIDENTDATE");
                        }
                        if (MatterMotorVehicleAccidentData.ACCIDENTDATE2) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.ACCIDENTDATE2, "ACCIDENTDATE2");
                        }
                        if (MatterMotorVehicleAccidentData.ACCIDENTDATE3) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.ACCIDENTDATE3, "ACCIDENTDATE3");
                        }
                        if (MatterMotorVehicleAccidentData.ACCIDENTDATE4) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.ACCIDENTDATE4, "ACCIDENTDATE4");
                        }
                        if (MatterMotorVehicleAccidentData.SETTLEMENTDATE) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.SETTLEMENTDATE, "SETTLEMENTDATE");
                        }
                        if (MatterMotorVehicleAccidentData.DATERECEIVEDBYINSURER) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.DATERECEIVEDBYINSURER, "DATERECEIVEDBYINSURER");
                        }
                        if (MatterMotorVehicleAccidentData.DATESENTTOINSURER) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.DATESENTTOINSURER, "DATESENTTOINSURER");
                        }
                        if (MatterMotorVehicleAccidentData.EXPIRATIONDATE) {
                            this.CommanDateSetter(MatterMotorVehicleAccidentData.EXPIRATIONDATE, "EXPIRATIONDATE");
                        }
                    }
                }
            });
        }
    }

    /**
     * This function is used to get the CommanDateSetter
     */
    CommanDateSetter(val, Field) {
        let SplitedDate = val.split("/");
        this.MotorVehicleAccidentForm.controls[Field + "TEXT"].setValue(new Date(SplitedDate[1] + '/' + SplitedDate[0] + '/' + SplitedDate[2]));
    }

    /**
     * This function is used to initForm data value.
     */
    initFormData() {
        this.MotorVehicleAccidentForm = this._formBuilder.group({
            MATTERGUID: [''],
            MATTERCOMPENSATIONGUID: [''],
            DATERECEIVEDBYINSURER: [''],
            DATERECEIVEDBYINSURERTEXT: [''],
            DATESENTTOINSURER: [''],
            DATESENTTOINSURERTEXT: [''],
            ACCIDENTDATETEXT: [''],
            ACCIDENTDATE: [''],
            ACCIDENTDATE2: [''],
            ACCIDENTDATE2TEXT: [''],
            ACCIDENTDATE3: [''],
            ACCIDENTDATE3TEXT: [''],
            ACCIDENTDATE4: [''],
            ACCIDENTDATE4TEXT: [''],
            PLACEOFINJURY: [''],
            ESTIMATEDAWARD: ['',{updateOn: 'blur'}],
            CLAIMNUMBER: [''],
            SETTLEMENTDATETEXT: [''],
            SETTLEMENTDATE: [''],
            EXPIRATIONDATE: [''],
            EXPIRATIONDATETEXT: ['']
        });
    }


 /**
     * This function is used to set the ACCIDENTDATE value.
     */
    AccidentDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['ACCIDENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the ACCIDENTDATE2 value.
     */
    AccidentDate2Click(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['ACCIDENTDATE2'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the ACCIDENTDATE3 value.
     */
    AccidentDate3Click(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['ACCIDENTDATE3'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the ACCIDENTDATE4 value.
     */
    AccidentDate4Click(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['ACCIDENTDATE4'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the ACCIDENTDATE4 value.
     */
    ExpirtyDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['EXPIRATIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the SETTLEMENTDATE value.
     */
    SettlementDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['SETTLEMENTDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

     /**
     * This function is used to set the DATESENTTOINSURER value.
     */
    DateSendToInsurerPiker(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['DATESENTTOINSURER'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }

    /**
     * this function is used to Receive the Date 
     */
    DateReceivedByInsurerPiker(type: string, event: MatDatepickerInputEvent<Date>) {
        this.MotorVehicleAccidentForm.controls['DATERECEIVEDBYINSURER'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    // HearingDatesPiker(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.MotorVehicleAccidentForm.controls['DATEOFHEARINGS'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }

     /**
     * this function is used to delete the accident
     */
    DeleteMotorVehicleAccident() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-compensation').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterMotorVehicleAccidentData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERCOMPENSATIONGUID: MatterMotorVehicleAccidentData.MATTERCOMPENSATIONGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-compensation').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

     /**
     * this function is used to Receive the Date 
     */
    SaveMotorVehicleAccident() {
        this.behaviorService.setMatterLoding(true);
        let formData: any = this.MotorVehicleAccidentForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERCOMPENSATIONGUID;
        }
        delete formData.DATERECEIVEDBYINSURERTEXT;
        delete formData.DATESENTTOINSURERTEXT;
        delete formData.ACCIDENTDATETEXT;
        delete formData.ACCIDENTDATE2TEXT;
        delete formData.ACCIDENTDATE3TEXT;
        delete formData.ACCIDENTDATE4TEXT;
        delete formData.SETTLEMENTDATETEXT;
        delete formData.EXPIRATIONDATETEXT;

        const formAction = (formData.MATTERCOMPENSATIONGUID) ? 'Update' : 'Insert';
        const MotorVehicleAccidentData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(MotorVehicleAccidentData, 'matter-compensation').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, MotorVehicleAccidentData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, MotorVehicleAccidentData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, MotorVehicleAccidentData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }
    /**
     * This function is used to check the validation
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveMotorVehicleAccidentAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveMotorVehicleAccidentAfterVAlidation(details);

        }
    }

    /**
     * This function is used to Save the vehical after validation
     */
    SaveMotorVehicleAccidentAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-compensation').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to get the text data value.
     */
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    }
}
