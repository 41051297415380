<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>Recalc Time Entries
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3 fxFlex="100" class="pr-4">
                        This function uses the rates specified on the matter to calculate the new price. As such you need to change the rates before recalculating the Time Entries.
                    </h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3 fxFlex="100" class="pr-4">
                        <strong>This process will prompt you for each Time Entry to make sure you want to change
                            it.</strong>
                    </h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-evenly center" fxFlex="1 0 auto">
                    <div> <strong>Date</strong> </div>
                    <div>{{workItem?.ITEMDATE}} </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <textarea matInput readonly [value]="workItem?.ADDITIONALTEXT"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto" style="padding-bottom: 25px;">
                    <div> <strong>Quantity</strong> </div>
                    <div>{{workItem?.QUANTITY}} </div>
                    <div>{{workItem?.QUANTITYTYPE}} </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                    <div fxFlex="13" class="pr-4"></div>
                    <div>
                        <strong> Current Price</strong>
                    </div>
                    <div><strong> New Price</strong>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                    <div fxFlex="10" class="pr-4"><strong>Ex-GST</strong> </div>
                    <div>{{workItem?.PRICE | currency}} </div>
                    <div>{{getSelectedWIPNewPrice?.PRICE | currency}} </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                    <div fxFlex="10" class="pr-4"><strong>GST</strong> </div>
                    <div>{{workItem?.GST | currency}} </div>
                    <div>{{getSelectedWIPNewPrice?.GST | currency}} </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                    <div fxFlex="10" class="pr-4"><strong>Inc-GST</strong> </div>
                    <div> {{workItem?.PRICEINCGST | currency}}</div>
                    <div> {{getSelectedWIPNewPrice?.PRICEINCGST | currency}}</div>
                </div>
            </form>
        </div>
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" *ngIf="!IsWipData">
            <div fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <h3 fxFlex="100" class=" pr-4">
                        <strong> There are no Entries to change</strong>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="ChangePrice()" cdkFocusInitial *ngIf="IsWipData" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Change entry
        </button>
        <button mat-raised-button color="primary" (click)="SkipTimeEntry()" cdkFocusInitial *ngIf="IsWipData">Don't
            Change</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial *ngIf="!IsWipData">Ok</button>
    </mat-dialog-actions>
</div>