<div class="one rate_tab_main" fxLayout="column">
    <!-- <div fxFlex="100"> -->
    <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

        <mat-accordion class="faqs-accordion">

            <app-property-details-expandation  [testBool]='testBool'  [action]="action" [toolTipList]="toolTipList"></app-property-details-expandation>

        </mat-accordion>
        <!-- </div> -->
    </form>
</div>