import { Injectable, OnInit } from "@angular/core";
import { MainAPiServiceService } from "./main-api-service.service";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: "root",
})
export class TooltipService implements OnInit {
    matterClassData$: any;
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService
    ) { }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void { }

    /**
     * This function is used to set the tool tip data value.
     */
    set setToolTipData(tooltipWindow) {
        let array: any = [];
        this._mainAPiServiceService
            .getSetData(
                { Action: "GetData", Filters: { EntityName: tooltipWindow } },
                "field-tips"
            )
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        let obj: any = {};
                        let FieldData: any = JSON.parse(
                            localStorage.getItem("FieldTooltipData")
                        );
                        if (obj && !FieldData) {
                            obj[tooltipWindow] = res.DATA.FIELDTIPS;
                            localStorage.setItem(
                                "FieldTooltipData",
                                JSON.stringify(obj)
                            );
                            this.matterClassData$ = obj;
                        } else {
                            FieldData[tooltipWindow] = res.DATA.FIELDTIPS;
                            localStorage.setItem(
                                "FieldTooltipData",
                                JSON.stringify(FieldData)
                            );
                            this.matterClassData$ = FieldData;
                        }
                        return this.matterClassData$;
                    }
                },
                (err) => {
                    this.toastr.error(err);
                }
            );
    }
    // get setToolTipData():any{
    //   this.matterClassData$ = JSON.parse(localStorage.getItem('FieldTooltipData'))
    //   return this.matterClassData$;
    // }

    /**
     * This function is used to copy tool tip data text.
     */
    copyTooltipText(event: any) {
        navigator.clipboard.writeText(event);
    }
}
