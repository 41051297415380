<div class="example-loading-shade" *ngIf="isLoadingResults" style="z-index: 99999;">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div uploading_document_main">
    <h2 cdkDragHandle mat-dialog-title>
        <span *ngIf="!isGenerating">Templates Uploaded</span>
        <span *ngIf="isGenerating">Uploading Templates</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main" fxLayout="column">
        <div *ngIf="isLoadingResults" style="position: relative;z-index: 99999;">
            <h3><strong>It will take a while for the Uploading Templates.</strong></h3>
            <br>
            <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
            <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
        </div>
        <div class="one" style="width: 100%;" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row"
            fxFlex="1 0 auto">
            <div *ngIf="IsAuth" style="width: 100%;">
                <div fxFlex="80" class="middle" fxLayoutAlign="start">
                    <span>
                        Please login to your Xero account. Once completed, please click on try again
                    </span>
                </div>
                <div fxFlex="20">
                    <button mat-raised-button color="accent" class="mat-accent" selectMatter (click)="syncToXero()">
                        Click Here to Login
                    </button>
                </div>
            </div>
            <div *ngIf="!IsAuth && !isError">
                <div fxFlex="100" fxLayoutAlign="start">
                    <h3><strong> {{btnText}} </strong></h3>
                </div>
            </div>
            <span *ngIf="isError" style="width: 100%;">
                <div *ngFor="let item of errorData" fxLayoutAlign="start">
                    <h3 fxFlex="25"><strong>{{item?.ERRORTYPE}}: </strong></h3>
                    <h3 fxFlex="75"><strong>{{item?.MESSAGE}}</strong></h3>
                    <hr>
                </div>
            </span>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="primary" [mat-dialog-close]="false" [disabled]="isLoadingResults"
            cdkFocusInitial>Cancel
        </button>
    </mat-dialog-actions>
</div>