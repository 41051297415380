import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from 'src/@fuse/services/config.service';
import { navigation } from 'src/app/navigation/navigation';
import { Router } from '@angular/router';
import { AuthenticationService } from '@_services/authentication.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { CurrentUser } from 'src/app/_models/CurrentUser';


@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    isOpenNotifications: boolean = false;
    isOpenWarning: boolean = false;
    isOpenNotificationsPermission: boolean = false;
    UserData: any = {};
    selectedColore: string;
    warningColor: string = '#cc3d76';
    taskData: any = [];
    isTaskData: boolean = false;
    intervel: any;
    toolbarsetting: any
    preferenceObjectData: any
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private authenticationService: AuthenticationService,
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
        private cd: ChangeDetectorRef,
        public router: Router
    ) {
        /*
        Used of this behvoiur :: This behaviour used to show all login API data which we used for the current user
        */
        this.authenticationService.currentUserSubject$.subscribe(result => {
            if (result) {
                this.UserData = result;
                this.selectedColore = (this.UserData.PRODUCTTYPE == "Solicitor") ? '#43a047' : 'rebeccapurple';
                if (this.UserData && (this.UserData.REGISTRATIONSTATUS == "Expires Soon" || this.UserData.REGISTRATIONSTATUS == "Grace Period" || this.UserData.REGISTRATIONSTATUS == "Expired" || this.UserData.REGISTRATIONSTATUS == "Trial Expired" || this.UserData.REGISTRATIONSTATUS == "Trial Expires Soon" || this.UserData.REGISTRATIONSTATUS == "Current Trial" || (this.UserData.REGISTRATIONSTATUS == "Using New UI" || this.UserData.REGISTRATIONSTATUS !== "") && this.UserData.REGISTRATIONMESSAGE && this.UserData.REGISTRATIONMESSAGE !== '')) {
                    this.isOpenNotifications = true;
                    this.isOpenWarning = true;
                    if (this.UserData.REGISTRATIONSTATUS == "Using New UI" || this.UserData.REGISTRATIONSTATUS !== "") {
                        this.behaviorService.permissionCheckforHeight$.next(true);
                    } else {
                        this.behaviorService.permissionCheckforHeight$.next(false);
                    }
                    // this.behaviorService.permissionCheckforHeight$.next(true);
                }
            } else {
                this.isOpenNotifications = false;
                this.isOpenWarning = false;
                this.UserData = {};
            }
        });
        this.behaviorService.toolBaarSetting$.subscribe(result => {
            this.toolbarsetting = result;
            // this.target = (result && result.OPENINNEWTABWINDOWS) ? result.OPENINNEWTABWINDOWS : {};
            // this.target = (this.toolbarsetting && this.toolbarsetting.OPENINNEWTAB) ? "_blank" : "_self";
        });
        this.behaviorService.permissionCheck$.subscribe(result => {
            //  this.isOpenNotificationsPermission = result;
            this.isOpenNotifications = result;
            if (result == true) {
                this.behaviorService.permissionCheckforHeight$.next(true);
            } else {
                this.behaviorService.permissionCheckforHeight$.next(false);

            }
        });
        this.behaviorService.GetPreferences$.subscribe((result) => {

            if (result) {
                this.preferenceObjectData = result

            } else {
                this.preferenceObjectData = JSON.parse(localStorage.getItem('currentUser'));

            }
            if (result && result.NAVBARFOLDED) {
                setTimeout(() => {
                    $('.navbar-fuse-sidebar').addClass('folded');
                }, 1000);
            } else {
                $('.navbar-fuse-sidebar').removeClass('folded');
            }

        })
        // Set the defaults
        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    isTaskDataBack(Evnt) {
        this.isTaskData = Evnt;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    displayWindowSize() {
        if (document.documentElement.clientWidth < 1280) {
            $('.navbar-fuse-sidebar').removeClass('open locked-open');
            $('.overlaywrap').remove();
        } else {
            $('.navbar-fuse-sidebar').addClass('open locked-open');

        }
    }

       /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
        });
        if (localStorage.getItem('currentUser')) {
            this.getTask();
        }
        this.intervel = setInterval(() => this.getTask(), 300000);
        window.addEventListener("resize", this.displayWindowSize);
        if (this.preferenceObjectData && this.preferenceObjectData.NAVBARFOLDED) {
            setTimeout(() => {
                $('.navbar-fuse-sidebar').addClass('folded');
            }, 1000);
        }
       
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.cd.detectChanges();
        // $('.time_entry_sidebar').mouseover(function() {
        //     $('body').css('--spaceRight', '310px');
        // });
        
        // // Mouseout event
        // $('.time_entry_sidebar').mouseout(function() {
        //     console.log('out1111');
        //         $('body').css('--spaceRight', '70px');
        // });
    }

    /**
     * This function is used to get the task.
     */
    getTask() {
        let currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.UserGuid && currentUser.SessionToken) {
            //new Payload changes see this ToDo -> New API for Tasks.
            let refectorPayload = {
                "Filters": {
                    HASREMINDER: true,
                    HASREMINDERMINUTES: 5,
                    USERGUID: currentUser.UserGuid,
                },
                "BACKGROUND": true,
                Action: "GetData"
            }

            //old changes -> {HASREMINDER: true, HASREMINDERMINUTES: 5, USERGUID: currentUser.UserGuid, "BACKGROUND": true} on refectorPayload place
            this._mainAPiServiceService.getSetData(refectorPayload, 'task').subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.taskData = res.DATA.TASKS;
                    if (this.taskData[0]) {
                        this.isTaskData = true;
                    } else {
                        this.isTaskData = false;
                    }
                }
            }, err => {
                console.log(err);
            });
        }
    }

    /**
     * This function is used to close the notification.
     */
    CloseNotification() {
        this.isOpenNotifications = false;
        this.behaviorService.permissionCheckforHeight$.next(false);

    }

    CloseWarning() {
        this.isOpenWarning = false;
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        clearTimeout(this.intervel);
    }
}
