
import { AccountReconciliationComponent } from './account-reconciliation.component';
import { AdjustmentDialogComponent } from './adjustment-dialog/adjustment-dialog.component';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { BankFeedComponent } from '../bank-feed/bank-feed.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { NumberOnlyDirective } from "./common-numeric.component";
import { PastBankingsComponent } from './past-bankings/past-bankings.component';
import { PastReconciliationComponent } from './past-reconciliation/past-reconciliation.component';
import { PipesModule } from '@_pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecounciliationItemComponent } from './recounciliation-item/recounciliation-item.component';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from 'src/app/Shared/shared.module';
import { TextFieldModule } from '@angular/cdk/text-field';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    { path: '', redirectTo: '/account-reconciliation/reconciliation-item', pathMatch: 'full', canActivate: [AuthGuard] },
    {
        path: '', component: AccountReconciliationComponent, children: [
            {
                path: 'reconciliation-item', component: RecounciliationItemComponent, data: {
                    title: 'Reconciliation Item'
                }
            },
            {
                path: 'past-banking', component: PastBankingsComponent, data: {
                    title: 'Past Banking'
                }
            },
            {
                path: 'past-reconciliation', component: PastReconciliationComponent, data: {
                    title: 'Past Reconciliation'
                }
            },
            // {
            //     path: 'bank-feed', component: BankFeedComponent, data: {
            //         title: 'Bank Feed'
            //     }
            // },
            {
                path: 'account-reconciliation/bank-feed', component: BankFeedComponent, data: {
                  title: 'Bank Feed'
                }
            },
            // { path: 'account-reconciliation/bank-feed#connect', redirectTo: 'account-reconciliation/bank-feed' }
        ], canActivate: [AuthGuard]
    }
];
// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "right",
//   allowNegative: true,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$",
//   suffix: "",
//   thousands: ",",
//   nullable: false,
//   inputMode: CurrencyMaskInputMode.NATURAL,
// };

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        PastReconciliationComponent,
        AccountReconciliationComponent,
        RecounciliationItemComponent,
        PastBankingsComponent,
        AdjustmentDialogComponent,
        NumberOnlyDirective,
    ],
    entryComponents: [
        AdjustmentDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatSelectModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        SatDatepickerModule, SatNativeDateModule,
        DragDropModule,
        MatExpansionModule,
        MatRadioModule,
        // N level tree checkbox
        MatTreeModule,
        CdkTableModule,
        CdkTreeModule,
        ScrollingModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        FormsModule,
        MatSortModule,
        ApplicationPipesModule,
        ResizableModule,
        NgxCurrencyModule,
        SharedModule,
        MatSidenavModule,
        NumberMaskModule,
        MatChipsModule,
        PipesModule,
        TextFieldModule,
        NgxMaskModule.forRoot(maskConfig)
    ],
    exports: [
        MatTreeModule,
        CdkTableModule,
        CdkTreeModule,
        ScrollingModule,
        MatDatepickerModule
    ],
    providers: [
        CurrencyPipe,
        DecimalPipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
        //  { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
    bootstrap: [],
})


export class AccountRecountciliationModule { }
