<div cdkDrag class="popup_main_div signatures_main">
    <h2 cdkDragHandle mat-dialog-title>{{isConfirmBoxTitle}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <mat-tab-group class="signature-tab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab label="Default Signature">
                    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="padding-top: 20px;">
                        <ckeditor [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc [config]="configsOption" fxFlex="100" class="ckeClass" [editor]="Editor" data="" (ready)="onReadyDefultSign($event)">
                        </ckeditor>
                    </div> -->
                    <!-- <div class="one" fxLayout="column">
                        <div fxLayout="row wrap" class="pt-12" fxLayoutAlign="end" fxFlex="1 0 auto">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="simple"
                                (change)="onValChange($event.value)">
                                <mat-button-toggle value="simple">Simple</mat-button-toggle>
                                <mat-button-toggle value="HTML">HTML</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                        <div class="pt-12 user-editorwrap"
                            *ngIf="signatureType!='HTML'">
                            <ckeditor [(ngModel)]="ckeditorValue" name="ckeditorValue" #ckeditor_full_desc
                                [config]="configsOption" fxFlex="100" class="ckeClass" [editor]="Editor"
                                data="" (ready)="changesDetact($event)">
                            </ckeditor>
                        </div>

                        <div   *ngIf="signatureType=='HTML'" class="pt-12">
                            <mat-form-field appearance="outline" fxFlex="100" class="signature-html">
                                <textarea class="min_height_textarea" (focusout)="signaturePreview()" matInput
                                    [(ngModel)]="ckeditorValue" name="ckeditorValue"></textarea>
                            </mat-form-field>
                        </div>
                        <mat-label class="font-weight-700" *ngIf="signatureType=='HTML'">Preview</mat-label>

                        <div *ngIf="signatureType=='HTML'">

                            <div fxFlex="100" class="parentHtmlCssDiv">
                                <div [innerHTML]="HtmlCss" class="previewDiv"></div>
                            </div>
                        </div>
                    </div> -->
                    <div class="one user-editorwrap" fxLayout="column">
                         <ejs-richtexteditor [fontColor]="fontColor" [backgroundColor]="backgroundColor"  [fontFamily]='fontFamily' [insertImageSettings]='insertImageSettings' [quickToolbarSettings]='quickToolbarSettings' [toolbarSettings]='toolbarSettings' [pasteCleanupSettings]="pasteCleanupSettings" [(ngModel)]="ckeditorValue"  fxFlex="100" name="ckeditorValue" class="ckeClass" #ckeditor_full_desc (ready)="onReadyDefultSign($event)">
                        </ejs-richtexteditor>
                    </div>
                </mat-tab>
                <mat-tab label="Reply/Forward Signature">
                   <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="padding-top: 20px;">
                         <ckeditor [(ngModel)]="ckeditorValue1" name="ckeditorValue1" #ckeditor_full_1_desc [config]="configsOption1" fxFlex="100" class="ckeClass" [editor]="Editor" data="" (ready)="onReadyReplySign($event)">
                        </ckeditor>
                    </div>-->
                    <div class="one user-editorwrap" fxLayout="column">

                        <ejs-richtexteditor [fontColor]="fontColor" [backgroundColor]="backgroundColor" *ngIf="showeditor" [fontFamily]='fontFamily' [insertImageSettings]='insertImageSettings' [quickToolbarSettings]='quickToolbarSettings' [toolbarSettings]='toolbarSettings' [pasteCleanupSettings]="pasteCleanupSettings" [(ngModel)]="ckeditorValue1"  fxFlex="100" name="ckeditorValue1" class="ckeClass" #ckeditor_full_1_desc (ready)="onReadyReplySign($event)">
                       </ejs-richtexteditor>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <mat-dialog-actions class="file_arrenge">
        <button class="margin_left_auto" mat-raised-button color="accent" cdkFocusInitial style="margin-bottom: 5px;margin-top: 5px;" [disabled]="isspiner" (click)="updateSignatures()">
            <mat-spinner *ngIf="isspiner"></mat-spinner>
            Save
        </button>
        <button class="margin_left_auto" mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial style="margin-bottom: 5px;margin-top: 5px;">Cancel</button>
    </mat-dialog-actions>
</div>
