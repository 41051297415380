import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

    var1: any;

    /**
     * This function is used to transform the data.
     */
    transform(value: any, contactNo: any): any {
        var statecode = '', numpart1 = '', numpart2 = '';

        let numstr = value.toString().trim().replace(/^\+/, '');
        numstr = numstr.replace(/\D/g, "");

        var lengthS = numstr.length;

        if (/^04/.test(numstr) && lengthS <= 10) {
            statecode = '(' + numstr.slice(0, 4) + ')';
            numpart1 = numstr.slice(4, 7);
            numpart2 = numstr.slice(7, 3);

            this.var1 = statecode + ' ' + numpart1 + '-' + numpart2;

        } else if (/^02/.test(numstr) && lengthS <= 10) {
            statecode = '(' + numstr.slice(0, 2) + ')';
            numpart1 = numstr.slice(2, 6);
            numpart2 = numstr.slice(6, 10);

            this.var1 = statecode + numpart1 + '-' + numpart2;

        } else if (lengthS <= 10) {
            numpart1 = numstr.slice(0, 5);
            numpart2 = numstr.slice(5, 10);

            this.var1 = numpart1 + ' ' + numpart2;
        }
        return this.var1;
    }


}
