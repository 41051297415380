import { Subscription, map } from 'rxjs';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, HostListener, ElementRef, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from 'src/@fuse/animations';
import { SortingDialogComponent } from 'src/app/main/sorting-dialog/sorting-dialog.component';
import { ResizeEvent } from 'angular-resizable-element';
import * as $ from 'jquery';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { MatSelect } from '@angular/material/select';
import { FilterSearchOptions } from 'src/app/_constant/dynamic-search-option.const';
import moment from 'moment';
@Component({
  selector: 'app-past-reconciliation',
  templateUrl: './past-reconciliation.component.html',
  styleUrls: ['./past-reconciliation.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PastReconciliationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
  subscription: Subscription;
  chartAccountDetail: any;
  pageSize: any;
  isLoadingResults: boolean = false;
  highlightedRows: any;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  displayedColumns: string[];
  tempColobj: any;
  dateColFilter: any = [];
  ColumnsObj = [];
  PastRECData: any = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  isDisplay: boolean = false;
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;
  accountTypeData: any = {};
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  resizableMousemove: () => void;
  resizableMouseup: () => void;
  currentUserData = JSON.parse(window.localStorage.getItem("currentUser"));
  PASTREC: FormGroup;
  FILTERS: FormArray;
  FILTERSLIST: FormArray;
  COLOR: any;
  currentMatter: any
  public COLOR$=this.behaviorService.ColorSystemSetting$.asObservable().pipe(map(data=>this.COLOR=data));
  optionalFilterList = [];
  showFiltersList: boolean = true;
  selectedFilterIs: any;
  activeOptionalFilter: any;
  addFilter;
  activeFilter = FilterSearchOptions.activeFilter;
    unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter;
    dateFilter = FilterSearchOptions.dateFilter;
    currencyFilter = FilterSearchOptions.currencyFilter;
    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;
    _filterList = [];
    optionalFilters = [];
    saveAdvanceFilter = [];
    optionalDateFilterPayload = [];
    finalPayloaddata: any;

  constructor(private dialog: MatDialog, private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService, private _formBuilder: FormBuilder,
    public behaviorService: BehaviorService,
    private cd: ChangeDetectorRef,
    private TableColumnsService: TableColumnsService, private SortingbehaviorService: SortingBehaviourService,private renderer: Renderer2) {
    this.sub1 = this.SortingbehaviorService.pastRecouncileSorting$.subscribe((result) => {
      if (result) {
        this.sortingDefaultState = result;
        localStorage.setItem('past_recounclie_screen_sorting', JSON.stringify(result));
      } else {
        this.sortingDefaultState = JSON.parse(localStorage.getItem('past_recounclie_screen_sorting'))
      }
    });
    this.sub2 = this.behaviorService.TrustDuplicateModuleHandling$.subscribe(result => {
      if (result) {
        this.accountTypeData = result;
      }
    });
    localStorage.setItem('istrackid', 'PastReconciliationComponent');
    this.sub3 = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'PastReconciliationComponent' && result['click'] && result['data']['reconciliation']) {
        if (!localStorage.getItem('pastreconciliation_list_columns')) {
          this.getTableFilter();
          setTimeout(() => {
            this.getFilter();
          },1000);
        }
        this.pastRecouncilliationRefresh();
      }
    });

    this.addFilter = {
      OPERATION: "",
      VALUE: "",
      VALUE2: "",
      FILTERTYPE: "",
  };
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if (this.sortingDefaultState) {
      this.sortactive = this.sortingDefaultState.active;
      this.sortDirection = this.sortingDefaultState.direction;
    } else {
      this.sortactive = "";
      this.sortDirection = "";
    }
    this.sub4 = this.behaviorService.ChartAccountData$.subscribe(result => {
      if (result) {
        this.chartAccountDetail = result;
      }
    });
    this.getTableFilter();
    setTimeout(() => {
      this.getFilter();
    },1000);    this.LoadData({ AccountGuid: this.chartAccountDetail.ACCOUNTGUID, USETRUST: (this.accountTypeData.UseTrust == "Yes" || this.accountTypeData.UseTrust == true) ? true : false });
    this.resizeTableForAllView();
    $(window).resize(() => {
      this.resizeTableForAllView();
    });

    this.PASTREC = this._formBuilder.group({
      FILTERS: new FormArray([]),
      FILTERSLIST: new FormArray([]),
      MAINFILTER: [""],
  });

  this.setDefaultAdvanceFilter();
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.sub3?.unsubscribe();
    this.sub4?.unsubscribe();
    this.sub5?.unsubscribe();
  }

  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit() {
    this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
    setTimeout(() => {
      this.resizeTableForAllView();
    }, 500);
  }
  resizeTableForAllView() {
    let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
    $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 100)) + 'px');//before 150
  }
  sortData(val) {
    this.SortingbehaviorService.pastRecouncileSorting(val);
    this.setDefaultWidth(this.displayedColumns, 0);
  }
  getTableFilter() {
    let pastReconListColumns: any = JSON.parse(localStorage.getItem('pastreconciliation_list_columns'));
    if (pastReconListColumns && pastReconListColumns != null) {
      let data = this.TableColumnsService.filtertableColum(pastReconListColumns.ColumnsObj);
      this.tempColobj = data.tempColobj;
      this.displayedColumns = pastReconListColumns.displayedColumns;
      this.ColumnsObj = pastReconListColumns.ColumnsObj;
      this.dateColFilter = data.dateCol;
    } else {
      this.sub5 = this.TableColumnsService.getTableFilter('Reconciliation', 'PastReconciliations').subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let data = this.TableColumnsService.filtertableColum(response.DATA.RECORDS);
          this.tempColobj = data.tempColobj;
          this.displayedColumns = data.showcol;
          this.ColumnsObj = data.colobj;
          this.dateColFilter = data.dateCol;
          localStorage.setItem('pastreconciliation_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
        }
      }, error => {
        this.toastr.error(error);
      });
    }
  }
  onResizing(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      const indexData = this.ColumnsObj.findIndex(col => col.COLUMNID === columnName);
      this.ColumnsObj[indexData]['WIDTH'] = event.rectangle.width;
      localStorage.setItem('pastreconciliation_list_columns', JSON.stringify({ "ColumnsObj": this.ColumnsObj, 'displayedColumns': this.displayedColumns }));
      
    }
  }
  onResizeEnd(event: ResizeEvent, columnName): void{
    this.TableColumnsService.SaveWidthData(this.ColumnsObj, 'Reconciliation', 'PastReconciliations');
  }

  setDefaultWidth(displayedColumns,timeout) {
    setTimeout(() => {
      displayedColumns.forEach(element => {
        let temWidth = this.tempColobj[element]['WIDTH'];
        const cssValue = temWidth + 'px';
        const columnElts = document.getElementsByClassName('mat-column-' + element);
        for (let i = 0; i < columnElts.length; i++) {
          const currentEl = columnElts[i] as HTMLDivElement;
          currentEl.style.visibility = 'inherit';
          currentEl.style.width = cssValue;
        }
      });
    }, timeout);
  }
  pastRecouncilliationRefresh() {
    this.LoadData({ AccountGuid: this.chartAccountDetail.ACCOUNTGUID, USETRUST: (this.accountTypeData.UseTrust == "Yes" || this.accountTypeData.UseTrust == true) ? true : false });
  }
  LoadData(dataa?) {
    this.isLoadingResults = true;

   let  data={
         AccountGuid: this.chartAccountDetail.ACCOUNTGUID,
         USETRUST: (this.accountTypeData.UseTrust == "Yes" || this.accountTypeData.UseTrust == true) ? true : false ,
         AdvancedFilters:''
         }

    const advanceFilterPayload =
            this.TableColumnsService.loadAdvanceFilterPayload("PASTREC");
            data.AdvancedFilters = advanceFilterPayload
            ? advanceFilterPayload
            : [];

        if (
          data.AdvancedFilters &&
          data.AdvancedFilters.length == 0
        ) {
            delete data.AdvancedFilters;
            localStorage.removeItem("PASTREC_Advance_Filter");
        }

        // ends here ~ for optional filter
        const finalBasicPayload = JSON.parse(JSON.stringify(data));

        delete finalBasicPayload.DateRange;
        delete finalBasicPayload.dayRange;
    this.subscription = this._mainAPiServiceService.getSetData({Action:'GetData',Filters:finalBasicPayload}, 'reconciliation').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.PastRECData = new MatTableDataSource(response.DATA.RECORDS);
        this.PastRECData.paginator = this.paginator;
        this.sortingDate();
        if (response.DATA.RECORDS[0]) {
          this.isDisplay = false;
          this.editBanking(response.DATA.RECORDS[0]);
          this.highlightedRows = response.DATA.RECORDS[0].RECONCILIATIONGUID;
        } else {
          this.isDisplay = true;
        }
        this.isLoadingResults = false;
        this.setDefaultWidth(this.displayedColumns,500);
      } else if (response.CODE == 406 && response.MESSAGE == "Permission denied") {
        this.PastRECData = new MatTableDataSource([]);
        this.PastRECData.paginator = this.paginator;
        this.sortingDate();
        this.isLoadingResults = false;
        this.setDefaultWidth(this.displayedColumns,500);
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
  }
  sortingDate() {
    this.PastRECData.sortingDataAccessor = (item, property) => {
      let FildeValue = this.dateColFilter;
      if (FildeValue.includes(property)) {
        if (item[property]) {
          let tempDate = item[property].split("/");
          let Sd = new Date(tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]);
          let newDate = new Date(Sd);
          return newDate;
        }
        return item[property];
      } else {
        return item[property];
      }
    };
    // proper shorting for date
    this.PastRECData.sort = this.sort;
  }
  editBanking(row) {
    this.behaviorService.PastRecouncileData(row);
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '100%';
    dialogConfig.disableClose = true;
    dialogConfig.data = { 'data': this.ColumnsObj, 'type': 'Reconciliation', 'list': 'PastReconciliations' };
    const dialogRef = this.dialog.open(SortingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tempColobj = result.tempColobj;
        this.displayedColumns = result.columObj;
        this.ColumnsObj = result.columnameObj;
        this.dateColFilter = result.dateCol;
        localStorage.setItem('pastreconciliation_list_columns', JSON.stringify({ 'displayedColumns': result.columObj, "ColumnsObj": result.columnameObj }));
        if (!result.columObj) {
          this.PastRECData = new MatTableDataSource([]);
          this.PastRECData.paginator = this.paginator;
          this.sortingDate();
          this.isDisplay = true;
        } else {
          this.LoadData({ AccountGuid: this.chartAccountDetail.ACCOUNTGUID, USETRUST: (this.accountTypeData.UseTrust == "Yes" || this.accountTypeData.UseTrust == true) ? true : false });
        }
      }
    });
  }
  onPaginateChange(event) {
    this.setDefaultWidth(this.displayedColumns,0);
    this.pageSize.past_recounciliations = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

//   onResizeColumnSetting(event , index){
//     //event : selected column.
//     //index : selected column index.
//     //renderer : dont make this comman it's give issue import in your component.
//     //displayedColumns : displayedColumns name's (Table Header names).
//     //matTableRef : most importent please import in component wise don't make it comman.
//     // last field is localstorage name which use to store columnObj

//     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'pastreconciliation_list_columns');
//   };

  //For resize this fuction is most impotent don't make it comman it's give issue in future.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
     this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

 /**
     * Used to go to the back filter
     * @param id -filter selected id
     */
 backToFilter(id):void {
  $("#" + id).removeClass("active");
  setTimeout(() => {
      $(".mat-menu-content").removeClass("active");
      $(".mat-menu-content").removeClass("mat-menu-content_height");
  }, 50);
  if (this.FILTERS) {
      this.FILTERSLIST?.reset();
  }
}

  /**
     * Used to get the filter data.
     */
  getFilter():void {
    let opFilterList = JSON.parse(
        localStorage.getItem("pastreconciliation_list_columns")
    );
    const filterData = this.TableColumnsService.getDynamicFilter(
        opFilterList,
        this.optionalFilterList,
        "pastreconciliation_list_columns"
    );
    this.refreshFilterList();
}

 /**
     * Get the data After Refresh the filter data.
     */
 refreshFilterList():void {
  //AdvanceFilter Refresh
  const advDynamicFilter = JSON.parse(
      localStorage.getItem("PASTREC_Advance_Filter")
  );
  this.optionalFilterList.map((x) => {
      if (
          advDynamicFilter &&
          advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
              -1
      ) {
          x.FILTERAPPLY = true;
      }
  });
}

/**
     * Used  to get the active filter data.
     * @param id -filter id
     * @param data -filter data.
     */
activeCurrentFilter(id, data?: any):void {
  $("#" + id).addClass("active");
  setTimeout(() => {
      $(".mat-menu-content").addClass("active");
      $(".mat-menu-content").addClass("mat-menu-content_height");
  }, 200);

  //this.matterFilterForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
  this.selectedFilterIs = "";
  this.PASTREC.controls["MAINFILTER"].reset();
  this.activeOptionalFilter = data;
}
@ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;

 /**
     * Used to set the default value to the filter
     * @param event -event data
     */
 preventDefault(event) {
  event.preventDefault();
  event.stopPropagation();
}


/**
     * Used to apply the optional date filter
     * @param event -event data
     * @param filterIs -filter object of values
     * @param val -current object value.
     * @param index -index of the filter data.
     */
optionalDateFilter(event, filterIs, val, index):void {
  if (val && val == "active") {
      this.selectedFilterIs = event.value;
  } else {
      filterIs.selectedFilter = event.value;
      this.selectedFilterIs = event.value;
  }
  if (index != -1) {
      this.PASTREC.controls.FILTERS["controls"][
          index
      ].controls["VALUE"].reset();
      this.PASTREC.controls.FILTERS["controls"][
          index
      ].controls["VALUE2"].reset();
  }

  this.addFilter["VALUE"] = "";
  this.addFilter["VALUE2"] = "";
}

  /**
     * Used to add new filter data dynamically
     * @param event -data
     */
  addNewFilter(event):void {
    // this.showFilterOption = false;
    setTimeout(() => {
        this.operationFilter.options.first.select();
    }, 200);

    this.addFilter.VALUE = "";
    this.addFilter.VALUE2 = "";

    if (this.FILTERSLIST) {
        this.FILTERSLIST.controls.splice(0, 1);
        //this.FILTERSLIST.reset();
    }

    if (event.FILTERTYPE == "DATE") {
        event.DATEFILTERLIST = this.dateFilter;
        event.DATEINNETFILTER = [
            {
                id: 1,
                value: "days",
            },
            {
                id: 2,
                value: "months",
            },
        ];
    }
    if (
        event.FILTERTYPE !== "DATE" &&
        event.FILTERTYPE !== "CURRENCY" &&
        event.FILTERTYPE !== "NUMBER"
    ) {
        event.DATEFILTERLIST = this.manullyOptionalFilter;
    }
    if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
        event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
            event.FILTERTYPE
        );
    }
    this.addFilter.FILTERTYPE = event.FILTERTYPE;

    // OPTIONAL FILTER
    this.FILTERSLIST = this.PASTREC.get(
        "FILTERSLIST"
    ) as FormArray;
    this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));
    const localFormIndex = this.FILTERSLIST.controls.length - 1;
    this.addFilter.OPERATION =
        event.FILTERTYPE == "DATE"
            ? "is in the last"
            : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
            ? "is equal to"
            : "contains";
    this._filterList = [event];
    this.cd.detectChanges();
}

/**
 * Create the Dynamic filter data
 * @param type -filter type
 * @returns -return the formGroup
 */
createOPFilter(type): FormGroup {
  return this._formBuilder.group({
      OPERATION:
          type == "DATE"
              ? "is in the last"
              : type == "CURRENCY" || type == "NUMBER"
              ? "is equal to"
              : "contains",
      VALUE: "",
      VALUE2: "",
      FILTERTYPE: type,
  });
}


  /**
     * Used to add the filter dynamicaly
     * @param IsfilterData -filter data
     * @param oprationalFilter -optional filter data
     * @param evt -evt data
     * @returns updated filter
     */
  async addfilter(IsfilterData, oprationalFilter, evt) {
    if (
        !this.TableColumnsService.checkValidation(
            this.addFilter.VALUE,
            this.addFilter.VALUE2,
            this.addFilter.OPERATION,
            IsfilterData.FILTERTYPE
        )
    ) {
        evt.preventDefault();
        evt.stopPropagation();
        return false;
    }
    await this.addDynamicFilter(IsfilterData);

    let sIndex = this.optionalFilters.length - 1;

    this.PASTREC.value.FILTERS[sIndex].VALUE =
        this.addFilter.VALUE;
    this.PASTREC.value.FILTERS[sIndex].VALUE2 =
        this.addFilter.VALUE2;
    this.PASTREC.value.FILTERS[sIndex].OPERATION =
        this.addFilter.OPERATION;
    this.PASTREC.value.FILTERS[sIndex].FILTERTYPE =
        IsfilterData.FILTERTYPE;
    IsfilterData.AdvancedFilter =
        this.PASTREC.value.FILTERS[sIndex];
    let advFilters = JSON.parse(
        localStorage.getItem("PASTREC_Advance_Filter")
    );
    if (advFilters) {
        this.saveAdvanceFilter = advFilters;
    }

    this.saveAdvanceFilter.push(IsfilterData);
    localStorage.setItem(
        "PASTREC_Advance_Filter",
        JSON.stringify(this.saveAdvanceFilter)
    );
    if (this.FILTERS.length == 1) {
        this.PASTREC.patchValue({
            FILTERS: [
                {
                    OPERATION: this.addFilter.OPERATION,
                    VALUE: this.addFilter.VALUE,
                    VALUE2: this.addFilter.VALUE2,
                    FILTERTYPE: IsfilterData.FILTERTYPE,
                },
            ],
        });
    } else {
        this.FILTERS.value.forEach((filterData, index) => {
            if (sIndex == index) {
                filterData = {
                    OPERATION: this.addFilter.OPERATION,
                    VALUE: this.addFilter.VALUE,
                    VALUE2: this.addFilter.VALUE2,
                    FILTERTYPE: IsfilterData.FILTERTYPE,
                };
            }
        });

        this.PASTREC.patchValue({
            FILTERS: this.FILTERS.value,
        });
    }

   
    this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

    $(".example-containerdata").css(
        "height",
        $(window).height() -
            ($("#filter-form-menu-wrap").height() + 265) +
            "px"
    );
}

/**
     * Used to add the dynamic filter value
     * @param event -UPdated data
     * @param type -filter type
     */
addDynamicFilter(event, type?):void {
  if (event.FILTERTYPE == "DATE") {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [
          {
              id: 1,
              value: "days",
          },
          {
              id: 2,
              value: "months",
          },
      ];
  }
  if (
      event.FILTERTYPE !== "DATE" &&
      event.FILTERTYPE !== "CURRENCY" &&
      event.FILTERTYPE !== "NUMBER"
  ) {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
  }
  if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
          event.FILTERTYPE
      );
  }
  event.FILTERAPPLY = true;
  if (!type) {
      this.optionalFilters.push(event);
  }
  this.FILTERS = this.PASTREC.get("FILTERS") as FormArray;
  this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));
}

/**
     * Apply the optional filter in dynamic format
     * @param FilterData -Filter data
     * @param isIndex -current filter index
     * @param OpretionIs -current operation value
     * @param evt -evt data
     * @returns optional filter
     */
applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any):boolean {
  let saveAdvancefilterData = JSON.parse(
      localStorage.getItem("PASTREC_Advance_Filter")
  );
  const field1Val =
      this.PASTREC.controls.FILTERS["controls"][isIndex]
          .controls["VALUE"].value;
  const field2Val =
      this.PASTREC.controls.FILTERS["controls"][isIndex]
          .controls["VALUE2"].value;
  const operationVal =
      this.PASTREC.controls.FILTERS["controls"][isIndex]
          .controls["OPERATION"].value;
  const filterTypeVal =
      this.PASTREC.controls.FILTERS["controls"][isIndex]
          .controls["FILTERTYPE"].value;

  if (
      !this.TableColumnsService.checkValidation(
          field1Val,
          field2Val,
          operationVal,
          filterTypeVal
      )
  ) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
  }

  let addData = JSON.parse(JSON.stringify(FilterData));
  addData.selectedFilter = OpretionIs.triggerValue;
  const applyFilterData = this.TableColumnsService.Optionalfilter(
      addData,
      isIndex,
      this.optionalDateFilterPayload,
      this.PASTREC
  );
  this.optionalDateFilterPayload = applyFilterData;
  this.TableColumnsService.setAdvanceFilter(
      saveAdvancefilterData,
      this.saveAdvanceFilter,
      isIndex,
      FilterData,
      field1Val,
      field2Val,
      operationVal,
      filterTypeVal,
      applyFilterData,
      "PASTREC"
  );
  this.doFilter(this.optionalDateFilterPayload);
}

 /**
     * used to start the filtering process
     * @param mainFilter -mainfilter data.
     */
 doFilter(mainFilter: any):void {
  const payloadData = JSON.parse(JSON.stringify(mainFilter));
  const tempPayload = Object.assign(
      JSON.parse(JSON.stringify(mainFilter))
  );

  const finalPayload = payloadData
      .filter((d) => d["value"] !== null)
      .map((e) => {
          if (e["value"] && e["value"] !== null) {
              delete e["value"].COLUMNID;
              return e["value"];
          }
      });
  this.finalPayloaddata = finalPayload;
  this.reload(finalPayload);
}

 /**
     * Used to reload the data
     * @param filterVal -filter data
     */
 reload(filterVal):void {
  this.LoadData(filterVal);
}
/**
     * Used to reset the all filter data.
     */
resetFilter():void {
  this.optionalFilterList.forEach((filter, index) => {
      filter.FILTERAPPLY = false;
  });

  localStorage.removeItem("PASTREC_Advance_Filter");
  this.saveAdvanceFilter = [];
  // ends here ~ reset FILTERAPPLY value
  this.optionalFilters = [];
  this.optionalDateFilterPayload = [];
  if (this.FILTERS) {
      this.FILTERS.reset();
  }
  this.LoadData();
}

  /**
     * Used to set the default advanced filter data.
     */
  async setDefaultAdvanceFilter():Promise<void> {
    let advanceFiltes = JSON.parse(
        localStorage.getItem("PASTREC_Advance_Filter")
    );
    if (advanceFiltes) {
        this.optionalDateFilterPayload = [];
        this.optionalFilters = advanceFiltes;
        await advanceFiltes.forEach(async (filterData, index) => {
            await this.addDynamicFilter(
                filterData.AdvancedFilter,
                "default"
            );

            this.FILTERS.value[index].OPERATION = await filterData
                .AdvancedFilter.OPERATION;
            this.FILTERS.value[index].FILTERTYPE = await filterData
                .AdvancedFilter.FILTERTYPE;
            this.FILTERS.value[index].VALUE = await filterData
                .AdvancedFilter.VALUE;
            this.FILTERS.value[index].VALUE2 = await filterData
                .AdvancedFilter.VALUE2;
            await this.optionalDateFilterPayload.push(
                filterData.FilterHeadings
            );

            await this.PASTREC.patchValue({
                FILTERS: this.FILTERS.value,
            });
        });
    }
}

 /**
     * Used to set the dynamic filter UI.
     * @param filter -filter data
     * @param index -filter index
     */
 setFilterUi(filter, index):void {
  this.selectedFilterIs =
      this.optionalDateFilterPayload[index]["value"]["OPERATION"];
}


 /**
     * Used to reset the Optional filter data.
     * @param event -event for getting the event data.
     * @param type -filter type.
     * @param isindex -updated index
     */
 resetOptionalFilter(event, type, isindex) {
  let index = this.optionalFilters.findIndex(
      (e) => e.COLUMNID == event.COLUMNID
  );
  event.FILTERAPPLY = false; //for filter hide/show from optional filter list.
  this.saveAdvanceFilter.splice(index, 1);
  this.optionalFilters.splice(index, 1);
  this.optionalDateFilterPayload.splice(isindex, 1);
  this.FILTERS.controls.splice(isindex, 1);
  this.PASTREC.value.FILTERS.splice(isindex, 1);
  const ArrayResetOptional = JSON.parse(
      JSON.stringify(this.PASTREC.value.FILTERS)
  );
  this.TableColumnsService.refrshAdvanceFilter(event, "PASTREC");
  const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
      if (this.optionalFilters[index]) {
          data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
      }
      return data;
  });

  const finalPayload = ArrayResetOptionalNew.filter(
      (d) => d["VALUE"] !== null && d["VALUE"] !== ""
  ).map((e) => {
      if (e["VALUE"] && e["VALUE"] !== "") {
          delete e.COLUMNID;
          if (e.FILTERTYPE == "DATE") {
              let date1 = e.VALUE;
              let date2 = e.VALUE2;

              let date1Split = date1.split("T");
              if (date1Split.length > 1) {
                  date1 =
                      e && e.VALUE !== ""
                          ? moment(e.VALUE).format("DD/MM/YYYY")
                          : "";
              }

              let date2Split = date2.split("T");
              if (date2Split.length > 1) {
                  date2 =
                      e && e.VALUE2 !== "" && e.OPERATION == "is between"
                          ? moment(e.VALUE2).format("DD/MM/YYYY")
                          : "";
              }

              if (e.OPERATION == "is in the last") {
                  e.VALUE = e.VALUE;
                  e.VALUE2 = e.VALUE2;
              } else {
                  e.VALUE = date1;
                  e.VALUE2 = date2;
              }
          }

          if (e.VALUE2 == null) {
              e.VALUE2 = "";
          }
          return e;
      }
  });

  this.finalPayloaddata = finalPayload;
  this.reload(finalPayload);
}

}
