<div class=" one " fxLayout="column" [formGroup]="MatterPropertyDetailsForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.PARISH,'has-warning':errorWarningData.Warning?.PARISH}">
            <mat-label>Parish </mat-label>
            <!-- <mat-select formControlName="PARISH" matTooltip="{{(toolTipList)?toolTipList.PARISH?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <mat-option *ngFor="let ParishLookupDataVal of ParishLookupData"
                    value="{{ParishLookupDataVal.LOOKUPGUID}}">
                    {{ParishLookupDataVal.LOOKUPFULLVALUE}}</mat-option>
            </mat-select> -->

            <mat-label>Parish</mat-label>
            <input matInput formControlName="PARISH" [matAutocomplete]="auto"
                matTooltip="{{(toolTipList)?toolTipList.PARISH?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"
                (keyup.enter)="parishDetectChanges($event,'Parish')" (keyup)="parishDetectChanges($event ,'Parish')">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let ParishLookupDataVal of filteredOptions"
                    value="{{ParishLookupDataVal.LOOKUPFULLVALUE}}" (click)="setId(ParishLookupDataVal,'Parish')">
                    {{ParishLookupDataVal.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>

            <div *ngIf="testBool && toolTipList.PARISH" class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.PARISH.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PARISH?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="pr-4">
        <mat-form-field appearance="outline" fxFlex="100"
            [ngClass]="{'has-error':errorWarningData.Error?.LOCALITY,'has-warning':errorWarningData.Warning?.LOCALITY}">
            <!-- <mat-label>Locality </mat-label> -->
            <!-- <input matInput formControlName="LOCALITY"> -->
            <!-- <mat-select formControlName="LOCALITY" matTooltip="{{(toolTipList)?toolTipList.LOCALITY?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <mat-option *ngFor="let LocalityLookupDataVal of LocalityLookupData"
                    value="{{LocalityLookupDataVal.LOOKUPGUID}}">
                    {{LocalityLookupDataVal.LOOKUPFULLVALUE}}</mat-option>
            </mat-select> -->

            <mat-label>Locality</mat-label>
            <input matInput formControlName="LOCALITY" [matAutocomplete]="auto1"
                matTooltip="{{(toolTipList)?toolTipList.LOCALITY?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"
                (keyup.enter)="parishDetectChanges($event ,'Locality')"
                (keyup)="parishDetectChanges($event ,'Locality')">
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option *ngFor="let LocalityLookupDataVal of LocalityFilteredOptions"
                    value="{{LocalityLookupDataVal.LOOKUPFULLVALUE}}" (click)="setId(LocalityLookupDataVal,'Locality')">
                    {{LocalityLookupDataVal.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>

            <div *ngIf="testBool && toolTipList.LOCALITY" class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.LOCALITY.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LOCALITY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="pr-4">
        <mat-form-field appearance="outline" fxFlex="100"
            [ngClass]="{'has-error':errorWarningData.Error?.COUNCIL,'has-warning':errorWarningData.Warning?.COUNCIL}">
            <!-- <mat-label>Council</mat-label> -->
            <!-- <input matInput formControlName="COUNCIL"> -->
            <!-- <mat-select formControlName="COUNCIL" matTooltip="{{(toolTipList)?toolTipList.COUNCIL?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
                <mat-option *ngFor="let CouncilLookupsDataVal of CouncilLookupsData"
                    value="{{CouncilLookupsDataVal.LOOKUPGUID}}">
                    {{CouncilLookupsDataVal.LOOKUPFULLVALUE}}</mat-option>
            </mat-select> -->

            <mat-label>Council</mat-label>
            <input matInput formControlName="COUNCIL" [matAutocomplete]="auto2"
                matTooltip="{{(toolTipList)?toolTipList.COUNCIL?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"
                (keyup.enter)="parishDetectChanges($event ,'Council')" (keyup)="parishDetectChanges($event ,'Council')">
            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                <mat-option *ngFor="let CouncilLookupsDataVal of CouncilFilteredOptions"
                    value="{{CouncilLookupsDataVal.LOOKUPFULLVALUE}}" (click)="setId(CouncilLookupsDataVal,'Council')">
                    {{CouncilLookupsDataVal.LOOKUPFULLVALUE}}
                </mat-option>
            </mat-autocomplete>

            <div *ngIf="testBool && toolTipList.COUNCIL" class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.COUNCIL.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COUNCIL?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.COUNCILPROPERTYNUMBER,'has-warning':errorWarningData.Warning?.COUNCILPROPERTYNUMBER}">
            <mat-label>Council Property No </mat-label>
            <input matInput formControlName="COUNCILPROPERTYNUMBER"
                matTooltip="{{(toolTipList)?toolTipList.COUNCILPROPERTYNUMBER?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <div *ngIf="testBool && toolTipList.COUNCILPROPERTYNUMBER" class="tooltip-wrap"><input id="myInput" readonly
                    disabled value="{{(toolTipList)?toolTipList.COUNCILPROPERTYNUMBER.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COUNCILPROPERTYNUMBER?.COPYVALUE:'')">file_copy</mat-icon>
            </div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.SPI,'has-warning':errorWarningData.Warning?.SPI}">
            <mat-label>SPI </mat-label>
            <input matInput name="SPI" formControlName="SPI" matTooltip="{{(toolTipList)?toolTipList.SPI?.HOVER:''}}"
                [matTooltipPosition]="toolTipPostion">
            <div *ngIf="testBool && toolTipList.SPI" class="tooltip-wrap"><input id="myInput" readonly disabled
                    value="{{(toolTipList)?toolTipList.SPI.COPYVALUE:''}}"><mat-icon
                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SPI?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</div>