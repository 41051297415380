<div class="one" fxLayout="column" [formGroup]="matterLeasingForm">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Formula </mat-label>
            <textarea matInput formControlName="RENTFORMULA" matTooltip="{{(toolTipList)?toolTipList.RENTFORMULA?.HOVER:''}}"
            toolTipPostion ="above"></textarea>
             <div  *ngIf="testBool && toolTipList.RENTFORMULA" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTFORMULA.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTFORMULA?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Frequency </mat-label>
            <input matInput formControlName="RENTREVIEWFREQUENCY" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWFREQUENCY?.HOVER:''}}"
            toolTipPostion ="above">
             <div  *ngIf="testBool && toolTipList.RENTREVIEWFREQUENCY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWFREQUENCY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWFREQUENCY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Clause </mat-label>
            <input matInput formControlName="RENTREVIEWCLAUSE" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWCLAUSE?.HOVER:''}}"
            toolTipPostion ="above">
             <div  *ngIf="testBool && toolTipList.RENTREVIEWCLAUSE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWCLAUSE.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWCLAUSE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Nature </mat-label>
            <input matInput formControlName="RENTREVIEWFREQUENCY" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWFREQUENCY?.HOVER:''}}"
            toolTipPostion ="above">
             <div  *ngIf="testBool && toolTipList.RENTREVIEWFREQUENCY" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWFREQUENCY.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWFREQUENCY?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Fix Amount </mat-label>
            <input matInput formControlName="RENTREVIEWFIXEDAMOUNT" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWFIXEDAMOUNT?.HOVER:''}}"
            toolTipPostion ="above" [value]="matterLeasingForm.get('RENTREVIEWFIXEDAMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="." style="text-align: right;" class="sideText" [leadZero]="true">
             <div  *ngIf="testBool && toolTipList.RENTREVIEWFIXEDAMOUNT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWFIXEDAMOUNT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWFIXEDAMOUNT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Current CPI </mat-label>
            <input matInput formControlName="RENTREVIEWCURRENTCPI" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWCURRENTCPI?.HOVER:''}}"
            toolTipPostion ="above">
             <div  *ngIf="testBool && toolTipList.RENTREVIEWCURRENTCPI" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWCURRENTCPI.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWCURRENTCPI?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Fixed Percent </mat-label>
            <input matInput formControlName="RENTREVIEWFIXEDPERCENT" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWFIXEDPERCENT?.HOVER:''}}"
            toolTipPostion ="above">
             <div  *ngIf="testBool && toolTipList.RENTREVIEWFIXEDPERCENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWFIXEDPERCENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWFIXEDPERCENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
            <mat-label>Market Rent Percent </mat-label>
            <input matInput formControlName="RENTREVIEWMARKETRENTPERCENT" matTooltip="{{(toolTipList)?toolTipList.RENTREVIEWMARKETRENTPERCENT?.HOVER:''}}"
            toolTipPostion ="above"> 
             <div  *ngIf="testBool && toolTipList.RENTREVIEWMARKETRENTPERCENT" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.RENTREVIEWMARKETRENTPERCENT.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RENTREVIEWMARKETRENTPERCENT?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>


</div>