<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="ChroneItem" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Format</mat-label>
                        <mat-select #value name="selected" (selectionChange)="FormatChange($event.value)" [(ngModel)]="ChronologyADD.FORMAT" formControlName="Format">
                            <mat-option value="1">dd mmm yyyy</mat-option>
                            <mat-option value="2">dd mmm yyyy - dd mmm yyyy </mat-option>
                            <mat-option value="3">mmm yyyy
                            </mat-option>
                            <mat-option value="4">mmm yyyy - mmm yyyy</mat-option>
                            <mat-option value="5">yyyy</mat-option>
                            <mat-option value="6">yyyy - yyyy</mat-option>
                            <mat-option value="7">dd mmm yyyy hh:mm</mat-option>
                            <mat-option value="8">dd mmm yyyy hh:mm - hh:mm</mat-option>
                        </mat-select>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FORMAT?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.FORMAT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.DATEFROM,'has-warning':errorWarningData.Warning?.DATEFROM}">
                        <mat-label>Date From</mat-label>
                        <input (dateInput)="choosedDateForm('input', $event)" (dateChange)="choosedDateForm('change', $event)" matInput [(ngModel)]="ChronologyADD.DATEFROM" formControlName="dateForm" [matDatepicker]="picker" matTooltip="{{(toolTipList)?toolTipList.DATEFROM?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATEFROM?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATEFROM?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="34" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.TIMEFROM,'has-warning':errorWarningData.Warning?.TIMEFROM}">
                        <mat-label>Time From</mat-label>
                        <input matInput [(ngModel)]="ChronologyADD.TIMEFROM" formControlName="timeForm" type="time" matTooltip="{{(toolTipList)?toolTipList.TIMEFROM?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TIMEFROM?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.TIMEFROM?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example" fxFlex="33" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.DATETO,'has-warning':errorWarningData.Warning?.DATETO}">
                        <mat-label>Date To</mat-label>
                        <input [(ngModel)]="ChronologyADD.DATETO" (dateInput)="choosedDateTo('input', $event)" (dateChange)="choosedDateTo('change', $event)" matInput formControlName="dateto" [matDatepicker]="picker2" disabled matTooltip="{{(toolTipList)?toolTipList.DATETO?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">  
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATETO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.DATETO?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field class="example" appearance="outline" fxFlex="33" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.TIMETO,'has-warning':errorWarningData.Warning?.TIMETO}">
                        <mat-label>Time To</mat-label>
                        <input [(ngModel)]="ChronologyADD.TIMETO" matInput formControlName="timeto" type="time" matTooltip="{{(toolTipList)?toolTipList.TIMETO?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TIMETO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TIMETO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">



                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Reference</mat-label>
                        <input matInput [(ngModel)]="ChronologyADD.REFERENCE" formControlName="Reference" matTooltip="{{(toolTipList)?toolTipList.REFERENCE?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"(click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.REFERENCE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.REFERENCE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Brief Pg#</mat-label>
                        <input matInput [(ngModel)]="ChronologyADD.BRIEFPAGENO" formControlName="brif" matTooltip="{{(toolTipList)?toolTipList.BRIEFPAGENO?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BRIEFPAGENO?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BRIEFPAGENO?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>


                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Witnesses</mat-label>
                        <input matInput [(ngModel)]="ChronologyADD.WITNESSES" formControlName="Witnesses" matTooltip="{{(toolTipList)?toolTipList.WITNESSES?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.WITNESSES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.WITNESSES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>


                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>COMMENT</mat-label>
                        <textarea [(ngModel)]="ChronologyADD.COMMENT" formControlName="COMMENT" matInput matTooltip="{{(toolTipList)?toolTipList.COMMENT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion"></textarea>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                    <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-checkbox [(ngModel)]="ChronologyADD.PRIVILEGED" formControlName="Privileged">Privileged
                    </mat-checkbox>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <!-- <mat-form-field class="example" appearance="outline" fxFlex="33" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}">
                        <mat-label>Text</mat-label>
                        <input matInput [(ngModel)]="ChronologyADD.ADDITIONALTEXT" formControlName="text" matTooltip="{{(toolTipList)?toolTipList.ADDITIONALTEXT:''}}" [matTooltipPosition]="toolTipPostion" >
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>

                    </mat-form-field> -->

                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Topic</mat-label>
                        <input matInput [(ngModel)]="ChronologyADD.TOPIC" formControlName="topic" matTooltip="{{(toolTipList)?toolTipList.TOPIC?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TOPIC?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.TOPIC?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Event Agreed</mat-label>
                        <!-- <mat-select #value name="selected" (selectionChange)="Event()" [(ngModel)]="ChronologyADD.EVENTAGREED" formControlName="eventAgereed"  matTooltip="{{(toolTipList)?toolTipList.EVENTAGREED:''}}" [matTooltipPosition]="toolTipPostion">
                            <mat-option value="1">Agreed</mat-option>
                            <mat-option value="2">Disputed by Plaintiff</mat-option>
                            <mat-option value="3">Disputed by Defendant</mat-option>
                            <mat-option value="4">Uncertain - required investigation</mat-option>
                        </mat-select> -->

                        <mat-select #value name="selected" (ngModelChange)="Event1($event)" [(ngModel)]="ChronologyADD.EVENTAGREED" formControlName="eventAgereed">
                            <mat-option *ngFor="let user of userList" value="{{user.LOOKUPGUID}}">{{user.LOOKUPFULLVALUE}}
                            </mat-option>
                        </mat-select>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EVENTAGREED?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.EVENTAGREED?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Document Website</mat-label>
                        <input [(ngModel)]="ChronologyADD.DOCUMENTNAME" formControlName="document" matInput matTooltip="{{(toolTipList)?toolTipList.DOCUMENTNAME?.HOVER:''}}" [matTooltipPosition]="toolTipPostion">
                        <mat-icon matSuffix [matMenuTriggerFor]="menu" fxFlex="5">
                            <img class="seticon" src="assets/icons/web_app/folder.ico" alt="" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DOCUMENTNAME?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.DOCUMENTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                    <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
                        <button (click)="documentRegPopup()" mat-menu-item>Choose from Document Register</button>
                        <button (click)="uploader.click()" mat-menu-item>Choose from device</button>
                    </mat-menu>
                    <!-- File Upload hiddenfiled -->
                    <input type="file" hidden #uploader (change)="selectDoc($event)">
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4"
                    [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}">
                        <mat-label>Text</mat-label>
                        <textarea matInput [(ngModel)]="ChronologyADD.ADDITIONALTEXT" formControlName="text" matTooltip="{{(toolTipList)?toolTipList.ADDITIONALTEXT?.HOVER:''}}" [matTooltipPosition]="toolTipPostion" ></textarea>
                        <!-- <input matInput class ="expand" [(ngModel)]="ChronologyADD.ADDITIONALTEXT" formControlName="text" matTooltip="{{(toolTipList)?toolTipList.ADDITIONALTEXT:''}}" [matTooltipPosition]="toolTipPostion" > -->
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALTEXT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDITIONALTEXT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>

                    </mat-form-field>
                </div>

            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="ChroneItemSave()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="ChroneItemSave()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="ChroneItemSave()" [disabled]="isspiner" *ngIf="action !== 'new' && action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>



        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>