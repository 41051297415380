<div class="sort_main_sidebar">
    <!-- SIDEBAR HEADER -->
    <div class="header p-24 side_header" fxLayout="column" fxLayoutAlign="space-between">
        <h3 class="one"><strong class="x">Protected Trust Details</strong></h3>
    </div>
    <!-- / SIDEBAR HEADER -->
    <!-- SIDEBAR CONTENT -->
    <div class="two">
        <div class="content" fusePerfectScrollbar>
            <div class="file-details" [@animate]="{value:'*',params:{delay:'200ms'}}">
                    <span>
                        <table>
                            <tr class="unbilled">
                                <th>Amount</th>
                                <td>{{ProtectedTrustList?.AMOUNT | toFixedAmount:true}}</td>
                            </tr>
                            <tr class="unbilled">
                                <th>AmountUsed</th>
                                <td>{{ProtectedTrustList?.AMOUNTUSED | toFixedAmount:true}}</td>
                            </tr>

                            <tr class="unbilled">
                                <th>Matter Name</th>
                                <td> </td>
                            </tr>
                            <tr class="unbilled">
                                <th>Protected Date</th>
                                <td>{{ProtectedTrustList?.PROTECTEDDATE}} </td>
                            </tr>
                            <tr class="unbilled">
                                <th>Reason</th>
                                <td>{{ProtectedTrustList?.REASON}}</td>
                            </tr>
                        </table>
                       
                    </span>
                
            </div>
        </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
</div>