<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div timeentry_popup select_metter_main_div " cdkDrag>
    <h2 cdkDragHandle mat-dialog-title> Split Item
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main main-flex">
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="splititemForm" id="splititemForm"
            [formGroup]="splititemForm">
            <div fxLayout="row" class="top-input" fxLayoutAlign="start center" fxFlex="1 0 auto">
                
                    <mat-form-field  appearance="outline" fxFlex="16.66" class="pr-4" appearance="outline">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date"   formControlName="DateValue"  >
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                
                <mat-form-field appearance="outline" fxFlex="14" class="pr-4">
                    <mat-label>Time of day</mat-label>
                    <input readonly class="sideText" matInput  formControlName="TimeOfDay" [disabled]="isdisable">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="14" class="pr-4">
                    <mat-label>Fee Earner</mat-label>
                    <input  readonly class="sideText" matInput  formControlName="FeeEarner">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="14" class="pr-4">
                    <mat-label>Duration </mat-label>
                    <input  readonly class="sideText" matInput formControlName="Duration">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="13" class="pr-4">
                    <input  readonly class="sideText" matInput  formControlName="QuentityType">
                     <mat-label>Type </mat-label>
                    <!-- <input class="sideText" matInput currencyMask > -->
                    <!-- <mat-select readonly formControlName="QuentityType">
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="2">2</mat-option>
                        <mat-option [value]="3">3</mat-option>
                    </mat-select> -->
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="15" class="pr-4">
                    <mat-label>Charge EX-GST</mat-label>
                    <input  readonly class="sideText" matInput  formControlName="ExGst">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="15" class="pr-4">
                    <mat-label>Charge Inc-GST</mat-label>
                    <input readonly class="sideText" matInput  formControlName="IncGst">
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                    <mat-label>Invoice Text</mat-label>
                    <textarea readonly [disabled]="isdisable" type="text" matInput formControlName="InvoiceText" matInput></textarea>
                    <!-- <textarea readonly type="text" matInput [matAutocomplete]="auto" formControlName="InvoiceText"></textarea> -->
                    <!-- <mat-autocomplete #auto="matAutocomplete" [panelWidth]="200"
                        (optionSelected)="LookupsChange($event.option.value)">
                        <mat-option *ngFor="let Lookups of filteredOptions | async" [value]="Lookups.LOOKUPFULLVALUE">
                            {{Lookups.LOOKUPFULLVALUE}}
                        </mat-option>
                    </mat-autocomplete> -->
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" class=" pr-4">
                    <mat-label>Notes</mat-label>
                    <textarea readonly cdkTextareaAutosize formControlName="Notes" cdkAutosizeMinRows="2.3"
                        cdkAutosizeMaxRows="2.3" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-label>How Many Splits ? (Including the Orignal)</mat-label>
                <mat-form-field appearance="outline" fxFlex="20" class="pr-4">
                    <!-- <mat-label>Fee Earnar</mat-label> -->
                    <input class="sideText"  [min] ='2' [max]='50' #event (focusout)="calcTotalINGST($event)"   type="number" matInput currencyMask formControlName="SplitPart">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="20" class="pr-4">
                    <!-- <mat-label>Fee Earnar</mat-label> -->
                    <input class="sideText" matInput currencyMask formControlName="Val1">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="20" class="pr-4">
                    <!-- <mat-label>Fee Earnar</mat-label> -->
                    <input class="sideText" matInput currencyMask formControlName="Val2">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="20" class="pr-4">
                    <!-- <mat-label>Fee Earnar</mat-label> -->
                    <input class="sideText" matInput currencyMask formControlName="Val3">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" fxFlex="1 0 auto">
               <!-- <mat-label class="lable_class_matter_no" formControlName ="matterautoVal_1"><b>Matter no {{matterautoVal_1}} ,</b></mat-label> -->
             <mat-form-field appearance="outline" fxFlex="10" class="pr-4">
                <mat-label> Selected Matter</mat-label>
                <input readonly matInput  placeholder="Selected Matter" formControlName="matterautoVal1">
                
            </mat-form-field>  

                <mat-form-field appearance="outline" fxFlex="90" class="pr-4" >
                    <mat-label>Matters</mat-label>
                    <mat-chip-list #chipList aria-label="Matter selection">
                        <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)">
                            {{matterdata| slice:0:40}}<ng-container *ngIf="matterdata.length>50">...</ng-container>
                            <button  matChipRemove class="canclebutton">
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <!-- [formControl]="fruitCtrl"  -->
                        </mat-chip>
                        <input (keyup)="onKeyUp($event)" placeholder="New Matter..."
                            formControlName="matterautoVal" #MatterInput [matAutocomplete]="auto"
                            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <!-- {{data.SHORTNAME}}  -->
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click) ="selectedmatterguid(data)">
                            <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->
                            {{data.SELECTEDDESCRIPTION}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </form>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">

        <button mat-raised-button color="accent"  class="mat-accent-blue" [disabled]="isspiner" (click)="SaveClickTimeEntry()">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>