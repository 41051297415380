import { TooltipService } from '@_services/tooltip.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @Input() contactForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() allToolTip:any;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  constructor(public tooltipService:TooltipService) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

}
