<div class="popup_main_div corres_popup_main">
    <h2 cdkDragHandle mat-dialog-title>Select Matter Contact  <span *ngIf="currentMatterNum != ''">for {{currentMatterNum}}</span> <span *ngIf="currentInvoiceNum != ''">for {{currentInvoiceNum}}</span> 
        <button class="btn" mat-icon-button (click)="ondialogcloseClick()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="one">
            <div class="example-containerdata">
                <table mat-table #table [dataSource]="getDataForTable" [@animateStagger]="{value:'50'}" matSort class="mat-elevation-z8">
                    <!-- <ng-container *ngIf="isFrom =='send email'"> -->
                    <ng-container  matColumnDef="select">
                        <th  mat-header-cell *matHeaderCellDef>
                            <mat-checkbox class="table_checkbx"
                            (change)="$event ? masterToggle() : null;helloFunction();"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox [ngClass]="row.PERSONEMAIL =='' ? 'checkdis_class':'' " [disabled]="row.PERSONEMAIL == ''" class="table_checkbx"
                            (click)="$event.stopPropagation();"
                            (change)="$event ? selection.toggle(row) : null;helloFunction();"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                <!-- </ng-container> -->
                    <!-- Job Title Column -->
                    <ng-container matColumnDef="contact">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate">
                                {{row.CONTACTNAME}}
                            </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate">
                                {{row.PERSONEMAIL}}
                            </p>
                        </td>
                    </ng-container>
                    <!-- Company Column -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="company text-truncate">
                                {{row.TYPENAME }}
                            </p>
                        </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <!-- sticky: true" -->
                    <tr mat-row [style.background]="highlightedRows == row.MATTERCONTACTGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumns;" [ngClass]="highlightedRows == row.MATTERCONTACTGUID ? 'row-text-colore' : ''" class="contact" (click)="editContact(row);highlightedRows = row.MATTERCONTACTGUID"
                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
            </div>
            <mat-paginator  [pageSize]="pageSize?.contact_corres_details"  [pageSizeOptions]="[100, 50 ,20,10]" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button mat-dialog-close color="accent" (click)="selectButton()" *ngIf="data.callType=='edit'">Select</button>
        <button mat-raised-button mat-dialog-close color="accent" (click)="selctbtn()" [mat-dialog-close]="currentRow" *ngIf="data.callType=='select'">Select</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>