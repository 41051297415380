import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker'; 

@Component({
  selector: 'app-property-strata',
  templateUrl: './property-strata.component.html',
  styleUrls: ['./property-strata.component.scss']
})
export class PropertyStrataComponent implements OnInit {
  @Input() MatterPropertyDetailsForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() testBool;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  constructor(public datepipe: DatePipe, public tooltipService: TooltipService) { }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  /**
   * This function is used to get the resolution date picker
   */
  ResolutionDatepicker(type: string, event: MatDatepickerInputEvent<Date>) {
    this.MatterPropertyDetailsForm.controls['SPECIALRESOLUTIONDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }

  /**
   * This function is used to get the text data value. 
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    //  navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    
    navigator.clipboard.writeText(event)
  
}
}
