<button mat-menu-item id="pastRecouncilliationRefresh" (click)="pastRecouncilliationRefresh()"
    style="display: none;"></button>
    <ng-container *ngIf="COLOR$ | async "></ng-container>
<div id="PastRecouncil" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
    <div class="content" fxLayoutAlign="center">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div sticky_search_div responsive_auto">
                <form fxLayout="column" fxFlex="1 0 auto">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <h2 fxFlex="25">{{chartAccountDetail.name}}</h2>
                        <span class="appCues-blankSpace">
                            <a href="javascript:void(0);">Link</a>
                        </span>
                    </div>
                </form>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center matter-side-div">
                <form [formGroup]="PASTREC" class="align-center display-flex filter-menuwrap form-div-side" id="filter-form-menu-wrap">
                    <div class="align-center display-flex flex-wrap filter-menublock">
                        <div class="add-filterwrap">
                            <button mat-button [matMenuTriggerFor]="menuFilter" class="filter-button"
                        (click)="backToFilter('optional-Discount');showFiltersList=true;"><img [src]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'assets/icons/filter-icon.png':'assets/icons/filter-icon-barrister.png'"
                            alt="Filter" class="filter-icon" />
                        Filters</button>
                        <mat-icon class="icon-btn close-btn" (click)="resetFilter()" *ngIf="optionalFilters?.length > 0">close</mat-icon>
                        <mat-icon class="icon-btn" (click)="pastRecouncilliationRefresh()" >refresh</mat-icon>
                        
                        <mat-menu #menuFilter="matMenu" panelClass="custom" class="filterscroll-wrap">
                            <div class="filterscroll test">
                                <ng-container *ngFor="let optionalFilterData of optionalFilterList">
                                    <div *ngIf="optionalFilterData.FILTERTYPE != 'HIDDEN'">
                                        <button class="addbutton" [ngClass]="COLOR" *ngIf="!optionalFilterData.FILTERAPPLY && showFiltersList"
                                            (click)="preventDefault($event);activeCurrentFilter('optional-Discount',optionalFilterData);addNewFilter(activeOptionalFilter);showFiltersList=false;">
                                            <mat-icon>add_circle</mat-icon>{{optionalFilterData.DESCRIPTION}}
                                        </button>
                                        <div class="menu-submenu" id="optional-Discount" [ngClass]="COLOR">
                                            <h5 class="filter-title" *ngIf="activeOptionalFilter">
                                                <mat-icon class="back-icon"
                                                    (click)="backToFilter('optional-Discount');preventDefault($event);showFiltersList=true;">arrow_back_ios
                                                </mat-icon>Filter by {{activeOptionalFilter.DESCRIPTION}}
                                            </h5>
                                            <div *ngIf="activeOptionalFilter">
                                                <ng-container formArrayName="FILTERSLIST" >
                                                    <div *ngFor="let filter of _filterList; let j =index;" class="position-relative">
                                                        <ng-container [formGroupName]="j">
                                                            <!-- <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5> -->
                                                            <mat-form-field appearance="outline" class="width-full"
                                                                (click)="preventDefault($event)">
                                                                <mat-select (selectionChange)="optionalDateFilter($event , filter , 'default' , -1)"
                                                                    name="OPERATION" [(ngModel)]="addFilter.OPERATION"
                                                                    formControlName="OPERATION"  #operationFilter>
                                                                    <mat-option *ngFor="let d of filter.DATEFILTERLIST; "
                                                                        [value]="d.value" >{{d.description}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                                </span>
                                                                <mat-form-field class="width-full" appearance="outline"
                                                                    (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                    <input matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                    [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required appNumberMask
                                                                    >
                                                                </mat-form-field>

                                                                <mat-form-field class="width-full" appearance="outline"
                                                                    (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                    <input matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                    [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required

                                                                    currencyMask>
                                                                </mat-form-field>
                                                            </div>

                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                                </span>
                                                                <mat-form-field class="width-full" appearance="outline"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput placeholder="" name="VALUE" formControlName="VALUE"
                                                                    [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                </mat-form-field>
                                                            </div>


                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                                </span>
                                                                <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                    <mat-form-field class="set-date" appearance="outline">
                                                                        <input matInput placeholder="" name="VALUE"
                                                                            formControlName="VALUE" [(ngModel)]="addFilter.VALUE"  [(value)]="addFilter.VALUE" required appNumberMask >
                                                                    </mat-form-field>
                                                                    <span class="middle-caption">and</span>
                                                                    <mat-form-field class="set-date" appearance="outline">
                                                                        <input matInput placeholder="" name="VALUE2"
                                                                            formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required appNumberMask >
                                                                    </mat-form-field>
                                                                </div>

                                                                <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                    <mat-form-field class="set-date" appearance="outline">
                                                                        <input matInput placeholder="" name="VALUE"
                                                                            formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required
                                                                            currencyMask>
                                                                    </mat-form-field>
                                                                    <span class="middle-caption">and</span>
                                                                    <mat-form-field class="set-date" appearance="outline">
                                                                        <input matInput placeholder="" name="VALUE2"
                                                                            formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required
                                                                            currencyMask>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                                </span>
                                                                <div class="display-flex align-center">
                                                                    <mat-form-field appearance="outline" class="set-date"
                                                                        (click)="preventDefault($event)">
                                                                        <input matInput [matDatepicker]="picker6" name="VALUE"
                                                                            formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required>
                                                                        <mat-datepicker-toggle matSuffix [for]="picker6">
                                                                        </mat-datepicker-toggle>
                                                                        <mat-datepicker #picker6></mat-datepicker>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                <span class="back-tick">
                                                                    <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                                </span>
                                                                <div class="display-flex align-center field-box"
                                                                    (click)="preventDefault($event)">
                                                                    <mat-form-field class="set-date" appearance="outline">
                                                                        <input matInput placeholder="" name="VALUE"
                                                                            formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required appNumberMask>
                                                                    </mat-form-field>
                                                                    <mat-form-field appearance="outline" class="set-date date-dropdown-selector">
                                                                        <mat-select  formControlName="VALUE2" [(value)]="addFilter.VALUE2" [(ngModel)]="addFilter.VALUE2" required>
                                                                            <mat-option value="days">Days</mat-option>
                                                                            <mat-option value="month">Month </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                            <div class="display-flex align-center"
                                                                *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                <mat-form-field appearance="outline" class="set-date"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                                        formControlName="VALUE" [(ngModel)]="addFilter.VALUE" [(value)]="addFilter.VALUE" required  (keydown)="preventDefaultTab($event)">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker></mat-datepicker>
                                                                </mat-form-field>
                                                                <span class="middle-caption">and</span>
                                                                <mat-form-field appearance="outline" class="set-date"
                                                                    (click)="preventDefault($event)">
                                                                    <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                                        formControlName="VALUE2" [(ngModel)]="addFilter.VALUE2" [(value)]="addFilter.VALUE2" required  (keydown)="preventDefaultTab($event)">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker2></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>

                                                            <button mat-raised-button color="primary" class="width-full accent"
                                                                (click)="addfilter(filter , operationFilter, $event)">Apply</button>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-menu>
                    </div>

                    <ng-container formArrayName="FILTERS" [ngClass]="COLOR">
                        <div *ngFor="let filter of optionalFilters; let i =index;" class="position-relative">
                            <ng-container [formGroupName]="i">
                                <mat-icon class="badge-ic" (click)="resetOptionalFilter(filter , filter.FILTERTYPE , i)">
                                    cancel_circle
                                </mat-icon>
                                <button mat-button [matMenuTriggerFor]="menuCreatedDate_i" class="buttonwith-text" (click)="setFilterUi(filter,i)">
                                    {{filter.DESCRIPTION}}<span
                                        class="filter-applytext display-flex align-center">{{(optionalDateFilterPayload[i] &&
                                        optionalDateFilterPayload[i]['value'] !==
                                        null)?optionalDateFilterPayload[i]['value'].VALUE : ''}} {{(optionalDateFilterPayload[i]
                                        && optionalDateFilterPayload[i]['value'] !== null &&
                                        optionalDateFilterPayload[i]['value'].VALUE2 !== '' && optionalDateFilterPayload[i]['value'].VALUE2 !== null)?' - ' +
                                        optionalDateFilterPayload[i]['value'].VALUE2 : ''}}<mat-icon class="rightdownarrow">
                                            keyboard_arrow_down</mat-icon></span>
                                </button>
                                <mat-menu #menuCreatedDate_i="matMenu">
                                    <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5>
                                    <mat-form-field appearance="outline" class="width-full" (click)="preventDefault($event)">
                                        <mat-select (selectionChange)="optionalDateFilter($event , filter , 'active' , i)" name="OPERATION"
                                            formControlName="OPERATION" #Datefilter>
                                            <mat-option *ngFor="let d of filter.DATEFILTERLIST " [value]="d.value">{{d.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="display-flex align-center"
                                        *ngIf="selectedFilterIs !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                        <span class="back-tick">
                                            <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                        </span>
                                        <mat-form-field class="width-full" appearance="outline" *ngIf="filter.FILTERTYPE == 'NUMBER'"
                                            (click)="preventDefault($event)">
                                            <input matInput placeholder="" name="VALUE" formControlName="VALUE" required appNumberMask>
                                        </mat-form-field>

                                        <mat-form-field class="width-full" appearance="outline" *ngIf="filter.FILTERTYPE == 'CURRENCY'"
                                            (click)="preventDefault($event)">
                                            <input matInput placeholder="" name="VALUE" formControlName="VALUE" required currencyMask>
                                        </mat-form-field>
                                    </div>
                                    <div class="display-flex align-center"
                                        *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                        <span class="back-tick">
                                            <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                        </span>
                                        <mat-form-field class="width-full" appearance="outline"
                                            (click)="preventDefault($event)">
                                            <input matInput placeholder="" name="VALUE" formControlName="VALUE" required>
                                        </mat-form-field>
                                    </div>
                                       <div class="display-flex align-center"
                                        *ngIf="selectedFilterIs == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                        <span class="back-tick">
                                            <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                        </span>
                                        <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                            <mat-form-field class="set-date" appearance="outline">
                                                <input matInput placeholder="" name="VALUE" formControlName="VALUE" required appNumberMask>
                                            </mat-form-field>
                                            <span class="middle-caption">and</span>
                                            <mat-form-field class="set-date" appearance="outline">
                                                <input matInput placeholder="" name="VALUE2" formControlName="VALUE2" required appNumberMask>
                                            </mat-form-field>
                                        </div>
                                        <div class="display-flex align-center" (click)="preventDefault($event)" *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                            <mat-form-field class="set-date" appearance="outline">
                                                <input matInput placeholder="" name="VALUE" formControlName="VALUE" required currencyMask>
                                            </mat-form-field>
                                            <span class="middle-caption">and</span>
                                            <mat-form-field class="set-date" appearance="outline">
                                                <input matInput placeholder="" name="VALUE2" formControlName="VALUE2" required currencyMask>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="display-flex align-center"
                                        *ngIf="filter.FILTERTYPE == 'DATE' && selectedFilterIs && (selectedFilterIs !== 'is in the last' && selectedFilterIs !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                        <span class="back-tick">
                                            <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                        </span>
                                        <div class="display-flex align-center">
                                            <mat-form-field appearance="outline" class="set-date"
                                                (click)="preventDefault($event)">
                                                <input matInput [matDatepicker]="picker6" name="VALUE" formControlName="VALUE"
                                                    required>
                                                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                                <mat-datepicker #picker6></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="display-flex align-center"
                                        *ngIf="filter.FILTERTYPE == 'DATE' && !selectedFilterIs || (selectedFilterIs == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                        <span class="back-tick">
                                            <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                        </span>
                                        <div class="display-flex align-center field-box" (click)="preventDefault($event)">
                                            <mat-form-field class="set-date" appearance="outline">
                                                <input matInput placeholder="" name="VALUE" formControlName="VALUE" required appNumberMask>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="set-date date-dropdown-selector">
                                                <mat-select [value]="'days'" formControlName="VALUE2" required>
                                                    <mat-option value="days">Days</mat-option>
                                                    <mat-option value="month">Month </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="display-flex align-center"
                                        *ngIf="filter.FILTERTYPE == 'DATE' && (selectedFilterIs == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                        <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                            <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                formControlName="VALUE" required>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <span class="middle-caption">and</span>
                                        <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                            <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                formControlName="VALUE2" required>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <button mat-raised-button color="primary" class="width-full accent"
                                        (click)="applayOptionalfilter(optionalFilters[i] , i ,Datefilter, $event)">Apply</button>
                                </mat-menu>
                            </ng-container>
                        </div>
                    </ng-container>


                        </div>
                        </form>
                        </div>

            <div class="content">
                <div class="page-layout simple left-sidebar inner-sidebar">
                    <div fxLayoutAlign="center" class="content">
                        <div class="center more_tbl_clmn" fusePerfectScrollbar>
                            <div class="content">
                                <div class="btn top_right_action_billing_position">
                                    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                                        <mat-icon class="secondary-text">more_vert</mat-icon>
                                    </button>
                                    <mat-menu #contextMenu="matMenu">
                                        <ng-template matMenuContent let-item="item">
                                            <button mat-menu-item (click)="openDialog()"> Field Configuration</button>
                                            <button mat-menu-item (click)="pastRecouncilliationRefresh()"> Reload
                                                Data</button>
                                        </ng-template>
                                    </mat-menu>
                                </div>
                                <div class="content">
                                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                                    </div>
                                    <div class="example-containerdata resizable-table">
                                        <div class="grid-overlay" *ngIf="isDisplay">
                                            <div class="grid-overlay-msg"> There is no data to display. </div>
                                        </div>
                                        <mat-table [dataSource]="PastRECData" (matSortChange)="sortData($event)"
                                            matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}"
                                            matSortDisableClear matSort [@animateStagger]="{value:'50'}">
                                            <ng-container *ngFor="let item of displayedColumns; let i = index"
                                                matColumnDef="{{item}}">
                                                <mat-header-cell
                                                    *matHeaderCellDef="let i = index"
                                                    mat-sort-header
                                                    class="header_title"
                                                    mwlResizable
                                                    enableGhostResize="true"
                                                    (resizing)="onResizing($event, item)"
                                                    (resizeEnd)="onResizeEnd($event, item)"
                                                    [resizeEdges]="{
                                                        bottom: false,
                                                        right: true,
                                                        top: false,
                                                        left: true
                                                    }">
                                                    <div
                                                        class="desc_cell resize-handle-right "
                                                        mwlResizeHandle
                                                        [resizeEdges]="{ right: true }"></div>
                                                    {{ tempColobj[item].DESCRIPTION }}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row"
                                                    class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}"
                                                    [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                                    <span
                                                        *ngIf="row[item]!==undefined">{{row[item] | toFixedAmount:tempColobj[item].CURRENCY | wordwrap:tempColobj[item].WIDEDATA}}</span>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                            </mat-header-row>
                                            <mat-row
                                                [style.background]="highlightedRows == row.RECONCILIATIONGUID ? selectedColore : ''"
                                                [ngClass]="highlightedRows == row.RECONCILIATIONGUID ? 'row-text-colore' : ''"
                                                highlightedRows=row.RECONCILIATIONGUID
                                                *matRowDef="let row; columns: displayedColumns;"
                                                (click)="editBanking(row);highlightedRows = row.RECONCILIATIONGUID "
                                                matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                            </mat-row>
                                        </mat-table>
                                    </div>
                                    <mat-paginator [pageSizeOptions]="[10, 20,50,100]"
                                        [pageSize]="pageSize?.past_recounciliations" (page)="onPaginateChange($event)"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>