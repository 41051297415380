import { fuseAnimations } from 'src/@fuse/animations';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ElementRef, ChangeDetectorRef, HostListener, Renderer2, AfterViewInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SortingDialogComponent } from 'src/app/main/sorting-dialog/sorting-dialog.component';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { ResizeEvent } from 'angular-resizable-element';
import * as moment from 'moment';
import { FilterSearchOptions } from 'src/app/_constant/dynamic-search-option.const';
import { TableColumnsService } from '@_services/table-columns.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-general-journal',
  templateUrl: './general-journal.component.html',
  styleUrls: ['./general-journal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GeneralJournalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
  highlightedRows: any;
  ColumnsObj = [];
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  displayedColumns: string[];
  tempColobj: any;
  accountTypeData: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isLoadingResults: boolean = false;
  dateRange: any = {};
  generalJournalData: any = [];
  isDisplay: boolean = false;
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;
  hasSearchVal: boolean = false;
  // updatecurrentDate.setDate( new Date().getDate() - 30);
  filterVals = {
    ITEMSTARTDATE: '', ITEMENDDATE: '', SEARCH: '', SHOWRECEIPTS: false, SHOWINVOICES: false, SHOWRECEIVEMONEY: false,
    SHOWSPENDMONEY: false, SHOWGENERALJOURNAL: false, UseTrust: false, SHOWTRUSTRECEIPTS: false, SHOWTRUSTWITHDRAWALS: false, SHOWTRUSTTRANSFERS: false
  };
  hasDateRange: boolean = false;
  dateColFilter = [];
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  //new filter
  @ViewChild("searchField", { static: false }) searchField: ElementRef<HTMLInputElement>;
  activeFilters: any;
  currentUserData = JSON.parse(window.localStorage.getItem("currentUser"));
  optionalFilterList = [];
  showFiltersList: boolean = true;
   disabledMainMenuTrigger = true;
  optionalFilters = [];
  generalJournalFilterForm: FormGroup;
  FILTERS: FormArray;
  FILTERSLIST: FormArray;
  _filterList = [];
  addFilter: any;
  manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;
  dateFilter = FilterSearchOptions.dateFilter;
  currencyFilter = FilterSearchOptions.currencyFilter;
  optionalDateFilterPayload = [];
  selectedFilterIs: string;
  activeOptionalFilter: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(
    private TableColumnsService: TableColumnsService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    public datepipe: DatePipe,
    private _mainAPiServiceService: MainAPiServiceService,
    public behaviorService: BehaviorService,
    private SortingbehaviorService: SortingBehaviourService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private cdf: ChangeDetectorRef
  ) {

    this.addFilter = {
      OPERATION: '',
      VALUE: '',
      VALUE2: '',
      FILTERTYPE: ''
    };

    localStorage.setItem('istrackid', 'GeneralJournalScreenComponent');
    this.SortingbehaviorService.generalJuronalSorting$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (result) {
        this.sortingDefaultState = result;
        localStorage.setItem('general_juronal_screen_sorting', JSON.stringify(result));
      } else {
        this.sortingDefaultState = JSON.parse(localStorage.getItem('general_juronal_screen_sorting'))
      }
    });

    this.behaviorService.TrustDuplicateModuleHandling$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        this.accountTypeData = result;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'GeneralJournalScreenComponent' && result['click'] && result['data']['GetJournal']) {
        if (!localStorage.getItem('generaljournal_list_columns')) {
          this.getTableFilter();
        }
        this.LoadData();
      }
    });

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {


    this.generalJournalFilterForm = this.fb.group({
      DATE: [''],
      SEARCH: [''],
      FILTERS: new FormArray([]),
      FILTERSLIST: new FormArray([]),
      MAINFILTER: ['']
    });

    this.activeFilters = {};
    if (this.sortingDefaultState) {
      this.sortactive = this.sortingDefaultState.active;
      this.sortDirection = this.sortingDefaultState.direction;
    } else {
      this.sortactive = "";
      this.sortDirection = "";
    }

    // this.behaviorService.resizeTableForAllView();
    // $(window).resize(() => {
    //   this.behaviorService.resizeTableForAllView();
    // });

    let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
    $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 50)) + 'px'); // before 60
    $(window).resize(() => {
      let newSeH = $('.sticky_search_div_new').is(":visible") ? $('.sticky_search_div_new').height() : 0;
      $('.example-containerdata').css('height', ($(window).height() - ($('#tool_baar_main').height() + $('.sticky_search_div').height() + newSeH + 50)) + 'px');
    });

    let updatecurrentDate = new Date();
    updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
    this.dateRange = { begin: updatecurrentDate, end: new Date() };
    this.filterVals.ITEMSTARTDATE = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
    this.filterVals.ITEMENDDATE = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
    this.getTableFilter();

    this.SortingbehaviorService.GeneralJournalFilterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (result) {
        this.filterVals = result;
      }
    });
    if (this.accountTypeData.ClickType == 'WithTrust') {
      this.filterVals.UseTrust = true;
    } else {
      this.filterVals.UseTrust = false;
    }
    if (this.filterVals.ITEMSTARTDATE || this.filterVals.ITEMENDDATE) {
      this.hasDateRange = true;
    } else {
      this.dateRange = {};
    }
    this.hasSearchVal = this.filterVals.SEARCH ? true : false;
    this.getFilter()
    this.LoadData();

  }


  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit(): void {
    this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }
  renderedData: [];

  /**
   * This function is used to sort the Data
   */
  sortData(val) {
    this.ArrangData(this.renderedData);
    // }, 1000);

    // this.SortingbehaviorService.generalJuronalSorting(val);
    // this.setDefaultWidth(this.displayedColumns, 0);
  }

  /**
   * This function is used to OnSearch the data
   */
  onSearch(searchFilter: any) {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter' || searchFilter == 'removeSearchString') {
      if (searchFilter == 'removeSearchString') {
        this.filterVals.SEARCH = "";
      };
      this.hasSearchVal = this.filterVals.SEARCH ? true : false;
      this.LoadData();
    }
  }
  ngOnDestroy(): void {
    // this.filterVals.SEARCH = '';
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }

  /**
   * This function is used to choosed the date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    try {
      this.hasDateRange = true;
      this.filterVals.ITEMSTARTDATE = this.datepipe.transform(event.value['begin'], 'dd/MM/yyyy');
      this.filterVals.ITEMENDDATE = this.datepipe.transform(event.value['end'], 'dd/MM/yyyy');
    } catch (error) {
      this.hasDateRange = false;
      this.filterVals.ITEMSTARTDATE = '';
      this.filterVals.ITEMENDDATE = '';
      this.dateRange = {};
    }
    this.LoadData();
  }

  /**
   * This function is used to change the value of the check box
   */
  changeValueOfCheckbox() {
    if (this.optionalDateFilterPayload && this.optionalDateFilterPayload.length > 0) {
      const payloadData = JSON.parse(JSON.stringify(this.optionalDateFilterPayload));
      const finalPayload = payloadData.filter((d) => d['value'] !== null).map((e) => {
        if (e['value'] && e['value'] !== null) {
          delete e['value'].COLUMNID;
          return e['value'];
        }
      });

      const advFilter = JSON.parse(JSON.stringify(this.filterVals));
      advFilter.AdvancedFilters = finalPayload;
      this.LoadData(advFilter);
      return;
    };

    this.LoadData();
  }

  /**
   * This function is used to get the Table filter
   */
  getTableFilter() {
    let generalJournalListColumns: any = JSON.parse(localStorage.getItem('generaljournal_list_columns'));
    if (generalJournalListColumns && generalJournalListColumns != null) {
      let data = this.TableColumnsService.filtertableColum(generalJournalListColumns.ColumnsObj);
      this.tempColobj = data.tempColobj;
      this.displayedColumns = generalJournalListColumns.displayedColumns;
      this.ColumnsObj = generalJournalListColumns.ColumnsObj;
      this.dateColFilter = data.dateCol;
    } else {
      this.TableColumnsService.getTableFilter('general journal', '').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let data = this.TableColumnsService.filtertableColum(response.DATA.RECORDS);
          this.tempColobj = data.tempColobj;
          this.displayedColumns = data.showcol;
          this.ColumnsObj = data.colobj;
          this.dateColFilter = data.dateCol;
          localStorage.setItem('generaljournal_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
        }
      }, error => {
        this.toastr.error(error);
      });
    }
  }

  /**
   * This function is used to OnResize the Column
   */
  onResizing(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      const indexData = this.ColumnsObj.findIndex(col => col.COLUMNID === columnName);
      this.ColumnsObj[indexData]['WIDTH'] = event.rectangle.width;
      localStorage.setItem('generaljournal_list_columns', JSON.stringify({ "ColumnsObj": this.ColumnsObj, 'displayedColumns': this.displayedColumns }));
    
    }
  }

  /**
   * This function is used to onResizeEnd
   */
  onResizeEnd(event: ResizeEvent, columnName): void{
    this.TableColumnsService.SaveWidthData(this.ColumnsObj, "general journal", "");
  }
//   // Resize table column. also make some change in html side put below function with 'mwlResizable'.
//   onResizeColumnSetting(event , index){
//     //event : selected column.
//     //index : selected column index.
//     //renderer : dont make this comman it's give issue import in your component.
//     //displayedColumns : displayedColumns name's (Table Header names).
//     //matTableRef : most importent please import in component wise don't make it comman.
//     // last field is localstorage name which use to store columnObj

//     this.TableColumnsService.onResizeColumn(event , index , this.renderer , this.displayedColumns , this.matTableRef , this.ColumnsObj , 'generaljournal_list_columns');
//   };

  //For resize this fuction is most impotent don't make it comman it's give issue in future.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
     this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  /**
   * This function is used to set the default Width
   */
  setDefaultWidth(displayedColumns, timeout) {
    setTimeout(() => {
      displayedColumns.forEach(element => {
        let temWidth = this.tempColobj[element]['WIDTH'];
        const cssValue = temWidth + 'px';
        const columnElts = document.getElementsByClassName('mat-column-' + element);
        for (let i = 0; i < columnElts.length; i++) {
          const currentEl = columnElts[i] as HTMLDivElement;
          currentEl.style.visibility = 'inherit';
          currentEl.style.width = cssValue;
        }
      });
    }, timeout);
  }

  /**
   * This function is used to load the data value
   */
  LoadData(doFilter?: any) {
    if (doFilter) {
      doFilter = doFilter
    } else {
      doFilter = this.filterVals
    //  this.SortingbehaviorService.SetGeneralJournalFilterData(doFilter);
    }

    if (this.optionalDateFilterPayload && this.optionalDateFilterPayload.length > 0) {
      const payloadData = JSON.parse(JSON.stringify(this.optionalDateFilterPayload));
      const finalPayload = payloadData.filter((d) => d['value'] !== null).map((e) => {
        if (e['value'] && e['value'] !== null) {
          delete e['value'].COLUMNID;
          return e['value'];
        }
      });

      const advFilter = JSON.parse(JSON.stringify(this.filterVals));
      advFilter.AdvancedFilters = finalPayload;
      doFilter = advFilter;
    };

    this.generalJournalData = [];
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData(doFilter, 'GetJournal').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.behaviorService.setGeneralData(null);
        let tempJData = [];
        let tempJData2 = [];
        let temJJ = response.DATA.JOURNALS;
        temJJ.forEach((item) => {
          item.JOURNALITEMS.forEach((item2) => {
            item2.JOURNALGUID = item.JOURNALGUID;
            item2.LINKTYPE = item.LINKTYPE;
            if (!tempJData2[item2.JOURNALGUID]) {
              item2.DATE = item.DATE;
              item2.JOURNALTIME = item.JOURNALTIME;
              item2.DESCRIPTION = item.DESCRIPTION;
              item2.JOURNALDATEANDTIME = item.JOURNALDATEANDTIME;
            } else {
              item2.DATE = '';
              item2.JOURNALTIME = '';
              item2.DESCRIPTION = '';
              item2.JOURNALDATEANDTIME = '';
            }
            item2.LINKGUID = item.LINKGUID;
            item2.EXPORTEDDATE = item.EXPORTEDDATE;
            item2.CLOSED = item.CLOSED;
            tempJData2[item2.JOURNALGUID] = item2;
            tempJData.push(item2);
          });
        });
        this.generalJournalData = new MatTableDataSource(tempJData);
        this.generalJournalData.sort = this.sort;
        this.generalJournalData.connect().subscribe(d => this.renderedData = d);

        this.sortingDate();
        if (tempJData[0]) {
          this.isDisplay = false;
          this.editjournal(tempJData[0]);
          this.highlightedRows = tempJData[0].JOURNALGUID;
        } else {
          this.isDisplay = true;
        }
        this.isLoadingResults = false;
        this.setDefaultWidth(this.displayedColumns, 500);
      } else if (response.CODE == 406 && response.MESSAGE == "Permission denied") {
        this.generalJournalData = new MatTableDataSource([]);
        this.isLoadingResults = false;
        this.setDefaultWidth(this.displayedColumns, 500);
      }
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to open the Dialog data value
   */
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '100%';
    dialogConfig.disableClose = true;
    dialogConfig.data = { 'data': this.ColumnsObj, 'type': 'contacts', 'list': '' };
    //open pop-up
    const dialogRef = this.dialog.open(SortingDialogComponent, dialogConfig);
    //Save button click
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tempColobj = result.tempColobj;
        this.displayedColumns = result.columObj;
        this.ColumnsObj = result.columnameObj;
        this.dateColFilter = result.dateCol;
        localStorage.setItem('generaljournal_list_columns', JSON.stringify({ 'displayedColumns': result.columObj, "ColumnsObj": result.columnameObj }));
        if (!result.columObj) {
          this.generalJournalData = new MatTableDataSource([]);
          this.isDisplay = true;
        } else {
          this.LoadData();
        }
      }
    });
  }

  /**
   * This function is used to edit the Journal data
   */
  editjournal(row) {
    this.behaviorService.setGeneralData(row);
  }

  /**
   * This function is used to refresh the General.
   */
  refreshGeneral() {
    if (this.optionalDateFilterPayload && this.optionalDateFilterPayload.length > 0) {
      const payloadData = JSON.parse(JSON.stringify(this.optionalDateFilterPayload));
      const finalPayload = payloadData.filter((d) => d['value'] !== null).map((e) => {
        if (e['value'] && e['value'] !== null) {
          delete e['value'].COLUMNID;
          return e['value'];
        }
      });

      const advFilter = JSON.parse(JSON.stringify(this.filterVals));
      advFilter.AdvancedFilters = finalPayload;
      this.LoadData(advFilter);
      return;
    };

    this.LoadData();
  }

  /**
   * This function is used to sorting the Date
   */
  sortingDate() {
    this.generalJournalData.sortingDataAccessor = (item, property) => {
      let FildeValue = this.dateColFilter;
      if (FildeValue.includes(property)) {
        if (item[property] && (property == 'JOURNALDATEANDTIME')) {
          let momentDate = moment(moment(item[property], 'DD/MM/YYYY hh:mm A')).format('YYYY-MM-DD HH:mm:ss');
          let timestamp = Date.parse(momentDate);
          return timestamp / 1000;
        } else if (item[property] && (property != 'JOURNALDATEANDTIME')) {
          let tempDate = item[property].split("/");
          let Sd = new Date(
            tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
          );
          let newDate = new Date(Sd);
          return newDate;
        }
        return item[property];
      } else {
        return item[property];
      }
    };
    this.ArrangData(this.generalJournalData.sortData(this.generalJournalData.filteredData, this.generalJournalData.sort));
    // proper shorting for date
    this.generalJournalData.sort = this.sort;
  }

  /**
   * This function is used to Arrange the Data.
   */
  ArrangData(ArraySort) {
    let DATA = ArraySort
    let DATA2 = DATA
    let tempJData2 = [];

    // for (let index = 0; index < DATA.length; index++) {
    //   const element = DATA[index];
    DATA.forEach(element => {
      let push = false
      if (element.DATE != '' && element.DATE != undefined && element.DATE != null && element.DATE) {
        //   push = true;
        // }

        // if (array.includes(DATA.length)) {
        //   return true
        // }
        // tempJData2.push(element);
        // for (let index1 = 0; index1 < DATA2.length; index1++) {
        //   const element1 = DATA2[index1];
        DATA.forEach(element1 => {

          if (element.JOURNALGUID == element1.JOURNALGUID) {

            tempJData2.push(element1);
            // if (element.JOURNALITEMGUID == element1.JOURNALITEMGUID) {

            // } else {
            // }
          } else {
          }
        })
        DATA = DATA.filter(item => {
          if (item.JOURNALGUID != element.JOURNALGUID) {
            return item
          }
        })
        // debugger;
      }
    });

    this.generalJournalData = tempJData2
  }

  
/**
 * This function is used to back to filter
 */
  backToFilter(id) {
    $('#' + id).removeClass('active');
    setTimeout(() => {
      $('.mat-menu-content').removeClass('active');
      $('.mat-menu-content').removeClass('mat-menu-content_height');
    }, 50);

    if (this.FILTERS) {
      //this.FILTERSLIST.reset();
    }
  }

  /**
   * This function is used to clear the filter
   */
  clearFilter(name, filterBy) {
    delete this.activeFilters[name];
    // this.clearFilters = true;

    if (filterBy == 'date_range') {
      this.dateRange = {};
      this.activeFilters.daterange = '';
      this.hasDateRange = false;

      let updatecurrentDate = new Date();
      updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
      this.dateRange = { begin: updatecurrentDate, end: new Date() };

      this.filterVals.ITEMSTARTDATE = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
      this.filterVals.ITEMENDDATE = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
      this.activeFilters.daterange = this.filterVals.ITEMSTARTDATE + "-" + this.filterVals.ITEMENDDATE;
    }

    if (filterBy == 'SearchString') {
      this.filterVals.SEARCH = '';
    };

    this.LoadData()
    // this.addOptionalFilter(resetFilter);
  };

  /**
   * This function is used to preventDefault the Data
   */
  preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * This function is used to set the focus
   */
  setFocus(fieldRefVar) {
    this.searchField.nativeElement.focus();
  };

  /**
   * This function is used to set the filter config data value
   */
  Filterconfig(event: MatDatepickerInputEvent<Date>, filterBy) {
    if (filterBy == 'date_range') {
      try {
        this.hasDateRange = true;
        this.filterVals.ITEMSTARTDATE = this.datepipe.transform(event.value['begin'], 'dd/MM/yyyy');
        this.filterVals.ITEMENDDATE = this.datepipe.transform(event.value['end'], 'dd/MM/yyyy');
        this.activeFilters.daterange = this.filterVals.ITEMSTARTDATE + "-" + this.filterVals.ITEMENDDATE;
      } catch (error) {
        this.hasDateRange = false;
        this.filterVals.ITEMSTARTDATE = '';
        this.filterVals.ITEMENDDATE = '';
        this.dateRange = {};
      }
    }

    if (filterBy == 'SearchString') {
      this.activeFilters.SearchString = this.filterVals.SEARCH
    }

  };

  /**
   * This function is used to onfiltersearch the data value
   */
  onFilterSearch(searchFilter?: any) {
    if (this.activeFilters && this.activeFilters.SearchString) {
      //able to remove search filter
      if (searchFilter == 'removeSearchString') {
        this.filterVals.SEARCH = ''
      };

      this.activeFilters.SearchString = this.filterVals.SEARCH;
      this.hasSearchVal = this.filterVals.SEARCH ? true : false;
      this.LoadData();
      // this.addOptionalFilter(null); // add the optional filters.
    }
  };

  /**
   * This function is used to active the current filters
   */
  activeCurrentFilter(id, data?: any) {
    $('#' + id).addClass('active');
    setTimeout(() => {
      $('.mat-menu-content').addClass('active');
      $('.mat-menu-content').addClass('mat-menu-content_height');
    }, 200);

    //this.matterInvoiceFilterForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
    this.selectedFilterIs = '';
    this.generalJournalFilterForm.controls['MAINFILTER'].reset();
    this.activeOptionalFilter = data;
  };

  /**
   * This function is used to DateRangeFilter
   */
  DateRangeFilter(evnt, activeFilters) {
    if (this.optionalDateFilterPayload && this.optionalDateFilterPayload.length > 0) {
      const payloadData = JSON.parse(JSON.stringify(this.optionalDateFilterPayload));
      const finalPayload = payloadData.filter((d) => d['value'] !== null).map((e) => {
        if (e['value'] && e['value'] !== null) {
          delete e['value'].COLUMNID;
          return e['value'];
        }
      });

      const advFilter = JSON.parse(JSON.stringify(this.filterVals));
      advFilter.AdvancedFilters = finalPayload;
      this.LoadData(advFilter);
      return;
    };
    this.LoadData();
  };

  /**
   * This function is used to default set value
   */
  defaultSetValue() {
    let updatecurrentDate = new Date();
    updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
    this.dateRange = { begin: updatecurrentDate, end: new Date() };

    this.filterVals.ITEMSTARTDATE = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
    this.filterVals.ITEMENDDATE = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
    this.activeFilters.daterange = this.filterVals.ITEMSTARTDATE + "-" + this.filterVals.ITEMENDDATE;
    //this.activeFilters.daterange = (this.filterVals.ITEMSTARTDATE && this.filterVals.ITEMENDDATE)?this.filterVals.ITEMSTARTDATE + "-" + this.filterVals.ITEMENDDATE : '';
    this.activeFilters.SearchString = this.filterVals.SEARCH;
  };

  /**
   * This function is used to get the filter data value
   */
  getFilter() {
    let opFilterList = JSON.parse(localStorage.getItem('generaljournal_list_columns'));
    const filterData = this.TableColumnsService.getDynamicFilter(opFilterList, this.optionalFilterList, 'generaljournal_list_columns');
    this.defaultSetValue()
  };

  /**
   * This function is used to add the New filter
   */
  addNewFilter(event) {

    this.addFilter = {
      OPERATION: '',
      VALUE: '',
      VALUE2: '',
      FILTERTYPE: ''
    };

    if (this.FILTERSLIST) {
      this.FILTERSLIST.controls.splice(0, 1);
      //this.FILTERSLIST.reset();
    };

    if (event.FILTERTYPE == 'DATE') {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [{
        id: 1,
        value: 'days'
      }, {
        id: 2,
        value: 'months'
      }]
    };


    if (event.FILTERTYPE !== 'DATE' && event.FILTERTYPE !== 'CURRENCY' && event.FILTERTYPE !== 'NUMBER') {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
    }

    if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(event.FILTERTYPE)
    }

    this.addFilter.FILTERTYPE = event.FILTERTYPE;

    // OPTIONAL FILTER
    this.FILTERSLIST = this.generalJournalFilterForm.get('FILTERSLIST') as FormArray;
    this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

    this.addFilter.OPERATION = (event.FILTERTYPE == "DATE") ? 'is in the last' : (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') ? 'is equal to' : 'contains'
    this._filterList = [event];

    this.cdf.detectChanges()

  };

  /**
   * This function is used to create the Op filter
   */
  createOPFilter(type): FormGroup {
    return this.fb.group({
      OPERATION: (type == "DATE") ? 'is in the last' : (type == 'CURRENCY' || type == 'NUMBER') ? 'is equal to' : 'contains',
      VALUE: '',
      VALUE2: '',
      FILTERTYPE: type
    });
  };

  /**
   * This function is used to add the dynamic filters
   */
  addDynamicFilter(event) {

    if (event.FILTERTYPE == 'DATE') {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [{
        id: 1,
        value: 'days'
      }, {
        id: 2,
        value: 'months'
      }]
    };


    if (event.FILTERTYPE !== 'DATE' && event.FILTERTYPE !== 'CURRENCY' && event.FILTERTYPE !== 'NUMBER') {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
    }

    if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(event.FILTERTYPE)
    }

    event.FILTERAPPLY = true; //for filter hide/show from optional filter list.
    this.optionalFilters.push(event);

    // OPTIONAL FILTER
    this.FILTERS = this.generalJournalFilterForm.get('FILTERS') as FormArray;
    this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));

  };

/**
 * This function is used to apply the Optional filter
 */
  applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any) {
    // new code for validation
    const field1Val = this.generalJournalFilterForm.controls.FILTERS["controls"][isIndex].controls["VALUE"].value
    const field2Val = this.generalJournalFilterForm.controls.FILTERS["controls"][isIndex].controls["VALUE2"].value
    const operationVal = this.generalJournalFilterForm.controls.FILTERS["controls"][isIndex].controls["OPERATION"].value
    const filterTypeVal = this.generalJournalFilterForm.controls.FILTERS["controls"][isIndex].controls["FILTERTYPE"].value

    if (!this.TableColumnsService.checkValidation(field1Val, field2Val, operationVal, filterTypeVal)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }

    // ends here ~ new code for validation

    //addData.selectedFilter = OpretionIs.triggerValue;
    let addData = JSON.parse(JSON.stringify(FilterData));
    addData.selectedFilter = OpretionIs.triggerValue
    const applyFilterData = this.TableColumnsService.Optionalfilter(addData, isIndex, this.optionalDateFilterPayload, this.generalJournalFilterForm);
    this.optionalDateFilterPayload = applyFilterData;
    this.doFilter(this.optionalDateFilterPayload);



  };

  /**
   * This filter is used to do the filter
   */
  doFilter(mainFilter: any) {
    const payloadData = JSON.parse(JSON.stringify(mainFilter));
    const finalPayload = payloadData.filter((d) => d['value'] !== null).map((e) => {
      if (e['value'] && e['value'] !== null) {
        delete e['value'].COLUMNID;
        return e['value'];
      }
    });

    this.SortingbehaviorService.SetGeneralJournalFilterData(this.filterVals)
    const doFilter = JSON.parse(JSON.stringify(this.filterVals));

    // doFilter.FILTERS = finalPayload;
    doFilter.AdvancedFilters = finalPayload;
    this.LoadData(doFilter)
  };

  /**
   * This function is used to add the filter data value
   */
  async addfilter(IsfilterData, oprationalFilter, evt) {
    // alert(" >> value changed >> ")

    if (!this.TableColumnsService.checkValidation(this.addFilter.VALUE, this.addFilter.VALUE2, this.addFilter.OPERATION, IsfilterData.FILTERTYPE)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }
    await this.addDynamicFilter(IsfilterData);

    let sIndex = this.optionalFilters.length - 1;

    this.generalJournalFilterForm.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
    this.generalJournalFilterForm.value.FILTERS[sIndex].VALUE2 = this.addFilter.VALUE2;
    this.generalJournalFilterForm.value.FILTERS[sIndex].OPERATION = this.addFilter.OPERATION;
    this.generalJournalFilterForm.value.FILTERS[sIndex].FILTERTYPE = IsfilterData.FILTERTYPE;

    if (this.FILTERS.length == 1) {
      this.generalJournalFilterForm.patchValue({
        FILTERS: [{
          OPERATION: this.addFilter.OPERATION,
          VALUE: this.addFilter.VALUE,
          VALUE2: this.addFilter.VALUE2,
          FILTERTYPE: IsfilterData.FILTERTYPE
        }]
      });
    } else {
      this.FILTERS.value.forEach((filterData, index) => {
        if (sIndex == index) {
          filterData = {
            OPERATION: this.addFilter.OPERATION,
            VALUE: this.addFilter.VALUE,
            VALUE2: this.addFilter.VALUE2,
            FILTERTYPE: IsfilterData.FILTERTYPE
          }
        }
      });

      this.generalJournalFilterForm.patchValue({
        FILTERS: this.FILTERS.value
      });
    }

    //After getting data appy filter.
    this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

    $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 275)) + 'px');

  };

  /**
   * This function is used to optional Date filter add
   */
  optionalDateFilter(event, filterIs, val, index) {

    filterIs.selectedFilter = event.value;
    this.selectedFilterIs = event.value;

    if (index != -1) {
      this.generalJournalFilterForm.controls.FILTERS["controls"][index].controls["VALUE"].reset();
      this.generalJournalFilterForm.controls.FILTERS["controls"][index].controls["VALUE2"].reset();
    }

    this.addFilter["VALUE"] = "";
    this.addFilter["VALUE2"] = "";
  };

  /**
   * This function is used to set the filter ui
   */
  setFilterUi(filter, index) {
    this.selectedFilterIs = this.optionalDateFilterPayload[index]["value"]["OPERATION"];
  }

  /**
   * This function is used to reset the filter
   */
  resetFilter() {
    this.optionalFilterList.forEach((filter, index)=>{
      filter.FILTERAPPLY = false;
    });

    let resetFilter = JSON.parse(localStorage.getItem('GeneralJournals_Filter'));
    this.generalJournalFilterForm.controls['DATE'].setValue('');
    this.generalJournalFilterForm.controls['SEARCH'].setValue('');
    this.optionalFilters = [];
    this.optionalDateFilterPayload = [];
    if (this.FILTERS) {
      this.FILTERS.reset();
      this.FILTERSLIST.reset();
    }

    setTimeout(() => {
      if (JSON.parse(localStorage.getItem('GeneralJournals_Filter'))) {
        this.activeFilters = JSON.parse(localStorage.getItem('GeneralJournals_Filter'));

        let updatecurrentDate = new Date();
        updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
        this.dateRange = { begin: updatecurrentDate, end: new Date() };

        this.filterVals.ITEMSTARTDATE = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
        this.filterVals.ITEMENDDATE = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
        this.activeFilters.daterange = this.filterVals.ITEMSTARTDATE + "-" + this.filterVals.ITEMENDDATE;
        this.activeFilters.SearchString = '';
        this.filterVals.SEARCH = '';

        resetFilter.ITEMSTARTDATE = this.filterVals.ITEMSTARTDATE
        resetFilter.ITEMENDDATE = this.filterVals.ITEMENDDATE;
        resetFilter.SEARCH = "";
        this.filterVals.UseTrust = false;
        // delete resetFilter.FILTERS;
        delete resetFilter.AdvancedFilters
        localStorage.setItem('GeneralJournals_Filter', JSON.stringify(resetFilter));
      };

      this.LoadData(resetFilter);

      //this.loadData(resetFilter)
    }, 500);

  };

  /**
   * This function is used to reset the optional filter data value
   */
  resetOptionalFilter(event, type, isindex) {
    let index = this.optionalFilters.findIndex((e) => e.COLUMNID == event.COLUMNID);
    event.FILTERAPPLY = false; //for filter hide/show from optional filter list.
    this.optionalFilters.splice(index, 1);
    this.optionalDateFilterPayload.splice(isindex, 1);
    this.FILTERS.controls.splice(isindex, 1);
    this.generalJournalFilterForm.value.FILTERS.splice(isindex, 1);


    //Delete Filters
    const resetFilter = JSON.parse(localStorage.getItem('GeneralJournals_Filter'));

    delete resetFilter.activeDesc;
    delete resetFilter.unbilledWorkDesc;
    delete resetFilter.FILTERINDEX;
    delete resetFilter.FILTERAPPLAY;

    const ArrayResetOptional = JSON.parse(JSON.stringify(this.generalJournalFilterForm.value.FILTERS));

    const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
      if (this.optionalFilters[index]) {
        data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
      }
      return data;
    })

    const finalPayload = ArrayResetOptionalNew.filter((d) => d['VALUE'] !== null && d['VALUE'] !== '').map((e) => {
      if (e['VALUE'] && e['VALUE'] !== '') {
        delete e.COLUMNID;
        if (e.FILTERTYPE == 'DATE') {
          let date1 = (e && e.VALUE !== '') ? moment(e.VALUE).format('DD/MM/YYYY') : '';
          let date2 = (e && e.VALUE2 !== '' && e.OPERATION == 'is between') ? moment(e.VALUE2).format('DD/MM/YYYY') : '';

          if (e.OPERATION == 'is in the last') {
            e.VALUE = e.VALUE;
            e.VALUE2 = e.VALUE2;
          } else {
            e.VALUE = date1;
            e.VALUE2 = date2;
          };
        }

        if (e.VALUE2 == null) {
          e.VALUE2 = "";
        }

        // e.COLUMNNAME = event.COLUMNNAME;
        // e.COLUMNNAME = ArrayResetOptionalNew[localIndex].COLUMNNAME
        return e;
      }
    });

    // resetFilter.FILTERS = finalPayload;
    resetFilter.AdvancedFilters = finalPayload
    if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
      // delete resetFilter.FILTERS;
      delete resetFilter.AdvancedFilters
    };

    // this.reload(resetFilter);
    this.LoadData(resetFilter);
  }
}
