import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';




@Component({
  selector: 'app-split-time-entry',
  templateUrl: './split-time-entry.component.html',
  styleUrls: ['./split-time-entry.component.scss']
})
export class SplitTimeEntryComponent implements OnInit, OnDestroy {
  isdisable: boolean = true;
  allmattername: any = []
  allmatterguid: any = []
  // resumeTimerForm: FormGroup;
  splititemForm: FormGroup
  searchMatter: any
  searchData: any
  action = 'Split'
  separatorKeysCodes: number[] = [ENTER, COMMA];
  // matterautoVal = new FormControl();
  matterlist: Observable<string[]>;
  mattername: any = []
  maaterguid: any
  findvalue1: Number
  selectedData: any = []
  isspiner = false;
  calculateData: any = { MatterGuid: '', QuantityType: '', Quantity: '', FeeEarner: '', FeeType: '' };
  isLoadingResults: boolean = false;
  QuantityType: any
  errorWarningData: any = {};
  errorWarningDataArray: any = {};
  successMsg: any;
  @ViewChild('MatterInput', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
  RequestGuid: string;
  private _unsubscribeAll$: Subject<void> = new Subject();
  private searchTerms = new Subject<string>();
  private subscription: Subscription;



  constructor(public dialogRef: MatDialogRef<SplitTimeEntryComponent>,
    public globalFunService: GlobalFunctionsService,
    private toastr: ToastrService,
    public MatDialog: MatDialog,
    private _formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private behaviorService: BehaviorService,
    private _mainAPiServiceService: MainAPiServiceService,
    private Timersservice: TimersService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    localStorage.setItem('istrackid', 'splittimeentriescomponent');
    this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.maaterguid = result;
      }
    });
  }


    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.splititemForm = this._formBuilder.group({
      matterautoVal1: [''],
      TimeOfDay: ['', Validators.required],
      FeeEarner: [''],
      Duration: [''],
      ExGst: ['', Validators.required],
      IncGst: [''],
      InvoiceText: ['', Validators.required],
      Notes: [''],
      SplitPart: ['2'],
      Val1: [this._data.WORKITEMGUID.QUANTITY ? this._data.WORKITEMGUID.QUANTITY : 0.00],
      Val2: [''],
      Val3: [''],
      MatterNo: [''],
      DateValue: [''],
      QuentityType: [''],
      matterautoVal: ['']
    });

    if (this.action === 'Split') {
      this.allmattername = []
      this.allmatterguid = []
      //  let adjustmentDate = this.datepipe.transform(new Date(this._data.WORKITEMGUID.ITEMDATE), 'dd/MM/yyyy');
      let tempDate = this._data.WORKITEMGUID.ITEMDATE.split("/");
      this.splititemForm.controls['matterautoVal1'].setValue(this._data.WORKITEMGUID.SHORTNAME);

      this.splititemForm.controls['TimeOfDay'].setValue(this._data.WORKITEMGUID.ITEMTIME);
      this.splititemForm.controls['FeeEarner'].setValue(this._data.WORKITEMGUID.FEEEARNER);
      // this.splititemForm.controls['Duration'].setValue((this._data.WORKITEMGUID.QUANTITY.split(":").length !==0)?this._data.WORKITEMGUID.QUANTITY:Number(this._data.WORKITEMGUID.QUANTITY).toFixed(2));
     this.splititemForm.controls['Duration'].setValue(this._data.WORKITEMGUID.QUANTITY)
      this.splititemForm.controls['ExGst'].setValue(this._data.WORKITEMGUID.PRICE);
      this.splititemForm.controls['IncGst'].setValue(this._data.WORKITEMGUID.PRICEINCGST);
      this.splititemForm.controls['InvoiceText'].setValue(this._data.WORKITEMGUID.ADDITIONALTEXT);
      this.splititemForm.controls['DateValue'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));
      this.splititemForm.controls['Notes'].setValue(this._data.WORKITEMGUID.COMMENT);
      this.splititemForm.controls['QuentityType'].setValue(this._data.WORKITEMGUID.QUANTITYTYPE);
      // this.splititemForm.controls['Val2'].setValue((this._data.WORKITEMGUID.PRICE / 2));
      // this.splititemForm.controls['Val3'].setValue((this._data.WORKITEMGUID.PRICEINCGST / 2));

      this.splititemForm.controls["TimeOfDay"].disable();
      this.splititemForm.controls['FeeEarner'].disable();
      this.splititemForm.controls['Duration'].disable();
      this.splititemForm.controls['ExGst'].disable();
      this.splititemForm.controls['IncGst'].disable();
      this.splititemForm.controls['InvoiceText'].disable();
      this.splititemForm.controls['DateValue'].disable();
      this.splititemForm.controls['Notes'].disable();
      this.splititemForm.controls['QuentityType'].disable();
      // this.splititemForm.controls['SplitPart'].setValue(this._data.WORKITEMGUID.SHORTNAME);
      this.allmattername.push(this._data.WORKITEMGUID.SHORTNAME)
      // this.allmatterguid.push(this._data.WORKITEMGUID.MATTERGUID)  
    }
     this.calcTotalINGST(2)
     this.subscription = this.searchTerms.pipe(
      debounceTime(300), // Wait for 300ms pause in events
      distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
      switchMap((term: string) => this.selectMatter(term)) // Switch to new observable on new term
    ).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.searchData = response.DATA.RECORDS;
        this.matterlist = this.searchData
      }
    });
  }

  /**
   * This function is used to Choose the date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>):void {
    let dateval = this.datepipe.transform(event.value, 'dd/MM/yyyy');
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.subscription?.unsubscribe();
  }

  /**
   * This function is used to calculate the Total INC GST
   */
  calcTotalINGST(event):void {
    let val_2 = this._data.WORKITEMGUID.PRICE / this.splititemForm.controls.SplitPart.value
    let val_3 = this._data.WORKITEMGUID.PRICEINCGST / this.splititemForm.controls.SplitPart.value
    this.splititemForm.controls['Val2'].setValue(Number(val_2).toFixed(2));
    this.splititemForm.controls['Val3'].setValue(Number(val_3).toFixed(2));
    if (this._data.WORKITEMGUID.QUANTITYTYPE == "hh:mm") {

      switch (this._data.WORKITEMGUID.QUANTITYTYPE) {
        case 'hh:mm': {
          this.QuantityType = 'X';
          break;
        }
        case 'Hours': {
          this.QuantityType = 'H';
          break;
        }
        case 'Minutes': {
          this.QuantityType = 'M';
          break;
        }
        case 'Days': {
          this.QuantityType = 'D';
          break;
        }
        case 'Units': {
          this.QuantityType = 'U';
          break;
        }
        case 'Fixed': {
          this.QuantityType = 'F';
          break;
        }
        default: {
          
          this.QuantityType = 'F';
          break;
        }
      }
      let currentQuantityType = 'X'
      let sendData = {
        FromQuantityType: this.QuantityType,
        toQuantityType: currentQuantityType,
        Quantity: this._data.WORKITEMGUID.QUANTITY.trim()
      }
     
      this.Timersservice.ChangeQuantityType(sendData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          // this.splititemForm.controls['Duration'].setValue(response.DATA.QUANTITY)
         
          let aa = response.DATA.QUANTITY.includes(':') ? response.DATA.QUANTITY.replace(":", ".") : response.DATA.QUANTITY
          let val_12 = aa / this.splititemForm.controls.SplitPart.value
          this.splititemForm.controls['Val1'].setValue(Number(val_12).toFixed(3));
        }
      })
    } else {
      // let aa = this._data.WORKITEMGUID.QUANTITY.includes(':') ? this._data.WORKITEMGUID.QUANTITY.replace(":", ".") : this._data.WORKITEMGUID.QUANTITY
      let val_1 = this._data.WORKITEMGUID.QUANTITY / this.splititemForm.controls.SplitPart.value
      this.splititemForm.controls['Val1'].setValue(Number(val_1).toFixed(3));
    }
  }

  onKeyUp(event: any): void {
    const searchValue = event.target.value;
    this.searchTerms.next(searchValue);
  }

  /**
   * This function is used to select the Matter
   */
  public selectMatter(event) {
    // let searchobj = { FastSearch: 'True', SearchString: event }
    let searchobj = {
      "Action": "GetData",
      "Filters": {  FastSearch: 'True', SearchString: event }
    };
    
    return this._mainAPiServiceService.getSetData(searchobj, 'matter')
  }

  /**
   * This function is used to Add the Matter.
   */
  add(event: MatChipInputEvent): void {

    const value = (event.value || '').trim();
    this.matterlist.forEach((element: any) => {
      if (element.SHORTNAME == event.value) {

        // Add our fruit
        const array3 = this.allmattername.concat(this.mattername);
        if (array3.length <= (this.splititemForm.controls.SplitPart.value - 1)) {
          if (value) {
            if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
              this.mattername.push(value)
            }
          }
        }
      }
    })


    // Clear the input value

    if (event.input) {
      event.input.value = '';
    }
    //  event.chipInput!.clear();

    this.splititemForm.controls['matterautoVal'].setValue(null);
  }

  /**
   * This function is used to select the MatterGuid.
   */
  selectedmatterguid(data: any):void {
    const array3 = this.allmattername.concat(this.selectedData);
    if (array3.length <= (this.splititemForm.controls.SplitPart.value - 1)) {
      if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
        this.selectedData.push(data)
      }
    }
  }

  /**
   * This function is used to remove the matter
   */
  remove(fruit: any):void {
    const index = this.mattername.indexOf(fruit);
    if (index >= 0) {
      this.mattername.splice(index, 1);
    }
    let sortname = fruit.split('--')[0]
    this.rm(sortname)
  };

  /**
   * This function is used to rm the matter data
   */
  rm(sortname):void {
    this.selectedData.forEach((element: any, index: any) => {
      if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
        this.selectedData.splice(index, 1);
      }
    })
  }


/**
 * This function is used to select the matter data
 */
  selected(event: MatAutocompleteSelectedEvent): void {
    const array3 = this.allmattername.concat(this.mattername)
    if (array3.length <= (this.splititemForm.controls.SplitPart.value - 1)) {
      if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
        this.mattername.push(event.option.viewValue);
        this.MatterInput.nativeElement.value = '';
        this.splititemForm.controls['matterautoVal'].setValue(null);
      }
    }
  }

  get qf() {
    return this.splititemForm.controls;
  }

  /**
   * This function is used to check the validation.
   */
  async checkValidation(bodyData: any, PostQuickTimeEntryData: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // // errorData
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'NO' || value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   } else if (value.VALUEVALID == 'WARNING' || value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    // this.errorWarningDataArray  = { "Error": tempError, 'Warning': tempWarning };

    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   // this.toastr.warning(warningData);
    //   this.isspiner = false;
    //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.saveTimeEntry(PostTimeEntryData);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.saveTimeEntry(PostTimeEntryData);
    // }
    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, PostQuickTimeEntryData).pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(data => {
       if (data) {
          this.errorWarningData = data.errorWarningData;
          this.errorWarningDataArray = data.errorWarningData;
          if (data.callback) {
            this.saveTimeEntry(PostQuickTimeEntryData);
          }
        }
      });
  }

  /**
   * This function is used to Save the Time entry.
   */
  saveTimeEntry(PostQuickTimeEntryData: any):void {
    PostQuickTimeEntryData.VALIDATEONLY = false;
    this.Timersservice.SetWorkItems(PostQuickTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success(this.successMsg);
        this.dialogRef.close(true);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(res.MESSAGE);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.warning(res.MESSAGE);
        this.isspiner = false;
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to Save the Click Time Entry
   */
  SaveClickTimeEntry():void {
    const array3 = this.allmattername.concat(this.mattername.map(ele => ele.split('--')[0]));
    let matterguid = this.selectedData.map(el => el.MATTERGUID)
    // const array4 = this.allmatterguid.concat(matterguid);
    const array4 = matterguid
    this.isspiner = true;
    // let PostData: any = {
    //   "SHORTNAME": array3,
    //   "ITEMDATE": this.qf.TimeOfDay.value,
    //   "FEEEARNER": this.qf.FeeEarner.value,
    //   "DURATION": Number(this.qf.Duration.value).toFixed(2),
    //   "PRICE": Number(this.qf.ExGst.value),
    //   "PRICEINCGST": Number(this.qf.IncGst.value),
    //   "ADDITIONALTEXT": this.qf.InvoiceText.value,
    //   "COMMENT": this.qf.Notes.value,
    //   "QUANTITYTYPE": this.qf.QuentityType.value,
    //   "MATTERGUID": array4,
    //   "SPLITPART": this.qf.SplitPart.value
    // };

    let matterGuid = [];
    array4.forEach(element => {
      matterGuid.push({
        "MatterGuid": element
      })
    });

    let requestPayload = {
      "WorkItemGuid": this._data.WORKITEMGUID.WORKITEMGUID,
      "SplitQuantity": this.qf.SplitPart.value,
      "MatterList": matterGuid
    };

    this.RequestGuid =  this._mainAPiServiceService.generateUniqSerial();

    let PostQuickTimeEntryData: any = { FormAction: 'Split', VALIDATEONLY: false, RequestGuid : this.RequestGuid , Data: requestPayload };
    this.Timersservice.SetWorkItems(PostQuickTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        // this.isspiner = true;
        this.dialogRef.close(res)
        this.successMsg = 'Time entry added successfully';
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.checkValidation(res.DATA.VALIDATIONS, PostQuickTimeEntryData);
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.checkValidation(res.DATA.VALIDATIONS, PostQuickTimeEntryData);
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }


}
