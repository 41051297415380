<!-- <p>export-process works!</p> -->
<form name="ExportProcessForm" id="ExportProcessForm" [formGroup]="ExportProcessForm" fxLayout="column"
    fxLayoutAlign="start" class="one" fxFlex="1 0 auto">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.EXPERTAGREEMENTDATESIGNED,'has-warning':errorWarningData.Warning?.EXPERTAGREEMENTDATESIGNED}">
            <mat-label>Agreement Signed Date</mat-label>
            <input (dateInput)="AgreementSignedDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.EXPERTAGREEMENTDATESIGNED?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="EXPERTAGREEMENTDATESIGNEDText"
                (dateChange)="AgreementSignedDateClick('change', $event)" matInput [matDatepicker]="Expertagreementdatesigned">
            <mat-datepicker-toggle matSuffix [for]="Expertagreementdatesigned"></mat-datepicker-toggle>
            <mat-datepicker #Expertagreementdatesigned></mat-datepicker>
            <div  *ngIf="testBool && toolTipList.EXPERTAGREEMENTDATESIGNED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXPERTAGREEMENTDATESIGNED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPERTAGREEMENTDATESIGNED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.EXPERTCONFIDENTIALITYDATESIGNED,'has-warning':errorWarningData.Warning?.EXPERTCONFIDENTIALITYDATESIGNED}">
            <mat-label>Confidentiality Signed Date</mat-label>
            <input (dateInput)="ConfidentialitySignedDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList.EXPERTCONFIDENTIALITYDATESIGNED?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="EXPERTCONFIDENTIALITYDATESIGNEDText"
                (dateChange)="ConfidentialitySignedDateClick('change', $event)" matInput [matDatepicker]="ExpertConfidentialityDateSigned">
            <mat-datepicker-toggle matSuffix [for]="ExpertConfidentialityDateSigned"></mat-datepicker-toggle>
            <mat-datepicker #ExpertConfidentialityDateSigned></mat-datepicker>
        <div  *ngIf="testBool && toolTipList.EXPERTCONFIDENTIALITYDATESIGNED" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList.EXPERTCONFIDENTIALITYDATESIGNED.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPERTCONFIDENTIALITYDATESIGNED?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        
        <mat-form-field appearance="outline" fxFlex="34" class="pr-4"
        [ngClass]="{'has-error':errorWarningData.Error?.EXPERTSUBMISSIONSDATE ,'has-warning':errorWarningData.Warning?.EXPERTDOCSEXCHANGEDDATE  }">
        <mat-label>Documents Exchanged Date</mat-label>
        <input (dateInput)="DocumentsExchangedDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList?.EXPERTDOCSEXCHANGEDDATE  ?.HOVER:''}}"
        [matTooltipPosition]="toolTipPostion"  formControlName="EXPERTDOCSEXCHANGEDDATEText"
            (dateChange)="DocumentsExchangedDateClick('change', $event)" matInput name="ExpertDocsExchangedDate"
            [matDatepicker]="ExpertDocsExchangedDate">
        <mat-datepicker-toggle matSuffix [for]="ExpertDocsExchangedDate"></mat-datepicker-toggle>
        <mat-datepicker #ExpertDocsExchangedDate></mat-datepicker>
    <div  *ngIf="testBool && toolTipList?.EXPERTDOCSEXCHANGEDDATE  " class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList?.EXPERTDOCSEXCHANGEDDATE  ?.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPERTDOCSEXCHANGEDDATE  ?.COPYVALUE:'')">file_copy</mat-icon></div>
    </mat-form-field>
       
    </div>
   
   
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.EXPERTSUBMISSIONSDATE ,'has-warning':errorWarningData.Warning?.EXPERTSUBMISSIONSDATE}">
            <mat-label>Submissions Date</mat-label>
            <input (dateInput)="SubmissionsClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList?.EXPERTSUBMISSIONSDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="EXPERTSUBMISSIONSDATEText"
                (dateChange)="SubmissionsClick('change', $event)" matInput name="expertsubmissionsdate"
                [matDatepicker]="expertsubmissionsdate" >
            <mat-datepicker-toggle matSuffix [for]="expertsubmissionsdate"></mat-datepicker-toggle>
            <mat-datepicker #expertsubmissionsdate></mat-datepicker>
        <div  *ngIf="testBool && toolTipList?.EXPERTSUBMISSIONSDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList?.EXPERTSUBMISSIONSDATE?.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPERTSUBMISSIONSDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50" class="pr-4"
            [ngClass]="{'has-error':errorWarningData.Error?.EXPERTHEARINGDATE,'has-warning':errorWarningData.Warning?.EXPERTHEARINGDATE}">
            <mat-label> Hearing Date</mat-label>
            <input (dateInput)=" HearingDateClick('input', $event)" matTooltip="{{(toolTipList)?toolTipList?.EXPERTHEARINGDATE?.HOVER:''}}"
            [matTooltipPosition]="toolTipPostion"  formControlName="EXPERTHEARINGDATEText"
                (dateChange)=" HearingDateClick('change', $event)" matInput name="ExpertHearingDate"
                [matDatepicker]="ExpertHearingDate">
            <mat-datepicker-toggle matSuffix [for]="ExpertHearingDate"></mat-datepicker-toggle>
            <mat-datepicker #ExpertHearingDate></mat-datepicker>
        <div  *ngIf="testBool && toolTipList?.EXPERTHEARINGDATE" class="tooltip-wrap" ><input id="myInput" readonly disabled value="{{(toolTipList)?toolTipList?.EXPERTHEARINGDATE?.COPYVALUE:''}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPERTHEARINGDATE?.COPYVALUE:'')">file_copy</mat-icon></div>
        </mat-form-field>
    </div>
</form>
