<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_allowance">
    <h2 cdkDragHandle mat-dialog-title>Estate details
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start">
            <form name="form" fxLayout="column" fxLayoutAlign="start">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline">
                        <mat-label>Matter</mat-label>
                        <input name="MatterName" readonly matInput [(ngModel)]="currentMatter.SHORTNAME" name="SHORTNAME">
                    </mat-form-field>
                    <mat-form-field class="example pr-4" fxFlex="67" appearance="outline">
                        <mat-label>Matter Discription</mat-label>
                        <input name="MatterName" readonly matInput [(ngModel)]="currentMatter.CONTACTNAME" name="CONTACTNAME">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <div fxFlex="100">
                        <app-wills-estate [action]="action" [moduleType]="moduleType"></app-wills-estate>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">

        <button mat-raised-button color="accent" [disabled]="isspiner" (click)="OnSubmit()"  [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>