import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TierPermissionPipe } from './tier-permission.pipe';
import { AppPermissionsPipe } from './app-permissions.pipe';
import { TooltipPipe } from './tooltip.pipe';
import { ProductTypePipe } from './product-type.pipe';
import { FieldConfigColorPipe } from './field-config-color.pipe';
import { EnableDisableFilterPipe } from './enable-disable-filter.pipe';
import { TransformAmountPipe } from './transform-amount.pipe';
import { CustomDecimalPipe } from './custom-decimal.pipe';



@NgModule({
    declarations: [TransformAmountPipe,TierPermissionPipe, AppPermissionsPipe, TooltipPipe, ProductTypePipe, FieldConfigColorPipe, EnableDisableFilterPipe, CustomDecimalPipe],
    imports: [
        CommonModule
    ],
    exports: [TransformAmountPipe,TierPermissionPipe, AppPermissionsPipe, TooltipPipe, ProductTypePipe, FieldConfigColorPipe, EnableDisableFilterPipe, CustomDecimalPipe]
})
export class PipesModule { }
