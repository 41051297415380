import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settlment-title',
  templateUrl: './settlment-title.component.html',
  styleUrls: ['./settlment-title.component.scss']
})
export class SettlmentTitleComponent implements OnInit, OnDestroy {
  isspiner: boolean = false;
  @Input() matterAddressForm: FormGroup;
  @Input() errorWarningData: any;
  sub: Subscription;
  constructor(
    public _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService
  ) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  get f() {
    return this.matterAddressForm.controls;
  }

  /**
   * This function is used to Split the Title Refs 
   */
  SplitTitleRefs() {
    this.isspiner = true;
    let Payload = {
      "Action": "SplitTitleRefs",
      "Data": {'titleReference': this.f.LOTSTITLE.value},
    }

    this.sub = this._mainAPiServiceService.getSetData(Payload, 'utility').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        let addressData = response.DATA;
        this.matterAddressForm.patchValue({
          LOTS: addressData['LOTS'],
          PLANTYPE: addressData['PLANTYPE'],
          PLANNUMBER: addressData['PLANNUMBER'],
          LOT2: addressData['LOT2'],
          PLANTYPE2: addressData['PLANTYPE2'],
          PLANNUMBER2: addressData['PLANNUMBER2'],
          LOT3: addressData['LOT3'],
          PLANTYPE3: addressData['PLANTYPE3'],
          PLANNUMBER3: addressData['PLANNUMBER3'],
          LOT4: addressData['LOT4'],
          PLANTYPE4: addressData['PLANTYPE4'],
          PLANNUMBER4: addressData['PLANNUMBER4'],
          LOT5: addressData['LOT5'],
          PLANTYPE5: addressData['PLANTYPE5'],
          PLANNUMBER5: addressData['PLANNUMBER5'],
        });
      }
      this.isspiner = false;
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }
}
