import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignaturesPopupComponent } from '@pages/inbox/signatures-popup/signatures-popup.component';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';

@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit, OnDestroy {
    class: any;
    signatureFileToUpload: any = "";
    signFileToUpload: any;
    fileToUpload: any = [];
    finalfileToUpload: any
    getDropDownValue: any;
    countryList: any;
    currentUserLocal: any;
    IMAGEPATH: string;
    userData: any;
    GeneralForm: FormGroup;
    sub: Subscription;
    sub1: Subscription;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    constructor(private toastr: ToastrService, private _snackBar: MatSnackBar, private behaviorService: BehaviorService, private _mainAPiServiceService: MainAPiServiceService, private fb: FormBuilder,public _matDialog: MatDialog, public tierpermission: TierPermissionPipe) { }

    generateForm() {
        this.GeneralForm = this.fb.group({
            UserName: [],
            EMAIL: [],
            PHONE1: [],
            LastName: [],
            COUNTRY: [],
            TIMEZONE: []
        })

    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.generateForm()
        this.sub = this.behaviorService.permissionCheckforHeight$.subscribe(data => {
            this.class = data
        })
        this.getUserData();
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.IMAGEPATH = `${'assets/images/avatars/Velazquez.jpg'}`
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
    }

    get f() {
        return this.GeneralForm.controls

    }

    /**
     * This function is used to get the user data list
     */
    getUserData() {
        this.currentUserLocal = JSON.parse(localStorage.getItem('currentUser'));
        let currentUser = JSON.parse(localStorage.getItem('login_data'));
        this.sub1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { USERGUID: currentUser.USERGUID, AllData: true } }, 'user').subscribe(response => {
            if (response.CODE === 200 && response.STATUS === 'success') {
                const userinfoData = response.DATA.USERS[0];
                this.userData = userinfoData;
                this.GeneralForm.controls['UserName'].setValue(userinfoData.USERNAME);
                this.GeneralForm.controls['EMAIL'].setValue(userinfoData.EMAIL);
                this.GeneralForm.controls['PHONE1'].setValue(userinfoData.PHONE1);
                //this.GeneralForm.controls['LastName'].setValue(userinfoData);
                //this.GeneralForm.controls['COUNTRY'].setValue(userinfoData);
                //this.GeneralForm.controls['TIMEZONE'].setValue(userinfoData);
                this.finalfileToUpload = [userinfoData.PHOTO]
            }
        })
    }

    /**
     * This function is used to onFile change
     * @param pFileList -list data value
     * @param imageType -image type value
     */
    onFileChange(pFileList: File[], imageType: string) {
        if (pFileList['size'] <= 10000 || (pFileList[0] && pFileList[0].size) <= 10000) {
            this.selectDocUpload(pFileList, imageType)

            if (imageType == "photo") {
                this.fileToUpload = (pFileList['name'] ? pFileList['name'] : pFileList[0]['name'])
            }
            this._snackBar.open("Successfully upload!", 'Close', {
                duration: 2000,
            });
        } else {
            this.toastr.error("Please Select up to 10kb Size Image")
        }

    }

    /**
     * This unction is used to upload the document data
     * @param fileInput -file input value
     * @param imageType -image type value
     */
    selectDocUpload(fileInput: any, imageType) {
        let file64bit = [];
        let filenames = [];
        let files = fileInput;
        // for (let i = 0; i < files.length; i++) {
        // filenames.push(files.name);
        var reader = new FileReader();

        reader.readAsDataURL(files[0] ? files[0] : files);
        reader.onload = function () {
            file64bit.push(reader.result);
        };
        reader.onerror = function (error) {
        };
        if (imageType == "photo") {
            this.finalfileToUpload = file64bit;
        }
    }

    /**
     * This function is used to signature dialog data value
     */
    signaturDialog() {
        const dialogRef = this._matDialog.open(SignaturesPopupComponent, {
            disableClose: true,
            panelClass: "",
            data: { data: "" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                dialogRef.close();
            }
        });
    }
}
