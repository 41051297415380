<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div calculate-interest" cdkDrag>
    <div class="popup_title_div">
        <h2 cdkDragHandle mat-dialog-title>
            Calculate Interest
            <!-- <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button> -->
            <mat-icon [mat-dialog-close]="false" aria-label="Close dialog" class="mat-icon-close">close</mat-icon>
        </h2>
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="popup_body">
            <div class="p-24 mr-24 remove_left_right_padding one details-box" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                <div class="information">
                    <mat-label>Matter</mat-label> {{invoiceData?.MATTER}}
                </div>
                <div class="information">
                    <mat-label>{{('' | productType)?   "Client" :"Solicitor"}}</mat-label> {{invoiceData?.CLIENTNAME}}
                </div>
                <div class="information">
                    <mat-label>Invoice</mat-label> {{invoiceData?.INVOICECODE | invoiceNumber}}
                </div>
                <div class="information">
                    <mat-label>Invoice Date</mat-label> {{invoiceData?.INVOICEDATE}}
                </div>
                <div class="information">
                    <mat-label>Due Date</mat-label> {{invoiceData?.DUEDATE}}
                </div>
                <div class="information">
                    <mat-label>Amount Outstanding</mat-label>
                    {{invoiceData?.AMOUNTOUTSTANDINGINCGST | toFixedAmount:true}}
                </div>
                <div class="information">
                    <mat-label>Last Interest Date</mat-label>
                    {{invoiceData?.INVOICEDATE}}
                </div>
                <!-- <div class="information">
                    <mat-label>Last Interest Date</mat-label> AAA
                </div> -->
            </div>
            <mat-divider horizontal></mat-divider>
            <div class="form-errors-model pt-24">
                <div class="example-container">
                    <div align="center" class="pb-16">
                        This will create an invoice that tracks the interest component of the selected invoice
                    </div>
                    <form>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field class="example" appearance="outline" fxFlex="100">
                                <mat-label>Calculation Method</mat-label>
                                <mat-select [(ngModel)]="invoiceCalData.METHOD" name="METHOD" (selectionChange)="MethodChange($event.value)">
                                    <mat-option value="Compound">Compound</mat-option>
                                    <mat-option value="Simple">Simple</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field class="example" appearance="outline" fxFlex="100">
                                <mat-label>Calculate Up to </mat-label>
                                <input matInput [matDatepicker]="CalculateUpto" [max]="tomorrow" [(ngModel)]="invoiceCalData.DATE" placeholder="Calculate Up to" name="DATE" (dateChange)="choosedDate('change', $event)">
                                <mat-datepicker-toggle matSuffix [for]="CalculateUpto"></mat-datepicker-toggle>
                                <mat-datepicker #CalculateUpto></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <mat-form-field class="example" appearance="outline" fxFlex="100">
                                <mat-label>Interest Amount </mat-label>
                                <input matInput currencyMask [options]="{align :'left' }" placeholder="Interest Amount" name="INTERESTAMOUNT" [(ngModel)]="invoiceCalData.INTERESTAMOUNT">
                            </mat-form-field>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <button mat-raised-button color="accent" class="mat-accent" mat-button (click)="SaveInvoice()" cdkFocusInitial [disabled]="isspiner">
                <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                    margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Create Invoice
            </button>
            <button mat-raised-button mat-button [mat-dialog-close]="true" color="primary">Cancel</button>
        </div>
    </mat-dialog-actions>
</div>