<div class="popup_main_div user_popup_main select_user_main_div" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>Select User
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog"><mat-icon>close</mat-icon></button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <div class="one select_user_main_div">
            <div class="example-containerdata">
                <table mat-table #table [dataSource]="getDataForTable" [@animateStagger]="{value:'50'}">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [(ngModel)]="row.TAGGED">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="USERID">
                        <th mat-header-cell *matHeaderCellDef>Fee Earner Id</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="job-title text-truncate">
                                {{row.USERID }}
                            </p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="USERNAME">
                        <th mat-header-cell *matHeaderCellDef>User name</th>
                        <td mat-cell *matCellDef="let row">
                            <p class="company text-truncate">
                                {{row.USERNAME }}
                            </p>
                        </td>
                    </ng-container>
                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row [style.background]="highlightedRows == row.USERGUID ? selectedColore : ''" *matRowDef="let row; columns: displayedColumns;" [ngClass]="highlightedRows == row.USERGUID ? 'row-text-colore' : ''" class="contact" (click)="selectUserId(row);highlightedRows = row.USERGUID"
                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" [pageSize]="pageSize?.user_select" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <button mat-raised-button [mat-dialog-close]="(_data && _data.multi=='Multi') ? this.selection.selected : currentUserData" color="accent">Select</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
        </div>
    </mat-dialog-actions>
</div>