<div class="one" [formGroup]="contactForm" fxLayout="column">

    <div class="group one" fxLayout="column">
        <h2>Name</h2>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.SALUTATION,'has-warning':errorWarningData.Warning?.SALUTATION}">
                <mat-label>Salutation</mat-label>
                <input name="SALUTATION" [formControlName]="'SALUTATION'" matInput matTooltip="{{allToolTip.SALUTATION?.HOVER}}">
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SALUTATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SALUTATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Position</mat-label>
                <input matInput matTooltip="{{allToolTip.SALUTATION?.HOVER}}">
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SALUTATION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.SALUTATION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Title</mat-label>
                <input matInput>
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.TITLE?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.TITLE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Initials</mat-label>
                <input matInput>
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INITIALS?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.INITIALS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.GIVENNAMES,'has-warning':errorWarningData.Warning?.GIVENNAMES}">
                <mat-label>First Name</mat-label>
                <input name="GIVENNAMES" [formControlName]="'GIVENNAMES'" matInput matTooltip="{{allToolTip.GIVENNAMES?.HOVER}}">
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GIVENNAMES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.GIVENNAMES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MIDDLENAMES,'has-warning':errorWarningData.Warning?.MIDDLENAMES}">
                <mat-label>Middle Name</mat-label>
                <input name="MIDDLENAMES" [formControlName]="'MIDDLENAMES'" matInput matTooltip="{{allToolTip.MIDDLENAMES?.HOVER}}">
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MIDDLENAMES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MIDDLENAMES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FAMILYNAME,'has-warning':errorWarningData.Warning?.FAMILYNAME}">
                <mat-label>Family Name</mat-label>
                <input name="FAMILYNAME" [formControlName]="'FAMILYNAME'" matInput matTooltip="{{allToolTip.FAMILYNAME?.HOVER}}">
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FAMILYNAME?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.FAMILYNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example" fxFlex="50" class="pr-4">
                <mat-label>Letters</mat-label>
                <input matInput>
          <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.LETTERS?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.LETTERS?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
            </mat-form-field>
        </div>
    </div>
</div>