import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

import { FuseSharedModule } from 'src/@fuse/shared.module';

import { QuickPanelComponent } from 'src/app/layout/components/quick-panel/quick-panel.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,

        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        FormsModule
    ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule {
}
