<!-- <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title> Set Letterhead
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main generate-docwrap" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start" class="letterhead-container">
            <ng-container *ngIf="isPage == 0">
                <div fxFlex="70" class="left-section">
                    <h3>Set Letterhead</h3>
                    <p>To use this feature you need to prepare an example document.</p>
                    <p>It will copy the headers and footers from the example document to each of the selected templates.</p>
                    <ol>
                        <li>Create a document in Word for Windows as the example.</li>
                        <li>Click on the View menu in Word and select Headers/Footers.</li>
                        <li>Edit the headers and footers to look exactly the way you want them to be.</li>
                        <li>Set the default font for the templates by setting the font/size etc. in the "Normal" style.</li>
                        <li>If you want a different header/footer on your first page, then click on File → Page Setup, Layout, and Different First Page. Then edit the headers/footers.</li>
                        <li>Save the document.</li>
                        <li>Select the document in the field below.</li>
                        <li>Make sure all Word documents are CLOSED.</li>
                    </ol>
                </div>
                <div fxFlex="30" class="right-section">
                    <h3>All Templates</h3>
                    <ul class="template-list">
                        <li *ngFor="let item of this.templateFields">
                            {{item.TEMPLATENAME}}
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="gotoNext()">
            Next
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="gotoPrevious()">
            Previous
        </button>
        <button mat-raised-button color="accent" class="mat-accent">
            Set Letterhead
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
</div> -->


<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>

<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title> Set Letterhead
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>

    <div mat-dialog-content class="mat-typography main generate-docwrap" fxLayout="column">

        <!-- First page content -->
        <div *ngIf="isPage === 0">
            <div fxLayout="row" fxLayoutAlign="start" class="letterhead-container">
                <div fxFlex="70" class="left-section">
                    <h3>Set Letterhead</h3>
                    <p>To use this feature you need to prepare an example document.</p>
                    <p>It will copy the headers and footers from the example document to each of the selected templates.</p>
                    <ol>
                        <li>Create a document in Word for Windows as the example.</li>
                        <li>Click on the View menu in Word and select Headers/Footers.</li>
                        <li>Edit the headers and footers to look exactly the way you want them to be.</li>
                        <li>Set the default font for the templates by setting the font/size etc. in the "Normal" style.</li>
                        <li>If you want a different header/footer on your first page, then click on File → Page Setup, Layout, and Different First Page. Then edit the headers/footers.</li>
                        <li>Save the document.</li>
                        <li>Select the document in the field below.</li>
                        <li>Make sure all Word documents are CLOSED.</li>
                    </ol>
                </div>
    
                <!-- Right section: Templates -->
                <div fxFlex="30" class="right-section">
                    <h3>All Templates</h3>
                    <!-- <ul class="template-list-firstpage">
                        <li *ngFor="let item of templateFields" (click)="selectRow(item)" [class]="selectedRow == item.TEMPLATENAME ? 'template-list-firstpage-bg' : ''">{{item.TEMPLATENAME}}</li>
                    </ul> -->
                    <div class="example-containerdata resizable-table">
                        <mat-table [dataSource]="TemplatedataList" matSortDisableClear [@animateStagger]="{value:'50'}" class="mattempletelist">
                            <ng-container matColumnDef="TEMPLATETYPE">
                                <!-- <mat-header-cell *matHeaderCellDef mwlResizable  (resizeEnd)="onResizeEnd($event, 'TEMPLATETYPE')" mwlResizeHandle
                                [resizeEdges]="{ right: true }">
                                    <div class="resize-handle-right" mwlResizeHandle
                                    [resizeEdges]="{ right: true }"></div>
                                </mat-header-cell> -->
                                <mat-cell *matCellDef="let row" style="width: 100px !important;">
                                    <img *ngIf="row.TEMPLATETYPE =='Template'" class="img-matter-popup" src="assets/icons/web_app/icon_template_d.ico" matTooltip="" matTooltipPosition="above">
                                    <img *ngIf="row.TEMPLATETYPE =='Folder'" class="img-matter-popup" src="assets/icons/web_app/folder.ico">
                                    <img *ngIf="row.TEMPLATETYPE =='Go_Back'" class="img-matter-popup" src="assets/icons/web_app/folderup.ico">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="TEMPLATENAME">
                                <!-- <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable  (resizeEnd)="onResizeEnd($event, 'TEMPLATENAME')" mwlResizeHandle
                                [resizeEdges]="{ right: true }">
                                    <div class="resize-handle-right" mwlResizeHandle
                          [resizeEdges]="{ right: true }"></div>
                                    Template name
                                </mat-header-cell> -->
                                <mat-cell *matCellDef="let row" style="width: 100px !important; margin-right: 0 !important;"> {{row.TEMPLATENAME }} </mat-cell>
                            </ng-container>
                            <!-- <mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true">
                            </mat-header-row> -->
                            <mat-row [style.background]="highlightedRowsList == row.TEMPLATENAME ? selectedColore : ''" [ngClass]="highlightedRowsList == row.TEMPLATENAME ? 'row-text-colore' : ''" highlightedRowsList=row.TEMPLATENAME *matRowDef="let row; columns: displayedColumns;" (click)="highlightedRowsList = row.TEMPLATENAME; clickRow(row);" (dblclick)="doubleClick(row,'true')" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                        </mat-table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Second page content (from screenshot) -->
        <div *ngIf="isPage === 1">
            <p>Select which templates you want to apply the header/footer/font to.<br>
                Note: Templates like envelopes don't use the letterhead.
            </p>
            <div fxLayout="row" fxLayoutAlign="start" class="template-selection">
                <!-- Left section: All Templates -->
                <div fxFlex="53" class="center p-010 more_tbl_clmn template-list list_tbl_main" fusePerfectScrollbar>
                    <h3>All Templates</h3>
                    <!-- <ul class="template-list">
                        <li *ngFor="let item of templateFields">{{item.TEMPLATENAME}}</li>
                    </ul> -->
                    <div class="example-containerdata resizable-table">
                        <mat-table [dataSource]="Templatedata" matSortDisableClear [@animateStagger]="{value:'50'}" class="mattempletelist">
                            <ng-container matColumnDef="TEMPLATETYPE">
                                <!-- <mat-header-cell *matHeaderCellDef mwlResizable  (resizeEnd)="onResizeEnd($event, 'TEMPLATETYPE')" mwlResizeHandle
                                [resizeEdges]="{ right: true }">
                                    <div class="resize-handle-right" mwlResizeHandle
                                    [resizeEdges]="{ right: true }"></div>
                                </mat-header-cell> -->
                                <mat-cell *matCellDef="let row" style="width: 100px;">
                                    <img *ngIf="row.TEMPLATETYPE =='Template'" class="img-matter-popup" src="assets/icons/web_app/icon_template_d.ico" matTooltip="" matTooltipPosition="above">
                                    <img *ngIf="row.TEMPLATETYPE =='Folder'" class="img-matter-popup" src="assets/icons/web_app/folder.ico">
                                    <img *ngIf="row.TEMPLATETYPE =='Go_Back'" class="img-matter-popup" src="assets/icons/web_app/folderup.ico">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="TEMPLATENAME" style="padding-right: 0; margin-right: 0; width: 100px;">
                                <!-- <mat-header-cell *matHeaderCellDef mat-sort-header mwlResizable  (resizeEnd)="onResizeEnd($event, 'TEMPLATENAME')" mwlResizeHandle
                                [resizeEdges]="{ right: true }">
                                    <div class="resize-handle-right" mwlResizeHandle
                          [resizeEdges]="{ right: true }"></div>
                                    Template name
                                </mat-header-cell> -->
                                <mat-cell *matCellDef="let row"> {{row.TEMPLATENAME }} </mat-cell>
                            </ng-container>
                            <!-- <mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumns; sticky: true">
                            </mat-header-row> -->
                            <mat-row [style.background]="highlightedRows == row.TEMPLATENAME ? selectedColore : ''" [ngClass]="highlightedRows == row.TEMPLATENAME ? 'row-text-colore' : ''" highlightedRows=row.TEMPLATENAME *matRowDef="let row; columns: displayedColumns;" (click)="highlightedRows = row.TEMPLATENAME; addList(row)" (dblclick)="doubleClick(row,'true')" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                        </mat-table>
                    </div>
                </div>

                <!-- Right section: Templates to Change -->
                <div fxFlex="45" class="right-section-secondpage">
                    <h3>Templates to Change</h3>
                    <ul class="template-list-firstpage" *ngIf="selectedTemplateFields.length > 0">
                        <li *ngFor="let item of selectedTemplateFields" (click)="selectRow(item)" [class]="selectedRow == item ? 'template-list-firstpage-bg' : ''">{{item.TEMPLATENAME}}</li>
                    </ul>
                </div>
            </div>

            <div class="button-section" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button color="accent" class="mat-accent" (click)="addToList()">Add to List</button>
                <button mat-raised-button color="accent" class="mat-accent" (click)="addAllToList()">Add All to List</button>
                <button mat-raised-button (click)="removeFromList()">Remove from List</button>
            </div>
        </div>

    </div>

    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="gotoNext()" *ngIf="isPage === 0">
            Next
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="gotoPrevious()" *ngIf="isPage === 1">
            Previous
        </button>
        <button mat-raised-button color="accent" class="mat-accent" *ngIf="isPage === 1" (click)="setLetterHead()">
            Set Letterhead
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>
            Close
        </button>
    </mat-dialog-actions>
</div>
