<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main" >
    <h2 cdkDragHandle mat-dialog-title>Set Lettershead 
      <button class="btn" mat-icon-button (click)="closepopup()"  [mat-dialog-close]="false" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
      <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
             
        <div fxLayout="column" fxFlex="1 0 auto"  *ngIf="ShowSection =='Previous'">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field  appearance="outline" fxFlex="100" class=" pr-4">
                    <mat-label>Example Document</mat-label>
                    <input name="ExampleDoc" [(ngModel)]="SetLetterData.ExampleDoc"  matInput>
                    <!-- <mat-icon matSuffix class="secondary-text">account_circle</mat-icon> -->
                </mat-form-field>

                <mat-icon matSuffix (click)='SelectDocument()'>
                        <img src="assets/icons/web_app/icon_letters_small_d.ico"  >
                    </mat-icon>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-checkbox name="ApplyHeaders" [(ngModel)]="SetLetterData.ApplyHeaders" fxFlex="50" >Apply Headers</mat-checkbox>
                <mat-checkbox name="ApplyFooters" [(ngModel)]="SetLetterData.ApplyFooters" fxFlex="50" >Apply Footers</mat-checkbox>
            </div>
            </div>

            <div fxLayout="column" fxFlex="1 0 auto" *ngIf="ShowSection =='Next'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                       <p fxFlex="100">Template Path</p> 
                       <mat-icon matSuffix (click)='SelectDocument()'>
                            <img src="assets/icons/web_app/icon_letters_small_d.ico"  >
                        </mat-icon>
                    </div>
                   

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                            <div class="example-containerdata pr-4" fxFlex="45" >
                                    <tr>
                                    <th>All Template</th>
                                    </tr>
                            <table class="simple" [@animateStagger]="{value:'50'}">
                                    <tr *ngFor="let item of ALLTemplateData; let index = index" (click)="AllTempRow(item ,index);highlightedRows=index" [style.background]="highlightedRows == index ? selectedColore : ''" [ngClass]="highlightedRows == index ? 'row-text-colore' : ''" matRipple
                                        [@animate]="{value:'*',params:{y:'100%'}}">
                                        <td>{{item.TEMPLATENAME}}</td>
                                    </tr>    
                                </table>
                                </div>
                                <div class="example-containerdata pr-4" fxFlex="45" >
                                        <tr>
                                                <th>Template To Change</th>
                                                </tr>
                                <table class="simple" [@animateStagger]="{value:'50'}">
                                        <tr *ngFor="let item of TemplateToChange; let index1 = index" (click)="TempChange(item ,index1);highlightedRows1=index1" [style.background]="highlightedRows1 == index1 ? selectedColore : ''" [ngClass]="highlightedRows1 == index1 ? 'row-text-colore' : ''" matRipple
                                            [@animate]="{value:'*',params:{y:'100%'}}">
                                            <td>{{item.TEMPLATENAME}}</td>
                                        </tr>
        
                                    </table>
                                     </div>
                          
        
                    </div>
                    <mat-dialog-actions align="left">
                            <button mat-button  fxFlex="30">
                          <section class="large-button-container">
                              <mat-icon class="main-icon">
                                  <img src="assets/icons/web_app/CHECK2.ICO"  >
                                  </mat-icon>
                              <div class="main-text">
                                  <span>Add To List</span>
                              </div>
                          </section>
                           </button>
                            <button  fxFlex="30"  mat-button (click)="AddAllRow()">
                            <section class="large-button-container">
                                <mat-icon  class="main-icon">
                                    <img src="assets/icons/web_app/CHECK2.ICO"  >
                                    </mat-icon>
                                <div class="main-text">
                                    <span>Add All To List</span>
                                </div>
                            </section>
                             </button>
                            <button  fxFlex="30" mat-button (click)="deleteElement()">
                              <section class="large-button-container">
                                  <mat-icon class="main-icon">
                                      <img src="assets/icons/web_app/CANCEL.ICO"  >
                                      </mat-icon>
                                  <div class="main-text">
                                      <span>Remove From List</span>
                                  </div>
                              </section>
                               </button>
                        </mat-dialog-actions>
                    <!-- <mat-dialog-actions align="left" class="popup_ftr">
                            <button fxFlex="33" mat-raised-button color="accent"  (click)="PreviousSetLetter()" cdkFocusInitial>Add To List</button>
                            <button fxFlex="34" mat-raised-button color="accent"  (click)="NextSetLetter()" cdkFocusInitial>Add All To List</button>
                            <button  fxFlex="33" mat-raised-button color="accent"  (click)="NextSetLetter()" cdkFocusInitial>Remove From List</button>
                        </mat-dialog-actions> -->
                    </div>


      </div>
    </div>
    <mat-dialog-actions align="left" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent"  (click)="PreviousSetLetter()" cdkFocusInitial>Previous</button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="NextSetLetter()" cdkFocusInitial>Next</button>
    </mat-dialog-actions>
    <mat-dialog-actions align="end" class="popup_ftr">

            <!-- <button align="left" mat-raised-button color="accent"  (click)="PreviousSetLetter()" cdkFocusInitial>Previous</button>
            <button align="left"  mat-raised-button color="accent"  (click)="NextSetLetter()" cdkFocusInitial>Next</button> -->
        
        <button mat-raised-button color="accent"  class="mat-accent" mat-dialog-close="false" cdkFocusInitial>Set LetterHead</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>