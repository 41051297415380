import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline/timeline.component';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { SetupGuideComponent } from './setup-guide/setup-guide.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MultiFactorAuthenticationComponent } from '../main/pages/multi-factor-authentication/multi-factor-authentication.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MultiFactorAuthenticationComponent } from '@pages/multi-factor-authentication/multi-factor-authentication.component';
import { TimelineIconDirective } from '@directives/timeline-icon.directive';



@NgModule({
    declarations: [TimelineComponent,
        TimelineIconDirective,
        SetupGuideComponent,
        MultiFactorAuthenticationComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatStepperModule,
        MatButtonModule,
        MatProgressBarModule,
        MatDividerModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        TimelineComponent,
        SetupGuideComponent,
        TimelineIconDirective,
        MultiFactorAuthenticationComponent
    ]
})
export class SharedModule { }
