import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
// import { GenerateTemplatesDialoagComponent } from 'src/app/main/pages/system-settings/templates/gennerate-template-dialoag/generate-template.component';
import { GenerateTemplatesDialoagComponent } from '@pages/system-settings/templates/gennerate-template-dialoag/generate-template.component';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, Subscription, combineLatest, of, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-authority-dialog',
  templateUrl: './authority-dialog.component.html',
  styleUrls: ['./authority-dialog.component.scss'],
  animations: fuseAnimations
})
export class AuthorityDialogComponent implements OnInit, OnDestroy {
  errorWarningData: any = { "Error": [], 'Warning': [] };
  addData: any = [];
  title: any;
  MainTopicClass: any = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isspiner: boolean = false;
  isLoadingResults: boolean = false;
  AuthoDialogeData: any = [];
  public authorityDialoge = {
    AUTHORITY: '', CITATION: '', WEBADDRESS: '', REFERENCE: '', COMMENT_: '', TOPICNAME: '', AUTHORITYGUID: '', TOPICGUID: ''
  }
  action: any;
  FormAction: string;
  TopicName: any;
  AuthoId: string;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  testBool: boolean=false;
  sub: Subscription;
  private _unsubscribeAll$: Subject<void> = new Subject();
  constructor(public _matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  public globalFunService:GlobalFunctionsService,
    private behaviorService: BehaviorService, private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService, public dialogRef: MatDialogRef<AuthorityDialogComponent>,public tooltipService:TooltipService,) {
    localStorage.setItem('istrackid', 'MainAuthorityDialogComponent');
    this.action = data.action;
    if (data.action == 'edit') {
      this.title = 'Update';
    } else if (data.action == 'duplicate') {
      this.title = 'Duplicate';
    }
    else if (data.action == 'new') {
      this.title = 'Add';
    }
    this.behaviorService.MainAuthorityData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.AuthoDialogeData = result;
      }
    });
    this.behaviorService.dialogClose$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });

    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'MainAuthorityDialogComponent' && result['click']) {
        if (result['data']['authority'] || result['data']['topic']) {
          this.ngOnInit();
        }
        if(result['data']['authority']){
          this.SaveAuthority();
        }
        // result['click']=false;
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.forTopic();
    if (this.action == 'edit' || this.action == 'duplicate') {
      this.isLoadingResults = true;
      let Payload = {
        "Action": "GetData",
        "Filters": {
          TOPICGUID: this.AuthoDialogeData.TOPICGUID 
        }
      };
      let PayloadData = {
        "Action": "GetData",
        "Filters": {
          AUTHORITYGUID: this.AuthoDialogeData.AUTHORITYGUID
        }
      };
      this.sub = this._mainAPiServiceService.getSetData(Payload, 'topic').pipe(
        switchMap((topics: any) => combineLatest([of(topics),this._mainAPiServiceService.getSetData(PayloadData, 'authority')]))
      ).subscribe(([topics, authorities]) => {
        if (topics.CODE === 200 && topics.STATUS === 'success') {
          this.authorityDialoge.TOPICNAME = topics.DATA.RECORDS[0].TOPICNAME;
          this.isLoadingResults = false;
        }
        else if (topics.MESSAGE == 'Not logged in') {
          this.isLoadingResults = false;
          this.dialogRef.close(false);
        }

        if (authorities.CODE === 200 && authorities.STATUS === 'success') {
          this.toolTipList = authorities.DATA.FIELDTIPS
          this.authorityDialoge.AUTHORITY = authorities.DATA.AUTHORITIES[0].AUTHORITY;
          this.authorityDialoge.CITATION = authorities.DATA.AUTHORITIES[0].CITATION;
          this.authorityDialoge.COMMENT_ = authorities.DATA.AUTHORITIES[0].COMMENT_;
          this.authorityDialoge.REFERENCE = authorities.DATA.AUTHORITIES[0].REFERENCE;
          this.authorityDialoge.WEBADDRESS = authorities.DATA.AUTHORITIES[0].WEBADDRESS;
          this.authorityDialoge.TOPICGUID = authorities.DATA.AUTHORITIES[0].TOPICGUID;
          this.authorityDialoge.AUTHORITYGUID = authorities.DATA.AUTHORITIES[0].AUTHORITYGUID;
          this.isLoadingResults = false;
        }
        else if (authorities.MESSAGE == 'Not logged in') {
          this.isLoadingResults = false;
          this.dialogRef.close(false);
        }
      })
      // this.isLoadingResults = true;

      // let Payload = {
      //   "Action": "GetData",
      //   "Filters": {
      //     TOPICGUID: this.AuthoDialogeData.TOPICGUID 
	    //   }
      // };

      // this._mainAPiServiceService.getSetData(Payload, 'topic').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      //   if (responses.CODE === 200 && responses.STATUS === 'success') {
      //     this.authorityDialoge.TOPICNAME = responses.DATA.RECORDS[0].TOPICNAME;
      //     this.isLoadingResults = false;
      //   }
      //   else if (responses.MESSAGE == 'Not logged in') {
      //     this.dialogRef.close(false);
      //   }
      //   this.isLoadingResults = false;
      // });
      // let PayloadData = {
      //   "Action": "GetData",
      //   "Filters": {
      //     AUTHORITYGUID: this.AuthoDialogeData.AUTHORITYGUID
	    //   }
      // };
      // this._mainAPiServiceService.getSetData(PayloadData, 'authority').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      //   if (responses.CODE === 200 && responses.STATUS === 'success') {
      //     this.toolTipList = responses.DATA.FIELDTIPS
      //     this.authorityDialoge.AUTHORITY = responses.DATA.AUTHORITIES[0].AUTHORITY;
      //     this.authorityDialoge.CITATION = responses.DATA.AUTHORITIES[0].CITATION;
      //     this.authorityDialoge.COMMENT_ = responses.DATA.AUTHORITIES[0].COMMENT_;
      //     this.authorityDialoge.REFERENCE = responses.DATA.AUTHORITIES[0].REFERENCE;
      //     this.authorityDialoge.WEBADDRESS = responses.DATA.AUTHORITIES[0].WEBADDRESS;
      //     this.authorityDialoge.TOPICGUID = responses.DATA.AUTHORITIES[0].TOPICGUID;
      //     this.authorityDialoge.AUTHORITYGUID = responses.DATA.AUTHORITIES[0].AUTHORITYGUID;
      //     this.isLoadingResults = false;
      //   }
      //   else if (responses.MESSAGE == 'Not logged in') {
      //     this.dialogRef.close(false);
      //   }
      //   this.isLoadingResults = false;
      // });

    }else if (this.action == 'new' ){
      this.NewAuthority()
    }
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub?.unsubscribe();
  }

  /**
   * This function is used to set the Tooltip data
   */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['Authority'] ){
      this.toolTipList  = TooltipData['Authority'];
    }else{
      this.tooltipService.setToolTipData = ('Authority');
     }
  };

  /**
   * This function is used to toggle the Updates
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to copy the tooltip text
   */
  copyTooltipText(event: any) {
    navigator.clipboard.writeText(event)
  };

  /**
   * THis function is used to set the topic data value
   */
  forTopic() {
    this.isLoadingResults = true;
    let Payload = {
      "Action": "GetData",
      "Filters" :{}
    };

    this._mainAPiServiceService.getSetData(Payload, 'topic').pipe(takeUntil(this._unsubscribeAll$)).subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        responses.DATA.RECORDS.forEach(element => {
          this.MainTopicClass.push(element);
          if (element.SUBTOPICS) {
            element.SUBTOPICS.forEach(element2 => {
              this.MainTopicClass.push(element2);
            });

          }
        });
        this.isLoadingResults = false;
      }
    });
  }

  /**
   * This function is used to open the Document
   */
  openDocument() {
    const dialogRef = this._matDialog.open(GenerateTemplatesDialoagComponent, {
      disableClose: true,
      panelClass: 'contact-dialog',
      data: {
        action: 'new',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.SettingForm.controls['INVOICETEMPLATE'].setValue(result);  

    });
  }

  /**
   * This function is used to NewAuthority
   */
  NewAuthority(){
    this._mainAPiServiceService.getSetData({Action:"default" ,DATA:{}}, 'authority').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toolTipList = response.DATA.FIELDTIPS;
      }
    })
  }

  /**
   * This function is used to Save thr Authority
   */
  SaveAuthority() {
    this.isspiner = true;
    if (this.action == 'edit') {
      this.AuthoId = this.authorityDialoge.AUTHORITYGUID;
      this.FormAction = 'update';
    } else if (this.action == 'new' || this.action == 'duplicate') {
      this.AuthoId = '';
      this.FormAction = 'insert';
    }
    let data = {
      TOPICGUID: this.authorityDialoge.TOPICGUID,
      AUTHORITYGUID: this.AuthoId,
      // TOPICNAME:this.authorityDialoge.TOPICNAME,
      AUTHORITY: this.authorityDialoge.AUTHORITY,
      CITATION: this.authorityDialoge.CITATION,
      COMMENT: this.authorityDialoge.COMMENT_,
      REFERENCE: this.authorityDialoge.REFERENCE,
      WEBADDRESS: this.authorityDialoge.WEBADDRESS
    }
    let finalData = { DATA: data, Action: this.FormAction, VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'authority').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });

  }

  /**
   * This functon is used to check the validation
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.AuthoritySaveData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.AuthoritySaveData(details);
    // }
    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        // this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.AuthoritySaveData(details);
        }
      }
    });
  }

  /**
   * This function is used to save the authority
   */
  AuthoritySaveData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'authority').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to topic class changes
   */
  TopicClassChange(val) {
    let value = this.MainTopicClass.find(c => c['TOPICNAME'] == val);
    this.authorityDialoge.TOPICGUID = value.TOPICGUID;
  }

}
