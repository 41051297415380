
<div cdkDrag class="popup_main_div add_edit_contact_main" >
    <h2 cdkDragHandle mat-dialog-title>Move WIP
      <button class="btn" mat-icon-button  [mat-dialog-close]="false" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
      <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
          <form  fxLayout="column" fxFlex="1 0 auto" name="form">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
               <h2>Are you sure you want to move this Work In Progress?</h2>
            </div>

          </form>
      </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="MoveEntry()" [mat-dialog-close]="" cdkFocusInitial>Yes</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>No</button>
    </mat-dialog-actions>
</div>