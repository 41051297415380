import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MattersComponent } from './matters.component';
import { MattersListComponent } from './matters-list/matters-list.component';
import { MattersSortDetailComponent } from './matters-sort-detail/matters-sort-detail.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { NumberMaskModule } from '@directives/number-mask/number-mask.module';
import { NumberSeparatorModule } from '@directives/numberseparator/number-separator.module';
import { NumericDirective } from './matter-popup/numericValidation.component';
import { OldCorrespondDailogComponent } from './old-correspond-dailog/old-correspond-dailog.component';
import { PipesModule } from '@_pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ResizableModule } from 'angular-resizable-element';
import { RightclickDirective } from '@directives/RightClick/rightclick.directive';
import { RouterModule, Routes } from '@angular/router';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
    {
        path: '', component: MattersComponent,
        data: {
            title: 'Matters'
        }, canActivate: [AuthGuard]
    }
];

const maskConfig: Partial<IConfig> = {
    validation: false,
};
// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "right",
//   allowNegative: true,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$",
//   suffix: "",
//   thousands: ",",
//   nullable: false,
//   inputMode: CurrencyMaskInputMode.NATURAL,
// };

@NgModule({
    declarations: [
        MattersComponent,
        MattersListComponent,
        MattersSortDetailComponent,
        NumericDirective,
        RightclickDirective,
        OldCorrespondDailogComponent,

    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        //mat
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatCardModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatTabsModule,
        MatPaginatorModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatGridListModule,
        MatRadioModule,
        MatGridListModule,
        DragDropModule,
        MatSortModule,
        ApplicationPipesModule,
        MatProgressBarModule,
        ResizableModule,
        TreeGridModule,
        NgxCurrencyModule,
        PipesModule,
        MatTooltipModule,
        NumberMaskModule,
        NgxMaskModule.forRoot(maskConfig),
        NumberSeparatorModule
    ],
    exports: [
        MattersComponent,
        DragDropModule,
    ],
    providers: [
        DecimalPipe,
        TierPermissionPipe
        // { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
    entryComponents: [],
})
export class MattersModule {

    constructor() {
    }
}
