import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  safeSrc: any = "";
  isLoadingResults: boolean = false;
  btntext: string = "Edit";
  paymentDetails: any = {};
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;

  constructor(private sanitizer: DomSanitizer, private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService:BehaviorService) {
      localStorage.setItem('istrackid', 'AccMangPaymentComponent');
      this.sub1 = this.behaviorService.APIretryCall$.subscribe((result) => {
        if (localStorage.getItem('istrackid') == 'AccMangPaymentComponent' && result['click'] && result['data']['HOGetPaymentDetailURL']) {
          this.ngOnInit();
        }
      });
     }

      /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.ViewPamentDetails();
  }

  ngOnDestroy(): void {
      this.sub1?.unsubscribe();
      this.sub2?.unsubscribe();
      this.sub3?.unsubscribe();
  }

  /**
   * this function is used to view the payment details. 
   */
  ViewPamentDetails() {
    this.sub2 = this._mainAPiServiceService.getSetData({}, 'HOGetPaymentDetailURL').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.btntext = 'Edit';
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response.DATA.RETURNURL);

        this.paymentDetails = response.DATA
        this.behaviorService.loadingAccountMNG('receipt');
      } else {
        this.behaviorService.loadingAccountMNG('payment');
      }
    });
  }

  /**
   * This function is used to edit the paymentDetails.
   */
  editPamentDetails() {
    this.behaviorService.loadingAccountMNG(null);
    this.sub3 = this._mainAPiServiceService.getSetData({ URLOption: 'Edit' }, 'HOGetPaymentDetailURL').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.btntext = 'View';
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response.DATA.RETURNURL);
        this.behaviorService.loadingAccountMNG('payment');
      } else {
        this.behaviorService.loadingAccountMNG('payment');
      }
    });
  }

  /**
   * This function is used to open the stripe url. 
   */
  openStripeUrl() {
    window.open(this.paymentDetails.RETURNURL, '_blank').focus();
  }

}
