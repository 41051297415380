<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<mat-divider class="mat-divider right_divide"></mat-divider>
<div id="precedents-form">
    <!-- <div class="precedents-header" [ngClass]="{'porpule-theme-on': theme_type =='theme-default'? true:false}"> -->
        <!-- <div class="top_flex_parent"> -->
        <!-- <div class="precedents-name"> -->
            <!-- <h3>Precedents Forms</h3> -->
        <!-- </div> -->
         <!-- don't t\remove id=matter-doc-template it's use in matter popup to hide search -->
        <!-- <div class="search-bar half_search_div" >
            <div class="search-input-content" fxLayout="row" fxLayoutAlign="start center" id="matter-doc-template">
                <mat-icon class="secondary-text">search</mat-icon>
                <input [(ngModel)]="search" (keydown)="onSearch($event)" placeholder="Search">
            </div>
        </div> -->
        <!-- </div> -->
        <!-- <div class="table-head"></div> -->
    <!-- </div> -->
    <div class="precedents-body">
        <div class="sidebar-precedents">
            <div class="side-two-content">
                <span *ngFor="let val of mainDataArray | keyvalue">
                    <div class="sidebar-content">
                        <!-- <p *ngIf="stateSelect == val.key" class="key_class"><strong>{{val.key}}</strong></p> -->
                        <div class="nav-item" *ngFor="let val2 of val.value">
                            <a class="nav-link" href="#" onclick="event.preventDefault()"
                                [ngClass]="{'active accent': whichFormSelect == val2.AREAOFLAWID,'porpule-theme-active': theme_type =='theme-default' && whichFormSelect == val2.AREAOFLAWID? true:false}"
                                (click)="formClick(val2)" *ngIf="stateSelect == val.key" class="key_subClass">
                                {{val2.DESCRIPTION}}</a>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="precedents-listing resizable-table table-container" style="display: flex;
        flex-direction: column;">
            <!-- <div class="btn top_right_action_billing_position matter-window">
                <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>
                <mat-menu #contextMenu="matMenu">
                    <ng-template matMenuContent let-item="item">
                        <button mat-menu-item (click)="precedentsFromRefresh()"> Reload Data</button>
                    </ng-template>
                </mat-menu>
            </div> -->
            <div *ngIf="isEmpty" class="no-data_class">
                <h2>There is no data to display</h2>
            </div>
            <mat-table matSort [dataSource]="precedentsdata" [@animateStagger]="{value:'50'}" class="inner-table table-wrapper" style="flex-grow: 1;">
                <ng-container matColumnDef="TYPEICON">
                    <!-- <mat-header-cell *matHeaderCellDef mwlResizable 
                        (resizeEnd)="onResizeEnd($event, 'TYPEICON')"
                        mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                        <div class="resize-handle-right" mwlResizeHandle
                        [resizeEdges]="{ right: true }"></div>
                    </mat-header-cell> -->
                    <mat-cell *matCellDef="let row">
                        <mat-icon class="main-icon_inner">
                            <img src="assets/icons/web_app/icon_letter_multi_small_d.ico"  matTooltip=""
                                matTooltipPosition="above">
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DESCRIPTION">
                    <!-- <mat-header-cell mat-sort-header *matHeaderCellDef mwlResizable 
                        (resizeEnd)="onResizeEnd($event, 'DESCRIPTION')"
                        mwlResizeHandle
                      [resizeEdges]="{ right: true }">
                        <div class="resize-handle-right" mwlResizeHandle
                      [resizeEdges]="{ right: true }"></div>
                        DESCRIPTION
                    </mat-header-cell> -->
                    <!-- rowClick -->
                    <mat-cell *matCellDef="let row"> {{row.DESCRIPTION }} </mat-cell>
                </ng-container>
                <!-- <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row> -->
                <mat-row *matRowDef="let row let index = index;  columns: displayedColumns;"
                    [style.background]="highlightedRows == index ? selectedColore : ''"
                    [ngClass]="highlightedRows == index ? 'row-text-colore' : ''" highlightedRows=index
                    (click)="rowClick(row);highlightedRows = index" (dblclick)='doubleClick(row)' matRipple>
                </mat-row>
            </mat-table>
          
                <!-- <mat-paginator [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.template_list" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator> -->
           
        </div>
    </div>
   
</div>