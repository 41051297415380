import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/date.adapter';
import { ApplicationPipesModule } from '../application-pipes.module';
import { AuthGuard } from '../../../_guards';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { DocumentDailogComponent } from './document-dailog/document-dailog.component';
import { DocumentRegisterComponent } from './document-register.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import { FileDragNDropModule } from '@directives/file-drag-n-drop/file-drag-n-drop.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { PipesModule } from '@_pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SelectDocumentsComponent } from './select-documents/select-documents.component';
import { SidePanelComponent } from './side-panel/side-panel.component';


/**
 * This is using for set the route JSON
 */
const routes: Routes = [
  {
    path: '', component: DocumentRegisterComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [
    DocumentRegisterComponent,
    DocumentDailogComponent,
    SelectDocumentsComponent,
    SidePanelComponent,
  ],
  entryComponents: [DocumentDailogComponent, SelectDocumentsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    PipesModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    SatDatepickerModule, SatNativeDateModule,
    MatCheckboxModule,
    DragDropModule,
    MatExpansionModule,
    MatRadioModule,
    // N level tree checkbox
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule,
    ScrollingModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSortModule,
    ApplicationPipesModule,
    ResizableModule,
    DropDownTreeModule,
    FileDragNDropModule
  ],
  exports: [
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule,
    ScrollingModule,
    MatDatepickerModule,
    DropDownTreeModule
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
  bootstrap: [],
})

export class DocumentRegitser {

}
