import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subject, Subscription } from 'rxjs';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-case-detail',
    templateUrl: './case-detail.component.html',
    styleUrls: ['./case-detail.component.scss']
})
export class CaseDetailComponent implements OnInit, OnDestroy {
    @Input() matterdetailForm: FormGroup;
    //matterdetailForm: FormGroup;
    @Input() errorWarningData: any;
    CourtData: any;
    DivisionData: any;
    RegistryData: any;
    ListData: any;
    ClientTypeData: any;
    CourtBelowData: any;
    @Input() testBool;
    @Input() toolTipList: any;
    toolTipPostion = "above";

    COSTESTIMATEIFWINEXGST: any = 0.00;
    CostEstimateIfWinIncGST: any = 0.00;

    CostEstimateIfFailExGST: any = 0.00;
    CostEstimateIfFailIncGST: any = 0.00;
    private _unsubscribeAll$: Subject<void> = new Subject();
    sub: Subscription;
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    currentUserType = (JSON.parse(localStorage.getItem('currentUser'))).PRODUCTTYPE
    constructor(
        private datepipe: DatePipe,
        private _mainAPiServiceService: MainAPiServiceService,
        public tooltipService: TooltipService
    ) { }

    get f() {
        return this.matterdetailForm.controls;
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {

        let courtLookups = JSON.parse(localStorage.getItem('court_lookups'));
        if (courtLookups && courtLookups != null) {
            this.CourtData = courtLookups;
        } else {
           this.sub = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'court' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.CourtData = responses.DATA.LOOKUPS;
                    localStorage.setItem('court_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }

        let divisionLookups = JSON.parse(localStorage.getItem('division_lookups'));
        if (divisionLookups && divisionLookups != null) {
            this.DivisionData = divisionLookups;
        } else {
           this.sub1 = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'division' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.DivisionData = responses.DATA.LOOKUPS;
                    localStorage.setItem('division_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }

        let registryLookups = JSON.parse(localStorage.getItem('registry_lookups'));
        if (registryLookups && registryLookups != null) {
            this.RegistryData = registryLookups;
        } else {
          this.sub2 = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'registry' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.RegistryData = responses.DATA.LOOKUPS;
                    localStorage.setItem('registry_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }

        let CourtListLookups = JSON.parse(localStorage.getItem('court_list_lookups'));
        if (CourtListLookups && CourtListLookups != null) {
            this.ListData = CourtListLookups;
        } else {
           this.sub3 = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Court List' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.ListData = responses.DATA.LOOKUPS;
                    localStorage.setItem('court_list_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let clientStatusLookups = JSON.parse(localStorage.getItem('client_status_lookups'));
        if (clientStatusLookups && clientStatusLookups != null) {
            this.ClientTypeData = clientStatusLookups;
        } else {
           this.sub4 = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'client status' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.ClientTypeData = responses.DATA.LOOKUPS;
                    localStorage.setItem('client_status_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }
        let courtBelowLookups = JSON.parse(localStorage.getItem('court_below_lookups'));
        if (courtBelowLookups && courtBelowLookups != null) {
            this.CourtBelowData = courtBelowLookups;
        } else {
           this.sub5 = this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'court below' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    this.CourtBelowData = responses.DATA.LOOKUPS;
                    localStorage.setItem('court_below_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                }
            });
        }

    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
    }

    // DateOfHearingsClick(type: string, event: MatDatepickerInputEvent<Date>) {
    //   this.matterdetailForm.controls['DATEOFHEARINGS'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    // }
    MaterialDateClick(type: string, event: MatDatepickerInputEvent<Date>) {
        this.matterdetailForm.controls['MATERIALDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
    }
    myFunction(event: any) {
        // var copyText: any = document.getElementById("myInput");
        // copyText.select();
        // copyText.setSelectionRange(0,9999);
        // navigator.clipboard.writeText(copyText.value)
        // var copyText: any = document.getElementById("myInput");
        // event.select();
        // event.setSelectionRange(0,9999);
        navigator.clipboard.writeText(event)
    };

    calcPE(type) {
        if (type == 'Successful') {
            this.CostEstimateIfWinIncGST = this.f.COSTESTIMATEIFWINEXGST.value * 1.1;
        } else {
            this.CostEstimateIfFailIncGST = this.f.CostEstimateIfFailExGST.value * 1.1;
        }
    }

    calcPI(type) {
        if (type == 'Successful') {
            this.COSTESTIMATEIFWINEXGST = this.f.CostEstimateIfWinIncGST.value / 1.1;
        } else {
            this.CostEstimateIfFailExGST = this.f.CostEstimateIfFailIncGST.value / 1.1;
        }
    }
}
