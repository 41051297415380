import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TooltipService } from '@_services/tooltip.service';

@Component({
  selector: 'app-strata',
  templateUrl: './strata.component.html',
  styleUrls: ['./strata.component.scss']
})
export class StrataComponent implements OnInit {

  constructor(private datepipe: DatePipe,
    public tooltipService: TooltipService) { }
  @Input() testBool;
  @Input() action: any;
  @Input() toolTipList : any;
  toolTipPostion ="above";


    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  /**
   * this function is ued to get the text data value
   */
  myFunction(event:any) {
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999);
    // navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999);
    navigator.clipboard.writeText(event)
  }
}
