import { BehaviorService } from "@_services/Behavior.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";
import { fuseAnimations } from "src/@fuse/animations";

@Component({
    selector: "app-protected-trust-multi-details",
    templateUrl: "./protected-trust-multi-details.component.html",
    styleUrls: ["./protected-trust-multi-details.component.scss"],
    animations: fuseAnimations,
})
export class ProtectedTrustMultiDetailsComponent implements OnInit, OnDestroy {
    ProtectedTrustList: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    currentuser = JSON.parse(localStorage.getItem("currentUser"));
    barristerTitle: any =
        this.currentuser.PRODUCTTYPE == "Solicitor"
            ? "Matter Num"
            : "Short Name";
    constructor(
        public dialog: MatDialog,
        private behaviorService: BehaviorService
    ) {
        this.behaviorService.ProtectedTrustList$.pipe(
            takeUntil(this._unsubscribeAll$)
        ).subscribe((result) => {
            if (result) {
                this.ProtectedTrustList = result;
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }
}
