<button mat-raised-button color="primary" type="button" id="refreshStatment" (click)="LoadData();">Refresh</button>
<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_allowance">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="beneficiaryForm"  #beneficiaryForm="ngForm" (ngSubmit)="OnSubmit(beneficiaryForm)">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field  class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Contact Name</mat-label>

                        <mat-chip-list #chipListContact aria-label="Matter selection">
                            <mat-chip *ngFor="let Contactdata of Contactname" (removed)="removeContact(Contactdata)" class="mat-chips-selected">
                                {{Contactdata| slice:0:40}}<ng-container *ngIf="Contactdata.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input  matInput
                                (keydown.Tab)="Contactdatashow($event)"  matTooltip="{{(toolTipList)?toolTipList.CONTACTNAME?.HOVER:''}}"
                                [(ngModel)]="EstateBeneficiaryForm.CONTACTNAME" #MatterInput2 [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" (keyup)="onKeyUp($event)" [ngClass]="Contactname.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1" *ngFor="let ContactData of Contactlist" (click) ="selectedContact(ContactData)">
                                {{ContactData.CONTACTNAME}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)='ContactMatter()' [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>

                        <!-- <input matInput [(ngModel)]="EstateBeneficiaryForm.CONTACTNAME" name="CONTACTNAME" #CONTACTNAME="ngModel"> -->
                        <input hidden matInput [(ngModel)]="EstateBeneficiaryForm.CONTACTGUID" name="CONTACTGUID" #CONTACTGUID="ngModel">
                        <!-- <mat-icon matSuffix (click)='ContactMatter()'>
                            <img class="seticon" src="assets/icons/web_app/icon_contact_d.ico" matTooltip="Select Contact" matTooltipPosition="above">
                        </mat-icon> -->

                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CONTACTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.CONTACTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-icon class="add-btn-contact" *ngIf="action === 'new'" (click)="selectNewContect()">add_circle</mat-icon>
                    <!-- <button type="button" mat-fab color="primary" class="add-contect-btn" *ngIf="action === 'new'" (click)="selectNewContect()">
                        <mat-icon class="add-icon">add</mat-icon>
                    </button> -->

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Entitlement</mat-label>
                        <textarea matInput matTooltip="{{(toolTipList)?toolTipList.ENTITLEMENTDESCRIPTION?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.ENTITLEMENTDESCRIPTION" name="ENTITLEMENTDESCRIPTION" #ENTITLEMENTDESCRIPTION="ngModel"></textarea>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ENTITLEMENTDESCRIPTION?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ENTITLEMENTDESCRIPTION?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label> Value Of Entitlement</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.VALUEOFENTITLEMENT?.HOVER:''}}" type="number" [(ngModel)]="EstateBeneficiaryForm.VALUEOFENTITLEMENT" name="VALUEOFENTITLEMENT" #VALUEOFENTITLEMENT="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.VALUEOFENTITLEMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.VALUEOFENTITLEMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-checkbox [(ngModel)]="EstateBeneficiaryForm.NOMINALENTITLEMENT" name="NOMINALENTITLEMENT" #NOMINALENTITLEMENT="ngModel">Nominal Entitlement?</mat-checkbox>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Relationship To Deceased</mat-label>
                        <mat-select [(ngModel)]="EstateBeneficiaryForm.RELATIONSHIPTODECEASED" name="RELATIONSHIPTODECEASED" matTooltip="{{(toolTipList)?toolTipList.RELATIONSHIPTODECEASED?.HOVER:''}}"  #RELATIONSHIPTODECEASED="ngModel">
                            <mat-option *ngFor="let assetType of RelationshipData" [value]="assetType.LOOKUPFULLVALUE">{{assetType.LOOKUPFULLVALUE}}</mat-option>
                        </mat-select>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RELATIONSHIPTODECEASED?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RELATIONSHIPTODECEASED?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Re Line</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.RELINE?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.RELINE" name="RELINE" #RELINE="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RELINE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.RELINE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Bank Name</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.BANKNAME?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.BANKNAME" name="BANKNAME" #BANKNAME="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Bank Branch</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.BANKBRANCH?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.BANKBRANCH" name="BANKBRANCH" #BANKBRANCH="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BANKBRANCH?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BANKBRANCH?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Account Name</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.ACCOUNTNAME?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.ACCOUNTNAME" name="ACCOUNTNAME" #ACCOUNTNAME="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTNAME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTNAME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>BSB</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.BSBNUMBER?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.BSBNUMBER" name="BSBNUMBER" #BSBNUMBER="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.BSBNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.BSBNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="example" appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Account Number</mat-label>
                        <input matInput matTooltip="{{(toolTipList)?toolTipList.ACCOUNTNUMBER?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.ACCOUNTNUMBER" name="ACCOUNTNUMBER" #ACCOUNTNUMBER="ngModel">
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ACCOUNTNUMBER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ACCOUNTNUMBER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-checkbox [(ngModel)]="EstateBeneficiaryForm.HASBEENDISTRIBUTED" name="HASBEENDISTRIBUTED" #HASBEENDISTRIBUTED="ngModel">Has been distributed?</mat-checkbox>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="example" appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Notes</mat-label>
                        <textarea matInput matTooltip="{{(toolTipList)?toolTipList.NOTES?.HOVER:''}}" [(ngModel)]="EstateBeneficiaryForm.NOTES" name="NOTES" #NOTES="ngModel"></textarea>
                     <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTES?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NOTES?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button (click)="OnSubmit()" mat-raised-button color="accent" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>{{action === 'edit'? 'Update': 'Save'}}
        </button>
        <!-- <button mat-raised-button color="accent" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button> -->
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>