<!-- <ejs-schedule [eventSettings]='eventSettings'> </ejs-schedule> -->

<div id="calendar" class="page-layout simple fullwidth user-box" [ngClass]="class == true ? 'height-526 one' : 'height-525 one'">
    <div class="height-524">
        <!-- CONTENT -->
        <div class="content padding-8 users-activity" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">

            <div class="display-flex justify-content-between flex-wrap mb-5">
                <div class="display-flex  justify-content-start">
                <!-- <button mat-flat-button color="accent"  class="btn-height mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14" (click)="showUserLoggedOnPopup()">
                    <mat-icon class="fs-21">add</mat-icon> 
                    Logged On Users
                </button> -->
                </div>
                <div class="display-flex flex-wrap ">
                    <button mat-flat-button color="accent"  class="btn-height mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14"  (click)="setViewType('Day')">
                        <mat-icon class="fs-21">add</mat-icon> 
                        Day View
                    </button>
                    <button mat-flat-button color="accent"  class="btn-height mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14"  (click)="setViewType('Week')">
                        <mat-icon class="fs-21">add</mat-icon> 
                        Week View
                    </button>
                    <button mat-flat-button color="accent" class="btn-height mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14"  (click)="setViewType('Month')">
                        <mat-icon class="fs-21">add</mat-icon> 
                        Month View
                        </button>
                        <button mat-flat-button color="accent"  class="btn-height mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14"  [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click large-button' : 'large-button'"(click)="ChangeUserOfUserActivity()">
                        <mat-icon class="fs-21">add</mat-icon> 
                        Combined View
                        </button>

                        <!-- <mat-nav-list> -->
                <button [matMenuTriggerFor]="menu" mat-flat-button color="accent"class="btn-height mat-focus-indicator mat-accent mat-raised-button mat-button-base activities-buttons fs-14">
                    <!-- <mat-icon>
                        <img src="assets/icons/web_app/icon_diary_new_d.ico">
                    </mat-icon> -->
                    <span>Time Scale ...</span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setTimeScale('12')">5
                        minutes</button>
                    <button mat-menu-item (click)="setTimeScale('6')">10
                        minutes</button>
                    <button mat-menu-item (click)="setTimeScale('4')">15
                        minutes</button>
                    <button mat-menu-item (click)="setTimeScale('2')">30
                        minutes</button>
                    <button mat-menu-item (click)="setTimeScale('1')">1 hour</button>
                </mat-menu>
            <!-- </mat-nav-list> -->
                        
        </div>
            </div>

            <!-- <ejs-schedule width='100%' height='550px' [selectedDate]="selectedDate" [eventSettings]="eventSettings"></ejs-schedule> -->
            <div class="main-tabletr">
                        <ejs-schedule #scheduleObj width="100%" height="100%" class="main-tabletr" [selectedDate]="selectedDate" class="schedule-cal-custom" (actionComplete)='onActionComplete($event)' [rowAutoHeight]="rowAutoHeight" [timeScale]="TimeScale" [currentView]="currentView" (popupOpen)='onPopupOpen($event)'
                (cellDoubleClick)="onCellDoubleClick($event)" (cellClick)="onCellClick($event)" [eventSettings]="eventSettings" [group]='group' [allowDragAndDrop]="false" (eventRendered)="applyCategoryColor($event)">
                <e-resources>
                    <e-resource field="OwnerId" title="Owner" name="Owners"  [dataSource]="ownerDataSource" [allowMultiple]="allowMultipleCategory" textField='FULLNAME' idField='USERGUID' >
                    </e-resource>
                </e-resources>
            </ejs-schedule>
            </div>

        </div>

    <!-- / CONTENT -->
    </div>
</div>