import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from 'src/@fuse/animations';
import { FuseConfigService } from 'src/@fuse/services/config.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-collegeof-law',
  templateUrl: './collegeof-law.component.html',
  styleUrls: ['./collegeof-law.component.scss'],
  animations: fuseAnimations
})
export class CollegeofLawComponent implements OnInit, OnDestroy {
  collegeofLawForm: FormGroup;
  isspiner: boolean = false;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  errorWarningData: any = {};
  hide: boolean = true;
  sub: Subscription;
  sub1: Subscription;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public behaviorService: BehaviorService,
    public _matDialog: MatDialog,
  ) {
    // this._fuseConfigService.config = {
    //   colorTheme: 'theme-default',
    //   layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
    // };
    this._fuseConfigService.config = {
        // colorTheme: 'theme-default',
         layout: { navbar: { hidden: true }, toolbar: { hidden: true }, footer: { hidden: true }, sidepanel: { hidden: true } }
       };
       let CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
       if(CurrentUser && CurrentUser?.THEMECOLOUR){
         this._fuseConfigService.config.colorTheme=CurrentUser.THEMECOLOUR
       }else{
         this._fuseConfigService.config.colorTheme='theme-default'
       }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.collegeofLawForm = this._formBuilder.group({ CustomerName: ['', Validators.required], Password: ['', Validators.required], Industry: ['Solicitor'], InitialData: ['NSWCOL'] });
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }

  get f() {
    return this.collegeofLawForm.controls;
  }

  /**
   * This function is used to ondialog Save click
   */
  ondialogSaveClick(): void {
    this.isspiner = true;
    let collegeofLawPostData: any = { FormAction: 'insert', SessionToken: '', VALIDATEONLY: true, Data: this.collegeofLawForm.getRawValue() };
    this.sub = this._mainAPiServiceService.getSetData(collegeofLawPostData, 'SetDatabase').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, collegeofLawPostData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, collegeofLawPostData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, collegeofLawPostData);
      }
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to check the validation
   * @param bodyData -bodydata value
   * @param details -details data value
   */
  checkValidation(bodyData: any, details: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveCollegeofLaw(details);
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveCollegeofLaw(details);
    }
  }

  /**
   * This function is used to saveCollage law
   * @param data 
   */
  saveCollegeofLaw(data: any) {
    data.VALIDATEONLY = false;
    this.sub1 = this._mainAPiServiceService.getSetData(data, 'SetDatabase').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.collegeofLawForm.reset();
        this.collegeofLawForm.controls['Industry'].setValue('Solicitor');
        this.collegeofLawForm.controls['InitialData'].setValue('NSWCOL');
        this.collegeofLawForm.controls['Password'].setValue('');
        this.collegeofLawForm.controls['CustomerName'].setValue('');
        this.toastr.success('Thank you. This database has been created successfully.');
        this.isspiner = false;
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      }
    }, error => {
      this.toastr.error(error);
    });
  }

}
