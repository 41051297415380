<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_divv add_edit_contact_main select_metter_main_div">
    <h2 cdkDragHandle mat-dialog-title>Generate Invoice
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <!-- Select the Pack you want to generate for this Invoice. -->


        <!-- <mat-tab-group (selectedTabChange)="selectTab($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <div (click)="selectedTabIndex=0">
                    <mat-icon class="m-icon">
                        <img src="assets/icons/web_app/icon_email_d.ico">
                    </mat-icon>
                    <span class="icon-word">Emails </span>
                </div>
              </ng-template>
              <app-email-templete></app-email-templete>

            </mat-tab>
          
            <mat-tab >
              <ng-template mat-tab-label>
                <div (click)="selectedTabIndex=1">
                    <mat-icon class="m-icon">
                        <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                    </mat-icon>
                    <span class="icon-word">Packs </span>
                </div>
              </ng-template>
              <app-packs></app-packs>

            </mat-tab>
          
         
          </mat-tab-group> -->

          <div class="item-container" *ngIf="isShownEmailPack">
            <div fxFlex="10" class="manage-tab" [ngClass]="selectedTabIndex == 0 ?'selected-active-tab':''">
                <div mat-list-item  (click)="selectTab(0);FilterSearch('');searchInput.value='';"
                    >
                    <mat-icon class="m-icon">
                        <img src="assets/icons/web_app/icon_email_d.ico">
                    </mat-icon>
                    <p class="icon-word">Emails </p>
                </div>
            </div>
            <div fxFlex="10" class="manage-tab"   [ngClass]="selectedTabIndex == 1?'selected-active-tab':''">
                <div mat-list-item  (click)="selectTab(1);FilterSearch('');searchInput.value='';"
                  >
                    <mat-icon class="m-icon">
                        <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                    </mat-icon>
                    <p class="icon-word">Packs </p>
                </div>
            </div>

            <div  class="manage-tab" style="flex: 1 1 100%;box-sizing: border-box;max-width: 80% !important;">
                <form style="width: 100%;">
                  
                    <mat-form-field class="example" appearance="outline" style="width: 90%;">
                        <mat-label>Search</mat-label>
                        <input matInput placeholder="search" #searchInput (keyup)="FilterSearch(searchInput.value)">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  
                </form>
            </div>
          </div>

          <div *ngIf="isShownEmailPack">
            <app-email-templete *ngIf="selectedTabIndex == 0" [inputShowLocalFilter]="false"></app-email-templete>
            
          </div>

          <div class="custome-example-containerdata" *ngIf="selectedTabIndex == 1 && isShownEmailPack">
            <table fxFlex="100" mat-table #table [dataSource]="GenerateInvoiceData" matSort [@animateStagger]="{value:'50'}">
                <ng-container matColumnDef="Pack">
                    <th mat-header-cell *matHeaderCellDef>Pack</th>
                    <td mat-cell *matCellDef="let element"> {{element.KITNAME}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let element;" class="row" (click)="RowClick
                    (row);highlightedRows=row.KITGUID" [style.background]="highlightedRows == row.KITGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.KITGUID ? 'row-text-colore' : ''" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                </tr>
            </table>
        </div>


        <!-- <div>
            <div fxFlex="10" class="manage-tab management_two">
                <a mat-list-item (click)="loadEmailData({})">
                    <mat-icon class="m-icon">
                        <img src="assets/icons/web_app/icon_email_d.ico">
                    </mat-icon>
                    <span class="icon-word">Emails </span>
                </a>
            </div>
            <div fxFlex="10" class="manage-tab management_three">
                <a mat-list-item  (click)="loadKitData()">
                    <mat-icon class="m-icon">
                        <img src="assets/icons/web_app/icon_doc_pack_d.ico">
                    </mat-icon>
                    <span class="icon-word">Packs </span>
                </a>
            
            </div>
        </div> -->
        <!-- <div class="custome-example-containerdata">
            <table fxFlex="100" mat-table #table [dataSource]="GenerateInvoiceData" matSort [@animateStagger]="{value:'50'}">
                <ng-container matColumnDef="Pack">
                    <th mat-header-cell *matHeaderCellDef>Pack</th>
                    <td mat-cell *matCellDef="let element"> {{element.KITNAME}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;let element;" class="row" (click)="RowClick
                    (row);highlightedRows=row.KITGUID" [style.background]="highlightedRows == row.KITGUID ? selectedColore : ''" [ngClass]="highlightedRows == row.KITGUID ? 'row-text-colore' : ''" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                </tr>
            </table>
        </div> -->
        <!-- <mat-dialog-actions align="left" class="popup_ftr_mat-dialog-actions"> -->
            <!-- <button mat-raised-button color="accent" (click)="GenerateDocumentPack('Pack')" cdkFocusInitial>Generate Document Pack</button>
        <button mat-raised-button color="primary" (click)="GenerateDocumentPack('Template')" cdkFocusInitial>Generate Invoice Only</button> -->
            <!-- <button [ngClass] = "_data.multipledatainvoice == true ? 'disabled-click-cursor' : ''" mat-button (click)="GenerateDocumentPack('Pack')">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_doc_pack_d.ico"  >
                        </mat-icon>
                    <div class="main-text">
                        <span>Generate Document Pack</span>
                    </div>
                </section>
             </button> -->

            <!-- <button mat-button (click)="GenerateDocumentPack('Template')">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_invoice_d.ico"  >
                        </mat-icon>
                    <div class="main-text">
                        <span>Generate Invoice Only</span>
                    </div>
                </section>
            </button> -->
        <!-- </mat-dialog-actions> -->
    </div>
    <mat-dialog-actions align="end" class="popup_ftr_mat-dialog-actions">

        <mat-form-field appearance="fill" class="generate-invoice">
            <mat-label>Invoice Template</mat-label>
            <mat-select  (selectionChange)="selectTamplate($event)" required  name="defaultLookupValue" [(value)]="defaultLookupValue" >
              <!-- <mat-option>--</mat-option> -->
              <mat-option *ngFor="let val of InvoiceTamplateList" [value]="val.LOOKUPGUID"> {{val.LOOKUPFULLVALUE}}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
            <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
          </mat-form-field>

        <button mat-button (click)="GenerateDocumentPack('Template')">
            <section class="large-button-container">
                <mat-icon class="main-icon">
                    <img src="assets/icons/web_app/icon_invoice_d.ico"  >
                    </mat-icon>
                <div class="main-text">
                    <span>Generate Invoice Only</span>
                </div>
            </section>
        </button>

        <!-- <button mat-raised-button color="accent" (click)="save()" [mat-dialog-close]="false" cdkFocusInitial>Save</button> -->

        <button
            *ngIf="selectedTabIndex == 0 && isShownEmailPack"
            mat-raised-button color="accent" class="mat-accent" (click)="GenarateEmail()">
            Generate Email
        </button>
        <button *ngIf="selectedTabIndex == 1 && isShownEmailPack" mat-raised-button
            color="accent" class="mat-accent"  (click)="GenerateDocumentPack('Pack')">
            Generate Pack
        </button>

        <button *ngIf="isShownEmailPack == false" mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Generate</button>
        <button *ngIf="isShownEmailPack == true" mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>

    </mat-dialog-actions>
</div>