import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConfirmPopupComponent } from 'src/app/main/confirm-popup/confirm-popup.component';
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-xero',
  templateUrl: './xero.component.html',
  styleUrls: ['./xero.component.scss']
})
export class XeroComponent implements OnInit, OnDestroy {
  @Input() AccountForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() action: any;
  confirmDialogRef: any;
  accountData: any = [];
  isspiner: boolean = false;
  isLoadingResults: boolean = false;
  inexOfxeroAccount: any;
  refreshbutton: boolean = false;
  sub: Subscription;
  constructor(private _mainAPiServiceService: MainAPiServiceService, public MatDialog: MatDialog, private toastr: ToastrService,) { }
  
   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // if (this.action == "edit") {
    // this.LoadAccount();
    // }
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  /**
   * This function is used to LoadAccount data value
   */
  LoadAccount() {
    this.isLoadingResults = true;
    this.sub = this._mainAPiServiceService.getSetData({ ACCOUNTCLASS: this.f.ACCOUNTCLASS.value, ACCOUNTTYPE: this.f.ACCOUNTTYPE.value }, 'NetAccountsGetAccounts').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.refreshbutton = false;
        this.accountData = response.DATA.NETACCOUNTS;
        if (this.f.XEROGUID.value) {
          const indexData = this.inexOfxeroAccount = this.accountData.findIndex(account => account.ACCOUNTID === this.f.XEROGUID.value);
          this.AccountForm.controls['xeroaccountClsss'].setValue(indexData);
        }
      } else if (response.CODE == 433 && response.STATUS == "error") {
        this.testConnections();
      }
      this.isLoadingResults = false;
    });
  }

  /**
   * This function is used to Get Account details
   * @param event -event value
   */
  GetAccountDetails(event) {
    this.inexOfxeroAccount = event;
  }
  get f() {
    return this.AccountForm.controls;
  }

  /**
   * This function is used to link the Account data
   */
  linkAccount() {
    this.isspiner = true;
    let accountData = this.accountData[this.inexOfxeroAccount];
    if (accountData) {
      this.AccountForm.controls['XEROACCOUNTCODE'].setValue(accountData.CODE);
      this.AccountForm.controls['XEROGUID'].setValue(accountData.ACCOUNTID);
    } else {
      this.toastr.error("Please Select Xero Account and then link");
    }
    this.isspiner = false;
  }

  /**
   * This function is used to Remove the Account
   */
  removeAccount() {
    this.confirmDialogRef = this.MatDialog.open(ConfirmPopupComponent, { disableClose: true, width: '100%', });
    this.confirmDialogRef.componentInstance.confirmMessage = "Are you sure you want to unlink the Xero account?";
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.AccountForm.controls['XEROACCOUNTCODE'].setValue('');
        this.AccountForm.controls['XEROGUID'].setValue('');
      }
    });
  }

  /**
   * This function is used to test the connection
   */
  testConnections() {
    this.refreshbutton = true;
    let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
    window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetAccounts&SessionToken=' + currentUser.SessionToken, 'targetWindow',
    `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=800,
     height=600`);
  }
}
