import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { Router } from "@angular/router";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { CurrentUser } from "src/app/_models/CurrentUser";
import * as $ from "jquery";
@Component({
    selector: "app-setup-guide",
    templateUrl: "./setup-guide.component.html",
    styleUrls: ["./setup-guide.component.scss"],
})
export class SetupGuideComponent implements OnInit {
    isspiner: boolean = false;
    errorWarningData: any = { Error: [], Warning: [] };
    sortdes: boolean = true;
    longdes: boolean = false;
    @Input() isLoadingResults: any;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem("currentUser"));
    /**
     * Used for just get information uiser click the setipguid from toolbar.
     *   */
    @Input() SetUpGuideData: any;
    SetUpGuideDataList: any;
    SetUpGuideDataTask: any;
    ProgressValue: any;

    theme_type = localStorage.getItem("theme_type");
    background: boolean;
    constructor(
        private router: Router,
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        public _matDialog: MatDialog,
        private globalFunService: GlobalFunctionsService,
        private _fuseSidebarService: FuseSidebarService,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {
        setTimeout(() => {
            this.behaviorService.UpdatedColorSystemSetting$.subscribe(
                (data) => {
                    if (data && data != null && data != undefined) {
                        if (
                            data == "theme-blue-gray-dark" ||
                            data == "theme-pink-dark"
                        ) {
                            this.background = true;
                        } else {
                            this.background = false;
                        }
                    } else {
                        let data = JSON.parse(
                            window.localStorage.getItem("currentUser")
                        );
                        if (
                            data &&
                            data.THEMECOLOUR != null &&
                            data.THEMECOLOUR != undefined
                        ) {
                            if (
                                data.THEMECOLOUR == "theme-blue-gray-dark" ||
                                data.THEMECOLOUR == "theme-pink-dark"
                            ) {
                                this.background = true;
                            } else {
                                this.background = false;
                            }
                        }
                    }
                }
            );
        }, 2000);
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {}
    ngOnChanges() {
        // create header using child_id

        if (this.SetUpGuideData) {
            this.LoadSetUpGuideData();
        }
    }
    /**
     * Returns the average of two numbers.
     *
     * NOTE:this is used for 'setup-guide' API call which used for sidbar in toolbar component
     *

     /**
      * This function is used to load the Set up Guid Data 
      */
    LoadSetUpGuideData() {
        // let PostData: any = {
        //     "Action": "GetData",
        //     "Filters": {
        //         "UserGuid": (this.currentUser? this.currentUser.UserGuid:'')
        //     },

        //   }
        // this._mainAPiServiceService.getSetData(PostData, 'setup-guide').subscribe(res => {
        //     if (res.CODE == 200 || res.STATUS == "success") {
        //         if (res.DATA && res.DATA.RECORDS) {
        //             this.SetUpGuideDataTask = res.DATA.RECORDS;
        //             this.ProgressValue = Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT)*10
        //             this.SetUpGuideDataList = res.DATA.RECORDS.TASKS;
        //         }
        //     } else if (res.CODE == 451 && res.STATUS == 'warning') {
        //         this.toastr.warning(res.MESSAGE);
        //         this.isspiner = false;
        //     } else if (res.CODE == 450 && res.STATUS == 'error') {
        //         this.toastr.warning(res.MESSAGE);
        //     }
        // }, err => {
        //     // this.isLoadingResults = false;
        //     // this.toastr.error(err);

        // });
        if (this.SetUpGuideData && this.SetUpGuideData.TASKS) {
            this.SetUpGuideDataTask = this.SetUpGuideData;
            if (this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT != 0) {
                this.ProgressValue =
                    Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT) * 10 +
                    10;
            } else if (this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT == 0) {
                this.ProgressValue =
                    Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT) * 10 -
                    10;
            }
            this.SetUpGuideDataList = this.SetUpGuideData.TASKS;
        }
    }
    /**
     * This function is used to toggle sidebar open pannel
     * @param key -toggle sidebar key
     */
    toggleSidebarOpen(key): void {
        // this._fuseSidebarService.getSidebar(key).toggleOpen();
        $(".setupinfo-slider.active").find(".backbtn").trigger("click");
        $(".time_entry_sidebar").removeClass("active");
        $(".time_entry_sidebar").removeClass("openwidget");
    }

    /**
     * This function is used to Set the router
     * @param Data -data of the router
     */
    Router(Data: any) {
        let data=Data.split('/');
        let Path=data[2];
       this.behaviorService.sendurlpath$.next(Path);
        if (Data) {
            this.router.navigate([Data]);
            this._fuseSidebarService.getSidebar("setupguide").toggleOpen();
        }
    }

    /**
     * This function is used to chenge the description data
     * @param value -value of the description
     */
    changeDescription(value: any) {
        if (value) {
            this.SetUpGuideDataList.forEach((element: any, i: any) => {
                if (element.ID == value.ID) {
                    this.SetUpGuideDataList[i].sortdes =
                        !this.SetUpGuideDataList[i].sortdes;
                    this.SetUpGuideDataList[i].longdes =
                        !this.SetUpGuideDataList[i].longdes;
                    $(".time_entry_sidebar").addClass("active");
                }
            });
        }

        //     const parser = new DOMParser();
        // const fragment = parser.parseFromString(value.LONGDESCRIPTION, 'text/html');
        // const anchorTags = fragment.querySelectorAll('a');
        // anchorTags.forEach((a: HTMLElement) => {
        //     a.style.color = '#43a047';
        //   });

        // this.elRef.nativeElement.innerHTML = fragment.body.innerHTML;
    }

    /**
     * This function is used to change the setup status
     * @param data -data of the status
     */
    changeSetupstatus(data: any) {
        this.isLoadingResults = true;
        let postdata = {
            Action: data.STATUS == "Incomplete" ? "Mark" : "Unmark",
            Data: {
                TaskID: data?.ID,
            },
        };
        this._mainAPiServiceService
            .getSetData(postdata, "setup-guide")
            .subscribe(
                (res) => {
                    if (res.CODE == 200 || res.STATUS == "success") {
                        this.APICall();
                        if (res.DATA && res.DATA.RECORDS) {
                            // this.SetUpGuideDataTask = res.DATA.RECORDS;
                            // if (this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT != 0) {
                            //     this.ProgressValue = Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT) * 10 + 10
                            // } else if (this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT == 0) {
                            //     this.ProgressValue = Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT) * 10 - 10
                            // }
                            // // this.ProgressValue = (Number(this.SetUpGuideDataTask.COMPLETEDTASKCOUNT) * 10) + 10
                            // this.SetUpGuideDataList = res.DATA.RECORDS.TASKS;
                        }
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.isLoadingResults = false;
                        this.toastr.warning(res.MESSAGE);
                        this.isspiner = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.isLoadingResults = false;
                        this.toastr.warning(res.MESSAGE);
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * This function is used to Call the API
     */
    APICall() {
        let PostData: any = {
            Action: "GetData",
            Filters: {
                UserGuid: this.currentUser ? this.currentUser.UserGuid : "",
            },
        };
        this._mainAPiServiceService
            .getSetData(PostData, "setup-guide")
            .subscribe(
                (res) => {
                    if (res.CODE == 200 || res.STATUS == "success") {
                        this.isLoadingResults = false;
                        if (res.DATA && res.DATA.RECORDS) {
                            this.SetUpGuideDataTask = res.DATA.RECORDS;
                            if (
                                this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT != 0
                            ) {
                                this.ProgressValue =
                                    Number(
                                        this.SetUpGuideDataTask
                                            ?.COMPLETEDTASKCOUNT
                                    ) *
                                        10 +
                                    10;
                            } else if (
                                this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT == 0
                            ) {
                                this.ProgressValue =
                                    Number(
                                        this.SetUpGuideDataTask
                                            ?.COMPLETEDTASKCOUNT
                                    ) *
                                        10 -
                                    10;
                            }
                            // this.ProgressValue = Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT)*10 +10
                            this.SetUpGuideDataList = res.DATA.RECORDS.TASKS;
                        }
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.isLoadingResults = false;
                        this.toastr.warning(res.MESSAGE);
                        this.isspiner = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.isLoadingResults = false;
                        this.toastr.warning(res.MESSAGE);
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }
}
