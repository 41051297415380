import { AccountManagmentModule } from './account-managment/account-managment.module';
import { AccountRecountciliationModule } from './account-reconciliation/account-reconciliation.module';
import { ActivityDialogComponent } from './activities/activity-dialog/activity-dialog.component';
import { ActivitySundryDialogComponent } from './activities/activity-sundry-dialog/activity-sundry-dialog.component';
import { AddRateComponent } from './matters/matter-popup/rates/add-rate/add-rate.component';
import { AllowancePopupComponent } from './legal-details/settlement/allowance-popup/allowance-popup.component';
import { ApplicationPipesModule } from './application-pipes.module';
import { AuthorityDialogComponent } from './globally-Authority/main-authorities/authority-dialog/authority-dialog.component';
import { BankingDialogModule } from './banking/banking-dialog.module';
import { BrowseFolderPopupComponent } from './matters/browse-folder-popup/browse-folder-popup.component';
import { CalculateInterestPopupComponent } from './invoice/calculate-interest-popup/calculate-interest-popup.component';
import { CaseDetailComponent } from './matters/matter-popup/case-detail/case-detail.component';
import { ChartOfAccount } from './chart-account/chart-account.module';
import { CheckForCreditsComponent } from './invoice/check-for-credits/check-for-credits.component';
import { ChequePopupComponent } from './legal-details/settlement/cheque-popup/cheque-popup.component';
import { ChronItemDailogComponent } from './legal-details/chronology/chron-item-dailog/chron-item-dailog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ClientComponent } from './matters/matter-popup/client/client.component';
import { CloseYearAccountsDialogModule } from './close-year-accounts-dialog/close-year-accounts-dialog.module';
import { CollegeofLawComponent } from './collegeof-law/collegeof-law.component';
import { CommercialComponent } from './matters/matter-popup/newmatter-detail/commercial/commercial.component';
import { CommonModule } from '@angular/common';
import { CompensationComponent } from './matters/matter-popup/newmatter-detail/compensation/compensation.component';
import { CompulsoryAcquisitionComponent } from './matters/matter-popup/newmatter-detail/compulsory-acquisition/compulsory-acquisition.component';
import { ContactModule } from './contact/contact.module';
import { ConveyancingConcessionsDutyComponent } from './matters/matter-popup/newmatter-detail/matter-conveyancing-expandation/conveyancing-concessions-duty/conveyancing-concessions-duty.component';
import { ConveyancingContractComponent } from './matters/matter-popup/newmatter-detail/matter-conveyancing-expandation/conveyancing-contract/conveyancing-contract.component';
import { ConveyancingPriceDepositeComponent } from './matters/matter-popup/newmatter-detail/matter-conveyancing-expandation/conveyancing-price-deposite/conveyancing-price-deposite.component';
import { ConveyancingSettlementComponent } from './matters/matter-popup/newmatter-detail/matter-conveyancing-expandation/conveyancing-settlement/conveyancing-settlement.component';
import { CorrespondDailogComponent } from './matters/correspond-dailog/correspond-dailog.component';
import { CriminalComponent } from './matters/matter-popup/newmatter-detail/criminal/criminal.component';
import { DebtRecoveryComponent } from './matters/matter-popup/newmatter-detail/debt-recovery/debt-recovery.component';
import { DeliverableDailogComponent } from './time-entries/deliverable-dailog/deliverable-dailog.component';
import { DiaryModule } from './diary/diary.module';
import { DiscountIncreaseComponent } from './invoice/invoice-add-dailog/discount-increase/discount-increase.component';
import { DocumentRegitser } from './document-register/document-register.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import { EmailTemplateDilogComponent } from './inbox/email-template-dilog/email-template-dilog.component';
import { EnableMfaComponent } from './multi-factor-authentication/enable-mfa/enable-mfa.component';
import { EstateAssetsComponent } from './legal-details/estate/estate-assets/estate-assets.component';
import { EstateBeneficiaryComponent } from './legal-details/estate/estate-beneficiary/estate-beneficiary.component';
import { EstateDetailsComponent } from './legal-details/estate/estate-details/estate-details.component';
import { EstateLiabilityComponent } from './legal-details/estate/estate-liability/estate-liability.component';
import { EstimateDilogComponent } from './time-billing/estimate/estimate-dilog/estimate-dilog.component';
import { FamilyComponent } from './matters/matter-popup/newmatter-detail/family/family.component';
import { FileNoteDialogComponent } from './matters/file-note-dialog/file-note-dialog.component';
import { ForeignCurrencyComponent } from './invoice/invoice-add-dailog/foreign-currency/foreign-currency.component';
import { FormsModule } from '@angular/forms';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';
import { FutureYearsLeasingComponent } from './matters/matter-popup/newmatter-detail/leasing/leasing-details-popup/future-years-leasing/future-years-leasing.component';
import { GeneralComponent } from './matters/matter-popup/general/general.component';
import { GeneralJoural } from './general-journal/general-journal.module';
import { GeneralLeasingComponent } from './matters/matter-popup/newmatter-detail/leasing/leasing-details-popup/general-leasing/general-leasing.component';
import { GeneralReceiptDilogComponent } from './receive-money/general-receipt-dilog/general-receipt-dilog.component';
import { GeneralWillTabComponent } from './matters/matter-popup/newmatter-detail/wills-estate/general-will-tab/general-will-tab.component';
import { GenerateInvoiceComponent } from './invoice/generate-invoice/generate-invoice.component';
import { GenerateTemplatesDialoagComponent } from './system-settings/templates/gennerate-template-dialoag/generate-template.component';
import { GloballySafeCustodyModule } from './globally-safecustody/globally-safecustody.module';
import { ImmigrationComponent } from './matters/matter-popup/newmatter-detail/immigration/immigration.component';
import { InstantInvoiceDailogComponent } from './invoice/instant-invoice-dailog/instant-invoice-dailog.component';
import { InvoiceAddDailogComponent } from './invoice/invoice-add-dailog/invoice-add-dailog.component';
import { InvoiceDetailComponent } from './invoice/invoice-detail/invoice-detail.component';
import { LeasingComponent } from './matters/matter-popup/newmatter-detail/leasing/leasing.component';
import { LeasingDetailsPopupComponent } from './matters/matter-popup/newmatter-detail/leasing/leasing-details-popup/leasing-details-popup.component';
import { LitigationComponent } from './matters/matter-popup/newmatter-detail/litigation/litigation.component';
// import { MainDashboardModule } from './main-dashboard/main-dashboard.module'
import { MaritimeComponent } from './matters/matter-popup/newmatter-detail/maritime/maritime.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatterClientComponent } from './matters/matter-popup/matter-client/matter-client.component';
import { MatterConveyancingExpandationComponent } from './matters/matter-popup/newmatter-detail/matter-conveyancing-expandation/matter-conveyancing-expandation.component';
import { MatterPopupComponent } from './matters/matter-popup/matter-popup.component';
import { MattersModule } from './matters/matters.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MortgageFinanceComponent } from './matters/matter-popup/newmatter-detail/mortgage-finance/mortgage-finance.component';
import { MotorVehicleAccidentComponent } from './matters/matter-popup/newmatter-detail/motor-vehicle-accident/motor-vehicle-accident.component';
import { NgModule } from '@angular/core';
import { OthersComponent } from './matters/matter-popup/others/others.component';
import { PercentPipe } from '@angular/common';
import { PropertyAddressComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-address/property-address.component';
import { PropertyComponent } from './matters/matter-popup/newmatter-detail/property-sale/property.component';

import { PropertyCouncilComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-council/property-council.component';
import { PropertyCrowAllotmentComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-crow-allotment/property-crow-allotment.component';
import { PropertyDetailsExpandationComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-details-expandation.component';
import { PropertyParentTitleComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-parent-title/property-parent-title.component';
import { PropertyPurchaseComponent } from './matters/matter-popup/newmatter-detail/property-purchase/property-purchase.component';
import { PropertyStrataComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-strata/property-strata.component';
import { PropertyTitleComponent } from './matters/matter-popup/newmatter-detail/property-details-expandation/property-title/property-title.component';
import { QRCodeModule } from 'angular2-qrcode';
import { RatesComponent } from './matters/matter-popup/rates/rates.component';
import { ReCalcTimeEntriesDialogeComponent } from './time-billing/re-calc-timeEntrie-dialoge/re-calc-timeEntrie-dialoge.component';
import { ReceiptDilogComponent } from './invoice/receipt-dilog/receipt-dilog.component';
import { RentLeasingComponent } from './matters/matter-popup/newmatter-detail/leasing/leasing-details-popup/rent-leasing/rent-leasing.component';
import { RentReviewLeasingComponent } from './matters/matter-popup/newmatter-detail/leasing/leasing-details-popup/rent-review-leasing/rent-review-leasing.component';
import { ResizableModule } from 'angular-resizable-element';
import { RouterModule, Routes } from '@angular/router';
import { SafeCustodyDialogeComponent } from './legal-details/safecustody/safe-custody-dialog/safe-custody-dialog.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { SearchandreplaceworkitemComponent } from './time-billing/searchandreplaceworkitem/searchandreplaceworkitem.component';
import { SettlementAddressPopupComponent } from './legal-details/settlement/settlement-address-popup/settlement-address-popup.component';
import { SettlmentAddressComponent } from './legal-details/settlement/settlement-address-popup/settlment-address/settlment-address.component';
import { SettlmentCouncilComponent } from './legal-details/settlement/settlement-address-popup/settlment-council/settlment-council.component';
import { SettlmentCrowAllotmentComponent } from './legal-details/settlement/settlement-address-popup/settlment-crow-allotment/settlment-crow-allotment.component';
import { SettlmentParentTitleComponent } from './legal-details/settlement/settlement-address-popup/settlment-parent-title/settlment-parent-title.component';
import { SettlmentStrataComponent } from './legal-details/settlement/settlement-address-popup/settlment-strata/settlment-strata.component';
import { SettlmentTitleComponent } from './legal-details/settlement/settlement-address-popup/settlment-title/settlment-title.component';
import { SortingDialogModule } from '../sorting-dialog/sorting-dialog.module';
import { SpendMoneyAddComponent } from './spend-money/spend-money-add-dialog/spend-money-add.component';
// import { SplitTimeEntryComponent } from './split-time-entry/split-time-entry.component';
import { SplitTimeEntryModule } from './split-time-entry/split-time-entry.module';
import { StrataComponent } from './matters/matter-popup/newmatter-detail/strata/strata.component';
import { SummaryComponent } from './invoice/invoice-add-dailog/summary/summary.component';
import { SystemSettingTemplateModule } from './system-settings/templates/templates.module';
import { TaskDialogeComponent } from './Task/task-dialoge/task-dialoge.component';
import { TemplateModule } from './template/template.module';
import { TimeEntriesModule } from './time-entries/time-entries.module';
import { TopicDialogComponent } from './globally-Authority/main-authorities/topic/topic-dialog/topic-dialog.component';
import { TrademarkIPComponent } from './matters/matter-popup/newmatter-detail/trademark-ip/trademark-ip.component';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { TrustMoneyModule } from './Trust Accounts/trust-money/trust-money.module';
import { TrySilqFreeComponent } from './try-silq-free/try-silq-free.component';
import { UserBudgetDialogComponent } from './users/user-dialog/user-budget-dialog/user-budget-dialog.component';
import { UserDialogComponent } from './users/user-dialog/user-dialog.component';
import { UserSelectPopupComponent } from './matters/user-select-popup/user-select-popup.component';
import { WillsDetailsTabComponent } from './matters/matter-popup/newmatter-detail/wills-estate/wills-details-tab/wills-details-tab.component';
import { WillsEstateComponent } from './matters/matter-popup/newmatter-detail/wills-estate/wills-estate.component';
import { WriteOffInvoiceComponent } from './invoice/newWriteOffInvoice/newWriteOffInvoice.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterPermisstionGuard } from 'src/app/_guards/router-permisstion.guard';
import { RatesOverridesDialogComponent } from './users/user-dialog/rates-overrides-dialog/rates-overrides-dialog.component';
import { ExportProcessComponent } from './matters/matter-popup/newmatter-detail/export-process/export-process.component';
import { MatterMediationComponent } from './matter-mediation/matter-mediation.component';
import { TrainingComponent } from './training/training.component';
import { CutofdateComponent } from './cutofdate/cutofdate.component';
import { DomesticViolenceComponent } from './matters/matter-popup/newmatter-detail/domestic-violence/domestic-violence.component';
import { FirstTimeLoginBasicInformationComponent } from './first-time-login-basic-information/first-time-login-basic-information.component';
import { CurrencyMaskConfig, CurrencyMaskInputMode } from '@directives/currencymask/currency-mask.config';
import { FileDragNDropModule } from '@directives/file-drag-n-drop/file-drag-n-drop.module';
import { NgxCurrencyModule } from '@directives/currencymask/currency-mask.module';
import { PipesModule } from '@_pipes/pipes.module';
import { TemplateViewerModule } from './web-template-viewer/template-viewer.module';
import { CurrencyMaskDirective } from '@directives/currencymask/currency-mask.directive';
import { NumberSeparatorFormatDirective } from '@directives/numberseparator/number-separator-format.directive';
import { NumberSeparatorModule } from '@directives/numberseparator/number-separator.module';
import { NumbersModule } from '@directives/allownumber/numbers.module';
import { NumericdirectiveDirective } from '@directives/allownumber/numericdirective.directive';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CountService, EmojiPickerService, FormatPainterService, HtmlEditorService, ImageService, LinkService, PasteCleanupService, RichTextEditorAllModule , ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { ImageEditorModule } from '@syncfusion/ej2-angular-image-editor';
import { SignatureComponent } from './users/signature/signature/signature.component';
// import { InvoiceModule } from './invoice/invoice.module';
import { DetailsModule } from './invoice/invoice-add-dailog/details/details.module';
import { BankFeedModule } from './bank-feed/bank-feed.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TemplateHeaderFooterModule } from './template-header-footer/template-header-footer.module';

/**
 * This is using for set the route JSON
 */
const appRoutes: Routes = [
    { path: '', redirectTo: '/matters', pathMatch: 'full' },
    { path: 'matters', loadChildren: () => import('./matters/matters.module').then(m => m.MattersModule) },
    { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
    { path: 'task', loadChildren: () => import('./Task/task.module').then(m => m.TaskModule) }, //done lazyload
    { path: 'invoice', loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule) },
    { path: 'searching', loadChildren: () => import('./main-searching/main-searching.module').then(m => m.MainSearchingModule) },
    { path: 'spend-money', loadChildren: () => import('./spend-money/spend-money.module').then(m => m.SpendMoneyModule) },
    { path: 'receive-money', loadChildren: () => import('./receive-money/receive-money.module').then(m => m.ReceiveMoneyModule) },
    { path: 'document-register', loadChildren: () => import('./document-register/document-register.module').then(m => m.DocumentRegitser) }, //done lazyload
    { path: 'chart-account', loadChildren: () => import('./chart-account/chart-account.module').then(m => m.ChartOfAccount) },
    { path: 'general-journal', loadChildren: () => import('./general-journal/general-journal.module').then(m => m.GeneralJoural) },
    { path: 'diary', loadChildren: () => import('./diary/diary.module').then(m => m.DiaryModule) },
    { path: 'create-document', loadChildren: () => import('./template/template.module').then(m => m.TemplateModule) },
    { path: 'legal-details', canActivate: [RouterPermisstionGuard], loadChildren: () => import('./legal-details/legal-details.module').then(m => m.LegalDetailsModule) }, //done lazyload
    { path: 'matter-details', loadChildren: () => import('./legal-details/legal-details.module').then(m => m.LegalDetailsModule) }, //done lazyload
    { path: 'time-billing', loadChildren: () => import('./time-billing/time-billing.module').then(m => m.TimeBillingModule) },
    { path: 'conflict-check', loadChildren: () => import('./conflict-check/conflict-check.module').then(m => m.ConflictCheckModule) }, //done lazyload
    { path: 'system-setting', loadChildren: () => import('./system-settings/system-setting.module').then(m => m.SystemSettingModule) }, //done lazyload
    //{ path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
    { path: 'activities', loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule) },
    { path: 'Firm-Directory', loadChildren: () => import('./user-firm-directory/user-firm-directory.module').then(m => m.UserFirmDirectoryModule) },
    { path: 'main-report', loadChildren: () => import('./main-report/main-report.module').then(m => m.MainReportModule) },
    { path: 'email', loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule) },
    { path: 'account', loadChildren: () => import('./account-transactions/account-transactions.module').then(m => m.AccountTransactionsModule) },
    { path: 'time-entries', loadChildren: () => import('./time-entries/time-entries.module').then(m => m.TimeEntriesModule) },
    { path: 'dashboard', loadChildren: () => import('./main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule) },
    { path: 'trust-end-month', loadChildren: () => import('./Trust Accounts/trust-end-of-month/trust-end-of-month.module').then(m => m.TrustEndOfMonthModule) },
    { path: 'trust-money', loadChildren: () => import('./Trust Accounts/trust-money/trust-money.module').then(m => m.TrustMoneyModule) },
    { path: 'authorities', loadChildren: () => import('./globally-Authority/globally-authority.module').then(m => m.GloballyAuthorityModule) },
    { path: 'full-Safe-Custody', loadChildren: () => import('./globally-safecustody/globally-safecustody.module').then(m => m.GloballySafeCustodyModule) },
    { path: 'account-reconciliation', loadChildren: () => import('./account-reconciliation/account-reconciliation.module').then(m => m.AccountRecountciliationModule) },
    { path: 'account-management', loadChildren: () => import('./account-managment/account-managment.module').then(m => m.AccountManagmentModule) },
    { path: 'audit-logs', loadChildren: () => import('./audit-logs/audit-logs.module').then(m => m.AuditLogsModule) },
    { path: 'admin-data/NSWCOL', component: CollegeofLawComponent },
    { path: 'admin-data/complete_signup', component: TrySilqFreeComponent },
    { path: 'multi-factor-authentication', loadChildren: () => import('./multi-factor-authentication/multi-factor-authentication.module').then(m => m.MultiFactorAuthenticationModule) },
    { path: 'my-profile', loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule) },
    { path: 'bank-feed', loadChildren: () => import('./bank-feed/bank-feed.module').then(m => m.BankFeedModule) },
];

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ".",
    precision: 2,
    prefix: "^^",
    suffix: "",
    thousands: ",",
    nullable: false,
    inputMode: CurrencyMaskInputMode.NATURAL,
};

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [SignatureComponent,CollegeofLawComponent, TrySilqFreeComponent, RatesOverridesDialogComponent, CutofdateComponent,
        //for lazylaod
        TaskDialogeComponent,
        ActivityDialogComponent,
        ActivitySundryDialogComponent,
        AuthorityDialogComponent,
        TopicDialogComponent,
        SpendMoneyAddComponent,
        UserDialogComponent,
        UserBudgetDialogComponent,
        InvoiceDetailComponent,
        ReceiptDilogComponent,
        GenerateInvoiceComponent,
        CalculateInterestPopupComponent,
        WriteOffInvoiceComponent,
        InstantInvoiceDailogComponent,
        GeneralReceiptDilogComponent,
        MatterPopupComponent,
        GeneralComponent,
        ClientComponent,
        MatterClientComponent,
        DebtRecoveryComponent,
        RatesComponent,
        CaseDetailComponent,
        CommercialComponent,
        CompensationComponent,
        CriminalComponent,
        FamilyComponent,
        ImmigrationComponent,
        LitigationComponent,
        MaritimeComponent,
        DomesticViolenceComponent,
        MortgageFinanceComponent,
        MotorVehicleAccidentComponent,
        CompulsoryAcquisitionComponent,
        LeasingComponent,
        PropertyComponent,
        ExportProcessComponent,
        MatterMediationComponent,
        TrainingComponent,
        PropertyPurchaseComponent,
        StrataComponent,
        WillsEstateComponent,
        TrademarkIPComponent,
        OthersComponent,
        AddRateComponent,
        CorrespondDailogComponent,
        BrowseFolderPopupComponent,
        FileNoteDialogComponent,
        InvoiceAddDailogComponent,
        SummaryComponent,
        DiscountIncreaseComponent,
        ForeignCurrencyComponent,
        CheckForCreditsComponent,
        UserSelectPopupComponent,
        ReCalcTimeEntriesDialogeComponent,
        EstimateDilogComponent,

        SafeCustodyDialogeComponent,
        AllowancePopupComponent,
        ChequePopupComponent,
        EstateAssetsComponent,
        SettlementAddressPopupComponent,
        SettlmentAddressComponent,
        SettlmentTitleComponent,
        SettlmentCrowAllotmentComponent,
        SettlmentCouncilComponent,
        SettlmentParentTitleComponent,
        SettlmentStrataComponent,
        LeasingDetailsPopupComponent,
        GeneralLeasingComponent,
        RentLeasingComponent,
        FutureYearsLeasingComponent,
        RentReviewLeasingComponent,
        GeneralWillTabComponent,
        WillsDetailsTabComponent,
        PropertyDetailsExpandationComponent,
        PropertyAddressComponent,
        PropertyCouncilComponent,
        PropertyCrowAllotmentComponent,
        PropertyParentTitleComponent,
        PropertyStrataComponent,
        PropertyTitleComponent,
        MatterConveyancingExpandationComponent,
        ConveyancingContractComponent,
        ConveyancingConcessionsDutyComponent,
        ConveyancingSettlementComponent,
        ConveyancingPriceDepositeComponent,
        // SelectDocumentsComponent,
        GenerateTemplatesDialoagComponent,
        ChronItemDailogComponent,
        EstateAssetsComponent,
        EstateLiabilityComponent,
        EstateBeneficiaryComponent,
        EstateDetailsComponent,
        EnableMfaComponent,
        EmailTemplateDilogComponent,
        SearchandreplaceworkitemComponent,
        FirstTimeLoginBasicInformationComponent
    ],
    entryComponents: [
        RatesOverridesDialogComponent,
        RatesComponent,
        TaskDialogeComponent,
        ActivityDialogComponent,
        ActivitySundryDialogComponent,
        AuthorityDialogComponent,
        TopicDialogComponent,
        SpendMoneyAddComponent,
        UserDialogComponent,
        UserBudgetDialogComponent,
        InvoiceDetailComponent,
        ReceiptDilogComponent,
        GenerateInvoiceComponent,
        CalculateInterestPopupComponent,
        WriteOffInvoiceComponent,
        InstantInvoiceDailogComponent,
        GeneralReceiptDilogComponent,
        MatterPopupComponent,
        AddRateComponent,
        CorrespondDailogComponent,
        BrowseFolderPopupComponent,
        FileNoteDialogComponent,
        InvoiceAddDailogComponent,
        UserSelectPopupComponent,
        ReCalcTimeEntriesDialogeComponent,
        EstimateDilogComponent,
        DeliverableDailogComponent,
        ChronItemDailogComponent,
        SafeCustodyDialogeComponent,
        AllowancePopupComponent,
        ChequePopupComponent,
        SettlementAddressPopupComponent,
        CheckForCreditsComponent,
        GenerateTemplatesDialoagComponent,
        LeasingDetailsPopupComponent,
        EstateAssetsComponent,
        EstateLiabilityComponent,
        EstateBeneficiaryComponent,
        EstateDetailsComponent,
        EnableMfaComponent,
        EmailTemplateDilogComponent,
        SearchandreplaceworkitemComponent,
        FirstTimeLoginBasicInformationComponent,
        CurrencyMaskDirective,
        NumberSeparatorFormatDirective,
        NumericdirectiveDirective
    ],
    imports: [
        NgApexchartsModule,
        ImageEditorModule,
        CommonModule,
        DetailsModule,
        // InvoiceModule,
        NgxCurrencyModule,
        NumberSeparatorModule,
        NumbersModule,
        RouterModule.forChild(appRoutes),
        MattersModule,
        ContactModule,
        BankFeedModule,
        TemplateModule,
        ChartOfAccount,
        GeneralJoural,
        DiaryModule,
        SystemSettingTemplateModule,

        TrustMoneyModule,
        AccountManagmentModule,
        TimeEntriesModule,
        GloballySafeCustodyModule,
        AccountRecountciliationModule,
        SortingDialogModule,
        BankingDialogModule,
        CloseYearAccountsDialogModule,
        DocumentRegitser,
        MatDatepickerModule,
        MatProgressBarModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        SplitTimeEntryModule,
        DragDropModule,
        MatPaginatorModule,
        MatDividerModule,
        MatDialogModule,
        MatMenuModule,
        MatTableModule,
        MatToolbarModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatSortModule,

        FuseProgressBarModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MatTabsModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        SatDatepickerModule,
        SatNativeDateModule,
        ResizableModule,
        TreeGridModule,
        ApplicationPipesModule,
        DropDownTreeModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        QRCodeModule,
        MatListModule,
        MatIconModule,
        MatSelectModule,
        MatTooltipModule,
        PipesModule,
        MatChipsModule,
        FileDragNDropModule,
        MatButtonToggleModule,
        CKEditorModule,
        MatSlideToggleModule,
        FormsModule,
        TemplateViewerModule,
        TemplateHeaderFooterModule,
        NgxMaskModule.forRoot(maskConfig),
        RichTextEditorAllModule
    ], exports: [
        FormsModule,
    ],
    providers: [
        CurrencyMaskDirective,
        NumberSeparatorFormatDirective,
        NumericdirectiveDirective,
        //{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        PercentPipe,
        ToolbarService, LinkService, ImageService, HtmlEditorService, EmojiPickerService, FormatPainterService, CountService, PasteCleanupService

    ],


})
export class PagesModule { }
