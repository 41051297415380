import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { retryWhen, delay, take } from "rxjs/operators";
import * as moment from "moment";
import { FileSaverService } from "ngx-filesaver";
import { MainAPiServiceService } from "./main-api-service.service";
import { BehaviorService } from "./Behavior.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class TableColumnsService {
    columnsListTableName = {
        trustmoney_list_columns: ["trust money", ""],
        Protected_trust_list_columns: ["trust money", "protected"],
        receivemoney_list_columns: ["receive money", ""],
        spendmoney_list_columns: ["spend money", ""],
        matters_list_columns: ["matters", ""],
        mainsearch_list_columns: ["searching", ""],
        invoice_list_columns: ["invoices", ""],
        timeentries_list_columns: ["time entries", ""],
        contact_list_columns: ["contacts", ""],
        wip_list_columns: ["time and billing", "work in progress"],
        maintask_list_columns: ["Tasks", ""],
        matterinvoice_list_columns: ["time and billing", "matter invoices"],
        mainsafecustody_list_columns: ["Safe Custody", "Safe Custody"],
        packets_list_columns: ["Safe Custody", "Packets"],
        deliverable_list_columns: ["time and billing", "deliverable"],
        estimate_list_columns: ["time and billing", "estimate"],
        chronology_list_columns: ["legal details", "chronology"],
        safecustody_list_columns: ["legal details", "Safe Custody"],
        file_note_list_columns: ["legal details", "file notes"],
        generaljournal_list_columns: ["general journal", ""],
        mattertrust_list_columns: ["time and billing", "trust"],
        legalsearch_list_columns: ["legal details", "searching"],
        legaltask_list_columns: ["legal details", "tasks"],
        user_list_columns: ["Users", ""],
        matterdoc_list_columns: ["Matter Documents", ""],
    };

    columns = [];
    selectedCellWidth: number = 0;
    TblHeaderCellList: any;
    currentResizeIndex: number;
    startX: number;
    startWidth: number;
    isResizingRight: boolean;
    matTableRef: any;
    renderer: any;
    ColumnsObj: any = [];

    resizableMousemove: () => void;
    resizableMouseup: () => void;
    pressed: any;
    columnLocalStorage: any;

    constructor(
        private httpClient: HttpClient,
        private toastr: ToastrService,
        private fileSaverService: FileSaverService,private http: HttpClient,private _mainAPiServiceService: MainAPiServiceService,
        private behaviorService:BehaviorService,private router:Router
    ) { }

    /**
     * This function is used to get the table filter
     * @param table -table data value
     * @param List -list data
     * @returns -data value
     */
    getTableFilter(table: any, List: any) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let Data = {
            Action: "GetData",
            Filters: {
                USERGUID: currentUser.UserGuid,
                PAGE: table,
                LIST: List,
            },
        };
        // return this.httpClient.post<any>(environment.APIEndpoint + 'GetTableColumns', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
        return this.httpClient
            .post<any>(
                environment.APIEndpointDeliverable + "table-column",
                Data
            )
            .pipe(retryWhen((errors) => errors.pipe(delay(4000), take(3))));
    }

    /**
     * This function is used to load the get column filter value
     * @param columnsListName -list name value
     * @returns data value
     */
    async loadGetColumnFilter(columnsListName) {
        const localColumnsList = localStorage.getItem(columnsListName);
        if (localColumnsList) {
            return false;
        }

        return new Promise((res, rej) => {
            this.getTableFilter(
                this.columnsListTableName[columnsListName][0],
                this.columnsListTableName[columnsListName][1]
            ).subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        let data = this.filtertableColum(response.DATA.RECORDS);
                        localStorage.setItem(
                            columnsListName,
                            JSON.stringify({
                                ColumnsObj: data.colobj,
                                displayedColumns: data.showcol,
                            })
                        );
                        res(data);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                    res(false);
                }
            );
        });
    }

    /**
     * This function is used to get the hyperlink inormation data value
     * @param response -response data values
     * @returns data value
     */
    hyperLinkInfo(response: any) {
        let linktype: any = [];
        response.forEach((element) => {
            if (element && element.LINKTYPE != "") {
                linktype[
                    element.COLUMNID ? element.COLUMNID : element.RECORDS
                ] = element;
            }
        });
        return linktype;
    }


    /**
     * This function is used to filter the table using column
     * @param response -response data value
     * @returns data value
     */
    filtertableColum(response: any) {
        let tempCol: any = [];
        let tempColobj: any = [];
        let showCol: any = [];
        let dateCol: any = [];
        let temshowCol: any = [];
        let finalList = response.sort(function (a, b) {
            return a.POSITION - b.POSITION;
        });
        finalList.forEach((itemsdata) => {
            if (itemsdata.ISDATE && itemsdata.ISDATE == 1)
                dateCol.push(itemsdata.COLUMNID);
            if (itemsdata.HIDDEN == 1 || itemsdata.HIDDEN == 0) {
                if (!temshowCol.includes(itemsdata.COLUMNID)) {
                    if (
                        itemsdata.HIDDEN == 0 &&
                        !showCol.includes(itemsdata.COLUMNID)
                    ) {
                        showCol.push(itemsdata.COLUMNID);
                    }
                    tempColobj[itemsdata.COLUMNID] = itemsdata;
                    tempCol.push(itemsdata);
                }
                temshowCol.push(itemsdata.COLUMNID);
            }
        });

        return {
            colobj: tempCol,
            showcol: showCol,
            tempColobj: tempColobj,
            dateCol: dateCol,
        };
    }

    /**
     * This function is used to set the table filter data value 
     * @param table -table data value
     * @param list -list data value
     * @param postData -post data value
     * @returns data value
     */
    setTableFilter(table: any, list: any, postData: any) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let Data = {
            Action: "Update",
            Data: {
                USERGUID: currentUser.UserGuid,
                PAGE: table,
                LIST: list,
                COLUMNSETTINGS: postData,
            },
        };
        // let Data = { 'USERGUID': currentUser.UserGuid, 'PAGE': table, 'LIST': list, 'COLUMNSETTINGS': postData };
        // return this.httpClient.post<any>(environment.APIEndpoint + 'SetTableColumns', Data).pipe(retryWhen(errors => errors.pipe(delay(4000), take(3))));
        return this.httpClient
            .post<any>(
                environment.APIEndpointDeliverable + "table-column",
                Data
            )
            .pipe(retryWhen((errors) => errors.pipe(delay(4000), take(3))));
    }

    /**
     * This unction is used to get the column name
     * @param data -data value
     * @returns data value
     */
    getColumename(data: any):void {
        return data;
    }

    /**
     * This function is used to export the data to the CSV 
     */
    exportDataIntoCsv(
        displayedColumns: any,
        data: any,
        fileName: any,
        tempColobj: any
    ) {
        let filename = fileName + new Date();
        let csvData = this.ConvertToCSV(data, displayedColumns, tempColobj);
        let blob = new Blob(["\ufeff" + csvData], {
            type: "text/csv;charset=utf-8;",
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser =
            navigator.userAgent.indexOf("Safari") != -1 &&
            navigator.userAgent.indexOf("Chrome") == -1;
        if (isSafariBrowser) {
            //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    /**
     *This function is used to Convert to CSV 
     */
    ConvertToCSV(objArray: any, headerList: any, tempColobj: any) {
        let array =
            typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        let str = "";
        let row: any = "S.No,";
        for (let index in headerList) {
            let temH: any = headerList[index];
            if (temH != "select") row += tempColobj[temH]?.DESCRIPTION + ",";
        }
        row = row.slice(0, -1);
        str += row + "\r\n";
        for (let i = 0; i < array.length; i++) {
            let line = i + 1 + "";
            for (let index in headerList) {
                let head = headerList[index];
                if (head != "select") {
                    if (
                        tempColobj[head]?.CURRENCY == 1 ||
                        tempColobj[head]?.CURRENCY == true
                    ) {
                        let temAm = this.transform(
                            array[i][head],
                            tempColobj[head]?.CURRENCY
                        );
                        line += "," + temAm;
                    } else {
                        let tempString =
                            typeof array[i][head] == "undefined" ||
                                array[i][head] == ""
                                ? ""
                                : '"' + array[i][head] + '"';
                        line += "," + tempString;
                    }
                }
            }
            str += line + "\r\n";
        }
        return str;
    }

    /**
     * This function is used to transorm the data value 
     */
    transform(value: any, args: any = false): any {
        let tempVal = value;
        if (typeof value == "string" && !value) {
            tempVal = 0;
        }
        if (args) {
            tempVal = typeof value == "undefined" ? 0 : tempVal;
            let tem: any = parseFloat(tempVal).toFixed(2);
            return "$" + tem;
        } else return value;
    }

    /**
     * This function is used to Save the width data value 
     */
    SaveWidthData(widthData, modelType, list):void {
        let dataObj = [];
        let POSITIONData = 1;
        widthData.forEach((itemsdata) => {
            dataObj.push({
                COLUMNNAME: itemsdata?.COLUMNNAME,
                HIDDEN: itemsdata?.HIDDEN,
                POSITION: POSITIONData,
                WIDTH: itemsdata?.WIDTH,
            });
            POSITIONData++;
        });
        this.setTableFilter(modelType, list, dataObj).subscribe(
            (response) => {
                if (response.CODE == 200 && response.STATUS == "success") {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * This function is used to convert the Chart data to the CSV 
     */
    convertChartDataToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
        data = args.data.chart || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ",";
        lineDelimiter = args.lineDelimiter || "\n";
        keys = Object.keys(data[0]);
        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    /**
     * This function is used to download the chart to the CSV 
     */
    downloadChartCSV(args) {
        var data, filename, link;
        var csv = "";
        // for (var i = 0; i < [{ x: 10, y: 71 }].length; i++) {
        csv += this.convertChartDataToCSV({
            data: args,
        });
        // }
        if (csv == null) return;
        filename = args.filename || "chart-data.csv";
        if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
        }

        data = encodeURI(csv);
        link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename);
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
    }

    /**
     * This function is used to get the dynamic filter
     */
    async getDynamicFilter(opFilterList, optionFilters, columnsListName) {
        let optionalFilterList = optionFilters;
        if (!opFilterList) {
            await this.loadGetColumnFilter(columnsListName).then(() => {
                const getFilterInterval = setInterval(async () => {
                    opFilterList = JSON.parse(
                        localStorage.getItem(columnsListName)
                    );
                    if (opFilterList) {
                        await opFilterList.ColumnsObj.forEach((data, i) => {
                            if (
                                optionalFilterList.findIndex(
                                    (e) => e.COLUMNID == data.COLUMNID
                                ) == -1
                            )
                                optionalFilterList.push({
                                    COLUMNID: data?.COLUMNID,
                                    COLUMNNAME: data?.COLUMNNAME,
                                    FILTERTYPE: data?.FILTERTYPE,
                                    FILTERLIST: data?.FILTERLIST,
                                    DESCRIPTION: data?.DESCRIPTION,
                                    FILTERINDEX: i,
                                    FILTERAPPLY: false,
                                });
                        });
                        clearInterval(getFilterInterval);
                        return optionalFilterList.sort((a, b) =>
                            a.DESCRIPTION.localeCompare(b.DESCRIPTION)
                        );
                    }
                }, 1000);
            });
        }

        if (opFilterList) {
            await opFilterList.ColumnsObj.forEach(async (data, i) => {
                if (
                    optionalFilterList.findIndex(
                        (e) => e.COLUMNID == data.COLUMNID
                    ) == -1
                )
                    optionalFilterList.push({
                        COLUMNID: data.COLUMNID,
                        COLUMNNAME: data.COLUMNNAME,
                        FILTERTYPE: data.FILTERTYPE,
                        FILTERLIST: data.FILTERLIST,
                        DESCRIPTION: data.DESCRIPTION,
                        FILTERINDEX: i,
                        FILTERAPPLY: false,
                    });
            });

            return optionalFilterList.sort((a, b) =>
                a.DESCRIPTION.localeCompare(b.DESCRIPTION)
            );
        }
    }

    /**
     * This function is used to Set the optional filter
     */
    Optionalfilter(addData, isIndex, optionalDataPayload, userFilterForm) {
        const OptionalFilterPayload = Object.assign(optionalDataPayload);
        let index: any | -1 = OptionalFilterPayload.findIndex((indx) => {
            if (indx) {
                return (
                    indx["value"] !== null &&
                    indx["value"].COLUMNID == addData.COLUMNID
                );
            } else {
                return;
            }
        });

        // When some filter is blank
        userFilterForm.value.FILTERS.forEach((el, indx) => {
            if (el.VALUE == "") {
                let temVal = {
                    index: indx,
                    value: null,
                };

                if (
                    OptionalFilterPayload.findIndex(
                        (nIndex, Findx) =>
                            Findx == indx && nIndex["value"] == null
                    ) == -1
                ) {
                    OptionalFilterPayload.push(temVal);
                }
            }
        });

        let nullIndex = OptionalFilterPayload.findIndex(
            (nIndex, indx) => indx == isIndex && nIndex["value"] == null
        );
        if (index == -1 && nullIndex == -1) {
            let date1 = "";
            let date2 = "";
            let duplicateDate = userFilterForm.value.FILTERS[isIndex];

            if (addData && index == -1) {
                if (addData.FILTERTYPE == "DATE") {
                    date1 =
                        duplicateDate && duplicateDate.VALUE !== ""
                            ? moment(duplicateDate.VALUE).format("DD/MM/YYYY")
                            : "";
                    date2 =
                        duplicateDate &&
                            duplicateDate.VALUE2 !== "" &&
                            addData.selectedFilter == "is between"
                            ? moment(duplicateDate.VALUE2).format("DD/MM/YYYY")
                            : "";
                    if (addData.selectedFilter == "is in the last") {
                        duplicateDate.VALUE = duplicateDate.VALUE;
                        duplicateDate.VALUE2 = duplicateDate.VALUE2;
                    } else {
                        duplicateDate.VALUE = date1;
                        duplicateDate.VALUE2 = date2;
                    }
                    duplicateDate.COLUMNNAME = addData.COLUMNNAME;
                    duplicateDate.COLUMNID = addData.COLUMNID;
                } else {
                    duplicateDate.COLUMNNAME = addData.COLUMNNAME;
                    duplicateDate.COLUMNID = addData.COLUMNID;
                }

                duplicateDate.OPERATION = addData.selectedFilter;

                let temValue = {
                    index: isIndex,
                    value: duplicateDate,
                };
                OptionalFilterPayload.push(temValue);
            }
        } else {
            if (OptionalFilterPayload[isIndex]["value"] == null) {
                OptionalFilterPayload[isIndex]["value"] =
                    userFilterForm.value.FILTERS[isIndex];
            }

            if (addData.FILTERTYPE == "DATE") {
                let date1 =
                    userFilterForm.value.FILTERS[isIndex].VALUE !== ""
                        ? moment(
                            userFilterForm.value.FILTERS[isIndex].VALUE
                        ).format("DD/MM/YYYY")
                        : "";
                let date2 =
                    userFilterForm.value.FILTERS[isIndex].VALUE2 !== "" &&
                        addData.selectedFilter == "is between"
                        ? moment(
                            userFilterForm.value.FILTERS[isIndex].VALUE2
                        ).format("DD/MM/YYYY")
                        : "";

                if (addData.selectedFilter == "is in the last") {
                    OptionalFilterPayload[isIndex]["value"].VALUE =
                        userFilterForm.value.FILTERS[isIndex].VALUE;
                    OptionalFilterPayload[isIndex]["value"].VALUE2 =
                        userFilterForm.value.FILTERS[isIndex].VALUE2;
                } else {
                    OptionalFilterPayload[isIndex]["value"].VALUE = date1;
                    OptionalFilterPayload[isIndex]["value"].VALUE2 = date2;
                }
                OptionalFilterPayload[isIndex]["value"].COLUMNNAME =
                    addData.COLUMNNAME;
                OptionalFilterPayload[isIndex]["value"].COLUMNID =
                    addData.COLUMNID;
                OptionalFilterPayload[isIndex]["value"].OPERATION =
                    addData.selectedFilter;
            } else {
                OptionalFilterPayload[isIndex]["value"].VALUE =
                    userFilterForm.value.FILTERS[isIndex].VALUE;
                OptionalFilterPayload[isIndex]["value"].VALUE2 =
                    userFilterForm.value.FILTERS[isIndex].VALUE2 == null
                        ? (userFilterForm.value.FILTERS[isIndex].VALUE2 = "")
                        : userFilterForm.value.FILTERS[isIndex].VALUE2;
                OptionalFilterPayload[isIndex]["value"].COLUMNNAME =
                    addData.COLUMNNAME;
                OptionalFilterPayload[isIndex]["value"].COLUMNID =
                    addData.COLUMNID;
                OptionalFilterPayload[isIndex]["value"].OPERATION =
                    addData.selectedFilter;
            }
        }

        return OptionalFilterPayload;
    }

    /**
     * This function is used to set the Do filter value
     */
    optionalDOFilters(mainFilter, activeFilters) {
        const payloadData = JSON.parse(
            JSON.stringify(Object.assign(mainFilter))
        );
        let activeFiltersData = Object.assign(activeFilters);
        const finalPayload = payloadData
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    delete e["value"].COLUMNID;
                    return e["value"];
                }
            });

        if (!activeFiltersData) {
            let copyOfFilter = activeFiltersData;
            copyOfFilter.subscribe((data) => {
                activeFiltersData = data;
            });
        }
        const doFilter = activeFiltersData;
        // const doFilter = JSON.parse(JSON.stringify(activeFiltersData));
        doFilter.FILTERS = finalPayload;

        return finalPayload;
    }

    /**
     * This function is used to check the validation data
     */
    checkValidation(value, value2, OPERATION, filtertype) {
        if (value == "" || value == null) {
            this.toastr.error("Error", "Please fill required field for Filter");
            return false;
        } else if (
            (value2 == "" || value2 == "" || value2 == null) &&
            (filtertype == "DATE" || filtertype == "NUMBER") &&
            (OPERATION == "is in the last" || OPERATION == "is between")
        ) {
            this.toastr.error("Error", "Please fill required field for filter");
            return false;
        } else if (OPERATION == "") {
            this.toastr.error(
                "Error",
                "Please select the Operation for filter"
            );
            return false;
        }
        return true;
    }

    /**
     * This function is used to set the Advanced filter
     */
    async setAdvanceFilter(
        saveAdvancefilterData,
        saveAdvanceFilter,
        isIndex,
        FilterData,
        field1Val,
        field2Val,
        operationVal,
        filterTypeVal,
        applyFilterData,
        storageName
    ) {
        if (saveAdvancefilterData) {
            if (!saveAdvancefilterData[isIndex]) {
                await saveAdvanceFilter.push(FilterData);
                saveAdvancefilterData = saveAdvancefilterData.concat(
                    saveAdvanceFilter[isIndex]
                );
            }

            saveAdvancefilterData[isIndex].AdvancedFilter.VALUE = field1Val;
            saveAdvancefilterData[isIndex].AdvancedFilter.VALUE2 = field2Val;
            saveAdvancefilterData[isIndex].AdvancedFilter.OPERATION =
                operationVal;
            saveAdvancefilterData[isIndex].AdvancedFilter.FILTERTYPE =
                filterTypeVal;

            saveAdvancefilterData[isIndex].FilterHeadings =
                applyFilterData[isIndex];
            saveAdvancefilterData[isIndex].selectedFilter = operationVal;
            localStorage.setItem(
                `${storageName}_Advance_Filter`,
                JSON.stringify(saveAdvancefilterData)
            );
        }
    }

    /**
     * This function is used to refresh the advanced filter
     */
    async refrshAdvanceFilter(event, storageName) {
        if (JSON.parse(localStorage.getItem(`${storageName}_Advance_Filter`))) {
            let filterData = JSON.parse(
                localStorage.getItem(`${storageName}_Advance_Filter`)
            );
            let Findex = filterData.findIndex(
                (e) => e.COLUMNID == event.COLUMNID
            );
            filterData.splice(Findex, 1);
            localStorage.setItem(
                `${storageName}_Advance_Filter`,
                JSON.stringify(filterData)
            );
        }
    }

    /**
     * This function is used to load the advanced filter data value
     */
    loadAdvanceFilterPayload(storageName) {
        let PayloadForAdvFilter = JSON.parse(
            localStorage.getItem(`${storageName}_Advance_Filter`)
        );
        if (PayloadForAdvFilter) {
            return PayloadForAdvFilter.map((advFilterData) => {
                if (advFilterData && advFilterData.FilterHeadings) {
                    delete advFilterData.FilterHeadings.value.COLUMNID;
                    return advFilterData.FilterHeadings.value;
                }
            });
        } else {
            return null;
        }
    }

    /**
     * This function is used to onResize the column
     */
    onResizeColumn(
        event,
        index,
        renderer,
        displayedColumns,
        matTableRef,
        ColumnsObj,
        localstoragename?: any
    ) {
        this.columns = displayedColumns;
        this.matTableRef = matTableRef;
        this.renderer = renderer;
        this.ColumnsObj = ColumnsObj;
        this.columnLocalStorage = localstoragename;

        this.checkResizing(event, index);
        this.currentResizeIndex = index;
        this.pressed = true;
        this.startX = event.pageX;
        let selectedCell = event.target.parentElement.children[index];
        this.TblHeaderCellList = event.target.parentElement.children;
        this.startWidth = selectedCell?.getBoundingClientRect().width;
        event.preventDefault();
        this.mouseMove(index);
    }

    /**
     * This function is used to check the Resizing data value
     */
    private checkResizing(event, index) {
        const cellData = this.getCellData(index);
        if (
            index === 0 ||
            (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
                index !== this.columns.length - 1)
        ) {
            this.isResizingRight = true;
        } else {
            this.isResizingRight = false;
        }
    }

    /**
     * This function is used to get the Cell data value
     */
    private getCellData(index: number) {
        const headerRow =
            this.matTableRef.nativeElement.children[0].querySelectorAll(
                "mat-header-cell"
            );
        const cell = headerRow[index];
        this.selectedCellWidth = cell.getBoundingClientRect().width;
        return cell.getBoundingClientRect();
    }

    /**
     * This function is used to move the mouse
     */
    mouseMove(index: number) {
        this.resizableMousemove = this.renderer.listen(
            "document",
            "mousemove",
            (event) => {
                if (this.pressed && event.buttons) {
                    const dx = this.isResizingRight
                        ? event.pageX - this.startX
                        : -event.pageX + this.startX;
                    const width = this.startWidth + dx;
                    if (this.currentResizeIndex === index && width > 50) {
                        this.setColumnWidthChanges(index, width);
                    }
                }
            }
        );

        this.resizableMouseup = this.renderer.listen(
            "document",
            "mouseup",
            (event) => {
                if (this.pressed) {
                    this.pressed = false;
                    this.currentResizeIndex = -1;
                    this.resizableMousemove();
                    this.resizableMouseup();
                }
            }
        );
    }

    /**
     * This function is used to set the column width data value
     */
    setColumnWidthChanges(index: number, width: number) {
        const orgWidth = this.selectedCellWidth;
        const dx = width - orgWidth;
        if (dx !== 0) {
            const j = this.isResizingRight ? index + 1 : index - 1;
            const newWidth =
                this.TblHeaderCellList[j].getBoundingClientRect().width - dx;
            if (newWidth > 50) {
                this.setColumnWidth(this.columns[index], width, index);
            }
        }
    }

    /**
     * This function is used to set table resize
     */
    setTableResize(tableWidth: number) {
        let totWidth = 0;
        this.columns.forEach((column, index) => {
            totWidth +=
                this.TblHeaderCellList[index]?.getBoundingClientRect().width;
        });

        const scale = (tableWidth - this.columns.length - 1) / totWidth;
        this.columns.forEach((column, index) => {
            this.TblHeaderCellList[index].getBoundingClientRect().width *=
                scale;
            this.setColumnWidth(
                this.columns[index],
                this.TblHeaderCellList[index].getBoundingClientRect().width,
                index
            );
        });
    }

    /**
     * This function is used ot
     */
    setColumnWidth(column: any, width: any, index: number) {
        const columnEls = Array.from(
            document.getElementsByClassName("mat-column-" + column)
        );
        columnEls.forEach((el: HTMLDivElement) => {
            el.style.width = width + "px";
            this.ColumnsObj[index]["WIDTH"] = width;
            localStorage.setItem(
                this.columnLocalStorage,
                JSON.stringify({
                    ColumnsObj: this.ColumnsObj,
                    displayedColumns: this.columns,
                })
            );
            // this.SaveWidthData(this.ColumnsObj, 'matters', '');
        });
    }

    // setColumnWidthForNext(column: any , width : any) {
    //   const columnEls = Array.from( document.getElementsByClassName('mat-column-' + column) );
    //   columnEls.forEach(( el: HTMLDivElement ) => {
    //     el.style.width = width + 'px';
    //   });
    // }

    /**
 * This function is used to getNewExportData;
 */
   async getNewExportData(Page,List,API,Filters,AdvancedFilters,columnname?,direction?){
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let Payload={
            "Action": "Export",
            "Data": {
                "ExportFormat": "csv",
                "IncludeHeaders": true,
                "UserGuid": currentUser.UserGuid,
                "Page": Page,
                "List": List,
                "SortByColumnName": columnname,
                "SortOrder": (direction == 'asc' ? 'ascending' : (direction !=='') ? 'descending' : '')
            },
            "Filters":Filters,
            "AdvancedFilters":AdvancedFilters
        }

        // if(this.router.url=='/matters' || this.router.url=='/contact'){
        //     Payload.Data.SortByColumnName=columnname;
        //     Payload.Data.SortOrder=(direction == 'asc'? 'ascending' : 'descending')
        // }
        if(AdvancedFilters == undefined || AdvancedFilters == null || AdvancedFilters == ''){
          delete Payload.AdvancedFilters
        }
        if(AdvancedFilters == null || AdvancedFilters.length == 0){
            delete Payload.AdvancedFilters
          }
      await  this._mainAPiServiceService.getSetData(Payload, API).subscribe(data=>{
            if(data?.STATUS == 'success' && data?.CODE ==200){
                // `${API}s-${currentUser.UserGuid}.csv`
              this.downloadFileDATA(data?.DATA?.DOCUMENTNAME);
              this.behaviorService.loaderDATA$.next(false);
            }else{
                this.behaviorService.loaderDATA$.next(false);
            }
        },error=>{
            this.behaviorService.loaderDATA$.next(false);
        })
      }

      /**
       * This function is used to download the file Data value
       */
      downloadFileDATA(url: string) {
        const filename = this.extractFilename(url);
        this.http.get(url, { responseType: 'blob' }).subscribe(
          (response: Blob) => {
            this.fileSaverService.save(response, filename);
          },
          error => {
            console.error('Download error:', error);
          }
        );
      }

      /**
       * This function is used to extract the file name
       */
    extractFilename(url: string): string {
        // Split the URL by '/' and get the last segment, then split by '?' to remove any query parameters
        return url.split('/').pop()?.split('?')[0] || '';
    }

    getFileExtension(filename: string): string {
        return filename.split('.').pop() || '';
    }
}
