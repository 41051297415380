import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, AfterViewInit, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SortingDialogComponent } from 'src/app/main/sorting-dialog/sorting-dialog.component';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ChartAcDailogComponent } from '../../chart-account/chart-ac-dailog/chart-ac-dailog.component';
import { ResizeEvent } from 'angular-resizable-element';
import * as moment from 'moment';
import { FilterSearchOptions } from "src/app/_constant/dynamic-search-option.const";
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TableColumnsService } from '@_services/table-columns.service';
import { BehaviorService } from '@_services/Behavior.service';
import { SortingBehaviourService } from '@_services/sorting-behaviour.service';
import { HyperlinkNavigationService } from '@_services/hyperlink-navigation.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-trust-money' ,
  templateUrl: './trust-money.component.html',
  styleUrls: ['./trust-money.component.scss'],
  animations: fuseAnimations
})
export class TrustMoneyComponent implements OnInit, AfterViewInit {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  @Input() errorWarningData: any;
  @ViewChild(MatTable, {read: ElementRef} ) private matTableRef: ElementRef;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isLoadingResults: boolean = false;
  isDisplay: boolean = false;
  UNBANKEDNOTICEData: any = '';
  pageSize: any;
  displayedColumns: any;
  tempColobj: any;
  ColumnsObj = [];
  filterData: any = [];
  TrustMoneyData: any = [];
  dateColFilter = [];
  TrustMoneyForm: FormGroup;
  detailHeight: any;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  highlightedRows: any;
  forHideShowDateRangePicker: string;
  IsTrustMoneyList: boolean = false;
  sortingDefaultState: any = {};
  sortactive: any;
  sortDirection: any;
  hasDateRange: boolean = false;
  hasSearchVal: boolean = false;
  isGetallData: any = false;
  isCallDownloadFunction:boolean=false
  optionalFilterList = [];
  FILTERS: FormArray;
  FILTERSLIST: FormArray;
  addFilter;
  _filterList = [];
  selectedFilterIs: any;
  optionalFilters = [];
  activeOptionalFilter: any;
  activeFilters;
  daterangedata: any;
  clearFilters=false;
  optionalDateFilterPayload = [];
  optionalFilterAppply = true;
  currentUserData: any;
  showFiltersList:boolean = true;
  saveAdvanceFilter = [];

  totalApplicableFilterCount: number = 0;
  hyperLinkInfo: any = {};
  Object: any = {};
  COLOR: any;
  HyperlinkColor: any;
  resizableMousemove: () => void;
  resizableMouseup: () => void;
  class: any;
  public SORT$=this.SortingbehaviorService.TrustMoneyScreenSorting$.asObservable().pipe(map(result=>{
    if (result) {
          this.sortingDefaultState = result;
          localStorage.setItem('trust_money_screen_sorting', JSON.stringify(result));
        } else {
          this.sortingDefaultState = JSON.parse(localStorage.getItem('trust_money_screen_sorting'))
        }
  }))
  
 public TMoneyFilter$=this.SortingbehaviorService.TrustMoneyFilterData$.pipe(map(result=>{
  if (result) {
    this.filterData = result;
    const localFilterData = JSON.parse(JSON.stringify(result));
    delete localFilterData.AdvancedFilters;
    localStorage.setItem('trustMoney_filter', JSON.stringify(localFilterData));
  }
 }))
 
public APICALL$=this.behaviorService.APIretryCall$.asObservable().pipe(map(result=>{
  if (localStorage.getItem('istrackid') == 'TrustMoneyComponent' && result['click'] && result['data']['trust-transaction']) {
        if (!localStorage.getItem('trustmoney_list_columns')) {
          this.getTableFilter();
          setTimeout(() => {
            this.getFilter();
          }, 1000);
    
        }
         this.addOptionalFilter(JSON.parse(localStorage.getItem('trustMoney_filter')));
      }
}))

public COLOR$=this.behaviorService.ColorSystemSetting$.asObservable().pipe(map(data=>this.COLOR=data));
public HyperlinkColor$=this.behaviorService.ColorSystemSettingHyperLink$.asObservable().pipe(map(data=>this.HyperlinkColor = data));
public PermissionChecked$=this.behaviorService.permissionCheckforHeight$.asObservable().pipe(map(data=> this.class=data))
  gloableAdvancedFilters: any;
  GloableadvanceFilterPayload: any;

  constructor(private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService, private TableColumnsService: TableColumnsService,
    public datepipe: DatePipe,
    private _formBuilder: FormBuilder,
    private behaviorService: BehaviorService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private SortingbehaviorService: SortingBehaviourService,
    private renderer: Renderer2,
    private _hyperlink:HyperlinkNavigationService,private router:Router) {

      this.Object = Object;

      this.currentUserData = JSON.parse(window.localStorage.getItem("currentUser"))
      this.addFilter = {
        OPERATION: '',
        VALUE: '',
        VALUE2: '',
        FILTERTYPE: ''
      };

    localStorage.setItem('istrackid', 'TrustMoneyComponent');   

    this.router.events.subscribe((res) => {
      this.router.url
  });


  this._hyperlink.setColorSetting();
  this._hyperlink.setColorSettingWithHyperlink();

  this.behaviorService.loaderDATA$.subscribe(data=>{
    this.isLoadingResults=data;
})

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    let windowHeight = $(window).height();
    let toolBaarMain = $('#tool_baar_main').height();
    let serchHeight = $('.sticky_search_div').height();
    this.detailHeight = windowHeight - (toolBaarMain + serchHeight + 52.5) + 'px';
    if (this.sortingDefaultState) {
      this.sortactive = this.sortingDefaultState.active;
      this.sortDirection = this.sortingDefaultState.direction;
    } else {
      this.sortactive = "DATEENTERED";
      this.sortDirection = "desc";
    }

    this.behaviorService.resizeTableForAllView();
    $(window).resize(() => {
      this.behaviorService.resizeTableForAllView();
    });

    $('.example-containerdata').css('height', ($(window).height() - ($('#filtermenu-wrap-form').height() + 295)) + 'px');
    $(window).resize(function () {
      $('.example-containerdata').css('height', ($(window).height() - ($('#filtermenu-wrap-form').height() + 295)) + 'px');
    });

    this.getTableFilter();
    this.getFilter()
    this.TrustMoneyForm = this._formBuilder.group({
      DateRangeSelect: [''],
      DateRange: [''],
      LASTRECONCILIATIONBALANCE: [''],
      LASTRECONCILIATIONDATE: [''],
      TRUSTBALANCE: [''],
      Search:[''],
      FILTERS: new FormArray([]),
      FILTERSLIST: new FormArray([]),
      MAINFILTER: ['']
    });
    let currentDate = new Date();
    let updatecurrentDate = new Date();
    // this.DateType = 'Incurred Date';
    this.forHideShowDateRangePicker = "hide";
    updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
    let end = this.datepipe.transform(currentDate, 'dd/MM/yyyy');
    let begin = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');

    this.filterData = {
      'TRANSACTIONSTARTDATE': begin, 'TRANSACTIONENDDATE': end, 'Search': ''
    }
    if (!localStorage.getItem("trustMoney_filter")) {
      localStorage.setItem('trustMoney_filter', JSON.stringify(this.filterData));
    } else {
      this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"))
    }
    if (this.filterData.TRANSACTIONSTARTDATE && this.filterData.TRANSACTIONENDDATE) {
      this.hasDateRange = true;
      let STARTDATE = this.filterData.TRANSACTIONSTARTDATE.split("/");
      let sendSTARTDATE = new Date(STARTDATE[1] + '/' + STARTDATE[0] + '/' + STARTDATE[2]);
      let ENDDATE = this.filterData.TRANSACTIONENDDATE.split("/");
      let SensENDDATE = new Date(ENDDATE[1] + '/' + ENDDATE[0] + '/' + ENDDATE[2]); 
      this.TrustMoneyForm.controls['DateRange'].setValue({ begin: sendSTARTDATE, end: SensENDDATE });
      const one = this.datepipe.transform(sendSTARTDATE, 'dd/MM/yyyy');
      const two = this.datepipe.transform(SensENDDATE, 'dd/MM/yyyy');
      this.activeFilters.daterange=one+'-'+two
      const date1 = sendSTARTDATE;
      const date2 = SensENDDATE;
      const date3 = new Date();
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const date4 = this.datepipe.transform(date2, 'dd/MM/yyyy');
      const date5 = this.datepipe.transform(date3, 'dd/MM/yyyy');
      if (diffDays == 0) {
        this.TrustMoneyForm.controls['DateRangeSelect'].setValue("Today");
      } else if (diffDays == 7) {
        this.TrustMoneyForm.controls['DateRangeSelect'].setValue("Last 7 days");
      } else if (diffDays == 30) {
        this.TrustMoneyForm.controls['DateRangeSelect'].setValue("Last 30 days");
      } else if (diffDays == 90) {
        this.TrustMoneyForm.controls['DateRangeSelect'].setValue("Last 90 days");
      }
      else {
        this.forHideShowDateRangePicker = "show";
        this.TrustMoneyForm.controls['DateRangeSelect'].setValue("Date Range");
      }
    }
    if (this.filterData.Search) {
      this.activeFilters.SearchString =this.filterData.Search
      this.TrustMoneyForm.controls['Search'].setValue(this.filterData.Search);
      this.hasSearchVal = true;
    }
  //  this.LoadData(this.filterData);
  this.addOptionalFilter(this.filterData)
    this.setDefaultAdvanceFilter();
  }

  /**
     * It runs only once after the component's view has been rendered.
     */
  ngAfterViewInit() {
    this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
    $('content').addClass('inner-scroll');
    $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 270)) + 'px');
    $(window).resize(function () {
      $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 270)) + 'px');
    });
  }

  /**
   * This Function is used to Download the data in CSV format.
   * @param data 
   */
  downloadFile(data?):void {
    const sortedData = this.TrustMoneyData.sortData(this.TrustMoneyData.filteredData,this.TrustMoneyData.sort);
    this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, sortedData, "Trust_money_data_", this.tempColobj);
    // this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, data, "Trust_money_data_", this.tempColobj);
  }
  /**
   * Used to change the pagination
   * @param event -event to get the updated page data.
   */
  onPaginateChange(event):void{
    this.setDefaultWidth(this.displayedColumns, 0);
    this.pageSize.trust_money = event.pageSize;
    localStorage.setItem('lastPageSize', JSON.stringify(this.pageSize));
  }

  /**
   * Used to select the day range 
   * @param val -get the day range
   */
  selectDayRange(val):void {
    this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"));
    let currentDate = new Date()
    let updatecurrentDate = new Date();
    let begin = this.datepipe.transform(currentDate, 'dd/MM/yyyy');
    if (val == "Last 7 days") {
      updatecurrentDate.setDate(updatecurrentDate.getDate() - 7);
      this.forHideShowDateRangePicker = "hide";
      this.TrustMoneyForm.controls['DateRange'].setValue({ begin: updatecurrentDate, end: currentDate });
    }
    else if (val == "Today") {
      // updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
      this.forHideShowDateRangePicker = "hide";
      this.TrustMoneyForm.controls['DateRange'].setValue({ begin: currentDate, end: currentDate });
    }
    else if (val == "Last 30 days") {
      updatecurrentDate.setDate(updatecurrentDate.getDate() - 30);
      this.forHideShowDateRangePicker = "hide";
      this.TrustMoneyForm.controls['DateRange'].setValue({ begin: updatecurrentDate, end: currentDate });
    }
    else if (val == "Last 90 days") {
      updatecurrentDate.setDate(updatecurrentDate.getDate() - 90);
      this.forHideShowDateRangePicker = "hide";
      this.TrustMoneyForm.controls['DateRange'].setValue({ begin: updatecurrentDate, end: currentDate });
    } else if (val == "Date Range") {
      this.forHideShowDateRangePicker = "show";
    }
    let end = this.datepipe.transform(updatecurrentDate, 'dd/MM/yyyy');
    this.CommonDatefun(begin, end);
    this.LoadData(this.filterData);
  }

  /**
   * Used to get the comman date value
   * @param begin -start date
   * @param end -end date
   */
  CommonDatefun(begin:string, end:string):void {
    this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"));

    this.filterData.TRANSACTIONSTARTDATE = end;
    this.filterData.TRANSACTIONENDDATE = begin;

    localStorage.setItem('trustMoney_filter', JSON.stringify(this.filterData));
  }

  /**
   * Used to get the date range one
   */
  DateRange1():void {
    try {
      if(this.daterangedata){
        let event=this.daterangedata
        let begin = this.datepipe.transform(event.value['begin'], 'dd/MM/yyyy');
        let end = this.datepipe.transform(event.value['end'], 'dd/MM/yyyy');
        this.CommonDatefun(end, begin);
      }else{
        this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"))
         this.CommonDatefun(this.filterData.TRANSACTIONSTARTDATE, this.filterData.TRANSACTIONENDDATE);
      } 
     
    } catch (error) {
      this.hasDateRange = false;
      this.CommonDatefun('', '');
      this.TrustMoneyForm.controls['DateRange'].setValue({ begin: '', end: '' });
    }
    this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"));
    // this.LoadData(this.filterData);
    this.addOptionalFilter(this.filterData);
  }

  /**
   * Used to get the search value
   * @param searchFilter  -search filter data.
   */
  onSearch(searchFilter: any):void {
    if (searchFilter['key'] === "Enter" || searchFilter == 'Enter' || searchFilter == 'removeSearchString') {
      //able to remove search filter
      if (searchFilter == 'removeSearchString') {
        this.TrustMoneyForm.controls['Search'].setValue('');
      }
      this.filterData.Search =this.f.Search.value
      if (!localStorage.getItem('trustMoney_filter')) {
        localStorage.setItem('trustMoney_filter', JSON.stringify(this.filterData));
      } else {
        this.filterData = JSON.parse(localStorage.getItem('trustMoney_filter'));
        this.filterData.Search = this.f.Search.value;
        localStorage.setItem('trustMoney_filter', JSON.stringify(this.filterData));
      }
      this.hasSearchVal = this.filterData.Search ? true : false;
     // this.LoadData(this.filterData);
     this.addOptionalFilter(this.filterData)
    }
  }

  /**
   * Used to sorting the date.
   */
  sortingDate():void {
    this.TrustMoneyData.sortingDataAccessor = (item, property) => {
      let FildeValue = this.dateColFilter;
      if (FildeValue.includes(property)) {
        if (item[property] && (property == 'DATEANDTIMEENTERED')) {
          let momentDate = moment(moment(item[property], 'DD/MM/YYYY hh:mm A')).format('YYYY-MM-DD HH:mm:ss');
          let timestamp = Date.parse(momentDate);
          return timestamp / 1000;
        } else if (item[property] && (property != 'DATEANDTIMEENTERED')) {
          let tempDate = item[property].split("/");
          let Sd = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
          let newDate = new Date(Sd);
          return newDate;
        }
        return item[property];
      } else {
        return item[property];
      }
    }
    // proper shorting for date 
    this.TrustMoneyData.sort = this.sort;
  }
  get f() {
    return this.TrustMoneyForm.controls;
  }

  /**
   * Used to get the table filter data.
   */
  getTableFilter():void {
    let trustmoneyListColumns: any = JSON.parse(localStorage.getItem('trustmoney_list_columns'));
    if (trustmoneyListColumns && trustmoneyListColumns != null) {
      let data = this.TableColumnsService.filtertableColum(trustmoneyListColumns.ColumnsObj);
      this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(trustmoneyListColumns.ColumnsObj);
      this.displayedColumns = trustmoneyListColumns.displayedColumns;
      this.ColumnsObj = trustmoneyListColumns.ColumnsObj;
      this.tempColobj = data.tempColobj;
      this.dateColFilter = data.dateCol;
      localStorage.setItem('trustmoney-justification',JSON.stringify(data?.colobj));
    } else {
      this.TableColumnsService.getTableFilter('trust money', '').subscribe(response => {
        if (response.CODE == 200 && response.STATUS == "success") {
          let data = this.TableColumnsService.filtertableColum(response.DATA.RECORDS);
          this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(response.DATA.RECORDS);

          this.displayedColumns = data.showcol;
          this.ColumnsObj = data.colobj;
          this.tempColobj = data.tempColobj;
          this.dateColFilter = data.dateCol;
          localStorage.setItem('trustmoney-justification',JSON.stringify(data?.colobj));
          localStorage.setItem('trustmoney_list_columns', JSON.stringify({ "ColumnsObj": data.colobj, 'displayedColumns': data.showcol }));
        }
      }, error => {
        this.toastr.error(error);
      });
    }
  }

  /**
   * Used to get the column resizing data.
   * @param event -get the resizing data.
   * @param columnName -column name which we need to resize.
   */
  onResizing(event: ResizeEvent, columnName): void {
    if (event.edges.right) {
      const cssValue = event.rectangle.width + 'px';
      const columnElts = document.getElementsByClassName('mat-column-' + columnName);
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      const indexData = this.ColumnsObj.findIndex(col => col.COLUMNID === columnName);
      this.ColumnsObj[indexData]['WIDTH'] = event.rectangle.width;
      localStorage.setItem('trustmoney_list_columns', JSON.stringify({ "ColumnsObj": this.ColumnsObj, 'displayedColumns': this.displayedColumns }));
    //   this.TableColumnsService.SaveWidthData(this.ColumnsObj, 'trust money', '');
    }
  }

  /**
   * Used to track the resizing process is end or not
   * @param event -get the resize data
   * @param columnName -columnname
   */
  onResizeEnd(event: ResizeEvent, columnName): void {
    this.TableColumnsService.SaveWidthData(this.ColumnsObj, 'trust money', '');
}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
     this.TableColumnsService.setTableResize(this.matTableRef.nativeElement.clientWidth);
  }

  /**
   * Used to set the default width to table column
   * @param displayedColumns -used to get the displayed column
   * @param timeout -time
   */
  setDefaultWidth(displayedColumns, timeout):void {
    setTimeout(() => {
      displayedColumns.forEach(element => {
        let temWidth = this.tempColobj[element]['WIDTH'];
        const cssValue = temWidth + 'px';
        const columnElts = document.getElementsByClassName('mat-column-' + element);
        for (let i = 0; i < columnElts.length; i++) {
          const currentEl = columnElts[i] as HTMLDivElement;
          currentEl.style.visibility = 'inherit';
          currentEl.style.width = cssValue;
        }
      });
    }, timeout);
  }

  /**
   * Used to open the mat Dialog
   */
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '100%';
    dialogConfig.disableClose = true;
    dialogConfig.data = { 'data': this.ColumnsObj, 'type': 'trust money', 'list': '' };
    //open pop-up
    const dialogRef = this.dialog.open(SortingDialogComponent, dialogConfig);
    //Save button click
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayedColumns = result.columObj;
        this.ColumnsObj = result.columnameObj;
        this.tempColobj = result.tempColobj;
        this.dateColFilter = result.dateCol;
        localStorage.setItem('trustmoney-justification',JSON.stringify(result?.columnameObj));
        localStorage.setItem('trustmoney_list_columns', JSON.stringify({ 'displayedColumns': result.columObj, "ColumnsObj": result.columnameObj }));
        if (!result.columObj) {
          this.TrustMoneyData = new MatTableDataSource([]);
          this.TrustMoneyData.paginator = this.paginator;
          this.TrustMoneyData.sort = this.sort;
          this.isDisplay = true;
        } else {
          this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"));
          this.LoadData(this.filterData);
        }
      }
    });
  }

  /**
   * Used to load the data.
   * @param data -data which we need to pass in api.
   */
  LoadData(data):void {
    this.isLoadingResults = true;
    const AdvancedFilters = data.AdvancedFilters ? data.AdvancedFilters : []; 
    this.gloableAdvancedFilters=data,
    this.GloableadvanceFilterPayload=AdvancedFilters
    let Payload : any= {
      "Action": "GetData",
       Filters: data,
       AdvancedFilters: AdvancedFilters
    };
    if(AdvancedFilters.length == 0){
      delete Payload.AdvancedFilters
    }
    delete Payload.Filters.AdvancedFilters;
    this._mainAPiServiceService.getSetData(Payload, 'trust-transaction').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.TrustMoneyForm.controls['LASTRECONCILIATIONBALANCE'].setValue(res.DATA.LASTRECONCILIATIONBALANCE);
        this.TrustMoneyForm.controls['LASTRECONCILIATIONDATE'].setValue(res.DATA.LASTRECONCILIATIONDATE);
        this.TrustMoneyForm.controls['TRUSTBALANCE'].setValue(res.DATA.TRUSTBALANCE);
        this.behaviorService.ChartAccountData({ ACCOUNTGUID: res.DATA.TRUSTACCOUNTGUID });
        this.UNBANKEDNOTICEData = res.DATA.UNBANKEDNOTICE;
        if (this.UNBANKEDNOTICEData) {
          setTimeout(() => {
            this.behaviorService.resizeTableForAllView();
          }, 250);
        }
        if (res.DATA.RECORDS[0]) {
          this.isDisplay = false;
          this.RowClick(res.DATA.RECORDS[0]);
          this.highlightedRows = res.DATA.RECORDS[0].TRUSTTRANSACTIONGUID;
          // this.behaviorService.TaskData(res.DATA.TASKS[0]);
        } else {
          this.isDisplay = true;
        }
        this.TrustMoneyData = new MatTableDataSource(res.DATA.RECORDS);
        this.TrustMoneyData.sort = this.sort;
        this.TrustMoneyData.paginator = this.paginator;
        this.isLoadingResults = false;
        this.sortingDate();
        if (res.DATA.TRUSTDETAILSINCOMPLETE == 1) {
          this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: { trstData: true } });
          this.confirmDialogRef.componentInstance.confirmMessage = 'The bank Details must be completed for the trust account before you can process any transactions';
          this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              let isTrust = true;
              let ChartHandlingData = { ClickType: 'WithTrust', UseTrust: 'Yes', PopUp: '', Lable: "TRUST CHART OF ACCOUNTS", ACCOUNTTYPENAME: "Trust Account" };
              this.behaviorService.TrustDuplicateModuleHandling(ChartHandlingData);
              const dialogRef = this.dialog.open(ChartAcDailogComponent, { disableClose: true, panelClass: 'ChartAc-dialog', data: { action: 'edit', UseTrust: 'Yes', isDefultAccount: '', matterGuid: '' } });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
            this.confirmDialogRef = null;
          });
        }
        this.setDefaultWidth(this.displayedColumns, 500);
        if(this.isCallDownloadFunction==true){
          setTimeout(() => {
            this.downloadFile()
            this.isCallDownloadFunction=false
          }, 1000);
         
        }
      } else if (res.CODE == 406 && res.MESSAGE == "Permission denied") {
        this.TrustMoneyData = new MatTableDataSource([]);
        this.TrustMoneyData.paginator = this.paginator;
        this.TrustMoneyData.sort = this.sort;
        this.isLoadingResults = false;
        this.sortingDate();
        this.isDisplay = true;
        this.setDefaultWidth(this.displayedColumns, 500);
      } else if (res.CODE == 417 && res.MESSAGE == "No filter was supplied") {
        this.TrustMoneyData = new MatTableDataSource(res.DATA.RECORDS);
        this.TrustMoneyData.paginator = this.paginator;
        this.TrustMoneyData.sort = this.sort;
        this.isLoadingResults = false;
        this.sortingDate();
        this.setDefaultWidth(this.displayedColumns, 500);
        this.isDisplay = true;
      }
      this.isLoadingResults = false;
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
    this.pageSize = JSON.parse(localStorage.getItem('lastPageSize'));
    delete data.AdvancedFilters;
    this.SortingbehaviorService.SetTrustMoneyFilterData(data);

  }

  /**
   * Used to sort the table data.
   * @param val -sorting value
   */
  sortData(val) {
    this.SortingbehaviorService.TrustMoneyScreenSorting(val);
    this.setDefaultWidth(this.displayedColumns, 0);
  }

  /**
   * used to click on row
   * @param row -row click data
   */
  RowClick(row):void {
    if (row.MULTIITEM) {
      let windowHeight = $(window).height();
      let toolBaarMain = $('#tool_baar_main').height();
      let serchHeight = $('.sticky_search_div').height();
      this.detailHeight = windowHeight - (toolBaarMain + serchHeight + 52.5) + 'px';
    }
    this.behaviorService.setTrustMoneyList(row);
  }

  /**
   * Used to refresh the trust money data.
   */
  trustMoneyRefersh():void {
    this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"))
    let appyFilterPayload = JSON.parse(JSON.stringify(this.filterData));
    let daynamicFilters = [];
    this.optionalDateFilterPayload.forEach((e) => {
      daynamicFilters.push(e.value);
    });

    if (daynamicFilters.length !== 0) {
      appyFilterPayload.AdvancedFilters = daynamicFilters;
    }
   // this.LoadData(this.filterData);
   this.LoadData(appyFilterPayload);

  }

  /**
   * Used to get the tooltip data.
   * @param row -get the row data
   * @returns -return the tooltip data.
   */
  getToolTipData(row: any):string {
    if (row.ROWCOLOUR && row.ROWCOLOUR == 'ff8000') {
      return 'This item is a reversal';
    } else if (row.ROWCOLOUR == 'ff00ff') {
      return 'This item has been reversed!';
    } else if (row.ROWCOLOUR == "ff0000") {
      return 'This item has not been printed';
    } else {
      return '';
    }
  }

  /**
   * used to convert the All table data into the CSV.
   */
  DownloadAllData():void{
      const requestdata={
        Search: "",
        TRANSACTIONENDDATE: "23/08/2022",
        TRANSACTIONSTARTDATE: "02/08/2022",
    }

    this.isGetallData=true
    this.isCallDownloadFunction=true
     this.LoadData(requestdata);
  }


  dateFilter =FilterSearchOptions.dateFilter
  currencyFilter = FilterSearchOptions.currencyFilter;
  manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;

/**
 * Used to go to previous filter
 * @param id -current filter id
 */
  backToFilter(id) {
    $('#' + id).removeClass('active');
    setTimeout(() => {
      $('.mat-menu-content').removeClass('active');
      $('.mat-menu-content').removeClass('mat-menu-content_height');
    }, 50);

    if (this.FILTERS) {
        this.FILTERSLIST.reset();
      
    }
  }

  /**
   * Used to set the prevent default value.
   * @param event -get the current data.
   */
  preventDefault(event):void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Used to get the filter data.
   */
  getFilter():void {
    let opFilterList = JSON.parse(localStorage.getItem('trustmoney_list_columns'));
    const filterData = this.TableColumnsService.getDynamicFilter(opFilterList , this.optionalFilterList,'trustmoney_list_columns');
   
    this.defaultSetFilter()
    this.refreshFilterList()
  };

  /**
   * Used to refresh the filter data.
   */
 refreshFilterList():void{
  this.totalApplicableFilterCount = 0;
    const advDynamicFilter = JSON.parse(localStorage.getItem('TrustMoney_Advance_Filter'));
    this.optionalFilterList.map((x)=>{
      if (x.FILTERTYPE != "HIDDEN") {
        this.totalApplicableFilterCount += 1;
    }
      if(advDynamicFilter && advDynamicFilter.findIndex((e)=> e.COLUMNID == x.COLUMNID) !== -1){
        x.FILTERAPPLY = true;
      };
    });
  }

  /**
   * Used to declared the default filter.
   */
  defaultSetFilter():void {
      this.activeFilters = {};
  }

  /**
   * Used to add the dynamic filter
   * @param event -get the updated filter data
   * @param type -get the filter type.
   */
  addDynamicFilter(event,type?):void {
    if (event.FILTERTYPE == 'DATE') {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [{
        id: 1,
        value: 'days'
      }, {
        id: 2,
        value: 'months'
      }]
    };

    // if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
    //   event.DATEFILTERLIST = this.currencyFilter
    // }

    if (event.FILTERTYPE !== 'DATE' && event.FILTERTYPE !== 'CURRENCY' && event.FILTERTYPE !== 'NUMBER') {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
    }
    if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(event.FILTERTYPE)
    }

    event.FILTERAPPLY = true;
    if (!type) {
      this.optionalFilters.push(event);
    }

   
    this.FILTERS = this.TrustMoneyForm.get('FILTERS') as FormArray;
    this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));

  }; 

  /**
   * Used to make the optional filter
   * @param type -get the filter type
   * @returns - its return the whole form group.
   */
  createOPFilter(type): FormGroup {
    return this._formBuilder.group({
      OPERATION: (type == "DATE") ? 'is in the last' : (type == 'CURRENCY' || type == 'NUMBER') ? 'is equal to' : 'contains',
      VALUE: '',
      VALUE2: '',
      FILTERTYPE: type
    });
  }

  /**
   * Used to get the Active filter data.
   * @param id -Active current filter id
   * @param data -current filter data
   */
  activeCurrentFilter(id, data?: any) {
    $('#' + id).addClass('active');
    setTimeout(() => {
      $('.mat-menu-content').addClass('active');
      $('.mat-menu-content').addClass('mat-menu-content_height');
    }, 200);

  //  this.TrustMoneyForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
  this.selectedFilterIs = '';
  this.TrustMoneyForm.controls['MAINFILTER'].reset();
    this.activeOptionalFilter = data;
  }

  /**
   * Used to set the filter confrigation 
   * @param activefilter 
   * @param filterBy 
   */
  Filterconfig(activefilter, filterBy) {
    if (filterBy == 'DATERANGE') {
      this.daterangedata=activefilter
      let begin = this.datepipe.transform(activefilter.value['begin'], 'dd/MM/yyyy');
      let end = this.datepipe.transform(activefilter.value['end'], 'dd/MM/yyyy');
      this.activeFilters.daterange = begin +'-'+end;
      this.activeFilters.inputdaterange = begin +'-'+end;
    }
    if (filterBy == 'TRUSTBALANCE') { 
    }
    if (filterBy == 'SearchString') {
     // this.activeFilters={}
      this.activeFilters.SearchString = this.f.Search.value;
    }
    localStorage.setItem('TrustMony_config', JSON.stringify(this.activeFilters));
  }

  /**
   * Used to clear the filter data.
   * @param name -filter name
   * @param filterBydata -Filter data
   */
  clearFilter(name, filterBydata):void {
    let filterBy= trim(filterBydata)
     delete this.activeFilters[name];
     let resetFilter = JSON.parse(localStorage.getItem('trustMoney_filter'));
      this.clearFilters = true; 
     if (filterBy == 'Date Range') {
       resetFilter.TRANSACTIONSTARTDATE = ''
       resetFilter.TRANSACTIONENDDATE = ''  
       this.TrustMoneyForm.controls['DateRange'].setValue('');
     }
     if (filterBy == 'Trust Balance') {
      
    }

    if (filterBy == 'SearchString') {
      resetFilter.Search = ''
      this.TrustMoneyForm.controls['Search'].setValue('');
    }

 
   
     
     this.addOptionalFilter(resetFilter);
  
   localStorage.setItem('trustMoney_filter', JSON.stringify(resetFilter));
     localStorage.setItem('TrustMony_config', JSON.stringify(this.activeFilters));
   }

   /**
    * Used to Add optional filter
    * @param prevFilter -value of the prefilter
    */
   addOptionalFilter(prevFilter?: any):void{
    //Delete Filters
    const Filter = (prevFilter)?JSON.parse(JSON.stringify(prevFilter)):JSON.parse(localStorage.getItem('trustMoney_filter'));

    const ArrayResetOptional = JSON.parse(JSON.stringify(this.TrustMoneyForm.value.FILTERS));

    const ArrayResetOptionalNew = ArrayResetOptional.map((data,index)=>{
      if(this.optionalFilters[index]) {
        data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
      }
      return data;
    })

    const finalPayload = ArrayResetOptionalNew.filter((d) => d['VALUE'] !== null && d['VALUE'] !== '').map((e) => {
      if (e['VALUE'] && e['VALUE'] !== '') {
        delete e.COLUMNID;
        return e;
      }
    });

    const advanceFilterPayload = this.TableColumnsService.loadAdvanceFilterPayload('TrustMoney');
    Filter.AdvancedFilters = (finalPayload.length !== 0) ? finalPayload : (advanceFilterPayload) ? advanceFilterPayload : [];

    if (Filter.AdvancedFilters.length == 0) {
      delete Filter.AdvancedFilters;
      localStorage.removeItem('TrustMoney_Advance_Filter');
    };

    // ends here ~ for optional filter
    const finalBasicPayload = JSON.parse(JSON.stringify(Filter));

    delete finalBasicPayload.DateRange;
    delete finalBasicPayload.dayRange;

    // Filter.FILTERS = finalPayload;
    // Filter.AdvancedFilters = finalPayload;
    // if ((this.FILTERS && this.FILTERS.controls.length == 0 )|| finalPayload.length == 0) {
    //   // delete Filter.FILTERS;
    //   delete Filter.AdvancedFilters;
    // };

    // this.reload(Filter);
    this.reload(finalBasicPayload);

  };

  /**
   * Used to reload the data. 
   * @param filterVal -filter data value
   */
  reload(filterVal) {
   // this.behaviorService.MatterData(null);
   this.LoadData(filterVal)
  }

/**
 * Used to reset the filter data
 */
  resetFilter():void {
    this.optionalFilterList.forEach((filter, index)=>{
      filter.FILTERAPPLY = false;
    })
    let resetFilter = JSON.parse(localStorage.getItem('trustMoney_filter'));
    localStorage.removeItem('TrustMoney_Advance_Filter');
    this.saveAdvanceFilter=[];
    this.TrustMoneyForm.controls['Search'].setValue('');
   
    this.optionalFilters = [];
    this.optionalDateFilterPayload = [];
    if (this.FILTERS) {
      this.FILTERS.reset();
   //   this.FILTERSLIST.reset();
    }

    setTimeout(() => {
      if (JSON.parse(localStorage.getItem('TrustMony_config'))) {
        this.activeFilters = JSON.parse(localStorage.getItem('TrustMony_config'));
        this.activeFilters.SearchString = ''
        resetFilter.Search= '',
        // delete resetFilter.FILTERS;
        delete resetFilter.AdvancedFilters
        localStorage.setItem('trustMoney_filter', JSON.stringify(resetFilter));
      };
      
    this.LoadData(resetFilter)
    }, 500);

  }

/**
 * Used to set the default filter configration data.
 * @param event -config data.
 */
  dateFilterconfig(event):void {
    this.optionalFilterAppply = false;
  }
  // applayOptionalfilter(addData, isIndex) {
  //   const applyFilterData = this.TableColumnsService.Optionalfilter(addData, isIndex , this.optionalDateFilterPayload , this.TrustMoneyForm);
  //   this.optionalDateFilterPayload = applyFilterData;
  //   this.doFilter(this.optionalDateFilterPayload);
  // }

  /**
   * Used to apply the optional filter in dynamic format.
   * @param FilterData -filter data
   * @param isIndex -current index 
   * @param OpretionIs- which operation we need to perform.
   * @param evt -evt data
   * @returns -its return the boolean value
   */
  applayOptionalfilter(FilterData, isIndex , OpretionIs?:any, evt?:any) {
    let saveAdvancefilterData = JSON.parse(localStorage.getItem("TrustMoney_Advance_Filter"));
    const field1Val = this.TrustMoneyForm.controls.FILTERS["controls"][isIndex].controls["VALUE"].value
    const field2Val = this.TrustMoneyForm.controls.FILTERS["controls"][isIndex].controls["VALUE2"].value
    const operationVal = this.TrustMoneyForm.controls.FILTERS["controls"][isIndex].controls["OPERATION"].value
    const filterTypeVal = this.TrustMoneyForm.controls.FILTERS["controls"][isIndex].controls["FILTERTYPE"].value

    if(!this.TableColumnsService.checkValidation(field1Val, field2Val, operationVal,filterTypeVal)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    } 

    // ends here ~ new code for validation

    //addData.selectedFilter = OpretionIs.triggerValue;
    let addData = JSON.parse(JSON.stringify(FilterData));
    addData.selectedFilter =  OpretionIs.triggerValue

    const applyFilterData = this.TableColumnsService.Optionalfilter(addData, isIndex , this.optionalDateFilterPayload , this.TrustMoneyForm);
    this.optionalDateFilterPayload = applyFilterData;
    this.TableColumnsService.setAdvanceFilter(saveAdvancefilterData,this.saveAdvanceFilter ,isIndex , FilterData ,field1Val , field2Val , operationVal , filterTypeVal , applyFilterData , 'TrustMoney');
    this.doFilter(this.optionalDateFilterPayload); 
  }
  
  /**
   * Used to pass the filter data to the main api. 
   * @param mainFilter -data of main filter
   */
  doFilter(mainFilter: any) {
    const payloadData = JSON.parse(JSON.stringify(mainFilter));
    const finalPayload = payloadData.filter((d) => d['value'] !== null).map((e) => {
      if (e['value'] && e['value'] !== null) {
        delete e['value'].COLUMNID;
        return e['value'];
      }
    });

    const doFilter = JSON.parse(JSON.stringify(this.filterData));
    // doFilter.FILTERS = finalPayload;
    doFilter.AdvancedFilters = finalPayload 

    delete doFilter.activeDesc;
    delete doFilter.unbilledWorkDesc;
    delete doFilter.FILTERINDEX;
    delete doFilter.FILTERAPPLAY;

    this.reload(doFilter);
  }

  /**
   * Used to ge the optional filter data.
   * @param event -updated evt
   * @param filterIs -olean data
   * @param val -updated data
   * @param index -filter index
   */
  optionalDateFilter(event, filterIs , val , index) {
    if(val && val == 'active'){
      this.selectedFilterIs = event.value;
    }else{
      filterIs.selectedFilter = event.value;
      this.selectedFilterIs = event.value;
    };
    if(index != -1) {
      this.TrustMoneyForm.controls.FILTERS["controls"][index].controls["VALUE"].reset();
      this.TrustMoneyForm.controls.FILTERS["controls"][index].controls["VALUE2"].reset();
    }
      
      this.addFilter["VALUE"] = "";
      this.addFilter["VALUE2"] = "";
  }

  /**
   * Used to reste the optional filter 
   * @param event -optional filter data
   * @param type -type of the filter
   * @param isindex -index of the current filter
   */
 async resetOptionalFilter(event, type, isindex) {
    let index = this.optionalFilters.findIndex((e) => e.COLUMNID == event.COLUMNID);

    event.FILTERAPPLY = false; 
    
    setTimeout(() => {
      this.optionalFilterList.map((x)=>{
        if(this.optionalFilters.findIndex((e)=> x.COLUMNID == event.COLUMNID) !== -1){
          x.FILTERAPPLY = false;
        };
      });
    }, 100)
    //for filter hide/show from optional filter list.
  // await  this.optionalFilterList.map((x)=>{
  //     if(this.optionalFilters.findIndex((e)=> e.COLUMNID == x.COLUMNID) !== -1){
  //       x.FILTERAPPLY = false;
  //     };
  //   });
    // this.optionalFilterList.forEach(element => {
    //   this.optionalFilters.forEach(element2 => {
    //     if(element.COLUMNID == element2.COLUMNID){
    //       element.FILTERAPPLY = false;
    //     }else{
    //     //  element.FILTERAPPLY = false;
    //     }
        
    //   });
      
    // });
    this.saveAdvanceFilter.splice(index, 1);
    this.optionalFilters.splice(index, 1);
    this.optionalDateFilterPayload.splice(isindex, 1);
    this.FILTERS.controls.splice(isindex, 1);
    this.TrustMoneyForm.value.FILTERS.splice(isindex, 1);

    //Delete Filters
    const resetFilter = JSON.parse(localStorage.getItem('trustMoney_filter'));
    // delete resetFilter.activeDesc;
    // delete resetFilter.unbilledWorkDesc;
    // delete resetFilter.FILTERINDEX;
    // delete resetFilter.FILTERAPPLAY;

    const ArrayResetOptional = JSON.parse(JSON.stringify(this.TrustMoneyForm.value.FILTERS));
    this.TableColumnsService.refrshAdvanceFilter(event , 'TrustMoney');

    const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {

      if (this.optionalFilters[index]) {
        data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
      }
      return data;
    })
    const finalPayload = ArrayResetOptionalNew.filter((d) => d['VALUE'] !== null && d['VALUE'] !== '').map((e) => {
      if (e['VALUE'] && e['VALUE'] !== '') {
        delete e.COLUMNID;
       // e.COLUMNNAME = event.COLUMNNAME;
        return e;
      }
    });

    // resetFilter.FILTERS = finalPayload;
    resetFilter.AdvancedFilters = finalPayload;

    if (this.FILTERS.controls.length == 0 || finalPayload.length == 0) {
      // delete resetFilter.FILTERS;
      delete resetFilter.AdvancedFilters;
    };

    this.reload(resetFilter);
  }

  @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;
  addNewFilter(event) {

    setTimeout(()=>{
      this.operationFilter.options.first.select();
    },200)

    this.addFilter.VALUE = "";
    this.addFilter.VALUE2 = "";
  
    if (this.FILTERSLIST) {
      this.FILTERSLIST.controls.splice(0, 1);
      //this.FILTERSLIST.reset();
    };

    if (event.FILTERTYPE == 'DATE') {
      event.DATEFILTERLIST = this.dateFilter;
      event.DATEINNETFILTER = [{
        id: 1,
        value: 'days'
      }, {
        id: 2,
        value: 'months'
      }]
    };

    // if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
    //   event.DATEFILTERLIST = this.currencyFilter
    // }

    if (event.FILTERTYPE !== 'DATE' && event.FILTERTYPE !== 'CURRENCY' && event.FILTERTYPE !== 'NUMBER') {
      event.DATEFILTERLIST = this.manullyOptionalFilter;
    }
    if (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') {
      event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(event.FILTERTYPE)
    }
    this.addFilter.FILTERTYPE = event.FILTERTYPE;

    // OPTIONAL FILTER
    this.FILTERSLIST = this.TrustMoneyForm.get('FILTERSLIST') as FormArray;
    this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));

    this.addFilter.OPERATION = (event.FILTERTYPE == "DATE") ? 'is in the last' : (event.FILTERTYPE == 'CURRENCY' || event.FILTERTYPE == 'NUMBER') ? 'is equal to' : 'contains'
    this._filterList = [event];
    this.cd.detectChanges()
  
  };

  /**
   * Used to add the filter dynamicly
   * @param IsfilterData -get the updated filter data.
   * @param oprationalFilter -the filter which we want to apply
   * @param evt -get the evt data.
   * @returns 
   */
  async addfilter(IsfilterData, oprationalFilter, evt):Promise<boolean> {
    // alert(" >> value changed >> ")
    this.cd.detectChanges();
    if (!this.TableColumnsService.checkValidation(this.addFilter.VALUE, this.addFilter.VALUE2, this.addFilter.OPERATION, IsfilterData.FILTERTYPE)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }
    this.cd.detectChanges();
    await this.addDynamicFilter(IsfilterData);

    let sIndex = this.optionalFilters.length - 1;
    this.cd.detectChanges();
    setTimeout(() => {
      this.TrustMoneyForm.controls.FILTERS["controls"][sIndex].controls["OPERATION"].setValue(this.addFilter.OPERATION);
    this.TrustMoneyForm.controls.FILTERS["controls"][sIndex].controls["VALUE"].setValue(this.addFilter.VALUE);
    this.TrustMoneyForm.controls.FILTERS["controls"][sIndex].controls["VALUE2"].setValue(this.addFilter.VALUE2);
    this.TrustMoneyForm.controls.FILTERS["controls"][sIndex].controls["FILTERTYPE"].setValue(IsfilterData.FILTERTYPE);
    }, 500);
   

  this.TrustMoneyForm.value.FILTERS[sIndex].VALUE = this.addFilter.VALUE;
  this.TrustMoneyForm.value.FILTERS[sIndex].VALUE2 = this.addFilter.VALUE2;
  this.TrustMoneyForm.value.FILTERS[sIndex].OPERATION = this.addFilter.OPERATION;
  this.TrustMoneyForm.value.FILTERS[sIndex].FILTERTYPE = IsfilterData.FILTERTYPE;


    // Advance Filter.
    IsfilterData.AdvancedFilter = this.TrustMoneyForm.value.FILTERS[sIndex];
    let advFilters = JSON.parse(localStorage.getItem('TrustMoney_Advance_Filter'));
    if (advFilters) {
      this.saveAdvanceFilter = advFilters;
    };

    this.saveAdvanceFilter.push(IsfilterData);
    localStorage.setItem("TrustMoney_Advance_Filter", JSON.stringify(this.saveAdvanceFilter));

    if (this.FILTERS.length == 1) {
      this.TrustMoneyForm.patchValue({
        FILTERS: [{
          OPERATION: this.addFilter.OPERATION,
          VALUE: this.addFilter.VALUE,
          VALUE2: this.addFilter.VALUE2,
          FILTERTYPE: IsfilterData.FILTERTYPE
        }]
      });
    } else {
      this.FILTERS.value.forEach((e, index) => {
        if (sIndex == index) {
          e.OPERATION = this.addFilter.OPERATION;
          e.VALUE = this.addFilter.VALUE;
          e.VALUE2 = this.addFilter.VALUE2;
          e.FILTERTYPE = IsfilterData.FILTERTYPE;
        }
      });

      this.TrustMoneyForm.patchValue({
        FILTERS: this.FILTERS.value
      });
    }

    //After getting data appy filter.
    this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

    $('.example-containerdata').css('height', ($(window).height() - ($('#filter-form-menu-wrap').height() + 260)) + 'px');
  }

  /**
   * Set the dynamic filter UI
   * @param filter -FILTER DATA.
   * @param index -UPDATED INDEX
   */
  setFilterUi(filter,index):void {
    this.selectedFilterIs = this.optionalDateFilterPayload[index]["value"]["OPERATION"];
  }

/**
 * Used to apply the default Advanced filter.
 */
  async setDefaultAdvanceFilter():Promise<void> {
    let advanceFiltes = JSON.parse(localStorage.getItem('TrustMoney_Advance_Filter'));
    if (advanceFiltes) {
      this.optionalDateFilterPayload = [];
      this.optionalFilters = advanceFiltes;
      await advanceFiltes.forEach(async (filterData, index) => {
        await this.addDynamicFilter(filterData.AdvancedFilter, 'default');

        this.FILTERS.value[index].OPERATION = await filterData.AdvancedFilter.OPERATION;
        this.FILTERS.value[index].FILTERTYPE = await filterData.AdvancedFilter.FILTERTYPE;
        this.FILTERS.value[index].VALUE = await filterData.AdvancedFilter.VALUE;
        this.FILTERS.value[index].VALUE2 = await filterData.AdvancedFilter.VALUE2;
        await this.optionalDateFilterPayload.push(filterData.FilterHeadings);

        await this.TrustMoneyForm.patchValue({
          FILTERS: this.FILTERS.value
        });
      });
    };
  }

  /**
   * get the selected hyperlink data.
   * @param row -row data.
   * @param item -selected item data.
   * @param hyperLinkInfo -hyperlink info
   */
  selectHyperLink(row,item,hyperLinkInfo:any) {
    this._hyperlink.selectHyperLink(row,item,hyperLinkInfo)
  }

     /**
 * This function is used to getNewExportData;
 */
     getNewExportData(){
      let active;
      let direction
      let contactjustification=JSON.parse(localStorage.getItem('trustmoney-justification'));
      if(this.sortingDefaultState == null){
        active=this.sortactive;
        direction=this.sortDirection
      }else{
        active=this.sortingDefaultState?.active;
        direction=this.sortingDefaultState?.direction
      }
      let columnname=[];
      if(active !== undefined && direction !== undefined && active !=='' &&  direction !== ''){
        columnname=contactjustification.filter(data=>data.COLUMNID == active);
      }else{
        columnname.push({COLUMNNAME:''})
        direction = ''
      }
      this.behaviorService.loaderDATA$.next(true);
      this.TableColumnsService.getNewExportData("trust money","","trust-transaction",this.gloableAdvancedFilters,this.GloableadvanceFilterPayload,columnname[0].COLUMNNAME,direction) 
      }

}

/**
 * MULTI AMOUNT PIPE
 */
import { Pipe, PipeTransform } from '@angular/core';
import { trim } from 'lodash';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';

@Pipe({ name: 'multiAmount' })
export class MultiAmount implements PipeTransform {

     /**
     * This function is used to transform the data.
     */
  transform(value: any, args: any = false, multiItem:number): any {
    let tempVal = value;
    
    if ((typeof value == "string" || typeof value == "undefined") && !value) {
      tempVal = 0;
    }
    if (args) {
      if (args === 'Amount' && multiItem === 1) {
        return 'Multi'
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  

}