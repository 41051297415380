import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { MainAPiServiceService } from '@_services/main-api-service.service';


@Component({
  selector: 'app-globally-safecustody',
  templateUrl: './globally-safecustody.component.html',
  styleUrls: ['./globally-safecustody.component.scss'],
  animations: fuseAnimations
})
export class GloballySafeCustodyComponent implements OnInit {

  constructor(private _mainAPiServiceService: MainAPiServiceService) { }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    // this._mainAPiServiceService.getSetData({}, 'GetSystem').subscribe(response=>{
    //   this.addData=response.DATA.SYSTEM.ADDRESSGROUP.POSTALADDRESSGROUP
    // })

  }



}