import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { BankingDialogComponent } from '../../banking/banking-dialog.component';
import { DatePipe } from '@angular/common';
import { fuseAnimations } from 'src/@fuse/animations';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-general-dailog',
  templateUrl: './general-dailog.component.html',
  styleUrls: ['./general-dailog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GeneralDailogComponent implements OnInit, OnDestroy {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isLoadingResults: boolean = false;
  action: string;
  dialogTitle: string;
  isspiner: boolean = false;
  highlightedRows: any;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  Number = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  displayedColumns: string[] = ['Number', 'Account', 'Debit', 'Credit'];
  disabledField: any;
  btnhide: any;
  craditDEbitData = { DRCR: 'DR', AMOUNT: '0.00', ACCOUNTGUID: '' };
  isDisable: boolean = true;
  GeneralForm: FormGroup;
  errorWarningData: any = {};
  ButtonText = 'Save';

  //buttonDisabled: boolean = true;
  successMsg: any;
  Slice: any;
  Slice2: any;
  JournalData: any = [];
  FormAction: string;
  DEBITSTOTAL: any = 0;
  CREDITSTOTAL: any = 0;
  CREDITDEBITDATA: any = [];
  generalTempData: any = [];
  CREDITDATAARRY: any = [];
  DEBITDATAARRAY: any = [];

  Accountguid: any;
  Accountname: any;
  FinalDebitTotal: any;
  FinalCreditTotal: any;
  CREDITTOTALFINAL: any = [];
  DEBITSTOTALFINAL: any = [];
  accountTypeData: any;
  ChartHandlingData: { ClickType: string; UseTrust: string; PopUp: string; Lable: string; };
  itemRowIndex: any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  sub: Subscription;
  constructor(
    public MatDialog: MatDialog,
    public dialogRef: MatDialogRef<GeneralDailogComponent>,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private behaviorService: BehaviorService,
    public _mainAPiServiceService: MainAPiServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    localStorage.setItem('istrackid', 'GeneralJournalDialogComponent');
    this.behaviorService.GeneralData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.JournalData = result;
      }
    });
    this.sub = this.behaviorService.dialogClose$.subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.TrustDuplicateModuleHandling$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result != null) {
        this.accountTypeData = result;
      }
    });
    this.action = data.action;
    // if (this.action === 'new') {
    //   this.dialogTitle = 'New General Journal Entry';
    // } else if (this.action === 'edit') {
    //   this.ButtonText = 'Update';
    //   this.dialogTitle = 'Update General Journal Entry';
    // } else {
    //   this.ButtonText = 'Duplicate';
    //   this.dialogTitle = 'Duplicate General Journal Entry';
    // }
    if (this.accountTypeData.ClickType == 'WithoutTrust') {
      if (this.action === 'new') {
        this.dialogTitle = 'New General Journal Entry';
      } else if (this.action === 'edit') {
        this.ButtonText = 'Update';
        this.dialogTitle = 'Update General Journal Entry';
      } else {
        this.ButtonText = 'Duplicate';
        this.dialogTitle = 'Duplicate General Journal Entry';
      }
    } else if (this.accountTypeData.ClickType == 'WithTrust') {
      if (this.action === 'new') {
        this.dialogTitle = 'Trust New General Journal Entry';
      } else if (this.action === 'edit') {
        this.ButtonText = 'Update';
        this.dialogTitle = 'Trust Update General Journal Entry';
      } else {
        this.ButtonText = 'Duplicate';
        this.dialogTitle = 'Trust Duplicate General Journal Entry';
      }
    }

    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'GeneralJournalDialogComponent' && result['click'] && result['data']['GetJournal']) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'GeneralJournalDialogComponent' && result['click'] && result['data']['SetJournal']) {
        this.SaveGeneral();
      }
    });

  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.btnhide = 'NewDelete';
    this.GeneralForm = this._formBuilder.group({
      DATEGENERAL: [new Date()],
      DESCRIPTION: [''],
      TOTALDEBIT: [''],
      TOTALCREDITS: [''],
      GAMOUNT: ['', { updateOn: 'blur' }],
      ACCOUNT: [''],
      AccountGuid: [''],
      CRDR: [''],
      NEWDATE: [this.datepipe.transform(new Date(), 'dd/MM/yyyy')],
    });
    if (this.action == 'edit' || this.action == 'duplicate') {
      this.EditGeneral();
    }
  }

  ngOnDestroy(): void {
      this._unsubscribeAll$.next();
      this._unsubscribeAll$.complete();
      this.sub?.unsubscribe();
  }

  get f() {
    return this.GeneralForm.controls;
  }

  /**
   * This function is used to choose the date
   */
  choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.GeneralForm.controls['NEWDATE'].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }
  
  /**
   * This function is used to select the account 
   */
  SelecteAccount() {
    // for check is trust account or not
    if (this.accountTypeData.ClickType == 'WithoutTrust') {
      this.ChartHandlingData = { ClickType: 'WithoutTrust', UseTrust: 'No', PopUp: '', Lable: "CHART OF ACCOUNTS", }
      localStorage.setItem('ChartURL', JSON.stringify(this.ChartHandlingData));
    } else {
      this.ChartHandlingData = { ClickType: 'WithTrust', UseTrust: 'Yes', PopUp: '', Lable: "TRUST CHART OF ACCOUNTS", }
      localStorage.setItem('ChartURL', JSON.stringify(this.ChartHandlingData));
    }
    this.behaviorService.TrustDuplicateModuleHandling(this.ChartHandlingData);

    const dialogRef = this.MatDialog.open(BankingDialogComponent, {
      disableClose: true, width: '100%', data: { AccountType: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem('istrackid', 'GeneralJournalScreenComponent');
      if (result) {
        result.name = result.ACCOUNTCLASS + ' - ' + result.ACCOUNTNUMBER + '        ' + result.ACCOUNTNAME
        let AccountName = result.name;
        this.Slice = AccountName.slice(0, 8);
        this.Slice2 = AccountName.slice(9);
        this.GeneralForm.controls['ACCOUNT'].setValue(result.name);
        this.Accountguid = result.ACCOUNTGUID;
      }
    });
  }

/**
 * This function is used to get the New Item
 */
  NewItem() {
    this.btnhide = 'AddCancel';
    this.isDisable = false;
    this.craditDEbitData.DRCR = "CR";
  }

  /**
   * This function is used to Delete the Item
   */
  DeleteItem() {
    this.CREDITDEBITDATA.splice(this.itemRowIndex, 1);
    this.generalTempData = new MatTableDataSource(this.CREDITDEBITDATA);
    this.DEBITSTOTAL = 0;
    this.CREDITSTOTAL = 0;
    this.CREDITDATAARRY = [];
    this.DEBITDATAARRAY = [];
    this.CREDITDEBITDATA.forEach(item => {

      if (item.DEBITCREDIT === 'CR') {
         this.CREDITSTOTAL += item.CREDITAMOUNT;
       // this.CREDITSTOTAL = item.AMOUNT
        this.CREDITDATAARRY.push({
          ACCOUNTGUID: item.ACCOUNTGUID,
          AMOUNT: item.CREDITAMOUNT
        });
        // this.GeneralForm.controls['ACCOUNT'].setValue('');
        // this.craditDEbitData.AMOUNT = '';
      } else {
        this.DEBITSTOTAL += item.DEBITAMOUNT;
        this.DEBITDATAARRAY.push({
          ACCOUNTGUID: item.ACCOUNTGUID,
          AMOUNT: item.DEBITAMOUNT
        });
        // this.GeneralForm.controls['ACCOUNT'].setValue('');
        // this.craditDEbitData.AMOUNT = '';
      }
    });

    // this.CREDITDEBITDATA
  }
  itemRowClick(index) {
    this.itemRowIndex = index;
  }

  /**
   * This function is used to Add the Item
   */
  AddItem() {
    this.btnhide = 'NewDelete';
    this.isDisable = true;
    if (this.craditDEbitData.AMOUNT == '' || this.craditDEbitData.AMOUNT == null) {
      this.toastr.error('Please enter amount.');
      return false;
    } else if (this.craditDEbitData.ACCOUNTGUID == '' || this.craditDEbitData.ACCOUNTGUID == null) {
      this.toastr.error('Please select account.');
      return false;
    }
    if (this.craditDEbitData.DRCR === 'CR') {
      this.CREDITDEBITDATA.push({
        ACCOUNTNUMBER: this.Slice,
        ACCOUNTNAME: this.Slice2,
        CREDITAMOUNT: this.craditDEbitData.AMOUNT,
        DEBITAMOUNT: 0,
        DEBITCREDIT: this.craditDEbitData.DRCR,
        ACCOUNTGUID: this.Accountguid
      });
      // this.CREDITSTOTAL += this.craditDEbitData.AMOUNT;
      const number1 = +this.craditDEbitData.AMOUNT;
      this.CREDITSTOTAL = this.CREDITSTOTAL+number1;
      this.CREDITDATAARRY.push({
        ACCOUNTGUID: this.Accountguid,
        AMOUNT: this.craditDEbitData.AMOUNT
      });
      this.GeneralForm.controls['ACCOUNT'].setValue('');
      this.craditDEbitData.AMOUNT = '';
    } else {
      this.CREDITDEBITDATA.push({
        ACCOUNTNUMBER: this.Slice,
        ACCOUNTNAME: this.Slice2,
        CREDITAMOUNT: 0,
        DEBITAMOUNT: this.craditDEbitData.AMOUNT,
        DEBITCREDIT: this.craditDEbitData.DRCR,
        ACCOUNTGUID: this.Accountguid
      });
     
      const number1 = +this.craditDEbitData.AMOUNT;
       this.DEBITSTOTAL = this.DEBITSTOTAL + number1;
     // this.CREDITSTOTAL = this.craditDEbitData.AMOUNT;
      this.DEBITDATAARRAY.push({
        ACCOUNTGUID: this.Accountguid,
        AMOUNT: this.craditDEbitData.AMOUNT
      });
      this.GeneralForm.controls['ACCOUNT'].setValue('');
      this.craditDEbitData.AMOUNT = '0.00';
    }
    this.craditDEbitData.DRCR = 'CR';
    this.generalTempData = new MatTableDataSource(this.CREDITDEBITDATA);
  }

/**
 * This function is used to cancle the Item data
 * */
  CancelItem() {
    this.btnhide = 'NewDelete';
    this.isDisable = true;
  }

/**
 * This function is used to Save the General data value
 */
  SaveGeneral() {
    let PostData: any = {
      DATE: this.f.NEWDATE.value,
      JOURNALTIME: "",
      DESCRIPTION: this.f.DESCRIPTION.value,
      DEBITS: this.DEBITDATAARRAY,
      CREDITS: this.CREDITDATAARRY
    }
    if (this.action === 'new' || this.action === 'duplicate') {
      this.FormAction = 'insert';
      PostData.JOURNALGUID = '';
      this.successMsg = 'Save successfully';
    } else {
      this.FormAction = 'update';
      PostData.JOURNALGUID = this.JournalData.JOURNALGUID;
      this.successMsg = 'Update successfully';
    }
    this.isspiner = true;
    // if (this.CREDITSTOTAL === this.DEBITSTOTAL) {
      let details = { FormAction: this.FormAction, UseTrust: this.accountTypeData.UseTrust, VALIDATEONLY: true, Data: PostData };
      this._mainAPiServiceService.getSetData(details, 'SetJournal').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
        if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
          this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 451 && response.STATUS == 'warning') {
          this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.CODE == 450 && response.STATUS == 'error') {
          this.checkValidation(response.DATA.VALIDATIONS, details);
        } else if (response.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }, error => {
        this.isspiner = false;
        this.toastr.error(error);
      });
    // } else {
    //   this.isspiner = false;
    //   this.toastr.error('Credit Amount total and Debit Amount total not equal.');
    // }
  }

  /**
   * This function is used to check the validation data value
   */
  checkValidation(bodyData: any, PostActivityData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.isspiner = false;
      this.toastr.error(errorData);
    }
    if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true, width: '100%', data: warningData
      });
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveActivityData(PostActivityData);
        }
        this.confirmDialogRef = null;
      });
    }
    if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0)
      this.saveActivityData(PostActivityData);
  }

  /**
   * This function is used to Save the Activity data
   */
  saveActivityData(PostActivityData: any) {
    PostActivityData.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(PostActivityData, 'SetJournal').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success(this.successMsg);
        this.dialogRef.close(true);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(this.successMsg);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.error(res.STATUS);
        this.isspiner = false;
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to Edit the General
   */
  EditGeneral() {
    this.isLoadingResults = true;
    this._mainAPiServiceService.getSetData({ JOURNALGUID: this.JournalData.JOURNALGUID }, 'GetJournal').pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result.CODE == 200 && result.STATUS == "success") {
        if (result.DATA.JOURNALS[0].DATE) {
          let DATE1 = result.DATA.JOURNALS[0].DATE.split("/");
          this.GeneralForm.controls['DATEGENERAL'].setValue(new Date(DATE1[1] + '/' + DATE1[0] + '/' + DATE1[2]));
          this.GeneralForm.controls['NEWDATE'].setValue(result.DATA.JOURNALS[0].DATE);
        }
        this.GeneralForm.controls['DESCRIPTION'].setValue(result.DATA.JOURNALS[0].DESCRIPTION);
        this.CREDITDEBITDATA = result.DATA.JOURNALS[0].JOURNALITEMS;
        this.CREDITDEBITDATA.forEach(element => {
          if (element.DEBITCREDIT == 'CR') {
            this.CREDITDATAARRY.push({
              ACCOUNTGUID: element.ACCOUNTGUID,
              AMOUNT: element.AMOUNT
            });
            this.DEBITSTOTAL += element.AMOUNT
          } else if (element.DEBITCREDIT == 'DR') {
            this.DEBITDATAARRAY.push({
              ACCOUNTGUID: element.ACCOUNTGUID,
              AMOUNT: element.AMOUNT
            });
             this.CREDITSTOTAL += element.AMOUNT
            //this.CREDITSTOTAL = element.AMOUNT
          }
        });
        this.generalTempData = new MatTableDataSource(this.CREDITDEBITDATA);
        this.isLoadingResults = false;
      }
    }, err => {
      this.toastr.error(err);
      this.isLoadingResults = false;
    });
  }
}
