import { TooltipService } from '@_services/tooltip.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-property-crow-allotment',
  templateUrl: './property-crow-allotment.component.html',
  styleUrls: ['./property-crow-allotment.component.scss']
})
export class PropertyCrowAllotmentComponent implements OnInit {
  @Input() MatterPropertyDetailsForm: FormGroup;
  @Input() errorWarningData: any;
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool;
  constructor( public tooltipService: TooltipService) { }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }
  /**
   * This function is used to get the textb data
   */
  myFunction(event:any) {
  
    // var copyText: any = document.getElementById("myInput");
    // copyText.select();
    // copyText.setSelectionRange(0,9999); 
    //  navigator.clipboard.writeText(copyText.value)
    // var copyText: any = document.getElementById("myInput");
    // event.select();
    // event.setSelectionRange(0,9999); 
    
    navigator.clipboard.writeText(event)
}
}
