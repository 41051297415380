import { NgModule } from '@angular/core';
import { FileDragNDropDirective } from './file-drag-n-drop.directive';




@NgModule({
    declarations: [FileDragNDropDirective],
    exports: [FileDragNDropDirective],
})
export class FileDragNDropModule { }
