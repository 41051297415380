<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_activities_main">
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">
        <!-- Update Work Item -->
        {{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="activityForm" fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.MATTERGUID,'has-warning':errorWarningData.Warning?.MATTERGUID}">
                        <mat-label>Matter</mat-label>
                        <input matInput readonly placeholder="Placeholder" formControlName="matterautoVal">
                        <mat-icon matSuffix (click)='selectMatter()'>
                            <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERGUID?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.MATTERGUID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field *ngIf="'' | productType" appearance="outline" fxFlex="33" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.FEEEARNER,'has-warning':errorWarningData.Warning?.FEEEARNER}">
                        <mat-label>Fee Earner</mat-label>
                        <mat-select #value name="selected" (selectionChange)="matterChange('FeeEarner',$event.value)" formControlName="FEEEARNER">
                            <mat-option *ngFor="let user of userList" value="{{user.USERID}}">{{user.FULLNAME}}
                            </mat-option>
                        </mat-select>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.FEEEARNER?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.FEEEARNER?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="18" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.ITEMDATE,'has-warning':errorWarningData.Warning?.ITEMDATE}">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateInput)="choosedDate('input', $event)" (dateChange)="choosedDate('change', $event)" [(ngModel)]="ActivityModel" formControlName="ITEMDATE">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMDATE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMDATE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <!-- <mat-form-field  appearance="outline" fxFlex="34" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                        <mat-label>Time of day</mat-label>
                        <mat-select formControlName="ITEMTIME">
                            <mat-option *ngFor="let data of timeStops" value="{{data}}">{{data}}</mat-option>
                        </mat-select>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
                    </mat-form-field> -->
                      <mat-form-field  appearance="outline" fxFlex="16" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.ITEMTIME,'has-warning':errorWarningData.Warning?.ITEMTIME}">
                        <input type="time" placeholder="Time of day" aria-label="Number" matInput  formControlName="ITEMTIME">
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ITEMTIME?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ITEMTIME?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="10" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.QUANTITY,'has-warning':errorWarningData.Warning?.QUANTITY}">
                        <mat-label>Quantity</mat-label>
                        <input matInput formControlName="QUANTITY" type="text" (focusout)="matterChange('Quantity',$event.value)">
                        <mat-icon matSuffix class="secondary-text">quantity</mat-icon>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITY?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITY?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.QUANTITYTYPE,'has-warning':errorWarningData.Warning?.QUANTITYTYPE}">
                        <mat-label *ngIf="lookuptype=='Activities' || lookuptype=='Activity'">Activity</mat-label>
                        <mat-label *ngIf="lookuptype=='Sundries' || lookuptype=='Sundry'">Sundry</mat-label>
                        <input type="text" matInput formControlName="QUANTITYTYPE" [matAutocomplete]="auto" (keydown.Tab)="activityChange()">
                        <mat-autocomplete #auto="matAutocomplete" [panelWidth]="800" (optionSelected)="matterChange('QuantityType',$event.option.value)">
                            <mat-option *ngFor="let Activity of filteredOptions | async" value="{{Activity.ACTIVITYID}}">
                                {{Activity?.ACTIVITYID}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{Activity.DESCRIPTION}}
                            </mat-option>
                        </mat-autocomplete>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.QUANTITYTYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.QUANTITYTYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="23" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICE,'has-warning':errorWarningData.Warning?.PRICE}">
                        <mat-label>Charge Ex-GST</mat-label>
                        <!-- currencyMask -->
                        <!-- [(ngModel)]="PRICEVAL" -->
                        <input class="sideText" type="text" mask="separator.2" [(ngModel)]="PRICEVAL" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [readonly]="isreadonly" matInput formControlName="PRICE" (blur)="calcPE()" [value]="activityForm.get('PRICE').value | number: '1.2-2'" [leadZero]="true">
                        <!-- <span class="currency_sign" matPrefix>$&nbsp;</span>
                        <mat-icon matSuffix class="secondary-text">currency</mat-icon> -->
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRICE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="23" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PRICEINCGST,'has-warning':errorWarningData.Warning?.PRICEINCGST}">
                        <mat-label>Charge Inc-GST</mat-label>
                        <!-- currencyMask -->
                        <!-- [(ngModel)]="PRICEINCGSTVAL" -->
                        <input class="sideText" type="text" mask="separator.2" [(ngModel)]="PRICEINCGSTVAL" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." [readonly]="isreadonly" type="text" matInput formControlName="PRICEINCGST" (blur)="calcPI()" [value]="activityForm.get('PRICEINCGST').value | number: '1.2-2'" [leadZero]="true">
                        <!-- <span class="currency_sign" matPrefix>$&nbsp;</span>
                        <mat-icon matSuffix class="secondary-text">currency</mat-icon> -->
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PRICEINCGST?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PRICEINCGST?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>    
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" fxFlex="33"  class="pr-4"   >
                        <mat-label>Deliverable</mat-label>
                        <mat-select  >
                            <mat-option >
                                </mat-option>
                        </mat-select>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" ><input id="myInput"  readonly disabled value="{{toolTipList?.SUBJECT?.COPYVALUE}}"><mat-icon (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SUBJECT?.COPYVALUE:'')">file_copy</mat-icon></div>
                    </mat-form-field> -->

                    <mat-form-field appearance="outline" fxFlex="19"  class="pr-4" *ngIf="DerivableDropShow==false && currentUser?.PRODUCTTYPE!='Barrister' &&  ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1">
                        <mat-label>Deliverable</mat-label>                  
                            <mat-select formControlName="MATTERDELIVERABLEGUID">
                                <mat-option [ngClass]="data.DELIVERABLEDESCRIPTION.length >= 50 ? 'my-multi-lines-item my-multi-lines-text' : ''" *ngFor="let data of Deliverable" [value]="data.MATTERDELIVERABLEGUID">{{data.DELIVERABLEDESCRIPTION}}</mat-option>
                                <!-- <mat-option value="Mandatory">Mandatory</mat-option>
                                <mat-option value="Optional">Optional</mat-option> -->

                            </mat-select>               
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.MATTERDELIVERABLEGUID?.COPYVALUE:'')" ><input id="myInput"  readonly disabled value="{{toolTipList?.MATTERDELIVERABLEGUID?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                        </mat-form-field>

                </div>
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                </div> -->
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.ADDITIONALTEXT,'has-warning':errorWarningData.Warning?.ADDITIONALTEXT}">
                        <mat-label>Invoice Text</mat-label>
                        <textarea matInput formControlName="ADDITIONALTEXT"></textarea>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.ADDITIONALTEXT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.ADDITIONALTEXT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'has-error':errorWarningData.Error?.COMMENT,'has-warning':errorWarningData.Warning?.COMMENT}">
                        <mat-label>Comment</mat-label>
                        <textarea matInput formControlName="COMMENT"></textarea>
                    <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.COMMENT?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.COMMENT?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
                     <!-- START -->
<!-- Add the new button As per the client Requirement. -->
        <button  *ngIf="action =='new'" mat-raised-button color="accent" (click)="saveActivity('SaveWithNew')" class="mat-accent" [disabled]="isspiner && modeIs == 'SaveWithNew'">
            <mat-spinner *ngIf="isspiner && modeIs == 'SaveWithNew'"></mat-spinner>Save & New
        </button>
<!-- Add the new button As per the client Requirement. -->
                     <!-- END -->
        <button mat-raised-button color="accent" class="mat-accent" (click)="saveActivity()" [disabled]="isspiner && modeIs != 'SaveWithNew'" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner && modeIs != 'SaveWithNew'"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="saveActivity()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="saveActivity()" [disabled]="isspiner" *ngIf="action === 'Duplicate'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>