import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component'
import { NewPacksDailogComponent } from '../../packs/new-packs-dailog/new-packs-dailog.component';
import { fuseAnimations } from 'src/@fuse/animations';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-packs-dailog',
  templateUrl: './packs-dailog.component.html',
  styleUrls: ['./packs-dailog.component.scss'],
  animations: fuseAnimations,
})
export class PacksDailogComponent implements OnInit, OnDestroy {
  PackDocument: FormGroup;
  isLoadingResults: boolean = false;
  action: string;
  errorWarningData: any = {};
  getDataForTable: any = [];
  dialogTitle: string;
  isspiner: boolean = false;
  theme_type = localStorage.getItem('theme_type');
  // selectedColore: string = this.theme_type == "theme-default" ? 'rebeccapurple' : '#43a047';
  selectedColore: string = 'rgb(217, 217, 217)';
  Order = this.theme_type == "theme-default" ? 'Solicitor' : 'Client';
  displayedColumns: string[] = ['Order', 'TempleteFile', 'TemplateType'];
  DocumentPack: any = [];
  currentData: any;
  highlightedRows: any;
  // DocumentPack = new MatTableDataSource(ELEMENT_DATA);
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public MainKitData: any = {
    "KITGUID": "", "KITNAME": "", "CONTEXT": "",
  };
  kitguid: any;
  formAction: string;
  Index: any;
  Context: any;
  KitName: any;
  private _unsubscribeAll$: Subject<void> = new Subject();

  constructor(
    public MatDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PacksDailogComponent>,
    private toastr: ToastrService,
    public _matDialog: MatDialog,
    private _mainAPiServiceService: MainAPiServiceService,
    private behaviorService: BehaviorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalFunService:GlobalFunctionsService
  ) {
    localStorage.setItem('istrackid', 'PacksDailogComponent');


    this.action = data.action;
    this.dialogTitle = this.action === 'edit' ? 'Edit Pack' : 'New Pack';
    localStorage.setItem('packaction', this.action);
    this.behaviorService.packs$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
      if (result) {
        this.kitguid = result.KITGUID;
        this.KitName = result.KITNAME;
        this.Context = result.CONTEXT;
      }
    });
    this.behaviorService.APIretryCall$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'PacksDailogComponent' && result['click'] && result['data']['document-pack-item']) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    if (this.action == "edit") {
      this.MainKitData.KITNAME = this.KitName;
      this.MainKitData.CONTEXT = this.Context;
      this.LoadData();
    }
  }

  /**
   * This function is used to load the data
   */
  LoadData() {
    this.isLoadingResults = true;
    let finalData = { ACTION:'GetData', Filters: { KITGUID: this.kitguid } }
    this._mainAPiServiceService.getSetData(finalData, 'document-pack-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      this.getDataForTable = res.DATA.RECORDS;
      this.isLoadingResults = false;
      this.highlightedRows = 0;
      this.rowdata(res.DATA.RECORDS, 0);
      this.currentData = res.DATA.RECORDS[0];
    });
  }

/**
 * This function is used to Add pack data
 */
  AddPack() {
    const dialogRef = this.dialog.open(NewPacksDailogComponent, {
      disableClose: true,
      panelClass: 'AddPack-dialog',
      data: {
        action: 'new',
        data: this.currentData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'EditPack') {
        this.LoadData();
      } else if (result != false) {
        this.getDataForTable.push(result);
      }
    });
  }

/**
 * This function is used to edit the the pack
 */
  EditPack() {
    const dialogRef = this.dialog.open(NewPacksDailogComponent, {
      disableClose: true, panelClass: 'EditPack-dialog', data: { action: 'edit', data: this.currentData, subdata: this.getDataForTable }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'EditPack') {
        this.LoadData();
      } else if (result != false) {
        this.getDataForTable[this.Index].ORDER = result.ORDER;
        this.getDataForTable[this.Index].TEMPLATEFILE = result.TEMPLATEFILE;
        this.getDataForTable[this.Index].TEMPLATETYPEDESC = result.TEMPLATETYPEDESC;
      }
    });
  }

/**
 * This function is used to delete the pack
 */
  DeletePack() {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: true,
      width: '100%',
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.action == 'edit') {
          let sendadata = { DATA: { KITITEMGUID: this.currentData.KITITEMGUID, KITGUID: this.kitguid }, ACTION: "delete" };
          this._mainAPiServiceService.getSetData(sendadata, 'document-pack-item').subscribe(res => {
            if (res.STATUS == "success") {
              this.LoadData();
              this.toastr.success(res.STATUS);
            }
          });
        } else {
          this.getDataForTable.splice(this.Index, 1);
        }
      }
    });
  }

  /**
   * this function is used to get the row data 
   */
  rowdata(row, index) {
    this.currentData = row;
    this.Index = index;
  }

/**
 * this function is used to save the pack
 */
  PackSave() {
    this.isspiner = true;
    let sendData = {
      KITGUID:this.action === 'edit' ? this.kitguid : '',
      KITNAME: this.MainKitData.KITNAME,
      CONTEXT: this.MainKitData.CONTEXT
    }
    let finalData = { ACTION: this.action === 'edit' ? 'update' : 'insert', DATA: sendData, VALIDATEONLY: true }
    this._mainAPiServiceService.getSetData(finalData, 'document-pack').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {

      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.checkValidation(response.DATA.VALIDATIONS, finalData);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });

  }

  /**
   * This function is used to check the validation 
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == 'No') {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   }
    //   else if (value.VALUEVALID == 'Warning') {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }

    // });
    // this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true,
    //     width: '100%',
    //     data: warningData
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
    //   this.confirmDialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.kitItemData(details);
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.kitItemData(details);

    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details).pipe(takeUntil(this._unsubscribeAll$))
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        //this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.kitItemData(details);
        }
      }
    });
  }

  /**
   * This function is used to get the kit 
   */
  kitItemData(data: any) {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, 'document-pack').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        if (localStorage.getItem('packaction') != 'edit') {
          this.getDataForTable.forEach(element => {
            if (element.TEMPLATETYPEDESC == 'Document') {
              element.TEMPLATETYPE = 0;
            } else {
              element.TEMPLATETYPE = 1;
            }
            element.KITGUID = response.DATA.KITGUID,
              element.KITITEMGUI = ''
            this.saveKitItem({ FormAction: 'insert', VALIDATEONLY: false, DATA: element });
          });

        }

        if (this.action !== 'edit') {
          this.toastr.success(' save successfully');
        } else {
          this.toastr.success(' update successfully');
        }
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == 'warning') {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == 'error') {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }

    }, error => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to save the kit
   */
  saveKitItem(data) {
    this._mainAPiServiceService.getSetData(data, 'document-pack-item').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success('Save Successfully');
      }
    }, err => {
      this.toastr.error(err);
    });
  }
  ngOnDestroy(): void {
    this.behaviorService.packsitems(null);
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }

}
