<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_folder_main">
    <h2 cdkDragHandle mat-dialog-title>{{titleText}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <div fxLayout="column" fxFlex="1 0 auto" name="form" *ngIf="_data.type=='update'">
                <mat-form-field class="example pr-4" fxFlex="50" appearance="outline">
                    <mat-label>Folder</mat-label>
                    <input matInput readonly [(ngModel)]="documentData.FOLDERNAME">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="1 0 auto" name="form">
                <mat-form-field class="example pr-4" fxFlex="50" appearance="outline">
                    <mat-label>New Folder</mat-label>
                    <input matInput [(ngModel)]="documentData.NEWFOLDERNAME">
                </mat-form-field>
            </div>
        </div>
        <mat-dialog-actions align="end" class="popup_ftr">
            <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
            <button mat-raised-button color="accent" class="mat-accent-blue" [disabled]="isspiner" (click)="saveFolder()">
                <mat-spinner *ngIf="isspiner"></mat-spinner>Save
            </button>
        </mat-dialog-actions>
    </div>