<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>{{dialogTitle}}
        <button class="btn" mat-icon-button (click)="CloseDocument()" [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form fxLayout="column" fxFlex="1 0 auto" name="form">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="{{(is_inboxAttech)?70:50}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTNAME,'has-warning':errorWarningData.Warning?.DOCUMENTNAME}">
                        <mat-label>Document Name</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTNAME" value="" matInput name="DOCUMENTNAME">
                        <mat-icon matSuffix fxFlex="5" (click)="uploader.click()">
                            <img class="seticon" src="assets/icons/web_app/folder.ico" alt="" matTooltip="" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <input type="file" hidden #uploader (change)="selectDocUpload($event)">

                    <div fxFlex="30" class="pr-4 ejs-dropdown-folder">
                        <ejs-dropdowntree (select)="FloderChnage($event)" id='folderlistFornewdoc' #folderlistdoc [fields]="fields" placeholder="Select items" (created)="created($event)" [treeSettings]="treeSettings"></ejs-dropdowntree>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.GENERATEDATE,'has-warning':errorWarningData.Warning?.GENERATEDATE}">
                        <mat-label>Date</mat-label>
                        <input (dateChange)="choosedDate('change', $event)" [(ngModel)]="DocumentRegiData.GENERATEDATETEXT" name="GENERATEDATETEXT" matInput [matDatepicker]="picker3">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" fxFlex="33" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.GENERATETIME,'has-warning':errorWarningData.Warning?.GENERATETIME}">
                        <mat-label>Time</mat-label>
                        <input [(ngModel)]="DocumentRegiData.GENERATETIME" name="GENERATETIME" matInput format="HH:mm">
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.GENERATETIME,'has-warning':errorWarningData.Warning?.GENERATETIME}">
                        <mat-label>Time</mat-label>
                        <input  matInput type="time" class="time-input" [(ngModel)]="DocumentRegiData.GENERATETIME" >
                        <!-- <mat-select [(ngModel)]="DocumentRegiData.GENERATETIME" name="GENERATETIME" #value>

                            <mat-option *ngFor="let item of timeslot" value="{{item}}">{{item}}</mat-option>
                        </mat-select> -->
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="22" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTNUMBER,'has-warning':errorWarningData.Warning?.DOCUMENTNUMBER}">
                        <mat-label>Doc No</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTNUMBER" value="0" matInput name="DOCUMENTNUMBER">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="28" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTCLASS,'has-warning':errorWarningData.Warning?.DOCUMENTCLASS}">
                        <mat-label>Class</mat-label>
                        <mat-select [(ngModel)]="DocumentRegiData.DOCUMENTCLASS" #value name="DOCUMENTCLASS">
                            <mat-option value="External Document">External Document</mat-option>
                            <mat-option value="Phone Call">Phone Call</mat-option>
                            <mat-option value="Generated Document">Generated Document</mat-option>
                            <mat-option value="Generated Email">Generated Email</mat-option>
                            <mat-option value="Received Email">Received Email</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="30" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DRAFTSTATUS,'has-warning':errorWarningData.Warning?.DRAFTSTATUS}">
                        <mat-label>Draft Status</mat-label>
                        <mat-select [(ngModel)]="DocumentRegiData.DRAFTSTATUS" #value name="DRAFTSTATUS">
                            <mat-option value="Draft">Draft</mat-option>
                            <mat-option value="Complete">Complete</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="35" class="pr-4">
                        <mat-label>Author</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTAUTHOR" matInput name="DOCUMENTAUTHOR">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="35" class="pr-4">
                        <mat-label>Recipients</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTRECIPIENTS" name="DOCUMENTRECIPIENTS" value="" matInput>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="{{(is_inboxAttech)?30:50}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTTYPE,'has-warning':errorWarningData.Warning?.DOCUMENTTYPE}">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="DocumentRegiData.DOCUMENTTYPE" #value name="DOCUMENTTYPE">
                            <mat-option value="Electronic Document">Electronic Document</mat-option>
                            <mat-option value="Web Page">Web Page</mat-option>
                            <mat-option value="Physical Document">Physical Document</mat-option>
                            <mat-option value="Generated Email">Generated Email</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="{{(is_inboxAttech)?70:50}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.KEYWORDS,'has-warning':errorWarningData.Warning?.KEYWORDS}">
                        <mat-label>Keywords</mat-label>
                        <input [(ngModel)]="DocumentRegiData.KEYWORDS" value="" matInput name="KEYWORDS">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DESCRIPTION,'has-warning':errorWarningData.Warning?.DESCRIPTION}">
                        <mat-label>Description</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DESCRIPTION" matInput name="DESCRIPTION">
                    </mat-form-field>
                </div>

                <!-- Old design -->
                <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DESCRIPTION,'has-warning':errorWarningData.Warning?.DESCRIPTION}">
                        <mat-label>Description</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DESCRIPTION" matInput name="DESCRIPTION">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DRAFTSTATUS,'has-warning':errorWarningData.Warning?.DRAFTSTATUS}">
                        <mat-label>Draft Status</mat-label>
                        <mat-select [(ngModel)]="DocumentRegiData.DRAFTSTATUS" #value name="DRAFTSTATUS">
                            <mat-option value="Draft">Draft</mat-option>
                            <mat-option value="Complete">Complete</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTNUMBER,'has-warning':errorWarningData.Warning?.DOCUMENTNUMBER}">
                        <mat-label>Doc No</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTNUMBER" value="0" matInput name="DOCUMENTNUMBER">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTTYPE,'has-warning':errorWarningData.Warning?.DOCUMENTTYPE}">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="DocumentRegiData.DOCUMENTTYPE" #value name="DOCUMENTTYPE">
                            <mat-option value="Electronic Document">Electronic Document</mat-option>
                            <mat-option value="Web Page">Web Page</mat-option>
                            <mat-option value="Physical Document">Physical Document</mat-option>
                            <mat-option value="Generated Email">Generated Email</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Author</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTAUTHOR" matInput name="DOCUMENTAUTHOR">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Recipients</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTRECIPIENTS" name="DOCUMENTRECIPIENTS" value="" matInput>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                    <div fxFlex="33" class="pr-4 ejs-dropdown-folder">
                        <ejs-dropdowntree (select)="FloderChnage($event)" id='folderlistFornewdoc' #folderlistdoc [fields]="fields" placeholder="Select items" (created)="created($event)" [treeSettings]="treeSettings"></ejs-dropdowntree>
                    </div>

                    <mat-form-field appearance="outline" fxFlex="{{(is_inboxAttech)?33:50}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.DOCUMENTNAME,'has-warning':errorWarningData.Warning?.DOCUMENTNAME}">
                        <mat-label>Document Name</mat-label>
                        <input [(ngModel)]="DocumentRegiData.DOCUMENTNAME" value="" matInput name="DOCUMENTNAME">
                        <mat-icon matSuffix fxFlex="5" (click)="uploader.click()">
                            <img class="seticon" src="assets/icons/web_app/folder.ico" alt="" matTooltip="Twitter" matTooltipPosition="above">
                        </mat-icon>
                    </mat-form-field>
                    <input type="file" hidden #uploader (change)="selectDocUpload($event)">
                    <mat-form-field appearance="outline" fxFlex="{{(is_inboxAttech)?34:50}}" class="pr-4" [ngClass]="{'has-error':errorWarningData.Error?.KEYWORDS,'has-warning':errorWarningData.Warning?.KEYWORDS}">
                        <mat-label>Keywords</mat-label>
                        <input [(ngModel)]="DocumentRegiData.KEYWORDS" value="" matInput name="KEYWORDS">
                    </mat-form-field>
                </div> -->

            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="DocumentSave()" [disabled]="isspiner" *ngIf="action === 'new'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="DocumentSave()" [disabled]="isspiner" *ngIf="action === 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update
        </button>
        <button mat-raised-button color="accent" class="mat-accent" (click)="DocumentSave()" [disabled]="isspiner" *ngIf="action !== 'new' && action !== 'edit'">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Duplicate
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>