<div fxLayout="column" fxFlex fusePerfectScrollbar class="time_entry_sidebar">
    <mat-list>
        <!-- <h3 matSubheader>
            <span>Timers</span>
            <button mat-raised-button color="primary" class="new-timer-button mat-accent" (click)="AddTimer()">New
                Timer</button>
        </h3> -->
        <div fxLayout="row">
            <mat-form-field class="pr-4" appearance="outline" fxFlex="70"
                class="searchMatter-input"
                [ngClass]="{'has-error':!searchMatterValue}">
                <mat-label>Matters</mat-label>
                <mat-chip-list #chipList aria-label="Matter selection"
                    id="chiplist">
                    <mat-chip *ngFor="let matterdata of mattername"
                        (removed)="remove(matterdata)"
                        class="mat-chips-selected">
                        {{matterdata| slice:0:40}}<ng-container
                            *ngIf="matterdata.length>50">...</ng-container>
                        <button matChipRemove class="canclebutton">
                            <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input matInput type="search" class="form-control"
                        name="Matter" [(ngModel)]="searchMatterValue"
                        (keydown.Tab)="datashow($event)"
                        #MatterInput1 [matAutocomplete]="autoMatter"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)"
                        (keyup)="onKeyUp($event)"
                        [ngClass]="mattername.length > 0?'hideInput':'showInput'">
                </mat-chip-list>
                <mat-autocomplete #autoMatter="matAutocomplete"
                    (optionSelected)="selectedValue($event)">
                    <mat-option class="mat-option_1"
                        *ngFor="let data of matterlist"
                        (click)="selectedmatterguid(data)"
                        value="{{data.MATTERGUID}}">
                        <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                    </mat-option>
                </mat-autocomplete>

                <mat-icon class="matter_icon_timeDiary" matSuffix
                    (click)='openMatterSetTimer()'>
                    <img src="assets/icons/web_app/icon_matter_d.ico"
                        matTooltip="select matter"
                        matTooltipPosition="above">
                </mat-icon>
            </mat-form-field>
            <button mat-raised-button color="primary"
                class="new-timer-button mat-accent"
                (click)="addTimerMatterWise()" fxFlex="27">New
                Timer</button>
        </div>

        <mat-divider cdkFocusRegionEnd></mat-divider>
        <span *ngIf="_eventsData">
            <mat-list-item *ngFor="let event of _eventsData"
                class="time_entry_list">
                <h3 matLine *ngIf="event.isStart">{{currentTimerHMS}}</h3>
                <h3 matLine *ngIf="!event.isStart">{{event.time}}</h3>
                <p matLine class="secondary-text listitem-text">{{event.matter_id}}</p>
                <button mat-raised-button color="primary" *ngIf="!event.isStart"
                    (click)="startTimer(event.uuid)"
                    mat-button class="play_btn"><i class="material-icons">play_arrow</i></button>
                <button mat-raised-button color="primary" *ngIf="event.isStart"
                    (click)="stopTimer(event.uuid)"
                    mat-button class="pause_btn"><i class="material-icons">pause</i></button>
                <button mat-raised-button color="primary" mat-button
                    class="pause_btn" (click)="endTimer(event.uuid)"><i
                        class="material-icons">stop</i></button>
                <mat-divider cdkFocusRegionEnd></mat-divider>
            </mat-list-item>
        </span>
    </mat-list>
</div>
