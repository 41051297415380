import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef  } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as $ from "jquery";
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-allowance-popup',
  templateUrl: './allowance-popup.component.html',
  styleUrls: ['./allowance-popup.component.scss']
})
export class AllowancePopupComponent implements OnInit, OnDestroy {
  AlowanceType: any = [];
  StatusOption: any = [];
  errorWarningData: any = {};
  errorWarningDataArray: any = {}
  dialogTitle: any;
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  action: any;
  selectedAllowance: any = {};
  curentUser: any;
  btnTex: any = "Save";
  confirmDialogRef: any;
  AllowanceForm: FormGroup;
  GetSetMixRetry: boolean = true;
  allowancesData: any;
  windowNameId:any;
  private _unsubscribeAll$: Subject<void> = new Subject();
  CurrenctSymbol: any = ''
  
  testBool: boolean=false;
  toolTipList: any
  toolTipPostion ="above";
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  sub6: Subscription;
  sub7: Subscription;
  sub8: Subscription;
  sub9: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _mainAPiServiceService: MainAPiServiceService,
    public datepipe: DatePipe,
    private behaviorService: BehaviorService, public _matDialog: MatDialog,
    private toastr: ToastrService, public dialogRef: MatDialogRef<AllowancePopupComponent>,
    private _formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    public globalFunService:GlobalFunctionsService,public tooltipService:TooltipService,) {
    localStorage.setItem('istrackid', 'AllowancePopupComponent');
    this.action = data.action;
    // this.behaviorService.MatterData$.subscribe(result => {
    //   if (result) {
    //     this.curentUser = result;
    //   } else {
    //     this.curentUser = JSON.parse(localStorage.getItem('set_active_matters'));
    //   }
    // });
    this.sub1 = this.behaviorService.CURRENCYSYMBOL$.subscribe(res => {
      if (res && res !== '') {
          this.CurrenctSymbol = res
      }
    })
    this.sub2 = this.behaviorService.MatterData$.subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
      
      this.curentUser = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
      if (this.curentUser) {
        // localStorage.setItem('set_active_matters', JSON.stringify(this.curentUser));
      } else {
        if (result) {
          this.curentUser = result;
          localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.curentUser))
        } else {
            this.curentUser = JSON.parse(localStorage.getItem('set_active_matters'));
            localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.curentUser))
        }
      }
    });

    
    this.sub3 = this.behaviorService.allowancesData$.subscribe(result => {
      if (result) {
        this.allowancesData = result;
      }
    });

    this.sub4 = this.behaviorService.APIretryCall$.subscribe((result) => {
      if (localStorage.getItem('istrackid') == 'AllowancePopupComponent' && this.GetSetMixRetry && result['click'] && (result['data']['settlement-allowance'] || result['data']['settlement-allowance'])) {
        this.ngOnInit();
      }
      if (localStorage.getItem('istrackid') == 'AllowancePopupComponent' && this.GetSetMixRetry == false && result['click'] && result['data']['settlement-allowance']) {
        this.saveAllowance();
      }
      if (localStorage.getItem('istrackid') == 'AllowancePopupComponent' && result['click'] && result['data']['utility']) {
        this.CalcSettlementAllowance();
      }
    });
  }

  ngOnInit() {
    this.setTooltipData()
    this.AllowanceForm = this._formBuilder.group({
      DATERANGE: ['', [Validators.required]],
      SETTLEMENTALLOWANCEGUID: [''],
      MATTERGUID: [this.curentUser.MATTERGUID],
      SETTLEMENTTYPE: [1],
      // DATEFROM: [this.datepipe.transform(new Date(), "dd/MM/yyyy")],
      // DATEFROMTEXT: [new Date()],
      // TODATE: [this.datepipe.transform(new Date(), "dd/MM/yyyy")],
      // TODATETEXT: [new Date()],
      DATEFROM: [''],
      DATEFROMTEXT: [''],
      TODATE: [''],
      TODATETEXT: [''],
      STATUS: [''],
      PAYABLEBYVENDER: [''],
      PAYABLEBYPURCHASER: [''],
      COMMENT: [''],
      COUNT: [''],
      UNITSIZE: ['',{ updateOn: 'blur' }],
      AMOUNTPERUNIT: ['',{ updateOn: 'blur' }],
      APPLICABLETOWHO: ['']
    });
    
    if (this.action === 'new') {
      this.dialogTitle = 'New Allowance';
      this.btnTex = 'Save';
      this.defaultAPI('new');
      this.AllowanceForm.controls["DATERANGE"].setValue('Date Range');
      this.hideshowdate('Date Range');
    } else if (this.action === 'edit') {
      this.dialogTitle = 'Update Allowance';
      this.btnTex = 'Update';
      this.defaultAPI('edit');
    }
    this.sub = this.AllowanceForm.valueChanges.subscribe(formControl => {
      this.changeForControl(formControl);
    })
  
   }

   ngOnDestroy(): void {
       this._unsubscribeAll$.next();
       this._unsubscribeAll$.complete();
       this.sub?.unsubscribe();
       this.sub1?.unsubscribe();
       this.sub2?.unsubscribe();
       this.sub3?.unsubscribe();
       this.sub4?.unsubscribe();
       this.sub5?.unsubscribe();
       this.sub6?.unsubscribe();
       this.sub7?.unsubscribe();
       this.sub8?.unsubscribe();
       this.sub9?.unsubscribe();
   }

   /**
    * This function is used to set the Tooltip Data value.
    */
  async setTooltipData(){ 
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['SettlementAllowance'] ){
      this.toolTipList  = TooltipData['SettlementAllowance'];
    }else{
      this.tooltipService.setToolTipData = ('SettlementAllowance');
     }
  };

  /**
   * This function is used to Toggle For the update 
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

  /**
   * This function is used to change for the control data value.
   */
  changeForControl(formControl):void{
    const dubObj ={...this.errorWarningDataArray.Error};
    let element = this.errorWarningData.Error;
    for (const [key, value] of Object.entries(dubObj)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element[key];
       dubObj[key] = value;
       this.errorWarningDataArray.Error = {...dubObj}
     } else if (!formControl[KEY]) {
        element[key] = dubObj[key];
     }
    }

    const dubObj_1 ={...this.errorWarningDataArray.Warning};
    let element_1 = this.errorWarningData.Warning;
    for (const [key, value] of Object.entries(dubObj_1)) {
      const KEY = (key).toLocaleUpperCase();
     if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
       delete element_1[key];
       dubObj_1[key] = value;
       this.errorWarningDataArray.Warning = {...dubObj_1}
     } else if (!formControl[KEY]) {
        element_1[key] = dubObj_1[key];
     }
    }
  }

  /**
   * This function is used to validate the color
   */
  async validationColor(bodyData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == "No") {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == "Warning") {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = await { Error: tempError, Warning: tempWarning };
    this.errorWarningDataArray  = await { Error: tempError, Warning: tempWarning };
    this.changeForControl(this.f)
  }

  /**
   * This function is used to choosed form the date data value.
   */
  choosedFromDate(type: string, event: MatDatepickerInputEvent<Date>):void {
    let begin = this.datepipe.transform(event.value, "dd/MM/yyyy");
    this.AllowanceForm.controls["DATEFROM"].setValue(begin);
    this.CalcSettlementAllowance();
  }

  /**
   * This function is used to choosed the Date data value.
   */
  choosedToDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let begin = this.datepipe.transform(event.value, "dd/MM/yyyy");
    this.AllowanceForm.controls["TODATE"].setValue(begin);
    this.CalcSettlementAllowance();
  }

  /**
   * This function is used to default API set
   */
  defaultAPI(action):void {
    this.isLoadingResults = true;
   this.sub5 = this._mainAPiServiceService.getSetData({ FormAction: 'Default', Data: { MATTERGUID: this.curentUser.MATTERGUID } }, 'settlement-allowance').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.AlowanceType = response.DATA.ALLOWANCETYPES;
        this.toolTipList = response.DATA.FIELDTIPS
        // const formattedAmount = this.currencyPipe.transform(response.DATA.DEFAULTVALUES.AMOUNTPERUNIT, this.CurrenctSymbol, 'symbol', '1.4-4');
        // const unitAmount = this.currencyPipe.transform(response.DATA.DEFAULTVALUES.UNITSIZE, this.CurrenctSymbol, 'symbol', '1.4-4');
        this.AllowanceForm.controls['AMOUNTPERUNIT'].setValue(response.DATA.DEFAULTVALUES.AMOUNTPERUNIT);
        this.AllowanceForm.controls['UNITSIZE'].setValue(response.DATA.DEFAULTVALUES.UNITSIZE);
        if (action === 'new') {
          this.allowanceChange(response.DATA.DEFAULTVALUES.SETTLEMENTTYPE);
          this.validationColor(response.DATA.VALIDATIONS);
        } else {
          this.getEditData(this.allowancesData.SETTLEMENTALLOWANCEGUID);
        }
      }
      this.isLoadingResults = false;
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to gte the edit data value.
   */
  getEditData(SETTLEMENTALLOWANCEGUID):void {
    this.isLoadingResults = true;
   this.sub6 = this._mainAPiServiceService.getSetData({"Action": "GetData",FILTERS:{ MATTERGUID: this.curentUser.MATTERGUID, SETTLEMENTALLOWANCEGUID: SETTLEMENTALLOWANCEGUID }}, 'settlement-allowance').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        let tempData = response.DATA.RECORDS[0];
        this.toolTipList = response.DATA.FIELDTIPS
        const formattedNumber = new DecimalPipe('en-GB').transform(tempData.UNITSIZE,'1.4-4');
        const formattedNumber2 = new DecimalPipe('en-GB').transform(tempData.AMOUNTPERUNIT,'1.4-4');
        this.AllowanceForm.patchValue({
          SETTLEMENTALLOWANCEGUID: tempData.SETTLEMENTALLOWANCEGUID,
          MATTERGUID: tempData.MATTERGUID,
          SETTLEMENTTYPE: tempData.SETTLEMENTTYPE,
          STATUS: tempData.STATUS,
          PAYABLEBYVENDER: tempData.PAYABLEBYVENDER,
          PAYABLEBYPURCHASER: tempData.PAYABLEBYPURCHASER,
          COMMENT: tempData.COMMENT,
          COUNT: tempData.COUNT,
          UNITSIZE: tempData.UNITSIZE,
          AMOUNTPERUNIT: tempData.AMOUNTPERUNIT,
          APPLICABLETOWHO: tempData.APPLICABLETOWHO
        });
        if (tempData.TODATE) {
          let temTODATE = tempData.TODATE.split("/");
          this.AllowanceForm.controls["TODATE"].setValue(tempData.TODATE);
          this.AllowanceForm.controls["TODATETEXT"].setValue(new Date(temTODATE[1] + "/" + temTODATE[0] + "/" + temTODATE[2]));
        }
        if (tempData.DATEFROM) {
          let temDATEFROM = tempData.DATEFROM.split("/");
          this.AllowanceForm.controls["DATEFROM"].setValue(tempData.DATEFROM);
          this.AllowanceForm.controls["DATEFROMTEXT"].setValue(new Date(temDATEFROM[1] + "/" + temDATEFROM[0] + "/" + temDATEFROM[2]));
        }
        this.allowanceChange(tempData.SETTLEMENTTYPE);
      }
      this.isLoadingResults = false;
    }, error => {
      this.isLoadingResults = false;
      this.toastr.error(error);
    });
  }
  get f() {
    return this.AllowanceForm.controls;
  }

  /**
   * This function is used to alowence changes
   */
  allowanceChange(val) {
    this.selectedAllowance = this.AlowanceType.find(element => (element.ALLOWANCEDESC).toLowerCase() == (val).toLowerCase());
    if (this.selectedAllowance) {
      this.StatusOption = this.selectedAllowance.STATUSOPTIONS;
      if (this.action === 'new') {
        this.NewAllowance()
        this.AllowanceForm.patchValue({ SETTLEMENTTYPE: this.selectedAllowance.ALLOWANCEDESC });
        if (this.StatusOption[0]) {
          this.AllowanceForm.patchValue({ STATUS: this.StatusOption[0] });
        }
      }
    }
  }

  /**
   * This function is used to amount changed
   */
  amountChange(element, field){
    const numbers = this.AllowanceForm.controls[field].value;
    // const finalNumber = numbers.replace( /[^\w.]|_/g, '');
    // if(finalNumber) {
    //   const formattedAmount = this.currencyPipe.transform(Number(finalNumber), this.CurrenctSymbol ?? '', 'symbol', '1.4-4');
    //   element.target.value = formattedAmount;
    // }
    if(numbers) {
      const numericValue = parseFloat(numbers.replace(/,/g, '')); // Remove commas and convert to a number
      const formattedNumber = new DecimalPipe('en-GB').transform(numericValue,'1.4-4'); // Use DecimalPipe
      element.target.value = formattedNumber;
    }
  }

  /**
   * This function is used to Calc Settelment Allowence
   */
  CalcSettlementAllowance():void {
    this.isLoadingResults = true;
   this.sub7 = this._mainAPiServiceService.getSetData({"Action": "CalcSettlementAllowance",
   Data:{
      MATTERGUID: this.curentUser.MATTERGUID,
       SETTLEMENTTYPE: this.f.SETTLEMENTTYPE.value,
      DATEFROM: this.f.DATEFROM.value,
       TODATE: this.f.TODATE.value,
        STATUS: this.f.STATUS.value,
      COUNT: this.f.COUNT.value,
       //UNITSIZE: this.f.UNITSIZE.value ? this.f.UNITSIZE.value.replace(/[^0-9\.]/g, '') : this.f.UNITSIZE.value,
      //  AMOUNTPERUNIT: (this.f.AMOUNTPERUNIT.value && this.f.AMOUNTPERUNIT.value !=null && this.f.AMOUNTPERUNIT.value !=undefined) ? this.f.AMOUNTPERUNIT.value.replace(/[^0-9\.]/g, '') : this.f.AMOUNTPERUNIT.value
      AMOUNTPERUNIT: (this.f.AMOUNTPERUNIT.value && this.f.AMOUNTPERUNIT.value !=null && this.f.AMOUNTPERUNIT.value !=undefined) ? this.f.AMOUNTPERUNIT.value : this.f.AMOUNTPERUNIT.value,
      UNITSIZE: this.f.UNITSIZE.value ? this.f.UNITSIZE.value : this.f.UNITSIZE.value
    }}, 'utility').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.AllowanceForm.patchValue({
          PAYABLEBYVENDER: response.DATA.PAYABLEBYVENDOR,
          PAYABLEBYPURCHASER: response.DATA.PAYABLEBYPURCHASER
        });
      }
      this.isLoadingResults = false;
    });
  }

  /**
   * This function is used to NewAllowence set 
   */
  NewAllowance():void{
   this.sub8 = this._mainAPiServiceService.getSetData({FormAction:"default" ,DATA:{}}, 'settlement-allowance').subscribe(response => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        this.toolTipList = response.DATA.FIELDTIPS;
      }
    })
  }

  /**
   * This functio is used to save the Allowence data value
   */
  saveAllowance() {
    this.GetSetMixRetry = false;
    this.isspiner = true;
    let FormAction: any = this.action === 'new' ? "insert" : "update";
    let Data: any = {
      MATTERGUID: this.curentUser.MATTERGUID,
      SETTLEMENTTYPE: this.f.SETTLEMENTTYPE.value,
      PAYABLEBYVENDER: this.f.PAYABLEBYVENDER.value,
      PAYABLEBYPURCHASER: this.f.PAYABLEBYPURCHASER.value,
      COMMENT: this.f.COMMENT.value,
      UNITSIZE: this.f.UNITSIZE.value,
      AMOUNTPERUNIT: this.f.AMOUNTPERUNIT.value,
      COUNT: this.f.COUNT.value,
      STATUS: this.f.STATUS.value,
      TODATE: this.f.TODATE.value,
      DATEFROM: this.f.DATEFROM.value,
      APPLICABLETOWHO: this.f.APPLICABLETOWHO.value
    };
    if (this.action != 'new') {
      Data.SETTLEMENTALLOWANCEGUID = this.f.SETTLEMENTALLOWANCEGUID.value;
    }
    let details = { FormAction: FormAction, VALIDATEONLY: true, Data: Data, };
    this.sub9 = this._mainAPiServiceService.getSetData(details, "settlement-allowance").subscribe((response) => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == 'success')) {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 451 && response.STATUS == "warning") {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.CODE == 450 && response.STATUS == "error") {
        this.checkValidation(response.DATA.VALIDATIONS, details);
      } else if (response.MESSAGE == "Not logged in") {
        this.dialogRef.close(false);
      }
    },
      (error) => {
        this.isspiner = false;
        this.toastr.error(error);
      }
    );
  }

  /**
   * This function is used to check the validation
   */
  async checkValidation(bodyData: any, details: any) {
    // let errorData: any = [];
    // let warningData: any = [];
    // let tempError: any = [];
    // let tempWarning: any = [];
    // bodyData.forEach(function (value) {
    //   if (value.VALUEVALID == "No") {
    //     errorData.push(value.ERRORDESCRIPTION);
    //     tempError[value.FIELDNAME] = value;
    //   } else if (value.VALUEVALID == "Warning") {
    //     tempWarning[value.FIELDNAME] = value;
    //     warningData.push(value.ERRORDESCRIPTION);
    //   }
    // });
    // this.errorWarningData = { Error: tempError, Warning: tempWarning };
    // this.errorWarningDataArray  = { Error: tempError, Warning: tempWarning };

    // if (Object.keys(errorData).length != 0) {
    //   this.toastr.error(errorData);
    //   this.isspiner = false;
    // } else if (Object.keys(warningData).length != 0) {
    //   this.isspiner = false;
    //   this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //     disableClose: true, width: "100%", data: warningData,
    //   });
    //   this.confirmDialogRef.componentInstance.confirmMessage = "Are you sure you want to Save?";
    //   this.confirmDialogRef.afterClosed().subscribe((result) => {
    //     if (result) {
    //       this.isspiner = true;
    //       this.saveAllowanceData(details);
    //     } else {
    //       this.isspiner = false;
    //     }
    //     this.confirmDialogRef = null;
    //   });
    // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
    //   this.saveAllowanceData(details);
    // }

    this.isspiner = false;
    await this.globalFunService.checkValidation(bodyData, details)
    .subscribe(data => {
      if (data) {
        this.errorWarningData = data.errorWarningData;
        this.errorWarningDataArray = data.errorWarningData;
        if (data.callback) {
          this.saveAllowanceData(details);
        }
      }
    });
  }

  /**
   * This function is used to save the allowence data value.
   */
  saveAllowanceData(data: any):void {
    data.VALIDATEONLY = false;
    this._mainAPiServiceService.getSetData(data, "settlement-allowance").subscribe((response) => {
      if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
        let massge = this.action !== "edit" ? "Save successfully" : "Update successfully";
        this.toastr.success(massge);
        $('#refreshAllowances').click();
        this.dialogRef.close(true);
      } else if (response.CODE == 451 && response.STATUS == "warning") {
        this.toastr.warning(response.MESSAGE);
        this.isspiner = false;
      } else if (response.CODE == 450 && response.STATUS == "error") {
        this.toastr.error(response.MESSAGE);
        this.isspiner = false;
      } else if (response.MESSAGE == "Not logged in") {
        this.dialogRef.close(false);
      }
    }, (error) => {
      this.toastr.error(error);
    });
  }

  /**
   * This function is used to hideshow date pickers
   */
  hideshowdatepicker(val):void {
    let FromDate;
    let ToDate;
    let ForFrom = new Date();
    let ForTo = new Date();
    switch (val) {
      case 'Last Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear() - 1, 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() , 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Current Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6 , 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() + 1, 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'First Half of Current Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Second Half of Current Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear()+1, 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() + 1, 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Last Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear() - 1, 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() - 1, 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'This Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'First Half of Current Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Second Half of Current Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case '1st Quarter (1 Jan – 31 March)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 2, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }

      case '2nd Quarter ( 1 April – 30 June)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 3, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }

      case '3rd Quarter  (1 July – 30 Sept)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 8, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }

      case '4th Quarter  (1 Oct – 31 Dec)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 9, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      default: {
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
    }

    let temDATEFROM = FromDate.split("/");
    this.AllowanceForm.controls["DATEFROM"].setValue(FromDate);
    this.AllowanceForm.controls["DATEFROMTEXT"].setValue(new Date(temDATEFROM[1] + "/" + temDATEFROM[0] + "/" + temDATEFROM[2]));

    let temTODATE = ToDate.split("/");
    this.AllowanceForm.controls["TODATE"].setValue(ToDate);
    this.AllowanceForm.controls["TODATETEXT"].setValue(new Date(temTODATE[1] + "/" + temTODATE[0] + "/" + temTODATE[2]));

    if (val == 'Date Range') {
      this.AllowanceForm.get('DATEFROMTEXT').enable();
      this.AllowanceForm.get('TODATETEXT').enable();
    } else {
      this.AllowanceForm.get('DATEFROMTEXT').disable();
      this.AllowanceForm.get('TODATETEXT').disable();
    }
    this.CalcSettlementAllowance();
  }

  /**
   * This fucntion is used to hide show the date picker data values. 
   */
  hideshowdate(val):void {
    let FromDate;
    let ToDate;
    let ForFrom = new Date();
    let ForTo = new Date();
    switch (val) {
      case 'Last Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear() - 1, 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() , 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Current Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6 , 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() + 1, 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'First Half of Current Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Second Half of Current Financial Year': {
        ForFrom.setFullYear(ForFrom.getFullYear()+1, 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() + 1, 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Last Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear() - 1, 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ForTo.setFullYear(ForTo.getFullYear() - 1, 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'This Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'First Half of Current Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case 'Second Half of Current Calendar Year': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      case '1st Quarter (1 Jan – 31 March)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 0, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 2, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }

      case '2nd Quarter ( 1 April – 30 June)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 3, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 5, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }

      case '3rd Quarter  (1 July – 30 Sept)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 6, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 8, 30);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }

      case '4th Quarter  (1 Oct – 31 Dec)': {
        ForFrom.setFullYear(ForFrom.getFullYear(), 9, 1);
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");

        ForTo.setFullYear(ForTo.getFullYear(), 11, 31);
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
      default: {
        FromDate = this.datepipe.transform(ForFrom, "dd/MM/yyyy");
        ToDate = this.datepipe.transform(ForTo, "dd/MM/yyyy");
        break;
      }
    }

    let temDATEFROM = FromDate.split("/");
    // this.AllowanceForm.controls["DATEFROM"].setValue(FromDate);
    // this.AllowanceForm.controls["DATEFROMTEXT"].setValue(new Date(temDATEFROM[1] + "/" + temDATEFROM[0] + "/" + temDATEFROM[2]));

    let temTODATE = ToDate.split("/");
    // this.AllowanceForm.controls["TODATE"].setValue(ToDate);
    // this.AllowanceForm.controls["TODATETEXT"].setValue(new Date(temTODATE[1] + "/" + temTODATE[0] + "/" + temTODATE[2]));

    if (val == 'Date Range') {
      this.AllowanceForm.get('DATEFROMTEXT').enable();
      this.AllowanceForm.get('TODATETEXT').enable();
    } else {
      this.AllowanceForm.get('DATEFROMTEXT').disable();
      this.AllowanceForm.get('TODATETEXT').disable();
    }
    this.CalcSettlementAllowance();
  }
}
