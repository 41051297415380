<div cdkDrag class="popup_main_div add_edit_contact_main">
    <h2 cdkDragHandle mat-dialog-title>Change Password
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class=" one " fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form [formGroup]="changePasswordForm" fxLayout="column" fxFlex="1 0 auto" name="changePasswordForm">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label>Enter Your Current Password</mat-label>
                        <input formControlName="oldpassword" matInput [type]="oldhide ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="oldhide = !oldhide">{{oldhide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label>Enter Your New Password</mat-label>
                        <input formControlName="password" matInput [type]="phide ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="phide = !phide">{{phide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                        <mat-label> Re-Enter New Password</mat-label>
                        <input formControlName="confirmPassword" matInput [type]="cnhide ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="cnhide = !cnhide">{{cnhide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="updatePassword()" [disabled]="isspiner">
            <mat-spinner *ngIf="isspiner"></mat-spinner>Update Password
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>