<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div cdkDrag class="popup_main_div add_edit_contact_main select_metter_main_div">
    <h2 cdkDragHandle mat-dialog-title>{{title}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form name="TrustMoneyForm" name="form" [formGroup]="TrustMoneyForm" fxLayout="column" fxFlex="1 0 auto">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" class="example" fxFlex="25" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.TRANSACTIONDATE,'has-warning':errorWarningData.Warning?.TRANSACTIONDATE,'readonly_apply': IsReverse}">
                        <mat-label>Transaction Date</mat-label>
                        <input [disabled]="isdisable || IsReverse" [readonly]="isTrustTrans"
                            (dateInput)="choosedDateTranD('input', $event)"
                            (dateChange)="choosedDateTranD('change', $event)" formControlName="TRANSACTIONDATE" matInput
                            [matDatepicker]="picker" placeholder="Transaction Date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT,'readonly_apply': isTrustTrans || isdisable || IsReverse || action == 'office'}">
                        <mat-label>Amount</mat-label>
                        <!-- currencyMask -->
                        <input class="sideText"
                            [readonly]="isTrustTrans || isdisable || action == 'office'"
                            [value]="TrustMoneyForm.get('AMOUNT').value | number: '1.2-2'" type="text" mask="separator.2" thousandSeparator="," decimalMarker="."
                            [leadZero]="true"
                            (focusout)="MainAmmountPress();" formControlName="AMOUNT" placeholder="Amount" matInput min="0" max="{{MaxValue}}">
                        <mat-error *ngIf="TrustMoneyForm.get('AMOUNT').hasError('max')">
                            The maximum amount to transfer is {{MaxValue | currency }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="25" class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PAYMENTTYPE,'has-warning':errorWarningData.Warning?.PAYMENTTYPE,'readonly_apply': IsReverse}">
                        <mat-label>Payment Type... </mat-label>
                        <!-- [(ngModel)]="TrustMoneyData.PaymentType" -->
                        <mat-select formControlName="PAYMENTTYPE" #value
                            (selectionChange)="PaymentTypeChange($event.value)" name="PAYMENTTYPE"
                            placeholder="Payment Type" [disabled]="isdisable || IsReverse">
                            <mat-option *ngIf="action!='Transfer' && action != 'Transfer Unknown Deposit'"
                                value="Cheque">Cheque</mat-option>
                            <mat-option
                                *ngIf="action !='Transfer' && action != 'Transfer Unknown Deposit'  && action !='Cancelled Cheque'"
                                value="EFT">
                                EFT</mat-option>
                            <mat-option *ngIf="action =='receipt'|| action =='Statutory Receipt'|| action =='money receipt'
                                || action =='Unknown Deposit'" value="Cash">Cash</mat-option>
                            <mat-option *ngIf="action =='Transfer' || action == 'Transfer Unknown Deposit'"
                                value="Transfer">Transfer</mat-option>
                            <mat-option *ngIf="action =='money receipt' || action =='money withdrawal'" value="Bank">
                                Bank</mat-option>
                            <mat-option *ngIf="action =='withdrawal'" value="BPay">
                                Bpay </mat-option>
                            <mat-option *ngIf="action =='withdrawal'" value="International EFT">
                                International EFT</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="action !='Cancelled Cheque' && action !='Statutory Receipt' && action !='Statutory Deposit' 
                && action !='Unknown Deposit' && action !='money receipt' && action !='money withdrawal'"
                fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" >
                        <mat-checkbox fxFlex="100" [disabled]="action =='office' || isTrustTrans" formControlName="CheckBox"
                            name="CheckBox" (change)="CheckBoxClick($event.checked)" class="pr-4">Multiple Matters
                        </mat-checkbox>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <!-- <mat-form-field appearance="outline" fxFlex="33" class=" pr-4">
                        <mat-label>Amount</mat-label>
                        <input placeholder="Amount" matInput type="number">
                    </mat-form-field> -->
                    <mat-form-field
                        *ngIf="action == 'Statutory Receipt' || action == 'receipt' || action == 'withdrawal' || action == 'office' ||  
                        action == 'Statutory Receipt' || action == 'Cancelled Cheque' || action =='Statutory Deposit' || action =='Unknown Deposit'"
                        appearance="outline" fxFlex="50" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.TrustAccount,'has-warning':errorWarningData.Warning?.TrustAccount}">
                        <mat-label>Trust Account</mat-label>
                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="TrustAccount"
                            placeholder="Trust Account" matInput readonly>
                    </mat-form-field>


                    <mat-form-field *ngIf="action != 'Transfer'  && action != 'Transfer Unknown Deposit'"
                        appearance="outline" fxFlex="50" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PAYOR,'has-warning':errorWarningData.Warning?.PAYOR,'readonly_apply': IsReverse}">
                        <mat-label>{{titletext}}</mat-label>
                        <!-- <input [disabled]="isdisable||IsReverse" [readonly]="isTrustTrans" formControlName="PAYOR" #payorElem
                            placeholder="{{titletext}}" [matAutocomplete]="autoPayor" matInput (keyup)="fastSearchPayor(payorElem.value)" (keydown.Tab)="selectContactTab($event)" >
                        <mat-icon class="matternews {{ImgDisAb}}" id="Contcat_id" matSuffix (click)='SelectContact()' 
                            fxFlex="5">
                            <img class="seticon" src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon> -->

                        <!-- <mat-chip-list #chipListContact aria-label="Matter selection"> -->
                            <!-- <mat-chip *ngFor="let contact of contactData" (removed)="removeContact(contact)" class="mat-chips-selected">
                                {{contact| slice:0:40}}<ng-container *ngIf="contact.length>30">...</ng-container>
                                <button  matChipRemove class="canclebutton">
                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip> -->
                            <!-- [matChipInputFor]="chipListContact" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)" -->
                            <input matInput (keydown.Tab)="selectContactTab($event)"
                                formControlName="PAYOR" #contactElem [matAutocomplete]="autoContact">
                        <!-- </mat-chip-list> -->
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <ng-container *ngIf="!selectFromIcon">
                                <mat-option class="mat-option_1" *ngFor="let data of payorList" [value]="data.CONTACTNAME" (click)="selectContactData(data)">
                                    <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                        <mat-icon matSuffix (click)="(this.contactElemdata && this.contactElemdata.nativeElement.value =='')? SelectContact():null"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>

                    </mat-form-field>
                    <!-- <mat-autocomplete #autoPayor="matAutocomplete">
                        <mat-option class="mat-option_1" *ngFor="let data of payorList" (click) ="selectContactData(data)" value="{{data.CONTACTGUID}}">
                           {{data.CONTACTNAME}}
                        </mat-option>
                    </mat-autocomplete> -->



                    <mat-form-field *ngIf="action == 'Transfer Unknown Deposit'" appearance="outline" fxFlex="50"
                        class="pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTGUIDTEXT,'has-warning':errorWarningData.Warning?.BANKACCOUNTGUIDTEXT,'readonly_apply': IsReverse}">
                        <mat-label>From Unknown</mat-label>
                        <input [disabled]="isdisable || IsReverse" [readonly]="isTrustTrans"
                            formControlName="BANKACCOUNTGUIDTEXT" placeholder="From Unknown" matInput>
                        <mat-icon matSuffix (click)="BankingDialogOpen('Unknown Deposit','Yes')" fxFlex="5">
                            <img class="seticon" src="assets/icons/web_app/icon_matter_d.ico">
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field *ngIf="action == 'Transfer'" appearance="outline" fxFlex="50" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.FROMMATTER,'has-warning':errorWarningData.Warning?.FROMMATTER,'readonly_apply': IsReverse}">
                        <mat-label>From Matter</mat-label>
                        <input class="height_increass_input" [disabled]="isdisable||IsReverse" [readonly]="isTrustTrans"
                            formControlName="FROMMATTER" placeholder="From Matter" matInput>
                        <mat-icon matSuffix (click)="SelectMatter('from matter')" fxFlex="5">
                            <img class="seticon" src="assets/icons/web_app/icon_matter_d.ico">
                        </mat-icon>
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <!-- <mat-form-field *ngIf="action == 'money receipt'" appearance="outline" fxFlex="100" class=" pr-4">
                            <mat-label>Matter</mat-label>
                            <input formControlName="SHORTNAME" placeholder="Matter" matInput>
                            <mat-icon matSuffix (click)="SelectMatter('singal matter')" fxFlex="5">
                                <img class="seticon" src="assets/icons/web_app/icon_matter_d.ico"  >
                            </mat-icon>
                        </mat-form-field> -->

                    <mat-form-field *ngIf="action == 'money withdrawal' || action == 'money receipt'"
                        appearance="outline" fxFlex="100" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.BANKACCOUNTGUIDTEXT,'has-warning':errorWarningData.Warning?.BANKACCOUNTGUIDTEXT}">
                        <mat-label>Select Controlled Money Account</mat-label>
                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="BANKACCOUNTGUIDTEXT"
                            placeholder="Select Controlled Money Account" matInput>
                        <mat-icon matSuffix (click)="BankingDialogOpen('Controlled Money Account','Yes')" fxFlex="5">
                            <img class="seticon" src="assets/icons/web_app/icon_matter_d.ico">
                        </mat-icon>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="trust-money-tabgroup">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab
                            *ngIf="matterType === false && action !='office' && action !='Cancelled Cheque' && action != 'Statutory Receipt' 
                        && action !='Statutory Deposit' && action !='Unknown Deposit' && action !='money receipt' && action !='money withdrawal'"
                            label="Single Matter">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <!-- <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.TOMATTERGUID,'has-warning':errorWarningData.Warning?.TOMATTERGUID,'readonly_apply': IsReverse || action == 'receipt' || action == 'withdrawal' || action == 'Transfer'}">
                                        <mat-label>Matter</mat-label>
                                        <input class="height_increass_input" [disabled]="isdisable || IsReverse" [readonly]="isTrustTrans || action == 'receipt' || action == 'withdrawal' || action == 'Transfer'" formControlName="SHORTNAME" matInput>
                                        <mat-icon class="matternews" matSuffix (click)="SelectMatter('singal matter')" fxFlex="5">
                                            <img class="icon_set" src="assets/icons/web_app/icon_matter_d.ico">
                                        </mat-icon>
                                    </mat-form-field> -->
                                    <mat-form-field class="example" fxFlex="100" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.TOMATTERGUID,'has-warning':errorWarningData.Error?.TOMATTERGUID, 'readonly_apply' : IsReverse}">
                                        <mat-label>Matters</mat-label>
                                        <mat-chip-list #chipList aria-label="Matter selection">
                                            <mat-chip *ngFor="let matterdata of mattername"
                                                (removed)="remove(matterdata)" class="mat-chips-selected">
                                                <span *ngIf="mattername.length != 0"><span [ngStyle]="{'color': '#'+AmountColor}">{{matterdata.SHORTNAME}}
                                                        :</span><span [ngStyle]="{'color': '#'+AmountColor}">{{matterdata.ACCOUNTNAME}}</span> &nbsp; <span [ngStyle]="{'color': '#'+AmountColor}">:
                                                        <!-- {{matterdata.OPENINGBALANCE | currency}} -->
                                                        {{mattername.ACCOUNTBALANCE ? mattername.ACCOUNTBALANCE : mattername[0].ACCOUNTBALANCE | currency}}
                                                    </span></span>

                                                <!-- <mat-chip *ngFor="let matterdata of DataGetAccount" (removed)="remove(matterdata)" class="mat-chips-selected">
                                                    {{matterdata.ACCOUNTNAME}}---{{matterdata.OPENINGBALANCE}} -->
                                                <button matChipRemove class="canclebutton" [disabled]="isTrustTrans">
                                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <input matInput (keydown.Tab)="datashow($event)" formControlName="SHORTNAME"
                                                #MatterInput1 [matAutocomplete]="autoMatter"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event)"
                                                (keyup)="onKeyUp($event)"
                                                [ngClass]="mattername.length > 0?'hideInput':'showInput'"
                                                matTooltip="{{(toolTipList)?toolTipList.SHORTNAME.HOVER:''}}"
                                                [disabled]="isdisable || IsReverse">
                                        </mat-chip-list>
                                        <mat-autocomplete #autoMatter="matAutocomplete"
                                            (optionSelected)="selectedValue($event)">
                                            <mat-option class="mat-option_1" *ngFor="let data of matterlist"
                                                (click)="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                                {{data.SHORTNAME}}--{{data.MATTER}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-icon class="matternews" matSuffix (click)="SelectMatter('singal matter')"
                                            fxFlex="5">
                                            <img class="icon_set" src="assets/icons/web_app/icon_matter_d.ico">
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea formControlName="PURPOSE" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                            cdkAutosizeMaxRows="5" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="matterType === true && action!='office'" label="Multiple Matter">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto"
                                    *ngIf="!isTrustTrans">
                                    <!-- <mat-form-field class="example " fxFlex="100" appearance="outline" [ngClass]="{'has-error':errorWarningData.Error?.SHORTNAME,'has-warning':errorWarningData.Warning?.SHORTNAME,'readonly_apply': IsReverse || action == 'receipt' || action == 'withdrawal' || action == 'Transfer'}">
                                        <mat-label>Matter</mat-label>
                                        <input class="height_increass_input" [disabled]="isdisable" [readonly]="isTrustTrans || action == 'receipt' || action == 'withdrawal' || action == 'Transfer'" formControlName="SHORTNAME" matInput>
                                        <mat-icon class="matternews" matSuffix (click)="SelectMatter('multi matter')" fxFlex="5">
                                            <img class="icon_set" src="assets/icons/web_app/icon_matter_d.ico">
                                        </mat-icon>
                                    </mat-form-field> -->
                                    <mat-form-field class="example" fxFlex="75" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.TOMATTERGUID,'has-warning':errorWarningData.Warning?.TOMATTERGUID}">
                                        <mat-label>Matters</mat-label>
                                        <mat-chip-list #chipList aria-label="Matter selection">
                                            <!-- <mat-chip *ngFor="let matterdata of mattername" (removed)="remove(matterdata)" class="mat-chips-selected">
                                                {{matterdata}} -->
                                            <mat-chip *ngFor="let matterdata of mattername"
                                                (removed)="remove(matterdata)" class="mat-chips-selected">
                                                <span *ngIf="mattername.lenght != 0"><span [ngStyle]="{'color': '#'+AmountColor}">{{matterdata.SHORTNAME}}
                                                        :</span><span [ngStyle]="{'color': '#'+AmountColor}">{{matterdata.ACCOUNTNAME}}</span> &nbsp; <span [ngStyle]="{'color': '#'+AmountColor}">:
                                                        {{matterdata.OPENINGBALANCE | currency}}</span></span>
                                                <button matChipRemove class="canclebutton">
                                                    <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <input matInput (keydown.Tab)="datashow($event)" formControlName="SHORTNAME"
                                                #MatterInput1 [matAutocomplete]="autoMatter"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event)"
                                                (keyup)="onKeyUp($event)"
                                                [ngClass]="mattername.length > 0?'hideInput':'showInput'"
                                                matTooltip="{{(toolTipList)?toolTipList.SHORTNAME.HOVER:''}}"
                                                [disabled]="hide">
                                        </mat-chip-list>
                                        <mat-autocomplete #autoMatter="matAutocomplete"
                                            (optionSelected)="selectedValue($event)">
                                            <mat-option class="mat-option_1" *ngFor="let data of matterlist"
                                                (click)="selectedmatterguid(data)" value="{{data.MATTERGUID}}">
                                                {{data.SHORTNAME}}--{{data.MATTER}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <!-- <mat-icon matSuffix>
                                            <button id="mattersnew" class="matternews menu-disabled" [disabled]="hide" (click)='selectMatter()'><img class="ipad-icon"
                                                    src="assets/icons/web_app/icon_matter_d.ico" matTooltip=""
                                                    matTooltipPosition="above">
                                            </button>
                                        </mat-icon> -->
                                        <mat-icon class="matternews" matSuffix (click)="SelectMatter('multi matter')"
                                            fxFlex="5">
                                            <img class="icon_set" src="assets/icons/web_app/icon_matter_d.ico">
                                        </mat-icon>
                                    </mat-form-field>
                                 <!-- <mat-form-field class="example" fxFlex="30" class="pr-4" appearance="outline"> -->
                                    <input [hidden]="true" type="file" id="csvFileUpload" name="csvFileUpload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  (change)="readMatterCsvFile($event.target.files)"/>
                                    <button (click)="LoadFromCSV()"
                                        class="pr-4 btn_style" mat-button>
                                        <mat-icon class="main-icon main-icon-class">
                                            <img src="assets/icons/web_app/icon_generic_delete_d.ico">
                                        </mat-icon>Load From CSV
                                    </button>
                                <!-- </mat-form-field>  -->
                                </div>
                                <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4" [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea [disabled]="isdisable" formControlName="PURPOSE" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" matInput></textarea>
                                    </mat-form-field>
                                </div> -->
                                <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto"
                                    *ngIf="!isTrustTrans">
                                    <mat-form-field class="example" fxFlex="20" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.MatterAMOUNT,'has-warning':errorWarningData.Warning?.MatterAMOUNT}">
                                        <mat-label>Matter Amount</mat-label>
                                        <input currencyMask [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="MatterAMOUNT" matInput>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="auto" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea [disabled]="isdisable" formControlName="PURPOSE" cdkTextareaAutosize
                                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" matInput></textarea>
                                    </mat-form-field>
                                    <div style="align-self: center;">
                                    <button [disabled]="isdisable || isTrustTrans" (click)="addMatterItem()"
                                        style="padding-left: 5px;" class="pr-4 btn_style" mat-button>
                                        <mat-icon class="main-icon main-icon-class">
                                            <img src="assets/icons/web_app/icon_new_d.ico" matTooltip=""
                                                matTooltipPosition="above">
                                        </mat-icon>Add
                                    </button>
                                    <button [disabled]="isdisable || isTrustTrans" (click)="deleteMatterItem()"
                                        style="padding-left: 5px;" class="pr-4 btn_style" mat-button>
                                        <mat-icon class="main-icon main-icon-class">
                                            <img src="assets/icons/web_app/icon_generic_delete_d.ico">
                                        </mat-icon>Remove
                                    </button>
                                </div>
                                </div>

                                <div *ngIf="isTrustTrans && action == 'receipt' " fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="auto" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea [disabled]="isdisable" formControlName="PURPOSE" cdkTextareaAutosize
                                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" matInput></textarea>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="isTrustTrans && action == 'withdrawal' " fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="auto" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea [disabled]="isdisable" formControlName="PURPOSE" cdkTextareaAutosize
                                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" matInput></textarea>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="isTrustTrans && action == 'Transfer' " fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="auto" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea [disabled]="isdisable" formControlName="PURPOSE" cdkTextareaAutosize
                                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" matInput></textarea>
                                    </mat-form-field>
                                </div>
                              
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-card>
                                        <div class="customer">
                                            <table class="simple data-table-trust" [@animateStagger]="{value:'0'}">
                                                <tr>
                                                    <th>Matter</th>
                                                    <th>Amount</th>
                                                    <th style=" padding-left: 48px">Reason</th>
                                                </tr>
                                                <tr *ngFor="let item of getDataForTable; let index = index"
                                                    (click)="editInnerTable(item ,index);highlightedRows=index"
                                                    [style.background]="highlightedRows == index ? selectedColore : ''"
                                                    [ngClass]="highlightedRows == index ? 'row-text-colore' : ''">
                                                    <td>{{item.MatterNumber}} : {{item.Matter}}</td>
                                                    <td>{{item.Ammount | toFixedAmount:item.Ammount}}</td>
                                                    <td style="width: 130px; padding-left: 48px">{{item.Reason}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </mat-card>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" class="exampleWrap" fxFlex="1 0 auto"
                                    *ngIf="!isTrustTrans">
                                    <mat-form-field class="example" fxFlex="50" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.Total,'has-warning':errorWarningData.Warning?.Total}">
                                        <mat-label>Total</mat-label>
                                        <input [disabled]="isdisable" currencyMask [readonly]="isTrustTrans"
                                            formControlName="Total" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example" fxFlex="50" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.Unallocated,'has-warning':errorWarningData.Warning?.Unallocated}">
                                        <mat-label>Unallocated</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans" currencyMask
                                            formControlName="Unallocated" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="action == 'money receipt' || action =='money withdrawal'" label="General">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea formControlName="PURPOSE" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                            cdkAutosizeMaxRows="5" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab
                            *ngIf="action =='Cancelled Cheque' || action =='Statutory Receipt' || action =='Statutory Deposit' || action =='Unknown Deposit'"
                            label="Details">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example" fxFlex="80" class="pr-4" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.Ledger,'has-warning':errorWarningData.Warning?.Ledger}">
                                        <mat-label>Ledger</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="Ledger"
                                            matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class=" pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.PURPOSE,'has-warning':errorWarningData.Warning?.PURPOSE}">
                                        <mat-label>Reason</mat-label>
                                        <textarea formControlName="PURPOSE" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                            cdkAutosizeMaxRows="5" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="action == 'office'" label="Trust To Office">
                            <div class="item_pad trust_To_Office" fxLayout="column">
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-card>
                                        <table #table mat-table [dataSource]="dataSource" matSort
                                            matSortActive="INVOICEDATE" matSortDirection="desc" matSortDisableClear
                                            class="table mat-elevation-z8 office_Table" style="overflow: auto;min-width: 1300px;">
                                            <ng-container matColumnDef="checked">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <mat-checkbox style="display: none;"></mat-checkbox>
                                                </th>
                                                <td mat-cell *matCellDef="let row;">
                                                    <mat-checkbox
                                                        (change)="$event ? selection.toggle(row) : null;changeValueOfCheckbox($event,row)">
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="INVOICECODE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.INVOICECODE | invoiceNumber}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="INVOICEDATE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                                                <td mat-cell *matCellDef="let element"> {{element.INVOICEDATE}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="AMOUNTOUTSTANDINGINCGST">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unpaid </th>
                                                <td mat-cell *matCellDef="let element">
                                                    {{element.AMOUNTOUTSTANDINGINCGST}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="TRUSTBALANCE">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Trust Bal </th>
                                                <td mat-cell *matCellDef="let element"> {{element.TRUSTBALANCE}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="AMOUNTAPPLIED">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Applied </th>
                                                <td mat-cell *matCellDef="let element"> {{element.AMOUNTAPPLIED}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="CLIENTNAME">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
                                                <td mat-cell *matCellDef="let element"> {{element.CLIENTNAME}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="SHORTNAME">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    {{barristerTitle}}</th>
                                                <td mat-cell *matCellDef="let element"> {{element.SHORTNAME}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="PRIMARYFEEEARNERNAME">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Primary Fee Earner</th>
                                                <td mat-cell *matCellDef="let element"> {{element.PRIMARYFEEEARNERNAME}}
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                            <tr [style.background]="highlightedRows == row.INVOICECODE ? selectedColore : ''"
                                                [ngClass]="highlightedRows == row.INVOICECODE ? 'row-text-colore' : ''"
                                                highlightedRows=row.INVOICECODE mat-row
                                                *matRowDef="let row; columns: displayedColumns;"
                                                (click)="Rowclick(row);highlightedRows = row.INVOICECODE" matRipple
                                                [@animate]="{value:'*',params:{y:'100%'}}">
                                            </tr>
                                        </table>
                                    </mat-card>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4 pt-5" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.OVERAMOUNT,'has-warning':errorWarningData.Warning?.OVERAMOUNT}">
                                        <mat-label>Enter override amount</mat-label>
                                        <input matInput matInput [readonly]="isDisabled" formControlName="OVERAMOUNT"
                                            type="number" (input)="onSearchChange($event.target.value)"
                                            max="{{priceTemp}}" value="">
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>

                        <!--start  tab 2 -->

                        <mat-tab *ngIf="PymentType=='Cheque'" label="Cheque Details">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.CHEQUENO,'has-warning':errorWarningData.Warning?.CHEQUENO}">
                                        <mat-label>Cheque No.</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="CHEQUENO" type="number" matInput>
                                    </mat-form-field>
                                    <mat-checkbox [disabled]="isdisable || isTrustTrans" formControlName="BANKCHEQUE"
                                        fxFlex="50" class="pr-4">Bank Cheque
                                    </mat-checkbox>
                                </div>

                                <div *ngIf="this.f.BANKCHEQUE.value == true && action !='receipt' && action !='Statutory Receipt' 
                                && action !='Unknown Deposit'  && action !='money receipt' " fxLayout="row wrap"
                                    fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.BENEFICIARY,'has-warning':errorWarningData.Warning?.BENEFICIARY}">
                                        <mat-label>Beneficiary</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="BENEFICIARY" matInput>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="action =='receipt' || action =='Statutory Receipt' || action =='Unknown Deposit' || action =='money receipt'"
                                    fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNAME,'has-warning':errorWarningData.Warning?.ACCOUNTNAME}">
                                        <mat-label>Account Name</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="ACCOUNTNAME" matInput>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="action =='receipt' || action =='Statutory Receipt'  || action =='money receipt' || action =='Unknown Deposit'"
                                    fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.BSB,'has-warning':errorWarningData.Warning?.BSB}">
                                        <mat-label>BSB</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="BSB"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.ACCOUNTNUMBER}">
                                        <mat-label>A/C No</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="ACCOUNTNUMBER" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>

                        <!-- end tab 2 -->
                        <mat-tab *ngIf="PymentType=='EFT'" label="EFT Details">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field *ngIf="action=='withdrawal'  || action == 'office'"
                                        class="example pr-4 readonly_apply" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.CHEQUENO,'has-warning':errorWarningData.Warning?.CHEQUENO}">
                                        <mat-label>EFT Id </mat-label>
                                        <input [disabled]="true" [readonly]="true" formControlName="CHEQUENO" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.EFTREFERENCE,'has-warning':errorWarningData.Warning?.EFTREFERENCE}">
                                        <mat-label>Bank Reference</mat-label>
                                        <!-- [readonly]="isTrustTrans" -->
                                        <input [disabled]="isdisable" formControlName="EFTREFERENCE" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNAME,'has-warning':errorWarningData.Warning?.ACCOUNTNAME}">
                                        <mat-label>Account Name</mat-label>
                                        <input [disabled]="isdisable" formControlName="ACCOUNTNAME" matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.BSB,'has-warning':errorWarningData.Warning?.BSB}">
                                        <mat-label>BSB</mat-label>
                                        <input [disabled]="isdisable" formControlName="BSB" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.ACCOUNTNUMBER,'has-warning':errorWarningData.Warning?.ACCOUNTNUMBER}">
                                        <mat-label>A/C No</mat-label>
                                        <input [disabled]="isdisable" formControlName="ACCOUNTNUMBER" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="PymentType=='Cash'" label="Cash">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.COINS,'has-warning':errorWarningData.Warning?.COINS}">
                                        <mat-label>Coins</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="COINS"
                                            (keyup)="onKey($event)" (blur)="onKey($event)" type="number" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FIVEDOLLARS,'has-warning':errorWarningData.Warning?.FIVEDOLLARS}">
                                        <mat-label>Five Dollars</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="FIVEDOLLARS" (keyup)="onKey($event)" (blur)="onKey($event)"
                                            type="number" matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.TENDOLLARS,'has-warning':errorWarningData.Warning?.TENDOLLARS}">
                                        <mat-label>Ten Dollars</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="TENDOLLARS" (keyup)="onKey($event)" (blur)="onKey($event)"
                                            type="number" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.TWENTYDOLLARS,'has-warning':errorWarningData.Warning?.TWENTYDOLLARS}">
                                        <mat-label>Twenty Dollars</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="TWENTYDOLLARS" (keyup)="onKey($event)"
                                            (blur)="onKey($event)" type="number" matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FIFTYDOLLARS,'has-warning':errorWarningData.Warning?.FIFTYDOLLARS}">
                                        <mat-label>Fifty Dollars</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="FIFTYDOLLARS" (keyup)="onKey($event)"
                                            (blur)="onKey($event)" type="number" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.HUNDREDDOLLARS,'has-warning':errorWarningData.Warning?.HUNDREDDOLLARS}">
                                        <mat-label>Hundred Dollars</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="HUNDREDDOLLARS" (keyup)="onKey($event)"
                                            (blur)="onKey($event)" type="number" matInput>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto"
                                    [ngClass]="{'has-error':errorWarningData.Error?.HUNDREDDOLLARS,'has-warning':errorWarningData.Warning?.HUNDREDDOLLARS}">
                                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline">
                                        <mat-label>Cash Total</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="TOTALDOLLARS" readonly type="number" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="PymentType=='BPay'" label="Bpay">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field *ngIf="action=='withdrawal'" class="example pr-4" fxFlex="50"
                                        appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.BPAYBILLERCODE,'has-warning':errorWarningData.Warning?.BPAYBILLERCODE}">
                                        <mat-label>Biller Code </mat-label>
                                        <input formControlName="BPAYBILLERCODE" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="50" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.BPAYREFERENCE,'has-warning':errorWarningData.Warning?.BPAYREFERENCE}">
                                        <mat-label>Bpay Reference</mat-label>
                                        <!-- [readonly]="isTrustTrans" -->
                                        <input formControlName="BPAYREFERENCE" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="PymentType=='International EFT'" label="International EFT">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field *ngIf="action=='withdrawal'" class="example pr-4" fxFlex="33"
                                        appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTSWIFTCODE,'has-warning':errorWarningData.Warning?.FOREIGNEFTSWIFTCODE}">
                                        <mat-label>Swift Code</mat-label>
                                        <input formControlName="FOREIGNEFTSWIFTCODE" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTIBAN,'has-warning':errorWarningData.Warning?.FOREIGNEFTIBAN}">
                                        <mat-label>IBAN</mat-label>
                                        <!-- [readonly]="isTrustTrans" -->
                                        <input formControlName="FOREIGNEFTIBAN" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTBANKNAME,'has-warning':errorWarningData.Warning?.FOREIGNEFTBANKNAME}">
                                        <mat-label>Bank name</mat-label>
                                        <input formControlName="FOREIGNEFTBANKNAME" matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCOUNTRY,'has-warning':errorWarningData.Warning?.FOREIGNEFTCOUNTRY}">
                                        <mat-label>Country</mat-label>
                                        <input formControlName="FOREIGNEFTCOUNTRY" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCURRENCY,'has-warning':errorWarningData.Warning?.FOREIGNEFTCURRENCY}">
                                        <mat-label>Currency</mat-label>
                                        <input formControlName="FOREIGNEFTCURRENCY" matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTAMOUNT,'has-warning':errorWarningData.Warning?.FOREIGNEFTAMOUNT}">
                                        <mat-label>Amount Paid</mat-label>
                                        <input currencyMask formControlName="FOREIGNEFTAMOUNT" matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="100" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.FOREIGNEFTCONVERSIONDETAILS,'has-warning':errorWarningData.Warning?.FOREIGNEFTCONVERSIONDETAILS}">
                                        <mat-label>Conversion details</mat-label>
                                        <input formControlName="FOREIGNEFTCONVERSIONDETAILS" matInput>
                                    </mat-form-field>

                                </div>
                            </div>
                        </mat-tab>

                        <!-- start tab 3 -->
                        <mat-tab
                            *ngIf="action != 'Transfer' && action != 'Transfer Unknown Deposit' && action != 'office' && action != 'Cancelled Cheque'"
                            label="Payor Address" [disabled]="isDisableTab">
                            <div class="item_pad" fxLayout="column">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field appearance="outline" fxFlex="100" class="pr-4"
                                        [ngClass]="{'has-error':errorWarningData.Error?.ADDRESS1,'has-warning':errorWarningData.Warning?.ADDRESS1}">
                                        <mat-label>Address</mat-label>
                                        <textarea [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="ADDRESS1" cdkTextareaAutosize cdkAutosizeMinRows="3"
                                            cdkAutosizeMaxRows="3" matInput></textarea>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.SUBURB,'has-warning':errorWarningData.Warning?.SUBURB}">
                                        <mat-label>Suburb</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="SUBURB"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.STATE,'has-warning':errorWarningData.Warning?.STATE}">
                                        <mat-label>State</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="STATE_"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field class="example pr-4" fxFlex="33" appearance="outline"
                                        [ngClass]="{'has-error':errorWarningData.Error?.POSTCODE,'has-warning':errorWarningData.Warning?.POSTCODE}">
                                        <mat-label>Postcode</mat-label>
                                        <input [disabled]="isdisable" [readonly]="isTrustTrans"
                                            formControlName="POSTCODE" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-tab>
                        <!-- end tab 3 -->
                    </mat-tab-group>
                </div>
                <!-- <mat-divider horizontal></mat-divider> -->
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="50" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.AUTHORISEDBY,'has-warning':errorWarningData.Warning?.AUTHORISEDBY}">
                        <mat-label>Authorised By</mat-label>
                        <input [disabled]="isdisable" formControlName="AUTHORISEDBY" placeholder="Authorised By"
                            matInput>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" class=" pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.PREPAREDBY,'has-warning':errorWarningData.Warning?.PREPAREDBY}">
                        <mat-label>Prepared By</mat-label>
                        <input [disabled]="isdisable" [readonly]="isTrustTrans" formControlName="PREPAREDBY"
                            placeholder="Prepared By" matInput>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <button mat-raised-button color="accent" class="mat-accent" (click)="checkBalaceSave()"
            [disabled]="(TrustMoneyForm.invalid || isspiner)" cdkFocusInitial>
            <mat-spinner *ngIf="isspiner"></mat-spinner>Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>Don't Save</button>
    </mat-dialog-actions>
</div>