import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TooltipService } from '@_services/tooltip.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-conveyancing-price-deposite',
  templateUrl: './conveyancing-price-deposite.component.html',
  styleUrls: ['./conveyancing-price-deposite.component.scss']
})
export class ConveyancingPriceDepositeComponent implements OnInit, OnDestroy {
  errorWarningData: any = {}
  @Input() MatterConveyancingDetailsForm: FormGroup
  BALANCEVAL: any;
  DepositType: any=[];
  @Input() toolTipList : any;
  toolTipPostion ="above";
  @Input() testBool ;
  sub: Subscription;
  constructor(private datepipe: DatePipe, private _mainAPiServiceService: MainAPiServiceService, public tooltipService: TooltipService) {
    this.sub = this._mainAPiServiceService.getSetData({Action:'getdata',Filters:{ 'LookupType': 'Deposit Type' }}, 'lookup').subscribe(responses => {
      if (responses.CODE === 200 && responses.STATUS === 'success') {
        this.DepositType = responses.DATA.LOOKUPS;
      }
    });
  }

    /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }


  /**
   * This function is used to get the text data.
   */
  myFunction(event:any) {
    navigator.clipboard.writeText(event)
  }
   
  get f() {
    return this.MatterConveyancingDetailsForm.controls;
  }

  /**
   * This function is used to calculate the balance
   */
  calcBalance() {
    this.BALANCEVAL = this.f.PURCHASEPRICE.value - (this.f.INITIALDEPOSIT.value + this.f.BALANCEDEPOSIT.value);
    const value = parseFloat(this.BALANCEVAL).toFixed(2);
    this.MatterConveyancingDetailsForm.controls['BALANCEVAL'].setValue(value);
  }

  /**
   * This function is used to get the comman date picker data
   */
  CommanDatePickerClick(type: string, event: MatDatepickerInputEvent<Date>, formControlVar) {
    this.MatterConveyancingDetailsForm.controls[formControlVar].setValue(this.datepipe.transform(event.value, 'dd/MM/yyyy'));
  }
}
