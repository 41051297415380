<div id="General" class="page-layout simple left-sidebar inner-sidebar inner-scroll"
    [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'solicitor-container':'barrister-container'">
    <div fxLayoutAlign="center" class="content">
        <div class="center p-24 more_tbl_clmn list_tbl_main" fusePerfectScrollbar>
            <div class="matter-serach-div general-journalwrap sticky_search_div display-flex">
                <!-- <form>
                    <div class="clearfix"> -->
                        <!-- <mat-form-field appearance="outline" fxFlex="50" class="dateinput">
                            <mat-label>Date Range</mat-label>
                            <input matInput placeholder="Choose a date" [satDatepicker]="picker2" name="dateRange" [(ngModel)]="dateRange" (dateChange)="choosedDate('change', $event)">
                            <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
                            <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                            <mat-icon matSuffix (click)="choosedDate('clearDateRange',null)" *ngIf='hasDateRange' class="icon-color-red highlight_off"> highlight_off</mat-icon>
                        </mat-form-field> -->

                        <!-- <mat-form-field class="search" appearance="outline" fxFlex="45">
                            <mat-label>Search</mat-label>
                            <input matInput placeholder="Search" (keydown)="onSearch($event)" name="SEARCH" [(ngModel)]="filterVals.SEARCH">
                            <mat-icon matSuffix (click)="onSearch('Enter')" *ngIf="!hasSearchVal">search</mat-icon>
                            <mat-icon matSuffix (click)="onSearch('removeSearchString')" *ngIf="hasSearchVal" class="icon-color-red">highlight_off</mat-icon>
                        </mat-form-field> -->
   <!-- New design filter --> 
 
                        <form [formGroup]="generalJournalFilterForm" class="align-center filter-menuwrap ml-0"
                            id="filter-form-menu-wrap">
                            <div class="align-center display-inlineflex flex-wrap filter-menublock">
                                <div class="add-filterwrap">
                                    <!-- button -->
                            <button mat-button [matMenuTriggerFor]="dummyButton" [disabled]="true" style="display: none;">
                                Filters</button>
                            <mat-menu #dummyButton="matMenu">
                            </mat-menu>
<!-- ends here ~ button -->
                                    <button mat-button [matMenuTriggerFor]="menuFilter" class="filter-button"
                                        (click)="backToFilter('optional-Discount');showFiltersList=true;"><img
                                            [src]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'assets/icons/filter-icon.png':'assets/icons/filter-icon-barrister.png'"
                                            alt="Filter" class="filter-icon" />
                                        Filters</button>
                                    <mat-icon class="icon-btn close-btn" (click)="resetFilter()"
                                        *ngIf="optionalFilters?.length > 0">close</mat-icon>
                                    <mat-icon class="icon-btn" (click)="refreshGeneral()">refresh</mat-icon>
                                    <mat-menu #menuFilter="matMenu">
                                        <div class="filterscroll">
                                            <ng-container *ngFor="let optionalFilterData of optionalFilterList">
                                                <div *ngIf="optionalFilterData.FILTERTYPE != 'HIDDEN'">
                                                    <button class="addbutton"
                                                        [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'add-btn-solicitor':'add-btn-barrister'"
                                                        *ngIf="!optionalFilterData.FILTERAPPLY && showFiltersList"
                                                        (click)="preventDefault($event);activeCurrentFilter('optional-Discount',optionalFilterData);addNewFilter(activeOptionalFilter);showFiltersList=false;">
                                                        <mat-icon>add_circle</mat-icon>
                                                        {{optionalFilterData.DESCRIPTION}}
                                                    </button>
                                                    <div class="menu-submenu" id="optional-Discount">
                                                        <h5 class="filter-title" *ngIf="activeOptionalFilter">
                                                            <mat-icon class="back-icon"
                                                                (click)="backToFilter('optional-Discount');preventDefault($event);showFiltersList=true;">
                                                                arrow_back_ios
                                                            </mat-icon>Filter by {{activeOptionalFilter.DESCRIPTION}}
                                                        </h5>

                                                        <div *ngIf="activeOptionalFilter">
                                                            <!-- Filter List Need to Apply -->
                                                            <ng-container formArrayName="FILTERSLIST">
                                                                <div *ngFor="let filter of _filterList; let j =index;"
                                                                    class="position-relative">
                                                                    <ng-container [formGroupName]="j">
                                                                        <!-- <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5> -->
                                                                        <mat-form-field appearance="outline"
                                                                            class="width-full"
                                                                            (click)="preventDefault($event)">
                                                                            <mat-select
                                                                                (selectionChange)="optionalDateFilter($event , filter , 'default' , -1)"
                                                                                name="OPERATION"
                                                                                [(ngModel)]="addFilter.OPERATION"
                                                                                formControlName="OPERATION"
                                                                                #operationFilter>
                                                                                <mat-option
                                                                                    *ngFor="let d of filter.DATEFILTERLIST; "
                                                                                    [value]="d.value">{{d.description}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>

                                                                        <div class="display-flex align-center"
                                                                            *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                            <span class="back-tick">
                                                                                <img src="assets/icons/lefticon.png"
                                                                                    alt="Left Arrow">
                                                                            </span>
                                                                            <mat-form-field class="width-full"
                                                                                appearance="outline"
                                                                                (click)="preventDefault($event)"
                                                                                *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                                <input matInput placeholder=""
                                                                                    name="VALUE" formControlName="VALUE"
                                                                                    [(ngModel)]="addFilter.VALUE"
                                                                                    [(value)]="addFilter.VALUE" required
                                                                                    appNumberMask>
                                                                            </mat-form-field>

                                                                            <mat-form-field class="width-full"
                                                                                appearance="outline"
                                                                                (click)="preventDefault($event)"
                                                                                *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                                <input matInput placeholder=""
                                                                                    name="VALUE" formControlName="VALUE"
                                                                                    [(ngModel)]="addFilter.VALUE"
                                                                                    [(value)]="addFilter.VALUE" required
                                                                                    currencyMask>
                                                                            </mat-form-field>
                                                                        </div>

                                                                        <div class="display-flex align-center"
                                                                            *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                                            <span class="back-tick">
                                                                                <img src="assets/icons/lefticon.png"
                                                                                    alt="Left Arrow">
                                                                            </span>
                                                                            <mat-form-field class="width-full"
                                                                                appearance="outline"
                                                                                (click)="preventDefault($event)">
                                                                                <input matInput placeholder=""
                                                                                    name="VALUE" formControlName="VALUE"
                                                                                    [(ngModel)]="addFilter.VALUE"
                                                                                    [(value)]="addFilter.VALUE"
                                                                                    required>
                                                                            </mat-form-field>
                                                                        </div>


                                                                        <div class="display-flex align-center"
                                                                            *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                                            <span class="back-tick">
                                                                                <img src="assets/icons/lefticon.png"
                                                                                    alt="Left Arrow">
                                                                            </span>
                                                                            <div class="display-flex align-center"
                                                                                (click)="preventDefault($event)"
                                                                                *ngIf="filter.FILTERTYPE == 'NUMBER'">
                                                                                <mat-form-field class="set-date"
                                                                                    appearance="outline">
                                                                                    <input matInput placeholder=""
                                                                                        name="VALUE"
                                                                                        formControlName="VALUE"
                                                                                        [(ngModel)]="addFilter.VALUE"
                                                                                        [(value)]="addFilter.VALUE"
                                                                                        required appNumberMask>
                                                                                </mat-form-field>
                                                                                <span class="middle-caption">and</span>
                                                                                <mat-form-field class="set-date"
                                                                                    appearance="outline">
                                                                                    <input matInput placeholder=""
                                                                                        name="VALUE2"
                                                                                        formControlName="VALUE2"
                                                                                        [(ngModel)]="addFilter.VALUE2"
                                                                                        [(value)]="addFilter.VALUE2"
                                                                                        required appNumberMask>
                                                                                </mat-form-field>
                                                                            </div>

                                                                            <div class="display-flex align-center"
                                                                                (click)="preventDefault($event)"
                                                                                *ngIf="filter.FILTERTYPE == 'CURRENCY'">
                                                                                <mat-form-field class="set-date"
                                                                                    appearance="outline">
                                                                                    <input matInput placeholder=""
                                                                                        name="VALUE"
                                                                                        formControlName="VALUE"
                                                                                        [(ngModel)]="addFilter.VALUE"
                                                                                        [(value)]="addFilter.VALUE"
                                                                                        required currencyMask>
                                                                                </mat-form-field>
                                                                                <span class="middle-caption">and</span>
                                                                                <mat-form-field class="set-date"
                                                                                    appearance="outline">
                                                                                    <input matInput placeholder=""
                                                                                        name="VALUE2"
                                                                                        formControlName="VALUE2"
                                                                                        [(ngModel)]="addFilter.VALUE2"
                                                                                        [(value)]="addFilter.VALUE2"
                                                                                        required currencyMask>
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>

                                                                        <div class="display-flex align-center"
                                                                            *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                            <span class="back-tick">
                                                                                <img src="assets/icons/lefticon.png"
                                                                                    alt="Left Arrow">
                                                                            </span>
                                                                            <div class="display-flex align-center">
                                                                                <mat-form-field appearance="outline"
                                                                                    class="set-date"
                                                                                    (click)="preventDefault($event)">
                                                                                    <input matInput
                                                                                        [matDatepicker]="picker6"
                                                                                        name="VALUE"
                                                                                        formControlName="VALUE"
                                                                                        [(ngModel)]="addFilter.VALUE"
                                                                                        [(value)]="addFilter.VALUE"
                                                                                        required>
                                                                                    <mat-datepicker-toggle matSuffix
                                                                                        [for]="picker6">
                                                                                    </mat-datepicker-toggle>
                                                                                    <mat-datepicker #picker6>
                                                                                    </mat-datepicker>
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>

                                                                        <div class="display-flex align-center"
                                                                            *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                            <span class="back-tick">
                                                                                <img src="assets/icons/lefticon.png"
                                                                                    alt="Left Arrow">
                                                                            </span>
                                                                            <div class="display-flex align-center field-box"
                                                                                (click)="preventDefault($event)">
                                                                                <mat-form-field class="set-date"
                                                                                    appearance="outline">
                                                                                    <input matInput placeholder=""
                                                                                        name="VALUE"
                                                                                        formControlName="VALUE"
                                                                                        [(ngModel)]="addFilter.VALUE"
                                                                                        [(value)]="addFilter.VALUE"
                                                                                        required appNumberMask>
                                                                                </mat-form-field>
                                                                                <mat-form-field appearance="outline"
                                                                                    class="set-date">
                                                                                    <mat-select formControlName="VALUE2"
                                                                                        [(value)]="addFilter.VALUE2"
                                                                                        [(ngModel)]="addFilter.VALUE2"
                                                                                        required>
                                                                                        <mat-option value="days">Days
                                                                                        </mat-option>
                                                                                        <mat-option value="month">Month
                                                                                        </mat-option>
                                                                                    </mat-select>
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>


                                                                        <div class="display-flex align-center"
                                                                            *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                                            <mat-form-field appearance="outline"
                                                                                class="set-date"
                                                                                (click)="preventDefault($event)">
                                                                                <input matInput [matDatepicker]="picker"
                                                                                    #StartDate name="VALUE"
                                                                                    formControlName="VALUE"
                                                                                    [(ngModel)]="addFilter.VALUE"
                                                                                    [(value)]="addFilter.VALUE" required
                                                                                    (keydown)="preventDefault($event)">
                                                                                <mat-datepicker-toggle matSuffix
                                                                                    [for]="picker">
                                                                                </mat-datepicker-toggle>
                                                                                <mat-datepicker #picker>
                                                                                </mat-datepicker>
                                                                            </mat-form-field>
                                                                            <span class="middle-caption">and</span>
                                                                            <mat-form-field appearance="outline"
                                                                                class="set-date"
                                                                                (click)="preventDefault($event)">
                                                                                <input matInput
                                                                                    [matDatepicker]="picker2" #EndDate
                                                                                    name="VALUE2"
                                                                                    formControlName="VALUE2"
                                                                                    [(ngModel)]="addFilter.VALUE2"
                                                                                    [(value)]="addFilter.VALUE2"
                                                                                    required
                                                                                    (keydown)="preventDefault($event)">
                                                                                <mat-datepicker-toggle matSuffix
                                                                                    [for]="picker2">
                                                                                </mat-datepicker-toggle>
                                                                                <mat-datepicker #picker2>
                                                                                </mat-datepicker>
                                                                            </mat-form-field>
                                                                        </div>

                                                                        <button mat-raised-button color="primary"
                                                                            class="width-full accent"
                                                                            (click)="addfilter(filter , operationFilter, $event)">Apply</button>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </mat-menu>
                                </div>
                                <div class="position-relative">
                                    <mat-icon class="badge-ic">
                                        add_circle
                                    </mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menucard"
                                        class="buttonwith-text dotted-button">
                                        Date Range<span
                                            class="filter-applytext display-flex align-center">{{(activeFilters &&
                                            activeFilters.daterange)?activeFilters.daterange : ''}}<mat-icon
                                                class="rightdownarrow">
                                                keyboard_arrow_down</mat-icon></span>
                                    </button>
                                    <mat-menu #menucard="matMenu">
                                        <h5 class="filter-title">Filter by Date Range</h5>
                                        <div class="display-flex align-center">
                                            <span class="back-tick">
                                                <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                            </span>
                                            <div class="display-flex align-center" (click)="preventDefault($event)">
                                                <mat-form-field appearance="outline" class="example dateinput">
                                                    <input matInput [satDatepicker]="picker2" name="dateRange"
                                                        formControlName="DATE" [(ngModel)]="dateRange"
                                                        (dateChange)="Filterconfig($event,'date_range')">
                                                    <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
                                                    <sat-datepicker-toggle matSuffix [for]="picker2">
                                                    </sat-datepicker-toggle>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <button mat-raised-button color="primary" class="width-full accent"
                                            (click)="DateRangeFilter('change',activeFilters.DateRange)">Apply</button>
                                    </mat-menu>
                                </div>
                                <!-- <div class="position-relative">
                                    <mat-icon class="badge-ic"
                                        (click)="(activeFilters && activeFilters.SearchString)?clearFilter('SearchString' , 'SearchString'):false">
                                        {{(activeFilters && activeFilters.SearchString)?'cancel_circle' : 'add_circle'}}
                                    </mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menu" class="buttonwith-text"
                                        (click)="setFocus('searchField')">
                                        Search<span class="filter-applytext display-flex align-center">{{(activeFilters
                                            &&
                                            activeFilters.SearchString)?activeFilters.SearchString : ''}}<mat-icon
                                                class="rightdownarrow">
                                                keyboard_arrow_down</mat-icon></span>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <div class="filter-emailbox display-flex align-center">
                                            <mat-form-field class="example" appearance="outline"
                                                (click)="preventDefault($event)">
                                                <input matInput placeholder="Search" formControlName="SEARCH"
                                                    name="SEARCH" [(ngModel)]="filterVals.SEARCH"
                                                    (keyup)="onSearch($event);Filterconfig($event,'SearchString')"
                                                    #searchField>
                                                <mat-icon matSuffix (click)="onFilterSearch('Enter')"
                                                    *ngIf="!hasSearchVal">search
                                                </mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <button mat-raised-button color="primary" class="width-full accent"
                                            (click)="onFilterSearch()">Apply</button>
                                    </mat-menu>
                                </div> -->

                                <ng-container formArrayName="FILTERS">
                                    <div *ngFor="let filter of optionalFilters; let i =index;" class="position-relative">
                                        <ng-container [formGroupName]="i">
                                            <mat-icon class="badge-ic" (click)="resetOptionalFilter(filter , filter.FILTERTYPE , i)">cancel_circle
                                            </mat-icon>
                                            <button mat-button [matMenuTriggerFor]="menuCreatedDate_i" class="buttonwith-text" (click)="setFilterUi(filter,i)">
                                                {{filter.DESCRIPTION}}<span
                                                    class="filter-applytext display-flex align-center">{{(optionalDateFilterPayload[i] &&
                                                    optionalDateFilterPayload[i]['value'] !==
                                                    null)?optionalDateFilterPayload[i]['value'].VALUE : ''}} {{(optionalDateFilterPayload[i]
                                                    && optionalDateFilterPayload[i]['value'] !== null &&
                                                    optionalDateFilterPayload[i]['value'].VALUE2 !== '' && optionalDateFilterPayload[i]['value'].VALUE2 !== null)?' - ' +
                                                    optionalDateFilterPayload[i]['value'].VALUE2 : ''}}<mat-icon class="rightdownarrow">
                                                        keyboard_arrow_down</mat-icon></span>
                                            </button>
                                            <mat-menu #menuCreatedDate_i="matMenu">
                                                <h5 class="filter-title">Filter by {{filter.DESCRIPTION}}</h5>
                                                <mat-form-field appearance="outline" class="width-full" (click)="preventDefault($event)">
                                                    <mat-select (selectionChange)="optionalDateFilter($event , filter , 'active' , i)" name="OPERATION"
                                                        formControlName="OPERATION" #Datefilter>
                                                        <mat-option *ngFor="let d of filter.DATEFILTERLIST " [value]="d.value">{{d.value}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                    
                                                <div class="display-flex align-center"
                                                    *ngIf="filter.selectedFilter !== 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                    <span class="back-tick">
                                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                    </span>
                                                    <mat-form-field class="width-full" appearance="outline"
                                                        (click)="preventDefault($event)">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required>
                                                    </mat-form-field>
                                                </div>
                    
                                                <div class="display-flex align-center"
                                                    *ngIf="filter.FILTERTYPE !== 'NUMBER' && filter.FILTERTYPE !== 'CURRENCY' && filter.FILTERTYPE !== 'DATE'">
                                                    <span class="back-tick">
                                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                    </span>
                                                    <mat-form-field class="width-full" appearance="outline"
                                                        (click)="preventDefault($event)">
                                                        <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                            required>
                                                    </mat-form-field>
                                                </div>
                    
                    
                                                <div class="display-flex align-center"
                                                    *ngIf="filter.selectedFilter == 'is between' && (filter.FILTERTYPE == 'NUMBER' || filter.FILTERTYPE == 'CURRENCY')">
                                                    <span class="back-tick">
                                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                    </span>
                                                    <div class="display-flex align-center" (click)="preventDefault($event)">
                                                        <mat-form-field class="set-date" appearance="outline">
                                                            <input matInput placeholder=""  name="VALUE" formControlName="VALUE"
                                                                required>
                                                        </mat-form-field>
                                                        <span class="middle-caption">and</span>
                                                        <mat-form-field class="set-date" appearance="outline">
                                                            <input matInput placeholder="" name="VALUE2" formControlName="VALUE2"
                                                                required>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                    
                                                <div class="display-flex align-center"
                                                    *ngIf="filter.FILTERTYPE == 'DATE' && filter.selectedFilter && (filter.selectedFilter !== 'is in the last' && filter.selectedFilter !== 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                    <span class="back-tick">
                                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                    </span>
                                                    <div class="display-flex align-center">
                                                        <mat-form-field appearance="outline" class="set-date"
                                                            (click)="preventDefault($event)">
                                                            <input matInput [matDatepicker]="picker6" name="VALUE" formControlName="VALUE"
                                                                required>
                                                            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker6></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                    
                                                <div class="display-flex align-center"
                                                    *ngIf="filter.FILTERTYPE == 'DATE' && !filter.selectedFilter || (filter.selectedFilter == 'is in the last') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                    <span class="back-tick">
                                                        <img src="assets/icons/lefticon.png" alt="Left Arrow">
                                                    </span>
                                                    <div class="display-flex align-center field-box" (click)="preventDefault($event)">
                                                        <mat-form-field class="set-date" appearance="outline">
                                                            <input matInput placeholder="" value="" name="VALUE" formControlName="VALUE"
                                                                required>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline" class="set-date">
                                                            <mat-select [value]="'days'" formControlName="VALUE2" required>
                                                                <mat-option value="days">Days</mat-option>
                                                                <mat-option value="month">Month </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                    
                    
                                                <div class="display-flex align-center"
                                                    *ngIf="filter.FILTERTYPE == 'DATE' && (filter.selectedFilter == 'is between') && (filter.FILTERTYPE !== 'NUMBER' || filter.FILTERTYPE !== 'CURRENCY')">
                                                    <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                                        <input matInput [matDatepicker]="picker" #StartDate name="VALUE"
                                                            formControlName="VALUE" required>
                                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                    <span class="middle-caption">and</span>
                                                    <mat-form-field appearance="outline" class="set-date" (click)="preventDefault($event)">
                                                        <input matInput [matDatepicker]="picker2" #EndDate name="VALUE2"
                                                            formControlName="VALUE2" required>
                                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker2></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                    
                                                <button mat-raised-button color="primary" class="width-full accent"
                                                    (click)="applayOptionalfilter(optionalFilters[i] , i ,Datefilter, $event)">Apply.</button>
                                            </mat-menu>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div class="position-relative">
                                    <!-- search  -->
                                    <mat-icon class="badge-ic"
                                        (click)="onSearch('removeSearchString');clearFilter('SearchString' , 'SearchString')"
                                        *ngIf="hasSearchVal">
                                        {{(hasSearchVal)?'cancel_circle' : ''}}</mat-icon>
                    
                                    <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                                        Search<span class="filter-applytext display-flex align-center">
                    
                                            <input matInput #searchField
                                                (keyup)="disabledMainMenuTrigger=true;onSearch($event);Filterconfig($event,'SearchString')"
                                                formControlName="SEARCH"  [(ngModel)]="filterVals.SEARCH" style="margin-top: 4px;text-align: left;" >
                                            <mat-icon matSuffix (click)="onSearch('Enter');preventDefault($event)">search</mat-icon>
                    
                                        </span>
                                    </button>
                                </div>
                                <mat-slide-toggle  class="" [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'togglechecksol':'togglecheckbar'" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'" name="SHOWRECEIPTS" [checked]="filterVals.SHOWRECEIPTS" [(ngModel)]="filterVals.SHOWRECEIPTS" [ngModelOptions]="{standalone: true}" (change)="changeValueOfCheckbox()">Receipts
                                </mat-slide-toggle> 
        
                                <mat-slide-toggle  class="" [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'togglechecksol':'togglecheckbar'" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'" name="SHOWINVOICES" [checked]="filterVals.SHOWINVOICES" [(ngModel)]="filterVals.SHOWINVOICES" [ngModelOptions]="{standalone: true}" (change)="changeValueOfCheckbox()">Invoice
                                </mat-slide-toggle> 
        
                                <mat-slide-toggle  class="" [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'togglechecksol':'togglecheckbar'" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'" name="SHOWRECEIVEMONEY" [checked]="filterVals.SHOWRECEIVEMONEY"
                                [(ngModel)]="filterVals.SHOWRECEIVEMONEY" [ngModelOptions]="{standalone: true}" (change)="changeValueOfCheckbox()">Receive Money
                                </mat-slide-toggle> 
        
                                <mat-slide-toggle  class="" [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'togglechecksol':'togglecheckbar'" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"  name="SHOWSPENDMONEY" [checked]="filterVals.SHOWSPENDMONEY"
                                [(ngModel)]="filterVals.SHOWSPENDMONEY" [ngModelOptions]="{standalone: true}" (change)="changeValueOfCheckbox()">Spend Money
                                </mat-slide-toggle>
        
                                <mat-slide-toggle  class="" [ngClass]="currentUserData.PRODUCTTYPE == 'Solicitor' ? 'togglechecksol':'togglecheckbar'" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"  name="SHOWGENERALJOURNAL" [checked]="filterVals.SHOWGENERALJOURNAL"
                                [(ngModel)]="filterVals.SHOWGENERALJOURNAL" [ngModelOptions]="{standalone: true}" (change)="changeValueOfCheckbox()">General Journal
                                </mat-slide-toggle> 

                            </div>
                        </form>
                
               

            
                        

                       



                        <!-- <mat-checkbox class="filter-checks" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"
                            name="SHOWRECEIPTS" [checked]="filterVals.SHOWRECEIPTS"
                            [(ngModel)]="filterVals.SHOWRECEIPTS" (change)="changeValueOfCheckbox()">Receipts
                        </mat-checkbox>&nbsp;&nbsp;&nbsp;
                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"
                            name="SHOWINVOICES" [checked]="filterVals.SHOWINVOICES"
                            [(ngModel)]="filterVals.SHOWINVOICES" (change)="changeValueOfCheckbox()">Invoice
                        </mat-checkbox>&nbsp;&nbsp;&nbsp;
                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"
                            name="SHOWRECEIVEMONEY" [checked]="filterVals.SHOWRECEIVEMONEY"
                            [(ngModel)]="filterVals.SHOWRECEIVEMONEY" (change)="changeValueOfCheckbox()">Receive Money
                        </mat-checkbox>&nbsp;&nbsp;&nbsp;
                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"
                            name="SHOWSPENDMONEY" [checked]="filterVals.SHOWSPENDMONEY"
                            [(ngModel)]="filterVals.SHOWSPENDMONEY" (change)="changeValueOfCheckbox()">Spend Money
                        </mat-checkbox>&nbsp;&nbsp;&nbsp;
                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData && accountTypeData.ClickType =='WithoutTrust'"
                            name="SHOWGENERALJOURNAL" [checked]="filterVals.SHOWGENERALJOURNAL"
                            [(ngModel)]="filterVals.SHOWGENERALJOURNAL" (change)="changeValueOfCheckbox()">General
                            Journal</mat-checkbox> -->



                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData.ClickType =='WithTrust'" name="SHOWTRUSTRECEIPTS"
                            [checked]="filterVals.SHOWTRUSTRECEIPTS" [(ngModel)]="filterVals.SHOWTRUSTRECEIPTS"
                            (change)="changeValueOfCheckbox()">Trust Receipts</mat-checkbox>&nbsp;&nbsp;&nbsp;
                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData.ClickType =='WithTrust'" name="SHOWTRUSTWITHDRAWALS"
                            [checked]="filterVals.SHOWTRUSTWITHDRAWALS" [(ngModel)]="filterVals.SHOWTRUSTWITHDRAWALS"
                            (change)="changeValueOfCheckbox()">Trust Withdrawals</mat-checkbox>&nbsp;&nbsp;&nbsp;
                        <mat-checkbox class="filter-checks" *ngIf="accountTypeData.ClickType =='WithTrust'" name="SHOWTRUSTTRANSFERS"
                            [checked]="filterVals.SHOWTRUSTTRANSFERS" [(ngModel)]="filterVals.SHOWTRUSTTRANSFERS"
                            (change)="changeValueOfCheckbox()">Trust Transfers</mat-checkbox>&nbsp;&nbsp;&nbsp;

                        <span class="appCues-blankSpace">
                            <a href="javascript:void(0);">Link</a>
                        </span>
                    <!-- </div>
                </form> -->


            </div>
            <div class="content">
                <div class="btn contact_right_action one dot_controller">
                    <button mat-icon-button [matMenuTriggerFor]="contextMenu" aria-label="More">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>
                    <mat-menu #contextMenu="matMenu">
                        <ng-template matMenuContent let-item="item">
                            <button mat-menu-item (click)="openDialog()"> Field Configuration</button>
                            <button mat-menu-item (click)="refreshGeneral()"> Reload Data</button>
                        </ng-template>
                    </mat-menu>

                </div>
                <div class="content">
                    <div class="example-loading-shade" *ngIf="isLoadingResults">
                        <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
                    </div>
                    <div class="example-containerdata resizable-table">
                        <div class="grid-overlay" *ngIf="isDisplay">
                            <div class="grid-overlay-msg"> There is no data to display. </div>
                        </div>
                        <mat-table [dataSource]="generalJournalData" (matSortChange)="sortData($event)"
                            matSortActive="{{sortactive}}" matSortDirection="{{sortDirection}}" matSort
                            matSortDisableClear [@animateStagger]="{value:'50'}">
                            <ng-container *ngFor="let item of displayedColumns; let i = index" matColumnDef="{{item}}">
                                <mat-header-cell
                        *matHeaderCellDef="let i = index"
                        mat-sort-header
                        class="header_title"
                        mwlResizable
                        enableGhostResize="true"
                        (resizing)="onResizing($event, item)"
                        (resizeEnd)="onResizeEnd($event, item)"
                        [resizeEdges]="{
                            bottom: false,
                            right: true,
                            top: false,
                            left: true
                         }">
                        <div
                            class="desc_cell resize-handle-right "
                            mwlResizeHandle
                            [resizeEdges]="{ right: true }"></div>
                        {{ tempColobj[item].DESCRIPTION }}
                    </mat-header-cell>
                                <mat-cell *matCellDef="let row"
                                    class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}"
                                    [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                    <span *ngIf="row[item]!==undefined">{{row[item] |
                                        toFixedAmount:tempColobj[item].CURRENCY |
                                        wordwrap:tempColobj[item].WIDEDATA}}</span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                            <mat-row [style.background]="highlightedRows == row.JOURNALGUID ? selectedColore : ''"
                                [ngClass]="highlightedRows == row.JOURNALGUID ? 'row-text-colore' : ''"
                                highlightedRows=row.JOURNALGUID *matRowDef="let row; columns: displayedColumns;"
                                (click)="editjournal(row);highlightedRows = row.JOURNALGUID " matRipple
                                [@animate]="{value:'*',params:{y:'100%'}}">
                            </mat-row>
                        </mat-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<button mat-raised-button type="button" color="primary" id="refreshGeneral" (click)="refreshGeneral();">Refresh</button>