import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { InvoiceDetailComponent } from "@pages/invoice/invoice-detail/invoice-detail.component";
import { ContactDialogComponent } from "@pages/contact/contact-dialog/contact-dialog.component";
import { UserDialogComponent } from "@pages/users/user-dialog/user-dialog.component";
import { ReceiptDilogComponent } from "@pages/invoice/receipt-dilog/receipt-dilog.component";
import { MainAPiServiceService } from "./main-api-service.service";
import { BehaviorService } from "./Behavior.service";
@Injectable({
    providedIn: "root",
})
export class HyperlinkNavigationService {
    currentUserData = JSON.parse(window.localStorage.getItem("currentUser"));
    MatterGuid: any;
    appPermissions: any = [];
    constructor(
        private toastr: ToastrService,
        private _mainAPiServiceService: MainAPiServiceService,
        private router: Router,
        public _matDialog: MatDialog,
        private behaviorService: BehaviorService
    ) {
        this.appPermissions = JSON.parse(
            localStorage.getItem("app_permissions")
        );
    }

    /**
     * This unction is used to set the hyper link
     * @param row -row data value
     * @param item -item data value
     * @param hyperLinkInfo -hyperlink inormation
     * @returns data value
     */
    selectHyperLink(row, item, hyperLinkInfo: any) {
        // if data is missing from backend
        if (!row[hyperLinkInfo["LINKFIELD"]]) {
            this.toastr.error("Data is Missing. Please try again");
            return;
        }
        // ends here ~ if data is missing from backend

        if (hyperLinkInfo.LINKTYPE == "MATTER") {
            /************
             * FOR MATTER
             */
            const MATTERGUID = row[hyperLinkInfo["LINKFIELD"]];
            this.selectMatter(MATTERGUID, hyperLinkInfo["LINKURL"]);
            /************
             * ends here ~ FOR MATTER
             */
        } else if (hyperLinkInfo.LINKTYPE == "INVOICE") {
            /************
             * FOR INVOICE
             */

            if (this.appPermissions[7]?.Access == 0) {
                this.toastr.error("You don't have permission to access this.");
                return;
            }

            const INVOICEGUID = row[hyperLinkInfo["LINKFIELD"]];
            const dialogRef = this._matDialog.open(InvoiceDetailComponent, {
                width: "100%",
                disableClose: true,
                data: { type: "view", INVOICEGUID: INVOICEGUID },
            });
            dialogRef.afterClosed().subscribe((result) => {
                // this.SetOldistrackid('close');
                if (result) {
                }
            });
            /************
             * ends here ~ FOR INVOICE
             */
        } else if (hyperLinkInfo.LINKTYPE == "CONTACT") {
            /************
             * FOR CONTACT
             */

            if (this.appPermissions[3]?.Edit == 0) {
                this.toastr.error("You don't have permission to access this.");
                return;
            }

            localStorage.setItem(
                "contactGuid",
                row[hyperLinkInfo["LINKFIELD"]]
            );
            const contactPopupData = { action: "edit" };
            const dialogRef = this._matDialog.open(ContactDialogComponent, {
                disableClose: true,
                width: "100%",
                data: contactPopupData,
            });
            /************
             * ends here ~ FOR CONTACT
             */
        } else if (hyperLinkInfo.LINKTYPE == "USER") {
            /************
             * FOR USER
             */

            if (this.appPermissions[29]?.Edit == 0) {
                this.toastr.error("You don't have permission to access this.");
                return;
            }

            const popupData = {
                action: "edit",
                USERGUID: row[hyperLinkInfo["LINKFIELD"]],
            };
            const dialogRef = this._matDialog.open(UserDialogComponent, {
                disableClose: true,
                panelClass: "User-dialog",
                data: popupData,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                }
            });
            /************
             * ends here ~ FOR USER
             */
        } else if (hyperLinkInfo.LINKTYPE == "INCOME") {
            /************
             * FOR INCOME
             */
            if (
                row.INCOMECLASS == "Receipt" ||
                row.INCOMECLASS == "Write Off"
            ) {
                const dialogRef = this._matDialog.open(ReceiptDilogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: {
                        action: "editForTB",
                        INCOMEGUID: row[hyperLinkInfo["LINKFIELD"]],
                    },
                });
            } else {
                const dialogRef = this._matDialog.open(ReceiptDilogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: {
                        action: "edit",
                        INCOMEGUID: row[hyperLinkInfo["LINKFIELD"]],
                    },
                });
            }
            /************
             * ends here ~ FOR INCOME
             */
        } else if (hyperLinkInfo.LINKTYPE == "ACCOUNT") {
            /**
             * FOR ACCOUNT
             */

            if (this.appPermissions[19]?.Access == 0) {
                this.toastr.error("You don't have permission to access this.");
                return;
            }

            const accountData = {
                ACCOUNTGUID: row[hyperLinkInfo["LINKFIELD"]],
                UseTrust: false,
            };
            localStorage.setItem(
                "setChartAccountDataEdit",
                JSON.stringify(accountData)
            );
            localStorage.setItem(
                "ChartAccountData",
                JSON.stringify(accountData)
            );
            this.behaviorService.TrustDuplicateModuleHandling(accountData);

            const chartUrl = {
                ClickType: "WithoutTrust",
                UseTrust: false,
                PopUp: "",
                Lable: "CHART OF ACCOUNTS",
            };
            this.behaviorService.TrustDuplicateModuleHandling(chartUrl);
            this.behaviorService.ChartAccountData(accountData);
            localStorage.setItem("ChartURL", JSON.stringify(chartUrl));

            this.router.navigate(["/account/transactions"]);
            /********
             * ends here ~ FOR ACCOUNT
             */
        } else if (hyperLinkInfo.LINKTYPE == "TRUSTACCOUNT") {
            /**
             * FOR TRUST TRANSACTION
             */

            if (this.appPermissions[23]?.Access == 0) {
                this.toastr.error("You don't have permission to access this.");
                return;
            }

            const accountData = {
                ACCOUNTGUID: row[hyperLinkInfo["LINKFIELD"]],
                UseTrust: false,
            };
            localStorage.setItem(
                "setChartAccountDataEdit",
                JSON.stringify(accountData)
            );
            localStorage.setItem(
                "ChartAccountData",
                JSON.stringify(accountData)
            );

            const chartUrl = {
                ClickType: "WithTrust",
                UseTrust: true,
                PopUp: "",
                Lable: "TRUST ACCOUNT TRANSACTIONS",
            };

            this.behaviorService.TrustDuplicateModuleHandling(chartUrl);
            this.behaviorService.ChartAccountData(accountData);
            localStorage.setItem("ChartURL", JSON.stringify(chartUrl));

            this.router.navigate(["/account/trust-transactions"]);
            /**
             * ends here ~ FOR TRUST TRANSACTION
             */
        } else if (hyperLinkInfo.LINKTYPE == "DOCUMENT") {
            /*********
             * FOR DOCUMENT
             */

            if (this.appPermissions[14]?.Access == 0) {
                this.toastr.error("You don't have permission to access this.");
                return;
            }

            this.OpenDocument(row[hyperLinkInfo["LINKFIELD"]]);

            /*********
             * ends here ~ FOR DOCUMENT
             */
        }
    }

   /**
    * This function is used to select the matter
    * @param MATTERGUID -Matterguid value
    * @param LINKURL -Linkurl
    */
    selectMatter(MATTERGUID, LINKURL) {
        this.MatterGuid = MATTERGUID;
        this.currentUserData = JSON.parse(
            window.localStorage.getItem("currentUser")
        );

        let matterPayload = {
            Action: "GetData",
            Filters: {
                MatterGuid: MATTERGUID,
                GETALLFIELDS: true,
            },
        };

        // Old --> { MATTERGUID: MATTERGUID, 'GETALLFIELDS': true }

        this._mainAPiServiceService
            .getSetData(matterPayload, "matter")
            .subscribe((response) => {
                // debugger;
                if (response.CODE === 200 && response.STATUS === "success") {
                    // let matterData = response.DATA.MATTERS[0];
                    let matterData = response.DATA.RECORDS[0];
                    if (matterData) {
                        localStorage.setItem(
                            "set_active_matters",
                            JSON.stringify(matterData)
                        );
                        this.behaviorService.clickMaterFromTimeLine$.next(matterData);
                        sessionStorage.setItem('SetclickMaterFromTimeLine', JSON.stringify(matterData))
                        if (this.currentUserData.OPENINNEWTAB) {
                            if (environment.ISPRO) {
                                this.commonFocusWindow(
                                    "/#" + LINKURL,
                                    "MATTERDETAILS" + "_" + this.MatterGuid
                                );
                            } else {
                                // this.commonFocusWindow(
                                //     "/" + environment.URLPATH + "/#" + LINKURL,
                                //     "MATTERDETAILS" + "_" + this.MatterGuid
                                // );
                                this.commonFocusWindow(
                                    "/#" + LINKURL,
                                    "MATTERDETAILS" + "_" + this.MatterGuid
                                );
                            }
                        } else {
                            this.router.navigate([LINKURL]);
                        }
                    } else {
                        this.toastr.error("Matter don't have sufficient Data");
                    }
                }
            });
    }
    // ends here ~ select matter link

    /**
     * This function is used to open the document
     * @param documentGUID -Document id
     */
    OpenDocument(documentGUID) {
        let sendData: any = {};
        // let APIURL = "NetDriveGetDocument";
        let APIURL = 'cloud-storage';
        sendData = { Action: 'GetDocument', Data: { FileType: "DOCUMENT", DOCUMENTGUID: "158S2TESNCDFPQPR" } };
        this._mainAPiServiceService.getSetData(sendData, APIURL).subscribe(
            (DriveGetDocument) => {
                if (
                    DriveGetDocument.CODE == 200 &&
                    DriveGetDocument.STATUS == "success"
                ) {
                    this.toastr.success("Document Generate successfully");
                    window.open(
                        DriveGetDocument.DATA.DOCUMENTS[0].DRIVEFILEWEBURL,
                        "_blank"
                    );
                } else if (DriveGetDocument.CODE == 432) {
                    let currentUser: any = JSON.parse(
                        localStorage.getItem("currentUser")
                    );
                    window.open(
                        environment.APIEndpoint +
                        "OAuth2Start?AuthType=NetDrive&SessionToken=" +
                        currentUser.SessionToken,
                        "targetWindow",
                        `toolbar=no,
               location=no,
               status=no,
               menubar=no,
               scrollbars=yes,
               resizable=yes,
               width=800,
               height=600`
                    );
                }
            },
            (error) => {
                this.toastr.error(error);
            }
        );
    }
    // ends here ~ Open Document

    
    /**
     * This function is used to comman focus on the window
     */
    commonFocusWindow(url, target) {
        let random = target;
        random = window.open(url, target);
        random.focus();
    }
    // ends here ~ open url in new tab

   
/**
 * This function is used to set the color setting
 */
    setColorSetting():void {
        this.currentUserData = JSON.parse(
            window.localStorage.getItem("currentUser")
        );
        setTimeout(() => {
            this.behaviorService.UpdatedColorSystemSetting$.subscribe(
                (data) => {
                    if (data && this.currentUserData) {
                        this.currentUserData.THEMECOLOUR = data;
                    }
                }
            );
        }, 3000);
        if (this.currentUserData) {
            if (
                this.currentUserData.PRODUCTTYPE == "Solicitor" &&
                this.currentUserData.THEMECOLOUR == "theme-yellow-light"
            ) {
                this.behaviorService.ColorSystemSetting$.next(
                    "solicitor-container"
                );
            } else if (
                this.currentUserData.PRODUCTTYPE == "Solicitor" &&
                this.currentUserData.THEMECOLOUR == "theme-blue-gray-dark"
            ) {
                this.behaviorService.ColorSystemSetting$.next(
                    "solicitor-container-withdarkmode"
                );
            } else if (
                this.currentUserData.PRODUCTTYPE == "Barrister" &&
                this.currentUserData.THEMECOLOUR == "theme-default"
            ) {
                this.behaviorService.ColorSystemSetting$.next(
                    "barrister-container"
                );
            } else if (
                this.currentUserData.PRODUCTTYPE == "Barrister" &&
                this.currentUserData.THEMECOLOUR == "theme-pink-dark"
            ) {
                this.behaviorService.ColorSystemSetting$.next(
                    "barrister-container-withdarkmode"
                );
            }
        }
    }

    /**
     * This function is used to set the color setting with hyper link
     */
    setColorSettingWithHyperlink():void {
        this.currentUserData = JSON.parse(
            window.localStorage.getItem("currentUser")
        );
        setTimeout(() => {
            this.behaviorService.UpdatedColorSystemSetting$.subscribe(
                (data) => {
                    if (data && this.currentUserData) {
                        this.currentUserData.THEMECOLOUR = data;
                    }
                }
            );
        }, 3000);
        if (this.currentUserData) {
            if (
                this.currentUserData.PRODUCTTYPE == "Solicitor" &&
                this.currentUserData.THEMECOLOUR == "theme-yellow-light"
            ) {
                this.behaviorService.ColorSystemSettingHyperLink$.next(
                    "custom-link-hyperlink"
                );
            } else if (
                this.currentUserData.PRODUCTTYPE == "Solicitor" &&
                this.currentUserData.THEMECOLOUR == "theme-blue-gray-dark"
            ) {
                this.behaviorService.ColorSystemSettingHyperLink$.next(
                    "custom-link-hyperlink-withdarkmode"
                );
            } else if (
                this.currentUserData.PRODUCTTYPE == "Barrister" &&
                this.currentUserData.THEMECOLOUR == "theme-default"
            ) {
                this.behaviorService.ColorSystemSettingHyperLink$.next(
                    "custom-link-hyperlink-barrister"
                );
            } else if (
                this.currentUserData.PRODUCTTYPE == "Barrister" &&
                this.currentUserData.THEMECOLOUR == "theme-pink-dark"
            ) {
                this.behaviorService.ColorSystemSettingHyperLink$.next(
                    "custom-link-hyperlink-barrister-with-darkmode"
                );
            }
        }
    }
}
