import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageEditorComponent } from '@syncfusion/ej2-angular-image-editor';
import { Browser, getComponent } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  @Input() imageURl
  @Output() SignaturePathData = new EventEmitter();
  public customToolbardata = ['Crop', 'ZoomIn', 'ZoomOut', 'Transform'];
  constructor() { }

  ngOnInit(): void {
  }

/**
 * This function is used to upload the Default signature
 */
uploadSignature(){
  setTimeout(() => {
      const imageEditor: ImageEditorComponent = getComponent(document.getElementById('image-editor-two'), 'image-editor');
      if (Browser.isDevice) {
          imageEditor.open(this.imageURl);
      } else {
          if(this.imageURl[0] !== ""){
              imageEditor.open((this.imageURl[0] !== "" && this.imageURl.length >0) ? this.imageURl[0] : '');
          }
      }
  },0);
}

/**
 * This function is used to open the signature in sfimage editor
 * @param data -signature data value.
 */
openSignature(data):void{
  if(data){
    setTimeout(() => {
      const imageEditordata: any = getComponent(document.getElementById('image-editor-two'), 'image-editor');
       var imageData = imageEditordata.getImageData();
       var canvas = document.createElement('canvas');
       var ctx = canvas.getContext('2d');
       canvas.width = imageData.width;
       canvas.height = imageData.height;
       ctx.putImageData(imageData, 0, 0);
       this.SignaturePathData.emit([canvas.toDataURL()]);
    },3000);
  }

}

    /**
 * This function is used to save the croped image Data.
 * @param args 
 */
    public toolbarItemClickedForImage = (args: any): void => {
      if(args.item.tooltipText == "Apply") {
          const imageEditorval: any = getComponent(document.getElementById('image-editor-two'), 'image-editor');
          var imageData = imageEditorval.getImageData();
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          canvas.width = imageData.width;
          canvas.height = imageData.height;
          ctx.putImageData(imageData, 0, 0);
          this.SignaturePathData.emit([canvas.toDataURL()]);  
        // this.getImageSize(canvas.toDataURL())
      }
  }

}
