import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { MatterPopupComponent } from '../../matter-popup.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';

@Component({
    selector: 'app-trademark-ip',
    templateUrl: './trademark-ip.component.html',
    styleUrls: ['./trademark-ip.component.scss']
})
export class TrademarkIPComponent implements OnInit, OnDestroy {
    modifiedApplicationDate: any
    DateSelect: any
    matterTrademarkForm: FormGroup;
    APIDataSubcription: Subscription
    APIDataSubcription_1: Subscription
    errorWarningData: any = {};
    MatterData: any = {};
    @Input() action: any;
    @Input() toolTipList: any;
    toolTipPostion = "above";
    @Input() testBool;
    private _unsubscribeAll$: Subject<void> = new Subject();
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MatterPopupComponent>,
        public datepipe: DatePipe,
        public _matDialog: MatDialog,
        private currencyPipe: CurrencyPipe,
        public behaviorService: BehaviorService,
        public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        public tooltipService: TooltipService

    ) {
        this.initFormData();
    }

    /**
     * This function is used to initiate the form data value
     */
    initFormData() {
        this.matterTrademarkForm = this._formBuilder.group({
            MATTERTRADEMARKGUID: [''],
            MATTERGUID: [''],
            APPLICATIONNO: [''],
            APPLICATIONDATE: [''],
            JURISDICTION: [''],
            TYPEOFTRADEMARK: [''],
            TRADEMARKCLASSES: [''],
        });
    }


      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.APIDataSubcription = this.behaviorService.ChildTableData$.subscribe(result => {
            if (result) {
                this.MatterData = result;
                this.matterTrademarkForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                if (result.classType == "Trademark/IP" && result.action == 'setData') {
                    this.SaveTradeMark();
                } else if (result.classType == "Trademark/IP" && result.action == 'deleteData') {
                    this.DeleteTradeMark();
                }
            }
        });

        if (this.action === 'edit') {
            this.mapTrademarkDataToFields();
        }

        // clear additional details data on click "Cancel" in Prompt Popup while duplicating matter
        this.behaviorService.additionalFieldsValues$.subscribe((data) => {
            if (data == true) {
                this.mapTrademarkDataToFields();
            }
        })

    }

/**
 * This function is used to map the Trademark Data to the fields
 */
    mapTrademarkDataToFields() {
        if (this.action === 'edit' || this.action === 'duplicate') {
            this.APIDataSubcription_1 = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-trademark').subscribe(response => {
                if (response.CODE == 200 && response.STATUS == 'success') {
                    let MatterTradeMarkData = response.DATA.RECORDS[0];


                    if (MatterTradeMarkData) {

                        let applicationDate = MatterTradeMarkData.APPLICATIONDATE;
                        let applicationDateSplit = applicationDate.split("/")
                        this.modifiedApplicationDate = `${applicationDateSplit[2]}/${applicationDateSplit[1]}/${applicationDateSplit[0]}`
                        this.matterTrademarkForm.patchValue({
                            MATTERGUID: MatterTradeMarkData.MATTERGUID,
                            MATTERTRADEMARKGUID: MatterTradeMarkData.MATTERTRADEMARKGUID,
                            APPLICATIONNO: MatterTradeMarkData.APPLICATIONNO,
                            APPLICATIONDATE: new Date(this.modifiedApplicationDate),
                            JURISDICTION: MatterTradeMarkData.JURISDICTION,
                            TYPEOFTRADEMARK: MatterTradeMarkData.TYPEOFTRADEMARK,
                            TRADEMARKCLASSES: MatterTradeMarkData.TRADEMARKCLASSES,
                        });

                    }
                }
            });
        }
    }
    // ends here ~ map trademark data to fields

/**
 * This function is used to get the common Date picker
 */
    CommanDatePickerEvent(type: string, event: MatDatepickerInputEvent<Date>, formField: any = '') {
        let conDate = this.datepipe.transform(event.value, 'dd/MM/yyyy')
        this.matterTrademarkForm.value.APPLICATIONDATE = conDate;
    }

    /**
     * This function is used to get the Save trade mark data value
     */
    SaveTradeMark() {
        let conDate = moment(this.matterTrademarkForm.value.APPLICATIONDATE, "YYYY/MM/DD").format("DD/MM/YYYY");
        this.behaviorService.setMatterLoding(true);
        this.matterTrademarkForm.value.APPLICATIONDATE = conDate;
        let formData: any = this.matterTrademarkForm.value;
        if (this.action === 'duplicate') {
            delete formData.MATTERTRADEMARKGUID;
        }

        const formAction = (formData.MATTERTRADEMARKGUID) ? 'Update' : 'Insert';
        const TradeMarkData: any = { Action: formAction, VALIDATEONLY: true, Data: formData };
        this._mainAPiServiceService.getSetData(TradeMarkData, 'matter-trademark').subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, TradeMarkData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, TradeMarkData);
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, TradeMarkData);
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to check the validation data
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.behaviorService.setMatterLoding(false);
        } else if (Object.keys(warningData).length != 0) {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: warningData });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SaveTradeMarkAfterVAlidation(details);
                }
                this.behaviorService.setMatterLoding(false);
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SaveTradeMarkAfterVAlidation(details);

        }
    }

    /**
     * This function is used to Save the Trade mark After the validation data value.
     */
    SaveTradeMarkAfterVAlidation(data: any) {
        data.VALIDATEONLY = false;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this._mainAPiServiceService.getSetData(data, 'matter-trademark').subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.behaviorService.setChildTableData(false);
                this.behaviorService.setMatterLoding(false);
                this.dialogRef.close(true);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.behaviorService.setMatterLoding(false);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, error => {
            this.behaviorService.setMatterLoding(false);
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to delete the trade mark data value
     */
    DeleteTradeMark() {
        this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { MATTERGUID: this.MatterData.MATTERGUID } }, 'matter-trademark').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == 'success') {
                let MatterTradeMarkData = response.DATA.RECORDS[0];
                let postData = { FormAction: "Delete", Data: { MATTERTRADEMARKGUID: MatterTradeMarkData.MATTERTRADEMARKGUID } };
                this._mainAPiServiceService.getSetData(postData, 'matter-trademark').subscribe(res => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // this.toastr.success('Deleted successfully');
                    } else {
                        let Massge: any = (res.DATA.VALIDATIONS[0]) ? res.DATA.VALIDATIONS[0]['ERRORDESCRIPTION'] : res.MESSAGE;
                        this.toastr.error(Massge);
                    }
                });
            }
        });
    }

    /**
     * This function is used to get the text data value
     */
    myFunction(event: any) {
        navigator.clipboard.writeText(event)
    }

    ngOnDestroy(): void {
        this.APIDataSubcription?.unsubscribe();
        this.APIDataSubcription_1?.unsubscribe();
    }

}
