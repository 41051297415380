import { BehaviorService } from '@_services/Behavior.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnDestroy {
    activeSubMenu: any
    sub: Subscription;
    constructor(private behaviorService: BehaviorService) {

        this.sub = this.behaviorService.ActiveSubMenu$.subscribe(res => {
            this.activeSubMenu = res

        })
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    savemyprofile() {

    }
}
